import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { orderList } from "../../redux/slices/orderSlice";
import Card from "../../components/AnnouncementCard";
import Loading from "../../partials/Loader";
const OrderList = () => {
  const { orderListData: data, orderListLoading: loading } = useSelector(
    (state) => state.order
  );
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(orderList());
  }, [dispatch]);

  if (loading)
    return (
      <div>
        <Loading />
      </div>
    );
  return (
    <section className="nav-container">
      <div className="">
        <div className="sup-header text-left w-[100%]">
          <div className="home-title">İlanlar</div>
        </div>
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-2 my-4">
          {data?.map((item) => {
            return (
              <Link to={`/order-details/${item._id}`}>
                <Card item={item} />
              </Link>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default OrderList;
