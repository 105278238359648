export const locations = [
      {
        "CITY": "ADANA",
        "DISTRICT": "ALADAĞ",
        "STREET": "AKPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "ALADAĞ",
        "STREET": "AKÖREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "ALADAĞ",
        "STREET": "BAŞPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "ALADAĞ",
        "STREET": "BOZTAHTA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "ALADAĞ",
        "STREET": "BÜYÜKSOFULU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "ALADAĞ",
        "STREET": "CERİTLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "ALADAĞ",
        "STREET": "DARILIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "ALADAĞ",
        "STREET": "DAİLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "ALADAĞ",
        "STREET": "DÖLEKLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "ALADAĞ",
        "STREET": "EBRİŞİM MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "ALADAĞ",
        "STREET": "EĞNER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "ALADAĞ",
        "STREET": "GERDİBİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "ALADAĞ",
        "STREET": "GÖKÇEKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "ALADAĞ",
        "STREET": "GİREĞİYENİKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "ALADAĞ",
        "STREET": "KABASAKAL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "ALADAĞ",
        "STREET": "KARAHAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "ALADAĞ",
        "STREET": "KICAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "ALADAĞ",
        "STREET": "KIZILDAM MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "ALADAĞ",
        "STREET": "KIŞLAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "ALADAĞ",
        "STREET": "KÖKEZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "ALADAĞ",
        "STREET": "KÖPRÜCÜK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "ALADAĞ",
        "STREET": "KÜP MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "ALADAĞ",
        "STREET": "MADENLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "ALADAĞ",
        "STREET": "MANSURLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "ALADAĞ",
        "STREET": "MAZILIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "ALADAĞ",
        "STREET": "POSYAĞBASAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "ALADAĞ",
        "STREET": "SİNANPAŞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "ALADAĞ",
        "STREET": "TOPALLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "ALADAĞ",
        "STREET": "UZUNKUYU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "ALADAĞ",
        "STREET": "YETİMLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "ALADAĞ",
        "STREET": "YÜKSEKÖREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "CEYHAN",
        "STREET": "ADAPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "CEYHAN",
        "STREET": "ADATEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "CEYHAN",
        "STREET": "AKDAM MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "CEYHAN",
        "STREET": "ALTIGÖZBEKİRLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "CEYHAN",
        "STREET": "ALTIKARA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "CEYHAN",
        "STREET": "ALTIOCAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "CEYHAN",
        "STREET": "ATATÜRK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "CEYHAN",
        "STREET": "AYDEMİROĞLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "CEYHAN",
        "STREET": "AYDINLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "CEYHAN",
        "STREET": "AZİZLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "CEYHAN",
        "STREET": "AĞAÇLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "CEYHAN",
        "STREET": "AĞAÇPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "CEYHAN",
        "STREET": "BAŞÖREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "CEYHAN",
        "STREET": "BELEDİYE EVLERİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "CEYHAN",
        "STREET": "BOTA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "CEYHAN",
        "STREET": "BURHANLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "CEYHAN",
        "STREET": "BURHANİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "CEYHAN",
        "STREET": "BÜYÜKBURHANİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "CEYHAN",
        "STREET": "BÜYÜKKIRIM MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "CEYHAN",
        "STREET": "BÜYÜKMANGIT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "CEYHAN",
        "STREET": "BİRKENT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "CEYHAN",
        "STREET": "CAMUZAĞILI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "CEYHAN",
        "STREET": "CEYHANBEKİRLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "CEYHAN",
        "STREET": "CUMHURİYET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "CEYHAN",
        "STREET": "CİVANTAYAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "CEYHAN",
        "STREET": "DAĞISTAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "CEYHAN",
        "STREET": "DEĞİRMENDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "CEYHAN",
        "STREET": "DEĞİRMENLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "CEYHAN",
        "STREET": "DOKUZTEKNE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "CEYHAN",
        "STREET": "DORUK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "CEYHAN",
        "STREET": "DURHASANDEDE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "CEYHAN",
        "STREET": "DUTLUPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "CEYHAN",
        "STREET": "DİKİLİTAŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "CEYHAN",
        "STREET": "EKİNYAZI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "CEYHAN",
        "STREET": "ELMAGÖLÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "CEYHAN",
        "STREET": "EMEK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "CEYHAN",
        "STREET": "ERENLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "CEYHAN",
        "STREET": "ESENTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "CEYHAN",
        "STREET": "FATİH SULTAN MEHMET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "CEYHAN",
        "STREET": "GAZİ OSMAN PAŞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "CEYHAN",
        "STREET": "GÜMÜRDÜLÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "CEYHAN",
        "STREET": "GÜNDOĞAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "CEYHAN",
        "STREET": "GÜNLÜCE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "CEYHAN",
        "STREET": "HAMDİLLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "CEYHAN",
        "STREET": "HAMİDİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "CEYHAN",
        "STREET": "HAMİTBEY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "CEYHAN",
        "STREET": "HAMİTBEYBUCAĞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "CEYHAN",
        "STREET": "HÜRRİYET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "CEYHAN",
        "STREET": "IRMAKLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "CEYHAN",
        "STREET": "ISIRGANLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "CEYHAN",
        "STREET": "KARAKAYALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "CEYHAN",
        "STREET": "KELEMETİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "CEYHAN",
        "STREET": "KILIÇKAYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "CEYHAN",
        "STREET": "KIVRIKLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "CEYHAN",
        "STREET": "KIZILDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "CEYHAN",
        "STREET": "KONAKOĞLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "CEYHAN",
        "STREET": "KORUKLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "CEYHAN",
        "STREET": "KURTKULAĞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "CEYHAN",
        "STREET": "KURTPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "CEYHAN",
        "STREET": "KUZUCAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "CEYHAN",
        "STREET": "KÖPRÜLÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "CEYHAN",
        "STREET": "KÖRKUYU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "CEYHAN",
        "STREET": "KÖSRELİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "CEYHAN",
        "STREET": "KÜÇÜKBURHANİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "CEYHAN",
        "STREET": "KÜÇÜKKIRIM MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "CEYHAN",
        "STREET": "KÜÇÜKMANGIT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "CEYHAN",
        "STREET": "MERCİMEK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "CEYHAN",
        "STREET": "MERCİN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "CEYHAN",
        "STREET": "MODERNEVLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "CEYHAN",
        "STREET": "MURADİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "CEYHAN",
        "STREET": "MUSTAFABEYLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "CEYHAN",
        "STREET": "MİTHAT PAŞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "CEYHAN",
        "STREET": "NAMIK KEMAL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "CEYHAN",
        "STREET": "NARLIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "CEYHAN",
        "STREET": "NAZIMBEY YENİKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "CEYHAN",
        "STREET": "SARIBAHÇE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "CEYHAN",
        "STREET": "SARIMAZI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "CEYHAN",
        "STREET": "SARISAKAL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "CEYHAN",
        "STREET": "SAĞIRLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "CEYHAN",
        "STREET": "SAĞKAYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "CEYHAN",
        "STREET": "SELİMİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "CEYHAN",
        "STREET": "SOYSALLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "CEYHAN",
        "STREET": "SOĞUKPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "CEYHAN",
        "STREET": "SİRKELİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "CEYHAN",
        "STREET": "TATARLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "CEYHAN",
        "STREET": "TATLIKUYU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "CEYHAN",
        "STREET": "TOKTAMIŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "CEYHAN",
        "STREET": "TUMLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "CEYHAN",
        "STREET": "ULUS MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "CEYHAN",
        "STREET": "VEYSİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "CEYHAN",
        "STREET": "YALAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "CEYHAN",
        "STREET": "YARSUAT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "CEYHAN",
        "STREET": "YELLİBEL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "CEYHAN",
        "STREET": "YEŞİLBAHÇE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "CEYHAN",
        "STREET": "YEŞİLDAM MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "CEYHAN",
        "STREET": "YILANKALE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "CEYHAN",
        "STREET": "ZÜBEYDE HANIM MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "CEYHAN",
        "STREET": "ÇAKALDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "CEYHAN",
        "STREET": "ÇATAKLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "CEYHAN",
        "STREET": "ÇATALHÜYÜK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "CEYHAN",
        "STREET": "ÇEVRETEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "CEYHAN",
        "STREET": "ÇOKÇAPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "CEYHAN",
        "STREET": "ÇİFTLİKLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "CEYHAN",
        "STREET": "ÇİÇEKLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "CEYHAN",
        "STREET": "ÜÇDUTYEŞİLOVA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "CEYHAN",
        "STREET": "İMRAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "CEYHAN",
        "STREET": "İNCEYER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "CEYHAN",
        "STREET": "İNÖNÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "CEYHAN",
        "STREET": "İSALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "CEYHAN",
        "STREET": "İSTİKLAL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "CEYHAN",
        "STREET": "ŞAHİN ÖZBİLEN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "CEYHAN",
        "STREET": "ŞEHİT HACI İBRAHİM MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "FEKE",
        "STREET": "AKKAYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "FEKE",
        "STREET": "AKOLUK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "FEKE",
        "STREET": "BAHÇECİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "FEKE",
        "STREET": "BAĞDATLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "FEKE",
        "STREET": "BELENKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "FEKE",
        "STREET": "DEĞİRMENCİUŞAĞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "FEKE",
        "STREET": "GAFFARUŞAĞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "FEKE",
        "STREET": "GEDİKLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "FEKE",
        "STREET": "GÖBELLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "FEKE",
        "STREET": "GÖKÇELİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "FEKE",
        "STREET": "GÜRÜMZE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "FEKE",
        "STREET": "GÜZPINARI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "FEKE",
        "STREET": "HIDIRUŞAĞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "FEKE",
        "STREET": "KALEYÜZÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "FEKE",
        "STREET": "KARACAOĞLAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "FEKE",
        "STREET": "KARACAUŞAĞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "FEKE",
        "STREET": "KAYADİBİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "FEKE",
        "STREET": "KAZANCI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "FEKE",
        "STREET": "KAŞALTI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "FEKE",
        "STREET": "KEKLİKÇİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "FEKE",
        "STREET": "KIRIKUŞAĞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "FEKE",
        "STREET": "KISACIKLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "FEKE",
        "STREET": "KIZILYER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "FEKE",
        "STREET": "KONAKKURAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "FEKE",
        "STREET": "KOVUKÇINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "FEKE",
        "STREET": "KOÇYAZI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "FEKE",
        "STREET": "MANSURLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "FEKE",
        "STREET": "MUSALAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "FEKE",
        "STREET": "OLUCAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "FEKE",
        "STREET": "ORMANCIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "FEKE",
        "STREET": "ORTAKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "FEKE",
        "STREET": "ORUÇLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "FEKE",
        "STREET": "PAŞALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "FEKE",
        "STREET": "SÜLEMİŞLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "FEKE",
        "STREET": "SÜPHANDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "FEKE",
        "STREET": "TENKERLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "FEKE",
        "STREET": "TOKMANAKLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "FEKE",
        "STREET": "TORTULU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "FEKE",
        "STREET": "UĞURLUBAĞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "FEKE",
        "STREET": "YAYLAPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "FEKE",
        "STREET": "YEREBAKAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "FEKE",
        "STREET": "YEŞİL DÜŞMÜŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "FEKE",
        "STREET": "ÇANDIRLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "FEKE",
        "STREET": "ÇONDU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "FEKE",
        "STREET": "ÇÜRÜKLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "FEKE",
        "STREET": "İNCİRCİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "FEKE",
        "STREET": "İSLAM MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "FEKE",
        "STREET": "ŞAHMURATLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "KARATAŞ",
        "STREET": "ADALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "KARATAŞ",
        "STREET": "ATAKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "KARATAŞ",
        "STREET": "BAHÇE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "KARATAŞ",
        "STREET": "BEBELİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "KARATAŞ",
        "STREET": "DAMLAPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "KARATAŞ",
        "STREET": "DEVELİÖREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "KARATAŞ",
        "STREET": "DOLAPLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "KARATAŞ",
        "STREET": "GÖLKAYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "KARATAŞ",
        "STREET": "HACIHASAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "KARATAŞ",
        "STREET": "HASIRAĞACI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "KARATAŞ",
        "STREET": "HELVACI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "KARATAŞ",
        "STREET": "KAPI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "KARATAŞ",
        "STREET": "KARAGÖÇER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "KARATAŞ",
        "STREET": "KARATAŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "KARATAŞ",
        "STREET": "KARŞIYAKA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "KARATAŞ",
        "STREET": "KEMALİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "KARATAŞ",
        "STREET": "KESİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "KARATAŞ",
        "STREET": "KIRHASAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "KARATAŞ",
        "STREET": "KIZILTAHTA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "KARATAŞ",
        "STREET": "KİREMİTLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "KARATAŞ",
        "STREET": "MELETMEZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "KARATAŞ",
        "STREET": "ORTA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "KARATAŞ",
        "STREET": "OYMAKLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "KARATAŞ",
        "STREET": "SARIMSAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "KARATAŞ",
        "STREET": "SİRKENLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "KARATAŞ",
        "STREET": "TABAKLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "KARATAŞ",
        "STREET": "TABUR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "KARATAŞ",
        "STREET": "TERLİKSİZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "KARATAŞ",
        "STREET": "TOPRAKLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "KARATAŞ",
        "STREET": "TUZKUYUSU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "KARATAŞ",
        "STREET": "TUZLA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "KARATAŞ",
        "STREET": "YASSIVEREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "KARATAŞ",
        "STREET": "YEMİŞLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "KARATAŞ",
        "STREET": "YENİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "KARATAŞ",
        "STREET": "YENİMURAT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "KARATAŞ",
        "STREET": "YÜZBAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "KARATAŞ",
        "STREET": "ÇAKIRÖREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "KARATAŞ",
        "STREET": "ÇAVUŞLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "KARATAŞ",
        "STREET": "ÇAĞŞIRLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "KARATAŞ",
        "STREET": "ÇUKURKAMIŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "KARATAŞ",
        "STREET": "ÇİMELİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "KARATAŞ",
        "STREET": "İNNEPLİHÜYÜĞÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "KARATAŞ",
        "STREET": "İSAHACILI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "KARAİSALI",
        "STREET": "AKTAŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "KARAİSALI",
        "STREET": "AKÇALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "KARAİSALI",
        "STREET": "ALTINOVA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "KARAİSALI",
        "STREET": "AYAKKIF MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "KARAİSALI",
        "STREET": "AŞAĞIBELEMEDİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "KARAİSALI",
        "STREET": "AŞAĞIYÖRÜKLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "KARAİSALI",
        "STREET": "BARAKDAĞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "KARAİSALI",
        "STREET": "BAŞKIF MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "KARAİSALI",
        "STREET": "BEKİRLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "KARAİSALI",
        "STREET": "BEYDEMİR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "KARAİSALI",
        "STREET": "BOLACALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "KARAİSALI",
        "STREET": "BUCAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "KARAİSALI",
        "STREET": "DEMİRÇİT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "KARAİSALI",
        "STREET": "DURAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "KARAİSALI",
        "STREET": "DÖŞEKEVİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "KARAİSALI",
        "STREET": "EMELCİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "KARAİSALI",
        "STREET": "ETEKLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "KARAİSALI",
        "STREET": "EĞLENCE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "KARAİSALI",
        "STREET": "FETTAHLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "KARAİSALI",
        "STREET": "FİLİKLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "KARAİSALI",
        "STREET": "GÖKHASANLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "KARAİSALI",
        "STREET": "GÜLÜŞLÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "KARAİSALI",
        "STREET": "GÜVENÇ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "KARAİSALI",
        "STREET": "GİLDİRLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "KARAİSALI",
        "STREET": "HACILI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "KARAİSALI",
        "STREET": "HACIMUSALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "KARAİSALI",
        "STREET": "KALEDAĞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "KARAİSALI",
        "STREET": "KAPIKAYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "KARAİSALI",
        "STREET": "KARAHASANLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "KARAİSALI",
        "STREET": "KARAKILIÇ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "KARAİSALI",
        "STREET": "KARAKUYU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "KARAİSALI",
        "STREET": "KARAPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "KARAİSALI",
        "STREET": "KIRALAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "KARAİSALI",
        "STREET": "KIRIKLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "KARAİSALI",
        "STREET": "KOCAVELİLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "KARAİSALI",
        "STREET": "KUYUCU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "KARAİSALI",
        "STREET": "KUZGUN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "KARAİSALI",
        "STREET": "KUŞCUSOFULU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "KARAİSALI",
        "STREET": "KÖRÜKLÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "KARAİSALI",
        "STREET": "MARAŞLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "KARAİSALI",
        "STREET": "MERKEZBOZTAHTA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "KARAİSALI",
        "STREET": "MURTÇUKURU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "KARAİSALI",
        "STREET": "NERGİZLİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "KARAİSALI",
        "STREET": "NUHLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "KARAİSALI",
        "STREET": "SADIKALİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "KARAİSALI",
        "STREET": "SARIMEHMETLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "KARAİSALI",
        "STREET": "SAYPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "KARAİSALI",
        "STREET": "SELAMPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "KARAİSALI",
        "STREET": "TATIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "KARAİSALI",
        "STREET": "TOPAKTAŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "KARAİSALI",
        "STREET": "TOPKARALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "KARAİSALI",
        "STREET": "TORUNSOLAKLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "KARAİSALI",
        "STREET": "TÜMENLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "KARAİSALI",
        "STREET": "YAZIBAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "KARAİSALI",
        "STREET": "ÇAKALLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "KARAİSALI",
        "STREET": "ÇATALAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "KARAİSALI",
        "STREET": "ÇECELİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "KARAİSALI",
        "STREET": "ÇEVLİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "KARAİSALI",
        "STREET": "ÇOCUKLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "KARAİSALI",
        "STREET": "ÇORLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "KARAİSALI",
        "STREET": "ÇUKUR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "KARAİSALI",
        "STREET": "ÖMERLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "KOZAN",
        "STREET": "ACARMANTAŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "KOZAN",
        "STREET": "AKARCA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "KOZAN",
        "STREET": "AKDAM MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "KOZAN",
        "STREET": "AKKAYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "KOZAN",
        "STREET": "AKÇALIUŞAĞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "KOZAN",
        "STREET": "ALAPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "KOZAN",
        "STREET": "ANDIL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "KOZAN",
        "STREET": "ARSLANLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "KOZAN",
        "STREET": "ARSLANPAŞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "KOZAN",
        "STREET": "AYDIN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "KOZAN",
        "STREET": "AYŞEHOCA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "KOZAN",
        "STREET": "AĞLIBOĞAZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "KOZAN",
        "STREET": "BAĞLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "KOZAN",
        "STREET": "BAĞTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "KOZAN",
        "STREET": "BAĞÖZÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "KOZAN",
        "STREET": "BOZTAHTA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "KOZAN",
        "STREET": "BUCAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "KOZAN",
        "STREET": "BULDUKLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "KOZAN",
        "STREET": "CUMHURİYET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "KOZAN",
        "STREET": "DAMYERİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "KOZAN",
        "STREET": "DOĞANALANI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "KOZAN",
        "STREET": "DURALUŞAĞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "KOZAN",
        "STREET": "DURMUŞLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "KOZAN",
        "STREET": "DÜZAĞAÇ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "KOZAN",
        "STREET": "DİKİLİTAŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "KOZAN",
        "STREET": "DİLEKKAYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "KOZAN",
        "STREET": "ENİZÇAKIRI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "KOZAN",
        "STREET": "ERGENUŞAĞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "KOZAN",
        "STREET": "ESKİKABASAKAL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "KOZAN",
        "STREET": "ESKİMANTAŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "KOZAN",
        "STREET": "FAYDALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "KOZAN",
        "STREET": "FERHATLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "KOZAN",
        "STREET": "GAZİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "KOZAN",
        "STREET": "GEDİKLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "KOZAN",
        "STREET": "GÖKGÖZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "KOZAN",
        "STREET": "GÖKÇEYOL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "KOZAN",
        "STREET": "GÖRBEYAZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "KOZAN",
        "STREET": "GÜNERİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "KOZAN",
        "STREET": "HACIBEYLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "KOZAN",
        "STREET": "HACIMİRZALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "KOZAN",
        "STREET": "HACIUŞAĞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "KOZAN",
        "STREET": "HAMAM MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "KOZAN",
        "STREET": "ILICA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "KOZAN",
        "STREET": "IŞIKKAYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "KOZAN",
        "STREET": "IŞIKLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "KOZAN",
        "STREET": "KABAKTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "KOZAN",
        "STREET": "KAHVELİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "KOZAN",
        "STREET": "KALKUMAÇ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "KOZAN",
        "STREET": "KAPIKAYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "KOZAN",
        "STREET": "KARABUCAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "KOZAN",
        "STREET": "KARACAOĞLAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "KOZAN",
        "STREET": "KARACAÖREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "KOZAN",
        "STREET": "KARAHAMZALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "KOZAN",
        "STREET": "KARANEBİLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "KOZAN",
        "STREET": "KEMERKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "KOZAN",
        "STREET": "KIBRISLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "KOZAN",
        "STREET": "KIZILLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "KOZAN",
        "STREET": "KIZLARSEKİSİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "KOZAN",
        "STREET": "KOZAN M TİPİ KAPALI VE AÇIK CEZA İNFAZ KURUMU",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "KOZAN",
        "STREET": "KUYTUCAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "KOZAN",
        "STREET": "KUYUBELİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "KOZAN",
        "STREET": "KUYULUK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "KOZAN",
        "STREET": "KÖSELİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "KOZAN",
        "STREET": "MAHMUTLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "KOZAN",
        "STREET": "MAHYALAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "KOZAN",
        "STREET": "MARANKEÇİLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "KOZAN",
        "STREET": "MİNNETLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "KOZAN",
        "STREET": "ORUÇLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "KOZAN",
        "STREET": "ORÇAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "KOZAN",
        "STREET": "PEKMEZCİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "KOZAN",
        "STREET": "POSTKABASAKAL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "KOZAN",
        "STREET": "SALMANLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "KOZAN",
        "STREET": "TAVŞANTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "KOZAN",
        "STREET": "TAŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "KOZAN",
        "STREET": "TEPECİKÖREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "KOZAN",
        "STREET": "TUFANLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "KOZAN",
        "STREET": "TUFANPAŞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "KOZAN",
        "STREET": "TURGUTLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "KOZAN",
        "STREET": "TURUNÇLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "KOZAN",
        "STREET": "TÜRKELİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "KOZAN",
        "STREET": "VARSAKLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "KOZAN",
        "STREET": "VELİCANLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "KOZAN",
        "STREET": "YANALERİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "KOZAN",
        "STREET": "YARIMOĞLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "KOZAN",
        "STREET": "YASSIÇALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "KOZAN",
        "STREET": "YENİKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "KOZAN",
        "STREET": "YUKARIKEÇİLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "KOZAN",
        "STREET": "YÜKSEKÖREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "KOZAN",
        "STREET": "ZERDALİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "KOZAN",
        "STREET": "ÇAMDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "KOZAN",
        "STREET": "ÇAMLARCA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "KOZAN",
        "STREET": "ÇANAKLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "KOZAN",
        "STREET": "ÇANDIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "KOZAN",
        "STREET": "ÇELENUŞAĞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "KOZAN",
        "STREET": "ÇOBANPINARI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "KOZAN",
        "STREET": "ÇOKAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "KOZAN",
        "STREET": "ÇUKURÖREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "KOZAN",
        "STREET": "ÇULLUUŞAĞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "KOZAN",
        "STREET": "ÇÜRÜKLÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "KOZAN",
        "STREET": "ÖRENDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "KOZAN",
        "STREET": "ÖZBAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "KOZAN",
        "STREET": "İDEMKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "KOZAN",
        "STREET": "ŞERİFLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "KOZAN",
        "STREET": "ŞEVKİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "POZANTI",
        "STREET": "AKÇATEKİR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "POZANTI",
        "STREET": "ALPU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "POZANTI",
        "STREET": "AŞÇIBEKİRLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "POZANTI",
        "STREET": "BELEMEDİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "POZANTI",
        "STREET": "CUMHURİYET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "POZANTI",
        "STREET": "DAĞDİBİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "POZANTI",
        "STREET": "ESKİKONACIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "POZANTI",
        "STREET": "FINDIKLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "POZANTI",
        "STREET": "GÖKBEZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "POZANTI",
        "STREET": "HAMİDİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "POZANTI",
        "STREET": "KAMIŞLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "POZANTI",
        "STREET": "KARAKIŞLAKÇI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "POZANTI",
        "STREET": "KURTULUŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "POZANTI",
        "STREET": "POZANTI M TİPİ KAPALI CEZA İNFAZ KURUMU",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "POZANTI",
        "STREET": "YAZICAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "POZANTI",
        "STREET": "YAĞLITAŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "POZANTI",
        "STREET": "YENİKONACIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "POZANTI",
        "STREET": "YUKARIBELEMEDİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "POZANTI",
        "STREET": "ZAFER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "POZANTI",
        "STREET": "ÇAMLIBEL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "POZANTI",
        "STREET": "ÖMERLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "POZANTI",
        "STREET": "İSTİKLAL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "SARIÇAM",
        "STREET": "ACIDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "SARIÇAM",
        "STREET": "ADANA 1 NOLU AÇIK CEZA İNFAZ KURUMU",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "SARIÇAM",
        "STREET": "ADANA 1 NOLU T-TİPİ KAPALI CEZA İNFAZ KURUMU",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "SARIÇAM",
        "STREET": "ADANA 2 NOLU T-TİPİ KAPALI CEZA İNFAZ KURUMU",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "SARIÇAM",
        "STREET": "ADANA E-TİPİ KAPALI CEZA İNFAZ KURUMU",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "SARIÇAM",
        "STREET": "ADANA F-TİPİ YÜKSEK GÜVENLİKLİ KAPALI CEZA İNFAZ K",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "SARIÇAM",
        "STREET": "AFLAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "SARIÇAM",
        "STREET": "AKKUYU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "SARIÇAM",
        "STREET": "AVCILAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "SARIÇAM",
        "STREET": "AYDINYURDU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "SARIÇAM",
        "STREET": "AYVALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "SARIÇAM",
        "STREET": "BALCALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "SARIÇAM",
        "STREET": "BAYRAM HACILI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "SARIÇAM",
        "STREET": "BEYCELİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "SARIÇAM",
        "STREET": "BOYNUYOĞUN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "SARIÇAM",
        "STREET": "BOZTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "SARIÇAM",
        "STREET": "BURUK CUMHURİYET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "SARIÇAM",
        "STREET": "BÜYÜK BAKLALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "SARIÇAM",
        "STREET": "CERENLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "SARIÇAM",
        "STREET": "CİHADİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "SARIÇAM",
        "STREET": "DAĞCI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "SARIÇAM",
        "STREET": "DUTLUCA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "SARIÇAM",
        "STREET": "ERTUĞRULGAZİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "SARIÇAM",
        "STREET": "ESENTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "SARIÇAM",
        "STREET": "EĞECİUŞAĞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "SARIÇAM",
        "STREET": "GÖKBUKET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "SARIÇAM",
        "STREET": "GÖZTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "SARIÇAM",
        "STREET": "GÜLTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "SARIÇAM",
        "STREET": "HAKKIBEYLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "SARIÇAM",
        "STREET": "HASANBEYLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "SARIÇAM",
        "STREET": "HOCALLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "SARIÇAM",
        "STREET": "HÜRRİYET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "SARIÇAM",
        "STREET": "KARAYUSUFLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "SARIÇAM",
        "STREET": "KARAÖMERLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "SARIÇAM",
        "STREET": "KARGAKEKEÇ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "SARIÇAM",
        "STREET": "KARLIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "SARIÇAM",
        "STREET": "KAŞOBASI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "SARIÇAM",
        "STREET": "KEMALPAŞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "SARIÇAM",
        "STREET": "KEPEZTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "SARIÇAM",
        "STREET": "KILBAŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "SARIÇAM",
        "STREET": "KILIÇLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "SARIÇAM",
        "STREET": "KIZILKAŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "SARIÇAM",
        "STREET": "KÖSEFAKILI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "SARIÇAM",
        "STREET": "KÜÇÜKBAKLALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "SARIÇAM",
        "STREET": "MALTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "SARIÇAM",
        "STREET": "MEHMET AKİF ERSOY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "SARIÇAM",
        "STREET": "MENEKŞE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "SARIÇAM",
        "STREET": "MUSTAFALAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "SARIÇAM",
        "STREET": "MÜMİNLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "SARIÇAM",
        "STREET": "ORHANGAZİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "SARIÇAM",
        "STREET": "OSMANGAZİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "SARIÇAM",
        "STREET": "REMZİ OĞUZ ARIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "SARIÇAM",
        "STREET": "SARIÇAM MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "SARIÇAM",
        "STREET": "SOFUDEDE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "SARIÇAM",
        "STREET": "SULUCA L-TİPİ KAPALI CEZA İNFAZ KURUMU",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "SARIÇAM",
        "STREET": "SULUCA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "SARIÇAM",
        "STREET": "TURUNÇLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "SARIÇAM",
        "STREET": "YARIMCA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "SARIÇAM",
        "STREET": "YAVUZ SULTAN SELİM MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "SARIÇAM",
        "STREET": "YAĞIZLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "SARIÇAM",
        "STREET": "YENİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "SARIÇAM",
        "STREET": "YENİYAYLA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "SARIÇAM",
        "STREET": "YEŞİLTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "SARIÇAM",
        "STREET": "YILDIRIM BEYAZIT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "SARIÇAM",
        "STREET": "YÜREKLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "SARIÇAM",
        "STREET": "ÇAMLICA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "SARIÇAM",
        "STREET": "ÇARKIPARE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "SARIÇAM",
        "STREET": "ÇAYLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "SARIÇAM",
        "STREET": "ÇINARLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "SARIÇAM",
        "STREET": "ÇİRİŞGEDİĞİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "SARIÇAM",
        "STREET": "ÇİÇEKLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "SARIÇAM",
        "STREET": "ÜNLÜCE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "SARIÇAM",
        "STREET": "İSTİKLAL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "SARIÇAM",
        "STREET": "ŞAHİNTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "SAİMBEYLİ",
        "STREET": "AKSAAĞAÇ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "SAİMBEYLİ",
        "STREET": "AVCIPINARI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "SAİMBEYLİ",
        "STREET": "AYVACIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "SAİMBEYLİ",
        "STREET": "BEYPINARI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "SAİMBEYLİ",
        "STREET": "CUMHURLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "SAİMBEYLİ",
        "STREET": "DEĞİRMENCİUŞAĞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "SAİMBEYLİ",
        "STREET": "EYÜPLÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "SAİMBEYLİ",
        "STREET": "FATİH MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "SAİMBEYLİ",
        "STREET": "GÖKMENLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "SAİMBEYLİ",
        "STREET": "GÜRLEŞEN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "SAİMBEYLİ",
        "STREET": "HALİLBEYLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "SAİMBEYLİ",
        "STREET": "HİMMETLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "SAİMBEYLİ",
        "STREET": "KANDİLLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "SAİMBEYLİ",
        "STREET": "KAPAKLIKUYU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "SAİMBEYLİ",
        "STREET": "KARAKUYU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "SAİMBEYLİ",
        "STREET": "KIZILAĞAÇ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "SAİMBEYLİ",
        "STREET": "MAHMUTLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "SAİMBEYLİ",
        "STREET": "NALTAŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "SAİMBEYLİ",
        "STREET": "NARLIDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "SAİMBEYLİ",
        "STREET": "TOPALLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "SAİMBEYLİ",
        "STREET": "TÜLÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "SAİMBEYLİ",
        "STREET": "YARDİBİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "SAİMBEYLİ",
        "STREET": "YENİKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "SAİMBEYLİ",
        "STREET": "YEŞİLBAĞLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "SAİMBEYLİ",
        "STREET": "ÇATAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "SAİMBEYLİ",
        "STREET": "ÇERALAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "SAİMBEYLİ",
        "STREET": "ÇORAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "SAİMBEYLİ",
        "STREET": "İSLAM MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "SEYHAN",
        "STREET": "2000 EVLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "SEYHAN",
        "STREET": "AHMET REMZİ YÜREĞİR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "SEYHAN",
        "STREET": "AKKAPI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "SEYHAN",
        "STREET": "ALİDEDE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "SEYHAN",
        "STREET": "AYDINLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "SEYHAN",
        "STREET": "BAHÇELİEVLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "SEYHAN",
        "STREET": "BAHÇEŞEHİR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "SEYHAN",
        "STREET": "BARBAROS MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "SEYHAN",
        "STREET": "BARIŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "SEYHAN",
        "STREET": "BEY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "SEYHAN",
        "STREET": "BEŞOCAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "SEYHAN",
        "STREET": "BÜYÜKDİKİLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "SEYHAN",
        "STREET": "BÜYÜKÇILDIRIM MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "SEYHAN",
        "STREET": "CAMUZCU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "SEYHAN",
        "STREET": "CEMALPAŞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "SEYHAN",
        "STREET": "DAĞLIOĞLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "SEYHAN",
        "STREET": "DEMETEVLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "SEYHAN",
        "STREET": "DENİZLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "SEYHAN",
        "STREET": "DERVİŞLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "SEYHAN",
        "STREET": "DUMLUPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "SEYHAN",
        "STREET": "DÖRTAĞAÇ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "SEYHAN",
        "STREET": "DÖŞEME MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "SEYHAN",
        "STREET": "EMEK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "SEYHAN",
        "STREET": "FATİH MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "SEYHAN",
        "STREET": "FEVZİPAŞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "SEYHAN",
        "STREET": "GAZİPAŞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "SEYHAN",
        "STREET": "GÖKÇELER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "SEYHAN",
        "STREET": "GÖLBAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "SEYHAN",
        "STREET": "GÜLBAHÇESİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "SEYHAN",
        "STREET": "GÜLPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "SEYHAN",
        "STREET": "GÜRSELPAŞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "SEYHAN",
        "STREET": "HADIRLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "SEYHAN",
        "STREET": "HANEDAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "SEYHAN",
        "STREET": "HAVUZLUBAHÇE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "SEYHAN",
        "STREET": "HURMALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "SEYHAN",
        "STREET": "HÜRRİYET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "SEYHAN",
        "STREET": "KARAKUYU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "SEYHAN",
        "STREET": "KARASOKU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "SEYHAN",
        "STREET": "KARAYUSUFLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "SEYHAN",
        "STREET": "KAVAKLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "SEYHAN",
        "STREET": "KAYALIBAĞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "SEYHAN",
        "STREET": "KAYIŞLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "SEYHAN",
        "STREET": "KOCAVEZİR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "SEYHAN",
        "STREET": "KOYUNCU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "SEYHAN",
        "STREET": "KOZA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "SEYHAN",
        "STREET": "KURTULUŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "SEYHAN",
        "STREET": "KURUKÖPRÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "SEYHAN",
        "STREET": "KUYUMCULAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "SEYHAN",
        "STREET": "KÖYLÜOĞLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "SEYHAN",
        "STREET": "KÜÇÜKDİKİLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "SEYHAN",
        "STREET": "KÜÇÜKÇILDIRIM MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "SEYHAN",
        "STREET": "MEKAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "SEYHAN",
        "STREET": "MESTANZADE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "SEYHAN",
        "STREET": "MEYDAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "SEYHAN",
        "STREET": "MIDIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "SEYHAN",
        "STREET": "MÜRSELOĞLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "SEYHAN",
        "STREET": "MİRZAÇELEBİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "SEYHAN",
        "STREET": "MİTHATPAŞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "SEYHAN",
        "STREET": "NAMIK KEMAL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "SEYHAN",
        "STREET": "NARLICA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "SEYHAN",
        "STREET": "ONUR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "SEYHAN",
        "STREET": "OVA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "SEYHAN",
        "STREET": "PINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "SEYHAN",
        "STREET": "REŞATBEY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "SEYHAN",
        "STREET": "SAKARYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "SEYHAN",
        "STREET": "SALMANBEYLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "SEYHAN",
        "STREET": "SARIHAMZALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "SEYHAN",
        "STREET": "SARIHUĞLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "SEYHAN",
        "STREET": "SARIYAKUP MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "SEYHAN",
        "STREET": "SERİNEVLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "SEYHAN",
        "STREET": "SUCUZADE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "SEYHAN",
        "STREET": "SÖĞÜTLÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "SEYHAN",
        "STREET": "SÜMER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "SEYHAN",
        "STREET": "TELLİDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "SEYHAN",
        "STREET": "TEPEBAĞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "SEYHAN",
        "STREET": "TÜRKOCAĞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "SEYHAN",
        "STREET": "ULUCAMİİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "SEYHAN",
        "STREET": "UÇAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "SEYHAN",
        "STREET": "YALMANLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "SEYHAN",
        "STREET": "YENİ  MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "SEYHAN",
        "STREET": "YENİBARAJ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "SEYHAN",
        "STREET": "YENİBEY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "SEYHAN",
        "STREET": "YENİDAM MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "SEYHAN",
        "STREET": "YEŞİLEVLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "SEYHAN",
        "STREET": "YEŞİLOBA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "SEYHAN",
        "STREET": "YEŞİLYURT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "SEYHAN",
        "STREET": "YEŞİLYUVA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "SEYHAN",
        "STREET": "YOLGEÇEN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "SEYHAN",
        "STREET": "ZEYTİNLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "SEYHAN",
        "STREET": "ZİYAPAŞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "SEYHAN",
        "STREET": "ÇAPUTÇU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "SEYHAN",
        "STREET": "ÇINARLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "SEYHAN",
        "STREET": "İSMETPAŞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "SEYHAN",
        "STREET": "İSTİKLAL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "SEYHAN",
        "STREET": "ŞAKİRPAŞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "SEYHAN",
        "STREET": "ŞEHİTDURAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "TUFANBEYLİ",
        "STREET": "AKPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "TUFANBEYLİ",
        "STREET": "AKÇAL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "TUFANBEYLİ",
        "STREET": "AYVAT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "TUFANBEYLİ",
        "STREET": "BOLATPINARI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "TUFANBEYLİ",
        "STREET": "BOZGÜNEY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "TUFANBEYLİ",
        "STREET": "CUMHURİYET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "TUFANBEYLİ",
        "STREET": "DAMLALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "TUFANBEYLİ",
        "STREET": "DEMİROLUK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "TUFANBEYLİ",
        "STREET": "DOĞANBEYLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "TUFANBEYLİ",
        "STREET": "DOĞANLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "TUFANBEYLİ",
        "STREET": "ELEMANLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "TUFANBEYLİ",
        "STREET": "EVCİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "TUFANBEYLİ",
        "STREET": "FATMAKUYU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "TUFANBEYLİ",
        "STREET": "GÜZELİM MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "TUFANBEYLİ",
        "STREET": "HANYERİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "TUFANBEYLİ",
        "STREET": "KARSAVRAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "TUFANBEYLİ",
        "STREET": "KAYAPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "TUFANBEYLİ",
        "STREET": "KAYARCIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "TUFANBEYLİ",
        "STREET": "KOCCAĞIZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "TUFANBEYLİ",
        "STREET": "KİRAZLIYURT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "TUFANBEYLİ",
        "STREET": "ORTAKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "TUFANBEYLİ",
        "STREET": "PEKMEZLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "TUFANBEYLİ",
        "STREET": "PINARLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "TUFANBEYLİ",
        "STREET": "TAŞPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "TUFANBEYLİ",
        "STREET": "TOZLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "TUFANBEYLİ",
        "STREET": "YAMANLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "TUFANBEYLİ",
        "STREET": "YENİCAMİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "TUFANBEYLİ",
        "STREET": "YEŞİLOVA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "TUFANBEYLİ",
        "STREET": "ÇATALÇAM MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "TUFANBEYLİ",
        "STREET": "ÇUKURKIŞLA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "TUFANBEYLİ",
        "STREET": "İSTİKLAL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "TUFANBEYLİ",
        "STREET": "İĞDEBEL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "TUFANBEYLİ",
        "STREET": "ŞAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "YUMURTALIK",
        "STREET": "AKDENİZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "YUMURTALIK",
        "STREET": "AKYUVA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "YUMURTALIK",
        "STREET": "ASMALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "YUMURTALIK",
        "STREET": "AYAS MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "YUMURTALIK",
        "STREET": "AYVALIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "YUMURTALIK",
        "STREET": "DEMİRTAŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "YUMURTALIK",
        "STREET": "DEVECİUŞAĞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "YUMURTALIK",
        "STREET": "DEVRİŞİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "YUMURTALIK",
        "STREET": "GÖLOVASI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "YUMURTALIK",
        "STREET": "HAMZALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "YUMURTALIK",
        "STREET": "HAYLAZLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "YUMURTALIK",
        "STREET": "KALDIRIM MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "YUMURTALIK",
        "STREET": "KALEMLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "YUMURTALIK",
        "STREET": "KEMALPAŞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "YUMURTALIK",
        "STREET": "KESMEBURUN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "YUMURTALIK",
        "STREET": "KIRMIZIDAM MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "YUMURTALIK",
        "STREET": "KUZUPINARI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "YUMURTALIK",
        "STREET": "NARLIÖREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "YUMURTALIK",
        "STREET": "SUGÖZÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "YUMURTALIK",
        "STREET": "YENİKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "YUMURTALIK",
        "STREET": "YEŞİLKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "YUMURTALIK",
        "STREET": "YUMURTALIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "YUMURTALIK",
        "STREET": "ZEYTİNBELİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "YUMURTALIK",
        "STREET": "ÖREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "YÜREĞİR",
        "STREET": "19 MAYIS MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "YÜREĞİR",
        "STREET": "ABDİOĞLU CUMHURİYET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "YÜREĞİR",
        "STREET": "AKARCALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "YÜREĞİR",
        "STREET": "AKDAM MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "YÜREĞİR",
        "STREET": "AKDENİZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "YÜREĞİR",
        "STREET": "AKINCILAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "YÜREĞİR",
        "STREET": "AKPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "YÜREĞİR",
        "STREET": "ALİHOCALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "YÜREĞİR",
        "STREET": "ANADOLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "YÜREĞİR",
        "STREET": "ATAKENT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "YÜREĞİR",
        "STREET": "ATATÜRK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "YÜREĞİR",
        "STREET": "AYDINCIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "YÜREĞİR",
        "STREET": "AĞZIBÜYÜK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "YÜREĞİR",
        "STREET": "BAHÇELİEVLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "YÜREĞİR",
        "STREET": "BAŞAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "YÜREĞİR",
        "STREET": "BELÖREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "YÜREĞİR",
        "STREET": "BEYKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "YÜREĞİR",
        "STREET": "BÜYÜKKAPILI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "YÜREĞİR",
        "STREET": "CAMİLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "YÜREĞİR",
        "STREET": "CIRIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "YÜREĞİR",
        "STREET": "CUMHURİYET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "YÜREĞİR",
        "STREET": "CİNE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "YÜREĞİR",
        "STREET": "DADALOĞLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "YÜREĞİR",
        "STREET": "DANIŞMENT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "YÜREĞİR",
        "STREET": "DEDE KORKUT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "YÜREĞİR",
        "STREET": "DEDEPINARI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "YÜREĞİR",
        "STREET": "DENİZKUYUSU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "YÜREĞİR",
        "STREET": "DERVİŞLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "YÜREĞİR",
        "STREET": "DOĞANKENT BAHÇELİEVLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "YÜREĞİR",
        "STREET": "DOĞANKENT CUMHURİYET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "YÜREĞİR",
        "STREET": "DOĞANKENT KIŞLA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "YÜREĞİR",
        "STREET": "DÜZCE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "YÜREĞİR",
        "STREET": "ESENLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "YÜREĞİR",
        "STREET": "ESKİ MİSİS MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "YÜREĞİR",
        "STREET": "EĞRİAĞAÇ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "YÜREĞİR",
        "STREET": "GAZİPAŞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "YÜREĞİR",
        "STREET": "GEÇİTLİ CUMHURİYET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "YÜREĞİR",
        "STREET": "GÖKÇELİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "YÜREĞİR",
        "STREET": "GÜMÜŞYAZI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "YÜREĞİR",
        "STREET": "GÜNEŞLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "YÜREĞİR",
        "STREET": "GÜVELOĞLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "YÜREĞİR",
        "STREET": "GÜZEL CUMHURİYET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "YÜREĞİR",
        "STREET": "GÜZELEVLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "YÜREĞİR",
        "STREET": "HACIALİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "YÜREĞİR",
        "STREET": "HAVRANİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "YÜREĞİR",
        "STREET": "HAVUTLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "YÜREĞİR",
        "STREET": "HAYDAROĞLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "YÜREĞİR",
        "STREET": "HEREKLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "YÜREĞİR",
        "STREET": "IRMAKBAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "YÜREĞİR",
        "STREET": "KADIKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "YÜREĞİR",
        "STREET": "KAMIŞLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "YÜREĞİR",
        "STREET": "KARAAHMETLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "YÜREĞİR",
        "STREET": "KARACAOĞLAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "YÜREĞİR",
        "STREET": "KAYARLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "YÜREĞİR",
        "STREET": "KAZIM KARABEKİR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "YÜREĞİR",
        "STREET": "KAŞLICA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "YÜREĞİR",
        "STREET": "KIŞLA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "YÜREĞİR",
        "STREET": "KOZA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "YÜREĞİR",
        "STREET": "KÖKLÜCE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "YÜREĞİR",
        "STREET": "KÖPRÜGÖZÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "YÜREĞİR",
        "STREET": "KÖPRÜLÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "YÜREĞİR",
        "STREET": "KÜTÜKLÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "YÜREĞİR",
        "STREET": "KİREMİTHANE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "YÜREĞİR",
        "STREET": "LEVENT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "YÜREĞİR",
        "STREET": "MUTLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "YÜREĞİR",
        "STREET": "P.T.T MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "YÜREĞİR",
        "STREET": "PAŞAKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "YÜREĞİR",
        "STREET": "PEKMEZLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "YÜREĞİR",
        "STREET": "SAKIZLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "YÜREĞİR",
        "STREET": "SARIÇAM MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "YÜREĞİR",
        "STREET": "SAZAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "YÜREĞİR",
        "STREET": "SAĞDIÇLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "YÜREĞİR",
        "STREET": "SELAHATTİN EYYUBİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "YÜREĞİR",
        "STREET": "SERİNEVLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "YÜREĞİR",
        "STREET": "SEYHAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "YÜREĞİR",
        "STREET": "SOLAKLI CUMHURİYET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "YÜREĞİR",
        "STREET": "SOLAKLI HÜRRİYET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "YÜREĞİR",
        "STREET": "SİNANPAŞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "YÜREĞİR",
        "STREET": "TAHSİLLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "YÜREĞİR",
        "STREET": "TAŞÇI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "YÜREĞİR",
        "STREET": "ULUBATLI HASAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "YÜREĞİR",
        "STREET": "VAYVAYLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "YÜREĞİR",
        "STREET": "YAHŞİLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "YÜREĞİR",
        "STREET": "YAKAPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "YÜREĞİR",
        "STREET": "YALNIZCA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "YÜREĞİR",
        "STREET": "YAMAÇLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "YÜREĞİR",
        "STREET": "YAVUZLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "YÜREĞİR",
        "STREET": "YENİCE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "YÜREĞİR",
        "STREET": "YENİDOĞAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "YÜREĞİR",
        "STREET": "YENİKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "YÜREĞİR",
        "STREET": "YERDELEN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "YÜREĞİR",
        "STREET": "YEŞİL BAĞLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "YÜREĞİR",
        "STREET": "YUKARIÇİÇEKLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "YÜREĞİR",
        "STREET": "YUNUS EMRE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "YÜREĞİR",
        "STREET": "YUNUSOĞLU CUMHURİYET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "YÜREĞİR",
        "STREET": "YUNUSOĞLU HÜRRİYET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "YÜREĞİR",
        "STREET": "ZAĞARLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "YÜREĞİR",
        "STREET": "ÇAMLIBEL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "YÜREĞİR",
        "STREET": "ÇATALPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "YÜREĞİR",
        "STREET": "ÇAĞIRKANLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "YÜREĞİR",
        "STREET": "ÇELEMLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "YÜREĞİR",
        "STREET": "ÇOTLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "YÜREĞİR",
        "STREET": "ÖZGÜR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "YÜREĞİR",
        "STREET": "ÖZLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "YÜREĞİR",
        "STREET": "ŞAHİNAĞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "YÜREĞİR",
        "STREET": "ŞEHİT ERKUT AKBAY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "YÜREĞİR",
        "STREET": "ŞEYHMURAT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "ÇUKUROVA",
        "STREET": "BELEDİYE EVLERİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "ÇUKUROVA",
        "STREET": "BEYAZEVLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "ÇUKUROVA",
        "STREET": "BOZCALAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "ÇUKUROVA",
        "STREET": "DÖRTLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "ÇUKUROVA",
        "STREET": "ESENTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "ÇUKUROVA",
        "STREET": "FADIL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "ÇUKUROVA",
        "STREET": "GÖKKUYU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "ÇUKUROVA",
        "STREET": "GÜZELYALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "ÇUKUROVA",
        "STREET": "HUZUREVLERİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "ÇUKUROVA",
        "STREET": "KABASAKAL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "ÇUKUROVA",
        "STREET": "KARAHAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "ÇUKUROVA",
        "STREET": "KARSLILAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "ÇUKUROVA",
        "STREET": "KAŞOBA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "ÇUKUROVA",
        "STREET": "KOCATEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "ÇUKUROVA",
        "STREET": "KURTTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "ÇUKUROVA",
        "STREET": "KÜÇÜKÇINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "ÇUKUROVA",
        "STREET": "MAHFESIĞMAZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "ÇUKUROVA",
        "STREET": "MEMİŞLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "ÇUKUROVA",
        "STREET": "PİRİLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "ÇUKUROVA",
        "STREET": "SALBAŞ ESENTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "ÇUKUROVA",
        "STREET": "SÖĞÜTLÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "ÇUKUROVA",
        "STREET": "TOROS MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "ÇUKUROVA",
        "STREET": "YENİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "ÇUKUROVA",
        "STREET": "YURT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "ÇUKUROVA",
        "STREET": "YÜZÜNCÜYIL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "ÇUKUROVA",
        "STREET": "ÖRCÜN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "ÇUKUROVA",
        "STREET": "ŞAMBAYADI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "İMAMOĞLU",
        "STREET": "ADALET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "İMAMOĞLU",
        "STREET": "ALAYBEYİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "İMAMOĞLU",
        "STREET": "ALİLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "İMAMOĞLU",
        "STREET": "AYVALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "İMAMOĞLU",
        "STREET": "AĞZIKARACA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "İMAMOĞLU",
        "STREET": "CAMİLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "İMAMOĞLU",
        "STREET": "CUMHURİYET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "İMAMOĞLU",
        "STREET": "DANACILI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "İMAMOĞLU",
        "STREET": "FATİH MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "İMAMOĞLU",
        "STREET": "HACIHASANLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "İMAMOĞLU",
        "STREET": "HÜRRİYET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "İMAMOĞLU",
        "STREET": "KOYUNEVİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "İMAMOĞLU",
        "STREET": "MALIHIDIRLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "İMAMOĞLU",
        "STREET": "MENTEŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "İMAMOĞLU",
        "STREET": "OTLUK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "İMAMOĞLU",
        "STREET": "PEKMEZCİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "İMAMOĞLU",
        "STREET": "SAYCA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "İMAMOĞLU",
        "STREET": "SAYGEÇİT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "İMAMOĞLU",
        "STREET": "SEVİNÇLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "İMAMOĞLU",
        "STREET": "SOKUTAŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "İMAMOĞLU",
        "STREET": "TUNA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "İMAMOĞLU",
        "STREET": "UFACIKÖREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "İMAMOĞLU",
        "STREET": "ULUÇINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "İMAMOĞLU",
        "STREET": "YAZITEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "İMAMOĞLU",
        "STREET": "YENİEVLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "İMAMOĞLU",
        "STREET": "ÇÖRTEN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADANA",
        "DISTRICT": "İMAMOĞLU",
        "STREET": "ÜÇTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "100.YIL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "AHMETHOCA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "AKDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "AKHİSAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "AKPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "AKYAZI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "AKÇALI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "ALBET KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "ALTINŞEHİR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "ALİBEY KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "ALİTAŞI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "ATAKENT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "ATAKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "AYDINLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "AĞAÇKONAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "AĞCİN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "AĞVEREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "AĞİKAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "BAHÇECİK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "BAHÇELİEVLER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "BARBAROS HAYRETTİN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "BATTALHÜYÜK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "BAĞDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "BAĞLICA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "BAĞPINAR KUYUCAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "BAĞPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "BAŞPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "BEBEK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "BOZATLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "BOZHÜYÜK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "BOZTEPE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "BÖRKENEK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "BÜKLÜM KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "BÜYÜKKAVAKLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "BÜYÜKKIRIKLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "CUMHURİYET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "DAMDIRMAZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "DAMLICA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "DARDAĞAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "DAVUTHAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "DERİNSU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "DOYRAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "DOĞANLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "DURAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "DURUKAYNAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "DÜZCE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "DİŞBUDAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "EKİNCİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "ELMACIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "ESENCE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "ESENTEPE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "ESKİHÜSNÜMANSUR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "ESKİSARAY MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "FATİH MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "GÖKÇAY KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "GÖLPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "GÖZEBAŞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "GÖZTEPE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "GÜMÜŞKAYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "GÜNEŞLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "GÜZELYURT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "GİRİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "HACIHALİL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "HASANCIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "HASANKENDİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "HOCAÖMER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "ILICAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "IŞIKLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "KALBURCU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "KAPCAMİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "KARAAĞAÇ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "KARAGÖL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "KARAHÖYÜK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "KARAKOÇ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "KARAPINAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "KAVAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "KAYACIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "KAYADİBİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "KAYALI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "KAYALIK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "KAYAÖNÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "KAŞKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "KEMERKAYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "KINDIRALİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "KIZILCAHÖYÜK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "KIZILCAPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "KORUKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "KOZAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "KOÇALİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "KUYUCAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "KUYULU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "KUŞAKKAYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "KUŞTEPE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "KÖMÜR-BAHÇELİEVLER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "KÖMÜR-CUMHURİYET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "KÖMÜR-ESENCE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "KÖMÜR-GAZİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "KÖMÜR-HASANKAN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "KÖMÜR-HÜRRİYET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "KÖMÜR-MUSTAFA YÜCEL ÖZBİLGİN  MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "KÖMÜR-SERİNTEPE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "KÖMÜR-YAKACIK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "KÖMÜR-YEŞİLYURT MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "KÜLAFHÜYÜK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "KÜÇÜKHASANCIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "LOKMAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "MALAZGİRT MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "MALPINARI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "MARA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "MEHMET AKİF MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "MESTAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "MUSALLA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "MİMAR SİNAN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "OLGUNLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "OLUKLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "ORMANİÇİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "PALANLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "PAYAMLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "PAŞAMEZRASI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "PINARYAYLA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "REZİP KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "SARIHARMAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "SARIKAYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "SERHATLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "SIRATUT MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "SÜMEREVLER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "SİTELER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "TAŞGEDİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "TAŞPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "TECİR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "TEKPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "TERMAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "TOPTEPE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "TURGUT REİS MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "TÜRKİYE PETROLLERİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "ULUCAMİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "ULUDAM KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "UZUNKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "UZUNPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "UĞURCA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "VARLIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "VARLIK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "YARMAKAYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "YAVUZ SELİM MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "YAYLADAMI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "YAYLAKONAK-KARAÇALI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "YAYLAKONAK-MERKEZ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "YAYLAKONAK-ZİVAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "YAYLAKONAK-ÇATDERESİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "YAYLAKONAK-ÇİMENKE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "YAYLAKONAK-İNCEKOZ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "YAZIBAŞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "YAZLIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "YEDİOLUK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "YENİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "YENİ SANAYİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "YENİCE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "YENİGÜVEN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "YENİKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "YENİPINAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "YEŞİLOVA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "YEŞİLYURT MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "YUNUS EMRE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "ZEY KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "ZİYARETPAYAMLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇAKMAKLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇAMGAZİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇAMLICA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇAMYURDU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇATALAĞAÇ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇAYIRLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇAYLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇEMBERLİTAŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇENÇENG KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇINARİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇOBANDEDE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "ÖRENLİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "ÜÇDİREK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "İMAMAĞA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "İNCEBAĞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "İNCELER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "İPEKLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "ŞEMİKAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "ŞEREFLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "BESNİ",
        "STREET": "15 TEMMUZ ŞEHİTLER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "BESNİ",
        "STREET": "AKDURAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "BESNİ",
        "STREET": "AKKUYU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "BESNİ",
        "STREET": "AKPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "BESNİ",
        "STREET": "AKTEPE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "BESNİ",
        "STREET": "AKYAZI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "BESNİ",
        "STREET": "ALIÇLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "BESNİ",
        "STREET": "ALİ ERDEMOĞLU MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "BESNİ",
        "STREET": "ALİŞAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "BESNİ",
        "STREET": "ATMALI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "BESNİ",
        "STREET": "AŞAĞISARHAN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "BESNİ",
        "STREET": "AŞAĞIÇÖPLÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "BESNİ",
        "STREET": "BAHRİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "BESNİ",
        "STREET": "BAŞLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "BESNİ",
        "STREET": "BEREKETLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "BESNİ",
        "STREET": "BERETE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "BESNİ",
        "STREET": "BEŞKOZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "BESNİ",
        "STREET": "BEŞYOL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "BESNİ",
        "STREET": "BONCUK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "BESNİ",
        "STREET": "BURUNÇAYIR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "BESNİ",
        "STREET": "CAMUŞÇU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "BESNİ",
        "STREET": "CİRİT MEYDANI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "BESNİ",
        "STREET": "DOĞANKAYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "BESNİ",
        "STREET": "DUMLUPINAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "BESNİ",
        "STREET": "DÖRTYOL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "BESNİ",
        "STREET": "DİKİLİTAŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "BESNİ",
        "STREET": "ERDEMOĞLU MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "BESNİ",
        "STREET": "EĞERLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "BESNİ",
        "STREET": "GEÇİTLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "BESNİ",
        "STREET": "GÜMÜŞLÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "BESNİ",
        "STREET": "GÜNEYKAŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "BESNİ",
        "STREET": "HACIHALİL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "BESNİ",
        "STREET": "HARMANARDI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "BESNİ",
        "STREET": "HASANLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "BESNİ",
        "STREET": "KARAGÜVEÇ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "BESNİ",
        "STREET": "KARALAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "BESNİ",
        "STREET": "KARGALI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "BESNİ",
        "STREET": "KAYMAKAM HASAN TÜTÜN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "BESNİ",
        "STREET": "KESECİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "BESNİ",
        "STREET": "KESMETEPE-ATATÜRK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "BESNİ",
        "STREET": "KESMETEPE-FATİH MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "BESNİ",
        "STREET": "KESMETEPE-TURGUT ÖZAL MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "BESNİ",
        "STREET": "KESMETEPE-ÇAKMAK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "BESNİ",
        "STREET": "KESMETEPE-İNCE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "BESNİ",
        "STREET": "KESMETEPE-İNÖNÜ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "BESNİ",
        "STREET": "KIZILHİSAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "BESNİ",
        "STREET": "KIZILKAYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "BESNİ",
        "STREET": "KIZILPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "BESNİ",
        "STREET": "KIZILİN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "BESNİ",
        "STREET": "KONUKLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "BESNİ",
        "STREET": "KORUPINAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "BESNİ",
        "STREET": "KURUGÖL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "BESNİ",
        "STREET": "KUTLUCA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "BESNİ",
        "STREET": "KUZEVLERİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "BESNİ",
        "STREET": "KÖSECELİ-AŞAĞISÖĞÜTLÜ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "BESNİ",
        "STREET": "KÖSECELİ-CUMHURİYET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "BESNİ",
        "STREET": "KÖSECELİ-HÜRRİYET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "BESNİ",
        "STREET": "KÖSECELİ-TETİRLİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "BESNİ",
        "STREET": "OYALI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "BESNİ",
        "STREET": "OYRATLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "BESNİ",
        "STREET": "PINARBAŞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "BESNİ",
        "STREET": "PINARBAŞI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "BESNİ",
        "STREET": "SARIKAYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "BESNİ",
        "STREET": "SARIYAPRAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "BESNİ",
        "STREET": "SAYÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "BESNİ",
        "STREET": "SUGÖZÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "BESNİ",
        "STREET": "SUVARLI-ADALET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "BESNİ",
        "STREET": "SUVARLI-CUMHURİYET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "BESNİ",
        "STREET": "SUVARLI-HÜRRİYET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "BESNİ",
        "STREET": "TAŞLIYAZI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "BESNİ",
        "STREET": "TEKAĞAÇ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "BESNİ",
        "STREET": "TOKAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "BESNİ",
        "STREET": "TOKLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "BESNİ",
        "STREET": "TOPKAPI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "BESNİ",
        "STREET": "UZUNKUYU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "BESNİ",
        "STREET": "YAYIKLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "BESNİ",
        "STREET": "YAZIBEYDİLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "BESNİ",
        "STREET": "YAZIKARAKUYU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "BESNİ",
        "STREET": "YELBASTI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "BESNİ",
        "STREET": "YENİ BESNİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "BESNİ",
        "STREET": "YENİKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "BESNİ",
        "STREET": "YOLDÜZÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "BESNİ",
        "STREET": "YUKARI SARHAN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "BESNİ",
        "STREET": "YUKARISÖĞÜTLÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "BESNİ",
        "STREET": "ÇAKIRHÜYÜK-ABIMISTIK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "BESNİ",
        "STREET": "ÇAKIRHÜYÜK-BOYBEYPINARI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "BESNİ",
        "STREET": "ÇAKIRHÜYÜK-KÖPRÜBAŞI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "BESNİ",
        "STREET": "ÇAKIRHÜYÜK-LEVZİN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "BESNİ",
        "STREET": "ÇAKIRHÜYÜK-YEŞİLOVA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "BESNİ",
        "STREET": "ÇAMLICA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "BESNİ",
        "STREET": "ÇAT MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "BESNİ",
        "STREET": "ÇAYKAYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "BESNİ",
        "STREET": "ÇOMAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "BESNİ",
        "STREET": "ÇORAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "BESNİ",
        "STREET": "ÇİLBOĞAZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "BESNİ",
        "STREET": "ÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "BESNİ",
        "STREET": "ÜÇGÖZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "BESNİ",
        "STREET": "İZOLLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "BESNİ",
        "STREET": "ŞAMBAYAT-BAYAT MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "BESNİ",
        "STREET": "ŞAMBAYAT-CUMHURİYET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "BESNİ",
        "STREET": "ŞAMBAYAT-FATİH MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "BESNİ",
        "STREET": "ŞAMBAYAT-MENDERES MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "BESNİ",
        "STREET": "ŞAMBAYAT-YENİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "BESNİ",
        "STREET": "ŞAMBAYAT-İNÖNÜ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "GERGER",
        "STREET": "AÇMA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "GERGER",
        "STREET": "AĞAÇLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "GERGER",
        "STREET": "AŞAĞIDAĞLICA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "GERGER",
        "STREET": "BEYBOSTAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "GERGER",
        "STREET": "BEŞGÖZE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "GERGER",
        "STREET": "BUDAKLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "GERGER",
        "STREET": "BURÇAKLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "GERGER",
        "STREET": "CEVİZPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "GERGER",
        "STREET": "DALLARCA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "GERGER",
        "STREET": "DAĞDEVİREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "GERGER",
        "STREET": "DEMİRTAŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "GERGER",
        "STREET": "ESKİKENT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "GERGER",
        "STREET": "FATİH MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "GERGER",
        "STREET": "GEÇİTLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "GERGER",
        "STREET": "GÖLYURT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "GERGER",
        "STREET": "GÖNAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "GERGER",
        "STREET": "GÖZPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "GERGER",
        "STREET": "GÜMÜŞKAŞIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "GERGER",
        "STREET": "GÜNDOĞDU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "GERGER",
        "STREET": "GÜNGÖRMÜŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "GERGER",
        "STREET": "GÜRGENLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "GERGER",
        "STREET": "GÜZELSU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "GERGER",
        "STREET": "KAŞYAZI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "GERGER",
        "STREET": "KELEŞAN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "GERGER",
        "STREET": "KESERTAŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "GERGER",
        "STREET": "KILIÇ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "GERGER",
        "STREET": "KONACIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "GERGER",
        "STREET": "KORULU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "GERGER",
        "STREET": "KOŞARLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "GERGER",
        "STREET": "KÖKLÜCE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "GERGER",
        "STREET": "KÜTÜKLÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "GERGER",
        "STREET": "MERKEZ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "GERGER",
        "STREET": "NAKIŞLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "GERGER",
        "STREET": "ONEVLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "GERGER",
        "STREET": "ORTACA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "GERGER",
        "STREET": "OYMAKLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "GERGER",
        "STREET": "SARAYCIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "GERGER",
        "STREET": "SEYİTMAHMUT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "GERGER",
        "STREET": "SUTEPE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "GERGER",
        "STREET": "YAYLADALI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "GERGER",
        "STREET": "YAĞMURLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "GERGER",
        "STREET": "YENİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "GERGER",
        "STREET": "YENİBARDAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "GERGER",
        "STREET": "YEŞİLYURT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "GERGER",
        "STREET": "YUKARIDAĞLICA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "GERGER",
        "STREET": "ÇAMİÇİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "GERGER",
        "STREET": "ÇOBANPINARI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "GERGER",
        "STREET": "ÇİFTHİSAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "GERGER",
        "STREET": "ÜÇKAYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "GÖLBAŞI",
        "STREET": "AKTOPRAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "GÖLBAŞI",
        "STREET": "AKÇABEL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "GÖLBAŞI",
        "STREET": "AKÇAKAYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "GÖLBAŞI",
        "STREET": "ASFALT MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "GÖLBAŞI",
        "STREET": "AŞAĞIAZAPLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "GÖLBAŞI",
        "STREET": "AŞAĞIKARAKUYU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "GÖLBAŞI",
        "STREET": "AŞAĞINASIRLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "GÖLBAŞI",
        "STREET": "BALKAR-ASFALT MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "GÖLBAŞI",
        "STREET": "BALKAR-CUMHURİYET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "GÖLBAŞI",
        "STREET": "BAĞLARBAŞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "GÖLBAŞI",
        "STREET": "BELÖREN-CUMHURİYET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "GÖLBAŞI",
        "STREET": "BELÖREN-MERKEZ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "GÖLBAŞI",
        "STREET": "CANKARA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "GÖLBAŞI",
        "STREET": "CUMHURİYET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "GÖLBAŞI",
        "STREET": "FATİH MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "GÖLBAŞI",
        "STREET": "GEDİKLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "GÖLBAŞI",
        "STREET": "HACILAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "GÖLBAŞI",
        "STREET": "HAMZALAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "GÖLBAŞI",
        "STREET": "HARMANLI-CUMHURİYET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "GÖLBAŞI",
        "STREET": "HARMANLI-KALEMKAS MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "GÖLBAŞI",
        "STREET": "HARMANLI-YENİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "GÖLBAŞI",
        "STREET": "HARMANLI-ÇAKMAK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "GÖLBAŞI",
        "STREET": "HAYDARLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "GÖLBAŞI",
        "STREET": "HÜRRİYET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "GÖLBAŞI",
        "STREET": "KARABAHŞILI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "GÖLBAŞI",
        "STREET": "KARABURUN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "GÖLBAŞI",
        "STREET": "KURUGEÇİT MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "GÖLBAŞI",
        "STREET": "KÖSÜKLÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "GÖLBAŞI",
        "STREET": "KÜÇÜKÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "GÖLBAŞI",
        "STREET": "MEYDAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "GÖLBAŞI",
        "STREET": "MİMAR SİNAN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "GÖLBAŞI",
        "STREET": "OZAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "GÖLBAŞI",
        "STREET": "SAVRAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "GÖLBAŞI",
        "STREET": "YARBAŞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "GÖLBAŞI",
        "STREET": "YAVUZ SELİM MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "GÖLBAŞI",
        "STREET": "YAYLACIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "GÖLBAŞI",
        "STREET": "YENİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "GÖLBAŞI",
        "STREET": "YENİKENT MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "GÖLBAŞI",
        "STREET": "YENİKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "GÖLBAŞI",
        "STREET": "YEŞİLOVA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "GÖLBAŞI",
        "STREET": "YUKARIKARAKUYU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "GÖLBAŞI",
        "STREET": "YUKARINASIRLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "GÖLBAŞI",
        "STREET": "YUKARIÇÖPLÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "GÖLBAŞI",
        "STREET": "ÇATALAĞAÇ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "GÖLBAŞI",
        "STREET": "ÇATALTEPE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "GÖLBAŞI",
        "STREET": "ÇELİKKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "GÖLBAŞI",
        "STREET": "ÖRENLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "KAHTA",
        "STREET": "ADALI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "KAHTA",
        "STREET": "AKALIN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "KAHTA",
        "STREET": "AKDOĞAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "KAHTA",
        "STREET": "AKINCILAR-AKINCILAR ATATÜRK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "KAHTA",
        "STREET": "AKINCILAR-KILAVUZ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "KAHTA",
        "STREET": "AKINCILAR-SOĞUKSU MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "KAHTA",
        "STREET": "AKINCILAR-ÇAMLICA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "KAHTA",
        "STREET": "AKKAVAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "KAHTA",
        "STREET": "AKKUŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "KAHTA",
        "STREET": "AKTAŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "KAHTA",
        "STREET": "AKYILDIZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "KAHTA",
        "STREET": "ALİDAM KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "KAHTA",
        "STREET": "ARILI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "KAHTA",
        "STREET": "ATATÜRK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "KAHTA",
        "STREET": "AYDINPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "KAHTA",
        "STREET": "BALLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "KAHTA",
        "STREET": "BAYRAKTAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "KAHTA",
        "STREET": "BAĞBAŞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "KAHTA",
        "STREET": "BAĞLAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "KAHTA",
        "STREET": "BAĞÖZÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "KAHTA",
        "STREET": "BELENLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "KAHTA",
        "STREET": "BELÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "KAHTA",
        "STREET": "BEŞİKLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "KAHTA",
        "STREET": "BOSTANLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "KAHTA",
        "STREET": "BOZPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "KAHTA",
        "STREET": "BOZTARLA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "KAHTA",
        "STREET": "BOĞAZKAYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "KAHTA",
        "STREET": "BURMAPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "KAHTA",
        "STREET": "BÖLÜKYAYLA-ATATÜRK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "KAHTA",
        "STREET": "BÖLÜKYAYLA-CUMHURİYET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "KAHTA",
        "STREET": "BÖLÜKYAYLA-FATİH MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "KAHTA",
        "STREET": "BÖLÜKYAYLA-KANGÜLÜ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "KAHTA",
        "STREET": "BÜYÜKBAĞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "KAHTA",
        "STREET": "BÜYÜKBEJYAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "KAHTA",
        "STREET": "BÜYÜKBEY KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "KAHTA",
        "STREET": "CAMİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "KAHTA",
        "STREET": "CUMHURİYET KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "KAHTA",
        "STREET": "CUMHURİYET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "KAHTA",
        "STREET": "DAMLACIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "KAHTA",
        "STREET": "DARDAĞAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "KAHTA",
        "STREET": "DOLUCA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "KAHTA",
        "STREET": "DUMLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "KAHTA",
        "STREET": "DUT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "KAHTA",
        "STREET": "DİKENLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "KAHTA",
        "STREET": "ECELER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "KAHTA",
        "STREET": "EKİNCİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "KAHTA",
        "STREET": "ELBEYİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "KAHTA",
        "STREET": "ERİKDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "KAHTA",
        "STREET": "ERİKLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "KAHTA",
        "STREET": "ESENDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "KAHTA",
        "STREET": "ESKİ KAHTA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "KAHTA",
        "STREET": "ESKİTAŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "KAHTA",
        "STREET": "FATİH MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "KAHTA",
        "STREET": "FIRAT MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "KAHTA",
        "STREET": "FISTIKLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "KAHTA",
        "STREET": "GAZİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "KAHTA",
        "STREET": "GELDİBULDU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "KAHTA",
        "STREET": "GÖKÇE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "KAHTA",
        "STREET": "GÖLGELİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "KAHTA",
        "STREET": "GÖÇERİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "KAHTA",
        "STREET": "GÜDÜLGE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "KAHTA",
        "STREET": "GÜZELÇAY KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "KAHTA",
        "STREET": "GİRNE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "KAHTA",
        "STREET": "HABİPLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "KAHTA",
        "STREET": "HACIYUSUF KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "KAHTA",
        "STREET": "HAMZALAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "KAHTA",
        "STREET": "HASANDİĞİN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "KAHTA",
        "STREET": "HASKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "KAHTA",
        "STREET": "HÜRRİYET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "KAHTA",
        "STREET": "IŞIKTEPE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "KAHTA",
        "STREET": "KARACAÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "KAHTA",
        "STREET": "KARADUT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "KAHTA",
        "STREET": "KARAMAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "KAHTA",
        "STREET": "KARATAŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "KAHTA",
        "STREET": "KARŞIYAKA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "KAHTA",
        "STREET": "KAVAKLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "KAHTA",
        "STREET": "KAYADİBİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "KAHTA",
        "STREET": "KOZAĞAÇ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "KAHTA",
        "STREET": "KOÇTEPE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "KAHTA",
        "STREET": "KÖSELER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "KAHTA",
        "STREET": "MENDERES MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "KAHTA",
        "STREET": "MENZİL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "KAHTA",
        "STREET": "MÜLKKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "KAHTA",
        "STREET": "NARLIDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "KAHTA",
        "STREET": "NARSIRTI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "KAHTA",
        "STREET": "NARİNCE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "KAHTA",
        "STREET": "OLUKLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "KAHTA",
        "STREET": "ORTANCA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "KAHTA",
        "STREET": "OVACIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "KAHTA",
        "STREET": "SALKIMBAĞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "KAHTA",
        "STREET": "SARISU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "KAHTA",
        "STREET": "SIRACA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "KAHTA",
        "STREET": "SIRAKAYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "KAHTA",
        "STREET": "SUSUZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "KAHTA",
        "STREET": "TAŞLICA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "KAHTA",
        "STREET": "TAŞLIÇAY KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "KAHTA",
        "STREET": "TEKNECİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "KAHTA",
        "STREET": "TEĞMENLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "KAHTA",
        "STREET": "TURANLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "KAHTA",
        "STREET": "TURGUT ÖZAL MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "KAHTA",
        "STREET": "TUĞLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "KAHTA",
        "STREET": "TÜTENOCAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "KAHTA",
        "STREET": "ULUPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "KAHTA",
        "STREET": "YAPRAKLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "KAHTA",
        "STREET": "YAVUZ SELİM MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "KAHTA",
        "STREET": "YELKOVAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "KAHTA",
        "STREET": "YENİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "KAHTA",
        "STREET": "YENİCE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "KAHTA",
        "STREET": "YENİKUŞAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "KAHTA",
        "STREET": "YEŞİLKAYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "KAHTA",
        "STREET": "YOLALTI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "KAHTA",
        "STREET": "ZEYTİN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "KAHTA",
        "STREET": "ZİYARET KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "KAHTA",
        "STREET": "ÇAKIREŞME KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "KAHTA",
        "STREET": "ÇALTILI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "KAHTA",
        "STREET": "ÇAMLICA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "KAHTA",
        "STREET": "ÇARDAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "KAHTA",
        "STREET": "ÇATALTEPE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "KAHTA",
        "STREET": "ÇAYBAŞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "KAHTA",
        "STREET": "ÇIRALIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "KAHTA",
        "STREET": "ÇOBANLI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "KAHTA",
        "STREET": "ÇUKURTAŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "KAHTA",
        "STREET": "İKİZCE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "KAHTA",
        "STREET": "İSLAMKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "KAHTA",
        "STREET": "ŞAHİNTEPE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "KAHTA",
        "STREET": "ŞENKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "KAHTA",
        "STREET": "ŞEYHBABA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "SAMSAT",
        "STREET": "AKDAMAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "SAMSAT",
        "STREET": "BARAJ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "SAMSAT",
        "STREET": "BAYIRLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "SAMSAT",
        "STREET": "BAĞARASI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "SAMSAT",
        "STREET": "DOĞANCA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "SAMSAT",
        "STREET": "GÖLPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "SAMSAT",
        "STREET": "GÖLTARLA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "SAMSAT",
        "STREET": "KALE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "SAMSAT",
        "STREET": "KIRMACIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "SAMSAT",
        "STREET": "KIZILÖZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "SAMSAT",
        "STREET": "KOVANOLUK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "SAMSAT",
        "STREET": "KUŞTEPE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "SAMSAT",
        "STREET": "OVACIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "SAMSAT",
        "STREET": "TAŞKUYU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "SAMSAT",
        "STREET": "TEPEÖNÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "SAMSAT",
        "STREET": "UZUNTEPE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "SAMSAT",
        "STREET": "YARIMBAĞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "SAMSAT",
        "STREET": "YAVUZ SELİM MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "SAMSAT",
        "STREET": "ÇİÇEK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "SAMSAT",
        "STREET": "ÖRENTAŞ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "SİNCİK",
        "STREET": "AKSU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "SİNCİK",
        "STREET": "ALANCIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "SİNCİK",
        "STREET": "ARIKONAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "SİNCİK",
        "STREET": "AYENGİN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "SİNCİK",
        "STREET": "BALKAYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "SİNCİK",
        "STREET": "CUMHURİYET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "SİNCİK",
        "STREET": "DİLEKTEPE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "SİNCİK",
        "STREET": "ESKİKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "SİNCİK",
        "STREET": "FATİH MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "SİNCİK",
        "STREET": "GEÇİTLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "SİNCİK",
        "STREET": "HASANLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "SİNCİK",
        "STREET": "HÜSEYİNLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "SİNCİK",
        "STREET": "KARAKÖSE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "SİNCİK",
        "STREET": "KARAMAN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "SİNCİK",
        "STREET": "MAHMUTOĞLU MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "SİNCİK",
        "STREET": "NARLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "SİNCİK",
        "STREET": "ONUR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "SİNCİK",
        "STREET": "PINARBAŞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "SİNCİK",
        "STREET": "SAKIZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "SİNCİK",
        "STREET": "SERİNCE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "SİNCİK",
        "STREET": "SUBAŞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "SİNCİK",
        "STREET": "SÖĞÜTLÜBAHÇE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "SİNCİK",
        "STREET": "TAŞKALE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "SİNCİK",
        "STREET": "UĞURLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "SİNCİK",
        "STREET": "YARPUZLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "SİNCİK",
        "STREET": "ZEYNEL ASLAN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "SİNCİK",
        "STREET": "ÇAMDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "SİNCİK",
        "STREET": "ÇAT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "SİNCİK",
        "STREET": "ÇATBAHÇE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "SİNCİK",
        "STREET": "İNLİCE-FIRAT MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "SİNCİK",
        "STREET": "İNLİCE-KARADAĞ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "SİNCİK",
        "STREET": "İNLİCE-TAŞLICA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "SİNCİK",
        "STREET": "İNLİCE-TUNA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "SİNCİK",
        "STREET": "İNLİCE-ÇINAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "SİNCİK",
        "STREET": "İNLİCE-ÇÜKAN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "SİNCİK",
        "STREET": "İNLİCE-ÖZBİLGİN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "SİNCİK",
        "STREET": "ŞAHKOLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "SİNCİK",
        "STREET": "ŞAHİNBEYLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "TUT",
        "STREET": "AKÇATEPE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "TUT",
        "STREET": "AYNİYE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "TUT",
        "STREET": "BOYUNDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "TUT",
        "STREET": "BULANIK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "TUT",
        "STREET": "CUMHURİYET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "TUT",
        "STREET": "ELÇİLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "TUT",
        "STREET": "FETHİYE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "TUT",
        "STREET": "HAVUTLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "TUT",
        "STREET": "KAŞLICA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "TUT",
        "STREET": "KÖSELİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "TUT",
        "STREET": "MERYEMUŞAĞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "TUT",
        "STREET": "REŞADİYE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "TUT",
        "STREET": "SALAH MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "TUT",
        "STREET": "TEPECİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "TUT",
        "STREET": "YALANKOZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "TUT",
        "STREET": "YAYLIMLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "TUT",
        "STREET": "YEŞİLYURT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "TUT",
        "STREET": "ÇAMLICA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "TUT",
        "STREET": "ÇİFTLİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "TUT",
        "STREET": "ÖĞÜTLÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "TUT",
        "STREET": "ÜNLÜCE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "ÇELİKHAN",
        "STREET": "AKSU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "ÇELİKHAN",
        "STREET": "ALTINTAŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "ÇELİKHAN",
        "STREET": "ASKERHAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "ÇELİKHAN",
        "STREET": "BAHÇELİ EVLER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "ÇELİKHAN",
        "STREET": "BAŞPINAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "ÇELİKHAN",
        "STREET": "BOZGEDİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "ÇELİKHAN",
        "STREET": "CAMİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "ÇELİKHAN",
        "STREET": "CUMHURİYET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "ÇELİKHAN",
        "STREET": "FATİH KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "ÇELİKHAN",
        "STREET": "FATİH MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "ÇELİKHAN",
        "STREET": "GÖLBAĞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "ÇELİKHAN",
        "STREET": "KALECİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "ÇELİKHAN",
        "STREET": "KARAGÖL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "ÇELİKHAN",
        "STREET": "KARAÇAYIR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "ÇELİKHAN",
        "STREET": "KORUCAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "ÇELİKHAN",
        "STREET": "KÖSEUŞAĞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "ÇELİKHAN",
        "STREET": "MAHMUT NEDİM ÖKMEN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "ÇELİKHAN",
        "STREET": "MERKEZ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "ÇELİKHAN",
        "STREET": "MUTLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "ÇELİKHAN",
        "STREET": "PINARBAŞI-AKTAŞ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "ÇELİKHAN",
        "STREET": "PINARBAŞI-BALIKBURUN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "ÇELİKHAN",
        "STREET": "PINARBAŞI-CAMİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "ÇELİKHAN",
        "STREET": "PINARBAŞI-HACILAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "ÇELİKHAN",
        "STREET": "PINARBAŞI-KAYA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "ÇELİKHAN",
        "STREET": "PINARBAŞI-KURUDERE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "ÇELİKHAN",
        "STREET": "PINARBAŞI-ÇAMLIYAYLA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "ÇELİKHAN",
        "STREET": "PINARBAŞI-İNÖNÜ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "ÇELİKHAN",
        "STREET": "RECEP KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "ÇELİKHAN",
        "STREET": "YAĞIZATLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "ÇELİKHAN",
        "STREET": "YEŞİLOVA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "ÇELİKHAN",
        "STREET": "YEŞİLTEPE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "ÇELİKHAN",
        "STREET": "YEŞİLYAYLA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "ÇELİKHAN",
        "STREET": "YUNUS EMRE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "ÇELİKHAN",
        "STREET": "ZAFER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "ÇELİKHAN",
        "STREET": "ÇAMPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "ÇELİKHAN",
        "STREET": "İNCİRLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ADIYAMAN",
        "DISTRICT": "ÇELİKHAN",
        "STREET": "ŞEREFHAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "MERKEZ",
        "STREET": "AFYONKARAHİSAR 1 NOLU T TİPİKAPALI CEZAEVİ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "MERKEZ",
        "STREET": "AFYONKARAHİSAR 2 NOLU T TİPİKAPALI CEZAEVİ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "MERKEZ",
        "STREET": "AFYONKARAHİSAR E TİPİKAPALI CEZAEVİ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "MERKEZ",
        "STREET": "AKMESCİT MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "MERKEZ",
        "STREET": "AKÇİN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "MERKEZ",
        "STREET": "ALCALI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "MERKEZ",
        "STREET": "ALİ ÇETİNKAYA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "MERKEZ",
        "STREET": "ALİ İHSAN PAŞA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "MERKEZ",
        "STREET": "ANITKAYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "MERKEZ",
        "STREET": "ATAKÖY MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "MERKEZ",
        "STREET": "BATTALGAZİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "MERKEZ",
        "STREET": "BAYATCIK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "MERKEZ",
        "STREET": "BAYRAMGAZİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "MERKEZ",
        "STREET": "BELKARACAÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "MERKEZ",
        "STREET": "BEYAZIT MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "MERKEZ",
        "STREET": "BEYYAZI-ATATÜRK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "MERKEZ",
        "STREET": "BEYYAZI-CUMHURİYET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "MERKEZ",
        "STREET": "BEYYAZI-ÖRNEK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "MERKEZ",
        "STREET": "BOSTANLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "MERKEZ",
        "STREET": "BURHANİYE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "MERKEZ",
        "STREET": "BURMALI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "MERKEZ",
        "STREET": "CUMHURİYET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "MERKEZ",
        "STREET": "DAİRECEP MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "MERKEZ",
        "STREET": "DEMİRÇEVRE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "MERKEZ",
        "STREET": "DERVİŞ PAŞA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "MERKEZ",
        "STREET": "DEĞİRMENAYVALI-KURTULUŞ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "MERKEZ",
        "STREET": "DEĞİRMENAYVALI-ŞEHİT AHMET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "MERKEZ",
        "STREET": "DEĞİRMENDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "MERKEZ",
        "STREET": "DUMLUPINAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "MERKEZ",
        "STREET": "DÖRTYOL MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "MERKEZ",
        "STREET": "ERENLER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "MERKEZ",
        "STREET": "ERKMEN-CUMHURİYET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "MERKEZ",
        "STREET": "ERKMEN-FEVZİ ÇAKMAK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "MERKEZ",
        "STREET": "ERKMEN-HÜRRİYET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "MERKEZ",
        "STREET": "ERTUĞRUL GAZİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "MERKEZ",
        "STREET": "ESENTEPE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "MERKEZ",
        "STREET": "EŞREFPAŞA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "MERKEZ",
        "STREET": "FAKIPAŞA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "MERKEZ",
        "STREET": "FATİH MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "MERKEZ",
        "STREET": "FETHİBEY-FATİH MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "MERKEZ",
        "STREET": "FETHİBEY-YAVUZ SELİM MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "MERKEZ",
        "STREET": "FETHİBEY-YUNUS EMRE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "MERKEZ",
        "STREET": "GAZİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "MERKEZ",
        "STREET": "GEBECELER-FATİH MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "MERKEZ",
        "STREET": "GEBECELER-KOCATEPE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "MERKEZ",
        "STREET": "GEBECELER-YENİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "MERKEZ",
        "STREET": "GEBECELER-ZAFER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "MERKEZ",
        "STREET": "GEBECELER-İSTİKLAL MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "MERKEZ",
        "STREET": "GÖZSÜZLÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "MERKEZ",
        "STREET": "GÜVENEVLER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "MERKEZ",
        "STREET": "HALIMORU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "MERKEZ",
        "STREET": "HAMİDİYE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "MERKEZ",
        "STREET": "HASAN KARAAĞAÇ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "MERKEZ",
        "STREET": "HATTAT KARAHİSAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "MERKEZ",
        "STREET": "HOCA AHMET YESEVİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "MERKEZ",
        "STREET": "IŞIKLAR-ATATÜRK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "MERKEZ",
        "STREET": "IŞIKLAR-CUMHURİYET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "MERKEZ",
        "STREET": "IŞIKLAR-HÜRRİYET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "MERKEZ",
        "STREET": "IŞIKLAR-YAKA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "MERKEZ",
        "STREET": "IŞIKLAR-YENİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "MERKEZ",
        "STREET": "IŞIKLAR-İSTİKLAL MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "MERKEZ",
        "STREET": "KANLICA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "MERKEZ",
        "STREET": "KAPLANLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "MERKEZ",
        "STREET": "KARAARSLAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "MERKEZ",
        "STREET": "KARAMAN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "MERKEZ",
        "STREET": "KARŞIYAKA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "MERKEZ",
        "STREET": "KASIMPAŞA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "MERKEZ",
        "STREET": "KAYADİBİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "MERKEZ",
        "STREET": "KIZILDAĞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "MERKEZ",
        "STREET": "KIŞLACIK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "MERKEZ",
        "STREET": "KOCATEPE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "MERKEZ",
        "STREET": "KOCATEPE-KOCATEPE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "MERKEZ",
        "STREET": "KOCATEPE-KURTULUŞ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "MERKEZ",
        "STREET": "KOZLUCA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "MERKEZ",
        "STREET": "KÖPRÜLÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "MERKEZ",
        "STREET": "KÜÇÜKKALECİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "MERKEZ",
        "STREET": "KÜÇÜKÇOBANLI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "MERKEZ",
        "STREET": "MAREŞAL FEVZİ ÇAKMAK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "MERKEZ",
        "STREET": "MARULCU MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "MERKEZ",
        "STREET": "MECİDİYE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "MERKEZ",
        "STREET": "NAZMİ SAATÇİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "MERKEZ",
        "STREET": "NURİBEY-ALİ İHSAN PAŞA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "MERKEZ",
        "STREET": "NURİBEY-FATİH MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "MERKEZ",
        "STREET": "NURİBEY-MAREŞAL FEVZİ ÇAKMAK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "MERKEZ",
        "STREET": "NURİBEY-MEHMET AKİF MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "MERKEZ",
        "STREET": "OLUCAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "MERKEZ",
        "STREET": "OLUCAK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "MERKEZ",
        "STREET": "OMUZCA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "MERKEZ",
        "STREET": "ORHANGAZİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "MERKEZ",
        "STREET": "OSMAN GAZİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "MERKEZ",
        "STREET": "SAADET KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "MERKEZ",
        "STREET": "SADIKBEY MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "MERKEZ",
        "STREET": "SAHİPATA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "MERKEZ",
        "STREET": "SALAR-BARBAROS MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "MERKEZ",
        "STREET": "SALAR-FATİH MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "MERKEZ",
        "STREET": "SALAR-GÜMÜŞ TEPE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "MERKEZ",
        "STREET": "SALAR-HALİL İBRAHİM SULTAN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "MERKEZ",
        "STREET": "SALAR-YAVUZ SELİM MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "MERKEZ",
        "STREET": "SARAYDÜZÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "MERKEZ",
        "STREET": "SARIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "MERKEZ",
        "STREET": "SELÇUKLU MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "MERKEZ",
        "STREET": "SUSUZ-GÖKHAN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "MERKEZ",
        "STREET": "SUSUZ-OSMANLI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "MERKEZ",
        "STREET": "SUSUZ-SAKARYA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "MERKEZ",
        "STREET": "SUSUZ-SELÇUKLU MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "MERKEZ",
        "STREET": "SÜLÜMENLİ-ALTINDAĞ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "MERKEZ",
        "STREET": "SÜLÜMENLİ-CUMHURİYET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "MERKEZ",
        "STREET": "SÜLÜMENLİ-ULUCAMİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "MERKEZ",
        "STREET": "SÜLÜMENLİ-ZAFER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "MERKEZ",
        "STREET": "SÜLÜMENLİ-İSTİKLAL MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "MERKEZ",
        "STREET": "SÜLÜMENLİ-ŞEKER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "MERKEZ",
        "STREET": "SÜLÜN-GÜLTEPE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "MERKEZ",
        "STREET": "SÜLÜN-HİSAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "MERKEZ",
        "STREET": "SÜMER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "MERKEZ",
        "STREET": "TACI AHMET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "MERKEZ",
        "STREET": "TAŞPINAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "MERKEZ",
        "STREET": "VEYSEL KARANİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "MERKEZ",
        "STREET": "YARENLER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "MERKEZ",
        "STREET": "YARIMCA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "MERKEZ",
        "STREET": "YENİCE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "MERKEZ",
        "STREET": "YEŞİLYURT MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "MERKEZ",
        "STREET": "YUNUS EMRE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "MERKEZ",
        "STREET": "ZAFER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇAKIR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇAVDARLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇAYIRBAĞ-ALİ ÇETİNKAYA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇAYIRBAĞ-FATİH MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇAYIRBAĞ-HUZUR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇAYIRBAĞ-UĞUR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇIKRIK-ERENLER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇIKRIK-YUNUSLAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "MERKEZ",
        "STREET": "ÖRNEKEVLER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "MERKEZ",
        "STREET": "İSMAİL MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "MERKEZ",
        "STREET": "İSTİKLAL MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "BAYAT",
        "STREET": "AKPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "BAYAT",
        "STREET": "AŞAĞIÇAYBELEN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "BAYAT",
        "STREET": "BÜYÜK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "BAYAT",
        "STREET": "CUMHURİYET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "BAYAT",
        "STREET": "DERBENT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "BAYAT",
        "STREET": "ESKİGÖMÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "BAYAT",
        "STREET": "HÜRRİYET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "BAYAT",
        "STREET": "KUZÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "BAYAT",
        "STREET": "MALLICA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "BAYAT",
        "STREET": "MURATKORU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "BAYAT",
        "STREET": "SAĞIRLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "BAYAT",
        "STREET": "YENİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "BAYAT",
        "STREET": "YUKARIÇAYBELEN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "BAYAT",
        "STREET": "ÇUKURKUYU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "BAYAT",
        "STREET": "İMRALLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "BAYAT",
        "STREET": "İNPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "BAŞMAKÇI",
        "STREET": "AKKEÇİLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "BAŞMAKÇI",
        "STREET": "AKKOYUNLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "BAŞMAKÇI",
        "STREET": "AKPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "BAŞMAKÇI",
        "STREET": "AŞAĞI BELTARLA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "BAŞMAKÇI",
        "STREET": "AŞAĞI HİLAL MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "BAŞMAKÇI",
        "STREET": "BARBAROS MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "BAŞMAKÇI",
        "STREET": "EKİNLİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "BAŞMAKÇI",
        "STREET": "FATİH MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "BAŞMAKÇI",
        "STREET": "GÜLİSTAN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "BAŞMAKÇI",
        "STREET": "HIRKAKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "BAŞMAKÇI",
        "STREET": "KÜLLÜCE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "BAŞMAKÇI",
        "STREET": "MİMAR SİNAN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "BAŞMAKÇI",
        "STREET": "OVACIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "BAŞMAKÇI",
        "STREET": "SARIKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "BAŞMAKÇI",
        "STREET": "YAKA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "BAŞMAKÇI",
        "STREET": "YASSIÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "BAŞMAKÇI",
        "STREET": "YENİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "BAŞMAKÇI",
        "STREET": "YUKARI HİLAL MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "BAŞMAKÇI",
        "STREET": "YUKARIBELTARLA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "BAŞMAKÇI",
        "STREET": "ÇAĞLAYAN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "BAŞMAKÇI",
        "STREET": "ÇEVLİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "BAŞMAKÇI",
        "STREET": "ÇIĞRI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "BOLVADİN",
        "STREET": "AKÇEŞME MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "BOLVADİN",
        "STREET": "ALACA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "BOLVADİN",
        "STREET": "ALİ EFENDİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "BOLVADİN",
        "STREET": "AYDINLAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "BOLVADİN",
        "STREET": "AŞAĞI GÖKÇEYAYLA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "BOLVADİN",
        "STREET": "BADEMLİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "BOLVADİN",
        "STREET": "BAHÇELİEVLER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "BOLVADİN",
        "STREET": "BAĞLARBAŞI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "BOLVADİN",
        "STREET": "BAĞLARÜSTÜ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "BOLVADİN",
        "STREET": "BEKİR AĞA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "BOLVADİN",
        "STREET": "BOLVADİN T TİPİ KAPALI VE AÇIK CEZAEVİ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "BOLVADİN",
        "STREET": "BUCAK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "BOLVADİN",
        "STREET": "BÜYÜKKARABAĞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "BOLVADİN",
        "STREET": "CİRİT MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "BOLVADİN",
        "STREET": "DEREKARABAĞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "BOLVADİN",
        "STREET": "DİPEVLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "BOLVADİN",
        "STREET": "DİŞLİ-FATİH MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "BOLVADİN",
        "STREET": "DİŞLİ-KÖPRÜBAŞI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "BOLVADİN",
        "STREET": "DİŞLİ-MALAZGİRT MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "BOLVADİN",
        "STREET": "DİŞLİ-MEVLANA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "BOLVADİN",
        "STREET": "DİŞLİ-OKUL MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "BOLVADİN",
        "STREET": "DİŞLİ-YAVUZ SELİM MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "BOLVADİN",
        "STREET": "DİŞLİ-YUKARI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "BOLVADİN",
        "STREET": "DİŞLİ-YUNUS EMRE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "BOLVADİN",
        "STREET": "DİŞLİ-ÇAYBAŞI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "BOLVADİN",
        "STREET": "DİŞLİ-ŞEYH ŞAMİL MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "BOLVADİN",
        "STREET": "EMRULLAH MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "BOLVADİN",
        "STREET": "ERKMEN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "BOLVADİN",
        "STREET": "ETHEM KELEKÇİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "BOLVADİN",
        "STREET": "FATİH MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "BOLVADİN",
        "STREET": "GÖLBAŞI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "BOLVADİN",
        "STREET": "GÜNEY KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "BOLVADİN",
        "STREET": "HACI ESAT MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "BOLVADİN",
        "STREET": "HACI HALİFE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "BOLVADİN",
        "STREET": "HACI YUSUF MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "BOLVADİN",
        "STREET": "HACI ÖMER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "BOLVADİN",
        "STREET": "HAMİDİYE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "BOLVADİN",
        "STREET": "HASTANE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "BOLVADİN",
        "STREET": "HİSAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "BOLVADİN",
        "STREET": "KALE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "BOLVADİN",
        "STREET": "KARABAĞLAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "BOLVADİN",
        "STREET": "KARAYOKUŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "BOLVADİN",
        "STREET": "KAYMAZ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "BOLVADİN",
        "STREET": "KEMERKAYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "BOLVADİN",
        "STREET": "KESTEMET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "BOLVADİN",
        "STREET": "KIRKGÖZ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "BOLVADİN",
        "STREET": "KIRKLAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "BOLVADİN",
        "STREET": "KONAK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "BOLVADİN",
        "STREET": "KURUCAOVA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "BOLVADİN",
        "STREET": "KUTLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "BOLVADİN",
        "STREET": "LALA SİNAN PAŞA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "BOLVADİN",
        "STREET": "MALAZGİRT MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "BOLVADİN",
        "STREET": "MESCİT MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "BOLVADİN",
        "STREET": "MÜSLÜMANA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "BOLVADİN",
        "STREET": "NUSRATLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "BOLVADİN",
        "STREET": "ORTAKARABAĞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "BOLVADİN",
        "STREET": "SELÇUKLU MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "BOLVADİN",
        "STREET": "SÜLEYMANİYE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "BOLVADİN",
        "STREET": "TAŞAĞIL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "BOLVADİN",
        "STREET": "TAŞAĞIL MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "BOLVADİN",
        "STREET": "TAŞLIDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "BOLVADİN",
        "STREET": "YAKUP ŞEVKİ PAŞA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "BOLVADİN",
        "STREET": "YENİ DOĞAN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "BOLVADİN",
        "STREET": "YENİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "BOLVADİN",
        "STREET": "YENİCE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "BOLVADİN",
        "STREET": "YILDIRIM MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "BOLVADİN",
        "STREET": "YUKARI GÖKÇEYAYLA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "BOLVADİN",
        "STREET": "YÜRÜKKARACAÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "BOLVADİN",
        "STREET": "ÇARDAKLI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "BOLVADİN",
        "STREET": "ÖMEROĞLU MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "BOLVADİN",
        "STREET": "ÖZBURUN-BAHÇELİEVLER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "BOLVADİN",
        "STREET": "ÖZBURUN-ULUCAMİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "BOLVADİN",
        "STREET": "ÖZBURUN-ÇAYBAŞI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "BOLVADİN",
        "STREET": "ÖZBURUN-ÜSKÜDAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "BOLVADİN",
        "STREET": "ÖZBURUN-ŞEHİRÖNÜ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "BOLVADİN",
        "STREET": "İHSANİYE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "BOLVADİN",
        "STREET": "İMARET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "BOLVADİN",
        "STREET": "ŞAZİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "BOLVADİN",
        "STREET": "ŞIHLAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "DAZKIRI",
        "STREET": "AKARCA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "DAZKIRI",
        "STREET": "ARIKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "DAZKIRI",
        "STREET": "AŞAĞIYENİCE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "DAZKIRI",
        "STREET": "BARBAROS MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "DAZKIRI",
        "STREET": "BOZAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "DAZKIRI",
        "STREET": "DARICILAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "DAZKIRI",
        "STREET": "ESENTEPE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "DAZKIRI",
        "STREET": "HASANDEDE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "DAZKIRI",
        "STREET": "HİSARALAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "DAZKIRI",
        "STREET": "KARAAĞAÇKUYUSU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "DAZKIRI",
        "STREET": "KIZILÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "DAZKIRI",
        "STREET": "KURTULUŞ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "DAZKIRI",
        "STREET": "SARIKAVAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "DAZKIRI",
        "STREET": "YAYLAKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "DAZKIRI",
        "STREET": "YENİYOL MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "DAZKIRI",
        "STREET": "YEŞİLYURT MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "DAZKIRI",
        "STREET": "YUKARIYENİCE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "DAZKIRI",
        "STREET": "YÜREĞİL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "DAZKIRI",
        "STREET": "ÇAMLIK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "DAZKIRI",
        "STREET": "ÇİFTLİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "DAZKIRI",
        "STREET": "ÖRTÜLÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "DAZKIRI",
        "STREET": "İDRİS KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "DİNAR",
        "STREET": "ADLİYE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "DİNAR",
        "STREET": "AFŞAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "DİNAR",
        "STREET": "AKGÜN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "DİNAR",
        "STREET": "AKPINARLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "DİNAR",
        "STREET": "AKTOPRAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "DİNAR",
        "STREET": "AKÇA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "DİNAR",
        "STREET": "AKÇİN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "DİNAR",
        "STREET": "ALACATLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "DİNAR",
        "STREET": "ALPARSLAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "DİNAR",
        "STREET": "ALTMIŞEVLER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "DİNAR",
        "STREET": "ATATÜRK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "DİNAR",
        "STREET": "AVDAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "DİNAR",
        "STREET": "BADEMLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "DİNAR",
        "STREET": "BAĞCILAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "DİNAR",
        "STREET": "BAĞLAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "DİNAR",
        "STREET": "BELENPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "DİNAR",
        "STREET": "BURUNKAYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "DİNAR",
        "STREET": "BÜLÜCALAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "DİNAR",
        "STREET": "BİLGİÇ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "DİNAR",
        "STREET": "CAMİKEBİR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "DİNAR",
        "STREET": "CERİTYAYLASI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "DİNAR",
        "STREET": "CUMHURİYET KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "DİNAR",
        "STREET": "CUMHURİYET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "DİNAR",
        "STREET": "DOMBAY KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "DİNAR",
        "STREET": "DOĞANLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "DİNAR",
        "STREET": "DUMAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "DİNAR",
        "STREET": "DÖRTYOL MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "DİNAR",
        "STREET": "DİKİCİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "DİNAR",
        "STREET": "DİNAR KAPALI CEZAEVİ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "DİNAR",
        "STREET": "ELDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "DİNAR",
        "STREET": "EMNİYET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "DİNAR",
        "STREET": "ERGENLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "DİNAR",
        "STREET": "FATİH MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "DİNAR",
        "STREET": "GAZİ MEHMET ÇAVUŞ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "DİNAR",
        "STREET": "GENCELİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "DİNAR",
        "STREET": "GÖKÇELİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "DİNAR",
        "STREET": "GÖÇERLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "DİNAR",
        "STREET": "HAYDARLI-CUMHURİYET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "DİNAR",
        "STREET": "HAYDARLI-HÜRRİYET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "DİNAR",
        "STREET": "HAYDARLI-MERKEZ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "DİNAR",
        "STREET": "HAYDARLI-OCAKLI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "DİNAR",
        "STREET": "HAYDARLI-YENİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "DİNAR",
        "STREET": "HAYDARLI-ÖRNEK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "DİNAR",
        "STREET": "HAYDARLI-İSTİKLAL MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "DİNAR",
        "STREET": "HÜRRİYET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "DİNAR",
        "STREET": "ILICA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "DİNAR",
        "STREET": "KABAKLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "DİNAR",
        "STREET": "KADILAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "DİNAR",
        "STREET": "KARABEDİR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "DİNAR",
        "STREET": "KARAHACILI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "DİNAR",
        "STREET": "KARAKUYU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "DİNAR",
        "STREET": "KARATAŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "DİNAR",
        "STREET": "KAZANPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "DİNAR",
        "STREET": "KEKLİCEK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "DİNAR",
        "STREET": "KINIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "DİNAR",
        "STREET": "KIZILLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "DİNAR",
        "STREET": "KONAK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "DİNAR",
        "STREET": "KORUBAŞI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "DİNAR",
        "STREET": "KÖRPELİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "DİNAR",
        "STREET": "OKÇULAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "DİNAR",
        "STREET": "OTOGAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "DİNAR",
        "STREET": "PALAZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "DİNAR",
        "STREET": "PANCAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "DİNAR",
        "STREET": "PAZAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "DİNAR",
        "STREET": "PINARBAŞI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "DİNAR",
        "STREET": "PINARLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "DİNAR",
        "STREET": "SANAYİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "DİNAR",
        "STREET": "SANTRAL MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "DİNAR",
        "STREET": "STADYUM MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "DİNAR",
        "STREET": "SUÇIKAN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "DİNAR",
        "STREET": "SÜTLAÇ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "DİNAR",
        "STREET": "TATARLI-ALTINHİSAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "DİNAR",
        "STREET": "TATARLI-CUMHURİYET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "DİNAR",
        "STREET": "TATARLI-FATİH MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "DİNAR",
        "STREET": "TATARLI-YEŞİLYURT MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "DİNAR",
        "STREET": "TATARLI-ÇAMLICA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "DİNAR",
        "STREET": "TATARLI-ÇAĞLAYAN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "DİNAR",
        "STREET": "TEKİN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "DİNAR",
        "STREET": "TOPTEPE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "DİNAR",
        "STREET": "TUGAYLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "DİNAR",
        "STREET": "ULUKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "DİNAR",
        "STREET": "YAKA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "DİNAR",
        "STREET": "YAPAĞLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "DİNAR",
        "STREET": "YELALAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "DİNAR",
        "STREET": "YENİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "DİNAR",
        "STREET": "YEŞİLHÜYÜK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "DİNAR",
        "STREET": "YEŞİLYURT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "DİNAR",
        "STREET": "YEŞİLÇAT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "DİNAR",
        "STREET": "YIPRAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "DİNAR",
        "STREET": "YÜKSEL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "DİNAR",
        "STREET": "ÇAKICI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "DİNAR",
        "STREET": "ÇAMLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "DİNAR",
        "STREET": "ÇAPALI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "DİNAR",
        "STREET": "ÇAYÜSTÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "DİNAR",
        "STREET": "ÇAĞLAYAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "DİNAR",
        "STREET": "ÇOBANSARAY KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "DİNAR",
        "STREET": "ÇÜRÜKLÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "DİNAR",
        "STREET": "ÇİÇEKTEPE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "DİNAR",
        "STREET": "ÜÇLERCE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "DİNAR",
        "STREET": "İNCİRLİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "DİNAR",
        "STREET": "İSTASYON MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "DİNAR",
        "STREET": "İTFAİYE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "EMİRDAĞ",
        "STREET": "ABLAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "EMİRDAĞ",
        "STREET": "ADAYAZI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "EMİRDAĞ",
        "STREET": "ADAÇAL MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "EMİRDAĞ",
        "STREET": "ALİBEYCE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "EMİRDAĞ",
        "STREET": "AVDAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "EMİRDAĞ",
        "STREET": "AYDINYAKA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "EMİRDAĞ",
        "STREET": "AZİZİYE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "EMİRDAĞ",
        "STREET": "AĞILCIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "EMİRDAĞ",
        "STREET": "AŞAĞIALİÇOMAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "EMİRDAĞ",
        "STREET": "AŞAĞIKURUDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "EMİRDAĞ",
        "STREET": "AŞAĞIPİRİBEYLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "EMİRDAĞ",
        "STREET": "BADEMLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "EMİRDAĞ",
        "STREET": "BALCAM KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "EMİRDAĞ",
        "STREET": "BAĞLICA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "EMİRDAĞ",
        "STREET": "BAŞKONAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "EMİRDAĞ",
        "STREET": "BEYKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "EMİRDAĞ",
        "STREET": "BEYÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "EMİRDAĞ",
        "STREET": "BURUNARKAÇ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "EMİRDAĞ",
        "STREET": "BÜYÜKTUĞLUK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "EMİRDAĞ",
        "STREET": "CAMİLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "EMİRDAĞ",
        "STREET": "CUMHURİYET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "EMİRDAĞ",
        "STREET": "DAVULGA-ALASAKALLI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "EMİRDAĞ",
        "STREET": "DAVULGA-DOMURLU MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "EMİRDAĞ",
        "STREET": "DAVULGA-HİSARKÖY MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "EMİRDAĞ",
        "STREET": "DAVULGA-KARAKUYU MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "EMİRDAĞ",
        "STREET": "DAVULGA-SOFULU MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "EMİRDAĞ",
        "STREET": "DAVULGA-İNCİK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "EMİRDAĞ",
        "STREET": "DAYDALI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "EMİRDAĞ",
        "STREET": "DAĞILGAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "EMİRDAĞ",
        "STREET": "DAĞINIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "EMİRDAĞ",
        "STREET": "DEMİRCİLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "EMİRDAĞ",
        "STREET": "DEREKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "EMİRDAĞ",
        "STREET": "EKİZCE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "EMİRDAĞ",
        "STREET": "ELHAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "EMİRDAĞ",
        "STREET": "EMİRBABA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "EMİRDAĞ",
        "STREET": "EMİRİNKÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "EMİRDAĞ",
        "STREET": "ESKİ KACERLİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "EMİRDAĞ",
        "STREET": "ESKİAKÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "EMİRDAĞ",
        "STREET": "EŞREFLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "EMİRDAĞ",
        "STREET": "GEDİKEVİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "EMİRDAĞ",
        "STREET": "GELİNCİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "EMİRDAĞ",
        "STREET": "GÖMÜ-BAĞLARBAŞI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "EMİRDAĞ",
        "STREET": "GÖMÜ-DÖRTYOL MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "EMİRDAĞ",
        "STREET": "GÖMÜ-FATİH MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "EMİRDAĞ",
        "STREET": "GÖMÜ-GÖKÇEYAKA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "EMİRDAĞ",
        "STREET": "GÖMÜ-TOKİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "EMİRDAĞ",
        "STREET": "GÖZELİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "EMİRDAĞ",
        "STREET": "GÜNEY KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "EMİRDAĞ",
        "STREET": "GÜNEYSARAY KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "EMİRDAĞ",
        "STREET": "GÜVEÇÇİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "EMİRDAĞ",
        "STREET": "HAMZAHACILI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "EMİRDAĞ",
        "STREET": "KARAAĞAÇ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "EMİRDAĞ",
        "STREET": "KARACALAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "EMİRDAĞ",
        "STREET": "KARAYATAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "EMİRDAĞ",
        "STREET": "KARŞIYAKA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "EMİRDAĞ",
        "STREET": "KILIÇLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "EMİRDAĞ",
        "STREET": "KILIÇLI KAVLAKLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "EMİRDAĞ",
        "STREET": "KIRKPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "EMİRDAĞ",
        "STREET": "KURUCA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "EMİRDAĞ",
        "STREET": "LEYLEKLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "EMİRDAĞ",
        "STREET": "MENDERES MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "EMİRDAĞ",
        "STREET": "SALİHLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "EMİRDAĞ",
        "STREET": "SIĞRACIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "EMİRDAĞ",
        "STREET": "SOĞUKKUYU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "EMİRDAĞ",
        "STREET": "SUVERMEZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "EMİRDAĞ",
        "STREET": "TABAKLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "EMİRDAĞ",
        "STREET": "TEPEKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "EMİRDAĞ",
        "STREET": "TEZKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "EMİRDAĞ",
        "STREET": "TOKLUCAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "EMİRDAĞ",
        "STREET": "TOPDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "EMİRDAĞ",
        "STREET": "TÜRKMENAKÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "EMİRDAĞ",
        "STREET": "TÜRKMENKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "EMİRDAĞ",
        "STREET": "VEYSEL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "EMİRDAĞ",
        "STREET": "YARIKKAYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "EMİRDAĞ",
        "STREET": "YARIMCA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "EMİRDAĞ",
        "STREET": "YAVUZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "EMİRDAĞ",
        "STREET": "YENİ KACERLİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "EMİRDAĞ",
        "STREET": "YENİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "EMİRDAĞ",
        "STREET": "YENİDOĞAN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "EMİRDAĞ",
        "STREET": "YENİKAPI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "EMİRDAĞ",
        "STREET": "YENİKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "EMİRDAĞ",
        "STREET": "YUKARIKURUDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "EMİRDAĞ",
        "STREET": "YUSUFAĞA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "EMİRDAĞ",
        "STREET": "YÜREĞİL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "EMİRDAĞ",
        "STREET": "ÇATALLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "EMİRDAĞ",
        "STREET": "ÇAYKIŞLA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "EMİRDAĞ",
        "STREET": "ÇİFTLİKKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "EMİRDAĞ",
        "STREET": "ÇİLLİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "EMİRDAĞ",
        "STREET": "ÖRENKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "EMİRDAĞ",
        "STREET": "ÖZKAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "EMİRDAĞ",
        "STREET": "ÜMRANİYE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "EMİRDAĞ",
        "STREET": "İNCİLİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "EMİRDAĞ",
        "STREET": "İNKİLAP MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "EVCİLER",
        "STREET": "AKYARMA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "EVCİLER",
        "STREET": "ALTINOVA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "EVCİLER",
        "STREET": "BARAKLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "EVCİLER",
        "STREET": "BOSTANCI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "EVCİLER",
        "STREET": "CAMİKEBİR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "EVCİLER",
        "STREET": "FATİH MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "EVCİLER",
        "STREET": "GÖKÇEK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "EVCİLER",
        "STREET": "KAYALIK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "EVCİLER",
        "STREET": "KÖRKUYU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "EVCİLER",
        "STREET": "MADENLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "EVCİLER",
        "STREET": "MEHMET AKİF MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "EVCİLER",
        "STREET": "YAVUZ SELİM MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "EVCİLER",
        "STREET": "YUNUS EMRE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "HOCALAR",
        "STREET": "AKÇADERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "HOCALAR",
        "STREET": "AVGANCIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "HOCALAR",
        "STREET": "DAVULGA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "HOCALAR",
        "STREET": "DEVLETHAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "HOCALAR",
        "STREET": "GÜRE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "HOCALAR",
        "STREET": "HÜRRİYET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "HOCALAR",
        "STREET": "KOCAGÖL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "HOCALAR",
        "STREET": "KOZLUCA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "HOCALAR",
        "STREET": "ULUKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "HOCALAR",
        "STREET": "YAĞCI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "HOCALAR",
        "STREET": "YENİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "HOCALAR",
        "STREET": "YEŞİLHİSAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "HOCALAR",
        "STREET": "ÇALÇA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "HOCALAR",
        "STREET": "ÇEPNİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "HOCALAR",
        "STREET": "ÖRENCİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "HOCALAR",
        "STREET": "ÖRTÜLÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "HOCALAR",
        "STREET": "İHSANİYE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "HOCALAR",
        "STREET": "ŞAFAK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "KIZILÖREN",
        "STREET": "CUMHURİYET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "KIZILÖREN",
        "STREET": "EKİNOVA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "KIZILÖREN",
        "STREET": "GÜLYAZI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "KIZILÖREN",
        "STREET": "TÜRKBELKAVAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "KIZILÖREN",
        "STREET": "YENİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "KIZILÖREN",
        "STREET": "YENİBELKAVAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "SANDIKLI",
        "STREET": "AKHARIM-DİKMEN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "SANDIKLI",
        "STREET": "AKHARIM-HİSAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "SANDIKLI",
        "STREET": "AKHARIM-ÇİĞİLTEPE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "SANDIKLI",
        "STREET": "AKİN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "SANDIKLI",
        "STREET": "ALACAMİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "SANDIKLI",
        "STREET": "ALAGÖZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "SANDIKLI",
        "STREET": "ALAMESCİT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "SANDIKLI",
        "STREET": "ARIZLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "SANDIKLI",
        "STREET": "ASMACIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "SANDIKLI",
        "STREET": "BALLIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "SANDIKLI",
        "STREET": "BAŞAĞAÇ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "SANDIKLI",
        "STREET": "BAŞKUYUCAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "SANDIKLI",
        "STREET": "BAŞTEPE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "SANDIKLI",
        "STREET": "BEKTEŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "SANDIKLI",
        "STREET": "CELİLOĞLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "SANDIKLI",
        "STREET": "CUMA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "SANDIKLI",
        "STREET": "DAYLIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "SANDIKLI",
        "STREET": "DODURGA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "SANDIKLI",
        "STREET": "DUTAĞAÇ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "SANDIKLI",
        "STREET": "ECE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "SANDIKLI",
        "STREET": "EKİNHİSAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "SANDIKLI",
        "STREET": "EMİRHİSAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "SANDIKLI",
        "STREET": "FATİH MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "SANDIKLI",
        "STREET": "GÖKÇEALAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "SANDIKLI",
        "STREET": "GÜRSU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "SANDIKLI",
        "STREET": "HIRKA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "SANDIKLI",
        "STREET": "HİSAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "SANDIKLI",
        "STREET": "KARACAÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "SANDIKLI",
        "STREET": "KARADİREK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "SANDIKLI",
        "STREET": "KARASANDIKLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "SANDIKLI",
        "STREET": "KARKIN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "SANDIKLI",
        "STREET": "KEVSER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "SANDIKLI",
        "STREET": "KINIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "SANDIKLI",
        "STREET": "KIZIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "SANDIKLI",
        "STREET": "KIZILCA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "SANDIKLI",
        "STREET": "KOZVAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "SANDIKLI",
        "STREET": "KOÇGAZİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "SANDIKLI",
        "STREET": "KOÇHİSAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "SANDIKLI",
        "STREET": "KUSURA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "SANDIKLI",
        "STREET": "MENTEŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "SANDIKLI",
        "STREET": "NASUHOĞLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "SANDIKLI",
        "STREET": "ODA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "SANDIKLI",
        "STREET": "OTLUK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "SANDIKLI",
        "STREET": "REŞADİYE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "SANDIKLI",
        "STREET": "SALTIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "SANDIKLI",
        "STREET": "SAZAK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "SANDIKLI",
        "STREET": "SELÇİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "SANDIKLI",
        "STREET": "SORKUN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "SANDIKLI",
        "STREET": "SOĞUCAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "SANDIKLI",
        "STREET": "SUSUZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "SANDIKLI",
        "STREET": "TAPDUK EMRE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "SANDIKLI",
        "STREET": "YANIKÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "SANDIKLI",
        "STREET": "YAVAŞLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "SANDIKLI",
        "STREET": "YAYMAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "SANDIKLI",
        "STREET": "YOLKONAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "SANDIKLI",
        "STREET": "YUMRUCA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "SANDIKLI",
        "STREET": "YUNUS EMRE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "SANDIKLI",
        "STREET": "ZAFER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "SANDIKLI",
        "STREET": "ÇAKIR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "SANDIKLI",
        "STREET": "ÇAMBEYLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "SANDIKLI",
        "STREET": "ÇAMOĞLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "SANDIKLI",
        "STREET": "ÇAY MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "SANDIKLI",
        "STREET": "ÇEVREPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "SANDIKLI",
        "STREET": "ÇUKURCA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "SANDIKLI",
        "STREET": "ÇİĞİLTEPE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "SANDIKLI",
        "STREET": "ÖRENKAYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "SANDIKLI",
        "STREET": "ÖRMEKUYU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "SANDIKLI",
        "STREET": "ÜLFECİLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "SANDIKLI",
        "STREET": "ÜRKÜT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "SANDIKLI",
        "STREET": "İSTASYON MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "SANDIKLI",
        "STREET": "ŞEYHYAHŞİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "SANDIKLI",
        "STREET": "ŞİRİNEVLER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "SULTANDAĞI",
        "STREET": "AKBABA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "SULTANDAĞI",
        "STREET": "DEREÇİNE-ATATÜRK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "SULTANDAĞI",
        "STREET": "DEREÇİNE-CAMİKEBİR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "SULTANDAĞI",
        "STREET": "DEREÇİNE-CUMHURİYET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "SULTANDAĞI",
        "STREET": "DEREÇİNE-KARAMAN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "SULTANDAĞI",
        "STREET": "DEREÇİNE-KAVAKLI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "SULTANDAĞI",
        "STREET": "DOĞANCIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "SULTANDAĞI",
        "STREET": "KARAKIŞLA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "SULTANDAĞI",
        "STREET": "KARAPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "SULTANDAĞI",
        "STREET": "KAYRAN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "SULTANDAĞI",
        "STREET": "KIRCA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "SULTANDAĞI",
        "STREET": "PAZARALTI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "SULTANDAĞI",
        "STREET": "SELÇUK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "SULTANDAĞI",
        "STREET": "TAŞKÖPRÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "SULTANDAĞI",
        "STREET": "YAKASİNEK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "SULTANDAĞI",
        "STREET": "YENİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "SULTANDAĞI",
        "STREET": "YENİKARABAĞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "SULTANDAĞI",
        "STREET": "YEŞİLÇİFTLİK-AKPINAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "SULTANDAĞI",
        "STREET": "YEŞİLÇİFTLİK-AŞAĞI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "SULTANDAĞI",
        "STREET": "YEŞİLÇİFTLİK-SIRA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "SULTANDAĞI",
        "STREET": "YEŞİLÇİFTLİK-TEPEBAŞI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "SULTANDAĞI",
        "STREET": "YEŞİLÇİFTLİK-YENİKENT MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "SULTANDAĞI",
        "STREET": "YEŞİLÇİFTLİK-YILDIZ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "SULTANDAĞI",
        "STREET": "YEŞİLÇİFTLİK-YUKARI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "SULTANDAĞI",
        "STREET": "YILDIRIM MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "SULTANDAĞI",
        "STREET": "ÇAMÖZÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "SULTANDAĞI",
        "STREET": "ÇAVUŞ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "SULTANDAĞI",
        "STREET": "ÇUKURCAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "SULTANDAĞI",
        "STREET": "ÜÇKUYU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "SİNANPAŞA",
        "STREET": "AHMETPAŞA-DİBEKBAŞI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "SİNANPAŞA",
        "STREET": "AHMETPAŞA-GÖLCÜK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "SİNANPAŞA",
        "STREET": "AHMETPAŞA-HALKALI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "SİNANPAŞA",
        "STREET": "AHMETPAŞA-KÜÇÜKTEPE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "SİNANPAŞA",
        "STREET": "AHMETPAŞA-YUKARI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "SİNANPAŞA",
        "STREET": "AHMETPAŞA-ÇİĞİLTEPE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "SİNANPAŞA",
        "STREET": "AKDEĞİRMEN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "SİNANPAŞA",
        "STREET": "AKPINAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "SİNANPAŞA",
        "STREET": "AKÇAŞAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "SİNANPAŞA",
        "STREET": "AKÖREN-BAĞDAT MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "SİNANPAŞA",
        "STREET": "AKÖREN-FATİH MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "SİNANPAŞA",
        "STREET": "AKÖREN-YILDIRIM BEYAZIT MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "SİNANPAŞA",
        "STREET": "ALİ ÇETİNKAYA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "SİNANPAŞA",
        "STREET": "AYVALI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "SİNANPAŞA",
        "STREET": "BAHÇELİEVLER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "SİNANPAŞA",
        "STREET": "BALMAHMUT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "SİNANPAŞA",
        "STREET": "BAŞKİMSE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "SİNANPAŞA",
        "STREET": "BOYALI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "SİNANPAŞA",
        "STREET": "BULCA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "SİNANPAŞA",
        "STREET": "CUMHURİYET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "SİNANPAŞA",
        "STREET": "DÜZAĞAÇ-CUMHURİYET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "SİNANPAŞA",
        "STREET": "DÜZAĞAÇ-FATİH MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "SİNANPAŞA",
        "STREET": "DÜZAĞAÇ-IŞIK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "SİNANPAŞA",
        "STREET": "DÜZAĞAÇ-ZAFER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "SİNANPAŞA",
        "STREET": "ELVANPAŞA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "SİNANPAŞA",
        "STREET": "EYİCE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "SİNANPAŞA",
        "STREET": "FATİH MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "SİNANPAŞA",
        "STREET": "GARİPCE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "SİNANPAŞA",
        "STREET": "GEZLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "SİNANPAŞA",
        "STREET": "GÜNEY-AŞAĞI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "SİNANPAŞA",
        "STREET": "GÜNEY-ORTA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "SİNANPAŞA",
        "STREET": "GÜNEY-YENİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "SİNANPAŞA",
        "STREET": "GÜNEY-YUKARI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "SİNANPAŞA",
        "STREET": "GÜNEY-ÇALIŞLAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "SİNANPAŞA",
        "STREET": "KARACA ÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "SİNANPAŞA",
        "STREET": "KAYADİBİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "SİNANPAŞA",
        "STREET": "KILIÇARSLAN-ATATÜRK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "SİNANPAŞA",
        "STREET": "KILIÇARSLAN-CUMHURİYET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "SİNANPAŞA",
        "STREET": "KILIÇARSLAN-FEVZİ ÇAKMAK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "SİNANPAŞA",
        "STREET": "KILIÇARSLAN-KOCATEPE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "SİNANPAŞA",
        "STREET": "KINIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "SİNANPAŞA",
        "STREET": "KIRKA-ATATÜRK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "SİNANPAŞA",
        "STREET": "KIRKA-FATİH MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "SİNANPAŞA",
        "STREET": "KIRKA-YAVUZ SULTAN SELİM MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "SİNANPAŞA",
        "STREET": "KIRKA-YILDIRIM BEYAZIT MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "SİNANPAŞA",
        "STREET": "KÜÇÜKHÜYÜK-ATATÜRK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "SİNANPAŞA",
        "STREET": "KÜÇÜKHÜYÜK-CUMHURİYET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "SİNANPAŞA",
        "STREET": "KÜÇÜKHÜYÜK-FATİH MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "SİNANPAŞA",
        "STREET": "KÜÇÜKHÜYÜK-KURTULUŞ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "SİNANPAŞA",
        "STREET": "KÜÇÜKHÜYÜK-ZAFER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "SİNANPAŞA",
        "STREET": "NUH KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "SİNANPAŞA",
        "STREET": "SARAYCIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "SİNANPAŞA",
        "STREET": "SERBAN-CUMHURİYET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "SİNANPAŞA",
        "STREET": "SERBAN-HOZMAN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "SİNANPAŞA",
        "STREET": "SERBAN-HİSAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "SİNANPAŞA",
        "STREET": "SERBAN-KÖPRÜBAŞI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "SİNANPAŞA",
        "STREET": "SERBAN-KÜMBET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "SİNANPAŞA",
        "STREET": "SERBAN-ORTA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "SİNANPAŞA",
        "STREET": "TAZLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "SİNANPAŞA",
        "STREET": "TAŞOLUK-BAHÇELİEVLER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "SİNANPAŞA",
        "STREET": "TAŞOLUK-CUMHURİYET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "SİNANPAŞA",
        "STREET": "TAŞOLUK-HÜRRİYET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "SİNANPAŞA",
        "STREET": "TAŞOLUK-YENİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "SİNANPAŞA",
        "STREET": "TAŞOLUK-ÇANKAYA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "SİNANPAŞA",
        "STREET": "TAŞOLUK-ÇINARLI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "SİNANPAŞA",
        "STREET": "TINAZTEPE-AMBAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "SİNANPAŞA",
        "STREET": "TINAZTEPE-BAHÇELİEVLER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "SİNANPAŞA",
        "STREET": "TINAZTEPE-FATİH MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "SİNANPAŞA",
        "STREET": "TINAZTEPE-SARAY MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "SİNANPAŞA",
        "STREET": "TINAZTEPE-YENİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "SİNANPAŞA",
        "STREET": "TINAZTEPE-ZAFER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "SİNANPAŞA",
        "STREET": "TOKUŞLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "SİNANPAŞA",
        "STREET": "YILDIRIM KEMAL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "SİNANPAŞA",
        "STREET": "YUKARI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "SİNANPAŞA",
        "STREET": "YÖRÜK MEZARI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "SİNANPAŞA",
        "STREET": "ÇATKUYU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "SİNANPAŞA",
        "STREET": "ÇAYHİSAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "SİNANPAŞA",
        "STREET": "ÇOBANÖZÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "SİNANPAŞA",
        "STREET": "ÇİĞİLTEPE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "SİNANPAŞA",
        "STREET": "ÖĞRETMENLER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "SİNANPAŞA",
        "STREET": "İĞDELİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "ÇAY",
        "STREET": "AKKONAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "ÇAY",
        "STREET": "AKTAŞ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "ÇAY",
        "STREET": "ALİ KALELİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "ÇAY",
        "STREET": "ALİ İHSAN PAŞA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "ÇAY",
        "STREET": "ARMUTLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "ÇAY",
        "STREET": "AYDOĞMUŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "ÇAY",
        "STREET": "AŞAĞI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "ÇAY",
        "STREET": "BAHÇELİEVLER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "ÇAY",
        "STREET": "BULANIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "ÇAY",
        "STREET": "CEDİT MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "ÇAY",
        "STREET": "CUMHURİYET KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "ÇAY",
        "STREET": "DEMİRAĞAÇ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "ÇAY",
        "STREET": "DERESİNEK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "ÇAY",
        "STREET": "DEVEDERESİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "ÇAY",
        "STREET": "DUMLUPINAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "ÇAY",
        "STREET": "EBER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "ÇAY",
        "STREET": "ELBİZ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "ÇAY",
        "STREET": "GÖCEN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "ÇAY",
        "STREET": "KADIKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "ÇAY",
        "STREET": "KARAMIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "ÇAY",
        "STREET": "KARAMIKKARACAÖREN-AŞAĞI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "ÇAY",
        "STREET": "KARAMIKKARACAÖREN-BAĞLAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "ÇAY",
        "STREET": "KARAMIKKARACAÖREN-SEKA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "ÇAY",
        "STREET": "KARAMIKKARACAÖREN-YUKARI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "ÇAY",
        "STREET": "KILIÇYAKA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "ÇAY",
        "STREET": "KOÇBEYLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "ÇAY",
        "STREET": "KÖPRÜBAŞI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "ÇAY",
        "STREET": "MALTEPE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "ÇAY",
        "STREET": "ORHANİYE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "ÇAY",
        "STREET": "ORTA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "ÇAY",
        "STREET": "PAZARAĞAÇ-AFYON MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "ÇAY",
        "STREET": "PAZARAĞAÇ-BEŞEVLER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "ÇAY",
        "STREET": "PAZARAĞAÇ-KARABULUT MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "ÇAY",
        "STREET": "PAZARAĞAÇ-ORTA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "ÇAY",
        "STREET": "PAZARAĞAÇ-YUKARI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "ÇAY",
        "STREET": "PINARKAYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "ÇAY",
        "STREET": "TEKKE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "ÇAY",
        "STREET": "VAKIF MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "ÇAY",
        "STREET": "YAKA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "ÇAY",
        "STREET": "YEŞİLEVLER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "ÇAY",
        "STREET": "YEŞİLYURT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "ÇAY",
        "STREET": "ÇAYIRPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "ÇAY",
        "STREET": "ÇAYIRYAZI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "ÇAY",
        "STREET": "İNLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "ÇAY",
        "STREET": "İSTASYON MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "ÇAY",
        "STREET": "ŞİRİNEVLER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "ÇOBANLAR",
        "STREET": "AKKOYUNLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "ÇOBANLAR",
        "STREET": "BUCAK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "ÇOBANLAR",
        "STREET": "GÖLCÜK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "ÇOBANLAR",
        "STREET": "GÖYNÜK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "ÇOBANLAR",
        "STREET": "IŞIK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "ÇOBANLAR",
        "STREET": "KALE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "ÇOBANLAR",
        "STREET": "KARADEDE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "ÇOBANLAR",
        "STREET": "KOCAÖZ-ATATÜRK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "ÇOBANLAR",
        "STREET": "KOCAÖZ-CUMHURİYET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "ÇOBANLAR",
        "STREET": "KOCAÖZ-FATİH MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "ÇOBANLAR",
        "STREET": "KOCAÖZ-İSTİKLAL MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "ÇOBANLAR",
        "STREET": "SIRAKAPI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "ÇOBANLAR",
        "STREET": "TERMALKENT MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "ÇOBANLAR",
        "STREET": "YAVUZ SELİM MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "ÇOBANLAR",
        "STREET": "YENİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "ÇOBANLAR",
        "STREET": "ZAFER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "İHSANİYE",
        "STREET": "ABLAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "İHSANİYE",
        "STREET": "AKÖREN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "İHSANİYE",
        "STREET": "AYAZİNİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "İHSANİYE",
        "STREET": "AŞAĞITANDIR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "İHSANİYE",
        "STREET": "BASIRLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "İHSANİYE",
        "STREET": "BAYRAMALİLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "İHSANİYE",
        "STREET": "BEYKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "İHSANİYE",
        "STREET": "BOZHÜYÜK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "İHSANİYE",
        "STREET": "CUMALI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "İHSANİYE",
        "STREET": "CUMHURİYET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "İHSANİYE",
        "STREET": "DEMİRLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "İHSANİYE",
        "STREET": "DÖĞER-FATİH MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "İHSANİYE",
        "STREET": "DÖĞER-KERVANSARAY MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "İHSANİYE",
        "STREET": "DÖĞER-YENİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "İHSANİYE",
        "STREET": "DÖĞER-YUNUS EMRE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "İHSANİYE",
        "STREET": "ESKİEYMİR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "İHSANİYE",
        "STREET": "EYNEHANKUZVİRAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "İHSANİYE",
        "STREET": "FATİH MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "İHSANİYE",
        "STREET": "GAZLIGÖL-BELCE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "İHSANİYE",
        "STREET": "GAZLIGÖL-CUMHURİYET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "İHSANİYE",
        "STREET": "GAZLIGÖL-FATİH MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "İHSANİYE",
        "STREET": "GAZLIGÖL-YUNUS EMRE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "İHSANİYE",
        "STREET": "HACIBEYLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "İHSANİYE",
        "STREET": "HÜRRİYET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "İHSANİYE",
        "STREET": "KADIMÜRSEL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "İHSANİYE",
        "STREET": "KARACAAHMET KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "İHSANİYE",
        "STREET": "KAYIHAN-CUMHURİYET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "İHSANİYE",
        "STREET": "KAYIHAN-KUNDUZLU MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "İHSANİYE",
        "STREET": "KAYIHAN-PINAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "İHSANİYE",
        "STREET": "KAYIHAN-TÜRBE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "İHSANİYE",
        "STREET": "KIYIR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "İHSANİYE",
        "STREET": "MURATLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "İHSANİYE",
        "STREET": "ORHANLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "İHSANİYE",
        "STREET": "OSMANKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "İHSANİYE",
        "STREET": "OĞULBEYLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "İHSANİYE",
        "STREET": "SARICAOVA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "İHSANİYE",
        "STREET": "SUSUZ OSMANİYE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "İHSANİYE",
        "STREET": "YAYLABAĞI-ATATÜRK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "İHSANİYE",
        "STREET": "YAYLABAĞI-BAHÇELİEVLER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "İHSANİYE",
        "STREET": "YAYLABAĞI-ESENTEPE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "İHSANİYE",
        "STREET": "YENİCE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "İHSANİYE",
        "STREET": "YENİKENT MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "İHSANİYE",
        "STREET": "YEŞİLYAYLA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "İHSANİYE",
        "STREET": "YUKARITANDIR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "İHSANİYE",
        "STREET": "YİĞİTPINARI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "İHSANİYE",
        "STREET": "ZAFER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "İHSANİYE",
        "STREET": "ÜÇLERKAYASI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "İHSANİYE",
        "STREET": "İĞDEMİR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "İHSANİYE",
        "STREET": "ŞAHİNLER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "İSCEHİSAR",
        "STREET": "ALANYURT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "İSCEHİSAR",
        "STREET": "BAHÇECİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "İSCEHİSAR",
        "STREET": "CEVİZLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "İSCEHİSAR",
        "STREET": "CUMHURİYET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "İSCEHİSAR",
        "STREET": "DOĞANLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "İSCEHİSAR",
        "STREET": "DOĞLAT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "İSCEHİSAR",
        "STREET": "ESENTEPE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "İSCEHİSAR",
        "STREET": "ESKİ HAMAM MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "İSCEHİSAR",
        "STREET": "FATİH MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "İSCEHİSAR",
        "STREET": "KARAAĞAÇ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "İSCEHİSAR",
        "STREET": "KARAKAYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "İSCEHİSAR",
        "STREET": "KAVAK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "İSCEHİSAR",
        "STREET": "KONARI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "İSCEHİSAR",
        "STREET": "KURTULUŞ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "İSCEHİSAR",
        "STREET": "MEDRESE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "İSCEHİSAR",
        "STREET": "OLUKPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "İSCEHİSAR",
        "STREET": "SELÇUKLU MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "İSCEHİSAR",
        "STREET": "SEYDİLER-CUMHURİYET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "İSCEHİSAR",
        "STREET": "SEYDİLER-HASAN BASRİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "İSCEHİSAR",
        "STREET": "TEPECİK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "İSCEHİSAR",
        "STREET": "ZEYBEK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "İSCEHİSAR",
        "STREET": "ÇALIŞLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "İSCEHİSAR",
        "STREET": "ÇATAĞIL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "İSCEHİSAR",
        "STREET": "ÇUKUR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "İSCEHİSAR",
        "STREET": "ŞİRİNEVLER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "ŞUHUT",
        "STREET": "AKYUVA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "ŞUHUT",
        "STREET": "ALTIGÖZ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "ŞUHUT",
        "STREET": "ANAYURT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "ŞUHUT",
        "STREET": "ARIZLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "ŞUHUT",
        "STREET": "ATLIHİSAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "ŞUHUT",
        "STREET": "AYDIN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "ŞUHUT",
        "STREET": "AĞZIKARA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "ŞUHUT",
        "STREET": "BADEMLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "ŞUHUT",
        "STREET": "BALÇIKHİSAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "ŞUHUT",
        "STREET": "BAŞ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "ŞUHUT",
        "STREET": "BAŞÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "ŞUHUT",
        "STREET": "BOZAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "ŞUHUT",
        "STREET": "DADAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "ŞUHUT",
        "STREET": "DEMİRBEL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "ŞUHUT",
        "STREET": "EFE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "ŞUHUT",
        "STREET": "GAZİPAŞA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "ŞUHUT",
        "STREET": "GÜNEYTEPE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "ŞUHUT",
        "STREET": "HACI MUSA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "ŞUHUT",
        "STREET": "HALLAÇ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "ŞUHUT",
        "STREET": "HİSAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "ŞUHUT",
        "STREET": "KARAADİLLİ-DEVELİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "ŞUHUT",
        "STREET": "KARAADİLLİ-ERENLER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "ŞUHUT",
        "STREET": "KARAADİLLİ-ZAFER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "ŞUHUT",
        "STREET": "KARAADİLLİ-ÇAĞLAYAN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "ŞUHUT",
        "STREET": "KARACAÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "ŞUHUT",
        "STREET": "KARAHALLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "ŞUHUT",
        "STREET": "KARLIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "ŞUHUT",
        "STREET": "KAVAKLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "ŞUHUT",
        "STREET": "KAYABELEN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "ŞUHUT",
        "STREET": "KILINÇKAYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "ŞUHUT",
        "STREET": "KOÇYATAĞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "ŞUHUT",
        "STREET": "KULAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "ŞUHUT",
        "STREET": "KURTULUŞ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "ŞUHUT",
        "STREET": "MAHMUT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "ŞUHUT",
        "STREET": "MEHMET AKİF ERSOY MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "ŞUHUT",
        "STREET": "ORTAPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "ŞUHUT",
        "STREET": "OYNAĞAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "ŞUHUT",
        "STREET": "PAZAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "ŞUHUT",
        "STREET": "PAŞACIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "ŞUHUT",
        "STREET": "SENİR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "ŞUHUT",
        "STREET": "TAŞLICA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "ŞUHUT",
        "STREET": "TEKKE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "ŞUHUT",
        "STREET": "UZUNPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "ŞUHUT",
        "STREET": "YALI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "ŞUHUT",
        "STREET": "YARIŞLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "ŞUHUT",
        "STREET": "ZAFER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "ŞUHUT",
        "STREET": "ÇAKIRÖZÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "ŞUHUT",
        "STREET": "ÇOBANKAYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "ŞUHUT",
        "STREET": "İCİKLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "ŞUHUT",
        "STREET": "İLYASLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "ŞUHUT",
        "STREET": "İPLİK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "ŞUHUT",
        "STREET": "İSALI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AFYONKARAHİSAR",
        "DISTRICT": "ŞUHUT",
        "STREET": "İSTİKLAL MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "MERKEZ",
        "STREET": "ACIPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "MERKEZ",
        "STREET": "AKHİSAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "MERKEZ",
        "STREET": "AKSARAY KAPALI CEZA İNFAZ KURUMU",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "MERKEZ",
        "STREET": "AKÇAKENT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "MERKEZ",
        "STREET": "AKİN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "MERKEZ",
        "STREET": "ALAYHANI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "MERKEZ",
        "STREET": "ALTINKAYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "MERKEZ",
        "STREET": "ARATOL BAHÇELİ  MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "MERKEZ",
        "STREET": "ARATOL İSTİKLAL MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "MERKEZ",
        "STREET": "ARMUTLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "MERKEZ",
        "STREET": "ATAKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "MERKEZ",
        "STREET": "AĞZIKARAHAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "MERKEZ",
        "STREET": "BABAKONAĞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "MERKEZ",
        "STREET": "BAHÇELİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "MERKEZ",
        "STREET": "BAHÇESARAY MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "MERKEZ",
        "STREET": "BAYINDIR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "MERKEZ",
        "STREET": "BAYMIŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "MERKEZ",
        "STREET": "BAYRAMBABA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "MERKEZ",
        "STREET": "BAĞLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "MERKEZ",
        "STREET": "BAĞLIKAYA-BOĞAZİÇİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "MERKEZ",
        "STREET": "BAĞLIKAYA-KURTULUŞ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "MERKEZ",
        "STREET": "BAĞLIKAYA-YENİMAHALLE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "MERKEZ",
        "STREET": "BAĞLIKAYA-ÇİMELİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "MERKEZ",
        "STREET": "BEBEK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "MERKEZ",
        "STREET": "BEDİR MUHTAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "MERKEZ",
        "STREET": "BORUCU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "MERKEZ",
        "STREET": "BOSTANLIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "MERKEZ",
        "STREET": "BOYALI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "MERKEZ",
        "STREET": "BOZCATEPE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "MERKEZ",
        "STREET": "BÜYÜK BÖLCEK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "MERKEZ",
        "STREET": "BÜYÜKGÜVE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "MERKEZ",
        "STREET": "BÜYÜKPÖRNEKLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "MERKEZ",
        "STREET": "CANKILLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "MERKEZ",
        "STREET": "CERAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "MERKEZ",
        "STREET": "CERİT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "MERKEZ",
        "STREET": "COĞLAKİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "MERKEZ",
        "STREET": "CUMHURİYET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "MERKEZ",
        "STREET": "DARIHÜYÜK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "MERKEZ",
        "STREET": "DERE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "MERKEZ",
        "STREET": "DOĞANTARLA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "MERKEZ",
        "STREET": "DİKMEN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "MERKEZ",
        "STREET": "EKECİKGÖDELER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "MERKEZ",
        "STREET": "EKECİKTOL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "MERKEZ",
        "STREET": "EKECİKYENİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "MERKEZ",
        "STREET": "ELMACIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "MERKEZ",
        "STREET": "EREĞLİKAPI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "MERKEZ",
        "STREET": "FATMAUŞAĞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "MERKEZ",
        "STREET": "FATİH MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "MERKEZ",
        "STREET": "GENÇOSMAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "MERKEZ",
        "STREET": "GÖKSÜGÜZEL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "MERKEZ",
        "STREET": "GÖKÇE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "MERKEZ",
        "STREET": "GÖZLÜKUYU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "MERKEZ",
        "STREET": "GÜCÜNKAYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "MERKEZ",
        "STREET": "GÜLTEPE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "MERKEZ",
        "STREET": "HACIHASANLI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "MERKEZ",
        "STREET": "HACILAR HARMANI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "MERKEZ",
        "STREET": "HAMİDİYE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "MERKEZ",
        "STREET": "HANOBASI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "MERKEZ",
        "STREET": "HASAS MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "MERKEZ",
        "STREET": "HATİPOĞLUTOLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "MERKEZ",
        "STREET": "HELVADERE-AYDINLAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "MERKEZ",
        "STREET": "HELVADERE-CUMHURİYET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "MERKEZ",
        "STREET": "HELVADERE-KİRAZLI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "MERKEZ",
        "STREET": "HELVADERE-ZAFER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "MERKEZ",
        "STREET": "HIRKATOL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "MERKEZ",
        "STREET": "HÜRRİYET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "MERKEZ",
        "STREET": "KALANLAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "MERKEZ",
        "STREET": "KALEBALTA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "MERKEZ",
        "STREET": "KARACAÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "MERKEZ",
        "STREET": "KARAKOVA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "MERKEZ",
        "STREET": "KARAKUYU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "MERKEZ",
        "STREET": "KARATAŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "MERKEZ",
        "STREET": "KARAÇAYIR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "MERKEZ",
        "STREET": "KARAÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "MERKEZ",
        "STREET": "KARKIN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "MERKEZ",
        "STREET": "KAZICIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "MERKEZ",
        "STREET": "KILIÇASLAN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "MERKEZ",
        "STREET": "KIRIMLI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "MERKEZ",
        "STREET": "KOYAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "MERKEZ",
        "STREET": "KOÇPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "MERKEZ",
        "STREET": "KURTULUŞ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "MERKEZ",
        "STREET": "KUTLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "MERKEZ",
        "STREET": "KÜÇÜK BÖLCEK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "MERKEZ",
        "STREET": "KÜÇÜKGÜVE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "MERKEZ",
        "STREET": "KÜÇÜKPÖRNEKLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "MERKEZ",
        "STREET": "LALELİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "MERKEZ",
        "STREET": "MACARLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "MERKEZ",
        "STREET": "MEHMET AKİF ERSOY MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "MERKEZ",
        "STREET": "MEYDAN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "MERKEZ",
        "STREET": "MUHSİN ÇELEBİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "MERKEZ",
        "STREET": "MİNARECİK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "MERKEZ",
        "STREET": "NAKKAŞ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "MERKEZ",
        "STREET": "NURGÖZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "MERKEZ",
        "STREET": "PAMUCAK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "MERKEZ",
        "STREET": "PAŞACIK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "MERKEZ",
        "STREET": "PINAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "MERKEZ",
        "STREET": "SALMANLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "MERKEZ",
        "STREET": "SANAYİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "MERKEZ",
        "STREET": "SAPMAZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "MERKEZ",
        "STREET": "SARAYHAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "MERKEZ",
        "STREET": "SARIAĞIL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "MERKEZ",
        "STREET": "SAĞLIK-DİVANOĞLU MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "MERKEZ",
        "STREET": "SAĞLIK-FATİH MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "MERKEZ",
        "STREET": "SAĞLIK-YENİMAHALLE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "MERKEZ",
        "STREET": "SELECİKÖSE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "MERKEZ",
        "STREET": "SELÇUKLU MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "MERKEZ",
        "STREET": "SEVİNÇLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "MERKEZ",
        "STREET": "SOFULAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "MERKEZ",
        "STREET": "SOMUNCU BABA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "MERKEZ",
        "STREET": "SUSADI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "MERKEZ",
        "STREET": "TACİN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "MERKEZ",
        "STREET": "TAPTUK EMRE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "MERKEZ",
        "STREET": "TATLICA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "MERKEZ",
        "STREET": "TAŞPAZAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "MERKEZ",
        "STREET": "TAŞPINAR-ERENLER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "MERKEZ",
        "STREET": "TAŞPINAR-YAVUZ SELİM MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "MERKEZ",
        "STREET": "TAŞPINAR-YENİ CAMİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "MERKEZ",
        "STREET": "TOPAKKAYA-FATİH MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "MERKEZ",
        "STREET": "TOPAKKAYA-GÜLHANE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "MERKEZ",
        "STREET": "TOPAKKAYA-MEDENİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "MERKEZ",
        "STREET": "TOPAKKAYA-YEŞİLÇAM MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "MERKEZ",
        "STREET": "ULUKIŞLA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "MERKEZ",
        "STREET": "ULUKIŞLATOLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "MERKEZ",
        "STREET": "YALMAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "MERKEZ",
        "STREET": "YALNIZCEVİZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "MERKEZ",
        "STREET": "YANYURT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "MERKEZ",
        "STREET": "YAPILCAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "MERKEZ",
        "STREET": "YAVUZ SULTAN SELİM MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "MERKEZ",
        "STREET": "YAĞAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "MERKEZ",
        "STREET": "YENİKENT-GAZİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "MERKEZ",
        "STREET": "YENİKENT-ZAFER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "MERKEZ",
        "STREET": "YENİKENT-İSTİKLAL MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "MERKEZ",
        "STREET": "YENİMAHALLE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "MERKEZ",
        "STREET": "YENİPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "MERKEZ",
        "STREET": "YEŞİLOVA-ALASAKALLI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "MERKEZ",
        "STREET": "YEŞİLOVA-KÖSELİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "MERKEZ",
        "STREET": "YEŞİLOVA-PARABAŞLI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "MERKEZ",
        "STREET": "YEŞİLTEPE-FATİH MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "MERKEZ",
        "STREET": "YEŞİLTEPE-HÜRRİYET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "MERKEZ",
        "STREET": "YEŞİLTEPE-ZAFER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "MERKEZ",
        "STREET": "YEŞİLTEPE-ÇALIŞKAN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "MERKEZ",
        "STREET": "YUNUS EMRE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "MERKEZ",
        "STREET": "YUVA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "MERKEZ",
        "STREET": "ZAFER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "MERKEZ",
        "STREET": "ZİNCİRLİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇAVDARLILAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇAĞLAYAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇEKİÇLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇELTEK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇERDİĞİN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇOLAKNEBİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇİFTLİK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇİMELİYENİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "MERKEZ",
        "STREET": "İNCESU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "MERKEZ",
        "STREET": "İSMAİLAĞATOLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "MERKEZ",
        "STREET": "İSTİKLAL MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "MERKEZ",
        "STREET": "ŞAMLI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "MERKEZ",
        "STREET": "ŞEYHHAMİT MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "MERKEZ",
        "STREET": "ŞEYHLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "MERKEZ",
        "STREET": "ŞİFAHANE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "AĞAÇÖREN",
        "STREET": "ABALI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "AĞAÇÖREN",
        "STREET": "ABDİUŞAĞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "AĞAÇÖREN",
        "STREET": "AHIRLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "AĞAÇÖREN",
        "STREET": "AVŞAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "AĞAÇÖREN",
        "STREET": "CAMİLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "AĞAÇÖREN",
        "STREET": "DADILAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "AĞAÇÖREN",
        "STREET": "DEMİRCİLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "AĞAÇÖREN",
        "STREET": "GÖLLÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "AĞAÇÖREN",
        "STREET": "GÖYNÜK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "AĞAÇÖREN",
        "STREET": "GÜMÜŞTEPE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "AĞAÇÖREN",
        "STREET": "GÜZELÖZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "AĞAÇÖREN",
        "STREET": "HACIAHMETLİDAVUTLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "AĞAÇÖREN",
        "STREET": "HACIAHMETLİTEPEKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "AĞAÇÖREN",
        "STREET": "HACIİSMAİLLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "AĞAÇÖREN",
        "STREET": "HÜSREVKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "AĞAÇÖREN",
        "STREET": "KALE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "AĞAÇÖREN",
        "STREET": "KAŞIÇALIKLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "AĞAÇÖREN",
        "STREET": "KEDERLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "AĞAÇÖREN",
        "STREET": "KILIÇLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "AĞAÇÖREN",
        "STREET": "KIRIMİNİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "AĞAÇÖREN",
        "STREET": "KURTİNİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "AĞAÇÖREN",
        "STREET": "KÜTÜKLÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "AĞAÇÖREN",
        "STREET": "OYMAAĞAÇ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "AĞAÇÖREN",
        "STREET": "SARIAĞIL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "AĞAÇÖREN",
        "STREET": "SARIHASANLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "AĞAÇÖREN",
        "STREET": "SOFULAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "AĞAÇÖREN",
        "STREET": "YAĞMURHÜYÜĞÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "AĞAÇÖREN",
        "STREET": "YENİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "AĞAÇÖREN",
        "STREET": "YENİCE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "AĞAÇÖREN",
        "STREET": "YENİŞABANLI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "AĞAÇÖREN",
        "STREET": "YURTSEVER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "AĞAÇÖREN",
        "STREET": "ZAFER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "AĞAÇÖREN",
        "STREET": "ÇATALÇEŞME KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "ESKİL",
        "STREET": "AYDINLIK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "ESKİL",
        "STREET": "BAYRAMDÜĞÜN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "ESKİL",
        "STREET": "BAŞARAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "ESKİL",
        "STREET": "BÜĞET KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "ESKİL",
        "STREET": "CELİL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "ESKİL",
        "STREET": "CUMHURİYET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "ESKİL",
        "STREET": "EŞMEKAYA-CAMİKEBİR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "ESKİL",
        "STREET": "EŞMEKAYA-KAYACIK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "ESKİL",
        "STREET": "EŞMEKAYA-YENİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "ESKİL",
        "STREET": "EŞMEKAYA-YEŞİLYURT MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "ESKİL",
        "STREET": "FATİH MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "ESKİL",
        "STREET": "FEVZİ ÇAKMAK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "ESKİL",
        "STREET": "FİLİKÇİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "ESKİL",
        "STREET": "GÜMÜŞDÜĞÜN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "ESKİL",
        "STREET": "GÜNEŞLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "ESKİL",
        "STREET": "HÜRRİYET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "ESKİL",
        "STREET": "KARŞIYAKA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "ESKİL",
        "STREET": "KATRANCI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "ESKİL",
        "STREET": "KÖKEZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "ESKİL",
        "STREET": "MERKEZ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "ESKİL",
        "STREET": "MİMAR SİNAN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "ESKİL",
        "STREET": "SAĞSAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "ESKİL",
        "STREET": "YAVUZ SULTAN SELİM MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "ESKİL",
        "STREET": "YILDIRIM MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "ESKİL",
        "STREET": "ZAFER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "ESKİL",
        "STREET": "ÇUKURYURT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "ESKİL",
        "STREET": "ÇULFA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "ESKİL",
        "STREET": "İSTİKLAL MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "GÜLAĞAÇ",
        "STREET": "AKMEZAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "GÜLAĞAÇ",
        "STREET": "ATATÜRK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "GÜLAĞAÇ",
        "STREET": "BEKARLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "GÜLAĞAÇ",
        "STREET": "CAMİLİÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "GÜLAĞAÇ",
        "STREET": "DEMİRCİ-ESKİCAMİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "GÜLAĞAÇ",
        "STREET": "DEMİRCİ-KALEALTI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "GÜLAĞAÇ",
        "STREET": "DEMİRCİ-KURTULUŞ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "GÜLAĞAÇ",
        "STREET": "DEMİRCİ-YENİPAZAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "GÜLAĞAÇ",
        "STREET": "FATİH MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "GÜLAĞAÇ",
        "STREET": "GÜLPINAR-CUMHURİYET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "GÜLAĞAÇ",
        "STREET": "GÜLPINAR-FATİH MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "GÜLAĞAÇ",
        "STREET": "GÜLPINAR-KOYUNLU MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "GÜLAĞAÇ",
        "STREET": "GÜLPINAR-PINARBAŞI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "GÜLAĞAÇ",
        "STREET": "GÜLPINAR-TUZLA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "GÜLAĞAÇ",
        "STREET": "HÜRRİYET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "GÜLAĞAÇ",
        "STREET": "KIZILKAYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "GÜLAĞAÇ",
        "STREET": "MEYDAN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "GÜLAĞAÇ",
        "STREET": "OSMANLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "GÜLAĞAÇ",
        "STREET": "PINARBAŞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "GÜLAĞAÇ",
        "STREET": "SARATLI-CUMHURİYET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "GÜLAĞAÇ",
        "STREET": "SARATLI-KARŞIYAKA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "GÜLAĞAÇ",
        "STREET": "SARATLI-MEYDAN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "GÜLAĞAÇ",
        "STREET": "SARATLI-ZAFER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "GÜLAĞAÇ",
        "STREET": "SARATLI-İSTİKLAL MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "GÜLAĞAÇ",
        "STREET": "SOFULAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "GÜLAĞAÇ",
        "STREET": "SÜLEYMANHÜYÜĞÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "GÜLAĞAÇ",
        "STREET": "YENİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "GÜLAĞAÇ",
        "STREET": "ÇATALSU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "GÜLAĞAÇ",
        "STREET": "İSTİKLAL MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "GÜZELYURT",
        "STREET": "AKYAMAÇ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "GÜZELYURT",
        "STREET": "ALANYURT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "GÜZELYURT",
        "STREET": "AŞAĞI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "GÜZELYURT",
        "STREET": "BELİSIRMA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "GÜZELYURT",
        "STREET": "BOZCAYURT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "GÜZELYURT",
        "STREET": "GAZİEMİR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "GÜZELYURT",
        "STREET": "IHLARA-AKPINAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "GÜZELYURT",
        "STREET": "IHLARA-HARMANLIK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "GÜZELYURT",
        "STREET": "IHLARA-KARŞIYAKA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "GÜZELYURT",
        "STREET": "IHLARA-KAYAARDI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "GÜZELYURT",
        "STREET": "ILISU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "GÜZELYURT",
        "STREET": "SELİME-KAYAALTI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "GÜZELYURT",
        "STREET": "SELİME-KÖPRÜBAŞI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "GÜZELYURT",
        "STREET": "SELİME-YENİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "GÜZELYURT",
        "STREET": "SİVRİHİSAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "GÜZELYURT",
        "STREET": "UZUNKAYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "GÜZELYURT",
        "STREET": "YAPRAKHİSAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "GÜZELYURT",
        "STREET": "YENİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "GÜZELYURT",
        "STREET": "YUKARI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "ORTAKÖY",
        "STREET": "AKPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "ORTAKÖY",
        "STREET": "BALCI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "ORTAKÖY",
        "STREET": "BOZKIR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "ORTAKÖY",
        "STREET": "CAMUZLUK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "ORTAKÖY",
        "STREET": "CECELİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "ORTAKÖY",
        "STREET": "CUMALİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "ORTAKÖY",
        "STREET": "DEVEDAMI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "ORTAKÖY",
        "STREET": "DURHASANLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "ORTAKÖY",
        "STREET": "ESENTEPE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "ORTAKÖY",
        "STREET": "FATİH MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "ORTAKÖY",
        "STREET": "GÖKKAYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "ORTAKÖY",
        "STREET": "GÖKLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "ORTAKÖY",
        "STREET": "HACIMAHMUTUŞAĞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "ORTAKÖY",
        "STREET": "HACIİBRAHİMUŞAĞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "ORTAKÖY",
        "STREET": "HARMANDALI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "ORTAKÖY",
        "STREET": "HIDIRLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "ORTAKÖY",
        "STREET": "HOCABEYLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "ORTAKÖY",
        "STREET": "KARAPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "ORTAKÖY",
        "STREET": "KARŞI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "ORTAKÖY",
        "STREET": "KIZILAY MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "ORTAKÖY",
        "STREET": "KÜMBET KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "ORTAKÖY",
        "STREET": "MALTEPE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "ORTAKÖY",
        "STREET": "MEHMET AKİF ERSOY MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "ORTAKÖY",
        "STREET": "NAMLIKIŞLA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "ORTAKÖY",
        "STREET": "OZANCIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "ORTAKÖY",
        "STREET": "OĞUZLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "ORTAKÖY",
        "STREET": "PINARBAŞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "ORTAKÖY",
        "STREET": "PLEVNE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "ORTAKÖY",
        "STREET": "PİRLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "ORTAKÖY",
        "STREET": "REŞADİYE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "ORTAKÖY",
        "STREET": "SALARIALACA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "ORTAKÖY",
        "STREET": "SALARIGÖDELER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "ORTAKÖY",
        "STREET": "SARIKARAMAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "ORTAKÖY",
        "STREET": "SATANSARI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "ORTAKÖY",
        "STREET": "SEKSENUŞAĞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "ORTAKÖY",
        "STREET": "YENİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "ORTAKÖY",
        "STREET": "YILDIRIMLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "ORTAKÖY",
        "STREET": "ZAFER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "ORTAKÖY",
        "STREET": "ÇANKAYA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "ORTAKÖY",
        "STREET": "ÇATİN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "ORTAKÖY",
        "STREET": "ÇİFTEVİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "ORTAKÖY",
        "STREET": "İSTİKLAL MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "SARIYAHŞİ",
        "STREET": "15 TEMMUZ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "SARIYAHŞİ",
        "STREET": "BEKDİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "SARIYAHŞİ",
        "STREET": "BOĞAZKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "SARIYAHŞİ",
        "STREET": "DEMİRCİOBASI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "SARIYAHŞİ",
        "STREET": "FATİH MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "SARIYAHŞİ",
        "STREET": "KARŞIYAKA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "SARIYAHŞİ",
        "STREET": "KIZILIRMAK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "SARIYAHŞİ",
        "STREET": "KURTULUŞ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "SARIYAHŞİ",
        "STREET": "SİPAHİLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "SARIYAHŞİ",
        "STREET": "YAYLAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "SARIYAHŞİ",
        "STREET": "YENİDOĞAN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "SARIYAHŞİ",
        "STREET": "YENİTORUNOBASI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "SARIYAHŞİ",
        "STREET": "ÖZAL MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "SARIYAHŞİ",
        "STREET": "ŞAFAKTEPE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "SULTANHANI",
        "STREET": "CUMHURİYET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "SULTANHANI",
        "STREET": "GAZİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "SULTANHANI",
        "STREET": "KOYUNCU MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "SULTANHANI",
        "STREET": "SELÇUK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "SULTANHANI",
        "STREET": "TÜRKİSTAN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "SULTANHANI",
        "STREET": "YEŞİLTÖMEK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "SULTANHANI",
        "STREET": "ZAFER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AKSARAY",
        "DISTRICT": "SULTANHANI",
        "STREET": "İSTİKAMET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "MERKEZ",
        "STREET": "ABACI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "MERKEZ",
        "STREET": "AKBİLEK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "MERKEZ",
        "STREET": "AKSALUR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "MERKEZ",
        "STREET": "AKTAŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "MERKEZ",
        "STREET": "AKYAZI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "MERKEZ",
        "STREET": "ALAKADI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "MERKEZ",
        "STREET": "ALBAYRAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "MERKEZ",
        "STREET": "AMASYA E TİPİ KAPALI CEZA İNFAZ KURUMU",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "MERKEZ",
        "STREET": "ARDIÇLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "MERKEZ",
        "STREET": "AVŞAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "MERKEZ",
        "STREET": "AYDINCA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "MERKEZ",
        "STREET": "AYDINLIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "MERKEZ",
        "STREET": "AYDOĞDU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "MERKEZ",
        "STREET": "BAHÇELERİÇİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "MERKEZ",
        "STREET": "BAYAT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "MERKEZ",
        "STREET": "BAĞLARÜSTÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "MERKEZ",
        "STREET": "BAĞLICA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "MERKEZ",
        "STREET": "BEKE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "MERKEZ",
        "STREET": "BELDAĞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "MERKEZ",
        "STREET": "BEYAZITPAŞA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "MERKEZ",
        "STREET": "BOĞAKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "MERKEZ",
        "STREET": "BOĞAZKÖY MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "MERKEZ",
        "STREET": "BULDUKLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "MERKEZ",
        "STREET": "BÖKE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "MERKEZ",
        "STREET": "BÜYÜKKIZILCA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "MERKEZ",
        "STREET": "DADI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "MERKEZ",
        "STREET": "DAMUDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "MERKEZ",
        "STREET": "DEMET EVLER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "MERKEZ",
        "STREET": "DERE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "MERKEZ",
        "STREET": "DEĞİRMENDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "MERKEZ",
        "STREET": "DOĞANTEPE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "MERKEZ",
        "STREET": "DURUCA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "MERKEZ",
        "STREET": "DİREKLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "MERKEZ",
        "STREET": "ELLİBEŞEVLER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "MERKEZ",
        "STREET": "ELİKTEKKE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "MERKEZ",
        "STREET": "ERMİŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "MERKEZ",
        "STREET": "ESKİKIZILCA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "MERKEZ",
        "STREET": "EZİNEPAZAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "MERKEZ",
        "STREET": "FETHİYE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "MERKEZ",
        "STREET": "FINDIKLI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "MERKEZ",
        "STREET": "GERNE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "MERKEZ",
        "STREET": "GÖKDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "MERKEZ",
        "STREET": "GÖKMEDRESE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "MERKEZ",
        "STREET": "GÖLLÜ BAĞLARI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "MERKEZ",
        "STREET": "GÖZLEK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "MERKEZ",
        "STREET": "GÜMÜŞLÜ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "MERKEZ",
        "STREET": "HACI İLYAS MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "MERKEZ",
        "STREET": "HACILAR MEYDANI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "MERKEZ",
        "STREET": "HALİFELİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "MERKEZ",
        "STREET": "HASABDAL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "MERKEZ",
        "STREET": "HATUNİYE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "MERKEZ",
        "STREET": "HELVACI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "MERKEZ",
        "STREET": "HIZIRPAŞA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "MERKEZ",
        "STREET": "KALEBOĞAZI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "MERKEZ",
        "STREET": "KALEKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "MERKEZ",
        "STREET": "KAPIKAYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "MERKEZ",
        "STREET": "KARAALİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "MERKEZ",
        "STREET": "KARAKESE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "MERKEZ",
        "STREET": "KARAKÖPRÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "MERKEZ",
        "STREET": "KARASENİR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "MERKEZ",
        "STREET": "KARATAŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "MERKEZ",
        "STREET": "KARAÇAVUŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "MERKEZ",
        "STREET": "KARAİBRAHİM KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "MERKEZ",
        "STREET": "KARSAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "MERKEZ",
        "STREET": "KAYABAŞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "MERKEZ",
        "STREET": "KAYACIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "MERKEZ",
        "STREET": "KAYRAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "MERKEZ",
        "STREET": "KEÇİLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "MERKEZ",
        "STREET": "KEŞLİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "MERKEZ",
        "STREET": "KIZILKIŞLACIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "MERKEZ",
        "STREET": "KIZOĞLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "MERKEZ",
        "STREET": "KIZSEKİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "MERKEZ",
        "STREET": "KOZA  MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "MERKEZ",
        "STREET": "KURŞUNLU MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "MERKEZ",
        "STREET": "KUTU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "MERKEZ",
        "STREET": "KUZGEÇE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "MERKEZ",
        "STREET": "KÖYCEĞİZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "MERKEZ",
        "STREET": "KÜÇÜKKIZILCA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "MERKEZ",
        "STREET": "KİRAZLIDERE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "MERKEZ",
        "STREET": "MAHMATLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "MERKEZ",
        "STREET": "MEHMET PAŞA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "MERKEZ",
        "STREET": "MEŞELİÇİFTLİĞİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "MERKEZ",
        "STREET": "MUSAKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "MERKEZ",
        "STREET": "NERGİZ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "MERKEZ",
        "STREET": "OLUZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "MERKEZ",
        "STREET": "ORMAN BAĞLARI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "MERKEZ",
        "STREET": "ORMANÖZÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "MERKEZ",
        "STREET": "ORTAKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "MERKEZ",
        "STREET": "OVASARAY KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "MERKEZ",
        "STREET": "PİRİNÇÇİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "MERKEZ",
        "STREET": "SARAYCIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "MERKEZ",
        "STREET": "SARAYÖZÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "MERKEZ",
        "STREET": "SARIALAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "MERKEZ",
        "STREET": "SARIKIZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "MERKEZ",
        "STREET": "SARIMEŞE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "MERKEZ",
        "STREET": "SARIYAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "MERKEZ",
        "STREET": "SAVADİYE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "MERKEZ",
        "STREET": "SAZKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "MERKEZ",
        "STREET": "SEVİNCER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "MERKEZ",
        "STREET": "SIRACEVİZLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "MERKEZ",
        "STREET": "SOFULAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "MERKEZ",
        "STREET": "SOMA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "MERKEZ",
        "STREET": "TATAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "MERKEZ",
        "STREET": "TUZSUZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "MERKEZ",
        "STREET": "UYGUR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "MERKEZ",
        "STREET": "YASSIÇAL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "MERKEZ",
        "STREET": "YAVRU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "MERKEZ",
        "STREET": "YAZI BAĞLARI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "MERKEZ",
        "STREET": "YAĞCIABDAL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "MERKEZ",
        "STREET": "YAĞMUR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "MERKEZ",
        "STREET": "YENİKÖY MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "MERKEZ",
        "STREET": "YEŞİLDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "MERKEZ",
        "STREET": "YEŞİLYENİCE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "MERKEZ",
        "STREET": "YEŞİLÖZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "MERKEZ",
        "STREET": "YIKILGAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "MERKEZ",
        "STREET": "YILDIZKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "MERKEZ",
        "STREET": "YOLYANI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "MERKEZ",
        "STREET": "YUVACIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "MERKEZ",
        "STREET": "YUVAKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "MERKEZ",
        "STREET": "YÜZEVLER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "MERKEZ",
        "STREET": "ZİYARET-AŞAĞI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "MERKEZ",
        "STREET": "ZİYARET-SARAYCIK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "MERKEZ",
        "STREET": "ZİYARET-YUKARI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇAKALLAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇATALÇAM KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇAVUŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇENGELKAYI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇİVİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇİĞDEMLİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "MERKEZ",
        "STREET": "ÖZFINDIKLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "MERKEZ",
        "STREET": "ÜMÜK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "MERKEZ",
        "STREET": "ÜÇLER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "MERKEZ",
        "STREET": "İBECİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "MERKEZ",
        "STREET": "İHSANİYE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "MERKEZ",
        "STREET": "İLGAZİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "MERKEZ",
        "STREET": "İLYAS KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "MERKEZ",
        "STREET": "İPEKKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "MERKEZ",
        "STREET": "ŞAMLAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "MERKEZ",
        "STREET": "ŞEHİRÜSTÜ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "MERKEZ",
        "STREET": "ŞEYHCUİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "MERKEZ",
        "STREET": "ŞEYHSADİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "GÖYNÜCEK",
        "STREET": "ABACI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "GÖYNÜCEK",
        "STREET": "ALAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "GÖYNÜCEK",
        "STREET": "ARDIÇPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "GÖYNÜCEK",
        "STREET": "ASAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "GÖYNÜCEK",
        "STREET": "AYVALIPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "GÖYNÜCEK",
        "STREET": "BAŞPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "GÖYNÜCEK",
        "STREET": "BEKDEMİR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "GÖYNÜCEK",
        "STREET": "BEŞİKTEPE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "GÖYNÜCEK",
        "STREET": "CUMHURİYET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "GÖYNÜCEK",
        "STREET": "DAMLAÇİMEN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "GÖYNÜCEK",
        "STREET": "DAVUTEVİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "GÖYNÜCEK",
        "STREET": "GEDİKSARAY KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "GÖYNÜCEK",
        "STREET": "GÖKÇELİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "GÖYNÜCEK",
        "STREET": "HARMANCIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "GÖYNÜCEK",
        "STREET": "HASANBEY KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "GÖYNÜCEK",
        "STREET": "ILISU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "GÖYNÜCEK",
        "STREET": "KAFARLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "GÖYNÜCEK",
        "STREET": "KARAYAKUP KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "GÖYNÜCEK",
        "STREET": "KARAŞAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "GÖYNÜCEK",
        "STREET": "KERTME KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "GÖYNÜCEK",
        "STREET": "KERVANSARAY KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "GÖYNÜCEK",
        "STREET": "KIŞLABEYİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "GÖYNÜCEK",
        "STREET": "KONURALAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "GÖYNÜCEK",
        "STREET": "KOYUNCU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "GÖYNÜCEK",
        "STREET": "KUYULU KAVAKLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "GÖYNÜCEK",
        "STREET": "MERKEZ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "GÖYNÜCEK",
        "STREET": "PENPELİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "GÖYNÜCEK",
        "STREET": "SIĞIRÇAYI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "GÖYNÜCEK",
        "STREET": "TENCİRLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "GÖYNÜCEK",
        "STREET": "TERZİKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "GÖYNÜCEK",
        "STREET": "YASSIKIŞLA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "GÖYNÜCEK",
        "STREET": "YENİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "GÖYNÜCEK",
        "STREET": "YENİKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "GÖYNÜCEK",
        "STREET": "ÇAMURLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "GÖYNÜCEK",
        "STREET": "ÇAYAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "GÖYNÜCEK",
        "STREET": "ÇAYKIŞLA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "GÖYNÜCEK",
        "STREET": "ÇULPARA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "GÖYNÜCEK",
        "STREET": "İKİZYAKA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "GÖYNÜCEK",
        "STREET": "ŞARKLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "GÖYNÜCEK",
        "STREET": "ŞIHLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "GÖYNÜCEK",
        "STREET": "ŞIHOĞLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "GÜMÜŞHACIKÖY",
        "STREET": "ADATEPE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "GÜMÜŞHACIKÖY",
        "STREET": "AKPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "GÜMÜŞHACIKÖY",
        "STREET": "ALÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "GÜMÜŞHACIKÖY",
        "STREET": "ARTIKABAT MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "GÜMÜŞHACIKÖY",
        "STREET": "AĞCADEDE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "GÜMÜŞHACIKÖY",
        "STREET": "AŞAĞIOVACIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "GÜMÜŞHACIKÖY",
        "STREET": "BACAKOĞLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "GÜMÜŞHACIKÖY",
        "STREET": "BADEMLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "GÜMÜŞHACIKÖY",
        "STREET": "BALIKLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "GÜMÜŞHACIKÖY",
        "STREET": "BEDEN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "GÜMÜŞHACIKÖY",
        "STREET": "CAMİKEBİR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "GÜMÜŞHACIKÖY",
        "STREET": "CUMARA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "GÜMÜŞHACIKÖY",
        "STREET": "DERBENTOBRUĞU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "GÜMÜŞHACIKÖY",
        "STREET": "DOLUCA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "GÜMÜŞHACIKÖY",
        "STREET": "DUMANLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "GÜMÜŞHACIKÖY",
        "STREET": "ESLEMEZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "GÜMÜŞHACIKÖY",
        "STREET": "GÜLLÜCE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "GÜMÜŞHACIKÖY",
        "STREET": "GÜPLÜCE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "GÜMÜŞHACIKÖY",
        "STREET": "GÜVEMÖZÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "GÜMÜŞHACIKÖY",
        "STREET": "HACIYAHYA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "GÜMÜŞHACIKÖY",
        "STREET": "IRGAT MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "GÜMÜŞHACIKÖY",
        "STREET": "KARAALİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "GÜMÜŞHACIKÖY",
        "STREET": "KARACAÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "GÜMÜŞHACIKÖY",
        "STREET": "KARAKAYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "GÜMÜŞHACIKÖY",
        "STREET": "KAĞNICI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "GÜMÜŞHACIKÖY",
        "STREET": "KEÇİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "GÜMÜŞHACIKÖY",
        "STREET": "KILIÇASLAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "GÜMÜŞHACIKÖY",
        "STREET": "KIRCA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "GÜMÜŞHACIKÖY",
        "STREET": "KIZIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "GÜMÜŞHACIKÖY",
        "STREET": "KIZILCA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "GÜMÜŞHACIKÖY",
        "STREET": "KOLTUK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "GÜMÜŞHACIKÖY",
        "STREET": "KONUKTEPE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "GÜMÜŞHACIKÖY",
        "STREET": "KORKUT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "GÜMÜŞHACIKÖY",
        "STREET": "KUTLUCA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "GÜMÜŞHACIKÖY",
        "STREET": "KUZALAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "GÜMÜŞHACIKÖY",
        "STREET": "KÖSELER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "GÜMÜŞHACIKÖY",
        "STREET": "KİZİROĞLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "GÜMÜŞHACIKÖY",
        "STREET": "OVABAŞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "GÜMÜŞHACIKÖY",
        "STREET": "PUSACAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "GÜMÜŞHACIKÖY",
        "STREET": "SALLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "GÜMÜŞHACIKÖY",
        "STREET": "SARAY MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "GÜMÜŞHACIKÖY",
        "STREET": "SARAYCIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "GÜMÜŞHACIKÖY",
        "STREET": "SARAYÖZÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "GÜMÜŞHACIKÖY",
        "STREET": "SEKÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "GÜMÜŞHACIKÖY",
        "STREET": "TEKKE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "GÜMÜŞHACIKÖY",
        "STREET": "YAZIYERİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "GÜMÜŞHACIKÖY",
        "STREET": "YENİCAMİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "GÜMÜŞHACIKÖY",
        "STREET": "YENİKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "GÜMÜŞHACIKÖY",
        "STREET": "ÇALKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "GÜMÜŞHACIKÖY",
        "STREET": "ÇAVUŞKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "GÜMÜŞHACIKÖY",
        "STREET": "ÇAY MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "GÜMÜŞHACIKÖY",
        "STREET": "ÇETMİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "GÜMÜŞHACIKÖY",
        "STREET": "ÇİFTÇİOĞLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "GÜMÜŞHACIKÖY",
        "STREET": "ÇİTLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "GÜMÜŞHACIKÖY",
        "STREET": "İMİRLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "GÜMÜŞHACIKÖY",
        "STREET": "ŞIHLAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "HAMAMÖZÜ",
        "STREET": "ALANKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "HAMAMÖZÜ",
        "STREET": "ARKUTBEY MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "HAMAMÖZÜ",
        "STREET": "ARPADERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "HAMAMÖZÜ",
        "STREET": "DAMLADERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "HAMAMÖZÜ",
        "STREET": "DEDEKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "HAMAMÖZÜ",
        "STREET": "GÖLKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "HAMAMÖZÜ",
        "STREET": "GÖÇERİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "HAMAMÖZÜ",
        "STREET": "HAMİTKAPLAN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "HAMAMÖZÜ",
        "STREET": "HIDIRLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "HAMAMÖZÜ",
        "STREET": "KIZILCAÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "HAMAMÖZÜ",
        "STREET": "KÖPRÜBAŞI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "HAMAMÖZÜ",
        "STREET": "MAĞARAOBRUĞU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "HAMAMÖZÜ",
        "STREET": "SARAY MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "HAMAMÖZÜ",
        "STREET": "SARAYÖZÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "HAMAMÖZÜ",
        "STREET": "TEKÇAM KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "HAMAMÖZÜ",
        "STREET": "TEPEKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "HAMAMÖZÜ",
        "STREET": "TUTKUNLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "HAMAMÖZÜ",
        "STREET": "UMARCA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "HAMAMÖZÜ",
        "STREET": "YEMİŞEN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "HAMAMÖZÜ",
        "STREET": "YUKARIOVACIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "HAMAMÖZÜ",
        "STREET": "ÇAYKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "MERZİFON",
        "STREET": "ABİDEHATUN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "MERZİFON",
        "STREET": "AKPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "MERZİFON",
        "STREET": "AKSUNGUR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "MERZİFON",
        "STREET": "AKTARLA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "MERZİFON",
        "STREET": "AKÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "MERZİFON",
        "STREET": "ALICIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "MERZİFON",
        "STREET": "ALIŞAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "MERZİFON",
        "STREET": "AŞAĞIBÜK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "MERZİFON",
        "STREET": "BAHÇECİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "MERZİFON",
        "STREET": "BAHÇELİEVLER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "MERZİFON",
        "STREET": "BALGÖZE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "MERZİFON",
        "STREET": "BAYAT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "MERZİFON",
        "STREET": "BAYAZIT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "MERZİFON",
        "STREET": "BAĞLARBAŞI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "MERZİFON",
        "STREET": "BULAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "MERZİFON",
        "STREET": "BUĞDAYLI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "MERZİFON",
        "STREET": "BÜYÜKÇAY KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "MERZİFON",
        "STREET": "CAMİCEDİT MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "MERZİFON",
        "STREET": "DEMİRPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "MERZİFON",
        "STREET": "DEREALAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "MERZİFON",
        "STREET": "DİPHACI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "MERZİFON",
        "STREET": "ELMAYOLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "MERZİFON",
        "STREET": "ESENTEPE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "MERZİFON",
        "STREET": "ESKİ CAMİİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "MERZİFON",
        "STREET": "EYMİR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "MERZİFON",
        "STREET": "GAZİMAHBUP MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "MERZİFON",
        "STREET": "GELİNSİNİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "MERZİFON",
        "STREET": "GÖKÇEBAĞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "MERZİFON",
        "STREET": "GÜMÜŞTEPE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "MERZİFON",
        "STREET": "HACET KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "MERZİFON",
        "STREET": "HACIBALI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "MERZİFON",
        "STREET": "HACIHASAN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "MERZİFON",
        "STREET": "HACIYAKUP KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "MERZİFON",
        "STREET": "HAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "MERZİFON",
        "STREET": "HARMANLAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "MERZİFON",
        "STREET": "HAYRETTİN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "MERZİFON",
        "STREET": "HIRKA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "MERZİFON",
        "STREET": "HOCASÜLEYMAN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "MERZİFON",
        "STREET": "KAMIŞLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "MERZİFON",
        "STREET": "KARACAKAYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "MERZİFON",
        "STREET": "KARAMAĞARA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "MERZİFON",
        "STREET": "KARAMUSTAFAPAŞA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "MERZİFON",
        "STREET": "KARATEPE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "MERZİFON",
        "STREET": "KARŞIYAKA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "MERZİFON",
        "STREET": "KAYADÜZÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "MERZİFON",
        "STREET": "KIREYMİR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "MERZİFON",
        "STREET": "KIZILEĞREK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "MERZİFON",
        "STREET": "KOÇ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "MERZİFON",
        "STREET": "KUYU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "MERZİFON",
        "STREET": "KÜMBET HATUN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "MERZİFON",
        "STREET": "KÜÇÜKÇAY KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "MERZİFON",
        "STREET": "MAHMUTLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "MERZİFON",
        "STREET": "MAHSEN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "MERZİFON",
        "STREET": "MEHMET AKİF ERSOY MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "MERZİFON",
        "STREET": "MUŞRUF KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "MERZİFON",
        "STREET": "NACCAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "MERZİFON",
        "STREET": "NUSRATİYE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "MERZİFON",
        "STREET": "ORTABÜK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "MERZİFON",
        "STREET": "ORTAOVA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "MERZİFON",
        "STREET": "OSMANOĞLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "MERZİFON",
        "STREET": "OYMAAĞAÇ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "MERZİFON",
        "STREET": "OYMAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "MERZİFON",
        "STREET": "PEKMEZCİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "MERZİFON",
        "STREET": "SARAYCIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "MERZİFON",
        "STREET": "SARI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "MERZİFON",
        "STREET": "SARIBUĞDAY KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "MERZİFON",
        "STREET": "SAZLICA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "MERZİFON",
        "STREET": "SELİMİYE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "MERZİFON",
        "STREET": "SOFULAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "MERZİFON",
        "STREET": "TAVŞAN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "MERZİFON",
        "STREET": "TÜRKOĞLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "MERZİFON",
        "STREET": "UZUNYAZI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "MERZİFON",
        "STREET": "YAKUP KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "MERZİFON",
        "STREET": "YALNIZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "MERZİFON",
        "STREET": "YAYLACIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "MERZİFON",
        "STREET": "YENİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "MERZİFON",
        "STREET": "YENİCE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "MERZİFON",
        "STREET": "YEŞİLTEPE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "MERZİFON",
        "STREET": "YEŞİLÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "MERZİFON",
        "STREET": "YOLÜSTÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "MERZİFON",
        "STREET": "YUKARIBÜK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "MERZİFON",
        "STREET": "YUNUS EMRE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "MERZİFON",
        "STREET": "ÇAMLICA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "MERZİFON",
        "STREET": "ÇAVUNDUR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "MERZİFON",
        "STREET": "ÇAYBAŞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "MERZİFON",
        "STREET": "ÇAYIR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "MERZİFON",
        "STREET": "ÇAYIRÖZÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "MERZİFON",
        "STREET": "ÇOBANÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "MERZİFON",
        "STREET": "İNALANI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "MERZİFON",
        "STREET": "ŞEYHYENİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "SULUOVA",
        "STREET": "AKÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "SULUOVA",
        "STREET": "ALABEDİR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "SULUOVA",
        "STREET": "ARMUTLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "SULUOVA",
        "STREET": "ARUCAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "SULUOVA",
        "STREET": "ATA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "SULUOVA",
        "STREET": "AYRANCI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "SULUOVA",
        "STREET": "AŞAĞIKARASU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "SULUOVA",
        "STREET": "BAYIRLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "SULUOVA",
        "STREET": "BEYAZIT MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "SULUOVA",
        "STREET": "BORSA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "SULUOVA",
        "STREET": "BOYALI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "SULUOVA",
        "STREET": "BİR EYLÜL MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "SULUOVA",
        "STREET": "CEVİZDİBİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "SULUOVA",
        "STREET": "CUMHURİYET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "SULUOVA",
        "STREET": "CÜRLÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "SULUOVA",
        "STREET": "DERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "SULUOVA",
        "STREET": "DEREBAŞALAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "SULUOVA",
        "STREET": "DEVECİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "SULUOVA",
        "STREET": "ERASLAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "SULUOVA",
        "STREET": "EYMİR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "SULUOVA",
        "STREET": "EĞRİBÜK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "SULUOVA",
        "STREET": "FATİH MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "SULUOVA",
        "STREET": "GİRNE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "SULUOVA",
        "STREET": "HACIBAYRAM MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "SULUOVA",
        "STREET": "HACIHAYTA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "SULUOVA",
        "STREET": "HARMANAĞILI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "SULUOVA",
        "STREET": "HÜRRİYET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "SULUOVA",
        "STREET": "KANATPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "SULUOVA",
        "STREET": "KAPANCI AĞILI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "SULUOVA",
        "STREET": "KARAAĞAÇ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "SULUOVA",
        "STREET": "KAZANLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "SULUOVA",
        "STREET": "KERİMOĞLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "SULUOVA",
        "STREET": "KILIÇASLAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "SULUOVA",
        "STREET": "KIRANBAŞALAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "SULUOVA",
        "STREET": "KOLAY KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "SULUOVA",
        "STREET": "KULU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "SULUOVA",
        "STREET": "KURNAZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "SULUOVA",
        "STREET": "KUTLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "SULUOVA",
        "STREET": "KUZALAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "SULUOVA",
        "STREET": "KÜPELİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "SULUOVA",
        "STREET": "MAARİF MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "SULUOVA",
        "STREET": "MAGOSA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "SULUOVA",
        "STREET": "ORTA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "SULUOVA",
        "STREET": "ORTAYAZI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "SULUOVA",
        "STREET": "OĞULBAĞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "SULUOVA",
        "STREET": "PAZAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "SULUOVA",
        "STREET": "SALUCU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "SULUOVA",
        "STREET": "SARAYDÜZÜ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "SULUOVA",
        "STREET": "SAYGILI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "SULUOVA",
        "STREET": "SEYFE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "SULUOVA",
        "STREET": "SOKU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "SULUOVA",
        "STREET": "UZUNOBA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "SULUOVA",
        "STREET": "YENİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "SULUOVA",
        "STREET": "YENİDOĞAN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "SULUOVA",
        "STREET": "YOLPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "SULUOVA",
        "STREET": "YÜZBEYİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "SULUOVA",
        "STREET": "ÇAYÜSTÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "SULUOVA",
        "STREET": "ÇELTEKMADENİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "SULUOVA",
        "STREET": "ÇUKURÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "SULUOVA",
        "STREET": "ÖZALAKADI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "SULUOVA",
        "STREET": "ŞEKER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "TAŞOVA",
        "STREET": "ALPASLAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "TAŞOVA",
        "STREET": "ALTINLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "TAŞOVA",
        "STREET": "ALÇAKBEL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "TAŞOVA",
        "STREET": "ANDIRAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "TAŞOVA",
        "STREET": "ARDIÇÖNÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "TAŞOVA",
        "STREET": "ARPADERESİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "TAŞOVA",
        "STREET": "BALLICA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "TAŞOVA",
        "STREET": "BELEVİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "TAŞOVA",
        "STREET": "BORABOY KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "TAŞOVA",
        "STREET": "CUMHURİYET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "TAŞOVA",
        "STREET": "DEREKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "TAŞOVA",
        "STREET": "DERELİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "TAŞOVA",
        "STREET": "DESTEK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "TAŞOVA",
        "STREET": "DEVRE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "TAŞOVA",
        "STREET": "DOĞU MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "TAŞOVA",
        "STREET": "DURUCASU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "TAŞOVA",
        "STREET": "DUTLUK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "TAŞOVA",
        "STREET": "DÖRTYOL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "TAŞOVA",
        "STREET": "ELMAKIRI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "TAŞOVA",
        "STREET": "ESENÇAY KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "TAŞOVA",
        "STREET": "GEMİBÜKÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "TAŞOVA",
        "STREET": "GEYDOĞAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "TAŞOVA",
        "STREET": "GÖKPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "TAŞOVA",
        "STREET": "GÜNGÖRMÜŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "TAŞOVA",
        "STREET": "GÜRSU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "TAŞOVA",
        "STREET": "GÜVENDİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "TAŞOVA",
        "STREET": "HACIBEY KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "TAŞOVA",
        "STREET": "HÜSNÜOĞLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "TAŞOVA",
        "STREET": "ILICA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "TAŞOVA",
        "STREET": "ILIPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "TAŞOVA",
        "STREET": "KARABÜK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "TAŞOVA",
        "STREET": "KARAMUK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "TAŞOVA",
        "STREET": "KARLIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "TAŞOVA",
        "STREET": "KARSAVUL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "TAŞOVA",
        "STREET": "KAVALOLUĞU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "TAŞOVA",
        "STREET": "KIRKHARMAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "TAŞOVA",
        "STREET": "KIZGÜLDÜREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "TAŞOVA",
        "STREET": "KORUBAŞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "TAŞOVA",
        "STREET": "KOZLUCA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "TAŞOVA",
        "STREET": "KUMLUCA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "TAŞOVA",
        "STREET": "MERCİMEKKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "TAŞOVA",
        "STREET": "MÜLKBÜKÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "TAŞOVA",
        "STREET": "SANAYİİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "TAŞOVA",
        "STREET": "SEPETLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "TAŞOVA",
        "STREET": "SOFUALAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "TAŞOVA",
        "STREET": "TATLIPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "TAŞOVA",
        "STREET": "TEKKE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "TAŞOVA",
        "STREET": "TEKPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "TAŞOVA",
        "STREET": "TEPE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "TAŞOVA",
        "STREET": "TÜRKMENDAMI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "TAŞOVA",
        "STREET": "ULUKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "TAŞOVA",
        "STREET": "YAYLADİBİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "TAŞOVA",
        "STREET": "YAYLASARAY KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "TAŞOVA",
        "STREET": "YEMİŞEN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "TAŞOVA",
        "STREET": "YENİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "TAŞOVA",
        "STREET": "YENİDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "TAŞOVA",
        "STREET": "YERKOZLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "TAŞOVA",
        "STREET": "YEŞİLIRMAK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "TAŞOVA",
        "STREET": "YEŞİLTEPE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "TAŞOVA",
        "STREET": "YEŞİLYURT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "TAŞOVA",
        "STREET": "YOLAÇAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "TAŞOVA",
        "STREET": "YUKARIBARAKLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "TAŞOVA",
        "STREET": "ÇAKIRSU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "TAŞOVA",
        "STREET": "ÇALKAYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "TAŞOVA",
        "STREET": "ÇAMBÜKÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "TAŞOVA",
        "STREET": "ÇAYDİBİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "TAŞOVA",
        "STREET": "ÇILKIDIR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "TAŞOVA",
        "STREET": "ÖZBARAKLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "TAŞOVA",
        "STREET": "ŞAHİNLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AMASYA",
        "DISTRICT": "TAŞOVA",
        "STREET": "ŞEYHLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "AKYURT",
        "STREET": "AHMETADİL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "AKYURT",
        "STREET": "ATATÜRK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "AKYURT",
        "STREET": "BALIKHİSAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "AKYURT",
        "STREET": "BEYAZIT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "AKYURT",
        "STREET": "BÜĞDÜZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "AKYURT",
        "STREET": "CÜCÜK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "AKYURT",
        "STREET": "ELECİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "AKYURT",
        "STREET": "GALABA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "AKYURT",
        "STREET": "GÜZELHİSAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "AKYURT",
        "STREET": "HAYDAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "AKYURT",
        "STREET": "KARACAKAYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "AKYURT",
        "STREET": "KARACALAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "AKYURT",
        "STREET": "KARAYATAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "AKYURT",
        "STREET": "KIZIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "AKYURT",
        "STREET": "KOZAYAĞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "AKYURT",
        "STREET": "SAMUT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "AKYURT",
        "STREET": "SARACALAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "AKYURT",
        "STREET": "TEBERİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "AKYURT",
        "STREET": "TİMURHAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "AKYURT",
        "STREET": "UZUNLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "AKYURT",
        "STREET": "YEŞİLTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "AKYURT",
        "STREET": "YILDIRIM MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "AKYURT",
        "STREET": "ÇAM MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "AKYURT",
        "STREET": "ÇARDAKBAĞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "AKYURT",
        "STREET": "ÇINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "AKYURT",
        "STREET": "ŞEYHLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ALTINDAĞ",
        "STREET": "ATIFBEY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ALTINDAĞ",
        "STREET": "AYDINCIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ALTINDAĞ",
        "STREET": "AYDINLIKEVLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ALTINDAĞ",
        "STREET": "BARAJ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ALTINDAĞ",
        "STREET": "BATTALGAZİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ALTINDAĞ",
        "STREET": "BAŞPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ALTINDAĞ",
        "STREET": "BEŞİKKAYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ALTINDAĞ",
        "STREET": "DOĞANTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ALTINDAĞ",
        "STREET": "FERİDUN ÇELİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ALTINDAĞ",
        "STREET": "GÜLTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ALTINDAĞ",
        "STREET": "GÜNEŞEVLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ALTINDAĞ",
        "STREET": "HACETTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ALTINDAĞ",
        "STREET": "HACI BAYRAM MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ALTINDAĞ",
        "STREET": "KALE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ALTINDAĞ",
        "STREET": "KARACAÖREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ALTINDAĞ",
        "STREET": "KARAPÜRÇEK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ALTINDAĞ",
        "STREET": "KAVAKLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ALTINDAĞ",
        "STREET": "MERKEZ GİCİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ALTINDAĞ",
        "STREET": "PEÇENEK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ALTINDAĞ",
        "STREET": "SOLFASOL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ALTINDAĞ",
        "STREET": "TATLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ALTINDAĞ",
        "STREET": "ULUBEY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ALTINDAĞ",
        "STREET": "YILDIZTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ALTINDAĞ",
        "STREET": "ZÜBEYDE HANIM MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ALTINDAĞ",
        "STREET": "ÖNDER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ALTINDAĞ",
        "STREET": "ÖRNEK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "AYAŞ",
        "STREET": "AKKAYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "AYAŞ",
        "STREET": "BAYAT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "AYAŞ",
        "STREET": "BAYRAM MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "AYAŞ",
        "STREET": "BAŞAYAŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "AYAŞ",
        "STREET": "BAŞBEREKET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "AYAŞ",
        "STREET": "CAMİATİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "AYAŞ",
        "STREET": "DERVİŞİMAM MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "AYAŞ",
        "STREET": "EMİNE TEVFİKA AYAŞLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "AYAŞ",
        "STREET": "EVCİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "AYAŞ",
        "STREET": "FERAHFAKİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "AYAŞ",
        "STREET": "FERUZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "AYAŞ",
        "STREET": "GENÇALİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "AYAŞ",
        "STREET": "GÖKLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "AYAŞ",
        "STREET": "GÖKÇEBAĞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "AYAŞ",
        "STREET": "HACIMEMİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "AYAŞ",
        "STREET": "HACIRECEP MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "AYAŞ",
        "STREET": "HACIVELİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "AYAŞ",
        "STREET": "ILICA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "AYAŞ",
        "STREET": "OLTAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "AYAŞ",
        "STREET": "ORTABEREKET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "AYAŞ",
        "STREET": "PINARYAKA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "AYAŞ",
        "STREET": "SİNANLI CUMA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "AYAŞ",
        "STREET": "SİNANLI HOCASİNAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "AYAŞ",
        "STREET": "SİNANLI MAHKEME MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "AYAŞ",
        "STREET": "TEKKE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "AYAŞ",
        "STREET": "ULUPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "AYAŞ",
        "STREET": "UĞURÇAYIRI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "AYAŞ",
        "STREET": "YAĞMURDEDE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "AYAŞ",
        "STREET": "ÇANILLI ULUYOL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "AYAŞ",
        "STREET": "ÇANILLI ÇİĞDEMCİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "AYAŞ",
        "STREET": "ÖMEROĞLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "AYAŞ",
        "STREET": "İLHANKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "AYAŞ",
        "STREET": "ŞEYHMUHİTTİN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "BALA",
        "STREET": "ABAZLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "BALA",
        "STREET": "AFŞAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "BALA",
        "STREET": "AHMETÇAYIRI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "BALA",
        "STREET": "AKKOYUNLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "BALA",
        "STREET": "AYDOĞAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "BALA",
        "STREET": "AŞAĞIHACIBEKİR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "BALA",
        "STREET": "AŞIKOĞLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "BALA",
        "STREET": "BAHÇEKARADALAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "BALA",
        "STREET": "BEKTAŞLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "BALA",
        "STREET": "BELÇARŞAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "BALA",
        "STREET": "BEYNAM MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "BALA",
        "STREET": "BÜYÜKBAYAT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "BALA",
        "STREET": "BÜYÜKBOYALIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "BALA",
        "STREET": "BÜYÜKCAMİLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "BALA",
        "STREET": "DAVDANLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "BALA",
        "STREET": "DEREKIŞLA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "BALA",
        "STREET": "ERGİN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "BALA",
        "STREET": "EĞRİBASAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "BALA",
        "STREET": "GÖZTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "BALA",
        "STREET": "GÜLBAĞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "BALA",
        "STREET": "HAMİDİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "BALA",
        "STREET": "HANBURUN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "BALA",
        "STREET": "KARAHAMZALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "BALA",
        "STREET": "KARTALTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "BALA",
        "STREET": "KERİŞLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "BALA",
        "STREET": "KESİKKÖPRÜ ERDEMLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "BALA",
        "STREET": "KESİKKÖPRÜ KIZILIRMAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "BALA",
        "STREET": "KESİKKÖPRÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "BALA",
        "STREET": "KOÇYAYLA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "BALA",
        "STREET": "KUYULAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "BALA",
        "STREET": "KÖSELİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "BALA",
        "STREET": "KÜÇÜKBAYAT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "BALA",
        "STREET": "KÜÇÜKBIYIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "BALA",
        "STREET": "KÜÇÜKBOYALIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "BALA",
        "STREET": "KÜÇÜKCAMİLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "BALA",
        "STREET": "SARIHÜYÜK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "BALA",
        "STREET": "SIRAPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "BALA",
        "STREET": "SOFULAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "BALA",
        "STREET": "SUYUGÜZEL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "BALA",
        "STREET": "TATARHÜYÜK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "BALA",
        "STREET": "TEPEKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "BALA",
        "STREET": "TOLKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "BALA",
        "STREET": "YAYLALIÖZÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "BALA",
        "STREET": "YENİKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "BALA",
        "STREET": "YENİYAPANÇARŞAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "BALA",
        "STREET": "YENİYAPANŞEYHLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "BALA",
        "STREET": "YUKARIHACIBEKİR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "BALA",
        "STREET": "YÖRELİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "BALA",
        "STREET": "ÇATALÇEŞME MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "BALA",
        "STREET": "ÇATALÖREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "BALA",
        "STREET": "ÇİĞDEMLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "BALA",
        "STREET": "ÜÇEM MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "BALA",
        "STREET": "İSMETPAŞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "BALA",
        "STREET": "ŞEHRİBAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "BALA",
        "STREET": "ŞENTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "BEYPAZARI",
        "STREET": "ACISU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "BEYPAZARI",
        "STREET": "ADAÖREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "BEYPAZARI",
        "STREET": "AKÇAKAVAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "BEYPAZARI",
        "STREET": "AKÇALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "BEYPAZARI",
        "STREET": "AYVAŞIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "BEYPAZARI",
        "STREET": "AŞAĞIGÜNEY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "BEYPAZARI",
        "STREET": "BATÇA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "BEYPAZARI",
        "STREET": "BAĞÖZÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "BEYPAZARI",
        "STREET": "BAŞAĞAÇ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "BEYPAZARI",
        "STREET": "BAŞÖREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "BEYPAZARI",
        "STREET": "BEYTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "BEYPAZARI",
        "STREET": "BOYALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "BEYPAZARI",
        "STREET": "CUMHURİYET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "BEYPAZARI",
        "STREET": "DAĞŞEYHLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "BEYPAZARI",
        "STREET": "DERELİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "BEYPAZARI",
        "STREET": "DOĞANYURT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "BEYPAZARI",
        "STREET": "DOĞANÇALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "BEYPAZARI",
        "STREET": "DUDAŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "BEYPAZARI",
        "STREET": "DİBECİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "BEYPAZARI",
        "STREET": "DİBEKÖREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "BEYPAZARI",
        "STREET": "DİKMEN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "BEYPAZARI",
        "STREET": "FASIL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "BEYPAZARI",
        "STREET": "GAZİPAŞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "BEYPAZARI",
        "STREET": "GEYİKPINARI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "BEYPAZARI",
        "STREET": "GÜRSÖĞÜT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "BEYPAZARI",
        "STREET": "HACIKARA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "BEYPAZARI",
        "STREET": "HARMANCIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "BEYPAZARI",
        "STREET": "HAYDARLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "BEYPAZARI",
        "STREET": "HIRKATEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "BEYPAZARI",
        "STREET": "KABACA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "BEYPAZARI",
        "STREET": "KABALAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "BEYPAZARI",
        "STREET": "KAPLAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "BEYPAZARI",
        "STREET": "KAPULLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "BEYPAZARI",
        "STREET": "KARACAÖREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "BEYPAZARI",
        "STREET": "KARAÖREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "BEYPAZARI",
        "STREET": "KARAŞAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "BEYPAZARI",
        "STREET": "KARGI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "BEYPAZARI",
        "STREET": "KAYABÜKÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "BEYPAZARI",
        "STREET": "KERBANLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "BEYPAZARI",
        "STREET": "KIRBAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "BEYPAZARI",
        "STREET": "KIRŞEYHLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "BEYPAZARI",
        "STREET": "KIZILCASÖĞÜT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "BEYPAZARI",
        "STREET": "KOZALAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "BEYPAZARI",
        "STREET": "KOZAĞAÇ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "BEYPAZARI",
        "STREET": "KURTKOVAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "BEYPAZARI",
        "STREET": "KURTULUŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "BEYPAZARI",
        "STREET": "KUYUCAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "BEYPAZARI",
        "STREET": "KUYUMCUTEKKE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "BEYPAZARI",
        "STREET": "KÖSELER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "BEYPAZARI",
        "STREET": "KÖST MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "BEYPAZARI",
        "STREET": "MACUN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "BEYPAZARI",
        "STREET": "MAHMUTLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "BEYPAZARI",
        "STREET": "MENÇELER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "BEYPAZARI",
        "STREET": "MİKAİL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "BEYPAZARI",
        "STREET": "NUHHOCA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "BEYPAZARI",
        "STREET": "OYMAAĞAÇ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "BEYPAZARI",
        "STREET": "RÜSTEMPAŞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "BEYPAZARI",
        "STREET": "SARAY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "BEYPAZARI",
        "STREET": "SARIAĞIL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "BEYPAZARI",
        "STREET": "SEKLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "BEYPAZARI",
        "STREET": "SOPÇAALAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "BEYPAZARI",
        "STREET": "TACETTİN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "BEYPAZARI",
        "STREET": "TAHİR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "BEYPAZARI",
        "STREET": "URUŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "BEYPAZARI",
        "STREET": "UŞAKGÖL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "BEYPAZARI",
        "STREET": "YALNIZÇAM MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "BEYPAZARI",
        "STREET": "YEŞİLAĞAÇ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "BEYPAZARI",
        "STREET": "YILDIZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "BEYPAZARI",
        "STREET": "YOĞUNPELİT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "BEYPAZARI",
        "STREET": "YUKARIGÜNEY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "BEYPAZARI",
        "STREET": "YUKARIULUCAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "BEYPAZARI",
        "STREET": "YİĞERLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "BEYPAZARI",
        "STREET": "ZAFER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "BEYPAZARI",
        "STREET": "ÇAKILOBA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "BEYPAZARI",
        "STREET": "ÇANTIRLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "BEYPAZARI",
        "STREET": "ÜREĞİL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "BEYPAZARI",
        "STREET": "İNCEPELİT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "BEYPAZARI",
        "STREET": "İSTİKLAL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ELMADAĞ",
        "STREET": "AKÇAALİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ELMADAĞ",
        "STREET": "AŞAĞIKAMIŞLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ELMADAĞ",
        "STREET": "DELİLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ELMADAĞ",
        "STREET": "EDİĞE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ELMADAĞ",
        "STREET": "GÜMÜŞPALA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ELMADAĞ",
        "STREET": "HASANOĞLAN BAHÇELİEVLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ELMADAĞ",
        "STREET": "HASANOĞLAN FATİH MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ELMADAĞ",
        "STREET": "HASANOĞLAN HAVUZBAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ELMADAĞ",
        "STREET": "KARACAHASAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ELMADAĞ",
        "STREET": "KAYADİBİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ELMADAĞ",
        "STREET": "KEMALPAŞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ELMADAĞ",
        "STREET": "KURTULUŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ELMADAĞ",
        "STREET": "KUŞÇUALİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ELMADAĞ",
        "STREET": "LALABEL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ELMADAĞ",
        "STREET": "MUZAFFER EKŞİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ELMADAĞ",
        "STREET": "SEYİTCEMALİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ELMADAĞ",
        "STREET": "SÜLEYMANLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ELMADAĞ",
        "STREET": "TABURLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ELMADAĞ",
        "STREET": "TATLICA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ELMADAĞ",
        "STREET": "TEKKE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ELMADAĞ",
        "STREET": "YENİCE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ELMADAĞ",
        "STREET": "YENİDOĞAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ELMADAĞ",
        "STREET": "YENİMAHALLE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ELMADAĞ",
        "STREET": "YENİPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ELMADAĞ",
        "STREET": "YEŞİLDERE FATİH MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ELMADAĞ",
        "STREET": "YUKARIKAMIŞLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ELMADAĞ",
        "STREET": "ÜÇEVLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ELMADAĞ",
        "STREET": "İSMETPAŞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ELMADAĞ",
        "STREET": "İSTASYON MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ELMADAĞ",
        "STREET": "ŞEHİTLİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ETİMESGUT",
        "STREET": "30 AĞUSTOS MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ETİMESGUT",
        "STREET": "AHİ MESUT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ETİMESGUT",
        "STREET": "ALSANCAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ETİMESGUT",
        "STREET": "ALTAY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ETİMESGUT",
        "STREET": "ATAKENT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ETİMESGUT",
        "STREET": "ATAYURT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ETİMESGUT",
        "STREET": "AYYILDIZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ETİMESGUT",
        "STREET": "AŞAĞIYURTÇU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ETİMESGUT",
        "STREET": "BAHÇEKAPI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ETİMESGUT",
        "STREET": "BALIKUYUMCU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ETİMESGUT",
        "STREET": "BAĞLICA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ETİMESGUT",
        "STREET": "DEVLET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ETİMESGUT",
        "STREET": "ELVAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ETİMESGUT",
        "STREET": "ERLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ETİMESGUT",
        "STREET": "ERYAMAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ETİMESGUT",
        "STREET": "ETİLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ETİMESGUT",
        "STREET": "FATİH SULTAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ETİMESGUT",
        "STREET": "FEVZİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ETİMESGUT",
        "STREET": "GÖKSU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ETİMESGUT",
        "STREET": "GÜZELKENT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ETİMESGUT",
        "STREET": "KAZIM KARABEKİR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ETİMESGUT",
        "STREET": "ORHUN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ETİMESGUT",
        "STREET": "OĞUZLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ETİMESGUT",
        "STREET": "PİYADE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ETİMESGUT",
        "STREET": "SÜVARİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ETİMESGUT",
        "STREET": "TOPÇU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ETİMESGUT",
        "STREET": "TUNAHAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ETİMESGUT",
        "STREET": "TURKUAZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ETİMESGUT",
        "STREET": "YAPRACIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ETİMESGUT",
        "STREET": "YAVUZ SELİM MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ETİMESGUT",
        "STREET": "YENİ BAĞLICA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ETİMESGUT",
        "STREET": "YEŞİLOVA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ETİMESGUT",
        "STREET": "YUKARIYURTÇU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ETİMESGUT",
        "STREET": "İSTASYON MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ETİMESGUT",
        "STREET": "ŞEHİT OSMAN AVCI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ETİMESGUT",
        "STREET": "ŞEHİTALİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ETİMESGUT",
        "STREET": "ŞEKER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ETİMESGUT",
        "STREET": "ŞEYH ŞAMİL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "EVREN",
        "STREET": "ALTINBAŞAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "EVREN",
        "STREET": "CEBİRLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "EVREN",
        "STREET": "DEMİRAYAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "EVREN",
        "STREET": "ESENTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "EVREN",
        "STREET": "ESKİTORUNOBASI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "EVREN",
        "STREET": "MODERN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "EVREN",
        "STREET": "SOLAKUŞAĞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "EVREN",
        "STREET": "YENİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "EVREN",
        "STREET": "YUSUFUŞAĞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "EVREN",
        "STREET": "ÇATALPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "EVREN",
        "STREET": "İBRAHİMBEYLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "EVREN",
        "STREET": "İNEBEYLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "EVREN",
        "STREET": "ŞERAFETTİN YILMAZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "GÖLBAŞI",
        "STREET": "AHİBOZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "GÖLBAŞI",
        "STREET": "AKÖRENÇARŞAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "GÖLBAŞI",
        "STREET": "ALTUNÇANAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "GÖLBAŞI",
        "STREET": "BAHÇELİEVLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "GÖLBAŞI",
        "STREET": "BALLIKPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "GÖLBAŞI",
        "STREET": "BAĞİÇİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "GÖLBAŞI",
        "STREET": "BEZİRHANE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "GÖLBAŞI",
        "STREET": "BOYALIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "GÖLBAŞI",
        "STREET": "DİKİLİTAŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "GÖLBAŞI",
        "STREET": "EMİRLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "GÖLBAŞI",
        "STREET": "EYMİR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "GÖLBAŞI",
        "STREET": "GAZİOSMANPAŞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "GÖLBAŞI",
        "STREET": "GÖKÇEHÜYÜK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "GÖLBAŞI",
        "STREET": "GÖLBEK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "GÖLBAŞI",
        "STREET": "GÜNALAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "GÖLBAŞI",
        "STREET": "HACIHASAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "GÖLBAŞI",
        "STREET": "HACILAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "GÖLBAŞI",
        "STREET": "HACIMURATLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "GÖLBAŞI",
        "STREET": "HALAÇLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "GÖLBAŞI",
        "STREET": "KARAALİ MERKEZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "GÖLBAŞI",
        "STREET": "KARAALİ YAZLIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "GÖLBAŞI",
        "STREET": "KARACAÖREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "GÖLBAŞI",
        "STREET": "KARAGEDİK AYDIN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "GÖLBAŞI",
        "STREET": "KARAGEDİK ERCAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "GÖLBAŞI",
        "STREET": "KARAOĞLAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "GÖLBAŞI",
        "STREET": "KARŞIYAKA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "GÖLBAŞI",
        "STREET": "KIRIKLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "GÖLBAŞI",
        "STREET": "KIZILCAŞAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "GÖLBAŞI",
        "STREET": "KOPARAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "GÖLBAŞI",
        "STREET": "MAHMATLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "GÖLBAŞI",
        "STREET": "MAHMATLIBAHÇE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "GÖLBAŞI",
        "STREET": "OYACA AKARSU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "GÖLBAŞI",
        "STREET": "OYACA YEŞİLÇAM MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "GÖLBAŞI",
        "STREET": "OĞULBEY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "GÖLBAŞI",
        "STREET": "SELAMETLİ ŞEHİT EMRAH MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "GÖLBAŞI",
        "STREET": "SEĞMENLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "GÖLBAŞI",
        "STREET": "SOĞULCAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "GÖLBAŞI",
        "STREET": "SUBAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "GÖLBAŞI",
        "STREET": "TAŞPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "GÖLBAŞI",
        "STREET": "TEPEYURT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "GÖLBAŞI",
        "STREET": "TOPAKLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "GÖLBAŞI",
        "STREET": "TULUMTAŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "GÖLBAŞI",
        "STREET": "VELİHİMMETLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "GÖLBAŞI",
        "STREET": "YAVRUCUK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "GÖLBAŞI",
        "STREET": "YAYLABAĞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "GÖLBAŞI",
        "STREET": "YAĞLIPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "GÖLBAŞI",
        "STREET": "YURTBEYİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "GÖLBAŞI",
        "STREET": "ÇAYIRLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "GÖLBAŞI",
        "STREET": "ÇELTEK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "GÖLBAŞI",
        "STREET": "ÇİMŞİT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "GÖLBAŞI",
        "STREET": "ÖRENCİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "GÖLBAŞI",
        "STREET": "İKİZCE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "GÖLBAŞI",
        "STREET": "İNCEK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "GÖLBAŞI",
        "STREET": "ŞAFAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "GÜDÜL",
        "STREET": "ADALIKUZU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "GÜDÜL",
        "STREET": "AFŞAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "GÜDÜL",
        "STREET": "AKBAŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "GÜDÜL",
        "STREET": "AKÇAKESE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "GÜDÜL",
        "STREET": "AŞAĞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "GÜDÜL",
        "STREET": "BOYALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "GÜDÜL",
        "STREET": "EMİRLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "GÜDÜL",
        "STREET": "GARİPÇE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "GÜDÜL",
        "STREET": "GÜNEYCE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "GÜDÜL",
        "STREET": "GÜZEL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "GÜDÜL",
        "STREET": "HACILAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "GÜDÜL",
        "STREET": "KADIOBASI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "GÜDÜL",
        "STREET": "KAMANLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "GÜDÜL",
        "STREET": "KARACAÖREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "GÜDÜL",
        "STREET": "KAVAKÖZÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "GÜDÜL",
        "STREET": "KAYI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "GÜDÜL",
        "STREET": "KIRKKAVAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "GÜDÜL",
        "STREET": "MEYVABÜKÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "GÜDÜL",
        "STREET": "SALİHLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "GÜDÜL",
        "STREET": "SAPANLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "GÜDÜL",
        "STREET": "SORGUN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "GÜDÜL",
        "STREET": "TAHTACIÖRENCİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "GÜDÜL",
        "STREET": "TAŞÖREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "GÜDÜL",
        "STREET": "YELLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "GÜDÜL",
        "STREET": "YENİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "GÜDÜL",
        "STREET": "YEŞİLÖZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "GÜDÜL",
        "STREET": "YUKARI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "GÜDÜL",
        "STREET": "ÇAĞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "GÜDÜL",
        "STREET": "ÇUKURÖREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "GÜDÜL",
        "STREET": "ÖZKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "GÜDÜL",
        "STREET": "ÖZÇALTI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "HAYMANA",
        "STREET": "AHIRLIKUYU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "HAYMANA",
        "STREET": "ALAHACILI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "HAYMANA",
        "STREET": "ALAÇIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "HAYMANA",
        "STREET": "ALTIPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "HAYMANA",
        "STREET": "ATAKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "HAYMANA",
        "STREET": "BAHÇECİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "HAYMANA",
        "STREET": "BALTALİN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "HAYMANA",
        "STREET": "BALÇIKHİSAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "HAYMANA",
        "STREET": "BOSTANHÜYÜK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "HAYMANA",
        "STREET": "BOĞAZKAYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "HAYMANA",
        "STREET": "BUMSUZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "HAYMANA",
        "STREET": "BÜYÜKKONAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "HAYMANA",
        "STREET": "BÜYÜKYAĞCI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "HAYMANA",
        "STREET": "CULUK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "HAYMANA",
        "STREET": "CİHANŞAH MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "HAYMANA",
        "STREET": "CİNGİRLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "HAYMANA",
        "STREET": "DEMİRÖZÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "HAYMANA",
        "STREET": "DEREKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "HAYMANA",
        "STREET": "DEVECİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "HAYMANA",
        "STREET": "DEVECİPINARI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "HAYMANA",
        "STREET": "DURUPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "HAYMANA",
        "STREET": "DURUTLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "HAYMANA",
        "STREET": "EMİRLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "HAYMANA",
        "STREET": "ESEN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "HAYMANA",
        "STREET": "ESKİKIŞLA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "HAYMANA",
        "STREET": "EVCİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "HAYMANA",
        "STREET": "EVLİYAFAKI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "HAYMANA",
        "STREET": "GEDİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "HAYMANA",
        "STREET": "GÜLTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "HAYMANA",
        "STREET": "GÜZELCEKALE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "HAYMANA",
        "STREET": "KADIKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "HAYMANA",
        "STREET": "KARAHOCA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "HAYMANA",
        "STREET": "KARAPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "HAYMANA",
        "STREET": "KARASÜLEYMANLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "HAYMANA",
        "STREET": "KATRANCI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "HAYMANA",
        "STREET": "KAVAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "HAYMANA",
        "STREET": "KAYABAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "HAYMANA",
        "STREET": "KERPİÇ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "HAYMANA",
        "STREET": "KESİKKAVAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "HAYMANA",
        "STREET": "KIZILKOYUNLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "HAYMANA",
        "STREET": "KUTLUHAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "HAYMANA",
        "STREET": "KÜÇÜKKONAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "HAYMANA",
        "STREET": "KÜÇÜKYAĞCI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "HAYMANA",
        "STREET": "KİRAZOĞLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "HAYMANA",
        "STREET": "MANDIRA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "HAYMANA",
        "STREET": "MEDRESE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "HAYMANA",
        "STREET": "SAATLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "HAYMANA",
        "STREET": "SARIDEĞİRMEN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "HAYMANA",
        "STREET": "SARIGÖL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "HAYMANA",
        "STREET": "SAZAĞASI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "HAYMANA",
        "STREET": "SERİNYAYLA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "HAYMANA",
        "STREET": "SEYRAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "HAYMANA",
        "STREET": "SINDIRAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "HAYMANA",
        "STREET": "SIRÇASARAY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "HAYMANA",
        "STREET": "SOĞULCA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "HAYMANA",
        "STREET": "SÖĞÜTTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "HAYMANA",
        "STREET": "SİNANLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "HAYMANA",
        "STREET": "TABAKLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "HAYMANA",
        "STREET": "TEPEKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "HAYMANA",
        "STREET": "TOYÇAYIRI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "HAYMANA",
        "STREET": "TÜRKHÜYÜK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "HAYMANA",
        "STREET": "TÜRKŞEREFLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "HAYMANA",
        "STREET": "YAMAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "HAYMANA",
        "STREET": "YAPRAKBAYIRI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "HAYMANA",
        "STREET": "YAYLABEYİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "HAYMANA",
        "STREET": "YENİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "HAYMANA",
        "STREET": "YENİKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "HAYMANA",
        "STREET": "YERGÖMÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "HAYMANA",
        "STREET": "YEŞİLKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "HAYMANA",
        "STREET": "YUKARISEBİL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "HAYMANA",
        "STREET": "YURTBEYLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "HAYMANA",
        "STREET": "ÇALDAĞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "HAYMANA",
        "STREET": "ÇALIŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "HAYMANA",
        "STREET": "ÇATAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "HAYMANA",
        "STREET": "ÇAYRAZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "HAYMANA",
        "STREET": "ÇELTİKLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "HAYMANA",
        "STREET": "İNCİRLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "HAYMANA",
        "STREET": "ŞEREFLİGÖKGÖZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "KAHRAMANKAZAN",
        "STREET": "AHİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "KAHRAMANKAZAN",
        "STREET": "AKINCI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "KAHRAMANKAZAN",
        "STREET": "AKÇAÖREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "KAHRAMANKAZAN",
        "STREET": "ALPAGUT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "KAHRAMANKAZAN",
        "STREET": "ATATÜRK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "KAHRAMANKAZAN",
        "STREET": "AYDIN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "KAHRAMANKAZAN",
        "STREET": "AŞAĞIKARAÖREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "KAHRAMANKAZAN",
        "STREET": "BİTİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "KAHRAMANKAZAN",
        "STREET": "CİĞİR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "KAHRAMANKAZAN",
        "STREET": "DAĞYAKA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "KAHRAMANKAZAN",
        "STREET": "DUTÖZÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "KAHRAMANKAZAN",
        "STREET": "EMİRGAZİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "KAHRAMANKAZAN",
        "STREET": "FATİH MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "KAHRAMANKAZAN",
        "STREET": "FETHİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "KAHRAMANKAZAN",
        "STREET": "GÜNBAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "KAHRAMANKAZAN",
        "STREET": "GÜVENÇ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "KAHRAMANKAZAN",
        "STREET": "KANUNİ SULTAN SÜLEYMAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "KAHRAMANKAZAN",
        "STREET": "KARALAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "KAHRAMANKAZAN",
        "STREET": "KAYI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "KAHRAMANKAZAN",
        "STREET": "KILIÇLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "KAHRAMANKAZAN",
        "STREET": "KINIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "KAHRAMANKAZAN",
        "STREET": "KIŞLA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "KAHRAMANKAZAN",
        "STREET": "KUMPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "KAHRAMANKAZAN",
        "STREET": "ORHANİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "KAHRAMANKAZAN",
        "STREET": "PEÇENEK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "KAHRAMANKAZAN",
        "STREET": "SANCAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "KAHRAMANKAZAN",
        "STREET": "SARAY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "KAHRAMANKAZAN",
        "STREET": "SARAÇ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "KAHRAMANKAZAN",
        "STREET": "SARIAYAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "KAHRAMANKAZAN",
        "STREET": "SARILAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "KAHRAMANKAZAN",
        "STREET": "SATIKADIN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "KAHRAMANKAZAN",
        "STREET": "SOĞUCAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "KAHRAMANKAZAN",
        "STREET": "TEKKE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "KAHRAMANKAZAN",
        "STREET": "UÇARI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "KAHRAMANKAZAN",
        "STREET": "YAKUPDERVİŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "KAHRAMANKAZAN",
        "STREET": "YASSIÖREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "KAHRAMANKAZAN",
        "STREET": "YAVUZ SULTAN SELİM MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "KAHRAMANKAZAN",
        "STREET": "YAYALAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "KAHRAMANKAZAN",
        "STREET": "YAZIBEYLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "KAHRAMANKAZAN",
        "STREET": "YILDIRIMBEYAZIT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "KAHRAMANKAZAN",
        "STREET": "ÇALTA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "KAHRAMANKAZAN",
        "STREET": "ÇİMŞİT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "KAHRAMANKAZAN",
        "STREET": "ÖRENCİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "KAHRAMANKAZAN",
        "STREET": "İMRENDİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "KAHRAMANKAZAN",
        "STREET": "İNCEĞİZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "KAHRAMANKAZAN",
        "STREET": "İNE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "KAHRAMANKAZAN",
        "STREET": "İYMİR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "KAHRAMANKAZAN",
        "STREET": "İÇÖREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "KALECİK",
        "STREET": "AFŞAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "KALECİK",
        "STREET": "AHİKEMALŞENYURT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "KALECİK",
        "STREET": "AHİLER YENİCE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "KALECİK",
        "STREET": "AKCATAŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "KALECİK",
        "STREET": "AKKAYNAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "KALECİK",
        "STREET": "AKKUZULU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "KALECİK",
        "STREET": "AKTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "KALECİK",
        "STREET": "ALTINTAŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "KALECİK",
        "STREET": "ALİBEYLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "KALECİK",
        "STREET": "ARKBÜRK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "KALECİK",
        "STREET": "BEYKAVAĞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "KALECİK",
        "STREET": "BUĞRA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "KALECİK",
        "STREET": "CUMA SARAY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "KALECİK",
        "STREET": "DAĞDEMİR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "KALECİK",
        "STREET": "DEMİRTAŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "KALECİK",
        "STREET": "DEĞİRMENKAYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "KALECİK",
        "STREET": "ELMAPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "KALECİK",
        "STREET": "ESKİKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "KALECİK",
        "STREET": "EŞMEDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "KALECİK",
        "STREET": "GÖKDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "KALECİK",
        "STREET": "GÖKÇEÖREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "KALECİK",
        "STREET": "GÖLKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "KALECİK",
        "STREET": "GÜMÜŞPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "KALECİK",
        "STREET": "HACIKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "KALECİK",
        "STREET": "HALİLAĞA TABAKHANE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "KALECİK",
        "STREET": "HALİTCEVRİASLANGİL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "KALECİK",
        "STREET": "HANÇILI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "KALECİK",
        "STREET": "HASAYAZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "KALECİK",
        "STREET": "KARAHÖYÜK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "KALECİK",
        "STREET": "KARALAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "KALECİK",
        "STREET": "KARATEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "KALECİK",
        "STREET": "KARKIN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "KALECİK",
        "STREET": "KEKLİCEK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "KALECİK",
        "STREET": "KILÇAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "KALECİK",
        "STREET": "KINIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "KALECİK",
        "STREET": "KIZILKAYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "KALECİK",
        "STREET": "KOYUNBABA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "KALECİK",
        "STREET": "KUYUCAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "KALECİK",
        "STREET": "MAHMUTLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "KALECİK",
        "STREET": "SAMANLIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "KALECİK",
        "STREET": "SATILAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "KALECİK",
        "STREET": "TAVŞANCIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "KALECİK",
        "STREET": "TİLKİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "KALECİK",
        "STREET": "UYURCA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "KALECİK",
        "STREET": "YALIMKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "KALECİK",
        "STREET": "YENİDOĞAN YEŞİLYURT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "KALECİK",
        "STREET": "YENİÇÖTE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "KALECİK",
        "STREET": "YEŞİLÖZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "KALECİK",
        "STREET": "YILANLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "KALECİK",
        "STREET": "YURTYENİCE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "KALECİK",
        "STREET": "YÜZBEY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "KALECİK",
        "STREET": "ÇANDIR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "KALECİK",
        "STREET": "ÇANŞA KALE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "KALECİK",
        "STREET": "ÇAYKAYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "KALECİK",
        "STREET": "ÇİFTLİKKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "KALECİK",
        "STREET": "ŞEMSETTİN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "KALECİK",
        "STREET": "ŞEYHMAHMUT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "KEÇİÖREN",
        "STREET": "19 MAYIS MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "KEÇİÖREN",
        "STREET": "23 NİSAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "KEÇİÖREN",
        "STREET": "ADNAN MENDERES MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "KEÇİÖREN",
        "STREET": "AKTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "KEÇİÖREN",
        "STREET": "ATAPARK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "KEÇİÖREN",
        "STREET": "AYVALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "KEÇİÖREN",
        "STREET": "AŞAĞI EĞLENCE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "KEÇİÖREN",
        "STREET": "BADEMLİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "KEÇİÖREN",
        "STREET": "BASINEVLERİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "KEÇİÖREN",
        "STREET": "BAĞLARBAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "KEÇİÖREN",
        "STREET": "BAĞLUM GÜZELYURT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "KEÇİÖREN",
        "STREET": "EMRAH MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "KEÇİÖREN",
        "STREET": "ESERTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "KEÇİÖREN",
        "STREET": "ETLİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "KEÇİÖREN",
        "STREET": "GÜMÜŞDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "KEÇİÖREN",
        "STREET": "GÜZELYURT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "KEÇİÖREN",
        "STREET": "GÜÇLÜKAYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "KEÇİÖREN",
        "STREET": "HASKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "KEÇİÖREN",
        "STREET": "HİSAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "KEÇİÖREN",
        "STREET": "KAFKAS MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "KEÇİÖREN",
        "STREET": "KALABA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "KEÇİÖREN",
        "STREET": "KAMİL OCAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "KEÇİÖREN",
        "STREET": "KANUNİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "KEÇİÖREN",
        "STREET": "KARAKAYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "KEÇİÖREN",
        "STREET": "KARARGAHTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "KEÇİÖREN",
        "STREET": "KARŞIYAKA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "KEÇİÖREN",
        "STREET": "KAVACIK SUBAYEVLERİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "KEÇİÖREN",
        "STREET": "KUŞCAĞIZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "KEÇİÖREN",
        "STREET": "KÖSRELİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "KEÇİÖREN",
        "STREET": "KÖŞK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "KEÇİÖREN",
        "STREET": "OSMANGAZİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "KEÇİÖREN",
        "STREET": "OVACIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "KEÇİÖREN",
        "STREET": "PINARBAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "KEÇİÖREN",
        "STREET": "SANCAKTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "KEÇİÖREN",
        "STREET": "SARIBEYLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "KEÇİÖREN",
        "STREET": "TEPEBAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "KEÇİÖREN",
        "STREET": "UFUKTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "KEÇİÖREN",
        "STREET": "UYANIŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "KEÇİÖREN",
        "STREET": "YAKACIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "KEÇİÖREN",
        "STREET": "YAYLA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "KEÇİÖREN",
        "STREET": "YEŞİLTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "KEÇİÖREN",
        "STREET": "YEŞİLÖZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "KEÇİÖREN",
        "STREET": "YÜKSELTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "KEÇİÖREN",
        "STREET": "ÇALDIRAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "KEÇİÖREN",
        "STREET": "ÇALSEKİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "KEÇİÖREN",
        "STREET": "ÇİÇEKLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "KEÇİÖREN",
        "STREET": "İNCİRLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "KEÇİÖREN",
        "STREET": "ŞEFKAT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "KEÇİÖREN",
        "STREET": "ŞEHİT KUBİLAY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "KEÇİÖREN",
        "STREET": "ŞENLİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "KEÇİÖREN",
        "STREET": "ŞENYUVA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "KIZILCAHAMAM",
        "STREET": "AKDOĞAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "KIZILCAHAMAM",
        "STREET": "AKSAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "KIZILCAHAMAM",
        "STREET": "AKÇAKESE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "KIZILCAHAMAM",
        "STREET": "AKÇAY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "KIZILCAHAMAM",
        "STREET": "ALPAGUT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "KIZILCAHAMAM",
        "STREET": "ALİBEY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "KIZILCAHAMAM",
        "STREET": "AYVACIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "KIZILCAHAMAM",
        "STREET": "AŞAĞIADA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "KIZILCAHAMAM",
        "STREET": "AŞAĞIHÜYÜK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "KIZILCAHAMAM",
        "STREET": "AŞAĞIÇANLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "KIZILCAHAMAM",
        "STREET": "BADEMLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "KIZILCAHAMAM",
        "STREET": "BALCILAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "KIZILCAHAMAM",
        "STREET": "BAYIR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "KIZILCAHAMAM",
        "STREET": "BAĞLICA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "KIZILCAHAMAM",
        "STREET": "BAĞÖREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "KIZILCAHAMAM",
        "STREET": "BAŞAĞAÇ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "KIZILCAHAMAM",
        "STREET": "BELPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "KIZILCAHAMAM",
        "STREET": "BERÇİNYAYALAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "KIZILCAHAMAM",
        "STREET": "BERÇİNÇATAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "KIZILCAHAMAM",
        "STREET": "BEZCİKUZÖREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "KIZILCAHAMAM",
        "STREET": "BEŞKONAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "KIZILCAHAMAM",
        "STREET": "BULAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "KIZILCAHAMAM",
        "STREET": "BİNKOZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "KIZILCAHAMAM",
        "STREET": "CİĞİRLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "KIZILCAHAMAM",
        "STREET": "DEMİRCİÖREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "KIZILCAHAMAM",
        "STREET": "DERENECİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "KIZILCAHAMAM",
        "STREET": "DEĞİRMENÖNÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "KIZILCAHAMAM",
        "STREET": "DOYMUŞÖREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "KIZILCAHAMAM",
        "STREET": "DOĞANÖZÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "KIZILCAHAMAM",
        "STREET": "ESENLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "KIZILCAHAMAM",
        "STREET": "EĞERLİALÖREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "KIZILCAHAMAM",
        "STREET": "EĞERLİBAŞKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "KIZILCAHAMAM",
        "STREET": "EĞERLİDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "KIZILCAHAMAM",
        "STREET": "EĞERLİKOZÖREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "KIZILCAHAMAM",
        "STREET": "GAZİ MUSTAFA KEMALPAŞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "KIZILCAHAMAM",
        "STREET": "GEBELER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "KIZILCAHAMAM",
        "STREET": "GÖKBEL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "KIZILCAHAMAM",
        "STREET": "GÖL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "KIZILCAHAMAM",
        "STREET": "GÜMELE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "KIZILCAHAMAM",
        "STREET": "GÜNEYSARAY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "KIZILCAHAMAM",
        "STREET": "GÜVEM MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "KIZILCAHAMAM",
        "STREET": "HIDIRLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "KIZILCAHAMAM",
        "STREET": "KALEMLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "KIZILCAHAMAM",
        "STREET": "KARAAĞAÇ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "KIZILCAHAMAM",
        "STREET": "KARACAÖREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "KIZILCAHAMAM",
        "STREET": "KARŞIYAKA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "KIZILCAHAMAM",
        "STREET": "KASIMLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "KIZILCAHAMAM",
        "STREET": "KAVAKÖZÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "KIZILCAHAMAM",
        "STREET": "KIRKIRCA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "KIZILCAHAMAM",
        "STREET": "KIRKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "KIZILCAHAMAM",
        "STREET": "KIZIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "KIZILCAHAMAM",
        "STREET": "KIZILCAKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "KIZILCAHAMAM",
        "STREET": "KIZILCAÖREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "KIZILCAHAMAM",
        "STREET": "KIŞLAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "KIZILCAHAMAM",
        "STREET": "KOCALAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "KIZILCAHAMAM",
        "STREET": "KURUMCU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "KIZILCAHAMAM",
        "STREET": "KUŞCUÖREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "KIZILCAHAMAM",
        "STREET": "MAHKEMEAĞCİN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "KIZILCAHAMAM",
        "STREET": "OLUCAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "KIZILCAHAMAM",
        "STREET": "ORTAKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "KIZILCAHAMAM",
        "STREET": "OTACI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "KIZILCAHAMAM",
        "STREET": "OĞLAKCI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "KIZILCAHAMAM",
        "STREET": "PAZAR BAŞÖREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "KIZILCAHAMAM",
        "STREET": "PAZAR KINIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "KIZILCAHAMAM",
        "STREET": "PAZAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "KIZILCAHAMAM",
        "STREET": "SALIN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "KIZILCAHAMAM",
        "STREET": "SARAY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "KIZILCAHAMAM",
        "STREET": "SARAYCIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "KIZILCAHAMAM",
        "STREET": "SAZAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "KIZILCAHAMAM",
        "STREET": "SEMELER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "KIZILCAHAMAM",
        "STREET": "SEMER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "KIZILCAHAMAM",
        "STREET": "SÜLELER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "KIZILCAHAMAM",
        "STREET": "TAHTALAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "KIZILCAHAMAM",
        "STREET": "TAŞLICA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "KIZILCAHAMAM",
        "STREET": "TURNALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "KIZILCAHAMAM",
        "STREET": "UĞURLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "KIZILCAHAMAM",
        "STREET": "YAKAKAYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "KIZILCAHAMAM",
        "STREET": "YANIK ÖZBEKLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "KIZILCAHAMAM",
        "STREET": "YAĞCIHÜSEYİN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "KIZILCAHAMAM",
        "STREET": "YENİCE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "KIZILCAHAMAM",
        "STREET": "YEŞİLKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "KIZILCAHAMAM",
        "STREET": "YILDIRIMDEMİRCİLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "KIZILCAHAMAM",
        "STREET": "YILDIRIMHACILAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "KIZILCAHAMAM",
        "STREET": "YILDIRIMYAĞLICA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "KIZILCAHAMAM",
        "STREET": "YILDIRIMÇATAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "KIZILCAHAMAM",
        "STREET": "YILDIRIMÖREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "KIZILCAHAMAM",
        "STREET": "YUKARIADA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "KIZILCAHAMAM",
        "STREET": "YUKARIHÜYÜK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "KIZILCAHAMAM",
        "STREET": "YUKARIKARAÖREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "KIZILCAHAMAM",
        "STREET": "YUKARIKESE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "KIZILCAHAMAM",
        "STREET": "YUKARIÇANLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "KIZILCAHAMAM",
        "STREET": "ÇAVUŞLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "KIZILCAHAMAM",
        "STREET": "ÇELTİKÇİ AKÇAÖREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "KIZILCAHAMAM",
        "STREET": "ÇELTİKÇİ BAŞÖREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "KIZILCAHAMAM",
        "STREET": "ÇELTİKÇİ KINIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "KIZILCAHAMAM",
        "STREET": "ÇELTİKÇİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "KIZILCAHAMAM",
        "STREET": "ÇEŞTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "KIZILCAHAMAM",
        "STREET": "ÇIRPAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "KIZILCAHAMAM",
        "STREET": "ÇUKURCA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "KIZILCAHAMAM",
        "STREET": "ÇUKURÖREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "KIZILCAHAMAM",
        "STREET": "ÖRENCİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "KIZILCAHAMAM",
        "STREET": "ÜYÜCEK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "KIZILCAHAMAM",
        "STREET": "ÜÇBAŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "KIZILCAHAMAM",
        "STREET": "İNCEĞEZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "KIZILCAHAMAM",
        "STREET": "İSMETPAŞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "KIZILCAHAMAM",
        "STREET": "İYCELER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "KIZILCAHAMAM",
        "STREET": "İĞDİR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "KIZILCAHAMAM",
        "STREET": "İĞMİR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "KIZILCAHAMAM",
        "STREET": "ŞAHİNLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "MAMAK",
        "STREET": "ABİDİNPAŞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "MAMAK",
        "STREET": "AKDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "MAMAK",
        "STREET": "AKŞEMSETTİN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "MAMAK",
        "STREET": "ALTIAĞAÇ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "MAMAK",
        "STREET": "ALTINEVLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "MAMAK",
        "STREET": "ARAPLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "MAMAK",
        "STREET": "AŞIK VEYSEL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "MAMAK",
        "STREET": "BAHÇELERÜSTÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "MAMAK",
        "STREET": "BAHÇELERİÇİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "MAMAK",
        "STREET": "BALKİRAZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "MAMAK",
        "STREET": "BAYINDIR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "MAMAK",
        "STREET": "BAŞAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "MAMAK",
        "STREET": "BOSTANCIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "MAMAK",
        "STREET": "BOĞAZİÇİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "MAMAK",
        "STREET": "CENGİZHAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "MAMAK",
        "STREET": "DEMİRLİBAHÇE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "MAMAK",
        "STREET": "DERBENT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "MAMAK",
        "STREET": "DOSTLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "MAMAK",
        "STREET": "DURALİ ALIÇ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "MAMAK",
        "STREET": "DUTLUK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "MAMAK",
        "STREET": "DİRİLİŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "MAMAK",
        "STREET": "EGE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "MAMAK",
        "STREET": "EKİN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "MAMAK",
        "STREET": "FAHRİ KORUTÜRK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "MAMAK",
        "STREET": "GENERAL ZEKİ DOĞAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "MAMAK",
        "STREET": "GÖKÇEYURT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "MAMAK",
        "STREET": "GÜLVEREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "MAMAK",
        "STREET": "HARMAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "MAMAK",
        "STREET": "HÜREL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "MAMAK",
        "STREET": "HÜSEYİNGAZİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "MAMAK",
        "STREET": "KARAAĞAÇ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "MAMAK",
        "STREET": "KARTALTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "MAMAK",
        "STREET": "KARŞIYAKA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "MAMAK",
        "STREET": "KAYAŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "MAMAK",
        "STREET": "KAZIM ORBAY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "MAMAK",
        "STREET": "KIBRISKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "MAMAK",
        "STREET": "KIZILCA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "MAMAK",
        "STREET": "KUSUNLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "MAMAK",
        "STREET": "KUTLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "MAMAK",
        "STREET": "KUTLUDÜĞÜN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "MAMAK",
        "STREET": "KÖSTENCE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "MAMAK",
        "STREET": "KÜÇÜK KAYAŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "MAMAK",
        "STREET": "LALAHAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "MAMAK",
        "STREET": "MEHTAP MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "MAMAK",
        "STREET": "MUTLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "MAMAK",
        "STREET": "MİSKET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "MAMAK",
        "STREET": "ORTAKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "MAMAK",
        "STREET": "PEYAMİ SAFA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "MAMAK",
        "STREET": "SAİMEKADIN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "MAMAK",
        "STREET": "TEPECİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "MAMAK",
        "STREET": "TUZLUÇAYIR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "MAMAK",
        "STREET": "TÜRKÖZÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "MAMAK",
        "STREET": "YENİ BAYINDIR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "MAMAK",
        "STREET": "YEŞİLBAYIR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "MAMAK",
        "STREET": "YUKARI İMROHOR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "MAMAK",
        "STREET": "ZİRVEKENT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "MAMAK",
        "STREET": "ÇAĞLAYAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "MAMAK",
        "STREET": "ÇİĞİLTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "MAMAK",
        "STREET": "ÜREĞİL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "MAMAK",
        "STREET": "ŞAFAKTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "MAMAK",
        "STREET": "ŞAHAP GÜRLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "MAMAK",
        "STREET": "ŞAHİNTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "MAMAK",
        "STREET": "ŞEHİT CENGİZ TOPEL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "MAMAK",
        "STREET": "ŞİRİNTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "NALLIHAN",
        "STREET": "100.YIL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "NALLIHAN",
        "STREET": "AKDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "NALLIHAN",
        "STREET": "AKSU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "NALLIHAN",
        "STREET": "ALAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "NALLIHAN",
        "STREET": "ALPAĞUT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "NALLIHAN",
        "STREET": "ALİAĞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "NALLIHAN",
        "STREET": "ALİEFE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "NALLIHAN",
        "STREET": "ARKUTÇA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "NALLIHAN",
        "STREET": "ATATÜRK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "NALLIHAN",
        "STREET": "ATÇA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "NALLIHAN",
        "STREET": "AYDOĞMUŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "NALLIHAN",
        "STREET": "AŞAĞIBAĞDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "NALLIHAN",
        "STREET": "AŞAĞIBAĞLICA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "NALLIHAN",
        "STREET": "AŞAĞIKAVACIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "NALLIHAN",
        "STREET": "BELENALAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "NALLIHAN",
        "STREET": "BELENÖREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "NALLIHAN",
        "STREET": "BEYALAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "NALLIHAN",
        "STREET": "BEYCİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "NALLIHAN",
        "STREET": "BEYDİLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "NALLIHAN",
        "STREET": "BOZYAKA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "NALLIHAN",
        "STREET": "CENDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "NALLIHAN",
        "STREET": "DANİŞMENT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "NALLIHAN",
        "STREET": "DAVUTOĞLAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "NALLIHAN",
        "STREET": "DEMİRKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "NALLIHAN",
        "STREET": "DEREKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "NALLIHAN",
        "STREET": "DOĞANDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "NALLIHAN",
        "STREET": "DÖĞMECİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "NALLIHAN",
        "STREET": "EMREMSULTAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "NALLIHAN",
        "STREET": "EPÇELER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "NALLIHAN",
        "STREET": "ERİCEK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "NALLIHAN",
        "STREET": "EYMİR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "NALLIHAN",
        "STREET": "EĞRİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "NALLIHAN",
        "STREET": "FATİH MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "NALLIHAN",
        "STREET": "GÖKÇEÖZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "NALLIHAN",
        "STREET": "GÜZELÖZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "NALLIHAN",
        "STREET": "HACIBEY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "NALLIHAN",
        "STREET": "HACIHASANLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "NALLIHAN",
        "STREET": "HIDIRLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "NALLIHAN",
        "STREET": "KABACA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "NALLIHAN",
        "STREET": "KADIKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "NALLIHAN",
        "STREET": "KARACASU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "NALLIHAN",
        "STREET": "KARAHİSAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "NALLIHAN",
        "STREET": "KARAHİSARGÖLCÜK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "NALLIHAN",
        "STREET": "KARAHİSARKOZLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "NALLIHAN",
        "STREET": "KARAKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "NALLIHAN",
        "STREET": "KAVAKKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "NALLIHAN",
        "STREET": "KULU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "NALLIHAN",
        "STREET": "KURUCA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "NALLIHAN",
        "STREET": "KUZUCULAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "NALLIHAN",
        "STREET": "MEYİLDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "NALLIHAN",
        "STREET": "MEYİLHACILAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "NALLIHAN",
        "STREET": "NALLIDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "NALLIHAN",
        "STREET": "NALLIGÖLCÜK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "NALLIHAN",
        "STREET": "NASUHPAŞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "NALLIHAN",
        "STREET": "NEBİOĞLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "NALLIHAN",
        "STREET": "OSMANKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "NALLIHAN",
        "STREET": "OZAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "NALLIHAN",
        "STREET": "SARIKAYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "NALLIHAN",
        "STREET": "SARIYAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "NALLIHAN",
        "STREET": "SOBRAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "NALLIHAN",
        "STREET": "SOĞUKKUYU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "NALLIHAN",
        "STREET": "SUBAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "NALLIHAN",
        "STREET": "TEKKE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "NALLIHAN",
        "STREET": "TEKİRLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "NALLIHAN",
        "STREET": "TEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "NALLIHAN",
        "STREET": "ULUHAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "NALLIHAN",
        "STREET": "ULUKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "NALLIHAN",
        "STREET": "UZUNÖZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "NALLIHAN",
        "STREET": "YAKAPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "NALLIHAN",
        "STREET": "YAZI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "NALLIHAN",
        "STREET": "YENİCE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "NALLIHAN",
        "STREET": "YEŞİLYURT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "NALLIHAN",
        "STREET": "YUKARIBAĞDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "NALLIHAN",
        "STREET": "YUKARIBAĞLICA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "NALLIHAN",
        "STREET": "YUKARIKAVACIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "NALLIHAN",
        "STREET": "ÇALICAALAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "NALLIHAN",
        "STREET": "ÇAMALAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "NALLIHAN",
        "STREET": "ÇAYIRHAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "NALLIHAN",
        "STREET": "ÇULHALAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "NALLIHAN",
        "STREET": "ÇİLLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "NALLIHAN",
        "STREET": "ÇİVE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "NALLIHAN",
        "STREET": "ÖMERŞEYHLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "NALLIHAN",
        "STREET": "ÖŞÜRLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "NALLIHAN",
        "STREET": "İSLAMALAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "POLATLI",
        "STREET": "ADATOPRAKPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "POLATLI",
        "STREET": "AVDANLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "POLATLI",
        "STREET": "AVŞAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "POLATLI",
        "STREET": "BABAYAKUP MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "POLATLI",
        "STREET": "BASRI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "POLATLI",
        "STREET": "BEYCEĞİZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "POLATLI",
        "STREET": "BEYLİKKÖPRÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "POLATLI",
        "STREET": "BEŞKÖPRÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "POLATLI",
        "STREET": "CUMHURİYET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "POLATLI",
        "STREET": "ESENTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "POLATLI",
        "STREET": "ESKİKARSAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "POLATLI",
        "STREET": "ESKİKÖSELER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "POLATLI",
        "STREET": "ESKİPOLATLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "POLATLI",
        "STREET": "FATİH MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "POLATLI",
        "STREET": "GAZİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "POLATLI",
        "STREET": "GEDİKLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "POLATLI",
        "STREET": "GENÇALİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "POLATLI",
        "STREET": "GÜLPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "POLATLI",
        "STREET": "GÜLVEREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "POLATLI",
        "STREET": "GÜMÜŞYAKA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "POLATLI",
        "STREET": "GÜNDOĞAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "POLATLI",
        "STREET": "GÜREŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "POLATLI",
        "STREET": "HACIMUSA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "POLATLI",
        "STREET": "HACIMUSLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "POLATLI",
        "STREET": "HACIOSMANOĞLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "POLATLI",
        "STREET": "HACITUĞRUL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "POLATLI",
        "STREET": "HIDIRŞEYH MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "POLATLI",
        "STREET": "ILICA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "POLATLI",
        "STREET": "KABAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "POLATLI",
        "STREET": "KARAAHMET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "POLATLI",
        "STREET": "KARABENLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "POLATLI",
        "STREET": "KARACAAHMET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "POLATLI",
        "STREET": "KARAHAMZALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "POLATLI",
        "STREET": "KARAKAYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "POLATLI",
        "STREET": "KARAKUYU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "POLATLI",
        "STREET": "KARAPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "POLATLI",
        "STREET": "KARAYAVŞAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "POLATLI",
        "STREET": "KARAİLYAS MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "POLATLI",
        "STREET": "KARGALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "POLATLI",
        "STREET": "KAYABAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "POLATLI",
        "STREET": "KIRANHARMANI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "POLATLI",
        "STREET": "KIZILCAKIŞLA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "POLATLI",
        "STREET": "KOCAHACILI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "POLATLI",
        "STREET": "KURTULUŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "POLATLI",
        "STREET": "KUŞCU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "POLATLI",
        "STREET": "MACUN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "POLATLI",
        "STREET": "MEHMETAKİF MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "POLATLI",
        "STREET": "MÜSLÜM MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "POLATLI",
        "STREET": "OLUKPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "POLATLI",
        "STREET": "OĞUZLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "POLATLI",
        "STREET": "POYRAZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "POLATLI",
        "STREET": "SABANCA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "POLATLI",
        "STREET": "SAKARYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "POLATLI",
        "STREET": "SARIHALİL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "POLATLI",
        "STREET": "SARIOBA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "POLATLI",
        "STREET": "SAZLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "POLATLI",
        "STREET": "SİNANLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "POLATLI",
        "STREET": "SİNCİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "POLATLI",
        "STREET": "SİVRİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "POLATLI",
        "STREET": "TATLIKUYU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "POLATLI",
        "STREET": "TAŞPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "POLATLI",
        "STREET": "TOYDEMİR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "POLATLI",
        "STREET": "TÜFEKÇİOĞLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "POLATLI",
        "STREET": "TÜRKKARSAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "POLATLI",
        "STREET": "TÜRKTACİRİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "POLATLI",
        "STREET": "UZUNBEY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "POLATLI",
        "STREET": "YARALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "POLATLI",
        "STREET": "YASSIHÜYÜK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "POLATLI",
        "STREET": "YAĞCIOĞLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "POLATLI",
        "STREET": "YAĞMURBABA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "POLATLI",
        "STREET": "YENİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "POLATLI",
        "STREET": "YENİCE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "POLATLI",
        "STREET": "YENİDOĞAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "POLATLI",
        "STREET": "YENİKÖSELER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "POLATLI",
        "STREET": "YENİMEHMETLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "POLATLI",
        "STREET": "YEŞİLÖZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "POLATLI",
        "STREET": "YILDIZLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "POLATLI",
        "STREET": "YÜZÜKBAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "POLATLI",
        "STREET": "ZAFER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "POLATLI",
        "STREET": "ÇAMLICA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "POLATLI",
        "STREET": "ÇANAKÇI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "POLATLI",
        "STREET": "ÇEKİRDEKSİZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "POLATLI",
        "STREET": "ÇİMENCEĞİZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "POLATLI",
        "STREET": "ÖMERLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "POLATLI",
        "STREET": "ÖRDEKGÖLÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "POLATLI",
        "STREET": "ÖZYURT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "POLATLI",
        "STREET": "ÜÇPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "POLATLI",
        "STREET": "İNLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "POLATLI",
        "STREET": "İSTİKLAL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "POLATLI",
        "STREET": "İĞCİLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "POLATLI",
        "STREET": "ŞABANÖZÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "POLATLI",
        "STREET": "ŞEHİTLİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "POLATLI",
        "STREET": "ŞENTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "POLATLI",
        "STREET": "ŞEYHALİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "POLATLI",
        "STREET": "ŞIHAHMETLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "PURSAKLAR",
        "STREET": "ABADAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "PURSAKLAR",
        "STREET": "ALTINOVA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "PURSAKLAR",
        "STREET": "AYYILDIZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "PURSAKLAR",
        "STREET": "FATİH MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "PURSAKLAR",
        "STREET": "KARAKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "PURSAKLAR",
        "STREET": "KARŞIYAKA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "PURSAKLAR",
        "STREET": "KÖSRELİKKIZIĞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "PURSAKLAR",
        "STREET": "MERKEZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "PURSAKLAR",
        "STREET": "MİMAR SİNAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "PURSAKLAR",
        "STREET": "PEÇENEK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "PURSAKLAR",
        "STREET": "SARAY CUMHURİYET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "PURSAKLAR",
        "STREET": "SARAY FATİH MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "PURSAKLAR",
        "STREET": "SARAY GÜMÜŞOLUK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "PURSAKLAR",
        "STREET": "SARAY OSMANGAZİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "PURSAKLAR",
        "STREET": "SİRKELİ YEŞİLOVA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "PURSAKLAR",
        "STREET": "SİRKELİ YEŞİLYURT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "PURSAKLAR",
        "STREET": "TEVFİK İLERİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "PURSAKLAR",
        "STREET": "YENİ KARAKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "PURSAKLAR",
        "STREET": "YILDIRIM BEYAZIT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "PURSAKLAR",
        "STREET": "YUNUS EMRE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "PURSAKLAR",
        "STREET": "YUVA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "SİNCAN",
        "STREET": "29 EKİM MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "SİNCAN",
        "STREET": "ADALET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "SİNCAN",
        "STREET": "AHİ EVRAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "SİNCAN",
        "STREET": "AKÇAÖREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "SİNCAN",
        "STREET": "AKŞEMSETTİN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "SİNCAN",
        "STREET": "ALAGÖZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "SİNCAN",
        "STREET": "ALCI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "SİNCAN",
        "STREET": "ANAYURT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "SİNCAN",
        "STREET": "ANDİÇEN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "SİNCAN",
        "STREET": "ATATÜRK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "SİNCAN",
        "STREET": "BACI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "SİNCAN",
        "STREET": "BEYOBASI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "SİNCAN",
        "STREET": "CUMHURİYET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "SİNCAN",
        "STREET": "ERKEKSU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "SİNCAN",
        "STREET": "ERTUĞRULGAZİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "SİNCAN",
        "STREET": "ESENLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "SİNCAN",
        "STREET": "FATİH MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "SİNCAN",
        "STREET": "FEVZİ ÇAKMAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "SİNCAN",
        "STREET": "GAZİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "SİNCAN",
        "STREET": "GAZİ OSMANPAŞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "SİNCAN",
        "STREET": "GÖKÇEK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "SİNCAN",
        "STREET": "GİRMEÇ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "SİNCAN",
        "STREET": "HÜRRİYET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "SİNCAN",
        "STREET": "HİSARLIKAYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "SİNCAN",
        "STREET": "KESİKTAŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "SİNCAN",
        "STREET": "MALAZGİRT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "SİNCAN",
        "STREET": "MALIKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "SİNCAN",
        "STREET": "MARAŞAL ÇAKMAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "SİNCAN",
        "STREET": "MENDERES MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "SİNCAN",
        "STREET": "MEVLANA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "SİNCAN",
        "STREET": "MUSTAFA KEMAL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "SİNCAN",
        "STREET": "MÜLK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "SİNCAN",
        "STREET": "OSMANLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "SİNCAN",
        "STREET": "OSMANİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "SİNCAN",
        "STREET": "PINARBAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "SİNCAN",
        "STREET": "PLEVNE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "SİNCAN",
        "STREET": "POLATLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "SİNCAN",
        "STREET": "SARAYCIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "SİNCAN",
        "STREET": "SELÇUKLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "SİNCAN",
        "STREET": "SİNCAN 1 NOLU F TİPİ YÜKSEK GÜVENLİKLİ KAPALI CEZA",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "SİNCAN",
        "STREET": "SİNCAN 1 NOLU L TİPİ KAPALI CEZA İNFAZ KURUMU",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "SİNCAN",
        "STREET": "SİNCAN 1 NOLU YÜKSEK GÜVENLİKLİ KAPALI CEZA İNFAZ ",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "SİNCAN",
        "STREET": "SİNCAN 2 NOLU F TİPİ YÜKSEK GÜVENLİKLİ KAPALI CEZA",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "SİNCAN",
        "STREET": "SİNCAN 2 NOLU L TİPİ KAPALI CEZA İNFAZ KURUMU",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "SİNCAN",
        "STREET": "SİNCAN 2 NOLU YÜKSEK GÜVENLİKLİ KAPALI CEZA İNFAZ ",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "SİNCAN",
        "STREET": "SİNCAN 3 NOLU L TİPİ KAPALI CEZA İNFAZ KURUMU",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "SİNCAN",
        "STREET": "SİNCAN 4 NOLU L TİPİ KAPALI CEZA İNFAZ KURUMU",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "SİNCAN",
        "STREET": "SİNCAN KADIN KAPALI CEZA İNFAZ KURUMU",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "SİNCAN",
        "STREET": "SİNCAN T TİPİ KAPALI CEZA İNFAZ KURUMU",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "SİNCAN",
        "STREET": "SİNCAN ÇOCUK KAPALI CEZA İNFAZ KURUMU",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "SİNCAN",
        "STREET": "TANDOĞAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "SİNCAN",
        "STREET": "TATLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "SİNCAN",
        "STREET": "TÖREKENT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "SİNCAN",
        "STREET": "TÜRKOBASI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "SİNCAN",
        "STREET": "ULUBATLI HASAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "SİNCAN",
        "STREET": "YENİHİSAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "SİNCAN",
        "STREET": "YENİKAYI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "SİNCAN",
        "STREET": "YENİPEÇENEK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "SİNCAN",
        "STREET": "YENİÇİMŞİT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "SİNCAN",
        "STREET": "YUNUS EMRE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "SİNCAN",
        "STREET": "ÇOKÖREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "SİNCAN",
        "STREET": "ÇOĞLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "SİNCAN",
        "STREET": "ÇİÇEKTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "SİNCAN",
        "STREET": "ÜCRET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "SİNCAN",
        "STREET": "İLYAKUT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "SİNCAN",
        "STREET": "İNCİRLİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "SİNCAN",
        "STREET": "İSTASYON MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "SİNCAN",
        "STREET": "İSTİKLAL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "YENİMAHALLE",
        "STREET": "25 MART MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "YENİMAHALLE",
        "STREET": "ANADOLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "YENİMAHALLE",
        "STREET": "ATA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "YENİMAHALLE",
        "STREET": "AVCILAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "YENİMAHALLE",
        "STREET": "AŞAĞI YAHYALAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "YENİMAHALLE",
        "STREET": "BARIŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "YENİMAHALLE",
        "STREET": "BARIŞTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "YENİMAHALLE",
        "STREET": "BATI SİTESİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "YENİMAHALLE",
        "STREET": "BEŞTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "YENİMAHALLE",
        "STREET": "BURÇ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "YENİMAHALLE",
        "STREET": "DEMETEVLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "YENİMAHALLE",
        "STREET": "DEMETGÜL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "YENİMAHALLE",
        "STREET": "DEMETLALE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "YENİMAHALLE",
        "STREET": "EMNİYET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "YENİMAHALLE",
        "STREET": "ERGAZİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "YENİMAHALLE",
        "STREET": "ERGENEKON MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "YENİMAHALLE",
        "STREET": "ESENTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "YENİMAHALLE",
        "STREET": "GAYRET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "YENİMAHALLE",
        "STREET": "GAZİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "YENİMAHALLE",
        "STREET": "GÜVENTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "YENİMAHALLE",
        "STREET": "GÜZELYAKA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "YENİMAHALLE",
        "STREET": "IŞINLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "YENİMAHALLE",
        "STREET": "KALETEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "YENİMAHALLE",
        "STREET": "KARACAKAYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "YENİMAHALLE",
        "STREET": "KARDELEN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "YENİMAHALLE",
        "STREET": "KARŞIYAKA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "YENİMAHALLE",
        "STREET": "KAYALAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "YENİMAHALLE",
        "STREET": "KENTKOOP MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "YENİMAHALLE",
        "STREET": "KUZEY YILDIZI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "YENİMAHALLE",
        "STREET": "MACUN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "YENİMAHALLE",
        "STREET": "MEHMET AKİF ERSOY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "YENİMAHALLE",
        "STREET": "MEMLİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "YENİMAHALLE",
        "STREET": "OSTİM MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "YENİMAHALLE",
        "STREET": "PAMUKLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "YENİMAHALLE",
        "STREET": "RAGIP TÜZÜN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "YENİMAHALLE",
        "STREET": "SERHAT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "YENİMAHALLE",
        "STREET": "SUSUZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "YENİMAHALLE",
        "STREET": "TEPEALTI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "YENİMAHALLE",
        "STREET": "TURGUT ÖZAL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "YENİMAHALLE",
        "STREET": "UĞUR MUMCU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "YENİMAHALLE",
        "STREET": "VARLIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "YENİMAHALLE",
        "STREET": "YAKACIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "YENİMAHALLE",
        "STREET": "YENİ BATI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "YENİMAHALLE",
        "STREET": "YENİÇAĞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "YENİMAHALLE",
        "STREET": "YEŞİLEVLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "YENİMAHALLE",
        "STREET": "YUKARI YAHYALAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "YENİMAHALLE",
        "STREET": "YUNUS EMRE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "YENİMAHALLE",
        "STREET": "YUVAKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "YENİMAHALLE",
        "STREET": "ÇAMLICA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "YENİMAHALLE",
        "STREET": "ÇARŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "YENİMAHALLE",
        "STREET": "ÇİĞDEMTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "YENİMAHALLE",
        "STREET": "ÖZEVLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "YENİMAHALLE",
        "STREET": "İLKYERLEŞİM MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "YENİMAHALLE",
        "STREET": "İNÖNÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "YENİMAHALLE",
        "STREET": "İVEDİKKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ÇAMLIDERE",
        "STREET": "AHATLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ÇAMLIDERE",
        "STREET": "AKKAYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ÇAMLIDERE",
        "STREET": "ALAKOÇ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ÇAMLIDERE",
        "STREET": "ATÇA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ÇAMLIDERE",
        "STREET": "AVDAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ÇAMLIDERE",
        "STREET": "AVŞARLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ÇAMLIDERE",
        "STREET": "BARDAKÇILAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ÇAMLIDERE",
        "STREET": "BAYINDIR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ÇAMLIDERE",
        "STREET": "BEYLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ÇAMLIDERE",
        "STREET": "BEŞBEYLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ÇAMLIDERE",
        "STREET": "BUĞRALAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ÇAMLIDERE",
        "STREET": "BÖKELER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ÇAMLIDERE",
        "STREET": "DAĞKUZÖREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ÇAMLIDERE",
        "STREET": "DOYMUŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ÇAMLIDERE",
        "STREET": "DOĞANCI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ÇAMLIDERE",
        "STREET": "DOĞANLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ÇAMLIDERE",
        "STREET": "DÖRTKONAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ÇAMLIDERE",
        "STREET": "ELDELEK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ÇAMLIDERE",
        "STREET": "ELMALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ÇAMLIDERE",
        "STREET": "ELVANLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ÇAMLIDERE",
        "STREET": "ELÖREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ÇAMLIDERE",
        "STREET": "GÜMELE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ÇAMLIDERE",
        "STREET": "GÜNEY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ÇAMLIDERE",
        "STREET": "KAYABAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ÇAMLIDERE",
        "STREET": "KUYUBAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ÇAMLIDERE",
        "STREET": "KUŞÇULAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ÇAMLIDERE",
        "STREET": "KÖRLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ÇAMLIDERE",
        "STREET": "MEŞELER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ÇAMLIDERE",
        "STREET": "MUZRUPAĞACIN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ÇAMLIDERE",
        "STREET": "MÜSELLİM MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ÇAMLIDERE",
        "STREET": "ORTA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ÇAMLIDERE",
        "STREET": "OSMANSİN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ÇAMLIDERE",
        "STREET": "PELİTÇİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ÇAMLIDERE",
        "STREET": "PEÇENEK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ÇAMLIDERE",
        "STREET": "SARIKAVAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ÇAMLIDERE",
        "STREET": "TATLAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ÇAMLIDERE",
        "STREET": "YAHYALAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ÇAMLIDERE",
        "STREET": "YAHŞİHAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ÇAMLIDERE",
        "STREET": "YAYALAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ÇAMLIDERE",
        "STREET": "YEDİÖREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ÇAMLIDERE",
        "STREET": "YILANLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ÇAMLIDERE",
        "STREET": "YONCATEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ÇAMLIDERE",
        "STREET": "ÇAMKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ÇAMLIDERE",
        "STREET": "ÇUKURÖREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ÇAMLIDERE",
        "STREET": "ÖMERAĞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ÇAMLIDERE",
        "STREET": "ÖRENKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ÇAMLIDERE",
        "STREET": "ÖZMÜŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ÇAMLIDERE",
        "STREET": "İNCEÖZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ÇANKAYA",
        "STREET": "100.YIL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ÇANKAYA",
        "STREET": "50.YIL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ÇANKAYA",
        "STREET": "AHLATLIBEL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ÇANKAYA",
        "STREET": "AKARLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ÇANKAYA",
        "STREET": "AKPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ÇANKAYA",
        "STREET": "ALACAATLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ÇANKAYA",
        "STREET": "ANITTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ÇANKAYA",
        "STREET": "ARKA TOPRAKLIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ÇANKAYA",
        "STREET": "ATA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ÇANKAYA",
        "STREET": "AYDINLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ÇANKAYA",
        "STREET": "AYRANCI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ÇANKAYA",
        "STREET": "AZİZİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ÇANKAYA",
        "STREET": "AŞAĞI ÖVEÇLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ÇANKAYA",
        "STREET": "AŞAĞI İMRAHOR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ÇANKAYA",
        "STREET": "AŞIKPAŞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ÇANKAYA",
        "STREET": "BADEMLİDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ÇANKAYA",
        "STREET": "BAHÇELİEVLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ÇANKAYA",
        "STREET": "BALGAT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ÇANKAYA",
        "STREET": "BARBAROS MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ÇANKAYA",
        "STREET": "BAYRAKTAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ÇANKAYA",
        "STREET": "BAĞCILAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ÇANKAYA",
        "STREET": "BEYTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ÇANKAYA",
        "STREET": "BOZTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ÇANKAYA",
        "STREET": "BÜYÜKESAT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ÇANKAYA",
        "STREET": "BİRLİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ÇANKAYA",
        "STREET": "CEBECİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ÇANKAYA",
        "STREET": "CEVİZLİDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ÇANKAYA",
        "STREET": "CUMHURİYET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ÇANKAYA",
        "STREET": "DEVLET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ÇANKAYA",
        "STREET": "DODURGA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ÇANKAYA",
        "STREET": "DOĞUŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ÇANKAYA",
        "STREET": "DUMLUPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ÇANKAYA",
        "STREET": "DİLEKLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ÇANKAYA",
        "STREET": "EHLİBEYT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ÇANKAYA",
        "STREET": "EMEK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ÇANKAYA",
        "STREET": "ERTUĞRULGAZİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ÇANKAYA",
        "STREET": "ERZURUM MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ÇANKAYA",
        "STREET": "ESATOĞLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ÇANKAYA",
        "STREET": "ETİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ÇANKAYA",
        "STREET": "EVCİLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ÇANKAYA",
        "STREET": "FAKÜLTELER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ÇANKAYA",
        "STREET": "FİDANLIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ÇANKAYA",
        "STREET": "GAZİOSMANPAŞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ÇANKAYA",
        "STREET": "GÖKKUŞAĞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ÇANKAYA",
        "STREET": "GÖKTÜRK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ÇANKAYA",
        "STREET": "GÜVENEVLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ÇANKAYA",
        "STREET": "GÜZELTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ÇANKAYA",
        "STREET": "HARBİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ÇANKAYA",
        "STREET": "HUZUR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ÇANKAYA",
        "STREET": "HİLAL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ÇANKAYA",
        "STREET": "KARAHASANLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ÇANKAYA",
        "STREET": "KARAPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ÇANKAYA",
        "STREET": "KARATAŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ÇANKAYA",
        "STREET": "KAVAKLIDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ÇANKAYA",
        "STREET": "KAZIM ÖZALP MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ÇANKAYA",
        "STREET": "KEKLİK PINARI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ÇANKAYA",
        "STREET": "KIRKKONAKLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ÇANKAYA",
        "STREET": "KIZILAY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ÇANKAYA",
        "STREET": "KIZILIRMAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ÇANKAYA",
        "STREET": "KOCATEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ÇANKAYA",
        "STREET": "KONUTKENT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ÇANKAYA",
        "STREET": "KORKUTREİS MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ÇANKAYA",
        "STREET": "KORU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ÇANKAYA",
        "STREET": "KÖMÜRCÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ÇANKAYA",
        "STREET": "KÜLTÜR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ÇANKAYA",
        "STREET": "KÜÇÜKESAT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ÇANKAYA",
        "STREET": "MALAZGİRT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ÇANKAYA",
        "STREET": "MALTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ÇANKAYA",
        "STREET": "MEBUSEVLERİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ÇANKAYA",
        "STREET": "METİN AKKUŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ÇANKAYA",
        "STREET": "METİN OKTAY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ÇANKAYA",
        "STREET": "MEŞRUTİYET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ÇANKAYA",
        "STREET": "MUHSİN ERTUĞRUL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ÇANKAYA",
        "STREET": "MURAT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ÇANKAYA",
        "STREET": "MUSTAFA KEMAL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ÇANKAYA",
        "STREET": "MUTLUKENT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ÇANKAYA",
        "STREET": "MÜRSEL ULUÇ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ÇANKAYA",
        "STREET": "MİMAR SİNAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ÇANKAYA",
        "STREET": "NACİ ÇAKIR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ÇANKAYA",
        "STREET": "NASUH AKAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ÇANKAYA",
        "STREET": "ORAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ÇANKAYA",
        "STREET": "ORTA İMRAHOR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ÇANKAYA",
        "STREET": "OSMAN TEMİZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ÇANKAYA",
        "STREET": "OĞUZLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ÇANKAYA",
        "STREET": "PROF. DR. AHMET TANER KIŞLALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ÇANKAYA",
        "STREET": "REMZİ OĞUZ ARIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ÇANKAYA",
        "STREET": "SANCAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ÇANKAYA",
        "STREET": "SAĞLIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ÇANKAYA",
        "STREET": "SEYRANBAĞLARI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ÇANKAYA",
        "STREET": "SOKULLU MEHMET PAŞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ÇANKAYA",
        "STREET": "SÖĞÜTÖZÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ÇANKAYA",
        "STREET": "TINAZTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ÇANKAYA",
        "STREET": "TOHUMLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ÇANKAYA",
        "STREET": "TOPRAKLIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ÇANKAYA",
        "STREET": "UMUT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ÇANKAYA",
        "STREET": "YAKUPABDAL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ÇANKAYA",
        "STREET": "YAYLA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ÇANKAYA",
        "STREET": "YAŞAMKENT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ÇANKAYA",
        "STREET": "YEŞİLKENT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ÇANKAYA",
        "STREET": "YILDIZEVLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ÇANKAYA",
        "STREET": "YUKARI BAHÇELİEVLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ÇANKAYA",
        "STREET": "YUKARI DİKMEN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ÇANKAYA",
        "STREET": "YUKARI ÖVEÇLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ÇANKAYA",
        "STREET": "YÜCETEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ÇANKAYA",
        "STREET": "ZAFERTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ÇANKAYA",
        "STREET": "ÇAMLITEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ÇANKAYA",
        "STREET": "ÇANKAYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ÇANKAYA",
        "STREET": "ÇAVUŞLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ÇANKAYA",
        "STREET": "ÇAYYOLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ÇANKAYA",
        "STREET": "ÇUKURAMBAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ÇANKAYA",
        "STREET": "ÇİĞDEM MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ÇANKAYA",
        "STREET": "ÖN CEBECİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ÇANKAYA",
        "STREET": "ÖVEÇLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ÇANKAYA",
        "STREET": "ÜMİT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ÇANKAYA",
        "STREET": "ÜNİVERSİTELER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ÇANKAYA",
        "STREET": "İLERİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ÇANKAYA",
        "STREET": "İLKADIM MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ÇANKAYA",
        "STREET": "İLKBAHAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ÇANKAYA",
        "STREET": "İLKER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ÇANKAYA",
        "STREET": "İNCESU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ÇANKAYA",
        "STREET": "İŞÇİ BLOKLARI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ÇANKAYA",
        "STREET": "ŞEHİT CENGİZ KARACA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ÇANKAYA",
        "STREET": "ŞEHİT CEVDET ÖZDEMİR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ÇUBUK",
        "STREET": "AKBAYIR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ÇUBUK",
        "STREET": "AKKUZULU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ÇUBUK",
        "STREET": "ATATÜRK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ÇUBUK",
        "STREET": "AVCIOVA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ÇUBUK",
        "STREET": "AĞILCIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ÇUBUK",
        "STREET": "AŞAĞIEMİRLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ÇUBUK",
        "STREET": "AŞAĞIOBRUK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ÇUBUK",
        "STREET": "AŞAĞIÇAVUNDUR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ÇUBUK",
        "STREET": "BARBAROS MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ÇUBUK",
        "STREET": "CAMİLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ÇUBUK",
        "STREET": "CUMHURİYET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ÇUBUK",
        "STREET": "DALYASAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ÇUBUK",
        "STREET": "DAĞKALAFAT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ÇUBUK",
        "STREET": "DEDELER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ÇUBUK",
        "STREET": "DEMİRCİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ÇUBUK",
        "STREET": "DUMLUPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ÇUBUK",
        "STREET": "DURHASAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ÇUBUK",
        "STREET": "ESENBOĞA MERKEZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ÇUBUK",
        "STREET": "ESKİÇÖTE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ÇUBUK",
        "STREET": "EĞRİEKİN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ÇUBUK",
        "STREET": "FATİH MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ÇUBUK",
        "STREET": "GÖKÇEDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ÇUBUK",
        "STREET": "GÜLDARPI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ÇUBUK",
        "STREET": "GÜMÜŞYAYLA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ÇUBUK",
        "STREET": "HACILAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ÇUBUK",
        "STREET": "KAPAKLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ÇUBUK",
        "STREET": "KARAAĞAÇ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ÇUBUK",
        "STREET": "KARADANA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ÇUBUK",
        "STREET": "KARAMAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ÇUBUK",
        "STREET": "KARATAŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ÇUBUK",
        "STREET": "KARAÇAM MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ÇUBUK",
        "STREET": "KARGIN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ÇUBUK",
        "STREET": "KAVAKLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ÇUBUK",
        "STREET": "KIZILCA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ÇUBUK",
        "STREET": "KIZILÖREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ÇUBUK",
        "STREET": "KIZILÖZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ÇUBUK",
        "STREET": "KIŞLACIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ÇUBUK",
        "STREET": "KURUÇAY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ÇUBUK",
        "STREET": "KUTUÖREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ÇUBUK",
        "STREET": "KUYUMCU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ÇUBUK",
        "STREET": "KÖSRELİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ÇUBUK",
        "STREET": "KÜÇÜKALİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ÇUBUK",
        "STREET": "MAHMUTOĞLAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ÇUBUK",
        "STREET": "MELİKŞAH MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ÇUBUK",
        "STREET": "MEŞELİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ÇUBUK",
        "STREET": "MUHSİN YAZICIOĞLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ÇUBUK",
        "STREET": "MUTLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ÇUBUK",
        "STREET": "NUSRATLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ÇUBUK",
        "STREET": "OKÇULAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ÇUBUK",
        "STREET": "OVACIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ÇUBUK",
        "STREET": "OYUMİĞDE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ÇUBUK",
        "STREET": "SARAYCIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ÇUBUK",
        "STREET": "SARIKOZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ÇUBUK",
        "STREET": "SARISU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ÇUBUK",
        "STREET": "SELE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ÇUBUK",
        "STREET": "SIĞIRLIHACI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ÇUBUK",
        "STREET": "SUSUZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ÇUBUK",
        "STREET": "SÜNLÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ÇUBUK",
        "STREET": "TAHTAYAZI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ÇUBUK",
        "STREET": "TAŞPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ÇUBUK",
        "STREET": "TUĞLA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ÇUBUK",
        "STREET": "ULUAĞAÇ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ÇUBUK",
        "STREET": "YAKUPHASAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ÇUBUK",
        "STREET": "YAVUZSELİM MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ÇUBUK",
        "STREET": "YAYLAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ÇUBUK",
        "STREET": "YAZIR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ÇUBUK",
        "STREET": "YAZLICA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ÇUBUK",
        "STREET": "YENİCE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ÇUBUK",
        "STREET": "YEŞİLKENT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ÇUBUK",
        "STREET": "YILDIRIM BEYAZIT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ÇUBUK",
        "STREET": "YILDIRIMAYDOĞAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ÇUBUK",
        "STREET": "YILDIRIMELÖREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ÇUBUK",
        "STREET": "YILDIRIMEVCİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ÇUBUK",
        "STREET": "YILMAZKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ÇUBUK",
        "STREET": "YUKARI ÇAVUNDUR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ÇUBUK",
        "STREET": "YUKARIEMİRLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ÇUBUK",
        "STREET": "YUKARIOBRUK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ÇUBUK",
        "STREET": "YİĞİTLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ÇUBUK",
        "STREET": "ÇATOKCULAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ÇUBUK",
        "STREET": "ÇİTKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ÇUBUK",
        "STREET": "ÖMERCİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ÇUBUK",
        "STREET": "ÖZLÜCE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ÇUBUK",
        "STREET": "İKİPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ÇUBUK",
        "STREET": "İMAMHÜSEYİN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ŞEREFLİKOÇHİSAR",
        "STREET": "ACIKUYU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ŞEREFLİKOÇHİSAR",
        "STREET": "ACIÖZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ŞEREFLİKOÇHİSAR",
        "STREET": "AKARCA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ŞEREFLİKOÇHİSAR",
        "STREET": "AKSEKİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ŞEREFLİKOÇHİSAR",
        "STREET": "AKTAŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ŞEREFLİKOÇHİSAR",
        "STREET": "AKİN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ŞEREFLİKOÇHİSAR",
        "STREET": "ALİUŞAĞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ŞEREFLİKOÇHİSAR",
        "STREET": "BALTALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ŞEREFLİKOÇHİSAR",
        "STREET": "BAĞOBASI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ŞEREFLİKOÇHİSAR",
        "STREET": "BOĞAZİÇİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ŞEREFLİKOÇHİSAR",
        "STREET": "BÜYÜKDAMLACIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ŞEREFLİKOÇHİSAR",
        "STREET": "BÜYÜKKIŞLA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ŞEREFLİKOÇHİSAR",
        "STREET": "CINGIL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ŞEREFLİKOÇHİSAR",
        "STREET": "CUMHURİYET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ŞEREFLİKOÇHİSAR",
        "STREET": "DELİLLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ŞEREFLİKOÇHİSAR",
        "STREET": "DEVEKOVAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ŞEREFLİKOÇHİSAR",
        "STREET": "DOĞANKAYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ŞEREFLİKOÇHİSAR",
        "STREET": "EKİCİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ŞEREFLİKOÇHİSAR",
        "STREET": "ELEY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ŞEREFLİKOÇHİSAR",
        "STREET": "EMEK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ŞEREFLİKOÇHİSAR",
        "STREET": "FADILLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ŞEREFLİKOÇHİSAR",
        "STREET": "GEÇİTLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ŞEREFLİKOÇHİSAR",
        "STREET": "GÜLHÜYÜK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ŞEREFLİKOÇHİSAR",
        "STREET": "HACIBEKTAŞLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ŞEREFLİKOÇHİSAR",
        "STREET": "HACIENBİYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ŞEREFLİKOÇHİSAR",
        "STREET": "HAMZALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ŞEREFLİKOÇHİSAR",
        "STREET": "HAYDARLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ŞEREFLİKOÇHİSAR",
        "STREET": "HÜRRİYET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ŞEREFLİKOÇHİSAR",
        "STREET": "KACARLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ŞEREFLİKOÇHİSAR",
        "STREET": "KADINCIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ŞEREFLİKOÇHİSAR",
        "STREET": "KADIOBASI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ŞEREFLİKOÇHİSAR",
        "STREET": "KALE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ŞEREFLİKOÇHİSAR",
        "STREET": "KARABÜK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ŞEREFLİKOÇHİSAR",
        "STREET": "KARAMOLLAUŞAĞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ŞEREFLİKOÇHİSAR",
        "STREET": "KARANDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ŞEREFLİKOÇHİSAR",
        "STREET": "KÜÇÜKDAMLACIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ŞEREFLİKOÇHİSAR",
        "STREET": "MEHMET AKİF ERSOY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ŞEREFLİKOÇHİSAR",
        "STREET": "MUSTAFACIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ŞEREFLİKOÇHİSAR",
        "STREET": "MUSULAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ŞEREFLİKOÇHİSAR",
        "STREET": "ODUNBOĞAZI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ŞEREFLİKOÇHİSAR",
        "STREET": "PALAZOBASI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ŞEREFLİKOÇHİSAR",
        "STREET": "PARLASAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ŞEREFLİKOÇHİSAR",
        "STREET": "SADIKLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ŞEREFLİKOÇHİSAR",
        "STREET": "SANAYİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ŞEREFLİKOÇHİSAR",
        "STREET": "SARIKAYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ŞEREFLİKOÇHİSAR",
        "STREET": "SEYMENLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ŞEREFLİKOÇHİSAR",
        "STREET": "TUZLA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ŞEREFLİKOÇHİSAR",
        "STREET": "YALNIZPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ŞEREFLİKOÇHİSAR",
        "STREET": "YAZISÖĞÜT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ŞEREFLİKOÇHİSAR",
        "STREET": "YENİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ŞEREFLİKOÇHİSAR",
        "STREET": "YEŞİLOVA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ŞEREFLİKOÇHİSAR",
        "STREET": "YEŞİLYURT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ŞEREFLİKOÇHİSAR",
        "STREET": "YUSUFKUYUSU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ŞEREFLİKOÇHİSAR",
        "STREET": "ÇALÖREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ŞEREFLİKOÇHİSAR",
        "STREET": "ÇATÇAT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ŞEREFLİKOÇHİSAR",
        "STREET": "ÇAVUŞKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ŞEREFLİKOÇHİSAR",
        "STREET": "ÇAYIRÖNÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ŞEREFLİKOÇHİSAR",
        "STREET": "ÜZENGİLİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ŞEREFLİKOÇHİSAR",
        "STREET": "İSTİKLAL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ŞEREFLİKOÇHİSAR",
        "STREET": "ŞANLIKIŞLA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ŞEREFLİKOÇHİSAR",
        "STREET": "ŞEKERKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ŞEREFLİKOÇHİSAR",
        "STREET": "ŞEREFLİDAVUTLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ŞEREFLİKOÇHİSAR",
        "STREET": "ŞEYH KUYUSU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANKARA",
        "DISTRICT": "ŞEREFLİKOÇHİSAR",
        "STREET": "ŞEYHLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "AKSEKİ",
        "STREET": "AKŞAHAP MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "AKSEKİ",
        "STREET": "ALAÇEŞME MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "AKSEKİ",
        "STREET": "AŞAĞIAŞIKLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "AKSEKİ",
        "STREET": "BADEMLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "AKSEKİ",
        "STREET": "BELENALAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "AKSEKİ",
        "STREET": "BOĞAZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "AKSEKİ",
        "STREET": "BUCAKALAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "AKSEKİ",
        "STREET": "BUCAKKIŞLA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "AKSEKİ",
        "STREET": "BÜYÜKALAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "AKSEKİ",
        "STREET": "CECELER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "AKSEKİ",
        "STREET": "CEMERLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "AKSEKİ",
        "STREET": "CENDEVE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "AKSEKİ",
        "STREET": "CEVİZLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "AKSEKİ",
        "STREET": "DEMİRCİLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "AKSEKİ",
        "STREET": "DEĞİRMENLİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "AKSEKİ",
        "STREET": "DUTLUCA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "AKSEKİ",
        "STREET": "DİKMEN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "AKSEKİ",
        "STREET": "EMİRAŞIKLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "AKSEKİ",
        "STREET": "ERENYAKA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "AKSEKİ",
        "STREET": "FAKILAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "AKSEKİ",
        "STREET": "GERİŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "AKSEKİ",
        "STREET": "GÜMÜŞDAMLA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "AKSEKİ",
        "STREET": "GÜNEYKAYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "AKSEKİ",
        "STREET": "GÜNYAKA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "AKSEKİ",
        "STREET": "GÜZELSU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "AKSEKİ",
        "STREET": "GÜÇLÜKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "AKSEKİ",
        "STREET": "HACI İLYAS MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "AKSEKİ",
        "STREET": "HOCAKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "AKSEKİ",
        "STREET": "HÜSAMETTİNKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "AKSEKİ",
        "STREET": "KARAKIŞLA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "AKSEKİ",
        "STREET": "KEPEZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "AKSEKİ",
        "STREET": "KEPEZBELENİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "AKSEKİ",
        "STREET": "KUYUCAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "AKSEKİ",
        "STREET": "MAHMUTLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "AKSEKİ",
        "STREET": "MENTEŞBEY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "AKSEKİ",
        "STREET": "MİNARELİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "AKSEKİ",
        "STREET": "PINARBAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "AKSEKİ",
        "STREET": "SADIKLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "AKSEKİ",
        "STREET": "SALİHLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "AKSEKİ",
        "STREET": "SARIHACILAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "AKSEKİ",
        "STREET": "SARIHALİLLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "AKSEKİ",
        "STREET": "SUSUZŞAHAP MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "AKSEKİ",
        "STREET": "SÜLEYMANİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "AKSEKİ",
        "STREET": "SİNANHOCA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "AKSEKİ",
        "STREET": "TAŞLICA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "AKSEKİ",
        "STREET": "YARPUZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "AKSEKİ",
        "STREET": "ÇALTILIÇUKUR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "AKSEKİ",
        "STREET": "ÇANAKPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "AKSEKİ",
        "STREET": "ÇINARDİBİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "AKSEKİ",
        "STREET": "ÇUKURKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "AKSEKİ",
        "STREET": "ÇİMİKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "AKSU",
        "STREET": "ALAYLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "AKSU",
        "STREET": "ALTINTAŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "AKSU",
        "STREET": "ATATÜRK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "AKSU",
        "STREET": "BARBAROS MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "AKSU",
        "STREET": "BOZTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "AKSU",
        "STREET": "CUMHURİYET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "AKSU",
        "STREET": "CİHADİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "AKSU",
        "STREET": "DUMANLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "AKSU",
        "STREET": "FATİH MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "AKSU",
        "STREET": "FETTAHLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "AKSU",
        "STREET": "GÖKDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "AKSU",
        "STREET": "GÜLOLUK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "AKSU",
        "STREET": "GÜZELYURT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "AKSU",
        "STREET": "HACIALİLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "AKSU",
        "STREET": "KARAÇALLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "AKSU",
        "STREET": "KARAÖZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "AKSU",
        "STREET": "KAYADİBİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "AKSU",
        "STREET": "KEMERAĞZI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "AKSU",
        "STREET": "KONAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "AKSU",
        "STREET": "KUMKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "AKSU",
        "STREET": "KUNDU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "AKSU",
        "STREET": "KURŞUNLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "AKSU",
        "STREET": "MACUN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "AKSU",
        "STREET": "MANDIRLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "AKSU",
        "STREET": "MURTUNA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "AKSU",
        "STREET": "PINARLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "AKSU",
        "STREET": "SOLAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "AKSU",
        "STREET": "SOĞUCAKSU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "AKSU",
        "STREET": "TOPALLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "AKSU",
        "STREET": "YENİDUMANLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "AKSU",
        "STREET": "YEŞİLKARAMAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "AKSU",
        "STREET": "YURTPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "AKSU",
        "STREET": "ÇALKAYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "AKSU",
        "STREET": "ÇAMKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "AKSU",
        "STREET": "İHSANİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "ALANYA",
        "STREET": "AKDAM MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "ALANYA",
        "STREET": "AKÇATI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "ALANYA",
        "STREET": "ALACAMİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "ALANYA",
        "STREET": "ALANYA L TİPİ CEZAEVİ",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "ALANYA",
        "STREET": "ALARA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "ALANYA",
        "STREET": "ALİEFENDİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "ALANYA",
        "STREET": "ASMACA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "ALANYA",
        "STREET": "AVSALLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "ALANYA",
        "STREET": "BADEMAĞACI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "ALANYA",
        "STREET": "BASIRLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "ALANYA",
        "STREET": "BAYIR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "ALANYA",
        "STREET": "BAYIRKOZAĞACI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "ALANYA",
        "STREET": "BAŞKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "ALANYA",
        "STREET": "BEKTAŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "ALANYA",
        "STREET": "BELDİBİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "ALANYA",
        "STREET": "BEYRELİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "ALANYA",
        "STREET": "BIÇAKÇI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "ALANYA",
        "STREET": "BUCAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "ALANYA",
        "STREET": "BURÇAKLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "ALANYA",
        "STREET": "BÜYÜKHASBAHÇE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "ALANYA",
        "STREET": "BÜYÜKPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "ALANYA",
        "STREET": "CUMHURİYET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "ALANYA",
        "STREET": "CİKCİLLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "ALANYA",
        "STREET": "DEMİRTAŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "ALANYA",
        "STREET": "DERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "ALANYA",
        "STREET": "DEĞİRMENDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "ALANYA",
        "STREET": "DİNEK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "ALANYA",
        "STREET": "ELİKESİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "ALANYA",
        "STREET": "EMİŞBELENİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "ALANYA",
        "STREET": "FAKIRCALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "ALANYA",
        "STREET": "FIĞLA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "ALANYA",
        "STREET": "GÖZÜBÜYÜK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "ALANYA",
        "STREET": "GÖZÜKÜÇÜKLÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "ALANYA",
        "STREET": "GÜLLER PINARI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "ALANYA",
        "STREET": "GÜMÜŞGÖZE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "ALANYA",
        "STREET": "GÜMÜŞKAVAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "ALANYA",
        "STREET": "GÜNEY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "ALANYA",
        "STREET": "GÜZELBAĞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "ALANYA",
        "STREET": "HACET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "ALANYA",
        "STREET": "HACIKERİMLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "ALANYA",
        "STREET": "HACIMEHMETLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "ALANYA",
        "STREET": "HOCALAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "ALANYA",
        "STREET": "HİSARİÇİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "ALANYA",
        "STREET": "KADIPAŞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "ALANYA",
        "STREET": "KARAKOCALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "ALANYA",
        "STREET": "KARAMANLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "ALANYA",
        "STREET": "KARAPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "ALANYA",
        "STREET": "KARGICAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "ALANYA",
        "STREET": "KAYABAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "ALANYA",
        "STREET": "KESTEL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "ALANYA",
        "STREET": "KEŞEFLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "ALANYA",
        "STREET": "KIZILCAŞEHİR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "ALANYA",
        "STREET": "KIZLAR PINARI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "ALANYA",
        "STREET": "KOCAOĞLANLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "ALANYA",
        "STREET": "KONAKLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "ALANYA",
        "STREET": "KUZYAKA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "ALANYA",
        "STREET": "KÜÇÜKHASBAHÇE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "ALANYA",
        "STREET": "MAHMUTLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "ALANYA",
        "STREET": "MAHMUTSEYDİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "ALANYA",
        "STREET": "OBA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "ALANYA",
        "STREET": "OBAALACAMİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "ALANYA",
        "STREET": "OKURCALAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "ALANYA",
        "STREET": "ORHAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "ALANYA",
        "STREET": "PAYALLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "ALANYA",
        "STREET": "PAŞAKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "ALANYA",
        "STREET": "SABURLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "ALANYA",
        "STREET": "SAPADERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "ALANYA",
        "STREET": "SARAY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "ALANYA",
        "STREET": "SEKİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "ALANYA",
        "STREET": "SOĞUKPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "ALANYA",
        "STREET": "SUGÖZÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "ALANYA",
        "STREET": "SÜLEYMANLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "ALANYA",
        "STREET": "TAŞBAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "ALANYA",
        "STREET": "TEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "ALANYA",
        "STREET": "TIRILAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "ALANYA",
        "STREET": "TOPHANE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "ALANYA",
        "STREET": "TOSLAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "ALANYA",
        "STREET": "TOSMUR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "ALANYA",
        "STREET": "TÜRKLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "ALANYA",
        "STREET": "TÜRKTAŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "ALANYA",
        "STREET": "UZUNÖZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "ALANYA",
        "STREET": "UĞRAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "ALANYA",
        "STREET": "UĞURLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "ALANYA",
        "STREET": "YALÇI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "ALANYA",
        "STREET": "YASİRALİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "ALANYA",
        "STREET": "YAYLAKONAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "ALANYA",
        "STREET": "YAYLALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "ALANYA",
        "STREET": "YENİCE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "ALANYA",
        "STREET": "YENİKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "ALANYA",
        "STREET": "YEŞİLÖZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "ALANYA",
        "STREET": "ÇAKALLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "ALANYA",
        "STREET": "ÇAMLICA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "ALANYA",
        "STREET": "ÇARŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "ALANYA",
        "STREET": "ÇIPLAKLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "ALANYA",
        "STREET": "ÖTEKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "ALANYA",
        "STREET": "ÖZVADİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "ALANYA",
        "STREET": "ÜZÜMLÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "ALANYA",
        "STREET": "İMAMLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "ALANYA",
        "STREET": "İNCEKUM MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "ALANYA",
        "STREET": "İSBATLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "ALANYA",
        "STREET": "İSHAKLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "ALANYA",
        "STREET": "ŞEKERHANE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "ALANYA",
        "STREET": "ŞIHLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "DEMRE",
        "STREET": "ALAKENT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "DEMRE",
        "STREET": "BELÖREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "DEMRE",
        "STREET": "BEYMELEK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "DEMRE",
        "STREET": "BÜYÜKKUM MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "DEMRE",
        "STREET": "DAVAZLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "DEMRE",
        "STREET": "GÖKYAZI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "DEMRE",
        "STREET": "GÜRSES MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "DEMRE",
        "STREET": "KALEÜÇAĞIZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "DEMRE",
        "STREET": "KAPAKLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "DEMRE",
        "STREET": "KARABUCAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "DEMRE",
        "STREET": "KAYAALTI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "DEMRE",
        "STREET": "KÖŞKERLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "DEMRE",
        "STREET": "KÜÇÜKKUM MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "DEMRE",
        "STREET": "YAVU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "DEMRE",
        "STREET": "YAYLAKAYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "DEMRE",
        "STREET": "ÇAĞMAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "DEMRE",
        "STREET": "ÇEVRELİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "DÖŞEMEALTI",
        "STREET": "AHIRTAŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "DÖŞEMEALTI",
        "STREET": "AKKOÇ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "DÖŞEMEALTI",
        "STREET": "ALTINKALE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "DÖŞEMEALTI",
        "STREET": "AYANLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "DÖŞEMEALTI",
        "STREET": "AYDINLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "DÖŞEMEALTI",
        "STREET": "AŞAĞIOBA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "DÖŞEMEALTI",
        "STREET": "BADEMAĞACI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "DÖŞEMEALTI",
        "STREET": "BAHÇEYAKA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "DÖŞEMEALTI",
        "STREET": "BIYIKLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "DÖŞEMEALTI",
        "STREET": "CAMİLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "DÖŞEMEALTI",
        "STREET": "DAĞBELİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "DÖŞEMEALTI",
        "STREET": "DERELİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "DÖŞEMEALTI",
        "STREET": "DÜZLERÇAMI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "DÖŞEMEALTI",
        "STREET": "EKŞİLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "DÖŞEMEALTI",
        "STREET": "ILICAKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "DÖŞEMEALTI",
        "STREET": "KARAMAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "DÖŞEMEALTI",
        "STREET": "KARATAŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "DÖŞEMEALTI",
        "STREET": "KARAVELİLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "DÖŞEMEALTI",
        "STREET": "KEVŞİRLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "DÖŞEMEALTI",
        "STREET": "KOVANLIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "DÖŞEMEALTI",
        "STREET": "KÖMÜRCÜLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "DÖŞEMEALTI",
        "STREET": "KİLLİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "DÖŞEMEALTI",
        "STREET": "L TİPİ KAPALI CEZA İNFAZ KURUMU",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "DÖŞEMEALTI",
        "STREET": "NEBİLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "DÖŞEMEALTI",
        "STREET": "ORTA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "DÖŞEMEALTI",
        "STREET": "S TİPİ KAPALI CEZA İNFAZ KURUMU",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "DÖŞEMEALTI",
        "STREET": "SELİMİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "DÖŞEMEALTI",
        "STREET": "TOMALAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "DÖŞEMEALTI",
        "STREET": "Y TİPİ KAPALI CEZA İNFAZ KURUMU",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "DÖŞEMEALTI",
        "STREET": "YALINLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "DÖŞEMEALTI",
        "STREET": "YAĞCA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "DÖŞEMEALTI",
        "STREET": "YENİKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "DÖŞEMEALTI",
        "STREET": "YEŞİLBAYIR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "DÖŞEMEALTI",
        "STREET": "YÜKSEK GÜVENLİKLİ KAPALI CEZA İNFAZ KURUMU",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "DÖŞEMEALTI",
        "STREET": "ÇIPLAKLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "DÖŞEMEALTI",
        "STREET": "ÇIĞLIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "ELMALI",
        "STREET": "AFŞAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "ELMALI",
        "STREET": "AHATLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "ELMALI",
        "STREET": "AKÇAENİŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "ELMALI",
        "STREET": "AKÇAY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "ELMALI",
        "STREET": "ARMUTLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "ELMALI",
        "STREET": "BAYINDIR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "ELMALI",
        "STREET": "BAYRALAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "ELMALI",
        "STREET": "BEYLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "ELMALI",
        "STREET": "BOZHÜYÜK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "ELMALI",
        "STREET": "BÜYÜKSÖĞLE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "ELMALI",
        "STREET": "CAMİATİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "ELMALI",
        "STREET": "CAMİCEDİT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "ELMALI",
        "STREET": "DEREKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "ELMALI",
        "STREET": "DÜDEN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "ELMALI",
        "STREET": "ELMALI A3 TİPİ KAPALI CEZA İNFAZ KURUMU",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "ELMALI",
        "STREET": "ESKİHİSAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "ELMALI",
        "STREET": "EYMİR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "ELMALI",
        "STREET": "GEÇMEN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "ELMALI",
        "STREET": "GEÇİT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "ELMALI",
        "STREET": "GÖKPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "ELMALI",
        "STREET": "GÖLOVA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "ELMALI",
        "STREET": "GÖLTARLA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "ELMALI",
        "STREET": "GÜMÜŞYAKA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "ELMALI",
        "STREET": "GÜNDOĞAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "ELMALI",
        "STREET": "HACIMUSALAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "ELMALI",
        "STREET": "HACIYUSUFLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "ELMALI",
        "STREET": "KAPMESCİT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "ELMALI",
        "STREET": "KARAKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "ELMALI",
        "STREET": "KARAMIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "ELMALI",
        "STREET": "KARYAĞDI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "ELMALI",
        "STREET": "KIZILCA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "ELMALI",
        "STREET": "KIŞLA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "ELMALI",
        "STREET": "KOCAPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "ELMALI",
        "STREET": "KUZKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "ELMALI",
        "STREET": "KÜÇÜKSÖĞLE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "ELMALI",
        "STREET": "MACUN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "ELMALI",
        "STREET": "MURSAL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "ELMALI",
        "STREET": "OVACIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "ELMALI",
        "STREET": "PİRHASANLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "ELMALI",
        "STREET": "SALUR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "ELMALI",
        "STREET": "SARILAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "ELMALI",
        "STREET": "TAHTAMESCİT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "ELMALI",
        "STREET": "TAVULLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "ELMALI",
        "STREET": "TEKKE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "ELMALI",
        "STREET": "TOKLULAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "ELMALI",
        "STREET": "YAKAÇİFTLİKKÖYÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "ELMALI",
        "STREET": "YALNIZDAM MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "ELMALI",
        "STREET": "YAPRAKLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "ELMALI",
        "STREET": "YENİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "ELMALI",
        "STREET": "YILMAZLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "ELMALI",
        "STREET": "YUVA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "ELMALI",
        "STREET": "YÖRENLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "ELMALI",
        "STREET": "ZÜMRÜTOVA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "ELMALI",
        "STREET": "ÇAYBAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "ELMALI",
        "STREET": "ÇOBANİSA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "ELMALI",
        "STREET": "ÇUKURELMA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "ELMALI",
        "STREET": "ÖZDEMİR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "ELMALI",
        "STREET": "İLYAĞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "ELMALI",
        "STREET": "İMİRCİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "ELMALI",
        "STREET": "İPLİK PAZARI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "ELMALI",
        "STREET": "İSLAMLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "FİNİKE",
        "STREET": "AKÇAALAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "FİNİKE",
        "STREET": "ALACADAĞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "FİNİKE",
        "STREET": "ARİF MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "FİNİKE",
        "STREET": "ASARÖNÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "FİNİKE",
        "STREET": "BOLDAĞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "FİNİKE",
        "STREET": "DAĞBAĞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "FİNİKE",
        "STREET": "ERNEZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "FİNİKE",
        "STREET": "ESKİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "FİNİKE",
        "STREET": "GÖKBÜK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "FİNİKE",
        "STREET": "GÖKÇEYAKA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "FİNİKE",
        "STREET": "HASYURT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "FİNİKE",
        "STREET": "KALE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "FİNİKE",
        "STREET": "KARŞIYAKA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "FİNİKE",
        "STREET": "KIZILCA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "FİNİKE",
        "STREET": "KUM MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "FİNİKE",
        "STREET": "SAHİLKENT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "FİNİKE",
        "STREET": "SAKLISU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "FİNİKE",
        "STREET": "TURUNÇOVA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "FİNİKE",
        "STREET": "YALNIZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "FİNİKE",
        "STREET": "YAZIR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "FİNİKE",
        "STREET": "YENİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "FİNİKE",
        "STREET": "YEŞİLKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "FİNİKE",
        "STREET": "YEŞİLYURT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "FİNİKE",
        "STREET": "YUVALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "FİNİKE",
        "STREET": "ÇAMLIBEL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "FİNİKE",
        "STREET": "İSKELE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "GAZİPAŞA",
        "STREET": "AKOLUK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "GAZİPAŞA",
        "STREET": "AYDIN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "GAZİPAŞA",
        "STREET": "AYDINCIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "GAZİPAŞA",
        "STREET": "BAKILAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "GAZİPAŞA",
        "STREET": "BEYOBASI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "GAZİPAŞA",
        "STREET": "BEYREBUCAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "GAZİPAŞA",
        "STREET": "CUMHURİYET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "GAZİPAŞA",
        "STREET": "DOĞANCA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "GAZİPAŞA",
        "STREET": "EKMEL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "GAZİPAŞA",
        "STREET": "ESENPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "GAZİPAŞA",
        "STREET": "ESENTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "GAZİPAŞA",
        "STREET": "GAZİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "GAZİPAŞA",
        "STREET": "GÖKÇEBELEN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "GAZİPAŞA",
        "STREET": "GÖKÇESARAY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "GAZİPAŞA",
        "STREET": "GÖÇÜK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "GAZİPAŞA",
        "STREET": "GÜNEYKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "GAZİPAŞA",
        "STREET": "GÜRÇAM MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "GAZİPAŞA",
        "STREET": "HASDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "GAZİPAŞA",
        "STREET": "ILICA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "GAZİPAŞA",
        "STREET": "KAHYALAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "GAZİPAŞA",
        "STREET": "KARALAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "GAZİPAŞA",
        "STREET": "KARATEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "GAZİPAŞA",
        "STREET": "KARAÇUKUR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "GAZİPAŞA",
        "STREET": "KIRAHMETLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "GAZİPAŞA",
        "STREET": "KIZILGÜNEY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "GAZİPAŞA",
        "STREET": "KORU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "GAZİPAŞA",
        "STREET": "KORUBAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "GAZİPAŞA",
        "STREET": "KÜÇÜKLÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "GAZİPAŞA",
        "STREET": "MACAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "GAZİPAŞA",
        "STREET": "MUZKENT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "GAZİPAŞA",
        "STREET": "PAZARCI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "GAZİPAŞA",
        "STREET": "SARIAĞAÇ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "GAZİPAŞA",
        "STREET": "SUGÖZÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "GAZİPAŞA",
        "STREET": "YAKACIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "GAZİPAŞA",
        "STREET": "YENİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "GAZİPAŞA",
        "STREET": "YENİGÜNEY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "GAZİPAŞA",
        "STREET": "YENİKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "GAZİPAŞA",
        "STREET": "YEŞİLYURT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "GAZİPAŞA",
        "STREET": "ZEYTİNADA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "GAZİPAŞA",
        "STREET": "ÇAKMAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "GAZİPAŞA",
        "STREET": "ÇALIPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "GAZİPAŞA",
        "STREET": "ÇAMLICA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "GAZİPAŞA",
        "STREET": "ÇIĞLIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "GAZİPAŞA",
        "STREET": "ÇOBANLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "GAZİPAŞA",
        "STREET": "ÇÖRÜŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "GAZİPAŞA",
        "STREET": "ÇİLE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "GAZİPAŞA",
        "STREET": "ÇİMENBAĞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "GAZİPAŞA",
        "STREET": "ÖZNURTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "GAZİPAŞA",
        "STREET": "ÜÇKONAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "GAZİPAŞA",
        "STREET": "İNAL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "GAZİPAŞA",
        "STREET": "İNCEĞİZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "GAZİPAŞA",
        "STREET": "İSTİKLAL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "GAZİPAŞA",
        "STREET": "ŞAHİNLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "GÜNDOĞMUŞ",
        "STREET": "AKYAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "GÜNDOĞMUŞ",
        "STREET": "BALKAYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "GÜNDOĞMUŞ",
        "STREET": "BEDAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "GÜNDOĞMUŞ",
        "STREET": "ESKİBAĞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "GÜNDOĞMUŞ",
        "STREET": "FATİH MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "GÜNDOĞMUŞ",
        "STREET": "GÜNEYCİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "GÜNDOĞMUŞ",
        "STREET": "GÜNEYYAKA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "GÜNDOĞMUŞ",
        "STREET": "KALECİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "GÜNDOĞMUŞ",
        "STREET": "KARABUL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "GÜNDOĞMUŞ",
        "STREET": "KARADERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "GÜNDOĞMUŞ",
        "STREET": "KARAKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "GÜNDOĞMUŞ",
        "STREET": "KARAİSA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "GÜNDOĞMUŞ",
        "STREET": "KAYABÜKÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "GÜNDOĞMUŞ",
        "STREET": "KOZAĞACI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "GÜNDOĞMUŞ",
        "STREET": "KÖPRÜLÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "GÜNDOĞMUŞ",
        "STREET": "NARAĞACI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "GÜNDOĞMUŞ",
        "STREET": "ORTAKONUŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "GÜNDOĞMUŞ",
        "STREET": "ORTAKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "GÜNDOĞMUŞ",
        "STREET": "PEMBELİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "GÜNDOĞMUŞ",
        "STREET": "RASİH KAPLAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "GÜNDOĞMUŞ",
        "STREET": "SENİR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "GÜNDOĞMUŞ",
        "STREET": "SERİNYAKA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "GÜNDOĞMUŞ",
        "STREET": "UMUTLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "GÜNDOĞMUŞ",
        "STREET": "YENİKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "GÜNDOĞMUŞ",
        "STREET": "ÇALTI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "GÜNDOĞMUŞ",
        "STREET": "ÇAMLIALAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "GÜNDOĞMUŞ",
        "STREET": "ÇAYIRÖZÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "GÜNDOĞMUŞ",
        "STREET": "ÇİÇEKOLUK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "GÜNDOĞMUŞ",
        "STREET": "ÖZÜGÜR  MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "KAŞ",
        "STREET": "AHATLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "KAŞ",
        "STREET": "AKLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "KAŞ",
        "STREET": "AKÖRÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "KAŞ",
        "STREET": "ANDİFLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "KAŞ",
        "STREET": "AĞULLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "KAŞ",
        "STREET": "BAYINDIR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "KAŞ",
        "STREET": "BELDİBİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "KAŞ",
        "STREET": "BELENLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "KAŞ",
        "STREET": "BELKONAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "KAŞ",
        "STREET": "BEZİRGAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "KAŞ",
        "STREET": "BOĞAZCIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "KAŞ",
        "STREET": "CEMRE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "KAŞ",
        "STREET": "DERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "KAŞ",
        "STREET": "DOĞANTAŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "KAŞ",
        "STREET": "DİRGENLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "KAŞ",
        "STREET": "GELEMİŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "KAŞ",
        "STREET": "GÖKÇEYAZI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "KAŞ",
        "STREET": "GÖKÇEÖREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "KAŞ",
        "STREET": "GÖMBE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "KAŞ",
        "STREET": "GÜRSU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "KAŞ",
        "STREET": "HACIOĞLAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "KAŞ",
        "STREET": "KALKAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "KAŞ",
        "STREET": "KARADAĞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "KAŞ",
        "STREET": "KASABA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "KAŞ",
        "STREET": "KEMER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "KAŞ",
        "STREET": "KINIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "KAŞ",
        "STREET": "KIZILAĞAÇ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "KAŞ",
        "STREET": "ORTABAĞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "KAŞ",
        "STREET": "OVA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "KAŞ",
        "STREET": "PALAMUT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "KAŞ",
        "STREET": "PINARBAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "KAŞ",
        "STREET": "SAHİLKILINÇLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "KAŞ",
        "STREET": "SARIBELEN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "KAŞ",
        "STREET": "SARILAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "KAŞ",
        "STREET": "SÜTLEĞEN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "KAŞ",
        "STREET": "SİNNELİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "KAŞ",
        "STREET": "UĞRAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "KAŞ",
        "STREET": "YAYLAKILINÇLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "KAŞ",
        "STREET": "YAYLAPALAMUT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "KAŞ",
        "STREET": "YENİKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "KAŞ",
        "STREET": "YEŞİLBARAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "KAŞ",
        "STREET": "YEŞİLKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "KAŞ",
        "STREET": "YUVACIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "KAŞ",
        "STREET": "ÇAMLIKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "KAŞ",
        "STREET": "ÇAMLIOVA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "KAŞ",
        "STREET": "ÇATALOLUK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "KAŞ",
        "STREET": "ÇAVDIR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "KAŞ",
        "STREET": "ÇAYKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "KAŞ",
        "STREET": "ÇERLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "KAŞ",
        "STREET": "ÇEŞME MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "KAŞ",
        "STREET": "ÇUKURBAĞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "KAŞ",
        "STREET": "ÜZÜMLÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "KAŞ",
        "STREET": "İKİZCE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "KAŞ",
        "STREET": "İSLAMLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "KEMER",
        "STREET": "ARSLANBUCAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "KEMER",
        "STREET": "BELDİBİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "KEMER",
        "STREET": "BEYCİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "KEMER",
        "STREET": "GÖYNÜK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "KEMER",
        "STREET": "KUZDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "KEMER",
        "STREET": "KİRİŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "KEMER",
        "STREET": "MERKEZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "KEMER",
        "STREET": "OVACIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "KEMER",
        "STREET": "TEKİROVA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "KEMER",
        "STREET": "ULUPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "KEMER",
        "STREET": "YENİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "KEMER",
        "STREET": "ÇAMYUVA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "KEPEZ",
        "STREET": "AHATLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "KEPEZ",
        "STREET": "AKTOPRAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "KEPEZ",
        "STREET": "ALTIAYAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "KEPEZ",
        "STREET": "ALTINOVA DÜDEN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "KEPEZ",
        "STREET": "ALTINOVA ORTA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "KEPEZ",
        "STREET": "ALTINOVA SİNAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "KEPEZ",
        "STREET": "ATATÜRK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "KEPEZ",
        "STREET": "AVNİ TOLUNAY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "KEPEZ",
        "STREET": "AYANOĞLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "KEPEZ",
        "STREET": "AYDOĞMUŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "KEPEZ",
        "STREET": "BARAJ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "KEPEZ",
        "STREET": "BARIŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "KEPEZ",
        "STREET": "BAŞKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "KEPEZ",
        "STREET": "BEŞKONAKLILAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "KEPEZ",
        "STREET": "DEMİREL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "KEPEZ",
        "STREET": "DUACI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "KEPEZ",
        "STREET": "DURALİLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "KEPEZ",
        "STREET": "DÜDENBAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "KEPEZ",
        "STREET": "E TİPİ KAPALI VE AÇIK CEZAEVİ",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "KEPEZ",
        "STREET": "EMEK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "KEPEZ",
        "STREET": "ERENKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "KEPEZ",
        "STREET": "ESENTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "KEPEZ",
        "STREET": "FABRİKALAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "KEPEZ",
        "STREET": "FATİH MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "KEPEZ",
        "STREET": "FEVZİ ÇAKMAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "KEPEZ",
        "STREET": "GAZİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "KEPEZ",
        "STREET": "GAZİLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "KEPEZ",
        "STREET": "GÖKSU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "KEPEZ",
        "STREET": "GÖÇERLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "KEPEZ",
        "STREET": "GÜLVEREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "KEPEZ",
        "STREET": "GÜNDOĞDU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "KEPEZ",
        "STREET": "GÜNEŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "KEPEZ",
        "STREET": "HABİBLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "KEPEZ",
        "STREET": "HÜSNÜ KARAKAŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "KEPEZ",
        "STREET": "KANAL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "KEPEZ",
        "STREET": "KARŞIYAKA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "KEPEZ",
        "STREET": "KAZIM KARABEKİR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "KEPEZ",
        "STREET": "KEPEZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "KEPEZ",
        "STREET": "KIZILLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "KEPEZ",
        "STREET": "KUZEYYAKA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "KEPEZ",
        "STREET": "KÜLTÜR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "KEPEZ",
        "STREET": "KÜTÜKÇÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "KEPEZ",
        "STREET": "KİRİŞÇİLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "KEPEZ",
        "STREET": "MEHMET AKİF ERSOY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "KEPEZ",
        "STREET": "MENDERES MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "KEPEZ",
        "STREET": "ODABAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "KEPEZ",
        "STREET": "SANTRAL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "KEPEZ",
        "STREET": "SÜTÇÜLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "KEPEZ",
        "STREET": "TEOMANPAŞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "KEPEZ",
        "STREET": "ULUS MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "KEPEZ",
        "STREET": "VARSAK ESENTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "KEPEZ",
        "STREET": "VARSAK KARŞIYAKA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "KEPEZ",
        "STREET": "VARSAK MENDERES MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "KEPEZ",
        "STREET": "YAVUZ SELİM MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "KEPEZ",
        "STREET": "YENİ DOĞAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "KEPEZ",
        "STREET": "YENİ EMEK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "KEPEZ",
        "STREET": "YENİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "KEPEZ",
        "STREET": "YEŞİLTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "KEPEZ",
        "STREET": "YEŞİLYURT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "KEPEZ",
        "STREET": "YÜKSELİŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "KEPEZ",
        "STREET": "ZAFER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "KEPEZ",
        "STREET": "ZEYTİNLİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "KEPEZ",
        "STREET": "ÇAMLIBEL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "KEPEZ",
        "STREET": "ÇAMLICA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "KEPEZ",
        "STREET": "ÇANKAYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "KEPEZ",
        "STREET": "ÖZGÜRLÜK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "KEPEZ",
        "STREET": "ÜNSAL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "KEPEZ",
        "STREET": "ŞAFAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "KEPEZ",
        "STREET": "ŞELALE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "KONYAALTI",
        "STREET": "AKDAMLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "KONYAALTI",
        "STREET": "AKKUYU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "KONYAALTI",
        "STREET": "ALTINKUM MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "KONYAALTI",
        "STREET": "ARAPSUYU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "KONYAALTI",
        "STREET": "AYDINLIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "KONYAALTI",
        "STREET": "AŞAĞIKARAMAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "KONYAALTI",
        "STREET": "BAHTILI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "KONYAALTI",
        "STREET": "DEMİRCİLİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "KONYAALTI",
        "STREET": "DOYRAN DAĞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "KONYAALTI",
        "STREET": "DOYRAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "KONYAALTI",
        "STREET": "DOYRAN ÇAMLIBEL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "KONYAALTI",
        "STREET": "GEYİKBAYIRI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "KONYAALTI",
        "STREET": "GÖKDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "KONYAALTI",
        "STREET": "GÖKÇAM MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "KONYAALTI",
        "STREET": "GÜRSU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "KONYAALTI",
        "STREET": "HACISEKİLİLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "KONYAALTI",
        "STREET": "HURMA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "KONYAALTI",
        "STREET": "HİSARÇANDIR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "KONYAALTI",
        "STREET": "KARATEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "KONYAALTI",
        "STREET": "KIR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "KONYAALTI",
        "STREET": "KURUÇAY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "KONYAALTI",
        "STREET": "KUŞKAVAĞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "KONYAALTI",
        "STREET": "LİMAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "KONYAALTI",
        "STREET": "MOLLA YUSUF MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "KONYAALTI",
        "STREET": "PINARBAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "KONYAALTI",
        "STREET": "SARISU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "KONYAALTI",
        "STREET": "SUİÇECEK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "KONYAALTI",
        "STREET": "SİTELER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "KONYAALTI",
        "STREET": "TOROS MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "KONYAALTI",
        "STREET": "ULUÇ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "KONYAALTI",
        "STREET": "UNCALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "KONYAALTI",
        "STREET": "YARBAŞÇANDIR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "KONYAALTI",
        "STREET": "YENİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "KONYAALTI",
        "STREET": "ZÜMRÜT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "KONYAALTI",
        "STREET": "ÇAKIRLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "KONYAALTI",
        "STREET": "ÇAĞLARCA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "KONYAALTI",
        "STREET": "ÇİTDİBİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "KONYAALTI",
        "STREET": "ÖĞRETMENEVLERİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "KONYAALTI",
        "STREET": "ÜÇOLUK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "KORKUTELİ",
        "STREET": "AKYAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "KORKUTELİ",
        "STREET": "ALAADDİN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "KORKUTELİ",
        "STREET": "AVDAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "KORKUTELİ",
        "STREET": "AŞAĞIPAZAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "KORKUTELİ",
        "STREET": "BAHÇEYAKA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "KORKUTELİ",
        "STREET": "BAYAT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "KORKUTELİ",
        "STREET": "BAYATBADEMLERİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "KORKUTELİ",
        "STREET": "BEĞİŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "KORKUTELİ",
        "STREET": "BOZOVA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "KORKUTELİ",
        "STREET": "BÜYÜKKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "KORKUTELİ",
        "STREET": "DEREKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "KORKUTELİ",
        "STREET": "DURALİLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "KORKUTELİ",
        "STREET": "ESENYURT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "KORKUTELİ",
        "STREET": "GARİPCE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "KORKUTELİ",
        "STREET": "GÖÇERLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "KORKUTELİ",
        "STREET": "GÜZLE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "KORKUTELİ",
        "STREET": "KARABAYIR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "KORKUTELİ",
        "STREET": "KARAKUYU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "KORKUTELİ",
        "STREET": "KARATAŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "KORKUTELİ",
        "STREET": "KARGALIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "KORKUTELİ",
        "STREET": "KARGIN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "KORKUTELİ",
        "STREET": "KARŞIYAKA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "KORKUTELİ",
        "STREET": "KAYABAŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "KORKUTELİ",
        "STREET": "KEMERAĞZI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "KORKUTELİ",
        "STREET": "KEVZER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "KORKUTELİ",
        "STREET": "KIRKPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "KORKUTELİ",
        "STREET": "KIZILALİLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "KORKUTELİ",
        "STREET": "KIZILCADAĞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "KORKUTELİ",
        "STREET": "KOZAĞACI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "KORKUTELİ",
        "STREET": "KÖSELER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "KORKUTELİ",
        "STREET": "KÜÇÜKKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "KORKUTELİ",
        "STREET": "KÜÇÜKLÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "KORKUTELİ",
        "STREET": "KİREMİTLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "KORKUTELİ",
        "STREET": "LEYLEK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "KORKUTELİ",
        "STREET": "MAMATLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "KORKUTELİ",
        "STREET": "MANAY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "KORKUTELİ",
        "STREET": "NEBİLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "KORKUTELİ",
        "STREET": "OSMANKALFALAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "KORKUTELİ",
        "STREET": "SIMANDIR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "KORKUTELİ",
        "STREET": "SÖĞÜTCÜK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "KORKUTELİ",
        "STREET": "SÜLEKLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "KORKUTELİ",
        "STREET": "TATKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "KORKUTELİ",
        "STREET": "TAŞKESİĞİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "KORKUTELİ",
        "STREET": "UZUNOLUK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "KORKUTELİ",
        "STREET": "YAKAKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "KORKUTELİ",
        "STREET": "YAZIR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "KORKUTELİ",
        "STREET": "YELEME MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "KORKUTELİ",
        "STREET": "YELTEN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "KORKUTELİ",
        "STREET": "YENİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "KORKUTELİ",
        "STREET": "YEŞİLOBA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "KORKUTELİ",
        "STREET": "YEŞİLYAYLA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "KORKUTELİ",
        "STREET": "YUKARIKARAMAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "KORKUTELİ",
        "STREET": "ÇAYKENARI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "KORKUTELİ",
        "STREET": "ÇIVGALAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "KORKUTELİ",
        "STREET": "ÇOMAKLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "KORKUTELİ",
        "STREET": "ÇUKURCA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "KORKUTELİ",
        "STREET": "İMECİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "KORKUTELİ",
        "STREET": "İMRAHOR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "KUMLUCA",
        "STREET": "ADRASAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "KUMLUCA",
        "STREET": "ALTINYAKA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "KUMLUCA",
        "STREET": "BAĞLIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "KUMLUCA",
        "STREET": "BELEN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "KUMLUCA",
        "STREET": "BEYKONAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "KUMLUCA",
        "STREET": "BEŞİKÇİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "KUMLUCA",
        "STREET": "BÜYÜKALAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "KUMLUCA",
        "STREET": "CUMHURİYET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "KUMLUCA",
        "STREET": "DEREKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "KUMLUCA",
        "STREET": "ELLİNCİ YIL MAHALLESİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "KUMLUCA",
        "STREET": "ERENTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "KUMLUCA",
        "STREET": "ESKİCAMİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "KUMLUCA",
        "STREET": "GÖKSU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "KUMLUCA",
        "STREET": "GÖLCÜK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "KUMLUCA",
        "STREET": "GÜZÖREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "KUMLUCA",
        "STREET": "HACIVELİLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "KUMLUCA",
        "STREET": "HIZIRKAHYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "KUMLUCA",
        "STREET": "KARACAAĞAÇ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "KUMLUCA",
        "STREET": "KARACAÖREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "KUMLUCA",
        "STREET": "KARŞIYAKA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "KUMLUCA",
        "STREET": "KASAPÇAYIRI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "KUMLUCA",
        "STREET": "KAVAKKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "KUMLUCA",
        "STREET": "KUM MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "KUMLUCA",
        "STREET": "KUMLUCA KAPALI CEZAEVİ",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "KUMLUCA",
        "STREET": "KUZCA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "KUMLUCA",
        "STREET": "MAVİKENT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "KUMLUCA",
        "STREET": "MERKEZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "KUMLUCA",
        "STREET": "MEYDAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "KUMLUCA",
        "STREET": "NARENCİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "KUMLUCA",
        "STREET": "ORTAKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "KUMLUCA",
        "STREET": "SALUR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "KUMLUCA",
        "STREET": "SARICASU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "KUMLUCA",
        "STREET": "SARIKAVAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "KUMLUCA",
        "STREET": "TEMEL EĞİTİM MAHALLESİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "KUMLUCA",
        "STREET": "TOPTAŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "KUMLUCA",
        "STREET": "YAZIR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "KUMLUCA",
        "STREET": "YENİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "KUMLUCA",
        "STREET": "YENİKIŞLA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "KUMLUCA",
        "STREET": "YEŞİLKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "KUMLUCA",
        "STREET": "ÇALTI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "KUMLUCA",
        "STREET": "ÇAYİÇİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "KUMLUCA",
        "STREET": "İNCİRCİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "MANAVGAT",
        "STREET": "A. HİSAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "MANAVGAT",
        "STREET": "A. PAZARCI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "MANAVGAT",
        "STREET": "AHMETLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "MANAVGAT",
        "STREET": "AKSAZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "MANAVGAT",
        "STREET": "ALTINKAYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "MANAVGAT",
        "STREET": "AYDINEVLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "MANAVGAT",
        "STREET": "AŞAĞIIŞIKLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "MANAVGAT",
        "STREET": "BAHÇELİEVLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "MANAVGAT",
        "STREET": "BALLIBUCAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "MANAVGAT",
        "STREET": "BELENOBASI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "MANAVGAT",
        "STREET": "BEREKET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "MANAVGAT",
        "STREET": "BEYDİĞİN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "MANAVGAT",
        "STREET": "BEŞKONAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "MANAVGAT",
        "STREET": "BOZTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "MANAVGAT",
        "STREET": "BUCAKŞEYHLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "MANAVGAT",
        "STREET": "BURMAHAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "MANAVGAT",
        "STREET": "BÜKLÜCE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "MANAVGAT",
        "STREET": "CEVİZLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "MANAVGAT",
        "STREET": "DEMİRCİLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "MANAVGAT",
        "STREET": "DENİZKENT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "MANAVGAT",
        "STREET": "DENİZYAKA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "MANAVGAT",
        "STREET": "DEĞİRMENÖZÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "MANAVGAT",
        "STREET": "DOLBAZLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "MANAVGAT",
        "STREET": "DOĞANÇAM MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "MANAVGAT",
        "STREET": "DÜZAĞAÇ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "MANAVGAT",
        "STREET": "DİKMEN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "MANAVGAT",
        "STREET": "EMEK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "MANAVGAT",
        "STREET": "ESKİ HİSAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "MANAVGAT",
        "STREET": "EVRENLERYAVŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "MANAVGAT",
        "STREET": "EVRENSEKİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "MANAVGAT",
        "STREET": "GAZİLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "MANAVGAT",
        "STREET": "GEBECE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "MANAVGAT",
        "STREET": "GENÇLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "MANAVGAT",
        "STREET": "GÜLTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "MANAVGAT",
        "STREET": "GÜNDOĞDU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "MANAVGAT",
        "STREET": "GÜZELYALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "MANAVGAT",
        "STREET": "HACIALİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "MANAVGAT",
        "STREET": "HACIOBASI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "MANAVGAT",
        "STREET": "HACIİSALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "MANAVGAT",
        "STREET": "HALİTAĞALAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "MANAVGAT",
        "STREET": "HATİPLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "MANAVGAT",
        "STREET": "HOCALAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "MANAVGAT",
        "STREET": "HOCALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "MANAVGAT",
        "STREET": "ILICA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "MANAVGAT",
        "STREET": "KADILAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "MANAVGAT",
        "STREET": "KALEMLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "MANAVGAT",
        "STREET": "KARABUCAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "MANAVGAT",
        "STREET": "KARABÜK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "MANAVGAT",
        "STREET": "KARACALAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "MANAVGAT",
        "STREET": "KARAKAYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "MANAVGAT",
        "STREET": "KARAVCA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "MANAVGAT",
        "STREET": "KARAÖZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "MANAVGAT",
        "STREET": "KASAPLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "MANAVGAT",
        "STREET": "KAVAKLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "MANAVGAT",
        "STREET": "KIRKKAVAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "MANAVGAT",
        "STREET": "KISALAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "MANAVGAT",
        "STREET": "KIZILAĞAÇ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "MANAVGAT",
        "STREET": "KIZILDAĞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "MANAVGAT",
        "STREET": "KIZILOT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "MANAVGAT",
        "STREET": "MANAVGAT S TİPİ KAPALI CEZAEVİ",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "MANAVGAT",
        "STREET": "MİLLİ EGEMENLİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "MANAVGAT",
        "STREET": "MİMAR SİNAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "MANAVGAT",
        "STREET": "NAMARAS MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "MANAVGAT",
        "STREET": "ODAÖNÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "MANAVGAT",
        "STREET": "OYMAPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "MANAVGAT",
        "STREET": "PERAKENDE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "MANAVGAT",
        "STREET": "SALKIM EVLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "MANAVGAT",
        "STREET": "SALUR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "MANAVGAT",
        "STREET": "SANAYİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "MANAVGAT",
        "STREET": "SARAÇLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "MANAVGAT",
        "STREET": "SARILAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "MANAVGAT",
        "STREET": "SAĞIRİN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "MANAVGAT",
        "STREET": "SEVİNÇKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "MANAVGAT",
        "STREET": "SEYDİLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "MANAVGAT",
        "STREET": "SIRTKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "MANAVGAT",
        "STREET": "SORGUN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "MANAVGAT",
        "STREET": "SÜLEK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "MANAVGAT",
        "STREET": "SİDE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "MANAVGAT",
        "STREET": "TAŞAĞIL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "MANAVGAT",
        "STREET": "TAŞKESİĞİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "MANAVGAT",
        "STREET": "TEPEKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "MANAVGAT",
        "STREET": "TİLKİLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "MANAVGAT",
        "STREET": "ULUKAPI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "MANAVGAT",
        "STREET": "UZUNKALE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "MANAVGAT",
        "STREET": "UZUNLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "MANAVGAT",
        "STREET": "Y.HİSAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "MANAVGAT",
        "STREET": "Y.PAZARCI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "MANAVGAT",
        "STREET": "YALÇIDİBİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "MANAVGAT",
        "STREET": "YAVRUDOĞAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "MANAVGAT",
        "STREET": "YAYLA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "MANAVGAT",
        "STREET": "YAYLAALAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "MANAVGAT",
        "STREET": "YENİKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "MANAVGAT",
        "STREET": "YEŞİLBAĞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "MANAVGAT",
        "STREET": "YUKARIIŞIKLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "MANAVGAT",
        "STREET": "ÇAKIŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "MANAVGAT",
        "STREET": "ÇALTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "MANAVGAT",
        "STREET": "ÇARDAKKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "MANAVGAT",
        "STREET": "ÇAVUŞKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "MANAVGAT",
        "STREET": "ÇAYYAZI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "MANAVGAT",
        "STREET": "ÇAĞLAYAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "MANAVGAT",
        "STREET": "ÇELTİKÇİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "MANAVGAT",
        "STREET": "ÇENGER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "MANAVGAT",
        "STREET": "ÇOLAKLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "MANAVGAT",
        "STREET": "ÖRENŞEHİR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "MANAVGAT",
        "STREET": "ÖRNEK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "MANAVGAT",
        "STREET": "ŞELALE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "MANAVGAT",
        "STREET": "ŞİŞELER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "MURATPAŞA",
        "STREET": "ALTINDAĞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "MURATPAŞA",
        "STREET": "BAHÇELİEVLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "MURATPAŞA",
        "STREET": "BALBEY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "MURATPAŞA",
        "STREET": "BARBAROS MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "MURATPAŞA",
        "STREET": "BAYINDIR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "MURATPAŞA",
        "STREET": "CUMHURİYET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "MURATPAŞA",
        "STREET": "DEMİRCİKARA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "MURATPAŞA",
        "STREET": "DENİZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "MURATPAŞA",
        "STREET": "DOĞUYAKA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "MURATPAŞA",
        "STREET": "DUTLUBAHÇE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "MURATPAŞA",
        "STREET": "ELMALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "MURATPAŞA",
        "STREET": "ERMENEK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "MURATPAŞA",
        "STREET": "ETİLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "MURATPAŞA",
        "STREET": "FENER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "MURATPAŞA",
        "STREET": "GEBİZLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "MURATPAŞA",
        "STREET": "GENÇLİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "MURATPAŞA",
        "STREET": "GÜVENLİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "MURATPAŞA",
        "STREET": "GÜZELBAĞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "MURATPAŞA",
        "STREET": "GÜZELOBA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "MURATPAŞA",
        "STREET": "GÜZELOLUK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "MURATPAŞA",
        "STREET": "HAŞİMİŞCAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "MURATPAŞA",
        "STREET": "KILINÇARSLAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "MURATPAŞA",
        "STREET": "KIRCAMİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "MURATPAŞA",
        "STREET": "KIZILARIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "MURATPAŞA",
        "STREET": "KIZILSARAY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "MURATPAŞA",
        "STREET": "KIZILTOPRAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "MURATPAŞA",
        "STREET": "KIŞLA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "MURATPAŞA",
        "STREET": "KONUKSEVER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "MURATPAŞA",
        "STREET": "MEHMETÇİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "MURATPAŞA",
        "STREET": "MELTEM MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "MURATPAŞA",
        "STREET": "MEMUREVLERİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "MURATPAŞA",
        "STREET": "MEYDANKAVAĞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "MURATPAŞA",
        "STREET": "MURATPAŞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "MURATPAŞA",
        "STREET": "SEDİR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "MURATPAŞA",
        "STREET": "SELÇUK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "MURATPAŞA",
        "STREET": "SOĞUKSU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "MURATPAŞA",
        "STREET": "SİNAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "MURATPAŞA",
        "STREET": "TAHILPAZARI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "MURATPAŞA",
        "STREET": "TARIM MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "MURATPAŞA",
        "STREET": "TOPÇULAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "MURATPAŞA",
        "STREET": "TUZCULAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "MURATPAŞA",
        "STREET": "VARLIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "MURATPAŞA",
        "STREET": "YENİGÖL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "MURATPAŞA",
        "STREET": "YENİGÜN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "MURATPAŞA",
        "STREET": "YEŞİLBAHÇE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "MURATPAŞA",
        "STREET": "YEŞİLDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "MURATPAŞA",
        "STREET": "YEŞİLOVA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "MURATPAŞA",
        "STREET": "YILDIZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "MURATPAŞA",
        "STREET": "YÜKSEKALAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "MURATPAŞA",
        "STREET": "ZERDALİLİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "MURATPAŞA",
        "STREET": "ZÜMRÜTOVA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "MURATPAŞA",
        "STREET": "ÇAYBAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "MURATPAŞA",
        "STREET": "ÇAĞLAYAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "MURATPAŞA",
        "STREET": "ÜÇGEN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "MURATPAŞA",
        "STREET": "ŞİRİNYALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "SERİK",
        "STREET": "ABDURRAHMANLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "SERİK",
        "STREET": "AKBAŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "SERİK",
        "STREET": "AKÇAALAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "SERİK",
        "STREET": "AKÇAPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "SERİK",
        "STREET": "ALACAMİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "SERİK",
        "STREET": "AŞAĞIKOCAYATAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "SERİK",
        "STREET": "AŞAĞIOBA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "SERİK",
        "STREET": "AŞAĞIÇATMA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "SERİK",
        "STREET": "BELEK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "SERİK",
        "STREET": "BELKIS MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "SERİK",
        "STREET": "BELPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "SERİK",
        "STREET": "BERENDİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "SERİK",
        "STREET": "BOZDOĞAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "SERİK",
        "STREET": "BOĞAZKENT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "SERİK",
        "STREET": "BUCAKKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "SERİK",
        "STREET": "BURMAHANCI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "SERİK",
        "STREET": "BÜĞÜŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "SERİK",
        "STREET": "BİLGİNLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "SERİK",
        "STREET": "CUMALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "SERİK",
        "STREET": "CUMHURİYET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "SERİK",
        "STREET": "DEMİRCİLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "SERİK",
        "STREET": "DENİZTEPESİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "SERİK",
        "STREET": "DORUMLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "SERİK",
        "STREET": "DİKMEN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "SERİK",
        "STREET": "EMİNCELER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "SERİK",
        "STREET": "ESKİYÜRÜK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "SERİK",
        "STREET": "ETLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "SERİK",
        "STREET": "GEBİZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "SERİK",
        "STREET": "GEDİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "SERİK",
        "STREET": "GÖKÇEPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "SERİK",
        "STREET": "HACIOSMANLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "SERİK",
        "STREET": "HASDÜMEN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "SERİK",
        "STREET": "HASKIZILÖREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "SERİK",
        "STREET": "HASPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "SERİK",
        "STREET": "KADRİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "SERİK",
        "STREET": "KARADAYI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "SERİK",
        "STREET": "KARATAŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "SERİK",
        "STREET": "KARINCALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "SERİK",
        "STREET": "KAYABURNU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "SERİK",
        "STREET": "KIRBAŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "SERİK",
        "STREET": "KOZAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "SERİK",
        "STREET": "KOZAĞACI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "SERİK",
        "STREET": "KUŞLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "SERİK",
        "STREET": "KÖKEZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "SERİK",
        "STREET": "KÜRÜŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "SERİK",
        "STREET": "MERKEZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "SERİK",
        "STREET": "NEBİLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "SERİK",
        "STREET": "ORTA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "SERİK",
        "STREET": "PINARCIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "SERİK",
        "STREET": "SARIABALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "SERİK",
        "STREET": "TEKKE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "SERİK",
        "STREET": "TÖNGÜŞLÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "SERİK",
        "STREET": "YANKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "SERİK",
        "STREET": "YENİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "SERİK",
        "STREET": "YEŞİLVADİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "SERİK",
        "STREET": "YEŞİLYURT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "SERİK",
        "STREET": "YUKARIKOCAYATAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "SERİK",
        "STREET": "YUKARIÇATMA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "SERİK",
        "STREET": "YUMAKLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "SERİK",
        "STREET": "ZIRLANKAYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "SERİK",
        "STREET": "ÇAKALLIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "SERİK",
        "STREET": "ÇANAKÇI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "SERİK",
        "STREET": "ÇANDIR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "SERİK",
        "STREET": "ÇATALLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "SERİK",
        "STREET": "ÜRÜNDÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "SERİK",
        "STREET": "ŞATIRLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "İBRADI",
        "STREET": "AYDINYAYLA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "İBRADI",
        "STREET": "AŞAĞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "İBRADI",
        "STREET": "BAŞLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "İBRADI",
        "STREET": "DÜZLEN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "İBRADI",
        "STREET": "ORMANA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "İBRADI",
        "STREET": "YUKARI  MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "İBRADI",
        "STREET": "ÇUKURVİRAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "İBRADI",
        "STREET": "ÜRÜNLÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ANTALYA",
        "DISTRICT": "İBRADI",
        "STREET": "ÜZÜMDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ARDAHAN",
        "DISTRICT": "MERKEZ",
        "STREET": "AKYAKA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARDAHAN",
        "DISTRICT": "MERKEZ",
        "STREET": "ALAGÖZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARDAHAN",
        "DISTRICT": "MERKEZ",
        "STREET": "ALTAŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARDAHAN",
        "DISTRICT": "MERKEZ",
        "STREET": "ARDAHAN T TİPİ KAPALI CEZA İNFAZ KURUMU",
        "TYPE": "0"
      },
      {
        "CITY": "ARDAHAN",
        "DISTRICT": "MERKEZ",
        "STREET": "ARDIÇDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARDAHAN",
        "DISTRICT": "MERKEZ",
        "STREET": "ATATÜRK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ARDAHAN",
        "DISTRICT": "MERKEZ",
        "STREET": "AÇIKYAZI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARDAHAN",
        "DISTRICT": "MERKEZ",
        "STREET": "AĞAÇLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARDAHAN",
        "DISTRICT": "MERKEZ",
        "STREET": "AĞZIPEK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARDAHAN",
        "DISTRICT": "MERKEZ",
        "STREET": "AŞAĞIKURTOĞLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARDAHAN",
        "DISTRICT": "MERKEZ",
        "STREET": "BALIKÇILAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARDAHAN",
        "DISTRICT": "MERKEZ",
        "STREET": "BAYRAMOĞLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARDAHAN",
        "DISTRICT": "MERKEZ",
        "STREET": "BAĞDAŞEN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARDAHAN",
        "DISTRICT": "MERKEZ",
        "STREET": "BEŞİKTAŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARDAHAN",
        "DISTRICT": "MERKEZ",
        "STREET": "BÜYÜKSÜTLÜCE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARDAHAN",
        "DISTRICT": "MERKEZ",
        "STREET": "BİNBAŞAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARDAHAN",
        "DISTRICT": "MERKEZ",
        "STREET": "DAĞCI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARDAHAN",
        "DISTRICT": "MERKEZ",
        "STREET": "DAĞEVİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARDAHAN",
        "DISTRICT": "MERKEZ",
        "STREET": "DEDEGÜL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARDAHAN",
        "DISTRICT": "MERKEZ",
        "STREET": "DERİNDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARDAHAN",
        "DISTRICT": "MERKEZ",
        "STREET": "DEĞİRMENLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARDAHAN",
        "DISTRICT": "MERKEZ",
        "STREET": "EDEGÜL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARDAHAN",
        "DISTRICT": "MERKEZ",
        "STREET": "GÖLGELİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARDAHAN",
        "DISTRICT": "MERKEZ",
        "STREET": "GÜRÇAYIR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ARDAHAN",
        "DISTRICT": "MERKEZ",
        "STREET": "GÜZELYURT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARDAHAN",
        "DISTRICT": "MERKEZ",
        "STREET": "GÜZÇİMENİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARDAHAN",
        "DISTRICT": "MERKEZ",
        "STREET": "HACIALİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARDAHAN",
        "DISTRICT": "MERKEZ",
        "STREET": "HALİLEFENDİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ARDAHAN",
        "DISTRICT": "MERKEZ",
        "STREET": "HASKÖY KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARDAHAN",
        "DISTRICT": "MERKEZ",
        "STREET": "HÖÇVAN BUCAĞI HASKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "ARDAHAN",
        "DISTRICT": "MERKEZ",
        "STREET": "KAPTANPAŞA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ARDAHAN",
        "DISTRICT": "MERKEZ",
        "STREET": "KARAGÖL MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ARDAHAN",
        "DISTRICT": "MERKEZ",
        "STREET": "KARTALPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARDAHAN",
        "DISTRICT": "MERKEZ",
        "STREET": "KAZLIKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "ARDAHAN",
        "DISTRICT": "MERKEZ",
        "STREET": "KIRAÇ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARDAHAN",
        "DISTRICT": "MERKEZ",
        "STREET": "KOCAKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "ARDAHAN",
        "DISTRICT": "MERKEZ",
        "STREET": "KÖPRÜCÜK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARDAHAN",
        "DISTRICT": "MERKEZ",
        "STREET": "KÜÇÜKSÜTLÜCE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARDAHAN",
        "DISTRICT": "MERKEZ",
        "STREET": "LEHİMLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARDAHAN",
        "DISTRICT": "MERKEZ",
        "STREET": "MEŞEDİBİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARDAHAN",
        "DISTRICT": "MERKEZ",
        "STREET": "NEBİOĞLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARDAHAN",
        "DISTRICT": "MERKEZ",
        "STREET": "ORTAGEÇİT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARDAHAN",
        "DISTRICT": "MERKEZ",
        "STREET": "OTBİÇEN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARDAHAN",
        "DISTRICT": "MERKEZ",
        "STREET": "OVAPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARDAHAN",
        "DISTRICT": "MERKEZ",
        "STREET": "SAMANBEYLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARDAHAN",
        "DISTRICT": "MERKEZ",
        "STREET": "SARIYAMAÇ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARDAHAN",
        "DISTRICT": "MERKEZ",
        "STREET": "SUGÖZE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARDAHAN",
        "DISTRICT": "MERKEZ",
        "STREET": "SULAKYURT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARDAHAN",
        "DISTRICT": "MERKEZ",
        "STREET": "TAZEKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "ARDAHAN",
        "DISTRICT": "MERKEZ",
        "STREET": "TAŞLIDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARDAHAN",
        "DISTRICT": "MERKEZ",
        "STREET": "TEPELER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARDAHAN",
        "DISTRICT": "MERKEZ",
        "STREET": "TEPESUYU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARDAHAN",
        "DISTRICT": "MERKEZ",
        "STREET": "TUNÇOLUK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARDAHAN",
        "DISTRICT": "MERKEZ",
        "STREET": "UZUNOVA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARDAHAN",
        "DISTRICT": "MERKEZ",
        "STREET": "YANLIZÇAM KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARDAHAN",
        "DISTRICT": "MERKEZ",
        "STREET": "YAYLACIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARDAHAN",
        "DISTRICT": "MERKEZ",
        "STREET": "YENİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ARDAHAN",
        "DISTRICT": "MERKEZ",
        "STREET": "YOKUŞDİBİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARDAHAN",
        "DISTRICT": "MERKEZ",
        "STREET": "YUKARIKURTOĞLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARDAHAN",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇALABAŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARDAHAN",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇAMLIÇATAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARDAHAN",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇATALDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARDAHAN",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇATALKÖPRÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARDAHAN",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇAĞLAYIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARDAHAN",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇETİNSU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARDAHAN",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇEĞİLLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARDAHAN",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇİMENKAYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARDAHAN",
        "DISTRICT": "MERKEZ",
        "STREET": "ÖLÇEK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARDAHAN",
        "DISTRICT": "MERKEZ",
        "STREET": "ÖMERAĞA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARDAHAN",
        "DISTRICT": "MERKEZ",
        "STREET": "İNÖNÜ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ARDAHAN",
        "DISTRICT": "DAMAL",
        "STREET": "ATA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ARDAHAN",
        "DISTRICT": "DAMAL",
        "STREET": "BURMADERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARDAHAN",
        "DISTRICT": "DAMAL",
        "STREET": "CUMHURİYET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ARDAHAN",
        "DISTRICT": "DAMAL",
        "STREET": "DEREKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "ARDAHAN",
        "DISTRICT": "DAMAL",
        "STREET": "ESKİKILIÇ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARDAHAN",
        "DISTRICT": "DAMAL",
        "STREET": "KALENDER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ARDAHAN",
        "DISTRICT": "DAMAL",
        "STREET": "KONUK SEVER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ARDAHAN",
        "DISTRICT": "DAMAL",
        "STREET": "MUSTAFA KEMAL MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ARDAHAN",
        "DISTRICT": "DAMAL",
        "STREET": "OTAĞLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARDAHAN",
        "DISTRICT": "DAMAL",
        "STREET": "SERHAT MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ARDAHAN",
        "DISTRICT": "DAMAL",
        "STREET": "SEYİTÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARDAHAN",
        "DISTRICT": "DAMAL",
        "STREET": "TEPEKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "ARDAHAN",
        "DISTRICT": "DAMAL",
        "STREET": "ÇAYBAŞI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ARDAHAN",
        "DISTRICT": "DAMAL",
        "STREET": "İKİZDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARDAHAN",
        "DISTRICT": "DAMAL",
        "STREET": "İNÖNÜ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ARDAHAN",
        "DISTRICT": "GÖLE",
        "STREET": "ARPAŞEN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARDAHAN",
        "DISTRICT": "GÖLE",
        "STREET": "BALÇEŞME KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARDAHAN",
        "DISTRICT": "GÖLE",
        "STREET": "BELLİTEPE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARDAHAN",
        "DISTRICT": "GÖLE",
        "STREET": "BUDAKLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARDAHAN",
        "DISTRICT": "GÖLE",
        "STREET": "BÜYÜKALTINBULAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARDAHAN",
        "DISTRICT": "GÖLE",
        "STREET": "DAMLASU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARDAHAN",
        "DISTRICT": "GÖLE",
        "STREET": "DEDEKILICI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARDAHAN",
        "DISTRICT": "GÖLE",
        "STREET": "DENGELİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARDAHAN",
        "DISTRICT": "GÖLE",
        "STREET": "DEREYOLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARDAHAN",
        "DISTRICT": "GÖLE",
        "STREET": "DURUCASU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARDAHAN",
        "DISTRICT": "GÖLE",
        "STREET": "DÖLEKÇAYIR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARDAHAN",
        "DISTRICT": "GÖLE",
        "STREET": "ESENBOĞAZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARDAHAN",
        "DISTRICT": "GÖLE",
        "STREET": "ESENYAYLA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARDAHAN",
        "DISTRICT": "GÖLE",
        "STREET": "ESKİDEMİRKAPI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARDAHAN",
        "DISTRICT": "GÖLE",
        "STREET": "FEVZİ ÇAKMAK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ARDAHAN",
        "DISTRICT": "GÖLE",
        "STREET": "FİLİZLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARDAHAN",
        "DISTRICT": "GÖLE",
        "STREET": "GEDİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARDAHAN",
        "DISTRICT": "GÖLE",
        "STREET": "GÜLİSTAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARDAHAN",
        "DISTRICT": "GÖLE",
        "STREET": "GÜNORTA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARDAHAN",
        "DISTRICT": "GÖLE",
        "STREET": "HOŞDÜLBENT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARDAHAN",
        "DISTRICT": "GÖLE",
        "STREET": "KALECİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARDAHAN",
        "DISTRICT": "GÖLE",
        "STREET": "KARLIYAZI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARDAHAN",
        "DISTRICT": "GÖLE",
        "STREET": "KAYAALTI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARDAHAN",
        "DISTRICT": "GÖLE",
        "STREET": "KAZIM KARABEKİR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ARDAHAN",
        "DISTRICT": "GÖLE",
        "STREET": "KOYUNLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARDAHAN",
        "DISTRICT": "GÖLE",
        "STREET": "KUBİLAY BEY MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ARDAHAN",
        "DISTRICT": "GÖLE",
        "STREET": "KUYTUCA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARDAHAN",
        "DISTRICT": "GÖLE",
        "STREET": "KÖPRÜLÜ-DEDEŞEN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ARDAHAN",
        "DISTRICT": "GÖLE",
        "STREET": "KÖPRÜLÜ-DURANÇAM MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ARDAHAN",
        "DISTRICT": "GÖLE",
        "STREET": "KÖPRÜLÜ-KUZUPINARI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ARDAHAN",
        "DISTRICT": "GÖLE",
        "STREET": "KÖPRÜLÜ-KÖPRÜLÜ MERKEZ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ARDAHAN",
        "DISTRICT": "GÖLE",
        "STREET": "KÜÇÜKALTINBULAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARDAHAN",
        "DISTRICT": "GÖLE",
        "STREET": "MOLLAHASAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARDAHAN",
        "DISTRICT": "GÖLE",
        "STREET": "OKÇU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARDAHAN",
        "DISTRICT": "GÖLE",
        "STREET": "SALİM BEY MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ARDAHAN",
        "DISTRICT": "GÖLE",
        "STREET": "SAMANDÖKEN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARDAHAN",
        "DISTRICT": "GÖLE",
        "STREET": "SENEMOĞLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARDAHAN",
        "DISTRICT": "GÖLE",
        "STREET": "SERİNÇAYIR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARDAHAN",
        "DISTRICT": "GÖLE",
        "STREET": "SÜRÜGÜDEN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARDAHAN",
        "DISTRICT": "GÖLE",
        "STREET": "TAHTAKIRAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARDAHAN",
        "DISTRICT": "GÖLE",
        "STREET": "TELLİOĞLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARDAHAN",
        "DISTRICT": "GÖLE",
        "STREET": "TOPTAŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARDAHAN",
        "DISTRICT": "GÖLE",
        "STREET": "UĞURTAŞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARDAHAN",
        "DISTRICT": "GÖLE",
        "STREET": "YANATLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARDAHAN",
        "DISTRICT": "GÖLE",
        "STREET": "YAVUZLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARDAHAN",
        "DISTRICT": "GÖLE",
        "STREET": "YAĞMUROĞLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARDAHAN",
        "DISTRICT": "GÖLE",
        "STREET": "YELEÇLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARDAHAN",
        "DISTRICT": "GÖLE",
        "STREET": "YENİDEMİRKAPI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARDAHAN",
        "DISTRICT": "GÖLE",
        "STREET": "YENİKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "ARDAHAN",
        "DISTRICT": "GÖLE",
        "STREET": "YİĞİTKONAĞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARDAHAN",
        "DISTRICT": "GÖLE",
        "STREET": "ÇAKILDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARDAHAN",
        "DISTRICT": "GÖLE",
        "STREET": "ÇAKIRÜZÜM KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARDAHAN",
        "DISTRICT": "GÖLE",
        "STREET": "ÇALIDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARDAHAN",
        "DISTRICT": "GÖLE",
        "STREET": "ÇARDAKLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARDAHAN",
        "DISTRICT": "GÖLE",
        "STREET": "ÇAYIRBAŞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARDAHAN",
        "DISTRICT": "GÖLE",
        "STREET": "ÇOBANKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "ARDAHAN",
        "DISTRICT": "GÖLE",
        "STREET": "ÇULLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARDAHAN",
        "DISTRICT": "HANAK",
        "STREET": "ALAÇAM MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ARDAHAN",
        "DISTRICT": "HANAK",
        "STREET": "ALTINEMEK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARDAHAN",
        "DISTRICT": "HANAK",
        "STREET": "ARIKONAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARDAHAN",
        "DISTRICT": "HANAK",
        "STREET": "ATALAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ARDAHAN",
        "DISTRICT": "HANAK",
        "STREET": "AVCILAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ARDAHAN",
        "DISTRICT": "HANAK",
        "STREET": "AŞAĞIAYDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARDAHAN",
        "DISTRICT": "HANAK",
        "STREET": "BAŞTOKLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARDAHAN",
        "DISTRICT": "HANAK",
        "STREET": "BÖRK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARDAHAN",
        "DISTRICT": "HANAK",
        "STREET": "BİNBAŞAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARDAHAN",
        "DISTRICT": "HANAK",
        "STREET": "DİLEKDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARDAHAN",
        "DISTRICT": "HANAK",
        "STREET": "GEÇ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARDAHAN",
        "DISTRICT": "HANAK",
        "STREET": "GÜNEŞGÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARDAHAN",
        "DISTRICT": "HANAK",
        "STREET": "HÜRRİYET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ARDAHAN",
        "DISTRICT": "HANAK",
        "STREET": "KARAKALE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARDAHAN",
        "DISTRICT": "HANAK",
        "STREET": "KOYUNPINARI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARDAHAN",
        "DISTRICT": "HANAK",
        "STREET": "ORTAKENT MERKEZ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ARDAHAN",
        "DISTRICT": "HANAK",
        "STREET": "OĞUZYOLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARDAHAN",
        "DISTRICT": "HANAK",
        "STREET": "SAZLIÇAYIR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARDAHAN",
        "DISTRICT": "HANAK",
        "STREET": "SELAM VERDİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ARDAHAN",
        "DISTRICT": "HANAK",
        "STREET": "SERİNKUYU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARDAHAN",
        "DISTRICT": "HANAK",
        "STREET": "SEVİMLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARDAHAN",
        "DISTRICT": "HANAK",
        "STREET": "SULAKÇAYIR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARDAHAN",
        "DISTRICT": "HANAK",
        "STREET": "YAMAÇYOLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARDAHAN",
        "DISTRICT": "HANAK",
        "STREET": "YAMÇILI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARDAHAN",
        "DISTRICT": "HANAK",
        "STREET": "YUKARIAYDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARDAHAN",
        "DISTRICT": "HANAK",
        "STREET": "YÜNBÜKEN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARDAHAN",
        "DISTRICT": "HANAK",
        "STREET": "ÇATKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "ARDAHAN",
        "DISTRICT": "HANAK",
        "STREET": "ÇAVDARLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARDAHAN",
        "DISTRICT": "HANAK",
        "STREET": "ÇAYAĞZI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARDAHAN",
        "DISTRICT": "HANAK",
        "STREET": "ÇİMLİÇAYIR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARDAHAN",
        "DISTRICT": "HANAK",
        "STREET": "ÇİÇEKLİDAĞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARDAHAN",
        "DISTRICT": "HANAK",
        "STREET": "İNCEDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARDAHAN",
        "DISTRICT": "POSOF",
        "STREET": "AKBALLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARDAHAN",
        "DISTRICT": "POSOF",
        "STREET": "ALABALIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARDAHAN",
        "DISTRICT": "POSOF",
        "STREET": "ALKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "ARDAHAN",
        "DISTRICT": "POSOF",
        "STREET": "ARILI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARDAHAN",
        "DISTRICT": "POSOF",
        "STREET": "ARMUTVEREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARDAHAN",
        "DISTRICT": "POSOF",
        "STREET": "ASMAKONAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARDAHAN",
        "DISTRICT": "POSOF",
        "STREET": "AŞIKZÜLALİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARDAHAN",
        "DISTRICT": "POSOF",
        "STREET": "AŞIKÜZEYİR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARDAHAN",
        "DISTRICT": "POSOF",
        "STREET": "BALGÖZE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARDAHAN",
        "DISTRICT": "POSOF",
        "STREET": "BAYKENT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARDAHAN",
        "DISTRICT": "POSOF",
        "STREET": "BİNBAŞIEMİNBEY KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARDAHAN",
        "DISTRICT": "POSOF",
        "STREET": "DEMİRDÖVER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARDAHAN",
        "DISTRICT": "POSOF",
        "STREET": "DERİNDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARDAHAN",
        "DISTRICT": "POSOF",
        "STREET": "DOĞRULAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ARDAHAN",
        "DISTRICT": "POSOF",
        "STREET": "ERİM KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARDAHAN",
        "DISTRICT": "POSOF",
        "STREET": "GÖNÜLAÇAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARDAHAN",
        "DISTRICT": "POSOF",
        "STREET": "GÜMÜŞKAVAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARDAHAN",
        "DISTRICT": "POSOF",
        "STREET": "GÜNBATAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARDAHAN",
        "DISTRICT": "POSOF",
        "STREET": "GÜNLÜCE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARDAHAN",
        "DISTRICT": "POSOF",
        "STREET": "GÜRARMUT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARDAHAN",
        "DISTRICT": "POSOF",
        "STREET": "KALEÖNÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARDAHAN",
        "DISTRICT": "POSOF",
        "STREET": "KALKANKAYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARDAHAN",
        "DISTRICT": "POSOF",
        "STREET": "KAYINLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARDAHAN",
        "DISTRICT": "POSOF",
        "STREET": "KIRKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "ARDAHAN",
        "DISTRICT": "POSOF",
        "STREET": "KOLKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "ARDAHAN",
        "DISTRICT": "POSOF",
        "STREET": "KOPUZLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARDAHAN",
        "DISTRICT": "POSOF",
        "STREET": "KUMLUKOZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARDAHAN",
        "DISTRICT": "POSOF",
        "STREET": "KURŞUNÇAVUŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARDAHAN",
        "DISTRICT": "POSOF",
        "STREET": "MERKEZ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ARDAHAN",
        "DISTRICT": "POSOF",
        "STREET": "SARIDARI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARDAHAN",
        "DISTRICT": "POSOF",
        "STREET": "SARIÇİÇEK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARDAHAN",
        "DISTRICT": "POSOF",
        "STREET": "SAVAŞIR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARDAHAN",
        "DISTRICT": "POSOF",
        "STREET": "SÖĞÜTLÜKAYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARDAHAN",
        "DISTRICT": "POSOF",
        "STREET": "SÜNGÜLÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARDAHAN",
        "DISTRICT": "POSOF",
        "STREET": "SÜTOLUK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARDAHAN",
        "DISTRICT": "POSOF",
        "STREET": "TAŞKIRAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARDAHAN",
        "DISTRICT": "POSOF",
        "STREET": "TÜRKGÖZÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARDAHAN",
        "DISTRICT": "POSOF",
        "STREET": "UĞURCA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARDAHAN",
        "DISTRICT": "POSOF",
        "STREET": "YAYLAALTI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARDAHAN",
        "DISTRICT": "POSOF",
        "STREET": "YENİKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "ARDAHAN",
        "DISTRICT": "POSOF",
        "STREET": "YOLAĞZI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARDAHAN",
        "DISTRICT": "POSOF",
        "STREET": "YURTBAŞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARDAHAN",
        "DISTRICT": "POSOF",
        "STREET": "YURTBEKLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARDAHAN",
        "DISTRICT": "POSOF",
        "STREET": "ÇAKIRKOÇ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARDAHAN",
        "DISTRICT": "POSOF",
        "STREET": "ÇAMBELİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARDAHAN",
        "DISTRICT": "POSOF",
        "STREET": "ÇAMYAZI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARDAHAN",
        "DISTRICT": "POSOF",
        "STREET": "ÇAYIRÇİMEN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARDAHAN",
        "DISTRICT": "POSOF",
        "STREET": "ÖZBAŞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARDAHAN",
        "DISTRICT": "POSOF",
        "STREET": "İNCEDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARDAHAN",
        "DISTRICT": "ÇILDIR",
        "STREET": "AKDARI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARDAHAN",
        "DISTRICT": "ÇILDIR",
        "STREET": "AKKİRAZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARDAHAN",
        "DISTRICT": "ÇILDIR",
        "STREET": "AKÇAKALE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARDAHAN",
        "DISTRICT": "ÇILDIR",
        "STREET": "AKÇIL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARDAHAN",
        "DISTRICT": "ÇILDIR",
        "STREET": "AĞILLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARDAHAN",
        "DISTRICT": "ÇILDIR",
        "STREET": "AŞAĞICAMBAZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARDAHAN",
        "DISTRICT": "ÇILDIR",
        "STREET": "AŞIKŞENLİK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ARDAHAN",
        "DISTRICT": "ÇILDIR",
        "STREET": "BALTALI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARDAHAN",
        "DISTRICT": "ÇILDIR",
        "STREET": "BAŞKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "ARDAHAN",
        "DISTRICT": "ÇILDIR",
        "STREET": "DAMLICA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARDAHAN",
        "DISTRICT": "ÇILDIR",
        "STREET": "DEDEKORKUT MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ARDAHAN",
        "DISTRICT": "ÇILDIR",
        "STREET": "DOĞANKAYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARDAHAN",
        "DISTRICT": "ÇILDIR",
        "STREET": "DİRSEKKAYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARDAHAN",
        "DISTRICT": "ÇILDIR",
        "STREET": "ESKİBEYREHATUN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARDAHAN",
        "DISTRICT": "ÇILDIR",
        "STREET": "EŞMEPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARDAHAN",
        "DISTRICT": "ÇILDIR",
        "STREET": "GÖLBELEN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARDAHAN",
        "DISTRICT": "ÇILDIR",
        "STREET": "GÖLEBAKAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARDAHAN",
        "DISTRICT": "ÇILDIR",
        "STREET": "GÜVENOCAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARDAHAN",
        "DISTRICT": "ÇILDIR",
        "STREET": "KARAKALE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARDAHAN",
        "DISTRICT": "ÇILDIR",
        "STREET": "KAYABEYİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARDAHAN",
        "DISTRICT": "ÇILDIR",
        "STREET": "KAŞLIKAYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARDAHAN",
        "DISTRICT": "ÇILDIR",
        "STREET": "KENARBEL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARDAHAN",
        "DISTRICT": "ÇILDIR",
        "STREET": "KENARDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARDAHAN",
        "DISTRICT": "ÇILDIR",
        "STREET": "KOTANLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARDAHAN",
        "DISTRICT": "ÇILDIR",
        "STREET": "KURTKALE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARDAHAN",
        "DISTRICT": "ÇILDIR",
        "STREET": "KUZUKAYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARDAHAN",
        "DISTRICT": "ÇILDIR",
        "STREET": "MERYEMKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "ARDAHAN",
        "DISTRICT": "ÇILDIR",
        "STREET": "SABAHOLDU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARDAHAN",
        "DISTRICT": "ÇILDIR",
        "STREET": "SAYMALI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARDAHAN",
        "DISTRICT": "ÇILDIR",
        "STREET": "SAZLISU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARDAHAN",
        "DISTRICT": "ÇILDIR",
        "STREET": "SEMİHAŞAKİR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARDAHAN",
        "DISTRICT": "ÇILDIR",
        "STREET": "SUHARA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ARDAHAN",
        "DISTRICT": "ÇILDIR",
        "STREET": "TAŞDEĞİRMEN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARDAHAN",
        "DISTRICT": "ÇILDIR",
        "STREET": "UFUKTEPE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ARDAHAN",
        "DISTRICT": "ÇILDIR",
        "STREET": "YAKINSU MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ARDAHAN",
        "DISTRICT": "ÇILDIR",
        "STREET": "YENİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ARDAHAN",
        "DISTRICT": "ÇILDIR",
        "STREET": "YENİBEYREHATUN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARDAHAN",
        "DISTRICT": "ÇILDIR",
        "STREET": "YILDIRIMTEPE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ARDAHAN",
        "DISTRICT": "ÇILDIR",
        "STREET": "YUKARICAMBAZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARDAHAN",
        "DISTRICT": "ÇILDIR",
        "STREET": "ÇANKAYA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ARDAHAN",
        "DISTRICT": "ÇILDIR",
        "STREET": "ÇAY MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ARDAHAN",
        "DISTRICT": "ÇILDIR",
        "STREET": "ÖNCÜL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARDAHAN",
        "DISTRICT": "ÇILDIR",
        "STREET": "ÖVÜNDÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "ARDANUÇ",
        "STREET": "AKARSU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "ARDANUÇ",
        "STREET": "ANAÇLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "ARDANUÇ",
        "STREET": "AVCILAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "ARDANUÇ",
        "STREET": "AYDINKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "ARDANUÇ",
        "STREET": "AŞAĞIIRMAKLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "ARDANUÇ",
        "STREET": "AŞIKLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "ARDANUÇ",
        "STREET": "BALLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "ARDANUÇ",
        "STREET": "BAĞLICA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "ARDANUÇ",
        "STREET": "BERATLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "ARDANUÇ",
        "STREET": "BEREKET KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "ARDANUÇ",
        "STREET": "BOYALI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "ARDANUÇ",
        "STREET": "BULANIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "ARDANUÇ",
        "STREET": "CEVİZLİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "ARDANUÇ",
        "STREET": "EKŞİNAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "ARDANUÇ",
        "STREET": "FERHATLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "ARDANUÇ",
        "STREET": "GEÇİTLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "ARDANUÇ",
        "STREET": "GÖKÇE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "ARDANUÇ",
        "STREET": "GÜLEŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "ARDANUÇ",
        "STREET": "GÜMÜŞHANE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "ARDANUÇ",
        "STREET": "HAMURLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "ARDANUÇ",
        "STREET": "HARMANLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "ARDANUÇ",
        "STREET": "HİSARLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "ARDANUÇ",
        "STREET": "KAPIKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "ARDANUÇ",
        "STREET": "KARLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "ARDANUÇ",
        "STREET": "KAŞIKÇI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "ARDANUÇ",
        "STREET": "KIZILCIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "ARDANUÇ",
        "STREET": "KONAKLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "ARDANUÇ",
        "STREET": "KUTLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "ARDANUÇ",
        "STREET": "MERKEZ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "ARDANUÇ",
        "STREET": "MEŞEKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "ARDANUÇ",
        "STREET": "MÜEZZİNLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "ARDANUÇ",
        "STREET": "NALDÖKEN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "ARDANUÇ",
        "STREET": "OVACIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "ARDANUÇ",
        "STREET": "PEYNİRLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "ARDANUÇ",
        "STREET": "SAKARYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "ARDANUÇ",
        "STREET": "SOĞANLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "ARDANUÇ",
        "STREET": "TEPEDÜZÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "ARDANUÇ",
        "STREET": "TORBALI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "ARDANUÇ",
        "STREET": "TOSUNLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "ARDANUÇ",
        "STREET": "TÜTÜNLÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "ARDANUÇ",
        "STREET": "USTALAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "ARDANUÇ",
        "STREET": "YAYLACIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "ARDANUÇ",
        "STREET": "YOLAĞZI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "ARDANUÇ",
        "STREET": "YOLÜSTÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "ARDANUÇ",
        "STREET": "YUKARIIRMAKLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "ARDANUÇ",
        "STREET": "ZEKERİYAKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "ARDANUÇ",
        "STREET": "ÇAKILLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "ARDANUÇ",
        "STREET": "ÇIRALAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "ARDANUÇ",
        "STREET": "ÖRTÜLÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "ARDANUÇ",
        "STREET": "İNCİLLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "ARHAVİ",
        "STREET": "ARILI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "ARHAVİ",
        "STREET": "AŞAĞI HACILAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "ARHAVİ",
        "STREET": "AŞAĞIŞAHİNLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "ARHAVİ",
        "STREET": "BALIKLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "ARHAVİ",
        "STREET": "BAŞKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "ARHAVİ",
        "STREET": "BOYUNCUK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "ARHAVİ",
        "STREET": "BOĞAZİÇİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "ARHAVİ",
        "STREET": "CUMHURİYET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "ARHAVİ",
        "STREET": "DERECİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "ARHAVİ",
        "STREET": "DEREÜSTÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "ARHAVİ",
        "STREET": "DÜLGERLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "ARHAVİ",
        "STREET": "DİKYAMAÇ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "ARHAVİ",
        "STREET": "GÜNEŞLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "ARHAVİ",
        "STREET": "GÜNGÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "ARHAVİ",
        "STREET": "GÜRGENCİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "ARHAVİ",
        "STREET": "KALE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "ARHAVİ",
        "STREET": "KAVAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "ARHAVİ",
        "STREET": "KEMERKÖPRÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "ARHAVİ",
        "STREET": "KESTANEALAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "ARHAVİ",
        "STREET": "KONAKLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "ARHAVİ",
        "STREET": "KÜÇÜKKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "ARHAVİ",
        "STREET": "KİREÇLİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "ARHAVİ",
        "STREET": "MUSAZADE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "ARHAVİ",
        "STREET": "ORTACALAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "ARHAVİ",
        "STREET": "SIRTOBA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "ARHAVİ",
        "STREET": "SOĞUCAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "ARHAVİ",
        "STREET": "TEPEYURT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "ARHAVİ",
        "STREET": "ULAŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "ARHAVİ",
        "STREET": "ULUKENT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "ARHAVİ",
        "STREET": "YEMİŞLİK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "ARHAVİ",
        "STREET": "YILDIZLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "ARHAVİ",
        "STREET": "YOLGEÇEN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "ARHAVİ",
        "STREET": "YUKARI HACILAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "ARHAVİ",
        "STREET": "YUKARIŞAHİNLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "ARHAVİ",
        "STREET": "ÜÇIRMAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "ARHAVİ",
        "STREET": "ÜÇLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "ARHAVİ",
        "STREET": "ŞENKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "MERKEZ",
        "STREET": "AHLAT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "MERKEZ",
        "STREET": "ALABALIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "MERKEZ",
        "STREET": "ARTVİN KAPALI CEZA İNFAZ KURUMU",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "MERKEZ",
        "STREET": "AĞILLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "MERKEZ",
        "STREET": "AŞAĞIMADEN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "MERKEZ",
        "STREET": "BAKIRKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "MERKEZ",
        "STREET": "BALCIOĞLU MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "MERKEZ",
        "STREET": "BALLIÜZÜM KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "MERKEZ",
        "STREET": "BAĞCILAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "MERKEZ",
        "STREET": "BEŞAĞIL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "MERKEZ",
        "STREET": "DERE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "MERKEZ",
        "STREET": "DERİNKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "MERKEZ",
        "STREET": "DOKUZOĞUL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "MERKEZ",
        "STREET": "DİKMENLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "MERKEZ",
        "STREET": "ERENLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "MERKEZ",
        "STREET": "FISTIKLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "MERKEZ",
        "STREET": "HAMAMLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "MERKEZ",
        "STREET": "HIZARLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "MERKEZ",
        "STREET": "KALBURLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "MERKEZ",
        "STREET": "KÖSELER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "MERKEZ",
        "STREET": "OKUMUŞLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "MERKEZ",
        "STREET": "ORMANLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "MERKEZ",
        "STREET": "ORTA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "MERKEZ",
        "STREET": "ORTAKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "MERKEZ",
        "STREET": "ORUÇLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "MERKEZ",
        "STREET": "PIRNALLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "MERKEZ",
        "STREET": "SAKALAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "MERKEZ",
        "STREET": "SALKIMLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "MERKEZ",
        "STREET": "SARIBUDAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "MERKEZ",
        "STREET": "SEYİTLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "MERKEZ",
        "STREET": "SÜMBÜLLÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "MERKEZ",
        "STREET": "TAŞLICA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "MERKEZ",
        "STREET": "TÜTÜNCÜLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "MERKEZ",
        "STREET": "VARLIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "MERKEZ",
        "STREET": "VEZİRKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "MERKEZ",
        "STREET": "YANIKLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "MERKEZ",
        "STREET": "YENİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "MERKEZ",
        "STREET": "YUKARIMADEN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "MERKEZ",
        "STREET": "ZEYTİNLİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇAMLIK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇARŞI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇAYAĞZI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇİMENLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "MERKEZ",
        "STREET": "ŞEHİTLİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "BORÇKA",
        "STREET": "ADAGÜL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "BORÇKA",
        "STREET": "AKPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "BORÇKA",
        "STREET": "AKSU MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "BORÇKA",
        "STREET": "ALACA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "BORÇKA",
        "STREET": "AMBARLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "BORÇKA",
        "STREET": "ARALIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "BORÇKA",
        "STREET": "ARKAKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "BORÇKA",
        "STREET": "ATANOĞLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "BORÇKA",
        "STREET": "AVCILAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "BORÇKA",
        "STREET": "BALCI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "BORÇKA",
        "STREET": "BOĞAZKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "BORÇKA",
        "STREET": "CAMİLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "BORÇKA",
        "STREET": "CİVAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "BORÇKA",
        "STREET": "DEMİRCİLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "BORÇKA",
        "STREET": "DÜZENLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "BORÇKA",
        "STREET": "DÜZKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "BORÇKA",
        "STREET": "EFELER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "BORÇKA",
        "STREET": "FINDIKLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "BORÇKA",
        "STREET": "GÜNDOĞDU MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "BORÇKA",
        "STREET": "GÜNEŞLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "BORÇKA",
        "STREET": "GÜREŞEN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "BORÇKA",
        "STREET": "GÜZELYURT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "BORÇKA",
        "STREET": "KALE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "BORÇKA",
        "STREET": "KARŞIKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "BORÇKA",
        "STREET": "KAYADİBİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "BORÇKA",
        "STREET": "KAYALAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "BORÇKA",
        "STREET": "KAYNARCA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "BORÇKA",
        "STREET": "KÜÇÜK KÖY MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "BORÇKA",
        "STREET": "MARALKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "BORÇKA",
        "STREET": "MERKEZ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "BORÇKA",
        "STREET": "MURATLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "BORÇKA",
        "STREET": "TARAKLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "BORÇKA",
        "STREET": "UĞURKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "BORÇKA",
        "STREET": "YENİYOL MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "BORÇKA",
        "STREET": "YEŞİLKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "BORÇKA",
        "STREET": "ZEDOBAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "BORÇKA",
        "STREET": "ZORLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "BORÇKA",
        "STREET": "ÇAVUŞLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "BORÇKA",
        "STREET": "ÇAYLIKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "BORÇKA",
        "STREET": "ÇİFTEKÖPRÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "BORÇKA",
        "STREET": "ÖRÜCÜLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "BORÇKA",
        "STREET": "İBRİKLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "BORÇKA",
        "STREET": "ŞEREFİYE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "HOPA",
        "STREET": "BALIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "HOPA",
        "STREET": "BAŞKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "HOPA",
        "STREET": "BAŞOBA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "HOPA",
        "STREET": "BUCAK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "HOPA",
        "STREET": "CUMHURİYET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "HOPA",
        "STREET": "ESENKIYI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "HOPA",
        "STREET": "EŞMEKAYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "HOPA",
        "STREET": "GÜNEŞLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "HOPA",
        "STREET": "GÜVERCİNLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "HOPA",
        "STREET": "HENDEK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "HOPA",
        "STREET": "KOYUNCULAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "HOPA",
        "STREET": "MERKEZ KULEDİBİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "HOPA",
        "STREET": "ORTAHOPA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "HOPA",
        "STREET": "PINARLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "HOPA",
        "STREET": "SUBAŞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "HOPA",
        "STREET": "SUGÖREN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "HOPA",
        "STREET": "SUNDURA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "HOPA",
        "STREET": "YEŞİLKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "HOPA",
        "STREET": "YOLDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "HOPA",
        "STREET": "YUKARI KULEDİBİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "HOPA",
        "STREET": "ÇAMLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "HOPA",
        "STREET": "ÇAVUŞLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "HOPA",
        "STREET": "ÇİMENLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "KEMALPAŞA",
        "STREET": "AKDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "KEMALPAŞA",
        "STREET": "CUMHURİYET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "KEMALPAŞA",
        "STREET": "DEREİÇİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "KEMALPAŞA",
        "STREET": "GÜMÜŞDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "KEMALPAŞA",
        "STREET": "KARAOSMANİYE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "KEMALPAŞA",
        "STREET": "KAYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "KEMALPAŞA",
        "STREET": "KAZİMİYE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "KEMALPAŞA",
        "STREET": "KÖPRÜCÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "KEMALPAŞA",
        "STREET": "LİMAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "KEMALPAŞA",
        "STREET": "OSMANİYE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "KEMALPAŞA",
        "STREET": "SARP KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "KEMALPAŞA",
        "STREET": "SELİMİYE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "KEMALPAŞA",
        "STREET": "UZUNYALI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "KEMALPAŞA",
        "STREET": "ÇAMURLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "KEMALPAŞA",
        "STREET": "ÜÇKARDEŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "MURGUL",
        "STREET": "AKANTAŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "MURGUL",
        "STREET": "ARDIÇLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "MURGUL",
        "STREET": "BAŞKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "MURGUL",
        "STREET": "DAMAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "MURGUL",
        "STREET": "ERENKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "MURGUL",
        "STREET": "KABACA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "MURGUL",
        "STREET": "KORUCULAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "MURGUL",
        "STREET": "KÜRE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "MURGUL",
        "STREET": "KÜRE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "MURGUL",
        "STREET": "OSMANLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "MURGUL",
        "STREET": "PETEK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "MURGUL",
        "STREET": "ÇİMENLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "YUSUFELİ",
        "STREET": "AHALT MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "YUSUFELİ",
        "STREET": "ALANBAŞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "YUSUFELİ",
        "STREET": "ALTIPARMAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "YUSUFELİ",
        "STREET": "ARPACIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "YUSUFELİ",
        "STREET": "AVCILAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "YUSUFELİ",
        "STREET": "BADEMKAYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "YUSUFELİ",
        "STREET": "BAHÇELİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "YUSUFELİ",
        "STREET": "BAKIRTEPE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "YUSUFELİ",
        "STREET": "BALALAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "YUSUFELİ",
        "STREET": "BALCILI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "YUSUFELİ",
        "STREET": "BIÇAKÇILAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "YUSUFELİ",
        "STREET": "BOSTANCI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "YUSUFELİ",
        "STREET": "BOYALI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "YUSUFELİ",
        "STREET": "CEVİZLİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "YUSUFELİ",
        "STREET": "DARICA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "YUSUFELİ",
        "STREET": "DAĞETEĞİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "YUSUFELİ",
        "STREET": "DEMİRDÖVEN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "YUSUFELİ",
        "STREET": "DEMİRKENT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "YUSUFELİ",
        "STREET": "DEMİRKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "YUSUFELİ",
        "STREET": "DEREİÇİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "YUSUFELİ",
        "STREET": "DOKUMACILAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "YUSUFELİ",
        "STREET": "ERDEMLER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "YUSUFELİ",
        "STREET": "ERENKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "YUSUFELİ",
        "STREET": "ESENDAL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "YUSUFELİ",
        "STREET": "ESENYAKA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "YUSUFELİ",
        "STREET": "GÜMÜŞÖZÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "YUSUFELİ",
        "STREET": "GÜNYAYLA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "YUSUFELİ",
        "STREET": "HASANAĞA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "YUSUFELİ",
        "STREET": "HAVUZLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "YUSUFELİ",
        "STREET": "IRMAKYANI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "YUSUFELİ",
        "STREET": "KAZIM KARABEKİR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "YUSUFELİ",
        "STREET": "KAÇKAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "YUSUFELİ",
        "STREET": "KILIÇKAYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "YUSUFELİ",
        "STREET": "KINALIÇAM KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "YUSUFELİ",
        "STREET": "KÖMÜRLÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "YUSUFELİ",
        "STREET": "KÖPRÜGÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "YUSUFELİ",
        "STREET": "KÜPLÜCE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "YUSUFELİ",
        "STREET": "KİRAZALAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "YUSUFELİ",
        "STREET": "LİVANE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "YUSUFELİ",
        "STREET": "MERKEZ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "YUSUFELİ",
        "STREET": "MEŞECİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "YUSUFELİ",
        "STREET": "MORKAYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "YUSUFELİ",
        "STREET": "MUTLUGÜN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "YUSUFELİ",
        "STREET": "NARLIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "YUSUFELİ",
        "STREET": "ORMANDİBİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "YUSUFELİ",
        "STREET": "PAMUKÇULAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "YUSUFELİ",
        "STREET": "SANCAK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "YUSUFELİ",
        "STREET": "SEBZECİLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "YUSUFELİ",
        "STREET": "SERİNSU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "YUSUFELİ",
        "STREET": "SÜTLÜCE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "YUSUFELİ",
        "STREET": "TARAKÇILAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "YUSUFELİ",
        "STREET": "TAŞKIRAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "YUSUFELİ",
        "STREET": "TEKKALE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "YUSUFELİ",
        "STREET": "YAMAÇÜSTÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "YUSUFELİ",
        "STREET": "YARBAŞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "YUSUFELİ",
        "STREET": "YAYLALAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "YUSUFELİ",
        "STREET": "YAĞCILAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "YUSUFELİ",
        "STREET": "YENİKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "YUSUFELİ",
        "STREET": "YOKUŞLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "YUSUFELİ",
        "STREET": "YÜKSEKOBA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "YUSUFELİ",
        "STREET": "YÜNCÜLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "YUSUFELİ",
        "STREET": "ZEYTİNCİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "YUSUFELİ",
        "STREET": "ÇAMLICA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "YUSUFELİ",
        "STREET": "ÇAĞLIYAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "YUSUFELİ",
        "STREET": "ÇELTİKDÜZÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "YUSUFELİ",
        "STREET": "ÇEVRELİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "YUSUFELİ",
        "STREET": "ÇIRALI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "YUSUFELİ",
        "STREET": "ÇORUH MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "YUSUFELİ",
        "STREET": "ÖZGÜVEN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "YUSUFELİ",
        "STREET": "ÖĞDEM KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "YUSUFELİ",
        "STREET": "İNANLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "YUSUFELİ",
        "STREET": "İŞHAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "ŞAVŞAT",
        "STREET": "AKDAMLA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "ŞAVŞAT",
        "STREET": "ARMUTLU MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "ŞAVŞAT",
        "STREET": "ARPALI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "ŞAVŞAT",
        "STREET": "ATALAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "ŞAVŞAT",
        "STREET": "AŞAĞIKOYUNLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "ŞAVŞAT",
        "STREET": "BALIKLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "ŞAVŞAT",
        "STREET": "CEVİZLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "ŞAVŞAT",
        "STREET": "CİRİTDÜZÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "ŞAVŞAT",
        "STREET": "DALKIRMAZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "ŞAVŞAT",
        "STREET": "DEMİRCİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "ŞAVŞAT",
        "STREET": "DEMİRKAPI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "ŞAVŞAT",
        "STREET": "DEREİÇİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "ŞAVŞAT",
        "STREET": "DUTLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "ŞAVŞAT",
        "STREET": "DÜZENLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "ŞAVŞAT",
        "STREET": "ELMALI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "ŞAVŞAT",
        "STREET": "ERİKLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "ŞAVŞAT",
        "STREET": "ESKİKALE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "ŞAVŞAT",
        "STREET": "HANLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "ŞAVŞAT",
        "STREET": "ILICA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "ŞAVŞAT",
        "STREET": "KARAAĞAÇ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "ŞAVŞAT",
        "STREET": "KARAKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "ŞAVŞAT",
        "STREET": "KAYABAŞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "ŞAVŞAT",
        "STREET": "KAYADİBİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "ŞAVŞAT",
        "STREET": "KOCABEY KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "ŞAVŞAT",
        "STREET": "KURUDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "ŞAVŞAT",
        "STREET": "KÖPRÜLÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "ŞAVŞAT",
        "STREET": "KÖPRÜYAKA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "ŞAVŞAT",
        "STREET": "KÜPLÜCE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "ŞAVŞAT",
        "STREET": "KİRAZLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "ŞAVŞAT",
        "STREET": "KİREÇLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "ŞAVŞAT",
        "STREET": "MADEN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "ŞAVŞAT",
        "STREET": "MEYDANCIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "ŞAVŞAT",
        "STREET": "MEŞELİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "ŞAVŞAT",
        "STREET": "MISIRLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "ŞAVŞAT",
        "STREET": "OBA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "ŞAVŞAT",
        "STREET": "OTLUCA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "ŞAVŞAT",
        "STREET": "PINARLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "ŞAVŞAT",
        "STREET": "SAVAŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "ŞAVŞAT",
        "STREET": "SAYLICA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "ŞAVŞAT",
        "STREET": "SEBZELİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "ŞAVŞAT",
        "STREET": "SUSUZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "ŞAVŞAT",
        "STREET": "SÖĞÜTLÜ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "ŞAVŞAT",
        "STREET": "TAŞKÖPRÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "ŞAVŞAT",
        "STREET": "TEPEBAŞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "ŞAVŞAT",
        "STREET": "TEPEKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "ŞAVŞAT",
        "STREET": "VELİKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "ŞAVŞAT",
        "STREET": "YAMAÇLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "ŞAVŞAT",
        "STREET": "YAVUZKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "ŞAVŞAT",
        "STREET": "YAĞLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "ŞAVŞAT",
        "STREET": "YAŞAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "ŞAVŞAT",
        "STREET": "YENİKÖY MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "ŞAVŞAT",
        "STREET": "YEŞİLCE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "ŞAVŞAT",
        "STREET": "YONCALI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "ŞAVŞAT",
        "STREET": "YUKARIKOYUNLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "ŞAVŞAT",
        "STREET": "ZİYARET KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "ŞAVŞAT",
        "STREET": "ÇAMLICA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "ŞAVŞAT",
        "STREET": "ÇAVDARLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "ŞAVŞAT",
        "STREET": "ÇAYAĞZI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "ŞAVŞAT",
        "STREET": "ÇAĞLIPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "ŞAVŞAT",
        "STREET": "ÇAĞLIYAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "ŞAVŞAT",
        "STREET": "ÇERMİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "ŞAVŞAT",
        "STREET": "ÇORAKLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "ŞAVŞAT",
        "STREET": "ÇUKUR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "ŞAVŞAT",
        "STREET": "ÇİFTLİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "ŞAVŞAT",
        "STREET": "ÜZÜMLÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "ŞAVŞAT",
        "STREET": "ŞALCI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "ŞAVŞAT",
        "STREET": "ŞENKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "ARTVİN",
        "DISTRICT": "ŞAVŞAT",
        "STREET": "ŞENOCAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "BOZDOĞAN",
        "STREET": "AKSEKİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "BOZDOĞAN",
        "STREET": "AKYAKA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "BOZDOĞAN",
        "STREET": "AKÇAY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "BOZDOĞAN",
        "STREET": "ALAMUT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "BOZDOĞAN",
        "STREET": "ALHİSAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "BOZDOĞAN",
        "STREET": "ALTINTAŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "BOZDOĞAN",
        "STREET": "AMASYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "BOZDOĞAN",
        "STREET": "ASMA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "BOZDOĞAN",
        "STREET": "BAŞALAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "BOZDOĞAN",
        "STREET": "CUMHURİYET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "BOZDOĞAN",
        "STREET": "DUTAĞAÇ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "BOZDOĞAN",
        "STREET": "DÖMEN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "BOZDOĞAN",
        "STREET": "EYMİR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "BOZDOĞAN",
        "STREET": "GÜNEY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "BOZDOĞAN",
        "STREET": "GÜNEYYAKA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "BOZDOĞAN",
        "STREET": "GÜRE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "BOZDOĞAN",
        "STREET": "GÜVENİR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "BOZDOĞAN",
        "STREET": "HAYDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "BOZDOĞAN",
        "STREET": "HIDIRBABA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "BOZDOĞAN",
        "STREET": "HIŞIMLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "BOZDOĞAN",
        "STREET": "HİSAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "BOZDOĞAN",
        "STREET": "KAKKALAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "BOZDOĞAN",
        "STREET": "KAMIŞLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "BOZDOĞAN",
        "STREET": "KARAAHMETLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "BOZDOĞAN",
        "STREET": "KARABAĞLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "BOZDOĞAN",
        "STREET": "KAVAKLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "BOZDOĞAN",
        "STREET": "KAZANDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "BOZDOĞAN",
        "STREET": "KEMER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "BOZDOĞAN",
        "STREET": "KILAVUZLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "BOZDOĞAN",
        "STREET": "KIZILCA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "BOZDOĞAN",
        "STREET": "KIZILTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "BOZDOĞAN",
        "STREET": "KONAKLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "BOZDOĞAN",
        "STREET": "KOYUNCULAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "BOZDOĞAN",
        "STREET": "KÖRTEKE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "BOZDOĞAN",
        "STREET": "MADRAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "BOZDOĞAN",
        "STREET": "OLUKBAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "BOZDOĞAN",
        "STREET": "OSMANİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "BOZDOĞAN",
        "STREET": "PINARLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "BOZDOĞAN",
        "STREET": "SEKİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "BOZDOĞAN",
        "STREET": "SIRMA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "BOZDOĞAN",
        "STREET": "TÜTÜNCÜLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "BOZDOĞAN",
        "STREET": "YAKA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "BOZDOĞAN",
        "STREET": "YAZIKENT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "BOZDOĞAN",
        "STREET": "YENİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "BOZDOĞAN",
        "STREET": "YENİCE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "BOZDOĞAN",
        "STREET": "YENİKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "BOZDOĞAN",
        "STREET": "YEŞİL YENİCE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "BOZDOĞAN",
        "STREET": "YEŞİLÇAM MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "BOZDOĞAN",
        "STREET": "ZİYARETLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "BOZDOĞAN",
        "STREET": "ÇAMLIDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "BOZDOĞAN",
        "STREET": "ÇARŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "BOZDOĞAN",
        "STREET": "ÖRENCİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "BOZDOĞAN",
        "STREET": "ÖRENTAHT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "BOZDOĞAN",
        "STREET": "ÖRMEPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "BOZDOĞAN",
        "STREET": "ÖRTÜLÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "BUHARKENT",
        "STREET": "ERİCEK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "BUHARKENT",
        "STREET": "FESLEK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "BUHARKENT",
        "STREET": "GELENBE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "BUHARKENT",
        "STREET": "GÜNDOĞAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "BUHARKENT",
        "STREET": "KAMİLPAŞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "BUHARKENT",
        "STREET": "KAYABURNU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "BUHARKENT",
        "STREET": "KIZILDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "BUHARKENT",
        "STREET": "MENDERES MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "BUHARKENT",
        "STREET": "MURATDAĞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "BUHARKENT",
        "STREET": "ORTAKÇI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "BUHARKENT",
        "STREET": "SAVCILLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "BUHARKENT",
        "STREET": "ZAFER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "BUHARKENT",
        "STREET": "ÜÇEYLÜL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "BUHARKENT",
        "STREET": "İSTİKLAL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "DİDİM",
        "STREET": "AK-YENİKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "DİDİM",
        "STREET": "AKBÜK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "DİDİM",
        "STREET": "AKKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "DİDİM",
        "STREET": "ALTINKUM MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "DİDİM",
        "STREET": "BALAT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "DİDİM",
        "STREET": "BATIKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "DİDİM",
        "STREET": "CUMHURİYET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "DİDİM",
        "STREET": "DENİZKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "DİDİM",
        "STREET": "EFELER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "DİDİM",
        "STREET": "FEVZİPAŞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "DİDİM",
        "STREET": "HİSAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "DİDİM",
        "STREET": "MAVİŞEHİR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "DİDİM",
        "STREET": "MERSİNDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "DİDİM",
        "STREET": "YALIKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "DİDİM",
        "STREET": "YENİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "DİDİM",
        "STREET": "ÇAMLIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "EFELER",
        "STREET": "ADNAN MENDERES MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "EFELER",
        "STREET": "ALANLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "EFELER",
        "STREET": "ALATEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "EFELER",
        "STREET": "AMBARCIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "EFELER",
        "STREET": "ARMUTLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "EFELER",
        "STREET": "ATA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "EFELER",
        "STREET": "AYDIN E TİPİ KAPALI VE AÇIK CEZA İNFAZ KURUMU",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "EFELER",
        "STREET": "AŞAĞIKAYACIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "EFELER",
        "STREET": "BADEMLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "EFELER",
        "STREET": "BALIKKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "EFELER",
        "STREET": "BALTAKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "EFELER",
        "STREET": "BÖCEK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "EFELER",
        "STREET": "CUMA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "EFELER",
        "STREET": "CUMHURİYET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "EFELER",
        "STREET": "DALAMA BUCAĞI YENİKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "EFELER",
        "STREET": "DALAMA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "EFELER",
        "STREET": "DANİŞMENT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "EFELER",
        "STREET": "DAĞEYMİRİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "EFELER",
        "STREET": "DEREKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "EFELER",
        "STREET": "DOĞAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "EFELER",
        "STREET": "EFELER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "EFELER",
        "STREET": "EMİRDOĞAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "EFELER",
        "STREET": "EĞRİKAVAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "EFELER",
        "STREET": "FATİH MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "EFELER",
        "STREET": "GÖDRENLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "EFELER",
        "STREET": "GÖLCÜK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "EFELER",
        "STREET": "GÖLHİSAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "EFELER",
        "STREET": "GÖZPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "EFELER",
        "STREET": "GÜZELHİSAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "EFELER",
        "STREET": "GİRNE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "EFELER",
        "STREET": "HASANEFENDİ-RAMAZAN PAŞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "EFELER",
        "STREET": "HOROZKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "EFELER",
        "STREET": "ILICABAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "EFELER",
        "STREET": "IŞIKLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "EFELER",
        "STREET": "KADIKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "EFELER",
        "STREET": "KALFAKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "EFELER",
        "STREET": "KARAHAYIT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "EFELER",
        "STREET": "KARAKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "EFELER",
        "STREET": "KARDEŞKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "EFELER",
        "STREET": "KEMER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "EFELER",
        "STREET": "KENGER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "EFELER",
        "STREET": "KIRIKLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "EFELER",
        "STREET": "KIZILCAKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "EFELER",
        "STREET": "KOCAGÜR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "EFELER",
        "STREET": "KONUKLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "EFELER",
        "STREET": "KOZALAKLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "EFELER",
        "STREET": "KULOĞULLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "EFELER",
        "STREET": "KURTULUŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "EFELER",
        "STREET": "KUYUCULAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "EFELER",
        "STREET": "KUYULU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "EFELER",
        "STREET": "KÖPRÜLÜ-VEYSİPAŞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "EFELER",
        "STREET": "MERKEZ BUCAĞI YENİKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "EFELER",
        "STREET": "MESUDİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "EFELER",
        "STREET": "MESUTLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "EFELER",
        "STREET": "MEŞRUTİYET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "EFELER",
        "STREET": "MUSLUCA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "EFELER",
        "STREET": "MİMAR SİNAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "EFELER",
        "STREET": "ORTA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "EFELER",
        "STREET": "ORTAKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "EFELER",
        "STREET": "OSMAN YOZGATLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "EFELER",
        "STREET": "OVAEYMİR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "EFELER",
        "STREET": "PINARDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "EFELER",
        "STREET": "SAVRANDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "EFELER",
        "STREET": "SERÇEKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "EFELER",
        "STREET": "SIRALILAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "EFELER",
        "STREET": "TEPECİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "EFELER",
        "STREET": "TEPEKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "EFELER",
        "STREET": "TERZİLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "EFELER",
        "STREET": "UMURLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "EFELER",
        "STREET": "YAĞCILAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "EFELER",
        "STREET": "YEDİ EYLÜL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "EFELER",
        "STREET": "YILMAZKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "EFELER",
        "STREET": "YUKARIKAYACIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "EFELER",
        "STREET": "ZAFER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "EFELER",
        "STREET": "ZEYBEK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "EFELER",
        "STREET": "ZEYTİNKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "EFELER",
        "STREET": "ÇAYYÜZÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "EFELER",
        "STREET": "ÇEŞTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "EFELER",
        "STREET": "ÇİFTLİKKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "EFELER",
        "STREET": "İLYASDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "EFELER",
        "STREET": "İMAMKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "EFELER",
        "STREET": "İSTİKLAL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "EFELER",
        "STREET": "ŞAHNALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "EFELER",
        "STREET": "ŞEVKETİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "GERMENCİK",
        "STREET": "ABDURRAHMANLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "GERMENCİK",
        "STREET": "ALANGÜLLÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "GERMENCİK",
        "STREET": "BALATÇIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "GERMENCİK",
        "STREET": "BOZKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "GERMENCİK",
        "STREET": "CAMİKEBİR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "GERMENCİK",
        "STREET": "DAMPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "GERMENCİK",
        "STREET": "DAĞKARAAĞAÇ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "GERMENCİK",
        "STREET": "DAĞYENİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "GERMENCİK",
        "STREET": "DEREKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "GERMENCİK",
        "STREET": "GÜMÜŞKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "GERMENCİK",
        "STREET": "GÜMÜŞYENİKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "GERMENCİK",
        "STREET": "HABİBLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "GERMENCİK",
        "STREET": "HIDIRBEYLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "GERMENCİK",
        "STREET": "KARAAĞAÇLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "GERMENCİK",
        "STREET": "KIZILCAGEDİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "GERMENCİK",
        "STREET": "KIZILCAPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "GERMENCİK",
        "STREET": "MESUDİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "GERMENCİK",
        "STREET": "MEŞELİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "GERMENCİK",
        "STREET": "MORALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "GERMENCİK",
        "STREET": "MURSALLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "GERMENCİK",
        "STREET": "NAİPLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "GERMENCİK",
        "STREET": "NEŞETİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "GERMENCİK",
        "STREET": "ORTAKLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "GERMENCİK",
        "STREET": "PARK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "GERMENCİK",
        "STREET": "REİSKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "GERMENCİK",
        "STREET": "SELATİN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "GERMENCİK",
        "STREET": "TEKİN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "GERMENCİK",
        "STREET": "TURANLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "GERMENCİK",
        "STREET": "UZUNKUM MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "GERMENCİK",
        "STREET": "YEDİEYLÜL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "GERMENCİK",
        "STREET": "YENİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "GERMENCİK",
        "STREET": "ÇAMKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "GERMENCİK",
        "STREET": "ÇARIKLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "GERMENCİK",
        "STREET": "ÖMERBEYLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "GERMENCİK",
        "STREET": "ÜZÜMLÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "GERMENCİK",
        "STREET": "İSTASYON MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "KARACASU",
        "STREET": "ALEMLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "KARACASU",
        "STREET": "ATAEYMİR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "KARACASU",
        "STREET": "ATAKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "KARACASU",
        "STREET": "AŞAĞIGÖRLE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "KARACASU",
        "STREET": "BAHÇEKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "KARACASU",
        "STREET": "BEREKETLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "KARACASU",
        "STREET": "BÜYÜKDAĞLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "KARACASU",
        "STREET": "BİNGEÇ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "KARACASU",
        "STREET": "CABİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "KARACASU",
        "STREET": "CUMA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "KARACASU",
        "STREET": "DEDELER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "KARACASU",
        "STREET": "DEREKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "KARACASU",
        "STREET": "DİKMEN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "KARACASU",
        "STREET": "ESENÇAY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "KARACASU",
        "STREET": "GEYRE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "KARACASU",
        "STREET": "GÖRLE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "KARACASU",
        "STREET": "GÜZELBEYLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "KARACASU",
        "STREET": "GÜZELKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "KARACASU",
        "STREET": "HACIHIDIRLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "KARACASU",
        "STREET": "IŞIKLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "KARACASU",
        "STREET": "KARABAĞLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "KARACASU",
        "STREET": "KARACAÖREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "KARACASU",
        "STREET": "KÜÇÜKDAĞLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "KARACASU",
        "STREET": "NARGEDİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "KARACASU",
        "STREET": "PALAMUTÇUK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "KARACASU",
        "STREET": "TEKELİLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "KARACASU",
        "STREET": "TEPECİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "KARACASU",
        "STREET": "YAYKIN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "KARACASU",
        "STREET": "YAYLALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "KARACASU",
        "STREET": "YAZIR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "KARACASU",
        "STREET": "YENİCE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "KARACASU",
        "STREET": "YENİKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "KARACASU",
        "STREET": "YEŞİLKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "KARACASU",
        "STREET": "YEŞİLYURT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "KARACASU",
        "STREET": "YOLALTI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "KARACASU",
        "STREET": "YOLÜSTÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "KARACASU",
        "STREET": "ÇAMARASI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "KARACASU",
        "STREET": "ÇAMKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "KARPUZLU",
        "STREET": "ABAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "KARPUZLU",
        "STREET": "AKÇAABAT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "KARPUZLU",
        "STREET": "BAYRAKTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "KARPUZLU",
        "STREET": "CUMALAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "KARPUZLU",
        "STREET": "EKTİRLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "KARPUZLU",
        "STREET": "GÖLCÜK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "KARPUZLU",
        "STREET": "GÜNEY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "KARPUZLU",
        "STREET": "HATIPKIŞLA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "KARPUZLU",
        "STREET": "IŞIKLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "KARPUZLU",
        "STREET": "KOĞUK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "KARPUZLU",
        "STREET": "MERİÇLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "KARPUZLU",
        "STREET": "MUTLUCA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "KARPUZLU",
        "STREET": "ORTA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "KARPUZLU",
        "STREET": "OVAPINARI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "KARPUZLU",
        "STREET": "TEKELER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "KARPUZLU",
        "STREET": "TÜTÜNLÜK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "KARPUZLU",
        "STREET": "ULUKONAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "KARPUZLU",
        "STREET": "UMCULAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "KARPUZLU",
        "STREET": "YAĞŞILAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "KARPUZLU",
        "STREET": "YENİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "KARPUZLU",
        "STREET": "ÇOBANİSA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "KARPUZLU",
        "STREET": "ÖMERLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "KARPUZLU",
        "STREET": "ŞENKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "KOÇARLI",
        "STREET": "ADNAN MENDERES MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "KOÇARLI",
        "STREET": "AKMESCİT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "KOÇARLI",
        "STREET": "ATATÜRK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "KOÇARLI",
        "STREET": "BAĞARCIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "KOÇARLI",
        "STREET": "BAĞCILAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "KOÇARLI",
        "STREET": "BIYIKLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "KOÇARLI",
        "STREET": "BOYDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "KOÇARLI",
        "STREET": "BOĞAZİÇİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "KOÇARLI",
        "STREET": "BÜYÜKDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "KOÇARLI",
        "STREET": "BİRCİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "KOÇARLI",
        "STREET": "CİNCİN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "KOÇARLI",
        "STREET": "DEDEKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "KOÇARLI",
        "STREET": "DEREKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "KOÇARLI",
        "STREET": "ESENTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "KOÇARLI",
        "STREET": "EVSEKLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "KOÇARLI",
        "STREET": "GAFFARLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "KOÇARLI",
        "STREET": "GÖZKAYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "KOÇARLI",
        "STREET": "GÜDÜŞLÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "KOÇARLI",
        "STREET": "GÜNDOĞAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "KOÇARLI",
        "STREET": "HACIHAMZALAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "KOÇARLI",
        "STREET": "HALİLBEYLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "KOÇARLI",
        "STREET": "HAYDARLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "KOÇARLI",
        "STREET": "KARAAĞAÇ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "KOÇARLI",
        "STREET": "KARACAÖREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "KOÇARLI",
        "STREET": "KARADUT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "KOÇARLI",
        "STREET": "KASAPLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "KOÇARLI",
        "STREET": "KIZILCABÖLÜK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "KOÇARLI",
        "STREET": "KIZILKAYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "KOÇARLI",
        "STREET": "KULLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "KOÇARLI",
        "STREET": "KUŞLARBELEN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "KOÇARLI",
        "STREET": "MERSİNBELEN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "KOÇARLI",
        "STREET": "ORHANİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "KOÇARLI",
        "STREET": "ORTA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "KOÇARLI",
        "STREET": "SAPALAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "KOÇARLI",
        "STREET": "SATILAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "KOÇARLI",
        "STREET": "SOBUCA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "KOÇARLI",
        "STREET": "TAŞKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "KOÇARLI",
        "STREET": "TEKELİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "KOÇARLI",
        "STREET": "TIĞILLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "KOÇARLI",
        "STREET": "TİMİNCİLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "KOÇARLI",
        "STREET": "YAĞCIDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "KOÇARLI",
        "STREET": "YAĞHANLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "KOÇARLI",
        "STREET": "YENİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "KOÇARLI",
        "STREET": "YENİKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "KOÇARLI",
        "STREET": "ZEYTİNKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "KOÇARLI",
        "STREET": "ÇAKIRBEYLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "KOÇARLI",
        "STREET": "ÇAKMAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "KOÇARLI",
        "STREET": "ÇALLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "KOÇARLI",
        "STREET": "ÇEŞMEKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "KOÇARLI",
        "STREET": "ÇULHALAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "KOÇARLI",
        "STREET": "ŞAHİNCİLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "KOÇARLI",
        "STREET": "ŞENKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "KUYUCAK",
        "STREET": "AKSAZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "KUYUCAK",
        "STREET": "AYDINOĞLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "KUYUCAK",
        "STREET": "AZİZABAT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "KUYUCAK",
        "STREET": "BAŞARAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "KUYUCAK",
        "STREET": "BELENOVA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "KUYUCAK",
        "STREET": "BEŞEYLÜL (MERKEZ) MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "KUYUCAK",
        "STREET": "BEŞEYLÜL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "KUYUCAK",
        "STREET": "BUCAKKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "KUYUCAK",
        "STREET": "CUMHURİYET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "KUYUCAK",
        "STREET": "DEREKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "KUYUCAK",
        "STREET": "FATİH MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "KUYUCAK",
        "STREET": "GENCELLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "KUYUCAK",
        "STREET": "GENCELLİDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "KUYUCAK",
        "STREET": "HORSUNLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "KUYUCAK",
        "STREET": "KARAPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "KUYUCAK",
        "STREET": "KAYRAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "KUYUCAK",
        "STREET": "KURTULUŞ (HORSUNLU) MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "KUYUCAK",
        "STREET": "KURTULUŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "KUYUCAK",
        "STREET": "MUSAKOLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "KUYUCAK",
        "STREET": "MUSTAFAPAŞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "KUYUCAK",
        "STREET": "OVACIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "KUYUCAK",
        "STREET": "PAMUCAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "KUYUCAK",
        "STREET": "PAMUKÖREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "KUYUCAK",
        "STREET": "SARICAOVA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "KUYUCAK",
        "STREET": "TAŞOLUK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "KUYUCAK",
        "STREET": "YAKUPPAŞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "KUYUCAK",
        "STREET": "YAMALAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "KUYUCAK",
        "STREET": "YAYLALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "KUYUCAK",
        "STREET": "YENİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "KUYUCAK",
        "STREET": "YEŞİLDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "KUYUCAK",
        "STREET": "YUKARIYAKACIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "KUYUCAK",
        "STREET": "YÖRE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "KUYUCAK",
        "STREET": "ÇAMDİBİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "KUYUCAK",
        "STREET": "ÇOBANİSA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "KUYUCAK",
        "STREET": "ÖREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "KUYUCAK",
        "STREET": "İĞDECİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "KUŞADASI",
        "STREET": "ALACAMESCİT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "KUŞADASI",
        "STREET": "BAYRAKLIDEDE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "KUŞADASI",
        "STREET": "CAFERLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "KUŞADASI",
        "STREET": "CAMİATİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "KUŞADASI",
        "STREET": "CAMİKEBİR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "KUŞADASI",
        "STREET": "CUMHURİYET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "KUŞADASI",
        "STREET": "DAVUTLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "KUŞADASI",
        "STREET": "DAĞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "KUŞADASI",
        "STREET": "DEĞİRMENDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "KUŞADASI",
        "STREET": "EGE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "KUŞADASI",
        "STREET": "GÜZELÇAMLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "KUŞADASI",
        "STREET": "HACIFEYZULLAH MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "KUŞADASI",
        "STREET": "KADIKALESİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "KUŞADASI",
        "STREET": "KADINLAR DENİZİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "KUŞADASI",
        "STREET": "KARAOVA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "KUŞADASI",
        "STREET": "KİRAZLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "KUŞADASI",
        "STREET": "SOĞUCAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "KUŞADASI",
        "STREET": "TÜRKMEN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "KUŞADASI",
        "STREET": "YAVANSU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "KUŞADASI",
        "STREET": "YAYLAKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "KUŞADASI",
        "STREET": "YENİKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "KUŞADASI",
        "STREET": "ÇINARKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "KUŞADASI",
        "STREET": "İKİÇEŞMELİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "KÖŞK",
        "STREET": "AHATLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "KÖŞK",
        "STREET": "AKÇAKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "KÖŞK",
        "STREET": "ALTIEYLÜL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "KÖŞK",
        "STREET": "BAKLAKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "KÖŞK",
        "STREET": "BAŞÇAYIR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "KÖŞK",
        "STREET": "BEYKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "KÖŞK",
        "STREET": "CUMADERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "KÖŞK",
        "STREET": "CUMAYANI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "KÖŞK",
        "STREET": "GÖKKİRİŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "KÖŞK",
        "STREET": "GÜNDOĞAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "KÖŞK",
        "STREET": "GÜZELKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "KÖŞK",
        "STREET": "ILIDAĞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "KÖŞK",
        "STREET": "KARATEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "KÖŞK",
        "STREET": "KETENYERİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "KÖŞK",
        "STREET": "KIRAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "KÖŞK",
        "STREET": "KIZILCAKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "KÖŞK",
        "STREET": "KIZILCAYER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "KÖŞK",
        "STREET": "KOÇAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "KÖŞK",
        "STREET": "MENTEŞELER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "KÖŞK",
        "STREET": "MEZEKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "KÖŞK",
        "STREET": "OVAKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "KÖŞK",
        "STREET": "SARIÇAM MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "KÖŞK",
        "STREET": "SERDAROĞLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "KÖŞK",
        "STREET": "SOĞUKKUYU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "KÖŞK",
        "STREET": "UZUNDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "KÖŞK",
        "STREET": "YAVUZKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "KÖŞK",
        "STREET": "ÇARŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "KÖŞK",
        "STREET": "ÇİFTLİKKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "NAZİLLİ",
        "STREET": "AKPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "NAZİLLİ",
        "STREET": "AKSU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "NAZİLLİ",
        "STREET": "ALTINTAŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "NAZİLLİ",
        "STREET": "APAKLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "NAZİLLİ",
        "STREET": "ARSLANLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "NAZİLLİ",
        "STREET": "AYDOĞDU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "NAZİLLİ",
        "STREET": "AŞAĞIYAKACIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "NAZİLLİ",
        "STREET": "AŞAĞIÖRENCİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "NAZİLLİ",
        "STREET": "BAYINDIR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "NAZİLLİ",
        "STREET": "BAĞCILLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "NAZİLLİ",
        "STREET": "BEKİRLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "NAZİLLİ",
        "STREET": "BEREKETLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "NAZİLLİ",
        "STREET": "BEĞERLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "NAZİLLİ",
        "STREET": "BOZYURT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "NAZİLLİ",
        "STREET": "CUMHURİYET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "NAZİLLİ",
        "STREET": "DALLICA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "NAZİLLİ",
        "STREET": "DEMİRCİLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "NAZİLLİ",
        "STREET": "DEREAĞZI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "NAZİLLİ",
        "STREET": "DEREBAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "NAZİLLİ",
        "STREET": "DUALAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "NAZİLLİ",
        "STREET": "DUMLUPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "NAZİLLİ",
        "STREET": "DURASILLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "NAZİLLİ",
        "STREET": "ESENKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "NAZİLLİ",
        "STREET": "ESENTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "NAZİLLİ",
        "STREET": "EYCELLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "NAZİLLİ",
        "STREET": "GEDİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "NAZİLLİ",
        "STREET": "GEDİKALTI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "NAZİLLİ",
        "STREET": "GÜZELKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "NAZİLLİ",
        "STREET": "HAMZALLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "NAZİLLİ",
        "STREET": "HAMİDİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "NAZİLLİ",
        "STREET": "HASKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "NAZİLLİ",
        "STREET": "HAYDARLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "NAZİLLİ",
        "STREET": "HİSARCIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "NAZİLLİ",
        "STREET": "IŞIKLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "NAZİLLİ",
        "STREET": "KAHVEDERESİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "NAZİLLİ",
        "STREET": "KARAHALLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "NAZİLLİ",
        "STREET": "KARAÇAY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "NAZİLLİ",
        "STREET": "KARDEŞKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "NAZİLLİ",
        "STREET": "KAVACIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "NAZİLLİ",
        "STREET": "KAŞIKÇILAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "NAZİLLİ",
        "STREET": "KESTEL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "NAZİLLİ",
        "STREET": "KETENDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "NAZİLLİ",
        "STREET": "KETENOVA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "NAZİLLİ",
        "STREET": "KIRCAKLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "NAZİLLİ",
        "STREET": "KIZILDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "NAZİLLİ",
        "STREET": "KOCAKESİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "NAZİLLİ",
        "STREET": "KOZDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "NAZİLLİ",
        "STREET": "KURTULUŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "NAZİLLİ",
        "STREET": "KUŞÇULAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "NAZİLLİ",
        "STREET": "MESCİTLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "NAZİLLİ",
        "STREET": "NAZİLLİ E TİPİ KAPALI CEZA İNFAZ KURUMU",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "NAZİLLİ",
        "STREET": "OCAKLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "NAZİLLİ",
        "STREET": "OVACIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "NAZİLLİ",
        "STREET": "PINARBAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "NAZİLLİ",
        "STREET": "PROF.MUAMMER AKSOY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "NAZİLLİ",
        "STREET": "PİRLİBEY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "NAZİLLİ",
        "STREET": "RAHMANLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "NAZİLLİ",
        "STREET": "SAMAİLLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "NAZİLLİ",
        "STREET": "SAİLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "NAZİLLİ",
        "STREET": "SEVİNDİKLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "NAZİLLİ",
        "STREET": "SÜMER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "NAZİLLİ",
        "STREET": "TOYGAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "NAZİLLİ",
        "STREET": "TURAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "NAZİLLİ",
        "STREET": "UZUNÇAM MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "NAZİLLİ",
        "STREET": "YALINKUYU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "NAZİLLİ",
        "STREET": "YAYLAPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "NAZİLLİ",
        "STREET": "YAZIRLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "NAZİLLİ",
        "STREET": "YELLİCE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "NAZİLLİ",
        "STREET": "YENİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "NAZİLLİ",
        "STREET": "YENİ SANAYİİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "NAZİLLİ",
        "STREET": "YEŞİL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "NAZİLLİ",
        "STREET": "YEŞİLYURT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "NAZİLLİ",
        "STREET": "YILDIZTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "NAZİLLİ",
        "STREET": "YUKARIÖRENCİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "NAZİLLİ",
        "STREET": "ZAFER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "NAZİLLİ",
        "STREET": "ÇAPAHASAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "NAZİLLİ",
        "STREET": "ÇATAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "NAZİLLİ",
        "STREET": "ÇAYLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "NAZİLLİ",
        "STREET": "ÇOBANLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "NAZİLLİ",
        "STREET": "İSABEYLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "NAZİLLİ",
        "STREET": "İSTİKLAL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "NAZİLLİ",
        "STREET": "ŞİMŞELLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "NAZİLLİ",
        "STREET": "ŞİRİNEVLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "SULTANHİSAR",
        "STREET": "ATÇA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "SULTANHİSAR",
        "STREET": "BEŞEYLÜL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "SULTANHİSAR",
        "STREET": "DEMİRHAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "SULTANHİSAR",
        "STREET": "ESKİHİSAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "SULTANHİSAR",
        "STREET": "GÜVENDİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "SULTANHİSAR",
        "STREET": "HİSAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "SULTANHİSAR",
        "STREET": "KABACA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "SULTANHİSAR",
        "STREET": "KAVAKLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "SULTANHİSAR",
        "STREET": "KILAVUZLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "SULTANHİSAR",
        "STREET": "KURTULUŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "SULTANHİSAR",
        "STREET": "MALGAÇEMİR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "SULTANHİSAR",
        "STREET": "MALGAÇMUSTAFA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "SULTANHİSAR",
        "STREET": "REKMEZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "SULTANHİSAR",
        "STREET": "SALAVATLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "SULTANHİSAR",
        "STREET": "UZUNLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "SULTANHİSAR",
        "STREET": "YAĞDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "SULTANHİSAR",
        "STREET": "ZAFER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "SULTANHİSAR",
        "STREET": "İNCEALAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "SÖKE",
        "STREET": "AKÇAKAYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "SÖKE",
        "STREET": "AKÇAKONAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "SÖKE",
        "STREET": "ARGAVLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "SÖKE",
        "STREET": "ARSLANYAYLASI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "SÖKE",
        "STREET": "ATATÜRK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "SÖKE",
        "STREET": "ATBURGAZI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "SÖKE",
        "STREET": "AVCILAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "SÖKE",
        "STREET": "AVŞAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "SÖKE",
        "STREET": "AĞAÇLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "SÖKE",
        "STREET": "BAYIRDAMI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "SÖKE",
        "STREET": "BAĞARASI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "SÖKE",
        "STREET": "BURUNKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "SÖKE",
        "STREET": "CUMHURİYET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "SÖKE",
        "STREET": "DEMİRÇAY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "SÖKE",
        "STREET": "DOĞANBEY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "SÖKE",
        "STREET": "FEVZİPAŞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "SÖKE",
        "STREET": "GÖLBENT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "SÖKE",
        "STREET": "GÜLLÜBAHÇE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "SÖKE",
        "STREET": "GÜNEYYAKA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "SÖKE",
        "STREET": "GÜZELTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "SÖKE",
        "STREET": "KARAATLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "SÖKE",
        "STREET": "KARACAHAYIT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "SÖKE",
        "STREET": "KARAKAYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "SÖKE",
        "STREET": "KAYGILLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "SÖKE",
        "STREET": "KEMALPAŞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "SÖKE",
        "STREET": "KONAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "SÖKE",
        "STREET": "KÖPRÜALAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "SÖKE",
        "STREET": "KİSİR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "SÖKE",
        "STREET": "NALBANTLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "SÖKE",
        "STREET": "PAMUKÇULAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "SÖKE",
        "STREET": "SARIKEMER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "SÖKE",
        "STREET": "SAVUCA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "SÖKE",
        "STREET": "SAYRAKÇI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "SÖKE",
        "STREET": "SAZLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "SÖKE",
        "STREET": "SERÇİN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "SÖKE",
        "STREET": "SOFULAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "SÖKE",
        "STREET": "SÖKE KAPALI CEZAEVİ",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "SÖKE",
        "STREET": "TUZBURGAZI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "SÖKE",
        "STREET": "YAMAÇ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "SÖKE",
        "STREET": "YENİCAMİİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "SÖKE",
        "STREET": "YENİDOĞAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "SÖKE",
        "STREET": "YENİKENT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "SÖKE",
        "STREET": "YENİKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "SÖKE",
        "STREET": "YEŞİLKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "SÖKE",
        "STREET": "YUVACA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "SÖKE",
        "STREET": "ÇALIKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "SÖKE",
        "STREET": "ÇALIŞLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "SÖKE",
        "STREET": "ÇAVDAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "SÖKE",
        "STREET": "ÇELTİKÇİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "SÖKE",
        "STREET": "ÖZBAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "YENİPAZAR",
        "STREET": "ALHAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "YENİPAZAR",
        "STREET": "ALİOĞULLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "YENİPAZAR",
        "STREET": "DEREKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "YENİPAZAR",
        "STREET": "DONDURAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "YENİPAZAR",
        "STREET": "DOĞU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "YENİPAZAR",
        "STREET": "DİRECİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "YENİPAZAR",
        "STREET": "EĞRİDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "YENİPAZAR",
        "STREET": "HACIKÖSELER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "YENİPAZAR",
        "STREET": "HAMZABALİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "YENİPAZAR",
        "STREET": "HÜKÜMET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "YENİPAZAR",
        "STREET": "KARACAÖREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "YENİPAZAR",
        "STREET": "KARAÇAKAL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "YENİPAZAR",
        "STREET": "KOYUNLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "YENİPAZAR",
        "STREET": "PAŞAKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "YENİPAZAR",
        "STREET": "YENİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "YENİPAZAR",
        "STREET": "ÇARŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "YENİPAZAR",
        "STREET": "ÇAVDAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "YENİPAZAR",
        "STREET": "ÇULHAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "ÇİNE",
        "STREET": "AKDAM MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "ÇİNE",
        "STREET": "AKÇAOVA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "ÇİNE",
        "STREET": "ALABAYIR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "ÇİNE",
        "STREET": "ALTINABAT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "ÇİNE",
        "STREET": "ALTINOVA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "ÇİNE",
        "STREET": "BAHÇEARASI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "ÇİNE",
        "STREET": "BAĞLARBAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "ÇİNE",
        "STREET": "BEDİRLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "ÇİNE",
        "STREET": "BEREKET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "ÇİNE",
        "STREET": "BUCAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "ÇİNE",
        "STREET": "BÖLÜNTÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "ÇİNE",
        "STREET": "CAMIZAĞILI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "ÇİNE",
        "STREET": "CUMALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "ÇİNE",
        "STREET": "CUMHURİYET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "ÇİNE",
        "STREET": "DERELİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "ÇİNE",
        "STREET": "DORUMLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "ÇİNE",
        "STREET": "DOĞANYURT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "ÇİNE",
        "STREET": "DUTLUOLUK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "ÇİNE",
        "STREET": "ELDERESİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "ÇİNE",
        "STREET": "ESENTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "ÇİNE",
        "STREET": "ESKİÇİNE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "ÇİNE",
        "STREET": "EVCİLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "ÇİNE",
        "STREET": "GÖKYAKA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "ÇİNE",
        "STREET": "HACIPAŞALAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "ÇİNE",
        "STREET": "HALLAÇLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "ÇİNE",
        "STREET": "HAMİTABAT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "ÇİNE",
        "STREET": "HASANLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "ÇİNE",
        "STREET": "KABALAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "ÇİNE",
        "STREET": "KABATAŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "ÇİNE",
        "STREET": "KADILAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "ÇİNE",
        "STREET": "KAHRAMAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "ÇİNE",
        "STREET": "KARAHAYIT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "ÇİNE",
        "STREET": "KARAKOLLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "ÇİNE",
        "STREET": "KARANFİLLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "ÇİNE",
        "STREET": "KARĞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "ÇİNE",
        "STREET": "KASAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "ÇİNE",
        "STREET": "KAVŞIT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "ÇİNE",
        "STREET": "KIRKIŞIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "ÇİNE",
        "STREET": "KIRKSAKALLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "ÇİNE",
        "STREET": "KIZILGÜNEY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "ÇİNE",
        "STREET": "KURUKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "ÇİNE",
        "STREET": "KİRAZDERESİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "ÇİNE",
        "STREET": "MUTAFLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "ÇİNE",
        "STREET": "OVACIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "ÇİNE",
        "STREET": "SARAÇLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "ÇİNE",
        "STREET": "SARIKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "ÇİNE",
        "STREET": "SARIOĞLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "ÇİNE",
        "STREET": "SARNIÇ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "ÇİNE",
        "STREET": "SAĞLIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "ÇİNE",
        "STREET": "SEFERLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "ÇİNE",
        "STREET": "SOĞANCILAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "ÇİNE",
        "STREET": "SOĞUKOLUK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "ÇİNE",
        "STREET": "SUBAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "ÇİNE",
        "STREET": "SÖĞÜTÇÜK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "ÇİNE",
        "STREET": "TATARMEMİŞLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "ÇİNE",
        "STREET": "TAŞOLUK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "ÇİNE",
        "STREET": "TEPEKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "ÇİNE",
        "STREET": "TOPÇAM MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "ÇİNE",
        "STREET": "UMURKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "ÇİNE",
        "STREET": "YAĞCILAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "ÇİNE",
        "STREET": "YENİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "ÇİNE",
        "STREET": "YENİKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "ÇİNE",
        "STREET": "YEŞİLKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "ÇİNE",
        "STREET": "YOLBOYU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "ÇİNE",
        "STREET": "YÖRÜKLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "ÇİNE",
        "STREET": "ÇALTI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "ÇİNE",
        "STREET": "ÇATAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "ÇİNE",
        "STREET": "ÇÖĞÜRLÜK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "ÇİNE",
        "STREET": "ÖZEREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "ÇİNE",
        "STREET": "ÜNLÜCE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "ÇİNE",
        "STREET": "İBRAHİMKAVAĞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "ÇİNE",
        "STREET": "ŞEVKETİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "İNCİRLİOVA",
        "STREET": "ACARLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "İNCİRLİOVA",
        "STREET": "AKÇEŞME MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "İNCİRLİOVA",
        "STREET": "ARPADERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "İNCİRLİOVA",
        "STREET": "ARZULAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "İNCİRLİOVA",
        "STREET": "ATATÜRK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "İNCİRLİOVA",
        "STREET": "BEYKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "İNCİRLİOVA",
        "STREET": "CUMHURİYET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "İNCİRLİOVA",
        "STREET": "DEREAĞZI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "İNCİRLİOVA",
        "STREET": "ERBEYLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "İNCİRLİOVA",
        "STREET": "EĞREK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "İNCİRLİOVA",
        "STREET": "GERENKOVA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "İNCİRLİOVA",
        "STREET": "HACIALİOBASI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "İNCİRLİOVA",
        "STREET": "HAMİTLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "İNCİRLİOVA",
        "STREET": "HÜRRİYET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "İNCİRLİOVA",
        "STREET": "KARABAĞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "İNCİRLİOVA",
        "STREET": "KARAGÖZLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "İNCİRLİOVA",
        "STREET": "KURTULUŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "İNCİRLİOVA",
        "STREET": "KÖPRÜOVA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "İNCİRLİOVA",
        "STREET": "OSMANBÜKÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "İNCİRLİOVA",
        "STREET": "PALAMUT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "İNCİRLİOVA",
        "STREET": "SANDIKLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "İNCİRLİOVA",
        "STREET": "SINIRTEKE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "İNCİRLİOVA",
        "STREET": "YAZIDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "İNCİRLİOVA",
        "STREET": "ZAFER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "İNCİRLİOVA",
        "STREET": "İKİZDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "İNCİRLİOVA",
        "STREET": "İSAFAKILAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "İNCİRLİOVA",
        "STREET": "İSTİKLAL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AYDIN",
        "DISTRICT": "İNCİRLİOVA",
        "STREET": "ŞİRİNDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "MERKEZ",
        "STREET": "100.YIL MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "MERKEZ",
        "STREET": "ABİDE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "MERKEZ",
        "STREET": "AHMETBEY KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "MERKEZ",
        "STREET": "AKBULGUR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "MERKEZ",
        "STREET": "AKÇAY KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "MERKEZ",
        "STREET": "ALPASLAN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "MERKEZ",
        "STREET": "ANAKAYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "MERKEZ",
        "STREET": "ARAKONAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "MERKEZ",
        "STREET": "ASLANGAZİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "MERKEZ",
        "STREET": "AĞILBAŞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "MERKEZ",
        "STREET": "AŞAĞIAĞADEVE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "MERKEZ",
        "STREET": "AŞAĞIDÜRMELİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "MERKEZ",
        "STREET": "AŞAĞIKENT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "MERKEZ",
        "STREET": "AŞAĞIKÜPKIRAN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "MERKEZ",
        "STREET": "AŞAĞIPAMUKTAŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "MERKEZ",
        "STREET": "AŞAĞISAKLICA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "MERKEZ",
        "STREET": "AŞAĞIYOLDÜZÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "MERKEZ",
        "STREET": "AŞKALE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "MERKEZ",
        "STREET": "BADILLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "MERKEZ",
        "STREET": "BAHÇELİEVLER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "MERKEZ",
        "STREET": "BALIKSU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "MERKEZ",
        "STREET": "BALKAYNAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "MERKEZ",
        "STREET": "BALLIBOSTAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "MERKEZ",
        "STREET": "BALOLUK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "MERKEZ",
        "STREET": "BAŞKENT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "MERKEZ",
        "STREET": "BAŞÇAVUŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "MERKEZ",
        "STREET": "BEZİRHANE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "MERKEZ",
        "STREET": "BEŞBULAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "MERKEZ",
        "STREET": "BEŞİKTEPE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "MERKEZ",
        "STREET": "BOZTOPRAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "MERKEZ",
        "STREET": "BÖLÜKBAŞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "MERKEZ",
        "STREET": "CUMAÇAY KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "MERKEZ",
        "STREET": "CUMHURİYET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "MERKEZ",
        "STREET": "DEDEMAKSUT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "MERKEZ",
        "STREET": "DOĞUTEPE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "MERKEZ",
        "STREET": "DUMANLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "MERKEZ",
        "STREET": "DÖNERDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "MERKEZ",
        "STREET": "ELİAÇIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "MERKEZ",
        "STREET": "ESENKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "MERKEZ",
        "STREET": "ESKİHARMAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "MERKEZ",
        "STREET": "EĞRİBELEN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "MERKEZ",
        "STREET": "FATİH MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "MERKEZ",
        "STREET": "FEVZİ ÇAKMAK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "MERKEZ",
        "STREET": "FIRAT MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "MERKEZ",
        "STREET": "GAZİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "MERKEZ",
        "STREET": "GEÇİTALAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "MERKEZ",
        "STREET": "GÜMÜŞYAZI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "MERKEZ",
        "STREET": "GÜNEYSU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "MERKEZ",
        "STREET": "GÜVENDİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "MERKEZ",
        "STREET": "GÜVENLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "MERKEZ",
        "STREET": "HACISEFER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "MERKEZ",
        "STREET": "HANOBA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "MERKEZ",
        "STREET": "HIDIR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "MERKEZ",
        "STREET": "HÜRRİYET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "MERKEZ",
        "STREET": "KALENDER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "MERKEZ",
        "STREET": "KAPALI CEZA İNFAZ KURUMU",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "MERKEZ",
        "STREET": "KARASU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "MERKEZ",
        "STREET": "KAVACIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "MERKEZ",
        "STREET": "KAVAKKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "MERKEZ",
        "STREET": "KAYABEY KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "MERKEZ",
        "STREET": "KAZIM KARABEKİR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "MERKEZ",
        "STREET": "KAZLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "MERKEZ",
        "STREET": "KOCATAŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "MERKEZ",
        "STREET": "KONUKTEPE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "MERKEZ",
        "STREET": "KOVANCIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "MERKEZ",
        "STREET": "KUMLUGEÇİT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "MERKEZ",
        "STREET": "KURTULUŞ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "MERKEZ",
        "STREET": "LEYLEK PINAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "MERKEZ",
        "STREET": "MEHMET AKİF ERSOY MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "MERKEZ",
        "STREET": "MOLLAALİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "MERKEZ",
        "STREET": "MOLLAOSMAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "MERKEZ",
        "STREET": "MURAT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "MERKEZ",
        "STREET": "MURAT MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "MERKEZ",
        "STREET": "MURATHAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "MERKEZ",
        "STREET": "ORTAKENT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "MERKEZ",
        "STREET": "ORTAYOKUŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "MERKEZ",
        "STREET": "OTLUBAYIR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "MERKEZ",
        "STREET": "OZANLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "MERKEZ",
        "STREET": "OĞLAKLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "MERKEZ",
        "STREET": "SABUNCU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "MERKEZ",
        "STREET": "SARICA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "MERKEZ",
        "STREET": "SARIDOĞAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "MERKEZ",
        "STREET": "SARIHARMAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "MERKEZ",
        "STREET": "SARITAŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "MERKEZ",
        "STREET": "SAĞIRTAŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "MERKEZ",
        "STREET": "SITKIYE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "MERKEZ",
        "STREET": "SOĞANCUMAÇAY KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "MERKEZ",
        "STREET": "SOĞANKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "MERKEZ",
        "STREET": "SUÇATAĞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "MERKEZ",
        "STREET": "SÖĞÜTLÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "MERKEZ",
        "STREET": "TAYPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "MERKEZ",
        "STREET": "TAŞTEKNE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "MERKEZ",
        "STREET": "TELLİSIRT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "MERKEZ",
        "STREET": "TEZEREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "MERKEZ",
        "STREET": "UZUNVELİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "MERKEZ",
        "STREET": "UÇARKAYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "MERKEZ",
        "STREET": "YAKINCA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "MERKEZ",
        "STREET": "YALNIZKONAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "MERKEZ",
        "STREET": "YAVUZ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "MERKEZ",
        "STREET": "YAYLAKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "MERKEZ",
        "STREET": "YAZICI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "MERKEZ",
        "STREET": "YAZILI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "MERKEZ",
        "STREET": "YIĞINTEPE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "MERKEZ",
        "STREET": "YOLLUYAZI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "MERKEZ",
        "STREET": "YOLUGÜZEL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "MERKEZ",
        "STREET": "YONCALI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "MERKEZ",
        "STREET": "YORGUNSÖĞÜT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "MERKEZ",
        "STREET": "YUKARIAĞADEVE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "MERKEZ",
        "STREET": "YUKARIDÜRMELİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "MERKEZ",
        "STREET": "YUKARIKÜPKIRAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "MERKEZ",
        "STREET": "YUKARIPAMUKTAŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "MERKEZ",
        "STREET": "YUKARISAKLICA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "MERKEZ",
        "STREET": "YUKARIYOLDÜZÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "MERKEZ",
        "STREET": "YURTPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "MERKEZ",
        "STREET": "ZİYARET KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇAKIROBA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇAMURLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇATALİPAŞA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇAYIRKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇOBANBEYİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇUKURALAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇUKURÇAYIR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "MERKEZ",
        "STREET": "ÖZBAŞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "MERKEZ",
        "STREET": "ÖZVEREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "DOĞUBAYAZIT",
        "STREET": "ABDİGÖR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "DOĞUBAYAZIT",
        "STREET": "AHMEDİHANİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "DOĞUBAYAZIT",
        "STREET": "AKTARLA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "DOĞUBAYAZIT",
        "STREET": "AKTUĞLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "DOĞUBAYAZIT",
        "STREET": "ALINTEPE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "DOĞUBAYAZIT",
        "STREET": "ATABAKAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "DOĞUBAYAZIT",
        "STREET": "AYRANCI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "DOĞUBAYAZIT",
        "STREET": "AŞAĞITAVLA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "DOĞUBAYAZIT",
        "STREET": "BARDAKLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "DOĞUBAYAZIT",
        "STREET": "BARINDI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "DOĞUBAYAZIT",
        "STREET": "BARIŞ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "DOĞUBAYAZIT",
        "STREET": "BAŞKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "DOĞUBAYAZIT",
        "STREET": "BEREKET KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "DOĞUBAYAZIT",
        "STREET": "BESLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "DOĞUBAYAZIT",
        "STREET": "BEZİRHANE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "DOĞUBAYAZIT",
        "STREET": "BOZKURT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "DOĞUBAYAZIT",
        "STREET": "BOZYAYLA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "DOĞUBAYAZIT",
        "STREET": "BULAKBAŞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "DOĞUBAYAZIT",
        "STREET": "BUYURETTİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "DOĞUBAYAZIT",
        "STREET": "BÖLÜCEK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "DOĞUBAYAZIT",
        "STREET": "BÜYÜK AĞRI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "DOĞUBAYAZIT",
        "STREET": "BİNKAYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "DOĞUBAYAZIT",
        "STREET": "DALBAHÇE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "DOĞUBAYAZIT",
        "STREET": "DAĞDELEN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "DOĞUBAYAZIT",
        "STREET": "DEMİRTEPE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "DOĞUBAYAZIT",
        "STREET": "DOLAKLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "DOĞUBAYAZIT",
        "STREET": "DOSTALİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "DOĞUBAYAZIT",
        "STREET": "DOĞUBAYAZIT T TİPİ KAPALI CEZAEVİ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "DOĞUBAYAZIT",
        "STREET": "ESKİSU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "DOĞUBAYAZIT",
        "STREET": "ESNEMEZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "DOĞUBAYAZIT",
        "STREET": "GÖKÇEKAYNAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "DOĞUBAYAZIT",
        "STREET": "GÖLLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "DOĞUBAYAZIT",
        "STREET": "GÖLYÜZÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "DOĞUBAYAZIT",
        "STREET": "GÖZÜKARA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "DOĞUBAYAZIT",
        "STREET": "GÜLLÜCE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "DOĞUBAYAZIT",
        "STREET": "GÜLTEPE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "DOĞUBAYAZIT",
        "STREET": "GÜNGÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "DOĞUBAYAZIT",
        "STREET": "GÜNYOLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "DOĞUBAYAZIT",
        "STREET": "GÜRBULAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "DOĞUBAYAZIT",
        "STREET": "HALAÇ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "DOĞUBAYAZIT",
        "STREET": "HASANKEYF MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "DOĞUBAYAZIT",
        "STREET": "HÜRRİYET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "DOĞUBAYAZIT",
        "STREET": "KALECİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "DOĞUBAYAZIT",
        "STREET": "KARABULAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "DOĞUBAYAZIT",
        "STREET": "KARABURUN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "DOĞUBAYAZIT",
        "STREET": "KARACA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "DOĞUBAYAZIT",
        "STREET": "KARAKENT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "DOĞUBAYAZIT",
        "STREET": "KARAŞEYH KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "DOĞUBAYAZIT",
        "STREET": "KARGAKONMAZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "DOĞUBAYAZIT",
        "STREET": "KAZAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "DOĞUBAYAZIT",
        "STREET": "KIZILKAYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "DOĞUBAYAZIT",
        "STREET": "KOÇKIRAN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "DOĞUBAYAZIT",
        "STREET": "KUCAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "DOĞUBAYAZIT",
        "STREET": "KUTLUBULAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "DOĞUBAYAZIT",
        "STREET": "MELİKŞAH KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "DOĞUBAYAZIT",
        "STREET": "MESCİTKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "DOĞUBAYAZIT",
        "STREET": "ORTADİREK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "DOĞUBAYAZIT",
        "STREET": "ORTAKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "DOĞUBAYAZIT",
        "STREET": "PULLUTARLA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "DOĞUBAYAZIT",
        "STREET": "SANAYİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "DOĞUBAYAZIT",
        "STREET": "SARIBIYIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "DOĞUBAYAZIT",
        "STREET": "SARIGÜL MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "DOĞUBAYAZIT",
        "STREET": "SARIÇAVUŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "DOĞUBAYAZIT",
        "STREET": "SAZOBA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "DOĞUBAYAZIT",
        "STREET": "SAĞDIÇ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "DOĞUBAYAZIT",
        "STREET": "SAĞLIKSUYU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "DOĞUBAYAZIT",
        "STREET": "SESLİTAŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "DOĞUBAYAZIT",
        "STREET": "SOMKAYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "DOĞUBAYAZIT",
        "STREET": "SUBEŞİĞİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "DOĞUBAYAZIT",
        "STREET": "SULUÇEM KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "DOĞUBAYAZIT",
        "STREET": "TANIKTEPE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "DOĞUBAYAZIT",
        "STREET": "TELÇEKER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "DOĞUBAYAZIT",
        "STREET": "TOPÇATAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "DOĞUBAYAZIT",
        "STREET": "TUTAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "DOĞUBAYAZIT",
        "STREET": "TUTUMLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "DOĞUBAYAZIT",
        "STREET": "ULUYOL MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "DOĞUBAYAZIT",
        "STREET": "UZUNYAZI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "DOĞUBAYAZIT",
        "STREET": "VATAN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "DOĞUBAYAZIT",
        "STREET": "YALINSAZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "DOĞUBAYAZIT",
        "STREET": "YANOBA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "DOĞUBAYAZIT",
        "STREET": "YAYGINYURT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "DOĞUBAYAZIT",
        "STREET": "YAĞMURDÜŞEN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "DOĞUBAYAZIT",
        "STREET": "YENİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "DOĞUBAYAZIT",
        "STREET": "YENİHARMAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "DOĞUBAYAZIT",
        "STREET": "YILANLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "DOĞUBAYAZIT",
        "STREET": "YIĞINÇAL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "DOĞUBAYAZIT",
        "STREET": "YUKARITAVLA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "DOĞUBAYAZIT",
        "STREET": "YİĞİTYATAĞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "DOĞUBAYAZIT",
        "STREET": "ÇALIKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "DOĞUBAYAZIT",
        "STREET": "ÇETENLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "DOĞUBAYAZIT",
        "STREET": "ÇÖMÇELİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "DOĞUBAYAZIT",
        "STREET": "ÇİFTEPINAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "DOĞUBAYAZIT",
        "STREET": "ÇİFTLİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "DOĞUBAYAZIT",
        "STREET": "ÖRMELİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "DOĞUBAYAZIT",
        "STREET": "ÖRTÜLÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "DOĞUBAYAZIT",
        "STREET": "ÜZENGİLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "DOĞUBAYAZIT",
        "STREET": "ÜÇGÖZE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "DOĞUBAYAZIT",
        "STREET": "ÜÇMURAT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "DOĞUBAYAZIT",
        "STREET": "İNCESU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "DOĞUBAYAZIT",
        "STREET": "İSHAKPAŞA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "DİYADİN",
        "STREET": "14 NİSAN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "DİYADİN",
        "STREET": "AKYOLAÇ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "DİYADİN",
        "STREET": "AKÇEVRE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "DİYADİN",
        "STREET": "ALTINKİLİT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "DİYADİN",
        "STREET": "ATADAMI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "DİYADİN",
        "STREET": "ATATÜRK  MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "DİYADİN",
        "STREET": "ATAYOLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "DİYADİN",
        "STREET": "AŞAĞIAKPAZAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "DİYADİN",
        "STREET": "AŞAĞIDALÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "DİYADİN",
        "STREET": "AŞAĞIKARDEŞLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "DİYADİN",
        "STREET": "AŞAĞITÜTEK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "DİYADİN",
        "STREET": "BATIBEYLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "DİYADİN",
        "STREET": "BOYALAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "DİYADİN",
        "STREET": "BUDAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "DİYADİN",
        "STREET": "BURGULU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "DİYADİN",
        "STREET": "BÜVETLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "DİYADİN",
        "STREET": "DAVUT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "DİYADİN",
        "STREET": "DEDEBULAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "DİYADİN",
        "STREET": "DELİHASAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "DİYADİN",
        "STREET": "DOKUZTAŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "DİYADİN",
        "STREET": "DİBEKLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "DİYADİN",
        "STREET": "FATİH MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "DİYADİN",
        "STREET": "GEDİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "DİYADİN",
        "STREET": "GÖL  MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "DİYADİN",
        "STREET": "GÖZÜPEK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "DİYADİN",
        "STREET": "GÜNBULDU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "DİYADİN",
        "STREET": "HACIHALİT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "DİYADİN",
        "STREET": "HEYBELİYURT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "DİYADİN",
        "STREET": "KAPANCA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "DİYADİN",
        "STREET": "KARAPAZAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "DİYADİN",
        "STREET": "KARATAŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "DİYADİN",
        "STREET": "KETENPINAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "DİYADİN",
        "STREET": "KOCAÇOBAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "DİYADİN",
        "STREET": "KOTANCI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "DİYADİN",
        "STREET": "KUŞBURNU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "DİYADİN",
        "STREET": "KUŞLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "DİYADİN",
        "STREET": "MOLLAKARA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "DİYADİN",
        "STREET": "MURAT MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "DİYADİN",
        "STREET": "MUTLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "DİYADİN",
        "STREET": "OMUZBAŞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "DİYADİN",
        "STREET": "OĞULOBA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "DİYADİN",
        "STREET": "PİRALİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "DİYADİN",
        "STREET": "RAHMANKULU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "DİYADİN",
        "STREET": "SATICILAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "DİYADİN",
        "STREET": "SOĞUKSU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "DİYADİN",
        "STREET": "SÜRENKÖK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "DİYADİN",
        "STREET": "SÜRMELİKOÇ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "DİYADİN",
        "STREET": "TAZEKENT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "DİYADİN",
        "STREET": "TAŞBASAMAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "DİYADİN",
        "STREET": "TAŞKESEN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "DİYADİN",
        "STREET": "TOKLUCAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "DİYADİN",
        "STREET": "ULUKENT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "DİYADİN",
        "STREET": "UYSALLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "DİYADİN",
        "STREET": "YANIKÇUKUR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "DİYADİN",
        "STREET": "YENİÇADIR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "DİYADİN",
        "STREET": "YEŞİLDURAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "DİYADİN",
        "STREET": "YILDIRIM KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "DİYADİN",
        "STREET": "YILDIZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "DİYADİN",
        "STREET": "YOLCUPINARI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "DİYADİN",
        "STREET": "YUKARIAKPAZAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "DİYADİN",
        "STREET": "YUKARIDALÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "DİYADİN",
        "STREET": "YUKARITÜTEK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "DİYADİN",
        "STREET": "YUVA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "DİYADİN",
        "STREET": "YÖRÜKATLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "DİYADİN",
        "STREET": "İSAAĞA  MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "DİYADİN",
        "STREET": "İSAAĞA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "DİYADİN",
        "STREET": "ŞAHİNŞAH KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "DİYADİN",
        "STREET": "ŞEKERBULAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "ELEŞKİRT",
        "STREET": "ABDİKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "ELEŞKİRT",
        "STREET": "AKYUMAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "ELEŞKİRT",
        "STREET": "ALAGÜN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "ELEŞKİRT",
        "STREET": "ALKUŞAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "ELEŞKİRT",
        "STREET": "ARİFBEY KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "ELEŞKİRT",
        "STREET": "AYDOĞDU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "ELEŞKİRT",
        "STREET": "AŞAĞIKOPUZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "ELEŞKİRT",
        "STREET": "DALKILIÇ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "ELEŞKİRT",
        "STREET": "DEĞİRMENOLUĞU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "ELEŞKİRT",
        "STREET": "DOLUTAŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "ELEŞKİRT",
        "STREET": "DÜZAĞIL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "ELEŞKİRT",
        "STREET": "DÜZYAYLA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "ELEŞKİRT",
        "STREET": "ERGÖZÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "ELEŞKİRT",
        "STREET": "ESENTEPE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "ELEŞKİRT",
        "STREET": "FATİH MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "ELEŞKİRT",
        "STREET": "GONCALI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "ELEŞKİRT",
        "STREET": "GÖZAYDIN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "ELEŞKİRT",
        "STREET": "GÜNEYKAYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "ELEŞKİRT",
        "STREET": "GÜVENCE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "ELEŞKİRT",
        "STREET": "HASANPINARI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "ELEŞKİRT",
        "STREET": "HAYDAROĞLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "ELEŞKİRT",
        "STREET": "HAYRANGÖL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "ELEŞKİRT",
        "STREET": "HÜRRİYET KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "ELEŞKİRT",
        "STREET": "HÜRRİYET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "ELEŞKİRT",
        "STREET": "KANATGEREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "ELEŞKİRT",
        "STREET": "KARABACAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "ELEŞKİRT",
        "STREET": "KARŞIYAKA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "ELEŞKİRT",
        "STREET": "KAYAYOLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "ELEŞKİRT",
        "STREET": "KAZIM KARABEKİR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "ELEŞKİRT",
        "STREET": "KOKULUPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "ELEŞKİRT",
        "STREET": "KÖRPEÇAYIR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "ELEŞKİRT",
        "STREET": "LOZAN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "ELEŞKİRT",
        "STREET": "MOLLAHÜSEYİN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "ELEŞKİRT",
        "STREET": "MOLLASÜLEYMAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "ELEŞKİRT",
        "STREET": "MÜFTÜSELİM MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "ELEŞKİRT",
        "STREET": "PALAKÇAYIRI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "ELEŞKİRT",
        "STREET": "PİRABAT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "ELEŞKİRT",
        "STREET": "RAMAZAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "ELEŞKİRT",
        "STREET": "SADAKLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "ELEŞKİRT",
        "STREET": "SALKIMLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "ELEŞKİRT",
        "STREET": "SARIKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "ELEŞKİRT",
        "STREET": "SULTANABAT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "ELEŞKİRT",
        "STREET": "SÖBETAŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "ELEŞKİRT",
        "STREET": "SÜZGEÇLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "ELEŞKİRT",
        "STREET": "TAHİR-AYDINTEPE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "ELEŞKİRT",
        "STREET": "TAHİR-KARŞIYAKA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "ELEŞKİRT",
        "STREET": "TAHİR-YEŞİL MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "ELEŞKİRT",
        "STREET": "TOPRAKKALE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "ELEŞKİRT",
        "STREET": "TÜRKELİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "ELEŞKİRT",
        "STREET": "ULUDAL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "ELEŞKİRT",
        "STREET": "UZUNYAZI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "ELEŞKİRT",
        "STREET": "YANIKDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "ELEŞKİRT",
        "STREET": "YAYLADÜZÜ-AŞAĞICİHANBEY MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "ELEŞKİRT",
        "STREET": "YAYLADÜZÜ-CUMHURİYET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "ELEŞKİRT",
        "STREET": "YAYLADÜZÜ-DEĞİRMENGEÇİDİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "ELEŞKİRT",
        "STREET": "YAYLADÜZÜ-GÖKÇAYIR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "ELEŞKİRT",
        "STREET": "YAYLADÜZÜ-KARŞIYAKA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "ELEŞKİRT",
        "STREET": "YAĞMURLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "ELEŞKİRT",
        "STREET": "YELKESEN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "ELEŞKİRT",
        "STREET": "YEŞİLOVA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "ELEŞKİRT",
        "STREET": "YIĞINTAŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "ELEŞKİRT",
        "STREET": "YUKARIKOPUZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "ELEŞKİRT",
        "STREET": "YÜCEKAPI-ESENTEPE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "ELEŞKİRT",
        "STREET": "YÜCEKAPI-OKLAVALI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "ELEŞKİRT",
        "STREET": "YÜCEKAPI-YEŞİLYURT MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "ELEŞKİRT",
        "STREET": "YÜCEKAPI-ŞEHİT ENGİN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "ELEŞKİRT",
        "STREET": "ÇATALPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "ELEŞKİRT",
        "STREET": "ÇATKÖSEDAĞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "ELEŞKİRT",
        "STREET": "ÇETİNSU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "ELEŞKİRT",
        "STREET": "ÇİFTEPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "ELEŞKİRT",
        "STREET": "ÖZTOPRAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "ELEŞKİRT",
        "STREET": "İKİZGEÇE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "ELEŞKİRT",
        "STREET": "İKİZGÖL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "ELEŞKİRT",
        "STREET": "İNDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "ELEŞKİRT",
        "STREET": "İSTİKLAL MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "HAMUR",
        "STREET": "ABDİÇIKMAZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "HAMUR",
        "STREET": "ADIMOVA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "HAMUR",
        "STREET": "AKYURT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "HAMUR",
        "STREET": "ALAKOYUN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "HAMUR",
        "STREET": "AYVACIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "HAMUR",
        "STREET": "AŞAĞIALADAĞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "HAMUR",
        "STREET": "AŞAĞIDEREDİBİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "HAMUR",
        "STREET": "AŞAĞIGÖZLÜCE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "HAMUR",
        "STREET": "AŞAĞIKARABAL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "HAMUR",
        "STREET": "AŞAĞIYENİGÜN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "HAMUR",
        "STREET": "BALDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "HAMUR",
        "STREET": "BEKLEMEZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "HAMUR",
        "STREET": "BUZHANE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "HAMUR",
        "STREET": "CEYLANLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "HAMUR",
        "STREET": "DANAKIRAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "HAMUR",
        "STREET": "DEMİRKAPI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "HAMUR",
        "STREET": "EKİNCİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "HAMUR",
        "STREET": "ERDOĞAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "HAMUR",
        "STREET": "ESENÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "HAMUR",
        "STREET": "GÜLTEPE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "HAMUR",
        "STREET": "GÜMÜŞKUŞAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "HAMUR",
        "STREET": "KALE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "HAMUR",
        "STREET": "KAMIŞLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "HAMUR",
        "STREET": "KANDİLDAĞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "HAMUR",
        "STREET": "KARADOĞU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "HAMUR",
        "STREET": "KARAKAZAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "HAMUR",
        "STREET": "KARASEYİTALİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "HAMUR",
        "STREET": "KARLICA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "HAMUR",
        "STREET": "KAYNAKLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "HAMUR",
        "STREET": "KAÇMAZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "HAMUR",
        "STREET": "KILIÇ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "HAMUR",
        "STREET": "KÖŞK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "HAMUR",
        "STREET": "KÜMBET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "HAMUR",
        "STREET": "NALLIKONAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "HAMUR",
        "STREET": "SARIBUĞDAY KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "HAMUR",
        "STREET": "SESLİDOĞAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "HAMUR",
        "STREET": "SEYİTHANBEY KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "HAMUR",
        "STREET": "SOĞANLITEPE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "HAMUR",
        "STREET": "SÜLEYMANKÜMBET KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "HAMUR",
        "STREET": "TÜKENMEZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "HAMUR",
        "STREET": "UĞURTAŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "HAMUR",
        "STREET": "YAPILI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "HAMUR",
        "STREET": "YENİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "HAMUR",
        "STREET": "YOĞUNHİSAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "HAMUR",
        "STREET": "YUKARIALADAĞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "HAMUR",
        "STREET": "YUKARIGÖZLÜCE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "HAMUR",
        "STREET": "YUKARIYENİGÜN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "HAMUR",
        "STREET": "YUVACIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "HAMUR",
        "STREET": "ÇAĞLAYAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "HAMUR",
        "STREET": "ÖZDİREK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "PATNOS",
        "STREET": "14 NİSAN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "PATNOS",
        "STREET": "ABDULVEHAP KÜFREVİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "PATNOS",
        "STREET": "AHMED-İ HANİ MAHALLESİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "PATNOS",
        "STREET": "AKDİLEK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "PATNOS",
        "STREET": "AKTEPE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "PATNOS",
        "STREET": "AKYEMİŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "PATNOS",
        "STREET": "AKÇAÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "PATNOS",
        "STREET": "ALATAY KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "PATNOS",
        "STREET": "ALPASLAN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "PATNOS",
        "STREET": "ANDAÇLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "PATNOS",
        "STREET": "ARMUTLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "PATNOS",
        "STREET": "ATATÜRK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "PATNOS",
        "STREET": "AŞAĞIGÖÇMEZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "PATNOS",
        "STREET": "AŞAĞIKAMIŞLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "PATNOS",
        "STREET": "BADİŞAN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "PATNOS",
        "STREET": "BALTACIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "PATNOS",
        "STREET": "BAĞBAŞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "PATNOS",
        "STREET": "BAŞTARLA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "PATNOS",
        "STREET": "BOZOĞLAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "PATNOS",
        "STREET": "BUDAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "PATNOS",
        "STREET": "CAMİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "PATNOS",
        "STREET": "CUMHURİYET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "PATNOS",
        "STREET": "DAĞALAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "PATNOS",
        "STREET": "DEDELİ-AKBULUT MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "PATNOS",
        "STREET": "DEDELİ-BUDAK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "PATNOS",
        "STREET": "DEDELİ-CUMHURİYET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "PATNOS",
        "STREET": "DEDELİ-DEĞİRMEN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "PATNOS",
        "STREET": "DEDELİ-MERKEZ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "PATNOS",
        "STREET": "DEDELİ-YEŞİLOVA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "PATNOS",
        "STREET": "DEDELİ-ÇAYBAŞI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "PATNOS",
        "STREET": "DEDELİ-ÜZÜMLÜ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "PATNOS",
        "STREET": "DEMİRÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "PATNOS",
        "STREET": "DERECİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "PATNOS",
        "STREET": "DEĞİRMENDÜZÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "PATNOS",
        "STREET": "DOĞANSU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "PATNOS",
        "STREET": "DÜZCELİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "PATNOS",
        "STREET": "DİZGİNKALE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "PATNOS",
        "STREET": "EDREMİT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "PATNOS",
        "STREET": "ERGEÇLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "PATNOS",
        "STREET": "ERYILMAZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "PATNOS",
        "STREET": "ESENBEL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "PATNOS",
        "STREET": "ESKİKONAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "PATNOS",
        "STREET": "GENÇALİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "PATNOS",
        "STREET": "GÖKOĞLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "PATNOS",
        "STREET": "GÖKÇEALİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "PATNOS",
        "STREET": "GÖNLÜAÇIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "PATNOS",
        "STREET": "GÜLLÜCE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "PATNOS",
        "STREET": "GÜNBELİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "PATNOS",
        "STREET": "GÜNDÜZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "PATNOS",
        "STREET": "GÜVERCİNLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "PATNOS",
        "STREET": "HACILAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "PATNOS",
        "STREET": "HASANDOLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "PATNOS",
        "STREET": "HİSAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "PATNOS",
        "STREET": "KARATOKLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "PATNOS",
        "STREET": "KARBASAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "PATNOS",
        "STREET": "KAZANBEY KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "PATNOS",
        "STREET": "KAŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "PATNOS",
        "STREET": "KEÇELBABA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "PATNOS",
        "STREET": "KIZILTEPE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "PATNOS",
        "STREET": "KIZKAPAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "PATNOS",
        "STREET": "KONAKBEYİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "PATNOS",
        "STREET": "KOÇAKLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "PATNOS",
        "STREET": "KUCAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "PATNOS",
        "STREET": "KURUYAKA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "PATNOS",
        "STREET": "KUŞKAYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "PATNOS",
        "STREET": "KÖSELER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "PATNOS",
        "STREET": "KÜREKLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "PATNOS",
        "STREET": "MEYDANDAĞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "PATNOS",
        "STREET": "MOLLAİBRAHİM KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "PATNOS",
        "STREET": "ONBAŞILAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "PATNOS",
        "STREET": "ORTADAMLA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "PATNOS",
        "STREET": "OYACIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "PATNOS",
        "STREET": "PATNOS L TİPİ KAPALI CEZA EVİ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "PATNOS",
        "STREET": "PİRÖMER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "PATNOS",
        "STREET": "SANAYİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "PATNOS",
        "STREET": "SARIDİBEK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "PATNOS",
        "STREET": "SARISU MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "PATNOS",
        "STREET": "SAĞRICA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "PATNOS",
        "STREET": "SELAHADDİNİ EYYUBİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "PATNOS",
        "STREET": "SERHAD MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "PATNOS",
        "STREET": "SULUCA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "PATNOS",
        "STREET": "SUSUZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "PATNOS",
        "STREET": "SÜPHAN DAĞI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "PATNOS",
        "STREET": "SÜTLÜ PINAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "PATNOS",
        "STREET": "TANYELİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "PATNOS",
        "STREET": "TAŞKIN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "PATNOS",
        "STREET": "TEPELİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "PATNOS",
        "STREET": "TEPEÖNÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "PATNOS",
        "STREET": "TURGUT ÖZAL MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "PATNOS",
        "STREET": "USLUCA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "PATNOS",
        "STREET": "UZUNCA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "PATNOS",
        "STREET": "UZUNGÜN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "PATNOS",
        "STREET": "YALÇINKAYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "PATNOS",
        "STREET": "YENİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "PATNOS",
        "STREET": "YEŞİL ÇİMEN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "PATNOS",
        "STREET": "YEŞİLHİSAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "PATNOS",
        "STREET": "YEŞİLYURT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "PATNOS",
        "STREET": "YUKARIDAMLA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "PATNOS",
        "STREET": "YUKARIGÖÇMEZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "PATNOS",
        "STREET": "YUKARIKAMIŞLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "PATNOS",
        "STREET": "YUKARIKÜLECİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "PATNOS",
        "STREET": "YUNUSEMRE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "PATNOS",
        "STREET": "YURTÖVEN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "PATNOS",
        "STREET": "YÜNCÜLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "PATNOS",
        "STREET": "YÜREKVEREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "PATNOS",
        "STREET": "ZİNCİRKALE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "PATNOS",
        "STREET": "ZİREKLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "PATNOS",
        "STREET": "ZİYARET KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "PATNOS",
        "STREET": "ÇAKIRBEY KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "PATNOS",
        "STREET": "ÇAMURLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "PATNOS",
        "STREET": "ÇAPUTLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "PATNOS",
        "STREET": "ÇATMAOLUK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "PATNOS",
        "STREET": "ÇAVUŞKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "PATNOS",
        "STREET": "ÇAY MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "PATNOS",
        "STREET": "ÇUKURBAĞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "PATNOS",
        "STREET": "ÇİMENLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "PATNOS",
        "STREET": "ÇİÇEK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "PATNOS",
        "STREET": "ÖRENDİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "PATNOS",
        "STREET": "ÖZDEMİR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "PATNOS",
        "STREET": "ÜRKÜT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "PATNOS",
        "STREET": "ÜÇOYMAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "PATNOS",
        "STREET": "İNÖNÜ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "TAŞLIÇAY",
        "STREET": "15 TEMMUZ ŞEHİTLER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "TAŞLIÇAY",
        "STREET": "ADNAN MENDERES MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "TAŞLIÇAY",
        "STREET": "ALAKOÇLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "TAŞLIÇAY",
        "STREET": "ARAS KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "TAŞLIÇAY",
        "STREET": "AŞAĞIDUMANLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "TAŞLIÇAY",
        "STREET": "AŞAĞIDÜZMEYDAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "TAŞLIÇAY",
        "STREET": "AŞAĞIESEN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "TAŞLIÇAY",
        "STREET": "AŞAĞITOKLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "TAŞLIÇAY",
        "STREET": "BALÇİÇEK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "TAŞLIÇAY",
        "STREET": "BAYIRALTI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "TAŞLIÇAY",
        "STREET": "BAYRAMYAZI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "TAŞLIÇAY",
        "STREET": "BOYUNCAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "TAŞLIÇAY",
        "STREET": "CUMHURİYET  MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "TAŞLIÇAY",
        "STREET": "DÜZGÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "TAŞLIÇAY",
        "STREET": "DİLEKYAZI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "TAŞLIÇAY",
        "STREET": "GEÇİTVEREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "TAŞLIÇAY",
        "STREET": "GÖZUCU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "TAŞLIÇAY",
        "STREET": "GÜNDOĞDU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "TAŞLIÇAY",
        "STREET": "GÜNEYSÖĞÜT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "TAŞLIÇAY",
        "STREET": "HÜRRİYET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "TAŞLIÇAY",
        "STREET": "KARAGÖZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "TAŞLIÇAY",
        "STREET": "KAĞNILI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "TAŞLIÇAY",
        "STREET": "KUMLUBUCAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "TAŞLIÇAY",
        "STREET": "KUMLUCA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "TAŞLIÇAY",
        "STREET": "SAMANYOLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "TAŞLIÇAY",
        "STREET": "TANRIVERDİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "TAŞLIÇAY",
        "STREET": "TANYOLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "TAŞLIÇAY",
        "STREET": "TAŞTEKER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "TAŞLIÇAY",
        "STREET": "YANALYOL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "TAŞLIÇAY",
        "STREET": "YANKAYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "TAŞLIÇAY",
        "STREET": "YARDIMCILAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "TAŞLIÇAY",
        "STREET": "YASSIKAYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "TAŞLIÇAY",
        "STREET": "YELTEPE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "TAŞLIÇAY",
        "STREET": "YUKARIDUMANLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "TAŞLIÇAY",
        "STREET": "YUKARIDÜZMEYDAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "TAŞLIÇAY",
        "STREET": "YUKARIESEN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "TAŞLIÇAY",
        "STREET": "YUKARITAŞLIÇAY KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "TAŞLIÇAY",
        "STREET": "YUKARITOKLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "TAŞLIÇAY",
        "STREET": "ÇÖKELGE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "TAŞLIÇAY",
        "STREET": "ÇÖĞÜRLÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "TAŞLIÇAY",
        "STREET": "İKİYAMAÇ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "TUTAK",
        "STREET": "ADAKENT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "TUTAK",
        "STREET": "AHMETABAT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "TUTAK",
        "STREET": "AKYELE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "TUTAK",
        "STREET": "ALACAHAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "TUTAK",
        "STREET": "ATABİNDİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "TUTAK",
        "STREET": "ATAKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "TUTAK",
        "STREET": "AZİZLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "TUTAK",
        "STREET": "AŞAĞIKARAHALİT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "TUTAK",
        "STREET": "AŞAĞIKARGALIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "TUTAK",
        "STREET": "AŞAĞIKÖŞKKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "TUTAK",
        "STREET": "AŞAĞIKÜLECİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "TUTAK",
        "STREET": "AŞAĞIÖZDEK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "TUTAK",
        "STREET": "BAHÇE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "TUTAK",
        "STREET": "BATMIŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "TUTAK",
        "STREET": "BAYINDIR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "TUTAK",
        "STREET": "BEYDAMARLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "TUTAK",
        "STREET": "BOLAŞLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "TUTAK",
        "STREET": "BOZKAŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "TUTAK",
        "STREET": "BULUTPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "TUTAK",
        "STREET": "BURNUBULAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "TUTAK",
        "STREET": "BİNTOSUN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "TUTAK",
        "STREET": "BİŞİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "TUTAK",
        "STREET": "CAMİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "TUTAK",
        "STREET": "DALDALIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "TUTAK",
        "STREET": "DAMLAKAYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "TUTAK",
        "STREET": "DAYIPINARI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "TUTAK",
        "STREET": "DAĞLICA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "TUTAK",
        "STREET": "DEREKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "TUTAK",
        "STREET": "DORUKDİBİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "TUTAK",
        "STREET": "DOĞANGÜN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "TUTAK",
        "STREET": "DÖNERTAŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "TUTAK",
        "STREET": "DÖŞKAYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "TUTAK",
        "STREET": "DİBELEK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "TUTAK",
        "STREET": "DİKBIYIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "TUTAK",
        "STREET": "DİKME KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "TUTAK",
        "STREET": "EKİNCEK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "TUTAK",
        "STREET": "ERDAL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "TUTAK",
        "STREET": "ERGEÇİDİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "TUTAK",
        "STREET": "ESMER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "TUTAK",
        "STREET": "GEÇİMLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "TUTAK",
        "STREET": "GÜLTEPE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "TUTAK",
        "STREET": "GÜNEŞGÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "TUTAK",
        "STREET": "HACIYUSUF KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "TUTAK",
        "STREET": "KARAAĞAÇ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "TUTAK",
        "STREET": "KARACAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "TUTAK",
        "STREET": "KARAHAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "TUTAK",
        "STREET": "KARAKUYU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "TUTAK",
        "STREET": "KARŞIYAKA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "TUTAK",
        "STREET": "KAŞÖNÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "TUTAK",
        "STREET": "KESİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "TUTAK",
        "STREET": "KILIÇGEDİĞİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "TUTAK",
        "STREET": "MIZRAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "TUTAK",
        "STREET": "MOLLAHASAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "TUTAK",
        "STREET": "MURAT MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "TUTAK",
        "STREET": "OCAKBAŞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "TUTAK",
        "STREET": "ORTAYAMAÇ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "TUTAK",
        "STREET": "OTLUCA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "TUTAK",
        "STREET": "OZANPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "TUTAK",
        "STREET": "OĞLAKSUYU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "TUTAK",
        "STREET": "PALANDÖKEN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "TUTAK",
        "STREET": "PINAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "TUTAK",
        "STREET": "SARIGÖZE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "TUTAK",
        "STREET": "SORGUÇLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "TUTAK",
        "STREET": "SOĞUKPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "TUTAK",
        "STREET": "SUVAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "TUTAK",
        "STREET": "SİNCAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "TUTAK",
        "STREET": "TAŞBUDAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "TUTAK",
        "STREET": "UZUNÖZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "TUTAK",
        "STREET": "YAYIKLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "TUTAK",
        "STREET": "YENİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "TUTAK",
        "STREET": "YENİKENT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "TUTAK",
        "STREET": "YENİKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "TUTAK",
        "STREET": "YUKARIKARAHALİT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "TUTAK",
        "STREET": "YUKARIKARGALIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "TUTAK",
        "STREET": "YUKARIKÖŞK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "TUTAK",
        "STREET": "YUKARIÖZDEK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "TUTAK",
        "STREET": "ÇELEBAŞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "TUTAK",
        "STREET": "ÇIRPILI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "TUTAK",
        "STREET": "ÇOBANOBA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "TUTAK",
        "STREET": "ÇUKURKONAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "TUTAK",
        "STREET": "ÖNDÜL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "TUTAK",
        "STREET": "İKİGÖZÜM KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "TUTAK",
        "STREET": "İPEKKUŞAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "TUTAK",
        "STREET": "İSAABAT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "AĞRI",
        "DISTRICT": "TUTAK",
        "STREET": "ŞEKERBULAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "ALTIEYLÜL",
        "STREET": "1.GÜNDOĞAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "ALTIEYLÜL",
        "STREET": "2.GÜNDOĞAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "ALTIEYLÜL",
        "STREET": "AKARSU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "ALTIEYLÜL",
        "STREET": "AKÇAKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "ALTIEYLÜL",
        "STREET": "ALTI EYLÜL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "ALTIEYLÜL",
        "STREET": "ALİAĞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "ALTIEYLÜL",
        "STREET": "ASLIHAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "ALTIEYLÜL",
        "STREET": "ASLIHANTEPECİĞİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "ALTIEYLÜL",
        "STREET": "ATAKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "ALTIEYLÜL",
        "STREET": "ATKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "ALTIEYLÜL",
        "STREET": "AYNAOĞLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "ALTIEYLÜL",
        "STREET": "AYVACIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "ALTIEYLÜL",
        "STREET": "AYVATLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "ALTIEYLÜL",
        "STREET": "AYŞEBACI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "ALTIEYLÜL",
        "STREET": "BAHÇEDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "ALTIEYLÜL",
        "STREET": "BAHÇELİEVLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "ALTIEYLÜL",
        "STREET": "BALIKLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "ALTIEYLÜL",
        "STREET": "BAYAT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "ALTIEYLÜL",
        "STREET": "BAĞALAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "ALTIEYLÜL",
        "STREET": "BEREKETLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "ALTIEYLÜL",
        "STREET": "BEŞPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "ALTIEYLÜL",
        "STREET": "BOZEN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "ALTIEYLÜL",
        "STREET": "BOĞATEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "ALTIEYLÜL",
        "STREET": "BÜYÜKBOSTANCI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "ALTIEYLÜL",
        "STREET": "CİNGE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "ALTIEYLÜL",
        "STREET": "DALLIMANDIRA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "ALTIEYLÜL",
        "STREET": "DEDEBURNU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "ALTIEYLÜL",
        "STREET": "DEREKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "ALTIEYLÜL",
        "STREET": "DEREÇİFTLİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "ALTIEYLÜL",
        "STREET": "DİNKÇİLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "ALTIEYLÜL",
        "STREET": "DİŞBUDAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "ALTIEYLÜL",
        "STREET": "ERTUĞRUL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "ALTIEYLÜL",
        "STREET": "GAZİOSMANPAŞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "ALTIEYLÜL",
        "STREET": "GÖKKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "ALTIEYLÜL",
        "STREET": "GÖKÇEÖREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "ALTIEYLÜL",
        "STREET": "GÜMÜSÇESME MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "ALTIEYLÜL",
        "STREET": "HACI İLBEY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "ALTIEYLÜL",
        "STREET": "HALALCA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "ALTIEYLÜL",
        "STREET": "HASAN BASRİ ÇANTAY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "ALTIEYLÜL",
        "STREET": "KABAKLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "ALTIEYLÜL",
        "STREET": "KARABEYLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "ALTIEYLÜL",
        "STREET": "KARAKAVAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "ALTIEYLÜL",
        "STREET": "KARAKAYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "ALTIEYLÜL",
        "STREET": "KARAMAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "ALTIEYLÜL",
        "STREET": "KARAMANLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "ALTIEYLÜL",
        "STREET": "KASAPLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "ALTIEYLÜL",
        "STREET": "KESİRVEN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "ALTIEYLÜL",
        "STREET": "KILCILAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "ALTIEYLÜL",
        "STREET": "KONAKPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "ALTIEYLÜL",
        "STREET": "KOZDEREGÜVEM MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "ALTIEYLÜL",
        "STREET": "KOZÖREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "ALTIEYLÜL",
        "STREET": "KUTLUDÜĞÜN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "ALTIEYLÜL",
        "STREET": "KUYUALAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "ALTIEYLÜL",
        "STREET": "KUŞKAYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "ALTIEYLÜL",
        "STREET": "KÖSELER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "ALTIEYLÜL",
        "STREET": "KÖYLÜ KÖYÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "ALTIEYLÜL",
        "STREET": "KÜPELER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "ALTIEYLÜL",
        "STREET": "KÜRSE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "ALTIEYLÜL",
        "STREET": "KÜÇÜKBOSTANCI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "ALTIEYLÜL",
        "STREET": "KİRAZKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "ALTIEYLÜL",
        "STREET": "KİRAZPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "ALTIEYLÜL",
        "STREET": "MACARLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "ALTIEYLÜL",
        "STREET": "MERYEMDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "ALTIEYLÜL",
        "STREET": "NERGİZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "ALTIEYLÜL",
        "STREET": "ORHANLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "ALTIEYLÜL",
        "STREET": "ORTAMANDIRA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "ALTIEYLÜL",
        "STREET": "OVABAYINDIR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "ALTIEYLÜL",
        "STREET": "OVAKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "ALTIEYLÜL",
        "STREET": "PAMUKÇU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "ALTIEYLÜL",
        "STREET": "PAŞAKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "ALTIEYLÜL",
        "STREET": "PLEVNE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "ALTIEYLÜL",
        "STREET": "SARIALAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "ALTIEYLÜL",
        "STREET": "SELİMİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "ALTIEYLÜL",
        "STREET": "SIVATPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "ALTIEYLÜL",
        "STREET": "SÜTLÜCE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "ALTIEYLÜL",
        "STREET": "TAYYİPLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "ALTIEYLÜL",
        "STREET": "TAŞKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "ALTIEYLÜL",
        "STREET": "TAŞPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "ALTIEYLÜL",
        "STREET": "TURNALAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "ALTIEYLÜL",
        "STREET": "TÜRKALİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "ALTIEYLÜL",
        "STREET": "YAKUPKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "ALTIEYLÜL",
        "STREET": "YENİCE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "ALTIEYLÜL",
        "STREET": "YEŞİLLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "ALTIEYLÜL",
        "STREET": "YEŞİLYURT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "ALTIEYLÜL",
        "STREET": "YILDIZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "ALTIEYLÜL",
        "STREET": "ÇAKILLIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "ALTIEYLÜL",
        "STREET": "ÇAMKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "ALTIEYLÜL",
        "STREET": "ÇANDIR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "ALTIEYLÜL",
        "STREET": "ÇAYIRHİSAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "ALTIEYLÜL",
        "STREET": "ÇINARLIDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "ALTIEYLÜL",
        "STREET": "ÇUKURHÜSEYİN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "ALTIEYLÜL",
        "STREET": "ÇİFTLİKKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "ALTIEYLÜL",
        "STREET": "ÇİFTÇİDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "ALTIEYLÜL",
        "STREET": "İNKAYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "AYVALIK",
        "STREET": "150 EVLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "AYVALIK",
        "STREET": "AKÇAPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "AYVALIK",
        "STREET": "ALTINOVA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "AYVALIK",
        "STREET": "ALİ ÇETİNKAYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "AYVALIK",
        "STREET": "BAĞYÜZÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "AYVALIK",
        "STREET": "BEŞİKTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "AYVALIK",
        "STREET": "BULUTÇEŞME MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "AYVALIK",
        "STREET": "FETHİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "AYVALIK",
        "STREET": "FEVZİPAŞA-VEHBİBEY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "AYVALIK",
        "STREET": "GAZİ KEMAL PAŞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "AYVALIK",
        "STREET": "HACIVELİLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "AYVALIK",
        "STREET": "HAMDİBEY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "AYVALIK",
        "STREET": "HAYRETTİNPAŞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "AYVALIK",
        "STREET": "KARAAYIT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "AYVALIK",
        "STREET": "KAZIM KARABEKİR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "AYVALIK",
        "STREET": "KIRCALAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "AYVALIK",
        "STREET": "KÜÇÜKKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "AYVALIK",
        "STREET": "MURATELİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "AYVALIK",
        "STREET": "MUTLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "AYVALIK",
        "STREET": "MİTHATPAŞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "AYVALIK",
        "STREET": "NAMIK KEMAL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "AYVALIK",
        "STREET": "ODABURNU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "AYVALIK",
        "STREET": "SAHİL KENT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "AYVALIK",
        "STREET": "SAKARYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "AYVALIK",
        "STREET": "SEFA ÇAMLIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "AYVALIK",
        "STREET": "TIFILLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "AYVALIK",
        "STREET": "TÜRKÖZÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "AYVALIK",
        "STREET": "YENİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "AYVALIK",
        "STREET": "YENİKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "AYVALIK",
        "STREET": "ZEKİBEY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "AYVALIK",
        "STREET": "ÇAKMAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "AYVALIK",
        "STREET": "ÇAMOBA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "AYVALIK",
        "STREET": "ÜÇKABAAĞAÇ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "AYVALIK",
        "STREET": "İSMETPAŞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "BALYA",
        "STREET": "AKBAŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "BALYA",
        "STREET": "ALİDEMİRCİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "BALYA",
        "STREET": "BENGİLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "BALYA",
        "STREET": "DANİŞMENT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "BALYA",
        "STREET": "DEREKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "BALYA",
        "STREET": "DEĞİRMENDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "BALYA",
        "STREET": "DOĞANLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "BALYA",
        "STREET": "ENVERPAŞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "BALYA",
        "STREET": "FARSAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "BALYA",
        "STREET": "GÖKMUSA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "BALYA",
        "STREET": "GÖKTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "BALYA",
        "STREET": "GÖLOBA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "BALYA",
        "STREET": "HABİPLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "BALYA",
        "STREET": "HACIHÜSEYİN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "BALYA",
        "STREET": "HAVUTBAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "BALYA",
        "STREET": "ILICA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "BALYA",
        "STREET": "KADIKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "BALYA",
        "STREET": "KARACAHİSAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "BALYA",
        "STREET": "KARLIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "BALYA",
        "STREET": "KAVAKALANI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "BALYA",
        "STREET": "KAYALAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "BALYA",
        "STREET": "KAYAPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "BALYA",
        "STREET": "KAŞIKÇI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "BALYA",
        "STREET": "KOCA CAMİİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "BALYA",
        "STREET": "KOCABÜK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "BALYA",
        "STREET": "KOYUNERİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "BALYA",
        "STREET": "MANCILIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "BALYA",
        "STREET": "MEDRESE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "BALYA",
        "STREET": "MÜSTECAP MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "BALYA",
        "STREET": "NARLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "BALYA",
        "STREET": "ORHANLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "BALYA",
        "STREET": "PATLAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "BALYA",
        "STREET": "SEMİZKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "BALYA",
        "STREET": "SÖBÜCEALAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "BALYA",
        "STREET": "YARIŞALANI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "BALYA",
        "STREET": "YAYLACIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "BALYA",
        "STREET": "YAZLIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "BALYA",
        "STREET": "YENİKAVAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "BALYA",
        "STREET": "ÇAKALLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "BALYA",
        "STREET": "ÇALOVA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "BALYA",
        "STREET": "ÇAMAVŞAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "BALYA",
        "STREET": "ÇAMUCU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "BALYA",
        "STREET": "ÇARMIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "BALYA",
        "STREET": "ÇUKURCAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "BALYA",
        "STREET": "ÇİĞDEM MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "BALYA",
        "STREET": "ÖRENKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "BANDIRMA",
        "STREET": "100.YIL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "BANDIRMA",
        "STREET": "17 EYLÜL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "BANDIRMA",
        "STREET": "600 EVLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "BANDIRMA",
        "STREET": "AKSAKAL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "BANDIRMA",
        "STREET": "AKÇAPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "BANDIRMA",
        "STREET": "AYYILDIZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "BANDIRMA",
        "STREET": "BANDIRMA 1 NOLU T TİPİ KAPALI CEZAEVİ",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "BANDIRMA",
        "STREET": "BANDIRMA 2 NOLU T TİPİ KAPALI CEZAEVİ",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "BANDIRMA",
        "STREET": "BANDIRMA M TİPİ KAPALI CEZAEVİ",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "BANDIRMA",
        "STREET": "BENTBAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "BANDIRMA",
        "STREET": "BEREKETLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "BANDIRMA",
        "STREET": "BEYKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "BANDIRMA",
        "STREET": "BEZİRCİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "BANDIRMA",
        "STREET": "DEDEOBA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "BANDIRMA",
        "STREET": "DERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "BANDIRMA",
        "STREET": "DOĞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "BANDIRMA",
        "STREET": "DOĞANPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "BANDIRMA",
        "STREET": "DOĞRUCA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "BANDIRMA",
        "STREET": "DUTLİMAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "BANDIRMA",
        "STREET": "EDİNCİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "BANDIRMA",
        "STREET": "EMRE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "BANDIRMA",
        "STREET": "ERGİLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "BANDIRMA",
        "STREET": "ERİKLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "BANDIRMA",
        "STREET": "ESKİZİRAATLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "BANDIRMA",
        "STREET": "GÖLYAKA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "BANDIRMA",
        "STREET": "GÜNAYDIN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "BANDIRMA",
        "STREET": "HACI YUSUF MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "BANDIRMA",
        "STREET": "HAYDAR ÇAVUŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "BANDIRMA",
        "STREET": "HIDIRKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "BANDIRMA",
        "STREET": "KARAÇALILIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "BANDIRMA",
        "STREET": "KAYACIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "BANDIRMA",
        "STREET": "KUŞCENNETİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "BANDIRMA",
        "STREET": "KÜLEFLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "BANDIRMA",
        "STREET": "KİRAZLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "BANDIRMA",
        "STREET": "LEVENT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "BANDIRMA",
        "STREET": "MAHBUBELER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "BANDIRMA",
        "STREET": "MİSAKÇA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "BANDIRMA",
        "STREET": "ORHANİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "BANDIRMA",
        "STREET": "PAŞABAYIR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "BANDIRMA",
        "STREET": "PAŞAKENT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "BANDIRMA",
        "STREET": "PAŞAKONAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "BANDIRMA",
        "STREET": "PAŞAMESCİT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "BANDIRMA",
        "STREET": "SUNULLAH MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "BANDIRMA",
        "STREET": "YENİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "BANDIRMA",
        "STREET": "YENİCE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "BANDIRMA",
        "STREET": "YENİSIĞIRCI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "BANDIRMA",
        "STREET": "YENİYENİCE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "BANDIRMA",
        "STREET": "YENİZİRAATLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "BANDIRMA",
        "STREET": "YEŞİLÇOMLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "BANDIRMA",
        "STREET": "ÇAKILKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "BANDIRMA",
        "STREET": "ÇALIŞKANLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "BANDIRMA",
        "STREET": "ÇARIKKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "BANDIRMA",
        "STREET": "ÇEPNİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "BANDIRMA",
        "STREET": "ÇINARLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "BANDIRMA",
        "STREET": "ÇİNGE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "BANDIRMA",
        "STREET": "ÖMERLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "BANDIRMA",
        "STREET": "İHSANİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "BANDIRMA",
        "STREET": "ŞİRİNÇAVUŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "BURHANİYE",
        "STREET": "ALİ ÇETİNKAYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "BURHANİYE",
        "STREET": "AVUNDUK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "BURHANİYE",
        "STREET": "AĞACIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "BURHANİYE",
        "STREET": "BAHADINLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "BURHANİYE",
        "STREET": "BAHÇELİEVLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "BURHANİYE",
        "STREET": "BURHANİYE T TİPİ KAPALI VE AÇIK CEZA İNFAZ KURUMU",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "BURHANİYE",
        "STREET": "BÖREZLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "BURHANİYE",
        "STREET": "CUMHURİYET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "BURHANİYE",
        "STREET": "DAMLALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "BURHANİYE",
        "STREET": "DUTLUCA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "BURHANİYE",
        "STREET": "GERİŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "BURHANİYE",
        "STREET": "HACIAHMET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "BURHANİYE",
        "STREET": "HACIBOZLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "BURHANİYE",
        "STREET": "HÜRRİYET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "BURHANİYE",
        "STREET": "HİSARKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "BURHANİYE",
        "STREET": "KARADERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "BURHANİYE",
        "STREET": "KIRTIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "BURHANİYE",
        "STREET": "KIZIKLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "BURHANİYE",
        "STREET": "KOCACAMİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "BURHANİYE",
        "STREET": "KURUCAOLUK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "BURHANİYE",
        "STREET": "KUYUCAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "BURHANİYE",
        "STREET": "KUYUMCU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "BURHANİYE",
        "STREET": "MAHKEME MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "BURHANİYE",
        "STREET": "MEMİŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "BURHANİYE",
        "STREET": "PELİTKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "BURHANİYE",
        "STREET": "SÜBEYLİDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "BURHANİYE",
        "STREET": "TAHTACI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "BURHANİYE",
        "STREET": "TAYLIELİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "BURHANİYE",
        "STREET": "YABANCILAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "BURHANİYE",
        "STREET": "YAYLACIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "BURHANİYE",
        "STREET": "YUNUS MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "BURHANİYE",
        "STREET": "YUNUSLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "BURHANİYE",
        "STREET": "ÇALLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "BURHANİYE",
        "STREET": "ÇAMTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "BURHANİYE",
        "STREET": "ÇORUK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "BURHANİYE",
        "STREET": "ÖREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "BURHANİYE",
        "STREET": "ÖĞRETMENLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "BURHANİYE",
        "STREET": "İSKELE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "BURHANİYE",
        "STREET": "ŞAHİNLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "BURHANİYE",
        "STREET": "ŞARKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "BİGADİÇ",
        "STREET": "4 EYLÜL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "BİGADİÇ",
        "STREET": "ABACI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "BİGADİÇ",
        "STREET": "ADALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "BİGADİÇ",
        "STREET": "AKYAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "BİGADİÇ",
        "STREET": "ALANKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "BİGADİÇ",
        "STREET": "ALTINLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "BİGADİÇ",
        "STREET": "AŞAĞIGÖCEK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "BİGADİÇ",
        "STREET": "AŞAĞIÇAMLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "BİGADİÇ",
        "STREET": "BABAKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "BİGADİÇ",
        "STREET": "BADEMLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "BİGADİÇ",
        "STREET": "BALATLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "BİGADİÇ",
        "STREET": "BAŞÇEŞME MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "BİGADİÇ",
        "STREET": "BEKİRLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "BİGADİÇ",
        "STREET": "BEĞENDİKLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "BİGADİÇ",
        "STREET": "BOZBÜK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "BİGADİÇ",
        "STREET": "CAMİİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "BİGADİÇ",
        "STREET": "DAVUTLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "BİGADİÇ",
        "STREET": "DAVUTÇA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "BİGADİÇ",
        "STREET": "DEDEÇINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "BİGADİÇ",
        "STREET": "DERE ÇERKES MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "BİGADİÇ",
        "STREET": "DEĞİRMENLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "BİGADİÇ",
        "STREET": "DOĞANÇAM MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "BİGADİÇ",
        "STREET": "DURASILAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "BİGADİÇ",
        "STREET": "DÜNDARCIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "BİGADİÇ",
        "STREET": "DİKKONAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "BİGADİÇ",
        "STREET": "ELYAPAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "BİGADİÇ",
        "STREET": "EMEK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "BİGADİÇ",
        "STREET": "EMİRLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "BİGADİÇ",
        "STREET": "ESENLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "BİGADİÇ",
        "STREET": "FETHİBEY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "BİGADİÇ",
        "STREET": "GÜVEMÇETMİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "BİGADİÇ",
        "STREET": "HACIÖMERDERESİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "BİGADİÇ",
        "STREET": "HAMİDİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "BİGADİÇ",
        "STREET": "HİSARKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "BİGADİÇ",
        "STREET": "IŞIKLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "BİGADİÇ",
        "STREET": "KADIKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "BİGADİÇ",
        "STREET": "KALAFAT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "BİGADİÇ",
        "STREET": "KARABAHÇE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "BİGADİÇ",
        "STREET": "KARGIN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "BİGADİÇ",
        "STREET": "KAYALIDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "BİGADİÇ",
        "STREET": "KAYIRLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "BİGADİÇ",
        "STREET": "KIRCA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "BİGADİÇ",
        "STREET": "KIZILÇUKUR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "BİGADİÇ",
        "STREET": "KOZPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "BİGADİÇ",
        "STREET": "KUYU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "BİGADİÇ",
        "STREET": "KÖSELER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "BİGADİÇ",
        "STREET": "KÜRSÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "BİGADİÇ",
        "STREET": "KÜÇÜKYENİKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "BİGADİÇ",
        "STREET": "MECİDİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "BİGADİÇ",
        "STREET": "MEYVALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "BİGADİÇ",
        "STREET": "OKÇULAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "BİGADİÇ",
        "STREET": "OKÇULARYERİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "BİGADİÇ",
        "STREET": "ORTA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "BİGADİÇ",
        "STREET": "OSMANCA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "BİGADİÇ",
        "STREET": "PANAYIR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "BİGADİÇ",
        "STREET": "SALMANLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "BİGADİÇ",
        "STREET": "SERVİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "BİGADİÇ",
        "STREET": "TOPALAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "BİGADİÇ",
        "STREET": "TOZAĞAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "BİGADİÇ",
        "STREET": "TURFULLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "BİGADİÇ",
        "STREET": "YAĞCIBEDİR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "BİGADİÇ",
        "STREET": "YAĞCILAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "BİGADİÇ",
        "STREET": "YENİKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "BİGADİÇ",
        "STREET": "YEŞİLDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "BİGADİÇ",
        "STREET": "YOLBAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "BİGADİÇ",
        "STREET": "YUKARIGÖÇEK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "BİGADİÇ",
        "STREET": "YUKARIÇAMLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "BİGADİÇ",
        "STREET": "YÜRÜCEKLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "BİGADİÇ",
        "STREET": "ÇAMKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "BİGADİÇ",
        "STREET": "ÇAVUŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "BİGADİÇ",
        "STREET": "ÇAYÜSTÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "BİGADİÇ",
        "STREET": "ÇAĞIŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "BİGADİÇ",
        "STREET": "ÇEKİRDEKLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "BİGADİÇ",
        "STREET": "ÇERİBAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "BİGADİÇ",
        "STREET": "ÇITAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "BİGADİÇ",
        "STREET": "ÇÖMLEKÇİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "BİGADİÇ",
        "STREET": "ÖZGÖREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "BİGADİÇ",
        "STREET": "İLYASLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "BİGADİÇ",
        "STREET": "İSKELEKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "BİGADİÇ",
        "STREET": "İĞCİLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "DURSUNBEY",
        "STREET": "ADAÖREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "DURSUNBEY",
        "STREET": "AKBAŞLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "DURSUNBEY",
        "STREET": "AKYAYLA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "DURSUNBEY",
        "STREET": "AKÇAGÜNEY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "DURSUNBEY",
        "STREET": "ALAGÜNEY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "DURSUNBEY",
        "STREET": "ALAÇAM MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "DURSUNBEY",
        "STREET": "ARIKLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "DURSUNBEY",
        "STREET": "AYVACIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "DURSUNBEY",
        "STREET": "AZİZİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "DURSUNBEY",
        "STREET": "AŞAĞIMUSALAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "DURSUNBEY",
        "STREET": "AŞAĞIYAĞCILAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "DURSUNBEY",
        "STREET": "BAYIRYÜZÜGÜNEY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "DURSUNBEY",
        "STREET": "BEYCE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "DURSUNBEY",
        "STREET": "BEYEL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "DURSUNBEY",
        "STREET": "BOYALICA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "DURSUNBEY",
        "STREET": "BOZYOKUŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "DURSUNBEY",
        "STREET": "BÜYÜKAKÇAALAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "DURSUNBEY",
        "STREET": "CEBECİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "DURSUNBEY",
        "STREET": "DADA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "DURSUNBEY",
        "STREET": "DELİCE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "DURSUNBEY",
        "STREET": "DEMİRCİLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "DURSUNBEY",
        "STREET": "DEREKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "DURSUNBEY",
        "STREET": "DEĞİRMENCİLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "DURSUNBEY",
        "STREET": "DODURGA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "DURSUNBEY",
        "STREET": "DOĞANCILAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "DURSUNBEY",
        "STREET": "DURABEYLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "DURSUNBEY",
        "STREET": "ERİCEK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "DURSUNBEY",
        "STREET": "FERAH MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "DURSUNBEY",
        "STREET": "GAZELLİDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "DURSUNBEY",
        "STREET": "GÖBÜL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "DURSUNBEY",
        "STREET": "GÖKÇEDAĞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "DURSUNBEY",
        "STREET": "GÖKÇEPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "DURSUNBEY",
        "STREET": "GÖLCÜK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "DURSUNBEY",
        "STREET": "GÜRLEYEN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "DURSUNBEY",
        "STREET": "GÜĞÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "DURSUNBEY",
        "STREET": "HACIAHMETPINARI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "DURSUNBEY",
        "STREET": "HACILAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "DURSUNBEY",
        "STREET": "HAMZACIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "DURSUNBEY",
        "STREET": "HASANLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "DURSUNBEY",
        "STREET": "HONDULAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "DURSUNBEY",
        "STREET": "HOPANLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "DURSUNBEY",
        "STREET": "HİNDİKLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "DURSUNBEY",
        "STREET": "IŞIKLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "DURSUNBEY",
        "STREET": "KARAGÖZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "DURSUNBEY",
        "STREET": "KARAKAYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "DURSUNBEY",
        "STREET": "KARAMANLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "DURSUNBEY",
        "STREET": "KARAPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "DURSUNBEY",
        "STREET": "KARDEŞLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "DURSUNBEY",
        "STREET": "KARYAĞMAZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "DURSUNBEY",
        "STREET": "KAVACIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "DURSUNBEY",
        "STREET": "KAVAKKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "DURSUNBEY",
        "STREET": "KAZİMİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "DURSUNBEY",
        "STREET": "KIZILCADERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "DURSUNBEY",
        "STREET": "KIZILÖZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "DURSUNBEY",
        "STREET": "KUMLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "DURSUNBEY",
        "STREET": "KURTLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "DURSUNBEY",
        "STREET": "KUZKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "DURSUNBEY",
        "STREET": "KÜÇÜKAKÇAALAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "DURSUNBEY",
        "STREET": "KÜÇÜKLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "DURSUNBEY",
        "STREET": "KİREÇ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "DURSUNBEY",
        "STREET": "MAHMUDİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "DURSUNBEY",
        "STREET": "MAHMUTÇA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "DURSUNBEY",
        "STREET": "MEYDANÇAYIRI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "DURSUNBEY",
        "STREET": "MICIRLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "DURSUNBEY",
        "STREET": "MOLLAOĞLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "DURSUNBEY",
        "STREET": "NAİPLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "DURSUNBEY",
        "STREET": "ODAKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "DURSUNBEY",
        "STREET": "OSMANİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "DURSUNBEY",
        "STREET": "POYRACIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "DURSUNBEY",
        "STREET": "RAMAZANLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "DURSUNBEY",
        "STREET": "RESÜLLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "DURSUNBEY",
        "STREET": "REŞADİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "DURSUNBEY",
        "STREET": "SAKIZKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "DURSUNBEY",
        "STREET": "SARISİPAHİLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "DURSUNBEY",
        "STREET": "SARNIÇKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "DURSUNBEY",
        "STREET": "SAÇAYAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "DURSUNBEY",
        "STREET": "SAĞIRLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "DURSUNBEY",
        "STREET": "SEBİLLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "DURSUNBEY",
        "STREET": "SELİMAĞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "DURSUNBEY",
        "STREET": "SÜLELER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "DURSUNBEY",
        "STREET": "SİNDERLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "DURSUNBEY",
        "STREET": "TAFAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "DURSUNBEY",
        "STREET": "TAŞKESİĞİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "DURSUNBEY",
        "STREET": "TAŞPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "DURSUNBEY",
        "STREET": "TEPEKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "DURSUNBEY",
        "STREET": "TEZLİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "DURSUNBEY",
        "STREET": "TURNACIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "DURSUNBEY",
        "STREET": "UMURLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "DURSUNBEY",
        "STREET": "VAKIF MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "DURSUNBEY",
        "STREET": "VELİLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "DURSUNBEY",
        "STREET": "YASSIÖREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "DURSUNBEY",
        "STREET": "YUKARIMUSALAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "DURSUNBEY",
        "STREET": "YUKARIYAĞCILAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "DURSUNBEY",
        "STREET": "YUNUSLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "DURSUNBEY",
        "STREET": "ÇAKIRCA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "DURSUNBEY",
        "STREET": "ÇAKMAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "DURSUNBEY",
        "STREET": "ÇALTICAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "DURSUNBEY",
        "STREET": "ÇAMHARMAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "DURSUNBEY",
        "STREET": "ÇAMKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "DURSUNBEY",
        "STREET": "ÇANAKÇI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "DURSUNBEY",
        "STREET": "ÇATALÇAM MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "DURSUNBEY",
        "STREET": "ÇELİKLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "DURSUNBEY",
        "STREET": "ÇINARKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "DURSUNBEY",
        "STREET": "ÇİFTÇİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "DURSUNBEY",
        "STREET": "ÖRENKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "DURSUNBEY",
        "STREET": "ÜÇEYLÜL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "DURSUNBEY",
        "STREET": "İRFANİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "DURSUNBEY",
        "STREET": "İSMAİLLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "DURSUNBEY",
        "STREET": "İSTASYON MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "DURSUNBEY",
        "STREET": "ŞABANLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "DURSUNBEY",
        "STREET": "ŞENKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "EDREMİT",
        "STREET": "AKÇAY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "EDREMİT",
        "STREET": "ALTINKUM MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "EDREMİT",
        "STREET": "ALTINOLUK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "EDREMİT",
        "STREET": "ARITAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "EDREMİT",
        "STREET": "ATATÜRK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "EDREMİT",
        "STREET": "AVCILAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "EDREMİT",
        "STREET": "BEYOBA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "EDREMİT",
        "STREET": "BOSTANCI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "EDREMİT",
        "STREET": "CAMİVASAT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "EDREMİT",
        "STREET": "CENNETAYAĞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "EDREMİT",
        "STREET": "CUMHURİYET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "EDREMİT",
        "STREET": "DARSOFA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "EDREMİT",
        "STREET": "DERELİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "EDREMİT",
        "STREET": "DOYRAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "EDREMİT",
        "STREET": "EROĞLAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "EDREMİT",
        "STREET": "GAZİCELAL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "EDREMİT",
        "STREET": "GAZİİLYAS MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "EDREMİT",
        "STREET": "GÜRE CUMHURİYET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "EDREMİT",
        "STREET": "GÜRE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "EDREMİT",
        "STREET": "HACIARSLANLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "EDREMİT",
        "STREET": "HACITUĞRUL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "EDREMİT",
        "STREET": "HAMİDİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "EDREMİT",
        "STREET": "HEKİMZADE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "EDREMİT",
        "STREET": "KADIKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "EDREMİT",
        "STREET": "KAPICIBAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "EDREMİT",
        "STREET": "KAVLAKLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "EDREMİT",
        "STREET": "KIZILKEÇİLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "EDREMİT",
        "STREET": "MEHMETALAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "EDREMİT",
        "STREET": "NARLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "EDREMİT",
        "STREET": "ORTAOBA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "EDREMİT",
        "STREET": "PINARBAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "EDREMİT",
        "STREET": "SARIKIZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "EDREMİT",
        "STREET": "SOĞANYEMEZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "EDREMİT",
        "STREET": "TAHTAKUŞLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "EDREMİT",
        "STREET": "TURHANBEY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "EDREMİT",
        "STREET": "TUZCUMURAT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "EDREMİT",
        "STREET": "YAYLAÖNÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "EDREMİT",
        "STREET": "YAŞYER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "EDREMİT",
        "STREET": "YOLÖREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "EDREMİT",
        "STREET": "ZEYTİNLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "EDREMİT",
        "STREET": "ÇAMCI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "EDREMİT",
        "STREET": "ÇAMLIBEL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "EDREMİT",
        "STREET": "ÇIKRIKÇI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "EDREMİT",
        "STREET": "İBRAHİMCE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "EDREMİT",
        "STREET": "İKİZÇAY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "EDREMİT",
        "STREET": "İSKELE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "EDREMİT",
        "STREET": "ŞAHİNDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "ERDEK",
        "STREET": "ALAETTİN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "ERDEK",
        "STREET": "ATATÜRK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "ERDEK",
        "STREET": "AŞAĞIYAPICI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "ERDEK",
        "STREET": "BALIKLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "ERDEK",
        "STREET": "BALLIPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "ERDEK",
        "STREET": "BELKIS MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "ERDEK",
        "STREET": "DOĞANLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "ERDEK",
        "STREET": "HALİTPAŞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "ERDEK",
        "STREET": "HAMAMLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "ERDEK",
        "STREET": "HARMANLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "ERDEK",
        "STREET": "KARŞIYAKA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "ERDEK",
        "STREET": "KESTANELİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "ERDEK",
        "STREET": "NARLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "ERDEK",
        "STREET": "OCAKLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "ERDEK",
        "STREET": "ORMANLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "ERDEK",
        "STREET": "PAŞALİMANI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "ERDEK",
        "STREET": "POYRAZLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "ERDEK",
        "STREET": "SAHİL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "ERDEK",
        "STREET": "TATLISU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "ERDEK",
        "STREET": "TURAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "ERDEK",
        "STREET": "TUZLA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "ERDEK",
        "STREET": "YALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "ERDEK",
        "STREET": "YUKARIYAPICI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "ERDEK",
        "STREET": "ZEYTİNLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "ERDEK",
        "STREET": "ÇAKIL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "ERDEK",
        "STREET": "ÇELTİKÇİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "ERDEK",
        "STREET": "İLHAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "ERDEK",
        "STREET": "ŞAHİNBURGAZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "GÖMEÇ",
        "STREET": "DURSUNLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "GÖMEÇ",
        "STREET": "HACIHÜSEYİNLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "GÖMEÇ",
        "STREET": "HACIOSMAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "GÖMEÇ",
        "STREET": "HACIOĞLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "GÖMEÇ",
        "STREET": "KARAAĞAÇ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "GÖMEÇ",
        "STREET": "KEMALPAŞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "GÖMEÇ",
        "STREET": "KEREMKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "GÖMEÇ",
        "STREET": "KOBAŞLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "GÖMEÇ",
        "STREET": "KUMGEDİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "GÖMEÇ",
        "STREET": "KUYUALAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "GÖMEÇ",
        "STREET": "MİTHATPAŞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "GÖMEÇ",
        "STREET": "ULUBEYLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "GÖMEÇ",
        "STREET": "YAYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "GÖNEN",
        "STREET": "100.YIL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "GÖNEN",
        "STREET": "AKÇAALİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "GÖNEN",
        "STREET": "AKÇAPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "GÖNEN",
        "STREET": "ALACAOLUK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "GÖNEN",
        "STREET": "ALAETTİN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "GÖNEN",
        "STREET": "ALAŞAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "GÖNEN",
        "STREET": "ALTAY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "GÖNEN",
        "STREET": "ARMUTLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "GÖNEN",
        "STREET": "ASMALIDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "GÖNEN",
        "STREET": "ATICIOBA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "GÖNEN",
        "STREET": "AYVALIDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "GÖNEN",
        "STREET": "BABAYAKA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "GÖNEN",
        "STREET": "BAKIRLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "GÖNEN",
        "STREET": "BALCI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "GÖNEN",
        "STREET": "BALCIDEDE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "GÖNEN",
        "STREET": "BAYRAMİÇ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "GÖNEN",
        "STREET": "BEYOLUK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "GÖNEN",
        "STREET": "BOSTANCI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "GÖNEN",
        "STREET": "BUĞDAYLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "GÖNEN",
        "STREET": "BÜYÜKSOĞUKLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "GÖNEN",
        "STREET": "CANBAZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "GÖNEN",
        "STREET": "DEREKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "GÖNEN",
        "STREET": "DIŞBUDAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "GÖNEN",
        "STREET": "DUMANALAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "GÖNEN",
        "STREET": "EKŞİDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "GÖNEN",
        "STREET": "FINDIKLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "GÖNEN",
        "STREET": "GAYBULAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "GÖNEN",
        "STREET": "GEBEÇINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "GÖNEN",
        "STREET": "GELGEÇ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "GÖNEN",
        "STREET": "GEYİKLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "GÖNEN",
        "STREET": "GÖKÇESU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "GÖNEN",
        "STREET": "GÜNDOĞAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "GÖNEN",
        "STREET": "GÜNDOĞDU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "GÖNEN",
        "STREET": "GÜNEŞLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "GÖNEN",
        "STREET": "HACIMENTEŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "GÖNEN",
        "STREET": "HACIVELİOBA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "GÖNEN",
        "STREET": "HAFIZHÜSEYİNBEY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "GÖNEN",
        "STREET": "HASANBEY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "GÖNEN",
        "STREET": "HAVUTÇA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "GÖNEN",
        "STREET": "HODUL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "GÖNEN",
        "STREET": "ILICAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "GÖNEN",
        "STREET": "ILICAOBA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "GÖNEN",
        "STREET": "KALBURCU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "GÖNEN",
        "STREET": "KALFAKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "GÖNEN",
        "STREET": "KAPLANOBA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "GÖNEN",
        "STREET": "KARAAĞAÇALAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "GÖNEN",
        "STREET": "KARALARÇİFTLİĞİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "GÖNEN",
        "STREET": "KARASUKABAKLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "GÖNEN",
        "STREET": "KARŞIYAKA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "GÖNEN",
        "STREET": "KAVAKALAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "GÖNEN",
        "STREET": "KAVAKOBA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "GÖNEN",
        "STREET": "KEÇELER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "GÖNEN",
        "STREET": "KINALAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "GÖNEN",
        "STREET": "KOCAPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "GÖNEN",
        "STREET": "KORUDEĞİRMEN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "GÖNEN",
        "STREET": "KOÇBAYIR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "GÖNEN",
        "STREET": "KUMKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "GÖNEN",
        "STREET": "KURTULUŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "GÖNEN",
        "STREET": "KÖRPEAĞAÇ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "GÖNEN",
        "STREET": "KÖTEYLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "GÖNEN",
        "STREET": "KÜPÇIKTI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "GÖNEN",
        "STREET": "KÜÇÜKSOĞUKLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "GÖNEN",
        "STREET": "KİLLİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "GÖNEN",
        "STREET": "MALKOÇ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "GÖNEN",
        "STREET": "MURATLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "GÖNEN",
        "STREET": "ORTAOBA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "GÖNEN",
        "STREET": "OSMANPAZAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "GÖNEN",
        "STREET": "PAŞAÇİFTLİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "GÖNEN",
        "STREET": "PEHLİVANHOCA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "GÖNEN",
        "STREET": "PLEVNE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "GÖNEN",
        "STREET": "REŞADİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "GÖNEN",
        "STREET": "RÜSTEM MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "GÖNEN",
        "STREET": "SARAÇLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "GÖNEN",
        "STREET": "SARIKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "GÖNEN",
        "STREET": "SEBEPLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "GÖNEN",
        "STREET": "SUÇIKTI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "GÖNEN",
        "STREET": "SÖĞÜT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "GÖNEN",
        "STREET": "TAHTALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "GÖNEN",
        "STREET": "TAŞTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "GÖNEN",
        "STREET": "TIRNOVA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "GÖNEN",
        "STREET": "TURPLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "GÖNEN",
        "STREET": "TUZAKÇI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "GÖNEN",
        "STREET": "TÜTÜNCÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "GÖNEN",
        "STREET": "ULUKIR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "GÖNEN",
        "STREET": "YENİAKÇAPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "GÖNEN",
        "STREET": "YÜRÜKKEÇİDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "GÖNEN",
        "STREET": "ÇAKMAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "GÖNEN",
        "STREET": "ÇALICA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "GÖNEN",
        "STREET": "ÇALIOBA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "GÖNEN",
        "STREET": "ÇATAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "GÖNEN",
        "STREET": "ÇINARLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "GÖNEN",
        "STREET": "ÇINARPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "GÖNEN",
        "STREET": "ÇIĞMIŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "GÖNEN",
        "STREET": "ÇOBANHAMİDİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "GÖNEN",
        "STREET": "ÇİFTEÇEŞMELER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "GÖNEN",
        "STREET": "ÇİFTLİKALAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "GÖNEN",
        "STREET": "ÖMERLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "GÖNEN",
        "STREET": "ÜZÜMLÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "GÖNEN",
        "STREET": "ÜÇPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "GÖNEN",
        "STREET": "İNCİRLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "GÖNEN",
        "STREET": "ŞAROLUK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "HAVRAN",
        "STREET": "BÜYÜKDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "HAVRAN",
        "STREET": "BÜYÜKŞAPÇI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "HAVRAN",
        "STREET": "CAMİİKEBİR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "HAVRAN",
        "STREET": "DEREÖREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "HAVRAN",
        "STREET": "EBUBEKİR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "HAVRAN",
        "STREET": "ESELER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "HAVRAN",
        "STREET": "EĞMİR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "HAVRAN",
        "STREET": "FAZLICA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "HAVRAN",
        "STREET": "HALILAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "HAVRAN",
        "STREET": "HALLAÇLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "HAVRAN",
        "STREET": "HAMAMBAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "HAVRAN",
        "STREET": "HÜSEYİNBEŞELER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "HAVRAN",
        "STREET": "KALABAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "HAVRAN",
        "STREET": "KARALAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "HAVRAN",
        "STREET": "KARAOĞLANLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "HAVRAN",
        "STREET": "KOBAKLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "HAVRAN",
        "STREET": "KOCADAĞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "HAVRAN",
        "STREET": "KOCASEYİT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "HAVRAN",
        "STREET": "KÖYLÜCE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "HAVRAN",
        "STREET": "KÜÇÜKDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "HAVRAN",
        "STREET": "KÜÇÜKŞAPÇI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "HAVRAN",
        "STREET": "MESCİT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "HAVRAN",
        "STREET": "SARNIÇ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "HAVRAN",
        "STREET": "TARLABAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "HAVRAN",
        "STREET": "TAŞARASI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "HAVRAN",
        "STREET": "TEKKE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "HAVRAN",
        "STREET": "TEMAŞALIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "HAVRAN",
        "STREET": "TEPEOBA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "HAVRAN",
        "STREET": "YENİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "HAVRAN",
        "STREET": "ÇAKIRDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "HAVRAN",
        "STREET": "ÇAKMAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "HAVRAN",
        "STREET": "ÇAMDİBİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "HAVRAN",
        "STREET": "ÇİĞİTZADE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "HAVRAN",
        "STREET": "İNÖNÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "KARESİ",
        "STREET": "1.ORUÇGAZİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "KARESİ",
        "STREET": "1.SAKARYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "KARESİ",
        "STREET": "2.ORUÇGAZİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "KARESİ",
        "STREET": "2.SAKARYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "KARESİ",
        "STREET": "ADNAN MENDERES MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "KARESİ",
        "STREET": "AKINCILAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "KARESİ",
        "STREET": "AKTARMA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "KARESİ",
        "STREET": "ALACABAYIR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "KARESİ",
        "STREET": "ALİ HİKMET PAŞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "KARESİ",
        "STREET": "ARMUTALAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "KARESİ",
        "STREET": "ATATÜRK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "KARESİ",
        "STREET": "AYGÖREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "KARESİ",
        "STREET": "BAKACAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "KARESİ",
        "STREET": "BEYKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "KARESİ",
        "STREET": "BOĞAZKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "KARESİ",
        "STREET": "BÜYÜKPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "KARESİ",
        "STREET": "DAVUTLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "KARESİ",
        "STREET": "DELİKLİTAŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "KARESİ",
        "STREET": "DUMLUPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "KARESİ",
        "STREET": "DÜZOBA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "KARESİ",
        "STREET": "EGE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "KARESİ",
        "STREET": "ESKİ KUYUMCULAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "KARESİ",
        "STREET": "FETHİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "KARESİ",
        "STREET": "HACI İSMAİL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "KARESİ",
        "STREET": "HALKAPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "KARESİ",
        "STREET": "HİSARALAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "KARESİ",
        "STREET": "HİSARİÇİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "KARESİ",
        "STREET": "KABAKDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "KARESİ",
        "STREET": "KALAYCILAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "KARESİ",
        "STREET": "KAMÇILLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "KARESİ",
        "STREET": "KARABEYLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "KARESİ",
        "STREET": "KARAKOL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "KARESİ",
        "STREET": "KARAOĞLAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "KARESİ",
        "STREET": "KARESİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "KARESİ",
        "STREET": "KAVAKLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "KARESİ",
        "STREET": "KAYABEY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "KARESİ",
        "STREET": "KIRMIZILAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "KARESİ",
        "STREET": "KIZPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "KARESİ",
        "STREET": "KOCAAVŞAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "KARESİ",
        "STREET": "KURTDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "KARESİ",
        "STREET": "KUVA-İ MİLLİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "KARESİ",
        "STREET": "KÖTEYLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "KARESİ",
        "STREET": "MALTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "KARESİ",
        "STREET": "MİRZABEY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "KARESİ",
        "STREET": "NAİPLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "KARESİ",
        "STREET": "ORTACA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "KARESİ",
        "STREET": "OVACIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "KARESİ",
        "STREET": "PAŞA ALANI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "KARESİ",
        "STREET": "TATLIPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "KARESİ",
        "STREET": "TAŞKESİĞİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "KARESİ",
        "STREET": "TOYBELEN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "KARESİ",
        "STREET": "TOYGAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "KARESİ",
        "STREET": "TURPLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "KARESİ",
        "STREET": "VİCDANİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "KARESİ",
        "STREET": "YAYLABAYIR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "KARESİ",
        "STREET": "YAYLACIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "KARESİ",
        "STREET": "YAĞCILAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "KARESİ",
        "STREET": "YENİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "KARESİ",
        "STREET": "YENİKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "KARESİ",
        "STREET": "YENİİSKENDER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "KARESİ",
        "STREET": "YEROLUK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "KARESİ",
        "STREET": "YEŞİLOVA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "KARESİ",
        "STREET": "YILDIRIM MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "KARESİ",
        "STREET": "ZİYARETLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "KARESİ",
        "STREET": "ÇANACIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "KARESİ",
        "STREET": "ÇAY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "KARESİ",
        "STREET": "ÇAYPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "KARESİ",
        "STREET": "ÜÇPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "KARESİ",
        "STREET": "İBİRLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "KARESİ",
        "STREET": "ŞAMLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "KEPSUT",
        "STREET": "AHMETÖLEN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "KEPSUT",
        "STREET": "AKÇAKERTİL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "KEPSUT",
        "STREET": "AKÇAKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "KEPSUT",
        "STREET": "ALAGÜNEY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "KEPSUT",
        "STREET": "ARMUTLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "KEPSUT",
        "STREET": "BALIKESİR L TİPİ KAPALI CEZAEVİ",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "KEPSUT",
        "STREET": "BAĞTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "KEPSUT",
        "STREET": "BEKTAŞLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "KEPSUT",
        "STREET": "BEYKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "KEPSUT",
        "STREET": "BÜKDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "KEPSUT",
        "STREET": "BÜYÜKKATRANCI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "KEPSUT",
        "STREET": "CAMİİATİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "KEPSUT",
        "STREET": "CAMİİCEDİT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "KEPSUT",
        "STREET": "DALKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "KEPSUT",
        "STREET": "DANAHİSAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "KEPSUT",
        "STREET": "DARIÇUKURU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "KEPSUT",
        "STREET": "DEDEKAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "KEPSUT",
        "STREET": "DERELİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "KEPSUT",
        "STREET": "DOMBAYDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "KEPSUT",
        "STREET": "DURAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "KEPSUT",
        "STREET": "EYÜPBÜKÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "KEPSUT",
        "STREET": "EŞELER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "KEPSUT",
        "STREET": "GÖBEL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "KEPSUT",
        "STREET": "GÖKKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "KEPSUT",
        "STREET": "HOTAŞLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "KEPSUT",
        "STREET": "IŞIKLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "KEPSUT",
        "STREET": "KALBURCU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "KEPSUT",
        "STREET": "KARACAAĞAÇ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "KEPSUT",
        "STREET": "KARACAÖREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "KEPSUT",
        "STREET": "KARAGÖZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "KEPSUT",
        "STREET": "KARAHALİLLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "KEPSUT",
        "STREET": "KARAÇALTI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "KEPSUT",
        "STREET": "KASAPZADE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "KEPSUT",
        "STREET": "KAYACIKLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "KEPSUT",
        "STREET": "KAYAELİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "KEPSUT",
        "STREET": "KEPEKLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "KEPSUT",
        "STREET": "KEÇİDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "KEPSUT",
        "STREET": "KIZILOLUK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "KEPSUT",
        "STREET": "KÜÇÜKKATRANCI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "KEPSUT",
        "STREET": "MAHKEME MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "KEPSUT",
        "STREET": "MAHMUDİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "KEPSUT",
        "STREET": "MEHMETLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "KEPSUT",
        "STREET": "MESTANLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "KEPSUT",
        "STREET": "MEZİTLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "KEPSUT",
        "STREET": "NUSRET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "KEPSUT",
        "STREET": "OSMANİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "KEPSUT",
        "STREET": "OVACIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "KEPSUT",
        "STREET": "PİYADE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "KEPSUT",
        "STREET": "RECEP MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "KEPSUT",
        "STREET": "SARAÇ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "KEPSUT",
        "STREET": "SARIFAKILAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "KEPSUT",
        "STREET": "SARIÇAYIR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "KEPSUT",
        "STREET": "SAYACIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "KEPSUT",
        "STREET": "SERVET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "KEPSUT",
        "STREET": "SERÇEÖREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "KEPSUT",
        "STREET": "SEÇDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "KEPSUT",
        "STREET": "TEKKEIŞIKLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "KEPSUT",
        "STREET": "TUZAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "KEPSUT",
        "STREET": "TİLKİCİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "KEPSUT",
        "STREET": "YAYLABAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "KEPSUT",
        "STREET": "YENİCE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "KEPSUT",
        "STREET": "YEŞİLDAĞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "KEPSUT",
        "STREET": "YOĞUNOLUK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "KEPSUT",
        "STREET": "ÇALKANDİL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "KEPSUT",
        "STREET": "ÖRENCİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "KEPSUT",
        "STREET": "ÖRENHARMAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "KEPSUT",
        "STREET": "ÖRENLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "KEPSUT",
        "STREET": "İHSANİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "KEPSUT",
        "STREET": "İSAALAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "KEPSUT",
        "STREET": "ŞEREMETLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "MANYAS",
        "STREET": "AKÇAOVA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "MANYAS",
        "STREET": "ATATÜRK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "MANYAS",
        "STREET": "BOĞAZPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "MANYAS",
        "STREET": "BÖLCEAĞAÇ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "MANYAS",
        "STREET": "CUMHURİYET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "MANYAS",
        "STREET": "DARICA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "MANYAS",
        "STREET": "DEREKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "MANYAS",
        "STREET": "DEĞİRMENBOĞAZI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "MANYAS",
        "STREET": "DOĞANCI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "MANYAS",
        "STREET": "DURA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "MANYAS",
        "STREET": "ERECEK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "MANYAS",
        "STREET": "ESKİMANYAS MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "MANYAS",
        "STREET": "ESKİÇATAL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "MANYAS",
        "STREET": "EŞEN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "MANYAS",
        "STREET": "HACIOSMAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "MANYAS",
        "STREET": "HACIYAKUP MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "MANYAS",
        "STREET": "HACIİBRAHİMPINARI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "MANYAS",
        "STREET": "HAMAMLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "MANYAS",
        "STREET": "HAYDAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "MANYAS",
        "STREET": "HEKİM MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "MANYAS",
        "STREET": "IŞIKLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "MANYAS",
        "STREET": "KALEBAYIR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "MANYAS",
        "STREET": "KALFA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "MANYAS",
        "STREET": "KAPAKLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "MANYAS",
        "STREET": "KARAKABAAĞAÇ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "MANYAS",
        "STREET": "KAYACA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "MANYAS",
        "STREET": "KIZIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "MANYAS",
        "STREET": "KIZIKSA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "MANYAS",
        "STREET": "KOCAGÖL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "MANYAS",
        "STREET": "KOÇOĞLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "MANYAS",
        "STREET": "KUBAŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "MANYAS",
        "STREET": "KULAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "MANYAS",
        "STREET": "MALTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "MANYAS",
        "STREET": "NECİP MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "MANYAS",
        "STREET": "PEYNİRKUYU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "MANYAS",
        "STREET": "SALUR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "MANYAS",
        "STREET": "SÜLEYMANLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "MANYAS",
        "STREET": "TEPECİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "MANYAS",
        "STREET": "YAYLA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "MANYAS",
        "STREET": "YENİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "MANYAS",
        "STREET": "YENİKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "MANYAS",
        "STREET": "ÇAKIRCA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "MANYAS",
        "STREET": "ÇAL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "MANYAS",
        "STREET": "ÇAMLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "MANYAS",
        "STREET": "ÇARŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "MANYAS",
        "STREET": "ÇATALTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "MANYAS",
        "STREET": "ÇAVUŞKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "MANYAS",
        "STREET": "ÖREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "MANYAS",
        "STREET": "İRŞADİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "MANYAS",
        "STREET": "ŞEVKETİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "MARMARA",
        "STREET": "ABROZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "MARMARA",
        "STREET": "ASMALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "MARMARA",
        "STREET": "AVŞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "MARMARA",
        "STREET": "CUMHURİYET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "MARMARA",
        "STREET": "DENİZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "MARMARA",
        "STREET": "EKİNLİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "MARMARA",
        "STREET": "GÜNDOĞDU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "MARMARA",
        "STREET": "HÜRRİYET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "MARMARA",
        "STREET": "OKULLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "MARMARA",
        "STREET": "SARAYLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "MARMARA",
        "STREET": "TOPAĞAÇ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "MARMARA",
        "STREET": "YENİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "MARMARA",
        "STREET": "YİĞİTLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "MARMARA",
        "STREET": "ÇINARLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "SAVAŞTEPE",
        "STREET": "AKPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "SAVAŞTEPE",
        "STREET": "ARDIÇLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "SAVAŞTEPE",
        "STREET": "AŞAĞIDANİŞMENT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "SAVAŞTEPE",
        "STREET": "BEYKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "SAVAŞTEPE",
        "STREET": "BOZALAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "SAVAŞTEPE",
        "STREET": "CUMHURİYET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "SAVAŞTEPE",
        "STREET": "DEVEÖREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "SAVAŞTEPE",
        "STREET": "DİKMELER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "SAVAŞTEPE",
        "STREET": "ESENKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "SAVAŞTEPE",
        "STREET": "EĞERCİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "SAVAŞTEPE",
        "STREET": "FATİH MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "SAVAŞTEPE",
        "STREET": "GÜVEM MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "SAVAŞTEPE",
        "STREET": "GÜVEMKÜÇÜKTARLA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "SAVAŞTEPE",
        "STREET": "HIDIRBALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "SAVAŞTEPE",
        "STREET": "HÜRRİYET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "SAVAŞTEPE",
        "STREET": "KALEMKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "SAVAŞTEPE",
        "STREET": "KARACALAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "SAVAŞTEPE",
        "STREET": "KARAPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "SAVAŞTEPE",
        "STREET": "KARAÇAM MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "SAVAŞTEPE",
        "STREET": "KOCABIYIKLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "SAVAŞTEPE",
        "STREET": "KOCAÖREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "SAVAŞTEPE",
        "STREET": "KONGURCA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "SAVAŞTEPE",
        "STREET": "KOĞUKYURT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "SAVAŞTEPE",
        "STREET": "KURUDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "SAVAŞTEPE",
        "STREET": "MADENMEZARI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "SAVAŞTEPE",
        "STREET": "MECİDİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "SAVAŞTEPE",
        "STREET": "MİNNETLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "SAVAŞTEPE",
        "STREET": "PELİTCİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "SAVAŞTEPE",
        "STREET": "SARIBEYLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "SAVAŞTEPE",
        "STREET": "SARISÜLEYMANLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "SAVAŞTEPE",
        "STREET": "SITMAPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "SAVAŞTEPE",
        "STREET": "SOĞUCAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "SAVAŞTEPE",
        "STREET": "SÖĞÜTLÜGÖZLE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "SAVAŞTEPE",
        "STREET": "SÖĞÜTÇÜK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "SAVAŞTEPE",
        "STREET": "TAVŞANCIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "SAVAŞTEPE",
        "STREET": "TÜREDİLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "SAVAŞTEPE",
        "STREET": "YAZÖREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "SAVAŞTEPE",
        "STREET": "YEŞİLHİSAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "SAVAŞTEPE",
        "STREET": "YOLCUPINARI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "SAVAŞTEPE",
        "STREET": "YUKARIDANİŞMENT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "SAVAŞTEPE",
        "STREET": "YUKARIKARAÇAM MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "SAVAŞTEPE",
        "STREET": "YUNAKDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "SAVAŞTEPE",
        "STREET": "ZAFER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "SAVAŞTEPE",
        "STREET": "ÇALTILI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "SAVAŞTEPE",
        "STREET": "ÇAMURLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "SAVAŞTEPE",
        "STREET": "ÇAVLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "SAVAŞTEPE",
        "STREET": "ÇUKURÇAYIR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "SAVAŞTEPE",
        "STREET": "ÇİFTLİKDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "SAVAŞTEPE",
        "STREET": "İSADERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "SAVAŞTEPE",
        "STREET": "İSTİKLAL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "SINDIRGI",
        "STREET": "AKTAŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "SINDIRGI",
        "STREET": "AKÇAKISRAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "SINDIRGI",
        "STREET": "ALACAATLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "SINDIRGI",
        "STREET": "ALAKIR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "SINDIRGI",
        "STREET": "ALAYAKA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "SINDIRGI",
        "STREET": "ARMUTLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "SINDIRGI",
        "STREET": "ASLANDEDE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "SINDIRGI",
        "STREET": "BAYRAKLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "SINDIRGI",
        "STREET": "BULAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "SINDIRGI",
        "STREET": "BÜKRECİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "SINDIRGI",
        "STREET": "BÜYÜKDAĞDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "SINDIRGI",
        "STREET": "CAMİCEDİT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "SINDIRGI",
        "STREET": "CAMİKEBİR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "SINDIRGI",
        "STREET": "CÜNEYT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "SINDIRGI",
        "STREET": "DANAÇAYIR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "SINDIRGI",
        "STREET": "DEDELER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "SINDIRGI",
        "STREET": "DERECİKÖREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "SINDIRGI",
        "STREET": "DEVLETLİBABA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "SINDIRGI",
        "STREET": "DÜVERTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "SINDIRGI",
        "STREET": "DÜĞÜNCÜLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "SINDIRGI",
        "STREET": "EMENDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "SINDIRGI",
        "STREET": "EĞRİDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "SINDIRGI",
        "STREET": "EŞMEDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "SINDIRGI",
        "STREET": "GÖLCÜK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "SINDIRGI",
        "STREET": "GÖZÖREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "SINDIRGI",
        "STREET": "HİSARALAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "SINDIRGI",
        "STREET": "IŞIKLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "SINDIRGI",
        "STREET": "KARAAĞAÇ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "SINDIRGI",
        "STREET": "KARACALAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "SINDIRGI",
        "STREET": "KARAGÜR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "SINDIRGI",
        "STREET": "KARAKAYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "SINDIRGI",
        "STREET": "KEPEZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "SINDIRGI",
        "STREET": "KERTİL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "SINDIRGI",
        "STREET": "KINIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "SINDIRGI",
        "STREET": "KIRANKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "SINDIRGI",
        "STREET": "KIZILGÜR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "SINDIRGI",
        "STREET": "KOCABEY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "SINDIRGI",
        "STREET": "KOCAKONAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "SINDIRGI",
        "STREET": "KOCASİNAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "SINDIRGI",
        "STREET": "KOZLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "SINDIRGI",
        "STREET": "KURTULUŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "SINDIRGI",
        "STREET": "KÜRENDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "SINDIRGI",
        "STREET": "KÜÇÜKBÜKÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "SINDIRGI",
        "STREET": "KÜÇÜKDAĞDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "SINDIRGI",
        "STREET": "MANDIRA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "SINDIRGI",
        "STREET": "MUMCUKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "SINDIRGI",
        "STREET": "ORMANİÇİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "SINDIRGI",
        "STREET": "OSMANLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "SINDIRGI",
        "STREET": "PELİTÖREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "SINDIRGI",
        "STREET": "PÜRSÜNLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "SINDIRGI",
        "STREET": "RIZAİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "SINDIRGI",
        "STREET": "SÜLLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "SINDIRGI",
        "STREET": "SİNANDEDE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "SINDIRGI",
        "STREET": "TAŞKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "SINDIRGI",
        "STREET": "UMURLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "SINDIRGI",
        "STREET": "YAYLABAYIR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "SINDIRGI",
        "STREET": "YAYLACIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "SINDIRGI",
        "STREET": "YAĞCIBEDİR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "SINDIRGI",
        "STREET": "YOLCUPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "SINDIRGI",
        "STREET": "YUSUFÇAMI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "SINDIRGI",
        "STREET": "YÜREĞİL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "SINDIRGI",
        "STREET": "ÇAKILLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "SINDIRGI",
        "STREET": "ÇALTILI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "SINDIRGI",
        "STREET": "ÇAMALANI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "SINDIRGI",
        "STREET": "ÇAVDAROĞLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "SINDIRGI",
        "STREET": "ÇAYGÖREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "SINDIRGI",
        "STREET": "ÇAYIR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "SINDIRGI",
        "STREET": "ÇELEBİLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "SINDIRGI",
        "STREET": "ÇIKRIKÇI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "SINDIRGI",
        "STREET": "ÇILBIRCI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "SINDIRGI",
        "STREET": "ÇOTURTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "SINDIRGI",
        "STREET": "İBİLLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "SINDIRGI",
        "STREET": "İZZETTİN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "SINDIRGI",
        "STREET": "ŞAHİNKAYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "SINDIRGI",
        "STREET": "ŞAPÇI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "SUSURLUK",
        "STREET": "ALİBEY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "SUSURLUK",
        "STREET": "ASMALIDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "SUSURLUK",
        "STREET": "AZİZİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "SUSURLUK",
        "STREET": "BABAKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "SUSURLUK",
        "STREET": "BALIKLIDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "SUSURLUK",
        "STREET": "BEYKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "SUSURLUK",
        "STREET": "BOZEN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "SUSURLUK",
        "STREET": "BURHANİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "SUSURLUK",
        "STREET": "BUZAĞILIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "SUSURLUK",
        "STREET": "DANAVELİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "SUSURLUK",
        "STREET": "DEMİRKAPI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "SUSURLUK",
        "STREET": "DEREKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "SUSURLUK",
        "STREET": "DUMAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "SUSURLUK",
        "STREET": "EKİNLİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "SUSURLUK",
        "STREET": "EMİNPINARI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "SUSURLUK",
        "STREET": "GÖBEL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "SUSURLUK",
        "STREET": "GÖKÇEAĞAÇ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "SUSURLUK",
        "STREET": "GÖKÇEDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "SUSURLUK",
        "STREET": "GÜNAYDIN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "SUSURLUK",
        "STREET": "GÜRECE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "SUSURLUK",
        "STREET": "HAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "SUSURLUK",
        "STREET": "ILICABOĞAZI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "SUSURLUK",
        "STREET": "KALFAKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "SUSURLUK",
        "STREET": "KARAKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "SUSURLUK",
        "STREET": "KARAPÜRÇEK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "SUSURLUK",
        "STREET": "KARŞIYAKA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "SUSURLUK",
        "STREET": "KAYALIDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "SUSURLUK",
        "STREET": "KAYIKÇI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "SUSURLUK",
        "STREET": "KEPEKLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "SUSURLUK",
        "STREET": "KIŞLA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "SUSURLUK",
        "STREET": "KOCAPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "SUSURLUK",
        "STREET": "KULAT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "SUSURLUK",
        "STREET": "KURUCAOLUK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "SUSURLUK",
        "STREET": "KİRAZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "SUSURLUK",
        "STREET": "MURADİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "SUSURLUK",
        "STREET": "ODALIDAM MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "SUSURLUK",
        "STREET": "OKÇUGÖL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "SUSURLUK",
        "STREET": "ORTA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "SUSURLUK",
        "STREET": "PAŞAKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "SUSURLUK",
        "STREET": "REŞADİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "SUSURLUK",
        "STREET": "SULTANÇAYIR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "SUSURLUK",
        "STREET": "SULTANİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "SUSURLUK",
        "STREET": "SÖVE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "SUSURLUK",
        "STREET": "SÖĞÜTÇAYIR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "SUSURLUK",
        "STREET": "SÜLECEK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "SUSURLUK",
        "STREET": "YAHYAKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "SUSURLUK",
        "STREET": "YAYLAÇAYIR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "SUSURLUK",
        "STREET": "YAĞCI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "SUSURLUK",
        "STREET": "YENİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "SUSURLUK",
        "STREET": "YILDIZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "SUSURLUK",
        "STREET": "ÖMERKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "SUSURLUK",
        "STREET": "ÜMİTELİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "SUSURLUK",
        "STREET": "İCLALİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "SUSURLUK",
        "STREET": "ŞEKER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "İVRİNDİ",
        "STREET": "7EYLÜL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "İVRİNDİ",
        "STREET": "AKÇAL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "İVRİNDİ",
        "STREET": "AYAKLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "İVRİNDİ",
        "STREET": "AŞAĞIKALEOBA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "İVRİNDİ",
        "STREET": "BEDRETTİN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "İVRİNDİ",
        "STREET": "BOZÖREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "İVRİNDİ",
        "STREET": "BÜYÜKFINDIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "İVRİNDİ",
        "STREET": "BÜYÜKILICA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "İVRİNDİ",
        "STREET": "BÜYÜKYENİCE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "İVRİNDİ",
        "STREET": "DEMİRCİLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "İVRİNDİ",
        "STREET": "DEĞİRMENBAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "İVRİNDİ",
        "STREET": "DÖŞEME MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "İVRİNDİ",
        "STREET": "ERDEL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "İVRİNDİ",
        "STREET": "ERİKLİKÖMÜRCÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "İVRİNDİ",
        "STREET": "EVCİLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "İVRİNDİ",
        "STREET": "GEBEÇINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "İVRİNDİ",
        "STREET": "GEÇMİŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "İVRİNDİ",
        "STREET": "GÖKÇELER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "İVRİNDİ",
        "STREET": "GÖKÇEYAZI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "İVRİNDİ",
        "STREET": "GÖMENİÇ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "İVRİNDİ",
        "STREET": "GÖZLÜÇAYIR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "İVRİNDİ",
        "STREET": "GÜMELİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "İVRİNDİ",
        "STREET": "HACIAHMETLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "İVRİNDİ",
        "STREET": "HAYDAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "İVRİNDİ",
        "STREET": "HÜSEYİNBEYOBASI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "İVRİNDİ",
        "STREET": "ILICAKPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "İVRİNDİ",
        "STREET": "KARAÇEPİŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "İVRİNDİ",
        "STREET": "KAYAPA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "İVRİNDİ",
        "STREET": "KAŞAĞIL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "İVRİNDİ",
        "STREET": "KILCILAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "İVRİNDİ",
        "STREET": "KINA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "İVRİNDİ",
        "STREET": "KINIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "İVRİNDİ",
        "STREET": "KIPIKLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "İVRİNDİ",
        "STREET": "KIŞLADERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "İVRİNDİ",
        "STREET": "KOCAELİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "İVRİNDİ",
        "STREET": "KOCAOBA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "İVRİNDİ",
        "STREET": "KORUCU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "İVRİNDİ",
        "STREET": "KURTULUŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "İVRİNDİ",
        "STREET": "KUŞDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "İVRİNDİ",
        "STREET": "KÜÇÜKFINDIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "İVRİNDİ",
        "STREET": "KÜÇÜKILICA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "İVRİNDİ",
        "STREET": "KÜÇÜKYENİCE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "İVRİNDİ",
        "STREET": "KİRAZ ÖREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "İVRİNDİ",
        "STREET": "MALLICA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "İVRİNDİ",
        "STREET": "OKÇULAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "İVRİNDİ",
        "STREET": "OSMANKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "İVRİNDİ",
        "STREET": "OSMANLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "İVRİNDİ",
        "STREET": "PELİTÖREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "İVRİNDİ",
        "STREET": "SAKARYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "İVRİNDİ",
        "STREET": "SARICA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "İVRİNDİ",
        "STREET": "SARIPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "İVRİNDİ",
        "STREET": "SOFULAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "İVRİNDİ",
        "STREET": "SOĞANBÜKÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "İVRİNDİ",
        "STREET": "SUSUZYAYLA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "İVRİNDİ",
        "STREET": "TAŞDİBİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "İVRİNDİ",
        "STREET": "TOPUZLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "İVRİNDİ",
        "STREET": "YAREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "İVRİNDİ",
        "STREET": "YAĞLILAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "İVRİNDİ",
        "STREET": "YEŞİLKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "İVRİNDİ",
        "STREET": "YUKARIKALEOBA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "İVRİNDİ",
        "STREET": "YÜREKLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "İVRİNDİ",
        "STREET": "ÇARKACI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "İVRİNDİ",
        "STREET": "ÇATALAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "İVRİNDİ",
        "STREET": "ÇELİMLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "İVRİNDİ",
        "STREET": "ÇOBANLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "İVRİNDİ",
        "STREET": "ÇUKURLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "İVRİNDİ",
        "STREET": "ÇUKUROBA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "İVRİNDİ",
        "STREET": "ÇİÇEKLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BALIKESİR",
        "DISTRICT": "İVRİNDİ",
        "STREET": "İKİZCE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BARTIN",
        "DISTRICT": "AMASRA",
        "STREET": "ACARLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BARTIN",
        "DISTRICT": "AMASRA",
        "STREET": "AHATLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BARTIN",
        "DISTRICT": "AMASRA",
        "STREET": "AKKONAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BARTIN",
        "DISTRICT": "AMASRA",
        "STREET": "ALİOBASI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BARTIN",
        "DISTRICT": "AMASRA",
        "STREET": "BOSTANLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BARTIN",
        "DISTRICT": "AMASRA",
        "STREET": "BOZTEPE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BARTIN",
        "DISTRICT": "AMASRA",
        "STREET": "CUMAYANI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BARTIN",
        "DISTRICT": "AMASRA",
        "STREET": "ESENLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BARTIN",
        "DISTRICT": "AMASRA",
        "STREET": "FATİH MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BARTIN",
        "DISTRICT": "AMASRA",
        "STREET": "GÖMÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BARTIN",
        "DISTRICT": "AMASRA",
        "STREET": "GÖÇKÜN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BARTIN",
        "DISTRICT": "AMASRA",
        "STREET": "GÖÇKÜNDEMİRCİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BARTIN",
        "DISTRICT": "AMASRA",
        "STREET": "HATİPLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BARTIN",
        "DISTRICT": "AMASRA",
        "STREET": "KALAYCI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BARTIN",
        "DISTRICT": "AMASRA",
        "STREET": "KALEİÇİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BARTIN",
        "DISTRICT": "AMASRA",
        "STREET": "KALEŞAH MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BARTIN",
        "DISTRICT": "AMASRA",
        "STREET": "KARAKAÇAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BARTIN",
        "DISTRICT": "AMASRA",
        "STREET": "KAZPINARI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BARTIN",
        "DISTRICT": "AMASRA",
        "STREET": "KOCAKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "BARTIN",
        "DISTRICT": "AMASRA",
        "STREET": "KUM MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BARTIN",
        "DISTRICT": "AMASRA",
        "STREET": "MAKARACI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BARTIN",
        "DISTRICT": "AMASRA",
        "STREET": "SARAYDÜZÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BARTIN",
        "DISTRICT": "AMASRA",
        "STREET": "TARLAAĞZI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BARTIN",
        "DISTRICT": "AMASRA",
        "STREET": "TOPALLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BARTIN",
        "DISTRICT": "AMASRA",
        "STREET": "TOPDERESİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BARTIN",
        "DISTRICT": "AMASRA",
        "STREET": "YAHYAYAZICILAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BARTIN",
        "DISTRICT": "AMASRA",
        "STREET": "YUKARISAL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BARTIN",
        "DISTRICT": "AMASRA",
        "STREET": "ÇAKRAZBOZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BARTIN",
        "DISTRICT": "AMASRA",
        "STREET": "ÇAKRAZOVA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BARTIN",
        "DISTRICT": "AMASRA",
        "STREET": "ÇAKRAZŞEYHLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BARTIN",
        "DISTRICT": "AMASRA",
        "STREET": "ÇANAKÇILAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BARTIN",
        "DISTRICT": "AMASRA",
        "STREET": "İNCİĞEZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BARTIN",
        "DISTRICT": "AMASRA",
        "STREET": "İNPİRİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BARTIN",
        "DISTRICT": "AMASRA",
        "STREET": "ŞENYURT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BARTIN",
        "DISTRICT": "AMASRA",
        "STREET": "ŞÜKÜRLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BARTIN",
        "DISTRICT": "MERKEZ",
        "STREET": "AHMETPAŞA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BARTIN",
        "DISTRICT": "MERKEZ",
        "STREET": "AKAĞAÇ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BARTIN",
        "DISTRICT": "MERKEZ",
        "STREET": "AKBABA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BARTIN",
        "DISTRICT": "MERKEZ",
        "STREET": "AKBAŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BARTIN",
        "DISTRICT": "MERKEZ",
        "STREET": "AKGÖZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BARTIN",
        "DISTRICT": "MERKEZ",
        "STREET": "AKINCILAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BARTIN",
        "DISTRICT": "MERKEZ",
        "STREET": "AKMANLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BARTIN",
        "DISTRICT": "MERKEZ",
        "STREET": "AKPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BARTIN",
        "DISTRICT": "MERKEZ",
        "STREET": "AKÇALI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BARTIN",
        "DISTRICT": "MERKEZ",
        "STREET": "AKÇAMESCİT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BARTIN",
        "DISTRICT": "MERKEZ",
        "STREET": "ALADAĞ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BARTIN",
        "DISTRICT": "MERKEZ",
        "STREET": "ALİBAŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BARTIN",
        "DISTRICT": "MERKEZ",
        "STREET": "ARIT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BARTIN",
        "DISTRICT": "MERKEZ",
        "STREET": "ARIÖNÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BARTIN",
        "DISTRICT": "MERKEZ",
        "STREET": "AVGÖLÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BARTIN",
        "DISTRICT": "MERKEZ",
        "STREET": "AYDINLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BARTIN",
        "DISTRICT": "MERKEZ",
        "STREET": "AĞDACI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BARTIN",
        "DISTRICT": "MERKEZ",
        "STREET": "AŞAĞIDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BARTIN",
        "DISTRICT": "MERKEZ",
        "STREET": "BAKİOĞLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BARTIN",
        "DISTRICT": "MERKEZ",
        "STREET": "BALAT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BARTIN",
        "DISTRICT": "MERKEZ",
        "STREET": "BARKAÇBOZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BARTIN",
        "DISTRICT": "MERKEZ",
        "STREET": "BARTIN KAPALI CEZA İNFAZ KURUMU",
        "TYPE": "0"
      },
      {
        "CITY": "BARTIN",
        "DISTRICT": "MERKEZ",
        "STREET": "BAYIRYÜZÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BARTIN",
        "DISTRICT": "MERKEZ",
        "STREET": "BAŞOĞLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BARTIN",
        "DISTRICT": "MERKEZ",
        "STREET": "BEDİL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BARTIN",
        "DISTRICT": "MERKEZ",
        "STREET": "BEŞKÖPRÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BARTIN",
        "DISTRICT": "MERKEZ",
        "STREET": "BUDAKDÜZÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BARTIN",
        "DISTRICT": "MERKEZ",
        "STREET": "BÜYÜKKIRAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BARTIN",
        "DISTRICT": "MERKEZ",
        "STREET": "BÜYÜKKIZILKUM KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BARTIN",
        "DISTRICT": "MERKEZ",
        "STREET": "CELİLBEYOĞLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BARTIN",
        "DISTRICT": "MERKEZ",
        "STREET": "CUMHURİYET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BARTIN",
        "DISTRICT": "MERKEZ",
        "STREET": "CÖCÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BARTIN",
        "DISTRICT": "MERKEZ",
        "STREET": "DALLICA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BARTIN",
        "DISTRICT": "MERKEZ",
        "STREET": "DARIÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BARTIN",
        "DISTRICT": "MERKEZ",
        "STREET": "DEMİRCİLER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BARTIN",
        "DISTRICT": "MERKEZ",
        "STREET": "DERBENT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BARTIN",
        "DISTRICT": "MERKEZ",
        "STREET": "DIRAZLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BARTIN",
        "DISTRICT": "MERKEZ",
        "STREET": "DOĞAŞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BARTIN",
        "DISTRICT": "MERKEZ",
        "STREET": "ECİKLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BARTIN",
        "DISTRICT": "MERKEZ",
        "STREET": "ELLİBAŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BARTIN",
        "DISTRICT": "MERKEZ",
        "STREET": "EPÇİLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BARTIN",
        "DISTRICT": "MERKEZ",
        "STREET": "EPÇİLERKADI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BARTIN",
        "DISTRICT": "MERKEZ",
        "STREET": "ESBEY KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BARTIN",
        "DISTRICT": "MERKEZ",
        "STREET": "ESENTEPE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BARTIN",
        "DISTRICT": "MERKEZ",
        "STREET": "ESENYURT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BARTIN",
        "DISTRICT": "MERKEZ",
        "STREET": "ESKİEMİRLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BARTIN",
        "DISTRICT": "MERKEZ",
        "STREET": "ESKİHAMİDİYE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BARTIN",
        "DISTRICT": "MERKEZ",
        "STREET": "EYÜPOĞLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BARTIN",
        "DISTRICT": "MERKEZ",
        "STREET": "FIRINLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BARTIN",
        "DISTRICT": "MERKEZ",
        "STREET": "GENÇALİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BARTIN",
        "DISTRICT": "MERKEZ",
        "STREET": "GERİŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BARTIN",
        "DISTRICT": "MERKEZ",
        "STREET": "GERİŞKATIRCI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BARTIN",
        "DISTRICT": "MERKEZ",
        "STREET": "GÖKÇEKIRAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BARTIN",
        "DISTRICT": "MERKEZ",
        "STREET": "GÖLBUCAĞI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BARTIN",
        "DISTRICT": "MERKEZ",
        "STREET": "GÖZPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BARTIN",
        "DISTRICT": "MERKEZ",
        "STREET": "GÜRGENPINARI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BARTIN",
        "DISTRICT": "MERKEZ",
        "STREET": "GÜRPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BARTIN",
        "DISTRICT": "MERKEZ",
        "STREET": "GÜZELCEHİSAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BARTIN",
        "DISTRICT": "MERKEZ",
        "STREET": "HACIHATİPOĞLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BARTIN",
        "DISTRICT": "MERKEZ",
        "STREET": "HACIOSMANOĞLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BARTIN",
        "DISTRICT": "MERKEZ",
        "STREET": "HANYERİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BARTIN",
        "DISTRICT": "MERKEZ",
        "STREET": "HASANEFENDİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BARTIN",
        "DISTRICT": "MERKEZ",
        "STREET": "HASANKADI-GERİŞLİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BARTIN",
        "DISTRICT": "MERKEZ",
        "STREET": "HASANKADI-MERKEZ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BARTIN",
        "DISTRICT": "MERKEZ",
        "STREET": "HASANKADI-TÜLOĞLU MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BARTIN",
        "DISTRICT": "MERKEZ",
        "STREET": "HASANKADI-ŞABANKADI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BARTIN",
        "DISTRICT": "MERKEZ",
        "STREET": "HASANLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BARTIN",
        "DISTRICT": "MERKEZ",
        "STREET": "HATİPLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BARTIN",
        "DISTRICT": "MERKEZ",
        "STREET": "HIDIRLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BARTIN",
        "DISTRICT": "MERKEZ",
        "STREET": "HOCAOĞLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BARTIN",
        "DISTRICT": "MERKEZ",
        "STREET": "HÜRRİYET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BARTIN",
        "DISTRICT": "MERKEZ",
        "STREET": "KABAGÖZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BARTIN",
        "DISTRICT": "MERKEZ",
        "STREET": "KAMAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BARTIN",
        "DISTRICT": "MERKEZ",
        "STREET": "KARACAOĞLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BARTIN",
        "DISTRICT": "MERKEZ",
        "STREET": "KARAHÜSEYİNLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BARTIN",
        "DISTRICT": "MERKEZ",
        "STREET": "KARAKÖY MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BARTIN",
        "DISTRICT": "MERKEZ",
        "STREET": "KARAKÖYŞEYHLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BARTIN",
        "DISTRICT": "MERKEZ",
        "STREET": "KARAMAZAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BARTIN",
        "DISTRICT": "MERKEZ",
        "STREET": "KARASU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BARTIN",
        "DISTRICT": "MERKEZ",
        "STREET": "KARAYAKUP KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BARTIN",
        "DISTRICT": "MERKEZ",
        "STREET": "KARAÇAY MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BARTIN",
        "DISTRICT": "MERKEZ",
        "STREET": "KARAİNLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BARTIN",
        "DISTRICT": "MERKEZ",
        "STREET": "KARŞIYAKA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BARTIN",
        "DISTRICT": "MERKEZ",
        "STREET": "KAYACILAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BARTIN",
        "DISTRICT": "MERKEZ",
        "STREET": "KAYADİBİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BARTIN",
        "DISTRICT": "MERKEZ",
        "STREET": "KAYADİBİKAVLAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BARTIN",
        "DISTRICT": "MERKEZ",
        "STREET": "KAYADİBİÇAVUŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BARTIN",
        "DISTRICT": "MERKEZ",
        "STREET": "KAŞBAŞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BARTIN",
        "DISTRICT": "MERKEZ",
        "STREET": "KEMER KÖPRÜ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BARTIN",
        "DISTRICT": "MERKEZ",
        "STREET": "KIRTEPE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BARTIN",
        "DISTRICT": "MERKEZ",
        "STREET": "KIZILELMA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BARTIN",
        "DISTRICT": "MERKEZ",
        "STREET": "KIŞLAKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "BARTIN",
        "DISTRICT": "MERKEZ",
        "STREET": "KOCAREİS KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BARTIN",
        "DISTRICT": "MERKEZ",
        "STREET": "KOZCAĞIZ-DEMİRCİ AHMETLER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BARTIN",
        "DISTRICT": "MERKEZ",
        "STREET": "KOZCAĞIZ-DEĞİRMENCİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BARTIN",
        "DISTRICT": "MERKEZ",
        "STREET": "KOZCAĞIZ-KAVLAKLIK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BARTIN",
        "DISTRICT": "MERKEZ",
        "STREET": "KOZCAĞIZ-MERKEZ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BARTIN",
        "DISTRICT": "MERKEZ",
        "STREET": "KOZCAĞIZ-YENİŞAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BARTIN",
        "DISTRICT": "MERKEZ",
        "STREET": "KUMAÇORAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BARTIN",
        "DISTRICT": "MERKEZ",
        "STREET": "KURT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BARTIN",
        "DISTRICT": "MERKEZ",
        "STREET": "KUTLUBEYDEMİRCİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BARTIN",
        "DISTRICT": "MERKEZ",
        "STREET": "KUTLUBEYTABAKLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BARTIN",
        "DISTRICT": "MERKEZ",
        "STREET": "KUTLUBEYYAZICILAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BARTIN",
        "DISTRICT": "MERKEZ",
        "STREET": "KÖYORTASI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BARTIN",
        "DISTRICT": "MERKEZ",
        "STREET": "KÖYYERİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BARTIN",
        "DISTRICT": "MERKEZ",
        "STREET": "KÜMESLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BARTIN",
        "DISTRICT": "MERKEZ",
        "STREET": "KÜÇÜKKIZILKUM KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BARTIN",
        "DISTRICT": "MERKEZ",
        "STREET": "MAMAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BARTIN",
        "DISTRICT": "MERKEZ",
        "STREET": "MEKEÇLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BARTIN",
        "DISTRICT": "MERKEZ",
        "STREET": "MERKEZ-GECEN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BARTIN",
        "DISTRICT": "MERKEZ",
        "STREET": "MURATBEY KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BARTIN",
        "DISTRICT": "MERKEZ",
        "STREET": "OKULAK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BARTIN",
        "DISTRICT": "MERKEZ",
        "STREET": "OKÇULAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BARTIN",
        "DISTRICT": "MERKEZ",
        "STREET": "ORDUYERİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BARTIN",
        "DISTRICT": "MERKEZ",
        "STREET": "ORTA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BARTIN",
        "DISTRICT": "MERKEZ",
        "STREET": "SARAYLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BARTIN",
        "DISTRICT": "MERKEZ",
        "STREET": "SERDAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BARTIN",
        "DISTRICT": "MERKEZ",
        "STREET": "SOFULAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BARTIN",
        "DISTRICT": "MERKEZ",
        "STREET": "SÖĞÜTLÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BARTIN",
        "DISTRICT": "MERKEZ",
        "STREET": "SÜLEK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BARTIN",
        "DISTRICT": "MERKEZ",
        "STREET": "SÜTLÜCE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BARTIN",
        "DISTRICT": "MERKEZ",
        "STREET": "SİPAHİLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BARTIN",
        "DISTRICT": "MERKEZ",
        "STREET": "TABANÖZÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BARTIN",
        "DISTRICT": "MERKEZ",
        "STREET": "TASMACI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BARTIN",
        "DISTRICT": "MERKEZ",
        "STREET": "TERKEHALİLLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BARTIN",
        "DISTRICT": "MERKEZ",
        "STREET": "TERKEHATİPLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BARTIN",
        "DISTRICT": "MERKEZ",
        "STREET": "TOPLUCA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BARTIN",
        "DISTRICT": "MERKEZ",
        "STREET": "TUNA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BARTIN",
        "DISTRICT": "MERKEZ",
        "STREET": "TURANLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BARTIN",
        "DISTRICT": "MERKEZ",
        "STREET": "TUZCULAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BARTIN",
        "DISTRICT": "MERKEZ",
        "STREET": "ULUGEÇİTAMBARCI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BARTIN",
        "DISTRICT": "MERKEZ",
        "STREET": "ULUGEÇİTKADI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BARTIN",
        "DISTRICT": "MERKEZ",
        "STREET": "ULUKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "BARTIN",
        "DISTRICT": "MERKEZ",
        "STREET": "USTAOĞLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BARTIN",
        "DISTRICT": "MERKEZ",
        "STREET": "UZUNÖZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BARTIN",
        "DISTRICT": "MERKEZ",
        "STREET": "UĞURLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BARTIN",
        "DISTRICT": "MERKEZ",
        "STREET": "YANAZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BARTIN",
        "DISTRICT": "MERKEZ",
        "STREET": "YENİHAMİDİYE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BARTIN",
        "DISTRICT": "MERKEZ",
        "STREET": "YENİKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "BARTIN",
        "DISTRICT": "MERKEZ",
        "STREET": "YEĞENLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BARTIN",
        "DISTRICT": "MERKEZ",
        "STREET": "YEŞİLKAYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BARTIN",
        "DISTRICT": "MERKEZ",
        "STREET": "YEŞİLYURT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BARTIN",
        "DISTRICT": "MERKEZ",
        "STREET": "YILDIZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BARTIN",
        "DISTRICT": "MERKEZ",
        "STREET": "YUKARIŞEYHLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BARTIN",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇAKIRDEMİRCİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BARTIN",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇAKIRKADI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BARTIN",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇAKIRÖMERAĞA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BARTIN",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇAMALTI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BARTIN",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇAMLIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BARTIN",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇAYBÜKÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BARTIN",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇAYDÜZÜ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BARTIN",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇAYIR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BARTIN",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇEŞTEPE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BARTIN",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇUKURBÜK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BARTIN",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇÖPBEY KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BARTIN",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇİFTLİKKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "BARTIN",
        "DISTRICT": "MERKEZ",
        "STREET": "ÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BARTIN",
        "DISTRICT": "MERKEZ",
        "STREET": "ÖZBAŞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BARTIN",
        "DISTRICT": "MERKEZ",
        "STREET": "İMAMLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BARTIN",
        "DISTRICT": "MERKEZ",
        "STREET": "ŞABANKADI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BARTIN",
        "DISTRICT": "MERKEZ",
        "STREET": "ŞAHNE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BARTIN",
        "DISTRICT": "MERKEZ",
        "STREET": "ŞAHİN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BARTIN",
        "DISTRICT": "MERKEZ",
        "STREET": "ŞARKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "BARTIN",
        "DISTRICT": "MERKEZ",
        "STREET": "ŞİREMİRTABAKLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BARTIN",
        "DISTRICT": "MERKEZ",
        "STREET": "ŞİREMİRÇAVUŞ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BARTIN",
        "DISTRICT": "MERKEZ",
        "STREET": "ŞİRİNKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "BARTIN",
        "DISTRICT": "KURUCAŞİLE",
        "STREET": "ALAPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BARTIN",
        "DISTRICT": "KURUCAŞİLE",
        "STREET": "AYDOĞMUŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BARTIN",
        "DISTRICT": "KURUCAŞİLE",
        "STREET": "BAŞKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "BARTIN",
        "DISTRICT": "KURUCAŞİLE",
        "STREET": "CURUNLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BARTIN",
        "DISTRICT": "KURUCAŞİLE",
        "STREET": "DANİŞMENT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BARTIN",
        "DISTRICT": "KURUCAŞİLE",
        "STREET": "DEMİRCİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BARTIN",
        "DISTRICT": "KURUCAŞİLE",
        "STREET": "DİZLERMEZECİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BARTIN",
        "DISTRICT": "KURUCAŞİLE",
        "STREET": "ELVANLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BARTIN",
        "DISTRICT": "KURUCAŞİLE",
        "STREET": "HACIKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "BARTIN",
        "DISTRICT": "KURUCAŞİLE",
        "STREET": "HİSARKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "BARTIN",
        "DISTRICT": "KURUCAŞİLE",
        "STREET": "KALEKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "BARTIN",
        "DISTRICT": "KURUCAŞİLE",
        "STREET": "KANATLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BARTIN",
        "DISTRICT": "KURUCAŞİLE",
        "STREET": "KAPISUYU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BARTIN",
        "DISTRICT": "KURUCAŞİLE",
        "STREET": "KARAMAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BARTIN",
        "DISTRICT": "KURUCAŞİLE",
        "STREET": "KARGACAK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BARTIN",
        "DISTRICT": "KURUCAŞİLE",
        "STREET": "KAVAKLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BARTIN",
        "DISTRICT": "KURUCAŞİLE",
        "STREET": "KÖMEÇ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BARTIN",
        "DISTRICT": "KURUCAŞİLE",
        "STREET": "KİRLİKMÜSLİMHOCA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BARTIN",
        "DISTRICT": "KURUCAŞİLE",
        "STREET": "LİMAN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BARTIN",
        "DISTRICT": "KURUCAŞİLE",
        "STREET": "MEYDAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BARTIN",
        "DISTRICT": "KURUCAŞİLE",
        "STREET": "OVATEKKEÖNÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BARTIN",
        "DISTRICT": "KURUCAŞİLE",
        "STREET": "PAŞALILAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BARTIN",
        "DISTRICT": "KURUCAŞİLE",
        "STREET": "SARIDERESİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BARTIN",
        "DISTRICT": "KURUCAŞİLE",
        "STREET": "UĞURLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BARTIN",
        "DISTRICT": "KURUCAŞİLE",
        "STREET": "YENİKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "BARTIN",
        "DISTRICT": "KURUCAŞİLE",
        "STREET": "ZİYARETKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "BARTIN",
        "DISTRICT": "KURUCAŞİLE",
        "STREET": "ÇAYALTI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BARTIN",
        "DISTRICT": "KURUCAŞİLE",
        "STREET": "ÖMERLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BARTIN",
        "DISTRICT": "KURUCAŞİLE",
        "STREET": "ÖMERLER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BARTIN",
        "DISTRICT": "KURUCAŞİLE",
        "STREET": "İLYASGEÇİDİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BARTIN",
        "DISTRICT": "KURUCAŞİLE",
        "STREET": "ŞEYHLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BARTIN",
        "DISTRICT": "KURUCAŞİLE",
        "STREET": "ŞİLE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BARTIN",
        "DISTRICT": "ULUS",
        "STREET": "ABDURRAHMAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BARTIN",
        "DISTRICT": "ULUS",
        "STREET": "ABDİPAŞA-DERECİK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BARTIN",
        "DISTRICT": "ULUS",
        "STREET": "ABDİPAŞA-KADIOĞLU MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BARTIN",
        "DISTRICT": "ULUS",
        "STREET": "ABDİPAŞA-KARADİKEN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BARTIN",
        "DISTRICT": "ULUS",
        "STREET": "ABDİPAŞA-ULUPINAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BARTIN",
        "DISTRICT": "ULUS",
        "STREET": "ABDİPAŞA-YEŞİLPAZAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BARTIN",
        "DISTRICT": "ULUS",
        "STREET": "AKTAŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BARTIN",
        "DISTRICT": "ULUS",
        "STREET": "AKÖRENSÖKÜLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BARTIN",
        "DISTRICT": "ULUS",
        "STREET": "ALIÇLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BARTIN",
        "DISTRICT": "ULUS",
        "STREET": "ALPI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BARTIN",
        "DISTRICT": "ULUS",
        "STREET": "ARPACIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BARTIN",
        "DISTRICT": "ULUS",
        "STREET": "AĞAKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "BARTIN",
        "DISTRICT": "ULUS",
        "STREET": "AŞAĞIDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BARTIN",
        "DISTRICT": "ULUS",
        "STREET": "AŞAĞIEMİRCE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BARTIN",
        "DISTRICT": "ULUS",
        "STREET": "AŞAĞIKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "BARTIN",
        "DISTRICT": "ULUS",
        "STREET": "AŞAĞIÇAMLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BARTIN",
        "DISTRICT": "ULUS",
        "STREET": "AŞAĞIÇERÇİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BARTIN",
        "DISTRICT": "ULUS",
        "STREET": "BAHÇECİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BARTIN",
        "DISTRICT": "ULUS",
        "STREET": "BALICAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BARTIN",
        "DISTRICT": "ULUS",
        "STREET": "BAĞDATLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BARTIN",
        "DISTRICT": "ULUS",
        "STREET": "BUĞURLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BARTIN",
        "DISTRICT": "ULUS",
        "STREET": "CEYÜPLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BARTIN",
        "DISTRICT": "ULUS",
        "STREET": "DERELİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BARTIN",
        "DISTRICT": "ULUS",
        "STREET": "DODURGA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BARTIN",
        "DISTRICT": "ULUS",
        "STREET": "DORUCAŞAHİNCİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BARTIN",
        "DISTRICT": "ULUS",
        "STREET": "DOĞANKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "BARTIN",
        "DISTRICT": "ULUS",
        "STREET": "DÖNGELLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BARTIN",
        "DISTRICT": "ULUS",
        "STREET": "DÖREKLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BARTIN",
        "DISTRICT": "ULUS",
        "STREET": "DÜZKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "BARTIN",
        "DISTRICT": "ULUS",
        "STREET": "DİBEKTAŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BARTIN",
        "DISTRICT": "ULUS",
        "STREET": "ELDEŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BARTIN",
        "DISTRICT": "ULUS",
        "STREET": "ELMACIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BARTIN",
        "DISTRICT": "ULUS",
        "STREET": "ESELER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BARTIN",
        "DISTRICT": "ULUS",
        "STREET": "GÖKPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BARTIN",
        "DISTRICT": "ULUS",
        "STREET": "GÜNEYÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BARTIN",
        "DISTRICT": "ULUS",
        "STREET": "HASANÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BARTIN",
        "DISTRICT": "ULUS",
        "STREET": "HOCAKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "BARTIN",
        "DISTRICT": "ULUS",
        "STREET": "HİSARKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "BARTIN",
        "DISTRICT": "ULUS",
        "STREET": "ISIRGANLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BARTIN",
        "DISTRICT": "ULUS",
        "STREET": "KADIKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "BARTIN",
        "DISTRICT": "ULUS",
        "STREET": "KALDIRIM MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BARTIN",
        "DISTRICT": "ULUS",
        "STREET": "KALECİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BARTIN",
        "DISTRICT": "ULUS",
        "STREET": "KARAHASAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BARTIN",
        "DISTRICT": "ULUS",
        "STREET": "KARAKIŞLA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BARTIN",
        "DISTRICT": "ULUS",
        "STREET": "KASIMLAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BARTIN",
        "DISTRICT": "ULUS",
        "STREET": "KAYABAŞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BARTIN",
        "DISTRICT": "ULUS",
        "STREET": "KEÇİDERESİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BARTIN",
        "DISTRICT": "ULUS",
        "STREET": "KIYIKLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BARTIN",
        "DISTRICT": "ULUS",
        "STREET": "KIZILLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BARTIN",
        "DISTRICT": "ULUS",
        "STREET": "KONAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BARTIN",
        "DISTRICT": "ULUS",
        "STREET": "KONUKLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BARTIN",
        "DISTRICT": "ULUS",
        "STREET": "KOZANLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BARTIN",
        "DISTRICT": "ULUS",
        "STREET": "KUMLUCA-KAVAKLIKALDIRIM MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BARTIN",
        "DISTRICT": "ULUS",
        "STREET": "KUMLUCA-MERKEZ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BARTIN",
        "DISTRICT": "ULUS",
        "STREET": "KUMLUCA-YENİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BARTIN",
        "DISTRICT": "ULUS",
        "STREET": "KÖKLÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BARTIN",
        "DISTRICT": "ULUS",
        "STREET": "KÜLLÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BARTIN",
        "DISTRICT": "ULUS",
        "STREET": "KİRAZCIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BARTIN",
        "DISTRICT": "ULUS",
        "STREET": "KİRSİNLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BARTIN",
        "DISTRICT": "ULUS",
        "STREET": "MEZGELLER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BARTIN",
        "DISTRICT": "ULUS",
        "STREET": "SAMAT MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BARTIN",
        "DISTRICT": "ULUS",
        "STREET": "SARIFASIL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BARTIN",
        "DISTRICT": "ULUS",
        "STREET": "SARNIÇ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BARTIN",
        "DISTRICT": "ULUS",
        "STREET": "SAZ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BARTIN",
        "DISTRICT": "ULUS",
        "STREET": "ULUKAYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BARTIN",
        "DISTRICT": "ULUS",
        "STREET": "ULUKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "BARTIN",
        "DISTRICT": "ULUS",
        "STREET": "YENİKIŞLA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BARTIN",
        "DISTRICT": "ULUS",
        "STREET": "YENİKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "BARTIN",
        "DISTRICT": "ULUS",
        "STREET": "YILANLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BARTIN",
        "DISTRICT": "ULUS",
        "STREET": "YUKARIDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BARTIN",
        "DISTRICT": "ULUS",
        "STREET": "ZAFER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BARTIN",
        "DISTRICT": "ULUS",
        "STREET": "ÇAVUŞKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "BARTIN",
        "DISTRICT": "ULUS",
        "STREET": "ÇERDE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BARTIN",
        "DISTRICT": "ULUS",
        "STREET": "ÇUBUKBELİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BARTIN",
        "DISTRICT": "ULUS",
        "STREET": "ÇUBUKLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BARTIN",
        "DISTRICT": "ULUS",
        "STREET": "ÖNCÜLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BARTIN",
        "DISTRICT": "ULUS",
        "STREET": "ÜÇSARAY KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BARTIN",
        "DISTRICT": "ULUS",
        "STREET": "İBRAHİMDERESİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BARTIN",
        "DISTRICT": "ULUS",
        "STREET": "İNCEÇAM KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BARTIN",
        "DISTRICT": "ULUS",
        "STREET": "İĞNECİLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BARTIN",
        "DISTRICT": "ULUS",
        "STREET": "ŞİRİNLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "19 MAYIS MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "AKOBA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "AKYÜREK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "AKÇA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "AYDINLIKEVLER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "BAHÇELİEVLER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "BALPINAR-SEGİRKAN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "BALPINAR-SEYRANTEPE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "BALPINAR-YENİKÖY MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "BALPINAR-YENİMAHALLE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "BATMAN M.TİPİ KAPALI CEZAEVİ",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "BAYINDIR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "BAYRAKLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "BAĞLAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "BELDE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "BEŞEVLER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "BIÇAKÇI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "BİNATLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "CUDİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "CUMHURİYET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "DEMİRBİLEK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "DEMİRLİPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "DOLUCA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "DİKTEPE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "FATİH MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "GAP MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "GÜLTEPE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "GÜNEYKENT MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "GÜNEŞLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "GÜVERCİN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "GİRBEREŞİK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "HUZUR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "HÜRRİYET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "HİLAL MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "KARAYÜN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "KARDELEN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "KARŞIYAKA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "KAYABAĞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "KESMEKÖPRÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "KILIÇ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "KISMET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "KOCALAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "KORİK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "KUYUBAŞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "KÖSETARLA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "KÜLTÜR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "MEYDAN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "OYMATAŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "PAZARYERİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "PETROL MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "PETROLKENT MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "PINARBAŞI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "RAMAN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "RECEPLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "SAĞLIK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "SEYİTLER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "SUÇEKEN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "SİTE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "TİLMERC MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "URGANLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "YAKITLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "YAYLICA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "YAĞMURLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "YEDİYOL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "YENİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "YENİŞEHİR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "YEŞİLTEPE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "YEŞİLÖZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "YOLAĞZI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "YOLVEREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "ZİYAGÖKALP MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇAMLICA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇAMLITEPE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇARIKLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇARŞI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇAY MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇAYÜSTÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇEVRİMOVA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇEŞMEBAŞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "İLUH MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "İRMİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "ŞAFAK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "ŞİRİNEVLER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "BEŞİRİ",
        "STREET": "ALACA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "BEŞİRİ",
        "STREET": "ASMADERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "BEŞİRİ",
        "STREET": "ATBAĞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "BEŞİRİ",
        "STREET": "AYRANCI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "BEŞİRİ",
        "STREET": "BAHÇELİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "BEŞİRİ",
        "STREET": "BATMAN İLİ BEŞİRİ KAPALI CEZA İNFAZ KURULU",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "BEŞİRİ",
        "STREET": "BATMAN İLİ BEŞİRİ İLÇESİ T TİPİ KAPALI CEZA İNFAZ ",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "BEŞİRİ",
        "STREET": "BAĞDU MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "BEŞİRİ",
        "STREET": "BAŞARI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "BEŞİRİ",
        "STREET": "BEHREM YOĞUN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "BEŞİRİ",
        "STREET": "BEYÇAYIRI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "BEŞİRİ",
        "STREET": "BEŞPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "BEŞİRİ",
        "STREET": "BİLEK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "BEŞİRİ",
        "STREET": "CUMHURİYET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "BEŞİRİ",
        "STREET": "DANALI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "BEŞİRİ",
        "STREET": "DAYILAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "BEŞİRİ",
        "STREET": "DAĞYOLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "BEŞİRİ",
        "STREET": "DEVEBOYNU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "BEŞİRİ",
        "STREET": "DEĞİRMENÜSTÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "BEŞİRİ",
        "STREET": "DOĞANKAVAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "BEŞİRİ",
        "STREET": "DOĞANPAZARI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "BEŞİRİ",
        "STREET": "DURUCAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "BEŞİRİ",
        "STREET": "ESENCE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "BEŞİRİ",
        "STREET": "ESKİHAMUR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "BEŞİRİ",
        "STREET": "GÜVERCİNLİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "BEŞİRİ",
        "STREET": "ILICA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "BEŞİRİ",
        "STREET": "IŞIKVEREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "BEŞİRİ",
        "STREET": "KARATEPE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "BEŞİRİ",
        "STREET": "KAYATEPE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "BEŞİRİ",
        "STREET": "KAŞÜSTÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "BEŞİRİ",
        "STREET": "KOBİN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "BEŞİRİ",
        "STREET": "KUMGEÇİT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "BEŞİRİ",
        "STREET": "KUMÇAY KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "BEŞİRİ",
        "STREET": "KURUKAVAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "BEŞİRİ",
        "STREET": "KUŞÇUKURU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "BEŞİRİ",
        "STREET": "KÜTÜKLÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "BEŞİRİ",
        "STREET": "MEHMET YATKIN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "BEŞİRİ",
        "STREET": "MİLLİ EGEMENLİK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "BEŞİRİ",
        "STREET": "OTLUCA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "BEŞİRİ",
        "STREET": "OĞUZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "BEŞİRİ",
        "STREET": "SAMANLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "BEŞİRİ",
        "STREET": "TEKAĞAÇ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "BEŞİRİ",
        "STREET": "TEPECİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "BEŞİRİ",
        "STREET": "UĞURCA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "BEŞİRİ",
        "STREET": "YAKACIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "BEŞİRİ",
        "STREET": "YALINCA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "BEŞİRİ",
        "STREET": "YALINKAVAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "BEŞİRİ",
        "STREET": "YARIMTAŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "BEŞİRİ",
        "STREET": "YAZIHAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "BEŞİRİ",
        "STREET": "YENİPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "BEŞİRİ",
        "STREET": "YENİYOL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "BEŞİRİ",
        "STREET": "YEŞİLOBA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "BEŞİRİ",
        "STREET": "YOLKONAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "BEŞİRİ",
        "STREET": "YONTUKYAZI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "BEŞİRİ",
        "STREET": "ÇAKILLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "BEŞİRİ",
        "STREET": "ÇAVUŞBAYIRI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "BEŞİRİ",
        "STREET": "ÇIĞIRLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "BEŞİRİ",
        "STREET": "ÖRMEGÖZE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "BEŞİRİ",
        "STREET": "ÜÇKUYULAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "BEŞİRİ",
        "STREET": "İKİKÖPRÜ-KARADUMAN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "BEŞİRİ",
        "STREET": "İKİKÖPRÜ-KIYANÇ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "BEŞİRİ",
        "STREET": "İKİKÖPRÜ-TURGUT ÖZAL MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "BEŞİRİ",
        "STREET": "İKİYAKA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "BEŞİRİ",
        "STREET": "İNPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "GERCÜŞ",
        "STREET": "AKBURÇ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "GERCÜŞ",
        "STREET": "AKYAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "GERCÜŞ",
        "STREET": "ARDIÇ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "GERCÜŞ",
        "STREET": "ARDIÇLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "GERCÜŞ",
        "STREET": "ARICA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "GERCÜŞ",
        "STREET": "AYDINCA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "GERCÜŞ",
        "STREET": "AYDINLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "GERCÜŞ",
        "STREET": "BABNİR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "GERCÜŞ",
        "STREET": "BAĞLARBAŞI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "GERCÜŞ",
        "STREET": "BAĞLICA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "GERCÜŞ",
        "STREET": "BAĞÖZÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "GERCÜŞ",
        "STREET": "BAŞARKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "GERCÜŞ",
        "STREET": "BAŞOVA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "GERCÜŞ",
        "STREET": "BECİRMAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "GERCÜŞ",
        "STREET": "BOĞAZKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "GERCÜŞ",
        "STREET": "CEVİZLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "GERCÜŞ",
        "STREET": "DERELİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "GERCÜŞ",
        "STREET": "DEREİÇİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "GERCÜŞ",
        "STREET": "DORUK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "GERCÜŞ",
        "STREET": "DÜZMEŞE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "GERCÜŞ",
        "STREET": "EYMİR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "GERCÜŞ",
        "STREET": "GEÇİT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "GERCÜŞ",
        "STREET": "GÖKÇE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "GERCÜŞ",
        "STREET": "GÖKÇEPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "GERCÜŞ",
        "STREET": "GÖNÜLLÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "GERCÜŞ",
        "STREET": "GÜRBÜZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "GERCÜŞ",
        "STREET": "GÜZELÖZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "GERCÜŞ",
        "STREET": "HİSAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "GERCÜŞ",
        "STREET": "KANTAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "GERCÜŞ",
        "STREET": "KARALAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "GERCÜŞ",
        "STREET": "KAYALAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "GERCÜŞ",
        "STREET": "KAYAPINAR-PINARBAŞI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "GERCÜŞ",
        "STREET": "KAYAPINAR-YEŞİLYURT MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "GERCÜŞ",
        "STREET": "KESİKSU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "GERCÜŞ",
        "STREET": "KIRKAT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "GERCÜŞ",
        "STREET": "KIŞLAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "GERCÜŞ",
        "STREET": "KOYUNLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "GERCÜŞ",
        "STREET": "KOZLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "GERCÜŞ",
        "STREET": "KOÇAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "GERCÜŞ",
        "STREET": "KUTLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "GERCÜŞ",
        "STREET": "KÖMÜRCÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "GERCÜŞ",
        "STREET": "NURLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "GERCÜŞ",
        "STREET": "PINARBAŞI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "GERCÜŞ",
        "STREET": "POYRAZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "GERCÜŞ",
        "STREET": "RÜZGARLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "GERCÜŞ",
        "STREET": "SARGIN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "GERCÜŞ",
        "STREET": "SEKİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "GERCÜŞ",
        "STREET": "SERİNKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "GERCÜŞ",
        "STREET": "TAŞÇI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "GERCÜŞ",
        "STREET": "TEPECİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "GERCÜŞ",
        "STREET": "ULAŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "GERCÜŞ",
        "STREET": "YAKITLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "GERCÜŞ",
        "STREET": "YAMANLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "GERCÜŞ",
        "STREET": "YASSICA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "GERCÜŞ",
        "STREET": "YAYLADÜZÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "GERCÜŞ",
        "STREET": "YEMİŞLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "GERCÜŞ",
        "STREET": "YENİCE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "GERCÜŞ",
        "STREET": "YOLAĞZI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "GERCÜŞ",
        "STREET": "YÜCEKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "GERCÜŞ",
        "STREET": "ÇALIŞKAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "GERCÜŞ",
        "STREET": "ÇUKURYURT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "GERCÜŞ",
        "STREET": "ÇUKURÇEŞME MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "GERCÜŞ",
        "STREET": "ÇİÇEKLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "GERCÜŞ",
        "STREET": "ÖZLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "HASANKEYF",
        "STREET": "AKALIN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "HASANKEYF",
        "STREET": "AKSU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "HASANKEYF",
        "STREET": "BAYIRLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "HASANKEYF",
        "STREET": "BÜYÜKDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "HASANKEYF",
        "STREET": "DİCLE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "HASANKEYF",
        "STREET": "EYYUBİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "HASANKEYF",
        "STREET": "GÜNEŞLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "HASANKEYF",
        "STREET": "IRMAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "HASANKEYF",
        "STREET": "KARAKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "HASANKEYF",
        "STREET": "KAYIKLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "HASANKEYF",
        "STREET": "KUMLUCA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "HASANKEYF",
        "STREET": "KÜLTÜR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "HASANKEYF",
        "STREET": "RAMAN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "HASANKEYF",
        "STREET": "SAKLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "HASANKEYF",
        "STREET": "SOĞUCAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "HASANKEYF",
        "STREET": "TEPEBAŞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "HASANKEYF",
        "STREET": "UZUNDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "HASANKEYF",
        "STREET": "YAKAKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "HASANKEYF",
        "STREET": "YOLÜSTÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "HASANKEYF",
        "STREET": "ÇARDAKLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "HASANKEYF",
        "STREET": "ÖĞÜTLÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "HASANKEYF",
        "STREET": "ÜÇYOL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "HASANKEYF",
        "STREET": "İNCİRLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "KOZLUK",
        "STREET": "AKÇAKIŞLA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "KOZLUK",
        "STREET": "AKÇALI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "KOZLUK",
        "STREET": "ALIÇLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "KOZLUK",
        "STREET": "ARIKAYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "KOZLUK",
        "STREET": "ARMUTLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "KOZLUK",
        "STREET": "AĞAÇLIK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "KOZLUK",
        "STREET": "AŞAĞIGÜNEŞLİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "KOZLUK",
        "STREET": "AŞAĞIKIRATLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "KOZLUK",
        "STREET": "BAHÇELİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "KOZLUK",
        "STREET": "BEKİRHAN YENİDOĞAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "KOZLUK",
        "STREET": "BEKİRHAN-ADNAN MENDERES MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "KOZLUK",
        "STREET": "BEKİRHAN-GÜLPINAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "KOZLUK",
        "STREET": "BEKİRHAN-ORTAÇAY MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "KOZLUK",
        "STREET": "BEKİRHAN-TURGUT ÖZAL MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "KOZLUK",
        "STREET": "BEKİRHAN-YAPRAKLI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "KOZLUK",
        "STREET": "BEYBAĞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "KOZLUK",
        "STREET": "BEŞEVLER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "KOZLUK",
        "STREET": "BEŞKONAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "KOZLUK",
        "STREET": "BÖLÜKKONAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "KOZLUK",
        "STREET": "DANAGÖZÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "KOZLUK",
        "STREET": "DERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "KOZLUK",
        "STREET": "DERİNCE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "KOZLUK",
        "STREET": "DEĞİRMENDERE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "KOZLUK",
        "STREET": "DUYGULU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "KOZLUK",
        "STREET": "DÖVECİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "KOZLUK",
        "STREET": "ESKİCE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "KOZLUK",
        "STREET": "GEYİKLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "KOZLUK",
        "STREET": "GEÇİTALTI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "KOZLUK",
        "STREET": "GÜLLÜCE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "KOZLUK",
        "STREET": "GÜMÜŞÖRGÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "KOZLUK",
        "STREET": "GÜNDÜZLÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "KOZLUK",
        "STREET": "GÜNYAYLA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "KOZLUK",
        "STREET": "GÜRPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "KOZLUK",
        "STREET": "HAMAM MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "KOZLUK",
        "STREET": "KAHVECİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "KOZLUK",
        "STREET": "KALE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "KOZLUK",
        "STREET": "KALETEPE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "KOZLUK",
        "STREET": "KAMIŞLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "KOZLUK",
        "STREET": "KARAOĞLAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "KOZLUK",
        "STREET": "KARPUZLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "KOZLUK",
        "STREET": "KARPUZLU MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "KOZLUK",
        "STREET": "KARŞIYAKA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "KOZLUK",
        "STREET": "KAVAKDİBİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "KOZLUK",
        "STREET": "KAVAKLI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "KOZLUK",
        "STREET": "KAYADİBİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "KOZLUK",
        "STREET": "KEMALPAŞA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "KOZLUK",
        "STREET": "KOMANDO MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "KOZLUK",
        "STREET": "KONAKLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "KOZLUK",
        "STREET": "KOÇAKLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "KOZLUK",
        "STREET": "KULLUDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "KOZLUK",
        "STREET": "KUMLUPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "KOZLUK",
        "STREET": "KÖPRÜBAŞI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "KOZLUK",
        "STREET": "ORTACA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "KOZLUK",
        "STREET": "OYUKTAŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "KOZLUK",
        "STREET": "PARMAKKAPI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "KOZLUK",
        "STREET": "PINARBAŞI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "KOZLUK",
        "STREET": "PINARHİSAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "KOZLUK",
        "STREET": "SAMANYOLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "KOZLUK",
        "STREET": "SELAHADDİNİ EYYÜBİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "KOZLUK",
        "STREET": "SEYRANTEPE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "KOZLUK",
        "STREET": "SEYİTLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "KOZLUK",
        "STREET": "TAŞLIDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "KOZLUK",
        "STREET": "TAŞLIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "KOZLUK",
        "STREET": "TEPECİK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "KOZLUK",
        "STREET": "TEPEÜSTÜ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "KOZLUK",
        "STREET": "TOSUNPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "KOZLUK",
        "STREET": "TUZLAGÖZÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "KOZLUK",
        "STREET": "ULAŞLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "KOZLUK",
        "STREET": "UZUNYAZI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "KOZLUK",
        "STREET": "UZUNÇAYIR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "KOZLUK",
        "STREET": "YAMAÇLI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "KOZLUK",
        "STREET": "YANIKKAYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "KOZLUK",
        "STREET": "YANKILI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "KOZLUK",
        "STREET": "YAPAKLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "KOZLUK",
        "STREET": "YAYALAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "KOZLUK",
        "STREET": "YAZILI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "KOZLUK",
        "STREET": "YAZPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "KOZLUK",
        "STREET": "YEDİBÖLÜK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "KOZLUK",
        "STREET": "YENİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "KOZLUK",
        "STREET": "YENİDOĞAN ASE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "KOZLUK",
        "STREET": "YENİÇAĞLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "KOZLUK",
        "STREET": "YEŞİLTEPE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "KOZLUK",
        "STREET": "YILDIZLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "KOZLUK",
        "STREET": "YOLALTI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "KOZLUK",
        "STREET": "YOLÜSTÜ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "KOZLUK",
        "STREET": "YUKARI GÜNEŞLİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "KOZLUK",
        "STREET": "YUKARIKIRATLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "KOZLUK",
        "STREET": "ZİYARET KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "KOZLUK",
        "STREET": "ÇAMLICA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "KOZLUK",
        "STREET": "ÇAYBAŞI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "KOZLUK",
        "STREET": "ÇAYGEÇİT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "KOZLUK",
        "STREET": "ÇAYHAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "KOZLUK",
        "STREET": "ÇAYÖNÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "KOZLUK",
        "STREET": "ÇEVRECİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "KOZLUK",
        "STREET": "ÖRENSU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "KOZLUK",
        "STREET": "ÜNSALDI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "KOZLUK",
        "STREET": "İNİŞLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "KOZLUK",
        "STREET": "İSLAMBEY MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "KOZLUK",
        "STREET": "ŞEMDİNAĞA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "SASON",
        "STREET": "ACAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "SASON",
        "STREET": "ALTINDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "SASON",
        "STREET": "AYKUT OZAN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "SASON",
        "STREET": "AŞAĞI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "SASON",
        "STREET": "BALBAŞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "SASON",
        "STREET": "BAYRAMLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "SASON",
        "STREET": "BEŞEVLER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "SASON",
        "STREET": "BOĞAZKAPI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "SASON",
        "STREET": "CEVİZLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "SASON",
        "STREET": "DAĞÇATI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "SASON",
        "STREET": "DEMETEVLER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "SASON",
        "STREET": "DEREKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "SASON",
        "STREET": "DEREİÇİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "SASON",
        "STREET": "DERİNCE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "SASON",
        "STREET": "DUMLUPINAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "SASON",
        "STREET": "DÖRTBÖLÜK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "SASON",
        "STREET": "DİKBAYIR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "SASON",
        "STREET": "EKİNLİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "SASON",
        "STREET": "EMEK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "SASON",
        "STREET": "ERDEMLİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "SASON",
        "STREET": "ERGÜNÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "SASON",
        "STREET": "ERİKLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "SASON",
        "STREET": "GENÇLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "SASON",
        "STREET": "GEÇİTLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "SASON",
        "STREET": "GÜRGENLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "SASON",
        "STREET": "GÜRPINAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "SASON",
        "STREET": "GÜVERCİNLİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "SASON",
        "STREET": "HAYDAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "SASON",
        "STREET": "HEYBELİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "SASON",
        "STREET": "KALEYOLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "SASON",
        "STREET": "KARAMEŞE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "SASON",
        "STREET": "KARAYÜN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "SASON",
        "STREET": "KARŞIYAKA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "SASON",
        "STREET": "KAVAKLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "SASON",
        "STREET": "KAYABAŞI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "SASON",
        "STREET": "KAYADÜZÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "SASON",
        "STREET": "KAŞYAYLA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "SASON",
        "STREET": "KELHASAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "SASON",
        "STREET": "KINALI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "SASON",
        "STREET": "KULAKSIZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "SASON",
        "STREET": "KURTULUŞ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "SASON",
        "STREET": "KÖPRÜBAŞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "SASON",
        "STREET": "KİLİMLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "SASON",
        "STREET": "KİLİS KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "SASON",
        "STREET": "MEHMET AKİF MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "SASON",
        "STREET": "MEŞELİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "SASON",
        "STREET": "ORTA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "SASON",
        "STREET": "PINARBAŞI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "SASON",
        "STREET": "SARIYAYLA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "SASON",
        "STREET": "SEVEK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "SASON",
        "STREET": "SOĞANLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "SASON",
        "STREET": "TAŞYUVA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "SASON",
        "STREET": "TEKEVLER  MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "SASON",
        "STREET": "TEKİNLER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "SASON",
        "STREET": "TOPLUCA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "SASON",
        "STREET": "TURNALI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "SASON",
        "STREET": "UMURLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "SASON",
        "STREET": "YAKABAĞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "SASON",
        "STREET": "YAYLATEPE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "SASON",
        "STREET": "YENİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "SASON",
        "STREET": "YENİKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "SASON",
        "STREET": "YEŞİLEVLER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "SASON",
        "STREET": "YEŞİLLER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "SASON",
        "STREET": "YEŞİLOVA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "SASON",
        "STREET": "YEŞİLTEPE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "SASON",
        "STREET": "YILDIZ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "SASON",
        "STREET": "YOLÜSTÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "SASON",
        "STREET": "YUKARI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "SASON",
        "STREET": "YUVALAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "SASON",
        "STREET": "YUVALIÇAY KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "SASON",
        "STREET": "YÜCEBAĞ-AYDINLIK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "SASON",
        "STREET": "YÜCEBAĞ-BARIŞ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "SASON",
        "STREET": "YÜCEBAĞ-BİNEKLİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "SASON",
        "STREET": "YÜCEBAĞ-GÜNLÜCE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "SASON",
        "STREET": "YÜCEBAĞ-KARŞIYAKA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "SASON",
        "STREET": "YÜCEBAĞ-KOÇKAYA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "SASON",
        "STREET": "YÜCEBAĞ-TEPE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "SASON",
        "STREET": "YÜCEBAĞ-YILDIZ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "SASON",
        "STREET": "YÜREKLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "SASON",
        "STREET": "YİĞİTLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "SASON",
        "STREET": "ZAFER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "SASON",
        "STREET": "ZİYARET KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "SASON",
        "STREET": "ÇAKIRPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "SASON",
        "STREET": "ÇALIŞIRLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "SASON",
        "STREET": "ÇAYIRLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "SASON",
        "STREET": "ÇAĞLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "SASON",
        "STREET": "ÇINARLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "SASON",
        "STREET": "ÖRENAĞIL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "SASON",
        "STREET": "İNCESU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BATMAN",
        "DISTRICT": "SASON",
        "STREET": "ŞİRİNEVLER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BAYBURT",
        "DISTRICT": "AYDINTEPE",
        "STREET": "AHMET YESEVİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BAYBURT",
        "DISTRICT": "AYDINTEPE",
        "STREET": "AKBULUT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BAYBURT",
        "DISTRICT": "AYDINTEPE",
        "STREET": "ALACA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BAYBURT",
        "DISTRICT": "AYDINTEPE",
        "STREET": "AŞAĞIKIRZI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BAYBURT",
        "DISTRICT": "AYDINTEPE",
        "STREET": "BAŞPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BAYBURT",
        "DISTRICT": "AYDINTEPE",
        "STREET": "DUMLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BAYBURT",
        "DISTRICT": "AYDINTEPE",
        "STREET": "ERİKDİBİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BAYBURT",
        "DISTRICT": "AYDINTEPE",
        "STREET": "GÜMÜŞDAMLA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BAYBURT",
        "DISTRICT": "AYDINTEPE",
        "STREET": "GÜNBULDU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BAYBURT",
        "DISTRICT": "AYDINTEPE",
        "STREET": "HACI ALİ EFENDİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BAYBURT",
        "DISTRICT": "AYDINTEPE",
        "STREET": "KALE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BAYBURT",
        "DISTRICT": "AYDINTEPE",
        "STREET": "KAVLATAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BAYBURT",
        "DISTRICT": "AYDINTEPE",
        "STREET": "KILIÇKAYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BAYBURT",
        "DISTRICT": "AYDINTEPE",
        "STREET": "PINARGÖZÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BAYBURT",
        "DISTRICT": "AYDINTEPE",
        "STREET": "SIRATAŞLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BAYBURT",
        "DISTRICT": "AYDINTEPE",
        "STREET": "SORKUNLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BAYBURT",
        "DISTRICT": "AYDINTEPE",
        "STREET": "SULUDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BAYBURT",
        "DISTRICT": "AYDINTEPE",
        "STREET": "YAKUDİYE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BAYBURT",
        "DISTRICT": "AYDINTEPE",
        "STREET": "YANOBA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BAYBURT",
        "DISTRICT": "AYDINTEPE",
        "STREET": "YAPRACIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BAYBURT",
        "DISTRICT": "AYDINTEPE",
        "STREET": "YAZLIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BAYBURT",
        "DISTRICT": "AYDINTEPE",
        "STREET": "YUKARIKIRZI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BAYBURT",
        "DISTRICT": "AYDINTEPE",
        "STREET": "ÇATIKSU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BAYBURT",
        "DISTRICT": "AYDINTEPE",
        "STREET": "ÇAYIRKÖPRÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BAYBURT",
        "DISTRICT": "AYDINTEPE",
        "STREET": "ÇİĞDEMLİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BAYBURT",
        "DISTRICT": "AYDINTEPE",
        "STREET": "İNCİLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BAYBURT",
        "DISTRICT": "AYDINTEPE",
        "STREET": "ŞALCILAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BAYBURT",
        "DISTRICT": "MERKEZ",
        "STREET": "ADABAŞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BAYBURT",
        "DISTRICT": "MERKEZ",
        "STREET": "AKDURAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BAYBURT",
        "DISTRICT": "MERKEZ",
        "STREET": "AKSAÇLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BAYBURT",
        "DISTRICT": "MERKEZ",
        "STREET": "AKÇAKUZU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BAYBURT",
        "DISTRICT": "MERKEZ",
        "STREET": "AKŞAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BAYBURT",
        "DISTRICT": "MERKEZ",
        "STREET": "ALAPELİT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BAYBURT",
        "DISTRICT": "MERKEZ",
        "STREET": "ALIÇLIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BAYBURT",
        "DISTRICT": "MERKEZ",
        "STREET": "ARDIÇGÖZE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BAYBURT",
        "DISTRICT": "MERKEZ",
        "STREET": "ARMUTLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BAYBURT",
        "DISTRICT": "MERKEZ",
        "STREET": "ARPALI-CUMHURİYET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BAYBURT",
        "DISTRICT": "MERKEZ",
        "STREET": "ARPALI-HÜRRİYET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BAYBURT",
        "DISTRICT": "MERKEZ",
        "STREET": "ARPALI-ÇİÇEKLİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BAYBURT",
        "DISTRICT": "MERKEZ",
        "STREET": "ARSLANDEDE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BAYBURT",
        "DISTRICT": "MERKEZ",
        "STREET": "AYDINCIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BAYBURT",
        "DISTRICT": "MERKEZ",
        "STREET": "AĞÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BAYBURT",
        "DISTRICT": "MERKEZ",
        "STREET": "AŞAĞIKIŞLAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BAYBURT",
        "DISTRICT": "MERKEZ",
        "STREET": "AŞAĞIPINARLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BAYBURT",
        "DISTRICT": "MERKEZ",
        "STREET": "AŞAĞIÇIMAĞIL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BAYBURT",
        "DISTRICT": "MERKEZ",
        "STREET": "BALCA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BAYBURT",
        "DISTRICT": "MERKEZ",
        "STREET": "BALKAYNAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BAYBURT",
        "DISTRICT": "MERKEZ",
        "STREET": "BALLIKAYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BAYBURT",
        "DISTRICT": "MERKEZ",
        "STREET": "BAYBURT M TİPİ KAPALI CEZAEVİ",
        "TYPE": "0"
      },
      {
        "CITY": "BAYBURT",
        "DISTRICT": "MERKEZ",
        "STREET": "BAYIRTEPE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BAYBURT",
        "DISTRICT": "MERKEZ",
        "STREET": "BAYRAKTAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BAYBURT",
        "DISTRICT": "MERKEZ",
        "STREET": "BAŞÇIMAĞIL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BAYBURT",
        "DISTRICT": "MERKEZ",
        "STREET": "BUĞDAYLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BAYBURT",
        "DISTRICT": "MERKEZ",
        "STREET": "CAMİİKEBİR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BAYBURT",
        "DISTRICT": "MERKEZ",
        "STREET": "DANİŞMENT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BAYBURT",
        "DISTRICT": "MERKEZ",
        "STREET": "DARICA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BAYBURT",
        "DISTRICT": "MERKEZ",
        "STREET": "DAĞTARLA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BAYBURT",
        "DISTRICT": "MERKEZ",
        "STREET": "DAĞÇATI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BAYBURT",
        "DISTRICT": "MERKEZ",
        "STREET": "DEMİRIŞIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BAYBURT",
        "DISTRICT": "MERKEZ",
        "STREET": "DEMİRKAŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BAYBURT",
        "DISTRICT": "MERKEZ",
        "STREET": "DEĞİRMENCİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BAYBURT",
        "DISTRICT": "MERKEZ",
        "STREET": "DÖVMEKAYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BAYBURT",
        "DISTRICT": "MERKEZ",
        "STREET": "DİKMETAŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BAYBURT",
        "DISTRICT": "MERKEZ",
        "STREET": "ERENLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BAYBURT",
        "DISTRICT": "MERKEZ",
        "STREET": "ESENTEPE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BAYBURT",
        "DISTRICT": "MERKEZ",
        "STREET": "GENÇOSMAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BAYBURT",
        "DISTRICT": "MERKEZ",
        "STREET": "GENÇOSMAN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BAYBURT",
        "DISTRICT": "MERKEZ",
        "STREET": "GEZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BAYBURT",
        "DISTRICT": "MERKEZ",
        "STREET": "GÖKLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BAYBURT",
        "DISTRICT": "MERKEZ",
        "STREET": "GÖKPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BAYBURT",
        "DISTRICT": "MERKEZ",
        "STREET": "GÖKÇELİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BAYBURT",
        "DISTRICT": "MERKEZ",
        "STREET": "GÖLDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BAYBURT",
        "DISTRICT": "MERKEZ",
        "STREET": "GÖLOBA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BAYBURT",
        "DISTRICT": "MERKEZ",
        "STREET": "GÜDER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BAYBURT",
        "DISTRICT": "MERKEZ",
        "STREET": "GÜLLÜCE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BAYBURT",
        "DISTRICT": "MERKEZ",
        "STREET": "GÜMÜŞSU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BAYBURT",
        "DISTRICT": "MERKEZ",
        "STREET": "GÜNEYDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BAYBURT",
        "DISTRICT": "MERKEZ",
        "STREET": "GÜZELCE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BAYBURT",
        "DISTRICT": "MERKEZ",
        "STREET": "HACIOĞLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BAYBURT",
        "DISTRICT": "MERKEZ",
        "STREET": "HALFİKALE (KALEARDI) MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BAYBURT",
        "DISTRICT": "MERKEZ",
        "STREET": "HARMANÖZÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BAYBURT",
        "DISTRICT": "MERKEZ",
        "STREET": "HELVA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BAYBURT",
        "DISTRICT": "MERKEZ",
        "STREET": "HEYBETEPE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BAYBURT",
        "DISTRICT": "MERKEZ",
        "STREET": "IĞDIR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BAYBURT",
        "DISTRICT": "MERKEZ",
        "STREET": "KABAÇAYIR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BAYBURT",
        "DISTRICT": "MERKEZ",
        "STREET": "KADIZADE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BAYBURT",
        "DISTRICT": "MERKEZ",
        "STREET": "KARASAKAL MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BAYBURT",
        "DISTRICT": "MERKEZ",
        "STREET": "KARLICA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BAYBURT",
        "DISTRICT": "MERKEZ",
        "STREET": "KARŞIGEÇİT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BAYBURT",
        "DISTRICT": "MERKEZ",
        "STREET": "KAVACIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BAYBURT",
        "DISTRICT": "MERKEZ",
        "STREET": "KAVAKYANI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BAYBURT",
        "DISTRICT": "MERKEZ",
        "STREET": "KEMERTAŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BAYBURT",
        "DISTRICT": "MERKEZ",
        "STREET": "KIRATLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BAYBURT",
        "DISTRICT": "MERKEZ",
        "STREET": "KIRKPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BAYBURT",
        "DISTRICT": "MERKEZ",
        "STREET": "KONURSU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BAYBURT",
        "DISTRICT": "MERKEZ",
        "STREET": "KOP KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BAYBURT",
        "DISTRICT": "MERKEZ",
        "STREET": "KOPUZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BAYBURT",
        "DISTRICT": "MERKEZ",
        "STREET": "KOZLUK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BAYBURT",
        "DISTRICT": "MERKEZ",
        "STREET": "KOÇBAYIR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BAYBURT",
        "DISTRICT": "MERKEZ",
        "STREET": "KURBANPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BAYBURT",
        "DISTRICT": "MERKEZ",
        "STREET": "KURUGÜNEY KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BAYBURT",
        "DISTRICT": "MERKEZ",
        "STREET": "KİTRE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BAYBURT",
        "DISTRICT": "MERKEZ",
        "STREET": "MADEN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BAYBURT",
        "DISTRICT": "MERKEZ",
        "STREET": "MANAS KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BAYBURT",
        "DISTRICT": "MERKEZ",
        "STREET": "MASAT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BAYBURT",
        "DISTRICT": "MERKEZ",
        "STREET": "MEHMET ÇELEBİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BAYBURT",
        "DISTRICT": "MERKEZ",
        "STREET": "MUTLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BAYBURT",
        "DISTRICT": "MERKEZ",
        "STREET": "NİŞANTAŞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BAYBURT",
        "DISTRICT": "MERKEZ",
        "STREET": "ORTAÇIMAĞIL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BAYBURT",
        "DISTRICT": "MERKEZ",
        "STREET": "ORUÇBEYLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BAYBURT",
        "DISTRICT": "MERKEZ",
        "STREET": "OZANSU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BAYBURT",
        "DISTRICT": "MERKEZ",
        "STREET": "PAMUKTAŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BAYBURT",
        "DISTRICT": "MERKEZ",
        "STREET": "PELİTLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BAYBURT",
        "DISTRICT": "MERKEZ",
        "STREET": "PETEKKAYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BAYBURT",
        "DISTRICT": "MERKEZ",
        "STREET": "POLATLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BAYBURT",
        "DISTRICT": "MERKEZ",
        "STREET": "RÜŞTÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BAYBURT",
        "DISTRICT": "MERKEZ",
        "STREET": "SAKIZLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BAYBURT",
        "DISTRICT": "MERKEZ",
        "STREET": "SALKIMSU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BAYBURT",
        "DISTRICT": "MERKEZ",
        "STREET": "SANCAKTEPE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BAYBURT",
        "DISTRICT": "MERKEZ",
        "STREET": "SARAYCIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BAYBURT",
        "DISTRICT": "MERKEZ",
        "STREET": "SARIHAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BAYBURT",
        "DISTRICT": "MERKEZ",
        "STREET": "SARIMEŞE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BAYBURT",
        "DISTRICT": "MERKEZ",
        "STREET": "SEYDİYAKUP KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BAYBURT",
        "DISTRICT": "MERKEZ",
        "STREET": "SIRAKAYALAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BAYBURT",
        "DISTRICT": "MERKEZ",
        "STREET": "SIĞIRCI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BAYBURT",
        "DISTRICT": "MERKEZ",
        "STREET": "SOĞUKGÖZE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BAYBURT",
        "DISTRICT": "MERKEZ",
        "STREET": "SÖĞÜTLÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BAYBURT",
        "DISTRICT": "MERKEZ",
        "STREET": "TAHT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BAYBURT",
        "DISTRICT": "MERKEZ",
        "STREET": "TAŞBURUN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BAYBURT",
        "DISTRICT": "MERKEZ",
        "STREET": "TAŞKESEN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BAYBURT",
        "DISTRICT": "MERKEZ",
        "STREET": "TAŞOCAĞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BAYBURT",
        "DISTRICT": "MERKEZ",
        "STREET": "TAŞÇILAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BAYBURT",
        "DISTRICT": "MERKEZ",
        "STREET": "TEPETARLA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BAYBURT",
        "DISTRICT": "MERKEZ",
        "STREET": "TOMLACIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BAYBURT",
        "DISTRICT": "MERKEZ",
        "STREET": "TUZCUZADE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BAYBURT",
        "DISTRICT": "MERKEZ",
        "STREET": "ULUÇAYIR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BAYBURT",
        "DISTRICT": "MERKEZ",
        "STREET": "UZUNGAZİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BAYBURT",
        "DISTRICT": "MERKEZ",
        "STREET": "UĞRAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BAYBURT",
        "DISTRICT": "MERKEZ",
        "STREET": "UĞURGELDİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BAYBURT",
        "DISTRICT": "MERKEZ",
        "STREET": "VELİŞABAN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BAYBURT",
        "DISTRICT": "MERKEZ",
        "STREET": "VEYSEL MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BAYBURT",
        "DISTRICT": "MERKEZ",
        "STREET": "YANIKÇAM KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BAYBURT",
        "DISTRICT": "MERKEZ",
        "STREET": "YAYLALAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BAYBURT",
        "DISTRICT": "MERKEZ",
        "STREET": "YAYLAPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BAYBURT",
        "DISTRICT": "MERKEZ",
        "STREET": "YAZYURDU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BAYBURT",
        "DISTRICT": "MERKEZ",
        "STREET": "YEDİGÖZE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BAYBURT",
        "DISTRICT": "MERKEZ",
        "STREET": "YENİKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "BAYBURT",
        "DISTRICT": "MERKEZ",
        "STREET": "YERLİCE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BAYBURT",
        "DISTRICT": "MERKEZ",
        "STREET": "YEŞİLYURT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BAYBURT",
        "DISTRICT": "MERKEZ",
        "STREET": "YOLALTI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BAYBURT",
        "DISTRICT": "MERKEZ",
        "STREET": "YONCALI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BAYBURT",
        "DISTRICT": "MERKEZ",
        "STREET": "YUKARIKIŞLAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BAYBURT",
        "DISTRICT": "MERKEZ",
        "STREET": "ZAHİT MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BAYBURT",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇAKIRBAĞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BAYBURT",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇALIDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BAYBURT",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇAMDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BAYBURT",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇAMLIKOZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BAYBURT",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇAYIRYOLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BAYBURT",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇAYIRÖZÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BAYBURT",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇERÇİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BAYBURT",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇORAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BAYBURT",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇİĞDEMTEPE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BAYBURT",
        "DISTRICT": "MERKEZ",
        "STREET": "ÖRENCE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BAYBURT",
        "DISTRICT": "MERKEZ",
        "STREET": "ÜZENGİLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BAYBURT",
        "DISTRICT": "MERKEZ",
        "STREET": "ŞEYHHAYRAN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BAYBURT",
        "DISTRICT": "MERKEZ",
        "STREET": "ŞİNGAH MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BAYBURT",
        "DISTRICT": "DEMİRÖZÜ",
        "STREET": "AKYAKA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BAYBURT",
        "DISTRICT": "DEMİRÖZÜ",
        "STREET": "BAYRAMPAŞA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BAYBURT",
        "DISTRICT": "DEMİRÖZÜ",
        "STREET": "BEŞPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BAYBURT",
        "DISTRICT": "DEMİRÖZÜ",
        "STREET": "DAMLICA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BAYBURT",
        "DISTRICT": "DEMİRÖZÜ",
        "STREET": "DEVETAŞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BAYBURT",
        "DISTRICT": "DEMİRÖZÜ",
        "STREET": "DİKMETAŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BAYBURT",
        "DISTRICT": "DEMİRÖZÜ",
        "STREET": "ELMALI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BAYBURT",
        "DISTRICT": "DEMİRÖZÜ",
        "STREET": "ESENTEPE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BAYBURT",
        "DISTRICT": "DEMİRÖZÜ",
        "STREET": "EYMÜR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BAYBURT",
        "DISTRICT": "DEMİRÖZÜ",
        "STREET": "GÖKÇEDERE-KARŞIYAKA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BAYBURT",
        "DISTRICT": "DEMİRÖZÜ",
        "STREET": "GÖKÇEDERE-PINARBAŞI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BAYBURT",
        "DISTRICT": "DEMİRÖZÜ",
        "STREET": "GÖKÇEDERE-YEŞİLYURT MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BAYBURT",
        "DISTRICT": "DEMİRÖZÜ",
        "STREET": "GÜNEŞLİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BAYBURT",
        "DISTRICT": "DEMİRÖZÜ",
        "STREET": "GÜVERCİNDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BAYBURT",
        "DISTRICT": "DEMİRÖZÜ",
        "STREET": "GÜÇLÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BAYBURT",
        "DISTRICT": "DEMİRÖZÜ",
        "STREET": "IŞIKOVA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BAYBURT",
        "DISTRICT": "DEMİRÖZÜ",
        "STREET": "KALECİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BAYBURT",
        "DISTRICT": "DEMİRÖZÜ",
        "STREET": "KARAYAŞMAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BAYBURT",
        "DISTRICT": "DEMİRÖZÜ",
        "STREET": "KAVAKLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BAYBURT",
        "DISTRICT": "DEMİRÖZÜ",
        "STREET": "OTLUKBELİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BAYBURT",
        "DISTRICT": "DEMİRÖZÜ",
        "STREET": "PETEKLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BAYBURT",
        "DISTRICT": "DEMİRÖZÜ",
        "STREET": "PINARCIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BAYBURT",
        "DISTRICT": "DEMİRÖZÜ",
        "STREET": "SERENLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BAYBURT",
        "DISTRICT": "DEMİRÖZÜ",
        "STREET": "YAKUPABDAL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BAYBURT",
        "DISTRICT": "DEMİRÖZÜ",
        "STREET": "YAZIBAŞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BAYBURT",
        "DISTRICT": "DEMİRÖZÜ",
        "STREET": "YELPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BAYBURT",
        "DISTRICT": "DEMİRÖZÜ",
        "STREET": "YUKARIPINARLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BAYBURT",
        "DISTRICT": "DEMİRÖZÜ",
        "STREET": "ÇAKIRÖZÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BAYBURT",
        "DISTRICT": "DEMİRÖZÜ",
        "STREET": "ÇATALÇEŞME KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BAYBURT",
        "DISTRICT": "DEMİRÖZÜ",
        "STREET": "ÇAĞILLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BAYBURT",
        "DISTRICT": "DEMİRÖZÜ",
        "STREET": "ÇİFTETAŞ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BAYBURT",
        "DISTRICT": "DEMİRÖZÜ",
        "STREET": "ÇİMENTEPE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BAYBURT",
        "DISTRICT": "DEMİRÖZÜ",
        "STREET": "ŞİRİN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "MERKEZ",
        "STREET": "AFŞAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "MERKEZ",
        "STREET": "AHMETLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "MERKEZ",
        "STREET": "AKPINAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "MERKEZ",
        "STREET": "AKTAŞ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "MERKEZ",
        "STREET": "AKÇAALAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "MERKEZ",
        "STREET": "ALIÇÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "MERKEZ",
        "STREET": "ALPAĞUT MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "MERKEZ",
        "STREET": "ALPAĞUTBEY MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "MERKEZ",
        "STREET": "AVDAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "MERKEZ",
        "STREET": "AYDINCIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "MERKEZ",
        "STREET": "AĞAÇCILAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "MERKEZ",
        "STREET": "AŞAĞIKUZÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "MERKEZ",
        "STREET": "AŞAĞISOKU MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "MERKEZ",
        "STREET": "AŞAĞIÇAMLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "MERKEZ",
        "STREET": "BAHÇEKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "MERKEZ",
        "STREET": "BAHÇELİEVLER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "MERKEZ",
        "STREET": "BAKIRLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "MERKEZ",
        "STREET": "BALTALI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "MERKEZ",
        "STREET": "BANAZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "MERKEZ",
        "STREET": "BAĞIŞLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "MERKEZ",
        "STREET": "BELKARAAĞAÇ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "MERKEZ",
        "STREET": "BERK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "MERKEZ",
        "STREET": "BEŞKAVAKLAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "MERKEZ",
        "STREET": "BOLU F TİPİ CEZAEVİ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "MERKEZ",
        "STREET": "BOLU T TİPİ KAPALI CEZAEVİ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "MERKEZ",
        "STREET": "BORAZANLAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "MERKEZ",
        "STREET": "BOZARMUT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "MERKEZ",
        "STREET": "BÜNÜŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "MERKEZ",
        "STREET": "BÜRNÜK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "MERKEZ",
        "STREET": "BÜYÜKCAMİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "MERKEZ",
        "STREET": "DAĞKENT MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "MERKEZ",
        "STREET": "DEMİRCİLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "MERKEZ",
        "STREET": "DERECEÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "MERKEZ",
        "STREET": "DEĞİRMENBELİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "MERKEZ",
        "STREET": "DEĞİRMENDERESİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "MERKEZ",
        "STREET": "DODURGA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "MERKEZ",
        "STREET": "DOĞANCI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "MERKEZ",
        "STREET": "ELMALIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "MERKEZ",
        "STREET": "ERİCEK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "MERKEZ",
        "STREET": "FASIL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "MERKEZ",
        "STREET": "GÖKPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "MERKEZ",
        "STREET": "GÖLCÜK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "MERKEZ",
        "STREET": "GÖLKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "MERKEZ",
        "STREET": "GÖLYÜZÜ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "MERKEZ",
        "STREET": "GÖVEM KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "MERKEZ",
        "STREET": "GÜNEYFELEKETTİN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "MERKEZ",
        "STREET": "HAMZABEY KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "MERKEZ",
        "STREET": "HIDIRŞEYHLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "MERKEZ",
        "STREET": "ILICAKINIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "MERKEZ",
        "STREET": "IŞIKLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "MERKEZ",
        "STREET": "KANDAMIŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "MERKEZ",
        "STREET": "KARACAAĞAÇ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "MERKEZ",
        "STREET": "KARACASU-KARACASU FATİH MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "MERKEZ",
        "STREET": "KARAKÖY MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "MERKEZ",
        "STREET": "KARAMANLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "MERKEZ",
        "STREET": "KARAMANLI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "MERKEZ",
        "STREET": "KARAÇAYIR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "MERKEZ",
        "STREET": "KARCA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "MERKEZ",
        "STREET": "KASAPLAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "MERKEZ",
        "STREET": "KETENLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "MERKEZ",
        "STREET": "KILIÇARSLAN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "MERKEZ",
        "STREET": "KINDIRA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "MERKEZ",
        "STREET": "KIRHA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "MERKEZ",
        "STREET": "KIZILAĞIL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "MERKEZ",
        "STREET": "KOLKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "MERKEZ",
        "STREET": "KOZLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "MERKEZ",
        "STREET": "KURUÇAY MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "MERKEZ",
        "STREET": "KUZFINDIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "MERKEZ",
        "STREET": "KUZÖRENDAĞLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "MERKEZ",
        "STREET": "KUZÖRENEMİRLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "MERKEZ",
        "STREET": "KÖPRÜCÜLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "MERKEZ",
        "STREET": "KÖROĞLU MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "MERKEZ",
        "STREET": "KÜLTÜR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "MERKEZ",
        "STREET": "KÜPLÜCE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "MERKEZ",
        "STREET": "KÜRKÇÜLER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "MERKEZ",
        "STREET": "KÜÇÜKBERK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "MERKEZ",
        "STREET": "MERKEŞLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "MERKEZ",
        "STREET": "MESCİLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "MERKEZ",
        "STREET": "MESCİÇELE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "MERKEZ",
        "STREET": "MURATLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "MERKEZ",
        "STREET": "MUSLUKLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "MERKEZ",
        "STREET": "MÜSTAKİMLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "MERKEZ",
        "STREET": "NUHLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "MERKEZ",
        "STREET": "OKÇULAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "MERKEZ",
        "STREET": "OVADÜZÜ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "MERKEZ",
        "STREET": "OĞULDURUK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "MERKEZ",
        "STREET": "PAŞAKÖY MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "MERKEZ",
        "STREET": "PELİTCİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "MERKEZ",
        "STREET": "PİRAHMETLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "MERKEZ",
        "STREET": "PİROĞLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "MERKEZ",
        "STREET": "RÜZGARLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "MERKEZ",
        "STREET": "SALIBEYLER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "MERKEZ",
        "STREET": "SANDALLAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "MERKEZ",
        "STREET": "SARAYCIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "MERKEZ",
        "STREET": "SARICALAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "MERKEZ",
        "STREET": "SAZAKKINIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "MERKEZ",
        "STREET": "SAZAKŞEYHLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "MERKEZ",
        "STREET": "SAÇÇILAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "MERKEZ",
        "STREET": "SAĞLIK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "MERKEZ",
        "STREET": "SEBENARDI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "MERKEZ",
        "STREET": "SEMERCİLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "MERKEZ",
        "STREET": "SEMERKANT MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "MERKEZ",
        "STREET": "SEYİT MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "MERKEZ",
        "STREET": "SULTAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "MERKEZ",
        "STREET": "SULTANBEY KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "MERKEZ",
        "STREET": "SUSUZKINIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "MERKEZ",
        "STREET": "SÜMER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "MERKEZ",
        "STREET": "TABAKLAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "MERKEZ",
        "STREET": "TARAKÇI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "MERKEZ",
        "STREET": "TATLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "MERKEZ",
        "STREET": "TAŞOLUK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "MERKEZ",
        "STREET": "TAŞÇILAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "MERKEZ",
        "STREET": "TEKKEDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "MERKEZ",
        "STREET": "TEPECİK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "MERKEZ",
        "STREET": "TETEMEÇELE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "MERKEZ",
        "STREET": "TOKMAKLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "MERKEZ",
        "STREET": "TOPARDIÇ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "MERKEZ",
        "STREET": "ULUMESCİT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "MERKEZ",
        "STREET": "UMUTKENT MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "MERKEZ",
        "STREET": "VAKIFGEÇİTVEREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "MERKEZ",
        "STREET": "YAKABAYAT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "MERKEZ",
        "STREET": "YAKUPLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "MERKEZ",
        "STREET": "YAYLADINLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "MERKEZ",
        "STREET": "YAZIÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "MERKEZ",
        "STREET": "YAŞAMKENT MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "MERKEZ",
        "STREET": "YENİAKÇAKAVAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "MERKEZ",
        "STREET": "YENİCEPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "MERKEZ",
        "STREET": "YENİGEÇİTVEREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "MERKEZ",
        "STREET": "YENİGÜNEY KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "MERKEZ",
        "STREET": "YENİKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "MERKEZ",
        "STREET": "YENİMAHALLE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "MERKEZ",
        "STREET": "YENİPELİTCİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "MERKEZ",
        "STREET": "YENİSEFA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "MERKEZ",
        "STREET": "YENİÇAYDURT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "MERKEZ",
        "STREET": "YEŞİLKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "MERKEZ",
        "STREET": "YEŞİLÇELE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "MERKEZ",
        "STREET": "YOLÇATI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "MERKEZ",
        "STREET": "YUKARISOKU MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "MERKEZ",
        "STREET": "YUKARIÇAMLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "MERKEZ",
        "STREET": "YUMRUKAYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "MERKEZ",
        "STREET": "YUVA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇAKMAKLAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇAMPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇAMYAYLA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇANAKÇILAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇATAKÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇAYGÖKPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇAYIRKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇEPNİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇIKINLAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇOBANKAYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇUKURÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇÖMLEKÇİLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇİVRİL MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "MERKEZ",
        "STREET": "ÖMERLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "MERKEZ",
        "STREET": "ÖRENCİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "MERKEZ",
        "STREET": "İHSANİYE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "MERKEZ",
        "STREET": "İZZET BAYSAL MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "DÖRTDİVAN",
        "STREET": "ADAKINIK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "DÖRTDİVAN",
        "STREET": "ADAKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "DÖRTDİVAN",
        "STREET": "AŞAĞIDÜĞER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "DÖRTDİVAN",
        "STREET": "AŞAĞISAYIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "DÖRTDİVAN",
        "STREET": "BAYRAMLAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "DÖRTDİVAN",
        "STREET": "BÜNÜŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "DÖRTDİVAN",
        "STREET": "CEMALLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "DÖRTDİVAN",
        "STREET": "DEVECİLER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "DÖRTDİVAN",
        "STREET": "DOĞANCILAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "DÖRTDİVAN",
        "STREET": "DÜLGER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "DÖRTDİVAN",
        "STREET": "GÖBÜLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "DÖRTDİVAN",
        "STREET": "GÜCÜKLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "DÖRTDİVAN",
        "STREET": "HACETLER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "DÖRTDİVAN",
        "STREET": "KADILAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "DÖRTDİVAN",
        "STREET": "KARGIBAYRAMLAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "DÖRTDİVAN",
        "STREET": "KILIÇLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "DÖRTDİVAN",
        "STREET": "KURUCA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "DÖRTDİVAN",
        "STREET": "ORTAKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "DÖRTDİVAN",
        "STREET": "SEYİTALİLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "DÖRTDİVAN",
        "STREET": "SORKUN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "DÖRTDİVAN",
        "STREET": "SÜLELER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "DÖRTDİVAN",
        "STREET": "YALACIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "DÖRTDİVAN",
        "STREET": "YAYALAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "DÖRTDİVAN",
        "STREET": "YAĞBAŞLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "DÖRTDİVAN",
        "STREET": "YUKARIDÜĞER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "DÖRTDİVAN",
        "STREET": "YUKARISAYIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "DÖRTDİVAN",
        "STREET": "ÇALKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "DÖRTDİVAN",
        "STREET": "ÇARDAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "DÖRTDİVAN",
        "STREET": "ÇAVUŞLAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "DÖRTDİVAN",
        "STREET": "ÇETİKÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "DÖRTDİVAN",
        "STREET": "ÇİTLER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "DÖRTDİVAN",
        "STREET": "ÖMERPAŞALAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "GEREDE",
        "STREET": "AFŞARTARAKÇI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "GEREDE",
        "STREET": "AHMETLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "GEREDE",
        "STREET": "AKBAŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "GEREDE",
        "STREET": "AKTAŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "GEREDE",
        "STREET": "AKTAŞKURTLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "GEREDE",
        "STREET": "AKÇABEY KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "GEREDE",
        "STREET": "AKÇAŞEHİR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "GEREDE",
        "STREET": "ASMACA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "GEREDE",
        "STREET": "AYDINLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "GEREDE",
        "STREET": "AĞIZÖRENGÜNEY KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "GEREDE",
        "STREET": "AŞAĞIOVACIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "GEREDE",
        "STREET": "AŞAĞIÖRENBAŞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "GEREDE",
        "STREET": "BAHÇEDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "GEREDE",
        "STREET": "BAHÇELİEVLER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "GEREDE",
        "STREET": "BALCILAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "GEREDE",
        "STREET": "BEŞKONAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "GEREDE",
        "STREET": "BÜNÜŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "GEREDE",
        "STREET": "BİRİNCİAFŞAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "GEREDE",
        "STREET": "DANİŞMENTLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "GEREDE",
        "STREET": "DAVUTBEYLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "GEREDE",
        "STREET": "DAYIOĞLU MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "GEREDE",
        "STREET": "DAĞKARA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "GEREDE",
        "STREET": "DEMİRCİLER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "GEREDE",
        "STREET": "DEMİRCİSOPRAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "GEREDE",
        "STREET": "DEMİRLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "GEREDE",
        "STREET": "DURSUNFAKI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "GEREDE",
        "STREET": "DİKMEN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "GEREDE",
        "STREET": "ELÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "GEREDE",
        "STREET": "ENSELİLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "GEREDE",
        "STREET": "ERTUĞRULKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "GEREDE",
        "STREET": "EYMÜR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "GEREDE",
        "STREET": "GEREDE KAPALI CEZA İNFAZ KURUMU",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "GEREDE",
        "STREET": "GEÇİTLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "GEREDE",
        "STREET": "GÖYNÜKÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "GEREDE",
        "STREET": "GÜNEYDEMİRCİLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "GEREDE",
        "STREET": "HACILAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "GEREDE",
        "STREET": "HALAÇLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "GEREDE",
        "STREET": "HASANLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "GEREDE",
        "STREET": "HAVULLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "GEREDE",
        "STREET": "IBRICAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "GEREDE",
        "STREET": "KABİLLER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "GEREDE",
        "STREET": "KALAÇ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "GEREDE",
        "STREET": "KAPAKLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "GEREDE",
        "STREET": "KARACADAĞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "GEREDE",
        "STREET": "KARACADAĞDEMİRCİLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "GEREDE",
        "STREET": "KARAPAZAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "GEREDE",
        "STREET": "KAVACIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "GEREDE",
        "STREET": "KAYIKİRAZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "GEREDE",
        "STREET": "KAYISOPRAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "GEREDE",
        "STREET": "KAZANLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "GEREDE",
        "STREET": "KOÇUMLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "GEREDE",
        "STREET": "KÖSRELİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "GEREDE",
        "STREET": "KÜLEF KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "GEREDE",
        "STREET": "KÜRKÇÜLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "GEREDE",
        "STREET": "KİTİRLER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "GEREDE",
        "STREET": "MACARLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "GEREDE",
        "STREET": "MANGALLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "GEREDE",
        "STREET": "MUKAMLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "GEREDE",
        "STREET": "MURATFAKILAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "GEREDE",
        "STREET": "MÜRDÜKLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "GEREDE",
        "STREET": "MİRCEKİRAZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "GEREDE",
        "STREET": "NUHÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "GEREDE",
        "STREET": "ORTA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "GEREDE",
        "STREET": "ORTACA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "GEREDE",
        "STREET": "SALUR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "GEREDE",
        "STREET": "SAMAT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "GEREDE",
        "STREET": "SAPANLIURGANCILAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "GEREDE",
        "STREET": "SARIOĞLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "GEREDE",
        "STREET": "SEVİLLER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "GEREDE",
        "STREET": "SOFULAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "GEREDE",
        "STREET": "SUNGURLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "GEREDE",
        "STREET": "SÜLLERTOKLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "GEREDE",
        "STREET": "SİPAHİLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "GEREDE",
        "STREET": "TATLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "GEREDE",
        "STREET": "ULAŞLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "GEREDE",
        "STREET": "YAKABOY KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "GEREDE",
        "STREET": "YAKAKAYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "GEREDE",
        "STREET": "YAZIKARA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "GEREDE",
        "STREET": "YAZIKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "GEREDE",
        "STREET": "YAĞDAŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "GEREDE",
        "STREET": "YELKENLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "GEREDE",
        "STREET": "YENECİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "GEREDE",
        "STREET": "YENİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "GEREDE",
        "STREET": "YENİYAPAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "GEREDE",
        "STREET": "YEŞİLVADİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "GEREDE",
        "STREET": "YUKARIOVACIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "GEREDE",
        "STREET": "YUKARIÖRENBAŞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "GEREDE",
        "STREET": "YUNUSLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "GEREDE",
        "STREET": "ZEYNELLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "GEREDE",
        "STREET": "ÇALAMAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "GEREDE",
        "STREET": "ÇALIŞLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "GEREDE",
        "STREET": "ÇAYÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "GEREDE",
        "STREET": "ÇAYÖRENGÜNEY KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "GEREDE",
        "STREET": "ÇAĞIŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "GEREDE",
        "STREET": "ÇOĞULLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "GEREDE",
        "STREET": "ÇUKURCA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "GEREDE",
        "STREET": "ÖRENCİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "GEREDE",
        "STREET": "ÜMİTKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "GEREDE",
        "STREET": "İKİNCİAFŞAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "GEREDE",
        "STREET": "İMAMLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "GEREDE",
        "STREET": "İNKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "GÖYNÜK",
        "STREET": "AHMETBEYLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "GÖYNÜK",
        "STREET": "AKSAKLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "GÖYNÜK",
        "STREET": "AKÇAALAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "GÖYNÜK",
        "STREET": "ALANKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "GÖYNÜK",
        "STREET": "ARIKÇAYIRI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "GÖYNÜK",
        "STREET": "ARIZLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "GÖYNÜK",
        "STREET": "AŞAĞIKINIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "GÖYNÜK",
        "STREET": "BAYINDIR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "GÖYNÜK",
        "STREET": "BEKİRFAKILAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "GÖYNÜK",
        "STREET": "BOYACILAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "GÖYNÜK",
        "STREET": "BOZCAARMUT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "GÖYNÜK",
        "STREET": "BULANIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "GÖYNÜK",
        "STREET": "BÖLÜCEKOVA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "GÖYNÜK",
        "STREET": "CEYLANLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "GÖYNÜK",
        "STREET": "CUMA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "GÖYNÜK",
        "STREET": "DAĞHACILAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "GÖYNÜK",
        "STREET": "DAĞŞEYHLERİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "GÖYNÜK",
        "STREET": "DEDELER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "GÖYNÜK",
        "STREET": "DEMİRHANLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "GÖYNÜK",
        "STREET": "DEĞİRMENÖZÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "GÖYNÜK",
        "STREET": "EKİNCİLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "GÖYNÜK",
        "STREET": "GERİŞLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "GÖYNÜK",
        "STREET": "GÖKÇESARAY KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "GÖYNÜK",
        "STREET": "GÜNEYÇALICA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "GÖYNÜK",
        "STREET": "GÜRPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "GÖYNÜK",
        "STREET": "HACI ABDİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "GÖYNÜK",
        "STREET": "HACIMAHMUT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "GÖYNÜK",
        "STREET": "HASANLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "GÖYNÜK",
        "STREET": "HİLALLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "GÖYNÜK",
        "STREET": "HİMMETOĞLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "GÖYNÜK",
        "STREET": "HİSARÖZÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "GÖYNÜK",
        "STREET": "KARAALİLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "GÖYNÜK",
        "STREET": "KARAARDIÇ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "GÖYNÜK",
        "STREET": "KARACALAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "GÖYNÜK",
        "STREET": "KAYABAŞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "GÖYNÜK",
        "STREET": "KAYALIDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "GÖYNÜK",
        "STREET": "KAŞIKÇIŞEYHLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "GÖYNÜK",
        "STREET": "KEPKEBİR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "GÖYNÜK",
        "STREET": "KILAVUZLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "GÖYNÜK",
        "STREET": "KIZILKUYU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "GÖYNÜK",
        "STREET": "KOZCAĞIZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "GÖYNÜK",
        "STREET": "KUMCUK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "GÖYNÜK",
        "STREET": "KUYUPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "GÖYNÜK",
        "STREET": "KÖYBAŞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "GÖYNÜK",
        "STREET": "KÜRNÜÇ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "GÖYNÜK",
        "STREET": "KİLCİLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "GÖYNÜK",
        "STREET": "MEMECELER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "GÖYNÜK",
        "STREET": "MUSTANLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "GÖYNÜK",
        "STREET": "NARZANLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "GÖYNÜK",
        "STREET": "PELİTCİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "GÖYNÜK",
        "STREET": "SARICALAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "GÖYNÜK",
        "STREET": "SARILAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "GÖYNÜK",
        "STREET": "SOFU ALİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "GÖYNÜK",
        "STREET": "SOĞUKÇAM KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "GÖYNÜK",
        "STREET": "SUSUZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "GÖYNÜK",
        "STREET": "SÜNNET KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "GÖYNÜK",
        "STREET": "TEKİRLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "GÖYNÜK",
        "STREET": "TEPEBAŞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "GÖYNÜK",
        "STREET": "UMURLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "GÖYNÜK",
        "STREET": "YENİCE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "GÖYNÜK",
        "STREET": "YENİKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "GÖYNÜK",
        "STREET": "YEŞİLYAZI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "GÖYNÜK",
        "STREET": "YUKARIKINIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "GÖYNÜK",
        "STREET": "ÇAMLICA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "GÖYNÜK",
        "STREET": "ÇAPAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "GÖYNÜK",
        "STREET": "ÇATACIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "GÖYNÜK",
        "STREET": "ÇAYKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "GÖYNÜK",
        "STREET": "ÇAYLAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "GÖYNÜK",
        "STREET": "ÇEŞME MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "GÖYNÜK",
        "STREET": "ÇUBUKKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "GÖYNÜK",
        "STREET": "ÖRENCİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "GÖYNÜK",
        "STREET": "İBRAHİMÖZÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "KIBRISCIK",
        "STREET": "ALANHİMMETLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "KIBRISCIK",
        "STREET": "ALEMDAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "KIBRISCIK",
        "STREET": "BALI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "KIBRISCIK",
        "STREET": "BELEN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "KIBRISCIK",
        "STREET": "BORUCAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "KIBRISCIK",
        "STREET": "BÖLÜCEKKAYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "KIBRISCIK",
        "STREET": "DEVECİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "KIBRISCIK",
        "STREET": "DEVEÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "KIBRISCIK",
        "STREET": "DOKUMACILAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "KIBRISCIK",
        "STREET": "GERİŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "KIBRISCIK",
        "STREET": "KARACAÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "KIBRISCIK",
        "STREET": "KARADOĞAN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "KIBRISCIK",
        "STREET": "KARAKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "KIBRISCIK",
        "STREET": "KILKARA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "KIBRISCIK",
        "STREET": "KIZILCAÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "KIBRISCIK",
        "STREET": "KUZCA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "KIBRISCIK",
        "STREET": "KÖKEZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "KIBRISCIK",
        "STREET": "KÖROĞLU MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "KIBRISCIK",
        "STREET": "KÖSELER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "KIBRISCIK",
        "STREET": "NADAS KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "KIBRISCIK",
        "STREET": "SARIKAYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "KIBRISCIK",
        "STREET": "TAŞLIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "KIBRISCIK",
        "STREET": "YAZICA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "KIBRISCIK",
        "STREET": "ÇÖKELER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "MENGEN",
        "STREET": "AFŞAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "MENGEN",
        "STREET": "AKTEPE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "MENGEN",
        "STREET": "AKÇAKOCA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "MENGEN",
        "STREET": "AKÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "MENGEN",
        "STREET": "ALİBEYLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "MENGEN",
        "STREET": "ARAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "MENGEN",
        "STREET": "AĞACALAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "MENGEN",
        "STREET": "AĞALAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "MENGEN",
        "STREET": "BABAHIZIR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "MENGEN",
        "STREET": "BANAZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "MENGEN",
        "STREET": "BAŞYELLİCE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "MENGEN",
        "STREET": "BEŞLER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "MENGEN",
        "STREET": "BÖLÜKÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "MENGEN",
        "STREET": "BÜRNÜK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "MENGEN",
        "STREET": "DEMİRCİLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "MENGEN",
        "STREET": "DEREKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "MENGEN",
        "STREET": "DÜZAĞAÇ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "MENGEN",
        "STREET": "DÜZKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "MENGEN",
        "STREET": "ELEMEN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "MENGEN",
        "STREET": "EMİRLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "MENGEN",
        "STREET": "GÖKÇESU-ADASAL MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "MENGEN",
        "STREET": "GÖKÇESU-ATATÜRK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "MENGEN",
        "STREET": "GÖKÇESU-HÜRRİYET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "MENGEN",
        "STREET": "GÖKÇESU-ÇAKIRLAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "MENGEN",
        "STREET": "GÖZECİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "MENGEN",
        "STREET": "GÜNEYGÖKÇESU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "MENGEN",
        "STREET": "HACIAHMETLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "MENGEN",
        "STREET": "HAYRANLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "MENGEN",
        "STREET": "KADILAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "MENGEN",
        "STREET": "KADISUSUZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "MENGEN",
        "STREET": "KARACALAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "MENGEN",
        "STREET": "KARAKAYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "MENGEN",
        "STREET": "KARAPINAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "MENGEN",
        "STREET": "KARAİSHAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "MENGEN",
        "STREET": "KARAŞEYHLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "MENGEN",
        "STREET": "KAVACIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "MENGEN",
        "STREET": "KAYABAŞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "MENGEN",
        "STREET": "KAYABÜKÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "MENGEN",
        "STREET": "KAYIŞLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "MENGEN",
        "STREET": "KIYASLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "MENGEN",
        "STREET": "KONAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "MENGEN",
        "STREET": "KUZGÖL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "MENGEN",
        "STREET": "KÖPRÜBAŞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "MENGEN",
        "STREET": "KÜÇÜKKUZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "MENGEN",
        "STREET": "MAMATLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "MENGEN",
        "STREET": "NAZIRLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "MENGEN",
        "STREET": "PAZARKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "MENGEN",
        "STREET": "RÜKNETTİN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "MENGEN",
        "STREET": "SARIKADILAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "MENGEN",
        "STREET": "SAZLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "MENGEN",
        "STREET": "TABAKLAR YAZIYAKA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "MENGEN",
        "STREET": "TEBERİKLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "MENGEN",
        "STREET": "TURNA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "MENGEN",
        "STREET": "TÜRKBEYLİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "MENGEN",
        "STREET": "YELLİCEDEMİRCİLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "MENGEN",
        "STREET": "YUMRUTAŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "MENGEN",
        "STREET": "ÇAYKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "MENGEN",
        "STREET": "ÇIRDAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "MENGEN",
        "STREET": "ÇORAKKADİRLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "MENGEN",
        "STREET": "ÇORAKMITIRLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "MENGEN",
        "STREET": "ÇUBUK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "MENGEN",
        "STREET": "ÇUKURCA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "MENGEN",
        "STREET": "İLYASLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "MENGEN",
        "STREET": "ŞAHBAZLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "MUDURNU",
        "STREET": "AKYOKUŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "MUDURNU",
        "STREET": "ALPAGUT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "MUDURNU",
        "STREET": "AVDULLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "MUDURNU",
        "STREET": "BEKDEMİRLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "MUDURNU",
        "STREET": "BEYDERESİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "MUDURNU",
        "STREET": "BOSTANCILAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "MUDURNU",
        "STREET": "BULANIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "MUDURNU",
        "STREET": "BÜYÜKCAMİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "MUDURNU",
        "STREET": "CUMA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "MUDURNU",
        "STREET": "DAĞYOLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "MUDURNU",
        "STREET": "DEDELER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "MUDURNU",
        "STREET": "DELİCE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "MUDURNU",
        "STREET": "DEREKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "MUDURNU",
        "STREET": "DEREÇETİNÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "MUDURNU",
        "STREET": "DODURGA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "MUDURNU",
        "STREET": "DOLAYÜZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "MUDURNU",
        "STREET": "EKİNÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "MUDURNU",
        "STREET": "ELMACIKDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "MUDURNU",
        "STREET": "ESENKAYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "MUDURNU",
        "STREET": "FERÜZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "MUDURNU",
        "STREET": "FINDICAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "MUDURNU",
        "STREET": "GELİNÖZÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "MUDURNU",
        "STREET": "GÖKÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "MUDURNU",
        "STREET": "GÖLCÜK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "MUDURNU",
        "STREET": "GÖLLÜÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "MUDURNU",
        "STREET": "GÖNCEK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "MUDURNU",
        "STREET": "GÜRÇAM KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "MUDURNU",
        "STREET": "GÜVEYTEPE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "MUDURNU",
        "STREET": "HACIHALİMLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "MUDURNU",
        "STREET": "HACIMUSALAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "MUDURNU",
        "STREET": "HIZIRFAKI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "MUDURNU",
        "STREET": "HÜSAMETTİNDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "MUDURNU",
        "STREET": "ILICA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "MUDURNU",
        "STREET": "KACIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "MUDURNU",
        "STREET": "KARACAKAYA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "MUDURNU",
        "STREET": "KARAMURAT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "MUDURNU",
        "STREET": "KARAPINARKAVAĞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "MUDURNU",
        "STREET": "KARATAŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "MUDURNU",
        "STREET": "KARŞIKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "MUDURNU",
        "STREET": "KAVALLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "MUDURNU",
        "STREET": "KAYGANA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "MUDURNU",
        "STREET": "KEÇİKIRAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "MUDURNU",
        "STREET": "KOVUCAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "MUDURNU",
        "STREET": "KURTLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "MUDURNU",
        "STREET": "KİLÖZÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "MUDURNU",
        "STREET": "MANGIRLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "MUDURNU",
        "STREET": "MUNDUŞLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "MUDURNU",
        "STREET": "MUSALLA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "MUDURNU",
        "STREET": "ORDULAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "MUDURNU",
        "STREET": "ORMANPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "MUDURNU",
        "STREET": "ORTAKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "MUDURNU",
        "STREET": "PELİTÖZÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "MUDURNU",
        "STREET": "SAMAT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "MUDURNU",
        "STREET": "SAMSAÇAVUŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "MUDURNU",
        "STREET": "SARIYAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "MUDURNU",
        "STREET": "SARPINCIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "MUDURNU",
        "STREET": "SEYRANCIK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "MUDURNU",
        "STREET": "SIRÇALI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "MUDURNU",
        "STREET": "SÜRMELİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "MUDURNU",
        "STREET": "TAVŞANSUYU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "MUDURNU",
        "STREET": "TAŞCILAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "MUDURNU",
        "STREET": "TAŞKESTİ-KARACASUMANDIRA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "MUDURNU",
        "STREET": "TAŞKESTİ-KARAÇOMAK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "MUDURNU",
        "STREET": "TAŞKESTİ-MERKEZ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "MUDURNU",
        "STREET": "TAŞKESTİ-TOSUNLAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "MUDURNU",
        "STREET": "TAŞKESTİ-YEĞENDERESİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "MUDURNU",
        "STREET": "TIMARAKTAŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "MUDURNU",
        "STREET": "UZUNÇAM KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "MUDURNU",
        "STREET": "UĞURLUALAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "MUDURNU",
        "STREET": "VAKIFAKTAŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "MUDURNU",
        "STREET": "YAYLABELİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "MUDURNU",
        "STREET": "YAZILAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "MUDURNU",
        "STREET": "YENİCEŞEYHLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "MUDURNU",
        "STREET": "YILDIRIM BAYAZID MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "MUDURNU",
        "STREET": "ÇAMURLUK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "MUDURNU",
        "STREET": "ÇAMYURDU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "MUDURNU",
        "STREET": "ÇAVUŞDERESİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "MUDURNU",
        "STREET": "ÇAĞŞAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "MUDURNU",
        "STREET": "ÇEPNİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "MUDURNU",
        "STREET": "ÇEVRELİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "MUDURNU",
        "STREET": "ÖRENCİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "MUDURNU",
        "STREET": "İĞNECİLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "SEBEN",
        "STREET": "ALPAGUT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "SEBEN",
        "STREET": "BAKIRLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "SEBEN",
        "STREET": "BOZYER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "SEBEN",
        "STREET": "DEDELER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "SEBEN",
        "STREET": "DEREBOY KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "SEBEN",
        "STREET": "DEĞİRMENKAYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "SEBEN",
        "STREET": "EKİCİLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "SEBEN",
        "STREET": "GERENÖZÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "SEBEN",
        "STREET": "GÖKHALİLLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "SEBEN",
        "STREET": "GÜNEYCE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "SEBEN",
        "STREET": "HACCAĞIZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "SEBEN",
        "STREET": "HOÇAŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "SEBEN",
        "STREET": "KABAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "SEBEN",
        "STREET": "KARAAĞAÇ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "SEBEN",
        "STREET": "KAŞBIYIKLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "SEBEN",
        "STREET": "KESENÖZÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "SEBEN",
        "STREET": "KESKİNLİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "SEBEN",
        "STREET": "KIZIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "SEBEN",
        "STREET": "KORUCUK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "SEBEN",
        "STREET": "KOZYAKA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "SEBEN",
        "STREET": "KUZGÖLCÜK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "SEBEN",
        "STREET": "MUSASOFULAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "SEBEN",
        "STREET": "NİMETLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "SEBEN",
        "STREET": "SOLAKLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "SEBEN",
        "STREET": "SUSUZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "SEBEN",
        "STREET": "TAZILAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "SEBEN",
        "STREET": "TEPE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "SEBEN",
        "STREET": "YAĞMA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "SEBEN",
        "STREET": "YUVA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "SEBEN",
        "STREET": "ÇARŞI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "SEBEN",
        "STREET": "ÇELTİKDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "YENİÇAĞA",
        "STREET": "ADAKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "YENİÇAĞA",
        "STREET": "AKINCILAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "YENİÇAĞA",
        "STREET": "ASİL BEY MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "YENİÇAĞA",
        "STREET": "AŞAĞIKULDAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "YENİÇAĞA",
        "STREET": "CAMİATİK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "YENİÇAĞA",
        "STREET": "DEREKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "YENİÇAĞA",
        "STREET": "DOĞANCI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "YENİÇAĞA",
        "STREET": "ESKİÇAĞA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "YENİÇAĞA",
        "STREET": "GÖLBAŞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "YENİÇAĞA",
        "STREET": "GÖLYÜZÜ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "YENİÇAĞA",
        "STREET": "HAMZABEY KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "YENİÇAĞA",
        "STREET": "KEMALLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "YENİÇAĞA",
        "STREET": "KINDIRA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "YENİÇAĞA",
        "STREET": "SARAYKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "YENİÇAĞA",
        "STREET": "YAMANLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "YENİÇAĞA",
        "STREET": "YUKARIKULDAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "YENİÇAĞA",
        "STREET": "ÇAMLIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "YENİÇAĞA",
        "STREET": "ÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BOLU",
        "DISTRICT": "YENİÇAĞA",
        "STREET": "ŞAHNALAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "ALTINYAYLA",
        "STREET": "ASMABAĞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "ALTINYAYLA",
        "STREET": "BALLIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "ALTINYAYLA",
        "STREET": "GÜLPINAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "ALTINYAYLA",
        "STREET": "HEYBELİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "ALTINYAYLA",
        "STREET": "KIZILYAKA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "ALTINYAYLA",
        "STREET": "TAŞYAKA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "ALTINYAYLA",
        "STREET": "YENİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "ALTINYAYLA",
        "STREET": "ÇATAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "ALTINYAYLA",
        "STREET": "ÇÖRTEN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "AĞLASUN",
        "STREET": "AŞAĞIYUMRUTAŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "AĞLASUN",
        "STREET": "BALA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "AĞLASUN",
        "STREET": "DEREKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "AĞLASUN",
        "STREET": "HAMAM MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "AĞLASUN",
        "STREET": "HİSAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "AĞLASUN",
        "STREET": "KIRAÇ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "AĞLASUN",
        "STREET": "KUM MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "AĞLASUN",
        "STREET": "KİPRİT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "AĞLASUN",
        "STREET": "MAMAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "AĞLASUN",
        "STREET": "SAKARCA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "AĞLASUN",
        "STREET": "YAZIR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "AĞLASUN",
        "STREET": "YEŞİLBAŞKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "AĞLASUN",
        "STREET": "YUMRUTAŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "AĞLASUN",
        "STREET": "ÇAMLIDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "AĞLASUN",
        "STREET": "ÇINAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "BUCAK",
        "STREET": "ALAATTİN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "BUCAK",
        "STREET": "ALKAYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "BUCAK",
        "STREET": "ANBAHAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "BUCAK",
        "STREET": "ATİLLA  MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "BUCAK",
        "STREET": "AVDANCIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "BUCAK",
        "STREET": "BARBOROS MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "BUCAK",
        "STREET": "BELÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "BUCAK",
        "STREET": "BEŞKONAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "BUCAK",
        "STREET": "BOĞAZKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "BUCAK",
        "STREET": "CAMİİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "BUCAK",
        "STREET": "DAĞARCIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "BUCAK",
        "STREET": "DUTALANI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "BUCAK",
        "STREET": "ELSAZI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "BUCAK",
        "STREET": "FATİH MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "BUCAK",
        "STREET": "GÜNDOĞDU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "BUCAK",
        "STREET": "KARAALİLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "BUCAK",
        "STREET": "KARACAÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "BUCAK",
        "STREET": "KARAOT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "BUCAK",
        "STREET": "KARAPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "BUCAK",
        "STREET": "KARASEKİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "BUCAK",
        "STREET": "KARAYVATLAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "BUCAK",
        "STREET": "KARGI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "BUCAK",
        "STREET": "KAVACIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "BUCAK",
        "STREET": "KAYI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "BUCAK",
        "STREET": "KESTEL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "BUCAK",
        "STREET": "KEÇİLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "BUCAK",
        "STREET": "KIZILCAAĞAÇ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "BUCAK",
        "STREET": "KIZILKAYA-AVDAN  MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "BUCAK",
        "STREET": "KIZILKAYA-PAZAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "BUCAK",
        "STREET": "KIZILKAYA-YENİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "BUCAK",
        "STREET": "KIZILKAYA-YUVA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "BUCAK",
        "STREET": "KIZILLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "BUCAK",
        "STREET": "KIZILSEKİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "BUCAK",
        "STREET": "KOCAALİLER-CAMİİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "BUCAK",
        "STREET": "KOCAALİLER-GÖKALAN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "BUCAK",
        "STREET": "KOCAALİLER-KARŞIYAKA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "BUCAK",
        "STREET": "KOCAALİLER-PAZAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "BUCAK",
        "STREET": "KONAK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "BUCAK",
        "STREET": "KUYUBAŞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "BUCAK",
        "STREET": "KUŞBABA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "BUCAK",
        "STREET": "MEHMET AKİF MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "BUCAK",
        "STREET": "MİMAR SİNAN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "BUCAK",
        "STREET": "ONAÇ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "BUCAK",
        "STREET": "OĞUZHAN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "BUCAK",
        "STREET": "PAZAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "BUCAK",
        "STREET": "SANAYİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "BUCAK",
        "STREET": "SEYDİKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "BUCAK",
        "STREET": "SUSUZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "BUCAK",
        "STREET": "TAŞYAYLA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "BUCAK",
        "STREET": "UĞURLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "BUCAK",
        "STREET": "YENİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "BUCAK",
        "STREET": "YETMİŞ EVLER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "BUCAK",
        "STREET": "YUNUS EMRE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "BUCAK",
        "STREET": "YÖRÜKLER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "BUCAK",
        "STREET": "YÜREĞİL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "BUCAK",
        "STREET": "ÇAMLICA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "BUCAK",
        "STREET": "ÇAMLIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "BUCAK",
        "STREET": "ÇAVUŞLAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "BUCAK",
        "STREET": "ÇOBANPINARI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "BUCAK",
        "STREET": "ÇUKUR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "BUCAK",
        "STREET": "ÜRKÜTLÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "BUCAK",
        "STREET": "ÜZÜMLÜBEL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "BUCAK",
        "STREET": "İNCİRDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "MERKEZ",
        "STREET": "AKIN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "MERKEZ",
        "STREET": "AKSU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "MERKEZ",
        "STREET": "AKYAKA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "MERKEZ",
        "STREET": "AKYAYLA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "MERKEZ",
        "STREET": "ARMAĞAN İLCİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "MERKEZ",
        "STREET": "ASKERİYE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "MERKEZ",
        "STREET": "ATATÜRK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "MERKEZ",
        "STREET": "AYDINLIKEVLER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "MERKEZ",
        "STREET": "AZİZİYE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "MERKEZ",
        "STREET": "AŞAĞI MÜSLÜMLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "MERKEZ",
        "STREET": "BAHÇELİEVLER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "MERKEZ",
        "STREET": "BAYINDIR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "MERKEZ",
        "STREET": "BAĞLAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "MERKEZ",
        "STREET": "BAŞMAKÇI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "MERKEZ",
        "STREET": "BEREKET KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "MERKEZ",
        "STREET": "BEŞKAVAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "MERKEZ",
        "STREET": "BOZKURT MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "MERKEZ",
        "STREET": "BOZLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "MERKEZ",
        "STREET": "BOĞAZİÇİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "MERKEZ",
        "STREET": "BURDUR E TİPİ KAPALI CEZAEVİ",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "MERKEZ",
        "STREET": "BURÇ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "MERKEZ",
        "STREET": "BÜĞDÜZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "MERKEZ",
        "STREET": "CEMİL MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "MERKEZ",
        "STREET": "CİMBİLLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "MERKEZ",
        "STREET": "DERE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "MERKEZ",
        "STREET": "DEĞİRMENLER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "MERKEZ",
        "STREET": "DÜĞER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "MERKEZ",
        "STREET": "EMEK  MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "MERKEZ",
        "STREET": "ERİKLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "MERKEZ",
        "STREET": "FEVZİ ÇAKMAK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "MERKEZ",
        "STREET": "GÖKÇEBAĞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "MERKEZ",
        "STREET": "GÜNALAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "MERKEZ",
        "STREET": "GÜNEYYAYLA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "MERKEZ",
        "STREET": "HACILAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "MERKEZ",
        "STREET": "HALICILAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "MERKEZ",
        "STREET": "HIZIR İLYAS MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "MERKEZ",
        "STREET": "KAPAKLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "MERKEZ",
        "STREET": "KARACAÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "MERKEZ",
        "STREET": "KARAKENT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "MERKEZ",
        "STREET": "KARASENİR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "MERKEZ",
        "STREET": "KARAÇAL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "MERKEZ",
        "STREET": "KARTALPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "MERKEZ",
        "STREET": "KAVACIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "MERKEZ",
        "STREET": "KAYAALTI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "MERKEZ",
        "STREET": "KAYIŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "MERKEZ",
        "STREET": "KIŞLA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "MERKEZ",
        "STREET": "KOCAPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "MERKEZ",
        "STREET": "KONAK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "MERKEZ",
        "STREET": "KOZLUCA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "MERKEZ",
        "STREET": "KUMLUCA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "MERKEZ",
        "STREET": "KURNA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "MERKEZ",
        "STREET": "KURUÇAY KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "MERKEZ",
        "STREET": "KUYU MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "MERKEZ",
        "STREET": "KÖKEZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "MERKEZ",
        "STREET": "MEHMET AKİF ERSOY MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "MERKEZ",
        "STREET": "MENDERES MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "MERKEZ",
        "STREET": "NECATİ BEY MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "MERKEZ",
        "STREET": "PAZAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "MERKEZ",
        "STREET": "RECEP MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "MERKEZ",
        "STREET": "SAKARYA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "MERKEZ",
        "STREET": "SARIOVA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "MERKEZ",
        "STREET": "SOĞANLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "MERKEZ",
        "STREET": "SULUDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "MERKEZ",
        "STREET": "SİNAN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "MERKEZ",
        "STREET": "TAŞKAPI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "MERKEZ",
        "STREET": "TEPE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "MERKEZ",
        "STREET": "ULUPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "MERKEZ",
        "STREET": "YARIKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "MERKEZ",
        "STREET": "YASSIGÜME KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "MERKEZ",
        "STREET": "YAYLABELİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "MERKEZ",
        "STREET": "YAZIKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "MERKEZ",
        "STREET": "YENİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "MERKEZ",
        "STREET": "YENİCE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "MERKEZ",
        "STREET": "YEŞİLDAĞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "MERKEZ",
        "STREET": "ZAFER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇALLICA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇATAĞIL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇENDİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇEŞMEDAMI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇİNE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "MERKEZ",
        "STREET": "ÖZGÜR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "MERKEZ",
        "STREET": "ÜÇDİBEK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "MERKEZ",
        "STREET": "İLYAS KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "MERKEZ",
        "STREET": "İNÖNÜ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "MERKEZ",
        "STREET": "İĞDELİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "MERKEZ",
        "STREET": "ŞEKER EVLERİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "MERKEZ",
        "STREET": "ŞİRİNEVLER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "GÖLHİSAR",
        "STREET": "ARMUTLU MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "GÖLHİSAR",
        "STREET": "ASMALI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "GÖLHİSAR",
        "STREET": "ELMALIYURT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "GÖLHİSAR",
        "STREET": "EVCİLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "GÖLHİSAR",
        "STREET": "FATİH MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "GÖLHİSAR",
        "STREET": "HORZUM MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "GÖLHİSAR",
        "STREET": "HİSARARDI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "GÖLHİSAR",
        "STREET": "KARAPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "GÖLHİSAR",
        "STREET": "KARGALI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "GÖLHİSAR",
        "STREET": "KONAK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "GÖLHİSAR",
        "STREET": "MERKEZ CAMİİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "GÖLHİSAR",
        "STREET": "PAZAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "GÖLHİSAR",
        "STREET": "SORKUN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "GÖLHİSAR",
        "STREET": "ULUCAMİİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "GÖLHİSAR",
        "STREET": "UYLUPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "GÖLHİSAR",
        "STREET": "YAMADI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "GÖLHİSAR",
        "STREET": "YEŞİLDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "GÖLHİSAR",
        "STREET": "YUSUFÇA-YUSUFÇA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "GÖLHİSAR",
        "STREET": "ÇAKMAK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "GÖLHİSAR",
        "STREET": "ÇAMKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "GÖLHİSAR",
        "STREET": "ÇEŞME MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "GÖLHİSAR",
        "STREET": "İBECİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "KARAMANLI",
        "STREET": "BADEMLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "KARAMANLI",
        "STREET": "BARBAROS MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "KARAMANLI",
        "STREET": "CAFER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "KARAMANLI",
        "STREET": "CAMİİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "KARAMANLI",
        "STREET": "DEREKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "KARAMANLI",
        "STREET": "EŞELER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "KARAMANLI",
        "STREET": "FATİH MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "KARAMANLI",
        "STREET": "HÜRRİYET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "KARAMANLI",
        "STREET": "KARAMAN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "KARAMANLI",
        "STREET": "KAYALI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "KARAMANLI",
        "STREET": "KAĞILCIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "KARAMANLI",
        "STREET": "KILAVUZLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "KARAMANLI",
        "STREET": "KILÇAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "KARAMANLI",
        "STREET": "MANCA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "KARAMANLI",
        "STREET": "MÜRSELLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "KARAMANLI",
        "STREET": "PAZAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "KARAMANLI",
        "STREET": "YENİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "KARAMANLI",
        "STREET": "ZAFER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "KARAMANLI",
        "STREET": "ÇAMLIK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "KARAMANLI",
        "STREET": "İSTİKLAL MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "KEMER",
        "STREET": "AKÇAÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "KEMER",
        "STREET": "AKÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "KEMER",
        "STREET": "BELENLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "KEMER",
        "STREET": "ELMACIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "KEMER",
        "STREET": "KAYI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "KEMER",
        "STREET": "KONAK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "KEMER",
        "STREET": "PINARBAŞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "KEMER",
        "STREET": "SARAY MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "KEMER",
        "STREET": "YAKALAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "KEMER",
        "STREET": "YENİ CAMİİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "TEFENNİ",
        "STREET": "BAYRAMLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "TEFENNİ",
        "STREET": "BAŞPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "TEFENNİ",
        "STREET": "BELKAYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "TEFENNİ",
        "STREET": "BEYKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "TEFENNİ",
        "STREET": "ECE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "TEFENNİ",
        "STREET": "ESENTEPE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "TEFENNİ",
        "STREET": "EŞELER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "TEFENNİ",
        "STREET": "FATİH MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "TEFENNİ",
        "STREET": "GÖKTÜRK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "TEFENNİ",
        "STREET": "HASANPAŞA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "TEFENNİ",
        "STREET": "KARAMUSA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "TEFENNİ",
        "STREET": "KIR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "TEFENNİ",
        "STREET": "PAZAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "TEFENNİ",
        "STREET": "SAZAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "TEFENNİ",
        "STREET": "SEYDİLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "TEFENNİ",
        "STREET": "YAYLAKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "TEFENNİ",
        "STREET": "YENİCE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "TEFENNİ",
        "STREET": "YEŞİLKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "TEFENNİ",
        "STREET": "YOKUŞ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "TEFENNİ",
        "STREET": "YUVA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "TEFENNİ",
        "STREET": "YUVALAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "TEFENNİ",
        "STREET": "ZAFER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "TEFENNİ",
        "STREET": "ÇAYLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "YEŞİLOVA",
        "STREET": "AKÇAKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "YEŞİLOVA",
        "STREET": "ALANKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "YEŞİLOVA",
        "STREET": "ARMUT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "YEŞİLOVA",
        "STREET": "AŞAĞI KIRLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "YEŞİLOVA",
        "STREET": "BAHCELİEVLER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "YEŞİLOVA",
        "STREET": "BAYINDIR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "YEŞİLOVA",
        "STREET": "BAYIRBAŞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "YEŞİLOVA",
        "STREET": "BAŞKUYU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "YEŞİLOVA",
        "STREET": "BEDİRLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "YEŞİLOVA",
        "STREET": "BEYKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "YEŞİLOVA",
        "STREET": "BÜYÜKYAKA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "YEŞİLOVA",
        "STREET": "DEREKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "YEŞİLOVA",
        "STREET": "DEĞİRMEN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "YEŞİLOVA",
        "STREET": "DOĞANBABA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "YEŞİLOVA",
        "STREET": "DÜDEN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "YEŞİLOVA",
        "STREET": "ELDEN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "YEŞİLOVA",
        "STREET": "EMEK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "YEŞİLOVA",
        "STREET": "GENÇALİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "YEŞİLOVA",
        "STREET": "GÖKÇEYAKA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "YEŞİLOVA",
        "STREET": "GÜNEY KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "YEŞİLOVA",
        "STREET": "HARMANLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "YEŞİLOVA",
        "STREET": "HOROZKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "YEŞİLOVA",
        "STREET": "IŞIKLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "YEŞİLOVA",
        "STREET": "KARAATLI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "YEŞİLOVA",
        "STREET": "KARAKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "YEŞİLOVA",
        "STREET": "KAVAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "YEŞİLOVA",
        "STREET": "KAYADİBİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "YEŞİLOVA",
        "STREET": "KOYUNLAR ÇEŞME MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "YEŞİLOVA",
        "STREET": "MERKEZ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "YEŞİLOVA",
        "STREET": "NİYAZLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "YEŞİLOVA",
        "STREET": "ONACAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "YEŞİLOVA",
        "STREET": "ORHANLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "YEŞİLOVA",
        "STREET": "SALDA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "YEŞİLOVA",
        "STREET": "SAZAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "YEŞİLOVA",
        "STREET": "TAŞPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "YEŞİLOVA",
        "STREET": "YARIŞLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "YEŞİLOVA",
        "STREET": "YUKARI KIRLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "YEŞİLOVA",
        "STREET": "ÇALTEPE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "YEŞİLOVA",
        "STREET": "ÇARDAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "YEŞİLOVA",
        "STREET": "ÇELTEK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "YEŞİLOVA",
        "STREET": "ÇUVALLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "YEŞİLOVA",
        "STREET": "ÖRENCİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "YEŞİLOVA",
        "STREET": "İĞDİR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "ÇAVDIR",
        "STREET": "ANBARCIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "ÇAVDIR",
        "STREET": "ATATÜRK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "ÇAVDIR",
        "STREET": "BAYIR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "ÇAVDIR",
        "STREET": "BÖLMEPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "ÇAVDIR",
        "STREET": "BÜYÜKALAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "ÇAVDIR",
        "STREET": "KARAKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "ÇAVDIR",
        "STREET": "KAYACIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "ÇAVDIR",
        "STREET": "KIZILLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "ÇAVDIR",
        "STREET": "KOCAPINAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "ÇAVDIR",
        "STREET": "KOZAĞACI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "ÇAVDIR",
        "STREET": "KÜÇÜKALAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "ÇAVDIR",
        "STREET": "MENEVŞELİK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "ÇAVDIR",
        "STREET": "PAZAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "ÇAVDIR",
        "STREET": "SÖĞÜT-FATİH MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "ÇAVDIR",
        "STREET": "SÖĞÜT-PAZAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "ÇAVDIR",
        "STREET": "SÖĞÜT-YEŞİL MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "ÇAVDIR",
        "STREET": "SÖĞÜT-ÇEŞME MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "ÇAVDIR",
        "STREET": "YAZIR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "ÇAVDIR",
        "STREET": "YENİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "ÇAVDIR",
        "STREET": "ÇINAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "ÇAVDIR",
        "STREET": "İSHAKKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "ÇELTİKÇİ",
        "STREET": "BAĞSARAY KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "ÇELTİKÇİ",
        "STREET": "CAMİİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "ÇELTİKÇİ",
        "STREET": "GÜVENLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "ÇELTİKÇİ",
        "STREET": "KONAK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "ÇELTİKÇİ",
        "STREET": "KUZKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "ÇELTİKÇİ",
        "STREET": "OVACIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "ÇELTİKÇİ",
        "STREET": "TEKKEKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "BURDUR",
        "DISTRICT": "ÇELTİKÇİ",
        "STREET": "ÇEBİŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "BÜYÜKORHAN",
        "STREET": "AKTAŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "BÜYÜKORHAN",
        "STREET": "AKÇASAZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "BÜYÜKORHAN",
        "STREET": "ARMUTÇUK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "BÜYÜKORHAN",
        "STREET": "BADEMLİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "BÜYÜKORHAN",
        "STREET": "BALABAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "BÜYÜKORHAN",
        "STREET": "BAYINDIR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "BÜYÜKORHAN",
        "STREET": "BURUNCA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "BÜYÜKORHAN",
        "STREET": "CUMHURİYET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "BÜYÜKORHAN",
        "STREET": "DANACILAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "BÜYÜKORHAN",
        "STREET": "DANAÇALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "BÜYÜKORHAN",
        "STREET": "DEMİRLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "BÜYÜKORHAN",
        "STREET": "DERECİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "BÜYÜKORHAN",
        "STREET": "DURHASAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "BÜYÜKORHAN",
        "STREET": "DÜĞÜNCÜLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "BÜYÜKORHAN",
        "STREET": "ELEKÇALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "BÜYÜKORHAN",
        "STREET": "ERİCEK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "BÜYÜKORHAN",
        "STREET": "GEDİKLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "BÜYÜKORHAN",
        "STREET": "GEYNİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "BÜYÜKORHAN",
        "STREET": "HACIAHMETLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "BÜYÜKORHAN",
        "STREET": "HACILAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "BÜYÜKORHAN",
        "STREET": "HEMŞERİLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "BÜYÜKORHAN",
        "STREET": "HOCAHASAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "BÜYÜKORHAN",
        "STREET": "KARAAĞIZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "BÜYÜKORHAN",
        "STREET": "KARALAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "BÜYÜKORHAN",
        "STREET": "KARAÇUKUR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "BÜYÜKORHAN",
        "STREET": "KAYAPA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "BÜYÜKORHAN",
        "STREET": "KINIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "BÜYÜKORHAN",
        "STREET": "KUŞLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "BÜYÜKORHAN",
        "STREET": "MAZLUMLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "BÜYÜKORHAN",
        "STREET": "ORHAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "BÜYÜKORHAN",
        "STREET": "OSMANLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "BÜYÜKORHAN",
        "STREET": "PERÇİN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "BÜYÜKORHAN",
        "STREET": "PINARKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "BÜYÜKORHAN",
        "STREET": "PİREBEYLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "BÜYÜKORHAN",
        "STREET": "SARNIÇ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "BÜYÜKORHAN",
        "STREET": "TEKERLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "BÜYÜKORHAN",
        "STREET": "YENİCE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "BÜYÜKORHAN",
        "STREET": "ZAFERİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "BÜYÜKORHAN",
        "STREET": "ÇAKIRYENİCE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "BÜYÜKORHAN",
        "STREET": "ÇERİBAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "BÜYÜKORHAN",
        "STREET": "ÖRENCİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "BÜYÜKORHAN",
        "STREET": "ÖZLÜCE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "BÜYÜKORHAN",
        "STREET": "İSMETİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "GEMLİK",
        "STREET": "ADLİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "GEMLİK",
        "STREET": "ATA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "GEMLİK",
        "STREET": "BALIKPAZARI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "GEMLİK",
        "STREET": "BÜYÜKKUMLA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "GEMLİK",
        "STREET": "CUMHURİYET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "GEMLİK",
        "STREET": "CİHATLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "GEMLİK",
        "STREET": "DEMİRSUBAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "GEMLİK",
        "STREET": "DR.ZİYA KAYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "GEMLİK",
        "STREET": "ENGÜRÜCÜK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "GEMLİK",
        "STREET": "EŞREF DİNÇER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "GEMLİK",
        "STREET": "FEYZİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "GEMLİK",
        "STREET": "FINDICAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "GEMLİK",
        "STREET": "GENÇALİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "GEMLİK",
        "STREET": "GÜVENLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "GEMLİK",
        "STREET": "HALİTPAŞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "GEMLİK",
        "STREET": "HAMİDİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "GEMLİK",
        "STREET": "HAMİDİYEKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "GEMLİK",
        "STREET": "HAYDARİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "GEMLİK",
        "STREET": "HİSAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "GEMLİK",
        "STREET": "KARACAALİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "GEMLİK",
        "STREET": "KATIRLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "GEMLİK",
        "STREET": "KAYHAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "GEMLİK",
        "STREET": "KUMLA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "GEMLİK",
        "STREET": "KURTUL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "GEMLİK",
        "STREET": "KURŞUNLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "GEMLİK",
        "STREET": "KÜÇÜKKUMLA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "GEMLİK",
        "STREET": "MURATOBA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "GEMLİK",
        "STREET": "NARLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "GEMLİK",
        "STREET": "ORHANİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "GEMLİK",
        "STREET": "OSMANİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "GEMLİK",
        "STREET": "UMURBEY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "GEMLİK",
        "STREET": "YENİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "GEMLİK",
        "STREET": "YENİKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "GEMLİK",
        "STREET": "ŞAHİNYURDU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "GEMLİK",
        "STREET": "ŞÜKRİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "GÜRSU",
        "STREET": "ADAKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "GÜRSU",
        "STREET": "AĞAKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "GÜRSU",
        "STREET": "CANBAZLARKÖYÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "GÜRSU",
        "STREET": "DIŞKAYAKÖYÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "GÜRSU",
        "STREET": "ERİCEKKÖYÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "GÜRSU",
        "STREET": "HASANKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "GÜRSU",
        "STREET": "KARAHIDIRKÖYÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "GÜRSU",
        "STREET": "KAZIKLIKÖYÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "GÜRSU",
        "STREET": "KUMLUKALAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "GÜRSU",
        "STREET": "KURTULUŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "GÜRSU",
        "STREET": "YENİDOĞAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "GÜRSU",
        "STREET": "ZAFER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "GÜRSU",
        "STREET": "İPEKYOLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "GÜRSU",
        "STREET": "İSTİKLAL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "GÜRSU",
        "STREET": "İĞDİRKÖYÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "HARMANCIK",
        "STREET": "AKPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "HARMANCIK",
        "STREET": "ALUTCA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "HARMANCIK",
        "STREET": "BALATDANİŞMENT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "HARMANCIK",
        "STREET": "BALLISARAY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "HARMANCIK",
        "STREET": "BEKDEMİRLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "HARMANCIK",
        "STREET": "DEDEBALİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "HARMANCIK",
        "STREET": "DELİCEGÜNEY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "HARMANCIK",
        "STREET": "DUTLUCA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "HARMANCIK",
        "STREET": "ECE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "HARMANCIK",
        "STREET": "GEDİKÖREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "HARMANCIK",
        "STREET": "GÖKÇELER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "HARMANCIK",
        "STREET": "GÜLÖZÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "HARMANCIK",
        "STREET": "HARMANCIK AKALAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "HARMANCIK",
        "STREET": "HOPANDANİŞMENT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "HARMANCIK",
        "STREET": "ILICAKSU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "HARMANCIK",
        "STREET": "KARACA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "HARMANCIK",
        "STREET": "KEPEKDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "HARMANCIK",
        "STREET": "KILAVUZLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "HARMANCIK",
        "STREET": "KIŞMANLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "HARMANCIK",
        "STREET": "KOCAPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "HARMANCIK",
        "STREET": "KOZLUCA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "HARMANCIK",
        "STREET": "MERKEZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "HARMANCIK",
        "STREET": "NALBANT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "HARMANCIK",
        "STREET": "OKÇULAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "HARMANCIK",
        "STREET": "SAÇAKLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "HARMANCIK",
        "STREET": "YAYABAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "HARMANCIK",
        "STREET": "YEŞİLYURT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "HARMANCIK",
        "STREET": "ÇAKMAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "HARMANCIK",
        "STREET": "ÇAMOĞLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "HARMANCIK",
        "STREET": "ÇATALSÖĞÜT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "HARMANCIK",
        "STREET": "İSHAKLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "KARACABEY",
        "STREET": "ABDULLAHPAŞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "KARACABEY",
        "STREET": "AKHİSAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "KARACABEY",
        "STREET": "AKÇAKOYUN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "KARACABEY",
        "STREET": "AKÇASUSURLUK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "KARACABEY",
        "STREET": "ARIZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "KARACABEY",
        "STREET": "BAKIRKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "KARACABEY",
        "STREET": "BALLIKAYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "KARACABEY",
        "STREET": "BAYRAMDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "KARACABEY",
        "STREET": "BEYLİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "KARACABEY",
        "STREET": "BOĞAZKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "KARACABEY",
        "STREET": "CANBALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "KARACABEY",
        "STREET": "CANBAZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "KARACABEY",
        "STREET": "DANİŞMENT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "KARACABEY",
        "STREET": "DAĞESEMEN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "KARACABEY",
        "STREET": "DAĞKADI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "KARACABEY",
        "STREET": "DOĞLA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "KARACABEY",
        "STREET": "DRAMA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "KARACABEY",
        "STREET": "EKMEKÇİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "KARACABEY",
        "STREET": "EKİNLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "KARACABEY",
        "STREET": "EMİRSULTAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "KARACABEY",
        "STREET": "ESENTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "KARACABEY",
        "STREET": "ESKİKARAAĞAÇ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "KARACABEY",
        "STREET": "ESKİSARIBEY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "KARACABEY",
        "STREET": "FEVZİPAŞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "KARACABEY",
        "STREET": "GARİPÇE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "KARACABEY",
        "STREET": "GAZİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "KARACABEY",
        "STREET": "GÖLECİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "KARACABEY",
        "STREET": "GÖLKIYI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "KARACABEY",
        "STREET": "GÖNÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "KARACABEY",
        "STREET": "GÜNGÖRMEZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "KARACABEY",
        "STREET": "HAMİDİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "KARACABEY",
        "STREET": "HARMANLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "KARACABEY",
        "STREET": "HAYIRLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "KARACABEY",
        "STREET": "HOTANLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "KARACABEY",
        "STREET": "HÜDAVENDİGAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "KARACABEY",
        "STREET": "HÜRRİYET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "KARACABEY",
        "STREET": "KARACAAHMET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "KARACABEY",
        "STREET": "KARAKOCA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "KARACABEY",
        "STREET": "KARASU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "KARACABEY",
        "STREET": "KEDİKAYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "KARACABEY",
        "STREET": "KEŞLİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "KARACABEY",
        "STREET": "KIRANLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "KARACABEY",
        "STREET": "KULAKPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "KARACABEY",
        "STREET": "KURŞUNLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "KARACABEY",
        "STREET": "KÜÇÜKKARAAĞAÇ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "KARACABEY",
        "STREET": "MAMURİYET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "KARACABEY",
        "STREET": "MECİDİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "KARACABEY",
        "STREET": "MURATLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "KARACABEY",
        "STREET": "NASRETTİN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "KARACABEY",
        "STREET": "OKÇULAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "KARACABEY",
        "STREET": "ORHANİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "KARACABEY",
        "STREET": "ORTASARIBEY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "KARACABEY",
        "STREET": "OVA HAMİDİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "KARACABEY",
        "STREET": "OVAESEMEN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "KARACABEY",
        "STREET": "RUNGUŞPAŞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "KARACABEY",
        "STREET": "SAADET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "KARACABEY",
        "STREET": "SAZLICA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "KARACABEY",
        "STREET": "SELİMİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "KARACABEY",
        "STREET": "SEYRAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "KARACABEY",
        "STREET": "SIRABADEMLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "KARACABEY",
        "STREET": "SUBAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "KARACABEY",
        "STREET": "SULTANİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "KARACABEY",
        "STREET": "TABAKLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "KARACABEY",
        "STREET": "TAVŞANLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "KARACABEY",
        "STREET": "TAŞLIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "KARACABEY",
        "STREET": "TAŞPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "KARACABEY",
        "STREET": "TOPHİSAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "KARACABEY",
        "STREET": "ULUABAT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "KARACABEY",
        "STREET": "YARIŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "KARACABEY",
        "STREET": "YENİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "KARACABEY",
        "STREET": "YENİCE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "KARACABEY",
        "STREET": "YENİKARAAĞAÇ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "KARACABEY",
        "STREET": "YENİSARIBEY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "KARACABEY",
        "STREET": "YEŞİLDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "KARACABEY",
        "STREET": "YOLAĞZI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "KARACABEY",
        "STREET": "ÇAMLICA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "KARACABEY",
        "STREET": "ÇARIKKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "KARACABEY",
        "STREET": "ÇAVUŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "KARACABEY",
        "STREET": "ÇEŞNİGİR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "KARACABEY",
        "STREET": "ÖRENCİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "KARACABEY",
        "STREET": "İKİZCE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "KARACABEY",
        "STREET": "İNKAYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "KARACABEY",
        "STREET": "İSMETPAŞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "KARACABEY",
        "STREET": "ŞAHMELEK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "KARACABEY",
        "STREET": "ŞAHİNKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "KELES",
        "STREET": "AKÇAPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "KELES",
        "STREET": "ALPAĞUT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "KELES",
        "STREET": "AVDAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "KELES",
        "STREET": "BARAKLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "KELES",
        "STREET": "BASAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "KELES",
        "STREET": "BELENÖREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "KELES",
        "STREET": "BIYIKLIALANI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "KELES",
        "STREET": "CUMA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "KELES",
        "STREET": "DAVUTLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "KELES",
        "STREET": "DAĞ DEMİRCİLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "KELES",
        "STREET": "DAĞDİBİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "KELES",
        "STREET": "DEDELER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "KELES",
        "STREET": "DELİCE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "KELES",
        "STREET": "DENİZLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "KELES",
        "STREET": "DURAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "KELES",
        "STREET": "DÜVENLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "KELES",
        "STREET": "EPÇELER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "KELES",
        "STREET": "ERTUĞRULGAZİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "KELES",
        "STREET": "GELEMİÇ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "KELES",
        "STREET": "GÖKÖZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "KELES",
        "STREET": "HARMANALANI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "KELES",
        "STREET": "HARMANCIK DEMİRCİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "KELES",
        "STREET": "HAYDAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "KELES",
        "STREET": "HEREKE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "KELES",
        "STREET": "ISSIZÖREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "KELES",
        "STREET": "KARAARDIÇ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "KELES",
        "STREET": "KEMALİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "KELES",
        "STREET": "KIRAN IŞIKLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "KELES",
        "STREET": "KOCA KOVACIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "KELES",
        "STREET": "KOZBUDAKLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "KELES",
        "STREET": "KÜÇÜK KOVACIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "KELES",
        "STREET": "KİRAZLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "KELES",
        "STREET": "MENTEŞE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "KELES",
        "STREET": "PINARCIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "KELES",
        "STREET": "SOFULAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "KELES",
        "STREET": "SORGUN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "KELES",
        "STREET": "UZUNÖZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "KELES",
        "STREET": "YAZIBAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "KELES",
        "STREET": "YAĞCILAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "KELES",
        "STREET": "YENİCE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "KELES",
        "STREET": "YUNUSLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "KELES",
        "STREET": "ÇUKUR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "KESTEL",
        "STREET": "AHMETVEFİKPAŞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "KESTEL",
        "STREET": "AKSU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "KESTEL",
        "STREET": "ALAÇAM MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "KESTEL",
        "STREET": "AĞLAŞAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "KESTEL",
        "STREET": "BABASULTAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "KESTEL",
        "STREET": "BARAKFAKİH MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "KESTEL",
        "STREET": "BURHANİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "KESTEL",
        "STREET": "DEREKIZIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "KESTEL",
        "STREET": "DUDAKLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "KESTEL",
        "STREET": "ERDOĞAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "KESTEL",
        "STREET": "ESENTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "KESTEL",
        "STREET": "GÖLBAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "KESTEL",
        "STREET": "GÖLCÜK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "KESTEL",
        "STREET": "GÖZEDE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "KESTEL",
        "STREET": "KALE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "KESTEL",
        "STREET": "KAYACIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "KESTEL",
        "STREET": "KAZANCI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "KESTEL",
        "STREET": "KOZLUÖREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "KESTEL",
        "STREET": "LÜTFİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "KESTEL",
        "STREET": "NARLIDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "KESTEL",
        "STREET": "NÜZHETİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "KESTEL",
        "STREET": "ORHANİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "KESTEL",
        "STREET": "OSMANİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "KESTEL",
        "STREET": "SAYFİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "KESTEL",
        "STREET": "SAİTABAT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "KESTEL",
        "STREET": "SERME MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "KESTEL",
        "STREET": "SEYMEN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "KESTEL",
        "STREET": "SOĞUKSU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "KESTEL",
        "STREET": "TURANKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "KESTEL",
        "STREET": "VANİMEHMET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "KESTEL",
        "STREET": "YAĞMURLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "KESTEL",
        "STREET": "YENİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "KESTEL",
        "STREET": "ÇATALTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "KESTEL",
        "STREET": "ÜMİTALAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "KESTEL",
        "STREET": "ŞEVKETİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "MUDANYA",
        "STREET": "AKKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "MUDANYA",
        "STREET": "ALTINTAŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "MUDANYA",
        "STREET": "AYDINPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "MUDANYA",
        "STREET": "BADEMLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "MUDANYA",
        "STREET": "BALABANCIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "MUDANYA",
        "STREET": "DEDEKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "MUDANYA",
        "STREET": "DEREKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "MUDANYA",
        "STREET": "EMİRLERYENİCESİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "MUDANYA",
        "STREET": "ESENCE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "MUDANYA",
        "STREET": "EVCİLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "MUDANYA",
        "STREET": "EĞERCE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "MUDANYA",
        "STREET": "GÖYNÜKLÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "MUDANYA",
        "STREET": "GÜZELYALI BURGAZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "MUDANYA",
        "STREET": "GÜZELYALI EĞİTİM MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "MUDANYA",
        "STREET": "GÜZELYALI SİTELER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "MUDANYA",
        "STREET": "GÜZELYALI YALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "MUDANYA",
        "STREET": "HALİTPAŞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "MUDANYA",
        "STREET": "HANÇERLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "MUDANYA",
        "STREET": "HASANBEY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "MUDANYA",
        "STREET": "HASKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "MUDANYA",
        "STREET": "IŞIKLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "MUDANYA",
        "STREET": "KAYMAKOBA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "MUDANYA",
        "STREET": "KUMYAKA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "MUDANYA",
        "STREET": "KÜÇÜKYENİCE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "MUDANYA",
        "STREET": "MESUDİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "MUDANYA",
        "STREET": "MÜRSEL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "MUDANYA",
        "STREET": "MÜTAREKE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "MUDANYA",
        "STREET": "MİRZAOBA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "MUDANYA",
        "STREET": "ORHANİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "MUDANYA",
        "STREET": "SÖĞÜTPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "MUDANYA",
        "STREET": "TİRİLYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "MUDANYA",
        "STREET": "YALIÇİFTLİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "MUDANYA",
        "STREET": "YAMAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "MUDANYA",
        "STREET": "YAYLACIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "MUDANYA",
        "STREET": "YENİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "MUDANYA",
        "STREET": "YÖRÜKALİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "MUDANYA",
        "STREET": "YÖRÜKYENİCESİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "MUDANYA",
        "STREET": "ÇAMLIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "MUDANYA",
        "STREET": "ÇAYÖNÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "MUDANYA",
        "STREET": "ÇAĞRIŞAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "MUDANYA",
        "STREET": "ÇEKRİCE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "MUDANYA",
        "STREET": "ÇEPNİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "MUDANYA",
        "STREET": "ÇINARLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "MUDANYA",
        "STREET": "ÖMERBEY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "MUDANYA",
        "STREET": "ÜLKÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "MUDANYA",
        "STREET": "İPEKYAYLA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "MUDANYA",
        "STREET": "ŞÜKRÜÇAVUŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "MUSTAFAKEMALPAŞA",
        "STREET": "ADAKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "MUSTAFAKEMALPAŞA",
        "STREET": "ADALET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "MUSTAFAKEMALPAŞA",
        "STREET": "AKARCA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "MUSTAFAKEMALPAŞA",
        "STREET": "AKÇAPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "MUSTAFAKEMALPAŞA",
        "STREET": "ALACAAT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "MUSTAFAKEMALPAŞA",
        "STREET": "ALPAĞUT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "MUSTAFAKEMALPAŞA",
        "STREET": "ALİSEYDİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "MUSTAFAKEMALPAŞA",
        "STREET": "ARALIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "MUSTAFAKEMALPAŞA",
        "STREET": "ATARİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "MUSTAFAKEMALPAŞA",
        "STREET": "ATATÜRK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "MUSTAFAKEMALPAŞA",
        "STREET": "AYAZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "MUSTAFAKEMALPAŞA",
        "STREET": "AĞAÇLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "MUSTAFAKEMALPAŞA",
        "STREET": "AŞAĞIBALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "MUSTAFAKEMALPAŞA",
        "STREET": "BAHARİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "MUSTAFAKEMALPAŞA",
        "STREET": "BARIŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "MUSTAFAKEMALPAŞA",
        "STREET": "BEHRAM MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "MUSTAFAKEMALPAŞA",
        "STREET": "BOSTANDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "MUSTAFAKEMALPAŞA",
        "STREET": "BOĞAZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "MUSTAFAKEMALPAŞA",
        "STREET": "BÜK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "MUSTAFAKEMALPAŞA",
        "STREET": "CAMANDAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "MUSTAFAKEMALPAŞA",
        "STREET": "CUMHURİYET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "MUSTAFAKEMALPAŞA",
        "STREET": "DALLICA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "MUSTAFAKEMALPAŞA",
        "STREET": "DEMİRDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "MUSTAFAKEMALPAŞA",
        "STREET": "DEMİRELİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "MUSTAFAKEMALPAŞA",
        "STREET": "DERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "MUSTAFAKEMALPAŞA",
        "STREET": "DERECİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "MUSTAFAKEMALPAŞA",
        "STREET": "DEREKADI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "MUSTAFAKEMALPAŞA",
        "STREET": "DEVECİKONAĞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "MUSTAFAKEMALPAŞA",
        "STREET": "DORAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "MUSTAFAKEMALPAŞA",
        "STREET": "DOĞANALAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "MUSTAFAKEMALPAŞA",
        "STREET": "DOĞANCI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "MUSTAFAKEMALPAŞA",
        "STREET": "DURUMTAY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "MUSTAFAKEMALPAŞA",
        "STREET": "DÖLLÜK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "MUSTAFAKEMALPAŞA",
        "STREET": "ESKİBALÇIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "MUSTAFAKEMALPAŞA",
        "STREET": "ESKİKIZILELMA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "MUSTAFAKEMALPAŞA",
        "STREET": "FEVZİDEDE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "MUSTAFAKEMALPAŞA",
        "STREET": "FINDICAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "MUSTAFAKEMALPAŞA",
        "STREET": "GARİPÇETEKKE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "MUSTAFAKEMALPAŞA",
        "STREET": "GÜLLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "MUSTAFAKEMALPAŞA",
        "STREET": "GÜLLÜCE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "MUSTAFAKEMALPAŞA",
        "STREET": "GÜNDOĞDU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "MUSTAFAKEMALPAŞA",
        "STREET": "GÜVEM MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "MUSTAFAKEMALPAŞA",
        "STREET": "GÜVEÇDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "MUSTAFAKEMALPAŞA",
        "STREET": "HACIAHMET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "MUSTAFAKEMALPAŞA",
        "STREET": "HACIALİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "MUSTAFAKEMALPAŞA",
        "STREET": "HAMZABEY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "MUSTAFAKEMALPAŞA",
        "STREET": "HAMİDİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "MUSTAFAKEMALPAŞA",
        "STREET": "HAMİDİYEKÖYÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "MUSTAFAKEMALPAŞA",
        "STREET": "HİSARALTI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "MUSTAFAKEMALPAŞA",
        "STREET": "IŞIKLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "MUSTAFAKEMALPAŞA",
        "STREET": "KABULBABA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "MUSTAFAKEMALPAŞA",
        "STREET": "KADİRÇEŞME MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "MUSTAFAKEMALPAŞA",
        "STREET": "KAPAKLIOLUK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "MUSTAFAKEMALPAŞA",
        "STREET": "KARACALAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "MUSTAFAKEMALPAŞA",
        "STREET": "KARAKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "MUSTAFAKEMALPAŞA",
        "STREET": "KARAORMAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "MUSTAFAKEMALPAŞA",
        "STREET": "KARAOĞLAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "MUSTAFAKEMALPAŞA",
        "STREET": "KARAPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "MUSTAFAKEMALPAŞA",
        "STREET": "KAYABAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "MUSTAFAKEMALPAŞA",
        "STREET": "KAZANPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "MUSTAFAKEMALPAŞA",
        "STREET": "KELTAŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "MUSTAFAKEMALPAŞA",
        "STREET": "KESTELEK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "MUSTAFAKEMALPAŞA",
        "STREET": "KOCAKORU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "MUSTAFAKEMALPAŞA",
        "STREET": "KOSOVA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "MUSTAFAKEMALPAŞA",
        "STREET": "KOŞUBOĞAZI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "MUSTAFAKEMALPAŞA",
        "STREET": "KUMKADI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "MUSTAFAKEMALPAŞA",
        "STREET": "KURŞUNLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "MUSTAFAKEMALPAŞA",
        "STREET": "KÖMÜRCÜKADI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "MUSTAFAKEMALPAŞA",
        "STREET": "KÖREKEM MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "MUSTAFAKEMALPAŞA",
        "STREET": "KÖSEHOROZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "MUSTAFAKEMALPAŞA",
        "STREET": "KİLLİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "MUSTAFAKEMALPAŞA",
        "STREET": "LALAŞAHİN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "MUSTAFAKEMALPAŞA",
        "STREET": "LÜTFİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "MUSTAFAKEMALPAŞA",
        "STREET": "MELİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "MUSTAFAKEMALPAŞA",
        "STREET": "MURADİYESARNIÇ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "MUSTAFAKEMALPAŞA",
        "STREET": "OCAKLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "MUSTAFAKEMALPAŞA",
        "STREET": "ONAÇ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "MUSTAFAKEMALPAŞA",
        "STREET": "ORHANİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "MUSTAFAKEMALPAŞA",
        "STREET": "ORMANKADI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "MUSTAFAKEMALPAŞA",
        "STREET": "ORTA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "MUSTAFAKEMALPAŞA",
        "STREET": "OSMANİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "MUSTAFAKEMALPAŞA",
        "STREET": "OVAAZATLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "MUSTAFAKEMALPAŞA",
        "STREET": "PAŞALAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "MUSTAFAKEMALPAŞA",
        "STREET": "SARIMUSTAFALAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "MUSTAFAKEMALPAŞA",
        "STREET": "SELİMİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "MUSTAFAKEMALPAŞA",
        "STREET": "SOĞUCAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "MUSTAFAKEMALPAŞA",
        "STREET": "SOĞUKPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "MUSTAFAKEMALPAŞA",
        "STREET": "SÖĞÜTALAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "MUSTAFAKEMALPAŞA",
        "STREET": "SÜNLÜK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "MUSTAFAKEMALPAŞA",
        "STREET": "SİNCANSARNIÇ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "MUSTAFAKEMALPAŞA",
        "STREET": "TATKAVAKLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "MUSTAFAKEMALPAŞA",
        "STREET": "TAŞKÖPRÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "MUSTAFAKEMALPAŞA",
        "STREET": "TAŞPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "MUSTAFAKEMALPAŞA",
        "STREET": "TEPECİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "MUSTAFAKEMALPAŞA",
        "STREET": "TIRNOVA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "MUSTAFAKEMALPAŞA",
        "STREET": "UĞURLUPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "MUSTAFAKEMALPAŞA",
        "STREET": "VIRACA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "MUSTAFAKEMALPAŞA",
        "STREET": "YALINTAŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "MUSTAFAKEMALPAŞA",
        "STREET": "YAMANLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "MUSTAFAKEMALPAŞA",
        "STREET": "YAVELLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "MUSTAFAKEMALPAŞA",
        "STREET": "YAYLAÇAYIR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "MUSTAFAKEMALPAŞA",
        "STREET": "YENİBALÇIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "MUSTAFAKEMALPAŞA",
        "STREET": "YENİCE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "MUSTAFAKEMALPAŞA",
        "STREET": "YENİDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "MUSTAFAKEMALPAŞA",
        "STREET": "YENİKIZILELMA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "MUSTAFAKEMALPAŞA",
        "STREET": "YEŞİLOVA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "MUSTAFAKEMALPAŞA",
        "STREET": "YONCAAĞAÇ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "MUSTAFAKEMALPAŞA",
        "STREET": "YUKARIBALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "MUSTAFAKEMALPAŞA",
        "STREET": "YUMURCAKLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "MUSTAFAKEMALPAŞA",
        "STREET": "YUNUS EMRE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "MUSTAFAKEMALPAŞA",
        "STREET": "YÜZBAŞI SABRİBEY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "MUSTAFAKEMALPAŞA",
        "STREET": "ZÜFERBEY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "MUSTAFAKEMALPAŞA",
        "STREET": "ÇAKALLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "MUSTAFAKEMALPAŞA",
        "STREET": "ÇALTILIBÜK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "MUSTAFAKEMALPAŞA",
        "STREET": "ÇAMLICA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "MUSTAFAKEMALPAŞA",
        "STREET": "ÇARDAKBELEN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "MUSTAFAKEMALPAŞA",
        "STREET": "ÇAVUŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "MUSTAFAKEMALPAŞA",
        "STREET": "ÇELTİKÇİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "MUSTAFAKEMALPAŞA",
        "STREET": "ÇIRPAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "MUSTAFAKEMALPAŞA",
        "STREET": "ÇÖMLEKÇİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "MUSTAFAKEMALPAŞA",
        "STREET": "ÇÖRDÜK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "MUSTAFAKEMALPAŞA",
        "STREET": "ÇİVİLİÇAM MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "MUSTAFAKEMALPAŞA",
        "STREET": "ÖMERALTI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "MUSTAFAKEMALPAŞA",
        "STREET": "ÜÇBEYLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "MUSTAFAKEMALPAŞA",
        "STREET": "İLYASÇILAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "MUSTAFAKEMALPAŞA",
        "STREET": "İNCEALİPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "MUSTAFAKEMALPAŞA",
        "STREET": "ŞAPÇI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "MUSTAFAKEMALPAŞA",
        "STREET": "ŞEHRİMAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "MUSTAFAKEMALPAŞA",
        "STREET": "ŞEREFİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "MUSTAFAKEMALPAŞA",
        "STREET": "ŞEVKETİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "MUSTAFAKEMALPAŞA",
        "STREET": "ŞEYHMÜFTÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "NİLÜFER",
        "STREET": "19 MAYIS MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "NİLÜFER",
        "STREET": "23 NİSAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "NİLÜFER",
        "STREET": "29 EKİM MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "NİLÜFER",
        "STREET": "30 AĞUSTOS ZAFER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "NİLÜFER",
        "STREET": "AHMET YESEVİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "NİLÜFER",
        "STREET": "AKÇALAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "NİLÜFER",
        "STREET": "ALAADDİNBEY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "NİLÜFER",
        "STREET": "ALTINŞEHİR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "NİLÜFER",
        "STREET": "ATAEVLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "NİLÜFER",
        "STREET": "ATLAS MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "NİLÜFER",
        "STREET": "AYVAKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "NİLÜFER",
        "STREET": "BADIRGA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "NİLÜFER",
        "STREET": "BALAT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "NİLÜFER",
        "STREET": "BALKAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "NİLÜFER",
        "STREET": "BARIŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "NİLÜFER",
        "STREET": "BAŞKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "NİLÜFER",
        "STREET": "BEŞEVLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "NİLÜFER",
        "STREET": "BÜYÜKBALIKLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "NİLÜFER",
        "STREET": "CUMHURİYET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "NİLÜFER",
        "STREET": "DAĞYENİCE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "NİLÜFER",
        "STREET": "DEMİRCİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "NİLÜFER",
        "STREET": "DOĞANKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "NİLÜFER",
        "STREET": "DUMLUPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "NİLÜFER",
        "STREET": "E TİPİ CEZAEVİ",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "NİLÜFER",
        "STREET": "ERTUĞRUL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "NİLÜFER",
        "STREET": "ESENTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "NİLÜFER",
        "STREET": "FADILLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "NİLÜFER",
        "STREET": "FETHİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "NİLÜFER",
        "STREET": "GÖKÇE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "NİLÜFER",
        "STREET": "GÖLYAZI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "NİLÜFER",
        "STREET": "GÖRÜKLE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "NİLÜFER",
        "STREET": "GÜMÜŞTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "NİLÜFER",
        "STREET": "GÜNGÖREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "NİLÜFER",
        "STREET": "H TİPİ CEZAEVİ",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "NİLÜFER",
        "STREET": "HASANAĞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "NİLÜFER",
        "STREET": "IŞIKTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "NİLÜFER",
        "STREET": "KADRİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "NİLÜFER",
        "STREET": "KARACAOBA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "NİLÜFER",
        "STREET": "KARAMAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "NİLÜFER",
        "STREET": "KAYAPA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "NİLÜFER",
        "STREET": "KIZILCIKLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "NİLÜFER",
        "STREET": "KONAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "NİLÜFER",
        "STREET": "KONAKLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "NİLÜFER",
        "STREET": "KORUBAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "NİLÜFER",
        "STREET": "KURTULUŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "NİLÜFER",
        "STREET": "KURUÇEŞME MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "NİLÜFER",
        "STREET": "KÜLTÜR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "NİLÜFER",
        "STREET": "MAKŞEMPINARI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "NİLÜFER",
        "STREET": "MİNARELİÇAVUŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "NİLÜFER",
        "STREET": "ODUNLUK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "NİLÜFER",
        "STREET": "TAHTALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "NİLÜFER",
        "STREET": "UNÇUKURU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "NİLÜFER",
        "STREET": "YAYLACIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "NİLÜFER",
        "STREET": "YOLÇATI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "NİLÜFER",
        "STREET": "YÜZÜNCÜYIL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "NİLÜFER",
        "STREET": "ÇALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "NİLÜFER",
        "STREET": "ÇAMLICA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "NİLÜFER",
        "STREET": "ÇATALAĞIL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "NİLÜFER",
        "STREET": "ÇAYLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "NİLÜFER",
        "STREET": "ÖZLÜCE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "NİLÜFER",
        "STREET": "ÜRÜNLÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "NİLÜFER",
        "STREET": "ÜÇEVLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "NİLÜFER",
        "STREET": "ÜÇPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "NİLÜFER",
        "STREET": "İHSANİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "NİLÜFER",
        "STREET": "İNEGAZİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "NİLÜFER",
        "STREET": "İRFANİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "ORHANELİ",
        "STREET": "300 EVLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "ORHANELİ",
        "STREET": "AKALAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "ORHANELİ",
        "STREET": "AKÇABÜK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "ORHANELİ",
        "STREET": "ALTINTAŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "ORHANELİ",
        "STREET": "ARGIN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "ORHANELİ",
        "STREET": "AĞAÇHİSAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "ORHANELİ",
        "STREET": "BALIOĞLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "ORHANELİ",
        "STREET": "BAŞKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "ORHANELİ",
        "STREET": "BELENOLUK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "ORHANELİ",
        "STREET": "CELEPLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "ORHANELİ",
        "STREET": "DAĞGÜNEY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "ORHANELİ",
        "STREET": "DELİBALLILAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "ORHANELİ",
        "STREET": "DEMİRCİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "ORHANELİ",
        "STREET": "DERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "ORHANELİ",
        "STREET": "DÜNDAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "ORHANELİ",
        "STREET": "EMİR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "ORHANELİ",
        "STREET": "ERENLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "ORHANELİ",
        "STREET": "ESENTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "ORHANELİ",
        "STREET": "ESKİDANİŞMENT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "ORHANELİ",
        "STREET": "FADIL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "ORHANELİ",
        "STREET": "FEVZİPAŞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "ORHANELİ",
        "STREET": "FİROZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "ORHANELİ",
        "STREET": "GAZİOLUK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "ORHANELİ",
        "STREET": "GAZİPAŞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "ORHANELİ",
        "STREET": "GÖKTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "ORHANELİ",
        "STREET": "GÖYNÜKBELEN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "ORHANELİ",
        "STREET": "GÜMÜŞPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "ORHANELİ",
        "STREET": "GİRENCİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "ORHANELİ",
        "STREET": "KABAKLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "ORHANELİ",
        "STREET": "KADI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "ORHANELİ",
        "STREET": "KARABEKİRPAŞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "ORHANELİ",
        "STREET": "KARAOĞLANLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "ORHANELİ",
        "STREET": "KARASİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "ORHANELİ",
        "STREET": "KARINCALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "ORHANELİ",
        "STREET": "KOÇU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "ORHANELİ",
        "STREET": "KUSUMLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "ORHANELİ",
        "STREET": "KÜÇÜKORHAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "ORHANELİ",
        "STREET": "LETAFET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "ORHANELİ",
        "STREET": "MAHALLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "ORHANELİ",
        "STREET": "NALINLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "ORHANELİ",
        "STREET": "ORTA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "ORHANELİ",
        "STREET": "OSMANİYEÇATAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "ORHANELİ",
        "STREET": "SADAĞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "ORHANELİ",
        "STREET": "SEMERCİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "ORHANELİ",
        "STREET": "SERÇELER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "ORHANELİ",
        "STREET": "SIRIL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "ORHANELİ",
        "STREET": "SÖĞÜT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "ORHANELİ",
        "STREET": "SÜLEYMANBEY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "ORHANELİ",
        "STREET": "TEPECİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "ORHANELİ",
        "STREET": "TOPUK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "ORHANELİ",
        "STREET": "YAKUPLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "ORHANELİ",
        "STREET": "YENİDANİŞMENT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "ORHANELİ",
        "STREET": "YEŞİLLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "ORHANELİ",
        "STREET": "YÖRÜKLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "ORHANELİ",
        "STREET": "ÇEKİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "ORHANELİ",
        "STREET": "ÇINARCIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "ORHANELİ",
        "STREET": "ÇÖRELER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "ORHANELİ",
        "STREET": "ÇİVİLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "ORHANELİ",
        "STREET": "İKİZOLUK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "ORHANELİ",
        "STREET": "İSMETPAŞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "ORHANELİ",
        "STREET": "ŞÜKRİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "ORHANGAZİ",
        "STREET": "AKHAREM MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "ORHANGAZİ",
        "STREET": "ARAPZADE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "ORHANGAZİ",
        "STREET": "BAYIRKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "ORHANGAZİ",
        "STREET": "CAMİİKEBİR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "ORHANGAZİ",
        "STREET": "CİHANKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "ORHANGAZİ",
        "STREET": "DUTLUCA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "ORHANGAZİ",
        "STREET": "FATİH MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "ORHANGAZİ",
        "STREET": "FINDIKLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "ORHANGAZİ",
        "STREET": "GEDELEK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "ORHANGAZİ",
        "STREET": "GEMİÇ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "ORHANGAZİ",
        "STREET": "GÖLYAKA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "ORHANGAZİ",
        "STREET": "GÜRLE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "ORHANGAZİ",
        "STREET": "HAMZALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "ORHANGAZİ",
        "STREET": "HECELER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "ORHANGAZİ",
        "STREET": "HÜRRİYET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "ORHANGAZİ",
        "STREET": "KARSAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "ORHANGAZİ",
        "STREET": "KERAMET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "ORHANGAZİ",
        "STREET": "MAHMUDİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "ORHANGAZİ",
        "STREET": "MURADİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "ORHANGAZİ",
        "STREET": "NARLICA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "ORHANGAZİ",
        "STREET": "ORTAKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "ORHANGAZİ",
        "STREET": "PAŞAPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "ORHANGAZİ",
        "STREET": "SÖLÖZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "ORHANGAZİ",
        "STREET": "TEKKE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "ORHANGAZİ",
        "STREET": "YENİGÜRLE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "ORHANGAZİ",
        "STREET": "YENİKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "ORHANGAZİ",
        "STREET": "YENİSÖLÖZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "ORHANGAZİ",
        "STREET": "ÇAKIRLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "ORHANGAZİ",
        "STREET": "ÇELTİKÇİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "ORHANGAZİ",
        "STREET": "ÖRNEKKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "ORHANGAZİ",
        "STREET": "ÜREĞİL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "OSMANGAZİ",
        "STREET": "ADALET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "OSMANGAZİ",
        "STREET": "AHMETBEY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "OSMANGAZİ",
        "STREET": "AHMETPAŞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "OSMANGAZİ",
        "STREET": "AKPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "OSMANGAZİ",
        "STREET": "AKSUNGUR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "OSMANGAZİ",
        "STREET": "AKTARHÜSSAM MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "OSMANGAZİ",
        "STREET": "ALAADDİN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "OSMANGAZİ",
        "STREET": "ALACAHIRKA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "OSMANGAZİ",
        "STREET": "ALACAMESCİT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "OSMANGAZİ",
        "STREET": "ALAŞARKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "OSMANGAZİ",
        "STREET": "ALEMDAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "OSMANGAZİ",
        "STREET": "ALTINOVA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "OSMANGAZİ",
        "STREET": "ALTIPARMAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "OSMANGAZİ",
        "STREET": "ALİPAŞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "OSMANGAZİ",
        "STREET": "ARMUTKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "OSMANGAZİ",
        "STREET": "ATICILAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "OSMANGAZİ",
        "STREET": "AVDANCIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "OSMANGAZİ",
        "STREET": "BAHAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "OSMANGAZİ",
        "STREET": "BAĞLARBAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "OSMANGAZİ",
        "STREET": "BAĞLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "OSMANGAZİ",
        "STREET": "BAŞARAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "OSMANGAZİ",
        "STREET": "BÜYÜKDELİLLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "OSMANGAZİ",
        "STREET": "DAĞAKÇA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "OSMANGAZİ",
        "STREET": "DEMİRKAPI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "OSMANGAZİ",
        "STREET": "DEMİRTAŞ BARBAROS MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "OSMANGAZİ",
        "STREET": "DEMİRTAŞ CUMHURİYET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "OSMANGAZİ",
        "STREET": "DEMİRTAŞ DUMLUPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "OSMANGAZİ",
        "STREET": "DEMİRTAŞ SAKARYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "OSMANGAZİ",
        "STREET": "DEMİRTAŞPAŞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "OSMANGAZİ",
        "STREET": "DEREÇAVUŞKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "OSMANGAZİ",
        "STREET": "DOBURCA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "OSMANGAZİ",
        "STREET": "DOĞANBEY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "OSMANGAZİ",
        "STREET": "DOĞANCI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "OSMANGAZİ",
        "STREET": "DOĞANEVLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "OSMANGAZİ",
        "STREET": "DÜRDANE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "OSMANGAZİ",
        "STREET": "DİKKALDIRIM MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "OSMANGAZİ",
        "STREET": "EBU İSHAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "OSMANGAZİ",
        "STREET": "ELMASBAHÇELER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "OSMANGAZİ",
        "STREET": "EMEK ADNAN MENDERES MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "OSMANGAZİ",
        "STREET": "EMEK FATİH SULTAN MEHMET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "OSMANGAZİ",
        "STREET": "EMEK ZEKAİ GÜMÜŞDİŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "OSMANGAZİ",
        "STREET": "FATİH MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "OSMANGAZİ",
        "STREET": "GAZİAKDEMİR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "OSMANGAZİ",
        "STREET": "GEÇİT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "OSMANGAZİ",
        "STREET": "GÖKÇEÖREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "OSMANGAZİ",
        "STREET": "GÜLBAHÇE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "OSMANGAZİ",
        "STREET": "GÜNDOĞDU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "OSMANGAZİ",
        "STREET": "GÜNEYBAYIR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "OSMANGAZİ",
        "STREET": "GÜNEYBUDAKLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "OSMANGAZİ",
        "STREET": "GÜNEŞTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "OSMANGAZİ",
        "STREET": "HACI İLYAS MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "OSMANGAZİ",
        "STREET": "HAMZABEY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "OSMANGAZİ",
        "STREET": "HAMİTLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "OSMANGAZİ",
        "STREET": "HOCAALİZADE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "OSMANGAZİ",
        "STREET": "HOCAHASAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "OSMANGAZİ",
        "STREET": "HÜDAVENDİGAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "OSMANGAZİ",
        "STREET": "HÜRRİYET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "OSMANGAZİ",
        "STREET": "HÜSEYİNALAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "OSMANGAZİ",
        "STREET": "KARABALÇIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "OSMANGAZİ",
        "STREET": "KARAİSLAH MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "OSMANGAZİ",
        "STREET": "KAVAKLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "OSMANGAZİ",
        "STREET": "KAYIHAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "OSMANGAZİ",
        "STREET": "KEMERÇEŞME MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "OSMANGAZİ",
        "STREET": "KIRCAALİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "OSMANGAZİ",
        "STREET": "KOCANAİP MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "OSMANGAZİ",
        "STREET": "KOĞUKÇINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "OSMANGAZİ",
        "STREET": "KURUÇEŞME MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "OSMANGAZİ",
        "STREET": "KÜKÜRTLÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "OSMANGAZİ",
        "STREET": "KÜPLÜPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "OSMANGAZİ",
        "STREET": "KÜÇÜKBALIKLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "OSMANGAZİ",
        "STREET": "KÜÇÜKDELİLLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "OSMANGAZİ",
        "STREET": "KİRAZLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "OSMANGAZİ",
        "STREET": "KİREMİTÇİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "OSMANGAZİ",
        "STREET": "MAKSEM MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "OSMANGAZİ",
        "STREET": "MEHMET AKİF MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "OSMANGAZİ",
        "STREET": "MOLLAFENARİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "OSMANGAZİ",
        "STREET": "MOLLAGÜRANİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "OSMANGAZİ",
        "STREET": "MURADİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "OSMANGAZİ",
        "STREET": "MÜRSELLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "OSMANGAZİ",
        "STREET": "NALBANTOĞLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "OSMANGAZİ",
        "STREET": "NAMIK KEMAL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "OSMANGAZİ",
        "STREET": "NİLÜFERKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "OSMANGAZİ",
        "STREET": "ORHANBEY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "OSMANGAZİ",
        "STREET": "OSMANGAZİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "OSMANGAZİ",
        "STREET": "OVAAKÇA EĞİTİM MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "OSMANGAZİ",
        "STREET": "OVAAKÇA MERKEZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "OSMANGAZİ",
        "STREET": "OVAAKÇA SANTRAL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "OSMANGAZİ",
        "STREET": "OVAAKÇA ÇEŞMEBAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "OSMANGAZİ",
        "STREET": "PANAYIR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "OSMANGAZİ",
        "STREET": "PINARBAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "OSMANGAZİ",
        "STREET": "REYHAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "OSMANGAZİ",
        "STREET": "SAKARYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "OSMANGAZİ",
        "STREET": "SANTRAL GARAJ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "OSMANGAZİ",
        "STREET": "SEFERIŞIKLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "OSMANGAZİ",
        "STREET": "SELAMET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "OSMANGAZİ",
        "STREET": "SELÇUKGAZİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "OSMANGAZİ",
        "STREET": "SELİMİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "OSMANGAZİ",
        "STREET": "SEÇ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "OSMANGAZİ",
        "STREET": "SIRAMEŞELER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "OSMANGAZİ",
        "STREET": "SOĞANLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "OSMANGAZİ",
        "STREET": "SOĞUKKUYU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "OSMANGAZİ",
        "STREET": "SOĞUKPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "OSMANGAZİ",
        "STREET": "SÜLEYMANİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "OSMANGAZİ",
        "STREET": "TAHTAKALE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "OSMANGAZİ",
        "STREET": "TAYAKADIN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "OSMANGAZİ",
        "STREET": "TUNA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "OSMANGAZİ",
        "STREET": "TUZAKLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "OSMANGAZİ",
        "STREET": "TUZPAZARI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "OSMANGAZİ",
        "STREET": "ULU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "OSMANGAZİ",
        "STREET": "ULUÇAM MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "OSMANGAZİ",
        "STREET": "VEYSEL KARANİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "OSMANGAZİ",
        "STREET": "YAHŞİBEY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "OSMANGAZİ",
        "STREET": "YENİBAĞLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "OSMANGAZİ",
        "STREET": "YENİCEABAT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "OSMANGAZİ",
        "STREET": "YENİKARAMAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "OSMANGAZİ",
        "STREET": "YENİKENT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "OSMANGAZİ",
        "STREET": "YEŞİLOVA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "OSMANGAZİ",
        "STREET": "YUNUSELİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "OSMANGAZİ",
        "STREET": "YİĞİTALİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "OSMANGAZİ",
        "STREET": "ZAFER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "OSMANGAZİ",
        "STREET": "ÇAYBAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "OSMANGAZİ",
        "STREET": "ÇAĞLAYAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "OSMANGAZİ",
        "STREET": "ÇEKİRGE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "OSMANGAZİ",
        "STREET": "ÇELTİKKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "OSMANGAZİ",
        "STREET": "ÇIRPAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "OSMANGAZİ",
        "STREET": "ÇUKURCAKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "OSMANGAZİ",
        "STREET": "ÇİFTEHAVUZLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "OSMANGAZİ",
        "STREET": "ÇİRİŞHANE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "OSMANGAZİ",
        "STREET": "İBRAHİMPAŞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "OSMANGAZİ",
        "STREET": "İNKAYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "OSMANGAZİ",
        "STREET": "İNTİZAM MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "OSMANGAZİ",
        "STREET": "İSMETİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "OSMANGAZİ",
        "STREET": "İSTİKLAL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "OSMANGAZİ",
        "STREET": "İVAZPAŞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "OSMANGAZİ",
        "STREET": "ŞEHABETTİNPAŞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "OSMANGAZİ",
        "STREET": "ŞEHREKÜSTÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "YENİŞEHİR",
        "STREET": "AFŞAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "YENİŞEHİR",
        "STREET": "AKBIYIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "YENİŞEHİR",
        "STREET": "AKDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "YENİŞEHİR",
        "STREET": "AKÇAPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "YENİŞEHİR",
        "STREET": "ALAYLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "YENİŞEHİR",
        "STREET": "AYAZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "YENİŞEHİR",
        "STREET": "AYDOĞDU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "YENİŞEHİR",
        "STREET": "BARCIN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "YENİŞEHİR",
        "STREET": "BEYPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "YENİŞEHİR",
        "STREET": "BURCUN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "YENİŞEHİR",
        "STREET": "CİHADİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "YENİŞEHİR",
        "STREET": "DEMİRBOĞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "YENİŞEHİR",
        "STREET": "DEREKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "YENİŞEHİR",
        "STREET": "EBEKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "YENİŞEHİR",
        "STREET": "EĞERCE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "YENİŞEHİR",
        "STREET": "FETHİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "YENİŞEHİR",
        "STREET": "GÖKÇESU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "YENİŞEHİR",
        "STREET": "GÜNDOĞAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "YENİŞEHİR",
        "STREET": "GÜNECE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "YENİŞEHİR",
        "STREET": "HAYRİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "YENİŞEHİR",
        "STREET": "HIDIRBALİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "YENİŞEHİR",
        "STREET": "KARAAMCA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "YENİŞEHİR",
        "STREET": "KARABAHADIR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "YENİŞEHİR",
        "STREET": "KARACAAHMET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "YENİŞEHİR",
        "STREET": "KARACAALİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "YENİŞEHİR",
        "STREET": "KARAKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "YENİŞEHİR",
        "STREET": "KARASIL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "YENİŞEHİR",
        "STREET": "KAVAKLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "YENİŞEHİR",
        "STREET": "KIBLEPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "YENİŞEHİR",
        "STREET": "KIZILHİSAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "YENİŞEHİR",
        "STREET": "KIZILKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "YENİŞEHİR",
        "STREET": "KOYUNHİSAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "YENİŞEHİR",
        "STREET": "KOZDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "YENİŞEHİR",
        "STREET": "KURTULUŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "YENİŞEHİR",
        "STREET": "KÖPRÜHİSAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "YENİŞEHİR",
        "STREET": "KİRAZLIYAYLA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "YENİŞEHİR",
        "STREET": "MAHMUDİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "YENİŞEHİR",
        "STREET": "MARMARACIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "YENİŞEHİR",
        "STREET": "MECİDİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "YENİŞEHİR",
        "STREET": "MENTEŞE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "YENİŞEHİR",
        "STREET": "ORHANİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "YENİŞEHİR",
        "STREET": "OSMANİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "YENİŞEHİR",
        "STREET": "PAPATYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "YENİŞEHİR",
        "STREET": "PAŞAYAYLA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "YENİŞEHİR",
        "STREET": "REŞADİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "YENİŞEHİR",
        "STREET": "SELİMİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "YENİŞEHİR",
        "STREET": "SUBAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "YENİŞEHİR",
        "STREET": "SÖYLEMİŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "YENİŞEHİR",
        "STREET": "SÜLEYMANİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "YENİŞEHİR",
        "STREET": "TABAKHANE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "YENİŞEHİR",
        "STREET": "TERZİLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "YENİŞEHİR",
        "STREET": "TOPRAKDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "YENİŞEHİR",
        "STREET": "TOPRAKOCAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "YENİŞEHİR",
        "STREET": "ULUCAMİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "YENİŞEHİR",
        "STREET": "YARHİSAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "YENİŞEHİR",
        "STREET": "YAZILI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "YENİŞEHİR",
        "STREET": "YENİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "YENİŞEHİR",
        "STREET": "YENİGÜN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "YENİŞEHİR",
        "STREET": "YENİKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "YENİŞEHİR",
        "STREET": "YENİŞEHİR KADIN KAPALI CEZA İNFAZ KURUMU",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "YENİŞEHİR",
        "STREET": "YILDIRIM MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "YENİŞEHİR",
        "STREET": "YILMAZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "YENİŞEHİR",
        "STREET": "YOLÖREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "YENİŞEHİR",
        "STREET": "YÜZÜNCÜYIL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "YENİŞEHİR",
        "STREET": "ÇAMÖNÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "YENİŞEHİR",
        "STREET": "ÇARDAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "YENİŞEHİR",
        "STREET": "ÇAYIR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "YENİŞEHİR",
        "STREET": "ÇAYIRLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "YENİŞEHİR",
        "STREET": "ÇELEBİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "YENİŞEHİR",
        "STREET": "ÇELTİKÇİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "YENİŞEHİR",
        "STREET": "ÇİÇEKÖZÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "YENİŞEHİR",
        "STREET": "İNCİRLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "YILDIRIM",
        "STREET": "152EVLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "YILDIRIM",
        "STREET": "75.YIL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "YILDIRIM",
        "STREET": "AKÇAĞLAYAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "YILDIRIM",
        "STREET": "ANADOLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "YILDIRIM",
        "STREET": "ARABAYATAĞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "YILDIRIM",
        "STREET": "BALABAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "YILDIRIM",
        "STREET": "BARUTHANE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "YILDIRIM",
        "STREET": "BAĞLARALTI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "YILDIRIM",
        "STREET": "BEYAZIT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "YILDIRIM",
        "STREET": "CUMALIKIZIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "YILDIRIM",
        "STREET": "DAVUTDEDE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "YILDIRIM",
        "STREET": "DAVUTKADI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "YILDIRIM",
        "STREET": "DEMETEVLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "YILDIRIM",
        "STREET": "DEĞİRMENLİKIZIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "YILDIRIM",
        "STREET": "DEĞİRMENÖNÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "YILDIRIM",
        "STREET": "DUAÇINARI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "YILDIRIM",
        "STREET": "EMİRSULTAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "YILDIRIM",
        "STREET": "ERTUĞRULGAZİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "YILDIRIM",
        "STREET": "ERİKLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "YILDIRIM",
        "STREET": "ESENEVLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "YILDIRIM",
        "STREET": "EĞİTİM MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "YILDIRIM",
        "STREET": "FİDYEKIZIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "YILDIRIM",
        "STREET": "GÜLLÜK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "YILDIRIM",
        "STREET": "HACISEYFETTİN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "YILDIRIM",
        "STREET": "HACİVAT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "YILDIRIM",
        "STREET": "HAMAMLIKIZIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "YILDIRIM",
        "STREET": "HOCATAŞKIN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "YILDIRIM",
        "STREET": "KAPLIKAYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "YILDIRIM",
        "STREET": "KARAAĞAÇ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "YILDIRIM",
        "STREET": "KARAMAZAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "YILDIRIM",
        "STREET": "KARAPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "YILDIRIM",
        "STREET": "KAZIM KARABEKİR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "YILDIRIM",
        "STREET": "KURTOĞLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "YILDIRIM",
        "STREET": "MALTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "YILDIRIM",
        "STREET": "MEHMETAKİFERSOY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "YILDIRIM",
        "STREET": "MEVLANA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "YILDIRIM",
        "STREET": "MEYDANCIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "YILDIRIM",
        "STREET": "MOLLAARAP MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "YILDIRIM",
        "STREET": "MUSABABA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "YILDIRIM",
        "STREET": "MİLLET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "YILDIRIM",
        "STREET": "MİMARSİNAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "YILDIRIM",
        "STREET": "NAMAZGAH MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "YILDIRIM",
        "STREET": "ORTABAĞLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "YILDIRIM",
        "STREET": "PİREMİR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "YILDIRIM",
        "STREET": "SAKARYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "YILDIRIM",
        "STREET": "SAMANLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "YILDIRIM",
        "STREET": "SELÇUKBEY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "YILDIRIM",
        "STREET": "SELİMZADE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "YILDIRIM",
        "STREET": "SIRACEVİZLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "YILDIRIM",
        "STREET": "SİNANDEDE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "YILDIRIM",
        "STREET": "SİTELER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "YILDIRIM",
        "STREET": "TEFERRÜÇ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "YILDIRIM",
        "STREET": "ULUS MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "YILDIRIM",
        "STREET": "UMURBEY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "YILDIRIM",
        "STREET": "VAKIF MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "YILDIRIM",
        "STREET": "VATAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "YILDIRIM",
        "STREET": "YAVUZSELIM MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "YILDIRIM",
        "STREET": "YEDİSELVİLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "YILDIRIM",
        "STREET": "YENIMAHALLE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "YILDIRIM",
        "STREET": "YEŞİL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "YILDIRIM",
        "STREET": "YEŞİLYAYLA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "YILDIRIM",
        "STREET": "YILDIRIM MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "YILDIRIM",
        "STREET": "YUNUSEMRE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "YILDIRIM",
        "STREET": "YİĞİTLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "YILDIRIM",
        "STREET": "ZEYNİLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "YILDIRIM",
        "STREET": "ZÜMRÜTEVLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "YILDIRIM",
        "STREET": "ÇELEBİ MEHMET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "YILDIRIM",
        "STREET": "ÇINARÖNÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "YILDIRIM",
        "STREET": "İSABEY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "YILDIRIM",
        "STREET": "ŞÜKRANİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "YILDIRIM",
        "STREET": "ŞİRİNEVLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "İNEGÖL",
        "STREET": "AKBAŞLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "İNEGÖL",
        "STREET": "AKHİSAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "İNEGÖL",
        "STREET": "AKINCILAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "İNEGÖL",
        "STREET": "ALİBEY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "İNEGÖL",
        "STREET": "AŞAĞIBALLIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "İNEGÖL",
        "STREET": "BABAOĞLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "İNEGÖL",
        "STREET": "BAHARİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "İNEGÖL",
        "STREET": "BAHÇEKAYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "İNEGÖL",
        "STREET": "BAYKOCA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "İNEGÖL",
        "STREET": "BAYRAMŞAH MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "İNEGÖL",
        "STREET": "BOĞAZKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "İNEGÖL",
        "STREET": "BURHANİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "İNEGÖL",
        "STREET": "BİLALKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "İNEGÖL",
        "STREET": "CEMİYET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "İNEGÖL",
        "STREET": "CERRAH MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "İNEGÖL",
        "STREET": "CUMA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "İNEGÖL",
        "STREET": "CUMHURİYET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "İNEGÖL",
        "STREET": "DEYDİNLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "İNEGÖL",
        "STREET": "DOĞANYURDU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "İNEGÖL",
        "STREET": "DÖMEZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "İNEGÖL",
        "STREET": "DİPSİZGÖL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "İNEGÖL",
        "STREET": "EDEBEY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "İNEGÖL",
        "STREET": "ELMAÇAYIR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "İNEGÖL",
        "STREET": "ERTUĞRULGAZİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "İNEGÖL",
        "STREET": "ESENKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "İNEGÖL",
        "STREET": "ESENTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "İNEGÖL",
        "STREET": "ESKİ BAHÇEKAYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "İNEGÖL",
        "STREET": "ESKİKARACAKAYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "İNEGÖL",
        "STREET": "ESKİKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "İNEGÖL",
        "STREET": "EYMİR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "İNEGÖL",
        "STREET": "FATİH MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "İNEGÖL",
        "STREET": "FEVZİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "İNEGÖL",
        "STREET": "FINDIKLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "İNEGÖL",
        "STREET": "GAZELLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "İNEGÖL",
        "STREET": "GEDİKPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "İNEGÖL",
        "STREET": "GÜLBAHÇE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "İNEGÖL",
        "STREET": "GÜNDÜZLÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "İNEGÖL",
        "STREET": "GÜNEYKESTANE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "İNEGÖL",
        "STREET": "GÜZELYURT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "İNEGÖL",
        "STREET": "HACIKARA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "İNEGÖL",
        "STREET": "HALHALCA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "İNEGÖL",
        "STREET": "HAMAMLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "İNEGÖL",
        "STREET": "HAMZABEY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "İNEGÖL",
        "STREET": "HAMİDİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "İNEGÖL",
        "STREET": "HAMİTABAT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "İNEGÖL",
        "STREET": "HASANPAŞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "İNEGÖL",
        "STREET": "HAYRİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "İNEGÖL",
        "STREET": "HOCAKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "İNEGÖL",
        "STREET": "HUZUR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "İNEGÖL",
        "STREET": "HİLMİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "İNEGÖL",
        "STREET": "KARAGÖLET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "İNEGÖL",
        "STREET": "KARAHASANLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "İNEGÖL",
        "STREET": "KARAKADI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "İNEGÖL",
        "STREET": "KARALAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "İNEGÖL",
        "STREET": "KAYAPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "İNEGÖL",
        "STREET": "KEMALPAŞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "İNEGÖL",
        "STREET": "KESTANEALANI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "İNEGÖL",
        "STREET": "KINIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "İNEGÖL",
        "STREET": "KIRAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "İNEGÖL",
        "STREET": "KOCAKONAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "İNEGÖL",
        "STREET": "KONURLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "İNEGÖL",
        "STREET": "KOZLUCA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "İNEGÖL",
        "STREET": "KULACA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "İNEGÖL",
        "STREET": "KURŞUNLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "İNEGÖL",
        "STREET": "KÜÇÜKYENİCE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "İNEGÖL",
        "STREET": "LÜTFİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "İNEGÖL",
        "STREET": "MADENKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "İNEGÖL",
        "STREET": "MAHMUDİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "İNEGÖL",
        "STREET": "MESUDİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "İNEGÖL",
        "STREET": "MEZİT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "İNEGÖL",
        "STREET": "MURATBEY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "İNEGÖL",
        "STREET": "OLUKMAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "İNEGÖL",
        "STREET": "ORHANİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "İNEGÖL",
        "STREET": "ORTAKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "İNEGÖL",
        "STREET": "OSMANİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "İNEGÖL",
        "STREET": "OSMANİYEKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "İNEGÖL",
        "STREET": "PAŞAÖREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "İNEGÖL",
        "STREET": "RÜŞTİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "İNEGÖL",
        "STREET": "SAADET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "İNEGÖL",
        "STREET": "SARIPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "İNEGÖL",
        "STREET": "SOĞUKDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "İNEGÖL",
        "STREET": "SULHİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "İNEGÖL",
        "STREET": "SULTANİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "İNEGÖL",
        "STREET": "SUNGURPAŞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "İNEGÖL",
        "STREET": "SÜLE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "İNEGÖL",
        "STREET": "SÜLEYMANİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "İNEGÖL",
        "STREET": "SÜLÜKLÜGÖL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "İNEGÖL",
        "STREET": "SÜPÜRTÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "İNEGÖL",
        "STREET": "SİNANBEY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "İNEGÖL",
        "STREET": "TAHTAKÖPRÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "İNEGÖL",
        "STREET": "TEKKEKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "İNEGÖL",
        "STREET": "TOKUŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "İNEGÖL",
        "STREET": "TURGUTALP MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "İNEGÖL",
        "STREET": "TURGUTALPKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "İNEGÖL",
        "STREET": "TUZLA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "İNEGÖL",
        "STREET": "TÜFEKÇİKONAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "İNEGÖL",
        "STREET": "YENİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "İNEGÖL",
        "STREET": "YENİCE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "İNEGÖL",
        "STREET": "YENİCEKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "İNEGÖL",
        "STREET": "YENİKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "İNEGÖL",
        "STREET": "YENİYÖRÜK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "İNEGÖL",
        "STREET": "YUKARIBALLIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "İNEGÖL",
        "STREET": "YUNUSEMRE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "İNEGÖL",
        "STREET": "YİĞİT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "İNEGÖL",
        "STREET": "ÇAVUŞKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "İNEGÖL",
        "STREET": "ÇAYLICA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "İNEGÖL",
        "STREET": "ÇAYYAKA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "İNEGÖL",
        "STREET": "ÇELTİKÇİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "İNEGÖL",
        "STREET": "ÇİFTLİKKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "İNEGÖL",
        "STREET": "ÇİTLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "İNEGÖL",
        "STREET": "ÖZLÜCE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "İNEGÖL",
        "STREET": "İCLALİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "İNEGÖL",
        "STREET": "İHSANİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "İNEGÖL",
        "STREET": "İSAÖREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "İNEGÖL",
        "STREET": "ŞEHİTLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "İNEGÖL",
        "STREET": "ŞİPALİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "İZNİK",
        "STREET": "AYDINLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "İZNİK",
        "STREET": "BAYINDIR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "İZNİK",
        "STREET": "BEYLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "İZNİK",
        "STREET": "BOYALICA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "İZNİK",
        "STREET": "CANDARLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "İZNİK",
        "STREET": "DERBENT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "İZNİK",
        "STREET": "DEREKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "İZNİK",
        "STREET": "DIRAZALİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "İZNİK",
        "STREET": "ELBEYLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "İZNİK",
        "STREET": "ELMALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "İZNİK",
        "STREET": "EŞREFZADE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "İZNİK",
        "STREET": "GÖLLÜCE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "İZNİK",
        "STREET": "GÜRMÜZLÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "İZNİK",
        "STREET": "HACIOSMAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "İZNİK",
        "STREET": "HOCAKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "İZNİK",
        "STREET": "HİSARDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "İZNİK",
        "STREET": "KARATEKİN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "İZNİK",
        "STREET": "KAYNARCA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "İZNİK",
        "STREET": "KIRINTI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "İZNİK",
        "STREET": "KUTLUCA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "İZNİK",
        "STREET": "MAHMUDİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "İZNİK",
        "STREET": "MAHMUT ÇELEBİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "İZNİK",
        "STREET": "MECİDİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "İZNİK",
        "STREET": "MUSTAFA KEMAL PAŞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "İZNİK",
        "STREET": "MUSTAFALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "İZNİK",
        "STREET": "MÜŞKÜLE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "İZNİK",
        "STREET": "ORHANİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "İZNİK",
        "STREET": "OSMANİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "İZNİK",
        "STREET": "SANSARAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "İZNİK",
        "STREET": "SARIAĞIL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "İZNİK",
        "STREET": "SELÇUK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "İZNİK",
        "STREET": "SÜLEYMANİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "İZNİK",
        "STREET": "TACİR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "İZNİK",
        "STREET": "YENİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "İZNİK",
        "STREET": "YENİŞEREFİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "İZNİK",
        "STREET": "YEŞİL CAMİİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "İZNİK",
        "STREET": "YÜRÜKLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "İZNİK",
        "STREET": "ÇAKIRCA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "İZNİK",
        "STREET": "ÇAMDİBİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "İZNİK",
        "STREET": "ÇAMOLUK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "İZNİK",
        "STREET": "ÇAMPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "İZNİK",
        "STREET": "ÇİÇEKLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "İZNİK",
        "STREET": "ÖMERLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "İZNİK",
        "STREET": "İHSANİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "İZNİK",
        "STREET": "İNİKLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BURSA",
        "DISTRICT": "İZNİK",
        "STREET": "ŞEREFİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "BİLECİK",
        "DISTRICT": "BOZÜYÜK",
        "STREET": "4 EYLÜL MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BİLECİK",
        "DISTRICT": "BOZÜYÜK",
        "STREET": "AKPINAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BİLECİK",
        "DISTRICT": "BOZÜYÜK",
        "STREET": "AKSUTEKKE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİLECİK",
        "DISTRICT": "BOZÜYÜK",
        "STREET": "AKÇAPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİLECİK",
        "DISTRICT": "BOZÜYÜK",
        "STREET": "ALİBEYDÜZÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİLECİK",
        "DISTRICT": "BOZÜYÜK",
        "STREET": "AŞAĞIARMUTLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİLECİK",
        "DISTRICT": "BOZÜYÜK",
        "STREET": "BOZALAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİLECİK",
        "DISTRICT": "BOZÜYÜK",
        "STREET": "CAMİLİYAYLA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİLECİK",
        "DISTRICT": "BOZÜYÜK",
        "STREET": "CİHANGAZİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİLECİK",
        "DISTRICT": "BOZÜYÜK",
        "STREET": "DARIDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİLECİK",
        "DISTRICT": "BOZÜYÜK",
        "STREET": "DELİELMACIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİLECİK",
        "DISTRICT": "BOZÜYÜK",
        "STREET": "DODURGA-MEHMET AKİF MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BİLECİK",
        "DISTRICT": "BOZÜYÜK",
        "STREET": "DODURGA-MİTHATPAŞA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BİLECİK",
        "DISTRICT": "BOZÜYÜK",
        "STREET": "DODURGA-NAMIK KEMAL MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BİLECİK",
        "DISTRICT": "BOZÜYÜK",
        "STREET": "DODURGA-ZİYA GÖKALP MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BİLECİK",
        "DISTRICT": "BOZÜYÜK",
        "STREET": "DOMBAYÇAYIRI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİLECİK",
        "DISTRICT": "BOZÜYÜK",
        "STREET": "DÜBEKLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİLECİK",
        "DISTRICT": "BOZÜYÜK",
        "STREET": "DÜZAĞAÇ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİLECİK",
        "DISTRICT": "BOZÜYÜK",
        "STREET": "DÜZDAĞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİLECİK",
        "DISTRICT": "BOZÜYÜK",
        "STREET": "ECEKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "BİLECİK",
        "DISTRICT": "BOZÜYÜK",
        "STREET": "ERİKLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİLECİK",
        "DISTRICT": "BOZÜYÜK",
        "STREET": "GÖKÇELİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİLECİK",
        "DISTRICT": "BOZÜYÜK",
        "STREET": "GÖYNÜCEK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİLECİK",
        "DISTRICT": "BOZÜYÜK",
        "STREET": "GÜNYARIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİLECİK",
        "DISTRICT": "BOZÜYÜK",
        "STREET": "HAMİDİYE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİLECİK",
        "DISTRICT": "BOZÜYÜK",
        "STREET": "KANDİLLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİLECİK",
        "DISTRICT": "BOZÜYÜK",
        "STREET": "KAPANALAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİLECİK",
        "DISTRICT": "BOZÜYÜK",
        "STREET": "KARAAĞAÇ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİLECİK",
        "DISTRICT": "BOZÜYÜK",
        "STREET": "KARABAYIR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİLECİK",
        "DISTRICT": "BOZÜYÜK",
        "STREET": "KARAÇAYIR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİLECİK",
        "DISTRICT": "BOZÜYÜK",
        "STREET": "KASIMPAŞA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BİLECİK",
        "DISTRICT": "BOZÜYÜK",
        "STREET": "KETENLİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİLECİK",
        "DISTRICT": "BOZÜYÜK",
        "STREET": "KIZILCAPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİLECİK",
        "DISTRICT": "BOZÜYÜK",
        "STREET": "KIZILTEPE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİLECİK",
        "DISTRICT": "BOZÜYÜK",
        "STREET": "KOVALICA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİLECİK",
        "DISTRICT": "BOZÜYÜK",
        "STREET": "KOZPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİLECİK",
        "DISTRICT": "BOZÜYÜK",
        "STREET": "KUYUPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİLECİK",
        "DISTRICT": "BOZÜYÜK",
        "STREET": "METRİSTEPE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİLECİK",
        "DISTRICT": "BOZÜYÜK",
        "STREET": "MURATDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİLECİK",
        "DISTRICT": "BOZÜYÜK",
        "STREET": "ORMANGÜZLE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİLECİK",
        "DISTRICT": "BOZÜYÜK",
        "STREET": "OSMANİYE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİLECİK",
        "DISTRICT": "BOZÜYÜK",
        "STREET": "POYRA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİLECİK",
        "DISTRICT": "BOZÜYÜK",
        "STREET": "REVNAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİLECİK",
        "DISTRICT": "BOZÜYÜK",
        "STREET": "SARAYCIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİLECİK",
        "DISTRICT": "BOZÜYÜK",
        "STREET": "TEKKE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BİLECİK",
        "DISTRICT": "BOZÜYÜK",
        "STREET": "YEDİLER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BİLECİK",
        "DISTRICT": "BOZÜYÜK",
        "STREET": "YENİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BİLECİK",
        "DISTRICT": "BOZÜYÜK",
        "STREET": "YENİDODURGA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİLECİK",
        "DISTRICT": "BOZÜYÜK",
        "STREET": "YENİDOĞAN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BİLECİK",
        "DISTRICT": "BOZÜYÜK",
        "STREET": "YENİÇEPNİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİLECİK",
        "DISTRICT": "BOZÜYÜK",
        "STREET": "YENİÜREĞİL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİLECİK",
        "DISTRICT": "BOZÜYÜK",
        "STREET": "YEŞİLKENT MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BİLECİK",
        "DISTRICT": "BOZÜYÜK",
        "STREET": "YEŞİLÇUKURCA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİLECİK",
        "DISTRICT": "BOZÜYÜK",
        "STREET": "ÇAMYAYLA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİLECİK",
        "DISTRICT": "BOZÜYÜK",
        "STREET": "ÇARŞI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BİLECİK",
        "DISTRICT": "BOZÜYÜK",
        "STREET": "ÇAYDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİLECİK",
        "DISTRICT": "BOZÜYÜK",
        "STREET": "ÇOKÇAPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİLECİK",
        "DISTRICT": "BOZÜYÜK",
        "STREET": "İÇKÖY MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BİLECİK",
        "DISTRICT": "MERKEZ",
        "STREET": "ABADİYE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİLECİK",
        "DISTRICT": "MERKEZ",
        "STREET": "ABBASLIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİLECİK",
        "DISTRICT": "MERKEZ",
        "STREET": "AHMETPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİLECİK",
        "DISTRICT": "MERKEZ",
        "STREET": "ALPAĞUT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİLECİK",
        "DISTRICT": "MERKEZ",
        "STREET": "AYVACIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİLECİK",
        "DISTRICT": "MERKEZ",
        "STREET": "AŞAĞIKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "BİLECİK",
        "DISTRICT": "MERKEZ",
        "STREET": "BAHÇECİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİLECİK",
        "DISTRICT": "MERKEZ",
        "STREET": "BAHÇELİEVLER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BİLECİK",
        "DISTRICT": "MERKEZ",
        "STREET": "BAYIRKÖY-FATİH MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BİLECİK",
        "DISTRICT": "MERKEZ",
        "STREET": "BAYIRKÖY-GAZİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BİLECİK",
        "DISTRICT": "MERKEZ",
        "STREET": "BAYIRKÖY-SARMAŞIK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BİLECİK",
        "DISTRICT": "MERKEZ",
        "STREET": "BAŞKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "BİLECİK",
        "DISTRICT": "MERKEZ",
        "STREET": "BEKDEMİR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİLECİK",
        "DISTRICT": "MERKEZ",
        "STREET": "BEYCE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİLECİK",
        "DISTRICT": "MERKEZ",
        "STREET": "BEŞİKTAŞ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BİLECİK",
        "DISTRICT": "MERKEZ",
        "STREET": "BİLECİK M TİPİ KAPALI CEZA İNFAZ KURUMU",
        "TYPE": "0"
      },
      {
        "CITY": "BİLECİK",
        "DISTRICT": "MERKEZ",
        "STREET": "CUMALI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİLECİK",
        "DISTRICT": "MERKEZ",
        "STREET": "CUMHURİYET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BİLECİK",
        "DISTRICT": "MERKEZ",
        "STREET": "DERESAKARI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİLECİK",
        "DISTRICT": "MERKEZ",
        "STREET": "DEREŞEMSETTİN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİLECİK",
        "DISTRICT": "MERKEZ",
        "STREET": "ELMABAHÇE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİLECİK",
        "DISTRICT": "MERKEZ",
        "STREET": "ERKOCA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİLECİK",
        "DISTRICT": "MERKEZ",
        "STREET": "ERTUĞRULGAZİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BİLECİK",
        "DISTRICT": "MERKEZ",
        "STREET": "GAZİPAŞA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BİLECİK",
        "DISTRICT": "MERKEZ",
        "STREET": "GÖKPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİLECİK",
        "DISTRICT": "MERKEZ",
        "STREET": "GÜLÜMBE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİLECİK",
        "DISTRICT": "MERKEZ",
        "STREET": "HASANDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİLECİK",
        "DISTRICT": "MERKEZ",
        "STREET": "HÜRRİYET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BİLECİK",
        "DISTRICT": "MERKEZ",
        "STREET": "KAPAKLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİLECİK",
        "DISTRICT": "MERKEZ",
        "STREET": "KARAAĞAÇ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİLECİK",
        "DISTRICT": "MERKEZ",
        "STREET": "KAVAKLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİLECİK",
        "DISTRICT": "MERKEZ",
        "STREET": "KENDİRLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİLECİK",
        "DISTRICT": "MERKEZ",
        "STREET": "KEPİRLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİLECİK",
        "DISTRICT": "MERKEZ",
        "STREET": "KINIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİLECİK",
        "DISTRICT": "MERKEZ",
        "STREET": "KIZILDAMLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİLECİK",
        "DISTRICT": "MERKEZ",
        "STREET": "KOYUNKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "BİLECİK",
        "DISTRICT": "MERKEZ",
        "STREET": "KURTKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "BİLECİK",
        "DISTRICT": "MERKEZ",
        "STREET": "KUYUBAŞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİLECİK",
        "DISTRICT": "MERKEZ",
        "STREET": "KÜNCEĞİZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİLECİK",
        "DISTRICT": "MERKEZ",
        "STREET": "KÜPLÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİLECİK",
        "DISTRICT": "MERKEZ",
        "STREET": "NECMİYEKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "BİLECİK",
        "DISTRICT": "MERKEZ",
        "STREET": "OKLUCA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİLECİK",
        "DISTRICT": "MERKEZ",
        "STREET": "ORHANGAZİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BİLECİK",
        "DISTRICT": "MERKEZ",
        "STREET": "OSMANGAZİ  MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BİLECİK",
        "DISTRICT": "MERKEZ",
        "STREET": "PELİTÖZÜ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BİLECİK",
        "DISTRICT": "MERKEZ",
        "STREET": "SELBÜKÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİLECİK",
        "DISTRICT": "MERKEZ",
        "STREET": "SELÖZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİLECİK",
        "DISTRICT": "MERKEZ",
        "STREET": "SÜTLÜK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİLECİK",
        "DISTRICT": "MERKEZ",
        "STREET": "TAŞÇILAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİLECİK",
        "DISTRICT": "MERKEZ",
        "STREET": "ULUPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİLECİK",
        "DISTRICT": "MERKEZ",
        "STREET": "VEZİRHAN-ERTUĞRULGAZİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BİLECİK",
        "DISTRICT": "MERKEZ",
        "STREET": "VEZİRHAN-KAYALAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BİLECİK",
        "DISTRICT": "MERKEZ",
        "STREET": "VEZİRHAN-KÖPRÜLÜ MEHMET PAŞA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BİLECİK",
        "DISTRICT": "MERKEZ",
        "STREET": "VEZİRHAN-YENİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BİLECİK",
        "DISTRICT": "MERKEZ",
        "STREET": "VEZİRHAN-İSTASYON MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BİLECİK",
        "DISTRICT": "MERKEZ",
        "STREET": "YENİKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "BİLECİK",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇAKIRPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİLECİK",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇAVUŞKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "BİLECİK",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇUKURÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİLECİK",
        "DISTRICT": "MERKEZ",
        "STREET": "ÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİLECİK",
        "DISTRICT": "MERKEZ",
        "STREET": "İKİZCE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİLECİK",
        "DISTRICT": "MERKEZ",
        "STREET": "İLYASBEY KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİLECİK",
        "DISTRICT": "MERKEZ",
        "STREET": "İLYASÇA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİLECİK",
        "DISTRICT": "MERKEZ",
        "STREET": "İSMETPAŞA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BİLECİK",
        "DISTRICT": "MERKEZ",
        "STREET": "İSTASYON MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BİLECİK",
        "DISTRICT": "MERKEZ",
        "STREET": "İSTİKLAL MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BİLECİK",
        "DISTRICT": "MERKEZ",
        "STREET": "ŞÜKRANİYE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİLECİK",
        "DISTRICT": "GÖLPAZARI",
        "STREET": "AKTAŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİLECİK",
        "DISTRICT": "GÖLPAZARI",
        "STREET": "AKÇAKAVAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİLECİK",
        "DISTRICT": "GÖLPAZARI",
        "STREET": "ALIÇ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİLECİK",
        "DISTRICT": "GÖLPAZARI",
        "STREET": "ARICAKLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİLECİK",
        "DISTRICT": "GÖLPAZARI",
        "STREET": "ARMUTÇUK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİLECİK",
        "DISTRICT": "GÖLPAZARI",
        "STREET": "BAHÇELİEVLER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BİLECİK",
        "DISTRICT": "GÖLPAZARI",
        "STREET": "BALTALI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİLECİK",
        "DISTRICT": "GÖLPAZARI",
        "STREET": "BAYAT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİLECİK",
        "DISTRICT": "GÖLPAZARI",
        "STREET": "BEDİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİLECİK",
        "DISTRICT": "GÖLPAZARI",
        "STREET": "BOLATLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİLECİK",
        "DISTRICT": "GÖLPAZARI",
        "STREET": "BÜYÜKBELEN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİLECİK",
        "DISTRICT": "GÖLPAZARI",
        "STREET": "BÜYÜKSUSUZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİLECİK",
        "DISTRICT": "GÖLPAZARI",
        "STREET": "BÜYÜKSÜRMELİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİLECİK",
        "DISTRICT": "GÖLPAZARI",
        "STREET": "DEMİRHANLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİLECİK",
        "DISTRICT": "GÖLPAZARI",
        "STREET": "DERECİKÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİLECİK",
        "DISTRICT": "GÖLPAZARI",
        "STREET": "DERELİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİLECİK",
        "DISTRICT": "GÖLPAZARI",
        "STREET": "DOKUZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİLECİK",
        "DISTRICT": "GÖLPAZARI",
        "STREET": "DOĞANCILAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİLECİK",
        "DISTRICT": "GÖLPAZARI",
        "STREET": "GÖKÇELER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİLECİK",
        "DISTRICT": "GÖLPAZARI",
        "STREET": "GÖKÇEÖZÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİLECİK",
        "DISTRICT": "GÖLPAZARI",
        "STREET": "GÖLDAĞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİLECİK",
        "DISTRICT": "GÖLPAZARI",
        "STREET": "GÖZAÇANLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİLECİK",
        "DISTRICT": "GÖLPAZARI",
        "STREET": "HACIKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "BİLECİK",
        "DISTRICT": "GÖLPAZARI",
        "STREET": "HAMİDİYE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİLECİK",
        "DISTRICT": "GÖLPAZARI",
        "STREET": "KARAAHMETLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİLECİK",
        "DISTRICT": "GÖLPAZARI",
        "STREET": "KARAAĞAÇ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİLECİK",
        "DISTRICT": "GÖLPAZARI",
        "STREET": "KARACALAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİLECİK",
        "DISTRICT": "GÖLPAZARI",
        "STREET": "KASIMLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİLECİK",
        "DISTRICT": "GÖLPAZARI",
        "STREET": "KAVAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİLECİK",
        "DISTRICT": "GÖLPAZARI",
        "STREET": "KESKİN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİLECİK",
        "DISTRICT": "GÖLPAZARI",
        "STREET": "KURŞUNLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİLECİK",
        "DISTRICT": "GÖLPAZARI",
        "STREET": "KUŞÇUÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİLECİK",
        "DISTRICT": "GÖLPAZARI",
        "STREET": "KÖPRÜCEK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİLECİK",
        "DISTRICT": "GÖLPAZARI",
        "STREET": "KÜMBET KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİLECİK",
        "DISTRICT": "GÖLPAZARI",
        "STREET": "KÜÇÜKSUSUZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİLECİK",
        "DISTRICT": "GÖLPAZARI",
        "STREET": "KÜÇÜKYENİCE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİLECİK",
        "DISTRICT": "GÖLPAZARI",
        "STREET": "ORTA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BİLECİK",
        "DISTRICT": "GÖLPAZARI",
        "STREET": "REŞADİYE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BİLECİK",
        "DISTRICT": "GÖLPAZARI",
        "STREET": "SARIHACILAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİLECİK",
        "DISTRICT": "GÖLPAZARI",
        "STREET": "SOFTALAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİLECİK",
        "DISTRICT": "GÖLPAZARI",
        "STREET": "SÖĞÜTCÜK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİLECİK",
        "DISTRICT": "GÖLPAZARI",
        "STREET": "TAŞÇIAHİLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİLECİK",
        "DISTRICT": "GÖLPAZARI",
        "STREET": "TONGURLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİLECİK",
        "DISTRICT": "GÖLPAZARI",
        "STREET": "TÜRKMEN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİLECİK",
        "DISTRICT": "GÖLPAZARI",
        "STREET": "ÇENGELLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİLECİK",
        "DISTRICT": "GÖLPAZARI",
        "STREET": "ÇIMIŞKI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİLECİK",
        "DISTRICT": "GÖLPAZARI",
        "STREET": "ÇUKURÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİLECİK",
        "DISTRICT": "GÖLPAZARI",
        "STREET": "ÇİFTLİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİLECİK",
        "DISTRICT": "GÖLPAZARI",
        "STREET": "ÜYÜK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİLECİK",
        "DISTRICT": "GÖLPAZARI",
        "STREET": "İNCİRLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİLECİK",
        "DISTRICT": "GÖLPAZARI",
        "STREET": "İSMETPAŞA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BİLECİK",
        "DISTRICT": "GÖLPAZARI",
        "STREET": "İSTİKLAL MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BİLECİK",
        "DISTRICT": "GÖLPAZARI",
        "STREET": "ŞAHİNLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİLECİK",
        "DISTRICT": "OSMANELİ",
        "STREET": "ADLİYE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİLECİK",
        "DISTRICT": "OSMANELİ",
        "STREET": "AKÇAPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİLECİK",
        "DISTRICT": "OSMANELİ",
        "STREET": "AVDAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİLECİK",
        "DISTRICT": "OSMANELİ",
        "STREET": "AĞLAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİLECİK",
        "DISTRICT": "OSMANELİ",
        "STREET": "BALÇIKHİSAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİLECİK",
        "DISTRICT": "OSMANELİ",
        "STREET": "BELENALAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİLECİK",
        "DISTRICT": "OSMANELİ",
        "STREET": "BEREKET KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİLECİK",
        "DISTRICT": "OSMANELİ",
        "STREET": "BORCAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİLECİK",
        "DISTRICT": "OSMANELİ",
        "STREET": "BOYUNKAYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİLECİK",
        "DISTRICT": "OSMANELİ",
        "STREET": "BÜYÜKYENİCE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİLECİK",
        "DISTRICT": "OSMANELİ",
        "STREET": "CAMİCEDİT MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BİLECİK",
        "DISTRICT": "OSMANELİ",
        "STREET": "CAMİKEBİR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BİLECİK",
        "DISTRICT": "OSMANELİ",
        "STREET": "CİCİLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİLECİK",
        "DISTRICT": "OSMANELİ",
        "STREET": "DEREYÖRÜK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİLECİK",
        "DISTRICT": "OSMANELİ",
        "STREET": "DÜZMEŞE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİLECİK",
        "DISTRICT": "OSMANELİ",
        "STREET": "ERİCEK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİLECİK",
        "DISTRICT": "OSMANELİ",
        "STREET": "GÜNÜÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİLECİK",
        "DISTRICT": "OSMANELİ",
        "STREET": "HACELOĞLU MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BİLECİK",
        "DISTRICT": "OSMANELİ",
        "STREET": "HİSARCIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİLECİK",
        "DISTRICT": "OSMANELİ",
        "STREET": "KAZANCI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİLECİK",
        "DISTRICT": "OSMANELİ",
        "STREET": "KIZILÖZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİLECİK",
        "DISTRICT": "OSMANELİ",
        "STREET": "MEDETLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİLECİK",
        "DISTRICT": "OSMANELİ",
        "STREET": "ORHANİYE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİLECİK",
        "DISTRICT": "OSMANELİ",
        "STREET": "OĞULPAŞA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİLECİK",
        "DISTRICT": "OSMANELİ",
        "STREET": "SARIYAZI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİLECİK",
        "DISTRICT": "OSMANELİ",
        "STREET": "SELÇİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİLECİK",
        "DISTRICT": "OSMANELİ",
        "STREET": "SELİMİYE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİLECİK",
        "DISTRICT": "OSMANELİ",
        "STREET": "SOĞUCAKPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİLECİK",
        "DISTRICT": "OSMANELİ",
        "STREET": "ÇERKEŞLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİLECİK",
        "DISTRICT": "OSMANELİ",
        "STREET": "ÇİFTLİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİLECİK",
        "DISTRICT": "OSMANELİ",
        "STREET": "İNÖNÜ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BİLECİK",
        "DISTRICT": "PAZARYERİ",
        "STREET": "AHMETLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİLECİK",
        "DISTRICT": "PAZARYERİ",
        "STREET": "ALINCA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİLECİK",
        "DISTRICT": "PAZARYERİ",
        "STREET": "ARAPDEDE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİLECİK",
        "DISTRICT": "PAZARYERİ",
        "STREET": "ARPADERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİLECİK",
        "DISTRICT": "PAZARYERİ",
        "STREET": "BAHÇESULTAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİLECİK",
        "DISTRICT": "PAZARYERİ",
        "STREET": "BEŞİKLİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BİLECİK",
        "DISTRICT": "PAZARYERİ",
        "STREET": "BOZCAARMUT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİLECİK",
        "DISTRICT": "PAZARYERİ",
        "STREET": "BULDUK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİLECİK",
        "DISTRICT": "PAZARYERİ",
        "STREET": "BURÇALIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİLECİK",
        "DISTRICT": "PAZARYERİ",
        "STREET": "BÜYÜKELMALI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİLECİK",
        "DISTRICT": "PAZARYERİ",
        "STREET": "DEMİRKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "BİLECİK",
        "DISTRICT": "PAZARYERİ",
        "STREET": "DEREBOĞAZ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BİLECİK",
        "DISTRICT": "PAZARYERİ",
        "STREET": "DEREKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "BİLECİK",
        "DISTRICT": "PAZARYERİ",
        "STREET": "DOĞANLAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BİLECİK",
        "DISTRICT": "PAZARYERİ",
        "STREET": "DÜLGEROĞLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİLECİK",
        "DISTRICT": "PAZARYERİ",
        "STREET": "ESEMEN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİLECİK",
        "DISTRICT": "PAZARYERİ",
        "STREET": "FIRANLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİLECİK",
        "DISTRICT": "PAZARYERİ",
        "STREET": "GÜDE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİLECİK",
        "DISTRICT": "PAZARYERİ",
        "STREET": "GÜMÜŞDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİLECİK",
        "DISTRICT": "PAZARYERİ",
        "STREET": "GÜNYURDU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİLECİK",
        "DISTRICT": "PAZARYERİ",
        "STREET": "KARADEDE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİLECİK",
        "DISTRICT": "PAZARYERİ",
        "STREET": "KARAKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "BİLECİK",
        "DISTRICT": "PAZARYERİ",
        "STREET": "KINIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİLECİK",
        "DISTRICT": "PAZARYERİ",
        "STREET": "KÜÇÜKELMALI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİLECİK",
        "DISTRICT": "PAZARYERİ",
        "STREET": "NAZİFPAŞA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİLECİK",
        "DISTRICT": "PAZARYERİ",
        "STREET": "SARIDAYI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİLECİK",
        "DISTRICT": "PAZARYERİ",
        "STREET": "SARNIÇ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİLECİK",
        "DISTRICT": "PAZARYERİ",
        "STREET": "YENİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BİLECİK",
        "DISTRICT": "PAZARYERİ",
        "STREET": "YÜZBAŞI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BİLECİK",
        "DISTRICT": "PAZARYERİ",
        "STREET": "ÇARŞI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BİLECİK",
        "DISTRICT": "SÖĞÜT",
        "STREET": "AKÇASU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİLECİK",
        "DISTRICT": "SÖĞÜT",
        "STREET": "BALABAN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BİLECİK",
        "DISTRICT": "SÖĞÜT",
        "STREET": "BORCAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİLECİK",
        "DISTRICT": "SÖĞÜT",
        "STREET": "CUMHURİYET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BİLECİK",
        "DISTRICT": "SÖĞÜT",
        "STREET": "DEREBOYU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİLECİK",
        "DISTRICT": "SÖĞÜT",
        "STREET": "DUDAŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİLECİK",
        "DISTRICT": "SÖĞÜT",
        "STREET": "DÖMEZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİLECİK",
        "DISTRICT": "SÖĞÜT",
        "STREET": "GEÇİTLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİLECİK",
        "DISTRICT": "SÖĞÜT",
        "STREET": "GÜNDÜZBEY KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİLECİK",
        "DISTRICT": "SÖĞÜT",
        "STREET": "HAMİTABAT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİLECİK",
        "DISTRICT": "SÖĞÜT",
        "STREET": "HAYRİYE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİLECİK",
        "DISTRICT": "SÖĞÜT",
        "STREET": "KAYABALI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİLECİK",
        "DISTRICT": "SÖĞÜT",
        "STREET": "KAYHAN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BİLECİK",
        "DISTRICT": "SÖĞÜT",
        "STREET": "KEPEN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİLECİK",
        "DISTRICT": "SÖĞÜT",
        "STREET": "KIZILSARAY KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİLECİK",
        "DISTRICT": "SÖĞÜT",
        "STREET": "KÜRE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİLECİK",
        "DISTRICT": "SÖĞÜT",
        "STREET": "OLUKLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİLECİK",
        "DISTRICT": "SÖĞÜT",
        "STREET": "ORTA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BİLECİK",
        "DISTRICT": "SÖĞÜT",
        "STREET": "ORTACA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİLECİK",
        "DISTRICT": "SÖĞÜT",
        "STREET": "RIZAPAŞA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİLECİK",
        "DISTRICT": "SÖĞÜT",
        "STREET": "SAVCIBEY KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİLECİK",
        "DISTRICT": "SÖĞÜT",
        "STREET": "SIRHOCA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİLECİK",
        "DISTRICT": "SÖĞÜT",
        "STREET": "TUZAKLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİLECİK",
        "DISTRICT": "SÖĞÜT",
        "STREET": "TÜRKMENBAŞI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BİLECİK",
        "DISTRICT": "SÖĞÜT",
        "STREET": "YAKACIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİLECİK",
        "DISTRICT": "SÖĞÜT",
        "STREET": "YEŞİLYURT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİLECİK",
        "DISTRICT": "SÖĞÜT",
        "STREET": "ZEMZEMİYE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİLECİK",
        "DISTRICT": "SÖĞÜT",
        "STREET": "ÇALTI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİLECİK",
        "DISTRICT": "YENİPAZAR",
        "STREET": "AŞAĞIBOĞAZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİLECİK",
        "DISTRICT": "YENİPAZAR",
        "STREET": "AŞAĞIÇAYLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİLECİK",
        "DISTRICT": "YENİPAZAR",
        "STREET": "BATIBELENÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİLECİK",
        "DISTRICT": "YENİPAZAR",
        "STREET": "BELKESE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİLECİK",
        "DISTRICT": "YENİPAZAR",
        "STREET": "CAFERLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİLECİK",
        "DISTRICT": "YENİPAZAR",
        "STREET": "DANİŞMENT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİLECİK",
        "DISTRICT": "YENİPAZAR",
        "STREET": "DEREKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "BİLECİK",
        "DISTRICT": "YENİPAZAR",
        "STREET": "DOĞUBELENÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİLECİK",
        "DISTRICT": "YENİPAZAR",
        "STREET": "ESENKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "BİLECİK",
        "DISTRICT": "YENİPAZAR",
        "STREET": "KARAHASANLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİLECİK",
        "DISTRICT": "YENİPAZAR",
        "STREET": "KATRAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİLECİK",
        "DISTRICT": "YENİPAZAR",
        "STREET": "KAVACIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİLECİK",
        "DISTRICT": "YENİPAZAR",
        "STREET": "KUŞÇA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİLECİK",
        "DISTRICT": "YENİPAZAR",
        "STREET": "KÖSÜRE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİLECİK",
        "DISTRICT": "YENİPAZAR",
        "STREET": "KÜKÜRT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİLECİK",
        "DISTRICT": "YENİPAZAR",
        "STREET": "NASUHLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİLECİK",
        "DISTRICT": "YENİPAZAR",
        "STREET": "OSMANGAZİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BİLECİK",
        "DISTRICT": "YENİPAZAR",
        "STREET": "SELİM KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİLECİK",
        "DISTRICT": "YENİPAZAR",
        "STREET": "SORGUNCUKAHİLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİLECİK",
        "DISTRICT": "YENİPAZAR",
        "STREET": "TOHUMLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİLECİK",
        "DISTRICT": "YENİPAZAR",
        "STREET": "ULUCAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİLECİK",
        "DISTRICT": "YENİPAZAR",
        "STREET": "YENİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BİLECİK",
        "DISTRICT": "YENİPAZAR",
        "STREET": "YUKARIBOĞAZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİLECİK",
        "DISTRICT": "YENİPAZAR",
        "STREET": "YUKARIÇAYLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİLECİK",
        "DISTRICT": "YENİPAZAR",
        "STREET": "YUMAKLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİLECİK",
        "DISTRICT": "YENİPAZAR",
        "STREET": "ZAFER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BİLECİK",
        "DISTRICT": "YENİPAZAR",
        "STREET": "ÇÖTE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BİLECİK",
        "DISTRICT": "İNHİSAR",
        "STREET": "AKKUM MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BİLECİK",
        "DISTRICT": "İNHİSAR",
        "STREET": "AKKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "BİLECİK",
        "DISTRICT": "İNHİSAR",
        "STREET": "GÜNER ORBAY MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BİLECİK",
        "DISTRICT": "İNHİSAR",
        "STREET": "HARMANKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "BİLECİK",
        "DISTRICT": "İNHİSAR",
        "STREET": "HİSARCIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİLECİK",
        "DISTRICT": "İNHİSAR",
        "STREET": "KARAAĞAÇ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BİLECİK",
        "DISTRICT": "İNHİSAR",
        "STREET": "KOYUNLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİLECİK",
        "DISTRICT": "İNHİSAR",
        "STREET": "MURATÇA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİLECİK",
        "DISTRICT": "İNHİSAR",
        "STREET": "SAMRI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİLECİK",
        "DISTRICT": "İNHİSAR",
        "STREET": "TARPAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİLECİK",
        "DISTRICT": "İNHİSAR",
        "STREET": "TOZMAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİLECİK",
        "DISTRICT": "İNHİSAR",
        "STREET": "ZAFER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BİLECİK",
        "DISTRICT": "İNHİSAR",
        "STREET": "ÇAYKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "ADAKLI",
        "STREET": "AFET KONUTLARI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "ADAKLI",
        "STREET": "AKBİNEK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "ADAKLI",
        "STREET": "ARICA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "ADAKLI",
        "STREET": "AYVADÜZÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "ADAKLI",
        "STREET": "BAĞLARPINARI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "ADAKLI",
        "STREET": "DEMİROLUK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "ADAKLI",
        "STREET": "DOLUÇAY KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "ADAKLI",
        "STREET": "DOĞANKAYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "ADAKLI",
        "STREET": "DÖŞLÜCE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "ADAKLI",
        "STREET": "ELMADÜZÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "ADAKLI",
        "STREET": "ERBAŞLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "ADAKLI",
        "STREET": "ERLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "ADAKLI",
        "STREET": "GÖKÇELİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "ADAKLI",
        "STREET": "GÜNGÖRSÜN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "ADAKLI",
        "STREET": "HASBAĞLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "ADAKLI",
        "STREET": "KARAÇUBUK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "ADAKLI",
        "STREET": "KAYNAKDÜZÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "ADAKLI",
        "STREET": "KOZLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "ADAKLI",
        "STREET": "MERKEZ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "ADAKLI",
        "STREET": "SEVKAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "ADAKLI",
        "STREET": "TOPAĞAÇLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "ADAKLI",
        "STREET": "YEŞİLTEPE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "ADAKLI",
        "STREET": "ÇATMA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "ADAKLI",
        "STREET": "ÇEVRELİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "MERKEZ",
        "STREET": "AKDURMUŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "MERKEZ",
        "STREET": "ALATEPE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "MERKEZ",
        "STREET": "ALTINIŞIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "MERKEZ",
        "STREET": "ALİBİR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "MERKEZ",
        "STREET": "ARDIÇTEPE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "MERKEZ",
        "STREET": "ARICILAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "MERKEZ",
        "STREET": "AZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "MERKEZ",
        "STREET": "AĞAÇELİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "MERKEZ",
        "STREET": "AĞAÇYOLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "MERKEZ",
        "STREET": "AŞAĞIAKPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "MERKEZ",
        "STREET": "AŞAĞIKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "MERKEZ",
        "STREET": "BAHÇELİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "MERKEZ",
        "STREET": "BAHÇELİEVLER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "MERKEZ",
        "STREET": "BALIKLIÇAY KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "MERKEZ",
        "STREET": "BALPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "MERKEZ",
        "STREET": "BÜYÜKTEKÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "MERKEZ",
        "STREET": "BİLALOĞLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "MERKEZ",
        "STREET": "DALLITEPE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "MERKEZ",
        "STREET": "DÜGERNAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "MERKEZ",
        "STREET": "DÜZYAYLA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "MERKEZ",
        "STREET": "DİKKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "MERKEZ",
        "STREET": "DİKME KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "MERKEZ",
        "STREET": "DİREKLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "MERKEZ",
        "STREET": "DİŞBUDAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "MERKEZ",
        "STREET": "EKİNYOLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "MERKEZ",
        "STREET": "ELMALI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "MERKEZ",
        "STREET": "EMTAĞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "MERKEZ",
        "STREET": "ERDEMLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "MERKEZ",
        "STREET": "ERENTEPE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "MERKEZ",
        "STREET": "GARİP KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "MERKEZ",
        "STREET": "GÖKDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "MERKEZ",
        "STREET": "GÖKÇEKANAT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "MERKEZ",
        "STREET": "GÖKÇELİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "MERKEZ",
        "STREET": "GÖLTEPESİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "MERKEZ",
        "STREET": "GÖZELER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "MERKEZ",
        "STREET": "GÖZER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "MERKEZ",
        "STREET": "GÜMÜŞLÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "MERKEZ",
        "STREET": "GÜNGÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "MERKEZ",
        "STREET": "GÜRPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "MERKEZ",
        "STREET": "GÜVEÇLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "MERKEZ",
        "STREET": "HAZİRAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "MERKEZ",
        "STREET": "ILICALAR-ILICA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "MERKEZ",
        "STREET": "ILICALAR-KAMIŞLI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "MERKEZ",
        "STREET": "ILICALAR-KILIÇLI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "MERKEZ",
        "STREET": "ILICALAR-UZUNDERE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "MERKEZ",
        "STREET": "ILICALAR-ÇAY MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "MERKEZ",
        "STREET": "ILICALAR-İÇPINAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "MERKEZ",
        "STREET": "KARDEŞLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "MERKEZ",
        "STREET": "KARTAL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "MERKEZ",
        "STREET": "KARŞIYAKA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "MERKEZ",
        "STREET": "KILÇADIR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "MERKEZ",
        "STREET": "KIRAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "MERKEZ",
        "STREET": "KUMGEÇİT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "MERKEZ",
        "STREET": "KURTULUŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "MERKEZ",
        "STREET": "KURUCA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "MERKEZ",
        "STREET": "KURUDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "MERKEZ",
        "STREET": "KUŞBURNU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "MERKEZ",
        "STREET": "KUŞKONDU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "MERKEZ",
        "STREET": "KÖKLÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "MERKEZ",
        "STREET": "KÜLTÜR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "MERKEZ",
        "STREET": "KÜÇÜKTEKÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "MERKEZ",
        "STREET": "MİRZAN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "MERKEZ",
        "STREET": "OLUKPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "MERKEZ",
        "STREET": "ORMANARDI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "MERKEZ",
        "STREET": "ORTAKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "MERKEZ",
        "STREET": "ORTAÇANAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "MERKEZ",
        "STREET": "OĞULDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "MERKEZ",
        "STREET": "RECEP TAYYİP ERDOĞAN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "MERKEZ",
        "STREET": "SANCAK-AKBUDAK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "MERKEZ",
        "STREET": "SANCAK-BÜYÜKBAŞKÖY MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "MERKEZ",
        "STREET": "SANCAK-HOŞKAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "MERKEZ",
        "STREET": "SANCAK-KARAPINAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "MERKEZ",
        "STREET": "SANCAK-KÜÇÜKBAŞKÖY MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "MERKEZ",
        "STREET": "SANCAK-SARIGÜMÜŞ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "MERKEZ",
        "STREET": "SANCAK-YEŞİLOVA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "MERKEZ",
        "STREET": "SANCAK-ÇİMENLİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "MERKEZ",
        "STREET": "SANCAKLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "MERKEZ",
        "STREET": "SARAY MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "MERKEZ",
        "STREET": "SARIÇİÇEK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "MERKEZ",
        "STREET": "SELAHADDİN-İ EYYUBİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "MERKEZ",
        "STREET": "SUDÜĞÜNÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "MERKEZ",
        "STREET": "SUVARAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "MERKEZ",
        "STREET": "SÜTGÖLÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "MERKEZ",
        "STREET": "SİMANİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "MERKEZ",
        "STREET": "TEPEBAŞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "MERKEZ",
        "STREET": "TOPALAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "MERKEZ",
        "STREET": "UZUNSAVAT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "MERKEZ",
        "STREET": "UĞUROVA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "MERKEZ",
        "STREET": "YAMAÇ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "MERKEZ",
        "STREET": "YAYGINÇAYIR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "MERKEZ",
        "STREET": "YAZGÜLÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "MERKEZ",
        "STREET": "YELESEN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "MERKEZ",
        "STREET": "YENİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "MERKEZ",
        "STREET": "YENİBAŞLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "MERKEZ",
        "STREET": "YENİKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "MERKEZ",
        "STREET": "YENİŞEHİR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "MERKEZ",
        "STREET": "YEŞİLKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "MERKEZ",
        "STREET": "YEŞİLYURT MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "MERKEZ",
        "STREET": "YOLÇATI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "MERKEZ",
        "STREET": "YUKARIAKPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "MERKEZ",
        "STREET": "YUMAKLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇAVUŞLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇAYAĞZI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇAYBOYU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇELTİKSUYU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇEVRİMPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇOBANTAŞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇUKURCA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇİRİŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇİÇEKDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇİÇEKYAYLA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "MERKEZ",
        "STREET": "İNALI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "MERKEZ",
        "STREET": "İNCESU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "MERKEZ",
        "STREET": "İNÖNÜ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "MERKEZ",
        "STREET": "İÇMELER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "MERKEZ",
        "STREET": "ŞABANKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "MERKEZ",
        "STREET": "ŞEHİT MUSTAFA GÜNDOĞDU MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "GENÇ",
        "STREET": "ALAADDİN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "GENÇ",
        "STREET": "ARDIÇDİBİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "GENÇ",
        "STREET": "BALGÖZE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "GENÇ",
        "STREET": "BAYIRLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "GENÇ",
        "STREET": "BULGURLUK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "GENÇ",
        "STREET": "BÜYÜKÇAĞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "GENÇ",
        "STREET": "BİNEKLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "GENÇ",
        "STREET": "CUMHURİYET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "GENÇ",
        "STREET": "DEREKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "GENÇ",
        "STREET": "DOĞANCA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "GENÇ",
        "STREET": "DOĞANEVLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "GENÇ",
        "STREET": "DİLEKTAŞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "GENÇ",
        "STREET": "DİREKLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "GENÇ",
        "STREET": "ELMAGÜNÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "GENÇ",
        "STREET": "GEYİKDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "GENÇ",
        "STREET": "GÖNÜLAÇAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "GENÇ",
        "STREET": "GÖZERTEPE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "GENÇ",
        "STREET": "GÖZÜTOK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "GENÇ",
        "STREET": "GÜZELDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "GENÇ",
        "STREET": "KEKLİKDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "GENÇ",
        "STREET": "KEPÇELİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "GENÇ",
        "STREET": "KOÇSIRTI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "GENÇ",
        "STREET": "KÜLTÜR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "GENÇ",
        "STREET": "MEŞEDALI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "GENÇ",
        "STREET": "PINARALTI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "GENÇ",
        "STREET": "SARIBUDAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "GENÇ",
        "STREET": "SARMAKAYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "GENÇ",
        "STREET": "SAĞGÖZE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "GENÇ",
        "STREET": "SERVİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "GENÇ",
        "STREET": "SÜREKLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "GENÇ",
        "STREET": "TARLABAŞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "GENÇ",
        "STREET": "YATANSÖĞÜT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "GENÇ",
        "STREET": "YAYLA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "GENÇ",
        "STREET": "YAZKONAĞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "GENÇ",
        "STREET": "YAĞIZCA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "GENÇ",
        "STREET": "YENİSU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "GENÇ",
        "STREET": "YENİYAZI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "GENÇ",
        "STREET": "YENİŞEHİR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "GENÇ",
        "STREET": "YOLDAŞAN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "GENÇ",
        "STREET": "YİĞİTBAŞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "GENÇ",
        "STREET": "ÇANAKÇI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "GENÇ",
        "STREET": "ÇAYTEPE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "GENÇ",
        "STREET": "ÇEVİRME KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "GENÇ",
        "STREET": "ŞEHİTKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "GENÇ",
        "STREET": "ŞEHİTTEPE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "KARLIOVA",
        "STREET": "AŞAĞIYAĞMURLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "KARLIOVA",
        "STREET": "BAHÇEKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "KARLIOVA",
        "STREET": "BAĞLIİSA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "KARLIOVA",
        "STREET": "BONCUKGÖZE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "KARLIOVA",
        "STREET": "CİLLİGÖL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "KARLIOVA",
        "STREET": "DÖRTYOL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "KARLIOVA",
        "STREET": "GEÇİTLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "KARLIOVA",
        "STREET": "GÖYNÜK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "KARLIOVA",
        "STREET": "HACILAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "KARLIOVA",
        "STREET": "HALİFAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "KARLIOVA",
        "STREET": "HARMANTEPE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "KARLIOVA",
        "STREET": "HASANOVA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "KARLIOVA",
        "STREET": "ILIPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "KARLIOVA",
        "STREET": "KALE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "KARLIOVA",
        "STREET": "KALENCİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "KARLIOVA",
        "STREET": "KANIREŞ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "KARLIOVA",
        "STREET": "KANTARKAYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "KARLIOVA",
        "STREET": "KANİTAHT MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "KARLIOVA",
        "STREET": "KARABALÇIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "KARLIOVA",
        "STREET": "KARGAPAZARI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "KARLIOVA",
        "STREET": "KARLICA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "KARLIOVA",
        "STREET": "KAYNAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "KARLIOVA",
        "STREET": "KAYNARPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "KARLIOVA",
        "STREET": "KAZANLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "KARLIOVA",
        "STREET": "KAŞIKÇI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "KARLIOVA",
        "STREET": "KIRAÇTEPE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "KARLIOVA",
        "STREET": "KIZILAĞAÇ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "KARLIOVA",
        "STREET": "KIZILÇUBUK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "KARLIOVA",
        "STREET": "KÜMBET KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "KARLIOVA",
        "STREET": "KÜRÜK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "KARLIOVA",
        "STREET": "MOLLAŞAKİR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "KARLIOVA",
        "STREET": "ORTAKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "KARLIOVA",
        "STREET": "SAKAÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "KARLIOVA",
        "STREET": "SARIKUŞAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "KARLIOVA",
        "STREET": "SERPMEKAYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "KARLIOVA",
        "STREET": "SEYRAN TEPE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "KARLIOVA",
        "STREET": "SOĞUKPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "KARLIOVA",
        "STREET": "SUDURAĞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "KARLIOVA",
        "STREET": "SUÇATI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "KARLIOVA",
        "STREET": "TAŞLIÇAY KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "KARLIOVA",
        "STREET": "TOKLULAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "KARLIOVA",
        "STREET": "TURGUT ÖZAL MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "KARLIOVA",
        "STREET": "TUZLUCA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "KARLIOVA",
        "STREET": "VİRANŞEHİR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "KARLIOVA",
        "STREET": "YENİKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "KARLIOVA",
        "STREET": "YEŞİLYURT MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "KARLIOVA",
        "STREET": "YONCALIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "KARLIOVA",
        "STREET": "YORGANÇAYIR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "KARLIOVA",
        "STREET": "YUKARIYAĞMURLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "KARLIOVA",
        "STREET": "YİĞİTLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "KARLIOVA",
        "STREET": "ÇATAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "KARLIOVA",
        "STREET": "ÇUKURTEPE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "KARLIOVA",
        "STREET": "ÇİFTLİKKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "KİĞI",
        "STREET": "DALLICA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "KİĞI",
        "STREET": "ESKİKAVAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "KİĞI",
        "STREET": "ESKİŞEHİR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "KİĞI",
        "STREET": "KADIKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "KİĞI",
        "STREET": "KUŞÇİMENİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "KİĞI",
        "STREET": "NACAKLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "KİĞI",
        "STREET": "SABIRTAŞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "KİĞI",
        "STREET": "SIRMAÇEK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "KİĞI",
        "STREET": "YAZGÜNÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "KİĞI",
        "STREET": "YENİŞEHİR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "KİĞI",
        "STREET": "YEŞİLYURT MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "KİĞI",
        "STREET": "ÇANAKÇI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "KİĞI",
        "STREET": "ÇİÇEKTEPE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "SOLHAN",
        "STREET": "ARAKONAK-KARŞIYAKA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "SOLHAN",
        "STREET": "ARAKONAK-KÜLTÜR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "SOLHAN",
        "STREET": "ARAKONAK-MERKEZ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "SOLHAN",
        "STREET": "ARSLANBEYLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "SOLHAN",
        "STREET": "ASMAKAYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "SOLHAN",
        "STREET": "BOZKANAT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "SOLHAN",
        "STREET": "BOĞLAN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "SOLHAN",
        "STREET": "DEMİRKAPI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "SOLHAN",
        "STREET": "DOĞUYELİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "SOLHAN",
        "STREET": "DÜZAĞAÇ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "SOLHAN",
        "STREET": "DİLEKTEPE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "SOLHAN",
        "STREET": "ELBAŞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "SOLHAN",
        "STREET": "ELMASIRTI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "SOLHAN",
        "STREET": "EŞMETAŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "SOLHAN",
        "STREET": "GELİNTEPE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "SOLHAN",
        "STREET": "GENÇTAVUS KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "SOLHAN",
        "STREET": "GÖKSU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "SOLHAN",
        "STREET": "HALİME PINAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "SOLHAN",
        "STREET": "HAZARŞAH KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "SOLHAN",
        "STREET": "KALE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "SOLHAN",
        "STREET": "KIRIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "SOLHAN",
        "STREET": "MURAT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "SOLHAN",
        "STREET": "MUTLUCA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "SOLHAN",
        "STREET": "OYMAPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "SOLHAN",
        "STREET": "SÜKYAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "SOLHAN",
        "STREET": "SÜLÜNKAŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "SOLHAN",
        "STREET": "TARHAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "SOLHAN",
        "STREET": "YENİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "SOLHAN",
        "STREET": "YENİBAŞAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "SOLHAN",
        "STREET": "YENİDAL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "SOLHAN",
        "STREET": "YEŞİLOVA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "SOLHAN",
        "STREET": "YİĞİTHARMAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "SOLHAN",
        "STREET": "İNANDIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "SOLHAN",
        "STREET": "ŞİMŞİRPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "YAYLADERE",
        "STREET": "AKÇADAMLAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "YAYLADERE",
        "STREET": "ALINYAZI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "YAYLADERE",
        "STREET": "AYDINLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "YAYLADERE",
        "STREET": "BATIAYAZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "YAYLADERE",
        "STREET": "BOĞAZKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "YAYLADERE",
        "STREET": "BİLEKKAYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "YAYLADERE",
        "STREET": "CONAG MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "YAYLADERE",
        "STREET": "DALBASAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "YAYLADERE",
        "STREET": "GÖKÇEDAL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "YAYLADERE",
        "STREET": "GÜNEŞLİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "YAYLADERE",
        "STREET": "GÜNLÜK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "YAYLADERE",
        "STREET": "HAKTANIR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "YAYLADERE",
        "STREET": "HASKÖY MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "YAYLADERE",
        "STREET": "KALKANLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "YAYLADERE",
        "STREET": "KIRKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "YAYLADERE",
        "STREET": "KORLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "YAYLADERE",
        "STREET": "MERKEZ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "YAYLADERE",
        "STREET": "SÜRMELİKOÇ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "YAYLADERE",
        "STREET": "YAVUZTAŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "YAYLADERE",
        "STREET": "YAYLABAĞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "YAYLADERE",
        "STREET": "YOLGÜDEN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "YAYLADERE",
        "STREET": "ZEYNELLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "YAYLADERE",
        "STREET": "ÇALIKAĞIL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "YAYLADERE",
        "STREET": "ÇATALKAYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "YAYLADERE",
        "STREET": "ÇAYAĞZI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "YAYLADERE",
        "STREET": "ÇİKAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "YEDİSU",
        "STREET": "AYANOĞLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "YEDİSU",
        "STREET": "DÖŞENGİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "YEDİSU",
        "STREET": "DİNARBEY KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "YEDİSU",
        "STREET": "ELMALI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "YEDİSU",
        "STREET": "ESKİBALTA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "YEDİSU",
        "STREET": "GELİNPERTEK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "YEDİSU",
        "STREET": "GÜZGÜLÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "YEDİSU",
        "STREET": "KABAOLUK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "YEDİSU",
        "STREET": "KABAYEL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "YEDİSU",
        "STREET": "KARAPOLAT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "YEDİSU",
        "STREET": "KAŞIKLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "YEDİSU",
        "STREET": "KOŞAN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "YEDİSU",
        "STREET": "MERKEZ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "YEDİSU",
        "STREET": "YAĞMURPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "YEDİSU",
        "STREET": "YENİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "YEDİSU",
        "STREET": "YEŞİLGÖL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİNGÖL",
        "DISTRICT": "YEDİSU",
        "STREET": "ŞENKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "ADİLCEVAZ",
        "STREET": "AKYAZI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "ADİLCEVAZ",
        "STREET": "AKÇIRA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "ADİLCEVAZ",
        "STREET": "ALACAATLI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "ADİLCEVAZ",
        "STREET": "ATATÜRK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "ADİLCEVAZ",
        "STREET": "AYDINLAR-ATATÜRK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "ADİLCEVAZ",
        "STREET": "AYDINLAR-BUDAKLI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "ADİLCEVAZ",
        "STREET": "AYDINLAR-ESENTEPE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "ADİLCEVAZ",
        "STREET": "AYDINLAR-GÜLTEPE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "ADİLCEVAZ",
        "STREET": "AYDINLAR-KIŞKILI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "ADİLCEVAZ",
        "STREET": "AYDINLAR-MENDERES MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "ADİLCEVAZ",
        "STREET": "AYDINLAR-İSMET PAŞA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "ADİLCEVAZ",
        "STREET": "AYGIRGÖLÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "ADİLCEVAZ",
        "STREET": "AŞAĞISÜPHAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "ADİLCEVAZ",
        "STREET": "BAHÇEDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "ADİLCEVAZ",
        "STREET": "CEVİZLİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "ADİLCEVAZ",
        "STREET": "CİHANGİR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "ADİLCEVAZ",
        "STREET": "DİZDAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "ADİLCEVAZ",
        "STREET": "ERİKBAĞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "ADİLCEVAZ",
        "STREET": "ESENKIYI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "ADİLCEVAZ",
        "STREET": "GÖLDÜZÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "ADİLCEVAZ",
        "STREET": "GÖLÜSTÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "ADİLCEVAZ",
        "STREET": "GÜMÜŞDÜVEN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "ADİLCEVAZ",
        "STREET": "HARMANTEPE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "ADİLCEVAZ",
        "STREET": "HEYBELİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "ADİLCEVAZ",
        "STREET": "HIDIRŞAH MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "ADİLCEVAZ",
        "STREET": "KALEBOYNU MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "ADİLCEVAZ",
        "STREET": "KARAKOL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "ADİLCEVAZ",
        "STREET": "KARAŞEYH KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "ADİLCEVAZ",
        "STREET": "KARŞIYAKA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "ADİLCEVAZ",
        "STREET": "KAVUŞTUK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "ADİLCEVAZ",
        "STREET": "KÖMÜRLÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "ADİLCEVAZ",
        "STREET": "MOLLAFADIL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "ADİLCEVAZ",
        "STREET": "ORTA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "ADİLCEVAZ",
        "STREET": "SEFASAHİL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "ADİLCEVAZ",
        "STREET": "SELÇUKLU MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "ADİLCEVAZ",
        "STREET": "YARIMADA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "ADİLCEVAZ",
        "STREET": "YILDIZKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "ADİLCEVAZ",
        "STREET": "YOLÇATI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "ADİLCEVAZ",
        "STREET": "YUKARISÜPHAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "ADİLCEVAZ",
        "STREET": "ÇANAKYAYLA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "ADİLCEVAZ",
        "STREET": "ÇAYIR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "ADİLCEVAZ",
        "STREET": "ÖRENTAŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "ADİLCEVAZ",
        "STREET": "İPEKÇAYIR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "AHLAT",
        "STREET": "AHLAT K-1 TİPİ KAPALI CEZAEVİ VE İNFAZ KURUMU",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "AHLAT",
        "STREET": "AKÇAÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "AHLAT",
        "STREET": "ALAKIR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "AHLAT",
        "STREET": "BAHÇE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "AHLAT",
        "STREET": "BURCUKAYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "AHLAT",
        "STREET": "CEMALETTİN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "AHLAT",
        "STREET": "DEVELİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "AHLAT",
        "STREET": "DİLBURNU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "AHLAT",
        "STREET": "ERKİZAN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "AHLAT",
        "STREET": "GÖLGÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "AHLAT",
        "STREET": "GÜZELSU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "AHLAT",
        "STREET": "HARABA ŞEHİR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "AHLAT",
        "STREET": "KACER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "AHLAT",
        "STREET": "KALE  MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "AHLAT",
        "STREET": "KINALIKOÇ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "AHLAT",
        "STREET": "KIRIKKAYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "AHLAT",
        "STREET": "KIRKDÖNÜM KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "AHLAT",
        "STREET": "KIRKLAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "AHLAT",
        "STREET": "KULAKSIZ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "AHLAT",
        "STREET": "KUŞHANE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "AHLAT",
        "STREET": "NAZİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "AHLAT",
        "STREET": "OTLUYAZI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "AHLAT",
        "STREET": "OVAKIŞLA-KURTULUŞ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "AHLAT",
        "STREET": "OVAKIŞLA-KÜLTÜR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "AHLAT",
        "STREET": "OVAKIŞLA-ORTA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "AHLAT",
        "STREET": "OVAKIŞLA-SARAY MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "AHLAT",
        "STREET": "OVAKIŞLA-YILDIZLAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "AHLAT",
        "STREET": "SAKA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "AHLAT",
        "STREET": "SELÇUKLU MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "AHLAT",
        "STREET": "SERİNBAYIR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "AHLAT",
        "STREET": "SEYRANTEPE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "AHLAT",
        "STREET": "SOĞANLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "AHLAT",
        "STREET": "TAHTI SÜLEYMAN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "AHLAT",
        "STREET": "TAŞHARMAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "AHLAT",
        "STREET": "TUNUS MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "AHLAT",
        "STREET": "ULUDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "AHLAT",
        "STREET": "YENİKÖPRÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "AHLAT",
        "STREET": "YOĞURTYEMEZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "AHLAT",
        "STREET": "YUVADAMI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "AHLAT",
        "STREET": "ÇATALAĞZI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "AHLAT",
        "STREET": "ÇUKURTARLA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "AHLAT",
        "STREET": "İKİ KUBBE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "MERKEZ",
        "STREET": "ALANİÇİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "MERKEZ",
        "STREET": "ARIDAĞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "MERKEZ",
        "STREET": "ATATÜRK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "MERKEZ",
        "STREET": "AYRANCILAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "MERKEZ",
        "STREET": "AĞAÇDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "MERKEZ",
        "STREET": "AĞAÇKÖPRÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "MERKEZ",
        "STREET": "AŞAĞIBALCILAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "MERKEZ",
        "STREET": "AŞAĞIKARABOY KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "MERKEZ",
        "STREET": "AŞAĞIÖLEK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "MERKEZ",
        "STREET": "BAYRAMALAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "MERKEZ",
        "STREET": "BAŞHAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "MERKEZ",
        "STREET": "BAŞMAKLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "MERKEZ",
        "STREET": "BEŞ MİNARE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "MERKEZ",
        "STREET": "BÖLÜKYAZI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "MERKEZ",
        "STREET": "BİTLİS E TİPİ KAPALI CEZA İNFAZ KURUMU",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "MERKEZ",
        "STREET": "CUMHURİYET KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "MERKEZ",
        "STREET": "DELİKTAŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "MERKEZ",
        "STREET": "DEREAĞZI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "MERKEZ",
        "STREET": "DEĞİRMENALTI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "MERKEZ",
        "STREET": "DOĞRUYOL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "MERKEZ",
        "STREET": "DÖRTAĞAÇ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "MERKEZ",
        "STREET": "DİREKTAŞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "MERKEZ",
        "STREET": "EKİNLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "MERKEZ",
        "STREET": "GAZİ BEY MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "MERKEZ",
        "STREET": "GEÇİTBAŞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "MERKEZ",
        "STREET": "HERSAN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "MERKEZ",
        "STREET": "HÜSREV PAŞA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "MERKEZ",
        "STREET": "ILICAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "MERKEZ",
        "STREET": "KARBASTI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "MERKEZ",
        "STREET": "KARINCA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "MERKEZ",
        "STREET": "KAVAKDİBİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "MERKEZ",
        "STREET": "KAYABAŞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "MERKEZ",
        "STREET": "KAYADAĞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "MERKEZ",
        "STREET": "KEKLİKDÜZÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "MERKEZ",
        "STREET": "KINALI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "MERKEZ",
        "STREET": "KOKARSU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "MERKEZ",
        "STREET": "KONALGA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "MERKEZ",
        "STREET": "KONUKSAYAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "MERKEZ",
        "STREET": "KORUK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "MERKEZ",
        "STREET": "KÖMÜRYAKAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "MERKEZ",
        "STREET": "KÜLLÜCE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "MERKEZ",
        "STREET": "KİREÇTAŞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "MERKEZ",
        "STREET": "MUŞTAKBABA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "MERKEZ",
        "STREET": "NARLIDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "MERKEZ",
        "STREET": "ORTAKAPI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "MERKEZ",
        "STREET": "OĞULCAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "MERKEZ",
        "STREET": "SARAY MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "MERKEZ",
        "STREET": "SARIKONAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "MERKEZ",
        "STREET": "SARPKAYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "MERKEZ",
        "STREET": "SEKİZ AĞUSTOS MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "MERKEZ",
        "STREET": "TABANÖZÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "MERKEZ",
        "STREET": "TANRIYAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "MERKEZ",
        "STREET": "TATLIKAYNAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "MERKEZ",
        "STREET": "TAŞ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "MERKEZ",
        "STREET": "TINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "MERKEZ",
        "STREET": "YANLIZÇAMLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "MERKEZ",
        "STREET": "YAYALAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "MERKEZ",
        "STREET": "YAYGIN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "MERKEZ",
        "STREET": "YEŞİLSIRT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "MERKEZ",
        "STREET": "YOLALAN-BOZTEPE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "MERKEZ",
        "STREET": "YOLALAN-DÜZ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "MERKEZ",
        "STREET": "YOLALAN-KALE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "MERKEZ",
        "STREET": "YOLALAN-KONAÇ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "MERKEZ",
        "STREET": "YOLALAN-MERKEZ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "MERKEZ",
        "STREET": "YOLALAN-PINARBAŞI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "MERKEZ",
        "STREET": "YOLALAN-YENİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "MERKEZ",
        "STREET": "YOLALAN-ÇINARBAŞI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "MERKEZ",
        "STREET": "YOLALAN-ÜÇ KARDEŞ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "MERKEZ",
        "STREET": "YOLAĞZI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "MERKEZ",
        "STREET": "YOLCULAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "MERKEZ",
        "STREET": "YOLYAZI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "MERKEZ",
        "STREET": "YUKARIBALCILAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "MERKEZ",
        "STREET": "YUKARIKARABOY KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "MERKEZ",
        "STREET": "YUKARIÖLEK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "MERKEZ",
        "STREET": "YUMURTATEPE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "MERKEZ",
        "STREET": "YUVACIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "MERKEZ",
        "STREET": "YÜCEBAŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "MERKEZ",
        "STREET": "YÜKSELİŞ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "MERKEZ",
        "STREET": "ZEYDAN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇALIDÜZÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇAYIRBAŞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇELTİKLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇOBANSUYU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "MERKEZ",
        "STREET": "ÜNALDI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "MERKEZ",
        "STREET": "ÜÇEVLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "MERKEZ",
        "STREET": "İNÖNÜ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "MERKEZ",
        "STREET": "İÇGEÇİT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "MERKEZ",
        "STREET": "İÇMELİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "MERKEZ",
        "STREET": "ŞEMSİ BİTLİS MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "GÜROYMAK",
        "STREET": "ARPACIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "GÜROYMAK",
        "STREET": "AŞAĞIKOLBAŞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "GÜROYMAK",
        "STREET": "BARAJYOLU MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "GÜROYMAK",
        "STREET": "BARIŞ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "GÜROYMAK",
        "STREET": "BAĞLAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "GÜROYMAK",
        "STREET": "BUDAKLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "GÜROYMAK",
        "STREET": "BÖLMEDERE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "GÜROYMAK",
        "STREET": "CEVİZYATAĞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "GÜROYMAK",
        "STREET": "DEĞİRMEN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "GÜROYMAK",
        "STREET": "ERENTEPE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "GÜROYMAK",
        "STREET": "GEDİKPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "GÜROYMAK",
        "STREET": "GÖLBAŞI-GÜLTEPE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "GÜROYMAK",
        "STREET": "GÖLBAŞI-KÖŞK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "GÜROYMAK",
        "STREET": "GÖLBAŞI-YENİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "GÜROYMAK",
        "STREET": "GÖLBAŞI-YEŞİLTEPE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "GÜROYMAK",
        "STREET": "GÖLBAŞI-ŞENKAYA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "GÜROYMAK",
        "STREET": "GÖLBAŞI-ŞENYAYLA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "GÜROYMAK",
        "STREET": "GÜNKIRI-CAMİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "GÜROYMAK",
        "STREET": "GÜNKIRI-CUMHURİYET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "GÜROYMAK",
        "STREET": "GÜNKIRI-HÜRRİYET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "GÜROYMAK",
        "STREET": "GÜNKIRI-PINARBAŞI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "GÜROYMAK",
        "STREET": "GÜNKIRI-REFAHİYE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "GÜROYMAK",
        "STREET": "GÜNKIRI-YEŞİLYURT MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "GÜROYMAK",
        "STREET": "GÜNKIRI-ŞENYURT MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "GÜROYMAK",
        "STREET": "GÜZELLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "GÜROYMAK",
        "STREET": "GİRNE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "GÜROYMAK",
        "STREET": "HAYDAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "GÜROYMAK",
        "STREET": "KALELİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "GÜROYMAK",
        "STREET": "KAVUNLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "GÜROYMAK",
        "STREET": "KEKLİKTEPE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "GÜROYMAK",
        "STREET": "KUŞTAŞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "GÜROYMAK",
        "STREET": "KÜMBET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "GÜROYMAK",
        "STREET": "NURTEPE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "GÜROYMAK",
        "STREET": "ODUNCULAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "GÜROYMAK",
        "STREET": "SAKLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "GÜROYMAK",
        "STREET": "SÜTDERESİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "GÜROYMAK",
        "STREET": "TAHTALI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "GÜROYMAK",
        "STREET": "TAŞÜSTÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "GÜROYMAK",
        "STREET": "UĞURLU MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "GÜROYMAK",
        "STREET": "YAMAÇKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "GÜROYMAK",
        "STREET": "YAYLADERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "GÜROYMAK",
        "STREET": "YAZIKONAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "GÜROYMAK",
        "STREET": "YEMİŞVEREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "GÜROYMAK",
        "STREET": "YEŞİLOVA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "GÜROYMAK",
        "STREET": "YUKARIKOLBAŞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "GÜROYMAK",
        "STREET": "ÇALLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "GÜROYMAK",
        "STREET": "ÇAYARASI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "GÜROYMAK",
        "STREET": "ÇITAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "GÜROYMAK",
        "STREET": "ÖZKAVAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "GÜROYMAK",
        "STREET": "ÜZÜMVEREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "GÜROYMAK",
        "STREET": "ŞENEVLER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "GÜROYMAK",
        "STREET": "ŞENOVA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "GÜROYMAK",
        "STREET": "ŞENTEPE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "GÜROYMAK",
        "STREET": "ŞİRİNTEPE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "HİZAN",
        "STREET": "AKBIYIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "HİZAN",
        "STREET": "AKDİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "HİZAN",
        "STREET": "AKÇA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "HİZAN",
        "STREET": "AKÇEVRE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "HİZAN",
        "STREET": "AKŞAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "HİZAN",
        "STREET": "ALADANA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "HİZAN",
        "STREET": "ALTINOLUK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "HİZAN",
        "STREET": "AĞILÖZÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "HİZAN",
        "STREET": "AŞAĞIKARASU MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "HİZAN",
        "STREET": "BAHÇELİEVLER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "HİZAN",
        "STREET": "BALLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "HİZAN",
        "STREET": "BALLICA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "HİZAN",
        "STREET": "BOZPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "HİZAN",
        "STREET": "BUDAKLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "HİZAN",
        "STREET": "BÖLÜKLÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "HİZAN",
        "STREET": "DAYILAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "HİZAN",
        "STREET": "DAĞÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "HİZAN",
        "STREET": "DERİNCE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "HİZAN",
        "STREET": "DOĞANCI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "HİZAN",
        "STREET": "DÖKÜKTAŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "HİZAN",
        "STREET": "DİBEKLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "HİZAN",
        "STREET": "EKİNLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "HİZAN",
        "STREET": "EKİNTEPE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "HİZAN",
        "STREET": "ELMACIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "HİZAN",
        "STREET": "ERENCİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "HİZAN",
        "STREET": "ESENLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "HİZAN",
        "STREET": "ESENTEPE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "HİZAN",
        "STREET": "GAYDA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "HİZAN",
        "STREET": "GÖKAY KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "HİZAN",
        "STREET": "GÖKTEPE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "HİZAN",
        "STREET": "GÖKÇİMEN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "HİZAN",
        "STREET": "GÖNÜLLÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "HİZAN",
        "STREET": "GÜNGÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "HİZAN",
        "STREET": "GÜRECE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "HİZAN",
        "STREET": "HACIMEHMET KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "HİZAN",
        "STREET": "HARMANDÖVEN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "HİZAN",
        "STREET": "HOROZDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "HİZAN",
        "STREET": "KALKANLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "HİZAN",
        "STREET": "KAPISUYU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "HİZAN",
        "STREET": "KARAAĞAÇ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "HİZAN",
        "STREET": "KARBASTI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "HİZAN",
        "STREET": "KARLITEPE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "HİZAN",
        "STREET": "KAYADELER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "HİZAN",
        "STREET": "KAYALI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "HİZAN",
        "STREET": "KAYMAKLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "HİZAN",
        "STREET": "KEKLİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "HİZAN",
        "STREET": "KEÇELİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "HİZAN",
        "STREET": "KOLLUDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "HİZAN",
        "STREET": "KOYUNLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "HİZAN",
        "STREET": "KOÇLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "HİZAN",
        "STREET": "KOÇYİĞİT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "HİZAN",
        "STREET": "KÜLTÜR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "HİZAN",
        "STREET": "MEYDAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "HİZAN",
        "STREET": "NUH KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "HİZAN",
        "STREET": "NURS KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "HİZAN",
        "STREET": "ORTACA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "HİZAN",
        "STREET": "OTLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "HİZAN",
        "STREET": "OYMAPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "HİZAN",
        "STREET": "OĞLAKLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "HİZAN",
        "STREET": "SARITAŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "HİZAN",
        "STREET": "SARPKAYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "HİZAN",
        "STREET": "SAĞINLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "HİZAN",
        "STREET": "SAĞIRKAYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "HİZAN",
        "STREET": "SOĞUKSU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "HİZAN",
        "STREET": "SÜRÜCÜLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "HİZAN",
        "STREET": "SÜTTAŞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "HİZAN",
        "STREET": "TAŞLI PINAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "HİZAN",
        "STREET": "TUTUMLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "HİZAN",
        "STREET": "UZUNTAŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "HİZAN",
        "STREET": "YAYLACIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "HİZAN",
        "STREET": "YELKIRAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "HİZAN",
        "STREET": "YEMİŞLİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "HİZAN",
        "STREET": "YENİ KENT MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "HİZAN",
        "STREET": "YENİCİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "HİZAN",
        "STREET": "YEŞİL OVA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "HİZAN",
        "STREET": "YIĞINKAYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "HİZAN",
        "STREET": "YOLBİLEN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "HİZAN",
        "STREET": "YOĞURTLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "HİZAN",
        "STREET": "YUKARIAYVACIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "HİZAN",
        "STREET": "YUKARIÇALI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "HİZAN",
        "STREET": "ÇALIŞKANLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "HİZAN",
        "STREET": "ÇATAKDEĞİRMEN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "HİZAN",
        "STREET": "ÇAVUŞLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "HİZAN",
        "STREET": "ÇAYIR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "HİZAN",
        "STREET": "ÇÖKEKYAZI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "HİZAN",
        "STREET": "ÖRGÜLÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "HİZAN",
        "STREET": "ÜRÜNVEREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "HİZAN",
        "STREET": "İNCİRLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "HİZAN",
        "STREET": "İÇLİKAVAL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "MUTKİ",
        "STREET": "AKINCI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "MUTKİ",
        "STREET": "AKPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "MUTKİ",
        "STREET": "AKÇAAĞAÇ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "MUTKİ",
        "STREET": "ALATOPRAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "MUTKİ",
        "STREET": "ALICIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "MUTKİ",
        "STREET": "ALKOYUN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "MUTKİ",
        "STREET": "AYDEMİR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "MUTKİ",
        "STREET": "AÇIKALAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "MUTKİ",
        "STREET": "BALLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "MUTKİ",
        "STREET": "BAĞARASI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "MUTKİ",
        "STREET": "BEŞEVLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "MUTKİ",
        "STREET": "BOZBURUN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "MUTKİ",
        "STREET": "BOĞAZÖNÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "MUTKİ",
        "STREET": "DAĞARCIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "MUTKİ",
        "STREET": "DAĞLIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "MUTKİ",
        "STREET": "DEREYOLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "MUTKİ",
        "STREET": "EKİZLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "MUTKİ",
        "STREET": "ERLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "MUTKİ",
        "STREET": "GEYİKPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "MUTKİ",
        "STREET": "GÖZTEPE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "MUTKİ",
        "STREET": "GÜMÜŞKANAT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "MUTKİ",
        "STREET": "KAPAKLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "MUTKİ",
        "STREET": "KAPIKAYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "MUTKİ",
        "STREET": "KARABUDAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "MUTKİ",
        "STREET": "KAVAKBAŞI-ARPALISEKİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "MUTKİ",
        "STREET": "KAVAKBAŞI-CAMİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "MUTKİ",
        "STREET": "KAVAKBAŞI-GÖLBAŞI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "MUTKİ",
        "STREET": "KAVAKBAŞI-ORTA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "MUTKİ",
        "STREET": "KAVAKBAŞI-YENİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "MUTKİ",
        "STREET": "KAVAKBAŞI-YÜKSEKOVA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "MUTKİ",
        "STREET": "KAVAKBAŞI-ÇİMEN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "MUTKİ",
        "STREET": "KAYABAŞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "MUTKİ",
        "STREET": "KAYRAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "MUTKİ",
        "STREET": "KAŞAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "MUTKİ",
        "STREET": "KAŞIKLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "MUTKİ",
        "STREET": "KOCAİNİŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "MUTKİ",
        "STREET": "KOVANLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "MUTKİ",
        "STREET": "KOYUNLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "MUTKİ",
        "STREET": "KUŞDİLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "MUTKİ",
        "STREET": "KÜLLÜCE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "MUTKİ",
        "STREET": "MEYDAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "MUTKİ",
        "STREET": "OLUKLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "MUTKİ",
        "STREET": "PINARBAŞI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "MUTKİ",
        "STREET": "SALMAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "MUTKİ",
        "STREET": "SARIÇİÇEK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "MUTKİ",
        "STREET": "SEKİLİYAZI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "MUTKİ",
        "STREET": "TAŞBOĞAZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "MUTKİ",
        "STREET": "TAŞLI PINAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "MUTKİ",
        "STREET": "TOLGALI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "MUTKİ",
        "STREET": "URAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "MUTKİ",
        "STREET": "UZUNYAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "MUTKİ",
        "STREET": "YALINTAŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "MUTKİ",
        "STREET": "YANIKÇAKIR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "MUTKİ",
        "STREET": "YAZICIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "MUTKİ",
        "STREET": "YENİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "MUTKİ",
        "STREET": "YENİBAĞ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "MUTKİ",
        "STREET": "YENİDOĞAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "MUTKİ",
        "STREET": "YENİKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "MUTKİ",
        "STREET": "YUMRUMEŞE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "MUTKİ",
        "STREET": "YUVALIDAM KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "MUTKİ",
        "STREET": "ÇATALERİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "MUTKİ",
        "STREET": "ÇATALSÖĞÜT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "MUTKİ",
        "STREET": "ÇAYGEÇİT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "MUTKİ",
        "STREET": "ÇAYIRLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "MUTKİ",
        "STREET": "ÇIĞIR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "MUTKİ",
        "STREET": "ÇİTLİYOL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "MUTKİ",
        "STREET": "ÇİĞDEMALAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "MUTKİ",
        "STREET": "ÖZENLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "MUTKİ",
        "STREET": "ÜSTYAYLA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "MUTKİ",
        "STREET": "ÜÇADIM KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "MUTKİ",
        "STREET": "İKİZLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "TATVAN",
        "STREET": "ADABAĞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "TATVAN",
        "STREET": "ALACABÜK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "TATVAN",
        "STREET": "AYDINLAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "TATVAN",
        "STREET": "BAHÇELİEVLER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "TATVAN",
        "STREET": "BENEKLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "TATVAN",
        "STREET": "BOLALAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "TATVAN",
        "STREET": "BUDAKLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "TATVAN",
        "STREET": "CUMHURİYET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "TATVAN",
        "STREET": "DALDA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "TATVAN",
        "STREET": "DAĞDİBİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "TATVAN",
        "STREET": "DUMLUPINAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "TATVAN",
        "STREET": "DÖNERTAŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "TATVAN",
        "STREET": "DÜZCEALAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "TATVAN",
        "STREET": "DİBEKLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "TATVAN",
        "STREET": "ESENTEPE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "TATVAN",
        "STREET": "EĞRİTAŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "TATVAN",
        "STREET": "FATİH MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "TATVAN",
        "STREET": "FUAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "TATVAN",
        "STREET": "GÖLLÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "TATVAN",
        "STREET": "GÜNTEPE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "TATVAN",
        "STREET": "GÜREŞÇİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "TATVAN",
        "STREET": "HANELMALI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "TATVAN",
        "STREET": "HARMANLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "TATVAN",
        "STREET": "HİDAYET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "TATVAN",
        "STREET": "KALE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "TATVAN",
        "STREET": "KARATAŞ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "TATVAN",
        "STREET": "KARŞIYAKA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "TATVAN",
        "STREET": "KIRKBULAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "TATVAN",
        "STREET": "KISIKLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "TATVAN",
        "STREET": "KIYIDÜZÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "TATVAN",
        "STREET": "KOLBAŞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "TATVAN",
        "STREET": "KORUKLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "TATVAN",
        "STREET": "KOYLUCA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "TATVAN",
        "STREET": "KOYUNPINARI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "TATVAN",
        "STREET": "KURUYAKA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "TATVAN",
        "STREET": "KUŞLUCA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "TATVAN",
        "STREET": "KÖPRÜCÜK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "TATVAN",
        "STREET": "KÜLTÜR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "TATVAN",
        "STREET": "KÜÇÜKSU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "TATVAN",
        "STREET": "NOHUTLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "TATVAN",
        "STREET": "OBUZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "TATVAN",
        "STREET": "PINARBAŞI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "TATVAN",
        "STREET": "SAHİL MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "TATVAN",
        "STREET": "SARAY MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "TATVAN",
        "STREET": "SARIKUM KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "TATVAN",
        "STREET": "SOSYAL MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "TATVAN",
        "STREET": "TOKAÇLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "TATVAN",
        "STREET": "TOSUNLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "TATVAN",
        "STREET": "TUĞ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "TATVAN",
        "STREET": "ULUSOY KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "TATVAN",
        "STREET": "UNCULAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "TATVAN",
        "STREET": "USLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "TATVAN",
        "STREET": "YASSICA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "TATVAN",
        "STREET": "YEDİVEREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "TATVAN",
        "STREET": "YELKENLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "TATVAN",
        "STREET": "YENİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "TATVAN",
        "STREET": "YEŞİLMEZRA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "TATVAN",
        "STREET": "YONCABAŞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "TATVAN",
        "STREET": "YUMURTATEPE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "TATVAN",
        "STREET": "ÇALIDÜZÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "TATVAN",
        "STREET": "ÇANAKDÜZÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "TATVAN",
        "STREET": "ÇAĞLAYAN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "TATVAN",
        "STREET": "ÇEKMECE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "TATVAN",
        "STREET": "ÖRENLİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "TATVAN",
        "STREET": "İŞLETME MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "BİTLİS",
        "DISTRICT": "TATVAN",
        "STREET": "ŞİRİNEVLER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "ACIPAYAM",
        "STREET": "AKALAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "ACIPAYAM",
        "STREET": "AKŞAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "ACIPAYAM",
        "STREET": "ALAATTİN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "ACIPAYAM",
        "STREET": "ALCI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "ACIPAYAM",
        "STREET": "ALİVEREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "ACIPAYAM",
        "STREET": "APA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "ACIPAYAM",
        "STREET": "AVŞAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "ACIPAYAM",
        "STREET": "AŞAĞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "ACIPAYAM",
        "STREET": "BADEMLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "ACIPAYAM",
        "STREET": "BEDİRBEY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "ACIPAYAM",
        "STREET": "BENLİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "ACIPAYAM",
        "STREET": "BOĞAZDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "ACIPAYAM",
        "STREET": "CORUM MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "ACIPAYAM",
        "STREET": "DARIVEREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "ACIPAYAM",
        "STREET": "DEDEBAĞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "ACIPAYAM",
        "STREET": "DODURGA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "ACIPAYAM",
        "STREET": "ESKİKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "ACIPAYAM",
        "STREET": "GEDİKLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "ACIPAYAM",
        "STREET": "GÖLCÜK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "ACIPAYAM",
        "STREET": "GÜMÜŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "ACIPAYAM",
        "STREET": "GÜNEY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "ACIPAYAM",
        "STREET": "HACIKURTLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "ACIPAYAM",
        "STREET": "HİSAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "ACIPAYAM",
        "STREET": "KARAHÜYÜK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "ACIPAYAM",
        "STREET": "KARAİSMAİLLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "ACIPAYAM",
        "STREET": "KELEKÇİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "ACIPAYAM",
        "STREET": "KIRCA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "ACIPAYAM",
        "STREET": "KUMAFŞARI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "ACIPAYAM",
        "STREET": "KURTLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "ACIPAYAM",
        "STREET": "KUYUCAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "ACIPAYAM",
        "STREET": "KUZÖREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "ACIPAYAM",
        "STREET": "KÖKE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "ACIPAYAM",
        "STREET": "MEVLÜTLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "ACIPAYAM",
        "STREET": "OLUKBAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "ACIPAYAM",
        "STREET": "OVAYURT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "ACIPAYAM",
        "STREET": "OĞUZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "ACIPAYAM",
        "STREET": "PINARBAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "ACIPAYAM",
        "STREET": "PINARYAZI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "ACIPAYAM",
        "STREET": "SANDALCIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "ACIPAYAM",
        "STREET": "SIRÇALIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "ACIPAYAM",
        "STREET": "SUÇATI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "ACIPAYAM",
        "STREET": "UCARI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "ACIPAYAM",
        "STREET": "YASSIHÜYÜK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "ACIPAYAM",
        "STREET": "YAZIR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "ACIPAYAM",
        "STREET": "YENİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "ACIPAYAM",
        "STREET": "YENİKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "ACIPAYAM",
        "STREET": "YEŞİLDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "ACIPAYAM",
        "STREET": "YEŞİLYUVA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "ACIPAYAM",
        "STREET": "YOLÇATI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "ACIPAYAM",
        "STREET": "YUKARI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "ACIPAYAM",
        "STREET": "YUMRUTAŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "ACIPAYAM",
        "STREET": "ÇAKIR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "ACIPAYAM",
        "STREET": "ÇAMLIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "ACIPAYAM",
        "STREET": "ÇUBUKÇULAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "ACIPAYAM",
        "STREET": "ÇİFTLİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "ACIPAYAM",
        "STREET": "ÖREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "BABADAĞ",
        "STREET": "AHILLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "BABADAĞ",
        "STREET": "BEKİRLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "BABADAĞ",
        "STREET": "CUMHURİYET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "BABADAĞ",
        "STREET": "DEMİRLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "BABADAĞ",
        "STREET": "GAZİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "BABADAĞ",
        "STREET": "GÜNDOĞDU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "BABADAĞ",
        "STREET": "KELLECİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "BABADAĞ",
        "STREET": "KIRANYER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "BABADAĞ",
        "STREET": "MOLLAAHMET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "BABADAĞ",
        "STREET": "OĞUZLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "BABADAĞ",
        "STREET": "YENİKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "BABADAĞ",
        "STREET": "İNCİLİPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "BAKLAN",
        "STREET": "BALCA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "BAKLAN",
        "STREET": "BEYELLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "BAKLAN",
        "STREET": "BOĞAZİÇİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "BAKLAN",
        "STREET": "DAĞAL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "BAKLAN",
        "STREET": "GÖKPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "BAKLAN",
        "STREET": "GÜRLÜK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "BAKLAN",
        "STREET": "HADİM MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "BAKLAN",
        "STREET": "HÜSAMETTİN DEDE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "BAKLAN",
        "STREET": "KAVAKLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "BAKLAN",
        "STREET": "KONAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "BAKLAN",
        "STREET": "KİRAZLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "BAKLAN",
        "STREET": "ÇATALOBA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "BAKLAN",
        "STREET": "İCİKLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "BAKLAN",
        "STREET": "ŞENYAYLA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "BEKİLLİ",
        "STREET": "BAHÇELİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "BEKİLLİ",
        "STREET": "BÜKRÜCE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "BEKİLLİ",
        "STREET": "DEŞDEMİR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "BEKİLLİ",
        "STREET": "EKİZBABA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "BEKİLLİ",
        "STREET": "GÖMCE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "BEKİLLİ",
        "STREET": "KUTLUBEY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "BEKİLLİ",
        "STREET": "KÖSELLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "BEKİLLİ",
        "STREET": "POYRAZLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "BEKİLLİ",
        "STREET": "SIRIKLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "BEKİLLİ",
        "STREET": "YAHYALAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "BEKİLLİ",
        "STREET": "YENİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "BEKİLLİ",
        "STREET": "YEŞİLOBA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "BEKİLLİ",
        "STREET": "ÇAMKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "BEKİLLİ",
        "STREET": "ÇOĞAŞLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "BEKİLLİ",
        "STREET": "ÜÇKUYU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "BEYAĞAÇ",
        "STREET": "CUMHURİYET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "BEYAĞAÇ",
        "STREET": "EŞENLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "BEYAĞAÇ",
        "STREET": "FATİH MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "BEYAĞAÇ",
        "STREET": "GERİÇAM MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "BEYAĞAÇ",
        "STREET": "HÜRRİYET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "BEYAĞAÇ",
        "STREET": "KAPUZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "BEYAĞAÇ",
        "STREET": "KIZILCAAĞAÇ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "BEYAĞAÇ",
        "STREET": "PINARÖNÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "BEYAĞAÇ",
        "STREET": "SAZAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "BEYAĞAÇ",
        "STREET": "SUBAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "BEYAĞAÇ",
        "STREET": "UZUNOLUK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "BEYAĞAÇ",
        "STREET": "YENİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "BEYAĞAÇ",
        "STREET": "YENİÇEŞME MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "BEYAĞAÇ",
        "STREET": "ZAFER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "BEYAĞAÇ",
        "STREET": "ÇAMLIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "BOZKURT",
        "STREET": "ALİKURT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "BOZKURT",
        "STREET": "ARMUTALANI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "BOZKURT",
        "STREET": "AVDAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "BOZKURT",
        "STREET": "BAKLANKUYUCAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "BOZKURT",
        "STREET": "BARBAROS MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "BOZKURT",
        "STREET": "BAŞÇEŞME MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "BOZKURT",
        "STREET": "CUMALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "BOZKURT",
        "STREET": "FATİH MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "BOZKURT",
        "STREET": "HAMDİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "BOZKURT",
        "STREET": "HAYRETTİNKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "BOZKURT",
        "STREET": "MAHMUDİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "BOZKURT",
        "STREET": "MECİDİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "BOZKURT",
        "STREET": "MEHMETÇİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "BOZKURT",
        "STREET": "MİMAR SİNAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "BOZKURT",
        "STREET": "SAZKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "BOZKURT",
        "STREET": "TUTLUCA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "BOZKURT",
        "STREET": "YENİBAĞLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "BOZKURT",
        "STREET": "ÇAMBAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "BOZKURT",
        "STREET": "İNCELER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "BOZKURT",
        "STREET": "İNCELERTEKKESİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "BULDAN",
        "STREET": "AKTAŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "BULDAN",
        "STREET": "ALACAOĞLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "BULDAN",
        "STREET": "ALANDIZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "BULDAN",
        "STREET": "BEYLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "BULDAN",
        "STREET": "BOSTANYERİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "BULDAN",
        "STREET": "BOZALAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "BULDAN",
        "STREET": "BOĞAZÇİFTLİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "BULDAN",
        "STREET": "BURSA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "BULDAN",
        "STREET": "BÖLMEKAYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "BULDAN",
        "STREET": "CUMHURİYET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "BULDAN",
        "STREET": "DERBENT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "BULDAN",
        "STREET": "DIMBAZLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "BULDAN",
        "STREET": "DOĞAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "BULDAN",
        "STREET": "DÖRT EYLÜL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "BULDAN",
        "STREET": "DÜZALAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "BULDAN",
        "STREET": "GÖLBAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "BULDAN",
        "STREET": "GÜLALAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "BULDAN",
        "STREET": "GÜROLUK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "BULDAN",
        "STREET": "GİRNE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "BULDAN",
        "STREET": "HASANBEYLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "BULDAN",
        "STREET": "HELVACILAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "BULDAN",
        "STREET": "KADIKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "BULDAN",
        "STREET": "KARAKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "BULDAN",
        "STREET": "KARŞIYAKA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "BULDAN",
        "STREET": "KAŞIKCI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "BULDAN",
        "STREET": "KIRANDAMI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "BULDAN",
        "STREET": "KOVANOLUK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "BULDAN",
        "STREET": "KURTULUŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "BULDAN",
        "STREET": "KURUDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "BULDAN",
        "STREET": "MAHMUTLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "BULDAN",
        "STREET": "OĞUZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "BULDAN",
        "STREET": "SARIMAHMUTLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "BULDAN",
        "STREET": "SÜLEYMANLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "BULDAN",
        "STREET": "TURAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "BULDAN",
        "STREET": "TÜRLÜBEY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "BULDAN",
        "STREET": "YALÇINKAYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "BULDAN",
        "STREET": "YAYLA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "BULDAN",
        "STREET": "YENİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "BULDAN",
        "STREET": "YENİCEKENT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "BULDAN",
        "STREET": "YENİÇAM MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "BULDAN",
        "STREET": "YEŞİL DERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "BULDAN",
        "STREET": "ÇAMKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "BULDAN",
        "STREET": "ÇARŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "BULDAN",
        "STREET": "ÇATAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "BULDAN",
        "STREET": "ÇAYBAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "GÜNEY",
        "STREET": "ADIGÜZELLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "GÜNEY",
        "STREET": "AYDOĞDU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "GÜNEY",
        "STREET": "AŞAĞIÇEŞME MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "GÜNEY",
        "STREET": "CİNDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "GÜNEY",
        "STREET": "DOĞANLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "GÜNEY",
        "STREET": "ERTUĞRUL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "GÜNEY",
        "STREET": "EZİLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "GÜNEY",
        "STREET": "FATİH MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "GÜNEY",
        "STREET": "HAMİDİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "GÜNEY",
        "STREET": "HAYLAMAZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "GÜNEY",
        "STREET": "KARAAĞAÇLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "GÜNEY",
        "STREET": "KARAGÖZLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "GÜNEY",
        "STREET": "KERİMLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "GÜNEY",
        "STREET": "KOPARAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "GÜNEY",
        "STREET": "ORTA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "GÜNEY",
        "STREET": "ORTAÇEŞME MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "GÜNEY",
        "STREET": "PARMAKSIZLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "GÜNEY",
        "STREET": "TİLKİLİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "GÜNEY",
        "STREET": "YAĞCILAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "GÜNEY",
        "STREET": "YENİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "GÜNEY",
        "STREET": "YENİKONAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "GÜNEY",
        "STREET": "ÇAMRAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "GÜNEY",
        "STREET": "ÇORBACILAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "GÜNEY",
        "STREET": "ÜÇ EYLÜL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "HONAZ",
        "STREET": "AFŞIN BEY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "HONAZ",
        "STREET": "AKBAŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "HONAZ",
        "STREET": "AYDINLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "HONAZ",
        "STREET": "AŞAĞIDAĞDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "HONAZ",
        "STREET": "CUMHURİYET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "HONAZ",
        "STREET": "DENİZLİ D TİPİ KAPALI CEZA İNFAZ KURUMU",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "HONAZ",
        "STREET": "DENİZLİ T TİPİ KAPALI CEZA İNFAZ KURUMU",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "HONAZ",
        "STREET": "DEREÇİFTLİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "HONAZ",
        "STREET": "EMİRAZİZLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "HONAZ",
        "STREET": "GÜRLEYİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "HONAZ",
        "STREET": "HAYDAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "HONAZ",
        "STREET": "HÜRRİYET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "HONAZ",
        "STREET": "HİSAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "HONAZ",
        "STREET": "KAKLIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "HONAZ",
        "STREET": "KARATEKE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "HONAZ",
        "STREET": "KARAÇAY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "HONAZ",
        "STREET": "KIZILYER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "HONAZ",
        "STREET": "KOCABAŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "HONAZ",
        "STREET": "MENTEŞE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "HONAZ",
        "STREET": "OVACIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "HONAZ",
        "STREET": "SAPACA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "HONAZ",
        "STREET": "YENİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "HONAZ",
        "STREET": "YOKUŞBAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "HONAZ",
        "STREET": "YUKARIDAĞDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "KALE",
        "STREET": "ADAMHARMANI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "KALE",
        "STREET": "ALANYURT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "KALE",
        "STREET": "BELENKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "KALE",
        "STREET": "CEVHERPAŞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "KALE",
        "STREET": "CUMHURİYET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "KALE",
        "STREET": "DEMİRCİLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "KALE",
        "STREET": "DOĞANKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "KALE",
        "STREET": "ESENKAYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "KALE",
        "STREET": "GÖKÇEÖREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "KALE",
        "STREET": "GÖLBAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "KALE",
        "STREET": "GÜLBAĞLIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "KALE",
        "STREET": "HABİPLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "KALE",
        "STREET": "HÜRRİYET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "KALE",
        "STREET": "KARAKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "KALE",
        "STREET": "KARAYAYLA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "KALE",
        "STREET": "KAYABAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "KALE",
        "STREET": "KIRKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "KALE",
        "STREET": "KOÇARBOĞAZI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "KALE",
        "STREET": "KÖPRÜBAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "KALE",
        "STREET": "KÜNAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "KALE",
        "STREET": "MUSLUGÜME MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "KALE",
        "STREET": "NARLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "KALE",
        "STREET": "ORTAKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "KALE",
        "STREET": "ORTATEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "KALE",
        "STREET": "TOKİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "KALE",
        "STREET": "ULUÇAM MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "KALE",
        "STREET": "YENİDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "KALE",
        "STREET": "YENİKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "KALE",
        "STREET": "ÇAKIRBAĞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "KALE",
        "STREET": "ÇAMLARCA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "KALE",
        "STREET": "ÖZLÜCE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "KALE",
        "STREET": "İNCEĞİZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "MERKEZ",
        "STREET": "1200 EVLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "MERKEZ",
        "STREET": "ADALET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "MERKEZ",
        "STREET": "AKKONAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "MERKEZ",
        "STREET": "AKÇEŞME MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "MERKEZ",
        "STREET": "ALPASLAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "MERKEZ",
        "STREET": "ALTINDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "MERKEZ",
        "STREET": "ALTINTOP MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "MERKEZ",
        "STREET": "AŞAĞIŞAMLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "MERKEZ",
        "STREET": "BAHÇELİEVLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "MERKEZ",
        "STREET": "BARBAROS MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "MERKEZ",
        "STREET": "BARUTÇULAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "MERKEZ",
        "STREET": "BAŞKARCI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "MERKEZ",
        "STREET": "BEREKETLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "MERKEZ",
        "STREET": "BOZBURUN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "MERKEZ",
        "STREET": "DEĞİRMENÖNÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "MERKEZ",
        "STREET": "ESKİHİSAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "MERKEZ",
        "STREET": "GERZELE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "MERKEZ",
        "STREET": "GÖVEÇLİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "MERKEZ",
        "STREET": "GÜLTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "MERKEZ",
        "STREET": "GÜMÜŞÇAY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "MERKEZ",
        "STREET": "HACIEYÜPLÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "MERKEZ",
        "STREET": "HALLAÇLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "MERKEZ",
        "STREET": "HİSAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "MERKEZ",
        "STREET": "KADILAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "MERKEZ",
        "STREET": "KARAHASANLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "MERKEZ",
        "STREET": "KARAMAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "MERKEZ",
        "STREET": "KAYALAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "MERKEZ",
        "STREET": "KUMKISIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "MERKEZ",
        "STREET": "MEHMET AKİF ERSOY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "MERKEZ",
        "STREET": "MERKEZ EFENDI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "MERKEZ",
        "STREET": "MURATDEDE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "MERKEZ",
        "STREET": "SALİHAĞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "MERKEZ",
        "STREET": "SARAYLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "MERKEZ",
        "STREET": "SARUHAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "MERKEZ",
        "STREET": "SELÇUK BEY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "MERKEZ",
        "STREET": "SERVERGAZİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "MERKEZ",
        "STREET": "SEVİNDİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "MERKEZ",
        "STREET": "SIRAKAPILAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "MERKEZ",
        "STREET": "SÜMER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "MERKEZ",
        "STREET": "YENİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "MERKEZ",
        "STREET": "YENİŞAFAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "MERKEZ",
        "STREET": "YENİŞEHİR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "MERKEZ",
        "STREET": "YEŞİLYAYLA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "MERKEZ",
        "STREET": "ZAFER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇAKMAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇELTİKÇİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "MERKEZ",
        "STREET": "ÜZERLİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "MERKEZ",
        "STREET": "İLBADE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "MERKEZ",
        "STREET": "ŞEMİKLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "MERKEZ",
        "STREET": "ŞİRİNKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "PAMUKKALE",
        "STREET": "15 MAYIS MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "PAMUKKALE",
        "STREET": "AKDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "PAMUKKALE",
        "STREET": "AKHAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "PAMUKKALE",
        "STREET": "AKKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "PAMUKKALE",
        "STREET": "AKTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "PAMUKKALE",
        "STREET": "AKÇAPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "PAMUKKALE",
        "STREET": "ANAFARTALAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "PAMUKKALE",
        "STREET": "ASMALIEVLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "PAMUKKALE",
        "STREET": "ATALAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "PAMUKKALE",
        "STREET": "BAĞBAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "PAMUKKALE",
        "STREET": "BELENARDIÇ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "PAMUKKALE",
        "STREET": "CANKURTARAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "PAMUKKALE",
        "STREET": "CUMHURİYET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "PAMUKKALE",
        "STREET": "DELİKTAŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "PAMUKKALE",
        "STREET": "DEVELİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "PAMUKKALE",
        "STREET": "DOKUZKAVAKLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "PAMUKKALE",
        "STREET": "ELDENİZLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "PAMUKKALE",
        "STREET": "EYMİR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "PAMUKKALE",
        "STREET": "FATİH MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "PAMUKKALE",
        "STREET": "FESLEĞEN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "PAMUKKALE",
        "STREET": "GONCALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "PAMUKKALE",
        "STREET": "GÖKPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "PAMUKKALE",
        "STREET": "GÖLEMEZLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "PAMUKKALE",
        "STREET": "GÖZLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "PAMUKKALE",
        "STREET": "GÜZELKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "PAMUKKALE",
        "STREET": "GÜZELPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "PAMUKKALE",
        "STREET": "HACIKAPLANLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "PAMUKKALE",
        "STREET": "HAYTABEY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "PAMUKKALE",
        "STREET": "HÜRRİYET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "PAMUKKALE",
        "STREET": "IRLIGANLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "PAMUKKALE",
        "STREET": "KALE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "PAMUKKALE",
        "STREET": "KARAHAYIT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "PAMUKKALE",
        "STREET": "KARAKOVA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "PAMUKKALE",
        "STREET": "KARAKURT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "PAMUKKALE",
        "STREET": "KARATAŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "PAMUKKALE",
        "STREET": "KARŞIYAKA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "PAMUKKALE",
        "STREET": "KAVAKBAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "PAMUKKALE",
        "STREET": "KAYIHAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "PAMUKKALE",
        "STREET": "KERVANSARAY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "PAMUKKALE",
        "STREET": "KINIKLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "PAMUKKALE",
        "STREET": "KOCADERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "PAMUKKALE",
        "STREET": "KORUCUK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "PAMUKKALE",
        "STREET": "KURTLUCA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "PAMUKKALE",
        "STREET": "KUŞPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "PAMUKKALE",
        "STREET": "KÜÇÜKDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "PAMUKKALE",
        "STREET": "MEHMETÇİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "PAMUKKALE",
        "STREET": "PAMUKKALE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "PAMUKKALE",
        "STREET": "PELİTLİBAĞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "PAMUKKALE",
        "STREET": "PINARKENT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "PAMUKKALE",
        "STREET": "SİTELER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "PAMUKKALE",
        "STREET": "TEKKE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "PAMUKKALE",
        "STREET": "TOPRAKLIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "PAMUKKALE",
        "STREET": "UZUNPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "PAMUKKALE",
        "STREET": "YENİKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "PAMUKKALE",
        "STREET": "YUKARIŞAMLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "PAMUKKALE",
        "STREET": "YUNUSEMRE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "PAMUKKALE",
        "STREET": "ZEYTİNKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "PAMUKKALE",
        "STREET": "ZÜMRÜT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "PAMUKKALE",
        "STREET": "ÇEŞMEBAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "PAMUKKALE",
        "STREET": "İNCİLİPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "PAMUKKALE",
        "STREET": "İSTİKLAL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "SARAYKÖY",
        "STREET": "ACIDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "SARAYKÖY",
        "STREET": "ACISU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "SARAYKÖY",
        "STREET": "ADA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "SARAYKÖY",
        "STREET": "AHMETLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "SARAYKÖY",
        "STREET": "ALTINTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "SARAYKÖY",
        "STREET": "ATATÜRK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "SARAYKÖY",
        "STREET": "AŞAĞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "SARAYKÖY",
        "STREET": "BALA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "SARAYKÖY",
        "STREET": "BEYLERBEYİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "SARAYKÖY",
        "STREET": "CABER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "SARAYKÖY",
        "STREET": "CUMHURİYET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "SARAYKÖY",
        "STREET": "DUACILI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "SARAYKÖY",
        "STREET": "GERALİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "SARAYKÖY",
        "STREET": "HASKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "SARAYKÖY",
        "STREET": "HİSAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "SARAYKÖY",
        "STREET": "KABAAĞAÇ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "SARAYKÖY",
        "STREET": "KARAKIRAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "SARAYKÖY",
        "STREET": "KARATAŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "SARAYKÖY",
        "STREET": "KUMLUCA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "SARAYKÖY",
        "STREET": "KÖPRÜBAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "SARAYKÖY",
        "STREET": "SAKARYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "SARAYKÖY",
        "STREET": "SAZAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "SARAYKÖY",
        "STREET": "SIĞMA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "SARAYKÖY",
        "STREET": "TEKKE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "SARAYKÖY",
        "STREET": "TEPEKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "SARAYKÖY",
        "STREET": "TIRKAZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "SARAYKÖY",
        "STREET": "TOSUNLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "SARAYKÖY",
        "STREET": "TRAFO MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "SARAYKÖY",
        "STREET": "TURAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "SARAYKÖY",
        "STREET": "UYANIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "SARAYKÖY",
        "STREET": "YAKAYURT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "SARAYKÖY",
        "STREET": "YEŞİLYURT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "SERİNHİSAR",
        "STREET": "AYAZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "SERİNHİSAR",
        "STREET": "AŞAĞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "SERİNHİSAR",
        "STREET": "CUMHURİYET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "SERİNHİSAR",
        "STREET": "KAYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "SERİNHİSAR",
        "STREET": "KOCAPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "SERİNHİSAR",
        "STREET": "ORTA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "SERİNHİSAR",
        "STREET": "PINARCIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "SERİNHİSAR",
        "STREET": "YATAĞAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "SERİNHİSAR",
        "STREET": "YENİCE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "SERİNHİSAR",
        "STREET": "YÜREĞİL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "SERİNHİSAR",
        "STREET": "ŞAİR EŞREF   MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "TAVAS",
        "STREET": "AKINCILAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "TAVAS",
        "STREET": "AKYAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "TAVAS",
        "STREET": "ALPA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "TAVAS",
        "STREET": "ALTINOVA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "TAVAS",
        "STREET": "AVDAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "TAVAS",
        "STREET": "AYDOĞDU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "TAVAS",
        "STREET": "BAHARLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "TAVAS",
        "STREET": "BAHÇEKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "TAVAS",
        "STREET": "BALKICA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "TAVAS",
        "STREET": "DAMLACIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "TAVAS",
        "STREET": "DENİZOLUĞU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "TAVAS",
        "STREET": "DEREAĞZI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "TAVAS",
        "STREET": "DERİNKUYU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "TAVAS",
        "STREET": "EBECİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "TAVAS",
        "STREET": "GARİPKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "TAVAS",
        "STREET": "GÖKÇELER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "TAVAS",
        "STREET": "GÜMÜŞDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "TAVAS",
        "STREET": "GÜZELKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "TAVAS",
        "STREET": "HIRKA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "TAVAS",
        "STREET": "HORASANLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "TAVAS",
        "STREET": "KARAHİSAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "TAVAS",
        "STREET": "KAYACA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "TAVAS",
        "STREET": "KAYAPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "TAVAS",
        "STREET": "KEÇELİLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "TAVAS",
        "STREET": "KIZILCA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "TAVAS",
        "STREET": "KIZILCABÖLÜK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "TAVAS",
        "STREET": "KOZLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "TAVAS",
        "STREET": "MEDET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "TAVAS",
        "STREET": "NİKFER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "TAVAS",
        "STREET": "ORTA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "TAVAS",
        "STREET": "OVACIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "TAVAS",
        "STREET": "PINARLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "TAVAS",
        "STREET": "PINARLIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "TAVAS",
        "STREET": "SAMANLIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "TAVAS",
        "STREET": "SARABAT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "TAVAS",
        "STREET": "SEKİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "TAVAS",
        "STREET": "SOFULAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "TAVAS",
        "STREET": "SOLMAZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "TAVAS",
        "STREET": "TEKKEKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "TAVAS",
        "STREET": "ULUKENT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "TAVAS",
        "STREET": "VAKIF MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "TAVAS",
        "STREET": "YAHŞİLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "TAVAS",
        "STREET": "YAKA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "TAVAS",
        "STREET": "YENİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "TAVAS",
        "STREET": "YEŞİLKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "TAVAS",
        "STREET": "YORGA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "TAVAS",
        "STREET": "YUKARIBOĞAZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "TAVAS",
        "STREET": "ÇALIKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "TAVAS",
        "STREET": "ÇAĞIRGAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "TAVAS",
        "STREET": "ÇİFTLİKKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "ÇAL",
        "STREET": "AKKENT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "ÇAL",
        "STREET": "ALFAKLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "ÇAL",
        "STREET": "AŞAĞISEYİT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "ÇAL",
        "STREET": "BAHADINLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "ÇAL",
        "STREET": "BAKLANÇAKIRLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "ÇAL",
        "STREET": "BAYIRALAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "ÇAL",
        "STREET": "BELEVİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "ÇAL",
        "STREET": "DAYILAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "ÇAL",
        "STREET": "DAĞMARMARA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "ÇAL",
        "STREET": "DENİZLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "ÇAL",
        "STREET": "DEVELLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "ÇAL",
        "STREET": "GELİNÖREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "ÇAL",
        "STREET": "HANÇALAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "ÇAL",
        "STREET": "HÜSEYİNLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "ÇAL",
        "STREET": "KABALAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "ÇAL",
        "STREET": "KAPLANLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "ÇAL",
        "STREET": "KARAKAYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "ÇAL",
        "STREET": "KARAPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "ÇAL",
        "STREET": "KOCAKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "ÇAL",
        "STREET": "MAHMUTGAZİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "ÇAL",
        "STREET": "ORTAKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "ÇAL",
        "STREET": "PEYNİRCİLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "ÇAL",
        "STREET": "SAKIZCILAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "ÇAL",
        "STREET": "SAZAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "ÇAL",
        "STREET": "SELCEN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "ÇAL",
        "STREET": "SÜLLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "ÇAL",
        "STREET": "YAZIR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "ÇAL",
        "STREET": "YEŞİLYURT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "ÇAL",
        "STREET": "YUKARISEYİT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "ÇAL",
        "STREET": "ÇALKUYUCAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "ÇAL",
        "STREET": "ÇALÇAKIRLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "ÇAL",
        "STREET": "İSABEY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "ÇAL",
        "STREET": "İSMAİLLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "ÇAL",
        "STREET": "ŞAPCILAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "ÇAMELİ",
        "STREET": "AKPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "ÇAMELİ",
        "STREET": "ARIKAYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "ÇAMELİ",
        "STREET": "AYVACIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "ÇAMELİ",
        "STREET": "BELEVİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "ÇAMELİ",
        "STREET": "BIÇAKÇI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "ÇAMELİ",
        "STREET": "CEVİZLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "ÇAMELİ",
        "STREET": "CUMAALANI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "ÇAMELİ",
        "STREET": "ELMALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "ÇAMELİ",
        "STREET": "EMECİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "ÇAMELİ",
        "STREET": "ERİCEK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "ÇAMELİ",
        "STREET": "GÖKÇEYAKA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "ÇAMELİ",
        "STREET": "GÜRSU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "ÇAMELİ",
        "STREET": "GÜZELYURT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "ÇAMELİ",
        "STREET": "KALINKOZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "ÇAMELİ",
        "STREET": "KARABAYIR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "ÇAMELİ",
        "STREET": "KINIKYERİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "ÇAMELİ",
        "STREET": "KIZILYAKA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "ÇAMELİ",
        "STREET": "KOCAOVA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "ÇAMELİ",
        "STREET": "KOLAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "ÇAMELİ",
        "STREET": "KİRAZLIYAYLA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "ÇAMELİ",
        "STREET": "SARIKAVAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "ÇAMELİ",
        "STREET": "SOFULAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "ÇAMELİ",
        "STREET": "TAŞÇILAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "ÇAMELİ",
        "STREET": "YAYLAPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "ÇAMELİ",
        "STREET": "YENİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "ÇAMELİ",
        "STREET": "YEŞİLYAYLA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "ÇAMELİ",
        "STREET": "YUMRUTAŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "ÇAMELİ",
        "STREET": "YUNUSPINARI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "ÇAMELİ",
        "STREET": "ÇAMLIBEL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "ÇAMELİ",
        "STREET": "ÇİĞDEMLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "ÇAMELİ",
        "STREET": "İMAMLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "ÇARDAK",
        "STREET": "AYVAZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "ÇARDAK",
        "STREET": "BAHÇELİEVLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "ÇARDAK",
        "STREET": "BEYLERLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "ÇARDAK",
        "STREET": "CUMHURİYET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "ÇARDAK",
        "STREET": "GEMİŞLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "ÇARDAK",
        "STREET": "GEMİŞPINARI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "ÇARDAK",
        "STREET": "GÖLCÜK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "ÇARDAK",
        "STREET": "HAYRİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "ÇARDAK",
        "STREET": "HÜRRİYET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "ÇARDAK",
        "STREET": "SARAY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "ÇARDAK",
        "STREET": "SÖĞÜTLÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "ÇARDAK",
        "STREET": "SÖĞÜTÖZÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "ÇARDAK",
        "STREET": "ÇALTI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "ÇARDAK",
        "STREET": "ÇINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "ÇARDAK",
        "STREET": "İSTİKLAL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "ÇİVRİL",
        "STREET": "AKPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "ÇİVRİL",
        "STREET": "AKTAŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "ÇİVRİL",
        "STREET": "AKÇAKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "ÇİVRİL",
        "STREET": "AŞAĞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "ÇİVRİL",
        "STREET": "BALÇIKHİSAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "ÇİVRİL",
        "STREET": "BAYAT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "ÇİVRİL",
        "STREET": "BEKİRLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "ÇİVRİL",
        "STREET": "BELENCE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "ÇİVRİL",
        "STREET": "BEYDİLLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "ÇİVRİL",
        "STREET": "BEYKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "ÇİVRİL",
        "STREET": "BOZDAĞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "ÇİVRİL",
        "STREET": "BUCAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "ÇİVRİL",
        "STREET": "BULGURLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "ÇİVRİL",
        "STREET": "CABAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "ÇİVRİL",
        "STREET": "CUMALAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "ÇİVRİL",
        "STREET": "DÜZBEL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "ÇİVRİL",
        "STREET": "EMİRCİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "ÇİVRİL",
        "STREET": "GÖKGÖL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "ÇİVRİL",
        "STREET": "GÜMÜŞSU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "ÇİVRİL",
        "STREET": "GÜRPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "ÇİVRİL",
        "STREET": "HAMAM MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "ÇİVRİL",
        "STREET": "HAYDAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "ÇİVRİL",
        "STREET": "IRGILLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "ÇİVRİL",
        "STREET": "IŞIKLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "ÇİVRİL",
        "STREET": "KARABEDİRLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "ÇİVRİL",
        "STREET": "KARAHACILI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "ÇİVRİL",
        "STREET": "KARALAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "ÇİVRİL",
        "STREET": "KARAMANLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "ÇİVRİL",
        "STREET": "KARAYAHŞİLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "ÇİVRİL",
        "STREET": "KAVAKALANI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "ÇİVRİL",
        "STREET": "KAVAKKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "ÇİVRİL",
        "STREET": "KIRALAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "ÇİVRİL",
        "STREET": "KIZILCASÖĞÜT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "ÇİVRİL",
        "STREET": "KIZILCAYER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "ÇİVRİL",
        "STREET": "KOCAYAKA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "ÇİVRİL",
        "STREET": "KOÇAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "ÇİVRİL",
        "STREET": "MENTEŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "ÇİVRİL",
        "STREET": "OSMANKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "ÇİVRİL",
        "STREET": "REŞADİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "ÇİVRİL",
        "STREET": "SARAY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "ÇİVRİL",
        "STREET": "SARIBEYLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "ÇİVRİL",
        "STREET": "SARILAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "ÇİVRİL",
        "STREET": "SAVRAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "ÇİVRİL",
        "STREET": "SERASERLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "ÇİVRİL",
        "STREET": "SOMAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "ÇİVRİL",
        "STREET": "STADYUM MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "ÇİVRİL",
        "STREET": "SUNDURLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "ÇİVRİL",
        "STREET": "SÖKMEN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "ÇİVRİL",
        "STREET": "SÜNGÜLLÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "ÇİVRİL",
        "STREET": "TEKKE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "ÇİVRİL",
        "STREET": "TOKÇA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "ÇİVRİL",
        "STREET": "TUĞLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "ÇİVRİL",
        "STREET": "YAHYALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "ÇİVRİL",
        "STREET": "YAKACIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "ÇİVRİL",
        "STREET": "YALINLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "ÇİVRİL",
        "STREET": "YAMANLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "ÇİVRİL",
        "STREET": "YASSIHÜYÜK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "ÇİVRİL",
        "STREET": "YUKARI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "ÇİVRİL",
        "STREET": "YUKARI ÇAPAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "ÇİVRİL",
        "STREET": "YUVAKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "ÇİVRİL",
        "STREET": "ÇAKALLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "ÇİVRİL",
        "STREET": "ÇANDIR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "ÇİVRİL",
        "STREET": "ÇAPAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "ÇİVRİL",
        "STREET": "ÇARŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "ÇİVRİL",
        "STREET": "ÇATLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "ÇİVRİL",
        "STREET": "ÇAYIR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "ÇİVRİL",
        "STREET": "ÇAĞLAYAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "ÇİVRİL",
        "STREET": "ÇETİNLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "ÇİVRİL",
        "STREET": "ÇITAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "ÇİVRİL",
        "STREET": "ÖMERLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "ÇİVRİL",
        "STREET": "ÖZDEMİRCİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "ÇİVRİL",
        "STREET": "İMRALLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "ÇİVRİL",
        "STREET": "İNCEKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "ÇİVRİL",
        "STREET": "İSHAKLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "ÇİVRİL",
        "STREET": "İĞDİR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "ÇİVRİL",
        "STREET": "ŞEHİTLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DENİZLİ",
        "DISTRICT": "ÇİVRİL",
        "STREET": "ŞENKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "AKÇAKOCA",
        "STREET": "AKKAYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "AKÇAKOCA",
        "STREET": "AKTAŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "AKÇAKOCA",
        "STREET": "ALTUNÇAY KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "AKÇAKOCA",
        "STREET": "ARABACI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "AKÇAKOCA",
        "STREET": "AYAZLI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "AKÇAKOCA",
        "STREET": "BALATLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "AKÇAKOCA",
        "STREET": "BEYHANLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "AKÇAKOCA",
        "STREET": "BEYÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "AKÇAKOCA",
        "STREET": "CUMHURİYET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "AKÇAKOCA",
        "STREET": "DADALI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "AKÇAKOCA",
        "STREET": "DAVUTAĞA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "AKÇAKOCA",
        "STREET": "DEREDİBİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "AKÇAKOCA",
        "STREET": "DEREKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "AKÇAKOCA",
        "STREET": "DOĞANCILAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "AKÇAKOCA",
        "STREET": "DÖNGELLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "AKÇAKOCA",
        "STREET": "DİLAVER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "AKÇAKOCA",
        "STREET": "EDİLLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "AKÇAKOCA",
        "STREET": "ESMAHANIM KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "AKÇAKOCA",
        "STREET": "FAKILLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "AKÇAKOCA",
        "STREET": "GÖKTEPE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "AKÇAKOCA",
        "STREET": "HACI YUSUFLAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "AKÇAKOCA",
        "STREET": "HASANÇAVUŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "AKÇAKOCA",
        "STREET": "HEMŞİN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "AKÇAKOCA",
        "STREET": "KALKIN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "AKÇAKOCA",
        "STREET": "KARATAVUK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "AKÇAKOCA",
        "STREET": "KEPENÇ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "AKÇAKOCA",
        "STREET": "KINIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "AKÇAKOCA",
        "STREET": "KOÇAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "AKÇAKOCA",
        "STREET": "KOÇULLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "AKÇAKOCA",
        "STREET": "KURUGÖL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "AKÇAKOCA",
        "STREET": "KURUKAVAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "AKÇAKOCA",
        "STREET": "KÜPLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "AKÇAKOCA",
        "STREET": "KİRAZLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "AKÇAKOCA",
        "STREET": "MELENAĞZI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "AKÇAKOCA",
        "STREET": "NAZIMBEY KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "AKÇAKOCA",
        "STREET": "ORHANGAZİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "AKÇAKOCA",
        "STREET": "ORTANCA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "AKÇAKOCA",
        "STREET": "OSMANİYE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "AKÇAKOCA",
        "STREET": "PAŞALAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "AKÇAKOCA",
        "STREET": "SARIYAYLA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "AKÇAKOCA",
        "STREET": "SUBAŞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "AKÇAKOCA",
        "STREET": "TAHİRLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "AKÇAKOCA",
        "STREET": "TEPEKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "AKÇAKOCA",
        "STREET": "UĞURLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "AKÇAKOCA",
        "STREET": "YALI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "AKÇAKOCA",
        "STREET": "YENİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "AKÇAKOCA",
        "STREET": "YENİCE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "AKÇAKOCA",
        "STREET": "YEŞİLKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "AKÇAKOCA",
        "STREET": "YUKARI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "AKÇAKOCA",
        "STREET": "ÇAYAĞZI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "AKÇAKOCA",
        "STREET": "ÇİÇEKPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "CUMAYERİ",
        "STREET": "AKPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "CUMAYERİ",
        "STREET": "AVLAYAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "CUMAYERİ",
        "STREET": "BÜYÜKMELEN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "CUMAYERİ",
        "STREET": "DOKUZDEĞİRMEN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "CUMAYERİ",
        "STREET": "ESENTEPE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "CUMAYERİ",
        "STREET": "HAMASCIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "CUMAYERİ",
        "STREET": "HARMANKAYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "CUMAYERİ",
        "STREET": "IĞDIR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "CUMAYERİ",
        "STREET": "KIZILÜZÜM KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "CUMAYERİ",
        "STREET": "MEHMET AKİF MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "CUMAYERİ",
        "STREET": "MISIRLIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "CUMAYERİ",
        "STREET": "ORDULUKARADERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "CUMAYERİ",
        "STREET": "ORTA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "CUMAYERİ",
        "STREET": "SIRTPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "CUMAYERİ",
        "STREET": "SUBAŞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "CUMAYERİ",
        "STREET": "TAŞLIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "CUMAYERİ",
        "STREET": "YAKA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "CUMAYERİ",
        "STREET": "YENİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "CUMAYERİ",
        "STREET": "YENİTEPE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "CUMAYERİ",
        "STREET": "YEŞİLTEPE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "CUMAYERİ",
        "STREET": "YUKARIAVLAYAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "CUMAYERİ",
        "STREET": "ÇAMLIPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "CUMAYERİ",
        "STREET": "ÇELİKDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "CUMAYERİ",
        "STREET": "ÇEVRİK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "CUMAYERİ",
        "STREET": "ÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "CUMAYERİ",
        "STREET": "ÜVEZBELİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "MERKEZ",
        "STREET": "AKBIYIKLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "MERKEZ",
        "STREET": "AKINLAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "MERKEZ",
        "STREET": "AKPINAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "MERKEZ",
        "STREET": "AKTARLA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "MERKEZ",
        "STREET": "AKYAZI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "MERKEZ",
        "STREET": "ALTINPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "MERKEZ",
        "STREET": "ARAPÇİFTLİĞİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "MERKEZ",
        "STREET": "ASAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "MERKEZ",
        "STREET": "AYBAŞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "MERKEZ",
        "STREET": "AYDINPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "MERKEZ",
        "STREET": "AYNALI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "MERKEZ",
        "STREET": "AZMİMİLLİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "MERKEZ",
        "STREET": "AZİZİYE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "MERKEZ",
        "STREET": "AĞAKÖYÜ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "MERKEZ",
        "STREET": "BAHÇE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "MERKEZ",
        "STREET": "BAHÇELİEVLER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "MERKEZ",
        "STREET": "BALLICA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "MERKEZ",
        "STREET": "BATAKLI ÇİFTLİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "MERKEZ",
        "STREET": "BEYCİLER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "MERKEZ",
        "STREET": "BEYKÖY-BEYTEPE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "MERKEZ",
        "STREET": "BEYKÖY-CUMHURİYET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "MERKEZ",
        "STREET": "BEYKÖY-DEĞİRMENBAŞI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "MERKEZ",
        "STREET": "BEYKÖY-KÜLTÜR  MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "MERKEZ",
        "STREET": "BEYKÖY-YENİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "MERKEZ",
        "STREET": "BEYKÖY-İSTİKLAL MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "MERKEZ",
        "STREET": "BOSTANLIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "MERKEZ",
        "STREET": "BOĞAZİÇİ-BALLAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "MERKEZ",
        "STREET": "BOĞAZİÇİ-DOKUZPINAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "MERKEZ",
        "STREET": "BOĞAZİÇİ-YAZLIK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "MERKEZ",
        "STREET": "BOĞAZİÇİ-YEŞİLKÖY MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "MERKEZ",
        "STREET": "BOĞAZİÇİ-ÇAYIRTARLA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "MERKEZ",
        "STREET": "BOĞAZİÇİ-ŞEKERPINAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "MERKEZ",
        "STREET": "BURHANİYE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "MERKEZ",
        "STREET": "BÜYÜKAÇMA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "MERKEZ",
        "STREET": "CAMİKEBİR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "MERKEZ",
        "STREET": "CEDİDİYE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "MERKEZ",
        "STREET": "CUMHURİYET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "MERKEZ",
        "STREET": "DARICI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "MERKEZ",
        "STREET": "DAĞDİBİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "MERKEZ",
        "STREET": "DEDELER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "MERKEZ",
        "STREET": "DEMETEVLER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "MERKEZ",
        "STREET": "DERDİN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "MERKEZ",
        "STREET": "DERELİTÜTÜNCÜ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "MERKEZ",
        "STREET": "DEVELİ BESNİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "MERKEZ",
        "STREET": "DOĞANLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "MERKEZ",
        "STREET": "DURAKLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "MERKEZ",
        "STREET": "DÜVERDÜZÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "MERKEZ",
        "STREET": "DÜZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "MERKEZ",
        "STREET": "EMİNAÇMA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "MERKEZ",
        "STREET": "ERDEMLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "MERKEZ",
        "STREET": "ESENTEPE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "MERKEZ",
        "STREET": "ESENTEPE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "MERKEZ",
        "STREET": "ESENÇAM KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "MERKEZ",
        "STREET": "ESKİ MENGENCİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "MERKEZ",
        "STREET": "FATİH MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "MERKEZ",
        "STREET": "FEVZİÇAKMAK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "MERKEZ",
        "STREET": "FINDIKLI AKSU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "MERKEZ",
        "STREET": "GÖKÇE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "MERKEZ",
        "STREET": "GÖLORMANI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "MERKEZ",
        "STREET": "GÜLDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "MERKEZ",
        "STREET": "GÜMÜŞPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "MERKEZ",
        "STREET": "GÜNBAŞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "MERKEZ",
        "STREET": "GÜNDOLAMASI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "MERKEZ",
        "STREET": "GÜVEN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "MERKEZ",
        "STREET": "GÜZELBAHÇE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "MERKEZ",
        "STREET": "HACI AHMETLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "MERKEZ",
        "STREET": "HACI ALİLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "MERKEZ",
        "STREET": "HAMİDİYE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "MERKEZ",
        "STREET": "HASANLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "MERKEZ",
        "STREET": "HATİPLİ KETENCİLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "MERKEZ",
        "STREET": "HOCAOĞLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "MERKEZ",
        "STREET": "KABALAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "MERKEZ",
        "STREET": "KADIOĞLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "MERKEZ",
        "STREET": "KALEDİBİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "MERKEZ",
        "STREET": "KARA HACIMUSA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "MERKEZ",
        "STREET": "KARACA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "MERKEZ",
        "STREET": "KARADERE HASANAĞA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "MERKEZ",
        "STREET": "KAVAKBIÇKI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "MERKEZ",
        "STREET": "KAYMAKÇI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "MERKEZ",
        "STREET": "KAZUKOĞLU MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "MERKEZ",
        "STREET": "KEMERKASIM KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "MERKEZ",
        "STREET": "KIZILCIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "MERKEZ",
        "STREET": "KONAKLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "MERKEZ",
        "STREET": "KOZLUK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "MERKEZ",
        "STREET": "KOÇYAZI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "MERKEZ",
        "STREET": "KURTSUYU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "MERKEZ",
        "STREET": "KUTLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "MERKEZ",
        "STREET": "KUYUMCUHACIALİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "MERKEZ",
        "STREET": "KUŞAÇMASI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "MERKEZ",
        "STREET": "KÖPRÜBAŞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "MERKEZ",
        "STREET": "KÖRPEŞLER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "MERKEZ",
        "STREET": "KÜLTÜR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "MERKEZ",
        "STREET": "KÜÇÜKAHMET KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "MERKEZ",
        "STREET": "KÜÇÜKMEHMET KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "MERKEZ",
        "STREET": "KİRAZLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "MERKEZ",
        "STREET": "KİREMİTOCAĞI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "MERKEZ",
        "STREET": "MAMURE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "MERKEZ",
        "STREET": "MERGİÇ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "MERKEZ",
        "STREET": "MUNCURLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "MERKEZ",
        "STREET": "MURADİYEMENGENCİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "MERKEZ",
        "STREET": "MUSABABA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "MERKEZ",
        "STREET": "NALBANTOĞLU MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "MERKEZ",
        "STREET": "NASIRLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "MERKEZ",
        "STREET": "NUHLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "MERKEZ",
        "STREET": "NUSRETTİN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "MERKEZ",
        "STREET": "ORHANGAZİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "MERKEZ",
        "STREET": "OSMANCA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "MERKEZ",
        "STREET": "OTLUOĞLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "MERKEZ",
        "STREET": "OVAPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "MERKEZ",
        "STREET": "OZANLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "MERKEZ",
        "STREET": "PAŞAKONAĞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "MERKEZ",
        "STREET": "PAŞAORMANI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "MERKEZ",
        "STREET": "PINARLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "MERKEZ",
        "STREET": "SALLAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "MERKEZ",
        "STREET": "SAMANDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "MERKEZ",
        "STREET": "SANCAKDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "MERKEZ",
        "STREET": "SANCAKLAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "MERKEZ",
        "STREET": "SARAYYERİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "MERKEZ",
        "STREET": "SOĞUKPINAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "MERKEZ",
        "STREET": "SUNCUK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "MERKEZ",
        "STREET": "SİNİRCİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "MERKEZ",
        "STREET": "TAŞKÖPRÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "MERKEZ",
        "STREET": "TERZİALİLER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "MERKEZ",
        "STREET": "TOKUŞLAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "MERKEZ",
        "STREET": "TURAPLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "MERKEZ",
        "STREET": "UZUNMUSTAFA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "MERKEZ",
        "STREET": "UĞUR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "MERKEZ",
        "STREET": "YAHYALAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "MERKEZ",
        "STREET": "YAKA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "MERKEZ",
        "STREET": "YAYAKBAŞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "MERKEZ",
        "STREET": "YAYLA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "MERKEZ",
        "STREET": "YENİ AYNALI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "MERKEZ",
        "STREET": "YENİ KARAKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "MERKEZ",
        "STREET": "YENİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "MERKEZ",
        "STREET": "YENİ TAŞKÖPRÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "MERKEZ",
        "STREET": "YEŞİLTEPE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "MERKEZ",
        "STREET": "YEŞİLÇAM KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "MERKEZ",
        "STREET": "YEŞİLÇİMEN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "MERKEZ",
        "STREET": "YUKARI YAHYALAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "MERKEZ",
        "STREET": "YÖRÜK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇAKIRHACI İBRAHİM KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇAKIRLAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇALICUMA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇAMKÖY MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇAMLIEVLER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇAMLISU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇAVUŞLAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇAY MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇINARDÜZÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇINARLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇİFTEPINARLAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇİFTLİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "MERKEZ",
        "STREET": "ÖZYANIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "MERKEZ",
        "STREET": "ÜÇYOL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "MERKEZ",
        "STREET": "İHSANİYE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "MERKEZ",
        "STREET": "İSLAHİYE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "MERKEZ",
        "STREET": "İSTİLLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "MERKEZ",
        "STREET": "ŞAZİYE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "MERKEZ",
        "STREET": "ŞEHİT BAYRAM GÖKMEN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "MERKEZ",
        "STREET": "ŞEHİT HÜSEYİN KIL MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "MERKEZ",
        "STREET": "ŞEHİT KEMAL IŞILDAK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "MERKEZ",
        "STREET": "ŞEHİT MURAT DEMİR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "MERKEZ",
        "STREET": "ŞEREFİYE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "MERKEZ",
        "STREET": "ŞIRALIK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "GÖLYAKA",
        "STREET": "AKSU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "GÖLYAKA",
        "STREET": "AÇMA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "GÖLYAKA",
        "STREET": "AÇMA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "GÖLYAKA",
        "STREET": "BAKACAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "GÖLYAKA",
        "STREET": "BEKİROĞLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "GÖLYAKA",
        "STREET": "CUMAKIRI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "GÖLYAKA",
        "STREET": "DEĞİRMENTEPE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "GÖLYAKA",
        "STREET": "ESEN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "GÖLYAKA",
        "STREET": "FATİH MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "GÖLYAKA",
        "STREET": "GÜZELDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "GÖLYAKA",
        "STREET": "HACISÜLEYMANBEY KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "GÖLYAKA",
        "STREET": "HACIYAKUP KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "GÖLYAKA",
        "STREET": "HAMAMÜSTÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "GÖLYAKA",
        "STREET": "KEMERYANI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "GÖLYAKA",
        "STREET": "KUYUDÜZÜ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "GÖLYAKA",
        "STREET": "KÜLTÜR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "GÖLYAKA",
        "STREET": "MUHAPDEDE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "GÖLYAKA",
        "STREET": "SARIDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "GÖLYAKA",
        "STREET": "SAÇMALIPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "GÖLYAKA",
        "STREET": "TAŞLIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "GÖLYAKA",
        "STREET": "YAZIPINAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "GÖLYAKA",
        "STREET": "YAZLIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "GÖLYAKA",
        "STREET": "YENİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "GÖLYAKA",
        "STREET": "YEŞİL MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "GÖLYAKA",
        "STREET": "YEŞİLOVA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "GÖLYAKA",
        "STREET": "YUNUSEFENDİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "GÖLYAKA",
        "STREET": "ZEKERİYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "GÖLYAKA",
        "STREET": "ÇAMLIBEL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "GÖLYAKA",
        "STREET": "ÇAY KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "GÖLYAKA",
        "STREET": "İMAMLAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "GÖLYAKA",
        "STREET": "İÇMELER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "GÜMÜŞOVA",
        "STREET": "ADAKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "GÜMÜŞOVA",
        "STREET": "ARDIÇDİBİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "GÜMÜŞOVA",
        "STREET": "DEDEDÜZÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "GÜMÜŞOVA",
        "STREET": "DEREKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "GÜMÜŞOVA",
        "STREET": "ELMACIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "GÜMÜŞOVA",
        "STREET": "FATİH MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "GÜMÜŞOVA",
        "STREET": "HACIKADİRLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "GÜMÜŞOVA",
        "STREET": "HACILAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "GÜMÜŞOVA",
        "STREET": "HALİLBEY KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "GÜMÜŞOVA",
        "STREET": "KAHVELERYANI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "GÜMÜŞOVA",
        "STREET": "KIYIKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "GÜMÜŞOVA",
        "STREET": "KIŞLA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "GÜMÜŞOVA",
        "STREET": "KÜLTÜR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "GÜMÜŞOVA",
        "STREET": "MERKEZ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "GÜMÜŞOVA",
        "STREET": "PAZARCIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "GÜMÜŞOVA",
        "STREET": "SELAMLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "GÜMÜŞOVA",
        "STREET": "SOĞUKSU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "GÜMÜŞOVA",
        "STREET": "SULTANİYE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "GÜMÜŞOVA",
        "STREET": "YAKABAŞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "GÜMÜŞOVA",
        "STREET": "YENİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "GÜMÜŞOVA",
        "STREET": "YEŞİLYAYLA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "GÜMÜŞOVA",
        "STREET": "YILDIZTEPE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "GÜMÜŞOVA",
        "STREET": "YONGALIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "GÜMÜŞOVA",
        "STREET": "ÇAYBÜKÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "KAYNAŞLI",
        "STREET": "ALTUNKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "KAYNAŞLI",
        "STREET": "BIÇKIYANI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "KAYNAŞLI",
        "STREET": "DARIYERİ YÖRÜKLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "KAYNAŞLI",
        "STREET": "DARIYERİBAKACAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "KAYNAŞLI",
        "STREET": "DARIYERİHASANBEY KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "KAYNAŞLI",
        "STREET": "DARIYERİMENGENCİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "KAYNAŞLI",
        "STREET": "DİPSİZGÖL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "KAYNAŞLI",
        "STREET": "ESKİKÖY MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "KAYNAŞLI",
        "STREET": "FINDIKLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "KAYNAŞLI",
        "STREET": "HACIAZİZLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "KAYNAŞLI",
        "STREET": "KARAÇALI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "KAYNAŞLI",
        "STREET": "KUMLUCA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "KAYNAŞLI",
        "STREET": "MERKEZ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "KAYNAŞLI",
        "STREET": "MURATBEY KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "KAYNAŞLI",
        "STREET": "SARIYER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "KAYNAŞLI",
        "STREET": "SARIÇÖKEK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "KAYNAŞLI",
        "STREET": "SAZKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "KAYNAŞLI",
        "STREET": "TAVAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "KAYNAŞLI",
        "STREET": "YENİYURT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "KAYNAŞLI",
        "STREET": "YEŞİLTEPE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "KAYNAŞLI",
        "STREET": "ÇAKIRSAYVAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "KAYNAŞLI",
        "STREET": "ÇAMLICA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "KAYNAŞLI",
        "STREET": "ÇAMOLUK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "KAYNAŞLI",
        "STREET": "ÇATALÇAM KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "KAYNAŞLI",
        "STREET": "ÇELE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "KAYNAŞLI",
        "STREET": "ÜÇKÖPRÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "KAYNAŞLI",
        "STREET": "ŞİMŞİR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "YIĞILCA",
        "STREET": "AHMETÇİLER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "YIĞILCA",
        "STREET": "AKSAKLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "YIĞILCA",
        "STREET": "AKÇAÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "YIĞILCA",
        "STREET": "ASAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "YIĞILCA",
        "STREET": "ATATÜRK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "YIĞILCA",
        "STREET": "AYDINYAYLA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "YIĞILCA",
        "STREET": "BEKİRLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "YIĞILCA",
        "STREET": "DOĞANLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "YIĞILCA",
        "STREET": "DUTLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "YIĞILCA",
        "STREET": "DİBEKTAŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "YIĞILCA",
        "STREET": "GAZİLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "YIĞILCA",
        "STREET": "GELENÖZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "YIĞILCA",
        "STREET": "GERİŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "YIĞILCA",
        "STREET": "GÖKÇEAĞAÇ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "YIĞILCA",
        "STREET": "GÜNEY KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "YIĞILCA",
        "STREET": "HACILAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "YIĞILCA",
        "STREET": "HACIYERİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "YIĞILCA",
        "STREET": "HEBELER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "YIĞILCA",
        "STREET": "HOCAKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "YIĞILCA",
        "STREET": "HOCATMAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "YIĞILCA",
        "STREET": "HOŞAFOĞLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "YIĞILCA",
        "STREET": "KARAKAŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "YIĞILCA",
        "STREET": "KIRIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "YIĞILCA",
        "STREET": "KOCAOĞLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "YIĞILCA",
        "STREET": "KÖSELER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "YIĞILCA",
        "STREET": "MAREŞAL FEVZİ  ÇAKMAK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "YIĞILCA",
        "STREET": "MENGEN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "YIĞILCA",
        "STREET": "NAŞLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "YIĞILCA",
        "STREET": "ORHANGAZİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "YIĞILCA",
        "STREET": "ORHANGAZİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "YIĞILCA",
        "STREET": "REDİFLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "YIĞILCA",
        "STREET": "SARIKAYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "YIĞILCA",
        "STREET": "TIRAŞLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "YIĞILCA",
        "STREET": "TUĞRUL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "YIĞILCA",
        "STREET": "YAYLATEPE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "YIĞILCA",
        "STREET": "YAĞCILAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "YIĞILCA",
        "STREET": "YENİYER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "YIĞILCA",
        "STREET": "YILGIN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "YIĞILCA",
        "STREET": "YOĞUNPELİT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "YIĞILCA",
        "STREET": "ÇAMLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "YIĞILCA",
        "STREET": "ÇUKURÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "YIĞILCA",
        "STREET": "ÇİFTLİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "YIĞILCA",
        "STREET": "İĞNELER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "ÇİLİMLİ",
        "STREET": "ALACAMESCİT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "ÇİLİMLİ",
        "STREET": "ARABACI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "ÇİLİMLİ",
        "STREET": "BIÇKIBAŞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "ÇİLİMLİ",
        "STREET": "DÖNGELLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "ÇİLİMLİ",
        "STREET": "DÜZCE T TİPİ KAPALI CEZA İNFAZ KURUMU MÜDÜRLÜĞÜ",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "ÇİLİMLİ",
        "STREET": "DİKMELİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "ÇİLİMLİ",
        "STREET": "ESENLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "ÇİLİMLİ",
        "STREET": "HIZARDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "ÇİLİMLİ",
        "STREET": "KAFYAYLA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "ÇİLİMLİ",
        "STREET": "KARAÇÖRTLEN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "ÇİLİMLİ",
        "STREET": "KIRKHARMAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "ÇİLİMLİ",
        "STREET": "KUŞOĞLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "ÇİLİMLİ",
        "STREET": "KİRAZTARLA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "ÇİLİMLİ",
        "STREET": "MAHİRAĞA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "ÇİLİMLİ",
        "STREET": "PIRPIR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "ÇİLİMLİ",
        "STREET": "SARIMEŞE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "ÇİLİMLİ",
        "STREET": "SÖĞÜTLÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "ÇİLİMLİ",
        "STREET": "TEPEKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "ÇİLİMLİ",
        "STREET": "TOPÇULAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "ÇİLİMLİ",
        "STREET": "ULUCAMİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "ÇİLİMLİ",
        "STREET": "YENİKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "ÇİLİMLİ",
        "STREET": "YENİVAKIF KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "ÇİLİMLİ",
        "STREET": "YEŞİL MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "ÇİLİMLİ",
        "STREET": "YEŞİLTEPE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "ÇİLİMLİ",
        "STREET": "YUKARIKARAKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "ÇİLİMLİ",
        "STREET": "ÇALILIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "ÇİLİMLİ",
        "STREET": "İSHAKLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "DÜZCE",
        "DISTRICT": "ÇİLİMLİ",
        "STREET": "ŞEREFİYE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "BAĞLAR",
        "STREET": "5 NİSAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "BAĞLAR",
        "STREET": "ALANKOZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "BAĞLAR",
        "STREET": "ALİPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "BAĞLAR",
        "STREET": "AĞAÇGEÇİT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "BAĞLAR",
        "STREET": "BATIKARAKOÇ  MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "BAĞLAR",
        "STREET": "BATIÇANAKÇI  MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "BAĞLAR",
        "STREET": "BAĞCILAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "BAĞLAR",
        "STREET": "BUYURANSU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "BAĞLAR",
        "STREET": "BUÇUKTEPE  MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "BAĞLAR",
        "STREET": "DEVELİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "BAĞLAR",
        "STREET": "EKİNCE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "BAĞLAR",
        "STREET": "FATİH MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "BAĞLAR",
        "STREET": "GÖMMETAŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "BAĞLAR",
        "STREET": "KABAHIDIR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "BAĞLAR",
        "STREET": "KAMIŞPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "BAĞLAR",
        "STREET": "KARACADAĞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "BAĞLAR",
        "STREET": "KARAHANKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "BAĞLAR",
        "STREET": "KAYNARTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "BAĞLAR",
        "STREET": "KIRKINCIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "BAĞLAR",
        "STREET": "KIRKKOYUN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "BAĞLAR",
        "STREET": "KOLLUDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "BAĞLAR",
        "STREET": "KÖRHAT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "BAĞLAR",
        "STREET": "KÖRTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "BAĞLAR",
        "STREET": "MEVLANA HALİT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "BAĞLAR",
        "STREET": "MURADİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "BAĞLAR",
        "STREET": "ORTAÖREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "BAĞLAR",
        "STREET": "OĞLAKLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "BAĞLAR",
        "STREET": "PINAROĞLU  MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "BAĞLAR",
        "STREET": "SAKALLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "BAĞLAR",
        "STREET": "SARIDALLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "BAĞLAR",
        "STREET": "SELAHATTİN EYYUBİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "BAĞLAR",
        "STREET": "TAVŞANTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "BAĞLAR",
        "STREET": "TELLİKAYA  MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "BAĞLAR",
        "STREET": "TOKLUCA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "BAĞLAR",
        "STREET": "TOPRAKTAŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "BAĞLAR",
        "STREET": "TOPYOLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "BAĞLAR",
        "STREET": "UZUNBAHÇE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "BAĞLAR",
        "STREET": "YENİKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "BAĞLAR",
        "STREET": "YEŞİLDALLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "BAĞLAR",
        "STREET": "YUKARI MOLLAALİ  MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "BAĞLAR",
        "STREET": "YUKARIAKDİBEK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "BAĞLAR",
        "STREET": "YUNUS EMRE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "BAĞLAR",
        "STREET": "YİĞİTYOLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "BAĞLAR",
        "STREET": "ÇİFTLİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "BAĞLAR",
        "STREET": "ÇİÇEKLİYURT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "BAĞLAR",
        "STREET": "ÖVÜNDÜLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "BAĞLAR",
        "STREET": "ÖZDEMİR  MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "BAĞLAR",
        "STREET": "ŞEYH ŞAMİL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "BİSMİL",
        "STREET": "AHMETLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "BİSMİL",
        "STREET": "AKBAŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "BİSMİL",
        "STREET": "AKKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "BİSMİL",
        "STREET": "AKPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "BİSMİL",
        "STREET": "AKÇAY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "BİSMİL",
        "STREET": "ALINCAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "BİSMİL",
        "STREET": "ALTIOK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "BİSMİL",
        "STREET": "ALUÇ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "BİSMİL",
        "STREET": "ALİBEY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "BİSMİL",
        "STREET": "AMBAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "BİSMİL",
        "STREET": "ARALIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "BİSMİL",
        "STREET": "ARAPKENT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "BİSMİL",
        "STREET": "ARIKGÖL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "BİSMİL",
        "STREET": "ASLANOĞLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "BİSMİL",
        "STREET": "ATAKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "BİSMİL",
        "STREET": "AYGEÇTİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "BİSMİL",
        "STREET": "AĞILKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "BİSMİL",
        "STREET": "AĞILLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "BİSMİL",
        "STREET": "AŞAĞIDOLAY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "BİSMİL",
        "STREET": "AŞAĞIOBA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "BİSMİL",
        "STREET": "BABAHAKİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "BİSMİL",
        "STREET": "BADEMLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "BİSMİL",
        "STREET": "BAHARLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "BİSMİL",
        "STREET": "BAHÇE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "BİSMİL",
        "STREET": "BALCILAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "BİSMİL",
        "STREET": "BAŞHAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "BİSMİL",
        "STREET": "BAŞKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "BİSMİL",
        "STREET": "BAŞÖREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "BİSMİL",
        "STREET": "BELLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "BİSMİL",
        "STREET": "BOYACI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "BİSMİL",
        "STREET": "BOZÇALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "BİSMİL",
        "STREET": "BÖLÜMLÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "BİSMİL",
        "STREET": "DERBENT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "BİSMİL",
        "STREET": "DORUK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "BİSMİL",
        "STREET": "DUMLUPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "BİSMİL",
        "STREET": "DİCLE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "BİSMİL",
        "STREET": "ELİAÇIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "BİSMİL",
        "STREET": "ERLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "BİSMİL",
        "STREET": "ESENTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "BİSMİL",
        "STREET": "FATİH MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "BİSMİL",
        "STREET": "FIRAT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "BİSMİL",
        "STREET": "GEDİKBAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "BİSMİL",
        "STREET": "GÖKSU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "BİSMİL",
        "STREET": "GÜNGEÇTİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "BİSMİL",
        "STREET": "GÜROLUK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "BİSMİL",
        "STREET": "GÜZELKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "BİSMİL",
        "STREET": "HARMANLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "BİSMİL",
        "STREET": "HASANPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "BİSMİL",
        "STREET": "IŞIKLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "BİSMİL",
        "STREET": "KAMBERLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "BİSMİL",
        "STREET": "KAMIŞLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "BİSMİL",
        "STREET": "KARABÖRK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "BİSMİL",
        "STREET": "KARACIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "BİSMİL",
        "STREET": "KARAGÖZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "BİSMİL",
        "STREET": "KARAPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "BİSMİL",
        "STREET": "KARATEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "BİSMİL",
        "STREET": "KARAYİĞİT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "BİSMİL",
        "STREET": "KARAÇÖLYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "BİSMİL",
        "STREET": "KAVUŞAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "BİSMİL",
        "STREET": "KAYIKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "BİSMİL",
        "STREET": "KAZANCI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "BİSMİL",
        "STREET": "KAĞITLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "BİSMİL",
        "STREET": "KEBERLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "BİSMİL",
        "STREET": "KILAVUZTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "BİSMİL",
        "STREET": "KOPMAZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "BİSMİL",
        "STREET": "KOPUZLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "BİSMİL",
        "STREET": "KORUKÇU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "BİSMİL",
        "STREET": "KOYUNLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "BİSMİL",
        "STREET": "KOĞUK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "BİSMİL",
        "STREET": "KUMRULU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "BİSMİL",
        "STREET": "KURTULUŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "BİSMİL",
        "STREET": "KURUDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "BİSMİL",
        "STREET": "KURUDEĞİRMEN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "BİSMİL",
        "STREET": "KÖPRÜKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "BİSMİL",
        "STREET": "KÖSELİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "BİSMİL",
        "STREET": "MERDAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "BİSMİL",
        "STREET": "MEYDANLIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "BİSMİL",
        "STREET": "MİRZABEY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "BİSMİL",
        "STREET": "OBALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "BİSMİL",
        "STREET": "OFKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "BİSMİL",
        "STREET": "OĞUZLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "BİSMİL",
        "STREET": "PINARBAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "BİSMİL",
        "STREET": "SANAYİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "BİSMİL",
        "STREET": "SARIKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "BİSMİL",
        "STREET": "SARITOPRAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "BİSMİL",
        "STREET": "SAZLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "BİSMİL",
        "STREET": "SEKİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "BİSMİL",
        "STREET": "SERÇELER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "BİSMİL",
        "STREET": "SEYRANTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "BİSMİL",
        "STREET": "SEYİT HASAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "BİSMİL",
        "STREET": "SİNANKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "BİSMİL",
        "STREET": "TATLIÇAYIR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "BİSMİL",
        "STREET": "TEKEL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "BİSMİL",
        "STREET": "TEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "BİSMİL",
        "STREET": "TEPECİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "BİSMİL",
        "STREET": "TOPRAKLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "BİSMİL",
        "STREET": "TÜRKMENHACI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "BİSMİL",
        "STREET": "TİLKİLİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "BİSMİL",
        "STREET": "ULUTÜRK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "BİSMİL",
        "STREET": "UYANIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "BİSMİL",
        "STREET": "UĞRAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "BİSMİL",
        "STREET": "UĞURLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "BİSMİL",
        "STREET": "YAMAÇKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "BİSMİL",
        "STREET": "YASİNCE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "BİSMİL",
        "STREET": "YAĞMURKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "BİSMİL",
        "STREET": "YENİCE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "BİSMİL",
        "STREET": "YUKARIDOLAY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "BİSMİL",
        "STREET": "YUKARIHARIM MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "BİSMİL",
        "STREET": "YUKARISALAT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "BİSMİL",
        "STREET": "ÇAKALLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "BİSMİL",
        "STREET": "ÇAKILLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "BİSMİL",
        "STREET": "ÇATALKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "BİSMİL",
        "STREET": "ÇAVUŞLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "BİSMİL",
        "STREET": "ÇELTİKLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "BİSMİL",
        "STREET": "ÇORAPA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "BİSMİL",
        "STREET": "ÇÖLAĞAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "BİSMİL",
        "STREET": "ÇÖLTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "BİSMİL",
        "STREET": "ÜÇTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "BİSMİL",
        "STREET": "İSALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "BİSMİL",
        "STREET": "İSAPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "BİSMİL",
        "STREET": "ŞAHİNTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "BİSMİL",
        "STREET": "ŞENTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "DİCLE",
        "STREET": "15 TEMMUZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "DİCLE",
        "STREET": "ACAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "DİCLE",
        "STREET": "ALTAYKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "DİCLE",
        "STREET": "ARIKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "DİCLE",
        "STREET": "BADEMLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "DİCLE",
        "STREET": "BAHÇEDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "DİCLE",
        "STREET": "BAHÇEKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "DİCLE",
        "STREET": "BALTACI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "DİCLE",
        "STREET": "BATURKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "DİCLE",
        "STREET": "BAĞLARBAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "DİCLE",
        "STREET": "BAŞKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "DİCLE",
        "STREET": "BOZOBA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "DİCLE",
        "STREET": "BOĞAZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "DİCLE",
        "STREET": "BOĞAZKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "DİCLE",
        "STREET": "BİÇER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "DİCLE",
        "STREET": "DEDEKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "DİCLE",
        "STREET": "DEĞİRMENLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "DİCLE",
        "STREET": "DURABEYLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "DİCLE",
        "STREET": "DÖĞER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "DİCLE",
        "STREET": "GELİNCİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "DİCLE",
        "STREET": "GÖLBAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "DİCLE",
        "STREET": "GÜNDOĞDU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "DİCLE",
        "STREET": "KAYGISIZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "DİCLE",
        "STREET": "KELEKÇİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "DİCLE",
        "STREET": "KIRKLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "DİCLE",
        "STREET": "KIRKPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "DİCLE",
        "STREET": "KOCAALAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "DİCLE",
        "STREET": "KORUKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "DİCLE",
        "STREET": "KURUDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "DİCLE",
        "STREET": "KURŞUNLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "DİCLE",
        "STREET": "MEYDAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "DİCLE",
        "STREET": "PEKMEZCİLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "DİCLE",
        "STREET": "PINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "DİCLE",
        "STREET": "SERGENLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "DİCLE",
        "STREET": "SÜSÜMLÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "DİCLE",
        "STREET": "TAŞAĞIL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "DİCLE",
        "STREET": "TEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "DİCLE",
        "STREET": "TEPEBAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "DİCLE",
        "STREET": "ULUBAŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "DİCLE",
        "STREET": "ULUÇEŞME MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "DİCLE",
        "STREET": "UĞRAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "DİCLE",
        "STREET": "YEŞİLSIRT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "DİCLE",
        "STREET": "YEŞİLTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "DİCLE",
        "STREET": "YOKUŞLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "DİCLE",
        "STREET": "ÇAVLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "DİCLE",
        "STREET": "ÇELEBİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "DİCLE",
        "STREET": "ÜZÜMLÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ERGANİ",
        "STREET": "ADNAN MENDERES MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ERGANİ",
        "STREET": "AHMETLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ERGANİ",
        "STREET": "AKÇAKALE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ERGANİ",
        "STREET": "AKÇOBAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ERGANİ",
        "STREET": "ALİTAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ERGANİ",
        "STREET": "ARMUTOVA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ERGANİ",
        "STREET": "AZIKLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ERGANİ",
        "STREET": "AZİZİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ERGANİ",
        "STREET": "AŞAĞIBİTİKÇİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ERGANİ",
        "STREET": "AŞAĞIKUYULU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ERGANİ",
        "STREET": "BADEMLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ERGANİ",
        "STREET": "BAGÜR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ERGANİ",
        "STREET": "BAHÇEKAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ERGANİ",
        "STREET": "BEREKETLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ERGANİ",
        "STREET": "BONCUKLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ERGANİ",
        "STREET": "BOZYER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ERGANİ",
        "STREET": "BOĞAZKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ERGANİ",
        "STREET": "CAFERAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ERGANİ",
        "STREET": "CANVEREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ERGANİ",
        "STREET": "COŞKUN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ERGANİ",
        "STREET": "CÖMERT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ERGANİ",
        "STREET": "DALLIDAĞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ERGANİ",
        "STREET": "DAĞARASI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ERGANİ",
        "STREET": "DEMİRLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ERGANİ",
        "STREET": "DEREBOYU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ERGANİ",
        "STREET": "DERİNGÖZE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ERGANİ",
        "STREET": "DEVELİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ERGANİ",
        "STREET": "DEVLETKUŞU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ERGANİ",
        "STREET": "DEĞİRMENDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ERGANİ",
        "STREET": "DOĞANKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ERGANİ",
        "STREET": "DİBEKTAŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ERGANİ",
        "STREET": "FATİH MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ERGANİ",
        "STREET": "FEVZİ ÇAKMAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ERGANİ",
        "STREET": "GÖKİÇİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ERGANİ",
        "STREET": "GÖZEKAYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ERGANİ",
        "STREET": "GÖZLÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ERGANİ",
        "STREET": "GÜLERCE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ERGANİ",
        "STREET": "GÜNEŞTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ERGANİ",
        "STREET": "GÜZELYURT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ERGANİ",
        "STREET": "GİRAYLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ERGANİ",
        "STREET": "HANÇERLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ERGANİ",
        "STREET": "HENDEKKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ERGANİ",
        "STREET": "HİLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ERGANİ",
        "STREET": "KARABURÇAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ERGANİ",
        "STREET": "KARPUZLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ERGANİ",
        "STREET": "KARŞIBAĞLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ERGANİ",
        "STREET": "KAVAKLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ERGANİ",
        "STREET": "KAVURMAKÜPÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ERGANİ",
        "STREET": "KAYAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ERGANİ",
        "STREET": "KEMALİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ERGANİ",
        "STREET": "KEMERTAŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ERGANİ",
        "STREET": "KESENTAŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ERGANİ",
        "STREET": "KIRALAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ERGANİ",
        "STREET": "KOCAALİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ERGANİ",
        "STREET": "KORTAŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ERGANİ",
        "STREET": "KOYUNALAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ERGANİ",
        "STREET": "KUMÇİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ERGANİ",
        "STREET": "KÖMÜRTAŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ERGANİ",
        "STREET": "MORKOYUN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ERGANİ",
        "STREET": "NAMIK KEMAL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ERGANİ",
        "STREET": "OLGUN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ERGANİ",
        "STREET": "ORTAAĞAÇ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ERGANİ",
        "STREET": "ORTAYAZI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ERGANİ",
        "STREET": "OTLUCA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ERGANİ",
        "STREET": "PINARKAYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ERGANİ",
        "STREET": "SABIRLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ERGANİ",
        "STREET": "SALLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ERGANİ",
        "STREET": "SALLICA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ERGANİ",
        "STREET": "SALİHLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ERGANİ",
        "STREET": "SANAYİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ERGANİ",
        "STREET": "SARAY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ERGANİ",
        "STREET": "SAVAŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ERGANİ",
        "STREET": "SELMANKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ERGANİ",
        "STREET": "SÖKÜNDÜZÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ERGANİ",
        "STREET": "TEVEKLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ERGANİ",
        "STREET": "USLUCA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ERGANİ",
        "STREET": "YAKACIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ERGANİ",
        "STREET": "YAMAÇLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ERGANİ",
        "STREET": "YAPRAKLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ERGANİ",
        "STREET": "YAYVANTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ERGANİ",
        "STREET": "YENİKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ERGANİ",
        "STREET": "YEŞİLKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ERGANİ",
        "STREET": "YOLBULAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ERGANİ",
        "STREET": "YOLKÖPRÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ERGANİ",
        "STREET": "YONCALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ERGANİ",
        "STREET": "YUKARIBİTİKÇİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ERGANİ",
        "STREET": "YUKARIKARPUZLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ERGANİ",
        "STREET": "YUKARIKUYULU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ERGANİ",
        "STREET": "ZİYARET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ERGANİ",
        "STREET": "ÇAKARTAŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ERGANİ",
        "STREET": "ÇAKIRFAKIR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ERGANİ",
        "STREET": "ÇAYIRDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ERGANİ",
        "STREET": "ÇAYKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ERGANİ",
        "STREET": "ÇUKURDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ERGANİ",
        "STREET": "ÇİMLİHÖYÜK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ERGANİ",
        "STREET": "ÖZBİLEK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ERGANİ",
        "STREET": "ÜZÜMLÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ERGANİ",
        "STREET": "ÜÇKARDEŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ERGANİ",
        "STREET": "İNCEHIDIR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ERGANİ",
        "STREET": "İSTASYON MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ERGANİ",
        "STREET": "ŞÖLEN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ERGANİ",
        "STREET": "ŞİRİNEVLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "EĞİL",
        "STREET": "AKALAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "EĞİL",
        "STREET": "BABALAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "EĞİL",
        "STREET": "BAHŞİLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "EĞİL",
        "STREET": "BALABAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "EĞİL",
        "STREET": "BALIM MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "EĞİL",
        "STREET": "BAYSU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "EĞİL",
        "STREET": "DERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "EĞİL",
        "STREET": "DÖŞEMELER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "EĞİL",
        "STREET": "DÜZLÜK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "EĞİL",
        "STREET": "GÜNDOĞURAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "EĞİL",
        "STREET": "GÜRÜNLÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "EĞİL",
        "STREET": "ILGIN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "EĞİL",
        "STREET": "KALE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "EĞİL",
        "STREET": "KALECİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "EĞİL",
        "STREET": "KALKAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "EĞİL",
        "STREET": "KAYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "EĞİL",
        "STREET": "KAZANLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "EĞİL",
        "STREET": "KIRKKUYU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "EĞİL",
        "STREET": "KONAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "EĞİL",
        "STREET": "MEŞELER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "EĞİL",
        "STREET": "OYALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "EĞİL",
        "STREET": "SARICA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "EĞİL",
        "STREET": "SARMAŞIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "EĞİL",
        "STREET": "SAĞLAM MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "EĞİL",
        "STREET": "SELMAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "EĞİL",
        "STREET": "TAŞDAM MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "EĞİL",
        "STREET": "TEPECİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "EĞİL",
        "STREET": "YATIR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "EĞİL",
        "STREET": "YENİŞEHİR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "EĞİL",
        "STREET": "YUKARIHAYDAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "HANİ",
        "STREET": "ABACILAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "HANİ",
        "STREET": "AKÇAYURT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "HANİ",
        "STREET": "ANIL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "HANİ",
        "STREET": "BELEN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "HANİ",
        "STREET": "DERELİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "HANİ",
        "STREET": "GÖMEÇ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "HANİ",
        "STREET": "GÜRBÜZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "HANİ",
        "STREET": "KALABA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "HANİ",
        "STREET": "KALEDİBİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "HANİ",
        "STREET": "KIRIM MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "HANİ",
        "STREET": "KUYULAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "HANİ",
        "STREET": "MERKEZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "HANİ",
        "STREET": "OKURKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "HANİ",
        "STREET": "SERENKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "HANİ",
        "STREET": "SERGEN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "HANİ",
        "STREET": "SOYLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "HANİ",
        "STREET": "SÜSLÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "HANİ",
        "STREET": "TOPÇULAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "HANİ",
        "STREET": "UZUNLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "HANİ",
        "STREET": "VEZİRİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "HANİ",
        "STREET": "YAYVAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "HANİ",
        "STREET": "YUKARITURALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "HANİ",
        "STREET": "ZİRVE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "HANİ",
        "STREET": "ÇARDAKLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "HANİ",
        "STREET": "ÇARŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "HANİ",
        "STREET": "ÇUKURKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "HAZRO",
        "STREET": "AĞARTI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "HAZRO",
        "STREET": "BAHÇE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "HAZRO",
        "STREET": "BAYIRDÜZÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "HAZRO",
        "STREET": "BAĞYURDU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "HAZRO",
        "STREET": "CAMİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "HAZRO",
        "STREET": "DADAŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "HAZRO",
        "STREET": "ELHUVAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "HAZRO",
        "STREET": "GEDİKALAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "HAZRO",
        "STREET": "GÖZLÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "HAZRO",
        "STREET": "HÜRRİYET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "HAZRO",
        "STREET": "KAVAKLIBOĞAZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "HAZRO",
        "STREET": "KIRKKAŞIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "HAZRO",
        "STREET": "KIRMATAŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "HAZRO",
        "STREET": "KOÇBABA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "HAZRO",
        "STREET": "KULAÇTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "HAZRO",
        "STREET": "MEŞEBAĞLARI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "HAZRO",
        "STREET": "MUTLUCA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "HAZRO",
        "STREET": "ORMANKAYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "HAZRO",
        "STREET": "REŞİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "HAZRO",
        "STREET": "SARIERİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "HAZRO",
        "STREET": "SARIÇANAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "HAZRO",
        "STREET": "TERDÖKEN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "HAZRO",
        "STREET": "TOPALAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "HAZRO",
        "STREET": "UZUNARGIT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "HAZRO",
        "STREET": "VARINCA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "HAZRO",
        "STREET": "YAZGI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "HAZRO",
        "STREET": "ÇÖKEKSU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "HAZRO",
        "STREET": "ÇİTLİBAHÇE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "HAZRO",
        "STREET": "ÜLGEN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "HAZRO",
        "STREET": "İNCEKAVAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "HAZRO",
        "STREET": "İŞKAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "KAYAPINAR",
        "STREET": "1 NOLU YÜKSEK GÜVENLİKLİ KAPALI CEZA İNFAZ KURUMU",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "KAYAPINAR",
        "STREET": "2 NOLU YÜKSEK GÜVENLİKLİ KAPALI CEZA İNFAZ KURUMU",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "KAYAPINAR",
        "STREET": "AVCISUYU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "KAYAPINAR",
        "STREET": "BARIŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "KAYAPINAR",
        "STREET": "BAYKARA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "KAYAPINAR",
        "STREET": "BENEKLİTAŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "KAYAPINAR",
        "STREET": "CANKATRAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "KAYAPINAR",
        "STREET": "CUMHURİYET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "KAYAPINAR",
        "STREET": "CÜCÜK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "KAYAPINAR",
        "STREET": "DİCLEKENT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "KAYAPINAR",
        "STREET": "DİYARBAKIR 1 NOLU T TİPİ KAPALI CEZAEVİ",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "KAYAPINAR",
        "STREET": "DİYARBAKIR 2 NOLU T TİPİ KAPALI CEZAEVİ",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "KAYAPINAR",
        "STREET": "DİYARBAKIR 3 NOLU T TİPİ KAPALI CEZAEVİ",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "KAYAPINAR",
        "STREET": "DİYARBAKIR 4 NOLU T TİPİ KAPALI CEZAEVİ",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "KAYAPINAR",
        "STREET": "DİYARBAKIR KADIN KAPALI CEZA İNFAZ KURUMU",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "KAYAPINAR",
        "STREET": "ESENTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "KAYAPINAR",
        "STREET": "FIRAT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "KAYAPINAR",
        "STREET": "GÖZALAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "KAYAPINAR",
        "STREET": "GÖZEGÖL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "KAYAPINAR",
        "STREET": "GÜLEÇOBA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "KAYAPINAR",
        "STREET": "HARMANARDI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "KAYAPINAR",
        "STREET": "HATİPOĞLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "KAYAPINAR",
        "STREET": "HUZUREVLERİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "KAYAPINAR",
        "STREET": "KALDIRIM MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "KAYAPINAR",
        "STREET": "KARAYAKUP MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "KAYAPINAR",
        "STREET": "KEKLİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "KAYAPINAR",
        "STREET": "KIRKPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "KAYAPINAR",
        "STREET": "MEDYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "KAYAPINAR",
        "STREET": "MEZOPOTAMYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "KAYAPINAR",
        "STREET": "PETEK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "KAYAPINAR",
        "STREET": "PEYAS MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "KAYAPINAR",
        "STREET": "SAĞKULAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "KAYAPINAR",
        "STREET": "SULTANTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "KAYAPINAR",
        "STREET": "TABAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "KAYAPINAR",
        "STREET": "TALAYTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "KAYAPINAR",
        "STREET": "TOSUNLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "KAYAPINAR",
        "STREET": "TİŞO MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "KAYAPINAR",
        "STREET": "UYANDIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "KAYAPINAR",
        "STREET": "YENİÖZERLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "KAYAPINAR",
        "STREET": "YOLBOYU PİRİNÇLİK  MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "KAYAPINAR",
        "STREET": "ÇOCUK VE GENÇLİK CEZAEVİ",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "KAYAPINAR",
        "STREET": "ÇÖLGÜZELİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "KOCAKÖY",
        "STREET": "AMBAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "KOCAKÖY",
        "STREET": "ARKBAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "KOCAKÖY",
        "STREET": "BOYUNLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "KOCAKÖY",
        "STREET": "BOZBAĞLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "KOCAKÖY",
        "STREET": "BOZYER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "KOCAKÖY",
        "STREET": "EYÜPLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "KOCAKÖY",
        "STREET": "GÖKÇEN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "KOCAKÖY",
        "STREET": "GÖZEBAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "KOCAKÖY",
        "STREET": "GÜNALAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "KOCAKÖY",
        "STREET": "HACI REŞİT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "KOCAKÖY",
        "STREET": "KAYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "KOCAKÖY",
        "STREET": "KOKULUPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "KOCAKÖY",
        "STREET": "SUÇIKTI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "KOCAKÖY",
        "STREET": "TEPECİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "KOCAKÖY",
        "STREET": "YAZIKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "KOCAKÖY",
        "STREET": "YENİŞEHİR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "KOCAKÖY",
        "STREET": "ÇAKMAKLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "KOCAKÖY",
        "STREET": "ÇAYIRLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "KOCAKÖY",
        "STREET": "ÇAYTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "KOCAKÖY",
        "STREET": "ŞAKLAT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "KOCAKÖY",
        "STREET": "ŞERİFOĞULLARI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "KOCAKÖY",
        "STREET": "ŞEYH ŞERAFETTİN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "KULP",
        "STREET": "AKBULAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "KULP",
        "STREET": "AKDORUK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "KULP",
        "STREET": "AKÇESİR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "KULP",
        "STREET": "ALACA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "KULP",
        "STREET": "ARGÜN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "KULP",
        "STREET": "AYGÜN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "KULP",
        "STREET": "AYHAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "KULP",
        "STREET": "AĞAÇKORUR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "KULP",
        "STREET": "AĞAÇLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "KULP",
        "STREET": "AĞILLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "KULP",
        "STREET": "BALOĞLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "KULP",
        "STREET": "BARIN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "KULP",
        "STREET": "BAYIR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "KULP",
        "STREET": "BAĞCILAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "KULP",
        "STREET": "BAŞBUĞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "KULP",
        "STREET": "DEMİRLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "KULP",
        "STREET": "DOLUN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "KULP",
        "STREET": "DÜZCE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "KULP",
        "STREET": "ELMALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "KULP",
        "STREET": "GÜLEÇ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "KULP",
        "STREET": "GÜLLÜK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "KULP",
        "STREET": "HAMZALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "KULP",
        "STREET": "KAMIŞLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "KULP",
        "STREET": "KARAAĞAÇ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "KULP",
        "STREET": "KARABULAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "KULP",
        "STREET": "KARAORMAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "KULP",
        "STREET": "KARPUZLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "KULP",
        "STREET": "KAYACIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "KULP",
        "STREET": "KAYAHAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "KULP",
        "STREET": "KAYNAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "KULP",
        "STREET": "KONUKLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "KULP",
        "STREET": "KOÇKAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "KULP",
        "STREET": "KURUDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "KULP",
        "STREET": "MERKEZ ESKİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "KULP",
        "STREET": "MERKEZ YENİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "KULP",
        "STREET": "NARLICA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "KULP",
        "STREET": "SALKIMLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "KULP",
        "STREET": "SALTUK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "KULP",
        "STREET": "TAŞKÖPRÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "KULP",
        "STREET": "TEMRAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "KULP",
        "STREET": "TEPECİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "KULP",
        "STREET": "TURGUT ÖZAL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "KULP",
        "STREET": "TUZLA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "KULP",
        "STREET": "UYGUR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "KULP",
        "STREET": "UZUNOVA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "KULP",
        "STREET": "YAKIT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "KULP",
        "STREET": "YAYIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "KULP",
        "STREET": "YAYLAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "KULP",
        "STREET": "YEŞİLKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "KULP",
        "STREET": "YUVACIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "KULP",
        "STREET": "ZEYREK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "KULP",
        "STREET": "ÇAĞLAYAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "KULP",
        "STREET": "ÇUKURCA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "KULP",
        "STREET": "ÖZBEK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "KULP",
        "STREET": "ÜNAL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "KULP",
        "STREET": "ÜÇKUYU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "KULP",
        "STREET": "İNKAYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "KULP",
        "STREET": "İSLAMKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "LİCE",
        "STREET": "AKÇABUDAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "LİCE",
        "STREET": "ARIKLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "LİCE",
        "STREET": "BAYIRLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "LİCE",
        "STREET": "BİRLİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "LİCE",
        "STREET": "CAMİKEBİR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "LİCE",
        "STREET": "DALLICA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "LİCE",
        "STREET": "DELVAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "LİCE",
        "STREET": "DERNEK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "LİCE",
        "STREET": "DURU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "LİCE",
        "STREET": "DİBEK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "LİCE",
        "STREET": "ECEMİŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "LİCE",
        "STREET": "GÖKÇE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "LİCE",
        "STREET": "GÜRBEYLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "LİCE",
        "STREET": "GÜÇLÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "LİCE",
        "STREET": "HEDİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "LİCE",
        "STREET": "KABAKAYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "LİCE",
        "STREET": "KALİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "LİCE",
        "STREET": "KARAHASAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "LİCE",
        "STREET": "KELVAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "LİCE",
        "STREET": "KILIÇLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "LİCE",
        "STREET": "KIPÇAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "LİCE",
        "STREET": "KIYI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "LİCE",
        "STREET": "KUMLUCA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "LİCE",
        "STREET": "KUTLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "LİCE",
        "STREET": "KÖRTÜK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "LİCE",
        "STREET": "MULLA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "LİCE",
        "STREET": "MURADİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "LİCE",
        "STREET": "MÜMİNAĞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "LİCE",
        "STREET": "OYUKLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "LİCE",
        "STREET": "SAVAT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "LİCE",
        "STREET": "SAYDAMLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "LİCE",
        "STREET": "SERİN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "LİCE",
        "STREET": "SIĞINAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "LİCE",
        "STREET": "TEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "LİCE",
        "STREET": "ULUCAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "LİCE",
        "STREET": "YALIMLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "LİCE",
        "STREET": "YAMAÇLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "LİCE",
        "STREET": "YAPRAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "LİCE",
        "STREET": "YENİŞEHİR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "LİCE",
        "STREET": "YEŞİLBURÇ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "LİCE",
        "STREET": "YOLÇATI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "LİCE",
        "STREET": "YÜNLÜCE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "LİCE",
        "STREET": "ZÜMRÜT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "LİCE",
        "STREET": "ZİYARET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "LİCE",
        "STREET": "ÇARŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "LİCE",
        "STREET": "ÇAĞDAŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "LİCE",
        "STREET": "ÇEPER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "LİCE",
        "STREET": "ÇIRALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "LİCE",
        "STREET": "ÖRTÜLÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "LİCE",
        "STREET": "ÜÇDAMLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "LİCE",
        "STREET": "ŞAAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "LİCE",
        "STREET": "ŞENLİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "SUR",
        "STREET": "ABDALDEDE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "SUR",
        "STREET": "ALABAL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "SUR",
        "STREET": "ALCIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "SUR",
        "STREET": "ALİ PAŞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "SUR",
        "STREET": "ALİBARDAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "SUR",
        "STREET": "ARPADERESİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "SUR",
        "STREET": "AĞAÇLIDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "SUR",
        "STREET": "BAHÇECİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "SUR",
        "STREET": "BAROĞLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "SUR",
        "STREET": "BAĞIVAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "SUR",
        "STREET": "BAĞPINAR  MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "SUR",
        "STREET": "BEYBULAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "SUR",
        "STREET": "BOSTANPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "SUR",
        "STREET": "BOZDEMİR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "SUR",
        "STREET": "BÜYÜKAKÖREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "SUR",
        "STREET": "BÜYÜKKADI  MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "SUR",
        "STREET": "CAMİ KEBİR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "SUR",
        "STREET": "CAMİ NEBİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "SUR",
        "STREET": "CEMAL YILMAZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "SUR",
        "STREET": "CEVAT PAŞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "SUR",
        "STREET": "DABANOĞLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "SUR",
        "STREET": "DERVİŞHASAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "SUR",
        "STREET": "DOĞANLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "SUR",
        "STREET": "DOĞU ÇANAKÇI  MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "SUR",
        "STREET": "DUMRUL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "SUR",
        "STREET": "ERYOLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "SUR",
        "STREET": "ERİMLİ  MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "SUR",
        "STREET": "ESENBAĞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "SUR",
        "STREET": "FATİHPAŞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "SUR",
        "STREET": "FETİH MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "SUR",
        "STREET": "FİDANLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "SUR",
        "STREET": "GENCAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "SUR",
        "STREET": "GÖLPINAR  MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "SUR",
        "STREET": "GÜLTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "SUR",
        "STREET": "HACIOSMAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "SUR",
        "STREET": "HARMANLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "SUR",
        "STREET": "HASIRLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "SUR",
        "STREET": "HAVACILAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "SUR",
        "STREET": "HIZIR İLYAS MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "SUR",
        "STREET": "KABASAKAL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "SUR",
        "STREET": "KAPAKLIPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "SUR",
        "STREET": "KARABAŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "SUR",
        "STREET": "KARACAÖREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "SUR",
        "STREET": "KARAÇALI  MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "SUR",
        "STREET": "KARAÇİMEN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "SUR",
        "STREET": "KARDEŞLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "SUR",
        "STREET": "KARPUZLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "SUR",
        "STREET": "KARPUZTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "SUR",
        "STREET": "KARTALTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "SUR",
        "STREET": "KAVAKLIBAĞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "SUR",
        "STREET": "KAYAYOLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "SUR",
        "STREET": "KENGERLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "SUR",
        "STREET": "KERVANPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "SUR",
        "STREET": "KIRMASIRT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "SUR",
        "STREET": "KONACIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "SUR",
        "STREET": "KOYUNGÜDEN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "SUR",
        "STREET": "KOZAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "SUR",
        "STREET": "KUMLUÇAT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "SUR",
        "STREET": "KUMRUCAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "SUR",
        "STREET": "KUŞBURNU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "SUR",
        "STREET": "KUŞLUKBAĞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "SUR",
        "STREET": "KÖPRÜBAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "SUR",
        "STREET": "KÖŞK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "SUR",
        "STREET": "KÜÇÜKAKÖREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "SUR",
        "STREET": "KÜÇÜKKADI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "SUR",
        "STREET": "LALEBEY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "SUR",
        "STREET": "MELİKAHMET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "SUR",
        "STREET": "MERMER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "SUR",
        "STREET": "MERMERİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "SUR",
        "STREET": "NEFİRTAŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "SUR",
        "STREET": "PINARDÜZÜ  MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "SUR",
        "STREET": "SAPANCA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "SUR",
        "STREET": "SARIKAMIŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "SUR",
        "STREET": "SARILAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "SUR",
        "STREET": "SARIYAZMA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "SUR",
        "STREET": "SATİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "SUR",
        "STREET": "SAVAŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "SUR",
        "STREET": "SAYARLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "SUR",
        "STREET": "SAĞDIÇLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "SUR",
        "STREET": "SOĞANLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "SUR",
        "STREET": "SÜLEYMAN NAZİF MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "SUR",
        "STREET": "SÜNGÜLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "SUR",
        "STREET": "TANOĞLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "SUR",
        "STREET": "TEZGEÇER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "SUR",
        "STREET": "YARIMCA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "SUR",
        "STREET": "YENİCE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "SUR",
        "STREET": "YENİDOĞAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "SUR",
        "STREET": "YENİEVLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "SUR",
        "STREET": "YEŞİLKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "SUR",
        "STREET": "YEŞİLLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "SUR",
        "STREET": "YUKARIKILIÇTAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "SUR",
        "STREET": "YİĞİTÇAVUŞ  MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "SUR",
        "STREET": "ZİYA GÖKALP MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "SUR",
        "STREET": "ZİYARET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "SUR",
        "STREET": "ÇARIKLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "SUR",
        "STREET": "ÇATAKSU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "SUR",
        "STREET": "ÇELİKLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "SUR",
        "STREET": "ÇUBUKLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "SUR",
        "STREET": "ÖZEKLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "SUR",
        "STREET": "İSKENDERPAŞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "SİLVAN",
        "STREET": "AKDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "SİLVAN",
        "STREET": "AKYOL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "SİLVAN",
        "STREET": "AKÇAYIR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "SİLVAN",
        "STREET": "AKÇELTİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "SİLVAN",
        "STREET": "ALTINKUM MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "SİLVAN",
        "STREET": "ALİBEY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "SİLVAN",
        "STREET": "ARI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "SİLVAN",
        "STREET": "AŞAĞIKAYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "SİLVAN",
        "STREET": "AŞAĞIVEYSİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "SİLVAN",
        "STREET": "BABAKAYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "SİLVAN",
        "STREET": "BAHÇE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "SİLVAN",
        "STREET": "BAHÇELİEVLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "SİLVAN",
        "STREET": "BAYRAMBAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "SİLVAN",
        "STREET": "BAĞDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "SİLVAN",
        "STREET": "BAĞLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "SİLVAN",
        "STREET": "BAŞDEĞİRMEN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "SİLVAN",
        "STREET": "BAŞIBÜYÜK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "SİLVAN",
        "STREET": "BELLİBAHÇE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "SİLVAN",
        "STREET": "BEREKETLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "SİLVAN",
        "STREET": "BEYPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "SİLVAN",
        "STREET": "BOYUNLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "SİLVAN",
        "STREET": "CAMİİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "SİLVAN",
        "STREET": "DARKÖPRÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "SİLVAN",
        "STREET": "DAĞCILAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "SİLVAN",
        "STREET": "DEMİRKUYU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "SİLVAN",
        "STREET": "DOLAPDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "SİLVAN",
        "STREET": "DOLUÇANAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "SİLVAN",
        "STREET": "DURU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "SİLVAN",
        "STREET": "DÜZALAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "SİLVAN",
        "STREET": "ERİKYAZI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "SİLVAN",
        "STREET": "ESKİKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "SİLVAN",
        "STREET": "ESKİOCAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "SİLVAN",
        "STREET": "EŞME MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "SİLVAN",
        "STREET": "FERİDUN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "SİLVAN",
        "STREET": "GÖKÇETEVEK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "SİLVAN",
        "STREET": "GÖRENTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "SİLVAN",
        "STREET": "GÖRMEZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "SİLVAN",
        "STREET": "GÜNDÜZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "SİLVAN",
        "STREET": "GÜRPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "SİLVAN",
        "STREET": "GÜZDERESİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "SİLVAN",
        "STREET": "GÜÇLÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "SİLVAN",
        "STREET": "HEYBELİKONAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "SİLVAN",
        "STREET": "KALE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "SİLVAN",
        "STREET": "KARACALAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "SİLVAN",
        "STREET": "KARAHACI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "SİLVAN",
        "STREET": "KARAMUS MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "SİLVAN",
        "STREET": "KASIMLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "SİLVAN",
        "STREET": "KAYADERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "SİLVAN",
        "STREET": "KAZANDAĞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "SİLVAN",
        "STREET": "KEKLİKDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "SİLVAN",
        "STREET": "KIRAÇTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "SİLVAN",
        "STREET": "KIZLAL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "SİLVAN",
        "STREET": "KONAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "SİLVAN",
        "STREET": "KUMGÖLÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "SİLVAN",
        "STREET": "KUMLUK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "SİLVAN",
        "STREET": "KUTLUALAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "SİLVAN",
        "STREET": "MALABADİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "SİLVAN",
        "STREET": "MESCİT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "SİLVAN",
        "STREET": "NOHUTTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "SİLVAN",
        "STREET": "ONBAŞILAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "SİLVAN",
        "STREET": "ORMANDIŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "SİLVAN",
        "STREET": "OTLUK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "SİLVAN",
        "STREET": "SARIBUĞDAY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "SİLVAN",
        "STREET": "SAĞLIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "SİLVAN",
        "STREET": "SELAHATTİN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "SİLVAN",
        "STREET": "SULAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "SİLVAN",
        "STREET": "SULUBAĞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "SİLVAN",
        "STREET": "SUSUZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "SİLVAN",
        "STREET": "TAŞPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "SİLVAN",
        "STREET": "TEKEL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "SİLVAN",
        "STREET": "TOKLUCA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "SİLVAN",
        "STREET": "UMUR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "SİLVAN",
        "STREET": "YENİDOĞAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "SİLVAN",
        "STREET": "YENİKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "SİLVAN",
        "STREET": "YENİŞEHİR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "SİLVAN",
        "STREET": "YEŞERDİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "SİLVAN",
        "STREET": "YEŞİLBAHÇE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "SİLVAN",
        "STREET": "YEŞİLKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "SİLVAN",
        "STREET": "YOLARASI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "SİLVAN",
        "STREET": "YOLAÇ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "SİLVAN",
        "STREET": "YUKARIVEYSİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "SİLVAN",
        "STREET": "YUVA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "SİLVAN",
        "STREET": "YÜKSEK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "SİLVAN",
        "STREET": "ÇAKILTAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "SİLVAN",
        "STREET": "ÇALDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "SİLVAN",
        "STREET": "ÇARDAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "SİLVAN",
        "STREET": "ÇEVRİKSU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "SİLVAN",
        "STREET": "ÇOBANTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "SİLVAN",
        "STREET": "ÇİĞDEMLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "SİLVAN",
        "STREET": "ÇİĞİL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "SİLVAN",
        "STREET": "ÜÇBASAMAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "SİLVAN",
        "STREET": "İNCESU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "SİLVAN",
        "STREET": "ŞANLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "YENİŞEHİR",
        "STREET": "AL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "YENİŞEHİR",
        "STREET": "ALANGÖR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "YENİŞEHİR",
        "STREET": "ALPU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "YENİŞEHİR",
        "STREET": "AZİZİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "YENİŞEHİR",
        "STREET": "BAHÇELİEVLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "YENİŞEHİR",
        "STREET": "BAŞİL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "YENİŞEHİR",
        "STREET": "BOZEK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "YENİŞEHİR",
        "STREET": "CUMHURİYET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "YENİŞEHİR",
        "STREET": "DOKUZÇELTİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "YENİŞEHİR",
        "STREET": "DÖKMETAŞ  MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "YENİŞEHİR",
        "STREET": "DÖNÜMLÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "YENİŞEHİR",
        "STREET": "DİCLE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "YENİŞEHİR",
        "STREET": "DİKENTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "YENİŞEHİR",
        "STREET": "EKİNCİLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "YENİŞEHİR",
        "STREET": "ELİDOLU  MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "YENİŞEHİR",
        "STREET": "ESER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "YENİŞEHİR",
        "STREET": "FABRİKA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "YENİŞEHİR",
        "STREET": "FERİT KÖŞK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "YENİŞEHİR",
        "STREET": "GEYİKTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "YENİŞEHİR",
        "STREET": "GÜRDOĞAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "YENİŞEHİR",
        "STREET": "GÜVENDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "YENİŞEHİR",
        "STREET": "GÜVERCİNLİK  MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "YENİŞEHİR",
        "STREET": "GÜZELKÖY  MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "YENİŞEHİR",
        "STREET": "HANTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "YENİŞEHİR",
        "STREET": "KESİKAĞAÇ  MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "YENİŞEHİR",
        "STREET": "KOOPERATİFLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "YENİŞEHİR",
        "STREET": "SANCAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "YENİŞEHİR",
        "STREET": "SARIYATAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "YENİŞEHİR",
        "STREET": "SEYRANTEPE SANAYİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "YENİŞEHİR",
        "STREET": "SİVRİTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "YENİŞEHİR",
        "STREET": "TANIŞIK  MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "YENİŞEHİR",
        "STREET": "YAYTAŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "YENİŞEHİR",
        "STREET": "YENİŞEHİR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "YENİŞEHİR",
        "STREET": "YOLALTI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "YENİŞEHİR",
        "STREET": "YUKARI NASIRLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "YENİŞEHİR",
        "STREET": "YÜKSEK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "YENİŞEHİR",
        "STREET": "ÇAKMAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "YENİŞEHİR",
        "STREET": "ÇELİKEVLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "YENİŞEHİR",
        "STREET": "ÇİMENLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "YENİŞEHİR",
        "STREET": "ÜÇKUYU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "YENİŞEHİR",
        "STREET": "İLBAŞ  MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "YENİŞEHİR",
        "STREET": "ŞEHİTLİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ÇERMİK",
        "STREET": "AKKOYUNLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ÇERMİK",
        "STREET": "AKPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ÇERMİK",
        "STREET": "AKÇÖRTEN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ÇERMİK",
        "STREET": "ALABUĞDAY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ÇERMİK",
        "STREET": "ALAKOÇ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ÇERMİK",
        "STREET": "ARABÜK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ÇERMİK",
        "STREET": "ARMAĞANTAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ÇERMİK",
        "STREET": "ARMUTLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ÇERMİK",
        "STREET": "ARTUK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ÇERMİK",
        "STREET": "ASMALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ÇERMİK",
        "STREET": "AYNALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ÇERMİK",
        "STREET": "AĞAÇHAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ÇERMİK",
        "STREET": "AŞAĞITAŞMALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ÇERMİK",
        "STREET": "AŞAĞIŞEYHLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ÇERMİK",
        "STREET": "BADEMLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ÇERMİK",
        "STREET": "BAHÇE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ÇERMİK",
        "STREET": "BALIKSIRTI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ÇERMİK",
        "STREET": "BAYAT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ÇERMİK",
        "STREET": "BAYIRBAĞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ÇERMİK",
        "STREET": "BAYKAL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ÇERMİK",
        "STREET": "BAYRAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ÇERMİK",
        "STREET": "BAŞARI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ÇERMİK",
        "STREET": "BULUNDU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ÇERMİK",
        "STREET": "BİNTAŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ÇERMİK",
        "STREET": "BİRCEMAL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ÇERMİK",
        "STREET": "CEYLAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ÇERMİK",
        "STREET": "DEĞİRMENLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ÇERMİK",
        "STREET": "DİKYOL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ÇERMİK",
        "STREET": "DİLEKPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ÇERMİK",
        "STREET": "ELİFUŞAĞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ÇERMİK",
        "STREET": "ESKİBAĞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ÇERMİK",
        "STREET": "GENCELİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ÇERMİK",
        "STREET": "GÖKTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ÇERMİK",
        "STREET": "GÖZEREK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ÇERMİK",
        "STREET": "GÜNAŞAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ÇERMİK",
        "STREET": "GÜRÜZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ÇERMİK",
        "STREET": "GÜZEL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ÇERMİK",
        "STREET": "GÜÇLÜTAŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ÇERMİK",
        "STREET": "HABURMAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ÇERMİK",
        "STREET": "KALA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ÇERMİK",
        "STREET": "KALAÇ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ÇERMİK",
        "STREET": "KALECİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ÇERMİK",
        "STREET": "KARACAVİRAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ÇERMİK",
        "STREET": "KARAKAYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ÇERMİK",
        "STREET": "KARAMUSA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ÇERMİK",
        "STREET": "KARATAŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ÇERMİK",
        "STREET": "KARTALTAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ÇERMİK",
        "STREET": "KAYAGEDİĞİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ÇERMİK",
        "STREET": "KEKLİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ÇERMİK",
        "STREET": "KIRMATEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ÇERMİK",
        "STREET": "KONAKLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ÇERMİK",
        "STREET": "KONUKSEVER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ÇERMİK",
        "STREET": "KORUDAĞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ÇERMİK",
        "STREET": "KUYU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ÇERMİK",
        "STREET": "KUŞLUKÇAYIRI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ÇERMİK",
        "STREET": "KÖKSAL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ÇERMİK",
        "STREET": "KÖMÜRCÜLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ÇERMİK",
        "STREET": "PAMUKLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ÇERMİK",
        "STREET": "PETEKKAYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ÇERMİK",
        "STREET": "PINARLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ÇERMİK",
        "STREET": "RECEP MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ÇERMİK",
        "STREET": "SALTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ÇERMİK",
        "STREET": "SARAY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ÇERMİK",
        "STREET": "SARIBALTA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ÇERMİK",
        "STREET": "SARICA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ÇERMİK",
        "STREET": "SİNEK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ÇERMİK",
        "STREET": "TEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ÇERMİK",
        "STREET": "TOPLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ÇERMİK",
        "STREET": "YABANARDI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ÇERMİK",
        "STREET": "YAYIKLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ÇERMİK",
        "STREET": "YAYLACIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ÇERMİK",
        "STREET": "YEŞİLOVA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ÇERMİK",
        "STREET": "YOĞUN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ÇERMİK",
        "STREET": "YİĞİTLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ÇERMİK",
        "STREET": "ÇALITEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ÇERMİK",
        "STREET": "ÇUKUR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ÇERMİK",
        "STREET": "ÇUKURELMA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ÇERMİK",
        "STREET": "ÖRENKUYU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ÇERMİK",
        "STREET": "İKİÇELTİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ÇERMİK",
        "STREET": "İNCİLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ÇERMİK",
        "STREET": "ŞEYHANDEDE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ÇINAR",
        "STREET": "AKTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ÇINAR",
        "STREET": "AKÇOMAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ÇINAR",
        "STREET": "ALABAŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ÇINAR",
        "STREET": "ALANCIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ÇINAR",
        "STREET": "ALTINAKAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ÇINAR",
        "STREET": "ARAFAT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ÇINAR",
        "STREET": "AVDALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ÇINAR",
        "STREET": "AYVERİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ÇINAR",
        "STREET": "AĞAÇSEVER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ÇINAR",
        "STREET": "AŞAĞI MOLLAALİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ÇINAR",
        "STREET": "AŞAĞIKONAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ÇINAR",
        "STREET": "BALLIBABA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ÇINAR",
        "STREET": "BAYIRKONAĞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ÇINAR",
        "STREET": "BAĞACIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ÇINAR",
        "STREET": "BAŞAKLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ÇINAR",
        "STREET": "BAŞALAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ÇINAR",
        "STREET": "BELENLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ÇINAR",
        "STREET": "BELLİTAŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ÇINAR",
        "STREET": "BENEKLİTAŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ÇINAR",
        "STREET": "BEŞPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ÇINAR",
        "STREET": "BOZÇALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ÇINAR",
        "STREET": "BOĞAZÖREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ÇINAR",
        "STREET": "BULUTÇEKER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ÇINAR",
        "STREET": "BİLMECE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ÇINAR",
        "STREET": "BİRA MEHMET AĞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ÇINAR",
        "STREET": "CUMHURİYET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ÇINAR",
        "STREET": "DEMİRÖLÇEK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ÇINAR",
        "STREET": "DÜZOVA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ÇINAR",
        "STREET": "DÜĞRÜK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ÇINAR",
        "STREET": "DİKMENCİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ÇINAR",
        "STREET": "DİŞLİBAŞAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ÇINAR",
        "STREET": "EKİNVEREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ÇINAR",
        "STREET": "ESKİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ÇINAR",
        "STREET": "FATİH MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ÇINAR",
        "STREET": "FİLİZÖREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ÇINAR",
        "STREET": "GAZİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ÇINAR",
        "STREET": "GÖKTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ÇINAR",
        "STREET": "GÖRECE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ÇINAR",
        "STREET": "GÜMÜŞTAŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ÇINAR",
        "STREET": "GÜRSES MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ÇINAR",
        "STREET": "HALIÖREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ÇINAR",
        "STREET": "HALKAPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ÇINAR",
        "STREET": "HARABE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ÇINAR",
        "STREET": "HASKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ÇINAR",
        "STREET": "HÖYÜKDİBİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ÇINAR",
        "STREET": "KARABABA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ÇINAR",
        "STREET": "KARABUDAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ÇINAR",
        "STREET": "KARALAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ÇINAR",
        "STREET": "KARASUNGUR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ÇINAR",
        "STREET": "KARAÇEVRE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ÇINAR",
        "STREET": "KAZIKTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ÇINAR",
        "STREET": "KILIÇKAYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ÇINAR",
        "STREET": "KIRKAĞAÇ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ÇINAR",
        "STREET": "KUBACIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ÇINAR",
        "STREET": "KURUYAZI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ÇINAR",
        "STREET": "KUTLUK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ÇINAR",
        "STREET": "KUYULUHÖYÜK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ÇINAR",
        "STREET": "KÖKSALAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ÇINAR",
        "STREET": "KÜREKLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ÇINAR",
        "STREET": "LEBLEBİTAŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ÇINAR",
        "STREET": "MEYDANKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ÇINAR",
        "STREET": "MURATCIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ÇINAR",
        "STREET": "ORTAŞAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ÇINAR",
        "STREET": "OVABAĞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ÇINAR",
        "STREET": "PEMBEVİRAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ÇINAR",
        "STREET": "SELYAZI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ÇINAR",
        "STREET": "SEVİNDİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ÇINAR",
        "STREET": "SIRIMKESER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ÇINAR",
        "STREET": "SOLMAZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ÇINAR",
        "STREET": "SOĞANSUYU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ÇINAR",
        "STREET": "SÜRENDAL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ÇINAR",
        "STREET": "TAŞHELVASI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ÇINAR",
        "STREET": "TEKKAYNAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ÇINAR",
        "STREET": "TORAMAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ÇINAR",
        "STREET": "TİLVER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ÇINAR",
        "STREET": "YAPRAKBAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ÇINAR",
        "STREET": "YARIMKAŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ÇINAR",
        "STREET": "YAZÇİÇEĞİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ÇINAR",
        "STREET": "YENİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ÇINAR",
        "STREET": "YEŞİL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ÇINAR",
        "STREET": "YEŞİLBAĞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ÇINAR",
        "STREET": "YEŞİLTAŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ÇINAR",
        "STREET": "YILLARCA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ÇINAR",
        "STREET": "YUKARIORTAÖREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ÇINAR",
        "STREET": "YUVACIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ÇINAR",
        "STREET": "ÇAKIRKAYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ÇINAR",
        "STREET": "ÇAKIRTUTMAZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ÇINAR",
        "STREET": "ÇATALTARLA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ÇINAR",
        "STREET": "ÇATMADAL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ÇINAR",
        "STREET": "ÇELTİKALTI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ÇINAR",
        "STREET": "ÇINARKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ÇINAR",
        "STREET": "ÇUKURBAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ÇINAR",
        "STREET": "ÇÖMÇELİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ÇINAR",
        "STREET": "ÖNCÜLÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ÇINAR",
        "STREET": "ÖZGİDER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ÇINAR",
        "STREET": "ÖZYAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ÇINAR",
        "STREET": "İNANÖZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ÇINAR",
        "STREET": "İNCİRTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ÇINAR",
        "STREET": "ŞEKERÖREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ÇINAR",
        "STREET": "ŞEYHÇOBAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ÇINAR",
        "STREET": "ŞÜKÜRLÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ÇÜNGÜŞ",
        "STREET": "AKBAŞAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ÇÜNGÜŞ",
        "STREET": "AKTAŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ÇÜNGÜŞ",
        "STREET": "ALBAYRAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ÇÜNGÜŞ",
        "STREET": "ARPADERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ÇÜNGÜŞ",
        "STREET": "ATALAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ÇÜNGÜŞ",
        "STREET": "AVUT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ÇÜNGÜŞ",
        "STREET": "AYDINLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ÇÜNGÜŞ",
        "STREET": "BALCILAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ÇÜNGÜŞ",
        "STREET": "CAMİİ KEBİR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ÇÜNGÜŞ",
        "STREET": "CAMİİSUK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ÇÜNGÜŞ",
        "STREET": "DEVEBOYNU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ÇÜNGÜŞ",
        "STREET": "DEĞİRMENSUYU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ÇÜNGÜŞ",
        "STREET": "ELMADERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ÇÜNGÜŞ",
        "STREET": "ELYOS MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ÇÜNGÜŞ",
        "STREET": "GÖKÇEPELİT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ÇÜNGÜŞ",
        "STREET": "GÜNEYDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ÇÜNGÜŞ",
        "STREET": "HANDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ÇÜNGÜŞ",
        "STREET": "HOYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ÇÜNGÜŞ",
        "STREET": "HİNDİBABA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ÇÜNGÜŞ",
        "STREET": "KARAKAYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ÇÜNGÜŞ",
        "STREET": "KARŞIYAKA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ÇÜNGÜŞ",
        "STREET": "KAYNAKKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ÇÜNGÜŞ",
        "STREET": "KELEŞEVLERİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ÇÜNGÜŞ",
        "STREET": "KOÇÖREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ÇÜNGÜŞ",
        "STREET": "NORŞİN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ÇÜNGÜŞ",
        "STREET": "ORMANÇAYI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ÇÜNGÜŞ",
        "STREET": "OYUKLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ÇÜNGÜŞ",
        "STREET": "POLATUŞAĞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ÇÜNGÜŞ",
        "STREET": "SAĞTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ÇÜNGÜŞ",
        "STREET": "SEFERUŞAĞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ÇÜNGÜŞ",
        "STREET": "TÜRKMEN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ÇÜNGÜŞ",
        "STREET": "YAYGINKONAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ÇÜNGÜŞ",
        "STREET": "YAZYAĞMURU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ÇÜNGÜŞ",
        "STREET": "YENİCE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ÇÜNGÜŞ",
        "STREET": "YENİKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ÇÜNGÜŞ",
        "STREET": "YUKARIŞEYHLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ÇÜNGÜŞ",
        "STREET": "ÇATALDUT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ÇÜNGÜŞ",
        "STREET": "ÇAYBAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ÇÜNGÜŞ",
        "STREET": "ÜÇPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "DİYARBAKIR",
        "DISTRICT": "ÇÜNGÜŞ",
        "STREET": "İBİKKAYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "MERKEZ",
        "STREET": "1.MURAT MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "MERKEZ",
        "STREET": "ABDURRAHMAN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "MERKEZ",
        "STREET": "AHI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "MERKEZ",
        "STREET": "AVARIZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "MERKEZ",
        "STREET": "BABADEMİRTAŞ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "MERKEZ",
        "STREET": "BARUTLUK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "MERKEZ",
        "STREET": "BOSNA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "MERKEZ",
        "STREET": "BUDAKDOĞANCA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "MERKEZ",
        "STREET": "BÜYÜKDÖLLÜK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "MERKEZ",
        "STREET": "BÜYÜKİSMAİLÇE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "MERKEZ",
        "STREET": "DEMİRHANLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "MERKEZ",
        "STREET": "DEĞİRMENYENİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "MERKEZ",
        "STREET": "DOYRAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "MERKEZ",
        "STREET": "DİLAVERBEY MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "MERKEZ",
        "STREET": "EDİRNE F TİPİ YÜKSEK GÜVENLİKLİ KAPALI CEZA İNFAZ ",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "MERKEZ",
        "STREET": "EDİRNE L TİPİ KAPALI CEZA İNFAZ KURUMU",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "MERKEZ",
        "STREET": "EKMEKÇİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "MERKEZ",
        "STREET": "ELÇİLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "MERKEZ",
        "STREET": "ESKİKADIN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "MERKEZ",
        "STREET": "FATİH MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "MERKEZ",
        "STREET": "HACIUMUR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "MERKEZ",
        "STREET": "HASANAĞA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "MERKEZ",
        "STREET": "HATİP KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "MERKEZ",
        "STREET": "HIDIRAĞA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "MERKEZ",
        "STREET": "KARAAĞAÇ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "MERKEZ",
        "STREET": "KARABULUT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "MERKEZ",
        "STREET": "KARAKASIM KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "MERKEZ",
        "STREET": "KARAYUSUF KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "MERKEZ",
        "STREET": "KAYAPA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "MERKEZ",
        "STREET": "KEMAL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "MERKEZ",
        "STREET": "KOCA SİNAN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "MERKEZ",
        "STREET": "KORUCU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "MERKEZ",
        "STREET": "KÖŞENÇİFTLİĞİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "MERKEZ",
        "STREET": "KÜÇÜKDÖLLÜK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "MERKEZ",
        "STREET": "MEDRESE ALİ BEY MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "MERKEZ",
        "STREET": "MENEKŞESOFULAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "MERKEZ",
        "STREET": "MENZİLAHIR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "MERKEZ",
        "STREET": "MEYDAN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "MERKEZ",
        "STREET": "MURATÇALI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "MERKEZ",
        "STREET": "MUSABEYLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "MERKEZ",
        "STREET": "MİTHAT PAŞA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "MERKEZ",
        "STREET": "NİŞANCIPAŞA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "MERKEZ",
        "STREET": "ORHANİYE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "MERKEZ",
        "STREET": "SABUNİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "MERKEZ",
        "STREET": "SARAYAKPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "MERKEZ",
        "STREET": "SARICAPAŞA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "MERKEZ",
        "STREET": "SAZLIDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "MERKEZ",
        "STREET": "SUAKACAĞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "MERKEZ",
        "STREET": "TALATPAŞA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "MERKEZ",
        "STREET": "TAYAKADIN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "MERKEZ",
        "STREET": "UMURBEY MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "MERKEZ",
        "STREET": "UZGAÇ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "MERKEZ",
        "STREET": "YANCIKÇI ŞAHİN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "MERKEZ",
        "STREET": "YENİKADIN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "MERKEZ",
        "STREET": "YENİİMARET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "MERKEZ",
        "STREET": "YILDIRIM BEYAZIT MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "MERKEZ",
        "STREET": "YILDIRIM HACI SARRAF MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "MERKEZ",
        "STREET": "YOLÜSTÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇAVUŞBEY MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "MERKEZ",
        "STREET": "ÜYÜKLÜTATAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "MERKEZ",
        "STREET": "İSKENDER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "MERKEZ",
        "STREET": "İSTASYON MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "MERKEZ",
        "STREET": "ŞÜKRÜPAŞA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "ENEZ",
        "STREET": "ABDURRAHİM KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "ENEZ",
        "STREET": "BÜYÜKEVREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "ENEZ",
        "STREET": "GAZİÖMERBEY MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "ENEZ",
        "STREET": "GÜLÇAVUŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "ENEZ",
        "STREET": "HASKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "ENEZ",
        "STREET": "HİSARLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "ENEZ",
        "STREET": "IŞIKLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "ENEZ",
        "STREET": "KARAİNCİRLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "ENEZ",
        "STREET": "KOCAALİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "ENEZ",
        "STREET": "KÜÇÜKEVREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "ENEZ",
        "STREET": "SULTANİÇE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "ENEZ",
        "STREET": "SÜTÇÜLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "ENEZ",
        "STREET": "UMURBEY KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "ENEZ",
        "STREET": "VAKIF KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "ENEZ",
        "STREET": "YAZIR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "ENEZ",
        "STREET": "YENİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "ENEZ",
        "STREET": "YENİCE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "ENEZ",
        "STREET": "ÇANDIR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "ENEZ",
        "STREET": "ÇATALTEPE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "ENEZ",
        "STREET": "ÇAVUŞKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "ENEZ",
        "STREET": "ÇERİBAŞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "ENEZ",
        "STREET": "ŞEHİTLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "HAVSA",
        "STREET": "ABALAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "HAVSA",
        "STREET": "ARPAÇ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "HAVSA",
        "STREET": "AZATLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "HAVSA",
        "STREET": "BAKIŞLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "HAVSA",
        "STREET": "BOSTANLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "HAVSA",
        "STREET": "CUMHURİYET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "HAVSA",
        "STREET": "HABİLLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "HAVSA",
        "STREET": "HACIGAZİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "HAVSA",
        "STREET": "HACIİSA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "HAVSA",
        "STREET": "HASKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "HAVSA",
        "STREET": "HELVACI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "HAVSA",
        "STREET": "KABAAĞAÇ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "HAVSA",
        "STREET": "KULUBALIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "HAVSA",
        "STREET": "KUZUCU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "HAVSA",
        "STREET": "KÖSEÖMER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "HAVSA",
        "STREET": "MUSULCA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "HAVSA",
        "STREET": "NAİPYUSUF KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "HAVSA",
        "STREET": "NECATİYE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "HAVSA",
        "STREET": "OSMANLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "HAVSA",
        "STREET": "OĞULPAŞA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "HAVSA",
        "STREET": "SÖĞÜTLÜDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "HAVSA",
        "STREET": "TAHAL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "HAVSA",
        "STREET": "TAPTIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "HAVSA",
        "STREET": "VAROŞ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "HAVSA",
        "STREET": "YENİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "HAVSA",
        "STREET": "YOLAGELDİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "HAVSA",
        "STREET": "ÇUKURKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "HAVSA",
        "STREET": "ŞERBETTAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "KEŞAN",
        "STREET": "AKHOCA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "KEŞAN",
        "STREET": "AKÇEŞME KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "KEŞAN",
        "STREET": "ALTINTAŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "KEŞAN",
        "STREET": "AŞAĞI ZAFERİYE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "KEŞAN",
        "STREET": "BAHÇEKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "KEŞAN",
        "STREET": "BARAĞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "KEŞAN",
        "STREET": "BEYKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "KEŞAN",
        "STREET": "BEĞENDİK-AYDOĞAN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "KEŞAN",
        "STREET": "BEĞENDİK-BEYKENT MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "KEŞAN",
        "STREET": "BEĞENDİK-KEMALPAŞA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "KEŞAN",
        "STREET": "BEĞENDİK-YENİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "KEŞAN",
        "STREET": "BOZTEPE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "KEŞAN",
        "STREET": "BÜYÜK CAMİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "KEŞAN",
        "STREET": "BÜYÜKDOĞANCA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "KEŞAN",
        "STREET": "CUMHURİYET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "KEŞAN",
        "STREET": "DANİŞMENT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "KEŞAN",
        "STREET": "DİŞBUDAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "KEŞAN",
        "STREET": "ERİKLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "KEŞAN",
        "STREET": "GÖKÇETEPE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "KEŞAN",
        "STREET": "GÜNDÜZLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "KEŞAN",
        "STREET": "KADIKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "KEŞAN",
        "STREET": "KARACAALİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "KEŞAN",
        "STREET": "KARAHİSAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "KEŞAN",
        "STREET": "KARASATI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "KEŞAN",
        "STREET": "KARLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "KEŞAN",
        "STREET": "KILIÇKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "KEŞAN",
        "STREET": "KIZKAPAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "KEŞAN",
        "STREET": "KORUKLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "KEŞAN",
        "STREET": "KOZKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "KEŞAN",
        "STREET": "KÜÇÜKDOĞANCA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "KEŞAN",
        "STREET": "LALACIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "KEŞAN",
        "STREET": "MAHMUTKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "KEŞAN",
        "STREET": "MALTEPE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "KEŞAN",
        "STREET": "MECİDİYE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "KEŞAN",
        "STREET": "MERCAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "KEŞAN",
        "STREET": "MUSTAFA KEMAL PAŞA  MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "KEŞAN",
        "STREET": "ORHANİYE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "KEŞAN",
        "STREET": "PAŞAYİĞİT MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "KEŞAN",
        "STREET": "PIRNAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "KEŞAN",
        "STREET": "SAZLIDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "KEŞAN",
        "STREET": "SEYDİKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "KEŞAN",
        "STREET": "SULUCA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "KEŞAN",
        "STREET": "SİĞİLLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "KEŞAN",
        "STREET": "TÜRKMEN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "KEŞAN",
        "STREET": "YAYLAKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "KEŞAN",
        "STREET": "YENİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "KEŞAN",
        "STREET": "YENİCEÇİFTLİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "KEŞAN",
        "STREET": "YENİMESCİT MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "KEŞAN",
        "STREET": "YENİMUHACİR-ATATÜRK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "KEŞAN",
        "STREET": "YENİMUHACİR-CUMHURİYET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "KEŞAN",
        "STREET": "YENİMUHACİR-İNÖNÜ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "KEŞAN",
        "STREET": "YENİMUHACİR-İSTİKLAL MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "KEŞAN",
        "STREET": "YERLİSU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "KEŞAN",
        "STREET": "YEŞİLKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "KEŞAN",
        "STREET": "YUKARI ZAFERİYE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "KEŞAN",
        "STREET": "ÇAMLICA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "KEŞAN",
        "STREET": "ÇELEBİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "KEŞAN",
        "STREET": "ÇELTİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "KEŞAN",
        "STREET": "ÇOBANÇEŞMESİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "KEŞAN",
        "STREET": "İSPAT CAMİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "KEŞAN",
        "STREET": "İSTASYON MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "KEŞAN",
        "STREET": "İZZETİYE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "KEŞAN",
        "STREET": "ŞABANMERA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "KEŞAN",
        "STREET": "ŞÜKRÜKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "LALAPAŞA",
        "STREET": "BÜYÜNLÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "LALAPAŞA",
        "STREET": "DEMİRKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "LALAPAŞA",
        "STREET": "DOMBAY KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "LALAPAŞA",
        "STREET": "DOĞANKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "LALAPAŞA",
        "STREET": "HACIDANİŞMENT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "LALAPAŞA",
        "STREET": "HACILAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "LALAPAŞA",
        "STREET": "HAMZABEYLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "LALAPAŞA",
        "STREET": "HANLIYENİCE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "LALAPAŞA",
        "STREET": "HÜSEYİNPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "LALAPAŞA",
        "STREET": "KALKANSÖĞÜT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "LALAPAŞA",
        "STREET": "KAVAKLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "LALAPAŞA",
        "STREET": "KÜÇÜNLÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "LALAPAŞA",
        "STREET": "MERKEZ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "LALAPAŞA",
        "STREET": "ORTAKÇI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "LALAPAŞA",
        "STREET": "SAKSAĞAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "LALAPAŞA",
        "STREET": "SARIDANİŞMENT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "LALAPAŞA",
        "STREET": "SÜLEYMANDANİŞMENT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "LALAPAŞA",
        "STREET": "SİNANKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "LALAPAŞA",
        "STREET": "TAŞLIMÜSELLİM KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "LALAPAŞA",
        "STREET": "TUĞLALIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "LALAPAŞA",
        "STREET": "UZUNBAYIR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "LALAPAŞA",
        "STREET": "VAYSAL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "LALAPAŞA",
        "STREET": "YÜNLÜCE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "LALAPAŞA",
        "STREET": "ÇALLIDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "LALAPAŞA",
        "STREET": "ÇATMA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "LALAPAŞA",
        "STREET": "ÇÖMLEK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "LALAPAŞA",
        "STREET": "ÇÖMLEKAKPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "LALAPAŞA",
        "STREET": "ÖMEROBA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "MERİÇ",
        "STREET": "ADASARHANLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "MERİÇ",
        "STREET": "AKINCILAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "MERİÇ",
        "STREET": "AKÇADAM KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "MERİÇ",
        "STREET": "ALİBEY KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "MERİÇ",
        "STREET": "B.DOĞANCA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "MERİÇ",
        "STREET": "BÜYÜKALTIAĞAÇ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "MERİÇ",
        "STREET": "HASIRCIARNAVUTKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "MERİÇ",
        "STREET": "K. DOĞANCA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "MERİÇ",
        "STREET": "KADIDONDURMA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "MERİÇ",
        "STREET": "KARAHAMZA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "MERİÇ",
        "STREET": "KARAYUSUFLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "MERİÇ",
        "STREET": "KAVAKLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "MERİÇ",
        "STREET": "KÜPDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "MERİÇ",
        "STREET": "KÜPLÜ-GAZİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "MERİÇ",
        "STREET": "KÜPLÜ-MERKEZ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "MERİÇ",
        "STREET": "KÜÇÜKALTIAĞAÇ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "MERİÇ",
        "STREET": "NASUHBEY KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "MERİÇ",
        "STREET": "OLACAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "MERİÇ",
        "STREET": "PAŞAYENİCE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "MERİÇ",
        "STREET": "RAHMANCA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "MERİÇ",
        "STREET": "SAATAĞACI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "MERİÇ",
        "STREET": "SEREM KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "MERİÇ",
        "STREET": "SUBAŞI-İZZET PAŞA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "MERİÇ",
        "STREET": "SUBAŞI-ŞEHİT MEHMET AYDIN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "MERİÇ",
        "STREET": "UMURCA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "MERİÇ",
        "STREET": "YAKUPBEY KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "MERİÇ",
        "STREET": "YENİCEGÖRÜCE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "SÜLOĞLU",
        "STREET": "AKARDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "SÜLOĞLU",
        "STREET": "BÜYÜK GERDELLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "SÜLOĞLU",
        "STREET": "CUMHURİYET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "SÜLOĞLU",
        "STREET": "DOMURCALI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "SÜLOĞLU",
        "STREET": "GEÇKİNLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "SÜLOĞLU",
        "STREET": "KERAMETTİN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "SÜLOĞLU",
        "STREET": "KÜKÜLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "SÜLOĞLU",
        "STREET": "MERKEZ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "SÜLOĞLU",
        "STREET": "SÜLECİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "SÜLOĞLU",
        "STREET": "TATARLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "SÜLOĞLU",
        "STREET": "TAŞLISEKBAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "SÜLOĞLU",
        "STREET": "YAĞCILI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "SÜLOĞLU",
        "STREET": "YENİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "SÜLOĞLU",
        "STREET": "ŞAFAK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "UZUNKÖPRÜ",
        "STREET": "ALIÇ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "UZUNKÖPRÜ",
        "STREET": "ALTINYAZI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "UZUNKÖPRÜ",
        "STREET": "ASLIHAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "UZUNKÖPRÜ",
        "STREET": "ATATÜRK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "UZUNKÖPRÜ",
        "STREET": "AŞÇIOĞLU MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "UZUNKÖPRÜ",
        "STREET": "BALABAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "UZUNKÖPRÜ",
        "STREET": "BALABANKORU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "UZUNKÖPRÜ",
        "STREET": "BAYRAMLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "UZUNKÖPRÜ",
        "STREET": "BAŞAĞIL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "UZUNKÖPRÜ",
        "STREET": "BEYKONAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "UZUNKÖPRÜ",
        "STREET": "BILDIR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "UZUNKÖPRÜ",
        "STREET": "BÜYÜK ŞEHSUVARBEY MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "UZUNKÖPRÜ",
        "STREET": "CUMHURİYET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "UZUNKÖPRÜ",
        "STREET": "DANİŞMENT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "UZUNKÖPRÜ",
        "STREET": "DEMİRTAŞ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "UZUNKÖPRÜ",
        "STREET": "DEREKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "UZUNKÖPRÜ",
        "STREET": "DEĞİRMENCİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "UZUNKÖPRÜ",
        "STREET": "ELMALI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "UZUNKÖPRÜ",
        "STREET": "ESKİKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "UZUNKÖPRÜ",
        "STREET": "GAZİHALİL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "UZUNKÖPRÜ",
        "STREET": "GAZİMEHMET KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "UZUNKÖPRÜ",
        "STREET": "GEMİCİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "UZUNKÖPRÜ",
        "STREET": "HABİB HOCA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "UZUNKÖPRÜ",
        "STREET": "HALİSE HATUN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "UZUNKÖPRÜ",
        "STREET": "HAMİDİYE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "UZUNKÖPRÜ",
        "STREET": "HAMİTLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "UZUNKÖPRÜ",
        "STREET": "HARMANLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "UZUNKÖPRÜ",
        "STREET": "HASANPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "UZUNKÖPRÜ",
        "STREET": "KADIKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "UZUNKÖPRÜ",
        "STREET": "KARABÜRÇEK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "UZUNKÖPRÜ",
        "STREET": "KARAPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "UZUNKÖPRÜ",
        "STREET": "KARAYAYLA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "UZUNKÖPRÜ",
        "STREET": "KAVACIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "UZUNKÖPRÜ",
        "STREET": "KAVAK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "UZUNKÖPRÜ",
        "STREET": "KAVAKAYAZMA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "UZUNKÖPRÜ",
        "STREET": "KIRCASALİH-BALABAN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "UZUNKÖPRÜ",
        "STREET": "KIRCASALİH-CUMHURİYET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "UZUNKÖPRÜ",
        "STREET": "KIRCASALİH-OKULLAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "UZUNKÖPRÜ",
        "STREET": "KIRCASALİH-İSTİKLAL MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "UZUNKÖPRÜ",
        "STREET": "KIRKKAVAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "UZUNKÖPRÜ",
        "STREET": "KIRKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "UZUNKÖPRÜ",
        "STREET": "KURDU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "UZUNKÖPRÜ",
        "STREET": "KURTBEY KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "UZUNKÖPRÜ",
        "STREET": "KURTTEPE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "UZUNKÖPRÜ",
        "STREET": "KÜÇÜK ŞEHSUVARBEY MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "UZUNKÖPRÜ",
        "STREET": "KİREMİTÇİSALİH KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "UZUNKÖPRÜ",
        "STREET": "MAKSUTLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "UZUNKÖPRÜ",
        "STREET": "MALKOÇ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "UZUNKÖPRÜ",
        "STREET": "MAREŞAL FEVZİ ÇAKMAK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "UZUNKÖPRÜ",
        "STREET": "MESCİT MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "UZUNKÖPRÜ",
        "STREET": "MEŞELİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "UZUNKÖPRÜ",
        "STREET": "MUHACIRKADI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "UZUNKÖPRÜ",
        "STREET": "MURADİYE CAMİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "UZUNKÖPRÜ",
        "STREET": "MURADİYE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "UZUNKÖPRÜ",
        "STREET": "RIZAEFENDİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "UZUNKÖPRÜ",
        "STREET": "SALARLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "UZUNKÖPRÜ",
        "STREET": "SAZLIMALKOÇ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "UZUNKÖPRÜ",
        "STREET": "SAÇLIMÜSELLİM KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "UZUNKÖPRÜ",
        "STREET": "SIĞIRCILI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "UZUNKÖPRÜ",
        "STREET": "SULTANŞAH KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "UZUNKÖPRÜ",
        "STREET": "SÜLEYMANİYE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "UZUNKÖPRÜ",
        "STREET": "SİPAHİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "UZUNKÖPRÜ",
        "STREET": "TURNACI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "UZUNKÖPRÜ",
        "STREET": "TÜRKOBASI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "UZUNKÖPRÜ",
        "STREET": "YAĞMURCA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "UZUNKÖPRÜ",
        "STREET": "YENİKÖY MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "UZUNKÖPRÜ",
        "STREET": "ÇAKMAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "UZUNKÖPRÜ",
        "STREET": "ÇALI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "UZUNKÖPRÜ",
        "STREET": "ÇAVUŞLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "UZUNKÖPRÜ",
        "STREET": "ÇOBANPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "UZUNKÖPRÜ",
        "STREET": "ÇÖPKÖY MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "UZUNKÖPRÜ",
        "STREET": "ÇİFTLİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "UZUNKÖPRÜ",
        "STREET": "ÖMERBEY KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "İPSALA",
        "STREET": "ALİÇOPEHLİVAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "İPSALA",
        "STREET": "BALABANCIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "İPSALA",
        "STREET": "BAYRAMBEY MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "İPSALA",
        "STREET": "BOZKURT MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "İPSALA",
        "STREET": "ESETÇE-ATATÜRK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "İPSALA",
        "STREET": "ESETÇE-CUMHURİYET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "İPSALA",
        "STREET": "ESETÇE-KURTULUŞ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "İPSALA",
        "STREET": "FATİH MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "İPSALA",
        "STREET": "HACI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "İPSALA",
        "STREET": "HIDIRKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "İPSALA",
        "STREET": "KAPUCU MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "İPSALA",
        "STREET": "KARAAĞAÇ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "İPSALA",
        "STREET": "KOCAHIDIR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "İPSALA",
        "STREET": "KORUCU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "İPSALA",
        "STREET": "KOYUNTEPE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "İPSALA",
        "STREET": "KUMDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "İPSALA",
        "STREET": "KÖPRÜ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "İPSALA",
        "STREET": "KÜÇÜKDOĞANCA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "İPSALA",
        "STREET": "PAZARDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "İPSALA",
        "STREET": "PAŞAKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "İPSALA",
        "STREET": "SARAÇ İLYAS MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "İPSALA",
        "STREET": "SARICAALİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "İPSALA",
        "STREET": "SARPDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "İPSALA",
        "STREET": "SULTAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "İPSALA",
        "STREET": "TEVFİKİYE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "İPSALA",
        "STREET": "TURPÇULAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "İPSALA",
        "STREET": "YAPILDAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "İPSALA",
        "STREET": "YENİKARPUZLU-FEVZİ ÇAKMAK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "İPSALA",
        "STREET": "YENİKARPUZLU-GAZİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "İPSALA",
        "STREET": "YENİKARPUZLU-İNÖNÜ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "EDİRNE",
        "DISTRICT": "İPSALA",
        "STREET": "İBRİKTEPE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "ALACAKAYA",
        "STREET": "ALTINÇANAK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "ALACAKAYA",
        "STREET": "ALTIOLUK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "ALACAKAYA",
        "STREET": "BAKLADAMLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "ALACAKAYA",
        "STREET": "ESENLİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "ALACAKAYA",
        "STREET": "GÜLAMAN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "ALACAKAYA",
        "STREET": "GÜNDOĞDU MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "ALACAKAYA",
        "STREET": "GÜRÇUBUK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "ALACAKAYA",
        "STREET": "HALKALI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "ALACAKAYA",
        "STREET": "KAYRANLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "ALACAKAYA",
        "STREET": "MERKEZ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "ALACAKAYA",
        "STREET": "SULARBAŞI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "ALACAKAYA",
        "STREET": "YALNIZDAMLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "ALACAKAYA",
        "STREET": "ÇAKMAKKAYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "ALACAKAYA",
        "STREET": "ÇANAKÇA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "ALACAKAYA",
        "STREET": "ÇATAKLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "ALACAKAYA",
        "STREET": "İNCEBAYIR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "ARICAK",
        "STREET": "BOZÇAVUŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "ARICAK",
        "STREET": "BÜKARDI-BÖLÜKBAŞI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "ARICAK",
        "STREET": "BÜKARDI-KAPLANBAĞ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "ARICAK",
        "STREET": "BÜKARDI-PINARBAŞI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "ARICAK",
        "STREET": "BÜKARDI-YAYLABAŞI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "ARICAK",
        "STREET": "BÜKARDI-ZEYNET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "ARICAK",
        "STREET": "ERBAĞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "ARICAK",
        "STREET": "ERİMLİ-CAMİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "ARICAK",
        "STREET": "ERİMLİ-CUMHURİYET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "ARICAK",
        "STREET": "ERİMLİ-GÜLEÇ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "ARICAK",
        "STREET": "ERİMLİ-GÜVENEVLER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "ARICAK",
        "STREET": "ERİMLİ-KIŞLA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "ARICAK",
        "STREET": "GÖRÜNDÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "ARICAK",
        "STREET": "GÜMÜŞYAKA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "ARICAK",
        "STREET": "HALİL YAVUZ  MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "ARICAK",
        "STREET": "HÜRRİYET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "ARICAK",
        "STREET": "KAMBERTEPE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "ARICAK",
        "STREET": "KARAKAŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "ARICAK",
        "STREET": "KÜPLÜCE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "ARICAK",
        "STREET": "MUSTAFA ÇELEBİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "ARICAK",
        "STREET": "ORMANPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "ARICAK",
        "STREET": "SAMAN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "ARICAK",
        "STREET": "SERİNEVLER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "ARICAK",
        "STREET": "SİMSERKİS MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "ARICAK",
        "STREET": "VALİ GÖKTAYOĞLU  MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "ARICAK",
        "STREET": "YOĞUNBİLEK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "ARICAK",
        "STREET": "ÇAVUŞDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "ARICAK",
        "STREET": "ÇEVRECİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "ARICAK",
        "STREET": "ÜÇOCAK-HABERCİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "ARICAK",
        "STREET": "ÜÇOCAK-KUTLU DÜĞÜN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "ARICAK",
        "STREET": "ÜÇOCAK-MERKEZ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "ARICAK",
        "STREET": "ÜÇOCAK-ZİYARET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "ARICAK",
        "STREET": "ÜÇOCAK-ÇAY MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "AĞIN",
        "STREET": "AKPINAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "AĞIN",
        "STREET": "ALTINAYVA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "AĞIN",
        "STREET": "AŞAĞIYABANLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "AĞIN",
        "STREET": "BADEMLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "AĞIN",
        "STREET": "BAHADIRLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "AĞIN",
        "STREET": "BALKAYASI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "AĞIN",
        "STREET": "BAŞPINAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "AĞIN",
        "STREET": "BEYELMASI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "AĞIN",
        "STREET": "DEMİRÇARIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "AĞIN",
        "STREET": "DİBEKLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "AĞIN",
        "STREET": "KAŞPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "AĞIN",
        "STREET": "MERKEZ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "AĞIN",
        "STREET": "MODANLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "AĞIN",
        "STREET": "PULKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "AĞIN",
        "STREET": "SAMANÇAY KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "AĞIN",
        "STREET": "SARAYCIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "AĞIN",
        "STREET": "YEDİBAĞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "AĞIN",
        "STREET": "YENİPAYAM KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "AĞIN",
        "STREET": "ÖĞRENDİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "AĞIN",
        "STREET": "ŞENPINAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "BASKİL",
        "STREET": "AKDEMİR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "BASKİL",
        "STREET": "AKUŞAĞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "BASKİL",
        "STREET": "ALADİKME KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "BASKİL",
        "STREET": "ALANGÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "BASKİL",
        "STREET": "ALTINUŞAĞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "BASKİL",
        "STREET": "AŞAĞIKULUŞAĞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "BASKİL",
        "STREET": "BEŞBÖLÜK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "BASKİL",
        "STREET": "BOZOĞLAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "BASKİL",
        "STREET": "BİLALUŞAĞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "BASKİL",
        "STREET": "CANBEYLER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "BASKİL",
        "STREET": "CUMHURİYET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "BASKİL",
        "STREET": "DELİKTAŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "BASKİL",
        "STREET": "DEMİRLİBAHÇE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "BASKİL",
        "STREET": "DOĞANCIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "BASKİL",
        "STREET": "DÜĞÜNTEPE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "BASKİL",
        "STREET": "EMİRHAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "BASKİL",
        "STREET": "ESKİBASKİL MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "BASKİL",
        "STREET": "ESKİKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "BASKİL",
        "STREET": "GEMİCİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "BASKİL",
        "STREET": "HABİBUŞAĞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "BASKİL",
        "STREET": "HACIHÜSEYİNLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "BASKİL",
        "STREET": "HACIMEHMETLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "BASKİL",
        "STREET": "HACIMUSTAFAKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "BASKİL",
        "STREET": "HACIUŞAĞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "BASKİL",
        "STREET": "HARABEKAYIŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "BASKİL",
        "STREET": "HÜYÜKKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "BASKİL",
        "STREET": "IŞIKLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "BASKİL",
        "STREET": "KADIKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "BASKİL",
        "STREET": "KARAALİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "BASKİL",
        "STREET": "KARAGEDİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "BASKİL",
        "STREET": "KARAKAŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "BASKİL",
        "STREET": "KAROĞLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "BASKİL",
        "STREET": "KAYABEYLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "BASKİL",
        "STREET": "KIZILUŞAĞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "BASKİL",
        "STREET": "KONACIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "BASKİL",
        "STREET": "KONALGA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "BASKİL",
        "STREET": "KOÇYOLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "BASKİL",
        "STREET": "KUMLUTARLA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "BASKİL",
        "STREET": "KUTLUGÜN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "BASKİL",
        "STREET": "KUŞSARAYI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "BASKİL",
        "STREET": "MEYDANCIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "BASKİL",
        "STREET": "MUSTAFA DEMİREL MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "BASKİL",
        "STREET": "ODABAŞI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "BASKİL",
        "STREET": "PAŞAKONAĞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "BASKİL",
        "STREET": "PINARLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "BASKİL",
        "STREET": "RESULKAHYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "BASKİL",
        "STREET": "SARITAŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "BASKİL",
        "STREET": "SUYATAĞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "BASKİL",
        "STREET": "SÖĞÜTDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "BASKİL",
        "STREET": "TATLIPAYAM KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "BASKİL",
        "STREET": "TAVŞANUŞAĞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "BASKİL",
        "STREET": "TOPALUŞAĞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "BASKİL",
        "STREET": "YALINDAM KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "BASKİL",
        "STREET": "YAYLANLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "BASKİL",
        "STREET": "YENİOCAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "BASKİL",
        "STREET": "YILDIZLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "BASKİL",
        "STREET": "YUKARIKULUŞAĞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "BASKİL",
        "STREET": "ÇAVUŞLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "BASKİL",
        "STREET": "ÇİĞDEMLİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "BASKİL",
        "STREET": "İMİKUŞAĞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "BASKİL",
        "STREET": "İÇLİKAVAL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "BASKİL",
        "STREET": "ŞAHAPLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "BASKİL",
        "STREET": "ŞAHİNDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "BASKİL",
        "STREET": "ŞEFKAT MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "BASKİL",
        "STREET": "ŞEYH HASAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "BASKİL",
        "STREET": "ŞİTUŞAĞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "MERKEZ",
        "STREET": "ABDULLAH PAŞA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "MERKEZ",
        "STREET": "ACIPAYAM KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "MERKEZ",
        "STREET": "AKPINAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "MERKEZ",
        "STREET": "AKSARAY MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "MERKEZ",
        "STREET": "AKÇAKALE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "MERKEZ",
        "STREET": "AKÇAKİRAZ-BAHÇEKAPI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "MERKEZ",
        "STREET": "AKÇAKİRAZ-HÜRRİYET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "MERKEZ",
        "STREET": "AKÇAKİRAZ-KIRAÇ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "MERKEZ",
        "STREET": "AKÇAKİRAZ-KÜLTÜR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "MERKEZ",
        "STREET": "AKÇAKİRAZ-PAŞALAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "MERKEZ",
        "STREET": "AKÇAKİRAZ-YENİCE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "MERKEZ",
        "STREET": "AKÇAKİRAZ-YEŞİLKUŞAK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "MERKEZ",
        "STREET": "AKÇAKİRAZ-YEŞİLYURT MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "MERKEZ",
        "STREET": "AKÇAKİRAZ-YÜZBAŞI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "MERKEZ",
        "STREET": "AKÇAKİRAZ-ZAFER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "MERKEZ",
        "STREET": "ALA YAPRAK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "MERKEZ",
        "STREET": "ALACA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "MERKEZ",
        "STREET": "ALATARLA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "MERKEZ",
        "STREET": "ALPAĞUT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "MERKEZ",
        "STREET": "ALTINKUŞAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "MERKEZ",
        "STREET": "ARINDIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "MERKEZ",
        "STREET": "ATAŞEHİR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "MERKEZ",
        "STREET": "AVCILI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "MERKEZ",
        "STREET": "AYDINCIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "MERKEZ",
        "STREET": "AYDINLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "MERKEZ",
        "STREET": "AŞAĞIDEMİRTAŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "MERKEZ",
        "STREET": "BADEMPINARI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "MERKEZ",
        "STREET": "BALIBEY KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "MERKEZ",
        "STREET": "BALLICA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "MERKEZ",
        "STREET": "BALPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "MERKEZ",
        "STREET": "BAĞDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "MERKEZ",
        "STREET": "BAĞLARCA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "MERKEZ",
        "STREET": "BEYDALI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "MERKEZ",
        "STREET": "BEYDOĞMUŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "MERKEZ",
        "STREET": "BEŞOLUK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "MERKEZ",
        "STREET": "BEŞİKKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "MERKEZ",
        "STREET": "BULUTLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "MERKEZ",
        "STREET": "BÖLÜKLÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "MERKEZ",
        "STREET": "CEVİZDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "MERKEZ",
        "STREET": "CUMHURİYET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "MERKEZ",
        "STREET": "CİPKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "MERKEZ",
        "STREET": "DALLICA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "MERKEZ",
        "STREET": "DAMBÜYÜK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "MERKEZ",
        "STREET": "DEDEPINARI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "MERKEZ",
        "STREET": "DEREBOĞAZI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "MERKEZ",
        "STREET": "DEĞİRMENÖNÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "MERKEZ",
        "STREET": "DOĞANKUŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "MERKEZ",
        "STREET": "DOĞU KENT MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "MERKEZ",
        "STREET": "DURUPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "MERKEZ",
        "STREET": "ELAZIĞ 1 NOLU YÜKSEK GÜVENLİKLİ CEZA İNFAZ KURUMU",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "MERKEZ",
        "STREET": "ELAZIĞ 2 NOLU YÜKSEK GÜVENLİKLİ CEZA İNFAZ KURUMU",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "MERKEZ",
        "STREET": "ELAZIĞ E TİPİ KAPALI CEZAEVİ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "MERKEZ",
        "STREET": "ELAZIĞ T TİPİ KAPALI CEZA İNFAZ KURUMU",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "MERKEZ",
        "STREET": "ELMAPINARI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "MERKEZ",
        "STREET": "ERBİLDİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "MERKEZ",
        "STREET": "ESEN TEPE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "MERKEZ",
        "STREET": "ESENKENT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "MERKEZ",
        "STREET": "FATMALI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "MERKEZ",
        "STREET": "FEVZİ ÇAKMAK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "MERKEZ",
        "STREET": "GEDİKYOLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "MERKEZ",
        "STREET": "GÖKÇE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "MERKEZ",
        "STREET": "GÖLARDI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "MERKEZ",
        "STREET": "GÖLKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "MERKEZ",
        "STREET": "GÖLLÜ BAĞ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "MERKEZ",
        "STREET": "GÖZEBAŞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "MERKEZ",
        "STREET": "GÖZPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "MERKEZ",
        "STREET": "GÜLPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "MERKEZ",
        "STREET": "GÜMÜŞ KAVAK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "MERKEZ",
        "STREET": "GÜMÜŞBAĞLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "MERKEZ",
        "STREET": "GÜNAÇTI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "MERKEZ",
        "STREET": "GÜNBAĞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "MERKEZ",
        "STREET": "GÜNEYKENT MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "MERKEZ",
        "STREET": "GÜNEYÇAYIRI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "MERKEZ",
        "STREET": "GÜZELYALI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "MERKEZ",
        "STREET": "HAL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "MERKEZ",
        "STREET": "HANKENDİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "MERKEZ",
        "STREET": "HARMANTEPE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "MERKEZ",
        "STREET": "HARPUT MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "MERKEZ",
        "STREET": "HIDIRBABA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "MERKEZ",
        "STREET": "HOŞKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "MERKEZ",
        "STREET": "HİCRET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "MERKEZ",
        "STREET": "HİLALKENT MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "MERKEZ",
        "STREET": "IŞIKYOLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "MERKEZ",
        "STREET": "KALKANTEPE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "MERKEZ",
        "STREET": "KAPLIKAYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "MERKEZ",
        "STREET": "KARAALİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "MERKEZ",
        "STREET": "KARASAZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "MERKEZ",
        "STREET": "KARATAŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "MERKEZ",
        "STREET": "KARAÇAVUŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "MERKEZ",
        "STREET": "KARŞIYAKA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "MERKEZ",
        "STREET": "KAVAKPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "MERKEZ",
        "STREET": "KAVAKTEPE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "MERKEZ",
        "STREET": "KELMAHMUT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "MERKEZ",
        "STREET": "KEPEKTAŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "MERKEZ",
        "STREET": "KIRAÇKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "MERKEZ",
        "STREET": "KIRKLAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "MERKEZ",
        "STREET": "KIZILAY MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "MERKEZ",
        "STREET": "KONAKALMAZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "MERKEZ",
        "STREET": "KOPARUŞAĞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "MERKEZ",
        "STREET": "KORUCU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "MERKEZ",
        "STREET": "KORUKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "MERKEZ",
        "STREET": "KOZLUK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "MERKEZ",
        "STREET": "KOÇHARMANI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "MERKEZ",
        "STREET": "KOÇKALE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "MERKEZ",
        "STREET": "KUMLA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "MERKEZ",
        "STREET": "KURTDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "MERKEZ",
        "STREET": "KUYULU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "MERKEZ",
        "STREET": "KUŞHANE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "MERKEZ",
        "STREET": "KÖRPE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "MERKEZ",
        "STREET": "KÜLLÜK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "MERKEZ",
        "STREET": "KÜLTÜR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "MERKEZ",
        "STREET": "MEŞELİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "MERKEZ",
        "STREET": "MOLLAKENDİ-GÜNTAŞI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "MERKEZ",
        "STREET": "MOLLAKENDİ-KARŞIBAĞ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "MERKEZ",
        "STREET": "MOLLAKENDİ-MERKEZ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "MERKEZ",
        "STREET": "MURATCIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "MERKEZ",
        "STREET": "MUSTAFA PAŞA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "MERKEZ",
        "STREET": "NAİL BEY MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "MERKEZ",
        "STREET": "NURALI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "MERKEZ",
        "STREET": "OBUZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "MERKEZ",
        "STREET": "OLGUNLAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "MERKEZ",
        "STREET": "ORTAÇALI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "MERKEZ",
        "STREET": "OYMAAĞAÇ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "MERKEZ",
        "STREET": "PELTEKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "MERKEZ",
        "STREET": "POYRAZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "MERKEZ",
        "STREET": "PİRİNÇÇİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "MERKEZ",
        "STREET": "RIZAİYE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "MERKEZ",
        "STREET": "RÜSTEM PAŞA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "MERKEZ",
        "STREET": "SAFRAN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "MERKEZ",
        "STREET": "SAKABAŞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "MERKEZ",
        "STREET": "SALI BABA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "MERKEZ",
        "STREET": "SALKAYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "MERKEZ",
        "STREET": "SANAYİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "MERKEZ",
        "STREET": "SANCAKLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "MERKEZ",
        "STREET": "SARAYATİK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "MERKEZ",
        "STREET": "SARIBÜK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "MERKEZ",
        "STREET": "SARIGÜL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "MERKEZ",
        "STREET": "SARIKAMIŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "MERKEZ",
        "STREET": "SARILI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "MERKEZ",
        "STREET": "SARIYAKUP KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "MERKEZ",
        "STREET": "SARIÇUBUK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "MERKEZ",
        "STREET": "SEDEFTEPE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "MERKEZ",
        "STREET": "SERİNCE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "MERKEZ",
        "STREET": "SUGÖZÜ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "MERKEZ",
        "STREET": "SULTANUŞAĞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "MERKEZ",
        "STREET": "SÜNKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "MERKEZ",
        "STREET": "SÜRSÜRÜ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "MERKEZ",
        "STREET": "SÜTLÜCE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "MERKEZ",
        "STREET": "SİNANKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "MERKEZ",
        "STREET": "TADIM KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "MERKEZ",
        "STREET": "TEMÜRKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "MERKEZ",
        "STREET": "TEPEKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "MERKEZ",
        "STREET": "TOHUMLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "MERKEZ",
        "STREET": "ULU KENT MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "MERKEZ",
        "STREET": "UZUNTARLA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "MERKEZ",
        "STREET": "YALINDAMLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "MERKEZ",
        "STREET": "YALNIZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "MERKEZ",
        "STREET": "YAZIKONAK-CUMHURİYET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "MERKEZ",
        "STREET": "YAZIKONAK-FATİH MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "MERKEZ",
        "STREET": "YAZIKONAK-KONAK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "MERKEZ",
        "STREET": "YAZIKONAK-MERKEZ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "MERKEZ",
        "STREET": "YAZIKONAK-SANAYİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "MERKEZ",
        "STREET": "YAZIKONAK-YENİ BAĞLAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "MERKEZ",
        "STREET": "YAZIPINARI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "MERKEZ",
        "STREET": "YEDİGÖZE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "MERKEZ",
        "STREET": "YEMİŞLİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "MERKEZ",
        "STREET": "YENİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "MERKEZ",
        "STREET": "YENİKAPI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "MERKEZ",
        "STREET": "YENİKONAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "MERKEZ",
        "STREET": "YILDIZ BAĞLARI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "MERKEZ",
        "STREET": "YOLÇATI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "MERKEZ",
        "STREET": "YOLÜSTÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "MERKEZ",
        "STREET": "YUKARIDEMİRTAŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "MERKEZ",
        "STREET": "YUKARIÇAKMAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "MERKEZ",
        "STREET": "YURTBAŞI-AKMEZRA SAHİL ÖNÜ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "MERKEZ",
        "STREET": "YURTBAŞI-BAĞLAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "MERKEZ",
        "STREET": "YURTBAŞI-CUMHURİYET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "MERKEZ",
        "STREET": "YURTBAŞI-GÜLPINAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "MERKEZ",
        "STREET": "YURTBAŞI-ORG. EŞREF BİTLİS  MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "MERKEZ",
        "STREET": "YURTBAŞI-SANAYİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "MERKEZ",
        "STREET": "YURTBAŞI-YENİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "MERKEZ",
        "STREET": "YÜNLÜCE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "MERKEZ",
        "STREET": "YÜREKLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇALICA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇARŞI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇATAL ÇEŞME MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇATALHARMAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇAYDAÇIRA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇAĞLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇÖTELİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "MERKEZ",
        "STREET": "ÖKSÜZUŞAĞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "MERKEZ",
        "STREET": "ÖRENÇAY KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "MERKEZ",
        "STREET": "ÜNİVERSİTE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "MERKEZ",
        "STREET": "ÜRÜNVEREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "MERKEZ",
        "STREET": "ÜÇAĞAÇ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "MERKEZ",
        "STREET": "İCADİYE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "MERKEZ",
        "STREET": "İKİTEPE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "MERKEZ",
        "STREET": "İZZET PAŞA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "MERKEZ",
        "STREET": "İÇME KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "MERKEZ",
        "STREET": "ŞABANLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "MERKEZ",
        "STREET": "ŞAHAPLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "MERKEZ",
        "STREET": "ŞAHİNKAYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "MERKEZ",
        "STREET": "ŞEHSUVAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "MERKEZ",
        "STREET": "ŞEYHHACI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "KARAKOÇAN",
        "STREET": "AKARBAŞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "KARAKOÇAN",
        "STREET": "AKBULAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "KARAKOÇAN",
        "STREET": "AKKUŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "KARAKOÇAN",
        "STREET": "AKPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "KARAKOÇAN",
        "STREET": "AKYOKUŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "KARAKOÇAN",
        "STREET": "AKÇİÇEK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "KARAKOÇAN",
        "STREET": "ALABAL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "KARAKOÇAN",
        "STREET": "ALAYAĞMUR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "KARAKOÇAN",
        "STREET": "ALTINOLUK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "KARAKOÇAN",
        "STREET": "AĞAMEZRASI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "KARAKOÇAN",
        "STREET": "AŞAĞIOVACIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "KARAKOÇAN",
        "STREET": "BAHÇECİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "KARAKOÇAN",
        "STREET": "BAHÇELİEVLER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "KARAKOÇAN",
        "STREET": "BALCALI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "KARAKOÇAN",
        "STREET": "BARDAKLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "KARAKOÇAN",
        "STREET": "BAZLAMA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "KARAKOÇAN",
        "STREET": "BAĞLIAĞAÇ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "KARAKOÇAN",
        "STREET": "BAŞYURT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "KARAKOÇAN",
        "STREET": "BEYDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "KARAKOÇAN",
        "STREET": "BULGURCUK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "KARAKOÇAN",
        "STREET": "CUMHURİYET KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "KARAKOÇAN",
        "STREET": "DEMİRDELEN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "KARAKOÇAN",
        "STREET": "DEMİRTAŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "KARAKOÇAN",
        "STREET": "DEMİRTEPE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "KARAKOÇAN",
        "STREET": "DEVECİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "KARAKOÇAN",
        "STREET": "DOĞANOĞLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "KARAKOÇAN",
        "STREET": "DUMLUYAZI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "KARAKOÇAN",
        "STREET": "DURMUŞKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "KARAKOÇAN",
        "STREET": "GÖLPINAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "KARAKOÇAN",
        "STREET": "GÖZEREK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "KARAKOÇAN",
        "STREET": "GÜLLÜCE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "KARAKOÇAN",
        "STREET": "GÜMÜŞAKAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "KARAKOÇAN",
        "STREET": "GÜNDEĞDİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "KARAKOÇAN",
        "STREET": "HAMURKESEN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "KARAKOÇAN",
        "STREET": "HAMZALI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "KARAKOÇAN",
        "STREET": "KALECİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "KARAKOÇAN",
        "STREET": "KALKANKAYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "KARAKOÇAN",
        "STREET": "KARAKOÇAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "KARAKOÇAN",
        "STREET": "KARAPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "KARAKOÇAN",
        "STREET": "KARASAKAL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "KARAKOÇAN",
        "STREET": "KARAÇAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "KARAKOÇAN",
        "STREET": "KARŞIYAKA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "KARAKOÇAN",
        "STREET": "KAVAKDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "KARAKOÇAN",
        "STREET": "KAVALCIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "KARAKOÇAN",
        "STREET": "KEKLİKKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "KARAKOÇAN",
        "STREET": "KIRGIL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "KARAKOÇAN",
        "STREET": "KIRKPINAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "KARAKOÇAN",
        "STREET": "KIZILCA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "KARAKOÇAN",
        "STREET": "KIZILPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "KARAKOÇAN",
        "STREET": "KOCADAYI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "KARAKOÇAN",
        "STREET": "KORUDİBİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "KARAKOÇAN",
        "STREET": "KOÇYİĞİTLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "KARAKOÇAN",
        "STREET": "KULUBABA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "KARAKOÇAN",
        "STREET": "KULUNDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "KARAKOÇAN",
        "STREET": "KUŞBAYIRI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "KARAKOÇAN",
        "STREET": "KUŞÇU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "KARAKOÇAN",
        "STREET": "KÖRYUSUF KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "KARAKOÇAN",
        "STREET": "KÜMBET KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "KARAKOÇAN",
        "STREET": "MAHMUTLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "KARAKOÇAN",
        "STREET": "MAKSUTALİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "KARAKOÇAN",
        "STREET": "MİRAHMET KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "KARAKOÇAN",
        "STREET": "OKÇULAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "KARAKOÇAN",
        "STREET": "ORMANCIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "KARAKOÇAN",
        "STREET": "PAMUKLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "KARAKOÇAN",
        "STREET": "PİLAVTEPE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "KARAKOÇAN",
        "STREET": "SARIBAŞAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "KARAKOÇAN",
        "STREET": "SARICAN-GÖLYAYLASI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "KARAKOÇAN",
        "STREET": "SARICAN-SUBAŞI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "KARAKOÇAN",
        "STREET": "SARİHAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "KARAKOÇAN",
        "STREET": "SAĞIN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "KARAKOÇAN",
        "STREET": "SAĞUCAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "KARAKOÇAN",
        "STREET": "TEKARDIÇ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "KARAKOÇAN",
        "STREET": "TEPE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "KARAKOÇAN",
        "STREET": "YALINTAŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "KARAKOÇAN",
        "STREET": "YENİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "KARAKOÇAN",
        "STREET": "YENİCE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "KARAKOÇAN",
        "STREET": "YENİKAYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "KARAKOÇAN",
        "STREET": "YENİKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "KARAKOÇAN",
        "STREET": "YEŞİLBELEN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "KARAKOÇAN",
        "STREET": "YOĞUNAĞAÇ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "KARAKOÇAN",
        "STREET": "YUKARIOVACIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "KARAKOÇAN",
        "STREET": "YÜCEKONAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "KARAKOÇAN",
        "STREET": "YÜZEV KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "KARAKOÇAN",
        "STREET": "ÇALIKAYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "KARAKOÇAN",
        "STREET": "ÇAMARDI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "KARAKOÇAN",
        "STREET": "ÇAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "KARAKOÇAN",
        "STREET": "ÇANAKÇI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "KARAKOÇAN",
        "STREET": "ÇATALYOL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "KARAKOÇAN",
        "STREET": "ÇAVUŞYOLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "KARAKOÇAN",
        "STREET": "ÇAYIRDAM KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "KARAKOÇAN",
        "STREET": "ÇAYIRGÜLÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "KARAKOÇAN",
        "STREET": "ÇELEBİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "KARAKOÇAN",
        "STREET": "ÇİTAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "KARAKOÇAN",
        "STREET": "ÖZLÜCE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "KARAKOÇAN",
        "STREET": "ÜÇBUDAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "KARAKOÇAN",
        "STREET": "İSABEY KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "KEBAN",
        "STREET": "AKGÖMLEK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "KEBAN",
        "STREET": "AKÇATEPE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "KEBAN",
        "STREET": "ALTINKÜREK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "KEBAN",
        "STREET": "ALTIYAKA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "KEBAN",
        "STREET": "ASLANKAŞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "KEBAN",
        "STREET": "AŞAĞIÇAKMAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "KEBAN",
        "STREET": "BADEMLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "KEBAN",
        "STREET": "BAHÇELİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "KEBAN",
        "STREET": "BARAJ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "KEBAN",
        "STREET": "BAYINDIR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "KEBAN",
        "STREET": "BEYDEĞİRMENİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "KEBAN",
        "STREET": "BÖLÜKÇALI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "KEBAN",
        "STREET": "BÜKLÜMLÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "KEBAN",
        "STREET": "DENİZLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "KEBAN",
        "STREET": "DEĞİRMENBAŞI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "KEBAN",
        "STREET": "DÜRÜMLÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "KEBAN",
        "STREET": "FIRAT MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "KEBAN",
        "STREET": "GÖKBELEN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "KEBAN",
        "STREET": "GÖLDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "KEBAN",
        "STREET": "GÜNEYTEPE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "KEBAN",
        "STREET": "KALLAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "KEBAN",
        "STREET": "KOPUZLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "KEBAN",
        "STREET": "KOYUNUŞAĞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "KEBAN",
        "STREET": "KURŞUNKAYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "KEBAN",
        "STREET": "KUŞÇU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "KEBAN",
        "STREET": "NİMRİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "KEBAN",
        "STREET": "SAĞDIÇLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "KEBAN",
        "STREET": "TAŞKESEN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "KEBAN",
        "STREET": "TOPKIRAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "KEBAN",
        "STREET": "ULUPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "KEBAN",
        "STREET": "ÇALIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "KEBAN",
        "STREET": "ÇEVREKAYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "KEBAN",
        "STREET": "ÖRENYAKA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "KEBAN",
        "STREET": "ÜÇPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "KOVANCILAR",
        "STREET": "ADALI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "KOVANCILAR",
        "STREET": "AKMEZRA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "KOVANCILAR",
        "STREET": "AVLAĞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "KOVANCILAR",
        "STREET": "AŞAĞIDEMİRCİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "KOVANCILAR",
        "STREET": "AŞAĞIKANATLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "KOVANCILAR",
        "STREET": "AŞAĞIKÖSE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "KOVANCILAR",
        "STREET": "AŞAĞIMİRAHMET KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "KOVANCILAR",
        "STREET": "BAHÇELİEVLER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "KOVANCILAR",
        "STREET": "BAYRAMYAZI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "KOVANCILAR",
        "STREET": "BAĞGÜLÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "KOVANCILAR",
        "STREET": "BAĞLAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "KOVANCILAR",
        "STREET": "BEŞPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "KOVANCILAR",
        "STREET": "BİLALKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "KOVANCILAR",
        "STREET": "DEMİRCİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "KOVANCILAR",
        "STREET": "DEĞİRMENTAŞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "KOVANCILAR",
        "STREET": "DURMUŞLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "KOVANCILAR",
        "STREET": "EKİNBAĞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "KOVANCILAR",
        "STREET": "EKİNÖZÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "KOVANCILAR",
        "STREET": "FATİH MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "KOVANCILAR",
        "STREET": "GEDİKYURT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "KOVANCILAR",
        "STREET": "GÖKÇEDAL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "KOVANCILAR",
        "STREET": "GÖZECİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "KOVANCILAR",
        "STREET": "GÖÇMEZLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "KOVANCILAR",
        "STREET": "GÜLTEPE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "KOVANCILAR",
        "STREET": "GÜLÇATI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "KOVANCILAR",
        "STREET": "HACIMEKKE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "KOVANCILAR",
        "STREET": "HACISAM KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "KOVANCILAR",
        "STREET": "HEYBET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "KOVANCILAR",
        "STREET": "KACAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "KOVANCILAR",
        "STREET": "KAPIAÇMAZ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "KOVANCILAR",
        "STREET": "KARABÖRK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "KOVANCILAR",
        "STREET": "KARACİMŞİT MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "KOVANCILAR",
        "STREET": "KARAMAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "KOVANCILAR",
        "STREET": "KARASUNGUR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "KOVANCILAR",
        "STREET": "KARINCAKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "KOVANCILAR",
        "STREET": "KAVAKKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "KOVANCILAR",
        "STREET": "KAYALIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "KOVANCILAR",
        "STREET": "KOLLUCA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "KOVANCILAR",
        "STREET": "KUŞAĞACI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "KOVANCILAR",
        "STREET": "KUŞÇU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "KOVANCILAR",
        "STREET": "KÖPRÜDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "KOVANCILAR",
        "STREET": "MURATBAĞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "KOVANCILAR",
        "STREET": "MUSTAFAKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "KOVANCILAR",
        "STREET": "NİŞANKAYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "KOVANCILAR",
        "STREET": "OKÇULAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "KOVANCILAR",
        "STREET": "OSMANAĞA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "KOVANCILAR",
        "STREET": "PAYAMLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "KOVANCILAR",
        "STREET": "SALKIMLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "KOVANCILAR",
        "STREET": "SARAYBAHÇE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "KOVANCILAR",
        "STREET": "SARIBUĞDAY KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "KOVANCILAR",
        "STREET": "SOĞANLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "KOVANCILAR",
        "STREET": "SOĞUKPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "KOVANCILAR",
        "STREET": "SÜREKLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "KOVANCILAR",
        "STREET": "TABANÖZÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "KOVANCILAR",
        "STREET": "TATAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "KOVANCILAR",
        "STREET": "TAŞÇANAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "KOVANCILAR",
        "STREET": "TAŞÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "KOVANCILAR",
        "STREET": "TEPEBAĞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "KOVANCILAR",
        "STREET": "TOKİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "KOVANCILAR",
        "STREET": "TOPAĞAÇ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "KOVANCILAR",
        "STREET": "TUNA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "KOVANCILAR",
        "STREET": "UYANDIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "KOVANCILAR",
        "STREET": "UZUNOVA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "KOVANCILAR",
        "STREET": "VALİ FAHRİBEY KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "KOVANCILAR",
        "STREET": "YARIMCA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "KOVANCILAR",
        "STREET": "YAZIBAŞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "KOVANCILAR",
        "STREET": "YENİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "KOVANCILAR",
        "STREET": "YENİDAM KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "KOVANCILAR",
        "STREET": "YENİKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "KOVANCILAR",
        "STREET": "YEŞİLDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "KOVANCILAR",
        "STREET": "YEŞİLKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "KOVANCILAR",
        "STREET": "YILBAŞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "KOVANCILAR",
        "STREET": "YONCALIBAYIR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "KOVANCILAR",
        "STREET": "YUKARIDEMİRLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "KOVANCILAR",
        "STREET": "YUKARIKANATLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "KOVANCILAR",
        "STREET": "YUKARIKAZANLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "KOVANCILAR",
        "STREET": "YUKARIMİRAHMET KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "KOVANCILAR",
        "STREET": "ÇAKIRKAŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "KOVANCILAR",
        "STREET": "ÇARŞI BAŞI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "KOVANCILAR",
        "STREET": "ÇATAKBAŞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "KOVANCILAR",
        "STREET": "ÇAY BAŞI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "KOVANCILAR",
        "STREET": "ÇAYBAĞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "KOVANCILAR",
        "STREET": "ÇELEBİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "KOVANCILAR",
        "STREET": "ÇİFTLİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "KOVANCILAR",
        "STREET": "İSAAĞAMEZRASI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "KOVANCILAR",
        "STREET": "İSMET PAŞA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "KOVANCILAR",
        "STREET": "İĞDELİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "KOVANCILAR",
        "STREET": "ŞEKERCİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "KOVANCILAR",
        "STREET": "ŞENOVA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "MADEN",
        "STREET": "AKBOĞA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "MADEN",
        "STREET": "ALTINTARLA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "MADEN",
        "STREET": "ARSLANTAŞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "MADEN",
        "STREET": "AĞADİBEK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "MADEN",
        "STREET": "BAHÇEDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "MADEN",
        "STREET": "BAHÇELİEVLER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "MADEN",
        "STREET": "CAMİİ KEBİR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "MADEN",
        "STREET": "CUMHURİYETÇİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "MADEN",
        "STREET": "DURMUŞTEPE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "MADEN",
        "STREET": "DUTPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "MADEN",
        "STREET": "EĞRİKAVAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "MADEN",
        "STREET": "GEZİN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "MADEN",
        "STREET": "HANEVLERİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "MADEN",
        "STREET": "HATUNKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "MADEN",
        "STREET": "IŞIKTEPE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "MADEN",
        "STREET": "KARATOP KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "MADEN",
        "STREET": "KARTALDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "MADEN",
        "STREET": "KAVAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "MADEN",
        "STREET": "KAYALAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "MADEN",
        "STREET": "KAŞLICA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "MADEN",
        "STREET": "KIZILTEPE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "MADEN",
        "STREET": "KOÇKONAĞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "MADEN",
        "STREET": "KUMYAZI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "MADEN",
        "STREET": "KÜÇÜKOVA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "MADEN",
        "STREET": "NALDÖKEN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "MADEN",
        "STREET": "PLAJKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "MADEN",
        "STREET": "POLATKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "MADEN",
        "STREET": "SAĞRILI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "MADEN",
        "STREET": "TEKEVLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "MADEN",
        "STREET": "TEPECİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "MADEN",
        "STREET": "TOPALUŞAĞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "MADEN",
        "STREET": "YENİBAHÇE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "MADEN",
        "STREET": "YEŞİLOVA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "MADEN",
        "STREET": "YILDIZHAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "MADEN",
        "STREET": "YONCAPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "MADEN",
        "STREET": "ÇAKIROĞLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "MADEN",
        "STREET": "ÇALKAYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "MADEN",
        "STREET": "ÇAYIRKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "MADEN",
        "STREET": "ÇİTLİKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "MADEN",
        "STREET": "ÖRTÜLÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "PALU",
        "STREET": "AKYÜREK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "PALU",
        "STREET": "ALTINÖLÇEK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "PALU",
        "STREET": "ANDILAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "PALU",
        "STREET": "ARINDIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "PALU",
        "STREET": "ATİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "PALU",
        "STREET": "AŞAĞI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "PALU",
        "STREET": "BALTAŞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "PALU",
        "STREET": "BEYDOĞAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "PALU",
        "STREET": "BEYHAN-GÜNEY MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "PALU",
        "STREET": "BEYHAN-GİRNE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "PALU",
        "STREET": "BEYHAN-KARŞIYAKA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "PALU",
        "STREET": "BEYHAN-İSTASYON MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "PALU",
        "STREET": "BOZÇANAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "PALU",
        "STREET": "BURGUDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "PALU",
        "STREET": "BÖLÜKELMA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "PALU",
        "STREET": "BÜYÜKÇALTI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "PALU",
        "STREET": "CUMHURİYET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "PALU",
        "STREET": "DAMLAPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "PALU",
        "STREET": "ESENTEPE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "PALU",
        "STREET": "GEMTEPE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "PALU",
        "STREET": "GÖKDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "PALU",
        "STREET": "GÜLLÜCE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "PALU",
        "STREET": "GÜMEÇBAĞLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "PALU",
        "STREET": "GÜMÜŞKAYNAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "PALU",
        "STREET": "HASBEY KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "PALU",
        "STREET": "KALEKENT MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "PALU",
        "STREET": "KARACABAĞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "PALU",
        "STREET": "KARASALKIM KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "PALU",
        "STREET": "KARATAŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "PALU",
        "STREET": "KARŞIBAHÇELER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "PALU",
        "STREET": "KASIL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "PALU",
        "STREET": "KAYAHİSAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "PALU",
        "STREET": "KAYAÖNÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "PALU",
        "STREET": "KEKLİKDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "PALU",
        "STREET": "KIRKBULAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "PALU",
        "STREET": "KÖKLÜCE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "PALU",
        "STREET": "KÜLTÜR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "PALU",
        "STREET": "KÜÇÜKÇALTI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "PALU",
        "STREET": "NUMUNE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "PALU",
        "STREET": "SEYDİLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "PALU",
        "STREET": "TARHANA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "PALU",
        "STREET": "UMUTKAYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "PALU",
        "STREET": "YARIMTEPE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "PALU",
        "STREET": "YENİMAHALLE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "PALU",
        "STREET": "YEŞİLBAYIR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "PALU",
        "STREET": "YUKARI PALU MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "PALU",
        "STREET": "ZİVER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "PALU",
        "STREET": "ÇARŞI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "PALU",
        "STREET": "ÖRENCİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "PALU",
        "STREET": "ÖRGÜLÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "PALU",
        "STREET": "ÜÇDEĞİRMENLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "SİVRİCE",
        "STREET": "AKBUĞDAY KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "SİVRİCE",
        "STREET": "AKPINAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "SİVRİCE",
        "STREET": "AKSEKİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "SİVRİCE",
        "STREET": "ALAATTİNKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "SİVRİCE",
        "STREET": "ALINCIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "SİVRİCE",
        "STREET": "AŞAĞIÇANAKÇI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "SİVRİCE",
        "STREET": "BAŞKAYNAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "SİVRİCE",
        "STREET": "BEKÇİTEPE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "SİVRİCE",
        "STREET": "CANUŞAĞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "SİVRİCE",
        "STREET": "DEDEYOLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "SİVRİCE",
        "STREET": "DEREBOYNU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "SİVRİCE",
        "STREET": "DOĞANBAĞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "SİVRİCE",
        "STREET": "DUYGULU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "SİVRİCE",
        "STREET": "DÖRTBÖLÜK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "SİVRİCE",
        "STREET": "DİKMEN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "SİVRİCE",
        "STREET": "ELMASUYU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "SİVRİCE",
        "STREET": "GELİNDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "SİVRİCE",
        "STREET": "GÖLBAŞI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "SİVRİCE",
        "STREET": "GÖRGÜLÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "SİVRİCE",
        "STREET": "GÖZELİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "SİVRİCE",
        "STREET": "GÜNAY KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "SİVRİCE",
        "STREET": "GÜNBALI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "SİVRİCE",
        "STREET": "HACILAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "SİVRİCE",
        "STREET": "HAFTASAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "SİVRİCE",
        "STREET": "HAZAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "SİVRİCE",
        "STREET": "ILINCAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "SİVRİCE",
        "STREET": "KALABA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "SİVRİCE",
        "STREET": "KAMIŞLIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "SİVRİCE",
        "STREET": "KARAÇALI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "SİVRİCE",
        "STREET": "KAVAKKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "SİVRİCE",
        "STREET": "KAVALLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "SİVRİCE",
        "STREET": "KAYABAĞLARI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "SİVRİCE",
        "STREET": "KAYAPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "SİVRİCE",
        "STREET": "KILIÇKAYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "SİVRİCE",
        "STREET": "KÖSEBAYIR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "SİVRİCE",
        "STREET": "KÜLTÜR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "SİVRİCE",
        "STREET": "KÜRKKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "SİVRİCE",
        "STREET": "MULLAALİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "SİVRİCE",
        "STREET": "NERGİZE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "SİVRİCE",
        "STREET": "OLGUNLAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "SİVRİCE",
        "STREET": "SANAYİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "SİVRİCE",
        "STREET": "SOĞUKPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "SİVRİCE",
        "STREET": "SÜREK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "SİVRİCE",
        "STREET": "TARLATEPE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "SİVRİCE",
        "STREET": "TAŞLIYAYLA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "SİVRİCE",
        "STREET": "TOPALUŞAĞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "SİVRİCE",
        "STREET": "USLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "SİVRİCE",
        "STREET": "YARUŞAĞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "SİVRİCE",
        "STREET": "YEDİKARDEŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "SİVRİCE",
        "STREET": "YEDİPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "SİVRİCE",
        "STREET": "YUKARIÇANAKÇI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "SİVRİCE",
        "STREET": "YÜREKKAYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "SİVRİCE",
        "STREET": "ÇATAKKAYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "SİVRİCE",
        "STREET": "ÇEVRİMTAŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "SİVRİCE",
        "STREET": "ÇORTUNLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "SİVRİCE",
        "STREET": "ÜÇLERCE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ELAZIĞ",
        "DISTRICT": "SİVRİCE",
        "STREET": "ÜĞRÜK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "AZİZİYE",
        "STREET": "ADAÇAY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "AZİZİYE",
        "STREET": "AHIRCIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "AZİZİYE",
        "STREET": "AKDAĞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "AZİZİYE",
        "STREET": "AKYAZI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "AZİZİYE",
        "STREET": "ALACA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "AZİZİYE",
        "STREET": "ALAYBEYİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "AZİZİYE",
        "STREET": "ATLIKONAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "AZİZİYE",
        "STREET": "AYNALIKALE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "AZİZİYE",
        "STREET": "AĞCAKENT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "AZİZİYE",
        "STREET": "AĞÖREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "AZİZİYE",
        "STREET": "AŞAĞI YENİCE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "AZİZİYE",
        "STREET": "AŞAĞICANÖREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "AZİZİYE",
        "STREET": "BAŞKENT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "AZİZİYE",
        "STREET": "BAŞKURTDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "AZİZİYE",
        "STREET": "BAŞOVACIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "AZİZİYE",
        "STREET": "BAŞÇAKMAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "AZİZİYE",
        "STREET": "BEYPINARI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "AZİZİYE",
        "STREET": "BİNGÖZE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "AZİZİYE",
        "STREET": "DAĞDAGÜL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "AZİZİYE",
        "STREET": "DEMİRGEÇİT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "AZİZİYE",
        "STREET": "DÜZTOPRAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "AZİZİYE",
        "STREET": "ELMALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "AZİZİYE",
        "STREET": "EMRECİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "AZİZİYE",
        "STREET": "ESKİPOLAT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "AZİZİYE",
        "STREET": "EĞERTİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "AZİZİYE",
        "STREET": "EŞKİNKAYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "AZİZİYE",
        "STREET": "GELİNKAYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "AZİZİYE",
        "STREET": "GEYİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "AZİZİYE",
        "STREET": "GÜLLÜCE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "AZİZİYE",
        "STREET": "HALİLKAYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "AZİZİYE",
        "STREET": "ILICA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "AZİZİYE",
        "STREET": "KABAKTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "AZİZİYE",
        "STREET": "KAHRAMANLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "AZİZİYE",
        "STREET": "KAPULU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "AZİZİYE",
        "STREET": "KARAKALE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "AZİZİYE",
        "STREET": "KAVAKLIDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "AZİZİYE",
        "STREET": "KAYAPA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "AZİZİYE",
        "STREET": "KIZILKALE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "AZİZİYE",
        "STREET": "KUMLUYAZI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "AZİZİYE",
        "STREET": "KUZGUN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "AZİZİYE",
        "STREET": "KUZULUK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "AZİZİYE",
        "STREET": "KUŞÇU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "AZİZİYE",
        "STREET": "OCAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "AZİZİYE",
        "STREET": "PAŞAYURDU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "AZİZİYE",
        "STREET": "RİZEKENT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "AZİZİYE",
        "STREET": "SAKALIKESİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "AZİZİYE",
        "STREET": "SALTUKLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "AZİZİYE",
        "STREET": "SARIYAYLA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "AZİZİYE",
        "STREET": "SELÇUKLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "AZİZİYE",
        "STREET": "SIRLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "AZİZİYE",
        "STREET": "SORKUNLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "AZİZİYE",
        "STREET": "SÖĞÜTLÜ (OVACIK) MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "AZİZİYE",
        "STREET": "SÖĞÜTLÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "AZİZİYE",
        "STREET": "TAŞPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "AZİZİYE",
        "STREET": "TEBRİZCİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "AZİZİYE",
        "STREET": "TINAZLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "AZİZİYE",
        "STREET": "TOPRAKKALE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "AZİZİYE",
        "STREET": "YARIMCA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "AZİZİYE",
        "STREET": "YEŞİLOVA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "AZİZİYE",
        "STREET": "YEŞİLVADİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "AZİZİYE",
        "STREET": "YONCALIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "AZİZİYE",
        "STREET": "YUKARICANÖREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "AZİZİYE",
        "STREET": "ÇAMLICA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "AZİZİYE",
        "STREET": "ÇATAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "AZİZİYE",
        "STREET": "ÇAVDARLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "AZİZİYE",
        "STREET": "ÇAVUŞOĞLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "AZİZİYE",
        "STREET": "ÇIKRIKLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "AZİZİYE",
        "STREET": "ÇİĞDEMLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "AZİZİYE",
        "STREET": "ÖMERTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "AZİZİYE",
        "STREET": "ÖZBEK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "AZİZİYE",
        "STREET": "ÖZBİLEN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "AZİZİYE",
        "STREET": "ÜÇKÖŞE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "AŞKALE",
        "STREET": "ABDALCIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "AŞKALE",
        "STREET": "AKÖREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "AŞKALE",
        "STREET": "ALTINTAŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "AŞKALE",
        "STREET": "BAHÇELİEVLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "AŞKALE",
        "STREET": "BALLITAŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "AŞKALE",
        "STREET": "BOZBURUN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "AŞKALE",
        "STREET": "BÜYÜKGEÇİT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "AŞKALE",
        "STREET": "CAHİT AYBAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "AŞKALE",
        "STREET": "DALLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "AŞKALE",
        "STREET": "DAĞYURDU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "AŞKALE",
        "STREET": "DEMİRKIRAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "AŞKALE",
        "STREET": "DEREKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "AŞKALE",
        "STREET": "DÜZYURT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "AŞKALE",
        "STREET": "EMEK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "AŞKALE",
        "STREET": "ESENTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "AŞKALE",
        "STREET": "EYÜPOĞLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "AŞKALE",
        "STREET": "GÖKÇEBÜK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "AŞKALE",
        "STREET": "GÜLLÜDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "AŞKALE",
        "STREET": "GÜMÜŞSEREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "AŞKALE",
        "STREET": "GÜNEYÇAM MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "AŞKALE",
        "STREET": "GÜRKAYNAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "AŞKALE",
        "STREET": "HACIBEKİR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "AŞKALE",
        "STREET": "HACIHAMZA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "AŞKALE",
        "STREET": "HACIMAHMUT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "AŞKALE",
        "STREET": "HATUNCUK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "AŞKALE",
        "STREET": "HAYDARHACI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "AŞKALE",
        "STREET": "KANDİLLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "AŞKALE",
        "STREET": "KAPIKALE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "AŞKALE",
        "STREET": "KARAHASAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "AŞKALE",
        "STREET": "KARASU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "AŞKALE",
        "STREET": "KAVURMAÇUKURU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "AŞKALE",
        "STREET": "KIBRIS MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "AŞKALE",
        "STREET": "KOÇBABA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "AŞKALE",
        "STREET": "KOŞAPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "AŞKALE",
        "STREET": "KURTMAHMUT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "AŞKALE",
        "STREET": "KÜKÜRTLÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "AŞKALE",
        "STREET": "KÜÇÜKGEÇİT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "AŞKALE",
        "STREET": "KÜÇÜKOVA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "AŞKALE",
        "STREET": "MERDİVENKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "AŞKALE",
        "STREET": "MERKEZ BUCAĞI GÖLÖREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "AŞKALE",
        "STREET": "MERKEZ BUCAĞI ÇATÖREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "AŞKALE",
        "STREET": "MEYDAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "AŞKALE",
        "STREET": "MEZREA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "AŞKALE",
        "STREET": "MUSATANIŞMAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "AŞKALE",
        "STREET": "NAHİYE GÖLÖREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "AŞKALE",
        "STREET": "OCAKLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "AŞKALE",
        "STREET": "OVACIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "AŞKALE",
        "STREET": "PIRNAKABAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "AŞKALE",
        "STREET": "SAPTIRAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "AŞKALE",
        "STREET": "SARIBABA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "AŞKALE",
        "STREET": "SAZLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "AŞKALE",
        "STREET": "TAŞAĞIL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "AŞKALE",
        "STREET": "TAŞLIÇAYIR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "AŞKALE",
        "STREET": "TEPSİCİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "AŞKALE",
        "STREET": "TOKÇA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "AŞKALE",
        "STREET": "TOPALÇAVUŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "AŞKALE",
        "STREET": "TOSUNLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "AŞKALE",
        "STREET": "TOZLUCA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "AŞKALE",
        "STREET": "TURAÇ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "AŞKALE",
        "STREET": "YAYLAKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "AŞKALE",
        "STREET": "YAYLAYOLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "AŞKALE",
        "STREET": "YAYLIMLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "AŞKALE",
        "STREET": "YENİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "AŞKALE",
        "STREET": "YENİKÖY HALİTPAŞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "AŞKALE",
        "STREET": "YEŞİLOVA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "AŞKALE",
        "STREET": "YUMRUVEREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "AŞKALE",
        "STREET": "ÇARŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "AŞKALE",
        "STREET": "ÇATALBAYIR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "AŞKALE",
        "STREET": "ÇAYKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "AŞKALE",
        "STREET": "ÇİFTLİK BUCAĞI ÇATÖREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "AŞKALE",
        "STREET": "ÇİFTLİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "AŞKALE",
        "STREET": "ÖZLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "AŞKALE",
        "STREET": "ÜÇ MART MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "AŞKALE",
        "STREET": "İSTASYON MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "AŞKALE",
        "STREET": "ŞAFAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "HINIS",
        "STREET": "ACARKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "HINIS",
        "STREET": "AKBAYIR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "HINIS",
        "STREET": "AKGELİN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "HINIS",
        "STREET": "AKGÖZE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "HINIS",
        "STREET": "AKÇAMELİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "HINIS",
        "STREET": "AKÖREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "HINIS",
        "STREET": "ALACA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "HINIS",
        "STREET": "ALAGÖZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "HINIS",
        "STREET": "ALINTERİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "HINIS",
        "STREET": "ALTINPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "HINIS",
        "STREET": "ALİKIRI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "HINIS",
        "STREET": "ARPADERESİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "HINIS",
        "STREET": "AVCILAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "HINIS",
        "STREET": "AŞAĞIKAYABAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "HINIS",
        "STREET": "BAHÇE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "HINIS",
        "STREET": "BAYIRKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "HINIS",
        "STREET": "BAŞKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "HINIS",
        "STREET": "BELLİTAŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "HINIS",
        "STREET": "BEYYURDU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "HINIS",
        "STREET": "BURHANKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "HINIS",
        "STREET": "DAĞÇAYIRI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "HINIS",
        "STREET": "DEMİRCİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "HINIS",
        "STREET": "DERVİŞALİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "HINIS",
        "STREET": "DERİNCE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "HINIS",
        "STREET": "DİBEKLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "HINIS",
        "STREET": "DİKİLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "HINIS",
        "STREET": "DİVANHÜSEYİN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "HINIS",
        "STREET": "ELMADALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "HINIS",
        "STREET": "ERBEYLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "HINIS",
        "STREET": "ERDURAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "HINIS",
        "STREET": "ERENCE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "HINIS",
        "STREET": "ESENLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "HINIS",
        "STREET": "GÖLLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "HINIS",
        "STREET": "GÜLLÜÇİMEN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "HINIS",
        "STREET": "GÜLİSTAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "HINIS",
        "STREET": "GÜRÇAYIR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "HINIS",
        "STREET": "GÜZELDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "HINIS",
        "STREET": "HALİLÇAVUŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "HINIS",
        "STREET": "HAYRAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "HINIS",
        "STREET": "HINIS KAPALI CEZAEVİ",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "HINIS",
        "STREET": "ILICAKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "HINIS",
        "STREET": "KALECİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "HINIS",
        "STREET": "KARAAĞAÇ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "HINIS",
        "STREET": "KARABUDAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "HINIS",
        "STREET": "KARAKULA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "HINIS",
        "STREET": "KARAMOLLA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "HINIS",
        "STREET": "KAZANCI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "HINIS",
        "STREET": "KETENCİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "HINIS",
        "STREET": "KISIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "HINIS",
        "STREET": "KIZILAHMET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "HINIS",
        "STREET": "KIZMUSA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "HINIS",
        "STREET": "KOLHİSAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "HINIS",
        "STREET": "KONGUR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "HINIS",
        "STREET": "KÖPRÜBAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "HINIS",
        "STREET": "MEYDANKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "HINIS",
        "STREET": "MEZRA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "HINIS",
        "STREET": "MOLLACELİL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "HINIS",
        "STREET": "MOLLAKULAÇ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "HINIS",
        "STREET": "MUTLUCA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "HINIS",
        "STREET": "ORTAKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "HINIS",
        "STREET": "OVAKOZLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "HINIS",
        "STREET": "OVAÇEVİRME MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "HINIS",
        "STREET": "PARMAKSIZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "HINIS",
        "STREET": "PINARBAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "HINIS",
        "STREET": "PINARKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "HINIS",
        "STREET": "SALTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "HINIS",
        "STREET": "SANAYİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "HINIS",
        "STREET": "SARILI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "HINIS",
        "STREET": "SILDIZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "HINIS",
        "STREET": "SULTANLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "HINIS",
        "STREET": "SUVARAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "HINIS",
        "STREET": "SÖĞÜTLÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "HINIS",
        "STREET": "TANIR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "HINIS",
        "STREET": "TAPUKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "HINIS",
        "STREET": "TAŞBUDAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "HINIS",
        "STREET": "TELLİTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "HINIS",
        "STREET": "TOPRAKKALE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "HINIS",
        "STREET": "TORAMAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "HINIS",
        "STREET": "TİPİDERESİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "HINIS",
        "STREET": "ULUÇAYIR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "HINIS",
        "STREET": "UYANIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "HINIS",
        "STREET": "YAMANLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "HINIS",
        "STREET": "YAYLAKONAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "HINIS",
        "STREET": "YELPİZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "HINIS",
        "STREET": "YENİKENT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "HINIS",
        "STREET": "YENİKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "HINIS",
        "STREET": "YEŞİLBAHÇE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "HINIS",
        "STREET": "YEŞİLYAZI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "HINIS",
        "STREET": "YOLÜSTÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "HINIS",
        "STREET": "YUKARIKAYABAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "HINIS",
        "STREET": "ÇAKMAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "HINIS",
        "STREET": "ÇAMURLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "HINIS",
        "STREET": "ÇATAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "HINIS",
        "STREET": "ÇİLLİGÖL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "HINIS",
        "STREET": "ÜNLÜCE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "HINIS",
        "STREET": "İSMAİL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "HINIS",
        "STREET": "ŞAHABETTİNKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "HINIS",
        "STREET": "ŞAHVERDİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "HINIS",
        "STREET": "ŞALGAMKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "HORASAN",
        "STREET": "ADNAN MENDERES MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "HORASAN",
        "STREET": "AKÇATAŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "HORASAN",
        "STREET": "AKÇATOPRAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "HORASAN",
        "STREET": "ALAGÖZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "HORASAN",
        "STREET": "ALİÇEYREK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "HORASAN",
        "STREET": "ARAS MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "HORASAN",
        "STREET": "ARDI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "HORASAN",
        "STREET": "ARPAÇAYIR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "HORASAN",
        "STREET": "AZAP MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "HORASAN",
        "STREET": "AĞILLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "HORASAN",
        "STREET": "AŞAĞIAKTAŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "HORASAN",
        "STREET": "AŞAĞIBADEMÖZÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "HORASAN",
        "STREET": "BAHÇE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "HORASAN",
        "STREET": "BULGURLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "HORASAN",
        "STREET": "CAMİİ KEBİR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "HORASAN",
        "STREET": "DALBAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "HORASAN",
        "STREET": "DANİŞMENT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "HORASAN",
        "STREET": "DEĞİRMENLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "HORASAN",
        "STREET": "DÖLLEK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "HORASAN",
        "STREET": "DÖNERTAŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "HORASAN",
        "STREET": "DİKİLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "HORASAN",
        "STREET": "ESENTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "HORASAN",
        "STREET": "EĞERTAŞLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "HORASAN",
        "STREET": "FATİH SULTAN MEHMET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "HORASAN",
        "STREET": "GEREK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "HORASAN",
        "STREET": "GÜNDEĞER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "HORASAN",
        "STREET": "GÜZELYAYLA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "HORASAN",
        "STREET": "HACIAHMET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "HORASAN",
        "STREET": "HACIHALİL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "HORASAN",
        "STREET": "HARÇLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "HORASAN",
        "STREET": "HASANBABA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "HORASAN",
        "STREET": "HASANBEY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "HORASAN",
        "STREET": "HAYDARLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "HORASAN",
        "STREET": "HIZARDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "HORASAN",
        "STREET": "HIZIRİLYAS MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "HORASAN",
        "STREET": "IĞIRBIĞIR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "HORASAN",
        "STREET": "KADICELAL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "HORASAN",
        "STREET": "KALENDER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "HORASAN",
        "STREET": "KARABIYIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "HORASAN",
        "STREET": "KARACAÖREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "HORASAN",
        "STREET": "KARAPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "HORASAN",
        "STREET": "KARAÇUHA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "HORASAN",
        "STREET": "KAYNARCA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "HORASAN",
        "STREET": "KAZIM KARABEKİR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "HORASAN",
        "STREET": "KEMERLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "HORASAN",
        "STREET": "KEPENEK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "HORASAN",
        "STREET": "KIRIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "HORASAN",
        "STREET": "KIRKDİKME MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "HORASAN",
        "STREET": "KIRKGÖZELER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "HORASAN",
        "STREET": "KIRKLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "HORASAN",
        "STREET": "KIZILCA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "HORASAN",
        "STREET": "KIZLARKALE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "HORASAN",
        "STREET": "KÜKÜRTLÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "HORASAN",
        "STREET": "KÜÇÜKKONAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "HORASAN",
        "STREET": "MOLLAAHMET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "HORASAN",
        "STREET": "MOLLAMELİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "HORASAN",
        "STREET": "MURATBAĞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "HORASAN",
        "STREET": "MÜMTAZ TURAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "HORASAN",
        "STREET": "PİNARLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "HORASAN",
        "STREET": "PİRALİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "HORASAN",
        "STREET": "PİRHASAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "HORASAN",
        "STREET": "RECEP TAYYİP ERDOĞAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "HORASAN",
        "STREET": "SAÇLIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "HORASAN",
        "STREET": "SEKMAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "HORASAN",
        "STREET": "TAHİRHOCA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "HORASAN",
        "STREET": "TAVŞANCIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "HORASAN",
        "STREET": "TEKNECİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "HORASAN",
        "STREET": "YARBOĞAZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "HORASAN",
        "STREET": "YAYLACIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "HORASAN",
        "STREET": "YAZILITAŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "HORASAN",
        "STREET": "YEŞİLDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "HORASAN",
        "STREET": "YEŞİLÖZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "HORASAN",
        "STREET": "YILDIRAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "HORASAN",
        "STREET": "YUKARIBADEMÖZÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "HORASAN",
        "STREET": "YUKARIHORUM MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "HORASAN",
        "STREET": "YUKARITAHİRHOCA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "HORASAN",
        "STREET": "YÜRÜKATLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "HORASAN",
        "STREET": "YÜZÖREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "HORASAN",
        "STREET": "ÇAMLIKALE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "HORASAN",
        "STREET": "ÇAMURLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "HORASAN",
        "STREET": "ÇAYIRDÜZÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "HORASAN",
        "STREET": "ÇİFTLİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "HORASAN",
        "STREET": "İNCESU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "HORASAN",
        "STREET": "İNÖNÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "HORASAN",
        "STREET": "İĞDELİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "HORASAN",
        "STREET": "ŞEREFİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "HORASAN",
        "STREET": "ŞEYHYUSUF MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "KARAYAZI",
        "STREET": "ABDURRAHMANKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "KARAYAZI",
        "STREET": "AKARSU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "KARAYAZI",
        "STREET": "AKPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "KARAYAZI",
        "STREET": "ALEMDAĞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "KARAYAZI",
        "STREET": "ALİKÜLLEK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "KARAYAZI",
        "STREET": "ANITLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "KARAYAZI",
        "STREET": "AYDINSU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "KARAYAZI",
        "STREET": "AĞAÇLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "KARAYAZI",
        "STREET": "AŞAĞISÖYLEMEZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "KARAYAZI",
        "STREET": "AŞAĞIİNCESU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "KARAYAZI",
        "STREET": "BAYRAKTAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "KARAYAZI",
        "STREET": "BEZİRHANE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "KARAYAZI",
        "STREET": "DEĞİRMENKAYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "KARAYAZI",
        "STREET": "DORUCA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "KARAYAZI",
        "STREET": "DURUCA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "KARAYAZI",
        "STREET": "DÖRTPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "KARAYAZI",
        "STREET": "DÜNDAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "KARAYAZI",
        "STREET": "ELMAAĞAÇ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "KARAYAZI",
        "STREET": "GEVENTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "KARAYAZI",
        "STREET": "GÖKSU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "KARAYAZI",
        "STREET": "GÖKTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "KARAYAZI",
        "STREET": "GÜLLÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "KARAYAZI",
        "STREET": "HACIBAYRAM MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "KARAYAZI",
        "STREET": "HASANOVA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "KARAYAZI",
        "STREET": "KAPANLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "KARAYAZI",
        "STREET": "KARAAĞIL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "KARAYAZI",
        "STREET": "KARABEY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "KARAYAZI",
        "STREET": "KARAKALE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "KARAYAZI",
        "STREET": "KARAKAYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "KARAYAZI",
        "STREET": "KARASU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "KARAYAZI",
        "STREET": "KARŞIYAKA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "KARAYAZI",
        "STREET": "KAYALAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "KARAYAZI",
        "STREET": "KAZBEL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "KARAYAZI",
        "STREET": "KIRGINDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "KARAYAZI",
        "STREET": "KIRIKPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "KARAYAZI",
        "STREET": "KURUPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "KARAYAZI",
        "STREET": "KÖSEHASAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "KARAYAZI",
        "STREET": "KÖYCEĞİZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "KARAYAZI",
        "STREET": "MOLLA OSMAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "KARAYAZI",
        "STREET": "MOLLABEKİR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "KARAYAZI",
        "STREET": "MURATLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "KARAYAZI",
        "STREET": "PAYVEREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "KARAYAZI",
        "STREET": "SALYAMAÇ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "KARAYAZI",
        "STREET": "SANCAKTAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "KARAYAZI",
        "STREET": "SARIÇİÇEK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "KARAYAZI",
        "STREET": "SELENLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "KARAYAZI",
        "STREET": "SUKONAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "KARAYAZI",
        "STREET": "SULUTAŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "KARAYAZI",
        "STREET": "TAŞAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "KARAYAZI",
        "STREET": "TOSUNLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "KARAYAZI",
        "STREET": "TURNAGÖL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "KARAYAZI",
        "STREET": "ULUCANLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "KARAYAZI",
        "STREET": "UĞURDALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "KARAYAZI",
        "STREET": "YAHYAKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "KARAYAZI",
        "STREET": "YALINDAL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "KARAYAZI",
        "STREET": "YENİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "KARAYAZI",
        "STREET": "YENİKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "KARAYAZI",
        "STREET": "YEŞİLOVA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "KARAYAZI",
        "STREET": "YEŞİLYURT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "KARAYAZI",
        "STREET": "YOLGÖREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "KARAYAZI",
        "STREET": "YUKARICİHANBEY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "KARAYAZI",
        "STREET": "YUKARISÖYLEMEZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "KARAYAZI",
        "STREET": "YUKARIÇIĞILGAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "KARAYAZI",
        "STREET": "YÜCELİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "KARAYAZI",
        "STREET": "YİĞİTYOLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "KARAYAZI",
        "STREET": "ÇAKMAKÖZÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "KARAYAZI",
        "STREET": "ÇALIŞKAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "KARAYAZI",
        "STREET": "ÇALTILI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "KARAYAZI",
        "STREET": "ÇATALÖREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "KARAYAZI",
        "STREET": "ÇAVUŞKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "KARAYAZI",
        "STREET": "ÇAYIRBEYLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "KARAYAZI",
        "STREET": "ÇELİKLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "KARAYAZI",
        "STREET": "ÇEPİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "KARAYAZI",
        "STREET": "ÜZENGİLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "KARAYAZI",
        "STREET": "ŞAKİRKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "KARAÇOBAN",
        "STREET": "AKKAVAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "KARAÇOBAN",
        "STREET": "BAHÇELİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "KARAÇOBAN",
        "STREET": "BAĞLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "KARAÇOBAN",
        "STREET": "BOZYAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "KARAÇOBAN",
        "STREET": "BUDAKLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "KARAÇOBAN",
        "STREET": "BURNAZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "KARAÇOBAN",
        "STREET": "BİNPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "KARAÇOBAN",
        "STREET": "DEDEÖREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "KARAÇOBAN",
        "STREET": "DOĞANBEY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "KARAÇOBAN",
        "STREET": "DUMAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "KARAÇOBAN",
        "STREET": "ERENLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "KARAÇOBAN",
        "STREET": "ERHANLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "KARAÇOBAN",
        "STREET": "GÜNDÜZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "KARAÇOBAN",
        "STREET": "HACILAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "KARAÇOBAN",
        "STREET": "KARAGÖZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "KARAÇOBAN",
        "STREET": "KARAKÖPRÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "KARAÇOBAN",
        "STREET": "KARMIŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "KARAÇOBAN",
        "STREET": "KAVAKLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "KARAÇOBAN",
        "STREET": "KIRIMKAYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "KARAÇOBAN",
        "STREET": "KOPAL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "KARAÇOBAN",
        "STREET": "KUŞLUCA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "KARAÇOBAN",
        "STREET": "MARUF MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "KARAÇOBAN",
        "STREET": "MOLLADAVUT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "KARAÇOBAN",
        "STREET": "OVAYONCALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "KARAÇOBAN",
        "STREET": "SARIVELİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "KARAÇOBAN",
        "STREET": "SEYHAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "KARAÇOBAN",
        "STREET": "ÇATALGÜL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "KÖPRÜKÖY",
        "STREET": "AKÇAM MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "KÖPRÜKÖY",
        "STREET": "ALACA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "KÖPRÜKÖY",
        "STREET": "ATAKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "KÖPRÜKÖY",
        "STREET": "AĞCAŞAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "KÖPRÜKÖY",
        "STREET": "AŞAĞIKIZILKALE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "KÖPRÜKÖY",
        "STREET": "AŞAĞIÇAKMAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "KÖPRÜKÖY",
        "STREET": "BUĞDAYLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "KÖPRÜKÖY",
        "STREET": "CAMİKEBİR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "KÖPRÜKÖY",
        "STREET": "DEREBAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "KÖPRÜKÖY",
        "STREET": "DUATEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "KÖPRÜKÖY",
        "STREET": "DUMANKAYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "KÖPRÜKÖY",
        "STREET": "DİLEK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "KÖPRÜKÖY",
        "STREET": "EMRE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "KÖPRÜKÖY",
        "STREET": "EYÜPLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "KÖPRÜKÖY",
        "STREET": "EĞİRMEZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "KÖPRÜKÖY",
        "STREET": "GEYİKLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "KÖPRÜKÖY",
        "STREET": "GÖLÇAYIR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "KÖPRÜKÖY",
        "STREET": "GÜZELHİSAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "KÖPRÜKÖY",
        "STREET": "ILICASU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "KÖPRÜKÖY",
        "STREET": "KARATAŞLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "KÖPRÜKÖY",
        "STREET": "KAYABAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "KÖPRÜKÖY",
        "STREET": "KIYIKONAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "KÖPRÜKÖY",
        "STREET": "MARİFET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "KÖPRÜKÖY",
        "STREET": "MESCİTLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "KÖPRÜKÖY",
        "STREET": "ORTAKLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "KÖPRÜKÖY",
        "STREET": "PEKECİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "KÖPRÜKÖY",
        "STREET": "SARITAŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "KÖPRÜKÖY",
        "STREET": "SAVATLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "KÖPRÜKÖY",
        "STREET": "SOĞUKSU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "KÖPRÜKÖY",
        "STREET": "TOPÇU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "KÖPRÜKÖY",
        "STREET": "YAPAĞILI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "KÖPRÜKÖY",
        "STREET": "YAĞAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "KÖPRÜKÖY",
        "STREET": "YEMLİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "KÖPRÜKÖY",
        "STREET": "YEŞİLÖZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "KÖPRÜKÖY",
        "STREET": "YILANLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "KÖPRÜKÖY",
        "STREET": "YUKARIKIZILCA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "KÖPRÜKÖY",
        "STREET": "YUKARIKIZILKALE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "KÖPRÜKÖY",
        "STREET": "YUKARISÖĞÜTLÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "KÖPRÜKÖY",
        "STREET": "ZİYARET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "KÖPRÜKÖY",
        "STREET": "ÇULLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "KÖPRÜKÖY",
        "STREET": "ÖRENTAŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "KÖPRÜKÖY",
        "STREET": "ŞEHİTLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "NARMAN",
        "STREET": "ALABALIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "NARMAN",
        "STREET": "ALACAYAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "NARMAN",
        "STREET": "ARAKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "NARMAN",
        "STREET": "AŞAĞIYAYLA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "NARMAN",
        "STREET": "BAŞKALE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "NARMAN",
        "STREET": "BEYLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "NARMAN",
        "STREET": "BOĞAKALE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "NARMAN",
        "STREET": "CAMİİKEBİR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "NARMAN",
        "STREET": "CAMİİSAĞIR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "NARMAN",
        "STREET": "DAĞYOLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "NARMAN",
        "STREET": "DEMİRDAĞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "NARMAN",
        "STREET": "ERGAZİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "NARMAN",
        "STREET": "GÖKDAĞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "NARMAN",
        "STREET": "GÖLLÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "NARMAN",
        "STREET": "GÜLLÜDAĞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "NARMAN",
        "STREET": "GÜVENLİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "NARMAN",
        "STREET": "KAMIŞÖZÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "NARMAN",
        "STREET": "KARADAĞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "NARMAN",
        "STREET": "KARAPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "NARMAN",
        "STREET": "KIŞLAKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "NARMAN",
        "STREET": "KOYUNÖREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "NARMAN",
        "STREET": "KOÇKAYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "NARMAN",
        "STREET": "KURUÇALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "NARMAN",
        "STREET": "KİLİMLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "NARMAN",
        "STREET": "MAHMUTÇAVUŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "NARMAN",
        "STREET": "MERCİMEKLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "NARMAN",
        "STREET": "OTLUTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "NARMAN",
        "STREET": "PINARYOLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "NARMAN",
        "STREET": "SAMİKALE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "NARMAN",
        "STREET": "SAPANLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "NARMAN",
        "STREET": "SAVAŞÇILAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "NARMAN",
        "STREET": "SERİNSU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "NARMAN",
        "STREET": "SEYYİD ALİ BABA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "NARMAN",
        "STREET": "SÜLÜKLÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "NARMAN",
        "STREET": "SÜTPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "NARMAN",
        "STREET": "TAŞBURUN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "NARMAN",
        "STREET": "TELLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "NARMAN",
        "STREET": "TOYGARLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "NARMAN",
        "STREET": "TUZLA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "NARMAN",
        "STREET": "YANIKTAŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "NARMAN",
        "STREET": "YOLDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "NARMAN",
        "STREET": "YUKARIYAYLA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "NARMAN",
        "STREET": "ÇAMLIYAYLA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "NARMAN",
        "STREET": "ÇİMENLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "NARMAN",
        "STREET": "ŞEHİTLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "NARMAN",
        "STREET": "ŞEKERLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "OLTU",
        "STREET": "ALATARLA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "OLTU",
        "STREET": "ARITAŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "OLTU",
        "STREET": "ASLANPAŞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "OLTU",
        "STREET": "AYVALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "OLTU",
        "STREET": "AYYILDIZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "OLTU",
        "STREET": "AŞAĞIKUMLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "OLTU",
        "STREET": "AŞAĞIÇAMLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "OLTU",
        "STREET": "BAHÇECİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "OLTU",
        "STREET": "BAHÇELİKIŞLA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "OLTU",
        "STREET": "BALLICA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "OLTU",
        "STREET": "BAŞAKLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "OLTU",
        "STREET": "BAŞBAĞLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "OLTU",
        "STREET": "CUMHURİYET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "OLTU",
        "STREET": "DAMARLITAŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "OLTU",
        "STREET": "DAĞDİBİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "OLTU",
        "STREET": "DEMİRTAŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "OLTU",
        "STREET": "DEREBAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "OLTU",
        "STREET": "DOKUZDEĞİRMEN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "OLTU",
        "STREET": "DURALAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "OLTU",
        "STREET": "ELMADÜZÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "OLTU",
        "STREET": "ERDOĞMUŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "OLTU",
        "STREET": "ESENYAMAÇ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "OLTU",
        "STREET": "GÖKÇEDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "OLTU",
        "STREET": "GÜNLÜCE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "OLTU",
        "STREET": "GÜRYAPRAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "OLTU",
        "STREET": "GÜZELSU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "OLTU",
        "STREET": "HALİTPAŞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "OLTU",
        "STREET": "KALEBOĞAZI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "OLTU",
        "STREET": "KARABEKİR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "OLTU",
        "STREET": "KARATAŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "OLTU",
        "STREET": "KAYAALTI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "OLTU",
        "STREET": "KEMERKAYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "OLTU",
        "STREET": "KONUKSEVEN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "OLTU",
        "STREET": "KÜÇÜKORUCUK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "OLTU",
        "STREET": "NÜĞÜRCÜK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "OLTU",
        "STREET": "OBAYAYLA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "OLTU",
        "STREET": "OLTU KAPALI CEZAEVİ MÜDÜRLÜĞÜ",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "OLTU",
        "STREET": "ORUCUK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "OLTU",
        "STREET": "SARISAZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "OLTU",
        "STREET": "SAĞLICAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "OLTU",
        "STREET": "SUBATUK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "OLTU",
        "STREET": "SÜLEYMANLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "OLTU",
        "STREET": "SÜLÜNKAYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "OLTU",
        "STREET": "TEKELİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "OLTU",
        "STREET": "TOKLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "OLTU",
        "STREET": "TOPKAYNAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "OLTU",
        "STREET": "TOPRAKKALE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "OLTU",
        "STREET": "TUTLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "OLTU",
        "STREET": "TUTMAÇ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "OLTU",
        "STREET": "TUZLA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "OLTU",
        "STREET": "VİŞNELİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "OLTU",
        "STREET": "YARBAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "OLTU",
        "STREET": "YASİN HAŞİMOĞLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "OLTU",
        "STREET": "YAYLAÇAYIR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "OLTU",
        "STREET": "YOLBOYU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "OLTU",
        "STREET": "YUKARIKUMLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "OLTU",
        "STREET": "YUKARIÇAMLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "OLTU",
        "STREET": "YUSUF ZİYABEY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "OLTU",
        "STREET": "ÇAMLIBEL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "OLTU",
        "STREET": "ÇANAKPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "OLTU",
        "STREET": "ÇATAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "OLTU",
        "STREET": "ÇATALSÖĞÜT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "OLTU",
        "STREET": "ÇAYÜSTÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "OLTU",
        "STREET": "ÇENGELLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "OLTU",
        "STREET": "ÖZDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "OLTU",
        "STREET": "ÜNLÜKAYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "OLTU",
        "STREET": "İNANMIŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "OLTU",
        "STREET": "İNCİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "OLTU",
        "STREET": "İPEKÇAYIRI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "OLTU",
        "STREET": "İRİAĞAÇ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "OLTU",
        "STREET": "İĞDELİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "OLTU",
        "STREET": "ŞEHİTLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "OLTU",
        "STREET": "ŞENDURAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "OLUR",
        "STREET": "AKBAYIR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "OLUR",
        "STREET": "AKTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "OLUR",
        "STREET": "ALTUNKAYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "OLUR",
        "STREET": "ATLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "OLUR",
        "STREET": "AŞAĞIKARACASU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "OLUR",
        "STREET": "AŞAĞIÇAYIRLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "OLUR",
        "STREET": "BEĞENDİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "OLUR",
        "STREET": "BEŞKAYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "OLUR",
        "STREET": "BOZDOĞAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "OLUR",
        "STREET": "BOĞAZGÖREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "OLUR",
        "STREET": "COŞKUNLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "OLUR",
        "STREET": "EKİNLİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "OLUR",
        "STREET": "EĞLEK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "OLUR",
        "STREET": "FİLİZLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "OLUR",
        "STREET": "GÜNGÖRÜNDÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "OLUR",
        "STREET": "HÜKKAM MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "OLUR",
        "STREET": "ILIKAYNAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "OLUR",
        "STREET": "KABAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "OLUR",
        "STREET": "KALEDİBİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "OLUR",
        "STREET": "KARAKOÇLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "OLUR",
        "STREET": "KEKİKLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "OLUR",
        "STREET": "KEÇİLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "OLUR",
        "STREET": "KÖPRÜBAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "OLUR",
        "STREET": "MERKEZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "OLUR",
        "STREET": "MERKEZ-MERKEZ YEŞİLBAĞLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "OLUR",
        "STREET": "OLGUN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "OLUR",
        "STREET": "OLURDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "OLUR",
        "STREET": "ORMANAĞZI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "OLUR",
        "STREET": "OĞUZKENT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "OLUR",
        "STREET": "SARIBAŞAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "OLUR",
        "STREET": "SOĞUKGÖZE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "OLUR",
        "STREET": "SÜNGÜBAYIR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "OLUR",
        "STREET": "TAŞGEÇİT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "OLUR",
        "STREET": "UZUNHARMAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "OLUR",
        "STREET": "YAYLABAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "OLUR",
        "STREET": "YEŞİLBAĞLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "OLUR",
        "STREET": "YILDIZKAYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "OLUR",
        "STREET": "YOLGÖZLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "OLUR",
        "STREET": "YUKARIKARACASU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "OLUR",
        "STREET": "YUKARIÇAYIRLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "OLUR",
        "STREET": "ÇATAKSU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "OLUR",
        "STREET": "ÜRÜNLÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "OLUR",
        "STREET": "ŞALPAZARI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "PALANDÖKEN",
        "STREET": "ABDURRAHMAN GAZİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "PALANDÖKEN",
        "STREET": "ADNAN MENDERES MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "PALANDÖKEN",
        "STREET": "ALİ BEZİRGAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "PALANDÖKEN",
        "STREET": "AZİZİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "PALANDÖKEN",
        "STREET": "BÖREKLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "PALANDÖKEN",
        "STREET": "DEREBOĞAZI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "PALANDÖKEN",
        "STREET": "GÜLLÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "PALANDÖKEN",
        "STREET": "GÜZELYURT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "PALANDÖKEN",
        "STREET": "HANCIĞAZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "PALANDÖKEN",
        "STREET": "HÜSEYİN AVNİ ULAŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "PALANDÖKEN",
        "STREET": "KONAKLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "PALANDÖKEN",
        "STREET": "KÜMBET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "PALANDÖKEN",
        "STREET": "MÜFTÜ SOLAKZADE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "PALANDÖKEN",
        "STREET": "NENE HATUN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "PALANDÖKEN",
        "STREET": "SIĞIRLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "PALANDÖKEN",
        "STREET": "TAŞLIGÜNEY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "PALANDÖKEN",
        "STREET": "TEKKEDERESİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "PALANDÖKEN",
        "STREET": "TEPEKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "PALANDÖKEN",
        "STREET": "TOPARLAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "PALANDÖKEN",
        "STREET": "UZUN AHMET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "PALANDÖKEN",
        "STREET": "YAĞMURCUK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "PALANDÖKEN",
        "STREET": "YIKILGAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "PALANDÖKEN",
        "STREET": "YUKARIYENİCE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "PALANDÖKEN",
        "STREET": "YUNUSEMRE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "PALANDÖKEN",
        "STREET": "ÇEPERLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "PALANDÖKEN",
        "STREET": "ŞEHİTLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "PASİNLER",
        "STREET": "ACI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "PASİNLER",
        "STREET": "ALTINBAŞAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "PASİNLER",
        "STREET": "ALVAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "PASİNLER",
        "STREET": "ARDIÇLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "PASİNLER",
        "STREET": "AĞAÇMİNARE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "PASİNLER",
        "STREET": "AĞCALAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "PASİNLER",
        "STREET": "AŞITLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "PASİNLER",
        "STREET": "BAHÇELİEVLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "PASİNLER",
        "STREET": "BALDIZI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "PASİNLER",
        "STREET": "BAŞÖREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "PASİNLER",
        "STREET": "BULKASIM MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "PASİNLER",
        "STREET": "BÜYÜKDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "PASİNLER",
        "STREET": "BÜYÜKTÜY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "PASİNLER",
        "STREET": "CAMİKEBİR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "PASİNLER",
        "STREET": "DEMİRDÖVEN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "PASİNLER",
        "STREET": "EMİRŞEYH MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "PASİNLER",
        "STREET": "EPSEMCE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "PASİNLER",
        "STREET": "ERZURUMKAPI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "PASİNLER",
        "STREET": "ESENDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "PASİNLER",
        "STREET": "GERDEKKAYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "PASİNLER",
        "STREET": "GÖLCİĞEZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "PASİNLER",
        "STREET": "HANAHMET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "PASİNLER",
        "STREET": "HASANDEDE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "PASİNLER",
        "STREET": "KAPLICALAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "PASİNLER",
        "STREET": "KARAKALE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "PASİNLER",
        "STREET": "KARAVELET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "PASİNLER",
        "STREET": "KASIMPAŞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "PASİNLER",
        "STREET": "KAVUŞTURAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "PASİNLER",
        "STREET": "KETHUDA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "PASİNLER",
        "STREET": "KEVENLİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "PASİNLER",
        "STREET": "KIZILÖREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "PASİNLER",
        "STREET": "KOTANDÜZÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "PASİNLER",
        "STREET": "KURBANÇAYIR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "PASİNLER",
        "STREET": "KURTULUŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "PASİNLER",
        "STREET": "KÜÇÜKTÜY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "PASİNLER",
        "STREET": "OTLUKKAPI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "PASİNLER",
        "STREET": "OVAKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "PASİNLER",
        "STREET": "PAŞABEY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "PASİNLER",
        "STREET": "PELİTLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "PASİNLER",
        "STREET": "PORSUK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "PASİNLER",
        "STREET": "PUSUDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "PASİNLER",
        "STREET": "REŞADİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "PASİNLER",
        "STREET": "SAKSI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "PASİNLER",
        "STREET": "SERÇEBOĞAZI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "PASİNLER",
        "STREET": "SUNAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "PASİNLER",
        "STREET": "SİVAS MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "PASİNLER",
        "STREET": "TAŞAĞIL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "PASİNLER",
        "STREET": "TAŞKAYNAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "PASİNLER",
        "STREET": "TAŞLIGÜNEY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "PASİNLER",
        "STREET": "TAŞLIYURT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "PASİNLER",
        "STREET": "TEPECİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "PASİNLER",
        "STREET": "TİMAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "PASİNLER",
        "STREET": "UZUNARK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "PASİNLER",
        "STREET": "YAMAÇ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "PASİNLER",
        "STREET": "YASTIKTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "PASİNLER",
        "STREET": "YAVUZLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "PASİNLER",
        "STREET": "YAYLA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "PASİNLER",
        "STREET": "YAYLADAĞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "PASİNLER",
        "STREET": "YENİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "PASİNLER",
        "STREET": "YENİKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "PASİNLER",
        "STREET": "YUKARIDANİŞMENT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "PASİNLER",
        "STREET": "YUKARIÇAKMAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "PASİNLER",
        "STREET": "YİĞİTPINARI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "PASİNLER",
        "STREET": "YİĞİTTAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "PASİNLER",
        "STREET": "ÇAKIRTAŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "PASİNLER",
        "STREET": "ÇALIYAZI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "PASİNLER",
        "STREET": "ÇAMLICA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "PASİNLER",
        "STREET": "ÇÖĞENDER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "PASİNLER",
        "STREET": "ÇİÇEKLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "PASİNLER",
        "STREET": "ÖVENLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "PASİNLER",
        "STREET": "ÜĞÜMÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "PASİNLER",
        "STREET": "ŞEHİT BURAK KARAKOÇ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "PAZARYOLU",
        "STREET": "21HAZİRAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "PAZARYOLU",
        "STREET": "AKBULUT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "PAZARYOLU",
        "STREET": "ALIÇLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "PAZARYOLU",
        "STREET": "AMBARALAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "PAZARYOLU",
        "STREET": "AYÇUKURU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "PAZARYOLU",
        "STREET": "BAYINDIR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "PAZARYOLU",
        "STREET": "BURÇAKLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "PAZARYOLU",
        "STREET": "BÜYÜKDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "PAZARYOLU",
        "STREET": "CENNETPINARI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "PAZARYOLU",
        "STREET": "CEVİZLİDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "PAZARYOLU",
        "STREET": "DELİKTAŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "PAZARYOLU",
        "STREET": "DEMİRGÖZE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "PAZARYOLU",
        "STREET": "DİKMETAŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "PAZARYOLU",
        "STREET": "ESENYURT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "PAZARYOLU",
        "STREET": "GÖLYANI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "PAZARYOLU",
        "STREET": "GÖZTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "PAZARYOLU",
        "STREET": "GÜLÇİMEN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "PAZARYOLU",
        "STREET": "GÜNEYSU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "PAZARYOLU",
        "STREET": "HACILAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "PAZARYOLU",
        "STREET": "KARAKOÇ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "PAZARYOLU",
        "STREET": "KARATAŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "PAZARYOLU",
        "STREET": "KILIÇÇI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "PAZARYOLU",
        "STREET": "KONAKYERİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "PAZARYOLU",
        "STREET": "KORKUTKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "PAZARYOLU",
        "STREET": "KOZLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "PAZARYOLU",
        "STREET": "KUMAŞKAYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "PAZARYOLU",
        "STREET": "KUYMAKLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "PAZARYOLU",
        "STREET": "KÖŞEYOLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "PAZARYOLU",
        "STREET": "KÜMBETTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "PAZARYOLU",
        "STREET": "LALELİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "PAZARYOLU",
        "STREET": "MERKEZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "PAZARYOLU",
        "STREET": "MEŞEBAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "PAZARYOLU",
        "STREET": "PAMUKLUDAĞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "PAZARYOLU",
        "STREET": "SADAKA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "PAZARYOLU",
        "STREET": "SERGENKAYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "PAZARYOLU",
        "STREET": "SÜLEYMANBAĞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "PAZARYOLU",
        "STREET": "YAYLALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "PAZARYOLU",
        "STREET": "YAYLAÖZÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "PAZARYOLU",
        "STREET": "YENİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "PAZARYOLU",
        "STREET": "YİĞİTBAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "PAZARYOLU",
        "STREET": "ÇATAKBAHÇE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "PAZARYOLU",
        "STREET": "ÇİFTEPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "PAZARYOLU",
        "STREET": "ŞEHİTLİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "TEKMAN",
        "STREET": "AKDAMAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "TEKMAN",
        "STREET": "AKDAĞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "TEKMAN",
        "STREET": "AKPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "TEKMAN",
        "STREET": "ALABAYIR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "TEKMAN",
        "STREET": "AYDINLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "TEKMAN",
        "STREET": "AYDINLIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "TEKMAN",
        "STREET": "AĞCAKOCA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "TEKMAN",
        "STREET": "AŞAĞIHANBEYİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "TEKMAN",
        "STREET": "AŞAĞITEPECİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "TEKMAN",
        "STREET": "BEYKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "TEKMAN",
        "STREET": "BEŞDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "TEKMAN",
        "STREET": "CİHAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "TEKMAN",
        "STREET": "DALSÖĞÜT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "TEKMAN",
        "STREET": "DELİLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "TEKMAN",
        "STREET": "DENGİZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "TEKMAN",
        "STREET": "DÜZYURT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "TEKMAN",
        "STREET": "ERENCE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "TEKMAN",
        "STREET": "GEÇİTKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "TEKMAN",
        "STREET": "GÖKOĞLAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "TEKMAN",
        "STREET": "GÖZLÜCE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "TEKMAN",
        "STREET": "GÜLVEREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "TEKMAN",
        "STREET": "GÜMÜŞLÜK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "TEKMAN",
        "STREET": "GÜNDAMI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "TEKMAN",
        "STREET": "GÜNEŞLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "TEKMAN",
        "STREET": "GÜRGÜR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "TEKMAN",
        "STREET": "GÜZELDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "TEKMAN",
        "STREET": "HACIÖMER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "TEKMAN",
        "STREET": "HAMZALAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "TEKMAN",
        "STREET": "HÜRRİYET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "TEKMAN",
        "STREET": "HÜSEYİNAĞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "TEKMAN",
        "STREET": "ILIGÖZE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "TEKMAN",
        "STREET": "IŞIKLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "TEKMAN",
        "STREET": "KALAYCI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "TEKMAN",
        "STREET": "KARAPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "TEKMAN",
        "STREET": "KARATAŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "TEKMAN",
        "STREET": "KARATEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "TEKMAN",
        "STREET": "KARLICA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "TEKMAN",
        "STREET": "KATRANLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "TEKMAN",
        "STREET": "KAYABOĞAZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "TEKMAN",
        "STREET": "KAZANCIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "TEKMAN",
        "STREET": "KIRIKAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "TEKMAN",
        "STREET": "KOÇYAYLA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "TEKMAN",
        "STREET": "KURUCA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "TEKMAN",
        "STREET": "KÖRSU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "TEKMAN",
        "STREET": "KÜLLÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "TEKMAN",
        "STREET": "MESCİTLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "TEKMAN",
        "STREET": "MOLLAMEHMET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "TEKMAN",
        "STREET": "SUSUZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "TEKMAN",
        "STREET": "TAŞKESEN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "TEKMAN",
        "STREET": "TOPTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "TEKMAN",
        "STREET": "TURNAGÖL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "TEKMAN",
        "STREET": "VATAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "TEKMAN",
        "STREET": "YALINCA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "TEKMAN",
        "STREET": "YERKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "TEKMAN",
        "STREET": "YEŞİLÖREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "TEKMAN",
        "STREET": "YONCALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "TEKMAN",
        "STREET": "YUKARIHANBEYİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "TEKMAN",
        "STREET": "YUKARITEPECİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "TEKMAN",
        "STREET": "YUVAKLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "TEKMAN",
        "STREET": "YÜCEPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "TEKMAN",
        "STREET": "YİĞİTLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "TEKMAN",
        "STREET": "ÇATAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "TEKMAN",
        "STREET": "ÇATKALE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "TEKMAN",
        "STREET": "ÇAYIRDAĞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "TEKMAN",
        "STREET": "ÇAĞLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "TEKMAN",
        "STREET": "ÇEVİRME MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "TEKMAN",
        "STREET": "ÇUKURYAYLA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "TEKMAN",
        "STREET": "ÇİÇEKDAĞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "TEKMAN",
        "STREET": "İNCESU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "TEKMAN",
        "STREET": "İSMETPAŞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "TEKMAN",
        "STREET": "ŞAKŞAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "TORTUM",
        "STREET": "AKBABA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "TORTUM",
        "STREET": "AKSU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "TORTUM",
        "STREET": "AKTAŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "TORTUM",
        "STREET": "ALAPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "TORTUM",
        "STREET": "ALPARSLAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "TORTUM",
        "STREET": "ARILI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "TORTUM",
        "STREET": "AŞAĞI SERDARLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "TORTUM",
        "STREET": "BAHÇELİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "TORTUM",
        "STREET": "BALLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "TORTUM",
        "STREET": "BAĞBAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "TORTUM",
        "STREET": "CİHANLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "TORTUM",
        "STREET": "DEMİRCİLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "TORTUM",
        "STREET": "DEREKAPI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "TORTUM",
        "STREET": "DERİNPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "TORTUM",
        "STREET": "DORUKLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "TORTUM",
        "STREET": "DİKMEN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "TORTUM",
        "STREET": "ESENDURAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "TORTUM",
        "STREET": "GÖKDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "TORTUM",
        "STREET": "HAMİDİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "TORTUM",
        "STREET": "KALEBOYNU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "TORTUM",
        "STREET": "KALEDİBİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "TORTUM",
        "STREET": "KAPIKAYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "TORTUM",
        "STREET": "KARLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "TORTUM",
        "STREET": "KAZANDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "TORTUM",
        "STREET": "KEMERKAYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "TORTUM",
        "STREET": "KIRMALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "TORTUM",
        "STREET": "KONAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "TORTUM",
        "STREET": "KİREÇLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "TORTUM",
        "STREET": "MEYDANLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "TORTUM",
        "STREET": "PEHLİVANLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "TORTUM",
        "STREET": "PEYNİRLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "TORTUM",
        "STREET": "SERDARLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "TORTUM",
        "STREET": "SUYATAĞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "TORTUM",
        "STREET": "SÖĞÜTLÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "TORTUM",
        "STREET": "TATLISU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "TORTUM",
        "STREET": "TAŞBAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "TORTUM",
        "STREET": "TAŞOLUK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "TORTUM",
        "STREET": "TORTUMKALE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "TORTUM",
        "STREET": "TİPİLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "TORTUM",
        "STREET": "UZUNKAVAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "TORTUM",
        "STREET": "VİŞNELİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "TORTUM",
        "STREET": "YAMANKAYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "TORTUM",
        "STREET": "YAVUZ SULTAN SELİM MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "TORTUM",
        "STREET": "YAZYURDU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "TORTUM",
        "STREET": "YAĞCILAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "TORTUM",
        "STREET": "YELLİTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "TORTUM",
        "STREET": "YUKARISİVRİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "TORTUM",
        "STREET": "YUMAKLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "TORTUM",
        "STREET": "ZİYARETLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "TORTUM",
        "STREET": "ÇAKILLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "TORTUM",
        "STREET": "ÇAMLICA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "TORTUM",
        "STREET": "ÇARDAKLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "TORTUM",
        "STREET": "ÇATALDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "TORTUM",
        "STREET": "ÇAYLICA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "TORTUM",
        "STREET": "ÇİFTLİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "TORTUM",
        "STREET": "ÇİVİLİKAYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "TORTUM",
        "STREET": "İNCEDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "TORTUM",
        "STREET": "ŞENYURT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "UZUNDERE",
        "STREET": "ALTINÇANAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "UZUNDERE",
        "STREET": "BALIKLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "UZUNDERE",
        "STREET": "CEVİZLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "UZUNDERE",
        "STREET": "CÖMERTLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "UZUNDERE",
        "STREET": "DİKYAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "UZUNDERE",
        "STREET": "ERİKLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "UZUNDERE",
        "STREET": "GÖLBAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "UZUNDERE",
        "STREET": "KİRAZLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "UZUNDERE",
        "STREET": "MERKEZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "UZUNDERE",
        "STREET": "MURATEFENDİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "UZUNDERE",
        "STREET": "SAPACA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "UZUNDERE",
        "STREET": "SEYİTEFENDİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "UZUNDERE",
        "STREET": "ULUBAĞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "UZUNDERE",
        "STREET": "UZUNDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "UZUNDERE",
        "STREET": "YAYLA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "UZUNDERE",
        "STREET": "ÇAMLIYAMAÇ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "UZUNDERE",
        "STREET": "ÇAYBAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "UZUNDERE",
        "STREET": "ÇAĞLAYAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "YAKUTİYE",
        "STREET": "AKDAĞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "YAKUTİYE",
        "STREET": "AKTOPRAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "YAKUTİYE",
        "STREET": "ALTINBULAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "YAKUTİYE",
        "STREET": "ALTINTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "YAKUTİYE",
        "STREET": "ARIBAHÇE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "YAKUTİYE",
        "STREET": "DADAŞKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "YAKUTİYE",
        "STREET": "DEĞİRMENLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "YAKUTİYE",
        "STREET": "DUMLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "YAKUTİYE",
        "STREET": "ERZURUM DUMLU 1 NOLU YÜKSEK GÜVENLİKLİ KAPALI CEZA",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "YAKUTİYE",
        "STREET": "ERZURUM DUMLU 2 NOLU YÜKSEK GÜVENLİKLİ KAPALI CEZA",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "YAKUTİYE",
        "STREET": "ERZURUM E TİPİ KAPALI CEZA İNFAZ KURUMU",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "YAKUTİYE",
        "STREET": "ERZURUM H TİPİ KAPALI CEZA İNFAZ KURUMU",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "YAKUTİYE",
        "STREET": "GÖKÇEYAMAÇ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "YAKUTİYE",
        "STREET": "GÜLPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "YAKUTİYE",
        "STREET": "GÜNGÖRMEZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "YAKUTİYE",
        "STREET": "GÜZELOVA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "YAKUTİYE",
        "STREET": "GÜZELYAYLA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "YAKUTİYE",
        "STREET": "KARAGÖBEK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "YAKUTİYE",
        "STREET": "KARASU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "YAKUTİYE",
        "STREET": "KAZIM KARABEKİR PAŞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "YAKUTİYE",
        "STREET": "KIRKGÖZE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "YAKUTİYE",
        "STREET": "KIRMIZITAŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "YAKUTİYE",
        "STREET": "KURTULUŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "YAKUTİYE",
        "STREET": "KÖSE MEHMET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "YAKUTİYE",
        "STREET": "KÖŞKKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "YAKUTİYE",
        "STREET": "LALAPAŞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "YAKUTİYE",
        "STREET": "MURATGELDİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "YAKUTİYE",
        "STREET": "MURATPAŞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "YAKUTİYE",
        "STREET": "MÜLK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "YAKUTİYE",
        "STREET": "ORTA DÜZÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "YAKUTİYE",
        "STREET": "RABİA ANA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "YAKUTİYE",
        "STREET": "SOĞUKÇERMİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "YAKUTİYE",
        "STREET": "SÖĞÜTYANI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "YAKUTİYE",
        "STREET": "UMUDUM MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "YAKUTİYE",
        "STREET": "UZUNYAYLA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "YAKUTİYE",
        "STREET": "YAZI PINARI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "YAKUTİYE",
        "STREET": "YERLİ SU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "YAKUTİYE",
        "STREET": "YEŞİLDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "YAKUTİYE",
        "STREET": "YEŞİLOVA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "YAKUTİYE",
        "STREET": "YEŞİLYAYLA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "YAKUTİYE",
        "STREET": "ÇAYIRCA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "YAKUTİYE",
        "STREET": "ÇAYIRTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "YAKUTİYE",
        "STREET": "ÇİFTLİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "YAKUTİYE",
        "STREET": "ÖMER NASUHİ BİLMEN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "YAKUTİYE",
        "STREET": "ÜNİVERSİTE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "YAKUTİYE",
        "STREET": "İBRAHİM HAKKI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "YAKUTİYE",
        "STREET": "ŞENYURT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "YAKUTİYE",
        "STREET": "ŞÜKRÜPAŞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "ÇAT",
        "STREET": "AĞAKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "ÇAT",
        "STREET": "AŞAĞIÇAT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "ÇAT",
        "STREET": "BABADERESİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "ÇAT",
        "STREET": "BARDAKÇI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "ÇAT",
        "STREET": "BAYINDIR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "ÇAT",
        "STREET": "BAĞLICA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "ÇAT",
        "STREET": "BAŞKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "ÇAT",
        "STREET": "BOZYAZI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "ÇAT",
        "STREET": "BUDAKLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "ÇAT",
        "STREET": "DEĞİRMENLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "ÇAT",
        "STREET": "ELMAPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "ÇAT",
        "STREET": "GÖBEKÖREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "ÇAT",
        "STREET": "GÖKÇEŞEYH MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "ÇAT",
        "STREET": "GÖLKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "ÇAT",
        "STREET": "HATUNKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "ÇAT",
        "STREET": "KAPLICA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "ÇAT",
        "STREET": "KARABEY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "ÇAT",
        "STREET": "KARACA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "ÇAT",
        "STREET": "KARAŞEYH MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "ÇAT",
        "STREET": "KIZILCA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "ÇAT",
        "STREET": "KUMAŞLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "ÇAT",
        "STREET": "KURBANLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "ÇAT",
        "STREET": "KÖSELER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "ÇAT",
        "STREET": "MOLLAÖMER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "ÇAT",
        "STREET": "MURATÇAYIRI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "ÇAT",
        "STREET": "OYUKLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "ÇAT",
        "STREET": "PARMAKSIZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "ÇAT",
        "STREET": "SALTAŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "ÇAT",
        "STREET": "SARIKAYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "ÇAT",
        "STREET": "SARIKAŞIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "ÇAT",
        "STREET": "SOĞUKPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "ÇAT",
        "STREET": "SÖBEÇAYIR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "ÇAT",
        "STREET": "TAŞAĞIL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "ÇAT",
        "STREET": "TUZLATAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "ÇAT",
        "STREET": "TUZLUCA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "ÇAT",
        "STREET": "YARMAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "ÇAT",
        "STREET": "YAVİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "ÇAT",
        "STREET": "YAYLASUYU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "ÇAT",
        "STREET": "YUKARIÇAT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "ÇAT",
        "STREET": "ÇAYIRTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "ÇAT",
        "STREET": "ÇUKURÇAYIR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "ÇAT",
        "STREET": "ÇİMENÖZÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "ÇAT",
        "STREET": "ÇİRİŞLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "ÇAT",
        "STREET": "İŞKİNLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "ÇAT",
        "STREET": "ŞEYHHASAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "İSPİR",
        "STREET": "AHLATLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "İSPİR",
        "STREET": "AKGÜNEY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "İSPİR",
        "STREET": "AKPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "İSPİR",
        "STREET": "AKSEKİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "İSPİR",
        "STREET": "AKSU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "İSPİR",
        "STREET": "ALACABÜK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "İSPİR",
        "STREET": "ARAKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "İSPİR",
        "STREET": "ARDIÇLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "İSPİR",
        "STREET": "ARMUTLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "İSPİR",
        "STREET": "ATÜRKÜTEN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "İSPİR",
        "STREET": "AVCIKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "İSPİR",
        "STREET": "AŞAĞI FINDIKLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "İSPİR",
        "STREET": "AŞAĞI ÖZBAĞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "İSPİR",
        "STREET": "BADEMLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "İSPİR",
        "STREET": "BAHÇELİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "İSPİR",
        "STREET": "BAŞKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "İSPİR",
        "STREET": "BAŞPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "İSPİR",
        "STREET": "BAŞÇEŞME MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "İSPİR",
        "STREET": "BOSTANCI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "İSPİR",
        "STREET": "BOZAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "İSPİR",
        "STREET": "CANKURTARAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "İSPİR",
        "STREET": "CİBALİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "İSPİR",
        "STREET": "DEMİRBİLEK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "İSPİR",
        "STREET": "DEMİRKAYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "İSPİR",
        "STREET": "DEVEDAĞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "İSPİR",
        "STREET": "DEĞİRMENDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "İSPİR",
        "STREET": "DEĞİRMENLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "İSPİR",
        "STREET": "DURUKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "İSPİR",
        "STREET": "DÜZKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "İSPİR",
        "STREET": "ELMALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "İSPİR",
        "STREET": "GAZİLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "İSPİR",
        "STREET": "GEÇİTAĞZI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "İSPİR",
        "STREET": "GÖLYURT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "İSPİR",
        "STREET": "GÖÇKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "İSPİR",
        "STREET": "GÜLHAS MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "İSPİR",
        "STREET": "GÜLLÜBAĞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "İSPİR",
        "STREET": "GÜNDOĞDU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "İSPİR",
        "STREET": "GÜNEY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "İSPİR",
        "STREET": "HALİLPAŞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "İSPİR",
        "STREET": "IRMAKKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "İSPİR",
        "STREET": "KARAHAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "İSPİR",
        "STREET": "KARAKALE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "İSPİR",
        "STREET": "KARAKAMIŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "İSPİR",
        "STREET": "KARAKAYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "İSPİR",
        "STREET": "KARASEYDİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "İSPİR",
        "STREET": "KARŞIYAKA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "İSPİR",
        "STREET": "KAVAKLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "İSPİR",
        "STREET": "KAYNAKBAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "İSPİR",
        "STREET": "KIRIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "İSPİR",
        "STREET": "KIZILHASAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "İSPİR",
        "STREET": "KOÇ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "İSPİR",
        "STREET": "KÖPRÜKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "İSPİR",
        "STREET": "KÜMETAŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "İSPİR",
        "STREET": "KİRAZLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "İSPİR",
        "STREET": "LEYLEKKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "İSPİR",
        "STREET": "MADENKÖPRÜBAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "İSPİR",
        "STREET": "MESCİTLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "İSPİR",
        "STREET": "MEYDANLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "İSPİR",
        "STREET": "MORYAYLA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "İSPİR",
        "STREET": "MÜLKKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "İSPİR",
        "STREET": "NUMANPAŞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "İSPİR",
        "STREET": "ORTAKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "İSPİR",
        "STREET": "ORTAÖREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "İSPİR",
        "STREET": "PETEKLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "İSPİR",
        "STREET": "PINARLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "İSPİR",
        "STREET": "SANDIKLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "İSPİR",
        "STREET": "SIRAKONAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "İSPİR",
        "STREET": "SOĞUKSU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "İSPİR",
        "STREET": "TAŞBAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "İSPİR",
        "STREET": "TAŞLICA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "İSPİR",
        "STREET": "TEKPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "İSPİR",
        "STREET": "TEPECİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "İSPİR",
        "STREET": "ULUBEL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "İSPİR",
        "STREET": "ULUTAŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "İSPİR",
        "STREET": "YAYLACIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "İSPİR",
        "STREET": "YAĞLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "İSPİR",
        "STREET": "YEDİGÖL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "İSPİR",
        "STREET": "YEDİGÖZE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "İSPİR",
        "STREET": "YEŞİLTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "İSPİR",
        "STREET": "YEŞİLYURT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "İSPİR",
        "STREET": "YILDIZTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "İSPİR",
        "STREET": "YUKARI FINDIKLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "İSPİR",
        "STREET": "YUKARI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "İSPİR",
        "STREET": "YUKARI ÖZBAĞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "İSPİR",
        "STREET": "YUNUSKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "İSPİR",
        "STREET": "ZEYREK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "İSPİR",
        "STREET": "ÇAKMAKLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "İSPİR",
        "STREET": "ÇAMLICA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "İSPİR",
        "STREET": "ÇAMLIKAYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "İSPİR",
        "STREET": "ÇATAKKAYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "İSPİR",
        "STREET": "ÇAYIRBAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "İSPİR",
        "STREET": "ÇAYIRÖZÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "İSPİR",
        "STREET": "ÇİÇEKLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "İSPİR",
        "STREET": "ÖZLÜCE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "İSPİR",
        "STREET": "ÖZTOPRAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "İSPİR",
        "STREET": "ÜZÜMBAĞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "İSPİR",
        "STREET": "İKİSU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "İSPİR",
        "STREET": "İNCESU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "İSPİR",
        "STREET": "İYİDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "İSPİR",
        "STREET": "ŞENKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "ŞENKAYA",
        "STREET": "AKTAŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "ŞENKAYA",
        "STREET": "AKŞAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "ŞENKAYA",
        "STREET": "ALICIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "ŞENKAYA",
        "STREET": "ATYOLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "ŞENKAYA",
        "STREET": "AYDOĞDU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "ŞENKAYA",
        "STREET": "AŞAĞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "ŞENKAYA",
        "STREET": "AŞAĞIBAKRAÇLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "ŞENKAYA",
        "STREET": "BALKAYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "ŞENKAYA",
        "STREET": "BEREKETLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "ŞENKAYA",
        "STREET": "BEYKAYNAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "ŞENKAYA",
        "STREET": "BEŞPINARLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "ŞENKAYA",
        "STREET": "DELİKTAŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "ŞENKAYA",
        "STREET": "DEĞİRMENLİDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "ŞENKAYA",
        "STREET": "DOKUZELMA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "ŞENKAYA",
        "STREET": "DOLUNAY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "ŞENKAYA",
        "STREET": "DOĞANKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "ŞENKAYA",
        "STREET": "DÖRTYOL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "ŞENKAYA",
        "STREET": "ESENCE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "ŞENKAYA",
        "STREET": "ESENYURT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "ŞENKAYA",
        "STREET": "EVBAKAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "ŞENKAYA",
        "STREET": "GAZİLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "ŞENKAYA",
        "STREET": "GEZENEK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "ŞENKAYA",
        "STREET": "GÖLLET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "ŞENKAYA",
        "STREET": "GÖREŞKEN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "ŞENKAYA",
        "STREET": "GÖZALAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "ŞENKAYA",
        "STREET": "GÖZEBAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "ŞENKAYA",
        "STREET": "GÜLVEREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "ŞENKAYA",
        "STREET": "HOŞKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "ŞENKAYA",
        "STREET": "KAYALISU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "ŞENKAYA",
        "STREET": "KAYNAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "ŞENKAYA",
        "STREET": "KÖROĞLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "ŞENKAYA",
        "STREET": "KÖŞKKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "ŞENKAYA",
        "STREET": "KÜRKÇÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "ŞENKAYA",
        "STREET": "KİREÇLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "ŞENKAYA",
        "STREET": "NİŞANTAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "ŞENKAYA",
        "STREET": "ORMANLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "ŞENKAYA",
        "STREET": "OYUKTAŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "ŞENKAYA",
        "STREET": "PAŞALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "ŞENKAYA",
        "STREET": "PENEK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "ŞENKAYA",
        "STREET": "SARIKAYALAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "ŞENKAYA",
        "STREET": "SARIYAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "ŞENKAYA",
        "STREET": "SINDIRAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "ŞENKAYA",
        "STREET": "SUSUZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "ŞENKAYA",
        "STREET": "SÖĞÜTLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "ŞENKAYA",
        "STREET": "TAHTKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "ŞENKAYA",
        "STREET": "TAZEKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "ŞENKAYA",
        "STREET": "TEKETAŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "ŞENKAYA",
        "STREET": "TURNALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "ŞENKAYA",
        "STREET": "TÜTENOCAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "ŞENKAYA",
        "STREET": "TİMURKIŞLA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "ŞENKAYA",
        "STREET": "UĞURLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "ŞENKAYA",
        "STREET": "YANIKKAVAL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "ŞENKAYA",
        "STREET": "YAYMEŞE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "ŞENKAYA",
        "STREET": "YAZILI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "ŞENKAYA",
        "STREET": "YELKIRAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "ŞENKAYA",
        "STREET": "YEŞİLDEMET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "ŞENKAYA",
        "STREET": "YEŞİLKAYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "ŞENKAYA",
        "STREET": "YOĞURTÇULAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "ŞENKAYA",
        "STREET": "YUKARI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "ŞENKAYA",
        "STREET": "YUKARIBAKRAÇLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "ŞENKAYA",
        "STREET": "YÜNÖREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "ŞENKAYA",
        "STREET": "YÜREKLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "ŞENKAYA",
        "STREET": "ZÜMRÜT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "ŞENKAYA",
        "STREET": "ÇAMLIALAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "ŞENKAYA",
        "STREET": "ÇATALELMA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "ŞENKAYA",
        "STREET": "ÖZYURT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "ŞENKAYA",
        "STREET": "İKİZPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "ŞENKAYA",
        "STREET": "İNCEÇAY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "ŞENKAYA",
        "STREET": "İÇMESU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "ŞENKAYA",
        "STREET": "İĞDELİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZURUM",
        "DISTRICT": "ŞENKAYA",
        "STREET": "ŞENPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "MERKEZ",
        "STREET": "AHMETLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "MERKEZ",
        "STREET": "AKTOPRAK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "MERKEZ",
        "STREET": "AKYAZI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "MERKEZ",
        "STREET": "AKŞEMSETTİN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "MERKEZ",
        "STREET": "ALSANCAK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "MERKEZ",
        "STREET": "ARSLANLI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "MERKEZ",
        "STREET": "ATATÜRK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "MERKEZ",
        "STREET": "AYDOĞDU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "MERKEZ",
        "STREET": "AZİZ BABA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "MERKEZ",
        "STREET": "AĞILÖZÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "MERKEZ",
        "STREET": "BAHÇELİEVLER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "MERKEZ",
        "STREET": "BAHÇELİKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "MERKEZ",
        "STREET": "BAHÇEYAZI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "MERKEZ",
        "STREET": "BALIBEYİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "MERKEZ",
        "STREET": "BALLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "MERKEZ",
        "STREET": "BALTAŞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "MERKEZ",
        "STREET": "BARBAROS MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "MERKEZ",
        "STREET": "BARIŞ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "MERKEZ",
        "STREET": "BAYRAK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "MERKEZ",
        "STREET": "BAŞBAĞLAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "MERKEZ",
        "STREET": "BAŞPINAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "MERKEZ",
        "STREET": "BEŞSARAY KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "MERKEZ",
        "STREET": "BOZYAZI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "MERKEZ",
        "STREET": "BULUTLU MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "MERKEZ",
        "STREET": "BUĞDAYLI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "MERKEZ",
        "STREET": "BÜYÜKÇAKIRMAN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "MERKEZ",
        "STREET": "BİNKOÇ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "MERKEZ",
        "STREET": "CAFERLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "MERKEZ",
        "STREET": "CEMAL GÜRSEL MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "MERKEZ",
        "STREET": "CEVİZLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "MERKEZ",
        "STREET": "CUMHURİYET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "MERKEZ",
        "STREET": "DAVARLI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "MERKEZ",
        "STREET": "DEMETEVLER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "MERKEZ",
        "STREET": "DEMİRKENT MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "MERKEZ",
        "STREET": "DEREYURT MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "MERKEZ",
        "STREET": "DEĞİRMENKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "MERKEZ",
        "STREET": "EKMEKLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "MERKEZ",
        "STREET": "EKİNCİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "MERKEZ",
        "STREET": "ELMAKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "MERKEZ",
        "STREET": "ERGAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "MERKEZ",
        "STREET": "ERGENEKON MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "MERKEZ",
        "STREET": "ERSEVENLER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "MERKEZ",
        "STREET": "ERTUĞRUL GAZİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "MERKEZ",
        "STREET": "ERZİNCAN CEZA İNFAZ KURUMU",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "MERKEZ",
        "STREET": "ERZİNCAN KAPALI CEZA İNFAZ KURUMU",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "MERKEZ",
        "STREET": "ESENTEPE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "MERKEZ",
        "STREET": "FATİH MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "MERKEZ",
        "STREET": "GANİEFENDİÇİFTLİĞİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "MERKEZ",
        "STREET": "GAZİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "MERKEZ",
        "STREET": "GÖLCÜK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "MERKEZ",
        "STREET": "GÖLPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "MERKEZ",
        "STREET": "GÖYNE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "MERKEZ",
        "STREET": "GÜLABİBEY MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "MERKEZ",
        "STREET": "GÜLLÜCE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "MERKEZ",
        "STREET": "GÜMÜŞTARLA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "MERKEZ",
        "STREET": "GÜNBAĞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "MERKEZ",
        "STREET": "GÜNEBAKAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "MERKEZ",
        "STREET": "GÜRLEVİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "MERKEZ",
        "STREET": "HACIALİPALANGASI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "MERKEZ",
        "STREET": "HALİTPAŞA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "MERKEZ",
        "STREET": "HAMİDİYE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "MERKEZ",
        "STREET": "HANCI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "MERKEZ",
        "STREET": "HANCIÇİFTLİĞİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "MERKEZ",
        "STREET": "HANİDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "MERKEZ",
        "STREET": "HEYBELİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "MERKEZ",
        "STREET": "HOCA AHMET YESEVİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "MERKEZ",
        "STREET": "HOCABEY MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "MERKEZ",
        "STREET": "HÜRREMPALANGASI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "MERKEZ",
        "STREET": "ILIDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "MERKEZ",
        "STREET": "IŞIKPINAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "MERKEZ",
        "STREET": "KALECİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "MERKEZ",
        "STREET": "KARAAĞAÇ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "MERKEZ",
        "STREET": "KARADİĞİN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "MERKEZ",
        "STREET": "KARATUŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "MERKEZ",
        "STREET": "KAVAKYOLU MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "MERKEZ",
        "STREET": "KAZIM KARABEKİR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "MERKEZ",
        "STREET": "KEKLİKKAYASI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "MERKEZ",
        "STREET": "KILIÇKAYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "MERKEZ",
        "STREET": "KIZILAY MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "MERKEZ",
        "STREET": "KONAKBAŞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "MERKEZ",
        "STREET": "KOÇYATAĞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "MERKEZ",
        "STREET": "KURUTİLEK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "MERKEZ",
        "STREET": "KÜÇÜKÇAKIRMAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "MERKEZ",
        "STREET": "KİLİMLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "MERKEZ",
        "STREET": "MECİDİYE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "MERKEZ",
        "STREET": "MENDERES MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "MERKEZ",
        "STREET": "MENGÜCELİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "MERKEZ",
        "STREET": "MOLLAKÖY-ATATÜRK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "MERKEZ",
        "STREET": "MOLLAKÖY-CUMHURİYET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "MERKEZ",
        "STREET": "MOLLAKÖY-MAHMUTLU MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "MERKEZ",
        "STREET": "MOLLAKÖY-TEPECİK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "MERKEZ",
        "STREET": "MOLLAKÖY-YEŞİLYURT MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "MERKEZ",
        "STREET": "MUNZUR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "MERKEZ",
        "STREET": "MUSTAFA KEMAL PAŞA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "MERKEZ",
        "STREET": "MİMAR SİNAN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "MERKEZ",
        "STREET": "ORTAYURT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "MERKEZ",
        "STREET": "OSMAN GAZİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "MERKEZ",
        "STREET": "OSMANLI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "MERKEZ",
        "STREET": "OĞLAKTEPE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "MERKEZ",
        "STREET": "PAŞA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "MERKEZ",
        "STREET": "PEKMEZLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "MERKEZ",
        "STREET": "PINARÖNÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "MERKEZ",
        "STREET": "SANCAK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "MERKEZ",
        "STREET": "SARIGÖL MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "MERKEZ",
        "STREET": "SAZLIPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "MERKEZ",
        "STREET": "SAZTEPE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "MERKEZ",
        "STREET": "SELÇUKLU MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "MERKEZ",
        "STREET": "SOĞUKOLUK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "MERKEZ",
        "STREET": "SÖĞÜTÖZÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "MERKEZ",
        "STREET": "SÜTPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "MERKEZ",
        "STREET": "TAKSİM MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "MERKEZ",
        "STREET": "TANDIRLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "MERKEZ",
        "STREET": "TATLISU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "MERKEZ",
        "STREET": "TERZİBABA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "MERKEZ",
        "STREET": "TÜRKMENOĞLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "MERKEZ",
        "STREET": "ULALAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "MERKEZ",
        "STREET": "ULUKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "MERKEZ",
        "STREET": "YALINCA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "MERKEZ",
        "STREET": "YALNIZBAĞ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "MERKEZ",
        "STREET": "YAVUZ SELİM MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "MERKEZ",
        "STREET": "YENİDOĞAN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "MERKEZ",
        "STREET": "YENİKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "MERKEZ",
        "STREET": "YENİMAHALLE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "MERKEZ",
        "STREET": "YEŞİLÇAT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "MERKEZ",
        "STREET": "YEŞİLÇAY KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "MERKEZ",
        "STREET": "YUNUS EMRE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇARŞI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇATALARMUT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇATALÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇAĞLAYAN-ATATÜRK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇAĞLAYAN-CUMHURİYET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇAĞLAYAN-DEREBAĞ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇAĞLAYAN-ERDENE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇAĞLAYAN-MERTEKLİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇAĞLAYAN-YAMAÇLI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇAĞLAYAN-ŞELALE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇUBUKLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇUKURKUYU MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "MERKEZ",
        "STREET": "ÖZGÜRLÜK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "MERKEZ",
        "STREET": "ÜREK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "MERKEZ",
        "STREET": "ÜÇKONAK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "MERKEZ",
        "STREET": "İNÖNÜ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "MERKEZ",
        "STREET": "İZZETPAŞA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "MERKEZ",
        "STREET": "ŞEHİT CENGİZ TOPEL MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "MERKEZ",
        "STREET": "ŞEHİT SERHAT MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "MERKEZ",
        "STREET": "ŞEHİT SERHAT YURTBAŞI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "KEMAH",
        "STREET": "AKBUDAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "KEMAH",
        "STREET": "AKTAŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "KEMAH",
        "STREET": "AKYÜNLÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "KEMAH",
        "STREET": "AKÇA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "KEMAH",
        "STREET": "ALP KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "KEMAH",
        "STREET": "ATMA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "KEMAH",
        "STREET": "AYRANPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "KEMAH",
        "STREET": "AĞAÇSARAY KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "KEMAH",
        "STREET": "AŞAĞIGEDİK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "KEMAH",
        "STREET": "BEKLİMÇAY MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "KEMAH",
        "STREET": "BEŞİKLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "KEMAH",
        "STREET": "BOZOĞLAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "KEMAH",
        "STREET": "BOĞAZİÇİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "KEMAH",
        "STREET": "CEVİZLİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "KEMAH",
        "STREET": "CİRGİŞİN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "KEMAH",
        "STREET": "DEDEK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "KEMAH",
        "STREET": "DEDEOĞLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "KEMAH",
        "STREET": "DERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "KEMAH",
        "STREET": "DOĞAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "KEMAH",
        "STREET": "DOĞANBEYLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "KEMAH",
        "STREET": "DUTLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "KEMAH",
        "STREET": "DİKYAMAÇ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "KEMAH",
        "STREET": "ELMALI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "KEMAH",
        "STREET": "ERİÇ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "KEMAH",
        "STREET": "ESKİBAĞLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "KEMAH",
        "STREET": "ESİMLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "KEMAH",
        "STREET": "GEDİKTEPE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "KEMAH",
        "STREET": "GÖKKAYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "KEMAH",
        "STREET": "GÖLKAYNAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "KEMAH",
        "STREET": "GÖĞÜSBAĞI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "KEMAH",
        "STREET": "GÜLBAHÇE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "KEMAH",
        "STREET": "HAKBİLİR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "KEMAH",
        "STREET": "KARACA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "KEMAH",
        "STREET": "KARADAĞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "KEMAH",
        "STREET": "KARDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "KEMAH",
        "STREET": "KARŞIBAĞ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "KEMAH",
        "STREET": "KAYABAŞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "KEMAH",
        "STREET": "KAZANKAYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "KEMAH",
        "STREET": "KEDEK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "KEMAH",
        "STREET": "KEMERYAKA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "KEMAH",
        "STREET": "KERER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "KEMAH",
        "STREET": "KIRIKDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "KEMAH",
        "STREET": "KONUKSEVER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "KEMAH",
        "STREET": "KORUYOLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "KEMAH",
        "STREET": "KOÇKAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "KEMAH",
        "STREET": "KUTLUOVA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "KEMAH",
        "STREET": "KÖMÜR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "KEMAH",
        "STREET": "KÜPLÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "KEMAH",
        "STREET": "MAKSUTUŞAĞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "KEMAH",
        "STREET": "MEKTEPÖNÜ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "KEMAH",
        "STREET": "MERMERLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "KEMAH",
        "STREET": "MEZRA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "KEMAH",
        "STREET": "MURATBOYNU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "KEMAH",
        "STREET": "OLUKPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "KEMAH",
        "STREET": "ORTAGEDİK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "KEMAH",
        "STREET": "OĞUZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "KEMAH",
        "STREET": "PARMAKKAYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "KEMAH",
        "STREET": "PÖRHENKBAŞI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "KEMAH",
        "STREET": "SARIYAZI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "KEMAH",
        "STREET": "SERİNGÖZE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "KEMAH",
        "STREET": "SÜREK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "KEMAH",
        "STREET": "TAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "KEMAH",
        "STREET": "TANDIRBAŞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "KEMAH",
        "STREET": "TAŞBULAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "KEMAH",
        "STREET": "TUZLA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "KEMAH",
        "STREET": "ULUÇINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "KEMAH",
        "STREET": "YAHŞILAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "KEMAH",
        "STREET": "YARDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "KEMAH",
        "STREET": "YASTIKTEPE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "KEMAH",
        "STREET": "YAĞCA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "KEMAH",
        "STREET": "YUKARI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "KEMAH",
        "STREET": "YÜCEBELEN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "KEMAH",
        "STREET": "ÇAKIRLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "KEMAH",
        "STREET": "ÇALGI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "KEMAH",
        "STREET": "ÇALIKLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "KEMAH",
        "STREET": "ÇAMLIYAYLA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "KEMAH",
        "STREET": "ÇARŞI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "KEMAH",
        "STREET": "ÇİĞDEMLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "KEMAH",
        "STREET": "ÖZDAMAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "KEMAH",
        "STREET": "İNCEDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "KEMAH",
        "STREET": "ŞAHİNTEPE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "KEMALİYE",
        "STREET": "ADAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "KEMALİYE",
        "STREET": "AKSÖĞÜT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "KEMALİYE",
        "STREET": "AKÇALI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "KEMALİYE",
        "STREET": "APÇAĞA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "KEMALİYE",
        "STREET": "ARMAĞAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "KEMALİYE",
        "STREET": "ARSLANOBA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "KEMALİYE",
        "STREET": "ARİKİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "KEMALİYE",
        "STREET": "AVCI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "KEMALİYE",
        "STREET": "AĞIL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "KEMALİYE",
        "STREET": "AŞAĞIUMUTLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "KEMALİYE",
        "STREET": "BAHÇE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "KEMALİYE",
        "STREET": "BALKIRI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "KEMALİYE",
        "STREET": "BAŞARI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "KEMALİYE",
        "STREET": "BAŞBAĞLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "KEMALİYE",
        "STREET": "BAŞPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "KEMALİYE",
        "STREET": "BOYLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "KEMALİYE",
        "STREET": "BUĞDAYPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "KEMALİYE",
        "STREET": "DALLICA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "KEMALİYE",
        "STREET": "DEMİR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "KEMALİYE",
        "STREET": "DOLUNAY KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "KEMALİYE",
        "STREET": "DUTLUCA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "KEMALİYE",
        "STREET": "DÖRTYOLAĞZI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "KEMALİYE",
        "STREET": "DİLLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "KEMALİYE",
        "STREET": "EFELER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "KEMALİYE",
        "STREET": "ERGÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "KEMALİYE",
        "STREET": "ESERTEPE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "KEMALİYE",
        "STREET": "GENÇAĞA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "KEMALİYE",
        "STREET": "GÖZAYDIN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "KEMALİYE",
        "STREET": "GÜLDİBİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "KEMALİYE",
        "STREET": "GÜMÜŞÇEŞME KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "KEMALİYE",
        "STREET": "GÜNYOLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "KEMALİYE",
        "STREET": "HACI YUSUF MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "KEMALİYE",
        "STREET": "HALİLAĞA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "KEMALİYE",
        "STREET": "HARMANKAYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "KEMALİYE",
        "STREET": "KABATAŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "KEMALİYE",
        "STREET": "KARAKOÇLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "KEMALİYE",
        "STREET": "KARAPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "KEMALİYE",
        "STREET": "KAVACIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "KEMALİYE",
        "STREET": "KEKİKPINARI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "KEMALİYE",
        "STREET": "KIŞLACIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "KEMALİYE",
        "STREET": "KOCAÇİMEN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "KEMALİYE",
        "STREET": "KOZLUPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "KEMALİYE",
        "STREET": "KUTLUCA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "KEMALİYE",
        "STREET": "KUŞAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "KEMALİYE",
        "STREET": "NAİP MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "KEMALİYE",
        "STREET": "OCAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "KEMALİYE",
        "STREET": "SALİHLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "KEMALİYE",
        "STREET": "SANDIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "KEMALİYE",
        "STREET": "SANDIKBAĞI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "KEMALİYE",
        "STREET": "SIRAKONAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "KEMALİYE",
        "STREET": "SUBAŞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "KEMALİYE",
        "STREET": "TAŞDİBİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "KEMALİYE",
        "STREET": "TOPKAPI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "KEMALİYE",
        "STREET": "TOYBELEN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "KEMALİYE",
        "STREET": "TUĞLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "KEMALİYE",
        "STREET": "YAKA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "KEMALİYE",
        "STREET": "YAZMAKAYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "KEMALİYE",
        "STREET": "YEŞİLYAMAÇ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "KEMALİYE",
        "STREET": "YEŞİLYAYLA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "KEMALİYE",
        "STREET": "YEŞİLYURT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "KEMALİYE",
        "STREET": "YILDIZLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "KEMALİYE",
        "STREET": "YUKARIUMUTLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "KEMALİYE",
        "STREET": "YUVA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "KEMALİYE",
        "STREET": "ÇAKIRTAŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "KEMALİYE",
        "STREET": "ÇALDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "KEMALİYE",
        "STREET": "ÇANAKÇI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "KEMALİYE",
        "STREET": "ÇAT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "KEMALİYE",
        "STREET": "ÇİT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "KEMALİYE",
        "STREET": "İSHAKPAŞA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "KEMALİYE",
        "STREET": "ŞAHİNLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "OTLUKBELİ",
        "STREET": "AVCIÇAYIRI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "OTLUKBELİ",
        "STREET": "AĞAMÇAĞAM KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "OTLUKBELİ",
        "STREET": "BOĞAZLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "OTLUKBELİ",
        "STREET": "FATİH MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "OTLUKBELİ",
        "STREET": "KARADİVAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "OTLUKBELİ",
        "STREET": "KÜÇÜKOTLUKBELİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "OTLUKBELİ",
        "STREET": "MEHMET AKİF ERSOY MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "OTLUKBELİ",
        "STREET": "SÖĞÜTLÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "OTLUKBELİ",
        "STREET": "UMURLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "OTLUKBELİ",
        "STREET": "YENİKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "OTLUKBELİ",
        "STREET": "YEŞİLBÜK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "OTLUKBELİ",
        "STREET": "ÖRDEKHACI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "OTLUKBELİ",
        "STREET": "ŞEHİTLER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "REFAHİYE",
        "STREET": "AKARSU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "REFAHİYE",
        "STREET": "AKBAĞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "REFAHİYE",
        "STREET": "AKÇİĞDEM KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "REFAHİYE",
        "STREET": "ALACATLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "REFAHİYE",
        "STREET": "ALAPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "REFAHİYE",
        "STREET": "ALAÇAYIR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "REFAHİYE",
        "STREET": "ALTKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "REFAHİYE",
        "STREET": "ARDIÇLIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "REFAHİYE",
        "STREET": "ARMUTLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "REFAHİYE",
        "STREET": "ARPAYAZI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "REFAHİYE",
        "STREET": "ASLANYUSUF KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "REFAHİYE",
        "STREET": "AVŞARÖZÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "REFAHİYE",
        "STREET": "AYDINCIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "REFAHİYE",
        "STREET": "AYDOĞAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "REFAHİYE",
        "STREET": "AĞMUSA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "REFAHİYE",
        "STREET": "AŞAĞISÜTLÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "REFAHİYE",
        "STREET": "AŞUT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "REFAHİYE",
        "STREET": "BABAASLAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "REFAHİYE",
        "STREET": "BAKACAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "REFAHİYE",
        "STREET": "BALOĞLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "REFAHİYE",
        "STREET": "BAŞTOSUN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "REFAHİYE",
        "STREET": "BOSTANDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "REFAHİYE",
        "STREET": "BÖLÜKTEPE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "REFAHİYE",
        "STREET": "BİNALİ YILDIRIM MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "REFAHİYE",
        "STREET": "BİÇER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "REFAHİYE",
        "STREET": "CAMİİŞERİF MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "REFAHİYE",
        "STREET": "CENGERLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "REFAHİYE",
        "STREET": "DAMLACA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "REFAHİYE",
        "STREET": "DEREBAŞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "REFAHİYE",
        "STREET": "DOLAYLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "REFAHİYE",
        "STREET": "DOĞANDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "REFAHİYE",
        "STREET": "DİYARLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "REFAHİYE",
        "STREET": "DİŞTAŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "REFAHİYE",
        "STREET": "EKECİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "REFAHİYE",
        "STREET": "ERECEK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "REFAHİYE",
        "STREET": "GAZİPINARI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "REFAHİYE",
        "STREET": "GEMECİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "REFAHİYE",
        "STREET": "GÖKSEKİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "REFAHİYE",
        "STREET": "GÖLKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "REFAHİYE",
        "STREET": "GÜLENSU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "REFAHİYE",
        "STREET": "GÜMÜŞAKAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "REFAHİYE",
        "STREET": "GÜNYÜZÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "REFAHİYE",
        "STREET": "GÜVENTEPE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "REFAHİYE",
        "STREET": "HACIKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "REFAHİYE",
        "STREET": "HAKOĞLU MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "REFAHİYE",
        "STREET": "HALİTLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "REFAHİYE",
        "STREET": "HÖKÜMET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "REFAHİYE",
        "STREET": "KABULLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "REFAHİYE",
        "STREET": "KADIKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "REFAHİYE",
        "STREET": "KALKANCI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "REFAHİYE",
        "STREET": "KAMBERAĞA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "REFAHİYE",
        "STREET": "KANDİL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "REFAHİYE",
        "STREET": "KANLITAŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "REFAHİYE",
        "STREET": "KARASU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "REFAHİYE",
        "STREET": "KARAYAPRAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "REFAHİYE",
        "STREET": "KAYI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "REFAHİYE",
        "STREET": "KAZÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "REFAHİYE",
        "STREET": "KAÇAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "REFAHİYE",
        "STREET": "KERSEN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "REFAHİYE",
        "STREET": "KEÇEGÖZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "REFAHİYE",
        "STREET": "KIRANTEPE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "REFAHİYE",
        "STREET": "KIRIKTAŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "REFAHİYE",
        "STREET": "KIRKBULAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "REFAHİYE",
        "STREET": "KIZILENİŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "REFAHİYE",
        "STREET": "KONAKKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "REFAHİYE",
        "STREET": "KOÇKAYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "REFAHİYE",
        "STREET": "KUZULUK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "REFAHİYE",
        "STREET": "KÖÇEVİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "REFAHİYE",
        "STREET": "KÜRELİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "REFAHİYE",
        "STREET": "LALELİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "REFAHİYE",
        "STREET": "LEVENTLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "REFAHİYE",
        "STREET": "MADENDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "REFAHİYE",
        "STREET": "MENDEMEBAŞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "REFAHİYE",
        "STREET": "MENDEMEKÖYÇUKURU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "REFAHİYE",
        "STREET": "MERKEZ KALKANCI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "REFAHİYE",
        "STREET": "MURATÇAYIRI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "REFAHİYE",
        "STREET": "MÜLK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "REFAHİYE",
        "STREET": "OLGUNLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "REFAHİYE",
        "STREET": "ONURLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "REFAHİYE",
        "STREET": "ORTAGÖZE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "REFAHİYE",
        "STREET": "PERÇEM KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "REFAHİYE",
        "STREET": "PINARYOLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "REFAHİYE",
        "STREET": "RESULLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "REFAHİYE",
        "STREET": "SALUR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "REFAHİYE",
        "STREET": "SARHAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "REFAHİYE",
        "STREET": "SARIBAYIR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "REFAHİYE",
        "STREET": "SARIKOÇ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "REFAHİYE",
        "STREET": "SAĞLIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "REFAHİYE",
        "STREET": "SIRALI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "REFAHİYE",
        "STREET": "SÖĞÜTLÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "REFAHİYE",
        "STREET": "TEKNECİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "REFAHİYE",
        "STREET": "TEPE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "REFAHİYE",
        "STREET": "TOPAĞAÇ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "REFAHİYE",
        "STREET": "TUZLUÇAYIR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "REFAHİYE",
        "STREET": "TÜLÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "REFAHİYE",
        "STREET": "ULUCAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "REFAHİYE",
        "STREET": "ULUDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "REFAHİYE",
        "STREET": "YAYLABELİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "REFAHİYE",
        "STREET": "YAYLAPINARI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "REFAHİYE",
        "STREET": "YAZI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "REFAHİYE",
        "STREET": "YAZIGEDİĞİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "REFAHİYE",
        "STREET": "YENİDOĞAN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "REFAHİYE",
        "STREET": "YENİKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "REFAHİYE",
        "STREET": "YENİYURT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "REFAHİYE",
        "STREET": "YILDIZÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "REFAHİYE",
        "STREET": "YUKARISÜTLÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "REFAHİYE",
        "STREET": "YUKARIYENİKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "REFAHİYE",
        "STREET": "YURTBAŞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "REFAHİYE",
        "STREET": "YUVADAĞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "REFAHİYE",
        "STREET": "ÇALTI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "REFAHİYE",
        "STREET": "ÇAMDİBİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "REFAHİYE",
        "STREET": "ÇAMLIMÜLK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "REFAHİYE",
        "STREET": "ÇAT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "REFAHİYE",
        "STREET": "ÇATAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "REFAHİYE",
        "STREET": "ÇATALÇAM KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "REFAHİYE",
        "STREET": "ÇAVUŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "REFAHİYE",
        "STREET": "ÇIRAGEDİĞİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "REFAHİYE",
        "STREET": "ÇUKURYAZI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "REFAHİYE",
        "STREET": "ÇUKURÇİMEN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "REFAHİYE",
        "STREET": "ÇİÇEKALİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "REFAHİYE",
        "STREET": "ÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "REFAHİYE",
        "STREET": "ÜÇÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "REFAHİYE",
        "STREET": "ŞAHALOĞLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "REFAHİYE",
        "STREET": "ŞAHVERDİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "REFAHİYE",
        "STREET": "ŞAİP KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "TERCAN",
        "STREET": "AHMET YESEVİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "TERCAN",
        "STREET": "AKTAŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "TERCAN",
        "STREET": "AKYURT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "TERCAN",
        "STREET": "ALTINKAYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "TERCAN",
        "STREET": "ARMUTLUK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "TERCAN",
        "STREET": "ATATÜRK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "TERCAN",
        "STREET": "AĞÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "TERCAN",
        "STREET": "BALYAYLA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "TERCAN",
        "STREET": "BAĞPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "TERCAN",
        "STREET": "BAŞBUDAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "TERCAN",
        "STREET": "BEYKONAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "TERCAN",
        "STREET": "BEĞENDİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "TERCAN",
        "STREET": "BEŞGÖZE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "TERCAN",
        "STREET": "BEŞKAYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "TERCAN",
        "STREET": "BULMUŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "TERCAN",
        "STREET": "BÜKLÜMDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "TERCAN",
        "STREET": "DALLICA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "TERCAN",
        "STREET": "DARITEPE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "TERCAN",
        "STREET": "DOLUCA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "TERCAN",
        "STREET": "EDEBÜK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "TERCAN",
        "STREET": "ELALDI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "TERCAN",
        "STREET": "ELMALI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "TERCAN",
        "STREET": "ESENEVLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "TERCAN",
        "STREET": "FATİH MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "TERCAN",
        "STREET": "FINDIKLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "TERCAN",
        "STREET": "GAFUREFENDİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "TERCAN",
        "STREET": "GEDİKDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "TERCAN",
        "STREET": "GEVENLİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "TERCAN",
        "STREET": "GÖKPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "TERCAN",
        "STREET": "GÖKTAŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "TERCAN",
        "STREET": "GÖKÇE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "TERCAN",
        "STREET": "GÜZBULAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "TERCAN",
        "STREET": "HACIBAYRAM KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "TERCAN",
        "STREET": "ILISU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "TERCAN",
        "STREET": "KALECİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "TERCAN",
        "STREET": "KARACAKIŞLAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "TERCAN",
        "STREET": "KARACAÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "TERCAN",
        "STREET": "KARAHÜSEYİN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "TERCAN",
        "STREET": "KARGIN-CAMİİ ŞERİF MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "TERCAN",
        "STREET": "KARGIN-SUCUALİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "TERCAN",
        "STREET": "KARGIN-YOLLARÜSTÜ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "TERCAN",
        "STREET": "KARGIN-İSTASYON MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "TERCAN",
        "STREET": "KARGIN-ŞEHİT AHMET AYTEKİN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "TERCAN",
        "STREET": "KAVAKLIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "TERCAN",
        "STREET": "KAZIM KARABEKİR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "TERCAN",
        "STREET": "KEMERÇAM KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "TERCAN",
        "STREET": "KIZILCA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "TERCAN",
        "STREET": "KONARLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "TERCAN",
        "STREET": "KURUKOL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "TERCAN",
        "STREET": "KUZÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "TERCAN",
        "STREET": "KÖPRÜBAŞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "TERCAN",
        "STREET": "KÜLLÜCE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "TERCAN",
        "STREET": "KÜÇÜKAĞA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "TERCAN",
        "STREET": "MAMAHATUN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "TERCAN",
        "STREET": "MANTARLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "TERCAN",
        "STREET": "MERCAN-AKŞEMSETTİN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "TERCAN",
        "STREET": "MERCAN-ATATÜRK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "TERCAN",
        "STREET": "MERCAN-FATİH MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "TERCAN",
        "STREET": "MUSTAFABEY KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "TERCAN",
        "STREET": "MÜFTÜOĞLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "TERCAN",
        "STREET": "ORTAKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "TERCAN",
        "STREET": "OĞULVEREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "TERCAN",
        "STREET": "SARIKAYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "TERCAN",
        "STREET": "SAĞLICA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "TERCAN",
        "STREET": "TEPEBAŞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "TERCAN",
        "STREET": "TOPALHASAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "TERCAN",
        "STREET": "YALINKAŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "TERCAN",
        "STREET": "YASTIKKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "TERCAN",
        "STREET": "YAVUZ SELİM MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "TERCAN",
        "STREET": "YAYLACIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "TERCAN",
        "STREET": "YAYLAYOLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "TERCAN",
        "STREET": "YAYLIM KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "TERCAN",
        "STREET": "YAZIÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "TERCAN",
        "STREET": "YENİBUCAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "TERCAN",
        "STREET": "YEŞİLYAYLA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "TERCAN",
        "STREET": "YUVALI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "TERCAN",
        "STREET": "ÇADIRKAYA-CAMİİ KEBİR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "TERCAN",
        "STREET": "ÇADIRKAYA-GÖZELER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "TERCAN",
        "STREET": "ÇADIRKAYA-YENİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "TERCAN",
        "STREET": "ÇALKIŞLA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "TERCAN",
        "STREET": "ÇATAKDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "TERCAN",
        "STREET": "ÇAYIRDÜZÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "TERCAN",
        "STREET": "ÇUKURYURT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "TERCAN",
        "STREET": "ÜÇPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "TERCAN",
        "STREET": "İKİZLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "TERCAN",
        "STREET": "ŞENGÜL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "ÇAYIRLI",
        "STREET": "ATATÜRK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "ÇAYIRLI",
        "STREET": "AŞAĞIKARTALLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "ÇAYIRLI",
        "STREET": "AŞAĞIÇAMURDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "ÇAYIRLI",
        "STREET": "BALIKLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "ÇAYIRLI",
        "STREET": "BARBAROS MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "ÇAYIRLI",
        "STREET": "BAŞKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "ÇAYIRLI",
        "STREET": "BOYBEYİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "ÇAYIRLI",
        "STREET": "BOZAĞA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "ÇAYIRLI",
        "STREET": "BÖLÜKOVA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "ÇAYIRLI",
        "STREET": "BÜYÜKGELENGEÇ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "ÇAYIRLI",
        "STREET": "BÜYÜKYAYLAKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "ÇAYIRLI",
        "STREET": "CENNETPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "ÇAYIRLI",
        "STREET": "DOLUCA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "ÇAYIRLI",
        "STREET": "DOĞANYUVA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "ÇAYIRLI",
        "STREET": "ESENDORUK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "ÇAYIRLI",
        "STREET": "EŞMEPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "ÇAYIRLI",
        "STREET": "FATİH MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "ÇAYIRLI",
        "STREET": "GELİNPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "ÇAYIRLI",
        "STREET": "GÖLLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "ÇAYIRLI",
        "STREET": "HARMANTEPE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "ÇAYIRLI",
        "STREET": "HASTARLA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "ÇAYIRLI",
        "STREET": "KARATAŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "ÇAYIRLI",
        "STREET": "KÜÇÜKGELENGEÇ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "ÇAYIRLI",
        "STREET": "MAZLUMAĞA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "ÇAYIRLI",
        "STREET": "MİRZAOĞLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "ÇAYIRLI",
        "STREET": "ORTAÇAT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "ÇAYIRLI",
        "STREET": "OZANLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "ÇAYIRLI",
        "STREET": "OĞULTAŞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "ÇAYIRLI",
        "STREET": "PAŞAYURDU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "ÇAYIRLI",
        "STREET": "PINARLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "ÇAYIRLI",
        "STREET": "SARAYCIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "ÇAYIRLI",
        "STREET": "SARIGÜNEY KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "ÇAYIRLI",
        "STREET": "SAYGILI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "ÇAYIRLI",
        "STREET": "SIRATAŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "ÇAYIRLI",
        "STREET": "TOPRAKKALE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "ÇAYIRLI",
        "STREET": "TOSUNLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "ÇAYIRLI",
        "STREET": "TURNAÇAYIRI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "ÇAYIRLI",
        "STREET": "VERİMLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "ÇAYIRLI",
        "STREET": "YAYLAKENT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "ÇAYIRLI",
        "STREET": "YAYLALAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "ÇAYIRLI",
        "STREET": "YAZIKAYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "ÇAYIRLI",
        "STREET": "YEŞİLYAKA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "ÇAYIRLI",
        "STREET": "YUKARIKARTALLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "ÇAYIRLI",
        "STREET": "YUKARIÇAMURDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "ÇAYIRLI",
        "STREET": "YÜREKLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "ÇAYIRLI",
        "STREET": "ÇATAKSU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "ÇAYIRLI",
        "STREET": "ÇAYKENT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "ÇAYIRLI",
        "STREET": "ÇAYÖNÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "ÇAYIRLI",
        "STREET": "ÇİLHOROZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "ÇAYIRLI",
        "STREET": "ÇİLLİGÖL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "ÜZÜMLÜ",
        "STREET": "ALTINBAŞAK-BÜYÜK KADAĞAN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "ÜZÜMLÜ",
        "STREET": "ALTINBAŞAK-FIRAT MAHELLESİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "ÜZÜMLÜ",
        "STREET": "ALTINBAŞAK-KÜÇÜKKADAĞAN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "ÜZÜMLÜ",
        "STREET": "ALTINBAŞAK-PINARBAŞI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "ÜZÜMLÜ",
        "STREET": "ALTINBAŞAK-SÜLEYMANLI MAHELLESİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "ÜZÜMLÜ",
        "STREET": "ALTINBAŞAK-İSTASYON MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "ÜZÜMLÜ",
        "STREET": "AVCILAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "ÜZÜMLÜ",
        "STREET": "BABACAN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "ÜZÜMLÜ",
        "STREET": "BAHÇEŞEHİR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "ÜZÜMLÜ",
        "STREET": "BALABANLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "ÜZÜMLÜ",
        "STREET": "BAYIRBAĞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "ÜZÜMLÜ",
        "STREET": "BAĞLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "ÜZÜMLÜ",
        "STREET": "BULANIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "ÜZÜMLÜ",
        "STREET": "BÜYÜKKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "ÜZÜMLÜ",
        "STREET": "CİMİNLİ BABA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "ÜZÜMLÜ",
        "STREET": "DEMİRPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "ÜZÜMLÜ",
        "STREET": "DENİZDAMI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "ÜZÜMLÜ",
        "STREET": "DEREBÜK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "ÜZÜMLÜ",
        "STREET": "ESENYURT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "ÜZÜMLÜ",
        "STREET": "FATİH MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "ÜZÜMLÜ",
        "STREET": "GEYİKLİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "ÜZÜMLÜ",
        "STREET": "GÖLLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "ÜZÜMLÜ",
        "STREET": "KARACALAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "ÜZÜMLÜ",
        "STREET": "KARAKAYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "ÜZÜMLÜ",
        "STREET": "KUREYŞLİSARIKAYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "ÜZÜMLÜ",
        "STREET": "MUHSİN YAZICIOĞLU MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "ÜZÜMLÜ",
        "STREET": "MİMAR SİNAN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "ÜZÜMLÜ",
        "STREET": "OCAKBAŞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "ÜZÜMLÜ",
        "STREET": "ORUÇLU MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "ÜZÜMLÜ",
        "STREET": "OTLUK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "ÜZÜMLÜ",
        "STREET": "PELİTLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "ÜZÜMLÜ",
        "STREET": "PINARLIKAYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "ÜZÜMLÜ",
        "STREET": "PİRİ SAMİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "ÜZÜMLÜ",
        "STREET": "PİŞKİDAĞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "ÜZÜMLÜ",
        "STREET": "TERZİBABA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "ÜZÜMLÜ",
        "STREET": "YAVUZ SELİM MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "ÜZÜMLÜ",
        "STREET": "YUNUS EMRE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "ÜZÜMLÜ",
        "STREET": "ÇADIRTEPE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "ÜZÜMLÜ",
        "STREET": "ÇAMLICA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "ÜZÜMLÜ",
        "STREET": "ÇARDAKLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "ÜZÜMLÜ",
        "STREET": "ÇAVUŞLU MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "ÜZÜMLÜ",
        "STREET": "ÇAY MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "ÜZÜMLÜ",
        "STREET": "ÇAYIRYAZI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "ÜZÜMLÜ",
        "STREET": "ÜÇLER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "İLİÇ",
        "STREET": "ABDULLAH PAŞA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "İLİÇ",
        "STREET": "AKÇAYAZI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "İLİÇ",
        "STREET": "ALTINTAŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "İLİÇ",
        "STREET": "ATMA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "İLİÇ",
        "STREET": "AĞILDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "İLİÇ",
        "STREET": "BALKAYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "İLİÇ",
        "STREET": "BAĞCUĞAZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "İLİÇ",
        "STREET": "BAĞIŞTAŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "İLİÇ",
        "STREET": "BAĞLICA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "İLİÇ",
        "STREET": "BOYALIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "İLİÇ",
        "STREET": "BOZYAYLA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "İLİÇ",
        "STREET": "BOZÇALI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "İLİÇ",
        "STREET": "BÜRÜNCEK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "İLİÇ",
        "STREET": "BÜYÜKARMUTLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "İLİÇ",
        "STREET": "BÜYÜKGÜMÜŞLÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "İLİÇ",
        "STREET": "BÜYÜKKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "İLİÇ",
        "STREET": "DORUKSARAY KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "İLİÇ",
        "STREET": "DOSTAL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "İLİÇ",
        "STREET": "DOĞAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "İLİÇ",
        "STREET": "DİKMEN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "İLİÇ",
        "STREET": "FATİH MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "İLİÇ",
        "STREET": "GÜNGÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "İLİÇ",
        "STREET": "KAPIKAYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "İLİÇ",
        "STREET": "KARAKAYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "İLİÇ",
        "STREET": "KAYACIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "İLİÇ",
        "STREET": "KAYMAKLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "İLİÇ",
        "STREET": "KAZIM KARABEKİR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "İLİÇ",
        "STREET": "KONUKÇU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "İLİÇ",
        "STREET": "KOZLUCA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "İLİÇ",
        "STREET": "KURUÇAY KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "İLİÇ",
        "STREET": "KUZKIŞLA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "İLİÇ",
        "STREET": "KÜÇÜKARMUTLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "İLİÇ",
        "STREET": "LEVENTPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "İLİÇ",
        "STREET": "ORTATEPE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "İLİÇ",
        "STREET": "SABIRLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "İLİÇ",
        "STREET": "SARIKONAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "İLİÇ",
        "STREET": "SARIPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "İLİÇ",
        "STREET": "SULARBAŞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "İLİÇ",
        "STREET": "SÜTLÜCE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "İLİÇ",
        "STREET": "TABANLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "İLİÇ",
        "STREET": "TURGUTLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "İLİÇ",
        "STREET": "ULUYAMAÇ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "İLİÇ",
        "STREET": "UĞUR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "İLİÇ",
        "STREET": "YAKUPLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "İLİÇ",
        "STREET": "YALINGÖZE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "İLİÇ",
        "STREET": "YAYLAPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "İLİÇ",
        "STREET": "YILMAZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "İLİÇ",
        "STREET": "ÇALTI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "İLİÇ",
        "STREET": "ÇAYLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "İLİÇ",
        "STREET": "ÇAYYAKA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "İLİÇ",
        "STREET": "ÇOBANLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "İLİÇ",
        "STREET": "ÇÖPLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "İLİÇ",
        "STREET": "ÇÖREKLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "İLİÇ",
        "STREET": "ÇİFTLİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "İLİÇ",
        "STREET": "ÇİLESİZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "İLİÇ",
        "STREET": "ÖZLÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "İLİÇ",
        "STREET": "İBRAHİM ÇEÇEN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ERZİNCAN",
        "DISTRICT": "İLİÇ",
        "STREET": "İSLAMKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "ALPU",
        "STREET": "AKTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "ALPU",
        "STREET": "ALAPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "ALPU",
        "STREET": "ARIKAYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "ALPU",
        "STREET": "AĞAÇHİSAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "ALPU",
        "STREET": "BAHÇECİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "ALPU",
        "STREET": "BAŞÖREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "ALPU",
        "STREET": "BELKESE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "ALPU",
        "STREET": "BOZAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "ALPU",
        "STREET": "BÜĞDÜZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "ALPU",
        "STREET": "DEREKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "ALPU",
        "STREET": "ESENCE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "ALPU",
        "STREET": "FATİH MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "ALPU",
        "STREET": "FEVZİ PAŞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "ALPU",
        "STREET": "FEVZİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "ALPU",
        "STREET": "GÖKÇEKAYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "ALPU",
        "STREET": "GÖKÇEOĞLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "ALPU",
        "STREET": "GÜROLUK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "ALPU",
        "STREET": "IŞIKÖREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "ALPU",
        "STREET": "KARACAÖREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "ALPU",
        "STREET": "KARAKAMIŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "ALPU",
        "STREET": "KEMALPAŞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "ALPU",
        "STREET": "KOŞMAT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "ALPU",
        "STREET": "MAMURE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "ALPU",
        "STREET": "OSMANİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "ALPU",
        "STREET": "SAKARIKARACAÖREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "ALPU",
        "STREET": "SARIKAVAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "ALPU",
        "STREET": "SÖĞÜTÇÜK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "ALPU",
        "STREET": "UYUZHAMAMKÖYÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "ALPU",
        "STREET": "YEŞİLDON MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "ALPU",
        "STREET": "YUNUSEMRE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "ALPU",
        "STREET": "ÇARDAKBAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "ALPU",
        "STREET": "ÇERKEZ ÇUKURHİSAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "ALPU",
        "STREET": "ÖZDENK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "BEYLİKOVA",
        "STREET": "AKKÖPRÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "BEYLİKOVA",
        "STREET": "ATA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "BEYLİKOVA",
        "STREET": "AŞAĞIDUDAŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "BEYLİKOVA",
        "STREET": "AŞAĞIİĞDEAĞACI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "BEYLİKOVA",
        "STREET": "DOĞANOĞLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "BEYLİKOVA",
        "STREET": "DOĞRAY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "BEYLİKOVA",
        "STREET": "EMİRCİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "BEYLİKOVA",
        "STREET": "GÖKÇEAYVA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "BEYLİKOVA",
        "STREET": "HALİLBAĞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "BEYLİKOVA",
        "STREET": "KIZILCAÖREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "BEYLİKOVA",
        "STREET": "KÖPRÜBAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "BEYLİKOVA",
        "STREET": "OKÇU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "BEYLİKOVA",
        "STREET": "PARSIBEY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "BEYLİKOVA",
        "STREET": "RAHMİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "BEYLİKOVA",
        "STREET": "SULTANİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "BEYLİKOVA",
        "STREET": "SÜLEYMANİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "BEYLİKOVA",
        "STREET": "UZUNBURUN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "BEYLİKOVA",
        "STREET": "YALINLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "BEYLİKOVA",
        "STREET": "YENİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "BEYLİKOVA",
        "STREET": "YENİYURT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "BEYLİKOVA",
        "STREET": "YUKARIDUDAŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "BEYLİKOVA",
        "STREET": "YUKARIİĞDEAĞACI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "BEYLİKOVA",
        "STREET": "YUNUS EMRE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "BEYLİKOVA",
        "STREET": "İKİPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "BEYLİKOVA",
        "STREET": "İMİKLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "GÜNYÜZÜ",
        "STREET": "ATLAS MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "GÜNYÜZÜ",
        "STREET": "AYVALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "GÜNYÜZÜ",
        "STREET": "BEDİL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "GÜNYÜZÜ",
        "STREET": "BEYYAYLA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "GÜNYÜZÜ",
        "STREET": "DOĞRAY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "GÜNYÜZÜ",
        "STREET": "FATİH MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "GÜNYÜZÜ",
        "STREET": "GECEK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "GÜNYÜZÜ",
        "STREET": "GÜMÜŞKONAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "GÜNYÜZÜ",
        "STREET": "KAVACIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "GÜNYÜZÜ",
        "STREET": "KAVUNCU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "GÜNYÜZÜ",
        "STREET": "KAYAKENT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "GÜNYÜZÜ",
        "STREET": "KUZÖREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "GÜNYÜZÜ",
        "STREET": "MERCAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "GÜNYÜZÜ",
        "STREET": "SÜMER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "GÜNYÜZÜ",
        "STREET": "TUTLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "GÜNYÜZÜ",
        "STREET": "YAZIR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "GÜNYÜZÜ",
        "STREET": "YAĞRI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "GÜNYÜZÜ",
        "STREET": "YENİKENT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "GÜNYÜZÜ",
        "STREET": "YEŞİLYAKA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "GÜNYÜZÜ",
        "STREET": "ÇAKMAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "GÜNYÜZÜ",
        "STREET": "ÇARDAKÖZÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "GÜNYÜZÜ",
        "STREET": "ÖZLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "HAN",
        "STREET": "AKDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "HAN",
        "STREET": "AKHİSAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "HAN",
        "STREET": "AĞLARCA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "HAN",
        "STREET": "BAŞARA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "HAN",
        "STREET": "ERTEN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "HAN",
        "STREET": "GÖKÇEKUYU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "HAN",
        "STREET": "GÖKÇEYAYLA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "HAN",
        "STREET": "HACILAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "HAN",
        "STREET": "HANKARAAĞAÇ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "HAN",
        "STREET": "HÜSREVPAŞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "HAN",
        "STREET": "KAYI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "HAN",
        "STREET": "PEÇENE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "HAN",
        "STREET": "TEPEKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "HAN",
        "STREET": "YAZILI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "HAN",
        "STREET": "İSKANKUYU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "MAHMUDİYE",
        "STREET": "AKYURT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "MAHMUDİYE",
        "STREET": "BALÇIKHİSAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "MAHMUDİYE",
        "STREET": "DOĞANCA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "MAHMUDİYE",
        "STREET": "FAHRİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "MAHMUDİYE",
        "STREET": "GÜLLÜCE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "MAHMUDİYE",
        "STREET": "HAMİDİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "MAHMUDİYE",
        "STREET": "IŞIKLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "MAHMUDİYE",
        "STREET": "KAYMAZYAYLA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "MAHMUDİYE",
        "STREET": "MESUDİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "MAHMUDİYE",
        "STREET": "ORTA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "MAHMUDİYE",
        "STREET": "TOKATHAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "MAHMUDİYE",
        "STREET": "TOPKAYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "MAHMUDİYE",
        "STREET": "TÜRKMENMECİDİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "MAHMUDİYE",
        "STREET": "YENİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "MAHMUDİYE",
        "STREET": "YENİKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "MAHMUDİYE",
        "STREET": "YEŞİLYURT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "MAHMUDİYE",
        "STREET": "ÇAL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "MAHMUDİYE",
        "STREET": "İSMETPAŞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "MAHMUDİYE",
        "STREET": "ŞEREFİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "MİHALGAZİ",
        "STREET": "ALPAGUT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "MİHALGAZİ",
        "STREET": "BOZANİÇ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "MİHALGAZİ",
        "STREET": "CUMHURİYET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "MİHALGAZİ",
        "STREET": "DEMİRCİLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "MİHALGAZİ",
        "STREET": "DOKUZ MAYIS MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "MİHALGAZİ",
        "STREET": "HÜRRİYET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "MİHALGAZİ",
        "STREET": "KARAOĞLAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "MİHALGAZİ",
        "STREET": "SAKARIILICA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "MİHALGAZİ",
        "STREET": "İSTİKLAL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "MİHALIÇÇIK",
        "STREET": "ADAHİSAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "MİHALIÇÇIK",
        "STREET": "AHUR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "MİHALIÇÇIK",
        "STREET": "AHURÖZÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "MİHALIÇÇIK",
        "STREET": "AKÇAÖREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "MİHALIÇÇIK",
        "STREET": "AYDINLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "MİHALIÇÇIK",
        "STREET": "BAHTİYAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "MİHALIÇÇIK",
        "STREET": "BELEN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "MİHALIÇÇIK",
        "STREET": "BEY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "MİHALIÇÇIK",
        "STREET": "CAMİKEBİR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "MİHALIÇÇIK",
        "STREET": "DAĞCI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "MİHALIÇÇIK",
        "STREET": "DÜMREK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "MİHALIÇÇIK",
        "STREET": "DİKÖZÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "MİHALIÇÇIK",
        "STREET": "DİNEK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "MİHALIÇÇIK",
        "STREET": "GÖZELER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "MİHALIÇÇIK",
        "STREET": "GÜCE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "MİHALIÇÇIK",
        "STREET": "GÜREŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "MİHALIÇÇIK",
        "STREET": "GÜRLEYİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "MİHALIÇÇIK",
        "STREET": "HAMİDİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "MİHALIÇÇIK",
        "STREET": "ILICALAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "MİHALIÇÇIK",
        "STREET": "KARAGEYİKLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "MİHALIÇÇIK",
        "STREET": "KARAÇAM MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "MİHALIÇÇIK",
        "STREET": "KAVAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "MİHALIÇÇIK",
        "STREET": "KAYI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "MİHALIÇÇIK",
        "STREET": "KIZILBÖRÜKLÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "MİHALIÇÇIK",
        "STREET": "KORUCU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "MİHALIÇÇIK",
        "STREET": "KOYUNAĞILI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "MİHALIÇÇIK",
        "STREET": "KOZLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "MİHALIÇÇIK",
        "STREET": "LÜTFİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "MİHALIÇÇIK",
        "STREET": "MAHMUTHİSAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "MİHALIÇÇIK",
        "STREET": "MEDRESE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "MİHALIÇÇIK",
        "STREET": "NARLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "MİHALIÇÇIK",
        "STREET": "OBRUK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "MİHALIÇÇIK",
        "STREET": "OTLUK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "MİHALIÇÇIK",
        "STREET": "SARAY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "MİHALIÇÇIK",
        "STREET": "SAZAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "MİHALIÇÇIK",
        "STREET": "SEKİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "MİHALIÇÇIK",
        "STREET": "SEKİÖREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "MİHALIÇÇIK",
        "STREET": "SORKUN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "MİHALIÇÇIK",
        "STREET": "SÜLELER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "MİHALIÇÇIK",
        "STREET": "TATARCIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "MİHALIÇÇIK",
        "STREET": "UŞAKBÜKÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "MİHALIÇÇIK",
        "STREET": "YALIMKAYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "MİHALIÇÇIK",
        "STREET": "YAYLA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "MİHALIÇÇIK",
        "STREET": "YEŞİLYURT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "MİHALIÇÇIK",
        "STREET": "YUNUSEMRE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "MİHALIÇÇIK",
        "STREET": "ÇALKAYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "MİHALIÇÇIK",
        "STREET": "ÇALÇI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "MİHALIÇÇIK",
        "STREET": "ÇARDAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "MİHALIÇÇIK",
        "STREET": "ÇUKURÖREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "MİHALIÇÇIK",
        "STREET": "ÖMER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "MİHALIÇÇIK",
        "STREET": "ÜÇBAŞLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "MİHALIÇÇIK",
        "STREET": "İKİZAFER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "MİHALIÇÇIK",
        "STREET": "İĞDECİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "ODUNPAZARI",
        "STREET": "71 EVLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "ODUNPAZARI",
        "STREET": "75.YIL (SULTANDERE) MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "ODUNPAZARI",
        "STREET": "AKARBAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "ODUNPAZARI",
        "STREET": "AKCAMİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "ODUNPAZARI",
        "STREET": "AKKAYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "ODUNPAZARI",
        "STREET": "AKPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "ODUNPAZARI",
        "STREET": "AKÇAKAYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "ODUNPAZARI",
        "STREET": "AKÇAĞLAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "ODUNPAZARI",
        "STREET": "ALANÖNÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "ODUNPAZARI",
        "STREET": "ARİFİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "ODUNPAZARI",
        "STREET": "AVDAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "ODUNPAZARI",
        "STREET": "AYVACIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "ODUNPAZARI",
        "STREET": "AĞAPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "ODUNPAZARI",
        "STREET": "AŞAĞI ÇAĞLAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "ODUNPAZARI",
        "STREET": "AŞAĞIILICA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "ODUNPAZARI",
        "STREET": "BÜYÜKDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "ODUNPAZARI",
        "STREET": "CUNUDİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "ODUNPAZARI",
        "STREET": "DEDE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "ODUNPAZARI",
        "STREET": "DELİKLİTAŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "ODUNPAZARI",
        "STREET": "DEMİRLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "ODUNPAZARI",
        "STREET": "DOĞANKAYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "ODUNPAZARI",
        "STREET": "EMEK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "ODUNPAZARI",
        "STREET": "ERENKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "ODUNPAZARI",
        "STREET": "EŞENKARA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "ODUNPAZARI",
        "STREET": "GÖKMEYDAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "ODUNPAZARI",
        "STREET": "GÖZTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "ODUNPAZARI",
        "STREET": "GÜLPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "ODUNPAZARI",
        "STREET": "GÜLTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "ODUNPAZARI",
        "STREET": "GÜMELE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "ODUNPAZARI",
        "STREET": "GÜNDOĞDU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "ODUNPAZARI",
        "STREET": "H TİPİ KAPALI CEZA İNFAZ KURUMU",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "ODUNPAZARI",
        "STREET": "HARMANDALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "ODUNPAZARI",
        "STREET": "HUZUR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "ODUNPAZARI",
        "STREET": "IHLAMURKENT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "ODUNPAZARI",
        "STREET": "KALKANLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "ODUNPAZARI",
        "STREET": "KANLIPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "ODUNPAZARI",
        "STREET": "KARAALAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "ODUNPAZARI",
        "STREET": "KARACAHÖYÜK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "ODUNPAZARI",
        "STREET": "KARACAŞEHİR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "ODUNPAZARI",
        "STREET": "KARAHÜYÜK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "ODUNPAZARI",
        "STREET": "KARAMUSTAFA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "ODUNPAZARI",
        "STREET": "KARAPAZAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "ODUNPAZARI",
        "STREET": "KARAPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "ODUNPAZARI",
        "STREET": "KARATEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "ODUNPAZARI",
        "STREET": "KARAÇAY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "ODUNPAZARI",
        "STREET": "KARGIN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "ODUNPAZARI",
        "STREET": "KAYACIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "ODUNPAZARI",
        "STREET": "KAYAPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "ODUNPAZARI",
        "STREET": "KIRAVDAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "ODUNPAZARI",
        "STREET": "KIRMIZITOPRAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "ODUNPAZARI",
        "STREET": "KURTULUŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "ODUNPAZARI",
        "STREET": "KUYUCAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "ODUNPAZARI",
        "STREET": "KİREÇ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "ODUNPAZARI",
        "STREET": "L TİPİ KAPALI CEZA İNFAZ KURUMU",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "ODUNPAZARI",
        "STREET": "LÜTFİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "ODUNPAZARI",
        "STREET": "MUSALAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "ODUNPAZARI",
        "STREET": "ORHANGAZİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "ODUNPAZARI",
        "STREET": "ORTA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "ODUNPAZARI",
        "STREET": "OSMANGAZİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "ODUNPAZARI",
        "STREET": "PAŞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "ODUNPAZARI",
        "STREET": "SARISUNGUR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "ODUNPAZARI",
        "STREET": "SEKLİCE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "ODUNPAZARI",
        "STREET": "SEVİNÇ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "ODUNPAZARI",
        "STREET": "SULTANDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "ODUNPAZARI",
        "STREET": "SÜMER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "ODUNPAZARI",
        "STREET": "SÜPÜREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "ODUNPAZARI",
        "STREET": "TÜRKMENTOKAT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "ODUNPAZARI",
        "STREET": "ULUÇAYIR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "ODUNPAZARI",
        "STREET": "VADİŞEHİR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "ODUNPAZARI",
        "STREET": "VİŞNELİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "ODUNPAZARI",
        "STREET": "YAHNİKAPAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "ODUNPAZARI",
        "STREET": "YASSIHÖYÜK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "ODUNPAZARI",
        "STREET": "YENİDOĞAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "ODUNPAZARI",
        "STREET": "YENİKENT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "ODUNPAZARI",
        "STREET": "YENİSOFÇA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "ODUNPAZARI",
        "STREET": "YILDIZTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "ODUNPAZARI",
        "STREET": "YUKARIILICA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "ODUNPAZARI",
        "STREET": "YUKARIKALABAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "ODUNPAZARI",
        "STREET": "YUKARIÇAĞLAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "ODUNPAZARI",
        "STREET": "YÜRÜKKARACAÖREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "ODUNPAZARI",
        "STREET": "YÜRÜKKIRKA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "ODUNPAZARI",
        "STREET": "ÇAMLICA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "ODUNPAZARI",
        "STREET": "ÇANKAYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "ODUNPAZARI",
        "STREET": "ÇAVLUM MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "ODUNPAZARI",
        "STREET": "İMİŞEHİR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "ODUNPAZARI",
        "STREET": "İSTİKLAL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "ODUNPAZARI",
        "STREET": "ŞARKİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "SARICAKAYA",
        "STREET": "BEYKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "SARICAKAYA",
        "STREET": "BEYYAYLA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "SARICAKAYA",
        "STREET": "BİLAL HABEŞİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "SARICAKAYA",
        "STREET": "CAMİKEBİR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "SARICAKAYA",
        "STREET": "DAĞKÜPLÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "SARICAKAYA",
        "STREET": "DÜZKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "SARICAKAYA",
        "STREET": "FATİH MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "SARICAKAYA",
        "STREET": "GÜNEY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "SARICAKAYA",
        "STREET": "KAPIKAYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "SARICAKAYA",
        "STREET": "LAÇİN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "SARICAKAYA",
        "STREET": "MAYISLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "SARICAKAYA",
        "STREET": "SARIKAYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "SARICAKAYA",
        "STREET": "YENİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "SARICAKAYA",
        "STREET": "İĞDİR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "SEYİTGAZİ",
        "STREET": "AKSAKLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "SEYİTGAZİ",
        "STREET": "AKİN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "SEYİTGAZİ",
        "STREET": "ASLANBEYLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "SEYİTGAZİ",
        "STREET": "AYVALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "SEYİTGAZİ",
        "STREET": "AŞAĞISÖĞÜT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "SEYİTGAZİ",
        "STREET": "BARDAKÇI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "SEYİTGAZİ",
        "STREET": "BEYKIŞLA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "SEYİTGAZİ",
        "STREET": "BEŞSARAY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "SEYİTGAZİ",
        "STREET": "BÜYÜKDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "SEYİTGAZİ",
        "STREET": "BÜYÜKYAYLA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "SEYİTGAZİ",
        "STREET": "CEVİZLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "SEYİTGAZİ",
        "STREET": "DEREBENEK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "SEYİTGAZİ",
        "STREET": "DEĞİŞÖREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "SEYİTGAZİ",
        "STREET": "DOĞANÇAYIR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "SEYİTGAZİ",
        "STREET": "FETHİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "SEYİTGAZİ",
        "STREET": "GEMİÇ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "SEYİTGAZİ",
        "STREET": "GÖCENOLUK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "SEYİTGAZİ",
        "STREET": "GÖKBAHÇE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "SEYİTGAZİ",
        "STREET": "GÖKNEBİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "SEYİTGAZİ",
        "STREET": "GÖKÇEGÜNEY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "SEYİTGAZİ",
        "STREET": "GÜMÜŞBEL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "SEYİTGAZİ",
        "STREET": "KARACALIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "SEYİTGAZİ",
        "STREET": "KARAÖREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "SEYİTGAZİ",
        "STREET": "KESENLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "SEYİTGAZİ",
        "STREET": "KIRKA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "SEYİTGAZİ",
        "STREET": "KÜMBET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "SEYİTGAZİ",
        "STREET": "NUMANOLUK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "SEYİTGAZİ",
        "STREET": "OYNAŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "SEYİTGAZİ",
        "STREET": "SALİHLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "SEYİTGAZİ",
        "STREET": "SANCAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "SEYİTGAZİ",
        "STREET": "SANDIKÖZÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "SEYİTGAZİ",
        "STREET": "SARAYÖREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "SEYİTGAZİ",
        "STREET": "SARICAİLYAS MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "SEYİTGAZİ",
        "STREET": "TAŞLIKKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "SEYİTGAZİ",
        "STREET": "YAPILDAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "SEYİTGAZİ",
        "STREET": "YARBASAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "SEYİTGAZİ",
        "STREET": "YAZIDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "SEYİTGAZİ",
        "STREET": "YENİKENT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "SEYİTGAZİ",
        "STREET": "YEŞİLTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "SEYİTGAZİ",
        "STREET": "YUKARISÖĞÜT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "SEYİTGAZİ",
        "STREET": "YUNUS MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "SEYİTGAZİ",
        "STREET": "ÇATÖREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "SEYİTGAZİ",
        "STREET": "ÇUKURAĞIL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "SEYİTGAZİ",
        "STREET": "ÇUKURCA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "SEYİTGAZİ",
        "STREET": "ÇÜRÜTTÜM MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "SEYİTGAZİ",
        "STREET": "ÖRENCİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "SEYİTGAZİ",
        "STREET": "ÜÇSARAY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "SEYİTGAZİ",
        "STREET": "İDRİSYAYLA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "SEYİTGAZİ",
        "STREET": "İKİZOLUK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "SEYİTGAZİ",
        "STREET": "İKİÇEŞME MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "SEYİTGAZİ",
        "STREET": "ŞÜKRANLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "SİVRİHİSAR",
        "STREET": "AHİLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "SİVRİHİSAR",
        "STREET": "AKTAŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "SİVRİHİSAR",
        "STREET": "AYDINLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "SİVRİHİSAR",
        "STREET": "AŞAĞIKEPEN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "SİVRİHİSAR",
        "STREET": "BABADAT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "SİVRİHİSAR",
        "STREET": "BAHÇECİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "SİVRİHİSAR",
        "STREET": "BALLIHİSAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "SİVRİHİSAR",
        "STREET": "BENLİKUYU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "SİVRİHİSAR",
        "STREET": "BENLİYAVER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "SİVRİHİSAR",
        "STREET": "BEYYAZI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "SİVRİHİSAR",
        "STREET": "BUHARA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "SİVRİHİSAR",
        "STREET": "BUZLUCA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "SİVRİHİSAR",
        "STREET": "BÖĞÜRTLEN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "SİVRİHİSAR",
        "STREET": "BİÇER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "SİVRİHİSAR",
        "STREET": "CAMİKEBİR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "SİVRİHİSAR",
        "STREET": "CUMHURİYET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "SİVRİHİSAR",
        "STREET": "DEMİRCİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "SİVRİHİSAR",
        "STREET": "DEMİRCİKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "SİVRİHİSAR",
        "STREET": "DUMLUCA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "SİVRİHİSAR",
        "STREET": "DÜMREK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "SİVRİHİSAR",
        "STREET": "DİNEK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "SİVRİHİSAR",
        "STREET": "ELCİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "SİVRİHİSAR",
        "STREET": "ELMALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "SİVRİHİSAR",
        "STREET": "ERTUĞRULKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "SİVRİHİSAR",
        "STREET": "GEDİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "SİVRİHİSAR",
        "STREET": "GERENLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "SİVRİHİSAR",
        "STREET": "GÖKTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "SİVRİHİSAR",
        "STREET": "GÜLÇAYIR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "SİVRİHİSAR",
        "STREET": "GÜVEMLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "SİVRİHİSAR",
        "STREET": "HAMAMKARAHİSAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "SİVRİHİSAR",
        "STREET": "HIZIRBEY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "SİVRİHİSAR",
        "STREET": "HÜDAVENDİGAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "SİVRİHİSAR",
        "STREET": "KADINCIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "SİVRİHİSAR",
        "STREET": "KALDIRIMKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "SİVRİHİSAR",
        "STREET": "KARABAŞLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "SİVRİHİSAR",
        "STREET": "KARABURHAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "SİVRİHİSAR",
        "STREET": "KARACAKAYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "SİVRİHİSAR",
        "STREET": "KARACALAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "SİVRİHİSAR",
        "STREET": "KARACAÖREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "SİVRİHİSAR",
        "STREET": "KARACAÖRENYAYLASI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "SİVRİHİSAR",
        "STREET": "KARADAT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "SİVRİHİSAR",
        "STREET": "KARAKAYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "SİVRİHİSAR",
        "STREET": "KARKIN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "SİVRİHİSAR",
        "STREET": "KAYMAZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "SİVRİHİSAR",
        "STREET": "KERTEK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "SİVRİHİSAR",
        "STREET": "KILIÇ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "SİVRİHİSAR",
        "STREET": "KINIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "SİVRİHİSAR",
        "STREET": "KOLTAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "SİVRİHİSAR",
        "STREET": "KOÇAŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "SİVRİHİSAR",
        "STREET": "KUBBELİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "SİVRİHİSAR",
        "STREET": "KURTŞEYH MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "SİVRİHİSAR",
        "STREET": "KURŞUNLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "SİVRİHİSAR",
        "STREET": "MEMİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "SİVRİHİSAR",
        "STREET": "MÜLKKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "SİVRİHİSAR",
        "STREET": "NASRETTİN HOCA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "SİVRİHİSAR",
        "STREET": "ORTAKLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "SİVRİHİSAR",
        "STREET": "OĞLAKÇI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "SİVRİHİSAR",
        "STREET": "PAŞAKADIN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "SİVRİHİSAR",
        "STREET": "SADIKBAĞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "SİVRİHİSAR",
        "STREET": "SARIKAVAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "SİVRİHİSAR",
        "STREET": "SELİMİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "SİVRİHİSAR",
        "STREET": "SIĞIRCIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "SİVRİHİSAR",
        "STREET": "TEKÖREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "SİVRİHİSAR",
        "STREET": "YAVERÖREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "SİVRİHİSAR",
        "STREET": "YENİCE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "SİVRİHİSAR",
        "STREET": "YENİDOĞAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "SİVRİHİSAR",
        "STREET": "YENİKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "SİVRİHİSAR",
        "STREET": "YEŞİLKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "SİVRİHİSAR",
        "STREET": "YUKARIKEPEN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "SİVRİHİSAR",
        "STREET": "YUNUSEMRE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "SİVRİHİSAR",
        "STREET": "ZEYKÖYÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "SİVRİHİSAR",
        "STREET": "ÇANDIR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "SİVRİHİSAR",
        "STREET": "ÇAYKOZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "SİVRİHİSAR",
        "STREET": "İBİKSEYDİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "SİVRİHİSAR",
        "STREET": "İLYASPAŞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "SİVRİHİSAR",
        "STREET": "İLÖREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "SİVRİHİSAR",
        "STREET": "İSTİKLALBAĞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "SİVRİHİSAR",
        "STREET": "İĞDECİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "TEPEBAŞI",
        "STREET": "AHILAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "TEPEBAŞI",
        "STREET": "ALINCA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "TEPEBAŞI",
        "STREET": "ATALAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "TEPEBAŞI",
        "STREET": "ATALANTEKKE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "TEPEBAŞI",
        "STREET": "AVLAMIŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "TEPEBAŞI",
        "STREET": "AŞAĞI SÖĞÜTÖNÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "TEPEBAŞI",
        "STREET": "AŞAĞIKARTAL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "TEPEBAŞI",
        "STREET": "BAHÇELİEVLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "TEPEBAŞI",
        "STREET": "BATIKENT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "TEPEBAŞI",
        "STREET": "BEHÇETİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "TEPEBAŞI",
        "STREET": "BEKTAŞPINARI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "TEPEBAŞI",
        "STREET": "BEYAZALTIN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "TEPEBAŞI",
        "STREET": "BOYACIOĞLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "TEPEBAŞI",
        "STREET": "BOZDAĞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "TEPEBAŞI",
        "STREET": "BULDUKPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "TEPEBAŞI",
        "STREET": "CUMHURİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "TEPEBAŞI",
        "STREET": "CUMHURİYET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "TEPEBAŞI",
        "STREET": "DANİŞMENT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "TEPEBAŞI",
        "STREET": "EMİRCEOĞLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "TEPEBAŞI",
        "STREET": "ERTUĞRULGAZİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "TEPEBAŞI",
        "STREET": "ESENTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "TEPEBAŞI",
        "STREET": "ESKİBAĞLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "TEPEBAŞI",
        "STREET": "EĞRİÖZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "TEPEBAŞI",
        "STREET": "FATİH MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "TEPEBAŞI",
        "STREET": "FEVZİÇAKMAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "TEPEBAŞI",
        "STREET": "GAZİPAŞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "TEPEBAŞI",
        "STREET": "GÖKDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "TEPEBAŞI",
        "STREET": "GÖKÇEKISIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "TEPEBAŞI",
        "STREET": "GÜLLÜK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "TEPEBAŞI",
        "STREET": "GÜNDÜZLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "TEPEBAŞI",
        "STREET": "HACI ALİ BEY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "TEPEBAŞI",
        "STREET": "HACI SEYİT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "TEPEBAŞI",
        "STREET": "HASANBEY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "TEPEBAŞI",
        "STREET": "HAYRİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "TEPEBAŞI",
        "STREET": "HEKİMDAĞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "TEPEBAŞI",
        "STREET": "HOŞNUDİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "TEPEBAŞI",
        "STREET": "HİSAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "TEPEBAŞI",
        "STREET": "IŞIKLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "TEPEBAŞI",
        "STREET": "KARADERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "TEPEBAŞI",
        "STREET": "KARAGÖZLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "TEPEBAŞI",
        "STREET": "KARAÇOBANPINARI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "TEPEBAŞI",
        "STREET": "KAVACIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "TEPEBAŞI",
        "STREET": "KESKİN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "TEPEBAŞI",
        "STREET": "KIZILCAÖREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "TEPEBAŞI",
        "STREET": "KIZILİNLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "TEPEBAŞI",
        "STREET": "KOZKAYI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "TEPEBAŞI",
        "STREET": "KOZLUBEL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "TEPEBAŞI",
        "STREET": "KUMLUBEL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "TEPEBAŞI",
        "STREET": "MAMURE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "TEPEBAŞI",
        "STREET": "MERKEZ YENİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "TEPEBAŞI",
        "STREET": "MOLLAOĞLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "TEPEBAŞI",
        "STREET": "MUSAÖZÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "TEPEBAŞI",
        "STREET": "MUSTAFA KEMAL PAŞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "TEPEBAŞI",
        "STREET": "MUTTALİP EMİRLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "TEPEBAŞI",
        "STREET": "MUTTALİP KOYUNLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "TEPEBAŞI",
        "STREET": "MUTTALİP ORTA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "TEPEBAŞI",
        "STREET": "NEMLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "TEPEBAŞI",
        "STREET": "SAKİNTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "TEPEBAŞI",
        "STREET": "SATILMIŞOĞLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "TEPEBAŞI",
        "STREET": "SAZOVA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "TEPEBAŞI",
        "STREET": "SULUKARAAĞAÇ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "TEPEBAŞI",
        "STREET": "SÜTLÜCE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "TEPEBAŞI",
        "STREET": "TAKMAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "TEPEBAŞI",
        "STREET": "TANDIR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "TEPEBAŞI",
        "STREET": "TAYCILAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "TEPEBAŞI",
        "STREET": "TEKECİLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "TEPEBAŞI",
        "STREET": "TUNALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "TEPEBAŞI",
        "STREET": "TURGUTLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "TEPEBAŞI",
        "STREET": "ULUDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "TEPEBAŞI",
        "STREET": "ULUÖNDER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "TEPEBAŞI",
        "STREET": "YAKAKAYI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "TEPEBAŞI",
        "STREET": "YARIMCA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "TEPEBAŞI",
        "STREET": "YAŞAMKENT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "TEPEBAŞI",
        "STREET": "YENİAKÇAYIR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "TEPEBAŞI",
        "STREET": "YENİBAĞLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "TEPEBAŞI",
        "STREET": "YENİİNCESU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "TEPEBAŞI",
        "STREET": "YEŞİLTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "TEPEBAŞI",
        "STREET": "YUKARI SÖĞÜTÖNÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "TEPEBAŞI",
        "STREET": "YUKARIKARTAL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "TEPEBAŞI",
        "STREET": "YUSUFLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "TEPEBAŞI",
        "STREET": "YÜRÜKAKÇAYIR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "TEPEBAŞI",
        "STREET": "ZAFER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "TEPEBAŞI",
        "STREET": "ZİNCİRLİKUYU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "TEPEBAŞI",
        "STREET": "ÇALKARA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "TEPEBAŞI",
        "STREET": "ÇAMLICA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "TEPEBAŞI",
        "STREET": "ÇANAKKIRAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "TEPEBAŞI",
        "STREET": "ÇUKURHİSAR YENİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "TEPEBAŞI",
        "STREET": "ÖMERAĞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "TEPEBAŞI",
        "STREET": "İHSANİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "TEPEBAŞI",
        "STREET": "ŞARHÖYÜK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "TEPEBAŞI",
        "STREET": "ŞEKER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "TEPEBAŞI",
        "STREET": "ŞİRİNTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "ÇİFTELER",
        "STREET": "ABBASHALİMPAŞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "ÇİFTELER",
        "STREET": "ADALAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "ÇİFTELER",
        "STREET": "ALİKAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "ÇİFTELER",
        "STREET": "ARSLANLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "ÇİFTELER",
        "STREET": "BAŞKURT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "ÇİFTELER",
        "STREET": "BELPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "ÇİFTELER",
        "STREET": "DOĞANAY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "ÇİFTELER",
        "STREET": "DİKMEN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "ÇİFTELER",
        "STREET": "DİKİLİKAYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "ÇİFTELER",
        "STREET": "EMİNEKİN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "ÇİFTELER",
        "STREET": "ERBAP MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "ÇİFTELER",
        "STREET": "HAYRİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "ÇİFTELER",
        "STREET": "ILICABAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "ÇİFTELER",
        "STREET": "KADIKUYUSU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "ÇİFTELER",
        "STREET": "KÖRHASAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "ÇİFTELER",
        "STREET": "MERKEZ-YENİDOĞAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "ÇİFTELER",
        "STREET": "ORHANİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "ÇİFTELER",
        "STREET": "ORTAKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "ÇİFTELER",
        "STREET": "OSMANİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "ÇİFTELER",
        "STREET": "SADIROĞLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "ÇİFTELER",
        "STREET": "SAKARYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "ÇİFTELER",
        "STREET": "SARIKAVAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "ÇİFTELER",
        "STREET": "SAİTHALİMPAŞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "ÇİFTELER",
        "STREET": "YENİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "ÇİFTELER",
        "STREET": "YILDIZÖREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "ÇİFTELER",
        "STREET": "ZAFERHAMİT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "ÇİFTELER",
        "STREET": "ÇATMAPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "ÇİFTELER",
        "STREET": "ÇİFTÇİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "İNÖNÜ",
        "STREET": "AŞAĞIKUZFINDIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "İNÖNÜ",
        "STREET": "DEREYALAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "İNÖNÜ",
        "STREET": "DUTLUCA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "İNÖNÜ",
        "STREET": "ERENKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "İNÖNÜ",
        "STREET": "ESNEMEZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "İNÖNÜ",
        "STREET": "KÜMBET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "İNÖNÜ",
        "STREET": "KÜMBETAKPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "İNÖNÜ",
        "STREET": "KÜMBETYENİKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "İNÖNÜ",
        "STREET": "OKLUBALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "İNÖNÜ",
        "STREET": "ORTA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "İNÖNÜ",
        "STREET": "SEYİTALİKÖYÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "İNÖNÜ",
        "STREET": "YENİCE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "İNÖNÜ",
        "STREET": "YUKARIKUZFINDIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "İNÖNÜ",
        "STREET": "YÜRÜKYAYLA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "İNÖNÜ",
        "STREET": "ÇARŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ESKİŞEHİR",
        "DISTRICT": "İNÖNÜ",
        "STREET": "İSMETPAŞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ARABAN",
        "STREET": "AKBUDAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ARABAN",
        "STREET": "AKKOÇ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ARABAN",
        "STREET": "ALTINPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ARABAN",
        "STREET": "AŞAĞIKARAVAİZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ARABAN",
        "STREET": "AŞAĞIYUFKALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ARABAN",
        "STREET": "BAĞLICA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ARABAN",
        "STREET": "BAŞPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ARABAN",
        "STREET": "BEYDİLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ARABAN",
        "STREET": "DAĞDANCIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ARABAN",
        "STREET": "DOĞAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ARABAN",
        "STREET": "DUMLUPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ARABAN",
        "STREET": "ELİF MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ARABAN",
        "STREET": "EMİRHAYDAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ARABAN",
        "STREET": "ERENBAĞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ARABAN",
        "STREET": "ESENTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ARABAN",
        "STREET": "ESKİALTINTAŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ARABAN",
        "STREET": "FAKILI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ARABAN",
        "STREET": "FEVZİ ÇAKMAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ARABAN",
        "STREET": "FISTIKLIDAĞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ARABAN",
        "STREET": "GELİNBUĞDAY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ARABAN",
        "STREET": "GÖKÇEPAYAM MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ARABAN",
        "STREET": "GÜLLÜCE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ARABAN",
        "STREET": "GÜMÜŞPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ARABAN",
        "STREET": "GÜZEY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ARABAN",
        "STREET": "HASANOĞLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ARABAN",
        "STREET": "HİSAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ARABAN",
        "STREET": "KALE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ARABAN",
        "STREET": "KARABABA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ARABAN",
        "STREET": "KARACAÖREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ARABAN",
        "STREET": "KÖKLÜCE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ARABAN",
        "STREET": "KÖRHACIOBASI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ARABAN",
        "STREET": "KÜÇÜKLÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ARABAN",
        "STREET": "MEHMET GÖKÇEK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ARABAN",
        "STREET": "MURATLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ARABAN",
        "STREET": "NURETTİN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ARABAN",
        "STREET": "SARIKAYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ARABAN",
        "STREET": "SARITEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ARABAN",
        "STREET": "TARLABAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ARABAN",
        "STREET": "TAŞDEĞİRMEN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ARABAN",
        "STREET": "TURGUT ÖZAL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ARABAN",
        "STREET": "YAYLACIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ARABAN",
        "STREET": "YENİ ALTINTAŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ARABAN",
        "STREET": "YEŞİLOVA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ARABAN",
        "STREET": "YOLVEREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ARABAN",
        "STREET": "YUKARIKARAVAİZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ARABAN",
        "STREET": "YUKARIYUFKALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ARABAN",
        "STREET": "ZİYARET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ARABAN",
        "STREET": "ÇİFTEKOZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ARABAN",
        "STREET": "ŞERİF PERİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "KARKAMIŞ",
        "STREET": "AKÇAKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "KARKAMIŞ",
        "STREET": "ALAGÖZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "KARKAMIŞ",
        "STREET": "ALAÇALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "KARKAMIŞ",
        "STREET": "ARIKDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "KARKAMIŞ",
        "STREET": "AYYILDIZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "KARKAMIŞ",
        "STREET": "BALABAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "KARKAMIŞ",
        "STREET": "BEŞKILIÇ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "KARKAMIŞ",
        "STREET": "ECELER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "KARKAMIŞ",
        "STREET": "ELİFOĞLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "KARKAMIŞ",
        "STREET": "ERENYOLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "KARKAMIŞ",
        "STREET": "ETİLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "KARKAMIŞ",
        "STREET": "GÜRÇAY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "KARKAMIŞ",
        "STREET": "KARACURUN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "KARKAMIŞ",
        "STREET": "KARANFİL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "KARKAMIŞ",
        "STREET": "KARŞIYAKA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "KARKAMIŞ",
        "STREET": "KELEKLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "KARKAMIŞ",
        "STREET": "KEPİRLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "KARKAMIŞ",
        "STREET": "KIVIRCIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "KARKAMIŞ",
        "STREET": "KORKMAZLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "KARKAMIŞ",
        "STREET": "KURUYAZI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "KARKAMIŞ",
        "STREET": "LOJMANLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "KARKAMIŞ",
        "STREET": "SAVAŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "KARKAMIŞ",
        "STREET": "SOYLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "KARKAMIŞ",
        "STREET": "SUBAĞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "KARKAMIŞ",
        "STREET": "TEKETAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "KARKAMIŞ",
        "STREET": "TOSUNLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "KARKAMIŞ",
        "STREET": "TÜRKYURDU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "KARKAMIŞ",
        "STREET": "YARIMCA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "KARKAMIŞ",
        "STREET": "YAZIR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "KARKAMIŞ",
        "STREET": "YAŞAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "KARKAMIŞ",
        "STREET": "YEŞERTİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "KARKAMIŞ",
        "STREET": "YOLAĞZI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "KARKAMIŞ",
        "STREET": "YURTBAĞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "KARKAMIŞ",
        "STREET": "ZÜHRECİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "KARKAMIŞ",
        "STREET": "ÇARŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "KARKAMIŞ",
        "STREET": "ÇİFTLİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "KARKAMIŞ",
        "STREET": "ÖNCÜLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "KARKAMIŞ",
        "STREET": "ÖRMETAŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "KARKAMIŞ",
        "STREET": "ŞENLİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "NURDAĞI",
        "STREET": "ALPASLAN TÜRKEŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "NURDAĞI",
        "STREET": "ALTINOVA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "NURDAĞI",
        "STREET": "ASLANLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "NURDAĞI",
        "STREET": "ATAKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "NURDAĞI",
        "STREET": "ATATÜRK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "NURDAĞI",
        "STREET": "ATMALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "NURDAĞI",
        "STREET": "BADEMLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "NURDAĞI",
        "STREET": "BAHÇELİEVLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "NURDAĞI",
        "STREET": "BALIKALAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "NURDAĞI",
        "STREET": "BAŞPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "NURDAĞI",
        "STREET": "BELPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "NURDAĞI",
        "STREET": "DEMİRLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "NURDAĞI",
        "STREET": "DURMUŞLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "NURDAĞI",
        "STREET": "EMİRLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "NURDAĞI",
        "STREET": "ESENYURT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "NURDAĞI",
        "STREET": "FATİH MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "NURDAĞI",
        "STREET": "GEDİKLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "NURDAĞI",
        "STREET": "GÖKÇEDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "NURDAĞI",
        "STREET": "GÖZLÜHÜYÜK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "NURDAĞI",
        "STREET": "HAMİDİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "NURDAĞI",
        "STREET": "HİSAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "NURDAĞI",
        "STREET": "KARABURÇLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "NURDAĞI",
        "STREET": "KARTAL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "NURDAĞI",
        "STREET": "KIRIŞKAL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "NURDAĞI",
        "STREET": "KIRKPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "NURDAĞI",
        "STREET": "KURUDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "NURDAĞI",
        "STREET": "KUZOLUK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "NURDAĞI",
        "STREET": "KÖMÜRLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "NURDAĞI",
        "STREET": "MEHMET AKİF ERSOY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "NURDAĞI",
        "STREET": "MESTHÜYÜK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "NURDAĞI",
        "STREET": "NAİMLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "NURDAĞI",
        "STREET": "NOGAYLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "NURDAĞI",
        "STREET": "OLUCAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "NURDAĞI",
        "STREET": "SAKÇAGÖZÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "NURDAĞI",
        "STREET": "SAYBURUN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "NURDAĞI",
        "STREET": "TANDIRLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "NURDAĞI",
        "STREET": "TERKEN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "NURDAĞI",
        "STREET": "TORUNLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "NURDAĞI",
        "STREET": "TÜLLÜCE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "NURDAĞI",
        "STREET": "YAVUZSELİM MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "NURDAĞI",
        "STREET": "YAYLACIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "NURDAĞI",
        "STREET": "YENİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "NURDAĞI",
        "STREET": "ÇAKMAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "NURDAĞI",
        "STREET": "İKİZKUYU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "NURDAĞI",
        "STREET": "İNCEGEDİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "NURDAĞI",
        "STREET": "İNCİRLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "NURDAĞI",
        "STREET": "İÇERİSU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "NURDAĞI",
        "STREET": "ŞATIRHÜYÜK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "NİZİP",
        "STREET": "ADAKLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "NİZİP",
        "STREET": "AKKUYU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "NİZİP",
        "STREET": "AKÇAKENT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "NİZİP",
        "STREET": "ALAHACI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "NİZİP",
        "STREET": "ALTINDAĞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "NİZİP",
        "STREET": "ATATÜRK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "NİZİP",
        "STREET": "AŞAĞIBAYINDIR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "NİZİP",
        "STREET": "AŞAĞIÇARDAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "NİZİP",
        "STREET": "BAHÇELİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "NİZİP",
        "STREET": "BALLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "NİZİP",
        "STREET": "BAĞLICA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "NİZİP",
        "STREET": "BELKIS (MERKEZ) MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "NİZİP",
        "STREET": "BELKIS MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "NİZİP",
        "STREET": "BOYLUCA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "NİZİP",
        "STREET": "BOZALİOĞLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "NİZİP",
        "STREET": "CUMHURİYET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "NİZİP",
        "STREET": "DAYIDAĞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "NİZİP",
        "STREET": "DAZHÜYÜK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "NİZİP",
        "STREET": "DERNEK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "NİZİP",
        "STREET": "DOĞRULAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "NİZİP",
        "STREET": "DURAKLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "NİZİP",
        "STREET": "DUTLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "NİZİP",
        "STREET": "DÜZBAYIR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "NİZİP",
        "STREET": "EKİNCİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "NİZİP",
        "STREET": "ERENKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "NİZİP",
        "STREET": "ESKİKONAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "NİZİP",
        "STREET": "EYÜP SULTAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "NİZİP",
        "STREET": "FATİH SULTAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "NİZİP",
        "STREET": "FEVKANİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "NİZİP",
        "STREET": "FEVZİ PAŞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "NİZİP",
        "STREET": "FIRAT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "NİZİP",
        "STREET": "GAZİLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "NİZİP",
        "STREET": "GEVENCE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "NİZİP",
        "STREET": "GÖKÇELİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "NİZİP",
        "STREET": "GÜDER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "NİZİP",
        "STREET": "GÜLKAYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "NİZİP",
        "STREET": "GÜMÜŞGÜN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "NİZİP",
        "STREET": "GÜNALTI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "NİZİP",
        "STREET": "GÜRBAŞAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "NİZİP",
        "STREET": "GÜZELKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "NİZİP",
        "STREET": "HAFIZPAŞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "NİZİP",
        "STREET": "HANCAĞIZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "NİZİP",
        "STREET": "HAZIMOĞLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "NİZİP",
        "STREET": "KALEKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "NİZİP",
        "STREET": "KAMIŞLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "NİZİP",
        "STREET": "KARABURÇ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "NİZİP",
        "STREET": "KARŞIYAKA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "NİZİP",
        "STREET": "KAYALAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "NİZİP",
        "STREET": "KEKLİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "NİZİP",
        "STREET": "KESİKTAŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "NİZİP",
        "STREET": "KIBRIS MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "NİZİP",
        "STREET": "KIRATLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "NİZİP",
        "STREET": "KIRAÇGÜLÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "NİZİP",
        "STREET": "KIZILCAKENT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "NİZİP",
        "STREET": "KIZILİN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "NİZİP",
        "STREET": "KOCATEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "NİZİP",
        "STREET": "KORUCAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "NİZİP",
        "STREET": "KUMLA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "NİZİP",
        "STREET": "KURUCAHÜYÜK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "NİZİP",
        "STREET": "KÖSELER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "NİZİP",
        "STREET": "MAĞARACIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "NİZİP",
        "STREET": "MEHMETOBASI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "NİZİP",
        "STREET": "MENDERES MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "NİZİP",
        "STREET": "MERCANLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "NİZİP",
        "STREET": "MEVLANA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "NİZİP",
        "STREET": "MİHRAP MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "NİZİP",
        "STREET": "MİMAR SİNAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "NİZİP",
        "STREET": "NAHIRTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "NİZİP",
        "STREET": "NAMIK KEMAL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "NİZİP",
        "STREET": "NİZİP KAPALI CEZAEVİ",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "NİZİP",
        "STREET": "PAZAR CAMİİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "NİZİP",
        "STREET": "SAHA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "NİZİP",
        "STREET": "SALKIM MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "NİZİP",
        "STREET": "SAMANDÖKEN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "NİZİP",
        "STREET": "SAMANLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "NİZİP",
        "STREET": "SARAY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "NİZİP",
        "STREET": "SARIKOÇ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "NİZİP",
        "STREET": "SEKİLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "NİZİP",
        "STREET": "SUBOYU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "NİZİP",
        "STREET": "SULTAN ABDÜLHAMİT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "NİZİP",
        "STREET": "SÖĞÜTLÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "NİZİP",
        "STREET": "TAHTANİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "NİZİP",
        "STREET": "TANIR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "NİZİP",
        "STREET": "TATLICAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "NİZİP",
        "STREET": "TOSUNLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "NİZİP",
        "STREET": "TOYDEMİR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "NİZİP",
        "STREET": "TULUKTAŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "NİZİP",
        "STREET": "TURLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "NİZİP",
        "STREET": "TURNALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "NİZİP",
        "STREET": "ULUYATIR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "NİZİP",
        "STREET": "YARIMTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "NİZİP",
        "STREET": "YAĞCILAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "NİZİP",
        "STREET": "YAĞMURALAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "NİZİP",
        "STREET": "YENİYAPAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "NİZİP",
        "STREET": "YENİYAZI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "NİZİP",
        "STREET": "YEŞİLEVLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "NİZİP",
        "STREET": "YOLÇATI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "NİZİP",
        "STREET": "YUKARIBAYINDIR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "NİZİP",
        "STREET": "YUKARIÇARDAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "NİZİP",
        "STREET": "YUNUS EMRE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "NİZİP",
        "STREET": "ZEYTİNLİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "NİZİP",
        "STREET": "ÇAKMAKTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "NİZİP",
        "STREET": "ÇANAKÇI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "NİZİP",
        "STREET": "ÇATALCA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "NİZİP",
        "STREET": "ÖZYURT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "NİZİP",
        "STREET": "İKİZCE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "NİZİP",
        "STREET": "İNTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "NİZİP",
        "STREET": "İSTASYON MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "NİZİP",
        "STREET": "İSTİKLAL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "NİZİP",
        "STREET": "ŞAHİNBEY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "NİZİP",
        "STREET": "ŞIHLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "OĞUZELİ",
        "STREET": "ACER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "OĞUZELİ",
        "STREET": "AKÇAMEZRA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "OĞUZELİ",
        "STREET": "ALTINYURT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "OĞUZELİ",
        "STREET": "AMBARCIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "OĞUZELİ",
        "STREET": "ARSLANLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "OĞUZELİ",
        "STREET": "ASMACIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "OĞUZELİ",
        "STREET": "AYDINKAYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "OĞUZELİ",
        "STREET": "AŞAĞI GÜNEYSE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "OĞUZELİ",
        "STREET": "AŞAĞI YENİYAPAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "OĞUZELİ",
        "STREET": "BELÖREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "OĞUZELİ",
        "STREET": "BEŞDELİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "OĞUZELİ",
        "STREET": "BULDUK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "OĞUZELİ",
        "STREET": "BÜYÜKKARACAVİRAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "OĞUZELİ",
        "STREET": "CUMHURİYET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "OĞUZELİ",
        "STREET": "DEMİRKONAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "OĞUZELİ",
        "STREET": "DERO KÜÇÜKKARACAVİRAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "OĞUZELİ",
        "STREET": "DEVEHÜYÜĞÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "OĞUZELİ",
        "STREET": "DOKUZYOL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "OĞUZELİ",
        "STREET": "DOĞANPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "OĞUZELİ",
        "STREET": "DURUKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "OĞUZELİ",
        "STREET": "DUTLUCA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "OĞUZELİ",
        "STREET": "DİBECİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "OĞUZELİ",
        "STREET": "DİKMETAŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "OĞUZELİ",
        "STREET": "DİREKLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "OĞUZELİ",
        "STREET": "EKİNVEREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "OĞUZELİ",
        "STREET": "ERMİŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "OĞUZELİ",
        "STREET": "FATİH MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "OĞUZELİ",
        "STREET": "GEBE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "OĞUZELİ",
        "STREET": "GEDİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "OĞUZELİ",
        "STREET": "GÜLLÜK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "OĞUZELİ",
        "STREET": "GÜNDOĞAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "OĞUZELİ",
        "STREET": "GÜRSU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "OĞUZELİ",
        "STREET": "GÜVEÇLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "OĞUZELİ",
        "STREET": "GÜZELCE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "OĞUZELİ",
        "STREET": "HACAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "OĞUZELİ",
        "STREET": "HATUNLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "OĞUZELİ",
        "STREET": "HÖTOĞLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "OĞUZELİ",
        "STREET": "HÜRRİYET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "OĞUZELİ",
        "STREET": "KABACAAĞAÇ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "OĞUZELİ",
        "STREET": "KARABURUN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "OĞUZELİ",
        "STREET": "KARADİBEK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "OĞUZELİ",
        "STREET": "KARAMAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "OĞUZELİ",
        "STREET": "KARATAŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "OĞUZELİ",
        "STREET": "KAVUNLUK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "OĞUZELİ",
        "STREET": "KAYACIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "OĞUZELİ",
        "STREET": "KAYALIPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "OĞUZELİ",
        "STREET": "KAŞYOLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "OĞUZELİ",
        "STREET": "KERSENTAŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "OĞUZELİ",
        "STREET": "KEÇİKUYUSU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "OĞUZELİ",
        "STREET": "KEÇİLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "OĞUZELİ",
        "STREET": "KILAVUZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "OĞUZELİ",
        "STREET": "KOVANLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "OĞUZELİ",
        "STREET": "KOÇAKLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "OĞUZELİ",
        "STREET": "KURTULUŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "OĞUZELİ",
        "STREET": "KURUÇAY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "OĞUZELİ",
        "STREET": "KÖRKÜN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "OĞUZELİ",
        "STREET": "MİMAR SİNAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "OĞUZELİ",
        "STREET": "OĞUZLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "OĞUZELİ",
        "STREET": "SAZGIN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "OĞUZELİ",
        "STREET": "SERGİLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "OĞUZELİ",
        "STREET": "SEVİNDİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "OĞUZELİ",
        "STREET": "SUBAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "OĞUZELİ",
        "STREET": "SÜTLÜCE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "OĞUZELİ",
        "STREET": "TAŞLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "OĞUZELİ",
        "STREET": "TAŞYAZI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "OĞUZELİ",
        "STREET": "TAŞÇANAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "OĞUZELİ",
        "STREET": "TINAZDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "OĞUZELİ",
        "STREET": "TÜZEL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "OĞUZELİ",
        "STREET": "ULAŞLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "OĞUZELİ",
        "STREET": "UĞUROVA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "OĞUZELİ",
        "STREET": "YAKACIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "OĞUZELİ",
        "STREET": "YALNIZBAĞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "OĞUZELİ",
        "STREET": "YAZILI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "OĞUZELİ",
        "STREET": "YENİ CUMHURİYET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "OĞUZELİ",
        "STREET": "YENİKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "OĞUZELİ",
        "STREET": "YEŞİLDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "OĞUZELİ",
        "STREET": "YEŞİLTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "OĞUZELİ",
        "STREET": "YUKARIGÜNEYSE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "OĞUZELİ",
        "STREET": "ÇATALSU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "OĞUZELİ",
        "STREET": "ÇATALÇAM MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "OĞUZELİ",
        "STREET": "ÇAVUŞBAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "OĞUZELİ",
        "STREET": "ÇAYBAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "OĞUZELİ",
        "STREET": "ÇAYBEYİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "OĞUZELİ",
        "STREET": "ÜÇDAMLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "OĞUZELİ",
        "STREET": "ÜÇKUBBE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "OĞUZELİ",
        "STREET": "İKİZKUYU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "OĞUZELİ",
        "STREET": "İNCEYOL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "OĞUZELİ",
        "STREET": "İNKILAP MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "OĞUZELİ",
        "STREET": "İNÖNÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "OĞUZELİ",
        "STREET": "ŞAHİNBEY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "OĞUZELİ",
        "STREET": "ŞIH KÜÇÜKKARACAVİRAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "YAVUZELİ",
        "STREET": "AKBAYIR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "YAVUZELİ",
        "STREET": "AŞAĞIHÖÇÜKLÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "YAVUZELİ",
        "STREET": "AŞAĞIKAYABAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "YAVUZELİ",
        "STREET": "AŞAĞIKEKLİKTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "YAVUZELİ",
        "STREET": "BAKIRCA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "YAVUZELİ",
        "STREET": "BALLIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "YAVUZELİ",
        "STREET": "BAĞTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "YAVUZELİ",
        "STREET": "BEĞENDİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "YAVUZELİ",
        "STREET": "BÜLBÜL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "YAVUZELİ",
        "STREET": "BÜYÜKKARAKUYU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "YAVUZELİ",
        "STREET": "CUMHURİYET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "YAVUZELİ",
        "STREET": "CİNGİFE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "YAVUZELİ",
        "STREET": "DEĞİRMİTAŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "YAVUZELİ",
        "STREET": "DÜZCE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "YAVUZELİ",
        "STREET": "FEVZİ ÇAKMAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "YAVUZELİ",
        "STREET": "GÖÇMEZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "YAVUZELİ",
        "STREET": "GÜLPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "YAVUZELİ",
        "STREET": "HACIMALLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "YAVUZELİ",
        "STREET": "HAVUZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "YAVUZELİ",
        "STREET": "HÜRRİYET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "YAVUZELİ",
        "STREET": "ILICAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "YAVUZELİ",
        "STREET": "KARABEY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "YAVUZELİ",
        "STREET": "KARAHAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "YAVUZELİ",
        "STREET": "KARAHÜSEYİNLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "YAVUZELİ",
        "STREET": "KASABA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "YAVUZELİ",
        "STREET": "KEŞROBASI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "YAVUZELİ",
        "STREET": "KUZUYATAĞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "YAVUZELİ",
        "STREET": "KÜÇÜKKARAKUYU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "YAVUZELİ",
        "STREET": "SARAYMAĞARA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "YAVUZELİ",
        "STREET": "SARIBUĞDAY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "YAVUZELİ",
        "STREET": "SARILAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "YAVUZELİ",
        "STREET": "SULTAN SELİM MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "YAVUZELİ",
        "STREET": "SÜLEYMANOBASI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "YAVUZELİ",
        "STREET": "TOKAÇLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "YAVUZELİ",
        "STREET": "YARIMCA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "YAVUZELİ",
        "STREET": "YENİYURT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "YAVUZELİ",
        "STREET": "YUKARIKEKLİKTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "YAVUZELİ",
        "STREET": "YUKARIYENİKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "YAVUZELİ",
        "STREET": "YÖRELİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "YAVUZELİ",
        "STREET": "ÇİLTOPRAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "YAVUZELİ",
        "STREET": "ÇİMENLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "YAVUZELİ",
        "STREET": "ÖRENLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "YAVUZELİ",
        "STREET": "ÜÇGÖL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "YAVUZELİ",
        "STREET": "ŞENLİKÇE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "İSLAHİYE",
        "STREET": "AKINYOLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "İSLAHİYE",
        "STREET": "ALACA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "İSLAHİYE",
        "STREET": "ALTINÜZÜM MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "İSLAHİYE",
        "STREET": "ARPALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "İSLAHİYE",
        "STREET": "ATATÜRK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "İSLAHİYE",
        "STREET": "AYDINLIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "İSLAHİYE",
        "STREET": "AĞABEY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "İSLAHİYE",
        "STREET": "AĞALAROBASI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "İSLAHİYE",
        "STREET": "BAHÇELİEVLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "İSLAHİYE",
        "STREET": "BAYRAKTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "İSLAHİYE",
        "STREET": "BEYLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "İSLAHİYE",
        "STREET": "BOĞAZİÇİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "İSLAHİYE",
        "STREET": "BURHANİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "İSLAHİYE",
        "STREET": "BURUNSUZLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "İSLAHİYE",
        "STREET": "CEVDETPAŞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "İSLAHİYE",
        "STREET": "CUMHURİYET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "İSLAHİYE",
        "STREET": "DERVİŞPAŞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "İSLAHİYE",
        "STREET": "DEĞİRMENCİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "İSLAHİYE",
        "STREET": "ELBİSTANHÜYÜĞÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "İSLAHİYE",
        "STREET": "ERENLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "İSLAHİYE",
        "STREET": "ESENLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "İSLAHİYE",
        "STREET": "FEVZİ ÇAKMAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "İSLAHİYE",
        "STREET": "FEVZİPAŞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "İSLAHİYE",
        "STREET": "GÖLTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "İSLAHİYE",
        "STREET": "GÜLLÜHÜYÜK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "İSLAHİYE",
        "STREET": "GÜNGÖREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "İSLAHİYE",
        "STREET": "HACİ ALİ ÖZTÜRK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "İSLAHİYE",
        "STREET": "HANAĞZI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "İSLAHİYE",
        "STREET": "HASANLÖK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "İSLAHİYE",
        "STREET": "HÜRRİYET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "İSLAHİYE",
        "STREET": "KABAKLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "İSLAHİYE",
        "STREET": "KALAYCIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "İSLAHİYE",
        "STREET": "KALE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "İSLAHİYE",
        "STREET": "KARACAÖREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "İSLAHİYE",
        "STREET": "KARAKAYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "İSLAHİYE",
        "STREET": "KARAPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "İSLAHİYE",
        "STREET": "KARAPOLAT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "İSLAHİYE",
        "STREET": "KAYABAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "İSLAHİYE",
        "STREET": "KAZIKLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "İSLAHİYE",
        "STREET": "KIRIKÇALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "İSLAHİYE",
        "STREET": "KOZDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "İSLAHİYE",
        "STREET": "KOÇCAĞIZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "İSLAHİYE",
        "STREET": "KUŞÇUMUSTAFA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "İSLAHİYE",
        "STREET": "KÖKLÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "İSLAHİYE",
        "STREET": "ORTAKLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "İSLAHİYE",
        "STREET": "PINARBAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "İSLAHİYE",
        "STREET": "SERİNEVLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "İSLAHİYE",
        "STREET": "SULUMAĞARA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "İSLAHİYE",
        "STREET": "TANDIR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "İSLAHİYE",
        "STREET": "TELLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "İSLAHİYE",
        "STREET": "TÜRKBAHÇE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "İSLAHİYE",
        "STREET": "YAĞIZLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "İSLAHİYE",
        "STREET": "YELLİBURUN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "İSLAHİYE",
        "STREET": "YENİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "İSLAHİYE",
        "STREET": "YENİCELİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "İSLAHİYE",
        "STREET": "YENİKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "İSLAHİYE",
        "STREET": "YESEMEK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "İSLAHİYE",
        "STREET": "YEŞİLOVA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "İSLAHİYE",
        "STREET": "YEŞİLYURT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "İSLAHİYE",
        "STREET": "YOLBAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "İSLAHİYE",
        "STREET": "YUKARIBİLENLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "İSLAHİYE",
        "STREET": "ZİNCİRLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "İSLAHİYE",
        "STREET": "ÇAMLICA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "İSLAHİYE",
        "STREET": "ÇERÇİLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "İSLAHİYE",
        "STREET": "ÇINARLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "İSLAHİYE",
        "STREET": "ÇOLAKLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "İSLAHİYE",
        "STREET": "ÇUBUK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "İSLAHİYE",
        "STREET": "ÖRTÜLÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "İSLAHİYE",
        "STREET": "İDİLLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "İSLAHİYE",
        "STREET": "İSLAHİYE T TİPİ KAPALI VE AÇIK CEZA İNFAZ KURUMU",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "İSLAHİYE",
        "STREET": "ŞAHMARAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "İSLAHİYE",
        "STREET": "ŞERİKANLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞAHİNBEY",
        "STREET": "23 NİSAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞAHİNBEY",
        "STREET": "25 ARALIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞAHİNBEY",
        "STREET": "60.YIL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞAHİNBEY",
        "STREET": "75.YIL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞAHİNBEY",
        "STREET": "ABDÜLHAMİD HAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞAHİNBEY",
        "STREET": "AKBAYIR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞAHİNBEY",
        "STREET": "AKBULUT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞAHİNBEY",
        "STREET": "AKDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞAHİNBEY",
        "STREET": "AKKENT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞAHİNBEY",
        "STREET": "AKPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞAHİNBEY",
        "STREET": "AKYAZI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞAHİNBEY",
        "STREET": "AKYOL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞAHİNBEY",
        "STREET": "ALAYBEY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞAHİNBEY",
        "STREET": "ALLEBEN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞAHİNBEY",
        "STREET": "ALMALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞAHİNBEY",
        "STREET": "ALİBABA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞAHİNBEY",
        "STREET": "AYDINBABA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞAHİNBEY",
        "STREET": "BAHÇELİEVLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞAHİNBEY",
        "STREET": "BARAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞAHİNBEY",
        "STREET": "BARIŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞAHİNBEY",
        "STREET": "BAYRAMLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞAHİNBEY",
        "STREET": "BAĞLARBAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞAHİNBEY",
        "STREET": "BEKİRBEY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞAHİNBEY",
        "STREET": "BEKİŞLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞAHİNBEY",
        "STREET": "BELENKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞAHİNBEY",
        "STREET": "BEY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞAHİNBEY",
        "STREET": "BEYAZLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞAHİNBEY",
        "STREET": "BEYBAHÇE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞAHİNBEY",
        "STREET": "BEYDİLLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞAHİNBEY",
        "STREET": "BEŞKUYU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞAHİNBEY",
        "STREET": "BEŞTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞAHİNBEY",
        "STREET": "BOSTANCI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞAHİNBEY",
        "STREET": "BOSTANCIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞAHİNBEY",
        "STREET": "BOYACI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞAHİNBEY",
        "STREET": "BOZCA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞAHİNBEY",
        "STREET": "BOZOKLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞAHİNBEY",
        "STREET": "BURÇ ESENTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞAHİNBEY",
        "STREET": "BURÇ KARAKUYU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞAHİNBEY",
        "STREET": "BÜLBÜLZADE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞAHİNBEY",
        "STREET": "BİNEVLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞAHİNBEY",
        "STREET": "CABİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞAHİNBEY",
        "STREET": "CEBELER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞAHİNBEY",
        "STREET": "CEMAL GÜRSEL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞAHİNBEY",
        "STREET": "CENGİZ TOPEL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞAHİNBEY",
        "STREET": "CEVİZLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞAHİNBEY",
        "STREET": "CUMHURİYET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞAHİNBEY",
        "STREET": "DAMLACIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞAHİNBEY",
        "STREET": "DENİZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞAHİNBEY",
        "STREET": "DEREDÜZÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞAHİNBEY",
        "STREET": "DOKUR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞAHİNBEY",
        "STREET": "DOĞANCA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞAHİNBEY",
        "STREET": "DUMLUPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞAHİNBEY",
        "STREET": "DURANTAŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞAHİNBEY",
        "STREET": "DÜZTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞAHİNBEY",
        "STREET": "EKİNLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞAHİNBEY",
        "STREET": "ERTUĞRULGAZİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞAHİNBEY",
        "STREET": "ESENTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞAHİNBEY",
        "STREET": "ETİLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞAHİNBEY",
        "STREET": "EYÜPOĞLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞAHİNBEY",
        "STREET": "FIRAT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞAHİNBEY",
        "STREET": "FİDANLIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞAHİNBEY",
        "STREET": "GAZİTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞAHİNBEY",
        "STREET": "GENEYİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞAHİNBEY",
        "STREET": "GERCİĞİN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞAHİNBEY",
        "STREET": "GEYLANİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞAHİNBEY",
        "STREET": "GÜLLÜCE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞAHİNBEY",
        "STREET": "GÜLPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞAHİNBEY",
        "STREET": "GÜMÜŞTEKİN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞAHİNBEY",
        "STREET": "GÜNEYKENT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞAHİNBEY",
        "STREET": "GÜNEŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞAHİNBEY",
        "STREET": "GÜZELVADİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞAHİNBEY",
        "STREET": "HACIARSLAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞAHİNBEY",
        "STREET": "HACIKÖPRÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞAHİNBEY",
        "STREET": "HACIKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞAHİNBEY",
        "STREET": "HOŞGÖR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞAHİNBEY",
        "STREET": "KABARCIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞAHİNBEY",
        "STREET": "KAHVELİPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞAHİNBEY",
        "STREET": "KALE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞAHİNBEY",
        "STREET": "KALEBOYNU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞAHİNBEY",
        "STREET": "KANALICI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞAHİNBEY",
        "STREET": "KAPÇAĞIZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞAHİNBEY",
        "STREET": "KARAGÖZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞAHİNBEY",
        "STREET": "KARATARLA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞAHİNBEY",
        "STREET": "KARATAŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞAHİNBEY",
        "STREET": "KARAYILAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞAHİNBEY",
        "STREET": "KARAÇOMAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞAHİNBEY",
        "STREET": "KAVAKLIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞAHİNBEY",
        "STREET": "KAVŞAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞAHİNBEY",
        "STREET": "KAYAKENT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞAHİNBEY",
        "STREET": "KAZIKLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞAHİNBEY",
        "STREET": "KEPENEK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞAHİNBEY",
        "STREET": "KERER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞAHİNBEY",
        "STREET": "KIBRIS MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞAHİNBEY",
        "STREET": "KILINÇOĞLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞAHİNBEY",
        "STREET": "KOLEJTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞAHİNBEY",
        "STREET": "KONAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞAHİNBEY",
        "STREET": "KOZANLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞAHİNBEY",
        "STREET": "KOZLUCA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞAHİNBEY",
        "STREET": "KUMRUHAMURKESEN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞAHİNBEY",
        "STREET": "KURBANBABA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞAHİNBEY",
        "STREET": "KUŞÇU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞAHİNBEY",
        "STREET": "KÜLECİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞAHİNBEY",
        "STREET": "KÜRÜM MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞAHİNBEY",
        "STREET": "KÜÇÜKKIZILHİSAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞAHİNBEY",
        "STREET": "KİLLİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞAHİNBEY",
        "STREET": "MALAZGİRT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞAHİNBEY",
        "STREET": "MAVİKENT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞAHİNBEY",
        "STREET": "MORCALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞAHİNBEY",
        "STREET": "MUHACİROSMAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞAHİNBEY",
        "STREET": "MİMAR SİNAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞAHİNBEY",
        "STREET": "NARLICA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞAHİNBEY",
        "STREET": "NARLITEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞAHİNBEY",
        "STREET": "NURİPAZARBAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞAHİNBEY",
        "STREET": "OCAKLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞAHİNBEY",
        "STREET": "ONUR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞAHİNBEY",
        "STREET": "ORTAKLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞAHİNBEY",
        "STREET": "OSMANLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞAHİNBEY",
        "STREET": "OZANLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞAHİNBEY",
        "STREET": "PANCARLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞAHİNBEY",
        "STREET": "PERİLİKAYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞAHİNBEY",
        "STREET": "SAKARYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞAHİNBEY",
        "STREET": "SARIBAŞAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞAHİNBEY",
        "STREET": "SARIKAYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞAHİNBEY",
        "STREET": "SARISALKIM MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞAHİNBEY",
        "STREET": "SARIT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞAHİNBEY",
        "STREET": "SAVCILI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞAHİNBEY",
        "STREET": "SAÇAKLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞAHİNBEY",
        "STREET": "SEFERPAŞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞAHİNBEY",
        "STREET": "SELÇUKLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞAHİNBEY",
        "STREET": "SERİNCE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞAHİNBEY",
        "STREET": "SERİNEVLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞAHİNBEY",
        "STREET": "SIRASÖĞÜT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞAHİNBEY",
        "STREET": "SULTAN SELİM MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞAHİNBEY",
        "STREET": "SUYABATMAZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞAHİNBEY",
        "STREET": "TEKSTİLKENT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞAHİNBEY",
        "STREET": "TIŞLAKİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞAHİNBEY",
        "STREET": "TURAN EMEKSİZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞAHİNBEY",
        "STREET": "TÖRELİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞAHİNBEY",
        "STREET": "TÜRKMENLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞAHİNBEY",
        "STREET": "TÜRKTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞAHİNBEY",
        "STREET": "TÜRKÖZÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞAHİNBEY",
        "STREET": "TİYEKLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞAHİNBEY",
        "STREET": "UFACIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞAHİNBEY",
        "STREET": "ULAŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞAHİNBEY",
        "STREET": "UĞURTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞAHİNBEY",
        "STREET": "VATAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞAHİNBEY",
        "STREET": "YAMAÇTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞAHİNBEY",
        "STREET": "YAVUZLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞAHİNBEY",
        "STREET": "YAYCI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞAHİNBEY",
        "STREET": "YAYLACIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞAHİNBEY",
        "STREET": "YAZIBAĞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞAHİNBEY",
        "STREET": "YAZICIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞAHİNBEY",
        "STREET": "YAĞDÖVER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞAHİNBEY",
        "STREET": "YEDİTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞAHİNBEY",
        "STREET": "YENİKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞAHİNBEY",
        "STREET": "YEŞİLEVLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞAHİNBEY",
        "STREET": "YEŞİLKENT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞAHİNBEY",
        "STREET": "YEŞİLKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞAHİNBEY",
        "STREET": "YEŞİLPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞAHİNBEY",
        "STREET": "YEŞİLYURT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞAHİNBEY",
        "STREET": "YIĞMATEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞAHİNBEY",
        "STREET": "YOĞUNTAŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞAHİNBEY",
        "STREET": "YUKARIBAYIR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞAHİNBEY",
        "STREET": "ZEYTİNLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞAHİNBEY",
        "STREET": "ÇAMLICA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞAHİNBEY",
        "STREET": "ÇAMTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞAHİNBEY",
        "STREET": "ÇAPALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞAHİNBEY",
        "STREET": "ÇAĞDAŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞAHİNBEY",
        "STREET": "ÇEVRELİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞAHİNBEY",
        "STREET": "ÇUBUKDİKEN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞAHİNBEY",
        "STREET": "ÇÖREKLİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞAHİNBEY",
        "STREET": "ÇİMENLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞAHİNBEY",
        "STREET": "ÖĞRETMENEVLERİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞAHİNBEY",
        "STREET": "ÜNALDI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞAHİNBEY",
        "STREET": "ÜÇOKLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞAHİNBEY",
        "STREET": "İBN-İ SİNA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞAHİNBEY",
        "STREET": "İNÖNÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞAHİNBEY",
        "STREET": "İSTİKLAL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞAHİNBEY",
        "STREET": "ŞAHVELİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞAHİNBEY",
        "STREET": "ŞAHİNBEY MÜLK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞAHİNBEY",
        "STREET": "ŞAHİNTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞAHİNBEY",
        "STREET": "ŞEKEROĞLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞAHİNBEY",
        "STREET": "ŞENYURT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞEHİTKAMİL",
        "STREET": "15 TEMMUZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞEHİTKAMİL",
        "STREET": "29 EKİM MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞEHİTKAMİL",
        "STREET": "8 ŞUBAT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞEHİTKAMİL",
        "STREET": "ACAROBA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞEHİTKAMİL",
        "STREET": "AKTOPRAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞEHİTKAMİL",
        "STREET": "AKÇABURÇ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞEHİTKAMİL",
        "STREET": "AKÇAGÖZE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞEHİTKAMİL",
        "STREET": "ALPASLAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞEHİTKAMİL",
        "STREET": "ATABEK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞEHİTKAMİL",
        "STREET": "ATAKENT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞEHİTKAMİL",
        "STREET": "ATALAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞEHİTKAMİL",
        "STREET": "ATATÜRK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞEHİTKAMİL",
        "STREET": "AYDINLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞEHİTKAMİL",
        "STREET": "AYDINTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞEHİTKAMİL",
        "STREET": "AĞAÇLIBOYNO MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞEHİTKAMİL",
        "STREET": "AŞAĞI ARIL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞEHİTKAMİL",
        "STREET": "BATIKENT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞEHİTKAMİL",
        "STREET": "BATTAL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞEHİTKAMİL",
        "STREET": "BAYATLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞEHİTKAMİL",
        "STREET": "BAĞBAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞEHİTKAMİL",
        "STREET": "BEDİRKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞEHİTKAMİL",
        "STREET": "BELKIZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞEHİTKAMİL",
        "STREET": "BEYKENT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞEHİTKAMİL",
        "STREET": "BEYLERBEYİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞEHİTKAMİL",
        "STREET": "BEYRELİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞEHİTKAMİL",
        "STREET": "BOYNO MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞEHİTKAMİL",
        "STREET": "BOZOBASI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞEHİTKAMİL",
        "STREET": "BURAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞEHİTKAMİL",
        "STREET": "BÜYÜKPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞEHİTKAMİL",
        "STREET": "BİLEK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞEHİTKAMİL",
        "STREET": "CERİTYENİYAPAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞEHİTKAMİL",
        "STREET": "DEĞİRMİÇEM MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞEHİTKAMİL",
        "STREET": "DIMIŞKILI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞEHİTKAMİL",
        "STREET": "DURNALIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞEHİTKAMİL",
        "STREET": "DÜLÜK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞEHİTKAMİL",
        "STREET": "DÜLÜKBABA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞEHİTKAMİL",
        "STREET": "DÜNDARLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞEHİTKAMİL",
        "STREET": "E TİPİ KAPALI CEZA İNFAZ KURUMU",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞEHİTKAMİL",
        "STREET": "EMEK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞEHİTKAMİL",
        "STREET": "ERİKLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞEHİTKAMİL",
        "STREET": "ESKİŞARKAYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞEHİTKAMİL",
        "STREET": "EYDİBABA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞEHİTKAMİL",
        "STREET": "EYÜPSULTAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞEHİTKAMİL",
        "STREET": "FATİH MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞEHİTKAMİL",
        "STREET": "FISTIKLIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞEHİTKAMİL",
        "STREET": "GAZİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞEHİTKAMİL",
        "STREET": "GAZİKENT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞEHİTKAMİL",
        "STREET": "GAZİLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞEHİTKAMİL",
        "STREET": "GÖKSÜNCÜK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞEHİTKAMİL",
        "STREET": "GÖKTÜRK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞEHİTKAMİL",
        "STREET": "GÖLLÜCE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞEHİTKAMİL",
        "STREET": "GÖZTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞEHİTKAMİL",
        "STREET": "GÜNBULUR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞEHİTKAMİL",
        "STREET": "GÜNGÜRGE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞEHİTKAMİL",
        "STREET": "GÜVENEVLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞEHİTKAMİL",
        "STREET": "GÜZELYURT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞEHİTKAMİL",
        "STREET": "GİRNE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞEHİTKAMİL",
        "STREET": "H TİPİ CEZA İNFAZ KURUMU",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞEHİTKAMİL",
        "STREET": "HACIBABA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞEHİTKAMİL",
        "STREET": "HASIRCIOĞLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞEHİTKAMİL",
        "STREET": "HUMANIZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞEHİTKAMİL",
        "STREET": "HÜRRİYET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞEHİTKAMİL",
        "STREET": "IŞIKLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞEHİTKAMİL",
        "STREET": "KABASAKIZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞEHİTKAMİL",
        "STREET": "KARACAAHMET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞEHİTKAMİL",
        "STREET": "KARACABURÇ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞEHİTKAMİL",
        "STREET": "KARACAOĞLAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞEHİTKAMİL",
        "STREET": "KARACAÖREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞEHİTKAMİL",
        "STREET": "KARADEDE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞEHİTKAMİL",
        "STREET": "KARAHÜYÜK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞEHİTKAMİL",
        "STREET": "KARAKESEK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞEHİTKAMİL",
        "STREET": "KARAOĞLAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞEHİTKAMİL",
        "STREET": "KARAYUSUFLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞEHİTKAMİL",
        "STREET": "KARPUZKAYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞEHİTKAMİL",
        "STREET": "KARŞIYAKA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞEHİTKAMİL",
        "STREET": "KAYAÖNÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞEHİTKAMİL",
        "STREET": "KIZIK KARASAKAL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞEHİTKAMİL",
        "STREET": "KIZIKHAMURKESEN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞEHİTKAMİL",
        "STREET": "KOCATEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞEHİTKAMİL",
        "STREET": "KOZLUYAZI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞEHİTKAMİL",
        "STREET": "KOÇLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞEHİTKAMİL",
        "STREET": "KÖKSALAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞEHİTKAMİL",
        "STREET": "KÜLLÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞEHİTKAMİL",
        "STREET": "L TİPİ KAPALI CEZA İNFAZ KURUMU",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞEHİTKAMİL",
        "STREET": "MEDEGÖZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞEHİTKAMİL",
        "STREET": "MERVEŞEHİR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞEHİTKAMİL",
        "STREET": "MEVLANA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞEHİTKAMİL",
        "STREET": "MÜCAHİTLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞEHİTKAMİL",
        "STREET": "MÜNÜFPAŞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞEHİTKAMİL",
        "STREET": "MİTHATPAŞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞEHİTKAMİL",
        "STREET": "NESİMİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞEHİTKAMİL",
        "STREET": "NURTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞEHİTKAMİL",
        "STREET": "ONAT KUTLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞEHİTKAMİL",
        "STREET": "OSMANGAZİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞEHİTKAMİL",
        "STREET": "PANCARLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞEHİTKAMİL",
        "STREET": "PİRSULTAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞEHİTKAMİL",
        "STREET": "SACIR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞEHİTKAMİL",
        "STREET": "SAM MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞEHİTKAMİL",
        "STREET": "SANAYİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞEHİTKAMİL",
        "STREET": "SANCAKTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞEHİTKAMİL",
        "STREET": "SARIGÜLLÜK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞEHİTKAMİL",
        "STREET": "SARILAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞEHİTKAMİL",
        "STREET": "SEFAŞEHİR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞEHİTKAMİL",
        "STREET": "SELAHATTİN EYYUBİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞEHİTKAMİL",
        "STREET": "SELİMİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞEHİTKAMİL",
        "STREET": "SERİNTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞEHİTKAMİL",
        "STREET": "SEYMENLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞEHİTKAMİL",
        "STREET": "SEYRANTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞEHİTKAMİL",
        "STREET": "SOFALICI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞEHİTKAMİL",
        "STREET": "SUBOĞAZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞEHİTKAMİL",
        "STREET": "SÜLÜKLÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞEHİTKAMİL",
        "STREET": "SİNAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞEHİTKAMİL",
        "STREET": "TAŞLICA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞEHİTKAMİL",
        "STREET": "TEKİRSİN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞEHİTKAMİL",
        "STREET": "TOKDEMİR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞEHİTKAMİL",
        "STREET": "TUĞLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞEHİTKAMİL",
        "STREET": "TÜRKYURDU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞEHİTKAMİL",
        "STREET": "UMUT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞEHİTKAMİL",
        "STREET": "UĞRUCA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞEHİTKAMİL",
        "STREET": "YALANKOZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞEHİTKAMİL",
        "STREET": "YAMAÇOBA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞEHİTKAMİL",
        "STREET": "YAPRAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞEHİTKAMİL",
        "STREET": "YAYIKTAŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞEHİTKAMİL",
        "STREET": "YENİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞEHİTKAMİL",
        "STREET": "YENİŞARKAYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞEHİTKAMİL",
        "STREET": "YEŞİLCE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞEHİTKAMİL",
        "STREET": "YIĞINLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞEHİTKAMİL",
        "STREET": "YUKARI ARIL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞEHİTKAMİL",
        "STREET": "YUNUS EMRE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞEHİTKAMİL",
        "STREET": "ZEYTİNLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞEHİTKAMİL",
        "STREET": "ZÜLFİKAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞEHİTKAMİL",
        "STREET": "ÇAKMAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞEHİTKAMİL",
        "STREET": "ÇAMLITEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞEHİTKAMİL",
        "STREET": "ÇAĞKUYU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞEHİTKAMİL",
        "STREET": "ÇAĞLAYAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞEHİTKAMİL",
        "STREET": "ÇIKSORUT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞEHİTKAMİL",
        "STREET": "ÖVÜNDÜK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞEHİTKAMİL",
        "STREET": "ÖZGÜRLÜK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞEHİTKAMİL",
        "STREET": "ÖĞÜMSÖĞÜT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞEHİTKAMİL",
        "STREET": "ÜLKERLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞEHİTKAMİL",
        "STREET": "ÜÇGÖZE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞEHİTKAMİL",
        "STREET": "İBRAHİMLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞEHİTKAMİL",
        "STREET": "İBRAHİMŞEHİR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞEHİTKAMİL",
        "STREET": "İNCESU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞEHİTKAMİL",
        "STREET": "İNCİLİ PINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞEHİTKAMİL",
        "STREET": "İNCİLİKAYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞEHİTKAMİL",
        "STREET": "İSKENDERLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞEHİTKAMİL",
        "STREET": "ŞEHİRGÖSTEREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GAZİANTEP",
        "DISTRICT": "ŞEHİTKAMİL",
        "STREET": "ŞİRİNEVLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "MERKEZ",
        "STREET": "AKGEDİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "MERKEZ",
        "STREET": "AKHİSAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "MERKEZ",
        "STREET": "AKOCAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "MERKEZ",
        "STREET": "AKPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "MERKEZ",
        "STREET": "AKSU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "MERKEZ",
        "STREET": "AKTUTAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "MERKEZ",
        "STREET": "AKÇAHİSAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "MERKEZ",
        "STREET": "AKÇAKALE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "MERKEZ",
        "STREET": "ALEMDAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "MERKEZ",
        "STREET": "ALÇAKDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "MERKEZ",
        "STREET": "ARDUÇ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "MERKEZ",
        "STREET": "ARSLANCA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "MERKEZ",
        "STREET": "ARZULARKABAKÖY-EKİNCİLER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "MERKEZ",
        "STREET": "ARZULARKABAKÖY-ESENTEPE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "MERKEZ",
        "STREET": "ARZULARKABAKÖY-FATİH MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "MERKEZ",
        "STREET": "ARZULARKABAKÖY-GAZİLER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "MERKEZ",
        "STREET": "ARZULARKABAKÖY-MEZİRE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "MERKEZ",
        "STREET": "ARZULARKABAKÖY-OSMAN GAZİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "MERKEZ",
        "STREET": "AVŞARBEYLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "MERKEZ",
        "STREET": "AŞAĞI YUVALI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "MERKEZ",
        "STREET": "AŞAĞIALIÇLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "MERKEZ",
        "STREET": "BAHÇECİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "MERKEZ",
        "STREET": "BALLICA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "MERKEZ",
        "STREET": "BANDIRLIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "MERKEZ",
        "STREET": "BAĞLARBAŞI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "MERKEZ",
        "STREET": "BEYÇAM KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "MERKEZ",
        "STREET": "BEŞOBA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "MERKEZ",
        "STREET": "BOYLUCA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "MERKEZ",
        "STREET": "BUĞALI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "MERKEZ",
        "STREET": "CANCA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "MERKEZ",
        "STREET": "DEMİRKAYNAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "MERKEZ",
        "STREET": "DEMİRÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "MERKEZ",
        "STREET": "DUMANLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "MERKEZ",
        "STREET": "DUYMADIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "MERKEZ",
        "STREET": "DÖLEK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "MERKEZ",
        "STREET": "DÖRTKONAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "MERKEZ",
        "STREET": "DÜĞÜNYAZI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "MERKEZ",
        "STREET": "DİBEKLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "MERKEZ",
        "STREET": "ERDEMLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "MERKEZ",
        "STREET": "ESENLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "MERKEZ",
        "STREET": "ESENYURT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "MERKEZ",
        "STREET": "ESKİBAĞLAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "MERKEZ",
        "STREET": "GEÇİT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "MERKEZ",
        "STREET": "GÖKDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "MERKEZ",
        "STREET": "GÖKÇEPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "MERKEZ",
        "STREET": "GÖZELER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "MERKEZ",
        "STREET": "GÜMÜŞKAYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "MERKEZ",
        "STREET": "GÜNGÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "MERKEZ",
        "STREET": "GÜVERCİNLİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "MERKEZ",
        "STREET": "HACIEMİN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "MERKEZ",
        "STREET": "HARMANCIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "MERKEZ",
        "STREET": "HASANBEY MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "MERKEZ",
        "STREET": "HASKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "MERKEZ",
        "STREET": "KALE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "MERKEZ",
        "STREET": "KALETAŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "MERKEZ",
        "STREET": "KARAER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "MERKEZ",
        "STREET": "KARAMUSTAFA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "MERKEZ",
        "STREET": "KARŞIYAKA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "MERKEZ",
        "STREET": "KAYABAŞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "MERKEZ",
        "STREET": "KAZANTAŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "MERKEZ",
        "STREET": "KEÇİKAYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "MERKEZ",
        "STREET": "KILIÇÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "MERKEZ",
        "STREET": "KIRIKLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "MERKEZ",
        "STREET": "KIZILCA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "MERKEZ",
        "STREET": "KOCAPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "MERKEZ",
        "STREET": "KOCAYOKUŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "MERKEZ",
        "STREET": "KURTOĞLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "MERKEZ",
        "STREET": "MESCİTLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "MERKEZ",
        "STREET": "NAZLIÇAYIR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "MERKEZ",
        "STREET": "OLTANBEY MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "MERKEZ",
        "STREET": "OLUCAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "MERKEZ",
        "STREET": "OLUKDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "MERKEZ",
        "STREET": "PEHLİVANTAŞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "MERKEZ",
        "STREET": "PİRAHMET KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "MERKEZ",
        "STREET": "SARGINKAYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "MERKEZ",
        "STREET": "SARIÇİÇEK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "MERKEZ",
        "STREET": "SUNGURBEYLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "MERKEZ",
        "STREET": "SÖĞÜTAĞIL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "MERKEZ",
        "STREET": "SÜLE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "MERKEZ",
        "STREET": "SÜLEYMANİYE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "MERKEZ",
        "STREET": "SÜNGÜBAYIR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "MERKEZ",
        "STREET": "TAMZI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "MERKEZ",
        "STREET": "TANDIRLIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "MERKEZ",
        "STREET": "TEKKE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "MERKEZ",
        "STREET": "YAYDEMİR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "MERKEZ",
        "STREET": "YAYLADERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "MERKEZ",
        "STREET": "YAĞLIDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "MERKEZ",
        "STREET": "YAĞMURDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "MERKEZ",
        "STREET": "YENİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "MERKEZ",
        "STREET": "YENİCE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "MERKEZ",
        "STREET": "YENİKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "MERKEZ",
        "STREET": "YENİYOL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "MERKEZ",
        "STREET": "YEŞİLDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "MERKEZ",
        "STREET": "YEŞİLYURT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "MERKEZ",
        "STREET": "YUKARI ALIÇLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "MERKEZ",
        "STREET": "YUKARI YUVALI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "MERKEZ",
        "STREET": "YİTİRMEZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇALIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇALTILI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇAMLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇAMLICA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇAYIRARDI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇORAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "MERKEZ",
        "STREET": "ÖRENLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "MERKEZ",
        "STREET": "ÖVÜNDÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "MERKEZ",
        "STREET": "ÖZCAN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "MERKEZ",
        "STREET": "ÜÇKOL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "MERKEZ",
        "STREET": "İKİSU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "MERKEZ",
        "STREET": "İKİZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "MERKEZ",
        "STREET": "İNCESU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "MERKEZ",
        "STREET": "İNÖNÜ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "MERKEZ",
        "STREET": "ŞEPHANE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "MERKEZ",
        "STREET": "GÜMÜŞHANE CEZAEVİ",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "KELKİT",
        "STREET": "AHMET YESEVİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "KELKİT",
        "STREET": "AKDAĞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "KELKİT",
        "STREET": "AKSÖĞÜT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "KELKİT",
        "STREET": "ALACAT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "KELKİT",
        "STREET": "ATATÜRK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "KELKİT",
        "STREET": "AYDOĞDU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "KELKİT",
        "STREET": "AZİZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "KELKİT",
        "STREET": "AĞIL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "KELKİT",
        "STREET": "AĞLIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "KELKİT",
        "STREET": "AŞAĞIÖZLÜCE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "KELKİT",
        "STREET": "AŞUT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "KELKİT",
        "STREET": "BABAKONAĞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "KELKİT",
        "STREET": "BAHÇELİEVLER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "KELKİT",
        "STREET": "BALIKLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "KELKİT",
        "STREET": "BALKAYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "KELKİT",
        "STREET": "BAŞPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "KELKİT",
        "STREET": "BELENLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "KELKİT",
        "STREET": "BEZENDİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "KELKİT",
        "STREET": "BEŞDEĞİRMEN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "KELKİT",
        "STREET": "BULAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "KELKİT",
        "STREET": "BÜYÜKCAMİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "KELKİT",
        "STREET": "BİNDAL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "KELKİT",
        "STREET": "CEMALLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "KELKİT",
        "STREET": "CUMHURİYET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "KELKİT",
        "STREET": "DAYISI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "KELKİT",
        "STREET": "DELİLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "KELKİT",
        "STREET": "DEREDOLU-CUMHURİYET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "KELKİT",
        "STREET": "DEREDOLU-HÜRRİYET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "KELKİT",
        "STREET": "DEREDOLU-MAHMATLI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "KELKİT",
        "STREET": "DEREYÜZÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "KELKİT",
        "STREET": "DEVEKORUSU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "KELKİT",
        "STREET": "DOĞANCA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "KELKİT",
        "STREET": "DOĞANKAVAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "KELKİT",
        "STREET": "DÖLEK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "KELKİT",
        "STREET": "ELMELİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "KELKİT",
        "STREET": "ESKİKADI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "KELKİT",
        "STREET": "ESKİYOL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "KELKİT",
        "STREET": "EYMÜR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "KELKİT",
        "STREET": "GERDEKHİSAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "KELKİT",
        "STREET": "GÖDÜL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "KELKİT",
        "STREET": "GÜLLÜCE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "KELKİT",
        "STREET": "GÜLTEPE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "KELKİT",
        "STREET": "GÜMÜŞGÖZE-FATİH MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "KELKİT",
        "STREET": "GÜMÜŞGÖZE-GÖZTEPE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "KELKİT",
        "STREET": "GÜMÜŞGÖZE-ÖZYURT MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "KELKİT",
        "STREET": "GÜNBATUR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "KELKİT",
        "STREET": "GÜNEYÇEVİRME KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "KELKİT",
        "STREET": "GÜRLEYİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "KELKİT",
        "STREET": "GÜZYURDU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "KELKİT",
        "STREET": "KARACAÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "KELKİT",
        "STREET": "KARAÇAYIR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "KELKİT",
        "STREET": "KARŞIYAKA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "KELKİT",
        "STREET": "KAZANPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "KELKİT",
        "STREET": "KAŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "KELKİT",
        "STREET": "KILIÇTAŞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "KELKİT",
        "STREET": "KILIÇÇI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "KELKİT",
        "STREET": "KINALITAŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "KELKİT",
        "STREET": "KIZILCA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "KELKİT",
        "STREET": "KOZOĞLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "KELKİT",
        "STREET": "KÖMÜR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "KELKİT",
        "STREET": "KÖYCÜK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "KELKİT",
        "STREET": "KÜÇÜKCAMİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "KELKİT",
        "STREET": "OBALAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "KELKİT",
        "STREET": "SADAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "KELKİT",
        "STREET": "SALÖRDEK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "KELKİT",
        "STREET": "SARIŞEYH KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "KELKİT",
        "STREET": "SÖKMEN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "KELKİT",
        "STREET": "SÖĞÜTLÜ-MERKEZ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "KELKİT",
        "STREET": "SÜTVEREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "KELKİT",
        "STREET": "TÜTENLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "KELKİT",
        "STREET": "UZUNKOL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "KELKİT",
        "STREET": "YARBAŞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "KELKİT",
        "STREET": "YENİCE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "KELKİT",
        "STREET": "YENİKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "KELKİT",
        "STREET": "YENİYOL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "KELKİT",
        "STREET": "YEŞİLOVA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "KELKİT",
        "STREET": "YEŞİLPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "KELKİT",
        "STREET": "YEŞİLYURT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "KELKİT",
        "STREET": "YOLÇATI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "KELKİT",
        "STREET": "YUKARIÖZLÜCE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "KELKİT",
        "STREET": "YUNUS EMRE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "KELKİT",
        "STREET": "ÇAKIRLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "KELKİT",
        "STREET": "ÇAMUR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "KELKİT",
        "STREET": "ÇAĞLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "KELKİT",
        "STREET": "ÇÖMLECİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "KELKİT",
        "STREET": "ÇİMENLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "KELKİT",
        "STREET": "ÖBEKTAŞ-ESENTEPE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "KELKİT",
        "STREET": "ÖBEKTAŞ-FATİH MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "KELKİT",
        "STREET": "ÖBEKTAŞ-HALKEVİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "KELKİT",
        "STREET": "ÖRENBEL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "KELKİT",
        "STREET": "ÖZEN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "KELKİT",
        "STREET": "ÖĞÜTLÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "KELKİT",
        "STREET": "ÜNLÜPINAR-BÜYÜKCAMİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "KELKİT",
        "STREET": "ÜNLÜPINAR-FATİH MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "KELKİT",
        "STREET": "İNÖNÜ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "KELKİT",
        "STREET": "ŞEN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "KÖSE",
        "STREET": "AKBABA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "KÖSE",
        "STREET": "ALTINTAŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "KÖSE",
        "STREET": "BAHÇELİEVLER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "KÖSE",
        "STREET": "BİZGİLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "KÖSE",
        "STREET": "CUMHURİYET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "KÖSE",
        "STREET": "FATİH MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "KÖSE",
        "STREET": "GÖKÇEKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "KÖSE",
        "STREET": "KABAKTEPE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "KÖSE",
        "STREET": "KAYADİBİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "KÖSE",
        "STREET": "OYLUMDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "KÖSE",
        "STREET": "SALYAZI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "KÖSE",
        "STREET": "SUBAŞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "KÖSE",
        "STREET": "YAVUZ SELİM MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "KÖSE",
        "STREET": "YAYLIM KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "KÖSE",
        "STREET": "YENİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "KÖSE",
        "STREET": "YUVACIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "KÖSE",
        "STREET": "ÖRENŞAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "KÖSE",
        "STREET": "ÖVÜNCE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "KÖSE",
        "STREET": "ÖZBEYLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "KÜRTÜN",
        "STREET": "AKTAŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "KÜRTÜN",
        "STREET": "AKÇAL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "KÜRTÜN",
        "STREET": "ARAKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "KÜRTÜN",
        "STREET": "ARPACIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "KÜRTÜN",
        "STREET": "AŞAĞI ULUKÖY MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "KÜRTÜN",
        "STREET": "AŞAĞIKARADERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "KÜRTÜN",
        "STREET": "BAĞLAMA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "KÜRTÜN",
        "STREET": "BEYTARLA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "KÜRTÜN",
        "STREET": "BEŞİRKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "KÜRTÜN",
        "STREET": "CAMİ YANI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "KÜRTÜN",
        "STREET": "DAMLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "KÜRTÜN",
        "STREET": "DEMİRCİLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "KÜRTÜN",
        "STREET": "EKİNCİLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "KÜRTÜN",
        "STREET": "ELCİĞEZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "KÜRTÜN",
        "STREET": "ELMALI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "KÜRTÜN",
        "STREET": "EĞRİGÜNEY KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "KÜRTÜN",
        "STREET": "GÖNDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "KÜRTÜN",
        "STREET": "GÜNDOĞDU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "KÜRTÜN",
        "STREET": "GÜNYÜZÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "KÜRTÜN",
        "STREET": "GÜRGENLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "KÜRTÜN",
        "STREET": "KIRGERİŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "KÜRTÜN",
        "STREET": "KIZILCADAM KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "KÜRTÜN",
        "STREET": "KIZILOT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "KÜRTÜN",
        "STREET": "KONACIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "KÜRTÜN",
        "STREET": "KUŞLUK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "KÜRTÜN",
        "STREET": "KÖYİÇİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "KÜRTÜN",
        "STREET": "MELİKLİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "KÜRTÜN",
        "STREET": "ORTAKÖY MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "KÜRTÜN",
        "STREET": "SAPMAZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "KÜRTÜN",
        "STREET": "SARIBABA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "KÜRTÜN",
        "STREET": "SÖĞÜTELİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "KÜRTÜN",
        "STREET": "TAŞLICA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "KÜRTÜN",
        "STREET": "TİLKİCEK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "KÜRTÜN",
        "STREET": "YAYLALI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "KÜRTÜN",
        "STREET": "YEŞİLKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "KÜRTÜN",
        "STREET": "YUKARI ULUKÖY MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "KÜRTÜN",
        "STREET": "YUKARIKARADERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "KÜRTÜN",
        "STREET": "ÇAYIRÇUKUR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "KÜRTÜN",
        "STREET": "ÖZKÜRTÜN-CAYRA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "KÜRTÜN",
        "STREET": "ÖZKÜRTÜN-KARAÇUKUR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "KÜRTÜN",
        "STREET": "ÖZKÜRTÜN-SÜME MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "KÜRTÜN",
        "STREET": "ÜÇTAŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "KÜRTÜN",
        "STREET": "ŞENDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "TORUL",
        "STREET": "AKSÜT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "TORUL",
        "STREET": "ALINYAYLA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "TORUL",
        "STREET": "ALTINPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "TORUL",
        "STREET": "ARILI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "TORUL",
        "STREET": "ARPALI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "TORUL",
        "STREET": "ATALAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "TORUL",
        "STREET": "BAHÇECİK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "TORUL",
        "STREET": "BAHÇELİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "TORUL",
        "STREET": "BUDAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "TORUL",
        "STREET": "BÜYÜKÇİT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "TORUL",
        "STREET": "CEBELİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "TORUL",
        "STREET": "DAĞDİBİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "TORUL",
        "STREET": "DEDELİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "TORUL",
        "STREET": "DEMİRKAPI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "TORUL",
        "STREET": "GÜLAÇAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "TORUL",
        "STREET": "GÜMÜŞTUĞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "TORUL",
        "STREET": "GÜNAY KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "TORUL",
        "STREET": "GÜVEMLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "TORUL",
        "STREET": "GÜZELOLUK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "TORUL",
        "STREET": "HARMANCIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "TORUL",
        "STREET": "HEREK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "TORUL",
        "STREET": "IŞIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "TORUL",
        "STREET": "KALECİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "TORUL",
        "STREET": "KALEDİBİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "TORUL",
        "STREET": "KOCADAL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "TORUL",
        "STREET": "KOPUZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "TORUL",
        "STREET": "KÖPRÜBAŞI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "TORUL",
        "STREET": "KÖSTERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "TORUL",
        "STREET": "KÜÇÜKÇİT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "TORUL",
        "STREET": "KİRAZLIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "TORUL",
        "STREET": "MERKEZ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "TORUL",
        "STREET": "SOĞUKSU MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "TORUL",
        "STREET": "TOKÇAM KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "TORUL",
        "STREET": "TUFANİYE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "TORUL",
        "STREET": "TUĞRULBEY MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "TORUL",
        "STREET": "UĞURTAŞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "TORUL",
        "STREET": "YALINKAVAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "TORUL",
        "STREET": "YEŞİLKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "TORUL",
        "STREET": "YILDIZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "TORUL",
        "STREET": "YURT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "TORUL",
        "STREET": "YÜCEBELEN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "TORUL",
        "STREET": "ZİGANA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "TORUL",
        "STREET": "ÇAMLICA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "TORUL",
        "STREET": "İLECİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "TORUL",
        "STREET": "İNKILAP KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "ŞİRAN",
        "STREET": "AKBULAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "ŞİRAN",
        "STREET": "AKSARAY KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "ŞİRAN",
        "STREET": "AKÇALI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "ŞİRAN",
        "STREET": "ALACAHAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "ŞİRAN",
        "STREET": "ALIÇ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "ŞİRAN",
        "STREET": "ARAKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "ŞİRAN",
        "STREET": "ARDIÇLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "ŞİRAN",
        "STREET": "ARITAŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "ŞİRAN",
        "STREET": "AŞAĞIDURUÇAY KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "ŞİRAN",
        "STREET": "BABACAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "ŞİRAN",
        "STREET": "BABUŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "ŞİRAN",
        "STREET": "BAHÇELİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "ŞİRAN",
        "STREET": "BALIKHİSAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "ŞİRAN",
        "STREET": "BAŞKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "ŞİRAN",
        "STREET": "BELEN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "ŞİRAN",
        "STREET": "BOLLUK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "ŞİRAN",
        "STREET": "BOĞAZYAYLA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "ŞİRAN",
        "STREET": "BİLGİLİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "ŞİRAN",
        "STREET": "DARIBÜKÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "ŞİRAN",
        "STREET": "DUMANOLUĞU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "ŞİRAN",
        "STREET": "DİLEKYOLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "ŞİRAN",
        "STREET": "ELDİĞİN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "ŞİRAN",
        "STREET": "ELMAÇUKURU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "ŞİRAN",
        "STREET": "ERENKAYA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "ŞİRAN",
        "STREET": "ERİCEK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "ŞİRAN",
        "STREET": "EVREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "ŞİRAN",
        "STREET": "FATİH MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "ŞİRAN",
        "STREET": "GÖKÇELER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "ŞİRAN",
        "STREET": "GÜNBATUR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "ŞİRAN",
        "STREET": "GÜNYÜZÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "ŞİRAN",
        "STREET": "GÜREŞKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "ŞİRAN",
        "STREET": "KADIÇAYIRI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "ŞİRAN",
        "STREET": "KARACA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "ŞİRAN",
        "STREET": "KARAŞEYH KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "ŞİRAN",
        "STREET": "KAVAKLIDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "ŞİRAN",
        "STREET": "KAVAKPINARI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "ŞİRAN",
        "STREET": "KAYNAKBAŞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "ŞİRAN",
        "STREET": "KIRINTI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "ŞİRAN",
        "STREET": "KOYUNBABA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "ŞİRAN",
        "STREET": "KOZAĞAÇ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "ŞİRAN",
        "STREET": "MERTEKLİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "ŞİRAN",
        "STREET": "OZANCA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "ŞİRAN",
        "STREET": "PAŞAPINARI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "ŞİRAN",
        "STREET": "PELİTLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "ŞİRAN",
        "STREET": "SADIKKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "ŞİRAN",
        "STREET": "SARICA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "ŞİRAN",
        "STREET": "SELLİDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "ŞİRAN",
        "STREET": "SELİMİYE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "ŞİRAN",
        "STREET": "SEYDİBABA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "ŞİRAN",
        "STREET": "SUSUZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "ŞİRAN",
        "STREET": "SÖĞÜTALAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "ŞİRAN",
        "STREET": "SİNANLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "ŞİRAN",
        "STREET": "TEKKE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "ŞİRAN",
        "STREET": "TELME KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "ŞİRAN",
        "STREET": "TEPEDAM KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "ŞİRAN",
        "STREET": "YEDİBÖLÜK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "ŞİRAN",
        "STREET": "YENİKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "ŞİRAN",
        "STREET": "YEŞİLBÜK-AKYAYLA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "ŞİRAN",
        "STREET": "YEŞİLBÜK-BEYDERE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "ŞİRAN",
        "STREET": "YEŞİLBÜK-KONAKLI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "ŞİRAN",
        "STREET": "YEŞİLBÜK-YEŞİLKENT MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "ŞİRAN",
        "STREET": "YEŞİLBÜK-YEŞİLYURT MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "ŞİRAN",
        "STREET": "YOLBİLEN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "ŞİRAN",
        "STREET": "YUKARIDURUÇAY KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "ŞİRAN",
        "STREET": "YUKARIKULACA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "ŞİRAN",
        "STREET": "ÇAKIRKAYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "ŞİRAN",
        "STREET": "ÇALKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "ŞİRAN",
        "STREET": "ÇAMBAŞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "ŞİRAN",
        "STREET": "ÇANAKÇI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "ŞİRAN",
        "STREET": "ÇATMALAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "ŞİRAN",
        "STREET": "ÇAVLAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "ŞİRAN",
        "STREET": "ÇAĞIL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "ŞİRAN",
        "STREET": "ÇEVREPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "ŞİRAN",
        "STREET": "ÖRENKALE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "ŞİRAN",
        "STREET": "İNCEDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "ŞİRAN",
        "STREET": "İNÖZÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GÜMÜŞHANE",
        "DISTRICT": "ŞİRAN",
        "STREET": "ŞEHİT HAKAN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "ALUCRA",
        "STREET": "AKTEPE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "ALUCRA",
        "STREET": "AKÇİÇEK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "ALUCRA",
        "STREET": "ARDA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "ALUCRA",
        "STREET": "ARDUÇ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "ALUCRA",
        "STREET": "ARMUTLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "ALUCRA",
        "STREET": "AYDINYAYLA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "ALUCRA",
        "STREET": "BABAPINAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "ALUCRA",
        "STREET": "BEREKETLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "ALUCRA",
        "STREET": "BEYLERCE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "ALUCRA",
        "STREET": "BOYLUCA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "ALUCRA",
        "STREET": "DEMİRÖZÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "ALUCRA",
        "STREET": "DEREÇİFTLİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "ALUCRA",
        "STREET": "DOLUDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "ALUCRA",
        "STREET": "ELMACIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "ALUCRA",
        "STREET": "FEVZİ ÇAKMAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "ALUCRA",
        "STREET": "GÖKÇEBEL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "ALUCRA",
        "STREET": "GÜNÜGÜZEL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "ALUCRA",
        "STREET": "GÜRBULAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "ALUCRA",
        "STREET": "HACIHASAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "ALUCRA",
        "STREET": "HACILI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "ALUCRA",
        "STREET": "KABAKTEPE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "ALUCRA",
        "STREET": "KALEDİBİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "ALUCRA",
        "STREET": "KAMIŞLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "ALUCRA",
        "STREET": "KARAAĞAÇ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "ALUCRA",
        "STREET": "KARABÖRK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "ALUCRA",
        "STREET": "KAVAKLIDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "ALUCRA",
        "STREET": "KEMALLI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "ALUCRA",
        "STREET": "KOMAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "ALUCRA",
        "STREET": "KONAKLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "ALUCRA",
        "STREET": "KÖKLÜCE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "ALUCRA",
        "STREET": "MESUDİYE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "ALUCRA",
        "STREET": "PİRİLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "ALUCRA",
        "STREET": "SUBAŞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "ALUCRA",
        "STREET": "SUYURDU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "ALUCRA",
        "STREET": "TEPEKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "ALUCRA",
        "STREET": "TOHUMLUK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "ALUCRA",
        "STREET": "TOPÇAM MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "ALUCRA",
        "STREET": "YEŞİLYURT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "ALUCRA",
        "STREET": "YUNUS EMRE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "ALUCRA",
        "STREET": "YÜKSELEN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "ALUCRA",
        "STREET": "ÇAKRAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "ALUCRA",
        "STREET": "ÇALGAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "ALUCRA",
        "STREET": "ÇAMLIYAYLA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "ALUCRA",
        "STREET": "İĞDECİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "BULANCAK",
        "STREET": "ACISU MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "BULANCAK",
        "STREET": "AHMETLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "BULANCAK",
        "STREET": "ALİBEY KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "BULANCAK",
        "STREET": "ARDAHAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "BULANCAK",
        "STREET": "ARİFLİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "BULANCAK",
        "STREET": "ATAKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "BULANCAK",
        "STREET": "AYDINDERE-AYDINLAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "BULANCAK",
        "STREET": "AYDINDERE-DERECİKALAN  MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "BULANCAK",
        "STREET": "AYDINDERE-GÜNEY MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "BULANCAK",
        "STREET": "AYDINDERE-MERKEZ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "BULANCAK",
        "STREET": "AYDINDERE-YENİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "BULANCAK",
        "STREET": "BAHÇELİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "BULANCAK",
        "STREET": "BAHÇELİEVLER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "BULANCAK",
        "STREET": "BALLICA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "BULANCAK",
        "STREET": "BAYINDIR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "BULANCAK",
        "STREET": "BOSTANLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "BULANCAK",
        "STREET": "BULANCAK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "BULANCAK",
        "STREET": "BURUNUCU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "BULANCAK",
        "STREET": "BÜYÜKADA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "BULANCAK",
        "STREET": "CİNDİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "BULANCAK",
        "STREET": "DAMUDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "BULANCAK",
        "STREET": "DEMİRCİLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "BULANCAK",
        "STREET": "DUACIOĞLU MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "BULANCAK",
        "STREET": "DUTTEPE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "BULANCAK",
        "STREET": "DÖNGERİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "BULANCAK",
        "STREET": "ELMALI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "BULANCAK",
        "STREET": "ERDOĞAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "BULANCAK",
        "STREET": "ERİKLİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "BULANCAK",
        "STREET": "ESENKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "BULANCAK",
        "STREET": "EZELTERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "BULANCAK",
        "STREET": "GÜLTEPE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "BULANCAK",
        "STREET": "GÜNDOĞDU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "BULANCAK",
        "STREET": "GÜNEYKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "BULANCAK",
        "STREET": "GÜZELYALI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "BULANCAK",
        "STREET": "GÜZELYURT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "BULANCAK",
        "STREET": "HACET KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "BULANCAK",
        "STREET": "HİSARKAYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "BULANCAK",
        "STREET": "KALE GÜNEY KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "BULANCAK",
        "STREET": "KARAAĞAÇ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "BULANCAK",
        "STREET": "KARACARESUL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "BULANCAK",
        "STREET": "KAYABAŞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "BULANCAK",
        "STREET": "KAYADİBİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "BULANCAK",
        "STREET": "KAYHAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "BULANCAK",
        "STREET": "KIZILOT MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "BULANCAK",
        "STREET": "KIŞLA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "BULANCAK",
        "STREET": "KOVANLIK-AHURLU MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "BULANCAK",
        "STREET": "KOVANLIK-DÜZ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "BULANCAK",
        "STREET": "KOVANLIK-MERKEZ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "BULANCAK",
        "STREET": "KOVANLIK-SOFULU MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "BULANCAK",
        "STREET": "KOVANLIK-SOĞUKSU MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "BULANCAK",
        "STREET": "KUZKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "BULANCAK",
        "STREET": "KUŞLUHAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "BULANCAK",
        "STREET": "KÜÇÜKADA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "BULANCAK",
        "STREET": "KÜÇÜKDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "BULANCAK",
        "STREET": "KÜÇÜKLÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "BULANCAK",
        "STREET": "MURATLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "BULANCAK",
        "STREET": "ODADÜZÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "BULANCAK",
        "STREET": "PAZARSUYU EMECEN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "BULANCAK",
        "STREET": "PAZARSUYU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "BULANCAK",
        "STREET": "SAMUGÜNEY KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "BULANCAK",
        "STREET": "SANAYİİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "BULANCAK",
        "STREET": "SARAÇLI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "BULANCAK",
        "STREET": "SÜME KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "BULANCAK",
        "STREET": "SİSİN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "BULANCAK",
        "STREET": "TALİPLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "BULANCAK",
        "STREET": "TANDIR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "BULANCAK",
        "STREET": "TEKMEZAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "BULANCAK",
        "STREET": "TEPECİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "BULANCAK",
        "STREET": "TEPEÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "BULANCAK",
        "STREET": "TOKMADİN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "BULANCAK",
        "STREET": "TOPRAKDEĞİRMENİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "BULANCAK",
        "STREET": "TORÇAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "BULANCAK",
        "STREET": "UCARLI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "BULANCAK",
        "STREET": "YALIKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "BULANCAK",
        "STREET": "YASLIBAHÇE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "BULANCAK",
        "STREET": "YENİKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "BULANCAK",
        "STREET": "YEŞİLHİSAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "BULANCAK",
        "STREET": "YEŞİLKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "BULANCAK",
        "STREET": "YEŞİLTEPE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "BULANCAK",
        "STREET": "YILDIZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "BULANCAK",
        "STREET": "YUNUSLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "BULANCAK",
        "STREET": "İCİLLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "BULANCAK",
        "STREET": "İHSANİYE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "BULANCAK",
        "STREET": "İNECE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "BULANCAK",
        "STREET": "İSMET PAŞA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "BULANCAK",
        "STREET": "ŞEMSETTİN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "BULANCAK",
        "STREET": "ŞEYHMUSA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "DERELİ",
        "STREET": "AKKAYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "DERELİ",
        "STREET": "AKSU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "DERELİ",
        "STREET": "ALANCIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "DERELİ",
        "STREET": "BAHÇELİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "DERELİ",
        "STREET": "DERELİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "DERELİ",
        "STREET": "EĞRİANBAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "DERELİ",
        "STREET": "FATİH MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "DERELİ",
        "STREET": "GÜDÜL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "DERELİ",
        "STREET": "GÜZELKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "DERELİ",
        "STREET": "HEYDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "DERELİ",
        "STREET": "HİSARKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "DERELİ",
        "STREET": "KIZILTAŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "DERELİ",
        "STREET": "KONUKLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "DERELİ",
        "STREET": "KURTULMUŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "DERELİ",
        "STREET": "KUZCA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "DERELİ",
        "STREET": "KUŞLUHAN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "DERELİ",
        "STREET": "KÜKNARLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "DERELİ",
        "STREET": "KÜMBET KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "DERELİ",
        "STREET": "KÜÇÜKAHMET KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "DERELİ",
        "STREET": "MADEN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "DERELİ",
        "STREET": "MEŞELİYATAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "DERELİ",
        "STREET": "PINARLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "DERELİ",
        "STREET": "SARIYAKUP KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "DERELİ",
        "STREET": "SÜTLÜCE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "DERELİ",
        "STREET": "TAMDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "DERELİ",
        "STREET": "TAŞLICA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "DERELİ",
        "STREET": "TEPEKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "DERELİ",
        "STREET": "TEPEKÜKNARLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "DERELİ",
        "STREET": "UZUNDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "DERELİ",
        "STREET": "YAVUZKEMAL-GÜZYURDU MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "DERELİ",
        "STREET": "YAVUZKEMAL-HAPAN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "DERELİ",
        "STREET": "YAVUZKEMAL-KULAKKAYA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "DERELİ",
        "STREET": "YAVUZKEMAL-YENİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "DERELİ",
        "STREET": "YAVUZKEMAL-YUVA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "DERELİ",
        "STREET": "YAVUZKEMAL-ŞIHLAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "DERELİ",
        "STREET": "YAYLACIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "DERELİ",
        "STREET": "YEŞİLKAYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "DERELİ",
        "STREET": "YEŞİLTEPE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "DERELİ",
        "STREET": "YEŞİLVADİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "DERELİ",
        "STREET": "YILDIZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "DERELİ",
        "STREET": "YÜCE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "DERELİ",
        "STREET": "ÇALCA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "DERELİ",
        "STREET": "ÇALKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "DERELİ",
        "STREET": "ÇAMLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "DERELİ",
        "STREET": "ÇENGELKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "DERELİ",
        "STREET": "İÇMESU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "DOĞANKENT",
        "STREET": "DOYMUŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "DOĞANKENT",
        "STREET": "GÜDÜL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "DOĞANKENT",
        "STREET": "GÜVENLİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "DOĞANKENT",
        "STREET": "KOZKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "DOĞANKENT",
        "STREET": "MERKEZ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "DOĞANKENT",
        "STREET": "OYRACA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "DOĞANKENT",
        "STREET": "SADAKLI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "DOĞANKENT",
        "STREET": "SÖĞÜTAĞZI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "DOĞANKENT",
        "STREET": "SÜTTAŞI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "DOĞANKENT",
        "STREET": "YENİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "DOĞANKENT",
        "STREET": "YENİKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "DOĞANKENT",
        "STREET": "YEŞİLTEPE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "DOĞANKENT",
        "STREET": "ÇATAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "DOĞANKENT",
        "STREET": "ÇATALAĞAÇ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "ESPİYE",
        "STREET": "ADABÜK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "ESPİYE",
        "STREET": "AKKAYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "ESPİYE",
        "STREET": "ARALICAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "ESPİYE",
        "STREET": "ARPACIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "ESPİYE",
        "STREET": "AVLUCA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "ESPİYE",
        "STREET": "AYDINLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "ESPİYE",
        "STREET": "AĞALIKMADENİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "ESPİYE",
        "STREET": "BAHÇECİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "ESPİYE",
        "STREET": "BAYRAMBEY KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "ESPİYE",
        "STREET": "BONCUKÇU MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "ESPİYE",
        "STREET": "CİBRİL MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "ESPİYE",
        "STREET": "DEMİRCİLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "ESPİYE",
        "STREET": "DEĞİRMENAĞZI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "ESPİYE",
        "STREET": "DİREKBÜKÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "ESPİYE",
        "STREET": "ERİCEK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "ESPİYE",
        "STREET": "ESENTEPE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "ESPİYE",
        "STREET": "ESPİYE L TİPİ KAPALI VE AÇIK CEZAEVİ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "ESPİYE",
        "STREET": "GEBELLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "ESPİYE",
        "STREET": "GÜLBURNU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "ESPİYE",
        "STREET": "GÜMÜŞDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "ESPİYE",
        "STREET": "GÜNEYKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "ESPİYE",
        "STREET": "GÜZELYURT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "ESPİYE",
        "STREET": "HACIKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "ESPİYE",
        "STREET": "HACIMAHMUTLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "ESPİYE",
        "STREET": "KALEBOYNU MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "ESPİYE",
        "STREET": "KARADERE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "ESPİYE",
        "STREET": "KAŞDİBİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "ESPİYE",
        "STREET": "KURUGERİŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "ESPİYE",
        "STREET": "MERKEZ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "ESPİYE",
        "STREET": "SAKARYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "ESPİYE",
        "STREET": "SEYDİKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "ESPİYE",
        "STREET": "SOĞUKPINAR-DİKMEN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "ESPİYE",
        "STREET": "SOĞUKPINAR-KOZKÖY MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "ESPİYE",
        "STREET": "SOĞUKPINAR-MERKEZ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "ESPİYE",
        "STREET": "SOĞUKPINAR-TUYGUN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "ESPİYE",
        "STREET": "TAFLANCIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "ESPİYE",
        "STREET": "TİKENCE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "ESPİYE",
        "STREET": "YENİKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "ESPİYE",
        "STREET": "YEŞİLKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "ESPİYE",
        "STREET": "YEŞİLYURT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "ESPİYE",
        "STREET": "ÇALKAYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "ESPİYE",
        "STREET": "ÇAM MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "ESPİYE",
        "STREET": "ÇEPNİKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "ESPİYE",
        "STREET": "İBRAHİMŞEYH KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "ESPİYE",
        "STREET": "ŞAHİNYUVA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "EYNESİL",
        "STREET": "ADAKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "EYNESİL",
        "STREET": "ALTINLI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "EYNESİL",
        "STREET": "ARALIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "EYNESİL",
        "STREET": "BALCILI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "EYNESİL",
        "STREET": "BOZTEPE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "EYNESİL",
        "STREET": "DEREBAŞI  MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "EYNESİL",
        "STREET": "DEREKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "EYNESİL",
        "STREET": "GÜMÜŞÇAY MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "EYNESİL",
        "STREET": "KEKİKTEPE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "EYNESİL",
        "STREET": "KEMALİYE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "EYNESİL",
        "STREET": "KEMERLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "EYNESİL",
        "STREET": "KÖSELİ  MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "EYNESİL",
        "STREET": "KÖSEMEN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "EYNESİL",
        "STREET": "YARIMCA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "EYNESİL",
        "STREET": "ÇORAPÇILAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "EYNESİL",
        "STREET": "ÖREN-BELEN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "EYNESİL",
        "STREET": "ÖREN-CAMİDÜZÜ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "EYNESİL",
        "STREET": "ÖREN-ERZİKIRANI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "EYNESİL",
        "STREET": "ÖREN-KIRAN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "EYNESİL",
        "STREET": "ÖREN-SOKAK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "EYNESİL",
        "STREET": "ÖREN-TAMYANI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "EYNESİL",
        "STREET": "ÖREN-YAKUPLU MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "EYNESİL",
        "STREET": "İSHAKLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "GÖRELE",
        "STREET": "AKHARMAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "GÖRELE",
        "STREET": "ARALIKOZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "GÖRELE",
        "STREET": "ARDIÇ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "GÖRELE",
        "STREET": "ATAKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "GÖRELE",
        "STREET": "AYDINLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "GÖRELE",
        "STREET": "AYDINLIKEVLER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "GÖRELE",
        "STREET": "BAHÇELİEVLER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "GÖRELE",
        "STREET": "BAYAZIT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "GÖRELE",
        "STREET": "BEŞİRLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "GÖRELE",
        "STREET": "BOZCAALİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "GÖRELE",
        "STREET": "BOĞALI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "GÖRELE",
        "STREET": "BURUNUCU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "GÖRELE",
        "STREET": "CİLLİOĞLU MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "GÖRELE",
        "STREET": "DAYILI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "GÖRELE",
        "STREET": "DEDELİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "GÖRELE",
        "STREET": "DEREBOYU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "GÖRELE",
        "STREET": "DEREKUŞÇULU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "GÖRELE",
        "STREET": "DİKMEN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "GÖRELE",
        "STREET": "ESENLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "GÖRELE",
        "STREET": "ESENYURT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "GÖRELE",
        "STREET": "ESERLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "GÖRELE",
        "STREET": "GÖLBAŞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "GÖRELE",
        "STREET": "GÜLPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "GÖRELE",
        "STREET": "GÜLTEPE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "GÖRELE",
        "STREET": "GÜNEYKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "GÖRELE",
        "STREET": "GÜVENDİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "GÖRELE",
        "STREET": "HAMZALI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "GÖRELE",
        "STREET": "HAYDARLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "GÖRELE",
        "STREET": "HENDEKBAŞI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "GÖRELE",
        "STREET": "HÜRRİYET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "GÖRELE",
        "STREET": "KABA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "GÖRELE",
        "STREET": "KALEKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "GÖRELE",
        "STREET": "KARABURUN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "GÖRELE",
        "STREET": "KARADERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "GÖRELE",
        "STREET": "KARAKEŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "GÖRELE",
        "STREET": "KARLIBEL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "GÖRELE",
        "STREET": "KAYNAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "GÖRELE",
        "STREET": "KIDIR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "GÖRELE",
        "STREET": "KIRIKLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "GÖRELE",
        "STREET": "KOYUNHAMZA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "GÖRELE",
        "STREET": "KUMYALI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "GÖRELE",
        "STREET": "KUŞÇULU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "GÖRELE",
        "STREET": "KÖPRÜBAŞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "GÖRELE",
        "STREET": "MAKSUTLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "GÖRELE",
        "STREET": "MENTEŞE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "GÖRELE",
        "STREET": "ORTAKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "GÖRELE",
        "STREET": "RECEPLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "GÖRELE",
        "STREET": "SAYFİYE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "GÖRELE",
        "STREET": "SAĞLIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "GÖRELE",
        "STREET": "SEFERLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "GÖRELE",
        "STREET": "SOFULU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "GÖRELE",
        "STREET": "SOĞUKPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "GÖRELE",
        "STREET": "SOĞUKSU MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "GÖRELE",
        "STREET": "TAŞLIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "GÖRELE",
        "STREET": "TEKGÖZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "GÖRELE",
        "STREET": "TEPEBAŞI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "GÖRELE",
        "STREET": "TEPEKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "GÖRELE",
        "STREET": "TERZİALİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "GÖRELE",
        "STREET": "TÜRKELLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "GÖRELE",
        "STREET": "UMUTLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "GÖRELE",
        "STREET": "YALIKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "GÖRELE",
        "STREET": "YEĞENLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "GÖRELE",
        "STREET": "YEŞİLDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "GÖRELE",
        "STREET": "YEŞİLTEPE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "GÖRELE",
        "STREET": "ÇALIŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "GÖRELE",
        "STREET": "ÇATAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "GÖRELE",
        "STREET": "ÇATAKKIRI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "GÖRELE",
        "STREET": "ÇAVUŞLU-BAHŞİŞ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "GÖRELE",
        "STREET": "ÇAVUŞLU-BEYLİ  MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "GÖRELE",
        "STREET": "ÇAVUŞLU-MERKEZ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "GÖRELE",
        "STREET": "ÇAVUŞLU-OBAKIRAN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "GÖRELE",
        "STREET": "ÇAVUŞLU-ORTA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "GÖRELE",
        "STREET": "ÇAVUŞLU-YENİKÖY MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "GÖRELE",
        "STREET": "ÇAVUŞLU-ŞAHALİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "GÖRELE",
        "STREET": "ÇEŞMEBAŞI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "GÖRELE",
        "STREET": "ÇİFTLİKKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "GÖRELE",
        "STREET": "ÖNERLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "GÖRELE",
        "STREET": "ÜNLÜCE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "GÖRELE",
        "STREET": "İNANCA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "GÖRELE",
        "STREET": "İSMAİLBEYLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "GÖRELE",
        "STREET": "ŞAFAKLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "GÖRELE",
        "STREET": "ŞAHİNYUVA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "GÖRELE",
        "STREET": "ŞENLİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "GÜCE",
        "STREET": "AKPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "GÜCE",
        "STREET": "BONCUKÇUKUR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "GÜCE",
        "STREET": "DAYICIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "GÜCE",
        "STREET": "DÜZÇUKUR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "GÜCE",
        "STREET": "ERGENEKON KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "GÜCE",
        "STREET": "FINDIKLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "GÜCE",
        "STREET": "FIRINLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "GÜCE",
        "STREET": "GÜCE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "GÜCE",
        "STREET": "GÜRAĞAÇ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "GÜCE",
        "STREET": "GİYİMLİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "GÜCE",
        "STREET": "KEMALİYE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "GÜCE",
        "STREET": "KULUNCAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "GÜCE",
        "STREET": "SARIYAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "GÜCE",
        "STREET": "SOĞUKPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "GÜCE",
        "STREET": "TEKKEKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "GÜCE",
        "STREET": "TEVEKLİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "GÜCE",
        "STREET": "YUKARIBOYNUYOĞUN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "GÜCE",
        "STREET": "ÖRNEKKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "GÜCE",
        "STREET": "İLİT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "MERKEZ",
        "STREET": "AKINCI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "MERKEZ",
        "STREET": "AKKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "MERKEZ",
        "STREET": "AKSU MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "MERKEZ",
        "STREET": "AKÇALI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "MERKEZ",
        "STREET": "ALINCA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "MERKEZ",
        "STREET": "ANBARALAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "MERKEZ",
        "STREET": "AYDINLAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "MERKEZ",
        "STREET": "BARÇA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "MERKEZ",
        "STREET": "BARÇAÇAKIRLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "MERKEZ",
        "STREET": "BAYAZIT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "MERKEZ",
        "STREET": "BOZTEKKE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "MERKEZ",
        "STREET": "BURHANİYE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "MERKEZ",
        "STREET": "CAMİLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "MERKEZ",
        "STREET": "CUMHURİYET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "MERKEZ",
        "STREET": "DARIKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "MERKEZ",
        "STREET": "DUROĞLU-DEMİRCİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "MERKEZ",
        "STREET": "DUROĞLU-HOMURLU MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "MERKEZ",
        "STREET": "DUROĞLU-KONACIK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "MERKEZ",
        "STREET": "DUROĞLU-TEKKE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "MERKEZ",
        "STREET": "DUROĞLU-ÇAĞLAYAN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "MERKEZ",
        "STREET": "ERGENCE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "MERKEZ",
        "STREET": "ERİKLİMAN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "MERKEZ",
        "STREET": "ERİKLİMANI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "MERKEZ",
        "STREET": "ESENTEPE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "MERKEZ",
        "STREET": "EVRENKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "MERKEZ",
        "STREET": "FEVZİ ÇAKMAK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "MERKEZ",
        "STREET": "GAZİLER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "MERKEZ",
        "STREET": "GEDİKKAYA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "MERKEZ",
        "STREET": "GEDİKLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "MERKEZ",
        "STREET": "GEMİLERÇEKEĞİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "MERKEZ",
        "STREET": "GÜNEY KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "MERKEZ",
        "STREET": "GÜRE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "MERKEZ",
        "STREET": "GÜRKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "MERKEZ",
        "STREET": "GÜVEÇ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "MERKEZ",
        "STREET": "GİRESUN E TİPİ KAPALI CEZA İNFAZ KURUMU",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "MERKEZ",
        "STREET": "HACI HÜSEYİN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "MERKEZ",
        "STREET": "HACIMİKTAT MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "MERKEZ",
        "STREET": "HACISİYAM MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "MERKEZ",
        "STREET": "HAMİDİYE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "MERKEZ",
        "STREET": "HİSARGERİŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "MERKEZ",
        "STREET": "KALE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "MERKEZ",
        "STREET": "KAPU MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "MERKEZ",
        "STREET": "KARAALİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "MERKEZ",
        "STREET": "KAVAKLAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "MERKEZ",
        "STREET": "KAYADİBİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "MERKEZ",
        "STREET": "KEMALİYE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "MERKEZ",
        "STREET": "KÜÇÜKKÖY MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "MERKEZ",
        "STREET": "LAPA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "MERKEZ",
        "STREET": "MELİKLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "MERKEZ",
        "STREET": "MESUDİYE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "MERKEZ",
        "STREET": "NİZAMİYE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "MERKEZ",
        "STREET": "OKÇU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "MERKEZ",
        "STREET": "ORHANİYE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "MERKEZ",
        "STREET": "ORTAKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "MERKEZ",
        "STREET": "OSMANİYE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "MERKEZ",
        "STREET": "OSMANİYE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "MERKEZ",
        "STREET": "PINARÇUKURU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "MERKEZ",
        "STREET": "SAMANLIKKIRANI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "MERKEZ",
        "STREET": "SARVAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "MERKEZ",
        "STREET": "SAYCA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "MERKEZ",
        "STREET": "SELDEĞİRMENİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "MERKEZ",
        "STREET": "SEYİTKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "MERKEZ",
        "STREET": "SIVACI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "MERKEZ",
        "STREET": "SULTAN SELİM MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "MERKEZ",
        "STREET": "SULTANİYE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "MERKEZ",
        "STREET": "TEYYAREDÜZÜ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "MERKEZ",
        "STREET": "UZGUR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "MERKEZ",
        "STREET": "UZKARA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "MERKEZ",
        "STREET": "YALI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "MERKEZ",
        "STREET": "YAYKINLIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "MERKEZ",
        "STREET": "YAZLIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "MERKEZ",
        "STREET": "YAĞMURCA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "MERKEZ",
        "STREET": "YENİCEHİSAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "MERKEZ",
        "STREET": "YUKARIALINLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇAMLIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇANDIR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇAVUŞOĞLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇAYKARA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇINARLAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇITLAKKALE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇUKURKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇİMŞİR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇİÇEKLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "MERKEZ",
        "STREET": "ÜLPER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "MERKEZ",
        "STREET": "İNCEGERİŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "MERKEZ",
        "STREET": "İNİŞDİBİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "MERKEZ",
        "STREET": "ŞEYHKERAMETTİN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "KEŞAP",
        "STREET": "ALAKAŞ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "KEŞAP",
        "STREET": "ALATAŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "KEŞAP",
        "STREET": "ALTINPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "KEŞAP",
        "STREET": "ARMUTDÜZÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "KEŞAP",
        "STREET": "ARNAVUT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "KEŞAP",
        "STREET": "BALIKLISU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "KEŞAP",
        "STREET": "BAYRAMBEY KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "KEŞAP",
        "STREET": "BAYRAMŞAH KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "KEŞAP",
        "STREET": "BOZKURT MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "KEŞAP",
        "STREET": "CEYLANPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "KEŞAP",
        "STREET": "DEMİRCİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "KEŞAP",
        "STREET": "DOKUZTEPE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "KEŞAP",
        "STREET": "DÜZKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "KEŞAP",
        "STREET": "ERKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "KEŞAP",
        "STREET": "FINDIKLI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "KEŞAP",
        "STREET": "GEÇİT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "KEŞAP",
        "STREET": "GÖNÜLLÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "KEŞAP",
        "STREET": "GÜNEYKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "KEŞAP",
        "STREET": "GÜRPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "KEŞAP",
        "STREET": "HALKALI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "KEŞAP",
        "STREET": "HARMANDARLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "KEŞAP",
        "STREET": "HİSARÜSTÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "KEŞAP",
        "STREET": "KARABEDİR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "KEŞAP",
        "STREET": "KARABULDUK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "KEŞAP",
        "STREET": "KARADERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "KEŞAP",
        "STREET": "KARAKOÇ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "KEŞAP",
        "STREET": "KARAİSHAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "KEŞAP",
        "STREET": "KAYABAŞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "KEŞAP",
        "STREET": "KAŞALTI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "KEŞAP",
        "STREET": "KILIÇLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "KEŞAP",
        "STREET": "KURBANPINARI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "KEŞAP",
        "STREET": "KÜÇÜKGERİŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "KEŞAP",
        "STREET": "KİRAZLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "KEŞAP",
        "STREET": "SANCAKLITEPE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "KEŞAP",
        "STREET": "SARAYCIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "KEŞAP",
        "STREET": "SAYCA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "KEŞAP",
        "STREET": "SÜRMENLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "KEŞAP",
        "STREET": "TAFLANCIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "KEŞAP",
        "STREET": "TEPEKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "KEŞAP",
        "STREET": "TÖNGEL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "KEŞAP",
        "STREET": "UNACA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "KEŞAP",
        "STREET": "UĞURCA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "KEŞAP",
        "STREET": "YAZLIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "KEŞAP",
        "STREET": "YOLAĞZI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "KEŞAP",
        "STREET": "YOLBAŞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "KEŞAP",
        "STREET": "YOLİÇİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "KEŞAP",
        "STREET": "YÜNLÜCE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "KEŞAP",
        "STREET": "YİVDİNCİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "KEŞAP",
        "STREET": "ÇAKIRLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "KEŞAP",
        "STREET": "ÇAMLICA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "PİRAZİZ",
        "STREET": "AKÇAY KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "PİRAZİZ",
        "STREET": "ALİDEDE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "PİRAZİZ",
        "STREET": "ARMUTÇUKURU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "PİRAZİZ",
        "STREET": "AYDERE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "PİRAZİZ",
        "STREET": "BALÇIKLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "PİRAZİZ",
        "STREET": "BOZAT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "PİRAZİZ",
        "STREET": "BÜLBÜLLÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "PİRAZİZ",
        "STREET": "DEREGÖZÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "PİRAZİZ",
        "STREET": "EREN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "PİRAZİZ",
        "STREET": "ESENTEPE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "PİRAZİZ",
        "STREET": "ETLİKKIRAN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "PİRAZİZ",
        "STREET": "GÖKÇEALİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "PİRAZİZ",
        "STREET": "GÜNEYKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "PİRAZİZ",
        "STREET": "GÜZELKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "PİRAZİZ",
        "STREET": "HASANŞEYH KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "PİRAZİZ",
        "STREET": "KALEYANI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "PİRAZİZ",
        "STREET": "KARGI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "PİRAZİZ",
        "STREET": "KILIÇLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "PİRAZİZ",
        "STREET": "MADEN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "PİRAZİZ",
        "STREET": "MEDRESE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "PİRAZİZ",
        "STREET": "NARLIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "PİRAZİZ",
        "STREET": "NEFSİ PİRAZİZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "PİRAZİZ",
        "STREET": "TEPEKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "PİRAZİZ",
        "STREET": "YENİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "PİRAZİZ",
        "STREET": "YUNUSEMRE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "PİRAZİZ",
        "STREET": "ÇAYIRKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "PİRAZİZ",
        "STREET": "ÇAĞLANDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "PİRAZİZ",
        "STREET": "ÖRNEKKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "PİRAZİZ",
        "STREET": "ŞEREFLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "PİRAZİZ",
        "STREET": "ŞEYHLİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "PİRAZİZ",
        "STREET": "ŞIHKIRAN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "TİREBOLU",
        "STREET": "AKINCILAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "TİREBOLU",
        "STREET": "ARAGERİŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "TİREBOLU",
        "STREET": "ARSLANCIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "TİREBOLU",
        "STREET": "ATAKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "TİREBOLU",
        "STREET": "AVCILI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "TİREBOLU",
        "STREET": "AŞAĞIBOYNUYOĞUN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "TİREBOLU",
        "STREET": "BALÇIKBELEN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "TİREBOLU",
        "STREET": "BELEN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "TİREBOLU",
        "STREET": "CİNTAŞI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "TİREBOLU",
        "STREET": "CİVİL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "TİREBOLU",
        "STREET": "DANIŞMAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "TİREBOLU",
        "STREET": "DEMİRCİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "TİREBOLU",
        "STREET": "DOKUZKONAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "TİREBOLU",
        "STREET": "DOĞANCI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "TİREBOLU",
        "STREET": "DÜZKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "TİREBOLU",
        "STREET": "EDE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "TİREBOLU",
        "STREET": "EYMÜR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "TİREBOLU",
        "STREET": "FATİH KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "TİREBOLU",
        "STREET": "HACIHÜSEYİN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "TİREBOLU",
        "STREET": "HALAÇLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "TİREBOLU",
        "STREET": "HAMAM MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "TİREBOLU",
        "STREET": "HARKKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "TİREBOLU",
        "STREET": "IŞIKLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "TİREBOLU",
        "STREET": "KARAAHMETLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "TİREBOLU",
        "STREET": "KARADEMİR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "TİREBOLU",
        "STREET": "KAYALAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "TİREBOLU",
        "STREET": "KETENÇUKUR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "TİREBOLU",
        "STREET": "KOVANCIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "TİREBOLU",
        "STREET": "KOVANPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "TİREBOLU",
        "STREET": "KUSKUNLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "TİREBOLU",
        "STREET": "KUZGUN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "TİREBOLU",
        "STREET": "KÖRLİMAN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "TİREBOLU",
        "STREET": "KÖSELER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "TİREBOLU",
        "STREET": "MENDERES KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "TİREBOLU",
        "STREET": "MURSAL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "TİREBOLU",
        "STREET": "ORTACAMİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "TİREBOLU",
        "STREET": "ORTAKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "TİREBOLU",
        "STREET": "SEKÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "TİREBOLU",
        "STREET": "SULTANKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "TİREBOLU",
        "STREET": "YALIKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "TİREBOLU",
        "STREET": "YALÇ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "TİREBOLU",
        "STREET": "YARAŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "TİREBOLU",
        "STREET": "YAĞLIKUYUMCU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "TİREBOLU",
        "STREET": "YENİKÖY MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "TİREBOLU",
        "STREET": "YEŞİLPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "TİREBOLU",
        "STREET": "YILGIN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "TİREBOLU",
        "STREET": "YUKARIBOĞALI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "TİREBOLU",
        "STREET": "YUKARIORTACAMİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "TİREBOLU",
        "STREET": "ÇAMLIKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "TİREBOLU",
        "STREET": "ÇARŞI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "TİREBOLU",
        "STREET": "ÇEĞEL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "TİREBOLU",
        "STREET": "ÖRENKAYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "TİREBOLU",
        "STREET": "ÖZLÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "TİREBOLU",
        "STREET": "İSTİKLAL MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "TİREBOLU",
        "STREET": "İĞNECE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "TİREBOLU",
        "STREET": "ŞENYUVA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "TİREBOLU",
        "STREET": "ŞİRİN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "YAĞLIDERE",
        "STREET": "AHALLI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "YAĞLIDERE",
        "STREET": "AKDARI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "YAĞLIDERE",
        "STREET": "AKDOĞAN  MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "YAĞLIDERE",
        "STREET": "AKKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "YAĞLIDERE",
        "STREET": "AKPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "YAĞLIDERE",
        "STREET": "BÜK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "YAĞLIDERE",
        "STREET": "DEREKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "YAĞLIDERE",
        "STREET": "DERİNDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "YAĞLIDERE",
        "STREET": "ELMABELEN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "YAĞLIDERE",
        "STREET": "GÜLLÜCE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "YAĞLIDERE",
        "STREET": "GÜNECE  MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "YAĞLIDERE",
        "STREET": "HİSARCIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "YAĞLIDERE",
        "STREET": "KANLICA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "YAĞLIDERE",
        "STREET": "KIZILELMA  MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "YAĞLIDERE",
        "STREET": "KOÇLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "YAĞLIDERE",
        "STREET": "KURUCALI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "YAĞLIDERE",
        "STREET": "KÜÇÜKKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "YAĞLIDERE",
        "STREET": "MERKEZ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "YAĞLIDERE",
        "STREET": "ORTAKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "YAĞLIDERE",
        "STREET": "ORUÇBEY MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "YAĞLIDERE",
        "STREET": "PALAKLI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "YAĞLIDERE",
        "STREET": "SINIRKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "YAĞLIDERE",
        "STREET": "SİNANLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "YAĞLIDERE",
        "STREET": "TEKKEKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "YAĞLIDERE",
        "STREET": "TEPEKÖY MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "YAĞLIDERE",
        "STREET": "YAZLIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "YAĞLIDERE",
        "STREET": "YENİAKKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "YAĞLIDERE",
        "STREET": "YENİCE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "YAĞLIDERE",
        "STREET": "YENİYAZLIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "YAĞLIDERE",
        "STREET": "YEŞİLPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "YAĞLIDERE",
        "STREET": "YEŞİLYURT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "YAĞLIDERE",
        "STREET": "ÖMERLİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "YAĞLIDERE",
        "STREET": "ÜMÜTBÜKÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "YAĞLIDERE",
        "STREET": "ÜÇTEPE-GÜLTEPE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "YAĞLIDERE",
        "STREET": "ÜÇTEPE-KOZBÜKÜ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "YAĞLIDERE",
        "STREET": "ÜÇTEPE-TUĞLACIK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "YAĞLIDERE",
        "STREET": "ÜÇTEPE-ÇAĞLAYAN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "ÇAMOLUK",
        "STREET": "AKYAPI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "ÇAMOLUK",
        "STREET": "BAYIR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "ÇAMOLUK",
        "STREET": "DALDİBİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "ÇAMOLUK",
        "STREET": "DULUNDAS MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "ÇAMOLUK",
        "STREET": "EĞNİR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "ÇAMOLUK",
        "STREET": "FINDIKLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "ÇAMOLUK",
        "STREET": "GÜCER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "ÇAMOLUK",
        "STREET": "GÜRÇALI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "ÇAMOLUK",
        "STREET": "HACIAHMETOĞLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "ÇAMOLUK",
        "STREET": "HACIÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "ÇAMOLUK",
        "STREET": "HÜRRİYET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "ÇAMOLUK",
        "STREET": "KALEDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "ÇAMOLUK",
        "STREET": "KARADİKMEN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "ÇAMOLUK",
        "STREET": "KARŞIYAKA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "ÇAMOLUK",
        "STREET": "KAYACIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "ÇAMOLUK",
        "STREET": "KAYNAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "ÇAMOLUK",
        "STREET": "KILIÇTUTAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "ÇAMOLUK",
        "STREET": "KOÇAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "ÇAMOLUK",
        "STREET": "KURTULUŞ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "ÇAMOLUK",
        "STREET": "KURUKOL MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "ÇAMOLUK",
        "STREET": "KUTLUCA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "ÇAMOLUK",
        "STREET": "KÖROĞLU MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "ÇAMOLUK",
        "STREET": "OKÇAÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "ÇAMOLUK",
        "STREET": "OZAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "ÇAMOLUK",
        "STREET": "PINARLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "ÇAMOLUK",
        "STREET": "SARPKAYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "ÇAMOLUK",
        "STREET": "TAŞCILAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "ÇAMOLUK",
        "STREET": "TAŞDEMİR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "ÇAMOLUK",
        "STREET": "USLUCA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "ÇAMOLUK",
        "STREET": "YAZILAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "ÇAMOLUK",
        "STREET": "YENİCE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "ÇAMOLUK",
        "STREET": "YENİKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "ÇAMOLUK",
        "STREET": "YUSUFELİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "ÇAMOLUK",
        "STREET": "YUVACIK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "ÇAMOLUK",
        "STREET": "ÇAKILKAYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "ÇANAKÇI",
        "STREET": "AKKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "ÇANAKÇI",
        "STREET": "BAKIMLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "ÇANAKÇI",
        "STREET": "BEYLİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "ÇANAKÇI",
        "STREET": "CAMİ YANI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "ÇANAKÇI",
        "STREET": "DEREGÖZÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "ÇANAKÇI",
        "STREET": "DOĞANKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "ÇANAKÇI",
        "STREET": "DÜZKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "ÇANAKÇI",
        "STREET": "EGEKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "ÇANAKÇI",
        "STREET": "ERENKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "ÇANAKÇI",
        "STREET": "KAHRAMAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "ÇANAKÇI",
        "STREET": "KALEDİBİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "ÇANAKÇI",
        "STREET": "KARABÖRK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "ÇANAKÇI",
        "STREET": "KUŞKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "ÇANAKÇI",
        "STREET": "MERKEZ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "ÇANAKÇI",
        "STREET": "ORTA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "ÇANAKÇI",
        "STREET": "SARAYKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "ÇANAKÇI",
        "STREET": "YENİŞADI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "ÇANAKÇI",
        "STREET": "YEŞİLKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "ÇANAKÇI",
        "STREET": "ÇAĞLAYAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "ŞEBİNKARAHİSAR",
        "STREET": "AHURCUK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "ŞEBİNKARAHİSAR",
        "STREET": "AKBUDAK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "ŞEBİNKARAHİSAR",
        "STREET": "AKVİRAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "ŞEBİNKARAHİSAR",
        "STREET": "ALTINÇEVRE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "ŞEBİNKARAHİSAR",
        "STREET": "ALİŞAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "ŞEBİNKARAHİSAR",
        "STREET": "ARSLANŞAH KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "ŞEBİNKARAHİSAR",
        "STREET": "ASARCIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "ŞEBİNKARAHİSAR",
        "STREET": "AVUTMUŞ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "ŞEBİNKARAHİSAR",
        "STREET": "BALCANA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "ŞEBİNKARAHİSAR",
        "STREET": "BALTAŞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "ŞEBİNKARAHİSAR",
        "STREET": "BAYHASAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "ŞEBİNKARAHİSAR",
        "STREET": "BAYRAMKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "ŞEBİNKARAHİSAR",
        "STREET": "BUZKEÇİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "ŞEBİNKARAHİSAR",
        "STREET": "BÜLBÜL MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "ŞEBİNKARAHİSAR",
        "STREET": "BİROĞUL MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "ŞEBİNKARAHİSAR",
        "STREET": "DEREKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "ŞEBİNKARAHİSAR",
        "STREET": "DOĞANYUVA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "ŞEBİNKARAHİSAR",
        "STREET": "DUMAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "ŞEBİNKARAHİSAR",
        "STREET": "DÖNENÇAY KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "ŞEBİNKARAHİSAR",
        "STREET": "DİLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "ŞEBİNKARAHİSAR",
        "STREET": "EKECEK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "ŞEBİNKARAHİSAR",
        "STREET": "ESENTEPE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "ŞEBİNKARAHİSAR",
        "STREET": "EVCİLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "ŞEBİNKARAHİSAR",
        "STREET": "FATİH MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "ŞEBİNKARAHİSAR",
        "STREET": "GÖKÇETAŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "ŞEBİNKARAHİSAR",
        "STREET": "GÜNEYGÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "ŞEBİNKARAHİSAR",
        "STREET": "GÜRPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "ŞEBİNKARAHİSAR",
        "STREET": "GÜVERCİNLİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "ŞEBİNKARAHİSAR",
        "STREET": "GÜZELYURT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "ŞEBİNKARAHİSAR",
        "STREET": "HACIÖMER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "ŞEBİNKARAHİSAR",
        "STREET": "HASANŞEYH KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "ŞEBİNKARAHİSAR",
        "STREET": "HOCAOĞLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "ŞEBİNKARAHİSAR",
        "STREET": "KARAAĞAÇ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "ŞEBİNKARAHİSAR",
        "STREET": "KARACAÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "ŞEBİNKARAHİSAR",
        "STREET": "KAVAKLAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "ŞEBİNKARAHİSAR",
        "STREET": "KAYALI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "ŞEBİNKARAHİSAR",
        "STREET": "KINIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "ŞEBİNKARAHİSAR",
        "STREET": "KIRKGÖZ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "ŞEBİNKARAHİSAR",
        "STREET": "KIZILCA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "ŞEBİNKARAHİSAR",
        "STREET": "KONAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "ŞEBİNKARAHİSAR",
        "STREET": "KULE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "ŞEBİNKARAHİSAR",
        "STREET": "KÜTKÜT MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "ŞEBİNKARAHİSAR",
        "STREET": "MÜFTÜ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "ŞEBİNKARAHİSAR",
        "STREET": "OCAKTAŞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "ŞEBİNKARAHİSAR",
        "STREET": "OVACIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "ŞEBİNKARAHİSAR",
        "STREET": "OZANLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "ŞEBİNKARAHİSAR",
        "STREET": "SARAYCIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "ŞEBİNKARAHİSAR",
        "STREET": "SARIYER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "ŞEBİNKARAHİSAR",
        "STREET": "SUBOYU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "ŞEBİNKARAHİSAR",
        "STREET": "SULTANKONAĞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "ŞEBİNKARAHİSAR",
        "STREET": "SİPAHİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "ŞEBİNKARAHİSAR",
        "STREET": "TAMZARA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "ŞEBİNKARAHİSAR",
        "STREET": "TAŞ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "ŞEBİNKARAHİSAR",
        "STREET": "TAŞCILI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "ŞEBİNKARAHİSAR",
        "STREET": "TEKKAYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "ŞEBİNKARAHİSAR",
        "STREET": "TEPELTEPE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "ŞEBİNKARAHİSAR",
        "STREET": "TOKLUAĞIL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "ŞEBİNKARAHİSAR",
        "STREET": "TOPLUKONAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "ŞEBİNKARAHİSAR",
        "STREET": "TURPÇU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "ŞEBİNKARAHİSAR",
        "STREET": "UĞURCA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "ŞEBİNKARAHİSAR",
        "STREET": "YAKINCA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "ŞEBİNKARAHİSAR",
        "STREET": "YAVUZ SELİM MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "ŞEBİNKARAHİSAR",
        "STREET": "YAYCI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "ŞEBİNKARAHİSAR",
        "STREET": "YEDİKARDEŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "ŞEBİNKARAHİSAR",
        "STREET": "YENİYOL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "ŞEBİNKARAHİSAR",
        "STREET": "YEŞİLYAYLA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "ŞEBİNKARAHİSAR",
        "STREET": "YEŞİLYURT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "ŞEBİNKARAHİSAR",
        "STREET": "YILTARIÇ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "ŞEBİNKARAHİSAR",
        "STREET": "YUMURCAKTAŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "ŞEBİNKARAHİSAR",
        "STREET": "ÇAKIR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "ŞEBİNKARAHİSAR",
        "STREET": "ÇAMLIBEL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "ŞEBİNKARAHİSAR",
        "STREET": "ÇAĞLAYAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "ŞEBİNKARAHİSAR",
        "STREET": "ÇİFTLİK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "ŞEBİNKARAHİSAR",
        "STREET": "ÖRENCİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "ŞEBİNKARAHİSAR",
        "STREET": "İKİOĞUL MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "ŞEBİNKARAHİSAR",
        "STREET": "ŞAHİNLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "ŞEBİNKARAHİSAR",
        "STREET": "ŞAPLICA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "GİRESUN",
        "DISTRICT": "ŞEBİNKARAHİSAR",
        "STREET": "ŞEBİNKARAHİSAR K2 TİPİ KAPALI VE AÇIK CEZA İNFAZ K",
        "TYPE": "0"
      },
      {
        "CITY": "HAKKARİ",
        "DISTRICT": "DERECİK",
        "STREET": "ANADAĞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "HAKKARİ",
        "DISTRICT": "DERECİK",
        "STREET": "BALKAYA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "HAKKARİ",
        "DISTRICT": "DERECİK",
        "STREET": "GELİŞEN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "HAKKARİ",
        "DISTRICT": "DERECİK",
        "STREET": "GÜNEY MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "HAKKARİ",
        "DISTRICT": "DERECİK",
        "STREET": "GÜRMEŞE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "HAKKARİ",
        "DISTRICT": "DERECİK",
        "STREET": "HACIBEY MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "HAKKARİ",
        "DISTRICT": "DERECİK",
        "STREET": "MERKEZ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "HAKKARİ",
        "DISTRICT": "DERECİK",
        "STREET": "ORTA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "HAKKARİ",
        "DISTRICT": "DERECİK",
        "STREET": "ORTAKLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "HAKKARİ",
        "DISTRICT": "DERECİK",
        "STREET": "RİZE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "HAKKARİ",
        "DISTRICT": "DERECİK",
        "STREET": "USLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "HAKKARİ",
        "DISTRICT": "DERECİK",
        "STREET": "VAPURTEPE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "HAKKARİ",
        "DISTRICT": "DERECİK",
        "STREET": "ÜÇYAN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "HAKKARİ",
        "DISTRICT": "MERKEZ",
        "STREET": "AKBULUT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "HAKKARİ",
        "DISTRICT": "MERKEZ",
        "STREET": "AKKUŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "HAKKARİ",
        "DISTRICT": "MERKEZ",
        "STREET": "AKSU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "HAKKARİ",
        "DISTRICT": "MERKEZ",
        "STREET": "AKÇALI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "HAKKARİ",
        "DISTRICT": "MERKEZ",
        "STREET": "AĞAÇDİBİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "HAKKARİ",
        "DISTRICT": "MERKEZ",
        "STREET": "AŞAĞI DERECİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "HAKKARİ",
        "DISTRICT": "MERKEZ",
        "STREET": "BAYKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "HAKKARİ",
        "DISTRICT": "MERKEZ",
        "STREET": "BAĞIŞLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "HAKKARİ",
        "DISTRICT": "MERKEZ",
        "STREET": "BAĞLAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "HAKKARİ",
        "DISTRICT": "MERKEZ",
        "STREET": "BERÇELAN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "HAKKARİ",
        "DISTRICT": "MERKEZ",
        "STREET": "BOYBEYİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "HAKKARİ",
        "DISTRICT": "MERKEZ",
        "STREET": "BULAK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "HAKKARİ",
        "DISTRICT": "MERKEZ",
        "STREET": "BİÇER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "HAKKARİ",
        "DISTRICT": "MERKEZ",
        "STREET": "CEVİZDİBİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "HAKKARİ",
        "DISTRICT": "MERKEZ",
        "STREET": "CEYLANLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "HAKKARİ",
        "DISTRICT": "MERKEZ",
        "STREET": "DAĞGÖL MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "HAKKARİ",
        "DISTRICT": "MERKEZ",
        "STREET": "DEMİRTAŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "HAKKARİ",
        "DISTRICT": "MERKEZ",
        "STREET": "DOĞANYURT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "HAKKARİ",
        "DISTRICT": "MERKEZ",
        "STREET": "DURANKAYA-BAĞLARBAŞI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "HAKKARİ",
        "DISTRICT": "MERKEZ",
        "STREET": "DURANKAYA-BİÇENEK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "HAKKARİ",
        "DISTRICT": "MERKEZ",
        "STREET": "DURANKAYA-CUMHURİYET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "HAKKARİ",
        "DISTRICT": "MERKEZ",
        "STREET": "DURANKAYA-KÖYİÇİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "HAKKARİ",
        "DISTRICT": "MERKEZ",
        "STREET": "DURANKAYA-PINARBAŞI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "HAKKARİ",
        "DISTRICT": "MERKEZ",
        "STREET": "DURANKAYA-YENİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "HAKKARİ",
        "DISTRICT": "MERKEZ",
        "STREET": "DURANKAYA-ÇAYLICA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "HAKKARİ",
        "DISTRICT": "MERKEZ",
        "STREET": "ELMACIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "HAKKARİ",
        "DISTRICT": "MERKEZ",
        "STREET": "GAZİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "HAKKARİ",
        "DISTRICT": "MERKEZ",
        "STREET": "GEÇİMLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "HAKKARİ",
        "DISTRICT": "MERKEZ",
        "STREET": "GEÇİTLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "HAKKARİ",
        "DISTRICT": "MERKEZ",
        "STREET": "HAKKARİ KAPALI CEZA İNFAZ KURUMU",
        "TYPE": "0"
      },
      {
        "CITY": "HAKKARİ",
        "DISTRICT": "MERKEZ",
        "STREET": "HALİFE DERVİŞ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "HAKKARİ",
        "DISTRICT": "MERKEZ",
        "STREET": "IŞIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "HAKKARİ",
        "DISTRICT": "MERKEZ",
        "STREET": "IŞIKLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "HAKKARİ",
        "DISTRICT": "MERKEZ",
        "STREET": "KAMIŞLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "HAKKARİ",
        "DISTRICT": "MERKEZ",
        "STREET": "KARŞIYAKA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "HAKKARİ",
        "DISTRICT": "MERKEZ",
        "STREET": "KAVAKLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "HAKKARİ",
        "DISTRICT": "MERKEZ",
        "STREET": "KAVALKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "HAKKARİ",
        "DISTRICT": "MERKEZ",
        "STREET": "KAYMAKLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "HAKKARİ",
        "DISTRICT": "MERKEZ",
        "STREET": "KEKLİKPINAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "HAKKARİ",
        "DISTRICT": "MERKEZ",
        "STREET": "KIRAN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "HAKKARİ",
        "DISTRICT": "MERKEZ",
        "STREET": "KIRIKDAĞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "HAKKARİ",
        "DISTRICT": "MERKEZ",
        "STREET": "KONAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "HAKKARİ",
        "DISTRICT": "MERKEZ",
        "STREET": "MEDRESE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "HAKKARİ",
        "DISTRICT": "MERKEZ",
        "STREET": "MERZAN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "HAKKARİ",
        "DISTRICT": "MERKEZ",
        "STREET": "OLGUNLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "HAKKARİ",
        "DISTRICT": "MERKEZ",
        "STREET": "OTLUCA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "HAKKARİ",
        "DISTRICT": "MERKEZ",
        "STREET": "OĞUL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "HAKKARİ",
        "DISTRICT": "MERKEZ",
        "STREET": "PEHLİVAN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "HAKKARİ",
        "DISTRICT": "MERKEZ",
        "STREET": "PINARCA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "HAKKARİ",
        "DISTRICT": "MERKEZ",
        "STREET": "SARITAŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "HAKKARİ",
        "DISTRICT": "MERKEZ",
        "STREET": "SÜMBÜL MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "HAKKARİ",
        "DISTRICT": "MERKEZ",
        "STREET": "TAŞBAŞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "HAKKARİ",
        "DISTRICT": "MERKEZ",
        "STREET": "YENİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "HAKKARİ",
        "DISTRICT": "MERKEZ",
        "STREET": "YONCALI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "HAKKARİ",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇALTIKORU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "HAKKARİ",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇANAKLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "HAKKARİ",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇİMENLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "HAKKARİ",
        "DISTRICT": "MERKEZ",
        "STREET": "ÖRDEKLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "HAKKARİ",
        "DISTRICT": "MERKEZ",
        "STREET": "ÜZÜMCÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "HAKKARİ",
        "DISTRICT": "YÜKSEKOVA",
        "STREET": "ADAKLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "HAKKARİ",
        "DISTRICT": "YÜKSEKOVA",
        "STREET": "AKALIN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "HAKKARİ",
        "DISTRICT": "YÜKSEKOVA",
        "STREET": "AKOCAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "HAKKARİ",
        "DISTRICT": "YÜKSEKOVA",
        "STREET": "AKPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "HAKKARİ",
        "DISTRICT": "YÜKSEKOVA",
        "STREET": "AKSU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "HAKKARİ",
        "DISTRICT": "YÜKSEKOVA",
        "STREET": "AKÇALI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "HAKKARİ",
        "DISTRICT": "YÜKSEKOVA",
        "STREET": "ALTINOLUK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "HAKKARİ",
        "DISTRICT": "YÜKSEKOVA",
        "STREET": "ARMUTDÜZÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "HAKKARİ",
        "DISTRICT": "YÜKSEKOVA",
        "STREET": "AŞAĞIULUYOL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "HAKKARİ",
        "DISTRICT": "YÜKSEKOVA",
        "STREET": "BATAKLIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "HAKKARİ",
        "DISTRICT": "YÜKSEKOVA",
        "STREET": "BAĞDAŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "HAKKARİ",
        "DISTRICT": "YÜKSEKOVA",
        "STREET": "BEŞATLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "HAKKARİ",
        "DISTRICT": "YÜKSEKOVA",
        "STREET": "BEŞBULAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "HAKKARİ",
        "DISTRICT": "YÜKSEKOVA",
        "STREET": "BOSTANCIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "HAKKARİ",
        "DISTRICT": "YÜKSEKOVA",
        "STREET": "BULAKLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "HAKKARİ",
        "DISTRICT": "YÜKSEKOVA",
        "STREET": "BÖLÜK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "HAKKARİ",
        "DISTRICT": "YÜKSEKOVA",
        "STREET": "BÜYÜKÇİFTLİK-KEREM ZEYDAN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "HAKKARİ",
        "DISTRICT": "YÜKSEKOVA",
        "STREET": "BÜYÜKÇİFTLİK-MERKEZ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "HAKKARİ",
        "DISTRICT": "YÜKSEKOVA",
        "STREET": "BÜYÜKÇİFTLİK-YEŞİLOVA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "HAKKARİ",
        "DISTRICT": "YÜKSEKOVA",
        "STREET": "CUMHURİYET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "HAKKARİ",
        "DISTRICT": "YÜKSEKOVA",
        "STREET": "DAĞLICA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "HAKKARİ",
        "DISTRICT": "YÜKSEKOVA",
        "STREET": "DEDELER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "HAKKARİ",
        "DISTRICT": "YÜKSEKOVA",
        "STREET": "DEMİRKONAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "HAKKARİ",
        "DISTRICT": "YÜKSEKOVA",
        "STREET": "DEĞERLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "HAKKARİ",
        "DISTRICT": "YÜKSEKOVA",
        "STREET": "DOĞANLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "HAKKARİ",
        "DISTRICT": "YÜKSEKOVA",
        "STREET": "DİBEKLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "HAKKARİ",
        "DISTRICT": "YÜKSEKOVA",
        "STREET": "DİLEKTAŞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "HAKKARİ",
        "DISTRICT": "YÜKSEKOVA",
        "STREET": "DİZE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "HAKKARİ",
        "DISTRICT": "YÜKSEKOVA",
        "STREET": "ESEN YURT MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "HAKKARİ",
        "DISTRICT": "YÜKSEKOVA",
        "STREET": "ESENDERE-GÜVENLİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "HAKKARİ",
        "DISTRICT": "YÜKSEKOVA",
        "STREET": "ESENDERE-MERKEZ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "HAKKARİ",
        "DISTRICT": "YÜKSEKOVA",
        "STREET": "ESENDERE-SARIYILDIZ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "HAKKARİ",
        "DISTRICT": "YÜKSEKOVA",
        "STREET": "ESENDERE-YILMAZ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "HAKKARİ",
        "DISTRICT": "YÜKSEKOVA",
        "STREET": "ESENTEPE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "HAKKARİ",
        "DISTRICT": "YÜKSEKOVA",
        "STREET": "ESKİ KIŞLA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "HAKKARİ",
        "DISTRICT": "YÜKSEKOVA",
        "STREET": "GÖKYURT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "HAKKARİ",
        "DISTRICT": "YÜKSEKOVA",
        "STREET": "GÜLDALI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "HAKKARİ",
        "DISTRICT": "YÜKSEKOVA",
        "STREET": "GÜLLÜCE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "HAKKARİ",
        "DISTRICT": "YÜKSEKOVA",
        "STREET": "GÜNGÖR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "HAKKARİ",
        "DISTRICT": "YÜKSEKOVA",
        "STREET": "GÜRDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "HAKKARİ",
        "DISTRICT": "YÜKSEKOVA",
        "STREET": "GÜRKAVAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "HAKKARİ",
        "DISTRICT": "YÜKSEKOVA",
        "STREET": "GÜÇLÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "HAKKARİ",
        "DISTRICT": "YÜKSEKOVA",
        "STREET": "KADIKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "HAKKARİ",
        "DISTRICT": "YÜKSEKOVA",
        "STREET": "KAMIŞLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "HAKKARİ",
        "DISTRICT": "YÜKSEKOVA",
        "STREET": "KARABEY KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "HAKKARİ",
        "DISTRICT": "YÜKSEKOVA",
        "STREET": "KARLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "HAKKARİ",
        "DISTRICT": "YÜKSEKOVA",
        "STREET": "KAZAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "HAKKARİ",
        "DISTRICT": "YÜKSEKOVA",
        "STREET": "KEÇİLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "HAKKARİ",
        "DISTRICT": "YÜKSEKOVA",
        "STREET": "KISIKLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "HAKKARİ",
        "DISTRICT": "YÜKSEKOVA",
        "STREET": "KOLBAŞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "HAKKARİ",
        "DISTRICT": "YÜKSEKOVA",
        "STREET": "KÖPRÜCEK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "HAKKARİ",
        "DISTRICT": "YÜKSEKOVA",
        "STREET": "KÖŞKÖNÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "HAKKARİ",
        "DISTRICT": "YÜKSEKOVA",
        "STREET": "MEZARLIK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "HAKKARİ",
        "DISTRICT": "YÜKSEKOVA",
        "STREET": "ONBAŞILAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "HAKKARİ",
        "DISTRICT": "YÜKSEKOVA",
        "STREET": "ORTAÇ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "HAKKARİ",
        "DISTRICT": "YÜKSEKOVA",
        "STREET": "PINARGÖZÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "HAKKARİ",
        "DISTRICT": "YÜKSEKOVA",
        "STREET": "SALKIMLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "HAKKARİ",
        "DISTRICT": "YÜKSEKOVA",
        "STREET": "SARITAŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "HAKKARİ",
        "DISTRICT": "YÜKSEKOVA",
        "STREET": "SERİNDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "HAKKARİ",
        "DISTRICT": "YÜKSEKOVA",
        "STREET": "SUÜSTÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "HAKKARİ",
        "DISTRICT": "YÜKSEKOVA",
        "STREET": "SÜREKLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "HAKKARİ",
        "DISTRICT": "YÜKSEKOVA",
        "STREET": "TATLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "HAKKARİ",
        "DISTRICT": "YÜKSEKOVA",
        "STREET": "TUĞLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "HAKKARİ",
        "DISTRICT": "YÜKSEKOVA",
        "STREET": "VEZİRLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "HAKKARİ",
        "DISTRICT": "YÜKSEKOVA",
        "STREET": "YENİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "HAKKARİ",
        "DISTRICT": "YÜKSEKOVA",
        "STREET": "YENİIŞIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "HAKKARİ",
        "DISTRICT": "YÜKSEKOVA",
        "STREET": "YEŞİLDERE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "HAKKARİ",
        "DISTRICT": "YÜKSEKOVA",
        "STREET": "YEŞİLTAŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "HAKKARİ",
        "DISTRICT": "YÜKSEKOVA",
        "STREET": "YONCALIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "HAKKARİ",
        "DISTRICT": "YÜKSEKOVA",
        "STREET": "YÜREKLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "HAKKARİ",
        "DISTRICT": "YÜKSEKOVA",
        "STREET": "ÇATMA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "HAKKARİ",
        "DISTRICT": "YÜKSEKOVA",
        "STREET": "ÇUKURCA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "HAKKARİ",
        "DISTRICT": "YÜKSEKOVA",
        "STREET": "ÖRNEKKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "HAKKARİ",
        "DISTRICT": "YÜKSEKOVA",
        "STREET": "İNANLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "HAKKARİ",
        "DISTRICT": "YÜKSEKOVA",
        "STREET": "İPEK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "HAKKARİ",
        "DISTRICT": "ÇUKURCA",
        "STREET": "AKKAYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "HAKKARİ",
        "DISTRICT": "ÇUKURCA",
        "STREET": "CUMHURİYET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "HAKKARİ",
        "DISTRICT": "ÇUKURCA",
        "STREET": "EMİRŞABAN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "HAKKARİ",
        "DISTRICT": "ÇUKURCA",
        "STREET": "GÜNDEŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "HAKKARİ",
        "DISTRICT": "ÇUKURCA",
        "STREET": "NARLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "HAKKARİ",
        "DISTRICT": "ÇUKURCA",
        "STREET": "YEŞİL ÇEŞME MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "HAKKARİ",
        "DISTRICT": "ÇUKURCA",
        "STREET": "ÇIĞLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "HAKKARİ",
        "DISTRICT": "ÇUKURCA",
        "STREET": "ÜZÜMLÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "HAKKARİ",
        "DISTRICT": "ŞEMDİNLİ",
        "STREET": "ALAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "HAKKARİ",
        "DISTRICT": "ŞEMDİNLİ",
        "STREET": "ALTINSU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "HAKKARİ",
        "DISTRICT": "ŞEMDİNLİ",
        "STREET": "BAĞLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "HAKKARİ",
        "DISTRICT": "ŞEMDİNLİ",
        "STREET": "BEYYURDU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "HAKKARİ",
        "DISTRICT": "ŞEMDİNLİ",
        "STREET": "BEŞEVLER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "HAKKARİ",
        "DISTRICT": "ŞEMDİNLİ",
        "STREET": "BOZYAMAÇ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "HAKKARİ",
        "DISTRICT": "ŞEMDİNLİ",
        "STREET": "BOĞAZKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "HAKKARİ",
        "DISTRICT": "ŞEMDİNLİ",
        "STREET": "GÜNYAZI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "HAKKARİ",
        "DISTRICT": "ŞEMDİNLİ",
        "STREET": "KARŞIYAKA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "HAKKARİ",
        "DISTRICT": "ŞEMDİNLİ",
        "STREET": "KAYALAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "HAKKARİ",
        "DISTRICT": "ŞEMDİNLİ",
        "STREET": "KONUR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "HAKKARİ",
        "DISTRICT": "ŞEMDİNLİ",
        "STREET": "KORGAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "HAKKARİ",
        "DISTRICT": "ŞEMDİNLİ",
        "STREET": "MODA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "HAKKARİ",
        "DISTRICT": "ŞEMDİNLİ",
        "STREET": "TEKELİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "HAKKARİ",
        "DISTRICT": "ŞEMDİNLİ",
        "STREET": "TÜTÜNLÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "HAKKARİ",
        "DISTRICT": "ŞEMDİNLİ",
        "STREET": "UĞURAÇAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "HAKKARİ",
        "DISTRICT": "ŞEMDİNLİ",
        "STREET": "YAYLA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "HAKKARİ",
        "DISTRICT": "ŞEMDİNLİ",
        "STREET": "YAYLAPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "HAKKARİ",
        "DISTRICT": "ŞEMDİNLİ",
        "STREET": "YENİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "HAKKARİ",
        "DISTRICT": "ŞEMDİNLİ",
        "STREET": "YUFKALI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "HAKKARİ",
        "DISTRICT": "ŞEMDİNLİ",
        "STREET": "ÇALIŞKANLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "HAKKARİ",
        "DISTRICT": "ŞEMDİNLİ",
        "STREET": "ÇATALCA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "HAKKARİ",
        "DISTRICT": "ŞEMDİNLİ",
        "STREET": "ÇUBUKLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "HAKKARİ",
        "DISTRICT": "ŞEMDİNLİ",
        "STREET": "ÖVEÇ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "ALTINÖZÜ",
        "STREET": "AKAMBER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "ALTINÖZÜ",
        "STREET": "AKDARI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "ALTINÖZÜ",
        "STREET": "ALAKENT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "ALTINÖZÜ",
        "STREET": "ALTINKAYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "ALTINÖZÜ",
        "STREET": "ATAYURDU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "ALTINÖZÜ",
        "STREET": "AVUTTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "ALTINÖZÜ",
        "STREET": "BABATORUN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "ALTINÖZÜ",
        "STREET": "BOYNUYOĞUN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "ALTINÖZÜ",
        "STREET": "BÜYÜKBURÇ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "ALTINÖZÜ",
        "STREET": "DOKUZDAL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "ALTINÖZÜ",
        "STREET": "ENEK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "ALTINÖZÜ",
        "STREET": "ERBAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "ALTINÖZÜ",
        "STREET": "FATİKLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "ALTINÖZÜ",
        "STREET": "GÖZECİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "ALTINÖZÜ",
        "STREET": "GÜNVURAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "ALTINÖZÜ",
        "STREET": "HACIPAŞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "ALTINÖZÜ",
        "STREET": "HANYOLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "ALTINÖZÜ",
        "STREET": "KAMBERLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "ALTINÖZÜ",
        "STREET": "KANSU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "ALTINÖZÜ",
        "STREET": "KARBEYAZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "ALTINÖZÜ",
        "STREET": "KARSU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "ALTINÖZÜ",
        "STREET": "KAZANCIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "ALTINÖZÜ",
        "STREET": "KESKİNCİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "ALTINÖZÜ",
        "STREET": "KILIÇTUTAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "ALTINÖZÜ",
        "STREET": "KIYIGÖREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "ALTINÖZÜ",
        "STREET": "KOLCULAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "ALTINÖZÜ",
        "STREET": "KOZKALESİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "ALTINÖZÜ",
        "STREET": "KURTMEZRAASI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "ALTINÖZÜ",
        "STREET": "MAYADALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "ALTINÖZÜ",
        "STREET": "OYMAKLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "ALTINÖZÜ",
        "STREET": "SARIBÜK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "ALTINÖZÜ",
        "STREET": "SARILAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "ALTINÖZÜ",
        "STREET": "SEFERLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "ALTINÖZÜ",
        "STREET": "SOFULAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "ALTINÖZÜ",
        "STREET": "SİVRİKAVAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "ALTINÖZÜ",
        "STREET": "TEPEHAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "ALTINÖZÜ",
        "STREET": "TOKAÇLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "ALTINÖZÜ",
        "STREET": "TOKDEMİR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "ALTINÖZÜ",
        "STREET": "TOPRAKHİSAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "ALTINÖZÜ",
        "STREET": "TÜRKMENMEZRAASI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "ALTINÖZÜ",
        "STREET": "YANIKPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "ALTINÖZÜ",
        "STREET": "YARSELİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "ALTINÖZÜ",
        "STREET": "YENİHİSAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "ALTINÖZÜ",
        "STREET": "YENİŞEHİR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "ALTINÖZÜ",
        "STREET": "YOLAĞZI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "ALTINÖZÜ",
        "STREET": "YUNUSHANI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "ALTINÖZÜ",
        "STREET": "ZİYARET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "ALTINÖZÜ",
        "STREET": "ÇETENLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "ANTAKYA",
        "STREET": "AKASYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "ANTAKYA",
        "STREET": "AKCURUN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "ANTAKYA",
        "STREET": "AKEVLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "ANTAKYA",
        "STREET": "AKHİSAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "ANTAKYA",
        "STREET": "AKSARAY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "ANTAKYA",
        "STREET": "AKÇAOVA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "ANTAKYA",
        "STREET": "ALAATTİN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "ANTAKYA",
        "STREET": "ALAHAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "ANTAKYA",
        "STREET": "ALAZI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "ANTAKYA",
        "STREET": "ALTINÇAY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "ANTAKYA",
        "STREET": "ANAYAZI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "ANTAKYA",
        "STREET": "APAYDIN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "ANTAKYA",
        "STREET": "ARPAHAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "ANTAKYA",
        "STREET": "AVSUYU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "ANTAKYA",
        "STREET": "AYDINLIKEVLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "ANTAKYA",
        "STREET": "AÇIKDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "ANTAKYA",
        "STREET": "AŞAĞIOBA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "ANTAKYA",
        "STREET": "BARBAROS MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "ANTAKYA",
        "STREET": "BAĞRIYANIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "ANTAKYA",
        "STREET": "BOHŞİN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "ANTAKYA",
        "STREET": "BOZHÖYÜK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "ANTAKYA",
        "STREET": "BÜYÜKDALYAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "ANTAKYA",
        "STREET": "BİNİCİLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "ANTAKYA",
        "STREET": "BİTİREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "ANTAKYA",
        "STREET": "CEBRAİL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "ANTAKYA",
        "STREET": "CUMHURİYET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "ANTAKYA",
        "STREET": "DEMİRKÖPRÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "ANTAKYA",
        "STREET": "DERİNCE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "ANTAKYA",
        "STREET": "DOĞANKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "ANTAKYA",
        "STREET": "DUTDİBİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "ANTAKYA",
        "STREET": "DİKMECE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "ANTAKYA",
        "STREET": "EKİNCİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "ANTAKYA",
        "STREET": "EMEK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "ANTAKYA",
        "STREET": "ESENLİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "ANTAKYA",
        "STREET": "ESENTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "ANTAKYA",
        "STREET": "FEVZİ ÇAKMAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "ANTAKYA",
        "STREET": "GAZİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "ANTAKYA",
        "STREET": "GAZİ PAŞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "ANTAKYA",
        "STREET": "GENERAL ŞÜKRÜ KANATLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "ANTAKYA",
        "STREET": "GÖKÇEGÖZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "ANTAKYA",
        "STREET": "GÜLDEREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "ANTAKYA",
        "STREET": "GÜLLÜ BAHÇE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "ANTAKYA",
        "STREET": "GÜNYAZI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "ANTAKYA",
        "STREET": "GÜZELBURÇ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "ANTAKYA",
        "STREET": "HABİB-İ NECCAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "ANTAKYA",
        "STREET": "HACI ÖMER ALPAGOT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "ANTAKYA",
        "STREET": "HARAPARASI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "ANTAKYA",
        "STREET": "HASANLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "ANTAKYA",
        "STREET": "HAVUZLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "ANTAKYA",
        "STREET": "KANTARA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "ANTAKYA",
        "STREET": "KARAALİ BÖLÜĞÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "ANTAKYA",
        "STREET": "KARAALİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "ANTAKYA",
        "STREET": "KARDEŞLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "ANTAKYA",
        "STREET": "KARLISU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "ANTAKYA",
        "STREET": "KIŞLA SARAY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "ANTAKYA",
        "STREET": "KOCAABDİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "ANTAKYA",
        "STREET": "KURUYER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "ANTAKYA",
        "STREET": "KUYULU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "ANTAKYA",
        "STREET": "KUZEYTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "ANTAKYA",
        "STREET": "KÜÇÜKDALYAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "ANTAKYA",
        "STREET": "KİSECİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "ANTAKYA",
        "STREET": "MADENBOYU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "ANTAKYA",
        "STREET": "MANSURLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "ANTAKYA",
        "STREET": "MARAŞBOĞAZI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "ANTAKYA",
        "STREET": "MAŞUKLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "ANTAKYA",
        "STREET": "MELEKLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "ANTAKYA",
        "STREET": "MEYDAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "ANTAKYA",
        "STREET": "NARLICA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "ANTAKYA",
        "STREET": "ODABAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "ANTAKYA",
        "STREET": "ORHANLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "ANTAKYA",
        "STREET": "OVAKENT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "ANTAKYA",
        "STREET": "OĞLAKÖREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "ANTAKYA",
        "STREET": "PAŞAKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "ANTAKYA",
        "STREET": "SARAYCIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "ANTAKYA",
        "STREET": "SARAYKENT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "ANTAKYA",
        "STREET": "SAÇAKLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "ANTAKYA",
        "STREET": "SERİNYOL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "ANTAKYA",
        "STREET": "SOFULAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "ANTAKYA",
        "STREET": "SUVATLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "ANTAKYA",
        "STREET": "TAHTAKÖPRÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "ANTAKYA",
        "STREET": "TANIŞMA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "ANTAKYA",
        "STREET": "ULUCAMİİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "ANTAKYA",
        "STREET": "UZUNALİÇ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "ANTAKYA",
        "STREET": "YAYLACIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "ANTAKYA",
        "STREET": "YENİ CAMİİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "ANTAKYA",
        "STREET": "YEŞİLOVA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "ANTAKYA",
        "STREET": "ZENGİNLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "ANTAKYA",
        "STREET": "ZÜLÜFLÜHAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "ANTAKYA",
        "STREET": "ÜRGEN PAŞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "ANTAKYA",
        "STREET": "ÜZÜMDALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "ANTAKYA",
        "STREET": "ÜÇGEDİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "ANTAKYA",
        "STREET": "İPLİK PAZARI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "ANTAKYA",
        "STREET": "ŞEHİTLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "ANTAKYA",
        "STREET": "ŞEYHALİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "ANTAKYA",
        "STREET": "ŞİRİNCE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "ARSUZ",
        "STREET": "AKÇALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "ARSUZ",
        "STREET": "ARPADERESİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "ARSUZ",
        "STREET": "ARPAGEDİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "ARSUZ",
        "STREET": "ARPAÇİFTLİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "ARSUZ",
        "STREET": "ARSUZ T TİPİ KAPALI CEZA İNFAZ KURUMU",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "ARSUZ",
        "STREET": "AVCILARSUYU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "ARSUZ",
        "STREET": "AŞAĞI KEPİRCE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "ARSUZ",
        "STREET": "BEYKÖYÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "ARSUZ",
        "STREET": "DEREKUYU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "ARSUZ",
        "STREET": "GÖKMEYDAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "ARSUZ",
        "STREET": "GÖZCÜLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "ARSUZ",
        "STREET": "GÜLCİHAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "ARSUZ",
        "STREET": "HACIAHMETLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "ARSUZ",
        "STREET": "HARLISU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "ARSUZ",
        "STREET": "HAYMASEKİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "ARSUZ",
        "STREET": "HELVALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "ARSUZ",
        "STREET": "HÜYÜK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "ARSUZ",
        "STREET": "IŞIKLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "ARSUZ",
        "STREET": "KALE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "ARSUZ",
        "STREET": "KARAAĞAÇ CUMHURİYET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "ARSUZ",
        "STREET": "KARAAĞAÇ KONARLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "ARSUZ",
        "STREET": "KARAAĞAÇ ÖVÜNDÜK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "ARSUZ",
        "STREET": "KARAAĞAÇ ŞARKONAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "ARSUZ",
        "STREET": "KARAGÖZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "ARSUZ",
        "STREET": "KARAHÜSEYİNLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "ARSUZ",
        "STREET": "KIŞLA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "ARSUZ",
        "STREET": "KONACIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "ARSUZ",
        "STREET": "KOZAKLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "ARSUZ",
        "STREET": "KURTBAĞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "ARSUZ",
        "STREET": "MADENLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "ARSUZ",
        "STREET": "NARDÜZÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "ARSUZ",
        "STREET": "NERGİZLİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "ARSUZ",
        "STREET": "PİRİNÇLİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "ARSUZ",
        "STREET": "TATARLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "ARSUZ",
        "STREET": "TÜLEK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "ARSUZ",
        "STREET": "ULUÇINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "ARSUZ",
        "STREET": "YUKARIKEPİRCE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "ARSUZ",
        "STREET": "ÇETİLLİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "ARSUZ",
        "STREET": "ÜÇGÜLLÜK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "BELEN",
        "STREET": "ATİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "BELEN",
        "STREET": "BAKRAS MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "BELEN",
        "STREET": "BENLİDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "BELEN",
        "STREET": "CUMHURİYET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "BELEN",
        "STREET": "DEREBAHÇE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "BELEN",
        "STREET": "FATİH MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "BELEN",
        "STREET": "HALİLBEY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "BELEN",
        "STREET": "KICI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "BELEN",
        "STREET": "KÖMÜRÇUKURU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "BELEN",
        "STREET": "MUHLİSALİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "BELEN",
        "STREET": "MÜFTÜLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "BELEN",
        "STREET": "SARIMAZI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "BELEN",
        "STREET": "SOĞUKOLUK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "BELEN",
        "STREET": "ÇAKALLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "BELEN",
        "STREET": "ÇERÇİKAYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "BELEN",
        "STREET": "ÖTENÇAY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "BELEN",
        "STREET": "İSSUME MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "BELEN",
        "STREET": "ŞEKERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "BELEN",
        "STREET": "ŞENBÜK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "DEFNE",
        "STREET": "AKDENİZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "DEFNE",
        "STREET": "AKNEHİR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "DEFNE",
        "STREET": "ARMUTLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "DEFNE",
        "STREET": "AŞAĞIOKÇULAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "DEFNE",
        "STREET": "BAHÇEKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "DEFNE",
        "STREET": "BALIKLIDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "DEFNE",
        "STREET": "BALLIÖZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "DEFNE",
        "STREET": "BOSTANCIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "DEFNE",
        "STREET": "BÜYÜKÇAT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "DEFNE",
        "STREET": "DEĞİRMENYOLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "DEFNE",
        "STREET": "DURSUNLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "DEFNE",
        "STREET": "DÖVER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "DEFNE",
        "STREET": "ELEKTRİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "DEFNE",
        "STREET": "GÜMÜŞGÖZE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "DEFNE",
        "STREET": "GÜNEYSÖĞÜT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "DEFNE",
        "STREET": "HANCAĞIZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "DEFNE",
        "STREET": "HARBİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "DEFNE",
        "STREET": "HÜSEYİNLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "DEFNE",
        "STREET": "KARŞIYAKA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "DEFNE",
        "STREET": "KOÇÖREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "DEFNE",
        "STREET": "MEYDANCIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "DEFNE",
        "STREET": "ORHANLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "DEFNE",
        "STREET": "SAMANKAYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "DEFNE",
        "STREET": "SUBAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "DEFNE",
        "STREET": "SÜMERLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "DEFNE",
        "STREET": "SİNANLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "DEFNE",
        "STREET": "TAVLA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "DEFNE",
        "STREET": "TOYGARLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "DEFNE",
        "STREET": "TURUNÇLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "DEFNE",
        "STREET": "YENİÇAĞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "DEFNE",
        "STREET": "YEŞİLPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "DEFNE",
        "STREET": "ÇARDAKLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "DEFNE",
        "STREET": "ÇEKMECE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "DEFNE",
        "STREET": "ÇINARLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "DEFNE",
        "STREET": "ÇÖKEK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "DEFNE",
        "STREET": "ÖZBEK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "DEFNE",
        "STREET": "ÜZENGİLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "DÖRTYOL",
        "STREET": "ALTINÇAĞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "DÖRTYOL",
        "STREET": "KAPILI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "DÖRTYOL",
        "STREET": "KARAKESE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "DÖRTYOL",
        "STREET": "KIŞLALAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "DÖRTYOL",
        "STREET": "KONAKLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "DÖRTYOL",
        "STREET": "KUZUCULU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "DÖRTYOL",
        "STREET": "NUMUNE EVLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "DÖRTYOL",
        "STREET": "OCAKLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "DÖRTYOL",
        "STREET": "SANAYİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "DÖRTYOL",
        "STREET": "YENİYURT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "DÖRTYOL",
        "STREET": "YEŞİL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "DÖRTYOL",
        "STREET": "YEŞİLKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "DÖRTYOL",
        "STREET": "ÇATKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "DÖRTYOL",
        "STREET": "ÇAYLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "DÖRTYOL",
        "STREET": "ÖZERLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "ERZİN",
        "STREET": "AŞAĞIBURNAZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "ERZİN",
        "STREET": "BAHÇELİEVLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "ERZİN",
        "STREET": "BAŞLAMIŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "ERZİN",
        "STREET": "CUMHURİYET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "ERZİN",
        "STREET": "GÖKDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "ERZİN",
        "STREET": "GÖKGÖL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "ERZİN",
        "STREET": "HÜRRİYET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "ERZİN",
        "STREET": "KARAMUSTAFALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "ERZİN",
        "STREET": "KIZLARÇAYI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "ERZİN",
        "STREET": "KUYULUK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "ERZİN",
        "STREET": "MAHMUTLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "ERZİN",
        "STREET": "MUSTAFALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "ERZİN",
        "STREET": "TURUNÇLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "ERZİN",
        "STREET": "YENİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "ERZİN",
        "STREET": "YEŞİLTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "ERZİN",
        "STREET": "YONCADÜZÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "ERZİN",
        "STREET": "YUKARIBURNAZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "ERZİN",
        "STREET": "İSALİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "ERZİN",
        "STREET": "İSTİKLAL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "ERZİN",
        "STREET": "ŞÜKRÜ PAŞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "HASSA",
        "STREET": "15 KASIM MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "HASSA",
        "STREET": "AKBEZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "HASSA",
        "STREET": "AKKÜLEK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "HASSA",
        "STREET": "AKTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "HASSA",
        "STREET": "ARDIÇLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "HASSA",
        "STREET": "ARPALIUŞAĞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "HASSA",
        "STREET": "AŞAĞIKARAFAKILI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "HASSA",
        "STREET": "BADEMLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "HASSA",
        "STREET": "BUHARA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "HASSA",
        "STREET": "BİNTAŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "HASSA",
        "STREET": "DEDEMLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "HASSA",
        "STREET": "DEMREK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "HASSA",
        "STREET": "DERVİŞ PAŞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "HASSA",
        "STREET": "EĞRİBUCAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "HASSA",
        "STREET": "GAZELUŞAĞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "HASSA",
        "STREET": "GÜLKENT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "HASSA",
        "STREET": "GÜLPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "HASSA",
        "STREET": "GÜVENÇ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "HASSA",
        "STREET": "GİRNE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "HASSA",
        "STREET": "HACILAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "HASSA",
        "STREET": "HAYDARLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "HASSA",
        "STREET": "KATRANLIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "HASSA",
        "STREET": "KORUHÜYÜK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "HASSA",
        "STREET": "KÜRECİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "HASSA",
        "STREET": "MAZMANLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "HASSA",
        "STREET": "SAPANÖZÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "HASSA",
        "STREET": "SUGEDİĞİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "HASSA",
        "STREET": "SÖĞÜT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "HASSA",
        "STREET": "TEPEBAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "HASSA",
        "STREET": "TİYEK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "HASSA",
        "STREET": "YENİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "HASSA",
        "STREET": "YENİYAPAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "HASSA",
        "STREET": "YOLUKLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "HASSA",
        "STREET": "YUKARIBUCAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "HASSA",
        "STREET": "YUKARIKARAFAKILI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "HASSA",
        "STREET": "YUVALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "HASSA",
        "STREET": "ZEYTİNOBA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "HASSA",
        "STREET": "ÇAY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "HASSA",
        "STREET": "ÇINARBAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "KIRIKHAN",
        "STREET": "408 EVLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "KIRIKHAN",
        "STREET": "ABALAKLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "KIRIKHAN",
        "STREET": "ADALAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "KIRIKHAN",
        "STREET": "ALAYBEYLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "KIRIKHAN",
        "STREET": "ALPARSLAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "KIRIKHAN",
        "STREET": "ALSANCAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "KIRIKHAN",
        "STREET": "ALİBEYÇAĞILLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "KIRIKHAN",
        "STREET": "ARKITÇA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "KIRIKHAN",
        "STREET": "ATTUTAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "KIRIKHAN",
        "STREET": "AYDINLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "KIRIKHAN",
        "STREET": "AYGIRGÖLÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "KIRIKHAN",
        "STREET": "BAHÇELİEVLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "KIRIKHAN",
        "STREET": "BALARMUDU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "KIRIKHAN",
        "STREET": "BALDIRAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "KIRIKHAN",
        "STREET": "BARBAROS MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "KIRIKHAN",
        "STREET": "BAŞPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "KIRIKHAN",
        "STREET": "BEKTAŞLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "KIRIKHAN",
        "STREET": "CAMUZKIŞLASI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "KIRIKHAN",
        "STREET": "CEYLANLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "KIRIKHAN",
        "STREET": "CUMHURİYET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "KIRIKHAN",
        "STREET": "DANAAHMETLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "KIRIKHAN",
        "STREET": "DEDEÇINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "KIRIKHAN",
        "STREET": "DELİBEKİRLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "KIRIKHAN",
        "STREET": "DEMİRKONAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "KIRIKHAN",
        "STREET": "FATİH MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "KIRIKHAN",
        "STREET": "GAZİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "KIRIKHAN",
        "STREET": "GÖLBAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "KIRIKHAN",
        "STREET": "GÜLTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "KIRIKHAN",
        "STREET": "GÜNDÜZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "KIRIKHAN",
        "STREET": "GÜVENTAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "KIRIKHAN",
        "STREET": "GÜZELCE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "KIRIKHAN",
        "STREET": "ILIKPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "KIRIKHAN",
        "STREET": "KALETEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "KIRIKHAN",
        "STREET": "KAMBERLİKAYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "KIRIKHAN",
        "STREET": "KAMIŞLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "KIRIKHAN",
        "STREET": "KANGALLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "KIRIKHAN",
        "STREET": "KARADURMUŞLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "KIRIKHAN",
        "STREET": "KARAELMASLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "KIRIKHAN",
        "STREET": "KARAMANKAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "KIRIKHAN",
        "STREET": "KARAMAĞARA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "KIRIKHAN",
        "STREET": "KARATAŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "KIRIKHAN",
        "STREET": "KARAÇAĞIL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "KIRIKHAN",
        "STREET": "KAZKELİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "KIRIKHAN",
        "STREET": "KIZILKAYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "KIRIKHAN",
        "STREET": "KODALLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "KIRIKHAN",
        "STREET": "KURTLUSARIMAZI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "KIRIKHAN",
        "STREET": "KURTLUSOĞUKSU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "KIRIKHAN",
        "STREET": "KURTULUŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "KIRIKHAN",
        "STREET": "MAHMUTLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "KIRIKHAN",
        "STREET": "MENDERES MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "KIRIKHAN",
        "STREET": "MURATPAŞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "KIRIKHAN",
        "STREET": "MİMAR SİNAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "KIRIKHAN",
        "STREET": "NARLIHOPUR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "KIRIKHAN",
        "STREET": "REŞATLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "KIRIKHAN",
        "STREET": "SAYLAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "KIRIKHAN",
        "STREET": "SUCUKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "KIRIKHAN",
        "STREET": "SÖĞÜTLÜÖZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "KIRIKHAN",
        "STREET": "TAŞOLUK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "KIRIKHAN",
        "STREET": "TOPBOĞAZI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "KIRIKHAN",
        "STREET": "TORUN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "KIRIKHAN",
        "STREET": "YALANGOZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "KIRIKHAN",
        "STREET": "YENİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "KIRIKHAN",
        "STREET": "YILANLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "KIRIKHAN",
        "STREET": "ÇAMSARI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "KIRIKHAN",
        "STREET": "ÇAMSEKİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "KIRIKHAN",
        "STREET": "ÇANKAYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "KIRIKHAN",
        "STREET": "ÇATALTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "KIRIKHAN",
        "STREET": "ÇİLOĞLANHÜYÜĞÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "KIRIKHAN",
        "STREET": "ÖZKIZILKAYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "KIRIKHAN",
        "STREET": "ÖZSOĞUKSU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "KIRIKHAN",
        "STREET": "ÖZYÖRÜK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "KIRIKHAN",
        "STREET": "İNCİRLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "KIRIKHAN",
        "STREET": "İÇADA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "KUMLU",
        "STREET": "AKKERPİÇ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "KUMLU",
        "STREET": "AKKUYU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "KUMLU",
        "STREET": "AKPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "KUMLU",
        "STREET": "AKTAŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "KUMLU",
        "STREET": "BATIAYRANCI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "KUMLU",
        "STREET": "CUMHURİYET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "KUMLU",
        "STREET": "DOĞUAYRANCI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "KUMLU",
        "STREET": "FEVZİ ÇAKMAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "KUMLU",
        "STREET": "GÖKÇEOĞLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "KUMLU",
        "STREET": "GÜLOVA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "KUMLU",
        "STREET": "HAMAM MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "KUMLU",
        "STREET": "HATAYHAMAMI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "KUMLU",
        "STREET": "KALETEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "KUMLU",
        "STREET": "KELİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "KUMLU",
        "STREET": "KIRCAOĞLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "KUMLU",
        "STREET": "MUHARREM MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "PAYAS",
        "STREET": "CUMHURİYET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "PAYAS",
        "STREET": "FATİH MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "PAYAS",
        "STREET": "KARACAMİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "PAYAS",
        "STREET": "KARBEYAZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "PAYAS",
        "STREET": "KARŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "PAYAS",
        "STREET": "KOZLUDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "PAYAS",
        "STREET": "KÜRTÜL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "PAYAS",
        "STREET": "SİNCAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "PAYAS",
        "STREET": "YENİŞEHİR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "PAYAS",
        "STREET": "YILDIRIM BEYAZIT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "PAYAS",
        "STREET": "ÇAĞLALIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "PAYAS",
        "STREET": "İSTİKLAL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "REYHANLI",
        "STREET": "ADABUCAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "REYHANLI",
        "STREET": "AHMETBEYLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "REYHANLI",
        "STREET": "AKYAYLA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "REYHANLI",
        "STREET": "ALAKUZU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "REYHANLI",
        "STREET": "BAHÇELİEVLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "REYHANLI",
        "STREET": "BAYIR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "REYHANLI",
        "STREET": "BAĞLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "REYHANLI",
        "STREET": "BEŞASLAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "REYHANLI",
        "STREET": "BÜKÜLMEZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "REYHANLI",
        "STREET": "CUMHURİYET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "REYHANLI",
        "STREET": "CÜDEYDE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "REYHANLI",
        "STREET": "CİLVEGÖZÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "REYHANLI",
        "STREET": "DAVUTPAŞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "REYHANLI",
        "STREET": "DEĞİRMENKAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "REYHANLI",
        "STREET": "ESENTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "REYHANLI",
        "STREET": "FEVZİPAŞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "REYHANLI",
        "STREET": "FİDANLIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "REYHANLI",
        "STREET": "GAZİMÜRSELTEPESİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "REYHANLI",
        "STREET": "GÖKTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "REYHANLI",
        "STREET": "GÜLTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "REYHANLI",
        "STREET": "HARRAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "REYHANLI",
        "STREET": "KARACANLIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "REYHANLI",
        "STREET": "KARAHÜYÜK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "REYHANLI",
        "STREET": "KARASÜLEYMANLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "REYHANLI",
        "STREET": "KONUK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "REYHANLI",
        "STREET": "KULETEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "REYHANLI",
        "STREET": "KUMTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "REYHANLI",
        "STREET": "KURTULUŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "REYHANLI",
        "STREET": "KUŞAKLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "REYHANLI",
        "STREET": "MEHMETBEYLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "REYHANLI",
        "STREET": "MUSTAFA KEMAL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "REYHANLI",
        "STREET": "NERGİZLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "REYHANLI",
        "STREET": "OĞULPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "REYHANLI",
        "STREET": "PAŞAHÜYÜK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "REYHANLI",
        "STREET": "PAŞAKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "REYHANLI",
        "STREET": "PINARBAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "REYHANLI",
        "STREET": "SULUKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "REYHANLI",
        "STREET": "TAYFURSÖKMEN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "REYHANLI",
        "STREET": "TERZİHÜYÜK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "REYHANLI",
        "STREET": "UZUNKAVAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "REYHANLI",
        "STREET": "VARIŞLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "REYHANLI",
        "STREET": "YENİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "REYHANLI",
        "STREET": "YENİŞEHİR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "REYHANLI",
        "STREET": "YEŞİLOVA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "REYHANLI",
        "STREET": "ÇAKIRYİĞİT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "REYHANLI",
        "STREET": "ÖZ KURTULUŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "REYHANLI",
        "STREET": "ÜÇTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "SAMANDAĞ",
        "STREET": "ATAKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "SAMANDAĞ",
        "STREET": "ATATÜRK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "SAMANDAĞ",
        "STREET": "AVCILAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "SAMANDAĞ",
        "STREET": "BATIAYAZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "SAMANDAĞ",
        "STREET": "BÜYÜKOBA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "SAMANDAĞ",
        "STREET": "CEMAL GÜRSEL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "SAMANDAĞ",
        "STREET": "CEYLANDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "SAMANDAĞ",
        "STREET": "CUMHURİYET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "SAMANDAĞ",
        "STREET": "DENİZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "SAMANDAĞ",
        "STREET": "DEĞİRMENBAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "SAMANDAĞ",
        "STREET": "ERİKLİKUYU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "SAMANDAĞ",
        "STREET": "FİDANLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "SAMANDAĞ",
        "STREET": "GENERAL ŞÜKRÜ KANADLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "SAMANDAĞ",
        "STREET": "GÖZENE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "SAMANDAĞ",
        "STREET": "HIDIRBEY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "SAMANDAĞ",
        "STREET": "HUZURLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "SAMANDAĞ",
        "STREET": "KAPISUYU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "SAMANDAĞ",
        "STREET": "KARAÇAY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "SAMANDAĞ",
        "STREET": "KOYUNOĞLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "SAMANDAĞ",
        "STREET": "KURTDERESİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "SAMANDAĞ",
        "STREET": "KUŞALANI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "SAMANDAĞ",
        "STREET": "MAĞARACIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "SAMANDAĞ",
        "STREET": "MEYDAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "SAMANDAĞ",
        "STREET": "MIZRAKLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "SAMANDAĞ",
        "STREET": "SELDİREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "SAMANDAĞ",
        "STREET": "SUTAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "SAMANDAĞ",
        "STREET": "TEKEBAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "SAMANDAĞ",
        "STREET": "TOMRUKSUYU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "SAMANDAĞ",
        "STREET": "UZUNBAĞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "SAMANDAĞ",
        "STREET": "VAKIFKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "SAMANDAĞ",
        "STREET": "YAYLICA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "SAMANDAĞ",
        "STREET": "YENİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "SAMANDAĞ",
        "STREET": "YENİKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "SAMANDAĞ",
        "STREET": "YEŞİLADA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "SAMANDAĞ",
        "STREET": "YEŞİLKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "SAMANDAĞ",
        "STREET": "YEŞİLYAZI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "SAMANDAĞ",
        "STREET": "YOĞUNOLUK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "SAMANDAĞ",
        "STREET": "ÇAMLIYAYLA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "SAMANDAĞ",
        "STREET": "ÇANAKOLUK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "SAMANDAĞ",
        "STREET": "ÇUBUKLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "SAMANDAĞ",
        "STREET": "ÇÖĞÜRLÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "SAMANDAĞ",
        "STREET": "ÇİĞDEDE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "YAYLADAĞI",
        "STREET": "ARSLANYAZI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "YAYLADAĞI",
        "STREET": "AYDINBAHÇE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "YAYLADAĞI",
        "STREET": "AYIŞIĞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "YAYLADAĞI",
        "STREET": "AŞAĞIPULLUYAZI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "YAYLADAĞI",
        "STREET": "BEZGE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "YAYLADAĞI",
        "STREET": "BOZLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "YAYLADAĞI",
        "STREET": "DENİZGÖREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "YAYLADAĞI",
        "STREET": "DUSDURU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "YAYLADAĞI",
        "STREET": "EĞERCİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "YAYLADAĞI",
        "STREET": "GÖRENTAŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "YAYLADAĞI",
        "STREET": "GÖZLÜCE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "YAYLADAĞI",
        "STREET": "GÜRIŞIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "YAYLADAĞI",
        "STREET": "GÜVEÇÇİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "YAYLADAĞI",
        "STREET": "GÜZELYURT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "YAYLADAĞI",
        "STREET": "HİSARCIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "YAYLADAĞI",
        "STREET": "KARACURUN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "YAYLADAĞI",
        "STREET": "KARAKÖSE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "YAYLADAĞI",
        "STREET": "KIZILÇAT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "YAYLADAĞI",
        "STREET": "KIŞLAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "YAYLADAĞI",
        "STREET": "KULAÇ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "YAYLADAĞI",
        "STREET": "KURTULUŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "YAYLADAĞI",
        "STREET": "KÖSRELİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "YAYLADAĞI",
        "STREET": "LEYLEKLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "YAYLADAĞI",
        "STREET": "OLGUNLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "YAYLADAĞI",
        "STREET": "SEBENOBA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "YAYLADAĞI",
        "STREET": "SUNGUR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "YAYLADAĞI",
        "STREET": "SÜRÜTME MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "YAYLADAĞI",
        "STREET": "TURFANDA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "YAYLADAĞI",
        "STREET": "TUTLUBAHÇE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "YAYLADAĞI",
        "STREET": "ULUYOL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "YAYLADAĞI",
        "STREET": "YALAZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "YAYLADAĞI",
        "STREET": "YAYIKDAMLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "YAYLADAĞI",
        "STREET": "YENİCE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "YAYLADAĞI",
        "STREET": "YEŞİLTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "YAYLADAĞI",
        "STREET": "YONCAKAYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "YAYLADAĞI",
        "STREET": "YUKARIOKÇULAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "YAYLADAĞI",
        "STREET": "YUKARIPULLUYAZI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "YAYLADAĞI",
        "STREET": "ÇABALA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "YAYLADAĞI",
        "STREET": "ÇAKI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "YAYLADAĞI",
        "STREET": "ÇAKSINA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "YAYLADAĞI",
        "STREET": "ÇAMALTI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "YAYLADAĞI",
        "STREET": "ÇANDIR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "YAYLADAĞI",
        "STREET": "ÇATBAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "YAYLADAĞI",
        "STREET": "ÇAYIR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "YAYLADAĞI",
        "STREET": "ÜÇIRMAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "YAYLADAĞI",
        "STREET": "ŞAKŞAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "YAYLADAĞI",
        "STREET": "ŞENKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "İSKENDERUN",
        "STREET": "AKARCA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "İSKENDERUN",
        "STREET": "AKÇAY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "İSKENDERUN",
        "STREET": "AZGANLIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "İSKENDERUN",
        "STREET": "AŞKARBEYLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "İSKENDERUN",
        "STREET": "BARBAROS MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "İSKENDERUN",
        "STREET": "BARIŞTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "İSKENDERUN",
        "STREET": "BEKBELE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "İSKENDERUN",
        "STREET": "BULUTTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "İSKENDERUN",
        "STREET": "BÜYÜKDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "İSKENDERUN",
        "STREET": "BİTİŞİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "İSKENDERUN",
        "STREET": "CEBİKE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "İSKENDERUN",
        "STREET": "CIRTIMAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "İSKENDERUN",
        "STREET": "CUMHURİYET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "İSKENDERUN",
        "STREET": "DENİZCİLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "İSKENDERUN",
        "STREET": "DUMLUPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "İSKENDERUN",
        "STREET": "ESENTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "İSKENDERUN",
        "STREET": "FATİH SULTAN MEHMET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "İSKENDERUN",
        "STREET": "GÜLTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "İSKENDERUN",
        "STREET": "GÜRSEL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "İSKENDERUN",
        "STREET": "GÜZELÇAY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "İSKENDERUN",
        "STREET": "HÜRRİYET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "İSKENDERUN",
        "STREET": "KALEDİBİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "İSKENDERUN",
        "STREET": "KARAYILAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "İSKENDERUN",
        "STREET": "KAVAKLIOLUK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "İSKENDERUN",
        "STREET": "KOCATEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "İSKENDERUN",
        "STREET": "KURTULUŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "İSKENDERUN",
        "STREET": "MEYDAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "İSKENDERUN",
        "STREET": "MODERNEVLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "İSKENDERUN",
        "STREET": "MURADİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "İSKENDERUN",
        "STREET": "MUSTAFA KEMAL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "İSKENDERUN",
        "STREET": "NUMUNE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "İSKENDERUN",
        "STREET": "ORHANGAZİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "İSKENDERUN",
        "STREET": "PINARBAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "İSKENDERUN",
        "STREET": "PİRİREİS MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "İSKENDERUN",
        "STREET": "SAKARYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "İSKENDERUN",
        "STREET": "SARISEKİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "İSKENDERUN",
        "STREET": "SAVAŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "İSKENDERUN",
        "STREET": "SUÇIKAĞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "İSKENDERUN",
        "STREET": "SÜLEYMANİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "İSKENDERUN",
        "STREET": "YENİŞEHİR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "İSKENDERUN",
        "STREET": "YILDIRIMTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "İSKENDERUN",
        "STREET": "YUNUS EMRE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "İSKENDERUN",
        "STREET": "ÇAY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "İSKENDERUN",
        "STREET": "ÇINARLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "HATAY",
        "DISTRICT": "İSKENDERUN",
        "STREET": "İSMET İNÖNÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "AKSU",
        "STREET": "AKÇAŞAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "AKSU",
        "STREET": "BAHÇELİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "AKSU",
        "STREET": "ELDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "AKSU",
        "STREET": "ELECİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "AKSU",
        "STREET": "HÜRRİYET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "AKSU",
        "STREET": "KARACAHİSAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "AKSU",
        "STREET": "KARAĞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "AKSU",
        "STREET": "KATİP KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "AKSU",
        "STREET": "KOÇULAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "AKSU",
        "STREET": "KURTULUŞ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "AKSU",
        "STREET": "KÖSRE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "AKSU",
        "STREET": "ORTA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "AKSU",
        "STREET": "PAZARCIK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "AKSU",
        "STREET": "SOFULAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "AKSU",
        "STREET": "TEPE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "AKSU",
        "STREET": "TERZİLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "AKSU",
        "STREET": "YAKA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "AKSU",
        "STREET": "YAKAAFŞAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "AKSU",
        "STREET": "YILANLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "AKSU",
        "STREET": "YUKARIYAYLABEL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "AKSU",
        "STREET": "ÇAY MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "AKSU",
        "STREET": "İSTİKLAL MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "ATABEY",
        "STREET": "ALTUNBA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "ATABEY",
        "STREET": "BAYAT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "ATABEY",
        "STREET": "GEZİRLER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "ATABEY",
        "STREET": "HARMANÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "ATABEY",
        "STREET": "KAPICAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "ATABEY",
        "STREET": "MÜFTÜ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "ATABEY",
        "STREET": "ONAÇ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "ATABEY",
        "STREET": "PAZAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "ATABEY",
        "STREET": "PEMBELİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "ATABEY",
        "STREET": "SÖKMEN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "ATABEY",
        "STREET": "SİNAN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "ATABEY",
        "STREET": "YENİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "ATABEY",
        "STREET": "ÇEŞME MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "ATABEY",
        "STREET": "İSLAMKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "EĞİRDİR",
        "STREET": "AKDOĞAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "EĞİRDİR",
        "STREET": "AKPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "EĞİRDİR",
        "STREET": "ALTINKUM MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "EĞİRDİR",
        "STREET": "AĞA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "EĞİRDİR",
        "STREET": "AĞILKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "EĞİRDİR",
        "STREET": "AŞAĞI GÖKDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "EĞİRDİR",
        "STREET": "BADEMLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "EĞİRDİR",
        "STREET": "BAKLAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "EĞİRDİR",
        "STREET": "BALKIRI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "EĞİRDİR",
        "STREET": "BARLA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "EĞİRDİR",
        "STREET": "BAĞBUYAN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "EĞİRDİR",
        "STREET": "BAĞICIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "EĞİRDİR",
        "STREET": "BAĞILLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "EĞİRDİR",
        "STREET": "BAĞLAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "EĞİRDİR",
        "STREET": "BAĞÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "EĞİRDİR",
        "STREET": "BEYDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "EĞİRDİR",
        "STREET": "CAMİİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "EĞİRDİR",
        "STREET": "DEMİRKAPI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "EĞİRDİR",
        "STREET": "EYÜPLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "EĞİRDİR",
        "STREET": "GÖKTAŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "EĞİRDİR",
        "STREET": "GÖKÇEHÖYÜK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "EĞİRDİR",
        "STREET": "HACIŞEYH MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "EĞİRDİR",
        "STREET": "HAMAM MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "EĞİRDİR",
        "STREET": "HAVUTLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "EĞİRDİR",
        "STREET": "HAYMANA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "EĞİRDİR",
        "STREET": "KALE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "EĞİRDİR",
        "STREET": "KATİP MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "EĞİRDİR",
        "STREET": "KIRINTI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "EĞİRDİR",
        "STREET": "KIZILÇUBUK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "EĞİRDİR",
        "STREET": "KONYA BUCAĞI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "EĞİRDİR",
        "STREET": "KUBBELİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "EĞİRDİR",
        "STREET": "MAHMATLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "EĞİRDİR",
        "STREET": "MENDERES MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "EĞİRDİR",
        "STREET": "PAZARKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "EĞİRDİR",
        "STREET": "PINAR PAZARI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "EĞİRDİR",
        "STREET": "POYRAZ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "EĞİRDİR",
        "STREET": "SANAYİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "EĞİRDİR",
        "STREET": "SARIİDRİS-AĞA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "EĞİRDİR",
        "STREET": "SARIİDRİS-DERE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "EĞİRDİR",
        "STREET": "SARIİDRİS-HÜRRİYET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "EĞİRDİR",
        "STREET": "SARIİDRİS-İSTİKLAL MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "EĞİRDİR",
        "STREET": "SEKİBAĞ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "EĞİRDİR",
        "STREET": "SERPİL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "EĞİRDİR",
        "STREET": "SEVİNÇBEY KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "EĞİRDİR",
        "STREET": "SEYDİM MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "EĞİRDİR",
        "STREET": "SORKUNCAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "EĞİRDİR",
        "STREET": "SİPAHİLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "EĞİRDİR",
        "STREET": "TEPELİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "EĞİRDİR",
        "STREET": "YAZLA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "EĞİRDİR",
        "STREET": "YENİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "EĞİRDİR",
        "STREET": "YEŞİLADA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "EĞİRDİR",
        "STREET": "YILGINCAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "EĞİRDİR",
        "STREET": "YUKARI GÖKDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "EĞİRDİR",
        "STREET": "YUVALI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "EĞİRDİR",
        "STREET": "ÇAY KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "EĞİRDİR",
        "STREET": "İMARET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "EĞİRDİR",
        "STREET": "İSTASYON MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "GELENDOST",
        "STREET": "17 EYLÜL MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "GELENDOST",
        "STREET": "AFŞAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "GELENDOST",
        "STREET": "AKDAĞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "GELENDOST",
        "STREET": "AŞAĞI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "GELENDOST",
        "STREET": "BAHÇELİEVLER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "GELENDOST",
        "STREET": "BALCI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "GELENDOST",
        "STREET": "BAĞILLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "GELENDOST",
        "STREET": "ESİNYURT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "GELENDOST",
        "STREET": "HACILAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "GELENDOST",
        "STREET": "HAYDARPAŞA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "GELENDOST",
        "STREET": "KEÇİLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "GELENDOST",
        "STREET": "KIREVLER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "GELENDOST",
        "STREET": "KÖKE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "GELENDOST",
        "STREET": "MADENLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "GELENDOST",
        "STREET": "MERKEZ-HÜSEYİN AVNİ PAŞA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "GELENDOST",
        "STREET": "MUHARREM MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "GELENDOST",
        "STREET": "ORTA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "GELENDOST",
        "STREET": "SELÇUK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "GELENDOST",
        "STREET": "YAKA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "GELENDOST",
        "STREET": "YENİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "GELENDOST",
        "STREET": "YENİCE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "GELENDOST",
        "STREET": "YEŞİLKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "GELENDOST",
        "STREET": "ÇALTI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "GÖNEN",
        "STREET": "ALACA MESCİT MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "GÖNEN",
        "STREET": "CAMİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "GÖNEN",
        "STREET": "GÖLBAŞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "GÖNEN",
        "STREET": "GÖLETEVLERİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "GÖNEN",
        "STREET": "GÜNEYKENT-BALADIZ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "GÖNEN",
        "STREET": "GÜNEYKENT-ORTA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "GÖNEN",
        "STREET": "GÜNEYKENT-TEKKE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "GÖNEN",
        "STREET": "GÜNEYKENT-YENİCE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "GÖNEN",
        "STREET": "KASAP MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "GÖNEN",
        "STREET": "KIZILCIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "GÖNEN",
        "STREET": "KOÇTEPE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "GÖNEN",
        "STREET": "MUSALLA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "GÖNEN",
        "STREET": "PAZAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "GÖNEN",
        "STREET": "SENİRCE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "GÖNEN",
        "STREET": "İĞDECİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "MERKEZ",
        "STREET": "AKKENT MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "MERKEZ",
        "STREET": "ALİKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "MERKEZ",
        "STREET": "ANADOLU MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "MERKEZ",
        "STREET": "AYAZMANA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "MERKEZ",
        "STREET": "BAHÇELİEVLER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "MERKEZ",
        "STREET": "BATIKENT MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "MERKEZ",
        "STREET": "BAĞLAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "MERKEZ",
        "STREET": "BOZANÖNÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "MERKEZ",
        "STREET": "BÜYÜKGÖKÇELİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "MERKEZ",
        "STREET": "BÜYÜKHACILAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "MERKEZ",
        "STREET": "BİNBİREVLER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "MERKEZ",
        "STREET": "DARIDERESİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "MERKEZ",
        "STREET": "DARIÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "MERKEZ",
        "STREET": "DAVRAZ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "MERKEZ",
        "STREET": "DERE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "MERKEZ",
        "STREET": "DEREGÜMÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "MERKEZ",
        "STREET": "DOĞANCI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "MERKEZ",
        "STREET": "DİREKLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "MERKEZ",
        "STREET": "EMRE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "MERKEZ",
        "STREET": "FATİH MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "MERKEZ",
        "STREET": "GAZİ KEMAL MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "MERKEZ",
        "STREET": "GELİNCİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "MERKEZ",
        "STREET": "GÜLCÜ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "MERKEZ",
        "STREET": "GÜLEVLER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "MERKEZ",
        "STREET": "GÜLİSTAN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "MERKEZ",
        "STREET": "GÜNEYCE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "MERKEZ",
        "STREET": "HALIKENT MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "MERKEZ",
        "STREET": "HALİFE SULTAN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "MERKEZ",
        "STREET": "HIZIRBEY MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "MERKEZ",
        "STREET": "HİSAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "MERKEZ",
        "STREET": "ISPARTA E TİPİ KAPALI CEZA İNFAZ KURUMU",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "MERKEZ",
        "STREET": "IŞIKKENT MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "MERKEZ",
        "STREET": "KADILAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "MERKEZ",
        "STREET": "KARAAĞAÇ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "MERKEZ",
        "STREET": "KAYIKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "MERKEZ",
        "STREET": "KEPECİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "MERKEZ",
        "STREET": "KEÇECİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "MERKEZ",
        "STREET": "KIŞLAKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "MERKEZ",
        "STREET": "KULEÖNÜ-KEMAL MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "MERKEZ",
        "STREET": "KULEÖNÜ-OKUL MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "MERKEZ",
        "STREET": "KULEÖNÜ-YENİCAMİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "MERKEZ",
        "STREET": "KURTULUŞ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "MERKEZ",
        "STREET": "KUTLUBEY MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "MERKEZ",
        "STREET": "KÜÇÜKGÖKÇELİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "MERKEZ",
        "STREET": "KÜÇÜKHACILAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "MERKEZ",
        "STREET": "KÜÇÜKKIŞLA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "MERKEZ",
        "STREET": "MEHMET TÖNGE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "MERKEZ",
        "STREET": "MODERN EVLER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "MERKEZ",
        "STREET": "MUZAFFER TÜRKEŞ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "MERKEZ",
        "STREET": "PİRİMEHMET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "MERKEZ",
        "STREET": "SANAYİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "MERKEZ",
        "STREET": "SAV-AŞAĞI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "MERKEZ",
        "STREET": "SAV-FATİH MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "MERKEZ",
        "STREET": "SAV-YUKARI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "MERKEZ",
        "STREET": "SERMET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "MERKEZ",
        "STREET": "SÜLÜBEY MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "MERKEZ",
        "STREET": "SİDRE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "MERKEZ",
        "STREET": "TURAN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "MERKEZ",
        "STREET": "VATAN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "MERKEZ",
        "STREET": "YAKAÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "MERKEZ",
        "STREET": "YAYLA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "MERKEZ",
        "STREET": "YAZISÖĞÜT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "MERKEZ",
        "STREET": "YEDİŞEHİTLER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "MERKEZ",
        "STREET": "YENİCE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "MERKEZ",
        "STREET": "ZAFER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇELEBİLER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇOBANİSA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇUKURKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇÜNÜR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "MERKEZ",
        "STREET": "İSKENDER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "MERKEZ",
        "STREET": "İSTİKLAL MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "KEÇİBORLU",
        "STREET": "ARDIÇLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "KEÇİBORLU",
        "STREET": "AYDOĞMUŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "KEÇİBORLU",
        "STREET": "AŞAĞI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "KEÇİBORLU",
        "STREET": "CUMHURİYET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "KEÇİBORLU",
        "STREET": "GÜLKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "KEÇİBORLU",
        "STREET": "HACI KÖYÜ  MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "KEÇİBORLU",
        "STREET": "HASANBEY MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "KEÇİBORLU",
        "STREET": "KAPLANLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "KEÇİBORLU",
        "STREET": "KAVAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "KEÇİBORLU",
        "STREET": "KAYA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "KEÇİBORLU",
        "STREET": "KILIÇ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "KEÇİBORLU",
        "STREET": "KOZLUCA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "KEÇİBORLU",
        "STREET": "KUYUCAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "KEÇİBORLU",
        "STREET": "KÜKÜRT MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "KEÇİBORLU",
        "STREET": "ORTA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "KEÇİBORLU",
        "STREET": "SARACIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "KEÇİBORLU",
        "STREET": "SENİR-CUMHURİYET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "KEÇİBORLU",
        "STREET": "SENİR-KUŞÇULAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "KEÇİBORLU",
        "STREET": "SENİR-ORTA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "KEÇİBORLU",
        "STREET": "SENİR-TEPECİK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "KEÇİBORLU",
        "STREET": "SENİR-YENİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "KEÇİBORLU",
        "STREET": "TOKİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "KEÇİBORLU",
        "STREET": "TUZLA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "KEÇİBORLU",
        "STREET": "YENİCE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "KEÇİBORLU",
        "STREET": "YENİTEPE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "KEÇİBORLU",
        "STREET": "YEŞİLYURT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "KEÇİBORLU",
        "STREET": "YUKARI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "KEÇİBORLU",
        "STREET": "ÇUKURÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "KEÇİBORLU",
        "STREET": "ÖZBAHÇE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "KEÇİBORLU",
        "STREET": "İNCESU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "SENİRKENT",
        "STREET": "16 HAZİRAN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "SENİRKENT",
        "STREET": "AKKEÇİLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "SENİRKENT",
        "STREET": "BAŞKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "SENİRKENT",
        "STREET": "BÜYÜKKABACA-CAMİİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "SENİRKENT",
        "STREET": "BÜYÜKKABACA-DERE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "SENİRKENT",
        "STREET": "BÜYÜKKABACA-KARŞI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "SENİRKENT",
        "STREET": "BÜYÜKKABACA-ORTA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "SENİRKENT",
        "STREET": "BÜYÜKKABACA-SULTAN SÜLEYMAN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "SENİRKENT",
        "STREET": "BÜYÜKKABACA-ZAFER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "SENİRKENT",
        "STREET": "CUMHURİYET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "SENİRKENT",
        "STREET": "GENÇALİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "SENİRKENT",
        "STREET": "HAMİDİYE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "SENİRKENT",
        "STREET": "KARİP KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "SENİRKENT",
        "STREET": "KIBRIS MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "SENİRKENT",
        "STREET": "ORTAYAZI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "SENİRKENT",
        "STREET": "SÜLEYMAN DEMİREL MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "SENİRKENT",
        "STREET": "TAŞMESCİT MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "SENİRKENT",
        "STREET": "TURGUTLAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "SENİRKENT",
        "STREET": "ULUĞBEY KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "SENİRKENT",
        "STREET": "YASSIÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "SENİRKENT",
        "STREET": "İSTİKLAL MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "SENİRKENT",
        "STREET": "ŞEHLER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "SÜTÇÜLER",
        "STREET": "AYVALIPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "SÜTÇÜLER",
        "STREET": "AŞAĞIYAYLABEL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "SÜTÇÜLER",
        "STREET": "BAŞKOZ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "SÜTÇÜLER",
        "STREET": "BEKİRAĞALAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "SÜTÇÜLER",
        "STREET": "BELEN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "SÜTÇÜLER",
        "STREET": "BELENCE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "SÜTÇÜLER",
        "STREET": "BEYDİLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "SÜTÇÜLER",
        "STREET": "BOĞAZKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "SÜTÇÜLER",
        "STREET": "BUCAKDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "SÜTÇÜLER",
        "STREET": "BİRLİK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "SÜTÇÜLER",
        "STREET": "CAMİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "SÜTÇÜLER",
        "STREET": "DARIBÜKÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "SÜTÇÜLER",
        "STREET": "ERENLER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "SÜTÇÜLER",
        "STREET": "GÜLDALLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "SÜTÇÜLER",
        "STREET": "GÜMÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "SÜTÇÜLER",
        "STREET": "HACIAHMETLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "SÜTÇÜLER",
        "STREET": "HACIALİLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "SÜTÇÜLER",
        "STREET": "KARADİKEN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "SÜTÇÜLER",
        "STREET": "KASIMLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "SÜTÇÜLER",
        "STREET": "KESME KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "SÜTÇÜLER",
        "STREET": "KUZCA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "SÜTÇÜLER",
        "STREET": "MELİKLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "SÜTÇÜLER",
        "STREET": "MÜEZZİNLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "SÜTÇÜLER",
        "STREET": "PINARKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "SÜTÇÜLER",
        "STREET": "SARAY KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "SÜTÇÜLER",
        "STREET": "SARIMEHMETLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "SÜTÇÜLER",
        "STREET": "SAĞRAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "SÜTÇÜLER",
        "STREET": "YENİKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "SÜTÇÜLER",
        "STREET": "YEŞİLDERE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "SÜTÇÜLER",
        "STREET": "YEŞİLYURT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "SÜTÇÜLER",
        "STREET": "YUKARI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "SÜTÇÜLER",
        "STREET": "ÇANDIR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "SÜTÇÜLER",
        "STREET": "ÇOBANİSA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "SÜTÇÜLER",
        "STREET": "ÇUKURCA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "SÜTÇÜLER",
        "STREET": "İBİŞLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "SÜTÇÜLER",
        "STREET": "İNCEDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "SÜTÇÜLER",
        "STREET": "ŞEHLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "ULUBORLU",
        "STREET": "DERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "ULUBORLU",
        "STREET": "GÜREŞYERİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "ULUBORLU",
        "STREET": "HAMİDELİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "ULUBORLU",
        "STREET": "KABAAĞAÇ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "ULUBORLU",
        "STREET": "KÖSEHASAN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "ULUBORLU",
        "STREET": "KÜÇÜKKABACA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "ULUBORLU",
        "STREET": "NAMAZGAH MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "ULUBORLU",
        "STREET": "PAZAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "ULUBORLU",
        "STREET": "SELÇUKLU MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "ULUBORLU",
        "STREET": "SERENLER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "ULUBORLU",
        "STREET": "TAŞKÖPRÜ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "ULUBORLU",
        "STREET": "TOPRAKLIK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "ULUBORLU",
        "STREET": "ZİNCİRLİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "ULUBORLU",
        "STREET": "İLEYDAĞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "ULUBORLU",
        "STREET": "İNHİSAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "YALVAÇ",
        "STREET": "ABACILAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "YALVAÇ",
        "STREET": "AKKÖPRÜ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "YALVAÇ",
        "STREET": "AKÇAŞAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "YALVAÇ",
        "STREET": "ALTIKAPI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "YALVAÇ",
        "STREET": "AYVALI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "YALVAÇ",
        "STREET": "AŞAĞIKAŞIKARA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "YALVAÇ",
        "STREET": "AŞAĞITIRTAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "YALVAÇ",
        "STREET": "BAHTİYAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "YALVAÇ",
        "STREET": "BAHÇELİEVLER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "YALVAÇ",
        "STREET": "BAĞKONAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "YALVAÇ",
        "STREET": "BAĞLAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "YALVAÇ",
        "STREET": "BAĞLARBAŞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "YALVAÇ",
        "STREET": "CELEPTAŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "YALVAÇ",
        "STREET": "CUMHURİYET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "YALVAÇ",
        "STREET": "DEBBAĞLAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "YALVAÇ",
        "STREET": "DEDEÇAM KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "YALVAÇ",
        "STREET": "ESKİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "YALVAÇ",
        "STREET": "EYÜPLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "YALVAÇ",
        "STREET": "EĞİRLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "YALVAÇ",
        "STREET": "FATİH MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "YALVAÇ",
        "STREET": "GÖKÇEALİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "YALVAÇ",
        "STREET": "GÖRGÜ BAYRAM MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "YALVAÇ",
        "STREET": "GÖRGÜ CAMİİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "YALVAÇ",
        "STREET": "GÖRGÜORTA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "YALVAÇ",
        "STREET": "HÜYÜKLÜ-ESKİGEÇİT MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "YALVAÇ",
        "STREET": "HÜYÜKLÜ-MESCİT MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "YALVAÇ",
        "STREET": "HÜYÜKLÜ-NALBANT MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "YALVAÇ",
        "STREET": "HÜYÜKLÜ-YANARIK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "YALVAÇ",
        "STREET": "HÜYÜKLÜ-YENİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "YALVAÇ",
        "STREET": "HİSARARDI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "YALVAÇ",
        "STREET": "KAŞAŞAĞI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "YALVAÇ",
        "STREET": "KAŞCAMİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "YALVAÇ",
        "STREET": "KAŞHACIBEY MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "YALVAÇ",
        "STREET": "KAŞYUKARI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "YALVAÇ",
        "STREET": "KIRKBAŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "YALVAÇ",
        "STREET": "KIZILCA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "YALVAÇ",
        "STREET": "KORUYAKA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "YALVAÇ",
        "STREET": "KOZLUÇAY KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "YALVAÇ",
        "STREET": "KUMDANLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "YALVAÇ",
        "STREET": "KURUSARI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "YALVAÇ",
        "STREET": "KUYUCAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "YALVAÇ",
        "STREET": "KUŞKU MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "YALVAÇ",
        "STREET": "KÖRKÜLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "YALVAÇ",
        "STREET": "LEBLEBİCİLER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "YALVAÇ",
        "STREET": "MISIRLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "YALVAÇ",
        "STREET": "MÜDERRİS MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "YALVAÇ",
        "STREET": "PAZAR AŞAĞI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "YALVAÇ",
        "STREET": "PAZAR YUKARI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "YALVAÇ",
        "STREET": "SALUR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "YALVAÇ",
        "STREET": "SANAYİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "YALVAÇ",
        "STREET": "SARAY MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "YALVAÇ",
        "STREET": "SAĞIRKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "YALVAÇ",
        "STREET": "SOFULAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "YALVAÇ",
        "STREET": "SÜCÜLLÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "YALVAÇ",
        "STREET": "TAŞEVİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "YALVAÇ",
        "STREET": "TERZİLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "YALVAÇ",
        "STREET": "TOKMACIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "YALVAÇ",
        "STREET": "YALVAÇ KAPALI CEZA EVİ",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "YALVAÇ",
        "STREET": "YARIKKAYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "YALVAÇ",
        "STREET": "YAĞCILAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "YALVAÇ",
        "STREET": "YUKARIKAŞIKARA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "YALVAÇ",
        "STREET": "YUKARITIRTAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "YALVAÇ",
        "STREET": "ZAFER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "YALVAÇ",
        "STREET": "ÇAKIRÇAL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "YALVAÇ",
        "STREET": "ÇAMHARMAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "YALVAÇ",
        "STREET": "ÇARŞI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "YALVAÇ",
        "STREET": "ÇETİNCE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "YALVAÇ",
        "STREET": "ÖZBAYAT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "YALVAÇ",
        "STREET": "ÖZGÜNEY KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "YALVAÇ",
        "STREET": "İLEĞİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "YENİŞARBADEMLİ",
        "STREET": "GÖLKONAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "YENİŞARBADEMLİ",
        "STREET": "GÜNDOĞDU MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "YENİŞARBADEMLİ",
        "STREET": "PINARBAŞI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "YENİŞARBADEMLİ",
        "STREET": "YENİCAMİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "YENİŞARBADEMLİ",
        "STREET": "YENİCE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "ŞARKİKARAAĞAÇ",
        "STREET": "ALCIKLAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "ŞARKİKARAAĞAÇ",
        "STREET": "ARAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "ŞARKİKARAAĞAÇ",
        "STREET": "ARMUTLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "ŞARKİKARAAĞAÇ",
        "STREET": "ASILKALE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "ŞARKİKARAAĞAÇ",
        "STREET": "ASLANDOĞMUŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "ŞARKİKARAAĞAÇ",
        "STREET": "AŞAĞIDİNEK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "ŞARKİKARAAĞAÇ",
        "STREET": "AŞAĞIKALE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "ŞARKİKARAAĞAÇ",
        "STREET": "BAŞDEĞİRMEN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "ŞARKİKARAAĞAÇ",
        "STREET": "BELCEĞİZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "ŞARKİKARAAĞAÇ",
        "STREET": "BEYKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "ŞARKİKARAAĞAÇ",
        "STREET": "CAMİKEBİR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "ŞARKİKARAAĞAÇ",
        "STREET": "FAKILAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "ŞARKİKARAAĞAÇ",
        "STREET": "FATİH MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "ŞARKİKARAAĞAÇ",
        "STREET": "FELE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "ŞARKİKARAAĞAÇ",
        "STREET": "GEDİKLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "ŞARKİKARAAĞAÇ",
        "STREET": "GÖKSÖĞÜT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "ŞARKİKARAAĞAÇ",
        "STREET": "KARAYAKA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "ŞARKİKARAAĞAÇ",
        "STREET": "KERKÜK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "ŞARKİKARAAĞAÇ",
        "STREET": "KIYAKDEDE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "ŞARKİKARAAĞAÇ",
        "STREET": "KÖPRÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "ŞARKİKARAAĞAÇ",
        "STREET": "MURATBAĞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "ŞARKİKARAAĞAÇ",
        "STREET": "ORTA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "ŞARKİKARAAĞAÇ",
        "STREET": "SALUR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "ŞARKİKARAAĞAÇ",
        "STREET": "SARIKAYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "ŞARKİKARAAĞAÇ",
        "STREET": "ULVİKALE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "ŞARKİKARAAĞAÇ",
        "STREET": "YAKAEMİR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "ŞARKİKARAAĞAÇ",
        "STREET": "YENİCEKALE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "ŞARKİKARAAĞAÇ",
        "STREET": "YENİKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "ŞARKİKARAAĞAÇ",
        "STREET": "YUKARIDİNEK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "ŞARKİKARAAĞAÇ",
        "STREET": "ÇALTI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "ŞARKİKARAAĞAÇ",
        "STREET": "ÇARIKSARAYLAR-BÖLÜKLER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "ŞARKİKARAAĞAÇ",
        "STREET": "ÇARIKSARAYLAR-CUMHURİYET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "ŞARKİKARAAĞAÇ",
        "STREET": "ÇARIKSARAYLAR-SUVAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "ŞARKİKARAAĞAÇ",
        "STREET": "ÇARIKSARAYLAR-ULU MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "ŞARKİKARAAĞAÇ",
        "STREET": "ÇARIKSARAYLAR-ÇUKUR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "ŞARKİKARAAĞAÇ",
        "STREET": "ÇAVUNDUR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "ŞARKİKARAAĞAÇ",
        "STREET": "ÇELTEK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "ŞARKİKARAAĞAÇ",
        "STREET": "ÇİÇEKPINAR-AŞAĞI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "ŞARKİKARAAĞAÇ",
        "STREET": "ÇİÇEKPINAR-ORTA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "ŞARKİKARAAĞAÇ",
        "STREET": "ÇİÇEKPINAR-ÇÖRDÜK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "ŞARKİKARAAĞAÇ",
        "STREET": "ÖRDEKCİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ISPARTA",
        "DISTRICT": "ŞARKİKARAAĞAÇ",
        "STREET": "ÖRENKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "IĞDIR",
        "DISTRICT": "ARALIK",
        "STREET": "ADETLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "IĞDIR",
        "DISTRICT": "ARALIK",
        "STREET": "ARAS MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "IĞDIR",
        "DISTRICT": "ARALIK",
        "STREET": "AŞAĞIARATAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "IĞDIR",
        "DISTRICT": "ARALIK",
        "STREET": "AŞAĞITOPRAKLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "IĞDIR",
        "DISTRICT": "ARALIK",
        "STREET": "AŞAĞIÇAMURLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "IĞDIR",
        "DISTRICT": "ARALIK",
        "STREET": "AŞAĞIÇİFTLİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "IĞDIR",
        "DISTRICT": "ARALIK",
        "STREET": "BABACAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "IĞDIR",
        "DISTRICT": "ARALIK",
        "STREET": "EMİNCE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "IĞDIR",
        "DISTRICT": "ARALIK",
        "STREET": "GÖDEKLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "IĞDIR",
        "DISTRICT": "ARALIK",
        "STREET": "HACIAĞA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "IĞDIR",
        "DISTRICT": "ARALIK",
        "STREET": "HASANHAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "IĞDIR",
        "DISTRICT": "ARALIK",
        "STREET": "KARAHACILI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "IĞDIR",
        "DISTRICT": "ARALIK",
        "STREET": "KARASU MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "IĞDIR",
        "DISTRICT": "ARALIK",
        "STREET": "KARŞIYAKA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "IĞDIR",
        "DISTRICT": "ARALIK",
        "STREET": "KIRÇİÇEĞİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "IĞDIR",
        "DISTRICT": "ARALIK",
        "STREET": "KOLİKENT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "IĞDIR",
        "DISTRICT": "ARALIK",
        "STREET": "KÖPRÜLER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "IĞDIR",
        "DISTRICT": "ARALIK",
        "STREET": "ORTAKÖY MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "IĞDIR",
        "DISTRICT": "ARALIK",
        "STREET": "RAMAZANKENT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "IĞDIR",
        "DISTRICT": "ARALIK",
        "STREET": "SARAÇLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "IĞDIR",
        "DISTRICT": "ARALIK",
        "STREET": "TAZEKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "IĞDIR",
        "DISTRICT": "ARALIK",
        "STREET": "YENİDOĞAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "IĞDIR",
        "DISTRICT": "ARALIK",
        "STREET": "YUKARIARATAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "IĞDIR",
        "DISTRICT": "ARALIK",
        "STREET": "YUKARITOPRAKLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "IĞDIR",
        "DISTRICT": "ARALIK",
        "STREET": "YUKARIÇAMURLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "IĞDIR",
        "DISTRICT": "ARALIK",
        "STREET": "YUKARIÇİFTLİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "IĞDIR",
        "DISTRICT": "MERKEZ",
        "STREET": "14 KASIM MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "IĞDIR",
        "DISTRICT": "MERKEZ",
        "STREET": "AKYUMAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "IĞDIR",
        "DISTRICT": "MERKEZ",
        "STREET": "ALİ KAMERLİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "IĞDIR",
        "DISTRICT": "MERKEZ",
        "STREET": "ALİBEYKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "IĞDIR",
        "DISTRICT": "MERKEZ",
        "STREET": "ASMA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "IĞDIR",
        "DISTRICT": "MERKEZ",
        "STREET": "ATATÜRK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "IĞDIR",
        "DISTRICT": "MERKEZ",
        "STREET": "AĞAVER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "IĞDIR",
        "DISTRICT": "MERKEZ",
        "STREET": "AŞAĞIERHACI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "IĞDIR",
        "DISTRICT": "MERKEZ",
        "STREET": "AŞAĞIÇARIKÇI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "IĞDIR",
        "DISTRICT": "MERKEZ",
        "STREET": "BAYRAKTUTAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "IĞDIR",
        "DISTRICT": "MERKEZ",
        "STREET": "BAĞLAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "IĞDIR",
        "DISTRICT": "MERKEZ",
        "STREET": "BENDEMURAT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "IĞDIR",
        "DISTRICT": "MERKEZ",
        "STREET": "CUMHURİYET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "IĞDIR",
        "DISTRICT": "MERKEZ",
        "STREET": "ELMAGÖL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "IĞDIR",
        "DISTRICT": "MERKEZ",
        "STREET": "EMEK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "IĞDIR",
        "DISTRICT": "MERKEZ",
        "STREET": "ENGİNALAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "IĞDIR",
        "DISTRICT": "MERKEZ",
        "STREET": "EVCİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "IĞDIR",
        "DISTRICT": "MERKEZ",
        "STREET": "GÜLPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "IĞDIR",
        "DISTRICT": "MERKEZ",
        "STREET": "GÜNGÖRMEZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "IĞDIR",
        "DISTRICT": "MERKEZ",
        "STREET": "HAKMEHMET KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "IĞDIR",
        "DISTRICT": "MERKEZ",
        "STREET": "HAKVEYİS MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "IĞDIR",
        "DISTRICT": "MERKEZ",
        "STREET": "HALFELİ-BAHÇELİEVLER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "IĞDIR",
        "DISTRICT": "MERKEZ",
        "STREET": "HALFELİ-SAİD NURSİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "IĞDIR",
        "DISTRICT": "MERKEZ",
        "STREET": "HALFELİ-TURGUT ÖZAL MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "IĞDIR",
        "DISTRICT": "MERKEZ",
        "STREET": "HALFELİ-YUNUS EMRE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "IĞDIR",
        "DISTRICT": "MERKEZ",
        "STREET": "HALFELİ-ÖZVATAN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "IĞDIR",
        "DISTRICT": "MERKEZ",
        "STREET": "HARMANDÖVEN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "IĞDIR",
        "DISTRICT": "MERKEZ",
        "STREET": "HOŞHABER-FARUKTOKA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "IĞDIR",
        "DISTRICT": "MERKEZ",
        "STREET": "HOŞHABER-YENİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "IĞDIR",
        "DISTRICT": "MERKEZ",
        "STREET": "IĞDIR KAPALI CEZAEVİ",
        "TYPE": "0"
      },
      {
        "CITY": "IĞDIR",
        "DISTRICT": "MERKEZ",
        "STREET": "KADIKIŞLAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "IĞDIR",
        "DISTRICT": "MERKEZ",
        "STREET": "KARAAĞAÇ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "IĞDIR",
        "DISTRICT": "MERKEZ",
        "STREET": "KARAGÜNEY KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "IĞDIR",
        "DISTRICT": "MERKEZ",
        "STREET": "KARAKUYU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "IĞDIR",
        "DISTRICT": "MERKEZ",
        "STREET": "KASIMCAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "IĞDIR",
        "DISTRICT": "MERKEZ",
        "STREET": "KAVAKTEPE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "IĞDIR",
        "DISTRICT": "MERKEZ",
        "STREET": "KAZANCI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "IĞDIR",
        "DISTRICT": "MERKEZ",
        "STREET": "KONAKLI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "IĞDIR",
        "DISTRICT": "MERKEZ",
        "STREET": "KUZUGÜDEN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "IĞDIR",
        "DISTRICT": "MERKEZ",
        "STREET": "KÜLLÜK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "IĞDIR",
        "DISTRICT": "MERKEZ",
        "STREET": "MELEKLİ-KARABAĞ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "IĞDIR",
        "DISTRICT": "MERKEZ",
        "STREET": "MELEKLİ-MEHMET AKİF ERSOY MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "IĞDIR",
        "DISTRICT": "MERKEZ",
        "STREET": "MELEKLİ-NAMIK KEMAL MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "IĞDIR",
        "DISTRICT": "MERKEZ",
        "STREET": "MEZRAA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "IĞDIR",
        "DISTRICT": "MERKEZ",
        "STREET": "NECEFALİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "IĞDIR",
        "DISTRICT": "MERKEZ",
        "STREET": "NİŞANKAYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "IĞDIR",
        "DISTRICT": "MERKEZ",
        "STREET": "OBAKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "IĞDIR",
        "DISTRICT": "MERKEZ",
        "STREET": "PANİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "IĞDIR",
        "DISTRICT": "MERKEZ",
        "STREET": "PINARBAŞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "IĞDIR",
        "DISTRICT": "MERKEZ",
        "STREET": "PİR SULTAN ABDAL MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "IĞDIR",
        "DISTRICT": "MERKEZ",
        "STREET": "SARIÇOBAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "IĞDIR",
        "DISTRICT": "MERKEZ",
        "STREET": "SUVEREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "IĞDIR",
        "DISTRICT": "MERKEZ",
        "STREET": "SÖĞÜTLÜ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "IĞDIR",
        "DISTRICT": "MERKEZ",
        "STREET": "TACİRLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "IĞDIR",
        "DISTRICT": "MERKEZ",
        "STREET": "TAŞLICA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "IĞDIR",
        "DISTRICT": "MERKEZ",
        "STREET": "TOPÇULAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "IĞDIR",
        "DISTRICT": "MERKEZ",
        "STREET": "YAYCI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "IĞDIR",
        "DISTRICT": "MERKEZ",
        "STREET": "YENİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "IĞDIR",
        "DISTRICT": "MERKEZ",
        "STREET": "YUKARIÇARIKCI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "IĞDIR",
        "DISTRICT": "MERKEZ",
        "STREET": "YÜZBAŞILAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "IĞDIR",
        "DISTRICT": "MERKEZ",
        "STREET": "ZOR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "IĞDIR",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇAKIRTAŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "IĞDIR",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇALPALA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "IĞDIR",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇİLLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "IĞDIR",
        "DISTRICT": "MERKEZ",
        "STREET": "ÖRÜŞMÜŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "IĞDIR",
        "DISTRICT": "MERKEZ",
        "STREET": "ÖZGÜR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "IĞDIR",
        "DISTRICT": "KARAKOYUNLU",
        "STREET": "AKTAŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "IĞDIR",
        "DISTRICT": "KARAKOYUNLU",
        "STREET": "AŞAĞIALİCAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "IĞDIR",
        "DISTRICT": "KARAKOYUNLU",
        "STREET": "BAYATDOĞANŞALI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "IĞDIR",
        "DISTRICT": "KARAKOYUNLU",
        "STREET": "BEKİRHANLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "IĞDIR",
        "DISTRICT": "KARAKOYUNLU",
        "STREET": "BULAKBAŞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "IĞDIR",
        "DISTRICT": "KARAKOYUNLU",
        "STREET": "CENNETABAT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "IĞDIR",
        "DISTRICT": "KARAKOYUNLU",
        "STREET": "ERGENEKON MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "IĞDIR",
        "DISTRICT": "KARAKOYUNLU",
        "STREET": "FATİH MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "IĞDIR",
        "DISTRICT": "KARAKOYUNLU",
        "STREET": "GÖKÇELİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "IĞDIR",
        "DISTRICT": "KARAKOYUNLU",
        "STREET": "GÖLKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "IĞDIR",
        "DISTRICT": "KARAKOYUNLU",
        "STREET": "KACARDOĞANŞALI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "IĞDIR",
        "DISTRICT": "KARAKOYUNLU",
        "STREET": "KERİMBEYLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "IĞDIR",
        "DISTRICT": "KARAKOYUNLU",
        "STREET": "KOÇKIRAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "IĞDIR",
        "DISTRICT": "KARAKOYUNLU",
        "STREET": "MÜRŞİTALİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "IĞDIR",
        "DISTRICT": "KARAKOYUNLU",
        "STREET": "ORTA ALİCAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "IĞDIR",
        "DISTRICT": "KARAKOYUNLU",
        "STREET": "TAŞBURUN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "IĞDIR",
        "DISTRICT": "KARAKOYUNLU",
        "STREET": "YAZLIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "IĞDIR",
        "DISTRICT": "KARAKOYUNLU",
        "STREET": "YUKARIALİCAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "IĞDIR",
        "DISTRICT": "KARAKOYUNLU",
        "STREET": "ZÜLFİKARKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "IĞDIR",
        "DISTRICT": "KARAKOYUNLU",
        "STREET": "İSLAMKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "IĞDIR",
        "DISTRICT": "KARAKOYUNLU",
        "STREET": "ŞIRACI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "IĞDIR",
        "DISTRICT": "TUZLUCA",
        "STREET": "ABBASGÖL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "IĞDIR",
        "DISTRICT": "TUZLUCA",
        "STREET": "AKDEĞİRMEN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "IĞDIR",
        "DISTRICT": "TUZLUCA",
        "STREET": "AKDİZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "IĞDIR",
        "DISTRICT": "TUZLUCA",
        "STREET": "AKOLUK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "IĞDIR",
        "DISTRICT": "TUZLUCA",
        "STREET": "ALHANLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "IĞDIR",
        "DISTRICT": "TUZLUCA",
        "STREET": "ALİKÖSE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "IĞDIR",
        "DISTRICT": "TUZLUCA",
        "STREET": "ARSLANLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "IĞDIR",
        "DISTRICT": "TUZLUCA",
        "STREET": "AĞABEY KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "IĞDIR",
        "DISTRICT": "TUZLUCA",
        "STREET": "AŞAĞI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "IĞDIR",
        "DISTRICT": "TUZLUCA",
        "STREET": "AŞAĞI ÇIYRIKLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "IĞDIR",
        "DISTRICT": "TUZLUCA",
        "STREET": "AŞAĞIAKTAŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "IĞDIR",
        "DISTRICT": "TUZLUCA",
        "STREET": "AŞAĞICİVANLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "IĞDIR",
        "DISTRICT": "TUZLUCA",
        "STREET": "AŞAĞIKATIRLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "IĞDIR",
        "DISTRICT": "TUZLUCA",
        "STREET": "AŞAĞISUTAŞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "IĞDIR",
        "DISTRICT": "TUZLUCA",
        "STREET": "BADİLLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "IĞDIR",
        "DISTRICT": "TUZLUCA",
        "STREET": "BAHÇECİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "IĞDIR",
        "DISTRICT": "TUZLUCA",
        "STREET": "BAHÇELİMEYDAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "IĞDIR",
        "DISTRICT": "TUZLUCA",
        "STREET": "BAĞLAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "IĞDIR",
        "DISTRICT": "TUZLUCA",
        "STREET": "BEYOĞLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "IĞDIR",
        "DISTRICT": "TUZLUCA",
        "STREET": "BOSTANLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "IĞDIR",
        "DISTRICT": "TUZLUCA",
        "STREET": "BURUKSU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "IĞDIR",
        "DISTRICT": "TUZLUCA",
        "STREET": "CANDERVİŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "IĞDIR",
        "DISTRICT": "TUZLUCA",
        "STREET": "CUMHURİYET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "IĞDIR",
        "DISTRICT": "TUZLUCA",
        "STREET": "DOĞANYURT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "IĞDIR",
        "DISTRICT": "TUZLUCA",
        "STREET": "ELMALIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "IĞDIR",
        "DISTRICT": "TUZLUCA",
        "STREET": "EĞREKDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "IĞDIR",
        "DISTRICT": "TUZLUCA",
        "STREET": "GAZİLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "IĞDIR",
        "DISTRICT": "TUZLUCA",
        "STREET": "GEDİKLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "IĞDIR",
        "DISTRICT": "TUZLUCA",
        "STREET": "GÖKTAŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "IĞDIR",
        "DISTRICT": "TUZLUCA",
        "STREET": "GÜLLÜCE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "IĞDIR",
        "DISTRICT": "TUZLUCA",
        "STREET": "GÜZELDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "IĞDIR",
        "DISTRICT": "TUZLUCA",
        "STREET": "HADIMLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "IĞDIR",
        "DISTRICT": "TUZLUCA",
        "STREET": "HAMURKESEN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "IĞDIR",
        "DISTRICT": "TUZLUCA",
        "STREET": "HASANKENT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "IĞDIR",
        "DISTRICT": "TUZLUCA",
        "STREET": "KALAÇA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "IĞDIR",
        "DISTRICT": "TUZLUCA",
        "STREET": "KAMIŞLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "IĞDIR",
        "DISTRICT": "TUZLUCA",
        "STREET": "KANDİLLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "IĞDIR",
        "DISTRICT": "TUZLUCA",
        "STREET": "KARABULAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "IĞDIR",
        "DISTRICT": "TUZLUCA",
        "STREET": "KARACÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "IĞDIR",
        "DISTRICT": "TUZLUCA",
        "STREET": "KARAKOYUN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "IĞDIR",
        "DISTRICT": "TUZLUCA",
        "STREET": "KARANLIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "IĞDIR",
        "DISTRICT": "TUZLUCA",
        "STREET": "KARATAŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "IĞDIR",
        "DISTRICT": "TUZLUCA",
        "STREET": "KAYAKIŞLAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "IĞDIR",
        "DISTRICT": "TUZLUCA",
        "STREET": "KAYAÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "IĞDIR",
        "DISTRICT": "TUZLUCA",
        "STREET": "KAZKOPARAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "IĞDIR",
        "DISTRICT": "TUZLUCA",
        "STREET": "KELEKLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "IĞDIR",
        "DISTRICT": "TUZLUCA",
        "STREET": "KILIÇLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "IĞDIR",
        "DISTRICT": "TUZLUCA",
        "STREET": "KIRKBULAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "IĞDIR",
        "DISTRICT": "TUZLUCA",
        "STREET": "KIZNEFER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "IĞDIR",
        "DISTRICT": "TUZLUCA",
        "STREET": "KULA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "IĞDIR",
        "DISTRICT": "TUZLUCA",
        "STREET": "KUMBULAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "IĞDIR",
        "DISTRICT": "TUZLUCA",
        "STREET": "KURUAĞAÇ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "IĞDIR",
        "DISTRICT": "TUZLUCA",
        "STREET": "KÖPRÜBAŞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "IĞDIR",
        "DISTRICT": "TUZLUCA",
        "STREET": "KÜÇÜKOVA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "IĞDIR",
        "DISTRICT": "TUZLUCA",
        "STREET": "LALELİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "IĞDIR",
        "DISTRICT": "TUZLUCA",
        "STREET": "MOLLAKAMER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "IĞDIR",
        "DISTRICT": "TUZLUCA",
        "STREET": "NAHIRKIRAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "IĞDIR",
        "DISTRICT": "TUZLUCA",
        "STREET": "OMBULAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "IĞDIR",
        "DISTRICT": "TUZLUCA",
        "STREET": "ORTA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "IĞDIR",
        "DISTRICT": "TUZLUCA",
        "STREET": "ORTABUCAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "IĞDIR",
        "DISTRICT": "TUZLUCA",
        "STREET": "OSMANKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "IĞDIR",
        "DISTRICT": "TUZLUCA",
        "STREET": "PİRDEMİR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "IĞDIR",
        "DISTRICT": "TUZLUCA",
        "STREET": "SARIABDAL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "IĞDIR",
        "DISTRICT": "TUZLUCA",
        "STREET": "SARIBULAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "IĞDIR",
        "DISTRICT": "TUZLUCA",
        "STREET": "SOĞUKBULAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "IĞDIR",
        "DISTRICT": "TUZLUCA",
        "STREET": "SÖĞÜTLÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "IĞDIR",
        "DISTRICT": "TUZLUCA",
        "STREET": "SÜRMELİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "IĞDIR",
        "DISTRICT": "TUZLUCA",
        "STREET": "TAŞUÇAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "IĞDIR",
        "DISTRICT": "TUZLUCA",
        "STREET": "TEZEKÇİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "IĞDIR",
        "DISTRICT": "TUZLUCA",
        "STREET": "TURABİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "IĞDIR",
        "DISTRICT": "TUZLUCA",
        "STREET": "TUTAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "IĞDIR",
        "DISTRICT": "TUZLUCA",
        "STREET": "UĞRUCA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "IĞDIR",
        "DISTRICT": "TUZLUCA",
        "STREET": "YASSIBULAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "IĞDIR",
        "DISTRICT": "TUZLUCA",
        "STREET": "YAYLACIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "IĞDIR",
        "DISTRICT": "TUZLUCA",
        "STREET": "YAĞLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "IĞDIR",
        "DISTRICT": "TUZLUCA",
        "STREET": "YENİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "IĞDIR",
        "DISTRICT": "TUZLUCA",
        "STREET": "YUKARI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "IĞDIR",
        "DISTRICT": "TUZLUCA",
        "STREET": "YUKARIAKTAŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "IĞDIR",
        "DISTRICT": "TUZLUCA",
        "STREET": "YUKARICİVANLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "IĞDIR",
        "DISTRICT": "TUZLUCA",
        "STREET": "YÜCEOTAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "IĞDIR",
        "DISTRICT": "TUZLUCA",
        "STREET": "ÇIRAKLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "IĞDIR",
        "DISTRICT": "TUZLUCA",
        "STREET": "ÇİÇEKLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "IĞDIR",
        "DISTRICT": "TUZLUCA",
        "STREET": "ÜNLENDİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "IĞDIR",
        "DISTRICT": "TUZLUCA",
        "STREET": "ÜÇKAYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "IĞDIR",
        "DISTRICT": "TUZLUCA",
        "STREET": "İNCE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "IĞDIR",
        "DISTRICT": "TUZLUCA",
        "STREET": "İNCESU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "AFŞİN",
        "STREET": "AFŞİNBEY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "AFŞİN",
        "STREET": "ALEMDAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "AFŞİN",
        "STREET": "ALPASLAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "AFŞİN",
        "STREET": "ALTAŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "AFŞİN",
        "STREET": "ALTINELMA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "AFŞİN",
        "STREET": "ALİMPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "AFŞİN",
        "STREET": "ANZOREY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "AFŞİN",
        "STREET": "ARITAŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "AFŞİN",
        "STREET": "ARMUTALAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "AFŞİN",
        "STREET": "AĞCAŞAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "AFŞİN",
        "STREET": "BAKRAÇ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "AFŞİN",
        "STREET": "BAŞÜSTÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "AFŞİN",
        "STREET": "BERÇENEK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "AFŞİN",
        "STREET": "BEYCEĞİZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "AFŞİN",
        "STREET": "BÜGET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "AFŞİN",
        "STREET": "BÜYÜKSEVİN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "AFŞİN",
        "STREET": "BÜYÜKTATLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "AFŞİN",
        "STREET": "BİNBOĞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "AFŞİN",
        "STREET": "DEDEBABA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "AFŞİN",
        "STREET": "DEVEBOYNU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "AFŞİN",
        "STREET": "DOKUZTAY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "AFŞİN",
        "STREET": "EFSUS TURAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "AFŞİN",
        "STREET": "EMİRLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "AFŞİN",
        "STREET": "EMİRİLYAS MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "AFŞİN",
        "STREET": "ERÇENE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "AFŞİN",
        "STREET": "ESENCE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "AFŞİN",
        "STREET": "GAZİOSMANPAŞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "AFŞİN",
        "STREET": "GERGER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "AFŞİN",
        "STREET": "GÖZPINARI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "AFŞİN",
        "STREET": "HATİCEPINARI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "AFŞİN",
        "STREET": "HURMAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "AFŞİN",
        "STREET": "HÜYÜKLÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "AFŞİN",
        "STREET": "KABAAĞAÇ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "AFŞİN",
        "STREET": "KALE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "AFŞİN",
        "STREET": "KANGAL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "AFŞİN",
        "STREET": "KARAGÖZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "AFŞİN",
        "STREET": "KAŞANLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "AFŞİN",
        "STREET": "KOÇOVASI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "AFŞİN",
        "STREET": "KUŞKAYASI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "AFŞİN",
        "STREET": "KÖTÜRE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "AFŞİN",
        "STREET": "MARABUZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "AFŞİN",
        "STREET": "MEHRE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "AFŞİN",
        "STREET": "NADIR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "AFŞİN",
        "STREET": "OĞLAKKAYASI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "AFŞİN",
        "STREET": "PINARBAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "AFŞİN",
        "STREET": "SOĞUCAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "AFŞİN",
        "STREET": "SÖĞÜTDERESİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "AFŞİN",
        "STREET": "TANIR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "AFŞİN",
        "STREET": "TATLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "AFŞİN",
        "STREET": "TOPAKTAŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "AFŞİN",
        "STREET": "TÜRKSEVİN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "AFŞİN",
        "STREET": "TÜRKÇAYIRI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "AFŞİN",
        "STREET": "YAZIDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "AFŞİN",
        "STREET": "YAZIKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "AFŞİN",
        "STREET": "YEŞİLYURT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "AFŞİN",
        "STREET": "ÇAĞILHAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "AFŞİN",
        "STREET": "ÇOBANBEYLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "AFŞİN",
        "STREET": "ÇOMUDÜZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "AFŞİN",
        "STREET": "ÇOĞULHAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "AFŞİN",
        "STREET": "ÇUKURPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "AFŞİN",
        "STREET": "ÖRDEK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "AFŞİN",
        "STREET": "ÖRENDERESİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "AFŞİN",
        "STREET": "ÖRENLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "AFŞİN",
        "STREET": "İNCİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "AFŞİN",
        "STREET": "İNCİRLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "AFŞİN",
        "STREET": "İĞDEMLİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "ANDIRIN",
        "STREET": "AKGÜMÜŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "ANDIRIN",
        "STREET": "AKÇAKOYUNLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "ANDIRIN",
        "STREET": "AKİFİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "ANDIRIN",
        "STREET": "ALAMEŞE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "ANDIRIN",
        "STREET": "ALANLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "ANDIRIN",
        "STREET": "ALINOLUK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "ANDIRIN",
        "STREET": "ALTINBOĞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "ANDIRIN",
        "STREET": "ALTINYAYLA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "ANDIRIN",
        "STREET": "ANACIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "ANDIRIN",
        "STREET": "ARIKLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "ANDIRIN",
        "STREET": "BAŞDOĞAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "ANDIRIN",
        "STREET": "BEKTAŞLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "ANDIRIN",
        "STREET": "BEŞBUCAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "ANDIRIN",
        "STREET": "BOSTANLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "ANDIRIN",
        "STREET": "BOYNUYOĞUNLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "ANDIRIN",
        "STREET": "BOZTOPRAKLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "ANDIRIN",
        "STREET": "BOĞAZÖREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "ANDIRIN",
        "STREET": "BULGURKAYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "ANDIRIN",
        "STREET": "CAMBAZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "ANDIRIN",
        "STREET": "CAMUZLUK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "ANDIRIN",
        "STREET": "DARIOVASI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "ANDIRIN",
        "STREET": "EFİRAĞIZLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "ANDIRIN",
        "STREET": "EMİRLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "ANDIRIN",
        "STREET": "ERENLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "ANDIRIN",
        "STREET": "GEBEN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "ANDIRIN",
        "STREET": "GÖKAHMETLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "ANDIRIN",
        "STREET": "GÖKGEDİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "ANDIRIN",
        "STREET": "GÖKÇELİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "ANDIRIN",
        "STREET": "HACIVELİUŞAĞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "ANDIRIN",
        "STREET": "KABAAĞAÇ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "ANDIRIN",
        "STREET": "KABAKLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "ANDIRIN",
        "STREET": "KALEBOYNU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "ANDIRIN",
        "STREET": "KARAPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "ANDIRIN",
        "STREET": "KARGAÇAYIRI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "ANDIRIN",
        "STREET": "KIYIKÇI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "ANDIRIN",
        "STREET": "KIZIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "ANDIRIN",
        "STREET": "KUMARLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "ANDIRIN",
        "STREET": "KUZGUN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "ANDIRIN",
        "STREET": "KÖKLÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "ANDIRIN",
        "STREET": "KÖLELİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "ANDIRIN",
        "STREET": "ORHANİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "ANDIRIN",
        "STREET": "OSMANCIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "ANDIRIN",
        "STREET": "PINARBAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "ANDIRIN",
        "STREET": "RİFATİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "ANDIRIN",
        "STREET": "SUMAKLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "ANDIRIN",
        "STREET": "TATARLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "ANDIRIN",
        "STREET": "TORUN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "ANDIRIN",
        "STREET": "TUFAN PAŞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "ANDIRIN",
        "STREET": "YENİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "ANDIRIN",
        "STREET": "YENİKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "ANDIRIN",
        "STREET": "YEŞİLOVA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "ANDIRIN",
        "STREET": "YEŞİLTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "ANDIRIN",
        "STREET": "YEŞİLYURT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "ANDIRIN",
        "STREET": "ÇOKAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "ANDIRIN",
        "STREET": "ÇUHADARLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "ANDIRIN",
        "STREET": "ÇİÇEKLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "ANDIRIN",
        "STREET": "ÇİĞŞAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "DULKADİROĞLU",
        "STREET": "ABBASLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "DULKADİROĞLU",
        "STREET": "AKSU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "DULKADİROĞLU",
        "STREET": "AKYAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "DULKADİROĞLU",
        "STREET": "ALİBEYUŞAĞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "DULKADİROĞLU",
        "STREET": "ARSLANBEY ÇİFTLİĞİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "DULKADİROĞLU",
        "STREET": "ASLAN BEY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "DULKADİROĞLU",
        "STREET": "AYAKLICAOLUK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "DULKADİROĞLU",
        "STREET": "AĞABEYLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "DULKADİROĞLU",
        "STREET": "BAHÇELİ EVLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "DULKADİROĞLU",
        "STREET": "BAHÇELİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "DULKADİROĞLU",
        "STREET": "BALLICA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "DULKADİROĞLU",
        "STREET": "BAYAZITLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "DULKADİROĞLU",
        "STREET": "BAYDEMİRLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "DULKADİROĞLU",
        "STREET": "BAĞLARBAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "DULKADİROĞLU",
        "STREET": "BAŞDEVRİŞLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "DULKADİROĞLU",
        "STREET": "BEŞENLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "DULKADİROĞLU",
        "STREET": "BOYALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "DULKADİROĞLU",
        "STREET": "BUDAKLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "DULKADİROĞLU",
        "STREET": "BULANIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "DULKADİROĞLU",
        "STREET": "DELİLKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "DULKADİROĞLU",
        "STREET": "DEMİRCİLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "DULKADİROĞLU",
        "STREET": "DENİZLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "DULKADİROĞLU",
        "STREET": "DEREKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "DULKADİROĞLU",
        "STREET": "DERELİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "DULKADİROĞLU",
        "STREET": "DOĞANLIKARAHASAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "DULKADİROĞLU",
        "STREET": "DOĞU KENT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "DULKADİROĞLU",
        "STREET": "DULKADİROĞLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "DULKADİROĞLU",
        "STREET": "DURAKLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "DULKADİROĞLU",
        "STREET": "DİVANLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "DULKADİROĞLU",
        "STREET": "EGEMENLİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "DULKADİROĞLU",
        "STREET": "EKBEROĞLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "DULKADİROĞLU",
        "STREET": "EKMEKÇİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "DULKADİROĞLU",
        "STREET": "ELMALAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "DULKADİROĞLU",
        "STREET": "ERKENEZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "DULKADİROĞLU",
        "STREET": "ESKİNARLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "DULKADİROĞLU",
        "STREET": "EYÜP SULTAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "DULKADİROĞLU",
        "STREET": "FEVZİ PAŞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "DULKADİROĞLU",
        "STREET": "GAFFARLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "DULKADİROĞLU",
        "STREET": "GAZİ PAŞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "DULKADİROĞLU",
        "STREET": "GENÇ OSMAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "DULKADİROĞLU",
        "STREET": "GÖLLÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "DULKADİROĞLU",
        "STREET": "GÜNEŞEVLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "DULKADİROĞLU",
        "STREET": "GÜZELYURT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "DULKADİROĞLU",
        "STREET": "HACIEYÜPLÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "DULKADİROĞLU",
        "STREET": "HALKAÇAYIRI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "DULKADİROĞLU",
        "STREET": "KABASAKAL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "DULKADİROĞLU",
        "STREET": "KANUNİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "DULKADİROĞLU",
        "STREET": "KAPIÇAM MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "DULKADİROĞLU",
        "STREET": "KARACASU FERHUŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "DULKADİROĞLU",
        "STREET": "KARACASU KARAZİYARET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "DULKADİROĞLU",
        "STREET": "KARACASU KARŞIYAKA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "DULKADİROĞLU",
        "STREET": "KARACASU KIRIM MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "DULKADİROĞLU",
        "STREET": "KARACASU MAMARAŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "DULKADİROĞLU",
        "STREET": "KARAMANLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "DULKADİROĞLU",
        "STREET": "KARATAŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "DULKADİROĞLU",
        "STREET": "KARTAL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "DULKADİROĞLU",
        "STREET": "KAYABAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "DULKADİROĞLU",
        "STREET": "KAZANLIPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "DULKADİROĞLU",
        "STREET": "KEMALLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "DULKADİROĞLU",
        "STREET": "KILAĞLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "DULKADİROĞLU",
        "STREET": "KOCOLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "DULKADİROĞLU",
        "STREET": "KOZLUDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "DULKADİROĞLU",
        "STREET": "KURTULUŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "DULKADİROĞLU",
        "STREET": "KUZUCAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "DULKADİROĞLU",
        "STREET": "KÜPELİKIZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "DULKADİROĞLU",
        "STREET": "KÜÇÜKNACAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "DULKADİROĞLU",
        "STREET": "MAKSUTUŞAĞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "DULKADİROĞLU",
        "STREET": "MEHMET AKİF MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "DULKADİROĞLU",
        "STREET": "MENDERES MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "DULKADİROĞLU",
        "STREET": "NAMIK KEMAL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "DULKADİROĞLU",
        "STREET": "NAVRUZLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "DULKADİROĞLU",
        "STREET": "OSMANBEY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "DULKADİROĞLU",
        "STREET": "PEYNİRDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "DULKADİROĞLU",
        "STREET": "PINARBAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "DULKADİROĞLU",
        "STREET": "SAKARYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "DULKADİROĞLU",
        "STREET": "SARIKAYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "DULKADİROĞLU",
        "STREET": "SENEM AYŞE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "DULKADİROĞLU",
        "STREET": "SEYRANTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "DULKADİROĞLU",
        "STREET": "SÖĞÜTLÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "DULKADİROĞLU",
        "STREET": "SÜMER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "DULKADİROĞLU",
        "STREET": "SÜTÇÜ İMAM MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "DULKADİROĞLU",
        "STREET": "SİVRİCEHÜYÜK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "DULKADİROĞLU",
        "STREET": "TEVEKKELLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "DULKADİROĞLU",
        "STREET": "TURAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "DULKADİROĞLU",
        "STREET": "ULUTAŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "DULKADİROĞLU",
        "STREET": "YAHYA KEMAL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "DULKADİROĞLU",
        "STREET": "YAVUZ SELİM MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "DULKADİROĞLU",
        "STREET": "YENİ ŞEHİR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "DULKADİROĞLU",
        "STREET": "YENİKENT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "DULKADİROĞLU",
        "STREET": "YENİPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "DULKADİROĞLU",
        "STREET": "YENİYURT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "DULKADİROĞLU",
        "STREET": "YEŞİLTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "DULKADİROĞLU",
        "STREET": "YUSUFHACILI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "DULKADİROĞLU",
        "STREET": "ÇATMAYAYLA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "DULKADİROĞLU",
        "STREET": "ÇINARLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "DULKADİROĞLU",
        "STREET": "ÇOBANLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "DULKADİROĞLU",
        "STREET": "ÇOKYAŞAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "DULKADİROĞLU",
        "STREET": "ÇİĞLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "DULKADİROĞLU",
        "STREET": "ÖKSÜZLÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "DULKADİROĞLU",
        "STREET": "İSA DİVANLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "DULKADİROĞLU",
        "STREET": "İSMET PAŞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "DULKADİROĞLU",
        "STREET": "İSTASYON MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "DULKADİROĞLU",
        "STREET": "ŞEREFOĞLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "DULKADİROĞLU",
        "STREET": "ŞEYH ADİL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "DULKADİROĞLU",
        "STREET": "ŞEYH ŞAMİL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "EKİNÖZÜ",
        "STREET": "AKPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "EKİNÖZÜ",
        "STREET": "ALTUNYAPRAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "EKİNÖZÜ",
        "STREET": "ALİŞAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "EKİNÖZÜ",
        "STREET": "ANBAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "EKİNÖZÜ",
        "STREET": "ATAKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "EKİNÖZÜ",
        "STREET": "DEMİRLİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "EKİNÖZÜ",
        "STREET": "GAZİLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "EKİNÖZÜ",
        "STREET": "GÖZPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "EKİNÖZÜ",
        "STREET": "KABAKTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "EKİNÖZÜ",
        "STREET": "KANDİL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "EKİNÖZÜ",
        "STREET": "KÜRTÜL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "EKİNÖZÜ",
        "STREET": "MAARİF MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "EKİNÖZÜ",
        "STREET": "ORTAÖREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "EKİNÖZÜ",
        "STREET": "SOYSALLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "EKİNÖZÜ",
        "STREET": "TÜRKELİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "EKİNÖZÜ",
        "STREET": "TÜRKMENLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "EKİNÖZÜ",
        "STREET": "YENİKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "EKİNÖZÜ",
        "STREET": "ÇİFTLİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "EKİNÖZÜ",
        "STREET": "ÇİFTLİKKALE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "EKİNÖZÜ",
        "STREET": "ÖZTÜRK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "EKİNÖZÜ",
        "STREET": "İÇMELER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "ELBİSTAN",
        "STREET": "AKARCA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "ELBİSTAN",
        "STREET": "AKBAYIR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "ELBİSTAN",
        "STREET": "AKSAKAL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "ELBİSTAN",
        "STREET": "AKÖREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "ELBİSTAN",
        "STREET": "ALEMBEY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "ELBİSTAN",
        "STREET": "ALKAYAOĞLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "ELBİSTAN",
        "STREET": "ARMUTALAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "ELBİSTAN",
        "STREET": "ATMALIKAŞANLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "ELBİSTAN",
        "STREET": "AĞLICA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "ELBİSTAN",
        "STREET": "BAHÇELİEVLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "ELBİSTAN",
        "STREET": "BAKIŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "ELBİSTAN",
        "STREET": "BALIKÇIL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "ELBİSTAN",
        "STREET": "BATTALGAZİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "ELBİSTAN",
        "STREET": "BEYYURDU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "ELBİSTAN",
        "STREET": "BEŞTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "ELBİSTAN",
        "STREET": "BÜYÜKYAPALAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "ELBİSTAN",
        "STREET": "CEYHAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "ELBİSTAN",
        "STREET": "CUMHURİYET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "ELBİSTAN",
        "STREET": "DEMİRCİLİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "ELBİSTAN",
        "STREET": "DERVİŞÇİMLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "ELBİSTAN",
        "STREET": "DOĞAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "ELBİSTAN",
        "STREET": "ELDELEK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "ELBİSTAN",
        "STREET": "ELMALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "ELBİSTAN",
        "STREET": "ESENTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "ELBİSTAN",
        "STREET": "EVCİHÜYÜK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "ELBİSTAN",
        "STREET": "FAKIOĞLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "ELBİSTAN",
        "STREET": "GEÇİT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "ELBİSTAN",
        "STREET": "GÖKÇEK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "ELBİSTAN",
        "STREET": "GÜBLÜCE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "ELBİSTAN",
        "STREET": "GÜCÜK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "ELBİSTAN",
        "STREET": "GÜMÜŞDÖVEN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "ELBİSTAN",
        "STREET": "GÜNALTI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "ELBİSTAN",
        "STREET": "GÜNDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "ELBİSTAN",
        "STREET": "GÜNEŞLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "ELBİSTAN",
        "STREET": "GÜVERCİNLİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "ELBİSTAN",
        "STREET": "HACIHASANLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "ELBİSTAN",
        "STREET": "HASANALİLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "ELBİSTAN",
        "STREET": "HASANKENDİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "ELBİSTAN",
        "STREET": "HORHOR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "ELBİSTAN",
        "STREET": "IZGIN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "ELBİSTAN",
        "STREET": "KALAYCIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "ELBİSTAN",
        "STREET": "KALEALTI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "ELBİSTAN",
        "STREET": "KANGAL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "ELBİSTAN",
        "STREET": "KANTARMA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "ELBİSTAN",
        "STREET": "KARAELBİSTAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "ELBİSTAN",
        "STREET": "KARAHASANUŞAĞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "ELBİSTAN",
        "STREET": "KARAHÜYÜK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "ELBİSTAN",
        "STREET": "KARAMAĞARA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "ELBİSTAN",
        "STREET": "KAVAKTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "ELBİSTAN",
        "STREET": "KAYAGEÇİT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "ELBİSTAN",
        "STREET": "KEÇEMAĞARA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "ELBİSTAN",
        "STREET": "KIZILCAOBA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "ELBİSTAN",
        "STREET": "KIŞLA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "ELBİSTAN",
        "STREET": "KÖPRÜBAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "ELBİSTAN",
        "STREET": "KÖRÜCEK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "ELBİSTAN",
        "STREET": "KÖSEYAHYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "ELBİSTAN",
        "STREET": "KÖŞK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "ELBİSTAN",
        "STREET": "KÜMBET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "ELBİSTAN",
        "STREET": "KÜÇÜKYAPALAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "ELBİSTAN",
        "STREET": "ORHANGAZİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "ELBİSTAN",
        "STREET": "OVACIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "ELBİSTAN",
        "STREET": "PINARBAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "ELBİSTAN",
        "STREET": "SARIYATAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "ELBİSTAN",
        "STREET": "SEVDİLLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "ELBİSTAN",
        "STREET": "SÖĞÜTLÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "ELBİSTAN",
        "STREET": "SÜNNET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "ELBİSTAN",
        "STREET": "TAPKIRAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "ELBİSTAN",
        "STREET": "TAPKIRANKALE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "ELBİSTAN",
        "STREET": "TAŞBURUN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "ELBİSTAN",
        "STREET": "TEPEBAŞI (GÜCÜK) MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "ELBİSTAN",
        "STREET": "TEPEBAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "ELBİSTAN",
        "STREET": "TOPALLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "ELBİSTAN",
        "STREET": "TOPRAKHİSAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "ELBİSTAN",
        "STREET": "TÜRKÖREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "ELBİSTAN",
        "STREET": "UNCULAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "ELBİSTAN",
        "STREET": "UZUNPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "ELBİSTAN",
        "STREET": "YALAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "ELBİSTAN",
        "STREET": "YALINTAŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "ELBİSTAN",
        "STREET": "YAPILI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "ELBİSTAN",
        "STREET": "YAPILIPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "ELBİSTAN",
        "STREET": "YAPRAKLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "ELBİSTAN",
        "STREET": "YEŞİLYURT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "ELBİSTAN",
        "STREET": "YOĞUNSÖĞÜT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "ELBİSTAN",
        "STREET": "YUNUS EMRE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "ELBİSTAN",
        "STREET": "ÇALIŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "ELBİSTAN",
        "STREET": "ÇATOVA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "ELBİSTAN",
        "STREET": "ÇITLIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "ELBİSTAN",
        "STREET": "ÇİÇEK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "ELBİSTAN",
        "STREET": "ÖZBEK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "ELBİSTAN",
        "STREET": "ÖZCANLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "ELBİSTAN",
        "STREET": "İKİZPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "ELBİSTAN",
        "STREET": "İNCECİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "ELBİSTAN",
        "STREET": "İĞDE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "GÖKSUN",
        "STREET": "ACIELMA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "GÖKSUN",
        "STREET": "AHMETCİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "GÖKSUN",
        "STREET": "ALIÇLIBUCAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "GÖKSUN",
        "STREET": "ALTINOBA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "GÖKSUN",
        "STREET": "APIKLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "GÖKSUN",
        "STREET": "ASLANBEYÇİFTLİĞİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "GÖKSUN",
        "STREET": "BAHÇELİEVLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "GÖKSUN",
        "STREET": "BERİT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "GÖKSUN",
        "STREET": "BOZARMUT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "GÖKSUN",
        "STREET": "BOZHÜYÜK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "GÖKSUN",
        "STREET": "BÜYÜKKIZILCIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "GÖKSUN",
        "STREET": "BÜYÜKÇAMURLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "GÖKSUN",
        "STREET": "CUMHURİYET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "GÖKSUN",
        "STREET": "DEĞİRMENDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "GÖKSUN",
        "STREET": "DOĞANKONAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "GÖKSUN",
        "STREET": "ELMALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "GÖKSUN",
        "STREET": "ERİCEK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "GÖKSUN",
        "STREET": "ESENKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "GÖKSUN",
        "STREET": "FINDIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "GÖKSUN",
        "STREET": "FINDIKLIKOYAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "GÖKSUN",
        "STREET": "GÖLPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "GÖKSUN",
        "STREET": "GÖYNÜK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "GÖKSUN",
        "STREET": "GÜCÜKSU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "GÖKSUN",
        "STREET": "GÜLDAĞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "GÖKSUN",
        "STREET": "GÜLLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "GÖKSUN",
        "STREET": "HACIKODAL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "GÖKSUN",
        "STREET": "HACIMİRZA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "GÖKSUN",
        "STREET": "HACIÖMER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "GÖKSUN",
        "STREET": "HARBİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "GÖKSUN",
        "STREET": "HUĞTAŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "GÖKSUN",
        "STREET": "KALE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "GÖKSUN",
        "STREET": "KALEBOYNU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "GÖKSUN",
        "STREET": "KAMIŞCIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "GÖKSUN",
        "STREET": "KANLIKAVAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "GÖKSUN",
        "STREET": "KARAAHMET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "GÖKSUN",
        "STREET": "KARATUT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "GÖKSUN",
        "STREET": "KARAÖMER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "GÖKSUN",
        "STREET": "KAVŞUT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "GÖKSUN",
        "STREET": "KAYABAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "GÖKSUN",
        "STREET": "KAZANDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "GÖKSUN",
        "STREET": "KEKLİKOLUK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "GÖKSUN",
        "STREET": "KEMALPAŞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "GÖKSUN",
        "STREET": "KINIKKOZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "GÖKSUN",
        "STREET": "KIZILÖZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "GÖKSUN",
        "STREET": "KORKMAZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "GÖKSUN",
        "STREET": "KURTULUŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "GÖKSUN",
        "STREET": "KÖMÜR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "GÖKSUN",
        "STREET": "KÖPRÜBAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "GÖKSUN",
        "STREET": "KÜÇÜKÇAMURLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "GÖKSUN",
        "STREET": "KİREÇ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "GÖKSUN",
        "STREET": "MAHMUTBEY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "GÖKSUN",
        "STREET": "MEHMETBEY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "GÖKSUN",
        "STREET": "MÜRSEL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "GÖKSUN",
        "STREET": "ORTATEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "GÖKSUN",
        "STREET": "PAYAMBURNU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "GÖKSUN",
        "STREET": "PINARBAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "GÖKSUN",
        "STREET": "SARAYCIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "GÖKSUN",
        "STREET": "SIRMALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "GÖKSUN",
        "STREET": "SOĞUKPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "GÖKSUN",
        "STREET": "TAHİRBEY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "GÖKSUN",
        "STREET": "TAŞOLUK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "GÖKSUN",
        "STREET": "TEMÜRAĞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "GÖKSUN",
        "STREET": "TEPEBAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "GÖKSUN",
        "STREET": "TOMBAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "GÖKSUN",
        "STREET": "YANTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "GÖKSUN",
        "STREET": "YAĞMURLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "GÖKSUN",
        "STREET": "YENİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "GÖKSUN",
        "STREET": "YENİYAPAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "GÖKSUN",
        "STREET": "YEŞİLKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "GÖKSUN",
        "STREET": "YOĞUNOLUK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "GÖKSUN",
        "STREET": "YİRİCEK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "GÖKSUN",
        "STREET": "ÇAMDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "GÖKSUN",
        "STREET": "ÇARDAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "GÖKSUN",
        "STREET": "ÇAĞLAYAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "NURHAK",
        "STREET": "ALÇİÇEK DEĞİRMENKAYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "NURHAK",
        "STREET": "AĞCAŞAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "NURHAK",
        "STREET": "BARIŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "NURHAK",
        "STREET": "BİÇİN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "NURHAK",
        "STREET": "ESKİKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "NURHAK",
        "STREET": "GÜRSEL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "NURHAK",
        "STREET": "HÜYÜK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "NURHAK",
        "STREET": "KARAÇAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "NURHAK",
        "STREET": "KARŞIYAKA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "NURHAK",
        "STREET": "KIYILAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "NURHAK",
        "STREET": "KULLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "NURHAK",
        "STREET": "PINARBAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "NURHAK",
        "STREET": "SEYİTALİLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "NURHAK",
        "STREET": "TATLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "NURHAK",
        "STREET": "UMUTLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "NURHAK",
        "STREET": "YENİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "ONİKİŞUBAT",
        "STREET": "5 NİSAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "ONİKİŞUBAT",
        "STREET": "ABDÜLHAMİD HAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "ONİKİŞUBAT",
        "STREET": "AKÇAKOYUNLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "ONİKİŞUBAT",
        "STREET": "AKİF İNAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "ONİKİŞUBAT",
        "STREET": "ALTINOVA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "ONİKİŞUBAT",
        "STREET": "AVCILAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "ONİKİŞUBAT",
        "STREET": "AVGASIR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "ONİKİŞUBAT",
        "STREET": "AVŞAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "ONİKİŞUBAT",
        "STREET": "AYŞEPINARI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "ONİKİŞUBAT",
        "STREET": "AĞCALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "ONİKİŞUBAT",
        "STREET": "BARBAROS MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "ONİKİŞUBAT",
        "STREET": "BEŞBAĞLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "ONİKİŞUBAT",
        "STREET": "BEŞEN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "ONİKİŞUBAT",
        "STREET": "BOĞAZİÇİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "ONİKİŞUBAT",
        "STREET": "BULUTOĞLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "ONİKİŞUBAT",
        "STREET": "BÜYÜKSIR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "ONİKİŞUBAT",
        "STREET": "BİN EVLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "ONİKİŞUBAT",
        "STREET": "CEYHAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "ONİKİŞUBAT",
        "STREET": "CUMHURİYET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "ONİKİŞUBAT",
        "STREET": "CÜCELİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "ONİKİŞUBAT",
        "STREET": "DADAĞLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "ONİKİŞUBAT",
        "STREET": "DEMREK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "ONİKİŞUBAT",
        "STREET": "DEREBOĞAZI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "ONİKİŞUBAT",
        "STREET": "DUMLUPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "ONİKİŞUBAT",
        "STREET": "DÖNGEL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "ONİKİŞUBAT",
        "STREET": "DÖNGELE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "ONİKİŞUBAT",
        "STREET": "DÖNÜKLÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "ONİKİŞUBAT",
        "STREET": "ERTUĞRUL GAZİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "ONİKİŞUBAT",
        "STREET": "FATMALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "ONİKİŞUBAT",
        "STREET": "FATİH MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "ONİKİŞUBAT",
        "STREET": "GAYBERLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "ONİKİŞUBAT",
        "STREET": "GEDEMEN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "ONİKİŞUBAT",
        "STREET": "GÖLPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "ONİKİŞUBAT",
        "STREET": "HACI BAYRAM VELİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "ONİKİŞUBAT",
        "STREET": "HACIAĞLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "ONİKİŞUBAT",
        "STREET": "HACIBUDAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "ONİKİŞUBAT",
        "STREET": "HACILAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "ONİKİŞUBAT",
        "STREET": "HACIMUSTAFA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "ONİKİŞUBAT",
        "STREET": "HACININOĞLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "ONİKİŞUBAT",
        "STREET": "HACIİBRAHİMUŞAĞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "ONİKİŞUBAT",
        "STREET": "HARTLAP MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "ONİKİŞUBAT",
        "STREET": "HASANCIKLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "ONİKİŞUBAT",
        "STREET": "HAYDAR BEY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "ONİKİŞUBAT",
        "STREET": "HAYRULLAH MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "ONİKİŞUBAT",
        "STREET": "HÜRRİYET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "ONİKİŞUBAT",
        "STREET": "ILICA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "ONİKİŞUBAT",
        "STREET": "KALAKAYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "ONİKİŞUBAT",
        "STREET": "KALE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "ONİKİŞUBAT",
        "STREET": "KAPIKAYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "ONİKİŞUBAT",
        "STREET": "KARACAOĞLAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "ONİKİŞUBAT",
        "STREET": "KARADERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "ONİKİŞUBAT",
        "STREET": "KARAMANLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "ONİKİŞUBAT",
        "STREET": "KAVLAKLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "ONİKİŞUBAT",
        "STREET": "KAYNAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "ONİKİŞUBAT",
        "STREET": "KAZIM KARABEKİR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "ONİKİŞUBAT",
        "STREET": "KERTMEN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "ONİKİŞUBAT",
        "STREET": "KERİMLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "ONİKİŞUBAT",
        "STREET": "KILAVUZLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "ONİKİŞUBAT",
        "STREET": "KISIKLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "ONİKİŞUBAT",
        "STREET": "KIZILDAMLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "ONİKİŞUBAT",
        "STREET": "KIZILSEKİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "ONİKİŞUBAT",
        "STREET": "KOZCAĞIZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "ONİKİŞUBAT",
        "STREET": "KUMARLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "ONİKİŞUBAT",
        "STREET": "KUMAŞIR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "ONİKİŞUBAT",
        "STREET": "KURTLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "ONİKİŞUBAT",
        "STREET": "KURUCAOVA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "ONİKİŞUBAT",
        "STREET": "KÖSELİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "ONİKİŞUBAT",
        "STREET": "KÖŞÜRGE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "ONİKİŞUBAT",
        "STREET": "KÜMPERLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "ONİKİŞUBAT",
        "STREET": "KÜRTÜL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "ONİKİŞUBAT",
        "STREET": "KÜÇÜKSIR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "ONİKİŞUBAT",
        "STREET": "MAARİF MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "ONİKİŞUBAT",
        "STREET": "MAKSUTLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "ONİKİŞUBAT",
        "STREET": "MALİK EJDER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "ONİKİŞUBAT",
        "STREET": "MAĞRALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "ONİKİŞUBAT",
        "STREET": "MERCİMEKTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "ONİKİŞUBAT",
        "STREET": "MEVLANA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "ONİKİŞUBAT",
        "STREET": "MOLLA GÜRANİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "ONİKİŞUBAT",
        "STREET": "MURATLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "ONİKİŞUBAT",
        "STREET": "MİMAR SİNAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "ONİKİŞUBAT",
        "STREET": "NECİP FAZIL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "ONİKİŞUBAT",
        "STREET": "ORHANGAZİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "ONİKİŞUBAT",
        "STREET": "ORUÇ REİS MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "ONİKİŞUBAT",
        "STREET": "OSMAN GAZİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "ONİKİŞUBAT",
        "STREET": "PAYAMLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "ONİKİŞUBAT",
        "STREET": "PİRİ REİS MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "ONİKİŞUBAT",
        "STREET": "RAHMACILAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "ONİKİŞUBAT",
        "STREET": "REYHANLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "ONİKİŞUBAT",
        "STREET": "SADIKLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "ONİKİŞUBAT",
        "STREET": "SARIGÜZEL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "ONİKİŞUBAT",
        "STREET": "SARIMOLLALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "ONİKİŞUBAT",
        "STREET": "SARIÇUKUR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "ONİKİŞUBAT",
        "STREET": "SAYGILI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "ONİKİŞUBAT",
        "STREET": "SAÇAKLIZADE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "ONİKİŞUBAT",
        "STREET": "SELÇUKLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "ONİKİŞUBAT",
        "STREET": "SELİMİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "ONİKİŞUBAT",
        "STREET": "SERİNTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "ONİKİŞUBAT",
        "STREET": "SULUYAYLA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "ONİKİŞUBAT",
        "STREET": "SUÇATI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "ONİKİŞUBAT",
        "STREET": "SÜLEYMANLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "ONİKİŞUBAT",
        "STREET": "SÜLEYMANŞAH MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "ONİKİŞUBAT",
        "STREET": "TAVŞAN TEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "ONİKİŞUBAT",
        "STREET": "TEKEREK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "ONİKİŞUBAT",
        "STREET": "TEKİR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "ONİKİŞUBAT",
        "STREET": "TOPÇALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "ONİKİŞUBAT",
        "STREET": "VADİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "ONİKİŞUBAT",
        "STREET": "YAMAÇTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "ONİKİŞUBAT",
        "STREET": "YENİCEKALE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "ONİKİŞUBAT",
        "STREET": "YENİDEMİR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "ONİKİŞUBAT",
        "STREET": "YENİYAPAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "ONİKİŞUBAT",
        "STREET": "YEŞİLYURT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "ONİKİŞUBAT",
        "STREET": "YOLYANI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "ONİKİŞUBAT",
        "STREET": "YUNUS EMRE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "ONİKİŞUBAT",
        "STREET": "YUSUFLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "ONİKİŞUBAT",
        "STREET": "YÜRÜKSELİM MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "ONİKİŞUBAT",
        "STREET": "YİRMİİKİGÜN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "ONİKİŞUBAT",
        "STREET": "ZEYTİNDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "ONİKİŞUBAT",
        "STREET": "ÇAKIRDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "ONİKİŞUBAT",
        "STREET": "ÇAKIRLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "ONİKİŞUBAT",
        "STREET": "ÇAMLIBEL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "ONİKİŞUBAT",
        "STREET": "ÇAMLICA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "ONİKİŞUBAT",
        "STREET": "ÇAMLIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "ONİKİŞUBAT",
        "STREET": "ÇAĞIRKAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "ONİKİŞUBAT",
        "STREET": "ÇAĞLAYAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "ONİKİŞUBAT",
        "STREET": "ÇEVREPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "ONİKİŞUBAT",
        "STREET": "ÇOKRAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "ONİKİŞUBAT",
        "STREET": "ÇUKURHİSAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "ONİKİŞUBAT",
        "STREET": "ÖNSEN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "ONİKİŞUBAT",
        "STREET": "ÖZTÜRK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "ONİKİŞUBAT",
        "STREET": "ÖŞLÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "ONİKİŞUBAT",
        "STREET": "ÜNGÜT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "ONİKİŞUBAT",
        "STREET": "İSMAİLLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "ONİKİŞUBAT",
        "STREET": "İSTİKLAL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "ONİKİŞUBAT",
        "STREET": "ŞAHİNKAYASI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "ONİKİŞUBAT",
        "STREET": "ŞAZİ BEY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "ONİKİŞUBAT",
        "STREET": "ŞEHİT ABDULLAH ÇAVUŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "ONİKİŞUBAT",
        "STREET": "ŞEHİT EVLİYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "PAZARCIK",
        "STREET": "15 TEMMUZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "PAZARCIK",
        "STREET": "AHMET BOZDAĞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "PAZARCIK",
        "STREET": "AKCAKOYUNLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "PAZARCIK",
        "STREET": "AKDEMİR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "PAZARCIK",
        "STREET": "AKÇALAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "PAZARCIK",
        "STREET": "ARMUTLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "PAZARCIK",
        "STREET": "AŞAĞIMÜLK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "PAZARCIK",
        "STREET": "BAĞDINISAĞIR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "PAZARCIK",
        "STREET": "BEŞÇEŞME MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "PAZARCIK",
        "STREET": "BÖLÜKÇAM MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "PAZARCIK",
        "STREET": "BÜYÜKNACAR FATİH MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "PAZARCIK",
        "STREET": "BÜYÜKNACAR KOCADERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "PAZARCIK",
        "STREET": "BÜYÜKNACAR MERKEZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "PAZARCIK",
        "STREET": "CENGİZ TOPEL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "PAZARCIK",
        "STREET": "CİMİKANLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "PAZARCIK",
        "STREET": "DAMLATAŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "PAZARCIK",
        "STREET": "DEDEPAŞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "PAZARCIK",
        "STREET": "EMİROĞLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "PAZARCIK",
        "STREET": "EVRİ PINARBAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "PAZARCIK",
        "STREET": "EVRİ TAŞBİÇME MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "PAZARCIK",
        "STREET": "EĞLEN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "PAZARCIK",
        "STREET": "EĞRİCE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "PAZARCIK",
        "STREET": "FATİH MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "PAZARCIK",
        "STREET": "GANİDAĞIKETİLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "PAZARCIK",
        "STREET": "GÖYNÜK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "PAZARCIK",
        "STREET": "GÖÇER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "PAZARCIK",
        "STREET": "HANOBASI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "PAZARCIK",
        "STREET": "HARMANCIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "PAZARCIK",
        "STREET": "HASANKOCA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "PAZARCIK",
        "STREET": "HÜRRİYET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "PAZARCIK",
        "STREET": "KADINCIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "PAZARCIK",
        "STREET": "KARAAĞAÇ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "PAZARCIK",
        "STREET": "KARABIYIKLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "PAZARCIK",
        "STREET": "KARAGÖL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "PAZARCIK",
        "STREET": "KARAHÜYÜK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "PAZARCIK",
        "STREET": "KARAÇAY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "PAZARCIK",
        "STREET": "KELEŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "PAZARCIK",
        "STREET": "KIZKAPANLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "PAZARCIK",
        "STREET": "KUZEYKENT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "PAZARCIK",
        "STREET": "KİZİRLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "PAZARCIK",
        "STREET": "MEHMET EMİN ARIKOĞLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "PAZARCIK",
        "STREET": "MEMİŞ ÖZDAL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "PAZARCIK",
        "STREET": "MEMİŞKAHYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "PAZARCIK",
        "STREET": "MENDERES MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "PAZARCIK",
        "STREET": "MEZERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "PAZARCIK",
        "STREET": "MUSOLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "PAZARCIK",
        "STREET": "NARLI BAHÇELİEVLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "PAZARCIK",
        "STREET": "NARLI CUMHURİYET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "PAZARCIK",
        "STREET": "NARLI İSMETPAŞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "PAZARCIK",
        "STREET": "NEFSİDOĞANLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "PAZARCIK",
        "STREET": "OSMANDEDE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "PAZARCIK",
        "STREET": "SADAKALAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "PAZARCIK",
        "STREET": "SAKARKAYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "PAZARCIK",
        "STREET": "SALLIUŞAĞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "PAZARCIK",
        "STREET": "SALMANIPAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "PAZARCIK",
        "STREET": "SALMANLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "PAZARCIK",
        "STREET": "SARIERİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "PAZARCIK",
        "STREET": "SARIL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "PAZARCIK",
        "STREET": "SOKU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "PAZARCIK",
        "STREET": "SULTANLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "PAZARCIK",
        "STREET": "TAŞDEMİR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "PAZARCIK",
        "STREET": "TETİRLİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "PAZARCIK",
        "STREET": "TURUNÇLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "PAZARCIK",
        "STREET": "TİLKİLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "PAZARCIK",
        "STREET": "UFACIKLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "PAZARCIK",
        "STREET": "ULUBAHÇE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "PAZARCIK",
        "STREET": "YARBAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "PAZARCIK",
        "STREET": "YEŞİLKENT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "PAZARCIK",
        "STREET": "YOLBOYU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "PAZARCIK",
        "STREET": "YUKARIHÖCÜKLÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "PAZARCIK",
        "STREET": "YUKARIMÜLK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "PAZARCIK",
        "STREET": "YUMAKLICERİT BAĞLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "PAZARCIK",
        "STREET": "YUMAKLICERİT CUMHURİYET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "PAZARCIK",
        "STREET": "YİĞİTLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "PAZARCIK",
        "STREET": "ÇAMLICA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "PAZARCIK",
        "STREET": "ÇAMLITEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "PAZARCIK",
        "STREET": "ÇÖÇELLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "PAZARCIK",
        "STREET": "ÇİÇEK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "PAZARCIK",
        "STREET": "ÇİÇEKALANI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "PAZARCIK",
        "STREET": "ÇİĞDEMTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "PAZARCIK",
        "STREET": "ÖRDEKDEDE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "PAZARCIK",
        "STREET": "İNCİRLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "PAZARCIK",
        "STREET": "ŞAHİNTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "PAZARCIK",
        "STREET": "ŞEHİT NURETTİN ADEMOĞLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "TÜRKOĞLU",
        "STREET": "AKÇALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "TÜRKOĞLU",
        "STREET": "AVŞARLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "TÜRKOĞLU",
        "STREET": "AYDINKAVAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "TÜRKOĞLU",
        "STREET": "BAYRAMGAZİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "TÜRKOĞLU",
        "STREET": "BEYOĞLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "TÜRKOĞLU",
        "STREET": "CECELİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "TÜRKOĞLU",
        "STREET": "CENNETPINARI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "TÜRKOĞLU",
        "STREET": "CUMHURİYET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "TÜRKOĞLU",
        "STREET": "DEDELER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "TÜRKOĞLU",
        "STREET": "DOLUCA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "TÜRKOĞLU",
        "STREET": "FATİH MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "TÜRKOĞLU",
        "STREET": "GAZİLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "TÜRKOĞLU",
        "STREET": "GAZİOSMANPAŞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "TÜRKOĞLU",
        "STREET": "GÜLLÜHÜYÜK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "TÜRKOĞLU",
        "STREET": "HACIBEBEK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "TÜRKOĞLU",
        "STREET": "HOPURLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "TÜRKOĞLU",
        "STREET": "KADIOĞLUÇİFTLİĞİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "TÜRKOĞLU",
        "STREET": "KALEDİBİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "TÜRKOĞLU",
        "STREET": "KELİBİŞLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "TÜRKOĞLU",
        "STREET": "KILILI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "TÜRKOĞLU",
        "STREET": "KIRMAKAYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "TÜRKOĞLU",
        "STREET": "KIZILENİŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "TÜRKOĞLU",
        "STREET": "KUMÇATI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "TÜRKOĞLU",
        "STREET": "KUYUMCULAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "TÜRKOĞLU",
        "STREET": "KÜÇÜKİMALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "TÜRKOĞLU",
        "STREET": "MİNEHÜYÜK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "TÜRKOĞLU",
        "STREET": "PINARHÜYÜK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "TÜRKOĞLU",
        "STREET": "TAHTALIDEDELER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "TÜRKOĞLU",
        "STREET": "UZUNSÖĞÜT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "TÜRKOĞLU",
        "STREET": "YAVUZLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "TÜRKOĞLU",
        "STREET": "YENİKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "TÜRKOĞLU",
        "STREET": "YENİPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "TÜRKOĞLU",
        "STREET": "YEŞİLYURT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "TÜRKOĞLU",
        "STREET": "YEŞİLYÖRE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "TÜRKOĞLU",
        "STREET": "YOLDERESİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "TÜRKOĞLU",
        "STREET": "ÇAKALLIHASANAĞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "TÜRKOĞLU",
        "STREET": "ÇAKALLIÇULLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "TÜRKOĞLU",
        "STREET": "ÇAKIROĞLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "TÜRKOĞLU",
        "STREET": "ÇOBANTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "TÜRKOĞLU",
        "STREET": "ÖNSENHOPURU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "TÜRKOĞLU",
        "STREET": "ÖZBEK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "TÜRKOĞLU",
        "STREET": "İMALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "TÜRKOĞLU",
        "STREET": "İSTASYON MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "TÜRKOĞLU",
        "STREET": "ŞEKEROBA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "ÇAĞLAYANCERİT",
        "STREET": "AKDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "ÇAĞLAYANCERİT",
        "STREET": "AKSU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "ÇAĞLAYANCERİT",
        "STREET": "BAYIRLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "ÇAĞLAYANCERİT",
        "STREET": "BOYLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "ÇAĞLAYANCERİT",
        "STREET": "BOZLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "ÇAĞLAYANCERİT",
        "STREET": "BÖLÜKDAMLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "ÇAĞLAYANCERİT",
        "STREET": "EMİRUŞAĞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "ÇAĞLAYANCERİT",
        "STREET": "ENGİZEK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "ÇAĞLAYANCERİT",
        "STREET": "FATİH MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "ÇAĞLAYANCERİT",
        "STREET": "HELETE CUMHURİYET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "ÇAĞLAYANCERİT",
        "STREET": "HELETE KARADAĞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "ÇAĞLAYANCERİT",
        "STREET": "HELETE YEŞİLOBA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "ÇAĞLAYANCERİT",
        "STREET": "KALE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "ÇAĞLAYANCERİT",
        "STREET": "KÜÇÜKCERİT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "ÇAĞLAYANCERİT",
        "STREET": "KÜÇÜKÜNGÜT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "ÇAĞLAYANCERİT",
        "STREET": "ORUÇPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "ÇAĞLAYANCERİT",
        "STREET": "SOĞUKPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "ÇAĞLAYANCERİT",
        "STREET": "ZEYNEPUŞAĞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAHRAMANMARAŞ",
        "DISTRICT": "ÇAĞLAYANCERİT",
        "STREET": "İSTİKLAL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "EFLANİ",
        "STREET": "ABAKOLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "EFLANİ",
        "STREET": "ADAY KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "EFLANİ",
        "STREET": "AFŞAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "EFLANİ",
        "STREET": "AKÇAKESE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "EFLANİ",
        "STREET": "AKÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "EFLANİ",
        "STREET": "ALAÇAT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "EFLANİ",
        "STREET": "ALPAGUT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "EFLANİ",
        "STREET": "BAKIRCILAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "EFLANİ",
        "STREET": "BAĞLICA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "EFLANİ",
        "STREET": "BAŞİĞDİR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "EFLANİ",
        "STREET": "BEDİL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "EFLANİ",
        "STREET": "BOSTANCI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "EFLANİ",
        "STREET": "BOSTANCILAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "EFLANİ",
        "STREET": "CANDAROĞULLARI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "EFLANİ",
        "STREET": "DEMİRLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "EFLANİ",
        "STREET": "EMİRLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "EFLANİ",
        "STREET": "ESENCİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "EFLANİ",
        "STREET": "GELİCEK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "EFLANİ",
        "STREET": "GÖKGÖZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "EFLANİ",
        "STREET": "GÖLLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "EFLANİ",
        "STREET": "GÜNGÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "EFLANİ",
        "STREET": "GÜNLÜCE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "EFLANİ",
        "STREET": "HACIAĞAÇ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "EFLANİ",
        "STREET": "HACIŞABAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "EFLANİ",
        "STREET": "HALKEVLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "EFLANİ",
        "STREET": "KARACAPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "EFLANİ",
        "STREET": "KARATAŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "EFLANİ",
        "STREET": "KARLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "EFLANİ",
        "STREET": "KAVAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "EFLANİ",
        "STREET": "KAYADİBİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "EFLANİ",
        "STREET": "KIRAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "EFLANİ",
        "STREET": "KOCACIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "EFLANİ",
        "STREET": "KOLTUCAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "EFLANİ",
        "STREET": "KUTLUÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "EFLANİ",
        "STREET": "MÜFTÜLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "EFLANİ",
        "STREET": "MÜLAYİM KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "EFLANİ",
        "STREET": "OSMANLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "EFLANİ",
        "STREET": "OVAÇALIŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "EFLANİ",
        "STREET": "OVAŞEYHLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "EFLANİ",
        "STREET": "PAŞABEY KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "EFLANİ",
        "STREET": "PINARÖZÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "EFLANİ",
        "STREET": "SARAYCIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "EFLANİ",
        "STREET": "SAÇAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "EFLANİ",
        "STREET": "SEFERLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "EFLANİ",
        "STREET": "SOĞUCAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "EFLANİ",
        "STREET": "TABAKLAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "EFLANİ",
        "STREET": "ULUGEÇİT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "EFLANİ",
        "STREET": "YALACIK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "EFLANİ",
        "STREET": "YAĞLICA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "EFLANİ",
        "STREET": "ÇAL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "EFLANİ",
        "STREET": "ÇALIŞLAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "EFLANİ",
        "STREET": "ÇAMYURT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "EFLANİ",
        "STREET": "ÇAVUŞLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "EFLANİ",
        "STREET": "ÇELEBİLER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "EFLANİ",
        "STREET": "ÇEMÇİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "EFLANİ",
        "STREET": "ÇENGELLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "EFLANİ",
        "STREET": "ÇUKURGELİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "EFLANİ",
        "STREET": "ÇUKURÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "EFLANİ",
        "STREET": "ÇÖREKLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "EFLANİ",
        "STREET": "ŞENYURT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "ESKİPAZAR",
        "STREET": "ADİLLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "ESKİPAZAR",
        "STREET": "ARSLANLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "ESKİPAZAR",
        "STREET": "BABALAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "ESKİPAZAR",
        "STREET": "BAHÇEPINAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "ESKİPAZAR",
        "STREET": "BAYINDIR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "ESKİPAZAR",
        "STREET": "BAŞPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "ESKİPAZAR",
        "STREET": "BELEN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "ESKİPAZAR",
        "STREET": "BEYTARLA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "ESKİPAZAR",
        "STREET": "BONCUKLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "ESKİPAZAR",
        "STREET": "BUDAKLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "ESKİPAZAR",
        "STREET": "BULDUK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "ESKİPAZAR",
        "STREET": "BÖLÜKÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "ESKİPAZAR",
        "STREET": "BÜYÜKYAYALAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "ESKİPAZAR",
        "STREET": "DERESEMAİL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "ESKİPAZAR",
        "STREET": "DERESOBLAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "ESKİPAZAR",
        "STREET": "DOĞANCILAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "ESKİPAZAR",
        "STREET": "DOĞLACIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "ESKİPAZAR",
        "STREET": "GÖZLÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "ESKİPAZAR",
        "STREET": "HAMAMLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "ESKİPAZAR",
        "STREET": "HAMZALAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "ESKİPAZAR",
        "STREET": "HANKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "ESKİPAZAR",
        "STREET": "HASANLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "ESKİPAZAR",
        "STREET": "HASLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "ESKİPAZAR",
        "STREET": "KABAARMUT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "ESKİPAZAR",
        "STREET": "KAPAKLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "ESKİPAZAR",
        "STREET": "KAPUCULAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "ESKİPAZAR",
        "STREET": "KARAHASANLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "ESKİPAZAR",
        "STREET": "KARAÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "ESKİPAZAR",
        "STREET": "KIRANKÖY MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "ESKİPAZAR",
        "STREET": "KULAT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "ESKİPAZAR",
        "STREET": "KUZÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "ESKİPAZAR",
        "STREET": "KÖYCEĞİZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "ESKİPAZAR",
        "STREET": "MERKEZ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "ESKİPAZAR",
        "STREET": "MERMER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "ESKİPAZAR",
        "STREET": "ORTAKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "ESKİPAZAR",
        "STREET": "OVA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "ESKİPAZAR",
        "STREET": "OZANKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "ESKİPAZAR",
        "STREET": "SADEYAKA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "ESKİPAZAR",
        "STREET": "SALLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "ESKİPAZAR",
        "STREET": "SOFULAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "ESKİPAZAR",
        "STREET": "SÖBÜÇİMEN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "ESKİPAZAR",
        "STREET": "TAMIŞLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "ESKİPAZAR",
        "STREET": "TOPÇALI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "ESKİPAZAR",
        "STREET": "YAMANLAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "ESKİPAZAR",
        "STREET": "YAZIBOY KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "ESKİPAZAR",
        "STREET": "YAZIKAVAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "ESKİPAZAR",
        "STREET": "YENİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "ESKİPAZAR",
        "STREET": "YEŞİLLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "ESKİPAZAR",
        "STREET": "YÜRECİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "ESKİPAZAR",
        "STREET": "ÇANDIRLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "ESKİPAZAR",
        "STREET": "ÇAYLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "ESKİPAZAR",
        "STREET": "ÇÖMLEKÇİLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "ESKİPAZAR",
        "STREET": "ÜÇEVLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "ESKİPAZAR",
        "STREET": "İMANLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "ESKİPAZAR",
        "STREET": "İNCEBOĞAZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "ESKİPAZAR",
        "STREET": "İSTASYON MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "ESKİPAZAR",
        "STREET": "ŞEVKİLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "MERKEZ",
        "STREET": "100.YIL MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "MERKEZ",
        "STREET": "5000 EVLER 75.YIL MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "MERKEZ",
        "STREET": "5000 EVLER BAHÇELİEVLER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "MERKEZ",
        "STREET": "5000 EVLER CUMHURİYET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "MERKEZ",
        "STREET": "ACIÖZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "MERKEZ",
        "STREET": "ADATEPE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "MERKEZ",
        "STREET": "ARICAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "MERKEZ",
        "STREET": "ATATÜRK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "MERKEZ",
        "STREET": "AYDINLIKEVLER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "MERKEZ",
        "STREET": "AŞAĞI KIZILCAÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "MERKEZ",
        "STREET": "BAYIR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "MERKEZ",
        "STREET": "BAŞKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "MERKEZ",
        "STREET": "BELENKÖY MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "MERKEZ",
        "STREET": "BOLKUŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "MERKEZ",
        "STREET": "BULAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "MERKEZ",
        "STREET": "BURUNSUZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "MERKEZ",
        "STREET": "BÜRNÜK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "MERKEZ",
        "STREET": "CEMALLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "MERKEZ",
        "STREET": "CUMAYANI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "MERKEZ",
        "STREET": "DAVUTLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "MERKEZ",
        "STREET": "DEMİRCİLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "MERKEZ",
        "STREET": "DÜZÇAM KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "MERKEZ",
        "STREET": "ERGENEKON MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "MERKEZ",
        "STREET": "FATİH MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "MERKEZ",
        "STREET": "FEVZİ ÇAKMAK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "MERKEZ",
        "STREET": "GÖLÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "MERKEZ",
        "STREET": "GÜNEŞLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "MERKEZ",
        "STREET": "HÜRRİYET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "MERKEZ",
        "STREET": "KADI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "MERKEZ",
        "STREET": "KAHYALAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "MERKEZ",
        "STREET": "KALE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "MERKEZ",
        "STREET": "KAMIŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "MERKEZ",
        "STREET": "KAPAKLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "MERKEZ",
        "STREET": "KAPULLU MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "MERKEZ",
        "STREET": "KARAAĞAÇ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "MERKEZ",
        "STREET": "KARABÜK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "MERKEZ",
        "STREET": "KARABÜK T TİPİ AÇIK VE KAPALI CEZA İNFAZ KURUMU",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "MERKEZ",
        "STREET": "KARAŞAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "MERKEZ",
        "STREET": "KARTALTEPE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "MERKEZ",
        "STREET": "KAYABAŞI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "MERKEZ",
        "STREET": "KAYI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "MERKEZ",
        "STREET": "KURTULUŞ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "MERKEZ",
        "STREET": "MEHTERLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "MERKEZ",
        "STREET": "MERKEZ-KILAVUZLAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "MERKEZ",
        "STREET": "NAMIK KEMAL MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "MERKEZ",
        "STREET": "ORTACA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "MERKEZ",
        "STREET": "SALMANLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "MERKEZ",
        "STREET": "SAİTLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "MERKEZ",
        "STREET": "SOĞUKSU MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "MERKEZ",
        "STREET": "SİPAHİLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "MERKEZ",
        "STREET": "TANDIR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "MERKEZ",
        "STREET": "YENİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "MERKEZ",
        "STREET": "YENİŞEHİR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "MERKEZ",
        "STREET": "YEŞİL MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "MERKEZ",
        "STREET": "YEŞİLKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "MERKEZ",
        "STREET": "YEŞİLTEPE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "MERKEZ",
        "STREET": "YUKARI KIZILCAÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "MERKEZ",
        "STREET": "ZOPRAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇERÇİLER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇUKURCA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "MERKEZ",
        "STREET": "ÖDEMİŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "MERKEZ",
        "STREET": "ÖĞLEBELİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "MERKEZ",
        "STREET": "ÜNİVERSİTE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "MERKEZ",
        "STREET": "ÜÇBAŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "MERKEZ",
        "STREET": "ŞENLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "MERKEZ",
        "STREET": "ŞİRİNEVLER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "OVACIK",
        "STREET": "ABDULLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "OVACIK",
        "STREET": "AHMETLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "OVACIK",
        "STREET": "ALINCA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "OVACIK",
        "STREET": "AMBARÖZÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "OVACIK",
        "STREET": "AVLAĞIKAYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "OVACIK",
        "STREET": "BAŞBOYUNDURUK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "OVACIK",
        "STREET": "BELEN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "OVACIK",
        "STREET": "BEYDİLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "OVACIK",
        "STREET": "BEYDİNİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "OVACIK",
        "STREET": "BODUROĞLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "OVACIK",
        "STREET": "BOYALI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "OVACIK",
        "STREET": "BÖLÜKÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "OVACIK",
        "STREET": "DOĞANLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "OVACIK",
        "STREET": "DUDAŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "OVACIK",
        "STREET": "DÖKECEK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "OVACIK",
        "STREET": "EKİNCİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "OVACIK",
        "STREET": "ERKEÇ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "OVACIK",
        "STREET": "GANİBEYLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "OVACIK",
        "STREET": "GÖKÇEDÜZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "OVACIK",
        "STREET": "GÜMELİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "OVACIK",
        "STREET": "GÜNEYSAZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "OVACIK",
        "STREET": "HATİPOĞLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "OVACIK",
        "STREET": "KAVAKLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "OVACIK",
        "STREET": "KIŞLA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "OVACIK",
        "STREET": "KOLTUK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "OVACIK",
        "STREET": "KÜÇÜKSU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "OVACIK",
        "STREET": "MERKEZ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "OVACIK",
        "STREET": "PELİTÇİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "OVACIK",
        "STREET": "PÜRÇÜKÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "OVACIK",
        "STREET": "SARILAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "OVACIK",
        "STREET": "SOFUOĞLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "OVACIK",
        "STREET": "SOĞANLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "OVACIK",
        "STREET": "SÜLÜK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "OVACIK",
        "STREET": "TAŞOĞLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "OVACIK",
        "STREET": "YAKA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "OVACIK",
        "STREET": "YAYLACILAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "OVACIK",
        "STREET": "YENİÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "OVACIK",
        "STREET": "YIĞINOT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "OVACIK",
        "STREET": "YÜREKÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "OVACIK",
        "STREET": "ÇATAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "OVACIK",
        "STREET": "ÇUKUR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "OVACIK",
        "STREET": "İMANLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "OVACIK",
        "STREET": "ŞAMLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "SAFRANBOLU",
        "STREET": "15 TEMMUZ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "SAFRANBOLU",
        "STREET": "AKKIŞLA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "SAFRANBOLU",
        "STREET": "AKÇASU MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "SAFRANBOLU",
        "STREET": "AKÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "SAFRANBOLU",
        "STREET": "ALÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "SAFRANBOLU",
        "STREET": "ATATÜRK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "SAFRANBOLU",
        "STREET": "AĞAÇKESE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "SAFRANBOLU",
        "STREET": "AŞAĞI TOKATLI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "SAFRANBOLU",
        "STREET": "AŞAĞIDANA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "SAFRANBOLU",
        "STREET": "AŞAĞIGÜNEY KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "SAFRANBOLU",
        "STREET": "AŞAĞIÇİFTLİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "SAFRANBOLU",
        "STREET": "BABASULTAN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "SAFRANBOLU",
        "STREET": "BARIŞ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "SAFRANBOLU",
        "STREET": "BAĞCIĞAZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "SAFRANBOLU",
        "STREET": "BAĞLARBAŞI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "SAFRANBOLU",
        "STREET": "BOSTANBÜKÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "SAFRANBOLU",
        "STREET": "CABBAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "SAFRANBOLU",
        "STREET": "CAMİKEBİR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "SAFRANBOLU",
        "STREET": "CEMALCAYMAZ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "SAFRANBOLU",
        "STREET": "CÜCAHLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "SAFRANBOLU",
        "STREET": "DANİŞMENT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "SAFRANBOLU",
        "STREET": "DAVUTOBASI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "SAFRANBOLU",
        "STREET": "DERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "SAFRANBOLU",
        "STREET": "DEĞİRMENCİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "SAFRANBOLU",
        "STREET": "DÜZCE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "SAFRANBOLU",
        "STREET": "EMEK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "SAFRANBOLU",
        "STREET": "ESENTEPE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "SAFRANBOLU",
        "STREET": "GAYZA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "SAFRANBOLU",
        "STREET": "GEREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "SAFRANBOLU",
        "STREET": "GÖKPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "SAFRANBOLU",
        "STREET": "GÜNDOĞAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "SAFRANBOLU",
        "STREET": "HACIHALİL MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "SAFRANBOLU",
        "STREET": "HACIHASAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "SAFRANBOLU",
        "STREET": "HACILAROBASI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "SAFRANBOLU",
        "STREET": "HARMANCIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "SAFRANBOLU",
        "STREET": "HÜSEYİNÇELEBİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "SAFRANBOLU",
        "STREET": "KADIBÜKÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "SAFRANBOLU",
        "STREET": "KARAALİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "SAFRANBOLU",
        "STREET": "KARACATEPE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "SAFRANBOLU",
        "STREET": "KARAPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "SAFRANBOLU",
        "STREET": "KARIT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "SAFRANBOLU",
        "STREET": "KEHLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "SAFRANBOLU",
        "STREET": "KIRIKLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "SAFRANBOLU",
        "STREET": "KONARI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "SAFRANBOLU",
        "STREET": "KUZYAKAHACILAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "SAFRANBOLU",
        "STREET": "KUZYAKAKÖSELER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "SAFRANBOLU",
        "STREET": "KUZYAKAÖTE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "SAFRANBOLU",
        "STREET": "KİRKİLLE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "SAFRANBOLU",
        "STREET": "MUSALLA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "SAFRANBOLU",
        "STREET": "NAVSAKLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "SAFRANBOLU",
        "STREET": "NEBİOĞLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "SAFRANBOLU",
        "STREET": "OVACUMA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "SAFRANBOLU",
        "STREET": "OVAKÖSELER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "SAFRANBOLU",
        "STREET": "OĞULÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "SAFRANBOLU",
        "STREET": "PELİTÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "SAFRANBOLU",
        "STREET": "SAKARALAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "SAFRANBOLU",
        "STREET": "SARIAHMETLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "SAFRANBOLU",
        "STREET": "SAT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "SAFRANBOLU",
        "STREET": "SIRÇALI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "SAFRANBOLU",
        "STREET": "SİNE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "SAFRANBOLU",
        "STREET": "TAYYİP KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "SAFRANBOLU",
        "STREET": "TOKATLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "SAFRANBOLU",
        "STREET": "TOPRAKCUMA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "SAFRANBOLU",
        "STREET": "TİNTİN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "SAFRANBOLU",
        "STREET": "YAZIKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "SAFRANBOLU",
        "STREET": "YENİMAHALLE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "SAFRANBOLU",
        "STREET": "YOLBAŞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "SAFRANBOLU",
        "STREET": "YUKARIDANA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "SAFRANBOLU",
        "STREET": "YUKARIÇİFTLİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "SAFRANBOLU",
        "STREET": "YÖRÜK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "SAFRANBOLU",
        "STREET": "ÇATAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "SAFRANBOLU",
        "STREET": "ÇAVUŞ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "SAFRANBOLU",
        "STREET": "ÇAVUŞLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "SAFRANBOLU",
        "STREET": "ÇERÇEN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "SAFRANBOLU",
        "STREET": "ÇEŞME MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "SAFRANBOLU",
        "STREET": "ÇIRAKLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "SAFRANBOLU",
        "STREET": "ÖRENCİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "SAFRANBOLU",
        "STREET": "ÜÇBÖLÜK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "SAFRANBOLU",
        "STREET": "İNCEÇAY KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "SAFRANBOLU",
        "STREET": "İSMETPAŞA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "SAFRANBOLU",
        "STREET": "İZZETPAŞA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "YENİCE",
        "STREET": "ABDULLAHOĞLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "YENİCE",
        "STREET": "AKMANLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "YENİCE",
        "STREET": "ATATÜRK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "YENİCE",
        "STREET": "BAĞBAŞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "YENİCE",
        "STREET": "CİHANBEY KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "YENİCE",
        "STREET": "DEREBAŞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "YENİCE",
        "STREET": "DEĞİRMENYANI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "YENİCE",
        "STREET": "ESENKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "YENİCE",
        "STREET": "FATİH MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "YENİCE",
        "STREET": "GÖKBEL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "YENİCE",
        "STREET": "GÜNEY KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "YENİCE",
        "STREET": "HÜSEYİNBEYOĞLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "YENİCE",
        "STREET": "HİSAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "YENİCE",
        "STREET": "IBRICAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "YENİCE",
        "STREET": "KADIKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "YENİCE",
        "STREET": "KALAYCILAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "YENİCE",
        "STREET": "KALE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "YENİCE",
        "STREET": "KARAHASANLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "YENİCE",
        "STREET": "KARAKIRIKLAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "YENİCE",
        "STREET": "KARŞIYAKA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "YENİCE",
        "STREET": "KAYAARKASI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "YENİCE",
        "STREET": "KAYADİBİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "YENİCE",
        "STREET": "KEYFALLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "YENİCE",
        "STREET": "KUZDAĞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "YENİCE",
        "STREET": "MENDERES MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "YENİCE",
        "STREET": "MERKEZ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "YENİCE",
        "STREET": "NODULLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "YENİCE",
        "STREET": "SARAY KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "YENİCE",
        "STREET": "SATUK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "YENİCE",
        "STREET": "TİR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "YENİCE",
        "STREET": "YAMAÇKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "YENİCE",
        "STREET": "YAZIKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "YENİCE",
        "STREET": "YENİKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "YENİCE",
        "STREET": "YEŞİLKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "YENİCE",
        "STREET": "YORTAN-ATATÜRK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "YENİCE",
        "STREET": "YORTAN-DERE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "YENİCE",
        "STREET": "YORTAN-DÖNGELLER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "YENİCE",
        "STREET": "YORTAN-KARAÖMERLER  MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "YENİCE",
        "STREET": "YORTAN-MERKEZ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "YENİCE",
        "STREET": "YORTAN-SİPAHİLER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "YENİCE",
        "STREET": "YORTAN-ÇENGELLER  MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "YENİCE",
        "STREET": "YİRMİBEŞLER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "YENİCE",
        "STREET": "YİRMİBEŞOĞLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "YENİCE",
        "STREET": "ÇAKILLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "YENİCE",
        "STREET": "ÇAMLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "YENİCE",
        "STREET": "ÇELTİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "YENİCE",
        "STREET": "ÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "YENİCE",
        "STREET": "ÖZAL MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "YENİCE",
        "STREET": "İSMETPAŞA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "YENİCE",
        "STREET": "ŞENKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "KARABÜK",
        "DISTRICT": "YENİCE",
        "STREET": "ŞİRİNKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "KARAMAN",
        "DISTRICT": "AYRANCI",
        "STREET": "AKPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARAMAN",
        "DISTRICT": "AYRANCI",
        "STREET": "AMBAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARAMAN",
        "DISTRICT": "AYRANCI",
        "STREET": "AĞIZBOĞAZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARAMAN",
        "DISTRICT": "AYRANCI",
        "STREET": "BERENDİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARAMAN",
        "DISTRICT": "AYRANCI",
        "STREET": "BUĞDAYLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARAMAN",
        "DISTRICT": "AYRANCI",
        "STREET": "BÖĞECİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARAMAN",
        "DISTRICT": "AYRANCI",
        "STREET": "BÜYÜKKORAŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARAMAN",
        "DISTRICT": "AYRANCI",
        "STREET": "DEDE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KARAMAN",
        "DISTRICT": "AYRANCI",
        "STREET": "DOKUZYOL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARAMAN",
        "DISTRICT": "AYRANCI",
        "STREET": "DİVLE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARAMAN",
        "DISTRICT": "AYRANCI",
        "STREET": "HÜYÜKBURUN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARAMAN",
        "DISTRICT": "AYRANCI",
        "STREET": "KALEKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "KARAMAN",
        "DISTRICT": "AYRANCI",
        "STREET": "KARAAĞAÇ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARAMAN",
        "DISTRICT": "AYRANCI",
        "STREET": "KAVAKÖZÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARAMAN",
        "DISTRICT": "AYRANCI",
        "STREET": "KAVUKLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARAMAN",
        "DISTRICT": "AYRANCI",
        "STREET": "KAYAÖNÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARAMAN",
        "DISTRICT": "AYRANCI",
        "STREET": "KIRAMAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARAMAN",
        "DISTRICT": "AYRANCI",
        "STREET": "KÜÇÜKKORAŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARAMAN",
        "DISTRICT": "AYRANCI",
        "STREET": "MELİKLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARAMAN",
        "DISTRICT": "AYRANCI",
        "STREET": "MUSA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KARAMAN",
        "DISTRICT": "AYRANCI",
        "STREET": "PINARKAYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARAMAN",
        "DISTRICT": "AYRANCI",
        "STREET": "SARAYKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "KARAMAN",
        "DISTRICT": "AYRANCI",
        "STREET": "ULU MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KARAMAN",
        "DISTRICT": "AYRANCI",
        "STREET": "YARIKKUYU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARAMAN",
        "DISTRICT": "AYRANCI",
        "STREET": "YENİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KARAMAN",
        "DISTRICT": "AYRANCI",
        "STREET": "ÇATKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "KARAMAN",
        "DISTRICT": "BAŞYAYLA",
        "STREET": "BAŞKÖY MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KARAMAN",
        "DISTRICT": "BAŞYAYLA",
        "STREET": "BOZYAKA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARAMAN",
        "DISTRICT": "BAŞYAYLA",
        "STREET": "BÜYÜKKARAPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARAMAN",
        "DISTRICT": "BAŞYAYLA",
        "STREET": "GÖZTEPE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KARAMAN",
        "DISTRICT": "BAŞYAYLA",
        "STREET": "KIŞLAKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "KARAMAN",
        "DISTRICT": "BAŞYAYLA",
        "STREET": "KİRAZLIYAYLA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KARAMAN",
        "DISTRICT": "BAŞYAYLA",
        "STREET": "YENİMAHALLE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KARAMAN",
        "DISTRICT": "BAŞYAYLA",
        "STREET": "ÜZÜMLÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARAMAN",
        "DISTRICT": "BAŞYAYLA",
        "STREET": "ŞİRİNDERE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KARAMAN",
        "DISTRICT": "ERMENEK",
        "STREET": "AKCAMESCİT MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KARAMAN",
        "DISTRICT": "ERMENEK",
        "STREET": "ARDIÇKAYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARAMAN",
        "DISTRICT": "ERMENEK",
        "STREET": "AĞAÇÇATI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARAMAN",
        "DISTRICT": "ERMENEK",
        "STREET": "AŞAĞIÇAĞLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARAMAN",
        "DISTRICT": "ERMENEK",
        "STREET": "BALKUSAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARAMAN",
        "DISTRICT": "ERMENEK",
        "STREET": "BAŞPINAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KARAMAN",
        "DISTRICT": "ERMENEK",
        "STREET": "BOYALIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARAMAN",
        "DISTRICT": "ERMENEK",
        "STREET": "DEĞİRMENLİK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KARAMAN",
        "DISTRICT": "ERMENEK",
        "STREET": "ELMAYURDU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARAMAN",
        "DISTRICT": "ERMENEK",
        "STREET": "ESKİCE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARAMAN",
        "DISTRICT": "ERMENEK",
        "STREET": "EVSİN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARAMAN",
        "DISTRICT": "ERMENEK",
        "STREET": "GÖKCESEKİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARAMAN",
        "DISTRICT": "ERMENEK",
        "STREET": "GÖKÇEKENT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARAMAN",
        "DISTRICT": "ERMENEK",
        "STREET": "GÖRMELİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARAMAN",
        "DISTRICT": "ERMENEK",
        "STREET": "GÜLLÜK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KARAMAN",
        "DISTRICT": "ERMENEK",
        "STREET": "GÜLPAZAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KARAMAN",
        "DISTRICT": "ERMENEK",
        "STREET": "GÜNEYYURT-ARALIK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KARAMAN",
        "DISTRICT": "ERMENEK",
        "STREET": "GÜNEYYURT-CAMİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KARAMAN",
        "DISTRICT": "ERMENEK",
        "STREET": "GÜNEYYURT-HABİB MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KARAMAN",
        "DISTRICT": "ERMENEK",
        "STREET": "GÜNEYYURT-KIŞLACIK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KARAMAN",
        "DISTRICT": "ERMENEK",
        "STREET": "GÜNEYYURT-ODA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KARAMAN",
        "DISTRICT": "ERMENEK",
        "STREET": "GÜNEYYURT-ORTA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KARAMAN",
        "DISTRICT": "ERMENEK",
        "STREET": "GÜNEYYURT-PINARGÖZÜ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KARAMAN",
        "DISTRICT": "ERMENEK",
        "STREET": "GÜNEYYURT-YENİMAHALLE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KARAMAN",
        "DISTRICT": "ERMENEK",
        "STREET": "KARALAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KARAMAN",
        "DISTRICT": "ERMENEK",
        "STREET": "KATRANLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARAMAN",
        "DISTRICT": "ERMENEK",
        "STREET": "KAYAÖNÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARAMAN",
        "DISTRICT": "ERMENEK",
        "STREET": "KAZANCI-BUCAK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KARAMAN",
        "DISTRICT": "ERMENEK",
        "STREET": "KAZANCI-GÖKCELER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KARAMAN",
        "DISTRICT": "ERMENEK",
        "STREET": "KAZANCI-MERKEZ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KARAMAN",
        "DISTRICT": "ERMENEK",
        "STREET": "KAZANCI-TEPECİK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KARAMAN",
        "DISTRICT": "ERMENEK",
        "STREET": "KAZANCI-TÜRBESEKİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KARAMAN",
        "DISTRICT": "ERMENEK",
        "STREET": "KAZANCI-ULUKÖY MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KARAMAN",
        "DISTRICT": "ERMENEK",
        "STREET": "KAZANCI-YUKARI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KARAMAN",
        "DISTRICT": "ERMENEK",
        "STREET": "KAZANCI-ÇATALBADEM MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KARAMAN",
        "DISTRICT": "ERMENEK",
        "STREET": "KEBENDİBİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KARAMAN",
        "DISTRICT": "ERMENEK",
        "STREET": "KEÇİPAZAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KARAMAN",
        "DISTRICT": "ERMENEK",
        "STREET": "KEŞİLLİK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KARAMAN",
        "DISTRICT": "ERMENEK",
        "STREET": "MEYDAN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KARAMAN",
        "DISTRICT": "ERMENEK",
        "STREET": "OLUKPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARAMAN",
        "DISTRICT": "ERMENEK",
        "STREET": "ORTA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KARAMAN",
        "DISTRICT": "ERMENEK",
        "STREET": "PAMUKLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARAMAN",
        "DISTRICT": "ERMENEK",
        "STREET": "PINARÖNÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARAMAN",
        "DISTRICT": "ERMENEK",
        "STREET": "SANDIKLI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KARAMAN",
        "DISTRICT": "ERMENEK",
        "STREET": "SARIVADİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARAMAN",
        "DISTRICT": "ERMENEK",
        "STREET": "SEYRAN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KARAMAN",
        "DISTRICT": "ERMENEK",
        "STREET": "SEYRAN MAH. M TİPİ KAPALI CEZA İNFAZ KURUMU",
        "TYPE": "0"
      },
      {
        "CITY": "KARAMAN",
        "DISTRICT": "ERMENEK",
        "STREET": "SUSAKLI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KARAMAN",
        "DISTRICT": "ERMENEK",
        "STREET": "TAŞBAŞI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KARAMAN",
        "DISTRICT": "ERMENEK",
        "STREET": "TEPEBAŞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARAMAN",
        "DISTRICT": "ERMENEK",
        "STREET": "YALINDAL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARAMAN",
        "DISTRICT": "ERMENEK",
        "STREET": "YAYLAPAZARI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARAMAN",
        "DISTRICT": "ERMENEK",
        "STREET": "YERBAĞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARAMAN",
        "DISTRICT": "ERMENEK",
        "STREET": "YEŞİLKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "KARAMAN",
        "DISTRICT": "ERMENEK",
        "STREET": "YUKARIÇAĞLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARAMAN",
        "DISTRICT": "ERMENEK",
        "STREET": "ÇAMLICA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARAMAN",
        "DISTRICT": "ERMENEK",
        "STREET": "ÇAVUŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARAMAN",
        "DISTRICT": "ERMENEK",
        "STREET": "ÇINARLISU MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KARAMAN",
        "DISTRICT": "ERMENEK",
        "STREET": "İKİZÇINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "ABBAS MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KARAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "ADA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "AHMET YESEVİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KARAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "AHİOSMAN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KARAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "AKPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "AKÇAALAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "AKÇAŞEHİR-ATATÜRK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KARAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "AKÇAŞEHİR-FATİH MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KARAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "AKÇAŞEHİR-GAZİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KARAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "AKÇAŞEHİR-HÜRRİYET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KARAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "ALACASULUK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KARAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "ALAÇATI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "ALİŞAHANE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KARAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "ATATÜRK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KARAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "AYBASTI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "AĞAÇYURDU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "AĞILÖNÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "AŞAĞIAKIN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "AŞAĞIKIZILCA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "BADEMLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "BAHÇELİEVLER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KARAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "BARUTKAVURAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "BAYIR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "BAŞAKŞEHİR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KARAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "BAŞKIŞLA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "BEYAZKENT MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KARAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "BEYDİLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "BOSTANÖZÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "BOYALI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "BOZKANDAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "BUCAKKIŞLA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "BURHAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "BURUNOBA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "BÖLÜKYAZI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "CEDİT MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KARAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "CERİT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "CUMHURİYET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KARAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "DAMLAPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "DAĞKONAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "DEMİRYURT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "DERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "DEĞİRMENBAŞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "DİNEK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "EKİNÖZÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "ELMADAĞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "ELMAŞEHİR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KARAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "EMİNLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "ERENKAVAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "EĞİLMEZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "FATİH MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KARAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "FENARİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KARAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "GAZİDÜKKAN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KARAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "GEVHER HATUN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KARAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "GÖKÇE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "GÖZTEPE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "GÖÇER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "GÜLDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "GÜLKAYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "GÜÇLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "HACICELAL MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KARAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "HAMİDİYE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "HAMİDİYE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KARAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "HÜRRİYET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KARAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "HİSAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KARAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "KALABA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "KAMENİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "KARACAÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "KARAMAN M TİPİ KAPALI CEZA EVİ KURUMU",
        "TYPE": "0"
      },
      {
        "CITY": "KARAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "KARAMANOĞLU MEHMET BEY MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KARAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "KAZIM KARABEKİR PAŞA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KARAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "KAŞOBA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "KILBASAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "KIRBAĞI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KARAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "KIZIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "KIZILLARAĞINI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "KIZILYAKA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "KOZLUBUCAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "KOÇAKDEDE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KARAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "KURTDERESİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "KURUCABEL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "KÜLHAN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KARAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "KİRİŞÇİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KARAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "KİSECİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "LALE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "LARENDE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KARAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "MADENŞEHRİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "MAHMUDİYE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KARAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "MANSURDEDE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KARAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "MEDRESELİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "MEHMET AKİF ERSOY MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KARAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "MESUDİYE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "MORCALI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "MURATDEDE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "MÜMİNE HATUN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KARAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "NARLIDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "NEFİSE SULTAN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KARAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "ORTAOBA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "OSMANGAZİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KARAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "OSMANİYE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "PAŞABAĞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "PINARBAŞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "PİRİREİS MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KARAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "RAUF DENKTAŞ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KARAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "SAKABAŞI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KARAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "SALUR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "SARIKAYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "SAZLIYAKA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "SEKİÇEŞME MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KARAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "SEYİTHASAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "SUDURAĞI-BAHÇELİEVLER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KARAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "SUDURAĞI-CUMHURİYET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KARAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "SUDURAĞI-KETENCİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KARAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "SÜLEYMANHACI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "SÜMER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KARAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "SİYAHSER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KARAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "TABDUK EMRE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KARAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "TAHSİN ÜNAL MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KARAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "TARLAÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "TAVŞANLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "TAŞKALE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "TOPUCAK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KARAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "URGAN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KARAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "VALİDE SULTAN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KARAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "YAZILI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "YENİMAHALLE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KARAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "YENİŞEHİR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KARAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "YEŞİLADA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KARAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "YEŞİLDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "YILANGÖMÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "YOLLARBAŞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "YUKARIAKIN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "YUKARIKIZILCA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "YUNUS EMRE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KARAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "YUNUSKENT MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KARAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "YUVATEPE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "ZEMBİLLİ ALİ EFENDİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KARAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "ZENGEN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "ZİYA GÖKALP MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KARAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇAKIRBAĞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇATAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇAVUŞPINARI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇELTEK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KARAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇOĞLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇUKUR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇUKURBAĞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇİMENKUYU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇİĞDEMLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "ÖZDEMİR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "ÜNİVERSİTE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KARAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "ÜÇBAŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "ÜÇKUYU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "İBRAHİM HAKKI KONYALI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KARAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "İHSANİYE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "İMARET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KARAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "İSLİHİSAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "ŞEYH EDEBALİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KARAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "ŞEYH ŞAMİL MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KARAMAN",
        "DISTRICT": "MERKEZ",
        "STREET": "ŞEYHLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARAMAN",
        "DISTRICT": "KAZIMKARABEKİR",
        "STREET": "AKARKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "KARAMAN",
        "DISTRICT": "KAZIMKARABEKİR",
        "STREET": "BOYACI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KARAMAN",
        "DISTRICT": "KAZIMKARABEKİR",
        "STREET": "EMSALHAYAT MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KARAMAN",
        "DISTRICT": "KAZIMKARABEKİR",
        "STREET": "EMİNETTİN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KARAMAN",
        "DISTRICT": "KAZIMKARABEKİR",
        "STREET": "KARALGAZİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARAMAN",
        "DISTRICT": "KAZIMKARABEKİR",
        "STREET": "KIZILKUYU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARAMAN",
        "DISTRICT": "KAZIMKARABEKİR",
        "STREET": "MECİDİYE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARAMAN",
        "DISTRICT": "KAZIMKARABEKİR",
        "STREET": "OBA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KARAMAN",
        "DISTRICT": "KAZIMKARABEKİR",
        "STREET": "PAZAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KARAMAN",
        "DISTRICT": "KAZIMKARABEKİR",
        "STREET": "SELÇUKLU MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KARAMAN",
        "DISTRICT": "KAZIMKARABEKİR",
        "STREET": "SUBAŞI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KARAMAN",
        "DISTRICT": "KAZIMKARABEKİR",
        "STREET": "SİNCİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARAMAN",
        "DISTRICT": "KAZIMKARABEKİR",
        "STREET": "TİMSAL MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KARAMAN",
        "DISTRICT": "KAZIMKARABEKİR",
        "STREET": "ÖZYURT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARAMAN",
        "DISTRICT": "SARIVELİLER",
        "STREET": "ADİLLER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KARAMAN",
        "DISTRICT": "SARIVELİLER",
        "STREET": "BAŞ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KARAMAN",
        "DISTRICT": "SARIVELİLER",
        "STREET": "CİVANDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARAMAN",
        "DISTRICT": "SARIVELİLER",
        "STREET": "CİVLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARAMAN",
        "DISTRICT": "SARIVELİLER",
        "STREET": "DARAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARAMAN",
        "DISTRICT": "SARIVELİLER",
        "STREET": "DUMLUGÖZE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARAMAN",
        "DISTRICT": "SARIVELİLER",
        "STREET": "GÖKTEPE-CUMHURİYET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KARAMAN",
        "DISTRICT": "SARIVELİLER",
        "STREET": "GÖKTEPE-ESENTEPE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KARAMAN",
        "DISTRICT": "SARIVELİLER",
        "STREET": "GÖKTEPE-GÖKSU MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KARAMAN",
        "DISTRICT": "SARIVELİLER",
        "STREET": "GÖKTEPE-GÜLPAZAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KARAMAN",
        "DISTRICT": "SARIVELİLER",
        "STREET": "GÖKTEPE-GÜNDER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KARAMAN",
        "DISTRICT": "SARIVELİLER",
        "STREET": "GÖKTEPE-YEŞİLYURT MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KARAMAN",
        "DISTRICT": "SARIVELİLER",
        "STREET": "GÖKTEPE-YILDIZ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KARAMAN",
        "DISTRICT": "SARIVELİLER",
        "STREET": "GÖKTEPE-ÇUKURBAĞ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KARAMAN",
        "DISTRICT": "SARIVELİLER",
        "STREET": "IŞIKLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARAMAN",
        "DISTRICT": "SARIVELİLER",
        "STREET": "KARAKAYA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KARAMAN",
        "DISTRICT": "SARIVELİLER",
        "STREET": "KOÇAŞLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARAMAN",
        "DISTRICT": "SARIVELİLER",
        "STREET": "KÜÇÜK KARAPINAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KARAMAN",
        "DISTRICT": "SARIVELİLER",
        "STREET": "MENÇEK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KARAMAN",
        "DISTRICT": "SARIVELİLER",
        "STREET": "MERKEZ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KARAMAN",
        "DISTRICT": "SARIVELİLER",
        "STREET": "ORTAKÖY MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KARAMAN",
        "DISTRICT": "SARIVELİLER",
        "STREET": "TURCALAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KARAMAN",
        "DISTRICT": "SARIVELİLER",
        "STREET": "ULUCAMİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KARAMAN",
        "DISTRICT": "SARIVELİLER",
        "STREET": "UĞURLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARAMAN",
        "DISTRICT": "SARIVELİLER",
        "STREET": "YEŞİLYURT MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KARAMAN",
        "DISTRICT": "SARIVELİLER",
        "STREET": "ÇAKILLAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "AKYAKA",
        "STREET": "AKBULAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "AKYAKA",
        "STREET": "ASLAHANE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "AKYAKA",
        "STREET": "BOYUNTAŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "AKYAKA",
        "STREET": "BÜYÜKDURDURAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "AKYAKA",
        "STREET": "BÜYÜKPİRVELİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "AKYAKA",
        "STREET": "CAMUŞLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "AKYAKA",
        "STREET": "CEBECİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "AKYAKA",
        "STREET": "DEMİRKENT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "AKYAKA",
        "STREET": "DURAKLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "AKYAKA",
        "STREET": "ESENYAYLA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "AKYAKA",
        "STREET": "GEÇİTKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "AKYAKA",
        "STREET": "HACIPİRİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "AKYAKA",
        "STREET": "KALKANKALE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "AKYAKA",
        "STREET": "KARAHAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "AKYAKA",
        "STREET": "KAYADÖVEN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "AKYAKA",
        "STREET": "KAYAKÖPRÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "AKYAKA",
        "STREET": "KÜREKDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "AKYAKA",
        "STREET": "KÜÇÜKAKÜZÜM KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "AKYAKA",
        "STREET": "KÜÇÜKDURDURAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "AKYAKA",
        "STREET": "SULAKBAHÇE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "AKYAKA",
        "STREET": "SÜNGÜDERESİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "AKYAKA",
        "STREET": "TEPE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "AKYAKA",
        "STREET": "TOPKAYA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "AKYAKA",
        "STREET": "YERLİKAVAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "AKYAKA",
        "STREET": "ÇETİNDURAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "AKYAKA",
        "STREET": "ÜÇPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "AKYAKA",
        "STREET": "İBİŞKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "AKYAKA",
        "STREET": "İNCEDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "AKYAKA",
        "STREET": "İSTASYON MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "AKYAKA",
        "STREET": "ŞAHNALAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "ARPAÇAY",
        "STREET": "AKMAZDAM KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "ARPAÇAY",
        "STREET": "AKÇAKALE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "ARPAÇAY",
        "STREET": "AKÇALAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "ARPAÇAY",
        "STREET": "ARSLANOĞLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "ARPAÇAY",
        "STREET": "ATCILAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "ARPAÇAY",
        "STREET": "AYDINGÜN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "ARPAÇAY",
        "STREET": "BACIOĞLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "ARPAÇAY",
        "STREET": "BAHÇELER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "ARPAÇAY",
        "STREET": "BARDAKLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "ARPAÇAY",
        "STREET": "BOZYİĞİT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "ARPAÇAY",
        "STREET": "BURCALI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "ARPAÇAY",
        "STREET": "BÜYÜKÇATMA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "ARPAÇAY",
        "STREET": "CARCI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "ARPAÇAY",
        "STREET": "CARCIOĞLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "ARPAÇAY",
        "STREET": "DAĞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "ARPAÇAY",
        "STREET": "DEĞİRMENKÖPRÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "ARPAÇAY",
        "STREET": "DOĞRUYOL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "ARPAÇAY",
        "STREET": "GEDİKSATILMIŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "ARPAÇAY",
        "STREET": "GÖLDALI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "ARPAÇAY",
        "STREET": "GÖNÜLALAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "ARPAÇAY",
        "STREET": "GÜLYÜZÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "ARPAÇAY",
        "STREET": "GÜVERCİN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "ARPAÇAY",
        "STREET": "HASANÇAVUŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "ARPAÇAY",
        "STREET": "KAKAÇ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "ARPAÇAY",
        "STREET": "KARAKALE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "ARPAÇAY",
        "STREET": "KARAURGAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "ARPAÇAY",
        "STREET": "KARDEŞTEPE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "ARPAÇAY",
        "STREET": "KIRAÇ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "ARPAÇAY",
        "STREET": "KOÇKÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "ARPAÇAY",
        "STREET": "KUYUCUK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "ARPAÇAY",
        "STREET": "KUZGUNLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "ARPAÇAY",
        "STREET": "KÜMBET KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "ARPAÇAY",
        "STREET": "KÜÇÜKBOĞAZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "ARPAÇAY",
        "STREET": "KÜÇÜKÇATMA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "ARPAÇAY",
        "STREET": "MELİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "ARPAÇAY",
        "STREET": "MESCİTLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "ARPAÇAY",
        "STREET": "MEYDANCIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "ARPAÇAY",
        "STREET": "OKÇUOĞLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "ARPAÇAY",
        "STREET": "ORTA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "ARPAÇAY",
        "STREET": "POLATKÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "ARPAÇAY",
        "STREET": "SÖĞÜTLÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "ARPAÇAY",
        "STREET": "TAŞBAŞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "ARPAÇAY",
        "STREET": "TAŞDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "ARPAÇAY",
        "STREET": "TAŞKÖPRÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "ARPAÇAY",
        "STREET": "TAŞLIAĞIL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "ARPAÇAY",
        "STREET": "TELEK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "ARPAÇAY",
        "STREET": "TEPECİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "ARPAÇAY",
        "STREET": "TEPEKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "ARPAÇAY",
        "STREET": "TOMARLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "ARPAÇAY",
        "STREET": "YALINÇAYIR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "ARPAÇAY",
        "STREET": "YENİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "ARPAÇAY",
        "STREET": "ÇANAKSU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "DİGOR",
        "STREET": "ALEM KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "DİGOR",
        "STREET": "ARPALI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "DİGOR",
        "STREET": "AŞAĞIBAŞKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "DİGOR",
        "STREET": "BACALI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "DİGOR",
        "STREET": "BAYIRBAĞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "DİGOR",
        "STREET": "BAŞKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "DİGOR",
        "STREET": "BOSTANKALE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "DİGOR",
        "STREET": "CELALKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "DİGOR",
        "STREET": "DAĞPINAR-MERKEZ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "DİGOR",
        "STREET": "DERİNÖZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "DİGOR",
        "STREET": "DOLAYLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "DİGOR",
        "STREET": "DÜZGEÇİT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "DİGOR",
        "STREET": "EREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "DİGOR",
        "STREET": "GÜLHAYRAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "DİGOR",
        "STREET": "HALIKIŞLAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "DİGOR",
        "STREET": "HASANCAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "DİGOR",
        "STREET": "HİSARÖNÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "DİGOR",
        "STREET": "KARABAĞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "DİGOR",
        "STREET": "KARAKALE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "DİGOR",
        "STREET": "KOCAKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "DİGOR",
        "STREET": "KÖSELER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "DİGOR",
        "STREET": "KİLİTTAŞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "DİGOR",
        "STREET": "MAHİRBEY KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "DİGOR",
        "STREET": "MERKEZ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "DİGOR",
        "STREET": "OYUKLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "DİGOR",
        "STREET": "SAKLICA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "DİGOR",
        "STREET": "SORGUÇKAVAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "DİGOR",
        "STREET": "SORKUNLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "DİGOR",
        "STREET": "TÜRKMEŞEN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "DİGOR",
        "STREET": "UZUNKAYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "DİGOR",
        "STREET": "VARLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "DİGOR",
        "STREET": "YAYLACIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "DİGOR",
        "STREET": "YAĞLICA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "DİGOR",
        "STREET": "YEMENÇAYIR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "DİGOR",
        "STREET": "YENİKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "DİGOR",
        "STREET": "ÇATAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "DİGOR",
        "STREET": "ŞATIROĞLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "DİGOR",
        "STREET": "ŞENOL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "DİGOR",
        "STREET": "ŞİRİNKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "MERKEZ",
        "STREET": "30 EKİM MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "MERKEZ",
        "STREET": "AKBABA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "MERKEZ",
        "STREET": "AKDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "MERKEZ",
        "STREET": "ALACA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "MERKEZ",
        "STREET": "ALPASLAN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "MERKEZ",
        "STREET": "ALÇILI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "MERKEZ",
        "STREET": "ANİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "MERKEZ",
        "STREET": "ARAZOĞLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "MERKEZ",
        "STREET": "ARSLANİZİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "MERKEZ",
        "STREET": "ATAKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "MERKEZ",
        "STREET": "ATATÜRK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "MERKEZ",
        "STREET": "ATAYURT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "MERKEZ",
        "STREET": "AYAKGEDİKLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "MERKEZ",
        "STREET": "AYDINALAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "MERKEZ",
        "STREET": "AYDINLIKEVLER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "MERKEZ",
        "STREET": "AZAT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "MERKEZ",
        "STREET": "AĞADEVE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "MERKEZ",
        "STREET": "BAHÇELİEVLER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "MERKEZ",
        "STREET": "BAYRAKTAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "MERKEZ",
        "STREET": "BAYRAMPAŞA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "MERKEZ",
        "STREET": "BAŞGEDİKLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "MERKEZ",
        "STREET": "BAŞKAYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "MERKEZ",
        "STREET": "BEKLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "MERKEZ",
        "STREET": "BORLUK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "MERKEZ",
        "STREET": "BOZKALE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "MERKEZ",
        "STREET": "BOĞATEPE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "MERKEZ",
        "STREET": "BOĞAZKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "MERKEZ",
        "STREET": "BULANIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "MERKEZ",
        "STREET": "BÜLBÜL MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "MERKEZ",
        "STREET": "BÜYÜKAKÜZÜM KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "MERKEZ",
        "STREET": "CUMHURİYET KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "MERKEZ",
        "STREET": "CUMHURİYET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "MERKEZ",
        "STREET": "DAVUL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "MERKEZ",
        "STREET": "DERECİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "MERKEZ",
        "STREET": "DİKME KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "MERKEZ",
        "STREET": "ESENKENT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "MERKEZ",
        "STREET": "ESENYAZI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "MERKEZ",
        "STREET": "EŞMEYAZI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "MERKEZ",
        "STREET": "FEVZİÇAKMAK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "MERKEZ",
        "STREET": "GELİRLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "MERKEZ",
        "STREET": "GÜDELİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "MERKEZ",
        "STREET": "HACIHALİL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "MERKEZ",
        "STREET": "HACIVELİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "MERKEZ",
        "STREET": "HAFIZPAŞA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "MERKEZ",
        "STREET": "HALEFOĞLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "MERKEZ",
        "STREET": "HALİTPAŞA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "MERKEZ",
        "STREET": "HAMZAGEREK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "MERKEZ",
        "STREET": "HAPANLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "MERKEZ",
        "STREET": "HASÇİFTLİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "MERKEZ",
        "STREET": "KALEİÇİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "MERKEZ",
        "STREET": "KARACAÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "MERKEZ",
        "STREET": "KARADAĞ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "MERKEZ",
        "STREET": "KARAKALE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "MERKEZ",
        "STREET": "KARAKAŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "MERKEZ",
        "STREET": "KARAÇOBAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "MERKEZ",
        "STREET": "KARS KAPALI CEZAEVİ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "MERKEZ",
        "STREET": "KOCABAHÇE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "MERKEZ",
        "STREET": "KOZLUCA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "MERKEZ",
        "STREET": "KÜLVEREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "MERKEZ",
        "STREET": "KÜMBETLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "MERKEZ",
        "STREET": "KÜÇÜKBOĞATEPE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "MERKEZ",
        "STREET": "KÜÇÜKPİRVELİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "MERKEZ",
        "STREET": "KÜÇÜKYUSUF KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "MERKEZ",
        "STREET": "MAKSUTÇUK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "MERKEZ",
        "STREET": "MERKEZ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "MERKEZ",
        "STREET": "MEZRAA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "MERKEZ",
        "STREET": "ORTAGEDİKLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "MERKEZ",
        "STREET": "ORTAKAPI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "MERKEZ",
        "STREET": "OĞUZLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "MERKEZ",
        "STREET": "PAŞAÇAYIRI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "MERKEZ",
        "STREET": "SOYLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "MERKEZ",
        "STREET": "SUBATAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "MERKEZ",
        "STREET": "SUKAPI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "MERKEZ",
        "STREET": "SÖĞÜTLÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "MERKEZ",
        "STREET": "TAZEKENT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "MERKEZ",
        "STREET": "TEKNELİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "MERKEZ",
        "STREET": "VERİMLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "MERKEZ",
        "STREET": "YALINKAYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "MERKEZ",
        "STREET": "YALÇINLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "MERKEZ",
        "STREET": "YAĞIKESEN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "MERKEZ",
        "STREET": "YENİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "MERKEZ",
        "STREET": "YENİŞEHİR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "MERKEZ",
        "STREET": "YILANLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "MERKEZ",
        "STREET": "YUSUFPAŞA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "MERKEZ",
        "STREET": "YÜCELEN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇAKMAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇAMURLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇAĞLAYAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇERME KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇIĞIRGAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇORAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "MERKEZ",
        "STREET": "ÖLÇÜLÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "MERKEZ",
        "STREET": "ÖRNEK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "MERKEZ",
        "STREET": "ÜÇBÖLÜK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "MERKEZ",
        "STREET": "İSTASYON MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "MERKEZ",
        "STREET": "ŞEHİTLER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "KAĞIZMAN",
        "STREET": "AKYAYLA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "KAĞIZMAN",
        "STREET": "AKÇAKALE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "KAĞIZMAN",
        "STREET": "AKÇAY KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "KAĞIZMAN",
        "STREET": "AKÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "KAĞIZMAN",
        "STREET": "ALTUNGEDİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "KAĞIZMAN",
        "STREET": "AYDINKAVAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "KAĞIZMAN",
        "STREET": "AĞDAM KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "KAĞIZMAN",
        "STREET": "AŞAĞI KÜMBET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "KAĞIZMAN",
        "STREET": "AŞAĞIDERE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "KAĞIZMAN",
        "STREET": "AŞAĞIKARAGÜNEY KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "KAĞIZMAN",
        "STREET": "AŞAĞITUT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "KAĞIZMAN",
        "STREET": "BAYAM MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "KAĞIZMAN",
        "STREET": "BULANIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "KAĞIZMAN",
        "STREET": "BÜCÜKLÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "KAĞIZMAN",
        "STREET": "CAMUŞLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "KAĞIZMAN",
        "STREET": "DENİZGÖLÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "KAĞIZMAN",
        "STREET": "DEVEBÜK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "KAĞIZMAN",
        "STREET": "DEĞİRMENDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "KAĞIZMAN",
        "STREET": "DONANDI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "KAĞIZMAN",
        "STREET": "DURANLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "KAĞIZMAN",
        "STREET": "DİBEKKAYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "KAĞIZMAN",
        "STREET": "ESENKIR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "KAĞIZMAN",
        "STREET": "GÜMÜŞTEPE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "KAĞIZMAN",
        "STREET": "GÜNİNDİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "KAĞIZMAN",
        "STREET": "GÜNİNDİYAYLASI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "KAĞIZMAN",
        "STREET": "HAZNEDAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "KAĞIZMAN",
        "STREET": "KARABAĞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "KAĞIZMAN",
        "STREET": "KARABONCUK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "KAĞIZMAN",
        "STREET": "KARACAÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "KAĞIZMAN",
        "STREET": "KARAKALE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "KAĞIZMAN",
        "STREET": "KARAKUŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "KAĞIZMAN",
        "STREET": "KEŞİŞKIRAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "KAĞIZMAN",
        "STREET": "KOZLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "KAĞIZMAN",
        "STREET": "KULOĞLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "KAĞIZMAN",
        "STREET": "KURUYAYLA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "KAĞIZMAN",
        "STREET": "KÖMÜRLÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "KAĞIZMAN",
        "STREET": "KÖRPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "KAĞIZMAN",
        "STREET": "KÖTEK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "KAĞIZMAN",
        "STREET": "ORTAKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "KAĞIZMAN",
        "STREET": "PASLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "KAĞIZMAN",
        "STREET": "TAŞBURUN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "KAĞIZMAN",
        "STREET": "TAŞBİLEK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "KAĞIZMAN",
        "STREET": "TOMRUKTAŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "KAĞIZMAN",
        "STREET": "TOPRAKKALE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "KAĞIZMAN",
        "STREET": "TUNÇKAYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "KAĞIZMAN",
        "STREET": "YALNIZAĞAÇ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "KAĞIZMAN",
        "STREET": "YANKIPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "KAĞIZMAN",
        "STREET": "YAĞLICA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "KAĞIZMAN",
        "STREET": "YELLİKIRAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "KAĞIZMAN",
        "STREET": "YENİCE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "KAĞIZMAN",
        "STREET": "YOLKORUR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "KAĞIZMAN",
        "STREET": "YUKARI DERE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "KAĞIZMAN",
        "STREET": "YUKARI KÜMBET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "KAĞIZMAN",
        "STREET": "YUKARIKARAGÜNEY KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "KAĞIZMAN",
        "STREET": "ZUVAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "KAĞIZMAN",
        "STREET": "ÇALLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "KAĞIZMAN",
        "STREET": "ÇAYARASI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "KAĞIZMAN",
        "STREET": "ÇAYBÜK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "KAĞIZMAN",
        "STREET": "ÇENGİLLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "KAĞIZMAN",
        "STREET": "ÇEPERLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "KAĞIZMAN",
        "STREET": "ÇUKURAYVA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "KAĞIZMAN",
        "STREET": "ÇİLEHANE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "KAĞIZMAN",
        "STREET": "ÇİÇEKLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "KAĞIZMAN",
        "STREET": "ÜRKER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "KAĞIZMAN",
        "STREET": "ŞABAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "KAĞIZMAN",
        "STREET": "ŞAHİNDERE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "SARIKAMIŞ",
        "STREET": "AKKOZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "SARIKAMIŞ",
        "STREET": "AKÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "SARIKAMIŞ",
        "STREET": "ALTINBULAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "SARIKAMIŞ",
        "STREET": "ALİSOFU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "SARIKAMIŞ",
        "STREET": "ARMUTLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "SARIKAMIŞ",
        "STREET": "ASBUĞA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "SARIKAMIŞ",
        "STREET": "AŞAĞISALLIPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "SARIKAMIŞ",
        "STREET": "BALABANTAŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "SARIKAMIŞ",
        "STREET": "BALIKLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "SARIKAMIŞ",
        "STREET": "BAŞKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "SARIKAMIŞ",
        "STREET": "BELENCİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "SARIKAMIŞ",
        "STREET": "BEŞYOL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "SARIKAMIŞ",
        "STREET": "BOYALI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "SARIKAMIŞ",
        "STREET": "BOZAT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "SARIKAMIŞ",
        "STREET": "CUMHURİYET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "SARIKAMIŞ",
        "STREET": "ERENLER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "SARIKAMIŞ",
        "STREET": "EŞMEÇAYIR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "SARIKAMIŞ",
        "STREET": "GECİKMEZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "SARIKAMIŞ",
        "STREET": "GÜLLÜCE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "SARIKAMIŞ",
        "STREET": "HAMAMLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "SARIKAMIŞ",
        "STREET": "HANDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "SARIKAMIŞ",
        "STREET": "KALEBAŞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "SARIKAMIŞ",
        "STREET": "KARAKURT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "SARIKAMIŞ",
        "STREET": "KARAKÖSE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "SARIKAMIŞ",
        "STREET": "KARAPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "SARIKAMIŞ",
        "STREET": "KARAURGAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "SARIKAMIŞ",
        "STREET": "KAYALIBOĞAZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "SARIKAMIŞ",
        "STREET": "KAZANTAŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "SARIKAMIŞ",
        "STREET": "KAZIKKAYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "SARIKAMIŞ",
        "STREET": "KAZIMKARABEKİR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "SARIKAMIŞ",
        "STREET": "KOZAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "SARIKAMIŞ",
        "STREET": "KOÇOĞLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "SARIKAMIŞ",
        "STREET": "KURBANÇAYIR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "SARIKAMIŞ",
        "STREET": "KÖROĞLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "SARIKAMIŞ",
        "STREET": "MESCİTLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "SARIKAMIŞ",
        "STREET": "ODALAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "SARIKAMIŞ",
        "STREET": "ORTAKALE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "SARIKAMIŞ",
        "STREET": "PARMAKDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "SARIKAMIŞ",
        "STREET": "SIRATAŞLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "SARIKAMIŞ",
        "STREET": "SIRBASAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "SARIKAMIŞ",
        "STREET": "SÜNGÜTAŞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "SARIKAMIŞ",
        "STREET": "TAŞLIGÜNEY KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "SARIKAMIŞ",
        "STREET": "TEPE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "SARIKAMIŞ",
        "STREET": "TOPKAYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "SARIKAMIŞ",
        "STREET": "UZUNGAZİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "SARIKAMIŞ",
        "STREET": "YARKAYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "SARIKAMIŞ",
        "STREET": "YAYIKLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "SARIKAMIŞ",
        "STREET": "YAĞBASAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "SARIKAMIŞ",
        "STREET": "YENİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "SARIKAMIŞ",
        "STREET": "YENİGAZİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "SARIKAMIŞ",
        "STREET": "YENİKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "SARIKAMIŞ",
        "STREET": "YUKARISALLIPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "SARIKAMIŞ",
        "STREET": "ÇAMYAZI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "SARIKAMIŞ",
        "STREET": "ÇARDAKÇATI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "SARIKAMIŞ",
        "STREET": "ÇATAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "SARIKAMIŞ",
        "STREET": "ÇOLAKLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "SARIKAMIŞ",
        "STREET": "İNKAYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "SARIKAMIŞ",
        "STREET": "İNÖNÜ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "SARIKAMIŞ",
        "STREET": "İSTASYON MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "SARIKAMIŞ",
        "STREET": "İSTİKLAL MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "SARIKAMIŞ",
        "STREET": "İSİSU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "SARIKAMIŞ",
        "STREET": "ŞEHİTEMİN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "SARIKAMIŞ",
        "STREET": "ŞEHİTHALİT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "SARIKAMIŞ",
        "STREET": "ŞEHİTLER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "SELİM",
        "STREET": "AKPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "SELİM",
        "STREET": "AKYAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "SELİM",
        "STREET": "AKÇAKALE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "SELİM",
        "STREET": "ALİSOFU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "SELİM",
        "STREET": "AŞAĞIDAMLAPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "SELİM",
        "STREET": "AŞAĞIKOTANLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "SELİM",
        "STREET": "BAYBURT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "SELİM",
        "STREET": "BAYKARA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "SELİM",
        "STREET": "BAŞKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "SELİM",
        "STREET": "BENLİAHMET KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "SELİM",
        "STREET": "BEYKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "SELİM",
        "STREET": "BOZKUŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "SELİM",
        "STREET": "BÖLÜKBAŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "SELİM",
        "STREET": "BÜYÜK OLUKLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "SELİM",
        "STREET": "BÜYÜKDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "SELİM",
        "STREET": "CAVLAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "SELİM",
        "STREET": "CUMHURİYET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "SELİM",
        "STREET": "DARBOĞAZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "SELİM",
        "STREET": "DÖLBENTLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "SELİM",
        "STREET": "ESKİGAZİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "SELİM",
        "STREET": "ESKİGEÇİT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "SELİM",
        "STREET": "GELİNALAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "SELİM",
        "STREET": "GÜRBÜZLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "SELİM",
        "STREET": "HASBEY KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "SELİM",
        "STREET": "KAMIŞLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "SELİM",
        "STREET": "KARAHAMZA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "SELİM",
        "STREET": "KARAKALE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "SELİM",
        "STREET": "KARAÇAYIR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "SELİM",
        "STREET": "KATRANLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "SELİM",
        "STREET": "KAYNARLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "SELİM",
        "STREET": "KEKEÇ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "SELİM",
        "STREET": "KIRKPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "SELİM",
        "STREET": "KOYUNYURDU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "SELİM",
        "STREET": "KOŞAPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "SELİM",
        "STREET": "KÖPRÜBAŞI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "SELİM",
        "STREET": "LALOĞLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "SELİM",
        "STREET": "MOLLAMUSTAFA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "SELİM",
        "STREET": "OLUKLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "SELİM",
        "STREET": "ORTAKALE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "SELİM",
        "STREET": "SARIGÜN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "SELİM",
        "STREET": "SÖĞÜTLÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "SELİM",
        "STREET": "TOZLUCA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "SELİM",
        "STREET": "TUYGUN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "SELİM",
        "STREET": "YALNIZÇAM KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "SELİM",
        "STREET": "YAMAÇLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "SELİM",
        "STREET": "YASSICA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "SELİM",
        "STREET": "YAYLACIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "SELİM",
        "STREET": "YENİCE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "SELİM",
        "STREET": "YEŞİLTEPE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "SELİM",
        "STREET": "YOLGEÇMEZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "SELİM",
        "STREET": "YUKARIDAMLAPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "SELİM",
        "STREET": "YUKARIKOTANLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "SELİM",
        "STREET": "ÇARŞI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "SELİM",
        "STREET": "ÇAYBAŞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "SELİM",
        "STREET": "ÇIPLAKLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "SELİM",
        "STREET": "İĞDİR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "SUSUZ",
        "STREET": "AKSU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "SUSUZ",
        "STREET": "AYNALI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "SUSUZ",
        "STREET": "BÜYÜKÇATAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "SUSUZ",
        "STREET": "CUMHURİYET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "SUSUZ",
        "STREET": "DOYUMLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "SUSUZ",
        "STREET": "ERDAĞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "SUSUZ",
        "STREET": "ERMİŞLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "SUSUZ",
        "STREET": "GÖLBAŞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "SUSUZ",
        "STREET": "HARMANLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "SUSUZ",
        "STREET": "KALECİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "SUSUZ",
        "STREET": "KARAPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "SUSUZ",
        "STREET": "KAYADİBİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "SUSUZ",
        "STREET": "KAYALIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "SUSUZ",
        "STREET": "KAZIM KARABEKİR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "SUSUZ",
        "STREET": "KEÇİLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "SUSUZ",
        "STREET": "KIRKPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "SUSUZ",
        "STREET": "KIRÇİÇEĞİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "SUSUZ",
        "STREET": "KURUGÖL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "SUSUZ",
        "STREET": "KÜÇÜKÇATAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "SUSUZ",
        "STREET": "KİZİROĞLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "SUSUZ",
        "STREET": "ORTALAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "SUSUZ",
        "STREET": "PORSUKLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "SUSUZ",
        "STREET": "TAŞLICA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "SUSUZ",
        "STREET": "YAYLACIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "SUSUZ",
        "STREET": "YOLBOYU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "SUSUZ",
        "STREET": "ÇAMÇAVUŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "SUSUZ",
        "STREET": "ÇIĞRIKLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "SUSUZ",
        "STREET": "İNCESU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "SUSUZ",
        "STREET": "İNCİLİPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "SUSUZ",
        "STREET": "İNKILAP MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KARS",
        "DISTRICT": "SUSUZ",
        "STREET": "İNÖNÜ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "ABANA",
        "STREET": "AKÇAM KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "ABANA",
        "STREET": "ALTIKULAÇ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "ABANA",
        "STREET": "BAĞLIK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "ABANA",
        "STREET": "DENİZBÜKÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "ABANA",
        "STREET": "ELMAÇUKURU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "ABANA",
        "STREET": "GÖYNÜKLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "ABANA",
        "STREET": "HACIVELİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "ABANA",
        "STREET": "KADIYUSUF KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "ABANA",
        "STREET": "KONAKÖREN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "ABANA",
        "STREET": "MERKEZ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "ABANA",
        "STREET": "YAKABAŞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "ABANA",
        "STREET": "YEMENİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "ABANA",
        "STREET": "YEŞİLYUVA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "ABANA",
        "STREET": "ÇAMPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "ABANA",
        "STREET": "ÇAYIRCIK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "ARAÇ",
        "STREET": "AHATLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "ARAÇ",
        "STREET": "AKGEÇİT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "ARAÇ",
        "STREET": "AKINCILAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "ARAÇ",
        "STREET": "AKSU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "ARAÇ",
        "STREET": "AKTAŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "ARAÇ",
        "STREET": "ALAKAYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "ARAÇ",
        "STREET": "ALINÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "ARAÇ",
        "STREET": "AVLACIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "ARAÇ",
        "STREET": "AVLAĞIÇAYIRI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "ARAÇ",
        "STREET": "AŞAĞIILIPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "ARAÇ",
        "STREET": "AŞAĞIOBA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "ARAÇ",
        "STREET": "AŞAĞIYAZI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "ARAÇ",
        "STREET": "AŞAĞIÇOBANÖZÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "ARAÇ",
        "STREET": "AŞAĞIİKİZÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "ARAÇ",
        "STREET": "BAHÇECİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "ARAÇ",
        "STREET": "BALÇIKHİSAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "ARAÇ",
        "STREET": "BAŞKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "ARAÇ",
        "STREET": "BEKTÜRE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "ARAÇ",
        "STREET": "BELEN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "ARAÇ",
        "STREET": "BELKAVAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "ARAÇ",
        "STREET": "BOĞDAM KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "ARAÇ",
        "STREET": "CELEPLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "ARAÇ",
        "STREET": "CEVİZLİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "ARAÇ",
        "STREET": "DAMLA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "ARAÇ",
        "STREET": "DERETEPE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "ARAÇ",
        "STREET": "DEREÇATI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "ARAÇ",
        "STREET": "DEĞİRMENÇAY KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "ARAÇ",
        "STREET": "DORUK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "ARAÇ",
        "STREET": "DOĞANCA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "ARAÇ",
        "STREET": "DOĞANPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "ARAÇ",
        "STREET": "EKİNÖZÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "ARAÇ",
        "STREET": "EREKLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "ARAÇ",
        "STREET": "ESKİİĞDİR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "ARAÇ",
        "STREET": "FINDIKLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "ARAÇ",
        "STREET": "GEMİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "ARAÇ",
        "STREET": "GERGEN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "ARAÇ",
        "STREET": "GÖKCEÇAT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "ARAÇ",
        "STREET": "GÖKÇESU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "ARAÇ",
        "STREET": "GÖLCÜK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "ARAÇ",
        "STREET": "GÜLÜKLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "ARAÇ",
        "STREET": "GÜZELCE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "ARAÇ",
        "STREET": "GÜZLÜK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "ARAÇ",
        "STREET": "HALİLOBA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "ARAÇ",
        "STREET": "HANÖZÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "ARAÇ",
        "STREET": "HATİP KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "ARAÇ",
        "STREET": "HURUÇÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "ARAÇ",
        "STREET": "KARACIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "ARAÇ",
        "STREET": "KARAKAYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "ARAÇ",
        "STREET": "KARAÇALAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "ARAÇ",
        "STREET": "KARCILAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "ARAÇ",
        "STREET": "KAVACIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "ARAÇ",
        "STREET": "KAVAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "ARAÇ",
        "STREET": "KAYABAŞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "ARAÇ",
        "STREET": "KAYABOĞAZI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "ARAÇ",
        "STREET": "KAYAÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "ARAÇ",
        "STREET": "KEMERLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "ARAÇ",
        "STREET": "KIYAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "ARAÇ",
        "STREET": "KIYIDİBİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "ARAÇ",
        "STREET": "KIZILSARAY KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "ARAÇ",
        "STREET": "KIZILÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "ARAÇ",
        "STREET": "KIŞLAKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "ARAÇ",
        "STREET": "KOVANLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "ARAÇ",
        "STREET": "KÖKLÜDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "ARAÇ",
        "STREET": "KÖKLÜYURT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "ARAÇ",
        "STREET": "KÖSE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "ARAÇ",
        "STREET": "KİRAZLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "ARAÇ",
        "STREET": "MESUDİYE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "ARAÇ",
        "STREET": "MURATLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "ARAÇ",
        "STREET": "MÜSLİMLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "ARAÇ",
        "STREET": "OKLUK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "ARAÇ",
        "STREET": "OKÇULAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "ARAÇ",
        "STREET": "OYCALI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "ARAÇ",
        "STREET": "PALAZLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "ARAÇ",
        "STREET": "PELİTÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "ARAÇ",
        "STREET": "PINARÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "ARAÇ",
        "STREET": "RECEPBEY KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "ARAÇ",
        "STREET": "SALTUKLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "ARAÇ",
        "STREET": "SAMATLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "ARAÇ",
        "STREET": "SARIHACI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "ARAÇ",
        "STREET": "SARPUN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "ARAÇ",
        "STREET": "SERDAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "ARAÇ",
        "STREET": "SIRAGÖMÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "ARAÇ",
        "STREET": "SOFCULAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "ARAÇ",
        "STREET": "SUSUZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "ARAÇ",
        "STREET": "SÜMENLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "ARAÇ",
        "STREET": "TATLICA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "ARAÇ",
        "STREET": "TAVŞANLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "ARAÇ",
        "STREET": "TAŞPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "ARAÇ",
        "STREET": "TELLİKOZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "ARAÇ",
        "STREET": "TERKE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "ARAÇ",
        "STREET": "TOKATLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "ARAÇ",
        "STREET": "TOYGAÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "ARAÇ",
        "STREET": "TUZAKLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "ARAÇ",
        "STREET": "ULUCAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "ARAÇ",
        "STREET": "UĞUR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "ARAÇ",
        "STREET": "YENİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "ARAÇ",
        "STREET": "YENİCE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "ARAÇ",
        "STREET": "YEŞİL MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "ARAÇ",
        "STREET": "YEŞİLOVA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "ARAÇ",
        "STREET": "YUKARIARAÇ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "ARAÇ",
        "STREET": "YUKARIGÜNEY KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "ARAÇ",
        "STREET": "YUKARIILIPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "ARAÇ",
        "STREET": "YUKARIOBA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "ARAÇ",
        "STREET": "YUKARIYAZI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "ARAÇ",
        "STREET": "YUKARIÇOBANÖZÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "ARAÇ",
        "STREET": "YUKARIİKİZÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "ARAÇ",
        "STREET": "YURTTEPE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "ARAÇ",
        "STREET": "ÇALKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "ARAÇ",
        "STREET": "ÇAMALTI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "ARAÇ",
        "STREET": "ÇAVUŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "ARAÇ",
        "STREET": "ÇAY MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "ARAÇ",
        "STREET": "ÇAYKAŞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "ARAÇ",
        "STREET": "ÇERÇİLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "ARAÇ",
        "STREET": "ÇUBUKLUDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "ARAÇ",
        "STREET": "ÇUKURPELİT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "ARAÇ",
        "STREET": "ÖMERSİN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "ARAÇ",
        "STREET": "ÖZBEL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "ARAÇ",
        "STREET": "ÜÇPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "ARAÇ",
        "STREET": "İHSANLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "ARAÇ",
        "STREET": "İNÖNÜ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "ARAÇ",
        "STREET": "İĞDİR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "ARAÇ",
        "STREET": "İĞDİRKIŞLA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "ARAÇ",
        "STREET": "ŞEHRİMANLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "ARAÇ",
        "STREET": "ŞENYURT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "ARAÇ",
        "STREET": "ŞİRİNGÜNEY KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "AZDAVAY",
        "STREET": "AHATKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "AZDAVAY",
        "STREET": "AKCAÇAM KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "AZDAVAY",
        "STREET": "ALACIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "AZDAVAY",
        "STREET": "ALI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "AZDAVAY",
        "STREET": "ARSLANCA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "AZDAVAY",
        "STREET": "BAHÇELİEVLER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "AZDAVAY",
        "STREET": "BAKIRCI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "AZDAVAY",
        "STREET": "BAŞAKÇAY KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "AZDAVAY",
        "STREET": "BAŞÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "AZDAVAY",
        "STREET": "DERELİTEKKE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "AZDAVAY",
        "STREET": "DEREYÜCEK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "AZDAVAY",
        "STREET": "EVLEK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "AZDAVAY",
        "STREET": "GECEN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "AZDAVAY",
        "STREET": "GÖKTAŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "AZDAVAY",
        "STREET": "GÜLTEPE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "AZDAVAY",
        "STREET": "GÜMÜRTLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "AZDAVAY",
        "STREET": "HIDIRLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "AZDAVAY",
        "STREET": "HOCAKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "AZDAVAY",
        "STREET": "KANLIDAĞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "AZDAVAY",
        "STREET": "KARAHALILILAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "AZDAVAY",
        "STREET": "KARAKUŞLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "AZDAVAY",
        "STREET": "KARŞIYAKA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "AZDAVAY",
        "STREET": "KAYABAŞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "AZDAVAY",
        "STREET": "KAYAOĞLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "AZDAVAY",
        "STREET": "KERPİÇLİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "AZDAVAY",
        "STREET": "KIRCALAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "AZDAVAY",
        "STREET": "KIRMACI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "AZDAVAY",
        "STREET": "KOLCA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "AZDAVAY",
        "STREET": "KOZLUÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "AZDAVAY",
        "STREET": "KURTÇULAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "AZDAVAY",
        "STREET": "MADEN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "AZDAVAY",
        "STREET": "MAKSUTKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "AZDAVAY",
        "STREET": "MEHMETÇELEBİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "AZDAVAY",
        "STREET": "MERKEZ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "AZDAVAY",
        "STREET": "SABUNCULAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "AZDAVAY",
        "STREET": "SADA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "AZDAVAY",
        "STREET": "SAMANCI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "AZDAVAY",
        "STREET": "SARAYKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "AZDAVAY",
        "STREET": "SARNIÇKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "AZDAVAY",
        "STREET": "SIRAKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "AZDAVAY",
        "STREET": "SÖĞÜTPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "AZDAVAY",
        "STREET": "TASKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "AZDAVAY",
        "STREET": "TOMRUKKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "AZDAVAY",
        "STREET": "TOPUK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "AZDAVAY",
        "STREET": "YENİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "AZDAVAY",
        "STREET": "YEŞİLKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "AZDAVAY",
        "STREET": "YUMACIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "AZDAVAY",
        "STREET": "ZÜMRÜT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "AZDAVAY",
        "STREET": "ÇAKIROĞLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "AZDAVAY",
        "STREET": "ÇAMLIBÜK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "AZDAVAY",
        "STREET": "ÇOÇUKÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "AZDAVAY",
        "STREET": "ÇÖMLEKTEPE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "AZDAVAY",
        "STREET": "ÜYÜK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "AĞLI",
        "STREET": "ADALAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "AĞLI",
        "STREET": "AKDİVAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "AĞLI",
        "STREET": "AKÇAKESE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "AĞLI",
        "STREET": "ALINCA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "AĞLI",
        "STREET": "BEREKETLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "AĞLI",
        "STREET": "DANACI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "AĞLI",
        "STREET": "DİKMEN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "AĞLI",
        "STREET": "FIRINCIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "AĞLI",
        "STREET": "GÖLCÜĞEZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "AĞLI",
        "STREET": "KABACI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "AĞLI",
        "STREET": "KETENCİLER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "AĞLI",
        "STREET": "KIRLARDERE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "AĞLI",
        "STREET": "MERKEZ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "AĞLI",
        "STREET": "MÜSELLİMLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "AĞLI",
        "STREET": "OLUKÖZÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "AĞLI",
        "STREET": "SELMANLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "AĞLI",
        "STREET": "SELMANLI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "AĞLI",
        "STREET": "SİNAN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "AĞLI",
        "STREET": "TUNUSLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "AĞLI",
        "STREET": "TURNACIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "AĞLI",
        "STREET": "YAĞLICA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "AĞLI",
        "STREET": "YEŞİLPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "AĞLI",
        "STREET": "ÖRENCİK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "BOZKURT",
        "STREET": "ALANTEPE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "BOZKURT",
        "STREET": "AMBARCILAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "BOZKURT",
        "STREET": "BAYRAMGAZİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "BOZKURT",
        "STREET": "BELDEĞİRMEN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "BOZKURT",
        "STREET": "DARSU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "BOZKURT",
        "STREET": "DURSUN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "BOZKURT",
        "STREET": "GÖRENTAŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "BOZKURT",
        "STREET": "GÜNGÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "BOZKURT",
        "STREET": "GÜNVAKTI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "BOZKURT",
        "STREET": "HAMZA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "BOZKURT",
        "STREET": "IŞIĞAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "BOZKURT",
        "STREET": "KAYALAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "BOZKURT",
        "STREET": "KESTANESÖKÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "BOZKURT",
        "STREET": "KEŞLİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "BOZKURT",
        "STREET": "KIZILCAELMA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "BOZKURT",
        "STREET": "KOCAÇAM KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "BOZKURT",
        "STREET": "KOŞMAPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "BOZKURT",
        "STREET": "KUTLUCA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "BOZKURT",
        "STREET": "KÖSEALİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "BOZKURT",
        "STREET": "KİRAZSÖKÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "BOZKURT",
        "STREET": "MAMATLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "BOZKURT",
        "STREET": "MERKEZ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "BOZKURT",
        "STREET": "ORTASÖKÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "BOZKURT",
        "STREET": "SAKIZCILAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "BOZKURT",
        "STREET": "SARIÇİÇEK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "BOZKURT",
        "STREET": "TEZCAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "BOZKURT",
        "STREET": "ULU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "BOZKURT",
        "STREET": "YAYLATEPE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "BOZKURT",
        "STREET": "YAŞARLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "BOZKURT",
        "STREET": "YENİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "BOZKURT",
        "STREET": "YÜKSEK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "BOZKURT",
        "STREET": "ÇİÇEKYAYLA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "BOZKURT",
        "STREET": "İBRAHİM KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "BOZKURT",
        "STREET": "İLİŞİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "BOZKURT",
        "STREET": "İNCEYAZI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "BOZKURT",
        "STREET": "ŞEN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "BOZKURT",
        "STREET": "ŞEYHOĞLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "CİDE",
        "STREET": "ABDULKADİR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "CİDE",
        "STREET": "AKBAYIR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "CİDE",
        "STREET": "AKÇA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "CİDE",
        "STREET": "ALAYAZI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "CİDE",
        "STREET": "ALAYÜZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "CİDE",
        "STREET": "AYDINCIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "CİDE",
        "STREET": "AĞAÇBÜKÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "CİDE",
        "STREET": "BALTACI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "CİDE",
        "STREET": "BAĞYURDU MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "CİDE",
        "STREET": "BAŞKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "CİDE",
        "STREET": "BELTEPE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "CİDE",
        "STREET": "BEŞEVLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "CİDE",
        "STREET": "CUMHURİYET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "CİDE",
        "STREET": "DENİZKONAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "CİDE",
        "STREET": "DEREBAĞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "CİDE",
        "STREET": "DEREBUCAĞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "CİDE",
        "STREET": "DOĞANKAYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "CİDE",
        "STREET": "DÖNGELCE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "CİDE",
        "STREET": "DÜZKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "CİDE",
        "STREET": "ECE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "CİDE",
        "STREET": "EMİRLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "CİDE",
        "STREET": "GEBEŞ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "CİDE",
        "STREET": "GÖKÇELER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "CİDE",
        "STREET": "GÖKÇEÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "CİDE",
        "STREET": "GÜNDOĞAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "CİDE",
        "STREET": "GÜNEBAKAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "CİDE",
        "STREET": "GÜZELYAYLA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "CİDE",
        "STREET": "HACIAHMET KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "CİDE",
        "STREET": "HAMİTLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "CİDE",
        "STREET": "HİMMETBEŞE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "CİDE",
        "STREET": "IRMAK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "CİDE",
        "STREET": "KALAFAT MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "CİDE",
        "STREET": "KAPISUYU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "CİDE",
        "STREET": "KARAKADI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "CİDE",
        "STREET": "KASABA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "CİDE",
        "STREET": "KASIM MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "CİDE",
        "STREET": "KAYAARDI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "CİDE",
        "STREET": "KAZANLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "CİDE",
        "STREET": "KEMERLİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "CİDE",
        "STREET": "KETHÜDA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "CİDE",
        "STREET": "KEZAĞZI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "CİDE",
        "STREET": "KIRANLIKOZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "CİDE",
        "STREET": "KIRCI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "CİDE",
        "STREET": "KONUKLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "CİDE",
        "STREET": "KOVANÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "CİDE",
        "STREET": "KOÇLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "CİDE",
        "STREET": "KUMKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "CİDE",
        "STREET": "KUMLUCA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "CİDE",
        "STREET": "KUŞKAYASI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "CİDE",
        "STREET": "KUŞÇU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "CİDE",
        "STREET": "KÖSELİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "CİDE",
        "STREET": "MEMİŞ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "CİDE",
        "STREET": "MENCEKLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "CİDE",
        "STREET": "MENÜK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "CİDE",
        "STREET": "MUSAKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "CİDE",
        "STREET": "NANEPINARI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "CİDE",
        "STREET": "NASUH MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "CİDE",
        "STREET": "OKÇULAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "CİDE",
        "STREET": "OLUCAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "CİDE",
        "STREET": "ORTACA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "CİDE",
        "STREET": "OVACIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "CİDE",
        "STREET": "PEHLİVANLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "CİDE",
        "STREET": "SAKALLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "CİDE",
        "STREET": "SIRAKAYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "CİDE",
        "STREET": "SOFULAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "CİDE",
        "STREET": "SOĞUCAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "CİDE",
        "STREET": "SİPAHİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "CİDE",
        "STREET": "TARAKÇI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "CİDE",
        "STREET": "TOYGARLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "CİDE",
        "STREET": "UĞURLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "CİDE",
        "STREET": "VELİOĞLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "CİDE",
        "STREET": "YALÇINKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "CİDE",
        "STREET": "YAYLAKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "CİDE",
        "STREET": "YENİCE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "CİDE",
        "STREET": "YENİKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "CİDE",
        "STREET": "YILDIZALAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "CİDE",
        "STREET": "YURTBAŞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "CİDE",
        "STREET": "ÇAKIRLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "CİDE",
        "STREET": "ÇAMALTI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "CİDE",
        "STREET": "ÇAMDİBİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "CİDE",
        "STREET": "ÇATALOLUK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "CİDE",
        "STREET": "ÇAYYAKA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "CİDE",
        "STREET": "ÇAYÜSTÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "CİDE",
        "STREET": "ÇUKURÇAL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "CİDE",
        "STREET": "ÇİLEKÇE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "CİDE",
        "STREET": "ÖVEÇLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "CİDE",
        "STREET": "ÜÇAĞIL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "CİDE",
        "STREET": "İLYASBEY KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "CİDE",
        "STREET": "İSAKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "CİDE",
        "STREET": "İSHAKÇA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "CİDE",
        "STREET": "ŞENKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "DADAY",
        "STREET": "AKILÇALMAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "DADAY",
        "STREET": "AKPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "DADAY",
        "STREET": "AKTAŞTEKKE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "DADAY",
        "STREET": "ALİPAŞA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "DADAY",
        "STREET": "ARABACILAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "DADAY",
        "STREET": "BASTAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "DADAY",
        "STREET": "BAYIR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "DADAY",
        "STREET": "BAYRAMLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "DADAY",
        "STREET": "BAĞIŞLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "DADAY",
        "STREET": "BEY MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "DADAY",
        "STREET": "BEYKOZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "DADAY",
        "STREET": "BEZİRKAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "DADAY",
        "STREET": "BOLATLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "DADAY",
        "STREET": "BOYALICA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "DADAY",
        "STREET": "BOYALILAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "DADAY",
        "STREET": "BUDAKLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "DADAY",
        "STREET": "DAVUT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "DADAY",
        "STREET": "DEMİRCE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "DADAY",
        "STREET": "DEREKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "DADAY",
        "STREET": "DEREÖZÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "DADAY",
        "STREET": "DEĞİRMENCİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "DADAY",
        "STREET": "DEĞİRMENÖZÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "DADAY",
        "STREET": "ELMAYAZI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "DADAY",
        "STREET": "ERTAŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "DADAY",
        "STREET": "FASILLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "DADAY",
        "STREET": "GÖKÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "DADAY",
        "STREET": "GÖRÜK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "DADAY",
        "STREET": "HASANAĞA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "DADAY",
        "STREET": "HASANŞEYH KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "DADAY",
        "STREET": "KALEM MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "DADAY",
        "STREET": "KAPAKLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "DADAY",
        "STREET": "KARAAĞAÇ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "DADAY",
        "STREET": "KARACAAĞAÇ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "DADAY",
        "STREET": "KARACAÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "DADAY",
        "STREET": "KARAMIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "DADAY",
        "STREET": "KAVAKYAYLA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "DADAY",
        "STREET": "KAYABAĞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "DADAY",
        "STREET": "KAYI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "DADAY",
        "STREET": "KELEBEK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "DADAY",
        "STREET": "KIZILÖRENCİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "DADAY",
        "STREET": "KIZSİNİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "DADAY",
        "STREET": "KOÇCUĞAZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "DADAY",
        "STREET": "KÖŞELER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "DADAY",
        "STREET": "KÜTEN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "DADAY",
        "STREET": "MERKEZ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "DADAY",
        "STREET": "OKLUK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "DADAY",
        "STREET": "SARIÇAM KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "DADAY",
        "STREET": "SARPUN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "DADAY",
        "STREET": "SELALMAZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "DADAY",
        "STREET": "SORKUN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "DADAY",
        "STREET": "SORKUNCUK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "DADAY",
        "STREET": "SİYAHLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "DADAY",
        "STREET": "TÜFEKÇİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "DADAY",
        "STREET": "UZBANLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "DADAY",
        "STREET": "YAZICAMEYDAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "DADAY",
        "STREET": "ÇAMKONAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "DADAY",
        "STREET": "ÇAMLIBEL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "DADAY",
        "STREET": "ÇAVUŞLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "DADAY",
        "STREET": "ÇAYIRLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "DADAY",
        "STREET": "ÇAYÖZÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "DADAY",
        "STREET": "ÇÖLMEKÇİLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "DADAY",
        "STREET": "ÖRENCİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "DADAY",
        "STREET": "ÜYÜKÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "DADAY",
        "STREET": "İNCİĞEZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "DEVREKANİ",
        "STREET": "AHLATÇIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "DEVREKANİ",
        "STREET": "AHİÇELEBİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "DEVREKANİ",
        "STREET": "AKDOĞAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "DEVREKANİ",
        "STREET": "AKMESCİT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "DEVREKANİ",
        "STREET": "AKÇAPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "DEVREKANİ",
        "STREET": "ALAÇAY KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "DEVREKANİ",
        "STREET": "ALINÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "DEVREKANİ",
        "STREET": "ALÇILAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "DEVREKANİ",
        "STREET": "ARSLANBEY KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "DEVREKANİ",
        "STREET": "ASARCIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "DEVREKANİ",
        "STREET": "BALABANLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "DEVREKANİ",
        "STREET": "BALTACAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "DEVREKANİ",
        "STREET": "BAŞAKPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "DEVREKANİ",
        "STREET": "BAŞAKPINARTEPE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "DEVREKANİ",
        "STREET": "BELOVACIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "DEVREKANİ",
        "STREET": "BINGILDAYIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "DEVREKANİ",
        "STREET": "BOZARMUT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "DEVREKANİ",
        "STREET": "BOZKOCA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "DEVREKANİ",
        "STREET": "BOZKOCATEPE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "DEVREKANİ",
        "STREET": "DOĞUÖRCÜNLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "DEVREKANİ",
        "STREET": "ELMALITEKKE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "DEVREKANİ",
        "STREET": "ERENLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "DEVREKANİ",
        "STREET": "ETÇİLER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "DEVREKANİ",
        "STREET": "FAKILAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "DEVREKANİ",
        "STREET": "GÖYNÜKÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "DEVREKANİ",
        "STREET": "HABEŞLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "DEVREKANİ",
        "STREET": "HASIRLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "DEVREKANİ",
        "STREET": "KADIOĞLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "DEVREKANİ",
        "STREET": "KADİRBEY MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "DEVREKANİ",
        "STREET": "KANLIABAT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "DEVREKANİ",
        "STREET": "KARAYAZICILAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "DEVREKANİ",
        "STREET": "KARAÇAM KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "DEVREKANİ",
        "STREET": "KASAPLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "DEVREKANİ",
        "STREET": "KINIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "DEVREKANİ",
        "STREET": "KIZACIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "DEVREKANİ",
        "STREET": "KURT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "DEVREKANİ",
        "STREET": "KURTŞEYH MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "DEVREKANİ",
        "STREET": "KUZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "DEVREKANİ",
        "STREET": "LAÇİN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "DEVREKANİ",
        "STREET": "MÜTEVELLİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "DEVREKANİ",
        "STREET": "PINARÖZÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "DEVREKANİ",
        "STREET": "SARAYDURAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "DEVREKANİ",
        "STREET": "SARIYONCA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "DEVREKANİ",
        "STREET": "SARPUNALINCA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "DEVREKANİ",
        "STREET": "SAZYAKA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "DEVREKANİ",
        "STREET": "SELHATTİN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "DEVREKANİ",
        "STREET": "SİNANTEKKE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "DEVREKANİ",
        "STREET": "TEKKEKIZILLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "DEVREKANİ",
        "STREET": "ULAMIŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "DEVREKANİ",
        "STREET": "YAZIBELEN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "DEVREKANİ",
        "STREET": "YAZIHİSAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "DEVREKANİ",
        "STREET": "ÇATAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "DEVREKANİ",
        "STREET": "ÇAVUŞLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "DEVREKANİ",
        "STREET": "ÇAYIRCIK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "DEVREKANİ",
        "STREET": "ÇONTAY KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "DEVREKANİ",
        "STREET": "ÇORBACI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "DEVREKANİ",
        "STREET": "ÇÖREKÇİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "DEVREKANİ",
        "STREET": "ÖRENBAŞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "DEVREKANİ",
        "STREET": "İNCİĞEZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "DEVREKANİ",
        "STREET": "İSMAİLBEY MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "DEVREKANİ",
        "STREET": "ŞENLİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "DEVREKANİ",
        "STREET": "ŞEYHBALİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "DOĞANYURT",
        "STREET": "AKÇABEL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "DOĞANYURT",
        "STREET": "AŞAĞIMESCİT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "DOĞANYURT",
        "STREET": "BALDIRAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "DOĞANYURT",
        "STREET": "BAŞKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "DOĞANYURT",
        "STREET": "BELYAKA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "DOĞANYURT",
        "STREET": "BOĞAZCIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "DOĞANYURT",
        "STREET": "DANIŞMAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "DOĞANYURT",
        "STREET": "DEMİRCİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "DOĞANYURT",
        "STREET": "DENİZBÜKÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "DOĞANYURT",
        "STREET": "DENİZGÖRÜLEN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "DOĞANYURT",
        "STREET": "DÜZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "DOĞANYURT",
        "STREET": "DÜZAĞAÇ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "DOĞANYURT",
        "STREET": "GÖKÇE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "DOĞANYURT",
        "STREET": "GÖZALAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "DOĞANYURT",
        "STREET": "GÜRMÜDÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "DOĞANYURT",
        "STREET": "HASKAVAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "DOĞANYURT",
        "STREET": "HOCAKÖYÜ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "DOĞANYURT",
        "STREET": "KAYRAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "DOĞANYURT",
        "STREET": "KÖFÜNANBARI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "DOĞANYURT",
        "STREET": "KÜÇÜKTEPE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "DOĞANYURT",
        "STREET": "MERKEZ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "DOĞANYURT",
        "STREET": "ORTABURUN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "DOĞANYURT",
        "STREET": "TAŞLIPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "DOĞANYURT",
        "STREET": "YASSIKIŞLA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "DOĞANYURT",
        "STREET": "YUKARIMESCİT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "DOĞANYURT",
        "STREET": "ÇAKIRLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "DOĞANYURT",
        "STREET": "ŞİRİN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "HANÖNÜ",
        "STREET": "AKÇASU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "HANÖNÜ",
        "STREET": "BAĞDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "HANÖNÜ",
        "STREET": "BÖLÜKYAZI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "HANÖNÜ",
        "STREET": "DEMİRCİMÜEZZİN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "HANÖNÜ",
        "STREET": "GELİNBÜKÜ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "HANÖNÜ",
        "STREET": "GÖKBELEN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "HANÖNÜ",
        "STREET": "GÖKÇEAĞAÇ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "HANÖNÜ",
        "STREET": "HALKABÜK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "HANÖNÜ",
        "STREET": "HANÖNÜ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "HANÖNÜ",
        "STREET": "HOCAVAKIF KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "HANÖNÜ",
        "STREET": "KAVAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "HANÖNÜ",
        "STREET": "KAYABAŞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "HANÖNÜ",
        "STREET": "KÜREÇAYI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "HANÖNÜ",
        "STREET": "SARIALAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "HANÖNÜ",
        "STREET": "SİRKE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "HANÖNÜ",
        "STREET": "VAKIF GEYMENE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "HANÖNÜ",
        "STREET": "YENİBOYUNDURCAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "HANÖNÜ",
        "STREET": "YENİCE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "HANÖNÜ",
        "STREET": "YENİKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "HANÖNÜ",
        "STREET": "YILANLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "HANÖNÜ",
        "STREET": "YUKARIÇAKIRÇAY KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "HANÖNÜ",
        "STREET": "ÇAKIRÇAY KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "HANÖNÜ",
        "STREET": "ÇAYBAŞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "MERKEZ",
        "STREET": "AHLAT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "MERKEZ",
        "STREET": "AHLATÇIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "MERKEZ",
        "STREET": "AHMETBEY KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "MERKEZ",
        "STREET": "AKDOĞAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "MERKEZ",
        "STREET": "AKKAYA BUCAĞI İSMAİLLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "MERKEZ",
        "STREET": "AKMESCİT MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "MERKEZ",
        "STREET": "AKSİNİR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "MERKEZ",
        "STREET": "AKTEKKE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "MERKEZ",
        "STREET": "AKÇAKESE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "MERKEZ",
        "STREET": "AKÇATAŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "MERKEZ",
        "STREET": "ALPARSLAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "MERKEZ",
        "STREET": "ALPAĞUT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "MERKEZ",
        "STREET": "ALPI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "MERKEZ",
        "STREET": "ALÇUCULAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "MERKEZ",
        "STREET": "ARIZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "MERKEZ",
        "STREET": "ATABEYGAZİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "MERKEZ",
        "STREET": "AYVALAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "MERKEZ",
        "STREET": "AŞAĞIAKÇA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "MERKEZ",
        "STREET": "AŞAĞIBATAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "MERKEZ",
        "STREET": "AŞAĞIELYAKUT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "MERKEZ",
        "STREET": "AŞAĞIYUVA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "MERKEZ",
        "STREET": "BAHADIR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "MERKEZ",
        "STREET": "BALLIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "MERKEZ",
        "STREET": "BALTACI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "MERKEZ",
        "STREET": "BALTACIKUYUCAĞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "MERKEZ",
        "STREET": "BAYINDIR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "MERKEZ",
        "STREET": "BAŞKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "MERKEZ",
        "STREET": "BAŞÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "MERKEZ",
        "STREET": "BEYÇELEBİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "MERKEZ",
        "STREET": "BOSTAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "MERKEZ",
        "STREET": "BOZOĞLAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "MERKEZ",
        "STREET": "BUDAMIŞ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "MERKEZ",
        "STREET": "BULACIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "MERKEZ",
        "STREET": "BURHANLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "MERKEZ",
        "STREET": "BÜK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "MERKEZ",
        "STREET": "BÜRME KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "MERKEZ",
        "STREET": "CANBAZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "MERKEZ",
        "STREET": "CANDAROĞULLARI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "MERKEZ",
        "STREET": "CEBECİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "MERKEZ",
        "STREET": "CEBRAİL MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "MERKEZ",
        "STREET": "CİVCİLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "MERKEZ",
        "STREET": "DAMLAÇAY KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "MERKEZ",
        "STREET": "DARIBÜKÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "MERKEZ",
        "STREET": "DAYILAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "MERKEZ",
        "STREET": "DEMİRCİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "MERKEZ",
        "STREET": "DERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "MERKEZ",
        "STREET": "DEREBERÇİN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "MERKEZ",
        "STREET": "DOKUZKAT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "MERKEZ",
        "STREET": "DURSUNLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "MERKEZ",
        "STREET": "DURUÇAY KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "MERKEZ",
        "STREET": "ECEOĞLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "MERKEZ",
        "STREET": "ELMAYAKASI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "MERKEZ",
        "STREET": "EMİR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "MERKEZ",
        "STREET": "EMİRLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "MERKEZ",
        "STREET": "ESENLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "MERKEZ",
        "STREET": "ESENLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "MERKEZ",
        "STREET": "ESENTEPE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "MERKEZ",
        "STREET": "ETYEMEZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "MERKEZ",
        "STREET": "EVCİLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "MERKEZ",
        "STREET": "EYMİR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "MERKEZ",
        "STREET": "EŞEN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "MERKEZ",
        "STREET": "GELİNÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "MERKEZ",
        "STREET": "GEYİKLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "MERKEZ",
        "STREET": "GÖCEN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "MERKEZ",
        "STREET": "GÖDEL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "MERKEZ",
        "STREET": "GÖKÇEKENT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "MERKEZ",
        "STREET": "GÖKÇUKUR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "MERKEZ",
        "STREET": "GÖLKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "MERKEZ",
        "STREET": "GÖMEÇ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "MERKEZ",
        "STREET": "GÖMMECE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "MERKEZ",
        "STREET": "GÜLEF KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "MERKEZ",
        "STREET": "GİRDALLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "MERKEZ",
        "STREET": "HACI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "MERKEZ",
        "STREET": "HACIBEY KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "MERKEZ",
        "STREET": "HACIMUHARREM KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "MERKEZ",
        "STREET": "HACIYUSUF KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "MERKEZ",
        "STREET": "HACIİLYAS KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "MERKEZ",
        "STREET": "HACIŞABAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "MERKEZ",
        "STREET": "HALAÇLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "MERKEZ",
        "STREET": "HALİFE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "MERKEZ",
        "STREET": "HALİFEKUYUCAĞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "MERKEZ",
        "STREET": "HAMİT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "MERKEZ",
        "STREET": "HAS KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "MERKEZ",
        "STREET": "HATIP KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "MERKEZ",
        "STREET": "HATİPLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "MERKEZ",
        "STREET": "HATİPOĞLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "MERKEZ",
        "STREET": "HAYDARLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "MERKEZ",
        "STREET": "HEPKEBİRLER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "MERKEZ",
        "STREET": "HOCA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "MERKEZ",
        "STREET": "HONSALAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "MERKEZ",
        "STREET": "HÜSEYİNLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "MERKEZ",
        "STREET": "HİSARARDI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "MERKEZ",
        "STREET": "KADIOĞLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "MERKEZ",
        "STREET": "KARAEVLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "MERKEZ",
        "STREET": "KARAKUZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "MERKEZ",
        "STREET": "KARAMUKMOLLA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "MERKEZ",
        "STREET": "KARAÇOMAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "MERKEZ",
        "STREET": "KARAŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "MERKEZ",
        "STREET": "KASABA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "MERKEZ",
        "STREET": "KASABAÖRENCİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "MERKEZ",
        "STREET": "KASTAMONU E TİPİ KAPALI AÇIK CEZAEVİ MÜDÜRLÜĞÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "MERKEZ",
        "STREET": "KAVAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "MERKEZ",
        "STREET": "KAVALCA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "MERKEZ",
        "STREET": "KAYALI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "MERKEZ",
        "STREET": "KAYI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "MERKEZ",
        "STREET": "KAŞÇILAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "MERKEZ",
        "STREET": "KEMERLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "MERKEZ",
        "STREET": "KEREMLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "MERKEZ",
        "STREET": "KIRCALAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "MERKEZ",
        "STREET": "KIRIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "MERKEZ",
        "STREET": "KIRIŞOĞLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "MERKEZ",
        "STREET": "KIRKÇEŞME MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "MERKEZ",
        "STREET": "KIZILKESE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "MERKEZ",
        "STREET": "KONUKÇA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "MERKEZ",
        "STREET": "KORU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "MERKEZ",
        "STREET": "KURTGÖMEÇ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "MERKEZ",
        "STREET": "KURTKAYI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "MERKEZ",
        "STREET": "KURUCAÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "MERKEZ",
        "STREET": "KURUSARAY KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "MERKEZ",
        "STREET": "KUZEYKENT MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "MERKEZ",
        "STREET": "KUZYAKA BUCAĞI EMİRLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "MERKEZ",
        "STREET": "KUZYAKA BUCAĞI KIYIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "MERKEZ",
        "STREET": "KUZYAKA BUCAĞI ÇAVUNDUR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "MERKEZ",
        "STREET": "KUZYAKA BUCAĞI İSMAİLLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "MERKEZ",
        "STREET": "KUZYAKA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "MERKEZ",
        "STREET": "KUŞKARA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "MERKEZ",
        "STREET": "KÖKLÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "MERKEZ",
        "STREET": "KÖSEOĞLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "MERKEZ",
        "STREET": "KÜÇÜKSU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "MERKEZ",
        "STREET": "KİRENLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "MERKEZ",
        "STREET": "MEHMET AKİF ERSOY MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "MERKEZ",
        "STREET": "MERKEZ BUCAĞI EMİRLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "MERKEZ",
        "STREET": "MERKEZ BUCAĞI KIYIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "MERKEZ",
        "STREET": "MERKEZ BUCAĞI ÇAVUNDUR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "MERKEZ",
        "STREET": "MESCİT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "MERKEZ",
        "STREET": "MOLLA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "MERKEZ",
        "STREET": "MUSALLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "MERKEZ",
        "STREET": "NALCIKUYUCAĞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "MERKEZ",
        "STREET": "NUMANLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "MERKEZ",
        "STREET": "OBRUK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "MERKEZ",
        "STREET": "OMCULAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "MERKEZ",
        "STREET": "ORTA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "MERKEZ",
        "STREET": "ORTABOĞAZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "MERKEZ",
        "STREET": "OĞUL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "MERKEZ",
        "STREET": "PEHLİVAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "MERKEZ",
        "STREET": "SADA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "MERKEZ",
        "STREET": "SAHİP KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "MERKEZ",
        "STREET": "SAPACA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "MERKEZ",
        "STREET": "SARAYCIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "MERKEZ",
        "STREET": "SARAÇLAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "MERKEZ",
        "STREET": "SARICA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "MERKEZ",
        "STREET": "SARIÖMER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "MERKEZ",
        "STREET": "SEREMİTTİN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "MERKEZ",
        "STREET": "SIRASÖĞÜTLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "MERKEZ",
        "STREET": "SUBAŞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "MERKEZ",
        "STREET": "SULUSÖKÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "MERKEZ",
        "STREET": "SİPAHİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "MERKEZ",
        "STREET": "TALİPLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "MERKEZ",
        "STREET": "TARLATEPE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "MERKEZ",
        "STREET": "TAŞLIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "MERKEZ",
        "STREET": "TEKKE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "MERKEZ",
        "STREET": "TEPEHARMAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "MERKEZ",
        "STREET": "TERZİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "MERKEZ",
        "STREET": "TOPÇUOĞLU MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "MERKEZ",
        "STREET": "UZUNOLUK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "MERKEZ",
        "STREET": "YAKA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "MERKEZ",
        "STREET": "YARÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "MERKEZ",
        "STREET": "YAVUZSELİM MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "MERKEZ",
        "STREET": "YENİKAVAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "MERKEZ",
        "STREET": "YILANCI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "MERKEZ",
        "STREET": "YOLKONAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "MERKEZ",
        "STREET": "YUKARIBATAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "MERKEZ",
        "STREET": "YUKARIELYAKUT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "MERKEZ",
        "STREET": "YUKARIKUYUCAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "MERKEZ",
        "STREET": "YUNUS KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "MERKEZ",
        "STREET": "YÜREKVEREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇAKILLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇATALÇAM KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇATÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇERÇİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇEVRELİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇIBAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇORUMLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇİĞİL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "MERKEZ",
        "STREET": "ÖMERLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "MERKEZ",
        "STREET": "ÖRENCİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "MERKEZ",
        "STREET": "ÖRENYERİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "MERKEZ",
        "STREET": "ÜMİT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "MERKEZ",
        "STREET": "ÜYÜCEK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "MERKEZ",
        "STREET": "İBRAHİMLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "MERKEZ",
        "STREET": "İBİŞLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "MERKEZ",
        "STREET": "İMAMKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "MERKEZ",
        "STREET": "İNCEBOĞAZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "MERKEZ",
        "STREET": "İNÖNÜ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "MERKEZ",
        "STREET": "İSFENDİYAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "MERKEZ",
        "STREET": "İSLAM KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "MERKEZ",
        "STREET": "İSMAİLBEY MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "MERKEZ",
        "STREET": "ŞEYH KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "KÜRE",
        "STREET": "AFŞARGÜNEY KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "KÜRE",
        "STREET": "AFŞARİMAM KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "KÜRE",
        "STREET": "AHMETBEŞE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "KÜRE",
        "STREET": "ALACIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "KÜRE",
        "STREET": "AVCIPINARI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "KÜRE",
        "STREET": "BELÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "KÜRE",
        "STREET": "BEYALAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "KÜRE",
        "STREET": "BEŞÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "KÜRE",
        "STREET": "BÜRÜM KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "KÜRE",
        "STREET": "CAMİEVSAT MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "KÜRE",
        "STREET": "CAMİKEBİR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "KÜRE",
        "STREET": "CAMİLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "KÜRE",
        "STREET": "CANBAZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "KÜRE",
        "STREET": "ERSİZLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "KÜRE",
        "STREET": "ERSİZLERDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "KÜRE",
        "STREET": "GÜLLÜCE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "KÜRE",
        "STREET": "GÜNEY KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "KÜRE",
        "STREET": "KARADONU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "KÜRE",
        "STREET": "KARAMAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "KÜRE",
        "STREET": "KAYADİBİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "KÜRE",
        "STREET": "KESEPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "KÜRE",
        "STREET": "KOYUNKIRTIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "KÜRE",
        "STREET": "KOZKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "KÜRE",
        "STREET": "KÖSRELİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "KÜRE",
        "STREET": "KÖSTEKÇİLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "KÜRE",
        "STREET": "MÜDERRİS MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "KÜRE",
        "STREET": "SAMAN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "KÜRE",
        "STREET": "SARPUN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "KÜRE",
        "STREET": "SİPAHİLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "KÜRE",
        "STREET": "TAŞPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "KÜRE",
        "STREET": "TOPÇU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "KÜRE",
        "STREET": "UZUNÖZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "KÜRE",
        "STREET": "ÇAT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "KÜRE",
        "STREET": "ÇATAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "KÜRE",
        "STREET": "ÇAYBÜKÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "KÜRE",
        "STREET": "İKİZÇİLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "KÜRE",
        "STREET": "İMRALI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "KÜRE",
        "STREET": "İĞDİR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "PINARBAŞI",
        "STREET": "ATATÜRK  MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "PINARBAŞI",
        "STREET": "AŞAĞIAKTAŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "PINARBAŞI",
        "STREET": "BAŞKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "PINARBAŞI",
        "STREET": "BOĞAZKAYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "PINARBAŞI",
        "STREET": "CUMHURİYET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "PINARBAŞI",
        "STREET": "DEMİRTAŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "PINARBAŞI",
        "STREET": "DİZDARLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "PINARBAŞI",
        "STREET": "ESENTEPE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "PINARBAŞI",
        "STREET": "FATİH MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "PINARBAŞI",
        "STREET": "GÜNBERİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "PINARBAŞI",
        "STREET": "HOCALAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "PINARBAŞI",
        "STREET": "ILICA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "PINARBAŞI",
        "STREET": "KALAYCI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "PINARBAŞI",
        "STREET": "KAPANCI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "PINARBAŞI",
        "STREET": "KARACAÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "PINARBAŞI",
        "STREET": "KARAFASIL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "PINARBAŞI",
        "STREET": "KAYABÜKÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "PINARBAŞI",
        "STREET": "KERTE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "PINARBAŞI",
        "STREET": "KURTLUGELİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "PINARBAŞI",
        "STREET": "MURATBAŞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "PINARBAŞI",
        "STREET": "MİRAHOR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "PINARBAŞI",
        "STREET": "SAVAŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "PINARBAŞI",
        "STREET": "SÜMENLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "PINARBAŞI",
        "STREET": "URVA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "PINARBAŞI",
        "STREET": "UZLA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "PINARBAŞI",
        "STREET": "UZUNÇAM MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "PINARBAŞI",
        "STREET": "YAMANLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "PINARBAŞI",
        "STREET": "YUKARIAKTAŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "PINARBAŞI",
        "STREET": "ÇALKAYA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "PINARBAŞI",
        "STREET": "ÇAMKIŞLA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "PINARBAŞI",
        "STREET": "ÇAVUŞKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "PINARBAŞI",
        "STREET": "ÇENGEL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "PINARBAŞI",
        "STREET": "ÜYÜKÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "SEYDİLER",
        "STREET": "AYVATLAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "SEYDİLER",
        "STREET": "AŞAĞIARSLANLI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "SEYDİLER",
        "STREET": "BATIÖRCÜNLER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "SEYDİLER",
        "STREET": "DELİHACILAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "SEYDİLER",
        "STREET": "DEMİRCİÖZÜ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "SEYDİLER",
        "STREET": "EMRELER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "SEYDİLER",
        "STREET": "ERCEK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "SEYDİLER",
        "STREET": "HACIKAYALAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "SEYDİLER",
        "STREET": "KARAÇAVUŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "SEYDİLER",
        "STREET": "KEPEZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "SEYDİLER",
        "STREET": "MANCILIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "SEYDİLER",
        "STREET": "MERKEZ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "SEYDİLER",
        "STREET": "ODABAŞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "SEYDİLER",
        "STREET": "SABUNCULAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "SEYDİLER",
        "STREET": "YAYLA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "SEYDİLER",
        "STREET": "YAĞLAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "SEYDİLER",
        "STREET": "YOLYAKA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "SEYDİLER",
        "STREET": "YUKARIARSLANLI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "SEYDİLER",
        "STREET": "ÇERÇİLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "SEYDİLER",
        "STREET": "ÇIRDAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "SEYDİLER",
        "STREET": "ÇİĞİLERİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "SEYDİLER",
        "STREET": "ÜYÜK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "SEYDİLER",
        "STREET": "İMRENLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "SEYDİLER",
        "STREET": "İNCESU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "SEYDİLER",
        "STREET": "ŞALGAM KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "TAŞKÖPRÜ",
        "STREET": "ABAY KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "TAŞKÖPRÜ",
        "STREET": "ABDALHASAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "TAŞKÖPRÜ",
        "STREET": "AFŞAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "TAŞKÖPRÜ",
        "STREET": "AKDEĞİRMEN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "TAŞKÖPRÜ",
        "STREET": "AKDOĞAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "TAŞKÖPRÜ",
        "STREET": "AKDOĞANTEKKE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "TAŞKÖPRÜ",
        "STREET": "AKSEKİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "TAŞKÖPRÜ",
        "STREET": "AKÇAKESE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "TAŞKÖPRÜ",
        "STREET": "ALAMABATAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "TAŞKÖPRÜ",
        "STREET": "ALAMAKAYIŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "TAŞKÖPRÜ",
        "STREET": "ALAMAŞİŞLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "TAŞKÖPRÜ",
        "STREET": "ALASÖKÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "TAŞKÖPRÜ",
        "STREET": "ALATARLA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "TAŞKÖPRÜ",
        "STREET": "ALİPAŞA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "TAŞKÖPRÜ",
        "STREET": "ALİSARAY KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "TAŞKÖPRÜ",
        "STREET": "ARMUTLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "TAŞKÖPRÜ",
        "STREET": "ARSLANLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "TAŞKÖPRÜ",
        "STREET": "AYVALI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "TAŞKÖPRÜ",
        "STREET": "AĞCIKİŞİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "TAŞKÖPRÜ",
        "STREET": "AŞAĞIEMERCE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "TAŞKÖPRÜ",
        "STREET": "AŞAĞIÇAYIRCIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "TAŞKÖPRÜ",
        "STREET": "AŞAĞIÇİT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "TAŞKÖPRÜ",
        "STREET": "AŞAĞIŞEHİRÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "TAŞKÖPRÜ",
        "STREET": "BADEMBEKDEMİR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "TAŞKÖPRÜ",
        "STREET": "BADEMCİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "TAŞKÖPRÜ",
        "STREET": "BAHÇELİEVLER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "TAŞKÖPRÜ",
        "STREET": "BEKDEMİREKŞİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "TAŞKÖPRÜ",
        "STREET": "BEKİRLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "TAŞKÖPRÜ",
        "STREET": "BEY KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "TAŞKÖPRÜ",
        "STREET": "BOYUNDURCAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "TAŞKÖPRÜ",
        "STREET": "BOZARMUT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "TAŞKÖPRÜ",
        "STREET": "BÖCÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "TAŞKÖPRÜ",
        "STREET": "BÜK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "TAŞKÖPRÜ",
        "STREET": "CAMİKEBİR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "TAŞKÖPRÜ",
        "STREET": "CELEP KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "TAŞKÖPRÜ",
        "STREET": "DAĞBELÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "TAŞKÖPRÜ",
        "STREET": "DERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "TAŞKÖPRÜ",
        "STREET": "DEREBEYSİBEY KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "TAŞKÖPRÜ",
        "STREET": "DEREKARAAĞAÇ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "TAŞKÖPRÜ",
        "STREET": "DONALAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "TAŞKÖPRÜ",
        "STREET": "DOYMUŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "TAŞKÖPRÜ",
        "STREET": "DURUCA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "TAŞKÖPRÜ",
        "STREET": "DİLEK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "TAŞKÖPRÜ",
        "STREET": "ERSİL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "TAŞKÖPRÜ",
        "STREET": "ERİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "TAŞKÖPRÜ",
        "STREET": "ESENLİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "TAŞKÖPRÜ",
        "STREET": "ESKİATÇA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "TAŞKÖPRÜ",
        "STREET": "ESKİOĞLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "TAŞKÖPRÜ",
        "STREET": "ETHEM MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "TAŞKÖPRÜ",
        "STREET": "GARİPŞAH KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "TAŞKÖPRÜ",
        "STREET": "GÖKIRMAK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "TAŞKÖPRÜ",
        "STREET": "GÜNDOĞDU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "TAŞKÖPRÜ",
        "STREET": "GÜNEYKALINKESE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "TAŞKÖPRÜ",
        "STREET": "GİZLİCE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "TAŞKÖPRÜ",
        "STREET": "HACIALİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "TAŞKÖPRÜ",
        "STREET": "HAMZAOĞLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "TAŞKÖPRÜ",
        "STREET": "HARMANCIK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "TAŞKÖPRÜ",
        "STREET": "HASANLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "TAŞKÖPRÜ",
        "STREET": "HOCA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "TAŞKÖPRÜ",
        "STREET": "KABALAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "TAŞKÖPRÜ",
        "STREET": "KADI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "TAŞKÖPRÜ",
        "STREET": "KAPAKLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "TAŞKÖPRÜ",
        "STREET": "KARACAKAYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "TAŞKÖPRÜ",
        "STREET": "KARACAOĞLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "TAŞKÖPRÜ",
        "STREET": "KARADEDEOĞLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "TAŞKÖPRÜ",
        "STREET": "KARAPÜRÇEK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "TAŞKÖPRÜ",
        "STREET": "KARASAİT MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "TAŞKÖPRÜ",
        "STREET": "KARNIAÇIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "TAŞKÖPRÜ",
        "STREET": "KARŞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "TAŞKÖPRÜ",
        "STREET": "KAYADİBİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "TAŞKÖPRÜ",
        "STREET": "KAYAPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "TAŞKÖPRÜ",
        "STREET": "KAYGINCA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "TAŞKÖPRÜ",
        "STREET": "KESE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "TAŞKÖPRÜ",
        "STREET": "KILIÇLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "TAŞKÖPRÜ",
        "STREET": "KIRAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "TAŞKÖPRÜ",
        "STREET": "KIRHA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "TAŞKÖPRÜ",
        "STREET": "KIZILCAÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "TAŞKÖPRÜ",
        "STREET": "KIZILCAÖRHEN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "TAŞKÖPRÜ",
        "STREET": "KIZILKESE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "TAŞKÖPRÜ",
        "STREET": "KORNAPA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "TAŞKÖPRÜ",
        "STREET": "KOÇANLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "TAŞKÖPRÜ",
        "STREET": "KUYLUŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "TAŞKÖPRÜ",
        "STREET": "KUZKALINKESE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "TAŞKÖPRÜ",
        "STREET": "KÖÇEKLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "TAŞKÖPRÜ",
        "STREET": "KÜÇÜKSÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "TAŞKÖPRÜ",
        "STREET": "KİRAZCIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "TAŞKÖPRÜ",
        "STREET": "MASATLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "TAŞKÖPRÜ",
        "STREET": "MUSALLA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "TAŞKÖPRÜ",
        "STREET": "OBRUCAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "TAŞKÖPRÜ",
        "STREET": "OLUKBAŞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "TAŞKÖPRÜ",
        "STREET": "ORTA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "TAŞKÖPRÜ",
        "STREET": "ORTAÖZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "TAŞKÖPRÜ",
        "STREET": "OYMAAĞAÇSEKİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "TAŞKÖPRÜ",
        "STREET": "PAŞA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "TAŞKÖPRÜ",
        "STREET": "PULCULAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "TAŞKÖPRÜ",
        "STREET": "PİRAHMETLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "TAŞKÖPRÜ",
        "STREET": "SAMANLIÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "TAŞKÖPRÜ",
        "STREET": "SARIKAVAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "TAŞKÖPRÜ",
        "STREET": "SARISEKİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "TAŞKÖPRÜ",
        "STREET": "SARPUN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "TAŞKÖPRÜ",
        "STREET": "SÜMER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "TAŞKÖPRÜ",
        "STREET": "TABAKHANE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "TAŞKÖPRÜ",
        "STREET": "TAVUKÇUOĞLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "TAŞKÖPRÜ",
        "STREET": "TAŞÇILAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "TAŞKÖPRÜ",
        "STREET": "TEKEOĞLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "TAŞKÖPRÜ",
        "STREET": "TEKKE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "TAŞKÖPRÜ",
        "STREET": "TEPEDELİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "TAŞKÖPRÜ",
        "STREET": "TOKAŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "TAŞKÖPRÜ",
        "STREET": "URGANCI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "TAŞKÖPRÜ",
        "STREET": "UZUNKAVAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "TAŞKÖPRÜ",
        "STREET": "VAKIFBELÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "TAŞKÖPRÜ",
        "STREET": "YAVUÇ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "TAŞKÖPRÜ",
        "STREET": "YAVUÇKUYUCAĞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "TAŞKÖPRÜ",
        "STREET": "YAZIHAMİT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "TAŞKÖPRÜ",
        "STREET": "YENİLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "TAŞKÖPRÜ",
        "STREET": "YEŞİLYURT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "TAŞKÖPRÜ",
        "STREET": "YOĞUNOLUK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "TAŞKÖPRÜ",
        "STREET": "YUKARIEMERCE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "TAŞKÖPRÜ",
        "STREET": "YUKARIÇAYIRCIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "TAŞKÖPRÜ",
        "STREET": "YUKARIŞEHİRÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "TAŞKÖPRÜ",
        "STREET": "ÇAMBAŞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "TAŞKÖPRÜ",
        "STREET": "ÇAYCEVHER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "TAŞKÖPRÜ",
        "STREET": "ÇAYKİRPİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "TAŞKÖPRÜ",
        "STREET": "ÇAYLAKLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "TAŞKÖPRÜ",
        "STREET": "ÇEKİÇ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "TAŞKÖPRÜ",
        "STREET": "ÇETMİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "TAŞKÖPRÜ",
        "STREET": "ÇEVİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "TAŞKÖPRÜ",
        "STREET": "ÇOROĞLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "TAŞKÖPRÜ",
        "STREET": "ÇÖRDÜK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "TAŞKÖPRÜ",
        "STREET": "ÇİFTKIRAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "TAŞKÖPRÜ",
        "STREET": "ÇİFTLİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "TAŞKÖPRÜ",
        "STREET": "ÇİT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "TAŞKÖPRÜ",
        "STREET": "ÖMERLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "TAŞKÖPRÜ",
        "STREET": "ÖRHEN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "TAŞKÖPRÜ",
        "STREET": "ÖRHENLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "TAŞKÖPRÜ",
        "STREET": "ÜSTATLAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "TAŞKÖPRÜ",
        "STREET": "İMAMOĞLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "TAŞKÖPRÜ",
        "STREET": "İNCESU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "TAŞKÖPRÜ",
        "STREET": "ŞAHİNÇATI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "TAŞKÖPRÜ",
        "STREET": "ŞEHİRÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "TOSYA",
        "STREET": "AHMETOĞLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "TOSYA",
        "STREET": "AKBÜK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "TOSYA",
        "STREET": "AKSEKİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "TOSYA",
        "STREET": "AŞAĞIBERÇİN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "TOSYA",
        "STREET": "AŞAĞIDİKMEN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "TOSYA",
        "STREET": "AŞAĞIKAYI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "TOSYA",
        "STREET": "BAHÇELİEVLER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "TOSYA",
        "STREET": "BAYAT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "TOSYA",
        "STREET": "BÜRNÜK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "TOSYA",
        "STREET": "CAMİATİK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "TOSYA",
        "STREET": "CUMHURİYET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "TOSYA",
        "STREET": "DAĞARDI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "TOSYA",
        "STREET": "DAĞÇATAĞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "TOSYA",
        "STREET": "DEDEM KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "TOSYA",
        "STREET": "DERE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "TOSYA",
        "STREET": "DİLKÜŞA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "TOSYA",
        "STREET": "EKİNCİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "TOSYA",
        "STREET": "ERMELİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "TOSYA",
        "STREET": "FEVZİPAŞA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "TOSYA",
        "STREET": "GÖKOMUZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "TOSYA",
        "STREET": "GÖKÇEÖZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "TOSYA",
        "STREET": "GÖVRECİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "TOSYA",
        "STREET": "HACI KEMAL MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "TOSYA",
        "STREET": "HACIPİR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "TOSYA",
        "STREET": "HARSAT MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "TOSYA",
        "STREET": "HOCAFAKI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "TOSYA",
        "STREET": "HOCAİMAT MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "TOSYA",
        "STREET": "KARABEY KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "TOSYA",
        "STREET": "KARAKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "TOSYA",
        "STREET": "KARASAPAÇA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "TOSYA",
        "STREET": "KARGI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "TOSYA",
        "STREET": "KARGIN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "TOSYA",
        "STREET": "KARŞIYAKA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "TOSYA",
        "STREET": "KAYAÖNÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "TOSYA",
        "STREET": "KEÇELİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "TOSYA",
        "STREET": "KINIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "TOSYA",
        "STREET": "KIZILCA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "TOSYA",
        "STREET": "KUZYAKA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "TOSYA",
        "STREET": "KUŞÇULAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "TOSYA",
        "STREET": "KÖSEN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "TOSYA",
        "STREET": "KÜÇÜKKIZILCA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "TOSYA",
        "STREET": "KÜÇÜKSEKİLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "TOSYA",
        "STREET": "KİLKUYU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "TOSYA",
        "STREET": "MISMILAĞAÇ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "TOSYA",
        "STREET": "MUSAKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "TOSYA",
        "STREET": "MİMAR SİNAN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "TOSYA",
        "STREET": "ORTALICA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "TOSYA",
        "STREET": "PINARBAŞI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "TOSYA",
        "STREET": "SAPACA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "TOSYA",
        "STREET": "SEKİLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "TOSYA",
        "STREET": "SEVİNÇÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "TOSYA",
        "STREET": "SOFULAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "TOSYA",
        "STREET": "SULUCA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "TOSYA",
        "STREET": "YAVUZ SELİM MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "TOSYA",
        "STREET": "YAĞCILAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "TOSYA",
        "STREET": "YENİDOĞAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "TOSYA",
        "STREET": "YUKARIBERÇİN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "TOSYA",
        "STREET": "YUKARIDİKMEN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "TOSYA",
        "STREET": "YUKARIKAYI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "TOSYA",
        "STREET": "YUNUS EMRE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "TOSYA",
        "STREET": "ZİNCİRLİKUYU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "TOSYA",
        "STREET": "ÇAKIRLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "TOSYA",
        "STREET": "ÇATMA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "TOSYA",
        "STREET": "ÇAYBAŞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "TOSYA",
        "STREET": "ÇAYKAPI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "TOSYA",
        "STREET": "ÇELTİKÇİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "TOSYA",
        "STREET": "ÇEPNİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "TOSYA",
        "STREET": "ÇEVLİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "TOSYA",
        "STREET": "ÇUKURKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "TOSYA",
        "STREET": "ÇİFTER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "TOSYA",
        "STREET": "ÖZBOYU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "TOSYA",
        "STREET": "İBNİ SELİM MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "TOSYA",
        "STREET": "İLYASBEY MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "TOSYA",
        "STREET": "İNCEBEL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "TOSYA",
        "STREET": "ŞARAKMAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "TOSYA",
        "STREET": "ŞEH MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "TOSYA",
        "STREET": "ŞEHREKÜSTÜ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "ÇATALZEYTİN",
        "STREET": "ARICA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "ÇATALZEYTİN",
        "STREET": "AŞAĞISÖKÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "ÇATALZEYTİN",
        "STREET": "CANLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "ÇATALZEYTİN",
        "STREET": "CELALLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "ÇATALZEYTİN",
        "STREET": "DAĞKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "ÇATALZEYTİN",
        "STREET": "DOĞAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "ÇATALZEYTİN",
        "STREET": "DURAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "ÇATALZEYTİN",
        "STREET": "EPÇELER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "ÇATALZEYTİN",
        "STREET": "FINDIKLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "ÇATALZEYTİN",
        "STREET": "GÜNEŞLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "ÇATALZEYTİN",
        "STREET": "HACIREİS KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "ÇATALZEYTİN",
        "STREET": "HACIREİSSÖKÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "ÇATALZEYTİN",
        "STREET": "HAMİDİYE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "ÇATALZEYTİN",
        "STREET": "KARACAKAYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "ÇATALZEYTİN",
        "STREET": "KAVAKLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "ÇATALZEYTİN",
        "STREET": "KAVAKÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "ÇATALZEYTİN",
        "STREET": "KAYADİBİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "ÇATALZEYTİN",
        "STREET": "KAYMAZLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "ÇATALZEYTİN",
        "STREET": "KAŞLICA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "ÇATALZEYTİN",
        "STREET": "KIZILCAKAYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "ÇATALZEYTİN",
        "STREET": "KONAKLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "ÇATALZEYTİN",
        "STREET": "KULFALLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "ÇATALZEYTİN",
        "STREET": "KUZSÖKÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "ÇATALZEYTİN",
        "STREET": "KUĞU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "ÇATALZEYTİN",
        "STREET": "KÖKLÜCE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "ÇATALZEYTİN",
        "STREET": "KİRAZLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "ÇATALZEYTİN",
        "STREET": "MERKEZ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "ÇATALZEYTİN",
        "STREET": "PAŞALI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "ÇATALZEYTİN",
        "STREET": "PİRİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "ÇATALZEYTİN",
        "STREET": "SAMANCI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "ÇATALZEYTİN",
        "STREET": "SARAÇLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "ÇATALZEYTİN",
        "STREET": "SIRAKONAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "ÇATALZEYTİN",
        "STREET": "SÖKÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "ÇATALZEYTİN",
        "STREET": "YEMİŞLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "ÇATALZEYTİN",
        "STREET": "YENİBEYLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "ÇATALZEYTİN",
        "STREET": "YUKARISÖKÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "ÇATALZEYTİN",
        "STREET": "YUNUSLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "ÇATALZEYTİN",
        "STREET": "ÇATAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "ÇATALZEYTİN",
        "STREET": "ÇAĞLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "ÇATALZEYTİN",
        "STREET": "ÇELEBİLER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "ÇATALZEYTİN",
        "STREET": "ÇEPNİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "ÇATALZEYTİN",
        "STREET": "ÇUBUKLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "ÇATALZEYTİN",
        "STREET": "İSMAİL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "İHSANGAZİ",
        "STREET": "AFŞAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "İHSANGAZİ",
        "STREET": "AKKAYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "İHSANGAZİ",
        "STREET": "AKKİRPİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "İHSANGAZİ",
        "STREET": "BEDİRGERİŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "İHSANGAZİ",
        "STREET": "BELENÇAL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "İHSANGAZİ",
        "STREET": "BOZARMUT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "İHSANGAZİ",
        "STREET": "DAĞYOLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "İHSANGAZİ",
        "STREET": "ELÇELEBİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "İHSANGAZİ",
        "STREET": "ENBİYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "İHSANGAZİ",
        "STREET": "GÖRPE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "İHSANGAZİ",
        "STREET": "HAYDARLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "İHSANGAZİ",
        "STREET": "HOCAHACİP KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "İHSANGAZİ",
        "STREET": "KALFAT MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "İHSANGAZİ",
        "STREET": "KAPAKLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "İHSANGAZİ",
        "STREET": "KAYAPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "İHSANGAZİ",
        "STREET": "KIZILELLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "İHSANGAZİ",
        "STREET": "KOÇCUĞAZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "İHSANGAZİ",
        "STREET": "KUŞÇULAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "İHSANGAZİ",
        "STREET": "OBRUK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "İHSANGAZİ",
        "STREET": "SARIPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "İHSANGAZİ",
        "STREET": "SEPETÇİOĞLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "İHSANGAZİ",
        "STREET": "SEVİNDİK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "İHSANGAZİ",
        "STREET": "SÜNLÜK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "İHSANGAZİ",
        "STREET": "SİPAHİLER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "İHSANGAZİ",
        "STREET": "YARIŞLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "İHSANGAZİ",
        "STREET": "YENİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "İHSANGAZİ",
        "STREET": "YEŞİL MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "İHSANGAZİ",
        "STREET": "ÇATALYAZI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "İHSANGAZİ",
        "STREET": "ÇAY MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "İHSANGAZİ",
        "STREET": "ÇİÇEKPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "İHSANGAZİ",
        "STREET": "ÖRENCİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "İHSANGAZİ",
        "STREET": "İNCİĞEZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "İHSANGAZİ",
        "STREET": "İSALAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "İNEBOLU",
        "STREET": "AKGÜNEY KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "İNEBOLU",
        "STREET": "AKKONAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "İNEBOLU",
        "STREET": "AKTAŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "İNEBOLU",
        "STREET": "AKÇAY KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "İNEBOLU",
        "STREET": "ALACA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "İNEBOLU",
        "STREET": "ATABEYLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "İNEBOLU",
        "STREET": "AVARA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "İNEBOLU",
        "STREET": "AYVAKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "İNEBOLU",
        "STREET": "AYVAT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "İNEBOLU",
        "STREET": "AŞAĞIHATİPBAĞI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "İNEBOLU",
        "STREET": "AŞAĞIÇAYLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "İNEBOLU",
        "STREET": "BAYIRALAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "İNEBOLU",
        "STREET": "BAŞKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "İNEBOLU",
        "STREET": "BELEN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "İNEBOLU",
        "STREET": "BELENCE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "İNEBOLU",
        "STREET": "BELÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "İNEBOLU",
        "STREET": "BEYLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "İNEBOLU",
        "STREET": "BOYRAN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "İNEBOLU",
        "STREET": "CAMİKEBİR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "İNEBOLU",
        "STREET": "CÜNÜRYE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "İNEBOLU",
        "STREET": "DELİKTAŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "İNEBOLU",
        "STREET": "DERESÖKÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "İNEBOLU",
        "STREET": "DOĞANÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "İNEBOLU",
        "STREET": "DURUPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "İNEBOLU",
        "STREET": "DİBEK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "İNEBOLU",
        "STREET": "DİKİLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "İNEBOLU",
        "STREET": "ERENYOLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "İNEBOLU",
        "STREET": "ERKEKARPA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "İNEBOLU",
        "STREET": "ESENYURT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "İNEBOLU",
        "STREET": "EVRENYE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "İNEBOLU",
        "STREET": "GÖKBEL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "İNEBOLU",
        "STREET": "GÖKÇEVRE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "İNEBOLU",
        "STREET": "GÖÇKÜN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "İNEBOLU",
        "STREET": "GÜDE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "İNEBOLU",
        "STREET": "GÜNEŞLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "İNEBOLU",
        "STREET": "HACIMEHMET KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "İNEBOLU",
        "STREET": "HACIİBRAHİM KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "İNEBOLU",
        "STREET": "HAMİTKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "İNEBOLU",
        "STREET": "HAYRİOĞLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "İNEBOLU",
        "STREET": "HÜRMETLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "İNEBOLU",
        "STREET": "KABALAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "İNEBOLU",
        "STREET": "KABALARSÖKÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "İNEBOLU",
        "STREET": "KARABEY KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "İNEBOLU",
        "STREET": "KARACA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "İNEBOLU",
        "STREET": "KARADENİZ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "İNEBOLU",
        "STREET": "KARŞIYAKA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "İNEBOLU",
        "STREET": "KAYAELMASI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "İNEBOLU",
        "STREET": "KELOĞLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "İNEBOLU",
        "STREET": "KIZILKARA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "İNEBOLU",
        "STREET": "KORUPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "İNEBOLU",
        "STREET": "KUZLUK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "İNEBOLU",
        "STREET": "KÖROĞLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "İNEBOLU",
        "STREET": "KÖSEKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "İNEBOLU",
        "STREET": "MUSA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "İNEBOLU",
        "STREET": "SAKALAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "İNEBOLU",
        "STREET": "SALICIOĞLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "İNEBOLU",
        "STREET": "SOĞUKPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "İNEBOLU",
        "STREET": "SÖKÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "İNEBOLU",
        "STREET": "TAŞBURUN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "İNEBOLU",
        "STREET": "TAŞOLUK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "İNEBOLU",
        "STREET": "TOKLUKAYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "İNEBOLU",
        "STREET": "ULUKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "İNEBOLU",
        "STREET": "ULUYOL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "İNEBOLU",
        "STREET": "UĞRAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "İNEBOLU",
        "STREET": "YAKABOYU MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "İNEBOLU",
        "STREET": "YAMAÇ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "İNEBOLU",
        "STREET": "YAZTEPE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "İNEBOLU",
        "STREET": "YENİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "İNEBOLU",
        "STREET": "YEŞİLÖZ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "İNEBOLU",
        "STREET": "YOLÜSTÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "İNEBOLU",
        "STREET": "YUKARIHATİPBAĞI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "İNEBOLU",
        "STREET": "YUKARIKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "İNEBOLU",
        "STREET": "YUKARIÇAYLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "İNEBOLU",
        "STREET": "YUNUSKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "İNEBOLU",
        "STREET": "YUVACIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "İNEBOLU",
        "STREET": "ÇAMDALI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "İNEBOLU",
        "STREET": "ÇAMLICA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "İNEBOLU",
        "STREET": "ÇAYDÜZÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "İNEBOLU",
        "STREET": "ÇAYKIYI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "İNEBOLU",
        "STREET": "ÇUBUK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "İNEBOLU",
        "STREET": "ÇİÇEKYAZI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "İNEBOLU",
        "STREET": "ÖRTÜLÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "İNEBOLU",
        "STREET": "ÖZBAŞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "İNEBOLU",
        "STREET": "ÖZLÜCE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "İNEBOLU",
        "STREET": "ÜÇEVLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "İNEBOLU",
        "STREET": "ÜÇLÜCE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "İNEBOLU",
        "STREET": "İKİYAKA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "İNEBOLU",
        "STREET": "İKİZLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "İNEBOLU",
        "STREET": "ŞAMALI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "İNEBOLU",
        "STREET": "ŞAMAOĞLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "İNEBOLU",
        "STREET": "ŞEYHÖMER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "ŞENPAZAR",
        "STREET": "ALANCIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "ŞENPAZAR",
        "STREET": "AYBASAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "ŞENPAZAR",
        "STREET": "AZ.KALAYCI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "ŞENPAZAR",
        "STREET": "AŞIKLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "ŞENPAZAR",
        "STREET": "BAŞÇAVUŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "ŞENPAZAR",
        "STREET": "BÜYÜKMUTLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "ŞENPAZAR",
        "STREET": "CELALLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "ŞENPAZAR",
        "STREET": "DAĞLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "ŞENPAZAR",
        "STREET": "DEMİRKAYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "ŞENPAZAR",
        "STREET": "DEREKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "ŞENPAZAR",
        "STREET": "DÖRDÜL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "ŞENPAZAR",
        "STREET": "DİBEKLİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "ŞENPAZAR",
        "STREET": "EDELER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "ŞENPAZAR",
        "STREET": "FURUNCUK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "ŞENPAZAR",
        "STREET": "GÜNEYTEKKE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "ŞENPAZAR",
        "STREET": "GÜRLEYİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "ŞENPAZAR",
        "STREET": "GÜRPELİT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "ŞENPAZAR",
        "STREET": "HARMANGERİŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "ŞENPAZAR",
        "STREET": "HİMMET KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "ŞENPAZAR",
        "STREET": "KARAMAN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "ŞENPAZAR",
        "STREET": "KORUCAK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "ŞENPAZAR",
        "STREET": "KUZTEKKE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "ŞENPAZAR",
        "STREET": "KÜÇÜKMUTLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "ŞENPAZAR",
        "STREET": "MERKEZ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "ŞENPAZAR",
        "STREET": "SALMAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "ŞENPAZAR",
        "STREET": "SEFER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "ŞENPAZAR",
        "STREET": "TEPECİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "ŞENPAZAR",
        "STREET": "UZUNYOL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "ŞENPAZAR",
        "STREET": "YARIMCA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KASTAMONU",
        "DISTRICT": "ŞENPAZAR",
        "STREET": "ÇEVRİK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "AKKIŞLA",
        "STREET": "AKİN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "AKKIŞLA",
        "STREET": "ALEVKIŞLA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "AKKIŞLA",
        "STREET": "GANİŞEYH MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "AKKIŞLA",
        "STREET": "GÖMÜRGEN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "AKKIŞLA",
        "STREET": "GÖMÜRGEN YENİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "AKKIŞLA",
        "STREET": "GÜMÜŞSU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "AKKIŞLA",
        "STREET": "GİRİNCİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "AKKIŞLA",
        "STREET": "KULULU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "AKKIŞLA",
        "STREET": "MANAVUZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "AKKIŞLA",
        "STREET": "ORTAKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "AKKIŞLA",
        "STREET": "UĞURLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "AKKIŞLA",
        "STREET": "YENİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "AKKIŞLA",
        "STREET": "YEŞİL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "AKKIŞLA",
        "STREET": "YUKARI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "AKKIŞLA",
        "STREET": "ŞEN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "BÜNYAN",
        "STREET": "AKMESCİT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "BÜNYAN",
        "STREET": "AKÇATI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "BÜNYAN",
        "STREET": "ASMAKAYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "BÜNYAN",
        "STREET": "AĞCALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "BÜNYAN",
        "STREET": "BAYRAMLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "BÜNYAN",
        "STREET": "BURHANİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "BÜNYAN",
        "STREET": "BÜYÜKTUZHİSAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "BÜNYAN",
        "STREET": "CAMİİCEDİT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "BÜNYAN",
        "STREET": "CAMİİKEBİR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "BÜNYAN",
        "STREET": "CUMHURİYET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "BÜNYAN",
        "STREET": "DAĞARDI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "BÜNYAN",
        "STREET": "DERVİŞAĞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "BÜNYAN",
        "STREET": "DOĞANLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "BÜNYAN",
        "STREET": "EKİNCİLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "BÜNYAN",
        "STREET": "ELBAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "BÜNYAN",
        "STREET": "EMİRÖREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "BÜNYAN",
        "STREET": "FATİH MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "BÜNYAN",
        "STREET": "GERGEME MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "BÜNYAN",
        "STREET": "GÜLLÜCE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "BÜNYAN",
        "STREET": "GİRVELİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "BÜNYAN",
        "STREET": "HAZARŞAH MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "BÜNYAN",
        "STREET": "KAHVECİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "BÜNYAN",
        "STREET": "KARACAÖREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "BÜNYAN",
        "STREET": "KARAHIDIR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "BÜNYAN",
        "STREET": "KARAKAYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "BÜNYAN",
        "STREET": "KARATAY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "BÜNYAN",
        "STREET": "KARDEŞLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "BÜNYAN",
        "STREET": "KAYSERİ KADIN KAPALI CEZA İNFAZ KURUMU",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "BÜNYAN",
        "STREET": "KAYSERİ T1 KAPALI CEZAEVİ İNFAZ KURUMU",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "BÜNYAN",
        "STREET": "KAYSERİ T2 KAPALI CEZA İNFAZ KURUMU",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "BÜNYAN",
        "STREET": "KOYUNABDAL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "BÜNYAN",
        "STREET": "KÖPRÜBAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "BÜNYAN",
        "STREET": "KÖSEHACILI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "BÜNYAN",
        "STREET": "MUSAŞEYH MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "BÜNYAN",
        "STREET": "PİRAHMET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "BÜNYAN",
        "STREET": "SAMAĞIR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "BÜNYAN",
        "STREET": "SAĞLIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "BÜNYAN",
        "STREET": "SIVGIN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "BÜNYAN",
        "STREET": "SULTANHANI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "BÜNYAN",
        "STREET": "SÜMER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "BÜNYAN",
        "STREET": "TOPSÖĞÜT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "BÜNYAN",
        "STREET": "YAKUTİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "BÜNYAN",
        "STREET": "YAVUZ SELİM MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "BÜNYAN",
        "STREET": "YAĞMURBEY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "BÜNYAN",
        "STREET": "YENİ SÜKSÜN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "BÜNYAN",
        "STREET": "YENİCE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "BÜNYAN",
        "STREET": "YÜNÖREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "BÜNYAN",
        "STREET": "İBRAHİMBEY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "BÜNYAN",
        "STREET": "İĞDECİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "DEVELİ",
        "STREET": "ABDULBAKİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "DEVELİ",
        "STREET": "ALPASLAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "DEVELİ",
        "STREET": "AYVAZHACI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "DEVELİ",
        "STREET": "AYŞEPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "DEVELİ",
        "STREET": "AŞAĞIEVEREK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "DEVELİ",
        "STREET": "AŞIK SEYRANİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "DEVELİ",
        "STREET": "BAHÇEBAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "DEVELİ",
        "STREET": "BAHÇELİEVLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "DEVELİ",
        "STREET": "BÜYÜKKÜNYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "DEVELİ",
        "STREET": "CAMİİCEDİT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "DEVELİ",
        "STREET": "CUMHURİYET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "DEVELİ",
        "STREET": "DEREBAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "DEVELİ",
        "STREET": "DEREŞİMLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "DEVELİ",
        "STREET": "DEVELİ CAMİKEBİR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "DEVELİ",
        "STREET": "DEVELİ KAPALI CEZAEVİ",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "DEVELİ",
        "STREET": "E.CAMİİKEBİR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "DEVELİ",
        "STREET": "EPÇE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "DEVELİ",
        "STREET": "EŞELİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "DEVELİ",
        "STREET": "FATİH MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "DEVELİ",
        "STREET": "FENESEAŞAĞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "DEVELİ",
        "STREET": "FENESEYUKARI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "DEVELİ",
        "STREET": "GAZİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "DEVELİ",
        "STREET": "GÖMEDİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "DEVELİ",
        "STREET": "GÜMÜŞÖREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "DEVELİ",
        "STREET": "GÜNEYAŞAĞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "DEVELİ",
        "STREET": "GÜNEYYUKARI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "DEVELİ",
        "STREET": "HARMAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "DEVELİ",
        "STREET": "HAVADAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "DEVELİ",
        "STREET": "HOŞÇA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "DEVELİ",
        "STREET": "HÜSEYİNLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "DEVELİ",
        "STREET": "KABAKLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "DEVELİ",
        "STREET": "KALEKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "DEVELİ",
        "STREET": "KARACAÖREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "DEVELİ",
        "STREET": "KILIÇKAYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "DEVELİ",
        "STREET": "KIZIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "DEVELİ",
        "STREET": "KOPÇULLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "DEVELİ",
        "STREET": "KOZLUCA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "DEVELİ",
        "STREET": "KULPAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "DEVELİ",
        "STREET": "KÖSELER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "DEVELİ",
        "STREET": "KÜÇÜKKÜNYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "DEVELİ",
        "STREET": "MEHMET AKİF ERSOY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "DEVELİ",
        "STREET": "MUSTAFA ASIM KÖKSAL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "DEVELİ",
        "STREET": "MİLLİDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "DEVELİ",
        "STREET": "REŞADİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "DEVELİ",
        "STREET": "SARAYCIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "DEVELİ",
        "STREET": "SARICA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "DEVELİ",
        "STREET": "SARIKAYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "DEVELİ",
        "STREET": "SATI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "DEVELİ",
        "STREET": "SOYSALLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "DEVELİ",
        "STREET": "SİNDELHÖYÜK CAMİKEBİR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "DEVELİ",
        "STREET": "SİNDELHÖYÜK FATİH MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "DEVELİ",
        "STREET": "SİNDELHÖYÜK YENİCE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "DEVELİ",
        "STREET": "TAŞCI BUCAK MERKEZİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "DEVELİ",
        "STREET": "TOMBAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "DEVELİ",
        "STREET": "YAYLACIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "DEVELİ",
        "STREET": "YAZIBAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "DEVELİ",
        "STREET": "YEDEK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "DEVELİ",
        "STREET": "YENİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "DEVELİ",
        "STREET": "YENİCE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "DEVELİ",
        "STREET": "YENİHAYAT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "DEVELİ",
        "STREET": "YENİKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "DEVELİ",
        "STREET": "YEŞİLYURT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "DEVELİ",
        "STREET": "YÜZEVLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "DEVELİ",
        "STREET": "ZİLE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "DEVELİ",
        "STREET": "ÇADIRYERİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "DEVELİ",
        "STREET": "ÇATALOLUK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "DEVELİ",
        "STREET": "ÇAYIRÖZÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "DEVELİ",
        "STREET": "ÇAYLICA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "DEVELİ",
        "STREET": "ÇOMAKLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "DEVELİ",
        "STREET": "ÇUKURYURT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "DEVELİ",
        "STREET": "ÇÖTEN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "DEVELİ",
        "STREET": "ÖKSÜT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "DEVELİ",
        "STREET": "İBRAHİMAĞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "DEVELİ",
        "STREET": "İNCESU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "DEVELİ",
        "STREET": "İZMİRLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "DEVELİ",
        "STREET": "ŞAHMELİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "DEVELİ",
        "STREET": "ŞHT.ÜSTĞM.HASAN ŞAHAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "DEVELİ",
        "STREET": "ŞIHLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "FELAHİYE",
        "STREET": "ACIRLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "FELAHİYE",
        "STREET": "ALPARSLAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "FELAHİYE",
        "STREET": "BEYLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "FELAHİYE",
        "STREET": "BÜYÜKTORAMAN HÜRRİYET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "FELAHİYE",
        "STREET": "BÜYÜKTORAMAN İSTİKLAL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "FELAHİYE",
        "STREET": "CUMHURİYET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "FELAHİYE",
        "STREET": "DARILI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "FELAHİYE",
        "STREET": "KALE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "FELAHİYE",
        "STREET": "KARAŞEYH MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "FELAHİYE",
        "STREET": "KAYAPINAR CUMHURİYET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "FELAHİYE",
        "STREET": "KAYAPINAR FATİH SULTAN MEHMET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "FELAHİYE",
        "STREET": "KEPİÇ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "FELAHİYE",
        "STREET": "KURUHÜYÜK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "FELAHİYE",
        "STREET": "MENTEŞE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "FELAHİYE",
        "STREET": "SİLAHTAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "FELAHİYE",
        "STREET": "YENİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "FELAHİYE",
        "STREET": "İSABEY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "HACILAR",
        "STREET": "AKDAM MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "HACILAR",
        "STREET": "AKYAZI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "HACILAR",
        "STREET": "AŞAĞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "HACILAR",
        "STREET": "BEĞENDİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "HACILAR",
        "STREET": "ERCİYES MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "HACILAR",
        "STREET": "HÜRMETCİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "HACILAR",
        "STREET": "KARPUZSEKİSİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "HACILAR",
        "STREET": "ORTA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "HACILAR",
        "STREET": "SAKARÇİFTLİĞİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "HACILAR",
        "STREET": "YEDİAĞAÇ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "HACILAR",
        "STREET": "YENİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "HACILAR",
        "STREET": "YUKARI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "KOCASİNAN",
        "STREET": "AHİ EVRAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "KOCASİNAN",
        "STREET": "AKCATEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "KOCASİNAN",
        "STREET": "AKİN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "KOCASİNAN",
        "STREET": "ALSANCAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "KOCASİNAN",
        "STREET": "AMARAT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "KOCASİNAN",
        "STREET": "ARGINCIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "KOCASİNAN",
        "STREET": "BARBAROS MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "KOCASİNAN",
        "STREET": "BARSAMA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "KOCASİNAN",
        "STREET": "BAYRAMHACI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "KOCASİNAN",
        "STREET": "BEYAZŞEHİR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "KOCASİNAN",
        "STREET": "BEYDEĞİRMENİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "KOCASİNAN",
        "STREET": "BOYACI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "KOCASİNAN",
        "STREET": "BOZTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "KOCASİNAN",
        "STREET": "BOĞAZKÖPRÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "KOCASİNAN",
        "STREET": "BUĞDAYLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "KOCASİNAN",
        "STREET": "CAMİKEBİR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "KOCASİNAN",
        "STREET": "CIRGALAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "KOCASİNAN",
        "STREET": "DADAĞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "KOCASİNAN",
        "STREET": "DORUKLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "KOCASİNAN",
        "STREET": "DÜVER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "KOCASİNAN",
        "STREET": "EBİÇ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "KOCASİNAN",
        "STREET": "ELAGÖZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "KOCASİNAN",
        "STREET": "ELMALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "KOCASİNAN",
        "STREET": "EMMİLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "KOCASİNAN",
        "STREET": "ERCİYESEVLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "KOCASİNAN",
        "STREET": "ERKİLET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "KOCASİNAN",
        "STREET": "ERTUĞRUL GAZİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "KOCASİNAN",
        "STREET": "ESKİÖMERLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "KOCASİNAN",
        "STREET": "EYİM MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "KOCASİNAN",
        "STREET": "FEVZİ ÇAKMAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "KOCASİNAN",
        "STREET": "FEVZİOĞLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "KOCASİNAN",
        "STREET": "GENERAL EMİR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "KOCASİNAN",
        "STREET": "GEVHER NESİBE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "KOCASİNAN",
        "STREET": "GÖMEÇ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "KOCASİNAN",
        "STREET": "GÜNEŞLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "KOCASİNAN",
        "STREET": "HACI SAKİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "KOCASİNAN",
        "STREET": "HASAN ARPA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "KOCASİNAN",
        "STREET": "HASANCI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "KOCASİNAN",
        "STREET": "HIRKAKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "KOCASİNAN",
        "STREET": "HOCA AHMET YESEVİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "KOCASİNAN",
        "STREET": "HÖBEK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "KOCASİNAN",
        "STREET": "HİMMETDEDE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "KOCASİNAN",
        "STREET": "KALKANCIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "KOCASİNAN",
        "STREET": "KARAHÖYÜK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "KOCASİNAN",
        "STREET": "KARAKİMSE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "KOCASİNAN",
        "STREET": "KAYABAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "KOCASİNAN",
        "STREET": "KAŞKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "KOCASİNAN",
        "STREET": "KEMER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "KOCASİNAN",
        "STREET": "KIZIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "KOCASİNAN",
        "STREET": "KOCASİNAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "KOCASİNAN",
        "STREET": "KUŞCAĞIZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "KOCASİNAN",
        "STREET": "KUŞÇU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "KOCASİNAN",
        "STREET": "MAHZEMİN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "KOCASİNAN",
        "STREET": "MEVLANA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "KOCASİNAN",
        "STREET": "MOLLAHACI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "KOCASİNAN",
        "STREET": "MOLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "KOCASİNAN",
        "STREET": "MİMARSİNAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "KOCASİNAN",
        "STREET": "MİTHATPAŞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "KOCASİNAN",
        "STREET": "OBRUK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "KOCASİNAN",
        "STREET": "ORUÇREİS MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "KOCASİNAN",
        "STREET": "OSMANGAZİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "KOCASİNAN",
        "STREET": "OYMAAĞAÇ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "KOCASİNAN",
        "STREET": "SAHABİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "KOCASİNAN",
        "STREET": "SALUR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "KOCASİNAN",
        "STREET": "SANAYİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "KOCASİNAN",
        "STREET": "SANCAKTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "KOCASİNAN",
        "STREET": "SARAY BOSNA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "KOCASİNAN",
        "STREET": "SARAYCIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "KOCASİNAN",
        "STREET": "SEYRANİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "KOCASİNAN",
        "STREET": "SÜMER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "KOCASİNAN",
        "STREET": "TALATPAŞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "KOCASİNAN",
        "STREET": "TAŞHAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "KOCASİNAN",
        "STREET": "TURGUT REİS MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "KOCASİNAN",
        "STREET": "UĞUREVLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "KOCASİNAN",
        "STREET": "VATAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "KOCASİNAN",
        "STREET": "YAKUT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "KOCASİNAN",
        "STREET": "YAVUZ SELİM MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "KOCASİNAN",
        "STREET": "YAVUZLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "KOCASİNAN",
        "STREET": "YAZIR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "KOCASİNAN",
        "STREET": "YEMLİHA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "KOCASİNAN",
        "STREET": "YENİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "KOCASİNAN",
        "STREET": "YENİDOĞAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "KOCASİNAN",
        "STREET": "YENİŞEHİR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "KOCASİNAN",
        "STREET": "YEŞİL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "KOCASİNAN",
        "STREET": "YILDIZEVLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "KOCASİNAN",
        "STREET": "YUKARIHASİNLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "KOCASİNAN",
        "STREET": "YUNUS EMRE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "KOCASİNAN",
        "STREET": "YUVALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "KOCASİNAN",
        "STREET": "YÜREĞİL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "KOCASİNAN",
        "STREET": "ZÜMRÜT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "KOCASİNAN",
        "STREET": "ZİYAGÖKALP MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "KOCASİNAN",
        "STREET": "ÇEVRİL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "KOCASİNAN",
        "STREET": "ŞEKER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "MELİKGAZİ",
        "STREET": "19 MAYIS MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "MELİKGAZİ",
        "STREET": "30 AĞUSTOS MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "MELİKGAZİ",
        "STREET": "ALPASLAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "MELİKGAZİ",
        "STREET": "ALTINOLUK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "MELİKGAZİ",
        "STREET": "ANAFARTALAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "MELİKGAZİ",
        "STREET": "ANBAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "MELİKGAZİ",
        "STREET": "AYDINLIKEVLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "MELİKGAZİ",
        "STREET": "AĞIRNAS MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "MELİKGAZİ",
        "STREET": "BAHÇELİEVLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "MELİKGAZİ",
        "STREET": "BATTALGAZİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "MELİKGAZİ",
        "STREET": "BAĞPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "MELİKGAZİ",
        "STREET": "BECEN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "MELİKGAZİ",
        "STREET": "BÜYÜK BÜRÜNGÜZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "MELİKGAZİ",
        "STREET": "CUMHURİYET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "MELİKGAZİ",
        "STREET": "DANİŞMENTGAZİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "MELİKGAZİ",
        "STREET": "DEMOKRASİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "MELİKGAZİ",
        "STREET": "ERENKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "MELİKGAZİ",
        "STREET": "ESENTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "MELİKGAZİ",
        "STREET": "ESENYURT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "MELİKGAZİ",
        "STREET": "EĞRİBUCAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "MELİKGAZİ",
        "STREET": "FATİH MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "MELİKGAZİ",
        "STREET": "GERMİR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "MELİKGAZİ",
        "STREET": "GESİ FATİH MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "MELİKGAZİ",
        "STREET": "GESİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "MELİKGAZİ",
        "STREET": "GÖKKENT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "MELİKGAZİ",
        "STREET": "GÜLTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "MELİKGAZİ",
        "STREET": "GÜLÜK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "MELİKGAZİ",
        "STREET": "GÜRPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "MELİKGAZİ",
        "STREET": "GÜZELKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "MELİKGAZİ",
        "STREET": "HUNAT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "MELİKGAZİ",
        "STREET": "HÜRRİYET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "MELİKGAZİ",
        "STREET": "HİSARCIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "MELİKGAZİ",
        "STREET": "KAYABAĞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "MELİKGAZİ",
        "STREET": "KAYSERİ KAPALI CEZAEVİ",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "MELİKGAZİ",
        "STREET": "KAZIMKARABEKİR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "MELİKGAZİ",
        "STREET": "KEYKUBAT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "MELİKGAZİ",
        "STREET": "KILIÇASLAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "MELİKGAZİ",
        "STREET": "KIRANARDI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "MELİKGAZİ",
        "STREET": "KOCATEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "MELİKGAZİ",
        "STREET": "KÖŞK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "MELİKGAZİ",
        "STREET": "KÜÇÜK BÜRÜNGÜZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "MELİKGAZİ",
        "STREET": "MİMARSİNAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "MELİKGAZİ",
        "STREET": "OSMAN KAVUNCU  MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "MELİKGAZİ",
        "STREET": "OSMANLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "MELİKGAZİ",
        "STREET": "SAKARYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "MELİKGAZİ",
        "STREET": "SARIMSAKLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "MELİKGAZİ",
        "STREET": "SELÇUKLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "MELİKGAZİ",
        "STREET": "SELİMİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "MELİKGAZİ",
        "STREET": "SUBAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "MELİKGAZİ",
        "STREET": "TACETTİNVELİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "MELİKGAZİ",
        "STREET": "TAVLUSUN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "MELİKGAZİ",
        "STREET": "TINAZTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "MELİKGAZİ",
        "STREET": "TURAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "MELİKGAZİ",
        "STREET": "VEKSE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "MELİKGAZİ",
        "STREET": "YENİKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "MELİKGAZİ",
        "STREET": "YEŞİLYURT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "MELİKGAZİ",
        "STREET": "YILDIRIM BEYAZIT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "MELİKGAZİ",
        "STREET": "İLDEM CUMHURİYET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "MELİKGAZİ",
        "STREET": "ŞİRİNTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "PINARBAŞI",
        "STREET": "AKPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "PINARBAŞI",
        "STREET": "AKÖREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "PINARBAŞI",
        "STREET": "ALAGAZİLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "PINARBAŞI",
        "STREET": "ALAMESCİT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "PINARBAŞI",
        "STREET": "ALTIKESEK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "PINARBAŞI",
        "STREET": "ARSLANBEYLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "PINARBAŞI",
        "STREET": "ARTMAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "PINARBAŞI",
        "STREET": "AVŞARPOTUKLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "PINARBAŞI",
        "STREET": "AYGÖRMEZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "PINARBAŞI",
        "STREET": "AŞAĞIBEYÇAYIRI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "PINARBAŞI",
        "STREET": "AŞAĞIBORANDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "PINARBAŞI",
        "STREET": "AŞAĞIKARAGÖZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "PINARBAŞI",
        "STREET": "AŞAĞIKIZILÇEVLİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "PINARBAŞI",
        "STREET": "BAHÇECİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "PINARBAŞI",
        "STREET": "BESEREK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "PINARBAŞI",
        "STREET": "BÜYÜKGÜMÜŞGÜN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "PINARBAŞI",
        "STREET": "BÜYÜKGÜRLEĞEN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "PINARBAŞI",
        "STREET": "BÜYÜKKABAKTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "PINARBAŞI",
        "STREET": "BÜYÜKKARAMANLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "PINARBAŞI",
        "STREET": "BÜYÜKKARAMUKLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "PINARBAŞI",
        "STREET": "BÜYÜKKÖMARMUT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "PINARBAŞI",
        "STREET": "BÜYÜKPOTUKLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "PINARBAŞI",
        "STREET": "CABE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "PINARBAŞI",
        "STREET": "CİNAHMET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "PINARBAŞI",
        "STREET": "CİNLİÖREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "PINARBAŞI",
        "STREET": "DEMİRCİLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "PINARBAŞI",
        "STREET": "DEMİRCİÖREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "PINARBAŞI",
        "STREET": "DEVEDERESİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "PINARBAŞI",
        "STREET": "DİKİLİTAŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "PINARBAŞI",
        "STREET": "DİLCİLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "PINARBAŞI",
        "STREET": "ELMALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "PINARBAŞI",
        "STREET": "EMEĞİL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "PINARBAŞI",
        "STREET": "ESENKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "PINARBAŞI",
        "STREET": "ESKİTEKKE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "PINARBAŞI",
        "STREET": "ESKİYASSIPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "PINARBAŞI",
        "STREET": "EĞRİSÖĞÜT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "PINARBAŞI",
        "STREET": "FAKIEKİNCİLİĞİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "PINARBAŞI",
        "STREET": "GEBELEK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "PINARBAŞI",
        "STREET": "GÖLCÜK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "PINARBAŞI",
        "STREET": "GÜLTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "PINARBAŞI",
        "STREET": "GÜMÜŞGÜN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "PINARBAŞI",
        "STREET": "GÜNEY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "PINARBAŞI",
        "STREET": "HALİTBEYÖREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "PINARBAŞI",
        "STREET": "HAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "PINARBAŞI",
        "STREET": "HASIRCI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "PINARBAŞI",
        "STREET": "HASSA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "PINARBAŞI",
        "STREET": "HAYRİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "PINARBAŞI",
        "STREET": "HÜRRİYET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "PINARBAŞI",
        "STREET": "HİLMİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "PINARBAŞI",
        "STREET": "KADILI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "PINARBAŞI",
        "STREET": "KAFTANGİYEN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "PINARBAŞI",
        "STREET": "KAMAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "PINARBAŞI",
        "STREET": "KARABOĞAZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "PINARBAŞI",
        "STREET": "KARAHACILI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "PINARBAŞI",
        "STREET": "KARAHALKA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "PINARBAŞI",
        "STREET": "KARAKUYU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "PINARBAŞI",
        "STREET": "KAVAKKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "PINARBAŞI",
        "STREET": "KAVLAKLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "PINARBAŞI",
        "STREET": "KAYAALTI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "PINARBAŞI",
        "STREET": "KAYABAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "PINARBAŞI",
        "STREET": "KAYAÖNÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "PINARBAŞI",
        "STREET": "KAYNAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "PINARBAŞI",
        "STREET": "KAZANCIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "PINARBAŞI",
        "STREET": "KERİMOĞLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "PINARBAŞI",
        "STREET": "KILIÇKIŞLA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "PINARBAŞI",
        "STREET": "KILIÇMEHMET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "PINARBAŞI",
        "STREET": "KIRKGEÇİT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "PINARBAŞI",
        "STREET": "KIRKPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "PINARBAŞI",
        "STREET": "KIZILDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "PINARBAŞI",
        "STREET": "KIZILHAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "PINARBAŞI",
        "STREET": "KIZILÖREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "PINARBAŞI",
        "STREET": "KURBAĞALIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "PINARBAŞI",
        "STREET": "KURTTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "PINARBAŞI",
        "STREET": "KUŞÇULAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "PINARBAŞI",
        "STREET": "KÖŞKERLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "PINARBAŞI",
        "STREET": "KÜÇÜKKÖMARMUT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "PINARBAŞI",
        "STREET": "KÜÇÜKPOTUKLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "PINARBAŞI",
        "STREET": "MALAKKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "PINARBAŞI",
        "STREET": "MELİKGAZİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "PINARBAŞI",
        "STREET": "METHİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "PINARBAŞI",
        "STREET": "OLUKKAYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "PINARBAŞI",
        "STREET": "ORUÇOĞLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "PINARBAŞI",
        "STREET": "PANLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "PINARBAŞI",
        "STREET": "PAZARSU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "PINARBAŞI",
        "STREET": "PAZARÖREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "PINARBAŞI",
        "STREET": "PAŞALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "PINARBAŞI",
        "STREET": "PULPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "PINARBAŞI",
        "STREET": "SAÇAYAĞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "PINARBAŞI",
        "STREET": "SAÇLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "PINARBAŞI",
        "STREET": "SERİNYURT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "PINARBAŞI",
        "STREET": "SOLAKLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "PINARBAŞI",
        "STREET": "SÖĞÜTLÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "PINARBAŞI",
        "STREET": "TAHTAKÖPRÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "PINARBAŞI",
        "STREET": "TAŞLIGEÇİT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "PINARBAŞI",
        "STREET": "TAŞLIOĞLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "PINARBAŞI",
        "STREET": "TAŞOLUK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "PINARBAŞI",
        "STREET": "TERSAKAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "PINARBAŞI",
        "STREET": "TÖZGÜN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "PINARBAŞI",
        "STREET": "UZUNPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "PINARBAŞI",
        "STREET": "UZUNYOL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "PINARBAŞI",
        "STREET": "YAHYABEY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "PINARBAŞI",
        "STREET": "YARIMTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "PINARBAŞI",
        "STREET": "YAĞLIPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "PINARBAŞI",
        "STREET": "YENİCAMİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "PINARBAŞI",
        "STREET": "YENİOCAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "PINARBAŞI",
        "STREET": "YEREGEÇEN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "PINARBAŞI",
        "STREET": "YUKARIBEYÇAYIRI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "PINARBAŞI",
        "STREET": "YUKARIBORANDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "PINARBAŞI",
        "STREET": "YUKARIKARAGÖZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "PINARBAŞI",
        "STREET": "YUKARIKIZILÇEVLİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "PINARBAŞI",
        "STREET": "YUSUFLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "PINARBAŞI",
        "STREET": "ÇAKILKAYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "PINARBAŞI",
        "STREET": "ÇAYBAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "PINARBAŞI",
        "STREET": "ÇUKURYURT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "PINARBAŞI",
        "STREET": "ÇÖRDÜKLÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "PINARBAŞI",
        "STREET": "ÖRENŞEHİR BUCAK MERKEZİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "PINARBAŞI",
        "STREET": "ÜÇPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "PINARBAŞI",
        "STREET": "İNLİÖREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "PINARBAŞI",
        "STREET": "İNÖNÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "PINARBAŞI",
        "STREET": "İĞDELİPAYASLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "PINARBAŞI",
        "STREET": "ŞABANLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "PINARBAŞI",
        "STREET": "ŞEREFİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "SARIOĞLAN",
        "STREET": "ALAMETTİN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "SARIOĞLAN",
        "STREET": "BAKARCAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "SARIOĞLAN",
        "STREET": "BURUNÖREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "SARIOĞLAN",
        "STREET": "DÜZENCİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "SARIOĞLAN",
        "STREET": "EBÜLHAYIR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "SARIOĞLAN",
        "STREET": "GAZİLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "SARIOĞLAN",
        "STREET": "GÜZELYAZI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "SARIOĞLAN",
        "STREET": "HÜRRİYET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "SARIOĞLAN",
        "STREET": "ILICA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "SARIOĞLAN",
        "STREET": "KADILI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "SARIOĞLAN",
        "STREET": "KALEKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "SARIOĞLAN",
        "STREET": "KARAÖZÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "SARIOĞLAN",
        "STREET": "KARPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "SARIOĞLAN",
        "STREET": "KEKLİKOĞLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "SARIOĞLAN",
        "STREET": "KIZILPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "SARIOĞLAN",
        "STREET": "MURATBEYLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "SARIOĞLAN",
        "STREET": "PALAS MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "SARIOĞLAN",
        "STREET": "SOFUMAHMUT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "SARIOĞLAN",
        "STREET": "TATILI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "SARIOĞLAN",
        "STREET": "YAHYALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "SARIOĞLAN",
        "STREET": "YERLİKUYU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "SARIOĞLAN",
        "STREET": "YILDIRIM MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "SARIOĞLAN",
        "STREET": "ÇİFTLİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "SARIOĞLAN",
        "STREET": "ÖMERHACILI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "SARIOĞLAN",
        "STREET": "ÖMÜRLÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "SARIOĞLAN",
        "STREET": "ÜZERLİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "SARIOĞLAN",
        "STREET": "İĞDELİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "SARIOĞLAN",
        "STREET": "ŞENYURT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "SARIZ",
        "STREET": "AKOLUK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "SARIZ",
        "STREET": "ALTISÖĞÜT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "SARIZ",
        "STREET": "AVŞAROBASI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "SARIZ",
        "STREET": "AYRANLIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "SARIZ",
        "STREET": "BAHÇELİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "SARIZ",
        "STREET": "BÜYÜK SÖBEÇİMEN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "SARIZ",
        "STREET": "BÜYÜK ÖRTÜLÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "SARIZ",
        "STREET": "DALLIKAVAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "SARIZ",
        "STREET": "DAMIZLIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "SARIZ",
        "STREET": "DARIDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "SARIZ",
        "STREET": "DAYOLUK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "SARIZ",
        "STREET": "DEĞİRMENTAŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "SARIZ",
        "STREET": "FETTAHDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "SARIZ",
        "STREET": "GÜMÜŞALİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "SARIZ",
        "STREET": "GÜNESEN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "SARIZ",
        "STREET": "KARAPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "SARIZ",
        "STREET": "KARAYURT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "SARIZ",
        "STREET": "KEMER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "SARIZ",
        "STREET": "KIRKISRAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "SARIZ",
        "STREET": "KISKAÇLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "SARIZ",
        "STREET": "KIZILPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "SARIZ",
        "STREET": "KURDİNİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "SARIZ",
        "STREET": "KURUDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "SARIZ",
        "STREET": "KUŞÇU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "SARIZ",
        "STREET": "KÜÇÜK KABAKTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "SARIZ",
        "STREET": "KÜÇÜK SÖBEÇİMEN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "SARIZ",
        "STREET": "KÜÇÜK ÖRTÜLÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "SARIZ",
        "STREET": "MOLLAHÜSEYİNLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "SARIZ",
        "STREET": "OĞLAKKAYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "SARIZ",
        "STREET": "SANCAKAĞIL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "SARIZ",
        "STREET": "TAVLA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "SARIZ",
        "STREET": "TEKNELİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "SARIZ",
        "STREET": "YAYLACI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "SARIZ",
        "STREET": "YEDİOLUK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "SARIZ",
        "STREET": "YENİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "SARIZ",
        "STREET": "YEŞİLKENT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "SARIZ",
        "STREET": "ÇATALPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "SARIZ",
        "STREET": "ÇAVDAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "SARIZ",
        "STREET": "ÇAĞŞAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "SARIZ",
        "STREET": "ÇÖREKDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "SARIZ",
        "STREET": "ÖRDEKLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "SARIZ",
        "STREET": "İMİRZAAĞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "SARIZ",
        "STREET": "İNCEDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "SARIZ",
        "STREET": "İNCEMAĞARA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "TALAS",
        "STREET": "AKÇAKAYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "TALAS",
        "STREET": "ALAYBEYLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "TALAS",
        "STREET": "ARDIÇ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "TALAS",
        "STREET": "BAHÇELİEVLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "TALAS",
        "STREET": "BAŞAKPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "TALAS",
        "STREET": "CEBİR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "TALAS",
        "STREET": "ENDÜRLÜK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "TALAS",
        "STREET": "HAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "TALAS",
        "STREET": "HARMAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "TALAS",
        "STREET": "KAMBER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "TALAS",
        "STREET": "KEPEZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "TALAS",
        "STREET": "KOÇCAĞIZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "TALAS",
        "STREET": "KURUKÖPRÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "TALAS",
        "STREET": "KİÇİKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "TALAS",
        "STREET": "MENGÜCEK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "TALAS",
        "STREET": "MEVLANA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "TALAS",
        "STREET": "REŞADİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "TALAS",
        "STREET": "SAKALTUTAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "TALAS",
        "STREET": "SOSUN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "TALAS",
        "STREET": "SÜLEYMANLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "TALAS",
        "STREET": "TABLAKAYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "TALAS",
        "STREET": "YAMAÇLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "TALAS",
        "STREET": "YAZYURDU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "TALAS",
        "STREET": "YENİDOĞAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "TALAS",
        "STREET": "YUKARI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "TALAS",
        "STREET": "ZİNCİDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "TALAS",
        "STREET": "ÇATAKDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "TALAS",
        "STREET": "ÇEVLİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "TALAS",
        "STREET": "ÇÖMLEKÇİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "TALAS",
        "STREET": "ÖRENCİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "TOMARZA",
        "STREET": "AKDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "TOMARZA",
        "STREET": "AKMEZAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "TOMARZA",
        "STREET": "ARSLANTAŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "TOMARZA",
        "STREET": "AVŞARSÖĞÜTLÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "TOMARZA",
        "STREET": "BEKTAŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "TOMARZA",
        "STREET": "BOSTANLIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "TOMARZA",
        "STREET": "BÖKE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "TOMARZA",
        "STREET": "BÜYÜKCANLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "TOMARZA",
        "STREET": "CULHA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "TOMARZA",
        "STREET": "CUMHURİYET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "TOMARZA",
        "STREET": "CÜCÜN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "TOMARZA",
        "STREET": "DADALOĞLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "TOMARZA",
        "STREET": "DAĞYURDU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "TOMARZA",
        "STREET": "EKİNLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "TOMARZA",
        "STREET": "EMİRUŞAĞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "TOMARZA",
        "STREET": "GÖKTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "TOMARZA",
        "STREET": "GÜLVEREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "TOMARZA",
        "STREET": "GÜZELCE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "TOMARZA",
        "STREET": "GÜZELSU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "TOMARZA",
        "STREET": "HACIPAŞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "TOMARZA",
        "STREET": "IŞIKLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "TOMARZA",
        "STREET": "KALE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "TOMARZA",
        "STREET": "KAPUKAYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "TOMARZA",
        "STREET": "KARAMUKLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "TOMARZA",
        "STREET": "KARAÖREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "TOMARZA",
        "STREET": "KESİR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "TOMARZA",
        "STREET": "KEVENAĞIL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "TOMARZA",
        "STREET": "KIZILÖREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "TOMARZA",
        "STREET": "KURTULUŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "TOMARZA",
        "STREET": "KUŞCAĞIZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "TOMARZA",
        "STREET": "KÖMÜR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "TOMARZA",
        "STREET": "KÖPRÜBAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "TOMARZA",
        "STREET": "KÜÇÜKCANLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "TOMARZA",
        "STREET": "MELİKÖREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "TOMARZA",
        "STREET": "PUSATLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "TOMARZA",
        "STREET": "SARIMEHMETLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "TOMARZA",
        "STREET": "SÖĞÜTLÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "TOMARZA",
        "STREET": "SÜVEGENLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "TOMARZA",
        "STREET": "TAHTAKEMER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "TOMARZA",
        "STREET": "TATAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "TOMARZA",
        "STREET": "TOKLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "TOMARZA",
        "STREET": "TURANLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "TOMARZA",
        "STREET": "YAVUZSELİM MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "TOMARZA",
        "STREET": "YENİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "TOMARZA",
        "STREET": "YEŞİLBAĞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "TOMARZA",
        "STREET": "ÇANAKPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "TOMARZA",
        "STREET": "ÇAYİNLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "TOMARZA",
        "STREET": "ÇUKURAĞAÇ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "TOMARZA",
        "STREET": "ÜÇKONAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "TOMARZA",
        "STREET": "İCADİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "TOMARZA",
        "STREET": "İMAMKULLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "TOMARZA",
        "STREET": "İNCİLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "TOMARZA",
        "STREET": "ŞIHBARAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "TOMARZA",
        "STREET": "ŞİRAZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "YAHYALI",
        "STREET": "100.YIL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "YAHYALI",
        "STREET": "75.YIL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "YAHYALI",
        "STREET": "AVLAĞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "YAHYALI",
        "STREET": "AĞCAŞAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "YAHYALI",
        "STREET": "BALCIÇAKIRI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "YAHYALI",
        "STREET": "BURHANİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "YAHYALI",
        "STREET": "BÜYÜKÇAKIR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "YAHYALI",
        "STREET": "CAMİKEBİR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "YAHYALI",
        "STREET": "DELİALİUŞAĞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "YAHYALI",
        "STREET": "DENİZOVASI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "YAHYALI",
        "STREET": "DEREBAĞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "YAHYALI",
        "STREET": "DİKME MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "YAHYALI",
        "STREET": "FETULLAH MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "YAHYALI",
        "STREET": "FEVZİÇAKMAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "YAHYALI",
        "STREET": "GAZİBEYLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "YAHYALI",
        "STREET": "KAPUZBAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "YAHYALI",
        "STREET": "KARAKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "YAHYALI",
        "STREET": "KAVACIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "YAHYALI",
        "STREET": "KOCAHACILI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "YAHYALI",
        "STREET": "KOPÇU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "YAHYALI",
        "STREET": "KUZOLUK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "YAHYALI",
        "STREET": "KİRAZLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "YAHYALI",
        "STREET": "MADAZI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "YAHYALI",
        "STREET": "MUSTAFABEYLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "YAHYALI",
        "STREET": "SAZAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "YAHYALI",
        "STREET": "SENİRKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "YAHYALI",
        "STREET": "SEYDİLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "YAHYALI",
        "STREET": "SÜLEYMANFAKILI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "YAHYALI",
        "STREET": "TAŞHAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "YAHYALI",
        "STREET": "ULUPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "YAHYALI",
        "STREET": "YENİCE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "YAHYALI",
        "STREET": "YERKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "YAHYALI",
        "STREET": "YEŞİLKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "YAHYALI",
        "STREET": "YULARI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "YAHYALI",
        "STREET": "ÇAMLICA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "YAHYALI",
        "STREET": "ÇAVDARUŞAĞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "YAHYALI",
        "STREET": "ÇUBUKHARMANI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "YAHYALI",
        "STREET": "ÇUBUKLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "YAHYALI",
        "STREET": "ÇİĞİLLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "YAHYALI",
        "STREET": "İLYASLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "YAHYALI",
        "STREET": "İSMET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "YEŞİLHİSAR",
        "STREET": "AKKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "YEŞİLHİSAR",
        "STREET": "ARAPLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "YEŞİLHİSAR",
        "STREET": "BAŞKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "YEŞİLHİSAR",
        "STREET": "BEYLERYUKARI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "YEŞİLHİSAR",
        "STREET": "BÜGET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "YEŞİLHİSAR",
        "STREET": "CAMİKEBİR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "YEŞİLHİSAR",
        "STREET": "CUMHURİYET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "YEŞİLHİSAR",
        "STREET": "DERBENTBAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "YEŞİLHİSAR",
        "STREET": "DOĞANLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "YEŞİLHİSAR",
        "STREET": "ERDEMLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "YEŞİLHİSAR",
        "STREET": "FATİH MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "YEŞİLHİSAR",
        "STREET": "FEVZİOĞLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "YEŞİLHİSAR",
        "STREET": "GÜLBAYIR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "YEŞİLHİSAR",
        "STREET": "GÜNEY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "YEŞİLHİSAR",
        "STREET": "GÜZELÖZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "YEŞİLHİSAR",
        "STREET": "KALE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "YEŞİLHİSAR",
        "STREET": "KARACABEY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "YEŞİLHİSAR",
        "STREET": "KAVAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "YEŞİLHİSAR",
        "STREET": "KAYADİBİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "YEŞİLHİSAR",
        "STREET": "KELER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "YEŞİLHİSAR",
        "STREET": "KEŞLİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "YEŞİLHİSAR",
        "STREET": "KOVALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "YEŞİLHİSAR",
        "STREET": "KUZEY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "YEŞİLHİSAR",
        "STREET": "KUŞÇU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "YEŞİLHİSAR",
        "STREET": "KÖŞK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "YEŞİLHİSAR",
        "STREET": "MAHMUDİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "YEŞİLHİSAR",
        "STREET": "MUSAHACILI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "YEŞİLHİSAR",
        "STREET": "OVAÇİFTLİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "YEŞİLHİSAR",
        "STREET": "SOĞANLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "YEŞİLHİSAR",
        "STREET": "YAVUZ SELİM MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "YEŞİLHİSAR",
        "STREET": "YENİŞEHİR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "YEŞİLHİSAR",
        "STREET": "YEŞİLOVA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "YEŞİLHİSAR",
        "STREET": "ÇADIRKAYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "YEŞİLHİSAR",
        "STREET": "İDRİS MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "YEŞİLHİSAR",
        "STREET": "İÇMECE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "ÖZVATAN",
        "STREET": "ALPARSLAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "ÖZVATAN",
        "STREET": "AŞAĞI BOĞAZİÇİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "ÖZVATAN",
        "STREET": "BAHÇELİ EVLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "ÖZVATAN",
        "STREET": "CUMHURİYET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "ÖZVATAN",
        "STREET": "GÜNEY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "ÖZVATAN",
        "STREET": "KALE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "ÖZVATAN",
        "STREET": "KAVAKLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "ÖZVATAN",
        "STREET": "KERMELİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "ÖZVATAN",
        "STREET": "KÜPELİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "ÖZVATAN",
        "STREET": "TAŞLIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "ÖZVATAN",
        "STREET": "TUĞLAŞAH MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "ÖZVATAN",
        "STREET": "YENİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "ÖZVATAN",
        "STREET": "YUKARI BOĞAZİÇİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "İNCESU",
        "STREET": "BAHÇELİEVLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "İNCESU",
        "STREET": "BAHÇESARAY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "İNCESU",
        "STREET": "BULGURCU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "İNCESU",
        "STREET": "DOKUZPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "İNCESU",
        "STREET": "FIRINÖNÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "İNCESU",
        "STREET": "GARİPÇE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "İNCESU",
        "STREET": "GÖNENKENT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "İNCESU",
        "STREET": "HAMURCU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "İNCESU",
        "STREET": "KARAHÜYÜK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "İNCESU",
        "STREET": "KARAKOYUNLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "İNCESU",
        "STREET": "KARAMUSTAFAPAŞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "İNCESU",
        "STREET": "KIZILÖREN AŞAĞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "İNCESU",
        "STREET": "KIZILÖREN TABAKLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "İNCESU",
        "STREET": "KIZILÖREN ÖTEBATAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "İNCESU",
        "STREET": "KÜLLÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "İNCESU",
        "STREET": "SARAYCIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "İNCESU",
        "STREET": "SARIKÜRKLÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "İNCESU",
        "STREET": "SEMERKENT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "İNCESU",
        "STREET": "SUBAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "İNCESU",
        "STREET": "SÜKSÜN CUMHURİYET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "İNCESU",
        "STREET": "SÜKSÜN HÜRRİYET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "İNCESU",
        "STREET": "SÜKSÜN ZAFER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "İNCESU",
        "STREET": "TAHİRİNLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "İNCESU",
        "STREET": "VALİ İHSAN ARAS MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "İNCESU",
        "STREET": "YARIM MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "İNCESU",
        "STREET": "YENİ CAMİİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "İNCESU",
        "STREET": "ÇARDAKLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "İNCESU",
        "STREET": "ÖRENŞEHİR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "İNCESU",
        "STREET": "ÜÇKUYU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KAYSERİ",
        "DISTRICT": "İNCESU",
        "STREET": "ŞEYHŞABAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KIRIKKALE",
        "DISTRICT": "BAHŞILI",
        "STREET": "ATADÖNMEZ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KIRIKKALE",
        "DISTRICT": "BAHŞILI",
        "STREET": "DOĞANAY MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KIRIKKALE",
        "DISTRICT": "BAHŞILI",
        "STREET": "HALİM ŞENSES MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KIRIKKALE",
        "DISTRICT": "BAHŞILI",
        "STREET": "HÜSEYİN ONBAŞI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KIRIKKALE",
        "DISTRICT": "BAHŞILI",
        "STREET": "HİDAYET DOĞRUER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KIRIKKALE",
        "DISTRICT": "BAHŞILI",
        "STREET": "KARAAHMETLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRIKKALE",
        "DISTRICT": "BAHŞILI",
        "STREET": "KÜREBOĞAZI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRIKKALE",
        "DISTRICT": "BAHŞILI",
        "STREET": "KÜÇÜKSARIKAYALAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRIKKALE",
        "DISTRICT": "BAHŞILI",
        "STREET": "SARIKAYALAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRIKKALE",
        "DISTRICT": "BAHŞILI",
        "STREET": "ÇAMLICA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRIKKALE",
        "DISTRICT": "BALIŞEYH",
        "STREET": "AKÇAKAVAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRIKKALE",
        "DISTRICT": "BALIŞEYH",
        "STREET": "AYDINŞIH KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRIKKALE",
        "DISTRICT": "BALIŞEYH",
        "STREET": "AŞAĞIKARAKISIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRIKKALE",
        "DISTRICT": "BALIŞEYH",
        "STREET": "BALLI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KIRIKKALE",
        "DISTRICT": "BALIŞEYH",
        "STREET": "BATTALOBA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRIKKALE",
        "DISTRICT": "BALIŞEYH",
        "STREET": "BEYOBASI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRIKKALE",
        "DISTRICT": "BALIŞEYH",
        "STREET": "BEŞBIÇAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRIKKALE",
        "DISTRICT": "BALIŞEYH",
        "STREET": "BIYIKAYDIN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRIKKALE",
        "DISTRICT": "BALIŞEYH",
        "STREET": "DİKMEN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRIKKALE",
        "DISTRICT": "BALIŞEYH",
        "STREET": "ELDELEK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRIKKALE",
        "DISTRICT": "BALIŞEYH",
        "STREET": "HIDIRŞIH KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRIKKALE",
        "DISTRICT": "BALIŞEYH",
        "STREET": "HÜSEYİNOBA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRIKKALE",
        "DISTRICT": "BALIŞEYH",
        "STREET": "IŞIKLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRIKKALE",
        "DISTRICT": "BALIŞEYH",
        "STREET": "KARALI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRIKKALE",
        "DISTRICT": "BALIŞEYH",
        "STREET": "KARGIN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRIKKALE",
        "DISTRICT": "BALIŞEYH",
        "STREET": "KENANOBA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRIKKALE",
        "DISTRICT": "BALIŞEYH",
        "STREET": "KILEVLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRIKKALE",
        "DISTRICT": "BALIŞEYH",
        "STREET": "KIRLANGIÇ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRIKKALE",
        "DISTRICT": "BALIŞEYH",
        "STREET": "KOÇUBABA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRIKKALE",
        "DISTRICT": "BALIŞEYH",
        "STREET": "KULAKSIZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRIKKALE",
        "DISTRICT": "BALIŞEYH",
        "STREET": "KÖSEDURAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRIKKALE",
        "DISTRICT": "BALIŞEYH",
        "STREET": "MEHMETBEYOBASI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRIKKALE",
        "DISTRICT": "BALIŞEYH",
        "STREET": "OBA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KIRIKKALE",
        "DISTRICT": "BALIŞEYH",
        "STREET": "SELAMLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRIKKALE",
        "DISTRICT": "BALIŞEYH",
        "STREET": "TOKİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KIRIKKALE",
        "DISTRICT": "BALIŞEYH",
        "STREET": "ULAKLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRIKKALE",
        "DISTRICT": "BALIŞEYH",
        "STREET": "UZUNLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRIKKALE",
        "DISTRICT": "BALIŞEYH",
        "STREET": "YENİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KIRIKKALE",
        "DISTRICT": "BALIŞEYH",
        "STREET": "YENİCE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRIKKALE",
        "DISTRICT": "BALIŞEYH",
        "STREET": "YENİLLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRIKKALE",
        "DISTRICT": "BALIŞEYH",
        "STREET": "YUKARIKARAKISIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRIKKALE",
        "DISTRICT": "BALIŞEYH",
        "STREET": "İZZETTİN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRIKKALE",
        "DISTRICT": "DELİCE",
        "STREET": "AKBOĞAZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRIKKALE",
        "DISTRICT": "DELİCE",
        "STREET": "AKTEPE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KIRIKKALE",
        "DISTRICT": "DELİCE",
        "STREET": "ALCILI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRIKKALE",
        "DISTRICT": "DELİCE",
        "STREET": "ARBİŞLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRIKKALE",
        "DISTRICT": "DELİCE",
        "STREET": "AŞAĞIİHSANGAZİLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRIKKALE",
        "DISTRICT": "DELİCE",
        "STREET": "BARAKLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRIKKALE",
        "DISTRICT": "DELİCE",
        "STREET": "BOZKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "KIRIKKALE",
        "DISTRICT": "DELİCE",
        "STREET": "BÜYÜKAFŞAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRIKKALE",
        "DISTRICT": "DELİCE",
        "STREET": "BÜYÜKYAĞLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRIKKALE",
        "DISTRICT": "DELİCE",
        "STREET": "COĞUL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRIKKALE",
        "DISTRICT": "DELİCE",
        "STREET": "CUMHURİYET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KIRIKKALE",
        "DISTRICT": "DELİCE",
        "STREET": "DAĞOBASI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRIKKALE",
        "DISTRICT": "DELİCE",
        "STREET": "DELİCE CEZAİNFAZ KURUMU",
        "TYPE": "0"
      },
      {
        "CITY": "KIRIKKALE",
        "DISTRICT": "DELİCE",
        "STREET": "DEREKIŞLA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRIKKALE",
        "DISTRICT": "DELİCE",
        "STREET": "DOĞANÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRIKKALE",
        "DISTRICT": "DELİCE",
        "STREET": "ELMALI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRIKKALE",
        "DISTRICT": "DELİCE",
        "STREET": "EVLİYALI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRIKKALE",
        "DISTRICT": "DELİCE",
        "STREET": "FADILOBASI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRIKKALE",
        "DISTRICT": "DELİCE",
        "STREET": "FATİH MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KIRIKKALE",
        "DISTRICT": "DELİCE",
        "STREET": "FEVZİ ÇAKMAK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KIRIKKALE",
        "DISTRICT": "DELİCE",
        "STREET": "GÖZÜKIZILLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRIKKALE",
        "DISTRICT": "DELİCE",
        "STREET": "GÜNDÜZ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KIRIKKALE",
        "DISTRICT": "DELİCE",
        "STREET": "HACIOBASI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRIKKALE",
        "DISTRICT": "DELİCE",
        "STREET": "HALİTLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRIKKALE",
        "DISTRICT": "DELİCE",
        "STREET": "HEREKLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRIKKALE",
        "DISTRICT": "DELİCE",
        "STREET": "HÜRRİYET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KIRIKKALE",
        "DISTRICT": "DELİCE",
        "STREET": "KARABEKİR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KIRIKKALE",
        "DISTRICT": "DELİCE",
        "STREET": "KARAKÖSELİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRIKKALE",
        "DISTRICT": "DELİCE",
        "STREET": "KARPUZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRIKKALE",
        "DISTRICT": "DELİCE",
        "STREET": "KAVAKKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "KIRIKKALE",
        "DISTRICT": "DELİCE",
        "STREET": "KOCABAŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRIKKALE",
        "DISTRICT": "DELİCE",
        "STREET": "KURTOĞLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRIKKALE",
        "DISTRICT": "DELİCE",
        "STREET": "KUZAYYURT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRIKKALE",
        "DISTRICT": "DELİCE",
        "STREET": "KÜÇÜKAFŞAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRIKKALE",
        "DISTRICT": "DELİCE",
        "STREET": "MEŞEYAYLA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRIKKALE",
        "DISTRICT": "DELİCE",
        "STREET": "MİMAR SİNAN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KIRIKKALE",
        "DISTRICT": "DELİCE",
        "STREET": "OCAKBAŞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRIKKALE",
        "DISTRICT": "DELİCE",
        "STREET": "SARIYAKA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRIKKALE",
        "DISTRICT": "DELİCE",
        "STREET": "TATLICAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRIKKALE",
        "DISTRICT": "DELİCE",
        "STREET": "TAVAÖZÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRIKKALE",
        "DISTRICT": "DELİCE",
        "STREET": "TAŞYAZI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRIKKALE",
        "DISTRICT": "DELİCE",
        "STREET": "TEKKEKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "KIRIKKALE",
        "DISTRICT": "DELİCE",
        "STREET": "YAYLAYURT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRIKKALE",
        "DISTRICT": "DELİCE",
        "STREET": "YENİYAPAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRIKKALE",
        "DISTRICT": "DELİCE",
        "STREET": "ÇATALLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRIKKALE",
        "DISTRICT": "DELİCE",
        "STREET": "ÇATALLIKARAKOYUNLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRIKKALE",
        "DISTRICT": "DELİCE",
        "STREET": "ÇERİKLİ-GÖKÇEBEL MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KIRIKKALE",
        "DISTRICT": "DELİCE",
        "STREET": "ÇERİKLİ-MERKEZ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KIRIKKALE",
        "DISTRICT": "DELİCE",
        "STREET": "ÇERİKLİ-SAKARYA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KIRIKKALE",
        "DISTRICT": "DELİCE",
        "STREET": "ÇERİKLİ-YENİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KIRIKKALE",
        "DISTRICT": "DELİCE",
        "STREET": "ÇERİKLİ-YERLİKENT MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KIRIKKALE",
        "DISTRICT": "DELİCE",
        "STREET": "ÇONGAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRIKKALE",
        "DISTRICT": "DELİCE",
        "STREET": "İMİRLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRIKKALE",
        "DISTRICT": "DELİCE",
        "STREET": "ŞAHCALI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRIKKALE",
        "DISTRICT": "KARAKEÇİLİ",
        "STREET": "AKKOŞAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRIKKALE",
        "DISTRICT": "KARAKEÇİLİ",
        "STREET": "FATİH SULTAN MEHMET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KIRIKKALE",
        "DISTRICT": "KARAKEÇİLİ",
        "STREET": "HOCA AHMET YESEVİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KIRIKKALE",
        "DISTRICT": "KARAKEÇİLİ",
        "STREET": "KARŞIYAKA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KIRIKKALE",
        "DISTRICT": "KARAKEÇİLİ",
        "STREET": "KURTULUŞ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KIRIKKALE",
        "DISTRICT": "KARAKEÇİLİ",
        "STREET": "MAREŞAL FEVZİ ÇAKMAK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KIRIKKALE",
        "DISTRICT": "KARAKEÇİLİ",
        "STREET": "SULUBÜK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRIKKALE",
        "DISTRICT": "KARAKEÇİLİ",
        "STREET": "YAVUZ SULTAN SELİM MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KIRIKKALE",
        "DISTRICT": "KARAKEÇİLİ",
        "STREET": "YEŞİLEVLER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KIRIKKALE",
        "DISTRICT": "KARAKEÇİLİ",
        "STREET": "ZAFER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KIRIKKALE",
        "DISTRICT": "KESKİN",
        "STREET": "ALTUNTAŞ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KIRIKKALE",
        "DISTRICT": "KESKİN",
        "STREET": "ARMUTLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRIKKALE",
        "DISTRICT": "KESKİN",
        "STREET": "AŞAĞIŞIH KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRIKKALE",
        "DISTRICT": "KESKİN",
        "STREET": "BARAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRIKKALE",
        "DISTRICT": "KESKİN",
        "STREET": "BARAKOBASI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRIKKALE",
        "DISTRICT": "KESKİN",
        "STREET": "BEŞLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRIKKALE",
        "DISTRICT": "KESKİN",
        "STREET": "BOZKURT MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KIRIKKALE",
        "DISTRICT": "KESKİN",
        "STREET": "BÜYÜKCECELİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRIKKALE",
        "DISTRICT": "KESKİN",
        "STREET": "CABATOBASI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRIKKALE",
        "DISTRICT": "KESKİN",
        "STREET": "CANKURTARAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRIKKALE",
        "DISTRICT": "KESKİN",
        "STREET": "CEBRAİLLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRIKKALE",
        "DISTRICT": "KESKİN",
        "STREET": "CERİTKALE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRIKKALE",
        "DISTRICT": "KESKİN",
        "STREET": "CERİTMÜMİNLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRIKKALE",
        "DISTRICT": "KESKİN",
        "STREET": "CERİTOBASI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRIKKALE",
        "DISTRICT": "KESKİN",
        "STREET": "CİNALİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRIKKALE",
        "DISTRICT": "KESKİN",
        "STREET": "DANACIOBASI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRIKKALE",
        "DISTRICT": "KESKİN",
        "STREET": "DAĞSOLAKLISI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRIKKALE",
        "DISTRICT": "KESKİN",
        "STREET": "EFENDİKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "KIRIKKALE",
        "DISTRICT": "KESKİN",
        "STREET": "EMİNEFENDİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRIKKALE",
        "DISTRICT": "KESKİN",
        "STREET": "EROĞLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRIKKALE",
        "DISTRICT": "KESKİN",
        "STREET": "ESATMÜMİNLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRIKKALE",
        "DISTRICT": "KESKİN",
        "STREET": "ESKİALİBUDAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRIKKALE",
        "DISTRICT": "KESKİN",
        "STREET": "GAZİBEYLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRIKKALE",
        "DISTRICT": "KESKİN",
        "STREET": "GÖKTAŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRIKKALE",
        "DISTRICT": "KESKİN",
        "STREET": "GÖÇBEYLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRIKKALE",
        "DISTRICT": "KESKİN",
        "STREET": "GÜLKONAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRIKKALE",
        "DISTRICT": "KESKİN",
        "STREET": "GÜNDOĞDU MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KIRIKKALE",
        "DISTRICT": "KESKİN",
        "STREET": "HACIALİOBASI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRIKKALE",
        "DISTRICT": "KESKİN",
        "STREET": "HACIÖMERSOLAKLISI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRIKKALE",
        "DISTRICT": "KESKİN",
        "STREET": "HAYDARDEDE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRIKKALE",
        "DISTRICT": "KESKİN",
        "STREET": "KARAFAKILI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRIKKALE",
        "DISTRICT": "KESKİN",
        "STREET": "KASIMAĞA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRIKKALE",
        "DISTRICT": "KESKİN",
        "STREET": "KAVLAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRIKKALE",
        "DISTRICT": "KESKİN",
        "STREET": "KAVURGALI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRIKKALE",
        "DISTRICT": "KESKİN",
        "STREET": "KAYALAKSOLAKLISI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRIKKALE",
        "DISTRICT": "KESKİN",
        "STREET": "KAÇAKKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "KIRIKKALE",
        "DISTRICT": "KESKİN",
        "STREET": "KESKİN CEZA VE İNFAZ KURUMU",
        "TYPE": "0"
      },
      {
        "CITY": "KIRIKKALE",
        "DISTRICT": "KESKİN",
        "STREET": "KEVENLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRIKKALE",
        "DISTRICT": "KESKİN",
        "STREET": "KONUR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRIKKALE",
        "DISTRICT": "KESKİN",
        "STREET": "KONURHACIOBASI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRIKKALE",
        "DISTRICT": "KESKİN",
        "STREET": "KURŞUNKAYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRIKKALE",
        "DISTRICT": "KESKİN",
        "STREET": "KUZUGÜDENLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRIKKALE",
        "DISTRICT": "KESKİN",
        "STREET": "KÖPRÜKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "KIRIKKALE",
        "DISTRICT": "KESKİN",
        "STREET": "MADEN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KIRIKKALE",
        "DISTRICT": "KESKİN",
        "STREET": "MÜSELLİM KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRIKKALE",
        "DISTRICT": "KESKİN",
        "STREET": "OLUNLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRIKKALE",
        "DISTRICT": "KESKİN",
        "STREET": "ORTASÖKEN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRIKKALE",
        "DISTRICT": "KESKİN",
        "STREET": "POLATYURDU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRIKKALE",
        "DISTRICT": "KESKİN",
        "STREET": "SEYFLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRIKKALE",
        "DISTRICT": "KESKİN",
        "STREET": "TAKAZLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRIKKALE",
        "DISTRICT": "KESKİN",
        "STREET": "TURHANLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRIKKALE",
        "DISTRICT": "KESKİN",
        "STREET": "YENİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KIRIKKALE",
        "DISTRICT": "KESKİN",
        "STREET": "YENİALİBUDAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRIKKALE",
        "DISTRICT": "KESKİN",
        "STREET": "YENİCE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KIRIKKALE",
        "DISTRICT": "KESKİN",
        "STREET": "YENİYAPAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRIKKALE",
        "DISTRICT": "KESKİN",
        "STREET": "YONCALI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRIKKALE",
        "DISTRICT": "KESKİN",
        "STREET": "ÇALIŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRIKKALE",
        "DISTRICT": "KESKİN",
        "STREET": "ÇAMURABATMAZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRIKKALE",
        "DISTRICT": "KESKİN",
        "STREET": "ÇİPİDERESİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRIKKALE",
        "DISTRICT": "KESKİN",
        "STREET": "ÜÇEVLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRIKKALE",
        "DISTRICT": "KESKİN",
        "STREET": "ÜÇKUYU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRIKKALE",
        "DISTRICT": "KESKİN",
        "STREET": "İNZİLOĞLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRIKKALE",
        "DISTRICT": "MERKEZ",
        "STREET": "AHILI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRIKKALE",
        "DISTRICT": "MERKEZ",
        "STREET": "AKŞEMSETTİN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KIRIKKALE",
        "DISTRICT": "MERKEZ",
        "STREET": "AŞAĞIMAHMUTLAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KIRIKKALE",
        "DISTRICT": "MERKEZ",
        "STREET": "BAHÇELİEVLER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KIRIKKALE",
        "DISTRICT": "MERKEZ",
        "STREET": "BAĞLARBAŞI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KIRIKKALE",
        "DISTRICT": "MERKEZ",
        "STREET": "DAĞEVİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRIKKALE",
        "DISTRICT": "MERKEZ",
        "STREET": "ETİLER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KIRIKKALE",
        "DISTRICT": "MERKEZ",
        "STREET": "FABRİKALAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KIRIKKALE",
        "DISTRICT": "MERKEZ",
        "STREET": "FATİH MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KIRIKKALE",
        "DISTRICT": "MERKEZ",
        "STREET": "GÜNDOĞDU MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KIRIKKALE",
        "DISTRICT": "MERKEZ",
        "STREET": "GÜRLER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KIRIKKALE",
        "DISTRICT": "MERKEZ",
        "STREET": "GÜZELTEPE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KIRIKKALE",
        "DISTRICT": "MERKEZ",
        "STREET": "HACILAR-ALTINOVA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KIRIKKALE",
        "DISTRICT": "MERKEZ",
        "STREET": "HACILAR-HACIBEY MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KIRIKKALE",
        "DISTRICT": "MERKEZ",
        "STREET": "HACILAR-SAĞLIK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KIRIKKALE",
        "DISTRICT": "MERKEZ",
        "STREET": "HASANDEDE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRIKKALE",
        "DISTRICT": "MERKEZ",
        "STREET": "HÜSEYİN KAHYA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KIRIKKALE",
        "DISTRICT": "MERKEZ",
        "STREET": "KALETEPE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KIRIKKALE",
        "DISTRICT": "MERKEZ",
        "STREET": "KARACAALİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRIKKALE",
        "DISTRICT": "MERKEZ",
        "STREET": "KARŞIYAKA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KIRIKKALE",
        "DISTRICT": "MERKEZ",
        "STREET": "KAZMACA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRIKKALE",
        "DISTRICT": "MERKEZ",
        "STREET": "KIRIKKÖYÜ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KIRIKKALE",
        "DISTRICT": "MERKEZ",
        "STREET": "KIZILDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRIKKALE",
        "DISTRICT": "MERKEZ",
        "STREET": "KIZILIRMAK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KIRIKKALE",
        "DISTRICT": "MERKEZ",
        "STREET": "KURTULUŞ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KIRIKKALE",
        "DISTRICT": "MERKEZ",
        "STREET": "KİMESKİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KIRIKKALE",
        "DISTRICT": "MERKEZ",
        "STREET": "OSMANGAZİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KIRIKKALE",
        "DISTRICT": "MERKEZ",
        "STREET": "OVACIK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KIRIKKALE",
        "DISTRICT": "MERKEZ",
        "STREET": "PAZARCIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRIKKALE",
        "DISTRICT": "MERKEZ",
        "STREET": "SANAYİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KIRIKKALE",
        "DISTRICT": "MERKEZ",
        "STREET": "SELİM ÖZER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KIRIKKALE",
        "DISTRICT": "MERKEZ",
        "STREET": "TEPEBAŞI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KIRIKKALE",
        "DISTRICT": "MERKEZ",
        "STREET": "ULAŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRIKKALE",
        "DISTRICT": "MERKEZ",
        "STREET": "YAYLACIK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KIRIKKALE",
        "DISTRICT": "MERKEZ",
        "STREET": "YENİDOĞAN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KIRIKKALE",
        "DISTRICT": "MERKEZ",
        "STREET": "YENİMAHALLE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KIRIKKALE",
        "DISTRICT": "MERKEZ",
        "STREET": "YUKARIMAHMUTLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRIKKALE",
        "DISTRICT": "MERKEZ",
        "STREET": "YUVA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KIRIKKALE",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇALILIÖZ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KIRIKKALE",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇULLU MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KIRIKKALE",
        "DISTRICT": "MERKEZ",
        "STREET": "KIRIKKALE F TİPİ YÜKSEK GÜVENLİKLİ KAPALI CEZA İNF",
        "TYPE": "0"
      },
      {
        "CITY": "KIRIKKALE",
        "DISTRICT": "SULAKYURT",
        "STREET": "AKKUYU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRIKKALE",
        "DISTRICT": "SULAKYURT",
        "STREET": "ALİŞEYHLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRIKKALE",
        "DISTRICT": "SULAKYURT",
        "STREET": "AMBARDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRIKKALE",
        "DISTRICT": "SULAKYURT",
        "STREET": "AYVATLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRIKKALE",
        "DISTRICT": "SULAKYURT",
        "STREET": "AĞAYLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRIKKALE",
        "DISTRICT": "SULAKYURT",
        "STREET": "CUMHURİYET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KIRIKKALE",
        "DISTRICT": "SULAKYURT",
        "STREET": "DANACI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRIKKALE",
        "DISTRICT": "SULAKYURT",
        "STREET": "DEREDÜZÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRIKKALE",
        "DISTRICT": "SULAKYURT",
        "STREET": "ESENPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRIKKALE",
        "DISTRICT": "SULAKYURT",
        "STREET": "FARAŞLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRIKKALE",
        "DISTRICT": "SULAKYURT",
        "STREET": "GÜZELYURT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRIKKALE",
        "DISTRICT": "SULAKYURT",
        "STREET": "HAMZALI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRIKKALE",
        "DISTRICT": "SULAKYURT",
        "STREET": "KALEKIŞLA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRIKKALE",
        "DISTRICT": "SULAKYURT",
        "STREET": "KIYIHALİLİNCELİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRIKKALE",
        "DISTRICT": "SULAKYURT",
        "STREET": "KIYIKAVURGALI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRIKKALE",
        "DISTRICT": "SULAKYURT",
        "STREET": "KORUKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "KIRIKKALE",
        "DISTRICT": "SULAKYURT",
        "STREET": "ORTAKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "KIRIKKALE",
        "DISTRICT": "SULAKYURT",
        "STREET": "SARIKIZLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRIKKALE",
        "DISTRICT": "SULAKYURT",
        "STREET": "SARIMBEY KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRIKKALE",
        "DISTRICT": "SULAKYURT",
        "STREET": "SOFULARÇİFTLİĞİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRIKKALE",
        "DISTRICT": "SULAKYURT",
        "STREET": "YAYLA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KIRIKKALE",
        "DISTRICT": "SULAKYURT",
        "STREET": "YAĞBASAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRIKKALE",
        "DISTRICT": "SULAKYURT",
        "STREET": "YENİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KIRIKKALE",
        "DISTRICT": "SULAKYURT",
        "STREET": "YENİCELİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRIKKALE",
        "DISTRICT": "SULAKYURT",
        "STREET": "YENİHAYAT MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KIRIKKALE",
        "DISTRICT": "SULAKYURT",
        "STREET": "YEŞİLLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRIKKALE",
        "DISTRICT": "SULAKYURT",
        "STREET": "YEŞİLYAZI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRIKKALE",
        "DISTRICT": "SULAKYURT",
        "STREET": "YEŞİLYURT MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KIRIKKALE",
        "DISTRICT": "SULAKYURT",
        "STREET": "YILDIZ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KIRIKKALE",
        "DISTRICT": "SULAKYURT",
        "STREET": "YÜKSELEN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KIRIKKALE",
        "DISTRICT": "SULAKYURT",
        "STREET": "ÇAYOBA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRIKKALE",
        "DISTRICT": "SULAKYURT",
        "STREET": "ÇEVRİMLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRIKKALE",
        "DISTRICT": "SULAKYURT",
        "STREET": "İMAMOĞLUÇEŞMESİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRIKKALE",
        "DISTRICT": "YAHŞİHAN",
        "STREET": "ALAADDİN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KIRIKKALE",
        "DISTRICT": "YAHŞİHAN",
        "STREET": "BAYRAKTEPE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KIRIKKALE",
        "DISTRICT": "YAHŞİHAN",
        "STREET": "BEDESTEN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRIKKALE",
        "DISTRICT": "YAHŞİHAN",
        "STREET": "DOĞANLIK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KIRIKKALE",
        "DISTRICT": "YAHŞİHAN",
        "STREET": "EMİRLER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KIRIKKALE",
        "DISTRICT": "YAHŞİHAN",
        "STREET": "ERENLER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KIRIKKALE",
        "DISTRICT": "YAHŞİHAN",
        "STREET": "HACIBALI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRIKKALE",
        "DISTRICT": "YAHŞİHAN",
        "STREET": "HACIBEY MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KIRIKKALE",
        "DISTRICT": "YAHŞİHAN",
        "STREET": "HİSARKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "KIRIKKALE",
        "DISTRICT": "YAHŞİHAN",
        "STREET": "IRMAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRIKKALE",
        "DISTRICT": "YAHŞİHAN",
        "STREET": "KADIOĞLU  MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KIRIKKALE",
        "DISTRICT": "YAHŞİHAN",
        "STREET": "KEÇİLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRIKKALE",
        "DISTRICT": "YAHŞİHAN",
        "STREET": "KILIÇLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRIKKALE",
        "DISTRICT": "YAHŞİHAN",
        "STREET": "MAHMUTLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRIKKALE",
        "DISTRICT": "YAHŞİHAN",
        "STREET": "SEYRANTEPE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KIRIKKALE",
        "DISTRICT": "YAHŞİHAN",
        "STREET": "YENİŞEHİR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KIRIKKALE",
        "DISTRICT": "YAHŞİHAN",
        "STREET": "YEŞİL VADİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KIRIKKALE",
        "DISTRICT": "ÇELEBİ",
        "STREET": "ALICIYENİYAPAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRIKKALE",
        "DISTRICT": "ÇELEBİ",
        "STREET": "BARBAROS MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KIRIKKALE",
        "DISTRICT": "ÇELEBİ",
        "STREET": "FATİH MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KIRIKKALE",
        "DISTRICT": "ÇELEBİ",
        "STREET": "GÜLVEREN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KIRIKKALE",
        "DISTRICT": "ÇELEBİ",
        "STREET": "HACIYUSUFLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRIKKALE",
        "DISTRICT": "ÇELEBİ",
        "STREET": "HALİLDEDE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRIKKALE",
        "DISTRICT": "ÇELEBİ",
        "STREET": "KALDIRIM KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRIKKALE",
        "DISTRICT": "ÇELEBİ",
        "STREET": "KARAAĞAÇ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRIKKALE",
        "DISTRICT": "ÇELEBİ",
        "STREET": "KARAAĞIL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRIKKALE",
        "DISTRICT": "ÇELEBİ",
        "STREET": "KARABUCAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRIKKALE",
        "DISTRICT": "ÇELEBİ",
        "STREET": "KARAHACILI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRIKKALE",
        "DISTRICT": "ÇELEBİ",
        "STREET": "KARAYAKUP KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRIKKALE",
        "DISTRICT": "ÇELEBİ",
        "STREET": "KEPİRLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRIKKALE",
        "DISTRICT": "ÇELEBİ",
        "STREET": "TİLKİLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRIKKALE",
        "DISTRICT": "ÇELEBİ",
        "STREET": "YENİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KIRIKKALE",
        "DISTRICT": "ÇELEBİ",
        "STREET": "YUKARIŞIH KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRIKKALE",
        "DISTRICT": "ÇELEBİ",
        "STREET": "İĞDEBELİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRKLARELİ",
        "DISTRICT": "BABAESKİ",
        "STREET": "ALPULLU-GÜLBAHÇE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KIRKLARELİ",
        "DISTRICT": "BABAESKİ",
        "STREET": "ALPULLU-YENİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KIRKLARELİ",
        "DISTRICT": "BABAESKİ",
        "STREET": "ALPULLU-ŞEKER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KIRKLARELİ",
        "DISTRICT": "BABAESKİ",
        "STREET": "ATATÜRK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KIRKLARELİ",
        "DISTRICT": "BABAESKİ",
        "STREET": "AĞAYERİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRKLARELİ",
        "DISTRICT": "BABAESKİ",
        "STREET": "BÜYÜKMANDIRA-HATİP MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KIRKLARELİ",
        "DISTRICT": "BABAESKİ",
        "STREET": "BÜYÜKMANDIRA-HÜRRİYET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KIRKLARELİ",
        "DISTRICT": "BABAESKİ",
        "STREET": "BÜYÜKMANDIRA-PAŞA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KIRKLARELİ",
        "DISTRICT": "BABAESKİ",
        "STREET": "BÜYÜKMANDIRA-ŞAMDANİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KIRKLARELİ",
        "DISTRICT": "BABAESKİ",
        "STREET": "CUMHURİYET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KIRKLARELİ",
        "DISTRICT": "BABAESKİ",
        "STREET": "DÜĞÜNCÜLÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRKLARELİ",
        "DISTRICT": "BABAESKİ",
        "STREET": "DİNDOĞRU MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KIRKLARELİ",
        "DISTRICT": "BABAESKİ",
        "STREET": "ERTUĞRULKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "KIRKLARELİ",
        "DISTRICT": "BABAESKİ",
        "STREET": "ERİKLERYURDU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRKLARELİ",
        "DISTRICT": "BABAESKİ",
        "STREET": "FEVZİ ÇAKMAK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KIRKLARELİ",
        "DISTRICT": "BABAESKİ",
        "STREET": "GAZİKEMAL MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KIRKLARELİ",
        "DISTRICT": "BABAESKİ",
        "STREET": "GAZİOSMANPAŞA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KIRKLARELİ",
        "DISTRICT": "BABAESKİ",
        "STREET": "HACIHASAN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KIRKLARELİ",
        "DISTRICT": "BABAESKİ",
        "STREET": "HAMİDİYE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KIRKLARELİ",
        "DISTRICT": "BABAESKİ",
        "STREET": "HAZİNEDAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRKLARELİ",
        "DISTRICT": "BABAESKİ",
        "STREET": "KADIKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "KIRKLARELİ",
        "DISTRICT": "BABAESKİ",
        "STREET": "KARABAYIR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRKLARELİ",
        "DISTRICT": "BABAESKİ",
        "STREET": "KARACAOĞLAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRKLARELİ",
        "DISTRICT": "BABAESKİ",
        "STREET": "KARAHALİL-BİRLİK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KIRKLARELİ",
        "DISTRICT": "BABAESKİ",
        "STREET": "KARAHALİL-DEMİRKAPI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KIRKLARELİ",
        "DISTRICT": "BABAESKİ",
        "STREET": "KARAHALİL-FEVZİ ÇAKMAK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KIRKLARELİ",
        "DISTRICT": "BABAESKİ",
        "STREET": "KARAHALİL-HALİLPAŞA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KIRKLARELİ",
        "DISTRICT": "BABAESKİ",
        "STREET": "KARAHALİL-KEMAL PAŞA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KIRKLARELİ",
        "DISTRICT": "BABAESKİ",
        "STREET": "KARAMESUTLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRKLARELİ",
        "DISTRICT": "BABAESKİ",
        "STREET": "KATRANCA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRKLARELİ",
        "DISTRICT": "BABAESKİ",
        "STREET": "KULELİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRKLARELİ",
        "DISTRICT": "BABAESKİ",
        "STREET": "KUMRULAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRKLARELİ",
        "DISTRICT": "BABAESKİ",
        "STREET": "KURTULUŞ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KIRKLARELİ",
        "DISTRICT": "BABAESKİ",
        "STREET": "KUZUÇARDAĞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRKLARELİ",
        "DISTRICT": "BABAESKİ",
        "STREET": "MUTLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRKLARELİ",
        "DISTRICT": "BABAESKİ",
        "STREET": "MÜSELLİM KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRKLARELİ",
        "DISTRICT": "BABAESKİ",
        "STREET": "MİNNETLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRKLARELİ",
        "DISTRICT": "BABAESKİ",
        "STREET": "NACAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRKLARELİ",
        "DISTRICT": "BABAESKİ",
        "STREET": "NADIRLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRKLARELİ",
        "DISTRICT": "BABAESKİ",
        "STREET": "ORUÇLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRKLARELİ",
        "DISTRICT": "BABAESKİ",
        "STREET": "OSMANİYE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRKLARELİ",
        "DISTRICT": "BABAESKİ",
        "STREET": "PANCARKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "KIRKLARELİ",
        "DISTRICT": "BABAESKİ",
        "STREET": "SOFUHALİL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRKLARELİ",
        "DISTRICT": "BABAESKİ",
        "STREET": "SİNANLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRKLARELİ",
        "DISTRICT": "BABAESKİ",
        "STREET": "TAŞAĞIL BUCAK MERKEZİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRKLARELİ",
        "DISTRICT": "BABAESKİ",
        "STREET": "TAŞKÖPRÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRKLARELİ",
        "DISTRICT": "BABAESKİ",
        "STREET": "TERZİLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRKLARELİ",
        "DISTRICT": "BABAESKİ",
        "STREET": "YENİKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "KIRKLARELİ",
        "DISTRICT": "BABAESKİ",
        "STREET": "YENİMAHALLE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRKLARELİ",
        "DISTRICT": "BABAESKİ",
        "STREET": "ÇAVUŞKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "KIRKLARELİ",
        "DISTRICT": "BABAESKİ",
        "STREET": "ÇENGERLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRKLARELİ",
        "DISTRICT": "BABAESKİ",
        "STREET": "ÇİĞDEMLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRKLARELİ",
        "DISTRICT": "DEMİRKÖY",
        "STREET": "ARMUTVEREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRKLARELİ",
        "DISTRICT": "DEMİRKÖY",
        "STREET": "AVCILAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRKLARELİ",
        "DISTRICT": "DEMİRKÖY",
        "STREET": "BALABAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRKLARELİ",
        "DISTRICT": "DEMİRKÖY",
        "STREET": "BEĞENDİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRKLARELİ",
        "DISTRICT": "DEMİRKÖY",
        "STREET": "BOZTAŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRKLARELİ",
        "DISTRICT": "DEMİRKÖY",
        "STREET": "GÖKYAKA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRKLARELİ",
        "DISTRICT": "DEMİRKÖY",
        "STREET": "HAMDİBEY KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRKLARELİ",
        "DISTRICT": "DEMİRKÖY",
        "STREET": "HAMDİBEY MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KIRKLARELİ",
        "DISTRICT": "DEMİRKÖY",
        "STREET": "KARACADAĞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRKLARELİ",
        "DISTRICT": "DEMİRKÖY",
        "STREET": "LİMANKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "KIRKLARELİ",
        "DISTRICT": "DEMİRKÖY",
        "STREET": "ORHANİYE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KIRKLARELİ",
        "DISTRICT": "DEMİRKÖY",
        "STREET": "SARPDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRKLARELİ",
        "DISTRICT": "DEMİRKÖY",
        "STREET": "SİSLİOBA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRKLARELİ",
        "DISTRICT": "DEMİRKÖY",
        "STREET": "SİVRİLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRKLARELİ",
        "DISTRICT": "DEMİRKÖY",
        "STREET": "YENİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KIRKLARELİ",
        "DISTRICT": "DEMİRKÖY",
        "STREET": "YEŞİLCE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRKLARELİ",
        "DISTRICT": "DEMİRKÖY",
        "STREET": "YİĞİTBAŞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRKLARELİ",
        "DISTRICT": "DEMİRKÖY",
        "STREET": "İNCESIRT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRKLARELİ",
        "DISTRICT": "DEMİRKÖY",
        "STREET": "İĞNEADA-DENİZ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KIRKLARELİ",
        "DISTRICT": "DEMİRKÖY",
        "STREET": "İĞNEADA-YENİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KIRKLARELİ",
        "DISTRICT": "MERKEZ",
        "STREET": "AHMETÇE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRKLARELİ",
        "DISTRICT": "MERKEZ",
        "STREET": "AKALAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KIRKLARELİ",
        "DISTRICT": "MERKEZ",
        "STREET": "ARMAĞAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRKLARELİ",
        "DISTRICT": "MERKEZ",
        "STREET": "ARİZBABA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRKLARELİ",
        "DISTRICT": "MERKEZ",
        "STREET": "ASILBEYLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRKLARELİ",
        "DISTRICT": "MERKEZ",
        "STREET": "ATATÜRK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KIRKLARELİ",
        "DISTRICT": "MERKEZ",
        "STREET": "BADEMLİK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KIRKLARELİ",
        "DISTRICT": "MERKEZ",
        "STREET": "BAYRAMDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRKLARELİ",
        "DISTRICT": "MERKEZ",
        "STREET": "BEYPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRKLARELİ",
        "DISTRICT": "MERKEZ",
        "STREET": "CUMHURİYET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KIRKLARELİ",
        "DISTRICT": "MERKEZ",
        "STREET": "DEMİRCİHALİL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRKLARELİ",
        "DISTRICT": "MERKEZ",
        "STREET": "DEMİRTAŞ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KIRKLARELİ",
        "DISTRICT": "MERKEZ",
        "STREET": "DEREKÖY  BUCAK MERKEZİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRKLARELİ",
        "DISTRICT": "MERKEZ",
        "STREET": "DEVEÇATAĞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRKLARELİ",
        "DISTRICT": "MERKEZ",
        "STREET": "DEĞİRMENCİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRKLARELİ",
        "DISTRICT": "MERKEZ",
        "STREET": "DOKUZHÜYÜK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRKLARELİ",
        "DISTRICT": "MERKEZ",
        "STREET": "DOLHAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRKLARELİ",
        "DISTRICT": "MERKEZ",
        "STREET": "DOĞU MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KIRKLARELİ",
        "DISTRICT": "MERKEZ",
        "STREET": "DÜZORMAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRKLARELİ",
        "DISTRICT": "MERKEZ",
        "STREET": "ERİKLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRKLARELİ",
        "DISTRICT": "MERKEZ",
        "STREET": "ERİKLİCE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRKLARELİ",
        "DISTRICT": "MERKEZ",
        "STREET": "GEÇİTAĞZI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRKLARELİ",
        "DISTRICT": "MERKEZ",
        "STREET": "KADIKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "KIRKLARELİ",
        "DISTRICT": "MERKEZ",
        "STREET": "KAPAKLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRKLARELİ",
        "DISTRICT": "MERKEZ",
        "STREET": "KARACAİBRAHİM MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KIRKLARELİ",
        "DISTRICT": "MERKEZ",
        "STREET": "KARADERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRKLARELİ",
        "DISTRICT": "MERKEZ",
        "STREET": "KARAHAMZA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRKLARELİ",
        "DISTRICT": "MERKEZ",
        "STREET": "KARAHIDIR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KIRKLARELİ",
        "DISTRICT": "MERKEZ",
        "STREET": "KARAKAŞ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KIRKLARELİ",
        "DISTRICT": "MERKEZ",
        "STREET": "KARAKOÇ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRKLARELİ",
        "DISTRICT": "MERKEZ",
        "STREET": "KARINCAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRKLARELİ",
        "DISTRICT": "MERKEZ",
        "STREET": "KAVAKDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRKLARELİ",
        "DISTRICT": "MERKEZ",
        "STREET": "KAVAKLI-CELALİYE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KIRKLARELİ",
        "DISTRICT": "MERKEZ",
        "STREET": "KAVAKLI-TURİST MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KIRKLARELİ",
        "DISTRICT": "MERKEZ",
        "STREET": "KAVAKLI-YENİ OKULLAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KIRKLARELİ",
        "DISTRICT": "MERKEZ",
        "STREET": "KAVAKLI-ÇARŞI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KIRKLARELİ",
        "DISTRICT": "MERKEZ",
        "STREET": "KAYALI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRKLARELİ",
        "DISTRICT": "MERKEZ",
        "STREET": "KIRKLARELİ KAPALI CEZAEVİ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRKLARELİ",
        "DISTRICT": "MERKEZ",
        "STREET": "KIZILCIKDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRKLARELİ",
        "DISTRICT": "MERKEZ",
        "STREET": "KOCAHIDIR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRKLARELİ",
        "DISTRICT": "MERKEZ",
        "STREET": "KOCAHIDIR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KIRKLARELİ",
        "DISTRICT": "MERKEZ",
        "STREET": "KORUKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "KIRKLARELİ",
        "DISTRICT": "MERKEZ",
        "STREET": "KOYUNBABA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRKLARELİ",
        "DISTRICT": "MERKEZ",
        "STREET": "KULA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRKLARELİ",
        "DISTRICT": "MERKEZ",
        "STREET": "KUZULU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRKLARELİ",
        "DISTRICT": "MERKEZ",
        "STREET": "PAŞAYERİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRKLARELİ",
        "DISTRICT": "MERKEZ",
        "STREET": "PINAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KIRKLARELİ",
        "DISTRICT": "MERKEZ",
        "STREET": "YAYLA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KIRKLARELİ",
        "DISTRICT": "MERKEZ",
        "STREET": "YOĞUNTAŞ BUCAK MERKEZİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRKLARELİ",
        "DISTRICT": "MERKEZ",
        "STREET": "YÜNDALAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRKLARELİ",
        "DISTRICT": "MERKEZ",
        "STREET": "YÜRÜKBAYIRI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRKLARELİ",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇAYIRLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRKLARELİ",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇAĞALAYIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRKLARELİ",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇEŞMEKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "KIRKLARELİ",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇUKURPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRKLARELİ",
        "DISTRICT": "MERKEZ",
        "STREET": "ÜRÜNLÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRKLARELİ",
        "DISTRICT": "MERKEZ",
        "STREET": "ÜSKÜP-CEVAT PAŞA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KIRKLARELİ",
        "DISTRICT": "MERKEZ",
        "STREET": "ÜSKÜP-GÜNDOĞDU MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KIRKLARELİ",
        "DISTRICT": "MERKEZ",
        "STREET": "ÜSKÜP-KEMAL PAŞA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KIRKLARELİ",
        "DISTRICT": "MERKEZ",
        "STREET": "ÜSKÜP-MEHMET ÇAVUŞ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KIRKLARELİ",
        "DISTRICT": "MERKEZ",
        "STREET": "ÜSKÜPDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRKLARELİ",
        "DISTRICT": "MERKEZ",
        "STREET": "İNECE-KAZIMPAŞA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KIRKLARELİ",
        "DISTRICT": "MERKEZ",
        "STREET": "İNECE-KEMALPAŞA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KIRKLARELİ",
        "DISTRICT": "MERKEZ",
        "STREET": "İNECE-ULUKONAK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KIRKLARELİ",
        "DISTRICT": "MERKEZ",
        "STREET": "İNECE-YENİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KIRKLARELİ",
        "DISTRICT": "MERKEZ",
        "STREET": "İSTASYON MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KIRKLARELİ",
        "DISTRICT": "MERKEZ",
        "STREET": "ŞÜKRÜPAŞA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRKLARELİ",
        "DISTRICT": "KOFÇAZ",
        "STREET": "AHLATLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRKLARELİ",
        "DISTRICT": "KOFÇAZ",
        "STREET": "AHMETLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRKLARELİ",
        "DISTRICT": "KOFÇAZ",
        "STREET": "AŞAĞIKANARA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRKLARELİ",
        "DISTRICT": "KOFÇAZ",
        "STREET": "BEYCİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRKLARELİ",
        "DISTRICT": "KOFÇAZ",
        "STREET": "DEVLETLİAĞAÇ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRKLARELİ",
        "DISTRICT": "KOFÇAZ",
        "STREET": "ELMACIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRKLARELİ",
        "DISTRICT": "KOFÇAZ",
        "STREET": "KARAABALAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRKLARELİ",
        "DISTRICT": "KOFÇAZ",
        "STREET": "KOCATARLA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRKLARELİ",
        "DISTRICT": "KOFÇAZ",
        "STREET": "KOCAYAZI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRKLARELİ",
        "DISTRICT": "KOFÇAZ",
        "STREET": "MALKOÇLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRKLARELİ",
        "DISTRICT": "KOFÇAZ",
        "STREET": "TASTEPE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRKLARELİ",
        "DISTRICT": "KOFÇAZ",
        "STREET": "TATLIPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRKLARELİ",
        "DISTRICT": "KOFÇAZ",
        "STREET": "TERZİDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRKLARELİ",
        "DISTRICT": "KOFÇAZ",
        "STREET": "TOPÇULAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRKLARELİ",
        "DISTRICT": "KOFÇAZ",
        "STREET": "YENİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KIRKLARELİ",
        "DISTRICT": "KOFÇAZ",
        "STREET": "YEŞİLLİK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KIRKLARELİ",
        "DISTRICT": "KOFÇAZ",
        "STREET": "YUKARIKANARA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRKLARELİ",
        "DISTRICT": "LÜLEBURGAZ",
        "STREET": "8 KASIM MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KIRKLARELİ",
        "DISTRICT": "LÜLEBURGAZ",
        "STREET": "AHMETBEY-DERE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KIRKLARELİ",
        "DISTRICT": "LÜLEBURGAZ",
        "STREET": "AHMETBEY-GÜNDOĞU MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KIRKLARELİ",
        "DISTRICT": "LÜLEBURGAZ",
        "STREET": "AHMETBEY-KARŞIYAKA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KIRKLARELİ",
        "DISTRICT": "LÜLEBURGAZ",
        "STREET": "AKÇAKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "KIRKLARELİ",
        "DISTRICT": "LÜLEBURGAZ",
        "STREET": "ALACAOĞLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRKLARELİ",
        "DISTRICT": "LÜLEBURGAZ",
        "STREET": "ATATÜRK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KIRKLARELİ",
        "DISTRICT": "LÜLEBURGAZ",
        "STREET": "AYVALI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRKLARELİ",
        "DISTRICT": "LÜLEBURGAZ",
        "STREET": "BARIŞ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KIRKLARELİ",
        "DISTRICT": "LÜLEBURGAZ",
        "STREET": "BÜYÜKKARIŞTIRAN-FATİH MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KIRKLARELİ",
        "DISTRICT": "LÜLEBURGAZ",
        "STREET": "BÜYÜKKARIŞTIRAN-KÜÇÜKKARIŞTIRAN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KIRKLARELİ",
        "DISTRICT": "LÜLEBURGAZ",
        "STREET": "BÜYÜKKARIŞTIRAN-YENİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KIRKLARELİ",
        "DISTRICT": "LÜLEBURGAZ",
        "STREET": "BÜYÜKKARIŞTIRAN-YILDIRIM MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KIRKLARELİ",
        "DISTRICT": "LÜLEBURGAZ",
        "STREET": "CELALİYE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRKLARELİ",
        "DISTRICT": "LÜLEBURGAZ",
        "STREET": "CEYLANKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "KIRKLARELİ",
        "DISTRICT": "LÜLEBURGAZ",
        "STREET": "CUMHURİYET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KIRKLARELİ",
        "DISTRICT": "LÜLEBURGAZ",
        "STREET": "DAVUTLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRKLARELİ",
        "DISTRICT": "LÜLEBURGAZ",
        "STREET": "DERE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KIRKLARELİ",
        "DISTRICT": "LÜLEBURGAZ",
        "STREET": "DURAK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KIRKLARELİ",
        "DISTRICT": "LÜLEBURGAZ",
        "STREET": "DÜĞÜNCÜBAŞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRKLARELİ",
        "DISTRICT": "LÜLEBURGAZ",
        "STREET": "EMİRALİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRKLARELİ",
        "DISTRICT": "LÜLEBURGAZ",
        "STREET": "ERTUĞRUL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRKLARELİ",
        "DISTRICT": "LÜLEBURGAZ",
        "STREET": "ESKİBEDİR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRKLARELİ",
        "DISTRICT": "LÜLEBURGAZ",
        "STREET": "ESKİTAŞLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRKLARELİ",
        "DISTRICT": "LÜLEBURGAZ",
        "STREET": "EVRENSEKİZ-FATİH MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KIRKLARELİ",
        "DISTRICT": "LÜLEBURGAZ",
        "STREET": "EVRENSEKİZ-GÜNDOĞU MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KIRKLARELİ",
        "DISTRICT": "LÜLEBURGAZ",
        "STREET": "EVRENSEKİZ-KIRCAALİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KIRKLARELİ",
        "DISTRICT": "LÜLEBURGAZ",
        "STREET": "GENÇLİK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KIRKLARELİ",
        "DISTRICT": "LÜLEBURGAZ",
        "STREET": "GÜNDOĞU MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KIRKLARELİ",
        "DISTRICT": "LÜLEBURGAZ",
        "STREET": "GÜNEŞ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KIRKLARELİ",
        "DISTRICT": "LÜLEBURGAZ",
        "STREET": "HAMZABEY KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRKLARELİ",
        "DISTRICT": "LÜLEBURGAZ",
        "STREET": "HAMİTABAT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRKLARELİ",
        "DISTRICT": "LÜLEBURGAZ",
        "STREET": "HÜRRİYET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KIRKLARELİ",
        "DISTRICT": "LÜLEBURGAZ",
        "STREET": "KARAAĞAÇ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRKLARELİ",
        "DISTRICT": "LÜLEBURGAZ",
        "STREET": "KARAMUSUL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRKLARELİ",
        "DISTRICT": "LÜLEBURGAZ",
        "STREET": "KAYABEYLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRKLARELİ",
        "DISTRICT": "LÜLEBURGAZ",
        "STREET": "KIRIKKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "KIRKLARELİ",
        "DISTRICT": "LÜLEBURGAZ",
        "STREET": "KOCASİNAN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KIRKLARELİ",
        "DISTRICT": "LÜLEBURGAZ",
        "STREET": "KURTULUŞ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KIRKLARELİ",
        "DISTRICT": "LÜLEBURGAZ",
        "STREET": "MÜSELLİM KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRKLARELİ",
        "DISTRICT": "LÜLEBURGAZ",
        "STREET": "OKLALI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRKLARELİ",
        "DISTRICT": "LÜLEBURGAZ",
        "STREET": "OVACIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRKLARELİ",
        "DISTRICT": "LÜLEBURGAZ",
        "STREET": "SAKIZKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "KIRKLARELİ",
        "DISTRICT": "LÜLEBURGAZ",
        "STREET": "SARICAALİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRKLARELİ",
        "DISTRICT": "LÜLEBURGAZ",
        "STREET": "SARIMSAKLI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KIRKLARELİ",
        "DISTRICT": "LÜLEBURGAZ",
        "STREET": "SEVGİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KIRKLARELİ",
        "DISTRICT": "LÜLEBURGAZ",
        "STREET": "SEYİTLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRKLARELİ",
        "DISTRICT": "LÜLEBURGAZ",
        "STREET": "SİTELER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KIRKLARELİ",
        "DISTRICT": "LÜLEBURGAZ",
        "STREET": "TATARKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "KIRKLARELİ",
        "DISTRICT": "LÜLEBURGAZ",
        "STREET": "TURGUTBEY KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRKLARELİ",
        "DISTRICT": "LÜLEBURGAZ",
        "STREET": "UMURCA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRKLARELİ",
        "DISTRICT": "LÜLEBURGAZ",
        "STREET": "YENİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KIRKLARELİ",
        "DISTRICT": "LÜLEBURGAZ",
        "STREET": "YENİBEDİR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRKLARELİ",
        "DISTRICT": "LÜLEBURGAZ",
        "STREET": "YENİTAŞLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRKLARELİ",
        "DISTRICT": "LÜLEBURGAZ",
        "STREET": "YILDIRIM MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KIRKLARELİ",
        "DISTRICT": "LÜLEBURGAZ",
        "STREET": "YILDIZ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KIRKLARELİ",
        "DISTRICT": "LÜLEBURGAZ",
        "STREET": "YILMAZ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KIRKLARELİ",
        "DISTRICT": "LÜLEBURGAZ",
        "STREET": "ZAFER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KIRKLARELİ",
        "DISTRICT": "LÜLEBURGAZ",
        "STREET": "ÇENGELLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRKLARELİ",
        "DISTRICT": "LÜLEBURGAZ",
        "STREET": "ÇEŞMEKOLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRKLARELİ",
        "DISTRICT": "LÜLEBURGAZ",
        "STREET": "ÇİFTLİKKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "KIRKLARELİ",
        "DISTRICT": "LÜLEBURGAZ",
        "STREET": "ÖZERLER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KIRKLARELİ",
        "DISTRICT": "LÜLEBURGAZ",
        "STREET": "İNÖNÜ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KIRKLARELİ",
        "DISTRICT": "LÜLEBURGAZ",
        "STREET": "İSTİKLAL MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KIRKLARELİ",
        "DISTRICT": "PEHLİVANKÖY",
        "STREET": "AKARCA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRKLARELİ",
        "DISTRICT": "PEHLİVANKÖY",
        "STREET": "DOĞANCA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRKLARELİ",
        "DISTRICT": "PEHLİVANKÖY",
        "STREET": "ERGENE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KIRKLARELİ",
        "DISTRICT": "PEHLİVANKÖY",
        "STREET": "HIDIRCA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRKLARELİ",
        "DISTRICT": "PEHLİVANKÖY",
        "STREET": "KAZIMDİRİK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KIRKLARELİ",
        "DISTRICT": "PEHLİVANKÖY",
        "STREET": "KUMKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "KIRKLARELİ",
        "DISTRICT": "PEHLİVANKÖY",
        "STREET": "KURTULUŞ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KIRKLARELİ",
        "DISTRICT": "PEHLİVANKÖY",
        "STREET": "KUŞTEPE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRKLARELİ",
        "DISTRICT": "PEHLİVANKÖY",
        "STREET": "YEŞİLOVA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRKLARELİ",
        "DISTRICT": "PEHLİVANKÖY",
        "STREET": "YEŞİLPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRKLARELİ",
        "DISTRICT": "PEHLİVANKÖY",
        "STREET": "İMAMPAZARI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRKLARELİ",
        "DISTRICT": "PINARHİSAR",
        "STREET": "AKÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRKLARELİ",
        "DISTRICT": "PINARHİSAR",
        "STREET": "ATAKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "KIRKLARELİ",
        "DISTRICT": "PINARHİSAR",
        "STREET": "BEYLİK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KIRKLARELİ",
        "DISTRICT": "PINARHİSAR",
        "STREET": "CAMİİKEBİR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KIRKLARELİ",
        "DISTRICT": "PINARHİSAR",
        "STREET": "CEVİZKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "KIRKLARELİ",
        "DISTRICT": "PINARHİSAR",
        "STREET": "DERE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KIRKLARELİ",
        "DISTRICT": "PINARHİSAR",
        "STREET": "ERENLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRKLARELİ",
        "DISTRICT": "PINARHİSAR",
        "STREET": "EVCİLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRKLARELİ",
        "DISTRICT": "PINARHİSAR",
        "STREET": "HACIFAKILI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRKLARELİ",
        "DISTRICT": "PINARHİSAR",
        "STREET": "KAYNARCA-AKARLAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KIRKLARELİ",
        "DISTRICT": "PINARHİSAR",
        "STREET": "KAYNARCA-IŞIKLAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KIRKLARELİ",
        "DISTRICT": "PINARHİSAR",
        "STREET": "KAYNARCA-YENİCAMİİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KIRKLARELİ",
        "DISTRICT": "PINARHİSAR",
        "STREET": "KURUDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRKLARELİ",
        "DISTRICT": "PINARHİSAR",
        "STREET": "ORTA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KIRKLARELİ",
        "DISTRICT": "PINARHİSAR",
        "STREET": "OSMANCIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRKLARELİ",
        "DISTRICT": "PINARHİSAR",
        "STREET": "PINARHİSAR CEZAEVİ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRKLARELİ",
        "DISTRICT": "PINARHİSAR",
        "STREET": "POYRALI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRKLARELİ",
        "DISTRICT": "PINARHİSAR",
        "STREET": "SÜTLÜCE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRKLARELİ",
        "DISTRICT": "PINARHİSAR",
        "STREET": "TOZAKLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRKLARELİ",
        "DISTRICT": "PINARHİSAR",
        "STREET": "YENİCE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRKLARELİ",
        "DISTRICT": "PINARHİSAR",
        "STREET": "ÇAYIRDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRKLARELİ",
        "DISTRICT": "PINARHİSAR",
        "STREET": "İSLAMBEYLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRKLARELİ",
        "DISTRICT": "VİZE",
        "STREET": "AKINCILAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRKLARELİ",
        "DISTRICT": "VİZE",
        "STREET": "AKPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRKLARELİ",
        "DISTRICT": "VİZE",
        "STREET": "AKSİCİM KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRKLARELİ",
        "DISTRICT": "VİZE",
        "STREET": "BALKAYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRKLARELİ",
        "DISTRICT": "VİZE",
        "STREET": "DEVELİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRKLARELİ",
        "DISTRICT": "VİZE",
        "STREET": "DEVLET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KIRKLARELİ",
        "DISTRICT": "VİZE",
        "STREET": "DOĞANCA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRKLARELİ",
        "DISTRICT": "VİZE",
        "STREET": "DÜZOVA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRKLARELİ",
        "DISTRICT": "VİZE",
        "STREET": "EVREN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KIRKLARELİ",
        "DISTRICT": "VİZE",
        "STREET": "EVRENCİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRKLARELİ",
        "DISTRICT": "VİZE",
        "STREET": "EVRENLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRKLARELİ",
        "DISTRICT": "VİZE",
        "STREET": "GAZİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KIRKLARELİ",
        "DISTRICT": "VİZE",
        "STREET": "HAMİDİYE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRKLARELİ",
        "DISTRICT": "VİZE",
        "STREET": "HASBUĞA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRKLARELİ",
        "DISTRICT": "VİZE",
        "STREET": "KALE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KIRKLARELİ",
        "DISTRICT": "VİZE",
        "STREET": "KIYIKÖY-CUMHURİYET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KIRKLARELİ",
        "DISTRICT": "VİZE",
        "STREET": "KIYIKÖY-GÜVEN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KIRKLARELİ",
        "DISTRICT": "VİZE",
        "STREET": "KIYIKÖY-KALE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KIRKLARELİ",
        "DISTRICT": "VİZE",
        "STREET": "KIZILAĞAÇ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRKLARELİ",
        "DISTRICT": "VİZE",
        "STREET": "KIŞLACIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRKLARELİ",
        "DISTRICT": "VİZE",
        "STREET": "KÖMÜRKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "KIRKLARELİ",
        "DISTRICT": "VİZE",
        "STREET": "KÜÇÜKYAYLA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRKLARELİ",
        "DISTRICT": "VİZE",
        "STREET": "MEHMET AKİF MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KIRKLARELİ",
        "DISTRICT": "VİZE",
        "STREET": "MÜSELLİM KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRKLARELİ",
        "DISTRICT": "VİZE",
        "STREET": "NAMIK KEMAL MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KIRKLARELİ",
        "DISTRICT": "VİZE",
        "STREET": "OKÇULAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRKLARELİ",
        "DISTRICT": "VİZE",
        "STREET": "PAZARLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRKLARELİ",
        "DISTRICT": "VİZE",
        "STREET": "SERGEN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRKLARELİ",
        "DISTRICT": "VİZE",
        "STREET": "SOFULAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRKLARELİ",
        "DISTRICT": "VİZE",
        "STREET": "SOĞUCAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRKLARELİ",
        "DISTRICT": "VİZE",
        "STREET": "TOPÇUKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "KIRKLARELİ",
        "DISTRICT": "VİZE",
        "STREET": "VİZE KAPALI CEZAEVİ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRKLARELİ",
        "DISTRICT": "VİZE",
        "STREET": "ÇAKILLI-CUMHURİYET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KIRKLARELİ",
        "DISTRICT": "VİZE",
        "STREET": "ÇAKILLI-HÜRRİYET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KIRKLARELİ",
        "DISTRICT": "VİZE",
        "STREET": "ÇAKILLI-ZAFER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KIRKLARELİ",
        "DISTRICT": "VİZE",
        "STREET": "ÇAVUŞKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "KIRKLARELİ",
        "DISTRICT": "VİZE",
        "STREET": "ÇÜVENLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "AKPINAR",
        "STREET": "ALİPAŞA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "AKPINAR",
        "STREET": "ALİŞAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "AKPINAR",
        "STREET": "AŞAĞIHOMURLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "AKPINAR",
        "STREET": "BOYALIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "AKPINAR",
        "STREET": "BÜYÜKABDİUŞAĞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "AKPINAR",
        "STREET": "CUMHURİYET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "AKPINAR",
        "STREET": "DEMİRCİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "AKPINAR",
        "STREET": "DEVECİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "AKPINAR",
        "STREET": "DURMUŞLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "AKPINAR",
        "STREET": "ELDELEKLİDEMİREL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "AKPINAR",
        "STREET": "ELDELEKLİORTAOBA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "AKPINAR",
        "STREET": "EŞREFLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "AKPINAR",
        "STREET": "GÜLVEREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "AKPINAR",
        "STREET": "HACI HÜSEYİN EFENDİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "AKPINAR",
        "STREET": "HACIMİRZA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "AKPINAR",
        "STREET": "HACISELİMLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "AKPINAR",
        "STREET": "HANYERİSARIKAYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "AKPINAR",
        "STREET": "HİMMETUŞAĞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "AKPINAR",
        "STREET": "KALEYAZI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "AKPINAR",
        "STREET": "KARAOVA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "AKPINAR",
        "STREET": "KELİSMAİLUŞAĞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "AKPINAR",
        "STREET": "KÖŞKER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "AKPINAR",
        "STREET": "PEKMEZCİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "AKPINAR",
        "STREET": "SOFRAZLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "AKPINAR",
        "STREET": "YENİDOĞAN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "AKPINAR",
        "STREET": "ÇALIBURNU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "AKPINAR",
        "STREET": "ÇAYÖZÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "AKPINAR",
        "STREET": "ÇEBİŞLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "AKPINAR",
        "STREET": "ÇELEBİUŞAĞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "AKPINAR",
        "STREET": "ÇİFTLİKMEHMETAĞA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "AKPINAR",
        "STREET": "ÇİFTLİKSARIKAYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "AKPINAR",
        "STREET": "ŞİRİNEVLER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "AKÇAKENT",
        "STREET": "AVANOĞLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "AKÇAKENT",
        "STREET": "AYVALI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "AKÇAKENT",
        "STREET": "DEREFAKILI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "AKÇAKENT",
        "STREET": "GÜLLÜHÜYÜK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "AKÇAKENT",
        "STREET": "HACIFAKILI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "AKÇAKENT",
        "STREET": "HAMZABEY KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "AKÇAKENT",
        "STREET": "HASANALİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "AKÇAKENT",
        "STREET": "KÖSEFAKILI BUCAK MERKEZİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "AKÇAKENT",
        "STREET": "KÜÇÜKABDİUŞAĞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "AKÇAKENT",
        "STREET": "KİLİMLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "AKÇAKENT",
        "STREET": "MAHSENLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "AKÇAKENT",
        "STREET": "POLATLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "AKÇAKENT",
        "STREET": "SOLAKUŞAĞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "AKÇAKENT",
        "STREET": "TAŞLIOLUK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "AKÇAKENT",
        "STREET": "TEPEFAKILI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "AKÇAKENT",
        "STREET": "YAYLAÖZÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "AKÇAKENT",
        "STREET": "YENİKENT MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "AKÇAKENT",
        "STREET": "YENİYAPAN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "AKÇAKENT",
        "STREET": "YETİKLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "AKÇAKENT",
        "STREET": "YEŞİLDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "AKÇAKENT",
        "STREET": "ÖDEMİŞLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "AKÇAKENT",
        "STREET": "ÖMERUŞAĞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "AKÇAKENT",
        "STREET": "ŞEHRİCEDİD MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "BOZTEPE",
        "STREET": "BAĞBAŞI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "BOZTEPE",
        "STREET": "BÜYÜKKIŞLA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "BOZTEPE",
        "STREET": "ESKİDOĞANLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "BOZTEPE",
        "STREET": "HARMANALTI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "BOZTEPE",
        "STREET": "HATUNOĞLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "BOZTEPE",
        "STREET": "HÜSEYİNLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "BOZTEPE",
        "STREET": "KARACAÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "BOZTEPE",
        "STREET": "KÜLHÜYÜK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "BOZTEPE",
        "STREET": "ORTA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "BOZTEPE",
        "STREET": "UZUNPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "BOZTEPE",
        "STREET": "YENİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "BOZTEPE",
        "STREET": "YENİDOĞANLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "BOZTEPE",
        "STREET": "ÇAMALAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "BOZTEPE",
        "STREET": "ÇEVİRME KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "BOZTEPE",
        "STREET": "ÇİMELİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "BOZTEPE",
        "STREET": "ÇİĞDELİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "BOZTEPE",
        "STREET": "ÜÇKUYU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "KAMAN",
        "STREET": "AYDINLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "KAMAN",
        "STREET": "AĞAPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "KAMAN",
        "STREET": "BAHÇELİEVLER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "KAMAN",
        "STREET": "BAYINDIR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "KAMAN",
        "STREET": "BAYRAMÖZÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "KAMAN",
        "STREET": "BAŞKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "KAMAN",
        "STREET": "BENZER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "KAMAN",
        "STREET": "BÜĞÜZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "KAMAN",
        "STREET": "CUMA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "KAMAN",
        "STREET": "DARIÖZÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "KAMAN",
        "STREET": "DARIÖZÜ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "KAMAN",
        "STREET": "DEMİRLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "KAMAN",
        "STREET": "DEĞİRMENÖZÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "KAMAN",
        "STREET": "ESENTEPE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "KAMAN",
        "STREET": "FAKILI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "KAMAN",
        "STREET": "GAFFAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "KAMAN",
        "STREET": "GÖKEŞME KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "KAMAN",
        "STREET": "GÜLTEPE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "KAMAN",
        "STREET": "HACIPINAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "KAMAN",
        "STREET": "HAMİT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "KAMAN",
        "STREET": "HİRFANLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "KAMAN",
        "STREET": "KALE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "KAMAN",
        "STREET": "KARAHABALI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "KAMAN",
        "STREET": "KARAKAYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "KAMAN",
        "STREET": "KARGINYENİCE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "KAMAN",
        "STREET": "KARKINKIZIKÖZÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "KAMAN",
        "STREET": "KARKINMEŞE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "KAMAN",
        "STREET": "KARKINSELİMAĞA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "KAMAN",
        "STREET": "KEKİLLİALİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "KAMAN",
        "STREET": "KURANCILI-BAHÇELİEVLER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "KAMAN",
        "STREET": "KURANCILI-CUMHURİYET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "KAMAN",
        "STREET": "KURANCILI-ORTA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "KAMAN",
        "STREET": "KURANCILI-YENİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "KAMAN",
        "STREET": "MEŞEKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "KAMAN",
        "STREET": "MOLLAOSMANLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "KAMAN",
        "STREET": "MÜDERRİS MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "KAMAN",
        "STREET": "ORTA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "KAMAN",
        "STREET": "SARIUŞAĞI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "KAMAN",
        "STREET": "SARIÖMERLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "KAMAN",
        "STREET": "SAVCILIBAĞBAŞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "KAMAN",
        "STREET": "SAVCILIBÜYÜKOBA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "KAMAN",
        "STREET": "SAVCILIEBEYİT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "KAMAN",
        "STREET": "SAVCILIKIŞLA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "KAMAN",
        "STREET": "SAVCILIKURUTLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "KAMAN",
        "STREET": "SAVCILIMERYEMKAŞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "KAMAN",
        "STREET": "TATIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "KAMAN",
        "STREET": "TEPEKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "KAMAN",
        "STREET": "YAZIYOLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "KAMAN",
        "STREET": "YAĞMURLU SARIUŞAĞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "KAMAN",
        "STREET": "YELEK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "KAMAN",
        "STREET": "YENİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "KAMAN",
        "STREET": "YENİKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "KAMAN",
        "STREET": "YENİYAPAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "KAMAN",
        "STREET": "YUKARI ÇİFTLİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "KAMAN",
        "STREET": "ZİYARETTEPE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "KAMAN",
        "STREET": "ÇADIRLI HACIBAYRAM KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "KAMAN",
        "STREET": "ÇADIRLI KÖRMEHMET KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "KAMAN",
        "STREET": "ÇAĞIRKAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "KAMAN",
        "STREET": "ÇİFTLİKLİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "KAMAN",
        "STREET": "ÖMERHACILI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "KAMAN",
        "STREET": "ÖMERKAHYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "KAMAN",
        "STREET": "İBRİŞİM KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "KAMAN",
        "STREET": "İKİZLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "KAMAN",
        "STREET": "İMANCI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "KAMAN",
        "STREET": "İSAHOCALI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "MERKEZ",
        "STREET": "AHİEVRAN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "MERKEZ",
        "STREET": "AKÇAAĞIL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "MERKEZ",
        "STREET": "AŞIKPAŞA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "MERKEZ",
        "STREET": "BAHÇELİEVLER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "MERKEZ",
        "STREET": "BAĞBAŞI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "MERKEZ",
        "STREET": "DEDELİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "MERKEZ",
        "STREET": "DEĞİRMENKAŞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "MERKEZ",
        "STREET": "DULKADİRLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "MERKEZ",
        "STREET": "DULKADİRLİKARAİSA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "MERKEZ",
        "STREET": "ECİKAĞIL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "MERKEZ",
        "STREET": "GÖLHİSAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "MERKEZ",
        "STREET": "GÖLLÜ BUCAK MERKEZİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "MERKEZ",
        "STREET": "GÜLDİKEN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "MERKEZ",
        "STREET": "GÜZLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "MERKEZ",
        "STREET": "HASHÜYÜK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "MERKEZ",
        "STREET": "HOMURLU BEŞLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "MERKEZ",
        "STREET": "HOMURLU ÜÇLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "MERKEZ",
        "STREET": "KALANKALDI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "MERKEZ",
        "STREET": "KARABOĞAZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "MERKEZ",
        "STREET": "KARADURAKLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "MERKEZ",
        "STREET": "KARAHIDIR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "MERKEZ",
        "STREET": "KARALAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "MERKEZ",
        "STREET": "KARINCALI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "MERKEZ",
        "STREET": "KARTALKAYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "MERKEZ",
        "STREET": "KAYABAŞI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "MERKEZ",
        "STREET": "KERVANSARAY MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "MERKEZ",
        "STREET": "KESİKKÖPRÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "MERKEZ",
        "STREET": "KINDAM MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "MERKEZ",
        "STREET": "KIRKPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "MERKEZ",
        "STREET": "KIRŞEHİR E TİPİ KAPALI CEZA İNFAZ KURUMU",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "MERKEZ",
        "STREET": "KIRŞEHİR S TİPİ KAPALI CEZA İNFAZ KURUMU",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "MERKEZ",
        "STREET": "KIRŞEHİR YÜKSEK GÜVENLİKLİ KAPALI CEZA İNFAZ KURUM",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "MERKEZ",
        "STREET": "KOCABEY KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "MERKEZ",
        "STREET": "KORTULU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "MERKEZ",
        "STREET": "KURTBELİYENİYAPAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "MERKEZ",
        "STREET": "KURUAĞIL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "MERKEZ",
        "STREET": "KUŞDİLLİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "MERKEZ",
        "STREET": "KÖRPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "MERKEZ",
        "STREET": "MEDRESE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "MERKEZ",
        "STREET": "NASUHDEDE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "MERKEZ",
        "STREET": "SARAYCIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "MERKEZ",
        "STREET": "SEVDİĞİN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "MERKEZ",
        "STREET": "SEYREKKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "MERKEZ",
        "STREET": "SIDIKLIBÜYÜKOBA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "MERKEZ",
        "STREET": "SIDIKLIDARBOĞAZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "MERKEZ",
        "STREET": "SIDIKLIKUMARKAÇ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "MERKEZ",
        "STREET": "SIDIKLIKÜÇÜKBOĞAZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "MERKEZ",
        "STREET": "SIDIKLIKÜÇÜKOBA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "MERKEZ",
        "STREET": "SIDIKLIORTAOBA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "MERKEZ",
        "STREET": "SIDIKLIİKİZAĞIL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "MERKEZ",
        "STREET": "TABUROĞLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "MERKEZ",
        "STREET": "TATARİLYASKIŞLA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "MERKEZ",
        "STREET": "TATARİLYASYAYLA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "MERKEZ",
        "STREET": "TEPESİDELİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "MERKEZ",
        "STREET": "TOKLÜMEN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "MERKEZ",
        "STREET": "TOSUNBURNU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "MERKEZ",
        "STREET": "ULUPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "MERKEZ",
        "STREET": "UZUNALİUŞAĞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "MERKEZ",
        "STREET": "YAĞMURLUARMUTLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "MERKEZ",
        "STREET": "YAĞMURLUBÜYÜKOBA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "MERKEZ",
        "STREET": "YAĞMURLUKALE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "MERKEZ",
        "STREET": "YAĞMURLUSAYOBASI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "MERKEZ",
        "STREET": "YENİCE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "MERKEZ",
        "STREET": "YEŞİLLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "MERKEZ",
        "STREET": "YEŞİLOBA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "MERKEZ",
        "STREET": "YUKARI HOMURLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇADIRLIHACIYUSUF KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇAYAĞZI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇUKURÇAYIR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇUĞUN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "MERKEZ",
        "STREET": "ÖZBAĞ-HELVACILAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "MERKEZ",
        "STREET": "ÖZBAĞ-KARŞIYAKA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "MERKEZ",
        "STREET": "ÖZBAĞ-KIZILCA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "MERKEZ",
        "STREET": "ÖZBAĞ-KIŞLAPINAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "MERKEZ",
        "STREET": "ÖZBAĞ-SARAYLAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "MERKEZ",
        "STREET": "ÖZBAĞ-YENİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "MERKEZ",
        "STREET": "ÖZBAĞ-ÖRCÜN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "MUCUR",
        "STREET": "ACIÖZ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "MUCUR",
        "STREET": "AKSAKLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "MUCUR",
        "STREET": "ALTINYAZI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "MUCUR",
        "STREET": "ASMAKARADAM KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "MUCUR",
        "STREET": "AVCIKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "MUCUR",
        "STREET": "AYDOĞMUŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "MUCUR",
        "STREET": "BABUR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "MUCUR",
        "STREET": "BAYRAMUŞAĞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "MUCUR",
        "STREET": "BAZLAMAÇ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "MUCUR",
        "STREET": "BUDAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "MUCUR",
        "STREET": "BÜYÜKKAYAPA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "MUCUR",
        "STREET": "DALAKÇI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "MUCUR",
        "STREET": "DAĞÇİFTLİĞİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "MUCUR",
        "STREET": "DEVEPINARI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "MUCUR",
        "STREET": "GEYCEK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "MUCUR",
        "STREET": "GÜMÜŞKÜMBET KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "MUCUR",
        "STREET": "GÜZYURDU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "MUCUR",
        "STREET": "HAMİDİYE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "MUCUR",
        "STREET": "KARAARKAÇ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "MUCUR",
        "STREET": "KARACALI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "MUCUR",
        "STREET": "KARAKUYU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "MUCUR",
        "STREET": "KARKIN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "MUCUR",
        "STREET": "KARŞIYAKA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "MUCUR",
        "STREET": "KEPEZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "MUCUR",
        "STREET": "KILIÇLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "MUCUR",
        "STREET": "KIRAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "MUCUR",
        "STREET": "KIZILAĞIL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "MUCUR",
        "STREET": "KIZILDAĞYENİYAPAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "MUCUR",
        "STREET": "KURUGÖL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "MUCUR",
        "STREET": "KUŞAKLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "MUCUR",
        "STREET": "KÜÇÜKBURNAĞIL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "MUCUR",
        "STREET": "KÜÇÜKKAVAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "MUCUR",
        "STREET": "KÜÇÜKKAYAPA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "MUCUR",
        "STREET": "MEDETSİZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "MUCUR",
        "STREET": "OBRUK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "MUCUR",
        "STREET": "OSMANGAZİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "MUCUR",
        "STREET": "PALANGIÇ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "MUCUR",
        "STREET": "PINARKAYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "MUCUR",
        "STREET": "RAHMALAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "MUCUR",
        "STREET": "SEYFE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "MUCUR",
        "STREET": "SOLAKLI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "MUCUR",
        "STREET": "SUSUZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "MUCUR",
        "STREET": "YAZIKINIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "MUCUR",
        "STREET": "YENİCE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "MUCUR",
        "STREET": "YENİKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "MUCUR",
        "STREET": "YEĞENAĞA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "MUCUR",
        "STREET": "YEŞİLYURT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "MUCUR",
        "STREET": "YÜRÜCEK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "MUCUR",
        "STREET": "ÇATALARKAÇ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "MUCUR",
        "STREET": "İNAÇ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "MUCUR",
        "STREET": "ŞATIROĞLU MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "ÇİÇEKDAĞI",
        "STREET": "ACIKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "ÇİÇEKDAĞI",
        "STREET": "AKBIYIKLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "ÇİÇEKDAĞI",
        "STREET": "ALAHACILI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "ÇİÇEKDAĞI",
        "STREET": "ALANKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "ÇİÇEKDAĞI",
        "STREET": "ALİMPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "ÇİÇEKDAĞI",
        "STREET": "ARMUTLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "ÇİÇEKDAĞI",
        "STREET": "AŞAĞIHACIAHMETLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "ÇİÇEKDAĞI",
        "STREET": "BAHÇEPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "ÇİÇEKDAĞI",
        "STREET": "BARAKLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "ÇİÇEKDAĞI",
        "STREET": "BAĞLARBAŞI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "ÇİÇEKDAĞI",
        "STREET": "BEŞİKLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "ÇİÇEKDAĞI",
        "STREET": "BOYALIK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "ÇİÇEKDAĞI",
        "STREET": "BOZLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "ÇİÇEKDAĞI",
        "STREET": "BOĞAZEVCİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "ÇİÇEKDAĞI",
        "STREET": "BÜYÜKTEFLEK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "ÇİÇEKDAĞI",
        "STREET": "DEMİRLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "ÇİÇEKDAĞI",
        "STREET": "DOĞANKAŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "ÇİÇEKDAĞI",
        "STREET": "FATİH MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "ÇİÇEKDAĞI",
        "STREET": "FEVZİ ÇAKMAK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "ÇİÇEKDAĞI",
        "STREET": "GÖLCÜK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "ÇİÇEKDAĞI",
        "STREET": "HACIDURAKLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "ÇİÇEKDAĞI",
        "STREET": "HACIHASANLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "ÇİÇEKDAĞI",
        "STREET": "HACIOĞLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "ÇİÇEKDAĞI",
        "STREET": "HALAÇLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "ÇİÇEKDAĞI",
        "STREET": "HARMANPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "ÇİÇEKDAĞI",
        "STREET": "HAYDARLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "ÇİÇEKDAĞI",
        "STREET": "KABAKLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "ÇİÇEKDAĞI",
        "STREET": "KALEEVCİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "ÇİÇEKDAĞI",
        "STREET": "KAVAKLIÖZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "ÇİÇEKDAĞI",
        "STREET": "KIRDÖK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "ÇİÇEKDAĞI",
        "STREET": "KIZILCALI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "ÇİÇEKDAĞI",
        "STREET": "KONURKALE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "ÇİÇEKDAĞI",
        "STREET": "KÖSELİ-BAHÇELİEVLER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "ÇİÇEKDAĞI",
        "STREET": "KÖSELİ-CUMHURİYET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "ÇİÇEKDAĞI",
        "STREET": "KÖSELİ-DEVRECELİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "ÇİÇEKDAĞI",
        "STREET": "KÖSELİ-ÇİFTLİK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "ÇİÇEKDAĞI",
        "STREET": "KÖSELİ-ÇİÇEK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "ÇİÇEKDAĞI",
        "STREET": "KÜÇÜKTEFLEK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "ÇİÇEKDAĞI",
        "STREET": "MAHMUTLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "ÇİÇEKDAĞI",
        "STREET": "ORTAHACIAHMETLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "ÇİÇEKDAĞI",
        "STREET": "OSMAN ŞEVKİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "ÇİÇEKDAĞI",
        "STREET": "PÖHRENK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "ÇİÇEKDAĞI",
        "STREET": "SAFALI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "ÇİÇEKDAĞI",
        "STREET": "TATBEKİRLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "ÇİÇEKDAĞI",
        "STREET": "TEPECİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "ÇİÇEKDAĞI",
        "STREET": "TOPALALİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "ÇİÇEKDAĞI",
        "STREET": "YALNIZAĞAÇ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "ÇİÇEKDAĞI",
        "STREET": "YENİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "ÇİÇEKDAĞI",
        "STREET": "YENİŞEHİR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "ÇİÇEKDAĞI",
        "STREET": "YUKARIHACIAHMETLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "ÇİÇEKDAĞI",
        "STREET": "ÇANAKPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "ÇİÇEKDAĞI",
        "STREET": "ÇEPNİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "ÇİÇEKDAĞI",
        "STREET": "ÇOPRAŞIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "ÇİÇEKDAĞI",
        "STREET": "ÇUBUKTARLA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "ÇİÇEKDAĞI",
        "STREET": "ÇİÇEKLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "ÇİÇEKDAĞI",
        "STREET": "İBİKLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KIRŞEHİR",
        "DISTRICT": "ÇİÇEKDAĞI",
        "STREET": "ŞAHİNOĞLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "BAŞİSKELE",
        "STREET": "AKSIĞIN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "BAŞİSKELE",
        "STREET": "ALTINKENT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "BAŞİSKELE",
        "STREET": "ATAKENT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "BAŞİSKELE",
        "STREET": "AYDINKENT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "BAŞİSKELE",
        "STREET": "BARBAROS MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "BAŞİSKELE",
        "STREET": "CAMİDÜZÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "BAŞİSKELE",
        "STREET": "DAMLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "BAŞİSKELE",
        "STREET": "DOĞANTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "BAŞİSKELE",
        "STREET": "DÖNGEL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "BAŞİSKELE",
        "STREET": "FATİH MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "BAŞİSKELE",
        "STREET": "HAVUZLUBAHÇE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "BAŞİSKELE",
        "STREET": "KARADENİZLİLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "BAŞİSKELE",
        "STREET": "KARŞIYAKA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "BAŞİSKELE",
        "STREET": "KAZANDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "BAŞİSKELE",
        "STREET": "KILIÇARSLAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "BAŞİSKELE",
        "STREET": "KULLAR TEPECİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "BAŞİSKELE",
        "STREET": "KULLAR YAKACIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "BAŞİSKELE",
        "STREET": "KÖRFEZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "BAŞİSKELE",
        "STREET": "MAHMUTPAŞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "BAŞİSKELE",
        "STREET": "MEHMETAĞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "BAŞİSKELE",
        "STREET": "MERKEZ-TEPECİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "BAŞİSKELE",
        "STREET": "OVACIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "BAŞİSKELE",
        "STREET": "PAŞADAĞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "BAŞİSKELE",
        "STREET": "SAHİL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "BAŞİSKELE",
        "STREET": "SEPETLİPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "BAŞİSKELE",
        "STREET": "SERDAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "BAŞİSKELE",
        "STREET": "SERVETİYE CAMİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "BAŞİSKELE",
        "STREET": "SERVETİYE KARŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "BAŞİSKELE",
        "STREET": "SERİNDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "BAŞİSKELE",
        "STREET": "SEYMEN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "BAŞİSKELE",
        "STREET": "VEZİRÇİFTLİĞİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "BAŞİSKELE",
        "STREET": "YAYLACIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "BAŞİSKELE",
        "STREET": "YENİKÖY MERKEZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "BAŞİSKELE",
        "STREET": "YEŞİLKENT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "BAŞİSKELE",
        "STREET": "YEŞİLYURT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "BAŞİSKELE",
        "STREET": "YUVACIK YAKACIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "BAŞİSKELE",
        "STREET": "ŞEHİTEKREM MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "DARICA",
        "STREET": "ABDİ İPEKÇİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "DARICA",
        "STREET": "BAYRAMOĞLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "DARICA",
        "STREET": "BAĞLARBAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "DARICA",
        "STREET": "CAMİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "DARICA",
        "STREET": "EMEK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "DARICA",
        "STREET": "FEVZİÇAKMAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "DARICA",
        "STREET": "KAZIMKARABEKİR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "DARICA",
        "STREET": "NENEHATUN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "DARICA",
        "STREET": "OSMANGAZİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "DARICA",
        "STREET": "PİRİ REİS MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "DARICA",
        "STREET": "SIRASÖĞÜTLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "DARICA",
        "STREET": "YALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "DARICA",
        "STREET": "YENİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "DARICA",
        "STREET": "ZİNCİRLİKUYU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "DERİNCE",
        "STREET": "DENİZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "DERİNCE",
        "STREET": "DUMLUPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "DERİNCE",
        "STREET": "FATİH SULTAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "DERİNCE",
        "STREET": "GEREDELİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "DERİNCE",
        "STREET": "KARAGÖLLÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "DERİNCE",
        "STREET": "KAŞIKÇI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "DERİNCE",
        "STREET": "MERSİNCİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "DERİNCE",
        "STREET": "SIRRIPAŞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "DERİNCE",
        "STREET": "TAHTALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "DERİNCE",
        "STREET": "TERZİLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "DERİNCE",
        "STREET": "TOYLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "DERİNCE",
        "STREET": "YAVUZ SULTAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "DERİNCE",
        "STREET": "YENİKENT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "DERİNCE",
        "STREET": "ÇAVUŞLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "DERİNCE",
        "STREET": "ÇENEDAĞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "DERİNCE",
        "STREET": "ÇINARLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "DERİNCE",
        "STREET": "İBNİSİNA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "DİLOVASI",
        "STREET": "CUMHURİYET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "DİLOVASI",
        "STREET": "DEMİRCİLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "DİLOVASI",
        "STREET": "DİLİSKELESİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "DİLOVASI",
        "STREET": "FATİH MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "DİLOVASI",
        "STREET": "KAYAPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "DİLOVASI",
        "STREET": "KÖSELER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "DİLOVASI",
        "STREET": "MİMAR SİNAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "DİLOVASI",
        "STREET": "ORHANGAZİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "DİLOVASI",
        "STREET": "TAVŞANCIL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "DİLOVASI",
        "STREET": "TEPECİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "DİLOVASI",
        "STREET": "TURGUT ÖZAL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "DİLOVASI",
        "STREET": "ÇERKEŞLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "GEBZE",
        "STREET": "ADEM YAVUZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "GEBZE",
        "STREET": "AHATLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "GEBZE",
        "STREET": "ARAPÇEŞME MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "GEBZE",
        "STREET": "BALÇIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "GEBZE",
        "STREET": "BARIŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "GEBZE",
        "STREET": "BEYLİKBAĞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "GEBZE",
        "STREET": "CUMAKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "GEBZE",
        "STREET": "CUMHURİYET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "GEBZE",
        "STREET": "DENİZLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "GEBZE",
        "STREET": "DURAKLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "GEBZE",
        "STREET": "ELBİZLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "GEBZE",
        "STREET": "ESKİHİSAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "GEBZE",
        "STREET": "GAZİLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "GEBZE",
        "STREET": "GEBZE KADIN KAPALI CEZA İNFAZ KURUMU MÜDÜRLÜĞÜ",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "GEBZE",
        "STREET": "GÜZELLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "GEBZE",
        "STREET": "HACIHALİL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "GEBZE",
        "STREET": "HATİPLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "GEBZE",
        "STREET": "HÜRRİYET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "GEBZE",
        "STREET": "KADILLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "GEBZE",
        "STREET": "KARGALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "GEBZE",
        "STREET": "KÖŞKLÜ ÇEŞME MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "GEBZE",
        "STREET": "KİRAZPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "GEBZE",
        "STREET": "MEVLANA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "GEBZE",
        "STREET": "MOLLAFENARİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "GEBZE",
        "STREET": "MUALLİMKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "GEBZE",
        "STREET": "MUDARLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "GEBZE",
        "STREET": "MUSTAFAPAŞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "GEBZE",
        "STREET": "MİMAR SİNAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "GEBZE",
        "STREET": "OSMAN YILMAZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "GEBZE",
        "STREET": "OVACIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "GEBZE",
        "STREET": "PELİTLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "GEBZE",
        "STREET": "SULTAN ORHAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "GEBZE",
        "STREET": "TATLIKUYU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "GEBZE",
        "STREET": "TAVŞANLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "GEBZE",
        "STREET": "TEPEMANAYIR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "GEBZE",
        "STREET": "ULUS MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "GEBZE",
        "STREET": "YAVUZ SELİM MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "GEBZE",
        "STREET": "YAĞCILAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "GEBZE",
        "STREET": "YENİKENT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "GEBZE",
        "STREET": "İNÖNÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "GEBZE",
        "STREET": "İSTASYON MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "GÖLCÜK",
        "STREET": "ATATÜRK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "GÖLCÜK",
        "STREET": "AYVAZPINARI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "GÖLCÜK",
        "STREET": "CUMHURİYET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "GÖLCÜK",
        "STREET": "DENİZ EVLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "GÖLCÜK",
        "STREET": "DEĞİRMENDERE MERKEZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "GÖLCÜK",
        "STREET": "DEĞİRMENDERE YALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "GÖLCÜK",
        "STREET": "DONANMA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "GÖLCÜK",
        "STREET": "DUMLUPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "GÖLCÜK",
        "STREET": "DÜZAĞAÇ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "GÖLCÜK",
        "STREET": "ESKİFERHADİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "GÖLCÜK",
        "STREET": "FERHADİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "GÖLCÜK",
        "STREET": "HALIDERE YALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "GÖLCÜK",
        "STREET": "HALIDERE YENİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "GÖLCÜK",
        "STREET": "HAMİDİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "GÖLCÜK",
        "STREET": "HASANEYN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "GÖLCÜK",
        "STREET": "HİSAREYN MERKEZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "GÖLCÜK",
        "STREET": "KARAKÖPRÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "GÖLCÜK",
        "STREET": "KAVAKLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "GÖLCÜK",
        "STREET": "KÖRFEZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "GÖLCÜK",
        "STREET": "LÜTFİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "GÖLCÜK",
        "STREET": "MAMURİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "GÖLCÜK",
        "STREET": "MERKEZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "GÖLCÜK",
        "STREET": "MESRURİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "GÖLCÜK",
        "STREET": "NÜZHETİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "GÖLCÜK",
        "STREET": "NİMETİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "GÖLCÜK",
        "STREET": "PANAYIR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "GÖLCÜK",
        "STREET": "PİYALEPAŞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "GÖLCÜK",
        "STREET": "SARAYLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "GÖLCÜK",
        "STREET": "SELİMİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "GÖLCÜK",
        "STREET": "SOFULAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "GÖLCÜK",
        "STREET": "SİYRETİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "GÖLCÜK",
        "STREET": "TOPÇULAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "GÖLCÜK",
        "STREET": "ULAŞLI YALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "GÖLCÜK",
        "STREET": "ULAŞLI YAVUZ SULTAN SELİM MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "GÖLCÜK",
        "STREET": "YAZLIK MERKEZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "GÖLCÜK",
        "STREET": "YAZLIK YENİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "GÖLCÜK",
        "STREET": "YENİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "GÖLCÜK",
        "STREET": "YUKARI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "GÖLCÜK",
        "STREET": "YUNUS EMRE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "GÖLCÜK",
        "STREET": "YÜZBAŞILAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "GÖLCÜK",
        "STREET": "ÇİFTLİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "GÖLCÜK",
        "STREET": "ÖRCÜN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "GÖLCÜK",
        "STREET": "ÜMMİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "GÖLCÜK",
        "STREET": "İCADİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "GÖLCÜK",
        "STREET": "İHSANİYE MERKEZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "GÖLCÜK",
        "STREET": "İPEK YOLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "GÖLCÜK",
        "STREET": "İRŞADİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "GÖLCÜK",
        "STREET": "ŞEHİTLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "GÖLCÜK",
        "STREET": "ŞEVKETİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "GÖLCÜK",
        "STREET": "ŞİRİNKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "KANDIRA",
        "STREET": "1 NO.LU T TİPİ KAPALI CEZA İNFAZ KURUMU",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "KANDIRA",
        "STREET": "2 NO.LU T TİPİ KAPALI CEZA İNFAZ KURUMU",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "KANDIRA",
        "STREET": "AHMETHACILAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "KANDIRA",
        "STREET": "AKBAL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "KANDIRA",
        "STREET": "AKDURAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "KANDIRA",
        "STREET": "AKINCI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "KANDIRA",
        "STREET": "AKÇABEYLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "KANDIRA",
        "STREET": "AKÇAKESE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "KANDIRA",
        "STREET": "AKÇAOVA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "KANDIRA",
        "STREET": "ALAYBEY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "KANDIRA",
        "STREET": "ALEFLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "KANDIRA",
        "STREET": "ANTAPLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "KANDIRA",
        "STREET": "AVDAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "KANDIRA",
        "STREET": "AYDINLIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "KANDIRA",
        "STREET": "AĞAÇAĞIL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "KANDIRA",
        "STREET": "BABAKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "KANDIRA",
        "STREET": "BABALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "KANDIRA",
        "STREET": "BALABAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "KANDIRA",
        "STREET": "BALCI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "KANDIRA",
        "STREET": "BALLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "KANDIRA",
        "STREET": "BAĞIRGANLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "KANDIRA",
        "STREET": "BEYCE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "KANDIRA",
        "STREET": "BEYLERBEYİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "KANDIRA",
        "STREET": "BOLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "KANDIRA",
        "STREET": "BOZBURUN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "KANDIRA",
        "STREET": "CEBECİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "KANDIRA",
        "STREET": "DALCA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "KANDIRA",
        "STREET": "DELİVELİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "KANDIRA",
        "STREET": "DOĞANCILI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "KANDIRA",
        "STREET": "DURAKLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "KANDIRA",
        "STREET": "DÖNGELLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "KANDIRA",
        "STREET": "ELMACIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "KANDIRA",
        "STREET": "ESENTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "KANDIRA",
        "STREET": "EĞERCİLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "KANDIRA",
        "STREET": "FERİZLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "KANDIRA",
        "STREET": "GEBEŞLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "KANDIRA",
        "STREET": "GONCAAYDIN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "KANDIRA",
        "STREET": "HACILAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "KANDIRA",
        "STREET": "HACIMAZLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "KANDIRA",
        "STREET": "HACIŞEYH MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "KANDIRA",
        "STREET": "HEDİYELİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "KANDIRA",
        "STREET": "HIDIRLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "KANDIRA",
        "STREET": "HÜDAVERDİLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "KANDIRA",
        "STREET": "KABAAĞAÇ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "KANDIRA",
        "STREET": "KANATLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "KANDIRA",
        "STREET": "KARAAĞAÇ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "KANDIRA",
        "STREET": "KARADİVAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "KANDIRA",
        "STREET": "KARLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "KANDIRA",
        "STREET": "KAYMAZ ERİKLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "KANDIRA",
        "STREET": "KAYMAZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "KANDIRA",
        "STREET": "KEFKEN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "KANDIRA",
        "STREET": "KERPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "KANDIRA",
        "STREET": "KIRKARMUT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "KANDIRA",
        "STREET": "KIZILCAPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "KANDIRA",
        "STREET": "KOCAELİ F TİPİ 1 NOLU KAPALI CEZA İNFAZ KURUMU",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "KANDIRA",
        "STREET": "KOCAELİ F TİPİ 2 NOLU KAPALI CEZA İNFAZ KURUMU",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "KANDIRA",
        "STREET": "KOCAKAYMAS MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "KANDIRA",
        "STREET": "KUBUZCU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "KANDIRA",
        "STREET": "KURTYERİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "KANDIRA",
        "STREET": "LOKMANLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "KANDIRA",
        "STREET": "MANCARLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "KANDIRA",
        "STREET": "MERKEZ ERİKLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "KANDIRA",
        "STREET": "MÜLKÜŞEHSUVAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "KANDIRA",
        "STREET": "NASUHLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "KANDIRA",
        "STREET": "ORHAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "KANDIRA",
        "STREET": "PELİTPINARI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "KANDIRA",
        "STREET": "PINARDÜZÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "KANDIRA",
        "STREET": "PINARLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "KANDIRA",
        "STREET": "PİRCELER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "KANDIRA",
        "STREET": "SAFALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "KANDIRA",
        "STREET": "SARIAHMETLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "KANDIRA",
        "STREET": "SARICAALİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "KANDIRA",
        "STREET": "SARIGAZİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "KANDIRA",
        "STREET": "SARNIÇLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "KANDIRA",
        "STREET": "SELAMETLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "KANDIRA",
        "STREET": "SELİMKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "KANDIRA",
        "STREET": "SEPETÇİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "KANDIRA",
        "STREET": "SEYİTALİLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "KANDIRA",
        "STREET": "SUCUALİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "KANDIRA",
        "STREET": "SÜLLÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "KANDIRA",
        "STREET": "SİNANLIBİLALLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "KANDIRA",
        "STREET": "TATARAHMET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "KANDIRA",
        "STREET": "TEKSEN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "KANDIRA",
        "STREET": "TERZİLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "KANDIRA",
        "STREET": "TOPLUCA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "KANDIRA",
        "STREET": "YAĞCILAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "KANDIRA",
        "STREET": "YUSUFÇA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "KANDIRA",
        "STREET": "ÇAKIRCAALİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "KANDIRA",
        "STREET": "ÇAKMAKLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "KANDIRA",
        "STREET": "ÇALCA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "KANDIRA",
        "STREET": "ÇALKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "KANDIRA",
        "STREET": "ÇALYER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "KANDIRA",
        "STREET": "ÇAMKONAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "KANDIRA",
        "STREET": "ÇARŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "KANDIRA",
        "STREET": "ÇERÇİLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "KANDIRA",
        "STREET": "ÖMERLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "KANDIRA",
        "STREET": "ÖZBEY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "KANDIRA",
        "STREET": "ÜĞÜMCE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "KANDIRA",
        "STREET": "İNCECİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "KANDIRA",
        "STREET": "ŞEREFSUNGUR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "KARAMÜRSEL",
        "STREET": "4 TEMMUZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "KARAMÜRSEL",
        "STREET": "AKPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "KARAMÜRSEL",
        "STREET": "AKÇAT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "KARAMÜRSEL",
        "STREET": "AVCIKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "KARAMÜRSEL",
        "STREET": "DEREKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "KARAMÜRSEL",
        "STREET": "EREĞLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "KARAMÜRSEL",
        "STREET": "FULACIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "KARAMÜRSEL",
        "STREET": "HAYRİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "KARAMÜRSEL",
        "STREET": "KADRİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "KARAMÜRSEL",
        "STREET": "KARAAHMETLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "KARAMÜRSEL",
        "STREET": "KARAPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "KARAMÜRSEL",
        "STREET": "KAYACIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "KARAMÜRSEL",
        "STREET": "KIZDERBENT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "KARAMÜRSEL",
        "STREET": "OLUKLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "KARAMÜRSEL",
        "STREET": "OSMANİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "KARAMÜRSEL",
        "STREET": "PAZARKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "KARAMÜRSEL",
        "STREET": "SAFİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "KARAMÜRSEL",
        "STREET": "SEMETLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "KARAMÜRSEL",
        "STREET": "SENAİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "KARAMÜRSEL",
        "STREET": "SULUDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "KARAMÜRSEL",
        "STREET": "TAHTALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "KARAMÜRSEL",
        "STREET": "TEPEKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "KARAMÜRSEL",
        "STREET": "YALAKDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "KARAMÜRSEL",
        "STREET": "ÇAMDİBİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "KARAMÜRSEL",
        "STREET": "ÇAMÇUKUR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "KARAMÜRSEL",
        "STREET": "İHSANİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "KARAMÜRSEL",
        "STREET": "İNEBEYLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "KARTEPE",
        "STREET": "ACISU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "KARTEPE",
        "STREET": "ARSLANBEY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "KARTEPE",
        "STREET": "ATAEVLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "KARTEPE",
        "STREET": "ATAŞEHİR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "KARTEPE",
        "STREET": "BALABAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "KARTEPE",
        "STREET": "DERBENT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "KARTEPE",
        "STREET": "DUMLUPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "KARTEPE",
        "STREET": "EMEKEVLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "KARTEPE",
        "STREET": "ERTUĞRUL GAZİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "KARTEPE",
        "STREET": "EŞME MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "KARTEPE",
        "STREET": "EŞMEAHMEDİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "KARTEPE",
        "STREET": "FATİH SULTAN MEHMET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "KARTEPE",
        "STREET": "HAVLUBURUN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "KARTEPE",
        "STREET": "KARATEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "KARTEPE",
        "STREET": "KETENCİLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "KARTEPE",
        "STREET": "KÖSEKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "KARTEPE",
        "STREET": "MAŞUKİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "KARTEPE",
        "STREET": "NUSRETİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "KARTEPE",
        "STREET": "PAZARÇAYIRI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "KARTEPE",
        "STREET": "RAHMİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "KARTEPE",
        "STREET": "SARIMEŞE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "KARTEPE",
        "STREET": "SERİNLİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "KARTEPE",
        "STREET": "SUADİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "KARTEPE",
        "STREET": "SULTANİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "KARTEPE",
        "STREET": "UZUNBEY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "KARTEPE",
        "STREET": "UZUNTARLA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "KARTEPE",
        "STREET": "UZUNÇİFTLİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "KARTEPE",
        "STREET": "ÇEPNİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "KARTEPE",
        "STREET": "İBRİKDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "KARTEPE",
        "STREET": "İSTASYON MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "KARTEPE",
        "STREET": "ŞEVKATİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "KARTEPE",
        "STREET": "ŞİRİNSULHİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "KÖRFEZ",
        "STREET": "17 AĞUSTOS MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "KÖRFEZ",
        "STREET": "AGAH ATEŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "KÖRFEZ",
        "STREET": "ALİHOCALAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "KÖRFEZ",
        "STREET": "ATALAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "KÖRFEZ",
        "STREET": "BARBAROS MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "KÖRFEZ",
        "STREET": "BELEN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "KÖRFEZ",
        "STREET": "CUMA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "KÖRFEZ",
        "STREET": "CUMHURİYET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "KÖRFEZ",
        "STREET": "DERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "KÖRFEZ",
        "STREET": "DİKENLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "KÖRFEZ",
        "STREET": "ELMACIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "KÖRFEZ",
        "STREET": "ESENTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "KÖRFEZ",
        "STREET": "FATİH MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "KÖRFEZ",
        "STREET": "GÜNEY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "KÖRFEZ",
        "STREET": "HACI AKİF MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "KÖRFEZ",
        "STREET": "HACI OSMAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "KÖRFEZ",
        "STREET": "HİMMETLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "KÖRFEZ",
        "STREET": "KALBURCU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "KÖRFEZ",
        "STREET": "KARAYAKUPLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "KÖRFEZ",
        "STREET": "KIŞLADÜZÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "KÖRFEZ",
        "STREET": "KUTLUCA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "KÖRFEZ",
        "STREET": "KUZEY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "KÖRFEZ",
        "STREET": "KİRAZLIYALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "KÖRFEZ",
        "STREET": "MİMAR SİNAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "KÖRFEZ",
        "STREET": "NAİP MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "KÖRFEZ",
        "STREET": "OSMANLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "KÖRFEZ",
        "STREET": "SEVİNDİKLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "KÖRFEZ",
        "STREET": "SİPAHİLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "KÖRFEZ",
        "STREET": "YAVUZ SULTAN SELİM MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "KÖRFEZ",
        "STREET": "YENİYALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "KÖRFEZ",
        "STREET": "YUKARI HEREKE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "KÖRFEZ",
        "STREET": "ÇAMLITEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "KÖRFEZ",
        "STREET": "ÇIRAKLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "KÖRFEZ",
        "STREET": "İLİMTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "KÖRFEZ",
        "STREET": "ŞEMSETTİN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "KÖRFEZ",
        "STREET": "ŞİRİNYALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "ÇAYIROVA",
        "STREET": "AKSE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "ÇAYIROVA",
        "STREET": "ATATÜRK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "ÇAYIROVA",
        "STREET": "CUMHURİYET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "ÇAYIROVA",
        "STREET": "EMEK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "ÇAYIROVA",
        "STREET": "YENİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "ÇAYIROVA",
        "STREET": "ÇAYIROVA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "ÇAYIROVA",
        "STREET": "ÖZGÜRLÜK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "ÇAYIROVA",
        "STREET": "İNÖNÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "ÇAYIROVA",
        "STREET": "ŞEKERPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "İZMİT",
        "STREET": "28 HAZİRAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "İZMİT",
        "STREET": "AKARCA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "İZMİT",
        "STREET": "AKMEŞE ATATÜRK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "İZMİT",
        "STREET": "AKMEŞE CUMHURİYET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "İZMİT",
        "STREET": "AKPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "İZMİT",
        "STREET": "AKÇAKOCA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "İZMİT",
        "STREET": "ALİKAHYA ATATÜRK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "İZMİT",
        "STREET": "ALİKAHYA CUMHURİYET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "İZMİT",
        "STREET": "ALİKAHYA FATİH MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "İZMİT",
        "STREET": "ALİKAHYA MERKEZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "İZMİT",
        "STREET": "AMBARCI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "İZMİT",
        "STREET": "ARIZLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "İZMİT",
        "STREET": "ARPALIKİHSANİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "İZMİT",
        "STREET": "AYAZMA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "İZMİT",
        "STREET": "BALÖREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "İZMİT",
        "STREET": "BAYRAKTAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "İZMİT",
        "STREET": "BAĞLICA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "İZMİT",
        "STREET": "BEKİRDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "İZMİT",
        "STREET": "BULDUK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "İZMİT",
        "STREET": "BÖĞÜRGEN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "İZMİT",
        "STREET": "BİBEROĞLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "İZMİT",
        "STREET": "CEDİT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "İZMİT",
        "STREET": "CUMHURİYET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "İZMİT",
        "STREET": "DAĞKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "İZMİT",
        "STREET": "DOĞAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "İZMİT",
        "STREET": "DURHASAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "İZMİT",
        "STREET": "DÜĞMECİLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "İZMİT",
        "STREET": "EMİRHAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "İZMİT",
        "STREET": "ERENLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "İZMİT",
        "STREET": "ESELER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "İZMİT",
        "STREET": "FATİH MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "İZMİT",
        "STREET": "FETHİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "İZMİT",
        "STREET": "FEVZİ ÇAKMAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "İZMİT",
        "STREET": "GEDİKLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "İZMİT",
        "STREET": "GÖKÇEÖREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "İZMİT",
        "STREET": "GÜLBAHÇE KADRİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "İZMİT",
        "STREET": "GÜLTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "İZMİT",
        "STREET": "GÜNDOĞDU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "İZMİT",
        "STREET": "GÜVERCİNLİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "İZMİT",
        "STREET": "HACIHASAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "İZMİT",
        "STREET": "HACIHIZIR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "İZMİT",
        "STREET": "HAKANİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "İZMİT",
        "STREET": "HASANCIKLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "İZMİT",
        "STREET": "HATİPKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "İZMİT",
        "STREET": "KABAOĞLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "İZMİT",
        "STREET": "KADIKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "İZMİT",
        "STREET": "KARAABDÜLBAKİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "İZMİT",
        "STREET": "KARABAŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "İZMİT",
        "STREET": "KARADENİZLİLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "İZMİT",
        "STREET": "KAYNARCA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "İZMİT",
        "STREET": "KEMALPAŞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "İZMİT",
        "STREET": "KISALAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "İZMİT",
        "STREET": "KOCATEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "İZMİT",
        "STREET": "KOZLUCA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "İZMİT",
        "STREET": "KOZLUK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "İZMİT",
        "STREET": "KULFALLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "İZMİT",
        "STREET": "KULMAHMUT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "İZMİT",
        "STREET": "KURTDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "İZMİT",
        "STREET": "KURUÇEŞME FATİH MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "İZMİT",
        "STREET": "KÖRFEZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "İZMİT",
        "STREET": "M.ALİPAŞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "İZMİT",
        "STREET": "MALTA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "İZMİT",
        "STREET": "MECİDİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "İZMİT",
        "STREET": "NEBİHOCA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "İZMİT",
        "STREET": "ORHAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "İZMİT",
        "STREET": "ORHANİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "İZMİT",
        "STREET": "ORTABURUN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "İZMİT",
        "STREET": "SANAYİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "İZMİT",
        "STREET": "SAPAKPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "İZMİT",
        "STREET": "SARIŞEYH MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "İZMİT",
        "STREET": "SEKBANLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "İZMİT",
        "STREET": "SEPETÇİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "İZMİT",
        "STREET": "SERDAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "İZMİT",
        "STREET": "SULTANİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "İZMİT",
        "STREET": "SÜLEYMANİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "İZMİT",
        "STREET": "SÜVERLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "İZMİT",
        "STREET": "TAVŞANTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "İZMİT",
        "STREET": "TEPECİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "İZMİT",
        "STREET": "TEPEKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "İZMİT",
        "STREET": "TERZİBAYIRI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "İZMİT",
        "STREET": "TOPÇULAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "İZMİT",
        "STREET": "TURGUT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "İZMİT",
        "STREET": "TÜYSÜZLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "İZMİT",
        "STREET": "VELİAHMET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "İZMİT",
        "STREET": "YAHYAKAPTAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "İZMİT",
        "STREET": "YASSIBAĞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "İZMİT",
        "STREET": "YENİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "İZMİT",
        "STREET": "YENİCE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "İZMİT",
        "STREET": "YENİDOĞAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "İZMİT",
        "STREET": "YENİŞEHİR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "İZMİT",
        "STREET": "YEŞİLOVA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "İZMİT",
        "STREET": "ZABITAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "İZMİT",
        "STREET": "ZEYTİNBURNU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "İZMİT",
        "STREET": "ÇAVUŞOĞLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "İZMİT",
        "STREET": "ÇAYIRKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "İZMİT",
        "STREET": "ÇAĞIRGAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "İZMİT",
        "STREET": "ÇUBUKLUBALA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "İZMİT",
        "STREET": "ÇUBUKLUOSMANİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "İZMİT",
        "STREET": "ÇUKURBAĞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "İZMİT",
        "STREET": "ÖMERAĞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "İZMİT",
        "STREET": "ŞAHİNLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KOCAELİ",
        "DISTRICT": "İZMİT",
        "STREET": "ŞİRİNTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "AHIRLI",
        "STREET": "AKKİSE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "AHIRLI",
        "STREET": "ALİÇERÇİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "AHIRLI",
        "STREET": "BADEMLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "AHIRLI",
        "STREET": "BALIKLAVA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "AHIRLI",
        "STREET": "BÜYÜKÖZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "AHIRLI",
        "STREET": "ERDOĞAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "AHIRLI",
        "STREET": "HAMZALAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "AHIRLI",
        "STREET": "HENGEME MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "AHIRLI",
        "STREET": "KARACAKUYU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "AHIRLI",
        "STREET": "KAYACIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "AHIRLI",
        "STREET": "KURUÇAY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "AHIRLI",
        "STREET": "KÜÇÜKÖZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "AHIRLI",
        "STREET": "MERKEZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "AHIRLI",
        "STREET": "ÇUKURBUCAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "AHIRLI",
        "STREET": "ÇİFTLİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "AKÖREN",
        "STREET": "AHMEDİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "AKÖREN",
        "STREET": "ALANKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "AKÖREN",
        "STREET": "AVDAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "AKÖREN",
        "STREET": "AĞALAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "AKÖREN",
        "STREET": "BELKUYU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "AKÖREN",
        "STREET": "DUTLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "AKÖREN",
        "STREET": "HACILAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "AKÖREN",
        "STREET": "KARAHÜYÜK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "AKÖREN",
        "STREET": "KAYASU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "AKÖREN",
        "STREET": "ORHANİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "AKÖREN",
        "STREET": "SÜLEYMANİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "AKÖREN",
        "STREET": "TÜLCE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "AKÖREN",
        "STREET": "YENİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "AKÖREN",
        "STREET": "ÇATÖREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "AKŞEHİR",
        "STREET": "ADSIZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "AKŞEHİR",
        "STREET": "AHİCELAL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "AKŞEHİR",
        "STREET": "AKŞEHİR T TİPİ KAPALI CEZA İNFAZ KURUMU",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "AKŞEHİR",
        "STREET": "ALANYURT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "AKŞEHİR",
        "STREET": "ALTUNKALEM MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "AKŞEHİR",
        "STREET": "ALTUNTAŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "AKŞEHİR",
        "STREET": "ANIT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "AKŞEHİR",
        "STREET": "ATAKENT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "AKŞEHİR",
        "STREET": "BOZLAĞAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "AKŞEHİR",
        "STREET": "CANKURTARAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "AKŞEHİR",
        "STREET": "DEĞİRMENKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "AKŞEHİR",
        "STREET": "DOĞRUGÖZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "AKŞEHİR",
        "STREET": "ENGİLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "AKŞEHİR",
        "STREET": "ESKİKALE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "AKŞEHİR",
        "STREET": "GAZİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "AKŞEHİR",
        "STREET": "GEDİL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "AKŞEHİR",
        "STREET": "GÖLÇAYIR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "AKŞEHİR",
        "STREET": "GÖZPINARI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "AKŞEHİR",
        "STREET": "ILICAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "AKŞEHİR",
        "STREET": "KARABULUT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "AKŞEHİR",
        "STREET": "KARAHÜYÜK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "AKŞEHİR",
        "STREET": "KIZILCA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "AKŞEHİR",
        "STREET": "KOZAĞAÇ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "AKŞEHİR",
        "STREET": "KURUÇAY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "AKŞEHİR",
        "STREET": "KUŞÇU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "AKŞEHİR",
        "STREET": "KİLECİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "AKŞEHİR",
        "STREET": "MEYDAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "AKŞEHİR",
        "STREET": "NASREDDİN HOCA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "AKŞEHİR",
        "STREET": "ORTACA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "AKŞEHİR",
        "STREET": "ORTAKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "AKŞEHİR",
        "STREET": "REİS MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "AKŞEHİR",
        "STREET": "SARAY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "AKŞEHİR",
        "STREET": "SAVAŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "AKŞEHİR",
        "STREET": "SELÇUK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "AKŞEHİR",
        "STREET": "SEYRAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "AKŞEHİR",
        "STREET": "SORKUN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "AKŞEHİR",
        "STREET": "SÖĞÜTLÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "AKŞEHİR",
        "STREET": "TEKKEKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "AKŞEHİR",
        "STREET": "TİPİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "AKŞEHİR",
        "STREET": "TİPİKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "AKŞEHİR",
        "STREET": "ULUPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "AKŞEHİR",
        "STREET": "YARENLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "AKŞEHİR",
        "STREET": "YAYLABELEN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "AKŞEHİR",
        "STREET": "YAZLA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "AKŞEHİR",
        "STREET": "YAŞARLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "AKŞEHİR",
        "STREET": "YENİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "AKŞEHİR",
        "STREET": "YENİKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "AKŞEHİR",
        "STREET": "YEŞİLKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "AKŞEHİR",
        "STREET": "YILDIRIM BAYEZİD MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "AKŞEHİR",
        "STREET": "ÇAKILLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "AKŞEHİR",
        "STREET": "ÇAMLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "AKŞEHİR",
        "STREET": "ÇAY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "AKŞEHİR",
        "STREET": "ÇİMENDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "AKŞEHİR",
        "STREET": "ÇİMENLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "AKŞEHİR",
        "STREET": "ÜÇHÜYÜK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "AKŞEHİR",
        "STREET": "İSTASYON MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "ALTINEKİN",
        "STREET": "AKINCILAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "ALTINEKİN",
        "STREET": "AKKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "ALTINEKİN",
        "STREET": "AKÇAŞAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "ALTINEKİN",
        "STREET": "AYIŞIĞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "ALTINEKİN",
        "STREET": "BORUKKUYU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "ALTINEKİN",
        "STREET": "DEDELER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "ALTINEKİN",
        "STREET": "HACINUMAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "ALTINEKİN",
        "STREET": "KALE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "ALTINEKİN",
        "STREET": "KARAKAYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "ALTINEKİN",
        "STREET": "KOÇAŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "ALTINEKİN",
        "STREET": "KOÇYAKA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "ALTINEKİN",
        "STREET": "MANTAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "ALTINEKİN",
        "STREET": "OĞUZELİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "ALTINEKİN",
        "STREET": "SARNIÇ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "ALTINEKİN",
        "STREET": "TOPRAKLIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "ALTINEKİN",
        "STREET": "YENİ ÖLMEZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "ALTINEKİN",
        "STREET": "YENİCE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "ALTINEKİN",
        "STREET": "YENİKUYU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "ALTINEKİN",
        "STREET": "YENİYAYLA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "ALTINEKİN",
        "STREET": "ÖLMEZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "BEYŞEHİR",
        "STREET": "ADAKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "BEYŞEHİR",
        "STREET": "AKBURUN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "BEYŞEHİR",
        "STREET": "AVDANCIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "BEYŞEHİR",
        "STREET": "AVŞAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "BEYŞEHİR",
        "STREET": "AĞILÖNÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "BEYŞEHİR",
        "STREET": "BADEMLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "BEYŞEHİR",
        "STREET": "BAHÇELİEVLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "BEYŞEHİR",
        "STREET": "BAYAT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "BEYŞEHİR",
        "STREET": "BAYAVŞAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "BEYŞEHİR",
        "STREET": "BAYINDIR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "BEYŞEHİR",
        "STREET": "BAŞGÖZE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "BEYŞEHİR",
        "STREET": "BEKDEMİR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "BEYŞEHİR",
        "STREET": "BEYTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "BEYŞEHİR",
        "STREET": "DALYAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "BEYŞEHİR",
        "STREET": "DAMLAPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "BEYŞEHİR",
        "STREET": "DOĞANBEY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "BEYŞEHİR",
        "STREET": "DOĞANCIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "BEYŞEHİR",
        "STREET": "DUMANLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "BEYŞEHİR",
        "STREET": "EMEN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "BEYŞEHİR",
        "STREET": "ESENCE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "BEYŞEHİR",
        "STREET": "ESENTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "BEYŞEHİR",
        "STREET": "EVSAT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "BEYŞEHİR",
        "STREET": "EYLİKLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "BEYŞEHİR",
        "STREET": "EĞİRLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "BEYŞEHİR",
        "STREET": "FASILLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "BEYŞEHİR",
        "STREET": "GÖKÇEKUYU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "BEYŞEHİR",
        "STREET": "GÖKÇİMEN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "BEYŞEHİR",
        "STREET": "GÖLKAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "BEYŞEHİR",
        "STREET": "GÖLYAKA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "BEYŞEHİR",
        "STREET": "GÖNEN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "BEYŞEHİR",
        "STREET": "GÖÇÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "BEYŞEHİR",
        "STREET": "GÜNDOĞDU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "BEYŞEHİR",
        "STREET": "HACIAKİF MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "BEYŞEHİR",
        "STREET": "HACIARMAĞAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "BEYŞEHİR",
        "STREET": "HAMİDİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "BEYŞEHİR",
        "STREET": "HUĞLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "BEYŞEHİR",
        "STREET": "HÜSEYİNLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "BEYŞEHİR",
        "STREET": "KARAALİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "BEYŞEHİR",
        "STREET": "KARABAYAT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "BEYŞEHİR",
        "STREET": "KARADİKEN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "BEYŞEHİR",
        "STREET": "KARAHİSAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "BEYŞEHİR",
        "STREET": "KAYABAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "BEYŞEHİR",
        "STREET": "KURUCUOVA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "BEYŞEHİR",
        "STREET": "KUŞLUCA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "BEYŞEHİR",
        "STREET": "KÜÇÜKAVŞAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "BEYŞEHİR",
        "STREET": "MESUTLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "BEYŞEHİR",
        "STREET": "MÜFTÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "BEYŞEHİR",
        "STREET": "SADIKHACI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "BEYŞEHİR",
        "STREET": "SARIKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "BEYŞEHİR",
        "STREET": "SEVİNDİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "BEYŞEHİR",
        "STREET": "YAZYURDU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "BEYŞEHİR",
        "STREET": "YENİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "BEYŞEHİR",
        "STREET": "YENİDOĞAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "BEYŞEHİR",
        "STREET": "YEŞİLDAĞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "BEYŞEHİR",
        "STREET": "YUKARIESENCE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "BEYŞEHİR",
        "STREET": "YUNUSLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "BEYŞEHİR",
        "STREET": "ÇETMİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "BEYŞEHİR",
        "STREET": "ÇUKURAĞIL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "BEYŞEHİR",
        "STREET": "ÇİFTLİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "BEYŞEHİR",
        "STREET": "ÇİVRİL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "BEYŞEHİR",
        "STREET": "ÇİÇEKLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "BEYŞEHİR",
        "STREET": "ÜSTÜNLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "BEYŞEHİR",
        "STREET": "ÜZÜMLÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "BEYŞEHİR",
        "STREET": "ÜÇPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "BEYŞEHİR",
        "STREET": "İSAKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "BEYŞEHİR",
        "STREET": "İÇERİŞEHİR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "BEYŞEHİR",
        "STREET": "ŞAMLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "BOZKIR",
        "STREET": "AKÇAPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "BOZKIR",
        "STREET": "ARMUTLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "BOZKIR",
        "STREET": "ARSLANTAŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "BOZKIR",
        "STREET": "AYDINKIŞLA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "BOZKIR",
        "STREET": "AYVALCA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "BOZKIR",
        "STREET": "BAYBAĞAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "BOZKIR",
        "STREET": "BAĞYURDU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "BOZKIR",
        "STREET": "BOZDAM MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "BOZKIR",
        "STREET": "CUMHURİYET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "BOZKIR",
        "STREET": "DEMİRASAF MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "BOZKIR",
        "STREET": "DEREKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "BOZKIR",
        "STREET": "DEREİÇİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "BOZKIR",
        "STREET": "ELMAAĞAÇ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "BOZKIR",
        "STREET": "HACILAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "BOZKIR",
        "STREET": "HACIYUNUSLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "BOZKIR",
        "STREET": "HAMZALAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "BOZKIR",
        "STREET": "HARMANPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "BOZKIR",
        "STREET": "HİSARLIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "BOZKIR",
        "STREET": "IŞIKLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "BOZKIR",
        "STREET": "KARABAYIR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "BOZKIR",
        "STREET": "KARACAARDIÇ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "BOZKIR",
        "STREET": "KARACAHİSAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "BOZKIR",
        "STREET": "KARAYAHYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "BOZKIR",
        "STREET": "KAYACILAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "BOZKIR",
        "STREET": "KAYAPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "BOZKIR",
        "STREET": "KINIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "BOZKIR",
        "STREET": "KIZILÇAKIR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "BOZKIR",
        "STREET": "KOVANLIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "BOZKIR",
        "STREET": "KOZAĞAÇ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "BOZKIR",
        "STREET": "KUZÖREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "BOZKIR",
        "STREET": "KUŞCA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "BOZKIR",
        "STREET": "KÜÇÜKHİSARLIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "BOZKIR",
        "STREET": "KİLDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "BOZKIR",
        "STREET": "PINARCIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "BOZKIR",
        "STREET": "SARIOĞLAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "BOZKIR",
        "STREET": "SAZLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "BOZKIR",
        "STREET": "SORKUN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "BOZKIR",
        "STREET": "SOĞUCAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "BOZKIR",
        "STREET": "SÖĞÜT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "BOZKIR",
        "STREET": "TARLABAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "BOZKIR",
        "STREET": "TAŞBAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "BOZKIR",
        "STREET": "TEPEARASI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "BOZKIR",
        "STREET": "TEPELİCE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "BOZKIR",
        "STREET": "ULUPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "BOZKIR",
        "STREET": "YALNIZCA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "BOZKIR",
        "STREET": "YAZDAMI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "BOZKIR",
        "STREET": "YELBEĞİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "BOZKIR",
        "STREET": "YENİKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "BOZKIR",
        "STREET": "YOLÖREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "BOZKIR",
        "STREET": "YUKARI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "BOZKIR",
        "STREET": "ÇAĞLAYAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "BOZKIR",
        "STREET": "ÜÇPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "CİHANBEYLİ",
        "STREET": "AHİRİGÜZEL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "CİHANBEYLİ",
        "STREET": "ATÇEKEN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "CİHANBEYLİ",
        "STREET": "AĞABEYLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "CİHANBEYLİ",
        "STREET": "BAHÇELİEVLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "CİHANBEYLİ",
        "STREET": "BEYLİOVA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "CİHANBEYLİ",
        "STREET": "BULDUK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "CİHANBEYLİ",
        "STREET": "BÖĞRÜDELİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "CİHANBEYLİ",
        "STREET": "BÜYÜKBEŞKAVAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "CİHANBEYLİ",
        "STREET": "CUMHURİYET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "CİHANBEYLİ",
        "STREET": "DAMLAKUYU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "CİHANBEYLİ",
        "STREET": "GEMECİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "CİHANBEYLİ",
        "STREET": "GÖKTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "CİHANBEYLİ",
        "STREET": "GÖLYAZI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "CİHANBEYLİ",
        "STREET": "GÜNYÜZÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "CİHANBEYLİ",
        "STREET": "HODOĞLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "CİHANBEYLİ",
        "STREET": "KALE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "CİHANBEYLİ",
        "STREET": "KANDİL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "CİHANBEYLİ",
        "STREET": "KARABAĞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "CİHANBEYLİ",
        "STREET": "KARATEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "CİHANBEYLİ",
        "STREET": "KARŞIYAKA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "CİHANBEYLİ",
        "STREET": "KAYI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "CİHANBEYLİ",
        "STREET": "KELHASAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "CİHANBEYLİ",
        "STREET": "KIRKIŞLA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "CİHANBEYLİ",
        "STREET": "KOCATEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "CİHANBEYLİ",
        "STREET": "KORKMAZLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "CİHANBEYLİ",
        "STREET": "KUŞÇA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "CİHANBEYLİ",
        "STREET": "KÖPRÜBAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "CİHANBEYLİ",
        "STREET": "KÜTÜKUŞAĞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "CİHANBEYLİ",
        "STREET": "KÜÇÜKBEŞKAVAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "CİHANBEYLİ",
        "STREET": "MUTLUKONAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "CİHANBEYLİ",
        "STREET": "PINARBAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "CİHANBEYLİ",
        "STREET": "SAĞLIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "CİHANBEYLİ",
        "STREET": "SIĞIRCIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "CİHANBEYLİ",
        "STREET": "TAŞPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "CİHANBEYLİ",
        "STREET": "TURANLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "CİHANBEYLİ",
        "STREET": "TÜFEKÇİPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "CİHANBEYLİ",
        "STREET": "UZUNCAYAYLA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "CİHANBEYLİ",
        "STREET": "YAPALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "CİHANBEYLİ",
        "STREET": "YENİCEOBA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "CİHANBEYLİ",
        "STREET": "YENİYAYLA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "CİHANBEYLİ",
        "STREET": "YEŞİLÖZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "CİHANBEYLİ",
        "STREET": "YÜNLÜKUYU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "CİHANBEYLİ",
        "STREET": "ZAFERİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "CİHANBEYLİ",
        "STREET": "ÇÖL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "CİHANBEYLİ",
        "STREET": "ÇİMEN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "CİHANBEYLİ",
        "STREET": "ÜZERLİKTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "CİHANBEYLİ",
        "STREET": "İNSUYU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "DERBENT",
        "STREET": "ARAPÖZÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "DERBENT",
        "STREET": "CAMİİKEBİR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "DERBENT",
        "STREET": "DERBENTTEKKE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "DERBENT",
        "STREET": "DEĞİŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "DERBENT",
        "STREET": "GÜNEY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "DERBENT",
        "STREET": "GÜNEYKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "DERBENT",
        "STREET": "HACI AHMET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "DERBENT",
        "STREET": "KARALAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "DERBENT",
        "STREET": "MÜLAYİM MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "DERBENT",
        "STREET": "SARAYPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "DERBENT",
        "STREET": "SÜLEYMAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "DERBENT",
        "STREET": "YUKARI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "DERBENT",
        "STREET": "ÇİFTLİKÖZÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "DEREBUCAK",
        "STREET": "DURAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "DEREBUCAK",
        "STREET": "GENCEK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "DEREBUCAK",
        "STREET": "GÖYNEM MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "DEREBUCAK",
        "STREET": "KENANKUYU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "DEREBUCAK",
        "STREET": "MUSALLA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "DEREBUCAK",
        "STREET": "PINARBAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "DEREBUCAK",
        "STREET": "SARAYÖNÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "DEREBUCAK",
        "STREET": "TAŞLIPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "DEREBUCAK",
        "STREET": "TEPEARASI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "DEREBUCAK",
        "STREET": "UĞURLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "DEREBUCAK",
        "STREET": "YENİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "DEREBUCAK",
        "STREET": "ÇAMLIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "DOĞANHİSAR",
        "STREET": "AYASLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "DOĞANHİSAR",
        "STREET": "AĞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "DOĞANHİSAR",
        "STREET": "BAŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "DOĞANHİSAR",
        "STREET": "BAŞKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "DOĞANHİSAR",
        "STREET": "CUMA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "DOĞANHİSAR",
        "STREET": "DEŞTİĞİN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "DOĞANHİSAR",
        "STREET": "FIRINLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "DOĞANHİSAR",
        "STREET": "GÜVENDİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "DOĞANHİSAR",
        "STREET": "HARMAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "DOĞANHİSAR",
        "STREET": "KARAAĞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "DOĞANHİSAR",
        "STREET": "KEMER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "DOĞANHİSAR",
        "STREET": "KONAKKALE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "DOĞANHİSAR",
        "STREET": "KOÇAŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "DOĞANHİSAR",
        "STREET": "KUZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "DOĞANHİSAR",
        "STREET": "PAZAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "DOĞANHİSAR",
        "STREET": "TEKKEKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "DOĞANHİSAR",
        "STREET": "UNCULAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "DOĞANHİSAR",
        "STREET": "YAZIR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "DOĞANHİSAR",
        "STREET": "YAZLICA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "DOĞANHİSAR",
        "STREET": "YENİCE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "DOĞANHİSAR",
        "STREET": "YEĞİN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "DOĞANHİSAR",
        "STREET": "ÇINAROBA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "DOĞANHİSAR",
        "STREET": "İLYASLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "DOĞANHİSAR",
        "STREET": "ŞIH MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "EMİRGAZİ",
        "STREET": "AKMESCİT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "EMİRGAZİ",
        "STREET": "BESCİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "EMİRGAZİ",
        "STREET": "CAMİKEBİR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "EMİRGAZİ",
        "STREET": "CUMHURİYET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "EMİRGAZİ",
        "STREET": "DEMİRCİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "EMİRGAZİ",
        "STREET": "EKİZLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "EMİRGAZİ",
        "STREET": "FATİH MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "EMİRGAZİ",
        "STREET": "GÖLÖREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "EMİRGAZİ",
        "STREET": "IŞIKLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "EMİRGAZİ",
        "STREET": "KALE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "EMİRGAZİ",
        "STREET": "KARAÖREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "EMİRGAZİ",
        "STREET": "MEŞELİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "EMİRGAZİ",
        "STREET": "TÜRBE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "EMİRGAZİ",
        "STREET": "YAMAÇ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "EMİRGAZİ",
        "STREET": "YENİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "EMİRGAZİ",
        "STREET": "ÖBEKTAŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "EREĞLİ",
        "STREET": "500 EVLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "EREĞLİ",
        "STREET": "ACIKUYU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "EREĞLİ",
        "STREET": "ACIPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "EREĞLİ",
        "STREET": "ADABAĞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "EREĞLİ",
        "STREET": "AKHÜYÜK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "EREĞLİ",
        "STREET": "ALHAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "EREĞLİ",
        "STREET": "ALPASLAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "EREĞLİ",
        "STREET": "ATAKENT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "EREĞLİ",
        "STREET": "AYDINLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "EREĞLİ",
        "STREET": "AZİZİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "EREĞLİ",
        "STREET": "AŞAĞIGÖNDELEN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "EREĞLİ",
        "STREET": "AŞIKLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "EREĞLİ",
        "STREET": "BAHÇELİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "EREĞLİ",
        "STREET": "BARBAROS MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "EREĞLİ",
        "STREET": "BATIALAGÖZLÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "EREĞLİ",
        "STREET": "BELCEAĞAÇ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "EREĞLİ",
        "STREET": "BELKAYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "EREĞLİ",
        "STREET": "BEYKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "EREĞLİ",
        "STREET": "BEYÖREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "EREĞLİ",
        "STREET": "BOYACIALİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "EREĞLİ",
        "STREET": "BULGURLUK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "EREĞLİ",
        "STREET": "BURHANİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "EREĞLİ",
        "STREET": "BÜYÜKDEDE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "EREĞLİ",
        "STREET": "CAHI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "EREĞLİ",
        "STREET": "CAMİKEBİR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "EREĞLİ",
        "STREET": "CİNLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "EREĞLİ",
        "STREET": "DALMAZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "EREĞLİ",
        "STREET": "DOGUALAGÖZLÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "EREĞLİ",
        "STREET": "EREĞLİ T TİPİ KAPALI CEZA İNFAZ KURUMU",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "EREĞLİ",
        "STREET": "EREĞLİ YÜKSEK GÜVENLİKLİ KAPALI CEZA İNFAZ KURUMU",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "EREĞLİ",
        "STREET": "ETİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "EREĞLİ",
        "STREET": "FATİH MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "EREĞLİ",
        "STREET": "GAYBİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "EREĞLİ",
        "STREET": "GÖKTÖME MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "EREĞLİ",
        "STREET": "GÖKÇEYAZI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "EREĞLİ",
        "STREET": "GÜLBAHÇE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "EREĞLİ",
        "STREET": "HACIMEMİŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "EREĞLİ",
        "STREET": "HACIMUSTAFA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "EREĞLİ",
        "STREET": "HACIMUTAHİR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "EREĞLİ",
        "STREET": "HAMİDİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "EREĞLİ",
        "STREET": "HIDIRLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "EREĞLİ",
        "STREET": "HORTU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "EREĞLİ",
        "STREET": "KAMIŞLIKUYU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "EREĞLİ",
        "STREET": "KARABURUN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "EREĞLİ",
        "STREET": "KARGACI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "EREĞLİ",
        "STREET": "KAZANCI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "EREĞLİ",
        "STREET": "KIZILGEDİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "EREĞLİ",
        "STREET": "KUSKUNCUK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "EREĞLİ",
        "STREET": "KUTÖREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "EREĞLİ",
        "STREET": "KUZUKUYU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "EREĞLİ",
        "STREET": "MEHMET AKİF MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "EREĞLİ",
        "STREET": "MELİCEK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "EREĞLİ",
        "STREET": "MİMAR SİNAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "EREĞLİ",
        "STREET": "NAMIK KEMAL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "EREĞLİ",
        "STREET": "ORHANGAZİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "EREĞLİ",
        "STREET": "ORHANİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "EREĞLİ",
        "STREET": "PINARÖZÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "EREĞLİ",
        "STREET": "PİRÖMER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "EREĞLİ",
        "STREET": "SARICA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "EREĞLİ",
        "STREET": "SARITOPALLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "EREĞLİ",
        "STREET": "SELÇUKLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "EREĞLİ",
        "STREET": "SERVİLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "EREĞLİ",
        "STREET": "SÜMER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "EREĞLİ",
        "STREET": "TALATPAŞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "EREĞLİ",
        "STREET": "TATLIKUYU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "EREĞLİ",
        "STREET": "TAŞAĞIL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "EREĞLİ",
        "STREET": "TAŞBUDAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "EREĞLİ",
        "STREET": "TOROS MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "EREĞLİ",
        "STREET": "TÜRBE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "EREĞLİ",
        "STREET": "TÜRKMEN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "EREĞLİ",
        "STREET": "ULUMEŞE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "EREĞLİ",
        "STREET": "YAZLIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "EREĞLİ",
        "STREET": "YELLİCE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "EREĞLİ",
        "STREET": "YENİBAĞLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "EREĞLİ",
        "STREET": "YENİKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "EREĞLİ",
        "STREET": "YILDIRIMBEYAZIT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "EREĞLİ",
        "STREET": "YILDIZLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "EREĞLİ",
        "STREET": "YUKARIGÖNDELEN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "EREĞLİ",
        "STREET": "YUNUSLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "EREĞLİ",
        "STREET": "ZENGEN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "EREĞLİ",
        "STREET": "ZİYA GÖKALP MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "EREĞLİ",
        "STREET": "ÇAKMAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "EREĞLİ",
        "STREET": "ÇAYHAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "EREĞLİ",
        "STREET": "ÇÖMLEKÇİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "EREĞLİ",
        "STREET": "ÇİLLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "EREĞLİ",
        "STREET": "ÇİMENCİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "EREĞLİ",
        "STREET": "ÖZGÜRLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "EREĞLİ",
        "STREET": "ÜÇGÖZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "EREĞLİ",
        "STREET": "ŞİNASİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "GÜNEYSINIR",
        "STREET": "AVCITEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "GÜNEYSINIR",
        "STREET": "AYDOĞMUŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "GÜNEYSINIR",
        "STREET": "AĞRAS MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "GÜNEYSINIR",
        "STREET": "BARDAS MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "GÜNEYSINIR",
        "STREET": "DURAYDA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "GÜNEYSINIR",
        "STREET": "EMİRHAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "GÜNEYSINIR",
        "STREET": "GÜNEYBAĞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "GÜNEYSINIR",
        "STREET": "GÜRAĞAÇ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "GÜNEYSINIR",
        "STREET": "HABİLLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "GÜNEYSINIR",
        "STREET": "KARASINIR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "GÜNEYSINIR",
        "STREET": "KAYAAĞZI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "GÜNEYSINIR",
        "STREET": "KIZILÖZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "GÜNEYSINIR",
        "STREET": "KURUKAVAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "GÜNEYSINIR",
        "STREET": "MEHMET ALİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "GÜNEYSINIR",
        "STREET": "MEVLANA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "GÜNEYSINIR",
        "STREET": "SARIHACI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "GÜNEYSINIR",
        "STREET": "ÖMEROĞLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "GÜNEYSINIR",
        "STREET": "ÖRENBOYALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "HADİM",
        "STREET": "ARMAĞANLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "HADİM",
        "STREET": "AŞAĞI HADİM MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "HADİM",
        "STREET": "AŞAĞI MERNEK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "HADİM",
        "STREET": "AŞAĞIEŞENLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "HADİM",
        "STREET": "BADEMLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "HADİM",
        "STREET": "BEYRELİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "HADİM",
        "STREET": "BOLAT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "HADİM",
        "STREET": "DEDEMLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "HADİM",
        "STREET": "DOLHANLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "HADİM",
        "STREET": "DÜLGERLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "HADİM",
        "STREET": "EĞİSTE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "HADİM",
        "STREET": "FAKILAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "HADİM",
        "STREET": "GAZİLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "HADİM",
        "STREET": "GEREZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "HADİM",
        "STREET": "GEZLEVİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "HADİM",
        "STREET": "GÖYNÜKKIŞLA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "HADİM",
        "STREET": "HOCALAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "HADİM",
        "STREET": "KALINAĞIL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "HADİM",
        "STREET": "KAPLANLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "HADİM",
        "STREET": "KÜPLÜCE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "HADİM",
        "STREET": "MERNEK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "HADİM",
        "STREET": "ODUNCU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "HADİM",
        "STREET": "SARNIÇ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "HADİM",
        "STREET": "SELAHATTİN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "HADİM",
        "STREET": "TAŞPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "HADİM",
        "STREET": "UMURLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "HADİM",
        "STREET": "YAĞCI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "HADİM",
        "STREET": "YELMEZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "HADİM",
        "STREET": "YENİKONAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "HADİM",
        "STREET": "YUKARIEŞENLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "HADİM",
        "STREET": "ÇİFTEPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "HADİM",
        "STREET": "İĞDEÖREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "HALKAPINAR",
        "STREET": "BAHÇELİEVLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "HALKAPINAR",
        "STREET": "BÜYÜKDOĞAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "HALKAPINAR",
        "STREET": "CUMHURİYET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "HALKAPINAR",
        "STREET": "DEDELİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "HALKAPINAR",
        "STREET": "DELİMAHMUTLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "HALKAPINAR",
        "STREET": "ESKİHİSAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "HALKAPINAR",
        "STREET": "KARAYUSUFLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "HALKAPINAR",
        "STREET": "KAYASARAY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "HALKAPINAR",
        "STREET": "KÖRLÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "HALKAPINAR",
        "STREET": "KÜSERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "HALKAPINAR",
        "STREET": "NERNEK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "HALKAPINAR",
        "STREET": "OSMANKÖSELİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "HALKAPINAR",
        "STREET": "SEYDİFAKILI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "HALKAPINAR",
        "STREET": "YASSIKAYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "HALKAPINAR",
        "STREET": "YEŞİLYURT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "HALKAPINAR",
        "STREET": "ÇAKILLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "HALKAPINAR",
        "STREET": "İVRİZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "HÜYÜK",
        "STREET": "AKDAĞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "HÜYÜK",
        "STREET": "BAHÇELİ EVLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "HÜYÜK",
        "STREET": "BAŞLAMIŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "HÜYÜK",
        "STREET": "BUDAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "HÜYÜK",
        "STREET": "BURUNSUZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "HÜYÜK",
        "STREET": "CUMHURİYET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "HÜYÜK",
        "STREET": "DEĞİRMENALTI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "HÜYÜK",
        "STREET": "GÖRÜNMEZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "HÜYÜK",
        "STREET": "GÖÇERİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "HÜYÜK",
        "STREET": "KIRELİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "HÜYÜK",
        "STREET": "KÖŞK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "HÜYÜK",
        "STREET": "MUTLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "HÜYÜK",
        "STREET": "PINARBAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "HÜYÜK",
        "STREET": "SELKİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "HÜYÜK",
        "STREET": "SULUDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "HÜYÜK",
        "STREET": "TOLCA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "HÜYÜK",
        "STREET": "ULUPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "HÜYÜK",
        "STREET": "YENİCE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "HÜYÜK",
        "STREET": "ÇAMLICA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "HÜYÜK",
        "STREET": "ÇAVUŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "HÜYÜK",
        "STREET": "ÇUKURKENT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "HÜYÜK",
        "STREET": "İLMEN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "HÜYÜK",
        "STREET": "İMRENLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "ILGIN",
        "STREET": "ARGITHANI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "ILGIN",
        "STREET": "AVDAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "ILGIN",
        "STREET": "AYVATDEDE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "ILGIN",
        "STREET": "AĞALAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "ILGIN",
        "STREET": "BALKI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "ILGIN",
        "STREET": "BARAKMUSLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "ILGIN",
        "STREET": "BEHLÜLBEY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "ILGIN",
        "STREET": "BELEKLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "ILGIN",
        "STREET": "BEYKONAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "ILGIN",
        "STREET": "BOĞAZKENT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "ILGIN",
        "STREET": "BULCUK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "ILGIN",
        "STREET": "BÜYÜKOBA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "ILGIN",
        "STREET": "CAMİİATİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "ILGIN",
        "STREET": "DEREKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "ILGIN",
        "STREET": "DIĞRAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "ILGIN",
        "STREET": "DÜĞER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "ILGIN",
        "STREET": "ELDEŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "ILGIN",
        "STREET": "ESENTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "ILGIN",
        "STREET": "FAHRETTİN ALTAY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "ILGIN",
        "STREET": "FATİH MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "ILGIN",
        "STREET": "GEDİKÖREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "ILGIN",
        "STREET": "GEÇİTKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "ILGIN",
        "STREET": "GÖKBUDAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "ILGIN",
        "STREET": "GÖKÇEYURT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "ILGIN",
        "STREET": "GÖLYAKA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "ILGIN",
        "STREET": "GÖSTERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "ILGIN",
        "STREET": "GÜNEYPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "ILGIN",
        "STREET": "HARMANYAZI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "ILGIN",
        "STREET": "ILICA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "ILGIN",
        "STREET": "KALEKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "ILGIN",
        "STREET": "KAPAKLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "ILGIN",
        "STREET": "KARAKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "ILGIN",
        "STREET": "MAHMUTHİSAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "ILGIN",
        "STREET": "MECİDİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "ILGIN",
        "STREET": "MİLLİ EGEMENLİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "ILGIN",
        "STREET": "MİSAFİRLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "ILGIN",
        "STREET": "OLUKPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "ILGIN",
        "STREET": "ORHANİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "ILGIN",
        "STREET": "ORMANÖZÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "ILGIN",
        "STREET": "SADIKKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "ILGIN",
        "STREET": "SAHİP ATA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "ILGIN",
        "STREET": "SEBİLLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "ILGIN",
        "STREET": "TEKELER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "ILGIN",
        "STREET": "UCARI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "ILGIN",
        "STREET": "YORAZLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "ILGIN",
        "STREET": "YUKARIÇİĞİL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "ILGIN",
        "STREET": "ZAFERİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "ILGIN",
        "STREET": "ÇATAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "ILGIN",
        "STREET": "ÇAVUŞÇUGÖL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "ILGIN",
        "STREET": "ÇOBANKAYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "ILGIN",
        "STREET": "ÇÖMLEKÇİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "ILGIN",
        "STREET": "ÇİĞİL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "ILGIN",
        "STREET": "İHSANİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "ILGIN",
        "STREET": "İSTASYON MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "ILGIN",
        "STREET": "ŞIHBEDRETTİN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "ILGIN",
        "STREET": "ŞIHCARULLAH MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "KADINHANI",
        "STREET": "AFŞARLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "KADINHANI",
        "STREET": "ALABAĞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "KADINHANI",
        "STREET": "ATLANTI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "KADINHANI",
        "STREET": "BAKIRPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "KADINHANI",
        "STREET": "BAYRAMLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "KADINHANI",
        "STREET": "BAŞKUYU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "KADINHANI",
        "STREET": "BEYKAVAĞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "KADINHANI",
        "STREET": "BULGURPINARI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "KADINHANI",
        "STREET": "DEMİROLUK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "KADINHANI",
        "STREET": "DOĞANLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "KADINHANI",
        "STREET": "GÜNGÖREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "KADINHANI",
        "STREET": "HACIMEHMETLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "KADINHANI",
        "STREET": "HACIOFLAZLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "KADINHANI",
        "STREET": "HACIPİRLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "KADINHANI",
        "STREET": "KABACALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "KADINHANI",
        "STREET": "KAMIŞLIÖZÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "KADINHANI",
        "STREET": "KARAHİSARLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "KADINHANI",
        "STREET": "KARAKAYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "KADINHANI",
        "STREET": "KARAKURTLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "KADINHANI",
        "STREET": "KARASEVİNÇ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "KADINHANI",
        "STREET": "KARAYÜRÜKLÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "KADINHANI",
        "STREET": "KAYABAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "KADINHANI",
        "STREET": "KIZILKUYU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "KADINHANI",
        "STREET": "KOLUKISA BERATLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "KADINHANI",
        "STREET": "KOLUKISA MESCİT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "KADINHANI",
        "STREET": "KONURÖREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "KADINHANI",
        "STREET": "KURTHASANLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "KADINHANI",
        "STREET": "KÖKEZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "KADINHANI",
        "STREET": "KÖYLÜTOLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "KADINHANI",
        "STREET": "KÜÇÜKKUYU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "KADINHANI",
        "STREET": "MAHMUDİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "KADINHANI",
        "STREET": "MEYDANLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "KADINHANI",
        "STREET": "OSMANCIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "KADINHANI",
        "STREET": "PINARBAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "KADINHANI",
        "STREET": "PUSAT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "KADINHANI",
        "STREET": "PİRALİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "KADINHANI",
        "STREET": "SARIKAYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "KADINHANI",
        "STREET": "SAÇIKARA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "KADINHANI",
        "STREET": "SÖĞÜTÖZÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "KADINHANI",
        "STREET": "TEPEBAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "KADINHANI",
        "STREET": "TOSUNOĞLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "KADINHANI",
        "STREET": "TURGUTLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "KADINHANI",
        "STREET": "YAYLAYAKA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "KADINHANI",
        "STREET": "YAĞLICA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "KADINHANI",
        "STREET": "YENİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "KADINHANI",
        "STREET": "ZAFER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "KADINHANI",
        "STREET": "ÇAVDAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "KADINHANI",
        "STREET": "ÇEŞMECİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "KADINHANI",
        "STREET": "ÇUBUK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "KADINHANI",
        "STREET": "ÖRNEK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "KADINHANI",
        "STREET": "ÜNVEREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "KADINHANI",
        "STREET": "İSTİKLAL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "KADINHANI",
        "STREET": "ŞAHÖREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "KARAPINAR",
        "STREET": "ADALET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "KARAPINAR",
        "STREET": "AKÇAYAZI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "KARAPINAR",
        "STREET": "AKÖREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "KARAPINAR",
        "STREET": "ALAADDİN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "KARAPINAR",
        "STREET": "APAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "KARAPINAR",
        "STREET": "CUMHURİYET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "KARAPINAR",
        "STREET": "FATİH MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "KARAPINAR",
        "STREET": "FEVZİ PAŞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "KARAPINAR",
        "STREET": "GAZİ OSMAN PAŞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "KARAPINAR",
        "STREET": "HACI ÖMERLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "KARAPINAR",
        "STREET": "HACI İSA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "KARAPINAR",
        "STREET": "HANKAPI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "KARAPINAR",
        "STREET": "HASANOBA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "KARAPINAR",
        "STREET": "HOTAMIŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "KARAPINAR",
        "STREET": "KALE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "KARAPINAR",
        "STREET": "KARAKIŞLA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "KARAPINAR",
        "STREET": "KAYACIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "KARAPINAR",
        "STREET": "KAYALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "KARAPINAR",
        "STREET": "KAZANHÜYÜĞÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "KARAPINAR",
        "STREET": "KESMEZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "KARAPINAR",
        "STREET": "KÜLLÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "KARAPINAR",
        "STREET": "KÜÇÜKAŞLAMA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "KARAPINAR",
        "STREET": "ORTAOBA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "KARAPINAR",
        "STREET": "OYMALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "KARAPINAR",
        "STREET": "PINARBAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "KARAPINAR",
        "STREET": "REŞADİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "KARAPINAR",
        "STREET": "SANDIKLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "KARAPINAR",
        "STREET": "SAZLIPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "KARAPINAR",
        "STREET": "SELİMİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "KARAPINAR",
        "STREET": "TÜRÜDİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "KARAPINAR",
        "STREET": "ULUS MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "KARAPINAR",
        "STREET": "YAĞMAPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "KARAPINAR",
        "STREET": "YENİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "KARAPINAR",
        "STREET": "YENİKUYU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "KARAPINAR",
        "STREET": "YEŞİLYURT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "KARAPINAR",
        "STREET": "YUNUS EMRE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "KARAPINAR",
        "STREET": "ZAFER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "KARAPINAR",
        "STREET": "ÇETMİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "KARAPINAR",
        "STREET": "ÇİĞİL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "KARAPINAR",
        "STREET": "İPEKÇİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "KARAPINAR",
        "STREET": "İSLİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "KARAPINAR",
        "STREET": "İSMET PAŞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "KARATAY",
        "STREET": "ACIDORT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "KARATAY",
        "STREET": "AKABE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "KARATAY",
        "STREET": "AKBAŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "KARATAY",
        "STREET": "AKÇEŞME MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "KARATAY",
        "STREET": "AKÖRENKIŞLA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "KARATAY",
        "STREET": "AKİFPAŞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "KARATAY",
        "STREET": "AZİZİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "KARATAY",
        "STREET": "AĞSAKLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "KARATAY",
        "STREET": "BAKIRTOLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "KARATAY",
        "STREET": "BAŞAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "KARATAY",
        "STREET": "BAŞGÖTÜREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "KARATAY",
        "STREET": "BEŞAĞIL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "KARATAY",
        "STREET": "BÜYÜK SİNAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "KARATAY",
        "STREET": "BÜYÜKBURNAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "KARATAY",
        "STREET": "DOĞUŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "KARATAY",
        "STREET": "DİVANLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "KARATAY",
        "STREET": "ELMACI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "KARATAY",
        "STREET": "EMİRGAZİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "KARATAY",
        "STREET": "ERENLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "KARATAY",
        "STREET": "ERLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "KARATAY",
        "STREET": "ESENTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "KARATAY",
        "STREET": "FETİH MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "KARATAY",
        "STREET": "FEVZİÇAKMAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "KARATAY",
        "STREET": "GAZİOSMANPAŞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "KARATAY",
        "STREET": "GÖÇÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "KARATAY",
        "STREET": "HACI HASAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "KARATAY",
        "STREET": "HACISADIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "KARATAY",
        "STREET": "HACIVEYİSZADE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "KARATAY",
        "STREET": "HACIYUSUFMESCİT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "KARATAY",
        "STREET": "HACIİBALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "KARATAY",
        "STREET": "HAMZAOĞLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "KARATAY",
        "STREET": "HASANDEDEMESCİT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "KARATAY",
        "STREET": "HAYIROĞLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "KARATAY",
        "STREET": "KALENDERHANE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "KARATAY",
        "STREET": "KARAASLANDEDE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "KARATAY",
        "STREET": "KARAASLANÜZÜMCÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "KARATAY",
        "STREET": "KARACİĞAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "KARATAY",
        "STREET": "KARADONA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "KARATAY",
        "STREET": "KARAKAYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "KARATAY",
        "STREET": "KARAKULAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "KARATAY",
        "STREET": "KATRANCI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "KARATAY",
        "STREET": "KAYACIKARAPLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "KARATAY",
        "STREET": "KERİM DEDE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "KARATAY",
        "STREET": "KEYKUBAT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "KARATAY",
        "STREET": "KEÇECİLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "KARATAY",
        "STREET": "KIZÖREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "KARATAY",
        "STREET": "KONYA E TİPİ KAPALI CEZA EVİ",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "KARATAY",
        "STREET": "KUMKÖPRÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "KARATAY",
        "STREET": "KÖPRÜBAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "KARATAY",
        "STREET": "KÖSEALİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "KARATAY",
        "STREET": "MENGENE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "KARATAY",
        "STREET": "NAKİPOĞLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "KARATAY",
        "STREET": "OBRUK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "KARATAY",
        "STREET": "ORHANGAZİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "KARATAY",
        "STREET": "ORTAKONAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "KARATAY",
        "STREET": "OVAKAVAĞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "KARATAY",
        "STREET": "SAKYATAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "KARATAY",
        "STREET": "SARAÇOĞLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "KARATAY",
        "STREET": "SARIYAKUP MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "KARATAY",
        "STREET": "SELİMSULTAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "KARATAY",
        "STREET": "SULTAN MESUD MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "KARATAY",
        "STREET": "SÜRÜÇ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "KARATAY",
        "STREET": "TATLICAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "KARATAY",
        "STREET": "ULUBATLIHASAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "KARATAY",
        "STREET": "YARMA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "KARATAY",
        "STREET": "YAVŞANKUYU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "KARATAY",
        "STREET": "YAĞLIBAYAT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "KARATAY",
        "STREET": "YEDİLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "KARATAY",
        "STREET": "YENİCE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "KARATAY",
        "STREET": "YENİKENT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "KARATAY",
        "STREET": "ZİNCİRLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "KARATAY",
        "STREET": "ÇATALHÜYÜK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "KARATAY",
        "STREET": "ÇATALTÖMEK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "KARATAY",
        "STREET": "ÇELEBİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "KARATAY",
        "STREET": "ÇENGİLTİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "KARATAY",
        "STREET": "ÇİMENLİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "KARATAY",
        "STREET": "İPEKLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "KARATAY",
        "STREET": "İSMİL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "KARATAY",
        "STREET": "İSTİKLAL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "KARATAY",
        "STREET": "ŞATIR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "KARATAY",
        "STREET": "ŞEMSİTEBRİZİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "KULU",
        "STREET": "ACIKUYU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "KULU",
        "STREET": "ALPARSLAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "KULU",
        "STREET": "ALTILAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "KULU",
        "STREET": "ARŞINCI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "KULU",
        "STREET": "BAHADIRLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "KULU",
        "STREET": "BEŞKARDEŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "KULU",
        "STREET": "BOZAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "KULU",
        "STREET": "BURUNAĞIL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "KULU",
        "STREET": "CAMİKEBİR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "KULU",
        "STREET": "CANIMANA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "KULU",
        "STREET": "CELEP MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "KULU",
        "STREET": "CUMHURİYET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "KULU",
        "STREET": "DEĞİRMENÖZÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "KULU",
        "STREET": "DOĞUTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "KULU",
        "STREET": "DİNEK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "KULU",
        "STREET": "DİPDEDE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "KULU",
        "STREET": "FATİH SULTAN MEHMET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "KULU",
        "STREET": "FEVZİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "KULU",
        "STREET": "GÖKLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "KULU",
        "STREET": "GÜZELYAYLA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "KULU",
        "STREET": "HİSARKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "KULU",
        "STREET": "KARACADAĞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "KULU",
        "STREET": "KARACADERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "KULU",
        "STREET": "KARAPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "KULU",
        "STREET": "KARŞIYAKA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "KULU",
        "STREET": "KEMALİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "KULU",
        "STREET": "KIRKKUYU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "KULU",
        "STREET": "KIRKPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "KULU",
        "STREET": "KOZANLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "KULU",
        "STREET": "KÖMÜŞİNİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "KULU",
        "STREET": "KÖSTENGİL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "KULU",
        "STREET": "KÖŞKER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "KULU",
        "STREET": "MANDIRA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "KULU",
        "STREET": "SARIYAYLA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "KULU",
        "STREET": "SEYİTAHMETLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "KULU",
        "STREET": "SOĞUKKUYU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "KULU",
        "STREET": "TAVLIÖREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "KULU",
        "STREET": "TUZYAKA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "KULU",
        "STREET": "YARAŞLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "KULU",
        "STREET": "YAZIÇAYIR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "KULU",
        "STREET": "YENİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "KULU",
        "STREET": "YEŞİLTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "KULU",
        "STREET": "YEŞİLYURT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "KULU",
        "STREET": "ZİNCİRLİKUYU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "KULU",
        "STREET": "ÖMERANLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "KULU",
        "STREET": "ŞEREFLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "MERAM",
        "STREET": "ALAKOVA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "MERAM",
        "STREET": "ALAVARDI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "MERAM",
        "STREET": "ALPASLAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "MERAM",
        "STREET": "ALİ ULVİ KURUCU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "MERAM",
        "STREET": "ATEŞBAZ VELİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "MERAM",
        "STREET": "AYANBEY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "MERAM",
        "STREET": "AYDOĞDU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "MERAM",
        "STREET": "AYMANAS MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "MERAM",
        "STREET": "AŞKAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "MERAM",
        "STREET": "BAHÇEŞEHİR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "MERAM",
        "STREET": "BAYAT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "MERAM",
        "STREET": "BORUKTOLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "MERAM",
        "STREET": "BOTSA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "MERAM",
        "STREET": "BOYALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "MERAM",
        "STREET": "DERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "MERAM",
        "STREET": "DURUNDAY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "MERAM",
        "STREET": "ERENKAYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "MERAM",
        "STREET": "EVLİYATEKKE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "MERAM",
        "STREET": "GÖDENE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "MERAM",
        "STREET": "HADİMİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "MERAM",
        "STREET": "HARMANCIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "MERAM",
        "STREET": "HASANŞEYH MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "MERAM",
        "STREET": "HATIP MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "MERAM",
        "STREET": "HATUNSARAY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "MERAM",
        "STREET": "HAVZAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "MERAM",
        "STREET": "KARAAĞAÇ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "MERAM",
        "STREET": "KARADİĞİN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "MERAM",
        "STREET": "KARADİĞİNDERESİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "MERAM",
        "STREET": "KARAHÜYÜK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "MERAM",
        "STREET": "KAVAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "MERAM",
        "STREET": "KAYADİBİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "MERAM",
        "STREET": "KAYALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "MERAM",
        "STREET": "KAYIHÜYÜK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "MERAM",
        "STREET": "KAŞINHANI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "MERAM",
        "STREET": "KIZILÖREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "MERAM",
        "STREET": "KONEVİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "MERAM",
        "STREET": "KOVANAĞZI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "MERAM",
        "STREET": "KOZAĞAÇ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "MERAM",
        "STREET": "KUMRALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "MERAM",
        "STREET": "KÖYCEĞİZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "MERAM",
        "STREET": "KİLİSTRA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "MERAM",
        "STREET": "LALEBAHÇE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "MERAM",
        "STREET": "MELİKŞAH MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "MERAM",
        "STREET": "MERKEZ-SAHİBATA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "MERAM",
        "STREET": "OSMAN GAZİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "MERAM",
        "STREET": "PAMUKCU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "MERAM",
        "STREET": "PİREBİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "MERAM",
        "STREET": "SADIKLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "MERAM",
        "STREET": "SARIKIZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "MERAM",
        "STREET": "SAĞLIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "MERAM",
        "STREET": "SEFAKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "MERAM",
        "STREET": "ULUIRMAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "MERAM",
        "STREET": "ULUĞBEY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "MERAM",
        "STREET": "UZUNHARMANLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "MERAM",
        "STREET": "YAKA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "MERAM",
        "STREET": "YATAĞAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "MERAM",
        "STREET": "YAYLAPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "MERAM",
        "STREET": "YENİBAHÇE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "MERAM",
        "STREET": "YENİŞEHİR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "MERAM",
        "STREET": "YEŞİLDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "MERAM",
        "STREET": "YEŞİLTEKKE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "MERAM",
        "STREET": "ÇARIKLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "MERAM",
        "STREET": "ÇAYBAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "MERAM",
        "STREET": "ÇAYIRBAĞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "MERAM",
        "STREET": "ÇOMAKLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "MERAM",
        "STREET": "ÇOMAKLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "MERAM",
        "STREET": "ÇUKURÇİMEN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "MERAM",
        "STREET": "İKİPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "MERAM",
        "STREET": "İNLİCE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "SARAYÖNÜ",
        "STREET": "BAHÇESARAY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "SARAYÖNÜ",
        "STREET": "BATI İSTASYON MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "SARAYÖNÜ",
        "STREET": "BAŞHÜYÜK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "SARAYÖNÜ",
        "STREET": "BOYALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "SARAYÖNÜ",
        "STREET": "BÜYÜKZENGİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "SARAYÖNÜ",
        "STREET": "DEĞİRMENLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "SARAYÖNÜ",
        "STREET": "DOĞU İSTASYON MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "SARAYÖNÜ",
        "STREET": "ERTUĞRUL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "SARAYÖNÜ",
        "STREET": "FATİH MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "SARAYÖNÜ",
        "STREET": "GÖZLÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "SARAYÖNÜ",
        "STREET": "HATİP MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "SARAYÖNÜ",
        "STREET": "KADIOĞLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "SARAYÖNÜ",
        "STREET": "KARABIYIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "SARAYÖNÜ",
        "STREET": "KARATEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "SARAYÖNÜ",
        "STREET": "KAYIÖREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "SARAYÖNÜ",
        "STREET": "KONAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "SARAYÖNÜ",
        "STREET": "KURŞUNLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "SARAYÖNÜ",
        "STREET": "KUYULUSEBİL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "SARAYÖNÜ",
        "STREET": "LADİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "SARAYÖNÜ",
        "STREET": "SARAÇ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "SARAYÖNÜ",
        "STREET": "SELİMİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "SARAYÖNÜ",
        "STREET": "YENİCEKAYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "SARAYÖNÜ",
        "STREET": "YUKARI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "SARAYÖNÜ",
        "STREET": "ÇEŞMELİSEBİL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "SARAYÖNÜ",
        "STREET": "ÖZKENT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "SARAYÖNÜ",
        "STREET": "İNLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "SELÇUKLU",
        "STREET": "AKADEMİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "SELÇUKLU",
        "STREET": "AKINCILAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "SELÇUKLU",
        "STREET": "AKPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "SELÇUKLU",
        "STREET": "AKŞEMSETTİN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "SELÇUKLU",
        "STREET": "ARDIÇLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "SELÇUKLU",
        "STREET": "AYDINLIKEVLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "SELÇUKLU",
        "STREET": "AŞAĞIPINARBAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "SELÇUKLU",
        "STREET": "BAĞRIKURT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "SELÇUKLU",
        "STREET": "BAŞARAKAVAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "SELÇUKLU",
        "STREET": "BEDİR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "SELÇUKLU",
        "STREET": "BEYHEKİM MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "SELÇUKLU",
        "STREET": "BOSNA HERSEK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "SELÇUKLU",
        "STREET": "BUHARA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "SELÇUKLU",
        "STREET": "BÜYÜKKAYACIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "SELÇUKLU",
        "STREET": "BİLECİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "SELÇUKLU",
        "STREET": "BİNKONUTLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "SELÇUKLU",
        "STREET": "BİÇER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "SELÇUKLU",
        "STREET": "CUMHURİYET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "SELÇUKLU",
        "STREET": "DAĞDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "SELÇUKLU",
        "STREET": "DOKUZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "SELÇUKLU",
        "STREET": "DUMLUPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "SELÇUKLU",
        "STREET": "ERENKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "SELÇUKLU",
        "STREET": "ESENLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "SELÇUKLU",
        "STREET": "EĞRİBAYAT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "SELÇUKLU",
        "STREET": "FATİH MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "SELÇUKLU",
        "STREET": "FERHUNİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "SELÇUKLU",
        "STREET": "FERİTPAŞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "SELÇUKLU",
        "STREET": "GÜVENÇ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "SELÇUKLU",
        "STREET": "HACIKAYMAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "SELÇUKLU",
        "STREET": "HANAYBAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "SELÇUKLU",
        "STREET": "HOCACİHAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "SELÇUKLU",
        "STREET": "HOROZLUHAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "SELÇUKLU",
        "STREET": "HÜSAMETTİN ÇELEBİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "SELÇUKLU",
        "STREET": "IŞIKLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "SELÇUKLU",
        "STREET": "KALEKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "SELÇUKLU",
        "STREET": "KARAÖMERLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "SELÇUKLU",
        "STREET": "KERVAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "SELÇUKLU",
        "STREET": "KILINÇARSLAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "SELÇUKLU",
        "STREET": "KINIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "SELÇUKLU",
        "STREET": "KIZILCAKUYU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "SELÇUKLU",
        "STREET": "KOSOVA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "SELÇUKLU",
        "STREET": "KÜÇÜKMUHSİNE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "SELÇUKLU",
        "STREET": "MALAZGİRT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "SELÇUKLU",
        "STREET": "MEHMET AKİF MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "SELÇUKLU",
        "STREET": "MEYDANKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "SELÇUKLU",
        "STREET": "MUSALLA BAĞLARI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "SELÇUKLU",
        "STREET": "NİŞANTAŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "SELÇUKLU",
        "STREET": "PARSANA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "SELÇUKLU",
        "STREET": "SAKARYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "SELÇUKLU",
        "STREET": "SALAHATTİN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "SELÇUKLU",
        "STREET": "SANCAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "SELÇUKLU",
        "STREET": "SARAYKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "SELÇUKLU",
        "STREET": "SARICALAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "SELÇUKLU",
        "STREET": "SELAHADDİNİ EYYUBİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "SELÇUKLU",
        "STREET": "SELÇUK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "SELÇUKLU",
        "STREET": "SIZMA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "SELÇUKLU",
        "STREET": "SULUTAS MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "SELÇUKLU",
        "STREET": "SİLLE AK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "SELÇUKLU",
        "STREET": "SİLLE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "SELÇUKLU",
        "STREET": "TATKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "SELÇUKLU",
        "STREET": "TEPEKENT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "SELÇUKLU",
        "STREET": "TÖMEK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "SELÇUKLU",
        "STREET": "ULUMUHSİNE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "SELÇUKLU",
        "STREET": "YAZIBELEN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "SELÇUKLU",
        "STREET": "YAZIR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "SELÇUKLU",
        "STREET": "YUKARIPINARBAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "SELÇUKLU",
        "STREET": "ÇALDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "SELÇUKLU",
        "STREET": "ÇALTI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "SELÇUKLU",
        "STREET": "ÇANDIR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "SELÇUKLU",
        "STREET": "İHSANİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "SELÇUKLU",
        "STREET": "ŞEKER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "SELÇUKLU",
        "STREET": "ŞEYH ŞAMİL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "SEYDİŞEHİR",
        "STREET": "AKTAŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "SEYDİŞEHİR",
        "STREET": "AKÇALAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "SEYDİŞEHİR",
        "STREET": "ALAYLAR BİR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "SEYDİŞEHİR",
        "STREET": "ALAYLAR İKİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "SEYDİŞEHİR",
        "STREET": "ANABAĞLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "SEYDİŞEHİR",
        "STREET": "AŞAĞIKARAÖREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "SEYDİŞEHİR",
        "STREET": "BAHÇELİEVLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "SEYDİŞEHİR",
        "STREET": "BAŞKARAÖREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "SEYDİŞEHİR",
        "STREET": "BOSTANDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "SEYDİŞEHİR",
        "STREET": "BOYALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "SEYDİŞEHİR",
        "STREET": "BÜKÇE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "SEYDİŞEHİR",
        "STREET": "DEĞİRMENCİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "SEYDİŞEHİR",
        "STREET": "DİKİLİTAŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "SEYDİŞEHİR",
        "STREET": "GEVREKLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "SEYDİŞEHİR",
        "STREET": "GÖKHÜYÜK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "SEYDİŞEHİR",
        "STREET": "GÖKÇEHÜYÜK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "SEYDİŞEHİR",
        "STREET": "GÖLYÜZÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "SEYDİŞEHİR",
        "STREET": "HACISEYİTALİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "SEYDİŞEHİR",
        "STREET": "IRMAKLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "SEYDİŞEHİR",
        "STREET": "KARABULAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "SEYDİŞEHİR",
        "STREET": "KARACAÖREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "SEYDİŞEHİR",
        "STREET": "KARAKAVAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "SEYDİŞEHİR",
        "STREET": "KAVAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "SEYDİŞEHİR",
        "STREET": "KESECİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "SEYDİŞEHİR",
        "STREET": "KETENLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "SEYDİŞEHİR",
        "STREET": "KIZILCA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "SEYDİŞEHİR",
        "STREET": "KIZILCALAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "SEYDİŞEHİR",
        "STREET": "KOZLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "SEYDİŞEHİR",
        "STREET": "KUMLUCA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "SEYDİŞEHİR",
        "STREET": "KURAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "SEYDİŞEHİR",
        "STREET": "MADENLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "SEYDİŞEHİR",
        "STREET": "MESUDİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "SEYDİŞEHİR",
        "STREET": "MURADİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "SEYDİŞEHİR",
        "STREET": "ORTAKARAÖREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "SEYDİŞEHİR",
        "STREET": "OĞLAKÇI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "SEYDİŞEHİR",
        "STREET": "PINARBAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "SEYDİŞEHİR",
        "STREET": "SAADETLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "SEYDİŞEHİR",
        "STREET": "SARAYCIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "SEYDİŞEHİR",
        "STREET": "SEYDİŞEHİR KAPALI CEZAEVİ",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "SEYDİŞEHİR",
        "STREET": "SEYİTHARUN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "SEYDİŞEHİR",
        "STREET": "SOFUHANE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "SEYDİŞEHİR",
        "STREET": "STAT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "SEYDİŞEHİR",
        "STREET": "SUSUZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "SEYDİŞEHİR",
        "STREET": "TARAŞCI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "SEYDİŞEHİR",
        "STREET": "TAŞAĞIL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "SEYDİŞEHİR",
        "STREET": "TEPECİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "SEYDİŞEHİR",
        "STREET": "TOL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "SEYDİŞEHİR",
        "STREET": "UFACIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "SEYDİŞEHİR",
        "STREET": "ULUKAPI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "SEYDİŞEHİR",
        "STREET": "YAYLACIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "SEYDİŞEHİR",
        "STREET": "YENİCAMİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "SEYDİŞEHİR",
        "STREET": "YENİCE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "SEYDİŞEHİR",
        "STREET": "ÇAT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "SEYDİŞEHİR",
        "STREET": "ÇATMAKAYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "SEYDİŞEHİR",
        "STREET": "ÇAVUŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "SEYDİŞEHİR",
        "STREET": "İNCESU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "TAŞKENT",
        "STREET": "AFŞAR TEPECİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "TAŞKENT",
        "STREET": "AFŞAR YUKARI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "TAŞKENT",
        "STREET": "BALCILAR ORTA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "TAŞKENT",
        "STREET": "BALCILAR VELİLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "TAŞKENT",
        "STREET": "BALCILAR YUKARI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "TAŞKENT",
        "STREET": "BEKTAŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "TAŞKENT",
        "STREET": "BOLAY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "TAŞKENT",
        "STREET": "HIRA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "TAŞKENT",
        "STREET": "ILICAPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "TAŞKENT",
        "STREET": "KECİMEN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "TAŞKENT",
        "STREET": "KONGUL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "TAŞKENT",
        "STREET": "SAZAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "TAŞKENT",
        "STREET": "ÇETMİ CÖMEŞPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "TAŞKENT",
        "STREET": "ÇETMİ YENİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "TAŞKENT",
        "STREET": "ŞIHLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "TUZLUKÇU",
        "STREET": "AŞAĞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "TUZLUKÇU",
        "STREET": "ERDOĞDU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "TUZLUKÇU",
        "STREET": "GÜRSU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "TUZLUKÇU",
        "STREET": "KONARI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "TUZLUKÇU",
        "STREET": "KORAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "TUZLUKÇU",
        "STREET": "KUNDULLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "TUZLUKÇU",
        "STREET": "KÖKLÜCE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "TUZLUKÇU",
        "STREET": "MEVLÜTLÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "TUZLUKÇU",
        "STREET": "ORTA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "TUZLUKÇU",
        "STREET": "PAZAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "TUZLUKÇU",
        "STREET": "PAZARKAYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "TUZLUKÇU",
        "STREET": "SUBATAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "TUZLUKÇU",
        "STREET": "TURSUNLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "TUZLUKÇU",
        "STREET": "YUKARI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "TUZLUKÇU",
        "STREET": "ÇÖĞÜRLÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "YALIHÜYÜK",
        "STREET": "ARASÖĞÜT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "YALIHÜYÜK",
        "STREET": "AŞAĞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "YALIHÜYÜK",
        "STREET": "SARAYKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "YALIHÜYÜK",
        "STREET": "YUKARI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "YUNAK",
        "STREET": "ALTINÖZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "YUNAK",
        "STREET": "AYRITEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "YUNAK",
        "STREET": "BEŞIŞIKLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "YUNAK",
        "STREET": "BÖĞRÜDELİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "YUNAK",
        "STREET": "CEBRAİL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "YUNAK",
        "STREET": "ESENTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "YUNAK",
        "STREET": "EĞRİKUYU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "YUNAK",
        "STREET": "EŞME MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "YUNAK",
        "STREET": "FATİH MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "YUNAK",
        "STREET": "HACIFAKILI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "YUNAK",
        "STREET": "HACIÖMEROĞLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "YUNAK",
        "STREET": "HARUNLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "YUNAK",
        "STREET": "HATIRLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "YUNAK",
        "STREET": "HURSUNLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "YUNAK",
        "STREET": "KARATAŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "YUNAK",
        "STREET": "KARAYAYLA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "YUNAK",
        "STREET": "KARGALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "YUNAK",
        "STREET": "KILLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "YUNAK",
        "STREET": "KOÇYAZI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "YUNAK",
        "STREET": "KURTUŞAĞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "YUNAK",
        "STREET": "KUYUBAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "YUNAK",
        "STREET": "KUZÖREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "YUNAK",
        "STREET": "MEŞELİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "YUNAK",
        "STREET": "ODABAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "YUNAK",
        "STREET": "ORTAKIŞLA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "YUNAK",
        "STREET": "PİRİBEYLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "YUNAK",
        "STREET": "SARAY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "YUNAK",
        "STREET": "SELÇUK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "YUNAK",
        "STREET": "SERTLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "YUNAK",
        "STREET": "SEVİNÇ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "YUNAK",
        "STREET": "SIRAM MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "YUNAK",
        "STREET": "SÜLÜKLÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "YUNAK",
        "STREET": "SİNANLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "YUNAK",
        "STREET": "TURGUT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "YUNAK",
        "STREET": "YAVAŞLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "YUNAK",
        "STREET": "YENİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "YUNAK",
        "STREET": "YEŞİLOBA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "YUNAK",
        "STREET": "YEŞİLYAYLA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "YUNAK",
        "STREET": "YIĞAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "YUNAK",
        "STREET": "ÇAYIRBAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "YUNAK",
        "STREET": "ÖZYAYLA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "YUNAK",
        "STREET": "İMAMOĞLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "ÇELTİK",
        "STREET": "ADAKASIM MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "ÇELTİK",
        "STREET": "BAHÇE SARAY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "ÇELTİK",
        "STREET": "BAHÇELİEVLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "ÇELTİK",
        "STREET": "DOĞANYURT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "ÇELTİK",
        "STREET": "FATİH MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "ÇELTİK",
        "STREET": "GÖKPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "ÇELTİK",
        "STREET": "HONAMLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "ÇELTİK",
        "STREET": "KARŞIYAKA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "ÇELTİK",
        "STREET": "KAŞÖREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "ÇELTİK",
        "STREET": "KÜÇÜKHASAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "ÇELTİK",
        "STREET": "SELÇUK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "ÇELTİK",
        "STREET": "TORUNLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "ÇELTİK",
        "STREET": "YUKARI ALİÇOMAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "ÇELTİK",
        "STREET": "İSHAKUŞAĞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "ÇUMRA",
        "STREET": "ABDİTOLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "ÇUMRA",
        "STREET": "ADAKALE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "ÇUMRA",
        "STREET": "AFŞAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "ÇUMRA",
        "STREET": "ALEMDAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "ÇUMRA",
        "STREET": "ALISSA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "ÇUMRA",
        "STREET": "ALİBEYHÜYÜĞÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "ÇUMRA",
        "STREET": "APA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "ÇUMRA",
        "STREET": "APASARAYCIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "ÇUMRA",
        "STREET": "ARIKÖREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "ÇUMRA",
        "STREET": "AVDUL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "ÇUMRA",
        "STREET": "BAKKALBAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "ÇUMRA",
        "STREET": "BALÇIKHİSAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "ÇUMRA",
        "STREET": "BARAJ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "ÇUMRA",
        "STREET": "BARDAKÇI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "ÇUMRA",
        "STREET": "BAĞLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "ÇUMRA",
        "STREET": "BEYLERCE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "ÇUMRA",
        "STREET": "BÜYÜKAŞLAMA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "ÇUMRA",
        "STREET": "CUMHURİYET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "ÇUMRA",
        "STREET": "DEDEMOĞLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "ÇUMRA",
        "STREET": "DOĞANLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "ÇUMRA",
        "STREET": "DİNEK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "ÇUMRA",
        "STREET": "DİNEKSARAY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "ÇUMRA",
        "STREET": "DİNLENDİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "ÇUMRA",
        "STREET": "ERENTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "ÇUMRA",
        "STREET": "FETHİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "ÇUMRA",
        "STREET": "GÖKHÜYÜK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "ÇUMRA",
        "STREET": "GÜVERCİNLİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "ÇUMRA",
        "STREET": "HÜRRİYET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "ÇUMRA",
        "STREET": "KARKIN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "ÇUMRA",
        "STREET": "KUZUCU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "ÇUMRA",
        "STREET": "KÜÇÜKKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "ÇUMRA",
        "STREET": "MEYDAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "ÇUMRA",
        "STREET": "OKÇU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "ÇUMRA",
        "STREET": "SEÇME MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "ÇUMRA",
        "STREET": "SÜRGÜÇ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "ÇUMRA",
        "STREET": "TAHTALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "ÇUMRA",
        "STREET": "TAŞAĞIL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "ÇUMRA",
        "STREET": "TÜRKMENCAMİLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "ÇUMRA",
        "STREET": "TÜRKMENKARAHÜYÜK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "ÇUMRA",
        "STREET": "UZUNKUYU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "ÇUMRA",
        "STREET": "YENİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "ÇUMRA",
        "STREET": "YENİDOĞAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "ÇUMRA",
        "STREET": "YENİSU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "ÇUMRA",
        "STREET": "YÖRÜKCAMİLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "ÇUMRA",
        "STREET": "ÇAYBAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "ÇUMRA",
        "STREET": "ÇUKURKAVAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "ÇUMRA",
        "STREET": "ÇİÇEKKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "ÇUMRA",
        "STREET": "ÜRÜNLÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "ÇUMRA",
        "STREET": "ÜÇHÜYÜK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "ÇUMRA",
        "STREET": "İNLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "ÇUMRA",
        "STREET": "İSTİKLAL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "ÇUMRA",
        "STREET": "İZZETBEY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KONYA",
        "DISTRICT": "ÇUMRA",
        "STREET": "İÇERİÇUMRA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "ALTINTAŞ",
        "STREET": "AKÇAKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "ALTINTAŞ",
        "STREET": "ALINCIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "ALTINTAŞ",
        "STREET": "ALTINTAŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "ALTINTAŞ",
        "STREET": "ALİBEY KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "ALTINTAŞ",
        "STREET": "AYDINLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "ALTINTAŞ",
        "STREET": "AYKIRIKÇI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "ALTINTAŞ",
        "STREET": "BEŞKARIŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "ALTINTAŞ",
        "STREET": "BOZBAY MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "ALTINTAŞ",
        "STREET": "CUMHURİYET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "ALTINTAŞ",
        "STREET": "ERENKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "ALTINTAŞ",
        "STREET": "EYMİR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "ALTINTAŞ",
        "STREET": "GECEK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "ALTINTAŞ",
        "STREET": "GENİŞLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "ALTINTAŞ",
        "STREET": "GÖKÇELER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "ALTINTAŞ",
        "STREET": "HÜRRİYET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "ALTINTAŞ",
        "STREET": "IŞIKLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "ALTINTAŞ",
        "STREET": "KARAAĞAÇ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "ALTINTAŞ",
        "STREET": "KUYUCAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "ALTINTAŞ",
        "STREET": "MECİDİYE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "ALTINTAŞ",
        "STREET": "MURATHANLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "ALTINTAŞ",
        "STREET": "OSMANİYE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "ALTINTAŞ",
        "STREET": "OYSU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "ALTINTAŞ",
        "STREET": "PINARCIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "ALTINTAŞ",
        "STREET": "PUSAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "ALTINTAŞ",
        "STREET": "SADIKKIRI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "ALTINTAŞ",
        "STREET": "SARAYCIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "ALTINTAŞ",
        "STREET": "SEVDİĞİN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "ALTINTAŞ",
        "STREET": "YALNIZSARAY KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "ALTINTAŞ",
        "STREET": "YAPILCAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "ALTINTAŞ",
        "STREET": "YAYLA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "ALTINTAŞ",
        "STREET": "YENİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "ALTINTAŞ",
        "STREET": "YENİKARAAĞAÇ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "ALTINTAŞ",
        "STREET": "YEŞİLYURT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "ALTINTAŞ",
        "STREET": "YOLÇATI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "ALTINTAŞ",
        "STREET": "ZAFERTEPEÇALKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "ALTINTAŞ",
        "STREET": "ÇAKIRSAZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "ALTINTAŞ",
        "STREET": "ÇAYIRBAŞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "ALTINTAŞ",
        "STREET": "ÜÇHÜYÜK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "ALTINTAŞ",
        "STREET": "İSTİKLAL MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "ALTINTAŞ",
        "STREET": "ŞANLIYURT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "ASLANAPA",
        "STREET": "ABAŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "ASLANAPA",
        "STREET": "ADA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "ASLANAPA",
        "STREET": "ASLIHANLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "ASLANAPA",
        "STREET": "BALLIBABA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "ASLANAPA",
        "STREET": "BAYAT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "ASLANAPA",
        "STREET": "BAYRAMŞAH KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "ASLANAPA",
        "STREET": "BEZİRGAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "ASLANAPA",
        "STREET": "CUMHURİYET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "ASLANAPA",
        "STREET": "DERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "ASLANAPA",
        "STREET": "EMREZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "ASLANAPA",
        "STREET": "ESEN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "ASLANAPA",
        "STREET": "GÖLBAŞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "ASLANAPA",
        "STREET": "GÖYNÜKÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "ASLANAPA",
        "STREET": "HAYDARLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "ASLANAPA",
        "STREET": "HÜRRİYET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "ASLANAPA",
        "STREET": "KARADİĞİN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "ASLANAPA",
        "STREET": "KUREYŞLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "ASLANAPA",
        "STREET": "MUSA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "ASLANAPA",
        "STREET": "NUHÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "ASLANAPA",
        "STREET": "ORTACA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "ASLANAPA",
        "STREET": "PAZARCIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "ASLANAPA",
        "STREET": "PINARBAŞI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "ASLANAPA",
        "STREET": "SARAY KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "ASLANAPA",
        "STREET": "TERZİLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "ASLANAPA",
        "STREET": "TOKUL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "ASLANAPA",
        "STREET": "YALNIZSARAY MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "ASLANAPA",
        "STREET": "YAĞCILAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "ASLANAPA",
        "STREET": "ÇAKMAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "ASLANAPA",
        "STREET": "ÇAL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "ASLANAPA",
        "STREET": "ÇAMDİBİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "ASLANAPA",
        "STREET": "ÇAMIRDIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "ASLANAPA",
        "STREET": "ÇUKURCA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "ASLANAPA",
        "STREET": "ÇÖMLEKÇİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "ASLANAPA",
        "STREET": "ÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "ASLANAPA",
        "STREET": "ŞENİŞLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "DOMANİÇ",
        "STREET": "AKSU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "DOMANİÇ",
        "STREET": "BERÇİN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "DOMANİÇ",
        "STREET": "BÖÇEN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "DOMANİÇ",
        "STREET": "BÜKERLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "DOMANİÇ",
        "STREET": "CUMHURİYET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "DOMANİÇ",
        "STREET": "DOMUR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "DOMANİÇ",
        "STREET": "DURABEY KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "DOMANİÇ",
        "STREET": "ERİKLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "DOMANİÇ",
        "STREET": "FINDICAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "DOMANİÇ",
        "STREET": "FIRANLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "DOMANİÇ",
        "STREET": "GÜNEY KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "DOMANİÇ",
        "STREET": "HASANTEPE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "DOMANİÇ",
        "STREET": "HİSAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "DOMANİÇ",
        "STREET": "KARAKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "DOMANİÇ",
        "STREET": "KARAMANLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "DOMANİÇ",
        "STREET": "KARŞIYAKA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "DOMANİÇ",
        "STREET": "KIRIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "DOMANİÇ",
        "STREET": "KOZCAĞIZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "DOMANİÇ",
        "STREET": "KOZLUCA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "DOMANİÇ",
        "STREET": "KÜÇÜKKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "DOMANİÇ",
        "STREET": "MUHACİRLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "DOMANİÇ",
        "STREET": "MURATLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "DOMANİÇ",
        "STREET": "ORTACA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "DOMANİÇ",
        "STREET": "SAFA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "DOMANİÇ",
        "STREET": "SARIOT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "DOMANİÇ",
        "STREET": "SARUHANLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "DOMANİÇ",
        "STREET": "SEYDİKUZU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "DOMANİÇ",
        "STREET": "SOĞUCAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "DOMANİÇ",
        "STREET": "TIRAZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "DOMANİÇ",
        "STREET": "YEŞİLKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "DOMANİÇ",
        "STREET": "ÇAKILKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "DOMANİÇ",
        "STREET": "ÇAMLICA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "DOMANİÇ",
        "STREET": "ÇARŞAMBA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "DOMANİÇ",
        "STREET": "ÇOKKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "DOMANİÇ",
        "STREET": "ÇUKURCA-CUMHURİYET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "DOMANİÇ",
        "STREET": "ÇUKURCA-ILICAKSU MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "DOMANİÇ",
        "STREET": "ÇUKURCA-KÜÇÜKLER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "DOMANİÇ",
        "STREET": "ÇUKURCA-OSMANLI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "DUMLUPINAR",
        "STREET": "ALLIÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "DUMLUPINAR",
        "STREET": "ARPALI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "DUMLUPINAR",
        "STREET": "AĞAÇ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "DUMLUPINAR",
        "STREET": "BÜYÜKASLIHANLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "DUMLUPINAR",
        "STREET": "CAFERGAZİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "DUMLUPINAR",
        "STREET": "CUMHURİYET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "DUMLUPINAR",
        "STREET": "EYİDEMİR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "DUMLUPINAR",
        "STREET": "HAMUR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "DUMLUPINAR",
        "STREET": "KIZILCA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "DUMLUPINAR",
        "STREET": "KÜÇÜKASLIHANLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "DUMLUPINAR",
        "STREET": "SELKİSARAY KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "DUMLUPINAR",
        "STREET": "TURGUT ÖZAL MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "DUMLUPINAR",
        "STREET": "YÜYLÜK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "DUMLUPINAR",
        "STREET": "ZAFER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "EMET",
        "STREET": "ABAŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "EMET",
        "STREET": "AKPINAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "EMET",
        "STREET": "AYDINCIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "EMET",
        "STREET": "BAHATLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "EMET",
        "STREET": "CUMHURİYET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "EMET",
        "STREET": "DERE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "EMET",
        "STREET": "DERELİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "EMET",
        "STREET": "DOĞANLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "EMET",
        "STREET": "DÜŞECEK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "EMET",
        "STREET": "ESATLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "EMET",
        "STREET": "ESELER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "EMET",
        "STREET": "ESENTEPE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "EMET",
        "STREET": "EĞRİGÖZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "EMET",
        "STREET": "GÖNCEK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "EMET",
        "STREET": "GÜNLÜCE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "EMET",
        "STREET": "GÜRPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "EMET",
        "STREET": "HAMAM MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "EMET",
        "STREET": "IŞIKLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "EMET",
        "STREET": "KABAKLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "EMET",
        "STREET": "KAPAKLICA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "EMET",
        "STREET": "KATRANDAĞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "EMET",
        "STREET": "KAYI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "EMET",
        "STREET": "KAYNARCA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "EMET",
        "STREET": "KIRGIL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "EMET",
        "STREET": "KONUŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "EMET",
        "STREET": "KÖPRÜCEK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "EMET",
        "STREET": "KÜRECİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "EMET",
        "STREET": "MUSALAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "EMET",
        "STREET": "SAMRIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "EMET",
        "STREET": "SARIAYAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "EMET",
        "STREET": "SUBAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "EMET",
        "STREET": "UMUTLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "EMET",
        "STREET": "UZUNÇAM KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "EMET",
        "STREET": "YARIŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "EMET",
        "STREET": "YAYLAYOLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "EMET",
        "STREET": "YAĞCIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "EMET",
        "STREET": "YENİCE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "EMET",
        "STREET": "YENİKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "EMET",
        "STREET": "ÇERTE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "EMET",
        "STREET": "ÖMERFAKI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "EMET",
        "STREET": "ÖRENCİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "EMET",
        "STREET": "İKİBAŞLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "EMET",
        "STREET": "İĞDEKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "GEDİZ",
        "STREET": "ABİDE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "GEDİZ",
        "STREET": "AKKAYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "GEDİZ",
        "STREET": "AKSAKLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "GEDİZ",
        "STREET": "ALTINKENT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "GEDİZ",
        "STREET": "ALİAĞA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "GEDİZ",
        "STREET": "ALİKAHYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "GEDİZ",
        "STREET": "ARICA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "GEDİZ",
        "STREET": "AŞIKPAŞA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "GEDİZ",
        "STREET": "CEBRAİL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "GEDİZ",
        "STREET": "DAYINLAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "GEDİZ",
        "STREET": "DEDEKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "GEDİZ",
        "STREET": "DEREKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "GEDİZ",
        "STREET": "DEĞİRMENKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "GEDİZ",
        "STREET": "DÖRTDEĞİRMEN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "GEDİZ",
        "STREET": "ECE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "GEDİZ",
        "STREET": "ERDOĞMUŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "GEDİZ",
        "STREET": "ERGENEKON MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "GEDİZ",
        "STREET": "ESKİGEDİZ-AKÇAALAN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "GEDİZ",
        "STREET": "ESKİGEDİZ-CAMİKEBİR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "GEDİZ",
        "STREET": "ESKİGEDİZ-HİSAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "GEDİZ",
        "STREET": "ESKİGEDİZ-KURTULUŞ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "GEDİZ",
        "STREET": "ESKİGEDİZ-PARK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "GEDİZ",
        "STREET": "ESKİGEDİZ-SABIRGAZİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "GEDİZ",
        "STREET": "ESKİGEDİZ-İSMETPAŞA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "GEDİZ",
        "STREET": "FIRDAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "GEDİZ",
        "STREET": "GAZİKEMAL MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "GEDİZ",
        "STREET": "GÖKLER-GÜNEY MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "GEDİZ",
        "STREET": "GÖKLER-KUZEY MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "GEDİZ",
        "STREET": "GÖYNÜK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "GEDİZ",
        "STREET": "GÖYNÜKÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "GEDİZ",
        "STREET": "GÜMELE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "GEDİZ",
        "STREET": "GÜMÜŞLÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "GEDİZ",
        "STREET": "GÜRLEK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "GEDİZ",
        "STREET": "GÜZÜNGÜLÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "GEDİZ",
        "STREET": "HACIBABA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "GEDİZ",
        "STREET": "ILICASU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "GEDİZ",
        "STREET": "IŞIKLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "GEDİZ",
        "STREET": "KARAAĞAÇ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "GEDİZ",
        "STREET": "KARABACAKLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "GEDİZ",
        "STREET": "KAYACIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "GEDİZ",
        "STREET": "KAYAKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "GEDİZ",
        "STREET": "KIRAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "GEDİZ",
        "STREET": "KIZILTEPE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "GEDİZ",
        "STREET": "KUMTAŞ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "GEDİZ",
        "STREET": "KURTÇAM KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "GEDİZ",
        "STREET": "KÖPENEZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "GEDİZ",
        "STREET": "MUHİPLER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "GEDİZ",
        "STREET": "PINARBAŞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "GEDİZ",
        "STREET": "PULAT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "GEDİZ",
        "STREET": "SALUR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "GEDİZ",
        "STREET": "SAMRALAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "GEDİZ",
        "STREET": "SANDIKLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "GEDİZ",
        "STREET": "SARUHANLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "GEDİZ",
        "STREET": "SAYGILAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "GEDİZ",
        "STREET": "SAZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "GEDİZ",
        "STREET": "SAZAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "GEDİZ",
        "STREET": "SOĞUKSU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "GEDİZ",
        "STREET": "SUMAKLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "GEDİZ",
        "STREET": "TEPEPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "GEDİZ",
        "STREET": "ULUOYMAK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "GEDİZ",
        "STREET": "UMURBEY MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "GEDİZ",
        "STREET": "UĞURLUCA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "GEDİZ",
        "STREET": "VAKIF KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "GEDİZ",
        "STREET": "YAYLAKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "GEDİZ",
        "STREET": "YAĞMURLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "GEDİZ",
        "STREET": "YELKİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "GEDİZ",
        "STREET": "YENİGÜNEY KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "GEDİZ",
        "STREET": "YENİKENT-GÖLCÜK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "GEDİZ",
        "STREET": "YENİKENT-HAMZABEY MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "GEDİZ",
        "STREET": "YENİKENT-YENİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "GEDİZ",
        "STREET": "YENİKENT-İBRAHİMKAHYA  MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "GEDİZ",
        "STREET": "YEĞİNLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "GEDİZ",
        "STREET": "YEŞİLOVA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "GEDİZ",
        "STREET": "YEŞİLÇAY KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "GEDİZ",
        "STREET": "YUKARISUSUZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "GEDİZ",
        "STREET": "YUMRUTAŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "GEDİZ",
        "STREET": "YUNUSLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "GEDİZ",
        "STREET": "ÇELTİKÇİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "GEDİZ",
        "STREET": "ÇOMAKLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "GEDİZ",
        "STREET": "ÇUKURÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "GEDİZ",
        "STREET": "ÖZYURT MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "GEDİZ",
        "STREET": "ÜZÜMLÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "HİSARCIK",
        "STREET": "82.YIL  MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "HİSARCIK",
        "STREET": "ALINÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "HİSARCIK",
        "STREET": "AŞAĞIYONCAAĞAÇ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "HİSARCIK",
        "STREET": "BEYKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "HİSARCIK",
        "STREET": "CUMHURİYET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "HİSARCIK",
        "STREET": "DEREKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "HİSARCIK",
        "STREET": "GÜLDÜREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "HİSARCIK",
        "STREET": "HALİFELER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "HİSARCIK",
        "STREET": "HAMAMKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "HİSARCIK",
        "STREET": "HASANLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "HİSARCIK",
        "STREET": "HOCALAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "HİSARCIK",
        "STREET": "KARAAĞIL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "HİSARCIK",
        "STREET": "KARBASAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "HİSARCIK",
        "STREET": "KARŞIYAKA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "HİSARCIK",
        "STREET": "KIZILÇUKUR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "HİSARCIK",
        "STREET": "KURTDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "HİSARCIK",
        "STREET": "KUTLUBEYLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "HİSARCIK",
        "STREET": "KUTLUHALLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "HİSARCIK",
        "STREET": "SAKLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "HİSARCIK",
        "STREET": "SEFAKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "HİSARCIK",
        "STREET": "TOKAT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "HİSARCIK",
        "STREET": "ULAŞLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "HİSARCIK",
        "STREET": "YENİDOĞAN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "HİSARCIK",
        "STREET": "YENİPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "HİSARCIK",
        "STREET": "YEŞİLHİSAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "HİSARCIK",
        "STREET": "YUKARIYONCAAĞAÇ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "HİSARCIK",
        "STREET": "ÇATAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "HİSARCIK",
        "STREET": "ÖRENKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "HİSARCIK",
        "STREET": "ŞEHİTLER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "HİSARCIK",
        "STREET": "ŞEYHLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "HİSARCIK",
        "STREET": "ŞEYHÇAKIR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "MERKEZ",
        "STREET": "100. YIL MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "MERKEZ",
        "STREET": "30 AĞUSTOS MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "MERKEZ",
        "STREET": "75. YIL MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "MERKEZ",
        "STREET": "AHMETOLUĞU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "MERKEZ",
        "STREET": "AHİLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "MERKEZ",
        "STREET": "AKKENT MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "MERKEZ",
        "STREET": "AKOLUK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "MERKEZ",
        "STREET": "AKPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "MERKEZ",
        "STREET": "AKÇAMESCİT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "MERKEZ",
        "STREET": "ALAYUNT MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "MERKEZ",
        "STREET": "ALOĞLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "MERKEZ",
        "STREET": "ALİPAŞA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "MERKEZ",
        "STREET": "ANASULTAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "MERKEZ",
        "STREET": "ANDIZ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "MERKEZ",
        "STREET": "ARSLANLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "MERKEZ",
        "STREET": "AYDOĞDU MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "MERKEZ",
        "STREET": "AYVALI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "MERKEZ",
        "STREET": "AĞAÇKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "MERKEZ",
        "STREET": "AĞAÇKÖY MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "MERKEZ",
        "STREET": "AĞIZÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "MERKEZ",
        "STREET": "BAHÇELİEVLER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "MERKEZ",
        "STREET": "BALIKLI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "MERKEZ",
        "STREET": "BAYAT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "MERKEZ",
        "STREET": "BAYRAMŞAH KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "MERKEZ",
        "STREET": "BAŞÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "MERKEZ",
        "STREET": "BELKAVAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "MERKEZ",
        "STREET": "BÖLÜCEK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "MERKEZ",
        "STREET": "BÖREKÇİLER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "MERKEZ",
        "STREET": "BÜYÜKSAKA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "MERKEZ",
        "STREET": "CEDİT MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "MERKEZ",
        "STREET": "CEMALETTİN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "MERKEZ",
        "STREET": "CUMHURİYET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "MERKEZ",
        "STREET": "CİVLİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "MERKEZ",
        "STREET": "DAMLALIKARAAĞAÇ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "MERKEZ",
        "STREET": "DARICA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "MERKEZ",
        "STREET": "DEDİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "MERKEZ",
        "STREET": "DEMİRCİÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "MERKEZ",
        "STREET": "DEMİRÖZÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "MERKEZ",
        "STREET": "DEREKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "MERKEZ",
        "STREET": "DOĞA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "MERKEZ",
        "STREET": "DOĞALAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "MERKEZ",
        "STREET": "DOĞARSLAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "MERKEZ",
        "STREET": "DOĞLUŞAH KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "MERKEZ",
        "STREET": "DUMLUPINAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "MERKEZ",
        "STREET": "ELMACIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "MERKEZ",
        "STREET": "ELMALI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "MERKEZ",
        "STREET": "ENNE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "MERKEZ",
        "STREET": "ESKİYÜREĞİL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "MERKEZ",
        "STREET": "EVLİYA ÇELEBİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "MERKEZ",
        "STREET": "EYNEGAZİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "MERKEZ",
        "STREET": "FATİH MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "MERKEZ",
        "STREET": "FINDIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "MERKEZ",
        "STREET": "FUATPAŞA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "MERKEZ",
        "STREET": "FİNCANBURNU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "MERKEZ",
        "STREET": "G. KEMAL MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "MERKEZ",
        "STREET": "GAYBİEFENDİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "MERKEZ",
        "STREET": "GEDİKOĞLUÇİFTLİĞİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "MERKEZ",
        "STREET": "GELİNKAYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "MERKEZ",
        "STREET": "GEVEN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "MERKEZ",
        "STREET": "GÖYNÜKÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "MERKEZ",
        "STREET": "GÖÇERİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "MERKEZ",
        "STREET": "GÜLLÜDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "MERKEZ",
        "STREET": "GÜLTEPE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "MERKEZ",
        "STREET": "GÜMÜŞKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "MERKEZ",
        "STREET": "GÜVEM KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "MERKEZ",
        "STREET": "GÜVEÇÇİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "MERKEZ",
        "STREET": "HACIAZİZLER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "MERKEZ",
        "STREET": "HAMİDİYE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "MERKEZ",
        "STREET": "HAMİDİYEKIZILCAÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "MERKEZ",
        "STREET": "HAYMANA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "MERKEZ",
        "STREET": "HİLALKENT MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "MERKEZ",
        "STREET": "IŞIKKARA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "MERKEZ",
        "STREET": "KARAAĞAÇ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "MERKEZ",
        "STREET": "KARACAÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "MERKEZ",
        "STREET": "KARAÖZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "MERKEZ",
        "STREET": "KARSAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "MERKEZ",
        "STREET": "KAYNARCA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "MERKEZ",
        "STREET": "KEPEZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "MERKEZ",
        "STREET": "KINIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "MERKEZ",
        "STREET": "KIRANŞEYH KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "MERKEZ",
        "STREET": "KIRGILLI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "MERKEZ",
        "STREET": "KIZIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "MERKEZ",
        "STREET": "KIZILCAKAYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "MERKEZ",
        "STREET": "KIZILCAÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "MERKEZ",
        "STREET": "KOZLUCA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "MERKEZ",
        "STREET": "KOÇAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "MERKEZ",
        "STREET": "KUMARI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "MERKEZ",
        "STREET": "KUMLUYURT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "MERKEZ",
        "STREET": "KUYUSİNİR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "MERKEZ",
        "STREET": "KÖPRÜÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "MERKEZ",
        "STREET": "KÖRPE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "MERKEZ",
        "STREET": "KÖRS KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "MERKEZ",
        "STREET": "KÜKÜRT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "MERKEZ",
        "STREET": "KÜTAHYA E TİPİ KAPALI VE YARI AÇIK CEZAEVİ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "MERKEZ",
        "STREET": "KİRAZLIYAYLASI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "MERKEZ",
        "STREET": "KİRAZPINAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "MERKEZ",
        "STREET": "KİREÇ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "MERKEZ",
        "STREET": "LALA HÜSEYİN PAŞA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "MERKEZ",
        "STREET": "LÜTFİYE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "MERKEZ",
        "STREET": "MAHMUDİYE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "MERKEZ",
        "STREET": "MAKASALANI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "MERKEZ",
        "STREET": "MALTEPE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "MERKEZ",
        "STREET": "MARUF MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "MERKEZ",
        "STREET": "MECİDİYE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "MERKEZ",
        "STREET": "MEHMET AKİF ERSOY MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "MERKEZ",
        "STREET": "MEYDAN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "MERKEZ",
        "STREET": "MUHATBOĞAZI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "MERKEZ",
        "STREET": "MUSTAFALAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "MERKEZ",
        "STREET": "MÜDERRİS MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "MERKEZ",
        "STREET": "NUSRET KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "MERKEZ",
        "STREET": "OK MEYDANI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "MERKEZ",
        "STREET": "OKÇU MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "MERKEZ",
        "STREET": "ORTACA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "MERKEZ",
        "STREET": "OSMANGAZİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "MERKEZ",
        "STREET": "OVACIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "MERKEZ",
        "STREET": "PARMAKÖREN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "MERKEZ",
        "STREET": "PAŞAM SULTAN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "MERKEZ",
        "STREET": "PERLİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "MERKEZ",
        "STREET": "PULLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "MERKEZ",
        "STREET": "PİRLER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "MERKEZ",
        "STREET": "SABUNCUPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "MERKEZ",
        "STREET": "SAKAÇİFTLİĞİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "MERKEZ",
        "STREET": "SARAY MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "MERKEZ",
        "STREET": "SAĞIRLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "MERKEZ",
        "STREET": "SEKİÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "MERKEZ",
        "STREET": "SERVİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "MERKEZ",
        "STREET": "SEYDİKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "MERKEZ",
        "STREET": "SEYİTÖMER-BOZCAHÖYÜK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "MERKEZ",
        "STREET": "SEYİTÖMER-GÜLTEPE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "MERKEZ",
        "STREET": "SEYİTÖMER-SEYİTLER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "MERKEZ",
        "STREET": "SIRÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "MERKEZ",
        "STREET": "SOBRAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "MERKEZ",
        "STREET": "SOFU MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "MERKEZ",
        "STREET": "SOFÇA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "MERKEZ",
        "STREET": "SOĞUKÇEŞME KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "MERKEZ",
        "STREET": "SULTANBAĞI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "MERKEZ",
        "STREET": "SULU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "MERKEZ",
        "STREET": "SÖKMEN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "MERKEZ",
        "STREET": "SÖĞÜT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "MERKEZ",
        "STREET": "SÖĞÜTYAYLASI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "MERKEZ",
        "STREET": "SÜNNETYENİCE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "MERKEZ",
        "STREET": "SİNER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "MERKEZ",
        "STREET": "TEPEKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "MERKEZ",
        "STREET": "TEŞVİKİYE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "MERKEZ",
        "STREET": "TURGUTLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "MERKEZ",
        "STREET": "ULU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "MERKEZ",
        "STREET": "VEFA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "MERKEZ",
        "STREET": "YAKACA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "MERKEZ",
        "STREET": "YAYLABABA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "MERKEZ",
        "STREET": "YAZLICA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "MERKEZ",
        "STREET": "YENİ BOSNA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "MERKEZ",
        "STREET": "YENİCE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "MERKEZ",
        "STREET": "YENİDOĞAN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "MERKEZ",
        "STREET": "YENİKARACAÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "MERKEZ",
        "STREET": "YENİKIZILCAÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "MERKEZ",
        "STREET": "YEŞİLBAYIR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "MERKEZ",
        "STREET": "YILDIRIM BEYAZIT MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "MERKEZ",
        "STREET": "YONCALI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "MERKEZ",
        "STREET": "YUMAKLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "MERKEZ",
        "STREET": "YUNUSEMRE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "MERKEZ",
        "STREET": "ZAFERTEPE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "MERKEZ",
        "STREET": "ZİRAAT MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "MERKEZ",
        "STREET": "ZİĞRA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇALCA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇAVUŞÇİFTLİĞİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇAYCA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇOBANLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇUBUKİÇİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇÖĞÜRLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇİFTEOLUKLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "MERKEZ",
        "STREET": "ÜRÜNLÜÇİFTLİĞİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "MERKEZ",
        "STREET": "İHSANİYE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "MERKEZ",
        "STREET": "İKİZÜYÜK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "MERKEZ",
        "STREET": "İNCİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "MERKEZ",
        "STREET": "İNKÖY MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "MERKEZ",
        "STREET": "İNLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "MERKEZ",
        "STREET": "İSHAK SEYDİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "MERKEZ",
        "STREET": "İSHAKÇILAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "MERKEZ",
        "STREET": "İSTİKLAL MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "MERKEZ",
        "STREET": "ŞAİR ŞEYHİ DUMLUPINAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "PAZARLAR",
        "STREET": "ADNANMENDERES MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "PAZARLAR",
        "STREET": "AKŞİNİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "PAZARLAR",
        "STREET": "ATATÜRK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "PAZARLAR",
        "STREET": "AYVALI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "PAZARLAR",
        "STREET": "FATİH MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "PAZARLAR",
        "STREET": "HOCAAHMETYESEVİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "PAZARLAR",
        "STREET": "ORHANLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "PAZARLAR",
        "STREET": "SARAYKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "PAZARLAR",
        "STREET": "SOFULAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "PAZARLAR",
        "STREET": "TEPEKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "PAZARLAR",
        "STREET": "TURGUT ÖZAL MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "PAZARLAR",
        "STREET": "YAKUPLAR ATATÜRK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "PAZARLAR",
        "STREET": "YAKUPLAR FATİH MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "PAZARLAR",
        "STREET": "YENİCE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "PAZARLAR",
        "STREET": "ÖREY KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "SİMAV",
        "STREET": "101 EVLER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "SİMAV",
        "STREET": "4 EYLÜL MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "SİMAV",
        "STREET": "AHLATLIÇEŞME KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "SİMAV",
        "STREET": "AHMETLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "SİMAV",
        "STREET": "AKDAĞ-CEVİZLİK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "SİMAV",
        "STREET": "AKDAĞ-KINIK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "SİMAV",
        "STREET": "AKDAĞ-SAVCILAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "SİMAV",
        "STREET": "AKPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "SİMAV",
        "STREET": "AKSAZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "SİMAV",
        "STREET": "AŞAĞIDOLAYLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "SİMAV",
        "STREET": "BADEMLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "SİMAV",
        "STREET": "BAHTILLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "SİMAV",
        "STREET": "BAŞKONAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "SİMAV",
        "STREET": "BECİLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "SİMAV",
        "STREET": "BEDİRLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "SİMAV",
        "STREET": "BEYCE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "SİMAV",
        "STREET": "BOĞAZKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "SİMAV",
        "STREET": "CUMA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "SİMAV",
        "STREET": "DEMİRCİ-DEMİRCİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "SİMAV",
        "STREET": "DEMİRCİ-KARACALAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "SİMAV",
        "STREET": "DEMİRCİ-ÇAYSİMAV MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "SİMAV",
        "STREET": "DEMİRCİ-ÖREYLER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "SİMAV",
        "STREET": "DEREKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "SİMAV",
        "STREET": "DEREYÜZÜDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "SİMAV",
        "STREET": "DEĞİRMENCİLER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "SİMAV",
        "STREET": "EFİR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "SİMAV",
        "STREET": "ESENBAĞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "SİMAV",
        "STREET": "ESENEVLER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "SİMAV",
        "STREET": "EVCİLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "SİMAV",
        "STREET": "EĞDEMİR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "SİMAV",
        "STREET": "EĞİRLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "SİMAV",
        "STREET": "FATİH MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "SİMAV",
        "STREET": "GILMANLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "SİMAV",
        "STREET": "GÖKÇELER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "SİMAV",
        "STREET": "GÖLKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "SİMAV",
        "STREET": "GÜMÜŞSU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "SİMAV",
        "STREET": "GÜNEY-CUMA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "SİMAV",
        "STREET": "GÜNEY-FATİH MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "SİMAV",
        "STREET": "GÜZELYURT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "SİMAV",
        "STREET": "HACIAHMETOĞLU MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "SİMAV",
        "STREET": "HACIHÜSEYİNEFENDİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "SİMAV",
        "STREET": "HASANÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "SİMAV",
        "STREET": "HIDIRDİVANI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "SİMAV",
        "STREET": "HİSARARDI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "SİMAV",
        "STREET": "HİSARBEY KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "SİMAV",
        "STREET": "IHLAMUR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "SİMAV",
        "STREET": "KALKAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "SİMAV",
        "STREET": "KAPIKAYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "SİMAV",
        "STREET": "KARACAHİSAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "SİMAV",
        "STREET": "KARACAÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "SİMAV",
        "STREET": "KARAKOCA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "SİMAV",
        "STREET": "KARAKÜR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "SİMAV",
        "STREET": "KARAPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "SİMAV",
        "STREET": "KARŞIYAKA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "SİMAV",
        "STREET": "KAYAIŞIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "SİMAV",
        "STREET": "KAYALIDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "SİMAV",
        "STREET": "KEKLİKTEPE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "SİMAV",
        "STREET": "KESTEL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "SİMAV",
        "STREET": "KIZILCIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "SİMAV",
        "STREET": "KOYUNOBA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "SİMAV",
        "STREET": "KURDUMAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "SİMAV",
        "STREET": "KUSUMLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "SİMAV",
        "STREET": "KUŞU-DERE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "SİMAV",
        "STREET": "KUŞU-KADILAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "SİMAV",
        "STREET": "KUŞU-KAYA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "SİMAV",
        "STREET": "KUŞU-YENİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "SİMAV",
        "STREET": "KÖSELER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "SİMAV",
        "STREET": "KÜLCÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "SİMAV",
        "STREET": "KÜPLÜCE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "SİMAV",
        "STREET": "KİÇİR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "SİMAV",
        "STREET": "MADEN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "SİMAV",
        "STREET": "MAMAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "SİMAV",
        "STREET": "MURADINLAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "SİMAV",
        "STREET": "NAŞA-CUMHURİYET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "SİMAV",
        "STREET": "NAŞA-FATİH MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "SİMAV",
        "STREET": "NAŞA-HAMZABEY MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "SİMAV",
        "STREET": "ORTACI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "SİMAV",
        "STREET": "PULLUCA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "SİMAV",
        "STREET": "SAMAT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "SİMAV",
        "STREET": "SARIKAYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "SİMAV",
        "STREET": "SARIÇAM KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "SİMAV",
        "STREET": "SARKATLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "SİMAV",
        "STREET": "SIRAPAYAM MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "SİMAV",
        "STREET": "SUDÖŞEĞİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "SİMAV",
        "STREET": "SÖĞÜT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "SİMAV",
        "STREET": "SÜNNETÇİLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "SİMAV",
        "STREET": "TABAKHANE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "SİMAV",
        "STREET": "TAŞKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "SİMAV",
        "STREET": "TAŞLIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "SİMAV",
        "STREET": "TEPECİK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "SİMAV",
        "STREET": "TOKLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "SİMAV",
        "STREET": "YASSIEYNİHAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "SİMAV",
        "STREET": "YAVI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "SİMAV",
        "STREET": "YAYKIN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "SİMAV",
        "STREET": "YAĞILLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "SİMAV",
        "STREET": "YAĞMURLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "SİMAV",
        "STREET": "YEMİŞLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "SİMAV",
        "STREET": "YENİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "SİMAV",
        "STREET": "YENİKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "SİMAV",
        "STREET": "YENİLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "SİMAV",
        "STREET": "YEŞİLDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "SİMAV",
        "STREET": "YEŞİLKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "SİMAV",
        "STREET": "YEŞİLOVA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "SİMAV",
        "STREET": "YEŞİLYAYLA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "SİMAV",
        "STREET": "YEŞİLÇAM KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "SİMAV",
        "STREET": "YUKARIDOLAYLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "SİMAV",
        "STREET": "ÇAKIRLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "SİMAV",
        "STREET": "ÇAMLIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "SİMAV",
        "STREET": "ÇAVDIR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "SİMAV",
        "STREET": "ÇINARLIDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "SİMAV",
        "STREET": "ÇULHALAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "SİMAV",
        "STREET": "ÇİTGÖL-CAMİİ KEBİR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "SİMAV",
        "STREET": "ÇİTGÖL-CUMHURİYET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "SİMAV",
        "STREET": "ÖRENCİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "SİMAV",
        "STREET": "ÖRENLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "SİMAV",
        "STREET": "İMRANLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "SİMAV",
        "STREET": "İNKAYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "SİMAV",
        "STREET": "İNLİCE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "SİMAV",
        "STREET": "ŞENKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "TAVŞANLI",
        "STREET": "ADA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "TAVŞANLI",
        "STREET": "AKÇAKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "TAVŞANLI",
        "STREET": "AKÇAŞEHİR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "TAVŞANLI",
        "STREET": "ALABARDA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "TAVŞANLI",
        "STREET": "ALTINOVA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "TAVŞANLI",
        "STREET": "ALİKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "TAVŞANLI",
        "STREET": "ARTIRANLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "TAVŞANLI",
        "STREET": "ARİFLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "TAVŞANLI",
        "STREET": "AVCILAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "TAVŞANLI",
        "STREET": "AYDINLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "TAVŞANLI",
        "STREET": "AYVALI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "TAVŞANLI",
        "STREET": "AĞAKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "TAVŞANLI",
        "STREET": "BALIKÖY-BALIKÖY-KADI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "TAVŞANLI",
        "STREET": "BALIKÖY-BALIKÖY-KARAŞEHİR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "TAVŞANLI",
        "STREET": "BALIKÖY-BALIKÖY-KIŞLA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "TAVŞANLI",
        "STREET": "BALIKÖY-BALIKÖY-MERKEZ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "TAVŞANLI",
        "STREET": "BALIKÖY-BALIKÖY-ÇIVGALAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "TAVŞANLI",
        "STREET": "BALIKÖY-SOLUGANLAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "TAVŞANLI",
        "STREET": "BAĞLIK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "TAVŞANLI",
        "STREET": "BAŞKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "TAVŞANLI",
        "STREET": "BEYKÖY MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "TAVŞANLI",
        "STREET": "BOZBELEN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "TAVŞANLI",
        "STREET": "BURHAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "TAVŞANLI",
        "STREET": "DAĞDEMİRLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "TAVŞANLI",
        "STREET": "DAĞÇEŞME MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "TAVŞANLI",
        "STREET": "DEDELER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "TAVŞANLI",
        "STREET": "DERBENT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "TAVŞANLI",
        "STREET": "DERECİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "TAVŞANLI",
        "STREET": "DEVEKAYASI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "TAVŞANLI",
        "STREET": "DEĞİRMİSAZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "TAVŞANLI",
        "STREET": "DOĞANLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "TAVŞANLI",
        "STREET": "DUDAŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "TAVŞANLI",
        "STREET": "DURAK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "TAVŞANLI",
        "STREET": "DUTLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "TAVŞANLI",
        "STREET": "DÜMREKHÜSEYİNPAŞA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "TAVŞANLI",
        "STREET": "DÜMREKORTA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "TAVŞANLI",
        "STREET": "DÜMREKULUKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "TAVŞANLI",
        "STREET": "ELMAAĞACI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "TAVŞANLI",
        "STREET": "ELMALI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "TAVŞANLI",
        "STREET": "EMİRLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "TAVŞANLI",
        "STREET": "ERİKLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "TAVŞANLI",
        "STREET": "EĞRİÖZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "TAVŞANLI",
        "STREET": "EŞEN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "TAVŞANLI",
        "STREET": "GAZELYAKUP KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "TAVŞANLI",
        "STREET": "GEVREKLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "TAVŞANLI",
        "STREET": "GÖBEL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "TAVŞANLI",
        "STREET": "GÖKÇEYURT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "TAVŞANLI",
        "STREET": "GÖLCÜK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "TAVŞANLI",
        "STREET": "GÜMÜŞGÖLCÜK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "TAVŞANLI",
        "STREET": "GÜMÜŞYENİKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "TAVŞANLI",
        "STREET": "HANIMÇEŞME MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "TAVŞANLI",
        "STREET": "KARACAKAŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "TAVŞANLI",
        "STREET": "KARAKAYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "TAVŞANLI",
        "STREET": "KARAKOVA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "TAVŞANLI",
        "STREET": "KARAKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "TAVŞANLI",
        "STREET": "KARAKİŞİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "TAVŞANLI",
        "STREET": "KARAPELİT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "TAVŞANLI",
        "STREET": "KARCIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "TAVŞANLI",
        "STREET": "KARGILI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "TAVŞANLI",
        "STREET": "KARLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "TAVŞANLI",
        "STREET": "KAVAKLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "TAVŞANLI",
        "STREET": "KAVAKLI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "TAVŞANLI",
        "STREET": "KAYAARASI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "TAVŞANLI",
        "STREET": "KAYI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "TAVŞANLI",
        "STREET": "KIRKKAVAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "TAVŞANLI",
        "STREET": "KIZILBÜK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "TAVŞANLI",
        "STREET": "KIZILÇUKUR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "TAVŞANLI",
        "STREET": "KIŞLADEMİRLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "TAVŞANLI",
        "STREET": "KOCAYERİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "TAVŞANLI",
        "STREET": "KOZLUCA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "TAVŞANLI",
        "STREET": "KURUÇAY-KURUÇAY-GÜZELYURT MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "TAVŞANLI",
        "STREET": "KURUÇAY-KURUÇAY-KOCAPINAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "TAVŞANLI",
        "STREET": "KURUÇAY-KURUÇAY-ÖREN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "TAVŞANLI",
        "STREET": "KÖPRÜCEK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "TAVŞANLI",
        "STREET": "KÖREKEN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "TAVŞANLI",
        "STREET": "KÖSELER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "TAVŞANLI",
        "STREET": "MADANLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "TAVŞANLI",
        "STREET": "MOYMUL MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "TAVŞANLI",
        "STREET": "NUSRETLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "TAVŞANLI",
        "STREET": "OPANÖZÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "TAVŞANLI",
        "STREET": "OVACIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "TAVŞANLI",
        "STREET": "SEKBANDEMİRLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "TAVŞANLI",
        "STREET": "SUBAŞI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "TAVŞANLI",
        "STREET": "TAVŞANLI T TİPİ KAPALI CEZA İNFAZ KURUMU",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "TAVŞANLI",
        "STREET": "TEPECİK-TEPECİK-AŞAĞI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "TAVŞANLI",
        "STREET": "TEPECİK-TEPECİK-ORTA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "TAVŞANLI",
        "STREET": "TEPECİK-TEPECİK-YUKARI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "TAVŞANLI",
        "STREET": "TUNÇBİLEK-GÜRAĞAÇ CEVİZLİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "TAVŞANLI",
        "STREET": "TUNÇBİLEK-GÜRAĞAÇ ÇINAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "TAVŞANLI",
        "STREET": "TUNÇBİLEK-TUNÇBİLEK MERKEZ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "TAVŞANLI",
        "STREET": "TUNÇBİLEK-TUNÇBİLEK-DEMİRBİLEK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "TAVŞANLI",
        "STREET": "TUNÇBİLEK-TUNÇBİLEK-HAMİTABAT MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "TAVŞANLI",
        "STREET": "TUNÇBİLEK-TUNÇBİLEK-IŞIK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "TAVŞANLI",
        "STREET": "TUNÇBİLEK-TUNÇBİLEK-KÖPRÜBAŞI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "TAVŞANLI",
        "STREET": "TUNÇBİLEK-TUNÇBİLEK-ÇAMALTI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "TAVŞANLI",
        "STREET": "ULUCAMİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "TAVŞANLI",
        "STREET": "ULUÇAM KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "TAVŞANLI",
        "STREET": "VAKIF KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "TAVŞANLI",
        "STREET": "YATAKLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "TAVŞANLI",
        "STREET": "YAĞMURLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "TAVŞANLI",
        "STREET": "YENİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "TAVŞANLI",
        "STREET": "YENİKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "TAVŞANLI",
        "STREET": "YILDIRIM BEYAZIT MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "TAVŞANLI",
        "STREET": "YÖRGÜÇ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "TAVŞANLI",
        "STREET": "ÇAKILLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "TAVŞANLI",
        "STREET": "ÇALDİBİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "TAVŞANLI",
        "STREET": "ÇALTILI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "TAVŞANLI",
        "STREET": "ÇARDAKLI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "TAVŞANLI",
        "STREET": "ÇAYIRKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "TAVŞANLI",
        "STREET": "ÇAYIROLUK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "TAVŞANLI",
        "STREET": "ÇIKRICAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "TAVŞANLI",
        "STREET": "ÇINARCIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "TAVŞANLI",
        "STREET": "ÇIRÇIRÇEŞME MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "TAVŞANLI",
        "STREET": "ÇOBANKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "TAVŞANLI",
        "STREET": "ÇOBANLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "TAVŞANLI",
        "STREET": "ÇUKURKÖY MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "TAVŞANLI",
        "STREET": "ÖKSÜZLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "TAVŞANLI",
        "STREET": "ÖMERBEY MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "TAVŞANLI",
        "STREET": "ÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "TAVŞANLI",
        "STREET": "ÜYÜCEK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "TAVŞANLI",
        "STREET": "İSAKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "TAVŞANLI",
        "STREET": "İSTASYON MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "TAVŞANLI",
        "STREET": "ŞAHMELEK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "TAVŞANLI",
        "STREET": "ŞAHİN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "TAVŞANLI",
        "STREET": "ŞAPÇI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "TAVŞANLI",
        "STREET": "ŞAPÇIDEDE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "TAVŞANLI",
        "STREET": "ŞENLİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "ÇAVDARHİSAR",
        "STREET": "AFŞAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "ÇAVDARHİSAR",
        "STREET": "AKPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "ÇAVDARHİSAR",
        "STREET": "AĞARI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "ÇAVDARHİSAR",
        "STREET": "BARAĞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "ÇAVDARHİSAR",
        "STREET": "CERELLER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "ÇAVDARHİSAR",
        "STREET": "DEMİROLUK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "ÇAVDARHİSAR",
        "STREET": "DOĞANCILAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "ÇAVDARHİSAR",
        "STREET": "EFELER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "ÇAVDARHİSAR",
        "STREET": "EFENDİKÖPRÜSÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "ÇAVDARHİSAR",
        "STREET": "GÖKAĞAÇ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "ÇAVDARHİSAR",
        "STREET": "GÖKLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "ÇAVDARHİSAR",
        "STREET": "HACIKEBİR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "ÇAVDARHİSAR",
        "STREET": "HACIMAHMUT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "ÇAVDARHİSAR",
        "STREET": "KEMALLER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "ÇAVDARHİSAR",
        "STREET": "KIZIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "ÇAVDARHİSAR",
        "STREET": "MEYDAN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "ÇAVDARHİSAR",
        "STREET": "PUSATLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "ÇAVDARHİSAR",
        "STREET": "SUSUZKAYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "ÇAVDARHİSAR",
        "STREET": "TEPECİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "ÇAVDARHİSAR",
        "STREET": "YAĞDIĞIN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "ÇAVDARHİSAR",
        "STREET": "YENİCEARMUTCUK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "ÇAVDARHİSAR",
        "STREET": "YEŞİLDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "ÇAVDARHİSAR",
        "STREET": "YUKARI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "ÇAVDARHİSAR",
        "STREET": "ZOBU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "ÇAVDARHİSAR",
        "STREET": "ÇAMKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "ÇAVDARHİSAR",
        "STREET": "ÇATKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "ÇAVDARHİSAR",
        "STREET": "İLCİKÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "ŞAPHANE",
        "STREET": "AYVACIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "ŞAPHANE",
        "STREET": "DEĞİRMENDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "ŞAPHANE",
        "STREET": "GAİPLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "ŞAPHANE",
        "STREET": "GÜRKUYU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "ŞAPHANE",
        "STREET": "HACI HASANLAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "ŞAPHANE",
        "STREET": "KALABAK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "ŞAPHANE",
        "STREET": "KARAAĞIL MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "ŞAPHANE",
        "STREET": "KARACADERBENT MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "ŞAPHANE",
        "STREET": "KARAKÜR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "ŞAPHANE",
        "STREET": "KARAMANCA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "ŞAPHANE",
        "STREET": "KARAMUSTAFALAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "ŞAPHANE",
        "STREET": "KIZILKOLTUK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "ŞAPHANE",
        "STREET": "PAZAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "ŞAPHANE",
        "STREET": "SUBAŞI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "ŞAPHANE",
        "STREET": "TÜREGÜN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "ŞAPHANE",
        "STREET": "UĞURLUGÜME KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "ŞAPHANE",
        "STREET": "ÇAM KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "ŞAPHANE",
        "STREET": "ÜÇBAŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KÜTAHYA",
        "DISTRICT": "ŞAPHANE",
        "STREET": "İNCEĞİZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KİLİS",
        "DISTRICT": "ELBEYLİ",
        "STREET": "AKÇAĞIL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KİLİS",
        "DISTRICT": "ELBEYLİ",
        "STREET": "ALAHAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KİLİS",
        "DISTRICT": "ELBEYLİ",
        "STREET": "AŞAĞIBEYLERBEYİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KİLİS",
        "DISTRICT": "ELBEYLİ",
        "STREET": "BEŞİRİYE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KİLİS",
        "DISTRICT": "ELBEYLİ",
        "STREET": "ERİKLİYAYLA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KİLİS",
        "DISTRICT": "ELBEYLİ",
        "STREET": "GEÇERLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KİLİS",
        "DISTRICT": "ELBEYLİ",
        "STREET": "GÜNECE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KİLİS",
        "DISTRICT": "ELBEYLİ",
        "STREET": "GÜVENDİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KİLİS",
        "DISTRICT": "ELBEYLİ",
        "STREET": "HAVUZLUÇAM KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KİLİS",
        "DISTRICT": "ELBEYLİ",
        "STREET": "HÜRRİYET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KİLİS",
        "DISTRICT": "ELBEYLİ",
        "STREET": "KALBURSAİT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KİLİS",
        "DISTRICT": "ELBEYLİ",
        "STREET": "KARACURUN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KİLİS",
        "DISTRICT": "ELBEYLİ",
        "STREET": "KARAÇAĞIL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KİLİS",
        "DISTRICT": "ELBEYLİ",
        "STREET": "KILCAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KİLİS",
        "DISTRICT": "ELBEYLİ",
        "STREET": "MERCANLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KİLİS",
        "DISTRICT": "ELBEYLİ",
        "STREET": "NECMETTİN ERBAKAN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KİLİS",
        "DISTRICT": "ELBEYLİ",
        "STREET": "SAĞLICAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KİLİS",
        "DISTRICT": "ELBEYLİ",
        "STREET": "SELMİNCİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KİLİS",
        "DISTRICT": "ELBEYLİ",
        "STREET": "TAŞLIBAKAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KİLİS",
        "DISTRICT": "ELBEYLİ",
        "STREET": "TURANLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KİLİS",
        "DISTRICT": "ELBEYLİ",
        "STREET": "UĞURLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KİLİS",
        "DISTRICT": "ELBEYLİ",
        "STREET": "YALÇIN NANE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KİLİS",
        "DISTRICT": "ELBEYLİ",
        "STREET": "YAĞIZKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "KİLİS",
        "DISTRICT": "ELBEYLİ",
        "STREET": "YENİDEĞİRMEN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KİLİS",
        "DISTRICT": "ELBEYLİ",
        "STREET": "ÇANGALLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KİLİS",
        "DISTRICT": "ELBEYLİ",
        "STREET": "ÇILDIROBA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KİLİS",
        "DISTRICT": "ELBEYLİ",
        "STREET": "İNÖNÜ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KİLİS",
        "DISTRICT": "MERKEZ",
        "STREET": "ABDİOYMAĞI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KİLİS",
        "DISTRICT": "MERKEZ",
        "STREET": "ACAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KİLİS",
        "DISTRICT": "MERKEZ",
        "STREET": "AKINCI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KİLİS",
        "DISTRICT": "MERKEZ",
        "STREET": "AKPINAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KİLİS",
        "DISTRICT": "MERKEZ",
        "STREET": "AKÇABAĞLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KİLİS",
        "DISTRICT": "MERKEZ",
        "STREET": "ALATEPE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KİLİS",
        "DISTRICT": "MERKEZ",
        "STREET": "ALBAY İBRAHİM KARAOĞLANOĞLU MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KİLİS",
        "DISTRICT": "MERKEZ",
        "STREET": "ALTINÜZÜM MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KİLİS",
        "DISTRICT": "MERKEZ",
        "STREET": "ARPAKESMEZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KİLİS",
        "DISTRICT": "MERKEZ",
        "STREET": "ASLAN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KİLİS",
        "DISTRICT": "MERKEZ",
        "STREET": "ATATÜRK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KİLİS",
        "DISTRICT": "MERKEZ",
        "STREET": "AŞIT MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KİLİS",
        "DISTRICT": "MERKEZ",
        "STREET": "BARIŞ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KİLİS",
        "DISTRICT": "MERKEZ",
        "STREET": "BAŞMAĞARA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KİLİS",
        "DISTRICT": "MERKEZ",
        "STREET": "BEŞENLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KİLİS",
        "DISTRICT": "MERKEZ",
        "STREET": "BEŞİKKAYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KİLİS",
        "DISTRICT": "MERKEZ",
        "STREET": "BOZCAYAZI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KİLİS",
        "DISTRICT": "MERKEZ",
        "STREET": "BULAMAÇLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KİLİS",
        "DISTRICT": "MERKEZ",
        "STREET": "BÖLÜK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KİLİS",
        "DISTRICT": "MERKEZ",
        "STREET": "BÜYÜKKONAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KİLİS",
        "DISTRICT": "MERKEZ",
        "STREET": "BÜYÜKKÜTAH MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KİLİS",
        "DISTRICT": "MERKEZ",
        "STREET": "BİLALİ HABEŞ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KİLİS",
        "DISTRICT": "MERKEZ",
        "STREET": "CANPOLAT PAŞA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KİLİS",
        "DISTRICT": "MERKEZ",
        "STREET": "CERİTLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KİLİS",
        "DISTRICT": "MERKEZ",
        "STREET": "CUMHURİYET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KİLİS",
        "DISTRICT": "MERKEZ",
        "STREET": "DELİOSMAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KİLİS",
        "DISTRICT": "MERKEZ",
        "STREET": "DELİÇAY KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KİLİS",
        "DISTRICT": "MERKEZ",
        "STREET": "DEMİRCİLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KİLİS",
        "DISTRICT": "MERKEZ",
        "STREET": "DEMİRCİLER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KİLİS",
        "DISTRICT": "MERKEZ",
        "STREET": "DEMİRIŞIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KİLİS",
        "DISTRICT": "MERKEZ",
        "STREET": "DEVECİLER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KİLİS",
        "DISTRICT": "MERKEZ",
        "STREET": "DOĞAN GÜREŞ PAŞA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KİLİS",
        "DISTRICT": "MERKEZ",
        "STREET": "DOĞANÇAY KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KİLİS",
        "DISTRICT": "MERKEZ",
        "STREET": "DURUCA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KİLİS",
        "DISTRICT": "MERKEZ",
        "STREET": "DÖLEK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KİLİS",
        "DISTRICT": "MERKEZ",
        "STREET": "EBULÜLE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KİLİS",
        "DISTRICT": "MERKEZ",
        "STREET": "EKREM ÇETİN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KİLİS",
        "DISTRICT": "MERKEZ",
        "STREET": "FATİH SULTAN MEHMET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KİLİS",
        "DISTRICT": "MERKEZ",
        "STREET": "GAZİLER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KİLİS",
        "DISTRICT": "MERKEZ",
        "STREET": "GÖKTAŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KİLİS",
        "DISTRICT": "MERKEZ",
        "STREET": "GÖZKAYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KİLİS",
        "DISTRICT": "MERKEZ",
        "STREET": "GÜLBABA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KİLİS",
        "DISTRICT": "MERKEZ",
        "STREET": "GÜMÜŞSUYU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KİLİS",
        "DISTRICT": "MERKEZ",
        "STREET": "GÜNEŞLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KİLİS",
        "DISTRICT": "MERKEZ",
        "STREET": "GÜVENLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KİLİS",
        "DISTRICT": "MERKEZ",
        "STREET": "HACI GÜMÜŞ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KİLİS",
        "DISTRICT": "MERKEZ",
        "STREET": "HACI İLYAS MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KİLİS",
        "DISTRICT": "MERKEZ",
        "STREET": "HAKVERDİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KİLİS",
        "DISTRICT": "MERKEZ",
        "STREET": "HASAN KAMİL DEMİRBAŞ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KİLİS",
        "DISTRICT": "MERKEZ",
        "STREET": "HELVACIOĞLU MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KİLİS",
        "DISTRICT": "MERKEZ",
        "STREET": "HICIPOĞLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KİLİS",
        "DISTRICT": "MERKEZ",
        "STREET": "HÜRRİYET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KİLİS",
        "DISTRICT": "MERKEZ",
        "STREET": "HİNDİOĞLU MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KİLİS",
        "DISTRICT": "MERKEZ",
        "STREET": "HİSAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KİLİS",
        "DISTRICT": "MERKEZ",
        "STREET": "KANUNİ SULTAN SÜLEYMAN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KİLİS",
        "DISTRICT": "MERKEZ",
        "STREET": "KAPDEĞİRMENİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KİLİS",
        "DISTRICT": "MERKEZ",
        "STREET": "KARAALİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KİLİS",
        "DISTRICT": "MERKEZ",
        "STREET": "KARACAÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KİLİS",
        "DISTRICT": "MERKEZ",
        "STREET": "KARAMELİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KİLİS",
        "DISTRICT": "MERKEZ",
        "STREET": "KARAÇAVUŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KİLİS",
        "DISTRICT": "MERKEZ",
        "STREET": "KARTALBEY MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KİLİS",
        "DISTRICT": "MERKEZ",
        "STREET": "KAZIM KARABEKİR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KİLİS",
        "DISTRICT": "MERKEZ",
        "STREET": "KETENCİLER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KİLİS",
        "DISTRICT": "MERKEZ",
        "STREET": "KOCABEYLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KİLİS",
        "DISTRICT": "MERKEZ",
        "STREET": "KUSKUNKIRAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KİLİS",
        "DISTRICT": "MERKEZ",
        "STREET": "KUZUİNİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KİLİS",
        "DISTRICT": "MERKEZ",
        "STREET": "KÜPLÜCE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KİLİS",
        "DISTRICT": "MERKEZ",
        "STREET": "KÜÇÜKKONAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KİLİS",
        "DISTRICT": "MERKEZ",
        "STREET": "KİLİS L TİPİ KAPALI CEZAEVİ",
        "TYPE": "0"
      },
      {
        "CITY": "KİLİS",
        "DISTRICT": "MERKEZ",
        "STREET": "MARAŞAL FEVZİ ÇAKMAK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KİLİS",
        "DISTRICT": "MERKEZ",
        "STREET": "MAĞARACIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KİLİS",
        "DISTRICT": "MERKEZ",
        "STREET": "MEHMET ABDİ BULUT MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KİLİS",
        "DISTRICT": "MERKEZ",
        "STREET": "MEHMET RIFAT KAZANCIOĞLU MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KİLİS",
        "DISTRICT": "MERKEZ",
        "STREET": "MEHMET SANLI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KİLİS",
        "DISTRICT": "MERKEZ",
        "STREET": "MEHMETALİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KİLİS",
        "DISTRICT": "MERKEZ",
        "STREET": "MERCİDABIK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KİLİS",
        "DISTRICT": "MERKEZ",
        "STREET": "MEŞETLİK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KİLİS",
        "DISTRICT": "MERKEZ",
        "STREET": "MIHALİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KİLİS",
        "DISTRICT": "MERKEZ",
        "STREET": "MISIRCIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KİLİS",
        "DISTRICT": "MERKEZ",
        "STREET": "MUALLİM RIFAT BİLGE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KİLİS",
        "DISTRICT": "MERKEZ",
        "STREET": "MULLAHAMİT MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KİLİS",
        "DISTRICT": "MERKEZ",
        "STREET": "MÜCAHİTLER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KİLİS",
        "DISTRICT": "MERKEZ",
        "STREET": "MÜSLÜMANBEY MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KİLİS",
        "DISTRICT": "MERKEZ",
        "STREET": "NACAROĞLU MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KİLİS",
        "DISTRICT": "MERKEZ",
        "STREET": "NAMIK KEMAL MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KİLİS",
        "DISTRICT": "MERKEZ",
        "STREET": "NAMIK ÜNLER PAŞA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KİLİS",
        "DISTRICT": "MERKEZ",
        "STREET": "NARLICA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KİLİS",
        "DISTRICT": "MERKEZ",
        "STREET": "NECMETTİN ERBAKAN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KİLİS",
        "DISTRICT": "MERKEZ",
        "STREET": "NEDİM ÖKMEN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KİLİS",
        "DISTRICT": "MERKEZ",
        "STREET": "NURETTİN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KİLİS",
        "DISTRICT": "MERKEZ",
        "STREET": "OKÇULAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KİLİS",
        "DISTRICT": "MERKEZ",
        "STREET": "OSMAN GAZİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KİLİS",
        "DISTRICT": "MERKEZ",
        "STREET": "OYLUM MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KİLİS",
        "DISTRICT": "MERKEZ",
        "STREET": "POLAT PAŞA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KİLİS",
        "DISTRICT": "MERKEZ",
        "STREET": "SAATLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KİLİS",
        "DISTRICT": "MERKEZ",
        "STREET": "SARAÇ MEHMET ÇAVUŞ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KİLİS",
        "DISTRICT": "MERKEZ",
        "STREET": "SÜNGÜTEPE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KİLİS",
        "DISTRICT": "MERKEZ",
        "STREET": "TABAKHANE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KİLİS",
        "DISTRICT": "MERKEZ",
        "STREET": "TAHTALI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KİLİS",
        "DISTRICT": "MERKEZ",
        "STREET": "TANBIRALI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KİLİS",
        "DISTRICT": "MERKEZ",
        "STREET": "TEKYE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KİLİS",
        "DISTRICT": "MERKEZ",
        "STREET": "TIRIKLI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KİLİS",
        "DISTRICT": "MERKEZ",
        "STREET": "TOPDAĞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KİLİS",
        "DISTRICT": "MERKEZ",
        "STREET": "TOSYALI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KİLİS",
        "DISTRICT": "MERKEZ",
        "STREET": "UZUNLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KİLİS",
        "DISTRICT": "MERKEZ",
        "STREET": "VAİZ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KİLİS",
        "DISTRICT": "MERKEZ",
        "STREET": "YAVUZ SULTAN SELİM MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KİLİS",
        "DISTRICT": "MERKEZ",
        "STREET": "YAVUZLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KİLİS",
        "DISTRICT": "MERKEZ",
        "STREET": "YAŞAR AKTÜRK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KİLİS",
        "DISTRICT": "MERKEZ",
        "STREET": "YEDİ ARALIK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KİLİS",
        "DISTRICT": "MERKEZ",
        "STREET": "YENİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KİLİS",
        "DISTRICT": "MERKEZ",
        "STREET": "ZEYTİNLİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KİLİS",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇAKKALLIPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KİLİS",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇALKAYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KİLİS",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇAYLAK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KİLİS",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇERÇİLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KİLİS",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇUKUROBA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KİLİS",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇÖRTEN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KİLİS",
        "DISTRICT": "MERKEZ",
        "STREET": "ÖNCÜ PINAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KİLİS",
        "DISTRICT": "MERKEZ",
        "STREET": "İNANLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KİLİS",
        "DISTRICT": "MERKEZ",
        "STREET": "İNNAPLI KÜTAH MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KİLİS",
        "DISTRICT": "MERKEZ",
        "STREET": "İSLAMBEY MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KİLİS",
        "DISTRICT": "MERKEZ",
        "STREET": "İSMET PAŞA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KİLİS",
        "DISTRICT": "MERKEZ",
        "STREET": "ŞEHİT SAKIP MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KİLİS",
        "DISTRICT": "MERKEZ",
        "STREET": "ŞEHİTLER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KİLİS",
        "DISTRICT": "MERKEZ",
        "STREET": "ŞIH ABDULLAH MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KİLİS",
        "DISTRICT": "MERKEZ",
        "STREET": "ŞIH AHMET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KİLİS",
        "DISTRICT": "MERKEZ",
        "STREET": "ŞIH MEHMET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KİLİS",
        "DISTRICT": "MERKEZ",
        "STREET": "ŞIHLAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KİLİS",
        "DISTRICT": "MERKEZ",
        "STREET": "ŞÖRHABİL MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KİLİS",
        "DISTRICT": "MUSABEYLİ",
        "STREET": "AĞCAKENT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KİLİS",
        "DISTRICT": "MUSABEYLİ",
        "STREET": "AŞAĞIBADEMLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KİLİS",
        "DISTRICT": "MUSABEYLİ",
        "STREET": "AŞAĞIKALECİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KİLİS",
        "DISTRICT": "MUSABEYLİ",
        "STREET": "BALIKLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KİLİS",
        "DISTRICT": "MUSABEYLİ",
        "STREET": "BELENTEPE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KİLİS",
        "DISTRICT": "MUSABEYLİ",
        "STREET": "BOZKAYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KİLİS",
        "DISTRICT": "MUSABEYLİ",
        "STREET": "CUMHURİYET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KİLİS",
        "DISTRICT": "MUSABEYLİ",
        "STREET": "DORUCAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KİLİS",
        "DISTRICT": "MUSABEYLİ",
        "STREET": "DUTLUCA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KİLİS",
        "DISTRICT": "MUSABEYLİ",
        "STREET": "FERİCEK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KİLİS",
        "DISTRICT": "MUSABEYLİ",
        "STREET": "FIRLAKLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KİLİS",
        "DISTRICT": "MUSABEYLİ",
        "STREET": "GEÇİTBOYU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KİLİS",
        "DISTRICT": "MUSABEYLİ",
        "STREET": "GÖKMUSA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KİLİS",
        "DISTRICT": "MUSABEYLİ",
        "STREET": "GÖZLÜCE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KİLİS",
        "DISTRICT": "MUSABEYLİ",
        "STREET": "GÜNDEĞER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KİLİS",
        "DISTRICT": "MUSABEYLİ",
        "STREET": "HACILAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KİLİS",
        "DISTRICT": "MUSABEYLİ",
        "STREET": "HASANCALI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KİLİS",
        "DISTRICT": "MUSABEYLİ",
        "STREET": "HAYDARLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KİLİS",
        "DISTRICT": "MUSABEYLİ",
        "STREET": "HÜSEYİNOĞLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KİLİS",
        "DISTRICT": "MUSABEYLİ",
        "STREET": "KARADUT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KİLİS",
        "DISTRICT": "MUSABEYLİ",
        "STREET": "KARBEYAZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KİLİS",
        "DISTRICT": "MUSABEYLİ",
        "STREET": "KAYAPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KİLİS",
        "DISTRICT": "MUSABEYLİ",
        "STREET": "KAYNAKLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KİLİS",
        "DISTRICT": "MUSABEYLİ",
        "STREET": "KIZILKENT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KİLİS",
        "DISTRICT": "MUSABEYLİ",
        "STREET": "KOLPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KİLİS",
        "DISTRICT": "MUSABEYLİ",
        "STREET": "KOZLUBAĞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KİLİS",
        "DISTRICT": "MUSABEYLİ",
        "STREET": "KOÇCAĞIZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KİLİS",
        "DISTRICT": "MUSABEYLİ",
        "STREET": "KURTARAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KİLİS",
        "DISTRICT": "MUSABEYLİ",
        "STREET": "KÖRAHMETHÜYÜĞÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KİLİS",
        "DISTRICT": "MUSABEYLİ",
        "STREET": "KÜRTÜNCÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KİLİS",
        "DISTRICT": "MUSABEYLİ",
        "STREET": "MADENYOLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KİLİS",
        "DISTRICT": "MUSABEYLİ",
        "STREET": "MURATHÜYÜĞÜ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KİLİS",
        "DISTRICT": "MUSABEYLİ",
        "STREET": "PINAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KİLİS",
        "DISTRICT": "MUSABEYLİ",
        "STREET": "SABANLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KİLİS",
        "DISTRICT": "MUSABEYLİ",
        "STREET": "TAHTALIKARADUT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KİLİS",
        "DISTRICT": "MUSABEYLİ",
        "STREET": "TOKAÇGEMRİĞİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KİLİS",
        "DISTRICT": "MUSABEYLİ",
        "STREET": "TOPALLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KİLİS",
        "DISTRICT": "MUSABEYLİ",
        "STREET": "UĞURTEPE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KİLİS",
        "DISTRICT": "MUSABEYLİ",
        "STREET": "YASTICA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KİLİS",
        "DISTRICT": "MUSABEYLİ",
        "STREET": "YEDİGÖZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KİLİS",
        "DISTRICT": "MUSABEYLİ",
        "STREET": "YEŞİLOBA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KİLİS",
        "DISTRICT": "MUSABEYLİ",
        "STREET": "YUKARIBADEMLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KİLİS",
        "DISTRICT": "MUSABEYLİ",
        "STREET": "YUKARIKALECİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KİLİS",
        "DISTRICT": "MUSABEYLİ",
        "STREET": "YUVABAŞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KİLİS",
        "DISTRICT": "MUSABEYLİ",
        "STREET": "ZEYTİNBAĞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KİLİS",
        "DISTRICT": "MUSABEYLİ",
        "STREET": "ÇAYIRALTI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KİLİS",
        "DISTRICT": "MUSABEYLİ",
        "STREET": "ÇINARKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "KİLİS",
        "DISTRICT": "MUSABEYLİ",
        "STREET": "ÜÇPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KİLİS",
        "DISTRICT": "MUSABEYLİ",
        "STREET": "ŞENLİKÇE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KİLİS",
        "DISTRICT": "POLATELİ",
        "STREET": "ATATÜRK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KİLİS",
        "DISTRICT": "POLATELİ",
        "STREET": "BAĞARASI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KİLİS",
        "DISTRICT": "POLATELİ",
        "STREET": "BEKTAŞOĞLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KİLİS",
        "DISTRICT": "POLATELİ",
        "STREET": "BELENÖZÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KİLİS",
        "DISTRICT": "POLATELİ",
        "STREET": "CUMHURİYET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KİLİS",
        "DISTRICT": "POLATELİ",
        "STREET": "DÜMBÜLLÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KİLİS",
        "DISTRICT": "POLATELİ",
        "STREET": "EKREM ÇETİN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KİLİS",
        "DISTRICT": "POLATELİ",
        "STREET": "EĞLEN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KİLİS",
        "DISTRICT": "POLATELİ",
        "STREET": "KAYMAKAM KÜRŞAT AĞCA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KİLİS",
        "DISTRICT": "POLATELİ",
        "STREET": "KIZILGÖL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KİLİS",
        "DISTRICT": "POLATELİ",
        "STREET": "POLATBEY KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KİLİS",
        "DISTRICT": "POLATELİ",
        "STREET": "SOSYAL KONUTLAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "KİLİS",
        "DISTRICT": "POLATELİ",
        "STREET": "SÖĞÜTLÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KİLİS",
        "DISTRICT": "POLATELİ",
        "STREET": "TAŞLIALAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KİLİS",
        "DISTRICT": "POLATELİ",
        "STREET": "YENİYAPAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KİLİS",
        "DISTRICT": "POLATELİ",
        "STREET": "YENİYURT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KİLİS",
        "DISTRICT": "POLATELİ",
        "STREET": "YEŞİLPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KİLİS",
        "DISTRICT": "POLATELİ",
        "STREET": "YILANCA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KİLİS",
        "DISTRICT": "POLATELİ",
        "STREET": "ÖMERCİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KİLİS",
        "DISTRICT": "POLATELİ",
        "STREET": "ÖMEROĞLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KİLİS",
        "DISTRICT": "POLATELİ",
        "STREET": "ÜRÜNLÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "KİLİS",
        "DISTRICT": "POLATELİ",
        "STREET": "ŞEHİT ALİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "AKÇADAĞ",
        "STREET": "AKSARAY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "AKÇADAĞ",
        "STREET": "AKSÜT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "AKÇADAĞ",
        "STREET": "AKTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "AKÇADAĞ",
        "STREET": "AKÇADAĞ CEZA İNFAZ KURUMU MÜDÜRLÜĞÜ",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "AKÇADAĞ",
        "STREET": "ALTUNLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "AKÇADAĞ",
        "STREET": "ALİÇERİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "AKÇADAĞ",
        "STREET": "ANCAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "AKÇADAĞ",
        "STREET": "AYDINLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "AKÇADAĞ",
        "STREET": "AŞAĞIÖRÜKÇÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "AKÇADAĞ",
        "STREET": "BAHRİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "AKÇADAĞ",
        "STREET": "BAYRAMUŞAĞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "AKÇADAĞ",
        "STREET": "BAĞKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "AKÇADAĞ",
        "STREET": "BAŞPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "AKÇADAĞ",
        "STREET": "BEKİRUŞAĞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "AKÇADAĞ",
        "STREET": "BÖLÜKLÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "AKÇADAĞ",
        "STREET": "BÜYÜKKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "AKÇADAĞ",
        "STREET": "BÜYÜKÇİMİŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "AKÇADAĞ",
        "STREET": "DARICA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "AKÇADAĞ",
        "STREET": "DEDEKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "AKÇADAĞ",
        "STREET": "DEMİRCİLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "AKÇADAĞ",
        "STREET": "DEVELİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "AKÇADAĞ",
        "STREET": "DOĞANLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "AKÇADAĞ",
        "STREET": "DOĞANTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "AKÇADAĞ",
        "STREET": "DOĞU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "AKÇADAĞ",
        "STREET": "DURULOVA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "AKÇADAĞ",
        "STREET": "DUTLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "AKÇADAĞ",
        "STREET": "DÜMÜKLÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "AKÇADAĞ",
        "STREET": "ESENBEY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "AKÇADAĞ",
        "STREET": "ESENLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "AKÇADAĞ",
        "STREET": "EĞİN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "AKÇADAĞ",
        "STREET": "FATİH MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "AKÇADAĞ",
        "STREET": "GÖLPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "AKÇADAĞ",
        "STREET": "GÜNEŞLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "AKÇADAĞ",
        "STREET": "GÜRKAYNAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "AKÇADAĞ",
        "STREET": "HANÇERLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "AKÇADAĞ",
        "STREET": "HARTUT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "AKÇADAĞ",
        "STREET": "HARUNUŞAĞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "AKÇADAĞ",
        "STREET": "ILICAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "AKÇADAĞ",
        "STREET": "KADIİBRAHİM MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "AKÇADAĞ",
        "STREET": "KAHYALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "AKÇADAĞ",
        "STREET": "KARAMAĞARA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "AKÇADAĞ",
        "STREET": "KARAPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "AKÇADAĞ",
        "STREET": "KASIMUŞAĞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "AKÇADAĞ",
        "STREET": "KAYADİBİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "AKÇADAĞ",
        "STREET": "KEKLİKPINARI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "AKÇADAĞ",
        "STREET": "KELLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "AKÇADAĞ",
        "STREET": "KEPEZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "AKÇADAĞ",
        "STREET": "KOLKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "AKÇADAĞ",
        "STREET": "KOTANGÖLÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "AKÇADAĞ",
        "STREET": "KOZLUCA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "AKÇADAĞ",
        "STREET": "KURTUŞAĞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "AKÇADAĞ",
        "STREET": "KÖMEKAVAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "AKÇADAĞ",
        "STREET": "KÜLTÜR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "AKÇADAĞ",
        "STREET": "KÜÇÜKKÜRNE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "AKÇADAĞ",
        "STREET": "LEVENT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "AKÇADAĞ",
        "STREET": "MEZRA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "AKÇADAĞ",
        "STREET": "MURATLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "AKÇADAĞ",
        "STREET": "MİHMANLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "AKÇADAĞ",
        "STREET": "ORTAKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "AKÇADAĞ",
        "STREET": "RESULUŞAĞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "AKÇADAĞ",
        "STREET": "SAHİLKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "AKÇADAĞ",
        "STREET": "SAKALIUZUN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "AKÇADAĞ",
        "STREET": "SARIHACI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "AKÇADAĞ",
        "STREET": "TATARUŞAĞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "AKÇADAĞ",
        "STREET": "TAŞEVLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "AKÇADAĞ",
        "STREET": "TAŞOLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "AKÇADAĞ",
        "STREET": "YALINBUDAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "AKÇADAĞ",
        "STREET": "YALINKAYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "AKÇADAĞ",
        "STREET": "YAYLIMLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "AKÇADAĞ",
        "STREET": "YAĞMURLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "AKÇADAĞ",
        "STREET": "YUKARIÖRÜKÇÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "AKÇADAĞ",
        "STREET": "ÇAKILLIPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "AKÇADAĞ",
        "STREET": "ÇATALBAHÇE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "AKÇADAĞ",
        "STREET": "ÇEVİRME MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "AKÇADAĞ",
        "STREET": "ÇOBANUŞAĞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "AKÇADAĞ",
        "STREET": "ÖREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "AKÇADAĞ",
        "STREET": "İKİNCİLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "AKÇADAĞ",
        "STREET": "ŞEYHLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "ARAPGİR",
        "STREET": "AKTAŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "ARAPGİR",
        "STREET": "ALIÇLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "ARAPGİR",
        "STREET": "AŞAĞIULUPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "ARAPGİR",
        "STREET": "AŞAĞIYENİCE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "ARAPGİR",
        "STREET": "AŞAĞIÇÖRENGE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "ARAPGİR",
        "STREET": "BERENGE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "ARAPGİR",
        "STREET": "BOSTANCIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "ARAPGİR",
        "STREET": "BOĞAZLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "ARAPGİR",
        "STREET": "BUDAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "ARAPGİR",
        "STREET": "CÖMERTLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "ARAPGİR",
        "STREET": "DEREGEZEN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "ARAPGİR",
        "STREET": "DÜZCE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "ARAPGİR",
        "STREET": "ESKİARAPGİR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "ARAPGİR",
        "STREET": "ESİKLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "ARAPGİR",
        "STREET": "EĞNİR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "ARAPGİR",
        "STREET": "GEBELİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "ARAPGİR",
        "STREET": "GÖZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "ARAPGİR",
        "STREET": "GÖZELİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "ARAPGİR",
        "STREET": "GÜNYÜZÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "ARAPGİR",
        "STREET": "HEZENEK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "ARAPGİR",
        "STREET": "HOCAALİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "ARAPGİR",
        "STREET": "KAYAKESEN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "ARAPGİR",
        "STREET": "KAYNAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "ARAPGİR",
        "STREET": "KAZANÇ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "ARAPGİR",
        "STREET": "KILIÇLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "ARAPGİR",
        "STREET": "KONDUCAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "ARAPGİR",
        "STREET": "KORU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "ARAPGİR",
        "STREET": "KÖSEOĞLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "ARAPGİR",
        "STREET": "MEHMETAKİF MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "ARAPGİR",
        "STREET": "MEŞELİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "ARAPGİR",
        "STREET": "ONAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "ARAPGİR",
        "STREET": "ORMANSIRTI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "ARAPGİR",
        "STREET": "OSMANPAŞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "ARAPGİR",
        "STREET": "PACALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "ARAPGİR",
        "STREET": "PİRALİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "ARAPGİR",
        "STREET": "SEKİZSU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "ARAPGİR",
        "STREET": "SELAMLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "ARAPGİR",
        "STREET": "SERGE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "ARAPGİR",
        "STREET": "SUCEYİN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "ARAPGİR",
        "STREET": "SUGEÇTİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "ARAPGİR",
        "STREET": "SİNİKLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "ARAPGİR",
        "STREET": "SİPAHİUŞAĞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "ARAPGİR",
        "STREET": "TARHAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "ARAPGİR",
        "STREET": "TAŞDELEN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "ARAPGİR",
        "STREET": "TAŞDİBEK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "ARAPGİR",
        "STREET": "ULAÇLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "ARAPGİR",
        "STREET": "YAYLACIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "ARAPGİR",
        "STREET": "YAZILI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "ARAPGİR",
        "STREET": "YENİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "ARAPGİR",
        "STREET": "YEŞİLYAYLA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "ARAPGİR",
        "STREET": "YUKARIULUPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "ARAPGİR",
        "STREET": "YUKARIYABANLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "ARAPGİR",
        "STREET": "YUKARIYENİCE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "ARAPGİR",
        "STREET": "YUKARIÇÖRENGE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "ARAPGİR",
        "STREET": "ZÖHRAP MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "ARAPGİR",
        "STREET": "ÇAKIRSU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "ARAPGİR",
        "STREET": "ÇARŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "ARAPGİR",
        "STREET": "ÇAYBAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "ARAPGİR",
        "STREET": "ÇOBANLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "ARAPGİR",
        "STREET": "ÇİMEN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "ARAPGİR",
        "STREET": "ÇİĞNİR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "ARAPGİR",
        "STREET": "ŞAĞILUŞAĞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "ARAPGİR",
        "STREET": "ŞIHLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "ARGUVAN",
        "STREET": "AKÖREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "ARGUVAN",
        "STREET": "ALHASUŞAĞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "ARGUVAN",
        "STREET": "ARMUTLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "ARGUVAN",
        "STREET": "ASAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "ARGUVAN",
        "STREET": "ASMACA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "ARGUVAN",
        "STREET": "AŞAĞISÜLMENLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "ARGUVAN",
        "STREET": "BAHÇELİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "ARGUVAN",
        "STREET": "BAHÇELİEVLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "ARGUVAN",
        "STREET": "BOZAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "ARGUVAN",
        "STREET": "DOLAYLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "ARGUVAN",
        "STREET": "DOYDUM MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "ARGUVAN",
        "STREET": "ERMİŞLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "ARGUVAN",
        "STREET": "EYMİR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "ARGUVAN",
        "STREET": "GÖKAĞAÇ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "ARGUVAN",
        "STREET": "GÖÇERUŞAĞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "ARGUVAN",
        "STREET": "GÜMÜŞLÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "ARGUVAN",
        "STREET": "GÜNGÖREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "ARGUVAN",
        "STREET": "GÜRGE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "ARGUVAN",
        "STREET": "GÜVEÇLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "ARGUVAN",
        "STREET": "HAKVERDİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "ARGUVAN",
        "STREET": "KARABABA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "ARGUVAN",
        "STREET": "KARAHÜYÜK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "ARGUVAN",
        "STREET": "KIZIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "ARGUVAN",
        "STREET": "KIZILUŞAĞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "ARGUVAN",
        "STREET": "KONAKBAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "ARGUVAN",
        "STREET": "KOÇAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "ARGUVAN",
        "STREET": "KURUTTAŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "ARGUVAN",
        "STREET": "KUYUDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "ARGUVAN",
        "STREET": "KÖMÜRLÜK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "ARGUVAN",
        "STREET": "MORHAMAM MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "ARGUVAN",
        "STREET": "NARMİKAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "ARGUVAN",
        "STREET": "PARÇİKAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "ARGUVAN",
        "STREET": "TARLACIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "ARGUVAN",
        "STREET": "TATKINIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "ARGUVAN",
        "STREET": "TEPEBAĞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "ARGUVAN",
        "STREET": "YAMAÇ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "ARGUVAN",
        "STREET": "YENİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "ARGUVAN",
        "STREET": "YENİKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "ARGUVAN",
        "STREET": "YONCALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "ARGUVAN",
        "STREET": "YUKARISÜLMENLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "ARGUVAN",
        "STREET": "YÜREKTAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "ARGUVAN",
        "STREET": "ÇAKMAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "ARGUVAN",
        "STREET": "ÇAVUŞKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "ARGUVAN",
        "STREET": "ÇAYIRLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "ARGUVAN",
        "STREET": "ÇEVRELİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "ARGUVAN",
        "STREET": "ÇİFTLİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "ARGUVAN",
        "STREET": "İSAKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "ARGUVAN",
        "STREET": "İÇMECE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "ARGUVAN",
        "STREET": "ŞOTİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "BATTALGAZİ",
        "STREET": "ADAGÖREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "BATTALGAZİ",
        "STREET": "AKPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "BATTALGAZİ",
        "STREET": "ALACAKAPI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "BATTALGAZİ",
        "STREET": "ALHANUŞAĞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "BATTALGAZİ",
        "STREET": "ALİŞAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "BATTALGAZİ",
        "STREET": "ASLANBEY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "BATTALGAZİ",
        "STREET": "ATAKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "BATTALGAZİ",
        "STREET": "AĞILYAZI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "BATTALGAZİ",
        "STREET": "B.HÜSEYİNBEY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "BATTALGAZİ",
        "STREET": "B.MUSTAFA PAŞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "BATTALGAZİ",
        "STREET": "BAHRİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "BATTALGAZİ",
        "STREET": "BAHÇELİEVLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "BATTALGAZİ",
        "STREET": "BATTALGAZİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "BATTALGAZİ",
        "STREET": "BAĞTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "BATTALGAZİ",
        "STREET": "BAŞHARIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "BATTALGAZİ",
        "STREET": "BEYDAĞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "BATTALGAZİ",
        "STREET": "BEYLERBAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "BATTALGAZİ",
        "STREET": "BORAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "BATTALGAZİ",
        "STREET": "BULGURLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "BATTALGAZİ",
        "STREET": "BULUTLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "BATTALGAZİ",
        "STREET": "CEVHERİZADE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "BATTALGAZİ",
        "STREET": "CİRİKPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "BATTALGAZİ",
        "STREET": "DABAKHANE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "BATTALGAZİ",
        "STREET": "DOLAMANTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "BATTALGAZİ",
        "STREET": "DÜZYOL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "BATTALGAZİ",
        "STREET": "FERHADİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "BATTALGAZİ",
        "STREET": "FIRAT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "BATTALGAZİ",
        "STREET": "FIRINCI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "BATTALGAZİ",
        "STREET": "GÖLLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "BATTALGAZİ",
        "STREET": "GÖZTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "BATTALGAZİ",
        "STREET": "GÜLÜMUŞAĞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "BATTALGAZİ",
        "STREET": "HACI ABDİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "BATTALGAZİ",
        "STREET": "HACIHALİLOĞLUÇİFTLİĞİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "BATTALGAZİ",
        "STREET": "HACIYUSUFLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "BATTALGAZİ",
        "STREET": "HALFETTİN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "BATTALGAZİ",
        "STREET": "HAMİDİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "BATTALGAZİ",
        "STREET": "HANIMINÇİFTLİĞİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "BATTALGAZİ",
        "STREET": "HASAN VAROL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "BATTALGAZİ",
        "STREET": "HASIRCILAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "BATTALGAZİ",
        "STREET": "HATUNSUYU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "BATTALGAZİ",
        "STREET": "HİDAYET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "BATTALGAZİ",
        "STREET": "HİSARTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "BATTALGAZİ",
        "STREET": "K.HÜSEYİNBEY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "BATTALGAZİ",
        "STREET": "K.MUSTAFA PAŞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "BATTALGAZİ",
        "STREET": "KADIÇAYIRI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "BATTALGAZİ",
        "STREET": "KAMIŞTAŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "BATTALGAZİ",
        "STREET": "KAPIKAYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "BATTALGAZİ",
        "STREET": "KARABAĞLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "BATTALGAZİ",
        "STREET": "KARAGÖZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "BATTALGAZİ",
        "STREET": "KARAHAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "BATTALGAZİ",
        "STREET": "KARAKAŞÇİFTLİĞİKÖYÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "BATTALGAZİ",
        "STREET": "KARAKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "BATTALGAZİ",
        "STREET": "KARATEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "BATTALGAZİ",
        "STREET": "KAVAKLIBAĞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "BATTALGAZİ",
        "STREET": "KEMERKÖPRÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "BATTALGAZİ",
        "STREET": "KERNEK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "BATTALGAZİ",
        "STREET": "KIRAÇ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "BATTALGAZİ",
        "STREET": "KIRÇUVAL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "BATTALGAZİ",
        "STREET": "KULUŞAĞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "BATTALGAZİ",
        "STREET": "MERDİVENLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "BATTALGAZİ",
        "STREET": "MERKEZ BEYDAĞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "BATTALGAZİ",
        "STREET": "MEYDANBAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "BATTALGAZİ",
        "STREET": "MEYDANCIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "BATTALGAZİ",
        "STREET": "NURİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "BATTALGAZİ",
        "STREET": "NİYAZİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "BATTALGAZİ",
        "STREET": "ORDUZU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "BATTALGAZİ",
        "STREET": "PAŞAKÖŞKÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "BATTALGAZİ",
        "STREET": "PELİTLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "BATTALGAZİ",
        "STREET": "SANCAKTAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "BATTALGAZİ",
        "STREET": "SARAY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "BATTALGAZİ",
        "STREET": "SARICIOĞLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "BATTALGAZİ",
        "STREET": "SELÇUKLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "BATTALGAZİ",
        "STREET": "SERİNTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "BATTALGAZİ",
        "STREET": "SEVİLDAĞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "BATTALGAZİ",
        "STREET": "TANDOĞAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "BATTALGAZİ",
        "STREET": "TANIŞIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "BATTALGAZİ",
        "STREET": "TAŞTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "BATTALGAZİ",
        "STREET": "TOKLUCA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "BATTALGAZİ",
        "STREET": "TOPTAŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "BATTALGAZİ",
        "STREET": "TOYGAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "BATTALGAZİ",
        "STREET": "ULUKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "BATTALGAZİ",
        "STREET": "YAMAÇ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "BATTALGAZİ",
        "STREET": "YARIMCAHAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "BATTALGAZİ",
        "STREET": "YAYGIN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "BATTALGAZİ",
        "STREET": "YENİCE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "BATTALGAZİ",
        "STREET": "YENİHAMAM MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "BATTALGAZİ",
        "STREET": "YENİKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "BATTALGAZİ",
        "STREET": "YILDIZTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "BATTALGAZİ",
        "STREET": "ZAFER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "BATTALGAZİ",
        "STREET": "ÇAMURLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "BATTALGAZİ",
        "STREET": "ÇOLAKLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "BATTALGAZİ",
        "STREET": "ÇOLAKOĞLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "BATTALGAZİ",
        "STREET": "ÇÖŞNÜK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "BATTALGAZİ",
        "STREET": "ÜNİVERSİTE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "BATTALGAZİ",
        "STREET": "ÜZÜMLÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "BATTALGAZİ",
        "STREET": "ÜÇBAĞLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "BATTALGAZİ",
        "STREET": "İSKENDER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "BATTALGAZİ",
        "STREET": "İSMETİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "BATTALGAZİ",
        "STREET": "İSTİKLAL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "BATTALGAZİ",
        "STREET": "İZZETİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "BATTALGAZİ",
        "STREET": "ŞEHİTFEVZİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "BATTALGAZİ",
        "STREET": "ŞIKŞIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "BATTALGAZİ",
        "STREET": "ŞİFA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "BATTALGAZİ",
        "STREET": "ŞİŞMAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "DARENDE",
        "STREET": "AKBABA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "DARENDE",
        "STREET": "AKOVA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "DARENDE",
        "STREET": "AKÇATOPRAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "DARENDE",
        "STREET": "AYVALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "DARENDE",
        "STREET": "AĞILBAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "DARENDE",
        "STREET": "AĞILYAZI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "DARENDE",
        "STREET": "AŞAĞIULUPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "DARENDE",
        "STREET": "BALABAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "DARENDE",
        "STREET": "BARINDIR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "DARENDE",
        "STREET": "BAYTARBAĞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "DARENDE",
        "STREET": "BAŞKAYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "DARENDE",
        "STREET": "BEYBAĞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "DARENDE",
        "STREET": "GAZİKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "DARENDE",
        "STREET": "GÖKYAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "DARENDE",
        "STREET": "GÖKÇEÖREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "DARENDE",
        "STREET": "GÖLLÜCE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "DARENDE",
        "STREET": "GÜDÜL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "DARENDE",
        "STREET": "GÜNERLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "DARENDE",
        "STREET": "GÜNPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "DARENDE",
        "STREET": "HACIDERVİŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "DARENDE",
        "STREET": "HACILAR SIRAGÖZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "DARENDE",
        "STREET": "HACILAR ŞEYHLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "DARENDE",
        "STREET": "HACOLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "DARENDE",
        "STREET": "HEYİKETEĞİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "DARENDE",
        "STREET": "HİSARCIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "DARENDE",
        "STREET": "HİSARKALE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "DARENDE",
        "STREET": "ILICA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "DARENDE",
        "STREET": "IRMAKLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "DARENDE",
        "STREET": "KALDIRIM MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "DARENDE",
        "STREET": "KARABACAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "DARENDE",
        "STREET": "KARABAYIR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "DARENDE",
        "STREET": "KARAOĞUZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "DARENDE",
        "STREET": "KAVAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "DARENDE",
        "STREET": "KAYNAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "DARENDE",
        "STREET": "KERİMLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "DARENDE",
        "STREET": "KILIÇBAĞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "DARENDE",
        "STREET": "KURUDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "DARENDE",
        "STREET": "KUZPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "DARENDE",
        "STREET": "KÖLÜKLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "DARENDE",
        "STREET": "MEDİŞEYH MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "DARENDE",
        "STREET": "MEHMET PAŞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "DARENDE",
        "STREET": "MOLLAUŞAĞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "DARENDE",
        "STREET": "NADİR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "DARENDE",
        "STREET": "NURKUYUSU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "DARENDE",
        "STREET": "OZAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "DARENDE",
        "STREET": "PALANGA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "DARENDE",
        "STREET": "SAKARYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "DARENDE",
        "STREET": "SANDIKKAYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "DARENDE",
        "STREET": "SAYFİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "DARENDE",
        "STREET": "SUNGUR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "DARENDE",
        "STREET": "UZUNHASAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "DARENDE",
        "STREET": "YARIMCA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "DARENDE",
        "STREET": "YAVUZLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "DARENDE",
        "STREET": "YAZIKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "DARENDE",
        "STREET": "YENİCE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "DARENDE",
        "STREET": "YENİKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "DARENDE",
        "STREET": "YENİPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "DARENDE",
        "STREET": "YEŞİLTAŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "DARENDE",
        "STREET": "YUKARIULUPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "DARENDE",
        "STREET": "ZAVİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "DARENDE",
        "STREET": "ÇAYBAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "DARENDE",
        "STREET": "ÇINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "DARENDE",
        "STREET": "ÇUKURKAYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "DARENDE",
        "STREET": "ÜÇPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "DARENDE",
        "STREET": "İBRAHİMPAŞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "DARENDE",
        "STREET": "ŞENDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "DARENDE",
        "STREET": "ŞUĞUL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "DOĞANYOL",
        "STREET": "AKKENT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "DOĞANYOL",
        "STREET": "BEHRAMLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "DOĞANYOL",
        "STREET": "BURÇKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "DOĞANYOL",
        "STREET": "DAMLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "DOĞANYOL",
        "STREET": "GEVHERUŞAĞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "DOĞANYOL",
        "STREET": "GÖKÇE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "DOĞANYOL",
        "STREET": "GÜMÜŞSU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "DOĞANYOL",
        "STREET": "KOLDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "DOĞANYOL",
        "STREET": "KONURTAY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "DOĞANYOL",
        "STREET": "MEZRAA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "DOĞANYOL",
        "STREET": "POYRAZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "DOĞANYOL",
        "STREET": "ULUTAŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "DOĞANYOL",
        "STREET": "YALINCA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "DOĞANYOL",
        "STREET": "YEŞİLKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "DOĞANYOL",
        "STREET": "ÇOLAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "DOĞANYOL",
        "STREET": "İSAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "DOĞANŞEHİR",
        "STREET": "ALTINTOP MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "DOĞANŞEHİR",
        "STREET": "BEĞRE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "DOĞANŞEHİR",
        "STREET": "BIÇAKÇI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "DOĞANŞEHİR",
        "STREET": "DEDEYAZI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "DOĞANŞEHİR",
        "STREET": "DOĞU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "DOĞANŞEHİR",
        "STREET": "ELMALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "DOĞANŞEHİR",
        "STREET": "ERKENEK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "DOĞANŞEHİR",
        "STREET": "ESENTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "DOĞANŞEHİR",
        "STREET": "ESKİKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "DOĞANŞEHİR",
        "STREET": "FINDIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "DOĞANŞEHİR",
        "STREET": "GÖVDELİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "DOĞANŞEHİR",
        "STREET": "GÜNEDOĞRU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "DOĞANŞEHİR",
        "STREET": "GÜROBASI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "DOĞANŞEHİR",
        "STREET": "GÜZELKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "DOĞANŞEHİR",
        "STREET": "HUDUT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "DOĞANŞEHİR",
        "STREET": "KADILI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "DOĞANŞEHİR",
        "STREET": "KAPIDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "DOĞANŞEHİR",
        "STREET": "KARANLIKDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "DOĞANŞEHİR",
        "STREET": "KARATERZİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "DOĞANŞEHİR",
        "STREET": "KARŞIYAKA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "DOĞANŞEHİR",
        "STREET": "KELHALİL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "DOĞANŞEHİR",
        "STREET": "KURUCAOVA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "DOĞANŞEHİR",
        "STREET": "KÜÇÜKLÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "DOĞANŞEHİR",
        "STREET": "POLAT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "DOĞANŞEHİR",
        "STREET": "POLATDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "DOĞANŞEHİR",
        "STREET": "REŞADİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "DOĞANŞEHİR",
        "STREET": "SAVAKLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "DOĞANŞEHİR",
        "STREET": "SUÇATI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "DOĞANŞEHİR",
        "STREET": "SÖĞÜT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "DOĞANŞEHİR",
        "STREET": "SÜRGÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "DOĞANŞEHİR",
        "STREET": "TOPRAKTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "DOĞANŞEHİR",
        "STREET": "YENİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "DOĞANŞEHİR",
        "STREET": "YOLKORU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "DOĞANŞEHİR",
        "STREET": "YUVALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "DOĞANŞEHİR",
        "STREET": "ÇAVUŞLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "DOĞANŞEHİR",
        "STREET": "ÇIĞLIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "DOĞANŞEHİR",
        "STREET": "ÇÖMLEKOBA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "DOĞANŞEHİR",
        "STREET": "ÖRENCİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "DOĞANŞEHİR",
        "STREET": "ŞATIROBA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "HEKİMHAN",
        "STREET": "AKMAĞARA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "HEKİMHAN",
        "STREET": "AKSÜTLÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "HEKİMHAN",
        "STREET": "AŞAĞISAZLICA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "HEKİMHAN",
        "STREET": "BAHÇEDAMI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "HEKİMHAN",
        "STREET": "BAHÇELİEVLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "HEKİMHAN",
        "STREET": "BALLIKAYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "HEKİMHAN",
        "STREET": "BASAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "HEKİMHAN",
        "STREET": "BAĞYOLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "HEKİMHAN",
        "STREET": "BAŞKAVAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "HEKİMHAN",
        "STREET": "BAŞKINIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "HEKİMHAN",
        "STREET": "BEYKENT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "HEKİMHAN",
        "STREET": "BOĞAZGÖREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "HEKİMHAN",
        "STREET": "BUDAKLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "HEKİMHAN",
        "STREET": "DAVULGU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "HEKİMHAN",
        "STREET": "DELİHASANYURDU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "HEKİMHAN",
        "STREET": "DEREKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "HEKİMHAN",
        "STREET": "DEVECİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "HEKİMHAN",
        "STREET": "DUMLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "HEKİMHAN",
        "STREET": "DURSUNLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "HEKİMHAN",
        "STREET": "DİKENLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "HEKİMHAN",
        "STREET": "DİKİLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "HEKİMHAN",
        "STREET": "FATİH MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "HEKİMHAN",
        "STREET": "GÜVENÇ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "HEKİMHAN",
        "STREET": "GÜZELYAYLA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "HEKİMHAN",
        "STREET": "GÜZELYURT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "HEKİMHAN",
        "STREET": "GÜÇLÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "HEKİMHAN",
        "STREET": "GİRMANA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "HEKİMHAN",
        "STREET": "HACILAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "HEKİMHAN",
        "STREET": "HASANÇELEBİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "HEKİMHAN",
        "STREET": "HAYDAROĞLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "HEKİMHAN",
        "STREET": "IŞIKLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "HEKİMHAN",
        "STREET": "KARADERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "HEKİMHAN",
        "STREET": "KARAKÖÇEK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "HEKİMHAN",
        "STREET": "KARAPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "HEKİMHAN",
        "STREET": "KARSLILAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "HEKİMHAN",
        "STREET": "KARŞIYAKA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "HEKİMHAN",
        "STREET": "KAVACIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "HEKİMHAN",
        "STREET": "KOCAÖZÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "HEKİMHAN",
        "STREET": "KOZDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "HEKİMHAN",
        "STREET": "KURŞUNLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "HEKİMHAN",
        "STREET": "KÖPRÜLÜ MEHMET PAŞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "HEKİMHAN",
        "STREET": "KÖYLÜKÖYÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "HEKİMHAN",
        "STREET": "MOLLAİBRAHİM MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "HEKİMHAN",
        "STREET": "MİMAR SİNAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "HEKİMHAN",
        "STREET": "SALICIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "HEKİMHAN",
        "STREET": "SARAYLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "HEKİMHAN",
        "STREET": "SARIKIZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "HEKİMHAN",
        "STREET": "SAZLICA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "HEKİMHAN",
        "STREET": "SÖĞÜT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "HEKİMHAN",
        "STREET": "TAŞBAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "HEKİMHAN",
        "STREET": "TAŞOLUK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "HEKİMHAN",
        "STREET": "TURGUT ÖZAL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "HEKİMHAN",
        "STREET": "UĞURLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "HEKİMHAN",
        "STREET": "YAYLADAM MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "HEKİMHAN",
        "STREET": "YAĞCA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "HEKİMHAN",
        "STREET": "YENİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "HEKİMHAN",
        "STREET": "YEŞİLKALE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "HEKİMHAN",
        "STREET": "YEŞİLKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "HEKİMHAN",
        "STREET": "YEŞİLPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "HEKİMHAN",
        "STREET": "YUKARISELİMLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "HEKİMHAN",
        "STREET": "ÇANAKPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "HEKİMHAN",
        "STREET": "ÇULHALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "HEKİMHAN",
        "STREET": "ÇİMENLİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "HEKİMHAN",
        "STREET": "İĞDİR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "HEKİMHAN",
        "STREET": "ŞEHİT MEHMET FETHİ AKYÜZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "KALE",
        "STREET": "ABUŞOĞLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "KALE",
        "STREET": "AKUŞAĞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "KALE",
        "STREET": "AKÇA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "KALE",
        "STREET": "BAĞLICA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "KALE",
        "STREET": "BENTKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "KALE",
        "STREET": "DARIPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "KALE",
        "STREET": "DEDEKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "KALE",
        "STREET": "DÜZTARLA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "KALE",
        "STREET": "ERDEMLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "KALE",
        "STREET": "GÜLENKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "KALE",
        "STREET": "GÜNEYCE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "KALE",
        "STREET": "KALEKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "KALE",
        "STREET": "KARAAĞAÇ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "KALE",
        "STREET": "KARAHÜSEYİN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "KALE",
        "STREET": "KIYICAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "KALE",
        "STREET": "KOZLUK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "KALE",
        "STREET": "KUMLUYAZI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "KALE",
        "STREET": "MAHMUT DURSUN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "KALE",
        "STREET": "SALKIMLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "KALE",
        "STREET": "SARIOT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "KALE",
        "STREET": "SOĞUKPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "KALE",
        "STREET": "TEPEBAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "KALE",
        "STREET": "UYANIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "KALE",
        "STREET": "UZUNHÜSEYİN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "KALE",
        "STREET": "YENİDAMLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "KALE",
        "STREET": "ÇANAKÇI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "KALE",
        "STREET": "ÜÇDEĞİRMEN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "KALE",
        "STREET": "İKİZPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "KULUNCAK",
        "STREET": "ALVAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "KULUNCAK",
        "STREET": "AŞAĞISELİMLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "KULUNCAK",
        "STREET": "BAHÇELİEVLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "KULUNCAK",
        "STREET": "BAŞÖREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "KULUNCAK",
        "STREET": "BIYIKBOĞAZI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "KULUNCAK",
        "STREET": "BOĞAZİÇİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "KULUNCAK",
        "STREET": "BİCİR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "KULUNCAK",
        "STREET": "CİRİTBELEN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "KULUNCAK",
        "STREET": "GÖĞEBAKAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "KULUNCAK",
        "STREET": "KARABÜK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "KULUNCAK",
        "STREET": "KARAÇAYIR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "KULUNCAK",
        "STREET": "KARINCALIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "KULUNCAK",
        "STREET": "KARLIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "KULUNCAK",
        "STREET": "KAYNARCA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "KULUNCAK",
        "STREET": "KIZILHİSAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "KULUNCAK",
        "STREET": "KIZILMAĞARA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "KULUNCAK",
        "STREET": "KONAKTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "KULUNCAK",
        "STREET": "KÖMÜKLÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "KULUNCAK",
        "STREET": "ORTAPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "KULUNCAK",
        "STREET": "SOFULAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "KULUNCAK",
        "STREET": "SULTANLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "KULUNCAK",
        "STREET": "TEMÜKLÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "KULUNCAK",
        "STREET": "YENİ  MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "KULUNCAK",
        "STREET": "ÇAYKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "KULUNCAK",
        "STREET": "ÇÖRMÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "KULUNCAK",
        "STREET": "İLİSULUK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "KULUNCAK",
        "STREET": "İSMETPAŞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "KULUNCAK",
        "STREET": "İSTİKLAL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "PÜTÜRGE",
        "STREET": "AKTARLA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "PÜTÜRGE",
        "STREET": "ALİHAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "PÜTÜRGE",
        "STREET": "ALİÇERİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "PÜTÜRGE",
        "STREET": "ARINLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "PÜTÜRGE",
        "STREET": "ARITOPRAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "PÜTÜRGE",
        "STREET": "ARSLANKENT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "PÜTÜRGE",
        "STREET": "AĞALAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "PÜTÜRGE",
        "STREET": "BAKIMLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "PÜTÜRGE",
        "STREET": "BALPINARI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "PÜTÜRGE",
        "STREET": "BAYIRKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "PÜTÜRGE",
        "STREET": "BAŞMEZRA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "PÜTÜRGE",
        "STREET": "BELENKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "PÜTÜRGE",
        "STREET": "BÖLÜKKAYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "PÜTÜRGE",
        "STREET": "BÖLÜNMEZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "PÜTÜRGE",
        "STREET": "BÜYÜKÖZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "PÜTÜRGE",
        "STREET": "DEREDÜZÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "PÜTÜRGE",
        "STREET": "ERDEMLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "PÜTÜRGE",
        "STREET": "ERSELE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "PÜTÜRGE",
        "STREET": "ESENCİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "PÜTÜRGE",
        "STREET": "ESENLİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "PÜTÜRGE",
        "STREET": "GERTAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "PÜTÜRGE",
        "STREET": "GÖZLÜCE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "PÜTÜRGE",
        "STREET": "GÜNDEĞER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "PÜTÜRGE",
        "STREET": "GÜNDÜZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "PÜTÜRGE",
        "STREET": "HATİP MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "PÜTÜRGE",
        "STREET": "KARAKAYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "PÜTÜRGE",
        "STREET": "KARŞIYAKA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "PÜTÜRGE",
        "STREET": "KAVAKLIDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "PÜTÜRGE",
        "STREET": "KAYADERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "PÜTÜRGE",
        "STREET": "KORUCAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "PÜTÜRGE",
        "STREET": "KOZLUK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "PÜTÜRGE",
        "STREET": "KOÇKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "PÜTÜRGE",
        "STREET": "KÖKLÜKAYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "PÜTÜRGE",
        "STREET": "KÖRME MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "PÜTÜRGE",
        "STREET": "KÖYLÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "PÜTÜRGE",
        "STREET": "MEŞEDİBİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "PÜTÜRGE",
        "STREET": "NOHUTLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "PÜTÜRGE",
        "STREET": "ORMANİÇİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "PÜTÜRGE",
        "STREET": "PAZARCIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "PÜTÜRGE",
        "STREET": "POSKIRAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "PÜTÜRGE",
        "STREET": "SAHİLKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "PÜTÜRGE",
        "STREET": "SÖĞÜTLÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "PÜTÜRGE",
        "STREET": "TATLICAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "PÜTÜRGE",
        "STREET": "TAŞBAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "PÜTÜRGE",
        "STREET": "TAŞMIŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "PÜTÜRGE",
        "STREET": "TAŞTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "PÜTÜRGE",
        "STREET": "TEKEDERESİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "PÜTÜRGE",
        "STREET": "TELUŞAĞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "PÜTÜRGE",
        "STREET": "TEPEHAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "PÜTÜRGE",
        "STREET": "ULUTAŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "PÜTÜRGE",
        "STREET": "UZUNKORU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "PÜTÜRGE",
        "STREET": "UZUNTAŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "PÜTÜRGE",
        "STREET": "YAMAÇ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "PÜTÜRGE",
        "STREET": "YANDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "PÜTÜRGE",
        "STREET": "YAZICA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "PÜTÜRGE",
        "STREET": "YEDİYOL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "PÜTÜRGE",
        "STREET": "YEŞİLDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "PÜTÜRGE",
        "STREET": "ÇAMLIDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "PÜTÜRGE",
        "STREET": "ÇAYKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "PÜTÜRGE",
        "STREET": "ÇENGELLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "PÜTÜRGE",
        "STREET": "ÇIĞIRLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "PÜTÜRGE",
        "STREET": "ÇUKUROYMAĞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "PÜTÜRGE",
        "STREET": "ÖRENCİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "PÜTÜRGE",
        "STREET": "ÖRMELİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "PÜTÜRGE",
        "STREET": "ÖRNEKKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "PÜTÜRGE",
        "STREET": "ÜÇYAKA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "PÜTÜRGE",
        "STREET": "ŞÜKAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "PÜTÜRGE",
        "STREET": "ŞİRO TARAKSU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "YAZIHAN",
        "STREET": "AKYAZI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "YAZIHAN",
        "STREET": "ALİCAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "YAZIHAN",
        "STREET": "AMBARCIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "YAZIHAN",
        "STREET": "BAHÇELİEVLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "YAZIHAN",
        "STREET": "BALABAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "YAZIHAN",
        "STREET": "BEREKETLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "YAZIHAN",
        "STREET": "BOYACA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "YAZIHAN",
        "STREET": "BOZTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "YAZIHAN",
        "STREET": "BUZLUK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "YAZIHAN",
        "STREET": "BÖĞÜRTLEN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "YAZIHAN",
        "STREET": "DEDEKARGIN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "YAZIHAN",
        "STREET": "DOĞUŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "YAZIHAN",
        "STREET": "DURUCASU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "YAZIHAN",
        "STREET": "EPREME MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "YAZIHAN",
        "STREET": "ERECEK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "YAZIHAN",
        "STREET": "EĞRİBÜK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "YAZIHAN",
        "STREET": "FETHİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "YAZIHAN",
        "STREET": "GAYRET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "YAZIHAN",
        "STREET": "GÖVÜK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "YAZIHAN",
        "STREET": "HAMİDİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "YAZIHAN",
        "STREET": "KARACA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "YAZIHAN",
        "STREET": "KOŞAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "YAZIHAN",
        "STREET": "KÖMÜŞHAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "YAZIHAN",
        "STREET": "MISIRDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "YAZIHAN",
        "STREET": "SADIKLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "YAZIHAN",
        "STREET": "SÜRÜR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "YAZIHAN",
        "STREET": "SİNANLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "YAZIHAN",
        "STREET": "TAHTALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "YAZIHAN",
        "STREET": "TECİRLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "YAZIHAN",
        "STREET": "YENİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "YAZIHAN",
        "STREET": "ÇAVUŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "YAZIHAN",
        "STREET": "ÇİVRİL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "YAZIHAN",
        "STREET": "İRİAĞAÇ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "YEŞİLYURT",
        "STREET": "ABDULGAFFAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "YEŞİLYURT",
        "STREET": "ATALAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "YEŞİLYURT",
        "STREET": "ATATÜRK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "YEŞİLYURT",
        "STREET": "AŞAĞI BAĞLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "YEŞİLYURT",
        "STREET": "AŞAĞIKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "YEŞİLYURT",
        "STREET": "BANAZI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "YEŞİLYURT",
        "STREET": "BENTBAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "YEŞİLYURT",
        "STREET": "BOSTANBAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "YEŞİLYURT",
        "STREET": "BİNDAL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "YEŞİLYURT",
        "STREET": "CAFANA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "YEŞİLYURT",
        "STREET": "CEMALGÜRSEL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "YEŞİLYURT",
        "STREET": "CEVATPAŞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "YEŞİLYURT",
        "STREET": "CUMHURİYET (YEŞİLYURT) MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "YEŞİLYURT",
        "STREET": "CUMHURİYET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "YEŞİLYURT",
        "STREET": "DURANLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "YEŞİLYURT",
        "STREET": "DURULDU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "YEŞİLYURT",
        "STREET": "DİLEK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "YEŞİLYURT",
        "STREET": "FATİH MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "YEŞİLYURT",
        "STREET": "GAZİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "YEŞİLYURT",
        "STREET": "GEDİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "YEŞİLYURT",
        "STREET": "GÖKTARLA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "YEŞİLYURT",
        "STREET": "GÖZENE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "YEŞİLYURT",
        "STREET": "GÜNDÜZBEY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "YEŞİLYURT",
        "STREET": "HAMİDİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "YEŞİLYURT",
        "STREET": "HIROĞLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "YEŞİLYURT",
        "STREET": "HOCA AHMET YESEVİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "YEŞİLYURT",
        "STREET": "HORATA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "YEŞİLYURT",
        "STREET": "KADİRUŞAĞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "YEŞİLYURT",
        "STREET": "KARAHAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "YEŞİLYURT",
        "STREET": "KARAKAVAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "YEŞİLYURT",
        "STREET": "KAYNARCA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "YEŞİLYURT",
        "STREET": "KENDİRLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "YEŞİLYURT",
        "STREET": "KIRKPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "YEŞİLYURT",
        "STREET": "KIRLANGIÇ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "YEŞİLYURT",
        "STREET": "KONAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "YEŞİLYURT",
        "STREET": "KOYUNOĞLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "YEŞİLYURT",
        "STREET": "KOZLUK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "YEŞİLYURT",
        "STREET": "KOŞU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "YEŞİLYURT",
        "STREET": "KUYULU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "YEŞİLYURT",
        "STREET": "KUŞDOĞAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "YEŞİLYURT",
        "STREET": "KİLTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "YEŞİLYURT",
        "STREET": "MAHMUTLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "YEŞİLYURT",
        "STREET": "MELEKBABA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "YEŞİLYURT",
        "STREET": "MERKEZ FATİH MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "YEŞİLYURT",
        "STREET": "MULLAKASIM MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "YEŞİLYURT",
        "STREET": "OLUKLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "YEŞİLYURT",
        "STREET": "ORTAKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "YEŞİLYURT",
        "STREET": "SALKONAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "YEŞİLYURT",
        "STREET": "SALKÖPRÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "YEŞİLYURT",
        "STREET": "SAMANKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "YEŞİLYURT",
        "STREET": "SAMANLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "YEŞİLYURT",
        "STREET": "SEYRAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "YEŞİLYURT",
        "STREET": "SEYİTUŞAĞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "YEŞİLYURT",
        "STREET": "SULUKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "YEŞİLYURT",
        "STREET": "SÜTLÜCE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "YEŞİLYURT",
        "STREET": "TECDE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "YEŞİLYURT",
        "STREET": "TEPEKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "YEŞİLYURT",
        "STREET": "TOHMA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "YEŞİLYURT",
        "STREET": "TOPRAKTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "YEŞİLYURT",
        "STREET": "TOPSÖĞÜT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "YEŞİLYURT",
        "STREET": "TURGUT ÖZAL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "YEŞİLYURT",
        "STREET": "YAKA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "YEŞİLYURT",
        "STREET": "YAKINCA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "YEŞİLYURT",
        "STREET": "YALINKAYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "YEŞİLYURT",
        "STREET": "YAVUZ SELİM MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "YEŞİLYURT",
        "STREET": "YEŞİLKAYNAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "YEŞİLYURT",
        "STREET": "ZAVİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "YEŞİLYURT",
        "STREET": "ÇARMUZU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "YEŞİLYURT",
        "STREET": "ÇAVUŞOĞLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "YEŞİLYURT",
        "STREET": "ÇAYIRKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "YEŞİLYURT",
        "STREET": "ÇUKURDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "YEŞİLYURT",
        "STREET": "ÇİLESİZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "YEŞİLYURT",
        "STREET": "ÖNCÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "YEŞİLYURT",
        "STREET": "ÖZAL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "YEŞİLYURT",
        "STREET": "ÖZALPER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "YEŞİLYURT",
        "STREET": "ÜÇGÖZE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "YEŞİLYURT",
        "STREET": "İKİZCE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "YEŞİLYURT",
        "STREET": "İLYAS MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "YEŞİLYURT",
        "STREET": "İNÖNÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "YEŞİLYURT",
        "STREET": "ŞAHNAHAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MALATYA",
        "DISTRICT": "YEŞİLYURT",
        "STREET": "ŞEYH BAYRAM MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "AHMETLİ",
        "STREET": "ALAHIDIR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "AHMETLİ",
        "STREET": "ALTIEYLÜL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "AHMETLİ",
        "STREET": "ATAKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "AHMETLİ",
        "STREET": "BAHÇECİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "AHMETLİ",
        "STREET": "BARBAROS MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "AHMETLİ",
        "STREET": "CANBAZLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "AHMETLİ",
        "STREET": "DEREKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "AHMETLİ",
        "STREET": "DERİCİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "AHMETLİ",
        "STREET": "DİBEKDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "AHMETLİ",
        "STREET": "GÖKKAYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "AHMETLİ",
        "STREET": "GÜLDEDE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "AHMETLİ",
        "STREET": "HACIKÖSELİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "AHMETLİ",
        "STREET": "HALİLKAHYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "AHMETLİ",
        "STREET": "KARAKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "AHMETLİ",
        "STREET": "KARGIN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "AHMETLİ",
        "STREET": "KENDİRLİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "AHMETLİ",
        "STREET": "KESTELLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "AHMETLİ",
        "STREET": "KURTULUŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "AHMETLİ",
        "STREET": "MANDALLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "AHMETLİ",
        "STREET": "SEYDİKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "AHMETLİ",
        "STREET": "ULU CAMİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "AHMETLİ",
        "STREET": "YARAŞLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "AHMETLİ",
        "STREET": "ZAFER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "AKHİSAR",
        "STREET": "AKHİSAR T TİPİ KAPALI CEZA İNFAZ KURUMU",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "AKHİSAR",
        "STREET": "AKKOCALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "AKHİSAR",
        "STREET": "AKSELENDİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "AKHİSAR",
        "STREET": "AKÇAALAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "AKHİSAR",
        "STREET": "AKÇEŞME MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "AKHİSAR",
        "STREET": "ARABACIBOZKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "AKHİSAR",
        "STREET": "ATATÜRK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "AKHİSAR",
        "STREET": "AŞAĞIDOLMA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "AKHİSAR",
        "STREET": "BALLICA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "AKHİSAR",
        "STREET": "BAŞLAMIŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "AKHİSAR",
        "STREET": "BEKİRLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "AKHİSAR",
        "STREET": "BEYOBA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "AKHİSAR",
        "STREET": "BOYALILAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "AKHİSAR",
        "STREET": "BÜKNÜŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "AKHİSAR",
        "STREET": "BÜNYANOSMANİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "AKHİSAR",
        "STREET": "CUMHURİYET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "AKHİSAR",
        "STREET": "DAYIOĞLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "AKHİSAR",
        "STREET": "DAĞDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "AKHİSAR",
        "STREET": "DEREKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "AKHİSAR",
        "STREET": "DOLMADEĞİRMEN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "AKHİSAR",
        "STREET": "DOĞANKAYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "AKHİSAR",
        "STREET": "DOĞUCA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "AKHİSAR",
        "STREET": "DURASIL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "AKHİSAR",
        "STREET": "DİNGİLLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "AKHİSAR",
        "STREET": "EFENDİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "AKHİSAR",
        "STREET": "ERDELLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "AKHİSAR",
        "STREET": "EROĞLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "AKHİSAR",
        "STREET": "EVKAFTEKE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "AKHİSAR",
        "STREET": "GÖCEK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "AKHİSAR",
        "STREET": "GÖKÇEAHMET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "AKHİSAR",
        "STREET": "GÖKÇELER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "AKHİSAR",
        "STREET": "HACI İSHAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "AKHİSAR",
        "STREET": "HACIOSMANLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "AKHİSAR",
        "STREET": "HACIİBRAHİMLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "AKHİSAR",
        "STREET": "HAMİDİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "AKHİSAR",
        "STREET": "HAMİTKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "AKHİSAR",
        "STREET": "HANPAŞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "AKHİSAR",
        "STREET": "HARMANDALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "AKHİSAR",
        "STREET": "HAS HOCA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "AKHİSAR",
        "STREET": "HASKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "AKHİSAR",
        "STREET": "HÜRRİYET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "AKHİSAR",
        "STREET": "IŞIKKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "AKHİSAR",
        "STREET": "KABAAĞAÇKIRAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "AKHİSAR",
        "STREET": "KADIDAĞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "AKHİSAR",
        "STREET": "KAPAKLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "AKHİSAR",
        "STREET": "KARABÖRKLÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "AKHİSAR",
        "STREET": "KARAKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "AKHİSAR",
        "STREET": "KARAYAĞCI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "AKHİSAR",
        "STREET": "KAVAKALAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "AKHİSAR",
        "STREET": "KAYALIOĞLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "AKHİSAR",
        "STREET": "KAYGANLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "AKHİSAR",
        "STREET": "KETHÜDA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "AKHİSAR",
        "STREET": "KIZLARALANI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "AKHİSAR",
        "STREET": "KOBAŞDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "AKHİSAR",
        "STREET": "KOCAKAĞAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "AKHİSAR",
        "STREET": "KULAKSIZLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "AKHİSAR",
        "STREET": "KURTULMUŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "AKHİSAR",
        "STREET": "KÖMÜRCÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "AKHİSAR",
        "STREET": "MECİDİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "AKHİSAR",
        "STREET": "MEDAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "AKHİSAR",
        "STREET": "MORALILAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "AKHİSAR",
        "STREET": "MUSACA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "AKHİSAR",
        "STREET": "MUSALAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "AKHİSAR",
        "STREET": "MUŞTULLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "AKHİSAR",
        "STREET": "PAŞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "AKHİSAR",
        "STREET": "PEKMEZCİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "AKHİSAR",
        "STREET": "PINARCIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "AKHİSAR",
        "STREET": "RAGIP BEY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "AKHİSAR",
        "STREET": "RAHMİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "AKHİSAR",
        "STREET": "REŞAT BEY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "AKHİSAR",
        "STREET": "SABANCILAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "AKHİSAR",
        "STREET": "SAKARKAYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "AKHİSAR",
        "STREET": "SARILAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "AKHİSAR",
        "STREET": "SARIÇALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "AKHİSAR",
        "STREET": "SARNIÇKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "AKHİSAR",
        "STREET": "SAZOBA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "AKHİSAR",
        "STREET": "SAĞRAKÇI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "AKHİSAR",
        "STREET": "SELVİLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "AKHİSAR",
        "STREET": "SELÇİKLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "AKHİSAR",
        "STREET": "SEYİT AHMET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "AKHİSAR",
        "STREET": "SEĞİRDİM MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "AKHİSAR",
        "STREET": "SIRTKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "AKHİSAR",
        "STREET": "SÖĞÜTLÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "AKHİSAR",
        "STREET": "SÜLEYMANKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "AKHİSAR",
        "STREET": "SÜLEYMANLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "AKHİSAR",
        "STREET": "SÜNNETÇİLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "AKHİSAR",
        "STREET": "SİNDELLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "AKHİSAR",
        "STREET": "TAŞÇILAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "AKHİSAR",
        "STREET": "TOPLUCA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "AKHİSAR",
        "STREET": "TÜTENLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "AKHİSAR",
        "STREET": "ULU CAMİİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "AKHİSAR",
        "STREET": "ULUPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "AKHİSAR",
        "STREET": "YATAĞAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "AKHİSAR",
        "STREET": "YAYAKIRILDIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "AKHİSAR",
        "STREET": "YAYKIN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "AKHİSAR",
        "STREET": "YENİCE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "AKHİSAR",
        "STREET": "YENİDOĞAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "AKHİSAR",
        "STREET": "YEĞENOBA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "AKHİSAR",
        "STREET": "ZEYTİNLİBAĞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "AKHİSAR",
        "STREET": "ZEYTİNLİOVA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "AKHİSAR",
        "STREET": "ÇAMÖNÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "AKHİSAR",
        "STREET": "ÇANAKÇI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "AKHİSAR",
        "STREET": "ÇITAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "AKHİSAR",
        "STREET": "ÇOBANHASAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "AKHİSAR",
        "STREET": "ÇORUK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "AKHİSAR",
        "STREET": "ÜÇAVLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "AKHİSAR",
        "STREET": "İNÖNÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "AKHİSAR",
        "STREET": "İSACA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "AKHİSAR",
        "STREET": "ŞEHİTLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "AKHİSAR",
        "STREET": "ŞEYHİSA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "ALAŞEHİR",
        "STREET": "AKKEÇİLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "ALAŞEHİR",
        "STREET": "ALHAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "ALAŞEHİR",
        "STREET": "AYDOĞDU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "ALAŞEHİR",
        "STREET": "AZITEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "ALAŞEHİR",
        "STREET": "BADINCA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "ALAŞEHİR",
        "STREET": "BAHADIR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "ALAŞEHİR",
        "STREET": "BAHÇEDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "ALAŞEHİR",
        "STREET": "BAHÇELİKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "ALAŞEHİR",
        "STREET": "BAKLACI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "ALAŞEHİR",
        "STREET": "BARIŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "ALAŞEHİR",
        "STREET": "BELENYAKA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "ALAŞEHİR",
        "STREET": "BEŞEYLÜL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "ALAŞEHİR",
        "STREET": "CABERBURHAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "ALAŞEHİR",
        "STREET": "CABERFAKILI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "ALAŞEHİR",
        "STREET": "CABERKAMARA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "ALAŞEHİR",
        "STREET": "DAĞARLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "ALAŞEHİR",
        "STREET": "DAĞHACIYUSUF MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "ALAŞEHİR",
        "STREET": "DELEMENLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "ALAŞEHİR",
        "STREET": "ERENKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "ALAŞEHİR",
        "STREET": "ESEN TEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "ALAŞEHİR",
        "STREET": "EVRENLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "ALAŞEHİR",
        "STREET": "FATİH MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "ALAŞEHİR",
        "STREET": "GÖBEKLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "ALAŞEHİR",
        "STREET": "GÜLENYAKA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "ALAŞEHİR",
        "STREET": "GÜLPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "ALAŞEHİR",
        "STREET": "GÜMÜŞÇAY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "ALAŞEHİR",
        "STREET": "GÜRSU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "ALAŞEHİR",
        "STREET": "GİRELLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "ALAŞEHİR",
        "STREET": "HACIALİLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "ALAŞEHİR",
        "STREET": "HACIBEY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "ALAŞEHİR",
        "STREET": "HORZUMALAYAKA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "ALAŞEHİR",
        "STREET": "HORZUMEMBELLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "ALAŞEHİR",
        "STREET": "HORZUMKESERLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "ALAŞEHİR",
        "STREET": "HORZUMSAZDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "ALAŞEHİR",
        "STREET": "ILGIN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "ALAŞEHİR",
        "STREET": "ILICA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "ALAŞEHİR",
        "STREET": "IŞIKLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "ALAŞEHİR",
        "STREET": "KARACALAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "ALAŞEHİR",
        "STREET": "KARADAĞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "ALAŞEHİR",
        "STREET": "KASAPLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "ALAŞEHİR",
        "STREET": "KAVAKLIDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "ALAŞEHİR",
        "STREET": "KEMALİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "ALAŞEHİR",
        "STREET": "KESTANEDERESİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "ALAŞEHİR",
        "STREET": "KOZLUCA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "ALAŞEHİR",
        "STREET": "KURTULUŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "ALAŞEHİR",
        "STREET": "KURUDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "ALAŞEHİR",
        "STREET": "KİLLİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "ALAŞEHİR",
        "STREET": "MANİSA ALAŞEHİR M TİPİ CEZA İNFAZ KURUMU",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "ALAŞEHİR",
        "STREET": "MATARLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "ALAŞEHİR",
        "STREET": "MENDERES MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "ALAŞEHİR",
        "STREET": "NARLIDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "ALAŞEHİR",
        "STREET": "OSMANİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "ALAŞEHİR",
        "STREET": "PİYADELER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "ALAŞEHİR",
        "STREET": "SAKARYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "ALAŞEHİR",
        "STREET": "SARIPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "ALAŞEHİR",
        "STREET": "SARISU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "ALAŞEHİR",
        "STREET": "SELCE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "ALAŞEHİR",
        "STREET": "SERİNKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "ALAŞEHİR",
        "STREET": "SERİNYAYLA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "ALAŞEHİR",
        "STREET": "SOBRAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "ALAŞEHİR",
        "STREET": "SOĞANLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "ALAŞEHİR",
        "STREET": "SOĞUKSU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "ALAŞEHİR",
        "STREET": "SOĞUKYURT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "ALAŞEHİR",
        "STREET": "SUBAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "ALAŞEHİR",
        "STREET": "TEPEKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "ALAŞEHİR",
        "STREET": "TOYGAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "ALAŞEHİR",
        "STREET": "TÜRKMEN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "ALAŞEHİR",
        "STREET": "ULUDERBENT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "ALAŞEHİR",
        "STREET": "YENİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "ALAŞEHİR",
        "STREET": "YENİCE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "ALAŞEHİR",
        "STREET": "YENİKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "ALAŞEHİR",
        "STREET": "YEŞİLYURT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "ALAŞEHİR",
        "STREET": "YUVACALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "ALAŞEHİR",
        "STREET": "ÇAKIRCAALİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "ALAŞEHİR",
        "STREET": "ÇAMLIBEL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "ALAŞEHİR",
        "STREET": "ÇARIKBOZDAĞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "ALAŞEHİR",
        "STREET": "ÇARIKKARALAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "ALAŞEHİR",
        "STREET": "ÇARIKTEKKE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "ALAŞEHİR",
        "STREET": "ÇAĞLAYAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "ALAŞEHİR",
        "STREET": "ÇEŞNELİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "ALAŞEHİR",
        "STREET": "ÖRENCİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "ALAŞEHİR",
        "STREET": "ÖRNEKKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "ALAŞEHİR",
        "STREET": "ÜZÜMLÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "ALAŞEHİR",
        "STREET": "İSMAİLBEY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "ALAŞEHİR",
        "STREET": "İSMETİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "ALAŞEHİR",
        "STREET": "İSTASYON MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "ALAŞEHİR",
        "STREET": "ŞAHYAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "ALAŞEHİR",
        "STREET": "ŞEYH SİNAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "DEMİRCİ",
        "STREET": "AHATLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "DEMİRCİ",
        "STREET": "AHMETLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "DEMİRCİ",
        "STREET": "AKDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "DEMİRCİ",
        "STREET": "AKINCILAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "DEMİRCİ",
        "STREET": "ALAAĞAÇ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "DEMİRCİ",
        "STREET": "ARMUTLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "DEMİRCİ",
        "STREET": "AYVAALAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "DEMİRCİ",
        "STREET": "AZİZBEY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "DEMİRCİ",
        "STREET": "BAHÇELER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "DEMİRCİ",
        "STREET": "BARDAKÇI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "DEMİRCİ",
        "STREET": "BAYRAMŞAH MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "DEMİRCİ",
        "STREET": "BEYAZIT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "DEMİRCİ",
        "STREET": "BOYACIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "DEMİRCİ",
        "STREET": "BOZKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "DEMİRCİ",
        "STREET": "BOZÇATLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "DEMİRCİ",
        "STREET": "BÜYÜKKIRAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "DEMİRCİ",
        "STREET": "CAMİİ ATİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "DEMİRCİ",
        "STREET": "CUMHURİYET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "DEMİRCİ",
        "STREET": "DANİŞMENTLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "DEMİRCİ",
        "STREET": "DEMİRCİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "DEMİRCİ",
        "STREET": "DOKTOR MEHMET AKARSU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "DEMİRCİ",
        "STREET": "DURHASAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "DEMİRCİ",
        "STREET": "ELEK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "DEMİRCİ",
        "STREET": "ESENYURT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "DEMİRCİ",
        "STREET": "ESKİHİSAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "DEMİRCİ",
        "STREET": "FATİH MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "DEMİRCİ",
        "STREET": "GÖMEÇLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "DEMİRCİ",
        "STREET": "GÜMELE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "DEMİRCİ",
        "STREET": "GÜRÇEŞME MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "DEMİRCİ",
        "STREET": "GÜVELİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "DEMİRCİ",
        "STREET": "HACIBABA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "DEMİRCİ",
        "STREET": "HACIHAMZA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "DEMİRCİ",
        "STREET": "HACIHASAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "DEMİRCİ",
        "STREET": "HACITÜRBEK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "DEMİRCİ",
        "STREET": "HIRKALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "DEMİRCİ",
        "STREET": "HOŞÇALAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "DEMİRCİ",
        "STREET": "HÜDÜK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "DEMİRCİ",
        "STREET": "IKLIKÇI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "DEMİRCİ",
        "STREET": "KARAİSALAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "DEMİRCİ",
        "STREET": "KARGINIŞIKLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "DEMİRCİ",
        "STREET": "KASIMFAKI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "DEMİRCİ",
        "STREET": "KAYAKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "DEMİRCİ",
        "STREET": "KAYRANOKÇULAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "DEMİRCİ",
        "STREET": "KAZANCI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "DEMİRCİ",
        "STREET": "KERPİÇLİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "DEMİRCİ",
        "STREET": "KILAVUZLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "DEMİRCİ",
        "STREET": "KIŞLAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "DEMİRCİ",
        "STREET": "KOVANCI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "DEMİRCİ",
        "STREET": "KULALAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "DEMİRCİ",
        "STREET": "KUZEYİR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "DEMİRCİ",
        "STREET": "KUZUKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "DEMİRCİ",
        "STREET": "KÖYLÜCE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "DEMİRCİ",
        "STREET": "KÜPELER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "DEMİRCİ",
        "STREET": "KÜÇÜKKIRAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "DEMİRCİ",
        "STREET": "KÜÇÜKOBA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "DEMİRCİ",
        "STREET": "MAHMUTLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "DEMİRCİ",
        "STREET": "MARMARACIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "DEMİRCİ",
        "STREET": "MEZİTLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "DEMİRCİ",
        "STREET": "MİNNETLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "DEMİRCİ",
        "STREET": "MİTHATPAŞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "DEMİRCİ",
        "STREET": "PAZAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "DEMİRCİ",
        "STREET": "RAHMANLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "DEMİRCİ",
        "STREET": "SAYIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "DEMİRCİ",
        "STREET": "SAĞNIÇ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "DEMİRCİ",
        "STREET": "SECAATTİN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "DEMİRCİ",
        "STREET": "SERÇELER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "DEMİRCİ",
        "STREET": "SEVİŞLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "DEMİRCİ",
        "STREET": "SOFULAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "DEMİRCİ",
        "STREET": "SÖĞÜTÇÜK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "DEMİRCİ",
        "STREET": "SİNAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "DEMİRCİ",
        "STREET": "TALAS MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "DEMİRCİ",
        "STREET": "TAŞOKÇULAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "DEMİRCİ",
        "STREET": "TEKELER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "DEMİRCİ",
        "STREET": "TEPERİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "DEMİRCİ",
        "STREET": "ULACIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "DEMİRCİ",
        "STREET": "YARBASAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "DEMİRCİ",
        "STREET": "YAVAŞLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "DEMİRCİ",
        "STREET": "YAĞMUR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "DEMİRCİ",
        "STREET": "YENİCE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "DEMİRCİ",
        "STREET": "YEŞİLDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "DEMİRCİ",
        "STREET": "YEŞİLOBA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "DEMİRCİ",
        "STREET": "YİĞENLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "DEMİRCİ",
        "STREET": "YİĞİTLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "DEMİRCİ",
        "STREET": "ÇAMKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "DEMİRCİ",
        "STREET": "ÇAMLICA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "DEMİRCİ",
        "STREET": "ÇANAKÇI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "DEMİRCİ",
        "STREET": "ÇANDIR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "DEMİRCİ",
        "STREET": "ÇARDAKLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "DEMİRCİ",
        "STREET": "ÇATALOLUK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "DEMİRCİ",
        "STREET": "ÇAĞILLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "DEMİRCİ",
        "STREET": "ÖKSÜZLÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "DEMİRCİ",
        "STREET": "ÖREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "DEMİRCİ",
        "STREET": "ÖRÜCÜLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "DEMİRCİ",
        "STREET": "ÜŞÜMÜŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "DEMİRCİ",
        "STREET": "İCİKLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "DEMİRCİ",
        "STREET": "İMCELER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "DEMİRCİ",
        "STREET": "İMRENLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "DEMİRCİ",
        "STREET": "İRİŞLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "DEMİRCİ",
        "STREET": "İSMAİLLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "DEMİRCİ",
        "STREET": "İÇHİSAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "DEMİRCİ",
        "STREET": "ŞEHREKÜSTÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "GÖLMARMARA",
        "STREET": "ATATÜRK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "GÖLMARMARA",
        "STREET": "AYANLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "GÖLMARMARA",
        "STREET": "BEYLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "GÖLMARMARA",
        "STREET": "DEĞNEKLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "GÖLMARMARA",
        "STREET": "ESKİ CAMİİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "GÖLMARMARA",
        "STREET": "HACIBAŞTANLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "GÖLMARMARA",
        "STREET": "HACIVELİLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "GÖLMARMARA",
        "STREET": "HIROĞLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "GÖLMARMARA",
        "STREET": "KAYAALTI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "GÖLMARMARA",
        "STREET": "KAYAPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "GÖLMARMARA",
        "STREET": "KILCANLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "GÖLMARMARA",
        "STREET": "OZANCA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "GÖLMARMARA",
        "STREET": "TAŞKUYUCAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "GÖLMARMARA",
        "STREET": "TİYENLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "GÖLMARMARA",
        "STREET": "YENİ CAMİİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "GÖLMARMARA",
        "STREET": "YENİKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "GÖLMARMARA",
        "STREET": "YUNUSLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "GÖLMARMARA",
        "STREET": "ÇAMKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "GÖLMARMARA",
        "STREET": "ÇÖMLEKÇİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "GÖLMARMARA",
        "STREET": "İHSANİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "GÖLMARMARA",
        "STREET": "İSMETPAŞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "GÖRDES",
        "STREET": "ADNAN MENDERES MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "GÖRDES",
        "STREET": "AKPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "GÖRDES",
        "STREET": "ATATÜRK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "GÖRDES",
        "STREET": "BALIKLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "GÖRDES",
        "STREET": "BAYAT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "GÖRDES",
        "STREET": "BENLİELİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "GÖRDES",
        "STREET": "BEĞEL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "GÖRDES",
        "STREET": "BEĞENLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "GÖRDES",
        "STREET": "BEŞEYLÜL  MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "GÖRDES",
        "STREET": "BOYALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "GÖRDES",
        "STREET": "BÖREZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "GÖRDES",
        "STREET": "CUMA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "GÖRDES",
        "STREET": "DALKARA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "GÖRDES",
        "STREET": "DARGIL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "GÖRDES",
        "STREET": "DELİÇOBAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "GÖRDES",
        "STREET": "DEREÇİFTLİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "GÖRDES",
        "STREET": "DOĞANPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "GÖRDES",
        "STREET": "DUTLUCA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "GÖRDES",
        "STREET": "DİKİLİTAŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "GÖRDES",
        "STREET": "DİVAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "GÖRDES",
        "STREET": "EFENDİLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "GÖRDES",
        "STREET": "EVCİLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "GÖRDES",
        "STREET": "FUNDACIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "GÖRDES",
        "STREET": "GÜLPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "GÖRDES",
        "STREET": "GÜNEŞLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "GÖRDES",
        "STREET": "HÜSEYNİ BABA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "GÖRDES",
        "STREET": "KABAKOZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "GÖRDES",
        "STREET": "KALEMOĞLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "GÖRDES",
        "STREET": "KARAAĞAÇ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "GÖRDES",
        "STREET": "KARAKEÇİLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "GÖRDES",
        "STREET": "KARAYAKUP MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "GÖRDES",
        "STREET": "KARAYAĞCI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "GÖRDES",
        "STREET": "KAYACIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "GÖRDES",
        "STREET": "KAŞIKÇI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "GÖRDES",
        "STREET": "KILCANLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "GÖRDES",
        "STREET": "KIRANKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "GÖRDES",
        "STREET": "KIYMIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "GÖRDES",
        "STREET": "KIZILDAM MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "GÖRDES",
        "STREET": "KOBAKLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "GÖRDES",
        "STREET": "KORUBAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "GÖRDES",
        "STREET": "KUYUCAKKARAPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "GÖRDES",
        "STREET": "KUŞLUKKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "GÖRDES",
        "STREET": "KÖSELER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "GÖRDES",
        "STREET": "KÜREKÇİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "GÖRDES",
        "STREET": "MALAZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "GÖRDES",
        "STREET": "MALKOCA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "GÖRDES",
        "STREET": "NAKIPAĞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "GÖRDES",
        "STREET": "OĞULDURUK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "GÖRDES",
        "STREET": "PINARBAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "GÖRDES",
        "STREET": "SALUR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "GÖRDES",
        "STREET": "SARIALİLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "GÖRDES",
        "STREET": "TEPEKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "GÖRDES",
        "STREET": "TÜPÜLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "GÖRDES",
        "STREET": "ULGAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "GÖRDES",
        "STREET": "UZUNÇAM MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "GÖRDES",
        "STREET": "YAKAKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "GÖRDES",
        "STREET": "YENİKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "GÖRDES",
        "STREET": "YEŞİLYURT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "GÖRDES",
        "STREET": "ÇATALARMUT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "GÖRDES",
        "STREET": "ÇAĞLAYAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "GÖRDES",
        "STREET": "ÇİÇEKLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "GÖRDES",
        "STREET": "ÇİĞİLLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "GÖRDES",
        "STREET": "ŞAHİNKAYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "GÖRDES",
        "STREET": "ŞEYHYAYLA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "KIRKAĞAÇ",
        "STREET": "ALACALAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "KIRKAĞAÇ",
        "STREET": "ALİFAKI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "KIRKAĞAÇ",
        "STREET": "BADEMLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "KIRKAĞAÇ",
        "STREET": "BAKIR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "KIRKAĞAÇ",
        "STREET": "BODUROĞLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "KIRKAĞAÇ",
        "STREET": "BOSTANCI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "KIRKAĞAÇ",
        "STREET": "CİNOSMAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "KIRKAĞAÇ",
        "STREET": "DEMİRTAŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "KIRKAĞAÇ",
        "STREET": "DUALAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "KIRKAĞAÇ",
        "STREET": "FIRDANLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "KIRKAĞAÇ",
        "STREET": "GEBELER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "KIRKAĞAÇ",
        "STREET": "GELENBE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "KIRKAĞAÇ",
        "STREET": "GÖKÇUKUR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "KIRKAĞAÇ",
        "STREET": "GÜVENDİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "KIRKAĞAÇ",
        "STREET": "HACET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "KIRKAĞAÇ",
        "STREET": "HACIHİMMET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "KIRKAĞAÇ",
        "STREET": "HALKAHAVLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "KIRKAĞAÇ",
        "STREET": "HAMİDİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "KIRKAĞAÇ",
        "STREET": "HAMİTLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "KIRKAĞAÇ",
        "STREET": "HIDIRAĞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "KIRKAĞAÇ",
        "STREET": "IŞIKLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "KIRKAĞAÇ",
        "STREET": "KADRİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "KIRKAĞAÇ",
        "STREET": "KARAALİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "KIRKAĞAÇ",
        "STREET": "KARAKURT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "KIRKAĞAÇ",
        "STREET": "KAYADİBİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "KIRKAĞAÇ",
        "STREET": "KINIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "KIRKAĞAÇ",
        "STREET": "KOCAMEHMETAĞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "KIRKAĞAÇ",
        "STREET": "KOCAİSKAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "KIRKAĞAÇ",
        "STREET": "KUYUCAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "KIRKAĞAÇ",
        "STREET": "KÜÇÜKYAYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "KIRKAĞAÇ",
        "STREET": "MEMİŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "KIRKAĞAÇ",
        "STREET": "MUSAHOCA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "KIRKAĞAÇ",
        "STREET": "MUSALAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "KIRKAĞAÇ",
        "STREET": "SAKARLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "KIRKAĞAÇ",
        "STREET": "SARIAĞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "KIRKAĞAÇ",
        "STREET": "SÖĞÜTALAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "KIRKAĞAÇ",
        "STREET": "SİLEDİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "KIRKAĞAÇ",
        "STREET": "TEVFİKİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "KIRKAĞAÇ",
        "STREET": "YAĞMURLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "KIRKAĞAÇ",
        "STREET": "YENİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "KIRKAĞAÇ",
        "STREET": "ZORAĞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "KIRKAĞAÇ",
        "STREET": "ÇALTICAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "KIRKAĞAÇ",
        "STREET": "ÇOBANLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "KIRKAĞAÇ",
        "STREET": "ÇİFTLİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "KIRKAĞAÇ",
        "STREET": "ÖVEÇLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "KIRKAĞAÇ",
        "STREET": "İLYASLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "KIRKAĞAÇ",
        "STREET": "ŞAİR EŞREF MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "KULA",
        "STREET": "4 EYLÜL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "KULA",
        "STREET": "AHMETLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "KULA",
        "STREET": "AKGÜN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "KULA",
        "STREET": "AKTAŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "KULA",
        "STREET": "AYAZÖREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "KULA",
        "STREET": "AYVATLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "KULA",
        "STREET": "BALIBEY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "KULA",
        "STREET": "BATTALMUSTAFA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "KULA",
        "STREET": "BAYRAMŞAH MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "KULA",
        "STREET": "BAŞIBÜYÜK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "KULA",
        "STREET": "BEBEKLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "KULA",
        "STREET": "BEY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "KULA",
        "STREET": "BÖRTLÜCE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "KULA",
        "STREET": "CAMİİ CEDİT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "KULA",
        "STREET": "DEREKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "KULA",
        "STREET": "EMRE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "KULA",
        "STREET": "ENCEKLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "KULA",
        "STREET": "ERENBAĞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "KULA",
        "STREET": "EROĞLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "KULA",
        "STREET": "ESENYAZI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "KULA",
        "STREET": "EVCİLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "KULA",
        "STREET": "GÖKDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "KULA",
        "STREET": "GÖKÇEÖREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "KULA",
        "STREET": "GÖLBAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "KULA",
        "STREET": "GÜVERCİNLİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "KULA",
        "STREET": "HACITUFAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "KULA",
        "STREET": "HAMİDİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "KULA",
        "STREET": "HAYALLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "KULA",
        "STREET": "KALINHARMAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "KULA",
        "STREET": "KARAOBA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "KULA",
        "STREET": "KAVACIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "KULA",
        "STREET": "KENGER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "KULA",
        "STREET": "KIZILKAYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "KULA",
        "STREET": "KONURCA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "KULA",
        "STREET": "KÖREZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "KULA",
        "STREET": "NARINCALIPITRAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "KULA",
        "STREET": "NARINCALISÜLEYMAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "KULA",
        "STREET": "ORTAKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "KULA",
        "STREET": "PAPUÇLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "KULA",
        "STREET": "SANDAL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "KULA",
        "STREET": "SARAÇLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "KULA",
        "STREET": "SARNIÇ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "KULA",
        "STREET": "SEYİTALİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "KULA",
        "STREET": "SÖĞÜTDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "KULA",
        "STREET": "TATLIÇEŞME MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "KULA",
        "STREET": "TOPUZDAMLARI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "KULA",
        "STREET": "YAĞBASTI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "KULA",
        "STREET": "YENİKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "KULA",
        "STREET": "YEŞİLYAYLA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "KULA",
        "STREET": "YURTBAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "KULA",
        "STREET": "ZAFERİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "KULA",
        "STREET": "ÇARIKBALLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "KULA",
        "STREET": "ÇARIKMAHMUTLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "KULA",
        "STREET": "ÇİFTÇİİBRAHİM MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "KULA",
        "STREET": "İBRAHİMAĞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "KULA",
        "STREET": "İNCESU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "KULA",
        "STREET": "ŞEHİTLİOĞLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "KULA",
        "STREET": "ŞEREMET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "KULA",
        "STREET": "ŞERİTLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "KULA",
        "STREET": "ŞIHLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "KÖPRÜBAŞI",
        "STREET": "AKÇAALAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "KÖPRÜBAŞI",
        "STREET": "ALANYOLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "KÖPRÜBAŞI",
        "STREET": "ARMAĞAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "KÖPRÜBAŞI",
        "STREET": "ARPACI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "KÖPRÜBAŞI",
        "STREET": "ATATÜRK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "KÖPRÜBAŞI",
        "STREET": "AZİMLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "KÖPRÜBAŞI",
        "STREET": "BORLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "KÖPRÜBAŞI",
        "STREET": "BOZBURUN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "KÖPRÜBAŞI",
        "STREET": "CICIKLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "KÖPRÜBAŞI",
        "STREET": "DÖĞÜŞEREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "KÖPRÜBAŞI",
        "STREET": "ESAT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "KÖPRÜBAŞI",
        "STREET": "GÖKVELİLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "KÖPRÜBAŞI",
        "STREET": "GÖLBAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "KÖPRÜBAŞI",
        "STREET": "GÜLPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "KÖPRÜBAŞI",
        "STREET": "GÜNDOĞDU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "KÖPRÜBAŞI",
        "STREET": "KARAELMACIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "KÖPRÜBAŞI",
        "STREET": "KARYAĞDI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "KÖPRÜBAŞI",
        "STREET": "KASAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "KÖPRÜBAŞI",
        "STREET": "KAVAKYERİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "KÖPRÜBAŞI",
        "STREET": "KEMHALLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "KÖPRÜBAŞI",
        "STREET": "KIDIRCIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "KÖPRÜBAŞI",
        "STREET": "KINIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "KÖPRÜBAŞI",
        "STREET": "KIRANŞEYH MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "KÖPRÜBAŞI",
        "STREET": "KOZAKLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "KÖPRÜBAŞI",
        "STREET": "KULALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "KÖPRÜBAŞI",
        "STREET": "KURTLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "KÖPRÜBAŞI",
        "STREET": "KİLLİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "KÖPRÜBAŞI",
        "STREET": "MEHMET AKİF ERSOY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "KÖPRÜBAŞI",
        "STREET": "MESTANLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "KÖPRÜBAŞI",
        "STREET": "NAMIK KEMAL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "KÖPRÜBAŞI",
        "STREET": "RAĞILLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "KÖPRÜBAŞI",
        "STREET": "SARAYCIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "KÖPRÜBAŞI",
        "STREET": "SARGAÇ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "KÖPRÜBAŞI",
        "STREET": "SELVİLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "KÖPRÜBAŞI",
        "STREET": "TEMREK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "KÖPRÜBAŞI",
        "STREET": "TOKMAKLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "KÖPRÜBAŞI",
        "STREET": "UĞURLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "KÖPRÜBAŞI",
        "STREET": "YABACI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "KÖPRÜBAŞI",
        "STREET": "YARDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "KÖPRÜBAŞI",
        "STREET": "YENİCE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "KÖPRÜBAŞI",
        "STREET": "YEŞİLKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "KÖPRÜBAŞI",
        "STREET": "YUMUKLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "KÖPRÜBAŞI",
        "STREET": "ÇARIKLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "KÖPRÜBAŞI",
        "STREET": "ÇAVULLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "KÖPRÜBAŞI",
        "STREET": "ÇAYKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "KÖPRÜBAŞI",
        "STREET": "İKİZKUYU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "SALİHLİ",
        "STREET": "ADALA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "SALİHLİ",
        "STREET": "AKSOY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "SALİHLİ",
        "STREET": "AKYAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "SALİHLİ",
        "STREET": "AKÖREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "SALİHLİ",
        "STREET": "ALLAHDİYEN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "SALİHLİ",
        "STREET": "ATATÜRK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "SALİHLİ",
        "STREET": "BAHÇECİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "SALİHLİ",
        "STREET": "BARIŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "SALİHLİ",
        "STREET": "BAĞCILAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "SALİHLİ",
        "STREET": "BAŞLIOĞLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "SALİHLİ",
        "STREET": "BEYLİKLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "SALİHLİ",
        "STREET": "BEŞEYLÜL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "SALİHLİ",
        "STREET": "BURHAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "SALİHLİ",
        "STREET": "CAFERBEY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "SALİHLİ",
        "STREET": "CUMHURİYET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "SALİHLİ",
        "STREET": "DAMATLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "SALİHLİ",
        "STREET": "DEDETAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "SALİHLİ",
        "STREET": "DELİBAŞLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "SALİHLİ",
        "STREET": "DERBENT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "SALİHLİ",
        "STREET": "DOMBAYLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "SALİHLİ",
        "STREET": "DURASILLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "SALİHLİ",
        "STREET": "ELDELEK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "SALİHLİ",
        "STREET": "EMİNBEY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "SALİHLİ",
        "STREET": "EMİRHACILI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "SALİHLİ",
        "STREET": "ESKİ CAMİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "SALİHLİ",
        "STREET": "GAFFAR OKKAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "SALİHLİ",
        "STREET": "GAZİLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "SALİHLİ",
        "STREET": "GÖKEYÜP MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "SALİHLİ",
        "STREET": "GÖKKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "SALİHLİ",
        "STREET": "GÖKÇEKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "SALİHLİ",
        "STREET": "GÜMÜŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "SALİHLİ",
        "STREET": "GÜNEŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "SALİHLİ",
        "STREET": "HACIBEKTAŞLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "SALİHLİ",
        "STREET": "HACIHIDIR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "SALİHLİ",
        "STREET": "HACIKÖSELİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "SALİHLİ",
        "STREET": "HACILI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "SALİHLİ",
        "STREET": "HASALAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "SALİHLİ",
        "STREET": "KABAZLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "SALİHLİ",
        "STREET": "KALE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "SALİHLİ",
        "STREET": "KAPANCI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "SALİHLİ",
        "STREET": "KAPLAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "SALİHLİ",
        "STREET": "KARAAĞAÇ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "SALİHLİ",
        "STREET": "KARAOĞLANLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "SALİHLİ",
        "STREET": "KARAPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "SALİHLİ",
        "STREET": "KARASAVCI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "SALİHLİ",
        "STREET": "KARAYAHŞİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "SALİHLİ",
        "STREET": "KELİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "SALİHLİ",
        "STREET": "KEMER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "SALİHLİ",
        "STREET": "KEMERDAMLARI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "SALİHLİ",
        "STREET": "KIRDAMLARI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "SALİHLİ",
        "STREET": "KIRVELİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "SALİHLİ",
        "STREET": "KIZILHAVLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "SALİHLİ",
        "STREET": "KOCAÇEŞME MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "SALİHLİ",
        "STREET": "KORDON MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "SALİHLİ",
        "STREET": "KURTTUTAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "SALİHLİ",
        "STREET": "KURTULUŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "SALİHLİ",
        "STREET": "KÖSEALİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "SALİHLİ",
        "STREET": "MAMATLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "SALİHLİ",
        "STREET": "MERSİNDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "SALİHLİ",
        "STREET": "MERSİNLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "SALİHLİ",
        "STREET": "MEVLUTLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "SALİHLİ",
        "STREET": "MİTATPAŞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "SALİHLİ",
        "STREET": "NAMIK KEMAL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "SALİHLİ",
        "STREET": "ORAKLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "SALİHLİ",
        "STREET": "ORTAKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "SALİHLİ",
        "STREET": "PAZARKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "SALİHLİ",
        "STREET": "POYRAZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "SALİHLİ",
        "STREET": "POYRAZDAMLARI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "SALİHLİ",
        "STREET": "SALİHLİ T TİPİ KAPALI CEZA İNFAZ KURUMU",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "SALİHLİ",
        "STREET": "SARIPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "SALİHLİ",
        "STREET": "SART MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "SALİHLİ",
        "STREET": "SAĞLIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "SALİHLİ",
        "STREET": "SEYRANTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "SALİHLİ",
        "STREET": "SÜLEYMANİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "SALİHLİ",
        "STREET": "SİNDEL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "SALİHLİ",
        "STREET": "TAYTAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "SALİHLİ",
        "STREET": "TEKELİOĞLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "SALİHLİ",
        "STREET": "TORUNLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "SALİHLİ",
        "STREET": "YAĞBASAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "SALİHLİ",
        "STREET": "YAĞMURLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "SALİHLİ",
        "STREET": "YENİKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "SALİHLİ",
        "STREET": "YENİPAZAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "SALİHLİ",
        "STREET": "YEŞİLKAVAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "SALİHLİ",
        "STREET": "YEŞİLOVA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "SALİHLİ",
        "STREET": "YEŞİLYURT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "SALİHLİ",
        "STREET": "YILMAZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "SALİHLİ",
        "STREET": "YÖRÜK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "SALİHLİ",
        "STREET": "ZAFER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "SALİHLİ",
        "STREET": "ÇAKALDOĞANLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "SALİHLİ",
        "STREET": "ÇALTILI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "SALİHLİ",
        "STREET": "ÇAMURHAMAMI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "SALİHLİ",
        "STREET": "ÇAPAKLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "SALİHLİ",
        "STREET": "ÇAVLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "SALİHLİ",
        "STREET": "ÇAYKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "SALİHLİ",
        "STREET": "ÇAYPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "SALİHLİ",
        "STREET": "ÇELİKLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "SALİHLİ",
        "STREET": "ÇUKUROBA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "SALİHLİ",
        "STREET": "ÇÖKELEK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "SALİHLİ",
        "STREET": "ÖZYURT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "SALİHLİ",
        "STREET": "ÜÇTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "SALİHLİ",
        "STREET": "İĞDECİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "SALİHLİ",
        "STREET": "ŞEHİTLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "SALİHLİ",
        "STREET": "ŞİRİNYER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "SARIGÖL",
        "STREET": "AFŞAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "SARIGÖL",
        "STREET": "AHMETAĞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "SARIGÖL",
        "STREET": "ALEMŞAHLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "SARIGÖL",
        "STREET": "AYAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "SARIGÖL",
        "STREET": "AŞAĞI KOÇAKLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "SARIGÖL",
        "STREET": "BAHADIRLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "SARIGÖL",
        "STREET": "BAHARLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "SARIGÖL",
        "STREET": "BAĞLICA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "SARIGÖL",
        "STREET": "BEREKETLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "SARIGÖL",
        "STREET": "BEYHARMANI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "SARIGÖL",
        "STREET": "CUMHURİYET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "SARIGÖL",
        "STREET": "DADAĞLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "SARIGÖL",
        "STREET": "DOĞUŞLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "SARIGÖL",
        "STREET": "DİNDARLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "SARIGÖL",
        "STREET": "EMCELLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "SARIGÖL",
        "STREET": "GÜNEYDAMLARI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "SARIGÖL",
        "STREET": "GÜNYAKA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "SARIGÖL",
        "STREET": "KAHRAMANLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "SARIGÖL",
        "STREET": "KARACAALİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "SARIGÖL",
        "STREET": "KIZILÇUKUR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "SARIGÖL",
        "STREET": "KONAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "SARIGÖL",
        "STREET": "MİMAR SİNAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "SARIGÖL",
        "STREET": "SELİMİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "SARIGÖL",
        "STREET": "SIĞIRTMAÇLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "SARIGÖL",
        "STREET": "SİTELER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "SARIGÖL",
        "STREET": "TIRAZLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "SARIGÖL",
        "STREET": "YENİKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "SARIGÖL",
        "STREET": "YEŞİLTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "SARIGÖL",
        "STREET": "YUKARIKOÇAKLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "SARIGÖL",
        "STREET": "ZİYANLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "SARIGÖL",
        "STREET": "ÇANAKÇI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "SARIGÖL",
        "STREET": "ÇAVUŞLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "SARIGÖL",
        "STREET": "ÇİMENTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "SARIGÖL",
        "STREET": "ÖZPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "SARIGÖL",
        "STREET": "ŞEYHDAVUTLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "SARUHANLI",
        "STREET": "ADİLOBA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "SARUHANLI",
        "STREET": "ALİBEYLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "SARUHANLI",
        "STREET": "APAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "SARUHANLI",
        "STREET": "ATATÜRK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "SARUHANLI",
        "STREET": "AYDINLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "SARUHANLI",
        "STREET": "AZİMLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "SARUHANLI",
        "STREET": "BAHADIR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "SARUHANLI",
        "STREET": "BEDELLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "SARUHANLI",
        "STREET": "BÜYÜKBELEN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "SARUHANLI",
        "STREET": "CENGİZ TOPEL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "SARUHANLI",
        "STREET": "CUMHURİYET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "SARUHANLI",
        "STREET": "DEVELİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "SARUHANLI",
        "STREET": "DİLEK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "SARUHANLI",
        "STREET": "GÖKÇEKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "SARUHANLI",
        "STREET": "GÖZLET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "SARUHANLI",
        "STREET": "GÜMÜLCELİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "SARUHANLI",
        "STREET": "HACIMUSA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "SARUHANLI",
        "STREET": "HACIRAHMANLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "SARUHANLI",
        "STREET": "HALİTPAŞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "SARUHANLI",
        "STREET": "HATIPLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "SARUHANLI",
        "STREET": "HEYBELİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "SARUHANLI",
        "STREET": "KAYIŞLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "SARUHANLI",
        "STREET": "KEMİKLİDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "SARUHANLI",
        "STREET": "KEPENEKLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "SARUHANLI",
        "STREET": "KOLDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "SARUHANLI",
        "STREET": "KOYUNCU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "SARUHANLI",
        "STREET": "KUMKUYUCAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "SARUHANLI",
        "STREET": "KURTULUŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "SARUHANLI",
        "STREET": "LÜTFİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "SARUHANLI",
        "STREET": "MÜTEVELLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "SARUHANLI",
        "STREET": "NURİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "SARUHANLI",
        "STREET": "PAŞAKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "SARUHANLI",
        "STREET": "PINARBAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "SARUHANLI",
        "STREET": "SARISIĞIRLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "SARUHANLI",
        "STREET": "SARIÇAM MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "SARUHANLI",
        "STREET": "SARUHAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "SARUHANLI",
        "STREET": "SEYİTOBA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "SARUHANLI",
        "STREET": "TAŞDİBİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "SARUHANLI",
        "STREET": "TİRKEŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "SARUHANLI",
        "STREET": "YILMAZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "SARUHANLI",
        "STREET": "ÇAKMAKLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "SARUHANLI",
        "STREET": "ÇALTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "SARUHANLI",
        "STREET": "ÇAMLIYURT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "SARUHANLI",
        "STREET": "ÇERKEZ OSMANİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "SARUHANLI",
        "STREET": "ÇINAROBA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "SARUHANLI",
        "STREET": "ÇULLUGÖRECE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "SARUHANLI",
        "STREET": "İSHAKÇELEBİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "SARUHANLI",
        "STREET": "İSTASYON MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "SARUHANLI",
        "STREET": "ŞATIRLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "SARUHANLI",
        "STREET": "ŞEHİTLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "SELENDİ",
        "STREET": "AKÇAKERTİL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "SELENDİ",
        "STREET": "ALTINKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "SELENDİ",
        "STREET": "AVLAŞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "SELENDİ",
        "STREET": "AŞAĞITEFEN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "SELENDİ",
        "STREET": "BEKİRAĞALAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "SELENDİ",
        "STREET": "BEYPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "SELENDİ",
        "STREET": "DEDELER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "SELENDİ",
        "STREET": "DUMANLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "SELENDİ",
        "STREET": "ESKİCAMİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "SELENDİ",
        "STREET": "ESKİN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "SELENDİ",
        "STREET": "FATİH MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "SELENDİ",
        "STREET": "GÖLBAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "SELENDİ",
        "STREET": "HACIHALİLLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "SELENDİ",
        "STREET": "HACILAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "SELENDİ",
        "STREET": "HALILAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "SELENDİ",
        "STREET": "HAVAOĞLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "SELENDİ",
        "STREET": "KABAKLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "SELENDİ",
        "STREET": "KARABEYLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "SELENDİ",
        "STREET": "KARAKOZAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "SELENDİ",
        "STREET": "KARASELENDİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "SELENDİ",
        "STREET": "KARATAŞTERZİLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "SELENDİ",
        "STREET": "KARŞIOVA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "SELENDİ",
        "STREET": "KAYRANLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "SELENDİ",
        "STREET": "KAZIKLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "SELENDİ",
        "STREET": "KINIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "SELENDİ",
        "STREET": "KURTULUŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "SELENDİ",
        "STREET": "KURŞUNLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "SELENDİ",
        "STREET": "KÜRKÇÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "SELENDİ",
        "STREET": "MIDIKLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "SELENDİ",
        "STREET": "MOLLAAHMETLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "SELENDİ",
        "STREET": "OMURLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "SELENDİ",
        "STREET": "PINARLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "SELENDİ",
        "STREET": "RAHMANLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "SELENDİ",
        "STREET": "SELMANHACILAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "SELENDİ",
        "STREET": "TAVAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "SELENDİ",
        "STREET": "TEPEYNİHAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "SELENDİ",
        "STREET": "TURPÇU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "SELENDİ",
        "STREET": "YAĞCI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "SELENDİ",
        "STREET": "YENİCAMİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "SELENDİ",
        "STREET": "YENİCE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "SELENDİ",
        "STREET": "YEŞİL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "SELENDİ",
        "STREET": "YILDIZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "SELENDİ",
        "STREET": "YUKARITEFEN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "SELENDİ",
        "STREET": "ZAFER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "SELENDİ",
        "STREET": "ZIRAMANLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "SELENDİ",
        "STREET": "ÇALIKLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "SELENDİ",
        "STREET": "ÇAMKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "SELENDİ",
        "STREET": "ÇAMLICA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "SELENDİ",
        "STREET": "ÇAMPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "SELENDİ",
        "STREET": "ÇAMYAYLA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "SELENDİ",
        "STREET": "ÇANŞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "SELENDİ",
        "STREET": "ÇIKRIKÇI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "SELENDİ",
        "STREET": "ÇORTAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "SELENDİ",
        "STREET": "ÇİNAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "SELENDİ",
        "STREET": "İNNİCE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "SELENDİ",
        "STREET": "ŞEHİRLİOĞLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "SELENDİ",
        "STREET": "ŞEREFİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "SOMA",
        "STREET": "ADİL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "SOMA",
        "STREET": "AKÇAAVLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "SOMA",
        "STREET": "ATATÜRK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "SOMA",
        "STREET": "AVDAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "SOMA",
        "STREET": "BAYAT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "SOMA",
        "STREET": "BEYCE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "SOMA",
        "STREET": "BONCUKLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "SOMA",
        "STREET": "BOZARMUT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "SOMA",
        "STREET": "BÜYÜK GÜNEY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "SOMA",
        "STREET": "BÜYÜK IŞIKLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "SOMA",
        "STREET": "CENKYERİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "SOMA",
        "STREET": "CUMA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "SOMA",
        "STREET": "CUMHURİYET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "SOMA",
        "STREET": "DARKALE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "SOMA",
        "STREET": "DENİŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "SOMA",
        "STREET": "DEREKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "SOMA",
        "STREET": "DEVLETHAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "SOMA",
        "STREET": "DUALAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "SOMA",
        "STREET": "DUĞLA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "SOMA",
        "STREET": "EVCİLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "SOMA",
        "STREET": "EĞNEZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "SOMA",
        "STREET": "GÖKTAŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "SOMA",
        "STREET": "HACIYUSUF MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "SOMA",
        "STREET": "HAMİDİYE (MERKEZ) MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "SOMA",
        "STREET": "HAMİDİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "SOMA",
        "STREET": "HATUN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "SOMA",
        "STREET": "HECİZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "SOMA",
        "STREET": "HÜRRİYET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "SOMA",
        "STREET": "KAPLAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "SOMA",
        "STREET": "KARACAHİSAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "SOMA",
        "STREET": "KARACAKAŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "SOMA",
        "STREET": "KARAMANLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "SOMA",
        "STREET": "KARAÇAM MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "SOMA",
        "STREET": "KAYRAKALTI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "SOMA",
        "STREET": "KIZILÖREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "SOMA",
        "STREET": "KOBAKLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "SOMA",
        "STREET": "KOYUNDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "SOMA",
        "STREET": "KOZANLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "SOMA",
        "STREET": "KOZLUÖREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "SOMA",
        "STREET": "KUM MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "SOMA",
        "STREET": "KURTULUŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "SOMA",
        "STREET": "KÜÇÜK GÜNEY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "SOMA",
        "STREET": "KİRAZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "SOMA",
        "STREET": "MENTEŞE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "SOMA",
        "STREET": "NALDÖKEN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "SOMA",
        "STREET": "NAMAZGAH MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "SOMA",
        "STREET": "NİHAT DANIŞMAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "SOMA",
        "STREET": "ONÜÇ EYLÜL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "SOMA",
        "STREET": "PİRAHMET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "SOMA",
        "STREET": "SAMSACI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "SOMA",
        "STREET": "SARIKAYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "SOMA",
        "STREET": "SEVİŞLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "SOMA",
        "STREET": "SULTANİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "SOMA",
        "STREET": "SÖĞÜTÇÜK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "SOMA",
        "STREET": "TABANLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "SOMA",
        "STREET": "TEKELİ IŞIKLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "SOMA",
        "STREET": "TURGUTALP MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "SOMA",
        "STREET": "TÜRKALİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "SOMA",
        "STREET": "TÜRKPİYALA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "SOMA",
        "STREET": "ULARCA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "SOMA",
        "STREET": "URUZLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "SOMA",
        "STREET": "VAKIFLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "SOMA",
        "STREET": "YAYLADALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "SOMA",
        "STREET": "YAĞCILI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "SOMA",
        "STREET": "YIRCA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "SOMA",
        "STREET": "ZAFER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "SOMA",
        "STREET": "ÇATALÇAM MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "SOMA",
        "STREET": "ÇAVDIR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "SOMA",
        "STREET": "ÇEVİRCEK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "SOMA",
        "STREET": "İNÖNÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "SOMA",
        "STREET": "İSTASYON MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "TURGUTLU",
        "STREET": "ACARLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "TURGUTLU",
        "STREET": "AKKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "TURGUTLU",
        "STREET": "AKÇAPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "TURGUTLU",
        "STREET": "ALBAYRAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "TURGUTLU",
        "STREET": "ALTAY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "TURGUTLU",
        "STREET": "ATATÜRK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "TURGUTLU",
        "STREET": "AVŞAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "TURGUTLU",
        "STREET": "AYVACIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "TURGUTLU",
        "STREET": "AŞAĞIBOZKIR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "TURGUTLU",
        "STREET": "BAKTIRLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "TURGUTLU",
        "STREET": "BOZKIR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "TURGUTLU",
        "STREET": "BOZKURT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "TURGUTLU",
        "STREET": "CUMHURİYET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "TURGUTLU",
        "STREET": "DALBAHÇE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "TURGUTLU",
        "STREET": "DAĞYENİKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "TURGUTLU",
        "STREET": "DERBENT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "TURGUTLU",
        "STREET": "ERGENEKON MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "TURGUTLU",
        "STREET": "GÖKGEDİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "TURGUTLU",
        "STREET": "GÜNEY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "TURGUTLU",
        "STREET": "HACIİSALAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "TURGUTLU",
        "STREET": "IRLAMAZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "TURGUTLU",
        "STREET": "KABAÇINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "TURGUTLU",
        "STREET": "KARAKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "TURGUTLU",
        "STREET": "KARAOLUK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "TURGUTLU",
        "STREET": "KAYRAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "TURGUTLU",
        "STREET": "KURTULUŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "TURGUTLU",
        "STREET": "KURUDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "TURGUTLU",
        "STREET": "KUŞLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "TURGUTLU",
        "STREET": "MUSACALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "TURGUTLU",
        "STREET": "MUSALARYENİKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "TURGUTLU",
        "STREET": "MUSTAFA KEMAL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "TURGUTLU",
        "STREET": "OSMANCIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "TURGUTLU",
        "STREET": "SARIBEY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "TURGUTLU",
        "STREET": "SELVİLİTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "TURGUTLU",
        "STREET": "SUBAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "TURGUTLU",
        "STREET": "SİNİRLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "TURGUTLU",
        "STREET": "SİVRİCE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "TURGUTLU",
        "STREET": "TEMREK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "TURGUTLU",
        "STREET": "TURAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "TURGUTLU",
        "STREET": "TURGUTLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "TURGUTLU",
        "STREET": "URGANLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "TURGUTLU",
        "STREET": "YAKUPLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "TURGUTLU",
        "STREET": "YAYLA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "TURGUTLU",
        "STREET": "YEDİ EYLÜL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "TURGUTLU",
        "STREET": "YENİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "TURGUTLU",
        "STREET": "YENİKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "TURGUTLU",
        "STREET": "YILDIRIM MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "TURGUTLU",
        "STREET": "YILMAZLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "TURGUTLU",
        "STREET": "YUNUSDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "TURGUTLU",
        "STREET": "YİĞİTLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "TURGUTLU",
        "STREET": "ÇAMPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "TURGUTLU",
        "STREET": "ÇATALKÖPRÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "TURGUTLU",
        "STREET": "ÇEPNİBEKTAŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "TURGUTLU",
        "STREET": "ÇEPNİDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "TURGUTLU",
        "STREET": "ÇIKRIKÇI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "TURGUTLU",
        "STREET": "ÖREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "TURGUTLU",
        "STREET": "ÖZYURT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "TURGUTLU",
        "STREET": "İSTASYONALTI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "TURGUTLU",
        "STREET": "İSTİKLAL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "TURGUTLU",
        "STREET": "İZZETTİN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "TURGUTLU",
        "STREET": "ŞEHİTLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "YUNUSEMRE",
        "STREET": "50.YIL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "YUNUSEMRE",
        "STREET": "75. YIL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "YUNUSEMRE",
        "STREET": "AKGEDİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "YUNUSEMRE",
        "STREET": "AKMESCİT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "YUNUSEMRE",
        "STREET": "AKÇAKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "YUNUSEMRE",
        "STREET": "ASMACIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "YUNUSEMRE",
        "STREET": "ATATÜRK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "YUNUSEMRE",
        "STREET": "AVDAL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "YUNUSEMRE",
        "STREET": "AYNİ ALİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "YUNUSEMRE",
        "STREET": "BARBAROS MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "YUNUSEMRE",
        "STREET": "BAĞYOLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "YUNUSEMRE",
        "STREET": "BEYDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "YUNUSEMRE",
        "STREET": "BOSTANLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "YUNUSEMRE",
        "STREET": "BÜYÜKSÜMBÜLLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "YUNUSEMRE",
        "STREET": "CUMHURİYET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "YUNUSEMRE",
        "STREET": "DAVUTLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "YUNUSEMRE",
        "STREET": "DAZYURT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "YUNUSEMRE",
        "STREET": "DEMİRCİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "YUNUSEMRE",
        "STREET": "DURASILLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "YUNUSEMRE",
        "STREET": "DÜZLEN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "YUNUSEMRE",
        "STREET": "EMLAKDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "YUNUSEMRE",
        "STREET": "EVRENOS MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "YUNUSEMRE",
        "STREET": "FATİH MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "YUNUSEMRE",
        "STREET": "GÖKÇELER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "YUNUSEMRE",
        "STREET": "GÜLBAHÇE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "YUNUSEMRE",
        "STREET": "GÜRLE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "YUNUSEMRE",
        "STREET": "GÜZELYURT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "YUNUSEMRE",
        "STREET": "HAFSA SULTAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "YUNUSEMRE",
        "STREET": "KARAAHMETLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "YUNUSEMRE",
        "STREET": "KARAALİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "YUNUSEMRE",
        "STREET": "KARAHÜSEYİNLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "YUNUSEMRE",
        "STREET": "KARAKILIÇLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "YUNUSEMRE",
        "STREET": "KARAKOCA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "YUNUSEMRE",
        "STREET": "KARAVELİLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "YUNUSEMRE",
        "STREET": "KARAYAĞCIHACILAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "YUNUSEMRE",
        "STREET": "KAYAPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "YUNUSEMRE",
        "STREET": "KAYNAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "YUNUSEMRE",
        "STREET": "KEÇİLİ KÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "YUNUSEMRE",
        "STREET": "KIŞLAKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "YUNUSEMRE",
        "STREET": "KOCAKORU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "YUNUSEMRE",
        "STREET": "KORUKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "YUNUSEMRE",
        "STREET": "KOZAKLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "YUNUSEMRE",
        "STREET": "KUYUALAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "YUNUSEMRE",
        "STREET": "KÜÇÜKBELEN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "YUNUSEMRE",
        "STREET": "KÜÇÜKSÜMBÜLLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "YUNUSEMRE",
        "STREET": "LALAPAŞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "YUNUSEMRE",
        "STREET": "LALELİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "YUNUSEMRE",
        "STREET": "MALDAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "YUNUSEMRE",
        "STREET": "MAREŞAL FEVZİ ÇAKMAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "YUNUSEMRE",
        "STREET": "MERKEZ EFENDİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "YUNUSEMRE",
        "STREET": "MESİR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "YUNUSEMRE",
        "STREET": "MOLLASÜLEYMANLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "YUNUSEMRE",
        "STREET": "MURADİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "YUNUSEMRE",
        "STREET": "MUTLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "YUNUSEMRE",
        "STREET": "MÜSLİH MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "YUNUSEMRE",
        "STREET": "ORTAKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "YUNUSEMRE",
        "STREET": "OSMANCALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "YUNUSEMRE",
        "STREET": "OTMANLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "YUNUSEMRE",
        "STREET": "PELİTALAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "YUNUSEMRE",
        "STREET": "PINARKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "YUNUSEMRE",
        "STREET": "RECEPLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "YUNUSEMRE",
        "STREET": "SAKALLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "YUNUSEMRE",
        "STREET": "SARIAHMETLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "YUNUSEMRE",
        "STREET": "SARINASUHLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "YUNUSEMRE",
        "STREET": "SARMA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "YUNUSEMRE",
        "STREET": "SEYİTLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "YUNUSEMRE",
        "STREET": "SPİL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "YUNUSEMRE",
        "STREET": "SÜMBÜLTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "YUNUSEMRE",
        "STREET": "SÜNGÜLLÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "YUNUSEMRE",
        "STREET": "TEVFİKİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "YUNUSEMRE",
        "STREET": "TOPÇUASIM MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "YUNUSEMRE",
        "STREET": "TURGUTALP MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "YUNUSEMRE",
        "STREET": "TÜRKMEN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "YUNUSEMRE",
        "STREET": "UNCUBOZKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "YUNUSEMRE",
        "STREET": "UZUNBURUN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "YUNUSEMRE",
        "STREET": "UZUNLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "YUNUSEMRE",
        "STREET": "YAYLAKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "YUNUSEMRE",
        "STREET": "YAĞCILAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "YUNUSEMRE",
        "STREET": "YENİ MAHALLE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "YUNUSEMRE",
        "STREET": "YUNTDAĞIKÖSELER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "YUNUSEMRE",
        "STREET": "YUNTDAĞYENİCE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "YUNUSEMRE",
        "STREET": "ÇAMLICA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "YUNUSEMRE",
        "STREET": "ÖRENCİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "YUNUSEMRE",
        "STREET": "ÖRSELLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "YUNUSEMRE",
        "STREET": "ÜÇPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "YUNUSEMRE",
        "STREET": "İLYASÇILAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "YUNUSEMRE",
        "STREET": "ŞAMAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "ŞEHZADELER",
        "STREET": "1.ANAFARTALAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "ŞEHZADELER",
        "STREET": "2.ANAFARTALAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "ŞEHZADELER",
        "STREET": "ADAKALE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "ŞEHZADELER",
        "STREET": "ADNAN MENDERES MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "ŞEHZADELER",
        "STREET": "AHMET BEDEVİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "ŞEHZADELER",
        "STREET": "AKINCILAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "ŞEHZADELER",
        "STREET": "AKPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "ŞEHZADELER",
        "STREET": "ALAYBEY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "ŞEHZADELER",
        "STREET": "ARDA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "ŞEHZADELER",
        "STREET": "AYVACIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "ŞEHZADELER",
        "STREET": "AŞAĞIÇOBANİSA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "ŞEHZADELER",
        "STREET": "BAYINDIRLIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "ŞEHZADELER",
        "STREET": "BELENYENİCE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "ŞEHZADELER",
        "STREET": "DERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "ŞEHZADELER",
        "STREET": "DİLŞİKAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "ŞEHZADELER",
        "STREET": "DİNÇER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "ŞEHZADELER",
        "STREET": "EGE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "ŞEHZADELER",
        "STREET": "GEDİZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "ŞEHZADELER",
        "STREET": "GÖKBEL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "ŞEHZADELER",
        "STREET": "GÖKTAŞLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "ŞEHZADELER",
        "STREET": "GÜZELKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "ŞEHZADELER",
        "STREET": "HACIHALİLLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "ŞEHZADELER",
        "STREET": "HALITLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "ŞEHZADELER",
        "STREET": "HAMZABEYLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "ŞEHZADELER",
        "STREET": "KALEKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "ŞEHZADELER",
        "STREET": "KALEMLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "ŞEHZADELER",
        "STREET": "KARAAĞAÇLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "ŞEHZADELER",
        "STREET": "KARAOĞLANLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "ŞEHZADELER",
        "STREET": "KARAYENİCE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "ŞEHZADELER",
        "STREET": "KAZIM KARABEKİR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "ŞEHZADELER",
        "STREET": "KAĞAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "ŞEHZADELER",
        "STREET": "KIRANÇİFTLİĞİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "ŞEHZADELER",
        "STREET": "KOCATEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "ŞEHZADELER",
        "STREET": "KUŞLUBAHÇE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "ŞEHZADELER",
        "STREET": "MANİSA E TİPİ KAPALI CEZA İNFAZ KURUMU",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "ŞEHZADELER",
        "STREET": "MANİSA T TİPİ CEZA İNFAZ KURUMU",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "ŞEHZADELER",
        "STREET": "MİMARSİNAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "ŞEHZADELER",
        "STREET": "NURLUPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "ŞEHZADELER",
        "STREET": "NİŞANCIPAŞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "ŞEHZADELER",
        "STREET": "PEKER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "ŞEHZADELER",
        "STREET": "SAKARYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "ŞEHZADELER",
        "STREET": "SANCAKLIBOZKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "ŞEHZADELER",
        "STREET": "SANCAKLIKAYADİBİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "ŞEHZADELER",
        "STREET": "SANCAKLIUZUNÇINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "ŞEHZADELER",
        "STREET": "SANCAKLIÇEŞMEBAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "ŞEHZADELER",
        "STREET": "SANCAKLIİĞDECİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "ŞEHZADELER",
        "STREET": "SARIALAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "ŞEHZADELER",
        "STREET": "SARUHAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "ŞEHZADELER",
        "STREET": "SELİMŞAHLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "ŞEHZADELER",
        "STREET": "TEKELİLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "ŞEHZADELER",
        "STREET": "TEPECİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "ŞEHZADELER",
        "STREET": "TUNCA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "ŞEHZADELER",
        "STREET": "TURGUT ÖZAL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "ŞEHZADELER",
        "STREET": "TİLKİSÜLEYMANİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "ŞEHZADELER",
        "STREET": "UTKU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "ŞEHZADELER",
        "STREET": "VEZİROĞLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "ŞEHZADELER",
        "STREET": "YARHASANLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "ŞEHZADELER",
        "STREET": "YENİHARMANDALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "ŞEHZADELER",
        "STREET": "YENİKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "ŞEHZADELER",
        "STREET": "YEŞİLKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "ŞEHZADELER",
        "STREET": "YUKARIÇOBANİSA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "ŞEHZADELER",
        "STREET": "ÇAMKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "ŞEHZADELER",
        "STREET": "ÇARŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "ŞEHZADELER",
        "STREET": "ÇAVUŞOĞLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "ŞEHZADELER",
        "STREET": "ÇERKEZMAHMUDİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "ŞEHZADELER",
        "STREET": "ÇINARLIKUYU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "ŞEHZADELER",
        "STREET": "İBRAHİMÇELEBİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "ŞEHZADELER",
        "STREET": "İSHAKÇELEBI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MANİSA",
        "DISTRICT": "ŞEHZADELER",
        "STREET": "ŞEHİTLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "ARTUKLU",
        "STREET": "13 MART MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "ARTUKLU",
        "STREET": "ACAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "ARTUKLU",
        "STREET": "AHMETLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "ARTUKLU",
        "STREET": "AKBAĞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "ARTUKLU",
        "STREET": "AKINCI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "ARTUKLU",
        "STREET": "ALAKUŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "ARTUKLU",
        "STREET": "ALIMLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "ARTUKLU",
        "STREET": "AMBAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "ARTUKLU",
        "STREET": "ARAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "ARTUKLU",
        "STREET": "ARPATEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "ARTUKLU",
        "STREET": "AVCILAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "ARTUKLU",
        "STREET": "AYTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "ARTUKLU",
        "STREET": "AŞAĞIYENİKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "ARTUKLU",
        "STREET": "BAĞLICA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "ARTUKLU",
        "STREET": "BOZTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "ARTUKLU",
        "STREET": "BUĞDAY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "ARTUKLU",
        "STREET": "CEVİZLİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "ARTUKLU",
        "STREET": "CEVİZPINARI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "ARTUKLU",
        "STREET": "CUMHURİYET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "ARTUKLU",
        "STREET": "DARA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "ARTUKLU",
        "STREET": "DÜZLÜK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "ARTUKLU",
        "STREET": "DİBEKTAŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "ARTUKLU",
        "STREET": "DİYARBAKIR KAPI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "ARTUKLU",
        "STREET": "ELMABAHÇE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "ARTUKLU",
        "STREET": "EMİNETTİN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "ARTUKLU",
        "STREET": "ENSAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "ARTUKLU",
        "STREET": "EROĞLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "ARTUKLU",
        "STREET": "ERYERİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "ARTUKLU",
        "STREET": "ESENTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "ARTUKLU",
        "STREET": "ESKİKALE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "ARTUKLU",
        "STREET": "GÖKÇE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "ARTUKLU",
        "STREET": "GÖLLÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "ARTUKLU",
        "STREET": "GÜL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "ARTUKLU",
        "STREET": "GÜNEYLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "ARTUKLU",
        "STREET": "GÜRAĞAÇ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "ARTUKLU",
        "STREET": "HAMZABEY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "ARTUKLU",
        "STREET": "HATUNLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "ARTUKLU",
        "STREET": "HAYDAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "ARTUKLU",
        "STREET": "HÜYÜKLÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "ARTUKLU",
        "STREET": "KABALA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "ARTUKLU",
        "STREET": "KARADEMİR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "ARTUKLU",
        "STREET": "KAYACAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "ARTUKLU",
        "STREET": "KONAKLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "ARTUKLU",
        "STREET": "KOTEK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "ARTUKLU",
        "STREET": "KUMLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "ARTUKLU",
        "STREET": "KUYULU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "ARTUKLU",
        "STREET": "KÜÇÜKKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "ARTUKLU",
        "STREET": "LATİFİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "ARTUKLU",
        "STREET": "MARDİN E TİPİ KAPALI CEZA İNFAZ KURUMU",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "ARTUKLU",
        "STREET": "MEDRESE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "ARTUKLU",
        "STREET": "NECMETTİN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "ARTUKLU",
        "STREET": "NUR (AKINCI) MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "ARTUKLU",
        "STREET": "NUR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "ARTUKLU",
        "STREET": "OFİS MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "ARTUKLU",
        "STREET": "ORTAKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "ARTUKLU",
        "STREET": "SAKALAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "ARTUKLU",
        "STREET": "SARAÇOĞLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "ARTUKLU",
        "STREET": "SAVURKAPI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "ARTUKLU",
        "STREET": "SULAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "ARTUKLU",
        "STREET": "SULTANKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "ARTUKLU",
        "STREET": "TANDIR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "ARTUKLU",
        "STREET": "TEKER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "ARTUKLU",
        "STREET": "TOZAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "ARTUKLU",
        "STREET": "TİLKİTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "ARTUKLU",
        "STREET": "ULUCAMİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "ARTUKLU",
        "STREET": "YALIM MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "ARTUKLU",
        "STREET": "YARDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "ARTUKLU",
        "STREET": "YAYLA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "ARTUKLU",
        "STREET": "YAYLABAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "ARTUKLU",
        "STREET": "YAYLACIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "ARTUKLU",
        "STREET": "YAYLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "ARTUKLU",
        "STREET": "YENİCE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "ARTUKLU",
        "STREET": "YENİKAPI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "ARTUKLU",
        "STREET": "YENİKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "ARTUKLU",
        "STREET": "YENİŞEHİR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "ARTUKLU",
        "STREET": "YOLBAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "ARTUKLU",
        "STREET": "YUKARIAYDINLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "ARTUKLU",
        "STREET": "YUKARIHATUNLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "ARTUKLU",
        "STREET": "YUKARIYENİKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "ARTUKLU",
        "STREET": "YÜCE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "ARTUKLU",
        "STREET": "ÇABUK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "ARTUKLU",
        "STREET": "ÇALIŞLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "ARTUKLU",
        "STREET": "ÇATAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "ARTUKLU",
        "STREET": "ÇAYIRPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "ARTUKLU",
        "STREET": "ÇAĞLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "ARTUKLU",
        "STREET": "ÇIPLAKTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "ARTUKLU",
        "STREET": "ÇUKURYURT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "ARTUKLU",
        "STREET": "ÇİFTLİKKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "ARTUKLU",
        "STREET": "ÖZLÜCE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "ARTUKLU",
        "STREET": "İSTASYON MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "ARTUKLU",
        "STREET": "ŞAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "ARTUKLU",
        "STREET": "ŞEHİDİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "DARGEÇİT",
        "STREET": "AKYOL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "DARGEÇİT",
        "STREET": "ALAYUNT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "DARGEÇİT",
        "STREET": "ALTINOLUK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "DARGEÇİT",
        "STREET": "ALTIYOL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "DARGEÇİT",
        "STREET": "BAHÇEBAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "DARGEÇİT",
        "STREET": "BATUR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "DARGEÇİT",
        "STREET": "BAĞÖZÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "DARGEÇİT",
        "STREET": "BEĞENDİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "DARGEÇİT",
        "STREET": "BOSTANLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "DARGEÇİT",
        "STREET": "GÜRIŞIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "DARGEÇİT",
        "STREET": "ILISU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "DARGEÇİT",
        "STREET": "KARABAYIR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "DARGEÇİT",
        "STREET": "KARTALKAYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "DARGEÇİT",
        "STREET": "KILAVUZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "DARGEÇİT",
        "STREET": "KISMETLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "DARGEÇİT",
        "STREET": "SAFA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "DARGEÇİT",
        "STREET": "SARAY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "DARGEÇİT",
        "STREET": "SUÇATI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "DARGEÇİT",
        "STREET": "SÜMER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "DARGEÇİT",
        "STREET": "TANYERİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "DARGEÇİT",
        "STREET": "TAVŞANLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "DARGEÇİT",
        "STREET": "TEMELLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "DARGEÇİT",
        "STREET": "TEPEBAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "DARGEÇİT",
        "STREET": "YILMAZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "DARGEÇİT",
        "STREET": "YONCALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "DARGEÇİT",
        "STREET": "ÇAVUŞLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "DARGEÇİT",
        "STREET": "ÇELİKKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "DERİK",
        "STREET": "ADAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "DERİK",
        "STREET": "ADAKENT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "DERİK",
        "STREET": "AHMETLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "DERİK",
        "STREET": "AKINCILAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "DERİK",
        "STREET": "AKÇAY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "DERİK",
        "STREET": "ALAGÖZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "DERİK",
        "STREET": "ALANLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "DERİK",
        "STREET": "ALİBEY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "DERİK",
        "STREET": "AMBARLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "DERİK",
        "STREET": "ATLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "DERİK",
        "STREET": "AYDINLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "DERİK",
        "STREET": "AŞAĞIMEZRA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "DERİK",
        "STREET": "BAHÇELİEVLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "DERİK",
        "STREET": "BALLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "DERİK",
        "STREET": "BALOVA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "DERİK",
        "STREET": "BAYIRKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "DERİK",
        "STREET": "BAYRAKLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "DERİK",
        "STREET": "BAŞARAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "DERİK",
        "STREET": "BEŞBUDAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "DERİK",
        "STREET": "BOYAKLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "DERİK",
        "STREET": "BOZBAYIR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "DERİK",
        "STREET": "BOZOK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "DERİK",
        "STREET": "BURÇKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "DERİK",
        "STREET": "BÖĞREK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "DERİK",
        "STREET": "CEVİZPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "DERİK",
        "STREET": "DAĞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "DERİK",
        "STREET": "DEMİRLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "DERİK",
        "STREET": "DENKTAŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "DERİK",
        "STREET": "DERİNSU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "DERİK",
        "STREET": "DOĞANCI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "DERİK",
        "STREET": "DUMANLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "DERİK",
        "STREET": "DUMLUCA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "DERİK",
        "STREET": "DÜZTAŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "DERİK",
        "STREET": "DİKMEN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "DERİK",
        "STREET": "GÖKTAŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "DERİK",
        "STREET": "GÖLBAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "DERİK",
        "STREET": "HAYIRLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "DERİK",
        "STREET": "HİSARALTI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "DERİK",
        "STREET": "ILICA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "DERİK",
        "STREET": "ISSIZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "DERİK",
        "STREET": "KALE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "DERİK",
        "STREET": "KANATLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "DERİK",
        "STREET": "KARABURUN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "DERİK",
        "STREET": "KARATAŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "DERİK",
        "STREET": "KAYACIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "DERİK",
        "STREET": "KOCATEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "DERİK",
        "STREET": "KONAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "DERİK",
        "STREET": "KONUK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "DERİK",
        "STREET": "KOVALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "DERİK",
        "STREET": "KOVANLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "DERİK",
        "STREET": "KOÇYİĞİT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "DERİK",
        "STREET": "KURUÇAY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "DERİK",
        "STREET": "KUTLUCA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "DERİK",
        "STREET": "KUYULU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "DERİK",
        "STREET": "KUŞÇU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "DERİK",
        "STREET": "KÖSEVELİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "DERİK",
        "STREET": "KÜÇÜKPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "DERİK",
        "STREET": "MEŞELİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "DERİK",
        "STREET": "ORTACA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "DERİK",
        "STREET": "PINARCIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "DERİK",
        "STREET": "PİRİNÇLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "DERİK",
        "STREET": "SOĞUKKUYU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "DERİK",
        "STREET": "SUBAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "DERİK",
        "STREET": "SÖĞÜTÖZÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "DERİK",
        "STREET": "TAŞIT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "DERİK",
        "STREET": "TEPEBAĞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "DERİK",
        "STREET": "YAZICIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "DERİK",
        "STREET": "YUKARIMEZRA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "DERİK",
        "STREET": "ZEYTİNPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "DERİK",
        "STREET": "ÇADIRLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "DERİK",
        "STREET": "ÇATALTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "DERİK",
        "STREET": "ÇAYKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "DERİK",
        "STREET": "ÇAĞIL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "DERİK",
        "STREET": "ÇUKURSU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "DERİK",
        "STREET": "ÜÇKUYU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "DERİK",
        "STREET": "ÜÇTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "DERİK",
        "STREET": "İNCESU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "DERİK",
        "STREET": "ŞAHVERDİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "DERİK",
        "STREET": "ŞEREFLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "KIZILTEPE",
        "STREET": "AKALIN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "KIZILTEPE",
        "STREET": "AKDOĞAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "KIZILTEPE",
        "STREET": "AKKOÇ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "KIZILTEPE",
        "STREET": "AKTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "KIZILTEPE",
        "STREET": "AKTULGA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "KIZILTEPE",
        "STREET": "AKYAZI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "KIZILTEPE",
        "STREET": "AKYÜZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "KIZILTEPE",
        "STREET": "AKZİYARET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "KIZILTEPE",
        "STREET": "AKÇA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "KIZILTEPE",
        "STREET": "AKÇAPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "KIZILTEPE",
        "STREET": "ALAKUŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "KIZILTEPE",
        "STREET": "ALEMDAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "KIZILTEPE",
        "STREET": "ALTINTOPRAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "KIZILTEPE",
        "STREET": "ALİPAŞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "KIZILTEPE",
        "STREET": "ARAKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "KIZILTEPE",
        "STREET": "ARIKLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "KIZILTEPE",
        "STREET": "ARITEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "KIZILTEPE",
        "STREET": "ASLANLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "KIZILTEPE",
        "STREET": "ATAKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "KIZILTEPE",
        "STREET": "ATATÜRK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "KIZILTEPE",
        "STREET": "ATMACA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "KIZILTEPE",
        "STREET": "AŞAĞIAZIKLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "KIZILTEPE",
        "STREET": "BAHÇELİEVLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "KIZILTEPE",
        "STREET": "BARIŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "KIZILTEPE",
        "STREET": "BAĞRIBÜTÜN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "KIZILTEPE",
        "STREET": "BAŞAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "KIZILTEPE",
        "STREET": "BEKTAŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "KIZILTEPE",
        "STREET": "BELLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "KIZILTEPE",
        "STREET": "BEŞDEĞİRMEN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "KIZILTEPE",
        "STREET": "BEŞEVLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "KIZILTEPE",
        "STREET": "BEŞİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "KIZILTEPE",
        "STREET": "BOZHÖYÜK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "KIZILTEPE",
        "STREET": "BÜYÜKAYRIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "KIZILTEPE",
        "STREET": "BÜYÜKBOĞAZİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "KIZILTEPE",
        "STREET": "BÜYÜKDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "KIZILTEPE",
        "STREET": "BÜYÜKTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "KIZILTEPE",
        "STREET": "CANTAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "KIZILTEPE",
        "STREET": "CUMHURİYET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "KIZILTEPE",
        "STREET": "DAMLALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "KIZILTEPE",
        "STREET": "DEMET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "KIZILTEPE",
        "STREET": "DEMİRCİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "KIZILTEPE",
        "STREET": "DEMİRKAPI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "KIZILTEPE",
        "STREET": "DEMİRLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "KIZILTEPE",
        "STREET": "DORA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "KIZILTEPE",
        "STREET": "DOYURAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "KIZILTEPE",
        "STREET": "DOĞANLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "KIZILTEPE",
        "STREET": "DUNAYSIR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "KIZILTEPE",
        "STREET": "DÖRTYOL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "KIZILTEPE",
        "STREET": "DÜĞÜRK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "KIZILTEPE",
        "STREET": "DİKMEN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "KIZILTEPE",
        "STREET": "EKİNLİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "KIZILTEPE",
        "STREET": "ELBEYLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "KIZILTEPE",
        "STREET": "ELMALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "KIZILTEPE",
        "STREET": "EROĞLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "KIZILTEPE",
        "STREET": "ERSOYLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "KIZILTEPE",
        "STREET": "ERİKLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "KIZILTEPE",
        "STREET": "ESENLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "KIZILTEPE",
        "STREET": "ESKİN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "KIZILTEPE",
        "STREET": "EYMİRLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "KIZILTEPE",
        "STREET": "EŞME MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "KIZILTEPE",
        "STREET": "FINDIKTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "KIZILTEPE",
        "STREET": "FIRAT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "KIZILTEPE",
        "STREET": "GÖLLÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "KIZILTEPE",
        "STREET": "GÖZLÜCE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "KIZILTEPE",
        "STREET": "GÜMÜŞDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "KIZILTEPE",
        "STREET": "GÜNEŞTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "KIZILTEPE",
        "STREET": "GÜNGÖREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "KIZILTEPE",
        "STREET": "GÜNLÜCE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "KIZILTEPE",
        "STREET": "GÜRMEŞE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "KIZILTEPE",
        "STREET": "GÜÇLÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "KIZILTEPE",
        "STREET": "HACIHASAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "KIZILTEPE",
        "STREET": "HACIYUSUF MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "KIZILTEPE",
        "STREET": "HAKVERDİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "KIZILTEPE",
        "STREET": "HALKALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "KIZILTEPE",
        "STREET": "HARMANDÜZÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "KIZILTEPE",
        "STREET": "HAZNEDAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "KIZILTEPE",
        "STREET": "HOCAKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "KIZILTEPE",
        "STREET": "ILICAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "KIZILTEPE",
        "STREET": "IŞIKLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "KIZILTEPE",
        "STREET": "IŞIKÖREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "KIZILTEPE",
        "STREET": "KAHRAMAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "KIZILTEPE",
        "STREET": "KALAYCIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "KIZILTEPE",
        "STREET": "KARABENT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "KIZILTEPE",
        "STREET": "KARAKULAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "KIZILTEPE",
        "STREET": "KARAKUYU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "KIZILTEPE",
        "STREET": "KATARLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "KIZILTEPE",
        "STREET": "KAYAPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "KIZILTEPE",
        "STREET": "KAYNARCA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "KIZILTEPE",
        "STREET": "KAŞIKLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "KIZILTEPE",
        "STREET": "KENGERLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "KIZILTEPE",
        "STREET": "KILDUMAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "KIZILTEPE",
        "STREET": "KIRKKUYU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "KIZILTEPE",
        "STREET": "KOCALAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "KIZILTEPE",
        "STREET": "KONUKLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "KIZILTEPE",
        "STREET": "KOÇHİSAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "KIZILTEPE",
        "STREET": "KOÇLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "KIZILTEPE",
        "STREET": "KÖPRÜBAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "KIZILTEPE",
        "STREET": "KÖRSU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "KIZILTEPE",
        "STREET": "KÜPLÜCE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "KIZILTEPE",
        "STREET": "KÜÇÜKAYRIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "KIZILTEPE",
        "STREET": "KÜÇÜKBOĞAZİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "KIZILTEPE",
        "STREET": "KİLİMLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "KIZILTEPE",
        "STREET": "MEVLANA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "KIZILTEPE",
        "STREET": "MEZOPOTAMYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "KIZILTEPE",
        "STREET": "ODAKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "KIZILTEPE",
        "STREET": "OFİS MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "KIZILTEPE",
        "STREET": "ORTAKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "KIZILTEPE",
        "STREET": "OTLUK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "KIZILTEPE",
        "STREET": "RIHANİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "KIZILTEPE",
        "STREET": "SANAYİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "KIZILTEPE",
        "STREET": "SANCARLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "KIZILTEPE",
        "STREET": "SANDIKLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "KIZILTEPE",
        "STREET": "SARICA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "KIZILTEPE",
        "STREET": "SARUHAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "KIZILTEPE",
        "STREET": "SELAHATTİN EYYUBİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "KIZILTEPE",
        "STREET": "SEVİMLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "KIZILTEPE",
        "STREET": "SÜREKLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "KIZILTEPE",
        "STREET": "TANRIVERDİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "KIZILTEPE",
        "STREET": "TARLABAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "KIZILTEPE",
        "STREET": "TATLICA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "KIZILTEPE",
        "STREET": "TAŞLICA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "KIZILTEPE",
        "STREET": "TEPEBAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "KIZILTEPE",
        "STREET": "TIRAŞLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "KIZILTEPE",
        "STREET": "TOSUNLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "KIZILTEPE",
        "STREET": "TURGUT ÖZAL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "KIZILTEPE",
        "STREET": "TUZLUCA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "KIZILTEPE",
        "STREET": "TİMURÇİFTLİĞİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "KIZILTEPE",
        "STREET": "ULAŞLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "KIZILTEPE",
        "STREET": "ULUKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "KIZILTEPE",
        "STREET": "UZUNKAYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "KIZILTEPE",
        "STREET": "YALINKILIÇ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "KIZILTEPE",
        "STREET": "YAMANLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "KIZILTEPE",
        "STREET": "YAMAÇ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "KIZILTEPE",
        "STREET": "YARIMCA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "KIZILTEPE",
        "STREET": "YAYIKLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "KIZILTEPE",
        "STREET": "YAYLIM MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "KIZILTEPE",
        "STREET": "YAŞARKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "KIZILTEPE",
        "STREET": "YENİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "KIZILTEPE",
        "STREET": "YENİKENT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "KIZILTEPE",
        "STREET": "YEŞİLKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "KIZILTEPE",
        "STREET": "YEŞİLLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "KIZILTEPE",
        "STREET": "YOLALDI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "KIZILTEPE",
        "STREET": "YOLDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "KIZILTEPE",
        "STREET": "YOLÜSTÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "KIZILTEPE",
        "STREET": "YONCALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "KIZILTEPE",
        "STREET": "YUKARIAZIKLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "KIZILTEPE",
        "STREET": "YUMRUTAŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "KIZILTEPE",
        "STREET": "YUMURCAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "KIZILTEPE",
        "STREET": "YURTDERİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "KIZILTEPE",
        "STREET": "YURTÖZÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "KIZILTEPE",
        "STREET": "YÜCELİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "KIZILTEPE",
        "STREET": "YÜKSEKTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "KIZILTEPE",
        "STREET": "ZERGAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "KIZILTEPE",
        "STREET": "ZİYARET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "KIZILTEPE",
        "STREET": "ÇAKIR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "KIZILTEPE",
        "STREET": "ÇAMLICA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "KIZILTEPE",
        "STREET": "ÇAMLIDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "KIZILTEPE",
        "STREET": "ÇANAKLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "KIZILTEPE",
        "STREET": "ÇATALCA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "KIZILTEPE",
        "STREET": "ÇAYBAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "KIZILTEPE",
        "STREET": "ÇAĞIL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "KIZILTEPE",
        "STREET": "ÇETİNKAYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "KIZILTEPE",
        "STREET": "ÇETİNLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "KIZILTEPE",
        "STREET": "ÇINARCIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "KIZILTEPE",
        "STREET": "ÇIPLAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "KIZILTEPE",
        "STREET": "ÇİMENLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "KIZILTEPE",
        "STREET": "ÇİTLİBAĞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "KIZILTEPE",
        "STREET": "ÖRENCİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "KIZILTEPE",
        "STREET": "ÜLKER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "KIZILTEPE",
        "STREET": "ÜÇEVLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "KIZILTEPE",
        "STREET": "İKİKUYU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "KIZILTEPE",
        "STREET": "İKİZLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "KIZILTEPE",
        "STREET": "İNANDI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "KIZILTEPE",
        "STREET": "İPEK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "KIZILTEPE",
        "STREET": "ŞAHKULUBEY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "KIZILTEPE",
        "STREET": "ŞENYURT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "MAZIDAĞI",
        "STREET": "AKSU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "MAZIDAĞI",
        "STREET": "ARIKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "MAZIDAĞI",
        "STREET": "ARISU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "MAZIDAĞI",
        "STREET": "ATALAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "MAZIDAĞI",
        "STREET": "ATLICA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "MAZIDAĞI",
        "STREET": "AYKUT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "MAZIDAĞI",
        "STREET": "BAHÇECİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "MAZIDAĞI",
        "STREET": "BALPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "MAZIDAĞI",
        "STREET": "BİLGE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "MAZIDAĞI",
        "STREET": "DERECİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "MAZIDAĞI",
        "STREET": "DURAKLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "MAZIDAĞI",
        "STREET": "DİKYAMAÇ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "MAZIDAĞI",
        "STREET": "EKİNCİLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "MAZIDAĞI",
        "STREET": "ENGİN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "MAZIDAĞI",
        "STREET": "ERDALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "MAZIDAĞI",
        "STREET": "EVCİLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "MAZIDAĞI",
        "STREET": "GÜMÜŞPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "MAZIDAĞI",
        "STREET": "GÜMÜŞYUVA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "MAZIDAĞI",
        "STREET": "GÜNDOĞAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "MAZIDAĞI",
        "STREET": "GÜRGÖZE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "MAZIDAĞI",
        "STREET": "IŞIKYAKA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "MAZIDAĞI",
        "STREET": "KARAALANİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "MAZIDAĞI",
        "STREET": "KARATAŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "MAZIDAĞI",
        "STREET": "KARŞIYAKA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "MAZIDAĞI",
        "STREET": "KAYALAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "MAZIDAĞI",
        "STREET": "KEBAPÇI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "MAZIDAĞI",
        "STREET": "KEMERLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "MAZIDAĞI",
        "STREET": "KIŞLAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "MAZIDAĞI",
        "STREET": "KOCAKENT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "MAZIDAĞI",
        "STREET": "KONUR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "MAZIDAĞI",
        "STREET": "MEŞELİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "MAZIDAĞI",
        "STREET": "ORTAKLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "MAZIDAĞI",
        "STREET": "POYRAZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "MAZIDAĞI",
        "STREET": "SAKIZLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "MAZIDAĞI",
        "STREET": "SAĞMAL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "MAZIDAĞI",
        "STREET": "TANRIYOLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "MAZIDAĞI",
        "STREET": "TARLACIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "MAZIDAĞI",
        "STREET": "ULUTAŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "MAZIDAĞI",
        "STREET": "YALINAĞAÇ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "MAZIDAĞI",
        "STREET": "YAĞMUR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "MAZIDAĞI",
        "STREET": "YETKİNLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "MAZIDAĞI",
        "STREET": "YEŞİLKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "MAZIDAĞI",
        "STREET": "YUKARIKONAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "MAZIDAĞI",
        "STREET": "YUKARIOCAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "MAZIDAĞI",
        "STREET": "YÜCEBAĞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "MAZIDAĞI",
        "STREET": "ÇANKAYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "MAZIDAĞI",
        "STREET": "ÇAYÖNÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "MAZIDAĞI",
        "STREET": "ÖMÜRLÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "MAZIDAĞI",
        "STREET": "ÜRÜNLÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "MAZIDAĞI",
        "STREET": "İKİSU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "MAZIDAĞI",
        "STREET": "ŞANLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "MAZIDAĞI",
        "STREET": "ŞENYUVA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "MİDYAT",
        "STREET": "ACIRLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "MİDYAT",
        "STREET": "ADAKLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "MİDYAT",
        "STREET": "AKÇAKAYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "MİDYAT",
        "STREET": "ALTINTAŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "MİDYAT",
        "STREET": "ANITLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "MİDYAT",
        "STREET": "BAHÇELİEVLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "MİDYAT",
        "STREET": "BARDAKÇI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "MİDYAT",
        "STREET": "BARIŞTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "MİDYAT",
        "STREET": "BAĞLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "MİDYAT",
        "STREET": "BAĞLARBAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "MİDYAT",
        "STREET": "BAŞYURT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "MİDYAT",
        "STREET": "BUDAKLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "MİDYAT",
        "STREET": "CUMHURİYET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "MİDYAT",
        "STREET": "DANIŞMAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "MİDYAT",
        "STREET": "DOLUNAY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "MİDYAT",
        "STREET": "DOĞANYAZI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "MİDYAT",
        "STREET": "DOĞANÇAY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "MİDYAT",
        "STREET": "DÜZGEÇİT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "MİDYAT",
        "STREET": "DÜZOBA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "MİDYAT",
        "STREET": "ELBEĞENDİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "MİDYAT",
        "STREET": "ERİŞTİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "MİDYAT",
        "STREET": "EĞLENCE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "MİDYAT",
        "STREET": "GELİNKAYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "MİDYAT",
        "STREET": "GÖLCÜK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "MİDYAT",
        "STREET": "GÜLGÖZE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "MİDYAT",
        "STREET": "GÜLVEREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "MİDYAT",
        "STREET": "GÜNGÖREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "MİDYAT",
        "STREET": "GÜVEN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "MİDYAT",
        "STREET": "HANLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "MİDYAT",
        "STREET": "HARMANLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "MİDYAT",
        "STREET": "IŞIKLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "MİDYAT",
        "STREET": "KAYALAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "MİDYAT",
        "STREET": "KAYALIPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "MİDYAT",
        "STREET": "KUTLUBEY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "MİDYAT",
        "STREET": "MERCİMEKLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "MİDYAT",
        "STREET": "MİDYAT M TİPİ KAPALI CEZA İNFAZ KURUMU",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "MİDYAT",
        "STREET": "NARLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "MİDYAT",
        "STREET": "ORTACA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "MİDYAT",
        "STREET": "ORTAÇARŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "MİDYAT",
        "STREET": "OYUKLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "MİDYAT",
        "STREET": "PELİTLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "MİDYAT",
        "STREET": "SANAYİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "MİDYAT",
        "STREET": "SARIKAYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "MİDYAT",
        "STREET": "SARIKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "MİDYAT",
        "STREET": "SEYİTLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "MİDYAT",
        "STREET": "SÖĞÜTLÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "MİDYAT",
        "STREET": "SİVRİCE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "MİDYAT",
        "STREET": "TAŞLIBURÇ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "MİDYAT",
        "STREET": "TEPELİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "MİDYAT",
        "STREET": "TOPTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "MİDYAT",
        "STREET": "TULGALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "MİDYAT",
        "STREET": "ULUCAMİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "MİDYAT",
        "STREET": "YAYVANTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "MİDYAT",
        "STREET": "YEMİŞLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "MİDYAT",
        "STREET": "YENİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "MİDYAT",
        "STREET": "YENİCE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "MİDYAT",
        "STREET": "YEŞİLÖZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "MİDYAT",
        "STREET": "YOLBAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "MİDYAT",
        "STREET": "YUNUS EMRE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "MİDYAT",
        "STREET": "YUVALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "MİDYAT",
        "STREET": "ZİYARET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "MİDYAT",
        "STREET": "ÇALDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "MİDYAT",
        "STREET": "ÇALPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "MİDYAT",
        "STREET": "ÇAMYURT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "MİDYAT",
        "STREET": "ÇANDARLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "MİDYAT",
        "STREET": "ÇAVUŞLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "MİDYAT",
        "STREET": "ÇAYIRLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "MİDYAT",
        "STREET": "ÜÇAĞIL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "MİDYAT",
        "STREET": "İKİZDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "MİDYAT",
        "STREET": "İZBIRAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "MİDYAT",
        "STREET": "ŞENKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "NUSAYBİN",
        "STREET": "8 MART MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "NUSAYBİN",
        "STREET": "ABDULKADİRPAŞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "NUSAYBİN",
        "STREET": "AKARSU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "NUSAYBİN",
        "STREET": "AKAĞIL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "NUSAYBİN",
        "STREET": "AKÇATARLA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "NUSAYBİN",
        "STREET": "AÇIKKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "NUSAYBİN",
        "STREET": "AÇIKYOL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "NUSAYBİN",
        "STREET": "BAHÇEBAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "NUSAYBİN",
        "STREET": "BAKACIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "NUSAYBİN",
        "STREET": "BALABAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "NUSAYBİN",
        "STREET": "BARIŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "NUSAYBİN",
        "STREET": "BEYLİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "NUSAYBİN",
        "STREET": "BÜYÜKKARDEŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "NUSAYBİN",
        "STREET": "DALLIAĞAÇ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "NUSAYBİN",
        "STREET": "DAĞİÇİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "NUSAYBİN",
        "STREET": "DEMİRTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "NUSAYBİN",
        "STREET": "DEVRİM MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "NUSAYBİN",
        "STREET": "DOĞANLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "NUSAYBİN",
        "STREET": "DURAKBAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "NUSAYBİN",
        "STREET": "DURUCA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "NUSAYBİN",
        "STREET": "DÜZCE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "NUSAYBİN",
        "STREET": "DİBEK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "NUSAYBİN",
        "STREET": "DİCLE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "NUSAYBİN",
        "STREET": "ESKİHİSAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "NUSAYBİN",
        "STREET": "ESKİYOL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "NUSAYBİN",
        "STREET": "FIRAT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "NUSAYBİN",
        "STREET": "GIRNAVAS MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "NUSAYBİN",
        "STREET": "GÖRENTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "NUSAYBİN",
        "STREET": "GÜNEBAKAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "NUSAYBİN",
        "STREET": "GÜNELİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "NUSAYBİN",
        "STREET": "GÜNYURDU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "NUSAYBİN",
        "STREET": "GÜRÜN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "NUSAYBİN",
        "STREET": "GÜVENLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "NUSAYBİN",
        "STREET": "GİRMELİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "NUSAYBİN",
        "STREET": "HASANTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "NUSAYBİN",
        "STREET": "HEYBELİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "NUSAYBİN",
        "STREET": "KALECİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "NUSAYBİN",
        "STREET": "KANTAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "NUSAYBİN",
        "STREET": "KAYADİBİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "NUSAYBİN",
        "STREET": "KIŞLA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "NUSAYBİN",
        "STREET": "KOCADAĞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "NUSAYBİN",
        "STREET": "KURUKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "NUSAYBİN",
        "STREET": "KUYULAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "NUSAYBİN",
        "STREET": "KÜÇÜKKARDEŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "NUSAYBİN",
        "STREET": "MOR YAKUP MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "NUSAYBİN",
        "STREET": "NERGİZLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "NUSAYBİN",
        "STREET": "ODABAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "NUSAYBİN",
        "STREET": "SELAHADDİN EYYUBİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "NUSAYBİN",
        "STREET": "SINIRTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "NUSAYBİN",
        "STREET": "SÖĞÜTLÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "NUSAYBİN",
        "STREET": "TAŞKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "NUSAYBİN",
        "STREET": "TEKAĞAÇ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "NUSAYBİN",
        "STREET": "TEPEALTI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "NUSAYBİN",
        "STREET": "TEPEÖREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "NUSAYBİN",
        "STREET": "TEPEÜSTÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "NUSAYBİN",
        "STREET": "TURGUTKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "NUSAYBİN",
        "STREET": "YANDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "NUSAYBİN",
        "STREET": "YAZYURDU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "NUSAYBİN",
        "STREET": "YENİTURAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "NUSAYBİN",
        "STREET": "YENİŞEHİR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "NUSAYBİN",
        "STREET": "YERKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "NUSAYBİN",
        "STREET": "YEŞİLKENT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "NUSAYBİN",
        "STREET": "YOLBİLEN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "NUSAYBİN",
        "STREET": "YOLİNDİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "NUSAYBİN",
        "STREET": "ZEYNELABİDİN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "NUSAYBİN",
        "STREET": "ÇALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "NUSAYBİN",
        "STREET": "ÇATALÖZÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "NUSAYBİN",
        "STREET": "ÇAĞLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "NUSAYBİN",
        "STREET": "ÇÖLOVA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "NUSAYBİN",
        "STREET": "ÇİLESİZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "NUSAYBİN",
        "STREET": "ÇİĞDEM MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "NUSAYBİN",
        "STREET": "ÜÇKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "NUSAYBİN",
        "STREET": "ÜÇYOL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "NUSAYBİN",
        "STREET": "İKİZTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "NUSAYBİN",
        "STREET": "İPEKYOLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "SAVUR",
        "STREET": "AKYÜREK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "SAVUR",
        "STREET": "ARMUTALAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "SAVUR",
        "STREET": "BAĞYAKA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "SAVUR",
        "STREET": "BAŞAĞAÇ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "SAVUR",
        "STREET": "BAŞKAVAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "SAVUR",
        "STREET": "BENGİSU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "SAVUR",
        "STREET": "DEREİÇİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "SAVUR",
        "STREET": "DURUSU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "SAVUR",
        "STREET": "ERKURAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "SAVUR",
        "STREET": "GÖLBAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "SAVUR",
        "STREET": "HARMANTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "SAVUR",
        "STREET": "HİSARKAYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "SAVUR",
        "STREET": "KAPLAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "SAVUR",
        "STREET": "KARAKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "SAVUR",
        "STREET": "KAYACIKLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "SAVUR",
        "STREET": "KAYATEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "SAVUR",
        "STREET": "KIRBALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "SAVUR",
        "STREET": "KIRKDİREK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "SAVUR",
        "STREET": "KOCAHÜYÜK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "SAVUR",
        "STREET": "KOŞUYOLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "SAVUR",
        "STREET": "KÖPRÜLÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "SAVUR",
        "STREET": "ORMANCIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "SAVUR",
        "STREET": "PINARDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "SAVUR",
        "STREET": "SAFA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "SAVUR",
        "STREET": "SANCAKLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "SAVUR",
        "STREET": "SERENLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "SAVUR",
        "STREET": "SEYDİN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "SAVUR",
        "STREET": "SOYLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "SAVUR",
        "STREET": "SÜRGÜCÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "SAVUR",
        "STREET": "TAŞLIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "SAVUR",
        "STREET": "TOKLUCA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "SAVUR",
        "STREET": "YAYLAYANI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "SAVUR",
        "STREET": "YAZIR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "SAVUR",
        "STREET": "YENİLMEZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "SAVUR",
        "STREET": "YEŞİLALAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "SAVUR",
        "STREET": "ÇINARÖNÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "SAVUR",
        "STREET": "ÜÇERLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "SAVUR",
        "STREET": "ÜÇKAVAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "SAVUR",
        "STREET": "İÇÖREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "SAVUR",
        "STREET": "İŞGÖREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "SAVUR",
        "STREET": "ŞENOCAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "YEŞİLLİ",
        "STREET": "ALIÇLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "YEŞİLLİ",
        "STREET": "BAHÇEBAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "YEŞİLLİ",
        "STREET": "BÜLBÜL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "YEŞİLLİ",
        "STREET": "DEREYANI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "YEŞİLLİ",
        "STREET": "GÜL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "YEŞİLLİ",
        "STREET": "KARŞIYAKA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "YEŞİLLİ",
        "STREET": "KOYUNLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "YEŞİLLİ",
        "STREET": "KÜTÜKLÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "YEŞİLLİ",
        "STREET": "OVAKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "YEŞİLLİ",
        "STREET": "SANCAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "YEŞİLLİ",
        "STREET": "TEPEBAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "YEŞİLLİ",
        "STREET": "UZUNKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "YEŞİLLİ",
        "STREET": "ZEYTİNLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "YEŞİLLİ",
        "STREET": "ŞİRİNEVLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "ÖMERLİ",
        "STREET": "ALIÇLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "ÖMERLİ",
        "STREET": "ANITTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "ÖMERLİ",
        "STREET": "BEŞİKKAYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "ÖMERLİ",
        "STREET": "CUMHURİYET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "ÖMERLİ",
        "STREET": "FISTIKLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "ÖMERLİ",
        "STREET": "GÖLLÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "ÖMERLİ",
        "STREET": "GÜZELAĞAÇ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "ÖMERLİ",
        "STREET": "KAYABALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "ÖMERLİ",
        "STREET": "KAYADERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "ÖMERLİ",
        "STREET": "KAYAÜSTÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "ÖMERLİ",
        "STREET": "KAYNAKKAYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "ÖMERLİ",
        "STREET": "KOCAKUYU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "ÖMERLİ",
        "STREET": "KOCASIRT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "ÖMERLİ",
        "STREET": "MUTLUCA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "ÖMERLİ",
        "STREET": "PINARCIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "ÖMERLİ",
        "STREET": "SALİHKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "ÖMERLİ",
        "STREET": "SULAKDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "ÖMERLİ",
        "STREET": "SİVRİTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "ÖMERLİ",
        "STREET": "TAVUKLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "ÖMERLİ",
        "STREET": "TAŞGEDİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "ÖMERLİ",
        "STREET": "TAŞLICA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "ÖMERLİ",
        "STREET": "TEKKUYU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "ÖMERLİ",
        "STREET": "TOKDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "ÖMERLİ",
        "STREET": "TOPAĞAÇ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "ÖMERLİ",
        "STREET": "YAYLATEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "ÖMERLİ",
        "STREET": "YENİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "ÖMERLİ",
        "STREET": "ÇINARALTI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "ÖMERLİ",
        "STREET": "ÖZTAŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "ÖMERLİ",
        "STREET": "İKİPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MARDİN",
        "DISTRICT": "ÖMERLİ",
        "STREET": "ŞAFAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "AKDENİZ",
        "STREET": "ABDULLAH ŞAHUTOĞLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "AKDENİZ",
        "STREET": "ADANALIOĞLU LİMONLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "AKDENİZ",
        "STREET": "ADANALIOĞLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "AKDENİZ",
        "STREET": "ADNAN MENDERES MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "AKDENİZ",
        "STREET": "AKDAM MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "AKDENİZ",
        "STREET": "AKDENİZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "AKDENİZ",
        "STREET": "ANADOLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "AKDENİZ",
        "STREET": "BAHÇE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "AKDENİZ",
        "STREET": "BAHÇELİ AŞAĞIBURHAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "AKDENİZ",
        "STREET": "BAHÇELİ KÜRKÇÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "AKDENİZ",
        "STREET": "BAHÇELİ YUKARIBURHAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "AKDENİZ",
        "STREET": "BAHŞİŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "AKDENİZ",
        "STREET": "BARIŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "AKDENİZ",
        "STREET": "BAĞCILAR-İHSANİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "AKDENİZ",
        "STREET": "BAĞLARBAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "AKDENİZ",
        "STREET": "BEKİRDE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "AKDENİZ",
        "STREET": "CAMİ ŞERİF MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "AKDENİZ",
        "STREET": "CAMİLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "AKDENİZ",
        "STREET": "CUMHURİYET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "AKDENİZ",
        "STREET": "CİVANYAYLAĞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "AKDENİZ",
        "STREET": "DEMİRHİSAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "AKDENİZ",
        "STREET": "DİKİLİTAŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "AKDENİZ",
        "STREET": "ESENLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "AKDENİZ",
        "STREET": "EVCİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "AKDENİZ",
        "STREET": "GAZİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "AKDENİZ",
        "STREET": "GÜNDOĞDU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "AKDENİZ",
        "STREET": "GÜNEŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "AKDENİZ",
        "STREET": "HAL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "AKDENİZ",
        "STREET": "HAMİDİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "AKDENİZ",
        "STREET": "HEBİLLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "AKDENİZ",
        "STREET": "HÜRRİYET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "AKDENİZ",
        "STREET": "IĞDIR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "AKDENİZ",
        "STREET": "KARACAİLYAS EMEK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "AKDENİZ",
        "STREET": "KARACAİLYAS EVREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "AKDENİZ",
        "STREET": "KARACAİLYAS MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "AKDENİZ",
        "STREET": "KARADUVAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "AKDENİZ",
        "STREET": "KAZANLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "AKDENİZ",
        "STREET": "KULAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "AKDENİZ",
        "STREET": "KÖSELERLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "AKDENİZ",
        "STREET": "KÜLTÜR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "AKDENİZ",
        "STREET": "KİREMİTHANE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "AKDENİZ",
        "STREET": "MAHMUDİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "AKDENİZ",
        "STREET": "MERSİN E TİPİ KAPALI -AÇIK CEZA İNFAZ KURUMU",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "AKDENİZ",
        "STREET": "MESUDİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "AKDENİZ",
        "STREET": "MÜFİDE İLHAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "AKDENİZ",
        "STREET": "MİTHATPAŞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "AKDENİZ",
        "STREET": "NACARLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "AKDENİZ",
        "STREET": "NUSRATİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "AKDENİZ",
        "STREET": "PARMAKKURDU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "AKDENİZ",
        "STREET": "PUĞKARACADAĞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "AKDENİZ",
        "STREET": "SARIİBRAHİMLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "AKDENİZ",
        "STREET": "SİTELER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "AKDENİZ",
        "STREET": "TOROSLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "AKDENİZ",
        "STREET": "TURGUTREİS MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "AKDENİZ",
        "STREET": "YANPAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "AKDENİZ",
        "STREET": "YENİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "AKDENİZ",
        "STREET": "YEŞİLOVA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "AKDENİZ",
        "STREET": "YEŞİLÇİMEN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "AKDENİZ",
        "STREET": "ÇANKAYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "AKDENİZ",
        "STREET": "ÇAY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "AKDENİZ",
        "STREET": "ÇİLEK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "AKDENİZ",
        "STREET": "ÖZGÜRLÜK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "AKDENİZ",
        "STREET": "ÜÇOCAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "AKDENİZ",
        "STREET": "İHSANİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "AKDENİZ",
        "STREET": "ŞAKİRGÜLMEN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "AKDENİZ",
        "STREET": "ŞEVKETSÜMER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "ANAMUR",
        "STREET": "AKARCA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "ANAMUR",
        "STREET": "AKDENİZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "ANAMUR",
        "STREET": "AKİNE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "ANAMUR",
        "STREET": "ALATAŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "ANAMUR",
        "STREET": "ANAMUR KAPALI CEZA VE TUTUK EVİ",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "ANAMUR",
        "STREET": "ANITLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "ANAMUR",
        "STREET": "AŞAĞIKÜKÜR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "ANAMUR",
        "STREET": "BAHÇE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "ANAMUR",
        "STREET": "BAHÇELİEVLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "ANAMUR",
        "STREET": "BOZDOĞAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "ANAMUR",
        "STREET": "BOĞUNTU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "ANAMUR",
        "STREET": "DEMİRÖREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "ANAMUR",
        "STREET": "EMİRŞAH MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "ANAMUR",
        "STREET": "ESENTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "ANAMUR",
        "STREET": "EVCİLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "ANAMUR",
        "STREET": "FATİH MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "ANAMUR",
        "STREET": "GERCEBAHŞİŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "ANAMUR",
        "STREET": "GÖKTAŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "ANAMUR",
        "STREET": "GÜLEÇ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "ANAMUR",
        "STREET": "GÜNEYBAHŞİŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "ANAMUR",
        "STREET": "GÜNGÖREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "ANAMUR",
        "STREET": "GÜZELYURT  MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "ANAMUR",
        "STREET": "KALINÖREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "ANAMUR",
        "STREET": "KARAAĞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "ANAMUR",
        "STREET": "KARADERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "ANAMUR",
        "STREET": "KARALARBAHŞİŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "ANAMUR",
        "STREET": "KARAÇUKUR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "ANAMUR",
        "STREET": "KAŞDİŞLEN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "ANAMUR",
        "STREET": "KILIÇ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "ANAMUR",
        "STREET": "KIZILALİLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "ANAMUR",
        "STREET": "KORUCUK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "ANAMUR",
        "STREET": "KÖPRÜBAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "ANAMUR",
        "STREET": "LALE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "ANAMUR",
        "STREET": "MALAKLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "ANAMUR",
        "STREET": "ORMANCIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "ANAMUR",
        "STREET": "OVABAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "ANAMUR",
        "STREET": "SARAY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "ANAMUR",
        "STREET": "SARIAĞAÇ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "ANAMUR",
        "STREET": "SARIDANA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "ANAMUR",
        "STREET": "SAĞLIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "ANAMUR",
        "STREET": "SUGÖZÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "ANAMUR",
        "STREET": "SULTAN ALAADDİN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "ANAMUR",
        "STREET": "TOROSLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "ANAMUR",
        "STREET": "UÇARI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "ANAMUR",
        "STREET": "YALIEVLERİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "ANAMUR",
        "STREET": "YEŞİLYURT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "ANAMUR",
        "STREET": "YILDIRIM BEYAZIT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "ANAMUR",
        "STREET": "YUKARI KÜKÜR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "ANAMUR",
        "STREET": "ÇALTIBÜKÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "ANAMUR",
        "STREET": "ÇAMLIPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "ANAMUR",
        "STREET": "ÇAMLIPINARALANI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "ANAMUR",
        "STREET": "ÇARIKLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "ANAMUR",
        "STREET": "ÇATALOLUK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "ANAMUR",
        "STREET": "ÇELTİKÇİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "ANAMUR",
        "STREET": "ÇUKURABANOZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "ANAMUR",
        "STREET": "ÖREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "ANAMUR",
        "STREET": "İSKELE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "AYDINCIK",
        "STREET": "ATATÜRK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "AYDINCIK",
        "STREET": "CUMHURİYET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "AYDINCIK",
        "STREET": "DURUHAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "AYDINCIK",
        "STREET": "ESKİYÜRÜK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "AYDINCIK",
        "STREET": "HACIBAHATTİN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "AYDINCIK",
        "STREET": "HÜRRİYET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "AYDINCIK",
        "STREET": "KARADERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "AYDINCIK",
        "STREET": "KARASEKİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "AYDINCIK",
        "STREET": "MERKEZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "AYDINCIK",
        "STREET": "PEMBECİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "AYDINCIK",
        "STREET": "TEKNECİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "AYDINCIK",
        "STREET": "YENİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "AYDINCIK",
        "STREET": "YENİKAŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "AYDINCIK",
        "STREET": "YENİYÜRÜK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "AYDINCIK",
        "STREET": "YENİYÜRÜKKAŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "BOZYAZI",
        "STREET": "AKCAMİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "BOZYAZI",
        "STREET": "ARDIÇLITAŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "BOZYAZI",
        "STREET": "BAHÇEKOYAĞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "BOZYAZI",
        "STREET": "BEYRELİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "BOZYAZI",
        "STREET": "DENİZCİLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "BOZYAZI",
        "STREET": "DEREBAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "BOZYAZI",
        "STREET": "DEREKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "BOZYAZI",
        "STREET": "ELMAKUZU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "BOZYAZI",
        "STREET": "GÖZCE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "BOZYAZI",
        "STREET": "GÖZSÜZCE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "BOZYAZI",
        "STREET": "GÜRLEVİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "BOZYAZI",
        "STREET": "KALEDİBİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "BOZYAZI",
        "STREET": "KARAİSALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "BOZYAZI",
        "STREET": "KIZILCA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "BOZYAZI",
        "STREET": "KÖMÜRLÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "BOZYAZI",
        "STREET": "KÖTEKLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "BOZYAZI",
        "STREET": "LENGER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "BOZYAZI",
        "STREET": "MERKEZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "BOZYAZI",
        "STREET": "NARİNCE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "BOZYAZI",
        "STREET": "SICAKYURT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "BOZYAZI",
        "STREET": "TEKEDÜZÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "BOZYAZI",
        "STREET": "TEKELİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "BOZYAZI",
        "STREET": "TEKMEN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "BOZYAZI",
        "STREET": "USTALAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "BOZYAZI",
        "STREET": "ÇOPURLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "BOZYAZI",
        "STREET": "ÇUBUKKOYAĞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "ERDEMLİ",
        "STREET": "ADNAN MENDERES MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "ERDEMLİ",
        "STREET": "AKDENİZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "ERDEMLİ",
        "STREET": "AKPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "ERDEMLİ",
        "STREET": "ALATA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "ERDEMLİ",
        "STREET": "ALİBEYLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "ERDEMLİ",
        "STREET": "ARPAÇBAHŞİŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "ERDEMLİ",
        "STREET": "ARSLANLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "ERDEMLİ",
        "STREET": "AYAŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "ERDEMLİ",
        "STREET": "AYDINLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "ERDEMLİ",
        "STREET": "BARBAROS MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "ERDEMLİ",
        "STREET": "BATISANDAL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "ERDEMLİ",
        "STREET": "DAĞLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "ERDEMLİ",
        "STREET": "DOĞUSANDAL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "ERDEMLİ",
        "STREET": "ELBEYLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "ERDEMLİ",
        "STREET": "ELVANLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "ERDEMLİ",
        "STREET": "ERDEMLİ KAPALI CEZAEVİ",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "ERDEMLİ",
        "STREET": "ESENPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "ERDEMLİ",
        "STREET": "EVDİLEK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "ERDEMLİ",
        "STREET": "FAKILI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "ERDEMLİ",
        "STREET": "FATİH MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "ERDEMLİ",
        "STREET": "GÜCÜŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "ERDEMLİ",
        "STREET": "GÜNEYLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "ERDEMLİ",
        "STREET": "GÜZELOLUK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "ERDEMLİ",
        "STREET": "HACIALANI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "ERDEMLİ",
        "STREET": "HACIHALİLARPAÇ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "ERDEMLİ",
        "STREET": "HARFİLLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "ERDEMLİ",
        "STREET": "HÜSAMETLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "ERDEMLİ",
        "STREET": "KARAAHMETLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "ERDEMLİ",
        "STREET": "KARAHIDIRLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "ERDEMLİ",
        "STREET": "KARAKEŞLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "ERDEMLİ",
        "STREET": "KARAYAKUP MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "ERDEMLİ",
        "STREET": "KARGICAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "ERDEMLİ",
        "STREET": "KARGIPINARI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "ERDEMLİ",
        "STREET": "KAYACI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "ERDEMLİ",
        "STREET": "KIZILEN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "ERDEMLİ",
        "STREET": "KIZKALESİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "ERDEMLİ",
        "STREET": "KOCAHASANLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "ERDEMLİ",
        "STREET": "KORAMŞALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "ERDEMLİ",
        "STREET": "KOYUNCU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "ERDEMLİ",
        "STREET": "KUŞLUCA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "ERDEMLİ",
        "STREET": "KÖSBUCAĞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "ERDEMLİ",
        "STREET": "KÖSERELİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "ERDEMLİ",
        "STREET": "KÜSTÜLÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "ERDEMLİ",
        "STREET": "LİMONLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "ERDEMLİ",
        "STREET": "MERKEZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "ERDEMLİ",
        "STREET": "MERKEZ-TIRTAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "ERDEMLİ",
        "STREET": "PINARBAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "ERDEMLİ",
        "STREET": "SARIKAYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "ERDEMLİ",
        "STREET": "SARIYER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "ERDEMLİ",
        "STREET": "SIRAÇ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "ERDEMLİ",
        "STREET": "SORGUN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "ERDEMLİ",
        "STREET": "SİNAP MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "ERDEMLİ",
        "STREET": "TABİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "ERDEMLİ",
        "STREET": "TAPURELİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "ERDEMLİ",
        "STREET": "TOROS MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "ERDEMLİ",
        "STREET": "TOZLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "ERDEMLİ",
        "STREET": "TÖMÜK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "ERDEMLİ",
        "STREET": "TÜRBE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "ERDEMLİ",
        "STREET": "VEYSELLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "ERDEMLİ",
        "STREET": "YARENLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "ERDEMLİ",
        "STREET": "YAĞDA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "ERDEMLİ",
        "STREET": "YENİYURT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "ERDEMLİ",
        "STREET": "YÜKSEK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "ERDEMLİ",
        "STREET": "ÇAMLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "ERDEMLİ",
        "STREET": "ÇERÇİLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "ERDEMLİ",
        "STREET": "ÇEŞMELİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "ERDEMLİ",
        "STREET": "ÇİFTEPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "ERDEMLİ",
        "STREET": "ÇİRİŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "ERDEMLİ",
        "STREET": "ÜZÜMLÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "ERDEMLİ",
        "STREET": "ÜÇTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "ERDEMLİ",
        "STREET": "İLEMİN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "ERDEMLİ",
        "STREET": "ŞAHNA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "GÜLNAR",
        "STREET": "AKDENİZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "GÜLNAR",
        "STREET": "AKOVA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "GÜLNAR",
        "STREET": "ARDIÇPINARI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "GÜLNAR",
        "STREET": "ARIKUYUSU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "GÜLNAR",
        "STREET": "AYVALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "GÜLNAR",
        "STREET": "BEREKET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "GÜLNAR",
        "STREET": "BEYDİLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "GÜLNAR",
        "STREET": "BOLYARAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "GÜLNAR",
        "STREET": "BOZAĞAÇ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "GÜLNAR",
        "STREET": "BÜYÜKECELİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "GÜLNAR",
        "STREET": "DAYICIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "GÜLNAR",
        "STREET": "DEDELER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "GÜLNAR",
        "STREET": "DELİKKAYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "GÜLNAR",
        "STREET": "DEMİRÖZÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "GÜLNAR",
        "STREET": "EMİRHACI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "GÜLNAR",
        "STREET": "GEZENDE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "GÜLNAR",
        "STREET": "GÖKTÜRK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "GÜLNAR",
        "STREET": "HACIPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "GÜLNAR",
        "STREET": "HALİFELER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "GÜLNAR",
        "STREET": "ILISU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "GÜLNAR",
        "STREET": "KAVAKOLUĞU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "GÜLNAR",
        "STREET": "KAYRAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "GÜLNAR",
        "STREET": "KONUR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "GÜLNAR",
        "STREET": "KORUCUK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "GÜLNAR",
        "STREET": "KOÇAŞLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "GÜLNAR",
        "STREET": "KURBAĞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "GÜLNAR",
        "STREET": "KUSKAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "GÜLNAR",
        "STREET": "KÖSEÇOBANLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "GÜLNAR",
        "STREET": "MOLLAÖMERLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "GÜLNAR",
        "STREET": "SARAY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "GÜLNAR",
        "STREET": "SARIKAVAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "GÜLNAR",
        "STREET": "SİPAHİLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "GÜLNAR",
        "STREET": "TAŞOLUK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "GÜLNAR",
        "STREET": "TEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "GÜLNAR",
        "STREET": "TIRNAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "GÜLNAR",
        "STREET": "TOZKOVAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "GÜLNAR",
        "STREET": "ULUPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "GÜLNAR",
        "STREET": "YANIŞLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "GÜLNAR",
        "STREET": "YARMASU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "GÜLNAR",
        "STREET": "YASSIBAĞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "GÜLNAR",
        "STREET": "YENİCE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "GÜLNAR",
        "STREET": "ZEYNE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "GÜLNAR",
        "STREET": "ÇAVUŞLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "GÜLNAR",
        "STREET": "ÇUKURASMA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "GÜLNAR",
        "STREET": "ÇUKURKONAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "GÜLNAR",
        "STREET": "ÖRENPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "GÜLNAR",
        "STREET": "ÖRTÜLÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "GÜLNAR",
        "STREET": "ÜÇOLUK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "GÜLNAR",
        "STREET": "İSHAKLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "GÜLNAR",
        "STREET": "ŞEYHÖMER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "MEZİTLİ",
        "STREET": "75.YIL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "MEZİTLİ",
        "STREET": "AKARCA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "MEZİTLİ",
        "STREET": "AKDENİZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "MEZİTLİ",
        "STREET": "ANAYURT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "MEZİTLİ",
        "STREET": "ATATÜRK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "MEZİTLİ",
        "STREET": "BOZÖN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "MEZİTLİ",
        "STREET": "CEMİLLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "MEZİTLİ",
        "STREET": "CUMHURİYET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "MEZİTLİ",
        "STREET": "DAVULTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "MEZİTLİ",
        "STREET": "DEMİRIŞIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "MEZİTLİ",
        "STREET": "DENİZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "MEZİTLİ",
        "STREET": "DOĞANÇAY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "MEZİTLİ",
        "STREET": "DOĞLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "MEZİTLİ",
        "STREET": "ESENBAĞLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "MEZİTLİ",
        "STREET": "ESKİ MEZİTLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "MEZİTLİ",
        "STREET": "FATİH MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "MEZİTLİ",
        "STREET": "FINDIKPINARI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "MEZİTLİ",
        "STREET": "HÜRRİYET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "MEZİTLİ",
        "STREET": "KALEKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "MEZİTLİ",
        "STREET": "KOCAYER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "MEZİTLİ",
        "STREET": "KUYULUK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "MEZİTLİ",
        "STREET": "KUZUCU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "MEZİTLİ",
        "STREET": "KUZUCUBELEN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "MEZİTLİ",
        "STREET": "MENDERES MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "MEZİTLİ",
        "STREET": "MERKEZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "MEZİTLİ",
        "STREET": "PELİTKOYAĞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "MEZİTLİ",
        "STREET": "SARILAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "MEZİTLİ",
        "STREET": "SEYMENLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "MEZİTLİ",
        "STREET": "TAKANLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "MEZİTLİ",
        "STREET": "TECE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "MEZİTLİ",
        "STREET": "TEPEKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "MEZİTLİ",
        "STREET": "TOL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "MEZİTLİ",
        "STREET": "VİRANŞEHİR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "MEZİTLİ",
        "STREET": "YENİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "MEZİTLİ",
        "STREET": "ZEYBEKLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "MEZİTLİ",
        "STREET": "ÇAMLICA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "MEZİTLİ",
        "STREET": "ÇANKAYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "MEZİTLİ",
        "STREET": "ÇEVLİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "MEZİTLİ",
        "STREET": "İSTİKLAL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "MEZİTLİ",
        "STREET": "ŞAHİN TEPESİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "MUT",
        "STREET": "ALAÇAM MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "MUT",
        "STREET": "AYDINOĞLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "MUT",
        "STREET": "AŞAĞIKÖSELERLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "MUT",
        "STREET": "BALLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "MUT",
        "STREET": "BARABANLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "MUT",
        "STREET": "BAĞCAĞIZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "MUT",
        "STREET": "BOZDOĞAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "MUT",
        "STREET": "BURUNKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "MUT",
        "STREET": "CERİTLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "MUT",
        "STREET": "CUMHURİYET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "MUT",
        "STREET": "DAĞPAZARI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "MUT",
        "STREET": "DEMİRKAPI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "MUT",
        "STREET": "DEREKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "MUT",
        "STREET": "DERİNÇAY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "MUT",
        "STREET": "DEVECİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "MUT",
        "STREET": "DOĞANCI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "MUT",
        "STREET": "DİŞTAŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "MUT",
        "STREET": "ELBEYLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "MUT",
        "STREET": "ELMAPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "MUT",
        "STREET": "ESENÇAY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "MUT",
        "STREET": "EVREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "MUT",
        "STREET": "FAKIRCA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "MUT",
        "STREET": "GENÇALİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "MUT",
        "STREET": "GEÇİMLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "MUT",
        "STREET": "GÖCEKLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "MUT",
        "STREET": "GÖKSU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "MUT",
        "STREET": "GÖKÇETAŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "MUT",
        "STREET": "GÜLLÜK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "MUT",
        "STREET": "GÜME MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "MUT",
        "STREET": "GÜZELKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "MUT",
        "STREET": "GÜZELYURT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "MUT",
        "STREET": "HACIAHMETLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "MUT",
        "STREET": "HACINUHLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "MUT",
        "STREET": "HACISAİT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "MUT",
        "STREET": "HACIİLYASLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "MUT",
        "STREET": "HAMAM MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "MUT",
        "STREET": "HAYDARKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "MUT",
        "STREET": "HOCALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "MUT",
        "STREET": "HİSARKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "MUT",
        "STREET": "ILICA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "MUT",
        "STREET": "IRMAKLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "MUT",
        "STREET": "IŞIKLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "MUT",
        "STREET": "KADIKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "MUT",
        "STREET": "KALE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "MUT",
        "STREET": "KARACAOĞLAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "MUT",
        "STREET": "KARADİKEN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "MUT",
        "STREET": "KARŞIYAKA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "MUT",
        "STREET": "KAVAKLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "MUT",
        "STREET": "KAVAKÖZÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "MUT",
        "STREET": "KAYABAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "MUT",
        "STREET": "KAYAÖNÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "MUT",
        "STREET": "KELCEKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "MUT",
        "STREET": "KEMENLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "MUT",
        "STREET": "KIRKKAVAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "MUT",
        "STREET": "KIZILALAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "MUT",
        "STREET": "KIŞLAKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "MUT",
        "STREET": "KUMAÇUKURU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "MUT",
        "STREET": "KURTSUYU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "MUT",
        "STREET": "KURTULUŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "MUT",
        "STREET": "KÖSELERLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "MUT",
        "STREET": "KÜLTÜR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "MUT",
        "STREET": "KÜRKÇÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "MUT",
        "STREET": "MEYDAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "MUT",
        "STREET": "MUCUK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "MUT",
        "STREET": "MİRAHOR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "MUT",
        "STREET": "NARLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "MUT",
        "STREET": "NARLIDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "MUT",
        "STREET": "ORTAKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "MUT",
        "STREET": "PALANTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "MUT",
        "STREET": "PAMUKLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "MUT",
        "STREET": "PINARBAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "MUT",
        "STREET": "SAKIZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "MUT",
        "STREET": "SARIVELİLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "MUT",
        "STREET": "SELAMLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "MUT",
        "STREET": "SUÇATI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "MUT",
        "STREET": "TEKELİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "MUT",
        "STREET": "TOPKAYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "MUT",
        "STREET": "TOPLUCA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "MUT",
        "STREET": "TUĞRUL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "MUT",
        "STREET": "YALNIZCABAĞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "MUT",
        "STREET": "YAPINTI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "MUT",
        "STREET": "YATIRTAŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "MUT",
        "STREET": "YAZALANI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "MUT",
        "STREET": "YEŞİLKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "MUT",
        "STREET": "YEŞİLYURT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "MUT",
        "STREET": "YILDIZKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "MUT",
        "STREET": "YUKARIKÖSELERLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "MUT",
        "STREET": "ZEYTİNÇUKURU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "MUT",
        "STREET": "ÇALTILI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "MUT",
        "STREET": "ÇAMLICA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "MUT",
        "STREET": "ÇAMPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "MUT",
        "STREET": "ÇATAKBAĞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "MUT",
        "STREET": "ÇATALHARMAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "MUT",
        "STREET": "ÇAĞLAYANGEDİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "MUT",
        "STREET": "ÇINARLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "MUT",
        "STREET": "ÇORTAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "MUT",
        "STREET": "ÇUKURBAĞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "MUT",
        "STREET": "ÇÖMELEK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "MUT",
        "STREET": "ÇİVİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "MUT",
        "STREET": "ÖZKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "MUT",
        "STREET": "ÖZLÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "MUT",
        "STREET": "İBRAHİMLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "SİLİFKE",
        "STREET": "AKDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "SİLİFKE",
        "STREET": "ARKUM MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "SİLİFKE",
        "STREET": "ATAKENT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "SİLİFKE",
        "STREET": "ATAYURT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "SİLİFKE",
        "STREET": "ATİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "SİLİFKE",
        "STREET": "AYAŞTÜRKMENLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "SİLİFKE",
        "STREET": "BAHÇE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "SİLİFKE",
        "STREET": "BAHÇEDERESİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "SİLİFKE",
        "STREET": "BALANDIZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "SİLİFKE",
        "STREET": "BAYINDIR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "SİLİFKE",
        "STREET": "BOLACALIKOYUNCU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "SİLİFKE",
        "STREET": "BUCAKLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "SİLİFKE",
        "STREET": "BURUNUCU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "SİLİFKE",
        "STREET": "BÜKDEĞİRMENİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "SİLİFKE",
        "STREET": "CAMİİKEBİR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "SİLİFKE",
        "STREET": "CANBAZLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "SİLİFKE",
        "STREET": "CILBAYIR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "SİLİFKE",
        "STREET": "DEMİRCİLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "SİLİFKE",
        "STREET": "EKŞİLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "SİLİFKE",
        "STREET": "EVKAFÇİFTLİĞİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "SİLİFKE",
        "STREET": "GAZİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "SİLİFKE",
        "STREET": "GEDİKPINARI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "SİLİFKE",
        "STREET": "GÖKBELEN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "SİLİFKE",
        "STREET": "GÖKSU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "SİLİFKE",
        "STREET": "GÜLÜMPAŞALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "SİLİFKE",
        "STREET": "GÜNDÜZLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "SİLİFKE",
        "STREET": "HIRMANLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "SİLİFKE",
        "STREET": "HÜSEYİNLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "SİLİFKE",
        "STREET": "IŞIKLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "SİLİFKE",
        "STREET": "KABASAKALLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "SİLİFKE",
        "STREET": "KARAHACILI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "SİLİFKE",
        "STREET": "KARAKAYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "SİLİFKE",
        "STREET": "KARGICAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "SİLİFKE",
        "STREET": "KAVAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "SİLİFKE",
        "STREET": "KEBEN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "SİLİFKE",
        "STREET": "KEPEZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "SİLİFKE",
        "STREET": "KEŞLİTÜRKMENLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "SİLİFKE",
        "STREET": "KICA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "SİLİFKE",
        "STREET": "KIRTIL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "SİLİFKE",
        "STREET": "KIZILGEÇİT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "SİLİFKE",
        "STREET": "KOCAOLUK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "SİLİFKE",
        "STREET": "KOCAPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "SİLİFKE",
        "STREET": "KURTULUŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "SİLİFKE",
        "STREET": "MARA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "SİLİFKE",
        "STREET": "MUKADDEM MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "SİLİFKE",
        "STREET": "NARLIKUYU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "SİLİFKE",
        "STREET": "NASRULLAH MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "SİLİFKE",
        "STREET": "NURU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "SİLİFKE",
        "STREET": "ORTAÖREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "SİLİFKE",
        "STREET": "OVACIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "SİLİFKE",
        "STREET": "PAZARKAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "SİLİFKE",
        "STREET": "PELİTPINARI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "SİLİFKE",
        "STREET": "SABAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "SİLİFKE",
        "STREET": "SARAY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "SİLİFKE",
        "STREET": "SARIAYDIN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "SİLİFKE",
        "STREET": "SARICALAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "SİLİFKE",
        "STREET": "SAY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "SİLİFKE",
        "STREET": "SAYAĞZI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "SİLİFKE",
        "STREET": "SENİR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "SİLİFKE",
        "STREET": "SEYDİLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "SİLİFKE",
        "STREET": "SEYRANLIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "SİLİFKE",
        "STREET": "SÖKÜN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "SİLİFKE",
        "STREET": "SÖMEK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "SİLİFKE",
        "STREET": "SİLİFKE M TİPİ KAPALI CEZAEVİ",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "SİLİFKE",
        "STREET": "TAŞUCU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "SİLİFKE",
        "STREET": "TOROS MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "SİLİFKE",
        "STREET": "TOSMURLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "SİLİFKE",
        "STREET": "TÜRKMENUŞAĞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "SİLİFKE",
        "STREET": "ULUGÖZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "SİLİFKE",
        "STREET": "UZUNCABURÇ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "SİLİFKE",
        "STREET": "UŞAKPINARI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "SİLİFKE",
        "STREET": "YENİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "SİLİFKE",
        "STREET": "YENİBAHÇE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "SİLİFKE",
        "STREET": "YENİSU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "SİLİFKE",
        "STREET": "YEĞENLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "SİLİFKE",
        "STREET": "YEŞİLOVACIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "SİLİFKE",
        "STREET": "ÇADIRLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "SİLİFKE",
        "STREET": "ÇALTIBOZKIR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "SİLİFKE",
        "STREET": "ÇAMLIBEL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "SİLİFKE",
        "STREET": "ÇAMLICA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "SİLİFKE",
        "STREET": "ÇATAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "SİLİFKE",
        "STREET": "ÇELTİKÇİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "SİLİFKE",
        "STREET": "ÖREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "SİLİFKE",
        "STREET": "ÖZBOYNUİNCELİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "SİLİFKE",
        "STREET": "ÖZTÜRKMENLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "SİLİFKE",
        "STREET": "İMAMBEKİRLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "SİLİFKE",
        "STREET": "İMAMLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "SİLİFKE",
        "STREET": "İMAMUŞAĞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "SİLİFKE",
        "STREET": "ŞAHMURLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "TARSUS",
        "STREET": "82EVLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "TARSUS",
        "STREET": "AKARSU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "TARSUS",
        "STREET": "AKGEDİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "TARSUS",
        "STREET": "AKÇAKOCALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "TARSUS",
        "STREET": "AKŞEMSETTİN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "TARSUS",
        "STREET": "ALADAĞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "TARSUS",
        "STREET": "ALTAYLILAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "TARSUS",
        "STREET": "ALİAĞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "TARSUS",
        "STREET": "ALİBEYLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "TARSUS",
        "STREET": "ALİEFENDİOĞLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "TARSUS",
        "STREET": "ALİFAKI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "TARSUS",
        "STREET": "ANIT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "TARSUS",
        "STREET": "ARDIÇLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "TARSUS",
        "STREET": "ARIKLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "TARSUS",
        "STREET": "ATALAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "TARSUS",
        "STREET": "ATATÜRK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "TARSUS",
        "STREET": "AVADAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "TARSUS",
        "STREET": "AĞZIDELİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "TARSUS",
        "STREET": "BAHARLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "TARSUS",
        "STREET": "BAHÇE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "TARSUS",
        "STREET": "BAHŞİŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "TARSUS",
        "STREET": "BALLICA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "TARSUS",
        "STREET": "BALTALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "TARSUS",
        "STREET": "BARBAROS MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "TARSUS",
        "STREET": "BAĞLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "TARSUS",
        "STREET": "BELEN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "TARSUS",
        "STREET": "BEYDEĞİRMENİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "TARSUS",
        "STREET": "BEYLİCE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "TARSUS",
        "STREET": "BOLATLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "TARSUS",
        "STREET": "BOZTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "TARSUS",
        "STREET": "BOĞAZPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "TARSUS",
        "STREET": "BÖĞRÜEĞRİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "TARSUS",
        "STREET": "BÜYÜKKÖSEBALCI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "TARSUS",
        "STREET": "CAMİLİMANDA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "TARSUS",
        "STREET": "CAMİNUR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "TARSUS",
        "STREET": "CIRBIKLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "TARSUS",
        "STREET": "CUMHURİYET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "TARSUS",
        "STREET": "CİN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "TARSUS",
        "STREET": "DADALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "TARSUS",
        "STREET": "DAMLAMA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "TARSUS",
        "STREET": "DEDELER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "TARSUS",
        "STREET": "DORAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "TARSUS",
        "STREET": "DUATEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "TARSUS",
        "STREET": "EGEMEN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "TARSUS",
        "STREET": "EMİNLİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "TARSUS",
        "STREET": "EMİRLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "TARSUS",
        "STREET": "ERGENEKON MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "TARSUS",
        "STREET": "ESENLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "TARSUS",
        "STREET": "ESKİ ÖMERLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "TARSUS",
        "STREET": "ESKİŞEHİR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "TARSUS",
        "STREET": "FAHRETTİN PAŞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "TARSUS",
        "STREET": "FATİH MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "TARSUS",
        "STREET": "FERAHİMŞALVUZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "TARSUS",
        "STREET": "FEVZİ ÇAKMAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "TARSUS",
        "STREET": "GAZİLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "TARSUS",
        "STREET": "GAZİPAŞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "TARSUS",
        "STREET": "GÖMMECE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "TARSUS",
        "STREET": "GÖZLÜKULE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "TARSUS",
        "STREET": "GÖÇÜK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "TARSUS",
        "STREET": "GÜLEK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "TARSUS",
        "STREET": "GÜNYURDU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "TARSUS",
        "STREET": "GİRNE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "TARSUS",
        "STREET": "HACIBOZAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "TARSUS",
        "STREET": "HACIHAMZALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "TARSUS",
        "STREET": "HALİTAĞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "TARSUS",
        "STREET": "HASANAĞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "TARSUS",
        "STREET": "HELEKE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "TARSUS",
        "STREET": "HÜRRİYET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "TARSUS",
        "STREET": "KABURGEDİĞİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "TARSUS",
        "STREET": "KADELLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "TARSUS",
        "STREET": "KAKLIKTAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "TARSUS",
        "STREET": "KALEBURCU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "TARSUS",
        "STREET": "KANBERHÜYÜĞÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "TARSUS",
        "STREET": "KARADİKEN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "TARSUS",
        "STREET": "KARADİRLİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "TARSUS",
        "STREET": "KARAKÜTÜK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "TARSUS",
        "STREET": "KARAYAYLA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "TARSUS",
        "STREET": "KARAÇERÇİLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "TARSUS",
        "STREET": "KARGILI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "TARSUS",
        "STREET": "KARSAVRAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "TARSUS",
        "STREET": "KAVAKLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "TARSUS",
        "STREET": "KAYADİBİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "TARSUS",
        "STREET": "KEFELİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "TARSUS",
        "STREET": "KELAHMET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "TARSUS",
        "STREET": "KEMALPAŞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "TARSUS",
        "STREET": "KERİMLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "TARSUS",
        "STREET": "KEŞLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "TARSUS",
        "STREET": "KIRIT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "TARSUS",
        "STREET": "KIRKLARSIRTI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "TARSUS",
        "STREET": "KIZILMURAT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "TARSUS",
        "STREET": "KIZILÇUKUR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "TARSUS",
        "STREET": "KOCAKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "TARSUS",
        "STREET": "KONAKLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "TARSUS",
        "STREET": "KOZOLUK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "TARSUS",
        "STREET": "KOÇMARLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "TARSUS",
        "STREET": "KULAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "TARSUS",
        "STREET": "KUMDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "TARSUS",
        "STREET": "KURBANLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "TARSUS",
        "STREET": "KURTÇUKURU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "TARSUS",
        "STREET": "KUŞÇULAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "TARSUS",
        "STREET": "KÖRLÜ BEYİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "TARSUS",
        "STREET": "KÖSEBALCI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "TARSUS",
        "STREET": "KÖSELERLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "TARSUS",
        "STREET": "KÜTÜKLÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "TARSUS",
        "STREET": "MAHMUTAĞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "TARSUS",
        "STREET": "MANTAŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "TARSUS",
        "STREET": "MEŞELİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "TARSUS",
        "STREET": "MURATLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "TARSUS",
        "STREET": "MİTHATPAŞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "TARSUS",
        "STREET": "NEMİROĞLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "TARSUS",
        "STREET": "OLUKKOYAĞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "TARSUS",
        "STREET": "PİRÖMERLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "TARSUS",
        "STREET": "REŞADİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "TARSUS",
        "STREET": "SANDAL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "TARSUS",
        "STREET": "SANLICA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "TARSUS",
        "STREET": "SARIVELİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "TARSUS",
        "STREET": "SAYKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "TARSUS",
        "STREET": "SAĞLIKLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "TARSUS",
        "STREET": "SIRAKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "TARSUS",
        "STREET": "SUCULAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "TARSUS",
        "STREET": "SİMİTHACILI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "TARSUS",
        "STREET": "TAKBAŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "TARSUS",
        "STREET": "TARSUS 1 NOLU T TİPİ KAPALI CEZA İNFAZ KURUMU MÜDÜ",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "TARSUS",
        "STREET": "TARSUS 2 NOLU T TİPİ KAPALI CEZA İNFAZ KURUMU MÜDÜ",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "TARSUS",
        "STREET": "TARSUS 3 NOLU T TİPİ KAPALI CEZA İNFAZ KURUMU MÜDÜ",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "TARSUS",
        "STREET": "TARSUS KADIN KAPALI CEZA İNFAZ KURUMU MÜDÜRLÜĞÜ",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "TARSUS",
        "STREET": "TARSUS ÇOCUK VE GENÇLİK KAPALI CEZA İNFAZ KURUMU M",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "TARSUS",
        "STREET": "TAŞKUYU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "TARSUS",
        "STREET": "TAŞOBASI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "TARSUS",
        "STREET": "TAŞÇILI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "TARSUS",
        "STREET": "TEKELİÖREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "TARSUS",
        "STREET": "TEKKE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "TARSUS",
        "STREET": "TEPEKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "TARSUS",
        "STREET": "TEPETAŞPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "TARSUS",
        "STREET": "TEPEÇAYLAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "TARSUS",
        "STREET": "TOPAKLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "TARSUS",
        "STREET": "TOPÇU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "TARSUS",
        "STREET": "TOZKOPARAN ZAHİT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "TARSUS",
        "STREET": "ULAŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "TARSUS",
        "STREET": "VERİMLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "TARSUS",
        "STREET": "YALAMIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "TARSUS",
        "STREET": "YANIKKIŞLA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "TARSUS",
        "STREET": "YARAMIŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "TARSUS",
        "STREET": "YARBAY ŞEMSETTİN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "TARSUS",
        "STREET": "YAZLIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "TARSUS",
        "STREET": "YENİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "TARSUS",
        "STREET": "YENİ ÖMERLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "TARSUS",
        "STREET": "YENİCE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "TARSUS",
        "STREET": "YENİKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "TARSUS",
        "STREET": "YENİÇAY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "TARSUS",
        "STREET": "YEŞİL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "TARSUS",
        "STREET": "YEŞİLEVLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "TARSUS",
        "STREET": "YEŞİLTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "TARSUS",
        "STREET": "YEŞİLYURT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "TARSUS",
        "STREET": "YUNUS EMRE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "TARSUS",
        "STREET": "YUNUSOĞLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "TARSUS",
        "STREET": "YÜKSEK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "TARSUS",
        "STREET": "ÇAKIRLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "TARSUS",
        "STREET": "ÇAMALAN B.M MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "TARSUS",
        "STREET": "ÇAMTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "TARSUS",
        "STREET": "ÇATALCA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "TARSUS",
        "STREET": "ÇAVDARLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "TARSUS",
        "STREET": "ÇAVUŞLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "TARSUS",
        "STREET": "ÇAĞBAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "TARSUS",
        "STREET": "ÇAĞLAYAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "TARSUS",
        "STREET": "ÇEVRELİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "TARSUS",
        "STREET": "ÇOKAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "TARSUS",
        "STREET": "ÇUKURBAĞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "TARSUS",
        "STREET": "ÇÖPLÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "TARSUS",
        "STREET": "ÇİFTLİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "TARSUS",
        "STREET": "ÇİRİŞTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "TARSUS",
        "STREET": "ÇİÇEKLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "TARSUS",
        "STREET": "ÖZBEK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "TARSUS",
        "STREET": "ÖZLÜCE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "TARSUS",
        "STREET": "ÖĞRETMENLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "TARSUS",
        "STREET": "İBRİŞİM MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "TARSUS",
        "STREET": "İNCİRGEDİĞİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "TARSUS",
        "STREET": "İNCİRLİKUYU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "TARSUS",
        "STREET": "İNKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "TARSUS",
        "STREET": "İSMETPAŞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "TARSUS",
        "STREET": "ŞAHİN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "TARSUS",
        "STREET": "ŞEHİTKERİM MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "TARSUS",
        "STREET": "ŞEHİTLER TEPESİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "TARSUS",
        "STREET": "ŞEHİTMUSTAFA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "TARSUS",
        "STREET": "ŞEHİTİSHAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "TOROSLAR",
        "STREET": "AKBELEN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "TOROSLAR",
        "STREET": "ALADAĞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "TOROSLAR",
        "STREET": "ALANYALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "TOROSLAR",
        "STREET": "ALSANCAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "TOROSLAR",
        "STREET": "ARPAÇSAKARLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "TOROSLAR",
        "STREET": "ARSLANKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "TOROSLAR",
        "STREET": "AYVAGEDİĞİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "TOROSLAR",
        "STREET": "BEKİRALANI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "TOROSLAR",
        "STREET": "BULUKLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "TOROSLAR",
        "STREET": "DALAKDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "TOROSLAR",
        "STREET": "DARISEKİSİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "TOROSLAR",
        "STREET": "DEMİRTAŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "TOROSLAR",
        "STREET": "DEĞNEK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "TOROSLAR",
        "STREET": "DEĞİRMENDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "TOROSLAR",
        "STREET": "DORUKLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "TOROSLAR",
        "STREET": "DÜĞDÜÖREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "TOROSLAR",
        "STREET": "EVRENLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "TOROSLAR",
        "STREET": "GÖZNE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "TOROSLAR",
        "STREET": "GÜNEYKENT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "TOROSLAR",
        "STREET": "GÜZELYAYLA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "TOROSLAR",
        "STREET": "HALKKENT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "TOROSLAR",
        "STREET": "HAMZABEYLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "TOROSLAR",
        "STREET": "HOROZLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "TOROSLAR",
        "STREET": "HÜSEYİN OKAN MERZECİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "TOROSLAR",
        "STREET": "IŞIKTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "TOROSLAR",
        "STREET": "KARAİSALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "TOROSLAR",
        "STREET": "KAVAKLIPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "TOROSLAR",
        "STREET": "KAYRAKKEŞLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "TOROSLAR",
        "STREET": "KAŞLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "TOROSLAR",
        "STREET": "KEPİRLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "TOROSLAR",
        "STREET": "KERİMLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "TOROSLAR",
        "STREET": "KIZILKAYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "TOROSLAR",
        "STREET": "KORUCULAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "TOROSLAR",
        "STREET": "KORUKENT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "TOROSLAR",
        "STREET": "KURDALİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "TOROSLAR",
        "STREET": "KURUDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "TOROSLAR",
        "STREET": "MEVLANA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "TOROSLAR",
        "STREET": "MUSALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "TOROSLAR",
        "STREET": "MUSTAFA KEMAL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "TOROSLAR",
        "STREET": "MİTHAT TOROĞLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "TOROSLAR",
        "STREET": "OSMANİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "TOROSLAR",
        "STREET": "PORTAKAL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "TOROSLAR",
        "STREET": "RESULKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "TOROSLAR",
        "STREET": "SADİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "TOROSLAR",
        "STREET": "SAĞLIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "TOROSLAR",
        "STREET": "SELÇUKLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "TOROSLAR",
        "STREET": "SOĞUCAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "TOROSLAR",
        "STREET": "TIRTAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "TOROSLAR",
        "STREET": "TOROSLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "TOROSLAR",
        "STREET": "TOZKOPARAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "TOROSLAR",
        "STREET": "TURGUT TÜRKALP MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "TOROSLAR",
        "STREET": "TURUNÇLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "TOROSLAR",
        "STREET": "YALINAYAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "TOROSLAR",
        "STREET": "YAVCA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "TOROSLAR",
        "STREET": "YENİKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "TOROSLAR",
        "STREET": "YUSUF KILIÇ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "TOROSLAR",
        "STREET": "YÜKSEKOLUK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "TOROSLAR",
        "STREET": "ZEKİ AYAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "TOROSLAR",
        "STREET": "ÇAMLIDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "TOROSLAR",
        "STREET": "ÇANDIR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "TOROSLAR",
        "STREET": "ÇAVUŞLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "TOROSLAR",
        "STREET": "ÇAĞDAŞKENT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "TOROSLAR",
        "STREET": "ÇAĞLARCA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "TOROSLAR",
        "STREET": "ÇELEBİLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "TOROSLAR",
        "STREET": "ÇOPURLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "TOROSLAR",
        "STREET": "ÇUKUROVA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "TOROSLAR",
        "STREET": "ŞAHİNPINARI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "YENİŞEHİR",
        "STREET": "50. YIL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "YENİŞEHİR",
        "STREET": "AKKENT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "YENİŞEHİR",
        "STREET": "AYDINLIKEVLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "YENİŞEHİR",
        "STREET": "BAHÇELİEVLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "YENİŞEHİR",
        "STREET": "BARBAROS MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "YENİŞEHİR",
        "STREET": "BATIKENT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "YENİŞEHİR",
        "STREET": "CUMHURİYET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "YENİŞEHİR",
        "STREET": "DENİZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "YENİŞEHİR",
        "STREET": "DEĞİRMENÇAY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "YENİŞEHİR",
        "STREET": "DUMLUPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "YENİŞEHİR",
        "STREET": "EMİRLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "YENİŞEHİR",
        "STREET": "EĞRİÇAM MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "YENİŞEHİR",
        "STREET": "FUATMOREL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "YENİŞEHİR",
        "STREET": "GAZİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "YENİŞEHİR",
        "STREET": "GÖKÇEBELEN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "YENİŞEHİR",
        "STREET": "GÜVENEVLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "YENİŞEHİR",
        "STREET": "HÜRRİYET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "YENİŞEHİR",
        "STREET": "KARAHACILI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "YENİŞEHİR",
        "STREET": "KOCAHAMZALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "YENİŞEHİR",
        "STREET": "KOCAVİLAYET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "YENİŞEHİR",
        "STREET": "KUZEYKENT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "YENİŞEHİR",
        "STREET": "LİMONLUK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "YENİŞEHİR",
        "STREET": "MENTEŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "YENİŞEHİR",
        "STREET": "PALMİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "YENİŞEHİR",
        "STREET": "PİRİREİS MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "YENİŞEHİR",
        "STREET": "TURUNÇLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "YENİŞEHİR",
        "STREET": "UZUNKAŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "YENİŞEHİR",
        "STREET": "ÇAVAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "YENİŞEHİR",
        "STREET": "ÇUKURKEŞLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "YENİŞEHİR",
        "STREET": "ÇİFTLİKKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "YENİŞEHİR",
        "STREET": "İNSU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "YENİŞEHİR",
        "STREET": "İNÖNÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "ÇAMLIYAYLA",
        "STREET": "BAĞÇATAĞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "ÇAMLIYAYLA",
        "STREET": "BELÇINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "ÇAMLIYAYLA",
        "STREET": "CUMAYAKASI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "ÇAMLIYAYLA",
        "STREET": "DARIPINARI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "ÇAMLIYAYLA",
        "STREET": "FAKILAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "ÇAMLIYAYLA",
        "STREET": "GİDEN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "ÇAMLIYAYLA",
        "STREET": "KALE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "ÇAMLIYAYLA",
        "STREET": "KESECİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "ÇAMLIYAYLA",
        "STREET": "KORUCAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "ÇAMLIYAYLA",
        "STREET": "KÖRMENLİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "ÇAMLIYAYLA",
        "STREET": "SARIKAVAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "ÇAMLIYAYLA",
        "STREET": "SARIKOYAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "ÇAMLIYAYLA",
        "STREET": "SEBİL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MERSİN",
        "DISTRICT": "ÇAMLIYAYLA",
        "STREET": "ÇAYIREKİNLİĞİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "BODRUM",
        "STREET": "AKYARLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "BODRUM",
        "STREET": "AKÇAALAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "BODRUM",
        "STREET": "BAHÇELİEVLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "BODRUM",
        "STREET": "BAHÇEYAKA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "BODRUM",
        "STREET": "BODRUM S TİPİ KAPALI CEZA İNFAZ KURUMU",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "BODRUM",
        "STREET": "BİTEZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "BODRUM",
        "STREET": "CEVAT ŞAKİR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "BODRUM",
        "STREET": "CUMHURİYET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "BODRUM",
        "STREET": "DAĞBELEN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "BODRUM",
        "STREET": "DEREKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "BODRUM",
        "STREET": "DİRMİL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "BODRUM",
        "STREET": "ESKİÇEŞME MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "BODRUM",
        "STREET": "FARİLYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "BODRUM",
        "STREET": "GERİŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "BODRUM",
        "STREET": "GÖKPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "BODRUM",
        "STREET": "GÖLBAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "BODRUM",
        "STREET": "GÖLKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "BODRUM",
        "STREET": "GÜMBET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "BODRUM",
        "STREET": "GÜMÜŞLÜK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "BODRUM",
        "STREET": "GÜNDOĞAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "BODRUM",
        "STREET": "GÜRECE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "BODRUM",
        "STREET": "GÜVERCİNLİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "BODRUM",
        "STREET": "KARABAĞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "BODRUM",
        "STREET": "KARAOVA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "BODRUM",
        "STREET": "KEMER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "BODRUM",
        "STREET": "KIZILAĞAÇ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "BODRUM",
        "STREET": "KONACIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "BODRUM",
        "STREET": "KOYUNBABA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "BODRUM",
        "STREET": "KUMBAHÇE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "BODRUM",
        "STREET": "KUMKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "BODRUM",
        "STREET": "KÜÇÜKBÜK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "BODRUM",
        "STREET": "MAZIKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "BODRUM",
        "STREET": "MUMCULAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "BODRUM",
        "STREET": "MÜSKEBİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "BODRUM",
        "STREET": "PEKSİMET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "BODRUM",
        "STREET": "PINARLIBELEN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "BODRUM",
        "STREET": "SAZKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "BODRUM",
        "STREET": "TEPECİK (KARAOVA) MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "BODRUM",
        "STREET": "TEPECİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "BODRUM",
        "STREET": "TORBA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "BODRUM",
        "STREET": "TURGUTREİS MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "BODRUM",
        "STREET": "TÜRKBÜKÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "BODRUM",
        "STREET": "TÜRKKUYUSU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "BODRUM",
        "STREET": "UMURCA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "BODRUM",
        "STREET": "YAHŞİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "BODRUM",
        "STREET": "YAKAKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "BODRUM",
        "STREET": "YALIKAVAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "BODRUM",
        "STREET": "YENİKÖY (KARAOVA) MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "BODRUM",
        "STREET": "YENİKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "BODRUM",
        "STREET": "YOKUŞBAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "BODRUM",
        "STREET": "ÇAMARASI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "BODRUM",
        "STREET": "ÇAMLIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "BODRUM",
        "STREET": "ÇARŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "BODRUM",
        "STREET": "ÇIRKAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "BODRUM",
        "STREET": "ÇÖMLEKÇİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "BODRUM",
        "STREET": "ÇİFTLİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "BODRUM",
        "STREET": "İSLAMHANELERİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "DALAMAN",
        "STREET": "AKÇATAŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "DALAMAN",
        "STREET": "ALTINTAS MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "DALAMAN",
        "STREET": "ATAKENT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "DALAMAN",
        "STREET": "BEZKESE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "DALAMAN",
        "STREET": "BOZBEL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "DALAMAN",
        "STREET": "DARIYERİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "DALAMAN",
        "STREET": "EGE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "DALAMAN",
        "STREET": "ELCİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "DALAMAN",
        "STREET": "GÜRKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "DALAMAN",
        "STREET": "GÜRLEYK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "DALAMAN",
        "STREET": "HÜRRİYET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "DALAMAN",
        "STREET": "KAPUKARGIN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "DALAMAN",
        "STREET": "KARACAAĞAÇ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "DALAMAN",
        "STREET": "KARAÇALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "DALAMAN",
        "STREET": "KARGINKÜRÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "DALAMAN",
        "STREET": "KAVACIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "DALAMAN",
        "STREET": "KAYADİBİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "DALAMAN",
        "STREET": "KIZILKAYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "DALAMAN",
        "STREET": "MERKEZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "DALAMAN",
        "STREET": "NARLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "DALAMAN",
        "STREET": "SABUNLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "DALAMAN",
        "STREET": "SÖĞÜTLÜYURT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "DALAMAN",
        "STREET": "TAŞBAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "DALAMAN",
        "STREET": "ÇÖĞMEN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "DALAMAN",
        "STREET": "ŞEREFLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "DATÇA",
        "STREET": "CUMALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "DATÇA",
        "STREET": "DATÇA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "DATÇA",
        "STREET": "EMECİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "DATÇA",
        "STREET": "HIZIRŞAH MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "DATÇA",
        "STREET": "KARAKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "DATÇA",
        "STREET": "KIZLAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "DATÇA",
        "STREET": "MESUDİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "DATÇA",
        "STREET": "REŞADİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "DATÇA",
        "STREET": "SINDI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "DATÇA",
        "STREET": "YAKA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "DATÇA",
        "STREET": "YAZI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "DATÇA",
        "STREET": "İSKELE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "FETHİYE",
        "STREET": "AKARCA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "FETHİYE",
        "STREET": "BABATAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "FETHİYE",
        "STREET": "BOZYER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "FETHİYE",
        "STREET": "CAMİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "FETHİYE",
        "STREET": "CUMHURİYET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "FETHİYE",
        "STREET": "ELDİREK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "FETHİYE",
        "STREET": "ESENKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "FETHİYE",
        "STREET": "FARALYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "FETHİYE",
        "STREET": "FOÇA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "FETHİYE",
        "STREET": "GÖCEK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "FETHİYE",
        "STREET": "GÖKBEN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "FETHİYE",
        "STREET": "GÖKÇEOVACIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "FETHİYE",
        "STREET": "KARAAĞAÇ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "FETHİYE",
        "STREET": "KARACAÖREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "FETHİYE",
        "STREET": "KARAGEDİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "FETHİYE",
        "STREET": "KARAGÖZLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "FETHİYE",
        "STREET": "KARAKEÇİLİLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "FETHİYE",
        "STREET": "KARAÇULHA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "FETHİYE",
        "STREET": "KARGI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "FETHİYE",
        "STREET": "KAYAKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "FETHİYE",
        "STREET": "KESİKKAPI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "FETHİYE",
        "STREET": "KIZILBEL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "FETHİYE",
        "STREET": "KORUKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "FETHİYE",
        "STREET": "MENTEŞEOĞLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "FETHİYE",
        "STREET": "NİF MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "FETHİYE",
        "STREET": "PATLANGIÇ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "FETHİYE",
        "STREET": "PAZARYERİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "FETHİYE",
        "STREET": "SÖĞÜTLÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "FETHİYE",
        "STREET": "TAŞYAKA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "FETHİYE",
        "STREET": "TUZLA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "FETHİYE",
        "STREET": "YAKACIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "FETHİYE",
        "STREET": "YANIKLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "FETHİYE",
        "STREET": "YENİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "FETHİYE",
        "STREET": "YEŞİLÜZÜMLÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "FETHİYE",
        "STREET": "ÇAMKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "FETHİYE",
        "STREET": "ÇATALARIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "FETHİYE",
        "STREET": "ÇENGER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "FETHİYE",
        "STREET": "ÇİFTLİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "FETHİYE",
        "STREET": "ÖLÜDENİZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "FETHİYE",
        "STREET": "İNCİRKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "FETHİYE",
        "STREET": "İNLİCE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "KAVAKLIDERE",
        "STREET": "BAKIRCILAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "KAVAKLIDERE",
        "STREET": "CUMHURİYET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "KAVAKLIDERE",
        "STREET": "DEREBAĞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "KAVAKLIDERE",
        "STREET": "KURUCUOVA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "KAVAKLIDERE",
        "STREET": "MENTEŞE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "KAVAKLIDERE",
        "STREET": "NEBİLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "KAVAKLIDERE",
        "STREET": "ORTAKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "KAVAKLIDERE",
        "STREET": "SALKIM MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "KAVAKLIDERE",
        "STREET": "YENİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "KAVAKLIDERE",
        "STREET": "YEŞİLKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "KAVAKLIDERE",
        "STREET": "ÇAMLIBEL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "KAVAKLIDERE",
        "STREET": "ÇAMLIYURT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "KAVAKLIDERE",
        "STREET": "ÇATAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "KAVAKLIDERE",
        "STREET": "ÇAVDIR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "KAVAKLIDERE",
        "STREET": "ÇAYBOYU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "KÖYCEĞİZ",
        "STREET": "AKKÖPRÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "KÖYCEĞİZ",
        "STREET": "BALCILAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "KÖYCEĞİZ",
        "STREET": "BEYOBASI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "KÖYCEĞİZ",
        "STREET": "DÖĞÜŞBELEN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "KÖYCEĞİZ",
        "STREET": "EKİNCİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "KÖYCEĞİZ",
        "STREET": "GELİŞİM MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "KÖYCEĞİZ",
        "STREET": "GÜLPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "KÖYCEĞİZ",
        "STREET": "HAMİTKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "KÖYCEĞİZ",
        "STREET": "KARAÇAM MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "KÖYCEĞİZ",
        "STREET": "KAVAKARASI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "KÖYCEĞİZ",
        "STREET": "KÖYCEĞİZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "KÖYCEĞİZ",
        "STREET": "OTMANLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "KÖYCEĞİZ",
        "STREET": "PINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "KÖYCEĞİZ",
        "STREET": "SAZAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "KÖYCEĞİZ",
        "STREET": "SULTANİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "KÖYCEĞİZ",
        "STREET": "TOPARLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "KÖYCEĞİZ",
        "STREET": "ULUCAMİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "KÖYCEĞİZ",
        "STREET": "YANGI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "KÖYCEĞİZ",
        "STREET": "YAYLA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "KÖYCEĞİZ",
        "STREET": "YENİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "KÖYCEĞİZ",
        "STREET": "YEŞİLKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "KÖYCEĞİZ",
        "STREET": "ZAFERLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "KÖYCEĞİZ",
        "STREET": "ZEYTİNALANI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "KÖYCEĞİZ",
        "STREET": "ÇANDIR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "KÖYCEĞİZ",
        "STREET": "ÇAYHİSAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "MARMARİS",
        "STREET": "ADAKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "MARMARİS",
        "STREET": "ARMUTALAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "MARMARİS",
        "STREET": "BAYIR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "MARMARİS",
        "STREET": "BELDİBİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "MARMARİS",
        "STREET": "BOZBURUN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "MARMARİS",
        "STREET": "HATİPİRİMİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "MARMARİS",
        "STREET": "HİSARÖNÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "MARMARİS",
        "STREET": "KARACA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "MARMARİS",
        "STREET": "KEMERALTI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "MARMARİS",
        "STREET": "ORHANİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "MARMARİS",
        "STREET": "OSMANİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "MARMARİS",
        "STREET": "SARIANA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "MARMARİS",
        "STREET": "SELİMİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "MARMARİS",
        "STREET": "SÖĞÜT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "MARMARİS",
        "STREET": "SİTELER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "MARMARİS",
        "STREET": "TAŞLICA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "MARMARİS",
        "STREET": "TEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "MARMARİS",
        "STREET": "TURGUT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "MARMARİS",
        "STREET": "TURUNÇ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "MARMARİS",
        "STREET": "YEŞİLBELDE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "MARMARİS",
        "STREET": "ÇAMDİBİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "MARMARİS",
        "STREET": "ÇAMLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "MARMARİS",
        "STREET": "ÇETİBELİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "MARMARİS",
        "STREET": "ÇILDIR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "MARMARİS",
        "STREET": "İÇMELER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "MENTEŞE",
        "STREET": "AKKAYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "MENTEŞE",
        "STREET": "AKYER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "MENTEŞE",
        "STREET": "AKÇAOVA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "MENTEŞE",
        "STREET": "ALGI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "MENTEŞE",
        "STREET": "AVCILAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "MENTEŞE",
        "STREET": "BALIBEY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "MENTEŞE",
        "STREET": "BAYIR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "MENTEŞE",
        "STREET": "BOZYER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "MENTEŞE",
        "STREET": "CAMİKEBİR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "MENTEŞE",
        "STREET": "DAĞDİBİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "MENTEŞE",
        "STREET": "DAĞPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "MENTEŞE",
        "STREET": "DENİZOVA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "MENTEŞE",
        "STREET": "DERİNKUYU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "MENTEŞE",
        "STREET": "DOKUZÇAM MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "MENTEŞE",
        "STREET": "DOĞAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "MENTEŞE",
        "STREET": "DÜĞEREK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "MENTEŞE",
        "STREET": "EMİRBEYAZIT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "MENTEŞE",
        "STREET": "ESENÇAY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "MENTEŞE",
        "STREET": "FADIL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "MENTEŞE",
        "STREET": "GAZELLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "MENTEŞE",
        "STREET": "GÖKTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "MENTEŞE",
        "STREET": "GÜLAĞZI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "MENTEŞE",
        "STREET": "GÜNLÜCE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "MENTEŞE",
        "STREET": "HACIRÜSTEM MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "MENTEŞE",
        "STREET": "KAFACA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "MENTEŞE",
        "STREET": "KARACAÖREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "MENTEŞE",
        "STREET": "KARAMEHMET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "MENTEŞE",
        "STREET": "KARŞIYAKA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "MENTEŞE",
        "STREET": "KIRAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "MENTEŞE",
        "STREET": "KOZAĞAÇ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "MENTEŞE",
        "STREET": "KUYUCAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "MENTEŞE",
        "STREET": "KUZLUK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "MENTEŞE",
        "STREET": "KÖTEKLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "MENTEŞE",
        "STREET": "KİRAMETTİN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "MENTEŞE",
        "STREET": "MEKE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "MENTEŞE",
        "STREET": "MURATLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "MENTEŞE",
        "STREET": "MUSLİHİTTİN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "MENTEŞE",
        "STREET": "MUĞLA E TİPİ KAPALI CEZAEVİ",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "MENTEŞE",
        "STREET": "MÜŞTAKBEY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "MENTEŞE",
        "STREET": "ORHANİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "MENTEŞE",
        "STREET": "ORTA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "MENTEŞE",
        "STREET": "ORTAKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "MENTEŞE",
        "STREET": "PAŞAPINARI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "MENTEŞE",
        "STREET": "SALİHPAŞALAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "MENTEŞE",
        "STREET": "SARNIÇ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "MENTEŞE",
        "STREET": "SUNGUR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "MENTEŞE",
        "STREET": "TAŞLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "MENTEŞE",
        "STREET": "YARAŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "MENTEŞE",
        "STREET": "YEMİŞENDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "MENTEŞE",
        "STREET": "YENİBAĞYAKA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "MENTEŞE",
        "STREET": "YENİCE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "MENTEŞE",
        "STREET": "YENİKÖY (YERKESİK) MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "MENTEŞE",
        "STREET": "YENİKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "MENTEŞE",
        "STREET": "YERKESİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "MENTEŞE",
        "STREET": "YEŞİLYURT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "MENTEŞE",
        "STREET": "YÖRÜKOĞLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "MENTEŞE",
        "STREET": "ZEYTİN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "MENTEŞE",
        "STREET": "ÇAKMAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "MENTEŞE",
        "STREET": "ÇAMOLUK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "MENTEŞE",
        "STREET": "ÇATAKBAĞYAKA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "MENTEŞE",
        "STREET": "ÇAYBÜKÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "MENTEŞE",
        "STREET": "ÇIRPI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "MENTEŞE",
        "STREET": "ÇİFTLİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "MENTEŞE",
        "STREET": "ÖZLÜCE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "MENTEŞE",
        "STREET": "İKİZCE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "MENTEŞE",
        "STREET": "ŞENYAYLA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "MENTEŞE",
        "STREET": "ŞEYH MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "MİLAS",
        "STREET": "AHMETÇAVUŞ HAYITLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "MİLAS",
        "STREET": "AKKOVANLIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "MİLAS",
        "STREET": "AKYOL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "MİLAS",
        "STREET": "AKÇAKAYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "MİLAS",
        "STREET": "AKÇALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "MİLAS",
        "STREET": "ALATEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "MİLAS",
        "STREET": "ALAÇAM MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "MİLAS",
        "STREET": "ASLANYAKA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "MİLAS",
        "STREET": "AVŞAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "MİLAS",
        "STREET": "AYDINLIKEVLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "MİLAS",
        "STREET": "AĞAÇLIHÜYÜK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "MİLAS",
        "STREET": "BAFA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "MİLAS",
        "STREET": "BAHARLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "MİLAS",
        "STREET": "BAHÇE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "MİLAS",
        "STREET": "BAHÇEBURUN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "MİLAS",
        "STREET": "BALCILAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "MİLAS",
        "STREET": "BAYIR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "MİLAS",
        "STREET": "BAĞDAMLARI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "MİLAS",
        "STREET": "BEYCİLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "MİLAS",
        "STREET": "BEÇİN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "MİLAS",
        "STREET": "BOZALAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "MİLAS",
        "STREET": "BOZBÜK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "MİLAS",
        "STREET": "BOĞAZİÇİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "MİLAS",
        "STREET": "BURGAZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "MİLAS",
        "STREET": "CUMHURİYET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "MİLAS",
        "STREET": "DAMLIBOĞAZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "MİLAS",
        "STREET": "DANİŞMENT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "MİLAS",
        "STREET": "DEMİRCİLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "MİLAS",
        "STREET": "DERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "MİLAS",
        "STREET": "DERİNCE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "MİLAS",
        "STREET": "DÖRTTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "MİLAS",
        "STREET": "DİBEKDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "MİLAS",
        "STREET": "EKİNANBARI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "MİLAS",
        "STREET": "EKİNDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "MİLAS",
        "STREET": "EKİZKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "MİLAS",
        "STREET": "EKİZTAŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "MİLAS",
        "STREET": "EMEK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "MİLAS",
        "STREET": "EPÇE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "MİLAS",
        "STREET": "ETRENLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "MİLAS",
        "STREET": "EĞRİDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "MİLAS",
        "STREET": "FESLEĞEN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "MİLAS",
        "STREET": "FİRUZPAŞA GAZİPAŞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "MİLAS",
        "STREET": "GÖKBEL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "MİLAS",
        "STREET": "GÖKÇELER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "MİLAS",
        "STREET": "GÖLDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "MİLAS",
        "STREET": "GÖLYAKA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "MİLAS",
        "STREET": "GÜLLÜK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "MİLAS",
        "STREET": "GÜMÜŞLÜK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "MİLAS",
        "STREET": "GÜNEŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "MİLAS",
        "STREET": "GÜNLÜK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "MİLAS",
        "STREET": "GÜRCEĞİZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "MİLAS",
        "STREET": "GÜRÇAMLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "MİLAS",
        "STREET": "HACI İLYAS MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "MİLAS",
        "STREET": "HACIABTİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "MİLAS",
        "STREET": "HACIAHMETLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "MİLAS",
        "STREET": "HASANLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "MİLAS",
        "STREET": "HÜSAMLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "MİLAS",
        "STREET": "HİSARBAŞI-HOCA BEDRETTİN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "MİLAS",
        "STREET": "HİSARCIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "MİLAS",
        "STREET": "KAFACA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "MİLAS",
        "STREET": "KALEM MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "MİLAS",
        "STREET": "KALINAĞIL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "MİLAS",
        "STREET": "KANDAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "MİLAS",
        "STREET": "KAPIKIRI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "MİLAS",
        "STREET": "KARACAAĞAÇ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "MİLAS",
        "STREET": "KARACAHİSAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "MİLAS",
        "STREET": "KARAHAYIT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "MİLAS",
        "STREET": "KARAKUYU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "MİLAS",
        "STREET": "KARAPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "MİLAS",
        "STREET": "KARGICAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "MİLAS",
        "STREET": "KARŞIYAKA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "MİLAS",
        "STREET": "KAYABAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "MİLAS",
        "STREET": "KAYABÜKÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "MİLAS",
        "STREET": "KAYADERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "MİLAS",
        "STREET": "KAZIKLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "MİLAS",
        "STREET": "KEMİKLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "MİLAS",
        "STREET": "KETENDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "MİLAS",
        "STREET": "KILAVUZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "MİLAS",
        "STREET": "KIRCAĞIZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "MİLAS",
        "STREET": "KISIRLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "MİLAS",
        "STREET": "KIYIKIŞLACIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "MİLAS",
        "STREET": "KIZILAĞAÇ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "MİLAS",
        "STREET": "KIZILCAKUYU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "MİLAS",
        "STREET": "KIZILCAYIKIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "MİLAS",
        "STREET": "KONAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "MİLAS",
        "STREET": "KORU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "MİLAS",
        "STREET": "KORUCUK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "MİLAS",
        "STREET": "KULTAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "MİLAS",
        "STREET": "KURUDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "MİLAS",
        "STREET": "KUZYAKA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "MİLAS",
        "STREET": "KÖŞK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "MİLAS",
        "STREET": "KÜÇÜKDİBEKDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "MİLAS",
        "STREET": "MENTEŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "MİLAS",
        "STREET": "MEŞELİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "MİLAS",
        "STREET": "NARHİSAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "MİLAS",
        "STREET": "OLUKBAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "MİLAS",
        "STREET": "ORTAKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "MİLAS",
        "STREET": "OVAKIŞLACIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "MİLAS",
        "STREET": "PINARARASI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "MİLAS",
        "STREET": "PINARCIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "MİLAS",
        "STREET": "PİNAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "MİLAS",
        "STREET": "SAKARKAYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "MİLAS",
        "STREET": "SARIKAYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "MİLAS",
        "STREET": "SAVRAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "MİLAS",
        "STREET": "SEK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "MİLAS",
        "STREET": "SELİMİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "MİLAS",
        "STREET": "SÖĞÜTÇÜK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "MİLAS",
        "STREET": "TUZABAT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "MİLAS",
        "STREET": "TÜRKEVLERİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "MİLAS",
        "STREET": "ULAŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "MİLAS",
        "STREET": "YAKA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "MİLAS",
        "STREET": "YAŞYER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "MİLAS",
        "STREET": "YOĞUNOLUK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "MİLAS",
        "STREET": "YUSUFCA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "MİLAS",
        "STREET": "ÇAKIRALAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "MİLAS",
        "STREET": "ÇALLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "MİLAS",
        "STREET": "ÇAMKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "MİLAS",
        "STREET": "ÇAMLIBELEN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "MİLAS",
        "STREET": "ÇAMLICA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "MİLAS",
        "STREET": "ÇAMLIYURT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "MİLAS",
        "STREET": "ÇAMOVALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "MİLAS",
        "STREET": "ÇANDIR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "MİLAS",
        "STREET": "ÇINARLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "MİLAS",
        "STREET": "ÇOMAKDAĞKIZILAĞAÇ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "MİLAS",
        "STREET": "ÇUKUR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "MİLAS",
        "STREET": "ÇÖKERTME MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "MİLAS",
        "STREET": "ÇİFTLİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "MİLAS",
        "STREET": "ÖREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "MİLAS",
        "STREET": "İSMET PAŞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "MİLAS",
        "STREET": "İÇME MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "MİLAS",
        "STREET": "ŞENKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "MİLAS",
        "STREET": "ŞEVKETİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "ORTACA",
        "STREET": "AKINCI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "ORTACA",
        "STREET": "ARIKBAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "ORTACA",
        "STREET": "ATATÜRK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "ORTACA",
        "STREET": "BAHÇELİEVLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "ORTACA",
        "STREET": "BEŞKÖPRÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "ORTACA",
        "STREET": "CUMHURİYET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "ORTACA",
        "STREET": "DALAKLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "ORTACA",
        "STREET": "DALYAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "ORTACA",
        "STREET": "DEREKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "ORTACA",
        "STREET": "DİKMEKAVAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "ORTACA",
        "STREET": "EKŞİLİYURT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "ORTACA",
        "STREET": "ESKİKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "ORTACA",
        "STREET": "FEVZİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "ORTACA",
        "STREET": "GÖKBEL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "ORTACA",
        "STREET": "GÖLBAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "ORTACA",
        "STREET": "GÜZELYURT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "ORTACA",
        "STREET": "KARABURUN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "ORTACA",
        "STREET": "KARADONLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "ORTACA",
        "STREET": "KEMALİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "ORTACA",
        "STREET": "MERGENLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "ORTACA",
        "STREET": "OKÇULAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "ORTACA",
        "STREET": "SARIGERME MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "ORTACA",
        "STREET": "TEPEARASI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "ORTACA",
        "STREET": "TERZİALİLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "ORTACA",
        "STREET": "YERBELEN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "ORTACA",
        "STREET": "YEŞİLYURT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "ORTACA",
        "STREET": "ÇAYLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "SEYDİKEMER",
        "STREET": "ALAÇAT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "SEYDİKEMER",
        "STREET": "ARSAKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "SEYDİKEMER",
        "STREET": "ARİFLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "SEYDİKEMER",
        "STREET": "ATLIDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "SEYDİKEMER",
        "STREET": "BAYIRKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "SEYDİKEMER",
        "STREET": "BAĞLIAĞAÇ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "SEYDİKEMER",
        "STREET": "BEKÇİLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "SEYDİKEMER",
        "STREET": "BELEN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "SEYDİKEMER",
        "STREET": "BOĞALAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "SEYDİKEMER",
        "STREET": "BOĞAZİÇİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "SEYDİKEMER",
        "STREET": "CEYLAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "SEYDİKEMER",
        "STREET": "CUMHURİYET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "SEYDİKEMER",
        "STREET": "DEMİRLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "SEYDİKEMER",
        "STREET": "DEREKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "SEYDİKEMER",
        "STREET": "DODURGA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "SEYDİKEMER",
        "STREET": "DONT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "SEYDİKEMER",
        "STREET": "DOĞANLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "SEYDİKEMER",
        "STREET": "DÖĞER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "SEYDİKEMER",
        "STREET": "EŞEN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "SEYDİKEMER",
        "STREET": "GERİŞBURNU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "SEYDİKEMER",
        "STREET": "GÖLBENT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "SEYDİKEMER",
        "STREET": "GÜNEŞLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "SEYDİKEMER",
        "STREET": "GİRMELER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "SEYDİKEMER",
        "STREET": "HACIOSMANLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "SEYDİKEMER",
        "STREET": "KABAAĞAÇ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "SEYDİKEMER",
        "STREET": "KADIKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "SEYDİKEMER",
        "STREET": "KARADERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "SEYDİKEMER",
        "STREET": "KARAKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "SEYDİKEMER",
        "STREET": "KAYABAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "SEYDİKEMER",
        "STREET": "KAYACIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "SEYDİKEMER",
        "STREET": "KAYADİBİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "SEYDİKEMER",
        "STREET": "KINCILAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "SEYDİKEMER",
        "STREET": "KINIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "SEYDİKEMER",
        "STREET": "KORUBÜKÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "SEYDİKEMER",
        "STREET": "KUMLUOVA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "SEYDİKEMER",
        "STREET": "MENEKŞE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "SEYDİKEMER",
        "STREET": "MİNARE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "SEYDİKEMER",
        "STREET": "ORTAKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "SEYDİKEMER",
        "STREET": "PAŞALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "SEYDİKEMER",
        "STREET": "SAHİLCEYLAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "SEYDİKEMER",
        "STREET": "SARIYER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "SEYDİKEMER",
        "STREET": "SEKİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "SEYDİKEMER",
        "STREET": "SEYDİKEMER-EŞEN T TİPİ AÇIK VE KAPALI CEZAEVİ",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "SEYDİKEMER",
        "STREET": "SEYDİLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "SEYDİKEMER",
        "STREET": "SÖĞÜTLÜDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "SEYDİKEMER",
        "STREET": "TEMEL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "SEYDİKEMER",
        "STREET": "UĞURLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "SEYDİKEMER",
        "STREET": "YAKABAĞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "SEYDİKEMER",
        "STREET": "YAKAKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "SEYDİKEMER",
        "STREET": "YAYLA ELDİREK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "SEYDİKEMER",
        "STREET": "YAYLA GÖKBEN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "SEYDİKEMER",
        "STREET": "YAYLA KARAÇULHA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "SEYDİKEMER",
        "STREET": "YAYLAPATLANGIÇ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "SEYDİKEMER",
        "STREET": "ZORLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "SEYDİKEMER",
        "STREET": "ÇALTILAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "SEYDİKEMER",
        "STREET": "ÇALTIÖZÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "SEYDİKEMER",
        "STREET": "ÇAMURKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "SEYDİKEMER",
        "STREET": "ÇATAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "SEYDİKEMER",
        "STREET": "ÇAYAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "SEYDİKEMER",
        "STREET": "ÇAYKENARI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "SEYDİKEMER",
        "STREET": "ÇOBANLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "SEYDİKEMER",
        "STREET": "ÇOBANİSA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "SEYDİKEMER",
        "STREET": "ÇUKURİNCİR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "SEYDİKEMER",
        "STREET": "ÇÖKEK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "SEYDİKEMER",
        "STREET": "ÖREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "SEYDİKEMER",
        "STREET": "İZZETTİNKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "ULA",
        "STREET": "AKYAKA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "ULA",
        "STREET": "AKÇAPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "ULA",
        "STREET": "ALPARSLAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "ULA",
        "STREET": "ARICILAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "ULA",
        "STREET": "ARMUTCUK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "ULA",
        "STREET": "ATAKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "ULA",
        "STREET": "AYAZKIYI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "ULA",
        "STREET": "DEMİRTAŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "ULA",
        "STREET": "ELMALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "ULA",
        "STREET": "ESENTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "ULA",
        "STREET": "GÖKOVA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "ULA",
        "STREET": "GÖKÇE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "ULA",
        "STREET": "GÖLCÜK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "ULA",
        "STREET": "KARABÖRTLEN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "ULA",
        "STREET": "KAVAKÇALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "ULA",
        "STREET": "KIRA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "ULA",
        "STREET": "KIZILAĞAÇ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "ULA",
        "STREET": "KIZILYAKA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "ULA",
        "STREET": "KÖPRÜBAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "ULA",
        "STREET": "PORTAKALLIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "ULA",
        "STREET": "SARAYYANI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "ULA",
        "STREET": "TURGUT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "ULA",
        "STREET": "YAYLASÖĞÜT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "ULA",
        "STREET": "YEŞİLOVA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "ULA",
        "STREET": "YEŞİLÇAM MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "ULA",
        "STREET": "ÇITLIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "ULA",
        "STREET": "ÇÖRÜŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "ULA",
        "STREET": "ÇİÇEKLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "ULA",
        "STREET": "ÖRNEKKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "ULA",
        "STREET": "ŞİRİNKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "YATAĞAN",
        "STREET": "AKGEDİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "YATAĞAN",
        "STREET": "AKYOL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "YATAĞAN",
        "STREET": "ALAŞAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "YATAĞAN",
        "STREET": "ATATÜRK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "YATAĞAN",
        "STREET": "BAHÇEYAKA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "YATAĞAN",
        "STREET": "BAĞYAKA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "YATAĞAN",
        "STREET": "BENCİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "YATAĞAN",
        "STREET": "BOZARMUT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "YATAĞAN",
        "STREET": "BOZÜYÜK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "YATAĞAN",
        "STREET": "CAZKIRLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "YATAĞAN",
        "STREET": "CUMHURİYET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "YATAĞAN",
        "STREET": "DERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "YATAĞAN",
        "STREET": "DEŞTİN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "YATAĞAN",
        "STREET": "DOĞANKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "YATAĞAN",
        "STREET": "ELMACIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "YATAĞAN",
        "STREET": "ESENKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "YATAĞAN",
        "STREET": "ESKİHİSAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "YATAĞAN",
        "STREET": "GÖKGEDİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "YATAĞAN",
        "STREET": "GÖKPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "YATAĞAN",
        "STREET": "HACIBAYRAMLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "YATAĞAN",
        "STREET": "HACIVELİLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "YATAĞAN",
        "STREET": "HİSARARDI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "YATAĞAN",
        "STREET": "KADIKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "YATAĞAN",
        "STREET": "KAFACAKAPLANCIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "YATAĞAN",
        "STREET": "KAPUBAĞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "YATAĞAN",
        "STREET": "KATRANCI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "YATAĞAN",
        "STREET": "KAVAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "YATAĞAN",
        "STREET": "KIRIKKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "YATAĞAN",
        "STREET": "KONAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "YATAĞAN",
        "STREET": "KOZAĞAÇ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "YATAĞAN",
        "STREET": "KÖKLÜK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "YATAĞAN",
        "STREET": "MADENLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "YATAĞAN",
        "STREET": "MESKEN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "YATAĞAN",
        "STREET": "NEBİKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "YATAĞAN",
        "STREET": "TURGUT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "YATAĞAN",
        "STREET": "TURGUTLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "YATAĞAN",
        "STREET": "YAVA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "YATAĞAN",
        "STREET": "YAYLA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "YATAĞAN",
        "STREET": "YENİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "YATAĞAN",
        "STREET": "YENİKARAKUYU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "YATAĞAN",
        "STREET": "YENİKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "YATAĞAN",
        "STREET": "YEŞİLBAĞCILAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "YATAĞAN",
        "STREET": "YEŞİLKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "YATAĞAN",
        "STREET": "YUKARIYAYLA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "YATAĞAN",
        "STREET": "ZEYTİNKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "YATAĞAN",
        "STREET": "ÇAKIRLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "YATAĞAN",
        "STREET": "ÇAMLICA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "YATAĞAN",
        "STREET": "ÇUKURÖZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "YATAĞAN",
        "STREET": "ŞAHİNLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUĞLA",
        "DISTRICT": "YATAĞAN",
        "STREET": "ŞEREF MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "BULANIK",
        "STREET": "ADIVAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "BULANIK",
        "STREET": "AKÇAARMUT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "BULANIK",
        "STREET": "ALTINOLUK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "BULANIK",
        "STREET": "ARAKONAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "BULANIK",
        "STREET": "AŞAĞIBÜKLÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "BULANIK",
        "STREET": "BAHÇELİEVLER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "BULANIK",
        "STREET": "BALOTU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "BULANIK",
        "STREET": "BOSTANCILAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "BULANIK",
        "STREET": "BÜNGÜLDEK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "BULANIK",
        "STREET": "CANKURTARAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "BULANIK",
        "STREET": "CUMHURİYET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "BULANIK",
        "STREET": "DEMİRKAPI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "BULANIK",
        "STREET": "DEĞİRMENSUYU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "BULANIK",
        "STREET": "DOKUZPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "BULANIK",
        "STREET": "ELMAKAYA-CUMHURİYET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "BULANIK",
        "STREET": "ELMAKAYA-KARŞIYAKA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "BULANIK",
        "STREET": "ERENTEPE-FATİH MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "BULANIK",
        "STREET": "ERENTEPE-GAZİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "BULANIK",
        "STREET": "ERENTEPE-KALE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "BULANIK",
        "STREET": "ERİCEK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "BULANIK",
        "STREET": "ESENLİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "BULANIK",
        "STREET": "ESKİYOL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "BULANIK",
        "STREET": "GÖLYANI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "BULANIK",
        "STREET": "GÖZTEPE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "BULANIK",
        "STREET": "GÜLLÜOVA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "BULANIK",
        "STREET": "GÜLÇİMEN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "BULANIK",
        "STREET": "GÜMÜŞPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "BULANIK",
        "STREET": "GÜNBATMAZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "BULANIK",
        "STREET": "GÜNDÜZÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "BULANIK",
        "STREET": "GÜNYURDU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "BULANIK",
        "STREET": "HAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "BULANIK",
        "STREET": "HOŞGELDİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "BULANIK",
        "STREET": "KARAAĞIL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "BULANIK",
        "STREET": "KARABURUN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "BULANIK",
        "STREET": "KIRKGÖZE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "BULANIK",
        "STREET": "KOTANLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "BULANIK",
        "STREET": "KOYUNAĞILI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "BULANIK",
        "STREET": "KURGANLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "BULANIK",
        "STREET": "KÖPRÜYOLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "BULANIK",
        "STREET": "KÜLTÜR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "BULANIK",
        "STREET": "MESCİTLİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "BULANIK",
        "STREET": "MEŞEİÇİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "BULANIK",
        "STREET": "MOLLADAVUT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "BULANIK",
        "STREET": "MOLLAKENT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "BULANIK",
        "STREET": "OKÇULAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "BULANIK",
        "STREET": "OLURDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "BULANIK",
        "STREET": "OĞLAKKAYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "BULANIK",
        "STREET": "RÜSTEMGEDİK-BAĞLAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "BULANIK",
        "STREET": "RÜSTEMGEDİK-HANEDAN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "BULANIK",
        "STREET": "RÜSTEMGEDİK-ZAFER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "BULANIK",
        "STREET": "RÜSTEMGEDİK-İNÖNÜ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "BULANIK",
        "STREET": "SAMANYOLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "BULANIK",
        "STREET": "SARIPINAR-PINAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "BULANIK",
        "STREET": "SARIPINAR-SİNAN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "BULANIK",
        "STREET": "SARIPINAR-ÇİMEN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "BULANIK",
        "STREET": "SEÇME KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "BULANIK",
        "STREET": "SIRADERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "BULANIK",
        "STREET": "SULTANLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "BULANIK",
        "STREET": "SÖĞÜTLÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "BULANIK",
        "STREET": "UZGÖRÜR-ABDALBEYAZIT MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "BULANIK",
        "STREET": "UZGÖRÜR-ALAGEZ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "BULANIK",
        "STREET": "UZGÖRÜR-ARI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "BULANIK",
        "STREET": "UZGÖRÜR-FATİH MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "BULANIK",
        "STREET": "UZGÖRÜR-GAZİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "BULANIK",
        "STREET": "YAZBAŞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "BULANIK",
        "STREET": "YEMİŞEN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "BULANIK",
        "STREET": "YENİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "BULANIK",
        "STREET": "YOKUŞBAŞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "BULANIK",
        "STREET": "YONCALI-CUMHURİYET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "BULANIK",
        "STREET": "YONCALI-HÜRRİYET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "BULANIK",
        "STREET": "YONCALI-YENİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "BULANIK",
        "STREET": "ZAFER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "BULANIK",
        "STREET": "ÇATAKLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "BULANIK",
        "STREET": "ÇAYGELDİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "BULANIK",
        "STREET": "ÖRENKENT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "BULANIK",
        "STREET": "ÜÇTEPE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "BULANIK",
        "STREET": "İNÖNÜ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "BULANIK",
        "STREET": "ŞATIRLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "BULANIK",
        "STREET": "ŞEHİTLİK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "BULANIK",
        "STREET": "ŞEHİTTAHİR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "BULANIK",
        "STREET": "ŞEHİTVEREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "HASKÖY",
        "STREET": "15 TEMMUZ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "HASKÖY",
        "STREET": "AZIKLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "HASKÖY",
        "STREET": "AŞAĞIÜÇDAM KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "HASKÖY",
        "STREET": "BAHÇELİEVLER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "HASKÖY",
        "STREET": "BÖĞÜRDELEN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "HASKÖY",
        "STREET": "BÜVETLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "HASKÖY",
        "STREET": "DAĞDİBİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "HASKÖY",
        "STREET": "DÜZKIŞLA-BAHÇELİEVLER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "HASKÖY",
        "STREET": "DÜZKIŞLA-KÜLTÜR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "HASKÖY",
        "STREET": "DÜZKIŞLA-YENİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "HASKÖY",
        "STREET": "ELMABULAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "HASKÖY",
        "STREET": "EŞMEPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "HASKÖY",
        "STREET": "GÖKYAZI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "HASKÖY",
        "STREET": "HÜRRİYET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "HASKÖY",
        "STREET": "KARAKÜTÜK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "HASKÖY",
        "STREET": "KOÇ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "HASKÖY",
        "STREET": "KOĞUKTAŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "HASKÖY",
        "STREET": "KÜLTÜR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "HASKÖY",
        "STREET": "ORTANCA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "HASKÖY",
        "STREET": "OTAÇ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "HASKÖY",
        "STREET": "SARIBAHÇE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "HASKÖY",
        "STREET": "SAYANLAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "HASKÖY",
        "STREET": "SUNAY MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "HASKÖY",
        "STREET": "UMURCA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "HASKÖY",
        "STREET": "VATAN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "HASKÖY",
        "STREET": "YARKAYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "HASKÖY",
        "STREET": "YUKARIÜÇDAM KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "HASKÖY",
        "STREET": "ZAFER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "HASKÖY",
        "STREET": "İSTİKLAL MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "KORKUT",
        "STREET": "AKYILDIZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "KORKUT",
        "STREET": "ALAZLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "KORKUT",
        "STREET": "ALTINOVA-AYDINLAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "KORKUT",
        "STREET": "ALTINOVA-BAHÇELİEVLER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "KORKUT",
        "STREET": "ALTINOVA-MERKEZ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "KORKUT",
        "STREET": "ALTINOVA-YENİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "KORKUT",
        "STREET": "BAHÇELİEVLER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "KORKUT",
        "STREET": "BALKIR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "KORKUT",
        "STREET": "DEMİRCİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "KORKUT",
        "STREET": "DEREİÇİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "KORKUT",
        "STREET": "DEĞİRMİTAŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "KORKUT",
        "STREET": "DURUCAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "KORKUT",
        "STREET": "DÜZOVA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "KORKUT",
        "STREET": "GÜLTEPE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "KORKUT",
        "STREET": "GÜNEYİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "KORKUT",
        "STREET": "GÜVEN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "KORKUT",
        "STREET": "KAPILI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "KORKUT",
        "STREET": "KARAKALE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "KORKUT",
        "STREET": "KIRYAKA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "KORKUT",
        "STREET": "KOCATARLA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "KORKUT",
        "STREET": "KONAKDÜZÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "KORKUT",
        "STREET": "KÜLTÜR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "KORKUT",
        "STREET": "MOLLABABA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "KORKUT",
        "STREET": "OĞULBALI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "KORKUT",
        "STREET": "PINARÜSTÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "KORKUT",
        "STREET": "SARMAŞIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "KORKUT",
        "STREET": "SAZLIKBAŞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "KORKUT",
        "STREET": "TAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "KORKUT",
        "STREET": "TAŞLICA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "KORKUT",
        "STREET": "YEDİPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "KORKUT",
        "STREET": "YOLGÖZLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "KORKUT",
        "STREET": "YÜNÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "KORKUT",
        "STREET": "YÜREKLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "KORKUT",
        "STREET": "ÇALAPLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "KORKUT",
        "STREET": "ÇINARARDI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "KORKUT",
        "STREET": "İÇBOĞAZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "KORKUT",
        "STREET": "ŞEHİTLER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "MALAZGİRT",
        "STREET": "ADAKSU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "MALAZGİRT",
        "STREET": "ADALAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "MALAZGİRT",
        "STREET": "AFŞİN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "MALAZGİRT",
        "STREET": "AKALAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "MALAZGİRT",
        "STREET": "AKSUNGUR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "MALAZGİRT",
        "STREET": "ALTUNTAŞ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "MALAZGİRT",
        "STREET": "ALYAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "MALAZGİRT",
        "STREET": "ALİKALKAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "MALAZGİRT",
        "STREET": "ALİŞAN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "MALAZGİRT",
        "STREET": "ARADERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "MALAZGİRT",
        "STREET": "ARSLANKAYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "MALAZGİRT",
        "STREET": "AYNALIHOCA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "MALAZGİRT",
        "STREET": "AĞILBAŞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "MALAZGİRT",
        "STREET": "AŞAĞIKICIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "MALAZGİRT",
        "STREET": "BAHÇE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "MALAZGİRT",
        "STREET": "BALKAYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "MALAZGİRT",
        "STREET": "BEYPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "MALAZGİRT",
        "STREET": "BEŞDAM KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "MALAZGİRT",
        "STREET": "BEŞÇATAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "MALAZGİRT",
        "STREET": "BOSTANKAYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "MALAZGİRT",
        "STREET": "BOYÇAPKIN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "MALAZGİRT",
        "STREET": "DANIŞMENTGAZİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "MALAZGİRT",
        "STREET": "DOLABAŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "MALAZGİRT",
        "STREET": "DOĞANTAŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "MALAZGİRT",
        "STREET": "DİRİMPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "MALAZGİRT",
        "STREET": "ERENCE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "MALAZGİRT",
        "STREET": "GÜLEÇ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "MALAZGİRT",
        "STREET": "GÜLKORU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "MALAZGİRT",
        "STREET": "HANCAĞIZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "MALAZGİRT",
        "STREET": "HASANPAŞA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "MALAZGİRT",
        "STREET": "HASRETPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "MALAZGİRT",
        "STREET": "KARAALİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "MALAZGİRT",
        "STREET": "KARAHASAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "MALAZGİRT",
        "STREET": "KARAKAYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "MALAZGİRT",
        "STREET": "KARAKOÇ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "MALAZGİRT",
        "STREET": "KARDEŞLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "MALAZGİRT",
        "STREET": "KARINCALI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "MALAZGİRT",
        "STREET": "KAZGÖL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "MALAZGİRT",
        "STREET": "KILIÇÇI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "MALAZGİRT",
        "STREET": "KIZILYUSUF KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "MALAZGİRT",
        "STREET": "KONAKKURAN-ATATÜRK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "MALAZGİRT",
        "STREET": "KONAKKURAN-MURAT MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "MALAZGİRT",
        "STREET": "KONAKKURAN-YENİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "MALAZGİRT",
        "STREET": "KONAKKURAN-ZİYARET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "MALAZGİRT",
        "STREET": "KOÇALİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "MALAZGİRT",
        "STREET": "KULCAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "MALAZGİRT",
        "STREET": "MAĞALCIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "MALAZGİRT",
        "STREET": "MENGÜÇGAZİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "MALAZGİRT",
        "STREET": "MEZRAAKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "MALAZGİRT",
        "STREET": "MOLLABAKİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "MALAZGİRT",
        "STREET": "MOLLADERMAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "MALAZGİRT",
        "STREET": "MURAT MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "MALAZGİRT",
        "STREET": "MURATKOLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "MALAZGİRT",
        "STREET": "NURETTİN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "MALAZGİRT",
        "STREET": "OKÇUHAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "MALAZGİRT",
        "STREET": "OĞUZHAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "MALAZGİRT",
        "STREET": "SAFTEKİNGAZİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "MALAZGİRT",
        "STREET": "SALTUKGAZİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "MALAZGİRT",
        "STREET": "SARIDAVUT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "MALAZGİRT",
        "STREET": "SELEKUTU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "MALAZGİRT",
        "STREET": "SELÇUKLU MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "MALAZGİRT",
        "STREET": "SIRTDÜZÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "MALAZGİRT",
        "STREET": "SÖĞÜTLÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "MALAZGİRT",
        "STREET": "TATARGAZİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "MALAZGİRT",
        "STREET": "TATLICA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "MALAZGİRT",
        "STREET": "TIKIZLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "MALAZGİRT",
        "STREET": "ULUSU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "MALAZGİRT",
        "STREET": "UYANIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "MALAZGİRT",
        "STREET": "YAPRAKLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "MALAZGİRT",
        "STREET": "YARAMIŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "MALAZGİRT",
        "STREET": "YOLGÖZLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "MALAZGİRT",
        "STREET": "YUKARIKICIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "MALAZGİRT",
        "STREET": "YURTSEVEN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "MALAZGİRT",
        "STREET": "ÇAYIRDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "MALAZGİRT",
        "STREET": "ÇİÇEKVEREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "MALAZGİRT",
        "STREET": "ÖRENŞAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "MALAZGİRT",
        "STREET": "İYİKOMŞU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "MALAZGİRT",
        "STREET": "ŞEKERBULAK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "MERKEZ",
        "STREET": "15 TEMMUZ MİLLİ İRADE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "MERKEZ",
        "STREET": "AKKONAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "MERKEZ",
        "STREET": "AKPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "MERKEZ",
        "STREET": "ALAGÜN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "MERKEZ",
        "STREET": "ALANİÇİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "MERKEZ",
        "STREET": "ALİCAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "MERKEZ",
        "STREET": "ALİGEDİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "MERKEZ",
        "STREET": "ARIKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "MERKEZ",
        "STREET": "ARPAYAZI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "MERKEZ",
        "STREET": "AYDOĞAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "MERKEZ",
        "STREET": "AĞARTI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "MERKEZ",
        "STREET": "AĞAÇLIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "MERKEZ",
        "STREET": "AĞILLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "MERKEZ",
        "STREET": "AŞAĞIYONGALI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "MERKEZ",
        "STREET": "BAHÇEKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "MERKEZ",
        "STREET": "BALCILAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "MERKEZ",
        "STREET": "BAĞLAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "MERKEZ",
        "STREET": "BEŞPARMAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "MERKEZ",
        "STREET": "BOSTANKENT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "MERKEZ",
        "STREET": "BOYUNCUK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "MERKEZ",
        "STREET": "BOZBULUT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "MERKEZ",
        "STREET": "BİLEK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "MERKEZ",
        "STREET": "CEVİZLİDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "MERKEZ",
        "STREET": "DERE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "MERKEZ",
        "STREET": "DERECİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "MERKEZ",
        "STREET": "DEREYURT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "MERKEZ",
        "STREET": "DONATIM KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "MERKEZ",
        "STREET": "DUMLUSU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "MERKEZ",
        "STREET": "DURUGÖZE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "MERKEZ",
        "STREET": "DİLİMLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "MERKEZ",
        "STREET": "EKİNDÜZÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "MERKEZ",
        "STREET": "ELÇİLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "MERKEZ",
        "STREET": "ERALANI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "MERKEZ",
        "STREET": "ERENCİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "MERKEZ",
        "STREET": "EĞİRMEÇ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "MERKEZ",
        "STREET": "GÖLKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "MERKEZ",
        "STREET": "GÜDÜMLÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "MERKEZ",
        "STREET": "GÜMÜŞALİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "MERKEZ",
        "STREET": "GÜNDOĞAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "MERKEZ",
        "STREET": "GÜZELTEPE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "MERKEZ",
        "STREET": "HARMAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "MERKEZ",
        "STREET": "HÜRRİYET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "MERKEZ",
        "STREET": "ILICA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "MERKEZ",
        "STREET": "KALE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "MERKEZ",
        "STREET": "KALECİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "MERKEZ",
        "STREET": "KARAAĞAÇ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "MERKEZ",
        "STREET": "KARAAĞAÇLI-BAĞLAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "MERKEZ",
        "STREET": "KARAAĞAÇLI-KÜLTÜR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "MERKEZ",
        "STREET": "KARAAĞAÇLI-ÇUBUKLU MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "MERKEZ",
        "STREET": "KARABEY KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "MERKEZ",
        "STREET": "KARAKUYU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "MERKEZ",
        "STREET": "KARAKÖPRÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "MERKEZ",
        "STREET": "KARAMEŞE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "MERKEZ",
        "STREET": "KARŞIYAKA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "MERKEZ",
        "STREET": "KAYALISU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "MERKEZ",
        "STREET": "KAYAŞIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "MERKEZ",
        "STREET": "KEPENEK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "MERKEZ",
        "STREET": "KEÇİDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "MERKEZ",
        "STREET": "KIRKÖY-BAHÇELİEVLER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "MERKEZ",
        "STREET": "KIRKÖY-DÜZ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "MERKEZ",
        "STREET": "KIRKÖY-KÜLTÜR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "MERKEZ",
        "STREET": "KIRKÖY-YENİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "MERKEZ",
        "STREET": "KIYIBAŞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "MERKEZ",
        "STREET": "KIYIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "MERKEZ",
        "STREET": "KIZILAĞAÇ-FETULLAHAĞA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "MERKEZ",
        "STREET": "KIZILAĞAÇ-KÜLTÜR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "MERKEZ",
        "STREET": "KIZILAĞAÇ-YEŞİLYURT MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "MERKEZ",
        "STREET": "KONUKBEKLER-KÖRPEAĞAÇ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "MERKEZ",
        "STREET": "KONUKBEKLER-MİNARE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "MERKEZ",
        "STREET": "KONUKBEKLER-YEROLUK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "MERKEZ",
        "STREET": "KONUKBEKLER-ŞİRİNEVLER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "MERKEZ",
        "STREET": "KUMLUCA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "MERKEZ",
        "STREET": "KUTLUGÜN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "MERKEZ",
        "STREET": "KÖŞK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "MERKEZ",
        "STREET": "KÜLTÜR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "MERKEZ",
        "STREET": "MERCİMEKKALE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "MERKEZ",
        "STREET": "MESCİTLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "MERKEZ",
        "STREET": "MEŞECİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "MERKEZ",
        "STREET": "MURATGÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "MERKEZ",
        "STREET": "MURATPAŞA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "MERKEZ",
        "STREET": "MUŞ E TİPİ KAPALI CEZA İNFAZ KURUMU",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "MERKEZ",
        "STREET": "MİNARE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "MERKEZ",
        "STREET": "NADASLIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "MERKEZ",
        "STREET": "ORTAKENT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "MERKEZ",
        "STREET": "OYMATAŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "MERKEZ",
        "STREET": "SARAY MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "MERKEZ",
        "STREET": "SARIDAL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "MERKEZ",
        "STREET": "SAVAŞÇILAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "MERKEZ",
        "STREET": "SERİNOVA-AYDINGÜN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "MERKEZ",
        "STREET": "SERİNOVA-BAKIRCILAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "MERKEZ",
        "STREET": "SERİNOVA-HÜRRİYET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "MERKEZ",
        "STREET": "SERİNOVA-KARŞIYAKA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "MERKEZ",
        "STREET": "SERİNOVA-ÇAĞDAŞ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "MERKEZ",
        "STREET": "SOĞUCAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "MERKEZ",
        "STREET": "SUBOYU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "MERKEZ",
        "STREET": "SUDURAĞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "MERKEZ",
        "STREET": "SULUCA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "MERKEZ",
        "STREET": "SUNAY MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "MERKEZ",
        "STREET": "SUNGU-BAHÇELİEVLER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "MERKEZ",
        "STREET": "SUNGU-HÜRRİYET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "MERKEZ",
        "STREET": "SUNGU-KÜLTÜR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "MERKEZ",
        "STREET": "SUNGU-ŞEHİT NAİM ÇİFTÇİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "MERKEZ",
        "STREET": "SUNGU-ŞEHİT YÜCEL KURTOĞLU MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "MERKEZ",
        "STREET": "SUVARAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "MERKEZ",
        "STREET": "SÜRÜGÜDEN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "MERKEZ",
        "STREET": "SÜTLÜCE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "MERKEZ",
        "STREET": "TABANLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "MERKEZ",
        "STREET": "TANDOĞAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "MERKEZ",
        "STREET": "TAŞOLUK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "MERKEZ",
        "STREET": "TEKYOL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "MERKEZ",
        "STREET": "TOPRAKKALE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "MERKEZ",
        "STREET": "TÜTEN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "MERKEZ",
        "STREET": "ULUKAYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "MERKEZ",
        "STREET": "YAMAÇ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "MERKEZ",
        "STREET": "YARPUZLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "MERKEZ",
        "STREET": "YAYGIN-BAHÇELİEVLER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "MERKEZ",
        "STREET": "YAYGIN-DAGARASI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "MERKEZ",
        "STREET": "YAYGIN-KARDESLER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "MERKEZ",
        "STREET": "YAYGIN-KARSIYAKA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "MERKEZ",
        "STREET": "YAYGIN-MERKEZ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "MERKEZ",
        "STREET": "YAYGIN-YENİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "MERKEZ",
        "STREET": "YAZLA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "MERKEZ",
        "STREET": "YELALAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "MERKEZ",
        "STREET": "YENİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "MERKEZ",
        "STREET": "YEŞİLCE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "MERKEZ",
        "STREET": "YEŞİLOVA-BAHÇELİEVLER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "MERKEZ",
        "STREET": "YEŞİLOVA-KÜLTÜR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "MERKEZ",
        "STREET": "YEŞİLOVA-YENİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "MERKEZ",
        "STREET": "YEŞİLYURT MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "MERKEZ",
        "STREET": "YONCALIÖZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "MERKEZ",
        "STREET": "YUKARISIZMA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "MERKEZ",
        "STREET": "YUKARIYONGALI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "MERKEZ",
        "STREET": "YÜCETEPE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "MERKEZ",
        "STREET": "ZAFER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "MERKEZ",
        "STREET": "ZİYARET KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇATBAŞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇUKURBAĞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇÖĞÜRLÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇİÇEKLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "MERKEZ",
        "STREET": "ÖZDİLEK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "MERKEZ",
        "STREET": "ÜÇDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "MERKEZ",
        "STREET": "ÜÇEVLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "MERKEZ",
        "STREET": "ÜÇSIRT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "MERKEZ",
        "STREET": "İNARDI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "MERKEZ",
        "STREET": "ŞENOBA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "VARTO",
        "STREET": "AKÇATEPE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "VARTO",
        "STREET": "ALAGÖZ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "VARTO",
        "STREET": "AĞAÇALTI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "VARTO",
        "STREET": "AĞAÇKORUR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "VARTO",
        "STREET": "AŞAĞI ALAGÖZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "VARTO",
        "STREET": "AŞAĞI HACIBEY KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "VARTO",
        "STREET": "BAHÇELİEVLER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "VARTO",
        "STREET": "BALTAŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "VARTO",
        "STREET": "BAĞİÇİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "VARTO",
        "STREET": "BAŞKENT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "VARTO",
        "STREET": "BOYALI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "VARTO",
        "STREET": "BOYLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "VARTO",
        "STREET": "BUZLUGÖZE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "VARTO",
        "STREET": "DALLIÖZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "VARTO",
        "STREET": "DERİNCE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "VARTO",
        "STREET": "DEĞERLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "VARTO",
        "STREET": "DUTÖZÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "VARTO",
        "STREET": "DÖNERTAŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "VARTO",
        "STREET": "ERDOĞAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "VARTO",
        "STREET": "ESENLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "VARTO",
        "STREET": "GÜMGÜM MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "VARTO",
        "STREET": "HAKSEVER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "VARTO",
        "STREET": "HÜRRİYET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "VARTO",
        "STREET": "HÜSEYİNOĞLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "VARTO",
        "STREET": "KALECİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "VARTO",
        "STREET": "KARAKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "VARTO",
        "STREET": "KARAMEŞE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "VARTO",
        "STREET": "KARAPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "VARTO",
        "STREET": "KAYALIDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "VARTO",
        "STREET": "KAYALIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "VARTO",
        "STREET": "KAYNARCA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "VARTO",
        "STREET": "KUMLUKIYI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "VARTO",
        "STREET": "KÖPRÜCÜK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "VARTO",
        "STREET": "KÜLTÜR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "VARTO",
        "STREET": "LEYLEK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "VARTO",
        "STREET": "OMCALI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "VARTO",
        "STREET": "ONPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "VARTO",
        "STREET": "OĞLAKÇI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "VARTO",
        "STREET": "SAZLICA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "VARTO",
        "STREET": "TAŞDİBEK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "VARTO",
        "STREET": "TAŞÇI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "VARTO",
        "STREET": "TEPE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "VARTO",
        "STREET": "ULUSIRT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "VARTO",
        "STREET": "YAYLA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "VARTO",
        "STREET": "YENİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "VARTO",
        "STREET": "YEŞİLPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "VARTO",
        "STREET": "YILANLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "VARTO",
        "STREET": "YURTTUTAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "VARTO",
        "STREET": "ÇALIDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "VARTO",
        "STREET": "ÇAYLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "VARTO",
        "STREET": "ÇAYÇATI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "VARTO",
        "STREET": "İLBEY KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "VARTO",
        "STREET": "İNÖNÜ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "MUŞ",
        "DISTRICT": "VARTO",
        "STREET": "İÇMELER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "NEVŞEHİR",
        "DISTRICT": "ACIGÖL",
        "STREET": "AĞILLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "NEVŞEHİR",
        "DISTRICT": "ACIGÖL",
        "STREET": "BAHÇELİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "NEVŞEHİR",
        "DISTRICT": "ACIGÖL",
        "STREET": "BAĞLICA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "NEVŞEHİR",
        "DISTRICT": "ACIGÖL",
        "STREET": "CUMHURİYET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "NEVŞEHİR",
        "DISTRICT": "ACIGÖL",
        "STREET": "KARACAÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "NEVŞEHİR",
        "DISTRICT": "ACIGÖL",
        "STREET": "KARAPINAR-BAHÇELİ EVLER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "NEVŞEHİR",
        "DISTRICT": "ACIGÖL",
        "STREET": "KARAPINAR-MUSTAFAPAŞA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "NEVŞEHİR",
        "DISTRICT": "ACIGÖL",
        "STREET": "KOZLUCA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "NEVŞEHİR",
        "DISTRICT": "ACIGÖL",
        "STREET": "KURUGÖL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "NEVŞEHİR",
        "DISTRICT": "ACIGÖL",
        "STREET": "TATLARIN-BALLIK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "NEVŞEHİR",
        "DISTRICT": "ACIGÖL",
        "STREET": "TATLARIN-KALE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "NEVŞEHİR",
        "DISTRICT": "ACIGÖL",
        "STREET": "TEPEKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "NEVŞEHİR",
        "DISTRICT": "ACIGÖL",
        "STREET": "TOPAÇ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "NEVŞEHİR",
        "DISTRICT": "ACIGÖL",
        "STREET": "YUKARI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "NEVŞEHİR",
        "DISTRICT": "ACIGÖL",
        "STREET": "YUVA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "NEVŞEHİR",
        "DISTRICT": "ACIGÖL",
        "STREET": "ÇAYIRLIK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "NEVŞEHİR",
        "DISTRICT": "ACIGÖL",
        "STREET": "ÇULLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "NEVŞEHİR",
        "DISTRICT": "ACIGÖL",
        "STREET": "İNALLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "NEVŞEHİR",
        "DISTRICT": "AVANOS",
        "STREET": "AKARCA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "NEVŞEHİR",
        "DISTRICT": "AVANOS",
        "STREET": "AKTEPE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "NEVŞEHİR",
        "DISTRICT": "AVANOS",
        "STREET": "ALAADDİN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "NEVŞEHİR",
        "DISTRICT": "AVANOS",
        "STREET": "ALTIPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "NEVŞEHİR",
        "DISTRICT": "AVANOS",
        "STREET": "BAHÇELİEVLER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "NEVŞEHİR",
        "DISTRICT": "AVANOS",
        "STREET": "BOZCA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "NEVŞEHİR",
        "DISTRICT": "AVANOS",
        "STREET": "BÜYÜKAYHAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "NEVŞEHİR",
        "DISTRICT": "AVANOS",
        "STREET": "CUMHURİYET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "NEVŞEHİR",
        "DISTRICT": "AVANOS",
        "STREET": "GÖYNÜK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "NEVŞEHİR",
        "DISTRICT": "AVANOS",
        "STREET": "KALABA-50.YIL MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "NEVŞEHİR",
        "DISTRICT": "AVANOS",
        "STREET": "KALABA-HÜRRİYET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "NEVŞEHİR",
        "DISTRICT": "AVANOS",
        "STREET": "KALABA-KARAYUSUF MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "NEVŞEHİR",
        "DISTRICT": "AVANOS",
        "STREET": "KALABA-YENİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "NEVŞEHİR",
        "DISTRICT": "AVANOS",
        "STREET": "KARACAUŞAĞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "NEVŞEHİR",
        "DISTRICT": "AVANOS",
        "STREET": "KARASEKİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "NEVŞEHİR",
        "DISTRICT": "AVANOS",
        "STREET": "KUYULUKIŞLA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "NEVŞEHİR",
        "DISTRICT": "AVANOS",
        "STREET": "KÜÇÜKAYHAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "NEVŞEHİR",
        "DISTRICT": "AVANOS",
        "STREET": "MAHMAT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "NEVŞEHİR",
        "DISTRICT": "AVANOS",
        "STREET": "ORTA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "NEVŞEHİR",
        "DISTRICT": "AVANOS",
        "STREET": "PAŞALI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "NEVŞEHİR",
        "DISTRICT": "AVANOS",
        "STREET": "SARILAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "NEVŞEHİR",
        "DISTRICT": "AVANOS",
        "STREET": "TOPAKLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "NEVŞEHİR",
        "DISTRICT": "AVANOS",
        "STREET": "YENİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "NEVŞEHİR",
        "DISTRICT": "AVANOS",
        "STREET": "YUKARI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "NEVŞEHİR",
        "DISTRICT": "AVANOS",
        "STREET": "ÇALIŞ-AŞAĞI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "NEVŞEHİR",
        "DISTRICT": "AVANOS",
        "STREET": "ÇALIŞ-ORTA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "NEVŞEHİR",
        "DISTRICT": "AVANOS",
        "STREET": "ÇALIŞ-YUKARI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "NEVŞEHİR",
        "DISTRICT": "AVANOS",
        "STREET": "ÇAVUŞİN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "NEVŞEHİR",
        "DISTRICT": "AVANOS",
        "STREET": "ÖZKONAK-CAMİ-KEBİR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "NEVŞEHİR",
        "DISTRICT": "AVANOS",
        "STREET": "ÖZKONAK-CAMİCEDİT MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "NEVŞEHİR",
        "DISTRICT": "AVANOS",
        "STREET": "ÖZKONAK-ORTA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "NEVŞEHİR",
        "DISTRICT": "AVANOS",
        "STREET": "ÜÇKUYU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "NEVŞEHİR",
        "DISTRICT": "AVANOS",
        "STREET": "İĞDELİKIŞLA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "NEVŞEHİR",
        "DISTRICT": "DERİNKUYU",
        "STREET": "BAYRAMLI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "NEVŞEHİR",
        "DISTRICT": "DERİNKUYU",
        "STREET": "BAŞ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "NEVŞEHİR",
        "DISTRICT": "DERİNKUYU",
        "STREET": "CUMHURİYET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "NEVŞEHİR",
        "DISTRICT": "DERİNKUYU",
        "STREET": "DEMİRCİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "NEVŞEHİR",
        "DISTRICT": "DERİNKUYU",
        "STREET": "DOĞALA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "NEVŞEHİR",
        "DISTRICT": "DERİNKUYU",
        "STREET": "ESENTEPE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "NEVŞEHİR",
        "DISTRICT": "DERİNKUYU",
        "STREET": "FATİH MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "NEVŞEHİR",
        "DISTRICT": "DERİNKUYU",
        "STREET": "GÜNEYCE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "NEVŞEHİR",
        "DISTRICT": "DERİNKUYU",
        "STREET": "KUYULUTATLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "NEVŞEHİR",
        "DISTRICT": "DERİNKUYU",
        "STREET": "NAMIK KEMAL MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "NEVŞEHİR",
        "DISTRICT": "DERİNKUYU",
        "STREET": "SUVERMEZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "NEVŞEHİR",
        "DISTRICT": "DERİNKUYU",
        "STREET": "TİL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "NEVŞEHİR",
        "DISTRICT": "DERİNKUYU",
        "STREET": "YAZIHÜYÜK-ALPARSLAN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "NEVŞEHİR",
        "DISTRICT": "DERİNKUYU",
        "STREET": "YAZIHÜYÜK-BOZKIR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "NEVŞEHİR",
        "DISTRICT": "DERİNKUYU",
        "STREET": "YAZIHÜYÜK-GÖKTÜRK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "NEVŞEHİR",
        "DISTRICT": "DERİNKUYU",
        "STREET": "YAZIHÜYÜK-POLATKAN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "NEVŞEHİR",
        "DISTRICT": "DERİNKUYU",
        "STREET": "YENİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "NEVŞEHİR",
        "DISTRICT": "DERİNKUYU",
        "STREET": "ÇAKILLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "NEVŞEHİR",
        "DISTRICT": "DERİNKUYU",
        "STREET": "ÖZLÜCE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "NEVŞEHİR",
        "DISTRICT": "GÜLŞEHİR",
        "STREET": "ABUUŞAĞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "NEVŞEHİR",
        "DISTRICT": "GÜLŞEHİR",
        "STREET": "ALEMLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "NEVŞEHİR",
        "DISTRICT": "GÜLŞEHİR",
        "STREET": "ALKAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "NEVŞEHİR",
        "DISTRICT": "GÜLŞEHİR",
        "STREET": "AÇIKSARAY MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "NEVŞEHİR",
        "DISTRICT": "GÜLŞEHİR",
        "STREET": "BÖLÜKÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "NEVŞEHİR",
        "DISTRICT": "GÜLŞEHİR",
        "STREET": "CUMHURİYET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "NEVŞEHİR",
        "DISTRICT": "GÜLŞEHİR",
        "STREET": "CİVELEK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "NEVŞEHİR",
        "DISTRICT": "GÜLŞEHİR",
        "STREET": "DADAĞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "NEVŞEHİR",
        "DISTRICT": "GÜLŞEHİR",
        "STREET": "EMMİLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "NEVŞEHİR",
        "DISTRICT": "GÜLŞEHİR",
        "STREET": "EROL ÜNLÜSOY MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "NEVŞEHİR",
        "DISTRICT": "GÜLŞEHİR",
        "STREET": "ESKİYAYLACIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "NEVŞEHİR",
        "DISTRICT": "GÜLŞEHİR",
        "STREET": "EĞRİKUYU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "NEVŞEHİR",
        "DISTRICT": "GÜLŞEHİR",
        "STREET": "FAKIUŞAĞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "NEVŞEHİR",
        "DISTRICT": "GÜLŞEHİR",
        "STREET": "FATİH MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "NEVŞEHİR",
        "DISTRICT": "GÜLŞEHİR",
        "STREET": "GÖKÇETOPRAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "NEVŞEHİR",
        "DISTRICT": "GÜLŞEHİR",
        "STREET": "GÜLPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "NEVŞEHİR",
        "DISTRICT": "GÜLŞEHİR",
        "STREET": "GÜMÜŞKENT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "NEVŞEHİR",
        "DISTRICT": "GÜLŞEHİR",
        "STREET": "GÜMÜŞYAZI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "NEVŞEHİR",
        "DISTRICT": "GÜLŞEHİR",
        "STREET": "HACIHALİLLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "NEVŞEHİR",
        "DISTRICT": "GÜLŞEHİR",
        "STREET": "HACILAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "NEVŞEHİR",
        "DISTRICT": "GÜLŞEHİR",
        "STREET": "HAMZALI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "NEVŞEHİR",
        "DISTRICT": "GÜLŞEHİR",
        "STREET": "KARACAŞAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "NEVŞEHİR",
        "DISTRICT": "GÜLŞEHİR",
        "STREET": "KARAHÜYÜK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "NEVŞEHİR",
        "DISTRICT": "GÜLŞEHİR",
        "STREET": "KARAVEZİR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "NEVŞEHİR",
        "DISTRICT": "GÜLŞEHİR",
        "STREET": "KIZILKAYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "NEVŞEHİR",
        "DISTRICT": "GÜLŞEHİR",
        "STREET": "ORUÇREİS MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "NEVŞEHİR",
        "DISTRICT": "GÜLŞEHİR",
        "STREET": "OVAÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "NEVŞEHİR",
        "DISTRICT": "GÜLŞEHİR",
        "STREET": "OĞULKAYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "NEVŞEHİR",
        "DISTRICT": "GÜLŞEHİR",
        "STREET": "TERLEMEZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "NEVŞEHİR",
        "DISTRICT": "GÜLŞEHİR",
        "STREET": "TUZKÖYÜ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "NEVŞEHİR",
        "DISTRICT": "GÜLŞEHİR",
        "STREET": "YAKATARLA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "NEVŞEHİR",
        "DISTRICT": "GÜLŞEHİR",
        "STREET": "YALINTAŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "NEVŞEHİR",
        "DISTRICT": "GÜLŞEHİR",
        "STREET": "YAMALI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "NEVŞEHİR",
        "DISTRICT": "GÜLŞEHİR",
        "STREET": "YENİYAYLACIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "NEVŞEHİR",
        "DISTRICT": "GÜLŞEHİR",
        "STREET": "YEŞİLLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "NEVŞEHİR",
        "DISTRICT": "GÜLŞEHİR",
        "STREET": "YEŞİLYURT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "NEVŞEHİR",
        "DISTRICT": "GÜLŞEHİR",
        "STREET": "YEŞİLÖZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "NEVŞEHİR",
        "DISTRICT": "GÜLŞEHİR",
        "STREET": "YÜKSEKLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "NEVŞEHİR",
        "DISTRICT": "GÜLŞEHİR",
        "STREET": "ÇALIŞANLAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "NEVŞEHİR",
        "DISTRICT": "GÜLŞEHİR",
        "STREET": "ÇAYIR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "NEVŞEHİR",
        "DISTRICT": "GÜLŞEHİR",
        "STREET": "ŞAHİNLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "NEVŞEHİR",
        "DISTRICT": "HACIBEKTAŞ",
        "STREET": "AKÇATAŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "NEVŞEHİR",
        "DISTRICT": "HACIBEKTAŞ",
        "STREET": "ANAPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "NEVŞEHİR",
        "DISTRICT": "HACIBEKTAŞ",
        "STREET": "AVUÇ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "NEVŞEHİR",
        "DISTRICT": "HACIBEKTAŞ",
        "STREET": "AŞAĞIBARAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "NEVŞEHİR",
        "DISTRICT": "HACIBEKTAŞ",
        "STREET": "AŞIKLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "NEVŞEHİR",
        "DISTRICT": "HACIBEKTAŞ",
        "STREET": "BALA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "NEVŞEHİR",
        "DISTRICT": "HACIBEKTAŞ",
        "STREET": "BAŞKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "NEVŞEHİR",
        "DISTRICT": "HACIBEKTAŞ",
        "STREET": "BELBARAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "NEVŞEHİR",
        "DISTRICT": "HACIBEKTAŞ",
        "STREET": "BÜYÜK KAYI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "NEVŞEHİR",
        "DISTRICT": "HACIBEKTAŞ",
        "STREET": "BÜYÜKBURUNAĞIL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "NEVŞEHİR",
        "DISTRICT": "HACIBEKTAŞ",
        "STREET": "BÜYÜKKIŞLA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "NEVŞEHİR",
        "DISTRICT": "HACIBEKTAŞ",
        "STREET": "HASANLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "NEVŞEHİR",
        "DISTRICT": "HACIBEKTAŞ",
        "STREET": "HIDIRLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "NEVŞEHİR",
        "DISTRICT": "HACIBEKTAŞ",
        "STREET": "HIRKATEPESİDELİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "NEVŞEHİR",
        "DISTRICT": "HACIBEKTAŞ",
        "STREET": "KARABURNA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "NEVŞEHİR",
        "DISTRICT": "HACIBEKTAŞ",
        "STREET": "KARABURÇ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "NEVŞEHİR",
        "DISTRICT": "HACIBEKTAŞ",
        "STREET": "KARAOVA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "NEVŞEHİR",
        "DISTRICT": "HACIBEKTAŞ",
        "STREET": "KAYAALTI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "NEVŞEHİR",
        "DISTRICT": "HACIBEKTAŞ",
        "STREET": "KAYI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "NEVŞEHİR",
        "DISTRICT": "HACIBEKTAŞ",
        "STREET": "KIZILAĞIL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "NEVŞEHİR",
        "DISTRICT": "HACIBEKTAŞ",
        "STREET": "KÖŞEKTAŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "NEVŞEHİR",
        "DISTRICT": "HACIBEKTAŞ",
        "STREET": "KÜTÜKÇÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "NEVŞEHİR",
        "DISTRICT": "HACIBEKTAŞ",
        "STREET": "KİLLİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "NEVŞEHİR",
        "DISTRICT": "HACIBEKTAŞ",
        "STREET": "MİKAİL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "NEVŞEHİR",
        "DISTRICT": "HACIBEKTAŞ",
        "STREET": "SADIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "NEVŞEHİR",
        "DISTRICT": "HACIBEKTAŞ",
        "STREET": "SAVAT MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "NEVŞEHİR",
        "DISTRICT": "HACIBEKTAŞ",
        "STREET": "YENİCE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "NEVŞEHİR",
        "DISTRICT": "HACIBEKTAŞ",
        "STREET": "YENİYAPAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "NEVŞEHİR",
        "DISTRICT": "HACIBEKTAŞ",
        "STREET": "YURTYERİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "NEVŞEHİR",
        "DISTRICT": "HACIBEKTAŞ",
        "STREET": "ZİR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "NEVŞEHİR",
        "DISTRICT": "HACIBEKTAŞ",
        "STREET": "ÇİVRİL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "NEVŞEHİR",
        "DISTRICT": "HACIBEKTAŞ",
        "STREET": "ÇİĞDEM KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "NEVŞEHİR",
        "DISTRICT": "HACIBEKTAŞ",
        "STREET": "İLİCEK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "NEVŞEHİR",
        "DISTRICT": "KOZAKLI",
        "STREET": "ABDİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "NEVŞEHİR",
        "DISTRICT": "KOZAKLI",
        "STREET": "AKPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "NEVŞEHİR",
        "DISTRICT": "KOZAKLI",
        "STREET": "ALTUNSU MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "NEVŞEHİR",
        "DISTRICT": "KOZAKLI",
        "STREET": "AYLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "NEVŞEHİR",
        "DISTRICT": "KOZAKLI",
        "STREET": "BAHÇELİEVLER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "NEVŞEHİR",
        "DISTRICT": "KOZAKLI",
        "STREET": "BAĞLICA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "NEVŞEHİR",
        "DISTRICT": "KOZAKLI",
        "STREET": "BELEKLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "NEVŞEHİR",
        "DISTRICT": "KOZAKLI",
        "STREET": "BOĞAZİÇİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "NEVŞEHİR",
        "DISTRICT": "KOZAKLI",
        "STREET": "BURUNCUK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "NEVŞEHİR",
        "DISTRICT": "KOZAKLI",
        "STREET": "BÜYÜKYAĞLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "NEVŞEHİR",
        "DISTRICT": "KOZAKLI",
        "STREET": "CAĞŞAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "NEVŞEHİR",
        "DISTRICT": "KOZAKLI",
        "STREET": "DOYDUK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "NEVŞEHİR",
        "DISTRICT": "KOZAKLI",
        "STREET": "DÖRTYOL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "NEVŞEHİR",
        "DISTRICT": "KOZAKLI",
        "STREET": "EMEK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "NEVŞEHİR",
        "DISTRICT": "KOZAKLI",
        "STREET": "GERCE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "NEVŞEHİR",
        "DISTRICT": "KOZAKLI",
        "STREET": "HACIFAKILI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "NEVŞEHİR",
        "DISTRICT": "KOZAKLI",
        "STREET": "HAMAMORTA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "NEVŞEHİR",
        "DISTRICT": "KOZAKLI",
        "STREET": "HIZIRUŞAĞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "NEVŞEHİR",
        "DISTRICT": "KOZAKLI",
        "STREET": "HOCA AHMET YESEVİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "NEVŞEHİR",
        "DISTRICT": "KOZAKLI",
        "STREET": "KALECİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "NEVŞEHİR",
        "DISTRICT": "KOZAKLI",
        "STREET": "KANLICA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "NEVŞEHİR",
        "DISTRICT": "KOZAKLI",
        "STREET": "KAPAKLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "NEVŞEHİR",
        "DISTRICT": "KOZAKLI",
        "STREET": "KARAHASANLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "NEVŞEHİR",
        "DISTRICT": "KOZAKLI",
        "STREET": "KARASENİR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "NEVŞEHİR",
        "DISTRICT": "KOZAKLI",
        "STREET": "KAŞKIŞLA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "NEVŞEHİR",
        "DISTRICT": "KOZAKLI",
        "STREET": "KIZILKOYUNLU MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "NEVŞEHİR",
        "DISTRICT": "KOZAKLI",
        "STREET": "KURUAĞIL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "NEVŞEHİR",
        "DISTRICT": "KOZAKLI",
        "STREET": "KÜLLÜCE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "NEVŞEHİR",
        "DISTRICT": "KOZAKLI",
        "STREET": "KÜÇÜKYAĞLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "NEVŞEHİR",
        "DISTRICT": "KOZAKLI",
        "STREET": "MERDANALİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "NEVŞEHİR",
        "DISTRICT": "KOZAKLI",
        "STREET": "TAŞLIHÜYÜK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "NEVŞEHİR",
        "DISTRICT": "KOZAKLI",
        "STREET": "YABANLI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "NEVŞEHİR",
        "DISTRICT": "KOZAKLI",
        "STREET": "YASSICA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "NEVŞEHİR",
        "DISTRICT": "KOZAKLI",
        "STREET": "YENİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "NEVŞEHİR",
        "DISTRICT": "KOZAKLI",
        "STREET": "ÇAYİÇİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "NEVŞEHİR",
        "DISTRICT": "KOZAKLI",
        "STREET": "ÖZCE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "NEVŞEHİR",
        "DISTRICT": "KOZAKLI",
        "STREET": "İMRAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "NEVŞEHİR",
        "DISTRICT": "MERKEZ",
        "STREET": "15 TEMMUZ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "NEVŞEHİR",
        "DISTRICT": "MERKEZ",
        "STREET": "20 TEMMUZ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "NEVŞEHİR",
        "DISTRICT": "MERKEZ",
        "STREET": "2000 EVLER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "NEVŞEHİR",
        "DISTRICT": "MERKEZ",
        "STREET": "350 EVLER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "NEVŞEHİR",
        "DISTRICT": "MERKEZ",
        "STREET": "ALACAŞAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "NEVŞEHİR",
        "DISTRICT": "MERKEZ",
        "STREET": "BAHÇELİEVLER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "NEVŞEHİR",
        "DISTRICT": "MERKEZ",
        "STREET": "BALCIN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "NEVŞEHİR",
        "DISTRICT": "MERKEZ",
        "STREET": "BASANSARNIÇ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "NEVŞEHİR",
        "DISTRICT": "MERKEZ",
        "STREET": "BEKDİK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "NEVŞEHİR",
        "DISTRICT": "MERKEZ",
        "STREET": "BOĞAZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "NEVŞEHİR",
        "DISTRICT": "MERKEZ",
        "STREET": "CAMİCEDİT MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "NEVŞEHİR",
        "DISTRICT": "MERKEZ",
        "STREET": "CEVHER DUDAYEV MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "NEVŞEHİR",
        "DISTRICT": "MERKEZ",
        "STREET": "EMEK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "NEVŞEHİR",
        "DISTRICT": "MERKEZ",
        "STREET": "ESENTEPE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "NEVŞEHİR",
        "DISTRICT": "MERKEZ",
        "STREET": "FATİH SULTAN MEHMET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "NEVŞEHİR",
        "DISTRICT": "MERKEZ",
        "STREET": "GÖRE-AFETEVLERİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "NEVŞEHİR",
        "DISTRICT": "MERKEZ",
        "STREET": "GÖRE-KASAPOĞLU MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "NEVŞEHİR",
        "DISTRICT": "MERKEZ",
        "STREET": "GÖREME-AYDINLI-ORTA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "NEVŞEHİR",
        "DISTRICT": "MERKEZ",
        "STREET": "GÖREME-İSALİ-GAFERLİ-AVCILAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "NEVŞEHİR",
        "DISTRICT": "MERKEZ",
        "STREET": "GÜVERCİNLİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "NEVŞEHİR",
        "DISTRICT": "MERKEZ",
        "STREET": "GÜZELYURT MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "NEVŞEHİR",
        "DISTRICT": "MERKEZ",
        "STREET": "HACIRÜŞTÜ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "NEVŞEHİR",
        "DISTRICT": "MERKEZ",
        "STREET": "HERİKLİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "NEVŞEHİR",
        "DISTRICT": "MERKEZ",
        "STREET": "KAPUCUBAŞI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "NEVŞEHİR",
        "DISTRICT": "MERKEZ",
        "STREET": "KARASOKU MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "NEVŞEHİR",
        "DISTRICT": "MERKEZ",
        "STREET": "KAVAK-AŞAĞI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "NEVŞEHİR",
        "DISTRICT": "MERKEZ",
        "STREET": "KAVAK-YUKARI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "NEVŞEHİR",
        "DISTRICT": "MERKEZ",
        "STREET": "KAYMAKLI-BEKİRAĞA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "NEVŞEHİR",
        "DISTRICT": "MERKEZ",
        "STREET": "KAYMAKLI-CAMİKEBİR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "NEVŞEHİR",
        "DISTRICT": "MERKEZ",
        "STREET": "KAYMAKLI-HATİPOĞLU MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "NEVŞEHİR",
        "DISTRICT": "MERKEZ",
        "STREET": "KIRATLIOĞLU MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "NEVŞEHİR",
        "DISTRICT": "MERKEZ",
        "STREET": "M.AKİF ERSOY MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "NEVŞEHİR",
        "DISTRICT": "MERKEZ",
        "STREET": "NAR-AŞAĞI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "NEVŞEHİR",
        "DISTRICT": "MERKEZ",
        "STREET": "NAR-BAŞ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "NEVŞEHİR",
        "DISTRICT": "MERKEZ",
        "STREET": "NAR-ORTA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "NEVŞEHİR",
        "DISTRICT": "MERKEZ",
        "STREET": "NAR-YENİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "NEVŞEHİR",
        "DISTRICT": "MERKEZ",
        "STREET": "RAŞİTBEY MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "NEVŞEHİR",
        "DISTRICT": "MERKEZ",
        "STREET": "SULUSARAY-CUMHURİYET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "NEVŞEHİR",
        "DISTRICT": "MERKEZ",
        "STREET": "SULUSARAY-SULTAN ALADDİN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "NEVŞEHİR",
        "DISTRICT": "MERKEZ",
        "STREET": "SULUSARAY-YENİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "NEVŞEHİR",
        "DISTRICT": "MERKEZ",
        "STREET": "SÜMER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "NEVŞEHİR",
        "DISTRICT": "MERKEZ",
        "STREET": "UÇHİSAR-AŞAĞI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "NEVŞEHİR",
        "DISTRICT": "MERKEZ",
        "STREET": "UÇHİSAR-TEKELLİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "NEVŞEHİR",
        "DISTRICT": "MERKEZ",
        "STREET": "UÇHİSAR-YUKARI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "NEVŞEHİR",
        "DISTRICT": "MERKEZ",
        "STREET": "YENİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "NEVŞEHİR",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇARDAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "NEVŞEHİR",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇAT-15 TEMMUZ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "NEVŞEHİR",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇAT-CUMHURİYET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "NEVŞEHİR",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇAT-İSTİKLAL MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "NEVŞEHİR",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇİFTLİKKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "NEVŞEHİR",
        "DISTRICT": "MERKEZ",
        "STREET": "ÖZYAYLA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "NEVŞEHİR",
        "DISTRICT": "MERKEZ",
        "STREET": "İBRAHİMPAŞA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "NEVŞEHİR",
        "DISTRICT": "MERKEZ",
        "STREET": "İCİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "NEVŞEHİR",
        "DISTRICT": "MERKEZ",
        "STREET": "NEVŞEHİR E TİPİ KAPALI CEZAEVİ",
        "TYPE": "0"
      },
      {
        "CITY": "NEVŞEHİR",
        "DISTRICT": "ÜRGÜP",
        "STREET": "15 TEMMUZ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "NEVŞEHİR",
        "DISTRICT": "ÜRGÜP",
        "STREET": "AKKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "NEVŞEHİR",
        "DISTRICT": "ÜRGÜP",
        "STREET": "AKSALUR AĞCAŞAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "NEVŞEHİR",
        "DISTRICT": "ÜRGÜP",
        "STREET": "AKSALUR SALUR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "NEVŞEHİR",
        "DISTRICT": "ÜRGÜP",
        "STREET": "AKÇAÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "NEVŞEHİR",
        "DISTRICT": "ÜRGÜP",
        "STREET": "AYVALI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "NEVŞEHİR",
        "DISTRICT": "ÜRGÜP",
        "STREET": "BAHÇELİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "NEVŞEHİR",
        "DISTRICT": "ÜRGÜP",
        "STREET": "BAHÇELİEVLER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "NEVŞEHİR",
        "DISTRICT": "ÜRGÜP",
        "STREET": "BAŞDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "NEVŞEHİR",
        "DISTRICT": "ÜRGÜP",
        "STREET": "BOYALI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "NEVŞEHİR",
        "DISTRICT": "ÜRGÜP",
        "STREET": "CEMİL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "NEVŞEHİR",
        "DISTRICT": "ÜRGÜP",
        "STREET": "CUMHURİYET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "NEVŞEHİR",
        "DISTRICT": "ÜRGÜP",
        "STREET": "DEMİRTAŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "NEVŞEHİR",
        "DISTRICT": "ÜRGÜP",
        "STREET": "DUAYERİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "NEVŞEHİR",
        "DISTRICT": "ÜRGÜP",
        "STREET": "EVKA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "NEVŞEHİR",
        "DISTRICT": "ÜRGÜP",
        "STREET": "FATİH MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "NEVŞEHİR",
        "DISTRICT": "ÜRGÜP",
        "STREET": "KARACAÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "NEVŞEHİR",
        "DISTRICT": "ÜRGÜP",
        "STREET": "KARAKAYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "NEVŞEHİR",
        "DISTRICT": "ÜRGÜP",
        "STREET": "KARAİN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "NEVŞEHİR",
        "DISTRICT": "ÜRGÜP",
        "STREET": "KARLIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "NEVŞEHİR",
        "DISTRICT": "ÜRGÜP",
        "STREET": "KAVAKLIÖNÜ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "NEVŞEHİR",
        "DISTRICT": "ÜRGÜP",
        "STREET": "MAZI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "NEVŞEHİR",
        "DISTRICT": "ÜRGÜP",
        "STREET": "MUSTAFAPAŞA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "NEVŞEHİR",
        "DISTRICT": "ÜRGÜP",
        "STREET": "ORTAHİSAR-BAHÇELİEVLER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "NEVŞEHİR",
        "DISTRICT": "ÜRGÜP",
        "STREET": "ORTAHİSAR-ESENTEPE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "NEVŞEHİR",
        "DISTRICT": "ÜRGÜP",
        "STREET": "ORTAHİSAR-ESKİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "NEVŞEHİR",
        "DISTRICT": "ÜRGÜP",
        "STREET": "ORTAHİSAR-YENİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "NEVŞEHİR",
        "DISTRICT": "ÜRGÜP",
        "STREET": "SARIHIDIR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "NEVŞEHİR",
        "DISTRICT": "ÜRGÜP",
        "STREET": "SOFULAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "NEVŞEHİR",
        "DISTRICT": "ÜRGÜP",
        "STREET": "TAŞKINPAŞA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "NEVŞEHİR",
        "DISTRICT": "ÜRGÜP",
        "STREET": "TEMENNİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "NEVŞEHİR",
        "DISTRICT": "ÜRGÜP",
        "STREET": "ULAŞLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "NEVŞEHİR",
        "DISTRICT": "ÜRGÜP",
        "STREET": "YEŞİLÖZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "NEVŞEHİR",
        "DISTRICT": "ÜRGÜP",
        "STREET": "ÇÖKEK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "NEVŞEHİR",
        "DISTRICT": "ÜRGÜP",
        "STREET": "İBRAHİMPAŞA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "NEVŞEHİR",
        "DISTRICT": "ÜRGÜP",
        "STREET": "İLTAŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "NEVŞEHİR",
        "DISTRICT": "ÜRGÜP",
        "STREET": "ŞAHİNEFENDİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "NİĞDE",
        "DISTRICT": "ALTUNHİSAR",
        "STREET": "AKÇAÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "NİĞDE",
        "DISTRICT": "ALTUNHİSAR",
        "STREET": "AŞAĞI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "NİĞDE",
        "DISTRICT": "ALTUNHİSAR",
        "STREET": "KARAKAPI-CUMHURİYET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "NİĞDE",
        "DISTRICT": "ALTUNHİSAR",
        "STREET": "KARAKAPI-YAKA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "NİĞDE",
        "DISTRICT": "ALTUNHİSAR",
        "STREET": "KARAKAPI-YENİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "NİĞDE",
        "DISTRICT": "ALTUNHİSAR",
        "STREET": "KEÇİKALESİ-CUMHURİYET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "NİĞDE",
        "DISTRICT": "ALTUNHİSAR",
        "STREET": "KEÇİKALESİ-ERENLER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "NİĞDE",
        "DISTRICT": "ALTUNHİSAR",
        "STREET": "KEÇİKALESİ-SELÇUK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "NİĞDE",
        "DISTRICT": "ALTUNHİSAR",
        "STREET": "ORTA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "NİĞDE",
        "DISTRICT": "ALTUNHİSAR",
        "STREET": "SAĞLIK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "NİĞDE",
        "DISTRICT": "ALTUNHİSAR",
        "STREET": "ULUKIŞLA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "NİĞDE",
        "DISTRICT": "ALTUNHİSAR",
        "STREET": "ULUÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "NİĞDE",
        "DISTRICT": "ALTUNHİSAR",
        "STREET": "YAKACIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "NİĞDE",
        "DISTRICT": "ALTUNHİSAR",
        "STREET": "YEŞİLYURT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "NİĞDE",
        "DISTRICT": "ALTUNHİSAR",
        "STREET": "YUKARI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "NİĞDE",
        "DISTRICT": "ALTUNHİSAR",
        "STREET": "ÇÖMLEKÇİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "NİĞDE",
        "DISTRICT": "ALTUNHİSAR",
        "STREET": "İSTİKLAL MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "NİĞDE",
        "DISTRICT": "BOR",
        "STREET": "ACIGÖL MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "NİĞDE",
        "DISTRICT": "BOR",
        "STREET": "AHMET KUDDUSİ  MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "NİĞDE",
        "DISTRICT": "BOR",
        "STREET": "ARMUTLU MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "NİĞDE",
        "DISTRICT": "BOR",
        "STREET": "BADAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "NİĞDE",
        "DISTRICT": "BOR",
        "STREET": "BAHÇELİ-CUMHURİYET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "NİĞDE",
        "DISTRICT": "BOR",
        "STREET": "BAHÇELİ-SARAY MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "NİĞDE",
        "DISTRICT": "BOR",
        "STREET": "BAHÇELİ-ZAFER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "NİĞDE",
        "DISTRICT": "BOR",
        "STREET": "BAHÇELİ-ŞEKER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "NİĞDE",
        "DISTRICT": "BOR",
        "STREET": "BALCI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "NİĞDE",
        "DISTRICT": "BOR",
        "STREET": "BAYAT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "NİĞDE",
        "DISTRICT": "BOR",
        "STREET": "BAĞDÜZ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "NİĞDE",
        "DISTRICT": "BOR",
        "STREET": "BAŞPINAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "NİĞDE",
        "DISTRICT": "BOR",
        "STREET": "BEREKE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "NİĞDE",
        "DISTRICT": "BOR",
        "STREET": "CIĞIZOĞLU OSMAN EFENDİ  MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "NİĞDE",
        "DISTRICT": "BOR",
        "STREET": "CUMHURİYET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "NİĞDE",
        "DISTRICT": "BOR",
        "STREET": "DİNK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "NİĞDE",
        "DISTRICT": "BOR",
        "STREET": "EMEN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "NİĞDE",
        "DISTRICT": "BOR",
        "STREET": "FATİH MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "NİĞDE",
        "DISTRICT": "BOR",
        "STREET": "GÖKBEZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "NİĞDE",
        "DISTRICT": "BOR",
        "STREET": "HALAÇ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "NİĞDE",
        "DISTRICT": "BOR",
        "STREET": "HARIM MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "NİĞDE",
        "DISTRICT": "BOR",
        "STREET": "HAVUZLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "NİĞDE",
        "DISTRICT": "BOR",
        "STREET": "KALE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "NİĞDE",
        "DISTRICT": "BOR",
        "STREET": "KARACA UĞURLU MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "NİĞDE",
        "DISTRICT": "BOR",
        "STREET": "KARACAÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "NİĞDE",
        "DISTRICT": "BOR",
        "STREET": "KARAMAHMUTLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "NİĞDE",
        "DISTRICT": "BOR",
        "STREET": "KARANLIKDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "NİĞDE",
        "DISTRICT": "BOR",
        "STREET": "KAVUKLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "NİĞDE",
        "DISTRICT": "BOR",
        "STREET": "KAYI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "NİĞDE",
        "DISTRICT": "BOR",
        "STREET": "KAYNARCA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "NİĞDE",
        "DISTRICT": "BOR",
        "STREET": "KEMERHİSAR-CAMİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "NİĞDE",
        "DISTRICT": "BOR",
        "STREET": "KEMERHİSAR-CUMHURİYET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "NİĞDE",
        "DISTRICT": "BOR",
        "STREET": "KEMERHİSAR-HAN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "NİĞDE",
        "DISTRICT": "BOR",
        "STREET": "KEMERHİSAR-KEMER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "NİĞDE",
        "DISTRICT": "BOR",
        "STREET": "KEMERHİSAR-TEPE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "NİĞDE",
        "DISTRICT": "BOR",
        "STREET": "KEMERHİSAR-ÇAYIR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "NİĞDE",
        "DISTRICT": "BOR",
        "STREET": "KILAVUZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "NİĞDE",
        "DISTRICT": "BOR",
        "STREET": "KIZILCA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "NİĞDE",
        "DISTRICT": "BOR",
        "STREET": "KIZILKAPI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "NİĞDE",
        "DISTRICT": "BOR",
        "STREET": "KÜRKÇÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "NİĞDE",
        "DISTRICT": "BOR",
        "STREET": "MEHMETÇİK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "NİĞDE",
        "DISTRICT": "BOR",
        "STREET": "OBRUK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "NİĞDE",
        "DISTRICT": "BOR",
        "STREET": "OKÇU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "NİĞDE",
        "DISTRICT": "BOR",
        "STREET": "ORTA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "NİĞDE",
        "DISTRICT": "BOR",
        "STREET": "POSTALLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "NİĞDE",
        "DISTRICT": "BOR",
        "STREET": "SALTUK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "NİĞDE",
        "DISTRICT": "BOR",
        "STREET": "SESLİKAYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "NİĞDE",
        "DISTRICT": "BOR",
        "STREET": "SIRA SÖĞÜTLER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "NİĞDE",
        "DISTRICT": "BOR",
        "STREET": "SOKUBAŞI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "NİĞDE",
        "DISTRICT": "BOR",
        "STREET": "TEPEKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "NİĞDE",
        "DISTRICT": "BOR",
        "STREET": "TOPLU KONUT  MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "NİĞDE",
        "DISTRICT": "BOR",
        "STREET": "YENİGÖÇMEN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "NİĞDE",
        "DISTRICT": "BOR",
        "STREET": "ÇUKURKUYU-BEKTİK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "NİĞDE",
        "DISTRICT": "BOR",
        "STREET": "ÇUKURKUYU-TÜRKMEN YURDU MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "NİĞDE",
        "DISTRICT": "BOR",
        "STREET": "ŞEKER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "NİĞDE",
        "DISTRICT": "MERKEZ",
        "STREET": "AHİPAŞA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "NİĞDE",
        "DISTRICT": "MERKEZ",
        "STREET": "AKTAŞ-CUMHURİYET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "NİĞDE",
        "DISTRICT": "MERKEZ",
        "STREET": "AKTAŞ-DEVLET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "NİĞDE",
        "DISTRICT": "MERKEZ",
        "STREET": "AKTAŞ-YENİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "NİĞDE",
        "DISTRICT": "MERKEZ",
        "STREET": "ALAADDİN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "NİĞDE",
        "DISTRICT": "MERKEZ",
        "STREET": "ALAY-CUMHURİYET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "NİĞDE",
        "DISTRICT": "MERKEZ",
        "STREET": "ALAY-HÜRRİYET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "NİĞDE",
        "DISTRICT": "MERKEZ",
        "STREET": "ALAY-MİLLİYET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "NİĞDE",
        "DISTRICT": "MERKEZ",
        "STREET": "AĞCAŞAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "NİĞDE",
        "DISTRICT": "MERKEZ",
        "STREET": "AŞAĞI KAYABAŞI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "NİĞDE",
        "DISTRICT": "MERKEZ",
        "STREET": "AŞLAMA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "NİĞDE",
        "DISTRICT": "MERKEZ",
        "STREET": "BALHASAN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "NİĞDE",
        "DISTRICT": "MERKEZ",
        "STREET": "BALLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "NİĞDE",
        "DISTRICT": "MERKEZ",
        "STREET": "BAĞLAMA-CUMHURİYET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "NİĞDE",
        "DISTRICT": "MERKEZ",
        "STREET": "BAĞLAMA-HÜRRİYET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "NİĞDE",
        "DISTRICT": "MERKEZ",
        "STREET": "BURHAN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "NİĞDE",
        "DISTRICT": "MERKEZ",
        "STREET": "DERE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "NİĞDE",
        "DISTRICT": "MERKEZ",
        "STREET": "DEĞİRMENLİ-AŞAĞI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "NİĞDE",
        "DISTRICT": "MERKEZ",
        "STREET": "DEĞİRMENLİ-ORTA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "NİĞDE",
        "DISTRICT": "MERKEZ",
        "STREET": "DEĞİRMENLİ-YUKARI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "NİĞDE",
        "DISTRICT": "MERKEZ",
        "STREET": "DÜNDARLI-CUMHURİYET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "NİĞDE",
        "DISTRICT": "MERKEZ",
        "STREET": "DÜNDARLI-FATİH MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "NİĞDE",
        "DISTRICT": "MERKEZ",
        "STREET": "DÜNDARLI-YENİCE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "NİĞDE",
        "DISTRICT": "MERKEZ",
        "STREET": "DÜNDARLI-ZAFER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "NİĞDE",
        "DISTRICT": "MERKEZ",
        "STREET": "DİKİLİTAŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "NİĞDE",
        "DISTRICT": "MERKEZ",
        "STREET": "E TİPİ KAPALI CEZA EVİ",
        "TYPE": "0"
      },
      {
        "CITY": "NİĞDE",
        "DISTRICT": "MERKEZ",
        "STREET": "EDİKLİ-CUMHURİYET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "NİĞDE",
        "DISTRICT": "MERKEZ",
        "STREET": "EDİKLİ-FEVZİPAŞA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "NİĞDE",
        "DISTRICT": "MERKEZ",
        "STREET": "EDİKLİ-HÜRRİYET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "NİĞDE",
        "DISTRICT": "MERKEZ",
        "STREET": "EDİKLİ-YENİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "NİĞDE",
        "DISTRICT": "MERKEZ",
        "STREET": "EFENDİBEY MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "NİĞDE",
        "DISTRICT": "MERKEZ",
        "STREET": "ELMALI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "NİĞDE",
        "DISTRICT": "MERKEZ",
        "STREET": "ESENBEY MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "NİĞDE",
        "DISTRICT": "MERKEZ",
        "STREET": "ESKİSARAY MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "NİĞDE",
        "DISTRICT": "MERKEZ",
        "STREET": "FERTEK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "NİĞDE",
        "DISTRICT": "MERKEZ",
        "STREET": "FESLEĞEN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "NİĞDE",
        "DISTRICT": "MERKEZ",
        "STREET": "GÖSTERLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "NİĞDE",
        "DISTRICT": "MERKEZ",
        "STREET": "GÜLLÜCE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "NİĞDE",
        "DISTRICT": "MERKEZ",
        "STREET": "GÜMÜŞLER-ESKİ GÜMÜŞ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "NİĞDE",
        "DISTRICT": "MERKEZ",
        "STREET": "GÜMÜŞLER-YENİ GÜMÜŞ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "NİĞDE",
        "DISTRICT": "MERKEZ",
        "STREET": "HACIABDULLAH-YENİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "NİĞDE",
        "DISTRICT": "MERKEZ",
        "STREET": "HACIBEYLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "NİĞDE",
        "DISTRICT": "MERKEZ",
        "STREET": "HAMAMLI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "NİĞDE",
        "DISTRICT": "MERKEZ",
        "STREET": "HANÇERLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "NİĞDE",
        "DISTRICT": "MERKEZ",
        "STREET": "HASAKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "NİĞDE",
        "DISTRICT": "MERKEZ",
        "STREET": "HİMMETLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "NİĞDE",
        "DISTRICT": "MERKEZ",
        "STREET": "KALE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "NİĞDE",
        "DISTRICT": "MERKEZ",
        "STREET": "KARAATLI-DUMLUPINAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "NİĞDE",
        "DISTRICT": "MERKEZ",
        "STREET": "KARAATLI-FATİH MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "NİĞDE",
        "DISTRICT": "MERKEZ",
        "STREET": "KARAATLI-ZAFER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "NİĞDE",
        "DISTRICT": "MERKEZ",
        "STREET": "KAYIRLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "NİĞDE",
        "DISTRICT": "MERKEZ",
        "STREET": "KIRKPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "NİĞDE",
        "DISTRICT": "MERKEZ",
        "STREET": "KIZILÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "NİĞDE",
        "DISTRICT": "MERKEZ",
        "STREET": "KONAKLI-CUMHURİYET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "NİĞDE",
        "DISTRICT": "MERKEZ",
        "STREET": "KONAKLI-HÜRRİYET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "NİĞDE",
        "DISTRICT": "MERKEZ",
        "STREET": "KONAKLI-HÜYÜK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "NİĞDE",
        "DISTRICT": "MERKEZ",
        "STREET": "KOYUNLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "NİĞDE",
        "DISTRICT": "MERKEZ",
        "STREET": "KUMLUCA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "NİĞDE",
        "DISTRICT": "MERKEZ",
        "STREET": "KÖMÜRCÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "NİĞDE",
        "DISTRICT": "MERKEZ",
        "STREET": "KÜÇÜKKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "NİĞDE",
        "DISTRICT": "MERKEZ",
        "STREET": "KİLEDERE-CUMHURİYET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "NİĞDE",
        "DISTRICT": "MERKEZ",
        "STREET": "KİLEDERE-HÜRRİYET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "NİĞDE",
        "DISTRICT": "MERKEZ",
        "STREET": "NAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "NİĞDE",
        "DISTRICT": "MERKEZ",
        "STREET": "NARKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "NİĞDE",
        "DISTRICT": "MERKEZ",
        "STREET": "ORHANLI-FATİH MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "NİĞDE",
        "DISTRICT": "MERKEZ",
        "STREET": "ORHANLI-YAVUZ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "NİĞDE",
        "DISTRICT": "MERKEZ",
        "STREET": "OVACIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "NİĞDE",
        "DISTRICT": "MERKEZ",
        "STREET": "PINARCIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "NİĞDE",
        "DISTRICT": "MERKEZ",
        "STREET": "SARUHAN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "NİĞDE",
        "DISTRICT": "MERKEZ",
        "STREET": "SAZLICA-AŞAĞI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "NİĞDE",
        "DISTRICT": "MERKEZ",
        "STREET": "SAZLICA-YENİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "NİĞDE",
        "DISTRICT": "MERKEZ",
        "STREET": "SAZLICA-YUKARI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "NİĞDE",
        "DISTRICT": "MERKEZ",
        "STREET": "SELÇUK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "NİĞDE",
        "DISTRICT": "MERKEZ",
        "STREET": "SIRALİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "NİĞDE",
        "DISTRICT": "MERKEZ",
        "STREET": "SONGUR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "NİĞDE",
        "DISTRICT": "MERKEZ",
        "STREET": "TAŞLICA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "NİĞDE",
        "DISTRICT": "MERKEZ",
        "STREET": "TEPEKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "NİĞDE",
        "DISTRICT": "MERKEZ",
        "STREET": "TIRHAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "NİĞDE",
        "DISTRICT": "MERKEZ",
        "STREET": "ULUAĞAÇ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "NİĞDE",
        "DISTRICT": "MERKEZ",
        "STREET": "YARHİSAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "NİĞDE",
        "DISTRICT": "MERKEZ",
        "STREET": "YAYLAYOLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "NİĞDE",
        "DISTRICT": "MERKEZ",
        "STREET": "YENİCE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "NİĞDE",
        "DISTRICT": "MERKEZ",
        "STREET": "YEŞİLBURÇ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "NİĞDE",
        "DISTRICT": "MERKEZ",
        "STREET": "YEŞİLGÖLCÜK-AŞAĞI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "NİĞDE",
        "DISTRICT": "MERKEZ",
        "STREET": "YEŞİLGÖLCÜK-CUMHURİYET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "NİĞDE",
        "DISTRICT": "MERKEZ",
        "STREET": "YEŞİLGÖLCÜK-HÜRRİYET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "NİĞDE",
        "DISTRICT": "MERKEZ",
        "STREET": "YEŞİLGÖLCÜK-YENİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "NİĞDE",
        "DISTRICT": "MERKEZ",
        "STREET": "YEŞİLGÖLCÜK-YUKARI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "NİĞDE",
        "DISTRICT": "MERKEZ",
        "STREET": "YEŞİLOVA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "NİĞDE",
        "DISTRICT": "MERKEZ",
        "STREET": "YILDIZTEPE-BAHÇELİEVLER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "NİĞDE",
        "DISTRICT": "MERKEZ",
        "STREET": "YILDIZTEPE-YENİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "NİĞDE",
        "DISTRICT": "MERKEZ",
        "STREET": "YUKARI KAYABAŞI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "NİĞDE",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇARIKLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "NİĞDE",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇAVDARLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "NİĞDE",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇAYIR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "NİĞDE",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇAYIRLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "NİĞDE",
        "DISTRICT": "MERKEZ",
        "STREET": "ÖZYURT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "NİĞDE",
        "DISTRICT": "MERKEZ",
        "STREET": "İLHANLI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "NİĞDE",
        "DISTRICT": "MERKEZ",
        "STREET": "İNÖNÜ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "NİĞDE",
        "DISTRICT": "MERKEZ",
        "STREET": "İÇMELİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "NİĞDE",
        "DISTRICT": "MERKEZ",
        "STREET": "ŞAHSÜLEYMAN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "NİĞDE",
        "DISTRICT": "MERKEZ",
        "STREET": "ŞAHİNALİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "NİĞDE",
        "DISTRICT": "MERKEZ",
        "STREET": "ŞEHİTLER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "NİĞDE",
        "DISTRICT": "ULUKIŞLA",
        "STREET": "ALPAĞUT MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "NİĞDE",
        "DISTRICT": "ULUKIŞLA",
        "STREET": "ALTAY KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "NİĞDE",
        "DISTRICT": "ULUKIŞLA",
        "STREET": "ALİHOCA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "NİĞDE",
        "DISTRICT": "ULUKIŞLA",
        "STREET": "ARDIÇLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "NİĞDE",
        "DISTRICT": "ULUKIŞLA",
        "STREET": "BALLIK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "NİĞDE",
        "DISTRICT": "ULUKIŞLA",
        "STREET": "BAYAĞIL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "NİĞDE",
        "DISTRICT": "ULUKIŞLA",
        "STREET": "BAŞMAKÇI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "NİĞDE",
        "DISTRICT": "ULUKIŞLA",
        "STREET": "DARBOĞAZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "NİĞDE",
        "DISTRICT": "ULUKIŞLA",
        "STREET": "DOĞU MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "NİĞDE",
        "DISTRICT": "ULUKIŞLA",
        "STREET": "ELMALI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "NİĞDE",
        "DISTRICT": "ULUKIŞLA",
        "STREET": "EMİNLİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "NİĞDE",
        "DISTRICT": "ULUKIŞLA",
        "STREET": "EMİRLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "NİĞDE",
        "DISTRICT": "ULUKIŞLA",
        "STREET": "GEDELLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "NİĞDE",
        "DISTRICT": "ULUKIŞLA",
        "STREET": "GÜMÜŞKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "NİĞDE",
        "DISTRICT": "ULUKIŞLA",
        "STREET": "GÜNEY KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "NİĞDE",
        "DISTRICT": "ULUKIŞLA",
        "STREET": "HACIBEKİRLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "NİĞDE",
        "DISTRICT": "ULUKIŞLA",
        "STREET": "HANDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "NİĞDE",
        "DISTRICT": "ULUKIŞLA",
        "STREET": "HASANGAZİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "NİĞDE",
        "DISTRICT": "ULUKIŞLA",
        "STREET": "HOROZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "NİĞDE",
        "DISTRICT": "ULUKIŞLA",
        "STREET": "HÜSNİYE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "NİĞDE",
        "DISTRICT": "ULUKIŞLA",
        "STREET": "KADILAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "NİĞDE",
        "DISTRICT": "ULUKIŞLA",
        "STREET": "KARACAÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "NİĞDE",
        "DISTRICT": "ULUKIŞLA",
        "STREET": "KEMALPAŞA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "NİĞDE",
        "DISTRICT": "ULUKIŞLA",
        "STREET": "KILAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "NİĞDE",
        "DISTRICT": "ULUKIŞLA",
        "STREET": "KOLSUZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "NİĞDE",
        "DISTRICT": "ULUKIŞLA",
        "STREET": "KOZLUCA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "NİĞDE",
        "DISTRICT": "ULUKIŞLA",
        "STREET": "KOÇAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "NİĞDE",
        "DISTRICT": "ULUKIŞLA",
        "STREET": "KÖŞKÖNÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "NİĞDE",
        "DISTRICT": "ULUKIŞLA",
        "STREET": "MADENKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "NİĞDE",
        "DISTRICT": "ULUKIŞLA",
        "STREET": "MEHMETPAŞA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "NİĞDE",
        "DISTRICT": "ULUKIŞLA",
        "STREET": "MISIRLI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "NİĞDE",
        "DISTRICT": "ULUKIŞLA",
        "STREET": "OVACIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "NİĞDE",
        "DISTRICT": "ULUKIŞLA",
        "STREET": "PORSUK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "NİĞDE",
        "DISTRICT": "ULUKIŞLA",
        "STREET": "TABAKLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "NİĞDE",
        "DISTRICT": "ULUKIŞLA",
        "STREET": "TEKNEÇUKUR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "NİĞDE",
        "DISTRICT": "ULUKIŞLA",
        "STREET": "TEPEKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "NİĞDE",
        "DISTRICT": "ULUKIŞLA",
        "STREET": "TORAMAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "NİĞDE",
        "DISTRICT": "ULUKIŞLA",
        "STREET": "YENİYILDIZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "NİĞDE",
        "DISTRICT": "ULUKIŞLA",
        "STREET": "ÇANAKÇI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "NİĞDE",
        "DISTRICT": "ULUKIŞLA",
        "STREET": "ÇÖVEK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "NİĞDE",
        "DISTRICT": "ULUKIŞLA",
        "STREET": "ÇİFTEHAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "NİĞDE",
        "DISTRICT": "ULUKIŞLA",
        "STREET": "ÇİFTEKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "NİĞDE",
        "DISTRICT": "ULUKIŞLA",
        "STREET": "ÜNLÜYAKA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "NİĞDE",
        "DISTRICT": "ULUKIŞLA",
        "STREET": "İLHANKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "NİĞDE",
        "DISTRICT": "ULUKIŞLA",
        "STREET": "İMRAHOR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "NİĞDE",
        "DISTRICT": "ULUKIŞLA",
        "STREET": "ŞEYHÖMERLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "NİĞDE",
        "DISTRICT": "ÇAMARDI",
        "STREET": "BADEMDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "NİĞDE",
        "DISTRICT": "ÇAMARDI",
        "STREET": "BEKÇİLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "NİĞDE",
        "DISTRICT": "ÇAMARDI",
        "STREET": "BEREKETLİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "NİĞDE",
        "DISTRICT": "ÇAMARDI",
        "STREET": "BEYAZKIŞLAKÇI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "NİĞDE",
        "DISTRICT": "ÇAMARDI",
        "STREET": "BURÇ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "NİĞDE",
        "DISTRICT": "ÇAMARDI",
        "STREET": "CELALLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "NİĞDE",
        "DISTRICT": "ÇAMARDI",
        "STREET": "DEMİRKAZIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "NİĞDE",
        "DISTRICT": "ÇAMARDI",
        "STREET": "ELEKGÖLÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "NİĞDE",
        "DISTRICT": "ÇAMARDI",
        "STREET": "EYNELLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "NİĞDE",
        "DISTRICT": "ÇAMARDI",
        "STREET": "KAVAKLIGÖL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "NİĞDE",
        "DISTRICT": "ÇAMARDI",
        "STREET": "KAVLAKTEPE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "NİĞDE",
        "DISTRICT": "ÇAMARDI",
        "STREET": "KOCAPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "NİĞDE",
        "DISTRICT": "ÇAMARDI",
        "STREET": "MADEN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "NİĞDE",
        "DISTRICT": "ÇAMARDI",
        "STREET": "MAHMATLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "NİĞDE",
        "DISTRICT": "ÇAMARDI",
        "STREET": "ORHANİYE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "NİĞDE",
        "DISTRICT": "ÇAMARDI",
        "STREET": "PINARBAŞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "NİĞDE",
        "DISTRICT": "ÇAMARDI",
        "STREET": "SULUCAOVA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "NİĞDE",
        "DISTRICT": "ÇAMARDI",
        "STREET": "YELATAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "NİĞDE",
        "DISTRICT": "ÇAMARDI",
        "STREET": "YENİ ÇARŞI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "NİĞDE",
        "DISTRICT": "ÇAMARDI",
        "STREET": "YENİKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "NİĞDE",
        "DISTRICT": "ÇAMARDI",
        "STREET": "YUNUS EMRE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "NİĞDE",
        "DISTRICT": "ÇAMARDI",
        "STREET": "ÇARDACIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "NİĞDE",
        "DISTRICT": "ÇAMARDI",
        "STREET": "ÇUKURBAĞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "NİĞDE",
        "DISTRICT": "ÇAMARDI",
        "STREET": "ÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "NİĞDE",
        "DISTRICT": "ÇAMARDI",
        "STREET": "ÜÇKAPILI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "NİĞDE",
        "DISTRICT": "ÇİFTLİK",
        "STREET": "ASMASIZ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "NİĞDE",
        "DISTRICT": "ÇİFTLİK",
        "STREET": "AZATLI-KÜLLÜCE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "NİĞDE",
        "DISTRICT": "ÇİFTLİK",
        "STREET": "AZATLI-SAĞIRGÖL MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "NİĞDE",
        "DISTRICT": "ÇİFTLİK",
        "STREET": "AZATLI-YENİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "NİĞDE",
        "DISTRICT": "ÇİFTLİK",
        "STREET": "AZATLI-YEŞİLKENT MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "NİĞDE",
        "DISTRICT": "ÇİFTLİK",
        "STREET": "AZATLI-ÇAYIR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "NİĞDE",
        "DISTRICT": "ÇİFTLİK",
        "STREET": "BOZKÖY-YENİCE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "NİĞDE",
        "DISTRICT": "ÇİFTLİK",
        "STREET": "BOZKÖY-YENİKENT MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "NİĞDE",
        "DISTRICT": "ÇİFTLİK",
        "STREET": "BOZKÖY-YEŞİLYURT MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "NİĞDE",
        "DISTRICT": "ÇİFTLİK",
        "STREET": "DİVARLI-ALPASLAN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "NİĞDE",
        "DISTRICT": "ÇİFTLİK",
        "STREET": "DİVARLI-FATİH MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "NİĞDE",
        "DISTRICT": "ÇİFTLİK",
        "STREET": "DİVARLI-YENİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "NİĞDE",
        "DISTRICT": "ÇİFTLİK",
        "STREET": "KULA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "NİĞDE",
        "DISTRICT": "ÇİFTLİK",
        "STREET": "KİTRELİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "NİĞDE",
        "DISTRICT": "ÇİFTLİK",
        "STREET": "MAHMUTLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "NİĞDE",
        "DISTRICT": "ÇİFTLİK",
        "STREET": "MURTAZAKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "NİĞDE",
        "DISTRICT": "ÇİFTLİK",
        "STREET": "OVALIBAĞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "NİĞDE",
        "DISTRICT": "ÇİFTLİK",
        "STREET": "SULTANPINARI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "NİĞDE",
        "DISTRICT": "ÇİFTLİK",
        "STREET": "YENİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "NİĞDE",
        "DISTRICT": "ÇİFTLİK",
        "STREET": "ÇARDAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "NİĞDE",
        "DISTRICT": "ÇİFTLİK",
        "STREET": "ÇARŞI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "NİĞDE",
        "DISTRICT": "ÇİFTLİK",
        "STREET": "ÇINARLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "NİĞDE",
        "DISTRICT": "ÇİFTLİK",
        "STREET": "ÇİFTÇİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "NİĞDE",
        "DISTRICT": "ÇİFTLİK",
        "STREET": "ŞEYHLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "AKKUŞ",
        "STREET": "AKPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "AKKUŞ",
        "STREET": "ALAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "AKKUŞ",
        "STREET": "AMBARGÜRGEN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "AKKUŞ",
        "STREET": "CEYHANLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "AKKUŞ",
        "STREET": "DAMYERİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "AKKUŞ",
        "STREET": "DAĞYOLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "AKKUŞ",
        "STREET": "DÜĞENCİLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "AKKUŞ",
        "STREET": "ESENTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "AKKUŞ",
        "STREET": "GEDİKLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "AKKUŞ",
        "STREET": "GÖKÇEBAYIR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "AKKUŞ",
        "STREET": "GÜRGENLİYATAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "AKKUŞ",
        "STREET": "HALİLUŞAĞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "AKKUŞ",
        "STREET": "KARAÇAL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "AKKUŞ",
        "STREET": "KARGI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "AKKUŞ",
        "STREET": "KEMİKGERİŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "AKKUŞ",
        "STREET": "KETENDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "AKKUŞ",
        "STREET": "KIZILELMA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "AKKUŞ",
        "STREET": "KOÇCUVAZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "AKKUŞ",
        "STREET": "KURTBOĞAZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "AKKUŞ",
        "STREET": "KUŞÇULU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "AKKUŞ",
        "STREET": "KÜLEKÇİLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "AKKUŞ",
        "STREET": "MERKEZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "AKKUŞ",
        "STREET": "MEYVALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "AKKUŞ",
        "STREET": "MURATLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "AKKUŞ",
        "STREET": "ORMANCIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "AKKUŞ",
        "STREET": "ORTABÖLME MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "AKKUŞ",
        "STREET": "SALMAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "AKKUŞ",
        "STREET": "SEFERLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "AKKUŞ",
        "STREET": "SUBAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "AKKUŞ",
        "STREET": "TUZAKKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "AKKUŞ",
        "STREET": "YAZLIKBELEN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "AKKUŞ",
        "STREET": "YENİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "AKKUŞ",
        "STREET": "YEŞİLGÜNEYCİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "AKKUŞ",
        "STREET": "YEŞİLKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "AKKUŞ",
        "STREET": "YOLBAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "AKKUŞ",
        "STREET": "YUKARIDÜĞENCİLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "AKKUŞ",
        "STREET": "ÇALDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "AKKUŞ",
        "STREET": "ÇAMALAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "AKKUŞ",
        "STREET": "ÇAMLICA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "AKKUŞ",
        "STREET": "ÇAVDAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "AKKUŞ",
        "STREET": "ÇAYIRALAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "AKKUŞ",
        "STREET": "ÇUKURKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "AKKUŞ",
        "STREET": "ÇÖKEK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "AKKUŞ",
        "STREET": "ŞAHİNKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "ALTINORDU",
        "STREET": "AKKİSE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "ALTINORDU",
        "STREET": "AKYAZI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "ALTINORDU",
        "STREET": "AKÇATEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "ALTINORDU",
        "STREET": "ALEMBEY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "ALTINORDU",
        "STREET": "ALINCA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "ALTINORDU",
        "STREET": "ALTUNYURT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "ALTINORDU",
        "STREET": "ALİSAYVAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "ALTINORDU",
        "STREET": "ARPAKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "ALTINORDU",
        "STREET": "ARTIKLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "ALTINORDU",
        "STREET": "ATAKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "ALTINORDU",
        "STREET": "AYDINLIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "ALTINORDU",
        "STREET": "AZİZİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "ALTINORDU",
        "STREET": "BAHARİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "ALTINORDU",
        "STREET": "BAHÇELİEVLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "ALTINORDU",
        "STREET": "BAYADI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "ALTINORDU",
        "STREET": "BAYRAMLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "ALTINORDU",
        "STREET": "BOZTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "ALTINORDU",
        "STREET": "BUCAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "ALTINORDU",
        "STREET": "BURHANETTİNKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "ALTINORDU",
        "STREET": "CUMHURİYET (KÖY) MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "ALTINORDU",
        "STREET": "CUMHURİYET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "ALTINORDU",
        "STREET": "DEDELİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "ALTINORDU",
        "STREET": "DELİKKAYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "ALTINORDU",
        "STREET": "DURUGÖL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "ALTINORDU",
        "STREET": "DÜZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "ALTINORDU",
        "STREET": "DÜZKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "ALTINORDU",
        "STREET": "DİKENCE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "ALTINORDU",
        "STREET": "EMEN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "ALTINORDU",
        "STREET": "ERENLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "ALTINORDU",
        "STREET": "ESKİPAZAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "ALTINORDU",
        "STREET": "EYÜPLÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "ALTINORDU",
        "STREET": "GERCE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "ALTINORDU",
        "STREET": "GÖKÖMER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "ALTINORDU",
        "STREET": "GÜMÜŞKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "ALTINORDU",
        "STREET": "GÜNÖREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "ALTINORDU",
        "STREET": "GÜZELYALI (UZUNİSA) MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "ALTINORDU",
        "STREET": "GÜZELYALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "ALTINORDU",
        "STREET": "HACILAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "ALTINORDU",
        "STREET": "HATİPLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "ALTINORDU",
        "STREET": "HÜRRİYET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "ALTINORDU",
        "STREET": "IŞIKLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "ALTINORDU",
        "STREET": "KARAAĞAÇ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "ALTINORDU",
        "STREET": "KARACAÖMER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "ALTINORDU",
        "STREET": "KARAOLUK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "ALTINORDU",
        "STREET": "KARAPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "ALTINORDU",
        "STREET": "KARŞIYAKA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "ALTINORDU",
        "STREET": "KAYABAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "ALTINORDU",
        "STREET": "KAYADİBİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "ALTINORDU",
        "STREET": "KISACIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "ALTINORDU",
        "STREET": "KIZILHİSAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "ALTINORDU",
        "STREET": "KOVANCI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "ALTINORDU",
        "STREET": "KUMBAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "ALTINORDU",
        "STREET": "KURTULMUŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "ALTINORDU",
        "STREET": "KUYLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "ALTINORDU",
        "STREET": "KÖKENLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "ALTINORDU",
        "STREET": "KİRAZLİMANI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "ALTINORDU",
        "STREET": "MUBAREK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "ALTINORDU",
        "STREET": "NİZAMETTİN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "ALTINORDU",
        "STREET": "ORHANİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "ALTINORDU",
        "STREET": "ORTAKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "ALTINORDU",
        "STREET": "OSMANİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "ALTINORDU",
        "STREET": "OĞMACA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "ALTINORDU",
        "STREET": "PELİTLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "ALTINORDU",
        "STREET": "SARAY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "ALTINORDU",
        "STREET": "SARAYCIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "ALTINORDU",
        "STREET": "SAĞIRLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "ALTINORDU",
        "STREET": "SELİMİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "ALTINORDU",
        "STREET": "SUBAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "ALTINORDU",
        "STREET": "TAŞBAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "ALTINORDU",
        "STREET": "TERZİLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "ALTINORDU",
        "STREET": "TEYNELİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "ALTINORDU",
        "STREET": "TOPLUCA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "ALTINORDU",
        "STREET": "UZUNMUSA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "ALTINORDU",
        "STREET": "UZUNİSA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "ALTINORDU",
        "STREET": "YARAŞLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "ALTINORDU",
        "STREET": "YAĞIZLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "ALTINORDU",
        "STREET": "YEMİŞLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "ALTINORDU",
        "STREET": "YENİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "ALTINORDU",
        "STREET": "YEŞİLKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "ALTINORDU",
        "STREET": "YILDIZLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "ALTINORDU",
        "STREET": "YUKARITEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "ALTINORDU",
        "STREET": "ZAFER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "ALTINORDU",
        "STREET": "ZAFERİMİLLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "ALTINORDU",
        "STREET": "ÇAVUŞLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "ALTINORDU",
        "STREET": "ÖCELİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "ALTINORDU",
        "STREET": "ÖRENCİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "ALTINORDU",
        "STREET": "ÖVÜNDÜK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "ALTINORDU",
        "STREET": "ŞAHİNCİLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "ALTINORDU",
        "STREET": "ŞARKİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "ALTINORDU",
        "STREET": "ŞENKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "ALTINORDU",
        "STREET": "ŞENOCAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "ALTINORDU",
        "STREET": "ŞİRİNEVLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "AYBASTI",
        "STREET": "ALACALAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "AYBASTI",
        "STREET": "ARMUTLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "AYBASTI",
        "STREET": "BEŞTAM MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "AYBASTI",
        "STREET": "ESENLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "AYBASTI",
        "STREET": "FATİH MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "AYBASTI",
        "STREET": "HİSARCIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "AYBASTI",
        "STREET": "KARAMANLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "AYBASTI",
        "STREET": "KAYABAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "AYBASTI",
        "STREET": "KOYUNCULU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "AYBASTI",
        "STREET": "KUTLULAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "AYBASTI",
        "STREET": "KÜÇÜKYAKA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "AYBASTI",
        "STREET": "ORTAKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "AYBASTI",
        "STREET": "PELİTÖZÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "AYBASTI",
        "STREET": "SARIYAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "AYBASTI",
        "STREET": "SAĞLIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "AYBASTI",
        "STREET": "SEFALIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "AYBASTI",
        "STREET": "TOYGAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "AYBASTI",
        "STREET": "UZUNDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "AYBASTI",
        "STREET": "ZAFERİMİLLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "AYBASTI",
        "STREET": "ÇAKIRLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "AYBASTI",
        "STREET": "ÇUKUR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "FATSA",
        "STREET": "AHMETLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "FATSA",
        "STREET": "ARPALIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "FATSA",
        "STREET": "ASLANCAMİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "FATSA",
        "STREET": "AYAZLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "FATSA",
        "STREET": "AŞAĞIARDIÇ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "FATSA",
        "STREET": "AŞAĞITEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "FATSA",
        "STREET": "AŞAĞIYAVAŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "FATSA",
        "STREET": "BACANAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "FATSA",
        "STREET": "BAHÇELER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "FATSA",
        "STREET": "BAĞLARCA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "FATSA",
        "STREET": "BAŞKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "FATSA",
        "STREET": "BEYCELİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "FATSA",
        "STREET": "BOLAMAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "FATSA",
        "STREET": "BOZDAĞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "FATSA",
        "STREET": "BUCAKLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "FATSA",
        "STREET": "BUHARİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "FATSA",
        "STREET": "BÜLBÜL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "FATSA",
        "STREET": "BÜYÜKKOÇ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "FATSA",
        "STREET": "DEMİRCİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "FATSA",
        "STREET": "DEREYURT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "FATSA",
        "STREET": "DOLUNAY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "FATSA",
        "STREET": "DUAYERİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "FATSA",
        "STREET": "DUMLUPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "FATSA",
        "STREET": "DÜĞÜNLÜK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "FATSA",
        "STREET": "ESKİORDU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "FATSA",
        "STREET": "EVKAF MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "FATSA",
        "STREET": "FATİH MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "FATSA",
        "STREET": "GEYİKÇELİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "FATSA",
        "STREET": "GÖLKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "FATSA",
        "STREET": "GÜVERCİNLİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "FATSA",
        "STREET": "HACIKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "FATSA",
        "STREET": "HAMLIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "FATSA",
        "STREET": "HATİPLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "FATSA",
        "STREET": "HIDIRBEYLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "FATSA",
        "STREET": "HOYLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "FATSA",
        "STREET": "ILICA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "FATSA",
        "STREET": "KABAKDAĞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "FATSA",
        "STREET": "KALEÖNÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "FATSA",
        "STREET": "KARATAŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "FATSA",
        "STREET": "KARGUCAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "FATSA",
        "STREET": "KARŞIYAKA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "FATSA",
        "STREET": "KAVRAZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "FATSA",
        "STREET": "KAYACA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "FATSA",
        "STREET": "KAYAKÖY AKÇAKESE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "FATSA",
        "STREET": "KILAVUZÖMER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "FATSA",
        "STREET": "KILIÇLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "FATSA",
        "STREET": "KONAKBAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "FATSA",
        "STREET": "KULAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "FATSA",
        "STREET": "KURTULUŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "FATSA",
        "STREET": "KÖSEBUCAĞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "FATSA",
        "STREET": "KÜPDÜŞEN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "FATSA",
        "STREET": "KÜÇÜKKOÇ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "FATSA",
        "STREET": "MEHMETAKİF MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "FATSA",
        "STREET": "MEŞEBÜKÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "FATSA",
        "STREET": "MUSTAFAKEMALPAŞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "FATSA",
        "STREET": "OLUKLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "FATSA",
        "STREET": "PALAZLI KÖMÜRLÜK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "FATSA",
        "STREET": "SAKARYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "FATSA",
        "STREET": "SALİHLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "FATSA",
        "STREET": "SARAYTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "FATSA",
        "STREET": "SAZCILAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "FATSA",
        "STREET": "SEFAKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "FATSA",
        "STREET": "SUDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "FATSA",
        "STREET": "TAHTABAŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "FATSA",
        "STREET": "TAYALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "FATSA",
        "STREET": "TAŞLICA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "FATSA",
        "STREET": "TEPECİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "FATSA",
        "STREET": "UZUNDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "FATSA",
        "STREET": "YALIKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "FATSA",
        "STREET": "YAPRAKLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "FATSA",
        "STREET": "YASSIBAHÇE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "FATSA",
        "STREET": "YASSITAŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "FATSA",
        "STREET": "YAVAŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "FATSA",
        "STREET": "YENİDOĞAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "FATSA",
        "STREET": "YENİKENT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "FATSA",
        "STREET": "YENİPAZAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "FATSA",
        "STREET": "YENİYURT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "FATSA",
        "STREET": "YEŞİLKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "FATSA",
        "STREET": "YEŞİLTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "FATSA",
        "STREET": "YUKARIARDIÇ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "FATSA",
        "STREET": "YUKARIBAHÇELER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "FATSA",
        "STREET": "YUKARITEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "FATSA",
        "STREET": "YUSUFLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "FATSA",
        "STREET": "ÇÖMLEKLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "FATSA",
        "STREET": "ÇÖTELÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "FATSA",
        "STREET": "ÖRENCİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "FATSA",
        "STREET": "İNÖNÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "FATSA",
        "STREET": "İSLAMDAĞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "FATSA",
        "STREET": "ŞEREFİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "GÖLKÖY",
        "STREET": "AHMETLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "GÖLKÖY",
        "STREET": "AKÇALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "GÖLKÖY",
        "STREET": "ALANYURT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "GÖLKÖY",
        "STREET": "AYDOĞAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "GÖLKÖY",
        "STREET": "BAYIRALAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "GÖLKÖY",
        "STREET": "BULUT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "GÖLKÖY",
        "STREET": "CİHADİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "GÖLKÖY",
        "STREET": "DAMARLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "GÖLKÖY",
        "STREET": "DÜZYAYLA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "GÖLKÖY",
        "STREET": "DİREKLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "GÖLKÖY",
        "STREET": "EMİRLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "GÖLKÖY",
        "STREET": "GÖLKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "GÖLKÖY",
        "STREET": "GÜZELYAYLA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "GÖLKÖY",
        "STREET": "GÜZELYURT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "GÖLKÖY",
        "STREET": "HARUNİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "GÖLKÖY",
        "STREET": "HÜRRİYET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "GÖLKÖY",
        "STREET": "KALE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "GÖLKÖY",
        "STREET": "KARAGÖZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "GÖLKÖY",
        "STREET": "KARAHASAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "GÖLKÖY",
        "STREET": "KONAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "GÖLKÖY",
        "STREET": "KOZÖREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "GÖLKÖY",
        "STREET": "KUŞLUVAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "GÖLKÖY",
        "STREET": "PAŞAPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "GÖLKÖY",
        "STREET": "SARICA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "GÖLKÖY",
        "STREET": "SÜLEYMANİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "GÖLKÖY",
        "STREET": "YUVAPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "GÖLKÖY",
        "STREET": "ÇATAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "GÖLKÖY",
        "STREET": "ÇETİLLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "GÖLKÖY",
        "STREET": "ÖZLÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "GÖLKÖY",
        "STREET": "İÇYAKA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "GÜLYALI",
        "STREET": "ALİBEY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "GÜLYALI",
        "STREET": "AMBARCILI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "GÜLYALI",
        "STREET": "AYRILIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "GÜLYALI",
        "STREET": "GÜLİSTAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "GÜLYALI",
        "STREET": "HOŞKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "GÜLYALI",
        "STREET": "KESTANE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "GÜLYALI",
        "STREET": "MUSTAFALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "GÜLYALI",
        "STREET": "SAYACA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "GÜLYALI",
        "STREET": "TAŞLIÇAY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "GÜLYALI",
        "STREET": "TEPEALTI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "GÜLYALI",
        "STREET": "TURNASUYU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "GÜLYALI",
        "STREET": "YENİKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "GÜLYALI",
        "STREET": "ÜRÜMBEY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "GÜRGENTEPE",
        "STREET": "AKMESCİT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "GÜRGENTEPE",
        "STREET": "AKYURT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "GÜRGENTEPE",
        "STREET": "AKÖREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "GÜRGENTEPE",
        "STREET": "ALASEHER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "GÜRGENTEPE",
        "STREET": "AĞIZLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "GÜRGENTEPE",
        "STREET": "BAHTİYARLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "GÜRGENTEPE",
        "STREET": "CUMHURİYET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "GÜRGENTEPE",
        "STREET": "DÖŞEK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "GÜRGENTEPE",
        "STREET": "ESKİKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "GÜRGENTEPE",
        "STREET": "GÖLLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "GÜRGENTEPE",
        "STREET": "GÜLBELEN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "GÜRGENTEPE",
        "STREET": "GÜLTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "GÜRGENTEPE",
        "STREET": "HASANCIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "GÜRGENTEPE",
        "STREET": "IŞIKTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "GÜRGENTEPE",
        "STREET": "MURATÇIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "GÜRGENTEPE",
        "STREET": "OKÇABEL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "GÜRGENTEPE",
        "STREET": "TEPEKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "GÜRGENTEPE",
        "STREET": "TUZLA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "GÜRGENTEPE",
        "STREET": "TİKENLİCE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "GÜRGENTEPE",
        "STREET": "YURTSEVEN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "GÜRGENTEPE",
        "STREET": "YURTYERİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "GÜRGENTEPE",
        "STREET": "ÇATALAĞAÇ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "GÜRGENTEPE",
        "STREET": "ŞİRİNKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "KABADÜZ",
        "STREET": "AKGÜNEY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "KABADÜZ",
        "STREET": "BAŞKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "KABADÜZ",
        "STREET": "DERİNÇAY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "KABADÜZ",
        "STREET": "DİŞKAYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "KABADÜZ",
        "STREET": "ESENYURT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "KABADÜZ",
        "STREET": "GELİNKAYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "KABADÜZ",
        "STREET": "GÜLPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "KABADÜZ",
        "STREET": "GÜMÜŞDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "KABADÜZ",
        "STREET": "HARAMİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "KABADÜZ",
        "STREET": "KABADÜZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "KABADÜZ",
        "STREET": "KARAKİRAZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "KABADÜZ",
        "STREET": "KİRAZDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "KABADÜZ",
        "STREET": "MUSAKIRIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "KABADÜZ",
        "STREET": "TURNALIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "KABADÜZ",
        "STREET": "YEŞİLADA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "KABADÜZ",
        "STREET": "YEŞİLYURT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "KABADÜZ",
        "STREET": "YOKUŞDİBİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "KABADÜZ",
        "STREET": "YUKARI KİRAZDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "KABADÜZ",
        "STREET": "ÖZLÜKENT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "KABATAŞ",
        "STREET": "ALANBAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "KABATAŞ",
        "STREET": "ALANKENT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "KABATAŞ",
        "STREET": "ARDIÇ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "KABATAŞ",
        "STREET": "BELEN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "KABATAŞ",
        "STREET": "BEYLERLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "KABATAŞ",
        "STREET": "DÜZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "KABATAŞ",
        "STREET": "ECELİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "KABATAŞ",
        "STREET": "ELBEYİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "KABATAŞ",
        "STREET": "HOŞKADEM MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "KABATAŞ",
        "STREET": "ILICAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "KABATAŞ",
        "STREET": "KABATAŞ YENİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "KABATAŞ",
        "STREET": "KAYINCIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "KABATAŞ",
        "STREET": "KUZKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "KABATAŞ",
        "STREET": "MERKEZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "KABATAŞ",
        "STREET": "YAKACIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "KABATAŞ",
        "STREET": "YENİCELİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "KABATAŞ",
        "STREET": "ÇUKURCAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "KABATAŞ",
        "STREET": "ŞİFA SUYU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "KORGAN",
        "STREET": "AŞAĞIKOZPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "KORGAN",
        "STREET": "AŞAĞIYAYLACIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "KORGAN",
        "STREET": "BELALAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "KORGAN",
        "STREET": "BEYPINARI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "KORGAN",
        "STREET": "BÜYÜKAKÇAKESE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "KORGAN",
        "STREET": "DURALİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "KORGAN",
        "STREET": "DİP MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "KORGAN",
        "STREET": "KARAKIŞLA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "KORGAN",
        "STREET": "KARAKOYUNLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "KORGAN",
        "STREET": "KOÇCUĞAZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "KORGAN",
        "STREET": "SARIALIÇ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "KORGAN",
        "STREET": "SOĞUKPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "KORGAN",
        "STREET": "TATARCIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "KORGAN",
        "STREET": "TEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "KORGAN",
        "STREET": "TEPEALAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "KORGAN",
        "STREET": "TERZİLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "KORGAN",
        "STREET": "YAZICI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "KORGAN",
        "STREET": "YAZLIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "KORGAN",
        "STREET": "YENİKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "KORGAN",
        "STREET": "YENİPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "KORGAN",
        "STREET": "YEŞİLALAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "KORGAN",
        "STREET": "YEŞİLDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "KORGAN",
        "STREET": "YEŞİLYURT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "KORGAN",
        "STREET": "YUKARIKOZPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "KORGAN",
        "STREET": "YUKARIYAYLACIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "KORGAN",
        "STREET": "ÇAMLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "KORGAN",
        "STREET": "ÇAYIRKENT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "KORGAN",
        "STREET": "ÇİFTLİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "KORGAN",
        "STREET": "ÇİTLİCE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "KUMRU",
        "STREET": "AKÇADERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "KUMRU",
        "STREET": "AVDULLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "KUMRU",
        "STREET": "AYVALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "KUMRU",
        "STREET": "AĞCAALANTÜRK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "KUMRU",
        "STREET": "BALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "KUMRU",
        "STREET": "BALLIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "KUMRU",
        "STREET": "DEMİRCİLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "KUMRU",
        "STREET": "DERBENT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "KUMRU",
        "STREET": "DEREKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "KUMRU",
        "STREET": "DUMAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "KUMRU",
        "STREET": "DİVANITÜRK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "KUMRU",
        "STREET": "ERGENTÜRK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "KUMRU",
        "STREET": "ERİKÇELİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "KUMRU",
        "STREET": "ESENCE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "KUMRU",
        "STREET": "ESKİÇOKDEĞİRMEN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "KUMRU",
        "STREET": "FATİH MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "KUMRU",
        "STREET": "FİZME MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "KUMRU",
        "STREET": "GÖKÇELİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "KUMRU",
        "STREET": "GÜNEYCİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "KUMRU",
        "STREET": "KADINCIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "KUMRU",
        "STREET": "KARAAĞAÇ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "KUMRU",
        "STREET": "KARACALAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "KUMRU",
        "STREET": "KARACALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "KUMRU",
        "STREET": "KAYABAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "KUMRU",
        "STREET": "KONAKLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "KUMRU",
        "STREET": "KOVANCILI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "KUMRU",
        "STREET": "KURTULUŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "KUMRU",
        "STREET": "KÜÇÜKAKÇAKESE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "KUMRU",
        "STREET": "ORTACA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "KUMRU",
        "STREET": "ORTAÇOKDEĞİRMEN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "KUMRU",
        "STREET": "SAMUR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "KUMRU",
        "STREET": "TEKKEKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "KUMRU",
        "STREET": "YALNIZDAM MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "KUMRU",
        "STREET": "YEMİŞKEN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "KUMRU",
        "STREET": "YENİAKÇAALAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "KUMRU",
        "STREET": "YENİDİVAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "KUMRU",
        "STREET": "YENİERGEN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "KUMRU",
        "STREET": "YUKARIDAMLALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "KUMRU",
        "STREET": "ÇATILI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "KUMRU",
        "STREET": "ŞENYURT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "MESUDİYE",
        "STREET": "ABDİLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "MESUDİYE",
        "STREET": "AKKIRIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "MESUDİYE",
        "STREET": "AKPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "MESUDİYE",
        "STREET": "ALAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "MESUDİYE",
        "STREET": "ARICILAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "MESUDİYE",
        "STREET": "ARIKMUSA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "MESUDİYE",
        "STREET": "ARMUTKOLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "MESUDİYE",
        "STREET": "ARPAALAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "MESUDİYE",
        "STREET": "AŞAĞIGÖKÇE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "MESUDİYE",
        "STREET": "AŞIKLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "MESUDİYE",
        "STREET": "BAHÇE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "MESUDİYE",
        "STREET": "BALIKLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "MESUDİYE",
        "STREET": "BAYIRKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "MESUDİYE",
        "STREET": "BAYRAKLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "MESUDİYE",
        "STREET": "BEYAĞAÇ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "MESUDİYE",
        "STREET": "BEYSEKİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "MESUDİYE",
        "STREET": "BEŞPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "MESUDİYE",
        "STREET": "BİREBİR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "MESUDİYE",
        "STREET": "CELAL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "MESUDİYE",
        "STREET": "DARICABAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "MESUDİYE",
        "STREET": "DAYILI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "MESUDİYE",
        "STREET": "DEREBAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "MESUDİYE",
        "STREET": "DOĞANÇAM MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "MESUDİYE",
        "STREET": "DURSUNLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "MESUDİYE",
        "STREET": "ERİKKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "MESUDİYE",
        "STREET": "ESATLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "MESUDİYE",
        "STREET": "GÖLBAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "MESUDİYE",
        "STREET": "GÖÇBEYİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "MESUDİYE",
        "STREET": "GÜLPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "MESUDİYE",
        "STREET": "GÜNEYCE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "MESUDİYE",
        "STREET": "GÜVENLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "MESUDİYE",
        "STREET": "GÜZELCE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "MESUDİYE",
        "STREET": "GÜZLE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "MESUDİYE",
        "STREET": "HAMZALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "MESUDİYE",
        "STREET": "HERKÖZÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "MESUDİYE",
        "STREET": "ILIŞAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "MESUDİYE",
        "STREET": "KALE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "MESUDİYE",
        "STREET": "KARABAYIR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "MESUDİYE",
        "STREET": "KARACAÖREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "MESUDİYE",
        "STREET": "KAVAKLIDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "MESUDİYE",
        "STREET": "KAYADİBİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "MESUDİYE",
        "STREET": "KIŞLACIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "MESUDİYE",
        "STREET": "KONACIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "MESUDİYE",
        "STREET": "KÖŞE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "MESUDİYE",
        "STREET": "MAHMUDİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "MESUDİYE",
        "STREET": "MAKSUTALAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "MESUDİYE",
        "STREET": "MERKEZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "MESUDİYE",
        "STREET": "MUSALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "MESUDİYE",
        "STREET": "MÜSLÜM SARICA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "MESUDİYE",
        "STREET": "PINARLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "MESUDİYE",
        "STREET": "SARICA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "MESUDİYE",
        "STREET": "SARIYAYLA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "MESUDİYE",
        "STREET": "TOPÇAM MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "MESUDİYE",
        "STREET": "TÜRKKÖYÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "MESUDİYE",
        "STREET": "YARDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "MESUDİYE",
        "STREET": "YAVŞAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "MESUDİYE",
        "STREET": "YAĞMURLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "MESUDİYE",
        "STREET": "YENİMAHALLE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "MESUDİYE",
        "STREET": "YEVELİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "MESUDİYE",
        "STREET": "YEŞİLCE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "MESUDİYE",
        "STREET": "YEŞİLÇİT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "MESUDİYE",
        "STREET": "YUKARIGÖKÇE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "MESUDİYE",
        "STREET": "YUVALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "MESUDİYE",
        "STREET": "ÇALTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "MESUDİYE",
        "STREET": "ÇARDAKLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "MESUDİYE",
        "STREET": "ÇAVDAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "MESUDİYE",
        "STREET": "ÇERÇİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "MESUDİYE",
        "STREET": "ÇUKURALAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "MESUDİYE",
        "STREET": "ÇİTLİKSARICA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "MESUDİYE",
        "STREET": "ÜÇYOL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "PERŞEMBE",
        "STREET": "ALINCA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "PERŞEMBE",
        "STREET": "ANAÇ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "PERŞEMBE",
        "STREET": "AZİZİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "PERŞEMBE",
        "STREET": "BABALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "PERŞEMBE",
        "STREET": "BEKİRLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "PERŞEMBE",
        "STREET": "BEYLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "PERŞEMBE",
        "STREET": "BOLATLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "PERŞEMBE",
        "STREET": "BOĞAZCIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "PERŞEMBE",
        "STREET": "DEREİÇİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "PERŞEMBE",
        "STREET": "DOĞAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "PERŞEMBE",
        "STREET": "DÜZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "PERŞEMBE",
        "STREET": "EFİRLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "PERŞEMBE",
        "STREET": "EKİNCİLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "PERŞEMBE",
        "STREET": "GÜNDOĞDU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "PERŞEMBE",
        "STREET": "GÜZELYURT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "PERŞEMBE",
        "STREET": "HACILAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "PERŞEMBE",
        "STREET": "HAMİDİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "PERŞEMBE",
        "STREET": "KACALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "PERŞEMBE",
        "STREET": "KALEYAKA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "PERŞEMBE",
        "STREET": "KAZANCILI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "PERŞEMBE",
        "STREET": "KIRLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "PERŞEMBE",
        "STREET": "KOVANLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "PERŞEMBE",
        "STREET": "KOZAĞZI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "PERŞEMBE",
        "STREET": "KURTULUŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "PERŞEMBE",
        "STREET": "KUTLUCA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "PERŞEMBE",
        "STREET": "KUYLUCA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "PERŞEMBE",
        "STREET": "MEDRESEÖNÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "PERŞEMBE",
        "STREET": "MERSİN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "PERŞEMBE",
        "STREET": "NENELİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "PERŞEMBE",
        "STREET": "OKÇULU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "PERŞEMBE",
        "STREET": "ORTATEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "PERŞEMBE",
        "STREET": "PERŞEMBE EFİRLİ CEZAEVİ",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "PERŞEMBE",
        "STREET": "RAMAZAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "PERŞEMBE",
        "STREET": "SARAY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "PERŞEMBE",
        "STREET": "SELİMİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "PERŞEMBE",
        "STREET": "SIRAKOVANCI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "PERŞEMBE",
        "STREET": "SOĞUKPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "PERŞEMBE",
        "STREET": "TARLACIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "PERŞEMBE",
        "STREET": "TEPECİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "PERŞEMBE",
        "STREET": "TEPEKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "PERŞEMBE",
        "STREET": "TÖNGELDÜZÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "PERŞEMBE",
        "STREET": "YARLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "PERŞEMBE",
        "STREET": "YAZLIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "PERŞEMBE",
        "STREET": "YENİKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "PERŞEMBE",
        "STREET": "YENİÖZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "PERŞEMBE",
        "STREET": "YEŞİLKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "PERŞEMBE",
        "STREET": "YUMRUTAŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "PERŞEMBE",
        "STREET": "ÇAMARASI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "PERŞEMBE",
        "STREET": "ÇANDIR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "PERŞEMBE",
        "STREET": "ÇAYTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "PERŞEMBE",
        "STREET": "ÇERLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "PERŞEMBE",
        "STREET": "ÇINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "PERŞEMBE",
        "STREET": "İMEÇLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "PERŞEMBE",
        "STREET": "İSTANBULBOĞAZI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "PERŞEMBE",
        "STREET": "ŞENYURT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "ULUBEY",
        "STREET": "AKOLUK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "ULUBEY",
        "STREET": "AKPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "ULUBEY",
        "STREET": "AYDINLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "ULUBEY",
        "STREET": "BAŞÇARDAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "ULUBEY",
        "STREET": "BELENYURT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "ULUBEY",
        "STREET": "CEVİZLİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "ULUBEY",
        "STREET": "DOĞLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "ULUBEY",
        "STREET": "DURAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "ULUBEY",
        "STREET": "ELMAÇUKUR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "ULUBEY",
        "STREET": "EYMÜR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "ULUBEY",
        "STREET": "FINDIKLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "ULUBEY",
        "STREET": "GÜNDÜZLÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "ULUBEY",
        "STREET": "GÜVENKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "ULUBEY",
        "STREET": "GÜVENYURT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "ULUBEY",
        "STREET": "GÜZELYURT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "ULUBEY",
        "STREET": "HOCAOĞLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "ULUBEY",
        "STREET": "KADINCIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "ULUBEY",
        "STREET": "KALICAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "ULUBEY",
        "STREET": "KARAKOCA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "ULUBEY",
        "STREET": "KARDEŞLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "ULUBEY",
        "STREET": "KIRANYAĞMUR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "ULUBEY",
        "STREET": "KOŞACA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "ULUBEY",
        "STREET": "KUMANLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "ULUBEY",
        "STREET": "KUMRULU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "ULUBEY",
        "STREET": "KİRAZLIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "ULUBEY",
        "STREET": "OHTAMIŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "ULUBEY",
        "STREET": "OYUMGÜRGEN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "ULUBEY",
        "STREET": "REFAHİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "ULUBEY",
        "STREET": "UZUNMAHMUT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "ULUBEY",
        "STREET": "YENİMAHALLE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "ULUBEY",
        "STREET": "YENİSAYACA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "ULUBEY",
        "STREET": "YOLBAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "ULUBEY",
        "STREET": "YUKARIKIZILEN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "ULUBEY",
        "STREET": "ÇATALLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "ULUBEY",
        "STREET": "ÇAĞLAYAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "ULUBEY",
        "STREET": "ÇUBUKLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "ULUBEY",
        "STREET": "ÇUKUR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "ULUBEY",
        "STREET": "ÖREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "ULUBEY",
        "STREET": "ŞAHİNKAYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "ULUBEY",
        "STREET": "ŞEKEROLUK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "ULUBEY",
        "STREET": "ŞIHLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "ÇAMAŞ",
        "STREET": "AKKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "ÇAMAŞ",
        "STREET": "AKPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "ÇAMAŞ",
        "STREET": "BUDAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "ÇAMAŞ",
        "STREET": "BURHAN GÜNEYİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "ÇAMAŞ",
        "STREET": "DANIŞMAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "ÇAMAŞ",
        "STREET": "EDİRLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "ÇAMAŞ",
        "STREET": "GÜMÜŞLÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "ÇAMAŞ",
        "STREET": "GİDEN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "ÇAMAŞ",
        "STREET": "HİSARBEY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "ÇAMAŞ",
        "STREET": "KEMAL PAŞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "ÇAMAŞ",
        "STREET": "KESTANEYOKUŞU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "ÇAMAŞ",
        "STREET": "KOCAMAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "ÇAMAŞ",
        "STREET": "SAKARGERİŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "ÇAMAŞ",
        "STREET": "SARIYAKUP MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "ÇAMAŞ",
        "STREET": "SUCUALİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "ÇAMAŞ",
        "STREET": "SÖKEN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "ÇAMAŞ",
        "STREET": "TAŞOLUK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "ÇAMAŞ",
        "STREET": "TEPELİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "ÇAMAŞ",
        "STREET": "UZUNALİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "ÇAMAŞ",
        "STREET": "YENİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "ÇAMAŞ",
        "STREET": "YEŞİLVADİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "ÇAMAŞ",
        "STREET": "ÇAVUŞBAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "ÇAMAŞ",
        "STREET": "ÖRMELİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "ÇATALPINAR",
        "STREET": "AKKAYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "ÇATALPINAR",
        "STREET": "DERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "ÇATALPINAR",
        "STREET": "ELMAKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "ÇATALPINAR",
        "STREET": "GÖLLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "ÇATALPINAR",
        "STREET": "GÜNDOĞDU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "ÇATALPINAR",
        "STREET": "GÜNEY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "ÇATALPINAR",
        "STREET": "GÜNEYCE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "ÇATALPINAR",
        "STREET": "HATİPLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "ÇATALPINAR",
        "STREET": "HAŞAL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "ÇATALPINAR",
        "STREET": "KARAHAMZA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "ÇATALPINAR",
        "STREET": "KARAHASAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "ÇATALPINAR",
        "STREET": "KARŞIYAKA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "ÇATALPINAR",
        "STREET": "KAYATEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "ÇATALPINAR",
        "STREET": "KEÇİLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "ÇATALPINAR",
        "STREET": "KIRAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "ÇATALPINAR",
        "STREET": "MADENKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "ÇATALPINAR",
        "STREET": "MERKEZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "ÇATALPINAR",
        "STREET": "ORTAKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "ÇATALPINAR",
        "STREET": "SAYACATÜRK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "ÇATALPINAR",
        "STREET": "TERİMLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "ÇATALPINAR",
        "STREET": "YENİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "ÇATALPINAR",
        "STREET": "İNCİRLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "ÇATALPINAR",
        "STREET": "ŞİRİNKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "ÇAYBAŞI",
        "STREET": "AKBABA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "ÇAYBAŞI",
        "STREET": "AŞIKLI ÇAKILLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "ÇAYBAŞI",
        "STREET": "BORAS MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "ÇAYBAŞI",
        "STREET": "CEMALLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "ÇAYBAŞI",
        "STREET": "CEVİZLİYATAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "ÇAYBAŞI",
        "STREET": "EĞRİBEL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "ÇAYBAŞI",
        "STREET": "GÖKSU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "ÇAYBAŞI",
        "STREET": "GÜRCEK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "ÇAYBAŞI",
        "STREET": "HACIALİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "ÇAYBAŞI",
        "STREET": "KAPILI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "ÇAYBAŞI",
        "STREET": "KARGALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "ÇAYBAŞI",
        "STREET": "KURUDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "ÇAYBAŞI",
        "STREET": "KUŞLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "ÇAYBAŞI",
        "STREET": "KÖKLÜK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "ÇAYBAŞI",
        "STREET": "MOLLALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "ÇAYBAŞI",
        "STREET": "NAMAZLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "ÇAYBAŞI",
        "STREET": "TAŞKESİĞİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "ÇAYBAŞI",
        "STREET": "TEKKE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "ÇAYBAŞI",
        "STREET": "YENİCUMA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "ÇAYBAŞI",
        "STREET": "ÇANDIR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "ÇAYBAŞI",
        "STREET": "ÇAY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "ÇAYBAŞI",
        "STREET": "ÇAYIR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "ÇAYBAŞI",
        "STREET": "ÇAĞLAYAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "ÇAYBAŞI",
        "STREET": "ÇINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "ÇAYBAŞI",
        "STREET": "İLKÜVEZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "ÇAYBAŞI",
        "STREET": "İÇERİBÜKÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "ÜNYE",
        "STREET": "ATAKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "ÜNYE",
        "STREET": "ATATÜRK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "ÜNYE",
        "STREET": "AYDINTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "ÜNYE",
        "STREET": "AĞIDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "ÜNYE",
        "STREET": "BAYRAMCA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "ÜNYE",
        "STREET": "BAŞKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "ÜNYE",
        "STREET": "BEYLERCE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "ÜNYE",
        "STREET": "BURUNUCU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "ÜNYE",
        "STREET": "CEVİZDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "ÜNYE",
        "STREET": "DENİZBÜKÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "ÜNYE",
        "STREET": "DEREKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "ÜNYE",
        "STREET": "DÖŞEMEDİBİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "ÜNYE",
        "STREET": "DÜZKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "ÜNYE",
        "STREET": "DÜZSAYLAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "ÜNYE",
        "STREET": "DİZDAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "ÜNYE",
        "STREET": "ELMALIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "ÜNYE",
        "STREET": "ERENYURT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "ÜNYE",
        "STREET": "ESENKALE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "ÜNYE",
        "STREET": "ESKİKIZILCAKESE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "ÜNYE",
        "STREET": "FATİH MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "ÜNYE",
        "STREET": "FEVZİ ÇAKMAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "ÜNYE",
        "STREET": "GÖBÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "ÜNYE",
        "STREET": "GÖLCÜĞEZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "ÜNYE",
        "STREET": "GÖLEVİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "ÜNYE",
        "STREET": "GÜNPINARI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "ÜNYE",
        "STREET": "GÜRECÜLÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "ÜNYE",
        "STREET": "GÜZELKALE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "ÜNYE",
        "STREET": "GÜZELYALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "ÜNYE",
        "STREET": "HAMİDİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "ÜNYE",
        "STREET": "HANYANI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "ÜNYE",
        "STREET": "HIZABAŞIGÜNLÜK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "ÜNYE",
        "STREET": "HIZARBAŞIKUMARLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "ÜNYE",
        "STREET": "KADILAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "ÜNYE",
        "STREET": "KALE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "ÜNYE",
        "STREET": "KALEDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "ÜNYE",
        "STREET": "KALEDİBİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "ÜNYE",
        "STREET": "KARŞIYAKA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "ÜNYE",
        "STREET": "KEŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "ÜNYE",
        "STREET": "KOCUKLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "ÜNYE",
        "STREET": "KUŞDOĞAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "ÜNYE",
        "STREET": "KUŞÇULU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "ÜNYE",
        "STREET": "KİLLİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "ÜNYE",
        "STREET": "LİSELER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "ÜNYE",
        "STREET": "NADIRLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "ÜNYE",
        "STREET": "NURETTİN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "ÜNYE",
        "STREET": "NURİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "ÜNYE",
        "STREET": "ORTA YILMAZLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "ÜNYE",
        "STREET": "ORTAKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "ÜNYE",
        "STREET": "PELİTLİYATAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "ÜNYE",
        "STREET": "PINARBAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "ÜNYE",
        "STREET": "SAHİLKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "ÜNYE",
        "STREET": "SARAYCIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "ÜNYE",
        "STREET": "SARAÇLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "ÜNYE",
        "STREET": "SARIHALİL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "ÜNYE",
        "STREET": "SAYLAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "ÜNYE",
        "STREET": "SOFUTEPESİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "ÜNYE",
        "STREET": "TAFLANCIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "ÜNYE",
        "STREET": "TAŞÇA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "ÜNYE",
        "STREET": "TEKKİRAZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "ÜNYE",
        "STREET": "TEPEKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "ÜNYE",
        "STREET": "UĞURLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "ÜNYE",
        "STREET": "YAVI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "ÜNYE",
        "STREET": "YAYCI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "ÜNYE",
        "STREET": "YAYLALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "ÜNYE",
        "STREET": "YAZKONAĞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "ÜNYE",
        "STREET": "YENİKENT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "ÜNYE",
        "STREET": "YENİKIZILCAKESE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "ÜNYE",
        "STREET": "YENİKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "ÜNYE",
        "STREET": "YEŞİLADA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "ÜNYE",
        "STREET": "YEŞİLKENT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "ÜNYE",
        "STREET": "YUNUS EMRE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "ÜNYE",
        "STREET": "YÜCELER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "ÜNYE",
        "STREET": "YİĞİTLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "ÜNYE",
        "STREET": "ÇAKMAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "ÜNYE",
        "STREET": "ÇAMURLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "ÜNYE",
        "STREET": "ÇATAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "ÜNYE",
        "STREET": "ÇATALPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "ÜNYE",
        "STREET": "ÇATALTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "ÜNYE",
        "STREET": "ÇINARCIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "ÜNYE",
        "STREET": "ÇINARLIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "ÜNYE",
        "STREET": "ÇİĞDEM MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "ÜNYE",
        "STREET": "ÜNYE M TİPİ KAPALI VE AÇIK CEZA İNFAZ KURUMU MÜDÜR",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "ÜNYE",
        "STREET": "ÜÇPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "ÜNYE",
        "STREET": "İNKUR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "ÜNYE",
        "STREET": "İPEKYOLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "ÜNYE",
        "STREET": "ŞENYURT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "İKİZCE",
        "STREET": "AĞCAALAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "İKİZCE",
        "STREET": "AŞAĞI KARLITEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "İKİZCE",
        "STREET": "AŞAĞI KAYNARTAŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "İKİZCE",
        "STREET": "BAŞÖNÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "İKİZCE",
        "STREET": "BEYLERCE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "İKİZCE",
        "STREET": "BOLLUK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "İKİZCE",
        "STREET": "DEREBAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "İKİZCE",
        "STREET": "DEVECİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "İKİZCE",
        "STREET": "DUMANTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "İKİZCE",
        "STREET": "DÜZMEŞE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "İKİZCE",
        "STREET": "DÜZPELİT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "İKİZCE",
        "STREET": "DİYEK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "İKİZCE",
        "STREET": "ENAĞIZLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "İKİZCE",
        "STREET": "ESENTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "İKİZCE",
        "STREET": "FATİH MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "İKİZCE",
        "STREET": "GAZİLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "İKİZCE",
        "STREET": "KALE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "İKİZCE",
        "STREET": "KERVANSARAY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "İKİZCE",
        "STREET": "KOCAMAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "İKİZCE",
        "STREET": "KURTKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "İKİZCE",
        "STREET": "KURTLUCA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "İKİZCE",
        "STREET": "KİRAZTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "İKİZCE",
        "STREET": "MERKEZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "İKİZCE",
        "STREET": "SEYMENLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "İKİZCE",
        "STREET": "TOMBAŞLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "İKİZCE",
        "STREET": "YENİCE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "İKİZCE",
        "STREET": "YOĞUNOLUK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "İKİZCE",
        "STREET": "YUKARI KARLITEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "İKİZCE",
        "STREET": "YUKARI KAYNARTAŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "İKİZCE",
        "STREET": "YUNUSKIRIĞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "İKİZCE",
        "STREET": "ÖZPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ORDU",
        "DISTRICT": "İKİZCE",
        "STREET": "ŞENTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "OSMANİYE",
        "DISTRICT": "BAHÇE",
        "STREET": "ARICAKLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "OSMANİYE",
        "DISTRICT": "BAHÇE",
        "STREET": "ARIKLIKAŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "OSMANİYE",
        "DISTRICT": "BAHÇE",
        "STREET": "ASLANLI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "OSMANİYE",
        "DISTRICT": "BAHÇE",
        "STREET": "AŞAĞIARICAKLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "OSMANİYE",
        "DISTRICT": "BAHÇE",
        "STREET": "AŞAĞIKARADERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "OSMANİYE",
        "DISTRICT": "BAHÇE",
        "STREET": "BAHÇELİ EVLER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "OSMANİYE",
        "DISTRICT": "BAHÇE",
        "STREET": "BEKDEMİR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "OSMANİYE",
        "DISTRICT": "BAHÇE",
        "STREET": "BURGAÇLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "OSMANİYE",
        "DISTRICT": "BAHÇE",
        "STREET": "CUMHURİYET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "OSMANİYE",
        "DISTRICT": "BAHÇE",
        "STREET": "ESENTEPE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "OSMANİYE",
        "DISTRICT": "BAHÇE",
        "STREET": "GÖKMUSTAFALI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "OSMANİYE",
        "DISTRICT": "BAHÇE",
        "STREET": "KALE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "OSMANİYE",
        "DISTRICT": "BAHÇE",
        "STREET": "KAMAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "OSMANİYE",
        "DISTRICT": "BAHÇE",
        "STREET": "KARŞIYAKA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "OSMANİYE",
        "DISTRICT": "BAHÇE",
        "STREET": "KIZLAÇ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "OSMANİYE",
        "DISTRICT": "BAHÇE",
        "STREET": "NOHUT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "OSMANİYE",
        "DISTRICT": "BAHÇE",
        "STREET": "SAVRANLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "OSMANİYE",
        "DISTRICT": "BAHÇE",
        "STREET": "YAYLALIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "OSMANİYE",
        "DISTRICT": "BAHÇE",
        "STREET": "YENI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "OSMANİYE",
        "DISTRICT": "BAHÇE",
        "STREET": "YEŞİL YURT MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "OSMANİYE",
        "DISTRICT": "BAHÇE",
        "STREET": "YUKARIKARDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "OSMANİYE",
        "DISTRICT": "BAHÇE",
        "STREET": "ÇAMLICA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "OSMANİYE",
        "DISTRICT": "BAHÇE",
        "STREET": "ÖRENCİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "OSMANİYE",
        "DISTRICT": "BAHÇE",
        "STREET": "İNDERESİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "OSMANİYE",
        "DISTRICT": "BAHÇE",
        "STREET": "İSLAM MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "OSMANİYE",
        "DISTRICT": "BAHÇE",
        "STREET": "İSTİKLAL MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "OSMANİYE",
        "DISTRICT": "DÜZİÇİ",
        "STREET": "AKÇAKOYUNLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "OSMANİYE",
        "DISTRICT": "DÜZİÇİ",
        "STREET": "ALİBOZLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "OSMANİYE",
        "DISTRICT": "DÜZİÇİ",
        "STREET": "ATALAN-KATOBASI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "OSMANİYE",
        "DISTRICT": "DÜZİÇİ",
        "STREET": "ATALAN-MERKEZ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "OSMANİYE",
        "DISTRICT": "DÜZİÇİ",
        "STREET": "ATALAN-ÇUKUROBA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "OSMANİYE",
        "DISTRICT": "DÜZİÇİ",
        "STREET": "ATALAN-ŞEHİT HÜSEYİN SARI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "OSMANİYE",
        "DISTRICT": "DÜZİÇİ",
        "STREET": "BAYINDIRLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "OSMANİYE",
        "DISTRICT": "DÜZİÇİ",
        "STREET": "BOSTANLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "OSMANİYE",
        "DISTRICT": "DÜZİÇİ",
        "STREET": "BOĞAZİÇİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "OSMANİYE",
        "DISTRICT": "DÜZİÇİ",
        "STREET": "BÖCEKLİ-BOYALI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "OSMANİYE",
        "DISTRICT": "DÜZİÇİ",
        "STREET": "BÖCEKLİ-CELİLLER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "OSMANİYE",
        "DISTRICT": "DÜZİÇİ",
        "STREET": "BÖCEKLİ-DEMİRCİLER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "OSMANİYE",
        "DISTRICT": "DÜZİÇİ",
        "STREET": "BÖCEKLİ-DÜMBÜRDEK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "OSMANİYE",
        "DISTRICT": "DÜZİÇİ",
        "STREET": "BÖCEKLİ-KÜRT MAHMUTLAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "OSMANİYE",
        "DISTRICT": "DÜZİÇİ",
        "STREET": "BÖCEKLİ-YEŞİL YURT MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "OSMANİYE",
        "DISTRICT": "DÜZİÇİ",
        "STREET": "CUMHURİYET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "OSMANİYE",
        "DISTRICT": "DÜZİÇİ",
        "STREET": "ELBEYLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "OSMANİYE",
        "DISTRICT": "DÜZİÇİ",
        "STREET": "ELLEK-AYDINLAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "OSMANİYE",
        "DISTRICT": "DÜZİÇİ",
        "STREET": "ELLEK-GÖKYILDIZ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "OSMANİYE",
        "DISTRICT": "DÜZİÇİ",
        "STREET": "ELLEK-MERKEZ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "OSMANİYE",
        "DISTRICT": "DÜZİÇİ",
        "STREET": "ELLEK-PEÇENEK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "OSMANİYE",
        "DISTRICT": "DÜZİÇİ",
        "STREET": "ELLEK-SOYLAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "OSMANİYE",
        "DISTRICT": "DÜZİÇİ",
        "STREET": "ELLEK-ÇEVLİK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "OSMANİYE",
        "DISTRICT": "DÜZİÇİ",
        "STREET": "ELLEK-ÇİRAZ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "OSMANİYE",
        "DISTRICT": "DÜZİÇİ",
        "STREET": "ELLEK-ŞEHİT ALİ GÖKÇE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "OSMANİYE",
        "DISTRICT": "DÜZİÇİ",
        "STREET": "FARSAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "OSMANİYE",
        "DISTRICT": "DÜZİÇİ",
        "STREET": "GÖKÇAYIR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "OSMANİYE",
        "DISTRICT": "DÜZİÇİ",
        "STREET": "GÜMÜŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "OSMANİYE",
        "DISTRICT": "DÜZİÇİ",
        "STREET": "GÜZELYURT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "OSMANİYE",
        "DISTRICT": "DÜZİÇİ",
        "STREET": "HÜRRİYET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "OSMANİYE",
        "DISTRICT": "DÜZİÇİ",
        "STREET": "KARACAOĞLAN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "OSMANİYE",
        "DISTRICT": "DÜZİÇİ",
        "STREET": "KARAGEDİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "OSMANİYE",
        "DISTRICT": "DÜZİÇİ",
        "STREET": "KARAGUZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "OSMANİYE",
        "DISTRICT": "DÜZİÇİ",
        "STREET": "KARKIN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "OSMANİYE",
        "DISTRICT": "DÜZİÇİ",
        "STREET": "KARLICA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "OSMANİYE",
        "DISTRICT": "DÜZİÇİ",
        "STREET": "KARŞIYAKA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "OSMANİYE",
        "DISTRICT": "DÜZİÇİ",
        "STREET": "KURTBEYOĞLU MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "OSMANİYE",
        "DISTRICT": "DÜZİÇİ",
        "STREET": "KURTULUŞ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "OSMANİYE",
        "DISTRICT": "DÜZİÇİ",
        "STREET": "KUŞCU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "OSMANİYE",
        "DISTRICT": "DÜZİÇİ",
        "STREET": "MERKEZ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "OSMANİYE",
        "DISTRICT": "DÜZİÇİ",
        "STREET": "OLUKLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "OSMANİYE",
        "DISTRICT": "DÜZİÇİ",
        "STREET": "PİRSULTANLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "OSMANİYE",
        "DISTRICT": "DÜZİÇİ",
        "STREET": "SELVERLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "OSMANİYE",
        "DISTRICT": "DÜZİÇİ",
        "STREET": "SÖĞÜTLÜGÖL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "OSMANİYE",
        "DISTRICT": "DÜZİÇİ",
        "STREET": "UZUN BANI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "OSMANİYE",
        "DISTRICT": "DÜZİÇİ",
        "STREET": "YARBAŞI-KARACAÖREN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "OSMANİYE",
        "DISTRICT": "DÜZİÇİ",
        "STREET": "YARBAŞI-KARAÇARLI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "OSMANİYE",
        "DISTRICT": "DÜZİÇİ",
        "STREET": "YARBAŞI-MERKEZ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "OSMANİYE",
        "DISTRICT": "DÜZİÇİ",
        "STREET": "YAZLAMAZLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "OSMANİYE",
        "DISTRICT": "DÜZİÇİ",
        "STREET": "YENİCE HARUNİYE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "OSMANİYE",
        "DISTRICT": "DÜZİÇİ",
        "STREET": "YENİFARSAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "OSMANİYE",
        "DISTRICT": "DÜZİÇİ",
        "STREET": "YEŞİLDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "OSMANİYE",
        "DISTRICT": "DÜZİÇİ",
        "STREET": "YEŞİLKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "OSMANİYE",
        "DISTRICT": "DÜZİÇİ",
        "STREET": "YEŞİLOVA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "OSMANİYE",
        "DISTRICT": "DÜZİÇİ",
        "STREET": "YEŞİLYURT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "OSMANİYE",
        "DISTRICT": "DÜZİÇİ",
        "STREET": "YUKARI HACILAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "OSMANİYE",
        "DISTRICT": "DÜZİÇİ",
        "STREET": "ÇAMİÇİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "OSMANİYE",
        "DISTRICT": "DÜZİÇİ",
        "STREET": "ÇATAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "OSMANİYE",
        "DISTRICT": "DÜZİÇİ",
        "STREET": "ÇERÇİOĞLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "OSMANİYE",
        "DISTRICT": "DÜZİÇİ",
        "STREET": "ÇOTLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "OSMANİYE",
        "DISTRICT": "DÜZİÇİ",
        "STREET": "ÇİFTLİK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "OSMANİYE",
        "DISTRICT": "DÜZİÇİ",
        "STREET": "ÇİTLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "OSMANİYE",
        "DISTRICT": "DÜZİÇİ",
        "STREET": "ÜZÜMLÜ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "OSMANİYE",
        "DISTRICT": "DÜZİÇİ",
        "STREET": "İRFANLI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "OSMANİYE",
        "DISTRICT": "DÜZİÇİ",
        "STREET": "İSTİKLAL MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "OSMANİYE",
        "DISTRICT": "DÜZİÇİ",
        "STREET": "ŞEHİT ASKER KEMAL KESKİN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "OSMANİYE",
        "DISTRICT": "HASANBEYLİ",
        "STREET": "CUMHURİYET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "OSMANİYE",
        "DISTRICT": "HASANBEYLİ",
        "STREET": "HÜRRİYET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "OSMANİYE",
        "DISTRICT": "HASANBEYLİ",
        "STREET": "KALECİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "OSMANİYE",
        "DISTRICT": "HASANBEYLİ",
        "STREET": "KARAYİĞİT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "OSMANİYE",
        "DISTRICT": "HASANBEYLİ",
        "STREET": "MERKEZ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "OSMANİYE",
        "DISTRICT": "HASANBEYLİ",
        "STREET": "SARAYOVA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "OSMANİYE",
        "DISTRICT": "HASANBEYLİ",
        "STREET": "YANIKKIŞLA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "OSMANİYE",
        "DISTRICT": "HASANBEYLİ",
        "STREET": "YEŞİLYURT MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "OSMANİYE",
        "DISTRICT": "HASANBEYLİ",
        "STREET": "ÇOLAKLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "OSMANİYE",
        "DISTRICT": "HASANBEYLİ",
        "STREET": "ÇULHALI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "OSMANİYE",
        "DISTRICT": "KADİRLİ",
        "STREET": "7 MART MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "OSMANİYE",
        "DISTRICT": "KADİRLİ",
        "STREET": "AKKÖPRÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "OSMANİYE",
        "DISTRICT": "KADİRLİ",
        "STREET": "AKOVA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "OSMANİYE",
        "DISTRICT": "KADİRLİ",
        "STREET": "ANBERİNARKI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "OSMANİYE",
        "DISTRICT": "KADİRLİ",
        "STREET": "AYDINLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "OSMANİYE",
        "DISTRICT": "KADİRLİ",
        "STREET": "AZAPLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "OSMANİYE",
        "DISTRICT": "KADİRLİ",
        "STREET": "AŞAĞIBOZKUYU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "OSMANİYE",
        "DISTRICT": "KADİRLİ",
        "STREET": "AŞAĞIÇİYANLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "OSMANİYE",
        "DISTRICT": "KADİRLİ",
        "STREET": "BAHADIRLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "OSMANİYE",
        "DISTRICT": "KADİRLİ",
        "STREET": "BAĞ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "OSMANİYE",
        "DISTRICT": "KADİRLİ",
        "STREET": "BEKERECİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "OSMANİYE",
        "DISTRICT": "KADİRLİ",
        "STREET": "CEMALPAŞA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "OSMANİYE",
        "DISTRICT": "KADİRLİ",
        "STREET": "CENGİZ TOPEL MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "OSMANİYE",
        "DISTRICT": "KADİRLİ",
        "STREET": "COŞKUNLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "OSMANİYE",
        "DISTRICT": "KADİRLİ",
        "STREET": "DERE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "OSMANİYE",
        "DISTRICT": "KADİRLİ",
        "STREET": "DERVİŞPAŞA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "OSMANİYE",
        "DISTRICT": "KADİRLİ",
        "STREET": "DEĞİRMENDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "OSMANİYE",
        "DISTRICT": "KADİRLİ",
        "STREET": "DURMUŞSOFULAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "OSMANİYE",
        "DISTRICT": "KADİRLİ",
        "STREET": "ERDOĞDU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "OSMANİYE",
        "DISTRICT": "KADİRLİ",
        "STREET": "GÖZTAŞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "OSMANİYE",
        "DISTRICT": "KADİRLİ",
        "STREET": "HACIHALİLOĞLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "OSMANİYE",
        "DISTRICT": "KADİRLİ",
        "STREET": "HALİTAĞALAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "OSMANİYE",
        "DISTRICT": "KADİRLİ",
        "STREET": "HARDALLIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "OSMANİYE",
        "DISTRICT": "KADİRLİ",
        "STREET": "HARKAŞTIĞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "OSMANİYE",
        "DISTRICT": "KADİRLİ",
        "STREET": "KABAYAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "OSMANİYE",
        "DISTRICT": "KADİRLİ",
        "STREET": "KARABACAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "OSMANİYE",
        "DISTRICT": "KADİRLİ",
        "STREET": "KARAKÜTÜK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "OSMANİYE",
        "DISTRICT": "KADİRLİ",
        "STREET": "KARATEPE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "OSMANİYE",
        "DISTRICT": "KADİRLİ",
        "STREET": "KAYASUYU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "OSMANİYE",
        "DISTRICT": "KADİRLİ",
        "STREET": "KERİMLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "OSMANİYE",
        "DISTRICT": "KADİRLİ",
        "STREET": "KESİKKELİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "OSMANİYE",
        "DISTRICT": "KADİRLİ",
        "STREET": "KESİM KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "OSMANİYE",
        "DISTRICT": "KADİRLİ",
        "STREET": "KIZYUSUFLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "OSMANİYE",
        "DISTRICT": "KADİRLİ",
        "STREET": "KOÇLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "OSMANİYE",
        "DISTRICT": "KADİRLİ",
        "STREET": "KURTULUŞ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "OSMANİYE",
        "DISTRICT": "KADİRLİ",
        "STREET": "KÖSEPINARI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "OSMANİYE",
        "DISTRICT": "KADİRLİ",
        "STREET": "KÜMBET KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "OSMANİYE",
        "DISTRICT": "KADİRLİ",
        "STREET": "KİREMİTLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "OSMANİYE",
        "DISTRICT": "KADİRLİ",
        "STREET": "MECİDİYE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "OSMANİYE",
        "DISTRICT": "KADİRLİ",
        "STREET": "MEHEDİNLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "OSMANİYE",
        "DISTRICT": "KADİRLİ",
        "STREET": "MEZRETLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "OSMANİYE",
        "DISTRICT": "KADİRLİ",
        "STREET": "NARLIKIŞLA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "OSMANİYE",
        "DISTRICT": "KADİRLİ",
        "STREET": "ORUÇBEY KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "OSMANİYE",
        "DISTRICT": "KADİRLİ",
        "STREET": "PAZAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "OSMANİYE",
        "DISTRICT": "KADİRLİ",
        "STREET": "SARITANIŞMANLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "OSMANİYE",
        "DISTRICT": "KADİRLİ",
        "STREET": "SAVRUN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "OSMANİYE",
        "DISTRICT": "KADİRLİ",
        "STREET": "SOFULAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "OSMANİYE",
        "DISTRICT": "KADİRLİ",
        "STREET": "SÖĞÜTLÜDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "OSMANİYE",
        "DISTRICT": "KADİRLİ",
        "STREET": "TAHTA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "OSMANİYE",
        "DISTRICT": "KADİRLİ",
        "STREET": "TATARLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "OSMANİYE",
        "DISTRICT": "KADİRLİ",
        "STREET": "TEKELİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "OSMANİYE",
        "DISTRICT": "KADİRLİ",
        "STREET": "TOPRAKTEPE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "OSMANİYE",
        "DISTRICT": "KADİRLİ",
        "STREET": "TOZLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "OSMANİYE",
        "DISTRICT": "KADİRLİ",
        "STREET": "TUFANPAŞA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "OSMANİYE",
        "DISTRICT": "KADİRLİ",
        "STREET": "VAYVAYLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "OSMANİYE",
        "DISTRICT": "KADİRLİ",
        "STREET": "YALNIZDUT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "OSMANİYE",
        "DISTRICT": "KADİRLİ",
        "STREET": "YENİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "OSMANİYE",
        "DISTRICT": "KADİRLİ",
        "STREET": "YENİGÜN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "OSMANİYE",
        "DISTRICT": "KADİRLİ",
        "STREET": "YENİKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "OSMANİYE",
        "DISTRICT": "KADİRLİ",
        "STREET": "YEŞİLTEPE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "OSMANİYE",
        "DISTRICT": "KADİRLİ",
        "STREET": "YOĞUNOLUK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "OSMANİYE",
        "DISTRICT": "KADİRLİ",
        "STREET": "YUKARIBOZKUYU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "OSMANİYE",
        "DISTRICT": "KADİRLİ",
        "STREET": "YUKARIÇİYANLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "OSMANİYE",
        "DISTRICT": "KADİRLİ",
        "STREET": "YUSUFİZZETTİN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "OSMANİYE",
        "DISTRICT": "KADİRLİ",
        "STREET": "ÇAYGEÇİT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "OSMANİYE",
        "DISTRICT": "KADİRLİ",
        "STREET": "ÇINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "OSMANİYE",
        "DISTRICT": "KADİRLİ",
        "STREET": "ÇUKURKÖPRÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "OSMANİYE",
        "DISTRICT": "KADİRLİ",
        "STREET": "ÇİĞCİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "OSMANİYE",
        "DISTRICT": "KADİRLİ",
        "STREET": "ÇİĞDEMLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "OSMANİYE",
        "DISTRICT": "KADİRLİ",
        "STREET": "ÖKSÜZLÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "OSMANİYE",
        "DISTRICT": "KADİRLİ",
        "STREET": "İLBİSTANLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "OSMANİYE",
        "DISTRICT": "KADİRLİ",
        "STREET": "ŞABAPLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "OSMANİYE",
        "DISTRICT": "KADİRLİ",
        "STREET": "ŞEHİT HALİS ŞİŞMAN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "OSMANİYE",
        "DISTRICT": "KADİRLİ",
        "STREET": "ŞEHİT KANSU KÜÇÜKATEŞ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "OSMANİYE",
        "DISTRICT": "KADİRLİ",
        "STREET": "ŞEHİT MEHMET DELİKUŞ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "OSMANİYE",
        "DISTRICT": "KADİRLİ",
        "STREET": "ŞEHİT MEHMET HALLAÇ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "OSMANİYE",
        "DISTRICT": "KADİRLİ",
        "STREET": "ŞEHİT MUSTAFA YAĞIZ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "OSMANİYE",
        "DISTRICT": "KADİRLİ",
        "STREET": "ŞEHİT ORHAN GÖK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "OSMANİYE",
        "DISTRICT": "KADİRLİ",
        "STREET": "ŞEHİT VEDAT  KOCADALLI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "OSMANİYE",
        "DISTRICT": "KADİRLİ",
        "STREET": "ŞEHİT İBRAHİM KUNDAKÇI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "OSMANİYE",
        "DISTRICT": "MERKEZ",
        "STREET": "ADNAN MENDERES MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "OSMANİYE",
        "DISTRICT": "MERKEZ",
        "STREET": "AHMET YESEVİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "OSMANİYE",
        "DISTRICT": "MERKEZ",
        "STREET": "AKYAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "OSMANİYE",
        "DISTRICT": "MERKEZ",
        "STREET": "ALİ BEKİRLİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "OSMANİYE",
        "DISTRICT": "MERKEZ",
        "STREET": "ALİBEYLİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "OSMANİYE",
        "DISTRICT": "MERKEZ",
        "STREET": "ARSLANLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "OSMANİYE",
        "DISTRICT": "MERKEZ",
        "STREET": "BAHÇE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "OSMANİYE",
        "DISTRICT": "MERKEZ",
        "STREET": "BAŞ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "OSMANİYE",
        "DISTRICT": "MERKEZ",
        "STREET": "CEVDETİYE-CUMHURİYET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "OSMANİYE",
        "DISTRICT": "MERKEZ",
        "STREET": "CEVDETİYE-KARATAŞ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "OSMANİYE",
        "DISTRICT": "MERKEZ",
        "STREET": "CEVDETİYE-KOCATEPE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "OSMANİYE",
        "DISTRICT": "MERKEZ",
        "STREET": "CEVDETİYE-YEŞİLTEPE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "OSMANİYE",
        "DISTRICT": "MERKEZ",
        "STREET": "CUMHURİYET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "OSMANİYE",
        "DISTRICT": "MERKEZ",
        "STREET": "DERELİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "OSMANİYE",
        "DISTRICT": "MERKEZ",
        "STREET": "DEREOBASI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "OSMANİYE",
        "DISTRICT": "MERKEZ",
        "STREET": "DERVİŞLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "OSMANİYE",
        "DISTRICT": "MERKEZ",
        "STREET": "DEĞİRMEN OCAĞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "OSMANİYE",
        "DISTRICT": "MERKEZ",
        "STREET": "DR.İHSAN GÖKNAL MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "OSMANİYE",
        "DISTRICT": "MERKEZ",
        "STREET": "DUMLUPINAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "OSMANİYE",
        "DISTRICT": "MERKEZ",
        "STREET": "ESENEVLER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "OSMANİYE",
        "DISTRICT": "MERKEZ",
        "STREET": "EYÜP SULTAN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "OSMANİYE",
        "DISTRICT": "MERKEZ",
        "STREET": "FAKIUŞAĞI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "OSMANİYE",
        "DISTRICT": "MERKEZ",
        "STREET": "FATİH MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "OSMANİYE",
        "DISTRICT": "MERKEZ",
        "STREET": "GEBELİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "OSMANİYE",
        "DISTRICT": "MERKEZ",
        "STREET": "HACI OSMANLI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "OSMANİYE",
        "DISTRICT": "MERKEZ",
        "STREET": "HARAZ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "OSMANİYE",
        "DISTRICT": "MERKEZ",
        "STREET": "HEMİTE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "OSMANİYE",
        "DISTRICT": "MERKEZ",
        "STREET": "ISSIZCA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "OSMANİYE",
        "DISTRICT": "MERKEZ",
        "STREET": "KARACALAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "OSMANİYE",
        "DISTRICT": "MERKEZ",
        "STREET": "KARACAY MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "OSMANİYE",
        "DISTRICT": "MERKEZ",
        "STREET": "KARATAŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "OSMANİYE",
        "DISTRICT": "MERKEZ",
        "STREET": "KAYALI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "OSMANİYE",
        "DISTRICT": "MERKEZ",
        "STREET": "KAZIM KARABEKİR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "OSMANİYE",
        "DISTRICT": "MERKEZ",
        "STREET": "KAZMACA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "OSMANİYE",
        "DISTRICT": "MERKEZ",
        "STREET": "KESMEBURUN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "OSMANİYE",
        "DISTRICT": "MERKEZ",
        "STREET": "KIRIKLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "OSMANİYE",
        "DISTRICT": "MERKEZ",
        "STREET": "KIRMACILI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "OSMANİYE",
        "DISTRICT": "MERKEZ",
        "STREET": "KIRMITLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "OSMANİYE",
        "DISTRICT": "MERKEZ",
        "STREET": "KORKUT ATA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "OSMANİYE",
        "DISTRICT": "MERKEZ",
        "STREET": "KOÇYURDU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "OSMANİYE",
        "DISTRICT": "MERKEZ",
        "STREET": "KUMARLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "OSMANİYE",
        "DISTRICT": "MERKEZ",
        "STREET": "KURTULUŞ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "OSMANİYE",
        "DISTRICT": "MERKEZ",
        "STREET": "KÖYYERİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "OSMANİYE",
        "DISTRICT": "MERKEZ",
        "STREET": "KÜLLÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "OSMANİYE",
        "DISTRICT": "MERKEZ",
        "STREET": "M.AKİF ERSOY MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "OSMANİYE",
        "DISTRICT": "MERKEZ",
        "STREET": "M.FEVZİ ÇAKMAK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "OSMANİYE",
        "DISTRICT": "MERKEZ",
        "STREET": "MEVLANA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "OSMANİYE",
        "DISTRICT": "MERKEZ",
        "STREET": "MİMAR SİNAN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "OSMANİYE",
        "DISTRICT": "MERKEZ",
        "STREET": "NOHUTTEPE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "OSMANİYE",
        "DISTRICT": "MERKEZ",
        "STREET": "ORHANİYE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "OSMANİYE",
        "DISTRICT": "MERKEZ",
        "STREET": "ORUÇGAZİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "OSMANİYE",
        "DISTRICT": "MERKEZ",
        "STREET": "RAHİME HATUN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "OSMANİYE",
        "DISTRICT": "MERKEZ",
        "STREET": "RAUFBEY MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "OSMANİYE",
        "DISTRICT": "MERKEZ",
        "STREET": "RIZAİYE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "OSMANİYE",
        "DISTRICT": "MERKEZ",
        "STREET": "SAKARCALIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "OSMANİYE",
        "DISTRICT": "MERKEZ",
        "STREET": "SAKIZGEDİĞİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "OSMANİYE",
        "DISTRICT": "MERKEZ",
        "STREET": "SARPINAĞZI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "OSMANİYE",
        "DISTRICT": "MERKEZ",
        "STREET": "SELİMİYE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "OSMANİYE",
        "DISTRICT": "MERKEZ",
        "STREET": "SELİMİYE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "OSMANİYE",
        "DISTRICT": "MERKEZ",
        "STREET": "SERDAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "OSMANİYE",
        "DISTRICT": "MERKEZ",
        "STREET": "SERİNOVA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "OSMANİYE",
        "DISTRICT": "MERKEZ",
        "STREET": "TEHÇİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "OSMANİYE",
        "DISTRICT": "MERKEZ",
        "STREET": "ULAŞLI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "OSMANİYE",
        "DISTRICT": "MERKEZ",
        "STREET": "VATAN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "OSMANİYE",
        "DISTRICT": "MERKEZ",
        "STREET": "YARPUZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "OSMANİYE",
        "DISTRICT": "MERKEZ",
        "STREET": "YAVERPAŞA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "OSMANİYE",
        "DISTRICT": "MERKEZ",
        "STREET": "YEDİ OCAK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "OSMANİYE",
        "DISTRICT": "MERKEZ",
        "STREET": "YENİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "OSMANİYE",
        "DISTRICT": "MERKEZ",
        "STREET": "YENİKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "OSMANİYE",
        "DISTRICT": "MERKEZ",
        "STREET": "YEŞİL YURT MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "OSMANİYE",
        "DISTRICT": "MERKEZ",
        "STREET": "YILDIRIM BEYAZIT MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "OSMANİYE",
        "DISTRICT": "MERKEZ",
        "STREET": "YUNUS EMRE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "OSMANİYE",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇARDAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "OSMANİYE",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇAĞŞAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "OSMANİYE",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇONA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "OSMANİYE",
        "DISTRICT": "MERKEZ",
        "STREET": "İSTİKLAL MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "OSMANİYE",
        "DISTRICT": "MERKEZ",
        "STREET": "ŞEKERDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "OSMANİYE",
        "DISTRICT": "MERKEZ",
        "STREET": "ŞİRİNEVLER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "OSMANİYE",
        "DISTRICT": "SUMBAS",
        "STREET": "AKDAM KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "OSMANİYE",
        "DISTRICT": "SUMBAS",
        "STREET": "AKÇATAŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "OSMANİYE",
        "DISTRICT": "SUMBAS",
        "STREET": "ALİBEYLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "OSMANİYE",
        "DISTRICT": "SUMBAS",
        "STREET": "DÖĞENLİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "OSMANİYE",
        "DISTRICT": "SUMBAS",
        "STREET": "ESENLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "OSMANİYE",
        "DISTRICT": "SUMBAS",
        "STREET": "GAFARLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "OSMANİYE",
        "DISTRICT": "SUMBAS",
        "STREET": "HÜYÜK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "OSMANİYE",
        "DISTRICT": "SUMBAS",
        "STREET": "KARAÖMERLİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "OSMANİYE",
        "DISTRICT": "SUMBAS",
        "STREET": "KIZILÖMERLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "OSMANİYE",
        "DISTRICT": "SUMBAS",
        "STREET": "KÖSELİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "OSMANİYE",
        "DISTRICT": "SUMBAS",
        "STREET": "KÜÇÜKÇINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "OSMANİYE",
        "DISTRICT": "SUMBAS",
        "STREET": "MEHMETLİ-ARMAĞANLI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "OSMANİYE",
        "DISTRICT": "SUMBAS",
        "STREET": "MEHMETLİ-CUMHURİYET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "OSMANİYE",
        "DISTRICT": "SUMBAS",
        "STREET": "MEHMETLİ-YENİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "OSMANİYE",
        "DISTRICT": "SUMBAS",
        "STREET": "REŞADİYE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "OSMANİYE",
        "DISTRICT": "SUMBAS",
        "STREET": "YAZIBOYU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "OSMANİYE",
        "DISTRICT": "SUMBAS",
        "STREET": "YEŞİLYAYLA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "OSMANİYE",
        "DISTRICT": "SUMBAS",
        "STREET": "ÇİÇEKLİDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "OSMANİYE",
        "DISTRICT": "TOPRAKKALE",
        "STREET": "ARSLANPINARI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "OSMANİYE",
        "DISTRICT": "TOPRAKKALE",
        "STREET": "DAGISTAN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "OSMANİYE",
        "DISTRICT": "TOPRAKKALE",
        "STREET": "FATIH SULTAN MEHMET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "OSMANİYE",
        "DISTRICT": "TOPRAKKALE",
        "STREET": "GÖÇMENLER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "OSMANİYE",
        "DISTRICT": "TOPRAKKALE",
        "STREET": "KARATAŞ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "OSMANİYE",
        "DISTRICT": "TOPRAKKALE",
        "STREET": "KIŞLA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "OSMANİYE",
        "DISTRICT": "TOPRAKKALE",
        "STREET": "KUMARLI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "OSMANİYE",
        "DISTRICT": "TOPRAKKALE",
        "STREET": "LALEGÖLÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "OSMANİYE",
        "DISTRICT": "TOPRAKKALE",
        "STREET": "SAYHÜYÜK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "OSMANİYE",
        "DISTRICT": "TOPRAKKALE",
        "STREET": "SAZLIK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "OSMANİYE",
        "DISTRICT": "TOPRAKKALE",
        "STREET": "TEPE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "OSMANİYE",
        "DISTRICT": "TOPRAKKALE",
        "STREET": "TÜRKMEN-BÜYÜKTÜRKMEN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "OSMANİYE",
        "DISTRICT": "TOPRAKKALE",
        "STREET": "TÜRKMEN-CUMHURİYET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "OSMANİYE",
        "DISTRICT": "TOPRAKKALE",
        "STREET": "TÜRKMEN-FATİH MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "OSMANİYE",
        "DISTRICT": "TOPRAKKALE",
        "STREET": "ÇAMLIKEVLER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "OSMANİYE",
        "DISTRICT": "TOPRAKKALE - TÜRKMEN",
        "STREET": "T-1TİPİ CEZAEVİ",
        "TYPE": "0"
      },
      {
        "CITY": "OSMANİYE",
        "DISTRICT": "TOPRAKKALE - TÜRKMEN",
        "STREET": "T-2 TİPİ CEZAEVİ",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "ARDEŞEN",
        "STREET": "AKDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "ARDEŞEN",
        "STREET": "AKKAYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "ARDEŞEN",
        "STREET": "ARICILAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "ARDEŞEN",
        "STREET": "ARMAĞAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "ARDEŞEN",
        "STREET": "AŞAĞIDURAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "ARDEŞEN",
        "STREET": "BAHAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "ARDEŞEN",
        "STREET": "BARIŞ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "ARDEŞEN",
        "STREET": "BAYIRCIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "ARDEŞEN",
        "STREET": "BAŞ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "ARDEŞEN",
        "STREET": "BEYAZKAYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "ARDEŞEN",
        "STREET": "CAMİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "ARDEŞEN",
        "STREET": "CUMHURİYET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "ARDEŞEN",
        "STREET": "DENİZ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "ARDEŞEN",
        "STREET": "DEREMEZRA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "ARDEŞEN",
        "STREET": "DOĞANAY KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "ARDEŞEN",
        "STREET": "DUYGULU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "ARDEŞEN",
        "STREET": "DÜZ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "ARDEŞEN",
        "STREET": "ELMALIK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "ARDEŞEN",
        "STREET": "ESKİARMUTLUK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "ARDEŞEN",
        "STREET": "FATİH MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "ARDEŞEN",
        "STREET": "FIRTINA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "ARDEŞEN",
        "STREET": "GÜNDOĞAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "ARDEŞEN",
        "STREET": "GÜNEY KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "ARDEŞEN",
        "STREET": "HOŞDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "ARDEŞEN",
        "STREET": "IŞIKLI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "ARDEŞEN",
        "STREET": "KAHVECİLER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "ARDEŞEN",
        "STREET": "KAVAKLIDERE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "ARDEŞEN",
        "STREET": "KAÇKAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "ARDEŞEN",
        "STREET": "KONAKTEPE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "ARDEŞEN",
        "STREET": "KURTULUŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "ARDEŞEN",
        "STREET": "KUZEY MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "ARDEŞEN",
        "STREET": "KÖPRÜKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "ARDEŞEN",
        "STREET": "KÜÇÜKKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "ARDEŞEN",
        "STREET": "KİRAZLIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "ARDEŞEN",
        "STREET": "MANGANEZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "ARDEŞEN",
        "STREET": "MERKEZ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "ARDEŞEN",
        "STREET": "MÜFTÜ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "ARDEŞEN",
        "STREET": "ORTAALAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "ARDEŞEN",
        "STREET": "PINARLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "ARDEŞEN",
        "STREET": "PİRİNÇLİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "ARDEŞEN",
        "STREET": "SERİNDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "ARDEŞEN",
        "STREET": "SESLİKAYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "ARDEŞEN",
        "STREET": "SİNAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "ARDEŞEN",
        "STREET": "TAŞBAŞI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "ARDEŞEN",
        "STREET": "TUNCA-ADA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "ARDEŞEN",
        "STREET": "TUNCA-DÜZ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "ARDEŞEN",
        "STREET": "TUNCA-ESENTEPE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "ARDEŞEN",
        "STREET": "TUNCA-KONAK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "ARDEŞEN",
        "STREET": "TUNCA-MERKEZ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "ARDEŞEN",
        "STREET": "TUNCA-ORTA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "ARDEŞEN",
        "STREET": "TUNCA-ŞENYUVA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "ARDEŞEN",
        "STREET": "YAMAÇDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "ARDEŞEN",
        "STREET": "YAVUZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "ARDEŞEN",
        "STREET": "YAVUZ SELİM MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "ARDEŞEN",
        "STREET": "YAYLA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "ARDEŞEN",
        "STREET": "YENİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "ARDEŞEN",
        "STREET": "YENİKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "ARDEŞEN",
        "STREET": "YENİYOL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "ARDEŞEN",
        "STREET": "YEŞİLTEPE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "ARDEŞEN",
        "STREET": "YUKARIDURAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "ARDEŞEN",
        "STREET": "YURTSEVER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "ARDEŞEN",
        "STREET": "ZEYTİNLİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "ARDEŞEN",
        "STREET": "ÇIRAKLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "ARDEŞEN",
        "STREET": "ÇİFTEKAVAK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "ARDEŞEN",
        "STREET": "ÖNDER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "ARDEŞEN",
        "STREET": "ÖZGÜR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "ARDEŞEN",
        "STREET": "ŞEHİTLİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "ARDEŞEN",
        "STREET": "ŞENDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "ARDEŞEN",
        "STREET": "ŞENTEPE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "ARDEŞEN",
        "STREET": "ŞENYAMAÇ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "ARDEŞEN",
        "STREET": "ŞENYURT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "DEREPAZARI",
        "STREET": "BAHATTİNPAŞA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "DEREPAZARI",
        "STREET": "BÜRÜCEK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "DEREPAZARI",
        "STREET": "ERİKLİMANI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "DEREPAZARI",
        "STREET": "ESENTEPE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "DEREPAZARI",
        "STREET": "FIÇICILAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "DEREPAZARI",
        "STREET": "KİRAZDAĞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "DEREPAZARI",
        "STREET": "MALTEPE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "DEREPAZARI",
        "STREET": "MERKEZ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "DEREPAZARI",
        "STREET": "SANDIKTAŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "DEREPAZARI",
        "STREET": "SARIYER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "DEREPAZARI",
        "STREET": "SUBAŞI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "DEREPAZARI",
        "STREET": "TERSANE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "DEREPAZARI",
        "STREET": "UZUNKAYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "DEREPAZARI",
        "STREET": "YANIKTAŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "DEREPAZARI",
        "STREET": "ÇAKMAKÇILAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "DEREPAZARI",
        "STREET": "ÇALIŞKANLAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "DEREPAZARI",
        "STREET": "ÇEŞME KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "DEREPAZARI",
        "STREET": "ÇUKURLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "FINDIKLI",
        "STREET": "AKSU MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "FINDIKLI",
        "STREET": "ARILI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "FINDIKLI",
        "STREET": "ASLANDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "FINDIKLI",
        "STREET": "AVCILAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "FINDIKLI",
        "STREET": "BEYDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "FINDIKLI",
        "STREET": "CENNET KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "FINDIKLI",
        "STREET": "DERBENT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "FINDIKLI",
        "STREET": "DOĞANAY KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "FINDIKLI",
        "STREET": "DÜZKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "FINDIKLI",
        "STREET": "GÜRSU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "FINDIKLI",
        "STREET": "HARA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "FINDIKLI",
        "STREET": "HÜRRİYET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "FINDIKLI",
        "STREET": "IHLAMURLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "FINDIKLI",
        "STREET": "ILICA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "FINDIKLI",
        "STREET": "KARAALİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "FINDIKLI",
        "STREET": "KIYICIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "FINDIKLI",
        "STREET": "LİMAN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "FINDIKLI",
        "STREET": "MERKEZ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "FINDIKLI",
        "STREET": "MEYVALI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "FINDIKLI",
        "STREET": "SAATKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "FINDIKLI",
        "STREET": "SAHİL MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "FINDIKLI",
        "STREET": "SULAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "FINDIKLI",
        "STREET": "SÜMER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "FINDIKLI",
        "STREET": "TATLISU MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "FINDIKLI",
        "STREET": "TEPECİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "FINDIKLI",
        "STREET": "YAYLACILAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "FINDIKLI",
        "STREET": "YENİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "FINDIKLI",
        "STREET": "YENİKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "FINDIKLI",
        "STREET": "YENİŞEHİTLİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "FINDIKLI",
        "STREET": "ÇAĞLAYAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "FINDIKLI",
        "STREET": "ÇINARLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "GÜNEYSU",
        "STREET": "ADACAMİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "GÜNEYSU",
        "STREET": "ASMALIIRMAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "GÜNEYSU",
        "STREET": "AŞAĞI KİREMİT MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "GÜNEYSU",
        "STREET": "BALLIDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "GÜNEYSU",
        "STREET": "BAŞARAN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "GÜNEYSU",
        "STREET": "BAŞKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "GÜNEYSU",
        "STREET": "BULUTLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "GÜNEYSU",
        "STREET": "BİRLİK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "GÜNEYSU",
        "STREET": "DUMANKAYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "GÜNEYSU",
        "STREET": "ESKİCAMİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "GÜNEYSU",
        "STREET": "GÜNELİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "GÜNEYSU",
        "STREET": "GÜRGEN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "GÜNEYSU",
        "STREET": "KIBLEDAĞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "GÜNEYSU",
        "STREET": "KÜÇÜKCAMİİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "GÜNEYSU",
        "STREET": "KİREMİT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "GÜNEYSU",
        "STREET": "MERKEZ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "GÜNEYSU",
        "STREET": "ORTAKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "GÜNEYSU",
        "STREET": "SELAMET KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "GÜNEYSU",
        "STREET": "TEPEBAŞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "GÜNEYSU",
        "STREET": "ULUCAMİİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "GÜNEYSU",
        "STREET": "YARIMADA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "GÜNEYSU",
        "STREET": "YENİCAMİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "GÜNEYSU",
        "STREET": "YENİKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "GÜNEYSU",
        "STREET": "YEŞİLKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "GÜNEYSU",
        "STREET": "YEŞİLYURT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "GÜNEYSU",
        "STREET": "YUKARIİSLAHİYE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "GÜNEYSU",
        "STREET": "YÜKSEKKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "GÜNEYSU",
        "STREET": "ÇAMLICA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "GÜNEYSU",
        "STREET": "İSLAHİYE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "HEMŞİN",
        "STREET": "AKYAMAÇ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "HEMŞİN",
        "STREET": "BAHAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "HEMŞİN",
        "STREET": "BİLENKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "HEMŞİN",
        "STREET": "HİLAL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "HEMŞİN",
        "STREET": "KANTARLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "HEMŞİN",
        "STREET": "LEVENTKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "HEMŞİN",
        "STREET": "MUTLU MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "HEMŞİN",
        "STREET": "NURLUCA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "HEMŞİN",
        "STREET": "ORTAKÖY MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "HEMŞİN",
        "STREET": "YALTKAYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "HEMŞİN",
        "STREET": "YENİKÖY MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "HEMŞİN",
        "STREET": "ÇAMLITEPE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "KALKANDERE",
        "STREET": "ADALAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "KALKANDERE",
        "STREET": "AKSU MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "KALKANDERE",
        "STREET": "AŞAĞI TATLISU MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "KALKANDERE",
        "STREET": "CEVİZLİK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "KALKANDERE",
        "STREET": "DAĞDİBİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "KALKANDERE",
        "STREET": "DÜLGERLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "KALKANDERE",
        "STREET": "ESENDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "KALKANDERE",
        "STREET": "ESENTEPE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "KALKANDERE",
        "STREET": "FINDIKLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "KALKANDERE",
        "STREET": "GEÇİTLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "KALKANDERE",
        "STREET": "HURMALIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "KALKANDERE",
        "STREET": "HÜSEYİNHOCA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "KALKANDERE",
        "STREET": "KAYABAŞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "KALKANDERE",
        "STREET": "KIZILTOPRAK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "KALKANDERE",
        "STREET": "KURUKÖY MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "KALKANDERE",
        "STREET": "MEDRESE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "KALKANDERE",
        "STREET": "ORMANLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "KALKANDERE",
        "STREET": "PINARKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "KALKANDERE",
        "STREET": "RİZE KALKANDERE L TİPİ KAPALI CEZA İNFAZ KURUMU",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "KALKANDERE",
        "STREET": "SEYRANTEPE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "KALKANDERE",
        "STREET": "SOĞUKSU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "KALKANDERE",
        "STREET": "SOĞUKSU MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "KALKANDERE",
        "STREET": "TAŞÇILAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "KALKANDERE",
        "STREET": "YENİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "KALKANDERE",
        "STREET": "YENİGEÇİTLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "KALKANDERE",
        "STREET": "YENİKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "KALKANDERE",
        "STREET": "YEŞİLKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "KALKANDERE",
        "STREET": "YOKUŞLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "KALKANDERE",
        "STREET": "YOLBAŞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "KALKANDERE",
        "STREET": "YUKARI TATLISU MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "KALKANDERE",
        "STREET": "YUMURTATEPE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "KALKANDERE",
        "STREET": "ÇAYIRLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "KALKANDERE",
        "STREET": "ÇAĞLAYAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "KALKANDERE",
        "STREET": "ÜNALAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "KALKANDERE",
        "STREET": "İNCİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "PAZAR",
        "STREET": "AKBUCAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "PAZAR",
        "STREET": "AKMESCİT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "PAZAR",
        "STREET": "AKTAŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "PAZAR",
        "STREET": "AKTEPE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "PAZAR",
        "STREET": "ALÇILI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "PAZAR",
        "STREET": "BALIKÇI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "PAZAR",
        "STREET": "BAŞKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "PAZAR",
        "STREET": "BEYAZTAŞ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "PAZAR",
        "STREET": "BOĞAZLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "PAZAR",
        "STREET": "BUCAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "PAZAR",
        "STREET": "CUMHURİYET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "PAZAR",
        "STREET": "DARILI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "PAZAR",
        "STREET": "DAĞDİBİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "PAZAR",
        "STREET": "DEREBAŞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "PAZAR",
        "STREET": "DERNEK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "PAZAR",
        "STREET": "DERİNSU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "PAZAR",
        "STREET": "ELMALIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "PAZAR",
        "STREET": "GAZİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "PAZAR",
        "STREET": "GÜNEY KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "PAZAR",
        "STREET": "GÜZELYALI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "PAZAR",
        "STREET": "HAMİDİYE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "PAZAR",
        "STREET": "HANDAĞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "PAZAR",
        "STREET": "HASKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "PAZAR",
        "STREET": "HİSARLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "PAZAR",
        "STREET": "IRMAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "PAZAR",
        "STREET": "IRMAKYENİKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "PAZAR",
        "STREET": "KAYAĞANTAŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "PAZAR",
        "STREET": "KESİKKÖPRÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "PAZAR",
        "STREET": "KOCAKÖPRÜ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "PAZAR",
        "STREET": "KUZAYCA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "PAZAR",
        "STREET": "KİRAZLIK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "PAZAR",
        "STREET": "MERDİVENLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "PAZAR",
        "STREET": "OCAK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "PAZAR",
        "STREET": "ORTAIRMAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "PAZAR",
        "STREET": "ORTAYOL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "PAZAR",
        "STREET": "PAPATYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "PAZAR",
        "STREET": "PAZAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "PAZAR",
        "STREET": "SAHİLKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "PAZAR",
        "STREET": "SESSİZDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "PAZAR",
        "STREET": "SOĞUKSU MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "PAZAR",
        "STREET": "SUBAŞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "PAZAR",
        "STREET": "SULAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "PAZAR",
        "STREET": "SUÇATI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "PAZAR",
        "STREET": "SİVRİKALE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "PAZAR",
        "STREET": "SİVRİTEPE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "PAZAR",
        "STREET": "TEKTAŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "PAZAR",
        "STREET": "TOPLUCA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "PAZAR",
        "STREET": "TÜTÜNCÜLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "PAZAR",
        "STREET": "UĞRAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "PAZAR",
        "STREET": "YAVUZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "PAZAR",
        "STREET": "YEMİŞLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "PAZAR",
        "STREET": "YEŞİLKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "PAZAR",
        "STREET": "YUKARI SOĞUKSU MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "PAZAR",
        "STREET": "YÜCEHİSAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "PAZAR",
        "STREET": "ZAFER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "PAZAR",
        "STREET": "ÖRNEK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "PAZAR",
        "STREET": "İKİZTEPE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "PAZAR",
        "STREET": "ŞEHİTLİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "PAZAR",
        "STREET": "ŞENDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "PAZAR",
        "STREET": "ŞENTEPE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "MERKEZ",
        "STREET": "AKARSU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "MERKEZ",
        "STREET": "AKPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "MERKEZ",
        "STREET": "AKTAŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "MERKEZ",
        "STREET": "ALİPAŞA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "MERKEZ",
        "STREET": "AMBARLIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "MERKEZ",
        "STREET": "ATMEYDANI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "MERKEZ",
        "STREET": "AYANE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "MERKEZ",
        "STREET": "AZAKLIHOCA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "MERKEZ",
        "STREET": "BALIKÇILAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "MERKEZ",
        "STREET": "BALSU MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "MERKEZ",
        "STREET": "BAĞDATLI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "MERKEZ",
        "STREET": "BEŞTEPE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "MERKEZ",
        "STREET": "BILDIRCINKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "MERKEZ",
        "STREET": "BOZKALE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "MERKEZ",
        "STREET": "BOĞAZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "MERKEZ",
        "STREET": "BOĞAZ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "MERKEZ",
        "STREET": "CAMİDAĞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "MERKEZ",
        "STREET": "CAMİÖNÜ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "MERKEZ",
        "STREET": "CANPOLAT MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "MERKEZ",
        "STREET": "DAĞINIKSU MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "MERKEZ",
        "STREET": "DAĞSU MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "MERKEZ",
        "STREET": "DEREBAŞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "MERKEZ",
        "STREET": "DEREÜSTÜ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "MERKEZ",
        "STREET": "DEĞİRMENDERE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "MERKEZ",
        "STREET": "DÖRTYOL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "MERKEZ",
        "STREET": "DÜZKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "MERKEZ",
        "STREET": "EKMEKÇİLER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "MERKEZ",
        "STREET": "EKREM ORHON MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "MERKEZ",
        "STREET": "ELMALI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "MERKEZ",
        "STREET": "EMİNETTİN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "MERKEZ",
        "STREET": "ENGİNDERE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "MERKEZ",
        "STREET": "ERENKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "MERKEZ",
        "STREET": "FATİH MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "MERKEZ",
        "STREET": "FENER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "MERKEZ",
        "STREET": "GÖLGELİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "MERKEZ",
        "STREET": "GÜLBAHAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "MERKEZ",
        "STREET": "GÜZELKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "MERKEZ",
        "STREET": "GÜZELYURT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "MERKEZ",
        "STREET": "HALATÇILAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "MERKEZ",
        "STREET": "HAMZABEY MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "MERKEZ",
        "STREET": "HAMİDİYE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "MERKEZ",
        "STREET": "HAYRAT MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "MERKEZ",
        "STREET": "KALE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "MERKEZ",
        "STREET": "KANBURSIRT MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "MERKEZ",
        "STREET": "KAPLICA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "MERKEZ",
        "STREET": "KARASU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "MERKEZ",
        "STREET": "KARAYEMİŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "MERKEZ",
        "STREET": "KAVAKLI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "MERKEZ",
        "STREET": "KENDİRLİ-ASMALIK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "MERKEZ",
        "STREET": "KENDİRLİ-DÜZ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "MERKEZ",
        "STREET": "KENDİRLİ-HALASLAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "MERKEZ",
        "STREET": "KENDİRLİ-ORTA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "MERKEZ",
        "STREET": "KENDİRLİ-SIRT MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "MERKEZ",
        "STREET": "KETENLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "MERKEZ",
        "STREET": "KIRKLARTEPESİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "MERKEZ",
        "STREET": "KOCATEPE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "MERKEZ",
        "STREET": "KURTULUŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "MERKEZ",
        "STREET": "KÖPRÜLÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "MERKEZ",
        "STREET": "KÜÇÜKKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "MERKEZ",
        "STREET": "KÜÇÜKÇAYIR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "MERKEZ",
        "STREET": "MELEK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "MERKEZ",
        "STREET": "MERMERDELEN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "MERKEZ",
        "STREET": "MEZRA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "MERKEZ",
        "STREET": "MURADİYE-KÖMÜRCÜLER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "MERKEZ",
        "STREET": "MURADİYE-KÖPRÜBAŞI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "MERKEZ",
        "STREET": "MURADİYE-MESUDİYE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "MERKEZ",
        "STREET": "MURADİYE-ORTA MURADİYE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "MERKEZ",
        "STREET": "MURADİYE-SOĞUKSU MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "MERKEZ",
        "STREET": "MURADİYE-YEŞİLDERE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "MERKEZ",
        "STREET": "MÜFTÜ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "MERKEZ",
        "STREET": "ORTAPAZAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "MERKEZ",
        "STREET": "PAZARKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "MERKEZ",
        "STREET": "PAŞAKUYU MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "MERKEZ",
        "STREET": "PEHLİVAN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "MERKEZ",
        "STREET": "PEHLİVANTAŞI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "MERKEZ",
        "STREET": "PEKMEZLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "MERKEZ",
        "STREET": "PINARBAŞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "MERKEZ",
        "STREET": "PORTAKALLIK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "MERKEZ",
        "STREET": "PİLAVDAĞI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "MERKEZ",
        "STREET": "PİRİÇELEBİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "MERKEZ",
        "STREET": "REŞADİYE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "MERKEZ",
        "STREET": "SALARHA-GÜNEŞLİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "MERKEZ",
        "STREET": "SALARHA-GÜNEŞTEPE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "MERKEZ",
        "STREET": "SALARHA-KASARCILAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "MERKEZ",
        "STREET": "SALARHA-KOKULUKAYA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "MERKEZ",
        "STREET": "SALARHA-KİREÇHANE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "MERKEZ",
        "STREET": "SALARHA-MÜDERRİSLER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "MERKEZ",
        "STREET": "SALARHA-SIRT KASARCILAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "MERKEZ",
        "STREET": "SALARHA-YEŞİLILICA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "MERKEZ",
        "STREET": "SALARHA-ÜZÜMLÜ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "MERKEZ",
        "STREET": "SELİMİYE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "MERKEZ",
        "STREET": "SOĞUKÇEŞME KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "MERKEZ",
        "STREET": "SÖĞÜTLÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "MERKEZ",
        "STREET": "SÜTLÜCE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "MERKEZ",
        "STREET": "TAŞKÖPRÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "MERKEZ",
        "STREET": "TAŞLIDERE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "MERKEZ",
        "STREET": "TAŞLIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "MERKEZ",
        "STREET": "TAŞPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "MERKEZ",
        "STREET": "TEKKE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "MERKEZ",
        "STREET": "TOPHANE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "MERKEZ",
        "STREET": "TOPKAYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "MERKEZ",
        "STREET": "TUĞLALI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "MERKEZ",
        "STREET": "UZUNKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "MERKEZ",
        "STREET": "VELİKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "MERKEZ",
        "STREET": "YAĞLITAŞ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "MERKEZ",
        "STREET": "YEMİŞLİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "MERKEZ",
        "STREET": "YENİDOĞAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "MERKEZ",
        "STREET": "YENİGÜZELKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "MERKEZ",
        "STREET": "YENİKALE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "MERKEZ",
        "STREET": "YENİKASARCILAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "MERKEZ",
        "STREET": "YENİKÖY MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "MERKEZ",
        "STREET": "YENİSELİMİYE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "MERKEZ",
        "STREET": "YEŞİLDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "MERKEZ",
        "STREET": "YOLVEREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "MERKEZ",
        "STREET": "YOLÜSTÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "MERKEZ",
        "STREET": "ZİNCİRLİKÖPRÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇAMLIBEL MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇARŞI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇAYBAŞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇAYCILAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇORAPÇILAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇİFTEKAVAK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇİMENLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "MERKEZ",
        "STREET": "ÖRNEK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "MERKEZ",
        "STREET": "ÜÇKAYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "MERKEZ",
        "STREET": "İSLAMPAŞA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "ÇAMLIHEMŞİN",
        "STREET": "AŞAĞIÇAMLICA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "ÇAMLIHEMŞİN",
        "STREET": "AŞAĞIŞİMŞİRLİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "ÇAMLIHEMŞİN",
        "STREET": "BEHİCE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "ÇAMLIHEMŞİN",
        "STREET": "BOĞAZİÇİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "ÇAMLIHEMŞİN",
        "STREET": "DERECİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "ÇAMLIHEMŞİN",
        "STREET": "DİDİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "ÇAMLIHEMŞİN",
        "STREET": "DİKKAYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "ÇAMLIHEMŞİN",
        "STREET": "GÜLLÜKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "ÇAMLIHEMŞİN",
        "STREET": "GÜROLUK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "ÇAMLIHEMŞİN",
        "STREET": "KADIKÖY MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "ÇAMLIHEMŞİN",
        "STREET": "KALE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "ÇAMLIHEMŞİN",
        "STREET": "KAPLICA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "ÇAMLIHEMŞİN",
        "STREET": "KAVAK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "ÇAMLIHEMŞİN",
        "STREET": "KOMİLO KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "ÇAMLIHEMŞİN",
        "STREET": "KONAKLAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "ÇAMLIHEMŞİN",
        "STREET": "KÖPRÜBAŞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "ÇAMLIHEMŞİN",
        "STREET": "MERKEZ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "ÇAMLIHEMŞİN",
        "STREET": "MEYDANKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "ÇAMLIHEMŞİN",
        "STREET": "MOLLAVEYİS KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "ÇAMLIHEMŞİN",
        "STREET": "ORTAKLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "ÇAMLIHEMŞİN",
        "STREET": "ORTANKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "ÇAMLIHEMŞİN",
        "STREET": "ORTAYAYLA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "ÇAMLIHEMŞİN",
        "STREET": "SIRAKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "ÇAMLIHEMŞİN",
        "STREET": "SIRT MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "ÇAMLIHEMŞİN",
        "STREET": "TOPLUCA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "ÇAMLIHEMŞİN",
        "STREET": "YAYLAKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "ÇAMLIHEMŞİN",
        "STREET": "YAZLIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "ÇAMLIHEMŞİN",
        "STREET": "YAĞMURLU MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "ÇAMLIHEMŞİN",
        "STREET": "YOLKIYI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "ÇAMLIHEMŞİN",
        "STREET": "YUKARIÇAMLICA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "ÇAMLIHEMŞİN",
        "STREET": "YUKARIŞİMŞİRLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "ÇAMLIHEMŞİN",
        "STREET": "ZİLKALE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "ÇAMLIHEMŞİN",
        "STREET": "ÇATKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "ÇAMLIHEMŞİN",
        "STREET": "ÇAYIRDÜZÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "ÇAMLIHEMŞİN",
        "STREET": "ŞENKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "ÇAMLIHEMŞİN",
        "STREET": "ŞENYUVA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "ÇAYELİ",
        "STREET": "ABDULLAHHOCA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "ÇAYELİ",
        "STREET": "ADALAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "ÇAYELİ",
        "STREET": "ARMUTLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "ÇAYELİ",
        "STREET": "AŞIKLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "ÇAYELİ",
        "STREET": "BAŞKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "ÇAYELİ",
        "STREET": "BEYAZSU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "ÇAYELİ",
        "STREET": "BEŞİKÇİLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "ÇAYELİ",
        "STREET": "BUZLUPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "ÇAYELİ",
        "STREET": "BÜYÜKCAFERPAŞA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "ÇAYELİ",
        "STREET": "BÜYÜKKÖY-ALTINTAŞ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "ÇAYELİ",
        "STREET": "BÜYÜKKÖY-ARKADERE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "ÇAYELİ",
        "STREET": "BÜYÜKKÖY-DEMİRCİLER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "ÇAYELİ",
        "STREET": "BÜYÜKKÖY-GÜMÜŞTAŞ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "ÇAYELİ",
        "STREET": "BÜYÜKKÖY-MERKEZ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "ÇAYELİ",
        "STREET": "BÜYÜKKÖY-SOĞUKSU MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "ÇAYELİ",
        "STREET": "BÜYÜKKÖY-ÇAVUŞLU MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "ÇAYELİ",
        "STREET": "BÜYÜKKÖY-ÇAYCILAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "ÇAYELİ",
        "STREET": "BÜYÜKTAŞHANE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "ÇAYELİ",
        "STREET": "DEMİRHİSAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "ÇAYELİ",
        "STREET": "DERECİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "ÇAYELİ",
        "STREET": "DÜZGEÇİT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "ÇAYELİ",
        "STREET": "ERDEMLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "ÇAYELİ",
        "STREET": "ERENLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "ÇAYELİ",
        "STREET": "ESENDAĞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "ÇAYELİ",
        "STREET": "ESKİPAZAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "ÇAYELİ",
        "STREET": "GEMİCİLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "ÇAYELİ",
        "STREET": "GÜRGENLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "ÇAYELİ",
        "STREET": "GÜRPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "ÇAYELİ",
        "STREET": "GÜZELTEPE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "ÇAYELİ",
        "STREET": "HAREMTEPE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "ÇAYELİ",
        "STREET": "HASSADIKLAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "ÇAYELİ",
        "STREET": "KAPTANPAŞA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "ÇAYELİ",
        "STREET": "KARAAĞAÇ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "ÇAYELİ",
        "STREET": "KAÇKAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "ÇAYELİ",
        "STREET": "KEMERKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "ÇAYELİ",
        "STREET": "KESMETAŞ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "ÇAYELİ",
        "STREET": "KESTANELİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "ÇAYELİ",
        "STREET": "KÖPRÜBAŞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "ÇAYELİ",
        "STREET": "KÜÇÜKCAFERPAŞA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "ÇAYELİ",
        "STREET": "KÜÇÜKTAŞHANE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "ÇAYELİ",
        "STREET": "LATİFLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "ÇAYELİ",
        "STREET": "LİMANKÖY MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "ÇAYELİ",
        "STREET": "MADENLİ-ALOĞLU MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "ÇAYELİ",
        "STREET": "MADENLİ-GÜRÇAM MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "ÇAYELİ",
        "STREET": "MADENLİ-HABİBOĞLU MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "ÇAYELİ",
        "STREET": "MADENLİ-KAZANCILAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "ÇAYELİ",
        "STREET": "MADENLİ-MADEN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "ÇAYELİ",
        "STREET": "MADENLİ-MERKEZ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "ÇAYELİ",
        "STREET": "MADENLİ-TOPKAYA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "ÇAYELİ",
        "STREET": "MADENLİ-YAZICILAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "ÇAYELİ",
        "STREET": "MADENLİ-YENİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "ÇAYELİ",
        "STREET": "MADENLİ-ÇAMLICA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "ÇAYELİ",
        "STREET": "MALTEPE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "ÇAYELİ",
        "STREET": "MUSADAĞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "ÇAYELİ",
        "STREET": "ORMANCIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "ÇAYELİ",
        "STREET": "ORTAKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "ÇAYELİ",
        "STREET": "SABUNCULAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "ÇAYELİ",
        "STREET": "SARISU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "ÇAYELİ",
        "STREET": "SEFALI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "ÇAYELİ",
        "STREET": "SELİMİYE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "ÇAYELİ",
        "STREET": "SESLİDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "ÇAYELİ",
        "STREET": "SIRTKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "ÇAYELİ",
        "STREET": "TAŞHANE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "ÇAYELİ",
        "STREET": "UZUNDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "ÇAYELİ",
        "STREET": "YAKA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "ÇAYELİ",
        "STREET": "YALI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "ÇAYELİ",
        "STREET": "YAMAÇ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "ÇAYELİ",
        "STREET": "YANIKDAĞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "ÇAYELİ",
        "STREET": "YAVUZLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "ÇAYELİ",
        "STREET": "YENİCAMİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "ÇAYELİ",
        "STREET": "YENİCE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "ÇAYELİ",
        "STREET": "YENİHİSAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "ÇAYELİ",
        "STREET": "YENİPAZAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "ÇAYELİ",
        "STREET": "YENİTEPE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "ÇAYELİ",
        "STREET": "YEŞİLIRMAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "ÇAYELİ",
        "STREET": "YEŞİLKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "ÇAYELİ",
        "STREET": "YEŞİLTEPE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "ÇAYELİ",
        "STREET": "YILDIZELİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "ÇAYELİ",
        "STREET": "ZAFER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "ÇAYELİ",
        "STREET": "ÇATAKLIHOCA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "ÇAYELİ",
        "STREET": "ÇATALDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "ÇAYELİ",
        "STREET": "ÇEŞMELİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "ÇAYELİ",
        "STREET": "ÇINARTEPE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "ÇAYELİ",
        "STREET": "ÇUKURLUHOCA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "ÇAYELİ",
        "STREET": "ÇİLİNGİR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "ÇAYELİ",
        "STREET": "İNCESIRT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "ÇAYELİ",
        "STREET": "İNCESU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "ÇAYELİ",
        "STREET": "ŞAİRLER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "ÇAYELİ",
        "STREET": "ŞİRİNKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "İKİZDERE",
        "STREET": "AYVALIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "İKİZDERE",
        "STREET": "BALLIKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "İKİZDERE",
        "STREET": "BAYIRKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "İKİZDERE",
        "STREET": "BAŞKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "İKİZDERE",
        "STREET": "CEVİZLİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "İKİZDERE",
        "STREET": "DEMİRKAPI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "İKİZDERE",
        "STREET": "DEREKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "İKİZDERE",
        "STREET": "DİKTAŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "İKİZDERE",
        "STREET": "ESKİCE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "İKİZDERE",
        "STREET": "GÖLYAYLA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "İKİZDERE",
        "STREET": "GÜNDOĞDU MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "İKİZDERE",
        "STREET": "GÜNEY MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "İKİZDERE",
        "STREET": "GÜNEYCE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "İKİZDERE",
        "STREET": "GÜRDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "İKİZDERE",
        "STREET": "GÜVENKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "İKİZDERE",
        "STREET": "IHLAMUR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "İKİZDERE",
        "STREET": "ILICAKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "İKİZDERE",
        "STREET": "KAMA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "İKİZDERE",
        "STREET": "KİRAZLI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "İKİZDERE",
        "STREET": "MEŞEKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "İKİZDERE",
        "STREET": "ORTAKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "İKİZDERE",
        "STREET": "RÜZGARLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "İKİZDERE",
        "STREET": "SİVRİKAYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "İKİZDERE",
        "STREET": "TOZKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "İKİZDERE",
        "STREET": "TULUMPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "İKİZDERE",
        "STREET": "YAĞCILAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "İKİZDERE",
        "STREET": "YERELMA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "İKİZDERE",
        "STREET": "YEŞİLYURT MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "İKİZDERE",
        "STREET": "ZAFER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "İKİZDERE",
        "STREET": "ÇAMLIKKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "İKİZDERE",
        "STREET": "ÇARŞI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "İKİZDERE",
        "STREET": "ÇATALTEPE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "İKİZDERE",
        "STREET": "ÇAĞRANKAYA  MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "İKİZDERE",
        "STREET": "ÇİFTEKÖPRÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "İKİZDERE",
        "STREET": "ÇİÇEKLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "İKİZDERE",
        "STREET": "ŞİMŞİRLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "İYİDERE",
        "STREET": "BÜYÜKÇİFTLİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "İYİDERE",
        "STREET": "DENİZGÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "İYİDERE",
        "STREET": "FETHİYE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "İYİDERE",
        "STREET": "FIÇITAŞI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "İYİDERE",
        "STREET": "HAZAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "İYİDERE",
        "STREET": "KALECİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "İYİDERE",
        "STREET": "KÖŞKLÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "İYİDERE",
        "STREET": "MERKEZ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "İYİDERE",
        "STREET": "SARAYKÖY MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "İYİDERE",
        "STREET": "TAŞHANE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "İYİDERE",
        "STREET": "YALIKÖY MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "İYİDERE",
        "STREET": "YAPRAKLAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "İYİDERE",
        "STREET": "YAYLACILAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "İYİDERE",
        "STREET": "ÇANAKÇEŞME MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "İYİDERE",
        "STREET": "ÇİFTLİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "RİZE",
        "DISTRICT": "İYİDERE",
        "STREET": "ÜSTÜPİLER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "ADAPAZARI",
        "STREET": "15 TEMMUZ CAMİLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "ADAPAZARI",
        "STREET": "ABALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "ADAPAZARI",
        "STREET": "ACIELMALIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "ADAPAZARI",
        "STREET": "AKINCILAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "ADAPAZARI",
        "STREET": "ALANDÜZÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "ADAPAZARI",
        "STREET": "AŞIRLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "ADAPAZARI",
        "STREET": "BAYRAKTAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "ADAPAZARI",
        "STREET": "BAĞLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "ADAPAZARI",
        "STREET": "BUDAKLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "ADAPAZARI",
        "STREET": "BÜYÜKHATAPLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "ADAPAZARI",
        "STREET": "BİLECİLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "ADAPAZARI",
        "STREET": "CUMHURİYET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "ADAPAZARI",
        "STREET": "DAĞDİBİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "ADAPAZARI",
        "STREET": "DEMİRBEY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "ADAPAZARI",
        "STREET": "DOĞANCILAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "ADAPAZARI",
        "STREET": "ELMALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "ADAPAZARI",
        "STREET": "EVRENKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "ADAPAZARI",
        "STREET": "GÖKTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "ADAPAZARI",
        "STREET": "GÜLLÜK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "ADAPAZARI",
        "STREET": "GÜNEŞLER MERKEZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "ADAPAZARI",
        "STREET": "GÜNEŞLER YENİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "ADAPAZARI",
        "STREET": "HACILAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "ADAPAZARI",
        "STREET": "HACIRAMAZANLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "ADAPAZARI",
        "STREET": "HARMANTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "ADAPAZARI",
        "STREET": "HIZIRTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "ADAPAZARI",
        "STREET": "IŞIKLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "ADAPAZARI",
        "STREET": "KARADAVUTLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "ADAPAZARI",
        "STREET": "KARADERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "ADAPAZARI",
        "STREET": "KARAKAMIŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "ADAPAZARI",
        "STREET": "KARAKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "ADAPAZARI",
        "STREET": "KARAMAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "ADAPAZARI",
        "STREET": "KARAOSMAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "ADAPAZARI",
        "STREET": "KARAPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "ADAPAZARI",
        "STREET": "KASIMLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "ADAPAZARI",
        "STREET": "KAVAKLIORMAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "ADAPAZARI",
        "STREET": "KAYRANCIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "ADAPAZARI",
        "STREET": "KIŞLA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "ADAPAZARI",
        "STREET": "KORUCUK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "ADAPAZARI",
        "STREET": "KURTBEYLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "ADAPAZARI",
        "STREET": "KURTULUŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "ADAPAZARI",
        "STREET": "KÖMÜRLÜK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "ADAPAZARI",
        "STREET": "KÖPRÜBAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "ADAPAZARI",
        "STREET": "KÜÇÜKHATAPLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "ADAPAZARI",
        "STREET": "MAHMUDİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "ADAPAZARI",
        "STREET": "MALTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "ADAPAZARI",
        "STREET": "MİTHATPAŞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "ADAPAZARI",
        "STREET": "NASUHLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "ADAPAZARI",
        "STREET": "ORTA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "ADAPAZARI",
        "STREET": "OZANLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "ADAPAZARI",
        "STREET": "PAPUÇÇULAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "ADAPAZARI",
        "STREET": "POYRAZLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "ADAPAZARI",
        "STREET": "RÜSTEMLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "ADAPAZARI",
        "STREET": "SAKARYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "ADAPAZARI",
        "STREET": "SALMANLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "ADAPAZARI",
        "STREET": "SEMERCİLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "ADAPAZARI",
        "STREET": "SOLAKLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "ADAPAZARI",
        "STREET": "SÜLEYMANBEY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "ADAPAZARI",
        "STREET": "TAŞKISIĞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "ADAPAZARI",
        "STREET": "TAŞLIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "ADAPAZARI",
        "STREET": "TEKELER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "ADAPAZARI",
        "STREET": "TEPEKUM MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "ADAPAZARI",
        "STREET": "TIĞCILAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "ADAPAZARI",
        "STREET": "TURNADERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "ADAPAZARI",
        "STREET": "TUZLA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "ADAPAZARI",
        "STREET": "YAHYALAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "ADAPAZARI",
        "STREET": "YAĞCILAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "ADAPAZARI",
        "STREET": "YENİCAMİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "ADAPAZARI",
        "STREET": "YENİDOĞAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "ADAPAZARI",
        "STREET": "YENİGÜN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "ADAPAZARI",
        "STREET": "YEŞİLYURT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "ADAPAZARI",
        "STREET": "ÇALTICAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "ADAPAZARI",
        "STREET": "ÇAMYOLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "ADAPAZARI",
        "STREET": "ÇAĞLAYAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "ADAPAZARI",
        "STREET": "ÇELEBİLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "ADAPAZARI",
        "STREET": "ÇERÇİLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "ADAPAZARI",
        "STREET": "ÇUKURAHMEDİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "ADAPAZARI",
        "STREET": "ÇÖKEKLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "ADAPAZARI",
        "STREET": "ÖRENTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "ADAPAZARI",
        "STREET": "İKİZCE MÜSLİM MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "ADAPAZARI",
        "STREET": "İKİZCE OSMANİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "ADAPAZARI",
        "STREET": "İLYASLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "ADAPAZARI",
        "STREET": "İSTİKLAL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "ADAPAZARI",
        "STREET": "ŞEKER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "ADAPAZARI",
        "STREET": "ŞİRİNEVLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "AKYAZI",
        "STREET": "AKBALIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "AKYAZI",
        "STREET": "ALAAĞAÇ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "AKYAZI",
        "STREET": "ALTINDERE CUMHURİYET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "AKYAZI",
        "STREET": "ALTINDERE GÜNDOĞAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "AKYAZI",
        "STREET": "ALTINDERE OSMANAĞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "AKYAZI",
        "STREET": "BALLIKAYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "AKYAZI",
        "STREET": "BATAKKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "AKYAZI",
        "STREET": "BEDİL KAZANCI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "AKYAZI",
        "STREET": "BEDİLKADIRBEY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "AKYAZI",
        "STREET": "BEDİLTAHİRBEY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "AKYAZI",
        "STREET": "BELDİBİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "AKYAZI",
        "STREET": "BIÇKIDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "AKYAZI",
        "STREET": "BOZTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "AKYAZI",
        "STREET": "BUĞDAYLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "AKYAZI",
        "STREET": "CUMHURİYET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "AKYAZI",
        "STREET": "DEDELER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "AKYAZI",
        "STREET": "DOKURCUN KULOĞLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "AKYAZI",
        "STREET": "DOKURCUN ÇAYLAR YENİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "AKYAZI",
        "STREET": "DOKURCUN ÇENGELLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "AKYAZI",
        "STREET": "DURMUŞLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "AKYAZI",
        "STREET": "DÜZYAZI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "AKYAZI",
        "STREET": "ERDOĞDU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "AKYAZI",
        "STREET": "ESKİBEDİL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "AKYAZI",
        "STREET": "FATİH MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "AKYAZI",
        "STREET": "GAZİ SÜLEYMAN PAŞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "AKYAZI",
        "STREET": "GEBEŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "AKYAZI",
        "STREET": "GÖKÇELER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "AKYAZI",
        "STREET": "GÜVENÇLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "AKYAZI",
        "STREET": "GÜZLEK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "AKYAZI",
        "STREET": "HANYATAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "AKYAZI",
        "STREET": "HARUNUSTA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "AKYAZI",
        "STREET": "HASANBEY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "AKYAZI",
        "STREET": "HASTAHANE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "AKYAZI",
        "STREET": "HAYDARLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "AKYAZI",
        "STREET": "KABAKULAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "AKYAZI",
        "STREET": "KARAÇALILIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "AKYAZI",
        "STREET": "KEPEKLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "AKYAZI",
        "STREET": "KIZILCIKORMAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "AKYAZI",
        "STREET": "KONURALP MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "AKYAZI",
        "STREET": "KUMKÖPRÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "AKYAZI",
        "STREET": "KUZULUK ORTAMAHALLE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "AKYAZI",
        "STREET": "KUZULUK TOPÇUSIRTI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "AKYAZI",
        "STREET": "KUZULUK ŞOSE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "AKYAZI",
        "STREET": "KÜÇÜCEK CUMHURİYET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "AKYAZI",
        "STREET": "KÜÇÜCEK İSTİKLAL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "AKYAZI",
        "STREET": "MADENLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "AKYAZI",
        "STREET": "MANSURLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "AKYAZI",
        "STREET": "MERKEZYENİKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "AKYAZI",
        "STREET": "OSMANBEY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "AKYAZI",
        "STREET": "PAZARKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "AKYAZI",
        "STREET": "REŞADİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "AKYAZI",
        "STREET": "SALİHİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "AKYAZI",
        "STREET": "SEYFELER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "AKYAZI",
        "STREET": "SUKENARI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "AKYAZI",
        "STREET": "TAŞAĞIL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "AKYAZI",
        "STREET": "TAŞBURUN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "AKYAZI",
        "STREET": "TAŞYATAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "AKYAZI",
        "STREET": "TOPAĞAÇ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "AKYAZI",
        "STREET": "TÜRKORMANKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "AKYAZI",
        "STREET": "UZUNÇINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "AKYAZI",
        "STREET": "VAKIF MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "AKYAZI",
        "STREET": "YAHYALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "AKYAZI",
        "STREET": "YAĞCILAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "AKYAZI",
        "STREET": "YENİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "AKYAZI",
        "STREET": "YENİDOĞAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "AKYAZI",
        "STREET": "YENİORMAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "AKYAZI",
        "STREET": "YONGALIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "AKYAZI",
        "STREET": "YUNUS EMRE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "AKYAZI",
        "STREET": "YUVALAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "AKYAZI",
        "STREET": "YÖRÜKYERİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "AKYAZI",
        "STREET": "ÇAKIROĞLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "AKYAZI",
        "STREET": "ÇATALKÖPRÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "AKYAZI",
        "STREET": "ÇILDIRLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "AKYAZI",
        "STREET": "ÖMERCİKLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "AKYAZI",
        "STREET": "İNÖNÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "AKYAZI",
        "STREET": "ŞEREFİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "ARİFİYE",
        "STREET": "ADLİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "ARİFİYE",
        "STREET": "AHMEDİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "ARİFİYE",
        "STREET": "ARİFBEY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "ARİFİYE",
        "STREET": "AŞAĞI KİRAZCA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "ARİFİYE",
        "STREET": "BOĞAZKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "ARİFİYE",
        "STREET": "CUMHURİYET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "ARİFİYE",
        "STREET": "FATİH MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "ARİFİYE",
        "STREET": "HACIKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "ARİFİYE",
        "STREET": "HANLI MERKEZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "ARİFİYE",
        "STREET": "HANLI SAKARYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "ARİFİYE",
        "STREET": "HANLIKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "ARİFİYE",
        "STREET": "KARAAPTİLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "ARİFİYE",
        "STREET": "KARAÇOMAKLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "ARİFİYE",
        "STREET": "KEMALİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "ARİFİYE",
        "STREET": "KIŞLAÇAY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "ARİFİYE",
        "STREET": "KUMBAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "ARİFİYE",
        "STREET": "KİRAZCA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "ARİFİYE",
        "STREET": "MOLLAKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "ARİFİYE",
        "STREET": "NEVİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "ARİFİYE",
        "STREET": "SEMERCİLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "ARİFİYE",
        "STREET": "TÜRKÇAYBAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "ARİFİYE",
        "STREET": "YUKARIKİRAZCA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "ARİFİYE",
        "STREET": "ÇAYBAŞI FUADİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "ARİFİYE",
        "STREET": "ÇINARDİBİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "ERENLER",
        "STREET": "ALANCUMA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "ERENLER",
        "STREET": "BAĞLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "ERENLER",
        "STREET": "BEKİRPAŞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "ERENLER",
        "STREET": "BÜYÜKESENCE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "ERENLER",
        "STREET": "DEĞİRMENDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "ERENLER",
        "STREET": "DİLMEN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "ERENLER",
        "STREET": "EKİNLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "ERENLER",
        "STREET": "EMİRLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "ERENLER",
        "STREET": "EPÇELLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "ERENLER",
        "STREET": "ERENLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "ERENLER",
        "STREET": "HACIOĞLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "ERENLER",
        "STREET": "HASANBEY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "ERENLER",
        "STREET": "HOROZLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "ERENLER",
        "STREET": "HÜRRİYET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "ERENLER",
        "STREET": "KAMIŞLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "ERENLER",
        "STREET": "KAYALARMEMDUHİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "ERENLER",
        "STREET": "KAYALARREŞİTBEY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "ERENLER",
        "STREET": "KOZLUK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "ERENLER",
        "STREET": "KÜPÇÜLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "ERENLER",
        "STREET": "KÜÇÜKESENCE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "ERENLER",
        "STREET": "MERKEZ YENİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "ERENLER",
        "STREET": "MERKEZ YEŞİLTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "ERENLER",
        "STREET": "NAKIŞLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "ERENLER",
        "STREET": "PİRAHMETLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "ERENLER",
        "STREET": "SARICALAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "ERENLER",
        "STREET": "TABAKHANE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "ERENLER",
        "STREET": "TEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "ERENLER",
        "STREET": "TUAPSALAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "ERENLER",
        "STREET": "YAZILI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "ERENLER",
        "STREET": "ÇAYBAŞIYENİKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "ERENLER",
        "STREET": "ÇAYKIŞLA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "ERENLER",
        "STREET": "ŞEYHKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "ERENLER",
        "STREET": "ŞÜKRİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "FERİZLİ",
        "STREET": "ABDÜRREZZAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "FERİZLİ",
        "STREET": "AKÇUKUR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "FERİZLİ",
        "STREET": "AĞACIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "FERİZLİ",
        "STREET": "BAKIRLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "FERİZLİ",
        "STREET": "CEYLANDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "FERİZLİ",
        "STREET": "DAMLIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "FERİZLİ",
        "STREET": "DEVLET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "FERİZLİ",
        "STREET": "DEĞİRMENCİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "FERİZLİ",
        "STREET": "DOĞANCI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "FERİZLİ",
        "STREET": "GÖLKENT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "FERİZLİ",
        "STREET": "HOCAOĞLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "FERİZLİ",
        "STREET": "KARADİKEN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "FERİZLİ",
        "STREET": "KEMALPAŞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "FERİZLİ",
        "STREET": "KUSCA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "FERİZLİ",
        "STREET": "NALKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "FERİZLİ",
        "STREET": "OSMANAĞA ÇİFTLİĞİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "FERİZLİ",
        "STREET": "SAKARYA 1 NOLU L TİPİ KAPALI CEZAEVİ",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "FERİZLİ",
        "STREET": "SAKARYA 2 NOLU L TİPİ KAPALI CEZAEVİ",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "FERİZLİ",
        "STREET": "SARIAHMETLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "FERİZLİ",
        "STREET": "SEYİFLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "FERİZLİ",
        "STREET": "SİNANOĞLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "FERİZLİ",
        "STREET": "TEBERİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "FERİZLİ",
        "STREET": "TOKAT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "FERİZLİ",
        "STREET": "YENİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "FERİZLİ",
        "STREET": "İNÖNÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "FERİZLİ",
        "STREET": "İSTİKLAL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "GEYVE",
        "STREET": "AHİBABA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "GEYVE",
        "STREET": "AKDOĞAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "GEYVE",
        "STREET": "AKINCI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "GEYVE",
        "STREET": "AKKAYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "GEYVE",
        "STREET": "ALIPLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "GEYVE",
        "STREET": "ALİFUATPAŞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "GEYVE",
        "STREET": "AYDINLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "GEYVE",
        "STREET": "BAYAT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "GEYVE",
        "STREET": "BAĞCAZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "GEYVE",
        "STREET": "BAĞLARBAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "GEYVE",
        "STREET": "BELPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "GEYVE",
        "STREET": "BOZÖREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "GEYVE",
        "STREET": "BURHANİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "GEYVE",
        "STREET": "CAMİKEBİR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "GEYVE",
        "STREET": "CECELER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "GEYVE",
        "STREET": "DEMİRLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "GEYVE",
        "STREET": "DEREKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "GEYVE",
        "STREET": "DOĞANCIL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "GEYVE",
        "STREET": "DOĞANTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "GEYVE",
        "STREET": "DOĞANÇAY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "GEYVE",
        "STREET": "DÜZAKÇAŞEHİR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "GEYVE",
        "STREET": "EPÇELER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "GEYVE",
        "STREET": "ESENKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "GEYVE",
        "STREET": "EŞME MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "GEYVE",
        "STREET": "FINDIKSUYU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "GEYVE",
        "STREET": "GAZİ SÜLEYMAN PAŞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "GEYVE",
        "STREET": "GÜNEY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "GEYVE",
        "STREET": "GÜNHOŞLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "GEYVE",
        "STREET": "HACILAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "GEYVE",
        "STREET": "HACIOSMANLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "GEYVE",
        "STREET": "HALİDİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "GEYVE",
        "STREET": "HIRKA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "GEYVE",
        "STREET": "HİSARLIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "GEYVE",
        "STREET": "ILICA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "GEYVE",
        "STREET": "KAMIŞLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "GEYVE",
        "STREET": "KARACAÖREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "GEYVE",
        "STREET": "KARAÇAM MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "GEYVE",
        "STREET": "KIZILKAYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "GEYVE",
        "STREET": "KORU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "GEYVE",
        "STREET": "KOZAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "GEYVE",
        "STREET": "KULFALLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "GEYVE",
        "STREET": "KÖPRÜBAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "GEYVE",
        "STREET": "MAKSUDİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "GEYVE",
        "STREET": "MELEKŞEORUÇ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "GEYVE",
        "STREET": "MELEKŞESOLAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "GEYVE",
        "STREET": "NURUOSMANİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "GEYVE",
        "STREET": "ORHANİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "GEYVE",
        "STREET": "POYDULAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "GEYVE",
        "STREET": "SABIRLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "GEYVE",
        "STREET": "SAFİBEY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "GEYVE",
        "STREET": "SARAÇLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "GEYVE",
        "STREET": "SARIGAZİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "GEYVE",
        "STREET": "SEKİHARMAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "GEYVE",
        "STREET": "SETÇE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "GEYVE",
        "STREET": "SUÇATI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "GEYVE",
        "STREET": "SÜTALAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "GEYVE",
        "STREET": "TAŞOLUK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "GEYVE",
        "STREET": "TEPECİKLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "GEYVE",
        "STREET": "UMURBEY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "GEYVE",
        "STREET": "YAYLAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "GEYVE",
        "STREET": "YÖRÜKLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "GEYVE",
        "STREET": "ÇAMLIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "GEYVE",
        "STREET": "ÇAYKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "GEYVE",
        "STREET": "ÇELTİKLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "GEYVE",
        "STREET": "ÇENGEL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "GEYVE",
        "STREET": "ÇUKURKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "GEYVE",
        "STREET": "ÇİNE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "GEYVE",
        "STREET": "ÖRENCİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "GEYVE",
        "STREET": "İHSANİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "GEYVE",
        "STREET": "İLİMBEY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "GEYVE",
        "STREET": "İNCİKSUYU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "GEYVE",
        "STREET": "ŞEHREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "GEYVE",
        "STREET": "ŞEREFİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "HENDEK",
        "STREET": "AKARCA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "HENDEK",
        "STREET": "AKOVA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "HENDEK",
        "STREET": "AKPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "HENDEK",
        "STREET": "AKSU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "HENDEK",
        "STREET": "AKTEFEK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "HENDEK",
        "STREET": "AKÇAYIR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "HENDEK",
        "STREET": "AŞAĞIÇALICA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "HENDEK",
        "STREET": "BAKACAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "HENDEK",
        "STREET": "BALIKLIİHSANİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "HENDEK",
        "STREET": "BAYRAKTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "HENDEK",
        "STREET": "BAŞPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "HENDEK",
        "STREET": "BEYKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "HENDEK",
        "STREET": "BEYLİCE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "HENDEK",
        "STREET": "BIÇKIATİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "HENDEK",
        "STREET": "BÜYÜKDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "HENDEK",
        "STREET": "DEREBOĞAZI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "HENDEK",
        "STREET": "DEREKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "HENDEK",
        "STREET": "DİKMEN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "HENDEK",
        "STREET": "ESENTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "HENDEK",
        "STREET": "ESKİBIÇKI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "HENDEK",
        "STREET": "GÖKSU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "HENDEK",
        "STREET": "GÜLDİBİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "HENDEK",
        "STREET": "GÜNDOĞAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "HENDEK",
        "STREET": "GÜNEY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "HENDEK",
        "STREET": "HACIKIŞLA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "HENDEK",
        "STREET": "HACİMBEY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "HENDEK",
        "STREET": "HALAÇ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "HENDEK",
        "STREET": "HAMİTLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "HENDEK",
        "STREET": "HARMANTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "HENDEK",
        "STREET": "HÜSEYİNŞEYH MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "HENDEK",
        "STREET": "HİCRİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "HENDEK",
        "STREET": "KADİFEKALE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "HENDEK",
        "STREET": "KAHRAMAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "HENDEK",
        "STREET": "KALAYIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "HENDEK",
        "STREET": "KARADERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "HENDEK",
        "STREET": "KARATOPRAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "HENDEK",
        "STREET": "KARGALI HANBABA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "HENDEK",
        "STREET": "KARGALIYENİKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "HENDEK",
        "STREET": "KAZIMİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "HENDEK",
        "STREET": "KEMALİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "HENDEK",
        "STREET": "KIRKTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "HENDEK",
        "STREET": "KIZANLIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "HENDEK",
        "STREET": "KOCAAHMETLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "HENDEK",
        "STREET": "KOCATÖNGEL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "HENDEK",
        "STREET": "KURTKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "HENDEK",
        "STREET": "KURTULUŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "HENDEK",
        "STREET": "KÖPRÜBAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "HENDEK",
        "STREET": "LÜTFİYEKÖŞK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "HENDEK",
        "STREET": "MAHMUTBEY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "HENDEK",
        "STREET": "MARTİNLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "HENDEK",
        "STREET": "MURADİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "HENDEK",
        "STREET": "NURİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "HENDEK",
        "STREET": "PAŞAKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "HENDEK",
        "STREET": "PINARLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "HENDEK",
        "STREET": "PUNA ORTAKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "HENDEK",
        "STREET": "RASİMPAŞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "HENDEK",
        "STREET": "SARIDEDE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "HENDEK",
        "STREET": "SARIYER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "HENDEK",
        "STREET": "SERVETİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "HENDEK",
        "STREET": "SOFULAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "HENDEK",
        "STREET": "SOĞUKSU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "HENDEK",
        "STREET": "SUKENARI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "HENDEK",
        "STREET": "SÜLEYMANİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "HENDEK",
        "STREET": "SÜMBÜLLÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "HENDEK",
        "STREET": "SİVRİTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "HENDEK",
        "STREET": "TURANLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "HENDEK",
        "STREET": "TUZAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "HENDEK",
        "STREET": "TÜRBE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "HENDEK",
        "STREET": "UZUNCAORMAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "HENDEK",
        "STREET": "UZUNÇARŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "HENDEK",
        "STREET": "YARICA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "HENDEK",
        "STREET": "YAYALAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "HENDEK",
        "STREET": "YAĞBASAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "HENDEK",
        "STREET": "YENİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "HENDEK",
        "STREET": "YENİKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "HENDEK",
        "STREET": "YENİYAYLA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "HENDEK",
        "STREET": "YEŞİLKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "HENDEK",
        "STREET": "YEŞİLVADİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "HENDEK",
        "STREET": "YEŞİLYURT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "HENDEK",
        "STREET": "YUKARIHÜSEYİNŞEYH MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "HENDEK",
        "STREET": "YUKARIÇALICA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "HENDEK",
        "STREET": "ÇAKALLIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "HENDEK",
        "STREET": "ÇAMLICA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "HENDEK",
        "STREET": "ÇAYIRBAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "HENDEK",
        "STREET": "ÇAĞLAYAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "HENDEK",
        "STREET": "ÇOBANYATAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "HENDEK",
        "STREET": "ÇUKURHAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "HENDEK",
        "STREET": "ÇİFTLİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "HENDEK",
        "STREET": "İKBALİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "HENDEK",
        "STREET": "İKRAMİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "HENDEK",
        "STREET": "ŞEYHLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "KARAPÜRÇEK",
        "STREET": "AHMEDİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "KARAPÜRÇEK",
        "STREET": "AHMETLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "KARAPÜRÇEK",
        "STREET": "CUMHURİYET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "KARAPÜRÇEK",
        "STREET": "HOCAKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "KARAPÜRÇEK",
        "STREET": "KANLIÇAY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "KARAPÜRÇEK",
        "STREET": "KIZILBAYIR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "KARAPÜRÇEK",
        "STREET": "KÜÇÜKKARAPÜRÇEK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "KARAPÜRÇEK",
        "STREET": "MECİDİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "KARAPÜRÇEK",
        "STREET": "MESUDİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "KARAPÜRÇEK",
        "STREET": "MEŞEPINARI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "KARAPÜRÇEK",
        "STREET": "YAZILIGÜRGEN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "KARAPÜRÇEK",
        "STREET": "YÜKSEL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "KARAPÜRÇEK",
        "STREET": "ÇEŞMEBAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "KARAPÜRÇEK",
        "STREET": "İNÖNÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "KARASU",
        "STREET": "ADATEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "KARASU",
        "STREET": "AKKUM MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "KARASU",
        "STREET": "ARDIÇBELİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "KARASU",
        "STREET": "AZİZİYE  MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "KARASU",
        "STREET": "CAMİTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "KARASU",
        "STREET": "DARIÇAYIRI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "KARASU",
        "STREET": "DENİZKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "KARASU",
        "STREET": "GÖLKÖPRÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "KARASU",
        "STREET": "HÜRRİYET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "KARASU",
        "STREET": "KABAKOZ  MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "KARASU",
        "STREET": "KANCALAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "KARASU",
        "STREET": "KARAMÜEZZİNLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "KARASU",
        "STREET": "KARANLIKDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "KARASU",
        "STREET": "KARAPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "KARASU",
        "STREET": "KARASU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "KARASU",
        "STREET": "KIZILCIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "KARASU",
        "STREET": "KONACIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "KARASU",
        "STREET": "KURUDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "KARASU",
        "STREET": "KURUMEŞE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "KARASU",
        "STREET": "KUYUMCULLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "KARASU",
        "STREET": "KUZULUK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "KARASU",
        "STREET": "LİMANDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "KARASU",
        "STREET": "MANAVPINARI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "KARASU",
        "STREET": "ORTAKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "KARASU",
        "STREET": "PARALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "KARASU",
        "STREET": "RESULLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "KARASU",
        "STREET": "SUBATAĞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "KARASU",
        "STREET": "TAŞLIGEÇİT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "KARASU",
        "STREET": "TEPETARLA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "KARASU",
        "STREET": "TUZLA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "KARASU",
        "STREET": "YALI  MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "KARASU",
        "STREET": "YASSIGEÇİT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "KARASU",
        "STREET": "YENİ  MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "KARASU",
        "STREET": "YENİDOĞAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "KARASU",
        "STREET": "YEŞİLKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "KARASU",
        "STREET": "YUVALIDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "KARASU",
        "STREET": "ÇATALÖVEZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "KARASU",
        "STREET": "ÜÇOLUK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "KARASU",
        "STREET": "İHSANİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "KARASU",
        "STREET": "İNCİLLİ  MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "KAYNARCA",
        "STREET": "AKBAŞLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "KAYNARCA",
        "STREET": "ARİFAĞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "KAYNARCA",
        "STREET": "BAŞOĞLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "KAYNARCA",
        "STREET": "BÜYÜKYANIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "KAYNARCA",
        "STREET": "BİRLİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "KAYNARCA",
        "STREET": "CEBEK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "KAYNARCA",
        "STREET": "DUDUKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "KAYNARCA",
        "STREET": "EĞRİOĞLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "KAYNARCA",
        "STREET": "GAZİLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "KAYNARCA",
        "STREET": "GÖLCE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "KAYNARCA",
        "STREET": "GÜRPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "KAYNARCA",
        "STREET": "GÜVEN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "KAYNARCA",
        "STREET": "HATİPLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "KAYNARCA",
        "STREET": "KALBURCU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "KAYNARCA",
        "STREET": "KARAMANLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "KAYNARCA",
        "STREET": "KARAÇALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "KAYNARCA",
        "STREET": "KAYACIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "KAYNARCA",
        "STREET": "KERTİL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "KAYNARCA",
        "STREET": "KIRKTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "KAYNARCA",
        "STREET": "KIZILCAALİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "KAYNARCA",
        "STREET": "KONAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "KAYNARCA",
        "STREET": "KULAKLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "KAYNARCA",
        "STREET": "KÜÇÜKKAYNARCA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "KAYNARCA",
        "STREET": "KÜÇÜKKIŞLA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "KAYNARCA",
        "STREET": "MERKEZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "KAYNARCA",
        "STREET": "MÜEZZİNLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "KAYNARCA",
        "STREET": "OKÇULAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "KAYNARCA",
        "STREET": "ORTA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "KAYNARCA",
        "STREET": "ORTAKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "KAYNARCA",
        "STREET": "OSMANLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "KAYNARCA",
        "STREET": "SABIRLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "KAYNARCA",
        "STREET": "SARIBEYLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "KAYNARCA",
        "STREET": "TAŞOLUK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "KAYNARCA",
        "STREET": "TOPÇU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "KAYNARCA",
        "STREET": "TURNALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "KAYNARCA",
        "STREET": "UZAKKIŞLA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "KAYNARCA",
        "STREET": "UZUNALAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "KAYNARCA",
        "STREET": "UĞURLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "KAYNARCA",
        "STREET": "YENİÇAM MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "KAYNARCA",
        "STREET": "YEŞİLOVA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "KAYNARCA",
        "STREET": "ZİAHMET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "KAYNARCA",
        "STREET": "ÖMERAĞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "KAYNARCA",
        "STREET": "İMAMLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "KAYNARCA",
        "STREET": "İŞARET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "KAYNARCA",
        "STREET": "ŞEYHTIMARI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "KOCAALİ",
        "STREET": "AKPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "KOCAALİ",
        "STREET": "AKTAŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "KOCAALİ",
        "STREET": "ALANDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "KOCAALİ",
        "STREET": "AYDOĞAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "KOCAALİ",
        "STREET": "AÇMABAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "KOCAALİ",
        "STREET": "AĞALAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "KOCAALİ",
        "STREET": "BEYLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "KOCAALİ",
        "STREET": "BEZİRGAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "KOCAALİ",
        "STREET": "CAFERİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "KOCAALİ",
        "STREET": "DEMİRAÇMA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "KOCAALİ",
        "STREET": "GÖRELE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "KOCAALİ",
        "STREET": "GÜMÜŞOLUK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "KOCAALİ",
        "STREET": "HIZAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "KOCAALİ",
        "STREET": "KADIKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "KOCAALİ",
        "STREET": "KARALAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "KOCAALİ",
        "STREET": "KARAPELİT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "KOCAALİ",
        "STREET": "KARŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "KOCAALİ",
        "STREET": "KESTANEPINARI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "KOCAALİ",
        "STREET": "KIZILÜZÜM MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "KOCAALİ",
        "STREET": "KOZLUK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "KOCAALİ",
        "STREET": "KOĞUTPELİT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "KOCAALİ",
        "STREET": "KÖYYERİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "KOCAALİ",
        "STREET": "KÜPLÜK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "KOCAALİ",
        "STREET": "KİRAZLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "KOCAALİ",
        "STREET": "MELEN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "KOCAALİ",
        "STREET": "MERKEZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "KOCAALİ",
        "STREET": "SELAHİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "KOCAALİ",
        "STREET": "SÜNGÜT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "KOCAALİ",
        "STREET": "YALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "KOCAALİ",
        "STREET": "YALPANKAYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "KOCAALİ",
        "STREET": "YANIKSAYVANT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "KOCAALİ",
        "STREET": "YAYLA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "KOCAALİ",
        "STREET": "YENİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "KOCAALİ",
        "STREET": "ÇAKMAKLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "KOCAALİ",
        "STREET": "ÇUKURKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "KOCAALİ",
        "STREET": "ŞERBETPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "PAMUKOVA",
        "STREET": "AHILAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "PAMUKOVA",
        "STREET": "AKÇAKAYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "PAMUKOVA",
        "STREET": "AĞAÇÇILAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "PAMUKOVA",
        "STREET": "BACIKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "PAMUKOVA",
        "STREET": "BAKACAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "PAMUKOVA",
        "STREET": "BAYIRAKÇAŞEHİR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "PAMUKOVA",
        "STREET": "CUMHURİYET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "PAMUKOVA",
        "STREET": "CİHADİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "PAMUKOVA",
        "STREET": "ELPEREK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "PAMUKOVA",
        "STREET": "ESKİYAYLA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "PAMUKOVA",
        "STREET": "EĞRİÇAY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "PAMUKOVA",
        "STREET": "FEVZİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "PAMUKOVA",
        "STREET": "GONCA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "PAMUKOVA",
        "STREET": "GÖKGÖZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "PAMUKOVA",
        "STREET": "HAYRETTİN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "PAMUKOVA",
        "STREET": "HÜSEYİNLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "PAMUKOVA",
        "STREET": "KADIKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "PAMUKOVA",
        "STREET": "KARAPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "PAMUKOVA",
        "STREET": "KAZIMİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "PAMUKOVA",
        "STREET": "KEMALİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "PAMUKOVA",
        "STREET": "MEKECE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "PAMUKOVA",
        "STREET": "ORUÇLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "PAMUKOVA",
        "STREET": "PAŞALAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "PAMUKOVA",
        "STREET": "PINARLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "PAMUKOVA",
        "STREET": "TEŞVİKİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "PAMUKOVA",
        "STREET": "TURGUTLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "PAMUKOVA",
        "STREET": "YENİCE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "PAMUKOVA",
        "STREET": "ÇARDAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "PAMUKOVA",
        "STREET": "ÇİLEKLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "PAMUKOVA",
        "STREET": "ÖZBEK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "PAMUKOVA",
        "STREET": "İSABALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "PAMUKOVA",
        "STREET": "ŞAHMELEK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "PAMUKOVA",
        "STREET": "ŞEYHVARMAZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "SAPANCA",
        "STREET": "AKÇAY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "SAPANCA",
        "STREET": "BALKAYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "SAPANCA",
        "STREET": "CAMİCEDİT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "SAPANCA",
        "STREET": "FEVZİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "SAPANCA",
        "STREET": "GAZİPAŞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "SAPANCA",
        "STREET": "GÖL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "SAPANCA",
        "STREET": "GÜLDİBİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "SAPANCA",
        "STREET": "HACIMERCAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "SAPANCA",
        "STREET": "KIRKPINAR HASANPAŞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "SAPANCA",
        "STREET": "KIRKPINAR SOĞUKSU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "SAPANCA",
        "STREET": "KIRKPINAR TEPEBAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "SAPANCA",
        "STREET": "KURTKÖY DİBEKTAŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "SAPANCA",
        "STREET": "KURTKÖY FATİH MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "SAPANCA",
        "STREET": "KURTKÖY YAVUZSELİM MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "SAPANCA",
        "STREET": "KURUÇEŞME MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "SAPANCA",
        "STREET": "MAHMUDİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "SAPANCA",
        "STREET": "MEMNUNİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "SAPANCA",
        "STREET": "MURADİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "SAPANCA",
        "STREET": "NAİLİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "SAPANCA",
        "STREET": "RÜSTEMPAŞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "SAPANCA",
        "STREET": "UZUNKUM MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "SAPANCA",
        "STREET": "YANIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "SAPANCA",
        "STREET": "YENİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "SAPANCA",
        "STREET": "ÇAYİÇİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "SAPANCA",
        "STREET": "ÜNLÜCE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "SAPANCA",
        "STREET": "İKRAMİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "SAPANCA",
        "STREET": "İLMİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "SAPANCA",
        "STREET": "İSTANBULDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "SAPANCA",
        "STREET": "ŞÜKRİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "SERDİVAN",
        "STREET": "ARABACIALANI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "SERDİVAN",
        "STREET": "ARALIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "SERDİVAN",
        "STREET": "AŞAĞIDEREKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "SERDİVAN",
        "STREET": "BAHÇELİEVLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "SERDİVAN",
        "STREET": "BEŞEVLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "SERDİVAN",
        "STREET": "BEŞKÖPRÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "SERDİVAN",
        "STREET": "DAĞYONCALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "SERDİVAN",
        "STREET": "ESENTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "SERDİVAN",
        "STREET": "HAMİTABAT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "SERDİVAN",
        "STREET": "KAZIMPAŞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "SERDİVAN",
        "STREET": "KEMALPAŞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "SERDİVAN",
        "STREET": "KIZILCIKLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "SERDİVAN",
        "STREET": "KURUÇEŞME MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "SERDİVAN",
        "STREET": "KÖPRÜBAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "SERDİVAN",
        "STREET": "MEŞELİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "SERDİVAN",
        "STREET": "OTUZİKİ EVLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "SERDİVAN",
        "STREET": "REŞADİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "SERDİVAN",
        "STREET": "SELAHİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "SERDİVAN",
        "STREET": "UZUNKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "SERDİVAN",
        "STREET": "VATAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "SERDİVAN",
        "STREET": "YAZLIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "SERDİVAN",
        "STREET": "YUKARIDEREKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "SERDİVAN",
        "STREET": "ÇUBUKLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "SERDİVAN",
        "STREET": "İSTİKLAL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "SÖĞÜTLÜ",
        "STREET": "AKARCA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "SÖĞÜTLÜ",
        "STREET": "AKGÖL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "SÖĞÜTLÜ",
        "STREET": "AKÇAKAMIŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "SÖĞÜTLÜ",
        "STREET": "BEŞDEĞİRMEN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "SÖĞÜTLÜ",
        "STREET": "CAMİ CEDİT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "SÖĞÜTLÜ",
        "STREET": "FINDIKLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "SÖĞÜTLÜ",
        "STREET": "GÜNDOĞAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "SÖĞÜTLÜ",
        "STREET": "HASANFAKI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "SÖĞÜTLÜ",
        "STREET": "KANTAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "SÖĞÜTLÜ",
        "STREET": "KARATEKE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "SÖĞÜTLÜ",
        "STREET": "KURUDİL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "SÖĞÜTLÜ",
        "STREET": "KÜÇÜK SÖĞÜTLÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "SÖĞÜTLÜ",
        "STREET": "LEVENT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "SÖĞÜTLÜ",
        "STREET": "MAKSUDİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "SÖĞÜTLÜ",
        "STREET": "MAĞARA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "SÖĞÜTLÜ",
        "STREET": "ORTA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "SÖĞÜTLÜ",
        "STREET": "RÜSTEMLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "SÖĞÜTLÜ",
        "STREET": "SIRAKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "SÖĞÜTLÜ",
        "STREET": "SOĞUCAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "SÖĞÜTLÜ",
        "STREET": "TOKMAKLIDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "SÖĞÜTLÜ",
        "STREET": "TÜRKBEYLİKKIŞLA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "SÖĞÜTLÜ",
        "STREET": "YENİKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "SÖĞÜTLÜ",
        "STREET": "İMAMLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "TARAKLI",
        "STREET": "AKSU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "TARAKLI",
        "STREET": "AKÇAPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "TARAKLI",
        "STREET": "ALBALLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "TARAKLI",
        "STREET": "AVDAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "TARAKLI",
        "STREET": "DIŞDEDELER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "TARAKLI",
        "STREET": "DUMAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "TARAKLI",
        "STREET": "ESENYURT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "TARAKLI",
        "STREET": "HACIALİLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "TARAKLI",
        "STREET": "HACIMURAT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "TARAKLI",
        "STREET": "HACIYAKUP MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "TARAKLI",
        "STREET": "HARKKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "TARAKLI",
        "STREET": "KEMALLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "TARAKLI",
        "STREET": "MAHDUMLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "TARAKLI",
        "STREET": "PİRLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "TARAKLI",
        "STREET": "TUZLA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "TARAKLI",
        "STREET": "ULUCAMİİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "TARAKLI",
        "STREET": "UĞURLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "TARAKLI",
        "STREET": "YENİDOĞAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "TARAKLI",
        "STREET": "YENİKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "TARAKLI",
        "STREET": "YUSUFBEY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "TARAKLI",
        "STREET": "ÇAMTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAKARYA",
        "DISTRICT": "TARAKLI",
        "STREET": "İÇDEDELER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "19 MAYIS",
        "STREET": "AYDINPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "19 MAYIS",
        "STREET": "BAHÇELİEVLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "19 MAYIS",
        "STREET": "BALLICA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "19 MAYIS",
        "STREET": "BEYLİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "19 MAYIS",
        "STREET": "CEREKLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "19 MAYIS",
        "STREET": "CUMHURİYET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "19 MAYIS",
        "STREET": "DAĞKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "19 MAYIS",
        "STREET": "DEREKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "19 MAYIS",
        "STREET": "DÜZKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "19 MAYIS",
        "STREET": "ENGİZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "19 MAYIS",
        "STREET": "ESENTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "19 MAYIS",
        "STREET": "ESENYER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "19 MAYIS",
        "STREET": "FINDIKLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "19 MAYIS",
        "STREET": "HİBE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "19 MAYIS",
        "STREET": "KARACAOĞLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "19 MAYIS",
        "STREET": "KARAGÖL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "19 MAYIS",
        "STREET": "KARŞIYAKA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "19 MAYIS",
        "STREET": "KERTME MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "19 MAYIS",
        "STREET": "KUMCAĞIZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "19 MAYIS",
        "STREET": "KUŞKAYASI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "19 MAYIS",
        "STREET": "KÖSEDİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "19 MAYIS",
        "STREET": "MİMAR SİNAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "19 MAYIS",
        "STREET": "ORMANCIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "19 MAYIS",
        "STREET": "PAZAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "19 MAYIS",
        "STREET": "TEPEKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "19 MAYIS",
        "STREET": "YENİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "19 MAYIS",
        "STREET": "YEŞİLKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "19 MAYIS",
        "STREET": "YEŞİLOVA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "19 MAYIS",
        "STREET": "YEŞİLYURT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "19 MAYIS",
        "STREET": "YUKARIENGİZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "19 MAYIS",
        "STREET": "YÖRÜKLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "19 MAYIS",
        "STREET": "YÜKSELİŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "19 MAYIS",
        "STREET": "ÇAMLICA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "19 MAYIS",
        "STREET": "ÇANDIR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "19 MAYIS",
        "STREET": "ÇEPİNLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "19 MAYIS",
        "STREET": "ÇETİRLİPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "19 MAYIS",
        "STREET": "ÇİFTLİKKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "19 MAYIS",
        "STREET": "İSTİKLAL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "ALAÇAM",
        "STREET": "AKBULUT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "ALAÇAM",
        "STREET": "AKGÜNEY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "ALAÇAM",
        "STREET": "ALPARSLAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "ALAÇAM",
        "STREET": "ALİDEDEBÖLMESİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "ALAÇAM",
        "STREET": "AŞAĞI AKGÜNEY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "ALAÇAM",
        "STREET": "AŞAĞIISIRGANLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "ALAÇAM",
        "STREET": "AŞAĞIKOÇLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "ALAÇAM",
        "STREET": "BAHŞİOYMAĞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "ALAÇAM",
        "STREET": "BEKİROĞLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "ALAÇAM",
        "STREET": "DEMİRCİDERESİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "ALAÇAM",
        "STREET": "DOYRAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "ALAÇAM",
        "STREET": "DOĞANKENT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "ALAÇAM",
        "STREET": "ESENTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "ALAÇAM",
        "STREET": "ETYEMEZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "ALAÇAM",
        "STREET": "FATİH MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "ALAÇAM",
        "STREET": "GÖKÇEAĞAÇOYMAĞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "ALAÇAM",
        "STREET": "GÖKÇEBOĞAZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "ALAÇAM",
        "STREET": "GÖÇKÜN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "ALAÇAM",
        "STREET": "GÜLKAYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "ALAÇAM",
        "STREET": "GÜLTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "ALAÇAM",
        "STREET": "GÜMÜŞOVA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "ALAÇAM",
        "STREET": "GÜVENLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "ALAÇAM",
        "STREET": "HABİLLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "ALAÇAM",
        "STREET": "HARMANCIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "ALAÇAM",
        "STREET": "KADIKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "ALAÇAM",
        "STREET": "KALIKDEMİRCİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "ALAÇAM",
        "STREET": "KAPAKLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "ALAÇAM",
        "STREET": "KARAHÜSEYİNLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "ALAÇAM",
        "STREET": "KARAÇUKUR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "ALAÇAM",
        "STREET": "KARLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "ALAÇAM",
        "STREET": "KARŞIYAKA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "ALAÇAM",
        "STREET": "KIZLAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "ALAÇAM",
        "STREET": "KIŞLAKONAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "ALAÇAM",
        "STREET": "KÖSEKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "ALAÇAM",
        "STREET": "KİLLİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "ALAÇAM",
        "STREET": "MADENLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "ALAÇAM",
        "STREET": "ORTAKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "ALAÇAM",
        "STREET": "PELİTBÜKÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "ALAÇAM",
        "STREET": "PELİTBÜKÜSEKECEK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "ALAÇAM",
        "STREET": "SANCAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "ALAÇAM",
        "STREET": "SARILIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "ALAÇAM",
        "STREET": "SOĞUKÇAM MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "ALAÇAM",
        "STREET": "TAŞKELİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "ALAÇAM",
        "STREET": "TEFEKLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "ALAÇAM",
        "STREET": "TEPEBÖLMESİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "ALAÇAM",
        "STREET": "TERSKIRIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "ALAÇAM",
        "STREET": "TOPLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "ALAÇAM",
        "STREET": "UMUTLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "ALAÇAM",
        "STREET": "UZUNKIRAÇ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "ALAÇAM",
        "STREET": "VİCİKLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "ALAÇAM",
        "STREET": "YAKACIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "ALAÇAM",
        "STREET": "YENİCAMİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "ALAÇAM",
        "STREET": "YENİCE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "ALAÇAM",
        "STREET": "YENİKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "ALAÇAM",
        "STREET": "YEŞİLKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "ALAÇAM",
        "STREET": "YOĞUNPELİT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "ALAÇAM",
        "STREET": "YUKARIELMA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "ALAÇAM",
        "STREET": "YUKARIISIRGANLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "ALAÇAM",
        "STREET": "YUKARIKOÇLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "ALAÇAM",
        "STREET": "YUKARISOĞUKÇAM MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "ALAÇAM",
        "STREET": "ZEYTİN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "ALAÇAM",
        "STREET": "ÇEŞME MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "ALAÇAM",
        "STREET": "ÖRENLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "ALAÇAM",
        "STREET": "İNCİRLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "ALAÇAM",
        "STREET": "ŞİRİNKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "ASARCIK",
        "STREET": "ACISU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "ASARCIK",
        "STREET": "AKYAZI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "ASARCIK",
        "STREET": "ALAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "ASARCIK",
        "STREET": "ALİŞAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "ASARCIK",
        "STREET": "ARICAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "ASARCIK",
        "STREET": "ARMUTLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "ASARCIK",
        "STREET": "ATATÜRK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "ASARCIK",
        "STREET": "AYAKLIALAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "ASARCIK",
        "STREET": "AYDINKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "ASARCIK",
        "STREET": "AŞURU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "ASARCIK",
        "STREET": "BİÇİNCİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "ASARCIK",
        "STREET": "DAĞCILAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "ASARCIK",
        "STREET": "EMİRMUSA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "ASARCIK",
        "STREET": "ESENTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "ASARCIK",
        "STREET": "GÖKGÖL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "ASARCIK",
        "STREET": "GÜLVEREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "ASARCIK",
        "STREET": "GÜNDOĞDU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "ASARCIK",
        "STREET": "HİSARİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "ASARCIK",
        "STREET": "KESEALAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "ASARCIK",
        "STREET": "KILAVUZLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "ASARCIK",
        "STREET": "KOŞACA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "ASARCIK",
        "STREET": "KUYUMCUOĞLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "ASARCIK",
        "STREET": "MUSAAĞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "ASARCIK",
        "STREET": "SAKIZLIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "ASARCIK",
        "STREET": "ULUKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "ASARCIK",
        "STREET": "YARIMCA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "ASARCIK",
        "STREET": "YAYLAKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "ASARCIK",
        "STREET": "YENİÖMERLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "ASARCIK",
        "STREET": "YEŞİLDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "ASARCIK",
        "STREET": "YEŞİLKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "ASARCIK",
        "STREET": "ÇULHAOĞLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "ASARCIK",
        "STREET": "İMAMLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "ATAKUM",
        "STREET": "AKALAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "ATAKUM",
        "STREET": "AKSU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "ATAKUM",
        "STREET": "ALANLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "ATAKUM",
        "STREET": "ASLANDAMI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "ATAKUM",
        "STREET": "ATATEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "ATAKUM",
        "STREET": "AYVALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "ATAKUM",
        "STREET": "BALAÇ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "ATAKUM",
        "STREET": "BEYPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "ATAKUM",
        "STREET": "BÜYÜKKOLPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "ATAKUM",
        "STREET": "BÜYÜKOYUMCA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "ATAKUM",
        "STREET": "CAMİİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "ATAKUM",
        "STREET": "CUMHURIYET  MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "ATAKUM",
        "STREET": "DENİZEVLERİ  MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "ATAKUM",
        "STREET": "ELMAÇUKURU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "ATAKUM",
        "STREET": "ERİKLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "ATAKUM",
        "STREET": "ESENEVLER  MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "ATAKUM",
        "STREET": "GÜNEYKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "ATAKUM",
        "STREET": "GÜZELYALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "ATAKUM",
        "STREET": "GÜZELYURT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "ATAKUM",
        "STREET": "KABADÜZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "ATAKUM",
        "STREET": "KAMALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "ATAKUM",
        "STREET": "KARAKAVUK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "ATAKUM",
        "STREET": "KARAOYUMCA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "ATAKUM",
        "STREET": "KASNAKCIMERMER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "ATAKUM",
        "STREET": "KAYAGÜNEY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "ATAKUM",
        "STREET": "KESİLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "ATAKUM",
        "STREET": "KULACADAĞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "ATAKUM",
        "STREET": "KURUGÖKÇE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "ATAKUM",
        "STREET": "KÖRFEZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "ATAKUM",
        "STREET": "KÖSELİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "ATAKUM",
        "STREET": "KÜÇÜKKOLPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "ATAKUM",
        "STREET": "MEVLANA  MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "ATAKUM",
        "STREET": "MEYVALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "ATAKUM",
        "STREET": "MİMARSİNAN  MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "ATAKUM",
        "STREET": "SARAYKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "ATAKUM",
        "STREET": "SARIIŞIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "ATAKUM",
        "STREET": "SARITAŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "ATAKUM",
        "STREET": "SARIYUSUF MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "ATAKUM",
        "STREET": "TAFLAN MERKEZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "ATAKUM",
        "STREET": "YALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "ATAKUM",
        "STREET": "YENİMAHALLE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "ATAKUM",
        "STREET": "YEŞİLDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "ATAKUM",
        "STREET": "YEŞİLTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "ATAKUM",
        "STREET": "YEŞİLYURT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "ATAKUM",
        "STREET": "YUKARIAKSU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "ATAKUM",
        "STREET": "ÇAKIRLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "ATAKUM",
        "STREET": "ÇAKIRLAR YALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "ATAKUM",
        "STREET": "ÇAMLIYAZI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "ATAKUM",
        "STREET": "ÇATMAOLUK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "ATAKUM",
        "STREET": "ÇOBANLI  MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "ATAKUM",
        "STREET": "ÇOBANÖZÜ  MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "ATAKUM",
        "STREET": "ÖZÖREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "ATAKUM",
        "STREET": "İNCESU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "ATAKUM",
        "STREET": "İNCESU YALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "ATAKUM",
        "STREET": "İSTİKLAL  MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "ATAKUM",
        "STREET": "ŞENYURT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "AYVACIK",
        "STREET": "ARDIÇ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "AYVACIK",
        "STREET": "BAŞALAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "AYVACIK",
        "STREET": "DÖNGEL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "AYVACIK",
        "STREET": "DÖNGELYATAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "AYVACIK",
        "STREET": "ESENYURT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "AYVACIK",
        "STREET": "EYNEL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "AYVACIK",
        "STREET": "EYÜPSULTAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "AYVACIK",
        "STREET": "FATİH SULTAN MEHMET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "AYVACIK",
        "STREET": "GÜLPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "AYVACIK",
        "STREET": "GÜLTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "AYVACIK",
        "STREET": "GÜRÇAM MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "AYVACIK",
        "STREET": "GÜZELYURT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "AYVACIK",
        "STREET": "KABAKLIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "AYVACIK",
        "STREET": "KAPIKAYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "AYVACIK",
        "STREET": "KARAAĞAÇ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "AYVACIK",
        "STREET": "KARŞIDÖNGEL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "AYVACIK",
        "STREET": "KAZANCILI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "AYVACIK",
        "STREET": "KESKİNOĞLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "AYVACIK",
        "STREET": "KOÇYURDU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "AYVACIK",
        "STREET": "MEŞELİDÜZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "AYVACIK",
        "STREET": "ORHANGAZİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "AYVACIK",
        "STREET": "ORTA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "AYVACIK",
        "STREET": "ORTAKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "AYVACIK",
        "STREET": "OSMANLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "AYVACIK",
        "STREET": "SAHİLKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "AYVACIK",
        "STREET": "SÖĞÜTPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "AYVACIK",
        "STREET": "TEPEALTI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "AYVACIK",
        "STREET": "TERİCE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "AYVACIK",
        "STREET": "TİRYAKİOĞLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "AYVACIK",
        "STREET": "UĞURLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "AYVACIK",
        "STREET": "YENİCE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "AYVACIK",
        "STREET": "YENİKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "AYVACIK",
        "STREET": "YEŞİLDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "AYVACIK",
        "STREET": "YEŞİLPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "AYVACIK",
        "STREET": "YEŞİLÇAM MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "AYVACIK",
        "STREET": "YUNUS EMRE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "AYVACIK",
        "STREET": "ZAFER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "AYVACIK",
        "STREET": "ÇAMALAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "AYVACIK",
        "STREET": "ÇAMLIKALE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "AYVACIK",
        "STREET": "ÇARŞIKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "AYVACIK",
        "STREET": "ÇÖKEKLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "AYVACIK",
        "STREET": "ÖRENCİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "AYVACIK",
        "STREET": "ŞENPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "BAFRA",
        "STREET": "ADAKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "BAFRA",
        "STREET": "AKALAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "BAFRA",
        "STREET": "AKTEKKE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "BAFRA",
        "STREET": "ALAÇAM MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "BAFRA",
        "STREET": "ALPARSLAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "BAFRA",
        "STREET": "ALTINAY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "BAFRA",
        "STREET": "ALTINKAYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "BAFRA",
        "STREET": "ALTINOVA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "BAFRA",
        "STREET": "ALTINYAPRAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "BAFRA",
        "STREET": "ASAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "BAFRA",
        "STREET": "ASMAÇAM MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "BAFRA",
        "STREET": "AZAY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "BAFRA",
        "STREET": "AĞCAALAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "BAFRA",
        "STREET": "AĞILLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "BAFRA",
        "STREET": "BAFRA T TİPİ KAPALI CEZA İNFAZ KURUMU",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "BAFRA",
        "STREET": "BAHÇELER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "BAFRA",
        "STREET": "BAKIRPINARI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "BAFRA",
        "STREET": "BALIKLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "BAFRA",
        "STREET": "BARIŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "BAFRA",
        "STREET": "BAŞARAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "BAFRA",
        "STREET": "BAŞKAYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "BAFRA",
        "STREET": "BENGÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "BAFRA",
        "STREET": "BOĞAZKAYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "BAFRA",
        "STREET": "BURUNCA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "BAFRA",
        "STREET": "BÜYÜKCAMİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "BAFRA",
        "STREET": "CUMHURİYET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "BAFRA",
        "STREET": "DARBOĞAZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "BAFRA",
        "STREET": "DEDEDAĞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "BAFRA",
        "STREET": "DEDELİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "BAFRA",
        "STREET": "DERBENT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "BAFRA",
        "STREET": "DERELER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "BAFRA",
        "STREET": "DOĞANCA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "BAFRA",
        "STREET": "DOĞANKAYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "BAFRA",
        "STREET": "DÜZKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "BAFRA",
        "STREET": "DİKENCİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "BAFRA",
        "STREET": "ELALAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "BAFRA",
        "STREET": "ELDAVUT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "BAFRA",
        "STREET": "ELİFLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "BAFRA",
        "STREET": "EMENLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "BAFRA",
        "STREET": "EMİREFENDİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "BAFRA",
        "STREET": "ESENÇAY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "BAFRA",
        "STREET": "EVRENUŞAĞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "BAFRA",
        "STREET": "EYNEGAZİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "BAFRA",
        "STREET": "FATİH MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "BAFRA",
        "STREET": "FENER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "BAFRA",
        "STREET": "FEVZİ ÇAKMAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "BAFRA",
        "STREET": "GAZİBEYLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "BAFRA",
        "STREET": "GAZİOSMANPAŞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "BAFRA",
        "STREET": "GAZİPAŞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "BAFRA",
        "STREET": "GERZELİLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "BAFRA",
        "STREET": "GÖKALAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "BAFRA",
        "STREET": "GÖKÇEAĞAÇ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "BAFRA",
        "STREET": "GÖKÇEKENT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "BAFRA",
        "STREET": "GÖKÇESU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "BAFRA",
        "STREET": "GÖLTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "BAFRA",
        "STREET": "GÜMÜŞYAPRAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "BAFRA",
        "STREET": "HACINABİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "BAFRA",
        "STREET": "HACIOĞLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "BAFRA",
        "STREET": "HARIZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "BAFRA",
        "STREET": "HIDIRELLEZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "BAFRA",
        "STREET": "HÜSEYİNBEYLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "BAFRA",
        "STREET": "KAHRAMAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "BAFRA",
        "STREET": "KALAYCILI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "BAFRA",
        "STREET": "KAMBERLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "BAFRA",
        "STREET": "KANLIGÜNEY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "BAFRA",
        "STREET": "KAPIKAYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "BAFRA",
        "STREET": "KARABURÇ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "BAFRA",
        "STREET": "KARAKÜTÜK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "BAFRA",
        "STREET": "KARINCAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "BAFRA",
        "STREET": "KARPUZLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "BAFRA",
        "STREET": "KAVAKPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "BAFRA",
        "STREET": "KAYGUSUZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "BAFRA",
        "STREET": "KELİKLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "BAFRA",
        "STREET": "KEMALPAŞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "BAFRA",
        "STREET": "KERESTECİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "BAFRA",
        "STREET": "KIZILIRMAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "BAFRA",
        "STREET": "KOLAY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "BAFRA",
        "STREET": "KOMŞUPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "BAFRA",
        "STREET": "KORULUK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "BAFRA",
        "STREET": "KOZAĞZI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "BAFRA",
        "STREET": "KOŞU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "BAFRA",
        "STREET": "KUZALAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "BAFRA",
        "STREET": "KUŞLUĞAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "BAFRA",
        "STREET": "KUŞÇULAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "BAFRA",
        "STREET": "KÖSELİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "BAFRA",
        "STREET": "KÜÇÜKKAVAKPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "BAFRA",
        "STREET": "LENGERLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "BAFRA",
        "STREET": "MEVLANA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "BAFRA",
        "STREET": "MEŞELİTÜRKMENLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "BAFRA",
        "STREET": "MÜSTECEP MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "BAFRA",
        "STREET": "ORTADURAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "BAFRA",
        "STREET": "OSMANBEYLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "BAFRA",
        "STREET": "OZAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "BAFRA",
        "STREET": "PAŞAŞEYH MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "BAFRA",
        "STREET": "SAHİLKENT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "BAFRA",
        "STREET": "SARIKAYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "BAFRA",
        "STREET": "SARIKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "BAFRA",
        "STREET": "SARIÇEVRE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "BAFRA",
        "STREET": "SARPIN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "BAFRA",
        "STREET": "SELEMELİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "BAFRA",
        "STREET": "SÜRMELİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "BAFRA",
        "STREET": "TABAKHANE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "BAFRA",
        "STREET": "TAŞKÖPRÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "BAFRA",
        "STREET": "TEPEBAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "BAFRA",
        "STREET": "TEPECİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "BAFRA",
        "STREET": "TERZİLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "BAFRA",
        "STREET": "TÜRBE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "BAFRA",
        "STREET": "TÜRKKÖYÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "BAFRA",
        "STREET": "TÜTÜNCÜLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "BAFRA",
        "STREET": "ULUAĞAÇ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "BAFRA",
        "STREET": "YAKA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "BAFRA",
        "STREET": "YAKINTAŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "BAFRA",
        "STREET": "YAĞMURCA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "BAFRA",
        "STREET": "YENİALAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "BAFRA",
        "STREET": "YENİKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "BAFRA",
        "STREET": "YERALTI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "BAFRA",
        "STREET": "YEŞİLKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "BAFRA",
        "STREET": "YEŞİLYAZI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "BAFRA",
        "STREET": "YÖRGÜÇ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "BAFRA",
        "STREET": "YİĞİTALAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "BAFRA",
        "STREET": "ÇALKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "BAFRA",
        "STREET": "ÇAMALTI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "BAFRA",
        "STREET": "ÇATAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "BAFRA",
        "STREET": "ÇATALTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "BAFRA",
        "STREET": "ÇETİNKAYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "BAFRA",
        "STREET": "ÇULHAKOCA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "BAFRA",
        "STREET": "ÇİLHANE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "BAFRA",
        "STREET": "ÖRENCİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "BAFRA",
        "STREET": "ÜÇPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "BAFRA",
        "STREET": "İKİZPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "BAFRA",
        "STREET": "İKİZTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "BAFRA",
        "STREET": "İLYASLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "BAFRA",
        "STREET": "İNÖZÜKOŞACA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "BAFRA",
        "STREET": "İSHAKLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "BAFRA",
        "STREET": "İSMETPAŞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "BAFRA",
        "STREET": "İĞDİR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "BAFRA",
        "STREET": "ŞAHİNKAYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "BAFRA",
        "STREET": "ŞEYHULAŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "BAFRA",
        "STREET": "ŞEYHÖREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "BAFRA",
        "STREET": "ŞİRİNKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "CANİK",
        "STREET": "ADATEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "CANİK",
        "STREET": "ALİBEYLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "CANİK",
        "STREET": "AMBARPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "CANİK",
        "STREET": "ATATÜRK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "CANİK",
        "STREET": "AVLUCA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "CANİK",
        "STREET": "BAŞALAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "CANİK",
        "STREET": "BAŞKONAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "CANİK",
        "STREET": "BELEDİYE EVLERİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "CANİK",
        "STREET": "DEMİRCİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "CANİK",
        "STREET": "DERELER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "CANİK",
        "STREET": "DEVGERİŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "CANİK",
        "STREET": "DÜVECİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "CANİK",
        "STREET": "DÜZARDIÇ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "CANİK",
        "STREET": "DÜZÖREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "CANİK",
        "STREET": "GAZİOSMANPAŞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "CANİK",
        "STREET": "GECEHAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "CANİK",
        "STREET": "GÖDEKLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "CANİK",
        "STREET": "GÖKÇEPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "CANİK",
        "STREET": "GÖLALAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "CANİK",
        "STREET": "GÜLTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "CANİK",
        "STREET": "GÜRGENYATAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "CANİK",
        "STREET": "HACI İSMAİL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "CANİK",
        "STREET": "HACINAİPLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "CANİK",
        "STREET": "HASKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "CANİK",
        "STREET": "HİLALTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "CANİK",
        "STREET": "KALEBOĞAZI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "CANİK",
        "STREET": "KARŞIYAKA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "CANİK",
        "STREET": "KAŞYAYLA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "CANİK",
        "STREET": "KESTANEPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "CANİK",
        "STREET": "KIZILOĞLAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "CANİK",
        "STREET": "KOZLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "CANİK",
        "STREET": "KUZEY YILDIZI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "CANİK",
        "STREET": "MURATLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "CANİK",
        "STREET": "SARIBIYIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "CANİK",
        "STREET": "SOĞUKSU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "CANİK",
        "STREET": "TEKKİRAZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "CANİK",
        "STREET": "TEKNEPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "CANİK",
        "STREET": "TOPTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "CANİK",
        "STREET": "TOYGAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "CANİK",
        "STREET": "TUZAKLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "CANİK",
        "STREET": "ULUDAĞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "CANİK",
        "STREET": "ULUÇAYIR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "CANİK",
        "STREET": "YAVUZ SELİM MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "CANİK",
        "STREET": "YAYLA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "CANİK",
        "STREET": "YENİKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "CANİK",
        "STREET": "YENİMAHALLE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "CANİK",
        "STREET": "YEŞİLPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "CANİK",
        "STREET": "ÇAMALAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "CANİK",
        "STREET": "ÇAĞLAYAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "CANİK",
        "STREET": "ÜÇPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "CANİK",
        "STREET": "İKİYÜZ EVLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "CANİK",
        "STREET": "İMAMLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "HAVZA",
        "STREET": "19 MAYIS MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "HAVZA",
        "STREET": "25 MAYIS MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "HAVZA",
        "STREET": "ARSLANÇAYIRI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "HAVZA",
        "STREET": "AĞCAMAHMUT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "HAVZA",
        "STREET": "AĞDIRHASAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "HAVZA",
        "STREET": "AŞAĞI YAVUCAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "HAVZA",
        "STREET": "AŞAĞISUSUZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "HAVZA",
        "STREET": "BAHÇELİEVLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "HAVZA",
        "STREET": "BAŞPELİT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "HAVZA",
        "STREET": "BEKDİĞİN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "HAVZA",
        "STREET": "BELALAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "HAVZA",
        "STREET": "BEYKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "HAVZA",
        "STREET": "BEYÖREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "HAVZA",
        "STREET": "BOYALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "HAVZA",
        "STREET": "BOYALICA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "HAVZA",
        "STREET": "CELİL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "HAVZA",
        "STREET": "CEVİZLİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "HAVZA",
        "STREET": "DEMİRYURT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "HAVZA",
        "STREET": "DEREKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "HAVZA",
        "STREET": "DEĞİRMENÜSTÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "HAVZA",
        "STREET": "DOĞANÇAYIR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "HAVZA",
        "STREET": "DÜNDARLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "HAVZA",
        "STREET": "EKİNPINARI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "HAVZA",
        "STREET": "ELMACIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "HAVZA",
        "STREET": "ERELİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "HAVZA",
        "STREET": "ERSANDIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "HAVZA",
        "STREET": "ERİKBELEN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "HAVZA",
        "STREET": "ESENBEY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "HAVZA",
        "STREET": "EYMİR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "HAVZA",
        "STREET": "GELİNCİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "HAVZA",
        "STREET": "GÖÇMENLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "HAVZA",
        "STREET": "GÜVERCİNLİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "HAVZA",
        "STREET": "GİDİRLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "HAVZA",
        "STREET": "HACIBATTAL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "HAVZA",
        "STREET": "HACIDEDE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "HAVZA",
        "STREET": "HECİNLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "HAVZA",
        "STREET": "HİLMİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "HAVZA",
        "STREET": "ILICA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "HAVZA",
        "STREET": "KALEKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "HAVZA",
        "STREET": "KAMLIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "HAVZA",
        "STREET": "KARABÜK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "HAVZA",
        "STREET": "KARAGEÇMİŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "HAVZA",
        "STREET": "KARAHALİL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "HAVZA",
        "STREET": "KARAMEŞE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "HAVZA",
        "STREET": "KARGA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "HAVZA",
        "STREET": "KARŞIYAKA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "HAVZA",
        "STREET": "KAYABAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "HAVZA",
        "STREET": "KAYACIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "HAVZA",
        "STREET": "KEMALİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "HAVZA",
        "STREET": "KIROĞLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "HAVZA",
        "STREET": "KOCAOĞLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "HAVZA",
        "STREET": "KOCAPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "HAVZA",
        "STREET": "KUŞKONAĞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "HAVZA",
        "STREET": "KÜFLEK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "HAVZA",
        "STREET": "KİRENLİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "HAVZA",
        "STREET": "MEDRESE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "HAVZA",
        "STREET": "MEMDUHİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "HAVZA",
        "STREET": "MERYEMDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "HAVZA",
        "STREET": "MESUDİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "HAVZA",
        "STREET": "MISMILAĞAÇ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "HAVZA",
        "STREET": "MÜRSEL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "HAVZA",
        "STREET": "ORHANİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "HAVZA",
        "STREET": "ORTAKLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "HAVZA",
        "STREET": "PAŞAPINARI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "HAVZA",
        "STREET": "PINARÇAY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "HAVZA",
        "STREET": "SOFULAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "HAVZA",
        "STREET": "SONDAJ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "HAVZA",
        "STREET": "SİVRİKESE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "HAVZA",
        "STREET": "TAŞKARACAÖREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "HAVZA",
        "STREET": "TEKKEKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "HAVZA",
        "STREET": "TUZLA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "HAVZA",
        "STREET": "ULUÇAL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "HAVZA",
        "STREET": "YAYLAÇATI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "HAVZA",
        "STREET": "YAZIKIŞLA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "HAVZA",
        "STREET": "YAĞCIMAHMUT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "HAVZA",
        "STREET": "YENİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "HAVZA",
        "STREET": "YENİCE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "HAVZA",
        "STREET": "YENİMESCİT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "HAVZA",
        "STREET": "YEŞİLALAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "HAVZA",
        "STREET": "YUKARISUSUZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "HAVZA",
        "STREET": "YUKARIYAVUCAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "HAVZA",
        "STREET": "ÇAKIRALAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "HAVZA",
        "STREET": "ÇAMYATAĞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "HAVZA",
        "STREET": "ÇAY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "HAVZA",
        "STREET": "ÇAYIRÖZÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "HAVZA",
        "STREET": "ÇELTEK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "HAVZA",
        "STREET": "ÇELİKALAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "HAVZA",
        "STREET": "ÇİFTLİKKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "HAVZA",
        "STREET": "ÜNİVERSİTE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "HAVZA",
        "STREET": "İCADİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "HAVZA",
        "STREET": "İMARET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "HAVZA",
        "STREET": "İMİRCİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "HAVZA",
        "STREET": "İNÖNÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "HAVZA",
        "STREET": "ŞERİFALİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "HAVZA",
        "STREET": "ŞEYHALİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "HAVZA",
        "STREET": "ŞEYHKOYUN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "HAVZA",
        "STREET": "ŞEYHLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "HAVZA",
        "STREET": "ŞEYHSAFİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "KAVAK",
        "STREET": "19 MAYIS MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "KAVAK",
        "STREET": "AHURLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "KAVAK",
        "STREET": "AKBELEN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "KAVAK",
        "STREET": "ALAGÖMLEK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "KAVAK",
        "STREET": "ALAÇAM MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "KAVAK",
        "STREET": "ATAYURT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "KAVAK",
        "STREET": "AZAKLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "KAVAK",
        "STREET": "AĞCAKİSE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "KAVAK",
        "STREET": "AŞAĞIÇİRİŞLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "KAVAK",
        "STREET": "BAHÇELİEVLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "KAVAK",
        "STREET": "BAYINDIR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "KAVAK",
        "STREET": "BAYRAKLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "KAVAK",
        "STREET": "BAŞALAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "KAVAK",
        "STREET": "BEKDEMİR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "KAVAK",
        "STREET": "BELALAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "KAVAK",
        "STREET": "BEYBESLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "KAVAK",
        "STREET": "BEYKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "KAVAK",
        "STREET": "BOĞAZİÇİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "KAVAK",
        "STREET": "BÜKCEĞİZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "KAVAK",
        "STREET": "BÜYÜKÇUKUR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "KAVAK",
        "STREET": "CELALLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "KAVAK",
        "STREET": "DEMİRCİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "KAVAK",
        "STREET": "DEREKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "KAVAK",
        "STREET": "DEĞİRMENCİLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "KAVAK",
        "STREET": "DORUK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "KAVAK",
        "STREET": "DUMAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "KAVAK",
        "STREET": "DURA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "KAVAK",
        "STREET": "DİVANBAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "KAVAK",
        "STREET": "EMİRLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "KAVAK",
        "STREET": "GERMİYAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "KAVAK",
        "STREET": "GÖÇEBE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "KAVAK",
        "STREET": "GÜNEYCE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "KAVAK",
        "STREET": "HACILI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "KAVAK",
        "STREET": "ILICAKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "KAVAK",
        "STREET": "KAPUHAYAT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "KAVAK",
        "STREET": "KARAASLAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "KAVAK",
        "STREET": "KARACALAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "KAVAK",
        "STREET": "KARADAĞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "KAVAK",
        "STREET": "KARANTU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "KAVAK",
        "STREET": "KARAPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "KAVAK",
        "STREET": "KARAYUSUFLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "KAVAK",
        "STREET": "KARLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "KAVAK",
        "STREET": "KARTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "KAVAK",
        "STREET": "KAVAK S TİPİ KAPALI CEZA İNFAZ KURUMU",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "KAVAK",
        "STREET": "KAVAK ÇOCUK VE GENÇLİK KAPALI CEZA İNFAZ KURUMU",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "KAVAK",
        "STREET": "KAYABAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "KAVAK",
        "STREET": "KAYAKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "KAVAK",
        "STREET": "KAZANCI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "KAVAK",
        "STREET": "KETHÜDA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "KAVAK",
        "STREET": "KOZANSIKI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "KAVAK",
        "STREET": "KURŞUNLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "KAVAK",
        "STREET": "KUZALAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "KAVAK",
        "STREET": "KÖSELİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "KAVAK",
        "STREET": "KÜÇÜKÇUKUR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "KAVAK",
        "STREET": "MAHMUTBEYLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "KAVAK",
        "STREET": "MAHMUTLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "KAVAK",
        "STREET": "MEHMETPAŞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "KAVAK",
        "STREET": "MERT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "KAVAK",
        "STREET": "MUHSİNLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "KAVAK",
        "STREET": "MURATBEYLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "KAVAK",
        "STREET": "ORTAKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "KAVAK",
        "STREET": "SARALAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "KAVAK",
        "STREET": "SEYİTALİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "KAVAK",
        "STREET": "SIRALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "KAVAK",
        "STREET": "SOĞUKSU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "KAVAK",
        "STREET": "SUSUZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "KAVAK",
        "STREET": "TABAKLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "KAVAK",
        "STREET": "TALIŞMAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "KAVAK",
        "STREET": "TATARMUSLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "KAVAK",
        "STREET": "TEKKEKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "KAVAK",
        "STREET": "TEPECİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "KAVAK",
        "STREET": "TOPTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "KAVAK",
        "STREET": "YAŞAR DOĞU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "KAVAK",
        "STREET": "YENİCAMİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "KAVAK",
        "STREET": "YENİGÜN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "KAVAK",
        "STREET": "YERALAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "KAVAK",
        "STREET": "YOSUNLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "KAVAK",
        "STREET": "YUKARI DEREKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "KAVAK",
        "STREET": "YUKARIÇİRİŞLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "KAVAK",
        "STREET": "ÇAKALLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "KAVAK",
        "STREET": "ÇALBAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "KAVAK",
        "STREET": "ÇARIKLIBAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "KAVAK",
        "STREET": "ÇATALTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "KAVAK",
        "STREET": "ÇAYIRLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "KAVAK",
        "STREET": "ÇUKURBÜK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "KAVAK",
        "STREET": "ÇİVRİL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "KAVAK",
        "STREET": "ÇİÇEKYAZI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "KAVAK",
        "STREET": "ÇİĞDEM MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "KAVAK",
        "STREET": "ÜÇHANLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "KAVAK",
        "STREET": "İDRİSLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "KAVAK",
        "STREET": "İKİZDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "KAVAK",
        "STREET": "ŞEYHLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "KAVAK",
        "STREET": "ŞEYHRESUL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "LADİK",
        "STREET": "AHMETSARAY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "LADİK",
        "STREET": "AKPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "LADİK",
        "STREET": "AKTAŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "LADİK",
        "STREET": "AKYAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "LADİK",
        "STREET": "ALAYURT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "LADİK",
        "STREET": "ALIÇLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "LADİK",
        "STREET": "ARSLANTAŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "LADİK",
        "STREET": "AYVALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "LADİK",
        "STREET": "AYVALISOKAĞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "LADİK",
        "STREET": "AĞCAKAYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "LADİK",
        "STREET": "AŞAĞIGÖLYAZI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "LADİK",
        "STREET": "BAHŞİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "LADİK",
        "STREET": "BAŞLAMIŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "LADİK",
        "STREET": "BOLAT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "LADİK",
        "STREET": "BUDAKDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "LADİK",
        "STREET": "BÜYÜKALAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "LADİK",
        "STREET": "BÜYÜKKIZOĞLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "LADİK",
        "STREET": "CÜCE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "LADİK",
        "STREET": "DALDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "LADİK",
        "STREET": "DELİAHMETOĞLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "LADİK",
        "STREET": "DERİNÖZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "LADİK",
        "STREET": "DOĞANKAŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "LADİK",
        "STREET": "EYNEKARACA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "LADİK",
        "STREET": "GÜNKORU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "LADİK",
        "STREET": "GÜRÜN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "LADİK",
        "STREET": "GÜVENLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "LADİK",
        "STREET": "HACIALİPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "LADİK",
        "STREET": "HAMAMAYAĞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "LADİK",
        "STREET": "HAMİTKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "LADİK",
        "STREET": "HASIRCI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "LADİK",
        "STREET": "HIZARBAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "LADİK",
        "STREET": "KABACAGÖZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "LADİK",
        "STREET": "KARAABDAL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "LADİK",
        "STREET": "KIRANBOĞAZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "LADİK",
        "STREET": "KIZILSİNİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "LADİK",
        "STREET": "KOĞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "LADİK",
        "STREET": "KUYUCAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "LADİK",
        "STREET": "KÖSEOĞLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "LADİK",
        "STREET": "KÜPECİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "LADİK",
        "STREET": "KÜÇÜKKIZOĞLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "LADİK",
        "STREET": "KİRAZPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "LADİK",
        "STREET": "MAZLUMOĞLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "LADİK",
        "STREET": "MEŞEPINARI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "LADİK",
        "STREET": "NUSRATLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "LADİK",
        "STREET": "OYMAPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "LADİK",
        "STREET": "SALUR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "LADİK",
        "STREET": "SANAYİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "LADİK",
        "STREET": "SARAY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "LADİK",
        "STREET": "SARIGAZEL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "LADİK",
        "STREET": "SARIKSIZOĞLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "LADİK",
        "STREET": "SOĞANLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "LADİK",
        "STREET": "SÖĞÜTLÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "LADİK",
        "STREET": "TATLICAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "LADİK",
        "STREET": "TEBEROĞLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "LADİK",
        "STREET": "TÜFEKÇİDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "LADİK",
        "STREET": "YENİCAMİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "LADİK",
        "STREET": "YUKARIGÖLYAZI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "LADİK",
        "STREET": "YUMAKLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "LADİK",
        "STREET": "YUVACIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "LADİK",
        "STREET": "ÇADIRKAYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "LADİK",
        "STREET": "ÇAKIRGÜMÜŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "LADİK",
        "STREET": "ÇAMLIKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "LADİK",
        "STREET": "İBİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "LADİK",
        "STREET": "İSASOFTA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "LADİK",
        "STREET": "İSKANİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "LADİK",
        "STREET": "ŞEHREKÜSTÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "LADİK",
        "STREET": "ŞIHLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "SALIPAZARI",
        "STREET": "ALAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "SALIPAZARI",
        "STREET": "ALANYAYKIN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "SALIPAZARI",
        "STREET": "ALBAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "SALIPAZARI",
        "STREET": "AVUT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "SALIPAZARI",
        "STREET": "BEREKET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "SALIPAZARI",
        "STREET": "BİÇME MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "SALIPAZARI",
        "STREET": "CEVİZLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "SALIPAZARI",
        "STREET": "DÜZKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "SALIPAZARI",
        "STREET": "DİKENCİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "SALIPAZARI",
        "STREET": "ESATÇİFTLİĞİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "SALIPAZARI",
        "STREET": "FATSALILAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "SALIPAZARI",
        "STREET": "FINDIKLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "SALIPAZARI",
        "STREET": "FİDANCIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "SALIPAZARI",
        "STREET": "GÖKÇEBAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "SALIPAZARI",
        "STREET": "GÖKÇELİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "SALIPAZARI",
        "STREET": "GÜZELVATAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "SALIPAZARI",
        "STREET": "KALFALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "SALIPAZARI",
        "STREET": "KARACALAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "SALIPAZARI",
        "STREET": "KARACAÖREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "SALIPAZARI",
        "STREET": "KARADERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "SALIPAZARI",
        "STREET": "KARAMAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "SALIPAZARI",
        "STREET": "KARAYONCA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "SALIPAZARI",
        "STREET": "KIRGIL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "SALIPAZARI",
        "STREET": "KIZILOT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "SALIPAZARI",
        "STREET": "KOCALAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "SALIPAZARI",
        "STREET": "KONAKÖREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "SALIPAZARI",
        "STREET": "KUŞCUĞAZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "SALIPAZARI",
        "STREET": "MERKEZ KOCALAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "SALIPAZARI",
        "STREET": "MUMLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "SALIPAZARI",
        "STREET": "MUSLUBEY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "SALIPAZARI",
        "STREET": "ORTA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "SALIPAZARI",
        "STREET": "SARIHASAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "SALIPAZARI",
        "STREET": "SULUCA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "SALIPAZARI",
        "STREET": "TACALAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "SALIPAZARI",
        "STREET": "TAHNAL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "SALIPAZARI",
        "STREET": "TEPEALTI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "SALIPAZARI",
        "STREET": "YAVAŞBEY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "SALIPAZARI",
        "STREET": "YAYLA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "SALIPAZARI",
        "STREET": "YENİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "SALIPAZARI",
        "STREET": "YENİDOĞAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "SALIPAZARI",
        "STREET": "YEŞİL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "SALIPAZARI",
        "STREET": "YUKARIKESTANEPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "SALIPAZARI",
        "STREET": "ÇAĞLAYAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "SALIPAZARI",
        "STREET": "ÇİÇEKLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "TEKKEKÖY",
        "STREET": "19 MAYIS MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "TEKKEKÖY",
        "STREET": "23 NİSAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "TEKKEKÖY",
        "STREET": "AKBAŞLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "TEKKEKÖY",
        "STREET": "ALTINKAYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "TEKKEKÖY",
        "STREET": "ANTYERİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "TEKKEKÖY",
        "STREET": "ASARAĞAÇ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "TEKKEKÖY",
        "STREET": "BAKACAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "TEKKEKÖY",
        "STREET": "BALCALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "TEKKEKÖY",
        "STREET": "BAŞKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "TEKKEKÖY",
        "STREET": "BEYOĞLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "TEKKEKÖY",
        "STREET": "BÜYÜKLÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "TEKKEKÖY",
        "STREET": "CUMHURİYET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "TEKKEKÖY",
        "STREET": "ERENKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "TEKKEKÖY",
        "STREET": "FINDICAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "TEKKEKÖY",
        "STREET": "GÖKÇE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "TEKKEKÖY",
        "STREET": "GÖKÇEDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "TEKKEKÖY",
        "STREET": "GÖLCEĞİZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "TEKKEKÖY",
        "STREET": "GÜZELYURT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "TEKKEKÖY",
        "STREET": "HAMZALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "TEKKEKÖY",
        "STREET": "HÜRRİYET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "TEKKEKÖY",
        "STREET": "KABABÜRÜK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "TEKKEKÖY",
        "STREET": "KAHYALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "TEKKEKÖY",
        "STREET": "KARAOĞLAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "TEKKEKÖY",
        "STREET": "KARAPERÇİN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "TEKKEKÖY",
        "STREET": "KARGILI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "TEKKEKÖY",
        "STREET": "KARIŞLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "TEKKEKÖY",
        "STREET": "KERPİÇLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "TEKKEKÖY",
        "STREET": "KERİMBEY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "TEKKEKÖY",
        "STREET": "KIŞLA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "TEKKEKÖY",
        "STREET": "KURTULUŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "TEKKEKÖY",
        "STREET": "KUTLUKENT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "TEKKEKÖY",
        "STREET": "KÖPRÜBAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "TEKKEKÖY",
        "STREET": "KİBARLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "TEKKEKÖY",
        "STREET": "KİRAZLIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "TEKKEKÖY",
        "STREET": "OVABAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "TEKKEKÖY",
        "STREET": "PINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "TEKKEKÖY",
        "STREET": "SANAYİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "TEKKEKÖY",
        "STREET": "SARIYURT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "TEKKEKÖY",
        "STREET": "SELYERİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "TEKKEKÖY",
        "STREET": "SEYMENLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "TEKKEKÖY",
        "STREET": "SITMASUYU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "TEKKEKÖY",
        "STREET": "YAVUZLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "TEKKEKÖY",
        "STREET": "YAYLAGERİŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "TEKKEKÖY",
        "STREET": "YAZILAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "TEKKEKÖY",
        "STREET": "YAĞBASAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "TEKKEKÖY",
        "STREET": "YENİ BÜYÜKLÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "TEKKEKÖY",
        "STREET": "YENİDOĞAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "TEKKEKÖY",
        "STREET": "YENİKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "TEKKEKÖY",
        "STREET": "YEŞİLALAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "TEKKEKÖY",
        "STREET": "YEŞİLDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "TEKKEKÖY",
        "STREET": "YEŞİLYURT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "TEKKEKÖY",
        "STREET": "YUKARIÇİNİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "TEKKEKÖY",
        "STREET": "ZAFER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "TEKKEKÖY",
        "STREET": "ÇAY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "TEKKEKÖY",
        "STREET": "ÇAYIRÇÖKEK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "TEKKEKÖY",
        "STREET": "ÇAYLEYİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "TEKKEKÖY",
        "STREET": "ÇINARALAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "TEKKEKÖY",
        "STREET": "ÇIRAKMAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "TEKKEKÖY",
        "STREET": "ÇİFTLİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "TEKKEKÖY",
        "STREET": "ÇİMENLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "TEKKEKÖY",
        "STREET": "ÇİNİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "TEKKEKÖY",
        "STREET": "İSTİKLAL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "TEKKEKÖY",
        "STREET": "ŞABANOĞLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "TERME",
        "STREET": "AHMETBEY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "TERME",
        "STREET": "AKBUCAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "TERME",
        "STREET": "AKÇAGÜN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "TERME",
        "STREET": "AKÇAY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "TERME",
        "STREET": "AKÇAYKARACALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "TERME",
        "STREET": "ALTUNLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "TERME",
        "STREET": "AMBARTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "TERME",
        "STREET": "AYBEDER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "TERME",
        "STREET": "AŞAĞIHÜSEYİNMESCİT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "TERME",
        "STREET": "BAFRACALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "TERME",
        "STREET": "BAHÇELİEVLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "TERME",
        "STREET": "BAZLAMAÇ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "TERME",
        "STREET": "BAĞSARAY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "TERME",
        "STREET": "BEŞİKLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "TERME",
        "STREET": "CILAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "TERME",
        "STREET": "CUMHURİYET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "TERME",
        "STREET": "DAĞDIRALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "TERME",
        "STREET": "DEREYOL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "TERME",
        "STREET": "DUTLUK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "TERME",
        "STREET": "DİBEKLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "TERME",
        "STREET": "ELMAKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "TERME",
        "STREET": "ELMALIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "TERME",
        "STREET": "EMİRYUSUF MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "TERME",
        "STREET": "ERENKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "TERME",
        "STREET": "ETYEMEZLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "TERME",
        "STREET": "EVCİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "TERME",
        "STREET": "EĞERCİLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "TERME",
        "STREET": "FENK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "TERME",
        "STREET": "GEÇMİŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "TERME",
        "STREET": "GÖKÇEAĞAÇ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "TERME",
        "STREET": "GÖLYAZI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "TERME",
        "STREET": "GÜNDOĞDU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "TERME",
        "STREET": "HÜSEYİNMESCİT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "TERME",
        "STREET": "KARACAKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "TERME",
        "STREET": "KARACALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "TERME",
        "STREET": "KARAMAHMUT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "TERME",
        "STREET": "KARKUCAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "TERME",
        "STREET": "KAZIMKARABEKİRPAŞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "TERME",
        "STREET": "KESİKKAYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "TERME",
        "STREET": "KOCAMAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "TERME",
        "STREET": "KOCAMANBAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "TERME",
        "STREET": "KOZLUK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "TERME",
        "STREET": "KUMCIĞAZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "TERME",
        "STREET": "KUŞCA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "TERME",
        "STREET": "KUŞCULU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "TERME",
        "STREET": "KÖYBUCAĞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "TERME",
        "STREET": "MESCİTLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "TERME",
        "STREET": "MEŞEYAZI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "TERME",
        "STREET": "MURATLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "TERME",
        "STREET": "ORTASÖĞÜTLÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "TERME",
        "STREET": "OĞUZLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "TERME",
        "STREET": "SAKARLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "TERME",
        "STREET": "SANCAKLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "TERME",
        "STREET": "SARAYKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "TERME",
        "STREET": "SÖĞÜTLÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "TERME",
        "STREET": "SÜTÖZÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "TERME",
        "STREET": "SİVASLILAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "TERME",
        "STREET": "TAŞLIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "TERME",
        "STREET": "TAŞPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "TERME",
        "STREET": "ULUDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "TERME",
        "STREET": "UZUNGAZİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "TERME",
        "STREET": "YALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "TERME",
        "STREET": "YENİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "TERME",
        "STREET": "YENİCAMİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "TERME",
        "STREET": "YENİDOĞAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "TERME",
        "STREET": "YERLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "TERME",
        "STREET": "YUKARISÖĞÜTLÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "TERME",
        "STREET": "YUKARITAŞPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "TERME",
        "STREET": "YUNUS EMRE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "TERME",
        "STREET": "YÜKSEKYAYLA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "TERME",
        "STREET": "ÇAMLICA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "TERME",
        "STREET": "ÇANAKLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "TERME",
        "STREET": "ÇANGALLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "TERME",
        "STREET": "ÇARDAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "TERME",
        "STREET": "ÇAY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "TERME",
        "STREET": "ÇAĞLAYAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "TERME",
        "STREET": "ÖRENCİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "TERME",
        "STREET": "ÖZYURT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "TERME",
        "STREET": "İLİMDAĞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "TERME",
        "STREET": "İMANALİSİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "TERME",
        "STREET": "ŞEYHLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "TERME",
        "STREET": "ŞUAYİP MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "VEZİRKÖPRÜ",
        "STREET": "ABDULGANİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "VEZİRKÖPRÜ",
        "STREET": "ADATEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "VEZİRKÖPRÜ",
        "STREET": "AHMETBABA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "VEZİRKÖPRÜ",
        "STREET": "AKÖREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "VEZİRKÖPRÜ",
        "STREET": "ALAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "VEZİRKÖPRÜ",
        "STREET": "ALANBAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "VEZİRKÖPRÜ",
        "STREET": "ALANCIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "VEZİRKÖPRÜ",
        "STREET": "ALANŞEYHİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "VEZİRKÖPRÜ",
        "STREET": "ALTINKAYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "VEZİRKÖPRÜ",
        "STREET": "ARICA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "VEZİRKÖPRÜ",
        "STREET": "ATATÜRK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "VEZİRKÖPRÜ",
        "STREET": "AVDAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "VEZİRKÖPRÜ",
        "STREET": "AYDINLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "VEZİRKÖPRÜ",
        "STREET": "AYDOĞDU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "VEZİRKÖPRÜ",
        "STREET": "AYVALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "VEZİRKÖPRÜ",
        "STREET": "AĞCAALAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "VEZİRKÖPRÜ",
        "STREET": "AĞCAYAZI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "VEZİRKÖPRÜ",
        "STREET": "BAHÇEKONAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "VEZİRKÖPRÜ",
        "STREET": "BAHÇELİEVLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "VEZİRKÖPRÜ",
        "STREET": "BAHÇESARAY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "VEZİRKÖPRÜ",
        "STREET": "BAKLA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "VEZİRKÖPRÜ",
        "STREET": "BAYRAM MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "VEZİRKÖPRÜ",
        "STREET": "BAŞALAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "VEZİRKÖPRÜ",
        "STREET": "BAŞFAKI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "VEZİRKÖPRÜ",
        "STREET": "BEKTAŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "VEZİRKÖPRÜ",
        "STREET": "BELALAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "VEZİRKÖPRÜ",
        "STREET": "BEŞPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "VEZİRKÖPRÜ",
        "STREET": "BORUK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "VEZİRKÖPRÜ",
        "STREET": "BOĞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "VEZİRKÖPRÜ",
        "STREET": "BOĞAZKORU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "VEZİRKÖPRÜ",
        "STREET": "BURHANİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "VEZİRKÖPRÜ",
        "STREET": "BÜYÜKKALE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "VEZİRKÖPRÜ",
        "STREET": "CEBELİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "VEZİRKÖPRÜ",
        "STREET": "CUMHURİYET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "VEZİRKÖPRÜ",
        "STREET": "DARIÇAY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "VEZİRKÖPRÜ",
        "STREET": "DARIÇAYALANI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "VEZİRKÖPRÜ",
        "STREET": "DEVALAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "VEZİRKÖPRÜ",
        "STREET": "DEĞİRMENBAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "VEZİRKÖPRÜ",
        "STREET": "DOYRAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "VEZİRKÖPRÜ",
        "STREET": "DURUÇAY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "VEZİRKÖPRÜ",
        "STREET": "ELALDI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "VEZİRKÖPRÜ",
        "STREET": "ELBEĞİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "VEZİRKÖPRÜ",
        "STREET": "ELMALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "VEZİRKÖPRÜ",
        "STREET": "ESEN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "VEZİRKÖPRÜ",
        "STREET": "ESENTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "VEZİRKÖPRÜ",
        "STREET": "ESENYURT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "VEZİRKÖPRÜ",
        "STREET": "FAZIL AHMET PAŞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "VEZİRKÖPRÜ",
        "STREET": "GÖL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "VEZİRKÖPRÜ",
        "STREET": "GÖL YENİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "VEZİRKÖPRÜ",
        "STREET": "GÖLLÜALAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "VEZİRKÖPRÜ",
        "STREET": "GÖMLEKHİSAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "VEZİRKÖPRÜ",
        "STREET": "GÜDER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "VEZİRKÖPRÜ",
        "STREET": "GÜLDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "VEZİRKÖPRÜ",
        "STREET": "HABİBFAKI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "VEZİRKÖPRÜ",
        "STREET": "HACILI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "VEZİRKÖPRÜ",
        "STREET": "HALKAHAVLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "VEZİRKÖPRÜ",
        "STREET": "HALİLBABA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "VEZİRKÖPRÜ",
        "STREET": "HAYRANLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "VEZİRKÖPRÜ",
        "STREET": "HIDIRLIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "VEZİRKÖPRÜ",
        "STREET": "KABALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "VEZİRKÖPRÜ",
        "STREET": "KADIOĞLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "VEZİRKÖPRÜ",
        "STREET": "KADIÇAYIRI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "VEZİRKÖPRÜ",
        "STREET": "KAPAKLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "VEZİRKÖPRÜ",
        "STREET": "KAPAKLIEŞME MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "VEZİRKÖPRÜ",
        "STREET": "KAPLANCIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "VEZİRKÖPRÜ",
        "STREET": "KARABÜK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "VEZİRKÖPRÜ",
        "STREET": "KARACAÖREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "VEZİRKÖPRÜ",
        "STREET": "KARADORUK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "VEZİRKÖPRÜ",
        "STREET": "KARAKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "VEZİRKÖPRÜ",
        "STREET": "KARANAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "VEZİRKÖPRÜ",
        "STREET": "KARAPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "VEZİRKÖPRÜ",
        "STREET": "KARKUCAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "VEZİRKÖPRÜ",
        "STREET": "KARLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "VEZİRKÖPRÜ",
        "STREET": "KAVAKPINARI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "VEZİRKÖPRÜ",
        "STREET": "KILIÇGÜNEY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "VEZİRKÖPRÜ",
        "STREET": "KIRANALAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "VEZİRKÖPRÜ",
        "STREET": "KIRATBÜKÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "VEZİRKÖPRÜ",
        "STREET": "KIRMA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "VEZİRKÖPRÜ",
        "STREET": "KIZILCAKORU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "VEZİRKÖPRÜ",
        "STREET": "KIZILCAÖREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "VEZİRKÖPRÜ",
        "STREET": "KIZILKESE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "VEZİRKÖPRÜ",
        "STREET": "KOCAKAYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "VEZİRKÖPRÜ",
        "STREET": "KOVALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "VEZİRKÖPRÜ",
        "STREET": "KUMRAL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "VEZİRKÖPRÜ",
        "STREET": "KURUÇAY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "VEZİRKÖPRÜ",
        "STREET": "KUYAŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "VEZİRKÖPRÜ",
        "STREET": "KUYUMCU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "VEZİRKÖPRÜ",
        "STREET": "KUŞCULAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "VEZİRKÖPRÜ",
        "STREET": "KÖPRÜBAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "VEZİRKÖPRÜ",
        "STREET": "KÜLETEK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "VEZİRKÖPRÜ",
        "STREET": "KÜÇÜKKALE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "VEZİRKÖPRÜ",
        "STREET": "MAHMATLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "VEZİRKÖPRÜ",
        "STREET": "MEHMET PAŞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "VEZİRKÖPRÜ",
        "STREET": "MELİKLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "VEZİRKÖPRÜ",
        "STREET": "MEZRAA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "VEZİRKÖPRÜ",
        "STREET": "MEŞELİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "VEZİRKÖPRÜ",
        "STREET": "NALBANTLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "VEZİRKÖPRÜ",
        "STREET": "NARLISARAY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "VEZİRKÖPRÜ",
        "STREET": "ORTA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "VEZİRKÖPRÜ",
        "STREET": "ORTACAMİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "VEZİRKÖPRÜ",
        "STREET": "ORUÇ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "VEZİRKÖPRÜ",
        "STREET": "OVACIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "VEZİRKÖPRÜ",
        "STREET": "OYMAAĞAÇ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "VEZİRKÖPRÜ",
        "STREET": "PAZARCI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "VEZİRKÖPRÜ",
        "STREET": "PAŞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "VEZİRKÖPRÜ",
        "STREET": "SAMUKALAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "VEZİRKÖPRÜ",
        "STREET": "SAMUR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "VEZİRKÖPRÜ",
        "STREET": "SARAYCIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "VEZİRKÖPRÜ",
        "STREET": "SARAYLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "VEZİRKÖPRÜ",
        "STREET": "SARIALAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "VEZİRKÖPRÜ",
        "STREET": "SARIDİBEK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "VEZİRKÖPRÜ",
        "STREET": "SARIYAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "VEZİRKÖPRÜ",
        "STREET": "SIRBAŞMAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "VEZİRKÖPRÜ",
        "STREET": "SOFULAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "VEZİRKÖPRÜ",
        "STREET": "SOĞUCAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "VEZİRKÖPRÜ",
        "STREET": "SUSUZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "VEZİRKÖPRÜ",
        "STREET": "TABAKHANE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "VEZİRKÖPRÜ",
        "STREET": "TAHTAKÖPRÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "VEZİRKÖPRÜ",
        "STREET": "TATARKALE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "VEZİRKÖPRÜ",
        "STREET": "TAŞKALE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "VEZİRKÖPRÜ",
        "STREET": "TAŞLIYÜK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "VEZİRKÖPRÜ",
        "STREET": "TEBERÜK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "VEZİRKÖPRÜ",
        "STREET": "TEKKEKIRAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "VEZİRKÖPRÜ",
        "STREET": "TEPEÖREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "VEZİRKÖPRÜ",
        "STREET": "TÜRKMEN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "VEZİRKÖPRÜ",
        "STREET": "TİKENLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "VEZİRKÖPRÜ",
        "STREET": "VEZİRKÖPRÜ M TİPİ KAPALI CEZAEVİ",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "VEZİRKÖPRÜ",
        "STREET": "YARBAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "VEZİRKÖPRÜ",
        "STREET": "YAĞCI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "VEZİRKÖPRÜ",
        "STREET": "YAĞINÖZÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "VEZİRKÖPRÜ",
        "STREET": "YENİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "VEZİRKÖPRÜ",
        "STREET": "YENİDÜZCE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "VEZİRKÖPRÜ",
        "STREET": "YENİÇELİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "VEZİRKÖPRÜ",
        "STREET": "YEŞİLADA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "VEZİRKÖPRÜ",
        "STREET": "YEŞİLTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "VEZİRKÖPRÜ",
        "STREET": "YOLPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "VEZİRKÖPRÜ",
        "STREET": "YUKARINARLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "VEZİRKÖPRÜ",
        "STREET": "YURTDAĞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "VEZİRKÖPRÜ",
        "STREET": "YÜRÜKÇAL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "VEZİRKÖPRÜ",
        "STREET": "ÇAKIRTAŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "VEZİRKÖPRÜ",
        "STREET": "ÇAL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "VEZİRKÖPRÜ",
        "STREET": "ÇALMAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "VEZİRKÖPRÜ",
        "STREET": "ÇALTU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "VEZİRKÖPRÜ",
        "STREET": "ÇAMLICA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "VEZİRKÖPRÜ",
        "STREET": "ÇAMLIDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "VEZİRKÖPRÜ",
        "STREET": "ÇAMLIKONAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "VEZİRKÖPRÜ",
        "STREET": "ÇANAKLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "VEZİRKÖPRÜ",
        "STREET": "ÇAYIRBAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "VEZİRKÖPRÜ",
        "STREET": "ÇEKALAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "VEZİRKÖPRÜ",
        "STREET": "ÇEKMEDEN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "VEZİRKÖPRÜ",
        "STREET": "ÇELTEK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "VEZİRKÖPRÜ",
        "STREET": "ÇORAKDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "VEZİRKÖPRÜ",
        "STREET": "ÖRENCİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "VEZİRKÖPRÜ",
        "STREET": "ÖZESENTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "VEZİRKÖPRÜ",
        "STREET": "ÖZGÜNEY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "VEZİRKÖPRÜ",
        "STREET": "ÖZKURUÇAY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "VEZİRKÖPRÜ",
        "STREET": "ÖZYÖRÜK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "VEZİRKÖPRÜ",
        "STREET": "ÖĞÜRLÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "VEZİRKÖPRÜ",
        "STREET": "İMİRCİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "VEZİRKÖPRÜ",
        "STREET": "İNCESU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "VEZİRKÖPRÜ",
        "STREET": "İNKAYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "VEZİRKÖPRÜ",
        "STREET": "ŞENTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "YAKAKENT",
        "STREET": "ASMAPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "YAKAKENT",
        "STREET": "BÜYÜKKIRIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "YAKAKENT",
        "STREET": "GÜNDÜZLÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "YAKAKENT",
        "STREET": "KARAABA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "YAKAKENT",
        "STREET": "KAYALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "YAKAKENT",
        "STREET": "KOZKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "YAKAKENT",
        "STREET": "KUZÖREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "YAKAKENT",
        "STREET": "KÜPLÜAĞZI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "YAKAKENT",
        "STREET": "KÜRÜZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "YAKAKENT",
        "STREET": "LİMAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "YAKAKENT",
        "STREET": "MERKEZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "YAKAKENT",
        "STREET": "MUTAFLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "YAKAKENT",
        "STREET": "SARIGÖL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "YAKAKENT",
        "STREET": "YASSIDAĞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "YAKAKENT",
        "STREET": "YEŞİLKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "YAKAKENT",
        "STREET": "ÇAMALAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "YAKAKENT",
        "STREET": "ÇEPNİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "ÇARŞAMBA",
        "STREET": "ACIKLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "ÇARŞAMBA",
        "STREET": "AHUBABA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "ÇARŞAMBA",
        "STREET": "AKKUZULU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "ÇARŞAMBA",
        "STREET": "AKÇALTI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "ÇARŞAMBA",
        "STREET": "AKÇATARLA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "ÇARŞAMBA",
        "STREET": "ALLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "ÇARŞAMBA",
        "STREET": "ALİ FUAT BAŞGİL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "ÇARŞAMBA",
        "STREET": "ALİBEYLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "ÇARŞAMBA",
        "STREET": "ARIM KÖSELİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "ÇARŞAMBA",
        "STREET": "ATAKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "ÇARŞAMBA",
        "STREET": "AĞCAGÜNEY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "ÇARŞAMBA",
        "STREET": "AŞAĞI DONURLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "ÇARŞAMBA",
        "STREET": "AŞAĞI DİKENCİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "ÇARŞAMBA",
        "STREET": "AŞAĞI ESENLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "ÇARŞAMBA",
        "STREET": "AŞAĞI KAVACIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "ÇARŞAMBA",
        "STREET": "AŞAĞI MUSALLA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "ÇARŞAMBA",
        "STREET": "AŞAĞI TURGUTLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "ÇARŞAMBA",
        "STREET": "AŞIKLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "ÇARŞAMBA",
        "STREET": "BAFRACALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "ÇARŞAMBA",
        "STREET": "BAHÇELİEVLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "ÇARŞAMBA",
        "STREET": "BAYRAMLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "ÇARŞAMBA",
        "STREET": "BEYLERCE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "ÇARŞAMBA",
        "STREET": "BEYYENİCE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "ÇARŞAMBA",
        "STREET": "BEZİRGAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "ÇARŞAMBA",
        "STREET": "BOYACILI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "ÇARŞAMBA",
        "STREET": "BÖLMEÇAYIR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "ÇARŞAMBA",
        "STREET": "CANLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "ÇARŞAMBA",
        "STREET": "CUMHURİYET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "ÇARŞAMBA",
        "STREET": "DALBAHÇE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "ÇARŞAMBA",
        "STREET": "DAMLATAŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "ÇARŞAMBA",
        "STREET": "DEMİRASLAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "ÇARŞAMBA",
        "STREET": "DEMİRCİLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "ÇARŞAMBA",
        "STREET": "DEMİRLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "ÇARŞAMBA",
        "STREET": "DENİZLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "ÇARŞAMBA",
        "STREET": "DEYİNCEK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "ÇARŞAMBA",
        "STREET": "DURAKBAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "ÇARŞAMBA",
        "STREET": "DURUSU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "ÇARŞAMBA",
        "STREET": "DİKBIYIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "ÇARŞAMBA",
        "STREET": "EPÇELİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "ÇARŞAMBA",
        "STREET": "ESENTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "ÇARŞAMBA",
        "STREET": "ESENÇAY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "ÇARŞAMBA",
        "STREET": "EĞERCİLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "ÇARŞAMBA",
        "STREET": "EĞRİDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "ÇARŞAMBA",
        "STREET": "EĞRİKUM MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "ÇARŞAMBA",
        "STREET": "GAZİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "ÇARŞAMBA",
        "STREET": "GÖKÇELİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "ÇARŞAMBA",
        "STREET": "GÖKÇEÇAKMAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "ÇARŞAMBA",
        "STREET": "GÜLDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "ÇARŞAMBA",
        "STREET": "GÜLYAZI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "ÇARŞAMBA",
        "STREET": "GÜLÖREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "ÇARŞAMBA",
        "STREET": "GÜNEŞLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "ÇARŞAMBA",
        "STREET": "GÜZPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "ÇARŞAMBA",
        "STREET": "HACILIÇAY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "ÇARŞAMBA",
        "STREET": "HASBAHÇE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "ÇARŞAMBA",
        "STREET": "HELVACALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "ÇARŞAMBA",
        "STREET": "HÜRRİYET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "ÇARŞAMBA",
        "STREET": "IRMAKSIRTI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "ÇARŞAMBA",
        "STREET": "KABACEVİZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "ÇARŞAMBA",
        "STREET": "KARAAĞAÇ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "ÇARŞAMBA",
        "STREET": "KARABAHÇE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "ÇARŞAMBA",
        "STREET": "KARACALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "ÇARŞAMBA",
        "STREET": "KARAKAYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "ÇARŞAMBA",
        "STREET": "KARAKULAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "ÇARŞAMBA",
        "STREET": "KARAMUSTAFALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "ÇARŞAMBA",
        "STREET": "KAYDAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "ÇARŞAMBA",
        "STREET": "KEMER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "ÇARŞAMBA",
        "STREET": "KESTANEPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "ÇARŞAMBA",
        "STREET": "KIZILOT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "ÇARŞAMBA",
        "STREET": "KOCAKAVAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "ÇARŞAMBA",
        "STREET": "KOLDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "ÇARŞAMBA",
        "STREET": "KONUKLUK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "ÇARŞAMBA",
        "STREET": "KUMKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "ÇARŞAMBA",
        "STREET": "KUMTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "ÇARŞAMBA",
        "STREET": "KURTAHMETLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "ÇARŞAMBA",
        "STREET": "KURTULUŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "ÇARŞAMBA",
        "STREET": "KUŞHANE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "ÇARŞAMBA",
        "STREET": "KUŞÇULU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "ÇARŞAMBA",
        "STREET": "KÖKLÜK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "ÇARŞAMBA",
        "STREET": "KÖROĞLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "ÇARŞAMBA",
        "STREET": "KÜRTÜN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "ÇARŞAMBA",
        "STREET": "KİRAZBUCAĞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "ÇARŞAMBA",
        "STREET": "KİRAZLIKÇAY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "ÇARŞAMBA",
        "STREET": "MAHMUTLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "ÇARŞAMBA",
        "STREET": "MELİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "ÇARŞAMBA",
        "STREET": "MUSÇALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "ÇARŞAMBA",
        "STREET": "NAMAZLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "ÇARŞAMBA",
        "STREET": "ORDU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "ÇARŞAMBA",
        "STREET": "ORDUBAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "ÇARŞAMBA",
        "STREET": "ORTA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "ÇARŞAMBA",
        "STREET": "OTLUK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "ÇARŞAMBA",
        "STREET": "OVACIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "ÇARŞAMBA",
        "STREET": "OYMALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "ÇARŞAMBA",
        "STREET": "PAŞAYAZI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "ÇARŞAMBA",
        "STREET": "PORSUK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "ÇARŞAMBA",
        "STREET": "SAHİL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "ÇARŞAMBA",
        "STREET": "SARAÇLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "ÇARŞAMBA",
        "STREET": "SARICALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "ÇARŞAMBA",
        "STREET": "SEFALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "ÇARŞAMBA",
        "STREET": "SELİMİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "ÇARŞAMBA",
        "STREET": "SIĞIRTMAÇ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "ÇARŞAMBA",
        "STREET": "SOĞUCAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "ÇARŞAMBA",
        "STREET": "SUNGURLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "ÇARŞAMBA",
        "STREET": "SUĞLUCA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "ÇARŞAMBA",
        "STREET": "TATARLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "ÇARŞAMBA",
        "STREET": "TAŞDEMİR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "ÇARŞAMBA",
        "STREET": "TURGUTLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "ÇARŞAMBA",
        "STREET": "TİLKİLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "ÇARŞAMBA",
        "STREET": "ULUKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "ÇARŞAMBA",
        "STREET": "ULUPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "ÇARŞAMBA",
        "STREET": "UMUT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "ÇARŞAMBA",
        "STREET": "USTACALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "ÇARŞAMBA",
        "STREET": "UZUNLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "ÇARŞAMBA",
        "STREET": "VAKIFKÖPRÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "ÇARŞAMBA",
        "STREET": "YALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "ÇARŞAMBA",
        "STREET": "YAMANLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "ÇARŞAMBA",
        "STREET": "YAYCILAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "ÇARŞAMBA",
        "STREET": "YENİ KARACALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "ÇARŞAMBA",
        "STREET": "YENİ KIŞLA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "ÇARŞAMBA",
        "STREET": "YENİ KÖSELİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "ÇARŞAMBA",
        "STREET": "YEŞİLDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "ÇARŞAMBA",
        "STREET": "YEŞİLIRMAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "ÇARŞAMBA",
        "STREET": "YEŞİLOVA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "ÇARŞAMBA",
        "STREET": "YUKARI AĞCAGÜNEY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "ÇARŞAMBA",
        "STREET": "YUKARI DONURLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "ÇARŞAMBA",
        "STREET": "YUKARI DİKENCİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "ÇARŞAMBA",
        "STREET": "YUKARI ESENLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "ÇARŞAMBA",
        "STREET": "YUKARI KARABAHÇE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "ÇARŞAMBA",
        "STREET": "YUKARI KAVACIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "ÇARŞAMBA",
        "STREET": "ZÜMRÜT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "ÇARŞAMBA",
        "STREET": "ÇALTI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "ÇARŞAMBA",
        "STREET": "ÇAMLICA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "ÇARŞAMBA",
        "STREET": "ÇARŞAMBA A TİPİ KAPALI CEZAEVİ",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "ÇARŞAMBA",
        "STREET": "ÇATAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "ÇARŞAMBA",
        "STREET": "ÇAY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "ÇARŞAMBA",
        "STREET": "ÇAYVAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "ÇARŞAMBA",
        "STREET": "ÇELİKLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "ÇARŞAMBA",
        "STREET": "ÇERÇİLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "ÇARŞAMBA",
        "STREET": "ÇINARLIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "ÇARŞAMBA",
        "STREET": "ÖMERLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "ÇARŞAMBA",
        "STREET": "ÜÇKÖPRÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "ÇARŞAMBA",
        "STREET": "İSTİKLAL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "ÇARŞAMBA",
        "STREET": "ŞENYURT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "ÇARŞAMBA",
        "STREET": "ŞEYHGÜVEN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "ÇARŞAMBA",
        "STREET": "ŞEYHHABİL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "İLKADIM",
        "STREET": "19 MAYIS MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "İLKADIM",
        "STREET": "ADALET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "İLKADIM",
        "STREET": "AHULU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "İLKADIM",
        "STREET": "AKGÖL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "İLKADIM",
        "STREET": "ANADOLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "İLKADIM",
        "STREET": "ATAKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "İLKADIM",
        "STREET": "AVDAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "İLKADIM",
        "STREET": "AŞAĞIAVDAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "İLKADIM",
        "STREET": "BAHÇELİEVLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "İLKADIM",
        "STREET": "BARUTHANE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "İLKADIM",
        "STREET": "BİLMECE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "İLKADIM",
        "STREET": "CEDİT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "İLKADIM",
        "STREET": "DEREBAHÇE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "İLKADIM",
        "STREET": "DERECİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "İLKADIM",
        "STREET": "FATİH MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "İLKADIM",
        "STREET": "FEVZİ ÇAKMAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "İLKADIM",
        "STREET": "GÜRGENDAĞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "İLKADIM",
        "STREET": "GÜZELDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "İLKADIM",
        "STREET": "HANÇERLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "İLKADIM",
        "STREET": "HASTANE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "İLKADIM",
        "STREET": "HÜRRİYET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "İLKADIM",
        "STREET": "KADAMUT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "İLKADIM",
        "STREET": "KADIKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "İLKADIM",
        "STREET": "KADİFEKALE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "İLKADIM",
        "STREET": "KALE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "İLKADIM",
        "STREET": "KALKANCI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "İLKADIM",
        "STREET": "KAPAKLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "İLKADIM",
        "STREET": "KARADENİZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "İLKADIM",
        "STREET": "KARASAMSUN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "İLKADIM",
        "STREET": "KAVACIK  MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "İLKADIM",
        "STREET": "KAZIM KARABEKİR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "İLKADIM",
        "STREET": "KILIÇDEDE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "İLKADIM",
        "STREET": "KIRAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "İLKADIM",
        "STREET": "KIŞLA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "İLKADIM",
        "STREET": "KUSÇULU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "İLKADIM",
        "STREET": "KÖKÇÜOĞLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "İLKADIM",
        "STREET": "LİMAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "İLKADIM",
        "STREET": "PAZAR  MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "İLKADIM",
        "STREET": "RASATHANE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "İLKADIM",
        "STREET": "REŞADİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "İLKADIM",
        "STREET": "SAMSUN T TİPİ KAPALI VE AÇIK CEZA İNFAZ KURUMU",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "İLKADIM",
        "STREET": "SAİTBEY  MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "İLKADIM",
        "STREET": "SELAHİYE  MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "İLKADIM",
        "STREET": "TEPECİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "İLKADIM",
        "STREET": "TOYBELEN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "İLKADIM",
        "STREET": "ULUGAZİ  MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "İLKADIM",
        "STREET": "UNKAPANI  MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "İLKADIM",
        "STREET": "UZGUR  MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "İLKADIM",
        "STREET": "YAŞARDOĞU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "İLKADIM",
        "STREET": "YENİDOĞAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "İLKADIM",
        "STREET": "YEŞİLTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "İLKADIM",
        "STREET": "ZAFER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "İLKADIM",
        "STREET": "ZEYTİNLİK  MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "İLKADIM",
        "STREET": "ÇANAKÇI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "İLKADIM",
        "STREET": "ÇANDIR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "İLKADIM",
        "STREET": "ÇATALARMUT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "İLKADIM",
        "STREET": "ÇAY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "İLKADIM",
        "STREET": "ÇELİKALAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "İLKADIM",
        "STREET": "ÇİFTLİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "İLKADIM",
        "STREET": "ÇİVRİL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "İLKADIM",
        "STREET": "İLYASKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SAMSUN",
        "DISTRICT": "İLKADIM",
        "STREET": "İSTASYON MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "AYANCIK",
        "STREET": "ABDÜLKADİRKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "AYANCIK",
        "STREET": "AKÇAKESE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "AYANCIK",
        "STREET": "AKÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "AYANCIK",
        "STREET": "ALİKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "AYANCIK",
        "STREET": "ARMUTLUYAZI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "AYANCIK",
        "STREET": "AVDULLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "AYANCIK",
        "STREET": "AYGÖRDÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "AYANCIK",
        "STREET": "AĞAÇLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "AYANCIK",
        "STREET": "AŞAĞIKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "AYANCIK",
        "STREET": "BABAKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "AYANCIK",
        "STREET": "BABAÇAY KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "AYANCIK",
        "STREET": "BAHÇELİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "AYANCIK",
        "STREET": "BAKIRLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "AYANCIK",
        "STREET": "BAKIRLIZAVİYE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "AYANCIK",
        "STREET": "BELPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "AYANCIK",
        "STREET": "BEŞİKTAŞ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "AYANCIK",
        "STREET": "BÜYÜKDÜZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "AYANCIK",
        "STREET": "BÜYÜKPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "AYANCIK",
        "STREET": "CEVİZLİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "AYANCIK",
        "STREET": "DAVUTLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "AYANCIK",
        "STREET": "DEDEAĞAÇ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "AYANCIK",
        "STREET": "DENİZCİLER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "AYANCIK",
        "STREET": "DEREKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "AYANCIK",
        "STREET": "DOLAY KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "AYANCIK",
        "STREET": "DOĞANLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "AYANCIK",
        "STREET": "DİBEKLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "AYANCIK",
        "STREET": "ERDEMLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "AYANCIK",
        "STREET": "ERİKLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "AYANCIK",
        "STREET": "FINDIKLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "AYANCIK",
        "STREET": "GÖLDAĞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "AYANCIK",
        "STREET": "GÖLKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "AYANCIK",
        "STREET": "GÜRPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "AYANCIK",
        "STREET": "GÜRSÖKÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "AYANCIK",
        "STREET": "HACILI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "AYANCIK",
        "STREET": "HACIOĞLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "AYANCIK",
        "STREET": "HATİP KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "AYANCIK",
        "STREET": "HÜSEYİNBEY KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "AYANCIK",
        "STREET": "KALDIRAYAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "AYANCIK",
        "STREET": "KARAKESTANE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "AYANCIK",
        "STREET": "KARAPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "AYANCIK",
        "STREET": "KESTANELİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "AYANCIK",
        "STREET": "KIZILCAKAYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "AYANCIK",
        "STREET": "KOZCUĞAZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "AYANCIK",
        "STREET": "KOZSÖKÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "AYANCIK",
        "STREET": "KURTKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "AYANCIK",
        "STREET": "KÖSEYAKASI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "AYANCIK",
        "STREET": "KÜTÜKKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "AYANCIK",
        "STREET": "MADEN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "AYANCIK",
        "STREET": "MESTAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "AYANCIK",
        "STREET": "MUSTAFAKEMALPAŞA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "AYANCIK",
        "STREET": "ORTALIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "AYANCIK",
        "STREET": "OTMANLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "AYANCIK",
        "STREET": "PAZARCIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "AYANCIK",
        "STREET": "SOFU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "AYANCIK",
        "STREET": "SULUSÖKÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "AYANCIK",
        "STREET": "SÖKÜÇAYIRI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "AYANCIK",
        "STREET": "TARAKÇI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "AYANCIK",
        "STREET": "TEPECİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "AYANCIK",
        "STREET": "TEVFİKİYE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "AYANCIK",
        "STREET": "TOPAĞAÇ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "AYANCIK",
        "STREET": "TÜRKMEN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "AYANCIK",
        "STREET": "UZUNÇAM KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "AYANCIK",
        "STREET": "YALI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "AYANCIK",
        "STREET": "YARENLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "AYANCIK",
        "STREET": "YEMİŞEN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "AYANCIK",
        "STREET": "YENİCE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "AYANCIK",
        "STREET": "YENİGÜLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "AYANCIK",
        "STREET": "YEŞİLYURT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "AYANCIK",
        "STREET": "ZAVİYE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "AYANCIK",
        "STREET": "ÇAMYAYLA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "AYANCIK",
        "STREET": "ÇAYBAŞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "AYANCIK",
        "STREET": "ÇAYLIOĞLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "AYANCIK",
        "STREET": "ÇAYİÇİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "AYANCIK",
        "STREET": "ÖMERDÜZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "AYANCIK",
        "STREET": "ÜNLÜCE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "AYANCIK",
        "STREET": "İNALTI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "BOYABAT",
        "STREET": "AKYÜRÜK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "BOYABAT",
        "STREET": "AKÇAKESE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "BOYABAT",
        "STREET": "ALİBEYLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "BOYABAT",
        "STREET": "ARDIÇ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "BOYABAT",
        "STREET": "ARIOĞLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "BOYABAT",
        "STREET": "AYDOĞAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "BOYABAT",
        "STREET": "AŞAĞISEYRİCEK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "BOYABAT",
        "STREET": "AŞIKLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "BOYABAT",
        "STREET": "BAYAMCA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "BOYABAT",
        "STREET": "BAĞLICA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "BOYABAT",
        "STREET": "BEKTAŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "BOYABAT",
        "STREET": "BENGÜBELEN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "BOYABAT",
        "STREET": "BENİŞLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "BOYABAT",
        "STREET": "BOYALI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "BOYABAT",
        "STREET": "BÖLÜKLÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "BOYABAT",
        "STREET": "BÜRÜM KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "BOYABAT",
        "STREET": "BÜYÜKKARAAĞAÇ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "BOYABAT",
        "STREET": "BİNERLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "BOYABAT",
        "STREET": "CAMİİKEBİR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "BOYABAT",
        "STREET": "CEMALETTİNKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "BOYABAT",
        "STREET": "CURKUŞLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "BOYABAT",
        "STREET": "DARIÖZÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "BOYABAT",
        "STREET": "DAYLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "BOYABAT",
        "STREET": "DAĞTABAKLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "BOYABAT",
        "STREET": "DEREÇATI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "BOYABAT",
        "STREET": "DODURGA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "BOYABAT",
        "STREET": "DOĞRUL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "BOYABAT",
        "STREET": "DOĞUCA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "BOYABAT",
        "STREET": "DÜZKARAAĞAÇ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "BOYABAT",
        "STREET": "EDİL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "BOYABAT",
        "STREET": "EKİNÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "BOYABAT",
        "STREET": "EMİROĞLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "BOYABAT",
        "STREET": "ENGİLEKİN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "BOYABAT",
        "STREET": "ERKEÇ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "BOYABAT",
        "STREET": "ESENGAZİLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "BOYABAT",
        "STREET": "ESENTEPE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "BOYABAT",
        "STREET": "ESENTEPE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "BOYABAT",
        "STREET": "EĞLENCE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "BOYABAT",
        "STREET": "GAZİDERESİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "BOYABAT",
        "STREET": "GAZİDERETABAKLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "BOYABAT",
        "STREET": "GÖKDERE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "BOYABAT",
        "STREET": "GÖKÇEAĞAÇSAKIZI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "BOYABAT",
        "STREET": "GÖKÇUKUR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "BOYABAT",
        "STREET": "GÖVE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "BOYABAT",
        "STREET": "GÜNPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "BOYABAT",
        "STREET": "HACIAHMETLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "BOYABAT",
        "STREET": "HAMZALI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "BOYABAT",
        "STREET": "ILICAKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "BOYABAT",
        "STREET": "KADINLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "BOYABAT",
        "STREET": "KARACAÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "BOYABAT",
        "STREET": "KARAMUSALI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "BOYABAT",
        "STREET": "KARTALOĞLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "BOYABAT",
        "STREET": "KAVACIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "BOYABAT",
        "STREET": "KAVAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "BOYABAT",
        "STREET": "KAYABOĞAZI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "BOYABAT",
        "STREET": "KESEKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "BOYABAT",
        "STREET": "KILIÇLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "BOYABAT",
        "STREET": "KOVAÇAYIR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "BOYABAT",
        "STREET": "KOZANLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "BOYABAT",
        "STREET": "KOZKULE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "BOYABAT",
        "STREET": "KOÇAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "BOYABAT",
        "STREET": "KUMLUK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "BOYABAT",
        "STREET": "KURTLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "BOYABAT",
        "STREET": "KURUSARAY KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "BOYABAT",
        "STREET": "KUYUCAKPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "BOYABAT",
        "STREET": "KUZVEREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "BOYABAT",
        "STREET": "KÖPRÜCEK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "BOYABAT",
        "STREET": "KİLLİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "BOYABAT",
        "STREET": "MAHMUTLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "BOYABAT",
        "STREET": "MARUF KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "BOYABAT",
        "STREET": "MARUFALINCA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "BOYABAT",
        "STREET": "MURATLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "BOYABAT",
        "STREET": "OKÇUMEHMETLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "BOYABAT",
        "STREET": "OSMANKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "BOYABAT",
        "STREET": "OĞLAKÇILAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "BOYABAT",
        "STREET": "PAŞALIOĞLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "BOYABAT",
        "STREET": "PEHLİVANLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "BOYABAT",
        "STREET": "PİREFENDİDERESİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "BOYABAT",
        "STREET": "SALAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "BOYABAT",
        "STREET": "SARIAĞAÇÇAYI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "BOYABAT",
        "STREET": "SARIYER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "BOYABAT",
        "STREET": "SEFA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "BOYABAT",
        "STREET": "TAŞHANLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "BOYABAT",
        "STREET": "TEKKE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "BOYABAT",
        "STREET": "TIRNALI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "BOYABAT",
        "STREET": "UZUNÇAY KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "BOYABAT",
        "STREET": "YABANLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "BOYABAT",
        "STREET": "YAYLACIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "BOYABAT",
        "STREET": "YAZIKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "BOYABAT",
        "STREET": "YENİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "BOYABAT",
        "STREET": "YENİCAMİLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "BOYABAT",
        "STREET": "YENİKAYALI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "BOYABAT",
        "STREET": "YENİKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "BOYABAT",
        "STREET": "YENİMEHMETLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "BOYABAT",
        "STREET": "YEŞİLKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "BOYABAT",
        "STREET": "YEŞİLYURT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "BOYABAT",
        "STREET": "YEŞİLÇAM KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "BOYABAT",
        "STREET": "YILDIZ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "BOYABAT",
        "STREET": "YUKARISEYRİCEK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "BOYABAT",
        "STREET": "ZİNCİRLİKUYU MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "BOYABAT",
        "STREET": "ÇALTU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "BOYABAT",
        "STREET": "ÇAMLICA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "BOYABAT",
        "STREET": "ÇARŞAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "BOYABAT",
        "STREET": "ÇATPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "BOYABAT",
        "STREET": "ÇATTEPE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "BOYABAT",
        "STREET": "ÇAYBAŞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "BOYABAT",
        "STREET": "ÇEŞNİGİR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "BOYABAT",
        "STREET": "ÇORAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "BOYABAT",
        "STREET": "ÇUKURHAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "BOYABAT",
        "STREET": "ÇULHALI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "BOYABAT",
        "STREET": "ÖMERKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "BOYABAT",
        "STREET": "ÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "BOYABAT",
        "STREET": "İMAMLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "BOYABAT",
        "STREET": "İSAOĞLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "BOYABAT",
        "STREET": "ŞIHLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "BOYABAT",
        "STREET": "ŞIHLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "DURAĞAN",
        "STREET": "AKBEL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "DURAĞAN",
        "STREET": "AKPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "DURAĞAN",
        "STREET": "AKÇAALAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "DURAĞAN",
        "STREET": "AKÇABÜK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "DURAĞAN",
        "STREET": "ALPAŞALI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "DURAĞAN",
        "STREET": "ALPUĞAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "DURAĞAN",
        "STREET": "ALTINKAYA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "DURAĞAN",
        "STREET": "AYVACIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "DURAĞAN",
        "STREET": "AŞAĞIALINCA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "DURAĞAN",
        "STREET": "AŞAĞIKARACAÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "DURAĞAN",
        "STREET": "BAYAT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "DURAĞAN",
        "STREET": "BAŞAĞAÇ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "DURAĞAN",
        "STREET": "BEYARDIÇ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "DURAĞAN",
        "STREET": "BEYBÜKÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "DURAĞAN",
        "STREET": "BOYABÜKÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "DURAĞAN",
        "STREET": "BOYALICA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "DURAĞAN",
        "STREET": "BUZLUK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "DURAĞAN",
        "STREET": "CEVİZLİBAĞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "DURAĞAN",
        "STREET": "DAĞDELEN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "DURAĞAN",
        "STREET": "DERELİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "DURAĞAN",
        "STREET": "EMİRTOLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "DURAĞAN",
        "STREET": "ERDUASI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "DURAĞAN",
        "STREET": "ERENKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "DURAĞAN",
        "STREET": "GÖKDOĞAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "DURAĞAN",
        "STREET": "GÖKIRMAK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "DURAĞAN",
        "STREET": "GÖKÇEBELEN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "DURAĞAN",
        "STREET": "GÖLALAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "DURAĞAN",
        "STREET": "GÖLGERİŞİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "DURAĞAN",
        "STREET": "GÜNGÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "DURAĞAN",
        "STREET": "GÜRPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "DURAĞAN",
        "STREET": "HACIMAHMUTLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "DURAĞAN",
        "STREET": "HACIOĞLAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "DURAĞAN",
        "STREET": "KAPLANGI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "DURAĞAN",
        "STREET": "KARAGÜNEY KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "DURAĞAN",
        "STREET": "KARATAŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "DURAĞAN",
        "STREET": "KAVAKLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "DURAĞAN",
        "STREET": "KEMERBAHÇE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "DURAĞAN",
        "STREET": "KILIÇASLAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "DURAĞAN",
        "STREET": "KIZILCAPELİT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "DURAĞAN",
        "STREET": "KUZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "DURAĞAN",
        "STREET": "KUZULUK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "DURAĞAN",
        "STREET": "KÖKLEN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "DURAĞAN",
        "STREET": "KÖSELİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "DURAĞAN",
        "STREET": "KİRENCİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "DURAĞAN",
        "STREET": "MERKEZ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "DURAĞAN",
        "STREET": "OLUCAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "DURAĞAN",
        "STREET": "OLUKBAŞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "DURAĞAN",
        "STREET": "ORTAKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "DURAĞAN",
        "STREET": "SALARKOLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "DURAĞAN",
        "STREET": "SARIKADI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "DURAĞAN",
        "STREET": "SARIYAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "DURAĞAN",
        "STREET": "SARNIKALINCASI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "DURAĞAN",
        "STREET": "SOFULAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "DURAĞAN",
        "STREET": "ULUPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "DURAĞAN",
        "STREET": "UZUNÖZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "DURAĞAN",
        "STREET": "YALNIZKAVAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "DURAĞAN",
        "STREET": "YANALAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "DURAĞAN",
        "STREET": "YANDAK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "DURAĞAN",
        "STREET": "YASSIALAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "DURAĞAN",
        "STREET": "YAĞBASAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "DURAĞAN",
        "STREET": "YEMİŞEN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "DURAĞAN",
        "STREET": "YENİKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "DURAĞAN",
        "STREET": "YEŞİLKENT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "DURAĞAN",
        "STREET": "YEŞİLYURT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "DURAĞAN",
        "STREET": "YEŞİLYURT MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "DURAĞAN",
        "STREET": "YUKARIKARACAÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "DURAĞAN",
        "STREET": "ÇALTUCAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "DURAĞAN",
        "STREET": "ÇAMLICA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "DURAĞAN",
        "STREET": "ÇAMPAŞASAKIZI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "DURAĞAN",
        "STREET": "ÇANDAĞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "DURAĞAN",
        "STREET": "ÇAYAĞZI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "DURAĞAN",
        "STREET": "ÇERÇİLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "DURAĞAN",
        "STREET": "ÇORAKYÜZÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "DURAĞAN",
        "STREET": "ÇÖVE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "DURAĞAN",
        "STREET": "İNCİR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "DİKMEN",
        "STREET": "AKÇAKESE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "DİKMEN",
        "STREET": "BABALIOĞLU MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "DİKMEN",
        "STREET": "BUCAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "DİKMEN",
        "STREET": "BÜYÜKDAĞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "DİKMEN",
        "STREET": "BÜYÜKKIZIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "DİKMEN",
        "STREET": "DAĞKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "DİKMEN",
        "STREET": "DUDAŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "DİKMEN",
        "STREET": "DUMANLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "DİKMEN",
        "STREET": "GÖLLÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "DİKMEN",
        "STREET": "GÖRÜMCEK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "DİKMEN",
        "STREET": "KADIKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "DİKMEN",
        "STREET": "KARAAĞAÇ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "DİKMEN",
        "STREET": "KARAKOYUN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "DİKMEN",
        "STREET": "KERİM KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "DİKMEN",
        "STREET": "KIRÇAL MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "DİKMEN",
        "STREET": "KUZALAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "DİKMEN",
        "STREET": "KÜPLÜCE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "DİKMEN",
        "STREET": "KÜÇÜKKIZIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "DİKMEN",
        "STREET": "SARAY KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "DİKMEN",
        "STREET": "YAKUPLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "DİKMEN",
        "STREET": "YAYKIN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "DİKMEN",
        "STREET": "YAYLABEYİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "DİKMEN",
        "STREET": "YENİKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "DİKMEN",
        "STREET": "YUKARIÇEKMEZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "DİKMEN",
        "STREET": "YUMAKLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "DİKMEN",
        "STREET": "ÇANAKÇI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "DİKMEN",
        "STREET": "ÇEVİKLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "DİKMEN",
        "STREET": "ÇORAK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "DİKMEN",
        "STREET": "ÇUKURCAALAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "DİKMEN",
        "STREET": "ÜÇPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "DİKMEN",
        "STREET": "ŞEYHHÜSEYİN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "ERFELEK",
        "STREET": "ABDURRAHMANPAŞA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "ERFELEK",
        "STREET": "AHMETMUHİPDIRANAS KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "ERFELEK",
        "STREET": "AKCASÖĞÜT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "ERFELEK",
        "STREET": "AKCAÇAM KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "ERFELEK",
        "STREET": "AVLAĞASÖKÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "ERFELEK",
        "STREET": "AYDINLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "ERFELEK",
        "STREET": "BALIFAKI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "ERFELEK",
        "STREET": "BAŞARAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "ERFELEK",
        "STREET": "DAĞYERİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "ERFELEK",
        "STREET": "DEREKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "ERFELEK",
        "STREET": "DEĞİRMENCİLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "ERFELEK",
        "STREET": "EMİRHALİL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "ERFELEK",
        "STREET": "GÖKÇEBEL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "ERFELEK",
        "STREET": "GÜMÜŞSUYU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "ERFELEK",
        "STREET": "GÜVEN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "ERFELEK",
        "STREET": "HACILAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "ERFELEK",
        "STREET": "HAMİDİYE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "ERFELEK",
        "STREET": "HASANDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "ERFELEK",
        "STREET": "HORZUM KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "ERFELEK",
        "STREET": "HÜRREMŞAH KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "ERFELEK",
        "STREET": "HİMMETOĞLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "ERFELEK",
        "STREET": "KALDIRAYAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "ERFELEK",
        "STREET": "KARACAKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "ERFELEK",
        "STREET": "KAZMASÖKÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "ERFELEK",
        "STREET": "KINIK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "ERFELEK",
        "STREET": "KIZILCAELMA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "ERFELEK",
        "STREET": "KIZILCAOT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "ERFELEK",
        "STREET": "KURCALI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "ERFELEK",
        "STREET": "KİRAZLIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "ERFELEK",
        "STREET": "MERKEZ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "ERFELEK",
        "STREET": "MESCİTDÜZÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "ERFELEK",
        "STREET": "MEYDAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "ERFELEK",
        "STREET": "ORMANTEPE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "ERFELEK",
        "STREET": "SALAVAT MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "ERFELEK",
        "STREET": "SALI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "ERFELEK",
        "STREET": "SARIBOĞA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "ERFELEK",
        "STREET": "SELBEYİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "ERFELEK",
        "STREET": "SORGUN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "ERFELEK",
        "STREET": "SOĞUCALI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "ERFELEK",
        "STREET": "TATLICA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "ERFELEK",
        "STREET": "TEKKE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "ERFELEK",
        "STREET": "TOMBUL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "ERFELEK",
        "STREET": "VEYSEL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "ERFELEK",
        "STREET": "YENİKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "ERFELEK",
        "STREET": "YENİÇAM KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "ERFELEK",
        "STREET": "ÇAYIRKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "ERFELEK",
        "STREET": "İNCİRPINARI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "ERFELEK",
        "STREET": "İNESÖKÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "ERFELEK",
        "STREET": "ŞEREFİYE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "GERZE",
        "STREET": "19 MAYIS MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "GERZE",
        "STREET": "ABDALOĞLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "GERZE",
        "STREET": "ACISU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "GERZE",
        "STREET": "AKGÜNEY KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "GERZE",
        "STREET": "AKKIRAÇ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "GERZE",
        "STREET": "ALTINYAYLA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "GERZE",
        "STREET": "ATATÜRK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "GERZE",
        "STREET": "BAŞSÖKÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "GERZE",
        "STREET": "BELÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "GERZE",
        "STREET": "BOLALI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "GERZE",
        "STREET": "BOYALI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "GERZE",
        "STREET": "BOYALICA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "GERZE",
        "STREET": "CUMHURİYET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "GERZE",
        "STREET": "GÜRSÖKÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "GERZE",
        "STREET": "GÜZELYURT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "GERZE",
        "STREET": "HACISELLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "GERZE",
        "STREET": "HAMİDİYE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "GERZE",
        "STREET": "HIDIRLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "GERZE",
        "STREET": "HİZARÇAYI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "GERZE",
        "STREET": "KABANLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "GERZE",
        "STREET": "KAHRAMANELİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "GERZE",
        "STREET": "KARLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "GERZE",
        "STREET": "KIZILCALI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "GERZE",
        "STREET": "KUZSÖKÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "GERZE",
        "STREET": "KÖŞK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "GERZE",
        "STREET": "KİRENÇUKURU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "GERZE",
        "STREET": "MAHMUTTIRI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "GERZE",
        "STREET": "PİRAHMET KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "GERZE",
        "STREET": "SARIMSAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "GERZE",
        "STREET": "SARIYER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "GERZE",
        "STREET": "SARNIÇ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "GERZE",
        "STREET": "SAZAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "GERZE",
        "STREET": "SORKUN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "GERZE",
        "STREET": "TATLICAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "GERZE",
        "STREET": "TEPEALTI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "GERZE",
        "STREET": "TOKUŞLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "GERZE",
        "STREET": "TÜRKMEN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "GERZE",
        "STREET": "TÜRKMENLİOĞLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "GERZE",
        "STREET": "YAKADİBİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "GERZE",
        "STREET": "YAMACIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "GERZE",
        "STREET": "YAYKIL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "GERZE",
        "STREET": "YENİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "GERZE",
        "STREET": "YENİKENT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "GERZE",
        "STREET": "YUVALI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "GERZE",
        "STREET": "ÇAKALLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "GERZE",
        "STREET": "ÇARŞI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "GERZE",
        "STREET": "ÇAĞLAYAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "GERZE",
        "STREET": "ÇIRNIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "GERZE",
        "STREET": "ŞEYHLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "SARAYDÜZÜ",
        "STREET": "AKBELEN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "SARAYDÜZÜ",
        "STREET": "ARIM KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "SARAYDÜZÜ",
        "STREET": "ASARCIKCAMİLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "SARAYDÜZÜ",
        "STREET": "ASARCIKHACIKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "SARAYDÜZÜ",
        "STREET": "ASARCIKKAYALI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "SARAYDÜZÜ",
        "STREET": "ASARCIKKAZAKLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "SARAYDÜZÜ",
        "STREET": "AVLUCA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "SARAYDÜZÜ",
        "STREET": "AŞAĞIAKPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "SARAYDÜZÜ",
        "STREET": "BAHÇEKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "SARAYDÜZÜ",
        "STREET": "BAHŞAŞLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "SARAYDÜZÜ",
        "STREET": "BAŞEKİN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "SARAYDÜZÜ",
        "STREET": "CUMAKAYALI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "SARAYDÜZÜ",
        "STREET": "CUMAKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "SARAYDÜZÜ",
        "STREET": "CUMATABAKLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "SARAYDÜZÜ",
        "STREET": "GÖYNÜKÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "SARAYDÜZÜ",
        "STREET": "HACIÇAY KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "SARAYDÜZÜ",
        "STREET": "HANOĞLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "SARAYDÜZÜ",
        "STREET": "KARAÇAYGÖLETİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "SARAYDÜZÜ",
        "STREET": "KORUCUK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "SARAYDÜZÜ",
        "STREET": "MERKEZ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "SARAYDÜZÜ",
        "STREET": "TEPEKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "SARAYDÜZÜ",
        "STREET": "ULUKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "SARAYDÜZÜ",
        "STREET": "YALMANSARAY KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "SARAYDÜZÜ",
        "STREET": "YAYLACILI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "SARAYDÜZÜ",
        "STREET": "YENİCE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "SARAYDÜZÜ",
        "STREET": "YUKARIAKPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "SARAYDÜZÜ",
        "STREET": "YUKARIARIM KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "SARAYDÜZÜ",
        "STREET": "ZAİMKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "SARAYDÜZÜ",
        "STREET": "ÇALPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "SARAYDÜZÜ",
        "STREET": "ÇAMPAŞALI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "SARAYDÜZÜ",
        "STREET": "ÇORMAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "MERKEZ",
        "STREET": "ABALI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "MERKEZ",
        "STREET": "ADA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "MERKEZ",
        "STREET": "AHMETYERİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "MERKEZ",
        "STREET": "AKBAŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "MERKEZ",
        "STREET": "ALASÖKÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "MERKEZ",
        "STREET": "ALOĞLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "MERKEZ",
        "STREET": "AVDAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "MERKEZ",
        "STREET": "BEKTAŞAĞA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "MERKEZ",
        "STREET": "BOSTANCILI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "MERKEZ",
        "STREET": "CAMİKEBİR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "MERKEZ",
        "STREET": "DEMİRCİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "MERKEZ",
        "STREET": "DİBEKLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "MERKEZ",
        "STREET": "DİZDAROĞLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "MERKEZ",
        "STREET": "ERİKLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "MERKEZ",
        "STREET": "EYMİR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "MERKEZ",
        "STREET": "FİDANLIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "MERKEZ",
        "STREET": "GELİNCİK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "MERKEZ",
        "STREET": "GÖLLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "MERKEZ",
        "STREET": "GÖLLÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "MERKEZ",
        "STREET": "HACIOĞLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "MERKEZ",
        "STREET": "KABALI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "MERKEZ",
        "STREET": "KALEYAZISI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "MERKEZ",
        "STREET": "KARAPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "MERKEZ",
        "STREET": "KEFEVİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "MERKEZ",
        "STREET": "KILIÇLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "MERKEZ",
        "STREET": "KORUCUK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "MERKEZ",
        "STREET": "KOZCUĞAZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "MERKEZ",
        "STREET": "KİRENÇUKURU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "MERKEZ",
        "STREET": "LALA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "MERKEZ",
        "STREET": "MELİKŞAH KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "MERKEZ",
        "STREET": "MERTOĞLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "MERKEZ",
        "STREET": "MEYDANKAPI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "MERKEZ",
        "STREET": "ORDU MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "MERKEZ",
        "STREET": "OSMANİYE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "MERKEZ",
        "STREET": "OĞUZELİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "MERKEZ",
        "STREET": "SARIKUM KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "MERKEZ",
        "STREET": "SAZLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "MERKEZ",
        "STREET": "SİNECAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "MERKEZ",
        "STREET": "SİNOP E TİPİ CEZAEVİ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "MERKEZ",
        "STREET": "TANGALOĞLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "MERKEZ",
        "STREET": "TAYPAKLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "MERKEZ",
        "STREET": "TAŞMANLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "MERKEZ",
        "STREET": "TINGIROĞLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "MERKEZ",
        "STREET": "UZUNGÜRGEN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "MERKEZ",
        "STREET": "YALI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "MERKEZ",
        "STREET": "YENİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "MERKEZ",
        "STREET": "ZEYTİNLİK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇAKILDAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇOBANLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇİFTLİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "MERKEZ",
        "STREET": "İNCEDAYI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "MERKEZ",
        "STREET": "ŞAMLIOĞLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "TÜRKELİ",
        "STREET": "AKÇABÜK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "TÜRKELİ",
        "STREET": "ALAGÖZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "TÜRKELİ",
        "STREET": "AYAZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "TÜRKELİ",
        "STREET": "DÜZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "TÜRKELİ",
        "STREET": "DÜZLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "TÜRKELİ",
        "STREET": "DİREKLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "TÜRKELİ",
        "STREET": "GAZİLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "TÜRKELİ",
        "STREET": "GEMİYANI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "TÜRKELİ",
        "STREET": "GENCEK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "TÜRKELİ",
        "STREET": "GÖKÇEALAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "TÜRKELİ",
        "STREET": "GÜNDOĞDU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "TÜRKELİ",
        "STREET": "GÜZELKENT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "TÜRKELİ",
        "STREET": "GÜZELYURT MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "TÜRKELİ",
        "STREET": "HACI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "TÜRKELİ",
        "STREET": "HAMAMLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "TÜRKELİ",
        "STREET": "IŞIKLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "TÜRKELİ",
        "STREET": "KARABEY KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "TÜRKELİ",
        "STREET": "KAYABAŞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "TÜRKELİ",
        "STREET": "KEŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "TÜRKELİ",
        "STREET": "KUZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "TÜRKELİ",
        "STREET": "KUŞÇULAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "TÜRKELİ",
        "STREET": "OYMAYAKA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "TÜRKELİ",
        "STREET": "SARMAŞIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "TÜRKELİ",
        "STREET": "SATI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "TÜRKELİ",
        "STREET": "SAZKIŞLA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "TÜRKELİ",
        "STREET": "TAÇAHMET KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "TÜRKELİ",
        "STREET": "TAŞGÜNEY KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "TÜRKELİ",
        "STREET": "TURHAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "TÜRKELİ",
        "STREET": "TÜMERKAN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "TÜRKELİ",
        "STREET": "YAPRAKLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "TÜRKELİ",
        "STREET": "YAZICI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "TÜRKELİ",
        "STREET": "YEŞİLOBA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "TÜRKELİ",
        "STREET": "YUSUFLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "TÜRKELİ",
        "STREET": "ÇATAKGERİŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "TÜRKELİ",
        "STREET": "ÇATAKGÜNEY KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİNOP",
        "DISTRICT": "TÜRKELİ",
        "STREET": "ÇATAKÖRENCİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "AKINCILAR",
        "STREET": "ABDURRAHMAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "AKINCILAR",
        "STREET": "ALTINTEPE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "AKINCILAR",
        "STREET": "AVŞAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "AKINCILAR",
        "STREET": "AŞAĞIYENİKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "AKINCILAR",
        "STREET": "BALLIDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "AKINCILAR",
        "STREET": "BALÇIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "AKINCILAR",
        "STREET": "CUMHURİYET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "AKINCILAR",
        "STREET": "DERECİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "AKINCILAR",
        "STREET": "DOĞANTEPE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "AKINCILAR",
        "STREET": "DÜNDAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "AKINCILAR",
        "STREET": "EKENEK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "AKINCILAR",
        "STREET": "ELİBÜYÜK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "AKINCILAR",
        "STREET": "ESKİBAĞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "AKINCILAR",
        "STREET": "GEYİKPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "AKINCILAR",
        "STREET": "GÖLLÜCE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "AKINCILAR",
        "STREET": "GÖNEN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "AKINCILAR",
        "STREET": "KAVAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "AKINCILAR",
        "STREET": "KAYI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "AKINCILAR",
        "STREET": "KILIÇ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "AKINCILAR",
        "STREET": "ONARI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "AKINCILAR",
        "STREET": "ORTAKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "AKINCILAR",
        "STREET": "SAPANLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "AKINCILAR",
        "STREET": "SEVİNDİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "AKINCILAR",
        "STREET": "SIYRINDI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "AKINCILAR",
        "STREET": "UĞRUNCA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "AKINCILAR",
        "STREET": "YAYLA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "AKINCILAR",
        "STREET": "YAĞLIÇAYIR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "AKINCILAR",
        "STREET": "YENİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "AKINCILAR",
        "STREET": "YEŞİLYAZI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "AKINCILAR",
        "STREET": "YUKARI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "AKINCILAR",
        "STREET": "YUKARISARICA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "AKINCILAR",
        "STREET": "YUSUFŞEYH KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "AKINCILAR",
        "STREET": "YÜNLÜCE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "AKINCILAR",
        "STREET": "ÇİÇEKLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "AKINCILAR",
        "STREET": "ÜZENGİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "AKINCILAR",
        "STREET": "İKİZYURT MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "AKINCILAR",
        "STREET": "ŞENBAĞLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "ALTINYAYLA",
        "STREET": "ALTINYURT MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "ALTINYAYLA",
        "STREET": "AYDIN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "ALTINYAYLA",
        "STREET": "BAYINDIR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "ALTINYAYLA",
        "STREET": "BAŞYAYLA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "ALTINYAYLA",
        "STREET": "DELİİLYAS-BAŞÖREN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "ALTINYAYLA",
        "STREET": "DELİİLYAS-GAZİOSMANPAŞA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "ALTINYAYLA",
        "STREET": "DELİİLYAS-MERKEZ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "ALTINYAYLA",
        "STREET": "DELİİLYAS-SERİNYAYLA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "ALTINYAYLA",
        "STREET": "DELİİLYAS-YASSIPINAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "ALTINYAYLA",
        "STREET": "DELİİLYAS-YEŞİLYURT MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "ALTINYAYLA",
        "STREET": "DOĞUPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "ALTINYAYLA",
        "STREET": "GÜMÜŞDİĞİN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "ALTINYAYLA",
        "STREET": "GÜZELOĞLAN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "ALTINYAYLA",
        "STREET": "HARMANDALI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "ALTINYAYLA",
        "STREET": "KALE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "ALTINYAYLA",
        "STREET": "KIZILHÜYÜK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "ALTINYAYLA",
        "STREET": "MUTUBEY KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "ALTINYAYLA",
        "STREET": "PAŞAKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "ALTINYAYLA",
        "STREET": "RIFAT ÖÇTEN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "ALTINYAYLA",
        "STREET": "TAHYURT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "ALTINYAYLA",
        "STREET": "TAŞLIHÜYÜK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "ALTINYAYLA",
        "STREET": "ŞAFAK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "DOĞANŞAR",
        "STREET": "ALAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "DOĞANŞAR",
        "STREET": "ALAZLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "DOĞANŞAR",
        "STREET": "ASLANTAŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "DOĞANŞAR",
        "STREET": "AVCIÇAYI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "DOĞANŞAR",
        "STREET": "BAŞEKİN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "DOĞANŞAR",
        "STREET": "BEŞAĞAÇ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "DOĞANŞAR",
        "STREET": "BOYALI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "DOĞANŞAR",
        "STREET": "CAMİKEBİR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "DOĞANŞAR",
        "STREET": "CİNCİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "DOĞANŞAR",
        "STREET": "DARIKOL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "DOĞANŞAR",
        "STREET": "DEVRİŞAĞA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "DOĞANŞAR",
        "STREET": "EKİNCİLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "DOĞANŞAR",
        "STREET": "ESKİKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "DOĞANŞAR",
        "STREET": "GÖÇÜKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "DOĞANŞAR",
        "STREET": "KALE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "DOĞANŞAR",
        "STREET": "KARKIN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "DOĞANŞAR",
        "STREET": "KIPCAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "DOĞANŞAR",
        "STREET": "KOZAĞAÇ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "DOĞANŞAR",
        "STREET": "KOZLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "DOĞANŞAR",
        "STREET": "OKCULU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "DOĞANŞAR",
        "STREET": "ORTAKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "DOĞANŞAR",
        "STREET": "SARISUVAT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "DOĞANŞAR",
        "STREET": "SÖBÜLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "DOĞANŞAR",
        "STREET": "TAVZA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "DOĞANŞAR",
        "STREET": "YAVŞANCIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "DOĞANŞAR",
        "STREET": "YEŞİLÇUKUR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "DOĞANŞAR",
        "STREET": "ÇALICI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "DOĞANŞAR",
        "STREET": "ÇATPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "DOĞANŞAR",
        "STREET": "ÇAY MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "DOĞANŞAR",
        "STREET": "ÖMERAĞA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "DOĞANŞAR",
        "STREET": "ÜTÜK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "DOĞANŞAR",
        "STREET": "İÇDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "DİVRİĞİ",
        "STREET": "ABUÇİMEN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "DİVRİĞİ",
        "STREET": "ADATEPE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "DİVRİĞİ",
        "STREET": "AHMETPAŞA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "DİVRİĞİ",
        "STREET": "AKBABA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "DİVRİĞİ",
        "STREET": "AKMEŞE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "DİVRİĞİ",
        "STREET": "AKPELİT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "DİVRİĞİ",
        "STREET": "ARHUSU MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "DİVRİĞİ",
        "STREET": "ARIKBAŞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "DİVRİĞİ",
        "STREET": "ATMALIOĞLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "DİVRİĞİ",
        "STREET": "AVŞARCIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "DİVRİĞİ",
        "STREET": "AĞAÇLIGÖL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "DİVRİĞİ",
        "STREET": "AŞAĞIHAMAM MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "DİVRİĞİ",
        "STREET": "BAHTİYAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "DİVRİĞİ",
        "STREET": "BAHÇELİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "DİVRİĞİ",
        "STREET": "BALOVA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "DİVRİĞİ",
        "STREET": "BAYIRLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "DİVRİĞİ",
        "STREET": "BAYIRÜSTÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "DİVRİĞİ",
        "STREET": "BAŞÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "DİVRİĞİ",
        "STREET": "BELDİBİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "DİVRİĞİ",
        "STREET": "BEYKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "DİVRİĞİ",
        "STREET": "CEDİTPAŞA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "DİVRİĞİ",
        "STREET": "DEMİRDAĞ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "DİVRİĞİ",
        "STREET": "DERİMLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "DİVRİĞİ",
        "STREET": "DUMLUCA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "DİVRİĞİ",
        "STREET": "DURUKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "DİVRİĞİ",
        "STREET": "DİKMEÇAY KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "DİVRİĞİ",
        "STREET": "DİKTAŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "DİVRİĞİ",
        "STREET": "DİLLİOĞLU MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "DİVRİĞİ",
        "STREET": "DİŞBUDAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "DİVRİĞİ",
        "STREET": "EKİNBAŞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "DİVRİĞİ",
        "STREET": "ERİKLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "DİVRİĞİ",
        "STREET": "ESKİBEYLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "DİVRİĞİ",
        "STREET": "EĞRİSU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "DİVRİĞİ",
        "STREET": "GEDİKBAŞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "DİVRİĞİ",
        "STREET": "GEZEY KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "DİVRİĞİ",
        "STREET": "GÖKÇEBEL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "DİVRİĞİ",
        "STREET": "GÖKÇECAMİİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "DİVRİĞİ",
        "STREET": "GÖKÇEHARMAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "DİVRİĞİ",
        "STREET": "GÖLÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "DİVRİĞİ",
        "STREET": "GÖNDÜREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "DİVRİĞİ",
        "STREET": "GÖZECİK (MERKEZ BUCAĞI) KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "DİVRİĞİ",
        "STREET": "GÖZECİK (SİNCAN BUCAĞI) KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "DİVRİĞİ",
        "STREET": "GÜLLÜBAĞ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "DİVRİĞİ",
        "STREET": "GÜLLÜCE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "DİVRİĞİ",
        "STREET": "GÜNBAHÇE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "DİVRİĞİ",
        "STREET": "GÜNEYEVLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "DİVRİĞİ",
        "STREET": "GÜNEŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "DİVRİĞİ",
        "STREET": "GÜRESİN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "DİVRİĞİ",
        "STREET": "GÜRPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "DİVRİĞİ",
        "STREET": "GÜVENKAYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "DİVRİĞİ",
        "STREET": "HACIKUMRU MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "DİVRİĞİ",
        "STREET": "HACIOSMANMESCİDİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "DİVRİĞİ",
        "STREET": "HANDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "DİVRİĞİ",
        "STREET": "HÖBEK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "DİVRİĞİ",
        "STREET": "KALE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "DİVRİĞİ",
        "STREET": "KALEALTI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "DİVRİĞİ",
        "STREET": "KALEDİBİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "DİVRİĞİ",
        "STREET": "KARAAĞAÇLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "DİVRİĞİ",
        "STREET": "KARAKALE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "DİVRİĞİ",
        "STREET": "KARAKUZULU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "DİVRİĞİ",
        "STREET": "KARAMAHMUT MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "DİVRİĞİ",
        "STREET": "KARAMAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "DİVRİĞİ",
        "STREET": "KARASAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "DİVRİĞİ",
        "STREET": "KARAYUSUF MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "DİVRİĞİ",
        "STREET": "KARŞIKONAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "DİVRİĞİ",
        "STREET": "KAVAKLISU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "DİVRİĞİ",
        "STREET": "KAYABURUN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "DİVRİĞİ",
        "STREET": "KAYACIK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "DİVRİĞİ",
        "STREET": "KEKLİKTEPE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "DİVRİĞİ",
        "STREET": "KEMENKEŞ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "DİVRİĞİ",
        "STREET": "KESME KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "DİVRİĞİ",
        "STREET": "KEÇİKAYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "DİVRİĞİ",
        "STREET": "KIRKGÖZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "DİVRİĞİ",
        "STREET": "KIZILCAÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "DİVRİĞİ",
        "STREET": "KOCAPAŞA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "DİVRİĞİ",
        "STREET": "KULUNCAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "DİVRİĞİ",
        "STREET": "KÜLTÜRLÜ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "DİVRİĞİ",
        "STREET": "KÜÇÜK HÜSEYİN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "DİVRİĞİ",
        "STREET": "MADEN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "DİVRİĞİ",
        "STREET": "MADENLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "DİVRİĞİ",
        "STREET": "MALTEPE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "DİVRİĞİ",
        "STREET": "MERCANTEPE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "DİVRİĞİ",
        "STREET": "MURSAL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "DİVRİĞİ",
        "STREET": "OLUKMAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "DİVRİĞİ",
        "STREET": "ORTAKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "DİVRİĞİ",
        "STREET": "OVACIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "DİVRİĞİ",
        "STREET": "OYUKTEPE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "DİVRİĞİ",
        "STREET": "OĞULBEY KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "DİVRİĞİ",
        "STREET": "PURUNSUR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "DİVRİĞİ",
        "STREET": "SELİMOĞLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "DİVRİĞİ",
        "STREET": "SIRÇALI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "DİVRİĞİ",
        "STREET": "SOĞUCAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "DİVRİĞİ",
        "STREET": "SUSUZLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "DİVRİĞİ",
        "STREET": "SUSUZÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "DİVRİĞİ",
        "STREET": "SÜLEYMANAĞA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "DİVRİĞİ",
        "STREET": "SİNCAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "DİVRİĞİ",
        "STREET": "TEPEHAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "DİVRİĞİ",
        "STREET": "TURABALİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "DİVRİĞİ",
        "STREET": "ULUCAMİİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "DİVRİĞİ",
        "STREET": "ULUÇAYIR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "DİVRİĞİ",
        "STREET": "UZUNBAĞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "DİVRİĞİ",
        "STREET": "UZUNKAYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "DİVRİĞİ",
        "STREET": "YALNIZSÖĞÜT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "DİVRİĞİ",
        "STREET": "YAZI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "DİVRİĞİ",
        "STREET": "YAĞBASAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "DİVRİĞİ",
        "STREET": "YERLİÇAY KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "DİVRİĞİ",
        "STREET": "YEŞİLYAYLA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "DİVRİĞİ",
        "STREET": "YEŞİLYOL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "DİVRİĞİ",
        "STREET": "YUSUFŞEYH KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "DİVRİĞİ",
        "STREET": "YUVA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "DİVRİĞİ",
        "STREET": "ZELİHA HATUN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "DİVRİĞİ",
        "STREET": "ÇAKIRAĞA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "DİVRİĞİ",
        "STREET": "ÇAKIRTARLA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "DİVRİĞİ",
        "STREET": "ÇAKMAKDÜZÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "DİVRİĞİ",
        "STREET": "ÇAMLIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "DİVRİĞİ",
        "STREET": "ÇAMURLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "DİVRİĞİ",
        "STREET": "ÇAYÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "DİVRİĞİ",
        "STREET": "ÇAYÖZÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "DİVRİĞİ",
        "STREET": "ÇOBANDURAĞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "DİVRİĞİ",
        "STREET": "ÇUKURÖZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "DİVRİĞİ",
        "STREET": "ÇİTME KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "DİVRİĞİ",
        "STREET": "ÇİĞDEMLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "DİVRİĞİ",
        "STREET": "ÖDEK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "DİVRİĞİ",
        "STREET": "ÖLÇEKLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "DİVRİĞİ",
        "STREET": "ÖZBAĞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "DİVRİĞİ",
        "STREET": "ÜRÜK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "DİVRİĞİ",
        "STREET": "ÜÇPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "DİVRİĞİ",
        "STREET": "İKİZBAŞAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "DİVRİĞİ",
        "STREET": "İMAMOĞLU MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "DİVRİĞİ",
        "STREET": "İMİRHAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "DİVRİĞİ",
        "STREET": "İNANLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "DİVRİĞİ",
        "STREET": "İNCİRLİPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "DİVRİĞİ",
        "STREET": "ŞAHİNKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "GEMEREK",
        "STREET": "AKÇAŞAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "GEMEREK",
        "STREET": "ALPEREN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "GEMEREK",
        "STREET": "ALİ KEMALİ EFENDİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "GEMEREK",
        "STREET": "BAHÇELİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "GEMEREK",
        "STREET": "BAŞTEPELER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "GEMEREK",
        "STREET": "BULHASAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "GEMEREK",
        "STREET": "BURHAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "GEMEREK",
        "STREET": "CESURLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "GEMEREK",
        "STREET": "DENDİL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "GEMEREK",
        "STREET": "DURGUNSU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "GEMEREK",
        "STREET": "EKİZCE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "GEMEREK",
        "STREET": "ESKİYURT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "GEMEREK",
        "STREET": "ESKİÇUBUK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "GEMEREK",
        "STREET": "EĞERCİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "GEMEREK",
        "STREET": "EŞİKLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "GEMEREK",
        "STREET": "FEVZİÇAKMAK  MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "GEMEREK",
        "STREET": "GAZİOSMANPAŞA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "GEMEREK",
        "STREET": "HACIYUSUF KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "GEMEREK",
        "STREET": "HORUK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "GEMEREK",
        "STREET": "KARAAĞIL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "GEMEREK",
        "STREET": "KARACALAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "GEMEREK",
        "STREET": "KARAERKEK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "GEMEREK",
        "STREET": "KARAGÖL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "GEMEREK",
        "STREET": "KARTALKAYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "GEMEREK",
        "STREET": "KEKLİCEK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "GEMEREK",
        "STREET": "KOCAOĞLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "GEMEREK",
        "STREET": "KÖSELİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "GEMEREK",
        "STREET": "KÜMEÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "GEMEREK",
        "STREET": "KÜÇÜKTUZHİSAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "GEMEREK",
        "STREET": "MUHSİN YAZICIOĞLU MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "GEMEREK",
        "STREET": "OSMANUŞAĞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "GEMEREK",
        "STREET": "SEYDİNALİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "GEMEREK",
        "STREET": "SIZIR-ARPAÖZÜ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "GEMEREK",
        "STREET": "SIZIR-KALEBAŞI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "GEMEREK",
        "STREET": "SIZIR-KÖPRÜBAŞI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "GEMEREK",
        "STREET": "SIZIR-SUBAŞI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "GEMEREK",
        "STREET": "SIZIR-YENİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "GEMEREK",
        "STREET": "TALAZOĞLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "GEMEREK",
        "STREET": "TATLIPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "GEMEREK",
        "STREET": "TEKMEN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "GEMEREK",
        "STREET": "YENİDOĞAN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "GEMEREK",
        "STREET": "YENİHAYAT MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "GEMEREK",
        "STREET": "YENİKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "GEMEREK",
        "STREET": "YEŞİLÖZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "GEMEREK",
        "STREET": "YUKARI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "GEMEREK",
        "STREET": "ÇAT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "GEMEREK",
        "STREET": "ÇEPNİ-ALAYBEY MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "GEMEREK",
        "STREET": "ÇEPNİ-UĞUR MUMCU MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "GEMEREK",
        "STREET": "ÇEPNİ-Ş.BİNBAŞI MEHMET ARAS MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "GEMEREK",
        "STREET": "ÇİÇEKOĞLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "GEMEREK",
        "STREET": "ÖRENYURT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "GEMEREK",
        "STREET": "ÖZİÇİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "GEMEREK",
        "STREET": "İNKIŞLA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "GÖLOVA",
        "STREET": "A.CAMİİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "GÖLOVA",
        "STREET": "AKÇATAŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "GÖLOVA",
        "STREET": "ALSANCAK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "GÖLOVA",
        "STREET": "ARSLANCA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "GÖLOVA",
        "STREET": "AYDOĞDU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "GÖLOVA",
        "STREET": "AŞAĞITEPECİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "GÖLOVA",
        "STREET": "BOZAT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "GÖLOVA",
        "STREET": "BOĞAZKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "GÖLOVA",
        "STREET": "CANKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "GÖLOVA",
        "STREET": "DEMİRKONAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "GÖLOVA",
        "STREET": "DİKKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "GÖLOVA",
        "STREET": "FEVZİ ÇAKMAK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "GÖLOVA",
        "STREET": "GÖZLÜCE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "GÖLOVA",
        "STREET": "GÖZÜKÜÇÜK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "GÖLOVA",
        "STREET": "GÜNALAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "GÖLOVA",
        "STREET": "GÜZÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "GÖLOVA",
        "STREET": "KARACA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "GÖLOVA",
        "STREET": "KARAYAKUP KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "GÖLOVA",
        "STREET": "OKÇAÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "GÖLOVA",
        "STREET": "SARIYUSUF KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "GÖLOVA",
        "STREET": "SOĞUKSU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "GÖLOVA",
        "STREET": "SUBAŞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "GÖLOVA",
        "STREET": "ULUÇUKUR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "GÖLOVA",
        "STREET": "YAYLAÇAYI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "GÖLOVA",
        "STREET": "YUKARITEPECİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "GÖLOVA",
        "STREET": "YUVACIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "GÖLOVA",
        "STREET": "ÇAKIRŞEYH KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "GÖLOVA",
        "STREET": "ÇALKAYA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "GÖLOVA",
        "STREET": "ÇATAKLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "GÖLOVA",
        "STREET": "ÇEVRECİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "GÖLOVA",
        "STREET": "ÇOBANLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "GÖLOVA",
        "STREET": "ÇUKURYURT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "GÖLOVA",
        "STREET": "İLYASKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "GÜRÜN",
        "STREET": "AKDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "GÜRÜN",
        "STREET": "AKPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "GÜRÜN",
        "STREET": "AKSU MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "GÜRÜN",
        "STREET": "AYVALI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "GÜRÜN",
        "STREET": "BAHÇEİÇİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "GÜRÜN",
        "STREET": "BAĞLIÇAY KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "GÜRÜN",
        "STREET": "BAŞÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "GÜRÜN",
        "STREET": "BEYPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "GÜRÜN",
        "STREET": "BOZHÜYÜK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "GÜRÜN",
        "STREET": "BOĞAZİÇİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "GÜRÜN",
        "STREET": "BURÇEVİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "GÜRÜN",
        "STREET": "BÖĞRÜDELİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "GÜRÜN",
        "STREET": "CAMİLİYURT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "GÜRÜN",
        "STREET": "DAVULHÜYÜK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "GÜRÜN",
        "STREET": "DAYAKPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "GÜRÜN",
        "STREET": "DEVEÇAYIRI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "GÜRÜN",
        "STREET": "DÜRMEPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "GÜRÜN",
        "STREET": "ERDOĞAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "GÜRÜN",
        "STREET": "ESKİBEKTAŞLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "GÜRÜN",
        "STREET": "ESKİHAMAL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "GÜRÜN",
        "STREET": "GÖBEKÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "GÜRÜN",
        "STREET": "GÖKPINAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "GÜRÜN",
        "STREET": "GÖKÇEYAZI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "GÜRÜN",
        "STREET": "GÜLDEDE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "GÜRÜN",
        "STREET": "GÜLLÜBUCAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "GÜRÜN",
        "STREET": "GÜNEŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "GÜRÜN",
        "STREET": "HACILAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "GÜRÜN",
        "STREET": "HÜYÜKLÜYURT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "GÜRÜN",
        "STREET": "IŞITAN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "GÜRÜN",
        "STREET": "KALEDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "GÜRÜN",
        "STREET": "KARADORUK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "GÜRÜN",
        "STREET": "KARAHİSAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "GÜRÜN",
        "STREET": "KARAKUYU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "GÜRÜN",
        "STREET": "KARAPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "GÜRÜN",
        "STREET": "KARATEPE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "GÜRÜN",
        "STREET": "KARAYAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "GÜRÜN",
        "STREET": "KARAÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "GÜRÜN",
        "STREET": "KARŞIYAKA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "GÜRÜN",
        "STREET": "KAVAKKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "GÜRÜN",
        "STREET": "KAYALAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "GÜRÜN",
        "STREET": "KAYNARCA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "GÜRÜN",
        "STREET": "KAŞKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "GÜRÜN",
        "STREET": "KETENÇAYIR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "GÜRÜN",
        "STREET": "KILIÇDOĞAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "GÜRÜN",
        "STREET": "KINDIRALIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "GÜRÜN",
        "STREET": "KIZILBURUN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "GÜRÜN",
        "STREET": "KIZILPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "GÜRÜN",
        "STREET": "KIZILÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "GÜRÜN",
        "STREET": "KONAKPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "GÜRÜN",
        "STREET": "KOYUNLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "GÜRÜN",
        "STREET": "KURULTAY MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "GÜRÜN",
        "STREET": "KÜLAHLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "GÜRÜN",
        "STREET": "KÜRKCÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "GÜRÜN",
        "STREET": "KİRAZLIK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "GÜRÜN",
        "STREET": "MAHKENLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "GÜRÜN",
        "STREET": "MAĞARA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "GÜRÜN",
        "STREET": "OSMANDEDE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "GÜRÜN",
        "STREET": "PINARÖNÜ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "GÜRÜN",
        "STREET": "REŞADİYE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "GÜRÜN",
        "STREET": "SARICA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "GÜRÜN",
        "STREET": "SULARBAŞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "GÜRÜN",
        "STREET": "TEPECİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "GÜRÜN",
        "STREET": "YASSICATEPE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "GÜRÜN",
        "STREET": "YAYLACIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "GÜRÜN",
        "STREET": "YAZYURDU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "GÜRÜN",
        "STREET": "YELKEN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "GÜRÜN",
        "STREET": "YENİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "GÜRÜN",
        "STREET": "YENİBEKTAŞLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "GÜRÜN",
        "STREET": "YEŞİLDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "GÜRÜN",
        "STREET": "YEŞİLYURT MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "GÜRÜN",
        "STREET": "YILANHÜYÜK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "GÜRÜN",
        "STREET": "YOLGEÇEN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "GÜRÜN",
        "STREET": "YUVA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "GÜRÜN",
        "STREET": "ÇAMLICA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "GÜRÜN",
        "STREET": "ÇAYBOYU MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "GÜRÜN",
        "STREET": "ÇÖRTEN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "GÜRÜN",
        "STREET": "ÇİÇEKYURT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "GÜRÜN",
        "STREET": "İNCESU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "GÜRÜN",
        "STREET": "ŞUĞUL MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "HAFİK",
        "STREET": "ACIPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "HAFİK",
        "STREET": "ADAMLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "HAFİK",
        "STREET": "AKKAYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "HAFİK",
        "STREET": "AKTAŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "HAFİK",
        "STREET": "ALANYURT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "HAFİK",
        "STREET": "ALÇIÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "HAFİK",
        "STREET": "ALİBEYLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "HAFİK",
        "STREET": "AYLIOĞLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "HAFİK",
        "STREET": "BAHÇECİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "HAFİK",
        "STREET": "BAKIMLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "HAFİK",
        "STREET": "BAYIRALTI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "HAFİK",
        "STREET": "BAYRAMTEPE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "HAFİK",
        "STREET": "BENLİKAYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "HAFİK",
        "STREET": "BESİNLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "HAFİK",
        "STREET": "BEYDİLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "HAFİK",
        "STREET": "BEYKONAĞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "HAFİK",
        "STREET": "CELALLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "HAFİK",
        "STREET": "DEMİRCİLİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "HAFİK",
        "STREET": "DERİNCE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "HAFİK",
        "STREET": "DEĞİRMENBOĞAZI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "HAFİK",
        "STREET": "DEĞİRMENSEKİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "HAFİK",
        "STREET": "DIŞKAPI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "HAFİK",
        "STREET": "DURULMUŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "HAFİK",
        "STREET": "DÜNDAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "HAFİK",
        "STREET": "DÜZYAYLA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "HAFİK",
        "STREET": "DÜĞER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "HAFİK",
        "STREET": "EKİNGÖLÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "HAFİK",
        "STREET": "EMRE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "HAFİK",
        "STREET": "ESENLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "HAFİK",
        "STREET": "ESENTEPE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "HAFİK",
        "STREET": "EVCİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "HAFİK",
        "STREET": "EYMİR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "HAFİK",
        "STREET": "FATİH MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "HAFİK",
        "STREET": "GEDİKÇAYIRI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "HAFİK",
        "STREET": "GÖLCÜK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "HAFİK",
        "STREET": "GÖYDÜN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "HAFİK",
        "STREET": "GÜNYAMAÇ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "HAFİK",
        "STREET": "GÜNYAMAÇ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "HAFİK",
        "STREET": "KABALI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "HAFİK",
        "STREET": "KAMIŞKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "HAFİK",
        "STREET": "KARAYAPRAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "HAFİK",
        "STREET": "KARLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "HAFİK",
        "STREET": "KAYTARMIŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "HAFİK",
        "STREET": "KIZILCAÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "HAFİK",
        "STREET": "KIZILÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "HAFİK",
        "STREET": "KOÇ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "HAFİK",
        "STREET": "KOŞUTDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "HAFİK",
        "STREET": "KURUGÖL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "HAFİK",
        "STREET": "KÜPECİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "HAFİK",
        "STREET": "MADEN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "HAFİK",
        "STREET": "MANSURLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "HAFİK",
        "STREET": "OLUKBAŞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "HAFİK",
        "STREET": "OTMANALAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "HAFİK",
        "STREET": "OYMADERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "HAFİK",
        "STREET": "PUSAT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "HAFİK",
        "STREET": "PİRHÜSEYİN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "HAFİK",
        "STREET": "SOFULAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "HAFİK",
        "STREET": "SÜLEYMANİYE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "HAFİK",
        "STREET": "TAVŞANLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "HAFİK",
        "STREET": "TEPE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "HAFİK",
        "STREET": "TOPÇUYENİKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "HAFİK",
        "STREET": "TUZHİSAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "HAFİK",
        "STREET": "YAKABOYU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "HAFİK",
        "STREET": "YALINCAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "HAFİK",
        "STREET": "YALNIZAĞIL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "HAFİK",
        "STREET": "YARHİSAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "HAFİK",
        "STREET": "YENİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "HAFİK",
        "STREET": "YENİKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "HAFİK",
        "STREET": "ÇAKMAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "HAFİK",
        "STREET": "ÇALTILI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "HAFİK",
        "STREET": "ÇAY MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "HAFİK",
        "STREET": "ÇINARLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "HAFİK",
        "STREET": "ÇÖMLEKLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "HAFİK",
        "STREET": "ÇİMENYENİCE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "HAFİK",
        "STREET": "ÖREĞİL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "HAFİK",
        "STREET": "ÖZEN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "HAFİK",
        "STREET": "ÜZEYİR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "HAFİK",
        "STREET": "İNANDIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "HAFİK",
        "STREET": "İNKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "HAFİK",
        "STREET": "ŞAHİN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "KANGAL",
        "STREET": "AKDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "KANGAL",
        "STREET": "AKGEDİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "KANGAL",
        "STREET": "AKPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "KANGAL",
        "STREET": "AKTEPE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "KANGAL",
        "STREET": "AKÇAKALE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "KANGAL",
        "STREET": "AKÇAMAĞARA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "KANGAL",
        "STREET": "AKÇAŞEHİR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "KANGAL",
        "STREET": "ALACAHAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "KANGAL",
        "STREET": "ALACAHAN YENİKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "KANGAL",
        "STREET": "ARMAĞAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "KANGAL",
        "STREET": "ARPALI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "KANGAL",
        "STREET": "AVŞARÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "KANGAL",
        "STREET": "AŞAĞIHÜYÜK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "KANGAL",
        "STREET": "BAHÇELİYURT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "KANGAL",
        "STREET": "BEKTAŞKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "KANGAL",
        "STREET": "BEYYURDU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "KANGAL",
        "STREET": "BOZARMUT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "KANGAL",
        "STREET": "BOĞAZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "KANGAL",
        "STREET": "BULAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "KANGAL",
        "STREET": "CEVİZKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "KANGAL",
        "STREET": "DAVULBAZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "KANGAL",
        "STREET": "DAYILI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "KANGAL",
        "STREET": "DAĞÖNÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "KANGAL",
        "STREET": "DELİGAZİLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "KANGAL",
        "STREET": "DELİKTAŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "KANGAL",
        "STREET": "DELİOĞLANDERESİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "KANGAL",
        "STREET": "DEREKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "KANGAL",
        "STREET": "DIŞLIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "KANGAL",
        "STREET": "DÜZCE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "KANGAL",
        "STREET": "ELALİBEY KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "KANGAL",
        "STREET": "ELKONDU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "KANGAL",
        "STREET": "ETYEMEZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "KANGAL",
        "STREET": "EYMİR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "KANGAL",
        "STREET": "EĞRİCEK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "KANGAL",
        "STREET": "GEBELİKATRAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "KANGAL",
        "STREET": "GENÇALİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "KANGAL",
        "STREET": "GÖZE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "KANGAL",
        "STREET": "GÜNEYPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "KANGAL",
        "STREET": "GÜRSEL MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "KANGAL",
        "STREET": "GÜRÜKBEKİR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "KANGAL",
        "STREET": "HAMAL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "KANGAL",
        "STREET": "HAMZABEY KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "KANGAL",
        "STREET": "HATUNÇAYIRI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "KANGAL",
        "STREET": "HAVUZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "KANGAL",
        "STREET": "HUMARLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "KANGAL",
        "STREET": "HÜRRİYET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "KANGAL",
        "STREET": "HÜYÜKLÜYURT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "KANGAL",
        "STREET": "IRMAÇ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "KANGAL",
        "STREET": "KABAKÇEVLİĞİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "KANGAL",
        "STREET": "KALKIM KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "KANGAL",
        "STREET": "KANGALTEKKESİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "KANGAL",
        "STREET": "KARACAÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "KANGAL",
        "STREET": "KARAGÜCÜK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "KANGAL",
        "STREET": "KARAMEHMETLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "KANGAL",
        "STREET": "KARANLIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "KANGAL",
        "STREET": "KARASÜVER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "KANGAL",
        "STREET": "KARŞIYAKA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "KANGAL",
        "STREET": "KAVAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "KANGAL",
        "STREET": "KAVAK MESCİT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "KANGAL",
        "STREET": "KIRKPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "KANGAL",
        "STREET": "KIZILDİKME KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "KANGAL",
        "STREET": "KIZILENİŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "KANGAL",
        "STREET": "KOCAKURT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "KANGAL",
        "STREET": "KOÇKÖPRÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "KANGAL",
        "STREET": "KURUAYŞE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "KANGAL",
        "STREET": "KUŞKAYASI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "KANGAL",
        "STREET": "KÜLEKLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "KANGAL",
        "STREET": "KÜRKÇÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "KANGAL",
        "STREET": "KİLLİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "KANGAL",
        "STREET": "MAKSUTLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "KANGAL",
        "STREET": "MANCILIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "KANGAL",
        "STREET": "MESCİT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "KANGAL",
        "STREET": "MISIRÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "KANGAL",
        "STREET": "MÜHÜRKULAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "KANGAL",
        "STREET": "MÜRSEL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "KANGAL",
        "STREET": "MİNAREKAYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "KANGAL",
        "STREET": "ONARAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "KANGAL",
        "STREET": "OĞLAKLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "KANGAL",
        "STREET": "PAŞAMÇAYIRI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "KANGAL",
        "STREET": "PINARGÖZÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "KANGAL",
        "STREET": "REŞADİYE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "KANGAL",
        "STREET": "SACAYAĞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "KANGAL",
        "STREET": "SARIKADI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "KANGAL",
        "STREET": "SARIPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "KANGAL",
        "STREET": "SEÇENYURDU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "KANGAL",
        "STREET": "SOĞUKPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "KANGAL",
        "STREET": "SULARBAŞI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "KANGAL",
        "STREET": "SULTANPINARI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "KANGAL",
        "STREET": "SUTAŞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "KANGAL",
        "STREET": "SİPAHİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "KANGAL",
        "STREET": "TAHTALI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "KANGAL",
        "STREET": "TATLIPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "KANGAL",
        "STREET": "TAŞLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "KANGAL",
        "STREET": "TAŞLIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "KANGAL",
        "STREET": "TOPARDIÇ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "KANGAL",
        "STREET": "TURNALI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "KANGAL",
        "STREET": "TİLKİHÜYÜK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "KANGAL",
        "STREET": "YARHİSAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "KANGAL",
        "STREET": "YAYLACIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "KANGAL",
        "STREET": "YELLİCE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "KANGAL",
        "STREET": "YENİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "KANGAL",
        "STREET": "YEŞİLDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "KANGAL",
        "STREET": "YEŞİLKALE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "KANGAL",
        "STREET": "YEŞİLVADİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "KANGAL",
        "STREET": "YEŞİLYURT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "KANGAL",
        "STREET": "YUKARIHÜYÜK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "KANGAL",
        "STREET": "ZAFER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "KANGAL",
        "STREET": "ZERK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "KANGAL",
        "STREET": "ÇAMURLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "KANGAL",
        "STREET": "ÇATKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "KANGAL",
        "STREET": "ÇAYIROVA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "KANGAL",
        "STREET": "ÇAĞLICAÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "KANGAL",
        "STREET": "ÇETİNKAYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "KANGAL",
        "STREET": "ÇİFTLİKÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "KANGAL",
        "STREET": "ÇİPİL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "KANGAL",
        "STREET": "ÖRENCİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "KANGAL",
        "STREET": "İMAMINKÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "KANGAL",
        "STREET": "İĞDELİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "KANGAL",
        "STREET": "İĞDELİDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "KANGAL",
        "STREET": "ŞEKERPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "KOYULHİSAR",
        "STREET": "AKSEKİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "KOYULHİSAR",
        "STREET": "AKSU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "KOYULHİSAR",
        "STREET": "AYDINLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "KOYULHİSAR",
        "STREET": "AŞAĞIKALE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "KOYULHİSAR",
        "STREET": "BAHÇE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "KOYULHİSAR",
        "STREET": "BALLICA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "KOYULHİSAR",
        "STREET": "BOYALI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "KOYULHİSAR",
        "STREET": "BOZKUŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "KOYULHİSAR",
        "STREET": "CAMİİKEBİR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "KOYULHİSAR",
        "STREET": "DEĞİRMENTAŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "KOYULHİSAR",
        "STREET": "DİLEKLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "KOYULHİSAR",
        "STREET": "EKİNÖZÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "KOYULHİSAR",
        "STREET": "GÖKDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "KOYULHİSAR",
        "STREET": "GÖLCÜK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "KOYULHİSAR",
        "STREET": "GÜMÜŞLÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "KOYULHİSAR",
        "STREET": "GÜNIŞIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "KOYULHİSAR",
        "STREET": "GÜZELYURT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "KOYULHİSAR",
        "STREET": "HACIİLYAS KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "KOYULHİSAR",
        "STREET": "KADİFE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "KOYULHİSAR",
        "STREET": "KALEBAŞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "KOYULHİSAR",
        "STREET": "KARAÇAM KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "KOYULHİSAR",
        "STREET": "KAVACIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "KOYULHİSAR",
        "STREET": "KAYAÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "KOYULHİSAR",
        "STREET": "KILIÇPINARI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "KOYULHİSAR",
        "STREET": "KIZILELMA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "KOYULHİSAR",
        "STREET": "KURŞUNLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "KOYULHİSAR",
        "STREET": "KÜPLÜCE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "KOYULHİSAR",
        "STREET": "ORTA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "KOYULHİSAR",
        "STREET": "ORTAKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "KOYULHİSAR",
        "STREET": "ORTASEKİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "KOYULHİSAR",
        "STREET": "SARIHARMAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "KOYULHİSAR",
        "STREET": "SARIKAYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "KOYULHİSAR",
        "STREET": "SUBAŞI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "KOYULHİSAR",
        "STREET": "SUGÖZÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "KOYULHİSAR",
        "STREET": "SÜTLÜCE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "KOYULHİSAR",
        "STREET": "TAŞPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "KOYULHİSAR",
        "STREET": "TUĞLADAĞI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "KOYULHİSAR",
        "STREET": "YALNIZTEPE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "KOYULHİSAR",
        "STREET": "YAĞCILAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "KOYULHİSAR",
        "STREET": "YENİARSLAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "KOYULHİSAR",
        "STREET": "YENİCE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "KOYULHİSAR",
        "STREET": "YEŞİLYURT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "KOYULHİSAR",
        "STREET": "YOLÜSTÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "KOYULHİSAR",
        "STREET": "YUKARIKALE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "KOYULHİSAR",
        "STREET": "ÇANDIR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "KOYULHİSAR",
        "STREET": "ÇAYLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "KOYULHİSAR",
        "STREET": "ÇUKUROBA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "KOYULHİSAR",
        "STREET": "ÇİÇEKÖZÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "KOYULHİSAR",
        "STREET": "İKİZYAKA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "KOYULHİSAR",
        "STREET": "İSKENDERŞEYH KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "KOYULHİSAR",
        "STREET": "ŞEYHLER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "SUŞEHRİ",
        "STREET": "AKINCI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "SUŞEHRİ",
        "STREET": "AKSU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "SUŞEHRİ",
        "STREET": "AKÇAAĞIL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "SUŞEHRİ",
        "STREET": "AKŞAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "SUŞEHRİ",
        "STREET": "ARPACI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "SUŞEHRİ",
        "STREET": "ARPAYAZI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "SUŞEHRİ",
        "STREET": "AVCULU MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "SUŞEHRİ",
        "STREET": "AŞAĞIAKÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "SUŞEHRİ",
        "STREET": "AŞAĞISARICA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "SUŞEHRİ",
        "STREET": "BALKARA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "SUŞEHRİ",
        "STREET": "BEYDEĞİRMENİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "SUŞEHRİ",
        "STREET": "BOSTANCIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "SUŞEHRİ",
        "STREET": "BOYALICA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "SUŞEHRİ",
        "STREET": "BÜYÜKGÜZEL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "SUŞEHRİ",
        "STREET": "CAMİLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "SUŞEHRİ",
        "STREET": "CAMİİORTA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "SUŞEHRİ",
        "STREET": "CEVİZLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "SUŞEHRİ",
        "STREET": "CUMHURİYET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "SUŞEHRİ",
        "STREET": "ELMASEKİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "SUŞEHRİ",
        "STREET": "ERENCE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "SUŞEHRİ",
        "STREET": "ESENYAKA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "SUŞEHRİ",
        "STREET": "ESKİMEŞE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "SUŞEHRİ",
        "STREET": "ESKİTOPRAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "SUŞEHRİ",
        "STREET": "ESKİŞAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "SUŞEHRİ",
        "STREET": "GELENGEÇ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "SUŞEHRİ",
        "STREET": "GÖKÇEKAŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "SUŞEHRİ",
        "STREET": "GÖKÇEKENT KARAAĞAÇ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "SUŞEHRİ",
        "STREET": "GÖKÇEKENT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "SUŞEHRİ",
        "STREET": "GÖLLÜKÖY MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "SUŞEHRİ",
        "STREET": "GÖNÜLOĞLU MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "SUŞEHRİ",
        "STREET": "GÖZKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "SUŞEHRİ",
        "STREET": "GÜDELİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "SUŞEHRİ",
        "STREET": "GÜMÜŞTAŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "SUŞEHRİ",
        "STREET": "GÜNEYLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "SUŞEHRİ",
        "STREET": "GÜNGÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "SUŞEHRİ",
        "STREET": "GÜNLÜCE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "SUŞEHRİ",
        "STREET": "HÖDÜCEK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "SUŞEHRİ",
        "STREET": "KALE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "SUŞEHRİ",
        "STREET": "KARACAÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "SUŞEHRİ",
        "STREET": "KARALAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "SUŞEHRİ",
        "STREET": "KARŞIYAKA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "SUŞEHRİ",
        "STREET": "KAVŞUT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "SUŞEHRİ",
        "STREET": "KAYADELEN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "SUŞEHRİ",
        "STREET": "KEKEÇ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "SUŞEHRİ",
        "STREET": "KEMALLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "SUŞEHRİ",
        "STREET": "KEMALPAŞA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "SUŞEHRİ",
        "STREET": "KESİKKAŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "SUŞEHRİ",
        "STREET": "KIZILTAŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "SUŞEHRİ",
        "STREET": "KIŞLALAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "SUŞEHRİ",
        "STREET": "KOZÇUKUR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "SUŞEHRİ",
        "STREET": "KURUGÖL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "SUŞEHRİ",
        "STREET": "KUZDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "SUŞEHRİ",
        "STREET": "KÜÇÜKGÜZEL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "SUŞEHRİ",
        "STREET": "KİZİRYURDU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "SUŞEHRİ",
        "STREET": "MERKEZ KARAAĞAÇ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "SUŞEHRİ",
        "STREET": "OLUCAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "SUŞEHRİ",
        "STREET": "POLAT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "SUŞEHRİ",
        "STREET": "SARAYCIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "SUŞEHRİ",
        "STREET": "SAĞPAZAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "SUŞEHRİ",
        "STREET": "SOLAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "SUŞEHRİ",
        "STREET": "SÖKÜN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "SUŞEHRİ",
        "STREET": "TAKLAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "SUŞEHRİ",
        "STREET": "TATAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "SUŞEHRİ",
        "STREET": "TAŞBAYIR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "SUŞEHRİ",
        "STREET": "TAŞKÖPRÜ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "SUŞEHRİ",
        "STREET": "TEPEARDI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "SUŞEHRİ",
        "STREET": "TÜRKMENLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "SUŞEHRİ",
        "STREET": "YALNIZBAĞLAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "SUŞEHRİ",
        "STREET": "YAMAÇ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "SUŞEHRİ",
        "STREET": "YAYGINSÖĞÜT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "SUŞEHRİ",
        "STREET": "YELKESEN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "SUŞEHRİ",
        "STREET": "YENİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "SUŞEHRİ",
        "STREET": "YEŞİLYAYLA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "SUŞEHRİ",
        "STREET": "YONCALI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "SUŞEHRİ",
        "STREET": "YUKARIAKÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "SUŞEHRİ",
        "STREET": "YÜREKLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "SUŞEHRİ",
        "STREET": "ÇAKIRLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "SUŞEHRİ",
        "STREET": "ÇAMLIDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "SUŞEHRİ",
        "STREET": "ÇATALOLUK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "SUŞEHRİ",
        "STREET": "ÇOKRAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "SUŞEHRİ",
        "STREET": "ÇİTLİCE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "SUŞEHRİ",
        "STREET": "ÖKSÜLÜ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "SUŞEHRİ",
        "STREET": "ÜZÜMLÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "SUŞEHRİ",
        "STREET": "ŞARKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "MERKEZ",
        "STREET": "ABDULVAHABİGAZİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "MERKEZ",
        "STREET": "ACIDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "MERKEZ",
        "STREET": "ACIPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "MERKEZ",
        "STREET": "AHMET TURANGAZİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "MERKEZ",
        "STREET": "AKDEĞİRMEN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "MERKEZ",
        "STREET": "AKKOÇ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "MERKEZ",
        "STREET": "AKKUZULU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "MERKEZ",
        "STREET": "AKPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "MERKEZ",
        "STREET": "AKÇAHAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "MERKEZ",
        "STREET": "AKÇAMESCİT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "MERKEZ",
        "STREET": "AKÇAİNİŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "MERKEZ",
        "STREET": "AKÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "MERKEZ",
        "STREET": "ALAHACI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "MERKEZ",
        "STREET": "ALTUNTABAK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "MERKEZ",
        "STREET": "ALİBABA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "MERKEZ",
        "STREET": "APA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "MERKEZ",
        "STREET": "ARMUTLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "MERKEZ",
        "STREET": "ARPAYAZI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "MERKEZ",
        "STREET": "ASARCIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "MERKEZ",
        "STREET": "AYDOĞAN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "MERKEZ",
        "STREET": "AYDOĞMUŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "MERKEZ",
        "STREET": "AYLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "MERKEZ",
        "STREET": "AĞILKAYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "MERKEZ",
        "STREET": "AŞAĞIYILDIZLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "MERKEZ",
        "STREET": "BADEMKAYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "MERKEZ",
        "STREET": "BAHTİYARBOSTAN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "MERKEZ",
        "STREET": "BARCIN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "MERKEZ",
        "STREET": "BAŞIBÜYÜK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "MERKEZ",
        "STREET": "BAŞSÖĞÜT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "MERKEZ",
        "STREET": "BEDİRLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "MERKEZ",
        "STREET": "BEYPINARI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "MERKEZ",
        "STREET": "BEŞPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "MERKEZ",
        "STREET": "BEŞTEPE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "MERKEZ",
        "STREET": "BOSTANCIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "MERKEZ",
        "STREET": "BUDAKLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "MERKEZ",
        "STREET": "BİNGÖL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "MERKEZ",
        "STREET": "CAMİ-İ KEBİR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "MERKEZ",
        "STREET": "DAMILI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "MERKEZ",
        "STREET": "DAMLACIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "MERKEZ",
        "STREET": "DANİŞMENTGAZİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "MERKEZ",
        "STREET": "DEDEBALI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "MERKEZ",
        "STREET": "DEMİRCİLERARDI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "MERKEZ",
        "STREET": "DEMİRYAZI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "MERKEZ",
        "STREET": "DOĞANCA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "MERKEZ",
        "STREET": "DURDULU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "MERKEZ",
        "STREET": "DÖRTEYLÜL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "MERKEZ",
        "STREET": "DÖRTEYLÜL MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "MERKEZ",
        "STREET": "DÜZOVA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "MERKEZ",
        "STREET": "DİKMENCİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "MERKEZ",
        "STREET": "DİRİLİŞ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "MERKEZ",
        "STREET": "EBUHAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "MERKEZ",
        "STREET": "ECE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "MERKEZ",
        "STREET": "EMEK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "MERKEZ",
        "STREET": "EMİRHAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "MERKEZ",
        "STREET": "ESENTEPE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "MERKEZ",
        "STREET": "ESENYURT MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "MERKEZ",
        "STREET": "ESKİAPARDI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "MERKEZ",
        "STREET": "ESKİBOĞAZKESEN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "MERKEZ",
        "STREET": "ESKİKALE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "MERKEZ",
        "STREET": "ESKİKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "MERKEZ",
        "STREET": "EĞRİBUCAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "MERKEZ",
        "STREET": "EĞRİKÖPRÜ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "MERKEZ",
        "STREET": "FATİH MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "MERKEZ",
        "STREET": "FERHATBOSTAN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "MERKEZ",
        "STREET": "GAZİBEY KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "MERKEZ",
        "STREET": "GAZİKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "MERKEZ",
        "STREET": "GÖKMEDRESE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "MERKEZ",
        "STREET": "GÖKÇEBOSTAN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "MERKEZ",
        "STREET": "GÖZMEN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "MERKEZ",
        "STREET": "GÜLLÜCE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "MERKEZ",
        "STREET": "GÜLLÜK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "MERKEZ",
        "STREET": "GÜLPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "MERKEZ",
        "STREET": "GÜLTEPE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "MERKEZ",
        "STREET": "GÜLYURT MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "MERKEZ",
        "STREET": "GÜMÜŞDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "MERKEZ",
        "STREET": "GÜNDÜZKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "MERKEZ",
        "STREET": "GÜNECE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "MERKEZ",
        "STREET": "GÜNELİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "MERKEZ",
        "STREET": "GÜNEY KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "MERKEZ",
        "STREET": "GÜNÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "MERKEZ",
        "STREET": "HACIALİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "MERKEZ",
        "STREET": "HALİL RIFATPAŞA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "MERKEZ",
        "STREET": "HANLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "MERKEZ",
        "STREET": "HARMANCIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "MERKEZ",
        "STREET": "HASBEY KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "MERKEZ",
        "STREET": "HAYDARLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "MERKEZ",
        "STREET": "HAYIRBEY KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "MERKEZ",
        "STREET": "HAYRANLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "MERKEZ",
        "STREET": "HEREKLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "MERKEZ",
        "STREET": "HIDIRNALI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "MERKEZ",
        "STREET": "HOCABEY KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "MERKEZ",
        "STREET": "HUZUR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "MERKEZ",
        "STREET": "HİMMETFAKI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "MERKEZ",
        "STREET": "KABASAKAL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "MERKEZ",
        "STREET": "KADI BURHANETTİN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "MERKEZ",
        "STREET": "KAHYALI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "MERKEZ",
        "STREET": "KALEARDI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "MERKEZ",
        "STREET": "KARABALÇIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "MERKEZ",
        "STREET": "KARACAÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "MERKEZ",
        "STREET": "KARAGÖMLEK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "MERKEZ",
        "STREET": "KARALAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "MERKEZ",
        "STREET": "KARALI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "MERKEZ",
        "STREET": "KARAMEHMETOĞLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "MERKEZ",
        "STREET": "KARAPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "MERKEZ",
        "STREET": "KARAYÜN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "MERKEZ",
        "STREET": "KARAÇAYIR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "MERKEZ",
        "STREET": "KARDEŞLER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "MERKEZ",
        "STREET": "KARTALCA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "MERKEZ",
        "STREET": "KARŞIYAKA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "MERKEZ",
        "STREET": "KAVLAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "MERKEZ",
        "STREET": "KAYADİBİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "MERKEZ",
        "STREET": "KERVANSARAY KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "MERKEZ",
        "STREET": "KEÇİLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "MERKEZ",
        "STREET": "KILAVUZ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "MERKEZ",
        "STREET": "KIZILALAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "MERKEZ",
        "STREET": "KIZILCA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "MERKEZ",
        "STREET": "KIZILCAKIŞLA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "MERKEZ",
        "STREET": "KIZILIRMAK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "MERKEZ",
        "STREET": "KIZILKAVRAZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "MERKEZ",
        "STREET": "KIZILOVA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "MERKEZ",
        "STREET": "KIZILÖZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "MERKEZ",
        "STREET": "KIZKAPAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "MERKEZ",
        "STREET": "KOLLUCA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "MERKEZ",
        "STREET": "KOYUNCU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "MERKEZ",
        "STREET": "KULYUSUF KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "MERKEZ",
        "STREET": "KUMYURT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "MERKEZ",
        "STREET": "KURTLAPA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "MERKEZ",
        "STREET": "KUZÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "MERKEZ",
        "STREET": "KUŞLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "MERKEZ",
        "STREET": "KÖKLÜCE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "MERKEZ",
        "STREET": "KÖRTUZLA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "MERKEZ",
        "STREET": "KÜMBET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "MERKEZ",
        "STREET": "KÜÇÜKMİNARE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "MERKEZ",
        "STREET": "MEHMET AKİF ERSOY MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "MERKEZ",
        "STREET": "MEHMETPAŞA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "MERKEZ",
        "STREET": "MENŞURLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "MERKEZ",
        "STREET": "MERAKÜM MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "MERKEZ",
        "STREET": "MERMER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "MERKEZ",
        "STREET": "MESCİTLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "MERKEZ",
        "STREET": "MEVLANA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "MERKEZ",
        "STREET": "MISMILIRMAK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "MERKEZ",
        "STREET": "MİMAR SİNAN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "MERKEZ",
        "STREET": "OLUKMAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "MERKEZ",
        "STREET": "ONBAŞILAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "MERKEZ",
        "STREET": "ORHANGAZİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "MERKEZ",
        "STREET": "OVACIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "MERKEZ",
        "STREET": "OZMUŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "MERKEZ",
        "STREET": "PAŞABEY MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "MERKEZ",
        "STREET": "PINARCA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "MERKEZ",
        "STREET": "PORSUK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "MERKEZ",
        "STREET": "PULUR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "MERKEZ",
        "STREET": "SARIDEMİR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "MERKEZ",
        "STREET": "SAVCUN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "MERKEZ",
        "STREET": "SELÇUKLU MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "MERKEZ",
        "STREET": "SERPİNCİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "MERKEZ",
        "STREET": "SEYRANTEPE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "MERKEZ",
        "STREET": "SORKUNCUK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "MERKEZ",
        "STREET": "SULARBAŞI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "MERKEZ",
        "STREET": "SÖĞÜTCÜK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "MERKEZ",
        "STREET": "SİVAS E TİPİ KAPALI CEZAEVİ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "MERKEZ",
        "STREET": "SİVRİTEPE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "MERKEZ",
        "STREET": "TAHTIKEMENT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "MERKEZ",
        "STREET": "TATLICAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "MERKEZ",
        "STREET": "TEPEÖNÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "MERKEZ",
        "STREET": "TOKUŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "MERKEZ",
        "STREET": "TUTMAÇ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "MERKEZ",
        "STREET": "TUZLUGÖL MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "MERKEZ",
        "STREET": "TÖRNÜK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "MERKEZ",
        "STREET": "ULUKAPI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "MERKEZ",
        "STREET": "UZUNTEPE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "MERKEZ",
        "STREET": "YAHYABEY MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "MERKEZ",
        "STREET": "YAKUPOĞLAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "MERKEZ",
        "STREET": "YANALAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "MERKEZ",
        "STREET": "YARAMIŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "MERKEZ",
        "STREET": "YASSICABEL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "MERKEZ",
        "STREET": "YAVU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "MERKEZ",
        "STREET": "YAZIBAŞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "MERKEZ",
        "STREET": "YAĞMURLUSEKİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "MERKEZ",
        "STREET": "YENİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "MERKEZ",
        "STREET": "YENİAPARDI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "MERKEZ",
        "STREET": "YENİBOĞAZKESEN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "MERKEZ",
        "STREET": "YENİCE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "MERKEZ",
        "STREET": "YENİDOĞAN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "MERKEZ",
        "STREET": "YENİKERVANSARAY KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "MERKEZ",
        "STREET": "YENİKIZILCAKIŞLA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "MERKEZ",
        "STREET": "YENİŞEHİR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "MERKEZ",
        "STREET": "YEŞİLYURT MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "MERKEZ",
        "STREET": "YILDIZ-FATİH MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "MERKEZ",
        "STREET": "YILDIZ-FEVZİÇAKMAK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "MERKEZ",
        "STREET": "YILDIZ-ŞEHİTÖMERYILDIRIM MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "MERKEZ",
        "STREET": "YUKARIYILDIZLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "MERKEZ",
        "STREET": "YUNUSEMRE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "MERKEZ",
        "STREET": "YÜCEYURT MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "MERKEZ",
        "STREET": "YİĞİTLER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "MERKEZ",
        "STREET": "ZENGİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇALLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇARŞIBAŞI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇATALKAYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇAYBOYU MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇAYGÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇAYPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇAYYURT MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇELEBİLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇELTEK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇERÇİDERESİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇONGAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇUKURBELEN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇİÇEKLİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "MERKEZ",
        "STREET": "ÖRENCİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "MERKEZ",
        "STREET": "ÖRTÜLÜPINAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "MERKEZ",
        "STREET": "ÜÇLERBEY MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "MERKEZ",
        "STREET": "ÜÇTEPE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "MERKEZ",
        "STREET": "İLKİNDİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "MERKEZ",
        "STREET": "İNCEAĞA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "MERKEZ",
        "STREET": "İNCEYOL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "MERKEZ",
        "STREET": "İNÖNÜ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "MERKEZ",
        "STREET": "İSMAİLBEYÇİFTLİĞİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "MERKEZ",
        "STREET": "İSTİKLAL MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "MERKEZ",
        "STREET": "İŞHANI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "MERKEZ",
        "STREET": "ŞABAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "MERKEZ",
        "STREET": "ŞAHBEY KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "MERKEZ",
        "STREET": "ŞEYH ŞAMİL MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "ULAŞ",
        "STREET": "ACIYURT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "ULAŞ",
        "STREET": "AKKAYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "ULAŞ",
        "STREET": "AŞAĞIADA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "ULAŞ",
        "STREET": "BAHARÖZÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "ULAŞ",
        "STREET": "BAŞÇAYIR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "ULAŞ",
        "STREET": "BOSTANKAYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "ULAŞ",
        "STREET": "BOĞAZDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "ULAŞ",
        "STREET": "DEMİRCİLİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "ULAŞ",
        "STREET": "EKİNCİOĞLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "ULAŞ",
        "STREET": "ESENTEPE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "ULAŞ",
        "STREET": "ESKİKARAHİSAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "ULAŞ",
        "STREET": "EZENTERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "ULAŞ",
        "STREET": "GÜMÜŞPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "ULAŞ",
        "STREET": "GÜNEŞLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "ULAŞ",
        "STREET": "GÜRPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "ULAŞ",
        "STREET": "HACIMİRZA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "ULAŞ",
        "STREET": "HÜRRİYET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "ULAŞ",
        "STREET": "KAPUKAYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "ULAŞ",
        "STREET": "KARACALAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "ULAŞ",
        "STREET": "KARAGÖL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "ULAŞ",
        "STREET": "KARATAŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "ULAŞ",
        "STREET": "KARAŞAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "ULAŞ",
        "STREET": "KAYAPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "ULAŞ",
        "STREET": "KAZANPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "ULAŞ",
        "STREET": "KERTMEKARACAÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "ULAŞ",
        "STREET": "KORUBAŞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "ULAŞ",
        "STREET": "KOVALI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "ULAŞ",
        "STREET": "KURTLUKAYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "ULAŞ",
        "STREET": "KURTOĞLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "ULAŞ",
        "STREET": "KÜPELİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "ULAŞ",
        "STREET": "OVACIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "ULAŞ",
        "STREET": "TECER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "ULAŞ",
        "STREET": "YAPALI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "ULAŞ",
        "STREET": "YAZICIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "ULAŞ",
        "STREET": "YAĞDONDURAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "ULAŞ",
        "STREET": "YENİKARAHİSAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "ULAŞ",
        "STREET": "YEŞİLYURT MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "ULAŞ",
        "STREET": "YUKARIADA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "ULAŞ",
        "STREET": "ÇAVDAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "ULAŞ",
        "STREET": "ÇEVİRME KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "ULAŞ",
        "STREET": "ÖRENLİCE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "ULAŞ",
        "STREET": "ŞENYURT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "YILDIZELİ",
        "STREET": "AKKOCA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "YILDIZELİ",
        "STREET": "AKPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "YILDIZELİ",
        "STREET": "AKPINARBELENİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "YILDIZELİ",
        "STREET": "AKÇAKALE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "YILDIZELİ",
        "STREET": "AKÇALI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "YILDIZELİ",
        "STREET": "AKÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "YILDIZELİ",
        "STREET": "ALACA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "YILDIZELİ",
        "STREET": "ALTINOLUK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "YILDIZELİ",
        "STREET": "ARSLANDOĞMUŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "YILDIZELİ",
        "STREET": "AVCIPINARI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "YILDIZELİ",
        "STREET": "AŞAĞIEKECİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "YILDIZELİ",
        "STREET": "AŞAĞIÇAKMAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "YILDIZELİ",
        "STREET": "BAKIRCIOĞLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "YILDIZELİ",
        "STREET": "BANAZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "YILDIZELİ",
        "STREET": "BAYAT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "YILDIZELİ",
        "STREET": "BAŞKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "YILDIZELİ",
        "STREET": "BEDEL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "YILDIZELİ",
        "STREET": "BELCİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "YILDIZELİ",
        "STREET": "BUĞDAYÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "YILDIZELİ",
        "STREET": "BÜYÜKAKÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "YILDIZELİ",
        "STREET": "CAMİİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "YILDIZELİ",
        "STREET": "CUMHURİYET KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "YILDIZELİ",
        "STREET": "CUMHURİYET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "YILDIZELİ",
        "STREET": "CİZÖZÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "YILDIZELİ",
        "STREET": "DANAÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "YILDIZELİ",
        "STREET": "DANİŞMENT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "YILDIZELİ",
        "STREET": "DAVULALAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "YILDIZELİ",
        "STREET": "DELİKKAYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "YILDIZELİ",
        "STREET": "DEMİRCİLİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "YILDIZELİ",
        "STREET": "DEMİROLUK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "YILDIZELİ",
        "STREET": "DEMİRÖZÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "YILDIZELİ",
        "STREET": "DEREKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "YILDIZELİ",
        "STREET": "DOĞANLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "YILDIZELİ",
        "STREET": "DİKİLİTAŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "YILDIZELİ",
        "STREET": "DİREKLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "YILDIZELİ",
        "STREET": "DİREKLİ SARIKAYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "YILDIZELİ",
        "STREET": "EMİRLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "YILDIZELİ",
        "STREET": "ERENLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "YILDIZELİ",
        "STREET": "ESENÇAY KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "YILDIZELİ",
        "STREET": "EŞMEBAŞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "YILDIZELİ",
        "STREET": "FATİHSULTANMEHMET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "YILDIZELİ",
        "STREET": "FEVZİ ÇAKMAK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "YILDIZELİ",
        "STREET": "FINDICAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "YILDIZELİ",
        "STREET": "GEYNİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "YILDIZELİ",
        "STREET": "GÖKKAYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "YILDIZELİ",
        "STREET": "GÖKÇELİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "YILDIZELİ",
        "STREET": "GÜNEYKAYA-AYDIN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "YILDIZELİ",
        "STREET": "GÜNEYKAYA-KARGIN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "YILDIZELİ",
        "STREET": "GÜNEYKAYA-YENİCAMİİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "YILDIZELİ",
        "STREET": "GÜNEYKAYA-YEŞİLYURT MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "YILDIZELİ",
        "STREET": "HALKAÇAYIR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "YILDIZELİ",
        "STREET": "HAMZAŞEYH KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "YILDIZELİ",
        "STREET": "ILICA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "YILDIZELİ",
        "STREET": "KADIKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "YILDIZELİ",
        "STREET": "KADILI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "YILDIZELİ",
        "STREET": "KALEKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "YILDIZELİ",
        "STREET": "KALIN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "YILDIZELİ",
        "STREET": "KAMAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "YILDIZELİ",
        "STREET": "KAPAKLIKAYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "YILDIZELİ",
        "STREET": "KAPI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "YILDIZELİ",
        "STREET": "KARACAÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "YILDIZELİ",
        "STREET": "KARAKAYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "YILDIZELİ",
        "STREET": "KARAKOÇ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "YILDIZELİ",
        "STREET": "KARALAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "YILDIZELİ",
        "STREET": "KARALEYLEK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "YILDIZELİ",
        "STREET": "KARŞIYAKA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "YILDIZELİ",
        "STREET": "KAVAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "YILDIZELİ",
        "STREET": "KAVAKDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "YILDIZELİ",
        "STREET": "KAYALIPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "YILDIZELİ",
        "STREET": "KERİMMÜMİN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "YILDIZELİ",
        "STREET": "KILDIR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "YILDIZELİ",
        "STREET": "KIRKPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "YILDIZELİ",
        "STREET": "KIVŞAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "YILDIZELİ",
        "STREET": "KIZILKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "YILDIZELİ",
        "STREET": "KONAKÖZÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "YILDIZELİ",
        "STREET": "KÜMBET KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "YILDIZELİ",
        "STREET": "KÜÇÜKAKÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "YILDIZELİ",
        "STREET": "KİLLİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "YILDIZELİ",
        "STREET": "KİREMİTLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "YILDIZELİ",
        "STREET": "MEHMETAKİFERSOY MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "YILDIZELİ",
        "STREET": "MENTEŞE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "YILDIZELİ",
        "STREET": "MERKEZ SARIKAYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "YILDIZELİ",
        "STREET": "MERKEZYENİKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "YILDIZELİ",
        "STREET": "MUMCUÇİFTLİĞİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "YILDIZELİ",
        "STREET": "NALLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "YILDIZELİ",
        "STREET": "NEVRUZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "YILDIZELİ",
        "STREET": "NEVRUZYAYLASI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "YILDIZELİ",
        "STREET": "ORTAKLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "YILDIZELİ",
        "STREET": "ORTAÇAKMAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "YILDIZELİ",
        "STREET": "REŞADİYE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "YILDIZELİ",
        "STREET": "SALİHİYE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "YILDIZELİ",
        "STREET": "SANDAL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "YILDIZELİ",
        "STREET": "SARIYAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "YILDIZELİ",
        "STREET": "SARIÇAL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "YILDIZELİ",
        "STREET": "SELAMET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "YILDIZELİ",
        "STREET": "SEREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "YILDIZELİ",
        "STREET": "SUBAŞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "YILDIZELİ",
        "STREET": "SÖĞÜTPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "YILDIZELİ",
        "STREET": "TATKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "YILDIZELİ",
        "STREET": "TAYALAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "YILDIZELİ",
        "STREET": "TOPULYURDU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "YILDIZELİ",
        "STREET": "TÖNGEL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "YILDIZELİ",
        "STREET": "YAKACIKÇAVUŞLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "YILDIZELİ",
        "STREET": "YAKUPKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "YILDIZELİ",
        "STREET": "YASSIKARA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "YILDIZELİ",
        "STREET": "YAVU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "YILDIZELİ",
        "STREET": "YAYLAGÖZE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "YILDIZELİ",
        "STREET": "YAĞLIDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "YILDIZELİ",
        "STREET": "YENİYAPAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "YILDIZELİ",
        "STREET": "YEŞİLALAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "YILDIZELİ",
        "STREET": "YILANLIKAYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "YILDIZELİ",
        "STREET": "YOLKAYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "YILDIZELİ",
        "STREET": "YUKARIEKECİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "YILDIZELİ",
        "STREET": "YUKARIÇAKMAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "YILDIZELİ",
        "STREET": "YUSUFOĞLAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "YILDIZELİ",
        "STREET": "YUVALIÇAYIR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "YILDIZELİ",
        "STREET": "YÜCEBACA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "YILDIZELİ",
        "STREET": "YİĞİTLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "YILDIZELİ",
        "STREET": "ÇAMLIBEL MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "YILDIZELİ",
        "STREET": "ÇAĞLAYAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "YILDIZELİ",
        "STREET": "ÇIRÇIR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "YILDIZELİ",
        "STREET": "ÇOBANSARAY KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "YILDIZELİ",
        "STREET": "ÇUBUK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "YILDIZELİ",
        "STREET": "ÇUKURSARAY KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "YILDIZELİ",
        "STREET": "ÇÖTE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "YILDIZELİ",
        "STREET": "ÜYÜK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "YILDIZELİ",
        "STREET": "ÜYÜKYAYLASI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "YILDIZELİ",
        "STREET": "İNCETAŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "YILDIZELİ",
        "STREET": "İSLİM KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "YILDIZELİ",
        "STREET": "İĞDECİKLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "YILDIZELİ",
        "STREET": "İĞNEBEY KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "YILDIZELİ",
        "STREET": "ŞEYHHALİL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "ZARA",
        "STREET": "ADAMFAKI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "ZARA",
        "STREET": "AHMETBAŞYURT MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "ZARA",
        "STREET": "AHMETHACI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "ZARA",
        "STREET": "AKDEDE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "ZARA",
        "STREET": "AKDEĞİRMEN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "ZARA",
        "STREET": "AKYAZI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "ZARA",
        "STREET": "AKÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "ZARA",
        "STREET": "ALUÇLUBEL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "ZARA",
        "STREET": "ALUÇLUSEKİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "ZARA",
        "STREET": "ALİŞANAĞILI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "ZARA",
        "STREET": "ALİŞİR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "ZARA",
        "STREET": "ARMUTÇAYIRI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "ZARA",
        "STREET": "ATALAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "ZARA",
        "STREET": "ATGEÇMEZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "ZARA",
        "STREET": "ATKIRAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "ZARA",
        "STREET": "AVŞAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "ZARA",
        "STREET": "AĞLIKÇAY KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "ZARA",
        "STREET": "AŞAĞIKOVACIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "ZARA",
        "STREET": "AŞAĞIMESCİT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "ZARA",
        "STREET": "AŞAĞIÇAMURCU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "ZARA",
        "STREET": "AŞAĞIÇAMÖZÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "ZARA",
        "STREET": "BAHARŞEYH KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "ZARA",
        "STREET": "BALLIKLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "ZARA",
        "STREET": "BAĞLAMA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "ZARA",
        "STREET": "BECEKLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "ZARA",
        "STREET": "BEDİRÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "ZARA",
        "STREET": "BEKTAŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "ZARA",
        "STREET": "BELENTARLA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "ZARA",
        "STREET": "BEYPINARI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "ZARA",
        "STREET": "BOLUCAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "ZARA",
        "STREET": "BOZKIR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "ZARA",
        "STREET": "BURHANİYE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "ZARA",
        "STREET": "BÜYÜKGÜNEY KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "ZARA",
        "STREET": "BÜYÜKKAYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "ZARA",
        "STREET": "BÜYÜKKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "ZARA",
        "STREET": "CANOVA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "ZARA",
        "STREET": "CEMAL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "ZARA",
        "STREET": "CULHALI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "ZARA",
        "STREET": "DANIŞIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "ZARA",
        "STREET": "DERBENT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "ZARA",
        "STREET": "DEREDAM KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "ZARA",
        "STREET": "DEREKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "ZARA",
        "STREET": "DÜZCELİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "ZARA",
        "STREET": "DİLEKPINARI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "ZARA",
        "STREET": "DİPSİZGÖL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "ZARA",
        "STREET": "EKİNLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "ZARA",
        "STREET": "EMİRHAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "ZARA",
        "STREET": "ESENLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "ZARA",
        "STREET": "EVRENCİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "ZARA",
        "STREET": "EYMİR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "ZARA",
        "STREET": "GÖHERTAŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "ZARA",
        "STREET": "GÖKTEPE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "ZARA",
        "STREET": "GÖLBAŞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "ZARA",
        "STREET": "GÜLLÜALİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "ZARA",
        "STREET": "GÜMÜŞÇEVRE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "ZARA",
        "STREET": "GİRİT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "ZARA",
        "STREET": "HATİP MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "ZARA",
        "STREET": "ILICA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "ZARA",
        "STREET": "KABAKTEPELER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "ZARA",
        "STREET": "KADRİYE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "ZARA",
        "STREET": "KANLIÇAYIR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "ZARA",
        "STREET": "KAPLAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "ZARA",
        "STREET": "KARACAHİSAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "ZARA",
        "STREET": "KARAHASAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "ZARA",
        "STREET": "KARAMAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "ZARA",
        "STREET": "KARAÇAYIR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "ZARA",
        "STREET": "KARDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "ZARA",
        "STREET": "KARSLILAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "ZARA",
        "STREET": "KARŞIYAKA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "ZARA",
        "STREET": "KAVASBAŞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "ZARA",
        "STREET": "KAYABAŞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "ZARA",
        "STREET": "KAYADİBİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "ZARA",
        "STREET": "KAYLUKAYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "ZARA",
        "STREET": "KELHASAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "ZARA",
        "STREET": "KEVENLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "ZARA",
        "STREET": "KEÇEYURDU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "ZARA",
        "STREET": "KISIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "ZARA",
        "STREET": "KIZIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "ZARA",
        "STREET": "KIZILIRMAK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "ZARA",
        "STREET": "KIZILKALE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "ZARA",
        "STREET": "KORKUT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "ZARA",
        "STREET": "KUMOĞLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "ZARA",
        "STREET": "KURUCAABAT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "ZARA",
        "STREET": "KURUKÖPRÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "ZARA",
        "STREET": "KUŞCU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "ZARA",
        "STREET": "KÖRPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "ZARA",
        "STREET": "KÜMBET KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "ZARA",
        "STREET": "KÜRÜNLÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "ZARA",
        "STREET": "MAHMUTAĞAÇİFTLİĞİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "ZARA",
        "STREET": "MÜSLÜMABAT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "ZARA",
        "STREET": "NASIR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "ZARA",
        "STREET": "OSMANİYE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "ZARA",
        "STREET": "PAZARBELENİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "ZARA",
        "STREET": "PAZARCIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "ZARA",
        "STREET": "REŞİTPAŞA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "ZARA",
        "STREET": "SANCAKKALE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "ZARA",
        "STREET": "SARIYUSUF KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "ZARA",
        "STREET": "SEDİLLE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "ZARA",
        "STREET": "SELİMİYE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "ZARA",
        "STREET": "SETEN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "ZARA",
        "STREET": "SORKUN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "ZARA",
        "STREET": "SUCAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "ZARA",
        "STREET": "SÖĞÜTLÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "ZARA",
        "STREET": "SÖĞÜTLÜAĞIL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "ZARA",
        "STREET": "SÖĞÜTÖZÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "ZARA",
        "STREET": "TAŞGÖZE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "ZARA",
        "STREET": "TEPEKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "ZARA",
        "STREET": "TOPAKTAŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "ZARA",
        "STREET": "TUZLAGÖZÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "ZARA",
        "STREET": "TÖDÜRGE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "ZARA",
        "STREET": "YAPAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "ZARA",
        "STREET": "YARAĞIL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "ZARA",
        "STREET": "YARIMKAYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "ZARA",
        "STREET": "YAYIKÖZÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "ZARA",
        "STREET": "YENİCAMİİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "ZARA",
        "STREET": "YEŞİLDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "ZARA",
        "STREET": "YEŞİLKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "ZARA",
        "STREET": "YEŞİMLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "ZARA",
        "STREET": "YIKILGAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "ZARA",
        "STREET": "YOLÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "ZARA",
        "STREET": "YOĞUNPELİT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "ZARA",
        "STREET": "YUKARIMESCİT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "ZARA",
        "STREET": "YUKARIÇAMURCU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "ZARA",
        "STREET": "YUKARIÇAMÖZÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "ZARA",
        "STREET": "ZOĞALLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "ZARA",
        "STREET": "ÇARŞIBAŞI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "ZARA",
        "STREET": "ÇATÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "ZARA",
        "STREET": "ÇAYLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "ZARA",
        "STREET": "ÇAYLICA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "ZARA",
        "STREET": "ÇAYPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "ZARA",
        "STREET": "ÇEVİRMEHAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "ZARA",
        "STREET": "ÇORAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "ZARA",
        "STREET": "ÜNGÜR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "ZARA",
        "STREET": "ÜTÜK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "ZARA",
        "STREET": "ÜTÜKYURDU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "ZARA",
        "STREET": "İKİDEĞİRMEN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "ZARA",
        "STREET": "İĞDELİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "ZARA",
        "STREET": "İĞDİR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "ZARA",
        "STREET": "ŞAFAKLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "ZARA",
        "STREET": "ŞEHİTLER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "ZARA",
        "STREET": "ŞENYAYLA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "ZARA",
        "STREET": "ŞEREFİYE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "ZARA",
        "STREET": "ŞEYHMERZUBAN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "İMRANLI",
        "STREET": "AKKAYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "İMRANLI",
        "STREET": "ALACAHACI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "İMRANLI",
        "STREET": "ALTINCA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "İMRANLI",
        "STREET": "ARDIÇALAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "İMRANLI",
        "STREET": "ARIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "İMRANLI",
        "STREET": "ATLICA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "İMRANLI",
        "STREET": "AVŞAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "İMRANLI",
        "STREET": "AYDIN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "İMRANLI",
        "STREET": "AYDOĞAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "İMRANLI",
        "STREET": "AŞAĞIBOĞAZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "İMRANLI",
        "STREET": "AŞAĞICULHA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "İMRANLI",
        "STREET": "AŞAĞIŞEYHLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "İMRANLI",
        "STREET": "BAHADUN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "İMRANLI",
        "STREET": "BAHTİYAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "İMRANLI",
        "STREET": "BARDAKLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "İMRANLI",
        "STREET": "BAĞYAZI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "İMRANLI",
        "STREET": "BAŞLICA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "İMRANLI",
        "STREET": "BECEK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "İMRANLI",
        "STREET": "BEĞENDİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "İMRANLI",
        "STREET": "BORULAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "İMRANLI",
        "STREET": "BOĞANAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "İMRANLI",
        "STREET": "BOĞAZÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "İMRANLI",
        "STREET": "BULGURLUK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "İMRANLI",
        "STREET": "CERİT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "İMRANLI",
        "STREET": "DARISEKİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "İMRANLI",
        "STREET": "DAĞYURDU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "İMRANLI",
        "STREET": "DELİCE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "İMRANLI",
        "STREET": "DEMİRTAŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "İMRANLI",
        "STREET": "DEREKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "İMRANLI",
        "STREET": "DOĞANÇAL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "İMRANLI",
        "STREET": "DURCAN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "İMRANLI",
        "STREET": "EKİNCİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "İMRANLI",
        "STREET": "ESKİDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "İMRANLI",
        "STREET": "ESKİKAPIMAHMUT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "İMRANLI",
        "STREET": "ESKİKEŞLİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "İMRANLI",
        "STREET": "GELENLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "İMRANLI",
        "STREET": "GELİNTARLA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "İMRANLI",
        "STREET": "GÖKDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "İMRANLI",
        "STREET": "GÖKÇEBEL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "İMRANLI",
        "STREET": "GÖRÜNMEZKALE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "İMRANLI",
        "STREET": "GÜVEN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "İMRANLI",
        "STREET": "HALİLLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "İMRANLI",
        "STREET": "KAPIKAYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "İMRANLI",
        "STREET": "KAPUMAHMUT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "İMRANLI",
        "STREET": "KARABOĞAZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "İMRANLI",
        "STREET": "KARACAHİSAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "İMRANLI",
        "STREET": "KARACAÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "İMRANLI",
        "STREET": "KARAHÜSEYİN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "İMRANLI",
        "STREET": "KARAPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "İMRANLI",
        "STREET": "KARATAŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "İMRANLI",
        "STREET": "KARATEKİN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "İMRANLI",
        "STREET": "KARAÇAYIR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "İMRANLI",
        "STREET": "KARLIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "İMRANLI",
        "STREET": "KARŞIYAKA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "İMRANLI",
        "STREET": "KASAPLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "İMRANLI",
        "STREET": "KAVALCIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "İMRANLI",
        "STREET": "KEMRELİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "İMRANLI",
        "STREET": "KERİMOĞLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "İMRANLI",
        "STREET": "KEVENLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "İMRANLI",
        "STREET": "KILIÇKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "İMRANLI",
        "STREET": "KILIÇLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "İMRANLI",
        "STREET": "KIZILIRMAK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "İMRANLI",
        "STREET": "KIZILMEZRA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "İMRANLI",
        "STREET": "KIZILTEPE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "İMRANLI",
        "STREET": "KORUKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "İMRANLI",
        "STREET": "KOYUNKAYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "İMRANLI",
        "STREET": "KOÇGEDİĞİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "İMRANLI",
        "STREET": "KUZKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "İMRANLI",
        "STREET": "KÖRABBAS KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "İMRANLI",
        "STREET": "MADEN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "İMRANLI",
        "STREET": "ORTAKDARACIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "İMRANLI",
        "STREET": "ORTAKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "İMRANLI",
        "STREET": "PİREDEDE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "İMRANLI",
        "STREET": "REFİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "İMRANLI",
        "STREET": "SANDAL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "İMRANLI",
        "STREET": "SÖĞÜTLÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "İMRANLI",
        "STREET": "SİNEK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "İMRANLI",
        "STREET": "TAŞDELEN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "İMRANLI",
        "STREET": "TAŞLICA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "İMRANLI",
        "STREET": "TOKLUCAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "İMRANLI",
        "STREET": "TOPTAŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "İMRANLI",
        "STREET": "TUZÖZÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "İMRANLI",
        "STREET": "TÜRKKEŞLİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "İMRANLI",
        "STREET": "TÜRKYENİCE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "İMRANLI",
        "STREET": "UYANIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "İMRANLI",
        "STREET": "UZUNTEMUR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "İMRANLI",
        "STREET": "VALİTUNCEL MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "İMRANLI",
        "STREET": "YAKAYERİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "İMRANLI",
        "STREET": "YAYLACIK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "İMRANLI",
        "STREET": "YAZIKAVAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "İMRANLI",
        "STREET": "YAZILI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "İMRANLI",
        "STREET": "YENİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "İMRANLI",
        "STREET": "YENİDOĞAN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "İMRANLI",
        "STREET": "YENİKENT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "İMRANLI",
        "STREET": "YENİKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "İMRANLI",
        "STREET": "YONCABAYIRI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "İMRANLI",
        "STREET": "YOZYATAĞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "İMRANLI",
        "STREET": "YUKARIBOĞAZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "İMRANLI",
        "STREET": "YUKARICULHA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "İMRANLI",
        "STREET": "YÜNÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "İMRANLI",
        "STREET": "YÜREKTAŞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "İMRANLI",
        "STREET": "ÇALIYURT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "İMRANLI",
        "STREET": "ÇARŞIBAŞI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "İMRANLI",
        "STREET": "ÇUKURYURT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "ŞARKIŞLA",
        "STREET": "ABDALLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "ŞARKIŞLA",
        "STREET": "AHMETLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "ŞARKIŞLA",
        "STREET": "AKÇAKIŞLA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "ŞARKIŞLA",
        "STREET": "AKÇASU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "ŞARKIŞLA",
        "STREET": "ALAMAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "ŞARKIŞLA",
        "STREET": "ALAÇAYIR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "ŞARKIŞLA",
        "STREET": "ALIKÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "ŞARKIŞLA",
        "STREET": "ARIKLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "ŞARKIŞLA",
        "STREET": "BAHÇEALAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "ŞARKIŞLA",
        "STREET": "BALTALAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "ŞARKIŞLA",
        "STREET": "BAĞLARARASI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "ŞARKIŞLA",
        "STREET": "BAŞAĞAÇ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "ŞARKIŞLA",
        "STREET": "BAŞÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "ŞARKIŞLA",
        "STREET": "BENLİHASAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "ŞARKIŞLA",
        "STREET": "BEYYURDU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "ŞARKIŞLA",
        "STREET": "BOZKURT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "ŞARKIŞLA",
        "STREET": "BURNUKARA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "ŞARKIŞLA",
        "STREET": "BÜYÜKTOPAÇ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "ŞARKIŞLA",
        "STREET": "BÜYÜKYÜREĞİL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "ŞARKIŞLA",
        "STREET": "CANABDAL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "ŞARKIŞLA",
        "STREET": "CEMEL-BAHÇELİEVLER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "ŞARKIŞLA",
        "STREET": "CEMEL-KARŞIYAKA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "ŞARKIŞLA",
        "STREET": "CEMEL-YENİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "ŞARKIŞLA",
        "STREET": "DAMLACA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "ŞARKIŞLA",
        "STREET": "DEMİRBOĞA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "ŞARKIŞLA",
        "STREET": "DEMİRKÖPRÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "ŞARKIŞLA",
        "STREET": "DÖKMETAŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "ŞARKIŞLA",
        "STREET": "DÖLLÜK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "ŞARKIŞLA",
        "STREET": "DİKİLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "ŞARKIŞLA",
        "STREET": "ELMALI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "ŞARKIŞLA",
        "STREET": "EMLAKKARACAÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "ŞARKIŞLA",
        "STREET": "ESENTEPE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "ŞARKIŞLA",
        "STREET": "FAKILI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "ŞARKIŞLA",
        "STREET": "FARAŞDERESİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "ŞARKIŞLA",
        "STREET": "GAZİKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "ŞARKIŞLA",
        "STREET": "GÜCÜK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "ŞARKIŞLA",
        "STREET": "GÜLTEKİN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "ŞARKIŞLA",
        "STREET": "GÜLÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "ŞARKIŞLA",
        "STREET": "GÜMÜŞTEPE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "ŞARKIŞLA",
        "STREET": "GÜRÇAYIR-DOĞU MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "ŞARKIŞLA",
        "STREET": "GÜRÇAYIR-YENİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "ŞARKIŞLA",
        "STREET": "GÜRÇAYIR-ÇİÇEKLİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "ŞARKIŞLA",
        "STREET": "HARDAL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "ŞARKIŞLA",
        "STREET": "HARUNKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "ŞARKIŞLA",
        "STREET": "HOCABEY KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "ŞARKIŞLA",
        "STREET": "HÜYÜKKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "ŞARKIŞLA",
        "STREET": "KAHVEPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "ŞARKIŞLA",
        "STREET": "KALE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "ŞARKIŞLA",
        "STREET": "KALECİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "ŞARKIŞLA",
        "STREET": "KALEKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "ŞARKIŞLA",
        "STREET": "KANDEMİR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "ŞARKIŞLA",
        "STREET": "KAPAKLIPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "ŞARKIŞLA",
        "STREET": "KARACAÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "ŞARKIŞLA",
        "STREET": "KARAKUZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "ŞARKIŞLA",
        "STREET": "KAVAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "ŞARKIŞLA",
        "STREET": "KAYALIYOKUŞ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "ŞARKIŞLA",
        "STREET": "KAYAPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "ŞARKIŞLA",
        "STREET": "KAYMAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "ŞARKIŞLA",
        "STREET": "KAZANCIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "ŞARKIŞLA",
        "STREET": "KEVENLİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "ŞARKIŞLA",
        "STREET": "KILIÇÇI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "ŞARKIŞLA",
        "STREET": "KIZILCAKIŞLA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "ŞARKIŞLA",
        "STREET": "KONAKYAZI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "ŞARKIŞLA",
        "STREET": "KONALGA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "ŞARKIŞLA",
        "STREET": "KÖMÜRKAYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "ŞARKIŞLA",
        "STREET": "KÜMBET KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "ŞARKIŞLA",
        "STREET": "KÜÇÜKTOPAÇ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "ŞARKIŞLA",
        "STREET": "KÜÇÜKYÜREĞİL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "ŞARKIŞLA",
        "STREET": "MAKSUTLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "ŞARKIŞLA",
        "STREET": "MENGENSOFULAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "ŞARKIŞLA",
        "STREET": "MESCİTLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "ŞARKIŞLA",
        "STREET": "MEZRAA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "ŞARKIŞLA",
        "STREET": "OLUKTAŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "ŞARKIŞLA",
        "STREET": "ORTAKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "ŞARKIŞLA",
        "STREET": "ORTATOPAÇ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "ŞARKIŞLA",
        "STREET": "OSMANPINARI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "ŞARKIŞLA",
        "STREET": "OTLUK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "ŞARKIŞLA",
        "STREET": "PINARÖNÜ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "ŞARKIŞLA",
        "STREET": "POLATPAŞA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "ŞARKIŞLA",
        "STREET": "SAMANKAYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "ŞARKIŞLA",
        "STREET": "SARAÇ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "ŞARKIŞLA",
        "STREET": "SARIKAVAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "ŞARKIŞLA",
        "STREET": "SARIKAYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "ŞARKIŞLA",
        "STREET": "SARITEKKE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "ŞARKIŞLA",
        "STREET": "SARIÇİÇEK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "ŞARKIŞLA",
        "STREET": "SAĞIR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "ŞARKIŞLA",
        "STREET": "SULTAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "ŞARKIŞLA",
        "STREET": "SİVRİALAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "ŞARKIŞLA",
        "STREET": "TAVLADERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "ŞARKIŞLA",
        "STREET": "TEMECİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "ŞARKIŞLA",
        "STREET": "UÇUK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "ŞARKIŞLA",
        "STREET": "YAHYALI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "ŞARKIŞLA",
        "STREET": "YALANI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "ŞARKIŞLA",
        "STREET": "YAPRACIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "ŞARKIŞLA",
        "STREET": "YENİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "ŞARKIŞLA",
        "STREET": "YENİYAPAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "ŞARKIŞLA",
        "STREET": "YILDIRIM MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "ŞARKIŞLA",
        "STREET": "YUKARI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "ŞARKIŞLA",
        "STREET": "YUNUSÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "ŞARKIŞLA",
        "STREET": "YÜKSELEN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "ŞARKIŞLA",
        "STREET": "ÇAMLICA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "ŞARKIŞLA",
        "STREET": "ÇANAKÇI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "ŞARKIŞLA",
        "STREET": "ÇATALYOL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "ŞARKIŞLA",
        "STREET": "ÇEKEM KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "ŞARKIŞLA",
        "STREET": "ÇİÇEKLİYURT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "ŞARKIŞLA",
        "STREET": "ÖRTÜLÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "ŞARKIŞLA",
        "STREET": "ÜYÜK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "ŞARKIŞLA",
        "STREET": "İLYASHACI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "ŞARKIŞLA",
        "STREET": "İSTİKLAL MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "ŞARKIŞLA",
        "STREET": "İĞDECİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "ŞARKIŞLA",
        "STREET": "İĞDELİÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİVAS",
        "DISTRICT": "ŞARKIŞLA",
        "STREET": "İĞECİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "BAYKAN",
        "STREET": "ADAKALE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "BAYKAN",
        "STREET": "ARDIÇDALI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "BAYKAN",
        "STREET": "ATABAĞI-MERKEZ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "BAYKAN",
        "STREET": "CEFAN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "BAYKAN",
        "STREET": "DEDEBAKIRI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "BAYKAN",
        "STREET": "DEMİRIŞIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "BAYKAN",
        "STREET": "DERİNCE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "BAYKAN",
        "STREET": "DOKUZÇAVUŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "BAYKAN",
        "STREET": "DİLEKTEPE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "BAYKAN",
        "STREET": "ENGİN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "BAYKAN",
        "STREET": "GÜMÜŞKAŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "BAYKAN",
        "STREET": "GÜNBULDU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "BAYKAN",
        "STREET": "GÜNDOĞDU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "BAYKAN",
        "STREET": "HAVEL MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "BAYKAN",
        "STREET": "KARAKAYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "BAYKAN",
        "STREET": "KARŞIYAKA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "BAYKAN",
        "STREET": "KASIMLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "BAYKAN",
        "STREET": "MEŞELİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "BAYKAN",
        "STREET": "NARLIYURT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "BAYKAN",
        "STREET": "OBALI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "BAYKAN",
        "STREET": "ORMANPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "BAYKAN",
        "STREET": "SARIDANA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "BAYKAN",
        "STREET": "SARISALKIM KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "BAYKAN",
        "STREET": "TÜTENOCAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "BAYKAN",
        "STREET": "ULAŞTI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "BAYKAN",
        "STREET": "VEYSELKARANİ-ATATÜRK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "BAYKAN",
        "STREET": "VEYSELKARANİ-ÇAY MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "BAYKAN",
        "STREET": "VEYSELKARANİ-ŞEYHOSMAN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "BAYKAN",
        "STREET": "YARIMCA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "BAYKAN",
        "STREET": "YENİDOĞAN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "BAYKAN",
        "STREET": "YEŞİLÇEVRE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "BAYKAN",
        "STREET": "ÇAMTAŞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "BAYKAN",
        "STREET": "ÇAYKAYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "BAYKAN",
        "STREET": "ÇELİKLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "BAYKAN",
        "STREET": "ÇEVRİMTEPE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "BAYKAN",
        "STREET": "ÇUKURCA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "BAYKAN",
        "STREET": "ÜNLÜCE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "BAYKAN",
        "STREET": "İKİZLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "BAYKAN",
        "STREET": "İNÖNÜ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "ERUH",
        "STREET": "AKDİKEN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "ERUH",
        "STREET": "AKMEŞE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "ERUH",
        "STREET": "BALLIKAVAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "ERUH",
        "STREET": "BAYIRYÜZÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "ERUH",
        "STREET": "BAYRAMLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "ERUH",
        "STREET": "BAĞGÖZE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "ERUH",
        "STREET": "BOZATLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "ERUH",
        "STREET": "BOZKUŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "ERUH",
        "STREET": "BUDAMIŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "ERUH",
        "STREET": "BÖLÜKLÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "ERUH",
        "STREET": "BİLGİLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "ERUH",
        "STREET": "BİNGÖL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "ERUH",
        "STREET": "CİNTEPE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "ERUH",
        "STREET": "DADAKLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "ERUH",
        "STREET": "DALKORUR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "ERUH",
        "STREET": "DAĞDÖŞÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "ERUH",
        "STREET": "DEMİREMEK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "ERUH",
        "STREET": "DÖNERDÖVER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "ERUH",
        "STREET": "DİH MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "ERUH",
        "STREET": "DİKBOĞAZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "ERUH",
        "STREET": "EKİNYOLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "ERUH",
        "STREET": "ERENKAYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "ERUH",
        "STREET": "FATİH MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "ERUH",
        "STREET": "GEDİKAŞAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "ERUH",
        "STREET": "GELENKARDEŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "ERUH",
        "STREET": "GÖLGELİKONAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "ERUH",
        "STREET": "GÖNÜLALDI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "ERUH",
        "STREET": "GÖRENDORUK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "ERUH",
        "STREET": "GÜLBURNU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "ERUH",
        "STREET": "KARADAYILAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "ERUH",
        "STREET": "KAVAKGÖLÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "ERUH",
        "STREET": "KAŞIKYAYLA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "ERUH",
        "STREET": "KEKLİKTEPE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "ERUH",
        "STREET": "KILIÇKAYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "ERUH",
        "STREET": "KUŞDALI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "ERUH",
        "STREET": "OKÇULAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "ERUH",
        "STREET": "ORTAKLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "ERUH",
        "STREET": "OYMAKILIÇ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "ERUH",
        "STREET": "PAYAMLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "ERUH",
        "STREET": "SALKIMBAĞLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "ERUH",
        "STREET": "SARIGÜL MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "ERUH",
        "STREET": "SAVAŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "ERUH",
        "STREET": "TÜNEKPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "ERUH",
        "STREET": "UFACA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "ERUH",
        "STREET": "YANIKSES KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "ERUH",
        "STREET": "YANILMAZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "ERUH",
        "STREET": "YEDİYAPRAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "ERUH",
        "STREET": "YELKESEN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "ERUH",
        "STREET": "YERLİÇOBAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "ERUH",
        "STREET": "YEŞİLÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "ERUH",
        "STREET": "YOKUŞLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "ERUH",
        "STREET": "ÇELTİKSUYU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "ERUH",
        "STREET": "ÇETİNKOL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "ERUH",
        "STREET": "ÇIRPILI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "ERUH",
        "STREET": "ÇİMENCİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "ERUH",
        "STREET": "ÇİZMELİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "ERUH",
        "STREET": "ÖZLÜPELİT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "ERUH",
        "STREET": "ÜZÜMLÜK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "KURTALAN",
        "STREET": "AKDEM KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "KURTALAN",
        "STREET": "AKSÖĞÜT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "KURTALAN",
        "STREET": "AKÇAGEDİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "KURTALAN",
        "STREET": "AKÇALI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "KURTALAN",
        "STREET": "ATALAY KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "KURTALAN",
        "STREET": "AVCILAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "KURTALAN",
        "STREET": "AYDEMİR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "KURTALAN",
        "STREET": "AZIKLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "KURTALAN",
        "STREET": "AĞAÇLIPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "KURTALAN",
        "STREET": "BAHÇELİEVLER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "KURTALAN",
        "STREET": "BALLIKAYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "KURTALAN",
        "STREET": "BAĞLICA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "KURTALAN",
        "STREET": "BEYKENT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "KURTALAN",
        "STREET": "BEŞLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "KURTALAN",
        "STREET": "BOZHÜYÜK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "KURTALAN",
        "STREET": "BÖLÜKTEPE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "KURTALAN",
        "STREET": "CUMHURİYET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "KURTALAN",
        "STREET": "DEMİRKUYU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "KURTALAN",
        "STREET": "DERİNCE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "KURTALAN",
        "STREET": "DİCLE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "KURTALAN",
        "STREET": "ERDURAĞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "KURTALAN",
        "STREET": "FIRAT MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "KURTALAN",
        "STREET": "GARZAN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "KURTALAN",
        "STREET": "GÖKDOĞAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "KURTALAN",
        "STREET": "GÖZPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "KURTALAN",
        "STREET": "GÜNEY MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "KURTALAN",
        "STREET": "GÜRGÖZE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "KURTALAN",
        "STREET": "GÜZELDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "KURTALAN",
        "STREET": "KAPIKAYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "KURTALAN",
        "STREET": "KARABAĞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "KURTALAN",
        "STREET": "KAYABAĞLAR-BAĞLAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "KURTALAN",
        "STREET": "KAYABAĞLAR-KAYALI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "KURTALAN",
        "STREET": "KAYABAĞLAR-ÇEŞME MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "KURTALAN",
        "STREET": "KAYALISU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "KURTALAN",
        "STREET": "KILIÇLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "KURTALAN",
        "STREET": "KONAKPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "KURTALAN",
        "STREET": "KÜLTÜR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "KURTALAN",
        "STREET": "SAİPBEYLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "KURTALAN",
        "STREET": "SEYRANTEPE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "KURTALAN",
        "STREET": "SÜMER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "KURTALAN",
        "STREET": "TATLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "KURTALAN",
        "STREET": "TAŞOLUK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "KURTALAN",
        "STREET": "TEKEL MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "KURTALAN",
        "STREET": "TEYLAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "KURTALAN",
        "STREET": "TOSUNBAĞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "KURTALAN",
        "STREET": "TOYTEPE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "KURTALAN",
        "STREET": "TULUMTAŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "KURTALAN",
        "STREET": "TÜTÜN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "KURTALAN",
        "STREET": "ULUKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "KURTALAN",
        "STREET": "YAKITTEPE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "KURTALAN",
        "STREET": "YANARSU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "KURTALAN",
        "STREET": "YAYIKDERE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "KURTALAN",
        "STREET": "YAYIKLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "KURTALAN",
        "STREET": "YELLİCE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "KURTALAN",
        "STREET": "YENİ  MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "KURTALAN",
        "STREET": "YENİDOĞAN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "KURTALAN",
        "STREET": "YENİKÖPRÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "KURTALAN",
        "STREET": "YEŞİLKONAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "KURTALAN",
        "STREET": "YOLDURAĞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "KURTALAN",
        "STREET": "YUVALI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "KURTALAN",
        "STREET": "YÜREKVEREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "KURTALAN",
        "STREET": "ÇAKILLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "KURTALAN",
        "STREET": "ÇALIDÜZÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "KURTALAN",
        "STREET": "ÇAYIRLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "KURTALAN",
        "STREET": "ÇELTİKBAŞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "KURTALAN",
        "STREET": "ÜÇPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "KURTALAN",
        "STREET": "İNCİRLİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "KURTALAN",
        "STREET": "İĞDELİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "KURTALAN",
        "STREET": "ŞENKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "PERVARİ",
        "STREET": "AYDIN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "PERVARİ",
        "STREET": "AYVALIBAĞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "PERVARİ",
        "STREET": "AŞAĞIBALCILAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "PERVARİ",
        "STREET": "BELENOLUK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "PERVARİ",
        "STREET": "BENTKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "PERVARİ",
        "STREET": "BEĞENDİK-GAZİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "PERVARİ",
        "STREET": "BEĞENDİK-KALE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "PERVARİ",
        "STREET": "DOLUSALKIM KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "PERVARİ",
        "STREET": "DOĞANCA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "PERVARİ",
        "STREET": "DOĞANKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "PERVARİ",
        "STREET": "DÜĞÜNCÜLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "PERVARİ",
        "STREET": "EKİNDÜZÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "PERVARİ",
        "STREET": "ERKENT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "PERVARİ",
        "STREET": "GÖKBUDAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "PERVARİ",
        "STREET": "GÖKÇEKORU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "PERVARİ",
        "STREET": "GÖLGELİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "PERVARİ",
        "STREET": "GÜLEÇLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "PERVARİ",
        "STREET": "GÜMÜŞÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "PERVARİ",
        "STREET": "KARASUNGUR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "PERVARİ",
        "STREET": "KARŞIKAYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "PERVARİ",
        "STREET": "KESKİN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "PERVARİ",
        "STREET": "KIŞLACIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "PERVARİ",
        "STREET": "KOCAÇAVUŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "PERVARİ",
        "STREET": "KÖPRÜÇAY KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "PERVARİ",
        "STREET": "NARSUYU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "PERVARİ",
        "STREET": "ORMANDALI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "PERVARİ",
        "STREET": "PALAMUTLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "PERVARİ",
        "STREET": "SARIDAM KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "PERVARİ",
        "STREET": "SARIYAPRAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "PERVARİ",
        "STREET": "SÖĞÜTÖNÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "PERVARİ",
        "STREET": "TAŞDİBEK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "PERVARİ",
        "STREET": "TOSUNTARLA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "PERVARİ",
        "STREET": "TUZCULAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "PERVARİ",
        "STREET": "YAPRAKTEPE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "PERVARİ",
        "STREET": "YENİAYDIN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "PERVARİ",
        "STREET": "YUKARIBALCILAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "PERVARİ",
        "STREET": "ÇATKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "PERVARİ",
        "STREET": "ÇAVUŞLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "PERVARİ",
        "STREET": "ÇOBANÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "PERVARİ",
        "STREET": "ÇUKURKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "PERVARİ",
        "STREET": "ÜÇOYUK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "PERVARİ",
        "STREET": "ŞAKİRAN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "MERKEZ",
        "STREET": "AFETEVLERİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "MERKEZ",
        "STREET": "AKDOĞMUŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "MERKEZ",
        "STREET": "AKTAŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "MERKEZ",
        "STREET": "AKYAMAÇ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "MERKEZ",
        "STREET": "ALAN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "MERKEZ",
        "STREET": "ALGÜL MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "MERKEZ",
        "STREET": "BAHÇELİEVLER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "MERKEZ",
        "STREET": "BARIŞ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "MERKEZ",
        "STREET": "BATI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "MERKEZ",
        "STREET": "BAYRAKTEPE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "MERKEZ",
        "STREET": "BAĞLICA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "MERKEZ",
        "STREET": "BEŞYOL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "MERKEZ",
        "STREET": "BOSTANCIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "MERKEZ",
        "STREET": "CONKBAYIR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "MERKEZ",
        "STREET": "DEMİRKAYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "MERKEZ",
        "STREET": "DOLUHARMAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "MERKEZ",
        "STREET": "DOĞAN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "MERKEZ",
        "STREET": "DUMLUPINAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "MERKEZ",
        "STREET": "EKMEKÇİLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "MERKEZ",
        "STREET": "EĞLENCE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "MERKEZ",
        "STREET": "GÖKÇEBAĞ-BAĞLAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "MERKEZ",
        "STREET": "GÖKÇEBAĞ-KOÇPINAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "MERKEZ",
        "STREET": "GÖKÇEBAĞ-YENİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "MERKEZ",
        "STREET": "HALENZE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "MERKEZ",
        "STREET": "KALENDER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "MERKEZ",
        "STREET": "KARAKOL MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "MERKEZ",
        "STREET": "KAVAKÖZÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "MERKEZ",
        "STREET": "KAYABOĞAZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "MERKEZ",
        "STREET": "KEMERLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "MERKEZ",
        "STREET": "KIŞLACIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "MERKEZ",
        "STREET": "KONACIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "MERKEZ",
        "STREET": "KOOPERATİF MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "MERKEZ",
        "STREET": "KOÇLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "MERKEZ",
        "STREET": "KÖPRÜBAŞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "MERKEZ",
        "STREET": "MEYDANDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "MERKEZ",
        "STREET": "MEŞELİDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "MERKEZ",
        "STREET": "ORMANARDI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "MERKEZ",
        "STREET": "PINARCA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "MERKEZ",
        "STREET": "PINAROVA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "MERKEZ",
        "STREET": "SAKARYA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "MERKEZ",
        "STREET": "SARITEPE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "MERKEZ",
        "STREET": "SAĞIRSU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "MERKEZ",
        "STREET": "SAĞLARCA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "MERKEZ",
        "STREET": "TINAZTEPE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "MERKEZ",
        "STREET": "TUZKUYUSU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "MERKEZ",
        "STREET": "ULUS MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "MERKEZ",
        "STREET": "VEYSEL KARANİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "MERKEZ",
        "STREET": "YAZLICA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "MERKEZ",
        "STREET": "YAĞMURTEPE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "MERKEZ",
        "STREET": "YENİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "MERKEZ",
        "STREET": "YERLİBAHÇE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "MERKEZ",
        "STREET": "YOKUŞBAĞLARI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "MERKEZ",
        "STREET": "ZORKAYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇAL MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇAĞBAŞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "MERKEZ",
        "STREET": "ÜLKÜ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "MERKEZ",
        "STREET": "İNKAPI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "MERKEZ",
        "STREET": "İNÖNÜ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "TİLLO",
        "STREET": "AKYAYLA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "TİLLO",
        "STREET": "FAKİRULLAH MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "TİLLO",
        "STREET": "FERSAF KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "TİLLO",
        "STREET": "HATRANT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "TİLLO",
        "STREET": "MÜCAHİT MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "TİLLO",
        "STREET": "SAYDANLAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "TİLLO",
        "STREET": "SİNEP KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "TİLLO",
        "STREET": "TAŞBALTA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "TİLLO",
        "STREET": "İKİZBAĞLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "ŞİRVAN",
        "STREET": "ADIGÜZEL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "ŞİRVAN",
        "STREET": "AKGEÇİT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "ŞİRVAN",
        "STREET": "AKYOKUŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "ŞİRVAN",
        "STREET": "AKÇAYAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "ŞİRVAN",
        "STREET": "BAYINDIR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "ŞİRVAN",
        "STREET": "BAĞCILAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "ŞİRVAN",
        "STREET": "BELENÇAY KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "ŞİRVAN",
        "STREET": "BOYLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "ŞİRVAN",
        "STREET": "CEVİZDALI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "ŞİRVAN",
        "STREET": "CEVİZLİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "ŞİRVAN",
        "STREET": "DALTEPE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "ŞİRVAN",
        "STREET": "DAMLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "ŞİRVAN",
        "STREET": "DEMİRKAPI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "ŞİRVAN",
        "STREET": "DERİNÇAY KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "ŞİRVAN",
        "STREET": "DOĞRUCA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "ŞİRVAN",
        "STREET": "DURANKAYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "ŞİRVAN",
        "STREET": "DİŞLİNAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "ŞİRVAN",
        "STREET": "ELMADALI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "ŞİRVAN",
        "STREET": "FATİH MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "ŞİRVAN",
        "STREET": "GÖZLÜCE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "ŞİRVAN",
        "STREET": "GÜMÜŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "ŞİRVAN",
        "STREET": "HÜRMÜZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "ŞİRVAN",
        "STREET": "KALKANCIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "ŞİRVAN",
        "STREET": "KAPILI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "ŞİRVAN",
        "STREET": "KARACA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "ŞİRVAN",
        "STREET": "KASIMLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "ŞİRVAN",
        "STREET": "KAYAHİSAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "ŞİRVAN",
        "STREET": "KESMETAŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "ŞİRVAN",
        "STREET": "KIRTEPE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "ŞİRVAN",
        "STREET": "KÖMÜRLÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "ŞİRVAN",
        "STREET": "KÜFRE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "ŞİRVAN",
        "STREET": "KİRAZLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "ŞİRVAN",
        "STREET": "MADENKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "ŞİRVAN",
        "STREET": "MEŞECİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "ŞİRVAN",
        "STREET": "NALLIKAYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "ŞİRVAN",
        "STREET": "ORMANBAĞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "ŞİRVAN",
        "STREET": "ORMANLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "ŞİRVAN",
        "STREET": "OTLUK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "ŞİRVAN",
        "STREET": "OYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "ŞİRVAN",
        "STREET": "PİRİNÇLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "ŞİRVAN",
        "STREET": "SARIDANA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "ŞİRVAN",
        "STREET": "SIRÇALI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "ŞİRVAN",
        "STREET": "SOĞANLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "ŞİRVAN",
        "STREET": "SOĞUKSU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "ŞİRVAN",
        "STREET": "SULUDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "ŞİRVAN",
        "STREET": "SULUYAZI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "ŞİRVAN",
        "STREET": "SİT MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "ŞİRVAN",
        "STREET": "TATLIPAYAM KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "ŞİRVAN",
        "STREET": "TAŞLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "ŞİRVAN",
        "STREET": "TAŞYAKA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "ŞİRVAN",
        "STREET": "YALKAYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "ŞİRVAN",
        "STREET": "YAMAÇLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "ŞİRVAN",
        "STREET": "YARIMTEPE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "ŞİRVAN",
        "STREET": "YAYLACI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "ŞİRVAN",
        "STREET": "YAYLADAĞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "ŞİRVAN",
        "STREET": "YAĞCILAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "ŞİRVAN",
        "STREET": "YEDİKAPI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "ŞİRVAN",
        "STREET": "YOLBAŞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "ŞİRVAN",
        "STREET": "ÇELTİKYOLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "ŞİRVAN",
        "STREET": "ÇINARLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "ŞİRVAN",
        "STREET": "ÖZPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "ŞİRVAN",
        "STREET": "İKİZLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "SİİRT",
        "DISTRICT": "ŞİRVAN",
        "STREET": "İNCEKAYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "ERGENE",
        "STREET": "AHİMEHMET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "ERGENE",
        "STREET": "BAKIRCA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "ERGENE",
        "STREET": "CUMHURİYET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "ERGENE",
        "STREET": "ESENLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "ERGENE",
        "STREET": "KARAMEHMET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "ERGENE",
        "STREET": "KIRKGÖZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "ERGENE",
        "STREET": "MARMARACIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "ERGENE",
        "STREET": "MİSİNLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "ERGENE",
        "STREET": "PAŞAKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "ERGENE",
        "STREET": "PINARBAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "ERGENE",
        "STREET": "SAĞLIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "ERGENE",
        "STREET": "ULAŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "ERGENE",
        "STREET": "VAKIFLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "ERGENE",
        "STREET": "VELİMEŞE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "ERGENE",
        "STREET": "YEŞİLTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "ERGENE",
        "STREET": "YULAFLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "ERGENE",
        "STREET": "İĞNELER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "HAYRABOLU",
        "STREET": "ATAKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "HAYRABOLU",
        "STREET": "AVLUOBASI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "HAYRABOLU",
        "STREET": "AYDINEVLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "HAYRABOLU",
        "STREET": "AYDINLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "HAYRABOLU",
        "STREET": "BAYRAMŞAH MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "HAYRABOLU",
        "STREET": "BUZAĞCI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "HAYRABOLU",
        "STREET": "BÜYÜKKARAKARLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "HAYRABOLU",
        "STREET": "CAMBAZDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "HAYRABOLU",
        "STREET": "CANHIDIR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "HAYRABOLU",
        "STREET": "DAMBASLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "HAYRABOLU",
        "STREET": "DANİŞMENT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "HAYRABOLU",
        "STREET": "DELİBEDİR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "HAYRABOLU",
        "STREET": "DUĞCALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "HAYRABOLU",
        "STREET": "EMİRYAKUP MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "HAYRABOLU",
        "STREET": "FAHRİOĞLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "HAYRABOLU",
        "STREET": "HACILLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "HAYRABOLU",
        "STREET": "HASKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "HAYRABOLU",
        "STREET": "HEDEYLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "HAYRABOLU",
        "STREET": "HİSAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "HAYRABOLU",
        "STREET": "KABAHÖYÜK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "HAYRABOLU",
        "STREET": "KADRİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "HAYRABOLU",
        "STREET": "KAHYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "HAYRABOLU",
        "STREET": "KANDAMIŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "HAYRABOLU",
        "STREET": "KARABABA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "HAYRABOLU",
        "STREET": "KARABÜRÇEK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "HAYRABOLU",
        "STREET": "KARAKAVAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "HAYRABOLU",
        "STREET": "KARAYAHŞİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "HAYRABOLU",
        "STREET": "KEMALLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "HAYRABOLU",
        "STREET": "KILIÇLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "HAYRABOLU",
        "STREET": "KURTDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "HAYRABOLU",
        "STREET": "KUTLUGÜN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "HAYRABOLU",
        "STREET": "KÜÇÜKKARAKARLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "HAYRABOLU",
        "STREET": "LAHNA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "HAYRABOLU",
        "STREET": "MUZRUPLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "HAYRABOLU",
        "STREET": "PARMAKSIZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "HAYRABOLU",
        "STREET": "SOYLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "HAYRABOLU",
        "STREET": "SUBAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "HAYRABOLU",
        "STREET": "SUSUZMÜSELLİM MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "HAYRABOLU",
        "STREET": "TATARLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "HAYRABOLU",
        "STREET": "TEMREZLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "HAYRABOLU",
        "STREET": "UMURBEY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "HAYRABOLU",
        "STREET": "UMURÇU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "HAYRABOLU",
        "STREET": "YÖRGÜÇ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "HAYRABOLU",
        "STREET": "YÖRÜKLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "HAYRABOLU",
        "STREET": "ÇENE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "HAYRABOLU",
        "STREET": "ÇERKEZMÜSELLİM MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "HAYRABOLU",
        "STREET": "ÇIKRIKÇI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "HAYRABOLU",
        "STREET": "ÖREY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "HAYRABOLU",
        "STREET": "ÖVENLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "HAYRABOLU",
        "STREET": "İLYAS MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "HAYRABOLU",
        "STREET": "İSMAİLLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "HAYRABOLU",
        "STREET": "ŞALGAMLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "KAPAKLI",
        "STREET": "ATATÜRK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "KAPAKLI",
        "STREET": "BAHÇEAĞIL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "KAPAKLI",
        "STREET": "BAHÇELİEVLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "KAPAKLI",
        "STREET": "CUMHURİYET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "KAPAKLI",
        "STREET": "FATİH MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "KAPAKLI",
        "STREET": "KARAAĞAÇ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "KAPAKLI",
        "STREET": "KARLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "KAPAKLI",
        "STREET": "KAZIM KARABEKİR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "KAPAKLI",
        "STREET": "MİMAR SİNAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "KAPAKLI",
        "STREET": "PINARCA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "KAPAKLI",
        "STREET": "UZUNHACI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "KAPAKLI",
        "STREET": "YANIKAĞIL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "KAPAKLI",
        "STREET": "İNÖNÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "KAPAKLI",
        "STREET": "İSMET PAŞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "MALKARA",
        "STREET": "AHMETPAŞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "MALKARA",
        "STREET": "AHİEVREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "MALKARA",
        "STREET": "AKSAKAL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "MALKARA",
        "STREET": "ALAYBEY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "MALKARA",
        "STREET": "ALLIIŞIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "MALKARA",
        "STREET": "BALABANCIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "MALKARA",
        "STREET": "BALLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "MALKARA",
        "STREET": "BALLISÜLE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "MALKARA",
        "STREET": "BATKIN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "MALKARA",
        "STREET": "BAYRAMTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "MALKARA",
        "STREET": "BAĞPINARI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "MALKARA",
        "STREET": "CAMİ ATİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "MALKARA",
        "STREET": "DANİŞMENT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "MALKARA",
        "STREET": "DAVUTELİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "MALKARA",
        "STREET": "DELİLLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "MALKARA",
        "STREET": "DEMİRCİLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "MALKARA",
        "STREET": "DEREKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "MALKARA",
        "STREET": "DEVECİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "MALKARA",
        "STREET": "DEVELİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "MALKARA",
        "STREET": "DOLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "MALKARA",
        "STREET": "DOĞANKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "MALKARA",
        "STREET": "ELMALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "MALKARA",
        "STREET": "EMİRALİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "MALKARA",
        "STREET": "ESENDİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "MALKARA",
        "STREET": "EVRENBEY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "MALKARA",
        "STREET": "GAZİBEY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "MALKARA",
        "STREET": "GÖNENCE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "MALKARA",
        "STREET": "GÖZSÜZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "MALKARA",
        "STREET": "GÜNEŞLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "MALKARA",
        "STREET": "HACIEVHAT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "MALKARA",
        "STREET": "HACISUNGUR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "MALKARA",
        "STREET": "HALIÇ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "MALKARA",
        "STREET": "HASKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "MALKARA",
        "STREET": "HEMİT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "MALKARA",
        "STREET": "HEREKE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "MALKARA",
        "STREET": "IZGAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "MALKARA",
        "STREET": "KADIKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "MALKARA",
        "STREET": "KALAYCI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "MALKARA",
        "STREET": "KARACAGÜR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "MALKARA",
        "STREET": "KARACAHALİL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "MALKARA",
        "STREET": "KARAMURAT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "MALKARA",
        "STREET": "KARAİĞDEMİR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "MALKARA",
        "STREET": "KAVAKÇEŞME MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "MALKARA",
        "STREET": "KERMEYAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "MALKARA",
        "STREET": "KIRIKALİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "MALKARA",
        "STREET": "KOZYÖRÜK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "MALKARA",
        "STREET": "KUYUCU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "MALKARA",
        "STREET": "KÜRTÜLLÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "MALKARA",
        "STREET": "KÜÇÜKHIDIR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "MALKARA",
        "STREET": "KİREMİTLİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "MALKARA",
        "STREET": "MESTANLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "MALKARA",
        "STREET": "MÜSTECEP MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "MALKARA",
        "STREET": "PİRİNÇÇEŞME MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "MALKARA",
        "STREET": "SARIPOLAT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "MALKARA",
        "STREET": "SARIYAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "MALKARA",
        "STREET": "SARNIÇ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "MALKARA",
        "STREET": "SAĞLAMTAŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "MALKARA",
        "STREET": "SIRTBEY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "MALKARA",
        "STREET": "TEKKEKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "MALKARA",
        "STREET": "TETEKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "MALKARA",
        "STREET": "VAKIFİĞDEMİR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "MALKARA",
        "STREET": "YAYLAGÖNE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "MALKARA",
        "STREET": "YAYLAKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "MALKARA",
        "STREET": "YENİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "MALKARA",
        "STREET": "YENİCE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "MALKARA",
        "STREET": "YENİDİBEK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "MALKARA",
        "STREET": "YILANLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "MALKARA",
        "STREET": "YÖRÜCEK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "MALKARA",
        "STREET": "YÖRÜK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "MALKARA",
        "STREET": "ÇAVUŞKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "MALKARA",
        "STREET": "ÇINARALTI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "MALKARA",
        "STREET": "ÇINARLIDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "MALKARA",
        "STREET": "ÇİMENDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "MALKARA",
        "STREET": "İBRİBEY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "MALKARA",
        "STREET": "İBRİCE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "MALKARA",
        "STREET": "İSHAKÇA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "MALKARA",
        "STREET": "ŞAHİN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "MARMARAEREĞLİSİ",
        "STREET": "BAHÇELİEVLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "MARMARAEREĞLİSİ",
        "STREET": "CEDİT ALİ PAŞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "MARMARAEREĞLİSİ",
        "STREET": "DEREAĞZI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "MARMARAEREĞLİSİ",
        "STREET": "KAMARADERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "MARMARAEREĞLİSİ",
        "STREET": "MUSTAFA KEMAL PAŞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "MARMARAEREĞLİSİ",
        "STREET": "SULTANKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "MARMARAEREĞLİSİ",
        "STREET": "TÜRKMENLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "MARMARAEREĞLİSİ",
        "STREET": "YAKUPLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "MARMARAEREĞLİSİ",
        "STREET": "YENİÇİFTLİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "MARMARAEREĞLİSİ",
        "STREET": "ÇEŞMELİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "MURATLI",
        "STREET": "ARZULU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "MURATLI",
        "STREET": "AYDINKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "MURATLI",
        "STREET": "AŞAĞISEVİNDİKLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "MURATLI",
        "STREET": "BALABANLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "MURATLI",
        "STREET": "BALLIHOCA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "MURATLI",
        "STREET": "FATİH MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "MURATLI",
        "STREET": "HANOĞLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "MURATLI",
        "STREET": "KAZIM DİRİK TURAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "MURATLI",
        "STREET": "KEPENEKLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "MURATLI",
        "STREET": "KIRKKEPENEKLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "MURATLI",
        "STREET": "MURADİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "MURATLI",
        "STREET": "MÜSELLİM MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "MURATLI",
        "STREET": "YAVAŞÇA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "MURATLI",
        "STREET": "YEŞİLSIRT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "MURATLI",
        "STREET": "YUKARISEVİNDİKLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "MURATLI",
        "STREET": "YUKARISIRT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "MURATLI",
        "STREET": "YURTBEKLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "MURATLI",
        "STREET": "ÇEVRİMKAYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "MURATLI",
        "STREET": "İNANLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "MURATLI",
        "STREET": "İSTİKLAL KURTPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "SARAY",
        "STREET": "ATATÜRK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "SARAY",
        "STREET": "AYASPAŞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "SARAY",
        "STREET": "AYVACIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "SARAY",
        "STREET": "AZİZİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "SARAY",
        "STREET": "BAHÇEDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "SARAY",
        "STREET": "BAHÇEKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "SARAY",
        "STREET": "BEYAZKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "SARAY",
        "STREET": "BOZOBA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "SARAY",
        "STREET": "BÜYÜKYONCALI MERKEZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "SARAY",
        "STREET": "CUMHURİYET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "SARAY",
        "STREET": "DEMİRLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "SARAY",
        "STREET": "EDİRKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "SARAY",
        "STREET": "GÖÇERLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "SARAY",
        "STREET": "GÜNGÖRMEZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "SARAY",
        "STREET": "KADIKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "SARAY",
        "STREET": "KARABÜRÇEK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "SARAY",
        "STREET": "KAVACIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "SARAY",
        "STREET": "KEMALPAŞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "SARAY",
        "STREET": "KURTDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "SARAY",
        "STREET": "KÜÇÜKYONCALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "SARAY",
        "STREET": "OSMANLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "SARAY",
        "STREET": "PAZARCIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "SARAY",
        "STREET": "SEFAALAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "SARAY",
        "STREET": "SOFULAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "SARAY",
        "STREET": "SİNANLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "SARAY",
        "STREET": "YENİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "SARAY",
        "STREET": "YUVALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "SARAY",
        "STREET": "ÇAYLA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "SARAY",
        "STREET": "ÇUKURYURT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "SÜLEYMANPAŞA",
        "STREET": "1 NOLU F TİPİ",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "SÜLEYMANPAŞA",
        "STREET": "1 NOLU T TİPİ",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "SÜLEYMANPAŞA",
        "STREET": "100. YIL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "SÜLEYMANPAŞA",
        "STREET": "2 NOLU F TİPİ",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "SÜLEYMANPAŞA",
        "STREET": "2 NOLU T TİPİ",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "SÜLEYMANPAŞA",
        "STREET": "AHMEDİKLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "SÜLEYMANPAŞA",
        "STREET": "AHMETÇE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "SÜLEYMANPAŞA",
        "STREET": "AKÇAHALİL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "SÜLEYMANPAŞA",
        "STREET": "ALTINOVA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "SÜLEYMANPAŞA",
        "STREET": "ARAPHACI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "SÜLEYMANPAŞA",
        "STREET": "ATATÜRK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "SÜLEYMANPAŞA",
        "STREET": "AVŞAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "SÜLEYMANPAŞA",
        "STREET": "AYDOĞDU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "SÜLEYMANPAŞA",
        "STREET": "AŞAĞIKILIÇLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "SÜLEYMANPAŞA",
        "STREET": "BAHÇELİEVLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "SÜLEYMANPAŞA",
        "STREET": "BANARLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "SÜLEYMANPAŞA",
        "STREET": "BARBAROS MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "SÜLEYMANPAŞA",
        "STREET": "BIYIKALİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "SÜLEYMANPAŞA",
        "STREET": "CUMHURİYET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "SÜLEYMANPAŞA",
        "STREET": "DEDECİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "SÜLEYMANPAŞA",
        "STREET": "DEMİRLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "SÜLEYMANPAŞA",
        "STREET": "DEĞİRMENALTI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "SÜLEYMANPAŞA",
        "STREET": "DOĞRUKARACAMURAT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "SÜLEYMANPAŞA",
        "STREET": "ERTUĞRUL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "SÜLEYMANPAŞA",
        "STREET": "EVCİLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "SÜLEYMANPAŞA",
        "STREET": "FERHADANLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "SÜLEYMANPAŞA",
        "STREET": "GAZİOĞLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "SÜLEYMANPAŞA",
        "STREET": "GENERLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "SÜLEYMANPAŞA",
        "STREET": "GÜNDÜZLÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "SÜLEYMANPAŞA",
        "STREET": "GÜVEÇLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "SÜLEYMANPAŞA",
        "STREET": "HACIKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "SÜLEYMANPAŞA",
        "STREET": "HUSUNLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "SÜLEYMANPAŞA",
        "STREET": "HÜRRİYET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "SÜLEYMANPAŞA",
        "STREET": "IŞIKLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "SÜLEYMANPAŞA",
        "STREET": "KARABEZİRGAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "SÜLEYMANPAŞA",
        "STREET": "KARACAKILAVUZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "SÜLEYMANPAŞA",
        "STREET": "KARADENİZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "SÜLEYMANPAŞA",
        "STREET": "KARAEVLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "SÜLEYMANPAŞA",
        "STREET": "KARAHALİL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "SÜLEYMANPAŞA",
        "STREET": "KARAHİSARLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "SÜLEYMANPAŞA",
        "STREET": "KARANSILLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "SÜLEYMANPAŞA",
        "STREET": "KARAÇALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "SÜLEYMANPAŞA",
        "STREET": "KAYI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "SÜLEYMANPAŞA",
        "STREET": "KAZANDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "SÜLEYMANPAŞA",
        "STREET": "KAŞIKÇI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "SÜLEYMANPAŞA",
        "STREET": "KILAVUZLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "SÜLEYMANPAŞA",
        "STREET": "KINIKLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "SÜLEYMANPAŞA",
        "STREET": "KUMBAĞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "SÜLEYMANPAŞA",
        "STREET": "KÖSEİLYAS MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "SÜLEYMANPAŞA",
        "STREET": "MAHRAMLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "SÜLEYMANPAŞA",
        "STREET": "NAMIK KEMAL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "SÜLEYMANPAŞA",
        "STREET": "NAİPKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "SÜLEYMANPAŞA",
        "STREET": "NUSRATFAKI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "SÜLEYMANPAŞA",
        "STREET": "NUSRATLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "SÜLEYMANPAŞA",
        "STREET": "ORMANLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "SÜLEYMANPAŞA",
        "STREET": "ORTACA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "SÜLEYMANPAŞA",
        "STREET": "ORTACAMİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "SÜLEYMANPAŞA",
        "STREET": "ORUÇBEYLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "SÜLEYMANPAŞA",
        "STREET": "OSMANLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "SÜLEYMANPAŞA",
        "STREET": "OTMANLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "SÜLEYMANPAŞA",
        "STREET": "OĞUZLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "SÜLEYMANPAŞA",
        "STREET": "SELÇUK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "SÜLEYMANPAŞA",
        "STREET": "SEMETLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "SÜLEYMANPAŞA",
        "STREET": "SEYMENLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "SÜLEYMANPAŞA",
        "STREET": "TATARLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "SÜLEYMANPAŞA",
        "STREET": "TAŞUMURCA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "SÜLEYMANPAŞA",
        "STREET": "TOPAĞAÇ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "SÜLEYMANPAŞA",
        "STREET": "VATAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "SÜLEYMANPAŞA",
        "STREET": "YAVUZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "SÜLEYMANPAŞA",
        "STREET": "YAYABAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "SÜLEYMANPAŞA",
        "STREET": "YAZIR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "SÜLEYMANPAŞA",
        "STREET": "YAĞCI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "SÜLEYMANPAŞA",
        "STREET": "YENİCE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "SÜLEYMANPAŞA",
        "STREET": "YENİKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "SÜLEYMANPAŞA",
        "STREET": "YUKARIKILIÇLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "SÜLEYMANPAŞA",
        "STREET": "YUVA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "SÜLEYMANPAŞA",
        "STREET": "ZAFER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "SÜLEYMANPAŞA",
        "STREET": "ÇANAKÇI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "SÜLEYMANPAŞA",
        "STREET": "ÇINARLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "SÜLEYMANPAŞA",
        "STREET": "ÇİFTLİKÖNÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "SÜLEYMANPAŞA",
        "STREET": "İNECİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "SÜLEYMANPAŞA",
        "STREET": "İSTİKLAL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "ÇERKEZKÖY",
        "STREET": "BAĞLIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "ÇERKEZKÖY",
        "STREET": "CUMHURİYET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "ÇERKEZKÖY",
        "STREET": "FATİH MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "ÇERKEZKÖY",
        "STREET": "FEVZİ PAŞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "ÇERKEZKÖY",
        "STREET": "GAZİ MUSTAFA KEMALPAŞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "ÇERKEZKÖY",
        "STREET": "GAZİ OSMAN PAŞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "ÇERKEZKÖY",
        "STREET": "KIZILPINAR ATATÜRK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "ÇERKEZKÖY",
        "STREET": "KIZILPINAR GÜLTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "ÇERKEZKÖY",
        "STREET": "KIZILPINAR NAMIK KEMAL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "ÇERKEZKÖY",
        "STREET": "VELİKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "ÇERKEZKÖY",
        "STREET": "YILDIRIM BEYAZIT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "ÇERKEZKÖY",
        "STREET": "İSTASYON MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "ÇORLU",
        "STREET": "ALİPAŞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "ÇORLU",
        "STREET": "CEMALİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "ÇORLU",
        "STREET": "CUMHURİYET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "ÇORLU",
        "STREET": "DEREGÜNDÜZLÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "ÇORLU",
        "STREET": "ESENTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "ÇORLU",
        "STREET": "HATİP MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "ÇORLU",
        "STREET": "HAVUZLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "ÇORLU",
        "STREET": "HIDIRAĞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "ÇORLU",
        "STREET": "HÜRRİYET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "ÇORLU",
        "STREET": "KAZIMİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "ÇORLU",
        "STREET": "KEMALETTİN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "ÇORLU",
        "STREET": "MAKSUTLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "ÇORLU",
        "STREET": "MUHİTTİN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "ÇORLU",
        "STREET": "NUSRATİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "ÇORLU",
        "STREET": "REŞADİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "ÇORLU",
        "STREET": "RUMELİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "ÇORLU",
        "STREET": "SARILAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "ÇORLU",
        "STREET": "SEYMEN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "ÇORLU",
        "STREET": "SİLAHTARAĞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "ÇORLU",
        "STREET": "TÜRKGÜCÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "ÇORLU",
        "STREET": "YENİCE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "ÇORLU",
        "STREET": "ZAFER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "ÇORLU",
        "STREET": "ÇOBANÇEŞME MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "ÇORLU",
        "STREET": "ÇORLU A3 TİPİ KAPALI CEZA İNFAZ KURUMU",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "ÇORLU",
        "STREET": "ÇORLU CEZA İNFAZ KURUMU KAMPÜSÜ",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "ÇORLU",
        "STREET": "ÖNERLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "ÇORLU",
        "STREET": "ŞAHPAZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "ÇORLU",
        "STREET": "ŞEYHSİNAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "ŞARKÖY",
        "STREET": "AŞAĞIKALAMIŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "ŞARKÖY",
        "STREET": "BEYOĞLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "ŞARKÖY",
        "STREET": "BULGUR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "ŞARKÖY",
        "STREET": "CAMİKEBİR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "ŞARKÖY",
        "STREET": "CUMHURİYET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "ŞARKÖY",
        "STREET": "ERİKLİCE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "ŞARKÖY",
        "STREET": "GAZİKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "ŞARKÖY",
        "STREET": "GÖLCÜK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "ŞARKÖY",
        "STREET": "GÜZELKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "ŞARKÖY",
        "STREET": "HOŞKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "ŞARKÖY",
        "STREET": "KIZILCATERZİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "ŞARKÖY",
        "STREET": "KOCAALİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "ŞARKÖY",
        "STREET": "KİRAZLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "ŞARKÖY",
        "STREET": "MURSALLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "ŞARKÖY",
        "STREET": "MÜREFTE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "ŞARKÖY",
        "STREET": "PALAMUT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "ŞARKÖY",
        "STREET": "SOFUKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "ŞARKÖY",
        "STREET": "TEPEKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "ŞARKÖY",
        "STREET": "ULAMAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "ŞARKÖY",
        "STREET": "UÇMAKDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "ŞARKÖY",
        "STREET": "YAYAAĞAÇ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "ŞARKÖY",
        "STREET": "YAYAKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "ŞARKÖY",
        "STREET": "YENİKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "ŞARKÖY",
        "STREET": "YUKARIKALAMIŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "ŞARKÖY",
        "STREET": "YÖRGÜÇ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "ŞARKÖY",
        "STREET": "ÇENGELLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "ŞARKÖY",
        "STREET": "ÇINARLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "ŞARKÖY",
        "STREET": "İSHAKLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "ŞARKÖY",
        "STREET": "İSTİKLAL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "ŞARKÖY",
        "STREET": "İĞDEBAĞLARI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TEKİRDAĞ",
        "DISTRICT": "ŞARKÖY",
        "STREET": "ŞENKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ALMUS",
        "STREET": "AKARÇAY GÖRÜMLÜ-AKARÇAY ARMUTALAN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ALMUS",
        "STREET": "AKARÇAY GÖRÜMLÜ-AKARÇAY İSTİKLAL MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ALMUS",
        "STREET": "AKARÇAY GÖRÜMLÜ-AKARÇAY ŞEHİT TURAN YILDIZ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ALMUS",
        "STREET": "AKARÇAY GÖRÜMLÜ-AKARÇAY ŞENYURT MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ALMUS",
        "STREET": "AKARÇAY GÖRÜMLÜ-ATATÜRK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ALMUS",
        "STREET": "AKARÇAY GÖRÜMLÜ-CUMHURİYET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ALMUS",
        "STREET": "AKARÇAY GÖRÜMLÜ-İNÖNÜ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ALMUS",
        "STREET": "AKARÇAY GÖRÜMLÜ-ŞEHİT TUNCAY ÇOBAN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ALMUS",
        "STREET": "ALANKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ALMUS",
        "STREET": "ARISU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ALMUS",
        "STREET": "ATAKÖY-ATA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ALMUS",
        "STREET": "ATAKÖY-BAHÇEKÖY MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ALMUS",
        "STREET": "ATAKÖY-KATRANLIK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ALMUS",
        "STREET": "ATAKÖY-KURUSEKİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ALMUS",
        "STREET": "ATAKÖY-SAĞIRLAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ALMUS",
        "STREET": "ATAKÖY-YENİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ALMUS",
        "STREET": "ATAKÖY-ÇAMBULAK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ALMUS",
        "STREET": "BABAKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ALMUS",
        "STREET": "BAKIMLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ALMUS",
        "STREET": "BAĞTAŞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ALMUS",
        "STREET": "CİHET KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ALMUS",
        "STREET": "DEREKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ALMUS",
        "STREET": "DEĞERYER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ALMUS",
        "STREET": "DURUDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ALMUS",
        "STREET": "DİKİLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ALMUS",
        "STREET": "EREK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ALMUS",
        "STREET": "ESENTEPE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ALMUS",
        "STREET": "GEBELİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ALMUS",
        "STREET": "GÖLGELİ-ATATÜRK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ALMUS",
        "STREET": "GÖLGELİ-CUMHURİYET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ALMUS",
        "STREET": "GÖLGELİ-HÜRRİYET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ALMUS",
        "STREET": "GÖLGELİ-SALKAVAK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ALMUS",
        "STREET": "GÖLTEPE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ALMUS",
        "STREET": "GÜMELEÖNÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ALMUS",
        "STREET": "HUBYAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ALMUS",
        "STREET": "KAPICI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ALMUS",
        "STREET": "KARADERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ALMUS",
        "STREET": "KINIK-CUMHURİYET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ALMUS",
        "STREET": "KINIK-GEVREK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ALMUS",
        "STREET": "KINIK-HÜRRİYET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ALMUS",
        "STREET": "KINIK-KURTULUŞ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ALMUS",
        "STREET": "KINIK-YUVAKÖY MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ALMUS",
        "STREET": "KIZILELMA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ALMUS",
        "STREET": "KOLKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ALMUS",
        "STREET": "KURTULUŞ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ALMUS",
        "STREET": "MESCİTKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ALMUS",
        "STREET": "ORMANDİBİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ALMUS",
        "STREET": "OĞULBEY KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ALMUS",
        "STREET": "SAHİLKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ALMUS",
        "STREET": "SARIÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ALMUS",
        "STREET": "SERİNCE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ALMUS",
        "STREET": "SİTELER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ALMUS",
        "STREET": "TEKNECİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ALMUS",
        "STREET": "ÇAMDALI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ALMUS",
        "STREET": "ÇAMKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ALMUS",
        "STREET": "ÇAYKAYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ALMUS",
        "STREET": "ÇAYKIYI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ALMUS",
        "STREET": "ÇEVRELİ-AYDINLAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ALMUS",
        "STREET": "ÇEVRELİ-BİRLİK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ALMUS",
        "STREET": "ÇEVRELİ-HÜRRİYET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ALMUS",
        "STREET": "ÇİFTLİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ALMUS",
        "STREET": "ÇİLEHANE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ALMUS",
        "STREET": "ÖTÜKENT MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ALMUS",
        "STREET": "ÜÇGÖL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ALMUS",
        "STREET": "İSTİKLAL MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ARTOVA",
        "STREET": "AHMETDANİŞMENT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ARTOVA",
        "STREET": "AKTAŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ARTOVA",
        "STREET": "ALPASLAN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ARTOVA",
        "STREET": "ALTINOVA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ARTOVA",
        "STREET": "AĞMUSA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ARTOVA",
        "STREET": "AŞAĞIGÜÇLÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ARTOVA",
        "STREET": "BAYIRLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ARTOVA",
        "STREET": "BEBEKDERESİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ARTOVA",
        "STREET": "BOYUNPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ARTOVA",
        "STREET": "DEVECİKARGIN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ARTOVA",
        "STREET": "EVLİDERESİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ARTOVA",
        "STREET": "GAZİOSMANPAŞA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ARTOVA",
        "STREET": "GAZİPINARI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ARTOVA",
        "STREET": "GÜMÜŞYURT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ARTOVA",
        "STREET": "GÜRARDIÇ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ARTOVA",
        "STREET": "KAYAÖNÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ARTOVA",
        "STREET": "KIZILCA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ARTOVA",
        "STREET": "KUNDUZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ARTOVA",
        "STREET": "KUNDUZAĞILI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ARTOVA",
        "STREET": "MERTEKLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ARTOVA",
        "STREET": "POYRAZALAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ARTOVA",
        "STREET": "SALUR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ARTOVA",
        "STREET": "SAĞLICA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ARTOVA",
        "STREET": "TANYELİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ARTOVA",
        "STREET": "TAŞPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ARTOVA",
        "STREET": "TUZLA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ARTOVA",
        "STREET": "ULUSULU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ARTOVA",
        "STREET": "YAĞCIMUSA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ARTOVA",
        "STREET": "YENİCE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ARTOVA",
        "STREET": "YUKARIGÜÇLÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ARTOVA",
        "STREET": "ÇELİKLİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ARTOVA",
        "STREET": "İSTASYON MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ARTOVA",
        "STREET": "İĞDİR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "BAŞÇİFTLİK",
        "STREET": "ALAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "BAŞÇİFTLİK",
        "STREET": "AYDOĞMUŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "BAŞÇİFTLİK",
        "STREET": "CUMHURİYET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "BAŞÇİFTLİK",
        "STREET": "DAĞÜSTÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "BAŞÇİFTLİK",
        "STREET": "ERİKBELEN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "BAŞÇİFTLİK",
        "STREET": "GENÇOSMAN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "BAŞÇİFTLİK",
        "STREET": "HATİPLİ-CUMHURİYET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "BAŞÇİFTLİK",
        "STREET": "HATİPLİ-FATİH MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "BAŞÇİFTLİK",
        "STREET": "HATİPLİ-GENÇOSMAN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "BAŞÇİFTLİK",
        "STREET": "KARACAÖREN ALİBABA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "BAŞÇİFTLİK",
        "STREET": "KARACAÖREN AŞIROĞLU MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "BAŞÇİFTLİK",
        "STREET": "KARACAÖREN GAZİOSMANPAŞA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "BAŞÇİFTLİK",
        "STREET": "SARIAĞIL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "BAŞÇİFTLİK",
        "STREET": "SİNANPAŞA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "BAŞÇİFTLİK",
        "STREET": "ŞAHNAALAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ERBAA",
        "STREET": "AHMET YESEVİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ERBAA",
        "STREET": "AKGÜN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ERBAA",
        "STREET": "AKÇA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ERBAA",
        "STREET": "ALACABAL MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ERBAA",
        "STREET": "ALAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ERBAA",
        "STREET": "AYAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ERBAA",
        "STREET": "AYDINSOFU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ERBAA",
        "STREET": "AĞCAALAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ERBAA",
        "STREET": "AĞCAKEÇİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ERBAA",
        "STREET": "AŞAĞIÇANDIR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ERBAA",
        "STREET": "BALLIBAĞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ERBAA",
        "STREET": "BAĞPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ERBAA",
        "STREET": "BENLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ERBAA",
        "STREET": "BEYKAYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ERBAA",
        "STREET": "CANBOLAT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ERBAA",
        "STREET": "CUMHURİYET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ERBAA",
        "STREET": "CİBRİL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ERBAA",
        "STREET": "DEMİRTAŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ERBAA",
        "STREET": "DEĞİRMENLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ERBAA",
        "STREET": "DOKUZÇAM KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ERBAA",
        "STREET": "DOĞANYURT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ERBAA",
        "STREET": "ENDİKPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ERBAA",
        "STREET": "ENGELLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ERBAA",
        "STREET": "ERDEMLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ERBAA",
        "STREET": "EREK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ERBAA",
        "STREET": "ERMEYDANI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ERBAA",
        "STREET": "ERTUĞRULGAZİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ERBAA",
        "STREET": "ERYABA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ERBAA",
        "STREET": "EVCİLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ERBAA",
        "STREET": "EVYABA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ERBAA",
        "STREET": "EZEBAĞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ERBAA",
        "STREET": "FATİHSULTANMEHMET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ERBAA",
        "STREET": "FEVZİPAŞA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ERBAA",
        "STREET": "GAZİOSMANPAŞA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ERBAA",
        "STREET": "GAZİPAŞA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ERBAA",
        "STREET": "GÖKAL-ALPARSLAN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ERBAA",
        "STREET": "GÖKAL-FATİH SULTAN MEHMET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ERBAA",
        "STREET": "GÖKAL-GAZİ OSMAN PAŞA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ERBAA",
        "STREET": "GÖKAL-GÜLDERE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ERBAA",
        "STREET": "GÖKAL-KARŞIYAKA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ERBAA",
        "STREET": "GÖKAL-SÜTLÜCE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ERBAA",
        "STREET": "GÖKAL-YAVUZ SULTAN SELİM MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ERBAA",
        "STREET": "GÖKAL-YEŞİLYURT MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ERBAA",
        "STREET": "GÖKAL-ÇERMİK KAPLICA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ERBAA",
        "STREET": "GÖLÖNÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ERBAA",
        "STREET": "GÜMÜŞALAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ERBAA",
        "STREET": "GÜNDOĞDU MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ERBAA",
        "STREET": "GÜVEÇLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ERBAA",
        "STREET": "HACIALİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ERBAA",
        "STREET": "HACIBÜKÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ERBAA",
        "STREET": "HACIPAZAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ERBAA",
        "STREET": "KALE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ERBAA",
        "STREET": "KARAAĞAÇ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ERBAA",
        "STREET": "KARAYAKA-BAHÇELİEVLER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ERBAA",
        "STREET": "KARAYAKA-HÜRMÜZLÜ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ERBAA",
        "STREET": "KARAYAKA-ORTA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ERBAA",
        "STREET": "KARAYAKA-TEPE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ERBAA",
        "STREET": "KARAYAKA-YUKARI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ERBAA",
        "STREET": "KARTOSMAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ERBAA",
        "STREET": "KARŞIYAKA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ERBAA",
        "STREET": "KAVALCIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ERBAA",
        "STREET": "KELKİT MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ERBAA",
        "STREET": "KEÇECİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ERBAA",
        "STREET": "KIRIKGÜNEY KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ERBAA",
        "STREET": "KIZILÇUBUK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ERBAA",
        "STREET": "KOZLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ERBAA",
        "STREET": "KOÇAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ERBAA",
        "STREET": "KURTULUŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ERBAA",
        "STREET": "KURUÇAY MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ERBAA",
        "STREET": "KUZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ERBAA",
        "STREET": "KÜPLÜCE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ERBAA",
        "STREET": "MADENLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ERBAA",
        "STREET": "MEHMET AKİF MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ERBAA",
        "STREET": "MEVLANA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ERBAA",
        "STREET": "MEYDANDÜZÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ERBAA",
        "STREET": "MİMARSİNAN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ERBAA",
        "STREET": "NARLIDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ERBAA",
        "STREET": "OCAKBAŞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ERBAA",
        "STREET": "ORTAKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ERBAA",
        "STREET": "OSMANGAZİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ERBAA",
        "STREET": "OĞLAKÇI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ERBAA",
        "STREET": "PINARBEYLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ERBAA",
        "STREET": "SALKIMÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ERBAA",
        "STREET": "SOKUTAŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ERBAA",
        "STREET": "TANDIRLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ERBAA",
        "STREET": "TANOBA-AKKOÇ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ERBAA",
        "STREET": "TANOBA-BAHÇELİEVLER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ERBAA",
        "STREET": "TANOBA-FEVZİPAŞA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ERBAA",
        "STREET": "TANOBA-GÜRPINAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ERBAA",
        "STREET": "TANOBA-ÇANKAYA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ERBAA",
        "STREET": "TEPEKIŞLA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ERBAA",
        "STREET": "TOSUNLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ERBAA",
        "STREET": "TÜRKFINDICAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ERBAA",
        "STREET": "USTAMEHMET KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ERBAA",
        "STREET": "YAVUZSULTANSELİM MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ERBAA",
        "STREET": "YAYLACIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ERBAA",
        "STREET": "YAYLALI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ERBAA",
        "STREET": "YENİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ERBAA",
        "STREET": "YEŞİLYURT MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ERBAA",
        "STREET": "YILDIRIMBEYAZIT MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ERBAA",
        "STREET": "YOLDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ERBAA",
        "STREET": "YUKARIÇANDIR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ERBAA",
        "STREET": "YUNUSEMRE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ERBAA",
        "STREET": "YURDALAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ERBAA",
        "STREET": "ZOĞALLIÇUKUR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ERBAA",
        "STREET": "ZİYAGÖKALP MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ERBAA",
        "STREET": "ÇAKIR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ERBAA",
        "STREET": "ÇALKARA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ERBAA",
        "STREET": "ÇAMDİBİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ERBAA",
        "STREET": "ÇATALAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ERBAA",
        "STREET": "ÇERKEZFINDICAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ERBAA",
        "STREET": "ÇEVRESU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ERBAA",
        "STREET": "ÇEŞMELİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ERBAA",
        "STREET": "ÜZÜMLÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ERBAA",
        "STREET": "İKİZCE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ERBAA",
        "STREET": "İSMETPAŞA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ERBAA",
        "STREET": "İVERÖNÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ERBAA",
        "STREET": "ŞÜKÜR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "NİKSAR",
        "STREET": "50.YIL MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "NİKSAR",
        "STREET": "AKGÜNEY KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "NİKSAR",
        "STREET": "AKINCI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "NİKSAR",
        "STREET": "AKPINAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "NİKSAR",
        "STREET": "ARAKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "NİKSAR",
        "STREET": "ARDIÇLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "NİKSAR",
        "STREET": "ARIPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "NİKSAR",
        "STREET": "ARPAÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "NİKSAR",
        "STREET": "ASAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "NİKSAR",
        "STREET": "AYDINLIKEVLER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "NİKSAR",
        "STREET": "AYVALI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "NİKSAR",
        "STREET": "AYVAZ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "NİKSAR",
        "STREET": "BAHÇELİEVLER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "NİKSAR",
        "STREET": "BAYRAKTEPE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "NİKSAR",
        "STREET": "BAĞLAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "NİKSAR",
        "STREET": "BENGİLER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "NİKSAR",
        "STREET": "BEYÇAYIRI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "NİKSAR",
        "STREET": "BOYLUCA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "NİKSAR",
        "STREET": "BOZCAARMUT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "NİKSAR",
        "STREET": "BUDAKLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "NİKSAR",
        "STREET": "BÜYÜKYURT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "NİKSAR",
        "STREET": "BİLGİLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "NİKSAR",
        "STREET": "CAMİDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "NİKSAR",
        "STREET": "CEDİT MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "NİKSAR",
        "STREET": "CEPNİBEY MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "NİKSAR",
        "STREET": "CERKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "NİKSAR",
        "STREET": "DALKAYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "NİKSAR",
        "STREET": "DERİNDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "NİKSAR",
        "STREET": "DÖNEKSE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "NİKSAR",
        "STREET": "DİREKLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "NİKSAR",
        "STREET": "EDİLLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "NİKSAR",
        "STREET": "ERYABA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "NİKSAR",
        "STREET": "ESENCE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "NİKSAR",
        "STREET": "EYNEAĞZI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "NİKSAR",
        "STREET": "FATİH MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "NİKSAR",
        "STREET": "G.OSMANPAŞA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "NİKSAR",
        "STREET": "GAZİAHMET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "NİKSAR",
        "STREET": "GERİT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "NİKSAR",
        "STREET": "GEYİKGÖLÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "NİKSAR",
        "STREET": "GÖKÇELİ-BAHÇELİEVLER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "NİKSAR",
        "STREET": "GÖKÇELİ-BALLICA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "NİKSAR",
        "STREET": "GÖKÇELİ-GÖZPINAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "NİKSAR",
        "STREET": "GÖKÇELİ-YAKINCA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "NİKSAR",
        "STREET": "GÖKÇELİ-YENİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "NİKSAR",
        "STREET": "GÖKÇEOLUK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "NİKSAR",
        "STREET": "GÜDÜKLÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "NİKSAR",
        "STREET": "GÜLBAYIR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "NİKSAR",
        "STREET": "GÜLEBİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "NİKSAR",
        "STREET": "GÜLTEPE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "NİKSAR",
        "STREET": "GÜNEBAKAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "NİKSAR",
        "STREET": "GÜNLÜCE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "NİKSAR",
        "STREET": "GÜRÇEŞME-BUZ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "NİKSAR",
        "STREET": "GÜRÇEŞME-ESKİDİR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "NİKSAR",
        "STREET": "GÜRÇEŞME-GAZİ OSMAN PAŞA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "NİKSAR",
        "STREET": "GÜRÇEŞME-YAPRAKLI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "NİKSAR",
        "STREET": "GÜVENLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "NİKSAR",
        "STREET": "GÜZELYAYLA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "NİKSAR",
        "STREET": "HACILI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "NİKSAR",
        "STREET": "HAMİDİYE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "NİKSAR",
        "STREET": "HANYERİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "NİKSAR",
        "STREET": "HAYDERBEY MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "NİKSAR",
        "STREET": "HÜSEYİNGAZİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "NİKSAR",
        "STREET": "IŞIKLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "NİKSAR",
        "STREET": "KAPIAĞZI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "NİKSAR",
        "STREET": "KARABODUR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "NİKSAR",
        "STREET": "KARAKAŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "NİKSAR",
        "STREET": "KAYAPAŞA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "NİKSAR",
        "STREET": "KAŞIKCI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "NİKSAR",
        "STREET": "KILIÇARSLAN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "NİKSAR",
        "STREET": "KIRKKIZLAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "NİKSAR",
        "STREET": "KORULU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "NİKSAR",
        "STREET": "KUMÇİFTLİK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "NİKSAR",
        "STREET": "KUYUCAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "NİKSAR",
        "STREET": "KÖKLÜCE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "NİKSAR",
        "STREET": "KÜLTÜR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "NİKSAR",
        "STREET": "KÜMBETLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "NİKSAR",
        "STREET": "KİRACI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "NİKSAR",
        "STREET": "MAHMUDİYE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "NİKSAR",
        "STREET": "MELİKGAZİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "NİKSAR",
        "STREET": "MERCİMEKDÜZÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "NİKSAR",
        "STREET": "MEZRA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "NİKSAR",
        "STREET": "MUHTARDÜZÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "NİKSAR",
        "STREET": "MUSAPINARI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "NİKSAR",
        "STREET": "MUTLUCA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "NİKSAR",
        "STREET": "OLUKLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "NİKSAR",
        "STREET": "ORMANCIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "NİKSAR",
        "STREET": "OSMANİYE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "NİKSAR",
        "STREET": "PELİTLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "NİKSAR",
        "STREET": "SARIYAZI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "NİKSAR",
        "STREET": "SERENLİ-AYVAKÖY MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "NİKSAR",
        "STREET": "SERENLİ-BEYAZIT MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "NİKSAR",
        "STREET": "SERENLİ-FATİH MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "NİKSAR",
        "STREET": "SERENLİ-GAZİ OSMAN PAŞA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "NİKSAR",
        "STREET": "SERENLİ-TAMLAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "NİKSAR",
        "STREET": "SORHUN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "NİKSAR",
        "STREET": "SULUGÖL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "NİKSAR",
        "STREET": "TAHTALI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "NİKSAR",
        "STREET": "TEKNEALAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "NİKSAR",
        "STREET": "TEPEYATAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "NİKSAR",
        "STREET": "TERZİOĞLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "NİKSAR",
        "STREET": "UMURLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "NİKSAR",
        "STREET": "USTAHASAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "NİKSAR",
        "STREET": "YALIKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "NİKSAR",
        "STREET": "YARBAŞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "NİKSAR",
        "STREET": "YAZICIK-KÜLTÜR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "NİKSAR",
        "STREET": "YAZICIK-YENİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "NİKSAR",
        "STREET": "YAZICIK-YEŞİLÇAM MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "NİKSAR",
        "STREET": "YAZICIK-ŞAFAK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "NİKSAR",
        "STREET": "YEŞİLHİSAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "NİKSAR",
        "STREET": "YEŞİLKAYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "NİKSAR",
        "STREET": "YEŞİLKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "NİKSAR",
        "STREET": "YEŞİLYURT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "NİKSAR",
        "STREET": "YOLKONAK-HASYER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "NİKSAR",
        "STREET": "YOLKONAK-KATİPGÖLÜ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "NİKSAR",
        "STREET": "YOLKONAK-MERKEZ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "NİKSAR",
        "STREET": "YOLKONAK-YEŞİLYURT MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "NİKSAR",
        "STREET": "YUSUFŞAH MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "NİKSAR",
        "STREET": "ÇALCA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "NİKSAR",
        "STREET": "ÇATAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "NİKSAR",
        "STREET": "ÇAY KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "NİKSAR",
        "STREET": "ÇENGELLİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "NİKSAR",
        "STREET": "ÇİMENÖZÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "NİKSAR",
        "STREET": "ÇİÇEKLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "NİKSAR",
        "STREET": "ÖRENLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "NİKSAR",
        "STREET": "ÖZALAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "NİKSAR",
        "STREET": "ÖZVEREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "NİKSAR",
        "STREET": "İSMETPAŞA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "NİKSAR",
        "STREET": "ŞAHİNLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "NİKSAR",
        "STREET": "ŞAİR EMRAH MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "NİKSAR",
        "STREET": "ŞIHLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "PAZAR",
        "STREET": "BALLICA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "PAZAR",
        "STREET": "BAĞLARBAŞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "PAZAR",
        "STREET": "BEŞEVLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "PAZAR",
        "STREET": "DEREKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "PAZAR",
        "STREET": "DEREÇAYLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "PAZAR",
        "STREET": "DOĞANCALI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "PAZAR",
        "STREET": "ERKİLET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "PAZAR",
        "STREET": "ESENTEPE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "PAZAR",
        "STREET": "KALEDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "PAZAR",
        "STREET": "MENTEŞE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "PAZAR",
        "STREET": "MERKEZ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "PAZAR",
        "STREET": "OCAKLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "PAZAR",
        "STREET": "OVACIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "PAZAR",
        "STREET": "OVAYURT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "PAZAR",
        "STREET": "SEYİTALİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "PAZAR",
        "STREET": "SİNANPAŞA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "PAZAR",
        "STREET": "TATARKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "PAZAR",
        "STREET": "TAŞLIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "PAZAR",
        "STREET": "TEKKE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "PAZAR",
        "STREET": "TEPEÇAYLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "PAZAR",
        "STREET": "ÇAYKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "PAZAR",
        "STREET": "ÇİFTLİKKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "PAZAR",
        "STREET": "ÜZÜMÖREN-FATİH MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "PAZAR",
        "STREET": "ÜZÜMÖREN-GAZİ OSMAN PAŞA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "PAZAR",
        "STREET": "ÜZÜMÖREN-KAZOVA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "PAZAR",
        "STREET": "ÜZÜMÖREN-MEHMET AKİF ERSOY MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "PAZAR",
        "STREET": "ÜZÜMÖREN-MRŞ.FEVZİ ÇAKMAK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "PAZAR",
        "STREET": "ÜZÜMÖREN-YEŞİLDERE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "REŞADİYE",
        "STREET": "ABDURRAHMANLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "REŞADİYE",
        "STREET": "AKDOĞMUŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "REŞADİYE",
        "STREET": "AKÇAKOLAY MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "REŞADİYE",
        "STREET": "ALTIPARMAK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "REŞADİYE",
        "STREET": "BAYDARLI-GÖKCESÜLEYMANLI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "REŞADİYE",
        "STREET": "BAYDARLI-GÜNDOĞDU MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "REŞADİYE",
        "STREET": "BAYDARLI-MEDRESE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "REŞADİYE",
        "STREET": "BAYDARLI-MERKEZ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "REŞADİYE",
        "STREET": "BAYDARLI-YÜKSELİŞ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "REŞADİYE",
        "STREET": "BAYIRBAŞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "REŞADİYE",
        "STREET": "BAĞDATLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "REŞADİYE",
        "STREET": "BEREKETLİ-ESKİTURAÇ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "REŞADİYE",
        "STREET": "BEREKETLİ-HEBÜLLÜ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "REŞADİYE",
        "STREET": "BEREKETLİ-KAYALIK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "REŞADİYE",
        "STREET": "BEREKETLİ-ORTA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "REŞADİYE",
        "STREET": "BEREKETLİ-YENİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "REŞADİYE",
        "STREET": "BEREKETLİ-ÇAKRAZ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "REŞADİYE",
        "STREET": "BEŞDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "REŞADİYE",
        "STREET": "BOSTANKOLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "REŞADİYE",
        "STREET": "BOZÇALI-GERDENGEÇ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "REŞADİYE",
        "STREET": "BOZÇALI-HACI ABİDİN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "REŞADİYE",
        "STREET": "BOZÇALI-IŞIKLAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "REŞADİYE",
        "STREET": "BOZÇALI-NİZAM MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "REŞADİYE",
        "STREET": "BOZÇALI-ORTA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "REŞADİYE",
        "STREET": "BOZÇALI-ZAFER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "REŞADİYE",
        "STREET": "BÜŞÜRÜM KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "REŞADİYE",
        "STREET": "CEMEL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "REŞADİYE",
        "STREET": "CİMİTEKKE-ALPLER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "REŞADİYE",
        "STREET": "CİMİTEKKE-CİMİSADİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "REŞADİYE",
        "STREET": "CİMİTEKKE-ERENLER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "REŞADİYE",
        "STREET": "CİMİTEKKE-HALAÇLI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "REŞADİYE",
        "STREET": "CİMİTEKKE-KAVAKLIDERE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "REŞADİYE",
        "STREET": "CİMİTEKKE-KETENİĞİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "REŞADİYE",
        "STREET": "CİMİTEKKE-ÇAMLIK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "REŞADİYE",
        "STREET": "DALPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "REŞADİYE",
        "STREET": "DANİŞMENT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "REŞADİYE",
        "STREET": "DARIDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "REŞADİYE",
        "STREET": "DEMİRCİLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "REŞADİYE",
        "STREET": "DOLAY KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "REŞADİYE",
        "STREET": "DOĞANTEPE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "REŞADİYE",
        "STREET": "DUTDİBİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "REŞADİYE",
        "STREET": "DÖLLÜK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "REŞADİYE",
        "STREET": "ELMACIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "REŞADİYE",
        "STREET": "EMİRŞEYHYAKUP MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "REŞADİYE",
        "STREET": "ESENKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "REŞADİYE",
        "STREET": "EYMÜR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "REŞADİYE",
        "STREET": "EYÜP KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "REŞADİYE",
        "STREET": "GURBETLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "REŞADİYE",
        "STREET": "GÖKKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "REŞADİYE",
        "STREET": "GÖLLÜKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "REŞADİYE",
        "STREET": "GÜLBURNU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "REŞADİYE",
        "STREET": "GÜLKONAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "REŞADİYE",
        "STREET": "GÜLLÜCE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "REŞADİYE",
        "STREET": "GÜNEYGÖLCÜK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "REŞADİYE",
        "STREET": "GÜVENDİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "REŞADİYE",
        "STREET": "GÜZELDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "REŞADİYE",
        "STREET": "HASANŞEYH-GÜNDOĞAN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "REŞADİYE",
        "STREET": "HASANŞEYH-GÜRPINAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "REŞADİYE",
        "STREET": "HASANŞEYH-HASANGAZİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "REŞADİYE",
        "STREET": "HASANŞEYH-KEÇİKÖY MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "REŞADİYE",
        "STREET": "HASANŞEYH-TAHİRBEY MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "REŞADİYE",
        "STREET": "HASANŞEYH-ULUKÖY MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "REŞADİYE",
        "STREET": "HASANŞEYH-ŞEYHLİOĞLU MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "REŞADİYE",
        "STREET": "KABALI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "REŞADİYE",
        "STREET": "KAPAKLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "REŞADİYE",
        "STREET": "KARACAAĞAÇ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "REŞADİYE",
        "STREET": "KARATAŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "REŞADİYE",
        "STREET": "KARLIYAYLA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "REŞADİYE",
        "STREET": "KARŞIKENT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "REŞADİYE",
        "STREET": "KAYALIK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "REŞADİYE",
        "STREET": "KAŞPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "REŞADİYE",
        "STREET": "KIZILCAÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "REŞADİYE",
        "STREET": "KONAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "REŞADİYE",
        "STREET": "KURTULUŞ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "REŞADİYE",
        "STREET": "KUYUCAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "REŞADİYE",
        "STREET": "KUZBAĞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "REŞADİYE",
        "STREET": "KUZGÖLCÜK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "REŞADİYE",
        "STREET": "KÖKLÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "REŞADİYE",
        "STREET": "MURATKAYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "REŞADİYE",
        "STREET": "NEBİŞEYH KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "REŞADİYE",
        "STREET": "OSMANİYE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "REŞADİYE",
        "STREET": "SARAYKIŞLA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "REŞADİYE",
        "STREET": "SARIYAYLA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "REŞADİYE",
        "STREET": "SAZAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "REŞADİYE",
        "STREET": "SOĞUKPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "REŞADİYE",
        "STREET": "TAŞLICA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "REŞADİYE",
        "STREET": "TOKLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "REŞADİYE",
        "STREET": "TOZANLIFINDICAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "REŞADİYE",
        "STREET": "UMURCA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "REŞADİYE",
        "STREET": "UĞURLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "REŞADİYE",
        "STREET": "YAĞSİYAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "REŞADİYE",
        "STREET": "YENİ DARIDERESİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "REŞADİYE",
        "STREET": "YENİTURAÇ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "REŞADİYE",
        "STREET": "YEŞİLYURT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "REŞADİYE",
        "STREET": "YOLÜSTÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "REŞADİYE",
        "STREET": "YOĞUNPELİT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "REŞADİYE",
        "STREET": "YUVACIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "REŞADİYE",
        "STREET": "ÇAKIRLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "REŞADİYE",
        "STREET": "ÇAKMAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "REŞADİYE",
        "STREET": "ÇAMBALI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "REŞADİYE",
        "STREET": "ÇAMLICA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "REŞADİYE",
        "STREET": "ÇAMLIKAYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "REŞADİYE",
        "STREET": "ÇAT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "REŞADİYE",
        "STREET": "ÇAVUŞBEYLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "REŞADİYE",
        "STREET": "ÇAYIRPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "REŞADİYE",
        "STREET": "ÇERMİK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "REŞADİYE",
        "STREET": "ÇEVRECİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "REŞADİYE",
        "STREET": "ÇINARCIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "REŞADİYE",
        "STREET": "ÖZEN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "REŞADİYE",
        "STREET": "ÖZLÜCE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "REŞADİYE",
        "STREET": "İBRAHİMŞEYH KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "REŞADİYE",
        "STREET": "İSLAMLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "REŞADİYE",
        "STREET": "İSMAİLİYE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "SULUSARAY",
        "STREET": "ALANYURT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "SULUSARAY",
        "STREET": "ALPUDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "SULUSARAY",
        "STREET": "ARPACIKARAÇAY KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "SULUSARAY",
        "STREET": "BALIKHİSAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "SULUSARAY",
        "STREET": "BALLIKAYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "SULUSARAY",
        "STREET": "BAYAZIT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "SULUSARAY",
        "STREET": "BELPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "SULUSARAY",
        "STREET": "BUĞDAYLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "SULUSARAY",
        "STREET": "FATİH MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "SULUSARAY",
        "STREET": "GAZİOSMANPAŞA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "SULUSARAY",
        "STREET": "ILICAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "SULUSARAY",
        "STREET": "MALUM SEYİT TEKKE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "SULUSARAY",
        "STREET": "MENDERES MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "SULUSARAY",
        "STREET": "SARIYAPRAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "SULUSARAY",
        "STREET": "SELİMİYE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "SULUSARAY",
        "STREET": "TEKKEYENİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "SULUSARAY",
        "STREET": "UYLUBAĞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "SULUSARAY",
        "STREET": "ÇİME KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "MERKEZ",
        "STREET": "ACIPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "MERKEZ",
        "STREET": "AHMETALAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "MERKEZ",
        "STREET": "AKBELEN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "MERKEZ",
        "STREET": "AKDEĞİRMEN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "MERKEZ",
        "STREET": "AKYAMAÇ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "MERKEZ",
        "STREET": "AKYURT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "MERKEZ",
        "STREET": "ALAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "MERKEZ",
        "STREET": "ALTIYÜZEVLER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "MERKEZ",
        "STREET": "ALTUNTAŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "MERKEZ",
        "STREET": "ALUÇ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "MERKEZ",
        "STREET": "ALİPAŞA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "MERKEZ",
        "STREET": "AVLUNLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "MERKEZ",
        "STREET": "AVŞARAĞZI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "MERKEZ",
        "STREET": "AYDINCA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "MERKEZ",
        "STREET": "AYDOĞDU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "MERKEZ",
        "STREET": "AŞAĞIFIRINDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "MERKEZ",
        "STREET": "BAHÇELİEVLER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "MERKEZ",
        "STREET": "BAKIŞLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "MERKEZ",
        "STREET": "BALLIDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "MERKEZ",
        "STREET": "BATMANTAŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "MERKEZ",
        "STREET": "BAĞBAŞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "MERKEZ",
        "STREET": "BEDESTENLİOĞLU MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "MERKEZ",
        "STREET": "BEDİRKALE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "MERKEZ",
        "STREET": "BEŞÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "MERKEZ",
        "STREET": "BOYALI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "MERKEZ",
        "STREET": "BULA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "MERKEZ",
        "STREET": "BÜYÜK BEYBAĞI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "MERKEZ",
        "STREET": "BÜYÜKBAĞLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "MERKEZ",
        "STREET": "BÜYÜKYILDIZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "MERKEZ",
        "STREET": "BİNECEK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "MERKEZ",
        "STREET": "CAMİİKEBİR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "MERKEZ",
        "STREET": "CEMALETTİN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "MERKEZ",
        "STREET": "DAYLIHACI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "MERKEZ",
        "STREET": "DEDELİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "MERKEZ",
        "STREET": "DERBENT MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "MERKEZ",
        "STREET": "DEREAĞZI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "MERKEZ",
        "STREET": "DEREKIŞLA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "MERKEZ",
        "STREET": "DEREYAKA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "MERKEZ",
        "STREET": "DEVEGÖRMEZ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "MERKEZ",
        "STREET": "DODURGA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "MERKEZ",
        "STREET": "DOĞANCIBAĞLARI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "MERKEZ",
        "STREET": "DOĞUKENT MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "MERKEZ",
        "STREET": "DÖLLÜK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "MERKEZ",
        "STREET": "EFE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "MERKEZ",
        "STREET": "EKİNCİLİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "MERKEZ",
        "STREET": "EMİRSEYİT-BAHÇELİEVLER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "MERKEZ",
        "STREET": "EMİRSEYİT-BOZATALAN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "MERKEZ",
        "STREET": "EMİRSEYİT-GÜNEŞLİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "MERKEZ",
        "STREET": "ERENLER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "MERKEZ",
        "STREET": "ESENTEPE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "MERKEZ",
        "STREET": "ESKİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "MERKEZ",
        "STREET": "EZE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "MERKEZ",
        "STREET": "GAZİOSMANPAŞA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "MERKEZ",
        "STREET": "GEYRAS MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "MERKEZ",
        "STREET": "GEZİRLİK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "MERKEZ",
        "STREET": "GÖKDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "MERKEZ",
        "STREET": "GÖKÇE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "MERKEZ",
        "STREET": "GÖKÇEYOL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "MERKEZ",
        "STREET": "GÖLCÜK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "MERKEZ",
        "STREET": "GÖZOVA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "MERKEZ",
        "STREET": "GÜLBAHARHATUN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "MERKEZ",
        "STREET": "GÜLPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "MERKEZ",
        "STREET": "GÜMENEK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "MERKEZ",
        "STREET": "GÜNEVİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "MERKEZ",
        "STREET": "GÜNEŞLİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "MERKEZ",
        "STREET": "GÜNÇALI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "MERKEZ",
        "STREET": "GÜRYILDIZ-ALİ YÜCEL MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "MERKEZ",
        "STREET": "GÜRYILDIZ-BAHÇELİEVLER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "MERKEZ",
        "STREET": "GÜRYILDIZ-KANALALTI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "MERKEZ",
        "STREET": "GÜRYILDIZ-KÜÇÜKYILDIZ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "MERKEZ",
        "STREET": "GÜRYILDIZ-ŞHT.ABDULLAH ÖZER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "MERKEZ",
        "STREET": "GÜZELCE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "MERKEZ",
        "STREET": "GÜZELDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "MERKEZ",
        "STREET": "GÜĞÜNLÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "MERKEZ",
        "STREET": "HALİLALAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "MERKEZ",
        "STREET": "HANPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "MERKEZ",
        "STREET": "HASANBABA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "MERKEZ",
        "STREET": "HOCAAHMET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "MERKEZ",
        "STREET": "KABAKBOĞAZI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "MERKEZ",
        "STREET": "KABE-İ MESCİD MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "MERKEZ",
        "STREET": "KADIVAKFI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "MERKEZ",
        "STREET": "KALEARDI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "MERKEZ",
        "STREET": "KARAKAYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "MERKEZ",
        "STREET": "KARKIN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "MERKEZ",
        "STREET": "KARKINCIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "MERKEZ",
        "STREET": "KARŞIYAKA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "MERKEZ",
        "STREET": "KAŞIKCIBAĞLARI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "MERKEZ",
        "STREET": "KEMALPAŞA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "MERKEZ",
        "STREET": "KEMER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "MERKEZ",
        "STREET": "KERVANSARAY KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "MERKEZ",
        "STREET": "KEŞLİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "MERKEZ",
        "STREET": "KIZIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "MERKEZ",
        "STREET": "KIZILKAYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "MERKEZ",
        "STREET": "KIZILKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "MERKEZ",
        "STREET": "KIZILÖZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "MERKEZ",
        "STREET": "KOCACIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "MERKEZ",
        "STREET": "KÖMEÇ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "MERKEZ",
        "STREET": "KÜMBET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "MERKEZ",
        "STREET": "KÜÇÜKBAĞLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "MERKEZ",
        "STREET": "KÜÇÜKBEY BAĞI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "MERKEZ",
        "STREET": "KİLLİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "MERKEZ",
        "STREET": "MADAS KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "MERKEZ",
        "STREET": "MAHMUTPAŞA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "MERKEZ",
        "STREET": "MEHMETPAŞA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "MERKEZ",
        "STREET": "MUSULLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "MERKEZ",
        "STREET": "MÜLK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "MERKEZ",
        "STREET": "NEBİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "MERKEZ",
        "STREET": "ORMANBEYLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "MERKEZ",
        "STREET": "ORTAÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "MERKEZ",
        "STREET": "OĞULBEY MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "MERKEZ",
        "STREET": "PERAKENDE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "MERKEZ",
        "STREET": "PINARLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "MERKEZ",
        "STREET": "SARITARLA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "MERKEZ",
        "STREET": "SEMERCİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "MERKEZ",
        "STREET": "SEMERKANT MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "MERKEZ",
        "STREET": "SEVİNDİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "MERKEZ",
        "STREET": "SEYİTNECMETTİN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "MERKEZ",
        "STREET": "SIRÇALI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "MERKEZ",
        "STREET": "SOĞUKPINAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "MERKEZ",
        "STREET": "SÖNGÜT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "MERKEZ",
        "STREET": "TAHTUBA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "MERKEZ",
        "STREET": "TAŞLIÇİFTLİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "MERKEZ",
        "STREET": "TEKNELİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "MERKEZ",
        "STREET": "TOPÇAM MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "MERKEZ",
        "STREET": "TOPÇUBAĞI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "MERKEZ",
        "STREET": "ULAŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "MERKEZ",
        "STREET": "UĞRAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "MERKEZ",
        "STREET": "YAKACIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "MERKEZ",
        "STREET": "YARAHMET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "MERKEZ",
        "STREET": "YATMIŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "MERKEZ",
        "STREET": "YAYLADALI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "MERKEZ",
        "STREET": "YAZIBAŞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "MERKEZ",
        "STREET": "YAĞMURLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "MERKEZ",
        "STREET": "YELPE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "MERKEZ",
        "STREET": "YENİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "MERKEZ",
        "STREET": "YENİCE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "MERKEZ",
        "STREET": "YENİYURT MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "MERKEZ",
        "STREET": "YEŞİLIRMAK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "MERKEZ",
        "STREET": "YEŞİLOVA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "MERKEZ",
        "STREET": "YEŞİLYURT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇAMALTI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇAMAĞZI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇAMDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇAMLIBEL-AKIN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇAMLIBEL-AKTEPE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇAMLIBEL-BEHRAM MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇAMLIBEL-KARASU MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇAMLIBEL-YUNUSEMRE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇAMLIBEL-ÇEKEREK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇAMLIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇAT-CUMHURİYET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇAT-KARŞIYAKA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇAT-MERKEZ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇATALKAYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇAY MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇAYÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇERDİĞİN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇERÇİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇUBUKLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇÖKELİKKIŞLA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇÖRDÜK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇÖREĞİBÜYÜK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "MERKEZ",
        "STREET": "ÖRTMELİÖNÜ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "MERKEZ",
        "STREET": "İHSANİYE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "MERKEZ",
        "STREET": "ŞEHİTLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "MERKEZ",
        "STREET": "ŞENKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "MERKEZ",
        "STREET": "TOKAT T TİPİ KAPALI VE AÇIK CEZA İNFAZ KURUMU",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "TURHAL",
        "STREET": "AKBUĞDAY KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "TURHAL",
        "STREET": "AKÇATARLA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "TURHAL",
        "STREET": "ARAPÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "TURHAL",
        "STREET": "ARZUPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "TURHAL",
        "STREET": "ATAKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "TURHAL",
        "STREET": "AYRANPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "TURHAL",
        "STREET": "AĞCAŞAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "TURHAL",
        "STREET": "BAHAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "TURHAL",
        "STREET": "BAHÇEBAŞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "TURHAL",
        "STREET": "BAĞLARPINARI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "TURHAL",
        "STREET": "BORSA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "TURHAL",
        "STREET": "BOYACILAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "TURHAL",
        "STREET": "BUZLUK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "TURHAL",
        "STREET": "CAMİKEBİR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "TURHAL",
        "STREET": "CELAL MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "TURHAL",
        "STREET": "CUMHURİYET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "TURHAL",
        "STREET": "DAZYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "TURHAL",
        "STREET": "DERBENTÇİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "TURHAL",
        "STREET": "DÖKMETEPE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "TURHAL",
        "STREET": "ELALMIŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "TURHAL",
        "STREET": "EMEK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "TURHAL",
        "STREET": "ERENLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "TURHAL",
        "STREET": "ERİKLİTEKKE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "TURHAL",
        "STREET": "FATİH MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "TURHAL",
        "STREET": "GAZİ OSMAN PAŞA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "TURHAL",
        "STREET": "GÖKDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "TURHAL",
        "STREET": "GÜNDOĞDU MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "TURHAL",
        "STREET": "GÜNEŞ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "TURHAL",
        "STREET": "GÜRSEL MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "TURHAL",
        "STREET": "HACILAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "TURHAL",
        "STREET": "HAMAM MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "TURHAL",
        "STREET": "HAMİDE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "TURHAL",
        "STREET": "HASANLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "TURHAL",
        "STREET": "KALAYCIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "TURHAL",
        "STREET": "KAMALI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "TURHAL",
        "STREET": "KARKIN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "TURHAL",
        "STREET": "KAT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "TURHAL",
        "STREET": "KAYACIK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "TURHAL",
        "STREET": "KAZANCI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "TURHAL",
        "STREET": "KAZIM KARABEKİR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "TURHAL",
        "STREET": "KIZKAYASI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "TURHAL",
        "STREET": "KORULUK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "TURHAL",
        "STREET": "KOVA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "TURHAL",
        "STREET": "KUYTUL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "TURHAL",
        "STREET": "KUZALAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "TURHAL",
        "STREET": "KUŞOTURAĞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "TURHAL",
        "STREET": "MARAŞAL FEVZİ ÇAKMAK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "TURHAL",
        "STREET": "MEVLANA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "TURHAL",
        "STREET": "MEYDAN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "TURHAL",
        "STREET": "MÜFTÜ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "TURHAL",
        "STREET": "MİMAR SİNAN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "TURHAL",
        "STREET": "NECİP KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "TURHAL",
        "STREET": "NURKAVAK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "TURHAL",
        "STREET": "ORMANÖZÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "TURHAL",
        "STREET": "OSMANGAZİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "TURHAL",
        "STREET": "PAZAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "TURHAL",
        "STREET": "RAY MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "TURHAL",
        "STREET": "SAMURÇAY KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "TURHAL",
        "STREET": "SARIKAYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "TURHAL",
        "STREET": "SARIÇİÇEK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "TURHAL",
        "STREET": "SÜTLÜCE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "TURHAL",
        "STREET": "TATLICAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "TURHAL",
        "STREET": "TAŞLIHÖYÜK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "TURHAL",
        "STREET": "ULUTEPE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "TURHAL",
        "STREET": "ULUÖZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "TURHAL",
        "STREET": "VARVARA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "TURHAL",
        "STREET": "YAVUZ SELİM MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "TURHAL",
        "STREET": "YAZITEPE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "TURHAL",
        "STREET": "YAĞLIALAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "TURHAL",
        "STREET": "YENİCELER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "TURHAL",
        "STREET": "YENİKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "TURHAL",
        "STREET": "YENİSU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "TURHAL",
        "STREET": "YEŞİLALAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "TURHAL",
        "STREET": "YEŞİLIRMAK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "TURHAL",
        "STREET": "YUNUS EMRE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "TURHAL",
        "STREET": "ÇAMLICA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "TURHAL",
        "STREET": "ÇARIKSIZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "TURHAL",
        "STREET": "ÇAYIRALTI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "TURHAL",
        "STREET": "ÇAYLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "TURHAL",
        "STREET": "ÇEVLİKLER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "TURHAL",
        "STREET": "ÇİVRİL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "TURHAL",
        "STREET": "ÜÇYOL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "TURHAL",
        "STREET": "ŞATROBA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "TURHAL",
        "STREET": "ŞENYURT-ASARCIK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "TURHAL",
        "STREET": "ŞENYURT-KARAEVLİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "TURHAL",
        "STREET": "ŞENYURT-ORTAKÖY MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "TURHAL",
        "STREET": "ŞENYURT-ÜÇGÖZEN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "YEŞİLYURT",
        "STREET": "100. YIL MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "YEŞİLYURT",
        "STREET": "BAHÇEBAŞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "YEŞİLYURT",
        "STREET": "BÜĞET KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "YEŞİLYURT",
        "STREET": "DAMLALI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "YEŞİLYURT",
        "STREET": "DOĞLACIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "YEŞİLYURT",
        "STREET": "EKİNLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "YEŞİLYURT",
        "STREET": "GAZİOSMANPAŞA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "YEŞİLYURT",
        "STREET": "GÜNDOĞAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "YEŞİLYURT",
        "STREET": "KARACAÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "YEŞİLYURT",
        "STREET": "KARAGÖZGÖLLÜALAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "YEŞİLYURT",
        "STREET": "KARAOLUK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "YEŞİLYURT",
        "STREET": "KAVUNLUK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "YEŞİLYURT",
        "STREET": "KUŞÇU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "YEŞİLYURT",
        "STREET": "SEKÜCEK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "YEŞİLYURT",
        "STREET": "SİVRİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "YEŞİLYURT",
        "STREET": "TÜRKMENLER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "YEŞİLYURT",
        "STREET": "YAĞMUR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "YEŞİLYURT",
        "STREET": "YENİKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "YEŞİLYURT",
        "STREET": "ÇERKEZ DANİŞMENT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "YEŞİLYURT",
        "STREET": "ÇIKRIK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "YEŞİLYURT",
        "STREET": "ÇIRDAK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "YEŞİLYURT",
        "STREET": "ŞEHİTLER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ZİLE",
        "STREET": "ACIPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ZİLE",
        "STREET": "ACISU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ZİLE",
        "STREET": "AKDOĞAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ZİLE",
        "STREET": "AKGÜLLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ZİLE",
        "STREET": "AKKILIÇ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ZİLE",
        "STREET": "ALACAMESCİTZİR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ZİLE",
        "STREET": "ALAMESCİTBALA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ZİLE",
        "STREET": "ALAYURT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ZİLE",
        "STREET": "ALIÇÖZÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ZİLE",
        "STREET": "ALİBAĞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ZİLE",
        "STREET": "ALİHOCA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ZİLE",
        "STREET": "ALİKADI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ZİLE",
        "STREET": "ARMUTALAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ZİLE",
        "STREET": "AYVALI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ZİLE",
        "STREET": "AĞCAKEÇİLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ZİLE",
        "STREET": "AĞILCIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ZİLE",
        "STREET": "BAHÇELİEVLER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ZİLE",
        "STREET": "BAYIR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ZİLE",
        "STREET": "BELKAYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ZİLE",
        "STREET": "BELPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ZİLE",
        "STREET": "BOLDACI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ZİLE",
        "STREET": "BÜYÜKAKÖZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ZİLE",
        "STREET": "BÜYÜKKARAYÜN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ZİLE",
        "STREET": "BÜYÜKKOZLUCA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ZİLE",
        "STREET": "BÜYÜKÖZLÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ZİLE",
        "STREET": "BİNBAŞIOĞLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ZİLE",
        "STREET": "CEDİT MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ZİLE",
        "STREET": "DEREBAŞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ZİLE",
        "STREET": "DUTLUPINAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ZİLE",
        "STREET": "DİNÇERLER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ZİLE",
        "STREET": "EDE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ZİLE",
        "STREET": "ELMACIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ZİLE",
        "STREET": "EMİRÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ZİLE",
        "STREET": "ESKİDAĞİÇİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ZİLE",
        "STREET": "ESKİDERBENT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ZİLE",
        "STREET": "EVRENKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ZİLE",
        "STREET": "FATİH KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ZİLE",
        "STREET": "GÖLCÜK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ZİLE",
        "STREET": "GÖÇENLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ZİLE",
        "STREET": "GÜBLÜCE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ZİLE",
        "STREET": "GÜMÜŞKAŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ZİLE",
        "STREET": "GÜNGÖRMEZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ZİLE",
        "STREET": "GÜZELBEYLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ZİLE",
        "STREET": "HACILAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ZİLE",
        "STREET": "HACIMEHMET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ZİLE",
        "STREET": "HARAMİKIŞLA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ZİLE",
        "STREET": "HASANAĞA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ZİLE",
        "STREET": "HATİPPINARI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ZİLE",
        "STREET": "KAHYA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ZİLE",
        "STREET": "KARABALÇIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ZİLE",
        "STREET": "KARACAÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ZİLE",
        "STREET": "KARAKAYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ZİLE",
        "STREET": "KARAKUZU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ZİLE",
        "STREET": "KARAŞEYH KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ZİLE",
        "STREET": "KARŞIPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ZİLE",
        "STREET": "KARŞIYAKA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ZİLE",
        "STREET": "KAZIKLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ZİLE",
        "STREET": "KEPEZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ZİLE",
        "STREET": "KERVANSARAY KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ZİLE",
        "STREET": "KIRLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ZİLE",
        "STREET": "KIZILCA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ZİLE",
        "STREET": "KORUCUK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ZİLE",
        "STREET": "KOZDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ZİLE",
        "STREET": "KOÇAŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ZİLE",
        "STREET": "KURUPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ZİLE",
        "STREET": "KURUÇAY KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ZİLE",
        "STREET": "KURŞUNLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ZİLE",
        "STREET": "KUZALAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ZİLE",
        "STREET": "KÖYLÜÜNÜRÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ZİLE",
        "STREET": "KÜÇÜKAKÖZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ZİLE",
        "STREET": "KÜÇÜKKARAYÜN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ZİLE",
        "STREET": "KÜÇÜKKOZLUCA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ZİLE",
        "STREET": "KÜÇÜKÖZLÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ZİLE",
        "STREET": "KİREÇLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ZİLE",
        "STREET": "KİSLİK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ZİLE",
        "STREET": "MİNAREİKEBİR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ZİLE",
        "STREET": "MİNAREİSAĞIR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ZİLE",
        "STREET": "NAKKAŞ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ZİLE",
        "STREET": "NARLIKIŞLA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ZİLE",
        "STREET": "OLUKMAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ZİLE",
        "STREET": "ORTA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ZİLE",
        "STREET": "OSMANPINARI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ZİLE",
        "STREET": "PALANLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ZİLE",
        "STREET": "REŞADİYE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ZİLE",
        "STREET": "SALUR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ZİLE",
        "STREET": "SARAÇ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ZİLE",
        "STREET": "SAVCU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ZİLE",
        "STREET": "SEKİKIŞLA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ZİLE",
        "STREET": "SELAMET KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ZİLE",
        "STREET": "SOFULAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ZİLE",
        "STREET": "SÖĞÜTÖZÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ZİLE",
        "STREET": "SÜLEYMANİYE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ZİLE",
        "STREET": "TAŞKIRAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ZİLE",
        "STREET": "TEMECÜK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ZİLE",
        "STREET": "TURGUTALP KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ZİLE",
        "STREET": "UZUNKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ZİLE",
        "STREET": "UZUNÖZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ZİLE",
        "STREET": "UĞURLUÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ZİLE",
        "STREET": "YALINYAZI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ZİLE",
        "STREET": "YALNIZKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ZİLE",
        "STREET": "YAPALAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ZİLE",
        "STREET": "YARAŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ZİLE",
        "STREET": "YAYLAKENT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ZİLE",
        "STREET": "YAYLAYOLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ZİLE",
        "STREET": "YENİDAĞİÇİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ZİLE",
        "STREET": "YENİDERBENT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ZİLE",
        "STREET": "YENİKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ZİLE",
        "STREET": "YEŞİLCE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ZİLE",
        "STREET": "YILDIZTEPE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ZİLE",
        "STREET": "YUNUSEMRE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ZİLE",
        "STREET": "YÜCEPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ZİLE",
        "STREET": "YÜNLÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ZİLE",
        "STREET": "ZİLE M TİPİ KAPALI CEZA İNFAZ KURUMU",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ZİLE",
        "STREET": "ZİNCİRLİ ÜLYA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ZİLE",
        "STREET": "ZİNCİRLİSÜFLA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ZİLE",
        "STREET": "ÇAKIRCALI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ZİLE",
        "STREET": "ÇAMDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ZİLE",
        "STREET": "ÇAPAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ZİLE",
        "STREET": "ÇAYIR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ZİLE",
        "STREET": "ÇAYIROLUĞU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ZİLE",
        "STREET": "ÇELTEK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ZİLE",
        "STREET": "ÇİFTLİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ZİLE",
        "STREET": "ÇİÇEKPINARI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ZİLE",
        "STREET": "ÖZYURT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ZİLE",
        "STREET": "ÜTÜK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ZİLE",
        "STREET": "ÜYÜK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ZİLE",
        "STREET": "ÜÇKAYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ZİLE",
        "STREET": "ÜÇKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ZİLE",
        "STREET": "İMİRTOLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ZİLE",
        "STREET": "İSTASYON MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ZİLE",
        "STREET": "İĞDİR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ZİLE",
        "STREET": "ŞEYHALİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ZİLE",
        "STREET": "ŞEYHKOLU MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ZİLE",
        "STREET": "ŞEYHKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "TOKAT",
        "DISTRICT": "ZİLE",
        "STREET": "ŞEYHNUSRETTİN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "AKÇAABAT",
        "STREET": "ACISU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "AKÇAABAT",
        "STREET": "ADACIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "AKÇAABAT",
        "STREET": "AKDAMAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "AKÇAABAT",
        "STREET": "AKPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "AKÇAABAT",
        "STREET": "AKÇAKALE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "AKÇAABAT",
        "STREET": "AKÇAKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "AKÇAABAT",
        "STREET": "AKÖREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "AKÇAABAT",
        "STREET": "ALSANCAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "AKÇAABAT",
        "STREET": "AMBARCIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "AKÇAABAT",
        "STREET": "ARPACILI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "AKÇAABAT",
        "STREET": "AYDINKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "AKÇAABAT",
        "STREET": "AĞAÇLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "AKÇAABAT",
        "STREET": "BOZDOĞAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "AKÇAABAT",
        "STREET": "CEVİZLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "AKÇAABAT",
        "STREET": "CEVİZLİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "AKÇAABAT",
        "STREET": "DARICA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "AKÇAABAT",
        "STREET": "DEMİRCİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "AKÇAABAT",
        "STREET": "DEMİRKAPI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "AKÇAABAT",
        "STREET": "DEMİRTAŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "AKÇAABAT",
        "STREET": "DERECİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "AKÇAABAT",
        "STREET": "DOĞANKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "AKÇAABAT",
        "STREET": "DÖRTYOL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "AKÇAABAT",
        "STREET": "DÜRBİNAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "AKÇAABAT",
        "STREET": "ESENTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "AKÇAABAT",
        "STREET": "ESKİKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "AKÇAABAT",
        "STREET": "FINDIKLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "AKÇAABAT",
        "STREET": "FISTIKLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "AKÇAABAT",
        "STREET": "GÜMÜŞLÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "AKÇAABAT",
        "STREET": "HELVACI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "AKÇAABAT",
        "STREET": "IŞIKLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "AKÇAABAT",
        "STREET": "KALEÖNÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "AKÇAABAT",
        "STREET": "KARAÇAYIR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "AKÇAABAT",
        "STREET": "KARPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "AKÇAABAT",
        "STREET": "KAVAKLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "AKÇAABAT",
        "STREET": "KAYALAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "AKÇAABAT",
        "STREET": "KEMALİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "AKÇAABAT",
        "STREET": "KOÇLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "AKÇAABAT",
        "STREET": "KURUÇAM MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "AKÇAABAT",
        "STREET": "KİRAZLIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "AKÇAABAT",
        "STREET": "MADEN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "AKÇAABAT",
        "STREET": "MERSİN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "AKÇAABAT",
        "STREET": "MEYDANKAYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "AKÇAABAT",
        "STREET": "MEŞELİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "AKÇAABAT",
        "STREET": "NEFSİPULATHANE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "AKÇAABAT",
        "STREET": "ORTA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "AKÇAABAT",
        "STREET": "ORTAALAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "AKÇAABAT",
        "STREET": "ORTAKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "AKÇAABAT",
        "STREET": "OSMANBABA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "AKÇAABAT",
        "STREET": "SALACIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "AKÇAABAT",
        "STREET": "SARICA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "AKÇAABAT",
        "STREET": "SARITAŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "AKÇAABAT",
        "STREET": "SERTKAYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "AKÇAABAT",
        "STREET": "SÖĞÜTLÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "AKÇAABAT",
        "STREET": "TATLISU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "AKÇAABAT",
        "STREET": "TÜTÜNCÜLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "AKÇAABAT",
        "STREET": "UÇARSU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "AKÇAABAT",
        "STREET": "YAYLACIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "AKÇAABAT",
        "STREET": "YENİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "AKÇAABAT",
        "STREET": "YENİKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "AKÇAABAT",
        "STREET": "YEŞİLTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "AKÇAABAT",
        "STREET": "YEŞİLYURT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "AKÇAABAT",
        "STREET": "YILDIZLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "AKÇAABAT",
        "STREET": "ZAFERLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "AKÇAABAT",
        "STREET": "ÇAMLICA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "AKÇAABAT",
        "STREET": "ÇAMLIDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "AKÇAABAT",
        "STREET": "ÇINARLIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "AKÇAABAT",
        "STREET": "ÇOLAKLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "AKÇAABAT",
        "STREET": "ÇUKURCA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "AKÇAABAT",
        "STREET": "ÇİLEKLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "AKÇAABAT",
        "STREET": "ÇİÇEKLİDÜZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "AKÇAABAT",
        "STREET": "ÖZAKDAMAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "AKÇAABAT",
        "STREET": "ÖZDEMİRCİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "AKÇAABAT",
        "STREET": "ŞİNİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "ARAKLI",
        "STREET": "AYTAŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "ARAKLI",
        "STREET": "AYVADERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "ARAKLI",
        "STREET": "BAHÇECİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "ARAKLI",
        "STREET": "BEREKETLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "ARAKLI",
        "STREET": "BUZLUCA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "ARAKLI",
        "STREET": "BİRLİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "ARAKLI",
        "STREET": "DEĞİRMENCİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "ARAKLI",
        "STREET": "ERENLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "ARAKLI",
        "STREET": "ERİKLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "ARAKLI",
        "STREET": "HALİLLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "ARAKLI",
        "STREET": "HASKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "ARAKLI",
        "STREET": "HÜRRİYET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "ARAKLI",
        "STREET": "KALECİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "ARAKLI",
        "STREET": "KARATEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "ARAKLI",
        "STREET": "KARŞIYAKA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "ARAKLI",
        "STREET": "KAYACIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "ARAKLI",
        "STREET": "KAYAİÇİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "ARAKLI",
        "STREET": "KAYMAKLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "ARAKLI",
        "STREET": "KESTANELİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "ARAKLI",
        "STREET": "KÖPRÜÜSTÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "ARAKLI",
        "STREET": "KÜKÜRTLÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "ARAKLI",
        "STREET": "MERKEZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "ARAKLI",
        "STREET": "MERKEZKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "ARAKLI",
        "STREET": "ORTAKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "ARAKLI",
        "STREET": "PERVANE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "ARAKLI",
        "STREET": "SULAKYURT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "ARAKLI",
        "STREET": "SULARBAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "ARAKLI",
        "STREET": "TAŞGEÇİT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "ARAKLI",
        "STREET": "TAŞTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "ARAKLI",
        "STREET": "TAŞÖNÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "ARAKLI",
        "STREET": "TURNALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "ARAKLI",
        "STREET": "TÜRKELİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "ARAKLI",
        "STREET": "YALIBOYU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "ARAKLI",
        "STREET": "YASSIKAYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "ARAKLI",
        "STREET": "YENİKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "ARAKLI",
        "STREET": "YEŞİLCE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "ARAKLI",
        "STREET": "YEŞİLKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "ARAKLI",
        "STREET": "YEŞİLYURT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "ARAKLI",
        "STREET": "YILDIZLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "ARAKLI",
        "STREET": "YOLGÖREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "ARAKLI",
        "STREET": "YONCALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "ARAKLI",
        "STREET": "YÜCEYURT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "ARAKLI",
        "STREET": "YİĞİTÖZÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "ARAKLI",
        "STREET": "ÇAMLICA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "ARAKLI",
        "STREET": "ÇAMLIKTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "ARAKLI",
        "STREET": "ÇANKAYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "ARAKLI",
        "STREET": "ÇUKURÇAYIR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "ARAKLI",
        "STREET": "ÇİFTEPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "ARAKLI",
        "STREET": "ÖZGEN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "ARAKLI",
        "STREET": "İYİSU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "ARSİN",
        "STREET": "ATAYURT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "ARSİN",
        "STREET": "BAŞDURAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "ARSİN",
        "STREET": "CUDİBEY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "ARSİN",
        "STREET": "CUMHURİYET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "ARSİN",
        "STREET": "DİLEK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "ARSİN",
        "STREET": "ELMAALAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "ARSİN",
        "STREET": "FATİH MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "ARSİN",
        "STREET": "FINDIKLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "ARSİN",
        "STREET": "GÖLCÜK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "ARSİN",
        "STREET": "GÖLGELİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "ARSİN",
        "STREET": "GÜNEYCE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "ARSİN",
        "STREET": "GÜZELYALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "ARSİN",
        "STREET": "HARMANLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "ARSİN",
        "STREET": "IŞIKLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "ARSİN",
        "STREET": "KARACA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "ARSİN",
        "STREET": "KONAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "ARSİN",
        "STREET": "KUZGUNCUK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "ARSİN",
        "STREET": "NUROĞLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "ARSİN",
        "STREET": "OĞUZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "ARSİN",
        "STREET": "YALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "ARSİN",
        "STREET": "YENİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "ARSİN",
        "STREET": "YENİKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "ARSİN",
        "STREET": "YEŞİLCE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "ARSİN",
        "STREET": "YEŞİLKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "ARSİN",
        "STREET": "YEŞİLTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "ARSİN",
        "STREET": "YEŞİLYALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "ARSİN",
        "STREET": "YOLAÇ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "ARSİN",
        "STREET": "YOLÜSTÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "ARSİN",
        "STREET": "ÇARDAKLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "ARSİN",
        "STREET": "ÇUBUKLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "ARSİN",
        "STREET": "ÇİÇEKLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "ARSİN",
        "STREET": "ÖRNEK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "ARSİN",
        "STREET": "ÖZLÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "ARSİN",
        "STREET": "ÜÇPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "ARSİN",
        "STREET": "İŞHAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "ARSİN",
        "STREET": "ŞENKAYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "BEŞİKDÜZÜ",
        "STREET": "ADACIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "BEŞİKDÜZÜ",
        "STREET": "AKKESE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "BEŞİKDÜZÜ",
        "STREET": "AKSAKLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "BEŞİKDÜZÜ",
        "STREET": "ANBARLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "BEŞİKDÜZÜ",
        "STREET": "ARDIÇATAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "BEŞİKDÜZÜ",
        "STREET": "AĞAÇLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "BEŞİKDÜZÜ",
        "STREET": "BAYIRKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "BEŞİKDÜZÜ",
        "STREET": "BEŞİKDAĞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "BEŞİKDÜZÜ",
        "STREET": "BEŞİKDÜZÜ T TİPİ KAPALI CEZA İNFAZ KURUMU",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "BEŞİKDÜZÜ",
        "STREET": "BOZLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "BEŞİKDÜZÜ",
        "STREET": "CUMHURİYET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "BEŞİKDÜZÜ",
        "STREET": "DAĞLICA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "BEŞİKDÜZÜ",
        "STREET": "DENİZLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "BEŞİKDÜZÜ",
        "STREET": "DOLANLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "BEŞİKDÜZÜ",
        "STREET": "DUYGULU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "BEŞİKDÜZÜ",
        "STREET": "FATİH MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "BEŞİKDÜZÜ",
        "STREET": "GÜRGENLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "BEŞİKDÜZÜ",
        "STREET": "HÜNERLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "BEŞİKDÜZÜ",
        "STREET": "KALEGÜNEY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "BEŞİKDÜZÜ",
        "STREET": "KORKUTHAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "BEŞİKDÜZÜ",
        "STREET": "KUTLUCA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "BEŞİKDÜZÜ",
        "STREET": "NEFSİŞARLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "BEŞİKDÜZÜ",
        "STREET": "OĞUZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "BEŞİKDÜZÜ",
        "STREET": "RESULLÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "BEŞİKDÜZÜ",
        "STREET": "SAYVANCIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "BEŞİKDÜZÜ",
        "STREET": "SEYİTAHMET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "BEŞİKDÜZÜ",
        "STREET": "TAKAZLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "BEŞİKDÜZÜ",
        "STREET": "VARDALLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "BEŞİKDÜZÜ",
        "STREET": "YENİCAMİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "BEŞİKDÜZÜ",
        "STREET": "YEŞİLKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "BEŞİKDÜZÜ",
        "STREET": "ZEMBEREK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "BEŞİKDÜZÜ",
        "STREET": "ÇAKIRLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "BEŞİKDÜZÜ",
        "STREET": "ÇEŞMEÖNÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "BEŞİKDÜZÜ",
        "STREET": "ÇITLAKLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "BEŞİKDÜZÜ",
        "STREET": "ŞAHMELİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "DERNEKPAZARI",
        "STREET": "AKKÖSE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "DERNEKPAZARI",
        "STREET": "GÜLEN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "DERNEKPAZARI",
        "STREET": "GÜNEBAKAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "DERNEKPAZARI",
        "STREET": "GÜNEY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "DERNEKPAZARI",
        "STREET": "KONDU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "DERNEKPAZARI",
        "STREET": "MERKEZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "DERNEKPAZARI",
        "STREET": "ORMANCIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "DERNEKPAZARI",
        "STREET": "TAŞÇILAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "DERNEKPAZARI",
        "STREET": "TÜFEKÇİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "DERNEKPAZARI",
        "STREET": "YENİCAMİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "DERNEKPAZARI",
        "STREET": "YENİCE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "DERNEKPAZARI",
        "STREET": "ZİNCİRLİTAŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "DERNEKPAZARI",
        "STREET": "ÇALIŞANLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "DERNEKPAZARI",
        "STREET": "ÇAYIRBAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "DÜZKÖY",
        "STREET": "ALAZLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "DÜZKÖY",
        "STREET": "AYKUT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "DÜZKÖY",
        "STREET": "BÜYÜK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "DÜZKÖY",
        "STREET": "CEVİZLİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "DÜZKÖY",
        "STREET": "DÜZALAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "DÜZKÖY",
        "STREET": "GÖKÇELER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "DÜZKÖY",
        "STREET": "GÜRGENDAĞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "DÜZKÖY",
        "STREET": "KÜÇÜKTEPEKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "DÜZKÖY",
        "STREET": "ORTA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "DÜZKÖY",
        "STREET": "TAŞOCAĞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "DÜZKÖY",
        "STREET": "TEPECİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "DÜZKÖY",
        "STREET": "YENİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "DÜZKÖY",
        "STREET": "YERLİCE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "DÜZKÖY",
        "STREET": "ÇAL CAMİLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "DÜZKÖY",
        "STREET": "ÇAL ORTA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "DÜZKÖY",
        "STREET": "ÇAL ÇEŞMELER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "DÜZKÖY",
        "STREET": "ÇAYIRBAĞI DOĞANKAYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "DÜZKÖY",
        "STREET": "ÇAYIRBAĞI GÜLCANA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "DÜZKÖY",
        "STREET": "ÇAYIRBAĞI ORTA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "DÜZKÖY",
        "STREET": "ÇAYIRBAĞI YUSUFLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "DÜZKÖY",
        "STREET": "ÇAYIRBAĞI ZELİHA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "DÜZKÖY",
        "STREET": "ÇİĞDEMLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "HAYRAT",
        "STREET": "AĞAÇLI DÜZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "HAYRAT",
        "STREET": "BALABAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "HAYRAT",
        "STREET": "BALABAN MERKEZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "HAYRAT",
        "STREET": "CUMHURİYET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "HAYRAT",
        "STREET": "DAĞÖNÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "HAYRAT",
        "STREET": "DEREYURT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "HAYRAT",
        "STREET": "FATİH MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "HAYRAT",
        "STREET": "GEÇİTLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "HAYRAT",
        "STREET": "GÖKSEL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "HAYRAT",
        "STREET": "GÖRGÜLÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "HAYRAT",
        "STREET": "GÜLDEREN FATİH MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "HAYRAT",
        "STREET": "GÜLDEREN MERKEZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "HAYRAT",
        "STREET": "HÜRRİYET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "HAYRAT",
        "STREET": "KILAVUZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "HAYRAT",
        "STREET": "KURTULUŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "HAYRAT",
        "STREET": "KÖYCEĞİZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "HAYRAT",
        "STREET": "MERKEZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "HAYRAT",
        "STREET": "MEYDANLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "HAYRAT",
        "STREET": "ONUR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "HAYRAT",
        "STREET": "PAZARÖNÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "HAYRAT",
        "STREET": "PINARCA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "HAYRAT",
        "STREET": "SARMAŞIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "HAYRAT",
        "STREET": "TAFLANCIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "HAYRAT",
        "STREET": "TOPAKLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "HAYRAT",
        "STREET": "YARLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "HAYRAT",
        "STREET": "YENİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "HAYRAT",
        "STREET": "YENİKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "HAYRAT",
        "STREET": "YILDIRIMLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "HAYRAT",
        "STREET": "YIRCA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "HAYRAT",
        "STREET": "ÇAMLITEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "HAYRAT",
        "STREET": "ÇAYCILAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "HAYRAT",
        "STREET": "ÇAĞLAYAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "HAYRAT",
        "STREET": "ŞEHİTLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "HAYRAT",
        "STREET": "ŞİŞLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "KÖPRÜBAŞI",
        "STREET": "AKPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "KÖPRÜBAŞI",
        "STREET": "ARPALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "KÖPRÜBAŞI",
        "STREET": "BEŞKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "KÖPRÜBAŞI",
        "STREET": "BÜYÜKDOĞANLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "KÖPRÜBAŞI",
        "STREET": "FİDANLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "KÖPRÜBAŞI",
        "STREET": "GÜNDOĞAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "KÖPRÜBAŞI",
        "STREET": "GÜNEŞLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "KÖPRÜBAŞI",
        "STREET": "PINARBAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "KÖPRÜBAŞI",
        "STREET": "YAĞMURLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "KÖPRÜBAŞI",
        "STREET": "ÇİFTEKÖPRÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "MAÇKA",
        "STREET": "AKARSU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "MAÇKA",
        "STREET": "AKMESCİT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "MAÇKA",
        "STREET": "ALATAŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "MAÇKA",
        "STREET": "ALAÇAM MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "MAÇKA",
        "STREET": "ALTINDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "MAÇKA",
        "STREET": "ANAYURT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "MAÇKA",
        "STREET": "ARDIÇLIYAYLA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "MAÇKA",
        "STREET": "ARMAĞAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "MAÇKA",
        "STREET": "ATASU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "MAÇKA",
        "STREET": "BAHÇEKAYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "MAÇKA",
        "STREET": "BAKIMLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "MAÇKA",
        "STREET": "BAKIRCILAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "MAÇKA",
        "STREET": "BARIŞLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "MAÇKA",
        "STREET": "BAĞIŞLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "MAÇKA",
        "STREET": "BAŞAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "MAÇKA",
        "STREET": "COŞANDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "MAÇKA",
        "STREET": "DİKKAYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "MAÇKA",
        "STREET": "ERGİNKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "MAÇKA",
        "STREET": "ESİROĞLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "MAÇKA",
        "STREET": "FATİH MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "MAÇKA",
        "STREET": "GAYRETLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "MAÇKA",
        "STREET": "GÜNAY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "MAÇKA",
        "STREET": "GÜNEY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "MAÇKA",
        "STREET": "GÜRGENAĞAÇ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "MAÇKA",
        "STREET": "GÜZELCE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "MAÇKA",
        "STREET": "GÜZELYAYLA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "MAÇKA",
        "STREET": "HAMSİKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "MAÇKA",
        "STREET": "HIZARLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "MAÇKA",
        "STREET": "KAPIKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "MAÇKA",
        "STREET": "KAYNARCA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "MAÇKA",
        "STREET": "KIRANTAŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "MAÇKA",
        "STREET": "KONAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "MAÇKA",
        "STREET": "KOZAĞAÇ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "MAÇKA",
        "STREET": "KUŞÇU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "MAÇKA",
        "STREET": "KÖPRÜYANI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "MAÇKA",
        "STREET": "KİREMİTLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "MAÇKA",
        "STREET": "MATARACI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "MAÇKA",
        "STREET": "MERKEZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "MAÇKA",
        "STREET": "OCAKLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "MAÇKA",
        "STREET": "ORMANÜSTÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "MAÇKA",
        "STREET": "ORMANİÇİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "MAÇKA",
        "STREET": "ORTAKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "MAÇKA",
        "STREET": "OĞULAĞAÇ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "MAÇKA",
        "STREET": "REŞADİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "MAÇKA",
        "STREET": "SEVİNÇ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "MAÇKA",
        "STREET": "SINDIRAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "MAÇKA",
        "STREET": "SUKENARI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "MAÇKA",
        "STREET": "TEMELLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "MAÇKA",
        "STREET": "YAYLABAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "MAÇKA",
        "STREET": "YAZILITAŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "MAÇKA",
        "STREET": "YAZLIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "MAÇKA",
        "STREET": "YENİKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "MAÇKA",
        "STREET": "YEŞİLTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "MAÇKA",
        "STREET": "YEŞİLYURT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "MAÇKA",
        "STREET": "YUKARIKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "MAÇKA",
        "STREET": "YÜZÜNCÜYIL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "MAÇKA",
        "STREET": "ZAFERLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "MAÇKA",
        "STREET": "ÇAMLIDÜZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "MAÇKA",
        "STREET": "ÇATAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "MAÇKA",
        "STREET": "ÇAYIRLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "MAÇKA",
        "STREET": "ÇEŞMELER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "MAÇKA",
        "STREET": "ÇIRALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "MAÇKA",
        "STREET": "ÖRNEKALAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "MAÇKA",
        "STREET": "ÖĞÜTLÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "MAÇKA",
        "STREET": "ÜÇGEDİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "MAÇKA",
        "STREET": "ŞİMŞİRLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "OF",
        "STREET": "AĞAÇBAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "OF",
        "STREET": "AĞAÇSEVEN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "OF",
        "STREET": "AŞAĞIKIŞLACIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "OF",
        "STREET": "BALLICA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "OF",
        "STREET": "BARIŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "OF",
        "STREET": "BAYIRCA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "OF",
        "STREET": "BAŞKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "OF",
        "STREET": "BÖLÜMLÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "OF",
        "STREET": "BİRLİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "OF",
        "STREET": "CUMAPAZARI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "OF",
        "STREET": "CUMHURİYET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "OF",
        "STREET": "DARILI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "OF",
        "STREET": "DAĞALAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "OF",
        "STREET": "DEREKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "OF",
        "STREET": "DOĞANÇAY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "OF",
        "STREET": "DUMLUSU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "OF",
        "STREET": "ERENKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "OF",
        "STREET": "ESENKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "OF",
        "STREET": "ESKİPAZAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "OF",
        "STREET": "FINDIKOBA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "OF",
        "STREET": "GÖKÇEOBA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "OF",
        "STREET": "GÜMÜŞÖREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "OF",
        "STREET": "GÜRESEN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "OF",
        "STREET": "GÜRPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "OF",
        "STREET": "KABAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "OF",
        "STREET": "KARABUDAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "OF",
        "STREET": "KAVAKPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "OF",
        "STREET": "KAZANÇLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "OF",
        "STREET": "KELER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "OF",
        "STREET": "KIYIBOYU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "OF",
        "STREET": "KIYICIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "OF",
        "STREET": "KORKUT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "OF",
        "STREET": "KORUCUK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "OF",
        "STREET": "KUMLUDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "OF",
        "STREET": "KİRAZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "OF",
        "STREET": "KİREÇLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "OF",
        "STREET": "OVACIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "OF",
        "STREET": "PINARALTI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "OF",
        "STREET": "SARAYKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "OF",
        "STREET": "SARAÇLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "OF",
        "STREET": "SARIBEY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "OF",
        "STREET": "SARIKAYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "OF",
        "STREET": "SEFAKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "OF",
        "STREET": "SERİNCE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "OF",
        "STREET": "SIRAAĞAÇ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "OF",
        "STREET": "SOĞUKPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "OF",
        "STREET": "SUGELDİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "OF",
        "STREET": "SULAKLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "OF",
        "STREET": "SÖĞÜTLÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "OF",
        "STREET": "SİVRİCE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "OF",
        "STREET": "TAVŞANLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "OF",
        "STREET": "TEKOBA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "OF",
        "STREET": "ULUAĞAÇ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "OF",
        "STREET": "UĞURLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "OF",
        "STREET": "YANIKTAŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "OF",
        "STREET": "YAZLIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "OF",
        "STREET": "YEMİŞALAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "OF",
        "STREET": "YENİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "OF",
        "STREET": "YUKARI KIŞLACIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "OF",
        "STREET": "ÇALTILI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "OF",
        "STREET": "ÇAMLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "OF",
        "STREET": "ÇAMLITEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "OF",
        "STREET": "ÇATALDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "OF",
        "STREET": "ÇATALSÖĞÜT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "OF",
        "STREET": "ÇUKUROVA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "OF",
        "STREET": "ÖRTÜLÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "OF",
        "STREET": "İKİDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "OF",
        "STREET": "İRFANLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "ORTAHİSAR",
        "STREET": "1 NOLU BEŞİRLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "ORTAHİSAR",
        "STREET": "1 NOLU BOSTANCI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "ORTAHİSAR",
        "STREET": "1 NOLU ERDOĞDU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "ORTAHİSAR",
        "STREET": "2 NOLU BEŞİRLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "ORTAHİSAR",
        "STREET": "2 NOLU BOSTANCI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "ORTAHİSAR",
        "STREET": "2 NOLU ERDOĞDU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "ORTAHİSAR",
        "STREET": "3 NOLU ERDOĞDU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "ORTAHİSAR",
        "STREET": "AKKAYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "ORTAHİSAR",
        "STREET": "AKOLUK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "ORTAHİSAR",
        "STREET": "AKTOPRAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "ORTAHİSAR",
        "STREET": "AKYAZI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "ORTAHİSAR",
        "STREET": "AYDINLIKEVLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "ORTAHİSAR",
        "STREET": "AYVALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "ORTAHİSAR",
        "STREET": "AĞILLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "ORTAHİSAR",
        "STREET": "BAHÇECİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "ORTAHİSAR",
        "STREET": "BENGİSU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "ORTAHİSAR",
        "STREET": "BEŞTAŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "ORTAHİSAR",
        "STREET": "BOZTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "ORTAHİSAR",
        "STREET": "BULAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "ORTAHİSAR",
        "STREET": "CUMHURİYET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "ORTAHİSAR",
        "STREET": "DEĞİRMENDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "ORTAHİSAR",
        "STREET": "DOLAYLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "ORTAHİSAR",
        "STREET": "DOĞANÇAY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "ORTAHİSAR",
        "STREET": "DÜZYURT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "ORTAHİSAR",
        "STREET": "ESENTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "ORTAHİSAR",
        "STREET": "ESENYURT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "ORTAHİSAR",
        "STREET": "FATİH MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "ORTAHİSAR",
        "STREET": "FATİH SULTAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "ORTAHİSAR",
        "STREET": "GAZİPAŞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "ORTAHİSAR",
        "STREET": "GEÇİT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "ORTAHİSAR",
        "STREET": "GÖLÇAYIR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "ORTAHİSAR",
        "STREET": "GÖZALAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "ORTAHİSAR",
        "STREET": "GÜLBAHARHATUN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "ORTAHİSAR",
        "STREET": "GÜNDOĞDU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "ORTAHİSAR",
        "STREET": "GÜRBULAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "ORTAHİSAR",
        "STREET": "HIZIRBEY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "ORTAHİSAR",
        "STREET": "KALKINMA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "ORTAHİSAR",
        "STREET": "KANUNİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "ORTAHİSAR",
        "STREET": "KARAKAYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "ORTAHİSAR",
        "STREET": "KARLIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "ORTAHİSAR",
        "STREET": "KARŞIYAKA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "ORTAHİSAR",
        "STREET": "KAVALA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "ORTAHİSAR",
        "STREET": "KAYMAKLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "ORTAHİSAR",
        "STREET": "KEMERKAYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "ORTAHİSAR",
        "STREET": "KONAKLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "ORTAHİSAR",
        "STREET": "KOZLUCA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "ORTAHİSAR",
        "STREET": "KURTULUŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "ORTAHİSAR",
        "STREET": "KUTLUGÜN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "ORTAHİSAR",
        "STREET": "KİREÇHANE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "ORTAHİSAR",
        "STREET": "OKÇULAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "ORTAHİSAR",
        "STREET": "ORTAHİSAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "ORTAHİSAR",
        "STREET": "PAZARKAPI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "ORTAHİSAR",
        "STREET": "PELİTLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "ORTAHİSAR",
        "STREET": "PINARALTI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "ORTAHİSAR",
        "STREET": "SANAYİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "ORTAHİSAR",
        "STREET": "SAYVAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "ORTAHİSAR",
        "STREET": "SEVİMLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "ORTAHİSAR",
        "STREET": "SOĞUKSU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "ORTAHİSAR",
        "STREET": "SUBAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "ORTAHİSAR",
        "STREET": "TOKLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "ORTAHİSAR",
        "STREET": "TOSKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "ORTAHİSAR",
        "STREET": "TRABZON E TİPİ KAPALI CEZA İNFAZ KURUMU",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "ORTAHİSAR",
        "STREET": "UĞURLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "ORTAHİSAR",
        "STREET": "YALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "ORTAHİSAR",
        "STREET": "YALINCAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "ORTAHİSAR",
        "STREET": "YENİCUMA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "ORTAHİSAR",
        "STREET": "YENİKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "ORTAHİSAR",
        "STREET": "YENİMAHALLE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "ORTAHİSAR",
        "STREET": "YEŞİLBÜK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "ORTAHİSAR",
        "STREET": "YEŞİLHİSAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "ORTAHİSAR",
        "STREET": "YEŞİLOVA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "ORTAHİSAR",
        "STREET": "YEŞİLTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "ORTAHİSAR",
        "STREET": "YEŞİLVADİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "ORTAHİSAR",
        "STREET": "YEŞİLYURT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "ORTAHİSAR",
        "STREET": "ZAFER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "ORTAHİSAR",
        "STREET": "ÇAMOBA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "ORTAHİSAR",
        "STREET": "ÇARŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "ORTAHİSAR",
        "STREET": "ÇAĞLAYAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "ORTAHİSAR",
        "STREET": "ÇUKURÇAYIR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "ORTAHİSAR",
        "STREET": "ÇÖMLEKÇİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "ORTAHİSAR",
        "STREET": "ÇİLEKLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "ORTAHİSAR",
        "STREET": "ÇİMENLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "ORTAHİSAR",
        "STREET": "ÖZBİRLİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "ORTAHİSAR",
        "STREET": "ÜNİVERSİTE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "ORTAHİSAR",
        "STREET": "İNCESU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "ORTAHİSAR",
        "STREET": "İNÖNÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "ORTAHİSAR",
        "STREET": "İSKENDERPAŞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "SÜRMENE",
        "STREET": "AKSU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "SÜRMENE",
        "STREET": "ARMUTLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "SÜRMENE",
        "STREET": "AŞAĞIOVALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "SÜRMENE",
        "STREET": "BALIKLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "SÜRMENE",
        "STREET": "BİRLİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "SÜRMENE",
        "STREET": "DİRLİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "SÜRMENE",
        "STREET": "FINDICAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "SÜRMENE",
        "STREET": "GÜLTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "SÜRMENE",
        "STREET": "GÜNEYKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "SÜRMENE",
        "STREET": "KAHRAMAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "SÜRMENE",
        "STREET": "KARACAKAYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "SÜRMENE",
        "STREET": "KONAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "SÜRMENE",
        "STREET": "KOYUNCULAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "SÜRMENE",
        "STREET": "KÜÇÜKDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "SÜRMENE",
        "STREET": "MURATLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "SÜRMENE",
        "STREET": "ORMANSEVEN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "SÜRMENE",
        "STREET": "ORTA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "SÜRMENE",
        "STREET": "ORTAKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "SÜRMENE",
        "STREET": "OYLUM MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "SÜRMENE",
        "STREET": "PETEKLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "SÜRMENE",
        "STREET": "SOĞUKSU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "SÜRMENE",
        "STREET": "YAZIOBA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "SÜRMENE",
        "STREET": "YEMİŞLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "SÜRMENE",
        "STREET": "YENİAY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "SÜRMENE",
        "STREET": "YEŞİLKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "SÜRMENE",
        "STREET": "YOKUŞBAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "SÜRMENE",
        "STREET": "YUKARIOVALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "SÜRMENE",
        "STREET": "YUKARIÇAVUŞLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "SÜRMENE",
        "STREET": "ZEYTİNLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "SÜRMENE",
        "STREET": "ÇAMBURNU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "SÜRMENE",
        "STREET": "ÇAMLICA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "SÜRMENE",
        "STREET": "ÇARŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "SÜRMENE",
        "STREET": "ÇAVUŞLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "SÜRMENE",
        "STREET": "ÇİFTESU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "SÜRMENE",
        "STREET": "ÇİMENLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "SÜRMENE",
        "STREET": "ÜSTÜNDAL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "SÜRMENE",
        "STREET": "ÜZÜMLÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "TONYA",
        "STREET": "BÜYÜK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "TONYA",
        "STREET": "BİÇİNLİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "TONYA",
        "STREET": "HOŞARLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "TONYA",
        "STREET": "KALEMLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "TONYA",
        "STREET": "KALEÖNÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "TONYA",
        "STREET": "KALINÇAM MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "TONYA",
        "STREET": "KARAAĞAÇLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "TONYA",
        "STREET": "KARASU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "TONYA",
        "STREET": "KARŞULAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "TONYA",
        "STREET": "KAYACAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "TONYA",
        "STREET": "KOZLUCA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "TONYA",
        "STREET": "KÖSECİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "TONYA",
        "STREET": "MELİKŞAH MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "TONYA",
        "STREET": "ORTA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "TONYA",
        "STREET": "SAYRAÇ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "TONYA",
        "STREET": "SAĞRI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "TONYA",
        "STREET": "TURALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "TONYA",
        "STREET": "YAKÇUKUR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "TONYA",
        "STREET": "YENİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "TONYA",
        "STREET": "ÇAMLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "TONYA",
        "STREET": "ÇAYIRİÇİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "TONYA",
        "STREET": "İSKENDERLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "VAKFIKEBİR",
        "STREET": "AKKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "VAKFIKEBİR",
        "STREET": "AYDOĞDU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "VAKFIKEBİR",
        "STREET": "AÇIKALAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "VAKFIKEBİR",
        "STREET": "BAHADIRLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "VAKFIKEBİR",
        "STREET": "BALLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "VAKFIKEBİR",
        "STREET": "BOZALAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "VAKFIKEBİR",
        "STREET": "BÜYÜKLİMAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "VAKFIKEBİR",
        "STREET": "CAFERLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "VAKFIKEBİR",
        "STREET": "CUMHURİYET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "VAKFIKEBİR",
        "STREET": "DEMİRCİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "VAKFIKEBİR",
        "STREET": "DEREGÖZÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "VAKFIKEBİR",
        "STREET": "DÜZLÜK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "VAKFIKEBİR",
        "STREET": "ESENTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "VAKFIKEBİR",
        "STREET": "FETHİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "VAKFIKEBİR",
        "STREET": "FEVZİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "VAKFIKEBİR",
        "STREET": "GÜNEYKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "VAKFIKEBİR",
        "STREET": "GÜNEYSU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "VAKFIKEBİR",
        "STREET": "HACIKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "VAKFIKEBİR",
        "STREET": "HAMZALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "VAKFIKEBİR",
        "STREET": "HÜRRİYET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "VAKFIKEBİR",
        "STREET": "KARATEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "VAKFIKEBİR",
        "STREET": "KEMALİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "VAKFIKEBİR",
        "STREET": "KIRANKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "VAKFIKEBİR",
        "STREET": "KÖPRÜCEK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "VAKFIKEBİR",
        "STREET": "KÖREZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "VAKFIKEBİR",
        "STREET": "KÜÇÜKDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "VAKFIKEBİR",
        "STREET": "KİRAZLIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "VAKFIKEBİR",
        "STREET": "KİRAZLIKYENİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "VAKFIKEBİR",
        "STREET": "MAHMUTLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "VAKFIKEBİR",
        "STREET": "MISIRLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "VAKFIKEBİR",
        "STREET": "ORTAKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "VAKFIKEBİR",
        "STREET": "RIDVANLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "VAKFIKEBİR",
        "STREET": "SEKMENLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "VAKFIKEBİR",
        "STREET": "SOĞUKSU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "VAKFIKEBİR",
        "STREET": "SİNANLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "VAKFIKEBİR",
        "STREET": "TARLACIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "VAKFIKEBİR",
        "STREET": "YALIKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "VAKFIKEBİR",
        "STREET": "YAYLACIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "VAKFIKEBİR",
        "STREET": "YILDIZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "VAKFIKEBİR",
        "STREET": "ÇAMLIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "VAKFIKEBİR",
        "STREET": "ÇAMLIK SAHİL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "VAKFIKEBİR",
        "STREET": "ÇARŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "VAKFIKEBİR",
        "STREET": "ÇAVUŞLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "VAKFIKEBİR",
        "STREET": "İLYASLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "VAKFIKEBİR",
        "STREET": "İSHAKLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "VAKFIKEBİR",
        "STREET": "ŞENOCAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "YOMRA",
        "STREET": "DEMİRCİLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "YOMRA",
        "STREET": "GÜLYURDU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "YOMRA",
        "STREET": "GÜRSEL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "YOMRA",
        "STREET": "KAYABAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "YOMRA",
        "STREET": "KAŞÜSTÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "YOMRA",
        "STREET": "KILIÇLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "YOMRA",
        "STREET": "KIRATLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "YOMRA",
        "STREET": "KÖMÜRCÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "YOMRA",
        "STREET": "MADEN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "YOMRA",
        "STREET": "NAMIKKEMAL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "YOMRA",
        "STREET": "OCAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "YOMRA",
        "STREET": "OYMALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "YOMRA",
        "STREET": "PINARLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "YOMRA",
        "STREET": "SANCAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "YOMRA",
        "STREET": "TANDIRLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "YOMRA",
        "STREET": "TAŞDELEN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "YOMRA",
        "STREET": "TEPEKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "YOMRA",
        "STREET": "YENİCE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "YOMRA",
        "STREET": "YOKUŞLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "YOMRA",
        "STREET": "ÇAMLICA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "YOMRA",
        "STREET": "ÇAMLIYURT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "YOMRA",
        "STREET": "ÇINARLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "YOMRA",
        "STREET": "ÖZDİL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "YOMRA",
        "STREET": "İKİSU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "YOMRA",
        "STREET": "ŞANLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "ÇARŞIBAŞI",
        "STREET": "BURUNBAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "ÇARŞIBAŞI",
        "STREET": "BÜYÜKDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "ÇARŞIBAŞI",
        "STREET": "ERENKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "ÇARŞIBAŞI",
        "STREET": "FENERKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "ÇARŞIBAŞI",
        "STREET": "GÜLBAHÇE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "ÇARŞIBAŞI",
        "STREET": "KADIKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "ÇARŞIBAŞI",
        "STREET": "KALEKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "ÇARŞIBAŞI",
        "STREET": "KAVAKLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "ÇARŞIBAŞI",
        "STREET": "KEREM MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "ÇARŞIBAŞI",
        "STREET": "KOVANLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "ÇARŞIBAŞI",
        "STREET": "KÜÇÜKKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "ÇARŞIBAŞI",
        "STREET": "MERKEZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "ÇARŞIBAŞI",
        "STREET": "PINARLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "ÇARŞIBAŞI",
        "STREET": "SALOVA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "ÇARŞIBAŞI",
        "STREET": "SAMSUN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "ÇARŞIBAŞI",
        "STREET": "SERPİLKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "ÇARŞIBAŞI",
        "STREET": "TAŞLITEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "ÇARŞIBAŞI",
        "STREET": "VELİKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "ÇARŞIBAŞI",
        "STREET": "YAVUZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "ÇARŞIBAŞI",
        "STREET": "YENİKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "ÇARŞIBAŞI",
        "STREET": "ZEYTİNLİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "ÇARŞIBAŞI",
        "STREET": "ÇALLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "ÇARŞIBAŞI",
        "STREET": "ŞAHİNLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "ÇAYKARA",
        "STREET": "AKDOĞAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "ÇAYKARA",
        "STREET": "ARPAÖZÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "ÇAYKARA",
        "STREET": "ATAKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "ÇAYKARA",
        "STREET": "AŞAĞIKUMLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "ÇAYKARA",
        "STREET": "BALTACILI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "ÇAYKARA",
        "STREET": "DEMİRKAPI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "ÇAYKARA",
        "STREET": "DEMİRLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "ÇAYKARA",
        "STREET": "DERİNDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "ÇAYKARA",
        "STREET": "EĞRİDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "ÇAYKARA",
        "STREET": "IŞIKLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "ÇAYKARA",
        "STREET": "KABATAŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "ÇAYKARA",
        "STREET": "KARAÇAM MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "ÇAYKARA",
        "STREET": "KAYRAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "ÇAYKARA",
        "STREET": "KOLDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "ÇAYKARA",
        "STREET": "KÖKNAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "ÇAYKARA",
        "STREET": "KÖSELİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "ÇAYKARA",
        "STREET": "MARAŞLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "ÇAYKARA",
        "STREET": "SOĞANLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "ÇAYKARA",
        "STREET": "TAŞKIRAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "ÇAYKARA",
        "STREET": "TAŞLIGEDİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "ÇAYKARA",
        "STREET": "TAŞÖREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "ÇAYKARA",
        "STREET": "ULUCAMİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "ÇAYKARA",
        "STREET": "UZUNGÖL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "ÇAYKARA",
        "STREET": "UZUNTARLA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "ÇAYKARA",
        "STREET": "YAYLAÖNÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "ÇAYKARA",
        "STREET": "YEŞİLALAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "ÇAYKARA",
        "STREET": "YUKARIKUMLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "ÇAYKARA",
        "STREET": "ÇAMBAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "ÇAYKARA",
        "STREET": "ÇAMLIBEL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "ÇAYKARA",
        "STREET": "ÇAYIROBA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "ÇAYKARA",
        "STREET": "ŞAHİNKAYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "ÇAYKARA",
        "STREET": "ŞEKERSU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "ŞALPAZARI",
        "STREET": "AKÇİRİŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "ŞALPAZARI",
        "STREET": "AĞIRTAŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "ŞALPAZARI",
        "STREET": "DEREKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "ŞALPAZARI",
        "STREET": "DORUKKİRİŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "ŞALPAZARI",
        "STREET": "DOĞANCI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "ŞALPAZARI",
        "STREET": "DÜZKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "ŞALPAZARI",
        "STREET": "FİDANBAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "ŞALPAZARI",
        "STREET": "GEYİKLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "ŞALPAZARI",
        "STREET": "GÖKÇEKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "ŞALPAZARI",
        "STREET": "GÖLKİRİŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "ŞALPAZARI",
        "STREET": "GÜDÜN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "ŞALPAZARI",
        "STREET": "KABASAKAL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "ŞALPAZARI",
        "STREET": "KALECİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "ŞALPAZARI",
        "STREET": "KARAKAYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "ŞALPAZARI",
        "STREET": "KASIMAĞZI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "ŞALPAZARI",
        "STREET": "KUZULUK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "ŞALPAZARI",
        "STREET": "MERKEZ-KİREÇ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "ŞALPAZARI",
        "STREET": "PELİTÇİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "ŞALPAZARI",
        "STREET": "SAYVANÇATAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "ŞALPAZARI",
        "STREET": "SUGÖREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "ŞALPAZARI",
        "STREET": "SÜTPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "ŞALPAZARI",
        "STREET": "SİMENLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "ŞALPAZARI",
        "STREET": "SİNLİCE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "ŞALPAZARI",
        "STREET": "TEPEAĞZI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "ŞALPAZARI",
        "STREET": "TURALIUŞAĞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "ŞALPAZARI",
        "STREET": "ÇAMKİRİŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "ŞALPAZARI",
        "STREET": "ÇAMLICA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "ŞALPAZARI",
        "STREET": "ÇARLAKLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "ŞALPAZARI",
        "STREET": "ÇETRİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TRABZON",
        "DISTRICT": "ŞALPAZARI",
        "STREET": "ÜZÜMÖZÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "TUNCELİ",
        "DISTRICT": "HOZAT",
        "STREET": "ALTINÇEVRE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TUNCELİ",
        "DISTRICT": "HOZAT",
        "STREET": "DALÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TUNCELİ",
        "DISTRICT": "HOZAT",
        "STREET": "DERVİŞCEMAL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TUNCELİ",
        "DISTRICT": "HOZAT",
        "STREET": "DİYAP AĞA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TUNCELİ",
        "DISTRICT": "HOZAT",
        "STREET": "FİKRİPAŞA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TUNCELİ",
        "DISTRICT": "HOZAT",
        "STREET": "KARABAKIR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TUNCELİ",
        "DISTRICT": "HOZAT",
        "STREET": "KARDELEN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TUNCELİ",
        "DISTRICT": "HOZAT",
        "STREET": "KÖPRÜBAŞI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TUNCELİ",
        "DISTRICT": "HOZAT",
        "STREET": "TÜRKTANER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TUNCELİ",
        "DISTRICT": "HOZAT",
        "STREET": "YENİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TUNCELİ",
        "DISTRICT": "HOZAT",
        "STREET": "YENİDOĞDU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TUNCELİ",
        "DISTRICT": "HOZAT",
        "STREET": "YÜCELDİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TUNCELİ",
        "DISTRICT": "HOZAT",
        "STREET": "ÇAĞLARCA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TUNCELİ",
        "DISTRICT": "HOZAT",
        "STREET": "İN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TUNCELİ",
        "DISTRICT": "MAZGİRT",
        "STREET": "AKDÜVEN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TUNCELİ",
        "DISTRICT": "MAZGİRT",
        "STREET": "AKPAZAR-ELMALIK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TUNCELİ",
        "DISTRICT": "MAZGİRT",
        "STREET": "AKPAZAR-GÜNEŞDERE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TUNCELİ",
        "DISTRICT": "MAZGİRT",
        "STREET": "AKPAZAR-KEPEKTAŞI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TUNCELİ",
        "DISTRICT": "MAZGİRT",
        "STREET": "AKPAZAR-KUŞÇU MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TUNCELİ",
        "DISTRICT": "MAZGİRT",
        "STREET": "AKPAZAR-ÇARŞIBAŞI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TUNCELİ",
        "DISTRICT": "MAZGİRT",
        "STREET": "AKPAZAR-ÖRS MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TUNCELİ",
        "DISTRICT": "MAZGİRT",
        "STREET": "AKTARLA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TUNCELİ",
        "DISTRICT": "MAZGİRT",
        "STREET": "ALANYAZI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TUNCELİ",
        "DISTRICT": "MAZGİRT",
        "STREET": "ALHAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TUNCELİ",
        "DISTRICT": "MAZGİRT",
        "STREET": "ASLANYURDU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TUNCELİ",
        "DISTRICT": "MAZGİRT",
        "STREET": "AVUNCA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TUNCELİ",
        "DISTRICT": "MAZGİRT",
        "STREET": "AĞAÇARDI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TUNCELİ",
        "DISTRICT": "MAZGİRT",
        "STREET": "AŞAĞIOYUMCA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TUNCELİ",
        "DISTRICT": "MAZGİRT",
        "STREET": "BALKAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TUNCELİ",
        "DISTRICT": "MAZGİRT",
        "STREET": "BEYLERMEZRASI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TUNCELİ",
        "DISTRICT": "MAZGİRT",
        "STREET": "BULGURCULAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TUNCELİ",
        "DISTRICT": "MAZGİRT",
        "STREET": "DANABURAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TUNCELİ",
        "DISTRICT": "MAZGİRT",
        "STREET": "DARIKENT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TUNCELİ",
        "DISTRICT": "MAZGİRT",
        "STREET": "DEMİRCİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TUNCELİ",
        "DISTRICT": "MAZGİRT",
        "STREET": "DEMİRKAZIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TUNCELİ",
        "DISTRICT": "MAZGİRT",
        "STREET": "DOĞANLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TUNCELİ",
        "DISTRICT": "MAZGİRT",
        "STREET": "ELTİHATUN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TUNCELİ",
        "DISTRICT": "MAZGİRT",
        "STREET": "GEÇİTVEREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TUNCELİ",
        "DISTRICT": "MAZGİRT",
        "STREET": "GÖKTEPE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TUNCELİ",
        "DISTRICT": "MAZGİRT",
        "STREET": "GÜMÜŞGÜN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TUNCELİ",
        "DISTRICT": "MAZGİRT",
        "STREET": "KALAYCI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TUNCELİ",
        "DISTRICT": "MAZGİRT",
        "STREET": "KIZILCIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TUNCELİ",
        "DISTRICT": "MAZGİRT",
        "STREET": "KIZILKALE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TUNCELİ",
        "DISTRICT": "MAZGİRT",
        "STREET": "KUŞHANE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TUNCELİ",
        "DISTRICT": "MAZGİRT",
        "STREET": "OTLUKAYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TUNCELİ",
        "DISTRICT": "MAZGİRT",
        "STREET": "SÜLÜNTAŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TUNCELİ",
        "DISTRICT": "MAZGİRT",
        "STREET": "TEMÜRTAHT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TUNCELİ",
        "DISTRICT": "MAZGİRT",
        "STREET": "YAZELİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TUNCELİ",
        "DISTRICT": "MAZGİRT",
        "STREET": "YENİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TUNCELİ",
        "DISTRICT": "MAZGİRT",
        "STREET": "YENİBUDAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TUNCELİ",
        "DISTRICT": "MAZGİRT",
        "STREET": "YUKARIOYUMCA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TUNCELİ",
        "DISTRICT": "MAZGİRT",
        "STREET": "ÖRENİÇİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TUNCELİ",
        "DISTRICT": "MAZGİRT",
        "STREET": "İSMAİLLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TUNCELİ",
        "DISTRICT": "NAZIMİYE",
        "STREET": "AŞAĞIDOLUCA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TUNCELİ",
        "DISTRICT": "NAZIMİYE",
        "STREET": "BALLICA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TUNCELİ",
        "DISTRICT": "NAZIMİYE",
        "STREET": "BOSTANLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TUNCELİ",
        "DISTRICT": "NAZIMİYE",
        "STREET": "BÜYÜKYURT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TUNCELİ",
        "DISTRICT": "NAZIMİYE",
        "STREET": "DEREOVA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TUNCELİ",
        "DISTRICT": "NAZIMİYE",
        "STREET": "GERİŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TUNCELİ",
        "DISTRICT": "NAZIMİYE",
        "STREET": "GÜNEYCİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TUNCELİ",
        "DISTRICT": "NAZIMİYE",
        "STREET": "GÜNLÜCE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TUNCELİ",
        "DISTRICT": "NAZIMİYE",
        "STREET": "GÜZELPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TUNCELİ",
        "DISTRICT": "NAZIMİYE",
        "STREET": "KAPIBAŞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TUNCELİ",
        "DISTRICT": "NAZIMİYE",
        "STREET": "KILKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "TUNCELİ",
        "DISTRICT": "NAZIMİYE",
        "STREET": "MERKEZ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TUNCELİ",
        "DISTRICT": "NAZIMİYE",
        "STREET": "SAPKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "TUNCELİ",
        "DISTRICT": "NAZIMİYE",
        "STREET": "SARIYAYLA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TUNCELİ",
        "DISTRICT": "NAZIMİYE",
        "STREET": "TURNAYOLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TUNCELİ",
        "DISTRICT": "NAZIMİYE",
        "STREET": "YAZGELDİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TUNCELİ",
        "DISTRICT": "NAZIMİYE",
        "STREET": "YİĞİTLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TUNCELİ",
        "DISTRICT": "NAZIMİYE",
        "STREET": "ÇEVRECİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TUNCELİ",
        "DISTRICT": "OVACIK",
        "STREET": "94 MAHALLESİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TUNCELİ",
        "DISTRICT": "OVACIK",
        "STREET": "ADA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TUNCELİ",
        "DISTRICT": "OVACIK",
        "STREET": "AĞAÇPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TUNCELİ",
        "DISTRICT": "OVACIK",
        "STREET": "AŞAĞITORUNOBA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TUNCELİ",
        "DISTRICT": "OVACIK",
        "STREET": "BURNAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TUNCELİ",
        "DISTRICT": "OVACIK",
        "STREET": "BUZLUTEPE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TUNCELİ",
        "DISTRICT": "OVACIK",
        "STREET": "BİLGEÇ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TUNCELİ",
        "DISTRICT": "OVACIK",
        "STREET": "CEVİZLİDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TUNCELİ",
        "DISTRICT": "OVACIK",
        "STREET": "ESKİGEDİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TUNCELİ",
        "DISTRICT": "OVACIK",
        "STREET": "EĞRİPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TUNCELİ",
        "DISTRICT": "OVACIK",
        "STREET": "GÜNEYKONAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TUNCELİ",
        "DISTRICT": "OVACIK",
        "STREET": "HANUŞAĞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TUNCELİ",
        "DISTRICT": "OVACIK",
        "STREET": "IŞIKVURAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TUNCELİ",
        "DISTRICT": "OVACIK",
        "STREET": "KANDOLAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TUNCELİ",
        "DISTRICT": "OVACIK",
        "STREET": "KARATAŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TUNCELİ",
        "DISTRICT": "OVACIK",
        "STREET": "KARAYONCA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TUNCELİ",
        "DISTRICT": "OVACIK",
        "STREET": "KIZIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TUNCELİ",
        "DISTRICT": "OVACIK",
        "STREET": "KOYUNGÖLÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TUNCELİ",
        "DISTRICT": "OVACIK",
        "STREET": "KÖSELER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TUNCELİ",
        "DISTRICT": "OVACIK",
        "STREET": "MOLLAALİLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TUNCELİ",
        "DISTRICT": "OVACIK",
        "STREET": "PAŞADÜZÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TUNCELİ",
        "DISTRICT": "OVACIK",
        "STREET": "PULUR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TUNCELİ",
        "DISTRICT": "OVACIK",
        "STREET": "SÖĞÜTLÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TUNCELİ",
        "DISTRICT": "OVACIK",
        "STREET": "TATUŞAĞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TUNCELİ",
        "DISTRICT": "OVACIK",
        "STREET": "TOPUZLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TUNCELİ",
        "DISTRICT": "OVACIK",
        "STREET": "YAYLAGÜNÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TUNCELİ",
        "DISTRICT": "OVACIK",
        "STREET": "YAZIÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TUNCELİ",
        "DISTRICT": "OVACIK",
        "STREET": "YENİKONAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TUNCELİ",
        "DISTRICT": "OVACIK",
        "STREET": "YEŞİLYAZI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TUNCELİ",
        "DISTRICT": "OVACIK",
        "STREET": "ZİYARET KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TUNCELİ",
        "DISTRICT": "OVACIK",
        "STREET": "ÇAKMAKLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TUNCELİ",
        "DISTRICT": "OVACIK",
        "STREET": "ÇAT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TUNCELİ",
        "DISTRICT": "OVACIK",
        "STREET": "ÇAYÜSTÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TUNCELİ",
        "DISTRICT": "PERTEK",
        "STREET": "AKDEMİR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TUNCELİ",
        "DISTRICT": "PERTEK",
        "STREET": "ARDIÇ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TUNCELİ",
        "DISTRICT": "PERTEK",
        "STREET": "AYAZPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TUNCELİ",
        "DISTRICT": "PERTEK",
        "STREET": "AŞAĞIGÜLBAHÇE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TUNCELİ",
        "DISTRICT": "PERTEK",
        "STREET": "BALLIDUT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TUNCELİ",
        "DISTRICT": "PERTEK",
        "STREET": "BEYDAMI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TUNCELİ",
        "DISTRICT": "PERTEK",
        "STREET": "BULGURTEPE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TUNCELİ",
        "DISTRICT": "PERTEK",
        "STREET": "CAMİKEBİR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TUNCELİ",
        "DISTRICT": "PERTEK",
        "STREET": "DEMİRSABAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TUNCELİ",
        "DISTRICT": "PERTEK",
        "STREET": "DERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TUNCELİ",
        "DISTRICT": "PERTEK",
        "STREET": "DEREBAŞI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TUNCELİ",
        "DISTRICT": "PERTEK",
        "STREET": "DERELİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TUNCELİ",
        "DISTRICT": "PERTEK",
        "STREET": "DORUTAY KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TUNCELİ",
        "DISTRICT": "PERTEK",
        "STREET": "ELMAKAŞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TUNCELİ",
        "DISTRICT": "PERTEK",
        "STREET": "GEÇİTYAKA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TUNCELİ",
        "DISTRICT": "PERTEK",
        "STREET": "GÖVDELİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TUNCELİ",
        "DISTRICT": "PERTEK",
        "STREET": "GÜNBOĞAZI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TUNCELİ",
        "DISTRICT": "PERTEK",
        "STREET": "KALEDİBİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TUNCELİ",
        "DISTRICT": "PERTEK",
        "STREET": "KAÇARLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TUNCELİ",
        "DISTRICT": "PERTEK",
        "STREET": "KOLANKAYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TUNCELİ",
        "DISTRICT": "PERTEK",
        "STREET": "KONAKLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TUNCELİ",
        "DISTRICT": "PERTEK",
        "STREET": "KORLUCA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TUNCELİ",
        "DISTRICT": "PERTEK",
        "STREET": "KOÇPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TUNCELİ",
        "DISTRICT": "PERTEK",
        "STREET": "MERCİMEK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TUNCELİ",
        "DISTRICT": "PERTEK",
        "STREET": "PINARLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TUNCELİ",
        "DISTRICT": "PERTEK",
        "STREET": "SAĞMAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TUNCELİ",
        "DISTRICT": "PERTEK",
        "STREET": "SOĞUKPINAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TUNCELİ",
        "DISTRICT": "PERTEK",
        "STREET": "SÖĞÜTLÜTEPE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TUNCELİ",
        "DISTRICT": "PERTEK",
        "STREET": "TOZKOPARAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TUNCELİ",
        "DISTRICT": "PERTEK",
        "STREET": "ULUPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TUNCELİ",
        "DISTRICT": "PERTEK",
        "STREET": "YENİKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "TUNCELİ",
        "DISTRICT": "PERTEK",
        "STREET": "YUKARIGÜLBAHÇE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TUNCELİ",
        "DISTRICT": "PERTEK",
        "STREET": "YUKARIYAKABAŞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TUNCELİ",
        "DISTRICT": "PERTEK",
        "STREET": "ÇAKIRBAHÇE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TUNCELİ",
        "DISTRICT": "PERTEK",
        "STREET": "ÇUKURCA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TUNCELİ",
        "DISTRICT": "PERTEK",
        "STREET": "İSTİKLAL MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TUNCELİ",
        "DISTRICT": "PÜLÜMÜR",
        "STREET": "ARDIÇLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TUNCELİ",
        "DISTRICT": "PÜLÜMÜR",
        "STREET": "ATATÜRK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TUNCELİ",
        "DISTRICT": "PÜLÜMÜR",
        "STREET": "DEREBOYU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TUNCELİ",
        "DISTRICT": "PÜLÜMÜR",
        "STREET": "DOĞANPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TUNCELİ",
        "DISTRICT": "PÜLÜMÜR",
        "STREET": "ECEVİT MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TUNCELİ",
        "DISTRICT": "PÜLÜMÜR",
        "STREET": "HASANGAZİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TUNCELİ",
        "DISTRICT": "PÜLÜMÜR",
        "STREET": "HİVER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TUNCELİ",
        "DISTRICT": "PÜLÜMÜR",
        "STREET": "KABADAL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TUNCELİ",
        "DISTRICT": "PÜLÜMÜR",
        "STREET": "KANGALLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TUNCELİ",
        "DISTRICT": "PÜLÜMÜR",
        "STREET": "KOCATEPE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TUNCELİ",
        "DISTRICT": "PÜLÜMÜR",
        "STREET": "MERKEZ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TUNCELİ",
        "DISTRICT": "PÜLÜMÜR",
        "STREET": "MEYDANLAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TUNCELİ",
        "DISTRICT": "PÜLÜMÜR",
        "STREET": "MEZRAA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TUNCELİ",
        "DISTRICT": "PÜLÜMÜR",
        "STREET": "SAĞLAMTAŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TUNCELİ",
        "DISTRICT": "PÜLÜMÜR",
        "STREET": "TURNADERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TUNCELİ",
        "DISTRICT": "PÜLÜMÜR",
        "STREET": "ÇAĞLAYAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TUNCELİ",
        "DISTRICT": "PÜLÜMÜR",
        "STREET": "ÇOBANYILDIZI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TUNCELİ",
        "DISTRICT": "PÜLÜMÜR",
        "STREET": "ÜÇDAM KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TUNCELİ",
        "DISTRICT": "MERKEZ",
        "STREET": "AKTULUK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TUNCELİ",
        "DISTRICT": "MERKEZ",
        "STREET": "ALİBABA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TUNCELİ",
        "DISTRICT": "MERKEZ",
        "STREET": "ATADOĞDU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TUNCELİ",
        "DISTRICT": "MERKEZ",
        "STREET": "ATATÜRK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TUNCELİ",
        "DISTRICT": "MERKEZ",
        "STREET": "BATMAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TUNCELİ",
        "DISTRICT": "MERKEZ",
        "STREET": "BAŞAKÇI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TUNCELİ",
        "DISTRICT": "MERKEZ",
        "STREET": "BURMAGEÇİT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TUNCELİ",
        "DISTRICT": "MERKEZ",
        "STREET": "BUĞULU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TUNCELİ",
        "DISTRICT": "MERKEZ",
        "STREET": "BÖĞÜRTLEN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TUNCELİ",
        "DISTRICT": "MERKEZ",
        "STREET": "CUMHURİYET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TUNCELİ",
        "DISTRICT": "MERKEZ",
        "STREET": "DEMİRKAPI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TUNCELİ",
        "DISTRICT": "MERKEZ",
        "STREET": "DİKENLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TUNCELİ",
        "DISTRICT": "MERKEZ",
        "STREET": "ESENTEPE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TUNCELİ",
        "DISTRICT": "MERKEZ",
        "STREET": "EĞRİYAMAÇ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TUNCELİ",
        "DISTRICT": "MERKEZ",
        "STREET": "GEYİKSUYU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TUNCELİ",
        "DISTRICT": "MERKEZ",
        "STREET": "GÖKÇEK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TUNCELİ",
        "DISTRICT": "MERKEZ",
        "STREET": "GÖZEN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TUNCELİ",
        "DISTRICT": "MERKEZ",
        "STREET": "GÜLEÇ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TUNCELİ",
        "DISTRICT": "MERKEZ",
        "STREET": "KANOĞLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TUNCELİ",
        "DISTRICT": "MERKEZ",
        "STREET": "KARŞILAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TUNCELİ",
        "DISTRICT": "MERKEZ",
        "STREET": "KOCAKOÇ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TUNCELİ",
        "DISTRICT": "MERKEZ",
        "STREET": "KOPUZLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TUNCELİ",
        "DISTRICT": "MERKEZ",
        "STREET": "KÖKLÜCE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TUNCELİ",
        "DISTRICT": "MERKEZ",
        "STREET": "MOĞULTAY MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TUNCELİ",
        "DISTRICT": "MERKEZ",
        "STREET": "OKURLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TUNCELİ",
        "DISTRICT": "MERKEZ",
        "STREET": "PINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TUNCELİ",
        "DISTRICT": "MERKEZ",
        "STREET": "SARITAŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TUNCELİ",
        "DISTRICT": "MERKEZ",
        "STREET": "SUVAT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TUNCELİ",
        "DISTRICT": "MERKEZ",
        "STREET": "TAHT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TUNCELİ",
        "DISTRICT": "MERKEZ",
        "STREET": "TUNCELİ KAPALI CEZA EVİ",
        "TYPE": "0"
      },
      {
        "CITY": "TUNCELİ",
        "DISTRICT": "MERKEZ",
        "STREET": "TÜLLÜK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TUNCELİ",
        "DISTRICT": "MERKEZ",
        "STREET": "YENİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TUNCELİ",
        "DISTRICT": "MERKEZ",
        "STREET": "YOLKONAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TUNCELİ",
        "DISTRICT": "MERKEZ",
        "STREET": "İSMET İNÖNÜ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TUNCELİ",
        "DISTRICT": "ÇEMİŞGEZEK",
        "STREET": "AKÇAPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TUNCELİ",
        "DISTRICT": "ÇEMİŞGEZEK",
        "STREET": "ANIL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TUNCELİ",
        "DISTRICT": "ÇEMİŞGEZEK",
        "STREET": "ARPADEREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TUNCELİ",
        "DISTRICT": "ÇEMİŞGEZEK",
        "STREET": "AŞAĞIBUDAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TUNCELİ",
        "DISTRICT": "ÇEMİŞGEZEK",
        "STREET": "BAĞSUYU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TUNCELİ",
        "DISTRICT": "ÇEMİŞGEZEK",
        "STREET": "BÜYÜKÖRENCE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TUNCELİ",
        "DISTRICT": "ÇEMİŞGEZEK",
        "STREET": "CEBE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TUNCELİ",
        "DISTRICT": "ÇEMİŞGEZEK",
        "STREET": "DOĞAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TUNCELİ",
        "DISTRICT": "ÇEMİŞGEZEK",
        "STREET": "DOĞANALAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TUNCELİ",
        "DISTRICT": "ÇEMİŞGEZEK",
        "STREET": "ERKALKAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TUNCELİ",
        "DISTRICT": "ÇEMİŞGEZEK",
        "STREET": "GEDİKLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TUNCELİ",
        "DISTRICT": "ÇEMİŞGEZEK",
        "STREET": "GÖZLÜÇAYIR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TUNCELİ",
        "DISTRICT": "ÇEMİŞGEZEK",
        "STREET": "GÜLBAHÇE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TUNCELİ",
        "DISTRICT": "ÇEMİŞGEZEK",
        "STREET": "GÜNEYBAŞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TUNCELİ",
        "DISTRICT": "ÇEMİŞGEZEK",
        "STREET": "HACI CAMİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TUNCELİ",
        "DISTRICT": "ÇEMİŞGEZEK",
        "STREET": "HAMAMATİK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TUNCELİ",
        "DISTRICT": "ÇEMİŞGEZEK",
        "STREET": "KALE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TUNCELİ",
        "DISTRICT": "ÇEMİŞGEZEK",
        "STREET": "KIRAÇLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TUNCELİ",
        "DISTRICT": "ÇEMİŞGEZEK",
        "STREET": "MESCİT MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TUNCELİ",
        "DISTRICT": "ÇEMİŞGEZEK",
        "STREET": "PAYAMDÜZÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TUNCELİ",
        "DISTRICT": "ÇEMİŞGEZEK",
        "STREET": "PAŞACIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TUNCELİ",
        "DISTRICT": "ÇEMİŞGEZEK",
        "STREET": "SARIBALTA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TUNCELİ",
        "DISTRICT": "ÇEMİŞGEZEK",
        "STREET": "TEKELİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TUNCELİ",
        "DISTRICT": "ÇEMİŞGEZEK",
        "STREET": "TEPEBAŞI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TUNCELİ",
        "DISTRICT": "ÇEMİŞGEZEK",
        "STREET": "TORATLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TUNCELİ",
        "DISTRICT": "ÇEMİŞGEZEK",
        "STREET": "ULUKALE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TUNCELİ",
        "DISTRICT": "ÇEMİŞGEZEK",
        "STREET": "UZUNGÖL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TUNCELİ",
        "DISTRICT": "ÇEMİŞGEZEK",
        "STREET": "VİŞNELİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TUNCELİ",
        "DISTRICT": "ÇEMİŞGEZEK",
        "STREET": "YEMİŞDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TUNCELİ",
        "DISTRICT": "ÇEMİŞGEZEK",
        "STREET": "YENİMAHALLE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "TUNCELİ",
        "DISTRICT": "ÇEMİŞGEZEK",
        "STREET": "YÜNBÜKEN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "TUNCELİ",
        "DISTRICT": "ÇEMİŞGEZEK",
        "STREET": "ÇUKUR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "UŞAK",
        "DISTRICT": "BANAZ",
        "STREET": "31 AĞUSTOS MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "UŞAK",
        "DISTRICT": "BANAZ",
        "STREET": "AHAT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "UŞAK",
        "DISTRICT": "BANAZ",
        "STREET": "ALABA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "UŞAK",
        "DISTRICT": "BANAZ",
        "STREET": "AYRANCI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "UŞAK",
        "DISTRICT": "BANAZ",
        "STREET": "BAHADIR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "UŞAK",
        "DISTRICT": "BANAZ",
        "STREET": "BALCIDAMI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "UŞAK",
        "DISTRICT": "BANAZ",
        "STREET": "BALTALI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "UŞAK",
        "DISTRICT": "BANAZ",
        "STREET": "BANAZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "UŞAK",
        "DISTRICT": "BANAZ",
        "STREET": "BAĞKONAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "UŞAK",
        "DISTRICT": "BANAZ",
        "STREET": "BURHANİYE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "UŞAK",
        "DISTRICT": "BANAZ",
        "STREET": "BÜYÜKOTURAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "UŞAK",
        "DISTRICT": "BANAZ",
        "STREET": "CORUM KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "UŞAK",
        "DISTRICT": "BANAZ",
        "STREET": "CUMHURİYET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "UŞAK",
        "DISTRICT": "BANAZ",
        "STREET": "DERBENT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "UŞAK",
        "DISTRICT": "BANAZ",
        "STREET": "DÜMENLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "UŞAK",
        "DISTRICT": "BANAZ",
        "STREET": "DÜZKIŞLA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "UŞAK",
        "DISTRICT": "BANAZ",
        "STREET": "DÜZLÜCE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "UŞAK",
        "DISTRICT": "BANAZ",
        "STREET": "DİLEK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "UŞAK",
        "DISTRICT": "BANAZ",
        "STREET": "GEDİKLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "UŞAK",
        "DISTRICT": "BANAZ",
        "STREET": "GÜLLÜÇAM KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "UŞAK",
        "DISTRICT": "BANAZ",
        "STREET": "GÜRLEK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "UŞAK",
        "DISTRICT": "BANAZ",
        "STREET": "HALAÇLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "UŞAK",
        "DISTRICT": "BANAZ",
        "STREET": "HAMAMBOĞAZI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "UŞAK",
        "DISTRICT": "BANAZ",
        "STREET": "HASANKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "UŞAK",
        "DISTRICT": "BANAZ",
        "STREET": "HATIPLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "UŞAK",
        "DISTRICT": "BANAZ",
        "STREET": "KAPLANGI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "UŞAK",
        "DISTRICT": "BANAZ",
        "STREET": "KARACAHİSAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "UŞAK",
        "DISTRICT": "BANAZ",
        "STREET": "KARAKÖSE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "UŞAK",
        "DISTRICT": "BANAZ",
        "STREET": "KAVACIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "UŞAK",
        "DISTRICT": "BANAZ",
        "STREET": "KAYILI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "UŞAK",
        "DISTRICT": "BANAZ",
        "STREET": "KIZILCASÖĞÜT-BARIŞ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "UŞAK",
        "DISTRICT": "BANAZ",
        "STREET": "KIZILCASÖĞÜT-CUMHURİYET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "UŞAK",
        "DISTRICT": "BANAZ",
        "STREET": "KIZILCASÖĞÜT-EMEK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "UŞAK",
        "DISTRICT": "BANAZ",
        "STREET": "KIZILCAÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "UŞAK",
        "DISTRICT": "BANAZ",
        "STREET": "KIZILHİSAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "UŞAK",
        "DISTRICT": "BANAZ",
        "STREET": "KUŞDEMİR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "UŞAK",
        "DISTRICT": "BANAZ",
        "STREET": "KÜÇÜKLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "UŞAK",
        "DISTRICT": "BANAZ",
        "STREET": "KÜÇÜKOTURAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "UŞAK",
        "DISTRICT": "BANAZ",
        "STREET": "MURATLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "UŞAK",
        "DISTRICT": "BANAZ",
        "STREET": "OVACIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "UŞAK",
        "DISTRICT": "BANAZ",
        "STREET": "PAŞACIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "UŞAK",
        "DISTRICT": "BANAZ",
        "STREET": "REŞADİYE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "UŞAK",
        "DISTRICT": "BANAZ",
        "STREET": "SUSUZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "UŞAK",
        "DISTRICT": "BANAZ",
        "STREET": "ULUPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "UŞAK",
        "DISTRICT": "BANAZ",
        "STREET": "YAZITEPE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "UŞAK",
        "DISTRICT": "BANAZ",
        "STREET": "YENİCE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "UŞAK",
        "DISTRICT": "BANAZ",
        "STREET": "YEŞİLYURT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "UŞAK",
        "DISTRICT": "BANAZ",
        "STREET": "ÇAMSU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "UŞAK",
        "DISTRICT": "BANAZ",
        "STREET": "ÇÖĞÜRLÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "UŞAK",
        "DISTRICT": "BANAZ",
        "STREET": "ÇİFTLİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "UŞAK",
        "DISTRICT": "BANAZ",
        "STREET": "ÖKSÜZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "UŞAK",
        "DISTRICT": "BANAZ",
        "STREET": "İMREZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "UŞAK",
        "DISTRICT": "BANAZ",
        "STREET": "İSLAM MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "UŞAK",
        "DISTRICT": "BANAZ",
        "STREET": "ŞABAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "UŞAK",
        "DISTRICT": "BANAZ",
        "STREET": "ŞEHİTLER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "UŞAK",
        "DISTRICT": "EŞME",
        "STREET": "AHMETLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "UŞAK",
        "DISTRICT": "EŞME",
        "STREET": "AKÇAKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "UŞAK",
        "DISTRICT": "EŞME",
        "STREET": "ALAHABALI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "UŞAK",
        "DISTRICT": "EŞME",
        "STREET": "ALIÇLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "UŞAK",
        "DISTRICT": "EŞME",
        "STREET": "ARAPLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "UŞAK",
        "DISTRICT": "EŞME",
        "STREET": "ARMUTLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "UŞAK",
        "DISTRICT": "EŞME",
        "STREET": "AYDINLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "UŞAK",
        "DISTRICT": "EŞME",
        "STREET": "AĞABEY KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "UŞAK",
        "DISTRICT": "EŞME",
        "STREET": "BALABANCI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "UŞAK",
        "DISTRICT": "EŞME",
        "STREET": "BEKİŞLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "UŞAK",
        "DISTRICT": "EŞME",
        "STREET": "BOZLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "UŞAK",
        "DISTRICT": "EŞME",
        "STREET": "CABERLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "UŞAK",
        "DISTRICT": "EŞME",
        "STREET": "CAMİLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "UŞAK",
        "DISTRICT": "EŞME",
        "STREET": "CEMALÇAVUŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "UŞAK",
        "DISTRICT": "EŞME",
        "STREET": "CEVİZLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "UŞAK",
        "DISTRICT": "EŞME",
        "STREET": "CUMHURİYET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "UŞAK",
        "DISTRICT": "EŞME",
        "STREET": "DAVUTLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "UŞAK",
        "DISTRICT": "EŞME",
        "STREET": "DELİBAŞLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "UŞAK",
        "DISTRICT": "EŞME",
        "STREET": "DEREKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "UŞAK",
        "DISTRICT": "EŞME",
        "STREET": "DERELİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "UŞAK",
        "DISTRICT": "EŞME",
        "STREET": "DERVİŞLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "UŞAK",
        "DISTRICT": "EŞME",
        "STREET": "ELVANLAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "UŞAK",
        "DISTRICT": "EŞME",
        "STREET": "EMİRLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "UŞAK",
        "DISTRICT": "EŞME",
        "STREET": "EŞMELİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "UŞAK",
        "DISTRICT": "EŞME",
        "STREET": "EŞMETAŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "UŞAK",
        "DISTRICT": "EŞME",
        "STREET": "GÖKÇUKUR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "UŞAK",
        "DISTRICT": "EŞME",
        "STREET": "GÜLLÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "UŞAK",
        "DISTRICT": "EŞME",
        "STREET": "GÜLLÜBAĞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "UŞAK",
        "DISTRICT": "EŞME",
        "STREET": "GÜNEY KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "UŞAK",
        "DISTRICT": "EŞME",
        "STREET": "GÜNYAKA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "UŞAK",
        "DISTRICT": "EŞME",
        "STREET": "HAMAMDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "UŞAK",
        "DISTRICT": "EŞME",
        "STREET": "HARDALLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "UŞAK",
        "DISTRICT": "EŞME",
        "STREET": "KANDEMİRLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "UŞAK",
        "DISTRICT": "EŞME",
        "STREET": "KARAAHMETLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "UŞAK",
        "DISTRICT": "EŞME",
        "STREET": "KARABACAKLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "UŞAK",
        "DISTRICT": "EŞME",
        "STREET": "KARACAÖMERLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "UŞAK",
        "DISTRICT": "EŞME",
        "STREET": "KATRANCILAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "UŞAK",
        "DISTRICT": "EŞME",
        "STREET": "KAYALI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "UŞAK",
        "DISTRICT": "EŞME",
        "STREET": "KAYAPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "UŞAK",
        "DISTRICT": "EŞME",
        "STREET": "KAZAKLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "UŞAK",
        "DISTRICT": "EŞME",
        "STREET": "KEKLİKLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "UŞAK",
        "DISTRICT": "EŞME",
        "STREET": "KEMER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "UŞAK",
        "DISTRICT": "EŞME",
        "STREET": "KIRANKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "UŞAK",
        "DISTRICT": "EŞME",
        "STREET": "KOCABEY KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "UŞAK",
        "DISTRICT": "EŞME",
        "STREET": "KOLANKAYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "UŞAK",
        "DISTRICT": "EŞME",
        "STREET": "KONAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "UŞAK",
        "DISTRICT": "EŞME",
        "STREET": "KÖSELER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "UŞAK",
        "DISTRICT": "EŞME",
        "STREET": "KÖYLÜOĞLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "UŞAK",
        "DISTRICT": "EŞME",
        "STREET": "MANAVLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "UŞAK",
        "DISTRICT": "EŞME",
        "STREET": "MERKEZ-YENİKENT MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "UŞAK",
        "DISTRICT": "EŞME",
        "STREET": "NARINCALI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "UŞAK",
        "DISTRICT": "EŞME",
        "STREET": "NARLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "UŞAK",
        "DISTRICT": "EŞME",
        "STREET": "OYMALI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "UŞAK",
        "DISTRICT": "EŞME",
        "STREET": "POSLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "UŞAK",
        "DISTRICT": "EŞME",
        "STREET": "SARAYCIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "UŞAK",
        "DISTRICT": "EŞME",
        "STREET": "TAKMAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "UŞAK",
        "DISTRICT": "EŞME",
        "STREET": "ULUYAYLA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "UŞAK",
        "DISTRICT": "EŞME",
        "STREET": "YAYLAKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "UŞAK",
        "DISTRICT": "EŞME",
        "STREET": "YELEĞEN-HÜRRİYET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "UŞAK",
        "DISTRICT": "EŞME",
        "STREET": "YELEĞEN-YILDIRIMLAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "UŞAK",
        "DISTRICT": "EŞME",
        "STREET": "YELEĞEN-İSTİKLAL MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "UŞAK",
        "DISTRICT": "EŞME",
        "STREET": "YENİKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "UŞAK",
        "DISTRICT": "EŞME",
        "STREET": "YEŞİLKAVAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "UŞAK",
        "DISTRICT": "EŞME",
        "STREET": "ÇAYKIŞLA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "UŞAK",
        "DISTRICT": "EŞME",
        "STREET": "ÜÇEYLÜL MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "UŞAK",
        "DISTRICT": "EŞME",
        "STREET": "İSALAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "UŞAK",
        "DISTRICT": "EŞME",
        "STREET": "İSTASYON MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "UŞAK",
        "DISTRICT": "EŞME",
        "STREET": "ŞEHİTALİBEY MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "UŞAK",
        "DISTRICT": "EŞME",
        "STREET": "ŞEHİTLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "UŞAK",
        "DISTRICT": "KARAHALLI",
        "STREET": "ALFAKLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "UŞAK",
        "DISTRICT": "KARAHALLI",
        "STREET": "BEKİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "UŞAK",
        "DISTRICT": "KARAHALLI",
        "STREET": "BOĞAZ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "UŞAK",
        "DISTRICT": "KARAHALLI",
        "STREET": "BUĞDAYLI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "UŞAK",
        "DISTRICT": "KARAHALLI",
        "STREET": "COĞUPLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "UŞAK",
        "DISTRICT": "KARAHALLI",
        "STREET": "DELİHIDIRLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "UŞAK",
        "DISTRICT": "KARAHALLI",
        "STREET": "DERE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "UŞAK",
        "DISTRICT": "KARAHALLI",
        "STREET": "DUMANLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "UŞAK",
        "DISTRICT": "KARAHALLI",
        "STREET": "DURAKLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "UŞAK",
        "DISTRICT": "KARAHALLI",
        "STREET": "KARAYAKUPLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "UŞAK",
        "DISTRICT": "KARAHALLI",
        "STREET": "KARBASAN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "UŞAK",
        "DISTRICT": "KARAHALLI",
        "STREET": "KAVAKLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "UŞAK",
        "DISTRICT": "KARAHALLI",
        "STREET": "KAYKILLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "UŞAK",
        "DISTRICT": "KARAHALLI",
        "STREET": "KIRKYAREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "UŞAK",
        "DISTRICT": "KARAHALLI",
        "STREET": "KONAK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "UŞAK",
        "DISTRICT": "KARAHALLI",
        "STREET": "KÜLKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "UŞAK",
        "DISTRICT": "KARAHALLI",
        "STREET": "PAŞALAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "UŞAK",
        "DISTRICT": "KARAHALLI",
        "STREET": "YENİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "UŞAK",
        "DISTRICT": "KARAHALLI",
        "STREET": "ÇOKAKLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "UŞAK",
        "DISTRICT": "SİVASLI",
        "STREET": "AKARCA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "UŞAK",
        "DISTRICT": "SİVASLI",
        "STREET": "AKINCILAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "UŞAK",
        "DISTRICT": "SİVASLI",
        "STREET": "ATATÜRK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "UŞAK",
        "DISTRICT": "SİVASLI",
        "STREET": "AZİZLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "UŞAK",
        "DISTRICT": "SİVASLI",
        "STREET": "AĞAÇBEYLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "UŞAK",
        "DISTRICT": "SİVASLI",
        "STREET": "BUDAKLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "UŞAK",
        "DISTRICT": "SİVASLI",
        "STREET": "CİNOĞLAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "UŞAK",
        "DISTRICT": "SİVASLI",
        "STREET": "ELDENİZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "UŞAK",
        "DISTRICT": "SİVASLI",
        "STREET": "ERİCE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "UŞAK",
        "DISTRICT": "SİVASLI",
        "STREET": "EVRENLİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "UŞAK",
        "DISTRICT": "SİVASLI",
        "STREET": "GÖLBAHÇE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "UŞAK",
        "DISTRICT": "SİVASLI",
        "STREET": "HACİM KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "UŞAK",
        "DISTRICT": "SİVASLI",
        "STREET": "HANOĞLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "UŞAK",
        "DISTRICT": "SİVASLI",
        "STREET": "KARABOYALIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "UŞAK",
        "DISTRICT": "SİVASLI",
        "STREET": "KETENLİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "UŞAK",
        "DISTRICT": "SİVASLI",
        "STREET": "KONAK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "UŞAK",
        "DISTRICT": "SİVASLI",
        "STREET": "KÖKEZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "UŞAK",
        "DISTRICT": "SİVASLI",
        "STREET": "PINARBAŞI-FATİH MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "UŞAK",
        "DISTRICT": "SİVASLI",
        "STREET": "PINARBAŞI-PINAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "UŞAK",
        "DISTRICT": "SİVASLI",
        "STREET": "SALMANLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "UŞAK",
        "DISTRICT": "SİVASLI",
        "STREET": "SAMATLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "UŞAK",
        "DISTRICT": "SİVASLI",
        "STREET": "SAZAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "UŞAK",
        "DISTRICT": "SİVASLI",
        "STREET": "SELÇİKLER-GÜLBEY MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "UŞAK",
        "DISTRICT": "SİVASLI",
        "STREET": "SELÇİKLER-YILDIZTEPE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "UŞAK",
        "DISTRICT": "SİVASLI",
        "STREET": "TATAR-ATATÜRK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "UŞAK",
        "DISTRICT": "SİVASLI",
        "STREET": "TATAR-DEDEBALLAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "UŞAK",
        "DISTRICT": "SİVASLI",
        "STREET": "TATAR-FEVZİ ÇAKMAK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "UŞAK",
        "DISTRICT": "SİVASLI",
        "STREET": "YAYALAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "UŞAK",
        "DISTRICT": "SİVASLI",
        "STREET": "YENİERİCE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "UŞAK",
        "DISTRICT": "SİVASLI",
        "STREET": "ÖZBEYLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "UŞAK",
        "DISTRICT": "ULUBEY",
        "STREET": "AKKEÇİLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "UŞAK",
        "DISTRICT": "ULUBEY",
        "STREET": "AKSAZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "UŞAK",
        "DISTRICT": "ULUBEY",
        "STREET": "AVGAN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "UŞAK",
        "DISTRICT": "ULUBEY",
        "STREET": "AŞAĞI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "UŞAK",
        "DISTRICT": "ULUBEY",
        "STREET": "BEKDEMİR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "UŞAK",
        "DISTRICT": "ULUBEY",
        "STREET": "BÜYÜKKAYALI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "UŞAK",
        "DISTRICT": "ULUBEY",
        "STREET": "CAMİKEBİR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "UŞAK",
        "DISTRICT": "ULUBEY",
        "STREET": "DUTLUCA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "UŞAK",
        "DISTRICT": "ULUBEY",
        "STREET": "DİLAVER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "UŞAK",
        "DISTRICT": "ULUBEY",
        "STREET": "EMİRLER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "UŞAK",
        "DISTRICT": "ULUBEY",
        "STREET": "GEDİKLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "UŞAK",
        "DISTRICT": "ULUBEY",
        "STREET": "GÖLBAŞI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "UŞAK",
        "DISTRICT": "ULUBEY",
        "STREET": "GÜMÜŞKOL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "UŞAK",
        "DISTRICT": "ULUBEY",
        "STREET": "HANYERİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "UŞAK",
        "DISTRICT": "ULUBEY",
        "STREET": "HASKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "UŞAK",
        "DISTRICT": "ULUBEY",
        "STREET": "KARACAAHMET KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "UŞAK",
        "DISTRICT": "ULUBEY",
        "STREET": "KIRAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "UŞAK",
        "DISTRICT": "ULUBEY",
        "STREET": "KIŞLA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "UŞAK",
        "DISTRICT": "ULUBEY",
        "STREET": "KURUDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "UŞAK",
        "DISTRICT": "ULUBEY",
        "STREET": "KÖSELER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "UŞAK",
        "DISTRICT": "ULUBEY",
        "STREET": "KÜLÇEN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "UŞAK",
        "DISTRICT": "ULUBEY",
        "STREET": "KÜÇÜKKAYALI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "UŞAK",
        "DISTRICT": "ULUBEY",
        "STREET": "KÜÇÜKİLYASLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "UŞAK",
        "DISTRICT": "ULUBEY",
        "STREET": "OMURCA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "UŞAK",
        "DISTRICT": "ULUBEY",
        "STREET": "SÖĞÜTLÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "UŞAK",
        "DISTRICT": "ULUBEY",
        "STREET": "SÜLÜMENLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "UŞAK",
        "DISTRICT": "ULUBEY",
        "STREET": "ÇAMDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "UŞAK",
        "DISTRICT": "ULUBEY",
        "STREET": "ÇAMLIBEL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "UŞAK",
        "DISTRICT": "ULUBEY",
        "STREET": "ÇARDAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "UŞAK",
        "DISTRICT": "ULUBEY",
        "STREET": "ÇIRPICILAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "UŞAK",
        "DISTRICT": "ULUBEY",
        "STREET": "ÜYÜKBAŞI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "UŞAK",
        "DISTRICT": "ULUBEY",
        "STREET": "İNAY KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "UŞAK",
        "DISTRICT": "ULUBEY",
        "STREET": "İSHAKLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "UŞAK",
        "DISTRICT": "MERKEZ",
        "STREET": "AKBULAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "UŞAK",
        "DISTRICT": "MERKEZ",
        "STREET": "AKTAŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "UŞAK",
        "DISTRICT": "MERKEZ",
        "STREET": "ALTINTAŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "UŞAK",
        "DISTRICT": "MERKEZ",
        "STREET": "ATATÜRK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "UŞAK",
        "DISTRICT": "MERKEZ",
        "STREET": "AYBEY MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "UŞAK",
        "DISTRICT": "MERKEZ",
        "STREET": "BAĞBAŞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "UŞAK",
        "DISTRICT": "MERKEZ",
        "STREET": "BELKAYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "UŞAK",
        "DISTRICT": "MERKEZ",
        "STREET": "BEYLERHAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "UŞAK",
        "DISTRICT": "MERKEZ",
        "STREET": "BOYALI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "UŞAK",
        "DISTRICT": "MERKEZ",
        "STREET": "BOZKURT MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "UŞAK",
        "DISTRICT": "MERKEZ",
        "STREET": "BOZKUŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "UŞAK",
        "DISTRICT": "MERKEZ",
        "STREET": "BOZKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "UŞAK",
        "DISTRICT": "MERKEZ",
        "STREET": "BUĞDAYLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "UŞAK",
        "DISTRICT": "MERKEZ",
        "STREET": "BÖLME MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "UŞAK",
        "DISTRICT": "MERKEZ",
        "STREET": "CUMHURİYET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "UŞAK",
        "DISTRICT": "MERKEZ",
        "STREET": "CİĞERDEDE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "UŞAK",
        "DISTRICT": "MERKEZ",
        "STREET": "DAĞDEMİRLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "UŞAK",
        "DISTRICT": "MERKEZ",
        "STREET": "DAĞYENİCE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "UŞAK",
        "DISTRICT": "MERKEZ",
        "STREET": "DEMİRÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "UŞAK",
        "DISTRICT": "MERKEZ",
        "STREET": "DERBENT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "UŞAK",
        "DISTRICT": "MERKEZ",
        "STREET": "DIŞKAYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "UŞAK",
        "DISTRICT": "MERKEZ",
        "STREET": "DURAK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "UŞAK",
        "DISTRICT": "MERKEZ",
        "STREET": "DİKİLİTAŞ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "UŞAK",
        "DISTRICT": "MERKEZ",
        "STREET": "ELMACIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "UŞAK",
        "DISTRICT": "MERKEZ",
        "STREET": "ELMALIDERE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "UŞAK",
        "DISTRICT": "MERKEZ",
        "STREET": "EMİRFAKI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "UŞAK",
        "DISTRICT": "MERKEZ",
        "STREET": "ESKİGÜNEY KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "UŞAK",
        "DISTRICT": "MERKEZ",
        "STREET": "ESKİSARAY KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "UŞAK",
        "DISTRICT": "MERKEZ",
        "STREET": "EYNİHAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "UŞAK",
        "DISTRICT": "MERKEZ",
        "STREET": "EĞLENCE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "UŞAK",
        "DISTRICT": "MERKEZ",
        "STREET": "FAKILI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "UŞAK",
        "DISTRICT": "MERKEZ",
        "STREET": "FATİH MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "UŞAK",
        "DISTRICT": "MERKEZ",
        "STREET": "FEVZİ ÇAKMAK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "UŞAK",
        "DISTRICT": "MERKEZ",
        "STREET": "GÖKÇEDAL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "UŞAK",
        "DISTRICT": "MERKEZ",
        "STREET": "GÖKÇETEPE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "UŞAK",
        "DISTRICT": "MERKEZ",
        "STREET": "GÖĞEM KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "UŞAK",
        "DISTRICT": "MERKEZ",
        "STREET": "GÜCER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "UŞAK",
        "DISTRICT": "MERKEZ",
        "STREET": "GÜNELİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "UŞAK",
        "DISTRICT": "MERKEZ",
        "STREET": "GÜRE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "UŞAK",
        "DISTRICT": "MERKEZ",
        "STREET": "HACIKADEM MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "UŞAK",
        "DISTRICT": "MERKEZ",
        "STREET": "HALİLEFENDİÇİFTLİĞİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "UŞAK",
        "DISTRICT": "MERKEZ",
        "STREET": "HOCALAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "UŞAK",
        "DISTRICT": "MERKEZ",
        "STREET": "HİSAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "UŞAK",
        "DISTRICT": "MERKEZ",
        "STREET": "IŞIK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "UŞAK",
        "DISTRICT": "MERKEZ",
        "STREET": "KABAKLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "UŞAK",
        "DISTRICT": "MERKEZ",
        "STREET": "KALFA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "UŞAK",
        "DISTRICT": "MERKEZ",
        "STREET": "KAPANCIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "UŞAK",
        "DISTRICT": "MERKEZ",
        "STREET": "KARAAĞAÇ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "UŞAK",
        "DISTRICT": "MERKEZ",
        "STREET": "KARABEYLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "UŞAK",
        "DISTRICT": "MERKEZ",
        "STREET": "KARACAHİSAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "UŞAK",
        "DISTRICT": "MERKEZ",
        "STREET": "KARAHASAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "UŞAK",
        "DISTRICT": "MERKEZ",
        "STREET": "KARAKUYU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "UŞAK",
        "DISTRICT": "MERKEZ",
        "STREET": "KARAKÖSE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "UŞAK",
        "DISTRICT": "MERKEZ",
        "STREET": "KARLIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "UŞAK",
        "DISTRICT": "MERKEZ",
        "STREET": "KAYAĞIL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "UŞAK",
        "DISTRICT": "MERKEZ",
        "STREET": "KEDİYÜNÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "UŞAK",
        "DISTRICT": "MERKEZ",
        "STREET": "KEMALÖZ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "UŞAK",
        "DISTRICT": "MERKEZ",
        "STREET": "KILCAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "UŞAK",
        "DISTRICT": "MERKEZ",
        "STREET": "KIRKA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "UŞAK",
        "DISTRICT": "MERKEZ",
        "STREET": "KISIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "UŞAK",
        "DISTRICT": "MERKEZ",
        "STREET": "KOYUNBEYLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "UŞAK",
        "DISTRICT": "MERKEZ",
        "STREET": "KURTULUŞ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "UŞAK",
        "DISTRICT": "MERKEZ",
        "STREET": "KURŞUNLUK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "UŞAK",
        "DISTRICT": "MERKEZ",
        "STREET": "KUYUCAK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "UŞAK",
        "DISTRICT": "MERKEZ",
        "STREET": "KÖME MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "UŞAK",
        "DISTRICT": "MERKEZ",
        "STREET": "KÖPRÜBAŞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "UŞAK",
        "DISTRICT": "MERKEZ",
        "STREET": "MEHMET AKİF ERSOY MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "UŞAK",
        "DISTRICT": "MERKEZ",
        "STREET": "MENDE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "UŞAK",
        "DISTRICT": "MERKEZ",
        "STREET": "MESUDİYE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "UŞAK",
        "DISTRICT": "MERKEZ",
        "STREET": "MOLLAMUSA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "UŞAK",
        "DISTRICT": "MERKEZ",
        "STREET": "MUHARREMŞAH MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "UŞAK",
        "DISTRICT": "MERKEZ",
        "STREET": "ORMANDAMI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "UŞAK",
        "DISTRICT": "MERKEZ",
        "STREET": "ORTABAĞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "UŞAK",
        "DISTRICT": "MERKEZ",
        "STREET": "ORTAKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "UŞAK",
        "DISTRICT": "MERKEZ",
        "STREET": "OVADEMİRLER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "UŞAK",
        "DISTRICT": "MERKEZ",
        "STREET": "PAÇACILAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "UŞAK",
        "DISTRICT": "MERKEZ",
        "STREET": "SARAYALTI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "UŞAK",
        "DISTRICT": "MERKEZ",
        "STREET": "SARIDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "UŞAK",
        "DISTRICT": "MERKEZ",
        "STREET": "SELVİLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "UŞAK",
        "DISTRICT": "MERKEZ",
        "STREET": "SELVİOĞLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "UŞAK",
        "DISTRICT": "MERKEZ",
        "STREET": "SELİKLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "UŞAK",
        "DISTRICT": "MERKEZ",
        "STREET": "SORKUN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "UŞAK",
        "DISTRICT": "MERKEZ",
        "STREET": "SUSUZÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "UŞAK",
        "DISTRICT": "MERKEZ",
        "STREET": "SİRGE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "UŞAK",
        "DISTRICT": "MERKEZ",
        "STREET": "TAŞKONAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "UŞAK",
        "DISTRICT": "MERKEZ",
        "STREET": "ULUCAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "UŞAK",
        "DISTRICT": "MERKEZ",
        "STREET": "UŞAK MERKEZ E TİPİ KAPALI CEZA EVİ",
        "TYPE": "0"
      },
      {
        "CITY": "UŞAK",
        "DISTRICT": "MERKEZ",
        "STREET": "YAPAĞILAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "UŞAK",
        "DISTRICT": "MERKEZ",
        "STREET": "YAVİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "UŞAK",
        "DISTRICT": "MERKEZ",
        "STREET": "YAŞAMIŞLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "UŞAK",
        "DISTRICT": "MERKEZ",
        "STREET": "YENİKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "UŞAK",
        "DISTRICT": "MERKEZ",
        "STREET": "YENİŞEHİR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "UŞAK",
        "DISTRICT": "MERKEZ",
        "STREET": "YEŞİL KARAAĞAÇ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "UŞAK",
        "DISTRICT": "MERKEZ",
        "STREET": "YEŞİLDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "UŞAK",
        "DISTRICT": "MERKEZ",
        "STREET": "YONCALI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "UŞAK",
        "DISTRICT": "MERKEZ",
        "STREET": "ZAHMAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "UŞAK",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇALIKHASAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "UŞAK",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇAMYAZI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "UŞAK",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇAMYUVA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "UŞAK",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇARIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "UŞAK",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇATALBAYIR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "UŞAK",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇEVRE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "UŞAK",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇINARCIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "UŞAK",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇUKURAĞIL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "UŞAK",
        "DISTRICT": "MERKEZ",
        "STREET": "ÖRENCİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "UŞAK",
        "DISTRICT": "MERKEZ",
        "STREET": "ÖZDEMİR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "UŞAK",
        "DISTRICT": "MERKEZ",
        "STREET": "ÜNALAN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "UŞAK",
        "DISTRICT": "MERKEZ",
        "STREET": "ÜRÜN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "UŞAK",
        "DISTRICT": "MERKEZ",
        "STREET": "ÜÇKUYULAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "UŞAK",
        "DISTRICT": "MERKEZ",
        "STREET": "İKİSARAY MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "UŞAK",
        "DISTRICT": "MERKEZ",
        "STREET": "İLYASLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "UŞAK",
        "DISTRICT": "MERKEZ",
        "STREET": "İSLİCE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "UŞAK",
        "DISTRICT": "MERKEZ",
        "STREET": "ŞÜKRANİYE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "BAHÇESARAY",
        "STREET": "AKYAYLA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "BAHÇESARAY",
        "STREET": "ALTINDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "BAHÇESARAY",
        "STREET": "ALTINTAS MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "BAHÇESARAY",
        "STREET": "ARVAS MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "BAHÇESARAY",
        "STREET": "BAĞCILAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "BAHÇESARAY",
        "STREET": "CEVİZLİBELEN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "BAHÇESARAY",
        "STREET": "ELMAYAKA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "BAHÇESARAY",
        "STREET": "FEKİYE TEYRAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "BAHÇESARAY",
        "STREET": "GÜNEYYAMAÇ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "BAHÇESARAY",
        "STREET": "KAŞIKÇILAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "BAHÇESARAY",
        "STREET": "PAŞAKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "BAHÇESARAY",
        "STREET": "ULUBEYLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "BAHÇESARAY",
        "STREET": "YAYLAKONAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "BAHÇESARAY",
        "STREET": "YAŞLIKAVAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "BAHÇESARAY",
        "STREET": "ÇATBAYIR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "BAHÇESARAY",
        "STREET": "ÇÖMLEKÇİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "BAHÇESARAY",
        "STREET": "ÇİÇEKLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "BAHÇESARAY",
        "STREET": "ÜNLÜCE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "BAHÇESARAY",
        "STREET": "İSLAM MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "BAHÇESARAY",
        "STREET": "ŞİŞLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "BAŞKALE",
        "STREET": "AKÇALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "BAŞKALE",
        "STREET": "ALBAYRAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "BAŞKALE",
        "STREET": "ATLILAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "BAŞKALE",
        "STREET": "AYDEMİR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "BAŞKALE",
        "STREET": "AZIKLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "BAŞKALE",
        "STREET": "AÇIKAĞIL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "BAŞKALE",
        "STREET": "AŞALAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "BAŞKALE",
        "STREET": "AŞAĞIKÜME MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "BAŞKALE",
        "STREET": "BARIŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "BAŞKALE",
        "STREET": "BELENCİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "BAŞKALE",
        "STREET": "BEŞOCAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "BAŞKALE",
        "STREET": "BÖLEKLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "BAŞKALE",
        "STREET": "BÖĞRÜPEK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "BAŞKALE",
        "STREET": "BÜKLÜMDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "BAŞKALE",
        "STREET": "BİLGEÇ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "BAŞKALE",
        "STREET": "DEREİÇİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "BAŞKALE",
        "STREET": "DERİNGEÇİT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "BAŞKALE",
        "STREET": "EKECEK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "BAŞKALE",
        "STREET": "EREK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "BAŞKALE",
        "STREET": "ERKONAĞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "BAŞKALE",
        "STREET": "ESENYAMAÇ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "BAŞKALE",
        "STREET": "EVBAKAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "BAŞKALE",
        "STREET": "EŞMEPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "BAŞKALE",
        "STREET": "GEDİKBAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "BAŞKALE",
        "STREET": "GELENLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "BAŞKALE",
        "STREET": "GÜLEÇLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "BAŞKALE",
        "STREET": "GÜROLUK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "BAŞKALE",
        "STREET": "GÜVENDİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "BAŞKALE",
        "STREET": "HAFİZİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "BAŞKALE",
        "STREET": "HASANBEY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "BAŞKALE",
        "STREET": "ILICAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "BAŞKALE",
        "STREET": "IŞIKLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "BAŞKALE",
        "STREET": "KAVURGALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "BAŞKALE",
        "STREET": "KAŞKOL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "BAŞKALE",
        "STREET": "KEÇİLİOBA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "BAŞKALE",
        "STREET": "KIZILCA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "BAŞKALE",
        "STREET": "KOCAKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "BAŞKALE",
        "STREET": "KONUKSAYAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "BAŞKALE",
        "STREET": "KOVALIPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "BAŞKALE",
        "STREET": "KOÇDAĞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "BAŞKALE",
        "STREET": "KÖPRÜAĞZI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "BAŞKALE",
        "STREET": "MAHMUTABAT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "BAŞKALE",
        "STREET": "ORTAYAZI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "BAŞKALE",
        "STREET": "ORTAYOL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "BAŞKALE",
        "STREET": "OĞULVEREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "BAŞKALE",
        "STREET": "SALLIDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "BAŞKALE",
        "STREET": "SAVAŞKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "BAŞKALE",
        "STREET": "SAÇAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "BAŞKALE",
        "STREET": "SUALTI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "BAŞKALE",
        "STREET": "TAHIL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "BAŞKALE",
        "STREET": "TEPEBAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "BAŞKALE",
        "STREET": "TINAZLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "BAŞKALE",
        "STREET": "UĞURLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "BAŞKALE",
        "STREET": "YANAL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "BAŞKALE",
        "STREET": "YAVUZLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "BAŞKALE",
        "STREET": "YENİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "BAŞKALE",
        "STREET": "YOLMAÇAYIR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "BAŞKALE",
        "STREET": "YUKARIDALLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "BAŞKALE",
        "STREET": "YUKARIDARICA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "BAŞKALE",
        "STREET": "YUKARIDİKMEN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "BAŞKALE",
        "STREET": "YURTTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "BAŞKALE",
        "STREET": "ÇAKIRDOĞAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "BAŞKALE",
        "STREET": "ÇALDIRAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "BAŞKALE",
        "STREET": "ÖMERDAĞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "BAŞKALE",
        "STREET": "ÖNCÜLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "BAŞKALE",
        "STREET": "ÖRENCİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "BAŞKALE",
        "STREET": "ÖRENKALE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "BAŞKALE",
        "STREET": "ÖRMETAŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "BAŞKALE",
        "STREET": "ÖZPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "EDREMİT",
        "STREET": "AKIN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "EDREMİT",
        "STREET": "ANDAÇ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "EDREMİT",
        "STREET": "AYAZPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "EDREMİT",
        "STREET": "BAKACIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "EDREMİT",
        "STREET": "BAKIMLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "EDREMİT",
        "STREET": "DOĞANLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "EDREMİT",
        "STREET": "DÖNEMEÇ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "EDREMİT",
        "STREET": "DİLKAYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "EDREMİT",
        "STREET": "ELMALIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "EDREMİT",
        "STREET": "EMİNPAŞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "EDREMİT",
        "STREET": "ENGİNSU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "EDREMİT",
        "STREET": "ERDEMKENT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "EDREMİT",
        "STREET": "ERENKENT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "EDREMİT",
        "STREET": "ESENTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "EDREMİT",
        "STREET": "ESKİ CAMİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "EDREMİT",
        "STREET": "GÖLKAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "EDREMİT",
        "STREET": "KAVURMA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "EDREMİT",
        "STREET": "KIYICAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "EDREMİT",
        "STREET": "KURUBAŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "EDREMİT",
        "STREET": "KÖKLÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "EDREMİT",
        "STREET": "KÖPRÜLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "EDREMİT",
        "STREET": "KÖŞKKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "EDREMİT",
        "STREET": "MÜLK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "EDREMİT",
        "STREET": "SELAHATTİN EYYUBİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "EDREMİT",
        "STREET": "SÜPHAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "EDREMİT",
        "STREET": "TAŞKONAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "EDREMİT",
        "STREET": "YENİ CAMİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "EDREMİT",
        "STREET": "YENİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "EDREMİT",
        "STREET": "ÇAYIRBAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "EDREMİT",
        "STREET": "ŞABANİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "ERCİŞ",
        "STREET": "ABDALMEZRASI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "ERCİŞ",
        "STREET": "ADNAN MENDERES MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "ERCİŞ",
        "STREET": "AKBAŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "ERCİŞ",
        "STREET": "AKSAKAL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "ERCİŞ",
        "STREET": "AKÇAYUVA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "ERCİŞ",
        "STREET": "ALKANAT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "ERCİŞ",
        "STREET": "AĞAÇÖREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "ERCİŞ",
        "STREET": "AĞIRKAYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "ERCİŞ",
        "STREET": "AŞAĞIAKÇAGEDİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "ERCİŞ",
        "STREET": "AŞAĞIGÖZE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "ERCİŞ",
        "STREET": "AŞAĞIIŞIKLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "ERCİŞ",
        "STREET": "AŞAĞIKOZLUCA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "ERCİŞ",
        "STREET": "AŞAĞIÇÖKEK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "ERCİŞ",
        "STREET": "BAYRAMLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "ERCİŞ",
        "STREET": "BEYAZIT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "ERCİŞ",
        "STREET": "BOZYAKA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "ERCİŞ",
        "STREET": "BUCAKÖNÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "ERCİŞ",
        "STREET": "BULAMAÇ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "ERCİŞ",
        "STREET": "CAMİİKEBİR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "ERCİŞ",
        "STREET": "DELİÇAY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "ERCİŞ",
        "STREET": "DEREKENT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "ERCİŞ",
        "STREET": "DERİMEVİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "ERCİŞ",
        "STREET": "DOLUCA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "ERCİŞ",
        "STREET": "DOĞANCI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "ERCİŞ",
        "STREET": "DURACAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "ERCİŞ",
        "STREET": "DÜVENCİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "ERCİŞ",
        "STREET": "DİNLENCE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "ERCİŞ",
        "STREET": "EKİCİLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "ERCİŞ",
        "STREET": "ERCİŞ KAPALI CEZA İNFAZ KURUMU",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "ERCİŞ",
        "STREET": "ERGÜCÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "ERCİŞ",
        "STREET": "EVBEYLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "ERCİŞ",
        "STREET": "GEDİKDİBİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "ERCİŞ",
        "STREET": "GERGİLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "ERCİŞ",
        "STREET": "GÖKOĞLAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "ERCİŞ",
        "STREET": "GÖLAĞZI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "ERCİŞ",
        "STREET": "GÖRÜŞLÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "ERCİŞ",
        "STREET": "GÖZÜTOK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "ERCİŞ",
        "STREET": "GÜLTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "ERCİŞ",
        "STREET": "GÜMÜŞOLUK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "ERCİŞ",
        "STREET": "HACIKAŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "ERCİŞ",
        "STREET": "HASANABDAL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "ERCİŞ",
        "STREET": "HAYDARBEY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "ERCİŞ",
        "STREET": "HOCAALİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "ERCİŞ",
        "STREET": "KADİRASKER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "ERCİŞ",
        "STREET": "KARATAVUK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "ERCİŞ",
        "STREET": "KARDOĞAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "ERCİŞ",
        "STREET": "KARLIYAYLA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "ERCİŞ",
        "STREET": "KASIMBAĞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "ERCİŞ",
        "STREET": "KAYABOYUN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "ERCİŞ",
        "STREET": "KEKLİKOVA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "ERCİŞ",
        "STREET": "KEKİKSIRTI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "ERCİŞ",
        "STREET": "KIRKDEĞİRMEN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "ERCİŞ",
        "STREET": "KIRKPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "ERCİŞ",
        "STREET": "KIZILÖREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "ERCİŞ",
        "STREET": "KIŞLA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "ERCİŞ",
        "STREET": "KOCAPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "ERCİŞ",
        "STREET": "KOÇKÖPRÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "ERCİŞ",
        "STREET": "KÖYCÜK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "ERCİŞ",
        "STREET": "LATİFİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "ERCİŞ",
        "STREET": "MAĞARA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "ERCİŞ",
        "STREET": "NİŞANCI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "ERCİŞ",
        "STREET": "ORTAYAYLA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "ERCİŞ",
        "STREET": "OYALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "ERCİŞ",
        "STREET": "PAYKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "ERCİŞ",
        "STREET": "PINARLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "ERCİŞ",
        "STREET": "SABANBÜKEN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "ERCİŞ",
        "STREET": "SAHİL KENT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "ERCİŞ",
        "STREET": "SALMANAĞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "ERCİŞ",
        "STREET": "SALİHİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "ERCİŞ",
        "STREET": "SÖĞÜTLÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "ERCİŞ",
        "STREET": "TAŞEVLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "ERCİŞ",
        "STREET": "TAŞKAPI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "ERCİŞ",
        "STREET": "TAŞLIÇAY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "ERCİŞ",
        "STREET": "TEKEVLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "ERCİŞ",
        "STREET": "TEKLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "ERCİŞ",
        "STREET": "TOPRAKLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "ERCİŞ",
        "STREET": "ULUPAMİR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "ERCİŞ",
        "STREET": "UNCULAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "ERCİŞ",
        "STREET": "VANYOLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "ERCİŞ",
        "STREET": "YALINDAM MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "ERCİŞ",
        "STREET": "YANKITEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "ERCİŞ",
        "STREET": "YETİŞEN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "ERCİŞ",
        "STREET": "YEŞİLOVA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "ERCİŞ",
        "STREET": "YILANLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "ERCİŞ",
        "STREET": "YOLDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "ERCİŞ",
        "STREET": "YUKARIAKÇAGEDİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "ERCİŞ",
        "STREET": "YUKARIIŞIKLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "ERCİŞ",
        "STREET": "YÖRELİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "ERCİŞ",
        "STREET": "YÜNÖREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "ERCİŞ",
        "STREET": "ÇAKIRBEY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "ERCİŞ",
        "STREET": "ÇATAKDİBİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "ERCİŞ",
        "STREET": "ÇATALTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "ERCİŞ",
        "STREET": "ÇELEBİBAĞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "ERCİŞ",
        "STREET": "ÇETİNTAŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "ERCİŞ",
        "STREET": "ÇINARLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "ERCİŞ",
        "STREET": "ÇOBANDÜZÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "ERCİŞ",
        "STREET": "ÇUBUKLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "ERCİŞ",
        "STREET": "ÇİMEN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "ERCİŞ",
        "STREET": "ÖRENE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "ERCİŞ",
        "STREET": "İKİZÇALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "ERCİŞ",
        "STREET": "İŞBAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "ERCİŞ",
        "STREET": "ŞEHİRPAZAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "ERCİŞ",
        "STREET": "ŞEREFLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "GEVAŞ",
        "STREET": "ABALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "GEVAŞ",
        "STREET": "ALADÜZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "GEVAŞ",
        "STREET": "ALTINSAÇ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "GEVAŞ",
        "STREET": "ANAKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "GEVAŞ",
        "STREET": "ATALAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "GEVAŞ",
        "STREET": "AYDINOCAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "GEVAŞ",
        "STREET": "BAHÇELİEVLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "GEVAŞ",
        "STREET": "BARIŞIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "GEVAŞ",
        "STREET": "BAĞLAMA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "GEVAŞ",
        "STREET": "DALDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "GEVAŞ",
        "STREET": "DAĞYÖRE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "GEVAŞ",
        "STREET": "DEREAĞZI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "GEVAŞ",
        "STREET": "DEĞİRMİTAŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "GEVAŞ",
        "STREET": "DOKUZAĞAÇ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "GEVAŞ",
        "STREET": "DİLMETAŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "GEVAŞ",
        "STREET": "ELMALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "GEVAŞ",
        "STREET": "GÖRÜNDÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "GEVAŞ",
        "STREET": "GÜNDOĞAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "GEVAŞ",
        "STREET": "GÜZELKONAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "GEVAŞ",
        "STREET": "HASBEY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "GEVAŞ",
        "STREET": "HİŞET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "GEVAŞ",
        "STREET": "KARŞIYAKA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "GEVAŞ",
        "STREET": "KAYALAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "GEVAŞ",
        "STREET": "KAZANÇ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "GEVAŞ",
        "STREET": "KIZILTAŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "GEVAŞ",
        "STREET": "KOÇAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "GEVAŞ",
        "STREET": "KURULTU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "GEVAŞ",
        "STREET": "KUŞLUK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "GEVAŞ",
        "STREET": "ORTA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "GEVAŞ",
        "STREET": "PINARBAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "GEVAŞ",
        "STREET": "SELİMİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "GEVAŞ",
        "STREET": "TÖRELİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "GEVAŞ",
        "STREET": "TİMAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "GEVAŞ",
        "STREET": "UYSAL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "GEVAŞ",
        "STREET": "UĞURVEREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "GEVAŞ",
        "STREET": "YANIKÇAY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "GEVAŞ",
        "STREET": "YEMİŞLİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "GEVAŞ",
        "STREET": "YOLDÖNDÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "GEVAŞ",
        "STREET": "YUVA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "GEVAŞ",
        "STREET": "İKİZLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "GEVAŞ",
        "STREET": "İNKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "GÜRPINAR",
        "STREET": "AKBULUT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "GÜRPINAR",
        "STREET": "AKDOĞU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "GÜRPINAR",
        "STREET": "AKPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "GÜRPINAR",
        "STREET": "ALNIAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "GÜRPINAR",
        "STREET": "ARINDI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "GÜRPINAR",
        "STREET": "AŞAĞIKAYMAZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "GÜRPINAR",
        "STREET": "BAĞRIYANIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "GÜRPINAR",
        "STREET": "BEŞBUDAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "GÜRPINAR",
        "STREET": "BOZYİĞİT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "GÜRPINAR",
        "STREET": "BÖLMEÇALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "GÜRPINAR",
        "STREET": "BÜKEÇ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "GÜRPINAR",
        "STREET": "CEVİZALAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "GÜRPINAR",
        "STREET": "CUMHURİYET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "GÜRPINAR",
        "STREET": "DAĞSEVEN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "GÜRPINAR",
        "STREET": "DEĞİRMENDÜZÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "GÜRPINAR",
        "STREET": "DOLAYLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "GÜRPINAR",
        "STREET": "DOLUÇIKIN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "GÜRPINAR",
        "STREET": "DİKBIYIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "GÜRPINAR",
        "STREET": "ELAÇMAZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "GÜRPINAR",
        "STREET": "ERKALDI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "GÜRPINAR",
        "STREET": "GEZİYURT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "GÜRPINAR",
        "STREET": "GEÇERLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "GÜRPINAR",
        "STREET": "GÖLARDI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "GÜRPINAR",
        "STREET": "GÜLEÇLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "GÜRPINAR",
        "STREET": "GÜNBAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "GÜRPINAR",
        "STREET": "GİYİMLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "GÜRPINAR",
        "STREET": "HACIKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "GÜRPINAR",
        "STREET": "HOŞAB MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "GÜRPINAR",
        "STREET": "IŞIKPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "GÜRPINAR",
        "STREET": "KALKANLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "GÜRPINAR",
        "STREET": "KAPÇIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "GÜRPINAR",
        "STREET": "KARAKOÇ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "GÜRPINAR",
        "STREET": "KILIÇTUTAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "GÜRPINAR",
        "STREET": "KIRKGEÇİT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "GÜRPINAR",
        "STREET": "KOYUNYATAĞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "GÜRPINAR",
        "STREET": "KOÇGÜDEN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "GÜRPINAR",
        "STREET": "KUŞDAĞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "GÜRPINAR",
        "STREET": "MOLLAHÜSEYİN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "GÜRPINAR",
        "STREET": "MURATALDI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "GÜRPINAR",
        "STREET": "ONGUN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "GÜRPINAR",
        "STREET": "ORTAKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "GÜRPINAR",
        "STREET": "OTBİÇER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "GÜRPINAR",
        "STREET": "OĞULDAMI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "GÜRPINAR",
        "STREET": "PARMAKKAPI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "GÜRPINAR",
        "STREET": "SAKALAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "GÜRPINAR",
        "STREET": "SAPAKONAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "GÜRPINAR",
        "STREET": "SAVACIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "GÜRPINAR",
        "STREET": "SEVİNDİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "GÜRPINAR",
        "STREET": "SICAKSU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "GÜRPINAR",
        "STREET": "SÜTLÜCE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "GÜRPINAR",
        "STREET": "TAŞDÖNDÜREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "GÜRPINAR",
        "STREET": "TAŞLIYAZI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "GÜRPINAR",
        "STREET": "TEPEGÖREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "GÜRPINAR",
        "STREET": "TOPSAKAL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "GÜRPINAR",
        "STREET": "TOPYILDIZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "GÜRPINAR",
        "STREET": "TOPÇUDEĞİRMENİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "GÜRPINAR",
        "STREET": "TUTAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "GÜRPINAR",
        "STREET": "TUTMAÇ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "GÜRPINAR",
        "STREET": "UMUT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "GÜRPINAR",
        "STREET": "UZUNGEDİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "GÜRPINAR",
        "STREET": "YALINCA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "GÜRPINAR",
        "STREET": "YARAMIŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "GÜRPINAR",
        "STREET": "YATAĞAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "GÜRPINAR",
        "STREET": "YEDİSALKIM MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "GÜRPINAR",
        "STREET": "YOLAŞAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "GÜRPINAR",
        "STREET": "YOLDÜŞTÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "GÜRPINAR",
        "STREET": "YUKARIKAYMAZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "GÜRPINAR",
        "STREET": "YURTBAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "GÜRPINAR",
        "STREET": "ZERNEK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "GÜRPINAR",
        "STREET": "ÇAKINLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "GÜRPINAR",
        "STREET": "ÇATAKDİBİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "GÜRPINAR",
        "STREET": "ÇAVUŞTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "GÜRPINAR",
        "STREET": "ÇEPKENLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "GÜRPINAR",
        "STREET": "ÇÖREKLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "GÜRPINAR",
        "STREET": "ÖRMELİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "GÜRPINAR",
        "STREET": "ÖVEÇLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "GÜRPINAR",
        "STREET": "ÖZLÜCE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "GÜRPINAR",
        "STREET": "ÜÇDOĞAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "GÜRPINAR",
        "STREET": "ÜÇGEN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "MURADİYE",
        "STREET": "ADAKLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "MURADİYE",
        "STREET": "AKBULAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "MURADİYE",
        "STREET": "AKÇAÇAY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "MURADİYE",
        "STREET": "ALKASNAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "MURADİYE",
        "STREET": "AÇIKYOL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "MURADİYE",
        "STREET": "AŞAĞIARGIT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "MURADİYE",
        "STREET": "BABACAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "MURADİYE",
        "STREET": "BALAKLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "MURADİYE",
        "STREET": "BEYDAĞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "MURADİYE",
        "STREET": "BEŞPARMAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "MURADİYE",
        "STREET": "DAĞÖREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "MURADİYE",
        "STREET": "DEVETAŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "MURADİYE",
        "STREET": "DOĞANGÜN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "MURADİYE",
        "STREET": "DÜRÜKKAŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "MURADİYE",
        "STREET": "ERİŞEN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "MURADİYE",
        "STREET": "ESENKOÇ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "MURADİYE",
        "STREET": "FATİH MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "MURADİYE",
        "STREET": "FEVZİ ÇAKMAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "MURADİYE",
        "STREET": "GÖNDERME MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "MURADİYE",
        "STREET": "GÖRECEK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "MURADİYE",
        "STREET": "GÜLLÜÇİMEN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "MURADİYE",
        "STREET": "GÜLTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "MURADİYE",
        "STREET": "GÜMÜŞTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "MURADİYE",
        "STREET": "KANDAHAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "MURADİYE",
        "STREET": "KARAHAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "MURADİYE",
        "STREET": "KARAOĞLAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "MURADİYE",
        "STREET": "KEMERKÖPRÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "MURADİYE",
        "STREET": "KEÇİKIRAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "MURADİYE",
        "STREET": "KOCASABAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "MURADİYE",
        "STREET": "KUŞÇU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "MURADİYE",
        "STREET": "KÖŞK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "MURADİYE",
        "STREET": "OTLAKBAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "MURADİYE",
        "STREET": "OVAPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "MURADİYE",
        "STREET": "SARIMEMET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "MURADİYE",
        "STREET": "SÜRÜYOLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "MURADİYE",
        "STREET": "TANSU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "MURADİYE",
        "STREET": "TOPUZARPA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "MURADİYE",
        "STREET": "ULUŞAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "MURADİYE",
        "STREET": "YAKINCAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "MURADİYE",
        "STREET": "YALINDÜZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "MURADİYE",
        "STREET": "YAVUZSELİM MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "MURADİYE",
        "STREET": "YENİŞEHİR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "MURADİYE",
        "STREET": "YUKARIARGIT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "MURADİYE",
        "STREET": "YUMAKLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "MURADİYE",
        "STREET": "ÇAKMAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "MURADİYE",
        "STREET": "ÇİÇEKLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "MURADİYE",
        "STREET": "ÜNSELİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "MURADİYE",
        "STREET": "ŞEHİTMEHMETBEY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "SARAY",
        "STREET": "ATATÜRK  MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "SARAY",
        "STREET": "BAKIŞIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "SARAY",
        "STREET": "BALTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "SARAY",
        "STREET": "BEYARSLAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "SARAY",
        "STREET": "DEĞİRMİGÖL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "SARAY",
        "STREET": "DOLUTAŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "SARAY",
        "STREET": "FERİTMELEN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "SARAY",
        "STREET": "KAPIKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "SARAY",
        "STREET": "KARAHİSAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "SARAY",
        "STREET": "KARGALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "SARAY",
        "STREET": "KAZIMPAŞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "SARAY",
        "STREET": "KAZLIGÖL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "SARAY",
        "STREET": "KEKİKDÜZÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "SARAY",
        "STREET": "KEPİR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "SARAY",
        "STREET": "KEÇİKAYASI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "SARAY",
        "STREET": "KOÇBAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "SARAY",
        "STREET": "KURUCAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "SARAY",
        "STREET": "MAHMUDİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "SARAY",
        "STREET": "SIRIMLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "SARAY",
        "STREET": "TURANKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "SARAY",
        "STREET": "YAMANYURT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "SARAY",
        "STREET": "YEŞİLAĞAÇ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "SARAY",
        "STREET": "ZİNCİRKIRAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "SARAY",
        "STREET": "ÇAKMAKKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "SARAY",
        "STREET": "ÇARTAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "SARAY",
        "STREET": "ÇAYBAĞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "SARAY",
        "STREET": "ÖRENBURÇ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "TUŞBA",
        "STREET": "ABDURRAHMAN GAZİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "TUŞBA",
        "STREET": "ADIGÜZEL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "TUŞBA",
        "STREET": "AKKÖPRÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "TUŞBA",
        "STREET": "AKÇAÖREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "TUŞBA",
        "STREET": "AKÇİFT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "TUŞBA",
        "STREET": "ALABAYIR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "TUŞBA",
        "STREET": "ALAKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "TUŞBA",
        "STREET": "ALTINTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "TUŞBA",
        "STREET": "ARISU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "TUŞBA",
        "STREET": "ATMACA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "TUŞBA",
        "STREET": "AYANIS MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "TUŞBA",
        "STREET": "AŞIT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "TUŞBA",
        "STREET": "BARDAKÇI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "TUŞBA",
        "STREET": "BAĞDAŞAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "TUŞBA",
        "STREET": "BEYÜZÜMÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "TUŞBA",
        "STREET": "CANİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "TUŞBA",
        "STREET": "DAĞÖNÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "TUŞBA",
        "STREET": "DEREBEY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "TUŞBA",
        "STREET": "DEĞİRMENÖZÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "TUŞBA",
        "STREET": "DİBEKDÜZÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "TUŞBA",
        "STREET": "DİLİMLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "TUŞBA",
        "STREET": "ERMİŞLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "TUŞBA",
        "STREET": "ESENPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "TUŞBA",
        "STREET": "GEDELOVA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "TUŞBA",
        "STREET": "GÖLLÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "TUŞBA",
        "STREET": "GÖLYAZI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "TUŞBA",
        "STREET": "GÜLSÜNLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "TUŞBA",
        "STREET": "GÜVENÇLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "TUŞBA",
        "STREET": "HALKALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "TUŞBA",
        "STREET": "HIDIRKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "TUŞBA",
        "STREET": "KALECİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "TUŞBA",
        "STREET": "KARAAĞAÇ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "TUŞBA",
        "STREET": "KASIMOĞLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "TUŞBA",
        "STREET": "KELLE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "TUŞBA",
        "STREET": "KOLSATAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "TUŞBA",
        "STREET": "KOZLUCA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "TUŞBA",
        "STREET": "KOÇKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "TUŞBA",
        "STREET": "KUMLUCA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "TUŞBA",
        "STREET": "MEYDANCIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "TUŞBA",
        "STREET": "MOLLAKASIM MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "TUŞBA",
        "STREET": "OCAKLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "TUŞBA",
        "STREET": "OTLUCA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "TUŞBA",
        "STREET": "PİRGARİP MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "TUŞBA",
        "STREET": "SATIBEY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "TUŞBA",
        "STREET": "SAĞLAMTAŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "TUŞBA",
        "STREET": "SEYRANTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "TUŞBA",
        "STREET": "TABANLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "TUŞBA",
        "STREET": "TEVEKLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "TUŞBA",
        "STREET": "TOPAKTAŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "TUŞBA",
        "STREET": "VAN F TİPİ CEZAEVİ",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "TUŞBA",
        "STREET": "VAN M TİPİ CEZAEVİ",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "TUŞBA",
        "STREET": "VAN T TİPİ CEZAEVİ",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "TUŞBA",
        "STREET": "VAN YÜKSEK GÜVENLİKLİ",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "TUŞBA",
        "STREET": "YALNIZAĞAÇ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "TUŞBA",
        "STREET": "YAYLIYAKA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "TUŞBA",
        "STREET": "YEMLİCE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "TUŞBA",
        "STREET": "YENİKÖŞK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "TUŞBA",
        "STREET": "YEŞİLKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "TUŞBA",
        "STREET": "YEŞİLSU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "TUŞBA",
        "STREET": "YUMRUTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "TUŞBA",
        "STREET": "ÇAKIRBEY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "TUŞBA",
        "STREET": "ÇOBANOĞLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "TUŞBA",
        "STREET": "ÇOLPAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "TUŞBA",
        "STREET": "ÇOMAKLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "TUŞBA",
        "STREET": "ÇİTÖREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "TUŞBA",
        "STREET": "ÖZKAYNAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "TUŞBA",
        "STREET": "ÖZYURT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "TUŞBA",
        "STREET": "İSKELE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "TUŞBA",
        "STREET": "İSTASYON MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "TUŞBA",
        "STREET": "ŞAHGELDİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "TUŞBA",
        "STREET": "ŞEMSİBEY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "ÇALDIRAN",
        "STREET": "AKBAŞAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "ÇALDIRAN",
        "STREET": "ALAKAYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "ÇALDIRAN",
        "STREET": "ALTIYOL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "ÇALDIRAN",
        "STREET": "ALİKELLE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "ÇALDIRAN",
        "STREET": "AVCIBAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "ÇALDIRAN",
        "STREET": "AYRANCILAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "ÇALDIRAN",
        "STREET": "AŞAĞIDİKME MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "ÇALDIRAN",
        "STREET": "AŞAĞIGÜLDEREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "ÇALDIRAN",
        "STREET": "AŞAĞIKUYUCAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "ÇALDIRAN",
        "STREET": "AŞAĞIMUTLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "ÇALDIRAN",
        "STREET": "AŞAĞIYANIKTAŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "ÇALDIRAN",
        "STREET": "AŞAĞIÇANAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "ÇALDIRAN",
        "STREET": "BAŞEĞMEZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "ÇALDIRAN",
        "STREET": "BEYAZIT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "ÇALDIRAN",
        "STREET": "BEYDOĞAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "ÇALDIRAN",
        "STREET": "BEZİRHANE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "ÇALDIRAN",
        "STREET": "BURÇAKALAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "ÇALDIRAN",
        "STREET": "BUĞULUKAYNAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "ÇALDIRAN",
        "STREET": "CUMHURİYET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "ÇALDIRAN",
        "STREET": "DEMİRCİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "ÇALDIRAN",
        "STREET": "DOYUMALAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "ÇALDIRAN",
        "STREET": "DİREKLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "ÇALDIRAN",
        "STREET": "ERGİNLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "ÇALDIRAN",
        "STREET": "EVCİLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "ÇALDIRAN",
        "STREET": "FATİH SULTAN MEHMET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "ÇALDIRAN",
        "STREET": "GÜLYOLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "ÇALDIRAN",
        "STREET": "GÜNGÖREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "ÇALDIRAN",
        "STREET": "HANGEDİĞİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "ÇALDIRAN",
        "STREET": "HANKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "ÇALDIRAN",
        "STREET": "KALKANDELEN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "ÇALDIRAN",
        "STREET": "KARADULDA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "ÇALDIRAN",
        "STREET": "KAŞIM MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "ÇALDIRAN",
        "STREET": "KILAVUZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "ÇALDIRAN",
        "STREET": "KOÇOVASI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "ÇALDIRAN",
        "STREET": "KURTOĞLAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "ÇALDIRAN",
        "STREET": "KUSKUNKIRAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "ÇALDIRAN",
        "STREET": "KİLİMLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "ÇALDIRAN",
        "STREET": "OSMANLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "ÇALDIRAN",
        "STREET": "RECEP TAYYİP ERDOĞAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "ÇALDIRAN",
        "STREET": "SALAHANE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "ÇALDIRAN",
        "STREET": "SARIHARMAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "ÇALDIRAN",
        "STREET": "SARIÇİMEN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "ÇALDIRAN",
        "STREET": "SELLİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "ÇALDIRAN",
        "STREET": "SERPMETAŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "ÇALDIRAN",
        "STREET": "SOĞUKSU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "ÇALDIRAN",
        "STREET": "SUNGUR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "ÇALDIRAN",
        "STREET": "TEKİNDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "ÇALDIRAN",
        "STREET": "TEMRENLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "ÇALDIRAN",
        "STREET": "TOPRAKSEVEN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "ÇALDIRAN",
        "STREET": "UMUTTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "ÇALDIRAN",
        "STREET": "YASSITEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "ÇALDIRAN",
        "STREET": "YAVUZ SELİM MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "ÇALDIRAN",
        "STREET": "YAYKILIÇ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "ÇALDIRAN",
        "STREET": "YAĞIBASAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "ÇALDIRAN",
        "STREET": "YENİYAKA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "ÇALDIRAN",
        "STREET": "YUKARIDİKME MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "ÇALDIRAN",
        "STREET": "YUKARIGÜLDEREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "ÇALDIRAN",
        "STREET": "YUKARIKUYUCAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "ÇALDIRAN",
        "STREET": "YUKARIMUTLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "ÇALDIRAN",
        "STREET": "YUKARISAĞMALLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "ÇALDIRAN",
        "STREET": "YUKARIYANIKTAŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "ÇALDIRAN",
        "STREET": "YUKARIÇANAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "ÇALDIRAN",
        "STREET": "YUVACIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "ÇALDIRAN",
        "STREET": "YÜCELEN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "ÇALDIRAN",
        "STREET": "ZÜLFİBULAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "ÇALDIRAN",
        "STREET": "ÇAYIRKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "ÇALDIRAN",
        "STREET": "ÇUBUKLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "ÇALDIRAN",
        "STREET": "İNCEALAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "ÇALDIRAN",
        "STREET": "İSMAİLBABA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "ÇALDIRAN",
        "STREET": "ŞEHİT JANDARMA BİNBAŞI KIVANÇ CESUR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "ÇATAK",
        "STREET": "ADNAN MENDERES MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "ÇATAK",
        "STREET": "AKÇABÜK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "ÇATAK",
        "STREET": "ALACAYAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "ÇATAK",
        "STREET": "ATLIHAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "ÇATAK",
        "STREET": "AĞAÇLIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "ÇATAK",
        "STREET": "AŞAĞINARLICA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "ÇATAK",
        "STREET": "BAHÇELİEVLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "ÇATAK",
        "STREET": "BOYUNPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "ÇATAK",
        "STREET": "BİLGİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "ÇATAK",
        "STREET": "CUMHURİYET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "ÇATAK",
        "STREET": "DALBASTI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "ÇATAK",
        "STREET": "DEREBAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "ÇATAK",
        "STREET": "DOKUZDAM MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "ÇATAK",
        "STREET": "ELİAÇIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "ÇATAK",
        "STREET": "GÖRENTAŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "ÇATAK",
        "STREET": "HACIOSMAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "ÇATAK",
        "STREET": "IŞINLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "ÇATAK",
        "STREET": "KARŞIYAKA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "ÇATAK",
        "STREET": "KAÇIT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "ÇATAK",
        "STREET": "KONALGA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "ÇATAK",
        "STREET": "KORULU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "ÇATAK",
        "STREET": "NARLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "ÇATAK",
        "STREET": "ONAĞIL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "ÇATAK",
        "STREET": "SELÇUKLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "ÇATAK",
        "STREET": "SIRMALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "ÇATAK",
        "STREET": "SUGELDİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "ÇATAK",
        "STREET": "SÖZVEREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "ÇATAK",
        "STREET": "TEKNECİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "ÇATAK",
        "STREET": "TOYGA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "ÇATAK",
        "STREET": "UZUNTEKNE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "ÇATAK",
        "STREET": "YUKARINARLICA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "ÇATAK",
        "STREET": "ÇILGA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "ÇATAK",
        "STREET": "ÖVECEK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "ÖZALP",
        "STREET": "AKSORGUÇ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "ÖZALP",
        "STREET": "ALTINBOĞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "ÖZALP",
        "STREET": "AŞAĞIAKÇAGÜL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "ÖZALP",
        "STREET": "AŞAĞIBALÇIKLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "ÖZALP",
        "STREET": "AŞAĞIKOÇKIRAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "ÖZALP",
        "STREET": "AŞAĞIMOLLAHASAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "ÖZALP",
        "STREET": "AŞAĞITULGALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "ÖZALP",
        "STREET": "AŞAĞIYORGANLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "ÖZALP",
        "STREET": "BAĞRIAÇIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "ÖZALP",
        "STREET": "BODURAĞAÇ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "ÖZALP",
        "STREET": "BOYALDI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "ÖZALP",
        "STREET": "BOĞAZKESEN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "ÖZALP",
        "STREET": "CUMHURİYET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "ÖZALP",
        "STREET": "DAMLACIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "ÖZALP",
        "STREET": "DAĞDEVİREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "ÖZALP",
        "STREET": "DORUTAY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "ÖZALP",
        "STREET": "DÖNERDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "ÖZALP",
        "STREET": "EMEK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "ÖZALP",
        "STREET": "ESKİ EMEK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "ÖZALP",
        "STREET": "EĞRİBELEN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "ÖZALP",
        "STREET": "GÖZDEĞMEZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "ÖZALP",
        "STREET": "GÜLTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "ÖZALP",
        "STREET": "GÜNYÜZLÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "ÖZALP",
        "STREET": "HACIALİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "ÖZALP",
        "STREET": "HACIKIŞLAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "ÖZALP",
        "STREET": "HAZİNE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "ÖZALP",
        "STREET": "KALECİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "ÖZALP",
        "STREET": "KARGALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "ÖZALP",
        "STREET": "KARLIYAMAÇ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "ÖZALP",
        "STREET": "KAŞIKARA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "ÖZALP",
        "STREET": "KIRKÇALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "ÖZALP",
        "STREET": "MAHMUDİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "ÖZALP",
        "STREET": "MEHMETALAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "ÖZALP",
        "STREET": "MOLLATOPUZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "ÖZALP",
        "STREET": "OYMAKLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "ÖZALP",
        "STREET": "SARIKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "ÖZALP",
        "STREET": "SAVATLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "ÖZALP",
        "STREET": "SAĞMALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "ÖZALP",
        "STREET": "SEYDİBEY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "ÖZALP",
        "STREET": "SUGEÇER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "ÖZALP",
        "STREET": "TEPEDAM MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "ÖZALP",
        "STREET": "YARIMKAYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "ÖZALP",
        "STREET": "YAVUZLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "ÖZALP",
        "STREET": "YUKARIAYAZCA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "ÖZALP",
        "STREET": "YUKARIBALÇIKLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "ÖZALP",
        "STREET": "YUKARIMOLLAHASAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "ÖZALP",
        "STREET": "YUKARITULGALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "ÖZALP",
        "STREET": "YUKARIYORGANLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "ÖZALP",
        "STREET": "YUKARIÇAVDARLIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "ÖZALP",
        "STREET": "YUMRUKLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "ÖZALP",
        "STREET": "YÜNKUŞAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "ÖZALP",
        "STREET": "ÇAMURLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "ÖZALP",
        "STREET": "ÇAVUŞLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "ÖZALP",
        "STREET": "ÇIRAKKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "ÖZALP",
        "STREET": "ÇUBUKLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "ÖZALP",
        "STREET": "İSTASYON MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "ÖZALP",
        "STREET": "ŞEHİTTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "ÖZALP",
        "STREET": "ŞEMSETTİN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "İPEKYOLU",
        "STREET": "AKTAŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "İPEKYOLU",
        "STREET": "ALİPAŞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "İPEKYOLU",
        "STREET": "ARITOPRAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "İPEKYOLU",
        "STREET": "AĞZIKARA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "İPEKYOLU",
        "STREET": "AŞAĞIGÖLALAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "İPEKYOLU",
        "STREET": "AŞAĞIÇİTLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "İPEKYOLU",
        "STREET": "BAHÇIVAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "İPEKYOLU",
        "STREET": "BAKLATEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "İPEKYOLU",
        "STREET": "BAKRAÇLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "İPEKYOLU",
        "STREET": "BEŞÇATAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "İPEKYOLU",
        "STREET": "BOSTANİÇİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "İPEKYOLU",
        "STREET": "BUZHANE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "İPEKYOLU",
        "STREET": "CEVDET PAŞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "İPEKYOLU",
        "STREET": "CUMHURİYET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "İPEKYOLU",
        "STREET": "DEREÜSTÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "İPEKYOLU",
        "STREET": "DEĞİRMENARKI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "İPEKYOLU",
        "STREET": "DEĞİRMENKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "İPEKYOLU",
        "STREET": "DİBEKLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "İPEKYOLU",
        "STREET": "ERÇEK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "İPEKYOLU",
        "STREET": "ESENLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "İPEKYOLU",
        "STREET": "GÖVELEK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "İPEKYOLU",
        "STREET": "HACIBEKİR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "İPEKYOLU",
        "STREET": "HAFİZİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "İPEKYOLU",
        "STREET": "HALİLAĞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "İPEKYOLU",
        "STREET": "HATUNİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "İPEKYOLU",
        "STREET": "ILIKAYNAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "İPEKYOLU",
        "STREET": "IRGAT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "İPEKYOLU",
        "STREET": "KARAGÜNDÜZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "İPEKYOLU",
        "STREET": "KARAKOÇ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "İPEKYOLU",
        "STREET": "KARPUZALANI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "İPEKYOLU",
        "STREET": "KARŞIYAKA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "İPEKYOLU",
        "STREET": "KAVUNCU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "İPEKYOLU",
        "STREET": "KAYMAKLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "İPEKYOLU",
        "STREET": "KEVENLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "İPEKYOLU",
        "STREET": "KIRATLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "İPEKYOLU",
        "STREET": "KÖŞEBAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "İPEKYOLU",
        "STREET": "ORTANCA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "İPEKYOLU",
        "STREET": "SARMAÇ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "İPEKYOLU",
        "STREET": "SELİMBEY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "İPEKYOLU",
        "STREET": "SERHAT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "İPEKYOLU",
        "STREET": "SEYİT FEHİM ARVASİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "İPEKYOLU",
        "STREET": "VALİ MİTHAT BEY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "İPEKYOLU",
        "STREET": "YALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "İPEKYOLU",
        "STREET": "YALINAĞAÇ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "İPEKYOLU",
        "STREET": "YATIKSIRT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "İPEKYOLU",
        "STREET": "YENİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "İPEKYOLU",
        "STREET": "YUKARIGÖLALAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "İPEKYOLU",
        "STREET": "YUKARIGÜNEYCE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "İPEKYOLU",
        "STREET": "ÇALIMLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "VAN",
        "DISTRICT": "İPEKYOLU",
        "STREET": "ŞEREFİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "YALOVA",
        "DISTRICT": "ALTINOVA",
        "STREET": "AHMEDİYE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YALOVA",
        "DISTRICT": "ALTINOVA",
        "STREET": "AKTOPRAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YALOVA",
        "DISTRICT": "ALTINOVA",
        "STREET": "CUMHURİYET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "YALOVA",
        "DISTRICT": "ALTINOVA",
        "STREET": "FEVZİYE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YALOVA",
        "DISTRICT": "ALTINOVA",
        "STREET": "GEYİKDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YALOVA",
        "DISTRICT": "ALTINOVA",
        "STREET": "HAVUZDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YALOVA",
        "DISTRICT": "ALTINOVA",
        "STREET": "HERSEK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "YALOVA",
        "DISTRICT": "ALTINOVA",
        "STREET": "HÜRRİYET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "YALOVA",
        "DISTRICT": "ALTINOVA",
        "STREET": "KARADERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YALOVA",
        "DISTRICT": "ALTINOVA",
        "STREET": "KAYTAZDERE-MERKEZ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "YALOVA",
        "DISTRICT": "ALTINOVA",
        "STREET": "SERMAYECİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YALOVA",
        "DISTRICT": "ALTINOVA",
        "STREET": "SOĞUKSU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YALOVA",
        "DISTRICT": "ALTINOVA",
        "STREET": "SUBAŞI-ALTINKENT MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "YALOVA",
        "DISTRICT": "ALTINOVA",
        "STREET": "SUBAŞI-DENİZGÖREN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "YALOVA",
        "DISTRICT": "ALTINOVA",
        "STREET": "SUBAŞI-MERKEZ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "YALOVA",
        "DISTRICT": "ALTINOVA",
        "STREET": "TAVŞANLI-FATİH MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "YALOVA",
        "DISTRICT": "ALTINOVA",
        "STREET": "TAVŞANLI-ŞEHİTLİK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "YALOVA",
        "DISTRICT": "ALTINOVA",
        "STREET": "TOKMAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YALOVA",
        "DISTRICT": "ALTINOVA",
        "STREET": "ÇAVUŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YALOVA",
        "DISTRICT": "ALTINOVA",
        "STREET": "ÇAVUŞÇİFTLİĞİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YALOVA",
        "DISTRICT": "ALTINOVA",
        "STREET": "ÖRENCİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YALOVA",
        "DISTRICT": "ARMUTLU",
        "STREET": "50. YIL MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "YALOVA",
        "DISTRICT": "ARMUTLU",
        "STREET": "BAYIR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "YALOVA",
        "DISTRICT": "ARMUTLU",
        "STREET": "FISTIKLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YALOVA",
        "DISTRICT": "ARMUTLU",
        "STREET": "HAYRİYE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YALOVA",
        "DISTRICT": "ARMUTLU",
        "STREET": "KAPAKLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YALOVA",
        "DISTRICT": "ARMUTLU",
        "STREET": "KARŞIYAKA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "YALOVA",
        "DISTRICT": "ARMUTLU",
        "STREET": "MECİDİYE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YALOVA",
        "DISTRICT": "ARMUTLU",
        "STREET": "SELİMİYE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YALOVA",
        "DISTRICT": "TERMAL",
        "STREET": "AKKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "YALOVA",
        "DISTRICT": "TERMAL",
        "STREET": "GÖKÇEDERE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "YALOVA",
        "DISTRICT": "TERMAL",
        "STREET": "YENİMAHALLE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YALOVA",
        "DISTRICT": "TERMAL",
        "STREET": "ÜVEZPINAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "YALOVA",
        "DISTRICT": "MERKEZ",
        "STREET": "ADNAN MENDERES MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "YALOVA",
        "DISTRICT": "MERKEZ",
        "STREET": "BAHÇELİEVLER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "YALOVA",
        "DISTRICT": "MERKEZ",
        "STREET": "BAYRAKTEPE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "YALOVA",
        "DISTRICT": "MERKEZ",
        "STREET": "BAĞLARBAŞI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "YALOVA",
        "DISTRICT": "MERKEZ",
        "STREET": "DERE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "YALOVA",
        "DISTRICT": "MERKEZ",
        "STREET": "ELMALIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YALOVA",
        "DISTRICT": "MERKEZ",
        "STREET": "ESADİYE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YALOVA",
        "DISTRICT": "MERKEZ",
        "STREET": "FEVZİ ÇAKMAK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "YALOVA",
        "DISTRICT": "MERKEZ",
        "STREET": "GAZİ OSMAN PAŞA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "YALOVA",
        "DISTRICT": "MERKEZ",
        "STREET": "GÜNEYKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "YALOVA",
        "DISTRICT": "MERKEZ",
        "STREET": "HACIMEHMET KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YALOVA",
        "DISTRICT": "MERKEZ",
        "STREET": "KADIKÖY-MERKEZ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "YALOVA",
        "DISTRICT": "MERKEZ",
        "STREET": "KADIKÖY-ÖZDEN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "YALOVA",
        "DISTRICT": "MERKEZ",
        "STREET": "KAZIM KARABEKİR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "YALOVA",
        "DISTRICT": "MERKEZ",
        "STREET": "KAZIMİYE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YALOVA",
        "DISTRICT": "MERKEZ",
        "STREET": "KURTKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "YALOVA",
        "DISTRICT": "MERKEZ",
        "STREET": "KİRAZLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YALOVA",
        "DISTRICT": "MERKEZ",
        "STREET": "MUSTAFA KEMAL PAŞA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "YALOVA",
        "DISTRICT": "MERKEZ",
        "STREET": "PAŞAKENT MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "YALOVA",
        "DISTRICT": "MERKEZ",
        "STREET": "RÜSTEM PAŞA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "YALOVA",
        "DISTRICT": "MERKEZ",
        "STREET": "SAFRAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YALOVA",
        "DISTRICT": "MERKEZ",
        "STREET": "SAMANLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YALOVA",
        "DISTRICT": "MERKEZ",
        "STREET": "SEYRANTEPE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "YALOVA",
        "DISTRICT": "MERKEZ",
        "STREET": "SOĞUCAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YALOVA",
        "DISTRICT": "MERKEZ",
        "STREET": "SUGÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YALOVA",
        "DISTRICT": "MERKEZ",
        "STREET": "SÜLEYMAN BEY MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "YALOVA",
        "DISTRICT": "MERKEZ",
        "STREET": "YALOVA A3 TİPİ KAPALI CEZA İNFAZ KURUMU",
        "TYPE": "0"
      },
      {
        "CITY": "YALOVA",
        "DISTRICT": "MERKEZ",
        "STREET": "İSMET PAŞA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "YALOVA",
        "DISTRICT": "ÇINARCIK",
        "STREET": "ESENKÖY-ALİYE HANIM MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "YALOVA",
        "DISTRICT": "ÇINARCIK",
        "STREET": "ESENKÖY-LİMAN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "YALOVA",
        "DISTRICT": "ÇINARCIK",
        "STREET": "HARMANLAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "YALOVA",
        "DISTRICT": "ÇINARCIK",
        "STREET": "HASANBABA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "YALOVA",
        "DISTRICT": "ÇINARCIK",
        "STREET": "KARPUZDERE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "YALOVA",
        "DISTRICT": "ÇINARCIK",
        "STREET": "KOCADERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YALOVA",
        "DISTRICT": "ÇINARCIK",
        "STREET": "KORU-ATAKENT  MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "YALOVA",
        "DISTRICT": "ÇINARCIK",
        "STREET": "KORU-CUMHURİYET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "YALOVA",
        "DISTRICT": "ÇINARCIK",
        "STREET": "KORU-HÜRRİYET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "YALOVA",
        "DISTRICT": "ÇINARCIK",
        "STREET": "ORTABURUN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YALOVA",
        "DISTRICT": "ÇINARCIK",
        "STREET": "TAŞLİMAN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "YALOVA",
        "DISTRICT": "ÇINARCIK",
        "STREET": "TEŞVİKİYE-CUMHURİYET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "YALOVA",
        "DISTRICT": "ÇINARCIK",
        "STREET": "TEŞVİKİYE-KARŞIYAKA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "YALOVA",
        "DISTRICT": "ÇINARCIK",
        "STREET": "TEŞVİKİYE-İSTİKLAL MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "YALOVA",
        "DISTRICT": "ÇINARCIK",
        "STREET": "ÇALICA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YALOVA",
        "DISTRICT": "ÇINARCIK",
        "STREET": "ÇAMLIK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "YALOVA",
        "DISTRICT": "ÇINARCIK",
        "STREET": "ŞENKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "YALOVA",
        "DISTRICT": "ÇİFTLİKKÖY",
        "STREET": "500 EVLER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "YALOVA",
        "DISTRICT": "ÇİFTLİKKÖY",
        "STREET": "BURHANİYE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YALOVA",
        "DISTRICT": "ÇİFTLİKKÖY",
        "STREET": "DENİZÇALI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YALOVA",
        "DISTRICT": "ÇİFTLİKKÖY",
        "STREET": "DEREKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "YALOVA",
        "DISTRICT": "ÇİFTLİKKÖY",
        "STREET": "GACIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YALOVA",
        "DISTRICT": "ÇİFTLİKKÖY",
        "STREET": "KABAKLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YALOVA",
        "DISTRICT": "ÇİFTLİKKÖY",
        "STREET": "KILIÇ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YALOVA",
        "DISTRICT": "ÇİFTLİKKÖY",
        "STREET": "LALEDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YALOVA",
        "DISTRICT": "ÇİFTLİKKÖY",
        "STREET": "MEHMET AKİF ERSOY MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "YALOVA",
        "DISTRICT": "ÇİFTLİKKÖY",
        "STREET": "SAHİL MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "YALOVA",
        "DISTRICT": "ÇİFTLİKKÖY",
        "STREET": "SULTANİYE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "YALOVA",
        "DISTRICT": "ÇİFTLİKKÖY",
        "STREET": "SİTELER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "YALOVA",
        "DISTRICT": "ÇİFTLİKKÖY",
        "STREET": "TAŞKÖPRÜ-TAŞKÖPRÜ MERKEZ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "YALOVA",
        "DISTRICT": "ÇİFTLİKKÖY",
        "STREET": "TAŞKÖPRÜ-TAŞKÖPRÜ YENİ  MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "YALOVA",
        "DISTRICT": "ÇİFTLİKKÖY",
        "STREET": "ÇUKURKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "YALOVA",
        "DISTRICT": "ÇİFTLİKKÖY",
        "STREET": "ÇİFTLİK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "YALOVA",
        "DISTRICT": "ÇİFTLİKKÖY",
        "STREET": "İLYASKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "AKDAĞMADENİ",
        "STREET": "ABDURRAHMANLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "AKDAĞMADENİ",
        "STREET": "AHİSHAVİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "AKDAĞMADENİ",
        "STREET": "AKBAŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "AKDAĞMADENİ",
        "STREET": "AKÇAKIŞLA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "AKDAĞMADENİ",
        "STREET": "AKÇAKOYUNLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "AKDAĞMADENİ",
        "STREET": "ALTILI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "AKDAĞMADENİ",
        "STREET": "ALİCİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "AKDAĞMADENİ",
        "STREET": "ARDIÇALANI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "AKDAĞMADENİ",
        "STREET": "ARPALIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "AKDAĞMADENİ",
        "STREET": "ARSLANLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "AKDAĞMADENİ",
        "STREET": "AĞAÇLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "AKDAĞMADENİ",
        "STREET": "AŞAĞIÇULHALI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "AKDAĞMADENİ",
        "STREET": "BAHÇECİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "AKDAĞMADENİ",
        "STREET": "BAŞÇATAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "AKDAĞMADENİ",
        "STREET": "BELEKÇAHAN-MERKEZ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "AKDAĞMADENİ",
        "STREET": "BOYALIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "AKDAĞMADENİ",
        "STREET": "BOZHÜYÜK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "AKDAĞMADENİ",
        "STREET": "BOĞAZKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "AKDAĞMADENİ",
        "STREET": "BULGURLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "AKDAĞMADENİ",
        "STREET": "DAVULBAZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "AKDAĞMADENİ",
        "STREET": "DAVUTLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "AKDAĞMADENİ",
        "STREET": "DAYILI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "AKDAĞMADENİ",
        "STREET": "DEREYURT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "AKDAĞMADENİ",
        "STREET": "DOKUZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "AKDAĞMADENİ",
        "STREET": "DOLAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "AKDAĞMADENİ",
        "STREET": "EMEK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "AKDAĞMADENİ",
        "STREET": "EVCİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "AKDAĞMADENİ",
        "STREET": "EYNELLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "AKDAĞMADENİ",
        "STREET": "GÖKDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "AKDAĞMADENİ",
        "STREET": "GÜLLÜK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "AKDAĞMADENİ",
        "STREET": "GÜLTEPE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "AKDAĞMADENİ",
        "STREET": "GÜMÜŞDİBEK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "AKDAĞMADENİ",
        "STREET": "GÜNDÜZLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "AKDAĞMADENİ",
        "STREET": "GÜNEYLİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "AKDAĞMADENİ",
        "STREET": "HACIFAKILI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "AKDAĞMADENİ",
        "STREET": "HALHACI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "AKDAĞMADENİ",
        "STREET": "HAYRAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "AKDAĞMADENİ",
        "STREET": "HÜYÜKLÜALANI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "AKDAĞMADENİ",
        "STREET": "KARAALİKAÇAĞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "AKDAĞMADENİ",
        "STREET": "KARACAÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "AKDAĞMADENİ",
        "STREET": "KARADİKMEN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "AKDAĞMADENİ",
        "STREET": "KARAPİR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "AKDAĞMADENİ",
        "STREET": "KARTAL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "AKDAĞMADENİ",
        "STREET": "KAYABAŞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "AKDAĞMADENİ",
        "STREET": "KAYAKIŞLA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "AKDAĞMADENİ",
        "STREET": "KILINÇLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "AKDAĞMADENİ",
        "STREET": "KIRLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "AKDAĞMADENİ",
        "STREET": "KIZILCAOVA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "AKDAĞMADENİ",
        "STREET": "KIZILDAĞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "AKDAĞMADENİ",
        "STREET": "KUŞLUKAÇAĞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "AKDAĞMADENİ",
        "STREET": "KÖRÜK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "AKDAĞMADENİ",
        "STREET": "KİRSİNKAVAĞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "AKDAĞMADENİ",
        "STREET": "MELİKLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "AKDAĞMADENİ",
        "STREET": "MUŞALİKALESİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "AKDAĞMADENİ",
        "STREET": "OKÇULU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "AKDAĞMADENİ",
        "STREET": "OLUKÖZÜ-KONACI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "AKDAĞMADENİ",
        "STREET": "OLUKÖZÜ-MERKEZ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "AKDAĞMADENİ",
        "STREET": "ORTAKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "AKDAĞMADENİ",
        "STREET": "PAZARCIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "AKDAĞMADENİ",
        "STREET": "PAŞABEY KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "AKDAĞMADENİ",
        "STREET": "SARIGÜNEY KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "AKDAĞMADENİ",
        "STREET": "SAZLIDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "AKDAĞMADENİ",
        "STREET": "SAĞIROĞLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "AKDAĞMADENİ",
        "STREET": "SEKİKAŞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "AKDAĞMADENİ",
        "STREET": "SEYRANTEPE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "AKDAĞMADENİ",
        "STREET": "TARHANA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "AKDAĞMADENİ",
        "STREET": "TASPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "AKDAĞMADENİ",
        "STREET": "TEKKEGÜNEYİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "AKDAĞMADENİ",
        "STREET": "TEMURŞEYH KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "AKDAĞMADENİ",
        "STREET": "UMUTLU-KALE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "AKDAĞMADENİ",
        "STREET": "UMUTLU-KARAHİSARTATLISI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "AKDAĞMADENİ",
        "STREET": "UMUTLU-KARAÇOKRAK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "AKDAĞMADENİ",
        "STREET": "UMUTLU-OLUCAK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "AKDAĞMADENİ",
        "STREET": "UMUTLU-UMUT MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "AKDAĞMADENİ",
        "STREET": "UZAKÇAY KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "AKDAĞMADENİ",
        "STREET": "VEZİRALANI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "AKDAĞMADENİ",
        "STREET": "YAZILITAŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "AKDAĞMADENİ",
        "STREET": "YEDİŞEHRİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "AKDAĞMADENİ",
        "STREET": "YENİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "AKDAĞMADENİ",
        "STREET": "YENİYAPAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "AKDAĞMADENİ",
        "STREET": "YEŞİLDERE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "AKDAĞMADENİ",
        "STREET": "YEŞİLTEPE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "AKDAĞMADENİ",
        "STREET": "YUKARIÇULHALI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "AKDAĞMADENİ",
        "STREET": "YÜNALANI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "AKDAĞMADENİ",
        "STREET": "ÇAMLIK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "AKDAĞMADENİ",
        "STREET": "ÇAMPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "AKDAĞMADENİ",
        "STREET": "ÇARDAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "AKDAĞMADENİ",
        "STREET": "ÇAYPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "AKDAĞMADENİ",
        "STREET": "ÇAĞLAYAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "AKDAĞMADENİ",
        "STREET": "ÇERÇİALANI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "AKDAĞMADENİ",
        "STREET": "ÖRENKALE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "AKDAĞMADENİ",
        "STREET": "ÖZER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "AKDAĞMADENİ",
        "STREET": "ÜÇKARAAĞAÇ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "AKDAĞMADENİ",
        "STREET": "İBRAHİM AĞA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "AKDAĞMADENİ",
        "STREET": "İBRAHİMAĞAÇİFTLİĞİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "AKDAĞMADENİ",
        "STREET": "İSTANBULLUOĞLU MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "AKDAĞMADENİ",
        "STREET": "ŞAHNADERESİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "AYDINCIK",
        "STREET": "AĞILLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "AYDINCIK",
        "STREET": "AŞAĞIKUYUCAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "AYDINCIK",
        "STREET": "BAKIRBOĞAZI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "AYDINCIK",
        "STREET": "BAYDİĞİN-MERKEZ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "AYDINCIK",
        "STREET": "BAŞTÜRK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "AYDINCIK",
        "STREET": "BOĞAZKAYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "AYDINCIK",
        "STREET": "BÜYÜKTORAMAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "AYDINCIK",
        "STREET": "DANLIDAĞ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "AYDINCIK",
        "STREET": "DEREÇİFTLİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "AYDINCIK",
        "STREET": "GÜROĞLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "AYDINCIK",
        "STREET": "HACIİLYAS KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "AYDINCIK",
        "STREET": "KAZANKAYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "AYDINCIK",
        "STREET": "KIYIKIŞLA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "AYDINCIK",
        "STREET": "KIZILCAKIŞLA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "AYDINCIK",
        "STREET": "KOCABEKİR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "AYDINCIK",
        "STREET": "KUYU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "AYDINCIK",
        "STREET": "KUŞSARAY KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "AYDINCIK",
        "STREET": "KÖSRELİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "AYDINCIK",
        "STREET": "KÜÇÜKTORAMAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "AYDINCIK",
        "STREET": "MERCİMEKÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "AYDINCIK",
        "STREET": "MOLLAİSMAİL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "AYDINCIK",
        "STREET": "SAKIZLIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "AYDINCIK",
        "STREET": "YENİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "AYDINCIK",
        "STREET": "YENİCE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "AYDINCIK",
        "STREET": "ÜZÜMLÜK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "BOĞAZLIYAN",
        "STREET": "ABDİLLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "BOĞAZLIYAN",
        "STREET": "AŞAĞI KARAKOÇ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "BOĞAZLIYAN",
        "STREET": "AŞAĞI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "BOĞAZLIYAN",
        "STREET": "BAHARİYE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "BOĞAZLIYAN",
        "STREET": "BAHÇELER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "BOĞAZLIYAN",
        "STREET": "BAĞLARBAŞI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "BOĞAZLIYAN",
        "STREET": "BAŞHOROZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "BOĞAZLIYAN",
        "STREET": "BELÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "BOĞAZLIYAN",
        "STREET": "BOĞAZLIYAN T TİPİ KAPALI AÇIK CEZA İNFAZ KURUMU",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "BOĞAZLIYAN",
        "STREET": "DEREÇEPNİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "BOĞAZLIYAN",
        "STREET": "DEVECİPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "BOĞAZLIYAN",
        "STREET": "ESENTEPE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "BOĞAZLIYAN",
        "STREET": "GÖVDECİLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "BOĞAZLIYAN",
        "STREET": "GÜVEÇLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "BOĞAZLIYAN",
        "STREET": "KARAKUYU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "BOĞAZLIYAN",
        "STREET": "KAYMAKAM KEMAL BEY MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "BOĞAZLIYAN",
        "STREET": "KEMALİYE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "BOĞAZLIYAN",
        "STREET": "MEVLANA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "BOĞAZLIYAN",
        "STREET": "MÜFTÜKIŞLA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "BOĞAZLIYAN",
        "STREET": "OVAKENT-AŞAĞIHASİNLİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "BOĞAZLIYAN",
        "STREET": "OVAKENT-CAMİİ KEBİR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "BOĞAZLIYAN",
        "STREET": "OVAKENT-MEVLANA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "BOĞAZLIYAN",
        "STREET": "OVAKENT-NAMIK KEMAL MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "BOĞAZLIYAN",
        "STREET": "OĞULCUK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "BOĞAZLIYAN",
        "STREET": "POYRAZLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "BOĞAZLIYAN",
        "STREET": "SIRÇALI-FATİH MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "BOĞAZLIYAN",
        "STREET": "SIRÇALI-MEVLANA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "BOĞAZLIYAN",
        "STREET": "UZUNLU-BAHÇELİEVLER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "BOĞAZLIYAN",
        "STREET": "UZUNLU-ESENTEPE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "BOĞAZLIYAN",
        "STREET": "UZUNLU-KEMALİYE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "BOĞAZLIYAN",
        "STREET": "UZUNLU-KOÇ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "BOĞAZLIYAN",
        "STREET": "UZUNLU-YENİDOĞAN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "BOĞAZLIYAN",
        "STREET": "YAMAÇLI-YENİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "BOĞAZLIYAN",
        "STREET": "YAMAÇLI-YEŞİL MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "BOĞAZLIYAN",
        "STREET": "YAMAÇLI-ZAFER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "BOĞAZLIYAN",
        "STREET": "YAPALAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "BOĞAZLIYAN",
        "STREET": "YAZIKIŞLA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "BOĞAZLIYAN",
        "STREET": "YAZIÇEPNİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "BOĞAZLIYAN",
        "STREET": "YENİDOĞAN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "BOĞAZLIYAN",
        "STREET": "YENİKIŞLA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "BOĞAZLIYAN",
        "STREET": "YENİPAZAR-AŞAĞISARIKAYA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "BOĞAZLIYAN",
        "STREET": "YENİPAZAR-EĞLENCE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "BOĞAZLIYAN",
        "STREET": "YENİPAZAR-KARŞIYAKA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "BOĞAZLIYAN",
        "STREET": "YENİPAZAR-SELANİK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "BOĞAZLIYAN",
        "STREET": "YENİPAZAR-YARAŞ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "BOĞAZLIYAN",
        "STREET": "YENİPAZAR-ÖMERLİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "BOĞAZLIYAN",
        "STREET": "YEŞİLHİSAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "BOĞAZLIYAN",
        "STREET": "YOĞUNHİSAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "BOĞAZLIYAN",
        "STREET": "YUKARI KARAKOÇ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "BOĞAZLIYAN",
        "STREET": "YUKARI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "BOĞAZLIYAN",
        "STREET": "ÇAKMAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "BOĞAZLIYAN",
        "STREET": "ÇALAPVERDİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "BOĞAZLIYAN",
        "STREET": "ÇARŞI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "BOĞAZLIYAN",
        "STREET": "ÖZLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "KADIŞEHRİ",
        "STREET": "AŞAĞIKIZILÖZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "KADIŞEHRİ",
        "STREET": "BELÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "KADIŞEHRİ",
        "STREET": "BUZLUK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "KADIŞEHRİ",
        "STREET": "DERBENT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "KADIŞEHRİ",
        "STREET": "DİKMESÖĞÜT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "KADIŞEHRİ",
        "STREET": "ELMALI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "KADIŞEHRİ",
        "STREET": "ELMALIÇİFTLİĞİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "KADIŞEHRİ",
        "STREET": "ELMALIÜTÜĞÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "KADIŞEHRİ",
        "STREET": "GÜMÜŞDİĞİN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "KADIŞEHRİ",
        "STREET": "GÜMÜŞSU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "KADIŞEHRİ",
        "STREET": "HALIKÖY-AKÇAKALE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "KADIŞEHRİ",
        "STREET": "HALIKÖY-BAHÇELİ EVLER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "KADIŞEHRİ",
        "STREET": "HALIKÖY-FATİH  MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "KADIŞEHRİ",
        "STREET": "HALIKÖY-YONCALIK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "KADIŞEHRİ",
        "STREET": "HALIKÖY-YUKARIKIZILÖZ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "KADIŞEHRİ",
        "STREET": "HANÖZÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "KADIŞEHRİ",
        "STREET": "KABALI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "KADIŞEHRİ",
        "STREET": "KADIOĞLU MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "KADIŞEHRİ",
        "STREET": "KEMALLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "KADIŞEHRİ",
        "STREET": "KIYILI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "KADIŞEHRİ",
        "STREET": "LÜTFULLAH KAYALAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "KADIŞEHRİ",
        "STREET": "OVACIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "KADIŞEHRİ",
        "STREET": "SEYHAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "KADIŞEHRİ",
        "STREET": "VASFIBEY  MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "KADIŞEHRİ",
        "STREET": "YAKACIK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "KADIŞEHRİ",
        "STREET": "YANGI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "KADIŞEHRİ",
        "STREET": "YANIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "KADIŞEHRİ",
        "STREET": "YAVUHASAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "KADIŞEHRİ",
        "STREET": "YELTEN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "KADIŞEHRİ",
        "STREET": "YENİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "KADIŞEHRİ",
        "STREET": "ÇAMSARAY KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "KADIŞEHRİ",
        "STREET": "ÇAYPINAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "KADIŞEHRİ",
        "STREET": "ÖRENCİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "KADIŞEHRİ",
        "STREET": "ÜÇAĞAÇ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "SARAYKENT",
        "STREET": "ALTINSU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "SARAYKENT",
        "STREET": "BAŞPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "SARAYKENT",
        "STREET": "BENLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "SARAYKENT",
        "STREET": "BEŞKAVAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "SARAYKENT",
        "STREET": "BÜYÜK CAMİİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "SARAYKENT",
        "STREET": "DEDEFAKILI-BALKAYA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "SARAYKENT",
        "STREET": "DEDEFAKILI-DEDEFAKILI MERKEZ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "SARAYKENT",
        "STREET": "DİVANLI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "SARAYKENT",
        "STREET": "FATİH SULTAN MEHMET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "SARAYKENT",
        "STREET": "GÜLDİBİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "SARAYKENT",
        "STREET": "KAMBERLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "SARAYKENT",
        "STREET": "KARAPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "SARAYKENT",
        "STREET": "KARŞIYAKA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "SARAYKENT",
        "STREET": "KESİKKÖPRÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "SARAYKENT",
        "STREET": "KÖSEALİLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "SARAYKENT",
        "STREET": "M.FEVZİ ÇAKMAK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "SARAYKENT",
        "STREET": "OZAN-CUMHURİYET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "SARAYKENT",
        "STREET": "OZAN-MERKEZ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "SARAYKENT",
        "STREET": "PARMAKSIZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "SARAYKENT",
        "STREET": "SARAY MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "SARAYKENT",
        "STREET": "SARAYÖZÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "SARAYKENT",
        "STREET": "SÖĞÜTLÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "SARAYKENT",
        "STREET": "YENİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "SARAYKENT",
        "STREET": "ÇİÇEKLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "SARAYKENT",
        "STREET": "ÇİÇEKLİHÜYÜĞÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "SARAYKENT",
        "STREET": "İZİBÜYÜK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "SARIKAYA",
        "STREET": "AKBENLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "SARIKAYA",
        "STREET": "AKBUCAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "SARIKAYA",
        "STREET": "AKÇADAM KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "SARIKAYA",
        "STREET": "ALEMBEY KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "SARIKAYA",
        "STREET": "ALİFAKILI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "SARIKAYA",
        "STREET": "ARPALIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "SARIKAYA",
        "STREET": "AZAPBAŞILI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "SARIKAYA",
        "STREET": "BABAYAĞMUR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "SARIKAYA",
        "STREET": "BAHÇELİEVLER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "SARIKAYA",
        "STREET": "BARAKLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "SARIKAYA",
        "STREET": "BATTALGAZİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "SARIKAYA",
        "STREET": "BAĞLICA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "SARIKAYA",
        "STREET": "BEBEKKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "SARIKAYA",
        "STREET": "BEYAZIT MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "SARIKAYA",
        "STREET": "BOYALIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "SARIKAYA",
        "STREET": "BURUNKIŞLA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "SARIKAYA",
        "STREET": "BÜYÜKÇALAĞIL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "SARIKAYA",
        "STREET": "CUMHURİYET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "SARIKAYA",
        "STREET": "DEREDOĞAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "SARIKAYA",
        "STREET": "DEREKAPLANCI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "SARIKAYA",
        "STREET": "DOĞANSARAY KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "SARIKAYA",
        "STREET": "EMİRBEY KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "SARIKAYA",
        "STREET": "ERBEK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "SARIKAYA",
        "STREET": "FATİH MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "SARIKAYA",
        "STREET": "FEVZİ ÇAKMAK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "SARIKAYA",
        "STREET": "GÜLPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "SARIKAYA",
        "STREET": "GÜNDÜZLÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "SARIKAYA",
        "STREET": "HASBEK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "SARIKAYA",
        "STREET": "HÜRRİYET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "SARIKAYA",
        "STREET": "HİSARBEY KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "SARIKAYA",
        "STREET": "ILISU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "SARIKAYA",
        "STREET": "KADIGÜLLÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "SARIKAYA",
        "STREET": "KADILI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "SARIKAYA",
        "STREET": "KAPLICA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "SARIKAYA",
        "STREET": "KARABACAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "SARIKAYA",
        "STREET": "KARACALAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "SARIKAYA",
        "STREET": "KARAELLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "SARIKAYA",
        "STREET": "KARAHALLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "SARIKAYA",
        "STREET": "KARAYAKUP-MERKEZ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "SARIKAYA",
        "STREET": "KARAYAKUP-TERZİLİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "SARIKAYA",
        "STREET": "KARAYAKUP-ÇIKRIKÇI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "SARIKAYA",
        "STREET": "KARGALIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "SARIKAYA",
        "STREET": "KARŞIYAKA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "SARIKAYA",
        "STREET": "KAYAPINAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "SARIKAYA",
        "STREET": "KAZIM KARABEKİR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "SARIKAYA",
        "STREET": "KEMALLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "SARIKAYA",
        "STREET": "KERPİÇCİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "SARIKAYA",
        "STREET": "KONURLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "SARIKAYA",
        "STREET": "KOÇAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "SARIKAYA",
        "STREET": "KOÇCAĞIZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "SARIKAYA",
        "STREET": "KÖPRÜCEK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "SARIKAYA",
        "STREET": "KÜRKCÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "SARIKAYA",
        "STREET": "KÜÇÜKÇALAĞIL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "SARIKAYA",
        "STREET": "MESCİTLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "SARIKAYA",
        "STREET": "OSMAN GAZİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "SARIKAYA",
        "STREET": "PINARKAYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "SARIKAYA",
        "STREET": "RAMADANLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "SARIKAYA",
        "STREET": "SELİMLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "SARIKAYA",
        "STREET": "SÖYLEMEZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "SARIKAYA",
        "STREET": "TEPEDOĞAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "SARIKAYA",
        "STREET": "TOMARCAHÜYÜĞÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "SARIKAYA",
        "STREET": "TOPAKTAŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "SARIKAYA",
        "STREET": "TOPRAKPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "SARIKAYA",
        "STREET": "YAHYALI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "SARIKAYA",
        "STREET": "YAYLAGÜL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "SARIKAYA",
        "STREET": "YAZIKAPLANCI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "SARIKAYA",
        "STREET": "YENİDOĞAN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "SARIKAYA",
        "STREET": "YUKARISARIKAYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "SARIKAYA",
        "STREET": "ÇATAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "SARIKAYA",
        "STREET": "ÇOKUMEŞME KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "SARIKAYA",
        "STREET": "ÜRKÜTLÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "SARIKAYA",
        "STREET": "İNEVİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "SARIKAYA",
        "STREET": "İNKIŞLA CELALHÜYÜĞÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "SARIKAYA",
        "STREET": "İSTİKLAL MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "SARIKAYA",
        "STREET": "ŞEKERPINAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "SORGUN",
        "STREET": "AGAHEFENDİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "SORGUN",
        "STREET": "AHMETEFENDİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "SORGUN",
        "STREET": "AKOLUK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "SORGUN",
        "STREET": "ALCI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "SORGUN",
        "STREET": "ALİŞAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "SORGUN",
        "STREET": "ARAPLI-GÜZEL YURT MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "SORGUN",
        "STREET": "ARAPLI-MERKEZ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "SORGUN",
        "STREET": "ARAPLI-YAYCILAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "SORGUN",
        "STREET": "AYDINLIKEVLER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "SORGUN",
        "STREET": "AYRIDAM KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "SORGUN",
        "STREET": "AĞCIN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "SORGUN",
        "STREET": "AŞAĞICUMAFAKILI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "SORGUN",
        "STREET": "AŞAĞIEMİRLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "SORGUN",
        "STREET": "AŞAĞIKARAHACILI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "SORGUN",
        "STREET": "AŞAĞIKARAKAYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "SORGUN",
        "STREET": "BABALI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "SORGUN",
        "STREET": "BAHADIN- SORGUN TATLISI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "SORGUN",
        "STREET": "BAHADIN-AYDINLAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "SORGUN",
        "STREET": "BAHADIN-GÜMÜŞKAVAK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "SORGUN",
        "STREET": "BAHADIN-HÜRRİYET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "SORGUN",
        "STREET": "BAHADIN-MÜLAZIM MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "SORGUN",
        "STREET": "BAHADIN-SALUR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "SORGUN",
        "STREET": "BAHADIN-SUNGUR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "SORGUN",
        "STREET": "BAHÇELİEVLER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "SORGUN",
        "STREET": "BAĞLARBAŞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "SORGUN",
        "STREET": "BEDİRBABA  MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "SORGUN",
        "STREET": "BELENCUMAFAKILI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "SORGUN",
        "STREET": "BOĞAZCUMAFAKILI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "SORGUN",
        "STREET": "BÜYÜKEYNELLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "SORGUN",
        "STREET": "BÜYÜKKIŞLA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "SORGUN",
        "STREET": "BÜYÜKTAŞLIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "SORGUN",
        "STREET": "BÜYÜKÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "SORGUN",
        "STREET": "CAFERLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "SORGUN",
        "STREET": "CİHANŞARLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "SORGUN",
        "STREET": "DOĞANKENT-AKSARAY MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "SORGUN",
        "STREET": "DOĞANKENT-KAYABAŞI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "SORGUN",
        "STREET": "DOĞANKENT-MAHMATLI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "SORGUN",
        "STREET": "DOĞANKENT-YENİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "SORGUN",
        "STREET": "DOĞANKENT-ÇAVUŞLU MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "SORGUN",
        "STREET": "DOĞANLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "SORGUN",
        "STREET": "DİŞLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "SORGUN",
        "STREET": "EMİRHAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "SORGUN",
        "STREET": "EMİRLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "SORGUN",
        "STREET": "ERKEKLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "SORGUN",
        "STREET": "EYMİR-AKOCAK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "SORGUN",
        "STREET": "EYMİR-KARŞIYAKA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "SORGUN",
        "STREET": "EYMİR-KAYAKIŞLA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "SORGUN",
        "STREET": "EYMİR-MUSU BABA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "SORGUN",
        "STREET": "EYMİR-ÇARŞIBAŞI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "SORGUN",
        "STREET": "EYMİR-ÇAY MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "SORGUN",
        "STREET": "GARİPLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "SORGUN",
        "STREET": "GEDİKHASANLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "SORGUN",
        "STREET": "GEVREK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "SORGUN",
        "STREET": "GÖKÖZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "SORGUN",
        "STREET": "GÖKİNİŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "SORGUN",
        "STREET": "GÖZBABA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "SORGUN",
        "STREET": "GÜLŞEHRİ-FARAŞLI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "SORGUN",
        "STREET": "GÜLŞEHRİ-HOŞUMLU MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "SORGUN",
        "STREET": "GÜLŞEHRİ-KESER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "SORGUN",
        "STREET": "GÜLŞEHRİ-ÇÖTELLİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "SORGUN",
        "STREET": "GÜNGÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "SORGUN",
        "STREET": "GÜNPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "SORGUN",
        "STREET": "GÜNYAZI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "SORGUN",
        "STREET": "GÜZELYURT MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "SORGUN",
        "STREET": "HALİLFAKILI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "SORGUN",
        "STREET": "HANBAŞI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "SORGUN",
        "STREET": "KAPAKLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "SORGUN",
        "STREET": "KARABALI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "SORGUN",
        "STREET": "KARABURUN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "SORGUN",
        "STREET": "KARAKOCAOĞLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "SORGUN",
        "STREET": "KARALIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "SORGUN",
        "STREET": "KARAVELİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "SORGUN",
        "STREET": "KARLIK AYVALIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "SORGUN",
        "STREET": "KARŞIYAKA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "SORGUN",
        "STREET": "KEPİRCE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "SORGUN",
        "STREET": "KODALLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "SORGUN",
        "STREET": "KODALLIÇİFTLİĞİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "SORGUN",
        "STREET": "KÜLHÜYÜK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "SORGUN",
        "STREET": "KÜÇÜKEYNELLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "SORGUN",
        "STREET": "KÜÇÜKKÖHNE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "SORGUN",
        "STREET": "KÜÇÜKTAŞLIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "SORGUN",
        "STREET": "MANSUROĞLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "SORGUN",
        "STREET": "MEHMETBEYLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "SORGUN",
        "STREET": "MUĞALLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "SORGUN",
        "STREET": "MÜKREMİN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "SORGUN",
        "STREET": "MİRAHOR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "SORGUN",
        "STREET": "OCAKLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "SORGUN",
        "STREET": "OSMANÇAVUŞ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "SORGUN",
        "STREET": "OSMANİYE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "SORGUN",
        "STREET": "PEYNİRYEMEZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "SORGUN",
        "STREET": "SARIHACILI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "SORGUN",
        "STREET": "SARIHAMZALI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "SORGUN",
        "STREET": "SİVRİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "SORGUN",
        "STREET": "TAŞPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "SORGUN",
        "STREET": "TEMREZLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "SORGUN",
        "STREET": "TULUM KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "SORGUN",
        "STREET": "TUZLACIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "SORGUN",
        "STREET": "TİFTİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "SORGUN",
        "STREET": "VELİÖLDÜK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "SORGUN",
        "STREET": "YAZILITAŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "SORGUN",
        "STREET": "YAZLAK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "SORGUN",
        "STREET": "YENİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "SORGUN",
        "STREET": "YENİDOĞAN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "SORGUN",
        "STREET": "YENİYER-AHMETFAKILI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "SORGUN",
        "STREET": "YENİYER-KARAKIZ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "SORGUN",
        "STREET": "YEŞİLÖZ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "SORGUN",
        "STREET": "ÇAKIRHACILI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "SORGUN",
        "STREET": "ÇAMURLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "SORGUN",
        "STREET": "ÇAVUŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "SORGUN",
        "STREET": "ÇAY MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "SORGUN",
        "STREET": "ÇAYÖZÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "SORGUN",
        "STREET": "ÇİĞDEMLİ-BURUNÖREN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "SORGUN",
        "STREET": "ÇİĞDEMLİ-CAMİİKEBİR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "SORGUN",
        "STREET": "ÇİĞDEMLİ-DURALİDAYILI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "SORGUN",
        "STREET": "ÇİĞDEMLİ-GÜLVEREN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "SORGUN",
        "STREET": "ÇİĞDEMLİ-KALE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "SORGUN",
        "STREET": "ÇİĞDEMLİ-KARŞIYAKA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "SORGUN",
        "STREET": "ÇİĞDEMLİ-İSMAİLHACILI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "SORGUN",
        "STREET": "İDRİSLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "SORGUN",
        "STREET": "İKİKARA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "SORGUN",
        "STREET": "İNCESU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "SORGUN",
        "STREET": "İSAFAKILI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "SORGUN",
        "STREET": "ŞAHMURATLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "YENİFAKILI",
        "STREET": "ALTINDAĞ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "YENİFAKILI",
        "STREET": "BAŞPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "YENİFAKILI",
        "STREET": "BEKTAŞLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "YENİFAKILI",
        "STREET": "CANKURTARAN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "YENİFAKILI",
        "STREET": "ESKİÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "YENİFAKILI",
        "STREET": "FEHİMLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "YENİFAKILI",
        "STREET": "MEHMET AKİF ERSOY MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "YENİFAKILI",
        "STREET": "YAZLAK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "YENİFAKILI",
        "STREET": "YENİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "YENİFAKILI",
        "STREET": "YİĞİTLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "YENİFAKILI",
        "STREET": "ÇÖPLÜÇİFTLİĞİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "YENİFAKILI",
        "STREET": "ÜÇOBALAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "YERKÖY",
        "STREET": "100.YIL MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "YERKÖY",
        "STREET": "AKPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "YERKÖY",
        "STREET": "ALTMIŞ EVLER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "YERKÖY",
        "STREET": "ALİ GALİP GENÇOĞLU MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "YERKÖY",
        "STREET": "ARSLANHACILI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "YERKÖY",
        "STREET": "ARİFEOĞLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "YERKÖY",
        "STREET": "AYANOĞLU MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "YERKÖY",
        "STREET": "AYDINGÜN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "YERKÖY",
        "STREET": "AŞAĞIELMAHACILI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "YERKÖY",
        "STREET": "AŞAĞIEĞERCİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "YERKÖY",
        "STREET": "BAĞLARBAŞI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "YERKÖY",
        "STREET": "BELKAVAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "YERKÖY",
        "STREET": "BESEREK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "YERKÖY",
        "STREET": "BURUNCUK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "YERKÖY",
        "STREET": "BİCİKLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "YERKÖY",
        "STREET": "CAKCAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "YERKÖY",
        "STREET": "DELİCE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "YERKÖY",
        "STREET": "DEREBAĞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "YERKÖY",
        "STREET": "DERECİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "YERKÖY",
        "STREET": "ERZURUM MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "YERKÖY",
        "STREET": "GÖÇERLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "YERKÖY",
        "STREET": "GÜLTEPE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "YERKÖY",
        "STREET": "GÜNDOĞDU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "YERKÖY",
        "STREET": "HACILI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "YERKÖY",
        "STREET": "HACIMUSALI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "YERKÖY",
        "STREET": "HACIOSMANLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "YERKÖY",
        "STREET": "HACIUŞAĞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "YERKÖY",
        "STREET": "HACIÇEŞMESİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "YERKÖY",
        "STREET": "HARGAŞAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "YERKÖY",
        "STREET": "HATİP KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "YERKÖY",
        "STREET": "HAŞİM KILIÇ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "YERKÖY",
        "STREET": "HÜYÜK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "YERKÖY",
        "STREET": "KAHYAKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "YERKÖY",
        "STREET": "KALE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "YERKÖY",
        "STREET": "KARACAAHMETLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "YERKÖY",
        "STREET": "KARACAAĞAÇ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "YERKÖY",
        "STREET": "KARACAŞAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "YERKÖY",
        "STREET": "KARAOSMANOĞLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "YERKÖY",
        "STREET": "KARLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "YERKÖY",
        "STREET": "KAYADİBİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "YERKÖY",
        "STREET": "KOCAOĞLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "YERKÖY",
        "STREET": "KUMLUCA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "YERKÖY",
        "STREET": "KÖMÜŞÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "YERKÖY",
        "STREET": "KÖRDEVE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "YERKÖY",
        "STREET": "KÖYCÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "YERKÖY",
        "STREET": "KÜÇÜKNEFES KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "YERKÖY",
        "STREET": "KÜÇÜKÇALIKLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "YERKÖY",
        "STREET": "ORHAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "YERKÖY",
        "STREET": "POYRAZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "YERKÖY",
        "STREET": "SALİHLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "YERKÖY",
        "STREET": "SARAY KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "YERKÖY",
        "STREET": "SARIYAPRAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "YERKÖY",
        "STREET": "SEDİR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "YERKÖY",
        "STREET": "SEKİLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "YERKÖY",
        "STREET": "SELÇUK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "YERKÖY",
        "STREET": "SUSUZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "YERKÖY",
        "STREET": "SÜLEYMANLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "YERKÖY",
        "STREET": "TERZİLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "YERKÖY",
        "STREET": "TOPAÇ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "YERKÖY",
        "STREET": "VEFİ PANDIR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "YERKÖY",
        "STREET": "YAKUPLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "YERKÖY",
        "STREET": "YERKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "YERKÖY",
        "STREET": "YUKARIELMAHACILI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "YERKÖY",
        "STREET": "YUKARIEĞERCİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "YERKÖY",
        "STREET": "YUKARIİHSANGAZİLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "YERKÖY",
        "STREET": "ZENCİR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "YERKÖY",
        "STREET": "ÇAKIRHACILI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "YERKÖY",
        "STREET": "ÇAKIRLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "YERKÖY",
        "STREET": "ÇALIKLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "YERKÖY",
        "STREET": "ÇAMDİBİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "YERKÖY",
        "STREET": "ÇAMLIBEL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "YERKÖY",
        "STREET": "ÇAYKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "YERKÖY",
        "STREET": "İĞDECİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "YERKÖY",
        "STREET": "ŞENTEPE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "MERKEZ",
        "STREET": "AGAHEFENDİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "MERKEZ",
        "STREET": "AKTAŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "MERKEZ",
        "STREET": "AKYAMAÇ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "MERKEZ",
        "STREET": "ALEMDAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "MERKEZ",
        "STREET": "AYDOĞAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "MERKEZ",
        "STREET": "AZİZLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "MERKEZ",
        "STREET": "AZİZLİBAĞLARI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "MERKEZ",
        "STREET": "AŞAĞI ÇATAK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "MERKEZ",
        "STREET": "AŞAĞINOHUTLU MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "MERKEZ",
        "STREET": "BACILI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "MERKEZ",
        "STREET": "BAHÇECİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "MERKEZ",
        "STREET": "BAHÇEŞEHİR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "MERKEZ",
        "STREET": "BALTASARILAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "MERKEZ",
        "STREET": "BATTAL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "MERKEZ",
        "STREET": "BAYATÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "MERKEZ",
        "STREET": "BAŞIBÜYÜKLÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "MERKEZ",
        "STREET": "BAŞINAYAYLA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "MERKEZ",
        "STREET": "BEYVELİOĞLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "MERKEZ",
        "STREET": "BOZLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "MERKEZ",
        "STREET": "BUZAĞCIOĞLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "MERKEZ",
        "STREET": "BÜYÜKMAHAL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "MERKEZ",
        "STREET": "BÜYÜKNEFES KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "MERKEZ",
        "STREET": "BÜYÜKİNCİRLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "MERKEZ",
        "STREET": "BİLAL ŞAHİN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "MERKEZ",
        "STREET": "BİŞEK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "MERKEZ",
        "STREET": "CİHANPAŞA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "MERKEZ",
        "STREET": "DAMBASAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "MERKEZ",
        "STREET": "DARICI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "MERKEZ",
        "STREET": "DAYILI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "MERKEZ",
        "STREET": "DAĞYENİCESİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "MERKEZ",
        "STREET": "DELİHASANLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "MERKEZ",
        "STREET": "DERBENT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "MERKEZ",
        "STREET": "DEREBOYMUL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "MERKEZ",
        "STREET": "DEREKIŞLA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "MERKEZ",
        "STREET": "DEREMAHAL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "MERKEZ",
        "STREET": "DEREMUMLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "MERKEZ",
        "STREET": "DEVELİK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "MERKEZ",
        "STREET": "DİVANLI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "MERKEZ",
        "STREET": "ERDOĞAN AKDAĞ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "MERKEZ",
        "STREET": "ERKEKLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "MERKEZ",
        "STREET": "ESENLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "MERKEZ",
        "STREET": "ESKİPAZAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "MERKEZ",
        "STREET": "EVCİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "MERKEZ",
        "STREET": "FAKIBEYLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "MERKEZ",
        "STREET": "FATİH MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "MERKEZ",
        "STREET": "GEVREK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "MERKEZ",
        "STREET": "GÖKÇEKIŞLA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "MERKEZ",
        "STREET": "GÖRPELİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "MERKEZ",
        "STREET": "GÜDÜLELMAHACILI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "MERKEZ",
        "STREET": "GÜLLÜK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "MERKEZ",
        "STREET": "GÜLLÜOLUK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "MERKEZ",
        "STREET": "GÜLYAYLA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "MERKEZ",
        "STREET": "GÜNEŞLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "MERKEZ",
        "STREET": "HAMZALI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "MERKEZ",
        "STREET": "HAYDARBEYLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "MERKEZ",
        "STREET": "KABABEL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "MERKEZ",
        "STREET": "KALE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "MERKEZ",
        "STREET": "KARABIYIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "MERKEZ",
        "STREET": "KARACALAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "MERKEZ",
        "STREET": "KARALAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "MERKEZ",
        "STREET": "KARATEPE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "MERKEZ",
        "STREET": "KAVURGALI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "MERKEZ",
        "STREET": "KAŞKIŞLA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "MERKEZ",
        "STREET": "KIRIKSOKU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "MERKEZ",
        "STREET": "KIRIM KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "MERKEZ",
        "STREET": "KIZILTEPE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "MERKEZ",
        "STREET": "KIŞLA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "MERKEZ",
        "STREET": "KOLANLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "MERKEZ",
        "STREET": "KOYUNCULU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "MERKEZ",
        "STREET": "KUYUMCU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "MERKEZ",
        "STREET": "KUŞCU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "MERKEZ",
        "STREET": "KÖSEOĞLU MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "MERKEZ",
        "STREET": "KÖSEYUSUFLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "MERKEZ",
        "STREET": "KÖÇEKKÖMÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "MERKEZ",
        "STREET": "KÖÇEKLİOĞLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "MERKEZ",
        "STREET": "LÖK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "MERKEZ",
        "STREET": "MEDRESE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "MERKEZ",
        "STREET": "MEHMET HULUSİ EFENDİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "MERKEZ",
        "STREET": "MEZRAA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "MERKEZ",
        "STREET": "MUSABEYLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "MERKEZ",
        "STREET": "MUSABEYLİBOĞAZI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "MERKEZ",
        "STREET": "MUTAFOĞLU MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "MERKEZ",
        "STREET": "OSMANPAŞA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "MERKEZ",
        "STREET": "PENBECİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "MERKEZ",
        "STREET": "RECEPLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "MERKEZ",
        "STREET": "SALMANFAKILI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "MERKEZ",
        "STREET": "SARIFATMA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "MERKEZ",
        "STREET": "SARIHACILI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "MERKEZ",
        "STREET": "SARIMBEY KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "MERKEZ",
        "STREET": "SARININÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "MERKEZ",
        "STREET": "SAĞLIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "MERKEZ",
        "STREET": "SÖĞÜTLÜYAYLA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "MERKEZ",
        "STREET": "TAYFUR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "MERKEZ",
        "STREET": "TAYIP KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "MERKEZ",
        "STREET": "TAŞKÖPRÜ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "MERKEZ",
        "STREET": "TEKKE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "MERKEZ",
        "STREET": "TEKKEYENİCESİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "MERKEZ",
        "STREET": "TOPAÇ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "MERKEZ",
        "STREET": "TOPCU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "MERKEZ",
        "STREET": "TUZKAYA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "MERKEZ",
        "STREET": "TÜRKMEN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "MERKEZ",
        "STREET": "TÜRKMENSARILAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "MERKEZ",
        "STREET": "YASSIHÜYÜK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "MERKEZ",
        "STREET": "YAZPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "MERKEZ",
        "STREET": "YENİCAMİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "MERKEZ",
        "STREET": "YEŞİLOVA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "MERKEZ",
        "STREET": "YOZGAT 1 NOLU T-TİPİ KAPALI CEZA İNFAZ KURUMU",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "MERKEZ",
        "STREET": "YOZGAT 2 NOLU T-TİPİ KAPALI CEZA İNFAZ KURUMU",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "MERKEZ",
        "STREET": "YOZGAT ETİPİKAPALICEZAİNFAZ KURUMU",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "MERKEZ",
        "STREET": "YUDAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "MERKEZ",
        "STREET": "YUKARINOHUTLU MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "MERKEZ",
        "STREET": "YUKARIÇATAK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇADIRARDIÇ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇALATLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇALILI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇAPANOĞLU MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇATMA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇAĞLAYAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇORAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "MERKEZ",
        "STREET": "ÖRENCİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "MERKEZ",
        "STREET": "ÖZLÜCE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "MERKEZ",
        "STREET": "İNCEÇAYIR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "MERKEZ",
        "STREET": "İSTANBULLUOĞLU MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "MERKEZ",
        "STREET": "İŞLEĞEN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "MERKEZ",
        "STREET": "ŞEYH AHMET EFENDİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "MERKEZ",
        "STREET": "ŞEYHOSMAN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "ÇANDIR",
        "STREET": "ABDULLAH EFENDİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "ÇANDIR",
        "STREET": "ATATÜRK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "ÇANDIR",
        "STREET": "BAHÇELİEVLER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "ÇANDIR",
        "STREET": "BARBOROS MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "ÇANDIR",
        "STREET": "BÜYÜKKIŞLA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "ÇANDIR",
        "STREET": "CUMHURİYET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "ÇANDIR",
        "STREET": "EŞREFPAŞA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "ÇANDIR",
        "STREET": "GÜLPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "ÇANDIR",
        "STREET": "KILIÇLAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "ÇANDIR",
        "STREET": "KOZAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "ÇANDIR",
        "STREET": "NABİOĞLU MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "ÇANDIR",
        "STREET": "YENİMEYDAN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "ÇANDIR",
        "STREET": "İĞDELİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "ÇANDIR",
        "STREET": "ŞAH SULTAN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "ÇAYIRALAN",
        "STREET": "ALİDEMİRCİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "ÇAYIRALAN",
        "STREET": "AVCILAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "ÇAYIRALAN",
        "STREET": "AVŞARALANI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "ÇAYIRALAN",
        "STREET": "AŞAĞITEKKE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "ÇAYIRALAN",
        "STREET": "AŞAĞIYAHYASARAY KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "ÇAYIRALAN",
        "STREET": "BAĞLARBAŞI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "ÇAYIRALAN",
        "STREET": "CUMHURİYET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "ÇAYIRALAN",
        "STREET": "CURALİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "ÇAYIRALAN",
        "STREET": "DEREKEMAL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "ÇAYIRALAN",
        "STREET": "ELÇİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "ÇAYIRALAN",
        "STREET": "EVCİLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "ÇAYIRALAN",
        "STREET": "FAHRALI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "ÇAYIRALAN",
        "STREET": "FATİH MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "ÇAYIRALAN",
        "STREET": "GÜLDERESİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "ÇAYIRALAN",
        "STREET": "GÜNYAYLA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "ÇAYIRALAN",
        "STREET": "GÜZELYAYLA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "ÇAYIRALAN",
        "STREET": "KALETEPE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "ÇAYIRALAN",
        "STREET": "KARAAZAP KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "ÇAYIRALAN",
        "STREET": "KARAKIŞLA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "ÇAYIRALAN",
        "STREET": "KONUKLAR-FATİH MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "ÇAYIRALAN",
        "STREET": "KONUKLAR-YAVUZ SULTAN SELİM MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "ÇAYIRALAN",
        "STREET": "KONUKLAR-YILDIRIM BEYAZIT MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "ÇAYIRALAN",
        "STREET": "KÜLEKÇİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "ÇAYIRALAN",
        "STREET": "MENTEŞE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "ÇAYIRALAN",
        "STREET": "SÖBEÇİMEN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "ÇAYIRALAN",
        "STREET": "TURLUHAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "ÇAYIRALAN",
        "STREET": "YENİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "ÇAYIRALAN",
        "STREET": "YUKARITEKKE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "ÇAYIRALAN",
        "STREET": "YUKARIYAHYASARAY KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "ÇAYIRALAN",
        "STREET": "ÇOKRADAN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "ÇAYIRALAN",
        "STREET": "ÇUKURÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "ÇAYIRALAN",
        "STREET": "İNÖNÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "ÇAYIRALAN",
        "STREET": "İSHAKLI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "ÇEKEREK",
        "STREET": "ALIÇLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "ÇEKEREK",
        "STREET": "ARPAÇ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "ÇEKEREK",
        "STREET": "BAHÇELİEVLER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "ÇEKEREK",
        "STREET": "BAYINDIRHÜYÜK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "ÇEKEREK",
        "STREET": "BAZLAMBAÇ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "ÇEKEREK",
        "STREET": "BAĞLARBAŞI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "ÇEKEREK",
        "STREET": "BAŞALAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "ÇEKEREK",
        "STREET": "BAŞPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "ÇEKEREK",
        "STREET": "BEYYURDU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "ÇEKEREK",
        "STREET": "CEMALOĞLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "ÇEKEREK",
        "STREET": "DEMİRCİALAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "ÇEKEREK",
        "STREET": "DOĞANOĞLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "ÇEKEREK",
        "STREET": "EKİZCE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "ÇEKEREK",
        "STREET": "ELEMİN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "ÇEKEREK",
        "STREET": "FAKIDAĞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "ÇEKEREK",
        "STREET": "FUADİYE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "ÇEKEREK",
        "STREET": "GÖKDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "ÇEKEREK",
        "STREET": "GÖNÜLYURDU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "ÇEKEREK",
        "STREET": "HAMZALI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "ÇEKEREK",
        "STREET": "KALEDERESİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "ÇEKEREK",
        "STREET": "KAMIŞCIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "ÇEKEREK",
        "STREET": "KAVAKALANI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "ÇEKEREK",
        "STREET": "KAYALAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "ÇEKEREK",
        "STREET": "KIRKDİLİM KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "ÇEKEREK",
        "STREET": "KOYUNCULU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "ÇEKEREK",
        "STREET": "KURTAĞILI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "ÇEKEREK",
        "STREET": "KURUÇAY KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "ÇEKEREK",
        "STREET": "KUZGUN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "ÇEKEREK",
        "STREET": "KÖRPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "ÇEKEREK",
        "STREET": "MEHMETLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "ÇEKEREK",
        "STREET": "MEVLANA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "ÇEKEREK",
        "STREET": "ORTAOBA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "ÇEKEREK",
        "STREET": "SARIKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "ÇEKEREK",
        "STREET": "SEYİT AHMET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "ÇEKEREK",
        "STREET": "TİPİDERESİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "ÇEKEREK",
        "STREET": "YENİYOL MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "ÇEKEREK",
        "STREET": "YUKARIKARAHACILI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "ÇEKEREK",
        "STREET": "YUKARIKARAKAYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "ÇEKEREK",
        "STREET": "YUKARIOBA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "ÇEKEREK",
        "STREET": "YUNUS EMRE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "ÇEKEREK",
        "STREET": "ÇAKIR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "ÇEKEREK",
        "STREET": "ÇANDIR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "ÇEKEREK",
        "STREET": "ÇAYIRÖZÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "ÇEKEREK",
        "STREET": "ÇELTEK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "ÇEKEREK",
        "STREET": "ÖZÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "ÇEKEREK",
        "STREET": "ÖZÜKAVAK-BAĞLARBAŞI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "ÇEKEREK",
        "STREET": "ÖZÜKAVAK-GÜLTEPE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "ÇEKEREK",
        "STREET": "İLBEYLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "ÇEKEREK",
        "STREET": "İSAKLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "ŞEFAATLİ",
        "STREET": "AKCAMİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "ŞEFAATLİ",
        "STREET": "AKÇAKOYUNLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "ŞEFAATLİ",
        "STREET": "ALİFAKILI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "ŞEFAATLİ",
        "STREET": "ARMAĞAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "ŞEFAATLİ",
        "STREET": "ARİFE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "ŞEFAATLİ",
        "STREET": "BAHÇELİEVLER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "ŞEFAATLİ",
        "STREET": "BAĞLARBAŞI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "ŞEFAATLİ",
        "STREET": "BAŞKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "ŞEFAATLİ",
        "STREET": "CAFERLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "ŞEFAATLİ",
        "STREET": "CANKILI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "ŞEFAATLİ",
        "STREET": "CICIKLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "ŞEFAATLİ",
        "STREET": "CUMHURİYET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "ŞEFAATLİ",
        "STREET": "DEDELİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "ŞEFAATLİ",
        "STREET": "EKİNCİUŞAĞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "ŞEFAATLİ",
        "STREET": "FATİH  MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "ŞEFAATLİ",
        "STREET": "GÖZELLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "ŞEFAATLİ",
        "STREET": "GÜLPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "ŞEFAATLİ",
        "STREET": "GÜLİSTAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "ŞEFAATLİ",
        "STREET": "H.BAYRAM KARABULUT MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "ŞEFAATLİ",
        "STREET": "HALAÇLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "ŞEFAATLİ",
        "STREET": "HAMZALI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "ŞEFAATLİ",
        "STREET": "HÜYÜKKIŞLA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "ŞEFAATLİ",
        "STREET": "KABACAOĞLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "ŞEFAATLİ",
        "STREET": "KABAKİNİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "ŞEFAATLİ",
        "STREET": "KALE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "ŞEFAATLİ",
        "STREET": "KARAKAYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "ŞEFAATLİ",
        "STREET": "KARALAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "ŞEFAATLİ",
        "STREET": "KAYKILI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "ŞEFAATLİ",
        "STREET": "KAZLIUŞAĞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "ŞEFAATLİ",
        "STREET": "KIZILKOCA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "ŞEFAATLİ",
        "STREET": "KIZILYAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "ŞEFAATLİ",
        "STREET": "KONAKLI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "ŞEFAATLİ",
        "STREET": "KOÇKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "ŞEFAATLİ",
        "STREET": "KUMKUYU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "ŞEFAATLİ",
        "STREET": "KUZAYCA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "ŞEFAATLİ",
        "STREET": "KÜÇÜKİNCİRLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "ŞEFAATLİ",
        "STREET": "OSMANLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "ŞEFAATLİ",
        "STREET": "PAŞAKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "ŞEFAATLİ",
        "STREET": "RIZVAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "ŞEFAATLİ",
        "STREET": "SAATLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "ŞEFAATLİ",
        "STREET": "SARIKENT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "ŞEFAATLİ",
        "STREET": "SAÇLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "ŞEFAATLİ",
        "STREET": "SEYDİYAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "ŞEFAATLİ",
        "STREET": "TAHİROĞLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "ŞEFAATLİ",
        "STREET": "TEMLİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "ŞEFAATLİ",
        "STREET": "TÜRÜDÜLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "ŞEFAATLİ",
        "STREET": "YAYLAPINAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "ŞEFAATLİ",
        "STREET": "YENİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "ŞEFAATLİ",
        "STREET": "ÇAYDOĞAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "ŞEFAATLİ",
        "STREET": "İBRAHİMHACILI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "ŞEFAATLİ",
        "STREET": "İNCEŞEHİR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "YOZGAT",
        "DISTRICT": "ŞEFAATLİ",
        "STREET": "ŞEREFOĞLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "ALAPLI",
        "STREET": "AHATLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "ALAPLI",
        "STREET": "AHİLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "ALAPLI",
        "STREET": "ALAPLIBÖLÜCEK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "ALAPLI",
        "STREET": "ALAPLIKOCAALİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "ALAPLI",
        "STREET": "ALAPLIORTACI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "ALAPLI",
        "STREET": "ALAPLIÖMERLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "ALAPLI",
        "STREET": "ALİOĞLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "ALAPLI",
        "STREET": "AYDINYAYLA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "ALAPLI",
        "STREET": "AŞAĞIDAĞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "ALAPLI",
        "STREET": "AŞAĞIDOĞANCILAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "ALAPLI",
        "STREET": "AŞAĞITEKKE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "ALAPLI",
        "STREET": "BEKTAŞLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "ALAPLI",
        "STREET": "BELEN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "ALAPLI",
        "STREET": "BÜYÜKTEKKE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "ALAPLI",
        "STREET": "CANBAZLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "ALAPLI",
        "STREET": "DEMİRCİLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "ALAPLI",
        "STREET": "DOĞANCILAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "ALAPLI",
        "STREET": "DURHANLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "ALAPLI",
        "STREET": "FINDIKLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "ALAPLI",
        "STREET": "GÖKHASAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "ALAPLI",
        "STREET": "GÜMELİ-ERENKÖY MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "ALAPLI",
        "STREET": "GÜMELİ-FINDIKLI AĞILI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "ALAPLI",
        "STREET": "GÜMELİ-KALE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "ALAPLI",
        "STREET": "GÜMELİ-MERKEZ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "ALAPLI",
        "STREET": "GÜRPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "ALAPLI",
        "STREET": "HACIHASAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "ALAPLI",
        "STREET": "HALLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "ALAPLI",
        "STREET": "HASANLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "ALAPLI",
        "STREET": "HÜSEYİNLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "ALAPLI",
        "STREET": "KABALAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "ALAPLI",
        "STREET": "KARŞIYAKA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "ALAPLI",
        "STREET": "KASIMLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "ALAPLI",
        "STREET": "KILÇAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "ALAPLI",
        "STREET": "KIRAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "ALAPLI",
        "STREET": "KOCAMAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "ALAPLI",
        "STREET": "KÜRKÜKLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "ALAPLI",
        "STREET": "KÜÇÜKKAYMAZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "ALAPLI",
        "STREET": "KÜÇÜKTEKKE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "ALAPLI",
        "STREET": "MERKEZ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "ALAPLI",
        "STREET": "MOLLABEY KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "ALAPLI",
        "STREET": "MUSABEYLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "ALAPLI",
        "STREET": "OKÇULAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "ALAPLI",
        "STREET": "ONURLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "ALAPLI",
        "STREET": "OSMANLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "ALAPLI",
        "STREET": "SABIRLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "ALAPLI",
        "STREET": "SARIKADI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "ALAPLI",
        "STREET": "SOFULLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "ALAPLI",
        "STREET": "TEPEKÖY MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "ALAPLI",
        "STREET": "YEDİGELLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "ALAPLI",
        "STREET": "YENİ SİTELER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "ALAPLI",
        "STREET": "YENİDOĞANLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "ALAPLI",
        "STREET": "YENİKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "ALAPLI",
        "STREET": "YENİMAHALLE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "ALAPLI",
        "STREET": "YEŞİLYURT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "ALAPLI",
        "STREET": "ÇAMCILAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "ALAPLI",
        "STREET": "ÇAMLIBEL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "ALAPLI",
        "STREET": "ÇATAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "ALAPLI",
        "STREET": "ÇAYKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "ALAPLI",
        "STREET": "ÇENGELLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "ALAPLI",
        "STREET": "ÇİÇEKLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "ALAPLI",
        "STREET": "İSAFAKILI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "DEVREK",
        "STREET": "ADATEPE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "DEVREK",
        "STREET": "AHMETOĞLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "DEVREK",
        "STREET": "AKSU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "DEVREK",
        "STREET": "AKÇABEY KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "DEVREK",
        "STREET": "AKÇASU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "DEVREK",
        "STREET": "ALPARSLAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "DEVREK",
        "STREET": "ATAKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "DEVREK",
        "STREET": "AĞALAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "DEVREK",
        "STREET": "BAKIRCILAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "DEVREK",
        "STREET": "BAŞLARKADI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "DEVREK",
        "STREET": "BILIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "DEVREK",
        "STREET": "BURHANOĞLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "DEVREK",
        "STREET": "BÖLÜCEK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "DEVREK",
        "STREET": "BÜKKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "DEVREK",
        "STREET": "DEDEOĞLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "DEVREK",
        "STREET": "DEREBULACA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "DEVREK",
        "STREET": "DURUPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "DEVREK",
        "STREET": "ERENLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "DEVREK",
        "STREET": "ERMEKOĞLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "DEVREK",
        "STREET": "ESKİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "DEVREK",
        "STREET": "EVEYİKLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "DEVREK",
        "STREET": "EĞERCİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "DEVREK",
        "STREET": "GÜMÜŞPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "DEVREK",
        "STREET": "GÜRBÜZLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "DEVREK",
        "STREET": "GÜRÇEŞME KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "DEVREK",
        "STREET": "GÜZELYURT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "DEVREK",
        "STREET": "HALİLBEYOĞLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "DEVREK",
        "STREET": "HATİPLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "DEVREK",
        "STREET": "HIŞIROĞLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "DEVREK",
        "STREET": "HÜSEYİNÇAVUŞOĞLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "DEVREK",
        "STREET": "KABACA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "DEVREK",
        "STREET": "KARABAŞLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "DEVREK",
        "STREET": "KARACAÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "DEVREK",
        "STREET": "KARAKOÇLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "DEVREK",
        "STREET": "KARŞIYAKA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "DEVREK",
        "STREET": "KOMŞULAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "DEVREK",
        "STREET": "KOZLUGÜNEY KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "DEVREK",
        "STREET": "KOZLUKADI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "DEVREK",
        "STREET": "KOZLUÇAY KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "DEVREK",
        "STREET": "KURUDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "DEVREK",
        "STREET": "KUZCA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "DEVREK",
        "STREET": "MADENCİOĞLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "DEVREK",
        "STREET": "MAHMUTOĞLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "DEVREK",
        "STREET": "MEKEKLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "DEVREK",
        "STREET": "MUMCUOĞLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "DEVREK",
        "STREET": "MÜFETTİŞLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "DEVREK",
        "STREET": "MÜSTAKİMLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "DEVREK",
        "STREET": "NİZAMLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "DEVREK",
        "STREET": "OSMANBEYLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "DEVREK",
        "STREET": "OĞUZHAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "DEVREK",
        "STREET": "PELİTLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "DEVREK",
        "STREET": "PINARÖNÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "DEVREK",
        "STREET": "PURTULOĞLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "DEVREK",
        "STREET": "SABUNLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "DEVREK",
        "STREET": "SARNAZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "DEVREK",
        "STREET": "SERDAROĞLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "DEVREK",
        "STREET": "SEYİSOĞLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "DEVREK",
        "STREET": "SOFULAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "DEVREK",
        "STREET": "SİPAHİLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "DEVREK",
        "STREET": "TABAKLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "DEVREK",
        "STREET": "TAŞKESEN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "DEVREK",
        "STREET": "TELLİOĞLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "DEVREK",
        "STREET": "TOHUMLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "DEVREK",
        "STREET": "TOSUNLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "DEVREK",
        "STREET": "TÜRKMENOĞLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "DEVREK",
        "STREET": "VELİBEYLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "DEVREK",
        "STREET": "YASSIÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "DEVREK",
        "STREET": "YAZICIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "DEVREK",
        "STREET": "YAZICIOĞLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "DEVREK",
        "STREET": "YAĞMURCA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "DEVREK",
        "STREET": "YENİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "DEVREK",
        "STREET": "YENİKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "DEVREK",
        "STREET": "YEŞİLADA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "DEVREK",
        "STREET": "YEŞİLKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "DEVREK",
        "STREET": "YEŞİLYURT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "DEVREK",
        "STREET": "YEŞİLÖZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "DEVREK",
        "STREET": "YILANLICA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "DEVREK",
        "STREET": "YILANLICAKUZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "DEVREK",
        "STREET": "ÇAYDEĞİRMENİ-HÜMMETOĞLU MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "DEVREK",
        "STREET": "ÇAYDEĞİRMENİ-KEMERLER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "DEVREK",
        "STREET": "ÇAYDEĞİRMENİ-MERKEZ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "DEVREK",
        "STREET": "ÇAYDEĞİRMENİ-RECEP TAYYİP ERDOĞAN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "DEVREK",
        "STREET": "ÇAYDEĞİRMENİ-YENİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "DEVREK",
        "STREET": "ÇAYDEĞİRMENİ-ÇAY MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "DEVREK",
        "STREET": "ÇAĞLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "DEVREK",
        "STREET": "ÇOLAKPEHLİVAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "DEVREK",
        "STREET": "ÇOMAKLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "DEVREK",
        "STREET": "ÇORAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "DEVREK",
        "STREET": "ÖZBAĞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "DEVREK",
        "STREET": "ÖZPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "DEVREK",
        "STREET": "ÖZYURT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "DEVREK",
        "STREET": "İSABEYLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "DEVREK",
        "STREET": "İSLAMKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "DEVREK",
        "STREET": "İSMET PAŞA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "EREĞLİ",
        "STREET": "ABDİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "EREĞLİ",
        "STREET": "AKARCA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "EREĞLİ",
        "STREET": "AKKAYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "EREĞLİ",
        "STREET": "AKKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "EREĞLİ",
        "STREET": "ALACABÜK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "EREĞLİ",
        "STREET": "ALAPLISOFULAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "EREĞLİ",
        "STREET": "ARTIKLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "EREĞLİ",
        "STREET": "ASARLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "EREĞLİ",
        "STREET": "AYDIN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "EREĞLİ",
        "STREET": "AYDINLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "EREĞLİ",
        "STREET": "AŞAĞIHOCALAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "EREĞLİ",
        "STREET": "AŞAĞIKAYALIDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "EREĞLİ",
        "STREET": "BAKIRLIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "EREĞLİ",
        "STREET": "BALI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "EREĞLİ",
        "STREET": "BALLICA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "EREĞLİ",
        "STREET": "BAYAT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "EREĞLİ",
        "STREET": "BAĞLIK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "EREĞLİ",
        "STREET": "BAŞÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "EREĞLİ",
        "STREET": "BAŞÖRENDOĞANCILAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "EREĞLİ",
        "STREET": "BELEN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "EREĞLİ",
        "STREET": "BELENYENİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "EREĞLİ",
        "STREET": "BÖLÜCEK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "EREĞLİ",
        "STREET": "CEMALLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "EREĞLİ",
        "STREET": "DANİŞMENTLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "EREĞLİ",
        "STREET": "DAVUTLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "EREĞLİ",
        "STREET": "DAĞLAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "EREĞLİ",
        "STREET": "DAĞLICA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "EREĞLİ",
        "STREET": "DEDELER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "EREĞLİ",
        "STREET": "DÜZPELİT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "EREĞLİ",
        "STREET": "ELMA TEPE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "EREĞLİ",
        "STREET": "ELMACI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "EREĞLİ",
        "STREET": "EMİRALİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "EREĞLİ",
        "STREET": "ESENKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "EREĞLİ",
        "STREET": "ESENLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "EREĞLİ",
        "STREET": "FINDIKLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "EREĞLİ",
        "STREET": "GEBE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "EREĞLİ",
        "STREET": "GÖKTEPE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "EREĞLİ",
        "STREET": "GÜLDERE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "EREĞLİ",
        "STREET": "GÜLLÜK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "EREĞLİ",
        "STREET": "GÜLÜÇ-BEKYERİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "EREĞLİ",
        "STREET": "GÜLÜÇ-MERKEZ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "EREĞLİ",
        "STREET": "GÜLÜÇ-ÇENGEL MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "EREĞLİ",
        "STREET": "GÜLÜÇ-ÖRENCİK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "EREĞLİ",
        "STREET": "GÜNEŞLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "EREĞLİ",
        "STREET": "GÜZELYURT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "EREĞLİ",
        "STREET": "HACIOSMANLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "EREĞLİ",
        "STREET": "HACIUSLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "EREĞLİ",
        "STREET": "HALAŞLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "EREĞLİ",
        "STREET": "HAMZAFAKILI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "EREĞLİ",
        "STREET": "HASANKAHYALAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "EREĞLİ",
        "STREET": "HASBEYLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "EREĞLİ",
        "STREET": "IŞIKLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "EREĞLİ",
        "STREET": "KANDİLLİ-ARMUTÇUK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "EREĞLİ",
        "STREET": "KANDİLLİ-GEYİKBELİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "EREĞLİ",
        "STREET": "KANDİLLİ-GÖKÇELER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "EREĞLİ",
        "STREET": "KANDİLLİ-YENİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "EREĞLİ",
        "STREET": "KANDİLLİ-ŞEHİTLİK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "EREĞLİ",
        "STREET": "KARAKAVUZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "EREĞLİ",
        "STREET": "KAVAKLIK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "EREĞLİ",
        "STREET": "KAYMAKLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "EREĞLİ",
        "STREET": "KDZ.EREĞLİ A2 TİPİ KAPALI CEZA İNFAZ KURUMU",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "EREĞLİ",
        "STREET": "KEPEZ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "EREĞLİ",
        "STREET": "KESTANECİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "EREĞLİ",
        "STREET": "KETENCİLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "EREĞLİ",
        "STREET": "KEŞKEK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "EREĞLİ",
        "STREET": "KIRMACI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "EREĞLİ",
        "STREET": "KIYICAK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "EREĞLİ",
        "STREET": "KIZILCA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "EREĞLİ",
        "STREET": "KIZILCAPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "EREĞLİ",
        "STREET": "KIŞLA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "EREĞLİ",
        "STREET": "KOCAALİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "EREĞLİ",
        "STREET": "KORUBAŞI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "EREĞLİ",
        "STREET": "KURTLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "EREĞLİ",
        "STREET": "KÖSELER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "EREĞLİ",
        "STREET": "KÜLAH KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "EREĞLİ",
        "STREET": "KİRENCİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "EREĞLİ",
        "STREET": "KİRMANLI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "EREĞLİ",
        "STREET": "MURTAZA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "EREĞLİ",
        "STREET": "MÜFTÜ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "EREĞLİ",
        "STREET": "ORHANLAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "EREĞLİ",
        "STREET": "ORMANLI ORMANLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "EREĞLİ",
        "STREET": "ORMANLI-MERKEZ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "EREĞLİ",
        "STREET": "ORMANLI-SARIKAYA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "EREĞLİ",
        "STREET": "ORMANLI-SEYİT AHMETLİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "EREĞLİ",
        "STREET": "ORMANLI-SİNİTLİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "EREĞLİ",
        "STREET": "ORTACI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "EREĞLİ",
        "STREET": "ORTAKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "EREĞLİ",
        "STREET": "OSMANLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "EREĞLİ",
        "STREET": "OVA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "EREĞLİ",
        "STREET": "PEMBECİLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "EREĞLİ",
        "STREET": "PINARCIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "EREĞLİ",
        "STREET": "RAMAZANLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "EREĞLİ",
        "STREET": "RÜŞANLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "EREĞLİ",
        "STREET": "SAKALLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "EREĞLİ",
        "STREET": "SALTUKLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "EREĞLİ",
        "STREET": "SARIKAYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "EREĞLİ",
        "STREET": "SARIKOKMAZ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "EREĞLİ",
        "STREET": "SERİNTEPE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "EREĞLİ",
        "STREET": "SOFULAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "EREĞLİ",
        "STREET": "SOĞANLIYÖRÜK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "EREĞLİ",
        "STREET": "SÜCÜLLÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "EREĞLİ",
        "STREET": "SÜLEYMANBEYLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "EREĞLİ",
        "STREET": "SÜLEYMANLAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "EREĞLİ",
        "STREET": "SÜTLÜCE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "EREĞLİ",
        "STREET": "TEPEÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "EREĞLİ",
        "STREET": "TERZİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "EREĞLİ",
        "STREET": "TOPALLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "EREĞLİ",
        "STREET": "TOPÇALI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "EREĞLİ",
        "STREET": "TOYFANLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "EREĞLİ",
        "STREET": "ULUDAĞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "EREĞLİ",
        "STREET": "UZUNMEHMET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "EREĞLİ",
        "STREET": "UZUNÇAYIR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "EREĞLİ",
        "STREET": "VAKIF KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "EREĞLİ",
        "STREET": "VELİDAĞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "EREĞLİ",
        "STREET": "YALNIZÇAM KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "EREĞLİ",
        "STREET": "YARAŞLIYÖRÜK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "EREĞLİ",
        "STREET": "YAZICILAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "EREĞLİ",
        "STREET": "YAZIÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "EREĞLİ",
        "STREET": "YENİDOĞANCILAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "EREĞLİ",
        "STREET": "YENİKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "EREĞLİ",
        "STREET": "YEŞİLKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "EREĞLİ",
        "STREET": "YUKARIHOCALAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "EREĞLİ",
        "STREET": "YUNUSLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "EREĞLİ",
        "STREET": "ZINDANCILAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "EREĞLİ",
        "STREET": "ÇAMLIBEL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "EREĞLİ",
        "STREET": "ÇAYIRLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "EREĞLİ",
        "STREET": "ÇAYLIOĞLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "EREĞLİ",
        "STREET": "ÇEVLİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "EREĞLİ",
        "STREET": "ÇÖMLEKÇİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "EREĞLİ",
        "STREET": "ÇİĞDEMLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "EREĞLİ",
        "STREET": "ÖMERLİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "EREĞLİ",
        "STREET": "ÖREN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "EREĞLİ",
        "STREET": "ÖĞBERLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "EREĞLİ",
        "STREET": "ÜVEYİKLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "EREĞLİ",
        "STREET": "ÜÇKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "EREĞLİ",
        "STREET": "İMRANLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "EREĞLİ",
        "STREET": "İSKENDERLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "EREĞLİ",
        "STREET": "ŞAMLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "GÖKÇEBEY",
        "STREET": "AKTARLA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "GÖKÇEBEY",
        "STREET": "ALİUSTA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "GÖKÇEBEY",
        "STREET": "AYDINLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "GÖKÇEBEY",
        "STREET": "BAKACAKKADI-KADIOĞLU MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "GÖKÇEBEY",
        "STREET": "BAKACAKKADI-MERKEZ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "GÖKÇEBEY",
        "STREET": "BAKACAKKADI-SATIBEYLER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "GÖKÇEBEY",
        "STREET": "BAKİLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "GÖKÇEBEY",
        "STREET": "BODAÇ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "GÖKÇEBEY",
        "STREET": "DAĞDEMİRCİLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "GÖKÇEBEY",
        "STREET": "DUHANCILAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "GÖKÇEBEY",
        "STREET": "GAZİLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "GÖKÇEBEY",
        "STREET": "HACIMUSA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "GÖKÇEBEY",
        "STREET": "KARAHATİPLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "GÖKÇEBEY",
        "STREET": "KARAPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "GÖKÇEBEY",
        "STREET": "MERKEZ-ÇUKUR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "GÖKÇEBEY",
        "STREET": "MUHARREMLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "GÖKÇEBEY",
        "STREET": "NAMAZGAH KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "GÖKÇEBEY",
        "STREET": "ONMAYIS MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "GÖKÇEBEY",
        "STREET": "PAZARLIOĞLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "GÖKÇEBEY",
        "STREET": "SARAÇLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "GÖKÇEBEY",
        "STREET": "UZUNAHMETLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "GÖKÇEBEY",
        "STREET": "VEYİSOĞLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "GÖKÇEBEY",
        "STREET": "YENİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "GÖKÇEBEY",
        "STREET": "YEŞİLKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "GÖKÇEBEY",
        "STREET": "ÇARŞI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "GÖKÇEBEY",
        "STREET": "ÇAY MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "GÖKÇEBEY",
        "STREET": "ÖRMECİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "GÖKÇEBEY",
        "STREET": "ÜÇBURGU MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "GÖKÇEBEY",
        "STREET": "İSTASYON MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "KOZLU",
        "STREET": "19 MAYIS MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "KOZLU",
        "STREET": "AKŞEYH KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "KOZLU",
        "STREET": "AŞAĞIÇAYIR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "KOZLU",
        "STREET": "BALÇIKLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "KOZLU",
        "STREET": "BOZCA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "KOZLU",
        "STREET": "DAĞKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "KOZLU",
        "STREET": "DEREKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "KOZLU",
        "STREET": "EBEGÜMECİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "KOZLU",
        "STREET": "ENSEKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "KOZLU",
        "STREET": "ESENKÖY MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "KOZLU",
        "STREET": "FATİH MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "KOZLU",
        "STREET": "FUTA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "KOZLU",
        "STREET": "GÜCEK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "KOZLU",
        "STREET": "GÜNEY MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "KOZLU",
        "STREET": "KARGALAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "KOZLU",
        "STREET": "KILIÇ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "KOZLU",
        "STREET": "KIZILCAKESE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "KOZLU",
        "STREET": "KOZLUKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "KOZLU",
        "STREET": "KÖSERECİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "KOZLU",
        "STREET": "MERKEZ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "KOZLU",
        "STREET": "SAKAKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "KOZLU",
        "STREET": "SEYFETLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "KOZLU",
        "STREET": "SİVRİLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "KOZLU",
        "STREET": "TAŞBACA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "KOZLU",
        "STREET": "UZUNGÜNEY KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "KOZLU",
        "STREET": "YAHMA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "KOZLU",
        "STREET": "ÇAMKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "KOZLU",
        "STREET": "ÇARDAŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "KOZLU",
        "STREET": "ÇIRGAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "KOZLU",
        "STREET": "ÖRENCİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "KOZLU",
        "STREET": "ÜÇKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "KOZLU",
        "STREET": "İHSANİYE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "KİLİMLİ",
        "STREET": "BELEDİYE SİTESİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "KİLİMLİ",
        "STREET": "BÖLÜM MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "KİLİMLİ",
        "STREET": "DAMARLI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "KİLİMLİ",
        "STREET": "GELİK-AYİÇİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "KİLİMLİ",
        "STREET": "GELİK-AYİÇİ MERKEZ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "KİLİMLİ",
        "STREET": "GELİK-ESEN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "KİLİMLİ",
        "STREET": "GELİK-GELİK MERKEZ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "KİLİMLİ",
        "STREET": "GELİK-KIRAT MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "KİLİMLİ",
        "STREET": "GÖBÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "KİLİMLİ",
        "STREET": "GÜNEY MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "KİLİMLİ",
        "STREET": "HİSAR ARKASI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "KİLİMLİ",
        "STREET": "KARADON MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "KİLİMLİ",
        "STREET": "KURTKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "KİLİMLİ",
        "STREET": "MERKEZ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "KİLİMLİ",
        "STREET": "MUSLU-CUMHURİYET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "KİLİMLİ",
        "STREET": "MUSLU-EMİRLER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "KİLİMLİ",
        "STREET": "MUSLU-MERKEZ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "KİLİMLİ",
        "STREET": "SUBATAN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "KİLİMLİ",
        "STREET": "TÜRKALİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "KİLİMLİ",
        "STREET": "ÇAMLIK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "KİLİMLİ",
        "STREET": "ÇATALAĞZI-CUMA YANI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "KİLİMLİ",
        "STREET": "ÇATALAĞZI-DOĞANCILAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "KİLİMLİ",
        "STREET": "ÇATALAĞZI-HACIOĞLU MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "KİLİMLİ",
        "STREET": "ÇATALAĞZI-KIRIMSA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "KİLİMLİ",
        "STREET": "ÇATALAĞZI-KOKURDAN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "KİLİMLİ",
        "STREET": "ÇATALAĞZI-KUZYAKA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "KİLİMLİ",
        "STREET": "ÇATALAĞZI-MERKEZ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "KİLİMLİ",
        "STREET": "ŞİRİNKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "MERKEZ",
        "STREET": "ALANCIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "MERKEZ",
        "STREET": "ASMA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "MERKEZ",
        "STREET": "AYVATLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "MERKEZ",
        "STREET": "BAHÇELİEVLER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "MERKEZ",
        "STREET": "BAĞLIK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "MERKEZ",
        "STREET": "BAŞTARLA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "MERKEZ",
        "STREET": "BEYCUMA-BEYTEPE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "MERKEZ",
        "STREET": "BEYCUMA-BİRLİK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "MERKEZ",
        "STREET": "BEYCUMA-KASIMLAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "MERKEZ",
        "STREET": "BEYCUMA-MERKEZ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "MERKEZ",
        "STREET": "BEYCUMA-MURATLI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "MERKEZ",
        "STREET": "BEYCUMA-YENİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "MERKEZ",
        "STREET": "BEYCUMA-ÇAMLIK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "MERKEZ",
        "STREET": "BİRLİK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "MERKEZ",
        "STREET": "DİLAVER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "MERKEZ",
        "STREET": "ECELER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "MERKEZ",
        "STREET": "ELVANPAZARCIK-CELEPÇİOĞLU MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "MERKEZ",
        "STREET": "ELVANPAZARCIK-CUMHURİYET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "MERKEZ",
        "STREET": "ELVANPAZARCIK-ELVAN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "MERKEZ",
        "STREET": "ELVANPAZARCIK-HAYAT MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "MERKEZ",
        "STREET": "ELVANPAZARCIK-MERKEZ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "MERKEZ",
        "STREET": "ELVANPAZARCIK-TASMACI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "MERKEZ",
        "STREET": "ELVANPAZARCIK-ÇALCA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "MERKEZ",
        "STREET": "HACIALİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "MERKEZ",
        "STREET": "HİMMETOĞLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "MERKEZ",
        "STREET": "KABALAKLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "MERKEZ",
        "STREET": "KALEOĞLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "MERKEZ",
        "STREET": "KARADERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "MERKEZ",
        "STREET": "KARAELMAS MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "MERKEZ",
        "STREET": "KARAMAN-KADI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "MERKEZ",
        "STREET": "KARAMAN-KARAMANLAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "MERKEZ",
        "STREET": "KARAMAN-MERKEZ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "MERKEZ",
        "STREET": "KARAMAN-SATIKLI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "MERKEZ",
        "STREET": "KARAMAN-YENİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "MERKEZ",
        "STREET": "KARAPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "MERKEZ",
        "STREET": "KARDEŞLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "MERKEZ",
        "STREET": "KELLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "MERKEZ",
        "STREET": "KORUCAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "MERKEZ",
        "STREET": "KUMTARLA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "MERKEZ",
        "STREET": "KÖROĞLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "MERKEZ",
        "STREET": "MEŞRUTİYET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "MERKEZ",
        "STREET": "MİTHATPAŞA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "MERKEZ",
        "STREET": "OLUKYANI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "MERKEZ",
        "STREET": "ON TEMMUZ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "MERKEZ",
        "STREET": "OSMANLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "MERKEZ",
        "STREET": "SAPÇA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "MERKEZ",
        "STREET": "SARAYCIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "MERKEZ",
        "STREET": "SARIMSAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "MERKEZ",
        "STREET": "SOFULAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "MERKEZ",
        "STREET": "TAŞÇILAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "MERKEZ",
        "STREET": "TEPEBAŞI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "MERKEZ",
        "STREET": "TERAKKİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "MERKEZ",
        "STREET": "YAYLA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "MERKEZ",
        "STREET": "YENİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "MERKEZ",
        "STREET": "YEŞİL MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇAYDAMAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇAĞLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇINARTEPE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇUKURÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "MERKEZ",
        "STREET": "İNAĞZI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "MERKEZ",
        "STREET": "İNCİVEZ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "MERKEZ",
        "STREET": "ZONGULDAK M TİPİ KAPALI CEZA İNFAZ KURUMU",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "ÇAYCUMA",
        "STREET": "ADAKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "ÇAYCUMA",
        "STREET": "AHATLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "ÇAYCUMA",
        "STREET": "AKPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "ÇAYCUMA",
        "STREET": "AKYAMAÇ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "ÇAYCUMA",
        "STREET": "AKÇAHATİPLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "ÇAYCUMA",
        "STREET": "ALİKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "ÇAYCUMA",
        "STREET": "AYVAZLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "ÇAYCUMA",
        "STREET": "AŞAĞISARMAŞIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "ÇAYCUMA",
        "STREET": "AŞAĞIİHSANİYE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "ÇAYCUMA",
        "STREET": "BASAT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "ÇAYCUMA",
        "STREET": "BAŞARAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "ÇAYCUMA",
        "STREET": "BURUNKAYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "ÇAYCUMA",
        "STREET": "COBURLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "ÇAYCUMA",
        "STREET": "DAĞÜSTÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "ÇAYCUMA",
        "STREET": "DERECİKÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "ÇAYCUMA",
        "STREET": "DEREKÖSELER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "ÇAYCUMA",
        "STREET": "DERELİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "ÇAYCUMA",
        "STREET": "DURSUNLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "ÇAYCUMA",
        "STREET": "EMİRŞAH KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "ÇAYCUMA",
        "STREET": "ERENKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "ÇAYCUMA",
        "STREET": "ESENLİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "ÇAYCUMA",
        "STREET": "ESENTEPE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "ÇAYCUMA",
        "STREET": "ESENYURT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "ÇAYCUMA",
        "STREET": "FİLYOS-ABACIK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "ÇAYCUMA",
        "STREET": "FİLYOS-GERİŞ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "ÇAYCUMA",
        "STREET": "FİLYOS-HİSARÖNÜ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "ÇAYCUMA",
        "STREET": "FİLYOS-KOCAALİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "ÇAYCUMA",
        "STREET": "FİLYOS-SEFERCİK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "ÇAYCUMA",
        "STREET": "FİLYOS-ÖTEYÜZ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "ÇAYCUMA",
        "STREET": "GEMİCİLER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "ÇAYCUMA",
        "STREET": "GERİŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "ÇAYCUMA",
        "STREET": "GÖKÇELER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "ÇAYCUMA",
        "STREET": "GÖKÇETABAKLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "ÇAYCUMA",
        "STREET": "GÜDÜLLÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "ÇAYCUMA",
        "STREET": "GÜZELOĞLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "ÇAYCUMA",
        "STREET": "GÜZELYAKA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "ÇAYCUMA",
        "STREET": "GÜZELYURT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "ÇAYCUMA",
        "STREET": "HACILAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "ÇAYCUMA",
        "STREET": "HACIOSMANLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "ÇAYCUMA",
        "STREET": "HACIİBADİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "ÇAYCUMA",
        "STREET": "HELVACILAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "ÇAYCUMA",
        "STREET": "KADIOĞLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "ÇAYCUMA",
        "STREET": "KAHVECİOĞLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "ÇAYCUMA",
        "STREET": "KALAFATLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "ÇAYCUMA",
        "STREET": "KALAYCIOĞLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "ÇAYCUMA",
        "STREET": "KARAAHMETLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "ÇAYCUMA",
        "STREET": "KARAKOÇ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "ÇAYCUMA",
        "STREET": "KARALAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "ÇAYCUMA",
        "STREET": "KARAMUSA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "ÇAYCUMA",
        "STREET": "KARAMUSA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "ÇAYCUMA",
        "STREET": "KARAPINAR-AŞAĞI ÇUKUR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "ÇAYCUMA",
        "STREET": "KARAPINAR-DURMUŞLAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "ÇAYCUMA",
        "STREET": "KARAPINAR-MERKEZ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "ÇAYCUMA",
        "STREET": "KARAPINAR-TORLAKLAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "ÇAYCUMA",
        "STREET": "KARAPINAR-TİLKİLER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "ÇAYCUMA",
        "STREET": "KARAPINAR-YUKARI ÇUKUR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "ÇAYCUMA",
        "STREET": "KAYABAŞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "ÇAYCUMA",
        "STREET": "KAYIKÇILAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "ÇAYCUMA",
        "STREET": "KERİMLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "ÇAYCUMA",
        "STREET": "KIZILBEL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "ÇAYCUMA",
        "STREET": "KIŞLA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "ÇAYCUMA",
        "STREET": "KORAMANLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "ÇAYCUMA",
        "STREET": "MADENLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "ÇAYCUMA",
        "STREET": "MUHARREMŞAH KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "ÇAYCUMA",
        "STREET": "MUHSİNLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "ÇAYCUMA",
        "STREET": "MUSAUSTA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "ÇAYCUMA",
        "STREET": "NEBİOĞLU-KARAEVLİGERİŞ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "ÇAYCUMA",
        "STREET": "NEBİOĞLU-TERZİLER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "ÇAYCUMA",
        "STREET": "NEBİOĞLU-YENİ PAZAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "ÇAYCUMA",
        "STREET": "NEBİOĞLU-YEŞİLYURT MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "ÇAYCUMA",
        "STREET": "PEHLİVANLAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "ÇAYCUMA",
        "STREET": "PERŞEMBE-HACI KADI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "ÇAYCUMA",
        "STREET": "PERŞEMBE-KEÇECİOĞLU MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "ÇAYCUMA",
        "STREET": "PERŞEMBE-MERKEZ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "ÇAYCUMA",
        "STREET": "PERŞEMBE-SARI KADI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "ÇAYCUMA",
        "STREET": "PERŞEMBE-YENİ MAHALLE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "ÇAYCUMA",
        "STREET": "RAMAZANOĞLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "ÇAYCUMA",
        "STREET": "SALTUKOVA-DÜZ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "ÇAYCUMA",
        "STREET": "SALTUKOVA-KARBOĞAZ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "ÇAYCUMA",
        "STREET": "SALTUKOVA-KOKAKSU MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "ÇAYCUMA",
        "STREET": "SALTUKOVA-SARIKUM MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "ÇAYCUMA",
        "STREET": "SANDALLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "ÇAYCUMA",
        "STREET": "SARMAŞIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "ÇAYCUMA",
        "STREET": "SAZKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "ÇAYCUMA",
        "STREET": "SERDAROĞLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "ÇAYCUMA",
        "STREET": "SOFULAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "ÇAYCUMA",
        "STREET": "SİPAHİLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "ÇAYCUMA",
        "STREET": "TAŞÇILI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "ÇAYCUMA",
        "STREET": "TEMENLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "ÇAYCUMA",
        "STREET": "TORLAKLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "ÇAYCUMA",
        "STREET": "ULUKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "ÇAYCUMA",
        "STREET": "VELİKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "ÇAYCUMA",
        "STREET": "VELİOĞLU MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "ÇAYCUMA",
        "STREET": "YAKADEMİRCİLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "ÇAYCUMA",
        "STREET": "YAZIBAŞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "ÇAYCUMA",
        "STREET": "YAZIKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "ÇAYCUMA",
        "STREET": "YENİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "ÇAYCUMA",
        "STREET": "YENİKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "ÇAYCUMA",
        "STREET": "YEŞİLKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "ÇAYCUMA",
        "STREET": "YEŞİLTEPE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "ÇAYCUMA",
        "STREET": "YEŞİLYAYLA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "ÇAYCUMA",
        "STREET": "YEŞİLYURT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "ÇAYCUMA",
        "STREET": "YOLGEÇEN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "ÇAYCUMA",
        "STREET": "YUKARIDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "ÇAYCUMA",
        "STREET": "YUKARIGÖYNÜK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "ÇAYCUMA",
        "STREET": "YUKARIİHSANİYE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "ÇAYCUMA",
        "STREET": "ÇAMLIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "ÇAYCUMA",
        "STREET": "ÇAY MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "ÇAYCUMA",
        "STREET": "ÇAYIR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "ÇAYCUMA",
        "STREET": "ÇAYKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "ÇAYCUMA",
        "STREET": "ÇOMRANLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "ÇAYCUMA",
        "STREET": "ÇORAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "ÇAYCUMA",
        "STREET": "ÇÖMLEKÇİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "ÇAYCUMA",
        "STREET": "İHSANOĞLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "ÇAYCUMA",
        "STREET": "İSTASYON MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "ÇAYCUMA",
        "STREET": "ŞEHLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "ÇAYCUMA",
        "STREET": "ŞENKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "ZONGULDAK",
        "DISTRICT": "ÇAYCUMA",
        "STREET": "ŞEYHOĞLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "AYVACIK",
        "STREET": "ADATEPE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "AYVACIK",
        "STREET": "AHMETLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "AYVACIK",
        "STREET": "AHMETÇE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "AYVACIK",
        "STREET": "AKÇİN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "AYVACIK",
        "STREET": "ARIKLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "AYVACIK",
        "STREET": "BABADERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "AYVACIK",
        "STREET": "BABAKALE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "AYVACIK",
        "STREET": "BADEMLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "AYVACIK",
        "STREET": "BAHARLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "AYVACIK",
        "STREET": "BAHÇEDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "AYVACIK",
        "STREET": "BALABANLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "AYVACIK",
        "STREET": "BEHRAM KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "AYVACIK",
        "STREET": "BEKTAŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "AYVACIK",
        "STREET": "BUDAKLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "AYVACIK",
        "STREET": "BÜYÜKHUSUN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "AYVACIK",
        "STREET": "BİLALLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "AYVACIK",
        "STREET": "CEMALLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "AYVACIK",
        "STREET": "DEMİRCİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "AYVACIK",
        "STREET": "DİBEKLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "AYVACIK",
        "STREET": "ERECEK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "AYVACIK",
        "STREET": "FATİH MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "AYVACIK",
        "STREET": "GÜLPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "AYVACIK",
        "STREET": "GÜZELKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "AYVACIK",
        "STREET": "HAMDİBEY MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "AYVACIK",
        "STREET": "HÜSEYİNFAKI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "AYVACIK",
        "STREET": "KAYALAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "AYVACIK",
        "STREET": "KESTANELİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "AYVACIK",
        "STREET": "KEÇİKAYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "AYVACIK",
        "STREET": "KIRCA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "AYVACIK",
        "STREET": "KISACIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "AYVACIK",
        "STREET": "KOCAKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "AYVACIK",
        "STREET": "KORUBAŞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "AYVACIK",
        "STREET": "KOYUNEVİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "AYVACIK",
        "STREET": "KOZLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "AYVACIK",
        "STREET": "KULFAL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "AYVACIK",
        "STREET": "KURUOBA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "AYVACIK",
        "STREET": "KÖSEDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "AYVACIK",
        "STREET": "KÜÇÜKHUSUN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "AYVACIK",
        "STREET": "KÜÇÜKKUYU-GÖKÇETEPE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "AYVACIK",
        "STREET": "KÜÇÜKKUYU-MIHLI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "AYVACIK",
        "STREET": "KÜÇÜKKUYU-SAHİL MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "AYVACIK",
        "STREET": "KÜÇÜKÇETMİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "AYVACIK",
        "STREET": "MİSVAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "AYVACIK",
        "STREET": "NALDÖKEN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "AYVACIK",
        "STREET": "NUSRATLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "AYVACIK",
        "STREET": "PAŞAKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "AYVACIK",
        "STREET": "PINARDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "AYVACIK",
        "STREET": "SAPANCA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "AYVACIK",
        "STREET": "SAZLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "AYVACIK",
        "STREET": "SÖĞÜTLÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "AYVACIK",
        "STREET": "SÜLEYMANKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "AYVACIK",
        "STREET": "TABAKLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "AYVACIK",
        "STREET": "TAMIŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "AYVACIK",
        "STREET": "TARTIŞIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "AYVACIK",
        "STREET": "TAŞAĞIL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "AYVACIK",
        "STREET": "TAŞBOĞAZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "AYVACIK",
        "STREET": "TUZLA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "AYVACIK",
        "STREET": "TUZTAŞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "AYVACIK",
        "STREET": "UZUNALAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "AYVACIK",
        "STREET": "YENİÇAM KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "AYVACIK",
        "STREET": "YEŞİLYURT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "AYVACIK",
        "STREET": "YUKARIKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "AYVACIK",
        "STREET": "ÇAKMAKLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "AYVACIK",
        "STREET": "ÇALTI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "AYVACIK",
        "STREET": "ÇAMKALABAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "AYVACIK",
        "STREET": "ÇAMKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "AYVACIK",
        "STREET": "ÇINARPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "AYVACIK",
        "STREET": "ÜMMÜHAN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "AYVACIK",
        "STREET": "İLYASFAKI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "AYVACIK",
        "STREET": "ŞAPKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "BAYRAMİÇ",
        "STREET": "AHMETÇELİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "BAYRAMİÇ",
        "STREET": "AKPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "BAYRAMİÇ",
        "STREET": "ALAKEÇİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "BAYRAMİÇ",
        "STREET": "ALİKABAKLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "BAYRAMİÇ",
        "STREET": "AĞAÇKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "BAYRAMİÇ",
        "STREET": "AŞAĞIŞAPCI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "BAYRAMİÇ",
        "STREET": "AŞAĞIŞEVİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "BAYRAMİÇ",
        "STREET": "BEKİRLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "BAYRAMİÇ",
        "STREET": "BEZİRGANLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "BAYRAMİÇ",
        "STREET": "BEŞİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "BAYRAMİÇ",
        "STREET": "BIYIKLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "BAYRAMİÇ",
        "STREET": "CAMİCEDİT MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "BAYRAMİÇ",
        "STREET": "CAMİKEBİR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "BAYRAMİÇ",
        "STREET": "CAZGİRLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "BAYRAMİÇ",
        "STREET": "DALOBA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "BAYRAMİÇ",
        "STREET": "DAĞAHMETÇE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "BAYRAMİÇ",
        "STREET": "DAĞOBA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "BAYRAMİÇ",
        "STREET": "DOĞANCI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "BAYRAMİÇ",
        "STREET": "EVCİLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "BAYRAMİÇ",
        "STREET": "GEDİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "BAYRAMİÇ",
        "STREET": "GÖKÇEİÇİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "BAYRAMİÇ",
        "STREET": "GÜZELTEPE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "BAYRAMİÇ",
        "STREET": "HACIBEKİRLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "BAYRAMİÇ",
        "STREET": "HACIDERVİŞLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "BAYRAMİÇ",
        "STREET": "HACIKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "BAYRAMİÇ",
        "STREET": "IŞIKELİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "BAYRAMİÇ",
        "STREET": "KARAKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "BAYRAMİÇ",
        "STREET": "KARAİBRAHİMLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "BAYRAMİÇ",
        "STREET": "KARINCALIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "BAYRAMİÇ",
        "STREET": "KAYKILAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "BAYRAMİÇ",
        "STREET": "KORUCAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "BAYRAMİÇ",
        "STREET": "KOŞUBURNUTÜRKMENLERİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "BAYRAMİÇ",
        "STREET": "KURŞUNLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "BAYRAMİÇ",
        "STREET": "KUTLUOBA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "BAYRAMİÇ",
        "STREET": "KUŞÇAYIR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "BAYRAMİÇ",
        "STREET": "KÖSELER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "BAYRAMİÇ",
        "STREET": "KÖYLÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "BAYRAMİÇ",
        "STREET": "KÜLCÜLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "BAYRAMİÇ",
        "STREET": "MENDERES MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "BAYRAMİÇ",
        "STREET": "MOLLAHASANLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "BAYRAMİÇ",
        "STREET": "MURATLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "BAYRAMİÇ",
        "STREET": "NEBİLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "BAYRAMİÇ",
        "STREET": "OSMANİYE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "BAYRAMİÇ",
        "STREET": "PALAMUTOBA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "BAYRAMİÇ",
        "STREET": "PINARBAŞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "BAYRAMİÇ",
        "STREET": "PITIRELİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "BAYRAMİÇ",
        "STREET": "SARAYCIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "BAYRAMİÇ",
        "STREET": "SARIDÜZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "BAYRAMİÇ",
        "STREET": "SARIOT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "BAYRAMİÇ",
        "STREET": "SAÇAKLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "BAYRAMİÇ",
        "STREET": "SERHAT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "BAYRAMİÇ",
        "STREET": "SÖĞÜTGEDİĞİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "BAYRAMİÇ",
        "STREET": "TEPECİK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "BAYRAMİÇ",
        "STREET": "TOLUKLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "BAYRAMİÇ",
        "STREET": "TONGURLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "BAYRAMİÇ",
        "STREET": "TÜLÜLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "BAYRAMİÇ",
        "STREET": "TÜRKMENLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "BAYRAMİÇ",
        "STREET": "YAHŞİELİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "BAYRAMİÇ",
        "STREET": "YANIKLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "BAYRAMİÇ",
        "STREET": "YASSIBAĞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "BAYRAMİÇ",
        "STREET": "YAYLACIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "BAYRAMİÇ",
        "STREET": "YENİCEKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "BAYRAMİÇ",
        "STREET": "YENİKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "BAYRAMİÇ",
        "STREET": "YEŞİLKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "BAYRAMİÇ",
        "STREET": "YUKARIŞAPÇI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "BAYRAMİÇ",
        "STREET": "YUKARIŞEVİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "BAYRAMİÇ",
        "STREET": "YİĞİTALİLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "BAYRAMİÇ",
        "STREET": "ZERDALİLİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "BAYRAMİÇ",
        "STREET": "ZEYTİNLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "BAYRAMİÇ",
        "STREET": "ÇALIDAĞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "BAYRAMİÇ",
        "STREET": "ÇALIOBAAKÇAKIL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "BAYRAMİÇ",
        "STREET": "ÇATALÇAM KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "BAYRAMİÇ",
        "STREET": "ÇAVUŞKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "BAYRAMİÇ",
        "STREET": "ÇAVUŞLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "BAYRAMİÇ",
        "STREET": "ÇIRPILAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "BAYRAMİÇ",
        "STREET": "ÇİFTLİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "BAYRAMİÇ",
        "STREET": "ÖRENLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "BAYRAMİÇ",
        "STREET": "ÜZÜMLÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "BAYRAMİÇ",
        "STREET": "ÜÇYOL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "BOZCAADA",
        "STREET": "ALAYBEY MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "BOZCAADA",
        "STREET": "CUMHURİYET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "BİGA",
        "STREET": "ABDİAĞA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "BİGA",
        "STREET": "ADLİYE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "BİGA",
        "STREET": "AHMETLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "BİGA",
        "STREET": "AKKAYRAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "BİGA",
        "STREET": "AKKÖPRÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "BİGA",
        "STREET": "AKPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "BİGA",
        "STREET": "AKSAZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "BİGA",
        "STREET": "AKYAPRAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "BİGA",
        "STREET": "AMBAROBA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "BİGA",
        "STREET": "ARABAALAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "BİGA",
        "STREET": "ARABAKONAĞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "BİGA",
        "STREET": "AYITDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "BİGA",
        "STREET": "AZİZİYE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "BİGA",
        "STREET": "AĞAKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "BİGA",
        "STREET": "AŞAĞIDEMİRCİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "BİGA",
        "STREET": "BAHÇELİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "BİGA",
        "STREET": "BAKACAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "BİGA",
        "STREET": "BAKACAKLIÇİFTLİĞİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "BİGA",
        "STREET": "BALIKLIÇEŞME KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "BİGA",
        "STREET": "BEKİRLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "BİGA",
        "STREET": "BEZİRGANLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "BİGA",
        "STREET": "BOZLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "BİGA",
        "STREET": "CAMİALAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "BİGA",
        "STREET": "CUMHURİYET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "BİGA",
        "STREET": "CİHADİYE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "BİGA",
        "STREET": "DANİŞMENT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "BİGA",
        "STREET": "DEREKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "BİGA",
        "STREET": "DEĞİRMENCİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "BİGA",
        "STREET": "DOĞANCI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "BİGA",
        "STREET": "DİKMEN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "BİGA",
        "STREET": "ELMALI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "BİGA",
        "STREET": "EMİRORMAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "BİGA",
        "STREET": "ESENTEPE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "BİGA",
        "STREET": "ESKİBALIKLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "BİGA",
        "STREET": "EYBEKLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "BİGA",
        "STREET": "EĞRİDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "BİGA",
        "STREET": "GAZİKEMAL MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "BİGA",
        "STREET": "GEMİCİKIRI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "BİGA",
        "STREET": "GEREDELLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "BİGA",
        "STREET": "GERLENGEÇ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "BİGA",
        "STREET": "GEYİKKIRI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "BİGA",
        "STREET": "GÖKTEPE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "BİGA",
        "STREET": "GÜLEÇ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "BİGA",
        "STREET": "GÜMÜŞÇAY-CUMHURİYET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "BİGA",
        "STREET": "GÜMÜŞÇAY-İĞDELİK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "BİGA",
        "STREET": "GÜNDOĞDU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "BİGA",
        "STREET": "GÜRGENDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "BİGA",
        "STREET": "GÜRÇEŞME KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "BİGA",
        "STREET": "GÜVEMALAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "BİGA",
        "STREET": "HACIHÜSEYİNYAYLASI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "BİGA",
        "STREET": "HACIKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "BİGA",
        "STREET": "HACIPEHLİVAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "BİGA",
        "STREET": "HAMDİBEY MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "BİGA",
        "STREET": "HARMANLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "BİGA",
        "STREET": "HAVDAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "BİGA",
        "STREET": "HOŞOBA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "BİGA",
        "STREET": "HİSARLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "BİGA",
        "STREET": "ILICABAŞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "BİGA",
        "STREET": "IŞIKELİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "BİGA",
        "STREET": "KAHVETEPE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "BİGA",
        "STREET": "KALAFAT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "BİGA",
        "STREET": "KALDIRIMBAŞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "BİGA",
        "STREET": "KANİBEY KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "BİGA",
        "STREET": "KAPANBELEN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "BİGA",
        "STREET": "KARAAĞAÇ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "BİGA",
        "STREET": "KARABİGA-ULUCAMİİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "BİGA",
        "STREET": "KARABİGA-YALI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "BİGA",
        "STREET": "KARABİGA-ZEYTİNLİK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "BİGA",
        "STREET": "KARACAALİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "BİGA",
        "STREET": "KARAHAMZALAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "BİGA",
        "STREET": "KARAPÜRÇEK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "BİGA",
        "STREET": "KATRANCI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "BİGA",
        "STREET": "KAYAPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "BİGA",
        "STREET": "KAYNARCA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "BİGA",
        "STREET": "KAZMALI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "BİGA",
        "STREET": "KAŞIKÇIOBA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "BİGA",
        "STREET": "KEMER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "BİGA",
        "STREET": "KEPEKLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "BİGA",
        "STREET": "KOCAGÜR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "BİGA",
        "STREET": "KORUOBA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "BİGA",
        "STREET": "KOZÇEŞME KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "BİGA",
        "STREET": "OSMANİYE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "BİGA",
        "STREET": "OTLUKDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "BİGA",
        "STREET": "OVACIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "BİGA",
        "STREET": "PAŞAÇAYI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "BİGA",
        "STREET": "PEKMEZLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "BİGA",
        "STREET": "RAMAZANLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "BİGA",
        "STREET": "SAKARYA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "BİGA",
        "STREET": "SARICA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "BİGA",
        "STREET": "SARIKAYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "BİGA",
        "STREET": "SARISIVAT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "BİGA",
        "STREET": "SARNIÇ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "BİGA",
        "STREET": "SAVAŞTEPE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "BİGA",
        "STREET": "SAZOBA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "BİGA",
        "STREET": "SELVİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "BİGA",
        "STREET": "SIĞIRCIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "BİGA",
        "STREET": "SİNEKÇİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "BİGA",
        "STREET": "TOKATKIRI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "BİGA",
        "STREET": "TURAN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "BİGA",
        "STREET": "TÜRKBAKACAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "BİGA",
        "STREET": "YANIÇ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "BİGA",
        "STREET": "YENİCEKÖY MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "BİGA",
        "STREET": "YENİMAHALLE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "BİGA",
        "STREET": "YENİÇİFTLİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "BİGA",
        "STREET": "YEŞİLKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "BİGA",
        "STREET": "YOLİNDİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "BİGA",
        "STREET": "YUKARIDEMİRCİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "BİGA",
        "STREET": "ÇAKIRLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "BİGA",
        "STREET": "ÇAVUŞKÖY MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "BİGA",
        "STREET": "ÇELTİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "BİGA",
        "STREET": "ÇELİKGÜRÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "BİGA",
        "STREET": "ÇEŞMEALTI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "BİGA",
        "STREET": "ÇINARDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "BİGA",
        "STREET": "ÇINARKÖPRÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "BİGA",
        "STREET": "ÇÖMLEKÇİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "BİGA",
        "STREET": "ÖRTÜLÜCE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "BİGA",
        "STREET": "İDRİSKORU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "BİGA",
        "STREET": "İLYASALAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "BİGA",
        "STREET": "İSKENDER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "BİGA",
        "STREET": "İSTİKLAL MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "BİGA",
        "STREET": "ŞAKİRBEY KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "BİGA",
        "STREET": "ŞİRİNKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "BİGA",
        "STREET": "ŞİRİNTEPE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "ECEABAT",
        "STREET": "ALÇITEPE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "ECEABAT",
        "STREET": "BEHRAMLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "ECEABAT",
        "STREET": "BEŞYOL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "ECEABAT",
        "STREET": "BÜYÜKANAFARTA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "ECEABAT",
        "STREET": "BİGALI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "ECEABAT",
        "STREET": "KEMALPAŞA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "ECEABAT",
        "STREET": "KOCADERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "ECEABAT",
        "STREET": "KUMKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "ECEABAT",
        "STREET": "KÜÇÜKANAFARTA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "ECEABAT",
        "STREET": "KİLİDÜLBAHİR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "ECEABAT",
        "STREET": "SEDDÜLBAHİR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "ECEABAT",
        "STREET": "YALOVA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "ECEABAT",
        "STREET": "YOLAĞZI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "ECEABAT",
        "STREET": "İSMETPAŞA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "EZİNE",
        "STREET": "AKKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "EZİNE",
        "STREET": "AKÇAKEÇİLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "EZİNE",
        "STREET": "ALADAĞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "EZİNE",
        "STREET": "ALEMŞAH KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "EZİNE",
        "STREET": "ARASANLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "EZİNE",
        "STREET": "BAHÇELİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "EZİNE",
        "STREET": "BALIKLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "EZİNE",
        "STREET": "BELEN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "EZİNE",
        "STREET": "BOZALAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "EZİNE",
        "STREET": "BOZELİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "EZİNE",
        "STREET": "BOZKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "EZİNE",
        "STREET": "CAMİKEBİR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "EZİNE",
        "STREET": "CUMHURİYET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "EZİNE",
        "STREET": "DALYAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "EZİNE",
        "STREET": "DANİŞMENT MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "EZİNE",
        "STREET": "DERBENTBAŞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "EZİNE",
        "STREET": "GAZİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "EZİNE",
        "STREET": "GEYİKLİ-CUMHURİYET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "EZİNE",
        "STREET": "GEYİKLİ-HÜRRİYET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "EZİNE",
        "STREET": "GÖKÇEBAYIR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "EZİNE",
        "STREET": "GÜLLÜCE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "EZİNE",
        "STREET": "HİSARALAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "EZİNE",
        "STREET": "KARADAĞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "EZİNE",
        "STREET": "KARAGÖMLEK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "EZİNE",
        "STREET": "KAYACIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "EZİNE",
        "STREET": "KEMALLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "EZİNE",
        "STREET": "KIZILKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "EZİNE",
        "STREET": "KIZILTEPE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "EZİNE",
        "STREET": "KUMBURUN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "EZİNE",
        "STREET": "KÖPRÜBAŞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "EZİNE",
        "STREET": "KÖRÜKTAŞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "EZİNE",
        "STREET": "KÖSELER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "EZİNE",
        "STREET": "MAHMUDİYE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "EZİNE",
        "STREET": "MECİDİYE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "EZİNE",
        "STREET": "PAZARKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "EZİNE",
        "STREET": "PINARBAŞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "EZİNE",
        "STREET": "SARISÖĞÜT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "EZİNE",
        "STREET": "SARPDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "EZİNE",
        "STREET": "SEFERŞAH MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "EZİNE",
        "STREET": "TAVAKLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "EZİNE",
        "STREET": "TAŞTEPE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "EZİNE",
        "STREET": "ULUKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "EZİNE",
        "STREET": "YAHYAÇAVUŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "EZİNE",
        "STREET": "YAVAŞLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "EZİNE",
        "STREET": "YAYLACIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "EZİNE",
        "STREET": "YENİKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "EZİNE",
        "STREET": "YENİOBA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "EZİNE",
        "STREET": "ÇAMKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "EZİNE",
        "STREET": "ÇAMLICA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "EZİNE",
        "STREET": "ÇAMOBA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "EZİNE",
        "STREET": "ÇARIKSIZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "EZİNE",
        "STREET": "ÇETMİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "EZİNE",
        "STREET": "ÇINARKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "EZİNE",
        "STREET": "ÜSKÜFÇÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "EZİNE",
        "STREET": "ÜVECİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "EZİNE",
        "STREET": "ŞAPKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "GELİBOLU",
        "STREET": "ADİLHAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "GELİBOLU",
        "STREET": "ALAEDDİN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "GELİBOLU",
        "STREET": "BAYIRKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "GELİBOLU",
        "STREET": "BAYRAMİÇ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "GELİBOLU",
        "STREET": "BOLAYIR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "GELİBOLU",
        "STREET": "BURHANLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "GELİBOLU",
        "STREET": "CAMİİKEBİR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "GELİBOLU",
        "STREET": "CEVİZLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "GELİBOLU",
        "STREET": "CUMALI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "GELİBOLU",
        "STREET": "DEMİRTEPE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "GELİBOLU",
        "STREET": "DEĞİRMENDÜZÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "GELİBOLU",
        "STREET": "EVREŞE-KEŞANOĞLU MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "GELİBOLU",
        "STREET": "EVREŞE-YENİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "GELİBOLU",
        "STREET": "EVREŞE-ÇEŞME MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "GELİBOLU",
        "STREET": "FINDIKLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "GELİBOLU",
        "STREET": "GAZİ SÜLEYMAN PAŞA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "GELİBOLU",
        "STREET": "GÜNEYLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "GELİBOLU",
        "STREET": "HOCA HAMZA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "GELİBOLU",
        "STREET": "ILGARDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "GELİBOLU",
        "STREET": "KALEALTI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "GELİBOLU",
        "STREET": "KARAİNEBEYLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "GELİBOLU",
        "STREET": "KAVAKKÖY-CUMHURİYET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "GELİBOLU",
        "STREET": "KAVAKKÖY-HÜRRİYET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "GELİBOLU",
        "STREET": "KAVAKLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "GELİBOLU",
        "STREET": "KOCAÇEŞME KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "GELİBOLU",
        "STREET": "KORUKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "GELİBOLU",
        "STREET": "OCAKLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "GELİBOLU",
        "STREET": "PAZARLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "GELİBOLU",
        "STREET": "SÜLEYMANİYE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "GELİBOLU",
        "STREET": "SÜTLÜCE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "GELİBOLU",
        "STREET": "TAYFURKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "GELİBOLU",
        "STREET": "YAZICIZADE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "GELİBOLU",
        "STREET": "YENİKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "GELİBOLU",
        "STREET": "YÜLÜCE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "GELİBOLU",
        "STREET": "ÇOKAL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "GELİBOLU",
        "STREET": "ŞADILLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "GÖKÇEADA",
        "STREET": "BADEMLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "GÖKÇEADA",
        "STREET": "CUMHURİYET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "GÖKÇEADA",
        "STREET": "DEREKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "GÖKÇEADA",
        "STREET": "EŞELEK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "GÖKÇEADA",
        "STREET": "FATİH MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "GÖKÇEADA",
        "STREET": "KALEKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "GÖKÇEADA",
        "STREET": "KUZULİMANI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "GÖKÇEADA",
        "STREET": "TEPEKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "GÖKÇEADA",
        "STREET": "UĞURLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "GÖKÇEADA",
        "STREET": "YENİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "GÖKÇEADA",
        "STREET": "YENİBADEMLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "GÖKÇEADA",
        "STREET": "ZEYTİNLİKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "GÖKÇEADA",
        "STREET": "ÇINARLI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "GÖKÇEADA",
        "STREET": "ŞİRİNKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "LAPSEKİ",
        "STREET": "ADATEPE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "LAPSEKİ",
        "STREET": "AKÇAALAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "LAPSEKİ",
        "STREET": "ALPAGUT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "LAPSEKİ",
        "STREET": "BALCILAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "LAPSEKİ",
        "STREET": "BEYBAŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "LAPSEKİ",
        "STREET": "BEYPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "LAPSEKİ",
        "STREET": "BEYÇAYIRI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "LAPSEKİ",
        "STREET": "CUMHURİYET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "LAPSEKİ",
        "STREET": "DALYAN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "LAPSEKİ",
        "STREET": "DEREKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "LAPSEKİ",
        "STREET": "DOĞANDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "LAPSEKİ",
        "STREET": "DUMANLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "LAPSEKİ",
        "STREET": "DİŞBUDAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "LAPSEKİ",
        "STREET": "EÇİALAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "LAPSEKİ",
        "STREET": "GAZİ SÜLEYMAN PAŞA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "LAPSEKİ",
        "STREET": "GÖKKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "LAPSEKİ",
        "STREET": "GÜRECİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "LAPSEKİ",
        "STREET": "HACIGELEN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "LAPSEKİ",
        "STREET": "HACIÖMERLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "LAPSEKİ",
        "STREET": "HARMANCIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "LAPSEKİ",
        "STREET": "KANGIRLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "LAPSEKİ",
        "STREET": "KARAMUSALAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "LAPSEKİ",
        "STREET": "KARAÖMERLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "LAPSEKİ",
        "STREET": "KEMİKLİALAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "LAPSEKİ",
        "STREET": "KIRCALAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "LAPSEKİ",
        "STREET": "KIZILDAM KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "LAPSEKİ",
        "STREET": "KOCABAŞLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "LAPSEKİ",
        "STREET": "KOCAVELİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "LAPSEKİ",
        "STREET": "MECİDİYE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "LAPSEKİ",
        "STREET": "NUSRATİYE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "LAPSEKİ",
        "STREET": "SINDAL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "LAPSEKİ",
        "STREET": "SUBAŞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "LAPSEKİ",
        "STREET": "SULUCA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "LAPSEKİ",
        "STREET": "TAŞTEPE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "LAPSEKİ",
        "STREET": "UMURBEY-UMURBEY MERKEZ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "LAPSEKİ",
        "STREET": "YAYLALAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "LAPSEKİ",
        "STREET": "YENİCEKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "LAPSEKİ",
        "STREET": "ÇAMYURT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "LAPSEKİ",
        "STREET": "ÇARDAK-GAZİYAKUPBEY MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "LAPSEKİ",
        "STREET": "ÇARDAK-TEKKE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "LAPSEKİ",
        "STREET": "ÇATALTEPE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "LAPSEKİ",
        "STREET": "ÇAVUŞKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "LAPSEKİ",
        "STREET": "ÜÇPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "LAPSEKİ",
        "STREET": "İLYASKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "LAPSEKİ",
        "STREET": "ŞAHİNLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "LAPSEKİ",
        "STREET": "ŞEVKETİYE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "YENİCE",
        "STREET": "AHİLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "YENİCE",
        "STREET": "AKKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "YENİCE",
        "STREET": "AKÇAKOYUN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "YENİCE",
        "STREET": "ALANCIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "YENİCE",
        "STREET": "ARAOVACIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "YENİCE",
        "STREET": "ARMUTÇUK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "YENİCE",
        "STREET": "AŞAĞIKARAAŞIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "YENİCE",
        "STREET": "AŞAĞIÇAVUŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "YENİCE",
        "STREET": "AŞAĞIİNOVA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "YENİCE",
        "STREET": "BALLIÇAY KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "YENİCE",
        "STREET": "BAYATLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "YENİCE",
        "STREET": "BAĞLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "YENİCE",
        "STREET": "BAŞKOZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "YENİCE",
        "STREET": "BEKTEN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "YENİCE",
        "STREET": "BOYNANLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "YENİCE",
        "STREET": "CANBAZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "YENİCE",
        "STREET": "DARIALAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "YENİCE",
        "STREET": "DAVUTKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "YENİCE",
        "STREET": "ENGECİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "YENİCE",
        "STREET": "ESKİ YENİCE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "YENİCE",
        "STREET": "GÜMÜŞLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "YENİCE",
        "STREET": "GÜNDOĞDU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "YENİCE",
        "STREET": "GÜZELOBA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "YENİCE",
        "STREET": "HACILAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "YENİCE",
        "STREET": "HACIYUSUFLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "YENİCE",
        "STREET": "HAMDİBEY KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "YENİCE",
        "STREET": "HAYDAROBA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "YENİCE",
        "STREET": "HIDIRLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "YENİCE",
        "STREET": "KABALI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "YENİCE",
        "STREET": "KALABAKBAŞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "YENİCE",
        "STREET": "KALKIM-ATATÜRK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "YENİCE",
        "STREET": "KALKIM-CUMHURİYET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "YENİCE",
        "STREET": "KARAAYDIN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "YENİCE",
        "STREET": "KARABEY KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "YENİCE",
        "STREET": "KARADORU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "YENİCE",
        "STREET": "KARAKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "YENİCE",
        "STREET": "KARASU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "YENİCE",
        "STREET": "KARGACI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "YENİCE",
        "STREET": "KIRIKLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "YENİCE",
        "STREET": "KIZILDAM KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "YENİCE",
        "STREET": "KIŞLALAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "YENİCE",
        "STREET": "KORUKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "YENİCE",
        "STREET": "KOVANCI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "YENİCE",
        "STREET": "KURTLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "YENİCE",
        "STREET": "KURTULUŞ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "YENİCE",
        "STREET": "KUZUPINARI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "YENİCE",
        "STREET": "NAMAZGAH KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "YENİCE",
        "STREET": "NEVRUZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "YENİCE",
        "STREET": "OĞLANALANI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "YENİCE",
        "STREET": "PAZARKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "YENİCE",
        "STREET": "REŞADİYE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "YENİCE",
        "STREET": "SAMETELİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "YENİCE",
        "STREET": "SARIÇAYIR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "YENİCE",
        "STREET": "SAZAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "YENİCE",
        "STREET": "SEYVAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "YENİCE",
        "STREET": "SOFULAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "YENİCE",
        "STREET": "SOĞUCAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "YENİCE",
        "STREET": "SUUÇTU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "YENİCE",
        "STREET": "TABAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "YENİCE",
        "STREET": "TORHASAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "YENİCE",
        "STREET": "UMURLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "YENİCE",
        "STREET": "YALIOBA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "YENİCE",
        "STREET": "YARIŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "YENİCE",
        "STREET": "YAĞDIRAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "YENİCE",
        "STREET": "YENİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "YENİCE",
        "STREET": "YENİKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "YENİCE",
        "STREET": "YEŞİLKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "YENİCE",
        "STREET": "YUKARIKARAAŞIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "YENİCE",
        "STREET": "YUKARIÇAVUŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "YENİCE",
        "STREET": "YUKARIİNOVA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "YENİCE",
        "STREET": "ÇAKIR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "YENİCE",
        "STREET": "ÇAKIROBA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "YENİCE",
        "STREET": "ÇAL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "YENİCE",
        "STREET": "ÇAMOBA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "YENİCE",
        "STREET": "ÇINARCIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "YENİCE",
        "STREET": "ÇINARKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "YENİCE",
        "STREET": "ÇIRPILAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "YENİCE",
        "STREET": "ÇUKUROBA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "YENİCE",
        "STREET": "ÇİFTLİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "YENİCE",
        "STREET": "ÖRENCİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "YENİCE",
        "STREET": "ÖĞMEN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "YENİCE",
        "STREET": "ÜÇKABAAĞAÇ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "ÇAN",
        "STREET": "AHLATLIBURUN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "ÇAN",
        "STREET": "ALTIKULAÇ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "ÇAN",
        "STREET": "ALİBEYÇİFTLİĞİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "ÇAN",
        "STREET": "ASMALI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "ÇAN",
        "STREET": "ATATÜRK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "ÇAN",
        "STREET": "BAHADIRLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "ÇAN",
        "STREET": "BARDAKÇILAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "ÇAN",
        "STREET": "BOSTANDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "ÇAN",
        "STREET": "BOZGUÇ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "ÇAN",
        "STREET": "BÜYÜKPAŞA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "ÇAN",
        "STREET": "BÜYÜKTEPE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "ÇAN",
        "STREET": "BİLALLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "ÇAN",
        "STREET": "CUMHURİYET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "ÇAN",
        "STREET": "CİCİKLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "ÇAN",
        "STREET": "DANAPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "ÇAN",
        "STREET": "DERENTİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "ÇAN",
        "STREET": "DEREOBA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "ÇAN",
        "STREET": "DONDURMA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "ÇAN",
        "STREET": "DOĞACA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "ÇAN",
        "STREET": "DOĞANCILAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "ÇAN",
        "STREET": "DUMAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "ÇAN",
        "STREET": "DURALİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "ÇAN",
        "STREET": "EMEŞE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "ÇAN",
        "STREET": "ESKİYAYLA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "ÇAN",
        "STREET": "ETİLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "ÇAN",
        "STREET": "FATİH MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "ÇAN",
        "STREET": "GÖLE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "ÇAN",
        "STREET": "HACIKASIM KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "ÇAN",
        "STREET": "HACILAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "ÇAN",
        "STREET": "HALİLAĞA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "ÇAN",
        "STREET": "HELVACI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "ÇAN",
        "STREET": "HURMA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "ÇAN",
        "STREET": "KADILAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "ÇAN",
        "STREET": "KALBURCU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "ÇAN",
        "STREET": "KARADAĞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "ÇAN",
        "STREET": "KARAKADILAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "ÇAN",
        "STREET": "KARAKOCA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "ÇAN",
        "STREET": "KARLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "ÇAN",
        "STREET": "KARŞIYAKA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "ÇAN",
        "STREET": "KEÇİAĞILI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "ÇAN",
        "STREET": "KIZILELMA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "ÇAN",
        "STREET": "KOCAYAYLA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "ÇAN",
        "STREET": "KOYUNYERİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "ÇAN",
        "STREET": "KULFAL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "ÇAN",
        "STREET": "KUMARLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "ÇAN",
        "STREET": "KÜÇÜKLÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "ÇAN",
        "STREET": "KÜÇÜKPAŞA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "ÇAN",
        "STREET": "MALLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "ÇAN",
        "STREET": "MALTEPE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "ÇAN",
        "STREET": "OKÇULAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "ÇAN",
        "STREET": "OSMANEFENDİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "ÇAN",
        "STREET": "OZANCIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "ÇAN",
        "STREET": "SAMETELİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "ÇAN",
        "STREET": "SERAMİK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "ÇAN",
        "STREET": "SÖĞÜTALAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "ÇAN",
        "STREET": "TEPEKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "ÇAN",
        "STREET": "TERZİALAN-SÜLE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "ÇAN",
        "STREET": "TERZİALAN-ÇAKMAKÇAYIR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "ÇAN",
        "STREET": "UZUNALAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "ÇAN",
        "STREET": "YAYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "ÇAN",
        "STREET": "YAYKIN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "ÇAN",
        "STREET": "YENİÇERİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "ÇAN",
        "STREET": "YUVALAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "ÇAN",
        "STREET": "ZEYBEKÇAYIR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "ÇAN",
        "STREET": "ÇAKILKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "ÇAN",
        "STREET": "ÇALTIKARA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "ÇAN",
        "STREET": "ÇAMKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "ÇAN",
        "STREET": "ÇEKİÇLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "ÇAN",
        "STREET": "ÇOMAKLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "ÇAN",
        "STREET": "ÜVEZDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "ÇAN",
        "STREET": "İLYASAĞAÇİFTLİĞİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "ÇAN",
        "STREET": "İSTİKLAL MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "ÇAN",
        "STREET": "ŞERBETLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "MERKEZ",
        "STREET": "AKÇALI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "MERKEZ",
        "STREET": "AKÇAPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "MERKEZ",
        "STREET": "AKÇEŞME KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "MERKEZ",
        "STREET": "ALANKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "MERKEZ",
        "STREET": "AŞAĞIOKÇULAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "MERKEZ",
        "STREET": "BARBAROS MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "MERKEZ",
        "STREET": "BELEN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "MERKEZ",
        "STREET": "BODURLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "MERKEZ",
        "STREET": "CEVAT PAŞA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "MERKEZ",
        "STREET": "CİVLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "MERKEZ",
        "STREET": "DEDELER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "MERKEZ",
        "STREET": "DENİZGÖRÜNDÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "MERKEZ",
        "STREET": "DÜMREK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "MERKEZ",
        "STREET": "ELMACIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "MERKEZ",
        "STREET": "ERENKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "MERKEZ",
        "STREET": "ESENLER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "MERKEZ",
        "STREET": "FEVZİPAŞA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "MERKEZ",
        "STREET": "GÖKÇALI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "MERKEZ",
        "STREET": "GÜZELYALI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "MERKEZ",
        "STREET": "HALİLELİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "MERKEZ",
        "STREET": "HALİLOĞLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "MERKEZ",
        "STREET": "IŞIKLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "MERKEZ",
        "STREET": "KALABAKLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "MERKEZ",
        "STREET": "KALAFAT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "MERKEZ",
        "STREET": "KARACALAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "MERKEZ",
        "STREET": "KARACAÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "MERKEZ",
        "STREET": "KARAPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "MERKEZ",
        "STREET": "KAYADERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "MERKEZ",
        "STREET": "KEMALPAŞA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "MERKEZ",
        "STREET": "KEMEL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "MERKEZ",
        "STREET": "KEPEZ-BOĞAZKENT MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "MERKEZ",
        "STREET": "KEPEZ-CUMHURİYET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "MERKEZ",
        "STREET": "KEPEZ-HAMİDİYE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "MERKEZ",
        "STREET": "KIZILCAÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "MERKEZ",
        "STREET": "KIZILKEÇİLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "MERKEZ",
        "STREET": "KOCALAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "MERKEZ",
        "STREET": "KUMKALE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "MERKEZ",
        "STREET": "KURŞUNLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "MERKEZ",
        "STREET": "KİRAZLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "MERKEZ",
        "STREET": "MARAŞALFEVZİÇAKMAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "MERKEZ",
        "STREET": "MUSAKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "MERKEZ",
        "STREET": "NAMIK KEMAL MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "MERKEZ",
        "STREET": "ORTACA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "MERKEZ",
        "STREET": "OVACIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "MERKEZ",
        "STREET": "SALİHLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "MERKEZ",
        "STREET": "SARAYCIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "MERKEZ",
        "STREET": "SARIBEYLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "MERKEZ",
        "STREET": "SARICAELİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "MERKEZ",
        "STREET": "SERÇİLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "MERKEZ",
        "STREET": "TAŞLITARLA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "MERKEZ",
        "STREET": "TERZİLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "MERKEZ",
        "STREET": "TEVFİKİYE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "MERKEZ",
        "STREET": "ULUPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "MERKEZ",
        "STREET": "YAPILDAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "MERKEZ",
        "STREET": "YAĞCILAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "MERKEZ",
        "STREET": "YUKARIOKÇULAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇAMYAYLA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇANAKKALE E TİPİ KAPALI CEZA EVİ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇINARLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇIPLAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇİFTLİKDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "MERKEZ",
        "STREET": "ÖZBEK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANAKKALE",
        "DISTRICT": "MERKEZ",
        "STREET": "İSMETPAŞA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "ATKARACALAR",
        "STREET": "BUDAKPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "ATKARACALAR",
        "STREET": "DEMİRLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "ATKARACALAR",
        "STREET": "EYÜPÖZÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "ATKARACALAR",
        "STREET": "GAZİBEY MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "ATKARACALAR",
        "STREET": "HOCA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "ATKARACALAR",
        "STREET": "HÜYÜKKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "ATKARACALAR",
        "STREET": "ILIPINAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "ATKARACALAR",
        "STREET": "KIRAN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "ATKARACALAR",
        "STREET": "KIZILİBRİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "ATKARACALAR",
        "STREET": "KÜKÜRT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "ATKARACALAR",
        "STREET": "MOLLAOSMAN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "ATKARACALAR",
        "STREET": "SUSUZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "ATKARACALAR",
        "STREET": "YAKALI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "ATKARACALAR",
        "STREET": "ÇARDAKLI-BOZKUŞ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "ATKARACALAR",
        "STREET": "ÇARDAKLI-CUMHURİYET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "ATKARACALAR",
        "STREET": "ÇARDAKLI-HÜRRİYET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "ATKARACALAR",
        "STREET": "ÇARDAKLI-MUSTAFA KEMAL MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "ATKARACALAR",
        "STREET": "ÇARDAKLI-NEVZAT AYAZ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "ATKARACALAR",
        "STREET": "İLKER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "BAYRAMÖREN",
        "STREET": "AKGÜNEY KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "BAYRAMÖREN",
        "STREET": "AKSEKİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "BAYRAMÖREN",
        "STREET": "BELENLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "BAYRAMÖREN",
        "STREET": "BOĞAZKAYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "BAYRAMÖREN",
        "STREET": "DALKOZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "BAYRAMÖREN",
        "STREET": "DEREKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "BAYRAMÖREN",
        "STREET": "DOLAŞLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "BAYRAMÖREN",
        "STREET": "ERENLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "BAYRAMÖREN",
        "STREET": "FERİZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "BAYRAMÖREN",
        "STREET": "GÖYNÜKÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "BAYRAMÖREN",
        "STREET": "HARMANCIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "BAYRAMÖREN",
        "STREET": "KARAKUZU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "BAYRAMÖREN",
        "STREET": "KARATAŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "BAYRAMÖREN",
        "STREET": "KAVAKKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "BAYRAMÖREN",
        "STREET": "KOÇLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "BAYRAMÖREN",
        "STREET": "KÖPRÜLÜBEY MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "BAYRAMÖREN",
        "STREET": "MERKEZ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "BAYRAMÖREN",
        "STREET": "OLUKLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "BAYRAMÖREN",
        "STREET": "OYMAAĞAÇ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "BAYRAMÖREN",
        "STREET": "SARIKAYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "BAYRAMÖREN",
        "STREET": "TOPÇU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "BAYRAMÖREN",
        "STREET": "YAYLATEPESİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "BAYRAMÖREN",
        "STREET": "YAZIÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "BAYRAMÖREN",
        "STREET": "YURTPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "BAYRAMÖREN",
        "STREET": "YUSUFOĞLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "BAYRAMÖREN",
        "STREET": "ZÜBEYDE HANIM MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "BAYRAMÖREN",
        "STREET": "ÇAKIRBAĞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "BAYRAMÖREN",
        "STREET": "ÇAYIRCIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "BAYRAMÖREN",
        "STREET": "ÜÇGAZİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "BAYRAMÖREN",
        "STREET": "İNCEKAYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "ELDİVAN",
        "STREET": "AKBULUT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "ELDİVAN",
        "STREET": "AKÇALI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "ELDİVAN",
        "STREET": "ALVA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "ELDİVAN",
        "STREET": "BAHÇELİEVLER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "ELDİVAN",
        "STREET": "BEYDERE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "ELDİVAN",
        "STREET": "BÜYÜKHACIBEY KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "ELDİVAN",
        "STREET": "CAMİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "ELDİVAN",
        "STREET": "DERE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "ELDİVAN",
        "STREET": "ELMACI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "ELDİVAN",
        "STREET": "GÖLEZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "ELDİVAN",
        "STREET": "GÖLEZKAYI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "ELDİVAN",
        "STREET": "HİSARCIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "ELDİVAN",
        "STREET": "HİSARCIKKAYI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "ELDİVAN",
        "STREET": "KÜÇÜKHACIBEY KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "ELDİVAN",
        "STREET": "SARAYKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "ELDİVAN",
        "STREET": "SARITARLA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "ELDİVAN",
        "STREET": "SEYDİKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "ELDİVAN",
        "STREET": "TOPRAKLIK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "ELDİVAN",
        "STREET": "YUKARIYANLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "ELDİVAN",
        "STREET": "ÇAY MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "ELDİVAN",
        "STREET": "ÇUKURÖZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "ELDİVAN",
        "STREET": "ÇİFTLİKKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "ILGAZ",
        "STREET": "AKCAÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "ILGAZ",
        "STREET": "AKTAŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "ILGAZ",
        "STREET": "ALIÇ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "ILGAZ",
        "STREET": "ALPAGUT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "ILGAZ",
        "STREET": "ALİBEY KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "ILGAZ",
        "STREET": "ARPAYERİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "ILGAZ",
        "STREET": "AŞAĞIBOZAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "ILGAZ",
        "STREET": "AŞAĞIDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "ILGAZ",
        "STREET": "AŞAĞIMEYDAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "ILGAZ",
        "STREET": "AŞIKLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "ILGAZ",
        "STREET": "BALCI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "ILGAZ",
        "STREET": "BAŞDİBEK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "ILGAZ",
        "STREET": "BELSÖĞÜT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "ILGAZ",
        "STREET": "BELÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "ILGAZ",
        "STREET": "BELÖREN YENİCE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "ILGAZ",
        "STREET": "BEYKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "ILGAZ",
        "STREET": "BOZATLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "ILGAZ",
        "STREET": "BÜKCÜK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "ILGAZ",
        "STREET": "CANDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "ILGAZ",
        "STREET": "CÖMERT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "ILGAZ",
        "STREET": "DANİŞMENT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "ILGAZ",
        "STREET": "EKSİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "ILGAZ",
        "STREET": "ERİCEK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "ILGAZ",
        "STREET": "ESKİCE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "ILGAZ",
        "STREET": "FATİH MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "ILGAZ",
        "STREET": "GAZİ DEDE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "ILGAZ",
        "STREET": "GAZİLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "ILGAZ",
        "STREET": "GÖKCEYAZI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "ILGAZ",
        "STREET": "GÜNEYKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "ILGAZ",
        "STREET": "HACIHASAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "ILGAZ",
        "STREET": "ILISILIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "ILGAZ",
        "STREET": "KALEKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "ILGAZ",
        "STREET": "KARATAŞ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "ILGAZ",
        "STREET": "KAVAKLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "ILGAZ",
        "STREET": "KAYI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "ILGAZ",
        "STREET": "KAZANCI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "ILGAZ",
        "STREET": "KESEKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "ILGAZ",
        "STREET": "KIRIŞLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "ILGAZ",
        "STREET": "KIYISIN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "ILGAZ",
        "STREET": "KIZILIBRIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "ILGAZ",
        "STREET": "KURMALAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "ILGAZ",
        "STREET": "KUYUPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "ILGAZ",
        "STREET": "KUŞÇAYIRI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "ILGAZ",
        "STREET": "MESUTÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "ILGAZ",
        "STREET": "MUSAKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "ILGAZ",
        "STREET": "MÜLAYIM KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "ILGAZ",
        "STREET": "MÜLAYİM YENİCE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "ILGAZ",
        "STREET": "OKCULAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "ILGAZ",
        "STREET": "ONAÇ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "ILGAZ",
        "STREET": "SARAYCIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "ILGAZ",
        "STREET": "SARMAŞIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "ILGAZ",
        "STREET": "SATILAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "ILGAZ",
        "STREET": "SAZAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "ILGAZ",
        "STREET": "SAĞIRLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "ILGAZ",
        "STREET": "SEKİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "ILGAZ",
        "STREET": "SERÇELER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "ILGAZ",
        "STREET": "SÖĞÜTCÜK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "ILGAZ",
        "STREET": "SÜLEYMANHACILAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "ILGAZ",
        "STREET": "ULUPINAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "ILGAZ",
        "STREET": "YALAYCIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "ILGAZ",
        "STREET": "YAVUZ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "ILGAZ",
        "STREET": "YAYLAÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "ILGAZ",
        "STREET": "YAZIKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "ILGAZ",
        "STREET": "YENİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "ILGAZ",
        "STREET": "YENİDEMİRCİLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "ILGAZ",
        "STREET": "YERKUYU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "ILGAZ",
        "STREET": "YEŞİLDUMLUPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "ILGAZ",
        "STREET": "YUKARI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "ILGAZ",
        "STREET": "YUKARIBOZAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "ILGAZ",
        "STREET": "YUKARIDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "ILGAZ",
        "STREET": "YUKARIMEYDAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "ILGAZ",
        "STREET": "YUVADEMİRCİLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "ILGAZ",
        "STREET": "YUVASARAY KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "ILGAZ",
        "STREET": "ZİRAAT MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "ILGAZ",
        "STREET": "ÇALTIPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "ILGAZ",
        "STREET": "ÇATAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "ILGAZ",
        "STREET": "ÇELTİKBAŞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "ILGAZ",
        "STREET": "ÇÖREKÇİLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "ILGAZ",
        "STREET": "ÖDEMİŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "ILGAZ",
        "STREET": "ÖMERLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "ILGAZ",
        "STREET": "İKİKAVAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "ILGAZ",
        "STREET": "İNKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "ILGAZ",
        "STREET": "ŞENTEPE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "ILGAZ",
        "STREET": "ŞEYHYUNUS KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "KIZILIRMAK",
        "STREET": "AŞAĞIALEGÖZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "KIZILIRMAK",
        "STREET": "AŞAĞIOVACIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "KIZILIRMAK",
        "STREET": "BAYANPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "KIZILIRMAK",
        "STREET": "BOSTANLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "KIZILIRMAK",
        "STREET": "BOYACIOĞLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "KIZILIRMAK",
        "STREET": "BÜYÜKBAHÇELİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "KIZILIRMAK",
        "STREET": "CACIKLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "KIZILIRMAK",
        "STREET": "GÜNEYKIŞLA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "KIZILIRMAK",
        "STREET": "HACILAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "KIZILIRMAK",
        "STREET": "HALLAÇLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "KIZILIRMAK",
        "STREET": "HÜSEYİNLİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "KIZILIRMAK",
        "STREET": "KAHYALI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "KIZILIRMAK",
        "STREET": "KAPAKLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "KIZILIRMAK",
        "STREET": "KARADİBEK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "KIZILIRMAK",
        "STREET": "KARALLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "KIZILIRMAK",
        "STREET": "KARAMÜRSEL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "KIZILIRMAK",
        "STREET": "KARAÖMER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "KIZILIRMAK",
        "STREET": "KAVLAKLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "KIZILIRMAK",
        "STREET": "KEMALLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "KIZILIRMAK",
        "STREET": "KORÇULLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "KIZILIRMAK",
        "STREET": "KUZEYKIŞLA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "KIZILIRMAK",
        "STREET": "OSMANLI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "KIZILIRMAK",
        "STREET": "SAKARCA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "KIZILIRMAK",
        "STREET": "SARAYCIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "KIZILIRMAK",
        "STREET": "TEPEALEGÖZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "KIZILIRMAK",
        "STREET": "TIMARLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "KIZILIRMAK",
        "STREET": "YENİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "KIZILIRMAK",
        "STREET": "YENİYAPAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "KIZILIRMAK",
        "STREET": "YUKARIALEGÖZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "KORGUN",
        "STREET": "ALPSARI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "KORGUN",
        "STREET": "BUĞAY KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "KORGUN",
        "STREET": "BÜYÜK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "KORGUN",
        "STREET": "DOĞU MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "KORGUN",
        "STREET": "DİKENLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "KORGUN",
        "STREET": "HICIP KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "KORGUN",
        "STREET": "ILDIZIM KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "KORGUN",
        "STREET": "KARATEKİN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "KORGUN",
        "STREET": "KAYIÇİVİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "KORGUN",
        "STREET": "KESECİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "KORGUN",
        "STREET": "MARUF KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "KORGUN",
        "STREET": "ULUDAĞ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "KORGUN",
        "STREET": "ÇUKURÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "KORGUN",
        "STREET": "İKİÇAM KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "KORGUN",
        "STREET": "ŞIHLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "KURŞUNLU",
        "STREET": "AĞILÖZÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "KURŞUNLU",
        "STREET": "BAŞOVACIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "KURŞUNLU",
        "STREET": "BEREKET KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "KURŞUNLU",
        "STREET": "BEŞPINAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "KURŞUNLU",
        "STREET": "CÖMERTLER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "KURŞUNLU",
        "STREET": "DAĞÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "KURŞUNLU",
        "STREET": "DEMİRCİÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "KURŞUNLU",
        "STREET": "DUMANLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "KURŞUNLU",
        "STREET": "ERENLER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "KURŞUNLU",
        "STREET": "ESKİAHIR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "KURŞUNLU",
        "STREET": "GÖLLÜCE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "KURŞUNLU",
        "STREET": "HACI BEKİR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "KURŞUNLU",
        "STREET": "HACIMUSLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "KURŞUNLU",
        "STREET": "HOCAHASAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "KURŞUNLU",
        "STREET": "KALE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "KURŞUNLU",
        "STREET": "KALEKAPU MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "KURŞUNLU",
        "STREET": "KAPAKLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "KURŞUNLU",
        "STREET": "KIZILCA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "KURŞUNLU",
        "STREET": "KÖPÜRLÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "KURŞUNLU",
        "STREET": "MADENLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "KURŞUNLU",
        "STREET": "MÜSLÜM MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "KURŞUNLU",
        "STREET": "SARIALAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "KURŞUNLU",
        "STREET": "SUMUCAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "KURŞUNLU",
        "STREET": "SÜNÜRLÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "KURŞUNLU",
        "STREET": "SİVRİCEK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "KURŞUNLU",
        "STREET": "TAŞKARACALAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "KURŞUNLU",
        "STREET": "YENİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "KURŞUNLU",
        "STREET": "YEŞİL MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "KURŞUNLU",
        "STREET": "YEŞİLÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "KURŞUNLU",
        "STREET": "YEŞİLÖZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "KURŞUNLU",
        "STREET": "ÇAL MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "KURŞUNLU",
        "STREET": "ÇATKESE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "KURŞUNLU",
        "STREET": "ÇAVUNDUR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "KURŞUNLU",
        "STREET": "ÇAYLICA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "KURŞUNLU",
        "STREET": "ÇIRDAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "KURŞUNLU",
        "STREET": "ÇUKURCA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "KURŞUNLU",
        "STREET": "ÇİYNİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "KURŞUNLU",
        "STREET": "İĞDİR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "ORTA",
        "STREET": "BUĞURÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "ORTA",
        "STREET": "BÜĞDÜZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "ORTA",
        "STREET": "CEM SULTAN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "ORTA",
        "STREET": "DEREBAYINDIR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "ORTA",
        "STREET": "DODURGA-HAMAMÖNÜ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "ORTA",
        "STREET": "DODURGA-KANARYA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "ORTA",
        "STREET": "DODURGA-NALTEPE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "ORTA",
        "STREET": "DOĞANLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "ORTA",
        "STREET": "ELDEN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "ORTA",
        "STREET": "ELMALIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "ORTA",
        "STREET": "ESKİ YAYLA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "ORTA",
        "STREET": "GÖKÇEÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "ORTA",
        "STREET": "HASANHACI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "ORTA",
        "STREET": "HÜYÜKKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "ORTA",
        "STREET": "KALFAT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "ORTA",
        "STREET": "KANLICA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "ORTA",
        "STREET": "KARAAĞAÇ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "ORTA",
        "STREET": "KAYIÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "ORTA",
        "STREET": "KIRSAKAL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "ORTA",
        "STREET": "KISAÇ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "ORTA",
        "STREET": "ORTABAYINDIR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "ORTA",
        "STREET": "SAKAELİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "ORTA",
        "STREET": "SAKARCAÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "ORTA",
        "STREET": "SALUR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "ORTA",
        "STREET": "SANCAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "ORTA",
        "STREET": "SULTAN BEYAZIT MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "ORTA",
        "STREET": "TUTMAÇBAYINDIR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "ORTA",
        "STREET": "YAYLAKENT-CUMHURİYET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "ORTA",
        "STREET": "YAYLAKENT-HÜRRİYET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "ORTA",
        "STREET": "YAYLAKENT-KAYILAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "ORTA",
        "STREET": "YAYLAKENT-İNKİLAP MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "ORTA",
        "STREET": "YENİCE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "ORTA",
        "STREET": "YUVA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "ORTA",
        "STREET": "ÖZLÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "ORTA",
        "STREET": "İNCECİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "YAPRAKLI",
        "STREET": "AKYAZI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "YAPRAKLI",
        "STREET": "AYSEKİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "YAPRAKLI",
        "STREET": "AYVA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "YAPRAKLI",
        "STREET": "AŞAĞI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "YAPRAKLI",
        "STREET": "AŞAĞIÖZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "YAPRAKLI",
        "STREET": "BADEMÇAY KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "YAPRAKLI",
        "STREET": "BALIBIDIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "YAPRAKLI",
        "STREET": "BELİBEDİR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "YAPRAKLI",
        "STREET": "BULUCA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "YAPRAKLI",
        "STREET": "BUĞAY KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "YAPRAKLI",
        "STREET": "BÜYÜKAKSEKİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "YAPRAKLI",
        "STREET": "CAMİKEBİR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "YAPRAKLI",
        "STREET": "DAVUTLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "YAPRAKLI",
        "STREET": "DOĞANBEY KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "YAPRAKLI",
        "STREET": "GÜRMEÇ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "YAPRAKLI",
        "STREET": "KARACAÖZÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "YAPRAKLI",
        "STREET": "KAVAK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "YAPRAKLI",
        "STREET": "KAYACIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "YAPRAKLI",
        "STREET": "KAYMAZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "YAPRAKLI",
        "STREET": "KIVCAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "YAPRAKLI",
        "STREET": "KULLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "YAPRAKLI",
        "STREET": "KİRLİAKÇA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "YAPRAKLI",
        "STREET": "MÜSELLİM KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "YAPRAKLI",
        "STREET": "OVACIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "YAPRAKLI",
        "STREET": "SARIKAYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "YAPRAKLI",
        "STREET": "SAZCAĞIZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "YAPRAKLI",
        "STREET": "SOFUOĞLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "YAPRAKLI",
        "STREET": "SUBAŞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "YAPRAKLI",
        "STREET": "TOPUZSARAY KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "YAPRAKLI",
        "STREET": "YAKA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "YAPRAKLI",
        "STREET": "YAKADERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "YAPRAKLI",
        "STREET": "YAMAÇBAŞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "YAPRAKLI",
        "STREET": "YENİCE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "YAPRAKLI",
        "STREET": "YEŞİLYAYLA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "YAPRAKLI",
        "STREET": "YUKARI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "YAPRAKLI",
        "STREET": "YUKARIÖZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "YAPRAKLI",
        "STREET": "YÜKLÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "YAPRAKLI",
        "STREET": "ZEKERİYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "YAPRAKLI",
        "STREET": "ÇAKIRLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "YAPRAKLI",
        "STREET": "ÇEVRECİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "YAPRAKLI",
        "STREET": "ÇİÇEK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "YAPRAKLI",
        "STREET": "İKİZÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "YAPRAKLI",
        "STREET": "İĞDİR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "YAPRAKLI",
        "STREET": "ŞEYH OSMAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "MERKEZ",
        "STREET": "ABDULHALİK RENDA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "MERKEZ",
        "STREET": "AHLAT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "MERKEZ",
        "STREET": "AKSU MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "MERKEZ",
        "STREET": "AKÇAVAKIF KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "MERKEZ",
        "STREET": "AKÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "MERKEZ",
        "STREET": "ALAÇAT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "MERKEZ",
        "STREET": "ALICA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "MERKEZ",
        "STREET": "ALTINLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "MERKEZ",
        "STREET": "ALİBEY MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "MERKEZ",
        "STREET": "AYAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "MERKEZ",
        "STREET": "AĞZIBÜYÜK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "MERKEZ",
        "STREET": "AŞAĞIPELİTÖZÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "MERKEZ",
        "STREET": "AŞAĞIYANLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "MERKEZ",
        "STREET": "AŞAĞIÇAVUŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "MERKEZ",
        "STREET": "BALIBAĞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "MERKEZ",
        "STREET": "BAYINDIR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "MERKEZ",
        "STREET": "BAŞEĞMEZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "MERKEZ",
        "STREET": "BEŞTUT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "MERKEZ",
        "STREET": "BOZKIR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "MERKEZ",
        "STREET": "BUĞDAY PAZARI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "MERKEZ",
        "STREET": "CUMHURİYET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "MERKEZ",
        "STREET": "DANABAŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "MERKEZ",
        "STREET": "DEDE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "MERKEZ",
        "STREET": "DEĞİM KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "MERKEZ",
        "STREET": "DOĞANTEPE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "MERKEZ",
        "STREET": "DUTAĞAÇ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "MERKEZ",
        "STREET": "ESENTEPE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "MERKEZ",
        "STREET": "FATİH MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "MERKEZ",
        "STREET": "GERMECE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "MERKEZ",
        "STREET": "HANDIRI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "MERKEZ",
        "STREET": "HASAKÇA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "MERKEZ",
        "STREET": "HIDIRLIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "MERKEZ",
        "STREET": "KARADAYI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "MERKEZ",
        "STREET": "KARATAŞ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "MERKEZ",
        "STREET": "KARATEKİN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "MERKEZ",
        "STREET": "KARAŞEYH KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "MERKEZ",
        "STREET": "KIRKEVLER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "MERKEZ",
        "STREET": "KUZU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "MERKEZ",
        "STREET": "KÜÇÜKLÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "MERKEZ",
        "STREET": "MERZİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "MERKEZ",
        "STREET": "MİMAR SİNAN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "MERKEZ",
        "STREET": "OVACIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "MERKEZ",
        "STREET": "PAŞA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "MERKEZ",
        "STREET": "PEHLİVANLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "MERKEZ",
        "STREET": "SATIYÜZÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "MERKEZ",
        "STREET": "SÜLEYMANLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "MERKEZ",
        "STREET": "TABAKHANE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "MERKEZ",
        "STREET": "TAYTAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "MERKEZ",
        "STREET": "TUZLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "MERKEZ",
        "STREET": "TÜNEY KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "MERKEZ",
        "STREET": "YENİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "MERKEZ",
        "STREET": "YUKARIPELİTÖZÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "MERKEZ",
        "STREET": "YUKARIÇAVUŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇATALELMA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇAYIRPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇAĞABEY KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇIRÇIR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇİVİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "MERKEZ",
        "STREET": "ÜNÜR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "MERKEZ",
        "STREET": "İNANDIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "MERKEZ",
        "STREET": "İNAÇ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "MERKEZ",
        "STREET": "İNCİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "MERKEZ",
        "STREET": "İNCİLİ  ÇEŞME MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "MERKEZ",
        "STREET": "İÇYENİCE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "ÇERKEŞ",
        "STREET": "AFŞAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "ÇERKEŞ",
        "STREET": "AKBAŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "ÇERKEŞ",
        "STREET": "AKHASAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "ÇERKEŞ",
        "STREET": "ALİÖZÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "ÇERKEŞ",
        "STREET": "AYDINLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "ÇERKEŞ",
        "STREET": "AĞACA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "ÇERKEŞ",
        "STREET": "BAYINDIR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "ÇERKEŞ",
        "STREET": "BEDİL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "ÇERKEŞ",
        "STREET": "BELKAVAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "ÇERKEŞ",
        "STREET": "BEYMELİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "ÇERKEŞ",
        "STREET": "BOZOĞLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "ÇERKEŞ",
        "STREET": "CUMHURİYET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "ÇERKEŞ",
        "STREET": "DAĞÇUKURÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "ÇERKEŞ",
        "STREET": "DODURGA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "ÇERKEŞ",
        "STREET": "DİKENLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "ÇERKEŞ",
        "STREET": "FINDICAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "ÇERKEŞ",
        "STREET": "GELİK OVACIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "ÇERKEŞ",
        "STREET": "GÖKÇELER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "ÇERKEŞ",
        "STREET": "GÖYNÜKÇUKURU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "ÇERKEŞ",
        "STREET": "HACILAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "ÇERKEŞ",
        "STREET": "HALKAOĞLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "ÇERKEŞ",
        "STREET": "KABAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "ÇERKEŞ",
        "STREET": "KADIKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "ÇERKEŞ",
        "STREET": "KADIÖZÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "ÇERKEŞ",
        "STREET": "KARACAHÖYÜK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "ÇERKEŞ",
        "STREET": "KARALAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "ÇERKEŞ",
        "STREET": "KARAMUKLU MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "ÇERKEŞ",
        "STREET": "KARAMUSTAFA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "ÇERKEŞ",
        "STREET": "KARAŞAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "ÇERKEŞ",
        "STREET": "KARGA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "ÇERKEŞ",
        "STREET": "KISAÇ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "ÇERKEŞ",
        "STREET": "KURTLAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "ÇERKEŞ",
        "STREET": "KUZDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "ÇERKEŞ",
        "STREET": "KUZÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "ÇERKEŞ",
        "STREET": "KİREMİTÇİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "ÇERKEŞ",
        "STREET": "MEYDAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "ÇERKEŞ",
        "STREET": "OKÇULAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "ÇERKEŞ",
        "STREET": "SARAYCIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "ÇERKEŞ",
        "STREET": "SAÇAK-GÜZELYURT MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "ÇERKEŞ",
        "STREET": "SAÇAK-KAYABAŞI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "ÇERKEŞ",
        "STREET": "SAÇAK-YALIÖZÜ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "ÇERKEŞ",
        "STREET": "SAÇAK-YENİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "ÇERKEŞ",
        "STREET": "TAŞANLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "ÇERKEŞ",
        "STREET": "TÜRBAŞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "ÇERKEŞ",
        "STREET": "ULUKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "ÇERKEŞ",
        "STREET": "ULUSU MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "ÇERKEŞ",
        "STREET": "YAKUPLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "ÇERKEŞ",
        "STREET": "YENİ KENT MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "ÇERKEŞ",
        "STREET": "YENİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "ÇERKEŞ",
        "STREET": "YENİKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "ÇERKEŞ",
        "STREET": "YEŞİLÖZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "ÇERKEŞ",
        "STREET": "YIPRAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "ÇERKEŞ",
        "STREET": "YONCALI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "ÇERKEŞ",
        "STREET": "YUMAKLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "ÇERKEŞ",
        "STREET": "ÇAKMAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "ÇERKEŞ",
        "STREET": "ÇALCIÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "ÇERKEŞ",
        "STREET": "ÇAYLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "ÇERKEŞ",
        "STREET": "ÇÖRDÜK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "ÇERKEŞ",
        "STREET": "ÖRENKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "ÇERKEŞ",
        "STREET": "ÖRENLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "ÇERKEŞ",
        "STREET": "İDRİS MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "ÇERKEŞ",
        "STREET": "İSTASYON MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "ÇERKEŞ",
        "STREET": "ŞEYHDOĞAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "ŞABANÖZÜ",
        "STREET": "BAKIRLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "ŞABANÖZÜ",
        "STREET": "BULDUK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "ŞABANÖZÜ",
        "STREET": "BULGURCU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "ŞABANÖZÜ",
        "STREET": "BÜYÜKYAKALI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "ŞABANÖZÜ",
        "STREET": "CUMHURİYET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "ŞABANÖZÜ",
        "STREET": "GÖLDAĞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "ŞABANÖZÜ",
        "STREET": "GÜMERDİĞİN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "ŞABANÖZÜ",
        "STREET": "GÜNDOĞMUŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "ŞABANÖZÜ",
        "STREET": "GÜRPINAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "ŞABANÖZÜ",
        "STREET": "KAMIŞKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "ŞABANÖZÜ",
        "STREET": "KARAHACI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "ŞABANÖZÜ",
        "STREET": "KARAKOÇAŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "ŞABANÖZÜ",
        "STREET": "KARAMUSA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "ŞABANÖZÜ",
        "STREET": "KARAÖREN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "ŞABANÖZÜ",
        "STREET": "KUTLUŞAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "ŞABANÖZÜ",
        "STREET": "KÜÇÜKYAKALI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "ŞABANÖZÜ",
        "STREET": "MAHMUDİYE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "ŞABANÖZÜ",
        "STREET": "MARTKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "ŞABANÖZÜ",
        "STREET": "SAĞLIK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "ŞABANÖZÜ",
        "STREET": "YENİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "ŞABANÖZÜ",
        "STREET": "ÇAPAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "ŞABANÖZÜ",
        "STREET": "ÇAPARKAYI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "ŞABANÖZÜ",
        "STREET": "ÇERÇİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "ŞABANÖZÜ",
        "STREET": "ÖDEK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇANKIRI",
        "DISTRICT": "ŞABANÖZÜ",
        "STREET": "ÖZBEK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "ALACA",
        "STREET": "AKPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "ALACA",
        "STREET": "AKÇAKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "ALACA",
        "STREET": "AKÇİÇEK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "ALACA",
        "STREET": "AKÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "ALACA",
        "STREET": "ALACAHÜYÜK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "ALACA",
        "STREET": "ALTINTAŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "ALACA",
        "STREET": "AYHAN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "ALACA",
        "STREET": "BAHÇELİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "ALACA",
        "STREET": "BALÇIKHİSAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "ALACA",
        "STREET": "BELPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "ALACA",
        "STREET": "BEŞİKTEPE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "ALACA",
        "STREET": "BOLATÇIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "ALACA",
        "STREET": "BOZDOĞAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "ALACA",
        "STREET": "BOĞAZİÇİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "ALACA",
        "STREET": "BÜYÜKCAMİLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "ALACA",
        "STREET": "BÜYÜKDONA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "ALACA",
        "STREET": "BÜYÜKHIRKA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "ALACA",
        "STREET": "BÜYÜKKEŞLİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "ALACA",
        "STREET": "BÜYÜKSÖĞÜTÖZÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "ALACA",
        "STREET": "CENGİZHAN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "ALACA",
        "STREET": "CUMHURİYET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "ALACA",
        "STREET": "DEDEPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "ALACA",
        "STREET": "DENİZHAN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "ALACA",
        "STREET": "DEREYAZICI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "ALACA",
        "STREET": "DEĞİRMENDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "ALACA",
        "STREET": "DEĞİRMENÖNÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "ALACA",
        "STREET": "DUTLUCA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "ALACA",
        "STREET": "EREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "ALACA",
        "STREET": "ESKİYAPAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "ALACA",
        "STREET": "EVCİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "ALACA",
        "STREET": "FAKILAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "ALACA",
        "STREET": "GAZİPAŞA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "ALACA",
        "STREET": "GERDEKKAYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "ALACA",
        "STREET": "GEVEN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "ALACA",
        "STREET": "GÖKÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "ALACA",
        "STREET": "GÜLDERESİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "ALACA",
        "STREET": "GÜLLÜK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "ALACA",
        "STREET": "GÜNHAN  MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "ALACA",
        "STREET": "HARHAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "ALACA",
        "STREET": "HAYDAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "ALACA",
        "STREET": "KALECİKKAYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "ALACA",
        "STREET": "KALINKAYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "ALACA",
        "STREET": "KAPAKLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "ALACA",
        "STREET": "KARAMAHMUT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "ALACA",
        "STREET": "KARATEPE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "ALACA",
        "STREET": "KARAÇAL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "ALACA",
        "STREET": "KARGIN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "ALACA",
        "STREET": "KARNIKARA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "ALACA",
        "STREET": "KAYABÜVET KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "ALACA",
        "STREET": "KICILI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "ALACA",
        "STREET": "KILAVUZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "ALACA",
        "STREET": "KIZILLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "ALACA",
        "STREET": "KIZILYAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "ALACA",
        "STREET": "KIZKARACA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "ALACA",
        "STREET": "KOYUNOĞLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "ALACA",
        "STREET": "KOÇHİSAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "ALACA",
        "STREET": "KUYLUŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "ALACA",
        "STREET": "KUYUMCUSARAY KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "ALACA",
        "STREET": "KUZKIŞLA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "ALACA",
        "STREET": "KÜLAH KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "ALACA",
        "STREET": "KÜRE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "ALACA",
        "STREET": "KÜRKÇÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "ALACA",
        "STREET": "KÜÇÜKCAMİLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "ALACA",
        "STREET": "KÜÇÜKDONA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "ALACA",
        "STREET": "KÜÇÜKHIRKA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "ALACA",
        "STREET": "KÜÇÜKKEŞLİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "ALACA",
        "STREET": "KİLLİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "ALACA",
        "STREET": "MAHMUDİYE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "ALACA",
        "STREET": "MAZIBAŞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "ALACA",
        "STREET": "MİYANESULTAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "ALACA",
        "STREET": "ONBAŞILAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "ALACA",
        "STREET": "PERÇEM KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "ALACA",
        "STREET": "SANCI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "ALACA",
        "STREET": "SARISÜLEYMAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "ALACA",
        "STREET": "SEYİTNİZAM KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "ALACA",
        "STREET": "SOĞUCAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "ALACA",
        "STREET": "SULTAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "ALACA",
        "STREET": "SULUDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "ALACA",
        "STREET": "SİNCAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "ALACA",
        "STREET": "TAHİRABAT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "ALACA",
        "STREET": "TEVFİKİYE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "ALACA",
        "STREET": "TUTAŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "ALACA",
        "STREET": "YATANKAVAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "ALACA",
        "STREET": "YENİCE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "ALACA",
        "STREET": "YENİKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "ALACA",
        "STREET": "YEŞİLYURT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "ALACA",
        "STREET": "YILDIZHAN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "ALACA",
        "STREET": "YÜKSEKYAYLA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "ALACA",
        "STREET": "ÇAL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "ALACA",
        "STREET": "ÇATAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "ALACA",
        "STREET": "ÇATALBAŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "ALACA",
        "STREET": "ÇATALKAYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "ALACA",
        "STREET": "ÇELEBİBAĞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "ALACA",
        "STREET": "ÇETEDERESİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "ALACA",
        "STREET": "ÇEVRELİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "ALACA",
        "STREET": "ÇIRÇIR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "ALACA",
        "STREET": "ÇOPRAŞIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "ALACA",
        "STREET": "ÇÖPLÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "ALACA",
        "STREET": "ÇÖPLÜAVUTMUŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "ALACA",
        "STREET": "ÇİKHASAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "ALACA",
        "STREET": "ÖRÜKAYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "ALACA",
        "STREET": "ÖZHAN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "ALACA",
        "STREET": "ÜNALAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "ALACA",
        "STREET": "İBRAHİM KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "ALACA",
        "STREET": "İMAT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "ALACA",
        "STREET": "İSAHACI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "ALACA",
        "STREET": "İSMAİLLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "BAYAT",
        "STREET": "AHACIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "BAYAT",
        "STREET": "AKSEKİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "BAYAT",
        "STREET": "AYVALICA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "BAYAT",
        "STREET": "AŞAĞI EMİRHALİL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "BAYAT",
        "STREET": "BARAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "BAYAT",
        "STREET": "BAYAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "BAYAT",
        "STREET": "BEYDİLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "BAYAT",
        "STREET": "CEVİZLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "BAYAT",
        "STREET": "DEMİRCİLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "BAYAT",
        "STREET": "DERE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "BAYAT",
        "STREET": "DEREKUTUĞUN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "BAYAT",
        "STREET": "DORUKSEKİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "BAYAT",
        "STREET": "EMİRHALİL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "BAYAT",
        "STREET": "EMİRŞAH KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "BAYAT",
        "STREET": "ESKİALİBEY KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "BAYAT",
        "STREET": "EVCİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "BAYAT",
        "STREET": "FALI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "BAYAT",
        "STREET": "HACIBAYRAM KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "BAYAT",
        "STREET": "KALINPELİT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "BAYAT",
        "STREET": "KARAKAYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "BAYAT",
        "STREET": "KAYABAŞI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "BAYAT",
        "STREET": "KUBBEDİN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "BAYAT",
        "STREET": "KUNDUZLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "BAYAT",
        "STREET": "KURUÇAY KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "BAYAT",
        "STREET": "KÖPÜKLÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "BAYAT",
        "STREET": "LAPA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "BAYAT",
        "STREET": "PANCARLIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "BAYAT",
        "STREET": "SARAY KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "BAYAT",
        "STREET": "SAĞPAZAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "BAYAT",
        "STREET": "TEPEBAYAT MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "BAYAT",
        "STREET": "TEPEKUTUĞUN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "BAYAT",
        "STREET": "TEVEKLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "BAYAT",
        "STREET": "TOYHANE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "BAYAT",
        "STREET": "YATUKCU MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "BAYAT",
        "STREET": "YENİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "BAYAT",
        "STREET": "YENİKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "BAYAT",
        "STREET": "YENİŞIHLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "BAYAT",
        "STREET": "YEŞİLÇAT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "BAYAT",
        "STREET": "YONCALI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "BAYAT",
        "STREET": "ÇAMLIGÜNEY KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "BAYAT",
        "STREET": "ÇAYKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "BAYAT",
        "STREET": "ÇERKEŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "BAYAT",
        "STREET": "ÇUKURÖZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "BAYAT",
        "STREET": "İLEĞİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "BAYAT",
        "STREET": "İSHAKLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "BOĞAZKALE",
        "STREET": "EMİRLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "BOĞAZKALE",
        "STREET": "EVCİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "BOĞAZKALE",
        "STREET": "GÖLPINARLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "BOĞAZKALE",
        "STREET": "HATTUSAS MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "BOĞAZKALE",
        "STREET": "HİSAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "BOĞAZKALE",
        "STREET": "KADILITÜRK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "BOĞAZKALE",
        "STREET": "KARAKEÇİLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "BOĞAZKALE",
        "STREET": "KAYMAZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "BOĞAZKALE",
        "STREET": "SARIÇİÇEK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "BOĞAZKALE",
        "STREET": "YANICAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "BOĞAZKALE",
        "STREET": "YAZIR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "BOĞAZKALE",
        "STREET": "YEKBAS KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "BOĞAZKALE",
        "STREET": "YENİKADILI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "BOĞAZKALE",
        "STREET": "YUKARIFINDIKLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "BOĞAZKALE",
        "STREET": "ÇARŞI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "BOĞAZKALE",
        "STREET": "ÇARŞICUMA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "BOĞAZKALE",
        "STREET": "ÖRENKAYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "DODURGA",
        "STREET": "AKKAYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "DODURGA",
        "STREET": "ALPAGUT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "DODURGA",
        "STREET": "AYVAKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "DODURGA",
        "STREET": "BERKKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "DODURGA",
        "STREET": "DOĞU MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "DODURGA",
        "STREET": "DİKENLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "DODURGA",
        "STREET": "ESENTEPE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "DODURGA",
        "STREET": "FATİH MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "DODURGA",
        "STREET": "KUYUCAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "DODURGA",
        "STREET": "KİRENCİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "DODURGA",
        "STREET": "MEHMETDEDEOBRUĞU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "DODURGA",
        "STREET": "MEHMETDEDETEKKESİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "DODURGA",
        "STREET": "OKUL MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "DODURGA",
        "STREET": "TUTUŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "DODURGA",
        "STREET": "ULUCAMİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "DODURGA",
        "STREET": "YENİKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "DODURGA",
        "STREET": "ÇATAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "DODURGA",
        "STREET": "ÇİFTLİKKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "KARGI",
        "STREET": "ABDULLAH KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "KARGI",
        "STREET": "AKKAYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "KARGI",
        "STREET": "AKKİSE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "KARGI",
        "STREET": "AKÇATAŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "KARGI",
        "STREET": "ALİOĞLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "KARGI",
        "STREET": "ARIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "KARGI",
        "STREET": "AVŞAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "KARGI",
        "STREET": "BADEMCE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "KARGI",
        "STREET": "BAHÇELİEVLER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "KARGI",
        "STREET": "BAYAT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "KARGI",
        "STREET": "BAĞÖZÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "KARGI",
        "STREET": "BAŞKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "KARGI",
        "STREET": "BEYGİRCİOĞLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "KARGI",
        "STREET": "BOZARMUT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "KARGI",
        "STREET": "CİHADİYE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "KARGI",
        "STREET": "DEMİRÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "KARGI",
        "STREET": "DEREKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "KARGI",
        "STREET": "FATİH MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "KARGI",
        "STREET": "GÖKBUDAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "KARGI",
        "STREET": "GÖKÇEDOĞAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "KARGI",
        "STREET": "GÖL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "KARGI",
        "STREET": "GÖLET KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "KARGI",
        "STREET": "GÖLETDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "KARGI",
        "STREET": "GÜMÜŞOĞLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "KARGI",
        "STREET": "GÜNEY KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "KARGI",
        "STREET": "GÜNYAZI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "KARGI",
        "STREET": "HACIHAMZA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "KARGI",
        "STREET": "HACIVELİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "KARGI",
        "STREET": "HALILAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "KARGI",
        "STREET": "KABAKCI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "KARGI",
        "STREET": "KARABOYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "KARGI",
        "STREET": "KARACAOĞLAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "KARGI",
        "STREET": "KARAKİSE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "KARGI",
        "STREET": "KARAOSMANLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "KARGI",
        "STREET": "KARAPÜRÇEK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "KARGI",
        "STREET": "KAVAKÇAYI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "KARGI",
        "STREET": "KOYUNKIRAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "KARGI",
        "STREET": "KÖPRÜBAŞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "KARGI",
        "STREET": "MAKSUTLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "KARGI",
        "STREET": "MİHRİHATUN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "KARGI",
        "STREET": "ORTA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "KARGI",
        "STREET": "PELİTCİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "KARGI",
        "STREET": "PELİTÖZÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "KARGI",
        "STREET": "SARAYCIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "KARGI",
        "STREET": "SARAÇLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "KARGI",
        "STREET": "SEKİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "KARGI",
        "STREET": "SÜNLÜK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "KARGI",
        "STREET": "SİNANÖZÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "KARGI",
        "STREET": "TEPELİCE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "KARGI",
        "STREET": "UZUNYURT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "KARGI",
        "STREET": "UĞUZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "KARGI",
        "STREET": "YAĞCILAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "KARGI",
        "STREET": "YENİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "KARGI",
        "STREET": "YEŞİLKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "KARGI",
        "STREET": "ÇAKIRLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "KARGI",
        "STREET": "ÇAL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "KARGI",
        "STREET": "ÇELTİKÖZÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "KARGI",
        "STREET": "ÇETMİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "KARGI",
        "STREET": "ÇOBANKAYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "KARGI",
        "STREET": "ÇOBANLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "KARGI",
        "STREET": "ÇUKURALUÇ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "KARGI",
        "STREET": "ÖRENCİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "KARGI",
        "STREET": "İMAMBEY MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "KARGI",
        "STREET": "İNCEÇAY KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "LAÇİN",
        "STREET": "BAHÇELİEVLER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "LAÇİN",
        "STREET": "DOĞANLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "LAÇİN",
        "STREET": "FATİH MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "LAÇİN",
        "STREET": "GÖKGÖZLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "LAÇİN",
        "STREET": "GÖKÇEKAYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "LAÇİN",
        "STREET": "GÖZÜBÜYÜK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "LAÇİN",
        "STREET": "KARASOKU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "LAÇİN",
        "STREET": "KAVAKLIÇİFTLİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "LAÇİN",
        "STREET": "KUYUMCU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "LAÇİN",
        "STREET": "MESCİTLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "LAÇİN",
        "STREET": "NARLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "LAÇİN",
        "STREET": "SITMA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "LAÇİN",
        "STREET": "YENİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "LAÇİN",
        "STREET": "YEŞİLPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "LAÇİN",
        "STREET": "ÇAMLICA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "LAÇİN",
        "STREET": "ÇAMLIPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "LAÇİN",
        "STREET": "İKİZCE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "MECİTÖZÜ",
        "STREET": "AKPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "MECİTÖZÜ",
        "STREET": "ALANCIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "MECİTÖZÜ",
        "STREET": "ALÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "MECİTÖZÜ",
        "STREET": "AĞCAKOYUN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "MECİTÖZÜ",
        "STREET": "AŞAĞIKÖRÜCEK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "MECİTÖZÜ",
        "STREET": "BAHÇELİEVLER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "MECİTÖZÜ",
        "STREET": "BAYINDIR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "MECİTÖZÜ",
        "STREET": "BEKİŞLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "MECİTÖZÜ",
        "STREET": "BEYÖZÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "MECİTÖZÜ",
        "STREET": "BOYACI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "MECİTÖZÜ",
        "STREET": "BOĞAZKAYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "MECİTÖZÜ",
        "STREET": "BÜKSE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "MECİTÖZÜ",
        "STREET": "CAMİKEBİR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "MECİTÖZÜ",
        "STREET": "DAĞSARAY KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "MECİTÖZÜ",
        "STREET": "DEVLETOĞLAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "MECİTÖZÜ",
        "STREET": "DOĞLA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "MECİTÖZÜ",
        "STREET": "DOĞU MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "MECİTÖZÜ",
        "STREET": "ELMAPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "MECİTÖZÜ",
        "STREET": "ELVANÇELEBİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "MECİTÖZÜ",
        "STREET": "EMİRBAĞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "MECİTÖZÜ",
        "STREET": "FAKIAHMET KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "MECİTÖZÜ",
        "STREET": "FINDIKLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "MECİTÖZÜ",
        "STREET": "FİGANİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "MECİTÖZÜ",
        "STREET": "GEYKOCA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "MECİTÖZÜ",
        "STREET": "GÖKÇEBEL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "MECİTÖZÜ",
        "STREET": "GÜNGÖRMEZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "MECİTÖZÜ",
        "STREET": "HIDIRLIK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "MECİTÖZÜ",
        "STREET": "HİSARKAVAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "MECİTÖZÜ",
        "STREET": "IŞIKLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "MECİTÖZÜ",
        "STREET": "KALECİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "MECİTÖZÜ",
        "STREET": "KARACAÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "MECİTÖZÜ",
        "STREET": "KARACUMA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "MECİTÖZÜ",
        "STREET": "KARGI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "MECİTÖZÜ",
        "STREET": "KAYI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "MECİTÖZÜ",
        "STREET": "KIŞLACIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "MECİTÖZÜ",
        "STREET": "KONAÇ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "MECİTÖZÜ",
        "STREET": "KOYUNAĞILI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "MECİTÖZÜ",
        "STREET": "KOZÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "MECİTÖZÜ",
        "STREET": "KUYUCAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "MECİTÖZÜ",
        "STREET": "KÖPRÜBAŞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "MECİTÖZÜ",
        "STREET": "KÖRÜCEK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "MECİTÖZÜ",
        "STREET": "KÖSEEYÜP KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "MECİTÖZÜ",
        "STREET": "MESCİT MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "MECİTÖZÜ",
        "STREET": "PINARBAŞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "MECİTÖZÜ",
        "STREET": "SARIDEDE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "MECİTÖZÜ",
        "STREET": "SARIHASAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "MECİTÖZÜ",
        "STREET": "SIRÇALI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "MECİTÖZÜ",
        "STREET": "SORKOĞLAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "MECİTÖZÜ",
        "STREET": "SÖĞÜTYOLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "MECİTÖZÜ",
        "STREET": "SÖĞÜTÖNÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "MECİTÖZÜ",
        "STREET": "SÜLÜKLÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "MECİTÖZÜ",
        "STREET": "TANRIVERMİŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "MECİTÖZÜ",
        "STREET": "TERKEN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "MECİTÖZÜ",
        "STREET": "TOTALİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "MECİTÖZÜ",
        "STREET": "TUTLUK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "MECİTÖZÜ",
        "STREET": "VAKIFLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "MECİTÖZÜ",
        "STREET": "YEDİGÖZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "MECİTÖZÜ",
        "STREET": "YEŞİLOVA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "MECİTÖZÜ",
        "STREET": "ÇAYKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "MECİTÖZÜ",
        "STREET": "ÇİTLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "MECİTÖZÜ",
        "STREET": "İBEK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "MECİTÖZÜ",
        "STREET": "ŞEHLER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "ORTAKÖY",
        "STREET": "ASAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "ORTAKÖY",
        "STREET": "AŞTAVUL-BAHÇELİEVLER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "ORTAKÖY",
        "STREET": "AŞTAVUL-ŞİRİNEVLER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "ORTAKÖY",
        "STREET": "BAHÇELİEVLER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "ORTAKÖY",
        "STREET": "BÜYÜKKIŞLA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "ORTAKÖY",
        "STREET": "CEVİZLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "ORTAKÖY",
        "STREET": "CUMHURİYET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "ORTAKÖY",
        "STREET": "ESENTEPE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "ORTAKÖY",
        "STREET": "FINDIKLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "ORTAKÖY",
        "STREET": "KARAHACİP KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "ORTAKÖY",
        "STREET": "KAVAKALAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "ORTAKÖY",
        "STREET": "KIZILHAMZA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "ORTAKÖY",
        "STREET": "ORUÇPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "ORTAKÖY",
        "STREET": "SALBAŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "ORTAKÖY",
        "STREET": "SENEMOĞLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "ORTAKÖY",
        "STREET": "SUYOLU MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "ORTAKÖY",
        "STREET": "YAYLACIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "ORTAKÖY",
        "STREET": "YUKARI KUYUCAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "ORTAKÖY",
        "STREET": "İNCESU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "OSMANCIK",
        "STREET": "AKÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "OSMANCIK",
        "STREET": "ALİBEY KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "OSMANCIK",
        "STREET": "ARDIÇ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "OSMANCIK",
        "STREET": "AVLAĞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "OSMANCIK",
        "STREET": "AYDIN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "OSMANCIK",
        "STREET": "AĞIROĞLAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "OSMANCIK",
        "STREET": "AŞAĞIZEYTİN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "OSMANCIK",
        "STREET": "AŞIKBÜKÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "OSMANCIK",
        "STREET": "BALDIRAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "OSMANCIK",
        "STREET": "BALTACIMEHMETPAŞA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "OSMANCIK",
        "STREET": "BAŞPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "OSMANCIK",
        "STREET": "BELKAVAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "OSMANCIK",
        "STREET": "CUMHURİYET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "OSMANCIK",
        "STREET": "DANİŞMENT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "OSMANCIK",
        "STREET": "DELLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "OSMANCIK",
        "STREET": "DOĞAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "OSMANCIK",
        "STREET": "DURUCASU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "OSMANCIK",
        "STREET": "ESENTEPE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "OSMANCIK",
        "STREET": "EVLİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "OSMANCIK",
        "STREET": "EYMİR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "OSMANCIK",
        "STREET": "FINDICAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "OSMANCIK",
        "STREET": "FINDIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "OSMANCIK",
        "STREET": "GECEK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "OSMANCIK",
        "STREET": "GEMİCİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "OSMANCIK",
        "STREET": "GÖKDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "OSMANCIK",
        "STREET": "GÜNEY MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "OSMANCIK",
        "STREET": "GÜNEŞÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "OSMANCIK",
        "STREET": "GÜRLEYİK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "OSMANCIK",
        "STREET": "GÜVERCİNLİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "OSMANCIK",
        "STREET": "GİRİNOĞLAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "OSMANCIK",
        "STREET": "HANEFİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "OSMANCIK",
        "STREET": "HIDIRLIK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "OSMANCIK",
        "STREET": "KAMİL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "OSMANCIK",
        "STREET": "KARAKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "OSMANCIK",
        "STREET": "KARALARGÜNEY KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "OSMANCIK",
        "STREET": "KARAPINAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "OSMANCIK",
        "STREET": "KARAÇAY KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "OSMANCIK",
        "STREET": "KARGI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "OSMANCIK",
        "STREET": "KIZILIRMAK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "OSMANCIK",
        "STREET": "KIZILTEPE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "OSMANCIK",
        "STREET": "KONACA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "OSMANCIK",
        "STREET": "KOYUNBABA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "OSMANCIK",
        "STREET": "KUMBABA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "OSMANCIK",
        "STREET": "KUZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "OSMANCIK",
        "STREET": "KUZHAYAT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "OSMANCIK",
        "STREET": "OVACIKSUYU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "OSMANCIK",
        "STREET": "PELİTÇİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "OSMANCIK",
        "STREET": "SARIALAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "OSMANCIK",
        "STREET": "SARPUNKAVAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "OSMANCIK",
        "STREET": "SEKİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "OSMANCIK",
        "STREET": "SEKİBAĞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "OSMANCIK",
        "STREET": "SÜTLÜCE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "OSMANCIK",
        "STREET": "TEKMEN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "OSMANCIK",
        "STREET": "TEMENÇE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "OSMANCIK",
        "STREET": "TEPEYOLALTI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "OSMANCIK",
        "STREET": "ULUCAMİİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "OSMANCIK",
        "STREET": "UMAÇ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "OSMANCIK",
        "STREET": "YAYLABAŞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "OSMANCIK",
        "STREET": "YAZI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "OSMANCIK",
        "STREET": "YAĞSİYAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "OSMANCIK",
        "STREET": "YENİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "OSMANCIK",
        "STREET": "YENİDANİŞMENT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "OSMANCIK",
        "STREET": "YEŞİLÇATMA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "OSMANCIK",
        "STREET": "YUKARIZEYTİN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "OSMANCIK",
        "STREET": "ÇAMPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "OSMANCIK",
        "STREET": "ÇATAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "OSMANCIK",
        "STREET": "ÇAY MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "OSMANCIK",
        "STREET": "ÇAYIR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "OSMANCIK",
        "STREET": "ÇİFTLİKLER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "OSMANCIK",
        "STREET": "ÖBEKTAŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "OSMANCIK",
        "STREET": "İNAL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "OSMANCIK",
        "STREET": "İNCESU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "OSMANCIK",
        "STREET": "ŞENYURT MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "OĞUZLAR",
        "STREET": "ASARÇAY MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "OĞUZLAR",
        "STREET": "AĞAÇÇAMI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "OĞUZLAR",
        "STREET": "BİRTATLI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "OĞUZLAR",
        "STREET": "CEVİZLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "OĞUZLAR",
        "STREET": "DERİNÖZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "OĞUZLAR",
        "STREET": "ERENLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "OĞUZLAR",
        "STREET": "GÖLBAŞI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "OĞUZLAR",
        "STREET": "KARADONLU MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "OĞUZLAR",
        "STREET": "KAYI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "OĞUZLAR",
        "STREET": "KIZILCAPELİT MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "OĞUZLAR",
        "STREET": "ÇARŞI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "OĞUZLAR",
        "STREET": "ÇEŞME MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "OĞUZLAR",
        "STREET": "ŞAPHANE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "SUNGURLU",
        "STREET": "AKDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "SUNGURLU",
        "STREET": "AKPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "SUNGURLU",
        "STREET": "AKÇAKENT MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "SUNGURLU",
        "STREET": "AKÇAKOYUNLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "SUNGURLU",
        "STREET": "AKÇALI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "SUNGURLU",
        "STREET": "AKÇAY MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "SUNGURLU",
        "STREET": "ALEMBEYLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "SUNGURLU",
        "STREET": "ARABAÇAYI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "SUNGURLU",
        "STREET": "ARICI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "SUNGURLU",
        "STREET": "ARİFEGAZİLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "SUNGURLU",
        "STREET": "ASAYİŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "SUNGURLU",
        "STREET": "AYAĞIBÜYÜK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "SUNGURLU",
        "STREET": "AYDOĞAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "SUNGURLU",
        "STREET": "AŞAĞIBEŞPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "SUNGURLU",
        "STREET": "AŞAĞIFINDIKLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "SUNGURLU",
        "STREET": "BAHÇELİEVLER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "SUNGURLU",
        "STREET": "BAHŞILI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "SUNGURLU",
        "STREET": "BALKAYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "SUNGURLU",
        "STREET": "BAĞCILI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "SUNGURLU",
        "STREET": "BAĞDATLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "SUNGURLU",
        "STREET": "BAŞPINAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "SUNGURLU",
        "STREET": "BEYLİCE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "SUNGURLU",
        "STREET": "BEYYURDU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "SUNGURLU",
        "STREET": "BEŞDAM KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "SUNGURLU",
        "STREET": "BEŞKIZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "SUNGURLU",
        "STREET": "BOZTEPE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "SUNGURLU",
        "STREET": "BOZYAYLA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "SUNGURLU",
        "STREET": "BUNALAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "SUNGURLU",
        "STREET": "BÜYÜKPOLATLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "SUNGURLU",
        "STREET": "BÜYÜKİNCESU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "SUNGURLU",
        "STREET": "CEVHERİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "SUNGURLU",
        "STREET": "DAYINCIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "SUNGURLU",
        "STREET": "DEMİRŞEYH KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "SUNGURLU",
        "STREET": "DENİZLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "SUNGURLU",
        "STREET": "DEREKIŞLA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "SUNGURLU",
        "STREET": "DERTLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "SUNGURLU",
        "STREET": "EKMEKÇİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "SUNGURLU",
        "STREET": "EŞME KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "SUNGURLU",
        "STREET": "FATİH  MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "SUNGURLU",
        "STREET": "FEVZİ PAŞA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "SUNGURLU",
        "STREET": "GAFURLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "SUNGURLU",
        "STREET": "GÖKÇAM KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "SUNGURLU",
        "STREET": "GÖKÇEKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "SUNGURLU",
        "STREET": "GÖLLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "SUNGURLU",
        "STREET": "GÜLDERESİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "SUNGURLU",
        "STREET": "GÜLOLUK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "SUNGURLU",
        "STREET": "GÜRPINAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "SUNGURLU",
        "STREET": "GÜVENDİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "SUNGURLU",
        "STREET": "HACETTEPE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "SUNGURLU",
        "STREET": "HACIOSMAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "SUNGURLU",
        "STREET": "HİLALLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "SUNGURLU",
        "STREET": "KALEDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "SUNGURLU",
        "STREET": "KALENDEROĞLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "SUNGURLU",
        "STREET": "KAMIŞLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "SUNGURLU",
        "STREET": "KARACABEY KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "SUNGURLU",
        "STREET": "KARAKAYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "SUNGURLU",
        "STREET": "KARAKOCALI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "SUNGURLU",
        "STREET": "KARAOĞLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "SUNGURLU",
        "STREET": "KARAÇAY KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "SUNGURLU",
        "STREET": "KAVŞUT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "SUNGURLU",
        "STREET": "KEMALLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "SUNGURLU",
        "STREET": "KERTME KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "SUNGURLU",
        "STREET": "KIRANKIŞLA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "SUNGURLU",
        "STREET": "KIZILCAKIŞLA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "SUNGURLU",
        "STREET": "KIŞLAKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "SUNGURLU",
        "STREET": "KULA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "SUNGURLU",
        "STREET": "KURBAĞLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "SUNGURLU",
        "STREET": "KUZUCAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "SUNGURLU",
        "STREET": "KUŞÇALI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "SUNGURLU",
        "STREET": "KÖRKÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "SUNGURLU",
        "STREET": "KÜÇÜKPOLATLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "SUNGURLU",
        "STREET": "KÜÇÜKİNCESU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "SUNGURLU",
        "STREET": "MAHMATLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "SUNGURLU",
        "STREET": "MEHMETALİÇİFTLİĞİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "SUNGURLU",
        "STREET": "MEHMETBEYLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "SUNGURLU",
        "STREET": "MURATKOLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "SUNGURLU",
        "STREET": "ORTAKIŞLA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "SUNGURLU",
        "STREET": "ORTAKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "SUNGURLU",
        "STREET": "OYACA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "SUNGURLU",
        "STREET": "OĞLAKÖZÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "SUNGURLU",
        "STREET": "SALMANKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "SUNGURLU",
        "STREET": "SARAYCIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "SUNGURLU",
        "STREET": "SARICALAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "SUNGURLU",
        "STREET": "SARIKAYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "SUNGURLU",
        "STREET": "SUNGURLU T TİPİ KAPALI VE AÇIK CEZA İNFAZ KURUMU",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "SUNGURLU",
        "STREET": "SUNGUROĞLU MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "SUNGURLU",
        "STREET": "TATLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "SUNGURLU",
        "STREET": "TERZİLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "SUNGURLU",
        "STREET": "TOKULLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "SUNGURLU",
        "STREET": "TOPUZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "SUNGURLU",
        "STREET": "TURAN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "SUNGURLU",
        "STREET": "TURGUTLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "SUNGURLU",
        "STREET": "TUĞCU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "SUNGURLU",
        "STREET": "TUĞLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "SUNGURLU",
        "STREET": "TÜRKHACILARHANI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "SUNGURLU",
        "STREET": "TİRKEŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "SUNGURLU",
        "STREET": "YARIMSÖĞÜT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "SUNGURLU",
        "STREET": "YENİDOĞAN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "SUNGURLU",
        "STREET": "YENİHACILARHANI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "SUNGURLU",
        "STREET": "YENİHAYAT MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "SUNGURLU",
        "STREET": "YEŞİLOVA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "SUNGURLU",
        "STREET": "YEŞİLYURT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "SUNGURLU",
        "STREET": "YORGALI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "SUNGURLU",
        "STREET": "YUKARIBEŞPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "SUNGURLU",
        "STREET": "YÖRÜKLÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "SUNGURLU",
        "STREET": "YİRCE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "SUNGURLU",
        "STREET": "ÇADIRHÜYÜK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "SUNGURLU",
        "STREET": "ÇAMOLUK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "SUNGURLU",
        "STREET": "ÇAVUŞKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "SUNGURLU",
        "STREET": "ÇAVUŞÇU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "SUNGURLU",
        "STREET": "ÇAYAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "SUNGURLU",
        "STREET": "ÇAYYAKA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "SUNGURLU",
        "STREET": "ÇUKURLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "SUNGURLU",
        "STREET": "ÇULHALI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "SUNGURLU",
        "STREET": "ÇİFTLİKKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "SUNGURLU",
        "STREET": "ÇİNGİLLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "SUNGURLU",
        "STREET": "ÇİÇEKLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "SUNGURLU",
        "STREET": "ÇİÇEKLİKELLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "SUNGURLU",
        "STREET": "ÖRNEKEVLER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "SUNGURLU",
        "STREET": "ÜÇOLUK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "SUNGURLU",
        "STREET": "İKİZLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "SUNGURLU",
        "STREET": "İMİRLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "SUNGURLU",
        "STREET": "İNEGAZİLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "SUNGURLU",
        "STREET": "İSMETPAŞA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "SUNGURLU",
        "STREET": "ŞEKERHACILI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "SUNGURLU",
        "STREET": "ŞEKERPINAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "UĞURLUDAĞ",
        "STREET": "ANBARCI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "UĞURLUDAĞ",
        "STREET": "AŞILIARMUT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "UĞURLUDAĞ",
        "STREET": "BELKAVAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "UĞURLUDAĞ",
        "STREET": "BOZTEPE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "UĞURLUDAĞ",
        "STREET": "BÜYÜKERİKLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "UĞURLUDAĞ",
        "STREET": "DAĞÖNÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "UĞURLUDAĞ",
        "STREET": "ESKİÇELTEK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "UĞURLUDAĞ",
        "STREET": "GÖKÇEAĞAÇ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "UĞURLUDAĞ",
        "STREET": "KARAEVLİYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "UĞURLUDAĞ",
        "STREET": "KARAKISIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "UĞURLUDAĞ",
        "STREET": "KARŞIYAKA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "UĞURLUDAĞ",
        "STREET": "KIRKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "UĞURLUDAĞ",
        "STREET": "KIZAĞLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "UĞURLUDAĞ",
        "STREET": "KÖPEÇ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "UĞURLUDAĞ",
        "STREET": "KÜÇÜKERİKLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "UĞURLUDAĞ",
        "STREET": "RESULOĞLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "UĞURLUDAĞ",
        "STREET": "SAZKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "UĞURLUDAĞ",
        "STREET": "TORUNLAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "UĞURLUDAĞ",
        "STREET": "TUTPINAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "UĞURLUDAĞ",
        "STREET": "YARIMCA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "UĞURLUDAĞ",
        "STREET": "YENİCAMİİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "UĞURLUDAĞ",
        "STREET": "YENİYAPAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "UĞURLUDAĞ",
        "STREET": "YEŞİLYURT MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "UĞURLUDAĞ",
        "STREET": "YÜZGEÇ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "UĞURLUDAĞ",
        "STREET": "ÜÇDAM KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "MERKEZ",
        "STREET": "ABDALATA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "MERKEZ",
        "STREET": "ACIPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "MERKEZ",
        "STREET": "AHMEDİYE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "MERKEZ",
        "STREET": "AHMETOĞLAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "MERKEZ",
        "STREET": "AHİİLYAS KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "MERKEZ",
        "STREET": "AK KENT MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "MERKEZ",
        "STREET": "AKSUNGUR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "MERKEZ",
        "STREET": "AKYAZI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "MERKEZ",
        "STREET": "AKÇAKAYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "MERKEZ",
        "STREET": "ALTINBAŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "MERKEZ",
        "STREET": "ARPALIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "MERKEZ",
        "STREET": "ARPAÖZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "MERKEZ",
        "STREET": "ARSLAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "MERKEZ",
        "STREET": "ATÇALI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "MERKEZ",
        "STREET": "AYAZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "MERKEZ",
        "STREET": "AYVALI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "MERKEZ",
        "STREET": "AŞAĞISARAYLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "MERKEZ",
        "STREET": "BABAOĞLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "MERKEZ",
        "STREET": "BAHÇELİEVLER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "MERKEZ",
        "STREET": "BALIMSULTAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "MERKEZ",
        "STREET": "BALIYAKUP KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "MERKEZ",
        "STREET": "BAYAT MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "MERKEZ",
        "STREET": "BEKTAŞOĞLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "MERKEZ",
        "STREET": "BEYDİLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "MERKEZ",
        "STREET": "BOZBOĞA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "MERKEZ",
        "STREET": "BOĞABAĞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "MERKEZ",
        "STREET": "BOĞACIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "MERKEZ",
        "STREET": "BOĞAZÖNÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "MERKEZ",
        "STREET": "BUDAKÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "MERKEZ",
        "STREET": "BUHARAEVLER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "MERKEZ",
        "STREET": "BURUN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "MERKEZ",
        "STREET": "BÜYÜKDİVAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "MERKEZ",
        "STREET": "BÜYÜKGÜLÜCEK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "MERKEZ",
        "STREET": "BÜĞDÜZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "MERKEZ",
        "STREET": "BÜĞET KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "MERKEZ",
        "STREET": "BÜĞRÜOĞLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "MERKEZ",
        "STREET": "CELİLKIRI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "MERKEZ",
        "STREET": "CERİT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "MERKEZ",
        "STREET": "DAĞKARAPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "MERKEZ",
        "STREET": "DELİBEKİROĞLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "MERKEZ",
        "STREET": "DELİLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "MERKEZ",
        "STREET": "DENİZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "MERKEZ",
        "STREET": "DERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "MERKEZ",
        "STREET": "DEĞİRMENDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "MERKEZ",
        "STREET": "DUT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "MERKEZ",
        "STREET": "DUTÇAKALLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "MERKEZ",
        "STREET": "DÜDÜKLÜK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "MERKEZ",
        "STREET": "DÜVENCİ-ATAKÖY MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "MERKEZ",
        "STREET": "DÜVENCİ-FATİH SULTAN MEHMET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "MERKEZ",
        "STREET": "DÜVENCİ-GEDİKBEKİR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "MERKEZ",
        "STREET": "DÜVENCİ-KIŞLADERESİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "MERKEZ",
        "STREET": "DÜVENCİ-KULTAK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "MERKEZ",
        "STREET": "DÜVENCİ-MİMARSİNAN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "MERKEZ",
        "STREET": "DÜVENCİ-YUNUSEMRE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "MERKEZ",
        "STREET": "DÜVENCİ-İKİPINAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "MERKEZ",
        "STREET": "ELKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "MERKEZ",
        "STREET": "ELMALI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "MERKEZ",
        "STREET": "ELİCEK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "MERKEZ",
        "STREET": "ERDEK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "MERKEZ",
        "STREET": "ERTUĞRUL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "MERKEZ",
        "STREET": "ESENÇAY KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "MERKEZ",
        "STREET": "ESKİCE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "MERKEZ",
        "STREET": "ESKİEKİN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "MERKEZ",
        "STREET": "ESKİKARADONA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "MERKEZ",
        "STREET": "ESKİKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "MERKEZ",
        "STREET": "ESKİÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "MERKEZ",
        "STREET": "EVCİKUZKIŞLA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "MERKEZ",
        "STREET": "EVCİORTAKIŞLA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "MERKEZ",
        "STREET": "EVCİYENİKIŞLA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "MERKEZ",
        "STREET": "EYERCİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "MERKEZ",
        "STREET": "EYMİR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "MERKEZ",
        "STREET": "FERUZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "MERKEZ",
        "STREET": "GEMET KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "MERKEZ",
        "STREET": "GÖCENOVACIĞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "MERKEZ",
        "STREET": "GÖKDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "MERKEZ",
        "STREET": "GÖKKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "MERKEZ",
        "STREET": "GÖKÇEPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "MERKEZ",
        "STREET": "GÜLABİBEY MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "MERKEZ",
        "STREET": "GÜNEY KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "MERKEZ",
        "STREET": "GÜVENLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "MERKEZ",
        "STREET": "GÜVEÇLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "MERKEZ",
        "STREET": "GÜZELYURT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "MERKEZ",
        "STREET": "HACIAHMETDERESİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "MERKEZ",
        "STREET": "HACIBEY KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "MERKEZ",
        "STREET": "HACIMUSA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "MERKEZ",
        "STREET": "HACIPAŞA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "MERKEZ",
        "STREET": "HAMAMLIÇAY KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "MERKEZ",
        "STREET": "HAMDİKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "MERKEZ",
        "STREET": "HANKOZLUSU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "MERKEZ",
        "STREET": "HARMANCIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "MERKEZ",
        "STREET": "HIMIROĞLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "MERKEZ",
        "STREET": "HIZIRDEDE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "MERKEZ",
        "STREET": "KADIDERESİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "MERKEZ",
        "STREET": "KADIKIRI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "MERKEZ",
        "STREET": "KALE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "MERKEZ",
        "STREET": "KALEHİSAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "MERKEZ",
        "STREET": "KARAAĞAÇ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "MERKEZ",
        "STREET": "KARABAYIR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "MERKEZ",
        "STREET": "KARABÜRÇEK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "MERKEZ",
        "STREET": "KARACA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "MERKEZ",
        "STREET": "KARACAÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "MERKEZ",
        "STREET": "KARADONA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "MERKEZ",
        "STREET": "KARAGÖZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "MERKEZ",
        "STREET": "KARAHİSAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "MERKEZ",
        "STREET": "KARAKEÇİLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "MERKEZ",
        "STREET": "KARAKEÇİLİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "MERKEZ",
        "STREET": "KARAPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "MERKEZ",
        "STREET": "KAVACIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "MERKEZ",
        "STREET": "KAYI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "MERKEZ",
        "STREET": "KAZIKLIKAYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "MERKEZ",
        "STREET": "KERTME KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "MERKEZ",
        "STREET": "KILIÇÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "MERKEZ",
        "STREET": "KINIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "MERKEZ",
        "STREET": "KINIKDELİLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "MERKEZ",
        "STREET": "KIRKDİLİM KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "MERKEZ",
        "STREET": "KIZILPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "MERKEZ",
        "STREET": "KONAKLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "MERKEZ",
        "STREET": "KOPARAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "MERKEZ",
        "STREET": "KOZLUCA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "MERKEZ",
        "STREET": "KUMÇELTİĞİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "MERKEZ",
        "STREET": "KUNDUZHAN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "MERKEZ",
        "STREET": "KURUÇAY KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "MERKEZ",
        "STREET": "KUTLUCA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "MERKEZ",
        "STREET": "KUŞSARAY KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "MERKEZ",
        "STREET": "KÖPRÜALAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "MERKEZ",
        "STREET": "KÜÇÜKDÜVENCİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "MERKEZ",
        "STREET": "KÜÇÜKGÜLÜCEK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "MERKEZ",
        "STREET": "KÜÇÜKPALABIYIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "MERKEZ",
        "STREET": "KİRANLIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "MERKEZ",
        "STREET": "KİRAZLIPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "MERKEZ",
        "STREET": "KİREÇOCAĞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "MERKEZ",
        "STREET": "LALOĞLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "MERKEZ",
        "STREET": "MECİDİYEKAVAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "MERKEZ",
        "STREET": "MOLLAHASAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "MERKEZ",
        "STREET": "MORSÜMBÜL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "MERKEZ",
        "STREET": "MUSTAFAÇELEBİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "MERKEZ",
        "STREET": "MÜHÜRLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "MERKEZ",
        "STREET": "MİMARSİNAN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "MERKEZ",
        "STREET": "MİSLEROVACIĞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "MERKEZ",
        "STREET": "NARLIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "MERKEZ",
        "STREET": "OSMANİYE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "MERKEZ",
        "STREET": "OVASARAY KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "MERKEZ",
        "STREET": "OYMAAĞAÇ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "MERKEZ",
        "STREET": "PALABIYIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "MERKEZ",
        "STREET": "PANCARLIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "MERKEZ",
        "STREET": "PAŞA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "MERKEZ",
        "STREET": "PEMBECİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "MERKEZ",
        "STREET": "PINARCIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "MERKEZ",
        "STREET": "PINARÇAY KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "MERKEZ",
        "STREET": "SALUR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "MERKEZ",
        "STREET": "SAPA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "MERKEZ",
        "STREET": "SARAYLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "MERKEZ",
        "STREET": "SARIKAYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "MERKEZ",
        "STREET": "SARILIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "MERKEZ",
        "STREET": "SARIMBEY KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "MERKEZ",
        "STREET": "SARIŞEYH KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "MERKEZ",
        "STREET": "SARMAŞA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "MERKEZ",
        "STREET": "SAZAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "MERKEZ",
        "STREET": "SAZDEĞİRMENİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "MERKEZ",
        "STREET": "SERBAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "MERKEZ",
        "STREET": "SERPİN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "MERKEZ",
        "STREET": "SEVİNDİKALANI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "MERKEZ",
        "STREET": "SEYDİM KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "MERKEZ",
        "STREET": "SEYDİMÇAKALLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "MERKEZ",
        "STREET": "SEYFE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "MERKEZ",
        "STREET": "SIRIKLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "MERKEZ",
        "STREET": "SOĞUKSU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "MERKEZ",
        "STREET": "TARHAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "MERKEZ",
        "STREET": "TARHANKOZLUSU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "MERKEZ",
        "STREET": "TATAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "MERKEZ",
        "STREET": "TAŞPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "MERKEZ",
        "STREET": "TESLİM KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "MERKEZ",
        "STREET": "TOLAMEHMET KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "MERKEZ",
        "STREET": "TOZLUBURUN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "MERKEZ",
        "STREET": "TURGUT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "MERKEZ",
        "STREET": "TÜRKAYŞE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "MERKEZ",
        "STREET": "TÜRKLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "MERKEZ",
        "STREET": "ULUKAVAK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "MERKEZ",
        "STREET": "UĞRAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "MERKEZ",
        "STREET": "YAKUPARPA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "MERKEZ",
        "STREET": "YAVRUTURNA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "MERKEZ",
        "STREET": "YAYDİĞİN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "MERKEZ",
        "STREET": "YENİCE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "MERKEZ",
        "STREET": "YENİHAYAT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "MERKEZ",
        "STREET": "YENİYOL MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "MERKEZ",
        "STREET": "YEŞİLDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "MERKEZ",
        "STREET": "YEŞİLYAYLA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "MERKEZ",
        "STREET": "YOĞUNPELİT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇAKIR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇALICA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇALKIŞLA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇALTICAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇALYAYLA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇANAKÇI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇATAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇAYHATAP KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇAĞŞAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇEPNİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇEŞMEÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇIKRIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇOBANDİVAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇOMARBAŞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇORAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇORUM KAPALI CEZAEVİ İNFAZ KURUMU",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇUKURÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "MERKEZ",
        "STREET": "ÇÖPLÜ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "MERKEZ",
        "STREET": "ÖKSÜZLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "MERKEZ",
        "STREET": "ÖMERBEY KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "MERKEZ",
        "STREET": "ÖRENCİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "MERKEZ",
        "STREET": "ÜLKENPINARI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "MERKEZ",
        "STREET": "ÜYÜK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "MERKEZ",
        "STREET": "ÜÇKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "MERKEZ",
        "STREET": "ÜÇTUTLAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "MERKEZ",
        "STREET": "İNALÖZÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "MERKEZ",
        "STREET": "İSMAİLKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "MERKEZ",
        "STREET": "İĞDELİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "MERKEZ",
        "STREET": "ŞAHİNKAYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "MERKEZ",
        "STREET": "ŞANLIOSMAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "MERKEZ",
        "STREET": "ŞEKERBEY KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "MERKEZ",
        "STREET": "ŞENDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "MERKEZ",
        "STREET": "ŞEYHHAMZA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "MERKEZ",
        "STREET": "ŞEYHMUSTAFA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "İSKİLİP",
        "STREET": "AHLATCIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "İSKİLİP",
        "STREET": "AHMETCE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "İSKİLİP",
        "STREET": "AKCASU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "İSKİLİP",
        "STREET": "AKPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "İSKİLİP",
        "STREET": "ALUÇ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "İSKİLİP",
        "STREET": "ASARCIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "İSKİLİP",
        "STREET": "AVHATYAKA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "İSKİLİP",
        "STREET": "AŞAĞIÖRENSEKİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "İSKİLİP",
        "STREET": "AŞAĞIŞEYHLER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "İSKİLİP",
        "STREET": "BAHABEY MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "İSKİLİP",
        "STREET": "BAŞMAKCI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "İSKİLİP",
        "STREET": "BEYOĞLAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "İSKİLİP",
        "STREET": "BÜYÜKTAŞ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "İSKİLİP",
        "STREET": "DAĞKIYISI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "İSKİLİP",
        "STREET": "DEREKARGIN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "İSKİLİP",
        "STREET": "DERESEKİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "İSKİLİP",
        "STREET": "DOĞANGİR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "İSKİLİP",
        "STREET": "EKİZOĞLU MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "İSKİLİP",
        "STREET": "ELMALI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "İSKİLİP",
        "STREET": "ERENLER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "İSKİLİP",
        "STREET": "ESKİKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "İSKİLİP",
        "STREET": "GÖL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "İSKİLİP",
        "STREET": "GÜNEYALUÇ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "İSKİLİP",
        "STREET": "HACIHALİL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "İSKİLİP",
        "STREET": "HACIPİRİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "İSKİLİP",
        "STREET": "HALLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "İSKİLİP",
        "STREET": "HARUN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "İSKİLİP",
        "STREET": "KALEBOĞAZI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "İSKİLİP",
        "STREET": "KARAAĞAÇ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "İSKİLİP",
        "STREET": "KARABURUN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "İSKİLİP",
        "STREET": "KARAGÖZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "İSKİLİP",
        "STREET": "KARAÇUKUR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "İSKİLİP",
        "STREET": "KARLIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "İSKİLİP",
        "STREET": "KARMIŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "İSKİLİP",
        "STREET": "KAVAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "İSKİLİP",
        "STREET": "KAYAAĞZI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "İSKİLİP",
        "STREET": "KILIÇDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "İSKİLİP",
        "STREET": "KIZILCABAYIR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "İSKİLİP",
        "STREET": "KURUSARAY KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "İSKİLİP",
        "STREET": "KUTLUÖZÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "İSKİLİP",
        "STREET": "KUZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "İSKİLİP",
        "STREET": "KUZULUK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "İSKİLİP",
        "STREET": "MEYDAN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "İSKİLİP",
        "STREET": "MUSULAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "İSKİLİP",
        "STREET": "MUTAFLAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "İSKİLİP",
        "STREET": "ONAÇ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "İSKİLİP",
        "STREET": "SAKARYA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "İSKİLİP",
        "STREET": "SARAYCIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "İSKİLİP",
        "STREET": "SARIKAVAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "İSKİLİP",
        "STREET": "SEKİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "İSKİLİP",
        "STREET": "SEYİRÇAYI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "İSKİLİP",
        "STREET": "SORKUN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "İSKİLİP",
        "STREET": "SOĞUCAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "İSKİLİP",
        "STREET": "SUHİLAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "İSKİLİP",
        "STREET": "TABAKHANE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "İSKİLİP",
        "STREET": "ULAŞTEPE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "İSKİLİP",
        "STREET": "ULUDERE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "İSKİLİP",
        "STREET": "YALAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "İSKİLİP",
        "STREET": "YALAKÇAY KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "İSKİLİP",
        "STREET": "YANOĞLAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "İSKİLİP",
        "STREET": "YAVU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "İSKİLİP",
        "STREET": "YAYLACIKSEKİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "İSKİLİP",
        "STREET": "YENİCAMİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "İSKİLİP",
        "STREET": "YENİCE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "İSKİLİP",
        "STREET": "YERLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "İSKİLİP",
        "STREET": "YUKARIÖRENSEKİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "İSKİLİP",
        "STREET": "ÇATKARA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "İSKİLİP",
        "STREET": "ÇAVUŞ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "İSKİLİP",
        "STREET": "ÇAVUŞOĞLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "İSKİLİP",
        "STREET": "ÇETMİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "İSKİLİP",
        "STREET": "ÇOMU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "İSKİLİP",
        "STREET": "ÇUKURKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "İSKİLİP",
        "STREET": "ÖRÜBAĞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "İSKİLİP",
        "STREET": "İBİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "İSKİLİP",
        "STREET": "İKİKİSE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "İSKİLİP",
        "STREET": "İKİPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "İSKİLİP",
        "STREET": "ŞEHİRKURUÇAY KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ÇORUM",
        "DISTRICT": "İSKİLİP",
        "STREET": "ŞEYH KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ADALAR",
        "STREET": "BURGAZADA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ADALAR",
        "STREET": "HEYBELİADA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ADALAR",
        "STREET": "KINALIADA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ADALAR",
        "STREET": "MADEN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ADALAR",
        "STREET": "NİZAM MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ARNAVUTKÖY",
        "STREET": "ADNAN MENDERES MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ARNAVUTKÖY",
        "STREET": "ANADOLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ARNAVUTKÖY",
        "STREET": "ARNAVUTKÖY MERKEZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ARNAVUTKÖY",
        "STREET": "BAKLALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ARNAVUTKÖY",
        "STREET": "BALABAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ARNAVUTKÖY",
        "STREET": "BOLLUCA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ARNAVUTKÖY",
        "STREET": "BOYALIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ARNAVUTKÖY",
        "STREET": "BOĞAZKÖY İSTİKLAL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ARNAVUTKÖY",
        "STREET": "DELİKLİKAYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ARNAVUTKÖY",
        "STREET": "DURSUNKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ARNAVUTKÖY",
        "STREET": "DURUSU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ARNAVUTKÖY",
        "STREET": "FATİH MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ARNAVUTKÖY",
        "STREET": "HACIMAŞLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ARNAVUTKÖY",
        "STREET": "HADIMKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ARNAVUTKÖY",
        "STREET": "HARAÇÇI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ARNAVUTKÖY",
        "STREET": "HASTANE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ARNAVUTKÖY",
        "STREET": "HİCRET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ARNAVUTKÖY",
        "STREET": "KARABURUN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ARNAVUTKÖY",
        "STREET": "KARLIBAYIR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ARNAVUTKÖY",
        "STREET": "MAREŞAL FEVZİ ÇAKMAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ARNAVUTKÖY",
        "STREET": "MAVİGÖL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ARNAVUTKÖY",
        "STREET": "MEHMET AKİF ERSOY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ARNAVUTKÖY",
        "STREET": "MERKEZ ATATÜRK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ARNAVUTKÖY",
        "STREET": "MUSTAFA KEMAL PAŞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ARNAVUTKÖY",
        "STREET": "NENEHATUN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ARNAVUTKÖY",
        "STREET": "SAZLIBOSNA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ARNAVUTKÖY",
        "STREET": "TAYAKADIN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ARNAVUTKÖY",
        "STREET": "TAŞOLUK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ARNAVUTKÖY",
        "STREET": "TERKOS MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ARNAVUTKÖY",
        "STREET": "YASSIÖREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ARNAVUTKÖY",
        "STREET": "YAVUZ SELİM MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ARNAVUTKÖY",
        "STREET": "YENİKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ARNAVUTKÖY",
        "STREET": "YEŞİLBAYIR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ARNAVUTKÖY",
        "STREET": "YUNUS EMRE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ARNAVUTKÖY",
        "STREET": "ÇİLİNGİR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ARNAVUTKÖY",
        "STREET": "ÖMERLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ARNAVUTKÖY",
        "STREET": "İMRAHOR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ARNAVUTKÖY",
        "STREET": "İSLAMBEY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ATAŞEHİR",
        "STREET": "ATATÜRK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ATAŞEHİR",
        "STREET": "AŞIKVEYSEL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ATAŞEHİR",
        "STREET": "BARBAROS MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ATAŞEHİR",
        "STREET": "ESATPAŞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ATAŞEHİR",
        "STREET": "FERHATPAŞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ATAŞEHİR",
        "STREET": "FETİH MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ATAŞEHİR",
        "STREET": "KAYIŞDAĞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ATAŞEHİR",
        "STREET": "KÜÇÜKBAKKALKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ATAŞEHİR",
        "STREET": "MEVLANA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ATAŞEHİR",
        "STREET": "MUSTAFA KEMAL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ATAŞEHİR",
        "STREET": "MİMAR SİNAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ATAŞEHİR",
        "STREET": "YENİ ÇAMLICA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ATAŞEHİR",
        "STREET": "YENİSAHRA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ATAŞEHİR",
        "STREET": "YENİŞEHİR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ATAŞEHİR",
        "STREET": "ÖRNEK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ATAŞEHİR",
        "STREET": "İNÖNÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ATAŞEHİR",
        "STREET": "İÇERENKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "AVCILAR",
        "STREET": "AMBARLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "AVCILAR",
        "STREET": "CİHANGİR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "AVCILAR",
        "STREET": "DENİZKÖŞKLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "AVCILAR",
        "STREET": "FİRUZKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "AVCILAR",
        "STREET": "GÜMÜŞPALA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "AVCILAR",
        "STREET": "MERKEZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "AVCILAR",
        "STREET": "MUSTAFA KEMALPAŞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "AVCILAR",
        "STREET": "TAHTAKALE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "AVCILAR",
        "STREET": "YEŞİLKENT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "AVCILAR",
        "STREET": "ÜNİVERSİTE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "BAHÇELİEVLER",
        "STREET": "BAHÇELİEVLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "BAHÇELİEVLER",
        "STREET": "CUMHURİYET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "BAHÇELİEVLER",
        "STREET": "FEVZİ ÇAKMAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "BAHÇELİEVLER",
        "STREET": "HÜRRİYET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "BAHÇELİEVLER",
        "STREET": "KOCASİNAN MERKEZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "BAHÇELİEVLER",
        "STREET": "SOĞANLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "BAHÇELİEVLER",
        "STREET": "SİYAVUŞPAŞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "BAHÇELİEVLER",
        "STREET": "YENİBOSNA MERKEZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "BAHÇELİEVLER",
        "STREET": "ZAFER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "BAHÇELİEVLER",
        "STREET": "ÇOBANÇEŞME MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "BAHÇELİEVLER",
        "STREET": "ŞİRİNEVLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "BAKIRKÖY",
        "STREET": "ATAKÖY 1. KISIM MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "BAKIRKÖY",
        "STREET": "ATAKÖY 2-5-6. KISIM MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "BAKIRKÖY",
        "STREET": "ATAKÖY 3-4-11. KISIM MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "BAKIRKÖY",
        "STREET": "ATAKÖY 7-8-9-10. KISIM MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "BAKIRKÖY",
        "STREET": "BAKIRKÖY KADIN KAPALI CEZA İNFAZ KURUMU",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "BAKIRKÖY",
        "STREET": "BASINKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "BAKIRKÖY",
        "STREET": "CEVİZLİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "BAKIRKÖY",
        "STREET": "KARTALTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "BAKIRKÖY",
        "STREET": "OSMANİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "BAKIRKÖY",
        "STREET": "SAKIZAĞACI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "BAKIRKÖY",
        "STREET": "YENİMAHALLE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "BAKIRKÖY",
        "STREET": "YEŞİLKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "BAKIRKÖY",
        "STREET": "YEŞİLYURT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "BAKIRKÖY",
        "STREET": "ZEYTİNLİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "BAKIRKÖY",
        "STREET": "ZUHURATBABA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "BAKIRKÖY",
        "STREET": "ŞENLİKKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "BAYRAMPAŞA",
        "STREET": "ALTINTEPSİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "BAYRAMPAŞA",
        "STREET": "CEVATPAŞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "BAYRAMPAŞA",
        "STREET": "KARTALTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "BAYRAMPAŞA",
        "STREET": "KOCATEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "BAYRAMPAŞA",
        "STREET": "MURATPAŞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "BAYRAMPAŞA",
        "STREET": "ORTA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "BAYRAMPAŞA",
        "STREET": "TERAZİDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "BAYRAMPAŞA",
        "STREET": "VATAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "BAYRAMPAŞA",
        "STREET": "YENİDOĞAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "BAYRAMPAŞA",
        "STREET": "YILDIRIM MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "BAYRAMPAŞA",
        "STREET": "İSMET PAŞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "BAĞCILAR",
        "STREET": "100. YIL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "BAĞCILAR",
        "STREET": "15 TEMMUZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "BAĞCILAR",
        "STREET": "BARBAROS MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "BAĞCILAR",
        "STREET": "BAĞLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "BAĞCILAR",
        "STREET": "DEMİRKAPI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "BAĞCILAR",
        "STREET": "FATİH MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "BAĞCILAR",
        "STREET": "FEVZİ ÇAKMAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "BAĞCILAR",
        "STREET": "GÖZTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "BAĞCILAR",
        "STREET": "GÜNEŞLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "BAĞCILAR",
        "STREET": "HÜRRİYET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "BAĞCILAR",
        "STREET": "KAZIM KARABEKİR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "BAĞCILAR",
        "STREET": "KEMALPAŞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "BAĞCILAR",
        "STREET": "KİRAZLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "BAĞCILAR",
        "STREET": "MAHMUTBEY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "BAĞCILAR",
        "STREET": "MERKEZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "BAĞCILAR",
        "STREET": "SANCAKTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "BAĞCILAR",
        "STREET": "YAVUZ SELİM MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "BAĞCILAR",
        "STREET": "YENİGÜN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "BAĞCILAR",
        "STREET": "YENİMAHALLE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "BAĞCILAR",
        "STREET": "YILDIZTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "BAĞCILAR",
        "STREET": "ÇINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "BAĞCILAR",
        "STREET": "İNÖNÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "BAŞAKŞEHİR",
        "STREET": "ALTINŞEHİR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "BAŞAKŞEHİR",
        "STREET": "BAHÇEŞEHİR 1. KISIM MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "BAŞAKŞEHİR",
        "STREET": "BAHÇEŞEHİR 2. KISIM MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "BAŞAKŞEHİR",
        "STREET": "BAŞAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "BAŞAKŞEHİR",
        "STREET": "BAŞAKŞEHİR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "BAŞAKŞEHİR",
        "STREET": "GÜVERCİNTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "BAŞAKŞEHİR",
        "STREET": "KAYABAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "BAŞAKŞEHİR",
        "STREET": "ZİYA GÖKALP MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "BAŞAKŞEHİR",
        "STREET": "ŞAHİNTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "BAŞAKŞEHİR",
        "STREET": "ŞAMLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "BEYKOZ",
        "STREET": "ACARLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "BEYKOZ",
        "STREET": "AKBABA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "BEYKOZ",
        "STREET": "ALİBAHADIR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "BEYKOZ",
        "STREET": "ANADOLU HİSARI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "BEYKOZ",
        "STREET": "ANADOLU KAVAĞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "BEYKOZ",
        "STREET": "ANADOLUFENERİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "BEYKOZ",
        "STREET": "BAKLACI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "BEYKOZ",
        "STREET": "BOZHANE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "BEYKOZ",
        "STREET": "CUMHURİYET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "BEYKOZ",
        "STREET": "DERESEKİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "BEYKOZ",
        "STREET": "ELMALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "BEYKOZ",
        "STREET": "FATİH MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "BEYKOZ",
        "STREET": "GÖKSU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "BEYKOZ",
        "STREET": "GÖLLÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "BEYKOZ",
        "STREET": "GÖRELE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "BEYKOZ",
        "STREET": "GÖZTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "BEYKOZ",
        "STREET": "GÜMÜŞSUYU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "BEYKOZ",
        "STREET": "KANLICA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "BEYKOZ",
        "STREET": "KAVACIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "BEYKOZ",
        "STREET": "KAYNARCA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "BEYKOZ",
        "STREET": "KILIÇLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "BEYKOZ",
        "STREET": "MAHMUTŞEVKETPAŞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "BEYKOZ",
        "STREET": "MERKEZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "BEYKOZ",
        "STREET": "ORTAÇEŞME MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "BEYKOZ",
        "STREET": "PAŞABAHÇE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "BEYKOZ",
        "STREET": "PAŞAMANDIRA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "BEYKOZ",
        "STREET": "POLONEZKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "BEYKOZ",
        "STREET": "POYRAZKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "BEYKOZ",
        "STREET": "RÜZGARLIBAHÇE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "BEYKOZ",
        "STREET": "RİVA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "BEYKOZ",
        "STREET": "SOĞUKSU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "BEYKOZ",
        "STREET": "TOKATKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "BEYKOZ",
        "STREET": "YALIKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "BEYKOZ",
        "STREET": "YAVUZ SELİM MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "BEYKOZ",
        "STREET": "YENİ MAHALLE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "BEYKOZ",
        "STREET": "ZERZAVATÇI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "BEYKOZ",
        "STREET": "ÇAMLIBAHÇE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "BEYKOZ",
        "STREET": "ÇENGELDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "BEYKOZ",
        "STREET": "ÇUBUKLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "BEYKOZ",
        "STREET": "ÇİFTLİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "BEYKOZ",
        "STREET": "ÇİĞDEM MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "BEYKOZ",
        "STREET": "ÖRNEKKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "BEYKOZ",
        "STREET": "ÖĞÜMCE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "BEYKOZ",
        "STREET": "İNCİRKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "BEYKOZ",
        "STREET": "İSHAKLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "BEYLİKDÜZÜ",
        "STREET": "ADNAN KAHVECİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "BEYLİKDÜZÜ",
        "STREET": "BARIŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "BEYLİKDÜZÜ",
        "STREET": "BÜYÜKŞEHİR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "BEYLİKDÜZÜ",
        "STREET": "CUMHURİYET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "BEYLİKDÜZÜ",
        "STREET": "DEREAĞZI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "BEYLİKDÜZÜ",
        "STREET": "GÜRPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "BEYLİKDÜZÜ",
        "STREET": "KAVAKLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "BEYLİKDÜZÜ",
        "STREET": "MARMARA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "BEYLİKDÜZÜ",
        "STREET": "SAHİL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "BEYLİKDÜZÜ",
        "STREET": "YAKUPLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "BEYOĞLU",
        "STREET": "ARAP CAMİ  MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "BEYOĞLU",
        "STREET": "ASMALI MESCİT  MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "BEYOĞLU",
        "STREET": "BEDRETTİN  MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "BEYOĞLU",
        "STREET": "BEREKETZADE  MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "BEYOĞLU",
        "STREET": "BOSTAN  MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "BEYOĞLU",
        "STREET": "BÜLBÜL  MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "BEYOĞLU",
        "STREET": "CAMİİKEBİR  MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "BEYOĞLU",
        "STREET": "CİHANGİR  MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "BEYOĞLU",
        "STREET": "EMEKYEMEZ  MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "BEYOĞLU",
        "STREET": "EVLİYA ÇELEBİ  MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "BEYOĞLU",
        "STREET": "FETİHTEPE  MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "BEYOĞLU",
        "STREET": "FİRUZAĞA  MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "BEYOĞLU",
        "STREET": "GÜMÜŞSUYU  MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "BEYOĞLU",
        "STREET": "HACIAHMET  MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "BEYOĞLU",
        "STREET": "HACIMİMİ  MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "BEYOĞLU",
        "STREET": "HALICIOĞLU  MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "BEYOĞLU",
        "STREET": "HÜSEYİNAĞA  MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "BEYOĞLU",
        "STREET": "KADIMEHMET EFENDİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "BEYOĞLU",
        "STREET": "KALYONCU KULLUĞU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "BEYOĞLU",
        "STREET": "KAMER HATUN  MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "BEYOĞLU",
        "STREET": "KAPTANPAŞA  MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "BEYOĞLU",
        "STREET": "KATİPMUSTAFA ÇELEBİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "BEYOĞLU",
        "STREET": "KEMANKEŞ KARAMUSTAFAPAŞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "BEYOĞLU",
        "STREET": "KEÇECİ PİRİ  MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "BEYOĞLU",
        "STREET": "KILIÇALİ PAŞA  MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "BEYOĞLU",
        "STREET": "KOCATEPE  MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "BEYOĞLU",
        "STREET": "KULAKSIZ  MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "BEYOĞLU",
        "STREET": "KULOĞLU  MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "BEYOĞLU",
        "STREET": "KÜÇÜK PİYALE  MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "BEYOĞLU",
        "STREET": "MÜEYYETZADE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "BEYOĞLU",
        "STREET": "PÜRTELAŞ HASAN EFENDİ  MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "BEYOĞLU",
        "STREET": "PİRİPAŞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "BEYOĞLU",
        "STREET": "PİYALEPAŞA  MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "BEYOĞLU",
        "STREET": "SURURİ MEHMET EFENDİ  MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "BEYOĞLU",
        "STREET": "SÜTLÜCE  MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "BEYOĞLU",
        "STREET": "TOMTOM  MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "BEYOĞLU",
        "STREET": "YAHYA KAHYA  MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "BEYOĞLU",
        "STREET": "YENİŞEHİR  MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "BEYOĞLU",
        "STREET": "ÇATMA MESCİT  MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "BEYOĞLU",
        "STREET": "ÇUKUR  MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "BEYOĞLU",
        "STREET": "ÖMER AVNİ  MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "BEYOĞLU",
        "STREET": "ÖRNEKTEPE  MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "BEYOĞLU",
        "STREET": "İSTİKLAL  MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "BEYOĞLU",
        "STREET": "ŞAHKULU  MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "BEYOĞLU",
        "STREET": "ŞEHİT MUHTAR  MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "BEŞİKTAŞ",
        "STREET": "ABBASAĞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "BEŞİKTAŞ",
        "STREET": "AKAT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "BEŞİKTAŞ",
        "STREET": "ARNAVUTKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "BEŞİKTAŞ",
        "STREET": "BALMUMCU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "BEŞİKTAŞ",
        "STREET": "BEBEK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "BEŞİKTAŞ",
        "STREET": "CİHANNÜMA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "BEŞİKTAŞ",
        "STREET": "DİKİLİTAŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "BEŞİKTAŞ",
        "STREET": "ETİLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "BEŞİKTAŞ",
        "STREET": "GAYRETTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "BEŞİKTAŞ",
        "STREET": "KONAKLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "BEŞİKTAŞ",
        "STREET": "KURUÇEŞME MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "BEŞİKTAŞ",
        "STREET": "KÜLTÜR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "BEŞİKTAŞ",
        "STREET": "LEVAZIM MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "BEŞİKTAŞ",
        "STREET": "LEVENT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "BEŞİKTAŞ",
        "STREET": "MECİDİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "BEŞİKTAŞ",
        "STREET": "MURADİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "BEŞİKTAŞ",
        "STREET": "NİSBETİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "BEŞİKTAŞ",
        "STREET": "ORTAKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "BEŞİKTAŞ",
        "STREET": "SİNANPAŞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "BEŞİKTAŞ",
        "STREET": "TÜRKALİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "BEŞİKTAŞ",
        "STREET": "ULUS MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "BEŞİKTAŞ",
        "STREET": "VİŞNEZADE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "BEŞİKTAŞ",
        "STREET": "YILDIZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "BÜYÜKÇEKMECE",
        "STREET": "19 MAYIS MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "BÜYÜKÇEKMECE",
        "STREET": "AHMEDİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "BÜYÜKÇEKMECE",
        "STREET": "ALKENT 2000 MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "BÜYÜKÇEKMECE",
        "STREET": "ATATÜRK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "BÜYÜKÇEKMECE",
        "STREET": "BAHÇELİEVLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "BÜYÜKÇEKMECE",
        "STREET": "CELALİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "BÜYÜKÇEKMECE",
        "STREET": "CUMHURİYET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "BÜYÜKÇEKMECE",
        "STREET": "DİZDARİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "BÜYÜKÇEKMECE",
        "STREET": "EKİNOBA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "BÜYÜKÇEKMECE",
        "STREET": "FATİH MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "BÜYÜKÇEKMECE",
        "STREET": "GÜZELCE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "BÜYÜKÇEKMECE",
        "STREET": "HÜRRİYET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "BÜYÜKÇEKMECE",
        "STREET": "KAMİLOBA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "BÜYÜKÇEKMECE",
        "STREET": "KARAAĞAÇ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "BÜYÜKÇEKMECE",
        "STREET": "KUMBURGAZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "BÜYÜKÇEKMECE",
        "STREET": "MURAT ÇESME MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "BÜYÜKÇEKMECE",
        "STREET": "MİMAROBA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "BÜYÜKÇEKMECE",
        "STREET": "MİMARSİNAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "BÜYÜKÇEKMECE",
        "STREET": "PINARTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "BÜYÜKÇEKMECE",
        "STREET": "SİNANOBA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "BÜYÜKÇEKMECE",
        "STREET": "TÜRKOBA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "BÜYÜKÇEKMECE",
        "STREET": "ULUS MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "BÜYÜKÇEKMECE",
        "STREET": "YENİMAHALLE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "BÜYÜKÇEKMECE",
        "STREET": "ÇAKMAKLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ESENLER",
        "STREET": "15 TEMMUZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ESENLER",
        "STREET": "BİRLİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ESENLER",
        "STREET": "DAVUTPAŞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ESENLER",
        "STREET": "FATİH MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ESENLER",
        "STREET": "FEVZİ ÇAKMAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ESENLER",
        "STREET": "HAVAALANI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ESENLER",
        "STREET": "KAZIM KARABEKİR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ESENLER",
        "STREET": "KEMER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ESENLER",
        "STREET": "MENDERES MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ESENLER",
        "STREET": "METRİS CEZA VE TUTUKEVİ",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ESENLER",
        "STREET": "MİMAR SİNAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ESENLER",
        "STREET": "NAMIK KEMAL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ESENLER",
        "STREET": "NİNE HATUN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ESENLER",
        "STREET": "ORUÇREİS MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ESENLER",
        "STREET": "TUNA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ESENLER",
        "STREET": "TURGUT REİS MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ESENLER",
        "STREET": "YAVUZ SELİM MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ESENLER",
        "STREET": "ÇİFTE HAVUZLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ESENYURT",
        "STREET": "AKEVLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ESENYURT",
        "STREET": "AKÇABURGAZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ESENYURT",
        "STREET": "AKŞEMSEDDİN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ESENYURT",
        "STREET": "ARDIÇLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ESENYURT",
        "STREET": "ATATÜRK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ESENYURT",
        "STREET": "AŞIK VEYSEL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ESENYURT",
        "STREET": "BALIKYOLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ESENYURT",
        "STREET": "BARBAROS HAYRETTİN PAŞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ESENYURT",
        "STREET": "BATTALGAZİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ESENYURT",
        "STREET": "BAĞLARÇEŞME MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ESENYURT",
        "STREET": "CUMHURİYET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ESENYURT",
        "STREET": "ESENKENT  MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ESENYURT",
        "STREET": "FATİH MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ESENYURT",
        "STREET": "GÖKEVLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ESENYURT",
        "STREET": "GÜZELYURT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ESENYURT",
        "STREET": "HÜRRİYET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ESENYURT",
        "STREET": "KOZA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ESENYURT",
        "STREET": "MEHMET AKİF ERSOY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ESENYURT",
        "STREET": "MEHTERÇEŞME MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ESENYURT",
        "STREET": "MEVLANA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ESENYURT",
        "STREET": "NAMIK KEMAL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ESENYURT",
        "STREET": "NECİP FAZIL KISAKÜREK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ESENYURT",
        "STREET": "ORHAN GAZİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ESENYURT",
        "STREET": "OSMANGAZİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ESENYURT",
        "STREET": "PINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ESENYURT",
        "STREET": "PİRİ REİS MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ESENYURT",
        "STREET": "SAADETDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ESENYURT",
        "STREET": "SELAHADDİN EYYUBİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ESENYURT",
        "STREET": "SULTANİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ESENYURT",
        "STREET": "SÜLEYMANİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ESENYURT",
        "STREET": "TALATPAŞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ESENYURT",
        "STREET": "TURGUT ÖZAL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ESENYURT",
        "STREET": "YENİKENT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ESENYURT",
        "STREET": "YEŞİLKENT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ESENYURT",
        "STREET": "YUNUS EMRE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ESENYURT",
        "STREET": "ZAFER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ESENYURT",
        "STREET": "ÇINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ESENYURT",
        "STREET": "ÖRNEK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ESENYURT",
        "STREET": "ÜÇEVLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ESENYURT",
        "STREET": "İNCİRTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ESENYURT",
        "STREET": "İNÖNÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ESENYURT",
        "STREET": "İSTİKLAL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ESENYURT",
        "STREET": "ŞEHİTLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "EYÜPSULTAN",
        "STREET": "5.LEVENT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "EYÜPSULTAN",
        "STREET": "AKPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "EYÜPSULTAN",
        "STREET": "AKŞEMSETTİN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "EYÜPSULTAN",
        "STREET": "ALİBEYKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "EYÜPSULTAN",
        "STREET": "AĞAÇLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "EYÜPSULTAN",
        "STREET": "DEFTERDAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "EYÜPSULTAN",
        "STREET": "DÜĞMECİLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "EYÜPSULTAN",
        "STREET": "EMNİYETTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "EYÜPSULTAN",
        "STREET": "ESENTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "EYÜPSULTAN",
        "STREET": "GÖKTÜRK MERKEZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "EYÜPSULTAN",
        "STREET": "GÜZELTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "EYÜPSULTAN",
        "STREET": "IŞIKLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "EYÜPSULTAN",
        "STREET": "KARADOLAP MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "EYÜPSULTAN",
        "STREET": "MERKEZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "EYÜPSULTAN",
        "STREET": "MİMAR SİNAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "EYÜPSULTAN",
        "STREET": "MİTHATPAŞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "EYÜPSULTAN",
        "STREET": "NİŞANCI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "EYÜPSULTAN",
        "STREET": "ODAYERİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "EYÜPSULTAN",
        "STREET": "PİRİNÇÇİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "EYÜPSULTAN",
        "STREET": "RAMİ CUMA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "EYÜPSULTAN",
        "STREET": "RAMİ YENİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "EYÜPSULTAN",
        "STREET": "SAKARYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "EYÜPSULTAN",
        "STREET": "SİLAHTARAĞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "EYÜPSULTAN",
        "STREET": "TOPÇULAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "EYÜPSULTAN",
        "STREET": "YEŞİLPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "EYÜPSULTAN",
        "STREET": "ÇIRÇIR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "EYÜPSULTAN",
        "STREET": "ÇİFTALAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "EYÜPSULTAN",
        "STREET": "İHSANİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "EYÜPSULTAN",
        "STREET": "İSLAMBEY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "FATİH",
        "STREET": "AKSARAY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "FATİH",
        "STREET": "AKŞEMSETTİN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "FATİH",
        "STREET": "ALEMDAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "FATİH",
        "STREET": "ALİ KUŞÇU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "FATİH",
        "STREET": "ATİKALİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "FATİH",
        "STREET": "AYVANSARAY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "FATİH",
        "STREET": "BALABANAĞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "FATİH",
        "STREET": "BALAT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "FATİH",
        "STREET": "BEYAZIT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "FATİH",
        "STREET": "BİNBİRDİREK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "FATİH",
        "STREET": "CANKURTARAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "FATİH",
        "STREET": "CERRAHPAŞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "FATİH",
        "STREET": "CİBALİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "FATİH",
        "STREET": "DEMİRTAŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "FATİH",
        "STREET": "DERVİŞ ALİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "FATİH",
        "STREET": "EMİN SİNAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "FATİH",
        "STREET": "HACI KADIN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "FATİH",
        "STREET": "HASEKİ SULTAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "FATİH",
        "STREET": "HIRKA-İ ŞERİF MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "FATİH",
        "STREET": "HOBYAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "FATİH",
        "STREET": "HOCA GIYASETTİN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "FATİH",
        "STREET": "HOCAPAŞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "FATİH",
        "STREET": "KALENDERHANE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "FATİH",
        "STREET": "KARAGÜMRÜK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "FATİH",
        "STREET": "KATİP KASIM MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "FATİH",
        "STREET": "KEMALPAŞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "FATİH",
        "STREET": "KOCA MUSTAFAPAŞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "FATİH",
        "STREET": "KÜÇÜK AYASOFYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "FATİH",
        "STREET": "MERCAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "FATİH",
        "STREET": "MESİHPAŞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "FATİH",
        "STREET": "MEVLANAKAPI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "FATİH",
        "STREET": "MOLLA FENARİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "FATİH",
        "STREET": "MOLLA GÜRANİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "FATİH",
        "STREET": "MOLLA HÜSREV MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "FATİH",
        "STREET": "MUHSİNE HATUN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "FATİH",
        "STREET": "MİMAR HAYRETTİN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "FATİH",
        "STREET": "MİMAR KEMALETTİN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "FATİH",
        "STREET": "NİŞANCA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "FATİH",
        "STREET": "RÜSTEMPAŞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "FATİH",
        "STREET": "SARAÇ İSHAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "FATİH",
        "STREET": "SEYYİD ÖMER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "FATİH",
        "STREET": "SULTAN AHMET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "FATİH",
        "STREET": "SURURİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "FATİH",
        "STREET": "SÜLEYMANİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "FATİH",
        "STREET": "SÜMBÜL EFENDİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "FATİH",
        "STREET": "SİLİVRİKAPI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "FATİH",
        "STREET": "TAHTAKALE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "FATİH",
        "STREET": "TAYA HATUN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "FATİH",
        "STREET": "TOPKAPI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "FATİH",
        "STREET": "YAVUZ SULTAN SELİM MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "FATİH",
        "STREET": "YAVUZ SİNAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "FATİH",
        "STREET": "YEDİKULE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "FATİH",
        "STREET": "ZEYREK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "FATİH",
        "STREET": "İSKENDERPAŞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "FATİH",
        "STREET": "ŞEHREMİNİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "FATİH",
        "STREET": "ŞEHSUVAR BEY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "GAZİOSMANPAŞA",
        "STREET": "BARBAROS HAYRETTİN PAŞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "GAZİOSMANPAŞA",
        "STREET": "BAĞLARBAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "GAZİOSMANPAŞA",
        "STREET": "FEVZİ ÇAKMAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "GAZİOSMANPAŞA",
        "STREET": "HÜRRİYET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "GAZİOSMANPAŞA",
        "STREET": "KARADENİZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "GAZİOSMANPAŞA",
        "STREET": "KARAYOLLARI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "GAZİOSMANPAŞA",
        "STREET": "KARLITEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "GAZİOSMANPAŞA",
        "STREET": "KAZIM KARABEKİR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "GAZİOSMANPAŞA",
        "STREET": "MERKEZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "GAZİOSMANPAŞA",
        "STREET": "MEVLANA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "GAZİOSMANPAŞA",
        "STREET": "PAZARİÇİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "GAZİOSMANPAŞA",
        "STREET": "SARIGÖL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "GAZİOSMANPAŞA",
        "STREET": "YENİ MAHALLE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "GAZİOSMANPAŞA",
        "STREET": "YENİDOĞAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "GAZİOSMANPAŞA",
        "STREET": "YILDIZTABYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "GAZİOSMANPAŞA",
        "STREET": "ŞEMSİPAŞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "GÜNGÖREN",
        "STREET": "ABDURRAHMAN NAFİZ GÜRMAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "GÜNGÖREN",
        "STREET": "AKINCILAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "GÜNGÖREN",
        "STREET": "GENÇOSMAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "GÜNGÖREN",
        "STREET": "GÜNEŞTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "GÜNGÖREN",
        "STREET": "GÜVEN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "GÜNGÖREN",
        "STREET": "HAZNEDAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "GÜNGÖREN",
        "STREET": "MAREŞAL ÇAKMAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "GÜNGÖREN",
        "STREET": "MEHMET NESİH ÖZMEN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "GÜNGÖREN",
        "STREET": "MERKEZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "GÜNGÖREN",
        "STREET": "SANAYİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "GÜNGÖREN",
        "STREET": "TOZKOPARAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "KADIKÖY",
        "STREET": "19 MAYIS MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "KADIKÖY",
        "STREET": "ACIBADEM MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "KADIKÖY",
        "STREET": "BOSTANCI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "KADIKÖY",
        "STREET": "CADDEBOSTAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "KADIKÖY",
        "STREET": "CAFERAĞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "KADIKÖY",
        "STREET": "DUMLUPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "KADIKÖY",
        "STREET": "ERENKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "KADIKÖY",
        "STREET": "EĞİTİM MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "KADIKÖY",
        "STREET": "FENERBAHÇE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "KADIKÖY",
        "STREET": "FENERYOLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "KADIKÖY",
        "STREET": "FİKİRTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "KADIKÖY",
        "STREET": "GÖZTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "KADIKÖY",
        "STREET": "HASANPAŞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "KADIKÖY",
        "STREET": "KOZYATAĞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "KADIKÖY",
        "STREET": "KOŞUYOLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "KADIKÖY",
        "STREET": "MERDİVENKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "KADIKÖY",
        "STREET": "OSMANAĞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "KADIKÖY",
        "STREET": "RASİMPAŞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "KADIKÖY",
        "STREET": "SAHRAYICEDİT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "KADIKÖY",
        "STREET": "SUADİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "KADIKÖY",
        "STREET": "ZÜHTÜPAŞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "KARTAL",
        "STREET": "ATALAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "KARTAL",
        "STREET": "CEVİZLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "KARTAL",
        "STREET": "CUMHURİYET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "KARTAL",
        "STREET": "ESENTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "KARTAL",
        "STREET": "GÜMÜŞPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "KARTAL",
        "STREET": "HÜRRİYET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "KARTAL",
        "STREET": "KARLIKTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "KARTAL",
        "STREET": "KORDONBOYU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "KARTAL",
        "STREET": "ORHANTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "KARTAL",
        "STREET": "ORTA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "KARTAL",
        "STREET": "PETROL İŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "KARTAL",
        "STREET": "SOĞANLIK YENİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "KARTAL",
        "STREET": "TOPSELVİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "KARTAL",
        "STREET": "UĞUR MUMCU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "KARTAL",
        "STREET": "YAKACIK YENİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "KARTAL",
        "STREET": "YAKACIK ÇARŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "KARTAL",
        "STREET": "YALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "KARTAL",
        "STREET": "YUKARI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "KARTAL",
        "STREET": "YUNUS MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "KARTAL",
        "STREET": "ÇAVUŞOĞLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "KAĞITHANE",
        "STREET": "EMNİYET EVLERİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "KAĞITHANE",
        "STREET": "GÜLTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "KAĞITHANE",
        "STREET": "GÜRSEL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "KAĞITHANE",
        "STREET": "HAMİDİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "KAĞITHANE",
        "STREET": "HARMANTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "KAĞITHANE",
        "STREET": "HÜRRİYET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "KAĞITHANE",
        "STREET": "MEHMET AKİF ERSOY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "KAĞITHANE",
        "STREET": "MERKEZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "KAĞITHANE",
        "STREET": "NURTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "KAĞITHANE",
        "STREET": "ORTABAYIR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "KAĞITHANE",
        "STREET": "SEYRANTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "KAĞITHANE",
        "STREET": "SULTAN SELİM MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "KAĞITHANE",
        "STREET": "TALATPAŞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "KAĞITHANE",
        "STREET": "TELSİZLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "KAĞITHANE",
        "STREET": "YAHYA KEMAL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "KAĞITHANE",
        "STREET": "YEŞİLCE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "KAĞITHANE",
        "STREET": "ÇAĞLAYAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "KAĞITHANE",
        "STREET": "ÇELİKTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "KAĞITHANE",
        "STREET": "ŞİRİNTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "KÜÇÜKÇEKMECE",
        "STREET": "ATAKENT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "KÜÇÜKÇEKMECE",
        "STREET": "ATATÜRK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "KÜÇÜKÇEKMECE",
        "STREET": "BEŞYOL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "KÜÇÜKÇEKMECE",
        "STREET": "CENNET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "KÜÇÜKÇEKMECE",
        "STREET": "CUMHURİYET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "KÜÇÜKÇEKMECE",
        "STREET": "FATİH MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "KÜÇÜKÇEKMECE",
        "STREET": "FEVZİ ÇAKMAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "KÜÇÜKÇEKMECE",
        "STREET": "GÜLTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "KÜÇÜKÇEKMECE",
        "STREET": "HALKALI MERKEZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "KÜÇÜKÇEKMECE",
        "STREET": "KANARYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "KÜÇÜKÇEKMECE",
        "STREET": "KARTALTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "KÜÇÜKÇEKMECE",
        "STREET": "KEMALPAŞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "KÜÇÜKÇEKMECE",
        "STREET": "MEHMET AKİF MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "KÜÇÜKÇEKMECE",
        "STREET": "SULTAN MURAT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "KÜÇÜKÇEKMECE",
        "STREET": "SÖĞÜTLÜ ÇEŞME MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "KÜÇÜKÇEKMECE",
        "STREET": "TEVFİKBEY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "KÜÇÜKÇEKMECE",
        "STREET": "YARIMBURGAZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "KÜÇÜKÇEKMECE",
        "STREET": "YENİ MAHALLE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "KÜÇÜKÇEKMECE",
        "STREET": "YEŞİLOVA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "KÜÇÜKÇEKMECE",
        "STREET": "İNÖNÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "KÜÇÜKÇEKMECE",
        "STREET": "İSTASYON MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "MALTEPE",
        "STREET": "ALTAYÇEŞME MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "MALTEPE",
        "STREET": "ALTINTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "MALTEPE",
        "STREET": "AYDINEVLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "MALTEPE",
        "STREET": "BAĞLARBAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "MALTEPE",
        "STREET": "BAŞIBÜYÜK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "MALTEPE",
        "STREET": "BÜYÜKBAKKALKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "MALTEPE",
        "STREET": "CEVİZLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "MALTEPE",
        "STREET": "ESENKENT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "MALTEPE",
        "STREET": "FEYZULLAH MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "MALTEPE",
        "STREET": "FINDIKLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "MALTEPE",
        "STREET": "GÜLENSU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "MALTEPE",
        "STREET": "GÜLSUYU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "MALTEPE",
        "STREET": "GİRNE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "MALTEPE",
        "STREET": "KÜÇÜKYALI MERKEZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "MALTEPE",
        "STREET": "MALTEPE 1 NOLU L TİPİ KAPALI CEZA İNFAZ KURUMU",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "MALTEPE",
        "STREET": "MALTEPE 2 NOLU L TİPİ KAPALI CEZA İNFAZ KURUMU",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "MALTEPE",
        "STREET": "MALTEPE ÇOCUK VE GENÇLİK KAPALI CEZA İNFAZ KURUMU",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "MALTEPE",
        "STREET": "YALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "MALTEPE",
        "STREET": "ZÜMRÜTEVLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "MALTEPE",
        "STREET": "ÇINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "MALTEPE",
        "STREET": "İDEALTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "PENDİK",
        "STREET": "AHMET YESEVİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "PENDİK",
        "STREET": "BAHÇELİEVLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "PENDİK",
        "STREET": "BALLICA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "PENDİK",
        "STREET": "BATI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "PENDİK",
        "STREET": "DOĞU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "PENDİK",
        "STREET": "DUMLUPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "PENDİK",
        "STREET": "EMİRLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "PENDİK",
        "STREET": "ERTUĞRUL GAZİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "PENDİK",
        "STREET": "ESENLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "PENDİK",
        "STREET": "ESENYALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "PENDİK",
        "STREET": "FATİH MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "PENDİK",
        "STREET": "FEVZİ ÇAKMAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "PENDİK",
        "STREET": "GÖÇBEYLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "PENDİK",
        "STREET": "GÜLLÜ BAĞLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "PENDİK",
        "STREET": "GÜZELYALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "PENDİK",
        "STREET": "HARMANDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "PENDİK",
        "STREET": "KARTAL H TİPİ KAPALI CEZA İNFAZ KURUMU",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "PENDİK",
        "STREET": "KAVAKPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "PENDİK",
        "STREET": "KAYNARCA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "PENDİK",
        "STREET": "KURNA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "PENDİK",
        "STREET": "KURTDOĞMUŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "PENDİK",
        "STREET": "KURTKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "PENDİK",
        "STREET": "ORHANGAZİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "PENDİK",
        "STREET": "ORTA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "PENDİK",
        "STREET": "RAMAZANOĞLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "PENDİK",
        "STREET": "SANAYİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "PENDİK",
        "STREET": "SAPAN BAĞLARI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "PENDİK",
        "STREET": "SÜLÜNTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "PENDİK",
        "STREET": "VELİBABA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "PENDİK",
        "STREET": "YAYALAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "PENDİK",
        "STREET": "YENİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "PENDİK",
        "STREET": "YENİŞEHİR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "PENDİK",
        "STREET": "YEŞİLBAĞLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "PENDİK",
        "STREET": "ÇAMLIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "PENDİK",
        "STREET": "ÇAMÇEŞME MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "PENDİK",
        "STREET": "ÇINARDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "PENDİK",
        "STREET": "ŞEYHLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "SANCAKTEPE",
        "STREET": "ABDURRAHMANGAZİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "SANCAKTEPE",
        "STREET": "AKPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "SANCAKTEPE",
        "STREET": "ATATÜRK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "SANCAKTEPE",
        "STREET": "EMEK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "SANCAKTEPE",
        "STREET": "EYÜP SULTAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "SANCAKTEPE",
        "STREET": "FATİH MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "SANCAKTEPE",
        "STREET": "HİLAL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "SANCAKTEPE",
        "STREET": "KEMAL TÜRKLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "SANCAKTEPE",
        "STREET": "MECLİS MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "SANCAKTEPE",
        "STREET": "MERVE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "SANCAKTEPE",
        "STREET": "MEVLANA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "SANCAKTEPE",
        "STREET": "OSMANGAZİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "SANCAKTEPE",
        "STREET": "PAŞAKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "SANCAKTEPE",
        "STREET": "SAFA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "SANCAKTEPE",
        "STREET": "SARIGAZİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "SANCAKTEPE",
        "STREET": "VEYSEL KARANİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "SANCAKTEPE",
        "STREET": "YENİDOĞAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "SANCAKTEPE",
        "STREET": "YUNUS EMRE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "SANCAKTEPE",
        "STREET": "İNÖNÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "SARIYER",
        "STREET": "AYAZAĞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "SARIYER",
        "STREET": "BAHÇEKÖY KEMER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "SARIYER",
        "STREET": "BAHÇEKÖY MERKEZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "SARIYER",
        "STREET": "BAHÇEKÖY YENİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "SARIYER",
        "STREET": "BALTALİMANI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "SARIYER",
        "STREET": "BÜYÜKDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "SARIYER",
        "STREET": "CUMHURİYET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "SARIYER",
        "STREET": "DARÜŞŞAFAKA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "SARIYER",
        "STREET": "DEMİRCİKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "SARIYER",
        "STREET": "EMİRGAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "SARIYER",
        "STREET": "FATİH SULTAN MEHMET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "SARIYER",
        "STREET": "FERAHEVLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "SARIYER",
        "STREET": "GARİPÇE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "SARIYER",
        "STREET": "GÜMÜŞDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "SARIYER",
        "STREET": "HUZUR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "SARIYER",
        "STREET": "KAZIM KARABEKİR PAŞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "SARIYER",
        "STREET": "KISIRKAYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "SARIYER",
        "STREET": "KOCATAŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "SARIYER",
        "STREET": "KUMKÖY (KİLYOS) MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "SARIYER",
        "STREET": "KİREÇBURNU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "SARIYER",
        "STREET": "MADEN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "SARIYER",
        "STREET": "MASLAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "SARIYER",
        "STREET": "PINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "SARIYER",
        "STREET": "POLİGON MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "SARIYER",
        "STREET": "PTT EVLERİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "SARIYER",
        "STREET": "REŞİTPAŞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "SARIYER",
        "STREET": "RUMELİFENERİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "SARIYER",
        "STREET": "RUMELİHİSARI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "SARIYER",
        "STREET": "RUMELİKAVAĞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "SARIYER",
        "STREET": "SARIYER MERKEZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "SARIYER",
        "STREET": "TARABYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "SARIYER",
        "STREET": "USKUMRUKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "SARIYER",
        "STREET": "YENİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "SARIYER",
        "STREET": "YENİKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "SARIYER",
        "STREET": "ZEKERİYAKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "SARIYER",
        "STREET": "ÇAMLITEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "SARIYER",
        "STREET": "ÇAYIRBAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "SARIYER",
        "STREET": "İSTİNYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "SULTANBEYLİ",
        "STREET": "ABDURRAHMANGAZİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "SULTANBEYLİ",
        "STREET": "ADİL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "SULTANBEYLİ",
        "STREET": "AHMET YESEVİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "SULTANBEYLİ",
        "STREET": "AKŞEMSETTİN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "SULTANBEYLİ",
        "STREET": "BATTALGAZİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "SULTANBEYLİ",
        "STREET": "FATİH  MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "SULTANBEYLİ",
        "STREET": "HAMİDİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "SULTANBEYLİ",
        "STREET": "HASANPAŞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "SULTANBEYLİ",
        "STREET": "MECİDİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "SULTANBEYLİ",
        "STREET": "MEHMET AKİF MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "SULTANBEYLİ",
        "STREET": "MİMAR SİNAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "SULTANBEYLİ",
        "STREET": "NECİP FAZIL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "SULTANBEYLİ",
        "STREET": "ORHANGAZİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "SULTANBEYLİ",
        "STREET": "TURGUT REİS MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "SULTANBEYLİ",
        "STREET": "YAVUZ SELİM MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "SULTANGAZİ",
        "STREET": "50. YIL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "SULTANGAZİ",
        "STREET": "75. YIL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "SULTANGAZİ",
        "STREET": "CEBECİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "SULTANGAZİ",
        "STREET": "CUMHURİYET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "SULTANGAZİ",
        "STREET": "ESENTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "SULTANGAZİ",
        "STREET": "ESKİ HABİPLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "SULTANGAZİ",
        "STREET": "GAZİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "SULTANGAZİ",
        "STREET": "HABİBLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "SULTANGAZİ",
        "STREET": "MALKOÇOĞLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "SULTANGAZİ",
        "STREET": "SULTANÇİFTLİĞİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "SULTANGAZİ",
        "STREET": "UĞUR MUMCU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "SULTANGAZİ",
        "STREET": "YAYLA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "SULTANGAZİ",
        "STREET": "YUNUS EMRE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "SULTANGAZİ",
        "STREET": "ZÜBEYDE HANIM MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "SULTANGAZİ",
        "STREET": "İSMETPAŞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "SİLİVRİ",
        "STREET": "AKÖREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "SİLİVRİ",
        "STREET": "ALİBEY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "SİLİVRİ",
        "STREET": "ALİPAŞA  MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "SİLİVRİ",
        "STREET": "BEKİRLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "SİLİVRİ",
        "STREET": "BEYCİLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "SİLİVRİ",
        "STREET": "BÜYÜK KILIÇLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "SİLİVRİ",
        "STREET": "BÜYÜK SİNEKLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "SİLİVRİ",
        "STREET": "BÜYÜK ÇAVUŞLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "SİLİVRİ",
        "STREET": "CUMHURİYET  MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "SİLİVRİ",
        "STREET": "DANAMANDIRA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "SİLİVRİ",
        "STREET": "DEĞİRMENKÖY FEVZİPAŞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "SİLİVRİ",
        "STREET": "DEĞİRMENKÖY İSMETPAŞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "SİLİVRİ",
        "STREET": "FATİH MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "SİLİVRİ",
        "STREET": "FENER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "SİLİVRİ",
        "STREET": "GAZİTEPE  MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "SİLİVRİ",
        "STREET": "GÜMÜŞYAKA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "SİLİVRİ",
        "STREET": "KADIKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "SİLİVRİ",
        "STREET": "KAVAKLI HÜRRİYET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "SİLİVRİ",
        "STREET": "KAVAKLI İSTİKLAL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "SİLİVRİ",
        "STREET": "KURFALLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "SİLİVRİ",
        "STREET": "KÜÇÜK KILIÇLI  MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "SİLİVRİ",
        "STREET": "KÜÇÜK SİNEKLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "SİLİVRİ",
        "STREET": "MARMARA 1 NOLU L TİPİ KAPALI CEZA İNFAZ KURUMU",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "SİLİVRİ",
        "STREET": "MARMARA 2 NOLU L TİPİ KAPALI CEZA İNFAZ KURUMU",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "SİLİVRİ",
        "STREET": "MARMARA 3 NOLU L TİPİ KAPALI CEZA İNFAZ KURUMU",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "SİLİVRİ",
        "STREET": "MARMARA 4 NOLU L TİPİ KAPALI CEZA İNFAZ KURUMU",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "SİLİVRİ",
        "STREET": "MARMARA 5 NOLU L TİPİ KAPALI CEZA İNFAZ KURUMU",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "SİLİVRİ",
        "STREET": "MARMARA 6 NOLU L TİPİ KAPALI CEZA İNFAZ KURUMU",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "SİLİVRİ",
        "STREET": "MARMARA 7 NOLU L TİPİ KAPALI CEZA İNFAZ KURUMU",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "SİLİVRİ",
        "STREET": "MARMARA 8 NOLU L TİPİ KAPALI CEZA İNFAZ KURUMU",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "SİLİVRİ",
        "STREET": "MARMARA KADIN KAPALI CEZA İNFAZ KURUMU",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "SİLİVRİ",
        "STREET": "MARMARA KAPALI CEZA İNFAZ KURUMU",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "SİLİVRİ",
        "STREET": "MİMAR SİNAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "SİLİVRİ",
        "STREET": "ORTAKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "SİLİVRİ",
        "STREET": "PİRİ MEHMET PAŞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "SİLİVRİ",
        "STREET": "SAYALAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "SİLİVRİ",
        "STREET": "SELİMPAŞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "SİLİVRİ",
        "STREET": "SEMİZKUMLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "SİLİVRİ",
        "STREET": "SEYMEN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "SİLİVRİ",
        "STREET": "YENİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "SİLİVRİ",
        "STREET": "YOLÇATI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "SİLİVRİ",
        "STREET": "ÇANTA BALABAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "SİLİVRİ",
        "STREET": "ÇANTA SANCAKTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "SİLİVRİ",
        "STREET": "ÇAYIRDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "SİLİVRİ",
        "STREET": "ÇELTİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "TUZLA",
        "STREET": "AKFIRAT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "TUZLA",
        "STREET": "ANADOLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "TUZLA",
        "STREET": "AYDINLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "TUZLA",
        "STREET": "AYDINTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "TUZLA",
        "STREET": "CAMİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "TUZLA",
        "STREET": "EVLİYA ÇELEBİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "TUZLA",
        "STREET": "FATİH MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "TUZLA",
        "STREET": "MESCİT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "TUZLA",
        "STREET": "MİMAR SİNAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "TUZLA",
        "STREET": "ORHANLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "TUZLA",
        "STREET": "ORTA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "TUZLA",
        "STREET": "POSTANE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "TUZLA",
        "STREET": "TEPEÖREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "TUZLA",
        "STREET": "YAYLA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "TUZLA",
        "STREET": "İSTASYON MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "TUZLA",
        "STREET": "İÇMELER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "TUZLA",
        "STREET": "ŞİFA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ZEYTİNBURNU",
        "STREET": "BEŞTELSİZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ZEYTİNBURNU",
        "STREET": "GÖKALP MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ZEYTİNBURNU",
        "STREET": "KAZLIÇEŞME MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ZEYTİNBURNU",
        "STREET": "MALTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ZEYTİNBURNU",
        "STREET": "MERKEZEFENDİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ZEYTİNBURNU",
        "STREET": "NURİPAŞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ZEYTİNBURNU",
        "STREET": "SEYİTNİZAM MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ZEYTİNBURNU",
        "STREET": "SÜMER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ZEYTİNBURNU",
        "STREET": "TELSİZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ZEYTİNBURNU",
        "STREET": "VELİEFENDİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ZEYTİNBURNU",
        "STREET": "YENİDOĞAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ZEYTİNBURNU",
        "STREET": "YEŞİLTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ZEYTİNBURNU",
        "STREET": "ÇIRPICI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ÇATALCA",
        "STREET": "AKALAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ÇATALCA",
        "STREET": "ATATÜRK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ÇATALCA",
        "STREET": "AYDINLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ÇATALCA",
        "STREET": "BAHŞAYİŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ÇATALCA",
        "STREET": "BAŞAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ÇATALCA",
        "STREET": "BELGRAT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ÇATALCA",
        "STREET": "CELEPKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ÇATALCA",
        "STREET": "DAĞYENİCE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ÇATALCA",
        "STREET": "ELBASAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ÇATALCA",
        "STREET": "FATİH MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ÇATALCA",
        "STREET": "FERHATPAŞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ÇATALCA",
        "STREET": "GÖKÇEALİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ÇATALCA",
        "STREET": "GÜMÜŞPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ÇATALCA",
        "STREET": "HALLAÇLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ÇATALCA",
        "STREET": "HİSARBEYLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ÇATALCA",
        "STREET": "KABAKÇA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ÇATALCA",
        "STREET": "KALEİÇİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ÇATALCA",
        "STREET": "KALFA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ÇATALCA",
        "STREET": "KARACAKÖY MERKEZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ÇATALCA",
        "STREET": "KARAMANDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ÇATALCA",
        "STREET": "KESTANELİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ÇATALCA",
        "STREET": "KIZILCAALİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ÇATALCA",
        "STREET": "MURATBEY MERKEZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ÇATALCA",
        "STREET": "NAKKAŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ÇATALCA",
        "STREET": "OKLALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ÇATALCA",
        "STREET": "ORMANLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ÇATALCA",
        "STREET": "OVAYENİCE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ÇATALCA",
        "STREET": "SUBAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ÇATALCA",
        "STREET": "YALIKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ÇATALCA",
        "STREET": "YAYLACIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ÇATALCA",
        "STREET": "YAZLIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ÇATALCA",
        "STREET": "ÇAKIL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ÇATALCA",
        "STREET": "ÇANAKÇA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ÇATALCA",
        "STREET": "ÇİFTLİKKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ÇATALCA",
        "STREET": "ÖRCÜNLÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ÇATALCA",
        "STREET": "ÖRENCİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ÇATALCA",
        "STREET": "İHSANİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ÇATALCA",
        "STREET": "İNCEĞİZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ÇATALCA",
        "STREET": "İZZETTİN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ÇEKMEKÖY",
        "STREET": "ALEMDAĞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ÇEKMEKÖY",
        "STREET": "AYDINLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ÇEKMEKÖY",
        "STREET": "CUMHURİYET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ÇEKMEKÖY",
        "STREET": "EKŞİOĞLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ÇEKMEKÖY",
        "STREET": "GÜNGÖREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ÇEKMEKÖY",
        "STREET": "HAMİDİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ÇEKMEKÖY",
        "STREET": "HÜSEYİNLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ÇEKMEKÖY",
        "STREET": "KOÇULLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ÇEKMEKÖY",
        "STREET": "KİRAZLIDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ÇEKMEKÖY",
        "STREET": "MEHMET AKİF MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ÇEKMEKÖY",
        "STREET": "MERKEZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ÇEKMEKÖY",
        "STREET": "MİMAR SİNAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ÇEKMEKÖY",
        "STREET": "NİŞANTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ÇEKMEKÖY",
        "STREET": "REŞADİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ÇEKMEKÖY",
        "STREET": "SIRAPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ÇEKMEKÖY",
        "STREET": "SOĞUKPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ÇEKMEKÖY",
        "STREET": "SULTANÇİFTLİĞİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ÇEKMEKÖY",
        "STREET": "TAŞDELEN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ÇEKMEKÖY",
        "STREET": "ÇAMLIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ÇEKMEKÖY",
        "STREET": "ÇATALMEŞE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ÇEKMEKÖY",
        "STREET": "ÖMERLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ÜMRANİYE",
        "STREET": "ADEM YAVUZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ÜMRANİYE",
        "STREET": "ALTINŞEHİR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ÜMRANİYE",
        "STREET": "ARMAĞANEVLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ÜMRANİYE",
        "STREET": "ATAKENT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ÜMRANİYE",
        "STREET": "ATATÜRK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ÜMRANİYE",
        "STREET": "AŞAĞI DUDULLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ÜMRANİYE",
        "STREET": "CEMİL MERİÇ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ÜMRANİYE",
        "STREET": "DUMLUPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ÜMRANİYE",
        "STREET": "ELMALIKENT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ÜMRANİYE",
        "STREET": "ESENEVLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ÜMRANİYE",
        "STREET": "ESENKENT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ÜMRANİYE",
        "STREET": "ESENŞEHİR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ÜMRANİYE",
        "STREET": "FATİH SULTAN MEHMET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ÜMRANİYE",
        "STREET": "FİNANSKENT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ÜMRANİYE",
        "STREET": "HEKİMBAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ÜMRANİYE",
        "STREET": "HUZUR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ÜMRANİYE",
        "STREET": "IHLAMURKUYU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ÜMRANİYE",
        "STREET": "KAZIM KARABEKİR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ÜMRANİYE",
        "STREET": "MADENLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ÜMRANİYE",
        "STREET": "MEHMET AKİF MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ÜMRANİYE",
        "STREET": "NAMIK KEMAL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ÜMRANİYE",
        "STREET": "NECİP FAZIL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ÜMRANİYE",
        "STREET": "PARSELLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ÜMRANİYE",
        "STREET": "SARAY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ÜMRANİYE",
        "STREET": "SİTE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ÜMRANİYE",
        "STREET": "TANTAVİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ÜMRANİYE",
        "STREET": "TATLISU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ÜMRANİYE",
        "STREET": "TEPEÜSTÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ÜMRANİYE",
        "STREET": "TOPAĞACI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ÜMRANİYE",
        "STREET": "YAMANEVLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ÜMRANİYE",
        "STREET": "YENİŞEHİR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ÜMRANİYE",
        "STREET": "YUKARI DUDULLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ÜMRANİYE",
        "STREET": "ÇAKMAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ÜMRANİYE",
        "STREET": "ÇAMLIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ÜMRANİYE",
        "STREET": "ÜMRANİYE T TİPİ KAPALI CEZAEVİ",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ÜMRANİYE",
        "STREET": "İNKILAP MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ÜMRANİYE",
        "STREET": "İSTİKLAL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ÜMRANİYE",
        "STREET": "ŞERİFALİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ÜSKÜDAR",
        "STREET": "ACIBADEM MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ÜSKÜDAR",
        "STREET": "AHMEDİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ÜSKÜDAR",
        "STREET": "ALTUNİZADE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ÜSKÜDAR",
        "STREET": "AZİZ MAHMUT HÜDAYİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ÜSKÜDAR",
        "STREET": "BAHÇELİEVLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ÜSKÜDAR",
        "STREET": "BARBAROS  MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ÜSKÜDAR",
        "STREET": "BEYLERBEYİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ÜSKÜDAR",
        "STREET": "BULGURLU  MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ÜSKÜDAR",
        "STREET": "BURHANİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ÜSKÜDAR",
        "STREET": "CUMHURİYET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ÜSKÜDAR",
        "STREET": "FERAH  MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ÜSKÜDAR",
        "STREET": "GÜZELTEPE  MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ÜSKÜDAR",
        "STREET": "KANDİLLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ÜSKÜDAR",
        "STREET": "KISIKLI  MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ÜSKÜDAR",
        "STREET": "KULELİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ÜSKÜDAR",
        "STREET": "KUZGUNCUK  MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ÜSKÜDAR",
        "STREET": "KÜPLÜCE  MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ÜSKÜDAR",
        "STREET": "KÜÇÜK ÇAMLICA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ÜSKÜDAR",
        "STREET": "KÜÇÜKSU  MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ÜSKÜDAR",
        "STREET": "KİRAZLITEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ÜSKÜDAR",
        "STREET": "MEHMET AKİF ERSOY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ÜSKÜDAR",
        "STREET": "MURATREİS MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ÜSKÜDAR",
        "STREET": "MİMAR SİNAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ÜSKÜDAR",
        "STREET": "PAŞAKAPISI KAPALI CEZA İNFAZ KURUMU",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ÜSKÜDAR",
        "STREET": "SALACAK  MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ÜSKÜDAR",
        "STREET": "SELAMİ ALİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ÜSKÜDAR",
        "STREET": "SELİMİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ÜSKÜDAR",
        "STREET": "SULTANTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ÜSKÜDAR",
        "STREET": "VALİDE-İ ATİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ÜSKÜDAR",
        "STREET": "YAVUZTÜRK  MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ÜSKÜDAR",
        "STREET": "ZEYNEP KAMİL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ÜSKÜDAR",
        "STREET": "ÇENGELKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ÜSKÜDAR",
        "STREET": "ÜNALAN  MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ÜSKÜDAR",
        "STREET": "İCADİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ŞİLE",
        "STREET": "AHMETLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ŞİLE",
        "STREET": "AKÇAKESE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ŞİLE",
        "STREET": "ALACALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ŞİLE",
        "STREET": "AVCIKORU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ŞİLE",
        "STREET": "AĞAÇDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ŞİLE",
        "STREET": "AĞVA MERKEZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ŞİLE",
        "STREET": "BALİBEY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ŞİLE",
        "STREET": "BIÇKIDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ŞİLE",
        "STREET": "BOZGOCA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ŞİLE",
        "STREET": "BUCAKLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ŞİLE",
        "STREET": "DARLIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ŞİLE",
        "STREET": "DEĞİRMENÇAYIRI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ŞİLE",
        "STREET": "DOĞANCILI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ŞİLE",
        "STREET": "ERENLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ŞİLE",
        "STREET": "ESENCELİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ŞİLE",
        "STREET": "GEREDELİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ŞİLE",
        "STREET": "GÖKMAŞLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ŞİLE",
        "STREET": "GÖKSU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ŞİLE",
        "STREET": "GÖÇE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ŞİLE",
        "STREET": "HACI KASIM MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ŞİLE",
        "STREET": "HACILLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ŞİLE",
        "STREET": "HASANLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ŞİLE",
        "STREET": "KABAKOZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ŞİLE",
        "STREET": "KADIKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ŞİLE",
        "STREET": "KALEM MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ŞİLE",
        "STREET": "KARABEYLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ŞİLE",
        "STREET": "KARACAKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ŞİLE",
        "STREET": "KARAKİRAZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ŞİLE",
        "STREET": "KARAMANDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ŞİLE",
        "STREET": "KERVANSARAY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ŞİLE",
        "STREET": "KIZILCA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ŞİLE",
        "STREET": "KORUCU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ŞİLE",
        "STREET": "KUMBABA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ŞİLE",
        "STREET": "KURFALLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ŞİLE",
        "STREET": "KURNA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ŞİLE",
        "STREET": "KÖMÜRLÜK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ŞİLE",
        "STREET": "MEŞRUTİYET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ŞİLE",
        "STREET": "ORUÇOĞLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ŞİLE",
        "STREET": "OSMANKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ŞİLE",
        "STREET": "OVACIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ŞİLE",
        "STREET": "SAHİLKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ŞİLE",
        "STREET": "SATMAZLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ŞİLE",
        "STREET": "SOFULAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ŞİLE",
        "STREET": "SORTULLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ŞİLE",
        "STREET": "SOĞULLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ŞİLE",
        "STREET": "TEKE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ŞİLE",
        "STREET": "ULUPELİT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ŞİLE",
        "STREET": "YAKA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ŞİLE",
        "STREET": "YAYLALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ŞİLE",
        "STREET": "YAZIMANAYIR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ŞİLE",
        "STREET": "YENİKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ŞİLE",
        "STREET": "YEŞİLVADİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ŞİLE",
        "STREET": "ÇATAKLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ŞİLE",
        "STREET": "ÇAVUŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ŞİLE",
        "STREET": "ÇAYIRBAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ŞİLE",
        "STREET": "ÇELEBİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ŞİLE",
        "STREET": "ÇENGİLLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ŞİLE",
        "STREET": "ÜVEZLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ŞİLE",
        "STREET": "İMRENDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ŞİLE",
        "STREET": "İMRENLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ŞİLE",
        "STREET": "İSAKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ŞİLE",
        "STREET": "ŞUAYİPLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ŞİŞLİ",
        "STREET": "19 MAYIS MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ŞİŞLİ",
        "STREET": "BOZKURT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ŞİŞLİ",
        "STREET": "CUMHURİYET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ŞİŞLİ",
        "STREET": "DUATEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ŞİŞLİ",
        "STREET": "ERGENEKON MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ŞİŞLİ",
        "STREET": "ESENTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ŞİŞLİ",
        "STREET": "ESKİŞEHİR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ŞİŞLİ",
        "STREET": "FERİKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ŞİŞLİ",
        "STREET": "FULYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ŞİŞLİ",
        "STREET": "GÜLBAHAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ŞİŞLİ",
        "STREET": "HALASKARGAZİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ŞİŞLİ",
        "STREET": "HALİDE EDİP ADIVAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ŞİŞLİ",
        "STREET": "HALİL RIFAT PAŞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ŞİŞLİ",
        "STREET": "HARBİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ŞİŞLİ",
        "STREET": "KAPTANPAŞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ŞİŞLİ",
        "STREET": "KUŞTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ŞİŞLİ",
        "STREET": "MAHMUT ŞEVKET PAŞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ŞİŞLİ",
        "STREET": "MECİDİYEKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ŞİŞLİ",
        "STREET": "MERKEZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ŞİŞLİ",
        "STREET": "MEŞRUTİYET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ŞİŞLİ",
        "STREET": "PAŞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ŞİŞLİ",
        "STREET": "TEŞVİKİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ŞİŞLİ",
        "STREET": "YAYLA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ŞİŞLİ",
        "STREET": "İNÖNÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İSTANBUL",
        "DISTRICT": "ŞİŞLİ",
        "STREET": "İZZET PAŞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "ALİAĞA",
        "STREET": "ATATÜRK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "ALİAĞA",
        "STREET": "AŞAĞIŞAKRAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "ALİAĞA",
        "STREET": "B.HAYRETTİN PAŞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "ALİAĞA",
        "STREET": "BAHÇEDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "ALİAĞA",
        "STREET": "BOZKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "ALİAĞA",
        "STREET": "FATİH MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "ALİAĞA",
        "STREET": "GÜZELHİSAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "ALİAĞA",
        "STREET": "HACIÖMERLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "ALİAĞA",
        "STREET": "HOROZGEDİĞİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "ALİAĞA",
        "STREET": "KALABAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "ALİAĞA",
        "STREET": "KAPUKAYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "ALİAĞA",
        "STREET": "KARAKUZU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "ALİAĞA",
        "STREET": "KARAKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "ALİAĞA",
        "STREET": "KAZIM DİRİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "ALİAĞA",
        "STREET": "KURTULUŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "ALİAĞA",
        "STREET": "KÜLTÜR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "ALİAĞA",
        "STREET": "MİMAR SİNAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "ALİAĞA",
        "STREET": "SAMURLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "ALİAĞA",
        "STREET": "SİTELER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "ALİAĞA",
        "STREET": "UZUNHASANLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "ALİAĞA",
        "STREET": "YALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "ALİAĞA",
        "STREET": "YENİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "ALİAĞA",
        "STREET": "YENİŞAKRAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "ALİAĞA",
        "STREET": "YUKARI ŞEHİTKEMAL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "ALİAĞA",
        "STREET": "YÜKSEKKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "ALİAĞA",
        "STREET": "ÇAKMAKLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "ALİAĞA",
        "STREET": "ÇALTILIDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "ALİAĞA",
        "STREET": "ÇITAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "ALİAĞA",
        "STREET": "ÇORAKLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "ALİAĞA",
        "STREET": "İZMİR 1 NOLU T TİPİ KAPALI CEZA İNFAZ KURUMU",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "ALİAĞA",
        "STREET": "İZMİR 2 NOLU T TİPİ KAPALI CEZA İNFAZ KURUMU",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "ALİAĞA",
        "STREET": "İZMİR 3 NOLU T TİPİ KAPALI CEZA İNFAZ KURUMU",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "ALİAĞA",
        "STREET": "İZMİR 4 NOLU T TİPİ KAPALI CEZA İNFAZ KURUMU",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "ALİAĞA",
        "STREET": "İZMİR KADIN KAPALI CEZA İNFAZ KURUMU",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "ALİAĞA",
        "STREET": "İZMİR ÇOCUK VE GENÇLİK KAPALI CEZA İNFAZ KURUMU",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "ALİAĞA",
        "STREET": "ŞEHİTKEMAL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BALÇOVA",
        "STREET": "BAHÇELERARASI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BALÇOVA",
        "STREET": "EĞİTİM MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BALÇOVA",
        "STREET": "FEVZİ ÇAKMAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BALÇOVA",
        "STREET": "KORUTÜRK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BALÇOVA",
        "STREET": "ONUR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BALÇOVA",
        "STREET": "TELEFERİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BALÇOVA",
        "STREET": "ÇETİN EMEÇ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BALÇOVA",
        "STREET": "İNCİRALTI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BAYINDIR",
        "STREET": "ALANKIYI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BAYINDIR",
        "STREET": "ALANKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BAYINDIR",
        "STREET": "ARIKBAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BAYINDIR",
        "STREET": "ATATÜRK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BAYINDIR",
        "STREET": "BALCILAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BAYINDIR",
        "STREET": "BIYIKLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BAYINDIR",
        "STREET": "BURUNCUK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BAYINDIR",
        "STREET": "CAMİİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BAYINDIR",
        "STREET": "CUMHURİYET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BAYINDIR",
        "STREET": "DEMİRCİLİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BAYINDIR",
        "STREET": "DEREKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BAYINDIR",
        "STREET": "DERNEKLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BAYINDIR",
        "STREET": "ELİFLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BAYINDIR",
        "STREET": "ERGENLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BAYINDIR",
        "STREET": "FATİH MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BAYINDIR",
        "STREET": "FIRINLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BAYINDIR",
        "STREET": "GAZİLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BAYINDIR",
        "STREET": "HACI BEŞİR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BAYINDIR",
        "STREET": "HACI İBRAHİM MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BAYINDIR",
        "STREET": "HASKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BAYINDIR",
        "STREET": "HATAY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BAYINDIR",
        "STREET": "HAVUZBAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BAYINDIR",
        "STREET": "HİSARLIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BAYINDIR",
        "STREET": "KABAAĞAÇ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BAYINDIR",
        "STREET": "KARAHALİLLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BAYINDIR",
        "STREET": "KARAHAYIT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BAYINDIR",
        "STREET": "KARAPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BAYINDIR",
        "STREET": "KARAVELİLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BAYINDIR",
        "STREET": "KIZILCAAĞAÇ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BAYINDIR",
        "STREET": "KIZILCAOVA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BAYINDIR",
        "STREET": "KIZILKEÇİLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BAYINDIR",
        "STREET": "KIZILOBA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BAYINDIR",
        "STREET": "KURT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BAYINDIR",
        "STREET": "LÜTUFLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BAYINDIR",
        "STREET": "MEKTEP MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BAYINDIR",
        "STREET": "MİTHATPAŞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BAYINDIR",
        "STREET": "NECATİ UZA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BAYINDIR",
        "STREET": "ORTA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BAYINDIR",
        "STREET": "OSMANLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BAYINDIR",
        "STREET": "PINARLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BAYINDIR",
        "STREET": "SADIKPAŞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BAYINDIR",
        "STREET": "SARIYURT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BAYINDIR",
        "STREET": "SÖĞÜTÖREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BAYINDIR",
        "STREET": "TOKATBAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BAYINDIR",
        "STREET": "TURAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BAYINDIR",
        "STREET": "YAKACIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BAYINDIR",
        "STREET": "YAKAPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BAYINDIR",
        "STREET": "YENİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BAYINDIR",
        "STREET": "YENİCE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BAYINDIR",
        "STREET": "YEŞİLOVA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BAYINDIR",
        "STREET": "YUSUFLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BAYINDIR",
        "STREET": "ZEYTİNOVA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BAYINDIR",
        "STREET": "ÇAMLIBEL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BAYINDIR",
        "STREET": "ÇENİKLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BAYINDIR",
        "STREET": "ÇINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BAYINDIR",
        "STREET": "ÇINARDİBİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BAYINDIR",
        "STREET": "ÇIRPI CAMİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BAYINDIR",
        "STREET": "ÇİFTÇİGEDİĞİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BAYINDIR",
        "STREET": "İBRAHİMÇAVUŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BAYRAKLI",
        "STREET": "75. YIL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BAYRAKLI",
        "STREET": "ADALET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BAYRAKLI",
        "STREET": "ALPASLAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BAYRAKLI",
        "STREET": "BAYRAKLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BAYRAKLI",
        "STREET": "CENGİZHAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BAYRAKLI",
        "STREET": "DOĞANÇAY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BAYRAKLI",
        "STREET": "EMEK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BAYRAKLI",
        "STREET": "FUAT EDİP BAKSI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BAYRAKLI",
        "STREET": "GÜMÜŞPALA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BAYRAKLI",
        "STREET": "KÖRFEZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BAYRAKLI",
        "STREET": "MANAVKUYU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BAYRAKLI",
        "STREET": "MANSUROĞLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BAYRAKLI",
        "STREET": "MUHİTTİN ERENER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BAYRAKLI",
        "STREET": "ONUR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BAYRAKLI",
        "STREET": "ORG.NAFİZ GÜRMAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BAYRAKLI",
        "STREET": "OSMANGAZİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BAYRAKLI",
        "STREET": "POSTACILAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BAYRAKLI",
        "STREET": "R.ŞEVKET İNCE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BAYRAKLI",
        "STREET": "SOĞUKKUYU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BAYRAKLI",
        "STREET": "TEPEKULE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BAYRAKLI",
        "STREET": "TURAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BAYRAKLI",
        "STREET": "YAMANLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BAYRAKLI",
        "STREET": "ÇAY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BAYRAKLI",
        "STREET": "ÇİÇEK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BERGAMA",
        "STREET": "AHMETBEYLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BERGAMA",
        "STREET": "AKÇENGER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BERGAMA",
        "STREET": "ALACALAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BERGAMA",
        "STREET": "ALHATLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BERGAMA",
        "STREET": "ALİBEYLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BERGAMA",
        "STREET": "ARMAĞANLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BERGAMA",
        "STREET": "ATATÜRK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BERGAMA",
        "STREET": "ATMACA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BERGAMA",
        "STREET": "ATÇILAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BERGAMA",
        "STREET": "AVUNDUK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BERGAMA",
        "STREET": "AVUNDURUK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BERGAMA",
        "STREET": "AYASKENT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BERGAMA",
        "STREET": "AYVATLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BERGAMA",
        "STREET": "AZİZİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BERGAMA",
        "STREET": "AŞAĞIBEY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BERGAMA",
        "STREET": "AŞAĞICUMA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BERGAMA",
        "STREET": "AŞAĞIILGINDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BERGAMA",
        "STREET": "AŞAĞIKIRIKLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BERGAMA",
        "STREET": "BAHÇELİEVLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BERGAMA",
        "STREET": "BALABAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BERGAMA",
        "STREET": "BARBAROS MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BERGAMA",
        "STREET": "BAYRAMCILAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BERGAMA",
        "STREET": "BEKİRLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BERGAMA",
        "STREET": "BERGAMA M TİPİ KAPALI CEZA İNFAZ KURUMU MÜDÜRLÜĞÜ",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BERGAMA",
        "STREET": "BOZKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BERGAMA",
        "STREET": "BOZYERLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BERGAMA",
        "STREET": "BÖLCEK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BERGAMA",
        "STREET": "CEVAPLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BERGAMA",
        "STREET": "DAĞISTAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BERGAMA",
        "STREET": "DEMİRCİDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BERGAMA",
        "STREET": "DEREKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BERGAMA",
        "STREET": "DOĞANCI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BERGAMA",
        "STREET": "DURMUŞLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BERGAMA",
        "STREET": "ERTUĞRUL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BERGAMA",
        "STREET": "EĞRİGÖL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BERGAMA",
        "STREET": "EĞİLLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BERGAMA",
        "STREET": "FATİH MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BERGAMA",
        "STREET": "FERİZLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BERGAMA",
        "STREET": "FEVZİPAŞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BERGAMA",
        "STREET": "GAYLAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BERGAMA",
        "STREET": "GAZİOSMANPAŞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BERGAMA",
        "STREET": "GAZİPAŞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BERGAMA",
        "STREET": "GÖBELLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BERGAMA",
        "STREET": "GÖKÇEYURT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BERGAMA",
        "STREET": "GÖÇBEYLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BERGAMA",
        "STREET": "GÜLTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BERGAMA",
        "STREET": "GÜNEŞLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BERGAMA",
        "STREET": "HACIHAMZALAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BERGAMA",
        "STREET": "HACILAR (DEREKÖY BUCAĞI) MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BERGAMA",
        "STREET": "HACILAR (İSMAİLLİ BUCAĞI) MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BERGAMA",
        "STREET": "HALİLAĞALAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BERGAMA",
        "STREET": "HAMZALISÜLEYMANİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BERGAMA",
        "STREET": "HİSARKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BERGAMA",
        "STREET": "ILGINCABER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BERGAMA",
        "STREET": "KADIKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BERGAMA",
        "STREET": "KADRİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BERGAMA",
        "STREET": "KALEARDI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BERGAMA",
        "STREET": "KAPLAN (GÖÇBEYLİ BUCAĞI) MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BERGAMA",
        "STREET": "KAPLAN (YUKARIBEY BUCAĞI) MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BERGAMA",
        "STREET": "KAPUKAYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BERGAMA",
        "STREET": "KARAHIDIRLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BERGAMA",
        "STREET": "KARALAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BERGAMA",
        "STREET": "KARAVELİLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BERGAMA",
        "STREET": "KATRANCI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BERGAMA",
        "STREET": "KAŞIKÇI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BERGAMA",
        "STREET": "KIRANLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BERGAMA",
        "STREET": "KIRCALAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BERGAMA",
        "STREET": "KIZILTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BERGAMA",
        "STREET": "KOCAHALİLLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BERGAMA",
        "STREET": "KOCAKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BERGAMA",
        "STREET": "KOYUNELİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BERGAMA",
        "STREET": "KOZLUCA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BERGAMA",
        "STREET": "KURFALLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BERGAMA",
        "STREET": "KURTULUŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BERGAMA",
        "STREET": "MAHMUDİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BERGAMA",
        "STREET": "MALTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BERGAMA",
        "STREET": "MARUFLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BERGAMA",
        "STREET": "MURATLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BERGAMA",
        "STREET": "NARLICA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BERGAMA",
        "STREET": "OKÇULAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BERGAMA",
        "STREET": "ORUŞLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BERGAMA",
        "STREET": "OVACIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BERGAMA",
        "STREET": "PAŞAKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BERGAMA",
        "STREET": "PINARKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BERGAMA",
        "STREET": "PİREVELİLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BERGAMA",
        "STREET": "RAHMANLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BERGAMA",
        "STREET": "SARICALAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BERGAMA",
        "STREET": "SARICAOĞLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BERGAMA",
        "STREET": "SARIDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BERGAMA",
        "STREET": "SAĞANCI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BERGAMA",
        "STREET": "SEKLİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BERGAMA",
        "STREET": "SELÇUK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BERGAMA",
        "STREET": "SÜLEYMANLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BERGAMA",
        "STREET": "SİNDEL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BERGAMA",
        "STREET": "TALATPAŞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BERGAMA",
        "STREET": "TAVUKÇUKURU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BERGAMA",
        "STREET": "TEKKEDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BERGAMA",
        "STREET": "TEPEKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BERGAMA",
        "STREET": "TERZİHALİLLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BERGAMA",
        "STREET": "TIRMANLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BERGAMA",
        "STREET": "TOPALLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BERGAMA",
        "STREET": "TURABEY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BERGAMA",
        "STREET": "TİYELTİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BERGAMA",
        "STREET": "ULUCAMİİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BERGAMA",
        "STREET": "YALNIZDAM MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BERGAMA",
        "STREET": "YALNIZEV MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BERGAMA",
        "STREET": "YENİKENT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BERGAMA",
        "STREET": "YENİLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BERGAMA",
        "STREET": "YERLİTAHTACI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BERGAMA",
        "STREET": "YORTANLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BERGAMA",
        "STREET": "YUKARIADA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BERGAMA",
        "STREET": "YUKARIBEY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BERGAMA",
        "STREET": "YUKARICUMA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BERGAMA",
        "STREET": "YUKARIKIRIKLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BERGAMA",
        "STREET": "ZAFER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BERGAMA",
        "STREET": "ZAĞNOZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BERGAMA",
        "STREET": "ZEYTİNDAĞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BERGAMA",
        "STREET": "ÇAKIRLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BERGAMA",
        "STREET": "ÇALIBAHÇE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BERGAMA",
        "STREET": "ÇALTIKORU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BERGAMA",
        "STREET": "ÇAMAVLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BERGAMA",
        "STREET": "ÇAMKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BERGAMA",
        "STREET": "ÇAMOBA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BERGAMA",
        "STREET": "ÇELTİKÇİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BERGAMA",
        "STREET": "ÇOBANLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BERGAMA",
        "STREET": "ÇÜRÜKBAĞLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BERGAMA",
        "STREET": "ÇİTKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BERGAMA",
        "STREET": "ÖKSÜZLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BERGAMA",
        "STREET": "ÖRENLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BERGAMA",
        "STREET": "ÖRLEMİŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BERGAMA",
        "STREET": "ÜRKÜTLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BERGAMA",
        "STREET": "ÜÇTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BERGAMA",
        "STREET": "İKİZLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BERGAMA",
        "STREET": "İNCECİKLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BERGAMA",
        "STREET": "İNEŞİR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BERGAMA",
        "STREET": "İNKILAP MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BERGAMA",
        "STREET": "İSLAMSARAY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BERGAMA",
        "STREET": "İSMAİLLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BEYDAĞ",
        "STREET": "ADAKÜRE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BEYDAĞ",
        "STREET": "AKTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BEYDAĞ",
        "STREET": "ALAKEÇİLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BEYDAĞ",
        "STREET": "ATATÜRK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BEYDAĞ",
        "STREET": "BAKIRKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BEYDAĞ",
        "STREET": "BEYKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BEYDAĞ",
        "STREET": "CUMHURİYET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BEYDAĞ",
        "STREET": "ERİKLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BEYDAĞ",
        "STREET": "EĞRİDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BEYDAĞ",
        "STREET": "HALIKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BEYDAĞ",
        "STREET": "KARAOBA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BEYDAĞ",
        "STREET": "KURUDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BEYDAĞ",
        "STREET": "MENDERES MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BEYDAĞ",
        "STREET": "MUTAFLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BEYDAĞ",
        "STREET": "PALAMUTÇUK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BEYDAĞ",
        "STREET": "SARIKAYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BEYDAĞ",
        "STREET": "TABAKLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BEYDAĞ",
        "STREET": "YAĞCILAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BEYDAĞ",
        "STREET": "YENİYURT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BEYDAĞ",
        "STREET": "YEŞİLTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BEYDAĞ",
        "STREET": "YUKARIAKTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BEYDAĞ",
        "STREET": "YUKARITOSUNLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BEYDAĞ",
        "STREET": "ÇAMLIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BEYDAĞ",
        "STREET": "ÇOMAKLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BEYDAĞ",
        "STREET": "ÇİFTLİKKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BORNOVA",
        "STREET": "ATATÜRK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BORNOVA",
        "STREET": "BARBAROS MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BORNOVA",
        "STREET": "BEŞYOL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BORNOVA",
        "STREET": "BİRLİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BORNOVA",
        "STREET": "DOĞANLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BORNOVA",
        "STREET": "EGEMENLİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BORNOVA",
        "STREET": "ERGENE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BORNOVA",
        "STREET": "ERZENE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BORNOVA",
        "STREET": "EVKA 3 MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BORNOVA",
        "STREET": "EVKA 4 MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BORNOVA",
        "STREET": "EĞRİDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BORNOVA",
        "STREET": "GAZİ OSMAN PAŞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BORNOVA",
        "STREET": "GÖKDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BORNOVA",
        "STREET": "GÜRPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BORNOVA",
        "STREET": "IŞIKLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BORNOVA",
        "STREET": "KARACAOĞLAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BORNOVA",
        "STREET": "KARAÇAM MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BORNOVA",
        "STREET": "KAVAKLIDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BORNOVA",
        "STREET": "KAYADİBİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BORNOVA",
        "STREET": "KAZIMDİRİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BORNOVA",
        "STREET": "KEMALPAŞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BORNOVA",
        "STREET": "KIZILAY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BORNOVA",
        "STREET": "KOŞUKAVAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BORNOVA",
        "STREET": "KURUDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BORNOVA",
        "STREET": "LAKA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BORNOVA",
        "STREET": "MERKEZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BORNOVA",
        "STREET": "MERİÇ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BORNOVA",
        "STREET": "MEVLANA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BORNOVA",
        "STREET": "NALDÖKEN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BORNOVA",
        "STREET": "RAFET PAŞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BORNOVA",
        "STREET": "SARNIÇKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BORNOVA",
        "STREET": "SERİNTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BORNOVA",
        "STREET": "TUNA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BORNOVA",
        "STREET": "YAKAKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BORNOVA",
        "STREET": "YEŞİLOVA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BORNOVA",
        "STREET": "YEŞİLÇAM MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BORNOVA",
        "STREET": "YILDIRIM BEYAZIT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BORNOVA",
        "STREET": "YUNUS EMRE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BORNOVA",
        "STREET": "ZAFER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BORNOVA",
        "STREET": "ÇAMKULE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BORNOVA",
        "STREET": "ÇAMİÇİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BORNOVA",
        "STREET": "ÇINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BORNOVA",
        "STREET": "ÇİÇEKLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BORNOVA",
        "STREET": "ÜMİT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BORNOVA",
        "STREET": "İNÖNÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BUCA",
        "STREET": "29 EKİM MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BUCA",
        "STREET": "ADATEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BUCA",
        "STREET": "AKINCILAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BUCA",
        "STREET": "ATATÜRK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BUCA",
        "STREET": "AYDOĞDU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BUCA",
        "STREET": "BARIŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BUCA",
        "STREET": "BELENBAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BUCA",
        "STREET": "BUCA KOOP MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BUCA",
        "STREET": "BUCA YÜKSEK GÜVENLİKLİ KAPALI CEZA İNFAZ KURUMU",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BUCA",
        "STREET": "CUMHURİYET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BUCA",
        "STREET": "DOĞANCILAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BUCA",
        "STREET": "DUMLUPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BUCA",
        "STREET": "DİCLE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BUCA",
        "STREET": "EFELER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BUCA",
        "STREET": "FIRAT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BUCA",
        "STREET": "GAZİLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BUCA",
        "STREET": "GÖKSU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BUCA",
        "STREET": "GÜVEN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BUCA",
        "STREET": "HÜRRİYET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BUCA",
        "STREET": "KARACAAĞAÇ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BUCA",
        "STREET": "KARANFİL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BUCA",
        "STREET": "KAYNAKLAR MERKEZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BUCA",
        "STREET": "KIRKLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BUCA",
        "STREET": "KOZAĞAÇ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BUCA",
        "STREET": "KURUÇEŞME MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BUCA",
        "STREET": "LALELİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BUCA",
        "STREET": "MENDERES MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BUCA",
        "STREET": "MURATHAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BUCA",
        "STREET": "MUSTAFA KEMAL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BUCA",
        "STREET": "SEYHAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BUCA",
        "STREET": "UFUK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BUCA",
        "STREET": "VALİ RAHMİ BEY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BUCA",
        "STREET": "YAYLACIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BUCA",
        "STREET": "YENİGÜN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BUCA",
        "STREET": "YEŞİLBAĞLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BUCA",
        "STREET": "YILDIZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BUCA",
        "STREET": "YILDIZLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BUCA",
        "STREET": "YİĞİTLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BUCA",
        "STREET": "ZAFER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BUCA",
        "STREET": "ÇALDIRAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BUCA",
        "STREET": "ÇAMLIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BUCA",
        "STREET": "ÇAMLIKULE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BUCA",
        "STREET": "ÇAMLIPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BUCA",
        "STREET": "ÇAĞDAŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BUCA",
        "STREET": "İNKILAP MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BUCA",
        "STREET": "İNÖNÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BUCA",
        "STREET": "İZKENT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BUCA",
        "STREET": "İZMİR 1 NOLU F TİPİ YÜKSEK GÜVENLİKLİ CEZA İNFAZ K",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BUCA",
        "STREET": "İZMİR 2 NOLU F TİPİ YÜKSEK GÜVENLİKLİ CEZA İNFAZ K",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "BUCA",
        "STREET": "ŞİRİNKAPI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "DİKİLİ",
        "STREET": "BADEMLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "DİKİLİ",
        "STREET": "BAHÇELİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "DİKİLİ",
        "STREET": "CUMHURİYET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "DİKİLİ",
        "STREET": "DELİKTAŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "DİKİLİ",
        "STREET": "DEMİRTAŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "DİKİLİ",
        "STREET": "DENİZKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "DİKİLİ",
        "STREET": "ESENTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "DİKİLİ",
        "STREET": "GAZİPAŞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "DİKİLİ",
        "STREET": "GÖKÇEAĞIL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "DİKİLİ",
        "STREET": "KABAKUM MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "DİKİLİ",
        "STREET": "KATIRALANI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "DİKİLİ",
        "STREET": "KIRATLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "DİKİLİ",
        "STREET": "KIROBA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "DİKİLİ",
        "STREET": "KIZILÇUKUR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "DİKİLİ",
        "STREET": "KOCAOBA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "DİKİLİ",
        "STREET": "MAZILI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "DİKİLİ",
        "STREET": "MERDİVENLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "DİKİLİ",
        "STREET": "NEBİLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "DİKİLİ",
        "STREET": "SALİHLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "DİKİLİ",
        "STREET": "SALİMBEY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "DİKİLİ",
        "STREET": "SAMANLIKKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "DİKİLİ",
        "STREET": "UZUNBURUN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "DİKİLİ",
        "STREET": "YAHŞİBEY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "DİKİLİ",
        "STREET": "YAYLAYURT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "DİKİLİ",
        "STREET": "YENİCE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "DİKİLİ",
        "STREET": "ÇANDARLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "DİKİLİ",
        "STREET": "ÇAĞLAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "DİKİLİ",
        "STREET": "ÇUKURALANI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "DİKİLİ",
        "STREET": "İSLAMLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "DİKİLİ",
        "STREET": "İSMETPAŞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "FOÇA",
        "STREET": "ATATÜRK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "FOÇA",
        "STREET": "CUMHURİYET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "FOÇA",
        "STREET": "FATİH MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "FOÇA",
        "STREET": "FEVZİ ÇAKMAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "FOÇA",
        "STREET": "FEVZİPAŞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "FOÇA",
        "STREET": "HACIVELİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "FOÇA",
        "STREET": "ILIPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "FOÇA",
        "STREET": "KAZIM DİRİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "FOÇA",
        "STREET": "KEMAL ATATÜRK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "FOÇA",
        "STREET": "KOCA MEHMETLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "FOÇA",
        "STREET": "KOZBEYLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "FOÇA",
        "STREET": "MAREŞAL FEVZİ ÇAKMAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "FOÇA",
        "STREET": "MUSTAFA KEMAL ATATÜRK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "FOÇA",
        "STREET": "YENİBAĞARASI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "FOÇA",
        "STREET": "YENİKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "FOÇA",
        "STREET": "İSMETPAŞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "GAZİEMİR",
        "STREET": "AKTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "GAZİEMİR",
        "STREET": "ATATÜRK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "GAZİEMİR",
        "STREET": "ATIFBEY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "GAZİEMİR",
        "STREET": "BEYAZEVLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "GAZİEMİR",
        "STREET": "BİNBAŞI REŞATBEY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "GAZİEMİR",
        "STREET": "DOKUZ EYLÜL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "GAZİEMİR",
        "STREET": "EMREZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "GAZİEMİR",
        "STREET": "FATİH MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "GAZİEMİR",
        "STREET": "GAZİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "GAZİEMİR",
        "STREET": "GAZİKENT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "GAZİEMİR",
        "STREET": "HÜRRİYET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "GAZİEMİR",
        "STREET": "IRMAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "GAZİEMİR",
        "STREET": "MENDERES MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "GAZİEMİR",
        "STREET": "SEVGİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "GAZİEMİR",
        "STREET": "YEŞİL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "GAZİEMİR",
        "STREET": "ZAFER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "GÜZELBAHÇE",
        "STREET": "ATATÜRK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "GÜZELBAHÇE",
        "STREET": "KAHRAMANDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "GÜZELBAHÇE",
        "STREET": "KÜÇÜKKAYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "GÜZELBAHÇE",
        "STREET": "MALTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "GÜZELBAHÇE",
        "STREET": "MUSTAFA KEMAL PAŞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "GÜZELBAHÇE",
        "STREET": "PAYAMLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "GÜZELBAHÇE",
        "STREET": "SİTELER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "GÜZELBAHÇE",
        "STREET": "YAKA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "GÜZELBAHÇE",
        "STREET": "YALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "GÜZELBAHÇE",
        "STREET": "YELKİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "GÜZELBAHÇE",
        "STREET": "ÇAMLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "GÜZELBAHÇE",
        "STREET": "ÇELEBİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KARABAĞLAR",
        "STREET": "ABDİ İPEKÇİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KARABAĞLAR",
        "STREET": "ADNAN SÜVARİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KARABAĞLAR",
        "STREET": "ALİ FUAT CEBESOY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KARABAĞLAR",
        "STREET": "ALİ FUAT ERDEN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KARABAĞLAR",
        "STREET": "ARAP HASAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KARABAĞLAR",
        "STREET": "AYDIN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KARABAĞLAR",
        "STREET": "AŞIK VEYSEL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KARABAĞLAR",
        "STREET": "BAHAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KARABAĞLAR",
        "STREET": "BAHRİYE ÜÇOK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KARABAĞLAR",
        "STREET": "BAHÇELİEVLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KARABAĞLAR",
        "STREET": "BARIŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KARABAĞLAR",
        "STREET": "BASIN SİTESİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KARABAĞLAR",
        "STREET": "BOZYAKA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KARABAĞLAR",
        "STREET": "CENNETOĞLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KARABAĞLAR",
        "STREET": "CENNETÇEŞME MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KARABAĞLAR",
        "STREET": "DEVRİM MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KARABAĞLAR",
        "STREET": "DOĞANAY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KARABAĞLAR",
        "STREET": "ESENLİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KARABAĞLAR",
        "STREET": "ESENTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KARABAĞLAR",
        "STREET": "ESENYALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KARABAĞLAR",
        "STREET": "FAHRETTİN ALTAY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KARABAĞLAR",
        "STREET": "GAZİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KARABAĞLAR",
        "STREET": "GENERAL ASIM GÜNDÜZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KARABAĞLAR",
        "STREET": "GENERAL KAZIM ÖZALP MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KARABAĞLAR",
        "STREET": "GÜLYAKA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KARABAĞLAR",
        "STREET": "GÜNALTAY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KARABAĞLAR",
        "STREET": "KARABAĞLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KARABAĞLAR",
        "STREET": "KAVACIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KARABAĞLAR",
        "STREET": "KAZIM KARABEKİR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KARABAĞLAR",
        "STREET": "KİBAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KARABAĞLAR",
        "STREET": "LİMONTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KARABAĞLAR",
        "STREET": "MALİYECİLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KARABAĞLAR",
        "STREET": "METİN OKTAY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KARABAĞLAR",
        "STREET": "MUAMMER AKAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KARABAĞLAR",
        "STREET": "OSMAN AKSÜNER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KARABAĞLAR",
        "STREET": "PEKER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KARABAĞLAR",
        "STREET": "POLİGON MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KARABAĞLAR",
        "STREET": "REFET BELE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KARABAĞLAR",
        "STREET": "REİS MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KARABAĞLAR",
        "STREET": "SALİH OMURTAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KARABAĞLAR",
        "STREET": "SARIYER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KARABAĞLAR",
        "STREET": "SELVİLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KARABAĞLAR",
        "STREET": "SEVGİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KARABAĞLAR",
        "STREET": "TAHSİN YAZICI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KARABAĞLAR",
        "STREET": "TIRAZLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KARABAĞLAR",
        "STREET": "UMUT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KARABAĞLAR",
        "STREET": "UZUNDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KARABAĞLAR",
        "STREET": "UĞUR MUMCU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KARABAĞLAR",
        "STREET": "VATAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KARABAĞLAR",
        "STREET": "YAŞAR KEMAL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KARABAĞLAR",
        "STREET": "YUNUS EMRE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KARABAĞLAR",
        "STREET": "YURDOĞLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KARABAĞLAR",
        "STREET": "YÜZBAŞI ŞERAFETTİN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KARABAĞLAR",
        "STREET": "ÇALIKUŞU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KARABAĞLAR",
        "STREET": "ÖZGÜR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KARABAĞLAR",
        "STREET": "ÜÇKUYULAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KARABAĞLAR",
        "STREET": "İHSAN ALYANAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KARABAĞLAR",
        "STREET": "ŞEHİTLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KARABURUN",
        "STREET": "ANBARSEKİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KARABURUN",
        "STREET": "BOZKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KARABURUN",
        "STREET": "EĞLENHOCA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KARABURUN",
        "STREET": "HASSEKİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KARABURUN",
        "STREET": "KÖSEDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KARABURUN",
        "STREET": "KÜÇÜKBAHÇE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KARABURUN",
        "STREET": "MERKEZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KARABURUN",
        "STREET": "MORDOĞAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KARABURUN",
        "STREET": "PARLAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KARABURUN",
        "STREET": "SALMAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KARABURUN",
        "STREET": "SARPINCIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KARABURUN",
        "STREET": "SAİP MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KARABURUN",
        "STREET": "TEPEBOZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KARABURUN",
        "STREET": "YAYLA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KARABURUN",
        "STREET": "İNECİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KARABURUN",
        "STREET": "İSKELE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KARŞIYAKA",
        "STREET": "AKSOY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KARŞIYAKA",
        "STREET": "ALAYBEY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KARŞIYAKA",
        "STREET": "ATAKENT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KARŞIYAKA",
        "STREET": "BAHARİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KARŞIYAKA",
        "STREET": "BAHRİYE ÜÇOK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KARŞIYAKA",
        "STREET": "BAHÇELİEVLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KARŞIYAKA",
        "STREET": "BOSTANLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KARŞIYAKA",
        "STREET": "CUMHURİYET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KARŞIYAKA",
        "STREET": "DEDEBAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KARŞIYAKA",
        "STREET": "DEMİRKÖPRÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KARŞIYAKA",
        "STREET": "DONANMACI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KARŞIYAKA",
        "STREET": "FİKRİ ALTAY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KARŞIYAKA",
        "STREET": "GONCALAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KARŞIYAKA",
        "STREET": "LATİFE HANIM MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KARŞIYAKA",
        "STREET": "MAVİŞEHİR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KARŞIYAKA",
        "STREET": "MUSTAFA KEMAL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KARŞIYAKA",
        "STREET": "NERGİZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KARŞIYAKA",
        "STREET": "SANCAKLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KARŞIYAKA",
        "STREET": "TERSANE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KARŞIYAKA",
        "STREET": "TUNA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KARŞIYAKA",
        "STREET": "YALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KARŞIYAKA",
        "STREET": "YAMANLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KARŞIYAKA",
        "STREET": "ZÜBEYDE HANIM MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KARŞIYAKA",
        "STREET": "ÖRNEKKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KARŞIYAKA",
        "STREET": "İMBATLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KARŞIYAKA",
        "STREET": "İNÖNÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KARŞIYAKA",
        "STREET": "ŞEMİKLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KEMALPAŞA",
        "STREET": "AKALAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KEMALPAŞA",
        "STREET": "ANSIZCA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KEMALPAŞA",
        "STREET": "ARMUTLU 85.YIL CUMHURİYET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KEMALPAŞA",
        "STREET": "ARMUTLU HÜRRİYET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KEMALPAŞA",
        "STREET": "ATATÜRK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KEMALPAŞA",
        "STREET": "AŞAĞI YENMİŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KEMALPAŞA",
        "STREET": "AŞAĞIKIZILCA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KEMALPAŞA",
        "STREET": "BAYRAMLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KEMALPAŞA",
        "STREET": "BAĞYURDU 29 EKİM MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KEMALPAŞA",
        "STREET": "BAĞYURDU KAZIMPAŞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KEMALPAŞA",
        "STREET": "BAĞYURDU KEMAL ATATÜRK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KEMALPAŞA",
        "STREET": "BAĞYURDU YENİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KEMALPAŞA",
        "STREET": "BEŞPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KEMALPAŞA",
        "STREET": "CUMALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KEMALPAŞA",
        "STREET": "DAMLACIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KEMALPAŞA",
        "STREET": "DEREKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KEMALPAŞA",
        "STREET": "GÖKYAKA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KEMALPAŞA",
        "STREET": "GÖKÇEYURT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KEMALPAŞA",
        "STREET": "HALİLBEYLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KEMALPAŞA",
        "STREET": "HAMZABABA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KEMALPAŞA",
        "STREET": "KAMBERLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KEMALPAŞA",
        "STREET": "KIZILÜZÜM MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KEMALPAŞA",
        "STREET": "KUYUCAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KEMALPAŞA",
        "STREET": "MEHMET AKİF ERSOY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KEMALPAŞA",
        "STREET": "NAZARKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KEMALPAŞA",
        "STREET": "OVACIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KEMALPAŞA",
        "STREET": "SARILAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KEMALPAŞA",
        "STREET": "SARIÇALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KEMALPAŞA",
        "STREET": "SEKİZ EYLÜL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KEMALPAŞA",
        "STREET": "SOĞUKPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KEMALPAŞA",
        "STREET": "SÜTÇÜLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KEMALPAŞA",
        "STREET": "SİNANCILAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KEMALPAŞA",
        "STREET": "ULUCAK CUMHURİYET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KEMALPAŞA",
        "STREET": "ULUCAK MUSTAFA KEMAL ATATÜRK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KEMALPAŞA",
        "STREET": "ULUCAK İSTİKLAL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KEMALPAŞA",
        "STREET": "VİŞNELİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KEMALPAŞA",
        "STREET": "YENMİŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KEMALPAŞA",
        "STREET": "YENİKURUDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KEMALPAŞA",
        "STREET": "YEŞİLKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KEMALPAŞA",
        "STREET": "YEŞİLYURT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KEMALPAŞA",
        "STREET": "YUKARIKIZILCA MERKEZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KEMALPAŞA",
        "STREET": "YİĞİTLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KEMALPAŞA",
        "STREET": "ZEAMET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KEMALPAŞA",
        "STREET": "ÇAMBEL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KEMALPAŞA",
        "STREET": "ÇINARKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KEMALPAŞA",
        "STREET": "ÇİNİLİKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KEMALPAŞA",
        "STREET": "ÖREN 75.YIL CUMHURİYET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KEMALPAŞA",
        "STREET": "ÖREN EGEMEN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KEMALPAŞA",
        "STREET": "ÖRNEKKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KINIK",
        "STREET": "ARPADERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KINIK",
        "STREET": "ARPASEKİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KINIK",
        "STREET": "AZİZİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KINIK",
        "STREET": "AŞAĞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KINIK",
        "STREET": "BADEMALAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KINIK",
        "STREET": "BALABAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KINIK",
        "STREET": "BAĞALAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KINIK",
        "STREET": "BÜYÜKOBA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KINIK",
        "STREET": "CUMALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KINIK",
        "STREET": "DEĞİRMENCİELİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KINIK",
        "STREET": "DÜNDARLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KINIK",
        "STREET": "ELMADERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KINIK",
        "STREET": "FATİH MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KINIK",
        "STREET": "HAMZAHOCALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KINIK",
        "STREET": "IŞIKLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KINIK",
        "STREET": "KALEMKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KINIK",
        "STREET": "KARADERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KINIK",
        "STREET": "KARATEKELİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KINIK",
        "STREET": "KOCAÖMER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KINIK",
        "STREET": "KODUKBURUN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KINIK",
        "STREET": "KÖSELER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KINIK",
        "STREET": "MISTIKLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KINIK",
        "STREET": "MUSACALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KINIK",
        "STREET": "OSMANİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KINIK",
        "STREET": "POYRACIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KINIK",
        "STREET": "SUCAHLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KINIK",
        "STREET": "TAŞTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KINIK",
        "STREET": "TÜRKCEDİT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KINIK",
        "STREET": "YAYAKENT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KINIK",
        "STREET": "YAYLAKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KINIK",
        "STREET": "YENİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KINIK",
        "STREET": "YUKARI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KINIK",
        "STREET": "ÇALTI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KINIK",
        "STREET": "ÇANKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KINIK",
        "STREET": "ÇİFTLİKKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KINIK",
        "STREET": "ÖRTÜLÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KINIK",
        "STREET": "İBRAHİMAĞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KONAK",
        "STREET": "1.KADRİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KONAK",
        "STREET": "19 MAYIS MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KONAK",
        "STREET": "2.KADRİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KONAK",
        "STREET": "26 AĞUSTOS MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KONAK",
        "STREET": "AKARCALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KONAK",
        "STREET": "AKDENİZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KONAK",
        "STREET": "AKIN SİMAV MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KONAK",
        "STREET": "AKINCI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KONAK",
        "STREET": "ALSANCAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KONAK",
        "STREET": "ALTAY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KONAK",
        "STREET": "ALTINORDU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KONAK",
        "STREET": "ALTINTAŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KONAK",
        "STREET": "ALİ REİS MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KONAK",
        "STREET": "ANADOLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KONAK",
        "STREET": "ATAMER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KONAK",
        "STREET": "ATİLLA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KONAK",
        "STREET": "AZİZİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KONAK",
        "STREET": "BALLIKUYU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KONAK",
        "STREET": "BARBAROS MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KONAK",
        "STREET": "BOZKURT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KONAK",
        "STREET": "BOĞAZİÇİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KONAK",
        "STREET": "CENGİZ TOPEL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KONAK",
        "STREET": "DAYIEMİR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KONAK",
        "STREET": "DOLAPLIKUYU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KONAK",
        "STREET": "DUATEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KONAK",
        "STREET": "EGE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KONAK",
        "STREET": "EMİR SULTAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KONAK",
        "STREET": "ETİLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KONAK",
        "STREET": "FATİH MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KONAK",
        "STREET": "FAİK PAŞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KONAK",
        "STREET": "FERAHLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KONAK",
        "STREET": "FEVZİ PAŞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KONAK",
        "STREET": "GÖZTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KONAK",
        "STREET": "GÜNEY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KONAK",
        "STREET": "GÜNEŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KONAK",
        "STREET": "GÜNEŞLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KONAK",
        "STREET": "GÜNGÖR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KONAK",
        "STREET": "GÜZELYALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KONAK",
        "STREET": "GÜZELYURT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KONAK",
        "STREET": "HALKAPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KONAK",
        "STREET": "HASAN ÖZDEMİR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KONAK",
        "STREET": "HURŞİDİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KONAK",
        "STREET": "HUZUR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KONAK",
        "STREET": "HİLAL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KONAK",
        "STREET": "KADİFEKALE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KONAK",
        "STREET": "KAHRAMAN MESCİT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KONAK",
        "STREET": "KAHRAMANLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KONAK",
        "STREET": "KEMAL REİS MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KONAK",
        "STREET": "KESTELLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KONAK",
        "STREET": "KILIÇ REİS MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KONAK",
        "STREET": "KOCAKAPI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KONAK",
        "STREET": "KOCATEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KONAK",
        "STREET": "KONAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KONAK",
        "STREET": "KOSOVA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KONAK",
        "STREET": "KUBİLAY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KONAK",
        "STREET": "KURTULUŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KONAK",
        "STREET": "KÜLTÜR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KONAK",
        "STREET": "KÜÇÜKADA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KONAK",
        "STREET": "LALE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KONAK",
        "STREET": "LEVENT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KONAK",
        "STREET": "MECİDİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KONAK",
        "STREET": "MEHMET AKİF MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KONAK",
        "STREET": "MEHMET ALİ AKMAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KONAK",
        "STREET": "MEHTAP MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KONAK",
        "STREET": "MERSİNLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KONAK",
        "STREET": "MURAT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KONAK",
        "STREET": "MURAT REİS MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KONAK",
        "STREET": "MİLLET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KONAK",
        "STREET": "MİMAR SİNAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KONAK",
        "STREET": "MİRALİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KONAK",
        "STREET": "MİTHATPAŞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KONAK",
        "STREET": "NAMIK KEMAL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KONAK",
        "STREET": "ODUNKAPI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KONAK",
        "STREET": "OĞUZLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KONAK",
        "STREET": "PAZARYERİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KONAK",
        "STREET": "PİRİ REİS MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KONAK",
        "STREET": "SAKARYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KONAK",
        "STREET": "SAYGI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KONAK",
        "STREET": "SELÇUK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KONAK",
        "STREET": "SÜMER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KONAK",
        "STREET": "SÜVARİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KONAK",
        "STREET": "TAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KONAK",
        "STREET": "TINAZTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KONAK",
        "STREET": "TRAKYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KONAK",
        "STREET": "TURGUT REİS MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KONAK",
        "STREET": "TUZCU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KONAK",
        "STREET": "TÜRKYILMAZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KONAK",
        "STREET": "ULUBATLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KONAK",
        "STREET": "UMURBEY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KONAK",
        "STREET": "UĞUR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KONAK",
        "STREET": "VEZİRAĞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KONAK",
        "STREET": "YAVUZ SELİM MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KONAK",
        "STREET": "YENİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KONAK",
        "STREET": "YENİDOĞAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KONAK",
        "STREET": "YENİGÜN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KONAK",
        "STREET": "YENİŞEHİR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KONAK",
        "STREET": "YEŞİLDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KONAK",
        "STREET": "YEŞİLTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KONAK",
        "STREET": "YILDIZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KONAK",
        "STREET": "ZAFERTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KONAK",
        "STREET": "ZEYBEK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KONAK",
        "STREET": "ZEYTİNLİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KONAK",
        "STREET": "ÇAHABEY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KONAK",
        "STREET": "ÇANKAYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KONAK",
        "STREET": "ÇINARLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KONAK",
        "STREET": "ÇINARTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KONAK",
        "STREET": "ÇİMENTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KONAK",
        "STREET": "ÜLKÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KONAK",
        "STREET": "İSMET KAPTAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KONAK",
        "STREET": "İSMET PAŞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KONAK",
        "STREET": "ŞEHİT NEDİM TUĞALTAY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KİRAZ",
        "STREET": "AHMETLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KİRAZ",
        "STREET": "AKPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KİRAZ",
        "STREET": "ALTINOLUK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KİRAZ",
        "STREET": "ARKACILAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KİRAZ",
        "STREET": "AVUNDURUK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KİRAZ",
        "STREET": "AYDOĞDU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KİRAZ",
        "STREET": "BAHÇEARASI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KİRAZ",
        "STREET": "BAŞARAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KİRAZ",
        "STREET": "CERİTLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KİRAZ",
        "STREET": "CEVİZLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KİRAZ",
        "STREET": "CUMHURİYET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KİRAZ",
        "STREET": "DOKUZLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KİRAZ",
        "STREET": "DOĞANCILAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KİRAZ",
        "STREET": "EMENLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KİRAZ",
        "STREET": "GEDİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KİRAZ",
        "STREET": "HALİLLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KİRAZ",
        "STREET": "HİSAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KİRAZ",
        "STREET": "KALEKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KİRAZ",
        "STREET": "KARABAĞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KİRAZ",
        "STREET": "KARABULU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KİRAZ",
        "STREET": "KARABURÇ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KİRAZ",
        "STREET": "KARAMAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KİRAZ",
        "STREET": "KIRKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KİRAZ",
        "STREET": "KİBAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KİRAZ",
        "STREET": "MAVİDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KİRAZ",
        "STREET": "MERSİNLİDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KİRAZ",
        "STREET": "OLGUNLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KİRAZ",
        "STREET": "OVACIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KİRAZ",
        "STREET": "PINARBAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KİRAZ",
        "STREET": "SARIKAYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KİRAZ",
        "STREET": "SARISU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KİRAZ",
        "STREET": "SAÇLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KİRAZ",
        "STREET": "SIRIMLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KİRAZ",
        "STREET": "SOLAKLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KİRAZ",
        "STREET": "SULUDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KİRAZ",
        "STREET": "TAŞLIYATAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KİRAZ",
        "STREET": "TEKBIÇAKLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KİRAZ",
        "STREET": "TUMBULLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KİRAZ",
        "STREET": "UMURCALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KİRAZ",
        "STREET": "UMURLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KİRAZ",
        "STREET": "UZUNKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KİRAZ",
        "STREET": "VELİLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KİRAZ",
        "STREET": "YAĞLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KİRAZ",
        "STREET": "YENİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KİRAZ",
        "STREET": "YENİKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KİRAZ",
        "STREET": "YENİŞEHİR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KİRAZ",
        "STREET": "YEŞİLDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KİRAZ",
        "STREET": "ÇANAKÇI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KİRAZ",
        "STREET": "ÇATAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KİRAZ",
        "STREET": "ÇAYAĞZI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KİRAZ",
        "STREET": "ÇÖMLEKÇİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KİRAZ",
        "STREET": "ÖREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KİRAZ",
        "STREET": "ÖRENCİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KİRAZ",
        "STREET": "İSTİKLAL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KİRAZ",
        "STREET": "İĞDELİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "KİRAZ",
        "STREET": "ŞEMSİLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "MENDERES",
        "STREET": "AHMETBEYLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "MENDERES",
        "STREET": "AKÇAKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "MENDERES",
        "STREET": "ALTINTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "MENDERES",
        "STREET": "ATA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "MENDERES",
        "STREET": "ATAKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "MENDERES",
        "STREET": "BARBAROS MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "MENDERES",
        "STREET": "BULGURCA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "MENDERES",
        "STREET": "CÜNEYTBEY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "MENDERES",
        "STREET": "DEREKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "MENDERES",
        "STREET": "DEVELİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "MENDERES",
        "STREET": "DEĞİRMENDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "MENDERES",
        "STREET": "EFEMÇUKURU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "MENDERES",
        "STREET": "GAZİPAŞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "MENDERES",
        "STREET": "GÖLCÜKLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "MENDERES",
        "STREET": "GÖLOVA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "MENDERES",
        "STREET": "GÖRECE CUMHURİYET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "MENDERES",
        "STREET": "GÜMÜLDÜR ATATÜRK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "MENDERES",
        "STREET": "GÜMÜLDÜR CUMHURİYET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "MENDERES",
        "STREET": "GÜMÜLDÜR FEVZİ ÇAKMAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "MENDERES",
        "STREET": "GÜMÜLDÜR İNÖNÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "MENDERES",
        "STREET": "HÜRRİYET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "MENDERES",
        "STREET": "KARAKUYU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "MENDERES",
        "STREET": "KASIMPAŞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "MENDERES",
        "STREET": "KELER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "MENDERES",
        "STREET": "KEMALPAŞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "MENDERES",
        "STREET": "KISIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "MENDERES",
        "STREET": "KUYUCAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "MENDERES",
        "STREET": "KÜNER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "MENDERES",
        "STREET": "MİTHATPAŞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "MENDERES",
        "STREET": "ORTA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "MENDERES",
        "STREET": "OĞLANANASI ATATÜRK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "MENDERES",
        "STREET": "OĞLANANASI CUMHURİYET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "MENDERES",
        "STREET": "SANCAKLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "MENDERES",
        "STREET": "TEKELİ ATATÜRK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "MENDERES",
        "STREET": "TEKELİ FEVZİ ÇAKMAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "MENDERES",
        "STREET": "YENİKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "MENDERES",
        "STREET": "ÇAKALTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "MENDERES",
        "STREET": "ÇAMÖNÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "MENDERES",
        "STREET": "ÇATALCA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "MENDERES",
        "STREET": "ÇUKURALTI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "MENDERES",
        "STREET": "ÇİLE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "MENDERES",
        "STREET": "ÇİLEME MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "MENDERES",
        "STREET": "ÖZDERE CUMHURİYET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "MENDERES",
        "STREET": "ŞAŞAL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "MENEMEN",
        "STREET": "29 EKİM MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "MENEMEN",
        "STREET": "30 AĞUSTOS MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "MENEMEN",
        "STREET": "75.YIL CUMHURİYET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "MENEMEN",
        "STREET": "85.YIL CUMHURİYET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "MENEMEN",
        "STREET": "9 EYLÜL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "MENEMEN",
        "STREET": "AHIHIDIR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "MENEMEN",
        "STREET": "ALANİÇİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "MENEMEN",
        "STREET": "ATATÜRK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "MENEMEN",
        "STREET": "AYVACIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "MENEMEN",
        "STREET": "BAĞCILAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "MENEMEN",
        "STREET": "BELEN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "MENEMEN",
        "STREET": "BOZALAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "MENEMEN",
        "STREET": "BURUNCUK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "MENEMEN",
        "STREET": "CAMİİKEBİR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "MENEMEN",
        "STREET": "CUMHURİYET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "MENEMEN",
        "STREET": "DEĞİRMENDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "MENEMEN",
        "STREET": "DOĞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "MENEMEN",
        "STREET": "EMİRALEM MERKEZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "MENEMEN",
        "STREET": "ESATPAŞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "MENEMEN",
        "STREET": "FATİH MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "MENEMEN",
        "STREET": "GAYBİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "MENEMEN",
        "STREET": "GAZİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "MENEMEN",
        "STREET": "GAZİ MUSTAFA KEMAL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "MENEMEN",
        "STREET": "GÖKTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "MENEMEN",
        "STREET": "GÖLCÜK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "MENEMEN",
        "STREET": "GÖRECE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "MENEMEN",
        "STREET": "GÜNERLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "MENEMEN",
        "STREET": "HASANLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "MENEMEN",
        "STREET": "HATUNDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "MENEMEN",
        "STREET": "HAYKIRAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "MENEMEN",
        "STREET": "IRMAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "MENEMEN",
        "STREET": "KARAORMAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "MENEMEN",
        "STREET": "KASIMPAŞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "MENEMEN",
        "STREET": "KAZIMPAŞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "MENEMEN",
        "STREET": "KEMAL ATATÜRK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "MENEMEN",
        "STREET": "KESİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "MENEMEN",
        "STREET": "KIR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "MENEMEN",
        "STREET": "MENEMEN R TİPİ KAPALI CEZA İNFAZ KURUMU (REHABİLİT",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "MENEMEN",
        "STREET": "MENEMEN T TİPİ KAPALI CEZA İNFAZ KURUMU",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "MENEMEN",
        "STREET": "MERMERLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "MENEMEN",
        "STREET": "MUSABEY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "MENEMEN",
        "STREET": "MUSTAFA KEMAL ATATÜRK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "MENEMEN",
        "STREET": "SEYDİNNASRULLAH MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "MENEMEN",
        "STREET": "SÜLEYMANLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "MENEMEN",
        "STREET": "SÜZBEYLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "MENEMEN",
        "STREET": "TELEKLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "MENEMEN",
        "STREET": "TURGUTLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "MENEMEN",
        "STREET": "TUZÇULLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "MENEMEN",
        "STREET": "TÜLBENTLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "MENEMEN",
        "STREET": "ULUS MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "MENEMEN",
        "STREET": "UĞUR MUMCU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "MENEMEN",
        "STREET": "VİLLAKENT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "MENEMEN",
        "STREET": "YAHŞELLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "MENEMEN",
        "STREET": "YANIKKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "MENEMEN",
        "STREET": "YAYLA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "MENEMEN",
        "STREET": "YEŞİL PINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "MENEMEN",
        "STREET": "YILDIRIM MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "MENEMEN",
        "STREET": "ZAFER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "MENEMEN",
        "STREET": "ZEYTİNLİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "MENEMEN",
        "STREET": "ÇALTI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "MENEMEN",
        "STREET": "ÇAVUŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "MENEMEN",
        "STREET": "ÇUKURKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "MENEMEN",
        "STREET": "İNCİRLİ PINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "MENEMEN",
        "STREET": "İNÖNÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "MENEMEN",
        "STREET": "İSMET İNÖNÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "MENEMEN",
        "STREET": "İSTİKLAL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "MENEMEN",
        "STREET": "İĞNEDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "NARLIDERE",
        "STREET": "2. İNÖNÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "NARLIDERE",
        "STREET": "ALTIEVLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "NARLIDERE",
        "STREET": "ATATÜRK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "NARLIDERE",
        "STREET": "HUZUR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "NARLIDERE",
        "STREET": "ILICA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "NARLIDERE",
        "STREET": "LİMANREİS MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "NARLIDERE",
        "STREET": "NARLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "NARLIDERE",
        "STREET": "SAHİLEVLERİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "NARLIDERE",
        "STREET": "YENİKALE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "NARLIDERE",
        "STREET": "ÇAMTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "NARLIDERE",
        "STREET": "ÇATALKAYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "SEFERİHİSAR",
        "STREET": "ATATÜRK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "SEFERİHİSAR",
        "STREET": "BENGİLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "SEFERİHİSAR",
        "STREET": "BEYLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "SEFERİHİSAR",
        "STREET": "CAMİKEBİR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "SEFERİHİSAR",
        "STREET": "CUMHURİYET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "SEFERİHİSAR",
        "STREET": "DÜZCE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "SEFERİHİSAR",
        "STREET": "GÖDENCE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "SEFERİHİSAR",
        "STREET": "GÖLCÜK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "SEFERİHİSAR",
        "STREET": "HIDIRLIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "SEFERİHİSAR",
        "STREET": "KAVAKDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "SEFERİHİSAR",
        "STREET": "MERSİN ALANI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "SEFERİHİSAR",
        "STREET": "ORHANLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "SEFERİHİSAR",
        "STREET": "PAYAMLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "SEFERİHİSAR",
        "STREET": "SIĞACIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "SEFERİHİSAR",
        "STREET": "TEPECİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "SEFERİHİSAR",
        "STREET": "TURABİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "SEFERİHİSAR",
        "STREET": "TURGUT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "SEFERİHİSAR",
        "STREET": "ULAMIŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "SEFERİHİSAR",
        "STREET": "ÇAMTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "SEFERİHİSAR",
        "STREET": "ÇOLAK İBRAHİM BEY  MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "SEFERİHİSAR",
        "STREET": "İHSANİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "SELÇUK",
        "STREET": "14 MAYIS MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "SELÇUK",
        "STREET": "ACARLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "SELÇUK",
        "STREET": "ATATÜRK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "SELÇUK",
        "STREET": "BARUTÇU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "SELÇUK",
        "STREET": "BELEVİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "SELÇUK",
        "STREET": "CUMHURİYET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "SELÇUK",
        "STREET": "GÖKÇEALAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "SELÇUK",
        "STREET": "HAVUTÇULU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "SELÇUK",
        "STREET": "SULTANİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "SELÇUK",
        "STREET": "ZAFER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "SELÇUK",
        "STREET": "ZEYTİNKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "SELÇUK",
        "STREET": "ÇAMLIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "SELÇUK",
        "STREET": "İSA BEY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "SELÇUK",
        "STREET": "ŞİRİNCE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "TORBALI",
        "STREET": "19 MAYIS MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "TORBALI",
        "STREET": "AHMETLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "TORBALI",
        "STREET": "ALPKENT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "TORBALI",
        "STREET": "ARSLANLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "TORBALI",
        "STREET": "ATALAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "TORBALI",
        "STREET": "ATATÜRK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "TORBALI",
        "STREET": "AYRANCILAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "TORBALI",
        "STREET": "BAHÇELİEVLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "TORBALI",
        "STREET": "BOZKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "TORBALI",
        "STREET": "BÜLBÜLDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "TORBALI",
        "STREET": "CUMHURİYET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "TORBALI",
        "STREET": "DAĞKIZILCA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "TORBALI",
        "STREET": "DAĞTEKE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "TORBALI",
        "STREET": "DEMİRCİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "TORBALI",
        "STREET": "DÜVERLİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "TORBALI",
        "STREET": "DİRMİL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "TORBALI",
        "STREET": "ERTUĞRUL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "TORBALI",
        "STREET": "EĞERCİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "TORBALI",
        "STREET": "FEVZİ ÇAKMAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "TORBALI",
        "STREET": "GAZİ MUSTAFA KEMAL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "TORBALI",
        "STREET": "GÖLLÜCE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "TORBALI",
        "STREET": "HELVACI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "TORBALI",
        "STREET": "KAPLANCIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "TORBALI",
        "STREET": "KARAKIZLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "TORBALI",
        "STREET": "KARAKUYU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "TORBALI",
        "STREET": "KARAOT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "TORBALI",
        "STREET": "KARŞIYAKA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "TORBALI",
        "STREET": "KAZIM KARABEKİR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "TORBALI",
        "STREET": "KIRBAŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "TORBALI",
        "STREET": "KUŞÇUBURUN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "TORBALI",
        "STREET": "MURATBEY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "TORBALI",
        "STREET": "MUSTAFA KEMAL ATATÜRK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "TORBALI",
        "STREET": "NAİME MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "TORBALI",
        "STREET": "ORMANKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "TORBALI",
        "STREET": "ORTAKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "TORBALI",
        "STREET": "PAMUKYAZI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "TORBALI",
        "STREET": "PANCAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "TORBALI",
        "STREET": "SAĞLIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "TORBALI",
        "STREET": "SAİPLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "TORBALI",
        "STREET": "SUBAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "TORBALI",
        "STREET": "TAŞKESİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "TORBALI",
        "STREET": "TEPEKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "TORBALI",
        "STREET": "TORBALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "TORBALI",
        "STREET": "TULUM MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "TORBALI",
        "STREET": "TÜRKMENKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "TORBALI",
        "STREET": "YAZIBAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "TORBALI",
        "STREET": "YEDİ EYLÜL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "TORBALI",
        "STREET": "YEMİŞLİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "TORBALI",
        "STREET": "YENİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "TORBALI",
        "STREET": "YENİKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "TORBALI",
        "STREET": "YEŞİLKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "TORBALI",
        "STREET": "YOĞURTÇULAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "TORBALI",
        "STREET": "ÇAKIRBEYLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "TORBALI",
        "STREET": "ÇAMLICA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "TORBALI",
        "STREET": "ÇAPAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "TORBALI",
        "STREET": "ÇAYBAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "TORBALI",
        "STREET": "ÖZBEY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "TORBALI",
        "STREET": "İNÖNÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "TORBALI",
        "STREET": "İSTİKLAL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "TORBALI",
        "STREET": "ŞEHİTLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "TİRE",
        "STREET": "4 EYLÜL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "TİRE",
        "STREET": "ADNAN MENDERES MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "TİRE",
        "STREET": "AKKOYUNLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "TİRE",
        "STREET": "AKMESCİT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "TİRE",
        "STREET": "AKYURT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "TİRE",
        "STREET": "AKÇAŞEHİR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "TİRE",
        "STREET": "ALACALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "TİRE",
        "STREET": "ALAYLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "TİRE",
        "STREET": "ARMUTLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "TİRE",
        "STREET": "ATATÜRK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "TİRE",
        "STREET": "AYAKLIKIRI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "TİRE",
        "STREET": "BAHARİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "TİRE",
        "STREET": "BAŞKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "TİRE",
        "STREET": "BOYNUYOĞUN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "TİRE",
        "STREET": "BÜYÜKKALE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "TİRE",
        "STREET": "BÜYÜKKEMERDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "TİRE",
        "STREET": "BÜYÜKKÖMÜRCÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "TİRE",
        "STREET": "CAMBAZLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "TİRE",
        "STREET": "CUMHURİYET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "TİRE",
        "STREET": "DALLIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "TİRE",
        "STREET": "DAĞDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "TİRE",
        "STREET": "DERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "TİRE",
        "STREET": "DEREBAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "TİRE",
        "STREET": "DERELİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "TİRE",
        "STREET": "DOYRANLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "TİRE",
        "STREET": "DUATEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "TİRE",
        "STREET": "DUMLUPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "TİRE",
        "STREET": "DÜNDARLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "TİRE",
        "STREET": "DİBEKÇİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "TİRE",
        "STREET": "ERTUĞRUL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "TİRE",
        "STREET": "ESKİOBA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "TİRE",
        "STREET": "EĞRİDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "TİRE",
        "STREET": "FATİH MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "TİRE",
        "STREET": "GÖKÇEN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "TİRE",
        "STREET": "HALKAPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "TİRE",
        "STREET": "HASANÇAVUŞLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "TİRE",
        "STREET": "HÜRRİYET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "TİRE",
        "STREET": "HİSARLIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "TİRE",
        "STREET": "IŞIKLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "TİRE",
        "STREET": "IŞIKLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "TİRE",
        "STREET": "KAHRAT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "TİRE",
        "STREET": "KAPLAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "TİRE",
        "STREET": "KARACA ALİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "TİRE",
        "STREET": "KARATEKE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "TİRE",
        "STREET": "KETENCİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "TİRE",
        "STREET": "KIRTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "TİRE",
        "STREET": "KIZILCAHAVLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "TİRE",
        "STREET": "KOCAALİLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "TİRE",
        "STREET": "KURTULUŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "TİRE",
        "STREET": "KURŞAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "TİRE",
        "STREET": "KÜRDÜLLÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "TİRE",
        "STREET": "KÜÇÜKBURUN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "TİRE",
        "STREET": "KÜÇÜKKALE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "TİRE",
        "STREET": "KÜÇÜKKEMERDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "TİRE",
        "STREET": "KÜÇÜKKÖMÜRCÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "TİRE",
        "STREET": "KİRELİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "TİRE",
        "STREET": "MAHMUTLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "TİRE",
        "STREET": "MEHMETLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "TİRE",
        "STREET": "MUSALAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "TİRE",
        "STREET": "ORTAKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "TİRE",
        "STREET": "OSMANCIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "TİRE",
        "STREET": "PAŞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "TİRE",
        "STREET": "PEŞREFLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "TİRE",
        "STREET": "SARILAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "TİRE",
        "STREET": "SARUHANLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "TİRE",
        "STREET": "SOMAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "TİRE",
        "STREET": "TOKİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "TİRE",
        "STREET": "TOPALAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "TİRE",
        "STREET": "TOPARLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "TİRE",
        "STREET": "TURAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "TİRE",
        "STREET": "TURGUTLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "TİRE",
        "STREET": "YAMANDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "TİRE",
        "STREET": "YEMİŞLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "TİRE",
        "STREET": "YENİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "TİRE",
        "STREET": "YENİOBA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "TİRE",
        "STREET": "YENİÇİFTLİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "TİRE",
        "STREET": "YENİŞEHİR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "TİRE",
        "STREET": "YEĞENLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "TİRE",
        "STREET": "ÇAYIRLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "TİRE",
        "STREET": "ÇERİKÖZÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "TİRE",
        "STREET": "ÇOBANKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "TİRE",
        "STREET": "ÇUKURKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "TİRE",
        "STREET": "ÇİNİYERİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "TİRE",
        "STREET": "ÜZÜMLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "TİRE",
        "STREET": "İBNİ MELEK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "TİRE",
        "STREET": "İHSANİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "TİRE",
        "STREET": "İPEKÇİLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "TİRE",
        "STREET": "İSTİKLAL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "URLA",
        "STREET": "ALTINTAŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "URLA",
        "STREET": "ATATÜRK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "URLA",
        "STREET": "BADEMLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "URLA",
        "STREET": "BALIKLIOVA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "URLA",
        "STREET": "BARBAROS MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "URLA",
        "STREET": "BİRGİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "URLA",
        "STREET": "CAMİATİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "URLA",
        "STREET": "DEMİRCİLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "URLA",
        "STREET": "DENİZLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "URLA",
        "STREET": "GÜLBAHÇE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "URLA",
        "STREET": "GÜVENDİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "URLA",
        "STREET": "HACI İSA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "URLA",
        "STREET": "KADIOVACIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "URLA",
        "STREET": "KALABAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "URLA",
        "STREET": "KUŞÇULAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "URLA",
        "STREET": "M.FEVZİ ÇAKMAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "URLA",
        "STREET": "NAİPLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "URLA",
        "STREET": "NOHUTALAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "URLA",
        "STREET": "OVACIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "URLA",
        "STREET": "RÜSTEM MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "URLA",
        "STREET": "SIRA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "URLA",
        "STREET": "TORASAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "URLA",
        "STREET": "UZUNKUYU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "URLA",
        "STREET": "YAKA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "URLA",
        "STREET": "YAĞCILAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "URLA",
        "STREET": "YELALTI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "URLA",
        "STREET": "YENİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "URLA",
        "STREET": "YENİCE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "URLA",
        "STREET": "YENİKENT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "URLA",
        "STREET": "ZEYTİNALANI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "URLA",
        "STREET": "ZEYTİNELİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "URLA",
        "STREET": "ZEYTİNLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "URLA",
        "STREET": "ÇAMLIÇAY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "URLA",
        "STREET": "ÖZBEK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "URLA",
        "STREET": "İSKELE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "URLA",
        "STREET": "İÇMELER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "URLA",
        "STREET": "ŞİRİNKENT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "ÇEŞME",
        "STREET": "16 EYLÜL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "ÇEŞME",
        "STREET": "ALAÇATI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "ÇEŞME",
        "STREET": "ALTINKUM MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "ÇEŞME",
        "STREET": "ALTINYUNUS MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "ÇEŞME",
        "STREET": "ARDIÇ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "ÇEŞME",
        "STREET": "BOYALIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "ÇEŞME",
        "STREET": "CELAL BAYAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "ÇEŞME",
        "STREET": "CUMHURİYET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "ÇEŞME",
        "STREET": "DALYAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "ÇEŞME",
        "STREET": "FAHRETTİNPAŞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "ÇEŞME",
        "STREET": "GERMİYAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "ÇEŞME",
        "STREET": "ILDIR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "ÇEŞME",
        "STREET": "ILICA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "ÇEŞME",
        "STREET": "KARAKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "ÇEŞME",
        "STREET": "MUSALLA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "ÇEŞME",
        "STREET": "OVACIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "ÇEŞME",
        "STREET": "REİSDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "ÇEŞME",
        "STREET": "SAKARYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "ÇEŞME",
        "STREET": "YALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "ÇEŞME",
        "STREET": "ÇAKABEY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "ÇEŞME",
        "STREET": "ÇİFTLİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "ÇEŞME",
        "STREET": "ÜNİVERSİTE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "ÇEŞME",
        "STREET": "İSMET İNÖNÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "ÇEŞME",
        "STREET": "ŞEHİT MEHMET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "ÇEŞME",
        "STREET": "ŞİFNE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "ÇİĞLİ",
        "STREET": "AHMET EFENDİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "ÇİĞLİ",
        "STREET": "AHMET TANER KIŞLALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "ÇİĞLİ",
        "STREET": "ATATÜRK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "ÇİĞLİ",
        "STREET": "ATAŞEHİR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "ÇİĞLİ",
        "STREET": "AYDINLIKEVLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "ÇİĞLİ",
        "STREET": "BALATÇIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "ÇİĞLİ",
        "STREET": "CUMHURİYET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "ÇİĞLİ",
        "STREET": "EGEKENT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "ÇİĞLİ",
        "STREET": "ESENTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "ÇİĞLİ",
        "STREET": "EVKA-2 MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "ÇİĞLİ",
        "STREET": "EVKA-5 MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "ÇİĞLİ",
        "STREET": "EVKA-6 MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "ÇİĞLİ",
        "STREET": "GÜZELTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "ÇİĞLİ",
        "STREET": "HARMANDALI GAZİ MUSTAFA KEMAL ATATÜRK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "ÇİĞLİ",
        "STREET": "KAKLIÇ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "ÇİĞLİ",
        "STREET": "KÖYİÇİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "ÇİĞLİ",
        "STREET": "KÜÇÜK ÇİĞLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "ÇİĞLİ",
        "STREET": "MALTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "ÇİĞLİ",
        "STREET": "SASALI MERKEZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "ÇİĞLİ",
        "STREET": "UĞUR MUMCU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "ÇİĞLİ",
        "STREET": "YAKAKENT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "ÇİĞLİ",
        "STREET": "YENİ MAHALLE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "ÇİĞLİ",
        "STREET": "ÇAĞDAŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "ÇİĞLİ",
        "STREET": "İNÖNÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "ÇİĞLİ",
        "STREET": "İZKENT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "ÇİĞLİ",
        "STREET": "ŞİRİNTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "ÖDEMİŞ",
        "STREET": "AKINCILAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "ÖDEMİŞ",
        "STREET": "ALAŞARLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "ÖDEMİŞ",
        "STREET": "ANAFARTALAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "ÖDEMİŞ",
        "STREET": "ARTICAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "ÖDEMİŞ",
        "STREET": "ATATÜRK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "ÖDEMİŞ",
        "STREET": "BADEMLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "ÖDEMİŞ",
        "STREET": "BALABANLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "ÖDEMİŞ",
        "STREET": "BAYIRLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "ÖDEMİŞ",
        "STREET": "BEBEKLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "ÖDEMİŞ",
        "STREET": "BENGİSU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "ÖDEMİŞ",
        "STREET": "BEYAZITLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "ÖDEMİŞ",
        "STREET": "BIÇAKÇI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "ÖDEMİŞ",
        "STREET": "BOZCAYAKA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "ÖDEMİŞ",
        "STREET": "BOZDAĞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "ÖDEMİŞ",
        "STREET": "BUCAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "ÖDEMİŞ",
        "STREET": "BÜLBÜLLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "ÖDEMİŞ",
        "STREET": "BÜYÜKAVULCUK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "ÖDEMİŞ",
        "STREET": "BİRGİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "ÖDEMİŞ",
        "STREET": "CEVİZALAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "ÖDEMİŞ",
        "STREET": "CUMHURİYET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "ÖDEMİŞ",
        "STREET": "DEMİRCİLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "ÖDEMİŞ",
        "STREET": "DEMİRDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "ÖDEMİŞ",
        "STREET": "DEREUZUNYER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "ÖDEMİŞ",
        "STREET": "DOLAYLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "ÖDEMİŞ",
        "STREET": "EMMİOĞLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "ÖDEMİŞ",
        "STREET": "EMİRLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "ÖDEMİŞ",
        "STREET": "ERTUĞRUL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "ÖDEMİŞ",
        "STREET": "GERELİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "ÖDEMİŞ",
        "STREET": "GERÇEKLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "ÖDEMİŞ",
        "STREET": "GÖLCÜK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "ÖDEMİŞ",
        "STREET": "GÜNEY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "ÖDEMİŞ",
        "STREET": "GÜNLÜCE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "ÖDEMİŞ",
        "STREET": "HACIHASAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "ÖDEMİŞ",
        "STREET": "HAMAM MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "ÖDEMİŞ",
        "STREET": "HORZUM MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "ÖDEMİŞ",
        "STREET": "HÜRRİYET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "ÖDEMİŞ",
        "STREET": "IŞIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "ÖDEMİŞ",
        "STREET": "KARADOĞAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "ÖDEMİŞ",
        "STREET": "KARAKOVA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "ÖDEMİŞ",
        "STREET": "KAYAKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "ÖDEMİŞ",
        "STREET": "KAYMAKÇI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "ÖDEMİŞ",
        "STREET": "KAZANLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "ÖDEMİŞ",
        "STREET": "KEMENLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "ÖDEMİŞ",
        "STREET": "KEMER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "ÖDEMİŞ",
        "STREET": "KERPİÇLİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "ÖDEMİŞ",
        "STREET": "KIZILCA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "ÖDEMİŞ",
        "STREET": "KIZILCAAVLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "ÖDEMİŞ",
        "STREET": "KIŞLA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "ÖDEMİŞ",
        "STREET": "KONAKLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "ÖDEMİŞ",
        "STREET": "KURUCUOVA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "ÖDEMİŞ",
        "STREET": "KUTLUBEYLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "ÖDEMİŞ",
        "STREET": "KUVVETLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "ÖDEMİŞ",
        "STREET": "KÖFÜNDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "ÖDEMİŞ",
        "STREET": "KÖSELER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "ÖDEMİŞ",
        "STREET": "KÜRE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "ÖDEMİŞ",
        "STREET": "KÜÇÜKAVULCUK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "ÖDEMİŞ",
        "STREET": "KÜÇÜKÖREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "ÖDEMİŞ",
        "STREET": "MESCİTLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "ÖDEMİŞ",
        "STREET": "MEŞRUTİYET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "ÖDEMİŞ",
        "STREET": "MURSALLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "ÖDEMİŞ",
        "STREET": "MİMAR SİNAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "ÖDEMİŞ",
        "STREET": "OCAKLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "ÖDEMİŞ",
        "STREET": "ORHANGAZİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "ÖDEMİŞ",
        "STREET": "ORTAKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "ÖDEMİŞ",
        "STREET": "OVACIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "ÖDEMİŞ",
        "STREET": "OVAKENT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "ÖDEMİŞ",
        "STREET": "OĞUZLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "ÖDEMİŞ",
        "STREET": "PİRİNÇÇİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "ÖDEMİŞ",
        "STREET": "SEKİKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "ÖDEMİŞ",
        "STREET": "SEYREKLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "ÖDEMİŞ",
        "STREET": "SUÇIKTI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "ÖDEMİŞ",
        "STREET": "SÜLEYMAN DEMİREL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "ÖDEMİŞ",
        "STREET": "SÜLEYMANLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "ÖDEMİŞ",
        "STREET": "TEKKE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "ÖDEMİŞ",
        "STREET": "TOSUNLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "ÖDEMİŞ",
        "STREET": "TÜRKMEN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "ÖDEMİŞ",
        "STREET": "TÜRKÖNÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "ÖDEMİŞ",
        "STREET": "UMURBEY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "ÖDEMİŞ",
        "STREET": "UZUNDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "ÖDEMİŞ",
        "STREET": "VELİLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "ÖDEMİŞ",
        "STREET": "YENİCEKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "ÖDEMİŞ",
        "STREET": "YENİKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "ÖDEMİŞ",
        "STREET": "YEŞİLKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "ÖDEMİŞ",
        "STREET": "YILANLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "ÖDEMİŞ",
        "STREET": "YOLÜSTÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "ÖDEMİŞ",
        "STREET": "YUSUFDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "ÖDEMİŞ",
        "STREET": "ZAFER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "ÖDEMİŞ",
        "STREET": "ÇAMLICA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "ÖDEMİŞ",
        "STREET": "ÇAMYAYLA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "ÖDEMİŞ",
        "STREET": "ÇAYIR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "ÖDEMİŞ",
        "STREET": "ÇAYLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "ÖDEMİŞ",
        "STREET": "ÇAĞLAYAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "ÖDEMİŞ",
        "STREET": "ÇOBANLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "ÖDEMİŞ",
        "STREET": "ÖDEMİŞ M TİPİ KAPALI CEZA İNFAZ KURUMU",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "ÖDEMİŞ",
        "STREET": "ÖDEMİŞ T TİPİ KAPALI CEZA İNFAZ KURUMU",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "ÖDEMİŞ",
        "STREET": "ÜZÜMLÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "ÖDEMİŞ",
        "STREET": "ÜÇ EYLÜL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "ÖDEMİŞ",
        "STREET": "ÜÇKONAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "ÖDEMİŞ",
        "STREET": "İLKKURŞUN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "ÖDEMİŞ",
        "STREET": "İNÖNÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "İZMİR",
        "DISTRICT": "ÖDEMİŞ",
        "STREET": "ŞİRİNKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "AKÇAKALE",
        "STREET": "ACIKUYU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "AKÇAKALE",
        "STREET": "ADNAN MENDERES MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "AKÇAKALE",
        "STREET": "AKBİLEK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "AKÇAKALE",
        "STREET": "AKDİKEN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "AKÇAKALE",
        "STREET": "AKKEÇİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "AKÇAKALE",
        "STREET": "AKSAHRINÇ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "AKÇAKALE",
        "STREET": "AKÇAKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "AKÇAKALE",
        "STREET": "AKÇALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "AKÇAKALE",
        "STREET": "ALATLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "AKÇAKALE",
        "STREET": "ALINCIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "AKÇAKALE",
        "STREET": "ARICAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "AKÇAKALE",
        "STREET": "ASLANLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "AKÇAKALE",
        "STREET": "ATATÜRK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "AKÇAKALE",
        "STREET": "AYDINLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "AKÇAKALE",
        "STREET": "AYRANLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "AKÇAKALE",
        "STREET": "AYYILDIZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "AKÇAKALE",
        "STREET": "AŞAĞIBEYDAŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "AKÇAKALE",
        "STREET": "AŞAĞIDEREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "AKÇAKALE",
        "STREET": "AŞAĞIÇİNPOLAT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "AKÇAKALE",
        "STREET": "BAYKUŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "AKÇAKALE",
        "STREET": "BOLATLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "AKÇAKALE",
        "STREET": "BOYBEYİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "AKÇAKALE",
        "STREET": "BUKET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "AKÇAKALE",
        "STREET": "BULUTLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "AKÇAKALE",
        "STREET": "BÜYÜCEK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "AKÇAKALE",
        "STREET": "BÜYÜKNANELİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "AKÇAKALE",
        "STREET": "BÜYÜKTAŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "AKÇAKALE",
        "STREET": "BÜYÜKTOKAÇ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "AKÇAKALE",
        "STREET": "BİLECE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "AKÇAKALE",
        "STREET": "CEVHER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "AKÇAKALE",
        "STREET": "CEYLANLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "AKÇAKALE",
        "STREET": "DENİZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "AKÇAKALE",
        "STREET": "DONANDI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "AKÇAKALE",
        "STREET": "DORUMALİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "AKÇAKALE",
        "STREET": "DÜZCE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "AKÇAKALE",
        "STREET": "DİBEK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "AKÇAKALE",
        "STREET": "EDEBEY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "AKÇAKALE",
        "STREET": "EKE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "AKÇAKALE",
        "STREET": "EKİNYAZI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "AKÇAKALE",
        "STREET": "ERDEMLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "AKÇAKALE",
        "STREET": "ERECEK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "AKÇAKALE",
        "STREET": "FATİH SULTAN MEHMET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "AKÇAKALE",
        "STREET": "FEVZİ ÇAKMAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "AKÇAKALE",
        "STREET": "FİŞENGE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "AKÇAKALE",
        "STREET": "GEÇİTTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "AKÇAKALE",
        "STREET": "GÖLBAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "AKÇAKALE",
        "STREET": "GÜLVEREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "AKÇAKALE",
        "STREET": "GÜNDAŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "AKÇAKALE",
        "STREET": "GÜNEREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "AKÇAKALE",
        "STREET": "GÜVENÇ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "AKÇAKALE",
        "STREET": "HACIEKBER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "AKÇAKALE",
        "STREET": "HÜRRİYET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "AKÇAKALE",
        "STREET": "KARATEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "AKÇAKALE",
        "STREET": "KAYACA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "AKÇAKALE",
        "STREET": "KAYALIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "AKÇAKALE",
        "STREET": "KELEBEK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "AKÇAKALE",
        "STREET": "KEPEZLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "AKÇAKALE",
        "STREET": "KEÇİLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "AKÇAKALE",
        "STREET": "KILIÇLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "AKÇAKALE",
        "STREET": "KIRMITLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "AKÇAKALE",
        "STREET": "KORUKLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "AKÇAKALE",
        "STREET": "KURUDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "AKÇAKALE",
        "STREET": "KÖSEÖREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "AKÇAKALE",
        "STREET": "KÜÇÜK HACIEKBER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "AKÇAKALE",
        "STREET": "MAVİTAŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "AKÇAKALE",
        "STREET": "MENZİL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "AKÇAKALE",
        "STREET": "MERMER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "AKÇAKALE",
        "STREET": "MİL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "AKÇAKALE",
        "STREET": "NARLIOVA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "AKÇAKALE",
        "STREET": "NUSRETİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "AKÇAKALE",
        "STREET": "NİMET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "AKÇAKALE",
        "STREET": "OHALİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "AKÇAKALE",
        "STREET": "OKAYLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "AKÇAKALE",
        "STREET": "ONORTAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "AKÇAKALE",
        "STREET": "ORTADEREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "AKÇAKALE",
        "STREET": "ORTAÖREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "AKÇAKALE",
        "STREET": "OSMANLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "AKÇAKALE",
        "STREET": "PEKMEZLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "AKÇAKALE",
        "STREET": "SAKÇA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "AKÇAKALE",
        "STREET": "SALİHLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "AKÇAKALE",
        "STREET": "SEVİMLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "AKÇAKALE",
        "STREET": "SEYRANTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "AKÇAKALE",
        "STREET": "SINIRGÖREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "AKÇAKALE",
        "STREET": "SÜLEYMANŞAH MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "AKÇAKALE",
        "STREET": "TATLICA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "AKÇAKALE",
        "STREET": "TAŞKINLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "AKÇAKALE",
        "STREET": "TOPÇU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "AKÇAKALE",
        "STREET": "TURGUT ÖZAL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "AKÇAKALE",
        "STREET": "TUZLUCA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "AKÇAKALE",
        "STREET": "UĞRAKLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "AKÇAKALE",
        "STREET": "UĞURHAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "AKÇAKALE",
        "STREET": "UĞURTAŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "AKÇAKALE",
        "STREET": "VİZE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "AKÇAKALE",
        "STREET": "YALINLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "AKÇAKALE",
        "STREET": "YAZLICA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "AKÇAKALE",
        "STREET": "YAĞMURALAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "AKÇAKALE",
        "STREET": "YEDİYOL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "AKÇAKALE",
        "STREET": "YENİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "AKÇAKALE",
        "STREET": "YENİŞEHİR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "AKÇAKALE",
        "STREET": "YEŞERTİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "AKÇAKALE",
        "STREET": "YUKARI ÇUKURCA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "AKÇAKALE",
        "STREET": "YUKARIBEYDAŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "AKÇAKALE",
        "STREET": "YUKARIDEREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "AKÇAKALE",
        "STREET": "YUKARIÇİNPOLAT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "AKÇAKALE",
        "STREET": "YUSUFBEY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "AKÇAKALE",
        "STREET": "ZENGİNOVA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "AKÇAKALE",
        "STREET": "ZEYTİNDALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "AKÇAKALE",
        "STREET": "ZORLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "AKÇAKALE",
        "STREET": "ÇAKIRLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "AKÇAKALE",
        "STREET": "ÇOKALAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "AKÇAKALE",
        "STREET": "ÇUKURCA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "AKÇAKALE",
        "STREET": "ÇÖMLEKÇİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "AKÇAKALE",
        "STREET": "ÖNCÜL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "AKÇAKALE",
        "STREET": "İKİZCE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "AKÇAKALE",
        "STREET": "İNCEDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "AKÇAKALE",
        "STREET": "ŞAHİNLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "AKÇAKALE",
        "STREET": "ŞAHİNSOYLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "AKÇAKALE",
        "STREET": "ŞANLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "AKÇAKALE",
        "STREET": "ŞEFKAT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "AKÇAKALE",
        "STREET": "ŞEHİTNUSRETBEY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "BOZOVA",
        "STREET": "75.YIL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "BOZOVA",
        "STREET": "AKMAĞARA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "BOZOVA",
        "STREET": "ALTINLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "BOZOVA",
        "STREET": "ARGINCIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "BOZOVA",
        "STREET": "ARIKÖK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "BOZOVA",
        "STREET": "ARPALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "BOZOVA",
        "STREET": "AVLAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "BOZOVA",
        "STREET": "AYLAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "BOZOVA",
        "STREET": "BALTAŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "BOZOVA",
        "STREET": "BAĞLICA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "BOZOVA",
        "STREET": "BOZTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "BOZOVA",
        "STREET": "BUDAKLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "BOZOVA",
        "STREET": "BULANCAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "BOZOVA",
        "STREET": "BUĞLUCA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "BOZOVA",
        "STREET": "BÜYÜKHAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "BOZOVA",
        "STREET": "BİNEKLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "BOZOVA",
        "STREET": "BİRİS MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "BOZOVA",
        "STREET": "DELİLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "BOZOVA",
        "STREET": "DENİZBACI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "BOZOVA",
        "STREET": "DUTLUCA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "BOZOVA",
        "STREET": "DUTLUK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "BOZOVA",
        "STREET": "ESKİN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "BOZOVA",
        "STREET": "FEVZİ ÇAKMAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "BOZOVA",
        "STREET": "GERDEK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "BOZOVA",
        "STREET": "GÖKÖREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "BOZOVA",
        "STREET": "GÖLBAŞI  MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "BOZOVA",
        "STREET": "GÖYNİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "BOZOVA",
        "STREET": "GÖZENEK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "BOZOVA",
        "STREET": "HACIKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "BOZOVA",
        "STREET": "HACILAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "BOZOVA",
        "STREET": "HİSARLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "BOZOVA",
        "STREET": "KABACIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "BOZOVA",
        "STREET": "KARACA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "BOZOVA",
        "STREET": "KARACAÖREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "BOZOVA",
        "STREET": "KARAKAŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "BOZOVA",
        "STREET": "KARAPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "BOZOVA",
        "STREET": "KARGILI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "BOZOVA",
        "STREET": "KEPİRCE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "BOZOVA",
        "STREET": "KESMETAŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "BOZOVA",
        "STREET": "KEVİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "BOZOVA",
        "STREET": "KILIÇÖREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "BOZOVA",
        "STREET": "KILÇIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "BOZOVA",
        "STREET": "KINDIRALİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "BOZOVA",
        "STREET": "KIRAĞILI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "BOZOVA",
        "STREET": "KIRMIZIPINAR  MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "BOZOVA",
        "STREET": "KIZLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "BOZOVA",
        "STREET": "KONUKSEVER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "BOZOVA",
        "STREET": "KOÇHİSAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "BOZOVA",
        "STREET": "KOÇVERAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "BOZOVA",
        "STREET": "KÖSEŞAHİN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "BOZOVA",
        "STREET": "KÜPELİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "BOZOVA",
        "STREET": "KİLLİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "BOZOVA",
        "STREET": "MAŞUK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "BOZOVA",
        "STREET": "MÜLKÖREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "BOZOVA",
        "STREET": "NARSAİT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "BOZOVA",
        "STREET": "NORÇİN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "BOZOVA",
        "STREET": "ORTATEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "BOZOVA",
        "STREET": "ORTAÖREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "BOZOVA",
        "STREET": "PİRHALİL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "BOZOVA",
        "STREET": "SALUC MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "BOZOVA",
        "STREET": "SAĞIRLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "BOZOVA",
        "STREET": "SEYİTÖREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "BOZOVA",
        "STREET": "SIZAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "BOZOVA",
        "STREET": "SIĞIRCIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "BOZOVA",
        "STREET": "SOĞUKKUYU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "BOZOVA",
        "STREET": "SÖĞÜTLÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "BOZOVA",
        "STREET": "TATARHÜYÜK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "BOZOVA",
        "STREET": "TAŞAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "BOZOVA",
        "STREET": "TOZLUCA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "BOZOVA",
        "STREET": "TUNALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "BOZOVA",
        "STREET": "TÜRKMENÖREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "BOZOVA",
        "STREET": "UZUNBURÇ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "BOZOVA",
        "STREET": "YALINTAŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "BOZOVA",
        "STREET": "YASLICA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "BOZOVA",
        "STREET": "YAVUZ SELİM  MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "BOZOVA",
        "STREET": "YAYLAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "BOZOVA",
        "STREET": "YEŞİL OVA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "BOZOVA",
        "STREET": "ZİVANLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "BOZOVA",
        "STREET": "ÇAKMAKLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "BOZOVA",
        "STREET": "ÇATAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "BOZOVA",
        "STREET": "ÖRGÜLÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "BOZOVA",
        "STREET": "ÖZGÖREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "BOZOVA",
        "STREET": "ÜRÜNLÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "BOZOVA",
        "STREET": "ÜÇDİREK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "BOZOVA",
        "STREET": "İKİZKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "BOZOVA",
        "STREET": "İNCİRLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "BOZOVA",
        "STREET": "ŞANLIAVŞAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "BOZOVA",
        "STREET": "ŞIHLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "BİRECİK",
        "STREET": "ABDALLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "BİRECİK",
        "STREET": "AKARÇAY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "BİRECİK",
        "STREET": "AKPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "BİRECİK",
        "STREET": "ALMAŞAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "BİRECİK",
        "STREET": "ALTINOVA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "BİRECİK",
        "STREET": "ARSLANLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "BİRECİK",
        "STREET": "AYRAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "BİRECİK",
        "STREET": "AŞAĞI FATMACIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "BİRECİK",
        "STREET": "AŞAĞI HABİP MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "BİRECİK",
        "STREET": "AŞAĞI KARKUTLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "BİRECİK",
        "STREET": "AŞAĞI KUYUCAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "BİRECİK",
        "STREET": "AŞAĞIALMAŞAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "BİRECİK",
        "STREET": "AŞAĞIEŞME MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "BİRECİK",
        "STREET": "BAHÇEÖNÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "BİRECİK",
        "STREET": "BAĞLARBAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "BİRECİK",
        "STREET": "BENTBAHÇESİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "BİRECİK",
        "STREET": "BOZDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "BİRECİK",
        "STREET": "BOZTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "BİRECİK",
        "STREET": "BÖĞÜRTLEN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "BİRECİK",
        "STREET": "CUMHURİYET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "BİRECİK",
        "STREET": "CİBİNÖREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "BİRECİK",
        "STREET": "DALOCAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "BİRECİK",
        "STREET": "DAMLICA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "BİRECİK",
        "STREET": "DAĞÖREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "BİRECİK",
        "STREET": "DIŞLIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "BİRECİK",
        "STREET": "DORUCAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "BİRECİK",
        "STREET": "DUYDUK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "BİRECİK",
        "STREET": "DÜZLÜCE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "BİRECİK",
        "STREET": "DİKTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "BİRECİK",
        "STREET": "DİVRİĞİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "BİRECİK",
        "STREET": "EKENEK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "BİRECİK",
        "STREET": "GEÇİTTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "BİRECİK",
        "STREET": "GÖKTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "BİRECİK",
        "STREET": "GÜNIŞIĞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "BİRECİK",
        "STREET": "GÜVENİR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "BİRECİK",
        "STREET": "GÜZELYURT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "BİRECİK",
        "STREET": "HAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "BİRECİK",
        "STREET": "HAYDARAHMET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "BİRECİK",
        "STREET": "HOCA ŞERİF MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "BİRECİK",
        "STREET": "ILGAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "BİRECİK",
        "STREET": "KARABABA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "BİRECİK",
        "STREET": "KARŞIYAKA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "BİRECİK",
        "STREET": "KESKİNCE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "BİRECİK",
        "STREET": "KOCAALİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "BİRECİK",
        "STREET": "KONAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "BİRECİK",
        "STREET": "KURAL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "BİRECİK",
        "STREET": "KURTULUŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "BİRECİK",
        "STREET": "KURUCAHÖYÜK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "BİRECİK",
        "STREET": "KÖMAĞIL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "BİRECİK",
        "STREET": "MENGELLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "BİRECİK",
        "STREET": "MERKEZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "BİRECİK",
        "STREET": "METELER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "BİRECİK",
        "STREET": "MEYANCA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "BİRECİK",
        "STREET": "MEYDAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "BİRECİK",
        "STREET": "MEZRA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "BİRECİK",
        "STREET": "ORTA FATMACIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "BİRECİK",
        "STREET": "SAHA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "BİRECİK",
        "STREET": "SANCAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "BİRECİK",
        "STREET": "SEKİLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "BİRECİK",
        "STREET": "SUMAKLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "BİRECİK",
        "STREET": "SURTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "BİRECİK",
        "STREET": "SÖĞÜRTLEN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "BİRECİK",
        "STREET": "TÜTEN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "BİRECİK",
        "STREET": "UĞURCUK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "BİRECİK",
        "STREET": "YAYLACIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "BİRECİK",
        "STREET": "YENİAKPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "BİRECİK",
        "STREET": "YILMAZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "BİRECİK",
        "STREET": "YUKARI KARABAŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "BİRECİK",
        "STREET": "YUKARIHABİB MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "BİRECİK",
        "STREET": "YUKARIYENİÇAĞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "BİRECİK",
        "STREET": "YUKARIİNCİRLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "BİRECİK",
        "STREET": "YUVACIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "BİRECİK",
        "STREET": "ZİYARET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "BİRECİK",
        "STREET": "ÇOĞAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "BİRECİK",
        "STREET": "ÇİFTLİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "BİRECİK",
        "STREET": "ÇİÇEKALAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "BİRECİK",
        "STREET": "ÖZVEREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "BİRECİK",
        "STREET": "ÜNSAL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "BİRECİK",
        "STREET": "İNCELER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "BİRECİK",
        "STREET": "İNCİRLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "BİRECİK",
        "STREET": "İNCİRLİDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "BİRECİK",
        "STREET": "İNNAPLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "BİRECİK",
        "STREET": "ŞEKERKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "CEYLANPINAR",
        "STREET": "15 TEMMUZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "CEYLANPINAR",
        "STREET": "ADNAN MENDERES MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "CEYLANPINAR",
        "STREET": "AKBULUT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "CEYLANPINAR",
        "STREET": "AKTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "CEYLANPINAR",
        "STREET": "ALACA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "CEYLANPINAR",
        "STREET": "ALTINKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "CEYLANPINAR",
        "STREET": "AVCILI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "CEYLANPINAR",
        "STREET": "AYDIN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "CEYLANPINAR",
        "STREET": "AYDOĞDU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "CEYLANPINAR",
        "STREET": "AŞAĞIDORUKLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "CEYLANPINAR",
        "STREET": "AŞAĞIDURMUŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "CEYLANPINAR",
        "STREET": "AŞAĞIKARATAŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "CEYLANPINAR",
        "STREET": "BAHÇELİEVLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "CEYLANPINAR",
        "STREET": "BOĞALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "CEYLANPINAR",
        "STREET": "BÜYÜKYENİCE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "CEYLANPINAR",
        "STREET": "BÜYÜKYILDIZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "CEYLANPINAR",
        "STREET": "BÜYÜKÇAYLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "CEYLANPINAR",
        "STREET": "CEYLAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "CEYLANPINAR",
        "STREET": "CUMHURİYET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "CEYLANPINAR",
        "STREET": "DAMLACIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "CEYLANPINAR",
        "STREET": "DÜZOVA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "CEYLANPINAR",
        "STREET": "DİKİLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "CEYLANPINAR",
        "STREET": "ENSAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "CEYLANPINAR",
        "STREET": "FATİH SULTAN MEHMET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "CEYLANPINAR",
        "STREET": "GELLEGÖÇ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "CEYLANPINAR",
        "STREET": "GÜLTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "CEYLANPINAR",
        "STREET": "GÜMÜŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "CEYLANPINAR",
        "STREET": "HAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "CEYLANPINAR",
        "STREET": "IŞIKLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "CEYLANPINAR",
        "STREET": "MADEN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "CEYLANPINAR",
        "STREET": "MEHMET AKİF ERSOY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "CEYLANPINAR",
        "STREET": "MEVLANA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "CEYLANPINAR",
        "STREET": "MURATLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "CEYLANPINAR",
        "STREET": "SARAÇÇEŞME MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "CEYLANPINAR",
        "STREET": "SELAHADDİN EYYÜBİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "CEYLANPINAR",
        "STREET": "SEYDO ATİLLA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "CEYLANPINAR",
        "STREET": "SUFRA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "CEYLANPINAR",
        "STREET": "TEKİNLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "CEYLANPINAR",
        "STREET": "TURGUT ÖZAL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "CEYLANPINAR",
        "STREET": "ULUCAMİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "CEYLANPINAR",
        "STREET": "YALÇINKAYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "CEYLANPINAR",
        "STREET": "YENİKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "CEYLANPINAR",
        "STREET": "YENİŞEHİR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "CEYLANPINAR",
        "STREET": "YEŞİLTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "CEYLANPINAR",
        "STREET": "YONCALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "CEYLANPINAR",
        "STREET": "YUKARIDORUKLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "CEYLANPINAR",
        "STREET": "YUKARIDURMUŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "CEYLANPINAR",
        "STREET": "YUKARIESENCE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "CEYLANPINAR",
        "STREET": "YUKARIKARATAŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "CEYLANPINAR",
        "STREET": "YUKARITAŞLIDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "CEYLANPINAR",
        "STREET": "YÜKSEKTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "CEYLANPINAR",
        "STREET": "ÖZBEK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "EYYÜBİYE",
        "STREET": "15 TEMMUZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "EYYÜBİYE",
        "STREET": "ABDURRAHMANDEDE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "EYYÜBİYE",
        "STREET": "AKABE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "EYYÜBİYE",
        "STREET": "AKDİLEK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "EYYÜBİYE",
        "STREET": "AKMAĞARA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "EYYÜBİYE",
        "STREET": "AKMEŞHET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "EYYÜBİYE",
        "STREET": "AKÇAMESCİT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "EYYÜBİYE",
        "STREET": "AKÖREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "EYYÜBİYE",
        "STREET": "AKŞEMSETTİN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "EYYÜBİYE",
        "STREET": "ALKANLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "EYYÜBİYE",
        "STREET": "ALTIN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "EYYÜBİYE",
        "STREET": "ALTINBAŞAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "EYYÜBİYE",
        "STREET": "ALTINTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "EYYÜBİYE",
        "STREET": "AMBARTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "EYYÜBİYE",
        "STREET": "APALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "EYYÜBİYE",
        "STREET": "ASYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "EYYÜBİYE",
        "STREET": "ATLIKONAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "EYYÜBİYE",
        "STREET": "AYRANCI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "EYYÜBİYE",
        "STREET": "AÇMALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "EYYÜBİYE",
        "STREET": "AŞAĞIHEMEDAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "EYYÜBİYE",
        "STREET": "AŞAĞIKOÇLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "EYYÜBİYE",
        "STREET": "AŞAĞIYAZICI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "EYYÜBİYE",
        "STREET": "BAKIRTAŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "EYYÜBİYE",
        "STREET": "BAKIŞLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "EYYÜBİYE",
        "STREET": "BANARLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "EYYÜBİYE",
        "STREET": "BATIKENT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "EYYÜBİYE",
        "STREET": "BAYRAKLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "EYYÜBİYE",
        "STREET": "BAĞIŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "EYYÜBİYE",
        "STREET": "BAŞGÖK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "EYYÜBİYE",
        "STREET": "BAŞGÖZE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "EYYÜBİYE",
        "STREET": "BAŞÖREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "EYYÜBİYE",
        "STREET": "BEYAZYAPRAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "EYYÜBİYE",
        "STREET": "BEYKAPUSU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "EYYÜBİYE",
        "STREET": "BEŞAT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "EYYÜBİYE",
        "STREET": "BIÇAKÇI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "EYYÜBİYE",
        "STREET": "BOZHÖYÜK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "EYYÜBİYE",
        "STREET": "BUHARA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "EYYÜBİYE",
        "STREET": "BULDUK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "EYYÜBİYE",
        "STREET": "BÜYÜKDÜZLÜK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "EYYÜBİYE",
        "STREET": "BÜYÜKHAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "EYYÜBİYE",
        "STREET": "BÜYÜKHANCAĞIZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "EYYÜBİYE",
        "STREET": "BÜYÜKOTLUCA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "EYYÜBİYE",
        "STREET": "BİLDİM MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "EYYÜBİYE",
        "STREET": "CAMİKEBİR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "EYYÜBİYE",
        "STREET": "DEDEOSMAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "EYYÜBİYE",
        "STREET": "DERNEK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "EYYÜBİYE",
        "STREET": "DOLUTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "EYYÜBİYE",
        "STREET": "DURUCA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "EYYÜBİYE",
        "STREET": "DİLİMLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "EYYÜBİYE",
        "STREET": "DİREKLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "EYYÜBİYE",
        "STREET": "EYYÜBİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "EYYÜBİYE",
        "STREET": "EYYÜP NEBİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "EYYÜBİYE",
        "STREET": "EYÜPKENT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "EYYÜBİYE",
        "STREET": "GELİNCİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "EYYÜBİYE",
        "STREET": "GÖL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "EYYÜBİYE",
        "STREET": "GÖLDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "EYYÜBİYE",
        "STREET": "GÖRENLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "EYYÜBİYE",
        "STREET": "GÖZELLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "EYYÜBİYE",
        "STREET": "GÜMÜŞKUŞAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "EYYÜBİYE",
        "STREET": "GÜMÜŞÖREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "EYYÜBİYE",
        "STREET": "GÜNBALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "EYYÜBİYE",
        "STREET": "GÜNEŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "EYYÜBİYE",
        "STREET": "GÜNGÖREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "EYYÜBİYE",
        "STREET": "GÜZELKUYU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "EYYÜBİYE",
        "STREET": "HACIBAYRAM MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "EYYÜBİYE",
        "STREET": "HACILAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "EYYÜBİYE",
        "STREET": "HAKİMDEDE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "EYYÜBİYE",
        "STREET": "HALEPLİBAHÇE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "EYYÜBİYE",
        "STREET": "HAMZABABA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "EYYÜBİYE",
        "STREET": "HANCIĞAZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "EYYÜBİYE",
        "STREET": "HAYATİ HARRANİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "EYYÜBİYE",
        "STREET": "HOROZKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "EYYÜBİYE",
        "STREET": "KADIKENDİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "EYYÜBİYE",
        "STREET": "KADIOĞLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "EYYÜBİYE",
        "STREET": "KAP MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "EYYÜBİYE",
        "STREET": "KAPLANKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "EYYÜBİYE",
        "STREET": "KARAALİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "EYYÜBİYE",
        "STREET": "KARAHİSAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "EYYÜBİYE",
        "STREET": "KARAKOYUNLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "EYYÜBİYE",
        "STREET": "KARAMAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "EYYÜBİYE",
        "STREET": "KEBERLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "EYYÜBİYE",
        "STREET": "KENDİRCİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "EYYÜBİYE",
        "STREET": "KESERDEDE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "EYYÜBİYE",
        "STREET": "KESKİN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "EYYÜBİYE",
        "STREET": "KEÇİKIRAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "EYYÜBİYE",
        "STREET": "KINALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "EYYÜBİYE",
        "STREET": "KIRKMAĞARA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "EYYÜBİYE",
        "STREET": "KIRÇİÇEĞİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "EYYÜBİYE",
        "STREET": "KIZILKUYU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "EYYÜBİYE",
        "STREET": "KOÇÖREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "EYYÜBİYE",
        "STREET": "KUBACIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "EYYÜBİYE",
        "STREET": "KURTULUŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "EYYÜBİYE",
        "STREET": "KURUCUK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "EYYÜBİYE",
        "STREET": "KÖPRÜLÜK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "EYYÜBİYE",
        "STREET": "KÜLÜNÇE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "EYYÜBİYE",
        "STREET": "KÜPELİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "EYYÜBİYE",
        "STREET": "KÜÇÜK ÇAMURLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "EYYÜBİYE",
        "STREET": "KÜÇÜKDÜZLÜK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "EYYÜBİYE",
        "STREET": "KÜÇÜKHAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "EYYÜBİYE",
        "STREET": "MANCE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "EYYÜBİYE",
        "STREET": "MURADİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "EYYÜBİYE",
        "STREET": "MUTLUKAYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "EYYÜBİYE",
        "STREET": "MİHRAPLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "EYYÜBİYE",
        "STREET": "NADİRE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "EYYÜBİYE",
        "STREET": "OLGUNLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "EYYÜBİYE",
        "STREET": "OLUKYANI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "EYYÜBİYE",
        "STREET": "ONİKİLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "EYYÜBİYE",
        "STREET": "ORTAHEMEDAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "EYYÜBİYE",
        "STREET": "OSMANLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "EYYÜBİYE",
        "STREET": "OVABEYLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "EYYÜBİYE",
        "STREET": "OZANLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "EYYÜBİYE",
        "STREET": "PAYAMLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "EYYÜBİYE",
        "STREET": "PINARBAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "EYYÜBİYE",
        "STREET": "RUHA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "EYYÜBİYE",
        "STREET": "SAĞLIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "EYYÜBİYE",
        "STREET": "SEKSENÖREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "EYYÜBİYE",
        "STREET": "SELMAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "EYYÜBİYE",
        "STREET": "SELÇUKLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "EYYÜBİYE",
        "STREET": "SULTANTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "EYYÜBİYE",
        "STREET": "SÜLEYMANŞAH MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "EYYÜBİYE",
        "STREET": "TARLABAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "EYYÜBİYE",
        "STREET": "TAŞLICA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "EYYÜBİYE",
        "STREET": "TEKYAMAÇ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "EYYÜBİYE",
        "STREET": "TEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "EYYÜBİYE",
        "STREET": "TOPDAĞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "EYYÜBİYE",
        "STREET": "TURLUK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "EYYÜBİYE",
        "STREET": "TUZLUCA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "EYYÜBİYE",
        "STREET": "TÜRKMEYDANI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "EYYÜBİYE",
        "STREET": "ULAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "EYYÜBİYE",
        "STREET": "ULUCANLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "EYYÜBİYE",
        "STREET": "ULUKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "EYYÜBİYE",
        "STREET": "UMUROBA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "EYYÜBİYE",
        "STREET": "UĞURLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "EYYÜBİYE",
        "STREET": "VERGİLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "EYYÜBİYE",
        "STREET": "YAKUBİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "EYYÜBİYE",
        "STREET": "YAMAÇALTI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "EYYÜBİYE",
        "STREET": "YANIKÇÖĞÜR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "EYYÜBİYE",
        "STREET": "YARDIMCI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "EYYÜBİYE",
        "STREET": "YAYKILIÇ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "EYYÜBİYE",
        "STREET": "YAĞMURLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "EYYÜBİYE",
        "STREET": "YAŞAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "EYYÜBİYE",
        "STREET": "YEDİYOL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "EYYÜBİYE",
        "STREET": "YENİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "EYYÜBİYE",
        "STREET": "YENİCE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "EYYÜBİYE",
        "STREET": "YEŞİLDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "EYYÜBİYE",
        "STREET": "YOLBAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "EYYÜBİYE",
        "STREET": "YOLBİLİR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "EYYÜBİYE",
        "STREET": "YUKARIHEMEDAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "EYYÜBİYE",
        "STREET": "YUKARIKOÇLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "EYYÜBİYE",
        "STREET": "YUKARIYAZICI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "EYYÜBİYE",
        "STREET": "YUKARIÇAYKUYU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "EYYÜBİYE",
        "STREET": "YUSUF MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "EYYÜBİYE",
        "STREET": "YUSUFPAŞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "EYYÜBİYE",
        "STREET": "ZEYNEPKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "EYYÜBİYE",
        "STREET": "ÇALIŞKANLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "EYYÜBİYE",
        "STREET": "ÇAMURLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "EYYÜBİYE",
        "STREET": "ÖRENLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "EYYÜBİYE",
        "STREET": "ÖZLÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "EYYÜBİYE",
        "STREET": "ÜZERLİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "EYYÜBİYE",
        "STREET": "İKİZCE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "EYYÜBİYE",
        "STREET": "ŞAHİNALAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "EYYÜBİYE",
        "STREET": "ŞAHİNLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "EYYÜBİYE",
        "STREET": "ŞIH MAKSUT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HALFETİ",
        "STREET": "ALTINOVA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HALFETİ",
        "STREET": "ARGAÇ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HALFETİ",
        "STREET": "ARGIL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HALFETİ",
        "STREET": "AŞAĞI GÖKLÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HALFETİ",
        "STREET": "BALABAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HALFETİ",
        "STREET": "BEYBURCU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HALFETİ",
        "STREET": "BOZYAZI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HALFETİ",
        "STREET": "BULAKLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HALFETİ",
        "STREET": "BİTEK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HALFETİ",
        "STREET": "CUMHURİYET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HALFETİ",
        "STREET": "DERGİLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HALFETİ",
        "STREET": "DURAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HALFETİ",
        "STREET": "DUTLUCA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HALFETİ",
        "STREET": "ERİKLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HALFETİ",
        "STREET": "FIRAT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HALFETİ",
        "STREET": "FISTIKÖZÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HALFETİ",
        "STREET": "GÖZELİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HALFETİ",
        "STREET": "GÜLAÇAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HALFETİ",
        "STREET": "GÜNECE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HALFETİ",
        "STREET": "GÜRKUYU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HALFETİ",
        "STREET": "GÜRLÜCE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HALFETİ",
        "STREET": "HİLALLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HALFETİ",
        "STREET": "KALKAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HALFETİ",
        "STREET": "KANTARMA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HALFETİ",
        "STREET": "KARAOTLAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HALFETİ",
        "STREET": "KAVAKLICA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HALFETİ",
        "STREET": "KAYALAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HALFETİ",
        "STREET": "KINIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HALFETİ",
        "STREET": "KURUGÖL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HALFETİ",
        "STREET": "MACUNLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HALFETİ",
        "STREET": "NARLIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HALFETİ",
        "STREET": "ORTAYOL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HALFETİ",
        "STREET": "RÜŞTİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HALFETİ",
        "STREET": "SALMANLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HALFETİ",
        "STREET": "SAVAŞAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HALFETİ",
        "STREET": "SAYLAKKAYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HALFETİ",
        "STREET": "SELDEK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HALFETİ",
        "STREET": "SIRATAŞLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HALFETİ",
        "STREET": "SÜTVEREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HALFETİ",
        "STREET": "SİYAHGÜL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HALFETİ",
        "STREET": "TAVŞANÖREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HALFETİ",
        "STREET": "YENİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HALFETİ",
        "STREET": "YEŞİLÖZEN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HALFETİ",
        "STREET": "YUKARIGÖKLÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HALFETİ",
        "STREET": "ÇAKALLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HALFETİ",
        "STREET": "ÇEBEKOĞLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HALFETİ",
        "STREET": "ÖMERLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HALFETİ",
        "STREET": "ÖZMÜŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HALFETİ",
        "STREET": "ŞİMALİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HALİLİYE",
        "STREET": "ACARYURT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HALİLİYE",
        "STREET": "AHMET YESEVİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HALİLİYE",
        "STREET": "AKDOĞAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HALİLİYE",
        "STREET": "AKPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HALİLİYE",
        "STREET": "AKTAŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HALİLİYE",
        "STREET": "AKÇALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HALİLİYE",
        "STREET": "ALTINDAMLA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HALİLİYE",
        "STREET": "ALTINTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HALİLİYE",
        "STREET": "ANAZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HALİLİYE",
        "STREET": "ASLANLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HALİLİYE",
        "STREET": "ASRİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HALİLİYE",
        "STREET": "ATATÜRK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HALİLİYE",
        "STREET": "AYAZCA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HALİLİYE",
        "STREET": "AÇIKYAZI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HALİLİYE",
        "STREET": "AŞAĞI İÇKARA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HALİLİYE",
        "STREET": "AŞAĞIAKÖREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HALİLİYE",
        "STREET": "AŞAĞIKOYMAT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HALİLİYE",
        "STREET": "AŞAĞIVARLICA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HALİLİYE",
        "STREET": "BAHÇELİEVLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HALİLİYE",
        "STREET": "BAKIMLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HALİLİYE",
        "STREET": "BALKATAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HALİLİYE",
        "STREET": "BALLICA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HALİLİYE",
        "STREET": "BAMYASUYU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HALİLİYE",
        "STREET": "BAĞLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HALİLİYE",
        "STREET": "BAĞLARBAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HALİLİYE",
        "STREET": "BONCUK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HALİLİYE",
        "STREET": "BOYDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HALİLİYE",
        "STREET": "BÜYÜKMİRDESİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HALİLİYE",
        "STREET": "CENGİZ TOPEL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HALİLİYE",
        "STREET": "DALBAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HALİLİYE",
        "STREET": "DAĞETEĞİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HALİLİYE",
        "STREET": "DAĞYANI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HALİLİYE",
        "STREET": "DENİZCİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HALİLİYE",
        "STREET": "DERMAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HALİLİYE",
        "STREET": "DERİNKUYU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HALİLİYE",
        "STREET": "DEVTEYŞTİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HALİLİYE",
        "STREET": "DİKME MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HALİLİYE",
        "STREET": "DİKTAŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HALİLİYE",
        "STREET": "DİPHİSAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HALİLİYE",
        "STREET": "EMİRLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HALİLİYE",
        "STREET": "ERNEBİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HALİLİYE",
        "STREET": "ERTUĞRUL GAZİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HALİLİYE",
        "STREET": "ESENYAYLA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HALİLİYE",
        "STREET": "EĞERKIRAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HALİLİYE",
        "STREET": "GÖKTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HALİLİYE",
        "STREET": "GÜLVEREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HALİLİYE",
        "STREET": "GÜMÜŞTAŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HALİLİYE",
        "STREET": "GÜRPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HALİLİYE",
        "STREET": "GÜVENLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HALİLİYE",
        "STREET": "GÜZELKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HALİLİYE",
        "STREET": "GÜZELYURT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HALİLİYE",
        "STREET": "GÜÇLÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HALİLİYE",
        "STREET": "HALİME MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HALİLİYE",
        "STREET": "HAMİDİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HALİLİYE",
        "STREET": "HAVŞANLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HALİLİYE",
        "STREET": "HIZMALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HALİLİYE",
        "STREET": "KAHRAMAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HALİLİYE",
        "STREET": "KALECİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HALİLİYE",
        "STREET": "KALINBAYAT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HALİLİYE",
        "STREET": "KAMBERİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HALİLİYE",
        "STREET": "KANATLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HALİLİYE",
        "STREET": "KANOĞLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HALİLİYE",
        "STREET": "KAPAKLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HALİLİYE",
        "STREET": "KARATEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HALİLİYE",
        "STREET": "KARGALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HALİLİYE",
        "STREET": "KARPUZLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HALİLİYE",
        "STREET": "KARSIYAKA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HALİLİYE",
        "STREET": "KAVAKBAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HALİLİYE",
        "STREET": "KAYALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HALİLİYE",
        "STREET": "KAYGILI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HALİLİYE",
        "STREET": "KAYNAKLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HALİLİYE",
        "STREET": "KENGERLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HALİLİYE",
        "STREET": "KEPEZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HALİLİYE",
        "STREET": "KEPİRLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HALİLİYE",
        "STREET": "KEREMLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HALİLİYE",
        "STREET": "KESME MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HALİLİYE",
        "STREET": "KEÇİLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HALİLİYE",
        "STREET": "KISAS MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HALİLİYE",
        "STREET": "KIZILPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HALİLİYE",
        "STREET": "KONAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HALİLİYE",
        "STREET": "KONAÇ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HALİLİYE",
        "STREET": "KONUKLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HALİLİYE",
        "STREET": "KOÇAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HALİLİYE",
        "STREET": "KÖRKUYU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HALİLİYE",
        "STREET": "KÖSECİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HALİLİYE",
        "STREET": "KÖSEKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HALİLİYE",
        "STREET": "KÜÇÜKSENEMAĞARA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HALİLİYE",
        "STREET": "MAMUCA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HALİLİYE",
        "STREET": "MAĞARACIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HALİLİYE",
        "STREET": "MEHMETÇİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HALİLİYE",
        "STREET": "MUTLUCA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HALİLİYE",
        "STREET": "MİL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HALİLİYE",
        "STREET": "MİMAR SİNAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HALİLİYE",
        "STREET": "NOKTA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HALİLİYE",
        "STREET": "ORTAÖREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HALİLİYE",
        "STREET": "OSMAN GAZİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HALİLİYE",
        "STREET": "OSMANBEY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HALİLİYE",
        "STREET": "OĞULBEY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HALİLİYE",
        "STREET": "PARMAKKAPI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HALİLİYE",
        "STREET": "PAYAMLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HALİLİYE",
        "STREET": "PAŞABAĞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HALİLİYE",
        "STREET": "PERŞEMBE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HALİLİYE",
        "STREET": "REFAHİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HALİLİYE",
        "STREET": "SANCAKTAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HALİLİYE",
        "STREET": "SARITAŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HALİLİYE",
        "STREET": "SARIŞEYH MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HALİLİYE",
        "STREET": "SARPDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HALİLİYE",
        "STREET": "SEFALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HALİLİYE",
        "STREET": "SELAHADDİN EYYÜBİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HALİLİYE",
        "STREET": "SENDEBELEN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HALİLİYE",
        "STREET": "SEYRANTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HALİLİYE",
        "STREET": "SIRRIN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HALİLİYE",
        "STREET": "SULTAN FATİH MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HALİLİYE",
        "STREET": "SUMAKLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HALİLİYE",
        "STREET": "SUSUZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HALİLİYE",
        "STREET": "SÜLEYMANİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HALİLİYE",
        "STREET": "TAŞLICA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HALİLİYE",
        "STREET": "TEKERLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HALİLİYE",
        "STREET": "TEPEDİBİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HALİLİYE",
        "STREET": "TEPEKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HALİLİYE",
        "STREET": "TERZİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HALİLİYE",
        "STREET": "TOPRAKLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HALİLİYE",
        "STREET": "ULUBATLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HALİLİYE",
        "STREET": "ULUBAĞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HALİLİYE",
        "STREET": "ULUHAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HALİLİYE",
        "STREET": "UMUT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HALİLİYE",
        "STREET": "UZUNKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HALİLİYE",
        "STREET": "VEYSEL KARANİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HALİLİYE",
        "STREET": "YARIMSU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HALİLİYE",
        "STREET": "YAVUZ SELİM MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HALİLİYE",
        "STREET": "YAZILIKAVAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HALİLİYE",
        "STREET": "YEDİKUYU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HALİLİYE",
        "STREET": "YENİCE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HALİLİYE",
        "STREET": "YENİKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HALİLİYE",
        "STREET": "YENİSU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HALİLİYE",
        "STREET": "YENİŞEHİR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HALİLİYE",
        "STREET": "YEROLUK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HALİLİYE",
        "STREET": "YEŞİLDİREK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HALİLİYE",
        "STREET": "YEŞİLKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HALİLİYE",
        "STREET": "YEŞİLTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HALİLİYE",
        "STREET": "YEŞİLYURT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HALİLİYE",
        "STREET": "YILDIZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HALİLİYE",
        "STREET": "YOLYAZI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HALİLİYE",
        "STREET": "YUKARIAKÖREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HALİLİYE",
        "STREET": "YUKARIKOYMAT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HALİLİYE",
        "STREET": "ÇAMLIDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HALİLİYE",
        "STREET": "ÇANAKÇI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HALİLİYE",
        "STREET": "ÇATALLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HALİLİYE",
        "STREET": "ÇEKÇEK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HALİLİYE",
        "STREET": "ÇUKURDORUÇ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HALİLİYE",
        "STREET": "ÇİÇEKLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HALİLİYE",
        "STREET": "ÇİÇEKTEPESİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HALİLİYE",
        "STREET": "ÖRENCİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HALİLİYE",
        "STREET": "ÜZÜMKARA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HALİLİYE",
        "STREET": "ÜÇGÖZE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HALİLİYE",
        "STREET": "ÜÇKONAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HALİLİYE",
        "STREET": "ÜÇKUYU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HALİLİYE",
        "STREET": "İBRİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HALİLİYE",
        "STREET": "İKİAĞIZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HALİLİYE",
        "STREET": "İMAM BAKIR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HALİLİYE",
        "STREET": "İNCİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HALİLİYE",
        "STREET": "İNCİRAĞACI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HALİLİYE",
        "STREET": "İNCİRLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HALİLİYE",
        "STREET": "İPEKYOL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HALİLİYE",
        "STREET": "İRİCE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HALİLİYE",
        "STREET": "ŞAİR NABİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HALİLİYE",
        "STREET": "ŞAİR ŞEVKET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HALİLİYE",
        "STREET": "ŞEHİTLİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HALİLİYE",
        "STREET": "ŞENOCAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HALİLİYE",
        "STREET": "ŞEYHÇOBAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HARRAN",
        "STREET": "15 TEMMUZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HARRAN",
        "STREET": "AHMETKARA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HARRAN",
        "STREET": "AKKUŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HARRAN",
        "STREET": "ALACALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HARRAN",
        "STREET": "ALGILI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HARRAN",
        "STREET": "ALTILI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HARRAN",
        "STREET": "ARALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HARRAN",
        "STREET": "ARIN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HARRAN",
        "STREET": "ASLANKUYUSU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HARRAN",
        "STREET": "AVLAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HARRAN",
        "STREET": "AYDINCIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HARRAN",
        "STREET": "AYDINLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HARRAN",
        "STREET": "AYDÜŞTÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HARRAN",
        "STREET": "AĞCIL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HARRAN",
        "STREET": "AŞAĞI YEŞİLOVA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HARRAN",
        "STREET": "AŞAĞIKESMEKAYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HARRAN",
        "STREET": "AŞAĞIYAKINYOL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HARRAN",
        "STREET": "AŞAĞIYARIMCA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HARRAN",
        "STREET": "BALKAT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HARRAN",
        "STREET": "BALKIR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HARRAN",
        "STREET": "BATIKONACIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HARRAN",
        "STREET": "BAŞAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HARRAN",
        "STREET": "BAŞKARAGÖZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HARRAN",
        "STREET": "BELLİTAŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HARRAN",
        "STREET": "BOZCEYLAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HARRAN",
        "STREET": "BOZYAZI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HARRAN",
        "STREET": "BULDUM MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HARRAN",
        "STREET": "BULGURLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HARRAN",
        "STREET": "BUĞDAYTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HARRAN",
        "STREET": "BÜKDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HARRAN",
        "STREET": "BÜYÜKTAŞLICA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HARRAN",
        "STREET": "BÜYÜKTÜRBE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HARRAN",
        "STREET": "BİLGİLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HARRAN",
        "STREET": "BİNEKLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HARRAN",
        "STREET": "CEVİZLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HARRAN",
        "STREET": "CEYLANGÖZÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HARRAN",
        "STREET": "CUMHURİYET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HARRAN",
        "STREET": "DAMLASU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HARRAN",
        "STREET": "DAYANIKLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HARRAN",
        "STREET": "DEMİRLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HARRAN",
        "STREET": "DORUÇ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HARRAN",
        "STREET": "DOĞUKESMEKAYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HARRAN",
        "STREET": "DOĞUKONACIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HARRAN",
        "STREET": "DURAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HARRAN",
        "STREET": "DİRİLİŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HARRAN",
        "STREET": "EMEKLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HARRAN",
        "STREET": "ESKİHARRAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HARRAN",
        "STREET": "GAZLIKUYU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HARRAN",
        "STREET": "GÖGEÇ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HARRAN",
        "STREET": "GÖKTAŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HARRAN",
        "STREET": "GÖKÇE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HARRAN",
        "STREET": "GÖZCÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HARRAN",
        "STREET": "GÜLLÜBAĞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HARRAN",
        "STREET": "GÜRGELEN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HARRAN",
        "STREET": "GİYİMLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HARRAN",
        "STREET": "HAZRETİ İMAMBAKIR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HARRAN",
        "STREET": "HUZURLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HARRAN",
        "STREET": "HZ. YAKUP MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HARRAN",
        "STREET": "KABATAŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HARRAN",
        "STREET": "KARATAŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HARRAN",
        "STREET": "KAYMAKLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HARRAN",
        "STREET": "KILIÇLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HARRAN",
        "STREET": "KIRMITLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HARRAN",
        "STREET": "KOYUNLUCA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HARRAN",
        "STREET": "KURUYER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HARRAN",
        "STREET": "KUYUKENT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HARRAN",
        "STREET": "KÖKENLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HARRAN",
        "STREET": "KÜPLÜCE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HARRAN",
        "STREET": "KÜTÜKLÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HARRAN",
        "STREET": "KÜÇÜK EKİNLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HARRAN",
        "STREET": "KÜÇÜKMİNARE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HARRAN",
        "STREET": "KÜÇÜKYILDIZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HARRAN",
        "STREET": "MERKEZ-TÜCCARİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HARRAN",
        "STREET": "MEYDANKAPI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HARRAN",
        "STREET": "MİNARE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HARRAN",
        "STREET": "MİYANLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HARRAN",
        "STREET": "ORTAKONACIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HARRAN",
        "STREET": "OĞULCUK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HARRAN",
        "STREET": "PARAPARA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HARRAN",
        "STREET": "SAVACIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HARRAN",
        "STREET": "SAİDE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HARRAN",
        "STREET": "SEFERKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HARRAN",
        "STREET": "SEHRİNCE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HARRAN",
        "STREET": "SELALMAZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HARRAN",
        "STREET": "SOYLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HARRAN",
        "STREET": "SUGELDİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HARRAN",
        "STREET": "SÜLEYMAN DEMİREL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HARRAN",
        "STREET": "SÜTLÜCE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HARRAN",
        "STREET": "TAHILALAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HARRAN",
        "STREET": "TANINMIŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HARRAN",
        "STREET": "TANTANA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HARRAN",
        "STREET": "TAYLICA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HARRAN",
        "STREET": "TEKDAL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HARRAN",
        "STREET": "TEKNELİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HARRAN",
        "STREET": "TOYTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HARRAN",
        "STREET": "TOZLUCA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HARRAN",
        "STREET": "TÜRKOĞLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HARRAN",
        "STREET": "ULUAĞAÇ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HARRAN",
        "STREET": "UZUNYOL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HARRAN",
        "STREET": "VARLIALAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HARRAN",
        "STREET": "YAKACIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HARRAN",
        "STREET": "YARDIMLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HARRAN",
        "STREET": "YAYGILI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HARRAN",
        "STREET": "YAYVANDORUK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HARRAN",
        "STREET": "YENİCE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HARRAN",
        "STREET": "YENİDOĞAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HARRAN",
        "STREET": "YEŞİLOVA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HARRAN",
        "STREET": "YOLGİDER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HARRAN",
        "STREET": "YUKARIKESMEKAYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HARRAN",
        "STREET": "YUKARIYAKINYOL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HARRAN",
        "STREET": "YUKARIYARIMCA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HARRAN",
        "STREET": "YÜNLÜCE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HARRAN",
        "STREET": "ZEYTİNDALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HARRAN",
        "STREET": "ÇALTILI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HARRAN",
        "STREET": "ÇATALHURMA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HARRAN",
        "STREET": "ÇAĞBAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HARRAN",
        "STREET": "ÇEPKENLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HARRAN",
        "STREET": "ÇOLPAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HARRAN",
        "STREET": "ÇİFTÇİLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HARRAN",
        "STREET": "ÇİÇEK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HARRAN",
        "STREET": "ÖNCÜLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HARRAN",
        "STREET": "ÖZBAY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HARRAN",
        "STREET": "ÖZLÜCE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HARRAN",
        "STREET": "ÖZTAŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HARRAN",
        "STREET": "ÖZTÜRK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HARRAN",
        "STREET": "ÜNLÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HARRAN",
        "STREET": "İBNİ TEYMİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HARRAN",
        "STREET": "İMAMBAKIR (MERKEZ) MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HARRAN",
        "STREET": "ŞEHİT ALİ AYDAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HARRAN",
        "STREET": "ŞEYH HAYATİ HARRANİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HARRAN",
        "STREET": "ŞUAYİPŞEHRİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HARRAN",
        "STREET": "ŞÜKÜRALİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HİLVAN",
        "STREET": "AKÇAKEBİR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HİLVAN",
        "STREET": "AKÇAÖREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HİLVAN",
        "STREET": "ALPI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HİLVAN",
        "STREET": "ARABUK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HİLVAN",
        "STREET": "ARICA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HİLVAN",
        "STREET": "ARINIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HİLVAN",
        "STREET": "ARPALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HİLVAN",
        "STREET": "ASLANLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HİLVAN",
        "STREET": "AYDINLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HİLVAN",
        "STREET": "AĞILMUS MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HİLVAN",
        "STREET": "AĞVEREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HİLVAN",
        "STREET": "AŞAĞIEKECE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HİLVAN",
        "STREET": "AŞAĞIKAMIŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HİLVAN",
        "STREET": "AŞAĞIKÜLÜNÇE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HİLVAN",
        "STREET": "AŞAĞIÇATAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HİLVAN",
        "STREET": "BAHÇECİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HİLVAN",
        "STREET": "BAHÇELİEVLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HİLVAN",
        "STREET": "BALKI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HİLVAN",
        "STREET": "BARĞAÇ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HİLVAN",
        "STREET": "BAĞLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HİLVAN",
        "STREET": "BUĞUR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HİLVAN",
        "STREET": "BÖLÜKBAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HİLVAN",
        "STREET": "DOĞRULAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HİLVAN",
        "STREET": "FAİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HİLVAN",
        "STREET": "GELENEK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HİLVAN",
        "STREET": "GÖKTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HİLVAN",
        "STREET": "GÖLCÜK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HİLVAN",
        "STREET": "GÖLEBAKAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HİLVAN",
        "STREET": "GÜLALDI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HİLVAN",
        "STREET": "GÜLUŞAĞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HİLVAN",
        "STREET": "GÜRGÜR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HİLVAN",
        "STREET": "HANMAĞARA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HİLVAN",
        "STREET": "HAYRAT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HİLVAN",
        "STREET": "HOŞİN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HİLVAN",
        "STREET": "KADIKENT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HİLVAN",
        "STREET": "KARABURÇ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HİLVAN",
        "STREET": "KARACURUN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HİLVAN",
        "STREET": "KARAKUCAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HİLVAN",
        "STREET": "KARAPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HİLVAN",
        "STREET": "KAVALIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HİLVAN",
        "STREET": "KEPİRHİSAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HİLVAN",
        "STREET": "KEPİRKUCAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HİLVAN",
        "STREET": "KIRBAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HİLVAN",
        "STREET": "KORGUN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HİLVAN",
        "STREET": "KOVANCI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HİLVAN",
        "STREET": "KUSKUNLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HİLVAN",
        "STREET": "KÜÇÜKGÖLCÜK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HİLVAN",
        "STREET": "MALÖREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HİLVAN",
        "STREET": "MANTARLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HİLVAN",
        "STREET": "ORGIZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HİLVAN",
        "STREET": "OVACIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HİLVAN",
        "STREET": "OYMAAĞAÇ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HİLVAN",
        "STREET": "SALUCA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HİLVAN",
        "STREET": "SUCUHAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HİLVAN",
        "STREET": "SÖĞÜTLÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HİLVAN",
        "STREET": "USTAHASAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HİLVAN",
        "STREET": "UZUNCUK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HİLVAN",
        "STREET": "YAKINYURT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HİLVAN",
        "STREET": "YENİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HİLVAN",
        "STREET": "YEŞERDİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HİLVAN",
        "STREET": "YUVACALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HİLVAN",
        "STREET": "ÇAKMAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HİLVAN",
        "STREET": "ÇAT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HİLVAN",
        "STREET": "ÇAĞILLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HİLVAN",
        "STREET": "ÇİMDELİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HİLVAN",
        "STREET": "ÖMERLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HİLVAN",
        "STREET": "ÖZBAŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HİLVAN",
        "STREET": "ÖZVEREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HİLVAN",
        "STREET": "ÜÇÜZLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "HİLVAN",
        "STREET": "İNCİRLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "KARAKÖPRÜ",
        "STREET": "AKBAYIR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "KARAKÖPRÜ",
        "STREET": "AKINCI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "KARAKÖPRÜ",
        "STREET": "AKPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "KARAKÖPRÜ",
        "STREET": "AKPIYAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "KARAKÖPRÜ",
        "STREET": "AKÇAHİSAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "KARAKÖPRÜ",
        "STREET": "ALİ BABA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "KARAKÖPRÜ",
        "STREET": "APAYDIN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "KARAKÖPRÜ",
        "STREET": "ATAKENT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "KARAKÖPRÜ",
        "STREET": "AYANLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "KARAKÖPRÜ",
        "STREET": "AĞILCIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "KARAKÖPRÜ",
        "STREET": "AĞIZHAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "KARAKÖPRÜ",
        "STREET": "AŞAĞIÇİFTLİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "KARAKÖPRÜ",
        "STREET": "AŞIKKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "KARAKÖPRÜ",
        "STREET": "BAHÇELİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "KARAKÖPRÜ",
        "STREET": "BATIKENT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "KARAKÖPRÜ",
        "STREET": "BAŞÖREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "KARAKÖPRÜ",
        "STREET": "BEZİRCİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "KARAKÖPRÜ",
        "STREET": "BOZKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "KARAKÖPRÜ",
        "STREET": "BUĞDAYHÜYÜK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "KARAKÖPRÜ",
        "STREET": "BÖLÜCEK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "KARAKÖPRÜ",
        "STREET": "BÜYÜK AKZİYARET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "KARAKÖPRÜ",
        "STREET": "BÜYÜKALANLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "KARAKÖPRÜ",
        "STREET": "BÜYÜKKARGILI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "KARAKÖPRÜ",
        "STREET": "BÜYÜKSALKIM MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "KARAKÖPRÜ",
        "STREET": "BÜYÜKÇÖMLEKÇİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "KARAKÖPRÜ",
        "STREET": "BÜYÜKÖRDEK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "KARAKÖPRÜ",
        "STREET": "CEMAL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "KARAKÖPRÜ",
        "STREET": "DEMİRCİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "KARAKÖPRÜ",
        "STREET": "DOLUNAY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "KARAKÖPRÜ",
        "STREET": "DOYUMLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "KARAKÖPRÜ",
        "STREET": "DOĞUKENT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "KARAKÖPRÜ",
        "STREET": "DÜĞER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "KARAKÖPRÜ",
        "STREET": "ERGÜNKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "KARAKÖPRÜ",
        "STREET": "ESEMKULU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "KARAKÖPRÜ",
        "STREET": "ESENTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "KARAKÖPRÜ",
        "STREET": "ESTAĞFURULLAH MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "KARAKÖPRÜ",
        "STREET": "GAZİBEY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "KARAKÖPRÜ",
        "STREET": "GELİBOLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "KARAKÖPRÜ",
        "STREET": "GEÇİTKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "KARAKÖPRÜ",
        "STREET": "GÖBEKLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "KARAKÖPRÜ",
        "STREET": "GÖLGEN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "KARAKÖPRÜ",
        "STREET": "GÖLPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "KARAKÖPRÜ",
        "STREET": "GÜLLÜBAĞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "KARAKÖPRÜ",
        "STREET": "GÜNIŞIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "KARAKÖPRÜ",
        "STREET": "HAMURKESEN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "KARAKÖPRÜ",
        "STREET": "HORZUM MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "KARAKÖPRÜ",
        "STREET": "HÜYÜKLÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "KARAKÖPRÜ",
        "STREET": "KARATAŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "KARAKÖPRÜ",
        "STREET": "KARŞIYAKA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "KARAKÖPRÜ",
        "STREET": "KASIMKUYU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "KARAKÖPRÜ",
        "STREET": "KIRKPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "KARAKÖPRÜ",
        "STREET": "KIZILBURÇ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "KARAKÖPRÜ",
        "STREET": "KIZLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "KARAKÖPRÜ",
        "STREET": "KORUKEZEN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "KARAKÖPRÜ",
        "STREET": "KUYUCAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "KARAKÖPRÜ",
        "STREET": "KUŞLUCA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "KARAKÖPRÜ",
        "STREET": "KÖKSÜREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "KARAKÖPRÜ",
        "STREET": "KÜLAFLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "KARAKÖPRÜ",
        "STREET": "KÜÇÜK AKZİYARET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "KARAKÖPRÜ",
        "STREET": "KÜÇÜKALANLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "KARAKÖPRÜ",
        "STREET": "KÜÇÜKTÜLMEN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "KARAKÖPRÜ",
        "STREET": "LÜLECİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "KARAKÖPRÜ",
        "STREET": "MAŞUK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "KARAKÖPRÜ",
        "STREET": "MEHMETÇİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "KARAKÖPRÜ",
        "STREET": "MUSTAFACIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "KARAKÖPRÜ",
        "STREET": "MİLLİSARAY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "KARAKÖPRÜ",
        "STREET": "NARLIKUYU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "KARAKÖPRÜ",
        "STREET": "OTLUKALAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "KARAKÖPRÜ",
        "STREET": "PINARBAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "KARAKÖPRÜ",
        "STREET": "SAKÇA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "KARAKÖPRÜ",
        "STREET": "SANCAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "KARAKÖPRÜ",
        "STREET": "SARIM MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "KARAKÖPRÜ",
        "STREET": "SEYRANTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "KARAKÖPRÜ",
        "STREET": "TATBURCU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "KARAKÖPRÜ",
        "STREET": "TÜLMEN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "KARAKÖPRÜ",
        "STREET": "TÜRKMEN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "KARAKÖPRÜ",
        "STREET": "UZUNCUK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "KARAKÖPRÜ",
        "STREET": "YARIMTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "KARAKÖPRÜ",
        "STREET": "YAYLACIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "KARAKÖPRÜ",
        "STREET": "YEDİKUYU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "KARAKÖPRÜ",
        "STREET": "YEMİŞLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "KARAKÖPRÜ",
        "STREET": "YIĞINAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "KARAKÖPRÜ",
        "STREET": "YOĞUNBURÇ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "KARAKÖPRÜ",
        "STREET": "YUKARIKOŞMA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "KARAKÖPRÜ",
        "STREET": "YUSUFKUYU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "KARAKÖPRÜ",
        "STREET": "ÇAKMAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "KARAKÖPRÜ",
        "STREET": "ÇAMLIYAYLA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "KARAKÖPRÜ",
        "STREET": "ÇANKAYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "KARAKÖPRÜ",
        "STREET": "ÇIRALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "KARAKÖPRÜ",
        "STREET": "ÇİFTEKEMER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "KARAKÖPRÜ",
        "STREET": "ÖRCÜNLÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "KARAKÖPRÜ",
        "STREET": "ÖVERLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "KARAKÖPRÜ",
        "STREET": "ÖZGEDİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "KARAKÖPRÜ",
        "STREET": "ÖĞÜTÇÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "KARAKÖPRÜ",
        "STREET": "İLHAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "KARAKÖPRÜ",
        "STREET": "İSAÖREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "KARAKÖPRÜ",
        "STREET": "ŞAHİN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "KARAKÖPRÜ",
        "STREET": "ŞANLIURFA 1 NOLU T TİPİ KAPALI CEZA İNFAZ KURUMU",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "KARAKÖPRÜ",
        "STREET": "ŞEKER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "KARAKÖPRÜ",
        "STREET": "ŞENEVLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "KARAKÖPRÜ",
        "STREET": "ŞEYHZELİHA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "SURUÇ",
        "STREET": "AKÖREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "SURUÇ",
        "STREET": "ALİGÖR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "SURUÇ",
        "STREET": "AYBASTI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "SURUÇ",
        "STREET": "AYDIN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "SURUÇ",
        "STREET": "AYHAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "SURUÇ",
        "STREET": "AĞIRTAŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "SURUÇ",
        "STREET": "AŞAĞIKARINCALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "SURUÇ",
        "STREET": "AŞAĞIOYLUM MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "SURUÇ",
        "STREET": "BALABAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "SURUÇ",
        "STREET": "BARIŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "SURUÇ",
        "STREET": "BELLİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "SURUÇ",
        "STREET": "BOZTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "SURUÇ",
        "STREET": "BOZYOKUŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "SURUÇ",
        "STREET": "BÜYÜKAĞACI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "SURUÇ",
        "STREET": "BÜYÜKSERGEN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "SURUÇ",
        "STREET": "BÜYÜKZİYARET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "SURUÇ",
        "STREET": "BİLGE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "SURUÇ",
        "STREET": "BİNATLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "SURUÇ",
        "STREET": "CUMHURİYET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "SURUÇ",
        "STREET": "DEMOKRASİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "SURUÇ",
        "STREET": "DUMLUKUYU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "SURUÇ",
        "STREET": "DİKİLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "SURUÇ",
        "STREET": "DİNLENCE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "SURUÇ",
        "STREET": "EKİLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "SURUÇ",
        "STREET": "ESKİCE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "SURUÇ",
        "STREET": "ESKİÖREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "SURUÇ",
        "STREET": "EZGİL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "SURUÇ",
        "STREET": "FISTIKLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "SURUÇ",
        "STREET": "GÖLEN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "SURUÇ",
        "STREET": "GÖLEÇ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "SURUÇ",
        "STREET": "GÜNEBAKAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "SURUÇ",
        "STREET": "HACILI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "SURUÇ",
        "STREET": "HARMANALAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "SURUÇ",
        "STREET": "HÜRRİYET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "SURUÇ",
        "STREET": "HÜYÜKYANI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "SURUÇ",
        "STREET": "KALKANLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "SURUÇ",
        "STREET": "KAPUCU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "SURUÇ",
        "STREET": "KARA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "SURUÇ",
        "STREET": "KARACA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "SURUÇ",
        "STREET": "KARADUT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "SURUÇ",
        "STREET": "KARAHÜYÜK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "SURUÇ",
        "STREET": "KARAKUYU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "SURUÇ",
        "STREET": "KARATAŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "SURUÇ",
        "STREET": "KEBERLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "SURUÇ",
        "STREET": "KESMECİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "SURUÇ",
        "STREET": "KIRMIT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "SURUÇ",
        "STREET": "KIZILHÜYÜK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "SURUÇ",
        "STREET": "KURUTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "SURUÇ",
        "STREET": "KÖSELER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "SURUÇ",
        "STREET": "KÖSEVELİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "SURUÇ",
        "STREET": "KÜÇÜKKÖPRÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "SURUÇ",
        "STREET": "KÜÇÜKOVA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "SURUÇ",
        "STREET": "KÜÇÜKSERGEN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "SURUÇ",
        "STREET": "KÜÇÜKZİYARET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "SURUÇ",
        "STREET": "MERTİSMAİL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "SURUÇ",
        "STREET": "MOLLAHAMZA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "SURUÇ",
        "STREET": "MÜRŞİTPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "SURUÇ",
        "STREET": "ORTA BOSTANCI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "SURUÇ",
        "STREET": "OYMAKLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "SURUÇ",
        "STREET": "SARAYALTI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "SURUÇ",
        "STREET": "SAYGIN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "SURUÇ",
        "STREET": "TAVŞANKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "SURUÇ",
        "STREET": "TAŞLIKUYU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "SURUÇ",
        "STREET": "TOKÇALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "SURUÇ",
        "STREET": "TOPÇULAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "SURUÇ",
        "STREET": "ULUDÜZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "SURUÇ",
        "STREET": "UYSALLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "SURUÇ",
        "STREET": "YALINCA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "SURUÇ",
        "STREET": "YALPI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "SURUÇ",
        "STREET": "YANALOBA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "SURUÇ",
        "STREET": "YATIRTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "SURUÇ",
        "STREET": "YAYLATEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "SURUÇ",
        "STREET": "YAZIKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "SURUÇ",
        "STREET": "YAĞIŞLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "SURUÇ",
        "STREET": "YENİŞEHİR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "SURUÇ",
        "STREET": "YEĞEN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "SURUÇ",
        "STREET": "YILDIRIM MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "SURUÇ",
        "STREET": "YILDIZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "SURUÇ",
        "STREET": "YUKARIBOSTANCI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "SURUÇ",
        "STREET": "YUMURTALIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "SURUÇ",
        "STREET": "YURTÇİÇEĞİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "SURUÇ",
        "STREET": "YÖNLÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "SURUÇ",
        "STREET": "ZEYREK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "SURUÇ",
        "STREET": "ÇANAKÇI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "SURUÇ",
        "STREET": "ÇAYKARA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "SURUÇ",
        "STREET": "ÇENGELLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "SURUÇ",
        "STREET": "ÇOMAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "SURUÇ",
        "STREET": "ÖLÇEKTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "SURUÇ",
        "STREET": "ÖZGÖREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "SURUÇ",
        "STREET": "ÖZLÜCE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "SURUÇ",
        "STREET": "ÜRKÜTLÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "SURUÇ",
        "STREET": "ÜVEÇLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "SURUÇ",
        "STREET": "ÜÇPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "SURUÇ",
        "STREET": "İZCİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "SİVEREK",
        "STREET": "ABDALAĞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "SİVEREK",
        "STREET": "ALAGÜN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "SİVEREK",
        "STREET": "ALANYURT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "SİVEREK",
        "STREET": "ALTAYLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "SİVEREK",
        "STREET": "ALTINAHIR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "SİVEREK",
        "STREET": "ALTINLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "SİVEREK",
        "STREET": "ALTINPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "SİVEREK",
        "STREET": "ANACAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "SİVEREK",
        "STREET": "ARMAĞANLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "SİVEREK",
        "STREET": "ATMACA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "SİVEREK",
        "STREET": "AVURTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "SİVEREK",
        "STREET": "AYRANCI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "SİVEREK",
        "STREET": "AYVANAT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "SİVEREK",
        "STREET": "AZIKLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "SİVEREK",
        "STREET": "AŞAĞIALINCA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "SİVEREK",
        "STREET": "AŞAĞIKARABAHÇE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "SİVEREK",
        "STREET": "AŞAĞIKARACAÖREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "SİVEREK",
        "STREET": "AŞAĞIYALANKOZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "SİVEREK",
        "STREET": "AŞLICA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "SİVEREK",
        "STREET": "BAHÇE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "SİVEREK",
        "STREET": "BAHÇELİEVLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "SİVEREK",
        "STREET": "BAKIRCA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "SİVEREK",
        "STREET": "BAKMAÇ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "SİVEREK",
        "STREET": "BAKİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "SİVEREK",
        "STREET": "BARDAKÇI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "SİVEREK",
        "STREET": "BATI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "SİVEREK",
        "STREET": "BAYIRÖZÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "SİVEREK",
        "STREET": "BAĞCI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "SİVEREK",
        "STREET": "BAĞLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "SİVEREK",
        "STREET": "BAŞBÜK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "SİVEREK",
        "STREET": "BAŞDEĞİRMEN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "SİVEREK",
        "STREET": "BENEK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "SİVEREK",
        "STREET": "BEYALİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "SİVEREK",
        "STREET": "BEYBABA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "SİVEREK",
        "STREET": "BEYDEŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "SİVEREK",
        "STREET": "BEYÇERİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "SİVEREK",
        "STREET": "BOZKUYU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "SİVEREK",
        "STREET": "BOZLAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "SİVEREK",
        "STREET": "BÖĞÜRTLEN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "SİVEREK",
        "STREET": "BÜRÜNCEK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "SİVEREK",
        "STREET": "BÜYÜKGÖL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "SİVEREK",
        "STREET": "BÜYÜKKAZANLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "SİVEREK",
        "STREET": "BÜYÜKOBA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "SİVEREK",
        "STREET": "BÜYÜKTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "SİVEREK",
        "STREET": "BÜYÜKYAKITLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "SİVEREK",
        "STREET": "CAMİKEBİR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "SİVEREK",
        "STREET": "CANPOLAT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "SİVEREK",
        "STREET": "CİNHİSAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "SİVEREK",
        "STREET": "DAMLICA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "SİVEREK",
        "STREET": "DARDAĞAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "SİVEREK",
        "STREET": "DARICALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "SİVEREK",
        "STREET": "DAĞBAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "SİVEREK",
        "STREET": "DELİKTAŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "SİVEREK",
        "STREET": "DOĞUKENT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "SİVEREK",
        "STREET": "DÖNEMEÇ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "SİVEREK",
        "STREET": "DÜĞERİN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "SİVEREK",
        "STREET": "DİCLE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "SİVEREK",
        "STREET": "DİLEKLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "SİVEREK",
        "STREET": "DİREKLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "SİVEREK",
        "STREET": "DİVAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "SİVEREK",
        "STREET": "EDİZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "SİVEREK",
        "STREET": "ENDARLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "SİVEREK",
        "STREET": "ERBEY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "SİVEREK",
        "STREET": "ERGEN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "SİVEREK",
        "STREET": "ERKONAĞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "SİVEREK",
        "STREET": "ERTEM MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "SİVEREK",
        "STREET": "ESKİHAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "SİVEREK",
        "STREET": "ESMER ÇAYI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "SİVEREK",
        "STREET": "EĞRİÇAY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "SİVEREK",
        "STREET": "FIRAT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "SİVEREK",
        "STREET": "FIRIN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "SİVEREK",
        "STREET": "GAZİKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "SİVEREK",
        "STREET": "GEDİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "SİVEREK",
        "STREET": "GERÇEK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "SİVEREK",
        "STREET": "GÖZCEK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "SİVEREK",
        "STREET": "GÖZELEK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "SİVEREK",
        "STREET": "GÖZELİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "SİVEREK",
        "STREET": "GÖĞERÇİN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "SİVEREK",
        "STREET": "GÜLABİBEY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "SİVEREK",
        "STREET": "GÜLLİCE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "SİVEREK",
        "STREET": "GÜLPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "SİVEREK",
        "STREET": "GÜNEY KARABAHÇE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "SİVEREK",
        "STREET": "GÜNEY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "SİVEREK",
        "STREET": "GÜRAKAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "SİVEREK",
        "STREET": "GÜVENLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "SİVEREK",
        "STREET": "GÜVERCİN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "SİVEREK",
        "STREET": "GÜZELPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "SİVEREK",
        "STREET": "HACI ÖMER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "SİVEREK",
        "STREET": "HALİLİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "SİVEREK",
        "STREET": "HAMAMÖREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "SİVEREK",
        "STREET": "HAMİDİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "SİVEREK",
        "STREET": "HASAN ÇELEBİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "SİVEREK",
        "STREET": "HASIRLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "SİVEREK",
        "STREET": "HAYRİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "SİVEREK",
        "STREET": "KALE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "SİVEREK",
        "STREET": "KALEMLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "SİVEREK",
        "STREET": "KALINAĞAÇ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "SİVEREK",
        "STREET": "KAMIŞLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "SİVEREK",
        "STREET": "KAPIKAYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "SİVEREK",
        "STREET": "KARABAHÇE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "SİVEREK",
        "STREET": "KARACADAĞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "SİVEREK",
        "STREET": "KARADİBEK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "SİVEREK",
        "STREET": "KARAHİSAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "SİVEREK",
        "STREET": "KARAKEÇİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "SİVEREK",
        "STREET": "KARAKOYUN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "SİVEREK",
        "STREET": "KARGALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "SİVEREK",
        "STREET": "KARKAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "SİVEREK",
        "STREET": "KARPUZCU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "SİVEREK",
        "STREET": "KAVAKLIDERE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "SİVEREK",
        "STREET": "KAVALIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "SİVEREK",
        "STREET": "KAYALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "SİVEREK",
        "STREET": "KAYSERİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "SİVEREK",
        "STREET": "KESMEKAYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "SİVEREK",
        "STREET": "KEÇİBURCU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "SİVEREK",
        "STREET": "KEÇİKIRAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "SİVEREK",
        "STREET": "KEŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "SİVEREK",
        "STREET": "KIVANÇLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "SİVEREK",
        "STREET": "KONURTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "SİVEREK",
        "STREET": "KULAKSIZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "SİVEREK",
        "STREET": "KURBAĞALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "SİVEREK",
        "STREET": "KUŞLUGÖL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "SİVEREK",
        "STREET": "KÜPTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "SİVEREK",
        "STREET": "KÜÇÜKGÖL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "SİVEREK",
        "STREET": "MEZRA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "SİVEREK",
        "STREET": "NARLIKAYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "SİVEREK",
        "STREET": "NOHUT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "SİVEREK",
        "STREET": "OFİS MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "SİVEREK",
        "STREET": "OLUKLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "SİVEREK",
        "STREET": "ORTANCA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "SİVEREK",
        "STREET": "OYMAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "SİVEREK",
        "STREET": "OYUKTAŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "SİVEREK",
        "STREET": "PEYNİRCİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "SİVEREK",
        "STREET": "SABANCI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "SİVEREK",
        "STREET": "SALOR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "SİVEREK",
        "STREET": "SARIDAM MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "SİVEREK",
        "STREET": "SARIKAYA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "SİVEREK",
        "STREET": "SARIÖREN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "SİVEREK",
        "STREET": "SAVUCAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "SİVEREK",
        "STREET": "SELİM PINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "SİVEREK",
        "STREET": "SOYDAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "SİVEREK",
        "STREET": "SUMAKLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "SİVEREK",
        "STREET": "SÖYLEMEZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "SİVEREK",
        "STREET": "SİSLİCE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "SİVEREK",
        "STREET": "SİVEREK CEZA İNFAZ KURUMU",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "SİVEREK",
        "STREET": "TANRIVERDİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "SİVEREK",
        "STREET": "TAŞAĞIL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "SİVEREK",
        "STREET": "TAŞHAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "SİVEREK",
        "STREET": "TAŞIKARA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "SİVEREK",
        "STREET": "TAŞLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "SİVEREK",
        "STREET": "TURNA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "SİVEREK",
        "STREET": "UZUNPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "SİVEREK",
        "STREET": "UZUNZİYARET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "SİVEREK",
        "STREET": "YELEKEN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "SİVEREK",
        "STREET": "YENİCELİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "SİVEREK",
        "STREET": "YENİŞEHİR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "SİVEREK",
        "STREET": "YOĞUNCA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "SİVEREK",
        "STREET": "YOĞURTÇU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "SİVEREK",
        "STREET": "YUMRUTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "SİVEREK",
        "STREET": "YÜCELEN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "SİVEREK",
        "STREET": "ZİNCİRLİÇAY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "SİVEREK",
        "STREET": "ÇANAKÇI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "SİVEREK",
        "STREET": "ÇATLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "SİVEREK",
        "STREET": "ÇAVUŞLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "SİVEREK",
        "STREET": "ÇAYLARBAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "SİVEREK",
        "STREET": "ÇAĞA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "SİVEREK",
        "STREET": "ÇAĞDAŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "SİVEREK",
        "STREET": "ÇELTİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "SİVEREK",
        "STREET": "ÇEPNİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "SİVEREK",
        "STREET": "ÇEVİRME MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "SİVEREK",
        "STREET": "ÇUBUKLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "SİVEREK",
        "STREET": "ÇİFTÇİBAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "SİVEREK",
        "STREET": "ÖNDER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "SİVEREK",
        "STREET": "ÖRGÜLÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "SİVEREK",
        "STREET": "ÜLKÜLÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "SİVEREK",
        "STREET": "ÜSTÜNTAŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "SİVEREK",
        "STREET": "İLERİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "SİVEREK",
        "STREET": "İNANLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "SİVEREK",
        "STREET": "ŞAİR İBRAHİM RAFET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "SİVEREK",
        "STREET": "ŞEKERLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "SİVEREK",
        "STREET": "ŞİRİNKUYU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "VİRANŞEHİR",
        "STREET": "ABALAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "VİRANŞEHİR",
        "STREET": "AKKESE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "VİRANŞEHİR",
        "STREET": "AKÇATAŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "VİRANŞEHİR",
        "STREET": "ALAKONAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "VİRANŞEHİR",
        "STREET": "ALTINBAŞAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "VİRANŞEHİR",
        "STREET": "ANIT MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "VİRANŞEHİR",
        "STREET": "ARISU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "VİRANŞEHİR",
        "STREET": "ARITIR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "VİRANŞEHİR",
        "STREET": "ASLANBABA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "VİRANŞEHİR",
        "STREET": "ATATÜRK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "VİRANŞEHİR",
        "STREET": "ATICILAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "VİRANŞEHİR",
        "STREET": "AYAKLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "VİRANŞEHİR",
        "STREET": "AYRIDÜŞEN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "VİRANŞEHİR",
        "STREET": "AZADİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "VİRANŞEHİR",
        "STREET": "AŞAĞITINAZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "VİRANŞEHİR",
        "STREET": "BAHÇELİEVLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "VİRANŞEHİR",
        "STREET": "BALLICA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "VİRANŞEHİR",
        "STREET": "BAŞARAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "VİRANŞEHİR",
        "STREET": "BAŞKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "VİRANŞEHİR",
        "STREET": "BEĞRÜK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "VİRANŞEHİR",
        "STREET": "BOZCA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "VİRANŞEHİR",
        "STREET": "BURÇ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "VİRANŞEHİR",
        "STREET": "BÜYÜKBARDAKÇI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "VİRANŞEHİR",
        "STREET": "BÜYÜKGÖRÜMLÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "VİRANŞEHİR",
        "STREET": "BÜYÜKMUTLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "VİRANŞEHİR",
        "STREET": "BÜYÜKÇAVUŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "VİRANŞEHİR",
        "STREET": "BİNEKLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "VİRANŞEHİR",
        "STREET": "CEYLAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "VİRANŞEHİR",
        "STREET": "CUMHURİYET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "VİRANŞEHİR",
        "STREET": "DEDEKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "VİRANŞEHİR",
        "STREET": "DEFTERDAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "VİRANŞEHİR",
        "STREET": "DEMİRCİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "VİRANŞEHİR",
        "STREET": "DEĞİM MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "VİRANŞEHİR",
        "STREET": "DİKİLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "VİRANŞEHİR",
        "STREET": "DİNÇER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "VİRANŞEHİR",
        "STREET": "DİNÇKÖK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "VİRANŞEHİR",
        "STREET": "EKİNCİLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "VİRANŞEHİR",
        "STREET": "EKİNDÖVER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "VİRANŞEHİR",
        "STREET": "ELBEĞENDİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "VİRANŞEHİR",
        "STREET": "ELGÜN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "VİRANŞEHİR",
        "STREET": "EMİROĞLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "VİRANŞEHİR",
        "STREET": "ENGELLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "VİRANŞEHİR",
        "STREET": "ESER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "VİRANŞEHİR",
        "STREET": "ESKİKALE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "VİRANŞEHİR",
        "STREET": "ESVETOĞLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "VİRANŞEHİR",
        "STREET": "EVCİMEN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "VİRANŞEHİR",
        "STREET": "EYYÜPNEBİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "VİRANŞEHİR",
        "STREET": "EŞKİN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "VİRANŞEHİR",
        "STREET": "GERMEN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "VİRANŞEHİR",
        "STREET": "GÖKTEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "VİRANŞEHİR",
        "STREET": "GÖLBAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "VİRANŞEHİR",
        "STREET": "GÖLCÜK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "VİRANŞEHİR",
        "STREET": "GÖMÜLÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "VİRANŞEHİR",
        "STREET": "GÖNÜLLÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "VİRANŞEHİR",
        "STREET": "GÖZELİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "VİRANŞEHİR",
        "STREET": "GÖZLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "VİRANŞEHİR",
        "STREET": "GÖĞERLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "VİRANŞEHİR",
        "STREET": "GÜLERYÜZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "VİRANŞEHİR",
        "STREET": "GÜRPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "VİRANŞEHİR",
        "STREET": "GÜZLEK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "VİRANŞEHİR",
        "STREET": "HÜRRİYET MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "VİRANŞEHİR",
        "STREET": "IŞILDAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "VİRANŞEHİR",
        "STREET": "KADIKÖY MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "VİRANŞEHİR",
        "STREET": "KALE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "VİRANŞEHİR",
        "STREET": "KARAKUZU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "VİRANŞEHİR",
        "STREET": "KARATAŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "VİRANŞEHİR",
        "STREET": "KARATEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "VİRANŞEHİR",
        "STREET": "KARGALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "VİRANŞEHİR",
        "STREET": "KARINCA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "VİRANŞEHİR",
        "STREET": "KAVURGA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "VİRANŞEHİR",
        "STREET": "KEMERLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "VİRANŞEHİR",
        "STREET": "KERVANSARAYI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "VİRANŞEHİR",
        "STREET": "KEÇELİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "VİRANŞEHİR",
        "STREET": "KINALITEPE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "VİRANŞEHİR",
        "STREET": "KIRBALI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "VİRANŞEHİR",
        "STREET": "KIRKGÖZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "VİRANŞEHİR",
        "STREET": "KIZLARSARAYI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "VİRANŞEHİR",
        "STREET": "KIŞLA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "VİRANŞEHİR",
        "STREET": "KONAKYERİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "VİRANŞEHİR",
        "STREET": "KOŞULU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "VİRANŞEHİR",
        "STREET": "KUCAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "VİRANŞEHİR",
        "STREET": "KUMÇEŞME MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "VİRANŞEHİR",
        "STREET": "KURTULMUŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "VİRANŞEHİR",
        "STREET": "KURUROĞLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "VİRANŞEHİR",
        "STREET": "KÜÇÜKMUTLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "VİRANŞEHİR",
        "STREET": "KÜÇÜKTOPÇA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "VİRANŞEHİR",
        "STREET": "MALTA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "VİRANŞEHİR",
        "STREET": "MEHMETÇİK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "VİRANŞEHİR",
        "STREET": "NERGİZLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "VİRANŞEHİR",
        "STREET": "OĞLAKÇI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "VİRANŞEHİR",
        "STREET": "PINARLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "VİRANŞEHİR",
        "STREET": "REYHANLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "VİRANŞEHİR",
        "STREET": "SAKALAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "VİRANŞEHİR",
        "STREET": "SAMANLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "VİRANŞEHİR",
        "STREET": "SARIBAL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "VİRANŞEHİR",
        "STREET": "SATICIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "VİRANŞEHİR",
        "STREET": "SAYOBA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "VİRANŞEHİR",
        "STREET": "SAĞIRTAŞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "VİRANŞEHİR",
        "STREET": "SAĞLAMLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "VİRANŞEHİR",
        "STREET": "SELAHADDİN EYYUBİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "VİRANŞEHİR",
        "STREET": "SEPETLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "VİRANŞEHİR",
        "STREET": "SERGEN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "VİRANŞEHİR",
        "STREET": "SEVGİLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "VİRANŞEHİR",
        "STREET": "SÖZERİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "VİRANŞEHİR",
        "STREET": "SÜLEYMANİYE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "VİRANŞEHİR",
        "STREET": "TANYELİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "VİRANŞEHİR",
        "STREET": "TAŞYAKA MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "VİRANŞEHİR",
        "STREET": "TAŞÖNÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "VİRANŞEHİR",
        "STREET": "TEKKE MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "VİRANŞEHİR",
        "STREET": "TEKNELİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "VİRANŞEHİR",
        "STREET": "TEPEDÜZÜ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "VİRANŞEHİR",
        "STREET": "TOKLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "VİRANŞEHİR",
        "STREET": "TUNÇBİLEK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "VİRANŞEHİR",
        "STREET": "TÜRKELİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "VİRANŞEHİR",
        "STREET": "ULAKLI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "VİRANŞEHİR",
        "STREET": "UĞURLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "VİRANŞEHİR",
        "STREET": "VİRANŞEHİR CEZAEVİ",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "VİRANŞEHİR",
        "STREET": "YABAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "VİRANŞEHİR",
        "STREET": "YARPUZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "VİRANŞEHİR",
        "STREET": "YAYIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "VİRANŞEHİR",
        "STREET": "YAZGÜNEŞİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "VİRANŞEHİR",
        "STREET": "YAĞIZLAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "VİRANŞEHİR",
        "STREET": "YENİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "VİRANŞEHİR",
        "STREET": "YENİŞEHİR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "VİRANŞEHİR",
        "STREET": "YEŞİLALIÇ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "VİRANŞEHİR",
        "STREET": "YEŞİLDURAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "VİRANŞEHİR",
        "STREET": "YILDIZ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "VİRANŞEHİR",
        "STREET": "YOLBİLEN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "VİRANŞEHİR",
        "STREET": "YOLLARBAŞI MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "VİRANŞEHİR",
        "STREET": "YUKARIBAĞ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "VİRANŞEHİR",
        "STREET": "YUKARIDİLİMLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "VİRANŞEHİR",
        "STREET": "YUKARIŞÖLENLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "VİRANŞEHİR",
        "STREET": "YÜCELER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "VİRANŞEHİR",
        "STREET": "ÇALICIK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "VİRANŞEHİR",
        "STREET": "ÇAMURLU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "VİRANŞEHİR",
        "STREET": "ÇOKRAN MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "VİRANŞEHİR",
        "STREET": "ÇİFTEKUYU MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "VİRANŞEHİR",
        "STREET": "ÇİFTÇİLER MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "VİRANŞEHİR",
        "STREET": "ÖVÜNCÜK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "VİRANŞEHİR",
        "STREET": "ÖZAL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "VİRANŞEHİR",
        "STREET": "ÜÇGÜL MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "VİRANŞEHİR",
        "STREET": "İNCİRLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "VİRANŞEHİR",
        "STREET": "İSA HARABESİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "VİRANŞEHİR",
        "STREET": "ŞAHİNLİ MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "VİRANŞEHİR",
        "STREET": "ŞARKPINAR MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞANLIURFA",
        "DISTRICT": "VİRANŞEHİR",
        "STREET": "ŞIRNAK MAH.",
        "TYPE": "1"
      },
      {
        "CITY": "ŞIRNAK",
        "DISTRICT": "BEYTÜŞŞEBAP",
        "STREET": "AKARSU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ŞIRNAK",
        "DISTRICT": "BEYTÜŞŞEBAP",
        "STREET": "AKÇAYOL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ŞIRNAK",
        "DISTRICT": "BEYTÜŞŞEBAP",
        "STREET": "ALİÇAVUŞ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ŞIRNAK",
        "DISTRICT": "BEYTÜŞŞEBAP",
        "STREET": "AYVALIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ŞIRNAK",
        "DISTRICT": "BEYTÜŞŞEBAP",
        "STREET": "AŞAĞIDERE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ŞIRNAK",
        "DISTRICT": "BEYTÜŞŞEBAP",
        "STREET": "BAŞARAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ŞIRNAK",
        "DISTRICT": "BEYTÜŞŞEBAP",
        "STREET": "BEŞAĞAÇ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ŞIRNAK",
        "DISTRICT": "BEYTÜŞŞEBAP",
        "STREET": "BOLAĞAÇ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ŞIRNAK",
        "DISTRICT": "BEYTÜŞŞEBAP",
        "STREET": "BOĞAZÖREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ŞIRNAK",
        "DISTRICT": "BEYTÜŞŞEBAP",
        "STREET": "BÖLÜCEK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ŞIRNAK",
        "DISTRICT": "BEYTÜŞŞEBAP",
        "STREET": "CEVİZAĞACI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ŞIRNAK",
        "DISTRICT": "BEYTÜŞŞEBAP",
        "STREET": "DAĞALTI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ŞIRNAK",
        "DISTRICT": "BEYTÜŞŞEBAP",
        "STREET": "DOĞANYOL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ŞIRNAK",
        "DISTRICT": "BEYTÜŞŞEBAP",
        "STREET": "ELKİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ŞIRNAK",
        "DISTRICT": "BEYTÜŞŞEBAP",
        "STREET": "GÖKÇE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ŞIRNAK",
        "DISTRICT": "BEYTÜŞŞEBAP",
        "STREET": "GÜNEYYAKA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ŞIRNAK",
        "DISTRICT": "BEYTÜŞŞEBAP",
        "STREET": "GÜNYÜZÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ŞIRNAK",
        "DISTRICT": "BEYTÜŞŞEBAP",
        "STREET": "ILICAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ŞIRNAK",
        "DISTRICT": "BEYTÜŞŞEBAP",
        "STREET": "KARŞIYAKA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ŞIRNAK",
        "DISTRICT": "BEYTÜŞŞEBAP",
        "STREET": "KOYUNOBA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ŞIRNAK",
        "DISTRICT": "BEYTÜŞŞEBAP",
        "STREET": "MEZRA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ŞIRNAK",
        "DISTRICT": "BEYTÜŞŞEBAP",
        "STREET": "MUTLUCA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ŞIRNAK",
        "DISTRICT": "BEYTÜŞŞEBAP",
        "STREET": "ORTALI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ŞIRNAK",
        "DISTRICT": "BEYTÜŞŞEBAP",
        "STREET": "OYMAKAYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ŞIRNAK",
        "DISTRICT": "BEYTÜŞŞEBAP",
        "STREET": "PINARBAŞI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ŞIRNAK",
        "DISTRICT": "BEYTÜŞŞEBAP",
        "STREET": "PİRİNÇLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ŞIRNAK",
        "DISTRICT": "BEYTÜŞŞEBAP",
        "STREET": "SÖĞÜTCE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ŞIRNAK",
        "DISTRICT": "BEYTÜŞŞEBAP",
        "STREET": "TOPTEPE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ŞIRNAK",
        "DISTRICT": "BEYTÜŞŞEBAP",
        "STREET": "YEŞİLÖZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ŞIRNAK",
        "DISTRICT": "BEYTÜŞŞEBAP",
        "STREET": "ÇIĞLICA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ŞIRNAK",
        "DISTRICT": "CİZRE",
        "STREET": "ALİ BEY MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ŞIRNAK",
        "DISTRICT": "CİZRE",
        "STREET": "AŞAĞIKONAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ŞIRNAK",
        "DISTRICT": "CİZRE",
        "STREET": "AŞAĞIÇEŞME KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ŞIRNAK",
        "DISTRICT": "CİZRE",
        "STREET": "BAĞLARBAŞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ŞIRNAK",
        "DISTRICT": "CİZRE",
        "STREET": "BOZALAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ŞIRNAK",
        "DISTRICT": "CİZRE",
        "STREET": "CUDİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ŞIRNAK",
        "DISTRICT": "CİZRE",
        "STREET": "DAĞ KAPI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ŞIRNAK",
        "DISTRICT": "CİZRE",
        "STREET": "DÜZOVA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ŞIRNAK",
        "DISTRICT": "CİZRE",
        "STREET": "DİCLE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ŞIRNAK",
        "DISTRICT": "CİZRE",
        "STREET": "DİRSEKLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ŞIRNAK",
        "DISTRICT": "CİZRE",
        "STREET": "ERDEM KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ŞIRNAK",
        "DISTRICT": "CİZRE",
        "STREET": "GÜRSU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ŞIRNAK",
        "DISTRICT": "CİZRE",
        "STREET": "GÜÇLÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ŞIRNAK",
        "DISTRICT": "CİZRE",
        "STREET": "HAVUZLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ŞIRNAK",
        "DISTRICT": "CİZRE",
        "STREET": "KALE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ŞIRNAK",
        "DISTRICT": "CİZRE",
        "STREET": "KATRAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ŞIRNAK",
        "DISTRICT": "CİZRE",
        "STREET": "KAYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ŞIRNAK",
        "DISTRICT": "CİZRE",
        "STREET": "KEBELİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ŞIRNAK",
        "DISTRICT": "CİZRE",
        "STREET": "KERUH KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ŞIRNAK",
        "DISTRICT": "CİZRE",
        "STREET": "KOCAPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ŞIRNAK",
        "DISTRICT": "CİZRE",
        "STREET": "KONAK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ŞIRNAK",
        "DISTRICT": "CİZRE",
        "STREET": "KORUCU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ŞIRNAK",
        "DISTRICT": "CİZRE",
        "STREET": "KOÇTEPE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ŞIRNAK",
        "DISTRICT": "CİZRE",
        "STREET": "KURTULUŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ŞIRNAK",
        "DISTRICT": "CİZRE",
        "STREET": "KUŞTEPE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ŞIRNAK",
        "DISTRICT": "CİZRE",
        "STREET": "NUR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ŞIRNAK",
        "DISTRICT": "CİZRE",
        "STREET": "SULAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ŞIRNAK",
        "DISTRICT": "CİZRE",
        "STREET": "SUR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ŞIRNAK",
        "DISTRICT": "CİZRE",
        "STREET": "TAŞHÖYÜK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ŞIRNAK",
        "DISTRICT": "CİZRE",
        "STREET": "TEPEÖNÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ŞIRNAK",
        "DISTRICT": "CİZRE",
        "STREET": "ULAŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ŞIRNAK",
        "DISTRICT": "CİZRE",
        "STREET": "UĞUR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ŞIRNAK",
        "DISTRICT": "CİZRE",
        "STREET": "VARLIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ŞIRNAK",
        "DISTRICT": "CİZRE",
        "STREET": "YAFES MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ŞIRNAK",
        "DISTRICT": "CİZRE",
        "STREET": "YAKACIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ŞIRNAK",
        "DISTRICT": "CİZRE",
        "STREET": "YALINTEPE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ŞIRNAK",
        "DISTRICT": "CİZRE",
        "STREET": "YEŞİLYURT KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ŞIRNAK",
        "DISTRICT": "CİZRE",
        "STREET": "ÇATAL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ŞIRNAK",
        "DISTRICT": "CİZRE",
        "STREET": "ÇAVUŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ŞIRNAK",
        "DISTRICT": "CİZRE",
        "STREET": "ÇAĞIL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ŞIRNAK",
        "DISTRICT": "CİZRE",
        "STREET": "ŞAH MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ŞIRNAK",
        "DISTRICT": "GÜÇLÜKONAK",
        "STREET": "AKDİZGİN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ŞIRNAK",
        "DISTRICT": "GÜÇLÜKONAK",
        "STREET": "AKÇAKUŞAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ŞIRNAK",
        "DISTRICT": "GÜÇLÜKONAK",
        "STREET": "BAĞLAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ŞIRNAK",
        "DISTRICT": "GÜÇLÜKONAK",
        "STREET": "BOYUNCUK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ŞIRNAK",
        "DISTRICT": "GÜÇLÜKONAK",
        "STREET": "DAMLABAŞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ŞIRNAK",
        "DISTRICT": "GÜÇLÜKONAK",
        "STREET": "DAMLARCA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ŞIRNAK",
        "DISTRICT": "GÜÇLÜKONAK",
        "STREET": "DAĞYELİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ŞIRNAK",
        "DISTRICT": "GÜÇLÜKONAK",
        "STREET": "DÜĞÜNYURDU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ŞIRNAK",
        "DISTRICT": "GÜÇLÜKONAK",
        "STREET": "FINDIK-BAHÇELİEVLER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ŞIRNAK",
        "DISTRICT": "GÜÇLÜKONAK",
        "STREET": "FINDIK-CUMHURİYET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ŞIRNAK",
        "DISTRICT": "GÜÇLÜKONAK",
        "STREET": "FINDIK-GÜMÜŞYAZI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ŞIRNAK",
        "DISTRICT": "GÜÇLÜKONAK",
        "STREET": "FINDIK-KARŞIYAKA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ŞIRNAK",
        "DISTRICT": "GÜÇLÜKONAK",
        "STREET": "KOÇTEPE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ŞIRNAK",
        "DISTRICT": "GÜÇLÜKONAK",
        "STREET": "ORMANİÇİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ŞIRNAK",
        "DISTRICT": "GÜÇLÜKONAK",
        "STREET": "SAĞKOL KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ŞIRNAK",
        "DISTRICT": "GÜÇLÜKONAK",
        "STREET": "TAŞKONAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ŞIRNAK",
        "DISTRICT": "GÜÇLÜKONAK",
        "STREET": "YATAĞANKAYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ŞIRNAK",
        "DISTRICT": "GÜÇLÜKONAK",
        "STREET": "YAĞIZOYMAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ŞIRNAK",
        "DISTRICT": "GÜÇLÜKONAK",
        "STREET": "YAĞMURKUYUSU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ŞIRNAK",
        "DISTRICT": "GÜÇLÜKONAK",
        "STREET": "YENİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ŞIRNAK",
        "DISTRICT": "GÜÇLÜKONAK",
        "STREET": "ÇEVRİMLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ŞIRNAK",
        "DISTRICT": "GÜÇLÜKONAK",
        "STREET": "ÇOBANKAZANI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ŞIRNAK",
        "DISTRICT": "SİLOPİ",
        "STREET": "AKTEPE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ŞIRNAK",
        "DISTRICT": "SİLOPİ",
        "STREET": "AKYILDIZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ŞIRNAK",
        "DISTRICT": "SİLOPİ",
        "STREET": "BARBAROS MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ŞIRNAK",
        "DISTRICT": "SİLOPİ",
        "STREET": "BAŞAK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ŞIRNAK",
        "DISTRICT": "SİLOPİ",
        "STREET": "BAŞVERİMLİ-CUMHURİYET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ŞIRNAK",
        "DISTRICT": "SİLOPİ",
        "STREET": "BAŞVERİMLİ-KÖPRÜBAŞI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ŞIRNAK",
        "DISTRICT": "SİLOPİ",
        "STREET": "BOSTANCI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ŞIRNAK",
        "DISTRICT": "SİLOPİ",
        "STREET": "BUĞDAYLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ŞIRNAK",
        "DISTRICT": "SİLOPİ",
        "STREET": "BİRLİKKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "ŞIRNAK",
        "DISTRICT": "SİLOPİ",
        "STREET": "CUDİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ŞIRNAK",
        "DISTRICT": "SİLOPİ",
        "STREET": "CUMHURİYET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ŞIRNAK",
        "DISTRICT": "SİLOPİ",
        "STREET": "DAMLACA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ŞIRNAK",
        "DISTRICT": "SİLOPİ",
        "STREET": "DEDELER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ŞIRNAK",
        "DISTRICT": "SİLOPİ",
        "STREET": "DORUKLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ŞIRNAK",
        "DISTRICT": "SİLOPİ",
        "STREET": "DİCLE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ŞIRNAK",
        "DISTRICT": "SİLOPİ",
        "STREET": "ESENLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ŞIRNAK",
        "DISTRICT": "SİLOPİ",
        "STREET": "GÖRÜMLÜ-BOĞAZ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ŞIRNAK",
        "DISTRICT": "SİLOPİ",
        "STREET": "GÖRÜMLÜ-YENİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ŞIRNAK",
        "DISTRICT": "SİLOPİ",
        "STREET": "GÖRÜMLÜ-YOLAĞIZI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ŞIRNAK",
        "DISTRICT": "SİLOPİ",
        "STREET": "KAPILI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ŞIRNAK",
        "DISTRICT": "SİLOPİ",
        "STREET": "KARŞIYAKA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ŞIRNAK",
        "DISTRICT": "SİLOPİ",
        "STREET": "KAVAKÖZÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ŞIRNAK",
        "DISTRICT": "SİLOPİ",
        "STREET": "KAVALLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ŞIRNAK",
        "DISTRICT": "SİLOPİ",
        "STREET": "KÖSRELİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ŞIRNAK",
        "DISTRICT": "SİLOPİ",
        "STREET": "MAHMUTLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ŞIRNAK",
        "DISTRICT": "SİLOPİ",
        "STREET": "NUH MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ŞIRNAK",
        "DISTRICT": "SİLOPİ",
        "STREET": "OFİS MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ŞIRNAK",
        "DISTRICT": "SİLOPİ",
        "STREET": "ORTAKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "ŞIRNAK",
        "DISTRICT": "SİLOPİ",
        "STREET": "OVAKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "ŞIRNAK",
        "DISTRICT": "SİLOPİ",
        "STREET": "PINARÖNÜ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ŞIRNAK",
        "DISTRICT": "SİLOPİ",
        "STREET": "YENİKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "ŞIRNAK",
        "DISTRICT": "SİLOPİ",
        "STREET": "YENİŞEHİR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ŞIRNAK",
        "DISTRICT": "SİLOPİ",
        "STREET": "YEŞİLTEPE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ŞIRNAK",
        "DISTRICT": "SİLOPİ",
        "STREET": "YOLAĞZI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ŞIRNAK",
        "DISTRICT": "SİLOPİ",
        "STREET": "ÇALIŞKAN-ATATÜRK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ŞIRNAK",
        "DISTRICT": "SİLOPİ",
        "STREET": "ÇALIŞKAN-CUMHURİYET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ŞIRNAK",
        "DISTRICT": "SİLOPİ",
        "STREET": "ÇALIŞKAN-FATİH MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ŞIRNAK",
        "DISTRICT": "SİLOPİ",
        "STREET": "ÇALIŞKAN-VATAN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ŞIRNAK",
        "DISTRICT": "SİLOPİ",
        "STREET": "ÇARDAKLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ŞIRNAK",
        "DISTRICT": "SİLOPİ",
        "STREET": "ÇİFTLİKKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "ŞIRNAK",
        "DISTRICT": "SİLOPİ",
        "STREET": "ÖZGEN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ŞIRNAK",
        "DISTRICT": "SİLOPİ",
        "STREET": "ÜÇAĞAÇ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ŞIRNAK",
        "DISTRICT": "SİLOPİ",
        "STREET": "ŞHT. HARUN BOY MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ŞIRNAK",
        "DISTRICT": "ULUDERE",
        "STREET": "ANDAÇ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ŞIRNAK",
        "DISTRICT": "ULUDERE",
        "STREET": "BALLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ŞIRNAK",
        "DISTRICT": "ULUDERE",
        "STREET": "BAĞLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ŞIRNAK",
        "DISTRICT": "ULUDERE",
        "STREET": "BAĞLICA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ŞIRNAK",
        "DISTRICT": "ULUDERE",
        "STREET": "BULAKBAŞI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ŞIRNAK",
        "DISTRICT": "ULUDERE",
        "STREET": "DAĞDİBİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ŞIRNAK",
        "DISTRICT": "ULUDERE",
        "STREET": "GÜLYAZI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ŞIRNAK",
        "DISTRICT": "ULUDERE",
        "STREET": "GÜNDOĞDU MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ŞIRNAK",
        "DISTRICT": "ULUDERE",
        "STREET": "HİLAL-GÜNEŞLİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ŞIRNAK",
        "DISTRICT": "ULUDERE",
        "STREET": "HİLAL-YILDIZ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ŞIRNAK",
        "DISTRICT": "ULUDERE",
        "STREET": "HİLAL-ŞEHAN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ŞIRNAK",
        "DISTRICT": "ULUDERE",
        "STREET": "IŞIKVEREN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ŞIRNAK",
        "DISTRICT": "ULUDERE",
        "STREET": "KILABAN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ŞIRNAK",
        "DISTRICT": "ULUDERE",
        "STREET": "KÜÇÜKÇAY MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ŞIRNAK",
        "DISTRICT": "ULUDERE",
        "STREET": "ORTABAĞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ŞIRNAK",
        "DISTRICT": "ULUDERE",
        "STREET": "ORTAKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "ŞIRNAK",
        "DISTRICT": "ULUDERE",
        "STREET": "ORTASU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ŞIRNAK",
        "DISTRICT": "ULUDERE",
        "STREET": "TAŞDELEN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ŞIRNAK",
        "DISTRICT": "ULUDERE",
        "STREET": "UZUNGEÇİT-AYYILDIZ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ŞIRNAK",
        "DISTRICT": "ULUDERE",
        "STREET": "UZUNGEÇİT-PINARBAŞI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ŞIRNAK",
        "DISTRICT": "ULUDERE",
        "STREET": "UZUNGEÇİT-TANİN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ŞIRNAK",
        "DISTRICT": "ULUDERE",
        "STREET": "UZUNGEÇİT-YENİŞEHİR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ŞIRNAK",
        "DISTRICT": "ULUDERE",
        "STREET": "YEMİŞLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ŞIRNAK",
        "DISTRICT": "ULUDERE",
        "STREET": "YEŞİLYOVA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ŞIRNAK",
        "DISTRICT": "ULUDERE",
        "STREET": "ÖDÜL MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ŞIRNAK",
        "DISTRICT": "ULUDERE",
        "STREET": "ÖZELLİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ŞIRNAK",
        "DISTRICT": "ULUDERE",
        "STREET": "İNCELER KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ŞIRNAK",
        "DISTRICT": "ULUDERE",
        "STREET": "ŞENOBA-AYYILDIZ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ŞIRNAK",
        "DISTRICT": "ULUDERE",
        "STREET": "ŞENOBA-BAŞKAYA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ŞIRNAK",
        "DISTRICT": "ULUDERE",
        "STREET": "ŞENOBA-CUMHURİYET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ŞIRNAK",
        "DISTRICT": "ULUDERE",
        "STREET": "ŞENOBA-GAZİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ŞIRNAK",
        "DISTRICT": "İDİL",
        "STREET": "AKDAĞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ŞIRNAK",
        "DISTRICT": "İDİL",
        "STREET": "AKKOYUNLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ŞIRNAK",
        "DISTRICT": "İDİL",
        "STREET": "AKSOY KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ŞIRNAK",
        "DISTRICT": "İDİL",
        "STREET": "ALAKAMIŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ŞIRNAK",
        "DISTRICT": "İDİL",
        "STREET": "ATAKENT MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ŞIRNAK",
        "DISTRICT": "İDİL",
        "STREET": "AÇMA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ŞIRNAK",
        "DISTRICT": "İDİL",
        "STREET": "AŞAĞI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ŞIRNAK",
        "DISTRICT": "İDİL",
        "STREET": "BAŞAKKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "ŞIRNAK",
        "DISTRICT": "İDİL",
        "STREET": "BEREKETLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ŞIRNAK",
        "DISTRICT": "İDİL",
        "STREET": "BOZBURUN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ŞIRNAK",
        "DISTRICT": "İDİL",
        "STREET": "BOZKIR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ŞIRNAK",
        "DISTRICT": "İDİL",
        "STREET": "DUMANLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ŞIRNAK",
        "DISTRICT": "İDİL",
        "STREET": "DURUKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "ŞIRNAK",
        "DISTRICT": "İDİL",
        "STREET": "DİRSEKLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ŞIRNAK",
        "DISTRICT": "İDİL",
        "STREET": "GEDİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ŞIRNAK",
        "DISTRICT": "İDİL",
        "STREET": "GÜZELOVA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ŞIRNAK",
        "DISTRICT": "İDİL",
        "STREET": "HABERLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ŞIRNAK",
        "DISTRICT": "İDİL",
        "STREET": "HENDEKKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "ŞIRNAK",
        "DISTRICT": "İDİL",
        "STREET": "IŞIK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ŞIRNAK",
        "DISTRICT": "İDİL",
        "STREET": "KARALAR-TEMEL MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ŞIRNAK",
        "DISTRICT": "İDİL",
        "STREET": "KARALAR-ŞAMİL MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ŞIRNAK",
        "DISTRICT": "İDİL",
        "STREET": "KAYALI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ŞIRNAK",
        "DISTRICT": "İDİL",
        "STREET": "KAYI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ŞIRNAK",
        "DISTRICT": "İDİL",
        "STREET": "KAŞIKÇI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ŞIRNAK",
        "DISTRICT": "İDİL",
        "STREET": "KENTLİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ŞIRNAK",
        "DISTRICT": "İDİL",
        "STREET": "KIRCA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ŞIRNAK",
        "DISTRICT": "İDİL",
        "STREET": "KOZLUCA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ŞIRNAK",
        "DISTRICT": "İDİL",
        "STREET": "KURTULUŞ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ŞIRNAK",
        "DISTRICT": "İDİL",
        "STREET": "KUYULU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ŞIRNAK",
        "DISTRICT": "İDİL",
        "STREET": "KÖYCEĞİZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ŞIRNAK",
        "DISTRICT": "İDİL",
        "STREET": "MAĞARAKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "ŞIRNAK",
        "DISTRICT": "İDİL",
        "STREET": "OCAKLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ŞIRNAK",
        "DISTRICT": "İDİL",
        "STREET": "OKÇU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ŞIRNAK",
        "DISTRICT": "İDİL",
        "STREET": "ORTACA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ŞIRNAK",
        "DISTRICT": "İDİL",
        "STREET": "ORTAKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "ŞIRNAK",
        "DISTRICT": "İDİL",
        "STREET": "OYALI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ŞIRNAK",
        "DISTRICT": "İDİL",
        "STREET": "OYMAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ŞIRNAK",
        "DISTRICT": "İDİL",
        "STREET": "PEÇENEK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ŞIRNAK",
        "DISTRICT": "İDİL",
        "STREET": "PINARBAŞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ŞIRNAK",
        "DISTRICT": "İDİL",
        "STREET": "SARIKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "ŞIRNAK",
        "DISTRICT": "İDİL",
        "STREET": "SIRTKÖY-DİCLE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ŞIRNAK",
        "DISTRICT": "İDİL",
        "STREET": "SIRTKÖY-FIRAT MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ŞIRNAK",
        "DISTRICT": "İDİL",
        "STREET": "SULAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ŞIRNAK",
        "DISTRICT": "İDİL",
        "STREET": "TEKEKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "ŞIRNAK",
        "DISTRICT": "İDİL",
        "STREET": "TEPECİK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ŞIRNAK",
        "DISTRICT": "İDİL",
        "STREET": "TEPEKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "ŞIRNAK",
        "DISTRICT": "İDİL",
        "STREET": "TOKLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ŞIRNAK",
        "DISTRICT": "İDİL",
        "STREET": "TOPRAKLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ŞIRNAK",
        "DISTRICT": "İDİL",
        "STREET": "TURGUT ÖZAL MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ŞIRNAK",
        "DISTRICT": "İDİL",
        "STREET": "ULAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ŞIRNAK",
        "DISTRICT": "İDİL",
        "STREET": "UÇARLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ŞIRNAK",
        "DISTRICT": "İDİL",
        "STREET": "UĞRAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ŞIRNAK",
        "DISTRICT": "İDİL",
        "STREET": "VARIMLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ŞIRNAK",
        "DISTRICT": "İDİL",
        "STREET": "YALAZ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ŞIRNAK",
        "DISTRICT": "İDİL",
        "STREET": "YARBAŞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ŞIRNAK",
        "DISTRICT": "İDİL",
        "STREET": "YAVŞAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ŞIRNAK",
        "DISTRICT": "İDİL",
        "STREET": "YAYALAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ŞIRNAK",
        "DISTRICT": "İDİL",
        "STREET": "YAZMAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ŞIRNAK",
        "DISTRICT": "İDİL",
        "STREET": "YAĞMURCA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ŞIRNAK",
        "DISTRICT": "İDİL",
        "STREET": "YENİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ŞIRNAK",
        "DISTRICT": "İDİL",
        "STREET": "YOLAÇAN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ŞIRNAK",
        "DISTRICT": "İDİL",
        "STREET": "YUKARI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ŞIRNAK",
        "DISTRICT": "İDİL",
        "STREET": "YUVALI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ŞIRNAK",
        "DISTRICT": "İDİL",
        "STREET": "YÖRÜK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ŞIRNAK",
        "DISTRICT": "İDİL",
        "STREET": "YÜKSEKKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "ŞIRNAK",
        "DISTRICT": "İDİL",
        "STREET": "ÇINARLI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ŞIRNAK",
        "DISTRICT": "İDİL",
        "STREET": "ÇIĞIR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ŞIRNAK",
        "DISTRICT": "İDİL",
        "STREET": "ÇUKURLU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ŞIRNAK",
        "DISTRICT": "İDİL",
        "STREET": "ÖZBEK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ŞIRNAK",
        "DISTRICT": "İDİL",
        "STREET": "ÖZEN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ŞIRNAK",
        "DISTRICT": "İDİL",
        "STREET": "ÖĞÜNDÜK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ŞIRNAK",
        "DISTRICT": "İDİL",
        "STREET": "ÜÇOK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ŞIRNAK",
        "DISTRICT": "MERKEZ",
        "STREET": "AKÇAY KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ŞIRNAK",
        "DISTRICT": "MERKEZ",
        "STREET": "ARAKÖY",
        "TYPE": "0"
      },
      {
        "CITY": "ŞIRNAK",
        "DISTRICT": "MERKEZ",
        "STREET": "ATATÜRK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ŞIRNAK",
        "DISTRICT": "MERKEZ",
        "STREET": "ATBAŞI KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ŞIRNAK",
        "DISTRICT": "MERKEZ",
        "STREET": "AYDINLIKEVLER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ŞIRNAK",
        "DISTRICT": "MERKEZ",
        "STREET": "BAHÇELİEVLER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ŞIRNAK",
        "DISTRICT": "MERKEZ",
        "STREET": "BALVEREN-AYDINLAR MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ŞIRNAK",
        "DISTRICT": "MERKEZ",
        "STREET": "BALVEREN-HATİPLER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ŞIRNAK",
        "DISTRICT": "MERKEZ",
        "STREET": "BALVEREN-YAYLA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ŞIRNAK",
        "DISTRICT": "MERKEZ",
        "STREET": "BALVEREN-ŞAFAK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ŞIRNAK",
        "DISTRICT": "MERKEZ",
        "STREET": "BAĞPINAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ŞIRNAK",
        "DISTRICT": "MERKEZ",
        "STREET": "BAŞAĞAÇ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ŞIRNAK",
        "DISTRICT": "MERKEZ",
        "STREET": "CUMHURİYET MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ŞIRNAK",
        "DISTRICT": "MERKEZ",
        "STREET": "DAĞKONAK KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ŞIRNAK",
        "DISTRICT": "MERKEZ",
        "STREET": "DİCLE MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ŞIRNAK",
        "DISTRICT": "MERKEZ",
        "STREET": "GAZİ PAŞA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ŞIRNAK",
        "DISTRICT": "MERKEZ",
        "STREET": "GEÇİTBOYU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ŞIRNAK",
        "DISTRICT": "MERKEZ",
        "STREET": "GÜNDOĞDU MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ŞIRNAK",
        "DISTRICT": "MERKEZ",
        "STREET": "GÜNEYCE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ŞIRNAK",
        "DISTRICT": "MERKEZ",
        "STREET": "GÜNEYÇAM KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ŞIRNAK",
        "DISTRICT": "MERKEZ",
        "STREET": "KASRİK-BAHÇELİEVLER MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ŞIRNAK",
        "DISTRICT": "MERKEZ",
        "STREET": "KASRİK-BOĞAZ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ŞIRNAK",
        "DISTRICT": "MERKEZ",
        "STREET": "KASRİK-YENİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ŞIRNAK",
        "DISTRICT": "MERKEZ",
        "STREET": "KAVUNCU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ŞIRNAK",
        "DISTRICT": "MERKEZ",
        "STREET": "KAYABOYUN KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ŞIRNAK",
        "DISTRICT": "MERKEZ",
        "STREET": "KIRKKUYU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ŞIRNAK",
        "DISTRICT": "MERKEZ",
        "STREET": "KIZILSU KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ŞIRNAK",
        "DISTRICT": "MERKEZ",
        "STREET": "KOÇBEYİ KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ŞIRNAK",
        "DISTRICT": "MERKEZ",
        "STREET": "KUMÇATI-ATATÜRK MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ŞIRNAK",
        "DISTRICT": "MERKEZ",
        "STREET": "KUMÇATI-AŞAĞI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ŞIRNAK",
        "DISTRICT": "MERKEZ",
        "STREET": "KUMÇATI-NASIRHAN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ŞIRNAK",
        "DISTRICT": "MERKEZ",
        "STREET": "KUMÇATI-ÇADIRLI MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ŞIRNAK",
        "DISTRICT": "MERKEZ",
        "STREET": "KUMÇATI-ÇAVUŞHAN MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ŞIRNAK",
        "DISTRICT": "MERKEZ",
        "STREET": "KÖRÜKLÜKAYA KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ŞIRNAK",
        "DISTRICT": "MERKEZ",
        "STREET": "TOPTEPE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ŞIRNAK",
        "DISTRICT": "MERKEZ",
        "STREET": "VAKIFKENT MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ŞIRNAK",
        "DISTRICT": "MERKEZ",
        "STREET": "YENİ MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ŞIRNAK",
        "DISTRICT": "MERKEZ",
        "STREET": "YENİASLANBAŞAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ŞIRNAK",
        "DISTRICT": "MERKEZ",
        "STREET": "YEŞİLYURT MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ŞIRNAK",
        "DISTRICT": "MERKEZ",
        "STREET": "YOĞURTÇULAR KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ŞIRNAK",
        "DISTRICT": "MERKEZ",
        "STREET": "İKİZCE KÖYÜ",
        "TYPE": "0"
      },
      {
        "CITY": "ŞIRNAK",
        "DISTRICT": "MERKEZ",
        "STREET": "İSMET PAŞA MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ŞIRNAK",
        "DISTRICT": "MERKEZ",
        "STREET": "ŞEHRİNUH MAH.",
        "TYPE": "0"
      },
      {
        "CITY": "ŞIRNAK",
        "DISTRICT": "MERKEZ",
        "STREET": "ŞIRNAK CEZA İNFAZ KURUMU",
        "TYPE": "0"
      }
    ]