import React, { useEffect, useState } from "react";
import warningIcon from "../assets/images/warning.png";
import messageIcon from "../assets/images/icon/message.png";
import profileIcon from "../assets/images/profile-logo.png";
import { useSelector, useDispatch } from "react-redux";
import { userCargoDetails } from "../redux/slices/userSlice";
import { orderCargoList } from "../redux/slices/orderSlice";
import { commentList } from "../redux/slices/commentSlice";
import ReportModalUser from "../components/ReportModalUser";
import { detailsUser } from "../redux/slices/userSlice";
import { Link } from "react-router-dom";

const CargoDetails = () => {
  const id = window.location.pathname.split("/")[2];

  const { userCargoDetailsData: data, userCargoDetailsLoading: loading } =
    useSelector((state) => state.users);
  const dispatch = useDispatch();

  const { user } = useSelector((state) => state.users);

  const { commentListData, commentListLoading } = useSelector(
    (state) => state.comment
  );

  const { orderListCargoData, orderListCargoLoading } = useSelector(
    (state) => state.order
  );

  useEffect(() => {
    dispatch(userCargoDetails({ id }));
    dispatch(commentList({ id }));
    dispatch(detailsUser());
    dispatch(orderCargoList({ id }));
  }, [dispatch, id]);

  const [isOpenReport, setIsOpenReport] = useState(false);

  return (
    <>
      <div className="w-[85%] mx-auto">
        <div>
          <div className="">
            <div className="text-left sup-header">
              <div className="home-title">
                <div>{data?.fullName}</div>
                {user?.type === 2 && (
                  <div className="ilan-detay-buttons">
                    <a
                      onClick={(e) => setIsOpenReport(!isOpenReport)}
                      className="cursor-pointer"
                    >
                      <span>
                        <img src={warningIcon} alt="warning" />
                      </span>
                      Bildir
                    </a>

                    <Link to={`/message/${data?._id}`}>
                      <span>
                        <img src={messageIcon} alt="message" />
                      </span>
                      Mesaj Gönder
                    </Link>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        {commentListData?.length > 0 ? (
          <div className="mt-[50px]">
            <div>
              <div className="profile-header">Aldığı Yorumlar</div>
              <div className="comment-container">
                {commentListLoading && (
                  <>
                    <p>Bekleyiniz</p>
                  </>
                )}
                {commentListData?.map((comment) => {
                  return (
                    <div className="comment-container-card">
                      <div className="comment-card-header">
                        <div className="comment-profile">
                          {comment?.userId?.image ? (
                            <img src={comment?.userId?.image} alt="profile" />
                          ) : (
                            <img src={profileIcon} alt="profile" />
                          )}{" "}
                        </div>
                        <div className="profile-name">
                          <span>{comment?.userId?.fullName}</span>
                          <br />
                        </div>
                        <div className="stats-hit">
                          <i className="text-[#FF9A00] fa-solid fa-star"></i>{" "}
                          {comment?.point}/5
                        </div>
                      </div>
                      <div className="comment-alt">{comment?.comment}</div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        ) : (
          <p className="text-red-500 pt-8">Yorum yok</p>
        )}

        <div>
          <div className="">
            <div className="profile-header">Verdiği Hizmetler</div>
            <div className="grid grid-cols-1 lg:grid-cols-3 gap-2">
              {orderListCargoData?.map((item) => {
                return (
                  <div className="s-card">
                    <div className=" p-4">
                      <div className="s-card-title">{item?.cargoType}</div>
                      <div className="s-card-c pt-4">
                        <i className="fa-regular fa-user"></i>{" "}
                        {item?.fromLocation.city}<i class="fa-solid fa-arrow-right"></i>{item?.toLocation.city}
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
      <ReportModalUser
        isOpenReport={isOpenReport}
        setIsOpenReport={setIsOpenReport}
        id={data?._id}
      />
    </>
  );
};

export default CargoDetails;
