import React from "react";
import profile from "../assets/images/profile1.png";
import msgStar from "../assets/images/message-star.png";

const Testimontials = () => {
  return (
    <div>
      <div className="nav-container">
        <div className="happy-customer-container">
          <div className="h-customer-co">
            <div className="h-text-customer"> 
              <div className="h-text-header">
                Mutlu
                <br />
                Müşteriler
              </div>
              <div className="h-text-alt">
                borvey ile taşıma hizmeti alan mutlu kullanıcılar
              </div>
            </div>
            <div className="h-button-container">
              <div className="h-button-container-content l1-btn">
                <div className="h-buttons">
                  <i className="fa-solid fa-arrow-left"></i>
                </div>
                <div className="h-buttons r1-btn">
                  <i className="fa-solid fa-arrow-right"></i>
                </div>
              </div>
            </div>
          </div>
          <div className="swiper mySwiper">
            <div className="swiper-wrapper">
              <div className="swiper-slide">
                <div className="h-customer-slide">
                  <div className="profile-p-slide">
                    <div className="profile-p-slide-img">
                      <img src={profile} alt="profile-name" />
                    </div>

                    <div className="profile-slide-text">
                      <div className="italic">
                        “borvey ile ilan bıraktım ve hem takvim olarak hem de fiyat olarak en uygun teklifleri karşılaştırıp hizmet aldım. 
                        Tüm süreç akıcı ve sorunsuzdu. Teşekkürler…”
                      </div>
                      <div className="icon-profile">
                        <img src={msgStar} alt="message-star" />
                      </div>
                      <div className="profile-footer">
                        Gamze Hazel
                        <br />
                        Öğretmen
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="swiper-slide">
                <div className="h-customer-slide">
                  <div className="profile-p-slide">
                    <div className="profile-p-slide-img">
                      <img src="img/profile2.png" alt="profile-name" />
                    </div>

                    <div className="profile-slide-text">
                      <div className="italic">
                        “Lorem ipsum dolor sit amet, consectetur adipiscing
                        elit. Integer vel tortor justo. Vestibulum vitae
                        vulputate lacus. Lorem ipsum dolor sit amet, consectetur
                        adipiscing elit. Integer vel tortor justo. Vestibulum
                        vitae vulputate lacus. Aliquam sollicitudin mauris odio.
                        Fusce egestas consectetur semper vulputate”
                      </div>
                      <div className="icon-profile">
                        <img src="img/message-star.png" alt="message-star" />
                      </div>
                      <div className="profile-footer">
                        Gamze Presley
                        <br />
                        Art Director
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Testimontials;
