import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { API_URL } from "../../utils/config";

const initialState = {
  otpSuccess: null,
  otpLoading: false,
  otpError: null,
};

export const verifyOtp = createAsyncThunk(
  "otp/verification",
  async ({ otpData }, { rejectWithValue }) => {
    try {
      const response = await axios.post(`${API_URL}/auth/verify`, otpData);
      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.response.data.message || "Failed to register"
      );
    }
  }
);


// Create the auth slice
const otpSlice = createSlice({
  name: "otp",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(verifyOtp.pending, (state) => {
        state.otpLoading = true;
        state.otpError = null;
      })
      .addCase(verifyOtp.fulfilled, (state, action) => {
        state.otpLoading = false;
        state.otpSuccess = action.payload.success;
      })
      .addCase(verifyOtp.rejected, (state, action) => {
        state.otpLoading = false;
        state.otpError = action.payload;
      })
  },
});

// Export actions and reducer
export const otpactions = otpSlice.actions;
export default otpSlice.reducer;
