import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { API_URL } from "../../utils/config";

const initialState = {
  demandCreateSuccess: null,
  demandCreateLoading: false,
  demandCreateError: null,
  demandUpdateStatusSuccess: null,
  demandUpdateStatusLoading: false,
  demandUpdateStatusError: null,
  demandListData: null,
  demandListLoading: false,
  demandListError: null,
  demandDetailsData: null,
  demandDetailsLoading: false,
  demandDetailsError: null,
  demandDetailsDataByOrder: null,
  demandDetailsDataByOrderLoading: false,
  demandDetailsDataByOrderError: null
};

export const demandCreate = createAsyncThunk(
    `/demand/create`,
    async ({ id }) => {
      try {
        const token = localStorage.getItem("token");
        const response = await axios.post(
          `${API_URL}/demand/${id}`,
          {},
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        return response.data;
      } catch (error) {
        throw new Error(error.response.data);
      }
    }
  );


export const demandList = createAsyncThunk(
    `/demand/list`,
    async (_, { rejectWithValue }) => {
      try {
        const token = localStorage.getItem("token");
        const response = await axios.get(
          `${API_URL}/demand`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        return response.data;
      } catch (error) {
        throw new Error(error.response.data);
      }
    }
  );

  export const demandDetails = createAsyncThunk(
    `/demand/details`,
    async ({id}, { rejectWithValue }) => {
      try {
        const token = localStorage.getItem("token");
        const response = await axios.get(
          `${API_URL}/demand/${id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        return response.data;
      } catch (error) {
        throw new Error(error.response.data);
      }
    }
  );

  export const demandDetailsByOrder = createAsyncThunk(
    `/demand/detailsOrder`,
    async ({id}, { rejectWithValue }) => {
      try {
        const token = localStorage.getItem("token");
        const response = await axios.get(
          `${API_URL}/demand/${id}/order`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        return response.data;
      } catch (error) {
        throw new Error(error.response.data);
      }
    }
  );
  
  

  export const demandUpdateStatus = createAsyncThunk(
    `/demand/updateStatus`,
    async ({ id, status }) => {
      try {
        const token = localStorage.getItem("token");
        const response = await axios.put(
          `${API_URL}/demand/${id}/${status}`,
          {},
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        return response.data;
      } catch (error) {
        throw new Error(error.response.data);
      }
    }
  );

// Create the demand slice
const demandSlice = createSlice({
  name: "demand",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(demandCreate.pending, (state) => {
        state.demandCreateLoading = true;
        state.demandCreateError = null;
      })
      .addCase(demandCreate.fulfilled, (state, action) => {
        state.demandCreateLoading = false;
        state.demandCreateData = action.payload;
      })
      .addCase(demandCreate.rejected, (state, action) => {
        state.demandCreateLoading = false;
        state.demandCreateError = action.payload;
      })
      .addCase(demandList.pending, (state) => {
        state.demandListLoading = true;
        state.demandListError = null;
      })
      .addCase(demandList.fulfilled, (state, action) => {
        state.demandListLoading = false;
        state.demandListData = action.payload;
      })
      .addCase(demandList.rejected, (state, action) => {
        state.demandListLoading = false;
        state.demandListError = action.payload;
      })
      .addCase(demandDetails.pending, (state) => {
        state.demandDetailsLoading = true;
        state.demandDetailsError = null;
      })
      .addCase(demandDetails.fulfilled, (state, action) => {
        state.demandDetailsLoading = false;
        state.demandDetailsData = action.payload;
      })
      .addCase(demandDetails.rejected, (state, action) => {
        state.demandDetailsLoading = false;
        state.demandDetailsError = action.payload;
      })
      .addCase(demandDetailsByOrder.pending, (state) => {
        state.demandDetailsDataByOrderLoading = true;
        state.demandDetailsDataByOrderError = null;
      })
      .addCase(demandDetailsByOrder.fulfilled, (state, action) => {
        state.demandDetailsDataByOrderLoading = false;
        state.demandDetailsDataByOrder = action.payload;
      })
      .addCase(demandDetailsByOrder.rejected, (state, action) => {
        state.demandDetailsDataByOrderLoading = false;
        state.demandDetailsDataByOrderError = action.payload;
      })
      .addCase(demandUpdateStatus.pending, (state) => {
        state.demandUpdateStatusLoading = true;
        state.demandUpdateStatusError = null;
      })
      .addCase(demandUpdateStatus.fulfilled, (state, action) => {
        state.demandUpdateStatusLoading = false;
        state.demandUpdateStatusSuccess = action.payload;
      })
      .addCase(demandUpdateStatus.rejected, (state, action) => {
        state.demandUpdateStatusLoading = false;
        state.demandUpdateStatusError = action.payload;
      })
  },
});

// Export actions and reducer
export const demandactions = demandSlice.actions;
export default demandSlice.reducer;
