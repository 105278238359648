import React, { useState, useEffect, useRef } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.css";
import Swal from "sweetalert2";
import swal from "sweetalert";
import { useDispatch } from "react-redux";
import { orderCreate } from "../../redux/slices/orderSlice";
import { locations } from "../../utils/locations.js";
const CreateOrder = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [cargoType, setCargoType] = useState("");
  const [roomNumber, setRoomNumber] = useState("");
  const [packingHelp, setPackingHelp] = useState("");
  const [movingMethod, setMovingMethod] = useState("");
  const [specialInstructions, setSpecialInstructions] = useState("");
  const [fromLocation, setFromLocation] = useState({
    city: "",
    district: "",
    neighborhood: "",
  });
  const [toLocation, setToLocation] = useState({
    city: "",
    district: "",
    neighborhood: "",
  });
  const [movingDate, setMovingDate] = useState("");
  const [isNextEnabled, setIsNextEnabled] = useState(false);

  const swiperRef = useRef(null);
  const dispatch = useDispatch();

  useEffect(() => {
    const currentSlideIndex = currentSlide;
    const slide = slides[currentSlideIndex];
    const currentValue = slide?.value;
    setIsNextEnabled(
      currentValue &&
        (typeof currentValue === "string" ? currentValue.trim() !== "" : true)
    );
  }, [
    currentSlide,
    cargoType,
    roomNumber,
    packingHelp,
    movingMethod,
    specialInstructions,
    fromLocation,
    toLocation,
    movingDate,
  ]);

  const handleLocationChange = (setter, field, value) => {
    setter((prev) => ({ ...prev, [field]: value }));
  };

  const slides = [
    {
      title: "Hangisine İhtiyacın var?",
      options: [
        "Evden Eve Nakliyat",
        "Tekli/Parça Ürün Nakliyat",
        "Yük Taşıma",
        "Ofis Nakliyat",
      ],
      value: cargoType,
      setter: setCargoType,
    },
    {
      title: "Kaç odalı ev eşyası taşınacak?",
      options: ["1+1", "2+1", "3+1", "4+1", "Daha Büyük"],
      value: roomNumber,
      setter: setRoomNumber,
    },
    {
      title: "Eşyalarının paketlenmesi için yardım gerekiyor mu?",
      options: [
        "Evet, tüm paketlemeyi nakliyeci yapsın",
        "Yalnız mobilya & beyaz eşya paketlensin",
        "Hayır, paketlemeyi biz yapalım",
      ],
      value: packingHelp,
      setter: setPackingHelp,
    },
    {
      title: "Eşyalar nasıl taşınmalı?",
      options: [
        "Zemin katında",
        "Merdiven - 4 kat veya daha fazla",
        "Bina asansörü kullanılabilir",
        "Yük asansörü var",
        "Modüler asansör kurulmalı",
      ],
      value: movingMethod,
      setter: setMovingMethod,
    },
    {
      title: "Nakliyeci başka neyi bilmeli / neye dikkat etmeli?",
      isTextArea: true,
      value: specialInstructions,
      setter: setSpecialInstructions,
    },
    {
      title: "Eşyalarınız nereden taşınacak?",
      isLocation: true,
      value: fromLocation,
      setter: setFromLocation,
    },
    {
      title: "Eşyalarınız nereye taşınacak?",
      isLocation: true,
      value: toLocation,
      setter: setToLocation,
    },
  ];

  const handleOptionClick = (setter, value) => {
    setter(value);
    setIsNextEnabled(true);
  };

  const [fromCity, setFromCity] = useState(fromLocation.city);
  const [toCity, setToCity] = useState(toLocation.city);

  const uniqueCities = [
    ...new Set(locations.map((location) => location.CITY.toLowerCase())),
  ]
    .sort((a, b) => a.localeCompare(b))
    .map((city) => city.toUpperCase());

  const getUniqueDistricts = (city) => {
    return [
      ...new Set(
        locations
          ?.filter(
            (location) => location.CITY.toLowerCase() === city.toLowerCase()
          )
          .map((location) => location.DISTRICT.toLowerCase())
      ),
    ]
      .sort((a, b) => a.localeCompare(b))
      .map((district) => district.toUpperCase());
  };

  const uniqueDistrictsFrom = getUniqueDistricts(fromCity);
  const uniqueDistrictsTo = getUniqueDistricts(toCity);

  const handleTextAreaChange = (setter, value) => {
    setter(value);
    setIsNextEnabled(value.trim() !== "");
  };

  const handleLocationInputChange = (location, setter, field, value) => {
    handleLocationChange(setter, field, value);
    const isFilled =
      location.city.trim() !== "" &&
      location.district.trim() !== "" &&
      location.neighborhood.trim() !== "";
    setIsNextEnabled(isFilled);
  };

  const handleDateChange = (setter, value) => {
    setter(value);
    setIsNextEnabled(value.trim() !== "");
  };

  const order = {
    cargoType,
    roomNumber,
    packingHelp,
    movingMethod,
    specialInstructions,
    fromLocation,
    toLocation,
    movingDate: "6",
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await dispatch(orderCreate({ order }));
      if (response.payload && response.payload !== 0) {
        Swal.fire({
          title: "Tebrikler!",
          html: "İlanınız Yayında",
          timer: 2000,
          timerProgressBar: true,
          onBeforeOpen: () => {
            Swal.showLoading();
          },
          didClose: () => {
            // Redirect to the desired URL after 2 seconds
            window.location.assign("/dashboard"); // Replace with your desired URL
          },
        });
      } else {
        // Unsuccessful response, show an error message
        swal("Hata!", "Lütfen tekrar deneyin!", "error");
      }
    } catch (error) {
      // Catch any other errors
      Swal.fire({
        title: "Hata",
        text: "Ürün eklenirken bir hata oluştu. Lütfen tekrar deneyin!",
        icon: "error",
      });
    }
  };

  return (
    <div>
      <div className="w-[85%] mx-auto">
        <div className="swiper slide-form">
          <Swiper
            onSlideChange={(swiper) => setCurrentSlide(swiper.activeIndex)}
            slidesPerView={1}
            spaceBetween={30}
            navigation={{
              nextEl: ".swiper-button-next-e",
              prevEl: ".swiper-button-prev-e",
            }}
            onSwiper={(swiper) => (swiperRef.current = swiper)}
            className="swiper-wrapper"
          >
            {slides.map((slide, index) => (
              <SwiperSlide key={index}>
                <div className="slide-item-container">
                  <div className="slide-item-nav">
                    <div className="bg-[#EBE5D9] progress-bar-acc">
                      <div
                        className="bg-[#FF9A00] progress-bar-in"
                        style={{
                          width: `${((index + 1) / slides.length) * 100}%`,
                        }}
                      ></div>
                    </div>
                    <div className="pagination-slide">
                      {index + 1}/{slides.length}
                    </div>
                  </div>
                  <div className="slide-item-title">{slide.title}</div>
                  <div className="slide-item-buttons">
                    {slide.options &&
                      slide.options.map((option, i) => (
                        <button
                          key={i}
                          className={`slide-item-button ${
                            slide.value === option ? "selected" : ""
                          }`}
                          onClick={() =>
                            handleOptionClick(slide.setter, option)
                          }
                        >
                          <div className="checkbox-slide">
                            {slide.value === option && (
                              <div className="slide-check"></div>
                            )}
                          </div>
                          {option}
                        </button>
                      ))}

                    {slide.isTextArea && (
                      <textarea
                        cols="30"
                        rows="10"
                        className="border-[1px] border-solid border-black-400 outline-none p-2 mx-4 rounded-[10px]"
                        placeholder="Dikkat edilmesi gerekenler konusunda bizi bilgilendirin..."
                        value={slide.value}
                        onChange={(e) =>
                          handleTextAreaChange(slide.setter, e.target.value)
                        }
                      ></textarea>
                    )}
                    {slide.isLocation && (
                      <div className="slide-items-inputs">
                        <select
                          placeholder="İl"
                          value={
                            slide.value === fromLocation ? fromCity : toCity
                          }
                          onChange={(e) => {
                            const selectedCity = e.target.value;
                            handleLocationInputChange(
                              slide.value,
                              slide.setter,
                              "city",
                              selectedCity
                            );

                            if (slide.value === fromLocation) {
                              setFromCity(selectedCity);
                            } else {
                              setToCity(selectedCity);
                            }
                          }}
                        >
                          <option value="">İl seçiniz</option>
                          {uniqueCities?.map((city) => (
                            <option key={city} value={city}>
                              {city}
                            </option>
                          ))}
                        </select>

                        <select
                          placeholder="İlçe"
                          value={
                            slide.value === fromLocation
                              ? fromLocation.district
                              : toLocation.district
                          }
                          onChange={(e) =>
                            handleLocationInputChange(
                              slide.value,
                              slide.setter,
                              "district",
                              e.target.value
                            )
                          }
                        >
                          <option value="">İlçe seçiniz</option>
                          {(slide.value === fromLocation
                            ? uniqueDistrictsFrom
                            : uniqueDistrictsTo
                          )?.map((district) => (
                            <option key={district} value={district}>
                              {district}
                            </option>
                          ))}
                        </select>

                        <input
                          placeholder="Mahalle"
                          value={
                            slide.value === fromLocation
                              ? fromLocation.neighborhood
                              : toLocation.neighborhood
                          }
                          onChange={(e) =>
                            handleLocationInputChange(
                              slide.value,
                              slide.setter,
                              "neighborhood",
                              e.target.value
                            )
                          }
                        />
                      </div>
                    )}
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
          <div className="slide-items-paginations">
            <button
              className="swiper-button-prev-e"
              onClick={() => swiperRef.current.slidePrev()}
            >
              Geri
            </button>
            {currentSlide < slides.length - 1 ? (
              <button
                className="swiper-button-next-e"
                onClick={() => isNextEnabled && swiperRef.current.slideNext()}
                disabled={!isNextEnabled}
              >
                Devam Et
              </button>
            ) : (
              <button
                className="swiper-button-next-e"
                onClick={handleSubmit}
                disabled={!isNextEnabled}
              >
                Gönder
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateOrder;
