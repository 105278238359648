export const helpData = [
  {
    id: 1,
    title: "Nasıl ilan veririm?",
    description:
    "Evet, ev, ofis ya da tek parça için de ilan oluşturup nakliyat firmalarından teklif alabilirsiniz.",  
  },
  {
    id: 2,
    title: "İlan verdikten sonra ne yapmam gerekir?",
    description:
    "Evet, ev, ofis ya da tek parça için de ilan oluşturup nakliyat firmalarından teklif alabilirsiniz.",  
  },
  {
    id: 3,
    title: "Tek parça taşıma için ilan verebilir miyim?",
    description:
      "Evet, ev, ofis ya da tek parça için de ilan oluşturup nakliyat firmalarından teklif alabilirsiniz."
  }
];