import React from "react";

const Cookies = () => {
  return (
    <section className="nav-container">
      <div className="mt-[50px]">
        <div className="container">
          <div className="help-alt-paragraph">
            1. GİRİŞ VE TARAFLAR <br />
            İş bu kullanıcı sözleşmesi ve www.borvey.com adresinde ve/veya
            borvey’e ait mobil uygulamalar üzerinde yer alan diğer kurallar,
            borvey tarafından sunulan hizmetlerin şart ve koşulları ve
            www.borvey.com (Bundan sonra “web sitesi” olarak anılacaktır)
            adresinin ve borvey’e ait mobil uygulamanın kullanılmasına ilişkin
            kuralları düzenlemektedir. Web sitesinde ve/veya mobil uygulamada
            yer alan tüm politika ve kurallar işbu kullanıcı sözleşmesinin
            ayrılmaz bir parçasıdır. İşbu sözleşme sizinle, Gökevler Mah. Adnan
            Kahveci Blv. Autopia 1/154 Esenyurt / iSTANBUL adresinde yerleşik
            Borvey Mobil Teknoloji A.Ş. (Bundan sonra “ borvey ” olarak
            anılacaktır) arasında yapılmakta olup; hizmetlerimizi kullanmakla,
            işbu kullanıcı sözleşmesinin hüküm ve şartlarını kabul etmektesiniz.
            borvey aracılık hizmetlerinin sürekliliğini sağlama, ileride doğacak
            teknik zorunluluklar veya mevzuata uyum sağlanması amacıyla işbu
            sözleşmeyi kullanıcılar aleyhine olmamak kaydıyla tek taraflı olarak
            değiştirme ya da tadil etme hakkına sahip olup; değişiklikler
            borvey’ın sitesinde yayınlandıkları tarihte geçerli olurlar. Veri
            Sahibi, işbu Gizlilik Politikası ile kişisel verilerinin işlenmesine
            ilişkin olarak aydınlatılmış olduğunu ve kişisel verilerinin burada
            belirtilen şekilde kullanımına muvafakat ettiğini beyan eder.
            <br />
            <br />
            <span>2. HİZMETİN KAPSAMI</span>
            <br />
            borvey taşınma eşyası taşıma hizmetinden faydalanmak isteyen
            kullanıcılarının, borvey’a ait web sitesi ve/veya mobil uygulama
            üzerinden oluşturdukları talep sonrasında, nakliyeciyi söz konusu
            taşıma işini gerçekleştirmesi için yönlendirir ve kullanıcının
            taşınma eşyası taşıma hizmetinin gerçekleştirilmesine aracı olur.
            <br />
            <br />
            <span>
              3. KULLANIM ŞART VE KOŞULLARI 3.1. Hizmet Talebinin Oluşturulması
              3.1.1.
            </span>
            <br />
            borvey, taşınma eşyası taşıması hizmetleri için kullanıcının
            belirleyeceği gün ve saatlerde uygun olan Nakliyeci’nin
            yönlendirilmesini talep eden bir rezervasyon sistemi sağlar ve
            kullanıcının talep oluşturmasına ve ödeme yapabilmesine olanak
            sağlar. 3.1.2. En az 18 (Onsekiz) yaşında olan ve temyiz kudretini
            haiz herhangi bir kullanıcı, işbu sözleşme koşulları uyarınca
            rezervasyon sisteminde talep edilen bilgileri doğru ve eksiksiz
            olarak doldurarak bir taşıma talebi oluşturabilir. 3.1.3. Geçerli
            bir taşıma talebi oluşturmak için hizmete ilişkin gerekli bilgilerin
            (talep edilen taşınmanın adresi, taşınacak eşyaların adedi ve
            boyutları, evin boyutu ve benzeri özellikleri) eksiksiz, doğru ve
            açık olarak verilmesi ve içeriğinin işbu sözleşmede belirtilen
            şartlara uygun olması gerekir. Hizmet talebinde verilen bilgilerin
            yanlış veya eksik olması teklif edilen hizmet bedelini
            değiştirebilir veya taşıma hizmetlerinin iptal edilmesine sebep
            olabilir. Borvey’ın, bu konuya ilişkin herhangi bir sorumluluğu
            bulunmamakta olup; kullanıcı tarafından bilgilerin yanlış veya eksik
            olması nedeniyle kullanıcıların veya üçüncü kişilerin uğrayacağı
            zararlardan sorumluluk kullanıcıya aittir. 3.1.4. Rezervasyon
            sisteminde adedi sorulmayan eşyalar ya da boyutları standart ev
            eşyaları dışında olan eşyalar ve yine rezervasyon sisteminde talep
            edilemeyen servisler, verilen hizmet bedelini değiştirebilir ya da
            istenen servis gerçekleştirilemeyebilir. 3.1.5. Piyano (duvar ya da
            kuyruklu), para kasası, birden fazla beyaz eşya çeşidi (örneğin evde
            2 adet bulaşık makinesi olması), birden fazla adreste eşya yükleme
            ya da eşya indirme, normal standartlardan büyük mobilyalar (örneğin
            2 metre’den uzun kütüphaneler, 3 metre’den uzun gardıroplar, 2
            metre’den uzun yemek ya da çalışma masaları), ağır veya değerli
            antik mobilyalar taşıma hizmet bedeline dahil değildir. Bu eşyaların
            listesi ayrıca belirtilmelidir. 3.1.6. Hayvan, bitki, silah, tüp,
            gaz gibi tehlikeli madde taşımaları hizmet kapsamında değildir.
            3.1.7. Kullanıcı her halükarda yürürlükteki yasalar gereği suç veya
            hukuka aykırılık teşkil edecek herhangi bir talepte bulunamaz.
            3.1.8. borvey, hizmet talebini değerlendirdikten sonra onay işlemini
            gerçekleştirir. 3.2. Hizmet Talebinde Değişiklik ve İptal Kuralları
            3.2.1. Kullanıcı, rezervasyon onayı yapılmadan önce hizmet
            talebindeki gün, saat, adres, eşya veya servis listesini
            değiştirmekte serbest olup; hizmet talebini iptal edebilir. 3.2.2.
            Rezervasyon onayından sonra hizmet tarihinin değiştirilmek istenmesi
            durumunda hizmetin verileceği tarihe 3 (Üç) günden fazla süre varsa
            ve hizmetin verileceği yeni tarih ilk belirlenen tarihten en az 7
            (Yedi) gün sonra ise değişiklik için herhangi bir ekstra ücret
            alınmayacak, 7 (Yedi) günden daha yakın bir tarihe yapılacak
            değişiklikler için taşıma bedelinin yanı sıra %10 tarih değişikliği
            ücreti yansıtılacaktır. Hizmetin verileceği tarihe 3 (Üç) günden
            daha az süre olan durumlarda ise, eski tarihten en az 7 (Yedi) gün
            sonraya yapılacak değişiklikler için %15, 7 (Yedi) günden daha yakın
            bir tarihe yapılacak değişiklikler için %20 tarih değişikliği ücreti
            doğacaktır. 3.2.3. Kullanıcı, rezervasyon onayı sonrasında hizmeti
            iptal etmesi durumunda; hizmetin verileceği tarihe dek en az 7
            (Yedi) gün var ise taşıma ücretinin hepsini, 7 (Yedi) ila 3 (Üç) gün
            arasında ücretin %85’ini, 3 (Üç) gün ila 24 (Yirmidört) saat
            arasında ücretin %70’ini, 24 (Yirmidört) saatten az bir süre var ise
            ücretin %35’ini iade alabilir. Ödemenin nakit olarak taşıma işlemi
            sırasında yapılacak olması halinde, söz konusu bedeller kullanıcıya
            yansıtılacaktır. 3.3. borvey Tarafından Taşıma Hizmetinin İptali
            3.3.1. borvey, hizmeti ifa edecek ve kalitesi kabul gören kayıtlı
            nakliyecilerinden herhangi birinde müsaitlik olmaması durumunda
            rezervasyon onayını iptal edebilir. Bu durumda eğer ödeme alınmış
            ise, ödemeyi tamamen iade eder. 3.3.2. borvey, kullanıcının işbu
            sözleşme şartlarına uymaması veya işbu sözleşme şartlarını ihlal
            etmesi halinde hizmet vermeyi reddedebilir ve kullanıcının web
            sitesini ve/veya mobil uygulamayı kullanmasını engelleyebilir.
            3.3.3. borvey, kullanıcı tarafından önceden istenmemiş ve
            onaylanmamış eşyalar veya servislerin olması durumunda bu ekstra
            servisleri vermeyi reddedebilir. Ekstra eşyaları taşımayı
            reddedebilir. Yalnızca yapılacağı en başta taahhüt edilen kadar
            hizmet ile bağlı kalınır. 3.4. Rezervasyon sisteminde, kullanıcıdan
            kaynaklanan hatalardan (yanlış tarih ve adres verilmesi, hizmet
            başlama saatinde kullanıcının veya yasal temsilcisinin adreste
            bulunmaması vb.) dolayı Borvey’ın herhangi bir sorumluluğu
            bulunmamaktadır. Borvey söz konusu durumların oluşmaması için azami
            gayreti gösterecektir. 3.5. Hizmete ilişkin olarak yürürlükteki
            mevzuat kapsamında temin etmesi gereken her türlü izin, onay,
            kontrol belgesi, ruhsat vb. belgeye ilişkin sorumluluk borvey’a
            kayıtlı nakliyeciye ait olup; aksi takdirde doğabilecek her türlü
            mali, idari, hukuki ve cezai sorumluluk nakliyeciye aittir. 3.6.
            borvey, taşıma hizmetinin ifası kapsamında, kullanıcının isim, adres
            ve telefon numarasını, kendisine kayıtlı nakliyeci ile paylaşabilir.
            3.7. Elektrik, su ve tesisat işleri, standart hizmet kapsamı
            dışındadır. 3.8. borvey’ın Sorumluluğu: 3.8.1. borvey işbu sözleşme
            kapsamında yalnızca teknoloji desteği sağlamaktadır. Bir diğer
            deyişle borvey, taşıma hizmeti konusunda kullanıcıları nakliyeciler
            ile buluşturmaktadır. Bu bağlamda borvey’ın söz konusu hizmet
            kapsamında herhangi bir sorumluluğu bulunmamakta olup; tüm
            sorumluluk nakliyeciye aittir. 3.8.2. Nakliyeci, eşyaların taşınması
            sırasında paketleme, yükleme veya indirme sırasında yaşanan ve kndi
            kusur ve ihmalinden kaynaklanan tüm hasar ve zararları toplam
            taşıma/hizmet bedeli ile sınırlı olarak karşılayacaktır. 3.8.3.
            Nakliyeci, eşyada zıyaın ve hasarın aşağıdaki sebeplerden
            kaynaklanması durumunda sorumluluktan kurtulur: Nakliyeci, değerli
            maden, taş, mücevher, posta pulu, madenî para, belge veya kıymetli
            evrak taşıyorsa. Kullanıcı tarafından yapılan paketleme veya
            etiketleme yetersizse. Taşınan eşya kullanıcı tarafından işleme tabi
            tutulmuş, yüklenmiş veya boşaltılmışsa. Nakliyeci tarafından
            ambalajlanmamış olan eşya taşınmışsa. Nakliyecinin, muhtemel hasar
            tehlikesine karşı göndereni önceden uyarmış olmasına rağmen,
            kullanıcının ısrarıyla, büyüklüğü ve ağırlığı itibarıyla yükleme ve
            boşaltma yerindeki şartlara uygun olmayan eşya yüklenmiş veya
            boşaltılmışsa. Canlı hayvan veya bitki taşınmışsa. Eşya doğal veya
            ayıplı yapısı dolayısıyla, özellikle kırılma, işlev bozukluğu,
            paslanma, bozulma veya sızma gibi sebeplerle kolaylıkla zarar
            görebilecek nitelikteyse. 3.8.4. Antikalar, güzel sanatlar, manevi
            değeri olan eşyalar, cam, seramik, cep telefonu, bilgisayar, altın,
            ipek halı ve benzeri eşyaların taşınması istenirse, nakliyecinin
            sorumluluğu kapsamı dışında taşıma işlemi gerçekleştirilir. 3.8.5.
            Kullanıcı, eşyanın zıyaından veya hasara uğramasından doğan istem
            hakları; (i) eşyanın zıyaa veya hasara uğramış olduğu açıkça
            görülüyorsa, en geç teslimi izleyen 24 (Yirmidört) saat içinde veya
            (ii) zıyaın veya hasarın açıkça görünmemesi halinde en geç teslimi
            izleyen 2 (İki) gün içinde borvey’e veya nakliyeciye, ilgili hasarın
            açıkça belli olduğu fotoğraf ile birlikte bildirmemişse sona erer.
            Bildirim yazılı olarak destek@Borvey.com adresine veya 08503051690
            numaralı telefon numarasına en geç yukarıda belirtilen tarihlerde
            yapılmalıdır. Aksi taktirde kullanıcı eşyanın zıyaından veya hasara
            uğramasından dolayı herhangi bir talepte bulunamayacak olup;
            nakliyeciye karşı belirtilen sürelerden sonra herhangi bir hak ileri
            süremeyecektir. Bu durumda söz konusu zıyaı veya hasardan kullanıcı
            sorumludur. Tamir veya yerine yenisinin alınmasını gerektiren ve bu
            kapsamda kalan zararların giderilmesinde eşyanın değerini belgeleyen
            dökümanların kullanıcı tarafından sunulması zorunlu olup; aksi
            taktirde Borvey ve/veya nakliyeci tarafından belirlenen bedeller
            esas alınacaktır. 3.8.6. Kullanıcı, kendi takdirine bağlı olarak
            dilerse, taşıma eşyalarının ayrıca sigorta ettirilmesini talep
            edebilir. Bu durumda, söz konusu sigorta masrafları kullanıcı
            tarafından karşılanacaktır.
            <br />
            <br />
            <span>4. ÖDEME </span>
            <br />
            4.1. Kullanıcı, ödemeyi hizmetin verildiği tarihte nakit veya
            rezervasyon onaya sunulmadan önce kredi kartı ile yapabilir. 4.2.
            Hizmet bedeline; hizmet talep formunda ayrıca talep edilmesi halinde
            kolileme (belirtilenler sınırlı olmamak kaydıyla örneğin, mutfak
            eşyalarının, kıyafetlerin ve diğer eşyaların kolilenmesi ve
            benzerleri) avize montaj, duvar montaj ve stor perde montaj ve
            benzeri servisler dahildir. Standart dışı eşya var ise hizmet bedeli
            değişebilir. 4.3. Hizmet karşılığı fiş/fatura veya düzenlenmesi
            gerekli diğer belgeler, ilgili mevzuat gereği düzenleme
            yükümlülükleri bulunuyorsa borvey’a kayıtlı nakliyeci tarafından
            düzenlenir. Söz konusu hizmete ilişkin fatura/fiş veya diğer
            belgeler borvey’e talep edilemeyecek olup; borvey yalnızca kendi
            verdiği hizmetlere ilişkin olarak komisyon bedeli veya iletişim
            hakkı için fatura kesmekle yükümlüdür. 4.4. borvey’e yapılan
            ödemelerde, kartın hamili haricinde bir başkası tarafından hukuka
            aykırı şekilde kullanılması halinde 23.02.2006 tarihli 5464 sayılı
            Banka Kartları ve Kredi Kartları Kanunu ve 10.03.2007 tarihli ve
            26458 sayılı Resmi Gazete’de yayımlanan Banka Kartları ve Kredi
            Kartları Hakkında Yönetmelik hükümlerine göre işlem yapılır. 4.5.
            Online Ödeme Sistemi için ödeme kuruluşları ile anlaşıldığı takdirde
            borvey yalnızca ilgili bedellerin ödenmesi/iadesi için ilgili
            kuruluşa gerekli talimatları iletmekle yükümlüdür. İlgili ödeme
            kuruluşu tarafından Kullanıcı bilgisi ve gerekli diğer tüm
            bilgilerin saklanması ve güvenli bir şekilde tutulması ve yapılan
            işlemlerin güvenli bir şekilde gerçekleştirilmesi ilgili ödeme
            kuruluşunun yükümlülüğündedir.
            <br />
            <br />
            <span>5. TARAFLARIN HAK VE YÜKÜMLÜLÜKLER </span>
            <br />
            5.1. Kullanıcı, söz konusu taşınma eşyası taşıma hizmetinin borvey’e
            kayıtlı nakliyeciler veya borvey tarafından seçilecek firmalar ile
            yapılacağını kabul eder. Kullanıcı, söz konusu firmalarda müsaitlik
            durumu olmaması veya işin kabul edilmemesi durumunda talebin
            reddedilebileceğini veya tarihin değiştirilmesi gerekebileceğini,
            kabul eder. 5.2. Kullanıcı, bir evden, bürodan veya benzeri bir
            yerden alınıp benzeri bir yere taşınan eşyanın, mobilya için geçerli
            olduğunu, insan, evcil hayvan gibi taşımaların mümkün olmadığını,
            kabul, beyan ve taahhüt eder. 5.3. Kullanıcı, talep formunda web
            sitesine ve/veya mobil uygulamaya sağlayacağı verilerin doğru,
            güncel ve eksiksiz olacağını, aksi halde hizmetin verilememesi,
            hatalı, gecikmeli veya eksik verilmesinden doğacak zarardan
            borvey’ın sorumlu olmayacağını kabul, beyan ve taahhüt eder. 5.4.
            Kullanıcı, taşınma için gerekli giriş-çıkış, saat, asansör
            kullanımı, park, park yeri ve sair izinleri taşınma günü öncesinde
            varsa ilgili site yönetimi veya benzer üçüncü kişilerden almış
            olmayı, aksi halde hizmetin verilememesi, gecikmeli veya eksik
            verilmesi sebebiyle doğacak zarardan kendisinin sorumlu olacağını,
            bu izinlerin alınmaması sebebiyle nakliyecilerin bekletilmesi ve
            işin fazladan saate sebep olması durumunda fazla ücret ödemek
            zorunda kalabileceğini kabul, beyan ve taahhüt eder. 5.5. Kullanıcı,
            taşıma sırasında kendisi veya yetkili bir temsilcisinin taşıma
            işlemlerinin gerçekleştirileceği adreslerde bulunacağını, bu kişinin
            18 (Onsekiz) yaşından büyük ve hak ve fiil ehliyetinin olduğunu,
            nakliyecinin adresi terk etmeden önce bu kişinin kendi adına beyanda
            bulunmaya yetkili olduğunu, bir diğer deyişle olası bir hasar
            talebinin yetkili temsilci tarafından işbu sözleşmede belirtilen
            şartlarda iletilmesi gerektiğini bildiğini, kabul, beyan ve taahhüt
            eder. 5.6. Kullanıcı, taşınma eşyası tehlikeli eşyadan sayılıyorsa
            eşyadan kaynaklanacak tehlike hakkında genel olarak borvey’i ve
            nakliyeciyi bilgilendirmekle yükümlüdür. Nakliyeci, eşyayı teslim
            alırken tehlikenin türünü bilmiyorsa veya kendilerine herhangi bir
            bildirimde bulunulmamışsa, kullanıcıya karşı herhangi bir tazmin
            yükümlülüğü doğmaksızın, tehlikeli malın boşaltılmasını,
            depolanmasını, geri taşınmasını veya gerektiğinde imhasını ve
            zararsız duruma getirilmesini ve bu önlemler sebebiyle gerekli
            giderlerin karşılanmasını, kullanıcıdan isteyebilir. Ancak ve her
            halükarda yürürlükteki yasalarca taşınması ve bulundurulması yasak
            olan madde ve cisimler taşınmayacaktır. 5.7. Kullanıcı, taşınacak
            eşyalar listesine ek eşyalar olacaksa taşınmadan evvel en geç 48
            (Kırksekiz) saat öncesine kadar haber vermekle yükümlüdür. Aksi
            halde, nakliye aracı ek eşyayı taşıyabilir kapasitede ise taşımayı
            kabul edebilir fakat hizmet bedeli değişebilir. Ancak taşıma
            kapasitesi yok ise, ikinci bir nakliye servisi ayarlanması mümkün
            olabilir. Bu durumda ikinci araç için ödenecek ücret, sıfırdan
            taşınma eşyası taşıma işlemi yapılan hizmet gibi
            ücretlendirilecektir. 5.8. Kullanıcı, taşıma gününden 5 (Beş) gün
            öncesine dek eşya listesini azaltmak istediğine yönelik talebi
            Borvey’a bildirmekle yükümlüdür. Böylece yeni eşya listesinin
            kapasitesi gereğince, tahsis edilecek taşıma aracı ve ekibin
            değiştirilmesi gerekiyorsa ödenecek ücrette değişiklik olabilir.
            5.9. Kullanıcı, belirteceği eşya listesinde, eşyaların boyutu veya
            ağırlığı ile ilgili standardın üzerinde bir boyut söz konusu ise
            bunu ayrıca Borvey’a iletmelidir. Aksi halde ilave taşıma ücreti
            veya ek taşıma aracı ücreti ödenmesi gerekebilir. 5.10. Kullanıcı
            tarafından talep oluşturulduktan sonra, Borvey tarafından kullanıcı
            için seçilen nakliye firmasının tüm iletişim bilgileri, kullanıcı
            ile paylaşılacaktır. Bu bağlamda nakliye firması, kullanıcı ile
            iletişime geçecektir. Ancak ve her halükarda Borvey, kullanıcıya
            hizmete dair her türlü uzaktan danışmanlığı sağlayacaktır. 5.11.
            Kullanıcı, Borvey’ın web sitesi ve/veya mobil uygulama aracılığı ile
            oluşturulan hizmet talebine ilişkin olarak Borvey’ın bilgisi
            dahilinde olmaksızın, Borvey’ı dışarıda bırakmak, daha düşük bir
            fiyata anlaşmak veya komisyon ücreti ödenmemesi amacıyla nakliyeci
            ile iletişime geçmeyecektir. 5.12. Kullanıcı, ilgili hizmet
            tutarının kredi kartından tahsil edilmesini, daha önceden paylaşılan
            kredi kartı bilgilerinin değişmesi durumunda Borvey’ı
            bilgilendirmeyi kabul eder. 5.13. Kullanıcı, kredi kartı
            bilgilerinin mevzuat kapsamında izin verildiği ölçüde olmak
            kaydıyla, borvey’ın anlaşmalı olduğu ödeme kuruluşu sisteminde
            saklanması ve sistem işleticileri ile paylaşma yetkisine sahip
            olduğunu kabul etmekte olup; anlaşmalı ödeme kuruluşu sisteminde
            oluşabilecek aksaklıklardan borvey sorumlu değildir. 5.14.
            Kullanıcı, hizmete ilişkin olarak tüm vergilerden, borvey’ın
            gelirine bağlı oluşan vergiler hariç olmak üzere sorumludur. 5.15.
            borvey, sistemin çalışmasını geçici bir süre askıya alabilir veya
            tamamen durdurabilir. Kullanıcılar, web sitesinin ve/veya mobil
            uygulamanın kullanımına ilişkin olarak borvey’a herhangi bir
            kullanım ücreti ödememekte olup; söz konusu nedenle borvey’dan
            sistemin durdurulması veya askıya alınması nedeniyle herhangi bir
            talepte bulunmayacaklardır. 5.16. borvey veya işbirliği yaptığı
            ödeme kuruluşu güvenlik şüphesi doğuran kullanıcı ve nakliyeci
            işlemlerinden dolayı ilgili kullanıcıların kredi kartı ile online
            ödeme yapma imkânını geçici bir süre askıya alabilir veya tamamen
            durdurabilir. Söz konusu nedenle borvey’nın kullanıcılarına veya
            üçüncü kişilere karşı hiçbir sorumluluğu bulunmamaktadır. 5.17.
            borvey veya işbirliğinde bulunduğu ödeme kuruluşu, rezervasyon
            sistemi kapsamında hizmet talebini onaylamadan önce Online Ödeme
            Sistemine konu kartın, limit yeterliliğini sorgulama hakkına
            sahiptir. 5.18. Borvey, web sitesinin ve/veya mobil uygulamanın
            kullanılması ile oluşacak tüm verilerin fikri haklarına sahiptir.
            borvey, söz konusu bilgilerle, kullanıcı bilgilerini açıklamaksızın
            demografik bilgiler içeren raporlar düzenleyebilir veya bu tarz
            bilgileri veya raporları kendisi kullanabilir veya bu rapor ve/veya
            istatistikleri iş ortakları ile üçüncü kişilerle bedelli veya
            bedelsiz paylaşabilir. Bu işlemler borvey’nın gizlilik politikası
            hükümlerine aykırılık teşkil etmez. 5.19. borvey, kullanıcılarına
            promosyonlar ile yeni hizmet veya projeler veya haberleri e-posta
            yolu ile haberdar edebilir. Kullanıcıların söz konusu e-postaları
            almak istememesi halinde destek@Borvey.com adresine yazılı
            bildirimde bulunarak söz konusu bildirimleri almayı durdurabilir.
            5.20. Kullanıcılar arasında fikri hakların ihlaline ilişkin herhangi
            ihtilaf olduğu takdirde Borvey, kendisine ibraz edilecek kesinleşmiş
            ve icra edilebilir bir mahkeme kararına istinaden işlem yapmakla
            yükümlüdür. Diğer hallerde, Borvey kendi takdirinde olmak üzere
            işlem yapar. 5.21. Kullanıcılardan gelen yorumlar/değerlendirmeler,
            Borvey tarafından yapılacak gerekli onay, kontrol ve düzeltme
            akabinde tüm kullanıcıların görebileceği şekilde yayınlanabilir.
            Borvey’ın söz konusu yorumları düzeltme ya da yayımlayıp yayımlamama
            yetkisi vardır. Borvey, önceden haber vererek veya vermeden dilediği
            zaman web sitesini ve/veya mobil uygulamanın (veya bir kısmını)
            geçici veya kalıcı olarak değiştirme veya sonlandırma hakkını saklı
            tutar. 5.22. Web sitesinin ve/veya mobil uygulamanın bütünlüğünü
            korumak amacıyla, Borvey dilediği zaman kendi inisiyatifine
            dayanarak, belirli İnternet Protokolü adreslerindeki kullanıcıların
            web sitesine ve/veya mobil uygulamaya erişmelerini engelleyebilir.
            5.23. Borvey hizmetlerin bazılarını veya tamamını ileride ücretli
            yapma hakkını saklı tutar. 5.24. Borvey, dilerse kullanıcıların
            e-posta adresini, cep telefonu numarasını ve diğer bilgileri
            doğrulamak amacıyla SMS, e-posta veya diğer teknik araçlar
            kullanabilir. 5.25. Borvey, kullanıcıların işbu sözleşmeyi ihlal
            ettiğinin tespit edilmesi halinde ya da durumun gerektirdiği ölçüde
            hiçbir sebep göstermek zorunda olmaksızın web sitesini ve/veya mobil
            uygulamayı kullanmaktan ve kullanıcı haklarına erişimden men
            edebilecek olup; web sitesi ve/veya mobil uygulama kullanımını
            geçici olarak durdurabilir.
            <br />
            <br />
            <span>6. GENEL SORUMLULUK </span>
            <br />
            6.1. Yürürlükteki kanunlar uyarınca yasal olarak müsaade edildiği
            sürece, Borvey, web sitesi ve/veya mobil uygulamanın ya da web
            sitesinin ve/veya mobil uygulamanın kullanımıyla ilgili olarak,
            herhangi bir üçüncü şahsın, web sitesi ve/veya mobil uygulama
            kullanıcılarının, reklam verenlerin ve/veya sponsorların fiil, ihmal
            veya davranışlarına ilişkin sorumlu değildir. 6.2. borvey, web
            sitesinin ve/veya mobil uygulamanın işletiminden veya koşullarının
            uygulanmasından doğan hiçbir veri kaybından sorumlu değildir. 6.3.
            borvey, koruma amaçlı makul tedbirleri almaktadır. Ancak kendisine
            ait bilgisayar ağına ve bu ağdaki mevcut veri tabanı bilgilerine
            yapılabilecek saldırılar sonucu, kullanıcı bilgilerinin kötü amaçlı
            kişilerin eline geçmesi ve bunların kötü niyetli kullanılması
            halinde doğabilecek sonuçlardan dolayı sorumluluk kabul
            etmemektedir. 6.4. borvey üçüncü kişilerin web sitesinin ve/veya
            mobil uygulamanın kullanımı ile ilgili davranışı sonucunda ortaya
            çıkan, bedensel zarar, duygusal rahatsızlık gibi fakat bunlarla
            sınırlı olmamak üzere, doğrudan veya dolaylı, maddi veya manevi her
            türlü zararı ve/veya bir nakliyecinin verdiği hizmetlerden dolayı
            doğabilecek zararlardan sorumluluk kabul etmemektedir.
            <br />
            <br />
            <span>7. FİKRİ MÜLKİYET HAKLARI </span>
            <br />
            7.1. www.borvey.com alan adını haiz internet sitesi, mobil uygulama
            ve içerikleri ile ilişkili fikri mülkiyet haklarının tamamı
            borvey’e, iştiraklerine veya üçüncü kişilere aittir. Web sitesinde
            ve/veya mobil uygulamada görünen tüm özel grafikler, simgeler ve
            diğer öğeler, borvey’in iştiraklerinin, çözüm ortaklarının veya
            borvey’a bu tür işaretleri kullanma hak ve lisansını vermiş olan
            diğer tüzel kişiliklerin ticari markaları, hizmet işaretleri veya
            ticari takdim şekilleridir. 7.2. Kullanıcılar, borvey hizmetlerini,
            bilgilerini ve telif haklarına tabi çalışmalarını satmak, işlemek,
            paylaşmak, dağıtmak, çoğaltmak, değiştirmek, kiralamak, ödünç
            vermek, karşıya yüklemek, aktarmak sergilemek veya başkasının
            borvey’in hizmetlerine erişmesi veya kullanmasına izin vermek
            hakkına sahip değildirler. Burada açıkça belirtildiği durum
            haricinde borvey, borvey’ın veya herhangi bir üçüncü kişinin fikri
            mülkiyetine açık veya kapalı hiçbir hak tanımaz. borvey sadece
            www.borvey.com alan adını haiz internet sitesi ve mobil uygulama
            içeriğine ve hizmetlerine, sadece borvey tarafından sunulduğu
            şekilde erişmeniz ve bunları bu şekilde kullanmanız ve
            www.Borvey.com alan adını haiz internet sitesi ve/veya mobil
            uygulama ile birlikte sunulan borvey bilgisayar ve ağ hizmetlerine
            sadece borvey tarafından açıkça izin verildiği şekilde erişmeniz ve
            bunları bu şekilde kullanmanız için kullanıcıya sınırlı, kişisel,
            başkalarına aktarılamaz, başkalarına alt lisans verilemez, geri
            alınabilir bir lisans vermektedir. Bu sınırlı lisans hariç borvey,
            size web sitesine ve/veya mobil uygulamaya erişme izni vererek,
            www.borvey.com sistemleri, www.borvey.com sistemleri üzerinden
            kullanılabilir bilgi veya veriler, içerik, hizmetler, web sitesi,
            mobil uygulama veya diğer her türlü borvey mülkiyeti için veya
            bunlarla ilgili olarak hiçbir çıkarı bulunduğunu ifade etmemektedir.
            Yasal mevzuat uyarınca gerekli olduğu veya işbu sözleşmede
            belirtildiği sınırlar hariç içeriği ve/veya bilgilerin hiçbiri önce
            borvey’ın yazılı izni alınmaksızın tersine mühendislik işlemlerinden
            geçirilemez, değiştirilemez, çoğaltılamaz, yeniden yayımlanamaz,
            herhangi bir dile veya bilgisayar diline çevrilemez, herhangi bir
            şekilde veya herhangi bir yöntemle yeniden aktarılamaz, yeniden
            satılamaz veya yeniden dağıtılamaz. Kullanıcılar, borvey’in bu
            konuda açık izni olmaksızın, içeriği hiçbir şekilde satamaz, satışa
            sunamaz, değiştiremez, çoğaltamaz, gösteremez, halka açık olarak
            ticari kazanç elde edemez, yeniden aktaramaz veya diğer herhangi bir
            şekilde kullanamaz.
            <br />
            <br />
            <span>8. WEB SİTESİ VE MOBİL UYGULAMA İÇERİĞİ </span>
            <br />
            8.1. borvey web sitesinin ve/veya mobil uygulamanın hatasız,
            kesintisiz ve güvenli olacağını ya da web sitesinin ve/veya mobil
            uygulamanın ya da üzerindeki herhangi bir içerik, arama veya
            bağlantının kullanımının belirli sonuçlar sağlayacağını taahhüt
            etmez ve 8.2. borvey, web sitesinden ve/veya mobil uygulama
            üzerinden indirilen herhangi bir dosyanın virüs ya da diğer kirli ya
            da bozucu özellikler taşımayacağını garanti edemez
            <br />
            <br />
            <span>9. MÜCBİR SEBEP </span>
            <br />
            9.1. Mücbir sebeplerle tarafların edimlerini yerine getirmelerinin
            imkânsızlaşması halinde sözleşmenin ifa edilmiş kısmından doğan
            yükümlülükler dışında taraflar birbirlerine karşı başkaca talepte
            bulunmayacaklardır. 9.2. Tarafların, işbu kullanıcı sözleşmesinin
            kullanıcı tarafından onaylanması anında önceden öngöremedikleri ve
            önleyemedikleri ve yapılan işi doğrudan etkileyen haller, deprem,
            yangın, sel ve benzerleri gibi doğal felaketler veya savaş, ambargo,
            abluka, ayaklanma, terör eylemleri, internet hizmetlerinin
            kısıtlanması, seferberlik hali gibi tarafların kontrolü dışında
            meydana gelen olaylar sebebiyle, taraflar işbu sözleşmeden
            kaynaklanan edimlerini hiç, gereği gibi veya zamanında yerine
            getiremezlerse, mücbir sebep halini karşı tarafa bildirerek, bu
            edimlerini mücbir sebep hali geçene kadar askıya alabilirler. 9.3.
            Mücbir sebebe bağlı olarak sözleşmesel edimini ifa edemeyecek olan
            taraf, Mücbir Sebebin ortaya çıktığı andan itibaren 2 (İki) gün
            içinde diğer tarafa borvey’e ait internet sitesi ve/veya mobil
            uygulama ya da noter kanalıyla bildirimde bulunulur. 9.4. Mücbir
            Sebep halinin 5 (Beş) günden fazla sürmesi halinde taraflar, tek
            taraflı beyan ile işbu sözleşmeden kaynaklanan haklarına halel
            gelmemek kaydıyla; mücbir sebebe bağlı fesih nedeniyle tazminat
            talep etme hakkı olmaksızın; sözleşmeyi feshedebilir.
            <br />
            <br />
            <span>10. FERAGAT </span>
            <br />
            10.1. İşbu kullanıcı sözleşmesinin herhangi bir hükmünden feragat
            edilmesi, o maddenin ve sözleşmenin tamamını geçersiz kılmayacaktır.
            10.2. Bu anlaşmanın herhangi bir hükmü yürürlükteki kanun ve nizamla
            çatışırsa veya herhangi bir şekilde adli veya idari bir merci
            tarafından iptal edilirse, sözleşme bütünüyle geçerliliğini
            koruyacak ancak çelişen veya iptal edilen hüküm anlaşmadan
            çıkarılmış sayılacaktır.
            <br />
            <br />
            <span>11. UYUŞMAZLIKLARIN ÇÖZÜMÜ VE YETKİLİ MAHKEME </span>
            <br />
            Taraflar, işbu sözleşmenin uygulanmasından ve yorumlanmasından
            dolayı, aralarında doğacak her türlü ihtilaf halinde, Türkiye
            Cumhuriyeti Kanunlarının uygulanacağını, İstanbul Merkez (Çağlayan)
            Mahkeme ve İcra Dairelerinin yetkili olduğunu, bu maddenin, bir
            yetki sözleşmesi niteliğinde kabul edildiğini, beyan ve taahhüt
            ederler.
            <br />
            <br />
            <span>12. KAYITLAR VE DELİL SÖZLEŞMESİ </span>
            <br />
            Bu sözleşmeden doğabilecek her türlü ihtilafta borvey’e ait internet
            sitesi ve/veya mobil uygulama üzerinden yapılan tüm yazışmalar,
            e-mailler, telefon görüşme kayıtları ve cep telefonuna gönderilen
            SMS/whatsapp bilgilendirme ve kayıtları delil niteliğinde olup;
            ayrıca borvey’e defter ve kayıtları kesin delil olarak kabul edilir.
            <br />
            <br />
            <span>13. SÖZLEŞMENİN İHLALİ VE FESHİ </span>
            <br />
            Kullanıcının, işbu sözleşme hükümlerinden herhangi birini ihlal
            etmesi halinde, borvey, işbu sözleşmeyi, her türlü hak ve talepleri
            saklı kalmak kaydıyla; derhal feshetmek hakkına sahip olacaktır.
            Taraflar, işbu sözleşmeyi diledikleri zaman sona
            erdirebileceklerdir. Sözleşmenin feshi anında tarafların
            birbirlerinden olan alacak hakları etkilenmez.
            <br />
            <br />
            <span>14. YÜRÜRLÜK </span>
            <br />
            Bu sözleşme web sitesi ve/veya mobil uygulama kullanılmaya devam
            edildiği ve kullanıcılara, borvey tarafından yeni bir sözleşme
            sunulmadığı sürece yürürlükte kalacaktır.
          </div>
        </div>
      </div>
    </section>
  );
};

export default Cookies;
