import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { API_URL } from "../../utils/config";

const initialState = {
  commentCreateSuccess: null,
  commentCreateLoading: false,
  commentCreateError: null,
  commentListData: null,
  commentListLoading: false,
  commentListError: null,
  blockCreateLoading: false,
  blockCreateError: null,
  blockCreateData: null
};

export const commentCreate = createAsyncThunk(
    `/comment/create`,
    async ({ id, demandId, data }) => {
      try {
        const token = localStorage.getItem("token");
        const response = await axios.post(
          `${API_URL}/rating/${id}/${demandId}`,
          data,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        return response.data;
      } catch (error) {
        throw new Error(error.response.data);
      }
    }
  );


export const commentList = createAsyncThunk(
    `/comment/list`,
    async ({id}, { rejectWithValue }) => {
      try {
        const token = localStorage.getItem("token");
        const response = await axios.get(
          `${API_URL}/rating/${id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        return response.data;
      } catch (error) {
        throw new Error(error.response.data);
      }
    }
  );

  

  export const reportCreate = createAsyncThunk(
    `/report/create`,
    async ({ id, data }) => {
      try {
        const token = localStorage.getItem("token");
        const response = await axios.post(
          `${API_URL}/rating/${id}/demand/report`,
          data,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        return response.data;
      } catch (error) {
        throw new Error(error.response.data);
      }
    }
  );


  export const blockCreate = createAsyncThunk(
    `/block/create`,
    async ({ id, data }) => {
      try {
        const token = localStorage.getItem("token");
        const response = await axios.post(
          `${API_URL}/rating/${id}/block`,
          data,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        return response.data;
      } catch (error) {
        throw new Error(error.response.data);
      }
    }
  );


// Create the comment slice
const commentSlice = createSlice({
  name: "comment",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(commentCreate.pending, (state) => {
        state.commentCreateLoading = true;
        state.commentCreateError = null;
      })
      .addCase(commentCreate.fulfilled, (state, action) => {
        state.commentCreateLoading = false;
        state.commentCreateData = action.payload;
      })
      .addCase(commentCreate.rejected, (state, action) => {
        state.commentCreateLoading = false;
        state.commentCreateError = action.payload;
      })
      .addCase(commentList.pending, (state) => {
        state.commentListLoading = true;
        state.commentListError = null;
      })
      .addCase(commentList.fulfilled, (state, action) => {
        state.commentListLoading = false;
        state.commentListData = action.payload;
      })
      .addCase(commentList.rejected, (state, action) => {
        state.commentListLoading = false;
        state.commentListError = action.payload;
      })
      .addCase(reportCreate.pending, (state) => {
        state.reportCreateLoading = true;
        state.reportCreateError = null;
      })
      .addCase(reportCreate.fulfilled, (state, action) => {
        state.reportCreateLoading = false;
        state.reportCreateData = action.payload;
      })
      .addCase(reportCreate.rejected, (state, action) => {
        state.reportCreateLoading = false;
        state.reportCreateError = action.payload;
      })
      .addCase(blockCreate.pending, (state) => {
        state.blockCreateLoading = true;
        state.blockCreateError = null;
      })
      .addCase(blockCreate.fulfilled, (state, action) => {
        state.blockCreateLoading = false;
        state.blockCreateData = action.payload;
      })
      .addCase(blockCreate.rejected, (state, action) => {
        state.blockCreateLoading = false;
        state.blockCreateError = action.payload;
      })
  },
});

// Export actions and reducer
export const commentactions = commentSlice.actions;
export default commentSlice.reducer;
