import React from "react";
import aboutUs from "../assets/images/about-us.png";
import solidarity from "../assets/images/solidarity.png";
import borveyVideo from "../assets/videos/borvey.mp4";

const About = () => {
  return (
    <div className="w-[85%] mx-auto">
      <div>
        <div className="container">
          <div className="about-us-flex-container">
            <div className="about-us-img">
              <img src={aboutUs} alt="png" />
            </div>
            <div className="about-us-text">
              <div className="about-us-text-header">
                İster ev, ofis ister tek parça
              </div>
              <div className="border-o-short"></div>
              <div className="about-us-text-paragraph">
                Türkiye'nin her yerinde kullanabileceğiniz borvey'den hizmet
                almak için ücretsiz üyelik açmanız ve taşınma işlemlerinizin
                detaylarını içeren bir ilan bırakmanız yeterli. borvey üyesi
                nakliyat firmaları ilanınızı değerlendirir ve size tekliflerini
                iletir. Teklifler arasından dilediğinizi seçebilir ve
                taşınmanızı en uygun koşullarla gerçekleştirebilirsiniz.
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div className="container">
          <div className="about-us-flex-container about-box-container justify-center flex-col ">
            <div className="about-us-text justify-center text-center">
              <div className="about-us-text-header">
                Bizi Farklı Kılan Nedir?
              </div>
              <div className="border-o-short m-auto"></div>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 mt-4 ">
              <div className="flex items-center text-center flex-col cursor-pointer  hover:bg-white hover:shadow-md rounded-[12px] p-4">
                <div className="boxes-about-icon">
                  <img src={solidarity} alt="s" />
                </div>
                <p className="text-[#383E42] text-[16px] pt-6 pb-2">
                  Kullanıcı dostu platformumuzla gönderileri kolayca yönetin.
                </p>
              </div>
              <div className="flex items-center text-center flex-col cursor-pointer  hover:bg-white hover:shadow-md rounded-[12px] p-4">
                <div className="boxes-about-icon">
                  <img src={solidarity} alt="s" />
                </div>
                <p className="text-[#383E42] text-[16px] pt-6 pb-2">
                  İster tek parça, ister ev ya da iş yerlerinizin dosyaları için ücretsiz ilan açmanız yeterli.
                </p>
              </div>

              <div className="flex items-center text-center flex-col cursor-pointer  hover:bg-white hover:shadow-md rounded-[12px] p-4">
                <div className="boxes-about-icon">
                  <img src={solidarity} alt="s" />
                </div>
                <p className="text-[#383E42] text-[16px] pt-6 pb-2">
                  Borvey, güvenilir ve nakliye firmalarını sizin için bir araya getirir, size profesyonel hizmet sunar.
                </p>
              </div>
              <div className="flex items-center text-center flex-col cursor-pointer  hover:bg-white hover:shadow-md rounded-[12px] p-4">
                <div className="boxes-about-icon">
                  <img src={solidarity} alt="s" />
                </div>
                <p className="text-[#383E42] text-[16px] pt-6 pb-2">
                  Bir sorunla karşılaştığınızda borvey'e hemen ulaşabilirsiniz. 
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div className="container frameembed">
          <video width="100%" height="100%" controls>
            <source src={borveyVideo} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
      </div>
    </div>
  );
};

export default About;
