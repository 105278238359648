import { useEffect } from "react";
import heroImage from "../assets/images/home-img.png";
import heroImage2 from "../assets/images/home-img2.png";
import { useSelector, useDispatch } from "react-redux";
import { detailsUser } from "../redux/slices/userSlice";
import { Link } from "react-router-dom";

const Section = ({ isOpen, setOpen }) => {
  const dispatch = useDispatch();

  const { user } = useSelector((state) => state.users);

  useEffect(() => {
    dispatch(detailsUser());
  }, [dispatch]);
  return (
    <div>
      <div className="container relative">
        <div className="home-container">
          <div className="home-text-container">
            {user?.type == 1 ? (
              <div className="home-text">
                borvey ile yük bulmak
                <br /> <span className="text-[#FF9A00]">hızlı</span> ve{" "}
                <span className="text-[#006EDF]">kolay!</span>
              </div>
            ) : user?.type == 2 ? (
              <div className="home-text">
                borvey ile taşınmak
                <br /> <span className="text-[#FF9A00]">hesaplı</span> ve{" "}
                <span className="text-[#006EDF]">kolay!</span>
              </div>
            ) : (
              <div className="home-text">
                borvey ile taşınmak
                <br /> <span className="text-[#FF9A00]">hesaplı</span> ve{" "}
                <span className="text-[#006EDF]">kolay!</span>
              </div>
            )}
            <div className="home-text-alt">
              Taşıma hizmeti almak isteyenlerle nakliye sektörü oyuncularını bir
              araya getiren paylaşım platformu...
            </div>
            <div className="search-category-container mt-14 mb-8">
              <div className="category-contianer flex gap-8">
                <div className="nav-link-button gap-8">
                  {user ? (
                    <div className="nav-link-button gap-8">
                      {user.type === 2 && (
                        <Link className="l-btn l-btn-edited" to="/create-order">
                          Nakliyeci Ara
                        </Link>
                      )}
                      {user.type === 1 && (
                        <Link
                          className="r-btn r-btn-edited r-btn-top"
                          to="/order-list"
                        >
                          Aracına Yük Bul
                        </Link>
                      )}
                    </div>
                  ) : (
                    <div className="nav-link-button gap-8">
                      <a
                        className="l-btn l-btn-edited"
                        onClick={() => setOpen(!isOpen)}
                        style={{ cursor: "pointer" }}
                      >
                        Nakliyeci Ara
                      </a>
                      <a
                        className="r-btn r-btn-edited"
                        onClick={() => setOpen(!isOpen)}
                        style={{ cursor: "pointer" }}
                      >
                        Aracına Yük Bul
                      </a>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="home-img">
            {user?.type == 1 ? (
              <img src={heroImage} alt="borvey" />
            ) : user?.type == 2 ? (
              <img src={heroImage2} alt="borvey" />
            ) : (
              <img src={heroImage} alt="borvey" />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Section;
