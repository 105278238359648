import React, { useEffect } from "react";
import messageIcon from "../../assets/images/icon/message.png";
import { useSelector, useDispatch } from "react-redux";
import { orderDetails } from "../../redux/slices/orderSlice";
import {
  demandCreate,
  demandDetailsByOrder,
} from "../../redux/slices/demandSlice";
import { detailsUser } from "../../redux/slices/userSlice";
import Loading from "../../partials/Loader";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";

const OrderDetails = () => {
  const id = window.location.pathname.split("/")[2];

  const { orderDetailsData: data, orderDetailsLoading: loading } = useSelector(
    (state) => state.order
  );

  const { user, loading: loadingUser } = useSelector((state) => state.users);

  const { demandDetailsDataByOrder: demand } = useSelector(
    (state) => state.demand
  );

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(orderDetails({ id }));
    dispatch(detailsUser());
    dispatch(demandDetailsByOrder({ id }));
  }, [dispatch, id]);

  const handleApply = async (status) => {
    const confirmResult = await Swal.fire({
      title: "Emin misiniz?",
      text: "İlana teklif vermek istiyor musunuz?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Evet",
      cancelButtonText: "Hayır",
    });

    if (confirmResult.isConfirmed) {
      try {
        const response = await dispatch(demandCreate({ id }));
        if (response && response.payload) {
          Swal.fire({
            title: "Tebrikler!",
            text: "Başarılı bir şekilde başvurdunuz",
            icon: "success",
          });
        }
      } catch (error) {
        Swal.fire({
          title: "Hata",
          text: "Durum değiştirilirken bir hata oluştu. Lütfen tekrar deneyin!",
          icon: "error",
        });
      }
    }
  };

  const checkValidUser = demand?.some(
    (item) => item?.cargoId?._id === user?._id
  );

  console.log(user?.type);

  if (loading)
    return (
      <div>
        <Loading />
      </div>
    );

  return (
    <section className="nav-container">
      <div>
        <div className="">
          <div className="sup-header">
            <div className="home-title">İlan Detay</div>
          </div>
        </div>
      </div>
      <div>
        <div className=" mt-4">
          <div className="ilan-detay-container">
            <div className="flex flex-end justify-end">
              {user?.type === 1 && (
                <div className="ilan-detay-buttons">
                  <Link to={`/message/${data?.userId?._id}`}>
                    <span>
                      <img src={messageIcon} alt="message" />
                    </span>
                    Mesaj Gönder
                  </Link>
                </div>
              )}
            </div>
            <div className="ilan-detay-content">
              <div className="ilan-detay-title">
                <span>{data?.cargoType}</span>{" "}
              </div>
              <div className="ilan-detay-title">
                <i className="fa-regular fa-user text-[#FF9A00] mr-[5px]"></i>
                <span>
                  {data?.userId?.fullName}
                  <br />
                </span>{" "}
                {data?.fromLocation.city}<i class="fa-solid fa-arrow-right"></i>{data?.toLocation.city}
              </div>
              <div className="ilan-detay-info">
                <div className="ilan-info-box">{data?.fromLocation.city}</div>
                <div className="ilan-info-box">
                  {data?.fromLocation.district}
                </div>
                <div className="ilan-info-box">
                  {data?.fromLocation.neighborhood}
                </div>
              </div>

              {data?.status === 1 && !checkValidUser && user?.type === 1 ? (
                <>
                  {" "}
                  <div className="old-button-container">
                    <div className="old-btn">
                      <div className="old-btn-icon text-[#24C4BB] bg-[#C8FFFC]">
                        <i
                          className="fa-solid fa-check-double"
                          aria-hidden="true"
                        ></i>
                      </div>
                      <div
                        className="old-btn-text"
                        onClick={(e) => handleApply(e, data._id)}
                      >
                        Projeye Teklif Ver
                      </div>
                    </div>
                  </div>
                </>
              ) : user?.type === 2 ? (
                <div></div>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default OrderDetails;
