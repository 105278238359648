import React from "react";

const HelpDetails = () => {
  return (
    <section className="nav-container">
      <div>
        <div className="container bg-[#006EDF] rounded-[15px]">
          <div className="help-search-container">
            <div className="help-title">Yardım Merkezi</div>
            <div className="help-search-input-container">
              <i className="fa-solid fa-magnifying-glass"></i>
              <input type="text" placeholder="Neyi merak ediyorsunuz?" />
            </div>
          </div>
        </div>
      </div>
      <div className="mt-[50px]">
        <div className="container">
          <div className="home-title text-[22px]">Sistem Nasıl Çalışıyor?</div>
          <div className="help-alt-paragraph">
            Sorunsuz bir deneyim borvey, sizlere en uygun nakliyatçıyı bulmanız
            konusunda nokta atışı bir hizmet sunar. 
            <br/>
            <br/>
            En uygun taşıma hizmetini
            bulmanız için nokta atışı hizmet sunuyoruz" diyebiliriz. borvey
            hizmetlerine, borvey.com’dan ve mobil uygulamalarımızdan istediğiniz
            zaman ulaşın. borvey, taşıma hizmeti almak isteyenlere daha kolay,
            güvenli ve ucuz taşınma hizmeti sunarken, sektör oyuncularına da
            maliyet ve zaman avantajı sağlar. 
            <br/>
            <br/>
            Uygun fiyatlı hızlı nakliye
            çözümleri için stratejiler geliştiren borvey ile sadece ilan verin;
            nakliyecileri ya da taşınma hizmeti almak isteyen müşteriyi size biz
            bulalım.
            <br />
            <br />
          </div>
        </div>
      </div>
    </section>
  );
};

export default HelpDetails;
