import React from "react";
import Section from "../components/Hero";
import ServiceCategories from "../components/ServiceCategories";
import LatestCompany from "../components/LatestCompany";
import Info from "../components/Info";
import LatestServices from "../components/LatestServices";
import HowItWorks from "../components/HowItWorks";
import Testimontial from "../components/Testimontials";
const HomeScreen = ({ isOpenRegister, setOpenRegister }) => {
  return (
    <section className="nav-container">

        <Section isOpen={isOpenRegister} setOpen={setOpenRegister} />
        <ServiceCategories isOpenRegister={isOpenRegister}
              setOpenRegister={setOpenRegister}/>
        <LatestCompany />
        <Info />
        <LatestServices />
        <HowItWorks />
        <Testimontial/>
        
    </section>
  );
};

export default HomeScreen;
