import React from "react";
import { Link } from "react-router-dom";
const MobileNavbar = ({ data, profile, setOpen, isOpen }) => {
  return (
    <div className="bg-white p-4">
      <div className="nav-link">
      </div>
      {data ? (
        <div className="nav-link-button gap-2 mt-2">
         
          <Link to="/dashboard" className="classic-btn profile-btn">
            <img width="30px" src={profile} alt="profile" /> {data?.fullName}{" "}
            <i className="fa-solid fa-angle-down"></i>
          </Link>
          {data?.type == 2 && (
            <Link className="l-btn" to="/create-order">
              İlan ver
            </Link>
          )}

          {data?.type == 1 && (
            <Link className="l-btn" to="/order-list">
              Yük ara
            </Link>
          )}
        </div>
      ) : (
        <div className="nav-link-button gap-2 mt-2">
          <a className="classic-btn sign-btn" onClick={() => setOpen(!isOpen)}>
            Giriş Yap / Üye Ol
          </a>
          <a className="l-btn" onClick={() => setOpen(!isOpen)} style={{cursor: "pointer"}}>
            Hizmet Al
          </a>
          <a className="r-btn" onClick={() => setOpen(!isOpen)} style={{cursor: "pointer"}}>
            Hizmet Ver
          </a>
        </div>
      )}
    </div>
  );
};

export default MobileNavbar;
