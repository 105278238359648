import { lazy } from "react";
const PastServices = lazy(() => import("../pages/User/PastServices"));
const Dashboard = lazy(() => import("../pages/Dashboard"));
const Security = lazy(() => import("../pages/User/Security"));
const Company = lazy(() => import("../pages/User/Company"));
const ActiveServices = lazy(() => import("../pages/User/ActiveServices"));
const ServiceDetails = lazy(() => import("../pages/User/ServiceDetails"));
const ServiceDetailsEdit = lazy(() => import("../pages/User/ServiceDetailsEdit"));
const DemandList = lazy(() => import("../pages/User/DemandList"));
const DemandDetails = lazy(() => import("../pages/User/DemandDetails"));
const Message = lazy(() => import("../pages/User/Message"));
const Chat = lazy(() => import("../pages/User/ChatDetails"));
const routes = [
  {
    path: "/dashboard",
    title: "Dashboard",
    component: Dashboard,
  },
  {
    path: "/company",
    title: "Company",
    component: Company,
  },
  {
    path: "/myservices/active",
    title: "Services",
    component: ActiveServices,
  },
  {
    path: "/myservices/past",
    title: "Services",
    component: PastServices,
  },
  {
    path: "/myservices/:id",
    title: "Services",
    component: ServiceDetails,
  },
  {
    path: "/myservices/:id/edit",
    title: "ServiceEdit",
    component: ServiceDetailsEdit,
  },
  {
    path: "/security",
    title: "Security",
    component: Security,
  },
  {
    path: "/offers",
    title: "Offers",
    component: DemandList,
  },
  {
    path: "/offers/:id",
    title: "Offers",
    component: DemandDetails,
  },
  {
    path: "/message",
    title: "Message",
    component: Message,
  },
  {
    path: "/message/:id",
    title: "Chat",
    component: Chat,
  },
];

export default routes;
