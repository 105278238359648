import React from "react";
import { helpData } from "../utils/helpData";
import { Link } from "react-router-dom";
const Help = () => {
  return (
    <section className="nav-container">
      <div>
        <div className="container">
          <div className="about-us-flex-container justify-center flex-col ">
            <div className="about-us-text justify-center text-center">
              <div className="about-us-text-top text-[#FF9A00]">
                Yardıma mı ihtiyacınız var?
              </div>
              <div className="about-us-text-header">
                Tüm Sorularınızın Yanıtları
              </div>
            </div>
            <div className="grid grid-cols-1 lg:grid-cols-3 gap-4">
                         {helpData?.map((item) => {
    return (
        <div className="w-[100%] flex-1 p-4 rounded-md bg-[#FFF5E5] text-left justify-start items-start flex flex-col">
            <div className="flex items-center justify-center rounded-full mb-2 bg-[#E6E8E5] w-[40px] h-[40px]">
                <i className="fa-solid fa-circle-info text-[#006EDF]"></i>
            </div>
            <div className="text-[22px]">
                {item?.title}
            </div>
            <div className="text-[#383E42] text-[14px] flex-1 pt-4">
                {item?.description}
            </div>
            <div className="mt-[20px]">
                <Link to={`/help/${item?.id}`} className="text-[#006EDF]">
                    Daha fazla bilgi edin{" "}
                    <i className="fa-solid fa-caret-right ml-[15px]"></i>
                </Link>
            </div>
        </div>
    );
})}

            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Help;
