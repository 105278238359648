import truck from "../assets/images/icon/truck.png";
import box from "../assets/images/icon/box.png";
import boxes from "../assets/images/icon/boxes.png";
import short from "../assets/images/icon/short.png";

import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { detailsUser } from "../redux/slices/userSlice";

const ServiceCategories = ({ isOpenRegister, setOpenRegister }) => {
  const dispatch = useDispatch();

  const { user } = useSelector((state) => state.users);

  useEffect(() => {
    dispatch(detailsUser());
  }, [dispatch]);

  return (
    <div className="sup-overflow mt-12">
      <div className="container">
        <div className="sup-header">
          <div className="home-title flex-j">Hizmet Kategorileri</div>
          <div className="title-alt">
            Güvenilir ve deneyimli nakliyat firmalarını sizin için bulan borvey,
            doğrudan tedarikçi olmayıp, sadece taşıma hizmeti almak isteyenlerle
            nakliye sektörü oyuncularını bir araya getiren paylaşım
            platformudur.
          </div>
        </div>
        <div className="sup-box-container">
        {user?.type == 1 ? (
          <div className="sup-box">
            <div className="s-box-img">
              <img src={truck} alt="evden eve" />
            </div>
            <div className="s-box-title"><a href="/order-list">Evden Eve Nakliye</a></div>
          </div>
        ) : user?.type == 2 ? (
          <div className="sup-box">
            <div className="s-box-img">
              <img src={truck} alt="evden eve" />
            </div>
            <div className="s-box-title"><a href="/create-order">Evden Eve Nakliye</a></div>
          </div>
        ) : (
          <div className="sup-box" onClick={() => setOpenRegister(!isOpenRegister)}>
            <div className="s-box-img">
              <img src={truck} alt="evden eve" />
            </div>
            <div className="s-box-title">Evden Eve Nakliye</div>
        </div>
        )}
        {user?.type == 1 ? (
          <div className="sup-box">
            <div className="s-box-img">
              <img src={box} alt="tekli ürün" />
            </div>
            <div className="s-box-title"><a href="/order-list">Tekli Ürün Nakliye</a></div>
        </div>
        ) : user?.type == 2 ? (
          <div className="sup-box">
            <div className="s-box-img">
              <img src={box} alt="tekli ürün" />
            </div>
            <div className="s-box-title"><a href="/create-order">Tekli Ürün Nakliye</a></div>
          </div>
        ) : (
          <div className="sup-box" onClick={() => setOpenRegister(!isOpenRegister)}>
            <div className="s-box-img">
              <img src={box} alt="tekli ürün" />
            </div>
            <div className="s-box-title">Tekli Ürün Nakliye</div>
          </div>
        )}  
        {user?.type == 1 ? (
          <div className="sup-box">
            <div className="s-box-img">
              <img src={boxes} alt="çoklu ürün" />
            </div>
            <div className="s-box-title"><a href="/order-list">Ofis Nakliye</a></div>
        </div>
        ) : user?.type == 2 ? (
          <div className="sup-box">
            <div className="s-box-img">
              <img src={boxes} alt="çoklu ürün" />
            </div>
            <div className="s-box-title"><a href="/create-order">Ofis Nakliye</a></div>
          </div>
        ) : (
          <div className="sup-box" onClick={() => setOpenRegister(!isOpenRegister)}>
            <div className="s-box-img">
              <img src={boxes} alt="çoklu ürün" />
            </div>
            <div className="s-box-title">Ofis Nakliye</div>
          </div>
        )}
        {user?.type == 1 ? (
          <div className="sup-box">
            <div className="s-box-img">
              <img src={short} alt="kısa mesafeli" />
            </div>
          <div className="s-box-title"><a href="/order-list">Kısa Mesafeli Nakliye</a></div>
        </div>  
        ) : user?.type == 2 ? (
          <div className="sup-box">
            <div className="s-box-img">
              <img src={short} alt="kısa mesafeli" />
            </div>
            <div className="s-box-title"><a href="/create-order">Kısa Mesafeli Nakliye</a></div>
          </div>  
        ) : (
          <div className="sup-box" onClick={() => setOpenRegister(!isOpenRegister)}>
            <div className="s-box-img">
              <img src={short} alt="kısa mesafeli" />
            </div>
            <div className="s-box-title">Kısa Mesafeli Nakliye</div>
          </div>  
        )}        
      </div>

        {user?.type == 1 ? (
          <a href="/order-list" className="primary-btn modal3-btn">
            Tümünü Gör <i className="fa-solid fa-chevron-right"></i>
          </a>
        ) : user?.type == 2 ? (
          <a href="/create-order" className="primary-btn modal3-btn">
            Tümünü Gör <i className="fa-solid fa-chevron-right"></i>
          </a>
        ) : (
          <div
            onClick={() => setOpenRegister(!isOpenRegister)}
            className="primary-btn modal3-btn"
          >
            Tümünü Gör <i className="fa-solid fa-chevron-right"></i>
          </div>
        )}{" "}
      </div>
    </div>
  );
};

export default ServiceCategories;
