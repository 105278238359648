import { Suspense, useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Layout from "./layout/layout";
import Home from "./pages/HomeScreen";
import { useSelector, useDispatch } from "react-redux";
import Loader from "./partials/Loader";
import NotFound from "./components/NotFound";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import routes from "./routes/index";
import Navbar from "./components/Navbar";
import { detailsUser } from "./redux/slices/userSlice";
import Footer from "./components/Footer";
import Dashboard from "./pages/Dashboard";
import Signin from "./components/Authentication/Signin";
import Register from "./components/Authentication/Register";
import OrderList from "./pages/Order/OrderList";
import OrderDetails from "./pages/Order/OrderDetails";
import CreateOrder from "./pages/Order/CreateOrder";
import About from "./pages/About";
import Contact from "./pages/Contact";
import Cookies from "./pages/Cookies";
import Help from "./pages/Help";
import HelpDetails from "./pages/HelpDetails";
import Seller from "./pages/CargoList";
import SellerDetails from "./pages/CargoDetails";
import OTP from "./pages/OTP";
import ForgotPassword from "./pages/ForgotPassword";
function Protected({ children }) {
  const { user } = useSelector((state) => state.users);
  return children;
}

const App = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const { user } = useSelector((state) => state.users);

  useEffect(() => {
    dispatch(detailsUser());
    setTimeout(() => setLoading(false), 1000);
  }, [dispatch]);

  const [isOpenRegister, setOpenRegister] = useState(false);
  const [isOpenSignin, setOpenSignin] = useState(false);
  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <Router>
          <ToastContainer />
          <Navbar
            isOpen={isOpenSignin}
            setOpen={setOpenSignin}
            data={user}
            loading={loading}
          />
          <div
            className={`${
              isOpenSignin
                ? "fixed z-[10]  lg:w-[50%] w-[85%] top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%]"
                : "hidden"
            }`}
          >
            <Signin
              isOpenSignIn={isOpenSignin}
              setOpenSignIn={setOpenSignin}
              isOpenRegister={isOpenRegister}
              setOpenRegister={setOpenRegister}
            />
          </div>
          <div
            className={`${
              isOpenRegister
                ? "fixed z-[99999]   z-index-[10] lg:w-[50%] w-[85%] left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%]"
                : "hidden"
            }`}
          >
            <Register
              isOpenSignIn={isOpenSignin}
              setOpenSignIn={setOpenSignin}
              isOpenRegister={isOpenRegister}
              setOpenRegister={setOpenRegister}
            />
          </div>

          <div>
            <Routes>
              <Route
                index
                path="/"
                exact
                element={
                  <Home
                    setOpenRegister={setOpenRegister}
                    isOpenRegister={isOpenRegister}
                  />
                }
              />
              <Route path="/order-list" element={<OrderList />} />
              <Route path="/order-details/:id" element={<OrderDetails />} />
              <Route path="/about" element={<About />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="/cookies" element={<Cookies />} />
              <Route path="/help" element={<Help />} />
              <Route path="/help/:id" element={<HelpDetails />} />
              <Route path="/nakliyeciler" element={<Seller />} />
              <Route path="/nakliye/:id" element={<SellerDetails />} />
              <Route path="/create-order" element={<CreateOrder />} />
              <Route path="/verification/:email" element={<OTP />} />
              <Route path="/verification/:email" element={<OTP />} />
              <Route path="/forgot-password" element={<ForgotPassword />} />

              <Route element={<Layout />}>
                <Route index element={<Dashboard />} />
                {routes.map((route, index) => {
                  const { path, component: Component } = route;
                  return (
                    <Route
                      key={index}
                      path={path}
                      exact={true}
                      element={
                        <Suspense fallback={<Loader />}>
                          <Protected>
                            <Component />
                          </Protected>
                        </Suspense>
                      }
                    />
                  );
                })}
              </Route>
              <Route path="/*" element={<NotFound />} />
            </Routes>
          </div>
          <Footer />
        </Router>
      )}
    </>
  );
};

export default App;
