import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';

const Contact = () => {

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm('service_tpmau7l', 'template_upr8utx', form.current, {
        publicKey: 'CreTuL9tDjuttXJML',
      })
      .then(
        () => {
          console.log('SUCCESS!');
        },
        (error) => {
          console.log('FAILED...', error.text);
        },
      );
  };
  
  
  return (
    <div className="">
      <div>
        <div className="bacground-contact"></div>

        <div className="w-[80%] mx-auto">
          <div className="mt-[100px]">
            <div className="container">
              <div className="about-us-flex-container about-box-container">
                <div className="about-us-img gap-[20px]">
                  <div className="about-us-text-top">Bize Ulaşın</div>
                  <div className="border-o-short my-[15px]"></div>
                  <div className="about-us-text-paragraph mt-[30px]">
                    İletişim Bilgileri
                  </div>
                  <div className="contact-container-icon-link">
                    <div className="contact-icon icon-contact-p">
                      <i className="fa-solid fa-phone"></i>
                    </div>
                    <div className="contact-icon">
                      +90 (532) 461 80 11
                      <br />
                    </div>
                  </div>
                  <div className="contact-container-icon-link">
                    <div className="contact-icon icon-contact-p">
                      <i className="fa-solid fa-envelope"></i>
                    </div>
                    <div className="contact-icon">
                      bilgi@borvey.com
                      <br />
                      destek@borvey.com
                      <br />
                    </div>
                  </div>
                  <div className="contact-container-icon-link">
                    <div className="contact-icon icon-contact-p">
                      <i className="fa-solid fa-location-dot"></i>
                    </div>
                    <div className="contact-icon">
                      Küçükçekmece / İstanbul
                    </div>
                  </div>
                </div>
                <form ref={form} onSubmit={sendEmail} className="about-us-text c-form-w">
                  <div className="contact-form-c">
                    <div className="c-form-title">Aşağıdaki Formu Doldurun</div>
                    <div className="input-c-form-container mt-[30px]">
                      <div className="input-c-form-f">
                        <input type="text" placeholder="Adınız" name="user_name"/>
                        <input type="text" placeholder="Soyisim" name="user_surname" />
                      </div>
                      <div className="input-c-form-f">
                        <input type="text" placeholder="Telefon" />
                        <input type="text" placeholder="E-posta" name="user_email"/>
                      </div>
                      <div className="input-c-form-textarea">
                        <textarea
                          name="user_message"
                          id=""
                          cols="30"
                          rows="10"
                          placeholder="Mesajınızı Girin"
                        ></textarea>
                      </div>
                    </div>
                    <div className="primary-btn-s2 w-[100%]"><input type="submit" value="Send" style={{cursor: "pointer"}}/></div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
