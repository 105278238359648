import React from "react";
import qi from "../assets/images/qi.png";
import q1 from "../assets/images/q1.jpg";
import q2 from "../assets/images/q2.jpg";
import q3 from "../assets/images/q3.jpg";
const HowItWorks = () => {
  return (
    <section className="bg-effect">
      <div className="container">
        <div className="sup-header">
          <div className="home-title flex-j">Sistem Nasıl Çalışıyor?</div>
        </div>
        <div className="sy-box-container">
          <div className="sy-box">
            <div className="flex items-center justify-center flex-col">
              <img src={qi} alt="q1" />
              <br />
              İhtiyacını Birkaç Soruda Anlat
            </div>
            <br />
            <img width="100%" src={q1} alt="q1" />
            <br />
            Sadece ilanını bırak gerisini biz halledelim
          </div>
          <div className="sy-box">
            <div className="flex items-center justify-center flex-col">
              <img src={qi} alt="q1" />
              <br />
              Özel Fiyat teklifleri Al
            </div>
            <br />
            <img width="100%" src={q2} alt="q1" />
            <br />
              Gelen teklifleri değerlendir
          </div>
          <div className="sy-box">
            <div className="flex items-center justify-center flex-col">
              <img src={qi} alt="q1" />
              <br />
              Karşılaştır ve Seç
            </div>
            <br />
            <img className="w-[100%]" src={q3} alt="q1" />
            <br />
              Senin için en uygun teklifi onayla  
          </div>
        </div>
      </div>
    </section>
  );
};

export default HowItWorks;
