import React, {useEffect} from "react";
import profile from "../assets/images/profile2.png";
import {useSelector, useDispatch} from 'react-redux';
import {userCargoList} from "../redux/slices/userSlice";
import Loading from "../partials/Loader";
import {Link} from "react-router-dom";
const LatestCompany = () => {
  const {userCargoListData: data, userCargoListLoading: loading} = useSelector((state) => state.users);
  const dispatch = useDispatch();
  useEffect(() => {
     dispatch(userCargoList());
  }, [dispatch])

  if (loading)
    return (
      <div>
        <Loading />
      </div>
    );
  return (
    <div className="relative">
      <div className="container">
        <div className="sup-header">
          <div className="home-title flex-j">Öne Çıkan Firmalar</div>
        </div>
        <div className="features-link">
          <a className="active" href="#">
            Tümü
          </a>
          <a href="#">En Çok Değerlendirilenler</a>
        </div>
        <div className="s-card-container">
          {data?.slice(0,6).map((item, index) => {
            return (
              <Link to={`/nakliye/${item?.user?._id}`} className="s-card">
                <div className="s-card-img">
                  {item?.user?.image ? (
                  <img src={item?.user?.image} alt="profile" />
                  ) : (
                  <img src={profile} alt="profile" />
                  )}
                </div>
                <div className="">
                  <div className="s-card-title">{item?.user?.cargoName}</div>
                  <div className="s-card-cap">{item?.user?.fullName}</div>
                  <div className="s-card-info">
                   
                    <div className="s-card-info-star">
                    <i className="text-[#FF9A00] fa-solid fa-star"></i> 
                    {item?.averageRating} ({item?.numberOfComments})
                    </div>
                  </div>
                </div>
              </Link>
            );
          })}
        </div>
      </div>
      <div className="see-all-container">
        <a href="/nakliyeciler" className="primary-btn-st">
          Tümünü Gör <i className="fa-solid fa-chevron-right"></i>
        </a>
      </div>
    </div>
  );
};

export default LatestCompany;
