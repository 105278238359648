import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { API_URL } from "../../utils/config";

const initialState = {
  user: null,
  loading: false,
  error: null,
  userUpdate: null,
  userUpdateLoading: false,
  userUpdateError: null,
  userChangePassword: null,
  userChangePasswordLoading: false,
  userChangePasswordError: null,
  userDeleteSuccess: null,
  userDeleteLoading: false,  
  userDeleteError: null,
  userCargoListData: null,
  userCargoListLoading: false,
  userCargoListError: null,
  userCargoDetailsData: null,
  userCargoDetailsLoading: false,
  userCargoDetailsError: null,
};

export const detailsUser = createAsyncThunk(
  `/user/details`,
  async (_, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(`${API_URL}/user/profile`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      throw new Error(error.response.data);
    }
  }
);

export const updateUser = createAsyncThunk(
  `/user/update`,
  async ({ updatedUser }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.put(`${API_URL}/user/profile`, updatedUser, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      localStorage.removeItem(token);
      return response.data;
    } catch (error) {
      throw new Error(error.response.data);
    }
  }
);

export const deleteUser = createAsyncThunk(
  'user/delete',
  async (_, thunkAPI) => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.delete(`${API_URL}/user`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      localStorage.removeItem('token'); // Fix: remove 'token' item from localStorage
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const updateUserPassword = createAsyncThunk(
  `/user/updatepassword`,
  async ({ updatedCredentials }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.post(
        `${API_URL}/user/change-password`,
        updatedCredentials,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      localStorage.removeItem("token");
      return response.data;
    } catch (error) {
      throw new Error(error.response.data);
    }
  }
);

export const userCargoList = createAsyncThunk(
  `/cargo/cargoList`,
  async (_, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(`${API_URL}/user/cargo`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      throw new Error(error.response.data);
    }
  }
);

export const userCargoDetails = createAsyncThunk(
  `/cargo/cargoDetails`,
  async ({ id }, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(`${API_URL}/user/cargo/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      throw new Error(error.response.data);
    }
  }
);

// Write forgotpassword thunk that accept email

export const forgotPassword = createAsyncThunk(
  `/user/forgotpassword`,
  async ({ emailData }) => {
    try {
      const response = await axios.post(
        `${API_URL}/api/ForgotPassword`,
        emailData
      );
      return response.data;
    } catch (error) {
      throw new Error(error.response.data);
    }
  }
);

// Create the auth slice
const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(detailsUser.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(detailsUser.fulfilled, (state, action) => {
        state.loading = false;
        state.user = action.payload;
      })
      .addCase(detailsUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(updateUser.pending, (state) => {
        state.userUpdateLoading = true;
        state.userUpdateError = null;
      })
      .addCase(updateUser.fulfilled, (state, action) => {
        state.userUpdateLoading = false;
        state.userUpdate = action.payload;
      })
      .addCase(updateUser.rejected, (state, action) => {
        state.userUpdateLoading = false;
        state.userUpdateError = action.payload;
      })
      .addCase(updateUserPassword.pending, (state) => {
        state.updateUserPasswordLoading = true;
        state.updateUserPasswordError = null;
      })
      .addCase(updateUserPassword.fulfilled, (state, action) => {
        state.updateUserPasswordLoading = false;
        state.updateUserPasswordSuccess = action.payload.success;
      })
      .addCase(updateUserPassword.rejected, (state, action) => {
        state.updateUserPasswordLoading = false;
        state.updateUserPasswordError = null;
      })
      .addCase(deleteUser.pending, (state) => {
        state.userDeleteLoading = true;
        state.userDeleteError = null;
      })
      .addCase(deleteUser.fulfilled, (state, action) => {
        state.userDeleteLoading = false;
        state.userDeleteSuccess = action.payload.success;
      })
      .addCase(deleteUser.rejected, (state, action) => {
        state.userDeleteLoading = false;
        state.userDeleteSuccess = null;
      })
      .addCase(userCargoList.pending, (state) => {
        state.userCargoListLoading = true;
        state.userCargoListError = null;
      })
      .addCase(userCargoList.fulfilled, (state, action) => {
        state.userCargoListLoading = false;
        state.userCargoListData = action.payload;
      })
      .addCase(userCargoList.rejected, (state, action) => {
        state.userCargoListLoading = false;
        state.userCargoListError = null;
      })
      .addCase(userCargoDetails.pending, (state) => {
        state.userCargoDetailsLoading = true;
        state.userCargoDetailsError = null;
      })
      .addCase(userCargoDetails.fulfilled, (state, action) => {
        state.userCargoDetailsLoading = false;
        state.userCargoDetailsData = action.payload;
      })
      .addCase(userCargoDetails.rejected, (state, action) => {
        state.userCargoDetailsLoading = false;
        state.userCargoDetailsError = null;
      });
  },
});

// Export actions and reducer
export const userActions = userSlice.actions;
export default userSlice.reducer;
