import React, { useEffect } from "react";
import Sidebar from "../components/SideBar";
import { useSelector, useDispatch } from "react-redux";
import { Outlet } from "react-router-dom";
import { detailsUser } from "../redux/slices/userSlice";
const Layout = () => {
  const dispatch = useDispatch();
  const { user: data, loading } = useSelector((state) => state.users);

  useEffect(() => {
    dispatch(detailsUser());
  }, [dispatch]);


  return (
    <div className="w-[95%] lg:w-[85%] mx-auto mt-8">
      <div className="grid grid-cols-4 gap-4">
        <div className="col-span-1">
          <Sidebar data={data} loading={loading} />
        </div>
        <div className="col-span-4 lg:col-span-3">
          <Outlet/>
        </div>
      </div>
    </div>
  );
};

export default Layout;
