import React from 'react';
import logo from "../assets/images/logo.png";

const Footer = () => {
  return (
    <div className='my-[70px]'>
    <div className="container">
        <div className="footer-container-width">
            <div className="footer-container">
                <div className="footer-section grid lg:grid-cols-2 grid-cols-1">
                    <div className="footer-box">
                        <div className="f-box-text">
                            Kolay ve Hesaplı…
                        </div>
                        <div className="f-box-text-alt">
                        Son kullanıcıya hizmete ulaşmada kolaylık sunan borvey, taşıma hizmeti alan müşteriye planlama yapma ya da hizmet almak 
                        istediği takvim ve güzergahta uygun hizmet verenlerle bir araya gelme olanağı tanır
                        </div>
                        <div className="f-box-btn or-f">
                            <a href="#">Hizmet Al</a>
                        </div>
                    </div>
                    <div className="footer-box">
                        <div className="f-box-text">
                            Aracın boşta beklemesin
                        </div>
                        <div className="f-box-text-alt">
                        Müşteri ya da yük için günlerce garajlarda beklemeye, boş ya da yarım yükle masraflı seferlere son.
                        borvey'e gel, uygun güzergahta uygun yük bulalım, zaman ve akaryakıt harcama

                        </div>
                        <div className="f-box-btn bl-f">
                            <a href="#">Hizmet Ver</a>
                        </div>
                    </div>
                </div>
                <div className="border-line"></div>
                <div className="footer-links-container px-[50px]">
                    <div className="footer-logo">
                        <a href="#"><img src={logo} alt="logo" /></a>
                    </div>
                    <div className="footer-links">
                        <a href="/">Anasayfa</a>
                        <a href="/about">Hakkımızda</a>
                        <a href="/help">Hizmetler</a>
                        <a href="/contact">İletişim</a>
                    </div>
                    <div className="footer-links-social">
                        <a href="https://www.facebook.com/people/borvey/100093522541664/"><i className="fa-brands fa-facebook-f"></i></a>
                        <a href="https://x.com/borveyborvey"><i class="fa-brands fa-twitter"></i></a>
                        <a href="https://www.linkedin.com/in/borvey-com-441345287/"><i className="fa-brands fa-linkedin-in"></i></a>
                        <a href="https://www.instagram.com/borveycom/"><i className="fa-brands fa-instagram"></i></a>
            
                    </div>
                </div>
                <div className="border-line"></div>
                <div  className="footer-links-container text-[#8C9491]">
                   <div className="footer-links">
                    © 2011 - 2024 borvey Teknoloji AŞ, Tüm Hakları Saklıdır
                   </div>
                    <div className="footer-links footer-kvk">
                        <a href="/cookies">Gizlilik Sözleşmesi</a>
                        <a href="/cookies">KVKK</a>
                        <a href="/cookies">Kullanıcı Sözleşmesi</a>
                        <a href="/cookies">Çağrı Merkezi Aydınlatma Metni</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
  )
}

export default Footer
