import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { API_URL } from "../../utils/config";

const initialState = {
  user: null,
  loading: false,
  error: null,
  createMessageSuccess: null,
  createMessageLoading: false,
  createMessageError: null,
};

export const createMessage = createAsyncThunk(
  `/message/create`,
  async ({ newMessage }) => {
    try {
      console.log(newMessage);
      const token = localStorage.getItem("token");
      const response = await axios.post(`${API_URL}/message`, newMessage, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      throw new Error(error.response.data);
    }
  }
);

export const messageList = createAsyncThunk(
  `/message/List`,
  async ({ id }, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(`${API_URL}/message/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      throw new Error(error.response.data);
    }
  }
);

// Create the auth slice
const userMessageSlice = createSlice({
  name: "message",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(messageList.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(messageList.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(messageList.rejected, (state, action) => {
        state.loading = false;
        state.error = null;
      })
      .addCase(createMessage.pending, (state) => {
        state.createMessageLoading = true;
        state.createMessageError = null;
      })
      .addCase(createMessage.fulfilled, (state, action) => {
        state.createMessageLoading = false;
        state.createMessageSuccess = action.payload;
      })
      .addCase(createMessage.rejected, (state, action) => {
        state.createMessageLoading = false;
        state.createMessageError = null;
      });
  },
});

// Export actions and reducer
export const messageActions = userMessageSlice.actions;
export default userMessageSlice.reducer;
