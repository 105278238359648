import React, { useState } from "react";
import { forgotPassword } from "../redux/slices/authSlice";
import { useSelector, useDispatch } from "react-redux";
import ActionButton from "../components/UI/ActionButton";
const ForgotPassword = () => {
  const dispatch = useDispatch();

  const { forgotPasswordSuccess, forgotPasswordLoading, forgotPasswordError } =
    useSelector((state) => state.auth);

  const [email, setEmail] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const userData = { email };
      await dispatch(forgotPassword({ userData }));
    } catch (error) {
      window.alert("Hata!", "Lütfen tekrar deneyin!", "error");
    }
  };
  return (
    <div className="w-[80%] lg:w-[50%] my-8 mx-auto text-center bg-white px-4 sm:px-8 py-10 rounded-xl shadow">
      <header className="mb-8">
        <h1 className="text-2xl font-bold mb-1">Şifre Hatırlatma</h1>
      </header>
      <form id="otp-form" onSubmit={handleSubmit}>
        <div className="flex items-center justify-center gap-3">
          <input
            type="text"
            placeholder="E-postanızı yazınız"
            className="w-[100%] text-center  font-extrabold text-slate-900 bg-slate-100 border border-transparent hover:border-slate-200 appearance-none rounded p-4 outline-none focus:bg-white focus:border-indigo-400 focus:ring-2 focus:ring-indigo-100"
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <div className="w-[100%] mx-auto mt-4">
          <ActionButton
            success={forgotPasswordSuccess}
            loading={forgotPasswordLoading}
            error={forgotPasswordError}
            path="/"
            message="Şifre başarıyla e-postaya gönderildi"
            content="Şifreyi Sıfırlayın"
          />
        </div>
      </form>
    </div>
  );
};

export default ForgotPassword;
