import React, { useEffect } from "react";
import profile from "../assets/images/profile2.png";
import { useSelector, useDispatch } from "react-redux";
import { userCargoList } from "../redux/slices/userSlice";
import Loading from "../partials/Loader";
import { Link } from "react-router-dom";

const Seller = () => {
  const { userCargoListData: data, userCargoListLoading: loading } =
    useSelector((state) => state.users);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(userCargoList());
  }, [dispatch]);

  if (loading)
    return (
      <div>
        <Loading />
      </div>
    );

    console.log(data);
  return (
    <div className="nav-container">
      <div className="">
        <div className="">
          <div className="sup-header text-left">
            <div className="home-title">Nakliyeciler</div>
            <div className="title-alt2">
              <i className="fa-solid fa-house"></i> borvey{" "}
              <i className="fa-solid fa-angle-right"></i> Size uygun
              nakliyeciler
            </div>
          </div>
          <div className="product-filter-container">
            <div className="product-filter">
              <a href="#">
                <span>Öne Çıkanlar</span>
                <br />
                Size en uyumlu nakliyeciler
              </a>
              <a href="#">
                <span>Tavsiye Edilen</span>
                <br />
                En çok değerlendirilen nakliyeciler
              </a>
            </div>
          </div>
          <div className="s-card-container">
            {data?.map((item) => {
              return (
                <Link to={`/nakliye/${item?.user?._id}`}>
                  <div className="s-card">
                    <div className="s-card-img">
                      <img src={profile} alt="profile" />
                    </div>
                    <div className="s-card-content">
                      <div className="s-card-title">{item?.user?.companyName}</div>
                      <div className="s-card-cap">{item?.user?.fullName}</div>
                      <div className="s-card-info">
                        <div className="s-card-info-loc">
                          <i className="fa-solid fa-star  text-[#FF9A00]"></i>{" "}
                          {item?.averageRating} ({item?.numberOfComments})
                        </div>
                      </div>
                    </div>
                  </div>
                </Link>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Seller;
