import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { API_URL } from "../../utils/config";

const initialState = {
  orderCreateSuccess: null,
  orderCreateLoading: false,
  orderCreateError: null,
  orderUpdateSuccess: null,
  orderUpdateLoading: false,
  orderUpdateError: null,
  orderDeleteSuccess: null,
  orderDeleteLoading: false,
  orderDeleteError: null,
  orderListData: null,
  orderListLoading: false,
  orderListError: null,
  orderListSeedData: null,
  orderListSeedLoading: false,
  orderListSeedError: null,
  orderDetailsData: null,
  orderDetailsLoading: false,
  orderDetailsError: null,
  orderUpdateStatusSuccess: null,
  orderUpdateStatusLoading: false,
  orderUpdateStatusError: null,
  orderListCargoData: null,
  orderListCargoLoading: false,
  orderListCargoError: null
};

export const orderCreate = createAsyncThunk(
    `/order/create`,
    async ({ order }) => {
      try {
        const token = localStorage.getItem("token");
        const response = await axios.post(
          `${API_URL}/order`,
          order,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        return response.data;
      } catch (error) {
        throw new Error(error.response.data);
      }
    }
  );
  

  export const orderList = createAsyncThunk(
    `/order/list`,
    async (_, { rejectWithValue }) => {
      try {
        const token = localStorage.getItem("token");
        const response = await axios.get(
          `${API_URL}/order`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        return response.data;
      } catch (error) {
        throw new Error(error.response.data);
      }
    }
  );


  export const orderDetails = createAsyncThunk(
    `/order/details`,
    async ({id}, { rejectWithValue }) => {
      try {
        const token = localStorage.getItem("token");
        const response = await axios.get(
          `${API_URL}/order/${id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        return response.data;
      } catch (error) {
        throw new Error(error.response.data);
      }
    }
  );

  
  export const orderSeedList = createAsyncThunk(
    `/order/seed/list`,
    async ({type}, { rejectWithValue }) => {
      try {
        const token = localStorage.getItem("token");
        const response = await axios.get(
          `${API_URL}/order/seed/${type}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        return response.data;
      } catch (error) {
        throw new Error(error.response.data);
      }
    }
  );


  export const orderUpdateStatus = createAsyncThunk(
    `/order/updateStatus`,
    async ({ id, status }) => {
      try {
        const token = localStorage.getItem("token");
        const response = await axios.put(
          `${API_URL}/order/${id}/${status}`,
          {},
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        return response.data;
      } catch (error) {
        throw new Error(error.response.data);
      }
    }
  );
  


  export const orderCargoList = createAsyncThunk(
    `/order/cargoList`,
    async ({id}, { rejectWithValue }) => {
      try {
        const token = localStorage.getItem("token");
        const response = await axios.get(
          `${API_URL}/order/cargo/${id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        return response.data;
      } catch (error) {
        throw new Error(error.response.data);
      }
    }
  );



  export const orderUpdate = createAsyncThunk(
    `/order/update`,
    async ({ id, updatedOrder }) => {
      try {
        const token = localStorage.getItem("token");
        const response = await axios.put(
          `${API_URL}/order/${id}`,
          updatedOrder,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        return response.data;
      } catch (error) {
        throw new Error(error.response.data);
      }
    }
  );
  



  export const orderDelete = createAsyncThunk(
    `/order/delete`,
    async ({ id }) => {
      try {
        const token = localStorage.getItem("token");
        const response = await axios.delete(
          `${API_URL}/order/${id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        return response.data;
      } catch (error) {
        throw new Error(error.response.data);
      }
    }
  );
  

// Create the order slice
const orderSlice = createSlice({
  name: "order",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(orderCreate.pending, (state) => {
        state.orderCreateLoading = true;
        state.orderCreateError = null;
      })
      .addCase(orderCreate.fulfilled, (state, action) => {
        state.orderCreateLoading = false;
        state.orderCreateSuccess = action.payload.success;
      })
      .addCase(orderCreate.rejected, (state, action) => {
        state.orderCreateLoading = false;
        state.orderCreateError = action.payload;
      })
      .addCase(orderUpdate.pending, (state) => {
        state.orderUpdateLoading = true;
        state.orderUpdateError = null;
      })
      .addCase(orderUpdate.fulfilled, (state, action) => {
        state.orderUpdateLoading = false;
        state.orderUpdateSuccess = action.payload.success;
      })
      .addCase(orderUpdate.rejected, (state, action) => {
        state.orderUpdateLoading = false;
        state.orderUpdateError = action.payload;
      })
      .addCase(orderDelete.pending, (state) => {
        state.orderDeleteLoading = true;
        state.orderDeleteError = null;
      })
      .addCase(orderDelete.fulfilled, (state, action) => {
        state.orderDeleteLoading = false;
        state.orderDeleteSuccess = action.payload.success;
      })
      .addCase(orderDelete.rejected, (state, action) => {
        state.orderDeleteLoading = false;
        state.orderDeleteError = action.payload;
      })
      .addCase(orderUpdateStatus.pending, (state) => {
        state.orderUpdateStatusLoading = true;
        state.orderUpdateStatusError = null;
      })
      .addCase(orderUpdateStatus.fulfilled, (state, action) => {
        state.orderUpdateStatusLoading = false;
        state.orderUpdateStatusSuccess = action.payload.success;
      })
      .addCase(orderUpdateStatus.rejected, (state, action) => {
        state.orderUpdateStatusLoading = false;
        state.orderUpdateStatusError = action.payload;
      })
      .addCase(orderList.pending, (state) => {
        state.orderListLoading = true;
        state.orderListError = null;
      })
      .addCase(orderList.fulfilled, (state, action) => {
        state.orderListLoading = false;
        state.orderListData = action.payload;
      })
      .addCase(orderList.rejected, (state, action) => {
        state.orderListLoading = false;
        state.orderListError = action.payload;
      })
      .addCase(orderSeedList.pending, (state) => {
        state.orderListSeedLoading = true;
        state.orderListSeedError = null;
      })
      .addCase(orderSeedList.fulfilled, (state, action) => {
        state.orderListSeedLoading = false;
        state.orderListSeedData = action.payload;
      })
      .addCase(orderSeedList.rejected, (state, action) => {
        state.orderListSeedLoading = false;
        state.orderListSeedError = action.payload;
      })
      .addCase(orderDetails.pending, (state) => {
        state.orderDetailsLoading = true;
        state.orderDetailsError = null;
      })
      .addCase(orderDetails.fulfilled, (state, action) => {
        state.orderDetailsLoading = false;
        state.orderDetailsData = action.payload;
      })
      .addCase(orderDetails.rejected, (state, action) => {
        state.orderDetailsLoading = false;
        state.orderDetailsError = action.payload;
      })
      .addCase(orderCargoList.pending, (state) => {
        state.orderListCargoLoading = true;
        state.orderListCargoError = null;
      })
      .addCase(orderCargoList.fulfilled, (state, action) => {
        state.orderListCargoLoading = false;
        state.orderListCargoData = action.payload;
      })
      .addCase(orderCargoList.rejected, (state, action) => {
        state.orderListCargoLoading = false;
        state.orderListCargoError = action.payload;
      })
  },
});

// Export actions and reducer
export const orderactions = orderSlice.actions;
export default orderSlice.reducer;
