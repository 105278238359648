import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { API_URL } from "../../utils/config";

const initialState = {
  loginSuccess: null,
  loginLoading: false,
  loginError: null,
  registerSuccess: null,
  registerLoading: false,
  registerError: null,
  forgotPasswordSuccess: null,
  forgotPasswordLoading: false,
  forgotPasswordError: null,
};

export const registerUser = createAsyncThunk(
  "register/user",
  async ({ userData }, { rejectWithValue }) => {
    try {
      const response = await axios.post(`${API_URL}/auth/signup`, userData);
      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.response.data.message || "Failed to register"
      );
    }
  }
);

export const loginUser = createAsyncThunk(
  "login/user",
  async ({ userData }, { rejectWithValue }) => {
    try {
      const response = await axios.post(`${API_URL}/auth/signin`, userData);
      localStorage.setItem("token", response.data.token);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message || "Failed to login");
    }
  }
);

export const forgotPassword = createAsyncThunk(
  "user/password",
  async ({ userData }, { rejectWithValue }) => {
    try {
      const response = await axios.post(`${API_URL}/auth/forgot-password`, userData);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message || "Failed to login");
    }
  }
);

// Create the auth slice
const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(registerUser.pending, (state) => {
        state.registerLoading = true;
        state.registerError = null;
      })
      .addCase(registerUser.fulfilled, (state, action) => {
        state.registerLoading = false;
        state.registerSuccess = action.payload.success;
      })
      .addCase(registerUser.rejected, (state, action) => {
        state.registerLoading = false;
        state.registerError = action.payload;
      })
      .addCase(loginUser.pending, (state) => {
        state.loginLoading = true;
        state.loginError = null;
      })
      .addCase(loginUser.fulfilled, (state, action) => {
        state.loginLoading = false;
        state.loginSuccess = action.payload.success;
      })
      .addCase(loginUser.rejected, (state, action) => {
        state.loginLoading = false;
        state.loginError = action.payload;
      })
      .addCase(forgotPassword.pending, (state) => {
        state.forgotPasswordLoading = true;
        state.forgotPasswordError = null;
      })
      .addCase(forgotPassword.fulfilled, (state, action) => {
        state.forgotPasswordLoading = false;
        state.forgotPasswordSuccess = action.payload.success;
      })
      .addCase(forgotPassword.rejected, (state, action) => {
        state.forgotPasswordLoading = false;
        state.forgotPasswordError = action.payload;
      });
  },
});

// Export actions and reducer
export const authactions = authSlice.actions;
export default authSlice.reducer;
