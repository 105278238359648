import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { orderList } from "../redux/slices/orderSlice";
import Loading from "../partials/Loader";
const LatestServices = () => {
  const { orderListData: data, orderListLoading: loading } = useSelector(
    (state) => state.order
  );
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(orderList());
  }, [dispatch]);

  if (loading)
    return (
      <div>
        <Loading />
      </div>
    );
  return (
    <section className="relative">
      <div className="container">
        <div className="sup-header">
          <div className="home-title flex-j">En Son Eklenen İlanlar</div>
        </div>
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-2 my-4">
          {data?.slice(0,10).map((item) => {
            return (
              <Link to={`/order-details/${item?._id}`}>
              <div className="s-card relative">
                <div className="p-4">
                  <div className="s-card-title">{item?.cargoType}</div>
                  <div className="s-card-c">
                    <i className="fa-regular fa-user"></i> {item?.fromLocation.city}<i class="fa-solid fa-arrow-right"></i>{item?.toLocation.city}
                  </div>
                  <a href="#" className="chat-btn absolute top-[10px] right-[10px]">
                    <i class="fa-solid fa-info"></i>
                  </a>
                </div>
              </div>
              </Link>
            );
          })}
        </div>
      </div>
      <div className="see-all-container">
        <a href="/order-list" className="primary-btn-st">
          Tümünü Gör <i className="fa-solid fa-chevron-right"></i>
        </a>
      </div>
    </section>
  );
};

export default LatestServices;
