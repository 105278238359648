import React from 'react'
import starblue from "../assets/images/icon/starblue.png";
import starorange from "../assets/images/icon/starorange.png";
import starcyan from "../assets/images/icon/starcyan.png";
import starblack from "../assets/images/icon/starblack.png";

const Info = () => {
  return (
    <section className="bg-effect">
    <div className="container">
        <div className="sup-header">
            <div className="home-title flex-j">
                Kolay, güvenli ve hızlı
            </div>
        </div>
        <div className="box-f-container">
            <div className="box-f">
                <div className="box-f-img">
                    <img src={starblue} alt="star" />
                </div>
                <div className="box-f-title">
                    Nakliye Maliyetlerinizi Azaltın
                </div>
                <div className="box-f-p">
                    borvey ile evini, iş yerini, yazlığına kargoyla gönderemediğin dolabını, ikinci elden aldığın eşyaları nasıl taşıyacağını düşünmene gerek yok. 
                </div>
            </div>
            <div className="box-f">
                <div className="box-f-img">
                    <img src={starorange} alt="star" />
                </div>
                <div className="box-f-title">
                    Güvenilir Nakliye Çözümleri                
                </div>
                <div className="box-f-p">
                    borvey, hem birlikte çalıştığı nakliye firmalarının hem de nakliye hizmeti alan müşterilerinin memnuniyeti için gerekli tüm tedbirleri alır.
                </div>
            </div>
            <div className="box-f">
                <div className="box-f-img">
                    <img src={starcyan} alt="star" />
                </div>
                <div className="box-f-title">
                	Taşınma Sürecinizi Biz Yönetiriz
                </div>
                <div className="box-f-p">
                    Sokak sokak nakliyeci aramanıza, sayısız ilan ve reklam arasında kaybolmanıza gerek yok.
                    borvey, belirlediğiniz kriterlerde hizmet verenleri size getirir. 
                </div>
            </div>
            <div className="box-f">
                <div className="box-f-img">
                    <img src={starblack} alt="star" />
                </div>
                <div className="box-f-title">
                	Rekabetçi Fiyat ve Zaman Fırsatı
                </div>
                <div className="box-f-p">
                    Taşıyıcı ağımızla nakliye maliyetlerini azaltın ve verimliliği artırın.
                    Her gönderi boyutu için uygun ve güvenilir nakliye çözümleri bulun.
                </div>
            </div>
        </div>
    </div>
</section>
  )
}

export default Info
