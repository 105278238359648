import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Swal from "sweetalert2";
import Loading from "../partials/Loader";
import { detailsUser, updateUser, deleteUser } from "../redux/slices/userSlice";
import cameraImage from "../assets/images/camera-plus.png";
import axios from "axios";
//import cameraImage from "../assets/images/camera-plus.png";

const Dashboard = () => {
  const dispatch = useDispatch();
  const { user: data, loading } = useSelector((state) => state.users);

  useEffect(() => {
    dispatch(detailsUser());
  }, [dispatch]);

  const [showFullNameInput, setShowFullNameInput] = useState(false);
  const [showEmailInput, setShowEmailInput] = useState(false);
  const [showPhoneNumberInput, setShowPhoneNumberInput] = useState(false);

  const toggleFullNameInput = () => setShowFullNameInput(!showFullNameInput);
  const toggleEmailInput = () => setShowEmailInput(!showEmailInput);
  const togglePhoneNumberInput = () =>
    setShowPhoneNumberInput(!showPhoneNumberInput);

  const [fullName, setFullName] = useState(data?.fullName || "");
  const [email, setEmail] = useState(data?.email || "");
  const [phoneNumber, setPhoneNumber] = useState(data?.phoneNumber || "");

  const [image, setImage] = useState(data?.image || undefined);

  const [loadingUpload, setLoadingUpload] = useState(false);
  const [errorUpload, setErrorUpload] = useState(false);

  useEffect(() => {
    if (data) {
      setFullName(data?.fullName);
      setImage(data?.image);
      setEmail(data?.email);
      setPhoneNumber(data?.phoneNumber);
    }
  }, [data]);

  console.log(image);
  const handleSubmit = async (e) => {
    e.preventDefault();
    const updatedUser = {
      fullName,
      image,
      email,
      phoneNumber,
    };

    try {
      const response = await dispatch(updateUser({ updatedUser }));
      if (response && response.payload) {
        Swal.fire({
          title: "Tebrikler!",
          html: "Profiliniz güncellendi",
          timer: 2000,
          timerProgressBar: true,
          onBeforeOpen: () => {
            Swal.showLoading();
          },
          didClose: () => {
            // Redirect to the desired URL after 2 seconds
            window.location.assign("/"); // Replace with your desired URL
          },
        });
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Hata",
        text: "Bir şeyler yanlış gitti",
      });
    }
  };

  const handleDeleteUser = async (e) => {
    e.preventDefault();
    try {
      const response = await dispatch(deleteUser()).unwrap();
      if (response) {
        localStorage.removeItem("token");
        Swal.fire({
          title: "Üzgünüz!",
          html: "Profiliniz silindi",
          timer: 2000,
          timerProgressBar: true,
          onBeforeOpen: () => {
            Swal.showLoading();
          },
          didClose: () => {
            // Redirect to the desired URL after 2 seconds
            window.location.assign("/"); // Replace with your desired URL
          },
        });
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Hata",
        text: "Bir şeyler yanlış gitti",
      });
    }
  };

  const uploadFileHandler = async (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      const bodyFormData = new FormData();
      bodyFormData.append("filename", file);
      setLoadingUpload(true);
      try {
        const { data } = await axios.post(
          "https://borvey-backend-two.vercel.app/api/upload",
          bodyFormData,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        setImage(data?.downloadURL);
        setLoadingUpload(false);
      } catch (error) {
        setErrorUpload(error);
        setLoadingUpload(false);
      }
    }
  };

  if (loading)
    return (
      <div>
        <Loading />
      </div>
    );

  return (
    <div className="content-section">
      <div className="account-content-container">
        <div className="acc-title">Kişisel bilgiler</div>
        <div className="acc-content-flex">
          {/* First Form */}
          <form
            onSubmit={handleSubmit}
            className="account-content-accordion-list2"
          >
            <div className="account-content-accordion-list">
              <div className="asked-questions-container">
                <div className="accordion-menu">
                  <div className="accordion-item">
                    <div className="borderLine">
                      <div className="faq-header">
                        <div className="acc-a-title">
                          Ad Soyad
                          <br />
                          <span>
                            {data?.fullName ? data?.fullName : "Belirtilmemiş"}
                          </span>
                        </div>
                        <div onClick={toggleFullNameInput}>
                          <i className="fa-regular fa-pen-to-square"></i>
                        </div>
                      </div>
                      {showFullNameInput && (
                        <div className="accordion-item paragraph">
                          <div className="paragraph-content">
                            <input
                              className="acc-in"
                              type="text"
                              placeholder="Ad Soyad"
                              value={fullName}
                              onChange={(e) => setFullName(e.target.value)}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="accordion-item">
                    <div className="borderLine">
                      <div className="faq-header">
                        <div className="acc-a-title">
                          E-posta
                          <br />
                          <span>
                            {data?.email ? data?.email : "Belirtilmemiş"}
                          </span>
                        </div>
                        <div onClick={toggleEmailInput}>
                          <i className="fa-regular fa-pen-to-square"></i>
                        </div>
                      </div>
                      {showEmailInput && (
                        <div className="accordion-item paragraph">
                          <div className="paragraph-content">
                            <input
                              className="acc-in"
                              type="text"
                              placeholder="E-posta"
                              value={email}
                              onChange={(e) => setEmail(e.target.value)}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="accordion-item">
                    <div className="borderLine">
                      <div className="faq-header">
                        <div className="acc-a-title">
                          Telefon numarası
                          <br />
                          <span>
                            {data?.phoneNumber
                              ? data?.phoneNumber
                              : "Belirtilmemiş"}
                          </span>
                        </div>
                        <div onClick={togglePhoneNumberInput}>
                          <i className="fa-regular fa-pen-to-square"></i>
                        </div>
                      </div>
                      {showPhoneNumberInput && (
                        <div className="accordion-item paragraph">
                          <div className="paragraph-content">
                            <input
                              className="acc-in"
                              type="text"
                              placeholder="Telefon numarası"
                              value={phoneNumber}
                              onChange={(e) => setPhoneNumber(e.target.value)}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <button
              type="submit"
              className="w-full inline-flex justify-center whitespace-nowrap rounded-lg bg-indigo-500 px-3.5 py-2.5 text-sm font-medium text-white shadow-sm shadow-indigo-950/10 hover:bg-indigo-600 focus:outline-none focus:ring focus:ring-indigo-300 focus-visible:outline-none focus-visible:ring focus-visible:ring-indigo-300 transition-colors duration-150 mt-4"
            >
              Güncelle
            </button>
          </form>

          {/* Second Form */}
          <div className="mt-8">
            <form className="relative">
              <input
                type="file"
                accept="image/*"
                className="cursor-pointer absolute w-full  bg-red-300 z-50 p-0 opacity-0 outline-none"
                onChange={uploadFileHandler}
              />
              {loadingUpload ? (
                <div className="text-center">Uploading...</div>
              ) : errorUpload ? (
                <div>Error...</div>
              ) : (
                <div className="flex flex-col gap-8 items-center text-center">
                  {image ? (
                    <div className="h-24 w-24 rounded-full">
                      <img
                        src={image}
                        alt="Selected"
                        className="w-full h-full rounded-full cursor-pointer"
                      />
                    </div>
                  ) : (
                    <div className="h-24 w-24 rounded-full">
                      <img
                        src={cameraImage}
                        alt="Selected"
                        className="w-full h-full rounded-full cursor-pointer"
                      />
                    </div>
                  )}
                  <div>
                    <p>
                      <span className="text-primary">Upload image</span>
                    </p>
                    <p className="mt-1.5 text-[14px]">PNG, JPG</p>
                    <p className="text-[12px]">(max, 800 X 800px)</p>
                  </div>
                </div>
              )}
            </form>
          </div>
        </div>

        <div className="acc-delete cursor-pointer" onClick={handleDeleteUser}>
          <i className="fa-solid fa-trash-can"></i> Hesabı Sil
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
