import { useState } from "react";
import logo from "../assets/images/logo.png";
import profile from "../assets/images/profile1.png";
import { Link } from "react-router-dom";
import MobileNavbar from "./MobileNavbar";

const Navbar = ({ isOpen, setOpen, data, loading }) => {
  const [openMobileNavbar, setOpenMobileNavbar] = useState(false);
  return (
    <div>
      <nav>
        <div className="w-[100%] lg:w-[85%] mx-auto">
          <div className="nav-segment">
            <div className="nav-logo">
              <Link to="/">
                <img src={logo} alt="logo" />
              </Link>
            </div>
            <div className="nav-links">
              <div className="nav-logo-m">
                <a href="index.html">
                  <img src={logo} alt="logo" />
                </a>
              </div>
              <div className="nav-link">
              </div>
              {data ? (
                <div className="nav-link-button gap-2">
                  <a href="#" className="hover-n">
                    <i
                      className="fa-regular fa-circle-question text-[#306FDF] text-[22px]"
                      aria-hidden="true"
                    ></i>
                  </a>
                  <Link to="/dashboard" className="classic-btn profile-btn">
                  {data?.image ? (
                    <img width="30px" src={data?.image} alt="profile" />
                  ) : (
                    <img width="30px" src={profile} alt="profile" />
                  )}
                    {data?.fullName} <i className="fa-solid fa-angle-down"></i>
                  </Link>
                  {data?.type == 2 && (
                    <Link className="l-btn" to="/create-order">
                      İlan ver
                    </Link>
                  )}

                  {data?.type == 1 && (
                    <Link className="r-btn" to="/order-list">
                      Yük ara
                    </Link>
                  )}
                </div>
              ) : (
                <div className="nav-link-button gap-2">
                  <a
                    className="classic-btn sign-btn"
                    onClick={() => setOpen(!isOpen)}
                  >
                    Giriş Yap / Üye Ol
                  </a>
                  <a className="l-btn" onClick={() => setOpen(!isOpen)} style={{cursor: "pointer"}}>
                    Hizmet Al
                  </a>
                  <a className="r-btn" onClick={() => setOpen(!isOpen)} style={{cursor: "pointer"}}>
                    Hizmet Ver
                  </a>
                </div>
              )}
            </div>
            <div
              className="menu-btn"
              onClick={() => setOpenMobileNavbar(!openMobileNavbar)}
            >
              <i className="fa-solid fa-bars"></i>
            </div>
          </div>
        </div>
        <div className="overlay"></div>
      </nav>
      {openMobileNavbar && (
        <MobileNavbar
          data={data}
          profile={profile}
          setOpen={setOpen}
          isOpen={isOpen}
        />
      )}
    </div>
  );
};

export default Navbar;
